import { Box, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { LinkButton } from '@/components/form/baseInputs/Link';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { useModalState } from '@/hooks/useModalState';
import { AddNewAnythingModal } from '@/modules/common/AddNewAnythingModal/AddNewAnythingModal';
import { TransferDirection } from '@/modules/entities/details/HypotheticalTransfersCard/hypotheticalTransfers/HypotheticalTransferForm';
import { useDraftEntityGrantorOptions } from '@/modules/entities/draftEntities/hooks/useDraftEntityGrantorOptions';
import { useDraftEntityOptions } from '@/modules/entities/draftEntities/hooks/useDraftEntityOptions';
import { EntityKind } from '@/types/schema';

import { useAssetOptions } from '../../HypotheticalTransfersCard/hypotheticalTransfers/hooks/useAssetOptions';
import { usePolicyOptions } from '../../HypotheticalTransfersCard/hypotheticalTransfers/hooks/usePolicyOptions';
import { useTransferDestinationOptions } from '../../HypotheticalTransfersCard/hypotheticalTransfers/hooks/useTransferDestinationOptions';
import { useHypotheticalTransferModalContext } from '../HypotheticalTransferModal.context';
import { HypotheticalTransferFormModalShape } from '../HypotheticalTransferModal.types';
import { AssetOptions } from './AssetOptions';
import { PolicyAssetRatio } from './PolicyAssetRadio';
import { PolicyOptions } from './PolicyOptions';

export function TransferSourceTarget() {
  const [
    { isModalOpen: isSourceModalOpen },
    { openModal: openSourceModal, closeModal: closeSourceModal },
  ] = useModalState();

  const [
    { isModalOpen: isTargetModalOpen },
    { openModal: openTargetModal, closeModal: closeTargetModal },
  ] = useModalState();

  const { control, setValue, resetField } =
    useFormContext<HypotheticalTransferFormModalShape>();
  const {
    householdId,
    initiatingDirection,
    transferId,
    derivedData: { ilitValueByEntityId },
  } = useHypotheticalTransferModalContext();
  const [
    transferToNewDraft,
    sourceId,
    targetId,
    _policyOrAsset,
    sourceInsurancePolicyId,
  ] = useWatch({
    control,
    name: [
      'transferToNewDraft',
      'sourceId',
      'targetId',
      '_policyOrAsset',
      'sourceInsurancePolicyId',
    ],
  });

  const isExistingTransfer = !!transferId;
  const isInbound = initiatingDirection === TransferDirection.Inbound;
  const isOutbound = initiatingDirection === TransferDirection.Outbound;

  const { typeOptions: sourceTypeOptions, loading: loadingSourceTypeOptions } =
    useTransferDestinationOptions(householdId, TransferDirection.Inbound);
  const { typeOptions: targetTypeOptions, loading: loadingTargetTypeOptions } =
    useTransferDestinationOptions(householdId, TransferDirection.Outbound);
  const { assetOptions, hasAssetOptions } = useAssetOptions({
    ownerId: sourceId,
  });
  const { policyOptions, hasPolicyOptions } = usePolicyOptions({
    entityId: sourceId,
  });

  const draftEntityGrantors = useDraftEntityGrantorOptions();

  const loading = loadingSourceTypeOptions || loadingTargetTypeOptions;

  // lock source if:
  // - loading state
  // - if existing outbound transfer
  const sourceDisabled = loading || (isOutbound && isExistingTransfer);

  // lock target if:
  // - loading state
  // - transferring to a new draft
  // - is existing transfer being edited from the inbound view
  const targetDisabled =
    loading || transferToNewDraft || (isInbound && isExistingTransfer);

  const draftEntityOptions = useDraftEntityOptions();

  const reverseTransfer = useCallback(() => {
    if (sourceDisabled || targetDisabled) {
      return;
    }

    setValue('sourceId', targetId);
    setValue('targetId', sourceId);
  }, [setValue, sourceDisabled, sourceId, targetDisabled, targetId]);

  const showPolicyAssetRadio = useMemo(() => {
    const hasNonExemptAssets = !!ilitValueByEntityId[sourceId];

    return (hasAssetOptions && hasPolicyOptions) || hasNonExemptAssets;
  }, [hasAssetOptions, hasPolicyOptions, ilitValueByEntityId, sourceId]);

  const [showAssetOptions, showPolicyOptions] = useMemo(() => {
    if (showPolicyAssetRadio) {
      // If we have decided to show the radio toggle, we show the option that is selected
      return [_policyOrAsset === 'asset', _policyOrAsset === 'policy'];
    }

    // If we have asset options, we show the asset options
    if (hasAssetOptions) {
      return [true, false];
    }

    // If we have policy options, we show the policy options
    if (hasPolicyOptions) {
      return [false, true];
    }

    return [false, false];
  }, [_policyOrAsset, hasAssetOptions, hasPolicyOptions, showPolicyAssetRadio]);

  const isGiftFromPolicy = useMemo(() => {
    if (!sourceInsurancePolicyId) return false;
    return true;
  }, [sourceInsurancePolicyId]);

  useEffect(
    function defaultIlitWhenGiftFromPolicy() {
      if (!isGiftFromPolicy) return;
      if (!transferToNewDraft) return;
      resetField('draftEntityType', { defaultValue: EntityKind.IlitTrust });
    },
    [isGiftFromPolicy, resetField, transferToNewDraft]
  );

  return (
    <>
      <Card variant="filled" sx={{ p: 3 }}>
        <FormLayoutRow>
          <FormLayoutItem>
            <AddNewAnythingModal
              addableTypes={['entity', 'individual']}
              handleClose={closeSourceModal}
              isOpen={isSourceModalOpen}
              householdId={householdId}
              onAfterCreate={closeSourceModal}
            />
            <FormAwareSelectInput<HypotheticalTransferFormModalShape>
              disabled={sourceDisabled}
              control={control}
              fieldName="sourceId"
              label="From"
              options={sourceTypeOptions}
              required
              showEmptyValue={false}
              addNewOption={{ onClick: openSourceModal }}
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <PolicyAssetRatio
          disabled={sourceDisabled}
          hide={!showPolicyAssetRadio}
        />
        <AssetOptions
          options={assetOptions}
          disabled={sourceDisabled}
          hide={!showAssetOptions}
        />
        <PolicyOptions
          options={policyOptions}
          disabled={sourceDisabled}
          entityId={sourceId}
          hide={!showPolicyOptions}
        />
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareSelectInput<HypotheticalTransferFormModalShape>
              disabled={targetDisabled}
              control={control}
              fieldName="targetId"
              label="To"
              options={targetTypeOptions}
              required={!transferToNewDraft}
              showEmptyValue={false}
              addNewOption={{
                onClick: openTargetModal,
              }}
            />
          </FormLayoutItem>
          <AddNewAnythingModal
            addableTypes={['entity', 'individual', 'organization']}
            handleClose={closeTargetModal}
            isOpen={isTargetModalOpen}
            householdId={householdId}
            onAfterCreate={closeTargetModal}
          />
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem width={transferToNewDraft ? 12 : 8}>
            <FormAwareSwitch<HypotheticalTransferFormModalShape>
              control={control}
              fieldName="transferToNewDraft"
              label="Transfer to a new draft entity"
              labelPosition="right"
            />
          </FormLayoutItem>
          {!sourceDisabled && !targetDisabled && (
            <FormLayoutItem width={4} sx={{ textAlign: 'right' }}>
              <Box>
                <LinkButton
                  onClick={reverseTransfer}
                  display="Reverse transfer"
                />
              </Box>
            </FormLayoutItem>
          )}
        </FormLayoutRow>
        {transferToNewDraft && (
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              <Card variant="filled-dark" sx={{ p: 3 }}>
                <FormLayoutRow>
                  <FormLayoutItem>
                    <Typography variant="h4" component="span">
                      New draft entity
                    </Typography>
                  </FormLayoutItem>
                </FormLayoutRow>
                <FormLayoutRow>
                  <FormLayoutItem>
                    <FormAwareSelectInput<HypotheticalTransferFormModalShape>
                      control={control}
                      fieldName="draftEntityType"
                      required
                      label="Entity type"
                      options={draftEntityOptions}
                    />
                  </FormLayoutItem>
                </FormLayoutRow>
                <FormLayoutRow>
                  <FormLayoutItem width={6}>
                    <FormAwareTextInput<HypotheticalTransferFormModalShape>
                      control={control}
                      fieldName="draftEntityName"
                      label="Name of draft entity"
                      required
                    />
                  </FormLayoutItem>
                  <FormLayoutItem width={6}>
                    <FormAwareSelectInput<HypotheticalTransferFormModalShape>
                      control={control}
                      fieldName="draftEntityGrantorId"
                      label="Grantor"
                      required
                      options={draftEntityGrantors}
                    />
                  </FormLayoutItem>
                </FormLayoutRow>
              </Card>
            </FormLayoutItem>
          </FormLayoutRow>
        )}
      </Card>
    </>
  );
}
