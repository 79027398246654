import { Box } from '@mui/material';
import Decimal from 'decimal.js';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useState } from 'react';
import { Control } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import {
  EditableList,
  EditableListItem,
} from '@/components/tables/EditableList/EditableList';
import { GRATDesignerTrowser } from '@/modules/entities/gratTrusts/GRATDesignerTrowser/GRATDesignerTrowser';
import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';
import { GratDesignerStages } from '@/navigation/constants';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';

import { FORM_NAME, FormShape } from '../form/constants';
import { DisplayEntities, DisplayEntity } from '../hooks/useClientEntities';
import { EditEntitiesModal } from '../modals/EditGratsModal/EditEntitiesModal';

interface Props {
  control: Control<FormShape>;
  setEntityIdsInProposal: React.Dispatch<React.SetStateAction<string[]>>;
  displayEntities: DisplayEntities;
  householdId: string;
  entitiesInProposal: Maybe<DisplayEntity>[];
  entityIdsInProposal: string[];
  selectedEntityId: string;
  setSelectedEntityId: React.Dispatch<React.SetStateAction<string>>;
  allowExternalNavigationCallback: (allowNavigation: boolean) => void;
  proposalId: string;
}

export function LeftPaneContent({
  control,
  allowExternalNavigationCallback,
  setEntityIdsInProposal,
  displayEntities,
  householdId,
  entitiesInProposal,
  entityIdsInProposal,
  selectedEntityId,
  setSelectedEntityId,
  proposalId,
}: Props) {
  const [currentlyEditingEntityId, setCurrentlyEditingEntityId] = useState<
    string | null
  >(null);
  const [isEditGratsModalOpen, setIsEditGratsModalOpen] = useState(false);

  return (
    <Box>
      <Box sx={{ pr: 3, pb: 3 }}>
        <FormLayoutRow
          sx={{
            pt: 3,
          }}
        >
          <FormLayoutItem>
            <FormAwareTextInput<FormShape>
              fieldName={`${FORM_NAME}.name`}
              label="Proposal name"
              control={control}
              required
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareTextInput<FormShape>
              fieldName={`${FORM_NAME}.description`}
              label="Proposal description"
              control={control}
              multiline
              rows={4}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      </Box>
      <FormLayoutRow>
        <FormLayoutItem>
          <EditEntitiesModal
            onSubmit={(formValues) => {
              const entitiesInProposal = formValues.entitiesInProposal.map(
                (entity) => entity?.id ?? ''
              );

              setEntityIdsInProposal(entitiesInProposal);
              setIsEditGratsModalOpen(false);
            }}
            onClose={() => setIsEditGratsModalOpen(false)}
            isOpen={isEditGratsModalOpen}
            entitiesInProposal={entitiesInProposal}
            displayEntities={displayEntities}
            householdId={householdId}
          />
          {currentlyEditingEntityId && (
            <GRATDesignerTrowser
              isOpen={!!currentlyEditingEntityId}
              householdId={householdId}
              entityId={currentlyEditingEntityId}
              initialStage={GratDesignerStages.STRUCTURING}
              onAfterUpdate={() => {
                setCurrentlyEditingEntityId(null);
                allowExternalNavigationCallback(false);
              }}
            />
          )}
          <EditableList
            footerButton={
              proposalId !== NEW_PROPOSAL_ID ? undefined : (
                <Button
                  onClick={() => setIsEditGratsModalOpen(true)}
                  variant="secondary"
                  size="sm"
                >
                  Add/remove GRATs
                </Button>
              )
            }
          >
            {displayEntities.map((entity, idx) => {
              if (!entity) {
                return null;
              }

              if (!entityIdsInProposal.includes(entity.id)) {
                return null;
              }

              return (
                <EditableListItem
                  key={`entity-item-${idx}`}
                  title={entity.displayName}
                  lineTwo={entity.description}
                  lineThree={entity.assetSummaryDisplay}
                  lineFour={formatCurrency(
                    entity.computedValue || new Decimal(0)
                  )}
                  onEditClick={() => setCurrentlyEditingEntityId(entity.id)}
                  onClick={() => {
                    allowExternalNavigationCallback(true);
                    setSelectedEntityId(entity.id);
                  }}
                  sx={{
                    backgroundColor:
                      selectedEntityId === entity.id
                        ? COLORS.FUNCTIONAL.HOVER
                        : 'unset',
                    padding: 2,
                    borderRight: `8px solid ${COLORS.NAVY[600]}`,
                  }}
                />
              );
            })}
          </EditableList>
        </FormLayoutItem>
      </FormLayoutRow>
    </Box>
  );
}
