import {
  Edge,
  SectionLabelNode,
  TileNode,
  TileNodeGroup,
} from '@/components/diagrams/FlowChart';
import { AfterDeath } from '@/types/schema';
import { FlowChartGraph } from '@/utils/graphology/FlowChartGraph';

import {
  EstateWaterfall_NodeFragment,
  EstateWaterfallFragment,
} from '../graphql/EstateWaterfall.generated';

export type GraphEdgeType = 'default' | 'tax';
export enum GraphNodeCategorizationType {
  Individual = 'INDIVIDUAL',
  PersonalAccount = 'PERSONAL_ACCOUNT',
  InsuranceAccount = 'INSURANCE_ACCOUNT',
  FamilyGiving = 'FAMILY_GIVING',
  CharitableEntity = 'CHARITABLE_ENTITY',
  EstateTax = 'ESTATE_TAX',
  SectionLabel = 'SECTION_LABEL',
  GroupNode = 'GROUP_NODE',
  GiftTax = 'GIFT_TAX',
  ExternalSource = 'EXTERNAL_SOURCE',
}

export interface MinimumNodeData {
  id: string;
  afterDeath: AfterDeath;
}

export type EstateTaxNodeData = MinimumNodeData;

export type GraphNodeData = EstateWaterfall_NodeFragment | EstateTaxNodeData;

export type GraphNode = TileNode | TileNodeGroup | SectionLabelNode;

export interface EstateWaterfallGraphNodeAttributes<
  Node = GraphNode,
  Data = GraphNodeData,
> {
  node: Node;
  data: Data;
  categorizationType: GraphNodeCategorizationType;
}

export interface EstateWaterfallGraphEdgeAttributes {
  type: GraphEdgeType;
  edge: Edge;
}

export interface EstateWaterfallGraphAttributes {
  firstPrimaryClientDeathId: string;
  waterfall: EstateWaterfallFragment;
}

export type EstateWaterfallGraph = FlowChartGraph<
  EstateWaterfallGraphNodeAttributes,
  EstateWaterfallGraphEdgeAttributes,
  EstateWaterfallGraphAttributes
>;
