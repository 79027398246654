import { Box, Stack, Typography } from '@mui/material';
import { compact } from 'lodash';
import { useMemo } from 'react';

import { SubtleLabelItem } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { Card } from '@/components/layout/Card/Card';
import { GroupedHeaderList } from '@/components/lists/GroupedHeaderList/GroupedHeaderList';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { DispositiveProvisionsListView } from '@/modules/dispositiveProvisions/DispositiveProvisionsListView/DispositiveProvisionsListView';
import { CONTROLLING_PERSONS_LABEL } from '@/modules/entities/EntitySubforms/utils/shared/controllingParties.utils';
import { testamentaryEntityKindToDisplayName } from '@/modules/entities/testamentaryEntities/testamentaryEntities.utils';
import { controllingPartyEntityKindOptions } from '@/modules/entities/testamentaryEntities/TestamentaryEntityForm/TestamentaryEntityForm.constants';
import { TrustAdvisorsList } from '@/modules/trusts/TrustAdvisorsList/TrustAdvisorsList';
import { TrustBeneficiariesList } from '@/modules/trusts/TrustBeneficiariesList/TrustBeneficiariesList';
import { TrustTrusteesList } from '@/modules/trusts/TrustTrusteesList/TrustTrusteesList';

import { TestamentaryEntitySummaryPanel_TestamentaryEntityFragment } from './graphql/TestamentaryEntitySummaryPanel.generated';

export enum TestamentaryEntityTabs {
  Summary = 'SUMMARY',
  Beneficiaries = 'BENEFICIARIES',
  Trustees = 'TRUTEES',
  Disposition = 'DISPOSITION',
}

interface Props {
  testamentaryEntity: TestamentaryEntitySummaryPanel_TestamentaryEntityFragment;
  hasAliveSpouse: boolean;
  taxStatus: string;
  firstDeathPrimaryClientId: string;
  assetLocation: string | null;
}

const controllingPartySet = new Set(controllingPartyEntityKindOptions);

export function useTestamentaryEntityPanels({
  testamentaryEntity,
  hasAliveSpouse,
  taxStatus,
  firstDeathPrimaryClientId,
  assetLocation,
}: Props) {
  const {
    kind,
    grantorDeath,
    displayName,
    beneficiaries,
    beneficiariesAfterBothGrantorDeaths,
    trustees,
    successorTrustees,
    trustAdvisors,
    controllingParties,
    description,
  } = testamentaryEntity;

  return useMemo(() => {
    const isControllingPartyEntity = controllingPartySet.has(kind);

    return compact([
      {
        display: 'Summary',
        value: TestamentaryEntityTabs.Summary,
        component: function Summary() {
          return (
            <Box>
              <Stack spacing={2}>
                <SubtleLabelItem label="Legal name" value={displayName} />
                <SubtleLabelItem
                  label="Entity type"
                  value={testamentaryEntityKindToDisplayName(
                    kind,
                    grantorDeath?.displayName
                  )}
                />
                <SubtleLabelItem
                  label="Created upon death of"
                  value={grantorDeath?.displayName ?? EMPTY_CONTENT_HYPHEN}
                />
                <SubtleLabelItem label="Tax status" value={taxStatus} />
                <SubtleLabelItem label="Asset location" value={assetLocation} />
              </Stack>
              {description && (
                <Box mt={3}>
                  <Typography variant="body2">{description}</Typography>
                </Box>
              )}
            </Box>
          );
        },
      },
      {
        display: 'Beneficiaries',
        value: TestamentaryEntityTabs.Beneficiaries,
        component: function Beneficiaries() {
          return (
            <TrustBeneficiariesList
              isTestamentaryEntity={true}
              beneficiariesGroups={
                hasAliveSpouse
                  ? [
                      {
                        heading: 'After first death',
                        items: beneficiaries || [],
                        noItemsText: 'No beneficiaries after first death',
                      },
                      {
                        heading: 'After second death',
                        items: beneficiariesAfterBothGrantorDeaths || [],
                        noItemsText: 'No beneficiaries after second death',
                      },
                    ]
                  : [
                      {
                        heading: undefined,
                        items: beneficiaries || [],
                      },
                    ]
              }
            />
          );
        },
      },
      {
        display: isControllingPartyEntity ? 'Controlling parties' : 'Trustees',
        value: TestamentaryEntityTabs.Trustees,
        component: function Trustees() {
          if (isControllingPartyEntity) {
            return (
              <Stack>
                <GroupedHeaderList<{ id: string; heading: string }>
                  rowGroups={[
                    {
                      heading: CONTROLLING_PERSONS_LABEL,
                      items:
                        controllingParties?.map((cp) => ({
                          id: cp.id,
                          heading:
                            cp.individual?.legalName ??
                            cp.organization?.name ??
                            EMPTY_CONTENT_HYPHEN,
                        })) ?? [],
                      noItemsText: 'No controlling parties',
                    },
                  ]}
                  ItemComponent={({ item }) => <RichListItem {...item} />}
                />
              </Stack>
            );
          }

          return (
            <Stack spacing={3}>
              <TrustTrusteesList
                trustees={trustees ?? []}
                successorTrustees={successorTrustees ?? []}
              />
              {trustAdvisors && trustAdvisors.length > 0 && (
                <Card variant="filled-dark" sx={{ p: 3 }}>
                  <TrustAdvisorsList trustAdvisors={trustAdvisors} />
                </Card>
              )}
            </Stack>
          );
        },
      },
      {
        display: 'Dispositions',
        value: TestamentaryEntityTabs.Disposition,
        component: function Dispositions() {
          return (
            <DispositiveProvisionsListView
              firstDeathPrimaryClientId={firstDeathPrimaryClientId}
            />
          );
        },
      },
    ]);
  }, [
    assetLocation,
    beneficiaries,
    beneficiariesAfterBothGrantorDeaths,
    controllingParties,
    description,
    displayName,
    firstDeathPrimaryClientId,
    grantorDeath?.displayName,
    hasAliveSpouse,
    kind,
    successorTrustees,
    taxStatus,
    trustAdvisors,
    trustees,
  ]);
}
