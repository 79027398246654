import {
  lighten,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  useTheme,
} from '@mui/material';
import { MouseEvent, MouseEventHandler, ReactNode, useState } from 'react';

import {
  Button,
  ButtonProps,
} from '@/components/form/baseInputs/Button/Button';
import { ChevronDownIcon } from '@/components/icons/ChevronDownIcon';
import { ChevronUpIcon } from '@/components/icons/ChevronUpIcon';
import { generateUniqueId } from '@/components/utils/inputUtils';

export interface SplitButtonProps extends ButtonProps {
  buttonContent: string | ReactNode;
  ariaLabel?: string;
  items: {
    name?: string;
    component?: ReactNode;
    clickHandler?: () => void;
    menuItemSx?: SxProps;
  }[];
  onClick: MouseEventHandler;
  menuDirection?: 'top' | 'bottom';
  htmlId: string;
  mainButtonTestId?: string;
  caretButtonTestId?: string;
}

export const SplitButton = ({
  buttonContent,
  ariaLabel,
  items,
  htmlId,
  onClick,
  menuDirection = 'top',
  loading,
  mainButtonTestId,
  caretButtonTestId,
  ...rest
}: SplitButtonProps) => {
  const theme = useTheme();
  const menuId = generateUniqueId(`${htmlId}SplitMenu_`);
  const buttonId = generateUniqueId(`${htmlId}SplitButton_`);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonSx: SxProps = {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  };

  const shouldShowDisabledBorder = rest.disabled || loading;

  if (['secondary', 'secondary-filled'].includes(rest.variant)) {
    // secondary and secondary-filled look good with the default border, so don't override it
  } else {
    buttonSx.borderRightStyle = 'solid';
    buttonSx.borderRightWidth = '1px';
    if (
      ['destructive-prominent', 'primary'].includes(rest.variant) &&
      !shouldShowDisabledBorder
    ) {
      // variants with a dark background have the same color for borders and
      // backgrounds, so use a slightly lighter color for a subtle border
      buttonSx.borderRightColor = lighten(theme.palette.buttons.main, 0.25);
    }
  }

  const ChevronIcon = open ? ChevronUpIcon : ChevronDownIcon;

  return (
    <>
      <Stack direction="row">
        <Button
          id={buttonId}
          sx={buttonSx}
          aria-controls={open ? menuId : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : 'false'}
          onClick={onClick}
          aria-label={ariaLabel ?? ''}
          loading={loading}
          data-testid={mainButtonTestId}
          {...rest}
        >
          {buttonContent}
        </Button>
        <Button
          onClick={handleClick}
          // we don't want the small button to also show the loading state;
          // just make it disabled
          disabled={rest.disabled || loading}
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: 'none',
            px: 1,
          }}
          data-testid={caretButtonTestId}
          {...rest}
        >
          <ChevronIcon />
        </Button>
      </Stack>

      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: menuDirection }}
        transformOrigin={{
          horizontal: 'right',
          vertical: menuDirection === 'top' ? 'bottom' : 'top',
        }}
      >
        {items.map((item, idx) => {
          return (
            <MenuItem
              key={`menu-item-${idx}`}
              onClick={() => {
                item.clickHandler && item.clickHandler();
                handleClose();
              }}
              sx={item.menuItemSx}
            >
              {item.name ? <ListItemText>{item.name}</ListItemText> : null}
              {item.component ? item.component : null}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
