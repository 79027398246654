import { EntityKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import type { EntityType } from '../types/EntityType';

export function getEntityKindFromEntityType(
  entityType: EntityType
): EntityKind {
  switch (entityType) {
    case 'grat':
      return EntityKind.GratTrust;
    case 'slat':
      return EntityKind.SlatTrust;
    case 'ilit':
      return EntityKind.IlitTrust;
    case 'irrevocable-trust':
      return EntityKind.IrrevocableTrust;
    case 'revocable-trust':
      return EntityKind.RevocableTrust;
    case 'qprt':
      return EntityKind.QprtTrust;
    case 'individual-account':
      return EntityKind.IndividualPersonalAccount;
    case 'joint-account':
      return EntityKind.JointPersonalAccount;
    case 'retirement-account':
      return EntityKind.RetirementPersonalAccount;
    case 'qualified-tuition-account':
      return EntityKind.QualifiedTuitionPersonalAccount;
    case 'custodial-account':
      return EntityKind.CustodialPersonalAccount;
    case 'clt':
      return EntityKind.CltTrust;
    case 'crt':
      return EntityKind.CrtTrust;
    case 'daf':
      return EntityKind.DonorAdvisedFund;
    case 'private-foundation':
      return EntityKind.PrivateFoundation;
    case 'sole-proprietorship':
      return EntityKind.SoleProprietorshipBusinessEntity;
    case 'c-corp':
      return EntityKind.CcorpBusinessEntity;
    case 's-corp':
      return EntityKind.ScorpBusinessEntity;
    case 'llc':
      return EntityKind.LlcBusinessEntity;
    case 'lp':
      return EntityKind.LpBusinessEntity;
    case 'gp':
      return EntityKind.GpBusinessEntity;
    case 'insurance-account':
      return EntityKind.InsurancePersonalAccount;
    default:
      throw new UnreachableError({
        case: entityType,
        message: 'Unexpected entity type',
      });
  }
}
