// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrummeyLetterTaskMutationMutationVariables = Types.Exact<{
  updateEntityTaskInput: Types.AugmentedUpdateEntityTaskInput;
  updateEntityInput: Types.AugmentedUpdateEntityInput;
}>;


export type CrummeyLetterTaskMutationMutation = { __typename?: 'Mutation', updateEntityTask: { __typename?: 'EntityTask', id: string }, updateEntity: { __typename?: 'Entity', id: string } };


export const CrummeyLetterTaskMutationDocument = gql`
    mutation CrummeyLetterTaskMutation($updateEntityTaskInput: AugmentedUpdateEntityTaskInput!, $updateEntityInput: AugmentedUpdateEntityInput!) {
  updateEntityTask(input: $updateEntityTaskInput) {
    id
  }
  updateEntity(input: $updateEntityInput) {
    id
  }
}
    `;
export type CrummeyLetterTaskMutationMutationFn = Apollo.MutationFunction<CrummeyLetterTaskMutationMutation, CrummeyLetterTaskMutationMutationVariables>;

/**
 * __useCrummeyLetterTaskMutationMutation__
 *
 * To run a mutation, you first call `useCrummeyLetterTaskMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCrummeyLetterTaskMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [crummeyLetterTaskMutationMutation, { data, loading, error }] = useCrummeyLetterTaskMutationMutation({
 *   variables: {
 *      updateEntityTaskInput: // value for 'updateEntityTaskInput'
 *      updateEntityInput: // value for 'updateEntityInput'
 *   },
 * });
 */
export function useCrummeyLetterTaskMutationMutation(baseOptions?: Apollo.MutationHookOptions<CrummeyLetterTaskMutationMutation, CrummeyLetterTaskMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CrummeyLetterTaskMutationMutation, CrummeyLetterTaskMutationMutationVariables>(CrummeyLetterTaskMutationDocument, options);
      }
export type CrummeyLetterTaskMutationMutationHookResult = ReturnType<typeof useCrummeyLetterTaskMutationMutation>;
export type CrummeyLetterTaskMutationMutationResult = Apollo.MutationResult<CrummeyLetterTaskMutationMutation>;
export type CrummeyLetterTaskMutationMutationOptions = Apollo.BaseMutationOptions<CrummeyLetterTaskMutationMutation, CrummeyLetterTaskMutationMutationVariables>;
export const Operations = {
  Mutation: {
    CrummeyLetterTaskMutation: 'CrummeyLetterTaskMutation' as const
  }
}