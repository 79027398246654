import { gridStringOrNumberComparator } from '@mui/x-data-grid-pro';

import { EditButtonRenderer } from '@/components/tables/DataTable/renderers/cell/EditButtonRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { GiftingProposalPortfolioPortfolioType } from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { portfolioTypeOptions } from '../OutOfEstatePortfolioFormModal/OutOfEstatePortfolioFormModal.utils';
import { OutOfEstatePortfolioRow } from './OutOfEstatePortfoliosTable.types';

function mapPortfolioTypeToDisplay(
  type: GiftingProposalPortfolioPortfolioType | ''
) {
  return portfolioTypeOptions.find((o) => o.value === type)?.display ?? '';
}

export const columns: Column<OutOfEstatePortfolioRow>[] = [
  {
    headerName: 'Out of estate portfolio',
    field: 'name',
    flex: 1,
    renderCell: TextRenderer({
      text: ({ row }) => row.name,
    }),
  },
  {
    headerName: 'Type',
    field: 'type',
    flex: 1,
    align: 'right',
    renderCell: TextRenderer({
      text: ({ row }) => mapPortfolioTypeToDisplay(row.type),
    }),
  },
  {
    headerName: 'Value',
    field: 'value',
    flex: 1,
    align: 'right',
    renderCell: TextRenderer({
      text: ({ row }) =>
        row.value ? formatCurrencyNoDecimals(row.value) : EMPTY_CONTENT_HYPHEN,
    }),
    sortComparator: (
      v1: OutOfEstatePortfolioRow['value'],
      v2: OutOfEstatePortfolioRow['value'],
      p1,
      p2
    ) => {
      return gridStringOrNumberComparator(v1, v2, p1, p2);
    },
  },
  {
    field: 'edit',
    headerName: '',
    width: 20,
    align: 'center',
    sortable: false,
    renderCell: EditButtonRenderer({
      editButtonProps: {
        buttonProps: {
          variant: 'transparent',
        },
        handleClick: (row) => {
          row.handleEdit();
        },
      },
    }),
  },
];
