// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FinalizeImplementationMutationVariables = Types.Exact<{
  taskID: Types.Scalars['ID']['input'];
  input: Types.AugmentedUpdateEntityInput;
}>;


export type FinalizeImplementationMutation = { __typename?: 'Mutation', completeEntityTask: { __typename?: 'EntityTask', id: string }, updateEntity: { __typename?: 'Entity', id: string, stage: Types.EntityStage } };


export const FinalizeImplementationDocument = gql`
    mutation FinalizeImplementation($taskID: ID!, $input: AugmentedUpdateEntityInput!) {
  completeEntityTask(id: $taskID) {
    id
  }
  updateEntity(input: $input) {
    id
    stage
  }
}
    `;
export type FinalizeImplementationMutationFn = Apollo.MutationFunction<FinalizeImplementationMutation, FinalizeImplementationMutationVariables>;

/**
 * __useFinalizeImplementationMutation__
 *
 * To run a mutation, you first call `useFinalizeImplementationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeImplementationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeImplementationMutation, { data, loading, error }] = useFinalizeImplementationMutation({
 *   variables: {
 *      taskID: // value for 'taskID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinalizeImplementationMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeImplementationMutation, FinalizeImplementationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeImplementationMutation, FinalizeImplementationMutationVariables>(FinalizeImplementationDocument, options);
      }
export type FinalizeImplementationMutationHookResult = ReturnType<typeof useFinalizeImplementationMutation>;
export type FinalizeImplementationMutationResult = Apollo.MutationResult<FinalizeImplementationMutation>;
export type FinalizeImplementationMutationOptions = Apollo.BaseMutationOptions<FinalizeImplementationMutation, FinalizeImplementationMutationVariables>;
export const Operations = {
  Mutation: {
    FinalizeImplementation: 'FinalizeImplementation' as const
  }
}