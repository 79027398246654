import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { first, isUndefined, last } from 'lodash';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';
import {
  DispositiveProvisionDispositionKind,
  DispositiveProvisionTemplateKind,
  DispositiveProvisionTransferTaxKind,
} from '@/types/schema';

import { DISPOSITIVE_PROVISIONS_FORM_NAMESPACE } from '../../DispositiveProvisionsForm/DispositiveProvisionsForm.constants';
import { Recipient } from '../../DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import { DraggableListItemRecipient } from '../../DispositiveProvisionsForm/DispositiveProvisionsFormRecipients/DraggableListItemRecipient';
import { SURVIVING_SPOUSE_SENTINEL } from '../../DispositiveProvisionsForm/hooks/useRecipientOptions';
import { BLANK_DISCLAIMER_TRUST } from '../DispositiveProvisionsTemplateSplitScreenModal.constants';
import {
  DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE,
  DispositiveProvisionsTemplateShape,
} from '../DispositiveProvisionsTemplateSplitScreenModal.types';
import { DisclaimerTrust } from './DispositiveProvisionsTemplateSplitScreenModal.components';

interface OutrightToSpouseProvisionProps {
  index: number;
}

function OutrightToSpouseProvision({ index }: OutrightToSpouseProvisionProps) {
  return (
    <DraggableListItemRecipient
      index={index}
      Draghandle={null}
      simulatedValue={null}
      onRemove={null}
      defaultExpanded
      templateType={DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse}
      emptyRecipientOptionDisplay="Select spouse"
      recipientConfiguration={{
        forceDefaultRecipient: true,
      }}
    />
  );
}

function modifyRecipientsOnIncludeDisclaimerTrustChange(
  recipients: Recipient[],
  includeDisclaimerTrust: boolean
): Recipient[] {
  // when adding a disclaimer trust:
  // - prepend a disclaimer trust
  // - reset the payout to anything left over
  if (includeDisclaimerTrust) {
    const newRecipient: Recipient = {
      ...(first(recipients) ||
        BLANK_OUTRIGHT_TO_SURVIVING_SPOUSE_FOR_DISCLAIMER),
      dispositionKind_ButtonGroup: 'other',
      dispositionKind_Select:
        DispositiveProvisionDispositionKind.AnythingLeftOver,
      dispositionPercentage: null,
    };
    return [{ ...BLANK_DISCLAIMER_TRUST }, newRecipient];
  } else {
    // when removing a disclaimer trust:
    // - remove the first item (disclaimer trust)
    // - reset the payout to 100%
    const existingRecipient: Recipient = {
      ...(last(recipients) || BLANK_OUTRIGHT_TO_SURVIVING_SPOUSE),
      dispositionKind_ButtonGroup:
        DispositiveProvisionDispositionKind.Percentage,
      dispositionKind_Select: '',
      dispositionPercentage: new Decimal(100),
    };
    return [existingRecipient];
  }
}

export function DispositiveProvisionsTemplateOutrightToSurvivingSpousePreset() {
  const { control, setValue } =
    useFormContext<DispositiveProvisionsTemplateShape>();
  const [recipients, includeDisclaimerTrust] = useWatch({
    control,
    name: [
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients`,
      `${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.includeDisclaimerTrust`,
    ],
  });

  const includeDisclaimerTrustPrevious = usePrevious(includeDisclaimerTrust);

  useEffect(() => {
    if (isUndefined(includeDisclaimerTrustPrevious)) return;
    if (includeDisclaimerTrustPrevious === includeDisclaimerTrust) return;

    const newRecipients = modifyRecipientsOnIncludeDisclaimerTrustChange(
      recipients,
      includeDisclaimerTrust
    );
    setValue(
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients`,
      newRecipients
    );
  }, [
    includeDisclaimerTrust,
    includeDisclaimerTrustPrevious,
    recipients,
    setValue,
  ]);

  return (
    <Stack direction="column" spacing={3}>
      <Card variant="filled-callout" sx={{ padding: 3 }}>
        <FormAwareSwitch<DispositiveProvisionsTemplateShape>
          control={control}
          label="Include disclaimer trust"
          fieldName={`${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.includeDisclaimerTrust`}
          labelPosition="right"
        />
      </Card>
      {includeDisclaimerTrust && recipients.length > 1 ? ( // need to check for length so it renders after the useEffect call above
        <>
          <DisclaimerTrust index={0} />
          <OutrightToSpouseProvision index={1} />
        </>
      ) : (
        <OutrightToSpouseProvision index={0} />
      )}
    </Stack>
  );
}

export const BLANK_OUTRIGHT_TO_SURVIVING_SPOUSE: Recipient = {
  _dispositiveProvisionId: null,
  recipientId: SURVIVING_SPOUSE_SENTINEL,
  recipientKind: null,
  notes: '',
  dispositionAmount: null,
  dispositionKind_ButtonGroup: DispositiveProvisionDispositionKind.Percentage,
  dispositionKind_Select: '',
  dispositionPercentage: new Decimal(100),
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion,
};

const BLANK_OUTRIGHT_TO_SURVIVING_SPOUSE_FOR_DISCLAIMER: Recipient = {
  _dispositiveProvisionId: null,
  recipientId: '', // blank string instead of null to avoid MUI warning
  recipientKind: null,
  notes: '',
  dispositionAmount: null,
  dispositionKind_ButtonGroup: 'other',
  dispositionKind_Select: DispositiveProvisionDispositionKind.AnythingLeftOver,
  dispositionPercentage: null,
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion,
};
