import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import { FormAwareMultiSelectInput } from '@/components/form/formAwareInputs/FormAwareMultiSelectInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { BENEFICIARY_LEVEL_ITEMS } from '@/modules/beneficiaries/beneficiaries.constants';
import { EntityMemberLevel } from '@/modules/content/tooltipContent/EntityMemberLevel';
import { TrustSuccessorTrustees } from '@/modules/content/tooltipContent/TrustSuccessorTrustees';
import { TrusteeDropdown } from '@/modules/entities/inputs/TrusteeDropdown/TrusteeDropdown';
import {
  emptySuccessorTrusteeValue,
  Fields,
  SubformField,
  TRUST_DETAILS_SUBFORM_NAMESPACE,
  TrustDetailsSubformType,
  TrustPersonDropdownFieldProps,
  TrustPersonMultiSelectFieldProps,
  TrustPersonRepeaterProps,
} from '@/modules/entities/TrustDetailsSubform/TrustDetailsSubform.types';
import { TRUSTEE_DUTY_KIND_ITEMS } from '@/modules/trustees/trustees.constants';
import { AiSuggestionKind } from '@/types/schema';

const SuccessorTrusteeDropdownField = ({
  index,
  hideLabel,
  trustPersonOptions,
  householdId,
  labelIconEnd,
  splitScreen,
  additionalItemRendererProps,
}: TrustPersonDropdownFieldProps) => {
  return (
    <TrusteeDropdown
      fieldName={
        `${TRUST_DETAILS_SUBFORM_NAMESPACE}.successorTrustees.${index}.successorTrusteeId` as const satisfies SubformField
      }
      label={'Successor trustees'}
      hideLabel={hideLabel}
      options={trustPersonOptions}
      contextualHelp={<TrustSuccessorTrustees />}
      householdId={householdId}
      labelIconEnd={labelIconEnd}
      suggestionKind={AiSuggestionKind.Trustee}
      blockNavigationAfterCreate={splitScreen}
      additionalItemRendererProps={additionalItemRendererProps}
    />
  );
};

const SuccessorTrusteeLevelsField = ({
  index,
  hideLabel,
}: TrustPersonMultiSelectFieldProps) => {
  const { control } = useFormContext<Fields>();

  const isFirst = index === 0;

  return (
    <FormAwareSelectInput<Fields>
      fieldName={
        `${TRUST_DETAILS_SUBFORM_NAMESPACE}.successorTrustees.${index}.level` as const satisfies SubformField
      }
      label={'Ordering'}
      hideLabel={hideLabel}
      contextualHelp={isFirst ? <EntityMemberLevel /> : undefined}
      control={control}
      options={BENEFICIARY_LEVEL_ITEMS}
    />
  );
};

const SuccessorTrusteeDutiesField = ({
  index,
  hideLabel,
}: TrustPersonMultiSelectFieldProps) => {
  const { control } = useFormContext<Fields>();

  return (
    <FormAwareMultiSelectInput<Fields>
      fieldName={
        `${TRUST_DETAILS_SUBFORM_NAMESPACE}.successorTrustees.${index}.duties` as const satisfies SubformField
      }
      label={'Duties'}
      hideLabel={hideLabel}
      control={control}
      options={TRUSTEE_DUTY_KIND_ITEMS}
    />
  );
};

export const SuccessorTrusteeInputRepeater = ({
  trusteesWithoutDuties,
  trustPersonOptions,
  householdId,
  control,
  splitScreen,
  shouldGroupDuplicates,
}: TrustPersonRepeaterProps) => {
  type RepeatedFormType = TrustDetailsSubformType['successorTrustees'][0];

  const name =
    `${TRUST_DETAILS_SUBFORM_NAMESPACE}.successorTrustees` as const satisfies SubformField;

  return (
    <FormAwareInputRepeater<RepeatedFormType>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- linter refactor
      control={control as any}
      name={name}
      emptyValue={emptySuccessorTrusteeValue}
      shouldInit
      addAnotherItemText="Add additional successor trustee"
      render={(i: number, _props, _id, additionalItemRendererProps) => {
        const hideLabel =
          additionalItemRendererProps?.hideLabelOverride ?? i > 0;
        return (
          <FormLayoutRow>
            <FormLayoutItem width={trusteesWithoutDuties ? 9 : 5}>
              <SuccessorTrusteeDropdownField
                index={i}
                hideLabel={hideLabel}
                trustPersonOptions={trustPersonOptions}
                householdId={householdId}
                splitScreen={splitScreen}
                additionalItemRendererProps={additionalItemRendererProps}
              />
            </FormLayoutItem>
            <FormLayoutItem width={3} sx={{ alignSelf: 'end' }}>
              <SuccessorTrusteeLevelsField index={i} hideLabel={hideLabel} />
            </FormLayoutItem>
            {!trusteesWithoutDuties && (
              <FormLayoutItem width={4} sx={{ alignSelf: 'end' }}>
                <SuccessorTrusteeDutiesField index={i} hideLabel={hideLabel} />
              </FormLayoutItem>
            )}
          </FormLayoutRow>
        );
      }}
      idField="successorTrusteeId"
      shouldGroupDuplicates={shouldGroupDuplicates}
      duplicateGroupLabel="Duplicate successor trustee from AI"
      duplicateGroupCaption="Decide which successor trustee's details to retain"
    />
  );
};
