import { Stack, SxProps } from '@mui/material';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { Card } from '@/components/layout/Card/Card';
import { LuminaryAiBetaLogo } from '@/modules/documents/components/LuminaryAiLogo';

interface LuminaryAIBannerCardProps {
  bannerText: React.ReactNode;
  primaryButtonProps: ButtonProps;
  secondaryButtonProps?: ButtonProps;
  cardSx?: SxProps;
}

/**
 * This component is used to display a banner related to AI activities. Includes
 * a teal background, the Luminary AI logo, and props for the banner text and
 * primary button.
 *
 * @param bannerText
 * @param primaryButtonProps
 * @param secondaryButtonProps
 * @param cardSx
 * @constructor
 */
export function LuminaryAIBannerCard({
  bannerText,
  primaryButtonProps,
  secondaryButtonProps,
  cardSx = {},
}: LuminaryAIBannerCardProps) {
  return (
    <Card variant="filled-teal" sx={{ p: 3, flexShrink: 0, ...cardSx }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <LuminaryAiBetaLogo />
          {bannerText}
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          {secondaryButtonProps && <Button {...secondaryButtonProps} />}
          <Button {...primaryButtonProps} />
        </Stack>
      </Stack>
    </Card>
  );
}
