import { TileVariant } from '@/components/diagrams/components/Tile/types';
import { TileNodeProps } from '@/components/diagrams/FlowChart/nodes/TileNode';
import { BankIcon } from '@/components/icons/BankIcon';
import { User01Icon } from '@/components/icons/User01Icon';
import { EntityKind, TestamentaryEntityKind } from '@/types/schema';

import { EstateWaterfall_NodeFragment } from '../graphql/EstateWaterfall.generated';
import { GraphNodeCategorizationType } from '../types';

export const DEFAULT_SECTION_GROUP_HEIGHT = 1;

const ValidTypenames = [
  'TestamentaryEntity',
  'Entity',
  'ClientProfile',
  'ClientOrganization',
] satisfies NonNullable<EstateWaterfall_NodeFragment['node']['__typename']>[];

export const ValidTypeNamesSet = new Set(ValidTypenames);

export type ValidTypeName = (typeof ValidTypenames)[number];
export type EntityNodeKind = EntityKind | TestamentaryEntityKind;
export type EntityNode = Extract<
  EstateWaterfall_NodeFragment['node'],
  { __typename?: ValidTypeName }
>;

export const ESTATE_TAX_NODE_IDENTIFIER = 'ESTATE_TAX_NODE';
export const EXTERNAL_TRANSFER_NODE_IDENTIFIER = 'EXTERNAL_TRANSFER_NODE';
export const UI_ONLY_GROUP_NODE_IDENTIFIER = 'UI_ONLY_GROUP_NODE';
export const GIFT_TAX_NODE_IDENTIFIER = 'GIFT_TAX_NODE';

export const kindToCategorizationType: Record<
  EntityNodeKind,
  GraphNodeCategorizationType | null
> = {
  CCORP_BUSINESS_ENTITY: null,
  GP_BUSINESS_ENTITY: null,
  LLC_BUSINESS_ENTITY: null,
  LP_BUSINESS_ENTITY: null,
  SOLE_PROPRIETORSHIP_BUSINESS_ENTITY: null,
  SCORP_BUSINESS_ENTITY: null,

  // Entities
  CLT_TRUST: GraphNodeCategorizationType.CharitableEntity,
  CRT_TRUST: GraphNodeCategorizationType.CharitableEntity,
  CUSTODIAL_PERSONAL_ACCOUNT: GraphNodeCategorizationType.PersonalAccount,
  DONOR_ADVISED_FUND: GraphNodeCategorizationType.CharitableEntity,
  GRAT_TRUST: GraphNodeCategorizationType.FamilyGiving,
  ILIT_TRUST: GraphNodeCategorizationType.FamilyGiving,
  INDIVIDUAL_PERSONAL_ACCOUNT: GraphNodeCategorizationType.PersonalAccount,
  IRREVOCABLE_TRUST: GraphNodeCategorizationType.FamilyGiving,
  JOINT_PERSONAL_ACCOUNT: GraphNodeCategorizationType.PersonalAccount,
  INSURANCE_PERSONAL_ACCOUNT: GraphNodeCategorizationType.InsuranceAccount,
  PRIVATE_FOUNDATION: GraphNodeCategorizationType.CharitableEntity,
  QPRT_TRUST: GraphNodeCategorizationType.FamilyGiving,
  QUALIFIED_TUITION_PERSONAL_ACCOUNT:
    GraphNodeCategorizationType.PersonalAccount,
  RETIREMENT_PERSONAL_ACCOUNT: GraphNodeCategorizationType.PersonalAccount,
  REVOCABLE_TRUST: GraphNodeCategorizationType.FamilyGiving,
  SLAT_TRUST: GraphNodeCategorizationType.FamilyGiving,

  // Testamentary Entities
  CHARITABLE_ENTITY: GraphNodeCategorizationType.CharitableEntity,
  CHARITABLE_TRUST: GraphNodeCategorizationType.CharitableEntity,
  GRANTOR_ESTATE: GraphNodeCategorizationType.FamilyGiving,
  INHERITED_RETIREMENT_ACCOUNT: GraphNodeCategorizationType.PersonalAccount,
  TESTAMENTARY_TRUST: GraphNodeCategorizationType.FamilyGiving,
};

export const categorizationTypeToIcon: Partial<
  Record<GraphNodeCategorizationType, typeof User01Icon>
> = {
  PERSONAL_ACCOUNT: BankIcon,
  INDIVIDUAL: User01Icon,
};

export const categorizationTypeToVariant: Record<
  GraphNodeCategorizationType,
  NonNullable<TileNodeProps['data']['variant']>
> = {
  [GraphNodeCategorizationType.CharitableEntity]: TileVariant.Tertiary,
  [GraphNodeCategorizationType.EstateTax]: TileVariant.Destructive,
  [GraphNodeCategorizationType.FamilyGiving]: TileVariant.Secondary,
  [GraphNodeCategorizationType.PersonalAccount]: TileVariant.Secondary,
  [GraphNodeCategorizationType.InsuranceAccount]: TileVariant.Secondary,
  [GraphNodeCategorizationType.Individual]: TileVariant.Secondary,
  [GraphNodeCategorizationType.GroupNode]: TileVariant.Group,
  [GraphNodeCategorizationType.SectionLabel]: TileVariant.Light, // not actually used
  [GraphNodeCategorizationType.GiftTax]: TileVariant.Destructive,
  [GraphNodeCategorizationType.ExternalSource]: TileVariant.Primary,
};

export const DEATH_PAYOUT_ENTITY_KINDS = [
  EntityKind.IlitTrust,
  EntityKind.InsurancePersonalAccount,
];
