import { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export interface TextEditorContext {
  theme: 'userGeneratedContent' | 'contextContent';
  setTheme: Dispatch<SetStateAction<TextEditorContext['theme']>>;
}

export const TextEditorContext = createContext<TextEditorContext>({
  theme: 'userGeneratedContent',
  setTheme: () => null,
});

export const useTextEditorContext = () => {
  return useGuardedContext(TextEditorContext, 'TextEditorProvider');
};
