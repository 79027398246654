import { Button } from '@/components/form/baseInputs/Button';
import { Download01Icon } from '@/components/icons/Download01Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { $downloadFileFromURL } from '@/modules/files/utils/fileUtils';

import { useDownloadGiftExportMutation } from './graphql/DownloadGiftingCSVButton.generated';

export interface DownloadGiftingCSVButtonProps {
  householdId: string;
}

export function DownloadGiftingCSVButton({
  householdId,
}: DownloadGiftingCSVButtonProps) {
  const { showFeedback } = useFeedback();
  const trackUserEvent = useTrackUserEvent();
  const { createErrorFeedback } = useFeedback();
  const [generateCSV] = useDownloadGiftExportMutation({
    variables: {
      householdId,
    },
    onError: createErrorFeedback('Failed to download CSV export'),
    onCompleted: (data) => {
      const downloadUrl =
        data.exportLifetimeExclusionEvents.download.downloadURL;
      try {
        void $downloadFileFromURL(downloadUrl, 'gifting.csv');
        trackUserEvent('gift_list exported', {
          householdId,
        });
      } catch (err) {
        showFeedback('Failed to download CSV. Please try again.');
      }
    },
  });

  return (
    <Button
      variant="secondary"
      size="sm"
      onClick={() => generateCSV()}
      startIcon={Download01Icon}
    >
      Export CSV
    </Button>
  );
}
