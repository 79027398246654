import { useEffect } from 'react';
import { FieldValues, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import { useDebouncedFn } from '@/hooks/useDebouncedFn';

/**
 * @description You should use this hook on forms where you want to trigger field revalidation
 * when other fields change.
 */
export function useDebouncedFormValidationTrigger<
  FormShape extends FieldValues,
>(trigger: UseFormTrigger<FormShape>, watch: UseFormWatch<FormShape>) {
  // debounce the trigger function to avoid hammering the render cycle
  // with every keystroke
  const debouncedTrigger = useDebouncedFn(trigger, 100, {
    leading: false,
    trailing: true,
  });

  useEffect(() => {
    void debouncedTrigger();

    const subscription = watch(() => {
      void debouncedTrigger();
    });
    return () => subscription.unsubscribe();
  }, [debouncedTrigger, watch]);
}
