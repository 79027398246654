import { useApolloClient } from '@apollo/client';
import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useState } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { Settings01Icon } from '@/components/icons/Settings01Icon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { ButtonTab, Tabs } from '@/components/navigation/NavigationTabs';
import { Callout } from '@/components/notifications/Callout/Callout';
import { ProjectedReturn } from '@/modules/content/tooltipContent/ProjectedReturn';
import { ProjectedReturnValue } from '@/modules/content/tooltipContent/ProjectedReturnValue';
import { TaxDragAssumptions } from '@/modules/content/tooltipContent/TaxDragAssumptions';
import { ProposalScenarioProjectionQuery } from '@/modules/proposal/graphql/ProposalScenarioProjections.generated';
import { useProposalForScenario } from '@/modules/proposal/hooks/useProjectionForScenario';
import { COLORS } from '@/styles/tokens/colors';
import {
  EntityProposalAssetValuationProjectionType,
  ProposalScenarioProjectionParamsV2,
} from '@/types/schema';
import { formatPercent } from '@/utils/formatting/percent';

import { TScenario } from '../../form/constants';
import { DisplayEntity } from '../../hooks/useClientEntities';
import { EditAssumptionsForm } from '../../modals/EditAssumptionsModal/constants';
import { Visualization } from './Visualization';

const DEFAULT_POST_TERM_PROJECTED_RATE_OF_RETURN = new Decimal(8);
interface Props<FormShape extends FieldValues> {
  cardActions: JSX.Element[];
  idx: number;
  control: Control<FormShape>;
  id: string;
  formPrefix: string;
  selectedEntity: DisplayEntity;
  scenario: TScenario;
  assumptions: EditAssumptionsForm;
  proposalYearProjectionOptions: {
    display: string;
    valueDecimal: Decimal;
    value: string;
  }[];
  setIsEditAssumptionsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PANEL_WIDTH = 4 as const;
const TABS = [
  {
    display: 'Pre-tax',
    value: 'preTax',
  },
  {
    display: 'After-tax',
    value: 'postTax',
  },
] as const;

export function Scenario<FormShape extends FieldValues>({
  cardActions,
  idx,
  control,
  id,
  assumptions,
  formPrefix,
  selectedEntity,
  scenario,
  proposalYearProjectionOptions,
  setIsEditAssumptionsModalOpen,
}: Props<FormShape>) {
  const client = useApolloClient();
  const {
    id: entityId,
    termDurationYears,
    rollingPeriodYears,
  } = selectedEntity;

  const [currentTab, setCurrentTab] =
    useState<
      keyof Omit<
        ProposalScenarioProjectionQuery['proposalScenarioProjectionV2'],
        '__typename'
      >
    >('preTax');

  let termEndAssetValuationType: EditAssumptionsForm[string]['termEndAssetValuationType'] =
    EntityProposalAssetValuationProjectionType.ProjectedRateOfReturn;

  const assumption = assumptions[entityId];
  if (!assumption) {
    throw new Error(`Expected assumption for entityId: ${entityId}`);
  }

  termEndAssetValuationType = assumption.termEndAssetValuationType;

  const queryInputs: ProposalScenarioProjectionParamsV2 = {
    entityID: entityId,
    taxDragEstimate: assumption.taxDragPercent,
    projectionTimelineYears:
      parseInt(scenario.selectedYearOption) ?? selectedEntity.termDurationYears,
    inTermAnnualRateOfReturn:
      scenario.projectedRateOfReturn ?? selectedEntity.projectedRateOfReturn,
    afterTermAnnualRateOfReturn:
      scenario.preTaxProjectedRateOfReturn ??
      DEFAULT_POST_TERM_PROJECTED_RATE_OF_RETURN,
    endTermProjectedValue:
      scenario.projectedMarketValueAtTerm ??
      selectedEntity.projectedMarketValueAtTerm,
  };

  const maxTimelineYears =
    proposalYearProjectionOptions[
      proposalYearProjectionOptions.length - 1
    ]?.valueDecimal.toNumber();

  const queryInputsMaxTerm: ProposalScenarioProjectionParamsV2 = {
    ...queryInputs,
    entityID: entityId,
    projectionTimelineYears:
      maxTimelineYears ?? selectedEntity.termDurationYears,
  };

  const { noPlan, withPlan, maxProjectionValue } = useProposalForScenario({
    currentTab,
    queryInputs,
    termEndAssetValuationType,
    client,
    queryInputsMaxTerm,
  });

  return (
    <Card>
      <Grid container spacing={0} flex={1} columns={12}>
        <Grid
          bgcolor={`${COLORS.GRAY[50]}`}
          item
          sm={PANEL_WIDTH}
          borderRight={`1px ${COLORS.GRAY[200]} solid}`}
        >
          <Controller
            name={`${formPrefix}._id` as Path<FormShape>}
            control={control}
            defaultValue={id as PathValue<FormShape, Path<FormShape>>}
            render={() => {
              return <input type="hidden" />;
            }}
          />

          <Stack direction="column" p={3} paddingBottom={4}>
            <Stack
              component="header"
              direction="row"
              alignItems="baseline"
              justifyContent="space-between"
            >
              <Typography mb={3} variant="h1" component="span">
                {`Scenario ${idx + 1}`}
              </Typography>

              <ButtonWithPopover
                hideChevron
                size="xs"
                variant="transparent"
                label={<Settings01Icon size={20} />}
                popperVariant="menuBelow"
              >
                <Box>{cardActions}</Box>
              </ButtonWithPopover>
            </Stack>
            <Typography mb={1} variant="h4">
              {`${rollingPeriodYears || termDurationYears}-year return`}
            </Typography>
            <FormLayoutRow>
              {termEndAssetValuationType ===
              EntityProposalAssetValuationProjectionType.ProjectedValue ? (
                <FormLayoutItem width={8}>
                  <FormAwareCurrencyInput
                    control={control}
                    isDecimalJSInput
                    fieldName={`${formPrefix}.projectedMarketValueAtTerm`}
                    label="Asset value"
                    contextualHelp={
                      <ProjectedReturnValue
                        handleClick={() => {
                          setIsEditAssumptionsModalOpen((isOpen) => !isOpen);
                        }}
                      />
                    }
                    required
                  />
                </FormLayoutItem>
              ) : (
                <FormLayoutItem width={8}>
                  <FormAwarePercentInput
                    control={control}
                    label="Annual return"
                    isDecimalJSInput
                    fieldName={`${formPrefix}.projectedRateOfReturn`}
                    fixedDecimalScale={true}
                    maxValue={1000}
                    decimalScale={1}
                    contextualHelp={
                      <ProjectedReturn
                        handleClick={() => {
                          setIsEditAssumptionsModalOpen((isOpen) => !isOpen);
                        }}
                      />
                    }
                    allowNegative
                    required
                  />
                </FormLayoutItem>
              )}
            </FormLayoutRow>
            <Stack pt={3}>
              <Typography mb={1} variant="h4">
                Post-GRAT return
              </Typography>
              <FormLayoutRow>
                <FormLayoutItem width={8}>
                  <FormAwarePercentInput
                    control={control}
                    label="Annual pre-tax return"
                    maxValue={1000}
                    fieldName={`${formPrefix}.preTaxProjectedRateOfReturn`}
                    fixedDecimalScale={true}
                    isDecimalJSInput
                    decimalScale={1}
                    allowNegative
                    contextualHelp={
                      <TaxDragAssumptions
                        handleClick={() => {
                          setIsEditAssumptionsModalOpen((isOpen) => !isOpen);
                        }}
                        taxDrag={queryInputs.taxDragEstimate}
                      />
                    }
                    required
                  />
                </FormLayoutItem>
              </FormLayoutRow>
              <FormLayoutRow>
                <FormLayoutItem width={8}>
                  <Callout severity="info-high">
                    <Typography variant="subtitle2" color={COLORS.BLUE[900]}>
                      Results in{' '}
                      <b>
                        {formatPercent(
                          queryInputs.afterTermAnnualRateOfReturn ??
                            new Decimal(0),
                          1
                        )}
                        %{' '}
                      </b>
                      grantor trust return (out-of estate) and{' '}
                      <b>
                        {formatPercent(
                          (
                            queryInputs.afterTermAnnualRateOfReturn ??
                            new Decimal(0)
                          ).times(
                            queryInputs.afterTermAnnualRateOfReturn.isNegative()
                              ? new Decimal(1).plus(
                                  queryInputs.taxDragEstimate.dividedBy(100)
                                )
                              : new Decimal(1).minus(
                                  queryInputs.taxDragEstimate.dividedBy(100)
                                )
                          ),
                          1
                        )}
                        %{' '}
                      </b>
                      after-tax return (in-estate)
                    </Typography>
                  </Callout>
                </FormLayoutItem>
              </FormLayoutRow>
            </Stack>
          </Stack>
        </Grid>
        <Grid item sm={12 - PANEL_WIDTH}>
          <Stack direction="column" p={2} height={'100%'}>
            <Box mb={2}>
              <Tabs>
                {TABS.map(({ display, value }) => (
                  <ButtonTab
                    key={value}
                    display={display}
                    isActive={currentTab === value}
                    onClick={() => setCurrentTab(value)}
                  />
                ))}
              </Tabs>
            </Box>
            <Visualization
              noPlan={noPlan}
              withPlan={withPlan}
              scenario={currentTab === 'preTax' ? 'pre-tax' : 'post-tax'}
              maxProjectionValue={maxProjectionValue}
            />
            <FormLayoutRow>
              <FormLayoutItem>
                <FormAwareButtonGroup
                  fieldName={`${formPrefix}.selectedYearOption`}
                  label=""
                  required
                  control={control}
                  options={proposalYearProjectionOptions}
                />
              </FormLayoutItem>
            </FormLayoutRow>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}
