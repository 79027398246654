import { Box, Stack } from '@mui/material';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { SetNonNullable, SetRequired } from 'type-fest';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { ButtonWithPopoverProps } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { iconSizeByButtonSize } from '@/components/form/baseInputs/Button/styles';
import { EyeIcon } from '@/components/icons/EyeIcon';
import { Link03Icon } from '@/components/icons/Link03Icon';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { $downloadFileFromURL } from '@/modules/files/utils/fileUtils';
import { DeleteProposalMenuItem } from '@/modules/proposal/components/DeleteProposalMenuItem/DeleteProposalMenuItem';
import { ShareProposalAction } from '@/modules/proposal/share/ShareProposalAction/ShareProposalAction';
import { ProposalKind } from '@/modules/proposal/types/ProposalKind';
import { GiftDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { GiftingProposalStatus } from '@/types/schema';

import { useDuplicateProposal } from '../../../modules/proposal/hooks/useDuplicateProposal';
import { ListClientProposals_ProposalFragment } from '../graphql/ListClientProposals.generated';
import { useDownloadProposal } from './useDownloadProposal';
import { getProposalKind } from './utils';

interface ViewButtonProps {
  proposalId: string;
  householdId: string;
}

function ViewButton(props: ViewButtonProps & Partial<ButtonProps>) {
  const { proposalId, householdId, ...buttonProps } = props;

  const title = buttonProps.disabled
    ? 'Proposal is currently not viewable'
    : 'View proposal';

  return (
    <Tooltip title={title} placement="top">
      <Box component="span">
        <Button
          size="sm"
          variant="secondary"
          square
          onClick={(e) => {
            // prevent triggering the underlying row click
            e.stopPropagation();

            const route = getCompletePathFromRouteKey(
              ROUTE_KEYS.HOUSEHOLD_PROPOSAL_PRESENTATION,
              {
                proposalId,
              },
              {
                preview: true,
                householdId,
              }
            );

            window.open(route, '_blank');
          }}
          {...buttonProps}
        >
          <EyeIcon size={iconSizeByButtonSize.sm} />
        </Button>
      </Box>
    </Tooltip>
  );
}

interface ShareButtonProps {
  proposalId: string;
}

function ShareButton(props: ShareButtonProps & Partial<ButtonProps>) {
  const { proposalId, ...buttonProps } = props;

  const title = buttonProps.disabled
    ? 'Proposal is currently not sharable'
    : 'Copy secure link';

  return (
    <Tooltip title={title} placement="top">
      <Box component="span">
        <ShareProposalAction
          ActionComponent={({ onClick, disabled }) => (
            <Button
              disabled={buttonProps.disabled || disabled}
              size="sm"
              square
              variant="secondary"
              onClick={onClick}
            >
              <Link03Icon size={iconSizeByButtonSize.sm} />
            </Button>
          )}
          proposalId={proposalId}
        />
      </Box>
    </Tooltip>
  );
}

type ContextMenuActionProps = {
  proposalId: string;
  refetch: () => void;
  onExportPDFClick?: () => void;
  onDuplicateProposal?: () => void;
  isPrintingPDF?: boolean;
  isDuplicatingProposal?: boolean;
  kind?: ProposalKind;
} & Partial<ButtonWithPopoverProps>;

function ContextMenuAction({
  proposalId,
  refetch,
  onExportPDFClick,
  onDuplicateProposal,
  isPrintingPDF,
  isDuplicatingProposal,
  kind,
  ...buttonProps
}: ContextMenuActionProps) {
  const canDeleteProposal = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_DELETE_ENTITIES_AND_PROPOSALS
  );

  const menuItems = useMemo(
    () =>
      compact([
        onExportPDFClick ? (
          <MenuItem
            key="export-pdf"
            label="Export PDF"
            onClick={() => onExportPDFClick()}
            loading={isPrintingPDF}
            muiMenuItemProps={{
              divider: canDeleteProposal && !onDuplicateProposal,
            }}
          />
        ) : null,
        onDuplicateProposal && (
          <MenuItem
            key="duplicate-proposal"
            label="Duplicate"
            onClick={() => onDuplicateProposal()}
            loading={isDuplicatingProposal}
            muiMenuItemProps={{
              divider: canDeleteProposal,
            }}
          />
        ),
        canDeleteProposal ? (
          <DeleteProposalMenuItem
            key="delete-proposal"
            onDelete={() => refetch()}
            proposalId={proposalId}
            kind={kind}
          />
        ) : null,
      ]),
    [
      canDeleteProposal,
      isDuplicatingProposal,
      isPrintingPDF,
      kind,
      onDuplicateProposal,
      onExportPDFClick,
      proposalId,
      refetch,
    ]
  );

  return <ContextMenuButton {...buttonProps}>{menuItems}</ContextMenuButton>;
}

interface EntityProposalActionsProps {
  proposalId: string;
  householdId: string;
  refetch: () => void;
  proposal: ListClientProposals_ProposalFragment;
}

export function EntityProposalActions({
  proposalId,
  householdId,
  refetch,
  proposal,
}: EntityProposalActionsProps) {
  const trackUserEvent = useTrackUserEvent();
  const { showFeedback } = useFeedback();
  const { navigate } = useNavigateToRoute();

  const [printDocument, { loading: isPrintingPDF }] = useDownloadProposal(
    householdId,
    proposalId
  );

  const onExportPDFClick = () => {
    void printDocument({
      onCompleted: (data) => {
        const downloadUrl = data.print.download.downloadURL;
        try {
          void $downloadFileFromURL(downloadUrl, proposal.displayName);
          trackUserEvent('proposal exported', {
            householdId,
            proposalId,
          });
          showFeedback('PDF generated successfully', { variant: 'success' });
        } catch (err) {
          showFeedback('Failed to download PDF. Please try again.');
        }
      },
    });
  };

  const [onDuplicateProposal, { loading: isDuplicatingProposal }] =
    useDuplicateProposal(proposal.id);

  return (
    <Stack direction="row" spacing={0.5}>
      <ViewButton householdId={householdId} proposalId={proposalId} />
      <ShareButton proposalId={proposalId} />
      <ContextMenuAction
        refetch={refetch}
        proposalId={proposalId}
        onExportPDFClick={onExportPDFClick}
        isPrintingPDF={isPrintingPDF}
        onDuplicateProposal={() =>
          onDuplicateProposal((proposalId: string) =>
            navigate(ROUTE_KEYS.HOUSEHOLD_PROPOSAL_EDIT, {
              householdId,
              proposalId,
            })
          )
        }
        isDuplicatingProposal={isDuplicatingProposal}
        kind={ProposalKind.Entity}
      />
    </Stack>
  );
}

interface GiftingProposalActionsProps {
  householdId: string;
  proposal: SetRequired<
    SetNonNullable<ListClientProposals_ProposalFragment, 'giftingProposal'>,
    'giftingProposal'
  >;
  refetch: () => void;
}

export function GiftingProposalActions({
  householdId,
  proposal,
  refetch,
}: GiftingProposalActionsProps) {
  const trackUserEvent = useTrackUserEvent();
  const { showFeedback } = useFeedback();
  const { navigate } = useNavigateToRoute();

  const [printDocument, { loading: isPrintingPDF }] = useDownloadProposal(
    householdId,
    proposal.id
  );
  const { status } = proposal.giftingProposal;

  const isDraft = status === GiftingProposalStatus.Draft;
  const isProposalCreated = status === GiftingProposalStatus.ProposalCreated;

  const onExportPDFClick = () => {
    void printDocument({
      onCompleted: (data) => {
        const downloadUrl = data.print.download.downloadURL;
        try {
          void $downloadFileFromURL(downloadUrl, proposal.displayName);
          trackUserEvent('proposal exported', {
            householdId,
            proposalId: proposal.id,
          });
          showFeedback('PDF generated successfully', { variant: 'success' });
        } catch (err) {
          showFeedback('Failed to download PDF. Please try again.');
        }
      },
    });
  };

  const [onDuplicateProposal, { loading: isDuplicatingProposal }] =
    useDuplicateProposal(proposal.id);

  return (
    <Stack direction="row" spacing={0.5}>
      <ViewButton
        householdId={householdId}
        proposalId={proposal.id}
        disabled={isDraft}
      />
      <ShareButton proposalId={proposal.id} disabled={!isProposalCreated} />
      <ContextMenuAction
        refetch={refetch}
        proposalId={proposal.id}
        onExportPDFClick={isProposalCreated ? onExportPDFClick : undefined}
        isPrintingPDF={isPrintingPDF}
        onDuplicateProposal={() =>
          onDuplicateProposal((proposalId: string) =>
            navigate(ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER, {
              householdId,
              proposalId,
              designerStage: GiftDesignerStages.BASIC_INFORMATION,
            })
          )
        }
        kind={ProposalKind.Gift}
        isDuplicatingProposal={isDuplicatingProposal}
      />
    </Stack>
  );
}

export interface CharitableProposalActionsProps {
  proposal: ListClientProposals_ProposalFragment;
  refetch: () => void;
}

export function CharitableProposalActions({
  proposal,
  refetch,
}: CharitableProposalActionsProps) {
  const { showFeedback } = useFeedback();
  const { navigate } = useNavigateToRoute();

  const [printDocument, { loading: isPrintingPDF }] = useDownloadProposal(
    proposal.household.id,
    proposal.id
  );

  const onExportPDFClick = () => {
    void printDocument({
      onCompleted: (data) => {
        const downloadUrl = data.print.download.downloadURL;
        try {
          void $downloadFileFromURL(downloadUrl, proposal.displayName);
          showFeedback('PDF generated successfully', { variant: 'success' });
        } catch (err) {
          showFeedback('Failed to download PDF. Please try again.');
        }
      },
    });
  };

  const [onDuplicateProposal, { loading: isDuplicatingProposal }] =
    useDuplicateProposal(proposal.id);

  const kind = getProposalKind(proposal);

  return (
    <Stack direction="row" spacing={0.5}>
      <ViewButton
        householdId={proposal.household.id}
        proposalId={proposal.id}
      />
      <ShareButton proposalId={proposal.id} />
      {kind && (
        <ContextMenuAction
          refetch={refetch}
          proposalId={proposal.id}
          onExportPDFClick={onExportPDFClick}
          isPrintingPDF={isPrintingPDF}
          onDuplicateProposal={() =>
            onDuplicateProposal((proposalId: string) =>
              navigate(
                ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION,
                {
                  householdId: proposal.household.id,
                  proposalId,
                  variant: kind,
                }
              )
            )
          }
          kind={proposal.crtProposal ? ProposalKind.CRT : ProposalKind.CLT}
          isDuplicatingProposal={isDuplicatingProposal}
        />
      )}
    </Stack>
  );
}
