import React from 'react';

import { GratDesignerStages } from '@/navigation/constants';

import { RenderDesignerFooter } from '../types';
import { BasicInformationDesigner } from './BasicInformationDesigner/BasicInformationDesigner';
import { TabName } from './StructuringDesigner/constants';
import { StructuringDesigner } from './StructuringDesigner/StructuringDesigner';

export interface GRATDesignerProps {
  householdId: string;
  entityId: string;
  designerStage?: GratDesignerStages;
  initialStructuringTab: TabName;
  isFullScreenModal?: boolean;
  DesignerFooter?: RenderDesignerFooter;
  onAfterUpdate?: () => void;
}

export function GRATDesigner({
  householdId,
  entityId,
  designerStage,
  isFullScreenModal = false,
  initialStructuringTab,
  DesignerFooter,
  onAfterUpdate,
}: GRATDesignerProps) {
  if (designerStage === GratDesignerStages.BASIC_INFORMATION) {
    return (
      <BasicInformationDesigner
        householdId={householdId}
        entityId={entityId}
        isFullScreenModal={isFullScreenModal}
        Footer={DesignerFooter}
        onAfterUpdate={onAfterUpdate}
      />
    );
  } else if (designerStage === GratDesignerStages.STRUCTURING) {
    return (
      <StructuringDesigner
        householdId={householdId}
        entityId={entityId}
        initialTab={initialStructuringTab}
        isFullScreenModal={isFullScreenModal}
        Footer={DesignerFooter}
        onAfterUpdate={onAfterUpdate}
      />
    );
  } else {
    throw new Error(`Unknown designer stage: ${designerStage}`);
  }
}
