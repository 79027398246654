import { useEffect } from 'react';
import { UseFormReset } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import {
  AugmentedUpdateClientOrganizationInput,
  ClientOrganizationKind,
} from '@/types/schema';

import { ClientOrganizationFormShape } from './ClientOrganizationModal.constants';
import { ClientOrganizationFragment } from './graphql/ClientOrganization.fragment.generated';
import { useGetClientOrganizationLazyQuery } from './graphql/ClientOrganizationModal.generated';

export function getFormValuesFromNode(
  data: ClientOrganizationFragment
): ClientOrganizationFormShape {
  const { address, ...restOrg } = data;

  return {
    kind: restOrg.kind || '',
    name: restOrg.name || '',
    isTrustee: restOrg.isTrustee || false,
    isBeneficiary: restOrg.isBeneficiary || false,
    address: {
      cityName: address?.cityName ?? '',
      stateCode: address?.stateCode ?? '',
    },
    pointOfContactEmail: restOrg.pointOfContactEmail || '',
    pointOfContactPhone: restOrg.pointOfContactPhone || '',
    pointOfContactName: restOrg.pointOfContactName || '',
    pointOfContactLastName: restOrg.pointOfContactLastName || '',
  };
}

export function mapFormDataToUpdateInput(
  formValues: ClientOrganizationFormShape,
  organizationId: string
): AugmentedUpdateClientOrganizationInput {
  const { address, ...clientOrganization } = formValues;

  return {
    id: organizationId,
    update: {
      name: clientOrganization.name,
      kind: clientOrganization.kind || ClientOrganizationKind.Other,
      ...(clientOrganization.pointOfContactEmail
        ? { pointOfContactEmail: clientOrganization.pointOfContactEmail }
        : { clearPointOfContactEmail: true }),
      ...(clientOrganization.pointOfContactPhone
        ? { pointOfContactPhone: clientOrganization.pointOfContactPhone }
        : { clearPointOfContactPhone: true }),
      ...(clientOrganization.pointOfContactName
        ? { pointOfContactName: clientOrganization.pointOfContactName }
        : { clearPointOfContactName: true }),
      ...(clientOrganization.pointOfContactLastName
        ? { pointOfContactLastName: clientOrganization.pointOfContactLastName }
        : { clearPointOfContactLastName: true }),
      isBeneficiary: clientOrganization.isBeneficiary || false,
      isTrustee: clientOrganization.isTrustee || false,
      clearAddress: true,
    },
    ...(address.cityName && address.stateCode
      ? { withAddress: { create: address } }
      : {}),
  };
}

export function useSyncClientOrganizationDataToForm(
  reset: UseFormReset<ClientOrganizationFormShape>,
  organizationId: string
) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [getClientOrganization, queryProps] = useGetClientOrganizationLazyQuery(
    {
      variables: { id: organizationId },
      onError: (err) => {
        showFeedback(
          "We weren't able to get the organization information. Please refresh the page and try again."
        );
        reportError(
          'EditClientOrganizationModal: Could not get organization',
          err,
          {
            organizationId,
          }
        );
      },
    }
  );

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    async function syncDataToForm() {
      const { data } = await getClientOrganization();
      const organization = data?.organization as ClientOrganizationFragment;
      if (organization) {
        reset(getFormValuesFromNode(organization));
      }
    }

    void syncDataToForm();
  }, [reset, getClientOrganization, organizationId]);

  return queryProps;
}
