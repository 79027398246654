import { useEffect, useState } from 'react';

import { EdgeOf } from '@/graphql/types';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';

export const useProposalScenarios = (
  clientProposals: EdgeOf<ProposalFragment>
) => {
  const [proposals, setProposals] = useState<ProposalFragment[] | null>(null);
  const [entityIdsInProposal, setEntityIdsInProposal] = useState<string[]>([]);

  useEffect(() => {
    const proposals: ProposalFragment[] = [];
    const entityIds: string[] = [];

    clientProposals?.forEach((edge) => {
      const proposal = edge?.node;
      if (!proposal) {
        return;
      }

      proposals.push(proposal);

      proposal.entityProposals?.forEach((ep) => {
        entityIds.push(ep.entity.id);
      });
    });

    if (!proposals) {
      return;
    }

    setProposals(proposals);
    setEntityIdsInProposal(entityIds);
  }, [clientProposals]);

  const proposal = proposals?.[0];

  return { proposal, entityIdsInProposal };
};
