import { useStoreApi } from '@xyflow/react';
import { groupBy } from 'lodash';
import { useCallback, useMemo } from 'react';

import {
  isTileGroupNode,
  isTileNode,
} from '@/components/diagrams/FlowChart/utils/nodes';

import { useEstateWaterfallContext } from '../contexts/estateWaterfall.context';
import { isTaxNode } from '../waterfallGraph/utils';

export function useNodeSelection() {
  const { dispatch, state } = useEstateWaterfallContext();
  const { getState, setState } = useStoreApi();

  const { selectedNodes, selectedNodesForGrouping } = useMemo(() => {
    const selectedNodes = state.nodes.filter((node) => node.selected);

    const selectedNodesBySection = groupBy(
      selectedNodes,
      (node) => node.id.split(':')[0]
    );

    if (Object.keys(selectedNodesBySection).length > 1) {
      // If there are selected nodes from multiple sections, return an empty array
      return {
        selectedNodes,
        selectedNodesForGrouping: [],
      };
    }

    const selectedNodesForGrouping = selectedNodes.filter((node) => {
      // Only allow grouping of tile nodes
      if (!isTileNode(node)) return false;
      if (isTaxNode(node.id)) return false;
      if (isTileGroupNode(node)) return false;

      return true;
    });

    return {
      selectedNodes,
      selectedNodesForGrouping,
    };
  }, [state.nodes]);

  const hasMultipleSelectedNodes = selectedNodes.length > 1;
  const hasMultipleSelectedNodesForGrouping =
    selectedNodesForGrouping.length > 1;

  const groupSelection = useCallback(() => {
    if (!(selectedNodesForGrouping.length > 1)) return;
    const groupNodesSorted = selectedNodesForGrouping.sort(
      (a, b) => a.position.x - b.position.x
    );
    // The group will be created where the first node is
    const groupNodeId = groupNodesSorted[0]!.id;
    const appendNodesIds = [...groupNodesSorted].slice(1).map((n) => n.id);

    dispatch({
      type: 'UPSERT_NODE_GROUP',
      groupNodeId,
      appendNodesIds,
    });
    getState().resetSelectedElements();
    setState({ nodesSelectionActive: false });
  }, [dispatch, getState, selectedNodesForGrouping, setState]);

  return {
    selectedNodes,
    hasMultipleSelectedNodes,
    selectedNodesForGrouping,
    hasMultipleSelectedNodesForGrouping,
    groupSelection,
  };
}
