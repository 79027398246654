function captureDownloadedFile(url: string, fileName: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

/**
 * @throws if the network response is not ok
 */
export async function $downloadFileFromURL(url: string, fileName: string) {
  const result = await fetch(url);

  if (!result.ok) {
    throw new Error('Network response was not ok');
  }

  const blob = await result.blob();
  downloadBlob(blob, fileName);
}

export function downloadBlob(blob: Blob, fileName: string) {
  const blobUrl = window.URL.createObjectURL(blob);
  captureDownloadedFile(blobUrl, fileName);
  window.URL.revokeObjectURL(blobUrl);
}
