import Decimal from 'decimal.js';

import { PathsOf } from '@/types/subform';

import { GRATTerm } from './ui/utils';

export const NAMESPACE = 'termsSubform' as const;
export type NamespaceType = typeof NAMESPACE;
const VARIANTS = ['twoColumn', 'oneColumn', 'none'] as const;
export type VariantType = (typeof VARIANTS)[number];

export enum RollingType {
  STANDARD = 'STANDARD',
  ROLLING = 'ROLLING',
}
// this is the type of the subform's values
export interface TermsSubformType {
  type: RollingType | null;
  termLength: GRATTerm;
  // if the user chooses a GRAT of 10+ years, we use this field.
  termLengthExtended: string;
  rollingPeriod: string;
  rate7520: Decimal | null;
}

// this is what the subform's values will be nested under in the parent form
export interface Fields {
  [NAMESPACE]: TermsSubformType;
}

export type SubformField = PathsOf<Fields>;

// this is Props for the UI component
export interface Props {
  variant: VariantType;
  subformValues: TermsSubformType;
}
