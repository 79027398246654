import { EntityKind } from '@/types/schema';

export const businessEntityKinds = new Set([
  EntityKind.LlcBusinessEntity,
  EntityKind.LpBusinessEntity,
  EntityKind.GpBusinessEntity,
  EntityKind.SoleProprietorshipBusinessEntity,
  EntityKind.ScorpBusinessEntity,
  EntityKind.CcorpBusinessEntity,
]);
