import { useNodesInitialized } from '@xyflow/react';
import { useCallback, useEffect, useMemo } from 'react';

import { useFlowChartContext } from '@/components/diagrams/FlowChart';
import { useEffectReducer } from '@/hooks/useEffectReducer';
import { useGetState } from '@/hooks/useGetState';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { reduceEffectReducers } from '@/utils/reducerUtils';

import { useSetIsDirtyEffect } from '../effects';
import { useCompleteInitialiationEffect } from '../effects/completeInitialization.effect';
import { useEditMutationEffect } from '../effects/editMutation.effect';
import { useFitViewEffect } from '../effects/fitView.effect';
import { entityMapReducer, entityMapStateTransformReducer } from '../reducers';
import { generateDefaultState } from '../state/generateDefaultState';
import { EntityMapEffectsMap, EntityMapInitialStateGetter } from '../types';
import { EntityMapAction } from '../types/actions';
import { EntityMapEffect } from '../types/effects';
import { EntityMapState, EntityMapStateProps } from '../types/state';
import { EntityMapContext } from './entityMap.context';

export interface EntityMapProviderProps extends EntityMapStateProps {
  children: JSX.Element;
}

export const rootReducer = reduceEffectReducers([
  // Primary reducer that handles incoming actions
  entityMapReducer,
  // Reducer that composes state transform functions. Add computations
  // here that are true for every action type and computed based on state
  // or
  // Derived data which is computed from state, but is more in the form of helper
  // boolean flags, object-by-id look up maps, etc.
  entityMapStateTransformReducer,
]);

function useEffectsMap(): EntityMapEffectsMap {
  const fitViewEffect = useFitViewEffect();
  const completeInitializationEffect = useCompleteInitialiationEffect();
  const editMutationEffect = useEditMutationEffect();
  const setIsDirtyEffect = useSetIsDirtyEffect();

  return useMemo<EntityMapEffectsMap>(() => {
    return {
      fitViewEffect,
      completeInitializationEffect,
      editMutationEffect,
      setIsDirtyEffect,
    };
  }, [
    completeInitializationEffect,
    editMutationEffect,
    fitViewEffect,
    setIsDirtyEffect,
  ]);
}

export const EntityMapProvider = ({
  children,
  household,
  entityGraphView,
  allEntityGraphViews,
}: EntityMapProviderProps) => {
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const isNodesInitialized = useNodesInitialized();
  const { flowBounds } = useFlowChartContext();

  const effectsMap = useEffectsMap();

  const getInitialState = useCallback<EntityMapInitialStateGetter>(
    (_queueEffect) => {
      return generateDefaultState({
        household,
        entityGraphView,
        allEntityGraphViews,
      });
    },
    [allEntityGraphViews, entityGraphView, household]
  );

  const [state, dispatch] = useEffectReducer<
    EntityMapState,
    EntityMapAction,
    EntityMapEffect
  >(rootReducer, getInitialState, effectsMap);

  const getState = useGetState(state);

  // Update state when the input variables to compute a entity map change
  useEffect(() => {
    // Note: This effect is idempotent, but it's nice to add these conditions to clean up the logs a bit
    if (getState().isInitializing) return;

    dispatch({
      type: 'UPDATE_ENTITY_MAP',
      household,
      entityGraphView,
      allEntityGraphViews,
    });
  }, [
    allEntityGraphViews,
    dispatch,
    entityGraphView,
    getState,
    household,
    isTwoClientHousehold,
  ]);

  // Once react flow has measured everything, we can start positioning nodes
  useEffect(() => {
    if (!isNodesInitialized) return;

    if (state.isInitializing) {
      dispatch({ type: 'INITIALIZE_NODE_POSITIONS' });
    } else {
      // IMPORTANT code branch: isNodesInitalized can flip from true to false AFTER we've initalized our own node layout.
      // This is a place holder in case we need to do any "re-measuring" of our internal styles, like in estate waterfall.
      dispatch({ type: 'REACTFLOW_NODE_MEASUREMENT_EVENT' });
    }
  }, [dispatch, isNodesInitialized, state.isInitializing]);

  useEffect(() => {
    dispatch({
      type: 'FLOW_BOUNDS_CHANGED',
      height: flowBounds.height,
      width: flowBounds.width,
    });
  }, [flowBounds.height, flowBounds.width, dispatch]);

  return (
    <EntityMapContext.Provider value={{ state, dispatch, getState }}>
      {children}
    </EntityMapContext.Provider>
  );
};
