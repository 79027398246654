import {
  array,
  boolean as booleanType,
  date,
  Describe,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreateSlatTrustInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateSlatTrustInput,
  CreateSlatTrustInput,
  UpdateSlatTrustInput,
} from '@/types/schema';

import {
  createInsurancePolicyInput,
  updateInsurancePolicyInput,
} from './insuranceTypes';
import {
  beneficiarySchema,
  commonUpdateEntityProperties,
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  grantorSchema,
  gstStatusSchema,
  inEstateStatusSchema,
  taxStatusSchema,
  trustAdvisorSchema,
  trusteeSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const createSlatTrustInput: Describe<CreateSlatTrustInput> = object({
  trustJurisdictionStateCode: optional(nullable(string())),
  taxStatus: taxStatusSchema,
  legalName: optional(nullable(string())),
  inEstateStatus: inEstateStatusSchema,
  gstStatus: gstStatusSchema,
  effectiveDate: optional(nullable(date())),
  displayName: string(),
  description: optional(nullable(string())),
});

const createSlatTrustSchema: Describe<AugmentedCreateSlatTrustInput> = object({
  create: createSlatTrustInput,
  withLifetimeBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withRemainderBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withTrustees: optional(nullable(array(trusteeSchema))),
  withSuccessorTrustees: optional(nullable(array(trusteeSchema))),
  withTrustAdvisors: optional(nullable(array(trustAdvisorSchema))),
  withGrantor: optional(nullable(grantorSchema)),
  withDesignerAccount: optional(nullable(createAccountSchema)),
});

const updateSlatTrustInput: Describe<UpdateSlatTrustInput> = object({
  description: optional(nullable(string())),
  displayName: optional(nullable(string())),
  effectiveDate: optional(nullable(date())),
  gstStatus: gstStatusSchema,
  inEstateStatus: inEstateStatusSchema,
  legalName: optional(nullable(string())),
  grantorID: optional(nullable(string())),
  taxStatus: taxStatusSchema,
  trustJurisdictionStateCode: optional(nullable(string())),
  clearEffectiveDate: optional(nullable(booleanType())),
  clearLegalName: optional(nullable(booleanType())),
  clearTrustJurisdictionStateCode: optional(nullable(booleanType())),
  clearLifetimeBeneficiaries: optional(nullable(booleanType())),
  clearRemainderBeneficiaries: optional(nullable(booleanType())),
  clearGrantor: optional(nullable(booleanType())),
  clearSuccessorTrustees: optional(nullable(booleanType())),
  clearTrustAdvisors: optional(nullable(booleanType())),
  clearDescription: optional(nullable(booleanType())),
  clearTrustees: optional(nullable(booleanType())),
  clearGstStatus: optional(nullable(booleanType())),
  clearTaxStatus: optional(nullable(booleanType())),
  removePolicyIDs: optional(nullable(array(string()))),
});

const updateSlatTrustSchema: Describe<AugmentedUpdateSlatTrustInput> = object({
  id: string(),
  update: updateSlatTrustInput,
  withLifetimeBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withRemainderBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withTrustees: optional(nullable(array(trusteeSchema))),
  withSuccessorTrustees: optional(nullable(array(trusteeSchema))),
  withTrustAdvisors: optional(nullable(array(trustAdvisorSchema))),
  withGrantor: optional(nullable(grantorSchema)),
  updateDesignerAccount: optional(nullable(updateAccountSchema)),
  withPolicies: optional(nullable(array(createInsurancePolicyInput))),
  updatePolicies: optional(nullable(array(updateInsurancePolicyInput))),
});

const createSchema: Describe<AugmentedCreateEntityInput> = object({
  create: createEntityInput,
  withSlatTrust: optional(nullable(createSlatTrustSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object(commonUpdateEntityProperties),
  updateSlatTrust: optional(nullable(updateSlatTrustSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export function validateSlatTrustCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'SLAT trust create');
}

export function validateSlatTrustUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'SLAT trust update');
}
