import { useMemo } from 'react';

import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';

import { SubBrandFragment } from '../graphql/Branding.generated';

export function useSubBrandOptions(subBrands: SubBrandFragment[]) {
  const { displayName: tenantDisplayName } = useTenantDetailsContext();

  return useMemo(() => {
    const validOptions = subBrands.map((subBrand) => ({
      display: subBrand.displayName || '',
      value: subBrand.id || '',
    }));

    // if there are no sub-brands, we want to return an empty array so that
    // we don't show any options downstream of this
    if (validOptions.length === 0) return [];

    const defaultOption = {
      display: tenantDisplayName ?? 'Default',
      value: '',
      withDividerBelow: true,
    };
    return [defaultOption, ...validOptions];
  }, [subBrands, tenantDisplayName]);
}
