import { css, cx } from '@emotion/css';
import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { WiggleProps } from '@/components/animation/Wiggle/Wiggle';
import { Tile, TileProps } from '@/components/diagrams/components/Tile';
import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';
import { compactReactChildren } from '@/utils/reactUtils';

import { TileVariant } from './types';

const classes: TileProps['classes'] = {
  lineOne: css({
    textAlign: 'center',
  }),
  lineTwo: css({
    textAlign: 'center',
  }),
  lineThree: css({
    textAlign: 'center',
  }),
  wrapper: css({
    height: '100%',
    cursor: 'pointer',
  }),
};

const wiggleClasses: WiggleProps['classes'] = {
  root: css({
    height: '100%',
  }),
};

const useStyles = makeStyles()((_theme) => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes -- lint rule doesn't work with extended classes object
  childrenWrapper: {
    width: '100%',
    height: '100%',
    padding: 16,
    borderRadius: 4,
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 4,
  },
}));

export type TileSummaryProps = TileProps & {
  classes?: TileProps['classes'] & WithClasses<typeof useStyles>;
  onClick?: () => void;
};

const variantToChildWrapperStyles: Partial<Record<TileVariant, string>> = {
  light: css({ backgroundColor: COLORS.GRAY[50] }),
  destructive: css({ backgroundColor: COLORS.ORANGE[50] }),
  secondary: css({ backgroundColor: COLORS.TEAL[50] }),
  tertiary: css({ backgroundColor: COLORS.BLUE[50] }),
};

export const TileSummary = ({
  children,
  onClick,
  variant = TileVariant.Light,
  ...props
}: TileSummaryProps) => {
  const { classes: tileSummaryClasses } = useStyles(undefined, { props });
  const compactChildren = compactReactChildren(children);

  const rootClass = useMemo(() => {
    if (!compactChildren) return undefined;
    /**
     * For some reason styles are loading in out of order with the classes / useStyles pattern
     * so the important is needed.
     * Unclear if this was a library upgrade or something else.
     * @todo: https://linear.app/luminary/issue/T1-1142/classes-pattern-not-applying-styles-in-correct-order
     */
    // @ts-ignore -- see above
    return css({
      display: 'grid !important',
      gridTemplateColumns: '1fr 2fr',
      width: '430px !important',
      alignItems: 'center',
      height: '100%',
    });
  }, [compactChildren]);

  return (
    <Box onClick={onClick} display="flex" alignSelf="stretch">
      <Tile
        {...props}
        variant={variant}
        classes={{
          ...props.classes,
          root: cx(rootClass, props.classes?.root),
          lineOne: cx(classes.lineOne, props.classes?.lineOne),
          lineTwo: cx(classes.lineTwo, props.classes?.lineTwo),
          lineThree: cx(classes.lineThree, props.classes?.lineThree),
          wrapper: cx(classes.wrapper, props.classes?.wrapper),
        }}
        wiggleClasses={wiggleClasses}
      >
        {compactChildren && (
          <div
            className={cx(
              tileSummaryClasses.childrenWrapper,
              variantToChildWrapperStyles[variant]
            )}
          >
            {compactChildren}
          </div>
        )}
      </Tile>
    </Box>
  );
};
