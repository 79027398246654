/**
 * @todo dedupe/share with PersonFormFragment logic
 * https://linear.app/luminary/issue/T1-2440/person-form-integration
 */

import { ClientProfileRelationshipType } from '@/types/schema';

export const CHILD_RELATIONSHIP_TYPES = [
  ClientProfileRelationshipType.Child,
  ClientProfileRelationshipType.StepChild,
  ClientProfileRelationshipType.Grandchild,
];

export const AFTER_SIBLING_RELATIONSHIP_TYPES = [
  ClientProfileRelationshipType.DomesticPartner,
  ClientProfileRelationshipType.Spouse,
];

export const BEFORE_SIBLING_RELATIONSHIP_TYPES = [
  ClientProfileRelationshipType.ExSpouse,
  ClientProfileRelationshipType.ExDomesticPartner,
];

export const PARENT_RELATIONSHIP_TYPES = [
  ClientProfileRelationshipType.Parent,
  ClientProfileRelationshipType.StepParent,
];

export const PRIMARY_CLIENT_RELATIONSHIP_TYPES = [
  ...CHILD_RELATIONSHIP_TYPES,
  ...PARENT_RELATIONSHIP_TYPES,
  ...AFTER_SIBLING_RELATIONSHIP_TYPES,
  ...BEFORE_SIBLING_RELATIONSHIP_TYPES,
];

export const EX_RELATIONSHIP_TYPES = [
  ClientProfileRelationshipType.ExSpouse,
  ClientProfileRelationshipType.ExDomesticPartner,
];
