import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useProposalScenarios } from '@/pages/proposal/hooks/useProposalScenarios';

import { useProposalsQuery as useProposalsQueryExternal } from '../graphql/Proposals.generated';

interface UseProposalsQueryProps {
  proposalId: string;
}

export enum ProposalKind {
  Entity = 'entity',
  Gift = 'gift',
  CLT = 'clt',
  CRT = 'crt',
}

export function useProposalsQuery({ proposalId }: UseProposalsQueryProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const queryProps = useProposalsQueryExternal({
    variables: {
      where: {
        id: proposalId,
      },
    },
    fetchPolicy: 'network-only',
    onError: (error) => {
      showFeedback(
        "We weren't able to load this proposal. Please refresh the page and try again."
      );
      reportError('could not load client proposal', error, {
        proposalId,
      });
    },
  });

  const { proposal, entityIdsInProposal } = useProposalScenarios(
    queryProps.data?.proposals.edges
  );

  const proposalKind: ProposalKind | null = useMemo(() => {
    if (!proposal) {
      return null;
    }

    if ((proposal.entityProposals?.length ?? 0) > 0) {
      return ProposalKind.Entity;
    }

    if (proposal.giftingProposal?.id) {
      return ProposalKind.Gift;
    }

    if (proposal.cltProposal?.id) {
      return ProposalKind.CLT;
    }

    if (proposal.crtProposal?.id) {
      return ProposalKind.CRT;
    }

    return null;
  }, [proposal]);

  const subBrandId = useMemo(() => {
    return proposal?.household.subBrand?.id;
  }, [proposal?.household.subBrand?.id]);

  return {
    ...queryProps,
    proposalKind,
    proposal,
    entityIdsInProposal,
    subBrandId,
  };
}
