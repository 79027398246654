import { ApolloError } from '@apollo/client';
import { Stack } from '@mui/material';
import compareAsc from 'date-fns/compareAsc';
import { maxTime } from 'date-fns/constants';
import Decimal from 'decimal.js';
import { compact, first } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { FormProvider, SubmitErrorHandler } from 'react-hook-form';

import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form/hooks';
import {
  MiniTable,
  MiniTableRow,
} from '@/components/tables/MiniTable/MiniTable';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useReportError } from '@/hooks/useReportError';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import {
  EntityTaskCompletionState,
  InsurancePolicyPremiumFrequency,
} from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { UnreachableError } from '@/utils/errors';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { getNodes } from '@/utils/graphqlUtils';

import { FullScreenTaskLayout } from '../FullScreenTaskLayout';
import { ILITTaskContext } from '../ILITTaskContext';
import { ILITTaskFooter } from '../ILITTaskFooter/ILITTaskFooter';
import { ILITTaskSidebar } from '../ILITTaskSidebar/ILITTaskSidebar';
import { useGetIlitAnnualExclusionGiftsTaskDetailsQuery } from './graphql/GetILITAnnualExclusionGiftsTaskDetails.generated';
import { useIlitMakeAnnualExclusionGiftMutation } from './graphql/ILITMakeAnnualExclusionGift.generated';
import { ILITMakeAnnualExclusionGiftsForm } from './ILITMakeAnnualExclusionGiftsTask.types';
import {
  generateTaskCompletionPayload,
  mapDataToForm,
} from './ILITMakeAnnualExclusionGiftsTask.utils';
import { ILITMakeAnnualExclusionGiftsTaskBody } from './ILITMakeAnnualExclusionGiftsTaskBody';

export interface ILITMakeAnnualExclusionGiftsTaskProps {
  taskId: string;
  onCompleteTask: () => void;
  onClose: () => void;
  householdId: string;
  entityId: string;
}

export function ILITMakeAnnualExclusionGiftsTaskInner() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { id: currentUserId } = useCurrentUser() ?? { id: '' };

  const { householdId, entityId, taskId, onCompleteTask } =
    useContext(ILITTaskContext);

  const formMethods = useForm<ILITMakeAnnualExclusionGiftsForm>({
    defaultValues: {
      grantors: {},
      giftSourceOptionsBySourceEntityId: {},
      documentIds: [],
    },
  });
  const { reset, handleSubmit } = formMethods;
  const currentTimestamp = useMemo(() => {
    return new Date();
  }, []);

  const { data, loading: isLoadingTaskDetails } =
    useGetIlitAnnualExclusionGiftsTaskDetailsQuery({
      variables: {
        householdId,
        entityId,
        taskId,
        currentTimestamp,
      },
      onError: (error: ApolloError) => {
        showFeedback(
          'Failed to load the task details.  Please refresh the page and try again.',
          { variant: 'error' }
        );
        reportError(
          'Caught error when loading exclusion gift task details',
          error
        );
      },
    });

  const currentTask = useMemo(() => {
    return first(getNodes(data?.entityTasks));
  }, [data]);

  const [makeExclusionGift, { loading: loadingExclusionGift }] =
    useIlitMakeAnnualExclusionGiftMutation({
      onCompleted: () => {
        onCompleteTask();
      },
      onError: (error: ApolloError) => {
        showFeedback(
          "We weren't able to complete the task. Please refresh the page and try again.",
          { variant: 'error' }
        );
        reportError(
          'Encountered error when marking annual exclusion task complete',
          error
        );
      },
    });

  useEffect(() => {
    if (!data) return;
    reset(mapDataToForm(data, entityId));
  }, [data, entityId, reset]);

  const sidebarTableRows = useMemo<MiniTableRow[]>(() => {
    const entity = first(getNodes(data?.entities));
    const ilitSubtype = entity?.subtype;
    if (ilitSubtype?.__typename !== 'ILITTrust') {
      return [];
    }
    const policies = ilitSubtype.policies || [];

    const totalAnnualPremiumDue = sumDecimalJS(
      compact(policies).map<Decimal>((policy) => {
        if (!policy.premiumFrequency) return new Decimal(0);

        const premiumAmount = policy.premiumAmount || new Decimal(0);
        switch (policy.premiumFrequency) {
          case InsurancePolicyPremiumFrequency.Annually:
            return premiumAmount;
          case InsurancePolicyPremiumFrequency.Semiannually:
            return premiumAmount.mul(2);
          case InsurancePolicyPremiumFrequency.Monthly:
            return premiumAmount.mul(12);
          default:
            throw new UnreachableError({
              case: policy.premiumFrequency,
              message: 'Invalid policy premium frequency',
            });
        }
      })
    );

    const upcomingTasks = compact(getNodes(entity?.tasks));
    const sortedTasks = upcomingTasks.sort((a, b) =>
      compareAsc(
        a.premiumPayment?.dueOn || new Date(maxTime),
        b.premiumPayment?.dueOn || new Date(maxTime)
      )
    );
    let nextDueDate: string = EMPTY_CONTENT_HYPHEN;
    const nextTaskDue = first(sortedTasks);
    if (nextTaskDue?.premiumPayment?.dueOn) {
      nextDueDate = formatDateToMonDDYYYY(nextTaskDue.premiumPayment.dueOn);
    }

    return [
      {
        label: 'Total premiums due per year',
        value: formatCurrency(totalAnnualPremiumDue),
      },
      {
        label: 'Next due date',
        value: nextDueDate,
      },
    ];
  }, [data]);

  function completeTaskSubmit(formData: ILITMakeAnnualExclusionGiftsForm) {
    const { errorMessage, variables } = generateTaskCompletionPayload({
      formData,
      entityId,
      currentUserId,
      taskId,
    });
    if (errorMessage !== null) {
      showFeedback(errorMessage);
    } else {
      void makeExclusionGift({ variables });
    }
  }

  const handleInvalidSubmit: SubmitErrorHandler<
    ILITMakeAnnualExclusionGiftsForm
  > = (errors) => {
    const grantorErrors = compact(Object.values(errors.grantors || {}));
    const hasSourceOrGiftDateError = grantorErrors.find(
      (grantor) => grantor.sourceId || grantor.giftDate
    );
    if (hasSourceOrGiftDateError) {
      showFeedback(
        'If no grantors are making gifts, mark the task as skipped.',
        { variant: 'info-high' }
      );
    }
  };

  const handleCompleteTask = handleSubmit(
    completeTaskSubmit,
    handleInvalidSubmit
  );

  const isLoading =
    isLoadingTaskDetails ||
    currentTask?.completionState === EntityTaskCompletionState.Complete;
  const isSubmitting = loadingExclusionGift;

  return (
    <FormProvider {...formMethods}>
      <FormFieldsDisabledProvider isDisabled={isLoading || isSubmitting}>
        <Stack height="100dvh">
          <FullScreenTaskLayout
            LeftPaneContent={
              <ILITTaskSidebar
                heading="Make an annual exclusion gift"
                subheading="Confirm the specific gift amounts attributed to each beneficiary of this ILIT and mark the task as complete once all transactions have been completed."
              >
                <MiniTable rows={sidebarTableRows} variant="default" />
              </ILITTaskSidebar>
            }
            RightPaneContent={<ILITMakeAnnualExclusionGiftsTaskBody />}
            Footer={
              <ILITTaskFooter
                onComplete={handleCompleteTask}
                disabled={isLoading}
                submitting={isSubmitting}
                skippable
              />
            }
          />
        </Stack>
      </FormFieldsDisabledProvider>
    </FormProvider>
  );
}

export function ILITMakeAnnualExclusionGiftsTask({
  householdId,
  entityId,
  taskId,
  onClose,
  onCompleteTask,
}: ILITMakeAnnualExclusionGiftsTaskProps) {
  return (
    <ILITTaskContext.Provider
      value={{ householdId, entityId, taskId, onClose, onCompleteTask }}
    >
      <ILITMakeAnnualExclusionGiftsTaskInner />
    </ILITTaskContext.Provider>
  );
}
