import { Box, Grid, Typography } from '@mui/material';

import { FONT_SIZING } from '@/styles/tokens/fonts';

import { PRESENTATION_SPACING_UNITS } from '../../presentation.constants';

export interface OverviewSlideLayoutProps {
  Assets: React.ReactNode;
  Summary: React.ReactNode;
  Callout: React.ReactNode;
  description?: string;
}

export function OverviewSlideLayout({
  Assets,
  Summary,
  Callout,
  description,
}: OverviewSlideLayoutProps) {
  return (
    <Grid p={PRESENTATION_SPACING_UNITS} spacing={3} container height="100%">
      <Grid item sm={4} sx={{ height: '100%' }}>
        <Box sx={{ height: '100%' }}>{Assets}</Box>
      </Grid>
      <Grid item container sm={8} spacing={3}>
        {/*
          AlignSelf is used so that the callout with its colored background doesn't default to taking up
          100% of the height
        */}
        <Grid item sm={5} sx={{ alignSelf: 'flex-start' }}>
          {Summary}
        </Grid>
        <Grid item sm={3} sx={{ alignSelf: 'flex-start' }}>
          {Callout}
        </Grid>
        {description && (
          <Grid item sm={12} flexGrow={1}>
            <Typography
              variant="body2"
              lineHeight="0.8rem"
              fontSize={FONT_SIZING.xs.fontSize} // 0.75rem
              sx={(t) => ({
                whiteSpace: 'pre-wrap',
                color: t.palette.text.primary,
              })}
            >
              {description}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
