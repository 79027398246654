import {
  Box,
  BoxProps,
  Stack,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material';
import {
  Children,
  CSSProperties,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { ChevronDownIcon } from '@/components/icons/ChevronDownIcon';
import { ChevronUpIcon } from '@/components/icons/ChevronUpIcon';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { Slot } from '@/components/utils/slots';
import { BeneficiaryHeader } from '@/modules/beneficiaryReporting/BeneficiarySelector';
import { WaterfallHeader } from '@/modules/estateWaterfall/components/EstateWaterfallsDropdown/EstateWaterfallsHeadingDropdown';
import { COLORS } from '@/styles/tokens/colors';

import { ButtonWithPopover, ButtonWithPopoverProps } from './ButtonWithPopover';

type DefaultHeaderProps = PropsWithChildren & Partial<TypographyProps>;

function DefaultHeader({ children, ...rest }: DefaultHeaderProps) {
  return (
    <Stack direction="row" spacing={1}>
      <Typography variant="h1" component="h2" {...rest}>
        {children}
      </Typography>
    </Stack>
  );
}

export function ChevronWithOutline({
  iconSize,
  isOpen,
  isActive = false,
  isVisible = true,
}: {
  iconSize?: number;
  isOpen?: boolean;
  isActive?: boolean;
  isVisible?: boolean;
}) {
  const sizeProps = { size: iconSize };

  if (!isVisible) {
    return null;
  }

  return (
    <OutlineBox isActive={isActive}>
      {isOpen ? (
        <ChevronUpIcon {...sizeProps} />
      ) : (
        <ChevronDownIcon {...sizeProps} />
      )}
    </OutlineBox>
  );
}

interface OutlineBoxProps extends BoxProps {
  isActive: boolean;
}

const OutlineBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isActive', // prevent isActive from being passed to the DOM
})<OutlineBoxProps>(({ isActive }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%',
  background: isActive ? COLORS.GRAY[100] : 'transparent',
  border: `1px solid ${isActive ? COLORS.GRAY[200] : 'transparent'}`,
  borderRadius: '4px',
}));

type HeaderWithPopoverProps = PropsWithChildren<{
  slots?: {
    Header?: Slot<
      typeof DefaultHeader | typeof WaterfallHeader | typeof BeneficiaryHeader
    >;
  };
  heading: string;
  buttonWithPopoverProps?: Partial<ButtonWithPopoverProps>;
  activeColor?: CSSProperties['color'];
}>;

export function HeaderWithPopover({
  slots,
  heading,
  buttonWithPopoverProps: buttonWithPopoverPropsExternal,
  children,
  activeColor = COLORS.ORANGE[500],
}: HeaderWithPopoverProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined);
  const { buttonSx, ...buttonWithPopoverProps } =
    buttonWithPopoverPropsExternal ?? {};

  const activate = useCallback(() => {
    setIsActive(true);
  }, []);

  const deactivate = useCallback(() => {
    setIsActive(false);
  }, []);

  const headerColor = useMemo(() => {
    if (isActive) {
      return activeColor;
    }

    return undefined;
  }, [activeColor, isActive]);

  const labelContent = useMemo(() => {
    if (!heading) {
      return EMPTY_CONTENT_HYPHEN;
    }

    if (!slots?.Header) {
      return <DefaultHeader color={headerColor}>{heading}</DefaultHeader>;
    }

    return (
      <slots.Header.component
        {...(slots?.Header.props ?? {})}
        color={headerColor}
      >
        {heading}
      </slots.Header.component>
    );
  }, [headerColor, heading, slots]);

  return (
    <ButtonWithPopover
      buttonSx={{
        p: 0,
        ...buttonSx,
      }}
      size="md"
      variant="transparent"
      popperVariant="menuBelow"
      onClick={() => setIsMenuOpen(true)}
      isOpen={isMenuOpen}
      onOpen={activate}
      onClose={deactivate}
      onMouseEnter={activate}
      onMouseLeave={(isOpen) => {
        if (isOpen) return;
        deactivate();
      }}
      label={labelContent}
      onWidthChange={setMenuWidth}
      slots={{
        Chevron: {
          component: ChevronWithOutline,
          props: {
            isActive,
            isVisible: Children.count(children) > 0,
          },
        },
      }}
      {...buttonWithPopoverProps}
    >
      {Children.map(children, (child, idx) => {
        return (
          <Box
            key={`menu-item-${idx}`}
            onClick={deactivate}
            sx={{
              width: menuWidth ? `${Math.max(menuWidth, 260)}px` : '100%',
            }}
          >
            {child}
          </Box>
        );
      })}
    </ButtonWithPopover>
  );
}
