import { Box, TypographyProps } from '@mui/material';
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { useFormContext } from '@/components/react-hook-form';
import { CellContainer } from '@/components/tables/DataTable/components/cells';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { getDisplayNameFromEntityType } from '@/modules/entities/utils/getDisplayNameFromEntityType';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { CSVUploadFormPaths } from '../../ImportCSVValuationsPage/ImportCSVValuationsPage.types';
import { ClientEntityRow } from '../ClientEntityCSVConnectionTable.constants';

function getSpacingProps(row: ClientEntityRow): TypographyProps {
  const rowKind = getPulidKind(row.id);
  if (rowKind === PulidKind.Entity) {
    return { pl: 4 };
  }
  return {};
}

export function useCSVTableColumns(): Column<ClientEntityRow>[] {
  return useMemo(() => {
    const columns: Column<ClientEntityRow>[] = [
      {
        field: 'name',
        headerName: 'Client & entities',
        flex: 1,
        renderCell: TwoLineTextRenderer({
          lineOne: ({ row }) => row.name,
          lineTwo: ({ row }) =>
            row.entityType ? getDisplayNameFromEntityType(row.entityType) : '',
          lineOneProps: ({ row }) => getSpacingProps(row),
          lineTwoProps: ({ row }) => getSpacingProps(row),
        }),
        sortable: false,
      },
      {
        field: 'linkedAccountIds',
        headerName: 'IDs in CSV file',
        flex: 1,
        renderCell: LinkedAccountInputRenderer,
        sortable: false,
      },
    ];

    return columns;
  }, []);
}

function LinkedAccountInputRenderer({
  row,
  colDef,
}: GridRenderCellParams<
  ClientEntityRow,
  unknown,
  unknown,
  GridTreeNodeWithRender
>) {
  const { control } = useFormContext();
  const rowKind = getPulidKind(row.id);
  const householdId = row.path[0];
  return (
    <CellContainer align={colDef.align}>
      {rowKind === PulidKind.Entity && (
        <Box width="100%">
          <FormAwareTextInput
            fieldName={
              `accountIdsByHousehold.${householdId}.entityIdToAccountIds.${row.id}` as const satisfies CSVUploadFormPaths
            }
            label="Linked account IDs"
            hideLabel
            control={control}
            // we need to do this because the input is inside a grid cell, and there are a number
            // of event listeners on the grid cell that we don't want to trigger when the input is focused
            // see more: https://stackoverflow.com/questions/71055614/why-is-the-space-key-being-filtered-out-by-muis-text-field-component
            onKeyDown={(e) => e.stopPropagation()}
          />
        </Box>
      )}
    </CellContainer>
  );
}
