import { Node } from '@/components/diagrams/FlowChart';
import { diagnostics } from '@/utils/diagnostics';

import { applyDerivedDataToState, generateWaterfallGraphState } from '../state';
import { EstateWaterfallActionFn, UndoNodeGroupAction } from '../types';

export const undoNodeGroupAction: EstateWaterfallActionFn<
  UndoNodeGroupAction
> = (state, _action, _queueEffect) => {
  if (!state.undoGroupingState?.waterfall) {
    diagnostics.error(`Tried to undo a grouping action without an undo state`);
    return state;
  }

  const undoState = {
    ...state,
    waterfall: state.undoGroupingState.waterfall,
  };

  const { nodes, edges, waterfallGraph } =
    generateWaterfallGraphState(undoState);

  const { derivedData } = applyDerivedDataToState(state);
  const { nodesById } = derivedData;

  const updatedNodes = nodes.map((n) => {
    const existingNode = nodesById[n.id];
    if (!existingNode) return n;
    // We only update the node data, but leave everything about position, height, width, etc. alone
    // since we've already computed it from when the chart first initialized
    return { ...existingNode, data: { ...n.data } } as Node;
  });

  return {
    ...undoState,
    waterfallGraph,
    nodes: updatedNodes,
    edges,
  };
};
