import { findIndex, groupBy, isEmpty, keyBy } from 'lodash';
import { useCallback } from 'react';
import { FieldArrayPathValue } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { AISuggestionsMatcherArrayField } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import { CombinedSupportedSubformFieldTypes } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { PathsOf } from '@/types/subform';

// An object that describes the array field to update in the subform, and new
// inputs to add / merge into that array field.
export interface ArrayFieldToUpdate extends ArrayFieldWithID {
  // New inputs to add / merge this array field
  newInputs: FieldArrayPathValue<
    CombinedSupportedSubformFieldTypes,
    AISuggestionsMatcherArrayField
  >;
}

// An object that describes the array field in a subform, including the idField
// that is used to identify one input element.
export interface ArrayFieldWithID {
  // The full path to the array field in the entity form, e.g., 'trustDetailsSubform.trustees'
  arrayFieldFullPath: AISuggestionsMatcherArrayField;
  // The id field of the input element that represents the value e.g., 'id' or 'clientProfileId'
  idField: string;
}

interface UseUpdateSubformArrayFieldReturn {
  // Given an array of updates, this function will update the entries of array
  // fields in a form. If there is an existing item in the form's array field
  // with the same id, the new input will overwrite the existing one.
  // This will reset the form to trigger a re-render and block navigation.
  updateArrayFields: (updates: ArrayFieldToUpdate[]) => void;

  // Given an array of updates, this function will update the entries of array
  // fields in a form. This will append the new inputs to the form's array
  // fields. This will reset the form to trigger a re-render and block navigation.
  appendArrayFields: (updates: ArrayFieldToUpdate[]) => void;

  // Given an array of array fields in a form, return the first array field that
  // has duplicate input elements, based on the value of the idField.
  getFirstDuplicateFieldName: (
    arrayFields: ArrayFieldWithID[]
  ) => PathsOf<CombinedSupportedSubformFieldTypes> | undefined;
}

/**
 * A hook that exposes a function to update the entries of array fields in a form.
 */
export function useUpdateSubformArrayField(): UseUpdateSubformArrayFieldReturn {
  const { getValues, reset, setValue, setShouldBlockNavigation } =
    useFormContext<CombinedSupportedSubformFieldTypes>();

  const updateArrayFields = useCallback(
    (updates: ArrayFieldToUpdate[]) => {
      if (isEmpty(updates)) return;

      updates.forEach((update) => {
        const { arrayFieldFullPath, newInputs, idField } = update;

        const currentValuesById = keyBy(getValues(arrayFieldFullPath), idField);
        const newValuesById = keyBy(newInputs, idField);

        // Merge the current array values with the new array values.
        const mergedValuesById = {
          ...currentValuesById,
          ...newValuesById,
        };

        // Remove the placeholder input element, if we have at least 1 "real"
        // input element now.
        let nonEmptyIds = Object.keys(mergedValuesById);
        if (Object.keys(mergedValuesById).length > 1) {
          nonEmptyIds = Object.keys(mergedValuesById).filter(
            (key) => key !== ''
          );
        }

        const newValues = nonEmptyIds.map((id) => mergedValuesById[id]);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // We are ignoring the type error here because the data types between
        // useFormContext and useFieldArray are not compatible, unfortunately.
        setValue(arrayFieldFullPath, newValues);
      });

      reset(getValues());
      setShouldBlockNavigation(true);
    },
    [getValues, reset, setShouldBlockNavigation, setValue]
  );

  const appendArrayFields = useCallback(
    (updates: ArrayFieldToUpdate[]) => {
      if (isEmpty(updates)) return;

      updates.forEach((update) => {
        const { arrayFieldFullPath, newInputs, idField } = update;

        const combined = [
          ...(getValues(arrayFieldFullPath) || []),
          ...(newInputs || []),
        ];

        // Remove the placeholder input element, if we have at least 1 "real"
        // input element now.
        let newValues = combined;
        if (combined.length > 1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // We are ignoring the type error here because the data types between
          // useFormContext and useFieldArray are not compatible, unfortunately.
          newValues = combined.filter((input) => input[idField] !== '');
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // We are ignoring the type error here because the data types between
        // useFormContext and useFieldArray are not compatible, unfortunately.
        setValue(arrayFieldFullPath, newValues);
      });

      reset(getValues());
      setShouldBlockNavigation(true);
    },
    [getValues, reset, setShouldBlockNavigation, setValue]
  );

  const getFirstDuplicateFieldName = useCallback(
    (
      arrayFields: ArrayFieldWithID[]
    ): PathsOf<CombinedSupportedSubformFieldTypes> | undefined => {
      if (isEmpty(arrayFields)) return undefined;

      let dupeId: string | undefined;
      let dupeArrayField: ArrayFieldWithID | undefined;

      arrayFields.forEach((field) => {
        const valuesById = groupBy(
          getValues(field.arrayFieldFullPath),
          field.idField
        );

        Object.entries(valuesById).forEach(([key, values]) => {
          if (values.length > 1) {
            dupeId = key;
            dupeArrayField = field;
          }
          if (dupeId && dupeArrayField) {
            return;
          }
        });

        if (dupeId && dupeArrayField) {
          return;
        }
      });

      if (!dupeId || !dupeArrayField) return undefined;

      const { arrayFieldFullPath, idField } = dupeArrayField;
      const values = getValues(arrayFieldFullPath);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // We are ignoring the type error here because the data types between
      // useFormContext and useFieldArray are not compatible, unfortunately.
      const index = findIndex(values, (v) => v[idField] === dupeId);
      if (index === -1) return undefined;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // We are ignoring the type error here because the data types between
      // useFormContext and useFieldArray are not compatible, unfortunately.
      return `${arrayFieldFullPath}.${index}.${idField}`;
    },
    [getValues]
  );

  return {
    updateArrayFields,
    appendArrayFields,
    getFirstDuplicateFieldName,
  };
}
