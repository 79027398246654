import { Position } from '@xyflow/react';

import { Edge } from '@/components/diagrams/FlowChart';
import { EntityGraphViewOrientation } from '@/types/schema';

import { EntityGraph } from '../types';

export const DefaultOrientationToTargetHandle: Record<
  EntityGraphViewOrientation,
  Pick<Edge, 'sourceHandle' | 'targetHandle'>
> = {
  [EntityGraphViewOrientation.TopBottom]: {
    sourceHandle: Position.Bottom,
    targetHandle: Position.Top,
  },
  [EntityGraphViewOrientation.BottomTop]: {
    sourceHandle: Position.Top,
    targetHandle: Position.Bottom,
  },
  [EntityGraphViewOrientation.LeftRight]: {
    sourceHandle: Position.Right,
    targetHandle: Position.Left,
  },
  [EntityGraphViewOrientation.RightLeft]: {
    sourceHandle: Position.Left,
    targetHandle: Position.Right,
  },
};

export type EdgeInput = Omit<Edge, 'id' | 'type'>;
export const createEdge = (
  graph: EntityGraph,
  { source, target, data, ...edge }: EdgeInput
): Edge => ({
  id: `${source}:${target}`,
  source,
  target,
  type: 'arrow',
  data: { hideLabel: true, ...data },
  ...DefaultOrientationToTargetHandle[graph.getAttribute('orientation')],
  ...edge,
});

export function toggleEdgeLabelVisibility(
  e: Edge,
  opts: { hideLabel?: boolean } = {}
) {
  if (e.type !== 'arrow') return e;
  const hideLabel = opts.hideLabel ?? !e.data?.hideLabel;
  return { ...e, data: { ...e.data, hideLabel } };
}
