import { UnreachableError } from '@/utils/errors';

import { ArrowMarker } from '../../components/markers/ArrowMarker';
import { useFlowChartContext } from '../context/flowChart.context';

/**
 * @description Unforunately, DOM properties on SVG marker elements CANNOT be updated dynamically.
 * Simply put, they must be initialized once, and never modified. This is a big issue for react's dynamic prop based architecture.
 *
 * Our solution is to enumerate every possible option into the DOM.
 * Use the accompanying useMarkerId hook for providing custom markers with dynamic props.
 * See for context: https://stackoverflow.com/questions/16664584/changing-an-svg-markers-color-css
 */
export const MarkerDefinitions = () => {
  const {
    __internal: { markers },
  } = useFlowChartContext();

  const Markers = Object.entries(markers).map(([id, { type, props }]) => {
    switch (type) {
      case 'arrow':
        return <ArrowMarker key={id} id={id} {...props} />;
      default:
        throw new UnreachableError({
          case: type,
          message: `Undefined marker type ${type}, provide a render function in MarkerDefinitions.tsx`,
        });
    }
  });

  return (
    <svg>
      <defs>{Markers}</defs>
    </svg>
  );
};
