import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { useMemo } from 'react';

import { FileCheck02Icon } from '@/components/icons/FileCheck02Icon';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { DOCUMENT_TYPE_OPTIONS } from '@/modules/documents/documents.constants';
import { COLORS } from '@/styles/tokens/colors';
import { DocumentType } from '@/types/schema';
import {
  formatDateToMonDDYYYY,
  formatDateToMonDDYYYYWithTime,
} from '@/utils/formatting/dates';

export interface FileRepresentationProps {
  sx?: SxProps<Theme>;
  fileName: string;
  fileNameOverflow?: 'ellipsis' | 'multiline';
  uploadedAt: Date;
  uploadedAtFormat?: 'date-only' | 'date-time';
  uploadedBy: string;
  actions?: React.ReactNode;
  documentType?: DocumentType | undefined;
  tooltipOnHover?: boolean;
  hideIcon?: boolean;
}

export function FileRepresentation({
  sx,
  fileName,
  fileNameOverflow = 'multiline',
  documentType,
  uploadedAt,
  uploadedAtFormat = 'date-time',
  uploadedBy,
  actions,
  tooltipOnHover = false,
  hideIcon = false,
}: FileRepresentationProps) {
  const documentTypeDisplay = useMemo(
    () =>
      DOCUMENT_TYPE_OPTIONS.find(
        (documentTypeOption) => documentTypeOption.value === documentType
      )?.display,
    [documentType]
  );

  const uploadedAtDisplay =
    uploadedAtFormat === 'date-time'
      ? formatDateToMonDDYYYYWithTime(uploadedAt)
      : formatDateToMonDDYYYY(uploadedAt);

  const fileNameTypographyProps =
    fileNameOverflow === 'ellipsis'
      ? ({
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        } as const)
      : {};

  return (
    <Stack
      direction="row"
      p={2}
      alignItems="center"
      justifyContent="space-between"
      border={`solid ${COLORS.GRAY[200]} 1px`}
      borderRadius={1}
      sx={{
        width: '100%',
        overflow: 'hidden',
        background: 'white',
        ...sx,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        width="100%"
        overflow="hidden"
      >
        {!hideIcon && <FileCheck02Icon color={COLORS.GRAY[400]} />}
        <Stack spacing={0.5} width="100%" overflow="hidden">
          <Tooltip title={tooltipOnHover ? fileName : ''}>
            <Typography
              variant="h5"
              component="div"
              {...fileNameTypographyProps}
            >
              {fileName}
            </Typography>
          </Tooltip>
          {documentTypeDisplay && (
            <Typography variant="subtitle2" component="div">
              {documentTypeDisplay}
            </Typography>
          )}
          <Typography variant="subtitle2" component="div">
            Uploaded {uploadedAtDisplay} by {uploadedBy}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        {actions && actions}
      </Stack>
    </Stack>
  );
}
