import React from 'react';

export interface ArrowMarkerProps {
  id: string;
  color: string;
}

export function ArrowMarker({ color, id }: ArrowMarkerProps) {
  return (
    <marker
      id={id}
      markerWidth="10"
      markerHeight="12"
      viewBox="-10 -10 20 20"
      orient="auto"
      refX="0"
      refY="0"
    >
      <polyline
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        fill={color}
        points="-12,-6 0,0 -12,6 -12,-6"
      />
    </marker>
  );
}
