import {
  JointPersonalAccountType,
  RetirementPersonalAccountType,
} from '@/types/schema';

/** JOINT ACCOUNT TYPE TYPES */
export const JOINT_ACCOUNT_TYPE_DISPLAY_NAMES: Record<
  JointPersonalAccountType,
  string
> = {
  [JointPersonalAccountType.CommunityProperty]: 'Community property',
  [JointPersonalAccountType.CommunityPropertyWithRightOfSurvivorship]:
    'Community property with right of survivorship',
  [JointPersonalAccountType.JointTenants]: 'Joint tenants',
  [JointPersonalAccountType.JointTenantsWithRightOfSurvivorship]:
    'Joint tenants with right of survivorship',
  [JointPersonalAccountType.TenantsByEntirety]: 'Tenants by entirety',
  [JointPersonalAccountType.TenantsInCommon]: 'Tenants in common',
};

/** RETIREMENT ACCOUNT TYPES */
export const RETIREMENT_ACCOUNT_TYPE_DISPLAY_NAMES: Record<
  RetirementPersonalAccountType,
  string
> = {
  [RetirementPersonalAccountType.Ira]: 'IRA',
  [RetirementPersonalAccountType.InheritedIra]: 'Inherited IRA',
  [RetirementPersonalAccountType.RothIra]: 'Roth IRA',
  [RetirementPersonalAccountType.InheritedRothIra]: 'Inherited Roth IRA',
  [RetirementPersonalAccountType.Type_401K]: '401k',
  [RetirementPersonalAccountType.DefinedBenefitPlan]:
    'Defined benefit plan/pension',
  [RetirementPersonalAccountType.Other]: 'Other',
};
