import { Box } from '@mui/material';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { Settings04Icon } from '@/components/icons/Settings04Icon';
import { BalanceSheetConfigurationPopper } from '@/modules/balanceSheet/BalanceSheetConfigurationPopper/BalanceSheetConfigurationPopper';

export function ConfigureViewButton() {
  return (
    <ButtonWithPopover
      hideChevron
      keepOpen
      variant="secondary"
      size="sm"
      startIcon={Settings04Icon}
      label="Configure view"
      popperVariant="menuBelow"
    >
      <Box
        sx={{
          px: 3,
          py: 2,
          minWidth: 350,
        }}
      >
        <BalanceSheetConfigurationPopper />
      </Box>
    </ButtonWithPopover>
  );
}
