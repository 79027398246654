import { first } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwareMultiSelectInput } from '@/components/form/formAwareInputs/FormAwareMultiSelectInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import {
  ColumnWidths,
  FormLayoutItem,
  FormLayoutRow,
} from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.constants';
import { AIOnboardingModalFormShape } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { useOnboardingEntityPrincipalOptions } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/hooks/useOnboardingEntityPrincipalOptions';
import { useAIOnboardingModalContext } from '@/modules/aiOnboarding/AIOnboardingModal/context/AIOnboardingModal.context';
import { useIsUserLoggedIntoAddepar } from '@/modules/assetProviderIntegrations/addepar/hooks/useIsAddeparEnabled';
import { useIsUserLoggedIntoBlackDiamond } from '@/modules/assetProviderIntegrations/blackDiamond/hooks/useIsBlackDiamondEnabled';
import { AssetIntegrationProviders } from '@/modules/assetProviderIntegrations/shared/constants';
import { IntegrationEntitiesTypeahead } from '@/modules/assetProviderIntegrations/shared/IntegrationEntitiesTypeahead/IntegrationEntitiesTypeahead';
import { getEntityShortFormOptions } from '@/modules/entities/EntityShortFormModal/CreateEntityShortForm/CreateEntityShortForm.utils';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { PathsOf } from '@/types/subform';

interface EntityFieldsRowProps {
  index: number;
  suggestionID: string | undefined;
}

export function EntityFieldsRow({ index, suggestionID }: EntityFieldsRowProps) {
  const { styles, columns } =
    AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS.entities;

  const { control, setValue } = useFormContext<AIOnboardingModalFormShape>();

  const entityTypeOptions = useMemo(() => getEntityShortFormOptions(), []);

  const entityType = useWatch({
    control,
    name: `entities.${index}.entityType` as const satisfies PathsOf<AIOnboardingModalFormShape>,
  });

  const {
    allowMultiplePrincipals,
    clientProfileOptions,
    businessEntityOwnerEntityOptions,
  } = useOnboardingEntityPrincipalOptions({
    suggestionID,
    entityType,
  });

  const allOptions = useMemo(
    () => [
      ...clientProfileOptions,
      ...businessEntityOwnerEntityOptions, // This will be empty for non-business entity types
    ],
    [businessEntityOwnerEntityOptions, clientProfileOptions]
  );

  const showMultiSelect = allowMultiplePrincipals && allOptions.length > 1;

  useEffect(() => {
    const firstOption = first(allOptions);
    if (allOptions.length !== 1 || !firstOption) return;

    // If there is only one principal option, select it by default
    if (showMultiSelect) {
      setValue(
        `entities.${index}.principalIds` as const satisfies PathsOf<AIOnboardingModalFormShape>,
        [firstOption.value]
      );
    } else {
      setValue(
        `entities.${index}.principalId` as const satisfies PathsOf<AIOnboardingModalFormShape>,
        firstOption.value
      );
    }
  }, [allOptions, index, setValue, showMultiSelect]);

  return (
    <>
      <FormLayoutRow sx={{ gap: styles.columnPaddingHorizontal }}>
        <FormLayoutItem width={columns.entityType.columnWidth}>
          <FormAwareSelectInput<AIOnboardingModalFormShape>
            control={control}
            fieldName={
              `entities.${index}.entityType` as const satisfies PathsOf<AIOnboardingModalFormShape>
            }
            label={columns.entityType.columnHeader}
            options={entityTypeOptions}
            hideLabel
            required
          />
        </FormLayoutItem>
        <FormLayoutItem width={columns.entityName.columnWidth}>
          <FormAwareTextInput<AIOnboardingModalFormShape>
            control={control}
            fieldName={
              `entities.${index}.entityName` as const satisfies PathsOf<AIOnboardingModalFormShape>
            }
            label={columns.entityName.columnHeader}
            hideLabel
            required
          />
        </FormLayoutItem>
        <FormLayoutItem width={columns.principalIds.columnWidth}>
          {showMultiSelect ? (
            <FormAwareMultiSelectInput
              control={control}
              fieldName={
                `entities.${index}.principalIds` as const satisfies PathsOf<AIOnboardingModalFormShape>
              }
              label={columns.principalIds.columnHeader}
              options={allOptions}
              hideLabel
              required
            />
          ) : (
            <FormAwareSelectInput<AIOnboardingModalFormShape>
              control={control}
              fieldName={
                `entities.${index}.principalId` as const satisfies PathsOf<AIOnboardingModalFormShape>
              }
              label={columns.principalIds.columnHeader}
              options={allOptions}
              hideLabel
              required
            />
          )}
        </FormLayoutItem>
      </FormLayoutRow>
      <IntegrationEntityTypeaheadFieldRows
        index={index}
        rowPaddingVertical={styles.rowPaddingVertical}
        columnWidth={columns.integrationEntityIds.columnWidth}
      />
    </>
  );
}

interface IntegrationEntityTypeaheadFieldRowProps {
  index: number;
  rowPaddingVertical: number;
  columnWidth: ColumnWidths;
}

function IntegrationEntityTypeaheadFieldRows({
  index,
  rowPaddingVertical,
  columnWidth,
}: IntegrationEntityTypeaheadFieldRowProps) {
  const { control } = useFormContext<AIOnboardingModalFormShape>();

  const { isHouseholdLinkedToBlackDiamond, isHouseholdLinkedToAddepar } =
    useHouseholdDetailsContext();
  const isUserLoggedIntoAddepar = useIsUserLoggedIntoAddepar();
  const isUserLoggedIntoBlackDiamond = useIsUserLoggedIntoBlackDiamond();

  const {
    household: { id: householdId },
  } = useAIOnboardingModalContext();

  if (!isHouseholdLinkedToAddepar && !isHouseholdLinkedToBlackDiamond) {
    return null;
  }

  return (
    <>
      {isHouseholdLinkedToAddepar && (
        <FormLayoutRow sx={{ marginTop: -rowPaddingVertical }}>
          <FormLayoutItem width={columnWidth}>
            <IntegrationEntitiesTypeahead<AIOnboardingModalFormShape>
              control={control}
              filterToHouseholdId={householdId}
              fieldName={
                `entities.${index}.integrationEntityIds` as const satisfies PathsOf<AIOnboardingModalFormShape>
              }
              allowAllEntities={false}
              notLoggedIn={!isUserLoggedIntoAddepar}
              provider={AssetIntegrationProviders.ADDEPAR}
              placeholder="Search for entities in Addepar to link to"
              label="Search for entities in Addepar to link to"
              hideLabel
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      {isHouseholdLinkedToBlackDiamond && (
        <FormLayoutRow sx={{ marginTop: -rowPaddingVertical }}>
          <FormLayoutItem width={columnWidth}>
            <IntegrationEntitiesTypeahead<AIOnboardingModalFormShape>
              control={control}
              fieldName={
                `entities.${index}.integrationEntityIds` as const satisfies PathsOf<AIOnboardingModalFormShape>
              }
              filterToHouseholdId={householdId}
              allowAllEntities={false}
              notLoggedIn={!isUserLoggedIntoBlackDiamond}
              provider={AssetIntegrationProviders.BLACK_DIAMOND}
              placeholder="Search for entities in Black Diamond to link to"
              label="Search for entities in Black Diamond to link to"
              hideLabel
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
    </>
  );
}
