import { useCallback } from 'react';

import {
  CompleteInitializationEffect,
  EstateWaterfallEffectFn,
} from '../types';

export function useCompleteInitialiationEffect(): EstateWaterfallEffectFn<CompleteInitializationEffect> {
  return useCallback<EstateWaterfallEffectFn<CompleteInitializationEffect>>(
    (_state, _effect, dispatch) => {
      dispatch({ type: 'COMPLETE_INITIALIZATION' });
    },
    []
  );
}
