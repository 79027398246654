import { BaseSyntheticEvent, useEffect, useRef } from 'react';
import { Path, useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { GiftDesignerModelScenariosFormShape } from '@/modules/gifting/proposal/designer/form';

// Hook to save the gift designer when a scenario is removed
export function useSaveGiftDesignerOnScenarioRemoved(
  onSubmitSaveModelScenarios: (
    e?: BaseSyntheticEvent<object, unknown, unknown> | undefined
  ) => Promise<void>
) {
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();

  const scenarios = useWatch({
    control,
    name: 'scenarios' as const satisfies Path<GiftDesignerModelScenariosFormShape>,
  });

  const scenariosLength = scenarios?.length ?? 0;

  const prevScenariosLength = useRef(scenariosLength);

  useEffect(() => {
    if (scenariosLength !== prevScenariosLength.current) {
      if (scenariosLength < prevScenariosLength.current) {
        void onSubmitSaveModelScenarios();
      }

      prevScenariosLength.current = scenariosLength;
    }
  }, [onSubmitSaveModelScenarios, scenariosLength]);
}
