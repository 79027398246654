import { Attributes } from 'graphology-types';
import { keyBy } from 'lodash';

import { DirectedGraph } from './DirectedGraph';

export interface FlowChartGraphNodeAttributes {
  // Node
  node: { id: string };
  data: object;
}

export interface FlowChartGraphEdgeAttributes {
  edge: {
    target?: string;
    source?: string;
  };
}

/**
 * TODO (LUM-2026): Maybe move this to the FlowChart component
 * @description Extends our DirectedGraph instance with some helpers specific to FlowChart components.
 */
export class FlowChartGraph<
  N extends FlowChartGraphNodeAttributes,
  E extends FlowChartGraphEdgeAttributes,
  G extends Attributes,
> extends DirectedGraph<N, E, G> {
  getEdges = (): E['edge'][] => {
    return this.mapEdges((_nodeId, { edge }) => edge);
  };

  getNodes = (): N['node'][] => {
    return this.mapNodes((_nodeId, { node }) => node);
  };

  getNodesById = (): Record<string, N> => {
    return keyBy(
      this.mapNodes((_nodeId, nodeAttrs) => nodeAttrs),
      (node) => node.node.id
    );
  };

  getInboundNodesByNodeId = (): Record<string, string[]> => {
    const edges = this.getEdges();
    const nodeIdToInboundNodes: Record<string, string[]> = {};

    edges.forEach((e) => {
      const target = e.target;
      const source = e.source;

      if (!target || !source) {
        return;
      }

      if (nodeIdToInboundNodes[target]) {
        nodeIdToInboundNodes[target]?.push(source);
      } else {
        nodeIdToInboundNodes[target] = [source];
      }
    });

    return nodeIdToInboundNodes;
  };
}
