import { gridStringOrNumberComparator } from '@mui/x-data-grid-pro';

import { LegendColorRenderer } from '@/components/tables/DataTable/renderers/cell/LegendColorRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { AfterDeath } from '@/types/schema';

import { EstateWaterfall_NodeFragment } from '../../graphql/EstateWaterfall.generated';

export type OnSelectionChange = ({
  selectedRowIds,
  rows,
}: {
  selectedRowIds: string[];
  rows: MultiSelectTableRow[];
}) => void;

export interface MultiSelectTableRow {
  node: EstateWaterfall_NodeFragment;
  afterDeath: AfterDeath;
  id: string;
  sectionIndicator: {
    backgroundColor: string;
  };
  entity: {
    lineOne: string;
    lineTwo: string;
  };
}

export const sectionIndicatorColumn: Column<MultiSelectTableRow> = {
  field: 'sectionIndicator',
  headerName: '',
  renderCell: LegendColorRenderer({
    backgroundColor: ({ row }) => row.sectionIndicator.backgroundColor,
  }),
  align: 'center',
  sortable: false,
  width: 55,
  minWidth: 55,
  maxWidth: 55,
};

export const entityColumn: Column<MultiSelectTableRow> = {
  field: 'entity',
  headerName: 'Items',
  flex: 1,
  renderCell: TwoLineTextRenderer({
    lineOneProps: {
      component: 'p',
      variant: 'h5',
    },
  }),
  sortComparator: (
    v1: MultiSelectTableRow['entity'],
    v2: MultiSelectTableRow['entity'],
    p1,
    p2
  ) => {
    return gridStringOrNumberComparator(v1.lineOne, v2.lineOne, p1, p2);
  },
};
