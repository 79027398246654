import { uniq } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { BASIC_INFORMATION_SUBFORM_NAMESPACE as BASIC_INFORMATION_SUBFORM_NAMESPACE } from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import {
  GrantorsFieldTypes_MultipleGrantor,
  GrantorsFieldTypes_SingleGrantor,
} from '@/modules/entities/principals/GrantorsField/GrantorsField.types';

import { SubformsCombinedType } from './EntitySubforms.types';

export function useSelectedPrincipals() {
  const { control } = useFormContext<SubformsCombinedType>();

  const basicInformationSubform = useWatch({
    name: BASIC_INFORMATION_SUBFORM_NAMESPACE,
    control,
  });

  return useMemo(() => {
    const selectedPrincipalClientProfildIds: string[] = [];

    if (basicInformationSubform && 'grantor' in basicInformationSubform) {
      const singleGrantorField: GrantorsFieldTypes_SingleGrantor['grantor'] =
        basicInformationSubform.grantor;
      if (singleGrantorField && 'clientProfileId' in singleGrantorField) {
        selectedPrincipalClientProfildIds.push(
          singleGrantorField.clientProfileId
        );
      }
    }

    if (basicInformationSubform && 'grantors' in basicInformationSubform) {
      const multiGrantorField: GrantorsFieldTypes_MultipleGrantor['grantors'] =
        basicInformationSubform.grantors;

      multiGrantorField.forEach((grantor) => {
        selectedPrincipalClientProfildIds.push(grantor.clientProfileId);
      });
    }

    if (
      basicInformationSubform &&
      'owner' in basicInformationSubform &&
      basicInformationSubform?.owner
    ) {
      selectedPrincipalClientProfildIds.push(
        basicInformationSubform.owner.ownerId
      );
    }

    if (
      basicInformationSubform &&
      'owners' in basicInformationSubform &&
      basicInformationSubform?.owners
    ) {
      basicInformationSubform.owners.forEach((owner) => {
        selectedPrincipalClientProfildIds.push(owner.ownerId);
      });
    }

    return uniq(selectedPrincipalClientProfildIds);
  }, [basicInformationSubform]);
}
