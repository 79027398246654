import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import {
  ButtonWithPopover,
  ButtonWithPopoverProps,
} from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { SlashCircle01Icon } from '@/components/icons/SlashCircle01Icon';
import { ActionTile } from '@/components/layout/ActionTile/ActionTile';
import { Grid } from '@/components/layout/Grid';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useModalState } from '@/hooks/useModalState';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { COLORS } from '@/styles/tokens/colors';
import { DispositiveProvisionTemplateKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { mapProvisionToRecipient } from '../../dispositiveProvisions.utils';
import { useDispositiveProvisionsTemplateSplitScreenModalContext } from '../../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.context';
import {
  DispositiveProvisionTemplateDetails,
  NEW_TEMPLATE_SENTINEL,
} from '../../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.types';
import { useDispositiveProvisionTemplatesQuery } from './graphql/DispositiveProvisionTemplates.generated';

export interface DispositiveProvisionTemplateMenuProps
  extends Omit<
    ButtonWithPopoverProps,
    'loading' | 'onClick' | 'popperVariant' | 'size'
  > {
  householdId: string;
  onClick?: (arg: DispositiveProvisionTemplateDetails) => void;
}

export function DispositiveProvisionTemplateMenu({
  householdId,
  onClick: onClickExternal,
  ...popoverProps
}: DispositiveProvisionTemplateMenuProps) {
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const { setDispositiveProvisionTemplateDetails } =
    useDispositiveProvisionsTemplateSplitScreenModalContext();

  const onClick = onClickExternal || setDispositiveProvisionTemplateDetails;

  const { data, loading } = useDispositiveProvisionTemplatesQuery({
    variables: {
      where: {
        hasHouseholdWith: [
          {
            id: householdId,
          },
        ],
      },
    },
    skip: !householdId,
    fetchPolicy: 'cache-and-network',
  });

  const templates = useMemo(() => {
    return getNodes(data?.dispositiveProvisionTemplates);
  }, [data?.dispositiveProvisionTemplates]);

  const { availableStateEstateTaxes } = useIRSConstants();
  const { primaryClients } = useHouseholdDetailsContext();

  const hasClientWithEstateTaxState = primaryClients?.some(
    (client) =>
      client.stateCode && availableStateEstateTaxes?.includes(client.stateCode)
  );

  return (
    <>
      <ButtonWithPopover
        {...popoverProps}
        loading={loading}
        popperVariant="menuBelow"
        size="sm"
        data-testid="disposition-templates-menu"
      >
        <MenuItem
          label="Create new template"
          onClick={() => {
            openModal();
          }}
          muiMenuItemProps={{ divider: !!templates.length }}
          icon={<PlusIcon size={18} />}
        />
        {templates.map((template) => (
          <MenuItem
            label={template.displayName}
            onClick={() =>
              onClick({
                templateId: template.id,
                recipients: getNodes(template.dispositiveProvisions).map(
                  (provision) => mapProvisionToRecipient(provision)
                ),
              })
            }
            key={template.id}
          />
        ))}
      </ButtonWithPopover>
      <DialogModal
        heading="Create template from scratch or use a preset"
        isOpen={isModalOpen}
        onClose={() => {
          closeModal();
        }}
      >
        <Grid container spacing={2} flex={1} columns={12}>
          {isTwoClientHousehold && (
            <>
              <Grid item sm={6}>
                <ActionTile
                  containerSx={{ height: '100%', width: '100%' }}
                  variant="small"
                  onClick={() => {
                    onClick({
                      templateId: NEW_TEMPLATE_SENTINEL,
                      templateType:
                        DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse,
                    });
                    closeModal();
                  }}
                  heading="Outright to surviving spouse"
                  body="All assets distribute outright to the surviving spouse upon 1st grantor’s death, with an option to disclaim a portion of the assets"
                />
              </Grid>
              <Grid item sm={6}>
                <ActionTile
                  containerSx={{ height: '100%', width: '100%' }}
                  variant="small"
                  onClick={() => {
                    onClick({
                      templateId: NEW_TEMPLATE_SENTINEL,
                      templateType:
                        DispositiveProvisionTemplateKind.MaritalTrust,
                    });
                    closeModal();
                  }}
                  heading="Marital trust"
                  body="All assets distribute to a marital trust FBO surviving spouse upon 1st grantor's death. Option to disclaim or QTIP all or portion of the assets."
                />
              </Grid>
              <Grid item sm={6}>
                <ActionTile
                  containerSx={{ height: '100%', width: '100%' }}
                  variant="small"
                  onClick={() => {
                    onClick({
                      templateId: NEW_TEMPLATE_SENTINEL,
                      templateType: DispositiveProvisionTemplateKind.AbTrust,
                    });
                    closeModal();
                  }}
                  heading="A/B trust"
                  body="Establish combo of bypass & marital trusts upon death of 1st grantor, with an option to include a survivor’s trust"
                />
              </Grid>
              {hasClientWithEstateTaxState && (
                <Grid item sm={6}>
                  <ActionTile
                    containerSx={{ height: '100%', width: '100%' }}
                    variant="small"
                    onClick={() => {
                      onClick({
                        templateId: NEW_TEMPLATE_SENTINEL,
                        templateType: DispositiveProvisionTemplateKind.AbcTrust,
                      });
                      closeModal();
                    }}
                    heading="A/B/C trust"
                    body="Establish combo of bypass & marital trusts. Assumes state QTIP election is made on marital trust."
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item sm={6}>
            <ActionTile
              containerSx={{ height: '100%', width: '100%' }}
              variant="small"
              onClick={() => {
                onClick({
                  templateId: NEW_TEMPLATE_SENTINEL,
                  templateType: DispositiveProvisionTemplateKind.PouroverWill,
                });
                closeModal();
              }}
              heading="Pour-over will"
              body="Assets not transferred into trust during the grantor’s lifetime is put into a revocable trust upon their death"
            />
          </Grid>
          <Grid item sm={6}>
            <ActionTile
              containerSx={{ height: '100%', width: '100%' }}
              variant="small"
              onClick={() => {
                onClick({
                  templateId: NEW_TEMPLATE_SENTINEL,
                  templateType: DispositiveProvisionTemplateKind.Default,
                });
                closeModal();
              }}
              body={
                <Stack alignItems="center">
                  <SlashCircle01Icon size={40} color={COLORS.NAVY[300]} />
                  <Typography variant="h4">No preset</Typography>
                </Stack>
              }
            />
          </Grid>
        </Grid>
      </DialogModal>
    </>
  );
}
