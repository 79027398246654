import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { DeleteButton } from '@/components/form/baseInputs/Button/DeleteButton';
import { Modal } from '@/components/modals/Modal/Modal';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';

import { useGetSubstitutionThresholdFormValues } from '../SubstitutionThresholdSubform/hooks/useGetSubstitutionThresholdFormValues';
import { useQuerySubstitutionThresholdDetails } from '../SubstitutionThresholdSubform/hooks/useQuerySubstitutionThresholdDetails';
import { SubstitutionThresholdSubform } from '../SubstitutionThresholdSubform/SubstitutionThresholdSubform';
import {
  Fields as SubsitutionThresholdSubformFields,
  NAMESPACE as SUBSTITUTION_THRESHOLD_SUBFORM_NAMESPACE,
} from '../SubstitutionThresholdSubform/types';
import { generateThresholdDetailsUpdates } from '../SubstitutionThresholdSubform/utils/mutationUtils';
import { useSetGratTrustThresholdsFromModalMutation } from './graphql/EditSubstitutionThresholdModal.generated';

export interface EditSubstitutionThresholdModalProps {
  isOpen: boolean;
  householdId: string;
  entityId: string;
  onClose: () => void;
}

const {
  FormComponent: SubstitutionThresholdForm,
  defaultValues: substitutionThresholdFormDefaultValues,
} = new SubstitutionThresholdSubform();

export function EditSubstitutionThresholdModal({
  isOpen,
  householdId,
  entityId,
  onClose,
}: EditSubstitutionThresholdModalProps) {
  const { createSuccessFeedback, createErrorFeedback } = useFeedback();
  const formMethods = useForm<SubsitutionThresholdSubformFields>({
    defaultValues: {
      [SUBSTITUTION_THRESHOLD_SUBFORM_NAMESPACE]:
        substitutionThresholdFormDefaultValues,
    },
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = formMethods;

  const watchedSubformFields = watch(SUBSTITUTION_THRESHOLD_SUBFORM_NAMESPACE);
  const {
    thresholdsData,
    loading: queryLoading,
    error: queryError,
  } = useQuerySubstitutionThresholdDetails(entityId, isOpen);
  const [updateThresholds, { error: formSubmitError, loading: submitLoading }] =
    useSetGratTrustThresholdsFromModalMutation({
      onError: createErrorFeedback(
        "We weren't able to update the substitution threshold. Please refresh the page and try again."
      ),
      onCompleted: createSuccessFeedback(
        'Successfully updated substitution threshold.'
      ),
    });
  const initialFormValues =
    useGetSubstitutionThresholdFormValues(thresholdsData);

  useReportError(
    'failed to update substitution threshold in modal',
    formSubmitError,
    {
      householdId,
      entityId,
    }
  );
  useReportError(
    'failed to load data for substitution threshold modal',
    queryError,
    { householdId, entityId }
  );

  // initialize the form values
  useEffect(() => {
    if (!initialFormValues) return;
    reset({
      [SUBSTITUTION_THRESHOLD_SUBFORM_NAMESPACE]: initialFormValues,
    });
  }, [initialFormValues, reset]);

  // reset the form values when the modal is closed
  useEffect(() => {
    if (!isOpen && isDirty) {
      reset({
        [SUBSTITUTION_THRESHOLD_SUBFORM_NAMESPACE]:
          substitutionThresholdFormDefaultValues,
      });
    }
  }, [reset, isOpen, isDirty]);

  const onSubmit = async (
    formValues: SubsitutionThresholdSubformFields | null,
    opts = { clearThresholds: false }
  ) => {
    if (!thresholdsData?.subtype.id) {
      throw new Error('Invalid form submission state');
    }

    const gratTrustInput = generateThresholdDetailsUpdates(formValues, opts);
    await updateThresholds({
      variables: {
        input: {
          id: entityId,
          update: {},
          updateGratTrust: {
            id: thresholdsData.subtype.id,
            update: gratTrustInput,
          },
        },
      },
    });

    if (!formSubmitError) {
      onClose();
    }
  };

  const onFormSubmit = handleSubmit((formValues) => {
    return onSubmit(formValues, { clearThresholds: false });
  });

  const onClearThresholds = () => {
    return onSubmit(null, { clearThresholds: true });
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  const disableInputs = Boolean(submitLoading || queryLoading || queryError);

  return (
    <FormProvider {...formMethods}>
      <Modal
        heading="Edit substitution threshold"
        onClose={handleClose}
        isOpen={isOpen}
        actions={
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box>
              <DeleteButton
                onConfirmDelete={onClearThresholds}
                confirmDeleteText="Confirm remove thresholds"
                promptDeleteText="Remove thresholds"
              />
            </Box>
            <Box>
              <Button
                size="sm"
                variant="secondary"
                disabled={disableInputs}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                disabled={disableInputs}
                size="sm"
                variant="primary"
                onClick={onFormSubmit}
              >
                Update thresholds
              </Button>
            </Box>
          </Stack>
        }
      >
        {queryError && (
          <Callout severity="error">
            We weren&apos;t able to load this information. Please refresh the
            page and try again.
          </Callout>
        )}
        <SubstitutionThresholdForm
          variant="standard"
          subformValues={watchedSubformFields}
          householdId={householdId}
          disabled={queryLoading}
        />
      </Modal>
    </FormProvider>
  );
}
