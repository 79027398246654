import { Box, Stack, Typography } from '@mui/material';

import { FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { THEME_SHADOWS } from '@/styles/themes/common';
import { COLORS } from '@/styles/tokens/colors';

interface LayoutWithSidePanelProps {
  heading: React.ReactNode;
  subheading: React.ReactNode;
  MainContent: React.ReactElement;
  SidebarContent: React.ReactElement;
  Footer: React.ReactElement;
}

export function LayoutWithSidePanel({
  heading,
  subheading,
  MainContent,
  SidebarContent,
  Footer,
}: LayoutWithSidePanelProps) {
  return (
    <Box>
      <Stack height={`calc(100vh - ${FOOTER_HEIGHT}px)`} overflow="auto">
        <Stack direction="row" width="100%" height="100%">
          <Box p={3} width="65%" overflow="auto">
            <Stack
              sx={{
                pb: 3,
                mb: 3,
                borderBottom: `solid ${COLORS.ORANGE[500]} 1px`,
              }}
            >
              <Typography variant="h1">{heading}</Typography>
              <Typography variant="subtitle1">{subheading}</Typography>
            </Stack>
            <Box>{MainContent}</Box>
          </Box>
          <Box
            width="35%"
            sx={{
              backgroundColor: COLORS.GRAY[100],
              boxShadow: THEME_SHADOWS.innerShadowRightward,
            }}
          >
            {SidebarContent}
          </Box>
        </Stack>
      </Stack>
      <Box>{Footer}</Box>
    </Box>
  );
}
