import Decimal from 'decimal.js';

import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { formatCurrency } from '@/utils/formatting/currency';

export enum LiabilitiesTableColumns {
  NAME = 'name',
  ISSUER = 'issuerName',
  RECIPIENT = 'recipientName',
  CURRENT_VALUE = 'currentValue',
  ACTIONS = 'actions',
}

export interface RowData {
  id: string;
  name: string;
  issuerName: string;
  recipientName: string;
  currentValue: Decimal;
  householdId: string;
  actions?: never;
}

export const columns: Column<RowData>[] = [
  {
    field: LiabilitiesTableColumns.NAME,
    headerName: 'Liability',
    width: 200,
    flex: 1,
    renderCell: TextRenderer({
      text: ({ row }) => row.name,
    }),
  },
  {
    field: LiabilitiesTableColumns.ISSUER,
    headerName: 'Lender',
    width: 175,
    flex: 1,
    renderCell: TextRenderer({
      text: ({ row }) => row.issuerName,
    }),
  },
  {
    field: LiabilitiesTableColumns.RECIPIENT,
    headerName: 'Debtor',
    width: 200,
    renderCell: TextRenderer({
      text: ({ row }) => row.recipientName,
    }),
  },
  {
    field: LiabilitiesTableColumns.CURRENT_VALUE,
    headerName: 'Current value',
    width: 200,
    renderCell: TextRenderer({
      text: ({ row }) => formatCurrency(row.currentValue),
    }),
  },
  {
    field: LiabilitiesTableColumns.ACTIONS,
    headerName: '',
    align: 'center',
    width: 64,
    minWidth: 64,
    maxWidth: 64,
    renderCell: IconRenderer({
      icon: Edit02Icon,
    }),
    sortable: false,
  },
];
