import { isHypotheticalWaterfall } from '@/modules/estateWaterfall/EstateWaterfall.utils';
import { isRecipientOfDistribution } from '@/modules/estateWaterfall/graphql';
import { EXTERNAL_TRANSFER_NODE_IDENTIFIER } from '@/modules/estateWaterfall/waterfallGraph/constants';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ClientProfileSummaryPanel } from '@/modules/summaryPanels/ClientProfileSummaryPanel/ClientProfileSummaryPanel';
import { EntitySummaryPanel } from '@/modules/summaryPanels/EntitySummaryPanel/EntitySummaryPanel';
import { EstateTaxSummaryPanel } from '@/modules/summaryPanels/EstateTaxSummaryPanel/EstateTaxSummaryPanel';
import { EstateWaterfallSummaryTileSummaryPanel } from '@/modules/summaryPanels/EstateWaterfallSummmaryTileSummaryPanel/EstateWaterfallSummaryTileSummaryPanel';
import { ExternalTranfserSummaryPanel } from '@/modules/summaryPanels/ExternalTransferSummaryPanel/ExternalTransferSummaryPanel';
import { GrowthProfilesSummaryPanel } from '@/modules/summaryPanels/GrowthProfilesSummaryPanel/GrowthProfilesSummaryPanel';
import { HypotheticalTransfersSummaryPanel } from '@/modules/summaryPanels/HypotheticalTransfersSummaryPanel/HypotheticalTransfersSummaryPanel';
import { OrganizationSummaryPanel } from '@/modules/summaryPanels/OrganizationSummaryPanel/OrganizationSummaryPanel';
import { TestamentaryEntitySummaryPanel } from '@/modules/summaryPanels/TestamentaryEntitySummaryPanel/TestamentaryEntitySummaryPanel';
import { AfterDeath, EntityStage } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { useWaterfallSummaryContext } from '../../../../modules/estateWaterfall/contexts/waterfallSummary.context';
import { useWaterfallSummaryTableRows } from '../WaterfallSummaryTable/useWaterfallSummaryTableRows';

export function SummaryPanel() {
  const { summaryPanel, waterfall } = useWaterfallSummaryContext();
  const { isModalOpen, data: modalState, closeModal, openModal } = summaryPanel;
  const { primaryClients } = useHouseholdDetailsContext();
  const rowMap = useWaterfallSummaryTableRows(waterfall, primaryClients || []);

  const householdId = waterfall?.household?.id;
  if (!isModalOpen || !modalState || !householdId) return null;

  if (modalState.type === 'estateTax') {
    return (
      <EstateTaxSummaryPanel
        householdId={householdId}
        waterfallId={waterfall.id}
        initialAfterDeathTab={modalState.afterDeath}
        onClose={closeModal}
      />
    );
  }

  if (modalState.type === 'hypotheticalTransfers') {
    return (
      <HypotheticalTransfersSummaryPanel
        waterfallId={waterfall.id}
        householdId={householdId}
        onClose={closeModal}
        defaultOpenTransferId={modalState.defaultOpenTransferId}
        onManageSource={(sourceId: string) => {
          if (sourceId === EXTERNAL_TRANSFER_NODE_IDENTIFIER) {
            openModal({
              type: 'externalTransfers',
            });
            return;
          }
          const rows = rowMap[AfterDeath.None];
          const row = rows.find(({ id }) => id === sourceId);
          if (row) {
            openModal({
              type: 'vizNode',
              row,
            });
          }
        }}
      />
    );
  }

  if (modalState.type === 'growthProfiles') {
    return (
      <GrowthProfilesSummaryPanel
        waterfall={waterfall}
        householdId={householdId}
        onClose={closeModal}
      />
    );
  }

  if (modalState.type === 'summaryTileDetails') {
    return (
      <EstateWaterfallSummaryTileSummaryPanel
        {...modalState}
        onClose={closeModal}
      />
    );
  }

  if (modalState.type === 'externalTransfers') {
    return (
      <ExternalTranfserSummaryPanel
        onClose={closeModal}
        waterfallId={waterfall.id}
        householdId={householdId}
      />
    );
  }

  if (modalState.row.kind !== 'node') {
    diagnostics.error(
      'No node found in waterfall summary panel modal state',
      new Error('No node found in modal state')
    );

    return null;
  }

  const modalStateRowNode = modalState.row.node;
  const vizNode = modalStateRowNode.node;

  if (vizNode.__typename === 'ClientProfile') {
    const isOnHypotheticalWaterfall = isHypotheticalWaterfall(waterfall);

    return (
      <ClientProfileSummaryPanel
        householdId={householdId}
        clientProfileId={vizNode.id}
        onClose={closeModal}
        afterDeath={modalStateRowNode.afterDeath}
        isRecipientOfDistribution={isRecipientOfDistribution(
          modalStateRowNode.id,
          waterfall.visualizationWithProjections.edges
        )}
        currentNodeValue={modalState.row.value}
        isOnSourceWaterfall={!isOnHypotheticalWaterfall}
        isOnHypotheticalWaterfall={isOnHypotheticalWaterfall}
        // Key on the client id so that the summary panel tab
        // is reset when changing clients
        key={vizNode.id}
      />
    );
  }

  if (vizNode.__typename === 'ClientOrganization') {
    return (
      <OrganizationSummaryPanel
        householdId={householdId}
        organizationId={vizNode.id}
        isOnSourceWaterfall={!isHypotheticalWaterfall(waterfall)}
        onClose={closeModal}
        // Key on the organization id so that the summary panel tab
        // is reset when changing organizations
        key={vizNode.id}
      />
    );
  }

  if (vizNode.__typename === 'Entity') {
    return (
      <EntitySummaryPanel
        householdId={householdId}
        entityId={vizNode.id}
        onClose={closeModal}
        isOnSourceWaterfall={!isHypotheticalWaterfall(waterfall)}
        isOnHypotheticalWaterfall={isHypotheticalWaterfall(waterfall)}
        isHypotheticalEntity={vizNode.stage === EntityStage.Draft}
        isSummaryView={true}
        // Key on the entity id so that the summary panel tab
        // is reset when changing entities
        key={vizNode.id}
      />
    );
  }

  if (vizNode.__typename === 'TestamentaryEntity') {
    return (
      <TestamentaryEntitySummaryPanel
        householdId={householdId}
        entityId={vizNode.id}
        onClose={closeModal}
        firstPrimaryClientDeathId={waterfall.firstGrantorDeath.id}
        entityValue={modalState.row.value}
        isOnWaterfall={true}
        // Key on the testamentary entity id so that the summary panel tab
        // is reset when changing entities
        key={vizNode.id}
      />
    );
  }

  return null;
}
