import { Box, Stack, Typography } from '@mui/material';
import { compact } from 'lodash';
import { usePostHog } from 'posthog-js/react';

import { Button } from '@/components/form/baseInputs/Button';
import { LinkButton } from '@/components/form/baseInputs/Link';
import { LinkExternal01Icon } from '@/components/icons/LinkExternal01Icon';
import { Mail01Icon } from '@/components/icons/Mail01Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { EXTERNAL_URLS } from '@/constants/externalUrls';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { getAppVersion } from '@/utils/environmentUtils';
import { getPosthogSessionURL } from '@/utils/posthogUtils';

function HelpPortalButtonLink() {
  return (
    <Button
      size="sm"
      variant="primary"
      endIcon={LinkExternal01Icon}
      external={true}
      target="_blank"
      href={EXTERNAL_URLS.SUPPORT_PAGE_URL}
    >
      Visit Luminary Academy
    </Button>
  );
}

function EmailSupportButtonLink() {
  return (
    <Button
      size="sm"
      variant="secondary"
      startIcon={Mail01Icon}
      external={true}
      target="_blank"
      href={'mailto:support@withluminary.com'}
    >
      Email support
    </Button>
  );
}

export function SupportTooltip() {
  const posthogClient = usePostHog();
  const user = useCurrentUser();
  const [copyToClipboard] = useCopyToClipboard();
  const { createErrorFeedback, createSuccessFeedback } = useFeedback();

  function handleCopyDebugDetails() {
    const posthogSessionURL = getPosthogSessionURL(posthogClient);
    const appURL = location.href;
    const version = getAppVersion();

    const details = compact([
      posthogSessionURL && `Posthog session URL: ${posthogSessionURL}`,
      `App Version: ${version}`,
      `App URL: ${appURL}`,
    ]);

    copyToClipboard(
      details.join('\n'),
      createSuccessFeedback('Copied to clipboard'),
      createErrorFeedback('Failed to copy to clipboard')
    );
  }

  return (
    <Stack spacing={2.5} px={2.5} py={2}>
      <Stack spacing={1}>
        <Typography variant="h1">Need assistance?</Typography>
        <Typography variant="body1">
          Visit Luminary Academy, our library of training videos and resources.
          If you need further assistance, email us and we’ll get back to you
          shortly!
        </Typography>
      </Stack>
      <Stack spacing={1}>
        <HelpPortalButtonLink />
        <EmailSupportButtonLink />
        {user?.isInternalUser && (
          <Box>
            <LinkButton
              display="Copy debugging details (internal)"
              onClick={handleCopyDebugDetails}
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
