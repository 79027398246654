import Decimal from 'decimal.js';
import { compact, first, isEmpty } from 'lodash';

import {
  AfterDeath,
  EntityInEstateStatus,
  EstateWaterfallProjectionInput,
} from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';
import { getTransferDetails } from '@/utils/hypotheticalTransfers/transferUtils';

import {
  getCombinedTaxStateAndFederal,
  getIsCharitableNode,
} from '../../EstateWaterfall.utils';
import { EstateWaterfall_NodeFragment } from '../../graphql/EstateWaterfall.generated';
import { EstateWaterfallComparisonShape } from '../EstateWaterfallComparison.type';
import { EstateWaterfallComparisonWaterfall } from '../EstateWaterfallComparison.type';
import { EstateWaterfallComparisonWaterfallWithAssumptions } from './EstateWaterfallComparisonTrowser.type';
import {
  EstateWaterfallComparisonTrowser_EstateWaterfallFragment,
  EstateWaterfallComparisonTrowserQuery,
  EstateWaterfallComparisonTrowserQueryVariables,
} from './graphql/EstateWaterfallComparisonTrowser.generated';

export function mapDataToEstateWaterfallComparisonTrowser(
  data: EstateWaterfallComparisonTrowserQuery,
  afterDeath: AfterDeath,
  waterfallIds: string[],
  comparisonState: EstateWaterfallComparisonShape | null
): EstateWaterfallComparisonWaterfallWithAssumptions[] {
  const externalWaterfalls = getNodes(data.estateWaterfalls);

  // sort the waterfalls by input ID order, since the `idIn` API will return in created order by default
  const waterfalls = isEmpty(externalWaterfalls)
    ? compact([
        first(getNodes(data.firstEstateWaterfall)),
        first(getNodes(data.secondEstateWaterfall)),
        first(getNodes(data.thirdEstateWaterfall)),
        first(getNodes(data.fourthEstateWaterfall)),
      ])
    : compact(
        waterfallIds.map<
          EstateWaterfallComparisonTrowser_EstateWaterfallFragment | undefined
        >((waterfallId) =>
          externalWaterfalls.find(({ id }) => id === waterfallId)
        )
      );

  const output =
    waterfalls.map<EstateWaterfallComparisonWaterfallWithAssumptions>(
      (waterfall, index) => {
        let inEstate = new Decimal(0);

        let giftAndEstateTax = new Decimal(0);
        switch (afterDeath) {
          case AfterDeath.First:
            giftAndEstateTax = getCombinedTaxStateAndFederal(
              waterfall.visualizationWithProjections.firstDeathTaxSummary
            );
            break;
          case AfterDeath.Second:
            giftAndEstateTax = getCombinedTaxStateAndFederal(
              waterfall.visualizationWithProjections.firstDeathTaxSummary
            ).plus(
              getCombinedTaxStateAndFederal(
                waterfall.visualizationWithProjections.secondDeathTaxSummary
              )
            );
            break;
          case AfterDeath.None:
          default:
            giftAndEstateTax = new Decimal(0);
        }

        let outOfEstateFamily = new Decimal(0);
        let outOfEstateCharity = new Decimal(0);
        const nodes: EstateWaterfall_NodeFragment[] = compact(
          waterfall.visualizationWithProjections.nodes.filter(
            (node) => node.afterDeath === afterDeath
          )
        );

        nodes.forEach((node) => {
          if (node.inEstateStatus === EntityInEstateStatus.InEstate) {
            inEstate = inEstate.plus(node.value);
          } else if (getIsCharitableNode(node.node)) {
            outOfEstateCharity = outOfEstateCharity.plus(node.value);
          } else {
            outOfEstateFamily = outOfEstateFamily.plus(node.value);
          }
        });

        const transfers = getNodes(waterfall.hypotheticalTransfers).map(
          getTransferDetails
        );

        const parentId = waterfall.parent?.id ?? null;

        const comparisonInfo = comparisonState?.waterfalls[index];

        return {
          id: waterfall.id,
          displayName:
            comparisonState?.waterfalls[index]?.displayName ||
            waterfall.displayName,
          isHypothetical: !!waterfall.parent?.id,
          inEstate,
          giftAndEstateTax,
          outOfEstateFamily,
          outOfEstateCharity,
          transfers,
          parentId,
          householdDisplayName: waterfall.household?.displayName ?? '',
          waterfallNode: waterfall,
          growthProfile: waterfall.visualizationWithProjections.growthProfile,
          comparisonInfo,
        };
      }
    );

  return output;
}

function mapWaterfallToVisualization(
  waterfall: EstateWaterfallComparisonWaterfall | undefined
): EstateWaterfallProjectionInput {
  if (!waterfall) {
    return {};
  }
  return {
    growthProfileID: waterfall.growthProfileId,
    firstGrantorDeathYear: waterfall.firstDeathYear,
    secondGrantorDeathYear: waterfall.secondDeathYear,
  };
}

export function getEstateWaterfallComparisonTrowserQueryVariables(
  waterfallIds: string[],
  isGrowthProfilesEnabled: boolean,
  isCustomGrowthRateEnabled: boolean,
  {
    firstGrantorDeathYear,
    secondGrantorDeathYear,
    assetGrowthReturn,
    exemptionGrowthRate,
    willExemptionSunset,
    useCustomGlobalGrowthRate,
  }: {
    firstGrantorDeathYear?: number | undefined;
    secondGrantorDeathYear?: number | undefined;
    assetGrowthReturn?: Decimal | undefined;
    exemptionGrowthRate?: Decimal | undefined;
    willExemptionSunset?: boolean | undefined;
    useCustomGlobalGrowthRate?: boolean | undefined;
  },
  comparisonState: EstateWaterfallComparisonShape | null
): EstateWaterfallComparisonTrowserQueryVariables {
  if (isGrowthProfilesEnabled && comparisonState) {
    const { waterfalls } = comparisonState;
    const [firstWaterfall, secondWaterfall, thirdWaterfall, fourthWaterfall] =
      waterfalls;

    const output: EstateWaterfallComparisonTrowserQueryVariables = {
      where: {},
      assumptions: {},
      useLegacyAssumptions: false,
      firstWaterfallId: firstWaterfall?.waterfallId || '',
      useFirstWaterfallVisualization: true,
      secondWaterfallId: secondWaterfall?.waterfallId || '',
      useSecondWaterfallVisualization: true,
      thirdWaterfallId: thirdWaterfall?.waterfallId || '',
      useThirdWaterfallVisualization: true,
      fourthWaterfallId: fourthWaterfall?.waterfallId || '',
      useFourthWaterfallVisualization: true,
      firstWaterfallVisualization: mapWaterfallToVisualization(firstWaterfall),
      secondWaterfallVisualization:
        mapWaterfallToVisualization(secondWaterfall),
      thirdWaterfallVisualization: mapWaterfallToVisualization(thirdWaterfall),
      fourthWaterfallVisualization:
        mapWaterfallToVisualization(fourthWaterfall),
    };

    return output;
  }

  let assetGrowth: Decimal | undefined = assetGrowthReturn;
  if (isCustomGrowthRateEnabled) {
    assetGrowth = useCustomGlobalGrowthRate ? assetGrowthReturn : undefined;
  }

  const output: EstateWaterfallComparisonTrowserQueryVariables = {
    where: {
      idIn: waterfallIds,
    },
    assumptions: {
      firstGrantorDeathYear,
      secondGrantorDeathYear,
      assetGrowthReturn: assetGrowth,
      exemptionGrowthRate,
      willExemptionSunset,
    },
    useLegacyAssumptions: true,
    useFirstWaterfallVisualization: false,
    useSecondWaterfallVisualization: false,
    useThirdWaterfallVisualization: false,
    useFourthWaterfallVisualization: false,
    firstWaterfallId: '',
    secondWaterfallId: '',
    thirdWaterfallId: '',
    fourthWaterfallId: '',
    firstWaterfallVisualization: {},
    secondWaterfallVisualization: {},
    thirdWaterfallVisualization: {},
    fourthWaterfallVisualization: {},
  };

  return output;
}
