import { first } from 'lodash';
import { useMemo } from 'react';

import { TransferDirection } from '@/modules/entities/details/HypotheticalTransfersCard/hypotheticalTransfers/HypotheticalTransferForm';
import {
  EntityStage,
  EstateWaterfallHypotheticalTransferDestinationKind,
  EstateWaterfallHypotheticalTransferSourceKind,
  EstateWaterfallHypotheticalTransferWhereInput,
} from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import {
  HypotheticalTransfersSummary_EstateWaterfallHypotheticalTransferFragment,
  HypotheticalTransfersSummaryQueryQueryResult,
  useHypotheticalTransfersSummaryQueryQuery,
} from '../../HypotheticalTransfersSummaryPanel/graphql/HypotheticalTransfersSummary.generated';

interface useGetExternalHypotheticalTransfersReturnType
  extends HypotheticalTransfersSummaryQueryQueryResult {
  transfers: HypotheticalTransfersSummary_EstateWaterfallHypotheticalTransferFragment[];
}

export function useGetExternalHypotheticalTransfers(
  waterfallId: string,
  direction: TransferDirection
): useGetExternalHypotheticalTransfersReturnType {
  const estateWaterfallsWhere: EstateWaterfallHypotheticalTransferWhereInput =
    useMemo(
      () => ({
        id: waterfallId,
      }),
      [waterfallId]
    );

  const queryResult = useHypotheticalTransfersSummaryQueryQuery({
    variables: {
      estateWaterfallsWhere,
    },
  });

  const { data } = queryResult;
  const transfers = useMemo(() => {
    const waterfall = first(getNodes(data?.estateWaterfalls));

    if (!waterfall) {
      return [];
    }

    return (
      getNodes(waterfall.hypotheticalTransfers)
        // only return
        .filter((transfer) => {
          if (
            direction === TransferDirection.Inbound &&
            transfer.sourceKind ===
              EstateWaterfallHypotheticalTransferSourceKind.External
          )
            return true;
          if (
            direction === TransferDirection.Outbound &&
            transfer.destinationKind ===
              EstateWaterfallHypotheticalTransferDestinationKind.External
          )
            return true;

          return false;
        })
        // filter out entities that don't exist
        .filter((transfer) => {
          if (
            direction === TransferDirection.Inbound &&
            transfer.destinationKind ===
              EstateWaterfallHypotheticalTransferDestinationKind.Entity
          ) {
            return transfer.destinationEntity?.stage !== EntityStage.Draft;
          } else if (
            direction === TransferDirection.Outbound &&
            transfer.sourceKind ===
              EstateWaterfallHypotheticalTransferSourceKind.Entity
          ) {
            return transfer.sourceEntity?.stage !== EntityStage.Draft;
          }
          return true;
        })
        .sort((a, b) => a.transferOrder - b.transferOrder)
    );
  }, [data?.estateWaterfalls, direction]);

  return {
    ...queryResult,
    transfers,
  };
}
