import Decimal from 'decimal.js';

import { IntegrationEntityExternalKind } from '@/types/schema';
import { PathsOf } from '@/types/subform';

import { EntityType } from '../entities/types/EntityType';

export const NAMESPACE = 'EntityImportTable' as const;

type LuminaryClientID = string;
type IntegrationEntityId = string;

export interface EntityImportTableEntity {
  shouldImport: boolean;
  integrationEntityId: IntegrationEntityId;
  remoteName: string;
  displayName: string;
  externalKind: IntegrationEntityExternalKind;
  entityKind: EntityType | '';
  entityValue: Decimal | null;
  commaSeparatedOwnerIDs: string;
  // level determines the amount of indentation an entity gets for ownership
  // context and display purposes
  level?: number;
  // levelIndex is the index of the entity within its level. we want to show ownership structure and show
  // an indicator that the entity is the first in its level.
  levelIndex?: number;
  parentEntityId: string | null;
  hasChildren: boolean;
}

export type EntityImportEntityMap = Record<
  IntegrationEntityId,
  EntityImportTableEntity
>;

export interface EntityImportTableClient {
  luminaryClientID: string;
  displayName: string;
  grantors: {
    id: string;
    displayName: string;
  }[];
  entityMap: EntityImportEntityMap;
}

export type EntityImportHouseholdMap = Record<
  LuminaryClientID,
  EntityImportTableClient
>;

export interface EntityImportTableForm {
  [NAMESPACE]: {
    householdMap: EntityImportHouseholdMap;
  };
}

export type EntityTableImportFormPaths = PathsOf<EntityImportTableForm>;
