import Decimal from 'decimal.js';
import React, { useCallback, useEffect } from 'react';
import { Control, FieldPath, FieldValues, useWatch } from 'react-hook-form';

import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import {
  ValidatedInputRepeaterPercentSumRow,
  validateExactly100Percent,
} from '@/components/form/formAwareInputs/FormAwareInputRepeater/ValidatedInputRepeaterPercentSumRow';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { RepeaterInputShape } from '@/components/form/formAwareInputs/formAwareRepeater/FormAwareRepeater.types';
import {
  FormAwareSelectInput,
  FormAwareSelectInputProps,
} from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { LocatableFormAwareInput } from '@/modules/aiSuggestions/components/forms/LocatableFormAwareInput';
import { TrustGrantors } from '@/modules/content/tooltipContent/TrustGrantors';
import { FieldNameFromFormShape } from '@/types/react-hook-form';
import { AiSuggestionKind } from '@/types/schema';
import { getComparableDecimalJS } from '@/utils/decimalJSUtils';

interface PrimaryClient {
  clientProfileId: string;
  percentage: Decimal | null;
}

export type PrimaryClientDropdownMultipleFormShape = FieldValues & {
  clients?: PrimaryClient[];
  // grantorAttributionSum is a computed property to allow us to validate
  // assigned grantor percentages
  _grantorAttributionSum?: Decimal | null;
};

const GRANTOR_ATTRIBUTION_PERCENTAGE_DECIMAL_SCALE = 5;

export interface PrimaryClientDropdownMultipleProps<
  FormShape extends FieldValues,
> {
  control: Control<FormShape>;
  fieldName: FieldPath<FormShape>;
  validationFieldName: FieldPath<FormShape>;
  options: SelectInputProps['options'];
  required: boolean;
  isDonors?: boolean;
  variant?: 'grantorOnly' | 'grantorAndPercent';
  labelIconEnd?: React.ReactNode;
}

export function PrimaryClientDropdownMultiple<
  FormShape extends PrimaryClientDropdownMultipleFormShape,
>({
  fieldName,
  validationFieldName,
  control,
  options,
  required,
  isDonors,
  variant = 'grantorOnly',
  labelIconEnd,
}: PrimaryClientDropdownMultipleProps<FormShape>) {
  const getAddAnotherItemText = useCallback(() => {
    if (isDonors) {
      return 'Add additional donor';
    }

    return 'Add additional grantor';
  }, [isDonors]);

  const { setValue } = useFormContext<FormShape>();

  const clientProfiles = useWatch({
    name: fieldName,
    control,
  }) as PrimaryClient[] | undefined;

  const attributionSum = (clientProfiles ?? []).reduce((acc, o) => {
    acc = acc.add(o.percentage ?? new Decimal(0));
    return acc;
  }, new Decimal(0));

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any -- linter refactor
    setValue(validationFieldName, attributionSum as any);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // getComparableDecimalJS is the only way to avoid infinite setValues when the value
    // is a Decimal.js instance
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getComparableDecimalJS(
      attributionSum,
      GRANTOR_ATTRIBUTION_PERCENTAGE_DECIMAL_SCALE
    ),
    setValue,
  ]);

  return (
    <FormAwareInputRepeater<FormShape>
      name={fieldName}
      emptyValue={
        {
          clientProfileId: '',
          percentage: null,
        } as RepeaterInputShape<FormShape>
      }
      control={control}
      shouldInit
      addAnotherItemText={getAddAnotherItemText()}
      rightFooterContent={
        variant === 'grantorAndPercent' && (
          <ValidatedInputRepeaterPercentSumRow<FormShape>
            decimalScale={GRANTOR_ATTRIBUTION_PERCENTAGE_DECIMAL_SCALE}
            validationField={validationFieldName}
            validation={{
              totalValue: (value) =>
                validateExactly100Percent(value as Decimal | null),
            }}
          />
        )
      }
      render={(i: number, _props, _id, additionalItemRendererProps) => {
        const isFirstInput = i === 0;
        return (
          <FormLayoutRow>
            <FormLayoutItem width={variant === 'grantorAndPercent' ? 8 : 12}>
              <LocatableFormAwareInput<
                FormShape,
                FormAwareSelectInputProps<FormShape>
              >
                FormAwareElement={FormAwareSelectInput}
                suggestionKind={AiSuggestionKind.Principal}
                fieldName={`${fieldName}.${i}.clientProfileId`}
                label={isDonors ? 'Donors' : 'Grantors'}
                hideLabel={!isFirstInput}
                control={control}
                options={options}
                contextualHelp={<TrustGrantors />}
                required={required}
                labelIconEnd={isFirstInput && labelIconEnd}
                additionalItemRendererProps={additionalItemRendererProps}
              />
            </FormLayoutItem>
            {variant === 'grantorAndPercent' && (
              <FormLayoutItem width={4}>
                <FormAwarePercentInput<FormShape>
                  fieldName={`${fieldName}.${i}.percentage`}
                  label={'Percentage'}
                  hideLabel={!isFirstInput}
                  decimalScale={5}
                  isDecimalJSInput
                  control={control}
                  required={required}
                />
              </FormLayoutItem>
            )}
          </FormLayoutRow>
        );
      }}
    />
  );
}

export type PrimaryClientDropdownSingleFormShape = FieldValues & {
  clientProfileId?: string;
};

export interface PrimaryClientDropdownSingleProps<
  FormShape extends PrimaryClientDropdownSingleFormShape,
> extends Partial<FormAwareSelectInputProps<FormShape>> {
  control: Control<FormShape>;
  namespace: FieldNameFromFormShape<FormShape>;
  options: SelectInputProps['options'];
  hideGrantorContextualHelp?: boolean;
  labelOverride?: string;
  labelIconEnd?: React.ReactNode;
}

export function PrimaryClientDropdownSingle<
  FormShape extends PrimaryClientDropdownSingleFormShape,
>({
  control,
  namespace,
  options,
  hideGrantorContextualHelp,
  labelOverride: label = 'Grantor',
  ...rest
}: PrimaryClientDropdownSingleProps<FormShape>) {
  return (
    <LocatableFormAwareInput<FormShape, FormAwareSelectInputProps<FormShape>>
      FormAwareElement={FormAwareSelectInput}
      suggestionKind={AiSuggestionKind.Principal}
      control={control}
      fieldName={`${namespace}.grantor.clientProfileId`}
      label={label}
      contextualHelp={hideGrantorContextualHelp ? undefined : <TrustGrantors />}
      options={options}
      {...rest}
    />
  );
}
