import { ENTITY_TYPES } from '../../entities/entities.constants';
import { EntityType } from '../../entities/types/EntityType';

// because integrated asset providers can have large numbers of assets, we limit the number of assets we show in limited-space scenarios to 4
export const NUMBER_PREVIEW_ASSETS = 4;

// it's not clear how to support ILIT valuation from Addepar or other integrations in Luminary
// see: https://linear.app/luminary/issue/T2-567/block-ilits-from-being-ingested-from-addepar
export const INTEGRATION_EXCLUDED_ENTITY_TYPES: EntityType[] = [
  ENTITY_TYPES.ILIT,
  ENTITY_TYPES.INSURANCE_ACCOUNT,
];

export enum AssetIntegrationProviders {
  ADDEPAR = 'ADDEPAR',
  BLACK_DIAMOND = 'BLACK_DIAMOND',
  CSV_IMPORT = 'CSV_IMPORT',
  ORION = 'ORION',
}

export const ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES: Record<
  AssetIntegrationProviders,
  string
> = {
  [AssetIntegrationProviders.ADDEPAR]: 'Addepar',
  [AssetIntegrationProviders.BLACK_DIAMOND]: 'Black Diamond',
  [AssetIntegrationProviders.ORION]: 'Orion',
  // note that this is the default value, but it can be overriden in the integration_configuration for the tenant
  [AssetIntegrationProviders.CSV_IMPORT]: 'CSV Import',
};
