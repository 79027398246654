import { useCallback, useEffect, useState } from 'react';
import { UseFormReset } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';

import { GRATStructuringForm } from './constants';
import {
  GratStructuring_EntityFragment,
  GratStructuring_GratTrustFragment,
  useGetStructuringGratLazyQuery,
} from './graphql/StructuringDesigner.generated';
import { generateInitialValues } from './structuringDesignerUtils';

export function useSyncStructuringDesignerData(
  entityId: string,
  reset: UseFormReset<GRATStructuringForm>
) {
  const { createErrorFeedback } = useFeedback();

  const [entityData, setEntityData] =
    useState<GratStructuring_EntityFragment | null>(null);
  const [gratData, setGRATData] =
    useState<GratStructuring_GratTrustFragment | null>(null);

  const [getGRATData, { loading }] = useGetStructuringGratLazyQuery({
    onError: createErrorFeedback(
      'Error fetching GRAT data. Please refresh the page to try again.'
    ),
  });

  const syncEntityDataToForm = useCallback(
    (entity: GratStructuring_EntityFragment | null) => {
      if (
        entity?.__typename !== 'Entity' ||
        entity.subtype?.__typename !== 'GRATTrust'
      ) {
        throw new Error('Invalid data');
      }

      // we pass null for the default interest rat here because if we're calling syncEntityDataToForm,
      // we're doing so from outside this initialization and can be confident that the grat has the interest
      // rate already defined, since that's a required field in the form.
      reset(generateInitialValues(entity.subtype, null));
      setEntityData(entity);
      setGRATData(entity.subtype);
    },
    [reset]
  );

  useEffect(() => {
    async function fetchAndSyncFormData() {
      const { data } = await getGRATData({
        variables: {
          entityId,
        },
      });

      if (
        data?.entity?.__typename !== 'Entity' ||
        data?.entity?.subtype?.__typename !== 'GRATTrust'
      ) {
        throw new Error('Invalid data');
      }

      reset(
        generateInitialValues(
          data.entity.subtype,
          data.official7520InterestRate.currentMonth.interestRate
        )
      );
      setEntityData(data.entity);
      setGRATData(data.entity.subtype);
    }

    void fetchAndSyncFormData();
  }, [entityId, reset, getGRATData]);

  return { entityData, gratData, loading, syncEntityDataToForm };
}
