import { Box, MenuItem, Stack, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import { isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { DataTableProps } from '@/components/tables/DataTable/types';
import {
  DisplayTable,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { useModalState } from '@/hooks/useModalState';
import {
  GrowthProfileGrowthType,
  GrowthProfileOverrideKind,
} from '@/types/schema';
import { formatPercent } from '@/utils/formatting/percent';

import {
  CustomAssetCategoryGrowthModal,
  CustomAssetCategoryGrowthModalFormShape,
} from '../CustomAssetCategoryGrowthModal/CustomAssetCategoryGrowthModal';
import { CustomEntityGrowthModal } from '../CustomEntityGrowthModal/CustomEntityGrowthModal';
import {
  GrowthProfileModal_AssetClassFragment,
  GrowthProfileModal_EntityFragment,
} from './graphql/GrowthProfileModal.generated';
import {
  CUSTOM_GROWTH_TABLE_COLUMNS,
  DEFAULT_GROWTH_TABLE_COLUMNS,
  growthProfileTypeOptions,
} from './GrowthProfileModal.constants';
import { useGrowthProfileModalContext } from './GrowthProfileModal.context';
import {
  CustomEntityGrowthCustomizationType,
  GrowthProfileCustomGrowthOverrideRowData,
  GrowthProfileModalFormPaths,
  GrowthProfileModalFormShape,
  GrowthProfileModalGrowthOverride,
} from './GrowthProfileModal.types';
import { mapGrowthOverrideToTableRow } from './GrowthProfileModal.utils';

export function GrowthProfileModalBody() {
  const { control } = useFormContext<GrowthProfileModalFormShape>();
  const { growthProfileId, isDefault, isDuplicating } =
    useGrowthProfileModalContext();

  const modalTitle = useMemo(() => {
    if (isDuplicating) return 'Duplicate growth profile';
    if (isDefault) return 'Default growth profile';
    if (growthProfileId) return 'Edit growth profile';
    return 'Create growth profile';
  }, [isDefault, growthProfileId, isDuplicating]);

  const [growthType] = useWatch({ control, name: ['growthType'] });

  return (
    <Stack direction="column" spacing={3} pt={3} pb={4}>
      <Card variant="filled-light" sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Typography variant="h1">{modalTitle}</Typography>
          <Box>
            <FormLayoutRow>
              <FormLayoutItem width={12}>
                <FormAwareTextInput<GrowthProfileModalFormShape>
                  control={control}
                  fieldName={
                    'displayName' as const satisfies GrowthProfileModalFormPaths
                  }
                  label="Name"
                  required
                />
              </FormLayoutItem>
            </FormLayoutRow>
            <FormLayoutRow>
              <FormLayoutItem width={3}>
                <FormAwarePercentInput<GrowthProfileModalFormShape>
                  control={control}
                  fieldName={
                    'exemptionGrowthRate' as const satisfies GrowthProfileModalFormPaths
                  }
                  label="Exemption growth rate"
                  required
                  isDecimalJSInput
                  fixedDecimalScale
                  decimalScale={2}
                />
              </FormLayoutItem>
              <FormLayoutItem width={9} sx={{ pt: 4 }}>
                <FormAwareSwitch<GrowthProfileModalFormShape>
                  control={control}
                  fieldName={
                    'willExemptionSunset' as const satisfies GrowthProfileModalFormPaths
                  }
                  label="Exemption sunsets in 2025"
                  labelPosition="right"
                />
              </FormLayoutItem>
            </FormLayoutRow>
          </Box>
          <GrowthTypeCard />
        </Stack>
      </Card>
      {growthType === GrowthProfileGrowthType.DefaultGrowth && (
        <DefaultGrowthTable />
      )}
      {growthType === GrowthProfileGrowthType.CustomGrowth && (
        <CustomGrowthTable />
      )}
    </Stack>
  );
}

function GrowthTypeCard() {
  const { control } = useFormContext<GrowthProfileModalFormShape>();
  const { isDefault } = useGrowthProfileModalContext();

  const [growthType] = useWatch({ control, name: ['growthType'] });

  const shouldDisplayGlobalGrowthInput =
    growthType === GrowthProfileGrowthType.GlobalGrowth;

  return (
    <Card variant="filled" sx={{ p: 3 }}>
      <Stack direction="column" spacing={2}>
        <FormAwareRadioGroup<GrowthProfileModalFormShape>
          data-testid="growth-type-radio-group"
          disabled={isDefault}
          control={control}
          fieldName={
            'growthType' as const satisfies GrowthProfileModalFormPaths
          }
          options={growthProfileTypeOptions}
          row={false}
          columnSpacing={2}
        />
        {shouldDisplayGlobalGrowthInput && (
          <FormLayoutRow>
            <FormLayoutItem width={3}>
              <Box pl={3}>
                <FormAwarePercentInput<GrowthProfileModalFormShape>
                  control={control}
                  fieldName={
                    'assetGrowthReturn' as const satisfies GrowthProfileModalFormPaths
                  }
                  label="Global growth rate"
                  required
                  isDecimalJSInput
                  hideLabel
                  fixedDecimalScale
                  decimalScale={2}
                />
              </Box>
            </FormLayoutItem>
          </FormLayoutRow>
        )}
      </Stack>
    </Card>
  );
}

function DefaultGrowthTable() {
  const { assetClasses } = useGrowthProfileModalContext();

  return (
    <Card variant="outlined" sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Typography variant="h1">Default growth rates</Typography>
        <DisplayTable columns={DEFAULT_GROWTH_TABLE_COLUMNS}>
          {assetClasses.map((row) => (
            <DefaultGrowthTableRow key={row.id} {...row} />
          ))}
        </DisplayTable>
      </Stack>
    </Card>
  );
}

function DefaultGrowthTableRow({
  displayName,
  growthRate,
}: GrowthProfileModal_AssetClassFragment) {
  return (
    <StyledTableRow>
      <StyledTableCell>{displayName}</StyledTableCell>
      <StyledTableCell align="right">
        {formatPercent(growthRate ?? new Decimal(0), 2)}%
      </StyledTableCell>
    </StyledTableRow>
  );
}

function CustomGrowthTable() {
  const [{ isModalOpen, data }, { openModal, closeModal }] = useModalState<{
    index: number | null;
    kind: GrowthProfileOverrideKind | null;
  }>();

  const { index, kind } = data ?? {
    index: null,
    kind: null,
  };

  const { householdId, assetClasses } = useGrowthProfileModalContext();
  const { control, setValue } = useFormContext<GrowthProfileModalFormShape>();
  const [growthProfileOverrides] = useWatch({
    control,
    name: ['growthProfileOverrides'],
  });

  const rows = useMemo(
    () => growthProfileOverrides?.map(mapGrowthOverrideToTableRow),
    [growthProfileOverrides]
  );

  const handleCustomAssetCategorySubmit = useCallback(
    ({
      categoryId,
      customGrowthRate,
    }: CustomAssetCategoryGrowthModalFormShape) => {
      if (categoryId === undefined) return;
      if (customGrowthRate === undefined) return;
      const newGrowthProfileOverrides = growthProfileOverrides.slice();

      const matchedAssetClass = assetClasses.find(
        ({ id }) => id === categoryId
      );

      if (!matchedAssetClass) return;

      if (index === null) {
        newGrowthProfileOverrides.push({
          id: newGrowthProfileOverrides.length,
          assetClass: matchedAssetClass,
          customGrowthRate,
          kind: GrowthProfileOverrideKind.Asset,
          customizationType: CustomEntityGrowthCustomizationType.None,
        });
        setValue('growthProfileOverrides', newGrowthProfileOverrides);
      } else {
        newGrowthProfileOverrides[index] = {
          id: index,
          assetClass: matchedAssetClass,
          customGrowthRate,
          kind: GrowthProfileOverrideKind.Asset,
          customizationType: CustomEntityGrowthCustomizationType.None,
        };
      }
      setValue('growthProfileOverrides', newGrowthProfileOverrides);
      closeModal();
    },
    [assetClasses, closeModal, growthProfileOverrides, index, setValue]
  );

  const handleCustomEntitySubmit = useCallback(
    ({
      formData,
      entity,
    }: {
      formData: GrowthProfileModalGrowthOverride;
      entity: GrowthProfileModal_EntityFragment;
    }) => {
      const newGrowthProfileOverrides = growthProfileOverrides.slice();
      const newEntry: GrowthProfileModalGrowthOverride = {
        ...formData,
        id: index === null ? newGrowthProfileOverrides.length : index,
        entity,
        kind: GrowthProfileOverrideKind.Entity,
      };

      if (index === null) {
        newGrowthProfileOverrides.push(newEntry);
      } else {
        newGrowthProfileOverrides[index] = newEntry;
      }
      setValue('growthProfileOverrides', newGrowthProfileOverrides);
      closeModal();
    },
    [closeModal, growthProfileOverrides, index, setValue]
  );

  const handleDeleteOverride = useCallback(() => {
    if (index === null) return;
    const newGrowthProfileOverrides = growthProfileOverrides.slice();
    newGrowthProfileOverrides.splice(index, 1);
    setValue('growthProfileOverrides', newGrowthProfileOverrides);
    closeModal();
  }, [index, growthProfileOverrides, setValue, closeModal]);

  const onRowClick: DataTableProps['onRowClick'] = useCallback(
    ({ row }: GridRowParams<GrowthProfileCustomGrowthOverrideRowData>) => {
      if (row.kind === GrowthProfileOverrideKind.Asset) {
        openModal({ index: row.id, kind: GrowthProfileOverrideKind.Asset });
      } else if (row.kind === GrowthProfileOverrideKind.Entity) {
        openModal({ index: row.id, kind: GrowthProfileOverrideKind.Entity });
      }
    },
    [openModal]
  );

  const currentOverride = useMemo(
    () => (index === null ? undefined : growthProfileOverrides?.[index]),
    [index, growthProfileOverrides]
  );

  return (
    <>
      {isModalOpen && kind === GrowthProfileOverrideKind.Asset && (
        <CustomAssetCategoryGrowthModal
          onSubmit={handleCustomAssetCategorySubmit}
          onClose={closeModal}
          onDelete={handleDeleteOverride}
          canDelete={index !== null}
          categoryId={currentOverride?.assetClass?.id}
          {...currentOverride}
        />
      )}
      {isModalOpen && kind === GrowthProfileOverrideKind.Entity && (
        <CustomEntityGrowthModal
          householdId={householdId}
          onSubmit={handleCustomEntitySubmit}
          onClose={closeModal}
          canDelete={index !== null}
          entityId={currentOverride?.entity?.id}
          onDelete={handleDeleteOverride}
          {...currentOverride}
        />
      )}
      <Card variant="outlined" sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h1">Custom growth</Typography>
            {!isEmpty(rows) && <AddGrowthRateButton openModal={openModal} />}
          </Stack>
          {isEmpty(rows) ? (
            <Stack
              alignItems="center"
              justifyContent="center"
              height="100%"
              sx={{ pt: 3 }}
              width="100%"
            >
              <Typography variant="h4" component="span" sx={{ pb: 1 }}>
                Set a custom growth rate at the asset category or entity level
              </Typography>
              <Typography variant="body1" sx={{ pb: 3 }}>
                Custom growth rates modeled in this profile will override any
                default rates set by an administrator
              </Typography>
              <Box sx={{ pb: 1 }}>
                <AddGrowthRateButton openModal={openModal} />
              </Box>
            </Stack>
          ) : (
            <>
              <Typography variant="body1">
                Entity growth rates take priority over asset categories
              </Typography>
              <DataTable
                columns={CUSTOM_GROWTH_TABLE_COLUMNS}
                rows={rows}
                onRowClick={onRowClick}
                hideFooterPagination
              />
            </>
          )}
        </Stack>
      </Card>
    </>
  );
}

interface AddGrowthRateButtonProps {
  openModal: (data: {
    index: number | null;
    kind: GrowthProfileOverrideKind;
  }) => void;
}

// Because this button can appear in multiple places, don't let it handle the modal open/close state
function AddGrowthRateButton({ openModal }: AddGrowthRateButtonProps) {
  return (
    <ButtonWithPopover
      label="Add growth rate"
      size="sm"
      variant="primary"
      popperVariant="menuBelow"
    >
      <MenuItem
        onClick={() =>
          openModal({ index: null, kind: GrowthProfileOverrideKind.Asset })
        }
      >
        Asset category
      </MenuItem>
      <MenuItem
        onClick={() =>
          openModal({ index: null, kind: GrowthProfileOverrideKind.Entity })
        }
      >
        Entity
      </MenuItem>
    </ButtonWithPopover>
  );
}
