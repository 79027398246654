import { FieldArrayPathValue, PathValue } from 'react-hook-form';
import { SetRequired } from 'type-fest';

import { ArrayFieldToUpdate } from '@/modules/aiSuggestions/AISuggestionsMatcher/hooks/useUpdateSubformArrayField';
import { SingleFieldToUpdate } from '@/modules/aiSuggestions/AISuggestionsMatcher/hooks/useUpdateSubformSingleField';
import { CommonAiSuggestionFragment } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { AddNewAnythingModalProps } from '@/modules/common/AddNewAnythingModal/AddNewAnythingModal';
import { BASIC_INFORMATION_SUBFORM_NAMESPACE } from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import { BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE } from '@/modules/entities/BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import { CombinedSupportedSubformFieldTypes } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import {
  BENEFICIARIES_SUBFORM,
  LIFETIME_BENEFICIARIES_SUBFORM,
} from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.constants';
import { TRUST_DETAILS_SUBFORM_NAMESPACE } from '@/modules/entities/TrustDetailsSubform/TrustDetailsSubform.types';
import { AiSuggestionKind } from '@/types/schema';
import { PathsOf } from '@/types/subform';

export enum AISuggestionsMatcherVariant {
  GRANTORS = 'GRANTORS',
  // Only allows matching one grantor, for things like SLAT's
  SINGLE_GRANTOR = 'SINGLE_GRANTOR',
  // Includes trustees, successor trustees, and trust advisors
  TRUSTEES = 'TRUSTEES',
  BENEFICIARIES = 'BENEFICIARIES',
  BENEFICIARIES_V2 = 'BENEFICIARIESV2',
  BUSINESS_PEOPLE = 'BUSINESS_PEOPLE', // Includes beneficial owners and key people
}

// These are the full field paths to the subform array fields that we allow
// AI suggestions to match against.
export const AI_SUGGESTIONS_MATCHER_ARRAY_FIELD_PATHS = [
  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.grantors` as const satisfies PathsOf<CombinedSupportedSubformFieldTypes>,
  `${TRUST_DETAILS_SUBFORM_NAMESPACE}.trustees` as const satisfies PathsOf<CombinedSupportedSubformFieldTypes>,
  `${TRUST_DETAILS_SUBFORM_NAMESPACE}.successorTrustees` as const satisfies PathsOf<CombinedSupportedSubformFieldTypes>,
  `${TRUST_DETAILS_SUBFORM_NAMESPACE}.trustAdvisors` as const satisfies PathsOf<CombinedSupportedSubformFieldTypes>,
  `${BENEFICIARIES_SUBFORM}.beneficiaries` as const satisfies PathsOf<CombinedSupportedSubformFieldTypes>,
  `${LIFETIME_BENEFICIARIES_SUBFORM}.lifetimeBeneficiaries` as const satisfies PathsOf<CombinedSupportedSubformFieldTypes>,
  `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.owners` as const satisfies PathsOf<CombinedSupportedSubformFieldTypes>,
  `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.keyPeople` as const satisfies PathsOf<CombinedSupportedSubformFieldTypes>,
] as const;
export type AISuggestionsMatcherArrayField =
  (typeof AI_SUGGESTIONS_MATCHER_ARRAY_FIELD_PATHS)[number];

// These are the full field paths to the subform single fields that we allow
// AI suggestions to match against.
export const AI_SUGGESTIONS_MATCHER_SINGLE_FIELD_PATHS = [
  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.grantor` as const satisfies PathsOf<CombinedSupportedSubformFieldTypes>,
] as const;
export type AISuggestionsMatcherSingleField =
  (typeof AI_SUGGESTIONS_MATCHER_SINGLE_FIELD_PATHS)[number];

export type AISuggestionsMatcherAddNewAnythingModalProps = SetRequired<
  Pick<
    AddNewAnythingModalProps,
    | 'addableTypes'
    | 'addClientProfileModalProps'
    | 'addClientOrganizationModalProps'
    | 'addEntityModalProps'
  >,
  | 'addableTypes'
  | 'addClientProfileModalProps'
  | 'addClientOrganizationModalProps'
  | 'addEntityModalProps'
>;

export interface AISuggestionsMatcherVariantConfig {
  nounSingular: string;
  nounPlural: string;
  calloutText?: string;
  suggestionKinds: AiSuggestionKind[];
  addNewAnythingModalProps: Partial<
    Record<AiSuggestionKind, AISuggestionsMatcherAddNewAnythingModalProps>
  >;
  subformArrayFieldUpdateProps: (Omit<ArrayFieldToUpdate, 'newInputs'> & {
    mapperFunc: (
      matches: AISuggestionMatch[]
    ) => FieldArrayPathValue<
      CombinedSupportedSubformFieldTypes,
      AISuggestionsMatcherArrayField
    >;
  })[];
  subformArrayFieldOnConflict: 'overwrite' | 'append';
  subformSingleFieldUpdateProps?: Omit<SingleFieldToUpdate, 'newInput'> & {
    mapperFunc: (
      match: AISuggestionMatch[]
    ) => PathValue<
      CombinedSupportedSubformFieldTypes,
      AISuggestionsMatcherSingleField
    >;
  };
}
export interface SuggestionsGroup {
  // If not given, we won't show the header text for the list of suggestions
  groupLabel?: string;
  suggestions: CommonAiSuggestionFragment[];
}

export interface AISuggestionMatch {
  clientProfileId?: string;
  clientOrganizationId?: string;
  entityId?: string;
  suggestion: CommonAiSuggestionFragment;
}

export interface MatchableField {
  isSuggestion?: boolean;
}
