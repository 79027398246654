import { Box, Stack, Typography } from '@mui/material';
import {
  ArrayPath,
  FieldArrayWithId,
  FieldValues,
  UseFieldArrayRemove,
} from 'react-hook-form';

import { PlusIcon } from '@/components/icons/PlusIcon';
import { XCircleIcon } from '@/components/icons/XCircleIcon';
import { COLORS } from '@/styles/tokens/colors';

import { Button } from '../baseInputs/Button';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

export interface FormAwareListItemRepeaterProps<FormShape extends FieldValues> {
  fields: FieldArrayWithId<FormShape, ArrayPath<FormShape>, '_id'>[];
  remove: UseFieldArrayRemove;
  render: (itemIndex: number) => React.ReactNode;
  addAnotherItemText: string;
  shouldInit?: boolean;
  maxItems?: number;
  sumRowText?: string;
  handleAddItem: () => void;
  hideAddButton?: boolean;
}

export function FormAwareListItemRepeater<FormShape extends FieldValues>({
  fields,
  remove,
  addAnotherItemText,
  render,
  maxItems = Infinity,
  sumRowText,
  handleAddItem,
  hideAddButton = false,
}: FormAwareListItemRepeaterProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  return (
    <Box>
      <Box mb={1}>
        {fields.map((_, i: number) => (
          <Stack direction="row" alignItems="center" key={i} sx={{ mt: 1 }}>
            <Box flexGrow={1}>{render(i)}</Box>

            <Box sx={{ textAlign: 'right' }}>
              <Button
                disabled={disabledFromContext}
                variant="transparent"
                size="sm"
                onClick={() => remove(i)}
                aria-label="Remove"
              >
                <XCircleIcon size={20} />
              </Button>
            </Box>
          </Stack>
        ))}
      </Box>
      {sumRowText && fields.length > 0 && (
        <Stack
          alignItems="end"
          sx={{
            mt: 2,
            padding: 2,
            paddingRight: 8,
            borderTop: `2px ${COLORS.NAVY[600]} solid`,
          }}
        >
          <Typography variant="h5" color={COLORS.FUNCTIONAL.SUCCESS[700]}>
            {sumRowText}
          </Typography>
        </Stack>
      )}
      {!hideAddButton && fields.length < maxItems && (
        <Button
          size="md"
          disabled={disabledFromContext}
          fullWidth
          variant="secondary"
          startIcon={PlusIcon}
          onClick={() => {
            handleAddItem();
          }}
        >
          {addAnotherItemText}
        </Button>
      )}
    </Box>
  );
}
