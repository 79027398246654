import { gridStringOrNumberComparator } from '@mui/x-data-grid-pro';

import { CellContainer } from '@/components/tables/DataTable/components/cells';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';

import { RowData } from './FlatBeneficiaryTable.types';

interface GetColumnsInput {
  heading?: string;
}

export function getColumns({
  heading = 'Beneficiaries',
}: GetColumnsInput): Column<RowData>[] {
  return [
    {
      field: 'beneficiary',
      headerName: heading,
      flex: 1,
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => row.beneficiary,
        lineOneProps: { variant: 'h5' },
      }),
      sortComparator: (
        v1: RowData['beneficiary'],
        v2: RowData['beneficiary'],
        p1,
        p2
      ) => {
        return gridStringOrNumberComparator(v1, v2, p1, p2);
      },
    },
    {
      field: 'access',
      headerName: 'Access',
      flex: 1,
      sortable: false,
      renderCell: ({ row, colDef }) => (
        <CellContainer align={colDef.align}>
          {row.access.component}
        </CellContainer>
      ),
    },
    {
      field: 'details',
      headerName: 'Details',
      flex: 1,
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => row.details.lineOne ?? '',
        lineTwo: ({ row }) => row.details.lineTwo ?? '',
      }),
      sortComparator: (
        v1: RowData['details'],
        v2: RowData['details'],
        p1,
        p2
      ) => {
        return gridStringOrNumberComparator(v1.lineOne, v2.lineOne, p1, p2);
      },
    },
  ];
}
