import { PopperContent } from '@/components/poppers/PopperContent';

import { ContextualHelpTrigger } from '../components/ContextualHelpTrigger';

export function GRATAnnuityPayments() {
  return (
    <PopperContent
      body={
        'GRAT payments can remain fixed for the duration of the term, or increase by up to 20% per year.'
      }
      actions={
        <ContextualHelpTrigger
          display="Learn more"
          contextualHelpId="annuityPaymentOptionsGRAT"
        />
      }
    />
  );
}
