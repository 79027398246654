import { Grow } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { FieldValues, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { File07Icon } from '@/components/icons/File07Icon';
import { Link01Icon } from '@/components/icons/Link01Icon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { DEFAULT_AUTO_HIDE_DURATION } from '@/components/notifications/Feedback/FeedbackProvider';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { ShareProposalAction } from '@/modules/proposal/share/ShareProposalAction/ShareProposalAction';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';

export interface FinalizeProposalModalProps {
  householdId: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (
    e?: BaseSyntheticEvent<object, unknown, unknown> | undefined
  ) => Promise<void>;
  proposalId: string;
}

export function FinalizeProposalModal<T extends FieldValues>({
  isOpen,
  onClose,
  householdId,
  onSave,
  proposalId,
}: FinalizeProposalModalProps) {
  const navigate = useNavigate();

  const { isSubmitting } = useFormState<T>();
  const [success, setSuccess] = useState(false);
  const trackUserEvent = useTrackUserEvent();

  useEffect(() => {
    if (!success) {
      return;
    }

    const timeout = setTimeout(() => {
      setSuccess(false);
    }, DEFAULT_AUTO_HIDE_DURATION);

    return () => {
      clearTimeout(timeout);
    };
  }, [success]);

  function handleReturnToProposals() {
    const proposalsPath = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_DETAILS_PROPOSALS,
      { householdId }
    );
    navigate(proposalsPath);
  }

  return (
    <DialogModal
      isOpen={isOpen}
      onClose={onClose}
      heading="What would you like to do next?"
    >
      <Stack spacing={3}>
        <Stack direction="row" spacing={3} justifyContent="space-between">
          <ActionCard
            sx={{ flexGrow: 1 }}
            description="Preview the proposal before sharing with your client"
            icon={
              <File07Icon
                size={84}
                sx={{
                  color: COLORS.GRAY[200],
                }}
              />
            }
            actions={
              <Stack direction="row" spacing={2} width="100%">
                <Button
                  target="_blank"
                  href={getCompletePathFromRouteKey(
                    ROUTE_KEYS.HOUSEHOLD_PROPOSAL_PRESENTATION,
                    { proposalId },
                    {
                      preview: true,
                      householdId,
                    }
                  )}
                  variant="primary"
                  size="lg"
                  fullWidth
                >
                  Preview
                </Button>
              </Stack>
            }
          />
          <ActionCard
            sx={{ flexGrow: 1 }}
            description="Generate a shareable link to the client proposal"
            icon={
              <Link01Icon
                size={84}
                sx={{
                  color: COLORS.GRAY[200],
                }}
              />
            }
            actions={
              <ShareProposalAction
                proposalId={proposalId}
                onSuccess={() => {
                  setSuccess(true);
                }}
                ActionComponent={({ onClick, disabled }) => (
                  <Button
                    startIcon={success ? CheckIcon : undefined}
                    onClick={async (e) => {
                      setSuccess(false);
                      trackUserEvent('proposal shared');
                      // Set proposal status to GiftingProposalStatus.ProposalCreated
                      await onSave();
                      // Copy link to clipboard
                      void onClick(e);
                    }}
                    disabled={disabled}
                    variant="secondary"
                    loading={isSubmitting}
                    size="lg"
                    fullWidth
                  >
                    {success ? (
                      <Grow in>
                        <Box>Copied!</Box>
                      </Grow>
                    ) : (
                      <Grow in>
                        <Box>Copy secure link</Box>
                      </Grow>
                    )}
                  </Button>
                )}
              />
            }
          />
        </Stack>
        <Box textAlign="center">
          <Button
            onClick={handleReturnToProposals}
            variant="transparent"
            size="md"
          >
            Return to proposals
          </Button>
        </Box>
      </Stack>
    </DialogModal>
  );
}
