import { Stack } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { Copy07Icon } from '@/components/icons/Copy07Icon';
import { DotsVerticalIcon } from '@/components/icons/DotsVerticalIcon';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { Lightbulb02Icon } from '@/components/icons/Lightbulb02Icon';
import { Scales01Icon } from '@/components/icons/Scales01Icon';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { ConfirmationModal } from '@/components/modals/ConfirmationModal/ConfirmationModal';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { shouldRefetchQuery } from '@/graphql/client.utils';
import { useModalState } from '@/hooks/useModalState';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { isHypotheticalWaterfall } from '../../EstateWaterfall.utils';
import { CreateEstateWaterfallButton } from '../EstateWaterfallActionButtons/CreateEstateWaterfallButton';
import { EstateWaterfallAssumptionFields } from '../EstateWaterfallAssumptions/types';
import { EstateWaterfallComparisonTrowser } from '../EstateWaterfallComparisionTrowser/EstateWaterfallComparisonTrowser';
import { EstateWaterfallComparisonShape } from '../EstateWaterfallComparison.type';
import { EstateWaterfallComparisonModal } from '../EstateWaterfallComparisonModal/EstateWaterfallComparisonModal';
import { GetWaterfallComparisonDocument } from '../EstateWaterfallComparisonTrowserLegacy/graphql/GetWaterfallComparison.generated';
import {
  COMPARE_VIEW_ASSUMPTION_ASSET_GROWTH,
  COMPARE_VIEW_ASSUMPTION_EXEMPTION_GROWTH,
  COMPARE_VIEW_ASSUMPTION_EXEMPTION_SUNSET,
  COMPARE_VIEW_ASSUMPTION_FIRST_DEATH,
  COMPARE_VIEW_ASSUMPTION_SECOND_DEATH,
  COMPARE_VIEW_SEARCH_PARAM,
} from '../EstateWaterfallComparisonTrowserLegacy/WaterfallComparison.constants';
import { DuplicateEstateWaterfallTrowser } from '../EstateWaterfallModal/DuplicateEstateWaterfallTrowser';
import { EditEstateWaterfallTrowser } from '../EstateWaterfallModal/EditEstateWaterfallTrowser';
import { useDeleteEstateWaterfallMutation } from '../EstateWaterfallModal/graphql/EstateWaterfallModal.generated';
import { useEstateWaterfallMenuItems } from '../EstateWaterfallsDropdown/hooks/useEstateWaterfallMenuItems';
import {
  getAssumptionsFromSearchParams,
  getSearchParamsFromAssumptions,
  getWaterfallIdsFromSearchParams,
} from './EstateWaterfallBreadcrumbNavigation.utils';
import { useGetEstateWaterfallFirstGrantorQuery } from './graphql/EstateWaterfallBreadcrumbNavigation.generated';
import {
  useWaterfallComparisonSearchParams,
  WATERFALL_COMPARISON_SEARCH_PARAM,
} from './hooks/useWaterfallComparisonSearchParams';
import { useWaterfallTipsAndTricks } from './hooks/useWaterfallTipsAndTricks';

export interface EstateWaterfallBreadcrumbNavigationProps {
  householdId: string;
  waterfallId: string;
}

export const EstateWaterfallBreadcrumbNavigation = ({
  householdId,
  waterfallId,
}: EstateWaterfallBreadcrumbNavigationProps) => {
  const { createErrorFeedback, showFeedback } = useFeedback();
  const { primaryClients } = useHouseholdDetailsContext();
  const navigate = useNavigate();
  const { openWaterfallTipsAndTricks } = useWaterfallTipsAndTricks();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [waterfallIds, setWaterfallIds] = useState<string[]>(
    getWaterfallIdsFromSearchParams(searchParams)
  );
  const [assumptions, setAssumptions] =
    useState<EstateWaterfallAssumptionFields>(
      getAssumptionsFromSearchParams(searchParams)
    );

  const trackUserEvent = useTrackUserEvent();

  const isEstateWaterfallComparisonTrowserOpen =
    searchParams.has(COMPARE_VIEW_SEARCH_PARAM) ||
    searchParams.has(WATERFALL_COMPARISON_SEARCH_PARAM);

  const [
    { isModalOpen: isDeleteModalOpen },
    { openModal: openDeleteModal, closeModal: closeDeleteModal },
  ] = useModalState();
  const [
    { isModalOpen: isEditModalOpen },
    { openModal: openEditModal, closeModal: closeEditModal },
  ] = useModalState();
  const [
    { isModalOpen: isDuplicateModalOpen },
    { openModal: openDuplicateModal, closeModal: closeDuplicateModal },
  ] = useModalState();
  const [
    { isModalOpen: isCompareModalOpen },
    { openModal: openCompareModal, closeModal: closeCompareModal },
  ] = useModalState();

  const { data, loading } = useGetEstateWaterfallFirstGrantorQuery({
    variables: {
      where: {
        id: waterfallId,
      },
    },
    onError: createErrorFeedback(
      'Failed to fetch first death client for estate waterfall.'
    ),
  });

  const waterfall = getNodes(data?.estateWaterfalls)[0];
  const waterfallName = waterfall?.displayName ?? '';
  const isHypothetical = isHypotheticalWaterfall(waterfall);

  const handleDeleteDialogClose = useCallback(() => {
    closeDeleteModal();
    setIsMenuOpen(false);
  }, [closeDeleteModal]);

  const handlEditModalClose = useCallback(() => {
    closeEditModal();
    setIsMenuOpen(false);
  }, [closeEditModal]);

  const handleDuplicateDialogClose = useCallback(() => {
    closeDuplicateModal();
    setIsMenuOpen(false);
  }, [closeDuplicateModal]);

  const [comparisonSearchParams, setComparisonSearchParams] =
    useWaterfallComparisonSearchParams();

  const handleEstateWaterfallComparisonModalSubmit = useCallback(
    (comparisonState: EstateWaterfallComparisonShape) => {
      setComparisonSearchParams(comparisonState);
    },
    [setComparisonSearchParams]
  );

  const handleLegacyEstateWaterfallComparisonModalSubmit = useCallback(
    (waterfallIds: string[], assumptions: EstateWaterfallAssumptionFields) => {
      const newSearchParams = getSearchParamsFromAssumptions(
        waterfallIds,
        assumptions,
        searchParams
      );

      setWaterfallIds(waterfallIds);
      setAssumptions(assumptions);
      setSearchParams(newSearchParams);

      closeCompareModal();
    },
    [closeCompareModal, searchParams, setSearchParams]
  );

  const handleEstateWaterfallComparisonTrowserClose = useCallback(() => {
    searchParams.delete(COMPARE_VIEW_ASSUMPTION_FIRST_DEATH);
    searchParams.delete(COMPARE_VIEW_ASSUMPTION_SECOND_DEATH);
    searchParams.delete(COMPARE_VIEW_ASSUMPTION_ASSET_GROWTH);
    searchParams.delete(COMPARE_VIEW_ASSUMPTION_EXEMPTION_GROWTH);
    searchParams.delete(COMPARE_VIEW_ASSUMPTION_EXEMPTION_SUNSET);
    searchParams.delete(COMPARE_VIEW_SEARCH_PARAM);
    searchParams.delete(WATERFALL_COMPARISON_SEARCH_PARAM);
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  const [deleteMutation, { loading: isDeleting }] =
    useDeleteEstateWaterfallMutation({
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onCompleted: () => {
        const routeKey = ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB;
        navigate(
          getCompletePathFromRouteKey(routeKey, {
            householdId,
          })
        );
      },
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        diagnostics.error(`Could not delete estate waterfall`, error, {
          id: waterfallId,
        });
      },
      onQueryUpdated: (query) => {
        // Prevent showing an error when refetching comparison
        return shouldRefetchQuery(query.queryName, {
          ignoredQueryDocuments: [GetWaterfallComparisonDocument],
        });
      },
    });

  const { clientWaterfalls } = useEstateWaterfallMenuItems({
    householdId,
    waterfallId,
  });

  const onClickDuplicate = useCallback(() => {
    trackUserEvent('waterfall duplicate menu item clicked', {
      isHypothetical,
    });
    openDuplicateModal();
  }, [isHypothetical, openDuplicateModal, trackUserEvent]);

  const onDelete = async () => {
    await deleteMutation({ variables: { id: waterfallId } });
    handleDeleteDialogClose();
  };

  const waterfallFilter = useMemo(() => {
    if (!waterfall?.filter) {
      return null;
    }

    return {
      includedClientIDs: getNodes(waterfall.filter.includedClients).map(
        (c) => c.id
      ),
      includedEntityIDs: getNodes(waterfall.filter.includedEntities).map(
        (e) => e.id
      ),
      waterfallID: waterfallId,
    };
  }, [waterfall?.filter, waterfallId]);

  const commonTrowserProps = useMemo(() => {
    return {
      householdId,
      waterfallName,
      waterfallFilter,
      sourceEstateWaterfalls: clientWaterfalls,
      grantors: primaryClients ?? [],
      isHypothetical,
    };
  }, [
    clientWaterfalls,
    householdId,
    isHypothetical,
    primaryClients,
    waterfallFilter,
    waterfallName,
  ]);

  return (
    <>
      <ConfirmationModal
        heading="Delete waterfall"
        onClose={handleDeleteDialogClose}
        isOpen={isDeleteModalOpen}
        confirmText="Delete"
        cancelButtonProps={{
          disabled: isDeleting,
        }}
        confirmButtonProps={{
          variant: 'destructive-prominent',
          onClick: onDelete,
          loading: isDeleting,
        }}
      >
        Are you sure you&apos;d like to delete the waterfall “{waterfallName}”?
        This cannot be undone.
      </ConfirmationModal>
      {isEstateWaterfallComparisonTrowserOpen && (
        <EstateWaterfallComparisonTrowser
          isOpen={isEstateWaterfallComparisonTrowserOpen}
          onClose={handleEstateWaterfallComparisonTrowserClose}
          waterfallIds={waterfallIds}
          assumptions={assumptions}
          comparisonState={comparisonSearchParams}
        />
      )}
      {/* RHF preserves form state by default, but we don't want this because the update should
      always reflect the current state of the map so we fully unmount on open / close */}
      {isEditModalOpen && !loading && (
        <EditEstateWaterfallTrowser
          {...commonTrowserProps}
          isOpen={isEditModalOpen}
          onClose={handlEditModalClose}
          waterfallId={waterfallId}
          sourceWaterfallId={waterfall?.parent?.id ?? undefined}
        />
      )}
      {isDuplicateModalOpen && !loading && (
        <DuplicateEstateWaterfallTrowser
          {...commonTrowserProps}
          sourceWaterfallId={waterfallId}
          isOpen={isDuplicateModalOpen}
          onClose={handleDuplicateDialogClose}
        />
      )}
      {isCompareModalOpen && !loading && (
        <EstateWaterfallComparisonModal
          legacyProps={{
            onClose: closeCompareModal,
            onSubmit: handleLegacyEstateWaterfallComparisonModalSubmit,
          }}
          comparisonState={comparisonSearchParams}
          onClose={closeCompareModal}
          onSubmit={handleEstateWaterfallComparisonModalSubmit}
          baseWaterfallId={waterfallId}
        />
      )}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" gap={1}>
          <CreateEstateWaterfallButton
            householdId={householdId}
            waterfallId={waterfallId}
          />
          <Button
            variant="secondary"
            size="sm"
            onClick={openCompareModal}
            startIcon={Scales01Icon}
          >
            Compare
          </Button>
          <ButtonWithPopover
            data-testid="EstateWaterfallBreadcrumbNavigation-menuButton"
            size="sm"
            variant="secondary"
            popperVariant="menuBelow"
            onClick={() => setIsMenuOpen(true)}
            isOpen={isMenuOpen}
            label={<DotsVerticalIcon size={20} />}
            square
            hideChevron={true}
          >
            <MenuItem
              icon={<Lightbulb02Icon size={20} />}
              onClick={openWaterfallTipsAndTricks}
              label="Waterfall tips and tricks"
            />
            <MenuItem
              icon={<Copy07Icon size={20} />}
              onClick={onClickDuplicate}
              label="Duplicate waterfall"
              loading={loading}
            />
            <MenuItem
              icon={<Edit02Icon size={20} />}
              onClick={() => {
                openEditModal();
              }}
              label="Edit waterfall"
              muiMenuItemProps={{
                divider: true,
              }}
            />
            <MenuItem
              typographyProps={{
                color: COLORS.FUNCTIONAL.ERROR.DEFAULT,
              }}
              icon={
                <Trash01Icon
                  color={COLORS.FUNCTIONAL.ERROR.DEFAULT}
                  size={20}
                />
              }
              onClick={() => {
                openDeleteModal();
              }}
              label="Delete waterfall"
            />
          </ButtonWithPopover>
        </Stack>
      </Stack>
    </>
  );
};
