import { useTheme } from '@mui/material';
import { CSSObject } from 'tss-react';

import { COLORS } from '@/styles/tokens/colors';

/**
 * @description Returns the styles to split a list into two columns with a divider.
 */
export function useListColumnStyles(overrides: CSSObject = {}): CSSObject {
  const theme = useTheme();
  return {
    columnCount: 2,
    columnWidth: '400px',
    columnRuleColor: COLORS.NEUTRAL_GRAY[900],
    columnRuleWidth: '1px',
    columnGap: theme.spacing(6),
    columnRuleStyle: 'solid',
    // display needs to be block to support splitting across multiple columns
    display: 'block !important',
    ...overrides,
  };
}
