import { Grid } from '@mui/material';
import { isEmpty, uniq } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';

import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareHiddenInput } from '@/components/form/formAwareInputs/FormAwareHiddenInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import { AssetProviderFormLinkIndicator } from '@/modules/assetProviderIntegrations/shared/AssetProviderFormLinkIndicator';
import { INTEGRATION_EXCLUDED_ENTITY_TYPES } from '@/modules/assetProviderIntegrations/shared/constants';
import { useAssetProviderDisplayName } from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import { SharedIntegrationEntityTypeahead } from '@/modules/assetProviderIntegrations/shared/SharedIntegrationEntityTypeahead';
import { DocumentUploaderWithList } from '@/modules/documents/DocumentUploaderWithList/DocumentUploaderWithList';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { DocumentType } from '@/types/schema';
import { isTodayNoonified } from '@/utils/dateUtils';
import { diagnostics } from '@/utils/diagnostics';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import {
  BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE as BUSINESS_ENTITY_DETAILS_NAMESPACE,
  BUSINESS_OWNERSHIP_PERCENTAGE_DECIMAL_SCALE,
  Fields as BusinessEntityDetailsFields,
} from '../BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import { useIsShortForm } from '../EntityShortFormModal/CreateEntityShortForm/context/CreateEntityShortForm.context';
import { useAssetCategoryOptions } from '../StructuredAssetsSubform/hooks/useAssetCategoryOptions';
import {
  BasicAssetsSubformType,
  Fields,
  Props,
  SubformField,
} from './BasicAssetsSubform.types';
import { AssetCategoryField } from './fields/AssetCategoryField';
import { ILITInsurancePolicyTable } from './ILITInsurancePolicyTable';
import { InsuranceAccountPolicyTable } from './InsuranceAccountPolicyTable';
import { useSyncDirtyStateToForm } from './useSyncDirtyStateToForm';

export function UI(props: Props) {
  const {
    variant,
    householdId,
    entityId,
    entityType,
    namespace,
    assetUpdateDescription,
    requireValuation,
    disabled,
    hideDescription,
  } = props;
  const [showDocumentUploadError, setShowDocumentUploadError] = useState(false);
  const { options: assetCategoryOptions, loading: formOptionsLoading } =
    useAssetCategoryOptions();

  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const { control, setValue } = useFormContext<Fields>();
  const isIntegrationSupportedEntityType =
    !INTEGRATION_EXCLUDED_ENTITY_TYPES.includes(entityType);

  const secondNamespace: keyof BasicAssetsSubformType = useMemo(() => {
    if (namespace === 'basicAssetsSubform') {
      return 'basicAssets';
    }

    return 'basicAssetsInitialFunding';
  }, [namespace]);

  const subformValues = useWatch({
    name: `${namespace}.${secondNamespace}` as const satisfies SubformField,
    control,
  });

  useSyncDirtyStateToForm({
    namespace,
    secondNamespace,
    subformValues,
  });

  // This is an escape hatch to read from the BusinessEntityDetailsSubform
  // if this subform is being used in a parent form that contains
  // the BusinessEntityDetailsSubform
  const businessDetailsSubform = useWatch({
    name: BUSINESS_ENTITY_DETAILS_NAMESPACE,
    control: control as unknown as Control<BusinessEntityDetailsFields>,
  });

  const attributedValue = (() => {
    if (
      !businessDetailsSubform?._ownershipSum ||
      !subformValues?.currentMarketValue
    )
      return null;
    return businessDetailsSubform._ownershipSum
      .div(100)
      .times(subformValues.currentMarketValue);
  })();

  // We only want to show the integration link in the BasicAssetsSubform
  // and the householdId is available (i.e., the BasicAssetsSubform is being used in a context where the householdId is available)
  // the we're collecting the current value of the entity, not the initial funding value
  const formSupportsIntegration =
    householdId &&
    isIntegrationSupportedEntityType &&
    secondNamespace === 'basicAssets' &&
    variant !== 'ilitPolicySummary' &&
    variant !== 'insuranceAccountPolicies';

  const { isHouseholdLinkedToAssetProvider } = useHouseholdDetailsContext();

  const shouldShowIntegrationLink =
    formSupportsIntegration && isHouseholdLinkedToAssetProvider;

  const isShortForm = useIsShortForm();
  const shouldRequireValuationDetails = isShortForm;

  const integrationProviderName = useAssetProviderDisplayName();
  const isLinkedToIntegration =
    integrationProviderName && !isEmpty(subformValues?.integrationEntityIds);

  // Because we prefill the date of valuation with today's date,
  // we use this to make the valuation amount *not* required if the date of valuation is today,
  // because it's likely it was prefilled
  const dateOfValuationIsToday = useCallback(() => {
    // Date inputs always have the time set to noon UTC.
    const dateOfValuation = subformValues?.dateOfValuation;
    if (!dateOfValuation) return false;
    return isTodayNoonified(dateOfValuation);
  }, [subformValues?.dateOfValuation]);

  return (
    <>
      <Grid container spacing={3} columns={12}>
        <Grid item sm={12}>
          <FormAwareHiddenInput<Fields>
            control={control}
            fieldName={
              `${namespace}.${secondNamespace}.valuationId` as const satisfies SubformField
            }
          />
          {shouldShowIntegrationLink && (
            <FormLayoutRow>
              <FormLayoutItem>
                <SharedIntegrationEntityTypeahead
                  filterToHouseholdId={householdId}
                  control={control}
                  fieldName={
                    `${namespace}.${secondNamespace}.integrationEntityIds` as const satisfies SubformField
                  }
                  includeAllEntitiesFieldName={
                    `${namespace}.${secondNamespace}.linkToAllAddeparEntities` as const satisfies SubformField
                  }
                />
              </FormLayoutItem>
            </FormLayoutRow>
          )}
          {variant === 'insuranceAccountPolicies' && (
            <>
              <FormLayoutRow>
                <FormLayoutItem width={12}>
                  <InsuranceAccountPolicyTable />
                </FormLayoutItem>
              </FormLayoutRow>
            </>
          )}
          {variant === 'ilitPolicySummary' && (
            <>
              <FormLayoutRow>
                <FormLayoutItem width={12}>
                  <ILITInsurancePolicyTable />
                </FormLayoutItem>
              </FormLayoutRow>
            </>
          )}
          {variant === 'default' && (
            <>
              {isLinkedToIntegration && !isShortForm && (
                <AssetProviderFormLinkIndicator
                  providerName={integrationProviderName}
                />
              )}
              {!isLinkedToIntegration && (
                <>
                  <FormLayoutRow>
                    <FormLayoutItem width={4}>
                      <AssetCategoryField
                        namespace={namespace}
                        secondNamespace={secondNamespace}
                        options={assetCategoryOptions}
                        disabled={disabled || formOptionsLoading}
                      />
                    </FormLayoutItem>
                    <FormLayoutItem width={4}>
                      <FormAwareCurrencyInput<Fields>
                        isDecimalJSInput
                        control={control}
                        disabled={disabled}
                        fieldName={
                          `${namespace}.${secondNamespace}.currentMarketValue` as const satisfies SubformField
                        }
                        label={
                          entityType === 'ilit'
                            ? 'Value of assets'
                            : 'Total market value'
                        }
                        required={
                          (!!subformValues?.dateOfValuation &&
                            !dateOfValuationIsToday() &&
                            shouldRequireValuationDetails) ||
                          requireValuation
                        }
                      />
                    </FormLayoutItem>
                    <FormLayoutItem width={4}>
                      <FormAwareDatePickerInput<Fields>
                        control={control}
                        disabled={disabled}
                        fieldName={
                          `${namespace}.${secondNamespace}.dateOfValuation` as const satisfies SubformField
                        }
                        label="Date of valuation"
                        required={
                          (!!subformValues?.currentMarketValue &&
                            shouldRequireValuationDetails) ||
                          requireValuation
                        }
                      />
                    </FormLayoutItem>
                  </FormLayoutRow>
                  {businessDetailsSubform?._ownershipSum && attributedValue && (
                    <FormLayoutRow>
                      <FormLayoutItem>
                        <Callout severity="info-high">
                          Based on the stated{' '}
                          {formatPercent(
                            businessDetailsSubform._ownershipSum,
                            BUSINESS_OWNERSHIP_PERCENTAGE_DECIMAL_SCALE
                          )}
                          % ownership above, a value of{' '}
                          {formatCurrency(attributedValue)} will be attributed
                          to owners in Luminary.
                        </Callout>
                      </FormLayoutItem>
                    </FormLayoutRow>
                  )}
                  {assetUpdateDescription && (
                    <FormLayoutRow>
                      <FormLayoutItem width={12}>
                        {assetUpdateDescription}
                      </FormLayoutItem>
                    </FormLayoutRow>
                  )}
                  {!hideDescription && (
                    <FormLayoutRow>
                      <FormLayoutItem width={12}>
                        <FormAwareTextInput<Fields>
                          control={control}
                          label="Description"
                          disabled={disabled}
                          fieldName={
                            `${namespace}.${secondNamespace}.description` as const satisfies SubformField
                          }
                          multiline
                          rows={4}
                        />
                      </FormLayoutItem>
                    </FormLayoutRow>
                  )}
                  {householdId && entityId && (
                    <FormLayoutRow>
                      <FormLayoutItem width={12}>
                        <DocumentUploaderWithList
                          uploaderLabel=""
                          documentIds={documentIds}
                          householdId={householdId}
                          listDocumentsLike={undefined}
                          getCreateDocumentInput={(file) => ({
                            householdID: householdId,
                            entityID: entityId,
                            name: file.fileName,
                            type: DocumentType.AssetValuation,
                            fileID: file.fileId,
                          })}
                          onDocumentUploadSuccess={({ documentId }) => {
                            const uniqueDocumentIds = uniq([
                              ...documentIds,
                              documentId,
                            ]);
                            setDocumentIds(uniqueDocumentIds);
                            setValue(
                              `${namespace}.${secondNamespace}.documentIds` as const satisfies SubformField,
                              uniqueDocumentIds
                            );
                            setShowDocumentUploadError(false);
                          }}
                          errorMessage={
                            showDocumentUploadError
                              ? 'Could not upload the document'
                              : undefined
                          }
                          onDocumentUploadError={(error) => {
                            diagnostics.error(
                              'Caught error when uploading asset valuation document',
                              error
                            );
                            setShowDocumentUploadError(true);
                          }}
                        />
                      </FormLayoutItem>
                    </FormLayoutRow>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <FormAwareHiddenInput<Fields>
        control={control}
        fieldName={
          `${namespace}.${secondNamespace}.accountId` as const satisfies SubformField
        }
      />
    </>
  );
}
