import { ObservableQuery, OperationVariables } from '@apollo/client';
import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { useCallback } from 'react';
import { useFormState } from 'react-hook-form';

import { ShowFeedbackFn } from '@/components/notifications/Feedback/feedback.context';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { shouldRefetchQuery } from '@/graphql/client.utils';
import { useReportError } from '@/hooks/useReportError';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { LifetimeExemptionCardDocument } from '@/modules/gifting/LifetimeExemptionCard/graphql/LifetimeExemptionCard.generated';
import {
  BaselineIncomeAndExpensesFormShape,
  GiftDesignerBasicInformationFormShape,
  GiftDesignerModelScenariosFormShape,
  NO_GIFTING_SENTINEL,
  OutOfEstatePortfolioFormShape,
  useGiftDesignerModelScenariosDefaultValues,
} from '@/modules/gifting/proposal/designer/form';
import { AnnuallyRecurringValue as AnnuallyRecurringValueBaseline } from '@/modules/gifting/proposal/designer/form/components/BaselineIncomeAndExpensesModal/BaselineIncomeAndExpensesModal.fields';
import { AnnuallyRecurringValue as AnnuallyRecurringValueGift } from '@/modules/gifting/proposal/designer/form/components/ScenarioGiftModal/ScenarioGiftModal.fields';
import { AnnuallyRecurringValue as AnnuallyRecurringValueIncomeAndExpenses } from '@/modules/gifting/proposal/designer/form/components/ScenarioIncomeAndExpensesModal/ScenarioIncomeAndExpensesModal.fields';
import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';
import {
  AugmentedCreateGiftingProposalCashFlowInput,
  AugmentedCreateGiftingProposalPortfolioInput,
  AugmentedCreateGiftingProposalScenarioInput,
  AugmentedUpdateGiftingProposalPortfolioInput,
  GiftingProposalGiftRecipientKind,
  GiftingProposalPortfolioPortfolioType,
  GiftingProposalSelectedPreTaxReturnCategory,
  GiftingProposalStatus,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';

import {
  DEFAULT_PRE_TAX_RETURNS,
  DEFAULT_TAX_DRAG_PERCENTAGES,
} from '../../designerConstants';
import { useCreateGiftProposalMutation } from '../graphql/CreateGiftProposal.generated';
import { GetGiftProposalDetails_ProposalFragment } from '../graphql/GetGiftProposalDetails.generated';
import { useUpdateGiftProposalMutation } from '../graphql/UpdateGiftProposal.generated';
import { useGiftProposalDetails } from './useGiftProposalDetails';

export enum SaveGiftDesignerInputKind {
  SaveBasicInformation = 'saveBasicInformation',
  Finalize = 'finalize',
  SaveModelScenarios = 'saveModelScenarios',
  CopySecureLink = 'copySecureLink',
}

// Three types of inputs to the saveGiftDesigner function
// 1. Save the basic information form and return projections
// 2. Save the model scenarios form and finalize the proposal
// 3. Save the model scenarios form and return projections
type SaveGiftDesignerInput =
  | {
      kind: SaveGiftDesignerInputKind.SaveBasicInformation;
      formValues: GiftDesignerBasicInformationFormShape;
    }
  | {
      kind: SaveGiftDesignerInputKind.Finalize;
      formValues: GiftDesignerModelScenariosFormShape;
    }
  | {
      kind: SaveGiftDesignerInputKind.SaveModelScenarios;
      formValues: GiftDesignerModelScenariosFormShape;
    }
  | {
      kind: SaveGiftDesignerInputKind.CopySecureLink;
      formValues: GiftDesignerModelScenariosFormShape;
    };

function getPortfolioCreateInput(
  portfolios: OutOfEstatePortfolioFormShape[]
): AugmentedCreateGiftingProposalPortfolioInput[] {
  return compact(
    portfolios.map((p, idx) => {
      if (p.portfolioId) {
        return null;
      }

      return {
        create: {
          displayName: p.name,
          order: idx,
          portfolioType: p.type
            ? p.type
            : GiftingProposalPortfolioPortfolioType.NonGrantorIndividual,
          startingAssetValue: p.amount ?? new Decimal(0),
        },
      };
    })
  );
}

function getPortfolioUpdateInput(
  portfolios: OutOfEstatePortfolioFormShape[]
): AugmentedUpdateGiftingProposalPortfolioInput[] {
  return compact(
    portfolios.map((p, idx) => {
      if (!p.portfolioId) {
        return null;
      }
      return {
        id: p.portfolioId,
        update: {
          displayName: p.name,
          order: idx,
          portfolioType: p.type
            ? p.type
            : GiftingProposalPortfolioPortfolioType.NonGrantorIndividual,
          startingAssetValue: p.amount ?? new Decimal(0),
        },
      };
    })
  );
}

function getBaseCashFlowsInput(
  cashFlows: BaselineIncomeAndExpensesFormShape[]
): AugmentedCreateGiftingProposalCashFlowInput[] {
  return cashFlows.map((cf, idx) => ({
    create: {
      amount: cf.amount ?? new Decimal(0),
      annuallyRecurring:
        cf.annuallyRecurring === AnnuallyRecurringValueBaseline.true
          ? true
          : false,
      cashFlowType: cf.cashFlowType,
      displayName: cf.displayName,
      endYear: cf.endYear?.toNumber() ?? null,
      growthPercentage: cf.growthPercentage,
      order: idx,
      startYear: cf.startYear.toNumber(),
    },
  }));
}

interface GetGiftingScenariosInputInput {
  formValues: GiftDesignerModelScenariosFormShape;
  showFeedback: ShowFeedbackFn;
  giftProposal: GetGiftProposalDetails_ProposalFragment;
}

function getGiftingScenariosInput({
  formValues,
  showFeedback,
  giftProposal,
}: GetGiftingScenariosInputInput): {
  create: AugmentedCreateGiftingProposalScenarioInput[];
} {
  const isGiftProposalEnhancementsEnabled = isFeatureFlagEnabled(
    'gift_proposal_enhancements'
  );

  return formValues.scenarios.reduce(
    (acc, s, idx) => {
      let isNoGiftingScenario = false;
      if ('isNoGiftingScenario' in s && s.isNoGiftingScenario) {
        isNoGiftingScenario = true;
      }

      const withScenarioGifts = s.scenarioGifts?.map((sg, idx) => {
        const recipientInformation = (() => {
          if (sg.isNewRecipient) {
            // If it's a new recipient, we will create them with the scenario gift
            return {};
          }
          switch (sg.recipientKind) {
            case GiftingProposalGiftRecipientKind.OutOfEstatePortfolio:
              return {
                recipientPortfolioID: sg.recipientId,
                recipientKind: sg.recipientKind,
              };
            case GiftingProposalGiftRecipientKind.Individual:
              return {
                recipientClientProfileID: sg.recipientId,
                recipientKind: sg.recipientKind,
              };
            case GiftingProposalGiftRecipientKind.Organization:
              return {
                recipientClientOrganizationID: sg.recipientId,
                recipientKind: sg.recipientKind,
              };
            default: {
              showFeedback(`Invalid recipient kind in scenario ${idx + 1}`);
              throw new UnreachableError({
                case: sg.recipientKind as never,
                message: 'Invalid recipient kind',
              });
            }
          }
        })();

        const withRecipientPortfolio:
          | AugmentedCreateGiftingProposalPortfolioInput
          | undefined = (() => {
          if (
            !sg.isNewRecipient ||
            !sg.newRecipientName ||
            !sg.newRecipientType ||
            !giftProposal.giftingProposal?.id
          ) {
            return undefined;
          }

          return {
            create: {
              displayName: sg.newRecipientName,
              portfolioType: sg.newRecipientType,
              order: 0, // We don't have context on what the portfolio order should be here, so just set it to 0
              startingAssetValue: new Decimal(0), // The starting asset value of the portfolio can be updated on the overview page
              giftingProposalID: giftProposal.giftingProposal.id,
            },
          };
        })();

        return {
          create: {
            displayName: sg.displayName,
            order: idx,
            ...recipientInformation,
            amount: sg.amount ?? new Decimal(0),
            annuallyRecurring:
              sg.annuallyRecurring === AnnuallyRecurringValueGift.true,
            discount: sg.discount,
            discountPercentage: sg.discount ? sg.discountPercent : undefined,
            endYear: sg.endYear?.toNumber() ?? null,
            growthPercentage: sg.growthPercentage,
            isTaxable: sg.isTaxable ?? false,
            nonTaxableGiftType: sg.nonTaxableGiftType,
            startYear: sg.startYear.toNumber(),
          },
          withSenders: sg.senderIds.map((senderId) => ({
            create: {
              grantorID: senderId,
            },
          })),
          withRecipientPortfolio,
        };
      });

      const withScenarioCashFlows = s.scenarioCashFlows?.flatMap((cf, idx) => {
        if (cf.isBaselineCashFlow) {
          return [];
        }

        return {
          create: {
            displayName: cf.displayName,
            order: idx,
            amount: cf.amount ?? new Decimal(0),
            cashFlowType: cf.cashFlowType,
            annuallyRecurring:
              cf.annuallyRecurring ===
              AnnuallyRecurringValueIncomeAndExpenses.true,
            endYear: cf.endYear?.toNumber() ?? null,
            growthPercentage: cf.growthPercentage,
            startYear: cf.startYear.toNumber(),
            portfolioID: isGiftProposalEnhancementsEnabled
              ? cf.portfolioID
              : undefined,
          },
        };
      });

      return {
        ...acc,
        create: [
          ...acc.create,
          {
            create: {
              displayName: isNoGiftingScenario ? NO_GIFTING_SENTINEL : s.name,
              exemptionSunsets: isGiftProposalEnhancementsEnabled
                ? s.exemptionSunsets ?? false
                : undefined,
              order: idx,
            },
            withScenarioGifts,
            withScenarioCashFlows,
          },
        ],
      };
    },
    {
      create: [] as AugmentedCreateGiftingProposalScenarioInput[],
    }
  );
}

export function useSaveGiftDesigner({
  proposalId,
  householdId,
}: {
  proposalId: string;
  householdId: string;
}) {
  const isGiftProposalEnhancementsEnabled = isFeatureFlagEnabled(
    'gift_proposal_enhancements'
  );

  // This is fetched on the parent page, so it will be in the cache
  const { giftProposal } = useGiftProposalDetails(proposalId);
  const { createErrorFeedback, showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { getDefaultValues } = useGiftDesignerModelScenariosDefaultValues({
    proposalId,
  });

  const { reset, setError } =
    useFormContext<GiftDesignerModelScenariosFormShape>();

  const { defaultValues: basicInformationDefaultValues } =
    useFormState<GiftDesignerBasicInformationFormShape>();

  const sharedMutationOptions = {
    refetchQueries: 'active' as const,
    awaitRefetchQueries: true,
    onQueryUpdated: (query: ObservableQuery<unknown, OperationVariables>) => {
      // We don't need to wait for the lifetime exemption card to refetch since we navigate away
      return shouldRefetchQuery(query.queryName, {
        ignoredQueryDocuments: [LifetimeExemptionCardDocument],
      });
    },
    onError: createErrorFeedback(
      'Could not save this gift proposal. Please try again.'
    ),
  };

  const [
    createGiftProposal,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateGiftProposalMutation({
    ...sharedMutationOptions,
  });
  const [
    updateGiftProposal,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateGiftProposalMutation({
    ...sharedMutationOptions,
  });

  const getStatusForSubmit = useCallback(() => {
    // All save events, including saving just the basic information, should set
    // the status to ProposalCreated.
    return GiftingProposalStatus.ProposalCreated;
  }, []);

  const getSelectedYearOfAnalysisInput = useCallback(
    (lengthOfAnalysis?: Decimal | null) => {
      return getYear(new Date()) + (lengthOfAnalysis?.toNumber() ?? 0);
    },
    []
  );

  const saveGiftDesigner = useCallback(
    async ({ kind, formValues }: SaveGiftDesignerInput) => {
      if (
        giftProposal !== NEW_PROPOSAL_ID &&
        giftProposal?.giftingProposal?.id
      ) {
        if (
          kind === SaveGiftDesignerInputKind.Finalize ||
          kind === SaveGiftDesignerInputKind.SaveModelScenarios ||
          kind === SaveGiftDesignerInputKind.CopySecureLink
        ) {
          const {
            lowPreTaxReturn,
            lowTaxDrag,
            mediumPreTaxReturn,
            mediumTaxDrag,
            highPreTaxReturn,
            highTaxDrag,
          } = formValues.taxReturnLimits;

          const status = getStatusForSubmit();

          const withGiftingScenarios = getGiftingScenariosInput({
            formValues,
            showFeedback,
            giftProposal,
          }).create;

          const allHaveDisplayNames = withGiftingScenarios.every(
            (scenario) => !!scenario.create.displayName
          );

          if (!allHaveDisplayNames) {
            const message = 'All scenarios must have a name.';

            diagnostics.error(message);
            showFeedback(message);
            // Sets isSubmitSuccessful to false
            setError('root', {
              type: 'manual',
              message,
            });
            // Sets isSubmitting to false
            return Promise.resolve();
          }

          // Saving the model scenarios form
          await updateGiftProposal({
            variables: {
              input: {
                id: proposalId,
                update: {},
                updateGiftingProposal: {
                  id: giftProposal.giftingProposal.id,
                  update: {
                    lengthOfAnalysis: (
                      formValues.lengthOfAnalysis || new Decimal(0)
                    ).toNumber(),
                    status,
                    showAfterEstateTax: formValues.showAfterEstateTax,
                    // This is handed by each scenario now
                    exemptionSunsets: isGiftProposalEnhancementsEnabled
                      ? undefined
                      : formValues.exemptionSunsets,
                    exemptionGrowthRate: formValues.exemptionGrowthRate,
                    selectedPreTaxReturnCategory: formValues.annualPreTaxReturn,
                    selectedYearOfAnalysis: formValues.yearOfAnalysis,
                    clearGiftingScenarios: true,
                    preTaxReturnPercentageLow: lowPreTaxReturn,
                    preTaxReturnPercentageMedium: mediumPreTaxReturn,
                    preTaxReturnPercentageHigh: highPreTaxReturn,
                    taxDragPercentageLow: lowTaxDrag,
                    taxDragPercentageMedium: mediumTaxDrag,
                    taxDragPercentageHigh: highTaxDrag,
                  },
                  withGiftingScenarios,
                },
              },
            },
          });

          // Because we can create portfolio recipients from the scenario gift modal, we need to reset the form
          // state so that the possible recipients are updated with the new ID and form modal state can be set isNewRecipient
          // to false.
          const newFormValues = await getDefaultValues();
          // We need to reset the form values to the new default values
          reset(newFormValues);
          return;
        } else {
          const status = getStatusForSubmit();
          const originalOutOfEstatePortfolios =
            basicInformationDefaultValues?.outOfEstatePortfolios?.flatMap(
              (p) => p?.portfolioId ?? []
            ) ?? [];
          const currentOutOfEstatePortfolios = new Set(
            formValues.outOfEstatePortfolios?.map((p) => p?.portfolioId)
          );
          const removeOutOfEstatePortfolioIDs =
            originalOutOfEstatePortfolios.filter(
              (id) => !currentOutOfEstatePortfolios.has(id)
            );

          // Updating the basic information form
          return await updateGiftProposal({
            variables: {
              input: {
                id: proposalId,
                update: {
                  displayName: formValues.name,
                },
                updateGiftingProposal: {
                  id: giftProposal.giftingProposal.id,
                  update: {
                    lengthOfAnalysis: formValues.lengthOfAnalysis?.toNumber(),
                    selectedYearOfAnalysis: getSelectedYearOfAnalysisInput(
                      formValues.lengthOfAnalysis
                    ), // Set the year of analysis to the max year
                    inEstatePortfolioValue: formValues.inEstatePortfolio,
                    status,
                    clearOutOfEstatePortfolios: false,
                    clearBaseCashFlows: true,
                    removeOutOfEstatePortfolioIDs,
                  },
                  updateOutOfEstatePortfolios: getPortfolioUpdateInput(
                    formValues.outOfEstatePortfolios
                  ),
                  withOutOfEstatePortfolios: getPortfolioCreateInput(
                    formValues.outOfEstatePortfolios
                  ),
                  withBaseCashFlows: getBaseCashFlowsInput(
                    formValues.baselineIncomeAndExpenses
                  ),
                },
              },
            },
          });
        }
      } else {
        if (
          kind === SaveGiftDesignerInputKind.Finalize ||
          kind === SaveGiftDesignerInputKind.SaveModelScenarios ||
          kind === SaveGiftDesignerInputKind.CopySecureLink
        ) {
          const errDescription =
            'Cannot finalize or save model scenarios for a gift proposal that has not been created yet.';

          const err = new Error(errDescription);

          reportError(errDescription, err, {
            proposalId,
            householdId,
          });
          throw err;
        }

        const status = getStatusForSubmit();

        // Saving the basic information form
        return await createGiftProposal({
          variables: {
            input: {
              create: {
                displayName: formValues.name,
                householdID: householdId,
                clientNotes: '',
                includeCumulativeView: false,
              },
              withGiftingProposal: {
                create: {
                  status,
                  lengthOfAnalysis: formValues.lengthOfAnalysis?.toNumber(),
                  selectedYearOfAnalysis: getSelectedYearOfAnalysisInput(
                    formValues.lengthOfAnalysis
                  ), // Set the year of analysis to the max year
                  inEstatePortfolioValue:
                    formValues.inEstatePortfolio ?? new Decimal(0),
                  selectedPreTaxReturnCategory:
                    GiftingProposalSelectedPreTaxReturnCategory.Medium,
                  ...DEFAULT_PRE_TAX_RETURNS,
                  ...DEFAULT_TAX_DRAG_PERCENTAGES,
                },
                withOutOfEstatePortfolios: getPortfolioCreateInput(
                  formValues.outOfEstatePortfolios
                ),
                withBaseCashFlows: getBaseCashFlowsInput(
                  formValues.baselineIncomeAndExpenses
                ),
              },
            },
          },
        });
      }
    },
    [
      giftProposal,
      getStatusForSubmit,
      showFeedback,
      updateGiftProposal,
      proposalId,
      isGiftProposalEnhancementsEnabled,
      getDefaultValues,
      reset,
      setError,
      basicInformationDefaultValues?.outOfEstatePortfolios,
      getSelectedYearOfAnalysisInput,
      createGiftProposal,
      householdId,
      reportError,
    ]
  );

  const savedProposalId =
    createData?.createProposalV2.id ?? updateData?.updateProposalV2.id ?? null;
  const loading = createLoading || updateLoading;
  const error = createError || updateError;

  return { saveGiftDesigner, savedProposalId, loading, error };
}
