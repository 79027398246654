import Decimal from 'decimal.js';
import { noop } from 'lodash';
import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { useModalState } from '@/hooks/useModalState';
import {
  GetWaterfallSummary_EstateWaterfallFragment,
  GetWaterfallSummary_EstateWaterfallVizNodeFragment,
} from '@/modules/estateWaterfall/graphql/GetWaterfallSummary.generated';
import { EstateWaterfallSummaryTileSummaryPanelProps } from '@/modules/summaryPanels/EstateWaterfallSummmaryTileSummaryPanel/types';
import { AfterDeath } from '@/types/schema';

type WaterfallSummaryTableItemKind =
  | {
      node: GetWaterfallSummary_EstateWaterfallVizNodeFragment;
      kind: 'node';
    }
  | {
      kind: 'tax';
    };
export type WaterfallSummaryTableItem = {
  afterDeath: AfterDeath;
  id: string;
  sectionIndicator: {
    backgroundColor: string;
  };
  entity: {
    lineOne: string;
    lineTwo: string;
  };
  /**
   * The value of the entity without projections
   */
  value: Decimal;
  /**
   * The value of the entity with projections
   */
  projectedValue?: Decimal;
  hasHypotheticalTransfer?: boolean;
  valueBeforeTransfers?: Decimal | null;
} & WaterfallSummaryTableItemKind;

interface EstateTaxSummaryModalState {
  afterDeath: AfterDeath;
  type: 'estateTax';
}

interface VizNodeSummaryModalState {
  row: WaterfallSummaryTableItem;
  type: 'vizNode';
}

interface HypotheticalTransfersSummaryModalState {
  type: 'hypotheticalTransfers';
  defaultOpenTransferId?: string;
}

interface GrowthProfilesModalState {
  type: 'growthProfiles';
}

interface ExternalTransfersSummaryModalState {
  type: 'externalTransfers';
}

interface SummaryTileDetailsModalState
  extends Omit<EstateWaterfallSummaryTileSummaryPanelProps, 'onClose'> {
  type: 'summaryTileDetails';
}

export type SummaryModalState =
  | EstateTaxSummaryModalState
  | VizNodeSummaryModalState
  | HypotheticalTransfersSummaryModalState
  | GrowthProfilesModalState
  | ExternalTransfersSummaryModalState
  | SummaryTileDetailsModalState;

type SummaryModalReturn = ReturnType<typeof useModalState<SummaryModalState>>;
type SummaryPanel = SummaryModalReturn[0] & SummaryModalReturn[1];

export interface WaterfallSummaryContext {
  waterfall: GetWaterfallSummary_EstateWaterfallFragment | null;
  hasNoWaterfallNodes: boolean;
  loading: boolean;
  summaryPanel: SummaryPanel;
}

export const WaterfallSummaryContext = createContext<WaterfallSummaryContext>({
  waterfall: null,
  hasNoWaterfallNodes: false,
  loading: false,
  summaryPanel: {
    closeModal: noop,
    openModal: noop,
    data: null,
    isModalOpen: false,
    setModalIsOpen: noop,
  },
});

export const useWaterfallSummaryContext = () => {
  return useGuardedContext(WaterfallSummaryContext, 'WaterfallSummaryProvider');
};
