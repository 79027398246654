import { isNumber } from 'lodash';

import { computedRoutes } from './routes';

export type RouteKey = keyof typeof computedRoutes;
export type NavigationKey = RouteKey | number | '..';

export function isRouteKey(x?: unknown): x is RouteKey {
  return !isNumber(x) && !!x && !!computedRoutes[x as RouteKey];
}

const getParamsFromPath = (path: string): string[] => {
  const pathParts = path.split('/');
  // this is kind of a weird use of flatMap, but basically this is the only native one-pass solution to
  // transform an array like ["client", ":householdId", "something", ":somethingId"]
  // into ["householdId", "somethingId"]
  const pathParams = pathParts.flatMap((pathPart) => {
    if (pathPart.startsWith(':')) return [pathPart.slice(1)];
    return [];
  });
  return pathParams;
};

const validateParams = (
  path: string,
  providedParams: Record<string, string>,
  requiredParams: string[]
) => {
  requiredParams.forEach((requiredParam) => {
    if (!providedParams[requiredParam])
      throw new Error(
        `Path "${path}" missing value for required parameter "${requiredParam}"`
      );
  });
};

const fillPathWithParams = (path: string, params: Record<string, string>) => {
  const pathParams = getParamsFromPath(path);
  validateParams(path, params, pathParams);
  pathParams.forEach((param) => {
    path = path.replace(`:${param}`, params[param]!);
  });
  return path;
};

export const getCompletePathFromRouteKey = (
  routeKey: RouteKey,
  params: Record<string, string>,
  searchParams?: Record<string, string | boolean | number> | URLSearchParams
) => {
  const path = computedRoutes[routeKey];

  if (!path) {
    throw new Error(
      `No path found for route key "${routeKey}". This is usually because the path isn't referenced in the routes.tsx file.`
    );
  }

  const completePath = fillPathWithParams(path, params);
  const searchString = (() => {
    const search = new URLSearchParams(
      (searchParams as Record<string, string>) || {}
    ).toString();
    if (!search) return '';
    return `?${search}`;
  })();
  return completePath + searchString;
};
