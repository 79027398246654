import {
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import Decimal from 'decimal.js';
import { ComponentProps, useMemo } from 'react';

import { zIndexes } from '@/styles/zIndexes';
import { formatCurrency } from '@/utils/formatting/currency';

import { getPreferredBackground } from '../chartAccessories';
import { StackedVerticalBar } from './StackedVerticalBar';
import { VerticalBarSubsection } from './VerticalBarSubsection';

interface VerticalBarSectionProps {
  isFirst: boolean;
  section: ComponentProps<typeof StackedVerticalBar>['sections'][number];
  isLast: boolean;
  total: number;
}

export function VerticalBarSection({
  section,
  isFirst,
  isLast,
  total,
}: VerticalBarSectionProps) {
  const {
    value,
    color,
    backgroundCss,
    sectionTextColor,
    subsections,
    typographyProps,
  } = section;
  const theme = useTheme();

  const backgroundForBar = getPreferredBackground({
    backgroundCss,
    color,
  });

  const showValue = !section.hideValue;

  const sectionHeightPercent = (Math.abs(section.value) / total) * 100;

  const sectionSx: SxProps<Theme> = useMemo(
    () => ({
      background: backgroundForBar,
      width: '100%',
      minWidth: theme.spacing(6),
      height: `${sectionHeightPercent}%`,
      minHeight: theme.spacing(3),
      position: 'relative',
      borderTopLeftRadius: isFirst ? '6px' : '0px',
      borderTopRightRadius: isFirst ? '6px' : '0px',
      borderBottomLeftRadius: isLast ? '6px' : '0px',
      borderBottomRightRadius: isLast ? '6px' : '0px',
      overflow: 'hidden', // make sure that any subsections are also affected by the border radius
      cursor: section.onClick ? 'pointer' : undefined,
    }),
    [
      backgroundForBar,
      isFirst,
      isLast,
      section.onClick,
      sectionHeightPercent,
      theme,
    ]
  );

  return (
    <Box onClick={section.onClick} sx={sectionSx}>
      {showValue && (
        <Stack
          direction="column"
          alignItems="center"
          height="100%"
          width="100%"
          justifyContent="center"
          sx={{
            p: 1,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            zIndex: zIndexes.CARD,
          }}
        >
          <Typography
            variant="h6"
            component="p"
            color={sectionTextColor ?? theme.palette.common.white}
            {...typographyProps}
          >
            {formatCurrency(new Decimal(value), {
              notation: 'compact',
            })}
          </Typography>
        </Stack>
      )}

      {subsections && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            height: '100%',
            position: 'relative',
            zIndex: zIndexes.CARD + 1,
          }}
        >
          {subsections.map((subsection, idx) => (
            <VerticalBarSubsection
              key={`subsection-${idx}`}
              subsection={subsection}
              total={value}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
