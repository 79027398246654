import { Stack } from '@mui/material';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { LinkExternal01Icon } from '@/components/icons/LinkExternal01Icon';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { AssetIntegration_IntegrationEntityFragment } from './graphql/assetProviderIntegration.fragments.generated';

export type ExternalIntegrationButtonProps = {
  integrationEntity: AssetIntegration_IntegrationEntityFragment;
} & Omit<Partial<ButtonProps>, 'children'>;

export function ExternalIntegrationButton({
  integrationEntity,
  ...rest
}: ExternalIntegrationButtonProps) {
  const { householdId } = useHouseholdDetailsContext();
  return (
    <Button
      external
      target="_blank"
      href={`/api/v1/integration/redirect/integration-entity?householdId=${householdId}&integrationEntityId=${integrationEntity.id}`}
      variant="secondary"
      size="sm"
      fullWidth
      {...rest}
    >
      <Stack direction="row" spacing={1} justifyContent="center">
        See assets for {integrationEntity.name}&nbsp;
        <LinkExternal01Icon size={18} />
      </Stack>
    </Button>
  );
}
