import { FetchResult } from '@apollo/client';
import axios from 'axios';
import { useCallback } from 'react';

import { FileUploaderProps } from '@/modules/files/FileUploader/FileUploader';

import { getSha256HashFromFile } from './fileUploaderUtils';

// The progress increments are used to calculate the progress of a single file upload.
const PROGRESS_INCREMENTS = {
  FILE_ACCEPTED: 5,
  FILE_CONTENTS_HASHED: 10,
  DOCUMENT_REGISTERED: 5,
} as const;
const PROGRESS_INCREMENTS_SUM = Object.values(PROGRESS_INCREMENTS).reduce(
  (acc, val) => acc + val,
  0
);
const REMAINING_PROGRESS_PERCENT_DECIMAL =
  (100 - PROGRESS_INCREMENTS_SUM) / 100;

export type UseUploadFileProps<Mutation> = Pick<
  FileUploaderProps<Mutation>,
  'generateSignedUploadURL'
> & {
  // When uploading a single file, we can set incremental progress values.
  setSingleFileUploadProgress: React.Dispatch<React.SetStateAction<number>>;
  // When uploading multiple files, we can increment the progress value.
  setMultipleFileUploadProgress: React.Dispatch<React.SetStateAction<number>>;
};

export interface UploadedFileData<Mutation> {
  file: File | null;
  result: FetchResult<Mutation> | null;
}

export function useUploadFile<Mutation>({
  generateSignedUploadURL,
  setSingleFileUploadProgress,
  setMultipleFileUploadProgress,
}: UseUploadFileProps<Mutation>) {
  const uploadFile = useCallback(
    async (file: File): Promise<UploadedFileData<Mutation>> => {
      setSingleFileUploadProgress(PROGRESS_INCREMENTS.FILE_ACCEPTED);

      const hash = await getSha256HashFromFile(file);
      setSingleFileUploadProgress(
        (lastValue) =>
          (lastValue || 0) + PROGRESS_INCREMENTS.FILE_CONTENTS_HASHED
      );

      const { signedUploadURL, ...mutationResult } =
        await generateSignedUploadURL(hash, {
          onError: (err) => {
            setSingleFileUploadProgress(0);
            throw err;
          },
          onCompleted: () =>
            setSingleFileUploadProgress(
              (lastValue) => lastValue + PROGRESS_INCREMENTS.DOCUMENT_REGISTERED
            ),
        });

      if (!mutationResult.data) {
        setSingleFileUploadProgress(0);
        throw new Error(
          `We weren't able to upload your file. Please try again.`
        );
      }

      await axios(signedUploadURL, {
        method: 'PUT',
        headers: {
          'Content-Type': file.type,
          'X-Amz-Content-Sha256': hash,
        },
        data: file,
        onUploadProgress: (progressEvent) => {
          if (typeof progressEvent.progress === 'undefined') return;
          const nextProgressValue =
            PROGRESS_INCREMENTS_SUM +
            REMAINING_PROGRESS_PERCENT_DECIMAL * (progressEvent.progress * 100);
          setSingleFileUploadProgress(nextProgressValue);
        },
      });

      setMultipleFileUploadProgress((lastValue) => lastValue + 1);
      return { file, result: mutationResult };
    },
    [
      setSingleFileUploadProgress,
      generateSignedUploadURL,
      setMultipleFileUploadProgress,
    ]
  );

  return {
    uploadFile,
  };
}
