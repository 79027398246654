import { ApolloError } from '@apollo/client';
import { useEffect, useState } from 'react';

import { useReportError } from '@/hooks/useReportError';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { useCreateHouseholdMutation } from '@/modules/household/graphql/Households.generated';
import { diagnostics } from '@/utils/diagnostics';

interface UsePreCreateHouseholdProps {
  isOpen: boolean;
  needsPreCreateHousehold: boolean;
}

interface UsePreCreateHouseholdReturn {
  isReady: boolean;
  preCreatedHouseholdId: string | null;
  error: ApolloError | null;
}

/**
 * This pre-creates an empty household, for the AI onboarding flow. We require
 * the household id to be set in order to upload documents. This can remain null
 * if the user does not need to upload documents.
 * @param needsPreCreateHousehold
 */
export function usePreCreateHousehold({
  isOpen,
  needsPreCreateHousehold,
}: UsePreCreateHouseholdProps): UsePreCreateHouseholdReturn {
  const currentUser = useCurrentUser();
  const { reportError } = useReportError();

  const [isReady, setIsReady] = useState(false);
  const [preCreatedHouseholdId, setPreCreatedHouseholdId] = useState<
    string | null
  >(null);
  const [error, setError] = useState<ApolloError | null>(null);

  const [preCreateHousehold] = useCreateHouseholdMutation({
    onError: (error: ApolloError) => {
      setIsReady(true);
      setError(error);
      // We don't want to block the user from continuing if this fails.
      // Hide the document uploader and proceed with the regular flow.
      reportError(
        'error pre-creating household during AI Onboarding flow',
        error
      );
    },
    onCompleted: (data) => {
      setIsReady(true);
      setPreCreatedHouseholdId(data.createHousehold.id);
      setError(null);
      diagnostics.debug(
        '[usePreCreateHousehold] Pre-created household for AI Onboarding flow',
        {
          householdId: data.createHousehold.id,
        }
      );
    },
  });

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (!needsPreCreateHousehold) {
      setIsReady(true);
      return;
    }

    if (needsPreCreateHousehold && !preCreatedHouseholdId) {
      setIsReady(false);
      void preCreateHousehold({
        variables: {
          input: {
            primaryRelationshipOwnerID: currentUser?.id ?? '',
          },
        },
      });
    }
  }, [
    currentUser,
    isOpen,
    needsPreCreateHousehold,
    preCreateHousehold,
    preCreatedHouseholdId,
  ]);

  return {
    isReady,
    preCreatedHouseholdId,
    error,
  };
}
