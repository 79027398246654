import { useNavigate } from 'react-router-dom';

import { ROUTE_KEYS } from '@/navigation/constants';
import {
  getCompletePathFromRouteKey,
  RouteKey,
} from '@/navigation/navigationUtils';
import { EntityTaskType } from '@/types/schema';

import { GRAT_IMPLEMENTATION_TASKS } from '../taskTypes';

interface HandleTaskOnClickOpts {
  taskId: string;
  entityId: string;
  householdId: string;
  taskType: EntityTaskType;
  from?: RouteKey;
}

export function useGetHandleTaskOnClick() {
  const navigate = useNavigate();

  function handleTaskOnClick({
    taskId,
    entityId,
    householdId,
    taskType,
    from,
  }: HandleTaskOnClickOpts) {
    // implementation tasks are handled in their own interface, so we
    if (GRAT_IMPLEMENTATION_TASKS.find((t) => t === taskType)) {
      const implementationTaskRoute = getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_ENTITY_IMPLEMENTATION,
        {
          entityId: entityId,
          householdId,
        },
        {
          taskId,
        }
      );

      return navigate(implementationTaskRoute, {
        state: { from: from ?? null },
      });
    }

    const standaloneTaskRoute = getCompletePathFromRouteKey(
      ROUTE_KEYS.TASK_STANDALONE,
      {
        taskId,
      },
      {
        postTaskRedirect: 'back',
      }
    );

    return navigate(standaloneTaskRoute, {
      state: { from: from ?? null },
    });
  }

  return handleTaskOnClick;
}
