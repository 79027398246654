import { Box, Stack, Typography } from '@mui/material';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { Medallion } from '@/components/display/Medallion/Medallion';
import { Button } from '@/components/form/baseInputs/Button';
import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { SelectInput } from '@/components/form/baseInputs/SelectInput';
import { DataIcon } from '@/components/icons/DataIcon';
import { Edit05Icon } from '@/components/icons/Edit05Icon';
import { Link03Icon } from '@/components/icons/Link03Icon';
import { Stars01Icon } from '@/components/icons/Stars01Icon';
import { ActionTile } from '@/components/layout/ActionTile/ActionTile';
import { Card } from '@/components/layout/Card/Card';
import { UnorderedListItem } from '@/components/lists/UnorderedListItem/UnorderedListItem';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useReportError } from '@/hooks/useReportError';
import { getAddeparIntegrationLoginPath } from '@/modules/assetProviderIntegrations/addepar/utils/addeparAuthUtils';
import { getBlackDiamondIntegrationLoginPath } from '@/modules/assetProviderIntegrations/blackDiamond/utils/blackDiamondAuthUtils';
import {
  ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES,
  AssetIntegrationProviders,
} from '@/modules/assetProviderIntegrations/shared/constants';
import { useIsAssetProviderIntegrationEnabled } from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { useAICapabilitiesEnabled } from '@/modules/tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';

import { PRE_OPEN_ONBOARDING_MODAL_PARAM } from './CreateHouseholdFlow';
import {
  CreateHouseholdFlowStep,
  useCreateHouseholdFlowContext,
} from './CreateHouseholdFlow.context';
import { useGetPostClientIntakeAction } from './useGetPostClientIntakeAction';
import { useUserHasCreatedHousehold } from './useUserHasCreatedHousehold';

const TWO_ACTION_TILE_WIDTH = 280;
const THREE_ACTION_TILE_WIDTH = 180;

function Container({ children }: PropsWithChildren) {
  return (
    <Stack
      sx={{ p: 6, pb: 3 }}
      direction="column"
      spacing={3}
      justifyContent="center"
      textAlign="center"
    >
      {children}
    </Stack>
  );
}

function FirstHouseholdHeader() {
  const { hasCreatedHousehold } = useUserHasCreatedHousehold();

  if (hasCreatedHousehold) {
    return (
      <Typography variant="h1">
        How would you like to set up this client?
      </Typography>
    );
  }

  return (
    <Stack direction="column" justifyContent="center" spacing={0.5}>
      <Typography variant="h1">
        Get started by building out your first client
      </Typography>
      <Typography variant="body1">
        Visualize your client&apos;s estate as it is today and model future
        scenarios by setting up entities and beneficiaries in just a few easy
        steps!
      </Typography>
    </Stack>
  );
}

function ExitButton() {
  const { onClose } = useCreateHouseholdFlowContext();

  return (
    <Button variant="transparent" size="sm" onClick={onClose}>
      Exit this guided process
    </Button>
  );
}

export function FlowPicker() {
  const { setNextStep } = useCreateHouseholdFlowContext();
  const { hasConnectedIntegration } = useIsAssetProviderIntegrationEnabled();

  const aiOnboardingIsOn = useFeatureFlag('ai_onboarding');
  const aiCapabilitiesEnabled = useAICapabilitiesEnabled();
  const aiOnboardingEnabled = aiOnboardingIsOn && aiCapabilitiesEnabled;

  const actionTileWidth = aiOnboardingEnabled
    ? THREE_ACTION_TILE_WIDTH
    : TWO_ACTION_TILE_WIDTH;

  return (
    <Container>
      <FirstHouseholdHeader />
      <Stack direction="row" spacing={3}>
        <ActionTile
          containerSx={{
            width: actionTileWidth,
            // We need the !important here because the ActionTile's useStyles
            // classes override the justifyContent property
            justifyContent: 'flex-start!important',
          }}
          icon={
            <Medallion
              size={40}
              icon={<Edit05Icon />}
              colors={{
                background: COLORS.NAVY[500],
                iconColor: undefined,
              }}
            />
          }
          onClick={() => {
            setNextStep(CreateHouseholdFlowStep.ClientDataIntake, false);
          }}
          body="Create manually"
          variant="large"
          dataTestId="CreateHouseholdFlow-FlowPicker-integrationManualCreate"
        />
        {aiOnboardingEnabled && (
          <ActionTile
            containerSx={{
              width: actionTileWidth,
              // We need the !important here because the ActionTile's useStyles
              // classes override the justifyContent property
              justifyContent: 'flex-start!important',
            }}
            icon={
              <Medallion
                size={40}
                icon={<Stars01Icon />}
                colors={{
                  background: COLORS.TEAL[500],
                  iconColor: undefined,
                }}
              />
            }
            onClick={() => {
              setNextStep(
                CreateHouseholdFlowStep.ClientDataIntakeWithAIOnboarding,
                false
              );
            }}
            body="Generate from documents"
            variant="large"
            dataTestId="CreateHouseholdFlow-integrationAICreate"
          />
        )}
        <ActionTile
          containerSx={{
            width: actionTileWidth,
            // We need the !important here because the ActionTile's useStyles
            // classes override the justifyContent property
            justifyContent: 'flex-start!important',
          }}
          icon={
            <Medallion
              size={40}
              icon={<DataIcon />}
              colors={{
                background: COLORS.GRAY[500],
                iconColor: undefined,
              }}
            />
          }
          onClick={() => {
            if (hasConnectedIntegration) {
              setNextStep(CreateHouseholdFlowStep.ClientDataIntake, true);
            } else {
              setNextStep(CreateHouseholdFlowStep.ConnectSelectProvider, true);
            }
          }}
          dataTestId="CreateHouseholdFlow-FlowPicker-integrationCreate"
          body="Import from a 3rd party"
          variant="large"
        />
      </Stack>
      <ExitButton />
    </Container>
  );
}

export function OnboardingNoIntegration() {
  const { setNextStep } = useCreateHouseholdFlowContext();

  const aiOnboardingIsOn = useFeatureFlag('ai_onboarding');
  const aiCapabilitiesEnabled = useAICapabilitiesEnabled();
  const aiOnboardingEnabled = aiOnboardingIsOn && aiCapabilitiesEnabled;

  return (
    <Container>
      <FirstHouseholdHeader />
      {aiOnboardingEnabled ? (
        <Stack direction="row" spacing={3}>
          <ActionTile
            containerSx={{
              width: TWO_ACTION_TILE_WIDTH,
              // We need the !important here because the ActionTile's useStyles
              // classes override the justifyContent property
              justifyContent: 'flex-start!important',
            }}
            icon={
              <Medallion
                size={40}
                icon={<Edit05Icon />}
                colors={{
                  background: COLORS.NAVY[500],
                  iconColor: undefined,
                }}
              />
            }
            onClick={() => {
              setNextStep(CreateHouseholdFlowStep.ClientDataIntake, false);
            }}
            body="Create manually"
            variant="large"
            // NOTE: THIS DOESNT ACTUALLY DO ANYTHING
            data-testid="CreateHouseholdFlow-OnboardingNoIntegration-createClient"
          />
          <ActionTile
            containerSx={{
              width: TWO_ACTION_TILE_WIDTH,
              // We need the !important here because the ActionTile's useStyles
              // classes override the justifyContent property
              justifyContent: 'flex-start!important',
            }}
            icon={
              <Medallion
                size={40}
                icon={<Stars01Icon />}
                colors={{
                  background: COLORS.TEAL[500],
                  iconColor: undefined,
                }}
              />
            }
            onClick={() => {
              setNextStep(
                CreateHouseholdFlowStep.ClientDataIntakeWithAIOnboarding,
                false
              );
            }}
            body="Generate from documents"
            variant="large"
            dataTestId="CreateHouseholdFlow-integrationAICreate"
          />
        </Stack>
      ) : (
        <Button
          variant="primary"
          size="sm"
          fullWidth
          onClick={() => setNextStep(CreateHouseholdFlowStep.ClientDataIntake)}
          data-testid="CreateHouseholdFlow-OnboardingNoIntegration-createClient"
        >
          Create new client
        </Button>
      )}
      <ExitButton />
    </Container>
  );
}

export function PostClientCreation() {
  const { householdId, currentStep } = useCreateHouseholdFlowContext();
  const { navigate } = useNavigateToRoute();
  const { householdIntegrationLink } = useHouseholdDetailsContext();

  const handlePostClientIntakeClick = useGetPostClientIntakeAction(currentStep);
  const { reportError } = useReportError();

  const onClickNextAction = useCallback(() => {
    if (!householdId) {
      reportError(
        'Could not get household ID, returning to household list',
        new Error()
      );
      navigate(ROUTE_KEYS.HOUSEHOLDS_LIST, {});
      return;
    }

    // for prospect households, we skip all onboarding, so a prospect household will never get here right now;
    // that will likely change in the future
    handlePostClientIntakeClick(householdId, householdIntegrationLink, {
      isProspectHousehold: false,
    });
  }, [
    householdId,
    handlePostClientIntakeClick,
    householdIntegrationLink,
    reportError,
    navigate,
  ]);

  return (
    <Container>
      <Stack direction="column" justifyContent="center" spacing={0.5}>
        <Typography variant="h1">New client successfully created</Typography>
        <Typography variant="body1">
          Continue building out your client
        </Typography>
      </Stack>
      <Box sx={{ px: 6, pb: 3 }}>
        <Card variant="filled" sx={{ p: 3 }}>
          <Stack justifyContent="left" textAlign="left">
            <ul>
              <UnorderedListItem emphasizedPart="Visualize your client's estate:">
                Add entities and beneficiaries to understand and visualize your
                client&apos;s estate
              </UnorderedListItem>
              <UnorderedListItem emphasizedPart="Understand estate distributions:">
                Create an estate waterfall to automatically calculate and
                visualize beneficiary and tax outcomes
              </UnorderedListItem>
              <UnorderedListItem emphasizedPart="Model new ideas:">
                Build hypothetical scenarios on top of your waterfall to
                illustrate the outcomes of various planning techniques
              </UnorderedListItem>
            </ul>
          </Stack>
        </Card>
      </Box>
      <Stack spacing={1}>
        <Button variant="primary" size="lg" onClick={onClickNextAction}>
          {!!householdIntegrationLink
            ? 'Proceed with importing entities'
            : 'Proceed with adding new entities'}
        </Button>
        <ExitButton />
      </Stack>
    </Container>
  );
}

export function ConnectSelectProvider() {
  const { setNextStep } = useCreateHouseholdFlowContext();
  const { canConnectToIntegrations } = useIsAssetProviderIntegrationEnabled();
  const options: SelectInputOption[] = canConnectToIntegrations.map(
    (integration) => ({
      display: ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES[integration],
      value: integration,
    })
  );
  const [selectedProvider, setSelectedProvider] = useState<
    AssetIntegrationProviders | ''
  >('');

  const integrationHref = useMemo<string>(() => {
    // get the households list route and add a special query param
    // that forces the onboarding modal open once the connection is completed
    const redirectTarget = `${getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLDS_LIST,
      {}
    )}?${PRE_OPEN_ONBOARDING_MODAL_PARAM}=true`;

    if (selectedProvider === AssetIntegrationProviders.ADDEPAR) {
      return getAddeparIntegrationLoginPath({ redirect: redirectTarget });
    }

    if (selectedProvider === AssetIntegrationProviders.BLACK_DIAMOND) {
      return getBlackDiamondIntegrationLoginPath({ redirect: redirectTarget });
    }

    return '';
  }, [selectedProvider]);

  return (
    <Container>
      <Stack direction="column" justifyContent="center" spacing={0.5}>
        <Typography variant="h1">
          Connect portfolio management provider
        </Typography>
        <Typography variant="body1">
          Connect to a portfolio management provider to enable the auto-update
          of entity valuations
        </Typography>
      </Stack>
      <SelectInput
        label="Provider"
        hideLabel
        value={selectedProvider}
        onChange={(event) => {
          setSelectedProvider(event.target.value as AssetIntegrationProviders);
        }}
        options={options}
        emptyValueDisplay="Select a provider"
        showEmptyValue
        required
        sx={{ textAlign: 'left' }}
      />
      <Button
        variant="primary"
        size="lg"
        rel="noreferrer"
        external
        target="_self"
        startIcon={Link03Icon}
        href={integrationHref}
      >
        Integrate with provider
      </Button>
      <Button
        variant="transparent"
        size="sm"
        onClick={() => setNextStep(CreateHouseholdFlowStep.FlowPicker)}
      >
        Return to previous step
      </Button>
    </Container>
  );
}
