// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganizationSummaryPanel_OrganizationFragment = { __typename?: 'ClientOrganization', id: string, kind?: Types.ClientOrganizationKind | null, name: string, pointOfContactEmail?: string | null, pointOfContactPhone?: string | null, pointOfContactName?: string | null, pointOfContactLastName?: string | null, address?: { __typename?: 'Address', id: string, cityName?: string | null, stateCode: string } | null };

export type OrganizationSummaryPanelQueryVariables = Types.Exact<{
  where: Types.ClientOrganizationWhereInput;
}>;


export type OrganizationSummaryPanelQuery = { __typename?: 'Query', clientOrganizations: { __typename?: 'ClientOrganizationConnection', edges?: Array<{ __typename?: 'ClientOrganizationEdge', node?: { __typename?: 'ClientOrganization', id: string, kind?: Types.ClientOrganizationKind | null, name: string, pointOfContactEmail?: string | null, pointOfContactPhone?: string | null, pointOfContactName?: string | null, pointOfContactLastName?: string | null, address?: { __typename?: 'Address', id: string, cityName?: string | null, stateCode: string } | null } | null } | null> | null } };

export const OrganizationSummaryPanel_OrganizationFragmentDoc = gql`
    fragment OrganizationSummaryPanel_Organization on ClientOrganization {
  id
  kind
  name
  address {
    id
    cityName
    stateCode
  }
  pointOfContactEmail
  pointOfContactPhone
  pointOfContactName
  pointOfContactLastName
}
    `;
export const OrganizationSummaryPanelDocument = gql`
    query OrganizationSummaryPanel($where: ClientOrganizationWhereInput!) {
  clientOrganizations(where: $where) {
    edges {
      node {
        ...OrganizationSummaryPanel_Organization
      }
    }
  }
}
    ${OrganizationSummaryPanel_OrganizationFragmentDoc}`;

/**
 * __useOrganizationSummaryPanelQuery__
 *
 * To run a query within a React component, call `useOrganizationSummaryPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSummaryPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSummaryPanelQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useOrganizationSummaryPanelQuery(baseOptions: Apollo.QueryHookOptions<OrganizationSummaryPanelQuery, OrganizationSummaryPanelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationSummaryPanelQuery, OrganizationSummaryPanelQueryVariables>(OrganizationSummaryPanelDocument, options);
      }
export function useOrganizationSummaryPanelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationSummaryPanelQuery, OrganizationSummaryPanelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationSummaryPanelQuery, OrganizationSummaryPanelQueryVariables>(OrganizationSummaryPanelDocument, options);
        }
export function useOrganizationSummaryPanelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<OrganizationSummaryPanelQuery, OrganizationSummaryPanelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationSummaryPanelQuery, OrganizationSummaryPanelQueryVariables>(OrganizationSummaryPanelDocument, options);
        }
export type OrganizationSummaryPanelQueryHookResult = ReturnType<typeof useOrganizationSummaryPanelQuery>;
export type OrganizationSummaryPanelLazyQueryHookResult = ReturnType<typeof useOrganizationSummaryPanelLazyQuery>;
export type OrganizationSummaryPanelSuspenseQueryHookResult = ReturnType<typeof useOrganizationSummaryPanelSuspenseQuery>;
export type OrganizationSummaryPanelQueryResult = Apollo.QueryResult<OrganizationSummaryPanelQuery, OrganizationSummaryPanelQueryVariables>;
export const Operations = {
  Query: {
    OrganizationSummaryPanel: 'OrganizationSummaryPanel' as const
  },
  Fragment: {
    OrganizationSummaryPanel_Organization: 'OrganizationSummaryPanel_Organization' as const
  }
}