import { QueryHookOptions, QueryResult } from '@apollo/client';
import { Stack, Typography } from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { Button } from '@/components/form/baseInputs/Button';
import { Card } from '@/components/layout/Card/Card';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { DefaultPaginatedTableFooter } from '@/components/tables/DataTable/components/DefaultPaginatedTableFooter';
import { SEARCH_TOOLBAR_STYLES } from '@/components/tables/DataTable/components/SearchSelectionToolbar';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import {
  CompactQueryData,
  ExternalPaginationQueryVariables,
  PaginationQuery,
  PaginationQueryVariables,
  usePaginatedDataTableQuery,
} from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { useGuardedContext } from '@/hooks/useGuardedContext';
import { useReportError } from '@/hooks/useReportError';
import {
  BULK_IMPORT_FLOW_ADDEPAR,
  BulkImportFlowContext,
} from '@/modules/import/BulkImportFlowContext';
import { UnreachableError } from '@/utils/errors';

import {
  getIntegrationClientSelectionVariables,
  mapClientLookupDataToImportClients,
} from '../addepar/BulkImportPage.addepar';
import { useAddeparClientImportLookupQuery } from '../addepar/graphql/AddeparClientImportLookup.generated';
import {
  BULK_IMPORT_STEP_CLIENT_DETAILS,
  BULK_IMPORT_STEP_ENTITY_DETAILS,
} from '../BulkImportPage.constants';
import {
  BulkImportForm,
  BulkImportStepConfig,
  ClientImportSelectionStepHousehold,
} from '../BulkImportPage.types';
import {
  BulkClientImportSelectionStepEmptySet,
  BulkClientImportSelectionStepNoRows,
  BulkClientImportSelectionStepToolbar,
} from './BulkClientImportSelectionStep.components';

export function BulkClientImportSelectionStep(): JSX.Element {
  const { flowType } = useGuardedContext(
    BulkImportFlowContext,
    'BulkImportFlowContextProvider'
  );

  switch (flowType) {
    case BULK_IMPORT_FLOW_ADDEPAR:
      return (
        <BulkClientImportSelectionStepInner
          fetchData={useAddeparClientImportLookupQuery}
          mapDataToTable={mapClientLookupDataToImportClients}
          headerName="Available individuals in Addepar"
          getVariables={getIntegrationClientSelectionVariables}
          tableCaption={
            <>
              <Typography variant="body1">
                The following clients exist in Addepar but have not been linked
                to a Luminary client. If the client does not already exist in
                Luminary, select the individuals below that you would like to
                create as new clients in Luminary.
              </Typography>
              <Callout severity="info-high" textVariant="subtitle2">
                If the client already exists in Luminary, link the client to
                Addepar by navigating to the “Client details” page
              </Callout>
            </>
          }
        />
      );
    default:
      throw new UnreachableError({
        case: flowType,
        message: `Invalid flowType: ${flowType}`,
      });
  }
}

function getColumns(
  headerName: string
): Column<ClientImportSelectionStepHousehold>[] {
  return [
    {
      field: 'displayName',
      renderCell: TextRenderer(),
      headerName,
      flex: 1,
    },
  ];
}

interface BulkClientImportSelectionStepInnerProps<
  TData extends PaginationQuery,
  TVariables extends PaginationQueryVariables,
> {
  fetchData: (
    options: QueryHookOptions<TData, TVariables>
  ) => QueryResult<TData, TVariables>;
  mapDataToTable: (
    queryData: CompactQueryData<TData, TVariables>[]
  ) => ClientImportSelectionStepHousehold[];
  getVariables: (
    queryString: string
  ) => ExternalPaginationQueryVariables<TVariables>;
  headerName: string;
  tableCaption?: JSX.Element;
}

function BulkClientImportSelectionStepInner<
  TData extends PaginationQuery,
  TVariables extends PaginationQueryVariables,
>({
  fetchData,
  mapDataToTable,
  getVariables,
  headerName,
  tableCaption,
}: BulkClientImportSelectionStepInnerProps<TData, TVariables>): JSX.Element {
  const { flowType } = useGuardedContext(
    BulkImportFlowContext,
    'BulkImportFlowContextProvider'
  );
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { setValue, control } = useFormContext<BulkImportForm>();
  const selectedIntegrationClientIds = useWatch<BulkImportForm>({
    control,
    name: 'selectedIntegrationClientIds',
  }) as BulkImportForm['selectedIntegrationClientIds'];
  const [searchString, setSearchString] = useState<string>('');

  const [paginatedTableProps, { data, loading }] = usePaginatedDataTableQuery(
    fetchData,
    {
      variables: getVariables(searchString),
      pageSize: PageSizes.Ten,
      onError: (error) => {
        reportError(`Failed to fetch client list for flow ${flowType}`, error);
        showFeedback(
          'Failed to load importable clients.  Please refresh the page to try again.'
        );
      },
    }
  );

  const rows: ClientImportSelectionStepHousehold[] = mapDataToTable(data);

  const onFilterModelChange = ({ quickFilterValues }: GridFilterModel) => {
    const searchString: string = quickFilterValues?.[0];
    setSearchString(searchString ?? '');
  };

  return (
    <SubpageLayout heading="Step 1: Select individuals available to import">
      <Card variant="outlined" sx={{ p: 3 }}>
        <Stack spacing={3}>
          {rows.length || loading || searchString.length ? (
            <>
              {tableCaption}
              <DataTable
                {...paginatedTableProps}
                sx={SEARCH_TOOLBAR_STYLES}
                checkboxSelection
                keepNonExistentRowsSelected
                disableRowSelectionOnClick={false}
                disableColumnReorder
                disableColumnResize
                rows={rows}
                columns={getColumns(headerName)}
                filterMode="server"
                rowSelectionModel={selectedIntegrationClientIds}
                slots={{
                  footer: DefaultPaginatedTableFooter,
                  toolbar: BulkClientImportSelectionStepToolbar,
                  noRowsOverlay: BulkClientImportSelectionStepNoRows,
                }}
                onFilterModelChange={onFilterModelChange}
                onRowSelectionModelChange={(gridRowIds) => {
                  const remoteHouseholdIDs = gridRowIds.map(
                    (gridRowId) => gridRowId as string
                  );
                  setValue('selectedIntegrationClientIds', remoteHouseholdIDs);
                }}
              />
            </>
          ) : (
            <BulkClientImportSelectionStepEmptySet />
          )}
        </Stack>
      </Card>
    </SubpageLayout>
  );
}

function NextButton() {
  const { setValue, getValues } = useFormContext<BulkImportForm>();
  return (
    <Button
      variant="primary"
      size="sm"
      onClick={() => {
        const selectedIntegrationClientIds = getValues(
          'selectedIntegrationClientIds'
        );
        if (selectedIntegrationClientIds.length > 0) {
          setValue('step', BULK_IMPORT_STEP_CLIENT_DETAILS);
        } else {
          setValue('step', BULK_IMPORT_STEP_ENTITY_DETAILS);
        }
      }}
    >
      Proceed to next step
    </Button>
  );
}

function PreviousButton(): JSX.Element {
  return <></>;
}

export const ClientImportSelectionStepConfig: BulkImportStepConfig = {
  Component: BulkClientImportSelectionStep,
  NextButton,
  PreviousButton,
};
