import { ApolloError } from '@apollo/client';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { sortAlpha } from '@/utils/sortUtils';

import {
  GetTaskAssigneesQuery,
  useGetTaskAssigneesQuery,
} from '../graphql/TaskQueries.generated';
import { UNASSIGNED_TASK_SENTINEL } from '../tasks.constants';

function getAssigneeOptions(data?: GetTaskAssigneesQuery) {
  if (data?.node?.__typename !== 'Household') return [];
  const sortedOptions =
    data.node.possibleTaskAssignees
      .flatMap((assignee) => {
        // make sure the array isn't sparse
        if (!assignee) {
          return [];
        }

        return {
          value: assignee.id,
          display: assignee.displayName,
        };
      })
      .sort((a, b) => sortAlpha(a.display, b.display)) ?? [];

  return [
    {
      value: UNASSIGNED_TASK_SENTINEL,
      display: 'Unassigned',
    },
    ...sortedOptions,
  ];
}

export function useGetTaskAssigneeOptions(
  queryOpts: Parameters<typeof useGetTaskAssigneesQuery>[0]
): [SelectInputOption[], { error?: ApolloError; loading: boolean }] {
  const { data, error, loading } = useGetTaskAssigneesQuery(queryOpts);
  const assigneeOptions = getAssigneeOptions(data);
  return [assigneeOptions, { error, loading }];
}
