import Decimal from 'decimal.js';
import { first } from 'lodash';
import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { getNodes } from '@/utils/graphqlUtils';

import { useGetHypotheticalTransfersQuery } from '../graphql/GetHypotheticalTransfers.generated';

interface GetHypotheticalTransfersInput {
  transferReferenceObjId?: string;
  waterfallId: string;
  sourceTransferReferenceObjId?: string;
  destinationTransferReferenceObjId?: string;
  isIndividual: boolean;
  isEntity: boolean;
}

export function useGetHypotheticalTransfers({
  transferReferenceObjId,
  waterfallId,
  sourceTransferReferenceObjId,
  destinationTransferReferenceObjId,
  isIndividual,
  isEntity,
}: GetHypotheticalTransfersInput) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const hypotheticalTransfersWhere = useMemo(() => {
    if (sourceTransferReferenceObjId) {
      if (isEntity) {
        return {
          hasSourceEntityWith: [
            {
              id: sourceTransferReferenceObjId,
            },
          ],
        };
      }

      if (isIndividual) {
        return {
          hasSourceIndividualWith: [
            {
              id: sourceTransferReferenceObjId,
            },
          ],
        };
      }
    }

    if (destinationTransferReferenceObjId) {
      if (isEntity) {
        return {
          hasDestinationEntityWith: [
            {
              id: destinationTransferReferenceObjId,
            },
          ],
        };
      }

      if (isIndividual) {
        return {
          hasDestinationIndividualWith: [
            {
              id: destinationTransferReferenceObjId,
            },
          ],
        };
      }
    }

    return;
  }, [
    destinationTransferReferenceObjId,
    isEntity,
    isIndividual,
    sourceTransferReferenceObjId,
  ]);

  const queryProps = useGetHypotheticalTransfersQuery({
    variables: {
      where: {
        id: waterfallId,
      },
      hypotheticalTransfersWhere,
    },
    fetchPolicy: 'cache-and-network', // Make sure transfers are represented in the cache
    onError: (err) => {
      showFeedback(
        'Failed to fetch transfers. Please refresh the page and try again.'
      );
      reportError('Failed to fetch transfers', err);
    },
    skip: !transferReferenceObjId,
  });

  const { data } = queryProps;

  const transfers = useMemo(() => {
    const waterfall = getNodes(data?.estateWaterfalls)[0];

    if (!waterfall) {
      return null;
    }
    return getNodes(waterfall.hypotheticalTransfers).sort((a, b) => {
      return a.transferOrder - b.transferOrder;
    });
  }, [data?.estateWaterfalls]);

  const growthRate = useMemo(() => {
    const waterfall = first(getNodes(data?.estateWaterfalls));
    return waterfall?.assetGrowthReturn || new Decimal(0);
  }, [data?.estateWaterfalls]);

  return { ...queryProps, transfers, growthRate };
}
