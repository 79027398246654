import Decimal from 'decimal.js';
import { compact, includes } from 'lodash';

import { NAMESPACE as ASSETS } from '@/modules/assets/AssetsSubform/types';
import { INSURANCE_POLICY_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import {
  BENEFICIARIES_SUBFORM,
  LIFETIME_BENEFICIARIES_SUBFORM,
  REMAINDER_BENEFICIARIES_SUBFORM,
} from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.constants';
import { INSURANCE_POLICIES_DETAILS_SUBFORM } from '@/modules/entities/InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform.types';
import { TaxStatusSubformTypeStateTax } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.types';
import { defaultStateTaxSubformForm } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  AugmentedCreateBeneficiaryInput,
  AugmentedCreateCltTrustInput,
  AugmentedCreateCrtTrustInput,
  AugmentedCreateEntityStateTaxInput,
  AugmentedCreateIlitTrustInput,
  AugmentedCreateInsurancePolicyInput,
  AugmentedCreateIrrevocableTrustInput,
  AugmentedCreatePrincipalInput,
  AugmentedCreateQprtTrustInput,
  AugmentedCreateRevocableTrustInput,
  AugmentedCreateSlatTrustInput,
  AugmentedUpdateAccountInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateIlitTrustInput,
  AugmentedUpdateInsurancePolicyInput,
  AugmentedUpdateIrrevocableTrustInput,
  AugmentedUpdateRevocableTrustInput,
  AugmentedUpdateSlatTrustInput,
  CreateCltTrustInput,
  CreateQprtTrustInput,
  EntityInEstateStatus,
  StateCode,
  TrustTaxStatus,
  UpdateCltTrustInput,
  UpdateCrtTrustInput,
  UpdateEntityInput,
  UpdateGratTrustInput,
  UpdateIlitTrustInput,
  UpdateIrrevocableTrustInput,
  UpdateQprtTrustInput,
  UpdateRevocableTrustInput,
  UpdateSlatTrustInput,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import {
  BASIC_ASSETS_INITIAL_FUNDING_SUBFORM,
  BASIC_ASSETS_SUBFORM as BASIC_ASSETS,
} from '../../../BasicAssetsSubform/BasicAssetsSubform.types';
import {
  BASIC_INFORMATION_SUBFORM_NAMESPACE as BASIC_INFORMATION,
  BASIC_INFORMATION_SUBFORM_NAMESPACE as BASIC_INFORMATION_SUBFORM_NAMESPACE,
  SubformValues as BasicInformationSubformValuesType,
} from '../../../BasicInformationSubform/BasicInformationSubform.types';
import { TAX_STATUS_SUBFORM as TAX_STATUS } from '../../../TaxStatusSubform/TaxStatusSubform.constants';
import {
  SubformValues as TrustDetailsSubformValuesType,
  TRUST_DETAILS_SUBFORM_NAMESPACE as TRUST_DETAILS,
} from '../../../TrustDetailsSubform/TrustDetailsSubform.types';
import {
  PartialSubformsCombinedType,
  SubformsCombinedType,
} from '../../EntitySubforms.types';
import {
  makeAccountInput,
  makeUpdateAccountInput,
  makeUpdateAccountWithAssetsInput,
} from '../shared/accounts.utils';
import {
  getCharitablePrimaryBeneficiary,
  makeCreateBeneficiaryInputs,
} from '../shared/beneficiaries.utils';
import {
  getDescriptionUpdate,
  getTypeOrUndefined,
} from '../shared/common.utils';
import {
  makeCreatePolicyInputs,
  makeUpdatePolicyInputs,
} from '../shared/policies.utils';
import { makeCreatePrincipalInputs } from '../shared/principals.utils';
import {
  entityGstStatusToApiTypeMap,
  estateInclusionStatusToApiTypeMap,
  sharedClearTaxStatusUpdate,
  trustTaxStatusToApiTypeMap,
} from '../shared/taxStatus.utils';
import {
  makeCreateSuccessorTrusteeInputs,
  makeCreateTrustAdvisorInputs,
  makeCreateTrusteeInputs,
} from '../shared/trustees.utils';
import { makeUpdateGratAnnuities } from './grats.utils';

function getValueOrClear<F extends string, V>(
  fieldName: F,
  value: V | undefined
) {
  if (value) {
    return { [fieldName]: value };
  }

  const clearProperty = (() => {
    const upperCasedFieldName =
      fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    return `clear${upperCasedFieldName}`;
  })();

  return {
    [clearProperty]: true,
  };
}

export function makeCreateTrustInput({
  formInput,
  householdId,
  config,
}: {
  formInput: SubformsCombinedType;
  householdId: string;
  config?: {
    entityType: EntityType;
    usesNewFormsUX?: boolean;
  };
}):
  | AugmentedCreateRevocableTrustInput
  | AugmentedCreateIrrevocableTrustInput
  | AugmentedCreateIlitTrustInput
  | AugmentedCreateSlatTrustInput
  | AugmentedCreateQprtTrustInput
  | AugmentedCreateCrtTrustInput
  | AugmentedCreateCltTrustInput {
  const entityType: EntityType | null = config?.entityType ?? null;

  let beneficiariesInput: {
    withBeneficiaries?: AugmentedCreateBeneficiaryInput[];
    withLifetimeBeneficiaries?: AugmentedCreateBeneficiaryInput[];
    withRemainderBeneficiaries?: AugmentedCreateBeneficiaryInput[];
  };

  if (entityType === 'slat') {
    beneficiariesInput = {
      withLifetimeBeneficiaries: makeCreateBeneficiaryInputs(
        formInput[LIFETIME_BENEFICIARIES_SUBFORM]?.lifetimeBeneficiaries ?? [],
        householdId
      ),
      withRemainderBeneficiaries: makeCreateBeneficiaryInputs(
        formInput[REMAINDER_BENEFICIARIES_SUBFORM]?.remainderBeneficiaries ??
          [],
        householdId
      ),
    };
  } else if (entityType === 'clt' || entityType === 'crt') {
    const primaryBeneficiary = getCharitablePrimaryBeneficiary(
      entityType,
      formInput
    );

    beneficiariesInput = {
      withLifetimeBeneficiaries: makeCreateBeneficiaryInputs(
        compact([primaryBeneficiary]),
        householdId
      ),
      withRemainderBeneficiaries: makeCreateBeneficiaryInputs(
        formInput[REMAINDER_BENEFICIARIES_SUBFORM]?.remainderBeneficiaries ??
          [],
        householdId
      ),
    };
  } else {
    beneficiariesInput = {
      withBeneficiaries: makeCreateBeneficiaryInputs(
        formInput[BENEFICIARIES_SUBFORM]?.beneficiaries ?? [],
        householdId
      ),
    };
  }

  let policiesInput: Partial<AugmentedUpdateIlitTrustInput> | undefined;
  let crummeyInput:
    | { crummeyWithdrawalPeriodDays: number | undefined }
    | undefined;
  if (entityType === 'ilit') {
    policiesInput = makeCreatePolicyInputs(
      formInput[INSURANCE_POLICIES_DETAILS_SUBFORM]
    );
    crummeyInput = {
      crummeyWithdrawalPeriodDays:
        formInput[
          INSURANCE_POLICIES_DETAILS_SUBFORM
        ]?.crummeyWithdrawalPeriodDays?.toNumber(),
    };
  }

  let grantorsInput: {
    withGrantors?: AugmentedCreatePrincipalInput[];
    withGrantor?: AugmentedCreatePrincipalInput;
  };

  if (entityType === 'slat') {
    const grantorsFieldInput:
      | BasicInformationSubformValuesType
      | TrustDetailsSubformValuesType =
      formInput[BASIC_INFORMATION_SUBFORM_NAMESPACE];
    grantorsInput = {
      withGrantor: makeCreatePrincipalInputs(grantorsFieldInput) as
        | AugmentedCreatePrincipalInput
        | undefined,
    };
  } else {
    grantorsInput = {
      withGrantors: makeCreatePrincipalInputs(
        formInput[BASIC_INFORMATION_SUBFORM_NAMESPACE]
      ) as AugmentedCreatePrincipalInput[],
    };
  }

  let qprtCreateProperties: Partial<CreateQprtTrustInput> = {};
  if (entityType === 'qprt') {
    qprtCreateProperties = {
      officialInterestRatePercent: getTypeOrUndefined<Decimal>(
        formInput[BASIC_INFORMATION].rate7520
      ),
      termDurationYears: getTypeOrUndefined<number>(
        parseInt(formInput[BASIC_INFORMATION].termLengthNumber)
      ),
      taxableGift: getTypeOrUndefined<Decimal>(
        formInput[BASIC_INFORMATION].taxableGift
      ),
    };
  }

  let charitableTrustCreateProperties: Partial<CreateCltTrustInput> = {};
  if (entityType === 'clt' || entityType === 'crt') {
    charitableTrustCreateProperties = {
      officialInterestRatePercent: getTypeOrUndefined<Decimal>(
        formInput[BASIC_INFORMATION].rate7520
      ),
      termDurationYears: getTypeOrUndefined<number>(
        parseInt(formInput[BASIC_INFORMATION].termLengthNumber)
      ),
      charitableDeduction: getTypeOrUndefined<Decimal>(
        formInput[BASIC_INFORMATION].charitableDeduction
      ),
      termDurationLifetime: formInput[BASIC_INFORMATION].isLifetime,
    };
  }

  if (entityType === 'crt') {
    // CRTs are always non-taxable and out of estate
    charitableTrustCreateProperties.taxStatus = TrustTaxStatus.NonTaxableTrust;
    charitableTrustCreateProperties.inEstateStatus =
      EntityInEstateStatus.OutOfEstate;
  }

  return {
    create: {
      trustJurisdictionStateCode: getTypeOrUndefined<string>(
        formInput[BASIC_INFORMATION].jurisdiction
      ),
      taxStatus: formInput[TAX_STATUS]?.taxStatus
        ? trustTaxStatusToApiTypeMap?.[formInput[TAX_STATUS].taxStatus]
        : undefined,
      legalName: formInput[BASIC_INFORMATION].legalName || undefined,
      inEstateStatus: formInput[TAX_STATUS]?.estateInclusionStatus
        ? estateInclusionStatusToApiTypeMap?.[
            formInput[TAX_STATUS].estateInclusionStatus
          ]
        : undefined,
      gstStatus: formInput[TAX_STATUS]?.gstStatus
        ? entityGstStatusToApiTypeMap?.[formInput[TAX_STATUS].gstStatus]
        : undefined,
      effectiveDate: getTypeOrUndefined<Date>(
        formInput[BASIC_INFORMATION].effectiveDate
      ),
      displayName: formInput[BASIC_INFORMATION].displayName,
      description: getTypeOrUndefined<string>(
        formInput[BASIC_INFORMATION].description
      ),
      ...qprtCreateProperties,
      ...charitableTrustCreateProperties,
      ...crummeyInput,
    },
    ...beneficiariesInput,
    ...policiesInput,
    withTrustees: makeCreateTrusteeInputs(formInput.trustDetailsSubform),
    withSuccessorTrustees: makeCreateSuccessorTrusteeInputs(
      formInput.trustDetailsSubform
    ),
    withTrustAdvisors: makeCreateTrustAdvisorInputs(
      formInput.trustDetailsSubform
    ),
    ...grantorsInput,
    withDesignerAccount: makeAccountInput(formInput),
  };
}

export function makeUpdateTrustInput({
  formInput,
  subtypeId,
  entityType,
  householdId,
}: {
  formInput: PartialSubformsCombinedType;
  subtypeId: string;
  entityType: EntityType;
  householdId: string;
}): AugmentedUpdateTrustInput {
  let update:
    | UpdateRevocableTrustInput
    | UpdateIrrevocableTrustInput
    | UpdateSlatTrustInput
    | UpdateGratTrustInput
    | UpdateCltTrustInput
    | UpdateCrtTrustInput
    | UpdateIlitTrustInput
    | UpdateQprtTrustInput = {
    displayName: formInput[BASIC_INFORMATION]?.displayName ?? '',
    ...getValueOrClear<keyof AugmentedUpdateTrustInput['update'], Date>(
      'effectiveDate',
      getTypeOrUndefined<Date>(formInput[BASIC_INFORMATION]?.effectiveDate)
    ),
    ...getValueOrClear<keyof AugmentedUpdateTrustInput['update'], string>(
      'legalName',
      formInput[BASIC_INFORMATION]?.legalName ?? ''
    ),
    ...getValueOrClear<keyof AugmentedUpdateTrustInput['update'], string>(
      'trustJurisdictionStateCode',
      formInput[BASIC_INFORMATION]?.jurisdiction
    ),
    gstStatus:
      formInput[TAX_STATUS]?.gstStatus &&
      formInput[TAX_STATUS].estateInclusionStatus !== 'in-estate'
        ? entityGstStatusToApiTypeMap?.[formInput[TAX_STATUS].gstStatus]
        : undefined,
    inEstateStatus: formInput[TAX_STATUS]?.estateInclusionStatus
      ? estateInclusionStatusToApiTypeMap?.[
          formInput[TAX_STATUS].estateInclusionStatus
        ]
      : undefined,
    taxStatus: formInput[TAX_STATUS]?.taxStatus
      ? trustTaxStatusToApiTypeMap?.[formInput[TAX_STATUS].taxStatus]
      : undefined,

    // clear all trustee, successor trustee, and trust advisor relationships
    // when updating the trust
    clearTrustees: true,
    clearSuccessorTrustees: true,
    clearTrustAdvisors: true,
    ...getDescriptionUpdate(formInput[BASIC_INFORMATION]?.description),
    ...sharedClearTaxStatusUpdate(formInput),
  };

  let beneficiariesInput: {
    withBeneficiaries?: AugmentedCreateBeneficiaryInput[];
    withLifetimeBeneficiaries?: AugmentedCreateBeneficiaryInput[];
    withRemainderBeneficiaries?: AugmentedCreateBeneficiaryInput[];
  };

  if (entityType === 'slat') {
    update = {
      ...update,
      // clear all beneficiaries when updating the trust
      clearLifetimeBeneficiaries: true,
      clearRemainderBeneficiaries: true,
      // clear all grantors when updating the trust
      clearGrantor: true,
    };
    // make new lifetime and remainder beneficiaries for the slat
    beneficiariesInput = {
      withLifetimeBeneficiaries: makeCreateBeneficiaryInputs(
        formInput[LIFETIME_BENEFICIARIES_SUBFORM]?.lifetimeBeneficiaries ?? [],
        householdId
      ),
      withRemainderBeneficiaries: makeCreateBeneficiaryInputs(
        formInput[REMAINDER_BENEFICIARIES_SUBFORM]?.remainderBeneficiaries ??
          [],
        householdId
      ),
    };
  } else if (entityType === 'clt' || entityType === 'crt') {
    const primaryBeneficiary = getCharitablePrimaryBeneficiary(
      entityType,
      formInput
    );

    update = {
      ...update,
      // clear all beneficiaries when updating the trust
      clearLifetimeBeneficiaries: true,
      clearRemainderBeneficiaries: true,
      // clear all grantors when updating the trust
      clearGrantors: true,
      officialInterestRatePercent: formInput[BASIC_INFORMATION]?.rate7520,
      charitableDeduction: formInput[BASIC_INFORMATION]?.charitableDeduction,
      termDurationLifetime: formInput[BASIC_INFORMATION]?.isLifetime,
      termDurationYears: formInput[BASIC_INFORMATION]?.termLengthNumber
        ? parseInt(formInput[BASIC_INFORMATION].termLengthNumber)
        : null,
    };
    // make new lifetime and remainder beneficiaries for the slat
    beneficiariesInput = {
      withLifetimeBeneficiaries: makeCreateBeneficiaryInputs(
        compact([primaryBeneficiary]),
        householdId
      ),
      withRemainderBeneficiaries: makeCreateBeneficiaryInputs(
        formInput[REMAINDER_BENEFICIARIES_SUBFORM]?.remainderBeneficiaries ??
          [],
        householdId
      ),
    };
  } else if (entityType === 'grat') {
    update = {
      ...update,
      // clear all beneficiaries when updating the trust
      clearBeneficiaries: true,
      // clear all grantors when updating the trust
      clearGrantor: true,
      officialInterestRatePercent: formInput[BASIC_INFORMATION]?.rate7520,
      taxableGift: formInput[BASIC_INFORMATION]?.taxableGift,
      termDurationYears: formInput[BASIC_INFORMATION]?.termLength
        ? parseInt(formInput[BASIC_INFORMATION].termLength)
        : null,
    };
    // make new beneficiaries for the trust
    beneficiariesInput = {
      withBeneficiaries: makeCreateBeneficiaryInputs(
        formInput[BENEFICIARIES_SUBFORM]?.beneficiaries ?? [],
        householdId
      ),
    };
  } else if (entityType === 'qprt') {
    update = {
      ...update,
      // clear all beneficiaries when updating the trust
      clearBeneficiaries: true,
      clearGrantors: true,
      officialInterestRatePercent: formInput[BASIC_INFORMATION]?.rate7520,
      taxableGift: formInput[BASIC_INFORMATION]?.taxableGift,
      termDurationYears: formInput[BASIC_INFORMATION]?.termLengthNumber
        ? parseInt(formInput[BASIC_INFORMATION].termLengthNumber)
        : null,
    };
    // make new beneficiaries for the trust
    beneficiariesInput = {
      withBeneficiaries: makeCreateBeneficiaryInputs(
        formInput[BENEFICIARIES_SUBFORM]?.beneficiaries ?? [],
        householdId
      ),
    };
  } else {
    update = {
      ...update,
      // clear all beneficiaries when updating the trust
      clearBeneficiaries: true,
      // clear all grantors when updating the trust
      clearGrantors: true,
    };
    // make new beneficiaries for the trust
    beneficiariesInput = {
      withBeneficiaries: makeCreateBeneficiaryInputs(
        formInput[BENEFICIARIES_SUBFORM]?.beneficiaries ?? [],
        householdId
      ),
    };
  }

  let grantorsInput: {
    withGrantors?: AugmentedCreatePrincipalInput[];
    withGrantor?: AugmentedCreatePrincipalInput;
  };

  // these two entity types only have one grantor; all the others allow n grantors
  if (entityType === 'slat' || entityType === 'grat') {
    const grantorsFieldInput: BasicInformationSubformValuesType | undefined =
      formInput[BASIC_INFORMATION_SUBFORM_NAMESPACE];
    grantorsInput = {
      withGrantor: grantorsFieldInput
        ? (makeCreatePrincipalInputs(grantorsFieldInput) as
            | AugmentedCreatePrincipalInput
            | undefined)
        : undefined,
    };
  } else {
    const grantorsFieldInput: BasicInformationSubformValuesType | undefined =
      formInput[BASIC_INFORMATION_SUBFORM_NAMESPACE];
    grantorsInput = {
      withGrantors: grantorsFieldInput
        ? (makeCreatePrincipalInputs(
            grantorsFieldInput
          ) as AugmentedCreatePrincipalInput[])
        : [],
    };
  }

  let updateDesignerAccount: AugmentedUpdateAccountInput | null = null;

  if (formInput[BASIC_ASSETS]) {
    updateDesignerAccount = makeUpdateAccountInput(formInput, BASIC_ASSETS);
  } else if (formInput[BASIC_ASSETS_INITIAL_FUNDING_SUBFORM]) {
    updateDesignerAccount = makeUpdateAccountInput(
      formInput,
      BASIC_ASSETS_INITIAL_FUNDING_SUBFORM
    );
  } else if (formInput[ASSETS]) {
    updateDesignerAccount = makeUpdateAccountWithAssetsInput(formInput[ASSETS]);
  }

  // only GRATs have annuities
  const hasAnnuitiesSubform = formInput.annuitiesSubform;
  const gratAnnuities =
    entityType === 'grat' && hasAnnuitiesSubform
      ? makeUpdateGratAnnuities(formInput)
      : {};
  const clearAnnuities =
    entityType === 'grat' && hasAnnuitiesSubform
      ? { clearAnnuities: true }
      : {};

  let updatePolicies: AugmentedUpdateInsurancePolicyInput[] | undefined;
  let withPolicies: AugmentedCreateInsurancePolicyInput[] | undefined;
  let crummeyInput:
    | {
        crummeyWithdrawalPeriodDays?: number | undefined;
        clearCrummeyWithdrawalPeriodDays?: boolean | undefined;
      }
    | undefined;
  if (entityType === 'ilit') {
    crummeyInput = {};
    const crummeyWithdrawalPeriodDays =
      formInput[
        INSURANCE_POLICIES_DETAILS_SUBFORM
      ]?.crummeyWithdrawalPeriodDays?.toNumber();
    if (crummeyWithdrawalPeriodDays) {
      crummeyInput.crummeyWithdrawalPeriodDays = crummeyWithdrawalPeriodDays;
    } else {
      crummeyInput.clearCrummeyWithdrawalPeriodDays = true;
    }

    const {
      updatePolicies: mappedUpdatePolicies,
      withPolicies: mappedWithPolicies,
      removePolicyIDs,
    } = makeUpdatePolicyInputs(formInput[INSURANCE_POLICIES_DETAILS_SUBFORM]);
    if (mappedUpdatePolicies.length) {
      updatePolicies = mappedUpdatePolicies;
    }
    if (mappedWithPolicies.length) {
      withPolicies = mappedWithPolicies;
    }
    (update as UpdateIlitTrustInput).removePolicyIDs = removePolicyIDs;
  }

  if (includes(INSURANCE_POLICY_ENTITY_TYPES, entityType)) {
    const {
      updatePolicies: mappedUpdatePolicies,
      withPolicies: mappedWithPolicies,
      removePolicyIDs,
    } = makeUpdatePolicyInputs(formInput[INSURANCE_POLICIES_DETAILS_SUBFORM]);
    if (mappedUpdatePolicies.length) {
      updatePolicies = mappedUpdatePolicies;
    }
    if (mappedWithPolicies.length) {
      withPolicies = mappedWithPolicies;
    }
    (update as UpdateRevocableTrustInput).removePolicyIDs = removePolicyIDs;
  }

  return {
    id: subtypeId,
    update: {
      ...update,
      ...clearAnnuities,
      ...crummeyInput,
    },
    updateDesignerAccount,
    ...beneficiariesInput,
    withTrustees: formInput[TRUST_DETAILS]
      ? makeCreateTrusteeInputs(formInput[TRUST_DETAILS])
      : [],
    withSuccessorTrustees: formInput[TRUST_DETAILS]
      ? makeCreateSuccessorTrusteeInputs(formInput[TRUST_DETAILS])
      : [],
    withTrustAdvisors: formInput[TRUST_DETAILS]
      ? makeCreateTrustAdvisorInputs(formInput[TRUST_DETAILS])
      : [],

    updatePolicies,
    withPolicies,
    ...gratAnnuities,
    ...grantorsInput,
  };
}

export type AugmentedUpdateTrustInput =
  | AugmentedUpdateRevocableTrustInput
  | AugmentedUpdateIrrevocableTrustInput
  | AugmentedUpdateSlatTrustInput
  | AugmentedUpdateIlitTrustInput;

export function makeEntityTypeUpdateTrustInput(
  entityId: string,
  entityType: EntityType,
  updateEntityInput: UpdateEntityInput,
  updateTrustInput: AugmentedUpdateTrustInput,
  formData: PartialSubformsCombinedType
): AugmentedUpdateEntityInput {
  const sharedProperties = {
    id: entityId,
    withStateTaxes: makeCreateStateTaxesInput(
      formData[TAX_STATUS]?.stateTax ?? defaultStateTaxSubformForm
    ),
    update: {
      ...updateEntityInput,
      clearStateTaxes: true,
    },
  };

  switch (entityType) {
    case 'revocable-trust':
      return {
        ...sharedProperties,
        updateRevocableTrust: updateTrustInput,
      };
    case 'irrevocable-trust':
      return {
        ...sharedProperties,
        updateIrrevocableTrust: updateTrustInput,
      };
    case 'slat':
      return {
        ...sharedProperties,
        updateSlatTrust: updateTrustInput,
      };
    case 'grat':
      return {
        ...sharedProperties,
        updateGratTrust: {
          ...updateTrustInput,
        },
      };
    case 'qprt':
      return {
        ...sharedProperties,
        updateQprtTrust: {
          ...updateTrustInput,
        },
      };
    case 'ilit':
      return {
        ...sharedProperties,
        updateIlitTrust: {
          ...updateTrustInput,
        },
      };
    case 'clt':
      return {
        ...sharedProperties,
        updateCltTrust: {
          ...updateTrustInput,
        },
      };
    case 'crt':
      return {
        ...sharedProperties,
        updateCrtTrust: {
          ...updateTrustInput,
        },
      };
    default:
      throw new UnreachableError({
        case: entityType as never,
        message: 'Unrecognized trust type',
      });
  }
}

export function makeCreateStateTaxesInput(
  stateTaxFormInput: Record<StateCode, TaxStatusSubformTypeStateTax>
): AugmentedCreateEntityStateTaxInput[] {
  return Object.entries(stateTaxFormInput ?? []).flatMap(
    ([stateCode, { inEstateStatus }]) => {
      if (!inEstateStatus) {
        return [];
      }

      return {
        create: {
          stateCode: stateCode as StateCode,
          inEstateStatus,
        },
      };
    }
  );
}
