import { Stack, Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import { COLORS } from '@/styles/tokens/colors';

import { BigBreadcrumbs } from './BigBreadcrumbs';
import { LinkTab, LinkTabProps, Tabs } from './NavigationTabs';

export interface PageHeaderProps {
  heading: React.ReactNode;
  tabs?: LinkTabProps[];
  action?: React.ReactNode;
  secondaryAction?: React.ReactNode;
}

export function PageHeader({
  heading,
  tabs,
  action,
  secondaryAction,
}: PageHeaderProps) {
  const theme = useTheme();
  const hasTabs = tabs?.length;
  return (
    <Box
      component="header"
      px={3}
      bgcolor={theme.palette.common.white}
      borderBottom={1}
      borderColor={COLORS.GRAY[200]}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        // using a CSS media query here, as the useMediaQuery hook doesn't properly respect window.print
        // https://github.com/mui/material-ui/issues/17595
        '@media print': { display: 'none' },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={3}
      >
        <Box>
          {typeof heading === 'string' && (
            <BigBreadcrumbs crumbs={[{ label: heading }]} />
          )}
          {typeof heading !== 'string' && (
            <Typography variant="h1">{heading}</Typography>
          )}
        </Box>

        {action && action}
      </Stack>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        {hasTabs && (
          // relatively positioned 1px below the normal high so the bottom border of the
          // tabs overlaps with the bottom border of the header
          <Box sx={{ position: 'relative', top: 1 }}>
            <Tabs>
              {tabs.map((tab) => (
                <LinkTab key={tab.to} {...tab} />
              ))}
            </Tabs>
          </Box>
        )}
        <Box flex={1} />
        {secondaryAction}
      </Stack>
    </Box>
  );
}
