import {
  SectionLabel,
  SectionLabelProps,
} from '@/components/diagrams/components/SectionLabel';

export const SectionLabelCentered = (props: SectionLabelProps) => {
  return (
    <SectionLabel
      {...props}
      badgeProps={{
        ...props.badgeProps,
        sx: {
          position: 'relative',
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          ...props.badgeProps?.sx,
        },
      }}
    />
  );
};
