import { useApolloClient } from '@apollo/client';
import { FormProvider } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useForm, useSubmitSuccessHandler } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';

import {
  mapFormDataToUpdateInput,
  useSyncLiabilityDataToForm,
} from './EditLiabilityModal.utils';
import {
  useDeleteLiabilityMutation,
  useUpdateLiabilityMutation,
} from './graphql/LiabilityModal.generated';
import { LiabilityForm } from './LiabilityForm';
import { defaultValues, LiabilityFormShape } from './LiabilityModal.constants';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'> & {
  liabilityId: string;
  entityId?: string;
};
export type EditLiabilityModalProps = FormModalOptionalProps;

function EditLiability({
  onClose,
  heading = 'Edit liability',
  liabilityId,
  entityId,
  ...formModalProps
}: FormModalOptionalProps) {
  const apolloClient = useApolloClient();
  const { showFeedback, createSuccessFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { formRef, handleSave } = useFormSaveHandler();
  const trackUserEvent = useTrackUserEvent();
  const { handleSubmit, reset, shouldBlockNavigation, formState } =
    useFormContext<LiabilityFormShape>();
  const { defaultValues } = formState;

  const [deleteMutation, { loading: deleting }] = useDeleteLiabilityMutation({
    variables: { liabilityId },
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError(`Could not delete liability`, error);
    },
    onCompleted: () => {
      closeModal();
      showFeedback('Liability successfully deleted', { variant: 'success' });
      apolloClient.cache.evict({ id: liabilityId });
    },
  });

  const [updateMutation] = useUpdateLiabilityMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError(`Could not edit liability`, error);
    },
    onCompleted: createSuccessFeedback('Successfully updated liability'),
  });

  const onSubmit = handleSubmit((formData) => {
    trackUserEvent('liability updated', {
      includesPaymentData: Boolean(formData.paymentKind),
    });
    return updateMutation({
      variables: {
        input: mapFormDataToUpdateInput(
          // the typing of default values is quite bad
          defaultValues as Readonly<LiabilityFormShape> | undefined,
          formData,
          liabilityId
        ),
      },
    });
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  useSubmitSuccessHandler(() => {
    closeModal();
  });

  return (
    <FormModal
      {...formModalProps}
      onClose={onClose}
      heading={heading}
      shouldBlockClose={shouldBlockNavigation}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.DeleteButton
            onConfirmDelete={() => deleteMutation()}
            loading={deleting}
          />
          <FormModalActions.CancelButton onClick={closeModal} />
          <FormModalActions.SaveButton onClick={handleSave}>
            Save changes
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <LiabilityForm entityId={entityId} />
      </form>
    </FormModal>
  );
}

export const EditLiabilityModal = ({
  liabilityId,
  ...props
}: EditLiabilityModalProps) => {
  const formMethods = useForm<LiabilityFormShape>({ defaultValues });
  useSyncLiabilityDataToForm(formMethods.reset, liabilityId);

  return (
    <FormProvider {...formMethods}>
      <EditLiability {...props} liabilityId={liabilityId} />
    </FormProvider>
  );
};
