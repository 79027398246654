import { useCallback } from 'react';

import { useFlowChartContext } from '../../context/flowChart.context';
import { InternalFlowChartProps } from '../../types';
import { useIsInteractive } from '../useIsInteractive';

/**
 * @description Wrapper around nodes changed callback to keep track of our "dirty state"
 * dirty == is interactive + nodes have been changed
 * pristine == not interactive
 */
export function useInternalOnNodesChange({
  onNodesChange: onNodesChangeProp,
}: InternalFlowChartProps): InternalFlowChartProps['onNodesChange'] {
  const { getIsInteractive } = useIsInteractive();
  const {
    __internal: { setIsDirty },
  } = useFlowChartContext();

  const onNodesChange = useCallback<
    NonNullable<InternalFlowChartProps['onNodesChange']>
  >(
    (...args) => {
      setIsDirty(getIsInteractive());
      onNodesChangeProp?.(...args);
    },
    [getIsInteractive, onNodesChangeProp, setIsDirty]
  );

  return onNodesChange;
}
