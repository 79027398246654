import { Theme } from '@emotion/react';
import { Box, BoxProps, SxProps } from '@mui/material';
import { useMemo } from 'react';

/**
 * @description The purpose of this component is to be an accessible, focusable, and clickable/keyboard-interactable
 * button that is transparent and has no border, so you can use it anywhere that you want a clickable element that doesn't look
 * and feel like a button.
 */
interface TransparentButtonProps extends BoxProps<'button'> {
  sx?: SxProps<Theme>;
}

export function TransparentButton({
  sx = {},
  ...buttonProps
}: TransparentButtonProps) {
  const finalSx = useMemo(() => {
    return {
      padding: 0,
      border: 'none',
      background: 'transparent',
      cursor: 'pointer',
      ...sx,
    };
  }, [sx]);
  return (
    // need to set textAlign to `inherit` because it's centered by default
    // for buttons
    <Box textAlign="inherit" component="button" {...buttonProps} sx={finalSx} />
  );
}
