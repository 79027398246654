import { ComponentType } from 'react';

import { COLORS } from '@/styles/tokens/colors';

import { Button, ButtonProps } from '../form/baseInputs/Button';
import { IconProps } from '../icons/ChevronDownIcon';
import { ActionCard } from '../layout/ActionCard/ActionCard';

export interface EmptyListActionCardProps {
  icon?: ComponentType<IconProps>;
  heading: string;
  description: string;

  // you can either pass buttonOnClick/buttonText or action
  buttonOnClick?: () => void;
  buttonText?: React.ReactNode;
  buttonStartIcon?: ButtonProps['startIcon'];
  action?: React.ReactNode;
}

export function EmptyListActionCard({
  icon: Icon,
  buttonText,
  buttonOnClick,
  buttonStartIcon,
  action,
  ...cardProps
}: EmptyListActionCardProps) {
  return (
    <ActionCard
      variant="noCard"
      icon={Icon && <Icon size={40} sx={{ color: COLORS.GRAY[300] }} />}
      {...cardProps}
      actions={
        action || (
          <Button
            startIcon={buttonStartIcon}
            onClick={() => buttonOnClick?.()}
            variant="primary"
            size="sm"
          >
            {buttonText || 'Add item'}
          </Button>
        )
      }
    />
  );
}
