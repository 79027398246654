import { Stack } from '@mui/material';
import {
  GridRowModel,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import { includes } from 'lodash';
import { useMemo } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { LinkExternal01Icon } from '@/components/icons/LinkExternal01Icon';
import {
  CellContainer,
  PrimaryCellTypography,
} from '@/components/tables/DataTable/components/cells';
import { ActionButtonRenderer } from '@/components/tables/DataTable/renderers/cell/ActionButtonRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { EntityType } from '@/modules/entities/types/EntityType';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { LifetimeExclusionEventKind } from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { ExemptionTrackingTable_LifetimeExclusionEventFragment } from './graphql/ExemptionTrackingTable.generated';

export interface RowData {
  id: string;
  date: Date;
  grantor: string;
  recipient: string;
  totalGiftAmount: Decimal | null;
  gstExemptionApplied: {
    lineOne: Decimal;
    lineTwo: string;
  };
  lifetimeExemptionApplied: {
    lineOne: Decimal;
    lineTwo: string;
  };
  annualGiftExclusionApplied: Decimal | null;
  kind: LifetimeExclusionEventKind;

  // recipientEntityId and recipientEntityType are only present for gifts
  // to entities
  recipientEntityId?: string;
  recipientEntityType?: EntityType;
  householdId: string;

  action?: unknown;
}

function formatExemptionAmount(amount: Decimal | null) {
  if (!amount) {
    return EMPTY_CONTENT_HYPHEN;
  } else {
    return formatCurrencyNoDecimals(amount);
  }
}

export function mapDataToRows(
  lifetimeExclusionEvents: ExemptionTrackingTable_LifetimeExclusionEventFragment[],
  opts: { householdId: string }
): RowData[] {
  return lifetimeExclusionEvents.map((event) => {
    const entityKind = event.recipient?.entity?.kind;
    return {
      id: event.id,
      date: event.eventDate,
      grantor: event.grantor?.displayName ?? EMPTY_CONTENT_HYPHEN,
      recipient: event.recipient?.summary?.displayName ?? EMPTY_CONTENT_HYPHEN,
      totalGiftAmount: event.giftAmount ?? null,
      lifetimeExemptionApplied: {
        lineOne: event.lifetimeExclusionAmount,
        lineTwo:
          event.kind === LifetimeExclusionEventKind.Adjustment
            ? 'Adjustment'
            : '',
      },
      gstExemptionApplied: {
        lineOne: event.gstExclusionAmount,
        lineTwo:
          event.kind === LifetimeExclusionEventKind.Adjustment
            ? 'Adjustment'
            : '',
      },
      annualGiftExclusionApplied: event.annualExclusionAmount ?? null,
      kind: event.kind,
      householdId: opts.householdId,
      recipientEntityId: event.recipient?.entity?.id,
      recipientEntityType: entityKind
        ? getEntityTypeFromEntityKind(entityKind)
        : undefined,
    };
  });
}

export function useColumns({
  handleEdit,
  handleDuplicate,
}: {
  handleEdit: (row: GridRowModel<RowData>) => void;
  handleDuplicate: (row: GridRowModel<RowData>) => void;
}) {
  const GiftRowActionButton = ActionButtonRenderer<GridRowModel<RowData>>({
    actions: [
      {
        label: () => 'Edit',
        handleClick: (row) => {
          handleEdit(row);
        },
      },
      {
        label: (row) =>
          row.kind === LifetimeExclusionEventKind.Gift
            ? 'Duplicate as new gift'
            : 'Duplicate as additional exemption',
        handleClick: (row) => {
          handleDuplicate(row);
        },
      },
    ],
  });

  const columns: Column<RowData>[] = useMemo(
    () => [
      {
        field: 'date',
        headerName: 'Date',
        width: 125,
        renderCell: TextRenderer({
          text: ({ row }) => formatDateToMonDDYYYY(row.date),
        }),
      },
      {
        field: 'grantor',
        headerName: 'Client',
        flex: 1,
      },
      {
        field: 'recipient',
        headerName: 'Recipient',
        flex: 1,
        renderCell: ({ row }) => {
          return (
            <CellContainer>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
                flex={1}
                maxWidth="100%"
              >
                <PrimaryCellTypography>{row.recipient}</PrimaryCellTypography>
                {row.recipientEntityId && (
                  <Button
                    href={getCompletePathFromRouteKey(
                      ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
                      {
                        householdId: row.householdId,
                        entityId: row.recipientEntityId,
                      }
                    )}
                    variant="transparent"
                    size="xs"
                  >
                    <LinkExternal01Icon size={18} />
                  </Button>
                )}
              </Stack>
            </CellContainer>
          );
        },
      },
      {
        field: 'totalGiftAmount',
        headerName: 'Total gift amount',
        width: 150,
        align: 'right',
        renderCell: TextRenderer({
          text: ({ row }) => formatExemptionAmount(row.totalGiftAmount),
        }),
      },
      {
        field: 'lifetimeExemptionApplied',
        headerName: 'Lifetime exemption applied',
        width: 150,
        align: 'right',
        renderCell: TwoLineTextRenderer({
          lineOne: ({ row }) =>
            formatExemptionAmount(row.lifetimeExemptionApplied.lineOne),
          lineTwo: ({ row }) => row.lifetimeExemptionApplied.lineTwo,
        }),
        sortComparator: (
          v1: RowData['lifetimeExemptionApplied'],
          v2: RowData['lifetimeExemptionApplied'],
          p1,
          p2
        ) => {
          return gridStringOrNumberComparator(v1.lineOne, v2.lineOne, p1, p2);
        },
      },
      {
        field: 'gstExemptionApplied',
        headerName: 'GST exemption applied',
        width: 150,
        align: 'right',
        renderCell: TwoLineTextRenderer({
          lineOne: ({ row }) =>
            formatExemptionAmount(row.gstExemptionApplied.lineOne),
          lineTwo: ({ row }) => row.gstExemptionApplied.lineTwo,
        }),
        sortComparator: (
          v1: RowData['gstExemptionApplied'],
          v2: RowData['gstExemptionApplied'],
          p1,
          p2
        ) => {
          return gridStringOrNumberComparator(v1.lineOne, v2.lineOne, p1, p2);
        },
      },
      {
        field: 'annualGiftExclusionApplied',
        headerName: 'Annual exclusion gift applied',
        width: 150,
        align: 'right',
        renderCell: TextRenderer({
          text: ({ row }) =>
            formatExemptionAmount(row.annualGiftExclusionApplied),
        }),
      },
      {
        field: 'kind',
        headerName: '',
        flex: 1,
        align: 'right',
      },
      {
        field: 'action',
        headerName: '',
        align: 'center',
        width: 64,
        minWidth: 64,
        maxWidth: 64,
        renderCell: (renderProps) => {
          // QPRT and GRAT gifts are only allowed to be logged manually and aren't editable through this interface
          if (
            includes(
              [ENTITY_TYPES.QPRT, ENTITY_TYPES.GRAT],
              renderProps.row.recipientEntityType
            )
          ) {
            return <CellContainer />;
          }

          return <GiftRowActionButton {...renderProps} />;
        },
        sortable: false,
      },
    ],
    [GiftRowActionButton]
  );

  return { columns };
}
