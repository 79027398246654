import { Grid } from '@mui/material';

import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useAISuggestionsEnabled } from '@/modules/aiSuggestions/hooks/useAISuggestionsEnabled';
import { SuccessorTrusteeInputRepeater } from '@/modules/entities/TrustDetailsSubform/SuccessorTrusteeInputRepeater';
import { TrustAdvisorInputRepeater } from '@/modules/entities/TrustDetailsSubform/TrustAdvisorInputRepeater';
import { TrusteeInputRepeater } from '@/modules/entities/TrustDetailsSubform/TrusteeInputRepeater';

import { getOptionsFromData as getTrusteeOptionsFromData } from '../inputs/TrusteeDropdown/TrusteeDropdown';
import { useOptionsForTrustDetailsForm } from './graphql/hooks';
import { useTrustDetailsFormOptionsQuery } from './graphql/TrustDetails.generated';
import { useNotes } from './hooks/useNotes';
import { SuccessorTrusteeNotes } from './SuccessorTrusteeNotes';
import { TrustAdvisorNotes } from './TrustAdvisorNotes';
import { Fields, TrustDetailsSubformProps } from './TrustDetailsSubform.types';
import { hasTrustAdvisors } from './TrustDetailsSubform.utils';
import { TrusteeNotes } from './TrusteeNotes';

export function UI({
  variant,
  householdId,
  subformValues,
  entityType,
  filterClientProfileIds = [],
}: TrustDetailsSubformProps) {
  const { control } = useFormContext<Fields>();

  const shouldGroupDuplicates = useAISuggestionsEnabled(entityType);

  const isTrusteesAndSuccessTrusteesOnly =
    variant === 'TrusteesAndSuccessorTrustees';
  const isTrusteesSuccessorTrusteesAndTrustAdvisorsOnly =
    variant === 'TrusteesSuccessorTrusteesAndTrustAdvisors';
  const trusteesWithoutDuties =
    isTrusteesAndSuccessTrusteesOnly ||
    isTrusteesSuccessorTrusteesAndTrustAdvisorsOnly;

  const { data, loading, error } = useTrustDetailsFormOptionsQuery({
    variables: {
      where: {
        id: householdId,
      },
    },
  });

  const possibleTrusteeOptions = getTrusteeOptionsFromData({
    data: data?.households.edges?.[0]?.node ?? null,
    subformValues,
  }).filter((option) => {
    // Don't filter out component options
    if (option.type === 'component') {
      return true;
    }
    // Filter out trustees that can't be selected due to circumstances
    // defined in the parent component, e.g., if the trustee is already
    // dead
    return !filterClientProfileIds.includes(option.value);
  });

  const { trusteeOptions, trustAdvisorOptions, successorTrusteeOptions } =
    useOptionsForTrustDetailsForm({ subformValues, possibleTrusteeOptions });

  // Returns the trust advisor notes component if there are trust advisors
  const trustAdvisorNotes = (() => {
    if (hasTrustAdvisors(subformValues)) {
      return (
        <TrustAdvisorNotes
          control={control}
          subformValues={subformValues}
          trusteeOptions={trusteeOptions}
        />
      );
    }

    return null;
  })();

  const {
    shouldShowTrusteeNotes,
    shouldShowSuccessorTrusteeNotes,
    shouldShowTrustAdvisorNotes,
  } = useNotes({
    subformValues: subformValues || null,
    isTrusteesAndSuccessTrusteesOnly,
  });

  useReportError('failed to fetch trust details form options', error);

  return (
    <FormFieldsDisabledProvider isDisabled={loading}>
      <Grid container spacing={3} columns={12}>
        <Grid item sm={12}>
          <>
            <FormLayoutRow>
              <FormLayoutItem width={12}>
                <TrusteeInputRepeater
                  control={control}
                  householdId={householdId}
                  trusteesWithoutDuties={trusteesWithoutDuties}
                  trustPersonOptions={trusteeOptions}
                  shouldGroupDuplicates={shouldGroupDuplicates}
                  splitScreen
                />
              </FormLayoutItem>
            </FormLayoutRow>
            {shouldShowTrusteeNotes && subformValues && (
              <TrusteeNotes
                control={control}
                subformValues={subformValues}
                trusteeOptions={trusteeOptions}
              />
            )}
            <FormLayoutRow>
              <FormLayoutItem width={12}>
                <SuccessorTrusteeInputRepeater
                  control={control}
                  householdId={householdId}
                  trusteesWithoutDuties={trusteesWithoutDuties}
                  trustPersonOptions={successorTrusteeOptions}
                  shouldGroupDuplicates={shouldGroupDuplicates}
                  splitScreen
                />
              </FormLayoutItem>
            </FormLayoutRow>
            {shouldShowSuccessorTrusteeNotes && subformValues && (
              <SuccessorTrusteeNotes
                control={control}
                subformValues={subformValues}
                trusteeOptions={trusteeOptions}
              />
            )}
            {variant !== 'TrusteesAndSuccessorTrustees' && (
              <FormLayoutRow>
                <FormLayoutItem width={12}>
                  <TrustAdvisorInputRepeater
                    control={control}
                    householdId={householdId}
                    trusteesWithoutDuties={trusteesWithoutDuties}
                    trustPersonOptions={trustAdvisorOptions}
                    shouldGroupDuplicates={shouldGroupDuplicates}
                    splitScreen
                  />
                </FormLayoutItem>
              </FormLayoutRow>
            )}
            {shouldShowTrustAdvisorNotes && trustAdvisorNotes}
          </>
        </Grid>
      </Grid>
    </FormFieldsDisabledProvider>
  );
}
