import { styled } from '@mui/system';
import React, { PropsWithChildren } from 'react';

const JiggleWrapper = styled('div')<{
  animate: boolean;
  numJiggles: number;
  jiggleDurationMs: number;
}>(({ animate, numJiggles, jiggleDurationMs }) => ({
  display: 'flex',
  transformOrigin: '50% 0%', // Rotate around the top center
  animation: animate ? `jiggle ${jiggleDurationMs}ms ${numJiggles}` : 'none',
  '@keyframes jiggle': {
    '0%': { transform: `rotate(0deg)` },
    '25%': { transform: `rotate(15deg)` },
    '75%': { transform: 'rotate(-15deg)' },
    '100%': { transform: 'rotate(0deg)' },
  },
}));

export interface JiggleProps {
  /** Whether to do the jiggle animation */
  animate: boolean;
  /** How many jiggle cycles to do */
  numJiggles?: number;
  /** How long should the jiggle cycle last */
  jiggleDurationMs?: number;
  /** Callback when the jiggle animation ends */
  onAnimationEnd?: () => void;
}

/**
 * Animates the children to move left to right, rotating about the top-center.
 *
 * @param animate
 * @param numJiggles
 * @param jiggleDurationMs
 * @param onAnimationEnd
 * @param children
 * @constructor
 */
export function Jiggle({
  animate,
  numJiggles = 3,
  jiggleDurationMs = 250,
  onAnimationEnd,
  children,
}: PropsWithChildren<JiggleProps>) {
  return (
    <JiggleWrapper
      animate={animate}
      numJiggles={numJiggles}
      jiggleDurationMs={jiggleDurationMs}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
    </JiggleWrapper>
  );
}
