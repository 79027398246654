import { Stack } from '@mui/material';

import { FormAwareMultiSelectInput } from '@/components/form/formAwareInputs/FormAwareMultiSelectInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form/hooks';
import { ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES } from '@/modules/assetProviderIntegrations/shared/constants';
import { useIsAssetProviderIntegrationEnabled } from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { AssetClassLiquidityStatus } from '@/types/schema';

import {
  CreateAssetCategoryFormShape,
  CreateAssetCategoryFormShapePath,
} from './CreateAssetCategoryModal.types';
import { useIntegrationCategoriesWithDisabledState } from './hooks/useIntegrationCategoriesWithDisabledState';

export function CreateAssetCategoryModalForm() {
  const growthRatesEnabled = useFeatureFlag('custom_growth_rates');
  const { integrationsConnected } = useIsAssetProviderIntegrationEnabled();
  const { control } = useFormContext<CreateAssetCategoryFormShape>();
  const { loading, options } = useIntegrationCategoriesWithDisabledState();
  const hasConnectedIntegration = integrationsConnected.length > 0;
  const connectedIntegration = integrationsConnected[0];

  return (
    <Stack>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput
            label="Category name"
            required
            autoFocus
            fieldName={
              `name` as const satisfies CreateAssetCategoryFormShapePath
            }
            control={control}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {hasConnectedIntegration && connectedIntegration && (
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareMultiSelectInput
              disabled={loading}
              label={`Associated categories in ${ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES[connectedIntegration]}`}
              fieldName={
                `associatedIntegrationAssetCategoryIDs` as const satisfies CreateAssetCategoryFormShapePath
              }
              control={control}
              options={options}
              helpText={
                'Integration categories can only be associated with one Luminary asset class. To update them, go to the categories settings page.'
              }
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      <FormLayoutRow sx={{ alignItems: 'end' }}>
        {growthRatesEnabled && (
          <FormLayoutItem width={4}>
            <FormAwarePercentInput
              label="Growth rate"
              isDecimalJSInput
              fieldName={
                `growthRate` as const satisfies CreateAssetCategoryFormShapePath
              }
              control={control}
            />
          </FormLayoutItem>
        )}
        <FormLayoutItem width={8} sx={{ pb: 1 }}>
          <FormAwareRadioGroup
            label="Liquidity status"
            hideLabel
            stackingDirection="row"
            row
            fieldName={
              `liquidityStatus` as const satisfies CreateAssetCategoryFormShapePath
            }
            options={[
              {
                label: 'Liquid',
                value: AssetClassLiquidityStatus.Liquid,
              },
              { label: 'Illiquid', value: AssetClassLiquidityStatus.Illiquid },
            ]}
            control={control}
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </Stack>
  );
}
