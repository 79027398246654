// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EstateWaterfallComparisonModal_EstateWaterfallFragment = { __typename?: 'EstateWaterfall', id: string, firstGrantorDeathYear?: number | null, secondGrantorDeathYear?: number | null, assetGrowthReturn?: Percent | null, exemptionGrowthRate?: Percent | null, willExemptionSunset?: boolean | null, parent?: { __typename?: 'EstateWaterfall', id: string } | null };

export type EstateWaterfallComparisonModalQueryVariables = Types.Exact<{
  where: Types.EstateWaterfallWhereInput;
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type EstateWaterfallComparisonModalQuery = { __typename?: 'Query', estateWaterfalls: { __typename?: 'EstateWaterfallConnection', edges?: Array<{ __typename?: 'EstateWaterfallEdge', node?: { __typename?: 'EstateWaterfall', id: string, firstGrantorDeathYear?: number | null, secondGrantorDeathYear?: number | null, assetGrowthReturn?: Percent | null, exemptionGrowthRate?: Percent | null, willExemptionSunset?: boolean | null, parent?: { __typename?: 'EstateWaterfall', id: string } | null } | null } | null> | null } };

export const EstateWaterfallComparisonModal_EstateWaterfallFragmentDoc = gql`
    fragment EstateWaterfallComparisonModal_EstateWaterfall on EstateWaterfall {
  id
  firstGrantorDeathYear
  secondGrantorDeathYear
  assetGrowthReturn
  exemptionGrowthRate
  willExemptionSunset
  parent {
    id
  }
}
    `;
export const EstateWaterfallComparisonModalDocument = gql`
    query EstateWaterfallComparisonModal($where: EstateWaterfallWhereInput!, $first: Int = 1) {
  estateWaterfalls(where: $where, first: $first) {
    edges {
      node {
        ...EstateWaterfallComparisonModal_EstateWaterfall
      }
    }
  }
}
    ${EstateWaterfallComparisonModal_EstateWaterfallFragmentDoc}`;

/**
 * __useEstateWaterfallComparisonModalQuery__
 *
 * To run a query within a React component, call `useEstateWaterfallComparisonModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstateWaterfallComparisonModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstateWaterfallComparisonModalQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useEstateWaterfallComparisonModalQuery(baseOptions: Apollo.QueryHookOptions<EstateWaterfallComparisonModalQuery, EstateWaterfallComparisonModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EstateWaterfallComparisonModalQuery, EstateWaterfallComparisonModalQueryVariables>(EstateWaterfallComparisonModalDocument, options);
      }
export function useEstateWaterfallComparisonModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstateWaterfallComparisonModalQuery, EstateWaterfallComparisonModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EstateWaterfallComparisonModalQuery, EstateWaterfallComparisonModalQueryVariables>(EstateWaterfallComparisonModalDocument, options);
        }
export function useEstateWaterfallComparisonModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EstateWaterfallComparisonModalQuery, EstateWaterfallComparisonModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EstateWaterfallComparisonModalQuery, EstateWaterfallComparisonModalQueryVariables>(EstateWaterfallComparisonModalDocument, options);
        }
export type EstateWaterfallComparisonModalQueryHookResult = ReturnType<typeof useEstateWaterfallComparisonModalQuery>;
export type EstateWaterfallComparisonModalLazyQueryHookResult = ReturnType<typeof useEstateWaterfallComparisonModalLazyQuery>;
export type EstateWaterfallComparisonModalSuspenseQueryHookResult = ReturnType<typeof useEstateWaterfallComparisonModalSuspenseQuery>;
export type EstateWaterfallComparisonModalQueryResult = Apollo.QueryResult<EstateWaterfallComparisonModalQuery, EstateWaterfallComparisonModalQueryVariables>;
export const Operations = {
  Query: {
    EstateWaterfallComparisonModal: 'EstateWaterfallComparisonModal' as const
  },
  Fragment: {
    EstateWaterfallComparisonModal_EstateWaterfall: 'EstateWaterfallComparisonModal_EstateWaterfall' as const
  }
}