import { Tab } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { GraphVizViewSelectorProps } from '@/modules/graphViz/graphVizView/GraphVizViewSelector';
import { ROUTE_KEYS } from '@/navigation/constants';
import { EntityGraphViewKind } from '@/types/schema';

import { useEntityMapContext } from '../contexts/entityMap.context';
import { EntityGraphNodeAttributes } from '../types';

interface Tab {
  display: string;
  value: EntityGraphNodeAttributes['categorizationType'];
}

interface UseGraphVizViewTabsProps {
  householdId: string;
}

export function useGraphVizViewTabs({ householdId }: UseGraphVizViewTabsProps) {
  const { state } = useEntityMapContext();
  const { navigate } = useNavigateToRoute();

  const tabs = useMemo(() => {
    const tabs: Tab[] = [
      { display: 'Individuals & orgs', value: 'INDIVIDUAL' },
      { display: 'Personal accounts', value: 'PERSONAL_ACCOUNT' },
      { display: 'Family giving', value: 'FAMILY_GIVING' },
      { display: 'Charitable entities', value: 'CHARITABLE_ENTITY' },
    ];

    if (state.entityGraphView.kind === EntityGraphViewKind.Ownership) {
      tabs.push({ display: 'Business entities', value: 'BUSINESS_ENTITY' });
    } else if (state.entityGraphView.kind === EntityGraphViewKind.Beneficiary) {
      tabs.push({
        display: 'Business entities',
        value: 'GRANTOR_BUSINESS_ENTITIES_SUMMARY',
      });
    }

    return tabs;
  }, [state.entityGraphView.kind]);

  const getTabForField = useCallback<
    GraphVizViewSelectorProps<Tab['value']>['getTabForField']
  >(
    (field) => {
      return state.entityGraph.getNodeAttributes(field.node.id)
        .categorizationType;
    },
    [state.entityGraph]
  );

  const navigateToView = useCallback<
    GraphVizViewSelectorProps<Tab['value']>['onSelectView']
  >(
    (view) => {
      navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITY_MAP_DIAGRAM, {
        householdId: householdId,
        id: view.id,
      });
    },
    [householdId, navigate]
  );

  return { tabs, getTabForField, navigateToView };
}
