import { ApolloError } from '@apollo/client';
import Decimal from 'decimal.js';
import { first, intersection } from 'lodash';

import { getTypeOrUndefined } from '@/modules/entities/EntitySubforms/utils/shared/common.utils';
import {
  AugmentedUpdateClientProfileInput,
  AugmentedUpdateCltProposalInput,
  AugmentedUpdateCrtProposalInput,
  AugmentedUpdateProposalInput,
  CharitableTrustCalculationMethod,
  UpdateCltProposalInput,
  UpdateCrtProposalInput,
} from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { mapExistingProposalDataToFrom } from '../CharitableTrustDesignerBasicInformation/CharitableTrustDesignerBasicInformation.utils';
import { CharitableTrustDesignerDataQuery } from '../CharitableTrustDesignerBasicInformation/graphql/CharitableTrustDesignerData.generated';
import { NAMESPACE as PRE_TAX_RETURN_LIMIT_NAMESPACE } from '../PreTaxReturnLimitModal/PreTaxReturnLimitModal.types';
import { mapPreTaxReturnLimitQueryToForm } from '../PreTaxReturnLimitModal/PreTaxReturnLimitModal.utils';
import { DEFAULT_CHARITABLE_TRUST_DESIGNER_ANALYSIS_FORM as DEFAULT_FORM } from './CharitableTrustDesignerAnalysis.constants';
import {
  CharitableTrustDesignerAnalysisDonor,
  CharitableTrustDesignerAnalysisForm,
  NAMESPACE,
} from './CharitableTrustDesignerAnalysis.types';
import { CharitableTrustDesignerAnalysisDataQuery } from './graphql/CharitableTrustDesignerAnalysisData.generated';

export function mapDataToAnalysisForm(
  loadedData: CharitableTrustDesignerAnalysisDataQuery,
  defaultRate7520: Decimal | null,
  preUpdateFormData?: CharitableTrustDesignerAnalysisForm
): CharitableTrustDesignerAnalysisForm {
  const basicInformationForm = mapExistingProposalDataToFrom(loadedData);
  const preTaxProposalModalForm = mapPreTaxReturnLimitQueryToForm(loadedData);

  if (!basicInformationForm || !preTaxProposalModalForm) {
    throw new Error('Could not prepopulate basic information');
  }

  const proposal = first(getNodes(loadedData.proposals));
  if (!proposal) {
    throw new Error('Could not load proposal data');
  }

  const charitableProposal = proposal.cltProposal || proposal.crtProposal;
  if (!charitableProposal) {
    throw new Error('Could not load proposal details');
  }

  const donors = (
    charitableProposal.donors || DEFAULT_FORM[NAMESPACE].term.donors
  ).map<CharitableTrustDesignerAnalysisDonor>((donor) => ({
    id: donor.id,
    dateOfBirth: donor.dateOfBirth || null,
    displayName: donor.displayName || '',
  }));
  const output: CharitableTrustDesignerAnalysisForm = {
    [NAMESPACE]: {
      assets: {
        value:
          charitableProposal.assetValue || DEFAULT_FORM[NAMESPACE].assets.value,
        costBasis:
          charitableProposal.assetCostBasis ||
          DEFAULT_FORM[NAMESPACE].assets.costBasis,
        notes:
          charitableProposal.assetNotes || DEFAULT_FORM[NAMESPACE].assets.notes,
      },
      term: {
        kind: charitableProposal.termKind || DEFAULT_FORM[NAMESPACE].term.kind,
        donors,
        length:
          charitableProposal.termYears || DEFAULT_FORM[NAMESPACE].term.length,
        rate7520: charitableProposal.rate7520 || defaultRate7520,
      },
      payouts: {
        kind:
          charitableProposal.payoutKind || DEFAULT_FORM[NAMESPACE].payouts.kind,
        unitrustKind:
          charitableProposal.unitrustKind ||
          DEFAULT_FORM[NAMESPACE].payouts.unitrustKind,
        calculationMethod:
          charitableProposal.calculationMethod ||
          DEFAULT_FORM[NAMESPACE].payouts.calculationMethod,
        optimizationTarget:
          charitableProposal.optimizationTarget ||
          DEFAULT_FORM[NAMESPACE].payouts.optimizationTarget,
        frequency:
          charitableProposal.payoutFrequency ||
          DEFAULT_FORM[NAMESPACE].payouts.frequency,
        annuityPaymentAmount:
          charitableProposal.annuityPayoutAmount ||
          DEFAULT_FORM[NAMESPACE].payouts.annuityPaymentAmount,
        unitrustPayoutPercent:
          charitableProposal.unitrustPayoutPercent ||
          DEFAULT_FORM[NAMESPACE].payouts.unitrustPayoutPercent,
      },
      analysis: {
        preTaxReturnModel:
          preUpdateFormData?.[NAMESPACE].analysis.preTaxReturnModel || // looking at the prior form data here as this is only ever used on the client
          DEFAULT_FORM[NAMESPACE].analysis.preTaxReturnModel,
        yearOfAnalysis:
          DEFAULT_FORM[NAMESPACE].analysis.yearOfAnalysis +
          (charitableProposal.termYears ||
            DEFAULT_FORM[NAMESPACE].term.length) -
          1,
      },
    },
    ...basicInformationForm,
    ...preTaxProposalModalForm,
  };
  return output;
}

export function mapAnalysisDataToClientProfileUpdatePayload({
  formData,
  loadedData,
}: {
  formData: CharitableTrustDesignerAnalysisForm;
  loadedData: CharitableTrustDesignerDataQuery | undefined;
}): AugmentedUpdateClientProfileInput[] {
  const loadedProposal = first(getNodes(loadedData?.proposals));

  if (!loadedProposal) {
    throw new Error('Tried to update when existing data is missing.');
  }

  return (
    loadedProposal.cltProposal?.donors ||
    loadedProposal?.crtProposal?.donors ||
    []
  ).map<AugmentedUpdateClientProfileInput>((donor) => ({
    id: donor.id,
    update: {
      dateOfBirth: getTypeOrUndefined(
        formData[NAMESPACE].term.donors.find(
          (termDonor) => termDonor.id === donor.id
        )?.dateOfBirth
      ),
    },
  }));
}

export function mapAnalysisDataToMutationPayload({
  formData,
  loadedData,
  isCRT,
}: {
  formData: CharitableTrustDesignerAnalysisForm;
  loadedData: CharitableTrustDesignerDataQuery | undefined;
  isCRT: boolean;
}): AugmentedUpdateProposalInput {
  const loadedProposal = first(getNodes(loadedData?.proposals));

  if (!loadedProposal) {
    throw new Error('Tried to update when existing data is missing.');
  }

  let updateCltProposal: AugmentedUpdateCltProposalInput | undefined =
    undefined;
  let updateCrtProposal: AugmentedUpdateCrtProposalInput | undefined =
    undefined;

  const update: UpdateCltProposalInput | UpdateCrtProposalInput = {
    assetNotes: formData[NAMESPACE].assets.notes,
    assetCostBasis: formData[NAMESPACE].assets.costBasis,
    assetValue: formData[NAMESPACE].assets.value,
    termKind: getTypeOrUndefined(formData[NAMESPACE].term.kind),
    termYears: getTypeOrUndefined(formData[NAMESPACE].term.length),
    payoutFrequency: getTypeOrUndefined(formData[NAMESPACE].payouts.frequency),
    payoutKind: getTypeOrUndefined(formData[NAMESPACE].payouts.kind),
    rate7520: getTypeOrUndefined(formData[NAMESPACE].term.rate7520),
    annuityPayoutAmount: getTypeOrUndefined(
      formData[NAMESPACE].payouts.annuityPaymentAmount
    ),
    unitrustPayoutPercent: getTypeOrUndefined(
      formData[NAMESPACE].payouts.unitrustPayoutPercent
    ),
    unitrustKind: getTypeOrUndefined(formData[NAMESPACE].payouts.unitrustKind),
    calculationMethod: formData[NAMESPACE].payouts.calculationMethod,
    yearOfAnalysis: formData[NAMESPACE].analysis.yearOfAnalysis,
    preTaxReturnPercentageLow: getTypeOrUndefined(
      formData[PRE_TAX_RETURN_LIMIT_NAMESPACE].preTaxReturnPercentageLow
    ),
    preTaxReturnPercentageMedium: getTypeOrUndefined(
      formData[PRE_TAX_RETURN_LIMIT_NAMESPACE].preTaxReturnPercentageMedium
    ),
    preTaxReturnPercentageHigh: getTypeOrUndefined(
      formData[PRE_TAX_RETURN_LIMIT_NAMESPACE].preTaxReturnPercentageHigh
    ),
    taxDragPercentageLow: getTypeOrUndefined(
      formData[PRE_TAX_RETURN_LIMIT_NAMESPACE].taxDragPercentageLow
    ),
    taxDragPercentageMedium: getTypeOrUndefined(
      formData[PRE_TAX_RETURN_LIMIT_NAMESPACE].taxDragPercentageMedium
    ),
    taxDragPercentageHigh: getTypeOrUndefined(
      formData[PRE_TAX_RETURN_LIMIT_NAMESPACE].taxDragPercentageHigh
    ),
  };

  if (
    update.calculationMethod === CharitableTrustCalculationMethod.Calculated
  ) {
    update.optimizationTarget = formData[NAMESPACE].payouts.optimizationTarget;
  } else {
    update.clearOptimizationTarget = true;
  }

  if (isCRT) {
    if (!loadedProposal.crtProposal) {
      throw new Error(
        'Tried to update CRT proposal when existing data is missing'
      );
    }
    updateCrtProposal = {
      id: loadedProposal.crtProposal.id,
      update,
    };
  } else {
    if (!loadedProposal.cltProposal) {
      throw new Error(
        'Tried to update CLT proposal when existing data is missing'
      );
    }
    updateCltProposal = {
      id: loadedProposal.cltProposal.id,
      update,
    };
  }

  const output: AugmentedUpdateProposalInput = {
    id: loadedProposal.id,
    update: {},
    updateCltProposal,
    updateCrtProposal,
  };
  return output;
}

export function isIgnorableProjectionError(error: ApolloError): boolean {
  // get all the error path segments for any bad request errors
  const badRequestErrorPathSegments = Array.from(error.graphQLErrors)
    .filter((graphQLError) => {
      const code = (
        graphQLError.extensions?.['luminary.code'] as
          | { display: string; status_code: number }
          | undefined
      )?.status_code;

      return code === 400;
    })
    .reduce<(string | number)[]>(
      (acc, graphQLError) => acc.concat(graphQLError.path || []),
      []
    );

  return (
    intersection(badRequestErrorPathSegments, [
      'projectionHigh',
      'projectionLow',
      'projectionMedium',
    ]).length > 0
  );
}
