import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import * as diagnostics from '@/utils/diagnostics';

import { ROUTE_KEYS } from './constants';
import { getCompletePathFromRouteKey } from './navigationUtils';

enum RedirectPaths {
  PublicError = 'public-error',
  Proposal = 'proposal',
  Task = 'task',
}

function getNormalizedRedirectPath(
  destination: RedirectPaths,
  params: URLSearchParams
): string {
  // TODO: Add additional validation that the query params are what we expect
  switch (destination) {
    case RedirectPaths.PublicError: {
      return getCompletePathFromRouteKey(ROUTE_KEYS.PUBLIC_ERROR, {
        errorCode: params.get('code') || 'UNKNOWN',
      });
    }
    case RedirectPaths.Proposal: {
      return getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_PROPOSAL_PRESENTATION,
        {
          proposalId: params.get('proposalId')!,
        }
      );
    }
    case RedirectPaths.Task: {
      return getCompletePathFromRouteKey(ROUTE_KEYS.TASK_STANDALONE, {
        taskId: params.get('taskId')!,
      });
    }
    default: {
      return getCompletePathFromRouteKey(ROUTE_KEYS.PUBLIC_ERROR, {
        errorCode: 'UNKNOWN',
      });
    }
  }
}

function getValidatedRedirectPath(destination?: string): RedirectPaths {
  const redirectPathValues = Object.values(RedirectPaths) as string[];
  if (!destination || !redirectPathValues.includes(destination)) {
    diagnostics.error(
      'Invalid redirect path',
      new Error(
        `No specified redirect path found for destination ${destination}; redirecting to ${RedirectPaths.PublicError}`
      )
    );
    return RedirectPaths.PublicError;
  }

  return destination as RedirectPaths;
}

export function FunctionalRedirect() {
  const { destination } = useParams();
  const [searchParams] = useSearchParams();
  const redirectPath = getNormalizedRedirectPath(
    getValidatedRedirectPath(destination),
    searchParams
  );
  return <Navigate to={redirectPath} replace />;
}
