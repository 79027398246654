import { mapValues } from 'lodash';
import { Dispatch, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useEffectReducer as useEffectReducerExternal } from 'use-effect-reducer';

import { useDebugContext } from '@/contexts/debugContext';
import { logger } from '@/utils/reducerUtils';

interface BaseInternalAction {
  type: `@LUMINARY_GLOBAL/${string}`;
}

interface InternalLogAction extends BaseInternalAction {
  type: '@LUMINARY_GLOBAL/dispatchEffect';
  effect: () => void;
}

type InternalAction = InternalLogAction;

/**
 * @description Wraps useEffectReducer with common functionality, mostly around logging currently but likely other common functionality in the future.
 */
export const useEffectReducer: typeof useEffectReducerExternal = (
  reducer,
  initialState,
  effectsMap
) => {
  const { debugLevel } = useDebugContext();

  const debugReducer = useMemo(() => {
    const loggedReducer = logger(reducer);

    const reducerWrapped: typeof reducer = (state, action, queueEffect) => {
      const internalAction = action as unknown as InternalAction;

      if (internalAction.type === '@LUMINARY_GLOBAL/dispatchEffect') {
        queueEffect(() => {
          internalAction.effect();
        });

        return state;
      }

      return loggedReducer(state, action, queueEffect);
    };

    return reducerWrapped;
  }, [reducer]);

  const debugEffectsMap = useMemo(() => {
    return mapValues(effectsMap, (effectFn) => {
      const wrappedEffectFn: typeof effectFn = (state, effect, dispatch) => {
        const internalDispatch =
          dispatch as unknown as Dispatch<InternalAction>;

        internalDispatch({
          type: '@LUMINARY_GLOBAL/dispatchEffect',
          effect: () => {
            /* eslint-disable ban/ban */
            console.groupCollapsed(`Effect: ${effect.type}`);
            console.log(
              '%cEffect:',
              'color: #00A7F7; font-weight: 700;',
              effect
            );
            console.groupEnd();
            /* eslint-enable ban/ban */
          },
        });

        return effectFn(state, effect, dispatch);
      };

      return wrappedEffectFn;
    });
  }, [effectsMap]);

  const finalReducer = debugLevel ? debugReducer : reducer;
  const finalEffectsMap = debugLevel ? debugEffectsMap : effectsMap;

  return useEffectReducerExternal(finalReducer, initialState, finalEffectsMap);
};
