import { FetchResult } from '@apollo/client';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { diagnostics } from '@/utils/diagnostics';

import { DEFAULT_ASSUMPTIONS } from '../../EstateWaterfallAssumptions/constants';
import { useUpdateEstateWaterfallAssumptionsMutation } from '../../EstateWaterfallAssumptions/graphql/EstateWaterfallAssumptions.generated';
import { mapFormDataToInput } from '../CreateEstateWaterfallModal.utils';
import { EstateWaterfallFormProps } from '../EstateWaterfallForm';
import {
  CreateEstateWaterfallVisualizationFilterMutation,
  DeleteEstateWaterfallFilterMutation,
  useCreateEstateWaterfallMutation,
  useCreateEstateWaterfallVisualizationFilterMutation,
  useDuplicateEstateWaterfallMutation,
} from '../graphql/EstateWaterfallModal.generated';
import { EstateWaterfallFormData } from '../types';

interface UseCreateEstateWaterfallInput {
  isTwoGrantor: boolean;
  sourceEstateWaterfalls?: EstateWaterfallFormProps['sourceEstateWaterfalls'];
  isDuplicate?: boolean;
}

export function useCreateEstateWaterfall({
  isTwoGrantor,
  sourceEstateWaterfalls,
}: UseCreateEstateWaterfallInput) {
  const { showFeedback } = useFeedback();
  const { setError } = useFormContext<EstateWaterfallFormData>();

  const [createWaterfallMutation, { data: createEstateWaterfallData }] =
    useCreateEstateWaterfallMutation({
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        diagnostics.error(`Could not create estate waterfall`, error);
      },
    });

  const [createVizFilter] = useCreateEstateWaterfallVisualizationFilterMutation(
    {
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        diagnostics.error(
          `Could not create estate waterfall viz filter`,
          error
        );
      },
    }
  );

  const [updateAssumptions] = useUpdateEstateWaterfallAssumptionsMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      diagnostics.error(
        `Could not reset hypothetical waterfall assumptions`,
        error
      );
    },
  });

  const [duplicateMutation, { data: duplicateEstateWaterfallData }] =
    useDuplicateEstateWaterfallMutation({
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        diagnostics.error(`Could not duplicate estate waterfall`, error);
      },
    });

  const submitFailure = (message: string) => {
    diagnostics.error(message);
    // Sets isSubmitSuccessful to false
    setError('root', {
      type: 'manual',
      message,
    });
    // Sets isSubmitting to false
    return Promise.resolve();
  };

  const createWaterfall: (
    formData: EstateWaterfallFormData
  ) => Promise<
    | void
    | FetchResult<CreateEstateWaterfallVisualizationFilterMutation>
    | FetchResult<DeleteEstateWaterfallFilterMutation>
  > = async (formData) => {
    const shouldCreateFilter = formData.waterfallFilter;
    try {
      let waterfallID: string | undefined;

      // We are duplicating the waterfall if the formData includes a parentID to duplicate
      if (formData.parentID) {
        const makeHypothetical = formData._isHypothetical;
        const { data } = await duplicateMutation({
          variables: {
            input: {
              id: formData.parentID,
              newName: formData.displayName,
              makeHypothetical,
            },
            opts: {
              applyAutoGrouping: formData.shouldAutoGroup,
            },
          },
        });

        waterfallID = data?.duplicateEstateWaterfall.id;

        if (!waterfallID) {
          return submitFailure('Failed to duplicate waterfall, no waterfallID');
        }

        const { includedClientIDs, includedEntityIDs } =
          formData.waterfallFilter ?? {};

        if (makeHypothetical) {
          // explicitly exclude useCustomGlobalGrowthRate, as it's not a property
          // that gets persisted
          const initialAssumptions = {
            firstGrantorDeathYear: DEFAULT_ASSUMPTIONS.firstGrantorDeathYear,
            secondGrantorDeathYear: DEFAULT_ASSUMPTIONS.secondGrantorDeathYear,
            assetGrowthReturn: DEFAULT_ASSUMPTIONS.assetGrowthReturn,
            exemptionGrowthRate: DEFAULT_ASSUMPTIONS.exemptionGrowthRate,
            willExemptionSunset: DEFAULT_ASSUMPTIONS.willExemptionSunset,
          };

          await updateAssumptions({
            variables: {
              input: {
                id: waterfallID,
                update: {
                  ...initialAssumptions,
                },
              },
            },
          });
        }

        if (!shouldCreateFilter) {
          return Promise.resolve();
        }

        return createVizFilter({
          variables: {
            input: {
              create: {
                includedClientIDs,
                includedEntityIDs,
                waterfallID,
              },
            },
          },
        });
      } else {
        const { data } = await createWaterfallMutation({
          variables: {
            input: {
              create: mapFormDataToInput({
                formData,
                isTwoGrantor,
                sourceEstateWaterfalls,
              }),
            },
            opts: {
              applyAutoGrouping: formData.shouldAutoGroup,
            },
          },
        });

        waterfallID = data?.createEstateWaterfall.id;
        if (!waterfallID) {
          return submitFailure('Failed to create waterfall, no waterfallID');
        }
      }

      if (!shouldCreateFilter) {
        return Promise.resolve();
      }

      const { includedClientIDs, includedEntityIDs } =
        formData.waterfallFilter ?? {};

      return createVizFilter({
        variables: {
          input: {
            create: {
              includedClientIDs,
              includedEntityIDs,
              waterfallID,
            },
          },
        },
      });
    } catch (e) {
      return submitFailure('Failed to create waterfall');
    }
  };

  return {
    createWaterfall,
    createEstateWaterfallData,
    duplicateEstateWaterfallData,
  };
}
