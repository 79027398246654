import { Decimal } from 'decimal.js';

import { CurrencyUSD, Percent } from '@/graphql/scalars';
import { Asset } from '@/modules/assets/types/asset';
import { RollingType } from '@/modules/entities/gratTrusts/TermsSubform/types';
import { getTermLengthValue } from '@/modules/entities/gratTrusts/TermsSubform/ui/utils';
import {
  getNormalizedRollingPeriodUpdates,
  parseStringToInteger,
} from '@/modules/entities/gratTrusts/TermsSubform/utils/mutationInputUtils';
import { getInitialTermLengthProperties } from '@/pages/designer/GRATDesigner/StructuringDesigner/TermsForm/getInitialTermLengthProperties';
import {
  AssetV2QsbsEligibility,
  AssetValueV2OwnershipType,
  AugmentedCreateAssetV2Input,
  AugmentedUpdateEntityInput,
  AugmentedUpdateGratTrustInput,
  CreateAssetValueV2Input,
  EntityStage,
} from '@/types/schema';
import { getNullFromEmptyDecimalJS } from '@/utils/decimalJSUtils';
import { getNodes } from '@/utils/graphqlUtils';

import {
  ANNUITY_GROWTH_TYPE_VALUES,
  REMAINDER_TYPE_VALUES,
} from './AnnuityForm/constants';
import { GRATStructuringForm, ReturnProjectionType } from './constants';
import { GratStructuring_GratTrustFragment } from './graphql/StructuringDesigner.generated';

function getCompoundAnnualGrowthRate(
  projectedValue: Decimal,
  initialFundingValue: Decimal,
  termDurationYears: number
) {
  return projectedValue
    .dividedBy(initialFundingValue)
    .pow(new Decimal(1).dividedBy(termDurationYears))
    .minus(new Decimal(1))
    .times(100);
}

interface GetEstimatedRateOfReturnParams
  extends Pick<
    GRATStructuringForm['assets'],
    | 'returnProjectionType'
    | 'projectedRateOfReturn'
    | 'projectedMarketValue'
    | 'projectedSharePrice'
  > {
  termDurationYears: number;
  rollingPeriodYears: number | null;
  initialFundingValue: Decimal;
}

export function getEstimatedRateOfReturn(
  params: GetEstimatedRateOfReturnParams
): Decimal | null {
  if (!params.returnProjectionType) return null;

  switch (params.returnProjectionType) {
    case ReturnProjectionType.RATE_OF_RETURN: {
      if (
        !params.projectedRateOfReturn ||
        params.projectedRateOfReturn.isNaN()
      ) {
        return null;
      }

      return params.projectedRateOfReturn;
    }
    case ReturnProjectionType.PROJECTED_VALUE: {
      if (
        !params.projectedMarketValue ||
        params.projectedMarketValue.isNaN() ||
        !params.termDurationYears
      ) {
        return null;
      }

      return getCompoundAnnualGrowthRate(
        params.projectedMarketValue,
        params.initialFundingValue,
        // NOTE: If we have a rollingPeriodYears set, then the projectedMarketValue
        // is meant to hit at the end of the rolling period years.
        // If no rolling period years, then do it for termDurationYears.
        params.rollingPeriodYears || params.termDurationYears
      );
    }
    default:
      throw new Error(
        `Unsupported returnProjectionType ${params.returnProjectionType}`
      );
  }
}

function getNormalizedProjectionProperties(
  formValues: Pick<
    GRATStructuringForm['assets'],
    | 'projectedMarketValue'
    | 'projectedRateOfReturn'
    | 'projectedSharePrice'
    | 'returnProjectionType'
  >
): Pick<
  AugmentedUpdateGratTrustInput['update'],
  | 'projectedMarketValue'
  | 'projectedRateOfReturn'
  | 'projectedSharePrice'
  | 'clearProjectedMarketValue'
  | 'clearProjectedRateOfReturn'
  | 'clearProjectedSharePrice'
> {
  if (
    formValues.returnProjectionType === ReturnProjectionType.PROJECTED_VALUE
  ) {
    return {
      projectedMarketValue: formValues.projectedMarketValue || null,
      projectedSharePrice: formValues.projectedSharePrice || null,
      clearProjectedRateOfReturn: true,
    };
  }

  return {
    projectedRateOfReturn: new Decimal(formValues.projectedRateOfReturn || '0'),
    clearProjectedMarketValue: true,
    clearProjectedSharePrice: true,
  };
}

function makeCreateAssetValueInput(
  asset: Asset,
  opts = { isUpdate: false }
): CreateAssetValueV2Input {
  switch (asset.valuationMethod) {
    case AssetValueV2OwnershipType.ShareBased:
      return {
        shareCount: asset.shareCount || null,
        shareValue: asset.sharePrice || null,
        ownershipType: asset.valuationMethod,
        ...(opts.isUpdate
          ? {
              clearOwnedValue: true,
              clearTotalValue: true,
              clearOwnedPercent: true,
            }
          : {}),
      };
    case AssetValueV2OwnershipType.ValueBased:
      return {
        ownedValue: asset.marketValue || null,
        ownershipType: asset.valuationMethod,
        ...(opts.isUpdate
          ? {
              clearShareCount: true,
              clearShareValue: true,
              clearTotalValue: true,
              clearOwnedPercent: true,
            }
          : {}),
      };
    case AssetValueV2OwnershipType.PercentBased:
      return {
        totalValue: asset.totalValue || null,
        ownedPercent: asset.ownedPercent || null,
        ownershipType: asset.valuationMethod,
        ...(opts.isUpdate
          ? {
              clearShareCount: true,
              clearShareValue: true,
              clearOwnedValue: true,
            }
          : {}),
      };
    default:
      throw new Error(`Unrecognized valuationMethod ${asset.valuationMethod}`);
  }
}

function makeCreateAssetInput(asset: Asset): AugmentedCreateAssetV2Input {
  return {
    create: {
      classID: asset.categoryId || '',
      displayName: asset.displayName,
      qsbsEligibility:
        asset.qsbsEligibility as unknown as AssetV2QsbsEligibility,
    },
    withAssetValue: {
      create: makeCreateAssetValueInput(asset),
    },
  };
}

/**
 * This function is responsible for taking the values from the form, and massaging them into a shape
 * that's valid for the backend.
 */
export function normalizeValuesForUpdate(
  values: GRATStructuringForm,
  stageToSet: EntityStage | undefined,
  entityId: string,
  subtypeId: string
): AugmentedUpdateEntityInput {
  const input: AugmentedUpdateEntityInput = {
    id: entityId,
    update: {
      stage: stageToSet,
    },
    updateGratTrust: {
      id: subtypeId,
      update: {
        annuityAnnualIncreasePercent:
          values.annuity.growthType === ANNUITY_GROWTH_TYPE_VALUES.FIXED
            ? new Decimal(0)
            : values.annuity.annuityAnnualIncreasePercent,
        officialInterestRatePercent: values.termsSubform.rate7520,

        ...getNormalizedRollingPeriodUpdates({
          type: values.termsSubform.type,
          rollingPeriod: values.termsSubform.rollingPeriod,
        }),

        ...getNormalizedProjectionProperties({
          projectedRateOfReturn: values.assets.projectedRateOfReturn,
          projectedMarketValue: values.assets.projectedMarketValue,
          projectedSharePrice: values.assets.projectedSharePrice,
          returnProjectionType: values.assets.returnProjectionType,
        }),

        termDurationYears: parseStringToInteger(
          getTermLengthValue(
            values.termsSubform.termLength,
            values.termsSubform.termLengthExtended
          )
        ),
        targetTaxableGift: values.annuity.taxableGiftAmount,
        taxableGift: values.annuity.calculatedTaxableGiftAmount,
        clearDesignerAccount: true,
      },
      withDesignerAccount: {
        create: {
          displayName: 'Default account',
        },
        withInitialValuation: {
          create: {
            effectiveDate: new Date(),
          },
          withAssets: values.assets.assets.map(makeCreateAssetInput),
        },
      },
    },
  };

  return input;
}

interface ProjectedValueProperties {
  projectedMarketValue: CurrencyUSD | null;
  projectedRateOfReturn: Percent | null;
  projectedSharePrice: CurrencyUSD | null;
}

function getInitialReturnProjectionProperties(
  strategyInstanceProperties: ProjectedValueProperties
): Pick<
  GRATStructuringForm['assets'],
  | 'projectedMarketValue'
  | 'projectedRateOfReturn'
  | 'projectedSharePrice'
  | 'returnProjectionType'
> {
  if (strategyInstanceProperties.projectedRateOfReturn) {
    return {
      returnProjectionType: ReturnProjectionType.RATE_OF_RETURN,
      projectedRateOfReturn: strategyInstanceProperties.projectedRateOfReturn,
    };
  } else if (
    strategyInstanceProperties.projectedMarketValue ||
    strategyInstanceProperties.projectedSharePrice
  ) {
    return {
      returnProjectionType: ReturnProjectionType.PROJECTED_VALUE,
      projectedMarketValue:
        strategyInstanceProperties.projectedMarketValue || null,
      projectedSharePrice:
        strategyInstanceProperties.projectedSharePrice || null,
    };
  }

  return {
    returnProjectionType: ReturnProjectionType.RATE_OF_RETURN,
    projectedRateOfReturn: null,
    projectedMarketValue: null,
    projectedSharePrice: null,
  };
}

function getDenormalizedAssetsFromStructuringDesignerAccount(
  designerAccount: GratStructuring_GratTrustFragment['designerAccount']
): Asset[] {
  const valuationNode = designerAccount?.initialValuation;
  const assetNodes = getNodes(valuationNode?.assets);
  return assetNodes.map((asset) => ({
    id: asset.id,
    valueId: asset.assetValue.id,
    qsbsEligibility: asset.qsbsEligibility,
    categoryId: asset.class?.id ?? '',
    displayName: asset.displayName,
    valuationMethod: asset.assetValue.ownershipType,
    marketValue: asset.assetValue.ownedValue,
    shareCount: asset.assetValue.shareCount,
    sharePrice: asset.assetValue.shareValue,
    totalValue: asset.assetValue.totalValue,
    ownedPercent: asset.assetValue.ownedPercent,
    doDelete: false,
  }));
}
/**
 * This function is responsible for taking the values provided by the backend, and massaging them into a shape
 * that's valid for the top-level Designer form.
 */
export function generateInitialValues(
  gratTrust: GratStructuring_GratTrustFragment,
  defaultInterestRate: Decimal | null
): GRATStructuringForm {
  const denormalizedAssets =
    getDenormalizedAssetsFromStructuringDesignerAccount(
      gratTrust.designerAccount
    );

  const taxableGift = gratTrust.targetTaxableGift;

  return {
    assets: {
      assets: denormalizedAssets,
      ...getInitialReturnProjectionProperties({
        projectedRateOfReturn: getNullFromEmptyDecimalJS(
          gratTrust.projectedRateOfReturn ?? new Decimal(0)
        ),
        projectedMarketValue: getNullFromEmptyDecimalJS(
          gratTrust.projectedMarketValue ?? new Decimal(0)
        ),
        projectedSharePrice: getNullFromEmptyDecimalJS(
          gratTrust.projectedSharePrice ?? new Decimal(0)
        ),
      }),
    },
    termsSubform: {
      type: gratTrust.rollingPeriodYears
        ? RollingType.ROLLING
        : RollingType.STANDARD,
      rollingPeriod: gratTrust.rollingPeriodYears?.toString() || '',
      ...getInitialTermLengthProperties(gratTrust.termDurationYears || null),
      // if there's a 7520 rate defined on the GRAT itself, that's correct and we should use it.
      // otherwise, we should use the default interest rate (which should be for the current month).
      // finally (and this should never happen) we default to letting people enter their own rate.
      rate7520: getNullFromEmptyDecimalJS(
        gratTrust.officialInterestRatePercent ??
          defaultInterestRate ??
          new Decimal(0)
      ),
    },
    annuity: {
      remainderType:
        taxableGift?.comparedTo(new Decimal(0)) === 0
          ? REMAINDER_TYPE_VALUES.ZEROED_OUT
          : REMAINDER_TYPE_VALUES.TAXABLE_GIFT,
      growthType:
        gratTrust.annuityAnnualIncreasePercent?.comparedTo(new Decimal(0)) === 0
          ? ANNUITY_GROWTH_TYPE_VALUES.FIXED
          : ANNUITY_GROWTH_TYPE_VALUES.INCREASING,
      annuityAnnualIncreasePercent: getNullFromEmptyDecimalJS(
        gratTrust.annuityAnnualIncreasePercent ?? new Decimal(0)
      ),
      grantorRetainedInterest: getNullFromEmptyDecimalJS(
        gratTrust.currentValue.minus(taxableGift ?? new Decimal(0))
      ),
      calculatedTaxableGiftAmount: gratTrust.targetTaxableGift
        ? getNullFromEmptyDecimalJS(
            gratTrust.targetTaxableGift ?? new Decimal(0)
          )
        : null,
      taxableGiftAmount: taxableGift || null,
    },
  };
}
