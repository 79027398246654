import { Box, Stack } from '@mui/material';
import { compact, isUndefined, keyBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useWatch } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { Button } from '@/components/form/baseInputs/Button';
import { iconSizeByButtonSize } from '@/components/form/baseInputs/Button/styles';
import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormConfigurationProvider } from '@/components/form/context/FormConfigurationContext';
import { FormAwareMultiSelectInput } from '@/components/form/formAwareInputs/FormAwareMultiSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormAwareTypeaheadSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';
import { Dataflow01Icon } from '@/components/icons/Dataflow01Icon';
import { Download01Icon } from '@/components/icons/Download01Icon';
import { File06Icon } from '@/components/icons/File06Icon';
import { Card } from '@/components/layout/Card/Card';
import { PopperContent } from '@/components/poppers/PopperContent';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useShowAISuggestionsToggle } from '@/modules/documents/hooks/useShowAISuggestionsToggle';
import { useDocumentDownload } from '@/modules/files/hooks/useFileDownload';
import { useAICapabilitiesEnabled } from '@/modules/tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import { COLORS } from '@/styles/tokens/colors';
import { PathsOf } from '@/types/subform';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { getNodes } from '@/utils/graphqlUtils';

import {
  ADDITIONAL_DOCUMENTS_POPPER_CONTENT,
  AI_ENABLED_FOR_DOCUMENT_HELP_TEXT,
  DOCUMENT_TYPE_OPTIONS,
} from '../documents.constants';
import { SUGGESTION_DOCUMENT_TYPES } from '../documents.constants';
import { documentTypeIsRestricted } from '../documents.utils';
import { DocumentDetails_DocumentFragment } from '../graphql/DocumentDetails.generated';
import { DocumentDetailsFormShape } from './DocumentDetailsForm.types';
import { useSaveDocumentDetailsForm } from './hooks/useSaveDocumentDetailsForm';

type DocumentDetailsFormShapeProps = PathsOf<DocumentDetailsFormShape>;

function DocumentDetailsFormInner({
  document,
  showEntity,
}: DocumentDetailsFormProps) {
  const { control, setValue, formState } =
    useFormContext<DocumentDetailsFormShape>();

  const [selectedEntityId, selectedDocumentKind] = useWatch({
    control,
    name: ['entityId', 'documentKind'],
  });

  const previousSelectedEntityId = usePrevious(selectedEntityId);
  const previousDocumentType = usePrevious(selectedDocumentKind);

  const entities = getNodes(document.household.entities);
  const testamentaryEntities = getNodes(
    document.household.testamentaryEntities
  );

  const entityOptions: SelectInputOption<string>[] = useMemo(() => {
    return entities.map((entity) => {
      return {
        value: entity.id,
        display: entity.subtype.displayName,
      };
    });
  }, [entities]);

  const testamentaryEntityOptions: SelectInputOption<string>[] = useMemo(() => {
    return testamentaryEntities.map((te) => {
      return {
        value: te.id,
        display: te.displayName,
      };
    });
  }, [testamentaryEntities]);

  const associatedEntityOptions = useMemo(() => {
    return [...entityOptions, ...testamentaryEntityOptions].filter(
      ({ value }) => value !== selectedEntityId
    );
  }, [entityOptions, selectedEntityId, testamentaryEntityOptions]);

  const entitiesByID = useMemo(() => keyBy(entities, (e) => e.id), [entities]);

  const aiCapabilitiesEnabled = useAICapabilitiesEnabled();
  const showAISuggestionsToggle = useShowAISuggestionsToggle(
    selectedEntityId,
    entitiesByID
  );

  const enableAiSuggestionsDirty = useMemo(
    () => formState.dirtyFields.enableAiSuggestions,
    [formState]
  );

  useEffect(() => {
    // if AI turned off for tenant, don't update the switch
    if (!aiCapabilitiesEnabled) return;

    if (enableAiSuggestionsDirty) return;

    // if first render, don't update the switch
    if (
      isUndefined(previousDocumentType) ||
      isUndefined(previousSelectedEntityId)
    ) {
      return;
    }

    // if there's no primary entity or document kind selected, don't update the switch
    if (!selectedEntityId || !selectedDocumentKind) return;

    // if the entity changes or document type changes and the new doc type is in the AI-enabled list, turn on the switch automatically
    if (
      previousSelectedEntityId !== selectedEntityId ||
      previousDocumentType !== selectedDocumentKind ||
      SUGGESTION_DOCUMENT_TYPES.includes(selectedDocumentKind)
    ) {
      setValue('enableAiSuggestions', true, { shouldDirty: false });
    }
  }, [
    aiCapabilitiesEnabled,
    enableAiSuggestionsDirty,
    previousDocumentType,
    previousSelectedEntityId,
    selectedDocumentKind,
    selectedEntityId,
    setValue,
  ]);

  return (
    <>
      <Card variant="filled" sx={{ p: 3 }}>
        <Stack spacing={3}>
          {aiCapabilitiesEnabled && (
            <FormAwareTypeaheadSelectInput<DocumentDetailsFormShape>
              startAdornment={
                <Dataflow01Icon size={16} color={COLORS.GRAY[400]} />
              }
              options={entityOptions}
              placeholder="Search entities..."
              control={control}
              label="Parent entity (for AI suggestions)"
              fieldName={
                'entityId' as const satisfies DocumentDetailsFormShapeProps
              }
              disabled={documentTypeIsRestricted(selectedDocumentKind)}
              contextualHelp={
                <PopperContent body="Entity details will be suggested by AI for the entity specified" />
              }
            />
          )}
          <Box sx={{ maxWidth: '100%' }}>
            <FormAwareMultiSelectInput<DocumentDetailsFormShape>
              startAdornment={
                <Dataflow01Icon size={16} color={COLORS.GRAY[400]} />
              }
              options={associatedEntityOptions}
              disabled={documentTypeIsRestricted(selectedDocumentKind)}
              control={control}
              label={
                aiCapabilitiesEnabled
                  ? 'Other entities that share this document'
                  : 'Shared entities'
              }
              fieldName={
                'associatedEntityIds' as const satisfies DocumentDetailsFormShapeProps
              }
              contextualHelp={
                <PopperContent body={ADDITIONAL_DOCUMENTS_POPPER_CONTENT} />
              }
            />
          </Box>
          {!showEntity && (
            <FormAwareSwitch<DocumentDetailsFormShape>
              control={control}
              label="Display as default when editing entity"
              fieldName={
                'isDefaultForEntity' as const satisfies DocumentDetailsFormShapeProps
              }
              labelPosition="right"
            />
          )}
          {showAISuggestionsToggle && (
            <FormAwareSwitch<DocumentDetailsFormShape>
              control={control}
              label="Enable Luminary AI suggestions"
              fieldName={
                'enableAiSuggestions' as const satisfies DocumentDetailsFormShapeProps
              }
              labelPosition="right"
              contextualHelp={
                <PopperContent body={AI_ENABLED_FOR_DOCUMENT_HELP_TEXT} />
              }
            />
          )}
        </Stack>
      </Card>
      <FormAwareTextInput<DocumentDetailsFormShape>
        control={control}
        label="File name"
        required
        fieldName={'fileName' as const satisfies DocumentDetailsFormShapeProps}
      />
      <FormAwareTypeaheadSelectInput<DocumentDetailsFormShape>
        startAdornment={<File06Icon size={16} color={COLORS.GRAY[400]} />}
        options={DOCUMENT_TYPE_OPTIONS}
        disabled={documentTypeIsRestricted(selectedDocumentKind)}
        control={control}
        placeholder="Search document types..."
        label="Type"
        required
        fieldName={
          'documentKind' as const satisfies DocumentDetailsFormShapeProps
        }
      />
    </>
  );
}

interface DocumentDetailsFormProps {
  document: DocumentDetails_DocumentFragment;
  showEntity: boolean;
}

export function DocumentDetailsForm({
  document,
  showEntity,
}: DocumentDetailsFormProps) {
  const [downloadFile, { loading: downloadingDocument }] =
    useDocumentDownload();

  const formMethods = useForm<DocumentDetailsFormShape>({
    defaultValues: {
      _documentId: document.id,
      fileName: document.name,
      documentKind: document.type,
      entityId: document.entity?.id,
      enableAiSuggestions: document?.enableAiSuggestions || false,
      isDefaultForEntity: !!document?.defaultDocumentOfEntity?.id,
      associatedEntityIds: [
        ...compact(document.additionalEntities).map(
          (additionalDoc) => additionalDoc.id
        ),
        ...compact(document.additionalTestamentaryEntities).map(
          (additionalDoc) => additionalDoc.id
        ),
      ],
    },
  });

  const {
    handleSubmit,
    formState: { isDirty },
  } = formMethods;

  const { updateDocument, loading, validateForm } =
    useSaveDocumentDetailsForm();

  const trackUserEvent = useTrackUserEvent();

  const handleValidSubmit: SubmitHandler<DocumentDetailsFormShape> = (form) => {
    // track only if the entity that will use this document for data extraction changes
    if (form.entityId !== document.entity?.id) {
      trackUserEvent('document data extraction enabled', {
        entityId: form.entityId,
      });
    }
    return updateDocument(validateForm(form));
  };

  const onSubmit = handleSubmit(handleValidSubmit);

  const rows = useMemo(() => {
    const valueProps = {
      textAlign: 'right',
    } as const;

    return [
      {
        label: 'Uploaded date',
        value: formatDateToMonDDYYYY(document.createdAt),
        valueProps,
      },
      {
        label: 'Created by',
        value: document.user.displayName,
        valueProps,
      },
    ];
  }, [document.createdAt, document.user.displayName]);

  return (
    <FormConfigurationProvider
      value={{ optionalDisplayType: 'required-asterisk' }}
    >
      <FormProvider {...formMethods}>
        <Stack spacing={3} component="form" onSubmit={onSubmit}>
          <DocumentDetailsFormInner
            document={document}
            showEntity={showEntity}
          />
        </Stack>
      </FormProvider>
      <MiniTable variant="default" rows={rows} />
      <Stack direction="row" justifyContent="space-between">
        <Button
          size="sm"
          variant="primary"
          onClick={onSubmit}
          loading={loading}
          disabled={!isDirty}
        >
          Save changes
        </Button>
        <Tooltip title="Download document">
          <Button
            size="sm"
            square
            variant="secondary"
            loading={downloadingDocument}
            onClick={() => downloadFile(document.id)}
          >
            <Download01Icon size={iconSizeByButtonSize.sm} />
          </Button>
        </Tooltip>
      </Stack>
    </FormConfigurationProvider>
  );
}
