import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface UseActiveTabProps<T> {
  defaultTab: T;
  filterSearchParam?: string;
}

/**
 * @description Hook to manage active tab state using search params
 */
export function useActiveTab<T extends string>({
  defaultTab,
  filterSearchParam = 'tab',
}: UseActiveTabProps<T>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabSearchParam = searchParams.get(filterSearchParam) as T | null;
  const activeTab = tabSearchParam || defaultTab;

  const setActiveTab = useCallback(
    (value: T) => {
      setSearchParams(
        (prevParams) => {
          prevParams.set(filterSearchParam, value);

          return prevParams;
        },
        {
          replace: true,
        }
      );
    },
    [filterSearchParam, setSearchParams]
  );

  return {
    activeTab,
    setActiveTab,
  };
}
