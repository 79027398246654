import { compact } from 'lodash';
import { useMemo } from 'react';

import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { EntityKind } from '@/types/schema';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { PersonalAccountSummaryData } from './PersonalAccountDetails.types';

export function PersonalAccountDetailsMiniTable({
  taxStatus,
  entityKind,
  ownerNames,
  effectiveDate,
  locationDisplay,
}: PersonalAccountSummaryData) {
  const shouldShowEffectiveDate =
    entityKind === EntityKind.IndividualPersonalAccount ||
    entityKind === EntityKind.JointPersonalAccount ||
    entityKind === EntityKind.CustodialPersonalAccount ||
    entityKind === EntityKind.QualifiedTuitionPersonalAccount;

  const rows = useMemo(() => {
    return compact([
      locationDisplay && {
        label: 'Asset location',
        value: locationDisplay,
      },
      ownerNames && {
        label: 'Owner(s)',
        value: ownerNames.join(', '),
      },
      taxStatus && {
        label: 'Tax status',
        value: taxStatus.value ?? '',
      },
      shouldShowEffectiveDate && {
        label: 'Effective Date',
        value: effectiveDate
          ? formatDateToMonDDYYYY(effectiveDate)
          : EMPTY_CONTENT_HYPHEN,
      },
    ]);
  }, [
    effectiveDate,
    locationDisplay,
    ownerNames,
    shouldShowEffectiveDate,
    taxStatus,
  ]);

  return <MiniTable variant="default" rows={rows} />;
}
