import { Box, Stack, Typography } from '@mui/material';
import { compact, isEmpty } from 'lodash';
import { ReactNode, useEffect } from 'react';

import { FileShield02Icon } from '@/components/icons/FileShield02Icon';
import { RibbonCard } from '@/components/layout/RibbonCard';
import { EmptyListActionCard } from '@/components/lists/EmptyListActionCard';
import { useMatchingRoutes } from '@/hooks/useMatchingRoutes';
import { EntityDetail_InsurancePolicyFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { EditEntitySection } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { EditEntitySplitScreenOpenModalButton } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenOpenModalButton';
import { DocumentRepresentationList } from '@/modules/files/DocumentRepresentation/DocumentRepresentationList';
import { ROUTE_KEYS } from '@/navigation/constants';
import { COLORS } from '@/styles/tokens/colors';

import { mapDataToPolicy } from './TrustPoliciesTab.utils';

export interface Policy {
  policyName: ReactNode;
  policyType: ReactNode;
  insured: ReactNode;
  deathBenefitAmount: ReactNode;
  effectiveDate: ReactNode;
  premiumAmount: ReactNode;
  nextPremiumDate: ReactNode;
  convertability: ReactNode;
  cashValue: ReactNode;
  loanBalanceOutstanding: ReactNode;
  notes: ReactNode;
  documentIds: string[];
}

interface TrustPolicyTabProps {
  policy: Policy;
}

function TrustPolicyTabBody({ policy }: TrustPolicyTabProps) {
  const {
    policyName,
    policyType,
    insured,
    deathBenefitAmount,
    effectiveDate,
    premiumAmount,
    nextPremiumDate,
    convertability,
    cashValue,
    loanBalanceOutstanding,
    notes,
  } = policy;
  return (
    <Stack
      direction="row"
      width="100%"
      pb={3}
      flexWrap="wrap"
      spacing={2}
      useFlexGap
    >
      <Stack direction="column" spacing={3} flex="1 1 45%" minWidth="200px">
        {policyName}
        {policyType}
        {insured}
        {deathBenefitAmount}
        {effectiveDate}
      </Stack>
      <Stack direction="column" spacing={3} flex="1 1 45%" minWidth="200px">
        {premiumAmount}
        {nextPremiumDate}
        {convertability}
        {cashValue}
        {loanBalanceOutstanding}
        {notes}
      </Stack>
    </Stack>
  );
}

function TrustPolicyTab({ policy }: TrustPolicyTabProps) {
  const { documentIds } = policy;
  return (
    <RibbonCard
      variant="vertical"
      ribbonColor={COLORS.BLUE[400]}
      icon={<FileShield02Icon size={24} />}
      cardVariant="filled"
      heading="Life"
    >
      <TrustPolicyTabBody policy={policy} />
      <DocumentRepresentationList documentIds={documentIds} />
    </RibbonCard>
  );
}

export interface TrustPoliciesTabProps {
  insurancePolicies?: EntityDetail_InsurancePolicyFragment[] | null;
  onShowEditEntity?: () => void;
}

export function TrustPoliciesTabPrint({
  insurancePolicies,
}: TrustPoliciesTabProps) {
  const policies: Policy[] = compact<Policy>(
    (insurancePolicies || []).map(mapDataToPolicy)
  );

  return (
    <Stack spacing={4}>
      {policies.map((policy, index) => (
        <Stack direction="column" key={`policy-${index}`}>
          <Typography
            variant="caption"
            sx={{
              fontWeight: 'bold',
              background: COLORS.GRAY[100],
              p: 1,
              mb: 2,
            }}
          >
            Policy Details
          </Typography>
          <Box sx={{ p: 1 }}>
            <TrustPolicyTabBody key={`policy-${index}`} policy={policy} />
          </Box>
        </Stack>
      ))}
    </Stack>
  );
}

export function TrustPoliciesTab({
  insurancePolicies: insurancePolicies,
  onShowEditEntity,
}: TrustPoliciesTabProps) {
  // This empty ActionCard is used on the EntityDetailsPage, and the WaterfallPage SummaryPanel.
  // If we're on the waterfall page and using the new EditEntitySplitScreen modal,
  // we don't want to navigate after saving an entity section.
  const { isMatchingRoute: isOnEntityDetailsPage } = useMatchingRoutes([
    ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
  ]);

  const policies: Policy[] = compact<Policy>(
    (insurancePolicies || []).map(mapDataToPolicy)
  );

  useEffect(() => {
    if (!isEmpty(policies)) {
      onShowEditEntity?.();
    }
  }, [onShowEditEntity, policies]);

  return (
    <Stack spacing={4}>
      {policies.length ? (
        policies.map((policy, index) => (
          <TrustPolicyTab key={`policy-${index}`} policy={policy} />
        ))
      ) : (
        <EmptyListActionCard
          heading="Specify insurance policies"
          description="No insurance policies have been specified for this entity"
          icon={FileShield02Icon}
          action={
            <EditEntitySplitScreenOpenModalButton
              initialSection={EditEntitySection.INSURANCE_POLICIES}
              startIcon={FileShield02Icon}
              buttonText="Add a policy"
              size="sm"
              variant="primary"
              navigateAfterSave={isOnEntityDetailsPage}
            />
          }
        />
      )}
    </Stack>
  );
}
