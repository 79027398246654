import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import {
  $findMatchingParent,
  $getNearestNodeOfType,
  mergeRegister,
} from '@lexical/utils';
import { Stack } from '@mui/material';
import {
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import {
  Button as LuminaryButton,
  ButtonProps,
} from '@/components/form/baseInputs/Button';
import { Bold01Icon } from '@/components/icons/Bold01Icon';
import { Italic01Icon } from '@/components/icons/Italic01Icon';
import { Strikethrough01Icon } from '@/components/icons/Strikethrough01Icon';
import { Underline01Icon } from '@/components/icons/Underline01Icon';
import { COLORS } from '@/styles/tokens/colors';

import { TextEditorKind } from '../../TextEditor';
import { BlockOptionsDropdown } from './BlockOptionsDropdown';
import { Block, iconProps } from './ToolbarPlugin.utils';

const Button = ({
  children,
  isActive,
  ...props
}: PropsWithChildren<Partial<ButtonProps> & { isActive: boolean }>) => {
  return (
    <LuminaryButton
      size="md"
      variant="secondary"
      sx={{
        padding: '10px',
        bgcolor: isActive ? COLORS.GRAY[300] : COLORS.PRIMITIVES.WHITE,
      }}
      {...props}
    >
      {children}
    </LuminaryButton>
  );
};

const LowPriority = 1;

interface ToolbarPluginProps {
  textEditorKind: TextEditorKind;
}

export function ToolbarPlugin({ textEditorKind }: ToolbarPluginProps) {
  const [editor] = useLexicalComposerContext();
  const [blockType, setBlockType] = useState<Block>('paragraph');
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const isMarkdown = textEditorKind === 'markdown';

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? (element.getTag() as Block)
            : (element.getType() as Block);
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsUnderline(selection.hasFormat('underline'));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload) => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  return (
    <Stack
      p={2}
      bgcolor={COLORS.GRAY[200]}
      direction="row"
      justifyContent="space-between"
      sx={{
        borderRadius: '6px',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      <BlockOptionsDropdown editor={editor} blockType={blockType} />
      <Stack direction="row" spacing={1}>
        <Button
          isActive={isBold}
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          }}
        >
          <Bold01Icon {...iconProps} />
        </Button>
        <Button
          isActive={isItalic}
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
          }}
        >
          <Italic01Icon {...iconProps} />
        </Button>
        {!isMarkdown && (
          <Button
            isActive={isUnderline}
            onClick={() => {
              editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
            }}
          >
            <Underline01Icon {...iconProps} />
          </Button>
        )}
        <Button
          isActive={isStrikethrough}
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
          }}
        >
          <Strikethrough01Icon {...iconProps} />
        </Button>
      </Stack>
    </Stack>
  );
}
