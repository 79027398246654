import {
  EntityMapActionFn,
  ReactFlowNodeMeasurementEventAction,
} from '../types';

export const reactFlowNodeMeasurementEventAction: EntityMapActionFn<
  ReactFlowNodeMeasurementEventAction
> = (state, _action, _queueEffect) => {
  return state;
};
