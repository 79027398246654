import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { getSearchParamsFromAssumptions } from '../../EstateWaterfallBreadcrumbNavigation/EstateWaterfallBreadcrumbNavigation.utils';
import { WATERFALL_COMPARISON_SEARCH_PARAM } from '../../EstateWaterfallBreadcrumbNavigation/hooks/useWaterfallComparisonSearchParams';
import { useDownloadComparisonMutation } from '../../EstateWaterfallComparisonTrowserLegacy/graphql/DownloadComparison.generated';
import { useEstateWaterfallComparisonTrowserContext } from '../EstateWaterfallComparisonTrowser.context';
import { EstateWaterfallComparisonTrowserFormShape } from '../EstateWaterfallComparisonTrowser.type';

export function useDownload() {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const { waterfallIds } = useEstateWaterfallComparisonTrowserContext();
  const { householdId } = useHouseholdDetailsContext();

  const { control } =
    useFormContext<EstateWaterfallComparisonTrowserFormShape>();

  const [legacyAssumptions, comparisonState] = useWatch({
    control,
    name: ['legacyAssumptions', 'comparisonState'],
  });

  const pathToPrint = useMemo(() => {
    if (!householdId) return '';
    const blankSearchParams = new URLSearchParams();
    const searchParams = getSearchParamsFromAssumptions(
      waterfallIds,
      legacyAssumptions,
      blankSearchParams
    );

    if (comparisonState) {
      const encoded = Buffer.from(JSON.stringify(comparisonState)).toString(
        'base64'
      );
      searchParams.set(WATERFALL_COMPARISON_SEARCH_PARAM, encoded);
    }

    const pathToPrint = getCompletePathFromRouteKey(
      ROUTE_KEYS.__PRINT_ONLY_WATERFALL_COMPARISON,
      { householdId },
      searchParams
    ).slice(1);
    // getCompletePathFromRouteKey returns a root-relative path, but the endpoint wants
    // a root-relative path that _doesn't_ include the leading slash, so it needs be trimmed

    return pathToPrint;
  }, [householdId, waterfallIds, legacyAssumptions, comparisonState]);

  return useDownloadComparisonMutation({
    variables: {
      householdId: householdId ?? '',
      pathToPrint,
    },
    onError: (err) => {
      reportError('Failed to print presentation', err);
      showFeedback(`We weren't able to generate the PDF. Please try again.`);
    },
  });
}
