import { gridStringOrNumberComparator } from '@mui/x-data-grid-pro';
import { compact } from 'lodash';
import { useMemo } from 'react';

import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';

import { RowData } from './AdminUsersPage.types';
import { EmployeeFragment } from './graphql/AdminUsers.generated';

export function mapDataToRows(
  employees: EmployeeFragment[]
): Omit<RowData, 'icon'>[] {
  return employees.map((employee) => {
    const clientNames = compact(
      employee.assignedHouseholds?.map((client) => client.displayName) ?? []
    );

    const roleNames = employee.roles?.map((role) => role.displayName) ?? [];

    return {
      id: employee.id ?? '',
      user: {
        lineOne: employee.displayName ?? '',
        lineTwo: employee.user.email ?? '',
      },
      clientNames: clientNames.join(', '),
      roleNames: roleNames.join(', '),
    };
  });
}

export const useUserTableColumns = () => {
  return useMemo(() => {
    const columns: Column<RowData>[] = [
      {
        field: 'user',
        headerName: 'User',
        flex: 1,
        renderCell: TwoLineTextRenderer({
          lineOneProps: {
            variant: 'h5',
          },
        }),
        sortComparator: (v1: RowData['user'], v2: RowData['user'], p1, p2) => {
          return gridStringOrNumberComparator(v1.lineOne, v2.lineOne, p1, p2);
        },
      },
      {
        field: 'roleNames',
        headerName: 'Roles',
        flex: 1,
        renderCell: TextRenderer(),
      },
      {
        field: 'clientNames',
        headerName: 'Client access',
        flex: 1,
        renderCell: TextRenderer(),
      },
      {
        field: 'icon',
        align: 'center',
        renderCell: IconRenderer({
          icon: ChevronRightIcon,
        }),
        sortable: false,
        width: 64,
        minWidth: 64,
        maxWidth: 64,
      },
    ];

    return columns;
  }, []);
};
