import Decimal from 'decimal.js';

import { formatCurrency } from '@/utils/formatting/currency';

export function formatTaxForCumulativeTaxTable(tax: Decimal) {
  return formatCurrency(tax.abs().times(-1), {
    currencySign: 'accounting',
    maximumFractionDigits: 0,
  });
}
