import { SubForm } from '@/types/subform';

import { EntityType } from '../types/EntityType';
import {
  BASIC_ASSETS_SUBFORM,
  BasicAssetsSubformType,
  defaultValues,
  Fields,
  NamespaceType,
  Props,
  VariantType,
} from './BasicAssetsSubform.types';
import { UI } from './BasicAssetsSubform.UI';

export class BasicAssetsSubform
  implements
    SubForm<
      BasicAssetsSubformType,
      Fields,
      NamespaceType,
      VariantType,
      { entityType: EntityType }
    >
{
  FormComponent: (props: Props) => JSX.Element;
  defaultValues: BasicAssetsSubformType;
  namespace: NamespaceType;

  constructor() {
    this.FormComponent = UI;
    this.defaultValues = defaultValues;
    this.namespace = BASIC_ASSETS_SUBFORM;
  }
}
