import { Box, Stack } from '@mui/material';
import Decimal from 'decimal.js';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { Button } from '@/components/form/baseInputs/Button';
import { Callout } from '@/components/notifications/Callout/Callout';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';
import {
  formatCurrency,
  formatCurrencyNoDecimals,
} from '@/utils/formatting/currency';

const currencyFormatOptions = {
  notation: 'compact' as const,
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
};

interface HouseholdEstimatedTaxSavingsProps {
  impliedEstateTaxSavings?: Decimal;
  wealthTransferred?: Decimal;
  householdId: string;
}

export function HouseholdEstimatedTaxSavings({
  impliedEstateTaxSavings,
  wealthTransferred,
  householdId,
}: HouseholdEstimatedTaxSavingsProps) {
  const performancePage = getCompletePathFromRouteKey(
    ROUTE_KEYS.HOUSEHOLD_DETAILS_PERFORMANCE,
    { householdId }
  );

  return (
    <Stack px={2} justifyContent="space-between" height="100%">
      <Stack spacing={3}>
        <OversizedMetricItem
          title="Estimated tax savings"
          titleVariant="h4"
          valueVariant="h1"
          valueColor={COLORS.NAVY[600]}
          value={
            impliedEstateTaxSavings
              ? formatCurrency(impliedEstateTaxSavings, currencyFormatOptions)
              : EMPTY_CONTENT_HYPHEN
          }
        />
        <Callout severity="info-low" textVariant="subtitle2">
          {`Calculations are based off an implied wealth transferred amount of `}
          <Box
            component="span"
            sx={{
              fontWeight: FONT_WEIGHTS.bold,
            }}
          >
            {wealthTransferred
              ? formatCurrencyNoDecimals(wealthTransferred)
              : EMPTY_CONTENT_HYPHEN}
          </Box>
        </Callout>
      </Stack>
      <Stack direction="row" justifyContent="end">
        <Button
          disabled={!performancePage}
          variant="secondary"
          size="sm"
          href={performancePage ?? ''}
        >
          View value creation
        </Button>
      </Stack>
    </Stack>
  );
}
