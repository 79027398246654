import { Stack, Typography } from '@mui/material';

import { DocumentRepresentationList } from '@/modules/files/DocumentRepresentation/DocumentRepresentationList';
import { DocumentWhereInput } from '@/types/schema';

import {
  DocumentUploader,
  DocumentUploaderProps,
} from '../DocumentUploader/DocumentUploader';

export interface DocumentUploaderWithListProps extends DocumentUploaderProps {
  listDocumentsLike?: DocumentWhereInput;
  uploaderLabel: string;
  documentIds?: string[];
}

export function DocumentUploaderWithList({
  uploaderLabel,
  listDocumentsLike,
  documentIds,
  ...documentUploaderProps
}: DocumentUploaderWithListProps) {
  return (
    <Stack spacing={2}>
      {uploaderLabel && <Typography variant="h4">{uploaderLabel}</Typography>}
      <DocumentUploader {...documentUploaderProps} />
      <DocumentRepresentationList
        showDelete={true}
        documentsLike={listDocumentsLike}
        documentIds={documentIds}
      />
    </Stack>
  );
}
