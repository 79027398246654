import { compact, isEmpty } from 'lodash';

import {
  DispositiveProvisions_DispositionScenarioFragment,
  DispositiveProvisions_DispositiveProvisionFragment,
} from '@/modules/dispositiveProvisions/graphql/DispositiveProvisions.fragments.generated';
import { PresentationSlideRegistrationProps } from '@/modules/presentation/presentation.types';
import { diagnostics } from '@/utils/diagnostics';
import { getProvisionsByDeathForScenario } from '@/utils/dispositiveProvisions';
import { getNodes } from '@/utils/graphqlUtils';

import { EntityPresentationSlideType } from '../../entityPresentations.types';
import { getPresentationEntitySlideProps } from '../../entityPresentations.utils';
import { DispositionScenarioSlideProps } from './DispositionScenarioSlide';

interface GetFilteredDispositionSlidesParams {
  dispositionScenarios: DispositiveProvisions_DispositionScenarioFragment[];
  entityId: string;
  slideTypes: EntityPresentationSlideType[];
}

type GeneratedDispositionScenarioSlideProps = Pick<
  DispositionScenarioSlideProps,
  | 'dispositionScenarioSlideIndex'
  | 'dispositionScenarioSlideTotalCount'
  | 'dispositiveProvisionSlideIndex'
  | 'dispositiveProvisionsSlideTotalCount'
  | 'dispositionScenario'
  | 'firstDeathProvisions'
  | 'secondDeathProvisions'
> &
  PresentationSlideRegistrationProps;

export function getFilteredDispositionSlideProps({
  dispositionScenarios,
  entityId,
  slideTypes,
}: GetFilteredDispositionSlidesParams): GeneratedDispositionScenarioSlideProps[] {
  return dispositionScenarios.flatMap((scenario, i) => {
    // give us room to add more slides
    const scenarioIndex = i;
    const {
      firstDeathProvisions: firstDeathProvisionsEdge,
      secondDeathProvisions: secondDeathProvisionsEdge,
    } = getProvisionsByDeathForScenario(scenario);

    const firstDeathProvisions = getNodes(firstDeathProvisionsEdge);
    const secondDeathProvisions = getNodes(secondDeathProvisionsEdge);

    // if there are no provisions, don't show the slide
    if (
      firstDeathProvisions.length === 0 &&
      secondDeathProvisions.length === 0
    ) {
      return [];
    }

    const slideDefinitions = private_getSlidesForScenario({
      firstDeathProvisions,
      secondDeathProvisions,
    });

    return slideDefinitions.map((slide, slideIndex) => {
      const slideProps = getPresentationEntitySlideProps(
        entityId,
        EntityPresentationSlideType.DISPOSITIVE_PROVISIONS,
        slideTypes,
        {
          localSlideTypeIndex: slideIndex,
          slideTypeId: scenario.id,
        }
      );

      return {
        ...slideProps,
        dispositionScenarioSlideIndex: scenarioIndex,
        dispositionScenarioSlideTotalCount: dispositionScenarios.length,
        dispositiveProvisionSlideIndex: slide.scenarioSlideIndex,
        dispositiveProvisionsSlideTotalCount: slide.scenarioSlideCount,
        dispositionScenario: scenario,
        firstDeathProvisions: slide.firstDeathProvisions,
        secondDeathProvisions: slide.secondDeathProvisions,
      };
    });
  });
}

export interface private_getSlidesForScenarioParams {
  firstDeathProvisions: DispositiveProvisions_DispositiveProvisionFragment[];
  secondDeathProvisions: DispositiveProvisions_DispositiveProvisionFragment[];
}

interface DispositionScenarioProvisionsConfiguration {
  // first/secondDeathProvisions would be empty arrays if no provisions are defined, or `null` if they may be defined but we don't want to
  // show them on this slide
  firstDeathProvisions:
    | DispositiveProvisions_DispositiveProvisionFragment[]
    | null;
  secondDeathProvisions:
    | DispositiveProvisions_DispositiveProvisionFragment[]
    | null;
  // which index this slide is within the scenario
  scenarioSlideIndex: number;
  // how many slides are present for this scenario
  scenarioSlideCount: number;
}

export const MAX_PROVISIONS_PER_COLUMN = 7;
export const COLUMNS_PER_SLIDE = 3;

// private_getSlidesForScenario is only exported to enable testing it directly.
export function private_getSlidesForScenario({
  firstDeathProvisions,
  secondDeathProvisions,
}: private_getSlidesForScenarioParams): DispositionScenarioProvisionsConfiguration[] {
  if (firstDeathProvisions.length === 0 && secondDeathProvisions.length === 0) {
    return [
      {
        firstDeathProvisions: null,
        secondDeathProvisions: null,
        scenarioSlideIndex: 0,
        scenarioSlideCount: 0,
      },
    ];
  }

  const firstDeathProvisionsColumnCount = Math.max(
    Math.ceil(firstDeathProvisions.length / MAX_PROVISIONS_PER_COLUMN),
    // there will be at least one colume of first death provisions, because we always show a blank
    // "At first death" column
    1
  );
  const secondDeathProvisionsColumnCount = Math.ceil(
    secondDeathProvisions.length / MAX_PROVISIONS_PER_COLUMN
  );

  const requiredSlides = Math.ceil(
    (firstDeathProvisionsColumnCount + secondDeathProvisionsColumnCount) /
      COLUMNS_PER_SLIDE
  );
  const slideConfigurations: DispositionScenarioProvisionsConfiguration[] = [];
  const firstDeathProvisionsColumns = [];
  const secondDeathProvisionsColumns = [];

  while (firstDeathProvisions.length) {
    firstDeathProvisionsColumns.push(
      compact(firstDeathProvisions.splice(0, MAX_PROVISIONS_PER_COLUMN))
    );
  }

  while (secondDeathProvisions.length) {
    secondDeathProvisionsColumns.push(
      compact(secondDeathProvisions.splice(0, MAX_PROVISIONS_PER_COLUMN))
    );
  }

  const haveAnyFirstDeathProvisions = firstDeathProvisionsColumns.length > 0;
  const haveAnySecondDeathProvisions = secondDeathProvisionsColumns.length > 0;

  for (let i = 0; i < requiredSlides; i++) {
    const slideRes: DispositionScenarioProvisionsConfiguration = {
      firstDeathProvisions: [],
      secondDeathProvisions: [],
      scenarioSlideIndex: i,
      scenarioSlideCount: requiredSlides,
    };

    for (let i = 0; i < COLUMNS_PER_SLIDE; i++) {
      if (firstDeathProvisionsColumns.length) {
        const firstDeathProvisions = firstDeathProvisionsColumns.shift() || [];
        slideRes.firstDeathProvisions!.push(...firstDeathProvisions);
      } else if (secondDeathProvisionsColumns.length) {
        const secondDeathProvisions =
          secondDeathProvisionsColumns.shift() || [];
        slideRes.secondDeathProvisions!.push(...secondDeathProvisions);
      }
    }

    // there are no provisions for this slide, so don't show it
    if (
      slideRes.firstDeathProvisions!.length === 0 &&
      slideRes.secondDeathProvisions!.length === 0
    ) {
      return slideConfigurations;
    }

    // first, normalize the slide so that we don't show any callout around "no dispositions specified on first death"
    // even if we've already shown those provisions on a previous slide
    if (
      slideRes.firstDeathProvisions!.length === 0 &&
      haveAnyFirstDeathProvisions
    ) {
      slideRes.firstDeathProvisions = null;
    }

    if (
      slideRes.secondDeathProvisions!.length === 0 &&
      haveAnySecondDeathProvisions
    ) {
      slideRes.secondDeathProvisions = null;
    }

    slideConfigurations.push(slideRes);
  }

  return slideConfigurations;
}

interface GetColumnsToRenderReturn {
  firstDeathColumns: number;
  secondDeathColumns: number;
}

export function getColumnsToRenderCount({
  firstDeathProvisions,
  secondDeathProvisions,
}: Pick<
  DispositionScenarioProvisionsConfiguration,
  'firstDeathProvisions' | 'secondDeathProvisions'
>): GetColumnsToRenderReturn {
  if (firstDeathProvisions && isEmpty(secondDeathProvisions)) {
    return {
      firstDeathColumns: Math.ceil(
        firstDeathProvisions.length / MAX_PROVISIONS_PER_COLUMN
      ),
      secondDeathColumns: 0,
    };
  } else if (secondDeathProvisions && isEmpty(firstDeathProvisions)) {
    return {
      // we always have one first death column because we show an empty column
      firstDeathColumns: 1,
      secondDeathColumns: Math.ceil(
        secondDeathProvisions.length / MAX_PROVISIONS_PER_COLUMN
      ),
    };
  }

  // show both as empty (this shouldn't actually be rendered)
  if (isEmpty(firstDeathProvisions) && isEmpty(secondDeathProvisions)) {
    return {
      firstDeathColumns: 1,
      secondDeathColumns: 1,
    };
  }

  diagnostics.error(
    'invalid state: both first and second death provisions are present',
    new Error(
      'invalid state: both first and second death provisions are present'
    )
  );
  return {
    firstDeathColumns: 0,
    secondDeathColumns: 0,
  };
}
