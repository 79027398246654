import { Box, Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { useState } from 'react';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { Button } from '@/components/form/baseInputs/Button';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { ArrowRightChunkyIcon } from '@/components/icons/ArrowRightChunkyIcon';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { Modal, ModalProps } from '@/components/modals/Modal/Modal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
// note:  I think this points out that our modules shouldn't need to know exactly what GraphQL documents to
// refetch. We should switch to the cache invalidation model.
import { GetGratPerformanceDocument } from '@/modules/entities/details/grat/GRATPerformancePage/graphql/GetGratPerformance.generated';
import { DocumentRepresentationList } from '@/modules/files/DocumentRepresentation/DocumentRepresentationList';
import { COLORS } from '@/styles/tokens/colors';
import { AssetValuationV2ValuationReason, DocumentType } from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { ValuationReasonLabel } from '../AssetValuationReasonLabel/AssetValuationReasonLabel';
import { AssetValuationTable } from '../AssetValuationTable/AssetValuationTable';
import {
  AssetValuationsDocument,
  AssetValuationV2Fragment,
} from '../graphql/entityValuationQueries.generated';
import { useDeleteAssetValuationMutation } from '../graphql/mutations.generated';

export type Props = Omit<ModalProps, 'children' | 'heading'> & {
  currentAssetValuationId: string;
  entityId: string;
  assetValuations: AssetValuationV2Fragment[];
};

function BeforeAfterValuation({
  beforeValue,
  afterValue,
  beforeDate,
  comparisonCopy,
}: {
  beforeValue: Decimal;
  afterValue: Decimal;
  beforeDate: Date;
  comparisonCopy: string;
}) {
  return (
    <Stack direction="row" justifyContent="space-between" py={3}>
      <OversizedMetricItem
        sx={{ textAlign: 'left', width: 200 }}
        title={`Previous valuation (${formatDateToMonDDYYYY(beforeDate)})`}
        value={formatCurrency(beforeValue)}
        valueColor={COLORS.GRAY[400]}
      />
      <ArrowRightChunkyIcon fill={COLORS.GRAY[200]} size={24} />
      <OversizedMetricItem
        sx={{ textAlign: 'right', width: 200 }}
        title={comparisonCopy}
        value={formatCurrency(afterValue)}
      />
    </Stack>
  );
}

export function AssetValuationAsOfModal({
  onClose,
  isOpen,
  currentAssetValuationId,
  assetValuations,
  entityId,
  ...modalProps
}: Props) {
  const { createErrorFeedback, createSuccessFeedback } = useFeedback();
  const [deleteAssetValuationMutation, { loading }] =
    useDeleteAssetValuationMutation({
      onError: createErrorFeedback(
        "We weren't able to delete this valuation. Please refresh the page and try again."
      ),
      onCompleted: createSuccessFeedback('Successfully deleted valuation.'),
    });
  const [tab, setTab] = useState<'before' | 'after'>('after');

  const handleDelete = async (id: string) => {
    await deleteAssetValuationMutation({
      variables: {
        assetValuationId: id,
      },
      refetchQueries: [GetGratPerformanceDocument, AssetValuationsDocument],
      onCompleted: onClose,
    });
  };

  const documentsQueryFilter = {
    hasEntityWith: [{ id: entityId }],
    type: DocumentType.AssetValuation,
  };

  const currentAssetValuation: AssetValuationV2Fragment | null =
    assetValuations.find(
      (assetValuation) => assetValuation.id === currentAssetValuationId
    ) ?? null;

  const previousValuationId =
    currentAssetValuation?.previousValuation?.id ?? '';

  let selectedAssetValuation = currentAssetValuation;

  if (tab === 'before') {
    selectedAssetValuation =
      assetValuations.find(
        (assetValuation) => assetValuation.id === previousValuationId
      ) ?? null;
  }

  const valuationReason = currentAssetValuation?.valuationReason;

  const canDelete =
    currentAssetValuation?.valuationReason ===
    AssetValuationV2ValuationReason.AssetRevaluation;

  const shouldShowValueComparison =
    valuationReason === AssetValuationV2ValuationReason.GratAnnuityPayment ||
    valuationReason === AssetValuationV2ValuationReason.GratAssetSubstitution;
  let comparisonCopy = 'Value after payment';
  if (
    valuationReason === AssetValuationV2ValuationReason.GratAssetSubstitution
  ) {
    comparisonCopy = 'Value after substitution';
  }

  const modalActions = (
    <Stack width="100%" px={1.5} direction="row" justifyContent="space-between">
      <Box>
        {canDelete && (
          <Button
            aria-label="Delete asset"
            onClick={() => handleDelete(currentAssetValuationId)}
            variant="destructive"
            disabled={loading}
            size="sm"
          >
            <Trash01Icon />
          </Button>
        )}
      </Box>
      <Box>
        <Button
          onClick={() => {
            onClose();

            setTimeout(() => {
              setTab('after');
            }, 200);
          }}
          size="sm"
          variant="secondary"
        >
          Close
        </Button>
      </Box>
    </Stack>
  );

  return (
    <Modal
      isOpen={isOpen}
      actions={modalActions}
      onClose={onClose}
      {...modalProps}
      heading={`Valuation as of ${formatDateToMonDDYYYY(
        currentAssetValuation?.effectiveDate ?? new Date()
      )}`}
      rightHeaderContent={
        <>
          {valuationReason && <ValuationReasonLabel reason={valuationReason} />}
        </>
      }
    >
      <Stack flex={1} sx={{ minHeight: 450 }} px={2}>
        {shouldShowValueComparison && (
          <ButtonGroup
            sx={{ mb: 3 }}
            label=""
            value={tab}
            onChange={(_, value) => setTab(value)}
            options={[
              { display: 'Previous valuation', value: 'before' },
              { display: comparisonCopy, value: 'after' },
            ]}
          />
        )}

        <AssetValuationTable
          entityId={entityId}
          assetValuationId={selectedAssetValuation?.id ?? ''}
          shouldShowSumRow={!shouldShowValueComparison}
        />

        {shouldShowValueComparison &&
          currentAssetValuation?.previousValuation && (
            <BeforeAfterValuation
              beforeValue={
                currentAssetValuation.previousValuation?.valuationValue ??
                new Decimal(0)
              }
              beforeDate={
                currentAssetValuation.previousValuation?.effectiveDate
              }
              afterValue={
                currentAssetValuation.valuationValue ?? new Decimal(0)
              }
              comparisonCopy={comparisonCopy}
            />
          )}
        <DocumentRepresentationList
          showDelete={false}
          sx={{ mt: 1 }}
          documentsLike={documentsQueryFilter}
        />
      </Stack>
    </Modal>
  );
}
