import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '@/components/modals/ConfirmationModal/ConfirmationModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';

import { useDeleteBalanceSheetCategoryMutation } from '../../graphql/AdminBalanceSheetConfigurationPage.generated';

export interface BalanceSheetCategoryDeletionModalProps
  extends Omit<ConfirmationModalProps, 'heading' | 'onClose'> {
  deletedCategoryId: string;
  onClose(didDelete: boolean): void;
}

export function BalanceSheetCategoryDeletionModal({
  onClose,
  deletedCategoryId,
  ...modalProps
}: BalanceSheetCategoryDeletionModalProps) {
  const { showFeedback } = useFeedback();
  const [deleteCategory] = useDeleteBalanceSheetCategoryMutation({
    onCompleted: () => {
      showFeedback('Successfully deleted balance sheet category', {
        variant: 'success',
      });
      onClose(true);
    },
    onError: () => {
      showFeedback('Failed to delete balance sheet category');
    },
    update: (cache) => {
      const normalizedId = cache.identify({
        id: deletedCategoryId,
        __typename: 'BalanceSheetCategory',
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const handleDelete = () => {
    void deleteCategory({
      variables: {
        id: deletedCategoryId,
      },
    });
  };

  return (
    <ConfirmationModal
      heading="Are you sure you want to delete this category?"
      onClose={(reason) => {
        if (reason === 'confirm') {
          handleDelete();
        } else {
          onClose(false);
        }
      }}
      {...modalProps}
    >
      This action cannot be undone.
    </ConfirmationModal>
  );
}
