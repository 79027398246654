import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { DocumentSummaryEntryMode } from '@/types/schema';

import { useRateSummary } from '../../hooks/useRateSummary';
import { useAsyncGenerateSummaryMutation } from '../graphql/AsyncGenerateSummary.generated';

export function useRegenAiSummary(
  documentId: string,
  documentSummaryId: string
) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const { clearSummaryRating } = useRateSummary(documentSummaryId, null, false);

  const [asyncRegen, mutationProps] = useAsyncGenerateSummaryMutation({
    variables: {
      input: {
        summaryEntryMode: DocumentSummaryEntryMode.AiUserInvoked,
        where: {
          id: documentId,
        },
      },
    },
    onCompleted: () => {
      // Make sure to clear the rating after regenerating the summary
      clearSummaryRating();
    },
    onError: (error) => {
      reportError('Failed to regenerate AI summary', error);
      showFeedback('Failed to regenerate summary');
    },
  });

  return { asyncRegen, ...mutationProps };
}
