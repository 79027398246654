import React from 'react';

import { PopperContent } from '@/components/poppers/PopperContent';

export function EstateWaterfallAssetGrowthReturn() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body="Projected annual rate of return for all assets"
    />
  );
}
