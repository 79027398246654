import { FitViewOptions, useReactFlow } from '@xyflow/react';
import { isFunction } from 'lodash';

import { Maximize02Icon } from '@/components/icons/Maximize02Icon';

import {
  CONTROL_BUTTON_ICON_SIZE,
  ControlButton,
  ControlButtonProps,
} from './ControlButton';

type FitViewOptionsGetter = () => FitViewOptions;

export type FitViewButtonProps = ControlButtonProps & {
  fitViewOptions?: FitViewOptions | FitViewOptionsGetter;
};

export const FitViewButton = ({
  onClick,
  fitViewOptions,
  children,
  ...props
}: FitViewButtonProps) => {
  const { fitView } = useReactFlow();

  const defaultOnClick = () => {
    void fitView(
      isFunction(fitViewOptions) ? fitViewOptions() : fitViewOptions
    );
  };

  return (
    <ControlButton
      variant="secondary-filled"
      onClick={onClick ?? defaultOnClick}
      title="fit view"
      aria-label="fit view"
      {...props}
    >
      {children ?? <Maximize02Icon size={CONTROL_BUTTON_ICON_SIZE} />}
    </ControlButton>
  );
};
