import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

import { Card } from '@/components/layout/Card/Card';
import { ContextualHelpTrigger } from '@/modules/content/components/ContextualHelpTrigger';

export function ClientDetailsOverviewPageCard({
  children,
  includeHelpContentTrigger,
}: PropsWithChildren<{ includeHelpContentTrigger?: boolean }>) {
  return (
    <Card
      variant="outlined"
      sx={{
        height: '100%',
        p: 1,
        pb: 4,
        borderRadius: '6px',
      }}
    >
      <Stack height="100%">
        {includeHelpContentTrigger && (
          <Stack direction="row" justifyContent="end">
            <ContextualHelpTrigger contextualHelpId="howIsThisCalculated" />
          </Stack>
        )}
        {children}
      </Stack>
    </Card>
  );
}
