import { Box, Stack, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface Props {
  label: string;
  value: React.ReactNode;
  invertLabel?: boolean;
  action?: JSX.Element | null;
  sx?: SxProps<Theme>;
}

export function LabelItem({ label, value, action, invertLabel, sx }: Props) {
  return (
    <Stack direction="row" sx={sx}>
      <Stack direction={invertLabel ? 'column-reverse' : 'column'}>
        <Typography variant="label">{label}</Typography>
        <Typography variant="body1">{value}</Typography>
      </Stack>
      {action && <Box ml={1}>{action}</Box>}
    </Stack>
  );
}
