import { ChartColorDefinitions } from '@/components/charts/constants';
import { EntityInEstateStatus, EntityKind } from '@/types/schema';

import {
  BUSINESS_ENTITY_KINDS,
  CHARITABLE_ENTITY_KINDS,
  ENTITY_SEMANTIC_COLORS,
} from '../entities.constants';

interface ColorableEntity {
  kind?: EntityKind;
  subtype?:
    | {
        inEstateStatus?: EntityInEstateStatus;
      }
    | object;
}

interface GetColorDefinitionForEntityInput {
  entity: ColorableEntity;
  colors: ChartColorDefinitions;
}

export function getColorDefinitionForEntity({
  entity,
  colors,
}: GetColorDefinitionForEntityInput) {
  const entitySubtype = entity.subtype;
  if (!entitySubtype) return 'transparent' as const;
  if (!entity.kind) return 'transparent' as const;

  if (BUSINESS_ENTITY_KINDS.includes(entity.kind)) {
    // Business entities can have mixed in- and out-of-estate ownership, so we show
    // them as transparent
    return 'transparent' as const;
  }

  if ('inEstateStatus' in entitySubtype) {
    const estateStatus = entitySubtype.inEstateStatus;

    if (estateStatus === EntityInEstateStatus.InEstate) {
      return colors[ENTITY_SEMANTIC_COLORS.inEstate].backgroundColor;
    }

    if (
      estateStatus === EntityInEstateStatus.OutOfEstate &&
      CHARITABLE_ENTITY_KINDS.includes(entity.kind)
    ) {
      return colors[ENTITY_SEMANTIC_COLORS.outOfEstateCharity].backgroundColor;
    }

    return colors[ENTITY_SEMANTIC_COLORS.outOfEstateFamily].backgroundColor;
  }

  return 'transparent' as const;
}
