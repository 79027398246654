import React from 'react';

import { useGuardedClientPresentationDesignerContext } from '../../contexts/clientPresentationDesigner.context';
import { ToggleVisibilityButton } from '../ToggleVisibilityButton';
import { SidebarGroupElement } from './SidebarGroupElement';

// This is the "state-aware" wrapper around the SidebarGroupElement component.
export type SidebarGroupProps = React.PropsWithChildren<{
  label: React.ReactNode;
  onToggleVisibility?: () => void;
  isHidden: boolean;
}>;

export function SidebarGroup({
  label,
  onToggleVisibility,
  isHidden,
  children,
}: SidebarGroupProps) {
  const { showHiddenItems } = useGuardedClientPresentationDesignerContext();

  if (isHidden) {
    return null;
  }

  return (
    <SidebarGroupElement
      label={label}
      RightAction={
        onToggleVisibility &&
        (({ isHovered }) => (
          <ToggleVisibilityButton
            isVisible={isHovered}
            toggleVariant={showHiddenItems ? 'add' : 'remove'}
            onClick={() => {
              onToggleVisibility();
            }}
          />
        ))
      }
    >
      {children}
    </SidebarGroupElement>
  );
}
