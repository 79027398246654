import { PopperContent } from '@/components/poppers/PopperContent';

export function LifetimeExemptionAdjustment() {
  return (
    <PopperContent
      body={
        'This represents the additional lifetime exemption used and will increase the total amount of lifetime exemption used for the specified client.'
      }
    />
  );
}
