import { FormProvider } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useForm, useSubmitSuccessHandler } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';

import { mapFormDataToInput } from './CreateSubBrandModal.utils';
import { useCreateSubBrandMutation } from './graphql/SubBrandModal.generated';
import { SubBrandForm } from './SubBrandForm';
import { defaultValues, SubBrandFormShape } from './SubBrandModal.constants';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'>;
export type CreateSubBrandModalProps = FormModalOptionalProps;

function CreateSubBrand({
  onClose,
  heading = 'Add new sub-brand',
  ...formModalProps
}: FormModalOptionalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const tenantDetails = useTenantDetailsContext();
  const { formRef, handleSave } = useFormSaveHandler();
  const { handleSubmit, reset, shouldBlockNavigation, formState } =
    useFormContext<SubBrandFormShape>();

  const [createMutation] = useCreateSubBrandMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError(`Could not create SubBrand`, error);
    },
  });

  const onSubmit = handleSubmit((formData) => {
    if (!tenantDetails.branding) {
      throw new Error('Missing required tenand branding data');
    }
    return createMutation({
      variables: {
        input: mapFormDataToInput(formData, tenantDetails.branding),
      },
    });
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  useSubmitSuccessHandler(() => {
    closeModal();
  });

  return (
    <FormModal
      {...formModalProps}
      onClose={onClose}
      heading={heading}
      shouldBlockClose={shouldBlockNavigation}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.CancelButton onClick={closeModal} />
          <FormModalActions.SaveButton onClick={handleSave}>
            Add sub-brand
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <SubBrandForm />
      </form>
    </FormModal>
  );
}

export const CreateSubBrandModal = ({ ...props }: CreateSubBrandModalProps) => {
  const formMethods = useForm<SubBrandFormShape>({ defaultValues });

  return (
    <FormProvider {...formMethods}>
      <CreateSubBrand {...props} />
    </FormProvider>
  );
};
