import { Box, Stack } from '@mui/material';
import React from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { useFormContext } from '@/components/react-hook-form';
import { getGreaterThanCurrentYearValidation } from '@/components/utils/inputUtils';
import { EstateWaterfallAssetGrowthReturn } from '@/modules/content/tooltipContent/EstateWaterfallAssetGrowthReturn';
import { EstateWaterfallExemptionGrowthRate } from '@/modules/content/tooltipContent/EstateWaterfallExemptionGrowthRate';
import { EstateWaterfallExemptionSunset } from '@/modules/content/tooltipContent/EstateWaterfallExemptionSunset';
import { EstateWaterfallFirstGrantorDeath } from '@/modules/content/tooltipContent/EstateWaterfallFirstGrantorDeath';
import { EstateWaterfallSecondGrantorDeath } from '@/modules/content/tooltipContent/EstateWaterfallSecondGrantorDeath';

import { EstateWaterfallAssumptionFields } from './types';

interface AssumptionFormFieldProps {
  disabled?: boolean;
}

export const FirstGrantorDeathField = ({
  disabled,
}: AssumptionFormFieldProps) => {
  const { control } = useFormContext<EstateWaterfallAssumptionFields>();

  return (
    <FormAwareFormattedNumberInput<EstateWaterfallAssumptionFields>
      control={control}
      label="1st death"
      fieldName={
        'firstGrantorDeathYear' as const satisfies keyof EstateWaterfallAssumptionFields
      }
      contextualHelp={<EstateWaterfallFirstGrantorDeath />}
      validation={{
        min: (v) => getGreaterThanCurrentYearValidation(v as number),
      }}
      disabled={disabled}
    />
  );
};

export const SecondGrantorDeathField = ({
  disabled,
}: AssumptionFormFieldProps) => {
  const { control, getValues } =
    useFormContext<EstateWaterfallAssumptionFields>();

  return (
    <FormAwareFormattedNumberInput<EstateWaterfallAssumptionFields>
      control={control}
      label="2nd death"
      fieldName={
        'secondGrantorDeathYear' as const satisfies keyof EstateWaterfallAssumptionFields
      }
      contextualHelp={<EstateWaterfallSecondGrantorDeath />}
      validation={{
        min: (value) => {
          if (!value) return undefined;

          const numYear = Number(value);
          if (isNaN(numYear)) {
            return `Year must be a number`;
          }

          const firstDeath = getValues('firstGrantorDeathYear');
          if (firstDeath && numYear < firstDeath) {
            return `2nd death year must be ≥ ${firstDeath}`;
          }

          return getGreaterThanCurrentYearValidation(numYear);
        },
      }}
      disabled={disabled}
    />
  );
};

export const WATERFALL_ASSET_GROWTH_DECIMAL_SCALE = 2;

export const AssetGrowthRateField = ({
  disabled,
}: AssumptionFormFieldProps) => {
  const { control } = useFormContext<EstateWaterfallAssumptionFields>();

  return (
    <FormAwarePercentInput<EstateWaterfallAssumptionFields>
      control={control}
      label={'Asset growth'}
      fieldName={
        'assetGrowthReturn' as const satisfies keyof EstateWaterfallAssumptionFields
      }
      isDecimalJSInput
      allowNegative
      fixedDecimalScale={true}
      decimalScale={WATERFALL_ASSET_GROWTH_DECIMAL_SCALE}
      contextualHelp={<EstateWaterfallAssetGrowthReturn />}
      disabled={disabled}
    />
  );
};

export const ExemptionSunsetField = ({
  disabled,
}: AssumptionFormFieldProps) => {
  const { control } = useFormContext<EstateWaterfallAssumptionFields>();

  return (
    <FormAwareCheckbox<EstateWaterfallAssumptionFields>
      control={control}
      label="Exemption sunsets"
      fieldName={
        'willExemptionSunset' as const satisfies keyof EstateWaterfallAssumptionFields
      }
      contextualHelp={<EstateWaterfallExemptionSunset />}
      disabled={disabled}
    />
  );
};

export const ExemptionGrowthRateField = ({
  disabled,
}: AssumptionFormFieldProps) => {
  const { control } = useFormContext<EstateWaterfallAssumptionFields>();

  return (
    <FormAwarePercentInput<EstateWaterfallAssumptionFields>
      control={control}
      label="Exemption growth"
      fieldName={
        'exemptionGrowthRate' as const satisfies keyof EstateWaterfallAssumptionFields
      }
      isDecimalJSInput
      allowNegative
      fixedDecimalScale={true}
      decimalScale={2}
      contextualHelp={<EstateWaterfallExemptionGrowthRate />}
      disabled={disabled}
    />
  );
};

export const CustomGlobalGrowthRateToggle = ({
  disabled,
}: AssumptionFormFieldProps) => {
  const { control } = useFormContext<EstateWaterfallAssumptionFields>();
  const shouldShowBlendedGrowthInput = useWatch({
    control,
    name: 'useCustomGlobalGrowthRate',
  });

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <FormAwareSwitch<EstateWaterfallAssumptionFields>
        control={control}
        labelPosition="right"
        label={
          shouldShowBlendedGrowthInput
            ? 'Override with blended rate of'
            : 'Override asset category growth rates'
        }
        fieldName={
          'useCustomGlobalGrowthRate' as const satisfies keyof EstateWaterfallAssumptionFields
        }
        disabled={disabled}
      />
      {shouldShowBlendedGrowthInput && (
        <Box width={125}>
          <FormAwarePercentInput<EstateWaterfallAssumptionFields>
            control={control}
            label={'Asset growth rate'}
            fieldName={
              'assetGrowthReturn' as const satisfies keyof EstateWaterfallAssumptionFields
            }
            isDecimalJSInput
            allowNegative
            fixedDecimalScale={true}
            hideLabel
            decimalScale={WATERFALL_ASSET_GROWTH_DECIMAL_SCALE}
            contextualHelp={<EstateWaterfallAssetGrowthReturn />}
            disabled={disabled}
          />
        </Box>
      )}
    </Stack>
  );
};
