import { AugmentedUpdateEntityInput, EntityStage } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { EntityType } from '../../types/EntityType';

interface MakeActivateEntityUpdateInputParams {
  entityId: string;
  subtypeId: string;
  entityType: EntityType;
}

export function makeActivateEntityUpdateInput({
  entityId,
  subtypeId,
  entityType,
}: MakeActivateEntityUpdateInputParams): AugmentedUpdateEntityInput {
  const updateEntity = {
    id: entityId,
    update: {
      stage: EntityStage.Active,
    },
  };
  const updateSubtype = {
    id: subtypeId,
    update: {},
  };

  switch (entityType) {
    case 'revocable-trust':
      return {
        ...updateEntity,
        updateRevocableTrust: updateSubtype,
      };
    case 'irrevocable-trust':
      return {
        ...updateEntity,
        updateIrrevocableTrust: updateSubtype,
      };
    case 'slat':
      return {
        ...updateEntity,
        updateSlatTrust: updateSubtype,
      };
    case 'grat':
      return {
        ...updateEntity,
        updateGratTrust: updateSubtype,
      };
    case 'qprt':
      return {
        ...updateEntity,
        updateQprtTrust: updateSubtype,
      };
    case 'ilit':
      return {
        ...updateEntity,
        updateIlitTrust: updateSubtype,
      };
    case 'individual-account':
      return {
        ...updateEntity,
        updateIndividualPersonalAccount: updateSubtype,
      };
    case 'insurance-account':
      return {
        ...updateEntity,
        updateInsurancePersonalAccount: updateSubtype,
      };
    case 'clt':
      return {
        ...updateEntity,
        updateCltTrust: updateSubtype,
      };
    case 'crt':
      return {
        ...updateEntity,
        updateCrtTrust: updateSubtype,
      };
    case 'daf':
      return {
        ...updateEntity,
        updateDonorAdvisedFund: updateSubtype,
      };
    case 'private-foundation':
      return {
        ...updateEntity,
        updatePrivateFoundation: updateSubtype,
      };
    case 'joint-account':
      return {
        ...updateEntity,
        updateJointPersonalAccount: updateSubtype,
      };
    case 'retirement-account':
      return {
        ...updateEntity,
        updateRetirementPersonalAccount: updateSubtype,
      };
    case 'custodial-account':
      return {
        ...updateEntity,
        updateCustodialPersonalAccount: updateSubtype,
      };
    case 'qualified-tuition-account':
      return {
        ...updateEntity,
        updateQualifiedTuitionPersonalAccount: updateSubtype,
      };
    case 'sole-proprietorship':
      return {
        ...updateEntity,
        updateSoleProprietorshipBusinessEntity: updateSubtype,
      };
    case 'c-corp':
      return {
        ...updateEntity,
        updateCcorpBusinessEntity: updateSubtype,
      };
    case 's-corp':
      return {
        ...updateEntity,
        updateScorpBusinessEntity: updateSubtype,
      };
    case 'llc':
      return {
        ...updateEntity,
        updateLlcBusinessEntity: updateSubtype,
      };
    case 'lp':
      return {
        ...updateEntity,
        updateLpBusinessEntity: updateSubtype,
      };
    case 'gp':
      return {
        ...updateEntity,
        updateGpBusinessEntity: updateSubtype,
      };
    default: {
      const err = Error(
        `Could not create entity input for unrecognized entity type: ${entityType}`
      );

      diagnostics.error(`Unrecognized entity type: ${entityType}`, err);
      throw err;
    }
  }
}
