import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { ComponentProps, JSXElementConstructor, useMemo } from 'react';

import { Switch } from '@/components/form/baseInputs/Switch';
import { Loader } from '@/components/progress/Loader/Loader';
import {
  DispositiveProvisionsContext,
  useDispositiveProvisionsContext,
} from '@/modules/dispositiveProvisions/contexts/dispositiveProvisions.context';
import { useDispositiveProvisionsContextData } from '@/modules/dispositiveProvisions/hooks/useDispositiveProvisionsContextData';

import { HypotheticalWaterfallScenarioCallout } from '../components/HypotheticalWaterfallScenarioCallout';
import { DispositiveProvisionsByDeath } from './DispositiveProvisionsByDeath';
import { DispositiveProvisionsForSingleClientHousehold } from './DispositiveProvisionsForSingleClientHousehold';
import { DispositiveProvisionsForTwoClientHousehold } from './DispositiveProvisionsForTwoClientHousehold';
import { useDispositiveProvisionsListViewSlots } from './hooks/useDispositiveProvisionsListViewSlots';
import { useDispositiveProvisionsListViewTab } from './hooks/useDispositiveProvisionsListViewTab';
import { NoDispositions } from './NoDispositions';

interface TrustDispositionsTabProps {
  currentNodeValue?: Decimal;
  firstDeathPrimaryClientId?: string | null;
}

function CalculateValuesSwitch() {
  const { showCalculatedDispositions, setShowCalculatedDispositions } =
    useDispositiveProvisionsContext();

  return (
    <Switch
      value={showCalculatedDispositions}
      onChange={() =>
        setShowCalculatedDispositions(!showCalculatedDispositions)
      }
      label="Show calculated distributions"
      labelPosition="right"
    />
  );
}

function DispositiveProvisionsListViewInner({
  currentNodeValue,
  firstDeathPrimaryClientId,
}: TrustDispositionsTabProps) {
  const {
    firstGrantorDeathId,
    dispositionScenarios,
    isTwoClientHousehold,
    isOnHypotheticalWaterfall,
    waterfall,
  } = useDispositiveProvisionsContext();
  const firstDeathId = firstDeathPrimaryClientId || firstGrantorDeathId;

  const activeTabProps = useDispositiveProvisionsListViewTab(firstDeathId);
  const { activePrimaryClientIdTab, defaultTab } = activeTabProps;

  if (dispositionScenarios && dispositionScenarios.length > 2) {
    throw new Error('Too many disposition scenarios. We only support 2.');
  }

  const dispositionScenarioForTab =
    dispositionScenarios?.find((scenario) => {
      return scenario.firstGrantorDeath.id === activePrimaryClientIdTab;
    }) ?? null;

  const hypotheticalWaterfallCallout =
    isOnHypotheticalWaterfall && waterfall ? (
      <HypotheticalWaterfallScenarioCallout
        waterfallName={waterfall.displayName}
      />
    ) : null;

  if (waterfall) {
    return (
      <Stack spacing={3}>
        <CalculateValuesSwitch />
        <DispositiveProvisionsByDeath
          currentNodeValue={currentNodeValue}
          waterfall={waterfall}
          {...activeTabProps}
        />
      </Stack>
    );
  }

  if (dispositionScenarios?.length === 0) {
    return (
      <Stack spacing={3}>
        {hypotheticalWaterfallCallout}
        <NoDispositions
          currentNodeValue={currentNodeValue}
          firstDeathPrimaryClientId={firstDeathId}
          {...activeTabProps}
        />
      </Stack>
    );
  }

  return (
    <Stack spacing={3}>
      {hypotheticalWaterfallCallout}
      <CalculateValuesSwitch />
      {isTwoClientHousehold && (
        <DispositiveProvisionsForTwoClientHousehold
          dispositionScenarioForTab={dispositionScenarioForTab}
          currentNodeValue={currentNodeValue}
          firstDeathPrimaryClientId={defaultTab}
          {...activeTabProps}
        />
      )}
      {!isTwoClientHousehold && (
        <DispositiveProvisionsForSingleClientHousehold
          dispositionScenarioForTab={dispositionScenarioForTab}
          currentNodeValue={currentNodeValue}
          firstDeathPrimaryClientId={defaultTab}
          {...activeTabProps}
        />
      )}
    </Stack>
  );
}

export interface DispositiveProvisionsListViewProps {
  firstDeathPrimaryClientId?: string | null;
  currentNodeValue?: Decimal;
  slots?: {
    Loader?: JSXElementConstructor<ComponentProps<typeof Loader>>;
  };
}

export function DispositiveProvisionsListView(
  props: DispositiveProvisionsListViewProps
) {
  const dispositiveProvisionsData = useDispositiveProvisionsContextData();

  const slots = useDispositiveProvisionsListViewSlots(props.slots);

  const isLoading = useMemo(() => {
    return dispositiveProvisionsData.loading;
  }, [dispositiveProvisionsData.loading]);

  if (isLoading) {
    return (
      <slots.Loader
        boxProps={{
          sx: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          },
        }}
      />
    );
  }

  return (
    <DispositiveProvisionsContext.Provider
      value={
        {
          ...dispositiveProvisionsData,
          formVariant: 'editEntity',
        } as DispositiveProvisionsContext
      }
    >
      <DispositiveProvisionsListViewInner {...props} />
    </DispositiveProvisionsContext.Provider>
  );
}
