import { BusinessEntityTaxStatus, KeyPersonRoleKind } from '@/types/schema';

export const businessTaxStatusDisplayNames: Record<
  BusinessEntityTaxStatus,
  string
> = {
  [BusinessEntityTaxStatus.Corporation]: 'Corporation',
  [BusinessEntityTaxStatus.DisregardedEntity]: 'Disregarded entity',
  [BusinessEntityTaxStatus.NonTaxableEntity]: 'Non-taxable entity',
  [BusinessEntityTaxStatus.Partnership]: 'Partnership',
};

export const keyPersonRoleDisplayNames: Record<KeyPersonRoleKind, string> = {
  [KeyPersonRoleKind.ControllingPerson]: 'Controlling person',
  [KeyPersonRoleKind.BoardMember]: 'Board member',
  [KeyPersonRoleKind.Director]: 'Director',
  [KeyPersonRoleKind.GeneralPartner]: 'General partner',
  [KeyPersonRoleKind.Manager]: 'Manager',
  [KeyPersonRoleKind.Officer]: 'Officer',
  [KeyPersonRoleKind.Other]: 'Other',
  [KeyPersonRoleKind.President]: 'President',
  [KeyPersonRoleKind.Secretary]: 'Secretary',
  [KeyPersonRoleKind.Signatory]: 'Signatory',
  [KeyPersonRoleKind.VicePresident]: 'Vice president',
};
