import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { ButtonGroupInputOption } from '@/components/form/baseInputs/inputTypes';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import {
  CharitableTrustDesignerAssetsTable,
  CharitableTrustDesignerProjectionBarGraphs,
} from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesignerAnalysis/IllustrationComponents/CharitableTrustDesignerAnalysisIllustrationAnalysis';
import { CharitableTrustDesignerAnalysisIllustrationSummaryCards } from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesignerAnalysis/IllustrationComponents/CharitableTrustDesignerAnalysisIllustrationSummaryCards';
import { formatPercent } from '@/utils/formatting/percent';

import { useGetCharitableProjection } from '../../hooks/useGetCharitableProjection';
import { CharitableAnalysisForm, NAMESPACE } from './CharitableAnalysis.types';

export interface UIProps {
  proposal: ProposalFragment;
}

export function CharitableAnalysisBody({ proposal }: UIProps) {
  const liveProposal = useGetCharitableProjection({ proposal });

  return (
    <Stack direction="column" spacing={3}>
      <CharitableTrustDesignerAnalysisIllustrationSummaryCards
        crtProjection={liveProposal.crtProjection}
        cltProjection={liveProposal.cltProjection}
      />
      <TaxAndDateSelector />
      <CharitableTrustDesignerProjectionBarGraphs
        crtProjection={liveProposal.crtProjection}
        cltProjection={liveProposal.cltProjection}
        noPlanProjection={liveProposal.noPlanProjection}
      />
      <CharitableTrustDesignerAssetsTable
        crtProjection={liveProposal.crtProjection}
        cltProjection={liveProposal.cltProjection}
        noPlanProjection={liveProposal.noPlanProjection}
      />
    </Stack>
  );
}

function TaxAndDateSelector() {
  const { control, setValue } = useFormContext<CharitableAnalysisForm>();

  const [years, selectedYearIdx, projections, selectedProjectionIdx] = useWatch(
    {
      control,
      name: [
        `${NAMESPACE}.years`,
        `${NAMESPACE}.selectedYearIdx`,
        `${NAMESPACE}.projections`,
        `${NAMESPACE}.selectedProjectionIdx`,
      ],
    }
  );

  const buttonGroupOptions = useMemo<ButtonGroupInputOption<number>[]>(() => {
    return projections.map((projection, idx) => ({
      display: `${formatPercent(projection.preTaxReturnPercentage, 0)}%`,
      value: idx,
    }));
  }, [projections]);

  const yearOptions: ButtonGroupInputOption[] = years.map((year, idx) => ({
    display: year.toString(),
    value: idx,
  }));

  return (
    <Card variant="outlined" sx={{ p: 3 }}>
      <FormLayoutRow>
        <FormLayoutItem width={6}>
          <ButtonGroup
            variant="onDark"
            options={buttonGroupOptions}
            label="Annual pre-tax return"
            value={selectedProjectionIdx}
            onChange={(_, value) =>
              setValue(`${NAMESPACE}.selectedProjectionIdx`, value)
            }
          />
        </FormLayoutItem>
        <FormLayoutItem width={6}>
          <ButtonGroup
            options={yearOptions}
            label="Year"
            value={selectedYearIdx}
            onChange={(_, yearIndexValue) => {
              setValue(`${NAMESPACE}.selectedYearIdx`, yearIndexValue);
              const selectedYear = years[yearIndexValue];
              if (selectedYear) {
                setValue(
                  `CHARITABLE_TRUST_DESIGNER_ANALYSIS.analysis.yearOfAnalysis`,
                  selectedYear
                );
              }
            }}
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </Card>
  );
}
