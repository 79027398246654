import { useReducer } from 'react';

import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';

import {
  AboutLoadingState,
  DocumentDetailsPageLoadingState,
  LoadingReducerInput,
  SummaryLoadingState,
} from '../DocumentDetailsPage.types';

function getStateFromInput(
  state: DocumentDetailsPageLoadingState,
  input: LoadingReducerInput
) {
  // Get the error states out of the way first
  if (input.doneLoading && !input.hasDocument) {
    // If the document is done loading but there is no document, we show an error
    return {
      ...state,
      error: true,
    };
  }

  // About either shows a spinner or is done loading
  const aboutLoadingState = input.doneLoading
    ? AboutLoadingState.Done
    : AboutLoadingState.Loading;

  const summaryLoadingState = (() => {
    // If the document is not done loading, we show a regular spinner
    if (!input.doneLoading) {
      return SummaryLoadingState.Loading;
    }

    // If the document is done loading but the document is still being processed,
    // we show a processing spinner
    if (input.doneLoading && !input.doneProcessingDocument) {
      return SummaryLoadingState.Processing;
    }

    // If the document is not loading or processing, but does not have a summary,
    // we show a no summary message
    if (
      input.doneLoading &&
      input.doneProcessingDocument &&
      !input.hasSummary
    ) {
      return SummaryLoadingState.NoSummary;
    }

    // If the document is done loading, done processing, and has a summary,
    // we show the summary
    if (input.doneLoading && input.doneProcessingDocument && input.hasSummary) {
      return SummaryLoadingState.WithSummary;
    }

    diagnostics.error(
      'Invalid loading state for DocumentDetailsPage',
      new Error('Invalid loading state for DocumentDetailsPage'),
      {
        ...input,
      }
    );
    return SummaryLoadingState.Loading;
  })();

  return {
    ...state,
    about: {
      loading: aboutLoadingState,
    },
    summary: {
      loading: summaryLoadingState,
    },
  };
}

const reducer = (
  state: DocumentDetailsPageLoadingState,
  action: { type: 'stateUpdate'; input: LoadingReducerInput }
): DocumentDetailsPageLoadingState => {
  switch (action.type) {
    case 'stateUpdate':
      return getStateFromInput(state, action.input);
    default:
      throw new UnreachableError({
        message: 'Invalid action type',
        case: action.type,
      });
  }
};

const INITIAL_LOADING_STATE: DocumentDetailsPageLoadingState = {
  error: false,
  about: {
    loading: AboutLoadingState.Loading,
  },
  summary: {
    loading: SummaryLoadingState.Loading,
  },
};

// Computes the loading view state for the DocumentDetailsPage. Helps
// disambiguate between loading and polling states across the different
// modules on the page.
export function useDocumentDetailsPageLoadingState() {
  const [state, dispatch] = useReducer(reducer, INITIAL_LOADING_STATE);

  return {
    state,
    dispatch,
  };
}
