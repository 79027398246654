import { Box, Grid, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
} from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { Modal, ModalProps } from '@/components/modals/Modal/Modal';
import { useForm } from '@/components/react-hook-form';
import { COLORS } from '@/styles/tokens/colors';
import { EntityProposalAssetValuationProjectionType } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import { DEFAULT_TAX_DRAG } from '../../form/constants';
import { EditAssumptionsForm } from './constants';

export type EditAssumptionsModalProps = Omit<
  ModalProps,
  'children' | 'heading'
> & {
  onSubmit: (values: EditAssumptionsForm[string]) => void;
  assumptions: EditAssumptionsForm;
  entityId: string;
};

export function EditAssumptionsModal({
  onClose,
  onSubmit,
  isOpen,
  assumptions,
  entityId,
  ...modalProps
}: EditAssumptionsModalProps) {
  const formMethods = useForm<EditAssumptionsForm[string]>({
    defaultValues: {
      termEndAssetValuationType:
        EntityProposalAssetValuationProjectionType.ProjectedRateOfReturn,
      taxDragPercent: DEFAULT_TAX_DRAG,
    },
  });

  const { control, handleSubmit, reset } = formMethods;

  useEffect(() => {
    const defaultValues = !assumptions[entityId]
      ? {
          termEndAssetValuationType:
            EntityProposalAssetValuationProjectionType.ProjectedRateOfReturn,
          taxDragPercent: DEFAULT_TAX_DRAG,
        }
      : assumptions[entityId];

    reset(defaultValues);
  }, [reset, assumptions, entityId]);

  const onValidSubmit: SubmitHandler<EditAssumptionsForm[string]> = (
    values
  ) => {
    onSubmit(values);
    setTimeout(() => {
      reset();
    }, 200);
  };

  const onInvalidSubmit: SubmitErrorHandler<EditAssumptionsForm[string]> = (
    errors
  ) => {
    diagnostics.debug(errors);
  };

  const onFormSubmit = handleSubmit(onValidSubmit, onInvalidSubmit);

  const modalActions = (
    <>
      <Box>
        <Button onClick={onClose} size="sm" variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={onFormSubmit}
          size="sm"
          variant="primary"
          type="submit"
        >
          Apply assumptions
        </Button>
      </Box>
    </>
  );

  return (
    <FormProvider {...formMethods}>
      <Modal
        isOpen={isOpen}
        actions={modalActions}
        onClose={onClose}
        heading={'Edit assumptions'}
        {...modalProps}
      >
        <Stack
          component="form"
          noValidate
          onSubmit={onFormSubmit}
          flex={1}
          sx={{ minHeight: 450 }}
        >
          <Stack flexDirection="column">
            <Grid
              display="grid"
              gridTemplateColumns="1fr 88px"
              alignItems="center"
              borderBottom={`1px ${COLORS.GRAY[200]} solid`}
            >
              <Box p={2}>
                <Typography variant="h4">Term-end asset valuation</Typography>
                <Typography variant="subtitle2"></Typography>
                <Typography variant="subtitle2">
                  Projected return for single assets can be expressed as either
                  an annual return rate or an asset value at term end.
                </Typography>
              </Box>
              <FormAwareButtonGroup<EditAssumptionsForm[string]>
                control={control}
                fieldName="termEndAssetValuationType"
                required
                label=""
                options={[
                  {
                    value:
                      EntityProposalAssetValuationProjectionType.ProjectedRateOfReturn,
                    display: '%',
                  },
                  {
                    value:
                      EntityProposalAssetValuationProjectionType.ProjectedValue,
                    display: '$',
                  },
                ]}
              />
            </Grid>
            <Grid
              display="grid"
              gridTemplateColumns="1fr 100px"
              alignItems="center"
            >
              <Box p={2}>
                <Typography variant="h4">Tax drag</Typography>
                <Typography variant="subtitle2"></Typography>
                <Typography variant="subtitle2">
                  Tax drag is the amount of annual return assumed to be used to
                  pay taxes on assets held in taxable trusts or the
                  client&apos;s estate. Grantor trust assets are assumed to grow
                  unencumbered by taxes.
                </Typography>
              </Box>
              <FormAwarePercentInput<EditAssumptionsForm[string]>
                control={control}
                isDecimalJSInput
                fieldName="taxDragPercent"
                required
                label=""
              />
            </Grid>
          </Stack>
        </Stack>
      </Modal>
    </FormProvider>
  );
}
