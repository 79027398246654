// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EstateWaterfallComparisonModalAssumptions_EstateWaterfallFragment = { __typename?: 'EstateWaterfall', id: string, visualizationWithProjections: { __typename?: 'EstateWaterfallViz', _cacheKey: string, beforeFirstDeathTaxSummary?: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null } | null, firstDeathTaxSummary: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null }, secondDeathTaxSummary?: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null } | null } };

export type EstateWaterfallComparisonModalAssumptionsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.EstateWaterfallWhereInput>;
}>;


export type EstateWaterfallComparisonModalAssumptionsQuery = { __typename?: 'Query', estateWaterfalls: { __typename?: 'EstateWaterfallConnection', edges?: Array<{ __typename?: 'EstateWaterfallEdge', node?: { __typename?: 'EstateWaterfall', id: string, visualizationWithProjections: { __typename?: 'EstateWaterfallViz', _cacheKey: string, beforeFirstDeathTaxSummary?: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null } | null, firstDeathTaxSummary: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null }, secondDeathTaxSummary?: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null } | null } } | null } | null> | null } };

export const EstateWaterfallComparisonModalAssumptions_EstateWaterfallFragmentDoc = gql`
    fragment EstateWaterfallComparisonModalAssumptions_EstateWaterfall on EstateWaterfall {
  id
  visualizationWithProjections {
    beforeFirstDeathTaxSummary {
      stateTax {
        stateCode
        stateDisclosure
        _cacheKey
      }
      _cacheKey
    }
    firstDeathTaxSummary {
      stateTax {
        stateCode
        stateDisclosure
        _cacheKey
      }
      _cacheKey
    }
    secondDeathTaxSummary {
      stateTax {
        stateCode
        stateDisclosure
        _cacheKey
      }
      _cacheKey
    }
    _cacheKey
  }
}
    `;
export const EstateWaterfallComparisonModalAssumptionsDocument = gql`
    query EstateWaterfallComparisonModalAssumptions($where: EstateWaterfallWhereInput) {
  estateWaterfalls(where: $where) {
    edges {
      node {
        ...EstateWaterfallComparisonModalAssumptions_EstateWaterfall
      }
    }
  }
}
    ${EstateWaterfallComparisonModalAssumptions_EstateWaterfallFragmentDoc}`;

/**
 * __useEstateWaterfallComparisonModalAssumptionsQuery__
 *
 * To run a query within a React component, call `useEstateWaterfallComparisonModalAssumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstateWaterfallComparisonModalAssumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstateWaterfallComparisonModalAssumptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useEstateWaterfallComparisonModalAssumptionsQuery(baseOptions?: Apollo.QueryHookOptions<EstateWaterfallComparisonModalAssumptionsQuery, EstateWaterfallComparisonModalAssumptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EstateWaterfallComparisonModalAssumptionsQuery, EstateWaterfallComparisonModalAssumptionsQueryVariables>(EstateWaterfallComparisonModalAssumptionsDocument, options);
      }
export function useEstateWaterfallComparisonModalAssumptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EstateWaterfallComparisonModalAssumptionsQuery, EstateWaterfallComparisonModalAssumptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EstateWaterfallComparisonModalAssumptionsQuery, EstateWaterfallComparisonModalAssumptionsQueryVariables>(EstateWaterfallComparisonModalAssumptionsDocument, options);
        }
export function useEstateWaterfallComparisonModalAssumptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EstateWaterfallComparisonModalAssumptionsQuery, EstateWaterfallComparisonModalAssumptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EstateWaterfallComparisonModalAssumptionsQuery, EstateWaterfallComparisonModalAssumptionsQueryVariables>(EstateWaterfallComparisonModalAssumptionsDocument, options);
        }
export type EstateWaterfallComparisonModalAssumptionsQueryHookResult = ReturnType<typeof useEstateWaterfallComparisonModalAssumptionsQuery>;
export type EstateWaterfallComparisonModalAssumptionsLazyQueryHookResult = ReturnType<typeof useEstateWaterfallComparisonModalAssumptionsLazyQuery>;
export type EstateWaterfallComparisonModalAssumptionsSuspenseQueryHookResult = ReturnType<typeof useEstateWaterfallComparisonModalAssumptionsSuspenseQuery>;
export type EstateWaterfallComparisonModalAssumptionsQueryResult = Apollo.QueryResult<EstateWaterfallComparisonModalAssumptionsQuery, EstateWaterfallComparisonModalAssumptionsQueryVariables>;
export const Operations = {
  Query: {
    EstateWaterfallComparisonModalAssumptions: 'EstateWaterfallComparisonModalAssumptions' as const
  },
  Fragment: {
    EstateWaterfallComparisonModalAssumptions_EstateWaterfall: 'EstateWaterfallComparisonModalAssumptions_EstateWaterfall' as const
  }
}