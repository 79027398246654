import { FieldValues } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import { stateOptions } from '@/components/form/baseInputs/SelectInput/standardValues/stateOptions';
import {
  FormAwareTypeaheadSelectInput,
  FormAwareTypeaheadSelectInputProps,
} from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';

export type StateDropdownInputProps<FormShape extends FieldValues> =
  SetOptional<
    Omit<FormAwareTypeaheadSelectInputProps<FormShape>, 'options'>,
    'label'
  >;

export function StateDropdownInput<FormShape extends FieldValues>(
  props: StateDropdownInputProps<FormShape>
) {
  return (
    <FormAwareTypeaheadSelectInput<FormShape>
      label="State"
      options={stateOptions}
      dropdownProps={{ style: { width: 250 } }}
      {...props}
    />
  );
}
