// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { ClientProfileWithRelationshipsFragmentDoc, ClientProfileBasicFragmentDoc } from '../../graphql/ClientProfile.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeneficiaryFormDataQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.HouseholdWhereInput>;
}>;


export type BeneficiaryFormDataQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, clientProfiles?: Array<{ __typename?: 'ClientProfile', id: string, firstName: string, lastName: string, email?: string | null, dateOfBirth?: Time | null, isGrantor: boolean, isTrustee: boolean, isPrimary: boolean, isBeneficiary: boolean, displayName: string, legalName: string, middleName?: string | null, suffix?: string | null, relationships?: Array<{ __typename?: 'ClientProfileRelationship', id: string, type: Types.ClientProfileRelationshipType, toClientProfile: { __typename?: 'ClientProfile', id: string } }> | null, address?: { __typename?: 'Address', id: string, cityName?: string | null, stateCode: string } | null }> | null, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, legalName: string, firstName: string }> } | null } | null> | null } };


export const BeneficiaryFormDataDocument = gql`
    query BeneficiaryFormData($where: HouseholdWhereInput) {
  households(where: $where) {
    edges {
      node {
        id
        clientProfiles {
          ...ClientProfileWithRelationships
        }
        possiblePrimaryClients {
          ...ClientProfileBasic
        }
      }
    }
  }
}
    ${ClientProfileWithRelationshipsFragmentDoc}
${ClientProfileBasicFragmentDoc}`;

/**
 * __useBeneficiaryFormDataQuery__
 *
 * To run a query within a React component, call `useBeneficiaryFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBeneficiaryFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeneficiaryFormDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useBeneficiaryFormDataQuery(baseOptions?: Apollo.QueryHookOptions<BeneficiaryFormDataQuery, BeneficiaryFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BeneficiaryFormDataQuery, BeneficiaryFormDataQueryVariables>(BeneficiaryFormDataDocument, options);
      }
export function useBeneficiaryFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeneficiaryFormDataQuery, BeneficiaryFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BeneficiaryFormDataQuery, BeneficiaryFormDataQueryVariables>(BeneficiaryFormDataDocument, options);
        }
export function useBeneficiaryFormDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BeneficiaryFormDataQuery, BeneficiaryFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BeneficiaryFormDataQuery, BeneficiaryFormDataQueryVariables>(BeneficiaryFormDataDocument, options);
        }
export type BeneficiaryFormDataQueryHookResult = ReturnType<typeof useBeneficiaryFormDataQuery>;
export type BeneficiaryFormDataLazyQueryHookResult = ReturnType<typeof useBeneficiaryFormDataLazyQuery>;
export type BeneficiaryFormDataSuspenseQueryHookResult = ReturnType<typeof useBeneficiaryFormDataSuspenseQuery>;
export type BeneficiaryFormDataQueryResult = Apollo.QueryResult<BeneficiaryFormDataQuery, BeneficiaryFormDataQueryVariables>;
export const Operations = {
  Query: {
    BeneficiaryFormData: 'BeneficiaryFormData' as const
  }
}