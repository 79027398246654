import { ColumnWidths } from '@/components/layout/FormLayout';
import {
  AIOnboardingModalFormFieldKeys,
  AIOnboardingModalFormShape,
  AIOnboardingModalFormShapeKeys,
} from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';

export const AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS: {
  [K in AIOnboardingModalFormShapeKeys]: {
    columns: Record<
      AIOnboardingModalFormFieldKeys<K>,
      {
        columnHeader: string;
        columnWidth: ColumnWidths;
      }
    >;
  } & {
    styles: { rowPaddingVertical: number; columnPaddingHorizontal: number };
  };
} = {
  individuals: {
    columns: {
      firstName: {
        columnHeader: 'First name',
        columnWidth: 4,
      },
      middleName: {
        columnHeader: 'Middle name',
        columnWidth: 3,
      },
      lastName: {
        columnHeader: 'Last name',
        columnWidth: 4,
      },
      suffix: {
        columnHeader: 'Suffix',
        columnWidth: 1,
      },
      relationshipToPrimaryClient1: {
        columnHeader: '',
        columnWidth: 6,
      },
      relationshipToPrimaryClient2: {
        columnHeader: '',
        columnWidth: 6,
      },
    },
    styles: {
      rowPaddingVertical: 2,
      columnPaddingHorizontal: 1,
    },
  },
  organizations: {
    columns: {
      organizationKind: { columnHeader: 'Type', columnWidth: 4 },
      organizationName: {
        columnHeader: 'Name of organization',
        columnWidth: 8,
      },
    },
    styles: {
      rowPaddingVertical: 1,
      columnPaddingHorizontal: 3,
    },
  },
  entities: {
    columns: {
      entityType: { columnHeader: 'Type', columnWidth: 3 },
      entityName: { columnHeader: 'Name of entity', columnWidth: 6 },
      principalIds: {
        columnHeader: 'Grantor/owner/donor',
        columnWidth: 3,
      },
      principalId: {
        columnHeader: '',
        columnWidth: 3,
      },
      integrationEntityIds: { columnHeader: '', columnWidth: 12 },
    },
    styles: {
      rowPaddingVertical: 2,
      columnPaddingHorizontal: 1,
    },
  },
  testamentaryEntities: {
    columns: {
      testamentaryEntityKind: { columnHeader: 'Type', columnWidth: 4 },
      testamentaryEntityName: {
        columnHeader: 'Name of testamentary entity',
        columnWidth: 8,
      },
    },
    styles: {
      rowPaddingVertical: 1,
      columnPaddingHorizontal: 3,
    },
  },
};

/**
 * Default empty values of the form
 */
export function getDefaultData(): AIOnboardingModalFormShape {
  return {
    individuals: [],
    organizations: [],
    entities: [],
    testamentaryEntities: [],
  };
}
