import { Box, Stack, Typography } from '@mui/material';

import {
  Logomark,
  LogomarkSize,
  LogomarkVariant,
} from '@/components/brand/Logomark/Logomark';
import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

export interface PrintOnlyClientDashboardHeaderProps {
  clientName: string;
}
export function PrintOnlyClientDashboardHeader({
  clientName,
}: PrintOnlyClientDashboardHeaderProps) {
  return (
    <Stack direction="row" justifyContent="space-between" pb={6}>
      <Stack direction="column">
        <OversizedMetricItem value="Value creation report" title={clientName} />
        <Typography variant="caption">
          Generated {formatDateToMonDDYYYY(new Date())}
        </Typography>
      </Stack>
      <Box>
        <Logomark
          size={LogomarkSize.Wide}
          variant={LogomarkVariant.Primary}
          style={{
            width: 'auto !important',
            height: 'auto !important',
            maxWidth: '240px',
            maxHeight: '64px',
          }}
        />
      </Box>
    </Stack>
  );
}
