import '@xyflow/react/dist/style.css';

import { ThemedReactFlow } from './components/ThemedReactFlow';
import { useClassName } from './hooks/internal/useClassName';
import { useDefaultProps } from './hooks/internal/useDefaultProps';
import { useInternalOnNodeDrag } from './hooks/internal/useInternalOnNodeDrag';
import { useInternalOnNodeDragStop } from './hooks/internal/useInternalOnNodeDragStop';
import { useInternalOnNodesChange } from './hooks/internal/useInternalOnNodesChange';
import { usePreventScrolling } from './hooks/internal/usePreventScrolling';
import { useResetIsDirty } from './hooks/internal/useResetIsDirty';
import { useSlots } from './hooks/internal/useSlots';
import { useSectionNodeResizeListeners } from './nodes/SectionLabelNode/hooks';
import { FlowChartProps } from './types';

/**
 * @description Wrapper around ReactFlow providing basic functionality, styling, types, etc.
 */
export function FlowChart(componentProps: FlowChartProps) {
  const { internalProps, externalProps } = useDefaultProps(componentProps);

  const {
    children,
    onNodesChange: _oNC,
    onNodeDrag: _oND,
    onNodeDragStop: _oNDS,
    className: _className,
    preventScrolling: _preventScrolling,
    ...reactFlowProps
  } = internalProps;

  const { loading } = externalProps;

  const className = useClassName({ ...internalProps, ...externalProps });
  const slots = useSlots(externalProps);
  const preventScrolling = usePreventScrolling(internalProps);
  const onNodesChange = useInternalOnNodesChange(internalProps);
  const onNodeDrag = useInternalOnNodeDrag(internalProps);
  const onNodeDragStop = useInternalOnNodeDragStop(internalProps);

  useResetIsDirty();
  useSectionNodeResizeListeners();

  return (
    <ThemedReactFlow
      onNodesChange={onNodesChange}
      onNodeDrag={onNodeDrag}
      onNodeDragStop={onNodeDragStop}
      className={className}
      preventScrolling={preventScrolling}
      {...externalProps}
      {...reactFlowProps}
    >
      <>
        {<slots.MarkerDefinitions />}
        {loading && <slots.Loader />}
        {children}
      </>
    </ThemedReactFlow>
  );
}
