import Decimal from 'decimal.js';

import { PopperContent } from '@/components/poppers/PopperContent';
import { AfterDeath } from '@/types/schema';

import { EstateWaterfallCumulativeTaxTable } from '../EstateWaterfallCumulativeTaxTable/EstateWaterfallCumulativeTaxTable';

interface EstateWaterfallSummaryBarChartsTooltipProps {
  taxBeforeFirstDeath?: Decimal;
  taxAfterFirstDeath?: Decimal;
  taxAfterSecondDeath?: Decimal;
  afterDeath: AfterDeath;
  actions?: React.ReactNode;
}

export function EstateWaterfallSummaryBarChartsTooltip({
  taxBeforeFirstDeath = new Decimal(0),
  taxAfterFirstDeath = new Decimal(0),
  taxAfterSecondDeath = new Decimal(0),
  afterDeath,
  actions,
}: EstateWaterfallSummaryBarChartsTooltipProps) {
  return (
    <PopperContent
      body={
        <EstateWaterfallCumulativeTaxTable
          taxBeforeFirstDeath={taxBeforeFirstDeath}
          taxAfterFirstDeath={taxAfterFirstDeath}
          taxAfterSecondDeath={taxAfterSecondDeath}
          afterDeath={afterDeath}
        />
      }
      actions={actions}
      actionsSx={{
        marginBottom: 0,
        marginRight: 0,
      }}
    />
  );
}
