import { Box, Stack } from '@mui/material';
import { keyBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Control, FieldValues, useFieldArray, useWatch } from 'react-hook-form';

import { UserPlus01Icon } from '@/components/icons/UserPlus01Icon';
import { HeaderList } from '@/components/lists/HeaderList/HeaderList';
import {
  RichListItem,
  RichListItemProps,
} from '@/components/lists/RichListItem/RichListItem';
import { Loader } from '@/components/progress/Loader/Loader';
import { ArrayFieldNameFromFormShape } from '@/types/react-hook-form';
import { diagnostics } from '@/utils/diagnostics';

import { Button } from '../baseInputs/Button';
import { TypeaheadSelectInput } from '../baseInputs/TypeaheadSelectInput/TypeaheadSelectInput';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

export interface TypeaheadListInputOption
  extends Pick<
    RichListItemProps,
    'heading' | 'description' | 'additionalItems' | 'badgeText'
  > {
  id: string;
}

interface RemoveButtonProps {
  id: string;
  handleRemove: () => void;
  disabled?: boolean;
}

function DefaultRemoveButton({ handleRemove, disabled }: RemoveButtonProps) {
  return (
    <Button
      disabled={disabled}
      variant="transparent"
      size="sm"
      onClick={handleRemove}
    >
      Remove
    </Button>
  );
}

interface FormAwareTypeaheadSelectEntityListProps<
  FormShape extends FieldValues,
> {
  control: Control<FormShape>;
  emptyListPlaceholder: JSX.Element;
  heading?: string;
  options: TypeaheadListInputOption[];
  loading?: boolean;
  name: ArrayFieldNameFromFormShape<FormShape>;
  placeholder?: string;
  disabled?: boolean;
  RemoveButton?: React.ComponentType<RemoveButtonProps>;
  onAddItem?: (id: string, item: TypeaheadListInputOption) => void;
}

export function FormAwareTypeaheadListInput<FormShape extends FieldValues>({
  heading,
  emptyListPlaceholder,
  placeholder,
  name,
  options,
  loading,
  control,
  disabled,
  onAddItem,
  RemoveButton = DefaultRemoveButton,
}: FormAwareTypeaheadSelectEntityListProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const [selectedOptionValue, setSelectedOptionValue] = useState<string | null>(
    ''
  );
  const { append, remove } = useFieldArray<FormShape>({
    control: control,
    name: name,
    keyName: 'id',
  });

  const selectedValues = useWatch({
    name: name,
    control: control,
  }) as TypeaheadListInputOption[];

  // disable options that have already been selected
  const selectionAwareTypeaheadOptions = useMemo(() => {
    return options.map((option) => ({
      display: option.heading,
      value: option.id,
      disabled: selectedValues.some((value) => value.id === option.id),
    }));
  }, [selectedValues, options]);

  const optionsByValue = useMemo(() => {
    return keyBy(options, 'id');
  }, [options]);

  // handle synchronizing the clients that were selected in the typeahead with the form
  useEffect(() => {
    if (!selectedOptionValue) return;
    const relatedOption = optionsByValue[selectedOptionValue];
    if (!relatedOption) {
      diagnostics.warn('could not find option for selected value', {
        value: selectedOptionValue,
      });
      return;
    }

    onAddItem?.(relatedOption.id, relatedOption);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any -- linter refactor
    append(relatedOption as any);
    setSelectedOptionValue('');
  }, [
    append,
    onAddItem,
    optionsByValue,
    selectedOptionValue,
    setSelectedOptionValue,
  ]);

  return (
    <HeaderList
      heading={heading}
      belowHeadingControl={
        <Box mb={1}>
          <TypeaheadSelectInput
            disabled={disabledFromContext ?? (loading || disabled)}
            placeholder={placeholder}
            onChange={(_e, value) => setSelectedOptionValue(value)}
            startAdornment={
              loading ? (
                <Loader
                  boxProps={{
                    sx: {
                      display: 'flex',
                    },
                  }}
                  circularProgressProps={{ size: 15 }}
                />
              ) : (
                <UserPlus01Icon size={20} />
              )
            }
            label=""
            value={selectedOptionValue}
            options={selectionAwareTypeaheadOptions}
          />
        </Box>
      }
    >
      {selectedValues.length === 0
        ? emptyListPlaceholder
        : selectedValues.map(({ id, ...richListItem }, i) => (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              key={id}
            >
              <RichListItem {...richListItem} />
              <RemoveButton
                id={id}
                disabled={disabledFromContext}
                handleRemove={() => remove(i)}
              />
            </Stack>
          ))}
    </HeaderList>
  );
}
