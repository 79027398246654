import { Box } from '@mui/system';
import Decimal from 'decimal.js';
import { useCallback, useState } from 'react';
import { ArrayPath, Control, useFieldArray } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { EmptyPlaceholder } from '@/components/form/EmptyPlaceholder';
import { FormAwareListItemRepeater } from '@/components/form/formAwareInputs/FormAwareInputListItemRepeater';
import { FileShield02Icon } from '@/components/icons/FileShield02Icon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { COLORS } from '@/styles/tokens/colors';
import { SummaryBeneficiaryKindv2 } from '@/types/schema';
import { assertNonNil } from '@/utils/assertUtils';
import { formatPercent } from '@/utils/formatting/percent';

import { BeneficiariesPickerModal } from '../../BeneficiaryPickerModal/BeneficiariesPickerModal';
import { BeneficiariesSubformType, Fields, NAMESPACE } from '../types';
import { useBeneficiarySummariesv2LazyQuery } from './graphql/BeneficiarySummaries.generated';
import { ListItem } from './ListItem';

// this is a hack to get around the fact that useFieldArray
// loses the type information of the fields array
// with the generic type arguments
interface FormStubType {
  beneficiariesSubform: BeneficiariesSubformType;
}

interface Props {
  control: Control<Fields>;
  householdId: string;
  grantorProfileId: string;
  formFields: BeneficiariesSubformType;
  emptyStateVariant: 'fancy' | 'plain';
  beneficiaryPickerModalVariant?: 'canNotCreateItems' | 'canCreateItems';
}

export function EditableList({
  control,
  householdId,
  grantorProfileId,
  formFields,
  emptyStateVariant,
  beneficiaryPickerModalVariant = 'canNotCreateItems',
}: Props) {
  const [getBeneficiarySummaries, { error: queryError }] =
    useBeneficiarySummariesv2LazyQuery({
      fetchPolicy: 'no-cache',
    });

  const { fields, remove, append } = useFieldArray<
    FormStubType,
    ArrayPath<FormStubType>,
    '_id'
  >({
    control,
    name: `${NAMESPACE}.beneficiaries`,
  });

  const appendNewEntitiesToList = useCallback(
    async function appendNewBeneficiaries(
      selectedTrustIds: string[],
      selectedIndividualIds: string[],
      selectedOrganizationIds: string[]
    ) {
      const selectedBeneficiaryIds = [
        ...selectedTrustIds,
        ...selectedIndividualIds,
        ...selectedOrganizationIds,
      ];
      const currentIds = fields.map((f) => f.beneficiaryId);
      const newIds = selectedBeneficiaryIds.filter(
        (id) => !currentIds.includes(id)
      );

      const removedItemIndexes = currentIds
        .map((id, idx) => {
          if (!selectedBeneficiaryIds.includes(id)) {
            return idx;
          }

          return null;
        })
        .filter((idx) => idx !== null);

      removedItemIndexes.forEach((itemIndex) => {
        if (itemIndex !== null) {
          remove(itemIndex);
        }
      });

      const { data } = await getBeneficiarySummaries({
        variables: {
          grantorClientProfileId: grantorProfileId,
          inputs: newIds.map((id) => {
            if (selectedTrustIds.includes(id)) {
              return { id, kind: SummaryBeneficiaryKindv2.Entity };
            } else if (selectedOrganizationIds.includes(id)) {
              return { id, kind: SummaryBeneficiaryKindv2.Organization };
            }

            return { id, kind: SummaryBeneficiaryKindv2.ClientProfile };
          }),
        },
      });

      const newBeneficiaries = //todo fix the "type" thing
        data?.beneficiarySummariesv2.map((summary) => {
          if (!summary) {
            return null;
          }

          return {
            beneficiaryId: summary.id,
            percent: new Decimal(100),
            displayType: summary.displayType,
            type: summary.kind,
            heading: summary.displayName,
            badgeText: summary.badgeText ?? '',
            additionalItems: summary.additionalContext,
          };
        }) ?? [];

      if (newBeneficiaries.length === 0) {
        return;
      }

      append(
        newBeneficiaries.filter((b): b is NonNullable<typeof b> => b !== null)
      );
    },
    [fields, append, remove, getBeneficiarySummaries, grantorProfileId]
  );

  const sum = formFields.beneficiaries.reduce(
    (acc, cur) => acc.plus(cur.percent ?? new Decimal(0)),
    new Decimal(0)
  );

  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      {queryError && (
        <Callout severity="error">
          There was an issue fetching data. If the problem persists, please
          refresh the page and try again.
        </Callout>
      )}
      <BeneficiariesPickerModal
        variant={beneficiaryPickerModalVariant}
        householdId={householdId}
        grantorProfileId={grantorProfileId}
        isOpen={isModalOpen}
        setIsOpen={setModalOpen}
        selectedIds={fields.map((b) => b.beneficiaryId)}
        returnSelectedIds={({ trusts, individuals }) => {
          void appendNewEntitiesToList(trusts, individuals, []); // TODO: add organizations
        }}
      />
      {fields.length === 0 && (
        <>
          {emptyStateVariant === 'fancy' ? (
            <EmptyPlaceholder
              actionComponent={
                <Box display={'flex'} justifyContent={'center'}>
                  <Button
                    variant="primary"
                    size="sm"
                    data-it="BasicInformationDesigner-emptyAddNewTrustOrBeneficiary"
                    name={'AddBeneficiary'}
                    onClick={() => setModalOpen(true)}
                  >
                    Add a beneficiary
                  </Button>
                </Box>
              }
            >
              <>
                <Box mb={2} textAlign="center">
                  <FileShield02Icon color={COLORS.GRAY[400]} size={60} />
                  <Box mt={2}>
                    Add individual beneficiaries or trusts that will be used as
                    a remainder beneficiaries for this GRAT.
                  </Box>
                </Box>
              </>
            </EmptyPlaceholder>
          ) : (
            <Callout severity="error">
              You have no beneficiaries set up for this GRAT. Please add
              beneficiaries.
            </Callout>
          )}
        </>
      )}
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareListItemRepeater
            fields={fields}
            remove={remove}
            shouldInit
            addAnotherItemText="Add a beneficiary"
            sumRowText={`${formatPercent(new Decimal(sum), 2)}%`}
            handleAddItem={() => {
              setModalOpen(true);
            }}
            hideAddButton={fields.length === 0}
            render={(idx: number) => {
              const {
                heading,
                badgeText,
                displayType,
                additionalItems,
                beneficiaryId,
              } = assertNonNil(fields[idx], `No field for ${idx}`);

              return (
                <ListItem
                  id={beneficiaryId}
                  heading={heading}
                  badgeText={badgeText}
                  type={displayType}
                  additionalItems={additionalItems}
                  control={control}
                  itemIdx={idx}
                />
              );
            }}
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </>
  );
}
