import { Box } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { includes } from 'lodash';
import { useState } from 'react';

import { FormAwareTypeaheadMultiSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadMultiSelectInput';
import { useFormContext } from '@/components/react-hook-form';
import { CellContainer } from '@/components/tables/DataTable/components/cells';
import { BalanceSheetCategoryType } from '@/types/schema';

import {
  BalanceSheetCategoriesFormPaths,
  RowData,
} from '../AdminBalanceSheetConfigurationPage.types';
import { useAvailableAssetClasses } from '../hooks/useAvailableAssetClasses';

export function BalanceSheetCategoryAssetClassesCell({
  row,
  colDef,
}: GridRenderCellParams<RowData>) {
  const { control } = useFormContext();
  const [inputValue, setInputValue] = useState('');

  const categoryId = row.id;

  const { options, loading } = useAvailableAssetClasses({
    currentRowId: categoryId,
    includeSystemClasses: row.type === BalanceSheetCategoryType.Unspecified,
  });

  // don't allow custom associations of assets classes for non-asset categories
  // like liabilities or receivables
  if (
    !includes(
      [BalanceSheetCategoryType.Asset, BalanceSheetCategoryType.Unspecified],
      row.type
    )
  ) {
    return <CellContainer />;
  }

  return (
    <CellContainer align={colDef.align}>
      <Box width="100%">
        <FormAwareTypeaheadMultiSelectInput
          fieldName={
            `balanceSheetCategoriesById.${categoryId}.assetClassIds` as const satisfies BalanceSheetCategoriesFormPaths
          }
          disabled={row.type !== BalanceSheetCategoryType.Asset}
          label="Associated asset classes"
          hideLabel
          control={control}
          inputValue={inputValue}
          onInputChange={setInputValue}
          options={options}
          loading={loading}
        />
      </Box>
    </CellContainer>
  );
}
