import { Box } from '@mui/material';

import { EntityDetail_GratTrustFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { ClientPresentationBundleTypes } from '@/modules/presentation/clientPresentation/clientPresentation.types';
import { useUnguardedClientPresentationDesignerContext } from '@/modules/presentation/clientPresentation/contexts/clientPresentationDesigner.context';
import { useRegisterBundle } from '@/modules/presentation/clientPresentation/hooks/useRegisterBundle';

import { DispositionScenarioSlide } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide';
import { getFilteredDispositionSlideProps } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide.utils';
import {
  EntityPresentationBundleProps,
  EntityPresentationSlideType,
  EntitySlideMap,
} from '../../entityPresentations.types';
import {
  generateEntityBundleId,
  getPresentationEntitySlideProps,
} from '../../entityPresentations.utils';
import { useOrderedEntitySlides } from '../../hooks/useFilteredEntitySlides';
import { GRATTrustBeneficiariesSlide } from './GRATTrust.Beneficiaries';
import { GRATTrustFiduciariesSlide } from './GRATTrust.Fiduciaries';
import { GRATTrustOverviewSlide } from './GRATTrust.Overview';

type RevocableTrustPresentationBundleProps =
  EntityPresentationBundleProps<EntityDetail_GratTrustFragment>;

export function GRATTrustPresentationBundle({
  slideTypes,
  SlideWrapper = Box,
  dispositionScenarios,
  ...slideProps
}: RevocableTrustPresentationBundleProps) {
  const { entityId } = slideProps;
  const bundleId = generateEntityBundleId(entityId);
  useRegisterBundle({
    type: ClientPresentationBundleTypes.ENTITY,
    id: bundleId,
    displayName: slideProps.subtype.displayName,
    identifier: entityId,
  });
  const grantors =
    slideProps.entity.household.possiblePrimaryClients?.map((grantor) => ({
      displayName: grantor.displayName,
      id: grantor.id,
    })) ?? [];

  const SLIDE_MAP: EntitySlideMap = {
    [EntityPresentationSlideType.OVERVIEW]: (
      <GRATTrustOverviewSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.OVERVIEW,
          slideTypes
        )}
        {...slideProps}
      />
    ),
    [EntityPresentationSlideType.TRUST_FIDUCIARIES]: (
      <GRATTrustFiduciariesSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.TRUST_FIDUCIARIES,
          slideTypes
        )}
        {...slideProps}
      />
    ),
    [EntityPresentationSlideType.BENEFICIARIES]: (
      <GRATTrustBeneficiariesSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.BENEFICIARIES,
          slideTypes
        )}
        {...slideProps}
      />
    ),
    [EntityPresentationSlideType.DISPOSITIVE_PROVISIONS]:
      getFilteredDispositionSlideProps({
        dispositionScenarios,
        entityId,
        slideTypes,
      }).map((props, i) => (
        <DispositionScenarioSlide
          {...props}
          key={i}
          displayName={slideProps.subtype.displayName}
          entity={slideProps.entity}
          grantors={grantors}
        />
      )),
  };

  const slides = useOrderedEntitySlides(slideTypes, SLIDE_MAP);
  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  if (!shouldShowItem(entityId)) {
    return null;
  }

  return (
    <>
      {slides.map((slide, index) => {
        return <SlideWrapper key={index}>{slide}</SlideWrapper>;
      })}
    </>
  );
}
