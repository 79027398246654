import { Box, Card, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo, useState } from 'react';

import { RibbonHorizontal } from '@/components/display/Ribbon/RibbonHorizontal';
import { LinkButton } from '@/components/form/baseInputs/Link';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { ComparisonChart } from '@/modules/proposal/Comparison';
import { PreTaxPostTax } from '@/modules/proposal/hooks/useProjectionForScenario';
import { COLORS } from '@/styles/tokens/colors';

import { ProposalScenarioFragment } from '../graphql/ProposalScenarioFragment.generated';
import { VisualizationInput } from '../types';
import { getBeneficiariesFromProposal } from '../utils';
import { BeneficiariesBreakdown } from './BeneficiariesBreakdown';
import { ScenarioComparisonTable } from './ScenarioComparisonTable';
import { ScenarioReturns } from './ScenarioReturns';

const COLUMN_HEIGHT_PX = 200 as const;
const COLUMN_WIDTH_PX = 73 as const;

interface Props {
  chartValues: {
    preTax: {
      noPlan: VisualizationInput;
      withPlan: VisualizationInput;
    };
    postTax: {
      noPlan: VisualizationInput;
      withPlan: VisualizationInput;
    };
  };
  maxPositive: Decimal;
  scenarioNumber: number;
  currentTab: Exclude<PreTaxPostTax, 'inTermCAGRV2'>;
  entityId: string;
  beneficiaries: ReturnType<typeof getBeneficiariesFromProposal>;
  gratProposalScenario: ProposalScenarioFragment['gratProposalScenario'];
  taxDrag: Decimal;
  maxHeight?: {
    preTax: number | null;
    postTax: number | null;
  };
  setMaxHeight?: React.Dispatch<
    React.SetStateAction<{
      preTax: number | null;
      postTax: number | null;
    }>
  >;
  maxProjectionValue?: Decimal;
}

export function ScenarioSummaryCompare({
  chartValues,
  maxPositive,
  scenarioNumber,
  currentTab,
  entityId,
  beneficiaries,
  gratProposalScenario,
  taxDrag,
  maxHeight,
  setMaxHeight,
  maxProjectionValue,
}: Props) {
  const theme = useTheme();
  const viewOnly = useViewOnly();

  const [showBeneficiaries, setShowBeneficiaries] = useState<boolean>(false);

  const chartHeight = useMemo(() => {
    if (viewOnly) {
      return undefined;
    }

    return maxHeight?.[currentTab] ? `${maxHeight[currentTab]}px` : 'unset';
  }, [currentTab, maxHeight, viewOnly]);

  return (
    <Card
      sx={{
        width: '408px',
        overflow: 'visible',
      }}
    >
      <Stack position="relative">
        <RibbonHorizontal
          ribbonColor={theme.palette.primary.main}
          ribbonText={`Scenario ${scenarioNumber}`}
        />
        <Stack mt={viewOnly ? 1 : 8}>
          <ScenarioReturns
            inTermRoR={gratProposalScenario?.inTermCAGRV2 ?? new Decimal(0)}
            afterTermTaxFreeInTrust={
              gratProposalScenario?.projectedAnnualReturnAfterTerm ??
              new Decimal(0)
            }
            afterTermTaxedInEstate={
              (
                gratProposalScenario?.projectedAnnualReturnAfterTerm ??
                new Decimal(0)
              ).times(
                gratProposalScenario?.projectedAnnualReturnAfterTerm?.isNegative()
                  ? new Decimal(1).plus(taxDrag.dividedBy(100))
                  : new Decimal(1).minus(taxDrag.dividedBy(100))
              ) ?? new Decimal(0)
            }
          />
        </Stack>
        <Stack direction="row" mt={viewOnly ? 1 : 2} mb={viewOnly ? 1 : 2}>
          <Stack
            direction="column"
            width="100%"
            mx={4}
            spacing={1}
            height={chartHeight}
          >
            <Box
              sx={{ borderBottom: `solid ${COLORS.NAVY[600]} 2px` }}
              pb="4px"
            >
              <Box width="50%">
                <Typography color={COLORS.NAVY[600]} variant="label">
                  Before estate tax
                </Typography>
              </Box>
            </Box>

            <div
              ref={(el: HTMLDivElement) => {
                if (setMaxHeight && maxHeight) {
                  const height = el?.getBoundingClientRect().height ?? 0;
                  const thisTabMaxHeight = maxHeight[currentTab] ?? 0;
                  if (height > thisTabMaxHeight) {
                    setMaxHeight((prev) => ({
                      ...prev,
                      [currentTab]: height,
                    }));
                  }
                }
              }}
            >
              <ComparisonChart
                noPlan={chartValues[currentTab].noPlan}
                withPlan={chartValues[currentTab].withPlan}
                columnHeight={COLUMN_HEIGHT_PX}
                columnWidth={COLUMN_WIDTH_PX}
                scenario={currentTab === 'postTax' ? 'post-tax' : 'pre-tax'}
                maxInGroup={maxPositive}
                maxProjectionValue={maxProjectionValue}
              />
            </div>
          </Stack>
        </Stack>
      </Stack>

      {!showBeneficiaries && (
        <ScenarioComparisonTable chartValues={chartValues} />
      )}
      {showBeneficiaries && (
        <BeneficiariesBreakdown
          chartValues={chartValues}
          entityId={entityId}
          beneficiaries={beneficiaries}
        />
      )}
      <Stack direction="row" justifyContent="end" m={2}>
        <LinkButton
          display={
            showBeneficiaries
              ? 'Back to summary view'
              : 'View by individual beneficiary'
          }
          onClick={() => setShowBeneficiaries(!showBeneficiaries)}
        />
      </Stack>
    </Card>
  );
}
