import { isEmpty } from 'lodash';
import React from 'react';

import { AppNotifications } from '@/components/AppNotifications/AppNotifications';
import { AIOnboardingModal } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal';
import {
  AsyncJobNotificationInfo,
  AsyncJobNotificationInfoType,
} from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';
import { AIOnboardingNotificationItem } from '@/modules/asyncJobs/AsyncJobNotifications/notificationTypes/aiOnboarding/AIOnboardingNotificationItem';
import { UnreachableError } from '@/utils/errors';

interface AsyncJobNotificationsProps {
  colorTheme: 'light' | 'dark';
}

export function AsyncJobNotifications({
  colorTheme,
}: AsyncJobNotificationsProps) {
  const { notifications, newNotificationPopup } =
    useAsyncJobNotificationsContext();

  const [lastClickedNotification, setLastClickedNotification] =
    React.useState<AsyncJobNotificationInfo | null>(null);

  if (isEmpty(notifications)) {
    return null;
  }

  return (
    <>
      <AppNotifications
        colorTheme={colorTheme}
        count={notifications.length}
        newNotificationPopup={newNotificationPopup}
      >
        {notifications.map((notification) => (
          <AsyncJobNotificationItem
            key={notification.id}
            notification={notification}
            onCompletedActionClick={() =>
              setLastClickedNotification(notification)
            }
          />
        ))}
      </AppNotifications>
      {lastClickedNotification &&
        lastClickedNotification.type ===
          AsyncJobNotificationInfoType.AI_ONBOARDING && (
          <AIOnboardingModal
            householdId={lastClickedNotification.household.id}
            kgRootId={lastClickedNotification.id}
            isOpen={!!lastClickedNotification}
            onClose={() => setLastClickedNotification(null)}
          />
        )}
    </>
  );
}

/**
 * @description Renders the appropriate notification item based on the notification type
 * @param notification
 * @param onCompletedActionClick
 * @constructor
 */
function AsyncJobNotificationItem({
  notification,
  onCompletedActionClick,
}: {
  notification: AsyncJobNotificationInfo;
  onCompletedActionClick: () => void;
}) {
  const { type } = notification;

  switch (type) {
    case AsyncJobNotificationInfoType.AI_ONBOARDING:
      return (
        <AIOnboardingNotificationItem
          notification={notification}
          onCompletedActionClick={onCompletedActionClick}
        />
      );
    default:
      throw new UnreachableError({
        case: type,
        message: `Unknown notification menu item type: ${type}`,
      });
  }
}
