import { Theme } from '@mui/material';

import { getContrastTextForColor } from '@/styles/themes/common';

import { TileVariant } from './types';

export function getTextColorForVariant(theme: Theme, variant: TileVariant) {
  const variantToColorMap: Partial<Record<TileVariant, string>> = {
    [TileVariant.Primary]: getContrastTextForColor(
      theme.palette.dataVisualizationPrimary.lighter
    ),
    [TileVariant.Secondary]: getContrastTextForColor(
      theme.palette.dataVisualizationSecondary.lighter
    ),
    [TileVariant.Tertiary]: getContrastTextForColor(
      theme.palette.dataVisualizationTertiary.lighter
    ),
    [TileVariant.Destructive]: getContrastTextForColor(
      theme.palette.dataVisualizationNegative.lighter
    ),
  };
  return variantToColorMap[variant] ?? variantToColorMap[TileVariant.Primary];
}
