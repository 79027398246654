// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InferDocumentTypesQueryVariables = Types.Exact<{
  input: Array<Types.InferDocumentTypeInput> | Types.InferDocumentTypeInput;
}>;


export type InferDocumentTypesQuery = { __typename?: 'Query', inferDocumentTypes: Array<{ __typename?: 'InferDocumentTypeOutput', id: string, fileName: string, documentType: Types.DocumentType }> };


export const InferDocumentTypesDocument = gql`
    query InferDocumentTypes($input: [InferDocumentTypeInput!]!) {
  inferDocumentTypes(input: $input) {
    id
    fileName
    documentType
  }
}
    `;

/**
 * __useInferDocumentTypesQuery__
 *
 * To run a query within a React component, call `useInferDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInferDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInferDocumentTypesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInferDocumentTypesQuery(baseOptions: Apollo.QueryHookOptions<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>(InferDocumentTypesDocument, options);
      }
export function useInferDocumentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>(InferDocumentTypesDocument, options);
        }
export function useInferDocumentTypesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>(InferDocumentTypesDocument, options);
        }
export type InferDocumentTypesQueryHookResult = ReturnType<typeof useInferDocumentTypesQuery>;
export type InferDocumentTypesLazyQueryHookResult = ReturnType<typeof useInferDocumentTypesLazyQuery>;
export type InferDocumentTypesSuspenseQueryHookResult = ReturnType<typeof useInferDocumentTypesSuspenseQuery>;
export type InferDocumentTypesQueryResult = Apollo.QueryResult<InferDocumentTypesQuery, InferDocumentTypesQueryVariables>;
export const Operations = {
  Query: {
    InferDocumentTypes: 'InferDocumentTypes' as const
  }
}