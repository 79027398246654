import Decimal from 'decimal.js';

import { StateCode } from '@/types/schema';
import { PathsOf } from '@/types/subform';

import { Fields as BasicAssetsSubformFields } from '../../BasicAssetsSubform/BasicAssetsSubform.types';
import { Fields as BusinessEntityDetailsSubformFields } from '../../BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import {
  EstateInclusionStatus,
  TaxStatusSubformTypeStateTax,
} from '../../TaxStatusSubform/TaxStatusSubform.types';
import { EntityType } from '../../types/EntityType';

export const CREATE_ENTITY_SHORT_FORM_NAMESPACE =
  'createEntityShortForm' as const;

export interface EntityShortFormPrincipal {
  isIncluded: boolean;
  ownershipPercentage: Decimal | null;
}

// this is a map from the clientProfileId to the principal object
export type PrincipalsOwnershipMap = Record<string, EntityShortFormPrincipal>;

export interface EntityShortFormFields {
  name: string;
  entityType: EntityType | '';
  // using principals as a generic term for grantor/owner/donor
  // it also maps to the backend type name for this
  // the type is a map of the clientProfileId to a bool of whether they're included.
  // this is only used for multi-principal entity types
  principals: PrincipalsOwnershipMap;
  // this is used for single-principal entity types
  selectedSinglePrincipal: string;
  // this is used to expose an error-aware calculated value for validation
  _ownershipSum: Decimal | null;
  estateInclusionStatus: EstateInclusionStatus;
  stateTax: Record<StateCode, TaxStatusSubformTypeStateTax>;
  // This is the list of document ids that are associated with the entity
  documentIds?: string[];
  // This is the default document when viewing the entity
  defaultDocumentId?: string;
}

export interface EntityShortFormShape
  extends BasicAssetsSubformFields,
    BusinessEntityDetailsSubformFields {
  [CREATE_ENTITY_SHORT_FORM_NAMESPACE]: EntityShortFormFields;
}

export type EntityShortFormPaths = PathsOf<EntityShortFormShape>;
