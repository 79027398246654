// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTransferGiftTypeOptionsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.EstateWaterfallWhereInput>;
}>;


export type GetTransferGiftTypeOptionsQuery = { __typename?: 'Query', estateWaterfalls: { __typename?: 'EstateWaterfallConnection', edges?: Array<{ __typename?: 'EstateWaterfallEdge', node?: { __typename?: 'EstateWaterfall', id: string, household: { __typename?: 'Household', id: string, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string }> } } | null } | null> | null } };


export const GetTransferGiftTypeOptionsDocument = gql`
    query GetTransferGiftTypeOptions($where: EstateWaterfallWhereInput) {
  estateWaterfalls(where: $where) {
    edges {
      node {
        id
        household {
          id
          possiblePrimaryClients {
            id
            displayName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetTransferGiftTypeOptionsQuery__
 *
 * To run a query within a React component, call `useGetTransferGiftTypeOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransferGiftTypeOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransferGiftTypeOptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTransferGiftTypeOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetTransferGiftTypeOptionsQuery, GetTransferGiftTypeOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransferGiftTypeOptionsQuery, GetTransferGiftTypeOptionsQueryVariables>(GetTransferGiftTypeOptionsDocument, options);
      }
export function useGetTransferGiftTypeOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransferGiftTypeOptionsQuery, GetTransferGiftTypeOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransferGiftTypeOptionsQuery, GetTransferGiftTypeOptionsQueryVariables>(GetTransferGiftTypeOptionsDocument, options);
        }
export function useGetTransferGiftTypeOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetTransferGiftTypeOptionsQuery, GetTransferGiftTypeOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTransferGiftTypeOptionsQuery, GetTransferGiftTypeOptionsQueryVariables>(GetTransferGiftTypeOptionsDocument, options);
        }
export type GetTransferGiftTypeOptionsQueryHookResult = ReturnType<typeof useGetTransferGiftTypeOptionsQuery>;
export type GetTransferGiftTypeOptionsLazyQueryHookResult = ReturnType<typeof useGetTransferGiftTypeOptionsLazyQuery>;
export type GetTransferGiftTypeOptionsSuspenseQueryHookResult = ReturnType<typeof useGetTransferGiftTypeOptionsSuspenseQuery>;
export type GetTransferGiftTypeOptionsQueryResult = Apollo.QueryResult<GetTransferGiftTypeOptionsQuery, GetTransferGiftTypeOptionsQueryVariables>;
export const Operations = {
  Query: {
    GetTransferGiftTypeOptions: 'GetTransferGiftTypeOptions' as const
  }
}