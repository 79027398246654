import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

export interface SectionHeaderProps {
  title: ReactNode;
  rightHeaderContent?: ReactNode;
}

export function SectionHeader({
  title,
  rightHeaderContent,
}: SectionHeaderProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ height: '40px' }}
    >
      <Typography variant="h2">{title}</Typography>
      {rightHeaderContent}
    </Stack>
  );
}
