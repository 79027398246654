import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useWatch } from 'react-hook-form';

import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';
import {
  DisplayTable,
  DisplayTableColumn,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { DisplayTableSummaryRow } from '@/components/tables/DisplayTable/DisplayTableSummaryRow';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { formatCurrency } from '@/utils/formatting/currency';

import { useIsShortForm } from '../EntityShortFormModal/CreateEntityShortForm/context/CreateEntityShortForm.context';
import {
  Fields as InsurancePolicyFields,
  INSURANCE_POLICIES_DETAILS_SUBFORM,
} from '../InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform.types';
import {
  BASIC_ASSETS_SUBFORM,
  BasicAssetsSubformProperties,
  Fields,
  SubformField,
} from './BasicAssetsSubform.types';

const COLUMNS: DisplayTableColumn[] = [
  {
    headerName: '',
    flex: 2,
    width: '45%',
  },
  {
    headerName: 'Valuation date',
    flex: 1,
    align: 'right',
    width: '30%',
  },
  {
    headerName: 'Value',
    flex: 1,
    align: 'right',
    width: '25%',
  },
];

export function useTotalCashValue(): Decimal {
  const { control: insurancePolicyControl } =
    useFormContext<InsurancePolicyFields>();

  const policies =
    useWatch({
      control: insurancePolicyControl,
      name: `${INSURANCE_POLICIES_DETAILS_SUBFORM}.policies`,
    }) ?? [];

  return policies.reduce((acc, policy) => {
    return acc.add(policy.cashValue || new Decimal(0));
  }, new Decimal(0));
}

function CashValueTooltip() {
  const isInsideShortForm = useIsShortForm();

  const tooltipContent = isInsideShortForm
    ? 'Policies can be added once the ILIT is created'
    : 'Update the current cash value on each policy in the section above';

  return (
    <ContextualHelpTooltip>
      <PopperContent body={tooltipContent} />
    </ContextualHelpTooltip>
  );
}

export function ILITInsurancePolicyTable() {
  const { control } = useFormContext<Fields>();
  const totalPoliciesCashValue = useTotalCashValue();
  const isInsideShortForm = useIsShortForm();
  const nonInsuranceAssets = useWatch<Fields>({
    control,
    name: `${BASIC_ASSETS_SUBFORM}.basicAssets.currentMarketValue` as const satisfies SubformField,
  }) as BasicAssetsSubformProperties['currentMarketValue'];
  const totalCashValue = totalPoliciesCashValue.add(
    nonInsuranceAssets || new Decimal(0)
  );

  return (
    <DisplayTable
      containerSx={{ width: '100%' }}
      columns={COLUMNS}
      Footer={
        <DisplayTableSummaryRow
          title="Total cash value of ILIT"
          // need an extra empty column here to push the cash value amount to the end
          // because there are three columns in the ILIT scenario, but only two in the
          // directly-held scenario. (this looks correct in both cases)
          values={['', formatCurrency(totalCashValue)]}
        />
      }
    >
      <StyledTableRow>
        <StyledTableCell>
          <Stack direction="row">
            <Typography>Cash value of policies held</Typography>
            <CashValueTooltip />
          </Stack>
        </StyledTableCell>
        <StyledTableCell align="right" />
        <StyledTableCell align="right">
          {isInsideShortForm
            ? EMPTY_CONTENT_HYPHEN
            : formatCurrency(totalPoliciesCashValue)}
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell>
          <Stack direction="row">
            <Typography>Non-insurance assets</Typography>
          </Stack>
        </StyledTableCell>
        <StyledTableCell align="right">
          <FormAwareDatePickerInput<Fields>
            fieldName={
              `${BASIC_ASSETS_SUBFORM}.basicAssets.dateOfValuation` as const satisfies SubformField
            }
            control={control}
            label="Date of non-insurance assets"
            hideLabel
          />
        </StyledTableCell>
        <StyledTableCell align="right">
          <FormAwareCurrencyInput<Fields>
            fieldName={
              `${BASIC_ASSETS_SUBFORM}.basicAssets.currentMarketValue` as const satisfies SubformField
            }
            control={control}
            label="Non-insurance asset amount"
            hideLabel
            isDecimalJSInput
          />
        </StyledTableCell>
      </StyledTableRow>
    </DisplayTable>
  );
}
