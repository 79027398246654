import { Box, Stack, Typography } from '@mui/material';
import React, { ComponentProps, ReactNode } from 'react';

import { COLORS } from '@/styles/tokens/colors';

import { Legend, LegendSection } from '../Legend/Legend';
import {
  Section,
  StackedHorizontalBar,
  StackedHorizontalBarProps,
} from '../StackedHorizontalBar/StackedHorizontalBar';

export interface StackedHorizontalBarGroupSection extends Section {
  groupName: string;
}

// doing it this way so either all the bars have labels or none of the bars have labels
interface StackedHorizontalBarGroupBase extends StackedHorizontalBarProps {
  sections: StackedHorizontalBarGroupSection[];
}

export interface StackedHorizontalBarsWithLabels
  extends StackedHorizontalBarGroupBase {
  label: ReactNode;
}

export interface StackedHorizontalBarsWithoutLabels
  extends StackedHorizontalBarGroupBase {
  label?: never;
}

interface PropsBase {
  groupLabel?: string | number | JSX.Element;
  headerVariant?: ComponentProps<typeof Typography>['variant'];
  headerSpacing?: number;
  fillStackedBars?: boolean;
  showBarLabels?: boolean;
  showLegend?: boolean;
}

interface PropsWithLabels extends PropsBase {
  bars: StackedHorizontalBarsWithLabels[];
}

interface PropsWithoutLabels extends PropsBase {
  bars: StackedHorizontalBarsWithoutLabels[];
}

export type StackedHorizontalBarGroupProps =
  | PropsWithLabels
  | PropsWithoutLabels;

export function StackedHorizontalBarGroup({
  bars,
  groupLabel,
  headerVariant = 'h2',
  headerSpacing = 3,
  fillStackedBars = false,
  showBarLabels = true,
  showLegend = true,
}: StackedHorizontalBarGroupProps) {
  const barTotals = bars.map<number>(({ sections }) => {
    const sectionTotal = sections.reduce((acc, { value }) => {
      return acc + value;
    }, 0);
    return sectionTotal;
  });
  const maxTotal = Math.max(...barTotals);

  let legendSections: LegendSection[] = [];
  if (showLegend) {
    legendSections = bars.reduce<LegendSection[]>((acc, bar) => {
      bar.sections.forEach((section) => {
        // curry only the groups not already on the list; take the first color definition
        const existingIndex = acc.findIndex(
          (value) => value.label === section.groupName
        );

        if (existingIndex === -1) {
          acc.push({
            label: section.groupName,
            color: section.color,
          });
        }
      });
      return acc;
    }, []);
  }

  return (
    <Stack spacing={headerSpacing}>
      <Typography variant={headerVariant}>{groupLabel}</Typography>
      <Stack width="100%" spacing={1}>
        {bars.map((bar, index) => (
          <Stack
            key={`bar-${index}`}
            width="100%"
            direction="row"
            alignItems="center"
          >
            {bar.label && showBarLabels && (
              <Typography
                variant="h6"
                sx={{
                  breakInside: 'avoid',
                  width: '10%',
                  flexGrow: 0,
                  flexShrink: 0,
                  color: COLORS.GRAY[500],
                }}
              >
                {bar.label}
              </Typography>
            )}
            <Box flexGrow={1} flexShrink={1}>
              <StackedHorizontalBar
                sections={bar.sections}
                scaleDenominator={fillStackedBars ? undefined : maxTotal}
              />
            </Box>
          </Stack>
        ))}
        {showLegend && <Legend sections={legendSections} />}
      </Stack>
    </Stack>
  );
}
