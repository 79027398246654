import { useApolloClient } from '@apollo/client';
import Decimal from 'decimal.js';
import { useEffect, useState } from 'react';

import { VisualizationInput } from '@/modules/proposal/types';
import {
  EntityProposalAssetValuationProjectionType,
  ProposalScenarioProjectionParamsV2,
} from '@/types/schema';

import {
  ProposalScenarioProjectionDocument,
  ProposalScenarioProjectionQuery,
  useProposalScenarioProjectionLazyQuery,
} from '../graphql/ProposalScenarioProjections.generated';

export type PreTaxPostTax = keyof Omit<
  ProposalScenarioProjectionQuery['proposalScenarioProjectionV2'],
  '__typename'
>;

function screenProjectionQueryInputs({
  queryInputs,
  termEndAssetValuationType,
}: {
  queryInputs: ProposalScenarioProjectionParamsV2;
  termEndAssetValuationType: EntityProposalAssetValuationProjectionType;
}): ProposalScenarioProjectionParamsV2 {
  // modify the query inputs based on the term end asset valuation type
  if (
    termEndAssetValuationType ===
    EntityProposalAssetValuationProjectionType.ProjectedRateOfReturn
  ) {
    queryInputs.endTermProjectedValue = undefined;
  } else if (
    termEndAssetValuationType ===
    EntityProposalAssetValuationProjectionType.ProjectedValue
  ) {
    queryInputs.inTermAnnualRateOfReturn = undefined;
  }

  return queryInputs;
}

interface Props {
  currentTab: PreTaxPostTax;
  queryInputs: ProposalScenarioProjectionParamsV2;
  termEndAssetValuationType: EntityProposalAssetValuationProjectionType;
  client: ReturnType<typeof useApolloClient>;
  queryInputsMaxTerm: ProposalScenarioProjectionParamsV2;
}

export async function queryProposalForScenario({
  currentTab,
  queryInputs,
  client,
  queryInputsMaxTerm,
}: Props) {
  const { data: projectionRes } =
    await client.query<ProposalScenarioProjectionQuery>({
      query: ProposalScenarioProjectionDocument,
      variables: {
        input: queryInputs,
      },
    });

  const { data: maxProjectionRes } =
    await client.query<ProposalScenarioProjectionQuery>({
      query: ProposalScenarioProjectionDocument,
      variables: {
        input: queryInputsMaxTerm,
      },
    });

  const maxProjectionValue =
    maxProjectionRes.proposalScenarioProjectionV2.preTax.withPlan.inEstate.plus(
      maxProjectionRes.proposalScenarioProjectionV2.preTax.withPlan.outOfEstate
    );

  const {
    outOfEstate: noPlanOutOfEstate,
    inEstate: noPlanInEstate,
    estateTax: noPlanEstateTax,
  } = projectionRes.proposalScenarioProjectionV2[currentTab].noPlan;

  const {
    outOfEstate: planOutOfEstate,
    inEstate: planInEstate,
    estateTax: planEstateTax,
  } = projectionRes.proposalScenarioProjectionV2[currentTab].withPlan;

  const noPlan: VisualizationInput = [
    noPlanOutOfEstate,
    noPlanInEstate,
    noPlanEstateTax.negated(),
  ];
  const withPlan: VisualizationInput = [
    planOutOfEstate,
    planInEstate,
    planEstateTax.negated(),
  ];

  return { noPlan, withPlan, maxProjectionValue };
}

export function useProposalForScenario({
  currentTab,
  queryInputs,
  termEndAssetValuationType,
  queryInputsMaxTerm,
}: Props) {
  const [visualizationInput, setVisualizationInput] = useState<{
    noPlan: VisualizationInput;
    withPlan: VisualizationInput;
  }>({
    noPlan: [new Decimal(0), new Decimal(0), new Decimal(0)],
    withPlan: [new Decimal(0), new Decimal(0), new Decimal(0)],
  });

  const [projectionRes, setProjectionRes] =
    useState<ProposalScenarioProjectionQuery | null>(null);
  const [maxProjectionRes, setMaxProjectionRes] =
    useState<ProposalScenarioProjectionQuery | null>(null);

  const screenedQueryInputs = screenProjectionQueryInputs({
    queryInputs,
    termEndAssetValuationType,
  });

  const screenedQueryInputsMaxTerm = screenProjectionQueryInputs({
    queryInputs: queryInputsMaxTerm,
    termEndAssetValuationType,
  });

  const [queryProjection, { loading, error }] =
    useProposalScenarioProjectionLazyQuery();

  // Get the projection value
  useEffect(() => {
    void (async () => {
      const { data } = await queryProjection({
        variables: {
          input: screenedQueryInputs,
        },
      });

      setProjectionRes(data ?? null);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(screenedQueryInputs)]);

  // Get the max projection value
  useEffect(() => {
    void (async () => {
      const { data } = await queryProjection({
        variables: {
          input: screenedQueryInputsMaxTerm,
        },
      });

      setMaxProjectionRes(data ?? null);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(screenedQueryInputsMaxTerm)]);

  const withPlan =
    maxProjectionRes?.proposalScenarioProjectionV2.preTax.withPlan;
  const maxProjectionValue = withPlan?.inEstate.plus(withPlan?.outOfEstate);

  useEffect(() => {
    if (loading || !projectionRes || error) {
      return;
    }

    const {
      outOfEstate: noPlanOutOfEstate,
      inEstate: noPlanInEstate,
      estateTax: noPlanEstateTax,
    } = projectionRes.proposalScenarioProjectionV2[currentTab].noPlan;

    const {
      outOfEstate: planOutOfEstate,
      inEstate: planInEstate,
      estateTax: planEstateTax,
    } = projectionRes.proposalScenarioProjectionV2[currentTab].withPlan;

    const noPlan: VisualizationInput = [
      noPlanOutOfEstate,
      noPlanInEstate,
      noPlanEstateTax.negated(),
    ];
    const withPlan: VisualizationInput = [
      planOutOfEstate,
      planInEstate,
      planEstateTax.negated(),
    ];

    setVisualizationInput({ noPlan, withPlan });
  }, [projectionRes, loading, error, currentTab]);

  return {
    ...visualizationInput,
    maxProjectionValue,
  };
}
