import { PopperContent } from '@/components/poppers/PopperContent';

export function Debtor() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body="Select the entity that holds the liability as a debt. If the individual client is the debtor, select a personal account entity."
    />
  );
}
