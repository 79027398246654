import { Box, Stack } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { addDays, formatDistanceToNow } from 'date-fns';
import { upperFirst } from 'lodash';
import { useMemo } from 'react';

import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { Callout } from '@/components/notifications/Callout/Callout';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { usePaginatedDataTableQuery } from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { TableEmptyState } from '@/components/tables/DataTable/TableEmptyState';
import { DataTableProps } from '@/components/tables/DataTable/types';
import { Column } from '@/components/tables/DataTable_LEGACY/tableTypes';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { RouteKey } from '@/navigation/constants';
import { COLORS } from '@/styles/tokens/colors';
import {
  EntityTaskOrder,
  EntityTaskOrderField,
  EntityTaskType,
  EntityTaskWhereInput,
  OrderDirection,
} from '@/types/schema';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { useGetHandleTaskOnClick } from '../../hooks/useGetHandleTaskOnClick';
import {
  TasksTableTasks_TaskFragment,
  useGetTasksTableTasksQuery,
} from './graphql/TasksTable.generated';

export enum TaskTableColumns {
  DUE_AT = 'dueAt',
  SUB_BRAND = 'subBrand',
  TASK_NAME = 'taskName',
  ASSIGNEE = 'assignee',
  ENTITY_NAME = 'entityName',
  CLIENT_NAME = 'clientName',
}
// keeping this separate from TaskTableColumns because the "actions" column
// should always be present
const ACTIONS_FIELD = 'actions';

interface TasksTableProps {
  filter: EntityTaskWhereInput;
  columns: TaskTableColumns[];
  orderBy?: EntityTaskOrder;
  pageSize?: number;
  hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS?: boolean;
  from?: RouteKey;
}

const COLOR_OK = COLORS.FUNCTIONAL.SUCCESS[700];

const getTaskRelativeDateColor = (dueAt: Date): typeof COLOR_OK => {
  const dateToShowWarning = addDays(new Date(), 30);
  const dateToShowError = new Date();
  let color = COLORS.FUNCTIONAL.SUCCESS[700];
  if (dueAt <= dateToShowError) {
    color = COLORS.FUNCTIONAL.ERROR[700];
  } else if (dueAt <= dateToShowWarning) {
    color = COLORS.FUNCTIONAL.WARNING[700];
  }
  return color;
};

interface TaskTableColumn {
  // also string because the 'actions' field isn't defined in the columns enum, nor
  // do we really want it to be
  field: TaskTableColumns | string;
}

const SUPPORTED_COLUMNS: Readonly<(TaskTableColumn & Column)[]> = [
  {
    field: TaskTableColumns.DUE_AT,
    headerName: 'Due date',
    width: 200,
    cellFormat: 'TaskDueDate',
    renderCell: TwoLineTextRenderer<RowData>({
      lineOne: ({ row }) => {
        return upperFirst(formatDistanceToNow(row?.dueAt));
      },
      lineOneProps: ({ row }) => {
        return {
          variant: 'h5',
          color: getTaskRelativeDateColor(row?.dueAt),
        };
      },
      lineTwo: ({ row }) => {
        return formatDateToMonDDYYYY(row?.dueAt);
      },
    }),
  },
  {
    field: TaskTableColumns.TASK_NAME,
    headerName: 'Task',
    width: 175,
    flex: 1,
  },
  {
    field: TaskTableColumns.ENTITY_NAME,
    headerName: 'Entity name',
    width: 175,
    flex: 1,
  },
  {
    field: TaskTableColumns.CLIENT_NAME,
    headerName: 'Client',
    width: 175,
    flex: 1,
  },
  {
    field: TaskTableColumns.SUB_BRAND,
    headerName: 'Sub-brand',
    width: 175,
  },
  {
    field: TaskTableColumns.ASSIGNEE,
    headerName: 'Assignee',
    width: 200,
  },
  {
    field: ACTIONS_FIELD,
    headerName: '',
    align: 'center',
    width: 64,
    minWidth: 64,
    maxWidth: 64,
    renderCell: IconRenderer({
      icon: ChevronRightIcon,
    }),
    sortable: false,
  },
] as const;

interface RowData {
  id: string;
  dueAt: Date;
  type: EntityTaskType;
  taskName: string;
  assignee: string;
  entityName: string;
  clientName: string;
  subBrand: string;
  taskData: {
    householdId: string;
    entityId: string;
  };
}

function useMapTasksToRows(
  taskEdges: TasksTableTasks_TaskFragment[]
): RowData[] {
  const { displayName: defaultTenantDisplayName } = useTenantDetailsContext();
  return useMemo(() => {
    return (
      taskEdges?.flatMap((task) => {
        if (!task) return [];
        return {
          id: task.id,
          dueAt: task.dueAt,
          type: task.type,
          taskName: task.title,
          assignee: task.assignedTo?.displayName ?? 'Unassigned',
          entityName: task.entity.subtype.displayName,
          clientName: task.entity.household.displayName,
          subBrand:
            task.entity.household.subBrand?.displayName ??
            defaultTenantDisplayName ??
            '',
          taskData: {
            householdId: task.entity.household.id,
            entityId: task.entity.id,
          },
        };
      }) ?? []
    );
  }, [defaultTenantDisplayName, taskEdges]);
}

export function TasksTable({
  filter,
  orderBy,
  pageSize = PageSizes.Twenty,
  columns,
  from,
}: TasksTableProps) {
  // it's a fair assumption that almost all tasks pages will want to hide completed tasks. that said,
  // this pattern should allow callers to override the `completedAtIsNil` attribute.
  const finalFilter = Object.assign({ completedAtIsNil: true }, filter);
  const [paginatedTableProps, { data, error }] = usePaginatedDataTableQuery(
    useGetTasksTableTasksQuery,
    {
      pageSize,
      variables: {
        where: finalFilter,
        orderBy: orderBy ?? {
          field: EntityTaskOrderField.DueAt,
          direction: OrderDirection.Asc,
        },
      },
    }
  );

  const rows = useMapTasksToRows(data ?? []);
  const { hasSubBrands } = useTenantDetailsContext();
  const showSubBrands = hasSubBrands;
  const renderedColumns = useMemo(() => {
    return SUPPORTED_COLUMNS.filter((supportedColDef) => {
      if (supportedColDef.field === ACTIONS_FIELD) return true;
      // all non-actions columns should be in the TaskTableColumns enum
      const columnField = supportedColDef.field as TaskTableColumns;

      // always hide the sub brand column if there are no sub brands enabled and present
      if (columnField === TaskTableColumns.SUB_BRAND && !showSubBrands) {
        return false;
      }

      return columns.includes(columnField);
    });
  }, [columns, showSubBrands]);

  const handleTaskOnClickNavigation = useGetHandleTaskOnClick();
  const onRowClick: NonNullable<DataTableProps<RowData>['onRowClick']> = (
    params: GridRowParams<RowData>
  ) => {
    const taskId = params.id;
    handleTaskOnClickNavigation({
      taskId: taskId.toString(),
      entityId: params.row.taskData.entityId,
      householdId: params.row.taskData.householdId,
      taskType: params.row.type,
      from,
    });
  };

  return (
    <Box>
      {error ? (
        <Callout severity="error">
          We weren&apos;t able to load your tasks. Please refresh the page to
          try again.
        </Callout>
      ) : (
        <Stack>
          <DataTable
            {...paginatedTableProps}
            rows={rows}
            columns={renderedColumns}
            onRowClick={onRowClick}
            slots={{
              noRowsOverlay: () => (
                <TableEmptyState text="No outstanding tasks" />
              ),
            }}
          />
        </Stack>
      )}
    </Box>
  );
}
