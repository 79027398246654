import { getQPRTTrustSummaryProperties } from '@/modules/entities/details/trusts/QPRTTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { TrustFiduciariesSlide } from '../components/Trust.Fiduciaries';
import { QPRTTrustPresentationSlideProps } from './QPRTTrust.types';

export function QPRTTrustFiduciariesSlide({
  entity,
  subtype,
  ...registrationProps
}: QPRTTrustPresentationSlideProps) {
  const summaryDetails = getQPRTTrustSummaryProperties({
    entity,
    entityType: ENTITY_TYPES.QPRT,
  });

  return (
    <TrustFiduciariesSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      displayName={subtype.displayName}
    />
  );
}
