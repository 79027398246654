import { PopperContent } from '@/components/poppers/PopperContent';

export function TrustTrustAdvisors() {
  return (
    <PopperContent
      body={
        'The individual(s) or corporate trustee(s) that serve as trust advisors to this entity.'
      }
    />
  );
}
