// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FamilyTree_ClientProfileRelationshipFragment = { __typename?: 'ClientProfileRelationship', id: string, relationID: string, type: Types.ClientProfileRelationshipType, notes?: string | null, toClientProfile: { __typename?: 'ClientProfile', id: string }, fromClientProfile: { __typename?: 'ClientProfile', id: string } };

export type FamilyTree_ClientProfileFragment = { __typename?: 'ClientProfile', id: string, isPrimary: boolean, displayName: string, relationships?: Array<{ __typename?: 'ClientProfileRelationship', id: string, relationID: string, type: Types.ClientProfileRelationshipType, notes?: string | null, toClientProfile: { __typename?: 'ClientProfile', id: string }, fromClientProfile: { __typename?: 'ClientProfile', id: string } }> | null };

export type FamilyTreeUpdateMutationVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
  update?: Types.InputMaybe<Array<Types.UpdateClientProfileRelationship> | Types.UpdateClientProfileRelationship>;
}>;


export type FamilyTreeUpdateMutation = { __typename?: 'Mutation', updateClientProfileRelationships: Array<{ __typename?: 'ClientProfileRelationship', id: string, relationID: string, type: Types.ClientProfileRelationshipType, notes?: string | null, toClientProfile: { __typename?: 'ClientProfile', id: string }, fromClientProfile: { __typename?: 'ClientProfile', id: string } } | null> };

export type FamilyTreeQueryVariables = Types.Exact<{
  where: Types.ClientProfileWhereInput;
}>;


export type FamilyTreeQuery = { __typename?: 'Query', clientProfiles: { __typename?: 'ClientProfileConnection', edges?: Array<{ __typename?: 'ClientProfileEdge', node?: { __typename?: 'ClientProfile', id: string, isPrimary: boolean, displayName: string, relationships?: Array<{ __typename?: 'ClientProfileRelationship', id: string, relationID: string, type: Types.ClientProfileRelationshipType, notes?: string | null, toClientProfile: { __typename?: 'ClientProfile', id: string }, fromClientProfile: { __typename?: 'ClientProfile', id: string } }> | null } | null } | null> | null } };

export const FamilyTree_ClientProfileRelationshipFragmentDoc = gql`
    fragment FamilyTree_ClientProfileRelationship on ClientProfileRelationship {
  id
  relationID
  type
  notes
  toClientProfile {
    id
  }
  fromClientProfile {
    id
  }
}
    `;
export const FamilyTree_ClientProfileFragmentDoc = gql`
    fragment FamilyTree_ClientProfile on ClientProfile {
  id
  isPrimary
  displayName
  relationships {
    ...FamilyTree_ClientProfileRelationship
  }
}
    ${FamilyTree_ClientProfileRelationshipFragmentDoc}`;
export const FamilyTreeUpdateDocument = gql`
    mutation FamilyTreeUpdate($householdId: ID!, $update: [UpdateClientProfileRelationship!]) {
  updateClientProfileRelationships(
    householdId: $householdId
    updateRelationships: $update
  ) {
    ...FamilyTree_ClientProfileRelationship
  }
}
    ${FamilyTree_ClientProfileRelationshipFragmentDoc}`;
export type FamilyTreeUpdateMutationFn = Apollo.MutationFunction<FamilyTreeUpdateMutation, FamilyTreeUpdateMutationVariables>;

/**
 * __useFamilyTreeUpdateMutation__
 *
 * To run a mutation, you first call `useFamilyTreeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFamilyTreeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [familyTreeUpdateMutation, { data, loading, error }] = useFamilyTreeUpdateMutation({
 *   variables: {
 *      householdId: // value for 'householdId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useFamilyTreeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<FamilyTreeUpdateMutation, FamilyTreeUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FamilyTreeUpdateMutation, FamilyTreeUpdateMutationVariables>(FamilyTreeUpdateDocument, options);
      }
export type FamilyTreeUpdateMutationHookResult = ReturnType<typeof useFamilyTreeUpdateMutation>;
export type FamilyTreeUpdateMutationResult = Apollo.MutationResult<FamilyTreeUpdateMutation>;
export type FamilyTreeUpdateMutationOptions = Apollo.BaseMutationOptions<FamilyTreeUpdateMutation, FamilyTreeUpdateMutationVariables>;
export const FamilyTreeDocument = gql`
    query FamilyTree($where: ClientProfileWhereInput!) {
  clientProfiles(where: $where) {
    edges {
      node {
        ...FamilyTree_ClientProfile
      }
    }
  }
}
    ${FamilyTree_ClientProfileFragmentDoc}`;

/**
 * __useFamilyTreeQuery__
 *
 * To run a query within a React component, call `useFamilyTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFamilyTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFamilyTreeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFamilyTreeQuery(baseOptions: Apollo.QueryHookOptions<FamilyTreeQuery, FamilyTreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FamilyTreeQuery, FamilyTreeQueryVariables>(FamilyTreeDocument, options);
      }
export function useFamilyTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FamilyTreeQuery, FamilyTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FamilyTreeQuery, FamilyTreeQueryVariables>(FamilyTreeDocument, options);
        }
export function useFamilyTreeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FamilyTreeQuery, FamilyTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FamilyTreeQuery, FamilyTreeQueryVariables>(FamilyTreeDocument, options);
        }
export type FamilyTreeQueryHookResult = ReturnType<typeof useFamilyTreeQuery>;
export type FamilyTreeLazyQueryHookResult = ReturnType<typeof useFamilyTreeLazyQuery>;
export type FamilyTreeSuspenseQueryHookResult = ReturnType<typeof useFamilyTreeSuspenseQuery>;
export type FamilyTreeQueryResult = Apollo.QueryResult<FamilyTreeQuery, FamilyTreeQueryVariables>;
export const Operations = {
  Query: {
    FamilyTree: 'FamilyTree' as const
  },
  Mutation: {
    FamilyTreeUpdate: 'FamilyTreeUpdate' as const
  },
  Fragment: {
    FamilyTree_ClientProfileRelationship: 'FamilyTree_ClientProfileRelationship' as const,
    FamilyTree_ClientProfile: 'FamilyTree_ClientProfile' as const
  }
}