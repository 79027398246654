import { makeVar } from '@apollo/client';
import { Box, InputAdornment, Stack } from '@mui/material';
import { compact } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useFormFieldsDisabled } from '@/components/form/context/formFieldsDisabled.context';
import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { useValidateOnChange } from '@/components/form/formAwareInputs/hooks/useValidateOnChange';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { User01Icon } from '@/components/icons/User01Icon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { ContextualHelpTrigger } from '@/modules/content/components/ContextualHelpTrigger';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import {
  DispositiveProvisionDispositionKind,
  DispositiveProvisionTemplateKind,
} from '@/types/schema';

import { useDispositiveProvisionsContext } from '../../contexts/dispositiveProvisions.context';
import { useDispositiveProvisionsTemplateSplitScreenModalContext } from '../../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.context';
import { RecipientSelectorModal } from '../../RecipientSelectorModal';
import {
  dispositionKindOptions,
  getTransferTaxKindOptions,
  otherDispositionKindOptions,
} from '../DispositiveProvisionsForm.constants';
import {
  DispositiveProvisionsFormPaths,
  DispositiveProvisionsFormShape,
  Recipient,
} from '../DispositiveProvisionsForm.types';
import {
  useRecipientOptions,
  useRecipientOptionsParams,
} from '../hooks/useRecipientOptions';
import { useRemainingExemptionAmountForClient } from '../hooks/useRemainingExemptionAmountForClient';
import {
  useDeathOrderAwareClient,
  useHasGrantorWithStateTax,
} from '../hooks/utilityHooks';
import { DispositiveProvisionsRecipientTemplateRecipientConfiguration } from './DispositiveProvisionsRecipient.types';
import {
  getDispositionKindOption,
  getTemplateRulesForTemplateType,
  validateRecipient,
} from './DispositiveProvisionsRecipient.utils';
import { useDefaultRecipientTransferTaxKind } from './hooks/useDefaultRecipientTransferTaxKind';

export const showStateTaxContentVar = makeVar<boolean>(false);

interface DispositiveProvisionsRecipientProps {
  idx: number;
  /** this will be null for templates, which are death order agnostic by definition */
  deadPrimaryClientId: string | null;
  isSecondDeath: boolean;
  onRemove?: ((index: number) => void) | null;
  templateType?: DispositiveProvisionTemplateKind;
  recipientConfiguration?: Partial<DispositiveProvisionsRecipientTemplateRecipientConfiguration>;
  emptyRecipientOptionDisplay?: string;
  isOptional?: boolean;
}

export function DispositiveProvisionsRecipient({
  idx,
  deadPrimaryClientId,
  isSecondDeath,
  onRemove,
  templateType,
  recipientConfiguration: externalRecipientConfiguration,
  emptyRecipientOptionDisplay,
  isOptional,
}: DispositiveProvisionsRecipientProps) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const { availableStateEstateTaxes } = useIRSConstants();
  const { control, setFocus } =
    useFormContext<DispositiveProvisionsFormShape>();
  const { isTemplateMode } =
    useDispositiveProvisionsTemplateSplitScreenModalContext();
  const {
    householdId: dispositiveProvisionsContextHouseholdId,
    primaryClients,
    isTwoClientHousehold,
  } = useDispositiveProvisionsContext();
  // this is necessary because the recipient picker can be invoked outside of a waterfall/death order
  const { householdId: householdContextId } = useHouseholdDetailsContext();
  const householdId =
    householdContextId ?? dispositiveProvisionsContextHouseholdId;

  const firstDeadGrantor = useDeathOrderAwareClient();
  const hasGrantorWithStateTax = useHasGrantorWithStateTax();

  const { templateRules, recipientConfiguration } =
    getTemplateRulesForTemplateType(
      templateType,
      externalRecipientConfiguration
    );
  const {
    templateRequiresRecipient,
    templateRequiresSpecificPayout,
    templateRequiresSpecificTaxKind,
  } = templateRules;

  // we validate recipients on change to prevent the user from selecting an invalid recipient,
  // or to provide them immediate feedback if the recipient has become valid
  useValidateOnChange<DispositiveProvisionsFormShape>(
    true,
    `dispositiveProvisions.recipients.${idx}.recipientId`,
    control,
    {
      valueType: 'string',
    }
  );

  const selectedDistributionKind = useWatch({
    control,
    name: `dispositiveProvisions.recipients.${idx}.dispositionKind_ButtonGroup` as const satisfies DispositiveProvisionsFormPaths,
  });

  const notes = useWatch({
    control,
    name: `dispositiveProvisions.recipients.${idx}.notes` as const satisfies DispositiveProvisionsFormPaths,
  });

  const recipientsFromForm = useWatch({
    control,
    name: `dispositiveProvisions.recipients` as const satisfies DispositiveProvisionsFormPaths,
  });

  const [notesAreExpanded, setNotesAreExpanded] = useState(
    Boolean(notes) || templateRules.alwaysShowNotes
  );

  const recipients: Recipient[] = useMemo(() => {
    return recipientsFromForm ?? [];
  }, [recipientsFromForm]);

  const thisRecipient = recipients[idx];

  const requiredFields = useMemo(() => {
    if (thisRecipient?.recipientId) {
      return [
        `dispositiveProvisions.recipients.${idx}.recipientId` as const satisfies DispositiveProvisionsFormPaths,
        `dispositiveProvisions.recipients.${idx}.dispositionKind_Select` as const satisfies DispositiveProvisionsFormPaths,
        `dispositiveProvisions.recipients.${idx}.transferTaxKind` as const satisfies DispositiveProvisionsFormPaths,
      ];
    }
    return [];
  }, [idx, thisRecipient]);

  const isAmountDistributionKind =
    selectedDistributionKind === DispositiveProvisionDispositionKind.Amount;
  const isPercentDistributionKind =
    selectedDistributionKind === DispositiveProvisionDispositionKind.Percentage;
  const isOtherDistributionKind = selectedDistributionKind === 'other';

  const deadPrimaryClientIds = useMemo(() => {
    if (!isSecondDeath) {
      return compact([deadPrimaryClientId]);
    }
    return primaryClients.map((c) => c.id);
  }, [deadPrimaryClientId, primaryClients, isSecondDeath]);

  const recipientOptionsParams = useRecipientOptionsParams();
  const { options: recipientOptions } = useRecipientOptions(
    recipientOptionsParams,
    recipientConfiguration
  );

  const hasStateTax = useMemo(() => {
    if (isTemplateMode) {
      return hasGrantorWithStateTax;
    }
    if (!firstDeadGrantor) return false;

    return Boolean(
      availableStateEstateTaxes?.includes(firstDeadGrantor.stateCode!)
    );
  }, [
    availableStateEstateTaxes,
    firstDeadGrantor,
    hasGrantorWithStateTax,
    isTemplateMode,
  ]);

  useEffect(() => {
    if (hasStateTax) {
      showStateTaxContentVar(true);
    } else {
      showStateTaxContentVar(false);
    }
  }, [hasStateTax]);

  useDefaultRecipientTransferTaxKind(thisRecipient, idx, hasStateTax);

  // selectableRecipientOptions *does* include disabled recipients, but just doesn't include
  // the component section label elements of the list
  const selectableRecipientOptions = useMemo(() => {
    return recipientOptions.filter(
      (o) => !('component' in o)
    ) as SelectInputOption[];
  }, [recipientOptions]);

  const { data: clientExemptionData } = useRemainingExemptionAmountForClient({
    // clientProfileId of who died
    clientId: deadPrimaryClientId,
  });

  const [isRecipientSelectorModalOpen, setIsRecipientSelectorModalOpen] =
    useState(false);
  const handleClose = useCallback(() => {
    setIsRecipientSelectorModalOpen(false);
  }, []);

  // Disable exclusion options if they are already selected
  // since they can only apply to the total once
  const dispositionOptionsToDisable: DispositiveProvisionDispositionKind[] =
    useMemo(() => {
      const selectedItemsToDisable: DispositiveProvisionDispositionKind[] = [];

      for (const r of recipients) {
        if (r.dispositionKind_Select === '') {
          continue;
        }

        if (
          [
            DispositiveProvisionDispositionKind.RemainingGstExclusionOfGrantorInExcessOfLifetimeExclusion,
            DispositiveProvisionDispositionKind.RemainingLifetimeExclusionOfGrantor,
          ].includes(r.dispositionKind_Select)
        ) {
          selectedItemsToDisable.push(r.dispositionKind_Select);
        }
      }

      return selectedItemsToDisable;
    }, [recipients]);

  const dispositionKindSelectOptions = useMemo(
    () =>
      otherDispositionKindOptions.flatMap((option) => {
        if (!hasStateTax && !templateRequiresSpecificPayout) {
          if (
            [
              DispositiveProvisionDispositionKind.RemainingFederalLifetimeExemptionInExcessOfStateExemption,
              DispositiveProvisionDispositionKind.RemainingStateExemption,
            ].includes(option.value)
          ) {
            return [];
          }
        }

        return getDispositionKindOption(
          option,
          clientExemptionData,
          dispositionOptionsToDisable
        );
      }),
    [
      clientExemptionData,
      dispositionOptionsToDisable,
      hasStateTax,
      templateRequiresSpecificPayout,
    ]
  );

  function handleExpandNotes() {
    setNotesAreExpanded(true);
    // allow a loop for the notes to expand
    setTimeout(() => {
      setFocus(
        `dispositiveProvisions.recipients.${idx}.notes` as const satisfies DispositiveProvisionsFormPaths
      );
    });
  }

  let isRecipientRequired =
    requiredFields.includes(
      `dispositiveProvisions.recipients.${idx}.recipientId` as const satisfies DispositiveProvisionsFormPaths
    ) || templateRequiresRecipient;
  if (isOptional) {
    isRecipientRequired = false;
  }
  return (
    <>
      <RecipientSelectorModal
        handleClose={handleClose}
        isOpen={isRecipientSelectorModalOpen}
        householdId={householdId}
        deadPrimaryClientId={deadPrimaryClientId}
        deadPrimaryClientIds={deadPrimaryClientIds}
        isTwoClientHousehold={isTwoClientHousehold}
        recipientConfiguration={recipientConfiguration}
      />
      <Box data-testid={`DispositiveProvisionsRecipient-${idx}`} key={idx}>
        <FormLayoutRow>
          <FormLayoutItem width={7}>
            <FormAwareSelectInput<DispositiveProvisionsFormShape>
              control={control}
              label="Recipient"
              addNewOption={
                recipientConfiguration.createRecipientTypes.length
                  ? {
                      onClick: () => setIsRecipientSelectorModalOpen(true),
                    }
                  : undefined
              }
              fieldName={
                `dispositiveProvisions.recipients.${idx}.recipientId` as const satisfies DispositiveProvisionsFormPaths
              }
              options={recipientOptions}
              required={isRecipientRequired}
              validation={{
                mustBeValidOption: (value) => {
                  if (!value || typeof value !== 'string') return undefined;
                  return validateRecipient(value, selectableRecipientOptions);
                },
              }}
              emptyValueDisplay={emptyRecipientOptionDisplay}
              disabled={recipientConfiguration.forceDefaultRecipient}
              startAdornment={
                <InputAdornment position="start">
                  <User01Icon size={20} />
                </InputAdornment>
              }
            />
          </FormLayoutItem>
          <FormLayoutItem width={5}>
            <Stack spacing={1}>
              <FormAwareSelectInput<DispositiveProvisionsFormShape>
                control={control}
                label="Recipient type"
                disabled={templateRequiresSpecificTaxKind}
                fieldName={
                  `dispositiveProvisions.recipients.${idx}.transferTaxKind` as const satisfies DispositiveProvisionsFormPaths
                }
                options={getTransferTaxKindOptions({
                  hasStateTax,
                  recipientConfiguration,
                })}
                required={requiredFields.includes(
                  `dispositiveProvisions.recipients.${idx}.transferTaxKind` as const satisfies DispositiveProvisionsFormPaths
                )}
              />
              {!templateRequiresSpecificTaxKind && (
                <ContextualHelpTrigger
                  display="Which type should I pick?"
                  contextualHelpId="dispositiveProvisionsRecipientType"
                  variant="helpText"
                />
              )}
            </Stack>
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow
          sx={{
            alignItems: 'end',
            marginBottom: 1,
          }}
        >
          {!templateRequiresSpecificPayout && (
            <FormLayoutItem width={4}>
              <FormAwareButtonGroup<DispositiveProvisionsFormShape>
                label="Distribution type"
                fieldName={
                  `dispositiveProvisions.recipients.${idx}.dispositionKind_ButtonGroup` as const satisfies DispositiveProvisionsFormPaths
                }
                control={control}
                options={dispositionKindOptions}
                variant="light"
              />
            </FormLayoutItem>
          )}
          <FormLayoutItem width={templateRequiresSpecificPayout ? 12 : 8}>
            {isPercentDistributionKind && (
              <FormAwarePercentInput<DispositiveProvisionsFormShape>
                label="Distribution percentage"
                fieldName={
                  `dispositiveProvisions.recipients.${idx}.dispositionPercentage` as const satisfies DispositiveProvisionsFormPaths
                }
                control={control}
                isDecimalJSInput
                decimalScale={2}
                disabled={templateRequiresSpecificPayout}
              />
            )}
            {isAmountDistributionKind && (
              <FormAwareCurrencyInput<DispositiveProvisionsFormShape>
                label="Distribution amount"
                fieldName={
                  `dispositiveProvisions.recipients.${idx}.dispositionAmount` as const satisfies DispositiveProvisionsFormPaths
                }
                control={control}
                isDecimalJSInput
                disabled={templateRequiresSpecificPayout}
              />
            )}
            {isOtherDistributionKind && (
              <FormAwareSelectInput<DispositiveProvisionsFormShape>
                label="Disposition"
                hideLabel
                fieldName={
                  `dispositiveProvisions.recipients.${idx}.dispositionKind_Select` as const satisfies DispositiveProvisionsFormPaths
                }
                control={control}
                options={dispositionKindSelectOptions}
                required={requiredFields.includes(
                  `dispositiveProvisions.recipients.${idx}.dispositionKind_Select` as const satisfies DispositiveProvisionsFormPaths
                )}
                disabled={templateRequiresSpecificPayout}
              />
            )}
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow sx={{ display: notesAreExpanded ? 'initial' : 'none' }}>
          <FormLayoutItem width={12}>
            <FormAwareTextInput<DispositiveProvisionsFormShape>
              label="Notes"
              multiline
              fieldName={
                `dispositiveProvisions.recipients.${idx}.notes` as const satisfies DispositiveProvisionsFormPaths
              }
              control={control}
              rows={3}
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
          mt={2}
        >
          <Box>
            {!notesAreExpanded && (
              <FooterButton
                variant="secondary"
                onClick={() => handleExpandNotes()}
                startIcon={PlusIcon}
              >
                Add notes
              </FooterButton>
            )}
          </Box>
          {!templateRequiresRecipient && onRemove && (
            <Box>
              <FooterButton
                variant="destructive-transparent"
                disabled={disabledFromContext}
                startIcon={Trash01Icon}
                onClick={() => onRemove(idx)}
              >
                Remove recipient
              </FooterButton>
            </Box>
          )}
        </Stack>
      </Box>
    </>
  );
}

function FooterButton(props: SetOptional<ButtonProps, 'size'>) {
  return <Button size="sm" {...props} />;
}
