import { useMemo } from 'react';

import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';

import { ROUTE_KEYS } from './constants';
import { RouteKey } from './navigationUtils';

export function useCanAccessPage(page: RouteKey) {
  const canAccessTasksPage = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_ACCESS_TASKS_PAGE
  );
  const canAccessCollaboratorsPage = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_ACCESS_COLLABORATORS_PAGE
  );

  const canAccessUserAdminPage = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_ACCESS_USER_ADMIN_PAGE
  );
  const canAccessBrandingAdminPage = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_ACCESS_BRANDING_ADMIN_PAGE
  );
  const canAccessSubBrandsAdminPage = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_ACCESS_SUB_BRANDS_ADMIN_PAGE
  );

  const canAccessIntegrationsAdminPage = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_ACCESS_INTEGRATIONS_ADMIN_PAGE
  );

  const canAccessInternalTools = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_ACCESS_INTERNAL_TOOLS
  );

  const canAccessLegalDisclaimersPage = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_ACCESS_LEGAL_DISCLAIMERS_PAGE
  );

  const hasAdminCategoriesPageAccess = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_ACCESS_ADMIN_CATEGORIES_PAGES
  );

  const canAccessAdminArea =
    canAccessUserAdminPage ||
    canAccessBrandingAdminPage ||
    canAccessSubBrandsAdminPage ||
    canAccessIntegrationsAdminPage ||
    canAccessLegalDisclaimersPage ||
    hasAdminCategoriesPageAccess ||
    canAccessInternalTools;

  const pageAccessLookup: Partial<Record<RouteKey, boolean>> = useMemo(() => {
    return {
      [ROUTE_KEYS.TASKS]: canAccessTasksPage,
      [ROUTE_KEYS.COLLABORATORS]: canAccessCollaboratorsPage,
      [ROUTE_KEYS.ADMIN_USERS]: canAccessUserAdminPage,
      [ROUTE_KEYS.ADMIN_BRANDING]: canAccessBrandingAdminPage,
      [ROUTE_KEYS.ADMIN_SUB_BRANDS]: canAccessSubBrandsAdminPage,
      [ROUTE_KEYS.ADMIN_INTEGRATIONS]: canAccessIntegrationsAdminPage,
      [ROUTE_KEYS.ADMIN_FEATURE_FLAGS]: canAccessInternalTools,
      [ROUTE_KEYS.ADMIN_TENANTS]: canAccessInternalTools,
      [ROUTE_KEYS.ADMIN]: canAccessAdminArea,
      [ROUTE_KEYS.ADMIN_LEGAL_DISCLAIMERS]: canAccessLegalDisclaimersPage,
      [ROUTE_KEYS.ADMIN_CATEGORIES]: hasAdminCategoriesPageAccess,
    };
  }, [
    canAccessTasksPage,
    canAccessCollaboratorsPage,
    canAccessUserAdminPage,
    canAccessBrandingAdminPage,
    canAccessSubBrandsAdminPage,
    canAccessIntegrationsAdminPage,
    canAccessInternalTools,
    canAccessAdminArea,
    canAccessLegalDisclaimersPage,
    hasAdminCategoriesPageAccess,
  ]);

  return pageAccessLookup[page] ?? true;
}
