import { Box, BoxProps } from '@mui/material';
import { ComponentType } from 'react';

import { BalanceSheetTable } from '@/modules/balanceSheet/BalanceSheetTable/BalanceSheetTable';
import { BalanceSheetTable_BalanceSheetFragment } from '@/modules/balanceSheet/BalanceSheetTable/graphql/BalanceSheetTable.fragments.generated';

import { ClientPresentationStandaloneSlideType } from '../clientPresentation/clientPresentation.types';
import { ClientPresentationDesigner_HouseholdFragment } from '../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { useUnguardedClientPresentationDesignerContext } from '../clientPresentation/contexts/clientPresentationDesigner.context';
import { useRegisterSlide } from '../clientPresentation/hooks/useRegisterSlide';
import { PresentationSlideSecondaryHeading } from '../components/PresentationSlideSecondaryHeading';
import { PRESENTATION_SPACING_UNITS } from '../presentation.constants';
import { PresentationSlide } from '../PresentationSlide';

export interface BalanceSheetSlideProps {
  balanceSheet: BalanceSheetTable_BalanceSheetFragment;
  client: ClientPresentationDesigner_HouseholdFragment;
  SlideWrapper?: ComponentType<BoxProps>;
}

export function BalanceSheetSlide({
  client,
  balanceSheet,
  SlideWrapper = Box,
}: BalanceSheetSlideProps) {
  const slideTitle = 'Balance sheet';
  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  const slideId = 'slide_balance_sheet';

  useRegisterSlide({
    displayName: slideTitle,
    bundleId: '',
    id: slideId,
    index: 0,
    identifier: ClientPresentationStandaloneSlideType.BALANCE_SHEET,
  });

  if (!shouldShowItem(ClientPresentationStandaloneSlideType.BALANCE_SHEET)) {
    return null;
  }

  return (
    <SlideWrapper>
      <PresentationSlide.Slide
        id={slideId}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={slideTitle} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading
            clientDisplayName={client.displayName}
          />
        }
        footer={<PresentationSlide.Footer />}
      >
        <Box px={PRESENTATION_SPACING_UNITS}>
          <BalanceSheetTable balanceSheet={balanceSheet} loading={false} />
        </Box>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}
