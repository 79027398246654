import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Alert } from '@/components/notifications/Alert/Alert';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { diagnostics } from '@/utils/diagnostics';

import { useImportAdvisorClientMutation } from './graphql/ClientImportExport.generated';

export function ClientImport() {
  const [importedClientLink, setImportedClientLink] = useState<string | null>(
    null
  );
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [importHousehold, { loading }] = useImportAdvisorClientMutation({
    onError: (err) => {
      showFeedback('Failed to import client');
      reportError('could not import client', err);
    },
  });

  async function onClientImport(clientJsonData: string) {
    return importHousehold({
      variables: {
        data: clientJsonData,
      },
      onCompleted: (data) => {
        const createdClientId = data.importHousehold.id;

        setImportedClientLink(
          getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW, {
            householdId: createdClientId,
          })
        );
      },
    });
  }

  function handleFileOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const fileReader = new FileReader();
    const selectedFile = e?.currentTarget?.files?.[0];
    if (!selectedFile) {
      diagnostics.debug('No file selected');
      return;
    }

    fileReader.readAsText(selectedFile, 'UTF-8');
    fileReader.onload = (e) => {
      void onClientImport(e?.target?.result as string);
    };
  }

  return (
    <Box>
      <Stack spacing={0.5}>
        <Typography variant="label" component="label" htmlFor="importButton">
          Import client
        </Typography>
        <input
          accept=".json"
          type="file"
          disabled={loading}
          id="importButton"
          onChange={handleFileOnChange}
        />
      </Stack>
      {importedClientLink && (
        <Alert size="sm" severity="success">
          <NavLink to={importedClientLink}>
            <Typography variant="body1">
              Go to the newly-created client {'→'}
            </Typography>
          </NavLink>
        </Alert>
      )}
    </Box>
  );
}
