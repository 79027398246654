import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import React from 'react';

import {
  ChartColorDefinitions,
  useChartColorDefinitions,
} from '@/components/charts/constants';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { Copy07Icon } from '@/components/icons/Copy07Icon';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { ConvertToActiveMenuItem } from '@/modules/entities/components/ConvertToActiveMenuItem/ConvertToActiveMenuItem';
import { DeleteEntityMenuItem } from '@/modules/entities/components/DeleteEntityMenuItem';
import { DuplicateTestamentaryEntityMenuItem } from '@/modules/entities/components/DuplicateEntityMenuItem';
import { FilterOptions } from '@/modules/entities/entities.constants';
import { entityKindToDisplayName } from '@/modules/entities/EntityForm/utils';
import { testamentaryEntityKindToDisplayName } from '@/modules/entities/testamentaryEntities/testamentaryEntities.utils';
import {
  getAssetLocationDisplay,
  getFederalAssetLocationDisplay,
  getStateAssetLocationDisplay,
} from '@/modules/entities/utils/getAssetLocationDisplay';
import { getColorDefinitionForEntity } from '@/modules/entities/utils/getColorDefinitionForEntity';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';
import { getGstStatusDisplay } from '@/modules/entities/utils/getGstStatusDisplay';
import { EntityStage } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';

import {
  EntityListFragment,
  TestamentaryEntityList_TestamentaryEntityFragment,
} from './graphql/ListClientEntities.generated';

function mapStageToTab(stage: EntityStage) {
  switch (stage) {
    case EntityStage.Active:
      return FilterOptions.ACTIVE;
    case EntityStage.Completed:
      return FilterOptions.COMPLETED;
    case EntityStage.Draft:
    case EntityStage.Implementation:
    case EntityStage.ReadyForProposal:
      return FilterOptions.DRAFTS;
    default:
      diagnostics.error(
        `Cannot map stage to tab`,
        new Error('Cannot map stage to tab'),
        { stage }
      );
      return null;
  }
}

interface Opts {
  first?: number;
}

type EntityListRow = ReturnType<typeof mapEntityToRow>;
type TestamentaryEntityListRow = ReturnType<typeof mapTestamentaryEntityToRow>;

export type ClientDetailsEntityListRow = EntityListRow &
  TestamentaryEntityListRow & {
    chevron: unknown;
    actions: unknown;
  };

interface mapEntitiesToRowsInput {
  entities: EntityListFragment[];
  testamentaries: TestamentaryEntityList_TestamentaryEntityFragment[];
  householdId: string;
  colors: ReturnType<typeof useChartColorDefinitions>;
  refetch?: () => void;
  opts?: Opts;
}

export function mapEntitiesToRows({
  entities,
  testamentaries,
  householdId,
  colors,
  refetch,
  opts,
}: mapEntitiesToRowsInput) {
  const combined = [...entities, ...testamentaries];

  const rows = combined
    .map(
      (
        entity:
          | EntityListFragment
          | TestamentaryEntityList_TestamentaryEntityFragment
      ) => {
        switch (entity.__typename) {
          case 'TestamentaryEntity':
            return mapTestamentaryEntityToRow(entity, householdId);
          default:
            return !(entity as EntityListFragment).subtype
              ? null
              : mapEntityToRow(entity as EntityListFragment, colors, refetch);
        }
      }
    )
    .filter((row) => row !== null)
    .sort((a, b) => {
      // sort by the strategy current value
      // PERF TODO maybe revisit this when StrategyConnection order_by works with edges
      return a?._currentValueSort.lessThan(
        b?._currentValueSort ?? new Decimal(-1)
      )
        ? 1
        : -1;
    });

  if (opts?.first) {
    return rows.slice(0, opts.first);
  }

  return rows;
}

function mapEntityToRow(
  entity: EntityListFragment,
  colors: ChartColorDefinitions,
  refetch?: () => void
) {
  const entitySubtype = entity.subtype;

  const status = mapStageToTab(entity.stage ?? EntityStage.Archived);

  let dollarsValue = new Decimal(0);
  let beneficiaries: string | undefined = '';
  let distributionDate = null;
  let distributedValue = new Decimal(0);
  let effectiveDate: Date | null = null;
  let endDate: Date | null = null;
  let assetLocation: string | null = null;
  let federalAssetLocation: string | null = null;
  let stateAssetLocation: string | null = null;
  let gstStatus: string | null = null;
  const asOfDate = entitySubtype?.mostRecentValuationDate ?? null;
  const showAsOfDate = !!asOfDate && status !== FilterOptions.DRAFTS;
  const stateTaxes = entity.stateTaxes ?? [];

  switch (entitySubtype.__typename) {
    case 'GRATTrust':
      dollarsValue = entitySubtype.currentValue
        ? entitySubtype?.currentValue
        : new Decimal(0);

      distributionDate = entitySubtype.distributionDate ?? null;
      distributedValue =
        entitySubtype.distributionAssetValuation?.valuationValue ??
        new Decimal(0);

      beneficiaries = entitySubtype.beneficiaries
        ?.map((beneficiary) => beneficiary.summary.displayName)
        .join(', ');

      effectiveDate = entitySubtype.effectiveDate ?? null;

      endDate = entitySubtype.termEndDate ?? null;

      assetLocation = getAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      federalAssetLocation = getFederalAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      stateAssetLocation = getStateAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      gstStatus = getGstStatusDisplay(entitySubtype);
      break;

    case 'QPRTTrust':
      dollarsValue = entitySubtype.currentValue
        ? entitySubtype?.currentValue
        : new Decimal(0);

      beneficiaries = entitySubtype.beneficiaries
        ?.map((beneficiary) => beneficiary.summary.displayName)
        .join(', ');

      endDate = entitySubtype.termEndDate ?? null;

      effectiveDate = entitySubtype.effectiveDate ?? null;
      assetLocation = getAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      federalAssetLocation = getFederalAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      stateAssetLocation = getStateAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      gstStatus = getGstStatusDisplay(entitySubtype);
      break;

    case 'IrrevocableTrust':
    case 'ILITTrust':
    case 'RevocableTrust':
    case 'DonorAdvisedFund':
    case 'PrivateFoundation':
      dollarsValue = entitySubtype.currentValue
        ? entitySubtype?.currentValue
        : new Decimal(0);

      beneficiaries = entitySubtype.beneficiaries
        ?.map((beneficiary) => beneficiary.summary.displayName)
        .join(', ');

      effectiveDate = entitySubtype.effectiveDate ?? null;
      assetLocation = getAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      federalAssetLocation = getFederalAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      stateAssetLocation = getStateAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      gstStatus = getGstStatusDisplay(entitySubtype);

      break;

    case 'SLATTrust':
    case 'CLTTrust':
    case 'CRTTrust':
      effectiveDate = entitySubtype.effectiveDate ?? null;

      dollarsValue = entitySubtype.currentValue
        ? entitySubtype?.currentValue
        : new Decimal(0);

      beneficiaries = [
        ...(entitySubtype.remainderBeneficiaries ?? []),
        ...(entitySubtype.lifetimeBeneficiaries ?? []),
      ]
        ?.map((beneficiary) => beneficiary.summary.displayName)
        .join(', ');
      assetLocation = getAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      federalAssetLocation = getFederalAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      stateAssetLocation = getStateAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      gstStatus = getGstStatusDisplay(entitySubtype);

      break;
    case 'RetirementPersonalAccount':
    case 'IndividualPersonalAccount':
    case 'CustodialPersonalAccount':
    case 'QualifiedTuitionPersonalAccount':
    case 'JointPersonalAccount':
      effectiveDate = entitySubtype.effectiveDate ?? null;

      dollarsValue = entitySubtype.currentValue
        ? entitySubtype?.currentValue
        : new Decimal(0);
      assetLocation = getAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      federalAssetLocation = getFederalAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      stateAssetLocation = getStateAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      gstStatus = getGstStatusDisplay(entitySubtype);
      beneficiaries = entitySubtype.beneficiaries
        ?.map((beneficiary) => beneficiary.summary.displayName)
        .join(', ');
      break;

    case 'InsurancePersonalAccount':
      effectiveDate = entitySubtype.effectiveDate ?? null;

      assetLocation = getAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      federalAssetLocation = getFederalAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;
      stateAssetLocation = getStateAssetLocationDisplay({
        inEstateStatus: entitySubtype.inEstateStatus,
        stateTaxes,
        gstStatus: entitySubtype.gstStatus,
      }).locationDisplayShort;

      dollarsValue = entitySubtype?.currentValue || new Decimal(0);
      break;

    case 'LLCBusinessEntity':
    case 'GPBusinessEntity':
    case 'LPBusinessEntity':
    case 'CCorpBusinessEntity':
    case 'SCorpBusinessEntity':
    case 'SoleProprietorshipBusinessEntity':
      effectiveDate = entitySubtype.effectiveDate ?? null;

      // we use grantorControlledValue instead of the currentValue (market value)
      // for business entities because we only want to show the value of the entity
      // that the grantors control, not the total value of the entity, which could include
      // other peoples' stakes
      dollarsValue = entitySubtype.grantorControlledValue
        ? entitySubtype.grantorControlledValue
        : new Decimal(0);
      break;

    default:
      throw new UnreachableError({
        case: entitySubtype.__typename as never,
        message: `Unhandled entity subtype: ${entitySubtype.__typename}`,
      });
  }

  return {
    id: entity.id,
    entityName: {
      lineOne: entitySubtype.displayName,
      lineTwo: entityKindToDisplayName(entity.kind),
    },
    effectiveDate,
    endDate,
    beneficiaries,
    assets: entitySubtype.assetSummaryDisplay || EMPTY_CONTENT_HYPHEN,
    currentValue: {
      lineOne: dollarsValue,
      lineTwo: showAsOfDate ? asOfDate : null,
    },
    distributedValue: {
      lineOne: distributedValue,
      lineTwo: distributionDate,
    },
    assetLocation: {
      lineOne: assetLocation,
      lineTwo: gstStatus,
    },
    federalAssetLocation: {
      lineOne: federalAssetLocation || EMPTY_CONTENT_HYPHEN,
      lineTwo: gstStatus,
    },
    stateAssetLocation: {
      lineOne: stateAssetLocation || EMPTY_CONTENT_HYPHEN,
      lineTwo: '',
    },
    status,
    _currentValueSort: dollarsValue,
    entityStage: entity.stage,
    entityKind: entity.kind,
    draftTabEntityLabel: getDraftTabEntityLabel(entity),
    swatch: getColorDefinitionForEntity({ entity, colors }),
    actions: (
      <ContextMenuButton>
        {entity.stage === EntityStage.Draft && (
          <ConvertToActiveMenuItem
            entityId={entity.id}
            subtypeId={entity.subtype.id}
            entityType={getEntityTypeFromEntityKind(entity.kind)}
            entityDisplayName={entity.subtype.displayName}
            menuItemProps={{
              muiMenuItemProps: {
                divider: true,
              },
            }}
          />
        )}
        <DeleteEntityMenuItem
          label="Delete entity"
          modalProps={{
            heading: 'Are you sure you want to delete this draft entity?',
          }}
          entityId={entity.id}
          onDelete={() => refetch?.()}
        />
      </ContextMenuButton>
    ),
  };
}

function mapTestamentaryEntityToRow(
  entity: TestamentaryEntityList_TestamentaryEntityFragment,
  householdId: string
) {
  const associatedEntityHasStateTax = !!entity.stateTax;

  return {
    id: entity.id,
    // This status is basically used to filter rows by selected tab.
    status: FilterOptions.TESTAMENTARY,
    entityName: {
      lineOne: (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={3}
        >
          <Typography variant="h5">{entity.displayName}</Typography>
        </Stack>
      ),
      lineTwo: testamentaryEntityKindToDisplayName(
        entity.kind,
        entity.grantorDeath?.displayName
      ),
    },
    createdAtTheDeathOf:
      entity.grantorDeath?.displayName ?? EMPTY_CONTENT_HYPHEN,
    beneficiaries:
      entity.beneficiaries
        ?.map((beneficiary) => beneficiary.summary.displayName)
        .join(', ') ?? EMPTY_CONTENT_HYPHEN,
    federalAssetLocation: {
      lineOne: getAssetLocationDisplay({
        inEstateStatus: entity.inEstateStatus,
        stateTaxes: [],
        gstStatus: entity.gstStatus,
      }).locationDisplayShort,
      lineTwo: getGstStatusDisplay({
        gstStatus: entity.gstStatus,
      }),
    },
    stateAssetLocation: associatedEntityHasStateTax
      ? {
          lineOne: getAssetLocationDisplay({
            inEstateStatus: entity.survivingSpouseStateInEstateStatus,
            stateTaxes: [],
            gstStatus: entity.gstStatus,
          }).locationDisplayShort,
          lineTwo: getGstStatusDisplay({
            gstStatus: entity.gstStatus,
          }),
        }
      : {
          lineOne: EMPTY_CONTENT_HYPHEN,
        },
    dyingGrantorId: entity.grantorDeath?.id ?? '',
    // This doesn't matter, because Testamentary entities
    // won't be shown with or sorted against the other "normal"
    // entities in the table.
    _currentValueSort: new Decimal(0),
    actions: (
      <ContextMenuButton>
        <DuplicateTestamentaryEntityMenuItem
          label="Duplicate"
          householdId={householdId}
          testamentaryEntityId={entity.id}
          icon={<Copy07Icon size={20} />}
        />
      </ContextMenuButton>
    ),
  };
}

/**
 * Indicates the type of entity, for entities in the "Drafts" tab.
 * @param entity
 */
function getDraftTabEntityLabel(entity: EntityListFragment) {
  switch (entity.stage) {
    case EntityStage.Draft:
      return 'Draft';
    case EntityStage.ReadyForProposal:
      return 'Ready for proposal';
    case EntityStage.Implementation:
      return 'Implementation';
    default:
      return '';
  }
}
