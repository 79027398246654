import { useApolloClient } from '@apollo/client';
import { Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { invalidateCacheObject } from '@/graphql/client.utils';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';

import { useCreateOrUpdateHypotheticalTransfersMutation } from '../HypotheticalTransfersCard/hypotheticalTransfers/graphql/CreateOrUpdateHypotheticalTransfers.generated';
import { TransferDirection } from '../HypotheticalTransfersCard/hypotheticalTransfers/HypotheticalTransferForm';
import {
  TransferDetails,
  TransferSourceTarget,
  TransferType,
} from './components';
import { TransferDiscount } from './components/TransferDiscount';
import { useHypotheticalTransferModalQuery } from './graphql/HypotheticalTransferModal.generated';
import { getDefaultValues } from './HypotheticalTransferModal.constants';
import {
  HypotheticalTransferModalContextProvider,
  HypotheticalTransferModalContextShape,
} from './HypotheticalTransferModal.context';
import {
  HypotheticalTransferFormModalShape,
  HypotheticalTransferUpdateEstateWaterfallPayload,
} from './HypotheticalTransferModal.types';
import { mapDataToHypotheticalTransferModalForm } from './HypotheticalTransferModal.utils';
import { getCreatePayload as getCreateTransferPayload } from './utils/getCreatePayload';
import { getUpdatePayload as getUpdateTransferPayload } from './utils/getUpdatePayload';

export interface HypotheticalTransferModalProps {
  initiatingDirection?: TransferDirection;
  initiatingId?: string;
  waterfallId: string;
  householdId: string;
  transferId?: string;
  isOpen: boolean;
  onClose: () => void;
  onDelete?: () => void;
}

function HypotheticalTransferModalInner({
  initiatingDirection,
  initiatingId,
  householdId,
  waterfallId,
  transferId,
  isOpen,
  onClose,
  onDelete: onDeleteExternal,
}: HypotheticalTransferModalProps) {
  useSubmitSuccessHandler(onClose);
  const context: Omit<
    HypotheticalTransferModalContextShape,
    'derivedData' | 'setDerivedData'
  > = useMemo(
    () => ({
      initiatingDirection,
      initiatingId,
      householdId,
      waterfallId,
      transferId,
    }),
    [householdId, initiatingDirection, initiatingId, transferId, waterfallId]
  );

  const client = useApolloClient();
  const trackUserEvent = useTrackUserEvent();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const defaultValues = getDefaultValues(
    initiatingId,
    transferId,
    initiatingDirection
  );

  const { control, reset, formState, handleSubmit } =
    useFormContext<HypotheticalTransferFormModalShape>();

  const [sourceInsurancePolicyId] = useWatch({
    control,
    name: ['sourceInsurancePolicyId'],
  });

  const showDiscount = useMemo(() => {
    // We do not want to discount insurance policy transfers
    if (sourceInsurancePolicyId) return false;

    return true;
  }, [sourceInsurancePolicyId]);

  const heading = `${transferId ? 'Edit' : 'Create'} transfer`;

  const { loading: loadingData, data: waterfallData } =
    useHypotheticalTransferModalQuery({
      variables: {
        waterfallId,
        transferId,
        hasExistingTransfer: !!transferId,
      },
      // since this query overlaps with the waterfall's query, but only pulls a subset of data
      // do not cache the result to avoid a runaway waterfall re-render
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        let formData = defaultValues;
        if (transferId) {
          formData = mapDataToHypotheticalTransferModalForm(data, initiatingId);
        }
        reset(formData);
      },
    });

  const [updateEstateWaterfall, { loading: savingData }] =
    useCreateOrUpdateHypotheticalTransfersMutation({
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        reportError('Error creating or updating hypothetical transfers', error);
      },
      onCompleted: async (data) => {
        onClose();
        await invalidateCacheObject(data.updateEstateWaterfall, client);
      },
    });

  const onDelete = useCallback(async () => {
    if (!transferId) {
      throw new Error('Transfer ID is required for deletion');
    }

    await updateEstateWaterfall({
      variables: {
        input: {
          id: waterfallId,
          update: {
            removeHypotheticalTransferIDs: [transferId],
          },
        },
      },
    });
    if (onDeleteExternal) {
      onDeleteExternal();
    }
  }, [onDeleteExternal, transferId, updateEstateWaterfall, waterfallId]);

  const submitCallback = useCallback(
    async (formData: HypotheticalTransferFormModalShape) => {
      let payload: HypotheticalTransferUpdateEstateWaterfallPayload | null =
        null;
      if (!transferId) {
        payload = getCreateTransferPayload(
          formData,
          waterfallData,
          householdId
        );
      } else {
        payload = getUpdateTransferPayload(
          formData,
          waterfallData,
          transferId,
          householdId
        );
      }

      if (!payload) {
        showFeedback(
          'Could not save the transfer.  Please double-check the form data.'
        );
        return;
      }

      const { message, input } = payload;

      if (message || !input) {
        showFeedback(
          "Could not save the transfer.  Please double-check the form's data."
        );
        return;
      }

      await updateEstateWaterfall({
        variables: {
          input,
        },
      });

      if (!transferId) {
        trackUserEvent('transfer created');
      }
    },
    [
      householdId,
      showFeedback,
      trackUserEvent,
      transferId,
      updateEstateWaterfall,
      waterfallData,
    ]
  );

  const onSubmit = handleSubmit(submitCallback);

  const loading = loadingData || savingData || formState.isSubmitting;

  return (
    <HypotheticalTransferModalContextProvider {...context}>
      <FormFieldsDisabledProvider isDisabled={loading}>
        <FormModal
          heading={heading}
          isOpen={isOpen}
          onClose={onClose}
          actions={
            <FormModalActions.Provider formState={formState} disabled={loading}>
              {transferId && (
                <FormModalActions.DeleteButton
                  disabled={loading}
                  onConfirmDelete={onDelete}
                />
              )}
              <FormModalActions.CancelButton onClick={onClose} />
              <FormModalActions.SaveButton onClick={onSubmit}>
                {transferId ? 'Save changes' : 'Create transfer'}
              </FormModalActions.SaveButton>
            </FormModalActions.Provider>
          }
        >
          <form>
            <Stack spacing={3}>
              <TransferSourceTarget />
              <TransferDetails />
              <TransferType />
              {showDiscount && <TransferDiscount />}
            </Stack>
          </form>
        </FormModal>
      </FormFieldsDisabledProvider>
    </HypotheticalTransferModalContextProvider>
  );
}

export function HypotheticalTransferModal(
  props: HypotheticalTransferModalProps
) {
  const { initiatingId, transferId, initiatingDirection } = props;

  const defaultValues = getDefaultValues(
    initiatingId,
    transferId,
    initiatingDirection
  );

  const formMethods = useForm<HypotheticalTransferFormModalShape>({
    defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <HypotheticalTransferModalInner {...props} />
    </FormProvider>
  );
}
