import { Stack } from '@mui/material';
import { compact } from 'lodash';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { LinkTabProps } from '@/components/navigation/NavigationTabs';
import { PageHeader } from '@/components/navigation/PageHeader';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { AddIndividualOrOrganization } from './AddIndividualOrOrganization';

export function ClientPeoplePage() {
  const householdId = useRequiredParam('householdId');
  const familyTreeFF = useFeatureFlag('client_family_tree');

  const tabs: LinkTabProps[] = useMemo(() => {
    return compact([
      familyTreeFF && {
        display: 'Family tree',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_FAMILY_TREE,
          {
            householdId,
          }
        ),
      },
      {
        display: 'Individuals & organizations',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_PEOPLE_ORGANIZATIONS,
          {
            householdId,
          }
        ),
      },
      {
        display: 'Client team',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_PEOPLE_CLIENT_TEAM,
          {
            householdId,
          }
        ),
      },
    ]);
  }, [familyTreeFF, householdId]);

  return (
    <Stack height="100%">
      <PageHeader
        heading="People"
        tabs={tabs}
        action={
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <AddIndividualOrOrganization householdId={householdId} />
          </Stack>
        }
      />
      <Stack p={3} pb={4} flex={1}>
        <Outlet />
      </Stack>
    </Stack>
  );
}
