// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
export type TenantPresentationConfiguration_TenantDisclaimerConfigurationFragment = { __typename?: 'TenantDisclaimerConfiguration', id: string, includeLuminaryDisclaimer: boolean, presentationCoverDisclaimerTitle?: string | null, presentationCoverDisclaimerBody?: string | null, presentationFooterDisclaimerTitle?: string | null, presentationFooterDisclaimerBody?: string | null, presentationStandaloneDisclaimerTitle?: string | null, presentationStandaloneDisclaimerBody?: string | null };

export const TenantPresentationConfiguration_TenantDisclaimerConfigurationFragmentDoc = gql`
    fragment TenantPresentationConfiguration_TenantDisclaimerConfiguration on TenantDisclaimerConfiguration {
  id
  includeLuminaryDisclaimer
  presentationCoverDisclaimerTitle
  presentationCoverDisclaimerBody
  presentationFooterDisclaimerTitle
  presentationFooterDisclaimerBody
  presentationStandaloneDisclaimerTitle
  presentationStandaloneDisclaimerBody
}
    `;
export const Operations = {
  Fragment: {
    TenantPresentationConfiguration_TenantDisclaimerConfiguration: 'TenantPresentationConfiguration_TenantDisclaimerConfiguration' as const
  }
}