import { Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import { SectionHeaderWithAccent } from '@/components/display/SectionHeaderWithAccent/SectionHeaderWithAccent';
import { Button } from '@/components/form/baseInputs/Button';
import { SplitScreenLayout } from '@/components/layout/SplitScreenLayout/SplitScreenLayout';
import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { Footer } from '@/components/navigation/Footer';
import { Callout } from '@/components/notifications/Callout/Callout';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { TextEditor } from '@/components/TextEditor/TextEditor';
import DocumentViewerWithSearch from '@/modules/documents/components/DocumentViewerWithSearch';
import { useCreateSummary } from '@/modules/documents/hooks/useCreateSummary';
import { ConfirmDiscardChangesModal } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.components';
import {
  DocumentSummaryEntryMode,
  DocumentSummarySummaryFormat,
} from '@/types/schema';

import { TrustSummaryData } from '../trusts/TrustDetails/TrustDetails.types';
import { normalizeWhitespace } from './documents.utils';
import { useSaveSummary } from './hooks/useSaveSummary';

interface SummaryFormShape {
  summaryContent: string;
}

interface EditSummaryTrowserProps {
  isOpen: boolean;
  onClose: () => void;
  documentId: string;
  documentSrc: string;
  documentSummary?: NonNullable<TrustSummaryData['documentSummary']>;
  hasAdditionalEntities?: boolean;
}

function EditSummaryTrowserInner({
  isOpen,
  onClose,
  documentId,
  documentSrc,
  documentSummary,
  hasAdditionalEntities,
}: EditSummaryTrowserProps) {
  const { handleSubmit, setValue, formState, reset } =
    useFormContext<SummaryFormShape>();

  // We don't want to use shouldBlockNavigation because we have a separate confirmation modal and that will block navigation on the underlying page
  const [summaryIsDirty, setSummaryIsDirty] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { saveSummary } = useSaveSummary({ documentId });
  const { createSummary } = useCreateSummary({ documentId });

  const onValidSubmission: SubmitHandler<SummaryFormShape> = (values) => {
    if (!documentSummary) {
      return createSummary({
        variables: {
          documentID: documentId,
          input: {
            documentID: documentId,
            summary: values.summaryContent,
            summaryFormat: DocumentSummarySummaryFormat.Markdown,
            entryMode: DocumentSummaryEntryMode.User,
          },
        },
      });
    }

    return saveSummary({
      variables: {
        updateDocumentSummaryId: documentSummary.id,
        input: {
          summary: values.summaryContent,
          summaryFormat: DocumentSummarySummaryFormat.Markdown,
          entryMode: DocumentSummaryEntryMode.User,
        },
      },
    });
  };

  const onSubmit = handleSubmit(onValidSubmission);

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const handleRequestDiscardAndClose = useCallback(() => {
    if (summaryIsDirty) {
      setShowConfirmationModal(true);
      return;
    }
    handleClose();
  }, [handleClose, summaryIsDirty]);

  const handleCloseConfirmDiscardAndCloseModal = useCallback(
    (reason: 'confirm' | 'cancel') => {
      setShowConfirmationModal(false);
      if (reason === 'confirm') {
        onClose();
      }
    },
    [onClose]
  );

  useSubmitSuccessHandler(handleClose);

  return (
    <>
      <FullScreenModal isOpen={isOpen} onClose={handleClose}>
        <form onSubmit={onSubmit}>
          <SplitScreenLayout
            leftContent={
              <DocumentViewerWithSearch
                documentId={documentId}
                documentSrc={documentSrc}
                sx={{ borderRadius: 0 }}
              />
            }
            rightContent={
              <Stack flex={1} p={3} gap={3} height="100%" overflow="hidden">
                <SectionHeaderWithAccent
                  header={{
                    heading: documentSummary
                      ? 'Edit document summary'
                      : 'Create document summary',
                  }}
                />
                <TextEditor
                  initialValue={documentSummary?.summary || ''}
                  initialValueKind="markdown"
                  onUpdate={(str) => {
                    // When the summary string is transformed to markdown, the whitespace
                    // in the string is altered. We normalize the whitespace to avoid
                    // false positives in the diff, which prompts the user to save
                    // despite making no changes.
                    const normalizedNew = normalizeWhitespace(str);
                    const normalizedOriginal = normalizeWhitespace(
                      documentSummary?.summary || ''
                    );

                    if (normalizedNew !== normalizedOriginal) {
                      setValue('summaryContent', str);
                      setSummaryIsDirty(true);
                    }
                  }}
                />
                {hasAdditionalEntities && (
                  <Callout severity="warning">
                    Edits to this document summary apply to all shared entities
                  </Callout>
                )}
              </Stack>
            }
            footer={
              <Footer
                rightAction={
                  <Stack
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                    direction="row"
                  >
                    <Button
                      onClick={handleRequestDiscardAndClose}
                      variant="secondary"
                      size="sm"
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      size="sm"
                      loading={formState.isSubmitting}
                    >
                      Save changes
                    </Button>
                  </Stack>
                }
              />
            }
          />
        </form>
      </FullScreenModal>
      <ConfirmDiscardChangesModal
        isOpen={showConfirmationModal}
        onClose={handleCloseConfirmDiscardAndCloseModal}
      />
    </>
  );
}

export function EditSummaryTrowser(props: EditSummaryTrowserProps) {
  const { documentSummary } = props;

  const formMethods = useForm<SummaryFormShape>({
    defaultValues: {
      summaryContent: documentSummary?.summary || '',
    },
  });

  return (
    <FormProvider {...formMethods}>
      <EditSummaryTrowserInner {...props} />
    </FormProvider>
  );
}
