import Decimal from 'decimal.js';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { GiftingProposalGiftNonTaxableGiftType } from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

export function getGiftDetailLabel({
  nonTaxableGiftType,
  isTaxable,
  gifters,
}: {
  nonTaxableGiftType?: GiftingProposalGiftNonTaxableGiftType | null;
  isTaxable: boolean;
  gifters: string[];
}) {
  if (nonTaxableGiftType === GiftingProposalGiftNonTaxableGiftType.Charitable) {
    return 'Charitable gift';
  }

  if (
    nonTaxableGiftType === GiftingProposalGiftNonTaxableGiftType.AnnualExclusion
  ) {
    return 'Annual exclusion gift';
  }

  const gifter = (() => {
    if (gifters.length === 1) {
      return `(${gifters[0]})`;
    }

    if (gifters.length > 1) {
      return '(both clients)';
    }

    return '';
  })();

  if (isTaxable) {
    return `Taxable gift ${gifter}`;
  }

  return 'Non-taxable';
}

export function dateRangeOrStartYear({
  startYear,
  endYear,
}: {
  startYear: number | typeof EMPTY_CONTENT_HYPHEN;
  endYear?: number | null;
}) {
  if (startYear === EMPTY_CONTENT_HYPHEN) {
    return EMPTY_CONTENT_HYPHEN;
  }

  if (endYear) {
    return `${startYear}–${endYear}`;
  }

  return startYear.toString();
}

export function formatPercentGrowthOrDiscount({
  percent,
  isGrowth,
  isDiscount,
}: {
  percent?: Decimal | null;
  isGrowth?: boolean;
  isDiscount?: boolean;
}): string | undefined {
  if (!percent) {
    return undefined;
  }

  if (percent.isZero()) {
    return undefined;
  }

  const label = (() => {
    if (isGrowth) {
      return 'growth';
    }

    if (isDiscount) {
      return 'discount';
    }

    return '';
  })();

  return `${formatPercent(percent, 0)}% ${label}`;
}

export function getGiftLabelProps({
  amount,
  discountPercentage,
}: {
  amount: Decimal;
  discountPercentage?: Decimal | null;
}) {
  const primaryValue = (() => {
    if (!discountPercentage) {
      return formatCurrencyNoDecimals(amount);
    }
    if (discountPercentage.isZero()) {
      return formatCurrencyNoDecimals(amount);
    }
    return formatCurrencyNoDecimals(
      amount.times(new Decimal(1).minus(discountPercentage.div(100)))
    );
  })();

  const secondaryValue = (() => {
    if (!discountPercentage) {
      return undefined;
    }
    if (discountPercentage.isZero()) {
      return undefined;
    }
    return formatCurrencyNoDecimals(amount);
  })();

  return {
    primaryValue,
    secondaryValue,
  };
}
