import { BaseRadioGroupInputProps } from '@/components/form/baseInputs/inputTypes';
import { EstateTaxScenarioPayoutKind } from '@/types/schema';

export const payoutMethodOptions: BaseRadioGroupInputProps<EstateTaxScenarioPayoutKind>['options'] =
  [
    {
      label: 'Pro Rata',
      value: EstateTaxScenarioPayoutKind.ProRata,
      helpText:
        'Estimated estate taxes due are automatically deducted from pro rata from each entity subject to estate tax',
    },
    {
      label: 'Custom selection',
      value: EstateTaxScenarioPayoutKind.Custom,
      helpText:
        'Specify the order in which entities will be depleted to pay estate taxes at each trigger event',
    },
  ];

export const FIRST_DEATH_CLIENT_ID_SEARCH_PARAM = 'primaryClientId' as const;
