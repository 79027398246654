// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPossibleRelationshipOwnersQueryVariables = Types.Exact<{
  optionsLike: Types.EmployeeWhereInput;
  selectedOptionsLike: Types.EmployeeWhereInput;
  first: Types.Scalars['Int']['input'];
}>;


export type GetPossibleRelationshipOwnersQuery = { __typename?: 'Query', queryData: { __typename?: 'EmployeeConnection', totalCount: number, edges?: Array<{ __typename?: 'EmployeeEdge', node?: { __typename?: 'Employee', id: string, displayName: string, user: { __typename?: 'User', id: string } } | null } | null> | null }, selectedData: { __typename?: 'EmployeeConnection', totalCount: number, edges?: Array<{ __typename?: 'EmployeeEdge', node?: { __typename?: 'Employee', id: string, displayName: string, user: { __typename?: 'User', id: string } } | null } | null> | null } };


export const GetPossibleRelationshipOwnersDocument = gql`
    query GetPossibleRelationshipOwners($optionsLike: EmployeeWhereInput!, $selectedOptionsLike: EmployeeWhereInput!, $first: Int!) {
  queryData: possibleRelationshipOwnersV2(where: $optionsLike, first: $first) {
    totalCount
    edges {
      node {
        id
        displayName
        user {
          id
        }
      }
    }
  }
  selectedData: possibleRelationshipOwnersV2(
    where: $selectedOptionsLike
    first: 1
  ) {
    totalCount
    edges {
      node {
        id
        displayName
        user {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetPossibleRelationshipOwnersQuery__
 *
 * To run a query within a React component, call `useGetPossibleRelationshipOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPossibleRelationshipOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPossibleRelationshipOwnersQuery({
 *   variables: {
 *      optionsLike: // value for 'optionsLike'
 *      selectedOptionsLike: // value for 'selectedOptionsLike'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetPossibleRelationshipOwnersQuery(baseOptions: Apollo.QueryHookOptions<GetPossibleRelationshipOwnersQuery, GetPossibleRelationshipOwnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPossibleRelationshipOwnersQuery, GetPossibleRelationshipOwnersQueryVariables>(GetPossibleRelationshipOwnersDocument, options);
      }
export function useGetPossibleRelationshipOwnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPossibleRelationshipOwnersQuery, GetPossibleRelationshipOwnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPossibleRelationshipOwnersQuery, GetPossibleRelationshipOwnersQueryVariables>(GetPossibleRelationshipOwnersDocument, options);
        }
export function useGetPossibleRelationshipOwnersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetPossibleRelationshipOwnersQuery, GetPossibleRelationshipOwnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPossibleRelationshipOwnersQuery, GetPossibleRelationshipOwnersQueryVariables>(GetPossibleRelationshipOwnersDocument, options);
        }
export type GetPossibleRelationshipOwnersQueryHookResult = ReturnType<typeof useGetPossibleRelationshipOwnersQuery>;
export type GetPossibleRelationshipOwnersLazyQueryHookResult = ReturnType<typeof useGetPossibleRelationshipOwnersLazyQuery>;
export type GetPossibleRelationshipOwnersSuspenseQueryHookResult = ReturnType<typeof useGetPossibleRelationshipOwnersSuspenseQuery>;
export type GetPossibleRelationshipOwnersQueryResult = Apollo.QueryResult<GetPossibleRelationshipOwnersQuery, GetPossibleRelationshipOwnersQueryVariables>;
export const Operations = {
  Query: {
    GetPossibleRelationshipOwners: 'GetPossibleRelationshipOwners' as const
  }
}