import { useGuardedContext } from '@/hooks/useGuardedContext';
import { AuthorizationLevel, UserKind } from '@/types/schema';

import { UserContext } from '../UserContext';

export function useCurrentUser() {
  const userContext = useGuardedContext(UserContext, 'UserProvider');

  const currentUser = userContext.currentUser;
  if (!currentUser) return null;

  return {
    ...currentUser,

    // including these for backwards compatibility, but `isAdminUser` isn't specific enough,
    // so we'll need to delete that
    isInternalUser:
      currentUser.authorization.userKind === UserKind.LuminaryInternal ||
      currentUser.authorization.userKind === UserKind.DatadogInternal,
    isAdminUser:
      currentUser.authorization.internalEmployees === AuthorizationLevel.Admin,
  };
}
