import { Stack } from '@mui/material';
import { CSSProperties, PropsWithChildren } from 'react';
import { To, useNavigate } from 'react-router-dom';

import { HeaderWithPopover } from '@/components/form/baseInputs/Button/HeaderWithPopover';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { COLORS } from '@/styles/tokens/colors';

type BeneficiaryHeaderProps = PropsWithChildren<{
  color?: CSSProperties['color'];
  hasDropdown?: boolean;
}>;

export const BeneficiaryHeader = ({
  children,
  color,
  hasDropdown,
}: BeneficiaryHeaderProps) => {
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <TypographyClamped
        lines={1}
        variant="h1"
        component="h3"
        color={hasDropdown ? color : COLORS.GRAY[400]}
        textAlign="left"
        display="flex"
      >
        {children}
      </TypographyClamped>
    </Stack>
  );
};

interface BeneficiarySelectorProps {
  heading: string;
  menuItems: React.ReactNode;
  loading: boolean;
  hideChevron?: boolean;
  to?: To;
}

export function BeneficiarySelector({
  heading,
  menuItems,
  loading,
  hideChevron = false,
  to,
}: BeneficiarySelectorProps) {
  const navigate = useNavigate();

  return (
    <HeaderWithPopover
      slots={{
        Header: {
          component: BeneficiaryHeader,
          props: {
            hasDropdown: !hideChevron,
          },
        },
      }}
      heading={heading}
      buttonWithPopoverProps={{
        loading,
        buttonSx: {
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          },
          '&:hover *': {
            color: hideChevron ? COLORS.GRAY[500] : undefined,
          },
        },
        hideChevron,
        onClick: () => {
          to && navigate(to);
        },
      }}
    >
      {hideChevron ? null : menuItems}
    </HeaderWithPopover>
  );
}
