// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EntityTableImportMutationVariables = Types.Exact<{
  inputs: Array<Types.AugmentedCreateEntityInput> | Types.AugmentedCreateEntityInput;
}>;


export type EntityTableImportMutation = { __typename?: 'Mutation', createEntities: Array<{ __typename?: 'Entity', id: string } | null> };


export const EntityTableImportDocument = gql`
    mutation EntityTableImport($inputs: [AugmentedCreateEntityInput!]!) {
  createEntities(input: $inputs) {
    id
  }
}
    `;
export type EntityTableImportMutationFn = Apollo.MutationFunction<EntityTableImportMutation, EntityTableImportMutationVariables>;

/**
 * __useEntityTableImportMutation__
 *
 * To run a mutation, you first call `useEntityTableImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEntityTableImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [entityTableImportMutation, { data, loading, error }] = useEntityTableImportMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useEntityTableImportMutation(baseOptions?: Apollo.MutationHookOptions<EntityTableImportMutation, EntityTableImportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EntityTableImportMutation, EntityTableImportMutationVariables>(EntityTableImportDocument, options);
      }
export type EntityTableImportMutationHookResult = ReturnType<typeof useEntityTableImportMutation>;
export type EntityTableImportMutationResult = Apollo.MutationResult<EntityTableImportMutation>;
export type EntityTableImportMutationOptions = Apollo.BaseMutationOptions<EntityTableImportMutation, EntityTableImportMutationVariables>;
export const Operations = {
  Mutation: {
    EntityTableImport: 'EntityTableImport' as const
  }
}