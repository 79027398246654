// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
export type HouseholdHasIntegration_HouseholdFragment = { __typename?: 'Household', id: string, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind }> | null };

export const HouseholdHasIntegration_HouseholdFragmentDoc = gql`
    fragment HouseholdHasIntegration_Household on Household {
  id
  integrationClients {
    id
    kind
  }
}
    `;
export const Operations = {
  Fragment: {
    HouseholdHasIntegration_Household: 'HouseholdHasIntegration_Household' as const
  }
}