export enum DispositiveProvisionsTotalLineVariants {
  AccountsForAllAssets = 'AccountsForAllAssets',
  ExceedsAssets = 'ExceedsAssets',
  LessThanAssets = 'LessThanAssets',
}

export enum DispositionScheme {
  NONE = 'NONE', //  there are explicitly no dispositions
  UPON_FIRST_DEATH = 'UPON_FIRST_DEATH', // the dispositions occur upon first death
  UPON_SECOND_DEATH = 'UPON_SECOND_DEATH', // the dispositions occur upon second
}

export enum DispositionScheme_LEGACY {
  UponFirstDeathOnly = 'Upon first death only',
  UponSecondDeathOnly = 'Upon second death only',
  AtFirstAndSecondDeath = 'At first & second death',
}
