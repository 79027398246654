// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
export type BeneficiaryDropdown_HouseholdFragment = { __typename?: 'Household', id: string, possibleBeneficiariesV2: { __typename?: 'PossibleBeneficiariesV2', clients: Array<{ __typename?: 'ClientProfile', id: string, legalName: string }>, entities: Array<{ __typename?: 'Entity', id: string, subtype: { __typename?: 'CCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'CLTTrust', id: string, displayName: string } | { __typename?: 'CRTTrust', id: string, displayName: string } | { __typename?: 'CustodialPersonalAccount', id: string, displayName: string } | { __typename?: 'DonorAdvisedFund', id: string, displayName: string } | { __typename?: 'GPBusinessEntity', id: string, displayName: string } | { __typename?: 'GRATTrust', id: string, displayName: string } | { __typename?: 'ILITTrust', id: string, displayName: string } | { __typename?: 'IndividualPersonalAccount', id: string, displayName: string } | { __typename?: 'InsurancePersonalAccount', id: string, displayName: string } | { __typename?: 'IrrevocableTrust', id: string, displayName: string } | { __typename?: 'JointPersonalAccount', id: string, displayName: string } | { __typename?: 'LLCBusinessEntity', id: string, displayName: string } | { __typename?: 'LPBusinessEntity', id: string, displayName: string } | { __typename?: 'PrivateFoundation', id: string, displayName: string } | { __typename?: 'QPRTTrust', id: string, displayName: string } | { __typename?: 'QualifiedTuitionPersonalAccount', id: string, displayName: string } | { __typename?: 'RetirementPersonalAccount', id: string, displayName: string } | { __typename?: 'RevocableTrust', id: string, displayName: string } | { __typename?: 'SCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'SLATTrust', id: string, displayName: string } | { __typename?: 'SoleProprietorshipBusinessEntity', id: string, displayName: string } }>, organizations: Array<{ __typename?: 'ClientOrganization', id: string, name: string }> } };

export const BeneficiaryDropdown_HouseholdFragmentDoc = gql`
    fragment BeneficiaryDropdown_Household on Household {
  id
  possibleBeneficiariesV2 {
    clients {
      id
      legalName
    }
    entities {
      id
      subtype {
        id
        displayName
      }
    }
    organizations {
      id
      name
    }
  }
}
    `;
export const Operations = {
  Fragment: {
    BeneficiaryDropdown_Household: 'BeneficiaryDropdown_Household' as const
  }
}