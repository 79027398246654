import Decimal from 'decimal.js';
import { useCallback, useMemo } from 'react';

import { useChartColorDefinitions } from '@/components/charts/constants';
import { ENTITY_SEMANTIC_COLORS } from '@/modules/entities/entities.constants';
import { sumDecimalJS } from '@/utils/decimalJSUtils';

interface UseHouseholdEstateValueMetricsInput {
  metrics?: {
    inEstateValue: Decimal;
    outOfEstateFamilyValue: Decimal;
    outOfEstateCharityValue: Decimal;
  } | null;
}

export function useHouseholdEstateValueMetrics({
  metrics,
}: UseHouseholdEstateValueMetricsInput) {
  const colors = useChartColorDefinitions();

  const sum = useMemo(() => {
    if (!metrics) return new Decimal(0);
    return sumDecimalJS([
      metrics.inEstateValue,
      metrics.outOfEstateFamilyValue,
      metrics.outOfEstateCharityValue,
    ]);
  }, [metrics]);

  const onePercentOfSum = sum.dividedBy(100);

  const percents = useMemo(() => {
    if (!metrics)
      return {
        inEstateValue: new Decimal(0),
        outOfEstateFamilyValue: new Decimal(0),
        outOfEstateCharityValue: new Decimal(0),
      };
    return {
      inEstateValue: metrics.inEstateValue.dividedBy(sum).times(100),
      outOfEstateFamilyValue: metrics.outOfEstateFamilyValue
        .dividedBy(sum)
        .times(100),
      outOfEstateCharityValue: metrics.outOfEstateCharityValue
        .dividedBy(sum)
        .times(100),
    };
  }, [metrics, sum]);

  const getDisplayValue = useCallback(
    (section: keyof typeof percents) => {
      if (
        percents[section].lessThanOrEqualTo(1) &&
        percents[section].greaterThan(0)
      ) {
        // If the percentage is less than or equal to 1%, we set the slice value to
        // exactly one percent of the sum of the three values.
        return onePercentOfSum.toNumber();
      }
      return metrics?.[section].toNumber() ?? 0;
    },
    [metrics, onePercentOfSum, percents]
  );

  const pieChartSeriesData = useMemo(() => {
    if (!metrics) return [];
    return [
      {
        value: getDisplayValue('outOfEstateFamilyValue'),
        label: 'Out of estate (family)',
        color: colors[ENTITY_SEMANTIC_COLORS.outOfEstateFamily].backgroundColor,
      },
      {
        value: getDisplayValue('outOfEstateCharityValue'),
        label: 'Out of estate (charity)',
        color:
          colors[ENTITY_SEMANTIC_COLORS.outOfEstateCharity].backgroundColor,
      },
      {
        value: getDisplayValue('inEstateValue'),
        label: 'In estate',
        color: colors[ENTITY_SEMANTIC_COLORS.inEstate].backgroundColor,
      },
    ];
  }, [colors, getDisplayValue, metrics]);

  return { pieChartSeriesData, onePercentOfSum, sum, percents };
}
