import { AccessParameterKind, Maybe } from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';
import { getNodes } from '@/utils/graphqlUtils';

import { EntityMap_BeneficiaryConnectionFragment } from '../graphql/EntityMap.generated';

export type BeneficiaryConnection =
  Maybe<EntityMap_BeneficiaryConnectionFragment>;

export function getBeneficiaryId(b: BeneficiaryConnection) {
  return b?.entity?.id || b?.individual?.id || b?.organization?.id;
}

export function getBeneficiaryEdgeLabelValue(b: BeneficiaryConnection): string {
  const accessParameters = getNodes(b?.accessParameters) ?? [];

  if (accessParameters.length !== 1) return '';

  // TODO (LUM-1678) support the multiple distribution scenario
  const accessParameter = accessParameters[0];
  if (
    accessParameter?.amount &&
    accessParameter.kind === AccessParameterKind.Amount
  ) {
    return formatCurrencyNoDecimals(accessParameter.amount);
  }

  if (
    accessParameter?.percentage &&
    accessParameter.kind === AccessParameterKind.Percentage
  ) {
    return `${formatPercent(accessParameter.percentage, 0)}%`;
  }

  return '';
}
