// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssociatedEntityProposalCountQueryVariables = Types.Exact<{
  entityId: Types.Scalars['ID']['input'];
}>;


export type AssociatedEntityProposalCountQuery = { __typename?: 'Query', proposals: { __typename?: 'ProposalConnection', totalCount: number } };


export const AssociatedEntityProposalCountDocument = gql`
    query AssociatedEntityProposalCount($entityId: ID!) {
  proposals(where: {hasEntityProposalsWith: {hasEntityWith: {id: $entityId}}}) {
    totalCount
  }
}
    `;

/**
 * __useAssociatedEntityProposalCountQuery__
 *
 * To run a query within a React component, call `useAssociatedEntityProposalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssociatedEntityProposalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssociatedEntityProposalCountQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useAssociatedEntityProposalCountQuery(baseOptions: Apollo.QueryHookOptions<AssociatedEntityProposalCountQuery, AssociatedEntityProposalCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssociatedEntityProposalCountQuery, AssociatedEntityProposalCountQueryVariables>(AssociatedEntityProposalCountDocument, options);
      }
export function useAssociatedEntityProposalCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssociatedEntityProposalCountQuery, AssociatedEntityProposalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssociatedEntityProposalCountQuery, AssociatedEntityProposalCountQueryVariables>(AssociatedEntityProposalCountDocument, options);
        }
export function useAssociatedEntityProposalCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AssociatedEntityProposalCountQuery, AssociatedEntityProposalCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AssociatedEntityProposalCountQuery, AssociatedEntityProposalCountQueryVariables>(AssociatedEntityProposalCountDocument, options);
        }
export type AssociatedEntityProposalCountQueryHookResult = ReturnType<typeof useAssociatedEntityProposalCountQuery>;
export type AssociatedEntityProposalCountLazyQueryHookResult = ReturnType<typeof useAssociatedEntityProposalCountLazyQuery>;
export type AssociatedEntityProposalCountSuspenseQueryHookResult = ReturnType<typeof useAssociatedEntityProposalCountSuspenseQuery>;
export type AssociatedEntityProposalCountQueryResult = Apollo.QueryResult<AssociatedEntityProposalCountQuery, AssociatedEntityProposalCountQueryVariables>;
export const Operations = {
  Query: {
    AssociatedEntityProposalCount: 'AssociatedEntityProposalCount' as const
  }
}