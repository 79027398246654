import { Position } from '@xyflow/react';

import { InfoTooltip } from '@/components/poppers/Tooltip/InfoTooltip';
import { getAnnuitiesStatistics } from '@/modules/entities/gratTrusts/performance/getAnnuitiesStatistics';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatDateToMMDD } from '@/utils/formatting/dates';

import { EntityMap_NodeFragment } from '../../graphql/EntityMap.generated';
import { EntityGraph } from '../../types';
import { createEdge } from '../../utils/edges';
import { getGrantorId } from '../../utils/grantors';
import { getGrantorNodeId } from '../../utils/normalize';
import { addBeneficiaries } from '../beneficiaries';
import { addGrantors } from '../grantors';

export function addGRAT(graph: EntityGraph, entity: EntityMap_NodeFragment) {
  if (entity.subtype.__typename !== 'GRATTrust') {
    throw new Error(`Unexpected type: ${entity.subtype.__typename}`);
  }

  const { beneficiaries, grantor, annuities } = entity.subtype;
  addBeneficiaries(graph, entity.id, beneficiaries);
  addGrantors(graph, entity.id, [grantor]);

  if (!annuities?.length || !annuities[0]) return;

  // GRATs are always due annually, so we can safely pick the first one
  const dueDate = annuities[0].paymentWindowStartDate;
  const { remainingPayments } = getAnnuitiesStatistics(annuities);

  const grantorId = getGrantorId(grantor);
  if (!grantorId) return;
  const grantorNodeId = getGrantorNodeId(grantorId);

  // Currently only grats support payments of any kind.
  // Eventually payments will be a first class concept on entities and this will need to be refactored
  // Annuity payments currently go from the entity (grat) to a grantor only
  const edge = createEdge(graph, {
    source: entity.id,
    target: grantorNodeId,
    targetHandle: Position.Bottom,
    data: {
      variant: 'secondary',
      edgeLabel: {
        variant: 'secondary',
        label: 'Payment',
        value: formatCurrencyNoDecimals(remainingPayments),
        endIcon: (
          <InfoTooltip
            title={`Due annually on ${formatDateToMMDD(dueDate)}`}
            placement="top-start"
          />
        ),
      },
    },
  });

  graph.addEdgeSafe(edge.source, edge.target, {
    edge,
    type: 'payment',
  });
}
