import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { Card } from '@/components/layout/Card/Card';
import { Callout } from '@/components/notifications/Callout/Callout';

import { AnnuityDetailsTable } from './AnnuityDetailsTable';
import {
  AnnuityPageGratFragment,
  GetGratAnnuitiesPageDetailsQuery,
  useGetGratAnnuitiesPageDetailsQuery,
} from './graphql/AnnuitiesDetails.generated';
import { PaymentsSummary } from './PaymentsSummary';

function getGuardedType(
  data?: GetGratAnnuitiesPageDetailsQuery
): AnnuityPageGratFragment | null {
  if (
    data?.entity?.__typename !== 'Entity' ||
    data.entity.subtype.__typename !== 'GRATTrust'
  )
    return null;
  return data.entity.subtype;
}

interface GRATAnnuitiesPageProps {
  entityId: string;
}

export function GRATAnnuitiesPage({ entityId }: GRATAnnuitiesPageProps) {
  const { data, error } = useGetGratAnnuitiesPageDetailsQuery({
    variables: {
      entityId: entityId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const gratTrust = getGuardedType(data);
  const annuities = gratTrust?.annuities ?? [];
  return (
    <Stack height="100%" p={3} spacing={3} pb={4}>
      <Typography variant="h1" component="h2">
        Payment schedule
      </Typography>
      <PaymentsSummary annuities={annuities} />
      <Card variant="outlined" sx={{ p: 2 }}>
        {error ? (
          <Callout severity="error">
            We weren&apos; able to fetch your annuity details. Please refresh
            the page to try again.
          </Callout>
        ) : (
          <AnnuityDetailsTable
            initialFundingValue={
              gratTrust?.initialFundingValue ?? new Decimal(0)
            }
            annuities={annuities}
          />
        )}
      </Card>
    </Stack>
  );
}
