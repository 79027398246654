import {
  Box,
  Skeleton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { SearchSmIcon } from '@/components/icons/SearchSmIcon';
import {
  DisplayTable,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { COLORS } from '@/styles/tokens/colors';

import { SearchResult, SearchResultSource } from './GlobalSearch.utils';
import { SearchResultItem } from './SearchResultItem';

export interface SearchResultsProps {
  results: SearchResult[];
  loading: boolean;
  source: SearchResultSource;
}

export function SearchResults({
  results,
  source,
  loading,
}: SearchResultsProps) {
  let body: JSX.Element = (
    <Stack
      alignContent="center"
      justifyContent="center"
      textAlign="center"
      sx={{ pt: 3 }}
      spacing={1}
    >
      <Typography variant="h1">No results were found</Typography>
      <Typography variant="body1">
        Shorten your query or try different keywords
      </Typography>
    </Stack>
  );

  if (source === 'recents' && results.length) {
    body = (
      <DisplayTable columns={[{ headerName: 'Recently accessed items' }]}>
        {results.map((result, idx) => (
          <TableRow key={idx}>
            <TableCell sx={{ padding: 0 }}>
              <SearchResultItem {...result} index={idx} />
            </TableCell>
          </TableRow>
        ))}
      </DisplayTable>
    );
  } else if (source === 'recents') {
    body = (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ pt: 3 }}
        spacing={1}
      >
        <SearchSmIcon sx={{ color: COLORS.GRAY[200] }} size={48} />
        <Typography variant="h1">Start typing your query</Typography>
        <Typography variant="body1">
          Matching results will be returned here when you pause typing
        </Typography>
      </Stack>
    );
  } else if (loading) {
    body = (
      <Stack>
        <StyledTableRow>
          <StyledTableCell sx={{ display: 'block' }}>
            <Skeleton height={20} />
            <Skeleton height={16} />
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell sx={{ display: 'block' }}>
            <Skeleton height={20} />
            <Skeleton height={16} />
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell sx={{ display: 'block' }}>
            <Skeleton height={20} />
            <Skeleton height={16} />
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell sx={{ display: 'block' }}>
            <Skeleton height={20} />
            <Skeleton height={16} />
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell sx={{ display: 'block' }}>
            <Skeleton height={20} />
            <Skeleton height={16} />
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell sx={{ display: 'block' }}>
            <Skeleton height={20} />
            <Skeleton height={16} />
          </StyledTableCell>
        </StyledTableRow>
      </Stack>
    );
  } else if (source === 'search' && results.length) {
    body = (
      <Stack>
        {results.map((result, idx) => {
          return <SearchResultItem key={idx} {...result} index={idx} />;
        })}
      </Stack>
    );
  }

  return (
    <>
      <Box
        sx={(t) => ({
          p: 3,
          height: '500px',
          overflowY: 'auto',
          boxShadow: t.palette.shadows.mdInset,
        })}
      >
        {body}
      </Box>
    </>
  );
}
