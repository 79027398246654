import { ApolloError, useApolloClient } from '@apollo/client';
import { Stack } from '@mui/material';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import {
  FullScreenFormLayout,
  ModalFooter,
} from '@/components/modals/FullScreenModal/FullScreenFormLayout';
import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import {
  AssetsSubform,
  AssetsSubformType,
  NAMESPACE as ASSETS_SUBFORM_NAMESPACE,
  VariantType as AssetsSubformVariantType,
} from '@/modules/assets/AssetsSubform/AssetsSubform';
import { useUpdateEntityMutation } from '@/modules/entities/graphql/UpdateEntity.generated';
import {
  AugmentedCreateAssetValuationV2Input,
  AugmentedUpdateAssetValuationV2Input,
} from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import { useUpdateAssetValuationMutation } from '../graphql/mutations.generated';
import { serializers } from './serializers';

interface Props {
  householdId: string;
  entityId: string;
  subtypeId: string;
  headerText?: string;
  isOpen: boolean;
  submitCopy?: string;
  onClose: () => void;
  assetsSubformType: AssetsSubformVariantType;
}

interface AssetsFullScreenModalForm {
  assetsSubform: AssetsSubformType;
}

const {
  FormComponent: AssetsFormComponent,
  defaultValues: assetsFormDefaultValues,
} = new AssetsSubform();

const initialFormValues = {
  [ASSETS_SUBFORM_NAMESPACE]: assetsFormDefaultValues,
};

export function AssetFullScreenModal({
  entityId,
  subtypeId,
  householdId,
  headerText = 'Update valuation',
  isOpen,
  submitCopy = 'Update valuation',
  onClose,
  assetsSubformType,
}: Props) {
  const client = useApolloClient();
  const { showFeedback } = useFeedback();
  const formMethods = useForm<AssetsFullScreenModalForm>({
    defaultValues: initialFormValues,
  });
  const { handleSubmit, reset } = formMethods;
  const [updateAssetValuation] = useUpdateAssetValuationMutation();
  const [updateEntity] = useUpdateEntityMutation();

  const handleClose = () => {
    onClose();
    reset(initialFormValues);
  };

  // handlers
  const onValidSubmission: SubmitHandler<AssetsFullScreenModalForm> = async (
    values
  ) => {
    const formValues = values[ASSETS_SUBFORM_NAMESPACE];

    if (formValues === null) {
      throw new Error('Form values are null');
    }

    if (
      assetsSubformType === 'initialFundingRevaluation' &&
      !formValues.confirm
    ) {
      showFeedback(
        'Please indicate that you confirm the changes before submitting.'
      );
      return;
    }

    const initialValuationForms: AssetsSubformVariantType[] = [
      'initialFundingRevaluation',
      'verifyInitialFunding',
    ];

    const revaluationForms: AssetsSubformVariantType[] = ['updateHoldings'];

    const serializer = serializers[assetsSubformType];

    if (initialValuationForms.includes(assetsSubformType)) {
      await updateAssetValuation({
        variables: {
          input: serializer(formValues) as AugmentedUpdateAssetValuationV2Input,
        },
        onCompleted: async () => {
          showFeedback(
            'Your initial valuation information was saved successfully.',
            { variant: 'success' }
          );

          await client.refetchQueries({
            include: 'active',
          });

          handleClose();
        },
        onError: (err) => {
          diagnostics.error('Failed to save VerifyInitialFunding changes', err);
          showFeedback('Sorry, we failed to save your data. Please try again.');
        },
      });
    } else if (revaluationForms.includes(assetsSubformType)) {
      const createAssetValuationInput = serializer(
        formValues
      ) as AugmentedCreateAssetValuationV2Input;
      await updateEntity({
        variables: {
          input: {
            id: entityId,
            update: {},
            updateGratTrust: {
              id: subtypeId,
              update: {},
              updateDesignerAccount: {
                id: formValues.accountId!,
                update: {},
                withValuations: [createAssetValuationInput],
              },
            },
          },
        },
        onCompleted: async () => {
          showFeedback('Your holdings were updated successfully.', {
            variant: 'success',
          });
          await client.refetchQueries({
            include: 'active',
            updateCache(cache) {
              cache.evict({ fieldName: 'assetValuationV2s' });
            },
          });

          handleClose();
        },
        onError: (err: ApolloError) => {
          diagnostics.error('Failed to save VerifyInitialFunding changes', err);
          showFeedback('Sorry, we failed to save your data. Please try again.');
        },
      });
    } else {
      throw new Error(`Unhandled variant ${assetsSubformType}`);
    }
  };
  const submitHandler = handleSubmit(onValidSubmission);

  return (
    <FormProvider {...formMethods}>
      <FullScreenModal isOpen={isOpen}>
        {isOpen && (
          <Stack component="form" noValidate onSubmit={submitHandler}>
            <FullScreenFormLayout
              heading={headerText}
              Content={
                <AssetsFormComponent
                  householdId={householdId}
                  entityId={entityId}
                  forceConfirmValuation={false}
                  dateCopy="Valuation date"
                  variant={assetsSubformType}
                />
              }
              Footer={
                <ModalFooter
                  submitCopy={submitCopy}
                  onRequestClose={handleClose}
                />
              }
            />
          </Stack>
        )}
      </FullScreenModal>
    </FormProvider>
  );
}
