import { styled, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { compact, isPlainObject, keyBy, uniqBy } from 'lodash';
import * as React from 'react';
import { useMemo } from 'react';

import { Loader } from '@/components/progress/Loader/Loader';
import { COLORS } from '@/styles/tokens/colors';

import { FormControl } from '../FormControl';
import {
  BaseBackendTypeaheadSelectInputProps,
  FormControlBackendTypeaheadSelectInputProps,
  HelpTextVariant,
  SelectInputOption,
  TypeaheadSelectInputOption,
} from '../inputTypes';
import { SelectItemGroupLabel } from '../SelectInput/SelectItemGroupLabel';
import { BackendTypeaheadOption } from './BackendTypeaheadOption';

export type ValidTypeaheadValue = string | number;

const GroupItems = styled('ul')({
  padding: 0,
});

// TODO(T2-599): dedupe this component with the static typeahead file
const BaseTypeaheadSelectInput = ({
  options,
  testId,
  required,
  inputRef,
  noOptionsText,
  inputValue,
  onInputChange,
  endAdornment,
  ...inputProps // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: BaseBackendTypeaheadSelectInputProps<any>) => {
  const { disabled } = inputProps;
  // explicitly splitting off error and autocomplete so we don't pass them to
  // the top-level Autocomplete element, which doesn't accept them
  const { autoComplete, error, ...otherInputProps } = inputProps;
  const disabledStyle = disabled ? { background: COLORS.GRAY[50] } : {};
  const mergedSx = Object.assign({ background: 'white', m: 0 }, disabledStyle);

  const optionValueLabelMap = React.useMemo(() => {
    return options.reduce<Record<string, string>>((acc, option) => {
      acc[JSON.stringify(option.value)] = option.display;
      return acc;
    }, {});
  }, [options]);

  return (
    // this select is a non-optimal experience for mobile; consider using NativeSelect here for a mobile device
    <Autocomplete<TypeaheadSelectInputOption<unknown>, false, boolean>
      autoHighlight
      openOnFocus
      disableClearable
      {...otherInputProps}
      onChange={(event, option, reason, details) =>
        otherInputProps.onChange?.(event, option?.value, reason, details)
      }
      options={options}
      inputValue={inputValue}
      onInputChange={(_, value, reason) => onInputChange?.(value, reason)}
      noOptionsText={noOptionsText ?? 'No options'}
      isOptionEqualToValue={(option, value) => option?.value === value}
      // we need renderOption here because MUI autocomplete defaults to using the `display` value as a key,
      // and there can be issues if there are multiple options with the same display value. we need to force
      // it to use the value as a key instead.
      renderOption={(props, option) => {
        const isSelectedOption = inputProps.value === option.value;
        return (
          <BackendTypeaheadOption
            {...props}
            key={String(option.value)}
            isSelected={isSelectedOption}
            display={option.display}
            badgeText={option.badgeText}
          />
        );
      }}
      renderGroup={(params) => (
        <li key={params.key}>
          {params.group && (
            <SelectItemGroupLabel sx={{ top: '-20px' }} label={params.group} />
          )}
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      // this method name is misleading. it actually passes the whole option when the
      // selection popover is opened, and just the value when the popover is closed.
      // see: https://github.com/mui/material-ui/issues/31192
      getOptionLabel={(valueOrOption) => {
        // this is the case where we're handling the selectInputOption
        if (isPlainObject(valueOrOption) && 'display' in valueOrOption) {
          return valueOrOption.display;
        }

        // this is the case where we have the value
        const value = valueOrOption as unknown as ValidTypeaheadValue;
        const lookupKey = JSON.stringify(value);
        return optionValueLabelMap[lookupKey] || '';
      }}
      getOptionDisabled={(option) => !!option.disabled}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputRef={inputRef}
            variant="outlined"
            error={error}
            placeholder={inputProps.placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment,
              sx: mergedSx,
              inputProps: {
                ...params.inputProps,
                required,
                autoComplete,
                'data-testid':
                  testId ?? `select-input-${inputProps.name ?? ''}`,
              },
            }}
          />
        );
      }}
    />
  );
};

export interface BackendTypeaheadSelectProps<V extends ValidTypeaheadValue>
  extends FormControlBackendTypeaheadSelectInputProps<V> {
  label: string;
  id?: string;
  // the presence of contextualHelp indicates that there's help text related to this input, which
  // will cause the "info" icon to show up next to the input label
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  hideLabel?: boolean;
  autoSelect?: boolean;
  loading?: boolean;
  emptyOption?: TypeaheadSelectInputOption<V>;
}

export function BackendTypeaheadSelectInput<V extends ValidTypeaheadValue>({
  id,
  label,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant,
  hideLabel,
  loading,
  emptyOption,
  options,
  ...inputProps
}: BackendTypeaheadSelectProps<V>) {
  const { value: selectedValue } = inputProps;

  const allOptionsByValue = useMemo(() => {
    return keyBy(options, 'value');
  }, [options]);

  const finalOptions = React.useMemo(() => {
    // we want to make sure to always include both the current query options *and* the option that corresponds
    // to the current value, but don't include the same option more than once
    if (selectedValue) {
      return uniqBy<SelectInputOption<V>>(
        compact([
          emptyOption,
          // put the currently-selected option first in the list
          allOptionsByValue[JSON.stringify(selectedValue)],
          ...options,
        ]),
        'value'
      );
    }

    return compact([emptyOption, ...options]);
  }, [selectedValue, emptyOption, allOptionsByValue, options]);

  const inputValue = useMemo(() => {
    return inputProps.inputValue ?? '';
  }, [inputProps.inputValue]);

  return (
    <>
      <FormControl<BaseBackendTypeaheadSelectInputProps<V>>
        id={id}
        hideLabel={hideLabel}
        contextualHelp={contextualHelp}
        component={BaseTypeaheadSelectInput}
        label={label}
        helpText={helpText}
        helpTextVariant={helpTextVariant}
        required={inputProps.required}
        errorMessage={errorMessage}
        inputProps={{
          ...inputProps,
          inputValue,
          options: finalOptions,
          noOptionsText: loading ? 'Loading...' : undefined,
          endAdornment: loading ? (
            <Loader
              boxProps={{
                alignItems: 'center',
                position: 'absolute',
                right: 10,
                top: 13,
              }}
              circularProgressProps={{ size: 15 }}
            />
          ) : undefined,
        }}
      />
    </>
  );
}
