import { useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useRequiredParams } from '@/hooks/useRequiredParams';
import { isRouteKey, NavigationKey } from '@/navigation/navigationUtils';
import { useUnloadPromptContext } from '@/navigation/unloadPrompt.context';

/**
 * @description This is a hook meant to be used in route modals where
 * navigating back to the previous route needs to be delayed until the
 * modal animation is finished
 * @param routeKey If present, will navigate to the passed routeKey on close. Otherwise, will navigate to the immediate parent route.
 *
 * By default, we expect the modal to be the direct child of the route we want to go back to.
 */
export function useRouteModal(routeKey: NavigationKey = '..') {
  const { navigate: navigateToRoute } = useNavigateToRoute();
  const navigate = useNavigate();
  const theme = useTheme();
  const params = useRequiredParams();
  const [searchParams] = useSearchParams();

  const [localState, setLocalState] = useState<boolean>(true);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const { setShouldBlockNavCtx } = useUnloadPromptContext();

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [setShouldBlockNavCtx]);

  const onRequestClose = useCallback(() => {
    setShouldBlockNavCtx(false);
    setLocalState(false);

    // Wait a little for the modal animation to finish
    timeoutRef.current = setTimeout(() => {
      if (!isRouteKey(routeKey)) {
        navigate(routeKey as string);
      } else {
        navigateToRoute(routeKey, params, searchParams);
      }
    }, theme.transitions.duration.leavingScreen);
  }, [
    navigate,
    navigateToRoute,
    params,
    routeKey,
    searchParams,
    setShouldBlockNavCtx,
    theme.transitions.duration.leavingScreen,
  ]);

  return {
    onRequestClose,
    isOpen: localState,
  };
}
