import { Box } from '@mui/material';
import { useMemo } from 'react';

import { Callout } from '@/components/notifications/Callout/Callout';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { EntityBeneficiariesSubform } from '../../EntityBeneficiariesSubform/EntityBeneficiariesSubform';
import {
  BENEFICIARIES_AFTER_BOTH_GRANTORS_DIE,
  BENEFICIARIES_SUBFORM,
} from '../../EntityBeneficiariesSubform/EntityBeneficiariesSubform.constants';

const { FormComponent: BeneficiariesFormComponent } =
  new EntityBeneficiariesSubform();

export function BeneficiariesTab({ bothDie }: { bothDie: boolean }) {
  const { householdId, isTwoClientHousehold: isTwoGrantorClient } =
    useHouseholdDetailsContext();

  const namespace = useMemo(() => {
    if (bothDie) {
      return BENEFICIARIES_AFTER_BOTH_GRANTORS_DIE;
    }

    return BENEFICIARIES_SUBFORM;
  }, [bothDie]);

  return (
    <>
      {isTwoGrantorClient && (
        <>
          {bothDie && (
            <Box pb={3}>
              <Callout severity="warning" textVariant="subtitle2">
                Only enter this information if this testamentary entity
                continues to exist after the death of the second client. In
                order to specify the disposition of this entity after the death
                of the second client, please navigate to the
                &quot;Disposition&quot; section of this testamentary entity.
              </Callout>
            </Box>
          )}
          {!bothDie && (
            <Box pb={3}>
              <Callout severity="warning" textVariant="subtitle2">
                Input beneficiaries that have access to this entity during the
                lifetime of the surviving spouse.
              </Callout>
            </Box>
          )}
        </>
      )}
      <BeneficiariesFormComponent
        variant="testamentaryEntity"
        householdId={householdId!}
        namespace={namespace}
      />
    </>
  );
}
