// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkCreateDocumentsMutationVariables = Types.Exact<{
  input: Array<Types.CreateDocumentInput> | Types.CreateDocumentInput;
}>;


export type BulkCreateDocumentsMutation = { __typename?: 'Mutation', createDocuments: Array<{ __typename?: 'Document', id: string }> };


export const BulkCreateDocumentsDocument = gql`
    mutation BulkCreateDocuments($input: [CreateDocumentInput!]!) {
  createDocuments(input: $input) {
    id
  }
}
    `;
export type BulkCreateDocumentsMutationFn = Apollo.MutationFunction<BulkCreateDocumentsMutation, BulkCreateDocumentsMutationVariables>;

/**
 * __useBulkCreateDocumentsMutation__
 *
 * To run a mutation, you first call `useBulkCreateDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkCreateDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkCreateDocumentsMutation, { data, loading, error }] = useBulkCreateDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBulkCreateDocumentsMutation(baseOptions?: Apollo.MutationHookOptions<BulkCreateDocumentsMutation, BulkCreateDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkCreateDocumentsMutation, BulkCreateDocumentsMutationVariables>(BulkCreateDocumentsDocument, options);
      }
export type BulkCreateDocumentsMutationHookResult = ReturnType<typeof useBulkCreateDocumentsMutation>;
export type BulkCreateDocumentsMutationResult = Apollo.MutationResult<BulkCreateDocumentsMutation>;
export type BulkCreateDocumentsMutationOptions = Apollo.BaseMutationOptions<BulkCreateDocumentsMutation, BulkCreateDocumentsMutationVariables>;
export const Operations = {
  Mutation: {
    BulkCreateDocuments: 'BulkCreateDocuments' as const
  }
}