import {
  array,
  boolean as booleanType,
  Describe,
  enums,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreateRetirementPersonalAccountInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateRetirementPersonalAccountInput,
  RetirementPersonalAccountType,
} from '@/types/schema';

import {
  commonCreatePersonalAccountProperties,
  commonUpdatePersonalAccountProperties,
  controllingPartySchema,
} from './personalAccountTypes';
import {
  beneficiarySchema,
  commonUpdateEntityProperties,
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  grantorSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const retirementAccountTypeSchema = optional(
  nullable(
    enums([
      RetirementPersonalAccountType.Ira,
      RetirementPersonalAccountType.InheritedIra,
      RetirementPersonalAccountType.RothIra,
      RetirementPersonalAccountType.InheritedRothIra,
      RetirementPersonalAccountType.Type_401K,
      RetirementPersonalAccountType.DefinedBenefitPlan,
      RetirementPersonalAccountType.Other,
    ])
  )
);

const createRetirementAccountSchema: Describe<AugmentedCreateRetirementPersonalAccountInput> =
  object({
    create: object({
      ...commonCreatePersonalAccountProperties,
      type: retirementAccountTypeSchema,
    }),
    withOwner: optional(nullable(grantorSchema)),
    withBeneficiaries: optional(nullable(array(beneficiarySchema))),
    withControllingParties: optional(nullable(array(controllingPartySchema))),
    withDesignerAccount: optional(nullable(createAccountSchema)),
  });

const updateRetirementAccountSchema: Describe<AugmentedUpdateRetirementPersonalAccountInput> =
  object({
    id: string(),
    update: object({
      ...commonUpdatePersonalAccountProperties,
      type: retirementAccountTypeSchema,
      clearType: optional(nullable(booleanType())),
      clearDescription: optional(nullable(booleanType())),
      clearControllingParties: optional(nullable(booleanType())),
      clearOwner: optional(nullable(booleanType())),
      clearBeneficiaries: optional(nullable(booleanType())),
      clearGstStatus: optional(nullable(booleanType())),
      clearTaxStatus: optional(nullable(booleanType())),
    }),
    withOwner: optional(nullable(grantorSchema)),
    withBeneficiaries: optional(nullable(array(beneficiarySchema))),
    withControllingParties: optional(nullable(array(controllingPartySchema))),
    updateDesignerAccount: optional(nullable(updateAccountSchema)),
  });

const createSchema: Describe<AugmentedCreateEntityInput> = object({
  create: createEntityInput,
  withRetirementPersonalAccount: optional(
    nullable(createRetirementAccountSchema)
  ),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object(commonUpdateEntityProperties),
  updateRetirementPersonalAccount: optional(
    nullable(updateRetirementAccountSchema)
  ),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export function validateRetirementAccountCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'Retirement account create');
}

export function validateRetirementAccountUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'Retirement account update');
}
