import { Maybe } from 'graphql/jsutils/Maybe';

import { MinimalTrusteeFragment } from './graphql/trustees.fragments.generated';

export function getTrusteeDisplayNames(
  trustees: Maybe<MinimalTrusteeFragment[]>
): string[] {
  return (
    trustees?.map((t) => {
      return t?.individual?.displayName || t?.organization?.name || '';
    }) ?? []
  );
}
