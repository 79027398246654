import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { ClientPresentationSection } from '@/types/schema';

import {
  ClientPresentationBundle,
  ClientPresentationBundleWithSlides,
  ClientPresentationSlide,
  SetInitialItemVisibilityParams,
  StringArrayOrNull,
} from '../clientPresentation.types';

export interface SetActiveSlideOpts {
  doScroll?: boolean;
  // defaults to 'auto'
  scrollBehavior?: ScrollBehavior;
}

export interface ClientPresentationDesignerContext {
  householdId: string | null;
  setHouseholdId: Dispatch<SetStateAction<string | null>>;

  presentationId: string | null;
  setPresentationId: Dispatch<SetStateAction<string | null>>;

  activeSlideId: string | null;
  setActiveSlideId: (slideId: string, opts?: SetActiveSlideOpts) => void;
  activeSlide: ClientPresentationSlide | null;

  registerSlide: (slide: ClientPresentationSlide) => void;

  registerBundle: (bundle: ClientPresentationBundle) => void;
  bundlesById: Record<string, ClientPresentationBundleWithSlides>;
  orderedBundles: ClientPresentationBundleWithSlides[];
  standaloneSlides: ClientPresentationSlide[];

  showHiddenItems: boolean;
  setShowHiddenItems: Dispatch<SetStateAction<boolean>>;
  setItemVisibility: (
    identifier: string,
    nextVisibility: 'show' | 'hide'
  ) => void;
  _setInitialItemVisibility: (
    initialItems: SetInitialItemVisibilityParams
  ) => void;

  // shouldShowItem returns a boolean for whether or not a specific item
  // should be shown, taking into account whether or not the designer is in "showHiddenItems"
  // mode or not.
  shouldShowItem: (identifier: string) => boolean;
  shouldShowCoverSlide: boolean;

  visibleEntityPulids: StringArrayOrNull;
  setVisibleEntityPulids: Dispatch<SetStateAction<StringArrayOrNull>>;
  visibleWaterfallPulids: StringArrayOrNull;
  setVisibleWaterfallPulids: Dispatch<SetStateAction<StringArrayOrNull>>;

  // visibleSectionOrder is the order of the sections that are visible to the user and "included" in the presentation.
  // this effectively mirrors the persisted section_order list from the backend.
  visibleSectionOrder: ClientPresentationSection[];
  // renderedSectionOrder is the full set of all possible sections, including those that are currently in a "hidden" state.
  renderedSectionOrder: ClientPresentationSection[];
  setSectionOrder: Dispatch<SetStateAction<ClientPresentationSection[]>>;

  isReorderMode: boolean;
  setIsReorderMode: Dispatch<SetStateAction<boolean>>;
}

export const ClientPresentationDesignerContext =
  createContext<ClientPresentationDesignerContext>({
    householdId: null,
    setHouseholdId: () => null,

    presentationId: null,
    setPresentationId: () => null,

    activeSlideId: null,
    setActiveSlideId: () => null,
    activeSlide: null,

    registerSlide: () => null,

    bundlesById: {},
    registerBundle: () => null,
    orderedBundles: [],
    standaloneSlides: [],

    showHiddenItems: false,
    setShowHiddenItems: () => null,
    setItemVisibility: () => null,
    shouldShowItem: () => true,
    _setInitialItemVisibility: () => null,
    shouldShowCoverSlide: false,

    visibleEntityPulids: null,
    setVisibleEntityPulids: () => null,
    visibleWaterfallPulids: null,
    setVisibleWaterfallPulids: () => null,

    visibleSectionOrder: [],
    renderedSectionOrder: [],
    setSectionOrder: () => null,

    isReorderMode: false,
    setIsReorderMode: () => null,
  });

export const useGuardedClientPresentationDesignerContext = () => {
  return useGuardedContext(
    ClientPresentationDesignerContext,
    'ClientPresentationDesignerProvider'
  );
};

export const useUnguardedClientPresentationDesignerContext = () => {
  return useContext(ClientPresentationDesignerContext);
};
