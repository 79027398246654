import { Typography } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

import { Button } from '../form/baseInputs/Button';
import { XCloseIcon } from '../icons/XCloseIcon';
import { Badge, BadgeVariants } from '../notifications/Badge/Badge';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '12px 16px 12px 24px',
    gap: '16px',
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${COLORS.ORANGE[400]}`,
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
    justifyContent: 'space-between',
  },
  title: {},
  subtitle: {},
  closeButton: {
    padding: 0,
    margin: 0,
  },
  closeIcon: {
    color: COLORS.GRAY[500],
  },
}));

export interface HeaderProps {
  title?: string;
  subtitle?: string;
  count?: number;
  totalCount?: number;
  rightHeaderContent?: ReactNode;
  leftHeaderContent?: ReactNode;
  onClose?: () => void;
  classes?: WithClasses<typeof useStyles>;
}

export const Header = ({
  title,
  subtitle,
  count,
  totalCount,
  onClose,
  rightHeaderContent,
  leftHeaderContent,
  children,
  ...props
}: PropsWithChildren<HeaderProps>) => {
  const { classes } = useStyles(undefined, { props });

  const leftContent = leftHeaderContent ?? (
    <div>
      {subtitle && (
        <Typography variant="subtitle1" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
      {title && (
        <Typography variant={subtitle ? 'h2' : 'h1'} className={classes.title}>
          {title}
        </Typography>
      )}
    </div>
  );

  const rightContent = rightHeaderContent ?? (
    <>
      {count && totalCount && (
        <Badge
          variant={BadgeVariants.Primary}
          display={`${count} of ${totalCount}`}
        />
      )}
      {onClose && (
        <Button
          size="sm"
          variant="transparent"
          onClick={onClose}
          className={classes.closeButton}
          data-testid="HeaderCloseButton"
        >
          <XCloseIcon className={classes.closeIcon} />
        </Button>
      )}
    </>
  );

  return (
    <header className={classes.root}>
      <div className={classes.contentWrapper}>
        {leftContent}
        {rightContent}
      </div>
      {children}
    </header>
  );
};
