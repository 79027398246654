import { cloneDeep } from 'lodash';

import { Node } from '@/components/diagrams/FlowChart';

import { applyDerivedDataToState } from '../state/applyDerivedDataToState';
import { generateWaterfallGraphState } from '../state/generateDefaultState';
import { EstateWaterfallActionFn, UpsertNodeGroupAction } from '../types';
import { cloneWaterfallWithUpsertGroup } from '../waterfallGraph/utils';

export const upsertNodeGroupAction: EstateWaterfallActionFn<
  UpsertNodeGroupAction
> = (state, { appendNodesIds, groupNodeId }, queueEffect) => {
  queueEffect({ type: 'editMutationEffect' });
  const nodesToBeGroupedSet = new Set(appendNodesIds);

  const nextWaterfall = cloneWaterfallWithUpsertGroup({
    graph: state.waterfallGraph,
    groupNode: state.nodes.find((n) => n.id === groupNodeId)!,
    appendNodes: state.nodes.filter((n) => nodesToBeGroupedSet.has(n.id)),
    reactFlowNodes: state.nodes,
    waterfallNodeConfigurations: state.waterfall.nodeConfigurations,
  });

  const nextState = { ...state, waterfall: nextWaterfall };
  const { nodes, edges, waterfallGraph } =
    generateWaterfallGraphState(nextState);

  const { derivedData } = applyDerivedDataToState(nextState);
  const { nodesById } = derivedData;

  const updatedNodes = nodes.map((n) => {
    const existingNode = nodesById[n.id];
    if (!existingNode) return n;
    // We only update the node data, but leave everything about position, height, width, etc. alone
    // since we've already computed it from when the chart first initialized
    return { ...existingNode, data: { ...n.data } } as Node;
  });

  return {
    ...nextState,
    edges,
    waterfallGraph,
    nodes: updatedNodes,
    undoGroupingState: { waterfall: cloneDeep(state.waterfall) },
  };
};
