// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { AsyncJobStatus_AsyncJobFragmentDoc } from '../../asyncJobs/graphql/AsyncJobs.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocumentStatus_DocumentFragment = { __typename?: 'Document', id: string, summaryAsyncJobs: { __typename?: 'AsyncJobConnection', edges?: Array<{ __typename?: 'AsyncJobEdge', node?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null } | null> | null }, metadataJobs: { __typename?: 'AsyncJobConnection', edges?: Array<{ __typename?: 'AsyncJobEdge', node?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null } | null> | null } };

export type DocumentStatusQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.DocumentWhereInput>;
}>;


export type DocumentStatusQuery = { __typename?: 'Query', documents: { __typename?: 'DocumentConnection', edges?: Array<{ __typename?: 'DocumentEdge', node?: { __typename?: 'Document', id: string, summaryAsyncJobs: { __typename?: 'AsyncJobConnection', edges?: Array<{ __typename?: 'AsyncJobEdge', node?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null } | null> | null }, metadataJobs: { __typename?: 'AsyncJobConnection', edges?: Array<{ __typename?: 'AsyncJobEdge', node?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null } | null> | null } } | null } | null> | null } };

export const DocumentStatus_DocumentFragmentDoc = gql`
    fragment DocumentStatus_Document on Document {
  id
  summaryAsyncJobs: asyncJobs(
    where: {kind: DOCUMENT_SUMMARY}
    orderBy: [{direction: DESC, field: created_at}]
    first: 1
  ) {
    edges {
      node {
        ...AsyncJobStatus_AsyncJob
      }
    }
  }
  metadataJobs: asyncJobs(
    where: {kind: DOCUMENT_INFERENCE_METADATA}
    orderBy: [{direction: DESC, field: created_at}]
    first: 1
  ) {
    edges {
      node {
        ...AsyncJobStatus_AsyncJob
      }
    }
  }
}
    ${AsyncJobStatus_AsyncJobFragmentDoc}`;
export const DocumentStatusDocument = gql`
    query DocumentStatus($where: DocumentWhereInput) {
  documents(where: $where) {
    edges {
      node {
        ...DocumentStatus_Document
      }
    }
  }
}
    ${DocumentStatus_DocumentFragmentDoc}`;

/**
 * __useDocumentStatusQuery__
 *
 * To run a query within a React component, call `useDocumentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentStatusQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDocumentStatusQuery(baseOptions?: Apollo.QueryHookOptions<DocumentStatusQuery, DocumentStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentStatusQuery, DocumentStatusQueryVariables>(DocumentStatusDocument, options);
      }
export function useDocumentStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentStatusQuery, DocumentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentStatusQuery, DocumentStatusQueryVariables>(DocumentStatusDocument, options);
        }
export function useDocumentStatusSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentStatusQuery, DocumentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentStatusQuery, DocumentStatusQueryVariables>(DocumentStatusDocument, options);
        }
export type DocumentStatusQueryHookResult = ReturnType<typeof useDocumentStatusQuery>;
export type DocumentStatusLazyQueryHookResult = ReturnType<typeof useDocumentStatusLazyQuery>;
export type DocumentStatusSuspenseQueryHookResult = ReturnType<typeof useDocumentStatusSuspenseQuery>;
export type DocumentStatusQueryResult = Apollo.QueryResult<DocumentStatusQuery, DocumentStatusQueryVariables>;
export const Operations = {
  Query: {
    DocumentStatus: 'DocumentStatus' as const
  },
  Fragment: {
    DocumentStatus_Document: 'DocumentStatus_Document' as const
  }
}