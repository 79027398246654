import { difference, groupBy, includes, isEmpty, isEqual, uniq } from 'lodash';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ClientPresentationSection } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';
import { getPulidKind, isValidPulid, PulidKind } from '@/utils/pulid';

import {
  ClientPresentationBundle,
  ClientPresentationBundleTypes,
  ClientPresentationBundleWithSlides,
  ClientPresentationIdentifier,
  ClientPresentationSlide,
  ClientPresentationStandaloneSlideType,
  isClientPresentationSlideType,
  SetInitialItemVisibilityParams,
  STANDALONE_SLIDE_TYPE_TO_PRESENTATION_SECTION,
  StringArrayOrNull,
} from '../clientPresentation.types';
import { useDefaultClientPresentationSections } from '../hooks/useDefaultClientPresentationSections';
import {
  ClientPresentationDesignerContext,
  SetActiveSlideOpts,
} from './clientPresentationDesigner.context';
import {
  getNextList,
  getOrderedSlides,
} from './ClientPresentationDesigner.provider.utils';

export const ACTIVE_SLIDE_QUERY_PARAM = 'activeSlideId';

function getEntitiesPulidsFromBundles(bundles: ClientPresentationBundle[]) {
  return bundles.flatMap((b) => {
    if (!b.identifier || !isValidPulid(b.identifier)) return [];
    const pulidKind = getPulidKind(b.identifier);
    if (pulidKind !== PulidKind.Entity) return [];
    return b.identifier;
  });
}

function getWaterfallPulidsFromBundles(bundles: ClientPresentationBundle[]) {
  return bundles.flatMap((b) => {
    if (!b.identifier || !isValidPulid(b.identifier)) return [];
    const pulidKind = getPulidKind(b.identifier);
    if (pulidKind !== PulidKind.EstateWaterfall) return [];
    return b.identifier;
  });
}

function useClientPresentationDesignerContextValue(): ClientPresentationDesignerContext {
  const [searchParams, setSearchParams] = useSearchParams();
  const [householdId, setHouseholdId] = useState<string | null>(null);
  const [presentationId, setPresentationId] = useState<string | null>(null);
  const [activeSlideId, setActiveSlideId] = useState<string | null>(null);
  const [sectionOrder, setSectionOrder] = useState<ClientPresentationSection[]>(
    []
  );

  const [shouldShowCoverSlide, setShouldShowCoverSlide] =
    useState<boolean>(false);
  const [visibleEntityPulids, setVisibleEntityPulids] =
    useState<StringArrayOrNull>(null);
  const [visibleWaterfallPulids, setVisibleWaterfallPulids] =
    useState<StringArrayOrNull>(null);
  const [showHiddenItems, setShowHiddenItems] = useState<boolean>(false);
  const [isReorderMode, setIsReorderMode] = useState<boolean>(false);

  const [bundlesById, setBundlesById] = useState<
    Record<string, ClientPresentationBundle>
  >({});
  const [slidesById, setSlidesById] = useState<
    Record<string, ClientPresentationSlide>
  >({});

  const { activeSlide, orderedSlides } = useMemo(() => {
    return {
      activeSlide: activeSlideId ? slidesById[activeSlideId] ?? null : null,
      orderedSlides: getOrderedSlides(Object.values(slidesById)),
    };
  }, [slidesById, activeSlideId]);

  const denormalizedBundlesById = useMemo(() => {
    const slidesByBundleId = groupBy(orderedSlides, 'bundleId');
    return Object.values(bundlesById).reduce(
      (acc, bundle) => {
        const orderedSlidesForBundle = (() => {
          const slidesForBundle = slidesByBundleId[bundle.id] ?? [];

          switch (bundle.type) {
            case ClientPresentationBundleTypes.ENTITY: {
              // entity slides are always returned in a consistent order;
              // it's entity *bundles* that are ordered.
              return slidesForBundle;
            }

            case ClientPresentationBundleTypes.ESTATE_WATERFALL: {
              // waterfall slides are always returned in a consistent order;
              // it's waterfall *bundles* that are ordered.
              return slidesForBundle;
            }
            default:
              throw new UnreachableError({
                message: `Unexpected bundle type ${bundle.type}`,
                case: bundle.type,
              });
          }
        })();

        const bundleWithSlides: ClientPresentationBundleWithSlides = {
          ...bundle,
          slides: orderedSlidesForBundle,
        };
        return {
          ...acc,
          [bundle.id]: bundleWithSlides,
        };
      },
      {} as Record<string, ClientPresentationBundleWithSlides>
    );
  }, [orderedSlides, bundlesById]);

  const orderedBundles = useMemo(() => {
    // TODO (T2-526) this will come from the backend
    return Object.values(denormalizedBundlesById);
  }, [denormalizedBundlesById]);

  // this is a little bit brittle, but:
  // the ClientPresentationIdentifier is either going to be a PULID (in the case of estate waterfalls and entities)
  // or a ClientPresentationStandaloneSlideType (in the case of standalone slides)
  const standaloneSlides = useMemo(() => {
    return orderedSlides.filter(
      (s) => s.identifier && !isValidPulid(s.identifier)
    );
  }, [orderedSlides]);

  const registerSlide = useCallback(
    (slide: ClientPresentationSlide) => {
      // registering a slide causes downstream rerenders, which then cause slides to be re-registered,
      // and so on. so we need to short-circuit here to avoid an infinite loop.
      if (slidesById[slide.id] && isEqual(slidesById[slide.id], slide)) {
        return;
      }

      diagnostics.debug('Registering slide: ', slide);
      setSlidesById((prevSlidesById) => ({
        ...prevSlidesById,
        [slide.id]: slide,
      }));
    },
    [setSlidesById, slidesById]
  );

  const _setInitialItemVisibility = useCallback(
    ({
      visibleEntityPulids,
      shouldShowCoverSlide,
      visibleWaterfallPulids,
      sectionOrder,
    }: SetInitialItemVisibilityParams) => {
      setShouldShowCoverSlide(shouldShowCoverSlide);
      setVisibleEntityPulids(visibleEntityPulids);
      setVisibleWaterfallPulids(visibleWaterfallPulids);
      setSectionOrder(sectionOrder);
    },
    []
  );

  const setItemVisibility = useCallback(
    (
      identifier: ClientPresentationIdentifier,
      nextVisibility: 'show' | 'hide'
    ) => {
      if (
        identifier ===
        (ClientPresentationStandaloneSlideType.DISCLAIMER as string)
      ) {
        throw new Error('Cannot hide the disclaimer slide');
      }

      if (
        identifier === (ClientPresentationStandaloneSlideType.COVER as string)
      ) {
        setShouldShowCoverSlide(nextVisibility === 'show');
        return;
      }

      const action = nextVisibility === 'show' ? 'add' : 'remove';

      // if the idenfitier is not a PULID, it's a standalone slide like the balance_sheet or professional_team slide
      if (!isValidPulid(identifier)) {
        if (!isClientPresentationSlideType(identifier)) {
          throw new Error(
            `Unexpected standalone slide type ${identifier} when attempting to remove it from the sectionOrder`
          );
        }

        const presentationSection =
          STANDALONE_SLIDE_TYPE_TO_PRESENTATION_SECTION[identifier];
        // we want to get a new sectionOrder that either *has* or *doesn't have* this slide in it. by default,
        // let's place newly-added standlone slides as the first item in the sectionOrder
        const nextSectionOrder: ClientPresentationSection[] = (() => {
          if (action === 'add') {
            return [presentationSection, ...sectionOrder];
          }

          return sectionOrder.filter((id) => id !== presentationSection);
        })();

        setSectionOrder(nextSectionOrder);
        return;
      }

      const pulidKind = getPulidKind(identifier);
      switch (pulidKind) {
        case PulidKind.EstateWaterfall: {
          // there are a few cases here:
          // 1. visibleWaterfallPulids is null because we want to show *all* waterfalls, including newly added ones, or
          // 2. visibleWaterfallPulids is null because we removed the final waterfalls from the list, and we want to always hide all entiies
          //    including newly-added ones

          const allWaterfallsHidden = !sectionOrder.includes(
            ClientPresentationSection.EstateWaterfallsGroup
          );

          const finalVisiblePulids = (() => {
            // if all entities are hidden by default, we want to append this new entities to an empty list,
            // so it ends up just being a list of one
            if (allWaterfallsHidden) return [];

            // otherwise, we're currently showing a subset of entities, and we want to either add or remove from that list
            if (visibleWaterfallPulids === null) {
              return getWaterfallPulidsFromBundles(orderedBundles);
            }

            // finally, we have a specific subset of entities we're showing, and we just want to add or remove from that list
            return visibleWaterfallPulids;
          })();

          const newPulidList = getNextList(
            finalVisiblePulids,
            identifier,
            action
          );
          setVisibleWaterfallPulids(newPulidList);

          // either fully include or fully exclude the EstateWaterfallsGroup section if we're transitioning
          if (isEmpty(newPulidList)) {
            const nextSectionOrder = sectionOrder.filter(
              (id) => id !== ClientPresentationSection.EstateWaterfallsGroup
            );
            setSectionOrder(nextSectionOrder);
          }
          if (isEmpty(visibleWaterfallPulids) && !isEmpty(newPulidList)) {
            const nextSectionOrder = uniq([
              ...sectionOrder,
              ClientPresentationSection.EstateWaterfallsGroup,
            ]);
            setSectionOrder(nextSectionOrder);
          }
          break;
        }
        case PulidKind.Entity: {
          // there are a few cases here:
          // 1. visibleEntityPulids is null because we want to show *all* entities, including newly added ones, or
          // 2. visibleEntityPulids is null because we removed the final entities from the list, and we want to always hide all entiies
          //    including newly-added ones

          const allEntitiesHidden = !sectionOrder.includes(
            ClientPresentationSection.EntitiesGroup
          );

          const finalVisiblePulids = (() => {
            // if all entities are hidden by default, we want to append this new entities to an empty list,
            // so it ends up just being a list of one
            if (allEntitiesHidden) return [];

            // otherwise, we're currently showing a subset of entities, and we want to either add or remove from that list
            if (visibleEntityPulids === null) {
              return getEntitiesPulidsFromBundles(orderedBundles);
            }

            // finally, we have a specific subset of entities we're showing, and we just want to add or remove from that list
            return visibleEntityPulids;
          })();

          const newPulidList = getNextList(
            finalVisiblePulids,
            identifier,
            action
          );
          setVisibleEntityPulids(newPulidList);

          if (isEmpty(newPulidList)) {
            const nextSectionOrder = sectionOrder.filter(
              (id) => id !== ClientPresentationSection.EntitiesGroup
            );
            setSectionOrder(nextSectionOrder);
          }
          if (isEmpty(visibleEntityPulids) && !isEmpty(newPulidList)) {
            const nextSectionOrder = uniq([
              ...sectionOrder,
              ClientPresentationSection.EntitiesGroup,
            ]);
            setSectionOrder(nextSectionOrder);
          }
          break;
        }
        default:
          throw new Error(`unexpected PulidKind ${pulidKind}`);
      }
    },
    [orderedBundles, sectionOrder, visibleEntityPulids, visibleWaterfallPulids]
  );

  const shouldShowItem = useCallback(
    (identifier: ClientPresentationIdentifier) => {
      const itemIsVisible = (() => {
        // as of right now, we've made the decision to always show the disclaimer slide
        // https://withluminary.slack.com/archives/C060DKAQBN1/p1704816945968679
        if (
          identifier ===
          (ClientPresentationStandaloneSlideType.DISCLAIMER as string)
        ) {
          return true;
        }

        if (
          identifier === (ClientPresentationStandaloneSlideType.COVER as string)
        ) {
          return shouldShowCoverSlide;
        }

        if (!isValidPulid(identifier)) {
          if (!isClientPresentationSlideType(identifier)) {
            throw new Error(
              `Unexpected standalone slide type ${identifier} when attempting to remove it from the sectionOrder`
            );
          }

          const presentationSection =
            STANDALONE_SLIDE_TYPE_TO_PRESENTATION_SECTION[identifier];

          return includes(sectionOrder, presentationSection);
        }

        const pulidKind = getPulidKind(identifier);
        switch (pulidKind) {
          case PulidKind.Entity:
            if (
              !includes(sectionOrder, ClientPresentationSection.EntitiesGroup)
            )
              return false;
            return visibleEntityPulids?.includes(identifier) ?? true;
          case PulidKind.EstateWaterfall:
            if (
              !includes(
                sectionOrder,
                ClientPresentationSection.EstateWaterfallsGroup
              )
            )
              return false;
            return visibleWaterfallPulids?.includes(identifier) ?? true;
          default:
            throw new Error(`unexpected PulidKind ${pulidKind}`);
        }
      })();

      // if the item is marked as visible and we're not in the "show hidden slides" state
      // we want to show it
      if (itemIsVisible) {
        return !showHiddenItems;
      }

      // if the item is hidden, we only want to show it in the "show hidden items" state
      return showHiddenItems;
    },
    [
      showHiddenItems,
      shouldShowCoverSlide,
      sectionOrder,
      visibleEntityPulids,
      visibleWaterfallPulids,
    ]
  );

  const registerBundle = useCallback(
    (bundle: ClientPresentationBundle) => {
      // registering a bundle causes downstream rerenders, which then cause slides to be re-registered,
      // and so on. so we need to short-circuit here to avoid an infinite loop.
      if (bundlesById[bundle.id] && isEqual(bundlesById[bundle.id], bundle)) {
        return;
      }

      diagnostics.debug('Registering bundle: ', bundle);
      setBundlesById((prevBundlesById) => ({
        ...prevBundlesById,
        [bundle.id]: bundle,
      }));
    },
    [setBundlesById, bundlesById]
  );

  const handleSetActiveSlideId = useCallback(
    (slideId: string, opts: SetActiveSlideOpts = {}) => {
      // this would happen if e.g. a user manually enters a slideId into the URL, or uses an old link where the entity
      // has been removed from the slide
      if (!slidesById[slideId]) {
        diagnostics.debug(
          `Attempting to set an invalid slideId ${slideId} with no registered slide by that id.`
        );
        return;
      }

      setActiveSlideId(slideId);
      if (searchParams.get(ACTIVE_SLIDE_QUERY_PARAM) !== slideId) {
        searchParams.set(ACTIVE_SLIDE_QUERY_PARAM, slideId);
        // "replace"-ing here because we don't want to add a new entry to the browser history
        // every time the user focuses on a new slide
        setSearchParams(searchParams, { replace: true });
      }

      const slideElement = document.querySelector(`#${slideId}`);
      slideElement &&
        opts.doScroll &&
        slideElement.scrollIntoView({
          behavior: opts.scrollBehavior ?? 'auto',
        });
    },
    [searchParams, setSearchParams, slidesById]
  );

  const defaultSectionOrder = useDefaultClientPresentationSections();
  const renderedSectionOrder = useMemo(() => {
    const hiddenSectionOrder = difference(defaultSectionOrder, sectionOrder);
    return [...sectionOrder, ...hiddenSectionOrder];
  }, [defaultSectionOrder, sectionOrder]);

  return {
    householdId,
    setHouseholdId,

    presentationId,
    setPresentationId,

    activeSlideId,
    setActiveSlideId: handleSetActiveSlideId,
    activeSlide,

    registerSlide,
    shouldShowItem,

    registerBundle,
    bundlesById: denormalizedBundlesById,
    orderedBundles,
    standaloneSlides,

    showHiddenItems,
    setShowHiddenItems,
    setItemVisibility,
    shouldShowCoverSlide,
    _setInitialItemVisibility,

    setVisibleEntityPulids,
    setVisibleWaterfallPulids,
    visibleEntityPulids,
    visibleWaterfallPulids,

    visibleSectionOrder: sectionOrder,
    renderedSectionOrder,
    setSectionOrder,

    isReorderMode,
    setIsReorderMode,
  };
}

export const ClientPresentationDesignerProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const value = useClientPresentationDesignerContextValue();
  return (
    <ClientPresentationDesignerContext.Provider value={value}>
      {children}
    </ClientPresentationDesignerContext.Provider>
  );
};
