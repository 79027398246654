import { Stack, Typography } from '@mui/material';
import { ReactNode, useCallback, useMemo } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Card } from '@/components/layout/Card/Card';
import { SidePanel } from '@/components/modals/SidePanel';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useModalState } from '@/hooks/useModalState';
import { HypotheticalTransferModal } from '@/modules/entities/details/HypotheticalTransferModal/HypotheticalTransferModal';
import { TransferDirection } from '@/modules/entities/details/HypotheticalTransfersCard/hypotheticalTransfers/HypotheticalTransferForm';

import { SummaryPanelLoader } from '../components/SummaryPanelLoader';
import { ExternalTransferList } from './ExternalTransferSummaryPanel.components';
import { useGetExternalHypotheticalTransfers } from './hooks/useGetExternalTransfers';

function getTransferDirection(
  summaryNodeId?: string
): TransferDirection | null {
  if (!summaryNodeId) {
    return TransferDirection.Inbound;
  }

  const nodeId = summaryNodeId.split(':')[1];

  if (!nodeId) {
    return null;
  }
  const direction: TransferDirection =
    nodeId.split('-')[0] === 'outgoing'
      ? TransferDirection.Outbound
      : TransferDirection.Inbound;

  return direction;
}

interface ExternalTransferSummaryPanelInnerProps {
  direction: TransferDirection;
  waterfallId: string;
  householdId: string;
  onClose: () => void;
}

function ExternalTransferSummaryPanelInner({
  direction,
  waterfallId,
  householdId,
  onClose: onCloseExternal,
}: ExternalTransferSummaryPanelInnerProps) {
  const { transfers, loading, error } = useGetExternalHypotheticalTransfers(
    waterfallId,
    direction
  );

  const isOutbound = direction === TransferDirection.Outbound;

  const initiatingDirection = useMemo(() => {
    // this needs to be reversed because we're displaying transfers from the external side
    return isOutbound ? TransferDirection.Inbound : TransferDirection.Outbound;
  }, [isOutbound]);

  const [{ isModalOpen, data: transferId }, { openModal, closeModal }] =
    useModalState<string | undefined>();

  const onDelete = useCallback(() => {
    // Because the modal closes before the mutation+refetch is complete, the deleted
    // transfer will still be present in the cache
    if (transfers.length <= 1) {
      onCloseExternal();
    }
  }, [onCloseExternal, transfers.length]);

  let body: ReactNode = null;
  if (error) {
    body = (
      <Typography>Could not load transfers. Please try again later.</Typography>
    );
  } else if (loading) {
    body = <SummaryPanelLoader />;
  } else if (transfers.length) {
    body = (
      <ExternalTransferList
        waterfallId={waterfallId}
        direction={direction}
        transfers={transfers}
        openModal={openModal}
      />
    );
  }

  return (
    <>
      {isModalOpen && (
        <HypotheticalTransferModal
          transferId={transferId || undefined}
          waterfallId={waterfallId}
          isOpen={isModalOpen}
          onClose={closeModal}
          householdId={householdId}
          initiatingDirection={initiatingDirection}
          onDelete={onDelete}
        />
      )}
      <Stack spacing={3}>
        <Card variant="filled-callout" sx={{ p: 2 }}>
          <Callout severity="info-high">
            {isOutbound
              ? 'Displaying outbound transfers to "External destination(s)"'
              : 'Displaying inbound transfers from "External source(s)"'}
          </Callout>
        </Card>
        <SidePanel.Section>{body}</SidePanel.Section>
        <Button
          variant="secondary"
          onClick={() => openModal()}
          startIcon={PlusIcon}
          size="sm"
          fullWidth
        >
          Add hypothetical transfer
        </Button>
      </Stack>
    </>
  );
}

export interface ExternalTranfserSummaryPanelProps
  extends Omit<ExternalTransferSummaryPanelInnerProps, 'direction'> {
  summaryNodeId?: string;
}

export function ExternalTranfserSummaryPanel({
  summaryNodeId,
  onClose,
  ...props
}: ExternalTranfserSummaryPanelProps) {
  const direction =
    getTransferDirection(summaryNodeId) || TransferDirection.Inbound;

  return (
    <SidePanel.Panel>
      <SidePanel.Header
        onClose={onClose}
        title={`External ${direction === TransferDirection.Inbound ? 'source' : 'destination'}`}
      />
      <SidePanel.Content>
        <ExternalTransferSummaryPanelInner
          direction={direction}
          onClose={onClose}
          {...props}
        />
      </SidePanel.Content>
    </SidePanel.Panel>
  );
}
