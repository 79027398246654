import {
  Breakpoint,
  List,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { compact } from 'lodash';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { usePreviousDistinct } from 'react-use';

import {
  Logomark,
  LogomarkSize,
  LogomarkVariant,
} from '@/components/brand/Logomark/Logomark';
import { BankIcon } from '@/components/icons/BankIcon';
import { CheckCircleBrokenIcon } from '@/components/icons/CheckCircleBrokenIcon';
import { FileSearch02Icon } from '@/components/icons/FileSearch02Icon';
import { FileShield02Icon } from '@/components/icons/FileShield02Icon';
import { LockUnlocked01Icon } from '@/components/icons/LockUnlocked01Icon';
import { Map01Icon } from '@/components/icons/Map01Icon';
import { PieChart04Icon } from '@/components/icons/PieChart04Icon';
import { PresentationChart01Icon } from '@/components/icons/PresentationChart01Icon';
import { User01Icon } from '@/components/icons/User01Icon';
import { UnstyledNavLink } from '@/components/navigation/UnstyledNavLink';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { useDebouncedFn } from '@/hooks/useDebouncedFn';
import { useEventListener } from '@/hooks/useEventListener';
import { useMatchingRoutes } from '@/hooks/useMatchingRoutes';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { useCanAccessPage } from '@/navigation/usePageAccessLookup';

import { appbarHeight } from '../Layout/AppBar/AppBar';
import { SidebarWithContainer } from './Sidebar';
import { SidebarMenuItem, SidebarMenuItemProps } from './SidebarMenuItem';

export const MENU_ITEMS_ID = 'sidebar-menu-items';

export const CLIENT_SIDEBAR_WIDTH = 167;

type SidebarExpansionState =
  | 'collapsed'
  | 'temporarilyExpanded'
  | 'permanentlyExpanded';

const collapseBelowBreakpoint: Breakpoint = 'xl';

const menuItemSxProps = {
  pl: '18px',
  py: 1,
  borderRadius: '0',
};

const TASKS = 'Tasks';
const PRESENTATIONS = 'Presentations';

interface ClientDetailsSidebarProps {
  householdId?: string;
}

export function ClientDetailsSidebar({
  householdId,
}: ClientDetailsSidebarProps) {
  const theme = useTheme();
  const location = useLocation();

  const canAccessTasksPage = useCanAccessPage(ROUTE_KEYS.TASKS);

  const isPermanentlyExpanded = useMediaQuery(
    theme.breakpoints.up(collapseBelowBreakpoint)
  );

  const [expansionState, setExpansionState] =
    React.useState<SidebarExpansionState>(
      isPermanentlyExpanded ? 'permanentlyExpanded' : 'collapsed'
    );

  const handleResize = useCallback(() => {
    if (expansionState === 'permanentlyExpanded' && isPermanentlyExpanded)
      return;
    if (expansionState === 'collapsed' && !isPermanentlyExpanded) return;
    setExpansionState(
      isPermanentlyExpanded ? 'permanentlyExpanded' : 'collapsed'
    );
  }, [expansionState, isPermanentlyExpanded]);

  const debouncedHandleResize = useDebouncedFn(handleResize, 50, {
    leading: true,
    trailing: true,
  });

  useEventListener('resize', debouncedHandleResize);

  const isExpanded = expansionState !== 'collapsed';

  const menuItems: SidebarMenuItemProps[] = useMemo(() => {
    const variant = isExpanded ? 'large' : 'small';
    if (!householdId) return [];

    return compact([
      {
        text: 'Overview',
        icon: <PieChart04Icon size={20} />,
        to: getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_SIDEBAR_OVERVIEW, {
          householdId,
        }),
        variant,
        menuItemSxProps,
      },
      {
        text: 'Wealth',
        icon: <BankIcon size={20} />,
        to: getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_SIDEBAR_WEALTH, {
          householdId,
        }),
        variant,
        menuItemSxProps,
      },
      {
        text: 'Entities',
        icon: <FileShield02Icon size={20} />,
        to: getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_SIDEBAR_ENTITIES, {
          householdId,
        }),
        variant,
        menuItemSxProps,
      },
      {
        text: 'People',
        icon: <User01Icon size={20} />,
        to: getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_SIDEBAR_PEOPLE, {
          householdId,
        }),
        variant,
        menuItemSxProps,
      },
      {
        text: 'Waterfall',
        icon: <Map01Icon size={20} />,
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB,
          {
            householdId,
          }
        ),
        variant,
        menuItemSxProps,
      },
      {
        text: PRESENTATIONS,
        icon: <PresentationChart01Icon size={20} />,
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_SIDEBAR_PRESENTATIONS,
          {
            householdId,
          }
        ),
        variant,
        menuItemSxProps,
      },
      canAccessTasksPage
        ? {
            text: TASKS,
            icon: <CheckCircleBrokenIcon size={20} />,
            to: getCompletePathFromRouteKey(
              ROUTE_KEYS.HOUSEHOLD_DETAILS_TASKS,
              {
                householdId,
              }
            ),
            variant,
            menuItemSxProps,
          }
        : null,
      {
        text: 'Documents',
        icon: <FileSearch02Icon size={20} />,
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_DOCUMENTS,
          {
            householdId,
          }
        ),
        variant,
        menuItemSxProps,
      },
    ]);
  }, [canAccessTasksPage, householdId, isExpanded]);

  const accessRoute = useMemo(() => {
    if (!householdId) return undefined;

    return getCompletePathFromRouteKey(ROUTE_KEYS.MANAGE_HOUSEHOLD_DETAILS, {
      householdId,
    });
  }, [householdId]);

  const { isMatchingRoute: isOnImplementationPage } = useMatchingRoutes([
    ROUTE_KEYS.HOUSEHOLD_ENTITY_IMPLEMENTATION,
  ]);

  const { isMatchingRoute: isOnGiftDesignerPage } = useMatchingRoutes([
    ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER,
    ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER_BASIC_INFORMATION,
    ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER_MODEL_SCENARIOS,
  ]);

  const matchingSidebarItem = useMemo(() => {
    const activeItem = [
      ...menuItems,
      {
        to: accessRoute,
      },
    ].find((item) => {
      return item.to && location.pathname.startsWith(item.to);
    });

    // Match implementation routes with the tasks menu item.
    if (isOnImplementationPage && !activeItem) {
      return menuItems.find((item) => item.text === TASKS);
    }

    // Match gift designer routes with the presentations menu item.
    if (isOnGiftDesignerPage && !activeItem) {
      return menuItems.find((item) => item.text === PRESENTATIONS);
    }

    return activeItem;
  }, [
    accessRoute,
    isOnGiftDesignerPage,
    isOnImplementationPage,
    location.pathname,
    menuItems,
  ]);

  const prevActiveItem = usePreviousDistinct(matchingSidebarItem);

  // This provides a fallback for when the active route is not associated with the sidebar items.
  const activeSidebarItem =
    matchingSidebarItem ?? prevActiveItem ?? menuItems[0];

  const TooltipComponent = useMemo(() => {
    if (!isExpanded) return Tooltip;
    const Wrapper = ({ children }: PropsWithChildren) => <>{children}</>;
    return Wrapper;
  }, [isExpanded]);

  return (
    <SidebarWithContainer
      expansionState={expansionState}
      data-it="Sidebar"
      width={CLIENT_SIDEBAR_WIDTH}
      collapsedWidth={56}
    >
      <Stack
        display="flex"
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
        overflow="hidden"
      >
        <Stack width="100%" height="100%" pb={1} spacing={2}>
          <Stack
            direction="row"
            minHeight={appbarHeight}
            alignItems="center"
            justifyContent="center"
          >
            <UnstyledNavLink
              to={getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLDS_LIST, {})}
            >
              <Logomark
                size={isExpanded ? LogomarkSize.Wide : LogomarkSize.Square}
                variant={LogomarkVariant.Secondary}
                imageSx={{
                  maxHeight: 48,
                  maxWidth: isExpanded ? 140 : 48,
                  display: 'flex',
                }}
              />
            </UnstyledNavLink>
          </Stack>
          <List sx={{ p: 0, height: '100%' }} id={MENU_ITEMS_ID}>
            <Stack justifyContent="space-between" height="100%">
              <Stack spacing={0.5}>
                {menuItems.map((menuItemProps) => (
                  <TooltipComponent
                    key={menuItemProps.text}
                    placement="right"
                    title={menuItemProps.text}
                  >
                    <SidebarMenuItem
                      isActive={activeSidebarItem?.to === menuItemProps.to}
                      colorTheme="dark"
                      {...menuItemProps}
                      typographyProps={{
                        variant: 'subtitle1',
                      }}
                    />
                  </TooltipComponent>
                ))}
              </Stack>
              {householdId && (
                <TooltipComponent placement="right" title="Access">
                  <SidebarMenuItem
                    colorTheme="dark"
                    text="Access"
                    icon={<LockUnlocked01Icon size={20} />}
                    to={accessRoute}
                    isActive={activeSidebarItem?.to === accessRoute}
                    variant={isExpanded ? 'large' : 'small'}
                    typographyProps={{
                      variant: 'subtitle1',
                    }}
                    menuItemSxProps={menuItemSxProps}
                  />
                </TooltipComponent>
              )}
            </Stack>
          </List>
        </Stack>
      </Stack>
    </SidebarWithContainer>
  );
}
