import { AuthorizationLevel, UserKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { Authentication_ApplicationAuthorizationFragment } from '../graphql/Authentication.generated';
import { useCurrentUser } from './useCurrentUser';

const AUTHORIZATION_LEVEL_ORDER = [
  AuthorizationLevel.None,
  AuthorizationLevel.Editor,
  AuthorizationLevel.Admin,
];

// only exporting this for tests; it shouldn't be used
// outside of this file
export function hasSufficientAuthLevel(
  requiredLevel: AuthorizationLevel,
  actualLevel: AuthorizationLevel
) {
  const requiredLevelIndex =
    AUTHORIZATION_LEVEL_ORDER.indexOf(requiredLevel) ?? 0;
  const actualLevelIndex = AUTHORIZATION_LEVEL_ORDER.indexOf(actualLevel) ?? 0;
  return actualLevelIndex >= requiredLevelIndex;
}

export enum BehaviorAuthorizationType {
  // INTERNAL
  CAN_ACCESS_INTERNAL_TOOLS = 'CAN_ACCESS_INTERNAL_TOOLS',

  // ACTIONS
  CAN_REASSIGN_RELATIONSHIP_OWNER = 'CAN_REASSIGN_RELATIONSHIP_OWNER',
  CAN_UPDATE_SUB_BRAND = 'CAN_UPDATE_SUB_BRAND',
  CAN_EDIT_CLIENT_COLLABORATORS_AND_EMPLOYEES = 'CAN_EDIT_CLIENT_COLLABORATORS_AND_EMPLOYEES',
  CAN_CREATE_CLIENTS = 'CAN_CREATE_CLIENTS',
  CAN_VIEW_TASKS = 'CAN_VIEW_TASKS',
  CAN_DELETE_ENTITIES_AND_PROPOSALS = 'CAN_DELETE_ENTITIES_AND_PROPOSALS',
  CAN_DELETE_CLIENTS = 'CAN_DELETE_CLIENTS',
  CAN_MODIFY_ASSET_CATEGORIES = 'CAN_MODIFY_ASSET_CATEGORIES',

  // PAGES
  CAN_ACCESS_HOME_PAGE = 'CAN_ACCESS_HOME_PAGE',
  CAN_ACCESS_TASKS_PAGE = 'CAN_ACCESS_TASKS_PAGE',
  CAN_ACCESS_COLLABORATORS_PAGE = 'CAN_ACCESS_COLLABORATORS_PAGE',
  CAN_ACCESS_USER_ADMIN_PAGE = 'CAN_ACCESS_USER_ADMIN_PAGE',
  CAN_ACCESS_INTEGRATIONS_ADMIN_PAGE = 'CAN_ACCESS_INTEGRATIONS_ADMIN_PAGE',
  CAN_ACCESS_BRANDING_ADMIN_PAGE = 'CAN_ACCESS_BRANDING_ADMIN_PAGE',
  CAN_ACCESS_SUB_BRANDS_ADMIN_PAGE = 'CAN_ACCESS_SUB_BRANDS_ADMIN_PAGE',
  CAN_ACCESS_USER_SETTINGS_PAGE = 'CAN_ACCESS_USER_SETTINGS_PAGE',
  CAN_ACCESS_LEGAL_DISCLAIMERS_PAGE = 'CAN_ACCESS_LEGAL_DISCLAIMERS_PAGE',
  CAN_ACCESS_ADMIN_CATEGORIES_PAGES = 'CAN_ACCESS_ADMIN_CATEGORIES_PAGES',
}

/**
 * @description THIS FUNCTION SHOULD NOT BE USED OUTSIDE OF THIS FILE. It's exported for testing. Use useHasBehaviorAuthorization instead.
 *
 * Given a behavior and an authorization object, this function determines whether or not the the authorization
 * object has the right to perform the behavior.
 */
export function private_hasBehaviorAuthorization(
  type: BehaviorAuthorizationType,
  authorization: Authentication_ApplicationAuthorizationFragment
): boolean {
  switch (type) {
    // INTERNAL
    case BehaviorAuthorizationType.CAN_ACCESS_INTERNAL_TOOLS:
      return authorization.userKind === UserKind.LuminaryInternal;

    // ACTIONS
    case BehaviorAuthorizationType.CAN_REASSIGN_RELATIONSHIP_OWNER:
      return hasSufficientAuthLevel(
        AuthorizationLevel.Admin,
        authorization.households
      );
    case BehaviorAuthorizationType.CAN_UPDATE_SUB_BRAND:
      return hasSufficientAuthLevel(
        AuthorizationLevel.Editor,
        authorization.households
      );
    case BehaviorAuthorizationType.CAN_EDIT_CLIENT_COLLABORATORS_AND_EMPLOYEES:
      return hasSufficientAuthLevel(
        AuthorizationLevel.Editor,
        authorization.households
      );

    case BehaviorAuthorizationType.CAN_DELETE_ENTITIES_AND_PROPOSALS:
      return hasSufficientAuthLevel(
        AuthorizationLevel.Editor,
        authorization.households
      );

    case BehaviorAuthorizationType.CAN_DELETE_CLIENTS:
      if (authorization.userKind === UserKind.LuminaryInternal) {
        return true;
      }

      return hasSufficientAuthLevel(
        AuthorizationLevel.Admin,
        authorization.households
      );
    case BehaviorAuthorizationType.CAN_CREATE_CLIENTS:
      // Luminary users aren't eligible as relationshop owners in prod, but our permissions allow us to
      // create clients and we want to expose the ability to do so.
      if (authorization.userKind === UserKind.LuminaryInternal) {
        return true;
      }

      return (
        hasSufficientAuthLevel(
          AuthorizationLevel.Editor,
          authorization.households
        ) &&
        authorization.canActAsRelationshipOwner &&
        authorization.userKind !== UserKind.ThirdParty
      );
    case BehaviorAuthorizationType.CAN_VIEW_TASKS:
      return authorization.userKind !== UserKind.ThirdParty;

    case BehaviorAuthorizationType.CAN_MODIFY_ASSET_CATEGORIES:
      return authorization.canModifyGlobalCategories;

    // PAGES
    case BehaviorAuthorizationType.CAN_ACCESS_HOME_PAGE:
    // falls through to shared handling with tasks page
    case BehaviorAuthorizationType.CAN_ACCESS_TASKS_PAGE:
      return private_hasBehaviorAuthorization(
        BehaviorAuthorizationType.CAN_VIEW_TASKS,
        authorization
      );

    case BehaviorAuthorizationType.CAN_ACCESS_COLLABORATORS_PAGE:
      return hasSufficientAuthLevel(
        AuthorizationLevel.Editor,
        authorization.collaborators
      );
    case BehaviorAuthorizationType.CAN_ACCESS_SUB_BRANDS_ADMIN_PAGE:
    // falls through to shared handling with branding admin page
    case BehaviorAuthorizationType.CAN_ACCESS_BRANDING_ADMIN_PAGE:
      return hasSufficientAuthLevel(
        AuthorizationLevel.Editor,
        authorization.branding
      );

    case BehaviorAuthorizationType.CAN_ACCESS_INTEGRATIONS_ADMIN_PAGE:
      // TODO: maybe this should be its own permission rather than be the
      // internalEmployees one?
      return hasSufficientAuthLevel(
        AuthorizationLevel.Admin,
        authorization.internalEmployees
      );

    case BehaviorAuthorizationType.CAN_ACCESS_USER_ADMIN_PAGE:
      return hasSufficientAuthLevel(
        AuthorizationLevel.Admin,
        authorization.internalEmployees
      );

    case BehaviorAuthorizationType.CAN_ACCESS_USER_SETTINGS_PAGE: {
      // as of this decision, the only functionality in the user setting page is the addepar
      // auth flow for single users, which isn't relevant to third parties.
      return authorization.userKind !== UserKind.ThirdParty;
    }

    case BehaviorAuthorizationType.CAN_ACCESS_LEGAL_DISCLAIMERS_PAGE: {
      return hasSufficientAuthLevel(
        AuthorizationLevel.Editor,
        authorization.branding
      );
    }

    case BehaviorAuthorizationType.CAN_ACCESS_ADMIN_CATEGORIES_PAGES: {
      return authorization.canModifyGlobalCategories;
    }

    default:
      throw new UnreachableError({
        case: type,
        message: `Unhandled BehaviorAuthorizationType ${type}`,
      });
  }
}

/**
 * @description Given a BehaviorAuthorizationType, this function determines whether or not the currently-authenticated
 * user can perform that behavior.
 * @param type the type of behavior to check the permission of
 * @returns boolean whether or not the user has the right
 */
export function useHasBehaviorAuthorization(
  type: BehaviorAuthorizationType
): boolean {
  const currentUser = useCurrentUser();
  // this should never happen because the backing data for useCurrentUser is hydrated as part of SSR; would only happen if
  // this is called e.g. in the unauthorized login page
  if (!currentUser) {
    throw new Error('Cannot call useBehaviorAuthorization with no currentUser');
  }

  return private_hasBehaviorAuthorization(type, currentUser.authorization);
}
