import { RollingType, TermsSubformType } from '../types';

export function getNormalizedRollingPeriodUpdates(
  formValues: Pick<TermsSubformType, 'type' | 'rollingPeriod'>
) {
  if (formValues.type === RollingType.STANDARD) {
    return { clearRollingPeriodYears: true };
  } else {
    return {
      rollingPeriodYears: parseStringToInteger(formValues.rollingPeriod),
    };
  }
}

export function parseStringToInteger(value: string): number | null {
  if (value === '') return null;
  return parseInt(value);
}
