import { ApolloError } from '@apollo/client';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { usePreviousDistinct } from 'react-use';

import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { AddNewAnythingModal } from '@/modules/common/AddNewAnythingModal/AddNewAnythingModal';
import { EntityKind } from '@/types/schema';

import { useTransferModalOptionsQuery } from './graphql/TransferModalOptions.generated';
import { ModalTypeContext } from './LogNewTransferModal';
import { NAMESPACE } from './LogNewTransferModal.constants';
import {
  LogNewTransferForm,
  LogNewTransferFormPaths,
  TransferType,
} from './LogNewTransferModal.types';
import { mapDataToSourceRecipientOptions } from './SourceRecipientInput.utils';

const getFieldName = (field: keyof LogNewTransferForm[typeof NAMESPACE]) =>
  `${NAMESPACE}.${field}` as const satisfies LogNewTransferFormPaths;

export const SourceRecipientInput: FC = () => {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const {
    transferType,
    initiatorId,
    initiatorEntityKind,
    householdId,
    isInboundDistribution,
    setTransferOptions,
  } = useContext(ModalTypeContext);
  const { control, setValue } = useFormContext();

  const selectedTargetId = useWatch({ name: getFieldName('targetId') });
  const previousTargetId = usePreviousDistinct(selectedTargetId);

  const [isAddNewAnythingModalOpen, setAddNewAnythingModalOpen] =
    useState<boolean>(false);

  const variables = { householdId };
  const { data, loading, refetch } = useTransferModalOptionsQuery({
    nextFetchPolicy: 'network-only', // necessary to skip cache on the post-add new refetch
    variables,
    onError: (error: ApolloError) => {
      showFeedback(
        'Could not fetch transfer options. Please refresh the page and try again.'
      );
      reportError(`could not fetch data for ${householdId}`, error, {
        householdId,
      });
    },
  });

  const targetId = useWatch({ name: getFieldName('targetId') });
  const { transferOptions, selectOptions } = useMemo(() => {
    // isCharitableDistribution has a dependency on transferOptions, so we need to
    // compute the transferOptions first
    let { transferOptions, selectOptions } = mapDataToSourceRecipientOptions(
      data,
      initiatorId,
      transferType,
      true,
      isInboundDistribution
    );

    const isCharitableDistribution = (() => {
      const selectedTransferOption = transferOptions.find(
        (option) => option.id === selectedTargetId
      );

      const charitableEntityKinds = [
        EntityKind.DonorAdvisedFund,
        EntityKind.PrivateFoundation,
      ];

      if (transferType === TransferType.Distribution && initiatorEntityKind) {
        return charitableEntityKinds.includes(initiatorEntityKind);
      }

      if (isInboundDistribution && selectedTransferOption?.entityKind) {
        return charitableEntityKinds.includes(
          selectedTransferOption.entityKind
        );
      }

      return false;
    })();

    // Once we have isCharitableDistribution, we can compute the transferOptions again
    ({ transferOptions, selectOptions } = mapDataToSourceRecipientOptions(
      data,
      initiatorId,
      transferType,
      isCharitableDistribution,
      isInboundDistribution
    ));

    return {
      transferOptions,
      selectOptions,
    };
  }, [
    data,
    initiatorEntityKind,
    initiatorId,
    isInboundDistribution,
    selectedTargetId,
    transferType,
  ]);

  useEffect(() => {
    const targetOption = transferOptions.find((data) => {
      return data.id === targetId;
    });
    setValue(getFieldName('targetType'), targetOption?.type);
  }, [setValue, targetId, transferOptions]);

  useEffect(() => {
    setTransferOptions(transferOptions);
  }, [setTransferOptions, transferOptions]);

  useEffect(() => {
    if (!previousTargetId) {
      return;
    }

    // If this is an inbound distribution, we need to clear the purpose field
    // when the target changes
    if (isInboundDistribution && previousTargetId !== selectedTargetId) {
      setValue(getFieldName('purpose'), '');
      setValue(getFieldName('otherPurpose'), '');
    }
  }, [selectedTargetId, isInboundDistribution, setValue, previousTargetId]);

  return (
    <>
      <AddNewAnythingModal
        addableTypes={['entity', 'individual', 'organization']}
        handleClose={async () => {
          await refetch(variables);
          setAddNewAnythingModalOpen(false);
        }}
        isOpen={isAddNewAnythingModalOpen}
        householdId={householdId || ''}
      />
      <FormAwareSelectInput
        disabled={loading}
        label={
          transferType === TransferType.Contribution ? 'Source' : 'Recipient'
        }
        required
        options={selectOptions}
        fieldName={getFieldName('targetId')}
        control={control}
        addNewOption={{
          onClick: () => setAddNewAnythingModalOpen(true),
        }}
      />
    </>
  );
};
