// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BulkClientImportMutationMutationVariables = Types.Exact<{
  inputs: Array<Types.InputMaybe<Types.AugmentedCreateHouseholdInput>> | Types.InputMaybe<Types.AugmentedCreateHouseholdInput>;
}>;


export type BulkClientImportMutationMutation = { __typename?: 'Mutation', createHouseholds: Array<{ __typename?: 'Household', id: string } | null> };


export const BulkClientImportMutationDocument = gql`
    mutation BulkClientImportMutation($inputs: [AugmentedCreateHouseholdInput]!) {
  createHouseholds(inputs: $inputs) {
    id
  }
}
    `;
export type BulkClientImportMutationMutationFn = Apollo.MutationFunction<BulkClientImportMutationMutation, BulkClientImportMutationMutationVariables>;

/**
 * __useBulkClientImportMutationMutation__
 *
 * To run a mutation, you first call `useBulkClientImportMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkClientImportMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkClientImportMutationMutation, { data, loading, error }] = useBulkClientImportMutationMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useBulkClientImportMutationMutation(baseOptions?: Apollo.MutationHookOptions<BulkClientImportMutationMutation, BulkClientImportMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkClientImportMutationMutation, BulkClientImportMutationMutationVariables>(BulkClientImportMutationDocument, options);
      }
export type BulkClientImportMutationMutationHookResult = ReturnType<typeof useBulkClientImportMutationMutation>;
export type BulkClientImportMutationMutationResult = Apollo.MutationResult<BulkClientImportMutationMutation>;
export type BulkClientImportMutationMutationOptions = Apollo.BaseMutationOptions<BulkClientImportMutationMutation, BulkClientImportMutationMutationVariables>;
export const Operations = {
  Mutation: {
    BulkClientImportMutation: 'BulkClientImportMutation' as const
  }
}