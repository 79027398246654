import { Stack } from '@mui/material';

import { CreateNewEntityOrStrategy } from '@/modules/common/CreateNewEntityOrStrategy/CreateNewEntityOrStrategy';

import { WaterfallEmptyState } from './WaterfallEmptyState';

export interface EmptyStateProps {
  householdId: string;
}

export const EmptyState = ({ householdId }: EmptyStateProps) => {
  return (
    <Stack gap={2} height="100%">
      <WaterfallEmptyState
        height="100%"
        heading="No entities exist"
        description="There are no entities associated with this client. As soon as you add entiites your estate waterfall will appear."
        action={
          <CreateNewEntityOrStrategy
            householdId={householdId}
            label="Create entity"
          />
        }
      />
    </Stack>
  );
};
