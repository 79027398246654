import { Box, Stack, Typography, useTheme } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Drawer from '@mui/material/Drawer';
import dynamic from 'next/dynamic';
import { useContext } from 'react';
import React from 'react';

import { PoweredByLuminary } from '@/components/brand/PoweredByLuminary/PoweredByLuminary';
import { Button } from '@/components/form/baseInputs/Button';
import { XCloseIcon } from '@/components/icons/XCloseIcon';
import { Loader } from '@/components/progress/Loader/Loader';
import { TextEditorProvider } from '@/components/TextEditor/contexts/TextEditor.provider';
import { ContextualHelpContext } from '@/modules/content/components/ContextualHelpContext';
import { COLORS } from '@/styles/tokens/colors';
import * as diagnostics from '@/utils/diagnostics';

export interface SidebarConfigShape {
  hideFooter: boolean;
}

interface ContextualHelpContent {
  id: string;
  heading: JSX.Element;
  content: JSX.Element;
  config?: SidebarConfigShape;
}

export function ContextualHelpDrawer() {
  const [contextualHelpContent, setContextualHelpContent] =
    React.useState<ContextualHelpContent | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const contextualHelpCtx = useContext(ContextualHelpContext);
  if (!contextualHelpCtx) {
    throw new Error(
      'ContextualHelpDrawer must be used inside a ContextualHelpProvider.'
    );
  }

  const fetchAndSetContextualHelpContent = async (contextualHelpId: string) => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      const Content = dynamic(
        () =>
          import(`../sidebarContent/${contextualHelpId}/content`).then(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
            (module) => module.Content
          ),
        { ssr: false }
      );

      const Heading = dynamic(
        () =>
          import(`../sidebarContent/${contextualHelpId}/content`).then(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
            (module) => module.Heading
          ),
        { ssr: false }
      );

      const maybeConfig = (await import(
        `../sidebarContent/${contextualHelpId}/content`
      )) as {
        SidebarConfig?: SidebarConfigShape;
      };

      setContextualHelpContent({
        heading: <Heading />,
        content: <Content />,
        config: maybeConfig.SidebarConfig,
        id: contextualHelpId,
      });
      setIsLoading(false);
    } catch (err) {
      diagnostics.error('Failed to fetch contextual help', err as Error);
      setIsLoading(false);
      setContextualHelpContent(null);
      setErrorMessage(
        `Sorry, we weren't able to fetch this content! Please close this and try again.`
      );
    }
  };

  const handleClose = () => {
    contextualHelpCtx.setContextualHelpId(null);
  };

  React.useEffect(() => {
    if (contextualHelpContent?.id === contextualHelpCtx.contextualHelpId) {
      return;
    }

    if (contextualHelpCtx.contextualHelpId === null) {
      return setContextualHelpContent(null);
    }

    void fetchAndSetContextualHelpContent(contextualHelpCtx.contextualHelpId);
  }, [
    setContextualHelpContent,
    contextualHelpContent,
    contextualHelpCtx.contextualHelpId,
  ]);

  const getDialogHeading = React.useCallback(() => {
    if (isLoading) return 'One moment...';
    if (errorMessage) return 'Help content';
    return contextualHelpContent?.heading;
  }, [isLoading, contextualHelpContent?.heading, errorMessage]);

  const getDialogBody = React.useCallback(() => {
    if (isLoading)
      return (
        <Loader
          boxProps={{
            sx: {
              textAlign: 'center',
              my: 3,
            },
          }}
        />
      );
    if (errorMessage) return errorMessage;
    return contextualHelpContent?.content;
  }, [isLoading, contextualHelpContent?.content, errorMessage]);

  return (
    <TextEditorProvider theme={'contextContent'}>
      <Drawer
        anchor="right"
        open={contextualHelpCtx.contextualHelpId !== null}
        onClose={handleClose}
        PaperProps={{ sx: { width: 600 } }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          position="sticky"
          top={0}
          component="header"
          sx={(theme) => ({
            borderBottom: `1px solid ${COLORS.ORANGE[400]}`,
            mb: 3,
            pr: 1,
            bgcolor: COLORS.PRIMITIVES.WHITE,
            zIndex: theme.zIndex.drawer + 1,
          })}
        >
          <DialogTitle
            sx={{
              flex: 'unset',
            }}
            variant="h1"
          >
            {getDialogHeading()}
          </DialogTitle>
          <Button
            data-testid="CloseHelpButton"
            variant="transparent"
            size="sm"
            onClick={handleClose}
          >
            <XCloseIcon />
          </Button>
        </Stack>
        <Box px={3} pb={3}>
          {getDialogBody()}
        </Box>
        <Stack flex={1} />
        {!contextualHelpContent?.config?.hideFooter && (
          <ContextualHelpDisclaimerFooter />
        )}
      </Drawer>
    </TextEditorProvider>
  );
}

function ContextualHelpDisclaimerFooter() {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      height={60}
      p={1}
      boxShadow={theme.palette.shadows.drawerShadowInset}
      bgcolor={COLORS.GRAY[50]}
    >
      <Stack maxWidth={100} alignItems="center">
        <PoweredByLuminary textVariant="dark" />
      </Stack>
      <Box maxWidth="70%">
        <Typography variant="subtitle2" textAlign="right">
          Luminary does not provide legal or tax advice. Consult with your legal
          and tax professionals prior to making any estate planning decisions.{' '}
        </Typography>
      </Box>
    </Stack>
  );
}
