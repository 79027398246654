import { Stack, Typography, useTheme } from '@mui/material';
import { compact, first, uniq } from 'lodash';
import { FC, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import {
  SelectInput,
  SelectInputProps,
} from '@/components/form/baseInputs/SelectInput/SelectInput';
import { FormAwareCardRepeater } from '@/components/form/formAwareInputs/FormAwareCardRepeater/FormAwareCardRepeater';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import {
  FormAwareFormattedNumberInput,
  FormAwareFormattedNumberInputProps,
} from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { User01Icon } from '@/components/icons/User01Icon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form/hooks';
import { SuggestableFormAwareInput } from '@/modules/aiSuggestions/components/forms/SuggestableFormAwareInput';
import { DocumentUploaderWithList } from '@/modules/documents/DocumentUploaderWithList/DocumentUploaderWithList';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import {
  AiSuggestionKind,
  DocumentType,
  InsurancePolicyKind,
  InsurancePolicyPremiumFrequency,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { getGrantorOptionsFromData } from '../inputs/PrimaryClientDropdown/PrimaryClientDropdown.utils';
import {
  defaultValues,
  Fields,
  INSURANCE_POLICIES_DETAILS_SUBFORM,
  InsurancePolicyDetails,
  InsurancePolicyDetailsSubformTypePaths,
  InsurancePolicyTypeCopyMap,
  Props,
  VariantType,
} from './InsurancePolicyDetailsSubform.types';

const TERM_LENGTH_OPTIONS: SelectInputProps['options'] = new Array(8)
  .fill(null, 0, 8)
  .map((_, index) => ({
    value: (index + 1) * 5,
    display: `${(index + 1) * 5} years`,
  }));

const POLICY_OPTIONS: SelectInputProps['options'] = [
  {
    value: InsurancePolicyKind.Whole,
    display: InsurancePolicyTypeCopyMap[InsurancePolicyKind.Whole],
  },
  {
    value: InsurancePolicyKind.Term,
    display: InsurancePolicyTypeCopyMap[InsurancePolicyKind.Term],
  },
  {
    value: InsurancePolicyKind.Universal,
    display: InsurancePolicyTypeCopyMap[InsurancePolicyKind.Universal],
  },
  {
    value: InsurancePolicyKind.Variable,
    display: InsurancePolicyTypeCopyMap[InsurancePolicyKind.Variable],
  },
];

const PREMIUM_FREQUENCY_OPTIONS = [
  {
    display: 'Annual',
    value: InsurancePolicyPremiumFrequency.Annually,
  },
  {
    display: 'Semi-annual',
    value: InsurancePolicyPremiumFrequency.Semiannually,
  },
  {
    display: 'Monthly',
    value: InsurancePolicyPremiumFrequency.Monthly,
  },
];

const getPolicyFieldName =
  (policyIndex: number) =>
  <T extends keyof InsurancePolicyDetails>(
    fieldName: T
  ): `${typeof INSURANCE_POLICIES_DETAILS_SUBFORM}.policies.${typeof policyIndex}.${T}` =>
    `${INSURANCE_POLICIES_DETAILS_SUBFORM}.policies.${policyIndex}.${fieldName}` as const satisfies InsurancePolicyDetailsSubformTypePaths;

const InsurancePolicyForm: FC<{
  policyIndex: number;
  householdId: string;
  entityId?: string | undefined;
  variant: VariantType;
}> = ({ policyIndex, householdId, entityId }) => {
  const { control, formState, setValue, reset } = useFormContext<Fields>();
  const { possibleGrantors } = useHouseholdDetailsContext();
  const theme = useTheme();

  const getFieldName = getPolicyFieldName(policyIndex);

  const policyType = useWatch({
    name: getFieldName('policyType'),
    control,
  });
  const isTermPolicy = policyType === InsurancePolicyKind.Term;
  const isConvertible = useWatch({
    name: getFieldName('isConvertible'),
    control,
  });
  const policyHolderIDs =
    useWatch({
      name: getFieldName('policyHolderIDs'),
      control,
    }) || [];

  const [showDocumentUploadError, setShowDocumentUploadError] =
    useState<boolean>(false);
  const formDocuments: string[] = useWatch({
    name: getFieldName('documentIds'),
  });

  const [documentIds, setDocumentIds] = useState<string[]>(formDocuments);
  const isSurvivorshipPolicy = !isTermPolicy && isConvertible;

  const dirtyPolicy =
    formState.dirtyFields[INSURANCE_POLICIES_DETAILS_SUBFORM]?.policies?.[
      policyIndex
    ] || {};
  const dirtyKeys = Object.keys(
    dirtyPolicy
  ) as (keyof InsurancePolicyDetails)[];
  const validateFields =
    !!dirtyKeys.find((item) => dirtyPolicy?.[item]) && !!policyType;

  const grantorOptions: SelectInputProps<string>['options'] =
    getGrantorOptionsFromData(possibleGrantors ?? [], policyHolderIDs);

  return (
    <Card variant="filled" sx={{ p: 2 }}>
      <FormLayoutRow>
        <FormLayoutItem width={6}>
          {/* need to use a plain select for the custom change handler */}
          <SelectInput
            label="Policy type"
            options={POLICY_OPTIONS}
            onChange={(event) => {
              const newValue = event.target.value as InsurancePolicyKind | '';
              // if blank, reset the form
              if (!newValue) {
                reset({
                  insurancePolicyDetailsSubform: {
                    policies: [
                      {
                        ...defaultValues.policies[0],
                        policyType: '',
                      },
                    ],
                  },
                });
                return;
              }
              setValue(getFieldName('policyType'), newValue);
              setValue(getFieldName('isConvertible'), false);
              const activePolicyHolderIDs = compact(policyHolderIDs);
              if (newValue === InsurancePolicyKind.Term) {
                // if newly setting to an term policy, enforce the single-insured restriction
                // by setting the policyHolderIDs array to either the first existing result or a blank string
                setValue(getFieldName('policyHolderIDs'), [
                  first(activePolicyHolderIDs) || '',
                ]);
              } else if (!activePolicyHolderIDs.length) {
                setValue(getFieldName('policyHolderIDs'), ['']);
              }
            }}
            value={policyType}
          />
        </FormLayoutItem>
        <FormLayoutItem
          width={6}
          sx={{
            // necessary to align with the top-label text box to the left
            mt: '22px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <FormAwareSwitch
            fieldName={getFieldName('isConvertible')}
            control={control}
            label={
              isTermPolicy
                ? 'Convertible'
                : 'Survivorship / second to die policy'
            }
            labelPosition="right"
          />
        </FormLayoutItem>
      </FormLayoutRow>

      {isTermPolicy && isConvertible && (
        <FormLayoutRow>
          <FormLayoutItem>
            <Card variant="filled-callout" sx={{ p: 2 }}>
              <FormLayoutRow>
                <FormLayoutItem width={4}>
                  <FormAwareDatePickerInput
                    fieldName={getFieldName('conversionDate')}
                    label="Date of conversion"
                    control={control}
                  />
                </FormLayoutItem>
              </FormLayoutRow>
            </Card>
          </FormLayoutItem>
        </FormLayoutRow>
      )}

      <FormLayoutRow>
        <FormLayoutItem width={8}>
          <FormAwareTextInput
            fieldName={getFieldName('insuranceCarrier')}
            label="Insurance carrier"
            control={control}
          />
        </FormLayoutItem>
        <FormLayoutItem width={4}>
          <FormAwareTextInput
            fieldName={getFieldName('policyNumber')}
            label="Policy number"
            control={control}
          />
        </FormLayoutItem>
      </FormLayoutRow>

      <FormLayoutRow
        sx={
          isTermPolicy
            ? undefined
            : { gap: 2, mb: `${theme.spacing(1)} !important` }
        }
      >
        <FormLayoutItem>
          <FormAwareSelectInput<Fields>
            startAdornment={<User01Icon />}
            control={control}
            options={grantorOptions}
            fieldName={`${getFieldName('policyHolderIDs')}.0`}
            label="Insured name"
            required={validateFields}
          />
        </FormLayoutItem>
      </FormLayoutRow>

      {!isTermPolicy && (
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareSelectInput<Fields>
              startAdornment={<User01Icon />}
              control={control}
              options={grantorOptions}
              fieldName={`${getFieldName('policyHolderIDs')}.1`}
              label="Insured name"
              required={validateFields && isSurvivorshipPolicy}
              hideLabel
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}

      <FormLayoutRow>
        <FormLayoutItem width={4}>
          <FormAwareCurrencyInput
            control={control}
            label="Death benefit amount"
            fieldName={getFieldName('deathBenefitAmount')}
            required={validateFields}
            isDecimalJSInput
          />
        </FormLayoutItem>
        <FormLayoutItem width={4}>
          <FormAwareDatePickerInput
            label="Policy start date"
            fieldName={getFieldName('policyStartDate')}
            control={control}
            required={validateFields}
          />
        </FormLayoutItem>
        {isTermPolicy && (
          <FormLayoutItem width={4}>
            <FormAwareSelectInput
              label="Term length"
              fieldName={getFieldName('termLength')}
              control={control}
              options={TERM_LENGTH_OPTIONS}
              required={validateFields}
            />
          </FormLayoutItem>
        )}
      </FormLayoutRow>

      <Card variant="filled-dark" sx={{ p: 2, mb: 2 }}>
        <FormLayoutRow>
          <FormLayoutItem>
            <Typography variant="h3">Policy premium payment details</Typography>
          </FormLayoutItem>
        </FormLayoutRow>

        <FormLayoutRow>
          <FormLayoutItem width={4}>
            <FormAwareCurrencyInput
              label="Premium amount"
              fieldName={getFieldName('premiumAmount')}
              control={control}
              isDecimalJSInput
            />
          </FormLayoutItem>
          <FormLayoutItem width={4}>
            <FormAwareSelectInput
              label="Frequency"
              fieldName={getFieldName('premiumFrequency')}
              control={control}
              options={PREMIUM_FREQUENCY_OPTIONS}
            />
          </FormLayoutItem>
          <FormLayoutItem width={4}>
            <FormAwareDatePickerInput
              label="Initial due date"
              fieldName={getFieldName('premiumInitialDueDate')}
              control={control}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      </Card>

      {!isTermPolicy && (
        <FormLayoutRow>
          <FormLayoutItem width={4}>
            <FormAwareCurrencyInput<Fields>
              fieldName={getFieldName('cashValue')}
              label="Cash value"
              control={control}
              isDecimalJSInput
            />
          </FormLayoutItem>
          <FormLayoutItem width={4}>
            <FormAwareDatePickerInput<Fields>
              fieldName={getFieldName('cashValueDate')}
              label="Cash value date"
              control={control}
            />
          </FormLayoutItem>
          <FormLayoutItem width={4}>
            <FormAwareCurrencyInput<Fields>
              fieldName={getFieldName('loanBalanceOutstanding')}
              label="Loan balance outstanding"
              control={control}
              isDecimalJSInput
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput
            label="Notes"
            fieldName={getFieldName('notes')}
            control={control}
            multiline
            rows={3}
          />
        </FormLayoutItem>
      </FormLayoutRow>

      <FormLayoutRow>
        <FormLayoutItem>
          <DocumentUploaderWithList
            uploaderLabel=""
            documentIds={documentIds}
            householdId={householdId}
            listDocumentsLike={
              entityId
                ? {
                    hasEntityWith: [{ id: entityId }],
                    type: DocumentType.InsurancePolicy,
                  }
                : undefined
            }
            getCreateDocumentInput={(file) => ({
              householdID: householdId,
              name: file.fileName,
              entityID: entityId || undefined,
              type: DocumentType.InsurancePolicy,
              fileID: file.fileId,
            })}
            onDocumentUploadSuccess={({ documentId }) => {
              const uniqueDocumentIds = uniq([...documentIds, documentId]);
              setValue(getFieldName('documentIds'), uniqueDocumentIds);
              setDocumentIds(uniqueDocumentIds);
              setShowDocumentUploadError(false);
            }}
            errorMessage={
              showDocumentUploadError
                ? 'Could not upload the document'
                : undefined
            }
            onDocumentUploadError={(error) => {
              diagnostics.error(
                'Caught error when uploading ILIT document',
                error
              );
              setShowDocumentUploadError(true);
            }}
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </Card>
  );
};

export function UI(props: Props) {
  const { householdId, entityId, variant } = props;

  const { control, formState: insurancePoliciesFormState } =
    useFormContext<Fields>();

  const policies = useWatch({
    name: `${INSURANCE_POLICIES_DETAILS_SUBFORM}.policies`,
    control,
  });

  const shouldValidateCrummeyLetter = useMemo(() => {
    // validate the crummey letter field if:
    // - the user has dirtied any field in the form
    // - there's at least one policy holder
    //   for one policy holder to exist, the user either needs to dirty the form (to newly
    //   select one) or load an existing policy; this is effectively a stand-in for validating
    //   after loading an existing trust
    const hasPolicyHolder = !!(policies || []).find((policy) =>
      policy.policyHolderIDs.find((id) => !!id)
    );

    const hasDirtyInsuranceField =
      !!insurancePoliciesFormState.dirtyFields[
        INSURANCE_POLICIES_DETAILS_SUBFORM
      ]?.policies;
    return hasDirtyInsuranceField || hasPolicyHolder;
  }, [insurancePoliciesFormState.dirtyFields, policies]);

  return (
    <Stack direction="column" gap={3}>
      {variant === 'ilit' && (
        <>
          <Typography variant="body1">
            <b>Tasks are automatically generated</b> using the policy &
            beneficiary details entered below. These tasks will prompt you to
            make annual gift contributions, send Crummey letters, and make
            premium payments.
          </Typography>
          <Card variant="filled-callout" sx={{ p: 2 }}>
            <FormLayoutRow>
              <FormLayoutItem sx={{ width: '240px' }}>
                <SuggestableFormAwareInput<
                  Fields,
                  FormAwareFormattedNumberInputProps<Fields>
                >
                  FormAwareElement={FormAwareFormattedNumberInput}
                  suggestionKind={AiSuggestionKind.CrummeyWithdrawalPeriodDays}
                  fieldName={
                    `${INSURANCE_POLICIES_DETAILS_SUBFORM}.crummeyWithdrawalPeriodDays` as const satisfies InsurancePolicyDetailsSubformTypePaths
                  }
                  label="Crummey withdrawal period"
                  decimalScale={0}
                  control={control}
                  required={shouldValidateCrummeyLetter}
                  isDecimalJSInput
                  fixedDecimalScale
                  endAdornment={<Typography variant="caption">days</Typography>}
                />
              </FormLayoutItem>
            </FormLayoutRow>
          </Card>
        </>
      )}
      <FormAwareCardRepeater
        name={
          `${INSURANCE_POLICIES_DETAILS_SUBFORM}.policies` as const satisfies InsurancePolicyDetailsSubformTypePaths
        }
        control={control}
        addAnotherItemText="Add additional policy"
        showRemove={policies.length > 1}
        emptyValue={defaultValues.policies[0]}
        allowEmpty={false}
        render={(index) => (
          <InsurancePolicyForm
            policyIndex={index}
            key={index}
            householdId={householdId}
            entityId={entityId}
            variant={variant}
          />
        )}
      />
    </Stack>
  );
}
