// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BackfillOcrUserFragment = { __typename?: 'User', id: string, displayName: string };

export type BackfillOcrAsyncJobFragment = { __typename?: 'AsyncJob', id: string, kind: Types.AsyncJobKind, status: Types.AsyncJobStatus, updatedAt: Time, user: { __typename?: 'User', id: string, displayName: string } };

export type BackfillOcrMutationVariables = Types.Exact<{
  skipExistingOCR: Types.Scalars['Boolean']['input'];
}>;


export type BackfillOcrMutation = { __typename?: 'Mutation', asyncDocumentOCRBackfill: Array<{ __typename?: 'AsyncJob', id: string, kind: Types.AsyncJobKind, status: Types.AsyncJobStatus, updatedAt: Time, user: { __typename?: 'User', id: string, displayName: string } }> };

export type BackfillOcrFetchMostRecentJobsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BackfillOcrFetchMostRecentJobsQuery = { __typename?: 'Query', backfillOCRAsyncJobs: Array<{ __typename?: 'AsyncJob', id: string, kind: Types.AsyncJobKind, status: Types.AsyncJobStatus, updatedAt: Time, user: { __typename?: 'User', id: string, displayName: string } }> };

export const BackfillOcrUserFragmentDoc = gql`
    fragment BackfillOCRUser on User {
  id
  displayName
}
    `;
export const BackfillOcrAsyncJobFragmentDoc = gql`
    fragment BackfillOCRAsyncJob on AsyncJob {
  id
  kind
  status
  updatedAt
  user {
    ...BackfillOCRUser
  }
}
    ${BackfillOcrUserFragmentDoc}`;
export const BackfillOcrDocument = gql`
    mutation BackfillOCR($skipExistingOCR: Boolean!) {
  asyncDocumentOCRBackfill(skipExistingOCR: $skipExistingOCR) {
    ...BackfillOCRAsyncJob
  }
}
    ${BackfillOcrAsyncJobFragmentDoc}`;
export type BackfillOcrMutationFn = Apollo.MutationFunction<BackfillOcrMutation, BackfillOcrMutationVariables>;

/**
 * __useBackfillOcrMutation__
 *
 * To run a mutation, you first call `useBackfillOcrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackfillOcrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backfillOcrMutation, { data, loading, error }] = useBackfillOcrMutation({
 *   variables: {
 *      skipExistingOCR: // value for 'skipExistingOCR'
 *   },
 * });
 */
export function useBackfillOcrMutation(baseOptions?: Apollo.MutationHookOptions<BackfillOcrMutation, BackfillOcrMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BackfillOcrMutation, BackfillOcrMutationVariables>(BackfillOcrDocument, options);
      }
export type BackfillOcrMutationHookResult = ReturnType<typeof useBackfillOcrMutation>;
export type BackfillOcrMutationResult = Apollo.MutationResult<BackfillOcrMutation>;
export type BackfillOcrMutationOptions = Apollo.BaseMutationOptions<BackfillOcrMutation, BackfillOcrMutationVariables>;
export const BackfillOcrFetchMostRecentJobsDocument = gql`
    query BackfillOCRFetchMostRecentJobs {
  backfillOCRAsyncJobs: getMostRecentDocumentAsyncJobs(
    kind: DOCUMENT_OCR_BACKFILL
  ) {
    ...BackfillOCRAsyncJob
  }
}
    ${BackfillOcrAsyncJobFragmentDoc}`;

/**
 * __useBackfillOcrFetchMostRecentJobsQuery__
 *
 * To run a query within a React component, call `useBackfillOcrFetchMostRecentJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackfillOcrFetchMostRecentJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackfillOcrFetchMostRecentJobsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBackfillOcrFetchMostRecentJobsQuery(baseOptions?: Apollo.QueryHookOptions<BackfillOcrFetchMostRecentJobsQuery, BackfillOcrFetchMostRecentJobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BackfillOcrFetchMostRecentJobsQuery, BackfillOcrFetchMostRecentJobsQueryVariables>(BackfillOcrFetchMostRecentJobsDocument, options);
      }
export function useBackfillOcrFetchMostRecentJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BackfillOcrFetchMostRecentJobsQuery, BackfillOcrFetchMostRecentJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BackfillOcrFetchMostRecentJobsQuery, BackfillOcrFetchMostRecentJobsQueryVariables>(BackfillOcrFetchMostRecentJobsDocument, options);
        }
export function useBackfillOcrFetchMostRecentJobsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BackfillOcrFetchMostRecentJobsQuery, BackfillOcrFetchMostRecentJobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BackfillOcrFetchMostRecentJobsQuery, BackfillOcrFetchMostRecentJobsQueryVariables>(BackfillOcrFetchMostRecentJobsDocument, options);
        }
export type BackfillOcrFetchMostRecentJobsQueryHookResult = ReturnType<typeof useBackfillOcrFetchMostRecentJobsQuery>;
export type BackfillOcrFetchMostRecentJobsLazyQueryHookResult = ReturnType<typeof useBackfillOcrFetchMostRecentJobsLazyQuery>;
export type BackfillOcrFetchMostRecentJobsSuspenseQueryHookResult = ReturnType<typeof useBackfillOcrFetchMostRecentJobsSuspenseQuery>;
export type BackfillOcrFetchMostRecentJobsQueryResult = Apollo.QueryResult<BackfillOcrFetchMostRecentJobsQuery, BackfillOcrFetchMostRecentJobsQueryVariables>;
export const Operations = {
  Query: {
    BackfillOCRFetchMostRecentJobs: 'BackfillOCRFetchMostRecentJobs' as const
  },
  Mutation: {
    BackfillOCR: 'BackfillOCR' as const
  },
  Fragment: {
    BackfillOCRUser: 'BackfillOCRUser' as const,
    BackfillOCRAsyncJob: 'BackfillOCRAsyncJob' as const
  }
}