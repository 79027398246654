import type { ReactFlowState } from '@xyflow/react';
import { useCallback } from 'react';

import { useGetValue } from './internal/useGetValue';

interface UseIsConnectedNodeDraggingProps {
  source: string;
  target: string;
}

export function useIsConnectedNodeDragging({
  source,
  target,
}: UseIsConnectedNodeDraggingProps) {
  const selector = useCallback(
    (s: ReactFlowState) => {
      return (
        !!s.nodeLookup.get(source)?.dragging ||
        !!s.nodeLookup.get(target)?.dragging
      );
    },
    [source, target]
  );

  const { value: dragging, get: getDragging } = useGetValue(selector);

  return { dragging, getDragging };
}
