import { GridApiPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { FC, MutableRefObject } from 'react';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { DataTableCard } from '@/components/tables/DataTable/components/DataTableCard';
import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { getNodes } from '@/utils/graphqlUtils';

import { ClientValuePerformanceDashboardSummary } from './ClientValuePerformanceDashboard.components';
import {
  mapAdvisorClientToValueDashboardRows,
  Row,
} from './ClientValuePerformanceDashboard.utils';
import { ClientValuePerformanceTable } from './ClientValuePerformanceTable';
import { useClientValuePerformanceDashboardQuery } from './graphql/ClientValuePerformanceDashboard.generated';

export interface ClientValuePerformanceDashboardContentProps {
  householdId: string;
  gridApiRef?: MutableRefObject<GridApiPro>;
  rows: Row[];
  loading?: boolean;
}
export function ClientValuePerformanceDashboardContent({
  householdId,
  gridApiRef,
  rows,
  loading = false,
}: ClientValuePerformanceDashboardContentProps) {
  return (
    <DataTableCard>
      <ClientValuePerformanceTable
        rows={rows}
        loading={loading}
        householdId={householdId}
        apiRef={gridApiRef}
      />
    </DataTableCard>
  );
}

export const ClientValuePerformanceDashboard: FC = () => {
  const householdId = useRequiredParam('householdId');
  const gridApiRef = useGridApiRef();

  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const { data, loading: loadingFromNetwork } =
    useClientValuePerformanceDashboardQuery({
      variables: { householdId },
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
        reportError('Encountered an issue when loading report', error);
        showFeedback('Encountered an error when loading report');
      },
    });

  const household = getNodes(data?.households)?.[0] || null;
  const clientPerformanceReport = household?.clientPerformanceReport;
  const { rows, familyWealthTransferred, charityWealthTransferred } =
    mapAdvisorClientToValueDashboardRows(household);

  return (
    <SubpageLayout
      heading={undefined}
      // we're not yet happy with how this looks in print mode, so disabling it for now
      // see T2-1683
      // actions={
      // <PrintClientDashboardButton
      //   householdId={householdId}
      //   fileName={
      //     household?.displayName
      //       ? `Performance report for ${household.displayName}`
      //       : 'Performance report'
      //   }
      // />
      // }
    >
      <ClientValuePerformanceDashboardSummary
        familyGivingWealthTransferred={familyWealthTransferred}
        charityWealthTransferred={charityWealthTransferred}
        clientPerformanceReport={clientPerformanceReport}
      />
      <ClientValuePerformanceDashboardContent
        rows={rows}
        householdId={householdId}
        gridApiRef={gridApiRef}
        loading={loadingFromNetwork && !data}
      />
    </SubpageLayout>
  );
};
