import { AugmentedUpdateEntityInput, EntityStage } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { BASIC_INFORMATION_SUBFORM_NAMESPACE as BASIC_INFORMATION } from '../BasicInformationSubform/BasicInformationSubform.types';
import { EntityType } from '../types/EntityType';
import { PartialSubformsCombinedType } from './EntitySubforms.types';
import { makeUpdateBusinessEntityInput } from './utils/entities/businessEntities.utils';
import {
  makeEntityTypeUpdateFoundationInput,
  makeUpdateNonTrustCharitableEntityInput,
} from './utils/entities/charitableNonTrustEntities';
import {
  makeUpdateCustodialAccountInput,
  makeUpdateIndividualAccountInput,
  makeUpdateInsuranceAccountInput,
  makeUpdateJointAccountInput,
  makeUpdateQualifiedTuitionAccountInput,
  makeUpdateRetirementAccountInput,
} from './utils/entities/personalAccounts.utils';
import {
  makeEntityTypeUpdateTrustInput,
  makeUpdateTrustInput,
} from './utils/entities/trusts.utils';
import { getSharedAssetIntegrationUpdateProperties } from './utils/shared/common.utils';
import { validateCcorpUpdateInput } from './validators/ccorp';
import { validateCltTrustUpdateInput } from './validators/clt';
import { validateCrtTrustUpdateInput } from './validators/crt';
import { validateCustodialAccountUpdateInput } from './validators/custodialAccount';
import { validateDafUpdateInput } from './validators/daf';
import { validateGPUpdateInput } from './validators/gp';
import { validateGratTrustUpdateInput } from './validators/grat';
import { validateIlitTrustUpdateInput } from './validators/ilit';
import { validateIndividualAccountUpdateInput } from './validators/individualAccount';
import { validateInsuranceAccountUpdateInput } from './validators/insuranceAccount';
import { validateIrrevocableTrustUpdateInput } from './validators/irrevocableTrust';
import { validateJointAccountUpdateInput } from './validators/jointAccount';
import { validateLLCUpdateInput } from './validators/llc';
import { validateLPUpdateInput } from './validators/lp';
import { validatePrivateFoundationUpdateInput } from './validators/privateFoundation';
import { validateQprtTrustUpdateInput } from './validators/qprt';
import { validateQualifiedTuitionAccountUpdateInput } from './validators/qualifiedTuitionAccount';
import { validateRetirementAccountUpdateInput } from './validators/retirementAccount';
import { validateRevocableTrustUpdateInput } from './validators/revocableTrust';
import { validateScorpUpdateInput } from './validators/scorp';
import { validateSlatTrustUpdateInput } from './validators/slat';
import { validateSoleProprietorshipUpdateInput } from './validators/soleProprietorship';

function makeUpdateRevocableTrustInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const input: AugmentedUpdateEntityInput = makeEntityTypeUpdateTrustInput(
    entityId,
    'revocable-trust',
    {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
    },
    makeUpdateTrustInput({
      formInput,
      subtypeId,
      entityType: 'revocable-trust',
      householdId,
    }),
    formInput
  );

  return input;
}

function makeUpdateIrrevocableTrustInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const input: AugmentedUpdateEntityInput = makeEntityTypeUpdateTrustInput(
    entityId,
    'irrevocable-trust',
    {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
    },
    makeUpdateTrustInput({
      formInput,
      subtypeId,
      entityType: 'irrevocable-trust',
      householdId,
    }),
    formInput
  );

  return input;
}

function makeUpdateSlatTrustInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const input: AugmentedUpdateEntityInput = makeEntityTypeUpdateTrustInput(
    entityId,
    'slat',
    {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
    },
    makeUpdateTrustInput({
      formInput,
      subtypeId,
      entityType: 'slat',
      householdId,
    }),
    formInput
  );

  return input;
}

function makeUpdateGratTrustInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const input: AugmentedUpdateEntityInput = makeEntityTypeUpdateTrustInput(
    entityId,
    'grat',
    {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
    },
    makeUpdateTrustInput({
      formInput,
      subtypeId,
      entityType: 'grat',
      householdId,
    }),
    formInput
  );

  return input;
}

function makeUpdateQprtTrustInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const input: AugmentedUpdateEntityInput = makeEntityTypeUpdateTrustInput(
    entityId,
    'qprt',
    {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
    },
    makeUpdateTrustInput({
      formInput,
      subtypeId,
      entityType: 'qprt',
      householdId,
    }),
    formInput
  );

  const existingStage =
    formInput[BASIC_INFORMATION]?.stage ?? EntityStage.Active;

  return {
    ...input,
    update: {
      ...input.update,
      stage: formInput[BASIC_INFORMATION]?.isCompleted
        ? EntityStage.Completed
        : existingStage,
    },
  };
}

function makeUpdateIlitTrustInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const input: AugmentedUpdateEntityInput = makeEntityTypeUpdateTrustInput(
    entityId,
    'ilit',
    {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
    },
    makeUpdateTrustInput({
      formInput,
      subtypeId,
      entityType: 'ilit',
      householdId,
    }),
    formInput
  );

  return input;
}

function makeUpdateCltTrustInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const input: AugmentedUpdateEntityInput = makeEntityTypeUpdateTrustInput(
    entityId,
    'clt',
    {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
    },
    makeUpdateTrustInput({
      formInput,
      subtypeId,
      entityType: 'clt',
      householdId,
    }),
    formInput
  );

  const existingStage =
    formInput[BASIC_INFORMATION]?.stage ?? EntityStage.Active;

  return {
    ...input,
    update: {
      ...input.update,
      stage: formInput[BASIC_INFORMATION]?.isCompleted
        ? EntityStage.Completed
        : existingStage,
    },
  };
}

function makeUpdateCrtTrustInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const input: AugmentedUpdateEntityInput = makeEntityTypeUpdateTrustInput(
    entityId,
    'crt',
    {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
    },
    makeUpdateTrustInput({
      formInput,
      subtypeId,
      entityType: 'crt',
      householdId,
    }),
    formInput
  );

  const existingStage =
    formInput[BASIC_INFORMATION]?.stage ?? EntityStage.Active;

  return {
    ...input,
    update: {
      ...input.update,
      stage: formInput[BASIC_INFORMATION]?.isCompleted
        ? EntityStage.Completed
        : existingStage,
    },
  };
}

function makeUpdateDafInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const input: AugmentedUpdateEntityInput = makeEntityTypeUpdateFoundationInput(
    entityId,
    'daf',
    {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
    },
    makeUpdateNonTrustCharitableEntityInput({
      formInput,
      subtypeId,
      entityType: 'daf',
      householdId,
    })
  );

  return input;
}

function makeUpdatePrivateFoundationInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const input: AugmentedUpdateEntityInput = makeEntityTypeUpdateFoundationInput(
    entityId,
    'private-foundation',
    {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
    },
    makeUpdateNonTrustCharitableEntityInput({
      formInput,
      subtypeId,
      entityType: 'private-foundation',
      householdId,
    })
  );

  return input;
}

export interface MakeUpdateEntityInputParams {
  entityId: string;
  subtypeId: string;
  entityType: EntityType;
  householdId: string;
}

export function makeUpdateEntityInput(
  input: PartialSubformsCombinedType,
  params: MakeUpdateEntityInputParams
): AugmentedUpdateEntityInput {
  switch (params.entityType) {
    case 'revocable-trust':
      return validateRevocableTrustUpdateInput(
        makeUpdateRevocableTrustInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'irrevocable-trust':
      return validateIrrevocableTrustUpdateInput(
        makeUpdateIrrevocableTrustInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'slat':
      return validateSlatTrustUpdateInput(
        makeUpdateSlatTrustInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'grat':
      return validateGratTrustUpdateInput(
        makeUpdateGratTrustInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'qprt':
      return validateQprtTrustUpdateInput(
        makeUpdateQprtTrustInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'ilit':
      return validateIlitTrustUpdateInput(
        makeUpdateIlitTrustInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'individual-account':
      return validateIndividualAccountUpdateInput(
        makeUpdateIndividualAccountInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'insurance-account':
      return validateInsuranceAccountUpdateInput(
        makeUpdateInsuranceAccountInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'clt':
      return validateCltTrustUpdateInput(
        makeUpdateCltTrustInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'crt':
      return validateCrtTrustUpdateInput(
        makeUpdateCrtTrustInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'daf':
      return validateDafUpdateInput(
        makeUpdateDafInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'private-foundation':
      return validatePrivateFoundationUpdateInput(
        makeUpdatePrivateFoundationInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'joint-account':
      return validateJointAccountUpdateInput(
        makeUpdateJointAccountInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'retirement-account':
      return validateRetirementAccountUpdateInput(
        makeUpdateRetirementAccountInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'custodial-account':
      return validateCustodialAccountUpdateInput(
        makeUpdateCustodialAccountInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    case 'qualified-tuition-account':
      return validateQualifiedTuitionAccountUpdateInput(
        makeUpdateQualifiedTuitionAccountInput(
          input,
          params.entityId,
          params.subtypeId,
          params.householdId
        )
      );

    // Business entities
    case 'sole-proprietorship':
      return validateSoleProprietorshipUpdateInput(
        makeUpdateBusinessEntityInput(
          input,
          params.entityId,
          params.entityType,
          params.subtypeId
        )
      );
    case 'c-corp':
      return validateCcorpUpdateInput(
        makeUpdateBusinessEntityInput(
          input,
          params.entityId,
          params.entityType,
          params.subtypeId
        )
      );
    case 's-corp':
      return validateScorpUpdateInput(
        makeUpdateBusinessEntityInput(
          input,
          params.entityId,
          params.entityType,
          params.subtypeId
        )
      );
    case 'llc':
      return validateLLCUpdateInput(
        makeUpdateBusinessEntityInput(
          input,
          params.entityId,
          params.entityType,
          params.subtypeId
        )
      );
    case 'lp':
      return validateLPUpdateInput(
        makeUpdateBusinessEntityInput(
          input,
          params.entityId,
          params.entityType,
          params.subtypeId
        )
      );
    case 'gp':
      return validateGPUpdateInput(
        makeUpdateBusinessEntityInput(
          input,
          params.entityId,
          params.entityType,
          params.subtypeId
        )
      );

    default: {
      const err = Error(
        `Could not create entity input for unrecognized entity type: ${params.entityType}`
      );

      diagnostics.error(`Unrecognized entity type: ${params.entityType}`, err);
      throw err;
    }
  }
}
