import { cx } from '@emotion/css';
import { SxProps } from '@mui/material';
import {
  DataGridProProps,
  GridEventListener,
  GridGroupingColDefOverride,
  GridRowClassNameParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { FC, MutableRefObject, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { InteractionParadigmContext } from '@/contexts/InteractionParadigm.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { EntityKind } from '@/types/schema';

import {
  getClientValuePerformanceDashboardColumns,
  HEADING_CHARITABLE_GIVING,
  HEADING_FAMILY_GIVING,
  Row,
} from './ClientValuePerformanceDashboard.utils';

const getTreeDataPath: DataGridProProps<Row>['getTreeDataPath'] = (row) =>
  row.hierarchy;

const Renderer = TwoLineTextRenderer<Row>({
  lineOne: ({ row: { line1 } }) => line1,
  lineTwo: ({ row: { line2 } }) => line2,
  lineOneProps: (params) => ({
    sx: {
      fontWeight: params.row?.shouldShowBold ? 'bold' : 'auto',
    },
  }),
  rightContent: ({ row: { nameRightContent } }) => nameRightContent,
});

const groupingColDef: GridGroupingColDefOverride<Row> = {
  hideDescendantCount: true,
  headerName: '',
  flex: 2,
  disableColumnMenu: true,
  disableReorder: true,
  // use valueGetter instead of renderCell to preserve the hierarchical display and expand/collapse arrows
  valueGetter: (params) => <Renderer {...params} />,
};

function getRowClassName({
  row: { shouldShowBold, id, entityId },
}: GridRowClassNameParams<Row>): string {
  const shouldShowCursorIcon = !!entityId;
  const shouldShowFamilyGiving = id === HEADING_FAMILY_GIVING;
  const shouldShowCharitableGiving = id === HEADING_CHARITABLE_GIVING;

  return cx(
    shouldShowBold && 'shouldShowBold',
    shouldShowFamilyGiving && 'shouldShowFamilyGiving',
    shouldShowCursorIcon && 'shouldShowCursorIcon',
    shouldShowCharitableGiving && 'shouldShowCharitableGiving'
  );
}

function getStyles(isViewOnly: boolean): SxProps {
  const styles: SxProps = {
    width: isViewOnly ? '924px' : undefined,
  };
  return styles;
}

export const ClientValuePerformanceTable: FC<{
  rows: Row[];
  loading?: boolean;
  householdId: string;
  apiRef?: MutableRefObject<GridApiPro>;
}> = ({ rows, loading, householdId, apiRef }) => {
  const navigate = useNavigate();
  const { viewOnly: isViewOnly } = useContext(InteractionParadigmContext);

  const handleNavigate: GridEventListener<'rowClick'> = useCallback(
    ({ row: { entityId, entityKind } }: GridRowParams<Row>, event) => {
      if (!entityId) {
        return;
      }

      let trustDetailsPath = '';

      if (entityKind === EntityKind.GratTrust) {
        trustDetailsPath = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_ENTITY_PERFORMANCE,
          {
            householdId,
            entityId: entityId.toString(),
          }
        );
      } else {
        trustDetailsPath = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_ENTITY_VALUATION_HISTORY,
          {
            householdId,
            entityId: entityId.toString(),
          }
        );
      }

      if (event.metaKey) {
        // if the user cmd-clicks the row, open it in a new window
        window.open(trustDetailsPath, '_blank');
      } else {
        navigate(trustDetailsPath, {});
      }
    },
    [householdId, navigate]
  );

  return (
    <DataTable
      treeData
      autoHeight
      apiRef={apiRef}
      rows={rows}
      columns={getClientValuePerformanceDashboardColumns(isViewOnly)}
      getTreeDataPath={getTreeDataPath}
      getRowClassName={getRowClassName}
      groupingColDef={groupingColDef}
      pagination={false}
      slots={{ footer: EmptyFooter }}
      useTreeStyling
      hideFooterPagination
      useEntityAwareStyling
      hideFooterSelectedRowCount
      hideFooterRowCount
      disableMultipleRowSelection
      disableColumnReorder
      disableColumnSelector
      disableRowSelectionOnClick
      disableVirtualization
      defaultGroupingExpansionDepth={-1}
      onRowClick={handleNavigate}
      loading={loading}
      rowSelection={false}
      sx={getStyles(isViewOnly)}
    />
  );
};

function EmptyFooter() {
  return <></>;
}
