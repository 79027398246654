import { Box, Stack } from '@mui/material';
import React from 'react';

import { CustomerThemeProvider } from '@/styles/themes/CustomerThemeProvider';
import { COLORS } from '@/styles/tokens/colors';

import { ScalableSlide } from '../ScalableSlide/ScalableSlide';
import {
  US_LETTER_ASPECT_RATIO,
  US_LETTER_SCALE_WIDTH_PX,
} from './PresentationSlide.constants';

interface HeaderProps {
  leftHeaderContent?: React.ReactNode;
  rightHeaderContent?: React.ReactNode;
}

function PresentationSlideHeader({
  leftHeaderContent,
  rightHeaderContent,
}: HeaderProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={3}
      justifyContent="space-between"
      component="header"
      sx={{ py: 3, borderBottom: `2px solid ${COLORS.GRAY[900]}` }}
    >
      {leftHeaderContent}
      {rightHeaderContent && rightHeaderContent}
    </Stack>
  );
}

export type PresentationSlideProps = React.PropsWithChildren<{
  footer?: React.ReactNode;
  // this id property is used to make it so that the slide can be scrolled to
  id: string;
  // readyToScale is an optional property that can be used to delay the scaling of the slide
  // which may be useful if the slide needs to be initialized before it can be scaled correctly
  readyToScale?: boolean;
}> &
  HeaderProps &
  Omit<React.HTMLProps<HTMLDivElement>, 'ref'>;

export function PresentationSlide({
  rightHeaderContent,
  leftHeaderContent,
  children,
  footer,
  readyToScale,
  ...htmlProps
}: PresentationSlideProps) {
  return (
    <CustomerThemeProvider>
      <ScalableSlide
        aspectRatio={US_LETTER_ASPECT_RATIO}
        scaleWidthPx={US_LETTER_SCALE_WIDTH_PX}
        readyToScale={readyToScale}
      >
        <Stack
          spacing={2}
          justifyContent="space-between"
          height="100%"
          {...htmlProps}
        >
          {(leftHeaderContent || rightHeaderContent) && (
            <Box px={3}>
              <PresentationSlideHeader
                rightHeaderContent={rightHeaderContent}
                leftHeaderContent={leftHeaderContent}
              />
            </Box>
          )}
          <Box flexGrow={1}>{children}</Box>
          {footer && <Box pb={2}>{footer}</Box>}
        </Stack>
      </ScalableSlide>
    </CustomerThemeProvider>
  );
}
