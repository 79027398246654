import { useEffect, useState } from 'react';

import { useFederalEstateTaxFormatted } from '@/modules/irs/useIRSConstants';

import { TextEditor } from '../TextEditor/TextEditor';

type MarkdownVars = 'FEDERAL_TAX_RATE_SENTINEL';

function useMarkdownVars(markdown: string) {
  const rate = useFederalEstateTaxFormatted();

  return markdown.replace(
    new RegExp(
      'FEDERAL_TAX_RATE_SENTINEL' as const satisfies MarkdownVars,
      'g'
    ),
    `${rate}%`
  );
}

interface MarkdownProps {
  markdown: string;
}

// Helper component to render markdown in a read-only text editor
export function Markdown({ markdown: markdownExternal }: MarkdownProps) {
  const markdown = useMarkdownVars(markdownExternal);

  // get unique key when markdown changes
  const [key, setKey] = useState(0);

  // update key when markdown changes
  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [markdown]);

  return <TextEditor key={key} initialValue={markdown} readOnly />;
}
