// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkTaskSkippedMutationVariables = Types.Exact<{
  cancelEntityTaskId: Types.Scalars['ID']['input'];
  cancelledReason: Types.Scalars['String']['input'];
}>;


export type MarkTaskSkippedMutation = { __typename?: 'Mutation', cancelEntityTask: { __typename?: 'EntityTask', id: string } };


export const MarkTaskSkippedDocument = gql`
    mutation MarkTaskSkipped($cancelEntityTaskId: ID!, $cancelledReason: String!) {
  cancelEntityTask(id: $cancelEntityTaskId, cancelledReason: $cancelledReason) {
    id
  }
}
    `;
export type MarkTaskSkippedMutationFn = Apollo.MutationFunction<MarkTaskSkippedMutation, MarkTaskSkippedMutationVariables>;

/**
 * __useMarkTaskSkippedMutation__
 *
 * To run a mutation, you first call `useMarkTaskSkippedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTaskSkippedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTaskSkippedMutation, { data, loading, error }] = useMarkTaskSkippedMutation({
 *   variables: {
 *      cancelEntityTaskId: // value for 'cancelEntityTaskId'
 *      cancelledReason: // value for 'cancelledReason'
 *   },
 * });
 */
export function useMarkTaskSkippedMutation(baseOptions?: Apollo.MutationHookOptions<MarkTaskSkippedMutation, MarkTaskSkippedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkTaskSkippedMutation, MarkTaskSkippedMutationVariables>(MarkTaskSkippedDocument, options);
      }
export type MarkTaskSkippedMutationHookResult = ReturnType<typeof useMarkTaskSkippedMutation>;
export type MarkTaskSkippedMutationResult = Apollo.MutationResult<MarkTaskSkippedMutation>;
export type MarkTaskSkippedMutationOptions = Apollo.BaseMutationOptions<MarkTaskSkippedMutation, MarkTaskSkippedMutationVariables>;
export const Operations = {
  Mutation: {
    MarkTaskSkipped: 'MarkTaskSkipped' as const
  }
}