import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { COLORS } from '@/styles/tokens/colors';
import { formatPercent } from '@/utils/formatting/percent';

interface Props {
  inTermRoR: Decimal;
  afterTermTaxFreeInTrust: Decimal;
  afterTermTaxedInEstate: Decimal;
}

export function ScenarioReturns({
  inTermRoR,
  afterTermTaxFreeInTrust,
  afterTermTaxedInEstate,
}: Props) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={2}
      margin={2}
      p={2}
      sx={{
        backgroundColor: 'background.paper',
        borderRadius: 1,
        border: '1px solid',
        borderColor: COLORS.GRAY[200],
      }}
    >
      <Stack direction="column" width="100%" spacing={1}>
        <Typography variant="h6">In-term returns</Typography>
        <Box component="span" display="flex">
          <Typography paddingRight={0.5} variant="h5">
            {formatPercent(inTermRoR, 1)}%
          </Typography>
          <Typography variant="subtitle1">annually</Typography>
        </Box>
      </Stack>
      <Stack direction="column" width="100%" spacing={1}>
        <Typography variant="h6">After-term returns</Typography>
        <Box component="span" display="flex">
          <Typography paddingRight={0.5} variant="h5">
            {formatPercent(afterTermTaxFreeInTrust, 1)}%
          </Typography>
          <Typography variant="subtitle1">grantor trust (tax-free)</Typography>
        </Box>
        <Box component="span" display="flex">
          <Typography paddingRight={0.5} variant="h5">
            {formatPercent(afterTermTaxedInEstate, 1)}%
          </Typography>
          <Typography variant="subtitle1">
            other entities (after-tax)
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}
