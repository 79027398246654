import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import {
  getFieldErrorValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import { HelpTextVariant } from '../baseInputs/inputTypes';
import { PhoneNumberInput } from '../baseInputs/PhoneNumberInput';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

export interface FormAwarePhoneNumberInputProps<FormShape extends FieldValues> {
  fieldName: FieldNameFromFormShape<FormShape>;
  label: string;
  control: Control<FormShape>;
  onFocus?: () => void;
  disabled?: boolean;
  validation?: RegisterOptions<FormShape>['validate'];
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  required?: boolean;
  startAdornment?: React.ReactNode;
  contextualHelp?: JSX.Element;
  testId?: string;
}

const validatePhone = (value: string) => {
  if (!value) {
    return undefined;
  }
  return isPossiblePhoneNumber(value) ? undefined : `Phone number is invalid.`;
};

export function FormAwarePhoneNumberInput<FormShape extends FieldValues>({
  fieldName,
  label,
  helpText,
  helpTextVariant,
  required,
  control,
  onFocus,
  disabled,
  validation = {},
  startAdornment,
  contextualHelp,
  testId,
}: FormAwarePhoneNumberInputProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const validations = getValidations(label, !!required, {
    ...validation,
    validatePhone,
  });

  return (
    <Controller
      control={control}
      name={fieldName}
      rules={{
        validate: validations,
      }}
      render={({ field, fieldState, formState }) => {
        return (
          <PhoneNumberInput
            testId={testId}
            label={label}
            name={field.name}
            required={required}
            onChange={field.onChange}
            onBlur={field.onBlur}
            onFocus={onFocus}
            disabled={disabledFromContext ?? disabled}
            value={field.value}
            inputRef={field.ref}
            helpText={helpText}
            helpTextVariant={helpTextVariant}
            errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
            startAdornment={startAdornment}
            contextualHelp={contextualHelp}
          />
        );
      }}
    />
  );
}
