import Decimal from 'decimal.js';

import { createValueEnum } from '@/types/definitionsUtils';
import { InterestRateSummary } from '@/types/schema';
import { formatPercent } from '@/utils/formatting/percent';

export const GRAT_TERM_ITEMS = [
  { display: '2', value: '2' },
  { display: '3', value: '3' },
  { display: '4', value: '4' },
  { display: '5', value: '5' },
  { display: '6', value: '6' },
  { display: '7', value: '7' },
  { display: '8', value: '8' },
  { display: '9', value: '9' },
  { display: '10+', value: 'TEN_OR_MORE' },
] as const;

export const GRAT_TERM_SELECT_ITEMS = [
  { display: '2 years', value: 2 },
  { display: '3 years', value: 3 },
  { display: '4 years', value: 4 },
  { display: '5 years', value: 5 },
  { display: '6 years', value: 6 },
  { display: '7 years', value: 7 },
  { display: '8 years', value: 8 },
  { display: '9 years', value: 9 },
  { display: '10 years', value: 10 },
  { display: '11 years', value: 11 },
  { display: '12 years', value: 12 },
  { display: '13 years', value: 13 },
  { display: '14 years', value: 14 },
  { display: '15 years', value: 15 },
  { display: '16 years', value: 16 },
  { display: '17 years', value: 17 },
  { display: '18 years', value: 18 },
  { display: '19 years', value: 19 },
  { display: '20 years', value: 20 },
] as const;

export const GRAT_TERM_SELECT_OPTIONS = GRAT_TERM_SELECT_ITEMS.slice(); // slice to make it non-readonly

export type GRATTerm = (typeof GRAT_TERM_ITEMS)[number]['value'];
export const GRAT_TERM_OPTIONS = GRAT_TERM_ITEMS.slice(); // slice to make it non-readonly
export const GRAT_TERM_VALUES = createValueEnum<GRATTerm>(GRAT_TERM_OPTIONS);

// GRATTerm | string is because we initialize it with an empty value. This whole
// function really shouldn't exist, but the term entry interaction is split between
// two different inputs and this is a somewhat-hacky but centralized abstraction over that.
export function getTermLengthValue(
  termLength: GRATTerm,
  termLengthExtended: string
): string {
  if (termLength === GRAT_TERM_VALUES.TEN_OR_MORE) {
    return termLengthExtended;
  }

  return termLength;
}

export function getRollingGRATFieldDescription(
  termLength: string,
  rollingPeriod: string
) {
  const rollingPeriodInt = parseInt(rollingPeriod, 10);
  const termLengthOrNaN = parseInt(termLength, 10);
  const termLengthInt = Number.isNaN(termLengthOrNaN) ? 0 : termLengthOrNaN;
  const validRollingPeriodDefined = !isNaN(rollingPeriodInt);

  if (!validRollingPeriodDefined || termLengthInt >= rollingPeriodInt) {
    return `Enter a rolling period greater than ${termLengthInt} years.`;
  }

  return (
    `A ${termLength}-year rolling GRAT with a ${rollingPeriodInt}-year ` +
    `rolling period means that you plan on creating ${
      rollingPeriodInt + 1 - termLengthInt
    } ` +
    `${termLength}-year GRATs over the next ${rollingPeriodInt} years.`
  );
}

export function getCurrentMonthVerbiage(
  currentMonthSummary: InterestRateSummary['currentMonth']
): string {
  const formattedInterestRate = formatPercent(
    currentMonthSummary.interestRate,
    1
  );
  return `Rate of ${formattedInterestRate}% as of ${currentMonthSummary.monthYearDisplay}.`;
}

export function getNextMonthSummary(
  nextMonthSummary: InterestRateSummary['nextMonth'],
  currentMonthinterestRate: Decimal
): string | null {
  if (!nextMonthSummary) return null;
  const formattedInterestRate = formatPercent(nextMonthSummary.interestRate, 1);
  if (nextMonthSummary.interestRate > currentMonthinterestRate) {
    return `Rate will increase to ${formattedInterestRate}% as of ${nextMonthSummary?.monthYearDisplay}.`;
  } else if (nextMonthSummary.interestRate < currentMonthinterestRate) {
    return `Rate will decrease to ${formattedInterestRate}% as of ${nextMonthSummary?.monthYearDisplay}.`;
  } else {
    return `Rate will remain at ${formattedInterestRate}% for ${nextMonthSummary?.monthYearDisplay}.`;
  }
}

export function getInterestRateSummaryText(summary?: InterestRateSummary) {
  if (!summary) return 'Fetching interest rate details...';
  const currentMonthVerbiage = getCurrentMonthVerbiage(summary.currentMonth);
  const nextMonthVerbiage = getNextMonthSummary(
    summary.nextMonth,
    summary.currentMonth.interestRate
  );
  return [currentMonthVerbiage, nextMonthVerbiage].filter((s) => !!s).join(' '); // `Rate will increase to 3.8% on Oct 1.`;
}
