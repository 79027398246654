import { DispositiveProvisionsTotalLineVariants } from '../../dispositiveProvisions.types';

export const copyMap: Record<
  DispositiveProvisionsTotalLineVariants,
  {
    heading: string;
    subheading?: string;
  }
> = {
  [DispositiveProvisionsTotalLineVariants.AccountsForAllAssets]: {
    heading: 'All assets are distributed',
  },
  [DispositiveProvisionsTotalLineVariants.ExceedsAssets]: {
    heading: 'Distributions exceed available assets',
    subheading:
      'Distributions will be made in the order listed until assets are exhausted',
  },
  [DispositiveProvisionsTotalLineVariants.LessThanAssets]: {
    heading: 'Does not account for all assets',
    subheading: 'Undistributed assets are assumed to remain in this entity',
  },
};
