// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { GetLifetimeExemptionDetails_ClientProfileFragmentDoc } from '../../../../../pages/designer/GiftDesigner/graphql/GetLifetimeExemptionDetails.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HypotheticalTransferModalExemption_EstateWaterfallHypotheticalTransferFragment = { __typename?: 'EstateWaterfallHypotheticalTransfer', id: string, transferValue?: CurrencyUSD | null, startYear?: number | null, endYear?: number | null, transferTaxKind: Types.EstateWaterfallHypotheticalTransferTransferTaxKind };

export type HypotheticalTransferModalExemption_ClientProfileFragment = { __typename?: 'ClientProfile', id: string, firstName: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD };

export type HypotheticalTransferModalExemption_EstateWaterfallFragment = { __typename?: 'EstateWaterfall', id: string, hypotheticalTransfers: { __typename?: 'EstateWaterfallHypotheticalTransferConnection', edges?: Array<{ __typename?: 'EstateWaterfallHypotheticalTransferEdge', node?: { __typename?: 'EstateWaterfallHypotheticalTransfer', id: string, transferValue?: CurrencyUSD | null, startYear?: number | null, endYear?: number | null, transferTaxKind: Types.EstateWaterfallHypotheticalTransferTransferTaxKind } | null } | null> | null }, household: { __typename?: 'Household', id: string, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, firstName: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD }> } };

export type HypotheticalTransferModalExemptionQueryVariables = Types.Exact<{
  estateWaterfallWhere: Types.EstateWaterfallWhereInput;
}>;


export type HypotheticalTransferModalExemptionQuery = { __typename?: 'Query', estateWaterfalls: { __typename?: 'EstateWaterfallConnection', edges?: Array<{ __typename?: 'EstateWaterfallEdge', node?: { __typename?: 'EstateWaterfall', id: string, hypotheticalTransfers: { __typename?: 'EstateWaterfallHypotheticalTransferConnection', edges?: Array<{ __typename?: 'EstateWaterfallHypotheticalTransferEdge', node?: { __typename?: 'EstateWaterfallHypotheticalTransfer', id: string, transferValue?: CurrencyUSD | null, startYear?: number | null, endYear?: number | null, transferTaxKind: Types.EstateWaterfallHypotheticalTransferTransferTaxKind } | null } | null> | null }, household: { __typename?: 'Household', id: string, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, firstName: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD }> } } | null } | null> | null } };

export const HypotheticalTransferModalExemption_EstateWaterfallHypotheticalTransferFragmentDoc = gql`
    fragment HypotheticalTransferModalExemption_EstateWaterfallHypotheticalTransfer on EstateWaterfallHypotheticalTransfer {
  id
  transferValue
  startYear
  endYear
  transferTaxKind
}
    `;
export const HypotheticalTransferModalExemption_ClientProfileFragmentDoc = gql`
    fragment HypotheticalTransferModalExemption_ClientProfile on ClientProfile {
  id
  firstName
  ...GetLifetimeExemptionDetails_ClientProfile
}
    ${GetLifetimeExemptionDetails_ClientProfileFragmentDoc}`;
export const HypotheticalTransferModalExemption_EstateWaterfallFragmentDoc = gql`
    fragment HypotheticalTransferModalExemption_EstateWaterfall on EstateWaterfall {
  id
  hypotheticalTransfers {
    edges {
      node {
        ...HypotheticalTransferModalExemption_EstateWaterfallHypotheticalTransfer
      }
    }
  }
  household {
    id
    possiblePrimaryClients {
      ...HypotheticalTransferModalExemption_ClientProfile
    }
  }
}
    ${HypotheticalTransferModalExemption_EstateWaterfallHypotheticalTransferFragmentDoc}
${HypotheticalTransferModalExemption_ClientProfileFragmentDoc}`;
export const HypotheticalTransferModalExemptionDocument = gql`
    query HypotheticalTransferModalExemption($estateWaterfallWhere: EstateWaterfallWhereInput!) {
  estateWaterfalls(where: $estateWaterfallWhere) {
    edges {
      node {
        ...HypotheticalTransferModalExemption_EstateWaterfall
      }
    }
  }
}
    ${HypotheticalTransferModalExemption_EstateWaterfallFragmentDoc}`;

/**
 * __useHypotheticalTransferModalExemptionQuery__
 *
 * To run a query within a React component, call `useHypotheticalTransferModalExemptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useHypotheticalTransferModalExemptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHypotheticalTransferModalExemptionQuery({
 *   variables: {
 *      estateWaterfallWhere: // value for 'estateWaterfallWhere'
 *   },
 * });
 */
export function useHypotheticalTransferModalExemptionQuery(baseOptions: Apollo.QueryHookOptions<HypotheticalTransferModalExemptionQuery, HypotheticalTransferModalExemptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HypotheticalTransferModalExemptionQuery, HypotheticalTransferModalExemptionQueryVariables>(HypotheticalTransferModalExemptionDocument, options);
      }
export function useHypotheticalTransferModalExemptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HypotheticalTransferModalExemptionQuery, HypotheticalTransferModalExemptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HypotheticalTransferModalExemptionQuery, HypotheticalTransferModalExemptionQueryVariables>(HypotheticalTransferModalExemptionDocument, options);
        }
export function useHypotheticalTransferModalExemptionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HypotheticalTransferModalExemptionQuery, HypotheticalTransferModalExemptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HypotheticalTransferModalExemptionQuery, HypotheticalTransferModalExemptionQueryVariables>(HypotheticalTransferModalExemptionDocument, options);
        }
export type HypotheticalTransferModalExemptionQueryHookResult = ReturnType<typeof useHypotheticalTransferModalExemptionQuery>;
export type HypotheticalTransferModalExemptionLazyQueryHookResult = ReturnType<typeof useHypotheticalTransferModalExemptionLazyQuery>;
export type HypotheticalTransferModalExemptionSuspenseQueryHookResult = ReturnType<typeof useHypotheticalTransferModalExemptionSuspenseQuery>;
export type HypotheticalTransferModalExemptionQueryResult = Apollo.QueryResult<HypotheticalTransferModalExemptionQuery, HypotheticalTransferModalExemptionQueryVariables>;
export const Operations = {
  Query: {
    HypotheticalTransferModalExemption: 'HypotheticalTransferModalExemption' as const
  },
  Fragment: {
    HypotheticalTransferModalExemption_EstateWaterfallHypotheticalTransfer: 'HypotheticalTransferModalExemption_EstateWaterfallHypotheticalTransfer' as const,
    HypotheticalTransferModalExemption_ClientProfile: 'HypotheticalTransferModalExemption_ClientProfile' as const,
    HypotheticalTransferModalExemption_EstateWaterfall: 'HypotheticalTransferModalExemption_EstateWaterfall' as const
  }
}