import { Stack } from '@mui/material';
import { useMemo } from 'react';

import { CompoundHeader } from '@/components/display/CompoundHeader/CompoundHeader';

import { useDispositiveProvisionsContext } from '../../contexts/dispositiveProvisions.context';
import { DispositionSchemeSelector } from '../../DispositionSchemeSelector/DispositionSchemeSelector';
import { DispositionScheme } from '../../dispositiveProvisions.types';
import {
  useActiveClientProfile,
  useOrderedDyingClients,
} from '../hooks/utilityHooks';

export interface InitialDispositionSchemeSelectorProps {
  onSchemeSelection: (scheme: DispositionScheme) => void;
}

export function InitialDispositionSchemeSelector({
  onSchemeSelection,
}: InitialDispositionSchemeSelectorProps) {
  const {
    client: activeClientProfile,
    isFirstToDie: activeClientProfileIsFirstToDie,
  } = useActiveClientProfile();
  const { isTwoClientHousehold, isClientProfile } =
    useDispositiveProvisionsContext();
  const [firstClientToDie] = useOrderedDyingClients();

  const subheading = useMemo(() => {
    if (isTwoClientHousehold && !isClientProfile) {
      return `If ${firstClientToDie.firstName} dies first`;
    }

    if (!isTwoClientHousehold) return undefined;

    // the last case here is that we're a two-client household looking at a client profile
    // configuration situation
    if (activeClientProfileIsFirstToDie) {
      return `If ${activeClientProfile?.firstName} dies first`;
    }

    return `If ${firstClientToDie.firstName} dies first and ${activeClientProfile?.firstName} dies second`;
  }, [
    activeClientProfile?.firstName,
    activeClientProfileIsFirstToDie,
    firstClientToDie.firstName,
    isClientProfile,
    isTwoClientHousehold,
  ]);

  return (
    <>
      <Stack spacing={2}>
        <CompoundHeader
          heading="When do dispositions occur?"
          subheading={subheading}
        />
        <DispositionSchemeSelector onChange={onSchemeSelection} />
      </Stack>
    </>
  );
}
