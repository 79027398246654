import { noop } from 'lodash';
import { createContext } from 'react';

export interface SearchContextType {
  onClose: () => void;
}

export const SearchContext = createContext<SearchContextType>({
  onClose: noop,
});
