import { Stack, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { Divider } from '@/components/Divider';
import { COLORS } from '@/styles/tokens/colors';

import { SlideContainer } from './SlideContainer';

export interface SlideBodyWithHeaderProps {
  header: ReactNode;
  rightHeaderContent?: ReactNode;
  children: ReactNode | ReactNode[];
  spacing?: number;
}
export function SlideBodyWithHeader({
  header,
  rightHeaderContent,
  children,
  spacing = 8,
}: SlideBodyWithHeaderProps) {
  const theme = useTheme();
  return (
    <SlideContainer
      sx={{
        paddingX: 11,
        paddingTop: 6,
      }}
      autoZoomOnPrint={true}
    >
      <Stack alignSelf="center" width="100%" maxWidth={2000}>
        <Stack spacing={spacing}>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Typography variant="h1" color={theme.palette.primary.main}>
                {header}
              </Typography>
              {rightHeaderContent && (
                <Typography variant="subtitle1">
                  {rightHeaderContent}
                </Typography>
              )}
            </Stack>
            <Divider
              sx={{ backgroundColor: COLORS.NEUTRAL_GRAY[200], height: '5px' }}
            />
          </Stack>
          {children}
        </Stack>
      </Stack>
    </SlideContainer>
  );
}
