import { PopperContent } from '@/components/poppers/PopperContent';

export function GSTExemptionAdjustment() {
  return (
    <PopperContent
      body={
        'This represents the additional lifetime exemption used and will increase the total amount of GST exemption used for the specified client.'
      }
    />
  );
}
