import { Stack } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useMemo } from 'react';

import { AfterDeath } from '@/types/schema';

import { CumulativeTaxTotalsCard } from './CumulativeTaxTotalsCard';
import { EstateTaxTotalsCard } from './EstateTaxTotalsCard';
import { GiftTaxTotalsCard } from './GiftTaxTotalsCard';
import { EstateTaxSummaryPanel_TaxSummaryFragment } from './graphql/EstateTaxSummaryPanel.generated';
import { useSummaryPanelQuery } from './hooks/useSummaryPanelQuery';

interface EstateTaxSummaryPanelTaxesProps {
  summaryPanelData: ReturnType<typeof useSummaryPanelQuery>['secondDeathData'];
  afterDeath: AfterDeath;
  taxSummary: Maybe<EstateTaxSummaryPanel_TaxSummaryFragment>;
}

export function EstateTaxSummaryPanelTaxes({
  summaryPanelData,
  afterDeath,
  taxSummary,
}: EstateTaxSummaryPanelTaxesProps) {
  const cumulativeTaxMetrics = useMemo(() => {
    return {
      afterDeath,
      taxBeforeFirstDeath:
        summaryPanelData.cumulativeTaxMetrics.taxBeforeFirstDeath,
      taxAfterFirstDeath:
        summaryPanelData.cumulativeTaxMetrics.taxAfterFirstDeath,
      taxAfterSecondDeath:
        summaryPanelData.cumulativeTaxMetrics.taxAfterSecondDeath,
    };
  }, [
    afterDeath,
    summaryPanelData.cumulativeTaxMetrics.taxAfterFirstDeath,
    summaryPanelData.cumulativeTaxMetrics.taxAfterSecondDeath,
    summaryPanelData.cumulativeTaxMetrics.taxBeforeFirstDeath,
  ]);

  return (
    <Stack spacing={3}>
      {afterDeath !== AfterDeath.None && (
        <EstateTaxTotalsCard taxSummary={taxSummary} />
      )}
      {afterDeath === AfterDeath.None && (
        <GiftTaxTotalsCard
          nodes={summaryPanelData.giftTaxNodes}
          lifetimeExemptionAppliedTowardsGiftTax={
            summaryPanelData.lifetimeExemptionAppliedTowardsGiftTax
          }
        />
      )}
      <CumulativeTaxTotalsCard {...cumulativeTaxMetrics} />
    </Stack>
  );
}
