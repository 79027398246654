import Decimal from 'decimal.js';

import { CurrencyUSD, Percent } from '@/graphql/scalars';
import { Asset } from '@/modules/assets/types/asset';
import {
  NAMESPACE as TERMS_NAMESPACE,
  RollingType,
} from '@/modules/entities/gratTrusts/TermsSubform/types';
import { GRATTerm } from '@/modules/entities/gratTrusts/TermsSubform/ui/utils';

import { REMAINDER_TYPE_VALUES, RemainderType } from './AnnuityForm/constants';

export enum ReturnProjectionType {
  RATE_OF_RETURN = 'RATE_OF_RETURN',
  PROJECTED_VALUE = 'PROJECTED_VALUE',
}

export type TabDisplayText = 'Assets' | 'Terms' | 'Annuity';

export const TAB_NAMES = {
  ASSETS: 'assets',
  TERMS: TERMS_NAMESPACE,
  ANNUITY: 'annuity',
} as const;
export type TabName = (typeof TAB_NAMES)[keyof typeof TAB_NAMES];

export const tabConfigurations: {
  display: TabDisplayText;
  value: TabName;
}[] = [
  {
    display: 'Assets',
    value: TAB_NAMES.ASSETS,
  },
  {
    display: 'Terms',
    value: TAB_NAMES.TERMS,
  },
  {
    display: 'Annuity',
    value: TAB_NAMES.ANNUITY,
  },
];
export interface GRATStructuringForm {
  [TAB_NAMES.ASSETS]: {
    assets: Asset[];
    returnProjectionType: ReturnProjectionType | null;
    projectedRateOfReturn?: Percent | null;
    // projectedMarketValue and projectedSharePrice will only be available if there's exactly
    // one asset in the list. otherwise,
    projectedMarketValue?: CurrencyUSD | null;
    // projected share price will only be populated if there's exactly one asset *and* that asset has a
    // share-based valuation model
    projectedSharePrice?: CurrencyUSD | null;
  };
  [TAB_NAMES.TERMS]: {
    type: RollingType | null;
    termLength: GRATTerm;
    // if the user chooses a GRAT of 10+ years, we use this field.
    termLengthExtended: string;
    rollingPeriod: string;
    rate7520: Decimal | null;
  };
  [TAB_NAMES.ANNUITY]: {
    remainderType: RemainderType;
    growthType: string;
    grantorRetainedInterest: Decimal | null;
    taxableGiftAmount: Decimal | null;
    calculatedTaxableGiftAmount: Decimal | null;
    annuityAnnualIncreasePercent: Decimal | null;
  };
}

export const initialValues: GRATStructuringForm = {
  [TAB_NAMES.ASSETS]: {
    assets: [],
    returnProjectionType: null,
    projectedRateOfReturn: null,
    projectedMarketValue: null,
    projectedSharePrice: null,
  },
  [TAB_NAMES.TERMS]: {
    type: null,
    termLength: '' as GRATTerm,
    termLengthExtended: '',
    rollingPeriod: '',
    rate7520: null,
  },
  [TAB_NAMES.ANNUITY]: {
    remainderType: REMAINDER_TYPE_VALUES.TAXABLE_GIFT,
    growthType: '',
    grantorRetainedInterest: null,
    taxableGiftAmount: null,
    calculatedTaxableGiftAmount: null,
    annuityAnnualIncreasePercent: null,
  },
};

export interface FormSectionProps<FormSection> {
  values: FormSection;
}
