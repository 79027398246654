import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { HouseholdOnboardingStepKind } from '@/types/schema';

export type HouseholdOnboardingActionMap = Record<
  HouseholdOnboardingStepKind,
  () => void
>;

export interface HouseholdOnboardingSidedbarActionContextType {
  useActionForKey: (stepKind: HouseholdOnboardingStepKind) => () => void;
}

export const HouseholdOnboardingSidebarActionContext =
  createContext<HouseholdOnboardingSidedbarActionContextType>({
    useActionForKey: () => () => {
      return;
    },
  });

export function useHouseholdOnboardingSidebarActionContext() {
  return useGuardedContext(
    HouseholdOnboardingSidebarActionContext,
    'HouseholdOnboardingSidebarActionContextProvider'
  );
}
