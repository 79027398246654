import { Box, Stack } from '@mui/material';

import { UsersXIcon } from '@/components/icons/UsersXIcon';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { COLORS } from '@/styles/tokens/colors';

export function NoBeneficiaryLeaf() {
  return (
    <Box bgcolor={COLORS.GRAY[100]} padding={2} borderRadius={1.5}>
      <Stack spacing={0.5}>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={1}
          color={COLORS.GRAY[400]}
        >
          <UsersXIcon size={32} />
        </Stack>
        <TypographyClamped color={COLORS.GRAY[500]} variant="h5" lines={2}>
          Beneficiaries not yet specified
        </TypographyClamped>
      </Stack>
    </Box>
  );
}
