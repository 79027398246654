import * as Apollo from '@apollo/client';

import {
  EntityPresentationDetailsQuery,
  EntityPresentationDetailsQueryVariables,
  useEntityPresentationDetailsQuery,
} from '../graphql/EntityPresentation.generated';
import { getGuardedEntityDetails } from './useEntityPresentationDetails.utils';
/**
 * @description This hook fetches the data needed to render the presentation for a single entity type.
 */
export function useEntityPresentationDetails(
  opts: Apollo.QueryHookOptions<
    EntityPresentationDetailsQuery,
    EntityPresentationDetailsQueryVariables
  >
) {
  const queryProps = useEntityPresentationDetailsQuery({
    onError: (error) => {
      // we throw because there's an error boundary above this
      throw error;
    },
    ...opts,
  });

  const entity = (() => {
    if (queryProps.data?.node?.__typename !== 'Entity') return null;
    return queryProps.data.node;
  })();

  const legalDisclaimers =
    queryProps.data?.tenantInformation?.disclosuresConfiguration ?? null;

  return {
    legalDisclaimers,
    ...getGuardedEntityDetails(entity),
    ...queryProps,
  };
}
