import { Box, Stack, Typography } from '@mui/material';
import { Path, useWatch } from 'react-hook-form';

import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';
import { COLORS } from '@/styles/tokens/colors';
import { AccessParameterKind } from '@/types/schema';

import { BeneficiaryFormAccessParameter } from './EntityBeneficiariesSubform.types';

type AccessParameterAgeRequirementFormPaths =
  Path<BeneficiaryFormAccessParameter>;

interface AccessParameterAgeRequirementFormProps {
  variant: 'single' | 'multiple';
  fieldName:
    | `values.${AccessParameterKind}` // The accessParametersOther.values object (HEMS, etc.)
    | `accessParametersPartial.${number}`; // The accessParametersPartial array (Percent and amount)
}

export function AccessParameterAgeRequirementForm({
  variant,
  fieldName,
}: AccessParameterAgeRequirementFormProps) {
  const { control } = useFormContext<BeneficiaryFormAccessParameter>();

  const hasAgeRequirementToggled = useWatch({
    name: `${fieldName}._hasAgeParams` as '_hasAgeParams' satisfies AccessParameterAgeRequirementFormPaths,
    control,
  });

  const ageParams = useWatch({
    name: `${fieldName}.ageParams` as 'ageParams' satisfies AccessParameterAgeRequirementFormPaths,
    control,
  });

  return (
    <Card
      variant="filled-dark"
      sx={{
        p: variant === 'single' ? undefined : 2,
        borderRadius: '6px',
        bgcolor: variant === 'single' ? 'transparent' : COLORS.GRAY[200],
      }}
    >
      <FormAwareSwitch<BeneficiaryFormAccessParameter>
        variant={variant === 'single' ? undefined : 'dark'}
        label="Age requirement"
        labelPosition="right"
        fieldName={
          `${fieldName}._hasAgeParams` as '_hasAgeParams' satisfies AccessParameterAgeRequirementFormPaths
        }
        control={control}
      />
      {hasAgeRequirementToggled && (
        <FormAwareInputRepeater<BeneficiaryFormAccessParameter>
          name={`${fieldName}.ageParams`}
          emptyValue={{
            _ageRequirementKind: 'upon' as const,
          }}
          control={control}
          shouldInit
          isSingleLineRepeatedInput={false}
          addAnotherItemText="Add additional age requirement"
          actionRowSx={{
            pl: 7,
          }}
          maxItems={variant === 'single' ? 1 : undefined}
          render={(idx) => {
            const ageParam = ageParams?.[idx];

            const selectedAgeParamKind = ageParam?._ageRequirementKind;

            return (
              <Card
                variant="filled"
                sx={{
                  p: variant === 'single' ? 0 : 2,
                  ml: 7, // Align with label
                  borderRadius: '6px',
                  bgcolor:
                    variant === 'single' ? 'transparent' : COLORS.GRAY[300],
                }}
              >
                <Stack spacing={2}>
                  <Stack spacing={1} direction="row" alignItems="center">
                    <Stack minWidth="120px">
                      <FormAwareSelectInput<BeneficiaryFormAccessParameter>
                        label="Age requirement"
                        hideLabel
                        fieldName={
                          `${fieldName}.ageParams.${idx}._ageRequirementKind` as `ageParams.${number}._ageRequirementKind` satisfies AccessParameterAgeRequirementFormPaths
                        }
                        control={control}
                        options={[
                          { value: 'upon', display: 'Upon' },
                          { value: 'until', display: 'Until' },
                          { value: 'between', display: 'Between' },
                        ]}
                      />
                    </Stack>
                    <Typography variant="label" width="30px">
                      {selectedAgeParamKind === 'between' ? 'ages' : 'age'}
                    </Typography>
                    {(selectedAgeParamKind === 'upon' ||
                      selectedAgeParamKind === 'between') && (
                      <Box width="80px">
                        <FormAwareFormattedNumberInput<BeneficiaryFormAccessParameter>
                          control={control}
                          label="Age"
                          hideLabel
                          fieldName={
                            `${fieldName}.ageParams.${idx}.ageRequirementStart` as `ageParams.${number}.ageRequirementStart` satisfies AccessParameterAgeRequirementFormPaths
                          }
                          fixedDecimalScale
                          decimalScale={0}
                        />
                      </Box>
                    )}
                    {selectedAgeParamKind === 'between' && (
                      <Typography variant="label">to</Typography>
                    )}
                    {(selectedAgeParamKind === 'until' ||
                      selectedAgeParamKind === 'between') && (
                      <Box width="80px">
                        <FormAwareFormattedNumberInput<BeneficiaryFormAccessParameter>
                          control={control}
                          label="Age"
                          hideLabel
                          fieldName={
                            `${fieldName}.ageParams.${idx}.ageRequirementEnd` as `ageParams.${number}.ageRequirementEnd` satisfies AccessParameterAgeRequirementFormPaths
                          }
                          fixedDecimalScale
                          decimalScale={0}
                        />
                      </Box>
                    )}
                  </Stack>
                </Stack>
              </Card>
            );
          }}
        />
      )}
    </Card>
  );
}
