import Decimal from 'decimal.js';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useCallback } from 'react';

import { STATE_CODE_TO_NAME } from '@/constants/states';
import { useEstateWaterfallSummaryBarChartsSectionColors } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useEstateWaterfallSummaryBarChartsSectionColors.hook';
import { EstateWaterfallSummaryBarChartsSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/types';
import { GetWaterfallSummary_TaxSummaryFragment } from '@/modules/estateWaterfall/graphql/GetWaterfallSummary.generated';
import { AfterDeath, StateCode } from '@/types/schema';

export function useWaterfallSummaryTableTaxRows() {
  const colorChoices = useEstateWaterfallSummaryBarChartsSectionColors();

  const makeTaxRowItem = useCallback(
    ({
      afterDeath,
      displayName,
      value,
      projectedValue,
    }: {
      afterDeath: AfterDeath;
      displayName: string;
      value: Decimal;
      projectedValue: Decimal;
    }) => {
      const negatedTax = value.negated();
      const negatedProjectedTax = projectedValue.negated();

      return {
        kind: 'tax' as const,
        afterDeath,
        id: `${afterDeath}-tax-${displayName}`,
        sectionIndicator: {
          backgroundColor:
            colorChoices[
              EstateWaterfallSummaryBarChartsSections.GiftAndEstateTax
            ],
        },
        entity: {
          lineOne: displayName,
          lineTwo: '',
        },
        value: negatedTax,
        projectedValue: negatedProjectedTax,
      };
    },
    [colorChoices]
  );

  const makeTaxRows = useCallback(
    ({
      taxSummary,
      taxSummaryProjection,
      afterDeath,
      stateCodesFromClientAddress,
      availableStateEstateTaxes,
    }: {
      taxSummary: Maybe<GetWaterfallSummary_TaxSummaryFragment>;
      taxSummaryProjection: Maybe<GetWaterfallSummary_TaxSummaryFragment>;
      afterDeath: AfterDeath;
      stateCodesFromClientAddress: StateCode[];
      availableStateEstateTaxes: StateCode[] | null;
    }) => {
      switch (afterDeath) {
        case AfterDeath.None:
          return [
            makeTaxRowItem({
              afterDeath,
              displayName: 'Federal gift tax',
              value: taxSummary?.federalTax?.tax ?? new Decimal(0),
              projectedValue:
                taxSummaryProjection?.federalTax?.tax ?? new Decimal(0),
            }),
          ];
        case AfterDeath.First:
        case AfterDeath.Second: {
          const stateTaxRows =
            stateCodesFromClientAddress.flatMap((state) => {
              if (
                !availableStateEstateTaxes ||
                !availableStateEstateTaxes.includes(state)
              ) {
                return [];
              }

              const value =
                taxSummary?.stateTax?.find((t) => t.stateCode === state)?.tax ??
                new Decimal(0);
              const projectedValue =
                taxSummaryProjection?.stateTax?.find(
                  (p) => p.stateCode === state
                )?.tax ?? new Decimal(0);

              return makeTaxRowItem({
                afterDeath,
                displayName: `${
                  STATE_CODE_TO_NAME[state ?? ''] ?? state
                } estate tax`,
                value,
                projectedValue,
              });
            }) ?? [];

          return [
            ...stateTaxRows,
            makeTaxRowItem({
              afterDeath,
              displayName: 'Federal estate tax',
              value: taxSummary?.federalTax?.tax ?? new Decimal(0),
              projectedValue:
                taxSummaryProjection?.federalTax?.tax ?? new Decimal(0),
            }),
          ];
        }
      }
    },
    [makeTaxRowItem]
  );

  return makeTaxRows;
}
