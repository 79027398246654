import { AssetClassFragment } from '../tenant/graphql/TenantInformation.generated';

export function getAssetCategoryDisplay(
  asset: { categoryId?: string | undefined },
  assetClassesById: Record<string, AssetClassFragment>
) {
  if (!asset.categoryId) {
    return 'Unknown';
  }
  return assetClassesById[asset.categoryId]?.displayName ?? 'Unknown';
}
