import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useModalState } from '@/hooks/useModalState';
import { HypotheticalTransferModal } from '@/modules/entities/details/HypotheticalTransferModal/HypotheticalTransferModal';
import { EstateWaterfallHypotheticalSaleLoanModal } from '@/modules/estateWaterfall/components/EstateWaterfallHypotheticalSaleLoanModal/EstateWaterfallHypotheticalSaleLoanModal';
import { HypotheticalSaleLoanFormKind } from '@/modules/estateWaterfall/components/EstateWaterfallHypotheticalSaleLoanModal/EstateWaterfallHypotheticalSaleLoanModal.types';
import { UnreachableError } from '@/utils/errors';

import {
  CreateHypotheticalEventModal,
  HypotheticalEventType,
} from './CreateHypotheticalEventModal';

interface CreateHypotheticalEventButtonProps extends Partial<ButtonProps> {
  waterfallId: string;
  householdId: string;
}

export function CreateHypotheticalEventButton({
  waterfallId,
  householdId,
  ...buttonProps
}: CreateHypotheticalEventButtonProps) {
  const [
    { isModalOpen: isTransferModalOpen },
    { openModal: openTransferModal, closeModal: closeTransferModal },
  ] = useModalState<string>();
  const [
    { isModalOpen: isSaleLoanModalOpen, data: saleLoanKind },
    { openModal: openSaleLoanModal, closeModal: closeSaleLoanModal },
  ] = useModalState<HypotheticalSaleLoanFormKind>();
  const [
    { isModalOpen: isEventSelectionDialogOpen },
    {
      openModal: openEventSelectionDialog,
      closeModal: closeEventSelectionDialog,
    },
  ] = useModalState();

  const handleEventSelection = (eventType: HypotheticalEventType | null) => {
    closeEventSelectionDialog();

    switch (eventType) {
      case null:
        // user just closed the dialog without selecting an option; do nothing
        break;
      case 'GIFT_TRANSFER':
        openTransferModal();
        break;
      case 'INSTALLMENT_SALE':
        openSaleLoanModal('sale');
        break;
      case 'INTRA_FAMILY_LOAN':
        openSaleLoanModal('loan');
        break;
      default:
        throw new UnreachableError({
          case: eventType,
          message: `Unhandled hypothetical event type: ${eventType}`,
        });
    }
  };

  return (
    <>
      {isTransferModalOpen && (
        <HypotheticalTransferModal
          waterfallId={waterfallId}
          householdId={householdId}
          isOpen={isTransferModalOpen}
          onClose={closeTransferModal}
          transferId={undefined}
        />
      )}
      {isSaleLoanModalOpen && saleLoanKind && (
        <EstateWaterfallHypotheticalSaleLoanModal
          waterfallId={waterfallId}
          householdId={householdId}
          isOpen={isSaleLoanModalOpen}
          onClose={closeSaleLoanModal}
          saleLoanId={null}
          initialKind={saleLoanKind ?? 'sale'}
        />
      )}
      {isEventSelectionDialogOpen && (
        <CreateHypotheticalEventModal
          isOpen={isEventSelectionDialogOpen}
          onClose={handleEventSelection}
        />
      )}
      {/* TODO: Add the sale loan modal here */}
      <Button
        size="lg"
        variant="primary"
        onClick={openEventSelectionDialog}
        startIcon={PlusIcon}
        {...buttonProps}
      >
        Add hypothetical transfer
      </Button>
    </>
  );
}
