import { useReportError } from '@/hooks/useReportError';
import { getNodes } from '@/utils/graphqlUtils';

import { useHouseholdTaxForWaterfallQuery as useHouseholdTaxForWaterfallQueryExternal } from '../graphql/HouseholdTaxForWaterfall.generated';

interface useClientDetailsOverviewPageQueryInput {
  householdId: string;
}

export function useHouseholdTaxForWaterfallQuery({
  householdId,
}: useClientDetailsOverviewPageQueryInput) {
  const { reportError } = useReportError();

  const queryProps = useHouseholdTaxForWaterfallQueryExternal({
    variables: {
      where: {
        hasHouseholdWith: [
          {
            id: householdId,
          },
        ],
      },
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    onError: (error) => {
      reportError(
        'failed to load data for household tax for waterfall module',
        error,
        {
          householdId,
        }
      );
    },
  });

  const waterfalls = getNodes(queryProps.data?.estateWaterfalls);

  return { waterfalls, ...queryProps };
}
