import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

import { DataTable_LEGACY } from '@/components/tables/DataTable_LEGACY/DataTable_LEGACY';
import { Column } from '@/components/tables/DataTable_LEGACY/tableTypes';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { NUMBER_PREVIEW_ASSETS } from '@/modules/assetProviderIntegrations/shared/constants';
import { ExternalIntegrationButton } from '@/modules/assetProviderIntegrations/shared/ExternalIntegrationButton';
import { AssetIntegration_IntegrationEntityFragment } from '@/modules/assetProviderIntegrations/shared/graphql/assetProviderIntegration.fragments.generated';
import { AssetV2Fragment } from '@/modules/assets/graphql/Asset.generated';
import { getAssetCellDefinition } from '@/modules/assets/utils';
import { SharePriceContent } from '@/modules/content/tooltipContent/SharePriceContent';
import { COLORS } from '@/styles/tokens/colors';
import { AssetValueV2OwnershipType } from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';
import { commaDecimal } from '@/utils/formatting/numbers';

export enum AssetValuationTableColumns {
  DISPLAY_NAME = 'displayName',
  SHARE_COUNT = 'shareCount',
  SHARE_VALUE = 'shareValue',
  TOTAL_MARKET_VALUE = 'totalMarketValue',
}

export type AssetV2Extended = AssetV2Fragment & {
  accountName?: string;
  displayCategory: string;
};

const columns: Readonly<Column[]> = [
  {
    field: AssetValuationTableColumns.DISPLAY_NAME,
    headerName: 'Asset',
    flex: 1,
    sortable: false,
  },
  {
    field: AssetValuationTableColumns.SHARE_COUNT,
    headerName: 'Share count',
    width: 110,
    align: 'right',
    sortable: false,
  },
  {
    field: AssetValuationTableColumns.SHARE_VALUE,
    headerName: 'Share price',
    width: 130,
    align: 'right',
    sortable: false,
    contextualHelp: <SharePriceContent />,
  },
  {
    field: AssetValuationTableColumns.TOTAL_MARKET_VALUE,
    headerName: 'Total market value',
    width: 140,
    sortable: false,
    align: 'right',
  },
];

const getRows = (
  allAssets: AssetV2Extended[],
  opts = { hideValues: false, isIntegratedValuation: false }
) => {
  const assets = opts.isIntegratedValuation
    ? allAssets.slice(0, NUMBER_PREVIEW_ASSETS)
    : allAssets;

  const rows = (() => {
    if (opts.hideValues) {
      return assets.map((asset) => ({
        id: asset.id,
        [AssetValuationTableColumns.DISPLAY_NAME]: getAssetCellDefinition({
          displayName: asset.displayName ?? '',
          displayCategory: asset.displayCategory,
          accountName: asset.accountName,
        }),
        [AssetValuationTableColumns.SHARE_COUNT]: EMPTY_CONTENT_HYPHEN,
        [AssetValuationTableColumns.SHARE_VALUE]: EMPTY_CONTENT_HYPHEN,
        [AssetValuationTableColumns.TOTAL_MARKET_VALUE]: EMPTY_CONTENT_HYPHEN,
      }));
    }

    return assets.map((asset, i) => ({
      id: asset.id || i,
      [AssetValuationTableColumns.DISPLAY_NAME]: getAssetCellDefinition({
        displayName: asset.displayName ?? 'Unknown',
        displayCategory: asset.displayCategory,
        accountName: asset.accountName,
      }),
      [AssetValuationTableColumns.SHARE_COUNT]:
        asset.assetValue.ownershipType ===
          AssetValueV2OwnershipType.ShareBased && asset.assetValue.shareCount
          ? commaDecimal(asset.assetValue.shareCount ?? new Decimal(0))
          : '',
      [AssetValuationTableColumns.SHARE_VALUE]:
        (asset.assetValue.ownershipType ===
          AssetValueV2OwnershipType.ShareBased &&
          formatCurrency(asset.assetValue.shareValue ?? new Decimal(0))) ??
        '',
      [AssetValuationTableColumns.TOTAL_MARKET_VALUE]: formatCurrency(
        asset.assetValue.value
      ),
    }));
  })();

  return { rows, totalCount: allAssets.length };
};

type RowType = ReturnType<typeof getRows>['rows'][number];

export type AssetValuationDisplayTableVariant = 'full' | 'compact';

export interface AssetValuationDisplayTableProps {
  integrationEntities?: AssetIntegration_IntegrationEntityFragment[];
  assets: AssetV2Extended[];
  variant?: AssetValuationDisplayTableVariant;
  hideValues?: boolean;
  shouldShowSumRow?: boolean;
  printOnly?: boolean;
}

export default function AssetValuationDisplayTable({
  assets,
  hideValues,
  shouldShowSumRow,
  variant,
  integrationEntities,
  printOnly,
}: AssetValuationDisplayTableProps) {
  const isIntegratedValuation = !isEmpty(integrationEntities);
  const { rows, totalCount } = getRows(assets, {
    hideValues: Boolean(hideValues),
    isIntegratedValuation,
  });
  const sum =
    assets.reduce((acc, asset) => {
      return acc.plus(asset.assetValue.value);
    }, new Decimal(0)) ?? new Decimal(0);

  const renderedColumns = useMemo(() => {
    return columns.filter((colDef) => {
      if (variant === 'compact') {
        const field = colDef.field as AssetValuationTableColumns;
        // we only want to show asset name and total market value in this scenario
        if (
          field === AssetValuationTableColumns.SHARE_COUNT ||
          field === AssetValuationTableColumns.SHARE_VALUE
        ) {
          return false;
        }
      }

      return colDef;
    });
  }, [variant]);

  return (
    <Box>
      <DataTable_LEGACY
        rowHoverVariant="transparent"
        sx={{
          '& .MuiDataGrid-row': {
            backgroundColor: 'transparent',
          },
        }}
        rows={rows}
        columns={renderedColumns}
        variant="long"
        hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS
        disableVirtualization={printOnly}
      />
      {shouldShowSumRow && variant === 'full' && (
        <>
          <Box
            sx={{ borderBottom: `solid ${COLORS.NAVY[600]} 2px` }}
            pb={0.5}
          />
          <Stack flexDirection="row" justifyContent="space-between" my={2}>
            <Typography variant="h5">Total value</Typography>
            <Typography variant="h4">{formatCurrency(sum)}</Typography>
          </Stack>
        </>
      )}
      <IntegratedAssetValuationDisplayTableFooter
        integrationEntities={integrationEntities}
        printOnly={printOnly}
        rows={rows}
        totalRowCount={totalCount}
      />
    </Box>
  );
}

interface IntegratedAssetValuationDisplayTableFooterProps {
  totalRowCount: number;
  integrationEntities?: AssetIntegration_IntegrationEntityFragment[];
  printOnly?: boolean;
  rows: RowType[];
}

function IntegratedAssetValuationDisplayTableFooter({
  integrationEntities,
  rows,
  printOnly,
  totalRowCount,
}: IntegratedAssetValuationDisplayTableFooterProps) {
  if (isEmpty(integrationEntities) || totalRowCount <= rows.length) {
    return null;
  }

  return (
    <Box mt={1}>
      {/* if we can, we want to link users out to their addepar instance to explore the entity more */}
      {!isEmpty(integrationEntities) && !printOnly ? (
        <Stack spacing={0.5}>
          {integrationEntities?.map((integrationEntity) => (
            <ExternalIntegrationButton
              key={integrationEntity.id}
              integrationEntity={integrationEntity}
            />
          ))}
        </Stack>
      ) : (
        <Typography
          component="strong"
          display="block"
          textAlign="center"
          variant="body1"
        >
          ...and {totalRowCount - rows.length} other assets
        </Typography>
      )}
    </Box>
  );
}
