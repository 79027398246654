import { compact } from 'lodash';

import {
  CreateClientProfileWithDetailsInput,
  CreateHouseholdInput,
  HouseholdBillableKind,
} from '@/types/schema';

import { CreateHouseholdFormShape } from './types';

export function mapFormToCreateHouseholdShape(
  values: CreateHouseholdFormShape,
  householdID: string | null
): CreateHouseholdInput {
  return {
    primaryRelationshipOwnerID: values.primaryRelationshipOwnerId,
    integrationClientIDs: compact(values.integrationClientIds),
    billableKind: values.isProspect ? HouseholdBillableKind.Prospect : null,
    subBrandID: values.subBrandId || null,
    profiles: values.profiles.map(
      (formProfile): CreateClientProfileWithDetailsInput => {
        const { isGrantor, address, ...rest } = formProfile;
        return {
          clientProfile: {
            householdID: householdID || undefined,
            isPrimary: isGrantor,
            isGrantor,
            address: {
              cityName: address.cityName || undefined,
              stateCode: address.stateCode,
            },
            ...rest,
          },
          relationships: [],
        };
      }
    ),
  };
}
