import Decimal from 'decimal.js';

import {
  DispositiveProvisionDispositionKind,
  DispositiveProvisionTransferTaxKind,
} from '@/types/schema';

import { Recipient } from '../DispositiveProvisionsForm/DispositiveProvisionsForm.types';

export const BLANK_POUROVER_RECIPIENT: Recipient = {
  recipientId: '', // blank string instead of null to avoid MUI warning
  recipientKind: null,
  dispositionKind_ButtonGroup: DispositiveProvisionDispositionKind.Percentage,
  dispositionKind_Select: '',
  dispositionAmount: null,
  dispositionPercentage: new Decimal(100),
  notes: '',
  transferTaxKind: DispositiveProvisionTransferTaxKind.Pourover,
  _dispositiveProvisionId: null,
};

export const BLANK_DISCLAIMER_TRUST: Recipient = {
  recipientId: '', // blank string instead of null to avoid MUI warning
  recipientKind: null,
  dispositionAmount: null,
  dispositionPercentage: null,
  notes: '',
  _dispositiveProvisionId: null,
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseCreditShelter,
  dispositionKind_ButtonGroup: 'other',
  dispositionKind_Select:
    DispositiveProvisionDispositionKind.RemainingLifetimeExclusionOfGrantor,
};
