import { forEach } from 'lodash';
import { useWatch } from 'react-hook-form';

import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { useFormContext } from '@/components/react-hook-form';
import { SearchSelectionToolbar } from '@/components/tables/DataTable/components/SearchSelectionToolbar';
import { pluralize } from '@/utils/formatting/strings';

import { CSVUploadFormShape } from '../ImportCSVValuationsPage/ImportCSVValuationsPage.types';

function getCountOfSyncedRows(
  accountIdsByHousehold: CSVUploadFormShape['accountIdsByHousehold']
) {
  let count = 0;
  forEach(accountIdsByHousehold, ({ entityIdToAccountIds }) => {
    // get the number of keys in the entityIdsToAccountIds map that are not empty
    count += Object.keys(entityIdToAccountIds).filter(
      (entityId) => entityIdToAccountIds[entityId]
    ).length;
  });
  return count;
}

export function ClientSearchSelectionToolbar() {
  const { control } = useFormContext<CSVUploadFormShape>();
  const formValues = useWatch({
    name: 'accountIdsByHousehold',
    control,
  });
  const countOfSyncedRows = getCountOfSyncedRows(formValues);
  return (
    <SearchSelectionToolbar
      label="Search for client"
      details={
        <Badge
          variant={BadgeVariants.Yellow}
          display={`${countOfSyncedRows} ${pluralize(
            countOfSyncedRows,
            'valuation'
          )} will be updated`}
        />
      }
    />
  );
}
