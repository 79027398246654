import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { getNodes } from '@/utils/graphqlUtils';

import { useAssetClassesQuery } from '../../../graphql/AdminAssetCategoriesConfigurationPage.generated';

export function useAssetCategoryOptions(assetClassToDeleteId: string) {
  const { createErrorFeedback } = useFeedback();
  const { data, ...queryProps } = useAssetClassesQuery({
    variables: {
      first: 100, // required variable, but we just don't want to limit
      where: {
        idNEQ: assetClassToDeleteId,
      },
    },
    onError: createErrorFeedback('Failed to fetch asset categories'),
  });

  const options = getNodes(data?.paginatedData).map((ac) => ({
    display: ac.displayName,
    value: ac.id,
  }));
  return { options, data, ...queryProps };
}
