import { Stack } from '@mui/material';
import Decimal from 'decimal.js';

import { WealthTransferredCallout } from '@/modules/content/tooltipContent/WealthTransferredCallout';

import { ClientValuePerformanceDashboard_HouseholdFragment } from './graphql/ClientValuePerformanceDashboard.generated';
import { ImpliedEstateTaxSavingsCallout } from './ImpliedEstateTaxSavingsCallout';

export interface ClientValuePerformanceDashboardSummaryProps {
  familyGivingWealthTransferred: Decimal;
  charityWealthTransferred: Decimal;
  clientPerformanceReport:
    | ClientValuePerformanceDashboard_HouseholdFragment['clientPerformanceReport']
    | undefined;
}
export function ClientValuePerformanceDashboardSummary({
  familyGivingWealthTransferred,
  charityWealthTransferred,
  clientPerformanceReport,
}: ClientValuePerformanceDashboardSummaryProps) {
  return (
    <Stack direction="row" sx={{ pb: 2, gap: 2 }}>
      <WealthTransferredCallout
        amountToFamily={familyGivingWealthTransferred}
        amountToCharity={charityWealthTransferred}
        totalWealthTransferred={
          clientPerformanceReport?.totalWealthTransferred || new Decimal(0)
        }
      />
      <ImpliedEstateTaxSavingsCallout
        impliedEstateTaxSavings={
          clientPerformanceReport?.impliedEstateTaxSavings || new Decimal(0)
        }
      />
    </Stack>
  );
}
