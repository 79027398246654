import { compact, first } from 'lodash';
import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { InputOption } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { getNodes } from '@/utils/graphqlUtils';

import { CashflowModalFormShape } from '../CashflowModal.types';
import {
  HouseholdCashflowEntityOptionsQueryHookResult,
  useHouseholdCashflowEntityOptionsQuery,
} from '../graphql/HouseholdCashflowEntityOptions.generated';

export function useEntityTargets(
  householdId: string | null
): [InputOption[], HouseholdCashflowEntityOptionsQueryHookResult] {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const queryData = useHouseholdCashflowEntityOptionsQuery({
    variables: {
      where: { id: householdId },
    },
    onError: (error) => {
      showFeedback('Could not fetch transfer targets.');
      reportError('COuld not fetch transfer targets', error);
    },
    skip: !householdId,
  });

  const household = first(getNodes(queryData.data?.households));

  const options = useMemo<InputOption[]>(() => {
    if (!household) {
      return [];
    }
    const { entities, possiblePrimaryClients = [] } = household || {};

    const entityOptions = getNodes(entities).map<
      SelectInputOption<CashflowModalFormShape['targetId']>
    >(({ id, subtype }) => ({
      value: id,
      display: subtype?.displayName,
    }));

    const clientOptions = possiblePrimaryClients.map<
      SelectInputOption<CashflowModalFormShape['targetId']>
    >(({ id, displayName }) => ({ value: id, display: displayName }));
    return compact([
      clientOptions.length
        ? {
            type: 'component' as const,
            component: <SelectItemGroupLabel label="Clients" />,
          }
        : null,
      ...clientOptions,
      entityOptions.length
        ? {
            type: 'component' as const,
            component: <SelectItemGroupLabel label="Entities" />,
          }
        : null,
      ...entityOptions,
    ]);
  }, [household]);

  return [options, queryData];
}
