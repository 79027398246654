import { MinusIcon } from '@/components/icons/MinusIcon';

import { useZoom } from '../../hooks/useZoom';
import {
  CONTROL_BUTTON_ICON_SIZE,
  ControlButton,
  ControlButtonProps,
} from './ControlButton';

export type ZoomOutButtonProps = ControlButtonProps;

export const ZoomOutButton = ({
  onClick,
  children,
  ...props
}: ZoomOutButtonProps) => {
  const { zoomOut, canZoomOut } = useZoom();
  const handleZoomOut = () => zoomOut();

  return (
    <ControlButton
      variant="secondary-filled"
      onClick={onClick ?? handleZoomOut}
      title="zoom out"
      aria-label="zoom out"
      disabled={!canZoomOut}
      {...props}
    >
      {children ?? <MinusIcon size={CONTROL_BUTTON_ICON_SIZE} />}
    </ControlButton>
  );
};
