import { NetworkStatus } from '@apollo/client';

import { Loader } from '@/components/progress/Loader/Loader';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { GiftLifetimeExemption } from '@/modules/content/tooltipContent/GiftLifetimeExemption';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { GiftDataCard } from '../GiftDataCard';
import { useGiftingExemptionsForClient } from '../hooks/useGiftingExemptionsForClient';
import { useLifetimeExemptionCardMenuItems } from './hooks/useLifetimeExemptionCardMenuItems';
import { useLifetimeExemptionCardQuery } from './hooks/useLifetimeExemptionCardQuery';

interface LifetimeExemptionCardProps {
  householdId: string;
  showMenu?: boolean;
  showTooltip?: boolean;
}

// Shows the available lifetime exemption for the current household
export function LifetimeExemptionCard({
  householdId,
  showMenu = false,
  showTooltip = false,
}: LifetimeExemptionCardProps) {
  const {
    household,
    loading: isLoadingCardData,
    networkStatus,
  } = useLifetimeExemptionCardQuery(householdId);
  const { lifetimeExemptionSections, availableLifetimeExemption } =
    useGiftingExemptionsForClient({
      household: household ?? undefined,
      returnNegativeValues: false,
    });
  const { menuItems, menuItemModals, isMenuOpen } =
    useLifetimeExemptionCardMenuItems(householdId);

  const loading = isLoadingCardData || !availableLifetimeExemption;
  const updating = networkStatus === NetworkStatus.refetch;

  if (loading) {
    return (
      <Loader
        boxProps={{
          sx: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          },
        }}
        circularProgressProps={{
          size: 24,
        }}
      />
    );
  }

  return (
    <>
      {showMenu && menuItemModals}
      <GiftDataCard
        cardSx={{
          pb: 4,
        }}
        sections={lifetimeExemptionSections}
        label="Lifetime exemption"
        displayValue={formatCurrencyNoDecimals(availableLifetimeExemption)}
        menuItems={showMenu ? menuItems : undefined}
        isMenuOpen={isMenuOpen}
        updating={updating}
        tooltip={
          showTooltip ? (
            <ContextualHelpTooltip>
              <GiftLifetimeExemption />
            </ContextualHelpTooltip>
          ) : undefined
        }
      />
    </>
  );
}
