// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCharitableDesignerOptimizationQueryVariables = Types.Exact<{
  cltOptimizationInput: Types.CltOptimizationInput;
  crtOptimizationInput: Types.CrtOptimizationInput;
  isCRT: Types.Scalars['Boolean']['input'];
}>;


export type GetCharitableDesignerOptimizationQuery = { __typename?: 'Query', cltOptimization?: { __typename?: 'CLTOptimization', annuityPayoutAmount?: CurrencyUSD | null, unitrustPayoutPercent?: Percent | null }, crtOptimization?: { __typename?: 'CRTOptimization', annuityPayoutAmount?: CurrencyUSD | null, unitrustPayoutPercent?: Percent | null } };


export const GetCharitableDesignerOptimizationDocument = gql`
    query GetCharitableDesignerOptimization($cltOptimizationInput: CLTOptimizationInput!, $crtOptimizationInput: CRTOptimizationInput!, $isCRT: Boolean!) {
  cltOptimization(input: $cltOptimizationInput) @skip(if: $isCRT) {
    annuityPayoutAmount
    unitrustPayoutPercent
  }
  crtOptimization(input: $crtOptimizationInput) @include(if: $isCRT) {
    annuityPayoutAmount
    unitrustPayoutPercent
  }
}
    `;

/**
 * __useGetCharitableDesignerOptimizationQuery__
 *
 * To run a query within a React component, call `useGetCharitableDesignerOptimizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCharitableDesignerOptimizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCharitableDesignerOptimizationQuery({
 *   variables: {
 *      cltOptimizationInput: // value for 'cltOptimizationInput'
 *      crtOptimizationInput: // value for 'crtOptimizationInput'
 *      isCRT: // value for 'isCRT'
 *   },
 * });
 */
export function useGetCharitableDesignerOptimizationQuery(baseOptions: Apollo.QueryHookOptions<GetCharitableDesignerOptimizationQuery, GetCharitableDesignerOptimizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCharitableDesignerOptimizationQuery, GetCharitableDesignerOptimizationQueryVariables>(GetCharitableDesignerOptimizationDocument, options);
      }
export function useGetCharitableDesignerOptimizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCharitableDesignerOptimizationQuery, GetCharitableDesignerOptimizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCharitableDesignerOptimizationQuery, GetCharitableDesignerOptimizationQueryVariables>(GetCharitableDesignerOptimizationDocument, options);
        }
export function useGetCharitableDesignerOptimizationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCharitableDesignerOptimizationQuery, GetCharitableDesignerOptimizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCharitableDesignerOptimizationQuery, GetCharitableDesignerOptimizationQueryVariables>(GetCharitableDesignerOptimizationDocument, options);
        }
export type GetCharitableDesignerOptimizationQueryHookResult = ReturnType<typeof useGetCharitableDesignerOptimizationQuery>;
export type GetCharitableDesignerOptimizationLazyQueryHookResult = ReturnType<typeof useGetCharitableDesignerOptimizationLazyQuery>;
export type GetCharitableDesignerOptimizationSuspenseQueryHookResult = ReturnType<typeof useGetCharitableDesignerOptimizationSuspenseQuery>;
export type GetCharitableDesignerOptimizationQueryResult = Apollo.QueryResult<GetCharitableDesignerOptimizationQuery, GetCharitableDesignerOptimizationQueryVariables>;
export const Operations = {
  Query: {
    GetCharitableDesignerOptimization: 'GetCharitableDesignerOptimization' as const
  }
}