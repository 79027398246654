import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from 'react';

import {
  EntitySubformNamespaceType,
  SubformConfig,
} from '@/modules/entities/EntitySubforms/entitySubformConfigs';
import * as diagnostics from '@/utils/diagnostics';

// constructs a map of subform types to data resolved by the dataFetcher
export function useData(subformConfig: SubformConfig[], entityNodeId?: string) {
  const apolloClient = useApolloClient();
  const [data, setData] = useState<Partial<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<EntitySubformNamespaceType, any>
  > | null>(null);

  const [error, setError] = useState<boolean | null>(null);

  useEffect(() => {
    async function fetchData() {
      const keys = subformConfig.reduce((acc, config) => {
        if (config.dataFetcher || config.defaultData) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-explicit-any
          return [...acc, config.type] as any[];
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return acc;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, [] as any);

      const subformDataPromises = subformConfig.map(
        async (config) =>
          (entityNodeId &&
            (await config.dataFetcher?.(apolloClient, entityNodeId))) ||
          config.defaultData
      );

      try {
        const subformData = await Promise.all(subformDataPromises);

        const data = subformData
          .filter((data) => data !== undefined)
          .reduce((acc, data, i) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
            return { ...acc, [keys[i]]: data };
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          }, {} as any);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- linter refactor
        setData(data);
      } catch (e) {
        diagnostics.error('Could not fetch data for subform', e as Error);
        setData({});
        setError(true);
      }
    }

    void fetchData();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [subformConfig.length]);

  return [data, error] as const;
}
