import {
  compact,
  concat,
  difference,
  first,
  groupBy,
  keys,
  map,
  transform,
  values,
} from 'lodash';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import {
  ClientProfessionalTeamForm,
  ClientProfessionalTeamMemberRoleKey,
  ClientProfessionalTeamRole,
  ClientProfessionalTeamRoleMap,
  ClientProfessionalTeamRoleMember,
  getEmptyRoleForKind,
} from '@/modules/professionalTeam/ClientProfessionalTeam.types';
import {
  AugmentedCreateProfessionalTeamRoleInput,
  AugmentedUpdateClientProfileInput,
  AugmentedUpdateProfessionalTeamRoleInput,
  ProfessionalTeamRoleKind,
  UpdateProfessionalTeamRoleInput,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import {
  getEmptyAdvisorClientRoleMap,
  getEmptyPrincipalClientRoleMap,
} from './ClientProfessionalTeam.constants';
import {
  ClientProfessionalTeam_ProfessionalTeamRoleFragment,
  ClientProfessionalTeamQuery,
} from './graphql/ClientProfessionalTeam.generated';

export function getClientProfessionalTeamRoleKey({
  kind,
  otherRoleName,
}: ClientProfessionalTeam_ProfessionalTeamRoleFragment): ClientProfessionalTeamMemberRoleKey {
  if (kind === ProfessionalTeamRoleKind.Other) {
    return `OTHER-${otherRoleName}`;
  }
  return kind;
}

function getTeamMembers(data: ClientProfessionalTeamQuery | undefined) {
  return getNodes(data?.clientProfiles).filter(({ id }) => !!id);
}

export function mapDataToTeamMemberOptions(
  data: ClientProfessionalTeamQuery | undefined
): SelectInputOption<string>[] {
  const teamMembers = getTeamMembers(data);

  return teamMembers
    .map<SelectInputOption<string>>((teamMember) => ({
      value: teamMember.id,
      display: teamMember.legalName,
    }))
    .sort((a, b) => a.display.localeCompare(b.display));
}

function getPrincipalClients(data: ClientProfessionalTeamQuery | undefined) {
  const household = first(getNodes(data?.households));
  return household?.principalClients?.filter(({ id }) => !!id) || [];
}

export function mapDataToPrincipalClientDisplay(
  data: ClientProfessionalTeamQuery | undefined
): Record<string, string> {
  return getPrincipalClients(data).reduce<Record<string, string>>(
    (acc, principalClient) => {
      acc[principalClient.id] = principalClient.displayName;
      return acc;
    },
    {}
  );
}

function addToRole(
  formRole: ClientProfessionalTeamRole | null | undefined,
  fetchedRole: ClientProfessionalTeam_ProfessionalTeamRoleFragment,
  memberId: string
): ClientProfessionalTeamRole {
  const role = formRole || getEmptyRoleForKind(fetchedRole.kind);

  const member: ClientProfessionalTeamRoleMember = {
    roleId: fetchedRole.id,
    memberId,
    powerOfAttorneyKind: fetchedRole.powerOfAttorneyKind || '',
  };

  // check the memberId instead of the list length, as an empty list has a
  // single element with a blank memberId
  if (!role.members[0]?.memberId) {
    role.members = [member];
  } else {
    role.members.push(member);
  }

  role.otherRoleName = role?.otherRoleName || fetchedRole?.otherRoleName || '';

  return role;
}

export function mapDataToColumn(
  data: ClientProfessionalTeamQuery | undefined
): ClientProfessionalTeamForm {
  const advisorClientRoles: ClientProfessionalTeamForm['advisorClientRoles'] =
    getEmptyAdvisorClientRoleMap();

  const principalClientIds = map(getPrincipalClients(data), ({ id }) => id);

  const principalClientIdTeamMemberMap: ClientProfessionalTeamForm['principalClientIdTeamMemberMap'] =
    principalClientIds.reduce<
      ClientProfessionalTeamForm['principalClientIdTeamMemberMap']
    >((acc, principalClientId) => {
      acc[principalClientId] = getEmptyPrincipalClientRoleMap();
      return acc;
    }, {});

  const professionalTeamMembers = compact(
    getNodes(data?.clientProfiles).filter(
      (professionalTeamMember) => !!professionalTeamMember.id
    )
  );

  professionalTeamMembers.forEach((teamMember) => {
    const roles = getNodes(teamMember.professionalTeamRoles);
    roles.forEach((role) => {
      const roleKey = getClientProfessionalTeamRoleKey(role);

      const principalClientId = role.associatedClientIndividual?.id ?? '';
      const principalClientRoles =
        principalClientIdTeamMemberMap[principalClientId];

      if (principalClientId) {
        if (!principalClientRoles) {
          diagnostics.error('Attempted to set role on non-principal client');
          return;
        }

        principalClientRoles[roleKey] = addToRole(
          principalClientRoles[roleKey],
          role,
          teamMember.id
        );
      } else if (role.household) {
        advisorClientRoles[roleKey] = addToRole(
          advisorClientRoles[roleKey],
          role,
          teamMember.id
        );
      }
    });
  });

  return {
    advisorClientRoles,
    principalClientIdTeamMemberMap,
  };
}

interface UserRole
  extends Omit<ClientProfessionalTeamRole, 'members'>,
    ClientProfessionalTeamRoleMember {
  principalClientId?: string;
}

function validateOtherRoleName({ kind, otherRoleName }: UserRole): void {
  if (kind !== ProfessionalTeamRoleKind.Other) {
    return;
  }

  if (!otherRoleName) {
    throw new Error(
      'A descriptive name is required if the role type is "Other"'
    );
  }

  return;
}

function flattenClientProfessionalTeamRoleMap(
  roleMap: Partial<ClientProfessionalTeamRoleMap> | null | undefined,
  principalClientId?: string
): UserRole[] {
  return values(roleMap).reduce<UserRole[]>((acc, mappedValue) => {
    const roleMembers: UserRole[] =
      mappedValue?.members.map<UserRole>((member) => {
        return {
          ...mappedValue,
          ...member,
          principalClientId,
        };
      }) || [];
    return acc.concat(roleMembers);
  }, []);
}

export function mapFormDataToAugmentedClientProfileUpdate(
  formData: ClientProfessionalTeamForm,
  queryData: ClientProfessionalTeamQuery | undefined,
  householdId: string
): AugmentedUpdateClientProfileInput[] {
  const teamMembers = getTeamMembers(queryData);
  const principalUserIds = keys(formData.principalClientIdTeamMemberMap);

  // step 1: flatten & merge data into an array of all role updates
  const allRoleUpdates = concat<UserRole>(
    flattenClientProfessionalTeamRoleMap(formData.advisorClientRoles),
    ...principalUserIds.map((principalClientId) =>
      flattenClientProfessionalTeamRoleMap(
        formData.principalClientIdTeamMemberMap[principalClientId],
        principalClientId
      )
    )
  ).filter((update) => !!update.memberId); // and filter out all the "None" choices

  // step 2: group the role updates per pro team member
  const proTeamMemberIdsToRoles = groupBy(allRoleUpdates, 'memberId');

  // step 3: map from user-centric data to proper output format
  let updates: AugmentedUpdateClientProfileInput[] = transform<
    UserRole[],
    AugmentedUpdateClientProfileInput[]
  >(
    proTeamMemberIdsToRoles,
    (acc, proTeamMemberRoles, proTeamMemberId) => {
      const initialTeamRoles = getNodes(
        teamMembers.find((teamMember) => teamMember.id === proTeamMemberId)
          ?.professionalTeamRoles
      );
      const initialTeamRoleIds: string[] = initialTeamRoles?.map(
        (teamRole) => teamRole.id
      );

      const addedTeamRoles = proTeamMemberRoles.filter(({ roleId }) => !roleId);

      const currentTeamRoleIds: string[] = compact(
        proTeamMemberRoles.map(({ roleId }) => roleId || null)
      );

      const removeProfessionalTeamRoleIDs = difference(
        initialTeamRoleIds,
        currentTeamRoleIds
      );

      const updatedTeamRoles = proTeamMemberRoles.filter(
        ({ roleId }) =>
          // role is updated if has a roleId (not existing) and not in the removed list
          !!roleId && !removeProfessionalTeamRoleIDs.includes(roleId)
      );

      const augmentedClientProfileInput: AugmentedUpdateClientProfileInput = {
        id: proTeamMemberId,
        update: {
          removeProfessionalTeamRoleIDs: removeProfessionalTeamRoleIDs.length
            ? removeProfessionalTeamRoleIDs
            : undefined,
        },
        withProfessionalTeamRoles:
          addedTeamRoles.map<AugmentedCreateProfessionalTeamRoleInput>(
            (addedRole) => {
              validateOtherRoleName(addedRole);
              return {
                create: {
                  associatedClientIndividualID:
                    addedRole.principalClientId || undefined,
                  kind: addedRole.kind,
                  otherRoleName: addedRole.otherRoleName || undefined,
                  householdID: householdId,
                  powerOfAttorneyKind:
                    addedRole.powerOfAttorneyKind || undefined,
                },
              };
            }
          ),
        updateProfessionalTeamRoles:
          updatedTeamRoles.map<AugmentedUpdateProfessionalTeamRoleInput>(
            (updatedRole) => {
              if (!updatedRole.roleId) {
                throw new Error('Cannot update role without ID');
                // this should never happen, since roles without IDs should be new roles based on the filtering above
              }
              validateOtherRoleName(updatedRole);

              let clearOtherRoleName: UpdateProfessionalTeamRoleInput['clearOtherRoleName'] =
                undefined;
              if (updatedRole.kind === ProfessionalTeamRoleKind.Other) {
                clearOtherRoleName = false;
              }

              let clearPowerOfAttorneyKind: UpdateProfessionalTeamRoleInput['clearPowerOfAttorneyKind'] =
                true;
              if (
                updatedRole.kind === ProfessionalTeamRoleKind.PowerOfAttorney
              ) {
                clearPowerOfAttorneyKind = !updatedRole.powerOfAttorneyKind;
              }

              return {
                id: updatedRole.roleId,
                update: {
                  associatedClientIndividualID:
                    updatedRole.principalClientId || undefined,
                  clearOtherRoleName,
                  clearPowerOfAttorneyKind,
                  kind: updatedRole.kind,
                  otherRoleName: updatedRole.otherRoleName || undefined,
                  powerOfAttorneyKind:
                    updatedRole.powerOfAttorneyKind || undefined,
                },
              };
            }
          ),
      };
      acc.push(augmentedClientProfileInput);
      return acc;
    },
    []
  );
  // step 4: if a user doesn't have any roles, clear them
  const allClientProfileIds = getNodes(queryData?.clientProfiles).map(
    (clientProfile) => clientProfile.id
  );
  const updatedClientProfileIds = updates.map(({ id }) => id);
  const clientProfileIdsToClear = difference(
    allClientProfileIds,
    updatedClientProfileIds
  );

  const updatesWithClearedRoles =
    clientProfileIdsToClear.map<AugmentedUpdateClientProfileInput>(
      (clearedClientProfileId) => ({
        id: clearedClientProfileId,
        update: {
          clearProfessionalTeamRoles: true,
        },
      })
    );
  updates = updates.concat(updatesWithClearedRoles);

  return updates;
}
