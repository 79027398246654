import { Maybe } from 'graphql/jsutils/Maybe';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import {
  EntityGstStatus,
  EntityInEstateStatus,
  StateCode,
} from '@/types/schema';

import {
  EntityGstStatusDisplayNames,
  EntityInEstateStatusDisplayNames,
} from '../details/entityDetailPageUtils';

export interface GetAssetLocationDisplayInput {
  inEstateStatus: Maybe<EntityInEstateStatus>;
  stateTaxes: { stateCode: StateCode; inEstateStatus: EntityInEstateStatus }[];
  gstStatus: Maybe<EntityGstStatus>;
}

export interface GetAssetLocationDisplayOutput {
  locationDisplay: string | null;
  locationDisplayShort: string | null;
  gstStatusDisplay: string | null | undefined;
}

export function getAssetLocationDisplay({
  inEstateStatus,
  stateTaxes,
  gstStatus,
}: GetAssetLocationDisplayInput): GetAssetLocationDisplayOutput {
  if (!inEstateStatus) {
    return {
      locationDisplay: null,
      locationDisplayShort: null,
      gstStatusDisplay: null,
    };
  }

  const estateStatusFederal = EntityInEstateStatusDisplayNames[inEstateStatus];
  const gstStatusDisplay = gstStatus
    ? EntityGstStatusDisplayNames[gstStatus]
    : undefined;

  if (!stateTaxes || stateTaxes.length === 0) {
    let locationDisplay = `${estateStatusFederal} (Federal)`;
    if (gstStatusDisplay) {
      locationDisplay += ` — ${gstStatusDisplay}`;
    }

    return {
      locationDisplay,
      locationDisplayShort: `${estateStatusFederal}`,
      gstStatusDisplay,
    };
  }

  const estateStatusToStates = stateTaxes.reduce(
    (acc, { stateCode, inEstateStatus }) => {
      acc[inEstateStatus] = [...(acc[inEstateStatus] ?? []), stateCode];
      return acc;
    },
    {} as Partial<Record<EntityInEstateStatus, StateCode[]>>
  );

  // The template is <federal estate status> (Federal & <state1>, <state2>) - <gst status>; <state estate status>, <state1, state2> for normal

  // Make <federal estate status> (Federal & <state1>, <state2>) - <gst status>
  let locationDisplay = estateStatusFederal;
  let locationDisplayShort = estateStatusFederal;
  if ((estateStatusToStates[inEstateStatus]?.length ?? 0) > 0) {
    // Add states with same estate status
    locationDisplay += ` (Federal & ${estateStatusToStates[
      inEstateStatus
    ]!.join(', ')})`;
  } else {
    locationDisplay += ' (Federal)';
  }

  const sameAsFederal = estateStatusToStates[inEstateStatus];
  // if some are the same as the federal, add them to the short display
  if (sameAsFederal) {
    locationDisplayShort = `${estateStatusFederal} (Federal & ${sameAsFederal.join(
      ', '
    )})`;
  }

  // Get estate statuses for states where the estate status is different from the federal estate status
  const otherEstateStatus = (
    Object.keys(estateStatusToStates) as EntityInEstateStatus[]
  ).filter((status) => status !== inEstateStatus);

  const otherEstateStatusDisplay = otherEstateStatus
    .map((status) => {
      const stateCodes = estateStatusToStates[status]!;
      return `${EntityInEstateStatusDisplayNames[status]} (${stateCodes.join(
        ', '
      )})`;
    })
    .join(', ');

  // if some are different, add them to the short display
  locationDisplayShort += otherEstateStatusDisplay
    ? ` (Federal), ${otherEstateStatusDisplay}`
    : '';

  if (gstStatusDisplay) {
    locationDisplay += ` — ${gstStatusDisplay}`;
  }

  // Add states with different estate status
  if (otherEstateStatusDisplay) {
    locationDisplay += `; ${otherEstateStatusDisplay}`;
  }

  return {
    locationDisplay,
    locationDisplayShort,
    gstStatusDisplay,
  };
}

export function getFederalAssetLocationDisplay({
  inEstateStatus,
  gstStatus,
}: GetAssetLocationDisplayInput): GetAssetLocationDisplayOutput {
  if (!inEstateStatus) {
    return {
      locationDisplay: null,
      locationDisplayShort: null,
      gstStatusDisplay: null,
    };
  }

  const locationDisplay =
    EntityInEstateStatusDisplayNames[inEstateStatus] || EMPTY_CONTENT_HYPHEN;
  const gstStatusDisplay = gstStatus
    ? EntityGstStatusDisplayNames[gstStatus]
    : undefined;
  return {
    locationDisplay,
    locationDisplayShort: locationDisplay,
    gstStatusDisplay,
  };
}

export function getStateAssetLocationDisplay({
  stateTaxes,
}: GetAssetLocationDisplayInput): GetAssetLocationDisplayOutput {
  if (!stateTaxes || stateTaxes.length === 0) {
    return {
      locationDisplay: null,
      locationDisplayShort: null,
      gstStatusDisplay: null,
    };
  }

  const estateStatusToStates = stateTaxes.reduce(
    (acc, { stateCode, inEstateStatus }) => {
      acc[inEstateStatus] = [...(acc[inEstateStatus] ?? []), stateCode];
      return acc;
    },
    {} as Partial<Record<EntityInEstateStatus, StateCode[]>>
  );

  if (
    estateStatusToStates[EntityInEstateStatus.InEstate]?.length &&
    estateStatusToStates[EntityInEstateStatus.OutOfEstate]?.length
  ) {
    return {
      locationDisplay: `${EntityInEstateStatusDisplayNames.IN_ESTATE} (${estateStatusToStates[EntityInEstateStatus.InEstate].join(', ')}), ${EntityInEstateStatusDisplayNames.OUT_OF_ESTATE} (${estateStatusToStates[EntityInEstateStatus.OutOfEstate].join(', ')})`,
      locationDisplayShort: 'Mixed',
      gstStatusDisplay: null,
    };
  }

  if (estateStatusToStates[EntityInEstateStatus.InEstate]?.length) {
    return {
      locationDisplay: EntityInEstateStatusDisplayNames.IN_ESTATE,
      locationDisplayShort: EntityInEstateStatusDisplayNames.IN_ESTATE,
      gstStatusDisplay: null,
    };
  }

  return {
    locationDisplay: EntityInEstateStatusDisplayNames.OUT_OF_ESTATE,
    locationDisplayShort: EntityInEstateStatusDisplayNames.OUT_OF_ESTATE,
    gstStatusDisplay: null,
  };
}
