import { compact, omit } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';

import { BalanceSheetCategoriesForm } from '../AdminBalanceSheetConfigurationPage.types';

interface UseAvailableAssetClassesParams {
  currentRowId: string;
  includeSystemClasses?: boolean;
}

export function useAvailableAssetClasses({
  currentRowId,
  includeSystemClasses = false,
}: UseAvailableAssetClassesParams) {
  const { assetClassesById } = useTenantDetailsContext();
  const { control } = useFormContext<BalanceSheetCategoriesForm>();

  const balanceSheetCategoriesById = useWatch({
    control,
    name: 'balanceSheetCategoriesById',
  });

  const alreadyAssignedAssetClasses = useMemo(() => {
    const otherCategories = omit<typeof balanceSheetCategoriesById>(
      balanceSheetCategoriesById,
      currentRowId
    );
    return Object.values(otherCategories).reduce<string[]>((acc, category) => {
      if (!category) return acc;
      return [...acc, ...category.assetClassIds];
    }, [] as string[]);
  }, [balanceSheetCategoriesById, currentRowId]);

  const options = useMemo(() => {
    return compact(
      Object.values(assetClassesById).map((assetClass) => {
        // don't allow associating balance sheet categories to
        // e.g. unspecified liquid/illiquid
        if (assetClass.isSystemClass && !includeSystemClasses) {
          return null;
        }
        return {
          display: assetClass.displayName,
          value: assetClass.id,
          disabled: alreadyAssignedAssetClasses.includes(assetClass.id),
        };
      })
    );
  }, [assetClassesById, alreadyAssignedAssetClasses, includeSystemClasses]);

  return {
    options,
    loading: false, // Since we're not fetching data asynchronously
  };
}
