import { Box } from '@mui/material';

import { Callout } from '@/components/notifications/Callout/Callout';

export interface AssetProviderUnlinkWarningProps {
  providerName: string;
}

export function AssetProviderUnlinkWarning({
  providerName,
}: AssetProviderUnlinkWarningProps) {
  return (
    <Callout severity="error">
      <Box mb={0.5} component="strong">
        Are you sure you wish to unlink {providerName} from this client?
      </Box>
      <Box>
        Upon saving changes, all linked entities will stop updating
        automatically.
      </Box>
    </Callout>
  );
}
