import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import {
  FormAwareSelectInput,
  InputOption,
} from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { SidePanel } from '@/components/modals/SidePanel';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useUpdateEstateWaterfallAssumptionsMutation } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/graphql/EstateWaterfallAssumptions.generated';
import { EstateWaterfallFragment } from '@/modules/estateWaterfall/graphql/EstateWaterfall.generated';
import { GetWaterfallSummary_EstateWaterfallFragment } from '@/modules/estateWaterfall/graphql/GetWaterfallSummary.generated';
import {
  GrowthProfilesOperations,
  useAvailableGrowthProfiles,
} from '@/modules/estateWaterfall/hooks/useAvailableGrowthProfiles';
import { GrowthProfileModal } from '@/modules/growthProfiles/GrowthProfileModal/GrowthProfileModal';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';
import { PathsOf } from '@/types/subform';
import { UnreachableError } from '@/utils/errors';

interface GrowthProfileSummaryPanelFormShape {
  growthProfileId: string;
  firstGrantorDeathYear: number | undefined | null;
  secondGrantorDeathYear: number | undefined | null;
}

type GrowthProfileSummaryPanelFormShapePaths =
  PathsOf<GrowthProfileSummaryPanelFormShape>;

interface GrowthProfilesSummaryPanelInnerProps {
  waterfall: GrowthProfilesSummaryPanelProps['waterfall'];
  openModal: () => void;
}

function useGrantorFirstName(
  firstGrantorDeathId: string,
  afterDeath: AfterDeath
): string | undefined {
  const { possibleGrantors } = useHouseholdDetailsContext();
  return possibleGrantors?.find((grantor) => {
    switch (afterDeath) {
      case AfterDeath.None:
      case AfterDeath.First:
        return grantor?.id === firstGrantorDeathId;
      case AfterDeath.Second:
        return grantor?.id !== firstGrantorDeathId;
      default:
        throw new UnreachableError({
          case: afterDeath,
          message: `Invalid death event for first name, ${afterDeath}`,
        });
    }
  })?.firstName;
}

function GrowthProfilesSummaryPanelInner({
  waterfall,
  openModal,
}: GrowthProfilesSummaryPanelInnerProps) {
  const { control } = useFormContext<GrowthProfileSummaryPanelFormShape>();
  const { isTwoClientHousehold, householdId } = useHouseholdDetailsContext();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const { growthProfiles, loading } = useAvailableGrowthProfiles(householdId, {
    onError(err) {
      reportError(
        `Could not fetch growth profiles for waterfall ${waterfall.id}`,
        err
      );
      showFeedback('Could not fetch growth profiles.  Please try again later.');
    },
  });

  const firstGrantorDeathFirstName =
    useGrantorFirstName(waterfall.firstGrantorDeath.id, AfterDeath.First) ||
    'First grantor';
  const secondGrantorDeathFirstName =
    useGrantorFirstName(waterfall.firstGrantorDeath.id, AfterDeath.Second) ||
    'Second grantor';

  const growthProfileOptions = useMemo(() => {
    return growthProfiles.map<InputOption>((profile) => {
      return {
        display: profile.displayName,
        value: profile.id,
        endAdornment: profile.isDefault ? (
          <Badge variant={BadgeVariants.Primary} display="Default" />
        ) : undefined,
      };
    });
  }, [growthProfiles]);

  return (
    <Stack>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <Stack direction="row" spacing={2} alignItems="flex-end">
            <Box flex={2}>
              <FormAwareSelectInput<GrowthProfileSummaryPanelFormShape>
                control={control}
                fieldName={
                  'growthProfileId' as const satisfies GrowthProfileSummaryPanelFormShapePaths
                }
                label="Growth profile"
                required
                options={growthProfileOptions}
                disabled={loading}
                showEmptyValue={false}
              />
            </Box>
            <IconButton
              variant="transparent"
              onClick={openModal}
              size="sm"
              icon={Edit02Icon}
              ariaLabel="Edit growth profile"
            />
          </Stack>
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        {isTwoClientHousehold ? (
          <>
            <FormLayoutItem width={6}>
              <FormAwareTextInput<GrowthProfileSummaryPanelFormShape>
                control={control}
                fieldName={
                  'firstGrantorDeathYear' as const satisfies GrowthProfileSummaryPanelFormShapePaths
                }
                label={`${firstGrantorDeathFirstName}'s death year`}
                required
              />
            </FormLayoutItem>
            <FormLayoutItem width={6}>
              <FormAwareTextInput<GrowthProfileSummaryPanelFormShape>
                control={control}
                fieldName={
                  'secondGrantorDeathYear' as const satisfies GrowthProfileSummaryPanelFormShapePaths
                }
                label={`${secondGrantorDeathFirstName}'s death year`}
                required
              />
            </FormLayoutItem>
          </>
        ) : (
          <FormLayoutItem width={12}>
            <FormAwareTextInput<GrowthProfileSummaryPanelFormShape>
              control={control}
              fieldName={
                'firstGrantorDeathYear' as const satisfies GrowthProfileSummaryPanelFormShapePaths
              }
              label={`${firstGrantorDeathFirstName}'s death year`}
              required
            />
          </FormLayoutItem>
        )}
      </FormLayoutRow>
    </Stack>
  );
}

export interface GrowthProfilesSummaryPanelProps {
  waterfall:
    | GetWaterfallSummary_EstateWaterfallFragment
    | EstateWaterfallFragment;
  householdId: string;
  onClose: () => void;
}

export function GrowthProfilesSummaryPanel({
  onClose,
  householdId,
  waterfall,
}: GrowthProfilesSummaryPanelProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const trackUserEvent = useTrackUserEvent();
  const formMethods = useForm<GrowthProfileSummaryPanelFormShape>({
    defaultValues: {
      growthProfileId: waterfall.growthProfile?.id ?? '',
      firstGrantorDeathYear: waterfall.firstGrantorDeathYear,
      secondGrantorDeathYear: waterfall.secondGrantorDeathYear,
    },
  });

  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  const [updateMutation] = useUpdateEstateWaterfallAssumptionsMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onCompleted: () => {
      showFeedback('Waterfall updated successfully', { variant: 'success' });
    },
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError(
        `Could not update estate waterfall assumptions for waterfall ${waterfall.id}`,
        error
      );
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = formMethods;
  const onSubmit = handleSubmit(async (data) => {
    if (waterfall?.growthProfile?.id !== data.growthProfileId) {
      trackUserEvent('growth_profile assigned');
    }
    await updateMutation({
      variables: {
        input: {
          id: waterfall.id,
          update: {
            growthProfileID: data.growthProfileId,
            firstGrantorDeathYear: data.firstGrantorDeathYear,
            secondGrantorDeathYear: data.secondGrantorDeathYear,
          },
        },
      },
    });
  });

  const [growthProfileId] = useWatch({
    control,
    name: ['growthProfileId'],
  });

  return (
    <FormProvider {...formMethods}>
      {isModalOpen && (
        <GrowthProfileModal
          onClose={() => {
            closeModal();
          }}
          householdId={householdId}
          growthProfileId={growthProfileId}
          refetchQueries={[GrowthProfilesOperations.Query.GrowthProfiles]}
        />
      )}
      <SidePanel.Panel>
        <SidePanel.Header title="Assumptions" onClose={onClose} />
        <SidePanel.Content>
          <GrowthProfilesSummaryPanelInner
            waterfall={waterfall}
            openModal={openModal}
          />
        </SidePanel.Content>
        <SidePanel.Footer>
          <Button
            variant="primary"
            onClick={onSubmit}
            size="sm"
            loading={isSubmitting}
          >
            Apply changes
          </Button>
        </SidePanel.Footer>
      </SidePanel.Panel>
    </FormProvider>
  );
}
