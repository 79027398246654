import { NavLink } from 'react-router-dom';

import { PopperContent } from '@/components/poppers/PopperContent';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

export function GRATQPRTTaxableGift({ householdId }: { householdId: string }) {
  const generationalGiftingLink = getCompletePathFromRouteKey(
    ROUTE_KEYS.HOUSEHOLD_DETAILS_GIFTING,
    { householdId }
  );
  return (
    <PopperContent
      body={
        <span>
          This represents the taxable gift made from funding this trust. The
          entered amount is assumed to count against the grantor&apos;s lifetime
          exemption. See the{' '}
          <NavLink to={generationalGiftingLink}>
            Generational Gifting summary
          </NavLink>{' '}
          for details.
        </span>
      }
    />
  );
}
