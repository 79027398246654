import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { ControllingPersonsOrSignatories } from '@/modules/content/tooltipContent/ControllingPersonsOrSignatories';

import {
  CONTROLLING_PERSONS_ADD_LABEL,
  CONTROLLING_PERSONS_LABEL,
} from '../../EntitySubforms/utils/shared/controllingParties.utils';
import {
  getOptionsFromData,
  TrusteeDropdown,
} from '../../inputs/TrusteeDropdown/TrusteeDropdown';
import { useOptionsForTrustDetailsForm } from '../../TrustDetailsSubform/graphql/hooks';
import { useTrustDetailsFormOptionsQuery } from '../../TrustDetailsSubform/graphql/TrustDetails.generated';
import { TrustDetailsSubformType_TrusteesAndSuccessorTrustees } from '../../TrustDetailsSubform/TrustDetailsSubform.types';
import {
  TestamentaryEntityFormField,
  TestamentaryEntityFormShape,
} from './TestamentaryEntityForm.types';

export const CONTROLLING_PARTY_SUBFORM = 'controllingPartySubform' as const;

export interface ControllingPartyInputShape {
  controllingPartyId: string;
}

export const defaultControllingParty: ControllingPartyInputShape = {
  controllingPartyId: '',
};

interface Props {
  subformValues: TrustDetailsSubformType_TrusteesAndSuccessorTrustees;
  filterClientProfileIds: string[];
  householdId: string;
}

export function ControllingPartyInput({
  subformValues,
  filterClientProfileIds,
  householdId,
}: Props) {
  const { control } = useFormContext<TestamentaryEntityFormShape>();
  const { createErrorFeedback } = useFeedback();

  const { data } = useTrustDetailsFormOptionsQuery({
    variables: {
      where: {
        id: householdId,
      },
    },
    onError: createErrorFeedback('Failed to fetch controlling party options.'),
  });

  const possibleTrusteeOptions = getOptionsFromData({
    data: data?.households.edges?.[0]?.node ?? null,
    subformValues,
  }).filter((option) => {
    // Don't filter out component options
    if (option.type === 'component') {
      return true;
    }
    // Filter out trustees that can't be selected due to circumstances
    // defined in the parent component, e.g., if the trustee is already
    // dead
    if (filterClientProfileIds.includes(option.value)) {
      return false;
    }
    return true;
  });

  const { trusteeOptions } = useOptionsForTrustDetailsForm({
    subformValues,
    possibleTrusteeOptions,
  });

  return (
    <FormAwareInputRepeater<TestamentaryEntityFormShape>
      name={`${CONTROLLING_PARTY_SUBFORM}.controllingParties`}
      emptyValue={defaultControllingParty}
      control={control}
      shouldInit
      addAnotherItemText={CONTROLLING_PERSONS_ADD_LABEL}
      render={(i: number) => {
        const isFirstInput = i === 0;
        return (
          <FormLayoutRow>
            <FormLayoutItem>
              <TrusteeDropdown
                fieldName={
                  `${CONTROLLING_PARTY_SUBFORM}.controllingParties.${i}.controllingPartyId` as const satisfies TestamentaryEntityFormField
                }
                label={CONTROLLING_PERSONS_LABEL}
                hideLabel={!isFirstInput}
                options={trusteeOptions}
                contextualHelp={<ControllingPersonsOrSignatories />}
                householdId={householdId}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        );
      }}
    />
  );
}
