import { useEffect, useState } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { getIntegrationEntitiesFromEntity } from '@/modules/assetProviderIntegrations/shared/utils';
import { STRUCTURED_ASSETS_SUBFORM_NAMESPACE } from '@/modules/entities/StructuredAssetsSubform/StructuredAssetsSubform.types';
import {
  getStructuredAssetsSubformValues,
  valuationHasAdditionalManualAssets,
} from '@/modules/entities/StructuredAssetsSubform/StructuredAssetsSubform.utils';

import { FullScreenStructuredAssetsModalForm } from '../FullScreenStructuredAssetsModal.types';
import { useFullScreenStructuredAssetsModalQuery } from '../graphql/FullScreenStructuredAssetsModal.generated';

export function useFullScreenStructuredAssetFormData(
  entityId: string,
  reset: (values: FullScreenStructuredAssetsModalForm) => void
) {
  const [completedLoadingFormData, setCompletedLoadingFormData] =
    useState(false);
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { data, ...queryProps } = useFullScreenStructuredAssetsModalQuery({
    // the underlying FormAwareInputRepeater will initialize the form values to empty,
    // but if we have two data values returning (cache, then network), then the empty/initialized
    // inputrepeater values will be overwritten with an empty array from the cache, then never
    // re-initialized
    fetchPolicy: 'network-only',
    variables: {
      entityId,
    },
    onError: (err) => {
      showFeedback('Failed to fetch asset details');
      reportError('could not fetch structured asset details', err);
    },
  });

  const entity = (() => {
    if (data?.node?.__typename !== 'Entity') return null;
    return data.node;
  })();

  const designerAccount = entity?.subtype.designerAccount ?? null;
  const valuation = designerAccount?.valuations.edges?.[0]?.node ?? null;

  useEffect(() => {
    if (!entity) return;
    const hasAdditionalManualAssets =
      valuationHasAdditionalManualAssets(valuation);

    const assetSubformValues =
      getStructuredAssetsSubformValues(designerAccount);

    const valuationAsOfDate = valuation?.effectiveDate ?? new Date();

    const currentIntegrationEntityIds = getIntegrationEntitiesFromEntity(
      entity
    ).map((ie) => ie.id);

    reset({
      [STRUCTURED_ASSETS_SUBFORM_NAMESPACE]: assetSubformValues,
      valuationAsOfDate: valuationAsOfDate,
      _previousIntegrationEntityIds: currentIntegrationEntityIds,
      integrationEntityIds: currentIntegrationEntityIds,
      linkToAllAddeparEntities: entity.addeparLinkedToNongrantorEntity ?? false,
      allowAdditionalManualAssets: hasAdditionalManualAssets,
    });
    setCompletedLoadingFormData(true);
  }, [designerAccount, entity, reset, valuation]);

  return {
    data,
    valuation,
    ...queryProps,
    loading: queryProps.loading || !completedLoadingFormData,
  };
}
