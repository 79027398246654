import { isEmpty } from 'lodash';

import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import {
  EditEntitySection,
  EditEntitySectionInitialConfigWithData,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { getSectionForEntitySubformType } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.utils';
import { EntityFormStage } from '@/modules/entities/EntityForm/types';
import { useData } from '@/modules/entities/EntityForm/useData';
import { useEntitySubformConfigs } from '@/modules/entities/EntityForm/useEntitySubformConfigs';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

interface UseEditEntitySplitScreenSubformConfigsReturn {
  subformConfigsWithInitialData: Record<
    EditEntitySection,
    EditEntitySectionInitialConfigWithData
  >;
  hasError: boolean;
}

/**
 * A hook to get the SubformConfigs and initial data for each EditEntitySection.
 * This bubbles up if the useData hook errored while fetching entity data.
 *
 * This also fetches the AI suggestions from each document associated with the entity.
 *
 * NOTE: Even though the EditEntitySplitScreenModal shows one section/subform
 * at a time, this hook fetches the entity data to populate the initial data for
 * all relevant sections/subforms.
 */
export function useEditEntitySplitScreenSubformConfigs(): UseEditEntitySplitScreenSubformConfigsReturn {
  const { householdId } = useHouseholdDetailsContext();
  const { entityId, entityType } = useEntityDetailsContext();

  const subformConfig = useEntitySubformConfigs(
    entityType,
    householdId,
    entityId || undefined,
    EntityFormStage.EDIT
  );

  const [data, hasError] = useData(subformConfig, entityId || undefined);

  if (isEmpty(data) || hasError) {
    return {
      subformConfigsWithInitialData: {} as Record<
        EditEntitySection,
        EditEntitySectionInitialConfigWithData
      >,
      hasError: !!hasError,
    };
  }

  const subformConfigsWithInitialData = subformConfig.reduce(
    (prev, subformConfig) => {
      const editEntitySection = getSectionForEntitySubformType(
        subformConfig.type
      );
      const initialData = data[subformConfig.type];
      prev[editEntitySection] = {
        subformConfig,
        initialData,
      };
      return prev;
    },
    {} as Record<EditEntitySection, EditEntitySectionInitialConfigWithData>
  );

  return {
    subformConfigsWithInitialData,
    hasError: !!hasError,
  };
}
