import { Stack, Typography } from '@mui/material';

import { Link03Icon } from '@/components/icons/Link03Icon';

export interface AssetProviderLinkIndicatorProps {
  providerName: string;
}

export function AssetProviderLinkIndicator({
  providerName,
}: AssetProviderLinkIndicatorProps) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Link03Icon size={22} />
      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography variant="h5" component="div">
          Data link
        </Typography>
        <Typography variant="subtitle2" component="div">
          {providerName}
        </Typography>
      </Stack>
    </Stack>
  );
}
