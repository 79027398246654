import { useMemo } from 'react';

import { BusinessEntityDetailsSubform } from '@/modules/entities/BusinessEntityDetailsSubform/BusinessEntityDetailsSubform';
import { EntityType } from '@/modules/entities/types/EntityType';

import { getPrincipalInputConfiguration } from './Principals.utils';
import { PrincipalsLabel } from './PrincipalsLabel';

const { FormComponent: BusinessEntityDetailsForm } =
  new BusinessEntityDetailsSubform();

interface BusinessEntityPrincipalsProps {
  householdId: string;
  entityType: EntityType;
}

export function BusinessEntityPrincipals({
  householdId,
  entityType,
}: BusinessEntityPrincipalsProps) {
  const { label } = useMemo(
    () => getPrincipalInputConfiguration(entityType, [], []),
    [entityType]
  );

  return (
    <>
      <PrincipalsLabel label={label} />
      <BusinessEntityDetailsForm householdId={householdId} variant="default" />
    </>
  );
}
