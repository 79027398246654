import {
  array,
  boolean as booleanType,
  date,
  Describe,
  enums,
  nullable,
  number,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateInsurancePolicyInput,
  AugmentedUpdateInsurancePolicyInput,
  InsurancePolicyKind,
  InsurancePolicyPremiumFrequency,
} from '@/types/schema';

import { decimal } from './types';

export const ilitPolicyKindSchema = enums([
  InsurancePolicyKind.Term,
  InsurancePolicyKind.Universal,
  InsurancePolicyKind.Variable,
  InsurancePolicyKind.Whole,
]);

export const ilitPolicyPremiumFrequency = enums([
  InsurancePolicyPremiumFrequency.Annually,
  InsurancePolicyPremiumFrequency.Semiannually,
  InsurancePolicyPremiumFrequency.Monthly,
]);

export const createInsurancePolicyInput: Describe<AugmentedCreateInsurancePolicyInput> =
  object({
    create: object({
      carrierName: optional(nullable(string())),
      cashValue: optional(nullable(decimal)),
      cashValueDate: optional(nullable(date())),
      conversionDate: optional(nullable(date())),
      deathBenefitAmount: decimal,
      documentIDs: optional(nullable(array(string()))),
      initialPremiumDueDate: optional(nullable(date())),
      kind: ilitPolicyKindSchema,
      loanBalanceOutstanding: optional(nullable(decimal)),
      notes: optional(nullable(string())),
      policyHolderIDs: optional(nullable(array(string()))),
      policyNumber: optional(nullable(string())),
      premiumAmount: optional(nullable(decimal)),
      premiumFrequency: optional(nullable(ilitPolicyPremiumFrequency)),
      startDate: date(),
      survivorship: optional(nullable(booleanType())),
      termDurationYears: optional(nullable(number())),
    }),
  });

export const updateInsurancePolicyInput: Describe<AugmentedUpdateInsurancePolicyInput> =
  object({
    id: string(),
    update: object({
      addDocumentIDs: optional(nullable(array(string()))),
      addPolicyHolderIDs: optional(nullable(array(string()))),
      carrierName: optional(nullable(string())),
      cashValue: optional(nullable(decimal)),
      cashValueDate: optional(nullable(date())),
      clearCarrierName: optional(nullable(booleanType())),
      clearCashValue: optional(nullable(booleanType())),
      clearCashValueDate: optional(nullable(booleanType())),
      clearConversionDate: optional(nullable(booleanType())),
      clearDocuments: optional(nullable(booleanType())),
      clearLoanBalanceOutstanding: optional(nullable(booleanType())),
      clearNotes: optional(nullable(booleanType())),
      clearPolicyHolders: optional(nullable(booleanType())),
      clearPolicyNumber: optional(nullable(booleanType())),
      clearTermDurationYears: optional(nullable(booleanType())),
      conversionDate: optional(nullable(date())),
      deathBenefitAmount: optional(nullable(decimal)),
      initialPremiumDueDate: optional(nullable(date())),
      kind: optional(nullable(ilitPolicyKindSchema)),
      loanBalanceOutstanding: optional(nullable(decimal)),
      notes: optional(nullable(string())),
      policyNumber: optional(nullable(string())),
      premiumAmount: optional(nullable(decimal)),
      premiumFrequency: optional(nullable(ilitPolicyPremiumFrequency)),
      clearPremiumFrequency: optional(nullable(booleanType())),
      clearInitialPremiumDueDate: optional(nullable(booleanType())),
      clearPremiumAmount: optional(nullable(booleanType())),
      removeDocumentIDs: optional(nullable(array(string()))),
      removePolicyHolderIDs: optional(nullable(array(string()))),
      startDate: optional(nullable(date())),
      survivorship: optional(nullable(booleanType())),
      termDurationYears: optional(nullable(number())),
    }),
  });
