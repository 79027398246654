import { noop } from 'lodash';
import { createContext, Dispatch } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

import { FamilyTreeAction, FamilyTreeState } from '../types';

export interface FamilyTreeContext {
  dispatch: Dispatch<FamilyTreeAction>;
  state: FamilyTreeState;
}

const defaultState: FamilyTreeState = {
  graph: null as unknown as FamilyTreeState['graph'],
  familyTree: null as unknown as FamilyTreeState['familyTree'],
  householdId: '',
  isInitializingData: true,
  isInitializingFlowchart: true,
  nodes: [],
  edges: [],
};

export const DEFAULT_FAMILY_TREE_STATE: Readonly<FamilyTreeState> =
  Object.freeze(defaultState);

export const FamilyTreeContext = createContext<FamilyTreeContext>({
  dispatch: noop,
  state: { ...DEFAULT_FAMILY_TREE_STATE },
});

export const useFamilyTreeContext = () => {
  return useGuardedContext(FamilyTreeContext, 'FamilyTreeProvider');
};
