import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMatchingRoutes } from '@/hooks/useMatchingRoutes';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { FIRST_DEATH_CLIENT_ID_SEARCH_PARAM } from '../EstateTaxScenarios.constants';

interface NavigateParams {
  householdId: string;
  waterfallId: string;
  firstDeathPrimaryClientId: string;
}

const WATERFALL_DIAGRAM_ROUTES = [
  ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM,
  ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM_ESTATE_TAX_CALCULATION_TROWSER,
];

/**
 * @todo this isn't great that the estate tax trowser has knowledge of the waterfall routing,
 * but it needs to be shared in both modules/estateWaterfall, summaryPanels/EstateTaxSummaryPanel,
 * and ClientDetailsEstateWaterfallSummaryPage.
 *
 * Moving from a trowser for the estate tax scenario to a modal that could be inlined in EstateTaxSummaryPanel would
 * solve this eventually, but is a bit of a larger refactor to fix this small issue of decoupling these modules
 *
 * So for now, we export this hook that has knowledge of all possible routes where the estate tax scenarios trowser is used
 */
export function useNavigateToEstateTaxScenarios() {
  const navigate = useNavigate();
  const { isMatchingRoute } = useMatchingRoutes(WATERFALL_DIAGRAM_ROUTES);

  const waterfallDiagramEstateTaxCalcTrowser =
    ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM_ESTATE_TAX_CALCULATION_TROWSER;
  const waterfallSummaryEstateTaxCalcTrowser =
    ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY_ESTATE_TAX_CALCULATION_TROWSER;

  return useCallback(
    ({
      householdId,
      waterfallId,
      firstDeathPrimaryClientId,
    }: NavigateParams) => {
      navigate(
        getCompletePathFromRouteKey(
          isMatchingRoute
            ? waterfallDiagramEstateTaxCalcTrowser
            : waterfallSummaryEstateTaxCalcTrowser,
          { householdId, waterfallId },
          {
            [FIRST_DEATH_CLIENT_ID_SEARCH_PARAM]: firstDeathPrimaryClientId,
          }
        )
      );
    },
    [
      isMatchingRoute,
      navigate,
      waterfallDiagramEstateTaxCalcTrowser,
      waterfallSummaryEstateTaxCalcTrowser,
    ]
  );
}
