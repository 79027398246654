import { useApolloClient } from '@apollo/client';
import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FieldArrayPath, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { Button } from '@/components/form/baseInputs/Button';
import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { ArrowRightChunkyIcon } from '@/components/icons/ArrowRightChunkyIcon';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Card } from '@/components/layout/Card/Card';
import {
  RIBBON_CARD_DEFAULT_PADDING,
  RibbonCard,
} from '@/components/layout/RibbonCard';
import { Footer } from '@/components/navigation/Footer';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { entityHasAssetsIntegration } from '@/modules/assetProviderIntegrations/shared/utils';
import {
  AssetDetailsModal,
  AssetDetailsModalProps,
} from '@/modules/assets/AssetDetailsModal/AssetDetailsModal';
import { useAssetsFieldArray } from '@/modules/assets/AssetDetailsModal/useAssetsFieldArray';
import { AssetFormTable } from '@/modules/assets/AssetFormTable/AssetFormTable';
import { FundingDateInput } from '@/modules/assets/AssetsSubform/FundingDateInput';
import { SubformAsset } from '@/modules/assets/AssetsSubform/types';
import { NEW_ASSET_ID } from '@/modules/assets/types/asset';
import {
  getCalculatedFundingValueFromAssets,
  getSelectedAsset,
  mapQueryAssetsToAssets,
} from '@/modules/assets/utils';
import { getAssetMutationUpdates } from '@/modules/assetValuation/AssetFullScreenModal/serializers';
import {
  useCurrentAssetValuation,
  useInitialAssetValuation,
  useSpecificAssetValuation,
} from '@/modules/assetValuation/useAssetValuation';
import { DocumentUploaderWithList } from '@/modules/documents/DocumentUploaderWithList/DocumentUploaderWithList';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import {
  AssetValuationV2ValuationReason,
  AugmentedCreateAssetValuationV2Input,
  AugmentedUpdateAssetValuationV2Input,
  AugmentedUpdateEntityInput,
  DocumentType,
  EntityStage,
  UpdateEntityInput,
} from '@/types/schema';
import { getNextDay } from '@/utils/dateUtils';
import * as diagnostics from '@/utils/diagnostics';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { getNodes } from '@/utils/graphqlUtils';

import { FullScreenTaskLeftPaneLayout } from '../components/FullScreenTaskLeftPaneLayout/FullScreenTaskLeftPaneLayout';
import { FullScreenTaskLayout } from '../FullScreenTaskLayout';
import { UNASSIGNED_TASK_SENTINEL } from '../tasks.constants';
import { AnnuityDetailsSummary } from './AnnuityDetailsSummary';
import { EditAnnuityPaymentAmountModal } from './EditAnnuityPaymentAmountModal';
import {
  GetGratAnnuityPaymentTaskDetailsDocument,
  GetGratAnnuityPaymentTaskDetailsQuery,
  GratAnnuityPaymentAnnuityFragment,
  GratAnnuityPaymentTask_EntityFragment,
  useGetGratAnnuityPaymentTaskDetailsQuery,
  useUpdateGratAnnuityTaskDetailsMutation,
  useUpdateGratAnnuityTaskHoldingsAndAnnuityMutation,
  useUpdateGratAnnuityTaskMutation,
} from './graphql/GratAnnuityPaymentTask.generated';
import { AnnuityTaskDetailsForm } from './types';

export interface GRATAnnuityPaymentTaskProps {
  taskId: string;
  onCompleteTask: () => void;
  onClose: () => void;
  householdId: string;
  entityId: string;
}

function getGuardedAnnuityData(
  taskId: string,
  data?: GetGratAnnuityPaymentTaskDetailsQuery
): {
  annuityDetails: GratAnnuityPaymentAnnuityFragment | null;
  entityData: GratAnnuityPaymentTask_EntityFragment | null;
} {
  if (!data) return { annuityDetails: null, entityData: null };
  const annuityData = getNodes(data.gratAnnuityV2s);
  const annuity = annuityData[0];
  if (!annuity) {
    const error = new Error('Missing annuity data for task');
    diagnostics.error('missing annuity data for task', error, { taskId });
    throw error;
  }

  const entityData = data.entity;
  if (entityData?.__typename !== 'Entity') {
    throw new Error('Invalid entity data');
  }

  return { annuityDetails: annuity, entityData };
}

function getConfirmedPostPaymentValue(assets: SubformAsset[]): Decimal | null {
  if (!_.some(assets, (asset) => asset.confirmed)) return null;
  return getCalculatedFundingValueFromAssets(assets);
}

function getUpdateValuationInputFromFormValues(
  values: AnnuityTaskDetailsForm,
  currentValuationId: string
): AugmentedUpdateAssetValuationV2Input {
  if (!values.paidOnDate) {
    throw new Error('Missing required field paidOnDate');
  }

  const { newAssets, existingAssetsCreate } = getAssetMutationUpdates(
    values.assets
  );

  return {
    id: currentValuationId,
    update: {
      effectiveDate: values.paidOnDate,
      clearAssets: true,
    },
    withAssets: [...newAssets, ...existingAssetsCreate],
  };
}

function getCreateValuationInputFromFormValues(
  values: AnnuityTaskDetailsForm,
  accountId: string
): AugmentedCreateAssetValuationV2Input {
  if (!values.paidOnDate) {
    throw new Error('Missing required field paidOnDate');
  }

  const { newAssets, existingAssetsCreate } = getAssetMutationUpdates(
    values.assets
  );

  // note that this is slightly different from other "CreateValuationInput" types because we pass the accountId inside the "create" object
  // whereas in other places we do this as a nested item of "updateDesignerAccount". the reason is because we want to be consistent in marking
  // annuities as paid, and do some association between the newly-created asset valuation and the annuity payment in the backend.
  return {
    create: {
      accountID: accountId,
      effectiveDate: values.paidOnDate,
      valuationReason: AssetValuationV2ValuationReason.GratAnnuityPayment,
    },
    withAssets: [...newAssets, ...existingAssetsCreate],
  };
}

function getEntityInputFromFormValues(
  values: AnnuityTaskDetailsForm
): UpdateEntityInput {
  return {
    // if `failedAnnuityPaymentAmount` is present, we mark the GRAT as "completed" because it's failed
    stage: values.failedAnnuityPaymentAmount ? EntityStage.Completed : null,
  };
}

function havePreviousAnnuitiesBeenPaid(
  annuityDetails: GratAnnuityPaymentAnnuityFragment | null
): boolean {
  if (!annuityDetails) return false;
  const currentAnnuityYear = annuityDetails.yearOfTerm;
  const previousAnnuity = annuityDetails.gratTrust?.annuities?.find(
    (annuity) => {
      return annuity.yearOfTerm === currentAnnuityYear - 1;
    }
  );
  // if no previous annuity is found, it's safe to assume that this is the first one and is okay to complete.
  if (!previousAnnuity) return true;
  // if there is a previous annuity, the current annuity is only valid to be paid if the prior one has already been paid
  return Boolean(previousAnnuity.paidOn);
}

enum ValuationSummaryType {
  PREVIOUS = 'previous',
  CURRENT = 'current',
}
const valuationSummaryTypeOptions = [
  { display: 'Previous valuation', value: ValuationSummaryType.PREVIOUS },
  { display: 'Value after payment', value: ValuationSummaryType.CURRENT },
];

function GRATAnnuityPaymentTaskInner({
  taskId,
  onCompleteTask,
  onClose,
  householdId,
  entityId,
}: GRATAnnuityPaymentTaskProps) {
  const client = useApolloClient();
  const { createErrorFeedback, showFeedback } = useFeedback();
  const [submittingTaskFormOnly, setSubmittingTaskFormOnly] = useState(false);
  // shownValuationSummaryType is used to control the state of the toggle in the task's post-completion
  // state where the user can compare the pre- and post-annuity payment valuations
  const [shownValuationSummaryType, setShownValuationSummaryType] =
    useState<ValuationSummaryType>(ValuationSummaryType.CURRENT);
  const [assetModalOpen, setAssetModalOpen] = useState(false);
  const [editPaymentAmountModalOpen, setEditPaymentAmountModalOpen] =
    useState(false);
  const [showUnconfirmedAssetError, setShowUnconfirmedAssetError] =
    useState(false);
  const unconfirmedAssetErrorRef = useRef<HTMLDivElement>(null);
  const [createDocumentErrorMessage, setCreateDocumentErrorMessage] = useState<
    string | null
  >(null);
  const [isEditingCompletedAnnuity, setIsEditingCompletedAnnuity] =
    useState(false);
  const [assetDisplayType, setAssetDisplayType] = useState<
    'current' | 'previous'
  >('current');

  const navigate = useNavigate();

  const [updateTask, { loading: taskUpdateLoading }] =
    useUpdateGratAnnuityTaskMutation({
      onError: createErrorFeedback(
        'We failed to save your task update. Please refresh the page and try again.'
      ),
    });

  const [updateTaskAnnuityAndHoldings, { loading: fullUpdateLoading }] =
    useUpdateGratAnnuityTaskHoldingsAndAnnuityMutation({
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onError: createErrorFeedback(
        'We failed to save your updates and mark the annuity as paid. Please refresh the page and try again.'
      ),
    });

  const [updateAnnuityAndValuation] = useUpdateGratAnnuityTaskDetailsMutation({
    onError: createErrorFeedback(
      'We failed to update your annuity details. Please refresh the page and try again.'
    ),
  });

  const { data: annuityData, loading: annuityLoading } =
    useGetGratAnnuityPaymentTaskDetailsQuery({
      variables: {
        entityId,
        annuitiesLike: {
          hasAssociatedTaskWith: [
            {
              id: taskId,
            },
          ],
        },
      },
      onError: createErrorFeedback('Failed to load annuity details'),
    });

  const formMethods = useForm<AnnuityTaskDetailsForm>({
    defaultValues: {
      dueDate: null,
      paidOnDate: null,
      assigneeId: '',
      assets: [],
      failedAnnuityPaymentAmount: null,
    },
  });

  const {
    control,
    handleSubmit,
    resetField,
    watch,
    setValue,
    formState: { isDirty },
  } = formMethods;

  const formAssets = watch(`assets`);
  const failedGRATPaymentAmount = watch('failedAnnuityPaymentAmount');
  const { removeByAsset, updateByAsset, handleAssetsModalSubmit } =
    useAssetsFieldArray<AnnuityTaskDetailsForm>(
      `assets` as FieldArrayPath<AnnuityTaskDetailsForm>,
      control,
      formAssets
    );

  const { annuityDetails, entityData } = useMemo(
    () => getGuardedAnnuityData(taskId, annuityData),
    [taskId, annuityData]
  );
  useEffect(
    function syncAnnuityDataToForm() {
      if (!annuityDetails) return;
      resetField('dueDate', {
        defaultValue: annuityDetails.associatedTask?.dueAt,
      });
      resetField('assigneeId', {
        defaultValue:
          annuityDetails.associatedTask?.assignedTo?.id ??
          UNASSIGNED_TASK_SENTINEL,
      });
      resetField('paidOnDate', {
        defaultValue: annuityDetails?.paidOn,
      });
    },
    [annuityDetails, resetField, taskId]
  );

  const initialAssetValuation = useInitialAssetValuation(entityId);
  const currentAssetValuation = useCurrentAssetValuation(entityId);
  const specificAssetValuation = useSpecificAssetValuation(
    annuityDetails?.associatedAssetValuation?.id
  );

  useEffect(
    function syncAssetDataToForm() {
      // this is the case for annuity payments that haven't yet been made,
      // so we prefill the assets data in the form with the most recent asset valuation.
      if (
        currentAssetValuation &&
        assetDisplayType === 'current' &&
        formAssets.length === 0
      ) {
        resetField('assets', {
          defaultValue: mapQueryAssetsToAssets(
            currentAssetValuation?.assetValues,
            { markAsConfirmed: false }
          ),
        });
        // this is the case for annuity payments that have already been, so we're prefilling the values
        // in the form with the data from the specific asset valuation that's associated with the
        // annuity payment
      } else if (specificAssetValuation && assetDisplayType !== 'previous') {
        resetField('assets', {
          defaultValue: mapQueryAssetsToAssets(
            specificAssetValuation?.assetValues,
            { markAsConfirmed: true }
          ),
        });
        setAssetDisplayType('previous');
      }
      if (!currentAssetValuation) return;
    },
    [
      specificAssetValuation,
      currentAssetValuation,
      assetDisplayType,
      formAssets.length,
      resetField,
    ]
  );

  async function handleTaskDetailsSubmit(values: AnnuityTaskDetailsForm) {
    if (isDirty) {
      await updateTask({
        variables: {
          input: {
            id: taskId,
            update: {
              dueAt: values.dueDate,
              assignedToID:
                values.assigneeId === UNASSIGNED_TASK_SENTINEL
                  ? null
                  : values.assigneeId,
              clearAssignedTo: values.assigneeId === UNASSIGNED_TASK_SENTINEL,
            },
          },
        },
        refetchQueries: [GetGratAnnuityPaymentTaskDetailsDocument],
        onCompleted: () => {
          showFeedback('Successfully updated task details', {
            variant: 'success',
          });
          onCompleteTask();
        },
        onError: createErrorFeedback('Failed to update task details'),
      });
    }
  }

  async function handleEditTaskSubmit(values: AnnuityTaskDetailsForm) {
    if (!annuityDetails?.gratTrust?.designerAccount?.id) {
      throw new Error('Attempting to edit task without annuity details');
    }

    if (!annuityDetails?.associatedAssetValuation?.id) {
      throw new Error('Attempting to edit task without associated valuation');
    }

    const update = getUpdateValuationInputFromFormValues(
      values,
      annuityDetails.associatedAssetValuation.id
    );
    const updateEntityInput: AugmentedUpdateEntityInput = {
      id: entityId,
      update: getEntityInputFromFormValues(values),
      updateGratTrust: {
        id: annuityDetails.gratTrust.id,
        update: {},
        updateAnnuities: [
          {
            id: annuityDetails.id,
            update: {
              paidOn: values.paidOnDate,
              failedAnnuityPaymentAmount: values.failedAnnuityPaymentAmount,
            },
          },
        ],
        updateDesignerAccount: {
          id: annuityDetails.gratTrust.designerAccount.id,
          update: {},
          updateValuations: [update],
        },
      },
    };

    await updateAnnuityAndValuation({
      variables: {
        updateEntityInput,
      },
      refetchQueries: [GetGratAnnuityPaymentTaskDetailsDocument],
      onCompleted: () => {
        onCompleteTask();
        showFeedback('Successfully updated annuity payment details', {
          variant: 'success',
        });
      },
      onError: createErrorFeedback('Failed to update annuity payment details'),
    });
  }

  async function handleFullTaskSubmit(values: AnnuityTaskDetailsForm) {
    // at least one asset value needs to have been updated. we can't guarantee that a user has to update/confirm every asset in the holdings,
    // because it's possible that they're making the annuity payment with just one asset
    const confirmedAsset = _.find(values.assets, 'confirmed');
    if (!confirmedAsset && !entityHasAssetsIntegration(entityData)) {
      setShowUnconfirmedAssetError(true);
      // setTimeout to allow the element to render before we focus on it
      setTimeout(() => unconfirmedAssetErrorRef?.current?.focus());
      return;
    }

    if (!annuityDetails?.gratTrust) {
      throw new Error('Attempting to edit task without annuity details');
    }

    const createValuationInput = getCreateValuationInputFromFormValues(
      values,
      annuityDetails.gratTrust?.designerAccount?.id ?? ''
    );

    const updateEntityInput: AugmentedUpdateEntityInput = {
      id: entityId,
      update: getEntityInputFromFormValues(values),
      updateGratTrust: {
        id: annuityDetails.gratTrust.id,
        update: {},
        setAnnuityToPaid: {
          annuityId: annuityDetails.id,
          failedAnnuityPaymentAmount: values.failedAnnuityPaymentAmount,
          // note that we always pass a createValuationInput here, even for addepar-linked accounts. we use the
          // date off of the valuation to determine the annuity payment date.
          assetValuation: createValuationInput,
        },
      },
    };

    await updateTaskAnnuityAndHoldings({
      variables: {
        taskId,
        updateEntityInput,
      },
      onCompleted: async () => {
        showFeedback('Successfully recorded the annuity payment', {
          variant: 'success',
        });
        await client.refetchQueries({
          updateCache(cache) {
            cache.evict({ fieldName: 'assetValuations' });
          },
        });

        onCompleteTask();
      },
    });
  }

  const taskIsCompleted = !!annuityDetails?.associatedTask?.completedAt;
  const taskIsReadyForCompletion =
    havePreviousAnnuitiesBeenPaid(annuityDetails);
  const taskIsCompletedAndNotEditing =
    taskIsCompleted && !isEditingCompletedAnnuity;
  const submitOnlyTaskForm = handleSubmit(handleTaskDetailsSubmit);
  const fullSubmit = handleSubmit(handleFullTaskSubmit);
  const editSubmit = handleSubmit(handleEditTaskSubmit);
  const formContentLoading = taskUpdateLoading || fullUpdateLoading;
  const disableAnnuityInputs =
    !taskIsReadyForCompletion ||
    formContentLoading ||
    taskIsCompletedAndNotEditing ||
    submittingTaskFormOnly;
  const annuityPaymentAmount =
    annuityDetails?.computedPaymentAmount ?? new Decimal(0);
  const savedFailedAnnuityPaymentAmount =
    annuityDetails?.failedAnnuityPaymentAmount;

  const previousValuation = currentAssetValuation?.value ?? new Decimal(0);
  const valueAfterPayment = getConfirmedPostPaymentValue(formAssets);
  const assetsToRenderInTable = (() => {
    if (
      taskIsCompletedAndNotEditing &&
      shownValuationSummaryType === ValuationSummaryType.PREVIOUS
    ) {
      if (!currentAssetValuation) return [];
      return mapQueryAssetsToAssets(currentAssetValuation.assetValues, {
        markAsConfirmed: true,
      });
    }

    return formAssets;
  })();

  // there are required inputs as part of the assets valuation subform that we don't want to
  // force the user to fill out before submitting just the task details
  const handleSubmitOnlyTaskForm = async () => {
    setSubmittingTaskFormOnly(true);
    await submitOnlyTaskForm();
    setSubmittingTaskFormOnly(false);
  };

  const onAssetModalSubmit: AssetDetailsModalProps['onSubmit'] = (
    value,
    opts
  ) => {
    handleAssetsModalSubmit(value, opts);
    setAssetModalOpen(false);
  };

  const handleEditAnnuityPaymentSubmit = (doMarkAsFailed: boolean) => {
    setEditPaymentAmountModalOpen(false);
    if (!doMarkAsFailed) return;
    if (!currentAssetValuation) {
      throw new Error(
        'Cannot edit annuity payment without a current valuation'
      );
    }
    const zeroedOutAssetsValue = mapQueryAssetsToAssets(
      currentAssetValuation.assetValues,
      {
        markAsConfirmed: true,
        setValuesToZero: true,
      }
    );
    setValue('assets', zeroedOutAssetsValue);
  };

  const navigateToGRAT = () => {
    return navigate(
      getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS, {
        householdId,
        entityId: entityId,
      })
    );
  };

  return (
    <FormProvider {...formMethods}>
      <AssetDetailsModal
        onSubmit={onAssetModalSubmit}
        asset={getSelectedAsset([], NEW_ASSET_ID)}
        isOpen={assetModalOpen}
        isNewAsset={true}
        onClose={() => setAssetModalOpen(false)}
      />
      <EditAnnuityPaymentAmountModal
        isOpen={editPaymentAmountModalOpen}
        control={control}
        onClose={(doMarkAsFailed) =>
          handleEditAnnuityPaymentSubmit(doMarkAsFailed)
        }
        resetField={resetField}
        paymentAmount={annuityPaymentAmount}
      />
      <Stack height="100%">
        <FullScreenTaskLayout
          LeftPaneContent={
            <FullScreenTaskLeftPaneLayout
              heading="Annuity payment"
              householdId={householdId}
              rightHeaderContent={
                annuityDetails && (
                  <Badge
                    variant={BadgeVariants.Gray}
                    display={`Payment ${annuityDetails.yearOfTerm} of ${annuityDetails.termDurationYears}`}
                    textTransform="none"
                  />
                )
              }
              body={
                <Stack spacing={3}>
                  <AnnuityDetailsSummary
                    annuity={annuityDetails}
                    annuityLoading={annuityLoading}
                  />
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={navigateToGRAT}
                  >
                    View this GRAT
                  </Button>
                  <Callout severity="info-high">
                    <Box mb={0.5}>
                      <strong>
                        Which assets should be used for annuity payments?
                      </strong>
                    </Box>
                    <Box>
                      The most tax efficient way to satisfy annuity payments is
                      to transfer securities in-kind. Use the lowest basis
                      securities for annuity payments to increase the tax
                      efficiency of assets transferred out of the grantor&apos;s
                      estate if the GRAT is successful.
                    </Box>
                  </Callout>
                </Stack>
              }
              control={control}
              taskControlsDisabled={taskIsCompleted}
              taskDescription="Specify an internal employee responsible for completing the annuity payment"
            />
          }
          RightPaneContent={
            <Stack spacing={2}>
              <RibbonCard heading="Confirm payment details">
                {/*
                  we're not rendering this component unless we have the task data loaded because we don't want to have this alert flash
                  while the form is loading
                */}
                {annuityDetails && !taskIsReadyForCompletion && (
                  <Callout severity="error" sx={{ mb: 3 }}>
                    This annuity can only be marked as paid once the previous
                    annuity or annuities have been marked as paid.
                  </Callout>
                )}
                <Card variant="filled-callout" sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <OversizedMetricItem
                      title="Payment amount"
                      value={formatCurrency(
                        failedGRATPaymentAmount ||
                          savedFailedAnnuityPaymentAmount ||
                          annuityPaymentAmount
                      )}
                      action={
                        <EditButton
                          disabled={taskIsCompleted}
                          onClick={() => setEditPaymentAmountModalOpen(true)}
                          aria-label={`Edit payment amount`}
                        />
                      }
                    />
                    <FundingDateInput
                      control={control}
                      label="Date of payment"
                      disabled={disableAnnuityInputs}
                      fieldName={`paidOnDate`}
                      minDate={
                        initialAssetValuation?.effectiveDate
                          ? getNextDay(initialAssetValuation.effectiveDate)
                          : new Date()
                      }
                    />
                  </Stack>
                </Card>
              </RibbonCard>
              {/* keeping a check on the presence of entityData here so that this big card doesn't flash and disappear while we're waiting for data to load */}
              {entityData && !entityHasAssetsIntegration(entityData) && (
                <RibbonCard
                  heading="Update holdings to reflect payment"
                  rightHeaderContent={
                    taskIsCompletedAndNotEditing ? (
                      <Box width={350}>
                        <ButtonGroup
                          label=""
                          value={shownValuationSummaryType}
                          options={valuationSummaryTypeOptions}
                          onChange={(_e, value) =>
                            setShownValuationSummaryType(value)
                          }
                        />
                      </Box>
                    ) : (
                      <Typography variant="subtitle2" textAlign="right">
                        Holdings should reflect the value of the asset(s) after
                        the annuity payment has been made
                      </Typography>
                    )
                  }
                  footer={
                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                      bgcolor={COLORS.GRAY[200]}
                      p={RIBBON_CARD_DEFAULT_PADDING}
                    >
                      <Stack direction="row" spacing={2}>
                        <OversizedMetricItem
                          sx={{ textAlign: 'right' }}
                          title={`Previous valuation (${formatDateToMonDDYYYY(
                            currentAssetValuation?.effectiveDate ?? new Date()
                          )})`}
                          value={formatCurrency(previousValuation)}
                        />
                        <ArrowRightChunkyIcon
                          fill="white"
                          sx={{
                            width: 56,
                          }}
                          size={56}
                        />
                        <OversizedMetricItem
                          sx={{ textAlign: 'right' }}
                          title={`Value after payment`}
                          value={
                            valueAfterPayment
                              ? formatCurrency(valueAfterPayment)
                              : EMPTY_CONTENT_HYPHEN
                          }
                        />
                      </Stack>
                    </Stack>
                  }
                >
                  <Stack spacing={6}>
                    <Box>
                      <AssetFormTable
                        assetList={assetsToRenderInTable}
                        removeByAsset={removeByAsset}
                        updateByAsset={updateByAsset}
                        loading={
                          !initialAssetValuation && !currentAssetValuation
                        }
                        disabled={
                          disableAnnuityInputs || !!failedGRATPaymentAmount
                        }
                        isInitialValuation={false}
                      />
                      <Button
                        fullWidth
                        whiteSpace="nowrap"
                        startIcon={PlusIcon}
                        sx={{ mt: 2 }}
                        size="sm"
                        variant="secondary"
                        disabled={
                          disableAnnuityInputs || !!failedGRATPaymentAmount
                        }
                        onClick={() => setAssetModalOpen(!assetModalOpen)}
                      >
                        Add additional asset
                      </Button>
                      {showUnconfirmedAssetError && (
                        <Callout
                          severity="error"
                          ref={unconfirmedAssetErrorRef}
                          sx={{ mt: 2 }}
                          tabIndex={-1}
                        >
                          Please update the holdings to reflect the annuity
                          payment.
                        </Callout>
                      )}
                    </Box>
                    <Stack alignItems="center">
                      <Box width="80%">
                        <DocumentUploaderWithList
                          uploaderLabel="Upload supporting documentation (optional)"
                          listDocumentsLike={{
                            hasEntityWith: [{ id: entityId }],
                            type: DocumentType.AssetValuation,
                          }}
                          errorMessage={createDocumentErrorMessage || undefined}
                          getCreateDocumentInput={(file) => ({
                            householdID: householdId,
                            name: file.fileName,
                            entityID: entityId,
                            type: DocumentType.AssetValuation,
                            fileID: file.fileId,
                          })}
                          onDocumentUploadSuccess={() =>
                            setCreateDocumentErrorMessage(null)
                          }
                          onDocumentUploadError={() =>
                            setCreateDocumentErrorMessage(
                              `We weren't able to complete your document generation process. Please try again.`
                            )
                          }
                          householdId={householdId}
                        />
                      </Box>
                    </Stack>
                  </Stack>
                </RibbonCard>
              )}
            </Stack>
          }
          Footer={
            <Footer
              leftAction={
                <Button
                  disabled={formContentLoading}
                  onClick={onClose}
                  variant="secondary"
                  size="sm"
                >
                  Exit without saving
                </Button>
              }
              rightAction={
                <Stack direction="row" spacing={3}>
                  {!taskIsCompleted && (
                    <>
                      <Button
                        disabled={formContentLoading || taskIsCompleted}
                        onClick={handleSubmitOnlyTaskForm}
                        variant="transparent"
                        size="sm"
                      >
                        Save changes
                      </Button>
                      <Button
                        disabled={
                          formContentLoading || !taskIsReadyForCompletion
                        }
                        onClick={fullSubmit}
                        variant="primary"
                        size="sm"
                      >
                        Mark as paid
                      </Button>
                    </>
                  )}
                  {taskIsCompleted && !isEditingCompletedAnnuity && (
                    <Button
                      disabled={formContentLoading}
                      onClick={() => setIsEditingCompletedAnnuity(true)}
                      variant="transparent"
                      size="md"
                    >
                      Edit annuity details
                    </Button>
                  )}
                  {isEditingCompletedAnnuity && (
                    <Button
                      disabled={formContentLoading}
                      onClick={editSubmit}
                      variant="primary"
                      size="md"
                    >
                      Save edited annuity
                    </Button>
                  )}
                </Stack>
              }
            />
          }
        />
      </Stack>
    </FormProvider>
  );
}

export function GRATAnnuityPaymentTask(
  props: Omit<GRATAnnuityPaymentTaskProps, 'householdId'>
) {
  const { householdId } = useHouseholdDetailsContext();

  if (!householdId) {
    return null;
  }

  return <GRATAnnuityPaymentTaskInner {...props} householdId={householdId} />;
}
