import { compact } from 'lodash';
import { Control, FieldValues } from 'react-hook-form';

import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import {
  FormAwareSelectInput,
  FormAwareSelectInputProps,
} from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import { EntityType } from '../../types/EntityType';
import { getEntityTypeFromSubtype } from '../../utils/getEntityTypeFromSubtype';
import { BeneficiaryDropdown_HouseholdFragment } from './graphql/BeneficiaryDropdown.generated';

export function getOptionsFromData(
  data: BeneficiaryDropdown_HouseholdFragment | null,
  selectedIds: string[],
  opts?: {
    filterEntityTypes?: EntityType[];
  }
): SelectInputProps<string>['options'] {
  const possibleBeneficiaries = data?.possibleBeneficiariesV2;
  const possibleClientOptions =
    possibleBeneficiaries?.clients.map((cp) => {
      return {
        display: cp.legalName,
        value: cp.id,
      };
    }) ?? [];

  const possibleEntityOptions = compact(
    possibleBeneficiaries?.entities.map((e) => {
      if (!e.subtype.__typename) {
        return null;
      }

      const entityType: EntityType = getEntityTypeFromSubtype(
        e.subtype.__typename
      );

      if (
        opts?.filterEntityTypes &&
        opts.filterEntityTypes.includes(entityType)
      ) {
        return null;
      }

      return {
        display: e.subtype.displayName,
        value: e.id,
      };
    }) ?? []
  );

  const possibleOrganizationOptions =
    possibleBeneficiaries?.organizations.map((o) => {
      return {
        display: o.name,
        value: o.id,
      };
    }) ?? [];

  const possibleOptions = compact([
    (() =>
      possibleClientOptions.length
        ? {
            component: <SelectItemGroupLabel label="Individuals" />,
            type: 'component' as const,
          }
        : null)(),

    ...possibleClientOptions,
    (() =>
      possibleOrganizationOptions.length
        ? {
            component: <SelectItemGroupLabel label="Organizations" />,

            type: 'component' as const,
          }
        : null)(),

    ...possibleOrganizationOptions,
    (() =>
      possibleEntityOptions.length
        ? {
            component: <SelectItemGroupLabel label="Entities" />,
            type: 'component' as const,
          }
        : null)(),

    ...possibleEntityOptions,
  ]);

  return getBeneficiaryOptionsWithSelectedDisabled({
    possibleOptions,
    selectedIds,
  });
}

function getBeneficiaryOptionsWithSelectedDisabled({
  possibleOptions,
  selectedIds,
}: {
  possibleOptions: ReturnType<typeof getOptionsFromData>;
  selectedIds: string[];
}) {
  return possibleOptions.map((option) => {
    if (option.type === 'component') return option;
    return {
      ...option,
      disabled: selectedIds.includes(option.value),
    };
  });
}

type BeneficiaryDropdownMultipleFormShape = FieldValues & {
  beneficiaries?: { beneficiaryId: string }[];
};

export interface BeneficiaryDropdownMultipleProps<
  FormShape extends BeneficiaryDropdownMultipleFormShape,
> {
  control: Control<FormShape>;
  namespace: FieldNameFromFormShape<FormShape>;
  options: SelectInputProps['options'];
  required: boolean;
  disabled?: boolean;
}

type BeneficiaryDropdownSingleFormShape = FieldValues & {
  beneficiaryId?: string;
};

export interface BeneficiaryDropdownSingleProps<
  FormShape extends BeneficiaryDropdownSingleFormShape,
> extends Partial<FormAwareSelectInputProps<FormShape>> {
  control: Control<FormShape>;
  namespace: FieldNameFromFormShape<FormShape>;
  options: SelectInputProps['options'];
}

export function BeneficiaryDropdownSingle<
  FormShape extends BeneficiaryDropdownSingleFormShape,
>({
  control,
  namespace,
  options,
  ...rest
}: BeneficiaryDropdownSingleProps<FormShape>) {
  return (
    <FormAwareSelectInput<FormShape>
      control={control}
      fieldName={`${namespace}.beneficiary.beneficiaryId`}
      label="Beneficiary"
      options={options}
      {...rest}
    />
  );
}
