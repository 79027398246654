import { SuggestedValue } from '@/modules/aiSuggestions/AISuggestedValue/AISuggestedValue.types';
import { BUSINESS_ENTITY_TAX_STATUS_ITEMS } from '@/modules/entities/BusinessEntitiesTaxStatusSubform/BusinessEntitiesTaxStatusSubform.types';
import {
  GST_EXEMPT,
  GST_NON_EXEMPT,
  GST_STATUS_ITEMS,
  MIXED_GST,
} from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.constants';
import { GSTStatus } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.types';
import {
  AiSuggestionBusinessEntityTaxStatus,
  AiSuggestionEntityGstStatus,
  BusinessEntityTaxStatus,
} from '@/types/schema';

// Map of the backend gst enum values to the TaxStatusSubform's GSTStatus values
const GST_STATUS_MAP: Record<AiSuggestionEntityGstStatus, GSTStatus> = {
  [AiSuggestionEntityGstStatus.GstExempt]: GST_EXEMPT,
  [AiSuggestionEntityGstStatus.GstNonExempt]: GST_NON_EXEMPT,
  [AiSuggestionEntityGstStatus.MixedGst]: MIXED_GST,
};

export function gstStatusMapper(
  suggested: AiSuggestionEntityGstStatus | undefined | null
): SuggestedValue {
  if (!suggested) return { value: '', display: '' };

  const gstStatus = GST_STATUS_MAP[suggested];
  if (!gstStatus) return { value: '', display: '' };

  const item = GST_STATUS_ITEMS.find((item) => item.value === gstStatus);
  if (!item) return { value: '', display: '' };

  return { value: gstStatus, display: item.display };
}

// Map of the backend business entity tax status enum values to the BusinessEntitiesTaxStatusSubform's BusinessEntityTaxStatus values
const BUSINESS_ENTITY_TAX_STATUS_MAP: Record<
  AiSuggestionBusinessEntityTaxStatus,
  BusinessEntityTaxStatus
> = {
  [AiSuggestionBusinessEntityTaxStatus.Corporation]:
    BusinessEntityTaxStatus.Corporation,
  [AiSuggestionBusinessEntityTaxStatus.DisregardedEntity]:
    BusinessEntityTaxStatus.DisregardedEntity,
  [AiSuggestionBusinessEntityTaxStatus.NonTaxableEntity]:
    BusinessEntityTaxStatus.NonTaxableEntity,
  [AiSuggestionBusinessEntityTaxStatus.Partnership]:
    BusinessEntityTaxStatus.Partnership,
};

export function businessEntityTaxStatusMapper(
  suggested: AiSuggestionBusinessEntityTaxStatus | undefined | null
): SuggestedValue {
  if (!suggested) return { value: '', display: '' };

  const taxStatus = BUSINESS_ENTITY_TAX_STATUS_MAP[suggested];
  if (!taxStatus) return { value: '', display: '' };

  const item = BUSINESS_ENTITY_TAX_STATUS_ITEMS.find(
    (item) => item.value === taxStatus
  );
  if (!item) return { value: '', display: '' };

  return { value: taxStatus, display: item.display };
}
