// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
export type NonTrustCharitableEntityDetailsCard_DonorPrincipalFragment = { __typename?: 'Principal', id: string, individual?: { __typename?: 'ClientProfile', id: string, legalName: string } | null };

export type NonTrustCharitableEntityDetailsCard_ControllingPartyFragment = { __typename?: 'ControllingParty', id: string, individual?: { __typename?: 'ClientProfile', id: string, legalName: string } | null, organization?: { __typename?: 'ClientOrganization', id: string, name: string } | null };

export const NonTrustCharitableEntityDetailsCard_DonorPrincipalFragmentDoc = gql`
    fragment NonTrustCharitableEntityDetailsCard_DonorPrincipal on Principal {
  id
  individual {
    id
    legalName
  }
}
    `;
export const NonTrustCharitableEntityDetailsCard_ControllingPartyFragmentDoc = gql`
    fragment NonTrustCharitableEntityDetailsCard_ControllingParty on ControllingParty {
  id
  individual {
    id
    legalName
  }
  organization {
    id
    name
  }
}
    `;
export const Operations = {
  Fragment: {
    NonTrustCharitableEntityDetailsCard_DonorPrincipal: 'NonTrustCharitableEntityDetailsCard_DonorPrincipal' as const,
    NonTrustCharitableEntityDetailsCard_ControllingParty: 'NonTrustCharitableEntityDetailsCard_ControllingParty' as const
  }
}