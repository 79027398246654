// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { SummaryBeneficiaryv2FragmentDoc } from '../../../graphql/SummaryBeneficiary.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BeneficiarySummariesv2QueryVariables = Types.Exact<{
  grantorClientProfileId: Types.Scalars['ID']['input'];
  inputs: Array<Types.BeneficiarySummaryQueryv2> | Types.BeneficiarySummaryQueryv2;
}>;


export type BeneficiarySummariesv2Query = { __typename?: 'Query', beneficiarySummariesv2: Array<{ __typename?: 'SummaryBeneficiaryv2', id: string, displayName: string, kind: Types.SummaryBeneficiaryKindv2, badgeText?: string | null, additionalContext: Array<string | null>, ownershipPercentage: string, displayType: string } | null> };


export const BeneficiarySummariesv2Document = gql`
    query BeneficiarySummariesv2($grantorClientProfileId: ID!, $inputs: [BeneficiarySummaryQueryv2!]!) {
  beneficiarySummariesv2(
    grantorClientProfileId: $grantorClientProfileId
    inputs: $inputs
  ) {
    ...SummaryBeneficiaryv2
  }
}
    ${SummaryBeneficiaryv2FragmentDoc}`;

/**
 * __useBeneficiarySummariesv2Query__
 *
 * To run a query within a React component, call `useBeneficiarySummariesv2Query` and pass it any options that fit your needs.
 * When your component renders, `useBeneficiarySummariesv2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBeneficiarySummariesv2Query({
 *   variables: {
 *      grantorClientProfileId: // value for 'grantorClientProfileId'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useBeneficiarySummariesv2Query(baseOptions: Apollo.QueryHookOptions<BeneficiarySummariesv2Query, BeneficiarySummariesv2QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BeneficiarySummariesv2Query, BeneficiarySummariesv2QueryVariables>(BeneficiarySummariesv2Document, options);
      }
export function useBeneficiarySummariesv2LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BeneficiarySummariesv2Query, BeneficiarySummariesv2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BeneficiarySummariesv2Query, BeneficiarySummariesv2QueryVariables>(BeneficiarySummariesv2Document, options);
        }
export function useBeneficiarySummariesv2SuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BeneficiarySummariesv2Query, BeneficiarySummariesv2QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BeneficiarySummariesv2Query, BeneficiarySummariesv2QueryVariables>(BeneficiarySummariesv2Document, options);
        }
export type BeneficiarySummariesv2QueryHookResult = ReturnType<typeof useBeneficiarySummariesv2Query>;
export type BeneficiarySummariesv2LazyQueryHookResult = ReturnType<typeof useBeneficiarySummariesv2LazyQuery>;
export type BeneficiarySummariesv2SuspenseQueryHookResult = ReturnType<typeof useBeneficiarySummariesv2SuspenseQuery>;
export type BeneficiarySummariesv2QueryResult = Apollo.QueryResult<BeneficiarySummariesv2Query, BeneficiarySummariesv2QueryVariables>;
export const Operations = {
  Query: {
    BeneficiarySummariesv2: 'BeneficiarySummariesv2' as const
  }
}