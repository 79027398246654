import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useCreateDocumentSummaryMutation } from '@/modules/documents/graphql/CreateSummary.generated';
import { DocumentDetailsDocument } from '@/modules/documents/graphql/DocumentDetails.generated';
import { EntityDetailsDocument } from '@/modules/entities/details/graphql/EntityDetailPage.generated';

interface UseCreateSummaryInput {
  documentId: string;
}

export function useCreateSummary({ documentId }: UseCreateSummaryInput) {
  const { showFeedback, createSuccessFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [createSummary] = useCreateDocumentSummaryMutation({
    refetchQueries: [EntityDetailsDocument, DocumentDetailsDocument],
    onCompleted: createSuccessFeedback('Summary saved successfully'),
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError('Failed to create summary for document', error, {
        documentId,
      });
    },
  });

  return { createSummary };
}
