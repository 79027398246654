import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';

import { entityKindToDisplayName } from '../../EntityForm/utils';
import { SUPPORTED_HYPOTHETICAL_ENTITY_KINDS } from '../draftEntities.constants';

export function useDraftEntityOptions(): SelectInputOption<string>[] {
  return useMemo(() => {
    return SUPPORTED_HYPOTHETICAL_ENTITY_KINDS.map((kind) => {
      return {
        display: entityKindToDisplayName(kind),
        value: kind,
      };
    });
  }, []);
}
