// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveSummaryMutationVariables = Types.Exact<{
  updateDocumentSummaryId: Types.Scalars['ID']['input'];
  input: Types.UpdateDocumentSummaryInput;
}>;


export type SaveSummaryMutation = { __typename?: 'Mutation', updateDocumentSummary: { __typename?: 'DocumentSummary', id: string, summary: string, summaryFormat?: Types.DocumentSummarySummaryFormat | null, entryMode?: Types.DocumentSummaryEntryMode | null } };


export const SaveSummaryDocument = gql`
    mutation SaveSummary($updateDocumentSummaryId: ID!, $input: UpdateDocumentSummaryInput!) {
  updateDocumentSummary(id: $updateDocumentSummaryId, input: $input) {
    id
    summary
    summaryFormat
    entryMode
  }
}
    `;
export type SaveSummaryMutationFn = Apollo.MutationFunction<SaveSummaryMutation, SaveSummaryMutationVariables>;

/**
 * __useSaveSummaryMutation__
 *
 * To run a mutation, you first call `useSaveSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSummaryMutation, { data, loading, error }] = useSaveSummaryMutation({
 *   variables: {
 *      updateDocumentSummaryId: // value for 'updateDocumentSummaryId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveSummaryMutation(baseOptions?: Apollo.MutationHookOptions<SaveSummaryMutation, SaveSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSummaryMutation, SaveSummaryMutationVariables>(SaveSummaryDocument, options);
      }
export type SaveSummaryMutationHookResult = ReturnType<typeof useSaveSummaryMutation>;
export type SaveSummaryMutationResult = Apollo.MutationResult<SaveSummaryMutation>;
export type SaveSummaryMutationOptions = Apollo.BaseMutationOptions<SaveSummaryMutation, SaveSummaryMutationVariables>;
export const Operations = {
  Mutation: {
    SaveSummary: 'SaveSummary' as const
  }
}