import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage, usePrevious } from 'react-use';

import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { LOCAL_STORAGE_BOOLEAN_SERIALIZER } from '@/utils/localStorageSerializers';

export enum EntityMapCoachmark {
  TopLineEstateMetrics = 'TopLineEstateMetrics',
  LifetimeBeneficiaries = 'LifetimeBeneficiaries',
}

export function useEntityMapCoachmark(): {
  coachmark: EntityMapCoachmark | null;
  onDismissCoachmark: (coachmarkToDismiss: EntityMapCoachmark) => void;
} {
  const [
    hasDismissedTopLineEstateMetricsCoachmark = false,
    setTopLineEstateMetricsCoachmarkDimissed,
  ] = useLocalStorage<boolean>(
    LOCAL_STORAGE_KEYS.COACHMARK_ENTITY_MAP_TOP_LINE_ESTATE_METRICS,
    false,
    LOCAL_STORAGE_BOOLEAN_SERIALIZER
  );
  const [
    hasDismissedLifetimeBeneficiariesCoachmark = false,
    setLifetimeBeneficiariesCoachmarkDimissed,
  ] = useLocalStorage<boolean>(
    LOCAL_STORAGE_KEYS.COACHMARK_ENTITY_MAP_ADD_BENEFICIARIES,
    false,
    LOCAL_STORAGE_BOOLEAN_SERIALIZER
  );

  let initialCoachmark: EntityMapCoachmark | null = null;
  if (!hasDismissedTopLineEstateMetricsCoachmark) {
    initialCoachmark = EntityMapCoachmark.TopLineEstateMetrics;
  } else if (!hasDismissedLifetimeBeneficiariesCoachmark) {
    initialCoachmark = EntityMapCoachmark.LifetimeBeneficiaries;
  }

  const [currentCoachmark, setCurrentCoachmark] =
    useState<EntityMapCoachmark | null>(initialCoachmark);

  const previousCoachmark = usePrevious(currentCoachmark);

  const onDismissCoachmark = useCallback(
    (coachmarkInput: EntityMapCoachmark) => {
      if (coachmarkInput === EntityMapCoachmark.TopLineEstateMetrics) {
        setTopLineEstateMetricsCoachmarkDimissed(true);
      } else if (coachmarkInput === EntityMapCoachmark.LifetimeBeneficiaries) {
        setLifetimeBeneficiariesCoachmarkDimissed(true);
      }
    },
    [
      setLifetimeBeneficiariesCoachmarkDimissed,
      setTopLineEstateMetricsCoachmarkDimissed,
    ]
  );

  useEffect(() => {
    let newCoachmark: EntityMapCoachmark | null = null;
    if (!hasDismissedTopLineEstateMetricsCoachmark) {
      newCoachmark = EntityMapCoachmark.TopLineEstateMetrics;
    } else if (!hasDismissedLifetimeBeneficiariesCoachmark) {
      newCoachmark = EntityMapCoachmark.LifetimeBeneficiaries;
    }

    if (newCoachmark !== previousCoachmark) {
      setCurrentCoachmark(newCoachmark);
    }
  }, [
    hasDismissedLifetimeBeneficiariesCoachmark,
    hasDismissedTopLineEstateMetricsCoachmark,
    previousCoachmark,
  ]);

  return {
    coachmark: currentCoachmark,
    onDismissCoachmark,
  };
}
