import { useCallback } from 'react';

import { compose } from '@/utils/reducerUtils';

import { rebuildFamilyTreeGraph } from '../state/familyTreeGraph';
import { loadFamilyTreeQuery } from '../state/loadNetworkQuery';
import { FamilyTreeActionFn, InitializeAction } from '../types';
import { useReadFamilyTreeCache } from '../utils/cacheRead.util';

export function useInitializeAction() {
  const readCache = useReadFamilyTreeCache();

  return useCallback<FamilyTreeActionFn<InitializeAction>>(
    (state, _action, queueEffect) => {
      queueEffect({ type: 'queryFamilyTreeEffect' });

      const cachedValue = readCache(state);

      if (cachedValue) {
        return compose(
          loadFamilyTreeQuery(cachedValue),
          rebuildFamilyTreeGraph()
        )(state);
      }

      return state;
    },
    [readCache]
  );
}
