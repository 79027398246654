import { Button, ButtonProps } from '@/components/form/baseInputs/Button';

export const CONTROL_BUTTON_ICON_SIZE = 20;

export type ControlButtonProps = Partial<ButtonProps>;
export const ControlButton = ({
  size = 'sm',
  variant = 'primary',
  square = true,
  iconProps: externalIconProps,
  ...props
}: ControlButtonProps) => {
  const iconProps =
    props.startIcon || props.endIcon
      ? { size: CONTROL_BUTTON_ICON_SIZE, ...externalIconProps }
      : externalIconProps;

  return (
    <Button
      size={size}
      variant={variant}
      square={square}
      iconProps={iconProps}
      {...props}
    />
  );
};
