import { Box, Stack, Typography } from '@mui/material';
import { addDays, formatDistanceStrict } from 'date-fns';
import Decimal from 'decimal.js';
import _ from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  DisplayTable,
  DisplayTableColumn,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { RelativeFormattedDate } from '@/components/typography/RelativeFormattedDate';
import { CurrencyUSD } from '@/graphql/scalars';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { GratAnnuityFragment } from '@/modules/entities/gratTrusts/graphql/GratTrust.generated';
import { GRAT_ANNUITIES_SUPPORTED_DECIMAL_PLACES } from '@/modules/gratAnnuities/gratAnnuities.constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import * as diagnostics from '@/utils/diagnostics';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { formatPercent } from '@/utils/formatting/percent';

const annuitiesDetailTableColumns: DisplayTableColumn[] = [
  {
    headerName: 'Status',
  },
  {
    headerName: 'Payment window',
  },
  {
    headerName: 'Payment amount',
    align: 'right',
  },
  {
    headerName: '% of initial funding value',
    align: 'right',
  },
  {
    headerName: 'Remaining payments',
    align: 'right',
  },
  {
    // this is a placeholder for the action column
    headerName: '',
    width: 30,
  },
];

interface AugmentedAnnuity
  extends Pick<
    GratAnnuityFragment,
    | 'id'
    | 'associatedTask'
    | 'associatedAssetValuation'
    | 'computedPaymentAmount'
    | 'initialFundingValuePercent'
    | 'yearOfTerm'
    | 'termDurationYears'
    | 'paymentWindowStartDate'
    | 'paymentWindowEndDate'
    | 'paidOn'
  > {
  id: string;
  remainingPaymentsAmount: Decimal;
  initialFundingValuePercent: Decimal;
  actualOrEstimatedPaymentAmount: Decimal;
  badgeText?: string;
  showAsCancelled?: boolean;
}

interface AnnuityDetailsTableProps {
  initialFundingValue: CurrencyUSD;
  annuities: GratAnnuityFragment[];
}

function AnnuityStatusCell({ annuity }: { annuity: AugmentedAnnuity }) {
  // inside of 30 days, we want to show the number of remaining days until
  // the task is due.
  const dateToShowCountdown = addDays(new Date(), 30);
  const dueDate = annuity.associatedTask?.dueAt;

  const displayNameRow = (
    <Typography variant="subtitle2" component="div">
      {annuity.associatedTask?.completedBy?.displayName}
    </Typography>
  );

  const cellContent = (() => {
    if (annuity.showAsCancelled) {
      return (
        <>
          <Typography
            sx={(theme) => ({ color: theme.typography.body1.color })}
            variant="h5"
            component="div"
          >
            Cancelled
          </Typography>
        </>
      );
    } else if (annuity.paidOn) {
      return (
        <>
          <RelativeFormattedDate severity="success">
            Paid {formatDateToMonDDYYYY(annuity.paidOn)}
          </RelativeFormattedDate>
          {displayNameRow}
        </>
      );
    } else if (dueDate && dueDate < dateToShowCountdown) {
      const severity = dueDate <= new Date() ? 'error' : 'warning';
      return (
        <>
          <RelativeFormattedDate severity={severity}>
            Due {formatDistanceStrict(dueDate, new Date(), { addSuffix: true })}
          </RelativeFormattedDate>
          {displayNameRow}
        </>
      );
    }

    return (
      <>
        <RelativeFormattedDate severity="standard">
          Due on{' '}
          {dueDate ? formatDateToMonDDYYYY(dueDate) : EMPTY_CONTENT_HYPHEN}
        </RelativeFormattedDate>
        {displayNameRow}
      </>
    );
  })();

  return (
    <StyledTableCell>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>{cellContent}</Box>
        {annuity.badgeText && (
          <Badge variant={BadgeVariants.Yellow} display={annuity.badgeText} />
        )}
      </Stack>
    </StyledTableCell>
  );
}

export function augmentAnnuitiesWithRemainingPayments(
  totalFundingValue: CurrencyUSD,
  annuities: GratAnnuityFragment[]
): AugmentedAnnuity[] {
  // first, sort the annuities into a consistent order
  const sortedAnnuities = annuities.slice().sort((a, b) => {
    return a.paymentWindowStartDate > b.paymentWindowStartDate ? 1 : -1;
  });
  const sumOfAllPayments = annuities.reduce<Decimal>((sum, annuity) => {
    return sum.add(annuity.computedPaymentAmount ?? new Decimal(0));
  }, new Decimal(0));

  let paymentsMadeThusFar = new Decimal(0);
  let isFailedAnnuityScenario = false;

  return sortedAnnuities.map((annuity) => {
    paymentsMadeThusFar = paymentsMadeThusFar.add(
      annuity.computedPaymentAmount ?? new Decimal(0)
    );

    const sharedProperties = _.pick(annuity, [
      'id',
      'paidOn',
      'paymentWindowStartDate',
      'initialFundingValuePercent',
      'computedPaymentAmount',
      'paymentWindowEndDate',
      'associatedTask',
      'associatedAssetValuation',
      'termDurationYears',
      'yearOfTerm',
    ]);

    // this block will return only for the one annuity payment that was unable to be
    // paid and actually caused the GRAT to fail
    if (
      annuity.failedAnnuityPaymentAmount &&
      !annuity.failedAnnuityPaymentAmount.isZero()
    ) {
      isFailedAnnuityScenario = true;
      const initialFundingValuePercent = annuity.failedAnnuityPaymentAmount
        .dividedBy(totalFundingValue)
        .times(100);

      return {
        ...sharedProperties,
        initialFundingValuePercent: initialFundingValuePercent,
        actualOrEstimatedPaymentAmount: annuity.actualPaymentAmount,
        remainingPaymentsAmount: new Decimal(0),
        badgeText: 'Insufficient assets',
      };

      // this else block will return for all payments subsequent to the failed one that weren't able to be paid
    } else if (isFailedAnnuityScenario) {
      return {
        ...sharedProperties,
        initialFundingValuePercent: new Decimal(0),
        actualOrEstimatedPaymentAmount: annuity.actualPaymentAmount,
        remainingPaymentsAmount: new Decimal(0),
        showAsCancelled: true,
      };
    }

    // this block will return for successful or future payments
    return {
      ...sharedProperties,
      actualOrEstimatedPaymentAmount:
        annuity.computedPaymentAmount || new Decimal(0),
      initialFundingValuePercent:
        annuity.initialFundingValuePercent ?? new Decimal(0),
      remainingPaymentsAmount: sumOfAllPayments.minus(paymentsMadeThusFar),
    };
  });
}

export function AnnuityDetailsTable({
  initialFundingValue,
  annuities,
}: AnnuityDetailsTableProps) {
  const canViewTasks = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_VIEW_TASKS
  );
  const navigate = useNavigate();
  const { showFeedback } = useFeedback();
  const augmentedAnnuities = useMemo(
    () => augmentAnnuitiesWithRemainingPayments(initialFundingValue, annuities),
    [initialFundingValue, annuities]
  );

  function handleRowClick(annuityId: string, taskId?: string) {
    if (!taskId) {
      diagnostics.error(
        'annuity with missing taskId',
        new Error('invalid annuity has no associated task'),
        {
          annuityId,
        }
      );
      showFeedback(
        "There's been an error opening your annuity. Please refresh the page to try again."
      );
      return;
    }

    const annuityTaskRoute = getCompletePathFromRouteKey(
      'TASK_STANDALONE',
      {
        taskId,
      },
      {
        postTaskRedirect: 'back',
      }
    );
    navigate(annuityTaskRoute);
  }

  return (
    <DisplayTable columns={annuitiesDetailTableColumns}>
      {augmentedAnnuities.map((augmentedAnnuity) => {
        return (
          <StyledTableRow
            disabled={augmentedAnnuity.showAsCancelled || !canViewTasks}
            key={augmentedAnnuity.id}
            onClick={() => {
              !augmentedAnnuity.showAsCancelled &&
                handleRowClick(
                  augmentedAnnuity.id,
                  augmentedAnnuity.associatedTask?.id
                );
            }}
          >
            <AnnuityStatusCell annuity={augmentedAnnuity} />
            <StyledTableCell>
              <Typography variant="body1" component="span">
                {formatDateToMonDDYYYY(
                  augmentedAnnuity?.paymentWindowStartDate
                )}
                &nbsp;-&nbsp;
                {formatDateToMonDDYYYY(augmentedAnnuity?.paymentWindowEndDate)}
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography variant="body1" component="span">
                {formatCurrency(
                  augmentedAnnuity.actualOrEstimatedPaymentAmount
                )}
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography variant="body1" component="span">
                {formatPercent(
                  augmentedAnnuity.initialFundingValuePercent,
                  GRAT_ANNUITIES_SUPPORTED_DECIMAL_PLACES
                )}
                %
              </Typography>
            </StyledTableCell>
            <StyledTableCell align="right">
              <Typography variant="body1" component="span">
                {formatCurrency(augmentedAnnuity.remainingPaymentsAmount)}
              </Typography>
            </StyledTableCell>
            <StyledTableCell>
              {!augmentedAnnuity.showAsCancelled && (
                <ChevronRightIcon size={20} />
              )}
            </StyledTableCell>
          </StyledTableRow>
        );
      })}
    </DisplayTable>
  );
}
