import { SubForm } from '@/types/subform';

import {
  defaultValues,
  Fields,
  NamespaceType,
  TRUST_DETAILS_SUBFORM_NAMESPACE,
  TrustDetailsSubformProps,
  TrustDetailsSubformType,
  VariantType,
} from './TrustDetailsSubform.types';
import { UI } from './TrustDetailsSubform.UI';

export class TrustDetailsSubform
  implements
    SubForm<
      TrustDetailsSubformType,
      Fields,
      NamespaceType,
      VariantType,
      {
        isCharitableEntity: boolean;
      }
    >
{
  FormComponent: (props: TrustDetailsSubformProps) => JSX.Element;
  defaultValues: TrustDetailsSubformType;
  namespace: NamespaceType;

  constructor() {
    this.FormComponent = UI;
    this.defaultValues = defaultValues;
    this.namespace = TRUST_DETAILS_SUBFORM_NAMESPACE;
  }
}
