import { Box, Stack, Typography, useTheme } from '@mui/material';

import { RibbonHorizontal } from '@/components/display/Ribbon/RibbonHorizontal';
import { Dataflow01Icon } from '@/components/icons/Dataflow01Icon';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { ASSET_CARD_WIDTH_PX } from '@/modules/assets/AssetDetailCard/AssetDetailCard';
import { useCurrentAssetValuation } from '@/modules/assetValuation/useAssetValuation';
import { GratTrustFragment } from '@/modules/entities/gratTrusts/graphql/GratTrust.generated';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

interface Props {
  grat: GratTrustFragment;
  footer?: React.ReactNode;
  entityId: string;
}

export function GratProposalDetailsCard({ grat, footer, entityId }: Props) {
  const theme = useTheme();

  const currentAssetValuation = useCurrentAssetValuation(entityId);

  const assets =
    currentAssetValuation?.assetValues.map((assetValue) => ({
      ...assetValue.asset,
    })) ?? [];

  const assetList = assets.reduce((acc, asset, idx) => {
    return (
      acc + asset.displayName + (idx < (assets.length ?? 0) - 1 ? ', ' : '')
    );
  }, '');

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      width={`${ASSET_CARD_WIDTH_PX}px`}
      bgcolor={theme.palette.grey[100]}
      borderRadius={1.5}
    >
      <RibbonHorizontal
        ribbonColor={theme.palette.secondary.main}
        icon={<Dataflow01Icon size={24} />}
      />
      <Stack padding={3} paddingLeft={7}>
        <TypographyClamped lines={2}>{grat.displayName}</TypographyClamped>
        <Typography variant="subtitle2">{grat.gratSummaryDisplay}</Typography>
        <Typography mb={theme.spacing(1)} variant="body2">
          {formatCurrencyNoDecimals(grat.currentValue)}
        </Typography>
        <Typography color={theme.palette.text.primary} variant="subtitle2">
          {assetList}
        </Typography>
      </Stack>
      {footer && (
        <Stack pb={2} pr={3} flexDirection="row-reverse">
          {footer}
        </Stack>
      )}
    </Box>
  );
}
