import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { every } from 'lodash';
import React, { useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { ButtonTab, Tabs } from '@/components/navigation/NavigationTabs';
import { PageHeader } from '@/components/navigation/PageHeader';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { DeleteEntityMenuItem } from '@/modules/entities/components/DeleteEntityMenuItem';
import { EntityDetailsAwareRoute } from '@/modules/entities/contexts/entityDetails/EntityDetailsAwareRoute';
import { getStageAwareLinkForEntity } from '@/modules/entities/utils/entitiesNavigationUtils';
import { GRATImplementationTask } from '@/modules/tasks/taskTypes';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { EntityStage } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import {
  GetImplementationTasksV2Query,
  useGetImplementationTasksV2Query,
} from './graphql/ImplementationTasks.generated';
import { EntityTask } from './Task';
import { TaskListItem, TaskListItemStatus } from './TaskList';
import {
  DenormalizedImplementationTask,
  getDenormalizedTasks,
  IMPLEMENTATION_TASK_TYPE_TO_TAB_NAME,
  ImplementationTabs,
  tabConfigurations,
} from './taskUtils';

const LEFT_PANE_COLUMNS = 4;

function getEntityHeading(entity: GetImplementationTasksV2Query['entity']) {
  if (!entity || entity.__typename !== 'Entity') return <Skeleton />;
  return entity.gratTrust?.displayName;
}

function getSearchParamsForTask(task: DenormalizedImplementationTask) {
  return {
    taskId: task.id,
    tab: IMPLEMENTATION_TASK_TYPE_TO_TAB_NAME[
      task.body.type as GRATImplementationTask
    ],
  };
}

function getInitialSearchParams(
  tasks: DenormalizedImplementationTask[],
  initialTaskId: string | null
) {
  if (initialTaskId) {
    const taskDefinition = tasks.find((t) => t.id === initialTaskId);
    // double-check here, because we can't fully trust the initial taskId coming from the query params. it's
    // possible that it's incorrect because of URL hacking or because upstream logic is incorrectly routing
    // users to this page.
    if (taskDefinition) {
      return getSearchParamsForTask(taskDefinition);
    } else {
      diagnostics.warn(
        'invalid initialTaskId passed to GRAT ImplementationPage',
        {
          taskId: initialTaskId,
        }
      );
    }
  }

  const taskDefinition =
    tasks.find((task) => task.status === TaskListItemStatus.NONE) || tasks[0];
  if (!taskDefinition) {
    throw new Error(`Could not find task for initial search params`);
  }
  return getSearchParamsForTask(taskDefinition);
}

export function ImplementationPage() {
  const entityId = useRequiredParam('entityId');
  const householdId = useRequiredParam('householdId');
  const navigate = useNavigate();
  const canDeleteEntity = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_DELETE_ENTITIES_AND_PROPOSALS
  );
  const [searchParams, setSearchParams] = useSearchParams({
    tab: ImplementationTabs.EXECUTION,
  });
  const { createErrorFeedback } = useFeedback();
  const { data } = useGetImplementationTasksV2Query({
    variables: {
      entityId: entityId,
      entityTaskLike: {
        hasEntityWith: [
          {
            id: entityId,
          },
        ],
      },
    },
    onError: createErrorFeedback(
      `We couldn't get the tasks to show you. Please try refreshing the page.`
    ),
    onCompleted: (data) => {
      if (data.entity?.__typename !== 'Entity')
        throw new Error('Invalid data in implementation page');
      // users can hit "back" after finalizing the implementation,
      if (data.entity.stage !== EntityStage.Implementation) {
        diagnostics.debug(
          'redirecting from implementation page for invalid stage',
          {
            entityId: data.entity.id,
            stage: data.entity.stage,
          }
        );
        navigate(getStageAwareLinkForEntity(data.entity));
      }
    },
  });

  const location = useLocation();
  const locationState = location.state;

  function handleTabChange(tab: ImplementationTabs) {
    setSearchParams(
      {
        taskId: searchParams.get('taskId') || 'none',
        tab,
      },
      {
        state: locationState,
      }
    );
  }

  function handleTaskChange(task: DenormalizedImplementationTask) {
    setSearchParams(
      {
        taskId: task.id,
        tab: task.tab,
      },
      {
        state: locationState,
      }
    );
  }

  const denormalizedTasks = React.useMemo(() => {
    const entity = (() => {
      if (!data) return null;
      if (data.entity?.__typename !== 'Entity') {
        throw new Error('Invalid data in implementation page');
      }

      return data.entity;
    })();

    return getDenormalizedTasks(
      data?.entityTasks.edges ?? [],
      entity,
      householdId
    );
  }, [data, householdId]);

  React.useEffect(
    function setInitialSearchParamsState() {
      if (denormalizedTasks.length < 1) {
        return;
      }
      const { taskId, tab } = getInitialSearchParams(
        denormalizedTasks,
        searchParams.get('taskId')
      );
      if (tab !== undefined) {
        setSearchParams(
          { taskId, tab },
          {
            replace: true,
            state: locationState,
          }
        );
      }
    },
    // we explicitly only want to run this just once, when the set of tasks initially loads.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [denormalizedTasks]
  );

  function getFirstOpenTask(): DenormalizedImplementationTask | null {
    return (
      denormalizedTasks.find((task) => {
        return task.body.completedAt === null;
      }) || null
    );
  }

  // currentTask will be null in the scenario where data hasn't come back yet
  const [currentTask, otherTasks] = useMemo(() => {
    const currentTaskId = searchParams.get('taskId');
    const currentTaskIndex = denormalizedTasks.findIndex(
      (task) => task.id === currentTaskId
    );
    return [
      denormalizedTasks[currentTaskIndex] || null,
      denormalizedTasks.filter((task) => task.id !== currentTaskId),
    ];
  }, [denormalizedTasks, searchParams]);

  function handleOnCompleteCurrentTask() {
    if (!currentTask) {
      const msg = 'currentTask is null in handleOnCompleteCurrentTask';
      diagnostics.error(msg, new Error(msg), {
        entityId,
      });
      return;
    }

    const currentTaskIdx = denormalizedTasks.findIndex(
      (task) => task.id === searchParams.get('taskId')
    );
    const nextTask = denormalizedTasks[currentTaskIdx + 1];
    if (!nextTask) {
      const msg = 'nextTask is null in handleOnCompleteCurrentTask';
      diagnostics.error(msg, new Error(msg), {
        entityId,
      });
      return;
    }
    handleTaskChange(nextTask);
  }

  function allTasksCompleteForTab(tab: ImplementationTabs) {
    if (denormalizedTasks.length === 0) return false;
    return every(denormalizedTasks, (task) => {
      if (task.tab !== tab) return true;
      return task.status === TaskListItemStatus.COMPLETED;
    });
  }

  return (
    <EntityDetailsAwareRoute entityId={entityId}>
      <Box>
        <PageHeader
          action={
            canDeleteEntity && (
              <ContextMenuButton>
                <DeleteEntityMenuItem
                  entityId={entityId}
                  onDelete={() =>
                    navigate(
                      getCompletePathFromRouteKey(
                        ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
                        { householdId }
                      )
                    )
                  }
                />
              </ContextMenuButton>
            )
          }
          heading={getEntityHeading(data?.entity)}
        />
        <Box p={3}>
          <Grid height="100%" container flex={1} columns={12}>
            <Grid height="100%" item sm={LEFT_PANE_COLUMNS}>
              <Box mr={3}>
                <Typography variant="h1" component="h2" mb={3}>
                  Implementation
                </Typography>
                <Tabs fullWidth>
                  {tabConfigurations.map(({ display, value }) => (
                    <ButtonTab
                      key={value}
                      display={display}
                      status={
                        allTasksCompleteForTab(value) ? 'complete' : 'pending'
                      }
                      isActive={searchParams.get('tab') === value}
                      onClick={() => handleTabChange(value)}
                    />
                  ))}
                </Tabs>
              </Box>
              <Box
                data-testid="ImplementationPage-taskList"
                component="ul"
                sx={{ listStyle: 'none', p: 0 }}
                mt={2}
              >
                {denormalizedTasks.map((taskItem) => {
                  if (taskItem.tab !== searchParams.get('tab')) return null;
                  return (
                    <li
                      key={taskItem.id}
                      data-testid="ImplementationPage-taskButton"
                    >
                      <TaskListItem
                        selected={searchParams.get('taskId') === taskItem.id}
                        onClick={() => handleTaskChange(taskItem)}
                        {...taskItem}
                      />
                    </li>
                  );
                })}
              </Box>
            </Grid>
            <Grid sx={{ height: '100%' }} item sm={12 - LEFT_PANE_COLUMNS}>
              <EntityTask
                onCompleteTask={handleOnCompleteCurrentTask}
                getFirstOpenTask={getFirstOpenTask}
                onTaskChange={handleTaskChange}
                otherTasks={otherTasks}
                task={currentTask}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </EntityDetailsAwareRoute>
  );
}
