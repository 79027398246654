import { Stack, StackProps } from '@mui/material';
import { ReactNode } from 'react';

import { COLORS } from '@/styles/tokens/colors';

import {
  CompoundHeader,
  CompoundHeaderProps,
} from '../CompoundHeader/CompoundHeader';

export interface SectionHeaderWithAccentProps {
  header: CompoundHeaderProps;
  containerProps?: StackProps;
  rightHeaderContent?: ReactNode;
}

export function SectionHeaderWithAccent({
  header,
  containerProps = {},
  rightHeaderContent,
}: SectionHeaderWithAccentProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      component="header"
      alignItems="center"
      borderBottom={`solid 2px ${COLORS.ORANGE[600]}`}
      pb={2}
      {...containerProps}
    >
      <CompoundHeader {...header} />
      {rightHeaderContent}
    </Stack>
  );
}
