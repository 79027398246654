import { isDate } from 'lodash';

export function validateDateMustBeInPast(fieldName = 'Date') {
  return (value: unknown): string | undefined => {
    if (!value) {
      return `${fieldName} is required`;
    }

    if (!isDate(value)) {
      return `${fieldName} must be a date`;
    }

    const dateOfBirth = value;

    if (dateOfBirth >= new Date()) {
      return `${fieldName} must be in the past`;
    }

    return undefined;
  };
}
