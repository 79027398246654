import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { Switch } from '@/components/form/baseInputs/Switch';
import { Card } from '@/components/layout/Card/Card';
import { SidePanel } from '@/components/modals/SidePanel';
import {
  DisplayTable,
  DisplayTableColumn,
} from '@/components/tables/DisplayTable/DisplayTable';
import { ClientOrganizationModal } from '@/modules/clientOrganizations/ClientOrganizationModal';
import { ClientProfileModal } from '@/modules/clientProfiles/ClientProfileForm/ClientProfileModal';
import { DispositiveProvisionsModalContainer } from '@/modules/dispositiveProvisions/DispositiveProvisionsModalContainer/DispositiveProvisionsContainer';
import { TestamentaryEntityModal } from '@/modules/entities/testamentaryEntities/TestamentaryEntityForm/TestamentaryEntityModal';
import { useShowSummaryTileDetails } from '@/modules/estateWaterfall/hooks/useShowSummaryTileDetails.hook';
import { setWaterfallSummaryTileDetails } from '@/modules/estateWaterfall/utils/localVars';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { WaterfallSummaryFooter } from './components/WaterfallSummaryFooter';
import { WaterfallSummaryRow } from './components/WaterfallSummaryRow';
import { useEditModals } from './hooks/useEditModals';
import { EstateWaterfallSummaryTileSummaryPanelProps } from './types';

const columns: DisplayTableColumn[] = [
  { headerName: 'Category', width: '50%' },
  { headerName: 'Amount', width: '30%', align: 'right' },
  { headerName: '', width: '20%', align: 'right' },
];

export function EstateWaterfallSummaryTileSummaryPanel({
  nodes,
  subtitle,
  title,
  total,
  onClose,
  primaryAction,
  householdId,
  firstPrimaryClientDeathId,
  waterfallId,
}: EstateWaterfallSummaryTileSummaryPanelProps) {
  const showSummaryTileDetails = useShowSummaryTileDetails(waterfallId);
  const { primaryClients, isTwoClientHousehold } = useHouseholdDetailsContext();
  const primaryClientIds = useMemo(
    () => new Set(primaryClients?.map((client) => client.id)) ?? new Set(),
    [primaryClients]
  );

  const {
    clientProfileModal: [clientProfileState, clientProfileActions],
    testamentaryEntityModal: [
      testamentaryEntityState,
      testamentaryEntityActions,
    ],
    clientOrganizationModal: [
      clientOrganizationState,
      clientOrganizationActions,
    ],
    dispositiveProvisionModal: [
      dispositiveProvisionState,
      dispositiveProvisionActions,
    ],
    handleEdit,
    handleManageDispositions,
  } = useEditModals({
    householdId,
    firstPrimaryClientDeathId,
    primaryClients: primaryClients ?? [],
    primaryClientIds,
  });

  return (
    <SidePanel.Panel data-testid="Summary-sidePanel">
      <SidePanel.Header title={title} onClose={onClose} />
      <SidePanel.Content>
        <SidePanel.Section>
          <Typography variant="subtitle2">{subtitle}</Typography>
          <Card variant="filled-light" sx={{ p: 2, my: 2 }}>
            <Switch
              labelPosition="right"
              value={showSummaryTileDetails}
              label={`Show details on all tiles`}
              onChange={() =>
                setWaterfallSummaryTileDetails({
                  waterfallId,
                  show: !showSummaryTileDetails,
                })
              }
            />
          </Card>
          <DisplayTable
            columns={columns}
            tableSx={{ tableLayout: 'fixed' }}
            data-testid="estate-waterfall-summary-table"
          >
            {nodes
              .slice()
              .sort((a, b) => (a.value.lessThan(b.value) ? 1 : -1))
              .map((node) => (
                <WaterfallSummaryRow
                  key={node.id}
                  node={node}
                  onEdit={handleEdit}
                  onManageDispositions={handleManageDispositions}
                  primaryClientIds={primaryClientIds}
                />
              ))}
            <WaterfallSummaryFooter total={total} />
          </DisplayTable>
          {primaryAction}
          <ClientProfileModal
            isOpen={clientProfileState.isModalOpen}
            setIsOpen={clientProfileActions.setModalIsOpen}
            householdId={householdId}
            clientProfileId={clientProfileState.data}
            clientProfileTypeDisplay="individual"
          />
          <ClientOrganizationModal
            isOpen={clientOrganizationState.isModalOpen}
            setIsOpen={clientOrganizationActions.setModalIsOpen}
            householdId={householdId}
            organizationId={clientOrganizationState.data}
          />
          <TestamentaryEntityModal
            isOpen={testamentaryEntityState.isModalOpen}
            onClose={testamentaryEntityActions.closeModal}
            testamentaryEntityId={
              testamentaryEntityState.data?.testamentaryEntityId ?? ''
            }
            isTwoClientHousehold={isTwoClientHousehold}
            deadClientIds={testamentaryEntityState.data?.deadClientIds ?? []}
            dyingPrimaryClientId={
              testamentaryEntityState.data?.dyingPrimaryClientId ?? ''
            }
          />
          {dispositiveProvisionState.data && (
            <DispositiveProvisionsModalContainer
              details={dispositiveProvisionState.data}
              onClose={dispositiveProvisionActions.closeModal}
            />
          )}
        </SidePanel.Section>
      </SidePanel.Content>
    </SidePanel.Panel>
  );
}
