// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ModifyImplementationTasksMutationVariables = Types.Exact<{
  dueDateUpdate?: Types.InputMaybe<Types.Scalars['Time']['input']>;
  reassignTasksWhereInput: Types.EntityTaskWhereInput;
  reassignToUserId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type ModifyImplementationTasksMutation = { __typename?: 'Mutation', assignEntityTasksToUser: number };

export type UpdateImplementationTaskMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateEntityTaskInput;
}>;


export type UpdateImplementationTaskMutation = { __typename?: 'Mutation', updateEntityTask: { __typename?: 'EntityTask', id: string, completedAt?: Time | null, completionState: Types.EntityTaskCompletionState, dueAt: Time, completedBy?: { __typename?: 'User', id: string, displayName: string } | null, assignedTo?: { __typename?: 'User', id: string, displayName: string } | null } };


export const ModifyImplementationTasksDocument = gql`
    mutation ModifyImplementationTasks($dueDateUpdate: Time, $reassignTasksWhereInput: EntityTaskWhereInput!, $reassignToUserId: ID) {
  assignEntityTasksToUser(
    dueDate: $dueDateUpdate
    user: $reassignToUserId
    input: $reassignTasksWhereInput
  )
}
    `;
export type ModifyImplementationTasksMutationFn = Apollo.MutationFunction<ModifyImplementationTasksMutation, ModifyImplementationTasksMutationVariables>;

/**
 * __useModifyImplementationTasksMutation__
 *
 * To run a mutation, you first call `useModifyImplementationTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyImplementationTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyImplementationTasksMutation, { data, loading, error }] = useModifyImplementationTasksMutation({
 *   variables: {
 *      dueDateUpdate: // value for 'dueDateUpdate'
 *      reassignTasksWhereInput: // value for 'reassignTasksWhereInput'
 *      reassignToUserId: // value for 'reassignToUserId'
 *   },
 * });
 */
export function useModifyImplementationTasksMutation(baseOptions?: Apollo.MutationHookOptions<ModifyImplementationTasksMutation, ModifyImplementationTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyImplementationTasksMutation, ModifyImplementationTasksMutationVariables>(ModifyImplementationTasksDocument, options);
      }
export type ModifyImplementationTasksMutationHookResult = ReturnType<typeof useModifyImplementationTasksMutation>;
export type ModifyImplementationTasksMutationResult = Apollo.MutationResult<ModifyImplementationTasksMutation>;
export type ModifyImplementationTasksMutationOptions = Apollo.BaseMutationOptions<ModifyImplementationTasksMutation, ModifyImplementationTasksMutationVariables>;
export const UpdateImplementationTaskDocument = gql`
    mutation UpdateImplementationTask($input: AugmentedUpdateEntityTaskInput!) {
  updateEntityTask(input: $input) {
    id
    completedAt
    completionState
    completedBy {
      id
      displayName
    }
    dueAt
    assignedTo {
      id
      displayName
    }
  }
}
    `;
export type UpdateImplementationTaskMutationFn = Apollo.MutationFunction<UpdateImplementationTaskMutation, UpdateImplementationTaskMutationVariables>;

/**
 * __useUpdateImplementationTaskMutation__
 *
 * To run a mutation, you first call `useUpdateImplementationTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateImplementationTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateImplementationTaskMutation, { data, loading, error }] = useUpdateImplementationTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateImplementationTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateImplementationTaskMutation, UpdateImplementationTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateImplementationTaskMutation, UpdateImplementationTaskMutationVariables>(UpdateImplementationTaskDocument, options);
      }
export type UpdateImplementationTaskMutationHookResult = ReturnType<typeof useUpdateImplementationTaskMutation>;
export type UpdateImplementationTaskMutationResult = Apollo.MutationResult<UpdateImplementationTaskMutation>;
export type UpdateImplementationTaskMutationOptions = Apollo.BaseMutationOptions<UpdateImplementationTaskMutation, UpdateImplementationTaskMutationVariables>;
export const Operations = {
  Mutation: {
    ModifyImplementationTasks: 'ModifyImplementationTasks' as const,
    UpdateImplementationTask: 'UpdateImplementationTask' as const
  }
}