import { PropsWithChildren, useEffect } from 'react';

import { useClientProfileDetailsContext } from './clientProfileDetails.context';

export const ClientProfileDetailsAwareRoute = ({
  clientProfileId: clientProfileIdExternal,
  children,
}: PropsWithChildren<{
  clientProfileId: string;
}>) => {
  const { clientProfileId, setClientProfileId } =
    useClientProfileDetailsContext();

  useEffect(() => {
    if (clientProfileIdExternal !== clientProfileId) {
      setClientProfileId(clientProfileIdExternal);
    }
  }, [clientProfileId, clientProfileIdExternal, setClientProfileId]);

  return <>{children}</>;
};
