import { ReactNode, useMemo } from 'react';
import { useSearchParam } from 'react-use';

import { isDevelopmentMode } from '@/utils/environmentUtils';

import { DebugContext, DebugLevel } from './debug.context';

export interface DebugContextProviderProps {
  children: ReactNode;
}

const validDebugLevels = new Set(Object.values(DebugLevel));

export const DebugContextProvider = ({
  children,
}: DebugContextProviderProps) => {
  const debug = useSearchParam('debug');

  const contextValue = useMemo<DebugContext>(() => {
    const debugLevel = parseInt(debug as unknown as string);
    const isValidDebugLevel =
      Number.isInteger(debugLevel) && validDebugLevels.has(debugLevel);
    const defaultDebugLevel = isDevelopmentMode() ? DebugLevel.DEFAULT : null;

    return { debugLevel: isValidDebugLevel ? debugLevel : defaultDebugLevel };
  }, [debug]);

  return (
    <DebugContext.Provider value={contextValue}>
      {children}
    </DebugContext.Provider>
  );
};
