import { Stack, Typography } from '@mui/material';

import { EntityDetailNameCard } from './EntityDetailNameCard';

interface ControllingPersonsProps {
  showControllingPersons: boolean;
  controllingPartyNames: string[];
}

export function ControllingPersons({
  showControllingPersons,
  controllingPartyNames,
}: ControllingPersonsProps) {
  if (!showControllingPersons) {
    return null;
  }

  return (
    <Stack spacing={1}>
      <Typography variant="h6" component="span">
        Controlling persons/signaturies
      </Typography>
      {controllingPartyNames.map((name, idx) => (
        <EntityDetailNameCard key={`${name}-${idx}`} leftText={name} />
      ))}
      {controllingPartyNames.length === 0 && (
        <Typography variant="subtitle2">None</Typography>
      )}
    </Stack>
  );
}
