// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnlinkIntegrationEntityMutationVariables = Types.Exact<{
  entityId: Types.Scalars['ID']['input'];
  integrationEntityIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type UnlinkIntegrationEntityMutation = { __typename?: 'Mutation', updateEntity: { __typename?: 'Entity', id: string, integrationEntities?: Array<{ __typename?: 'IntegrationEntity', id: string }> | null } };


export const UnlinkIntegrationEntityDocument = gql`
    mutation UnlinkIntegrationEntity($entityId: ID!, $integrationEntityIds: [ID!]!) {
  updateEntity(
    input: {id: $entityId, update: {removeIntegrationEntityIDs: $integrationEntityIds}}
  ) {
    id
    integrationEntities {
      id
    }
  }
}
    `;
export type UnlinkIntegrationEntityMutationFn = Apollo.MutationFunction<UnlinkIntegrationEntityMutation, UnlinkIntegrationEntityMutationVariables>;

/**
 * __useUnlinkIntegrationEntityMutation__
 *
 * To run a mutation, you first call `useUnlinkIntegrationEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkIntegrationEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkIntegrationEntityMutation, { data, loading, error }] = useUnlinkIntegrationEntityMutation({
 *   variables: {
 *      entityId: // value for 'entityId'
 *      integrationEntityIds: // value for 'integrationEntityIds'
 *   },
 * });
 */
export function useUnlinkIntegrationEntityMutation(baseOptions?: Apollo.MutationHookOptions<UnlinkIntegrationEntityMutation, UnlinkIntegrationEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlinkIntegrationEntityMutation, UnlinkIntegrationEntityMutationVariables>(UnlinkIntegrationEntityDocument, options);
      }
export type UnlinkIntegrationEntityMutationHookResult = ReturnType<typeof useUnlinkIntegrationEntityMutation>;
export type UnlinkIntegrationEntityMutationResult = Apollo.MutationResult<UnlinkIntegrationEntityMutation>;
export type UnlinkIntegrationEntityMutationOptions = Apollo.BaseMutationOptions<UnlinkIntegrationEntityMutation, UnlinkIntegrationEntityMutationVariables>;
export const Operations = {
  Mutation: {
    UnlinkIntegrationEntity: 'UnlinkIntegrationEntity' as const
  }
}