import Decimal from 'decimal.js';

import { getNodes } from '@/utils/graphqlUtils';

import { BeneficiaryOptionv2 } from '../../types';
import { BeneficiaryFormDetailsv2Fragment } from '../graphql/BeneficiariesFormDetails.generated';
import { BeneficiariesSubformType } from '../types';

export function getBeneficiaryOptionsv2(
  beneficiariesSubform: BeneficiariesSubformType,
  existingGRATTrust: BeneficiaryFormDetailsv2Fragment | null
): BeneficiaryOptionv2[] {
  // When creating a new GRAT trust, the existingGRATTrust will be null
  if (existingGRATTrust && existingGRATTrust.__typename !== 'GRATTrust') {
    throw new Error('Unsupported subtype');
  }

  const existingBeneficiaries = existingGRATTrust?.beneficiaries ?? [];

  const existingBeneficiaryTypeIds =
    existingBeneficiaries.map((beneficiary) => beneficiary.summary.id) ?? [];

  const selectedBeneficiaryTypeIds = beneficiariesSubform.beneficiaries.map(
    (beneficiary) => beneficiary.beneficiaryId
  );

  const beneficiaryIdToEntityBeneficiaryId =
    existingBeneficiaries.reduce(
      (acc, bene) => {
        const id = bene.summary.id;
        acc[id] = bene.id;

        return acc;
      },
      {} as Record<string, string>
    ) ?? {};

  // these are newly selected beneficiaries and existing beneficiaries that continue to be selected
  const beneficiaryOptionsSelected: BeneficiaryOptionv2[] =
    beneficiariesSubform.beneficiaries.map((beneficiary) => {
      const option: BeneficiaryOptionv2 = {
        displayName: beneficiary.heading,
        type: beneficiary.type!,
        beneficiaryTypeId: beneficiary.beneficiaryId,
        entityBeneficiaryId:
          beneficiaryIdToEntityBeneficiaryId[beneficiary.beneficiaryId] ??
          undefined,
        ownershipPercentageDecimal: beneficiary.percent,
        initiallySelected: existingBeneficiaryTypeIds.includes(
          beneficiary.beneficiaryId
        ),
        selected: true,
      };

      return option;
    });

  // these were initially selected, but are now deselected
  const beneficiaryOptionsDeselected: BeneficiaryOptionv2[] =
    existingBeneficiaries
      .map((beneficiary) => {
        if (selectedBeneficiaryTypeIds.includes(beneficiary.summary.id)) {
          return null;
        }

        const ownershipPercentage =
          getNodes(beneficiary.accessParameters)[0]?.percentage ??
          new Decimal(0);

        const option: BeneficiaryOptionv2 = {
          displayName: beneficiary.summary.displayName,
          beneficiaryTypeId: beneficiary.summary.id,
          type: beneficiary.summary.kind,
          entityBeneficiaryId: beneficiary.id,
          // note that we only read and write a single distribution for the GRAT beneficiary scenario
          ownershipPercentageDecimal: ownershipPercentage,
          initiallySelected: true,
          selected: false,
        };

        return option;
      })
      .filter((bene): bene is NonNullable<typeof bene> => bene !== null);

  return [...beneficiaryOptionsSelected, ...beneficiaryOptionsDeselected];
}
