import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { TypeaheadListInputOption } from '@/components/form/formAwareInputs/FormAwareTypeaheadListInput';
import { COLLABORATOR_KIND_DISPLAY_NAMES } from '@/modules/collaborators/collaborators.constants';
import { CollaboratorKind } from '@/types/schema';

export interface CollaboratorFormShape {
  firstName: string;
  lastName: string;
  email: string;
  role: CollaboratorKind | '';
  title: string;
  organization: string;
  associatedClients: TypeaheadListInputOption[];
}

export const defaultValues: CollaboratorFormShape = {
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  title: '',
  organization: '',
  associatedClients: [],
};

export const ROLE_OPTION_ITEMS: SelectInputOption[] = [
  {
    display: COLLABORATOR_KIND_DISPLAY_NAMES.ATTORNEY,
    value: CollaboratorKind.Attorney,
  },
  { display: COLLABORATOR_KIND_DISPLAY_NAMES.CPA, value: CollaboratorKind.Cpa },
  {
    display: COLLABORATOR_KIND_DISPLAY_NAMES.OTHER,
    value: CollaboratorKind.Other,
  },
];
