import { compact } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { getNodes } from '@/utils/graphqlUtils';

import {
  DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE,
  DispositiveProvisionsTemplateShape,
  NEW_TEMPLATE_SENTINEL,
} from '../DispositiveProvisionsTemplateSplitScreenModal.types';
import {
  getDispositiveProvisionTemplateUseCountEntityWhereInput,
  getDispositiveProvisionTemplateUseCountIndividualWhereInput,
} from '../DispositiveProvisionsTemplateSplitScreenModal.utils';
import {
  DispositiveProvisionTemplateUseCount_ClientProfileFragment,
  DispositiveProvisionTemplateUseCount_EntityFragment,
  DispositiveProvisionTemplateUseCountQueryHookResult,
  useDispositiveProvisionTemplateUseCountQuery,
} from '../graphql/DispositiveProvisionTemplateUseCount.generated';

interface useLinkedEntitiesReturnType
  extends DispositiveProvisionTemplateUseCountQueryHookResult {
  entities: DispositiveProvisionTemplateUseCount_EntityFragment[];
  individuals: DispositiveProvisionTemplateUseCount_ClientProfileFragment[];
}

export function useLinkedEntities(): useLinkedEntitiesReturnType {
  const { control } = useFormContext<DispositiveProvisionsTemplateShape>();
  const { primaryClients } = useHouseholdDetailsContext();

  const [templateId] = useWatch({
    control,
    name: [`${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.id`],
  });

  const isNewTemplate = templateId === NEW_TEMPLATE_SENTINEL;
  const [entityWhere, individualWhere] = useMemo(
    () => [
      getDispositiveProvisionTemplateUseCountEntityWhereInput(templateId),
      getDispositiveProvisionTemplateUseCountIndividualWhereInput(
        templateId,
        primaryClients
      ),
    ],
    [primaryClients, templateId]
  );

  const queryResult = useDispositiveProvisionTemplateUseCountQuery({
    variables: {
      entityWhere,
      individualWhere,
    },
    fetchPolicy: 'cache-and-network',
    skip: isNewTemplate,
  });

  return {
    ...queryResult,
    entities: compact(getNodes(queryResult.data?.entities)),
    individuals: compact(getNodes(queryResult.data?.clientProfiles)),
  };
}
