import { compact, first } from 'lodash';

import { useReportError } from '@/hooks/useReportError';
import { useEntityMostRecentPendingAsyncJobOfKindQuery } from '@/modules/asyncJobs/graphql/AsyncJobs.generated';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { AsyncJobKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

/**
 * Fetches the most recent pending AI suggestions async job for the current
 * entity, and sets it on the entity context.
 */
export function useEntityPendingAiSuggestionsAsyncJob() {
  const { reportError } = useReportError();

  const { entityId, setAiSuggestionsAsyncJobs } = useEntityDetailsContext();

  const queryReturns = useEntityMostRecentPendingAsyncJobOfKindQuery({
    skip: !entityId,
    fetchPolicy: 'network-only',
    // notifyOnNetworkStatusChange is required to rerun onComplete for each poll
    notifyOnNetworkStatusChange: true,
    variables: {
      entityID: entityId || '',
      kind: AsyncJobKind.DocumentInferenceAiSuggestion,
    },
    onCompleted: (data) => {
      const entity = first(getNodes(data.entities));
      const job = first(getNodes(entity?.asyncJobs));
      setAiSuggestionsAsyncJobs(compact([job]));
    },
    onError: (error) => {
      reportError(
        `Error fetching pending AI suggestions job for entityID=${entityId}`,
        error
      );
    },
  });

  return {
    ...queryReturns,
  };
}
