import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { ROUTE_KEYS } from '@/navigation/constants';

import { useDuplicateHouseholdMutation } from './graphql/DuplicateHouseholdButton.generated';

export interface DuplicateHouseholdButtonProps extends Partial<ButtonProps> {
  householdId: string;
}

export function DuplicateHouseholdButton({
  householdId,
  ...buttonProps
}: DuplicateHouseholdButtonProps) {
  const { navigate } = useNavigateToRoute();
  const { createErrorFeedback, showFeedback } = useFeedback();
  const [duplicateHousehold, { loading: loadingDuplicate }] =
    useDuplicateHouseholdMutation({
      onError: createErrorFeedback(
        'Failed to duplicate client. Please refresh the page and try again.'
      ),
      onCompleted: (data) => {
        showFeedback('Client duplicated successfully', { variant: 'success' });
        navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW, {
          householdId: data.copyHousehold.id,
        });
      },
    });

  function handleDeleteHousehold() {
    void duplicateHousehold({
      variables: { id: householdId },
    });
  }

  return (
    <Button
      loading={loadingDuplicate}
      variant="primary"
      size="sm"
      onClick={handleDeleteHousehold}
      {...buttonProps}
    >
      Duplicate household
    </Button>
  );
}
