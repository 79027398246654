import { EntityGstStatus } from '@/types/schema';

import { EntityGstStatusDisplayNames } from '../details/entityDetailPageUtils';

export function getGstStatusDisplay({
  gstStatus,
}: {
  gstStatus?: EntityGstStatus | null;
}): string | null {
  if (!gstStatus) {
    return null;
  }
  return EntityGstStatusDisplayNames[gstStatus];
}
