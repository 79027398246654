import { Box, Card, Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { UploadCloud01Icon } from '@/components/icons/UploadCloud01Icon';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { PageHeader } from '@/components/navigation/PageHeader';
import { useForm } from '@/components/react-hook-form';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { EntityImportTableHeader } from '@/modules/import/addepar/EntityImportTable.header';
import {
  BulkImportFlowContextProvider,
  BulkImportFlowType,
  getBulkImportFlow,
} from '@/modules/import/BulkImportFlowContext';
import {
  EntityImportTable,
  EntityImportTableSubmitButton,
} from '@/modules/import/EntityImportTable';
import {
  EntityImportTableForm,
  NAMESPACE,
} from '@/modules/import/EntityImportTable.types';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

export function BulkImportEntitiesPage() {
  const navigate = useNavigate();
  const flowType: BulkImportFlowType = getBulkImportFlow(
    useRequiredParam('flowType')
  );
  const formMethods = useForm<EntityImportTableForm>({
    defaultValues: {
      [NAMESPACE]: {
        householdMap: {},
      },
    },
  });

  const {
    formState: { isSubmitting },
  } = formMethods;

  return (
    <BulkImportFlowContextProvider value={{ flowType }}>
      <FormProvider<EntityImportTableForm> {...formMethods}>
        <FormFieldsDisabledProvider isDisabled={isSubmitting}>
          <Stack
            sx={{
              height: `calc(100vh - ${FOOTER_HEIGHT}px)`,
              overflow: 'scroll',
            }}
          >
            <PageHeader
              heading="Import entities for linked clients"
              action={
                <Button
                  startIcon={UploadCloud01Icon}
                  variant="primary"
                  onClick={() => {
                    navigate(
                      getCompletePathFromRouteKey(
                        ROUTE_KEYS.IMPORT_ADDEPAR_CONTENT,
                        { flowType }
                      )
                    );
                  }}
                  size="md"
                >
                  Import new clients
                </Button>
              }
            />
            <Box sx={{ p: 3 }}>
              <Card variant="outlined" sx={{ p: 3 }}>
                <EntityImportTable tableCaption={<EntityImportTableHeader />} />
              </Card>
            </Box>
          </Stack>
          <Box>
            <Footer
              leftAction={
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() =>
                    navigate(
                      getCompletePathFromRouteKey(
                        ROUTE_KEYS.HOUSEHOLDS_LIST,
                        {}
                      )
                    )
                  }
                >
                  Exit import
                </Button>
              }
              rightAction={<EntityImportTableSubmitButton />}
            />
          </Box>
        </FormFieldsDisabledProvider>
      </FormProvider>
    </BulkImportFlowContextProvider>
  );
}
