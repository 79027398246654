import { ApolloError } from '@apollo/client';
import { noop } from 'lodash';
import { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { EntityKind } from '@/types/schema';
import { PulidKind } from '@/utils/pulid';

import { EntityDocumentViewer_DocumentFragment } from '../graphql/EntityDocumentViewer.generated';

export type DocumentViewerTab = 'document' | 'summary';

export interface UpdateActiveDocumentOpts {
  documentId: string;
  shouldClearSearchResults: boolean;
}

export interface HouseholdDocumentsViewerContext {
  householdId: string;
  targetId: string;
  targetKind: PulidKind | null;
  // Only relevant if targetKind is entity
  entityKind: EntityKind | null | undefined;

  documents: EntityDocumentViewer_DocumentFragment[] | null;

  // The active document that is being displayed in the viewer
  activeDocument: EntityDocumentViewer_DocumentFragment | null;
  activeDocumentId: string | null;
  updateActiveDocumentId: ({
    documentId,
    shouldClearSearchResults,
  }: UpdateActiveDocumentOpts) => void;

  // The document that should be set to active on initial load
  defaultDocumentId: string | null;
  setDefaultDocumentId: Dispatch<SetStateAction<string | null>>;

  // The active tab of the document viewer side of the split screen
  documentViewerActiveTab: DocumentViewerTab;
  updateDocumentViewerActiveTab: (tab: DocumentViewerTab) => void;

  // The id of a document that was uploaded in the current session
  newlyUploadedDocumentId: string | null;
  setNewlyUploadedDocumentId: (documentId: string) => void;

  loading: boolean;
  error: ApolloError | undefined;
  refetch: () => void;

  // hideAddDocument will hide the "Add new" menu item in the document selector
  hideAddDocument: boolean;
}

export const HouseholdDocumentsViewerContext =
  createContext<HouseholdDocumentsViewerContext>({
    // safe to make this a string in the default because we instantiate with an entityId
    // already present, and don't want to make consumers handle an unnecessary null check
    householdId: '',
    targetId: '',
    targetKind: null,
    // Only relevant if targetKind is entity
    entityKind: null,

    documents: null,
    activeDocument: null,
    activeDocumentId: null,
    updateActiveDocumentId: noop,

    defaultDocumentId: null,
    setDefaultDocumentId: noop,

    documentViewerActiveTab: 'document',
    updateDocumentViewerActiveTab: noop,

    newlyUploadedDocumentId: null,
    setNewlyUploadedDocumentId: noop,

    loading: false,
    error: undefined,
    refetch: noop,

    hideAddDocument: false,
  });

export const useHouseholdDocumentsViewerContext = () =>
  useGuardedContext(
    HouseholdDocumentsViewerContext,
    'HouseholdDocumentsViewerProvider'
  );
