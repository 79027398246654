import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

import { getHexColorWithOpacity } from '@/components/utils/colorUtils';
import { COLORS } from '@/styles/tokens/colors';

export const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 24,
  borderRadius: theme.shape.borderRadius,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: getHexColorWithOpacity(COLORS.NAVY[400], 0.35),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: theme.shape.borderRadius,
    background: `radial-gradient(farthest-corner at 5% 10%, ${COLORS.NAVY[200]}, ${COLORS.NAVY[400]})`,
  },
}));
