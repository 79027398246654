import { useCallback } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { GraphViz_NodeConfigFragment } from '@/modules/graphViz/graphql/GraphVizNodeConfig.fragment.generated';
import { useUpdateGraphVizViewMutation } from '@/modules/graphViz/graphql/GraphVizView.generated';
import { EntityGraphNodeConfigurationInput } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { applyDerivedDataToState } from '../state';
import {
  EditMutationEffect,
  EntityMapEffectFn,
  EntityMapState,
} from '../types';

/**
 * Priority ordering for determining how we save node configurations:
 * 1. If there is a node in the current map, save that config
 * 2. If there is an existing view config for that node,
 * but perhaps the node is hidden from the view currently (not in the map), then
 * persist that old value back again for when it's re-added.
 * 3. Otherwise, there was never this node in the view config to begin with. Likely,
 * it was just added from the data model.
 */
function collectNodeConfigurationsFromState(
  state: EntityMapState
): EntityGraphNodeConfigurationInput[] {
  const allPossibleNodes = state.entityGraph.getNodes();
  const { derivedData } = applyDerivedDataToState(state);

  return allPossibleNodes.map((node) => {
    const nodeInMap = derivedData.nodesById[node.id];
    const nodeWithViewConfig: GraphViz_NodeConfigFragment | undefined =
      derivedData.nodeConfigurationsByNodeId[node.id];

    if (nodeInMap) {
      return {
        nodeID: nodeInMap.id,
        xPosition: nodeInMap.position.x,
        yPosition: nodeInMap.position.y,
        // When the map is initially created, some users may have de-selected nodes for the view so we want to persist that
        hidden: nodeWithViewConfig?.hidden ?? false,
      };
    }

    if (nodeWithViewConfig) {
      return nodeWithViewConfig;
    }

    return {
      nodeID: node.id,
      xPosition: node.position.x,
      yPosition: node.position.y,
      hidden: false,
    };
  });
}

export function useEditMutationEffect(): EntityMapEffectFn<EditMutationEffect> {
  const { showFeedback } = useFeedback();
  const [updateEntityGraphView] = useUpdateGraphVizViewMutation({
    onQueryUpdated: () => false,
  });

  return useCallback<EntityMapEffectFn<EditMutationEffect>>(
    (state, _effect, _dispatch) => {
      const id = state.entityGraphView.id;
      const nodeConfigurations = collectNodeConfigurationsFromState(state);

      void updateEntityGraphView({
        variables: { input: { id, update: { nodeConfigurations } } },
      }).catch((err) => {
        showFeedback(`Something went wrong. Please try again.`);
        diagnostics.error(
          `couldn't save entity map configuration`,
          err as Error,
          { id }
        );
      });
    },
    [showFeedback, updateEntityGraphView]
  );
}
