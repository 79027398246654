import { compact } from 'lodash';
import { useMemo } from 'react';
import { To } from 'react-router-dom';

import { BigBreadcrumbs } from '@/components/navigation/BigBreadcrumbs';

interface DocumentBreadcrumbProps {
  documentCrumbLabel?: string;
  rootPath: To | null;
}

export function DocumentBreadcrumb({
  documentCrumbLabel,
  rootPath,
}: DocumentBreadcrumbProps) {
  const crumbs = useMemo(() => {
    return compact([
      {
        to: rootPath ?? undefined,
        label: 'Documents',
      },
      documentCrumbLabel && {
        label: documentCrumbLabel,
      },
    ]);
  }, [documentCrumbLabel, rootPath]);

  return <BigBreadcrumbs crumbs={crumbs} />;
}
