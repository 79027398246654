// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateGrowthProfileMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateGrowthProfileInput;
}>;


export type CreateGrowthProfileMutation = { __typename?: 'Mutation', createGrowthProfile: { __typename?: 'GrowthProfile', id: string } };

export type UpdateGrowthProfileMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateGrowthProfileInput;
}>;


export type UpdateGrowthProfileMutation = { __typename?: 'Mutation', updateGrowthProfile: { __typename?: 'GrowthProfile', id: string } };


export const CreateGrowthProfileDocument = gql`
    mutation CreateGrowthProfile($input: AugmentedCreateGrowthProfileInput!) {
  createGrowthProfile(input: $input) {
    id
  }
}
    `;
export type CreateGrowthProfileMutationFn = Apollo.MutationFunction<CreateGrowthProfileMutation, CreateGrowthProfileMutationVariables>;

/**
 * __useCreateGrowthProfileMutation__
 *
 * To run a mutation, you first call `useCreateGrowthProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGrowthProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGrowthProfileMutation, { data, loading, error }] = useCreateGrowthProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGrowthProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateGrowthProfileMutation, CreateGrowthProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGrowthProfileMutation, CreateGrowthProfileMutationVariables>(CreateGrowthProfileDocument, options);
      }
export type CreateGrowthProfileMutationHookResult = ReturnType<typeof useCreateGrowthProfileMutation>;
export type CreateGrowthProfileMutationResult = Apollo.MutationResult<CreateGrowthProfileMutation>;
export type CreateGrowthProfileMutationOptions = Apollo.BaseMutationOptions<CreateGrowthProfileMutation, CreateGrowthProfileMutationVariables>;
export const UpdateGrowthProfileDocument = gql`
    mutation UpdateGrowthProfile($input: AugmentedUpdateGrowthProfileInput!) {
  updateGrowthProfile(input: $input) {
    id
  }
}
    `;
export type UpdateGrowthProfileMutationFn = Apollo.MutationFunction<UpdateGrowthProfileMutation, UpdateGrowthProfileMutationVariables>;

/**
 * __useUpdateGrowthProfileMutation__
 *
 * To run a mutation, you first call `useUpdateGrowthProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGrowthProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGrowthProfileMutation, { data, loading, error }] = useUpdateGrowthProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGrowthProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGrowthProfileMutation, UpdateGrowthProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGrowthProfileMutation, UpdateGrowthProfileMutationVariables>(UpdateGrowthProfileDocument, options);
      }
export type UpdateGrowthProfileMutationHookResult = ReturnType<typeof useUpdateGrowthProfileMutation>;
export type UpdateGrowthProfileMutationResult = Apollo.MutationResult<UpdateGrowthProfileMutation>;
export type UpdateGrowthProfileMutationOptions = Apollo.BaseMutationOptions<UpdateGrowthProfileMutation, UpdateGrowthProfileMutationVariables>;
export const Operations = {
  Mutation: {
    CreateGrowthProfile: 'CreateGrowthProfile' as const,
    UpdateGrowthProfile: 'UpdateGrowthProfile' as const
  }
}