import { PrintOnlyAssetsCard } from '@/modules/entities/details/shared/ViewOnlyAssetsCard';
import { getSLATTrustSummaryProperties } from '@/modules/entities/details/trusts/SLATTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { TrustOverviewSlide } from '../components/Trust.OverviewSlide';
import { SLATTrustPresentationSlideProps } from './SLATTrust.types';

export function SLATTrustOverviewSlide({
  entity,
  subtype,
  ...registrationProps
}: SLATTrustPresentationSlideProps) {
  const summaryDetails = getSLATTrustSummaryProperties({
    entity,
    entityType: ENTITY_TYPES.SLAT,
  });

  return (
    <TrustOverviewSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      AssetCard={<PrintOnlyAssetsCard entity={entity} />}
      displayName={subtype.displayName}
    />
  );
}
