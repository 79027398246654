import { Box } from '@mui/material';
import { size } from 'lodash';
import { useEffect } from 'react';

import { FlowChart } from '@/components/diagrams/FlowChart';
import { Background } from '@/components/diagrams/FlowChart/components/Background';
import { Controls } from '@/components/diagrams/FlowChart/components/Controls/Controls';
import { DownloadButton } from '@/components/diagrams/FlowChart/components/Controls/DownloadButton';
import { EditButton } from '@/components/diagrams/FlowChart/components/Controls/EditButton';
import { FitViewButton } from '@/components/diagrams/FlowChart/components/Controls/FitViewButton';
import { ResetButton } from '@/components/diagrams/FlowChart/components/Controls/ResetButton';
import { ZoomInButton } from '@/components/diagrams/FlowChart/components/Controls/ZoomInButton';
import { ZoomOutButton } from '@/components/diagrams/FlowChart/components/Controls/ZoomOutButton';
import { HelperLines } from '@/components/diagrams/FlowChart/components/HelperLines';
import {
  SumLine,
  SumLineContainer,
} from '@/components/diagrams/FlowChart/components/SumLine';
import { useIsInteractive } from '@/components/diagrams/FlowChart/hooks/useIsInteractive';
import { Coachmark } from '@/components/poppers/Coachmark/Coachmark';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { useOnBeforeUnloadPrompt } from '@/hooks/useOnBeforeUnloadPrompt';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';

import { GraphVizViewSelector } from '../graphViz/graphVizView/GraphVizViewSelector';
import { GraphVizViewUpdateButton } from '../graphViz/graphVizView/GraphVizViewUpdateButton';
import { EntityMapLegend } from './components/EntityMapLegend';
import { useEntityMapContext } from './contexts/entityMap.context';
import {
  EntityMapCoachmark,
  useEntityMapCoachmark,
} from './hooks/useEntityMapCoachmark';
import { useFlowChartProps } from './hooks/useFlowChartProps';
import { useGraphVizViewTabs } from './hooks/useGraphVizViewTabs';
import { useTotalValue } from './hooks/useTotalValue';

export interface EntityMapFlowChartProps {
  householdId: string;
}

export const EntityMapFlowChart = ({
  householdId,
}: EntityMapFlowChartProps) => {
  const { flowChartProps, helperLineHorizontal, helperLineVertical } =
    useFlowChartProps();
  const trackUserEvent = useTrackUserEvent();
  const { isInteractive } = useIsInteractive();
  const { dispatch, state } = useEntityMapContext();
  const { coachmark, onDismissCoachmark } = useEntityMapCoachmark();

  function onAutoPosition() {
    dispatch({ type: 'AUTO_POSITION' });
    return Promise.resolve();
  }

  function onEditButtonSave() {
    dispatch({ type: 'EDIT_SAVE' });
    return Promise.resolve();
  }

  useOnBeforeUnloadPrompt(isInteractive);

  // Close the summary panel when the edit button is toggled
  useEffect(() => {
    if (isInteractive && state.derivedData.isSummaryPanelOpen) {
      dispatch({ type: 'CLOSE_SUMMARY_PANEL' });
    }
  }, [dispatch, isInteractive, state.derivedData.isSummaryPanelOpen]);

  const { inEstateTotal, outOfEstateTotal, totalValue } = useTotalValue({
    household: state.household,
    nodes: state.nodes,
  });

  const { tabs, getTabForField, navigateToView } = useGraphVizViewTabs({
    householdId,
  });

  return (
    <FlowChart {...flowChartProps}>
      <Background />
      <HelperLines
        vertical={helperLineVertical}
        horizontal={helperLineHorizontal}
      />
      <Coachmark
        id={LOCAL_STORAGE_KEYS.COACHMARK_ENTITY_MAP_ADD_BENEFICIARIES}
        isOpen={coachmark === EntityMapCoachmark.LifetimeBeneficiaries}
        title="Click on any entity tile to start adding lifetime beneficiaries"
        body="These beneficiaries will automatically appear on this diagram as new tiles downstream of their respective entities."
        buttonLabel="Got it"
        hideArrow
        placement="bottom"
        onClose={() =>
          onDismissCoachmark(EntityMapCoachmark.LifetimeBeneficiaries)
        }
      >
        <Box
          // this Box is used to center the coachmark inside its relatively-positioned parent
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Coachmark>
      {!state.derivedData.isSummaryPanelOpen && (
        <Controls position="top-left">
          <GraphVizViewSelector
            householdId={householdId}
            view={state.entityGraphView}
            views={state.allEntityGraphViews}
            onSelectView={navigateToView}
            nodes={state.derivedData.allPossibleNodes}
            onSuccess={navigateToView}
            tabs={tabs}
            getTabForField={getTabForField}
          />
          {isInteractive && (
            <GraphVizViewUpdateButton
              householdId={householdId}
              view={state.entityGraphView}
              allowDelete={size(state.allEntityGraphViews) > 1}
              nodes={state.derivedData.allPossibleNodes}
              tabs={tabs}
              getTabForField={getTabForField}
              onDelete={(viewId) => {
                const firstView = state.allEntityGraphViews.filter(
                  (v) => v.id !== viewId
                )[0]!;
                navigateToView(firstView);
              }}
              onSuccess={(updatedView) => {
                if (
                  updatedView.orientation !== state.entityGraphView.orientation
                ) {
                  dispatch({ type: 'AUTO_POSITION' });
                }
              }}
            />
          )}
          <ResetButton onReset={onAutoPosition} />
        </Controls>
      )}
      <Controls>
        <EditButton onSave={onEditButtonSave} />
        <DownloadButton
          viewDisplayName={state.entityGraphView.displayName}
          householdId={householdId}
          tooltipText="Download diagram as PNG"
          onAfterDownload={() => {
            trackUserEvent('entity_diagram exported', {
              householdId,
            });
          }}
        />
        <FitViewButton />
        <ZoomOutButton />
        <ZoomInButton />
      </Controls>
      <Controls position="bottom-left" style={{ margin: 0 }}>
        <EntityMapLegend />
      </Controls>
      {!state.derivedData.isSummaryPanelOpen && (
        <SumLineContainer data-testid="EntityMapFlowChart-legend">
          <Coachmark
            id={LOCAL_STORAGE_KEYS.COACHMARK_ENTITY_MAP_TOP_LINE_ESTATE_METRICS}
            initialOpen={coachmark === EntityMapCoachmark.TopLineEstateMetrics}
            title="Summary of estate value"
            body="Entity valuations flow into this breakdown of in estate value, out of estate value, and total value."
            buttonLabel="Got it"
            onClose={() =>
              onDismissCoachmark(EntityMapCoachmark.TopLineEstateMetrics)
            }
            placement="top-end"
          />
          <SumLine label="In estate" amount={inEstateTotal} />
          <SumLine label="Out of estate" amount={outOfEstateTotal} />
          <SumLine label="Total value" amount={totalValue} emphasize />
        </SumLineContainer>
      )}
    </FlowChart>
  );
};
