import { ApolloError } from '@apollo/client';
import { Stack } from '@mui/material';
import { useCallback, useState } from 'react';

import { SectionHeader } from '@/components/display/SectionHeader/SectionHeader';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import {
  GetCharitableProjectionQuery,
  useGetCharitableProjectionLazyQuery,
} from '@/pages/client-proposal/graphql/GetCharitableProjection.generated';
import {
  CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment,
  CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment,
  CharitableTrustDesignerIllustrationData_NoPlanProjectionFragment,
} from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesignerAnalysis/graphql/CharitableTrustDesignerIllustrationData.generated';

import { CharitableTrustDesignerDistributionTable } from './CharitableTrustDesignerDistributionTable';
import {
  BaseCharitableProjectionVariables,
  useCharitableProjection,
} from './hooks/useCharitableProjection';
import { CharitableTrustDesignerAnalysisIllustrationAnalysis } from './IllustrationComponents/CharitableTrustDesignerAnalysisIllustrationAnalysis';
import { CharitableTrustDesignerAnalysisIllustrationSummaryCards } from './IllustrationComponents/CharitableTrustDesignerAnalysisIllustrationSummaryCards';

export function CharitableTrustDesignerAnalysisIllustration() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [crtProjection, setCrtProjection] = useState<
    | CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment
    | undefined
  >(undefined);
  const [cltProjection, setCltProjection] = useState<
    | CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment
    | undefined
  >(undefined);
  const [noPlanProjection, setNoPlanProjection] = useState<
    CharitableTrustDesignerIllustrationData_NoPlanProjectionFragment | undefined
  >(undefined);

  const onQueryError = useCallback(
    (err: ApolloError) => {
      showFeedback('Could not fetch projection');
      reportError('Could not fetch projection', err);
    },
    [reportError, showFeedback]
  );

  const [charitableProjectionLazyQuery] = useGetCharitableProjectionLazyQuery({
    onError: onQueryError,
  });

  const projectionCallback = useCallback(
    ({
      crtProposalProjection,
      cltProposalProjection,
      noPlanProjection,
    }: GetCharitableProjectionQuery) => {
      setCrtProjection(crtProposalProjection);
      setCltProjection(cltProposalProjection);
      setNoPlanProjection(noPlanProjection);
    },
    []
  );

  const getVariables = useCallback(
    (vars: BaseCharitableProjectionVariables) => vars,
    []
  );
  useCharitableProjection(
    charitableProjectionLazyQuery,
    projectionCallback,
    getVariables
  );

  return (
    <Stack direction="column" spacing={3}>
      <SectionHeader title="Illustration" />
      <CharitableTrustDesignerAnalysisIllustrationSummaryCards
        crtProjection={crtProjection}
        cltProjection={cltProjection}
      />
      <CharitableTrustDesignerAnalysisIllustrationAnalysis
        crtProjection={crtProjection}
        cltProjection={cltProjection}
        noPlanProjection={noPlanProjection}
      />
      <CharitableTrustDesignerDistributionTable />
      <Callout severity="info-high" textVariant="subtitle2">
        The analysis above is for illustrative purposes only and is not a
        guarantee of future strategy performance. The analysis assumes a Federal
        income tax rate of 37%, a capital gains tax rate of 20%, and straight
        line asset growth based on the return projections specified above.
      </Callout>
    </Stack>
  );
}
