import { InputAdornment, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwarePhoneNumberInput } from '@/components/form/formAwareInputs/FormAwarePhoneNumberInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { ModalFormLayout } from '@/components/form/layout/ModalFormLayout';
import { Mail01Icon } from '@/components/icons/Mail01Icon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { ClientOrganizationKind } from '@/types/schema';

import {
  CLIENT_ORGANIZATION_KIND_OPTIONS,
  ClientOrganizationFormShape,
} from './ClientOrganizationModal.constants';

interface ClientOrganizationFormProps {
  variant: 'create' | 'edit';
  requirement?: 'mustBeTrustee' | 'mustBeBeneficiary';
}

export function ClientOrganizationForm({
  requirement,
  variant,
}: ClientOrganizationFormProps) {
  const { control, setValue } = useFormContext<ClientOrganizationFormShape>();
  const kind = useWatch({ name: 'kind' as const, control });

  useEffect(() => {
    if (!kind || variant === 'edit') return;

    const isTrustee = kind === ClientOrganizationKind.CorporateTrustee;
    const isBeneficiary = kind !== ClientOrganizationKind.CorporateTrustee;
    setValue('isTrustee', isTrustee, { shouldDirty: true });
    setValue('isBeneficiary', isBeneficiary, { shouldDirty: true });
  }, [kind, setValue, variant]);

  useEffect(() => {
    if (requirement === 'mustBeTrustee') {
      setValue('isTrustee', true, { shouldDirty: true });
    }
    if (requirement === 'mustBeBeneficiary') {
      setValue('isBeneficiary', true, { shouldDirty: true });
    }
  }, [requirement, setValue]);

  return (
    <ModalFormLayout>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput<ClientOrganizationFormShape>
            control={control}
            fieldName="name"
            label="Organization name"
            required
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareSelectInput<ClientOrganizationFormShape>
            control={control}
            label="Type of organization"
            fieldName="kind"
            options={CLIENT_ORGANIZATION_KIND_OPTIONS}
            required
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={6}>
          <FormAwareTextInput<ClientOrganizationFormShape>
            control={control}
            fieldName="address.cityName"
            label="City"
          />
        </FormLayoutItem>
        <FormLayoutItem width={6}>
          <FormAwareTextInput<ClientOrganizationFormShape>
            control={control}
            fieldName="address.stateCode"
            label="State"
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <Card variant="filled" sx={{ p: 2 }}>
            <Typography variant="h4" component="h3" mb={1}>
              Point of contact
            </Typography>
            <FormLayoutRow>
              <FormLayoutItem width={6}>
                <FormAwareTextInput
                  control={control}
                  label="First name"
                  fieldName={`pointOfContactName`}
                />
              </FormLayoutItem>
              <FormLayoutItem width={6}>
                <FormAwareTextInput
                  control={control}
                  label="Last name"
                  fieldName={`pointOfContactLastName`}
                />
              </FormLayoutItem>
            </FormLayoutRow>
            <FormLayoutRow>
              <FormLayoutItem width={7}>
                <FormAwareTextInput
                  control={control}
                  label="Email"
                  type="email"
                  fieldName={`pointOfContactEmail`}
                  startAdornment={
                    <InputAdornment position="start">
                      <Mail01Icon size={20} />
                    </InputAdornment>
                  }
                />
              </FormLayoutItem>
              <FormLayoutItem width={5}>
                <FormAwarePhoneNumberInput
                  control={control}
                  label="Phone"
                  fieldName={`pointOfContactPhone`}
                />
              </FormLayoutItem>
            </FormLayoutRow>
          </Card>
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareSwitch
            disabled={requirement === 'mustBeTrustee'}
            control={control}
            label="Enable use as a trustee"
            fieldName={`isTrustee`}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareSwitch
            disabled={requirement === 'mustBeBeneficiary'}
            control={control}
            label="Enable use as a beneficiary"
            fieldName={`isBeneficiary`}
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </ModalFormLayout>
  );
}
