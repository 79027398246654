import Decimal from 'decimal.js';

export const DEFAULT_PRE_TAX_RETURNS = {
  preTaxReturnPercentageLow: new Decimal(0),
  preTaxReturnPercentageMedium: new Decimal(5),
  preTaxReturnPercentageHigh: new Decimal(10),
};

export const DEFAULT_TAX_DRAG_PERCENTAGES = {
  taxDragPercentageLow: new Decimal(20),
  taxDragPercentageMedium: new Decimal(20),
  taxDragPercentageHigh: new Decimal(20),
};
