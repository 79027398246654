import Decimal from 'decimal.js';
import { compact } from 'lodash';

import { BASIC_ASSETS_SUBFORM as BASIC_ASSETS } from '@/modules/entities/BasicAssetsSubform/BasicAssetsSubform.types';
import { INSURANCE_POLICIES_DETAILS_SUBFORM } from '@/modules/entities/InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform.types';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  AugmentedCreateEntityInput,
  AugmentedCreateKeyPersonInput,
  AugmentedCreateLlcBusinessEntityInput,
  AugmentedCreateOwnershipStakeInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateGpBusinessEntityInput,
  AugmentedUpdateLlcBusinessEntityInput,
  AugmentedUpdateLpBusinessEntityInput,
  AugmentedUpdateSoleProprietorshipBusinessEntityInput,
  BusinessEntityTaxStatus,
  CreateOwnershipStakeInput,
  UpdateEntityInput,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { BASIC_INFORMATION_SUBFORM_NAMESPACE as BASIC_INFORMATION } from '../../../BasicInformationSubform/BasicInformationSubform.types';
import { NAMESPACE as BUSINESS_ENTITIES_TAX_STATUS } from '../../../BusinessEntitiesTaxStatusSubform/BusinessEntitiesTaxStatusSubform.types';
import {
  BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE as BUSINESS_ENTITY_DETAILS,
  Fields as BusinessEntityDetailsSubformFields,
} from '../../../BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import {
  PartialSubformsCombinedType,
  SubformsCombinedType,
} from '../../EntitySubforms.types';
import {
  makeAccountInput,
  makeUpdateAccountInput,
} from '../shared/accounts.utils';
import {
  getDescriptionUpdate,
  getRelatedModelInputProperties,
  getSharedAssetIntegrationUpdateProperties,
  getTypeOrUndefined,
} from '../shared/common.utils';
import { makeUpdatePolicyInputs } from '../shared/policies.utils';

type AugmentedUpdateBusinessEntityInput =
  | AugmentedUpdateLlcBusinessEntityInput
  | AugmentedUpdateGpBusinessEntityInput
  | AugmentedUpdateLpBusinessEntityInput
  | AugmentedUpdateSoleProprietorshipBusinessEntityInput;

export function makeUpdateBusinessEntityInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  entityType: EntityType,
  subtypeId: string
): AugmentedUpdateEntityInput {
  const entityUpdate = makeEntityTypeUpdateBusinessEntityInput(
    entityId,
    entityType,
    {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
      clearOwnedByStakes: true,
    },
    makeUpdateBusinessEntitySubtypeInput({
      formInput,
      subtypeId,
      entityType,
    })
  );

  return {
    ...entityUpdate,
    withOwnedByStakes: makeBusinessEntityOwnershipStakeInputs(
      formInput as BusinessEntityDetailsSubformFields
    ),
  };
}

function makeCreateKeyPeopleInputs(
  businessEntityDetails: SubformsCombinedType['businessEntityDetailsSubform']
): AugmentedCreateKeyPersonInput[] {
  return compact(
    (businessEntityDetails?.keyPeople || []).map((keyPerson) => {
      if (!keyPerson?.keyPersonId) {
        return null;
      }

      const modelInputIds = getRelatedModelInputProperties(
        keyPerson.keyPersonId
      );
      return {
        create: {
          ...modelInputIds,
        },
        withRoles: keyPerson.roles.map((roleKind) => ({
          create: {
            kind: roleKind,
          },
        })),
      };
    })
  );
}

export function getSharedCreateBusinessEntityTopLevelProperties(
  formInput: SubformsCombinedType
): Partial<AugmentedCreateEntityInput> {
  return {
    withOwnedByStakes: makeBusinessEntityOwnershipStakeInputs(formInput),
  };
}

export function makeBusinessEntityInput(
  formInput: SubformsCombinedType,
  _config: {
    entityType: EntityType;
  }
): AugmentedCreateLlcBusinessEntityInput {
  return {
    create: {
      // BASIC INFORMATION
      legalName: formInput[BASIC_INFORMATION].legalName || undefined,
      displayName: formInput[BASIC_INFORMATION].displayName,
      effectiveDate: getTypeOrUndefined<Date>(
        formInput[BASIC_INFORMATION].effectiveDate
      ),
      description: getTypeOrUndefined<string>(
        formInput[BASIC_INFORMATION].description
      ),
      doingBusinessAsName: getTypeOrUndefined<string>(
        formInput[BASIC_INFORMATION].doingBusinessAsName
      ),
      formationJurisdictionStateCode: getTypeOrUndefined<string>(
        formInput[BASIC_INFORMATION].jurisdiction
      ),

      // KEY PEOPLE SHARED PROPERTIES
      keyPeopleNotes: getTypeOrUndefined<string>(
        formInput[BUSINESS_ENTITY_DETAILS]?.keyPeopleNotes
      ),

      // OWNERSHIP
      // (other ownership properties are handled at the entities level)
      ownershipAsOfDate: getTypeOrUndefined<Date>(
        formInput[BUSINESS_ENTITY_DETAILS]?.ownershipAsOfDate
      ),

      // TAX STATUS
      taxStatus: getTypeOrUndefined<BusinessEntityTaxStatus>(
        formInput[BUSINESS_ENTITIES_TAX_STATUS]?.taxStatus
      ),
      taxID: getTypeOrUndefined<string>(
        formInput[BUSINESS_ENTITIES_TAX_STATUS]?.taxId
      ),
    },
    withDesignerAccount: makeAccountInput(formInput),
    withKeyPeople: makeCreateKeyPeopleInputs(
      formInput.businessEntityDetailsSubform
    ),
  };
}

function makeEntityTypeUpdateBusinessEntityInput(
  entityId: string,
  entityType: EntityType,
  updateEntityInput: UpdateEntityInput,
  updateBusinessEntityInput: AugmentedUpdateBusinessEntityInput
): AugmentedUpdateEntityInput {
  const sharedProperties = {
    id: entityId,
    update: updateEntityInput,
  };

  switch (entityType) {
    case 'llc':
      return {
        ...sharedProperties,
        updateLlcBusinessEntity: updateBusinessEntityInput,
      };
    case 'gp':
      return {
        ...sharedProperties,
        updateGpBusinessEntity: updateBusinessEntityInput,
      };
    case 'lp':
      return {
        ...sharedProperties,
        updateLpBusinessEntity: updateBusinessEntityInput,
      };
    case 'sole-proprietorship':
      return {
        ...sharedProperties,
        updateSoleProprietorshipBusinessEntity: updateBusinessEntityInput,
      };
    case 's-corp':
      return {
        ...sharedProperties,
        updateScorpBusinessEntity: updateBusinessEntityInput,
      };
    case 'c-corp':
      return {
        ...sharedProperties,
        updateCcorpBusinessEntity: updateBusinessEntityInput,
      };
    default:
      throw new UnreachableError({
        case: entityType as never,
        message: 'Unrecognized business entity type',
      });
  }
}

function makeUpdateBusinessEntitySubtypeInput({
  formInput,
  subtypeId,
  entityType: _entityType,
}: {
  formInput: PartialSubformsCombinedType;
  subtypeId: string;
  entityType: EntityType;
}): AugmentedUpdateBusinessEntityInput {
  if (
    !formInput[BUSINESS_ENTITY_DETAILS] ||
    !formInput[BASIC_INFORMATION] ||
    !formInput[BUSINESS_ENTITIES_TAX_STATUS]
  ) {
    throw new Error(`Subforms are required to update a business entity`);
  }

  const { updatePolicies, withPolicies, removePolicyIDs } =
    makeUpdatePolicyInputs(formInput[INSURANCE_POLICIES_DETAILS_SUBFORM]);

  const businessEntitySubtypeUpdate: AugmentedUpdateBusinessEntityInput['update'] =
    {
      // required properties
      displayName: formInput[BASIC_INFORMATION].displayName,
      ownershipAsOfDate: getTypeOrUndefined<Date>(
        formInput[BUSINESS_ENTITY_DETAILS].ownershipAsOfDate
      ),
      requiresCtaReporting: formInput[BASIC_INFORMATION]?.requiresCtaReporting,

      // TODO: make a typesafe utility function to handle this; there are a lot of bugs
      // related to this across these forms right now.
      // nillable properties
      ...(() => {
        const legalName = getTypeOrUndefined<string>(
          formInput[BASIC_INFORMATION]?.legalName
        );

        return legalName ? { legalName } : { clearLegalName: true };
      })(),
      ...(() => {
        const effectiveDate = getTypeOrUndefined<Date>(
          formInput[BASIC_INFORMATION].effectiveDate
        );
        return effectiveDate ? { effectiveDate } : { clearEffectiveDate: true };
      })(),
      ...(() => {
        const description = getTypeOrUndefined<string>(
          formInput[BASIC_INFORMATION].description
        );
        return description ? { description } : { clearDescription: true };
      })(),
      ...(() => {
        const doingBusinessAsName = getTypeOrUndefined<string>(
          formInput[BASIC_INFORMATION].doingBusinessAsName
        );
        return doingBusinessAsName
          ? { doingBusinessAsName }
          : { clearDoingBusinessAsName: true };
      })(),
      ...(() => {
        const formationJurisdictionStateCode = getTypeOrUndefined<string>(
          formInput[BASIC_INFORMATION].jurisdiction
        );
        return formationJurisdictionStateCode
          ? { formationJurisdictionStateCode }
          : { clearFormationJurisdictionStateCode: true };
      })(),

      // KEY PEOPLE SHARED PROPERTIES
      ...(() => {
        const keyPeopleNotes = getTypeOrUndefined<string>(
          formInput[BUSINESS_ENTITY_DETAILS].keyPeopleNotes
        );
        return keyPeopleNotes
          ? { keyPeopleNotes }
          : { clearKeyPeopleNotes: true };
      })(),
      ...(() => {
        const taxStatus = getTypeOrUndefined<BusinessEntityTaxStatus>(
          formInput[BUSINESS_ENTITIES_TAX_STATUS].taxStatus
        );

        return taxStatus ? { taxStatus } : { clearTaxStatus: true };
      })(),
      ...(() => {
        const taxID = getTypeOrUndefined<string>(
          formInput[BUSINESS_ENTITIES_TAX_STATUS].taxId
        );

        return taxID ? { taxID } : { clearTaxID: true };
      })(),
      ...(() => {
        const requiresCtaReporting = getTypeOrUndefined<string>(
          formInput[BASIC_INFORMATION]?.requiresCtaReporting
        );
        const finCenID = getTypeOrUndefined<string>(
          formInput[BASIC_INFORMATION]?.finCenID
        );

        return requiresCtaReporting ? { finCenID } : { clearFinCenID: true };
      })(),

      ...getDescriptionUpdate(formInput[BASIC_INFORMATION].description),
      clearKeyPeople: true,
      removePolicyIDs,
    };

  const updateInput: AugmentedUpdateBusinessEntityInput = {
    id: subtypeId,
    withKeyPeople: makeCreateKeyPeopleInputs(
      formInput.businessEntityDetailsSubform
    ),
    withPolicies,
    updatePolicies,
    update: businessEntitySubtypeUpdate,
  };

  if (formInput[BASIC_ASSETS]) {
    updateInput.updateDesignerAccount = makeUpdateAccountInput(formInput);
  }

  return updateInput;
}

export function makeBusinessEntityOwnershipStakeInputs(
  formInput: BusinessEntityDetailsSubformFields
): AugmentedCreateOwnershipStakeInput[] {
  if (!formInput[BUSINESS_ENTITY_DETAILS]) {
    throw new Error('Must have business entity details form input');
  }

  return compact(
    formInput[BUSINESS_ENTITY_DETAILS].owners.map((owner) => {
      if (!owner?.ownerId) {
        return null;
      }

      const pulidKind = getPulidKind(owner.ownerId);

      if (!pulidKind) {
        throw new Error('Could not get pulid kind');
      }

      const modelInputIds: Partial<CreateOwnershipStakeInput> = {};

      if (pulidKind === PulidKind.Entity) {
        modelInputIds.owningEntityID = owner.ownerId;
      } else if (pulidKind === PulidKind.ClientProfile) {
        modelInputIds.owningIndividualID = owner.ownerId;
      } else {
        throw new Error(`Unexpected pulid kind ${pulidKind}`);
      }

      return {
        create: {
          ...modelInputIds,
          ownershipPercentage: getTypeOrUndefined<Decimal>(owner.percentOwned),
        },
      };
    })
  );
}
