import { Card, Typography } from '@mui/material';

import { useChartColorDefinitions } from '@/components/charts/constants';
import { StackedHorizontalBarGroup } from '@/components/charts/StackedHorizontalBarGroup/StackedHorizontalBarGroup';

export function AdminBrandingPreview() {
  const chartColorDefinitions = useChartColorDefinitions();
  return (
    <Card variant="outlined" sx={{ p: 3 }}>
      <Typography variant="h1">Preview</Typography>
      <StackedHorizontalBarGroup
        bars={[
          {
            label: 'Current',
            sections: [
              {
                color: chartColorDefinitions.PRIMARY.backgroundColor,
                sectionTextColor: chartColorDefinitions.PRIMARY.contrastText,
                value: 40,
                groupName: 'Cash',
              },
              {
                color: chartColorDefinitions.SECONDARY.backgroundColor,
                sectionTextColor: chartColorDefinitions.SECONDARY.contrastText,
                value: 35,
                groupName: 'Bonds',
              },
              {
                color: chartColorDefinitions.TERTIARY.backgroundColor,
                sectionTextColor: chartColorDefinitions.TERTIARY.contrastText,
                value: 25,
                groupName: 'Illiquid investments',
              },
              {
                color: chartColorDefinitions.NEGATIVE.backgroundColor,
                sectionTextColor: chartColorDefinitions.NEGATIVE.contrastText,
                value: 25,
                groupName: 'Liabilities',
              },
            ],
          },
          {
            label: 'Target',
            sections: [
              {
                color: chartColorDefinitions.PRIMARY.backgroundColor,
                sectionTextColor: chartColorDefinitions.PRIMARY.contrastText,
                value: 10,
                groupName: 'Cash',
              },
              {
                color: chartColorDefinitions.SECONDARY.backgroundColor,
                sectionTextColor: chartColorDefinitions.SECONDARY.contrastText,
                value: 40,
                groupName: 'Bonds',
              },
              {
                color: chartColorDefinitions.TERTIARY.backgroundColor,
                sectionTextColor: chartColorDefinitions.TERTIARY.contrastText,
                value: 25,
                groupName: 'Illiquid investments',
              },
              {
                color: chartColorDefinitions.NEGATIVE.backgroundColor,
                sectionTextColor: chartColorDefinitions.NEGATIVE.contrastText,
                value: 50,
                groupName: 'Liabilities',
              },
            ],
          },
        ]}
        showBarLabels={true}
        showLegend={true}
        headerVariant="h4"
        headerSpacing={2}
      />
    </Card>
  );
}
