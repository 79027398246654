import { Stack, Typography, useTheme } from '@mui/material';
import { PropsWithChildren } from 'react';

import { COLORS } from '@/styles/tokens/colors';

type TileFrameProps = PropsWithChildren<{
  frameText?: string;
}>;

export function TileFrame({ children, frameText }: TileFrameProps) {
  const theme = useTheme();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      p={1}
      spacing={1}
      borderRadius={theme.spacing(1)}
      bgcolor={COLORS.GRAY[300]}
    >
      {children}
      {frameText && <Typography variant="subtitle2">{frameText}</Typography>}
    </Stack>
  );
}
