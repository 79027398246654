import { Stack, Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

import { COLORS } from '@/styles/tokens/colors';

export interface TableHeaderBarProps {
  header: ReactNode;
  variant?: TypographyProps['variant'];
  rightContent?: ReactNode;
}

export function TableHeaderBar({
  header,
  variant = 'h6',
  rightContent,
}: TableHeaderBarProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      p={1.5}
      bgcolor={COLORS.NAVY['800']}
    >
      <Typography variant={variant} color="white">
        {header}
      </Typography>
      {rightContent}
    </Stack>
  );
}
