import { useEffect } from 'react';

import { useTenantDetailsContext } from './TenantDetailsContext';

export function useSubBrand(subBrandId: string | null) {
  const { setActiveSubBrandId } = useTenantDetailsContext();
  useEffect(() => {
    setActiveSubBrandId(subBrandId);

    // when leaving the context of the sub-brand, clear it so it doesn't apply to other things later
    return () => {
      setActiveSubBrandId(null);
    };
  }, [subBrandId, setActiveSubBrandId]);
}
