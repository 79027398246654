export const DEFAULT_UNMASK_LENGTH = 4 as const;
export const DEFAULT_MASK = 'x' as const;
export const DEFAULT_SEPARATOR = '-' as const;

export function getMaskedString(
  input: string,
  unmaskLength: number = DEFAULT_UNMASK_LENGTH,
  options: {
    separator: string;
    mask: string;
  } = {
    separator: DEFAULT_SEPARATOR,
    mask: DEFAULT_MASK,
  }
): string {
  if (input.length <= unmaskLength) {
    return input;
  }
  const toBeMaskedLength = input.length - unmaskLength;
  const toBeUnmasked = input.substring(toBeMaskedLength);

  const maskedString = new Array<string>(toBeMaskedLength)
    .fill(options.mask)
    .join('');

  return [maskedString, toBeUnmasked].join(options.separator);
}
