import { Box, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';

import { UsersXIcon } from '@/components/icons/UsersXIcon';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { VisualizationInput } from '../types';
import { getBeneficiariesFromProposal } from '../utils';

interface Props {
  beneficiaries: ReturnType<typeof getBeneficiariesFromProposal>;
  entityId: string;
  chartValues: {
    preTax: {
      noPlan: VisualizationInput;
      withPlan: VisualizationInput;
    };
    postTax: {
      noPlan: VisualizationInput;
      withPlan: VisualizationInput;
    };
  };
}

export function BeneficiariesBreakdown({
  beneficiaries,
  entityId,
  chartValues,
}: Props) {
  const theme = useTheme();
  const isNoBeneficiaries = beneficiaries[entityId]?.length === 0;
  const beneficiaryClientProfileIds = beneficiaries[entityId]?.map(
    (beneficiary) => beneficiary.id
  );

  // if there are multiple of the same beneficiary, we need to clarify if the beneficiary
  // is receiving the benefit directly or via a remainder trust
  const needsBeneficiarySourceClarification =
    new Set(beneficiaryClientProfileIds).size !==
    beneficiaryClientProfileIds?.length;

  return (
    <Stack flexDirection="column" m={2}>
      <Box width="100%" bgcolor={theme.palette.primary.main}>
        <Typography
          px={1.5}
          py={1}
          color={theme.palette.common.white}
          variant="h5"
        >
          Outcome by beneficiary
        </Typography>
      </Box>
      {isNoBeneficiaries && (
        <Stack
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
          padding={2}
          color={theme.palette.grey[400]}
        >
          <UsersXIcon size={32} />

          <Typography
            margin={2}
            textAlign="center"
            maxWidth={100}
            color={theme.palette.grey[500]}
            variant="h6"
          >
            Beneficiaries not yet specified
          </Typography>
        </Stack>
      )}
      {beneficiaries[entityId]?.map((beneficiary, idx) => {
        const withPlanOutcomeDecimal = beneficiary.ownershipPercentageDecimal
          .dividedBy(new Decimal(100))
          .times(
            chartValues.postTax.withPlan[0].plus(
              chartValues.postTax.withPlan[1]
            )
          );

        const noPlanOutcomeDecimal = beneficiary.ownershipPercentageDecimal
          .dividedBy(new Decimal(100))
          .times(
            chartValues.postTax.noPlan[0].plus(chartValues.postTax.noPlan[1])
          );

        const withPlanDifferenceDecimal =
          withPlanOutcomeDecimal.minus(noPlanOutcomeDecimal);

        const withPlanOutcomeCopy = withPlanDifferenceDecimal.isNegative()
          ? `${formatCurrencyNoDecimals(withPlanOutcomeDecimal)}`
          : `Add'l ${formatCurrencyNoDecimals(withPlanDifferenceDecimal)}`;

        return (
          <Stack
            key={`beneficiary.id-${idx}`}
            flexDirection="column"
            borderBottom={`1px ${theme.palette.grey[200]} solid`}
            px={1.5}
            py={1}
          >
            <Typography variant="h5">
              {beneficiary.displayName}{' '}
              {needsBeneficiarySourceClarification
                ? beneficiary.irrevocableTrustName
                  ? `(via ${beneficiary.irrevocableTrustName})`
                  : `(directly)`
                : null}
            </Typography>
            <Stack flexDirection="row" justifyContent="space-between">
              <Stack flexDirection="column" spacing={0.5}>
                <Typography variant="subtitle2">No plan</Typography>
                <Typography variant="subtitle2">With plan</Typography>
              </Stack>
              <Stack flexDirection="column" spacing={0.5} alignItems="end">
                <Typography variant="h6">
                  {formatCurrencyNoDecimals(noPlanOutcomeDecimal)}
                </Typography>
                <Typography
                  variant="h6"
                  color={theme.palette.dataVisualizationSecondary.dark}
                >
                  {withPlanOutcomeCopy}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}
