import { compact, uniq } from 'lodash';

import { ClientProfile } from '@/types/schema';
import { formatEnumCase } from '@/utils/formatting/strings';

import { ClientProfileMinimalGrantorRelationshipFragment } from './graphql/ClientProfile.generated';

export function getFullName(
  clientProfile?: Pick<ClientProfile, 'firstName' | 'lastName'> | null
): string {
  return compact([clientProfile?.firstName, clientProfile?.lastName]).join(' ');
}

interface ClientProfileGrantorRelationship {
  name: string;
  type: string;
}
export function getGrantorRelationships(
  relationships?: ClientProfileMinimalGrantorRelationshipFragment[] | null
): ClientProfileGrantorRelationship[] {
  return (relationships || []).flatMap(
    ({ fromClientProfile, toClientProfile, type }) => {
      const grantorRelationships = [];

      if (fromClientProfile.isPrimary) {
        grantorRelationships.push({
          name: getFullName(fromClientProfile),
          type: 'Self',
        });
      }

      if (toClientProfile.isPrimary) {
        grantorRelationships.push({
          name: getFullName(toClientProfile),
          type: formatEnumCase(type),
        });
      }

      return grantorRelationships;
    }
  );
}

export function getGrantorRelationshipsFriendlyString(
  relationships?: ClientProfileMinimalGrantorRelationshipFragment[] | null
): string {
  // Because we don't specify the name of the grantor, a uniq call is required
  // so we don't see something like (child, child) in the case of two grantors
  return uniq(
    getGrantorRelationships(relationships).map(({ type }) => type)
  ).join(', ');
}
