import { RichListItemProps } from '@/components/lists/RichListItem/RichListItem';

import { TrustDetailsSummary_SummaryPersonFragment } from './graphql/TrustDetailsSummary.generated';

export function mapSummaryPersonToRichListItemProps(
  person: TrustDetailsSummary_SummaryPersonFragment
): RichListItemProps {
  return {
    heading: person.displayName,
    // doing this funny remapping to get rid of nulls and replace them with newlines
    additionalItems:
      person.additionalContext?.map((context) => context ?? '') ?? [],
    badgeText: person.badgeText || undefined,
    description: person.displayType || undefined,
  };
}
