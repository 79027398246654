import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

type EstateWaterfallTrowserLayoutProps = PropsWithChildren;

export function EstateWaterfallTrowserLayout({
  children,
}: EstateWaterfallTrowserLayoutProps) {
  return (
    <Stack height="100%" flex="none" overflow="auto" alignItems="center">
      <Stack
        pt={3}
        pb={4}
        width={{ md: '100%', lg: '70%' }}
        maxWidth={800}
        minWidth={{ md: 'auto', lg: 760 }}
        spacing={3}
      >
        {children}
      </Stack>
    </Stack>
  );
}
