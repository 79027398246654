import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export interface WaterfallRouteContext {
  waterfallId: string | null;
}

export const WaterfallRouteContext = createContext<WaterfallRouteContext>({
  waterfallId: null,
});

export const useWaterfallRouteContext = () => {
  return useGuardedContext(WaterfallRouteContext, 'WaterfallRouteProvider');
};
