import { PopperContent } from '@/components/poppers/PopperContent';

export function Rate7520() {
  return (
    <PopperContent
      body={
        "The IRC section 7520 rate is used to calculate interest due in annuity payments during a GRAT's term, and is set monthly based on the mid-term Applicable Federal Rate (AFR)."
      }
    />
  );
}
