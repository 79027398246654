import { getYear, isValid } from 'date-fns';
import Decimal from 'decimal.js';
import {
  ControllerFieldState,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import { diagnostics } from '@/utils/diagnostics';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

let lastId = 0;
export function generateUniqueId(prefix = 'id_') {
  lastId++;
  return `${prefix}${lastId}`;
}

// TODO: There's a lot of logic in RHF about error messages (just check out the interface of fieldState.error)
// and they actually have their own error message component: https://react-hook-form.com/api/useformstate/errormessage
// But I also want to keep the underlying input component unaware of the form lib. We could potentially pass
// in a React.ReactNode as a component prop?
export function getFieldErrorValue(
  fieldState: ControllerFieldState,
  isFormSubmitted: boolean,
  validateOnChange?: boolean
) {
  const isPossibleRenderErrorState = isFormSubmitted || validateOnChange;
  if (isPossibleRenderErrorState && fieldState.error)
    return fieldState.error.message;
  return undefined;
}

// per IRS instructions, 7520 rates are always rounded to the nearest 2/10s of a percent.
// https://www.irs.gov/businesses/small-businesses-self-employed/section-7520-interest-rates
export function interestRate7520Valuation(value: Decimal | null | undefined) {
  if (!value || value.isNaN()) return undefined;
  const stringValue = value.toFixed(1);
  const isValidDecimal =
    parseInt(stringValue[stringValue.length - 1]!) % 2 === 0;
  if (!isValidDecimal) return '7520 rate must end in an even decimal.';
  return undefined;
}

const globalMinimumDate = new Date('01/01/1900');
export function minimumDateValidation(value: Date | null | undefined) {
  if (!value || !(value instanceof Date)) return undefined;
  if (value <= globalMinimumDate) {
    return `Date must be after ${formatDateToMonDDYYYY(globalMinimumDate)}`;
  }

  return undefined;
}

export function validDateValidation(value: Date | null | undefined) {
  if (!value || !(value instanceof Date)) return undefined;
  if (!isValid(value)) return 'Date must be valid.';
  return undefined;
}

export function getGreaterThanCurrentYearValidation(
  value: number | string | null | undefined
) {
  const numYear = Number(value);
  if (isNaN(numYear)) {
    return `Year must be a number`;
  }

  const currYear = getYear(new Date());
  if (isNaN(numYear) || numYear < currYear) {
    return `Year must be ≥ ${currYear}`;
  }
  return undefined;
}

export function getValidations<
  FormShape extends FieldValues,
  FieldValue = unknown,
>(
  label: string,
  required: boolean,
  validation?: RegisterOptions<FormShape>['validate']
): RegisterOptions<FormShape>['validate'] {
  const defaultRequiredValidation = (value: FieldValue) => {
    if (!required) return undefined;
    if (!value) {
      return `${label} is required.`;
    }
    return undefined;
  };

  // this pattern allows the caller to pass in a more specific "required" validation
  // function and have it overwrite the default one.
  const allValidations = Object.assign(
    { required: defaultRequiredValidation },
    validation || {}
  );
  return allValidations;
}

type FieldValueType = Decimal | string | null;

function getFormattedDecimalValue(
  value: FieldValueType,
  decimalScale: number,
  fieldName?: string
): string {
  if (Decimal.isDecimal(value)) {
    return value.toFixed(decimalScale);
  } else if (value === null || value === undefined) {
    return '';
  } else {
    throw new Error(
      `Passing a non-Decimal.js value to a Decimal.js expecting parser${
        fieldName ? ` to field name ${fieldName}` : ''
      }.`
    );
  }
}

interface GetPossiblyDecimalFieldValueOpts {
  isDecimalJS: boolean;
  decimalScale: number;
  fieldName?: string;
}

export function getPossiblyDecimalFieldValue(
  value: FieldValueType,
  opts: GetPossiblyDecimalFieldValueOpts = {
    isDecimalJS: false,
    decimalScale: 2,
  }
): string {
  // if it's a Decimal.js input, we expect either null (to render an empty input)
  // or a valid Decimal.js value
  if (opts.isDecimalJS) {
    return getFormattedDecimalValue(value, opts.decimalScale, opts.fieldName);
  } else if (Decimal.isDecimal(value)) {
    // we're passing a Decimal value to a non-Decimal input. warn the user.
    diagnostics.warn(
      'Passing a Decimal.js value to an input that\'s not marked with "isDecimalJSInput".'
    );
    return getFormattedDecimalValue(value, opts.decimalScale, opts.fieldName);
  }

  return value || '';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- linter refactor
function getEventWithDecimalValue(event: any) {
  /* eslint-disable @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return -- linter refactor too much work */
  try {
    // instantiating a Decimal with an empty string will throw an error
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- linter refactor too much work
    const decimalValue = new Decimal(event.target.value);
    if (decimalValue.isNaN()) {
      event.target.value = null;
      return event;
    }
    event.target.value = decimalValue;
    return event;
  } catch (err) {
    event.target.value = null;
    return event;
  }
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */
}

export function getDecimalJSSupportingOnChange(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- linter refactor
  formOnChange: (event: any) => void,
  opts = { isDecimalJS: false }
) {
  if (!opts.isDecimalJS) {
    return formOnChange;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- linter refactor
  return function handleDecimalOnChange(event: any) {
    const normalizedEvent = getEventWithDecimalValue(event);
    formOnChange(normalizedEvent);
  };
}

export function getDecimalJSValueFromEvent(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- linter refactor
  event: any
): Decimal | null {
  const eventWithDecimalValue = getEventWithDecimalValue(event);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return eventWithDecimalValue.target.value;
}
