import { useCallback, useState } from 'react';

/**
 * @returns a boolean indicating whether the element is hovered, and a set of props to spread on the element that you want to track
 */
export function useIsElementHovered(): [
  boolean,
  { onMouseEnter: () => void; onMouseLeave: () => void },
] {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return [isHovered, { onMouseEnter, onMouseLeave }];
}
