import { Section } from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import { AfterDeath } from '@/types/schema';

import { GetWaterfallSummary_EstateWaterfallVizNodeFragment } from '../../graphql/GetWaterfallSummary.generated';

export enum EstateWaterfallSummaryBarChartsSections {
  InEstate = 'In estate',
  OutOfEstateFamily = 'Out of estate (family)',
  OutOfEstateCharity = 'Out of estate (charity)',
  // LifeInsuranceDeathBenefit isn't a standalone section, it's a subsection that can live within
  // any of the others except for gift and estate tax
  LifeInsuranceDeathBenefit = 'Life insurance death benefit',
  GiftAndEstateTax = 'Gift & estate tax (cumulative)',
}

export type WaterfallSection = Section & {
  kind: EstateWaterfallSummaryBarChartsSections;
  afterDeath: AfterDeath;
  nodes: GetWaterfallSummary_EstateWaterfallVizNodeFragment[];
};

export type WaterfallSections = [
  WaterfallSection & {
    kind: EstateWaterfallSummaryBarChartsSections.InEstate;
  },
  WaterfallSection & {
    kind: EstateWaterfallSummaryBarChartsSections.OutOfEstateFamily;
  },
  WaterfallSection & {
    kind: EstateWaterfallSummaryBarChartsSections.OutOfEstateCharity;
  },
  WaterfallSection & {
    kind: EstateWaterfallSummaryBarChartsSections.GiftAndEstateTax;
  },
];
