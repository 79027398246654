import { EntityKind } from '@/types/schema';

export interface ConvertEntityFormShape {
  ownerId: string;
  // Due to the nature of a checkbox, we need to keep a record of each possible
  // owner and whether or not they have been selected
  owners: Record<string, boolean>;
}

export const ConvertEntityHeadingMap: Partial<Record<EntityKind, string>> = {
  [EntityKind.RevocableTrust]: 'Convert to irrevocable trust',
  [EntityKind.IrrevocableTrust]: 'Convert to revocable trust',
  [EntityKind.JointPersonalAccount]: 'Convert to personal account',
  [EntityKind.IndividualPersonalAccount]: 'Convert to joint account',
};
