import React from 'react';

import { IconProps, Loading02Icon } from '@/components/icons/Loading02Icon';

interface SpinningIconProps extends IconProps {
  Icon?: React.ElementType;
  speed?: number;
  unit?: 's' | 'ms';
}

/**
 * A wrapper around an icon that spins it.
 * @param Icon
 * @param iconProps
 * @param speed
 * @param unit
 */
export const SpinningIcon = ({
  Icon = Loading02Icon,
  speed = 2,
  unit = 's',
  ...iconProps
}: SpinningIconProps) => {
  const spinningStyles = React.useMemo(
    () => ({
      animation: `spin ${speed}${unit} linear infinite`,
      '@keyframes spin': {
        from: { transform: 'rotate(0deg)' },
        to: { transform: 'rotate(360deg)' },
      },
    }),
    [speed, unit]
  );

  return <Icon {...iconProps} sx={{ ...iconProps?.sx, ...spinningStyles }} />;
};
