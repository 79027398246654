import { Stack, SxProps, Typography } from '@mui/material';
import { getYear } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { StackedHorizontalBarGroup } from '@/components/charts/StackedHorizontalBarGroup/StackedHorizontalBarGroup';
import { ColorBox } from '@/components/display/ColorBox/ColorBox';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { ButtonGroupInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import {
  DisplayTable,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { COLORS } from '@/styles/tokens/colors';
import { formatPercent } from '@/utils/formatting/percent';
import { getNodes } from '@/utils/graphqlUtils';
import { validateNumberRange } from '@/utils/validators/validateNumberRange';

import {
  useCharitableTrustDesignerContext,
  useIsCRT,
} from '../../CharitableTrustDesignerContext';
import { usePreTaxReturnLimitModalQuery } from '../../PreTaxReturnLimitModal/graphql/PreTaxReturnLimitModalQuery.generated';
import { PreTaxReturnLimitModal } from '../../PreTaxReturnLimitModal/PreTaxReturnLimitModal';
import {
  NAMESPACE as PRE_TAX_RETURN_LIMIT_MODAL_NAMESPACE,
  PreTaxReturnLimitModalForm,
} from '../../PreTaxReturnLimitModal/PreTaxReturnLimitModal.types';
import {
  CharitableTrustDesignerAnalysisForm,
  CharitableTrustDesignerAnalysisFormPaths,
  CharitableTrustDesignerPreTaxReturnModel,
  NAMESPACE,
} from '../CharitableTrustDesignerAnalysis.types';
import {
  CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment,
  CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment,
  CharitableTrustDesignerIllustrationData_NoPlanProjectionFragment,
} from '../graphql/CharitableTrustDesignerIllustrationData.generated';
import {
  CharitableTrustDesignerIllustrationAssetsTableRowType,
  getBarGraphsForProjection,
  getTableDataForProjection,
} from './CharitableTrustDesignerAnalysisIllustration.utils';

export interface CharitableTrustDesignerAnalysisIllustrationAnalysisProps {
  crtProjection?: CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment;
  cltProjection?: CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment;
  noPlanProjection?: CharitableTrustDesignerIllustrationData_NoPlanProjectionFragment;
  loading?: boolean;
}

export function CharitableTrustDesignerAnalysisIllustrationAnalysis(
  props: CharitableTrustDesignerAnalysisIllustrationAnalysisProps
) {
  return (
    <Card variant="outlined" sx={{ p: 3, pb: 4 }}>
      <Stack direction="column" spacing={5}>
        <Card variant="filled" sx={{ p: 3 }}>
          <FormLayoutRow>
            <FormLayoutItem width={6}>
              <AnnualPreTaxReturn />
            </FormLayoutItem>
            <FormLayoutItem width={6}>
              <YearOfAnalysis />
            </FormLayoutItem>
          </FormLayoutRow>
        </Card>
        <CharitableTrustDesignerProjectionBarGraphs {...props} />
        <CharitableTrustDesignerAssetsTable {...props} />
      </Stack>
    </Card>
  );
}

const PATH_TAX_LOW =
  `${PRE_TAX_RETURN_LIMIT_MODAL_NAMESPACE}.taxDragPercentageLow` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_TAX_MEDIUM =
  `${PRE_TAX_RETURN_LIMIT_MODAL_NAMESPACE}.taxDragPercentageMedium` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_TAX_HIGH =
  `${PRE_TAX_RETURN_LIMIT_MODAL_NAMESPACE}.taxDragPercentageHigh` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_RETURN_LOW =
  `${PRE_TAX_RETURN_LIMIT_MODAL_NAMESPACE}.preTaxReturnPercentageLow` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_RETURN_MEDIUM =
  `${PRE_TAX_RETURN_LIMIT_MODAL_NAMESPACE}.preTaxReturnPercentageMedium` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_RETURN_HIGH =
  `${PRE_TAX_RETURN_LIMIT_MODAL_NAMESPACE}.preTaxReturnPercentageHigh` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
function AnnualPreTaxReturn() {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { control, setValue } =
    useFormContext<CharitableTrustDesignerAnalysisForm>();
  const { proposalId } = useCharitableTrustDesignerContext();

  usePreTaxReturnLimitModalQuery({
    fetchPolicy: 'network-only',
    variables: { proposalId },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const proposal = getNodes(data.proposals)[0];
      const charitableProposal = proposal?.cltProposal || proposal?.crtProposal;

      if (!charitableProposal) {
        return;
      }

      const {
        preTaxReturnPercentageLow: returnLow,
        preTaxReturnPercentageMedium: returnMedium,
        preTaxReturnPercentageHigh: returnHigh,
        taxDragPercentageLow: taxLow,
        taxDragPercentageMedium: taxMedium,
        taxDragPercentageHigh: taxHigh,
      } = charitableProposal;

      if (returnLow) setValue(PATH_RETURN_LOW, returnLow);
      if (returnMedium) setValue(PATH_RETURN_MEDIUM, returnMedium);
      if (returnHigh) setValue(PATH_RETURN_HIGH, returnHigh);
      if (taxLow) setValue(PATH_TAX_LOW, taxLow);
      if (taxMedium) setValue(PATH_TAX_MEDIUM, taxMedium);
      if (taxHigh) setValue(PATH_TAX_HIGH, taxHigh);
    },
  });

  const preTaxReturnModelPath =
    `${NAMESPACE}.analysis.preTaxReturnModel` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
  const preTaxReturnModel = useWatch({
    control,
    name: preTaxReturnModelPath,
  });

  const [
    preTaxReturnPercentageLow,
    preTaxReturnPercentageMedium,
    preTaxReturnPercentageHigh,
  ] = useWatch({
    control,
    name: [PATH_RETURN_LOW, PATH_RETURN_MEDIUM, PATH_RETURN_HIGH],
  });

  const buttonGroupOptions = useMemo<
    ButtonGroupInputOption<CharitableTrustDesignerPreTaxReturnModel>[]
  >(() => {
    return [
      {
        display: `${formatPercent(preTaxReturnPercentageLow, 0)}%`,
        value: 'low',
      },
      {
        display: `${formatPercent(preTaxReturnPercentageMedium, 0)}%`,
        value: 'medium',
      },
      {
        display: `${formatPercent(preTaxReturnPercentageHigh, 0)}%`,
        value: 'high',
      },
    ];
  }, [
    preTaxReturnPercentageHigh,
    preTaxReturnPercentageLow,
    preTaxReturnPercentageMedium,
  ]);

  const handlePreTaxReturnLimitSave = useCallback(
    (formData: PreTaxReturnLimitModalForm) => {
      setValue(
        PRE_TAX_RETURN_LIMIT_MODAL_NAMESPACE,
        formData[PRE_TAX_RETURN_LIMIT_MODAL_NAMESPACE]
      );
    },
    [setValue]
  );

  return (
    <>
      <ButtonGroup
        variant="onDark"
        options={buttonGroupOptions}
        label="Annual pre-tax return"
        value={preTaxReturnModel}
        onChange={(_, value) => setValue(preTaxReturnModelPath, value)}
      />
      <Typography
        variant="subtitle2"
        onClick={() => setModalOpen(true)}
        sx={{ textDecoration: 'underline', cursor: 'pointer', mt: 0.5 }}
      >
        Edit return assumptions
      </Typography>
      <PreTaxReturnLimitModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        proposalId={proposalId}
        onSave={handlePreTaxReturnLimitSave}
      />
    </>
  );
}

const YEAR_OF_ANALYSIS_PATH = `${NAMESPACE}.analysis.yearOfAnalysis` as const;

function YearOfAnalysis() {
  const { control } = useFormContext<CharitableTrustDesignerAnalysisForm>();
  const termLength = useWatch({
    control,
    name: `${NAMESPACE}.term.length`,
  });
  const currentYear = getYear(new Date());
  const maxYear = currentYear + parseInt((termLength || '0').toString()) - 1;
  return (
    <FormAwareFormattedNumberInput
      control={control}
      fieldName={YEAR_OF_ANALYSIS_PATH}
      label="Year of analysis"
      required
      validateOnChange
      helpText={`Enter a year between ${currentYear} and ${maxYear}`}
      validation={{
        minMax: validateNumberRange({
          min: currentYear,
          max: maxYear,
          fieldName: 'Year',
        }),
      }}
    />
  );
}

export function CharitableTrustDesignerProjectionBarGraphs({
  cltProjection,
  crtProjection,
  noPlanProjection,
  loading,
}: CharitableTrustDesignerAnalysisIllustrationAnalysisProps) {
  const isCRT = useIsCRT();
  const { control } = useFormContext<CharitableTrustDesignerAnalysisForm>();
  const [yearOfAnalysis] = useWatch({
    control,
    name: [YEAR_OF_ANALYSIS_PATH],
  });

  const barGraphs = getBarGraphsForProjection({
    isCRT,
    crtProjection,
    cltProjection,
    noPlanProjection,
    yearOfAnalysis,
    loading,
  });

  return <StackedHorizontalBarGroup bars={barGraphs} headerSpacing={0} />;
}

export function CharitableTrustDesignerAssetsTable({
  crtProjection,
  cltProjection,
  noPlanProjection,
}: CharitableTrustDesignerAnalysisIllustrationAnalysisProps) {
  const isCRT = useIsCRT();
  const { control } = useFormContext<CharitableTrustDesignerAnalysisForm>();
  const [yearOfAnalysis] = useWatch({
    control,
    name: [YEAR_OF_ANALYSIS_PATH],
  });

  const { columns, rows, footerRow } = getTableDataForProjection({
    isCRT,
    crtProjection,
    cltProjection,
    noPlanProjection,
    yearOfAnalysis,
  });

  return (
    <DisplayTable
      columns={columns}
      Footer={
        <tfoot>
          <AssetsTableRow isFooterRow {...footerRow} />
        </tfoot>
      }
    >
      {rows.map((row, idx) => (
        <AssetsTableRow key={`asset-table-row-${idx}`} {...row} />
      ))}
    </DisplayTable>
  );
}

function AssetsTableRow({
  color,
  label,
  noPlanValue,
  withPlanValue,
  isFooterRow = false,
}: CharitableTrustDesignerIllustrationAssetsTableRowType & {
  isFooterRow?: boolean;
}) {
  const variant = isFooterRow ? 'h5' : ('body1' as const);
  const valueSx: SxProps | undefined = isFooterRow
    ? {
        color: COLORS.NAVY[900],
      }
    : undefined;
  return (
    <StyledTableRow
      sx={{
        borderTop: isFooterRow ? `2px solid ${COLORS.NAVY[600]}` : undefined,
      }}
    >
      <StyledTableCell>
        <ColorBox color={color} />
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant={variant}>{label}</Typography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Typography variant={variant} sx={valueSx}>
          {noPlanValue}
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Typography variant={variant} sx={valueSx}>
          {withPlanValue}
        </Typography>
      </StyledTableCell>
    </StyledTableRow>
  );
}
