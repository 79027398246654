// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { SubstitutionThresholdDetailsv2FragmentDoc } from '../../SubstitutionThresholdSubform/graphql/SubstitutionThresholdSubform.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetGratTrustThresholdsFromModalMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateEntityInput;
}>;


export type SetGratTrustThresholdsFromModalMutation = { __typename?: 'Mutation', updateEntity: { __typename?: 'Entity', id: string, subtype: { __typename?: 'CCorpBusinessEntity', id: string } | { __typename?: 'CLTTrust', id: string } | { __typename?: 'CRTTrust', id: string } | { __typename?: 'CustodialPersonalAccount', id: string } | { __typename?: 'DonorAdvisedFund', id: string } | { __typename?: 'GPBusinessEntity', id: string } | { __typename?: 'GRATTrust', upsideThresholdPercent?: Percent | null, upsideThresholdValue?: CurrencyUSD | null, downsideThresholdPercent?: Percent | null, id: string } | { __typename?: 'ILITTrust', id: string } | { __typename?: 'IndividualPersonalAccount', id: string } | { __typename?: 'InsurancePersonalAccount', id: string } | { __typename?: 'IrrevocableTrust', id: string } | { __typename?: 'JointPersonalAccount', id: string } | { __typename?: 'LLCBusinessEntity', id: string } | { __typename?: 'LPBusinessEntity', id: string } | { __typename?: 'PrivateFoundation', id: string } | { __typename?: 'QPRTTrust', id: string } | { __typename?: 'QualifiedTuitionPersonalAccount', id: string } | { __typename?: 'RetirementPersonalAccount', id: string } | { __typename?: 'RevocableTrust', id: string } | { __typename?: 'SCorpBusinessEntity', id: string } | { __typename?: 'SLATTrust', id: string } | { __typename?: 'SoleProprietorshipBusinessEntity', id: string } } };


export const SetGratTrustThresholdsFromModalDocument = gql`
    mutation SetGRATTrustThresholdsFromModal($input: AugmentedUpdateEntityInput!) {
  updateEntity(input: $input) {
    ...SubstitutionThresholdDetailsv2
  }
}
    ${SubstitutionThresholdDetailsv2FragmentDoc}`;
export type SetGratTrustThresholdsFromModalMutationFn = Apollo.MutationFunction<SetGratTrustThresholdsFromModalMutation, SetGratTrustThresholdsFromModalMutationVariables>;

/**
 * __useSetGratTrustThresholdsFromModalMutation__
 *
 * To run a mutation, you first call `useSetGratTrustThresholdsFromModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGratTrustThresholdsFromModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGratTrustThresholdsFromModalMutation, { data, loading, error }] = useSetGratTrustThresholdsFromModalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetGratTrustThresholdsFromModalMutation(baseOptions?: Apollo.MutationHookOptions<SetGratTrustThresholdsFromModalMutation, SetGratTrustThresholdsFromModalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetGratTrustThresholdsFromModalMutation, SetGratTrustThresholdsFromModalMutationVariables>(SetGratTrustThresholdsFromModalDocument, options);
      }
export type SetGratTrustThresholdsFromModalMutationHookResult = ReturnType<typeof useSetGratTrustThresholdsFromModalMutation>;
export type SetGratTrustThresholdsFromModalMutationResult = Apollo.MutationResult<SetGratTrustThresholdsFromModalMutation>;
export type SetGratTrustThresholdsFromModalMutationOptions = Apollo.BaseMutationOptions<SetGratTrustThresholdsFromModalMutation, SetGratTrustThresholdsFromModalMutationVariables>;
export const Operations = {
  Mutation: {
    SetGRATTrustThresholdsFromModal: 'SetGRATTrustThresholdsFromModal' as const
  }
}