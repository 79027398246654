import { Box, Stack } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { appbarHeight } from '@/components/architecture/Layout/AppBar/AppBar';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { Grid } from '@/components/layout/Grid';
import { Footer } from '@/components/navigation/Footer';
import { PageHeader } from '@/components/navigation/PageHeader';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { DeleteProposalMenuItem } from '@/modules/proposal/components/DeleteProposalMenuItem/DeleteProposalMenuItem';
import { DuplicateProposalMenuItem } from '@/modules/proposal/components/DuplicateProposalMenuItem/DuplicateProposalMenuItem';
import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';
import { ProposalKind } from '@/modules/proposal/types/ProposalKind';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { zIndexes } from '@/styles/zIndexes';

import { ProposalDisclaimer } from './ProposalDisclaimer';

export type ProposalLayoutProps = React.PropsWithChildren<{
  heading: string;
  householdId: string;
  proposalId: string;
  LeftPaneContent: React.ReactElement;
  RightPaneContent: React.ReactElement;
  FooterActionLeftContent: React.ReactElement;
  FooterActionRightContent: React.ReactElement;
  onFormSubmit: () => void;
}>;

export function ProposalLayout({
  heading,
  proposalId,
  householdId,
  LeftPaneContent,
  RightPaneContent,
  FooterActionLeftContent,
  FooterActionRightContent,
  onFormSubmit,
}: ProposalLayoutProps) {
  const leftPaneColumns = 3;
  const navigate = useNavigate();
  const canDeleteProposal = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_DELETE_ENTITIES_AND_PROPOSALS
  );

  const canDuplicateProposal = proposalId !== NEW_PROPOSAL_ID;

  return (
    <Stack height={`calc(100vh - ${appbarHeight})`}>
      <Stack flexGrow={1} overflow="auto">
        <PageHeader
          heading={heading}
          action={
            (canDeleteProposal || canDuplicateProposal) && (
              <ContextMenuButton>
                {canDuplicateProposal && (
                  <DuplicateProposalMenuItem
                    proposalId={proposalId}
                    kind={ProposalKind.Entity}
                    afterDuplicate={(createdProposalId: string) => {
                      navigate(
                        getCompletePathFromRouteKey(
                          ROUTE_KEYS.HOUSEHOLD_PROPOSAL_EDIT,
                          {
                            householdId,
                            proposalId: createdProposalId,
                          }
                        )
                      );
                    }}
                  />
                )}
                {canDeleteProposal && (
                  <DeleteProposalMenuItem
                    label="Delete proposal"
                    proposalId={proposalId}
                    onDelete={() =>
                      navigate(
                        getCompletePathFromRouteKey(
                          ROUTE_KEYS.HOUSEHOLD_DETAILS_PROPOSALS,
                          { householdId }
                        )
                      )
                    }
                  />
                )}
              </ContextMenuButton>
            )
          }
        />
        <Stack data-it="ProposalLayout-childrenContainer" flex={1}>
          <Stack
            component="form"
            noValidate
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              e.preventDefault();
              onFormSubmit();
            }}
            flex={1}
          >
            <Stack p={3} flex={1}>
              <Grid container spacing={0} flex={1} columns={12}>
                <Grid height="100%" item sm={leftPaneColumns}>
                  {LeftPaneContent}
                </Grid>
                <Grid item sm={12 - leftPaneColumns}>
                  {RightPaneContent}
                </Grid>
              </Grid>
            </Stack>
            <Stack p={3}>
              <ProposalDisclaimer />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Box flexShrink={0} zIndex={zIndexes.CARD}>
        <Footer
          leftAction={FooterActionLeftContent}
          rightAction={FooterActionRightContent}
        />
      </Box>
    </Stack>
  );
}
