import { keyBy } from 'lodash';

import { Node, TileNode } from '@/components/diagrams/FlowChart';
import { getBoundingBoxForNodes } from '@/components/diagrams/FlowChart/utils/nodes';
import { AfterDeath } from '@/types/schema';

import { GraphViz_NodeConfigFragment } from '../graphql/GraphVizNodeConfig.fragment.generated';
import {
  isNodeEligibleForGraveyard,
  transformNodeForNodeGraveyard,
} from './nodeGraveyard.utils';

/**
 * @description A position of 0 is equivalent to a layout that has not been yet auto-layouted,
 * in which case we take the existing node position in the graph
 * (implies the config was saved with a non-autolayout state)
 */
export function getNormalizedPosition(
  node: Node,
  config: GraphViz_NodeConfigFragment
): Node['position'] {
  const x = config.xPosition === 0 ? node.position.x : config.xPosition;
  const y = config.yPosition === 0 ? node.position.y : config.yPosition;
  return { x, y };
}

export function transformNonGraveyardNodes(
  node: Node,
  config?: GraphViz_NodeConfigFragment
): Node | null {
  if (!config) return node;
  // Section group layout masnaged by a different process
  if (node.type === 'sectionLabel') return node;
  if (config.hidden) return null;
  return { ...node, position: getNormalizedPosition(node, config) };
}

export interface TransformNodesWithViewConfigInput {
  nodes: Node[];
  nodeConfigurations: GraphViz_NodeConfigFragment[];
}

export function transformNodesWithViewConfig({
  nodes,
  nodeConfigurations,
}: TransformNodesWithViewConfigInput): Node[] {
  const viewConfigByNodeId: Record<
    string,
    // We won't always have a 1:1 mapping of view config to nodes if this is the first time initializing the view,
    // or if nodes haven't been saved yet into the config
    GraphViz_NodeConfigFragment | undefined
  > = keyBy(nodeConfigurations, ({ nodeID }) => nodeID);

  const nonGraveyardNodes = nodes.filter((node) => {
    return !isNodeEligibleForGraveyard(node);
  });

  const graveyardNodes: TileNode[] = nodes.filter(isNodeEligibleForGraveyard);

  const transformedNonGraveyardNodes = nonGraveyardNodes.flatMap((node) => {
    return transformNonGraveyardNodes(node, viewConfigByNodeId[node.id]) ?? [];
  });

  const boundingBoxForNonGraveyardNodes = getBoundingBoxForNodes(
    transformedNonGraveyardNodes
  );

  const graveyardIdxBySection: Record<string, number> = {
    [AfterDeath.None]: 0,
    [AfterDeath.First]: 0,
    [AfterDeath.Second]: 0,
  };
  const transformedGraveyardNodes = graveyardNodes.flatMap((node) => {
    const graveyardIdx =
      graveyardIdxBySection[node.data.sectionLabelId ?? 'unknown'] ?? 0;

    const transformedGraveyardNode = transformNodeForNodeGraveyard(
      node,
      graveyardIdx,
      boundingBoxForNonGraveyardNodes.left // First, graveyard nodes line up with the left of the non-graveyard nodes
    );
    graveyardIdxBySection[node.data.sectionLabelId ?? 'unknown'] += 1;
    return transformedGraveyardNode;
  });

  const boundingBoxForGraveyardNodes = getBoundingBoxForNodes(
    transformedGraveyardNodes
  );

  const widthOfGraveyardNodes =
    boundingBoxForGraveyardNodes.right - boundingBoxForGraveyardNodes.left;

  const widthOfNonGraveyardNodes =
    boundingBoxForNonGraveyardNodes.right -
    boundingBoxForNonGraveyardNodes.left;

  // Get center of non-graveyard nodes
  const sectionCenter =
    boundingBoxForNonGraveyardNodes.left + widthOfNonGraveyardNodes / 2;

  // Shift graveyard nodes to the center of the section
  const transformedGraveyardNodesCentered = transformedGraveyardNodes.map(
    (node) => {
      return {
        ...node,
        position: {
          ...node.position,
          x: node.position.x + sectionCenter - widthOfGraveyardNodes / 2, // Second, take the current node x position, shift it to the right to be indexed on the section center, then shift it back to the left by half the width of the graveyard nodes
        },
      };
    }
  );

  return [
    ...transformedGraveyardNodesCentered,
    ...transformedNonGraveyardNodes,
  ];
}
