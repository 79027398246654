import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { useChartColorDefinitions } from '@/components/charts/constants';
import { LegendItem } from '@/components/charts/Legend/Legend';
import { ColorBox } from '@/components/display/ColorBox/ColorBox';
import { DiagonalSplitColorBox } from '@/components/display/ColorBox/DiagonalSplitColorBox';
import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { Divider } from '@/components/Divider';
import { Button } from '@/components/form/baseInputs/Button';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useModalState } from '@/hooks/useModalState';
import { CreateEntityShortFormModal } from '@/modules/entities/EntityShortFormModal/CreateEntityShortFormModal';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';

import { useHouseholdEstateValueMetrics } from './hooks/useHouseholdEstateValueMetrics';
import { EstateValuePieChart } from './HouseholdEstateValueMetrics.utils';

const currencyFormatOptions: Intl.NumberFormatOptions = {
  notation: 'compact' as const,
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
  currencySign: 'accounting',
};

interface HouseholdEstateValueMetrics {
  totalEstateValue: Decimal;
  inEstateValue: Decimal;
  outOfEstateValue: Decimal;
  outOfEstateCharityValue: Decimal;
  outOfEstateFamilyValue: Decimal;
}

export interface HouseholdEstateValueMetricsProps {
  metrics?: HouseholdEstateValueMetrics | null;
  householdId: string;
  hasEntities: boolean;
}

export function HouseholdEstateValueMetricsPortrait({
  metrics,
  householdId,
  hasEntities,
}: HouseholdEstateValueMetricsProps) {
  const colors = useChartColorDefinitions();
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  const entitiesPageUrl = getCompletePathFromRouteKey(
    ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST,
    { householdId }
  );

  const { pieChartSeriesData, onePercentOfSum } =
    useHouseholdEstateValueMetrics({
      metrics,
    });

  return (
    <>
      {isModalOpen && (
        <CreateEntityShortFormModal
          onClose={closeModal}
          isOpen={isModalOpen}
          householdId={householdId}
        />
      )}

      <Stack px={2} spacing={3} height="100%">
        <OversizedMetricItem
          title="Net value"
          titleVariant="h4"
          valueVariant="h1"
          valueColor={COLORS.NAVY[600]}
          value={
            metrics
              ? formatCurrency(metrics.totalEstateValue, currencyFormatOptions)
              : EMPTY_CONTENT_HYPHEN
          }
        />
        <Stack justifyContent="center" alignItems="center" direction="row">
          <EstateValuePieChart
            pieChartSeriesData={pieChartSeriesData}
            onePercentOfSum={onePercentOfSum}
            size={160}
          />
        </Stack>

        <Stack
          spacing={2}
          divider={<Divider sx={{ borderColor: COLORS.GRAY[200] }} />}
        >
          <Stack spacing={1.5} alignItems="center" direction="row">
            <ColorBox color={colors.PRIMARY.backgroundColor} width={20} />
            <OversizedMetricItem
              title="In estate"
              titleVariant="h4"
              valueVariant="h1"
              valueColor={COLORS.NAVY[600]}
              value={
                metrics
                  ? formatCurrency(metrics.inEstateValue, currencyFormatOptions)
                  : EMPTY_CONTENT_HYPHEN
              }
            />
          </Stack>
          <Stack spacing={1}>
            <Stack spacing={1.5} alignItems="center" direction="row">
              <DiagonalSplitColorBox
                topLeftColor={colors.SECONDARY.backgroundColor}
                bottomRightColor={colors.TERTIARY.backgroundColor}
                width={20}
              />
              <OversizedMetricItem
                title="Out of estate"
                titleVariant="h4"
                valueVariant="h1"
                valueColor={COLORS.NAVY[600]}
                value={
                  metrics
                    ? formatCurrency(
                        metrics.outOfEstateValue,
                        currencyFormatOptions
                      )
                    : EMPTY_CONTENT_HYPHEN
                }
              />
            </Stack>

            <Stack direction="row" spacing={3} pl="32px">
              <LegendItem
                primaryText={
                  <Typography variant="label">
                    {formatCurrency(
                      metrics?.outOfEstateFamilyValue ?? new Decimal(0),
                      currencyFormatOptions
                    )}
                  </Typography>
                }
                secondaryText="Family giving"
                legendItemColor={colors.SECONDARY.backgroundColor}
              />

              <LegendItem
                primaryText={
                  <Typography variant="label">
                    {formatCurrency(
                      metrics?.outOfEstateCharityValue ?? new Decimal(0),
                      currencyFormatOptions
                    )}
                  </Typography>
                }
                secondaryText="Charitable giving"
                legendItemColor={colors.TERTIARY.backgroundColor}
              />
            </Stack>
          </Stack>
        </Stack>
        <Box flexGrow={1} />
        <Stack direction="row" justifyContent="end">
          {hasEntities && (
            <Button variant="secondary" size="sm" href={entitiesPageUrl}>
              View all entities
            </Button>
          )}
          {!hasEntities && (
            <Button variant="primary" size="lg" fullWidth onClick={openModal}>
              Add an entity to get started
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  );
}
