import { PrintOnlyAssetsCard } from '@/modules/entities/details/shared/ViewOnlyAssetsCard';
import { getRevocableIrrevocableTrustSummaryProperties } from '@/modules/entities/details/trusts/RevocableIrrevocableTrustDetails';
import { getEntityTypeFromSubtype } from '@/modules/entities/utils/getEntityTypeFromSubtype';

import { TrustOverviewSlide } from '../components/Trust.OverviewSlide';
import { RevocableIrrevocableTrustPresentationSlideProps } from './RevocableIrrevocableTrust.types';

export function RevocableIrrevocableTrustOverviewSlide({
  entity,
  subtype,
  ...registrationProps
}: RevocableIrrevocableTrustPresentationSlideProps) {
  const summaryDetails = getRevocableIrrevocableTrustSummaryProperties({
    entity,
    entityType: getEntityTypeFromSubtype(subtype.__typename!),
  });

  return (
    <TrustOverviewSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      displayName={subtype.displayName}
      AssetCard={<PrintOnlyAssetsCard entity={entity} />}
    />
  );
}
