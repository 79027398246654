import { AugmentedUpdateEntityInput, UpdateEntityInput } from '@/types/schema';
import { AugmentedUpdateAccountInput } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { EntityType } from '../types/EntityType';

/**
 * @description this type should only reflect the properties that are shared between *all entity subtypes*
 */
export interface AugmentedPartialSubtypeUpdateInput {
  id: string;
  updateDesignerAccount: AugmentedUpdateAccountInput;
}

/**
 * @description the intent of this function is to allow for a type-agnostic entry point to update entities
 * of all types outside of the bounds of the entity form framework.
 */

export function makeEntityTypeUniversalUpdateInput(
  entityId: string,
  entityType: EntityType,
  updateSubtypeInput: AugmentedPartialSubtypeUpdateInput,
  updateEntityInput?: UpdateEntityInput
): AugmentedUpdateEntityInput {
  const sharedProperties = {
    id: entityId,
    update: updateEntityInput || {},
  };

  const updateEntitySubtypeSharedProperties = {
    update: {},
    ...updateSubtypeInput,
  };

  switch (entityType) {
    case 'revocable-trust':
      return {
        ...sharedProperties,
        updateRevocableTrust: updateEntitySubtypeSharedProperties,
      };
    case 'irrevocable-trust':
      return {
        ...sharedProperties,
        updateIrrevocableTrust: updateEntitySubtypeSharedProperties,
      };
    case 'slat':
      return {
        ...sharedProperties,
        updateSlatTrust: updateEntitySubtypeSharedProperties,
      };
    case 'grat':
      return {
        ...sharedProperties,
        updateGratTrust: updateEntitySubtypeSharedProperties,
      };
    case 'qprt':
      return {
        ...sharedProperties,
        updateQprtTrust: updateEntitySubtypeSharedProperties,
      };
    case 'ilit':
      return {
        ...sharedProperties,
        updateIlitTrust: updateEntitySubtypeSharedProperties,
      };
    case 'individual-account':
      return {
        ...sharedProperties,
        updateIndividualPersonalAccount: updateEntitySubtypeSharedProperties,
      };
    case 'joint-account':
      return {
        ...sharedProperties,
        updateJointPersonalAccount: updateEntitySubtypeSharedProperties,
      };
    case 'custodial-account':
      return {
        ...sharedProperties,
        updateCustodialPersonalAccount: updateEntitySubtypeSharedProperties,
      };
    case 'retirement-account':
      return {
        ...sharedProperties,
        updateRetirementPersonalAccount: updateEntitySubtypeSharedProperties,
      };
    case 'qualified-tuition-account':
      return {
        ...sharedProperties,
        updateQualifiedTuitionPersonalAccount:
          updateEntitySubtypeSharedProperties,
      };
    case 'clt':
      return {
        ...sharedProperties,
        updateCltTrust: updateEntitySubtypeSharedProperties,
      };
    case 'crt':
      return {
        ...sharedProperties,
        updateCrtTrust: updateEntitySubtypeSharedProperties,
      };
    case 'daf':
      return {
        ...sharedProperties,
        updateDonorAdvisedFund: updateEntitySubtypeSharedProperties,
      };
    case 'private-foundation':
      return {
        ...sharedProperties,
        updatePrivateFoundation: updateEntitySubtypeSharedProperties,
      };

    case 'sole-proprietorship':
      return {
        ...sharedProperties,
        updateSoleProprietorshipBusinessEntity:
          updateEntitySubtypeSharedProperties,
      };

    case 'c-corp':
      return {
        ...sharedProperties,
        updateCcorpBusinessEntity: updateEntitySubtypeSharedProperties,
      };

    case 's-corp':
      return {
        ...sharedProperties,
        updateScorpBusinessEntity: updateEntitySubtypeSharedProperties,
      };

    case 'llc':
      return {
        ...sharedProperties,
        updateLlcBusinessEntity: updateEntitySubtypeSharedProperties,
      };

    case 'lp':
      return {
        ...sharedProperties,
        updateLpBusinessEntity: updateEntitySubtypeSharedProperties,
      };

    case 'gp':
      return {
        ...sharedProperties,
        updateGpBusinessEntity: updateEntitySubtypeSharedProperties,
      };

    case 'insurance-account':
      return {
        ...sharedProperties,
        updateInsurancePersonalAccount: updateEntitySubtypeSharedProperties,
      };

    default:
      throw new UnreachableError({
        case: entityType,
        message: `Unrecognized entity type ${entityType}`,
      });
  }
}
