import { Box, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { useSearchParams } from 'react-router-dom';

import { LinkButton } from '@/components/form/baseInputs/Link';
import { Callout } from '@/components/notifications/Callout/Callout';
import { ASSET_CARD_WIDTH_PX } from '@/modules/assets/AssetDetailCard/AssetDetailCard';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { GratProposalDetailsCard } from '@/pages/client-proposal/slides/GratProposalDetailsCard/GratProposalDetailsCard';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { ADVISOR_PREVIEW_PARAM_NAME } from '../hooks/useIsAdvisorPreview';
import { SlideContainer } from './SlideContainer';

export function CombinedOverview({ proposal }: { proposal: ProposalFragment }) {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const totalSum = proposal.entityProposals?.reduce((acc, entityProposal) => {
    return acc.plus(
      entityProposal.entity.gratTrust?.currentValue ?? new Decimal(0)
    );
  }, new Decimal(0));

  return (
    <SlideContainer
      id="combined-overview"
      sx={{
        paddingX: 11,
        paddingTop: 6,
      }}
      autoZoomOnPrint={true}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack spacing={1}>
          <Typography variant="h1" color={theme.palette.primary.main}>
            What&apos;s included in this proposal
          </Typography>
          <Typography variant="body1">
            The Grantor Retained Annuity Trusts (GRATs) outlined below are
            included in this proposal. Click &apos;view details&apos; to skip to
            a specific GRAT or use the navigation in the righthand corner to
            proceed.
          </Typography>
        </Stack>
      </Stack>
      <Stack
        p={4}
        width={'100%'}
        flexDirection="row"
        justifyContent="center"
        sx={{
          borderBottom: `solid ${theme.palette.grey[500]} 1px`,
        }}
      >
        <Box
          sx={{
            width: '100%',
            padding: 3,
          }}
        >
          <Stack flexDirection="column" spacing={2}>
            <Box
              display="grid"
              gridAutoRows="1fr"
              padding={2}
              gap={3}
              gridTemplateColumns={`repeat(auto-fill, minmax(${ASSET_CARD_WIDTH_PX}px, 1fr))`}
              width="100%"
              justifyItems="center"
            >
              {proposal.entityProposals?.map((entityProposal, idx) => {
                if (!entityProposal?.entity?.gratTrust) return null;
                return (
                  <GratProposalDetailsCard
                    key={entityProposal.entity.gratTrust?.id ?? `entity-${idx}`}
                    grat={entityProposal?.entity?.gratTrust}
                    entityId={entityProposal.entity.id}
                    footer={
                      <LinkButton
                        display="View details"
                        onClick={() => {
                          const isPreview = !!searchParams.get(
                            ADVISOR_PREVIEW_PARAM_NAME
                          );

                          if (isPreview) {
                            setSearchParams({
                              entity: entityProposal.entity.id,
                              preview: 'true',
                            });
                          } else {
                            setSearchParams({
                              entity: entityProposal.entity.id,
                            });
                          }
                        }}
                      />
                    }
                  />
                );
              })}
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Stack
        flexDirection="row"
        py={4}
        px={8}
        alignItems="baseline"
        justifyContent="space-between"
      >
        <Typography variant="h4">Total assets analyzed</Typography>
        <Typography variant="h1">
          {formatCurrencyNoDecimals(totalSum ?? new Decimal(0))}
        </Typography>
      </Stack>
      <Callout severity="info-low">
        The only assets included in this analysis are assets used to fund the
        GRAT strategy analyzed. This analysis does not include any other assets
        held by the grantor.
      </Callout>
    </SlideContainer>
  );
}
