import { Stack, Typography } from '@mui/material';
import { BaseSyntheticEvent } from 'react';
import { UseFieldArrayInsert, UseFieldArrayReturn } from 'react-hook-form';

import { PresentationChart01Icon } from '@/components/icons/PresentationChart01Icon';
import { useFormContext } from '@/components/react-hook-form';
import { COLORS } from '@/styles/tokens/colors';

import { GiftDesignerModelScenariosFormShape } from '../../GiftDesignerModelScenariosForm.types';
import { AddGiftScenarioButton } from '../AddGiftScenarioButton';
import { GiftScenario } from './GiftScenario';

type GiftScenariosProps = UseFieldArrayReturn<
  GiftDesignerModelScenariosFormShape,
  'scenarios',
  '_id'
> & {
  triggerSave: (
    e?: BaseSyntheticEvent<object, unknown, unknown> | undefined
  ) => Promise<void>;
  proposalId: string;
  householdId: string;
  duplicate: UseFieldArrayInsert<
    GiftDesignerModelScenariosFormShape,
    'scenarios'
  >;
};

export const MAX_GIFT_SCENARIOS_COUNT = 4;

export function GiftScenarios(props: GiftScenariosProps) {
  const { fields: scenarios, append } = props;

  const {
    formState: { isLoading },
  } = useFormContext<GiftDesignerModelScenariosFormShape>();

  return (
    <Stack spacing={3}>
      {scenarios?.map((scenario, idx) => (
        <GiftScenario
          key={scenario._id}
          idx={idx}
          scenario={scenario}
          {...props}
        />
      ))}
      {!isLoading && scenarios?.length === 0 && (
        <Stack spacing={1} alignItems="center">
          <PresentationChart01Icon color={COLORS.GRAY[200]} size={48} />
          <Typography variant="h1" component="h3">
            Add a scenario
          </Typography>
          <Typography variant="body2">
            Create up to 4 unique scenarios
          </Typography>
        </Stack>
      )}
      <AddGiftScenarioButton
        appendGiftScenario={append}
        giftScenarioCount={scenarios.length}
        disabled={isLoading}
      />
    </Stack>
  );
}
