import Decimal from 'decimal.js';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { AfterDeath } from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import {
  EstateTaxSummaryPanel_EstateWaterfallFragment,
  EstateTaxSummaryPanel_TaxSummaryFragment,
  useEstateTaxSummaryPanelQuery,
} from '../graphql/EstateTaxSummaryPanel.generated';

interface GetSummaryPanelDataForDeathTargetInput {
  estateWaterfall?: EstateTaxSummaryPanel_EstateWaterfallFragment;
  afterDeath: AfterDeath;
}

export function getSummaryPanelDataForDeathTarget({
  estateWaterfall,
  afterDeath,
}: GetSummaryPanelDataForDeathTargetInput) {
  const isTwoPrimaryClientWaterfall =
    estateWaterfall?.household?.possiblePrimaryClients.length === 2;

  const nodeAfterDeathTarget =
    afterDeath === AfterDeath.First ? AfterDeath.None : AfterDeath.First;

  const deathYear =
    afterDeath === AfterDeath.First
      ? estateWaterfall?.firstGrantorDeathYear
      : estateWaterfall?.secondGrantorDeathYear;

  // Filter nodes to ones with an estate tax value, and which match the death payout order
  const viz = estateWaterfall?.visualizationWithProjections;

  // Get the remaining lifetime exemption that was used for computations for this death
  const remainingLifetimeExemption =
    afterDeath === AfterDeath.First
      ? viz?.firstDeathRemainingLifetimeExemption
      : viz?.secondDeathRemainingLifetimeExemption;

  const allNodes = viz?.nodes || [];

  const nodesForDeathTarget = (viz?.nodes || []).filter(
    (n) => n.afterDeath === nodeAfterDeathTarget
  );

  const giftTaxNodes = allNodes.filter((n) => n.transferredToGiftingTax?.gt(0));

  const stateTaxNodes = [] as typeof giftTaxNodes;

  const taxBeforeFirstDeath = sumDecimalJS(
    // Tax before first death is only from gift tax
    giftTaxNodes.map((n) => n.transferredToGiftingTax ?? new Decimal(0))
  );

  const taxAfterFirstDeath = sumDecimalJS(
    // First death tax comes from the nodes in the none section
    allNodes.map((n) => {
      if (n.afterDeath !== AfterDeath.None) {
        return new Decimal(0);
      }

      return n.transferredToEstateTax ?? new Decimal(0);
    })
  );

  const taxAfterSecondDeath = sumDecimalJS(
    // Second death tax comes from the nodes in the first death section
    allNodes.map((n) => {
      if (n.afterDeath !== AfterDeath.First) {
        return new Decimal(0);
      }

      return n.transferredToEstateTax ?? new Decimal(0);
    })
  );

  const cumulativeTaxMetrics = {
    taxBeforeFirstDeath,
    taxAfterFirstDeath,
    taxAfterSecondDeath: isTwoPrimaryClientWaterfall
      ? taxAfterSecondDeath
      : undefined,
  };

  return {
    nodesForDeathTarget,
    giftTaxNodes,
    stateTaxNodes,
    cumulativeTaxMetrics,
    waterfall: estateWaterfall,
    deathYear,
    remainingLifetimeExemption,
    payoutKind: viz?.estateTaxScenario?.payoutKind,
    lifetimeExemptionAppliedTowardsGiftTax:
      viz?.lifetimeExemptionAppliedTowardsGiftTax,
  };
}

export interface UseSummaryPanelQueryInput {
  householdId: string;
  waterfallId: string;
}

export function useSummaryPanelQuery({
  householdId,
  waterfallId,
}: UseSummaryPanelQueryInput) {
  const { showFeedback } = useFeedback();

  const { data, ...rest } = useEstateTaxSummaryPanelQuery({
    fetchPolicy: 'cache-and-network', // We can use cache-and-network here because we have a custom merge policy for the visualizationWithProjections field
    variables: {
      where: { id: waterfallId },
    },
    onError: (error) => {
      showFeedback(
        `Could not get tax information. Please refresh the page and try again.`
      );
      diagnostics.error(`failed to fetch estate tax summary info`, error, {
        waterfallId,
        householdId,
      });
    },
  });

  const firstDeathData = useMemo(() => {
    const estateWaterfall = getNodes(data?.estateWaterfalls)[0] ?? undefined;

    return getSummaryPanelDataForDeathTarget({
      estateWaterfall,
      afterDeath: AfterDeath.First,
    });
  }, [data]);

  const secondDeathData = useMemo(() => {
    const estateWaterfall = getNodes(data?.estateWaterfalls)[0] ?? undefined;

    return getSummaryPanelDataForDeathTarget({
      estateWaterfall,
      afterDeath: AfterDeath.Second,
    });
  }, [data]);

  const taxSummary: {
    beforeFirstDeath: Maybe<EstateTaxSummaryPanel_TaxSummaryFragment>;
    firstDeath: Maybe<EstateTaxSummaryPanel_TaxSummaryFragment>;
    secondDeath: Maybe<EstateTaxSummaryPanel_TaxSummaryFragment>;
  } = useMemo(() => {
    const estateWaterfall = getNodes(data?.estateWaterfalls)[0] ?? undefined;

    return {
      beforeFirstDeath:
        estateWaterfall?.visualizationWithProjections
          .beforeFirstDeathTaxSummary,
      firstDeath:
        estateWaterfall?.visualizationWithProjections.firstDeathTaxSummary,
      secondDeath:
        estateWaterfall?.visualizationWithProjections.secondDeathTaxSummary,
    };
  }, [data]);

  return { data, firstDeathData, secondDeathData, taxSummary, ...rest };
}
