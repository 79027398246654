import Decimal from 'decimal.js';
import { first } from 'lodash';

import { getCreateDraftEntityInput } from '@/modules/entities/draftEntities/getCreateDraftEntityInput';
import { getTypeOrUndefined } from '@/modules/entities/EntitySubforms/utils/shared/common.utils';
import { getNodes } from '@/utils/graphqlUtils';

import { HypotheticalTransferModalQuery } from '../graphql/HypotheticalTransferModal.generated';
import {
  AnnuallyRecurringValue,
  HypotheticalTransferFormModalShape,
  HypotheticalTransferUpdateEstateWaterfallPayload,
} from '../HypotheticalTransferModal.types';
import {
  getHypotheticalTransferDestination,
  getHypotheticalTransferSource,
  getHypotheticalTransferTaxDetails,
} from '../HypotheticalTransferModal.utils';

export function getCreatePayload(
  formData: HypotheticalTransferFormModalShape,
  waterfallData: HypotheticalTransferModalQuery | undefined,
  householdId: string
): HypotheticalTransferUpdateEstateWaterfallPayload {
  const waterfall = first(getNodes(waterfallData?.estateWaterfalls));

  const destination = getHypotheticalTransferDestination(formData);
  const source = getHypotheticalTransferSource(formData);
  const { transferTaxKind, giftingGrantorIDs } =
    getHypotheticalTransferTaxDetails(formData);

  if (!waterfall) {
    return { message: 'Could not get waterfall details' };
  } else if (!destination) {
    return { message: 'Could not get destination details' };
  } else if (!source) {
    return { message: 'Could not get source details' };
  } else if (!transferTaxKind) {
    return { message: 'Could not get transfer tax kind' };
  }

  let startYear: number | undefined = undefined;
  let endYear: number | undefined = undefined;
  if (formData.transferFrequency === AnnuallyRecurringValue.true) {
    startYear = getTypeOrUndefined<number>(formData.transferStartYear);
    endYear = getTypeOrUndefined<number>(formData.transferEndYear);
  } else {
    startYear = getTypeOrUndefined<number>(formData.transferStartYear);
    endYear = getTypeOrUndefined<number>(formData.transferStartYear);
  }

  const transferCount = waterfall.allTransfers?.totalCount || 0;
  const withDestinationEntity = (() => {
    if (!formData.transferToNewDraft || !formData.draftEntityType) {
      return undefined;
    }

    return getCreateDraftEntityInput({
      draftEntityType: formData.draftEntityType,
      draftEntityName: formData.draftEntityName,
      draftEntityGrantorId: formData.draftEntityGrantorId,
      householdId,
    });
  })();
  // getWithDestinationEntity(formData, householdId);

  const output = {
    id: waterfall.id,
    update: {},
    withHypotheticalTransfers: [
      {
        create: {
          ...destination,
          ...source,
          transferTaxKind,
          taxDiscount: formData._discountRateToggle
            ? getTypeOrUndefined<Decimal>(formData.taxDiscountRate)
            : undefined,
          name: getTypeOrUndefined<string>(formData.transferName),
          transferValue: getTypeOrUndefined<Decimal>(formData.transferAmount),
          taxableValue: getTypeOrUndefined<Decimal>(formData.taxableValue),
          isGstGift: !!formData.transferGstGift,
          assetGrowthReturn: getTypeOrUndefined<Decimal>(
            formData.transferGrowthRate
          ),
          transferOrder: transferCount,
          giftingGrantorIDs,
          startYear,
          endYear,
        },
        withDestinationEntity,
      },
    ],
  };

  return { input: output };
}
