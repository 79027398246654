// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { ClientProfileBasicFragmentDoc } from '../../../../clientProfiles/graphql/ClientProfile.generated';
export type TrusteeDropdown_HouseholdFragment = { __typename?: 'Household', id: string, possibleTrusteesV2: { __typename?: 'PossibleTrusteesV2', clients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, legalName: string, firstName: string }>, organizations: Array<{ __typename?: 'ClientOrganization', id: string, name: string }> } };

export const TrusteeDropdown_HouseholdFragmentDoc = gql`
    fragment TrusteeDropdown_Household on Household {
  id
  possibleTrusteesV2 {
    clients {
      ...ClientProfileBasic
    }
    organizations {
      id
      name
    }
  }
}
    ${ClientProfileBasicFragmentDoc}`;
export const Operations = {
  Fragment: {
    TrusteeDropdown_Household: 'TrusteeDropdown_Household' as const
  }
}