import { ApolloQueryResult } from '@apollo/client';
import { createContext, ReactNode } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

import {
  CashflowsQuery,
  CashflowsQueryHookResult,
} from './graphql/ClientDetailsManageIncomeExpenses.generated';

export interface ClientDetailsManageIncomeExpensesPageContextShape {
  refetch: CashflowsQueryHookResult['refetch'];
  householdId: string;
}

export type ClientDetailsManageIncomeExpensesPageContextProviderProps =
  ClientDetailsManageIncomeExpensesPageContextShape & {
    children: ReactNode;
  };

const ClientDetailsManageIncomeExpensesPageContext = createContext<
  ClientDetailsManageIncomeExpensesPageContextShape | undefined
>({
  refetch: () => {
    return Promise.resolve({} as ApolloQueryResult<CashflowsQuery>);
  },
  householdId: '',
});

export function ClientDetailsManageIncomeExpensesPageContextProvider({
  children,
  ...contextValues
}: ClientDetailsManageIncomeExpensesPageContextProviderProps) {
  return (
    <ClientDetailsManageIncomeExpensesPageContext.Provider
      value={contextValues}
    >
      {children}
    </ClientDetailsManageIncomeExpensesPageContext.Provider>
  );
}

export function useClientDetailsManageIncomeExpensesPageContext() {
  return useGuardedContext(
    ClientDetailsManageIncomeExpensesPageContext,
    'ClientDetailsManageIncomeExpensesPageContextProvider'
  );
}
