import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { getNodes } from '@/utils/graphqlUtils';

import { useLifetimeExemptionCardQuery as useLifetimeExemptionCardQueryInternal } from '../graphql/LifetimeExemptionCard.generated';

const ERROR_FEEDBACK_MESSAGE = `Sorry, we failed to load the gifting page. Please refresh the page and try again.`;

export function useLifetimeExemptionCardQuery(householdId: string) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const queryProps = useLifetimeExemptionCardQueryInternal({
    variables: {
      householdWhere: { id: householdId },
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      showFeedback(ERROR_FEEDBACK_MESSAGE);
      reportError('useLifetimeExemptionCardQuery load failure', error, {
        householdId,
      });
    },
    onCompleted: (data) => {
      const household = getNodes(data?.households)[0];
      if (!household) {
        showFeedback(ERROR_FEEDBACK_MESSAGE);
        reportError(
          'useLifetimeExemptionCardQuery load failure, no household found',
          undefined,
          {
            householdId,
          }
        );
      }
    },
  });

  const household = getNodes(queryProps.data?.households)[0];

  return {
    ...queryProps,
    household,
    // Override queryProps.loading with our own loading state
    // since this hook should only be loading if there is no
    // advisorClient state.
    loading: !household,
  };
}
