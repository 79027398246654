import { InputAdornment } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { User01Icon } from '@/components/icons/User01Icon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { BENEFICIARY_DISTRIBUTION_FREQUENCY_ITEMS_V2 } from '@/modules/beneficiaries/beneficiaries.constants';
import { AddNewAnythingModal } from '@/modules/common/AddNewAnythingModal/AddNewAnythingModal';
import { EntityDocumentViewerDocument } from '@/modules/documents/components/HouseholdDocumentsViewer/graphql/EntityDocumentViewer.generated';
import { AccessParameterKind } from '@/types/schema';

import {
  UNITRUST_TYPE_ITEMS_V2,
  validUnitrustTypesV2,
} from './EntityBeneficiariesSubform.constants';
import {
  EntityBeneficiariesFormBodyProps,
  EntityBeneficiariesSubformFields,
  EntityBeneficiariesSubformShape,
  EntityBeneficiariesSubformSubformPath,
} from './EntityBeneficiariesSubform.types';

interface CharitableBeneficaryFormBodyProps {
  control: EntityBeneficiariesFormBodyProps['control'];
  beneficiaryOptions: EntityBeneficiariesFormBodyProps['beneficiaryOptions'];
  householdId: EntityBeneficiariesFormBodyProps['householdId'];
  namespace:
    | 'leadBeneficiarySubform.leadBeneficiary'
    | 'incomeBeneficiarySubform.incomeBeneficiary';
  splitScreen: boolean;
}

export function CharitableBeneficiaryFormBodyV2({
  control,
  beneficiaryOptions,
  householdId,
  namespace,
  splitScreen,
}: CharitableBeneficaryFormBodyProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [newlyCreatedId, setNewlyCreatedId] = useState('');

  const isClt = namespace === 'leadBeneficiarySubform.leadBeneficiary';

  const { setValue, setShouldBlockNavigation } = useFormContext<{
    leadBeneficiarySubform: {
      leadBeneficiary: EntityBeneficiariesSubformShape['leadBeneficiary'];
    };
    incomeBeneficiarySubform: {
      incomeBeneficiary: EntityBeneficiariesSubformShape['incomeBeneficiary'];
    };
  }>();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const currentTab = useWatch({
    name: `${namespace}.charitableBeneficiaryType` as const satisfies EntityBeneficiariesSubformSubformPath,
    control,
  });

  // our app currently makes the assumption that there will only be one distribution for a given
  // charitable beneficiary, so we can assume index 0.
  const beneficiaryType: AccessParameterKind = useWatch({
    name: `${namespace}.beneficiaryFormAccessParameters.accessParametersPartial.${0}.kind` as const satisfies EntityBeneficiariesSubformSubformPath,
    control,
  });

  const currentBeneficiary = useWatch({
    name: `${namespace}` as const satisfies EntityBeneficiariesSubformSubformPath,
    control,
  });

  // generally speaking, it's not required to select a beneficiary. however, we want to make sure that
  // a user isn't losing valuable data that they've entered because they forget to select the underlying beneficiary.
  // so, we mark the beneficiary dropdown as "required" if the user has entered data in what we deem to be
  // "high-value" fields in the subform.
  const beneficiarySelectionRequired = useMemo(() => {
    if (!currentBeneficiary) return false;
    if (currentTab === 'unitrust') {
      return Boolean(
        currentBeneficiary?.beneficiaryFormAccessParameters
          ?.accessParametersPartial?.[0]?.percentage
      );
    } else {
      return Boolean(
        currentBeneficiary?.beneficiaryFormAccessParameters
          ?.accessParametersPartial?.[0]?.amount
      );
    }
  }, [currentBeneficiary, currentTab]);

  useEffect(
    function setTypeWhenUnitrust() {
      if (currentTab === 'unitrust') {
        // set the type to percentage if the selected type isn't valid for unitrust
        if (!validUnitrustTypesV2.includes(beneficiaryType)) {
          setValue(
            // our app currently makes the assumption that there will only be one distribution for a given
            // charitable beneficiary, so we can assume index 0.
            `${namespace}.beneficiaryFormAccessParameters.accessParametersPartial.${0}.kind` as const satisfies EntityBeneficiariesSubformSubformPath,
            AccessParameterKind.Percentage,
            { shouldDirty: true }
          );
        }
      } else {
        setValue(
          // our app currently makes the assumption that there will only be one distribution for a given
          // charitable beneficiary, so we can assume index 0.
          `${namespace}.beneficiaryFormAccessParameters.accessParametersPartial.${0}.kind` as const satisfies EntityBeneficiariesSubformSubformPath,
          AccessParameterKind.Amount,
          { shouldDirty: true }
        );
      }
    },
    [currentTab, beneficiaryType, setValue, namespace]
  );

  useEffect(() => {
    if (splitScreen && newlyCreatedId && !isOpen) {
      // Closing the modal after creating a new entity/individual/organization
      // should block navigation for the parent form.
      setShouldBlockNavigation(true);
      setNewlyCreatedId('');
    }
  }, [newlyCreatedId, setShouldBlockNavigation, isOpen, splitScreen]);

  return (
    <>
      <AddNewAnythingModal
        addableTypes={['entity', 'individual', 'organization']}
        handleClose={handleClose}
        isOpen={isOpen}
        householdId={householdId}
        onAfterCreate={setNewlyCreatedId}
        addClientProfileModalProps={{
          ignoredQueryDocuments: [EntityDocumentViewerDocument],
        }}
        addClientOrganizationModalProps={{
          ignoredQueryDocuments: [EntityDocumentViewerDocument],
        }}
      />
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <FormAwareButtonGroup<EntityBeneficiariesSubformFields>
            label=""
            fieldName={
              `${namespace}.charitableBeneficiaryType` as const satisfies EntityBeneficiariesSubformSubformPath
            }
            control={control}
            options={[
              {
                display: 'Annuity',
                value: 'annuity',
              },
              {
                display: 'Unitrust',
                value: 'unitrust',
              },
            ]}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {currentTab === 'annuity' && (
        <>
          <FormLayoutRow>
            <FormLayoutItem width={8}>
              <FormAwareCurrencyInput<EntityBeneficiariesSubformFields>
                label="Distribution amount"
                fieldName={
                  `${namespace}.beneficiaryFormAccessParameters.accessParametersPartial.${0}.amount` as const satisfies EntityBeneficiariesSubformSubformPath
                }
                control={control}
                isDecimalJSInput
              />
            </FormLayoutItem>
            <FormLayoutItem width={4}>
              <FormAwareSelectInput<EntityBeneficiariesSubformFields>
                label="Distribution frequency"
                fieldName={
                  `${namespace}.beneficiaryFormAccessParameters.accessParametersPartial.${0}.frequency` as const satisfies EntityBeneficiariesSubformSubformPath
                }
                control={control}
                options={BENEFICIARY_DISTRIBUTION_FREQUENCY_ITEMS_V2}
              />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              <FormAwareSelectInput
                control={control}
                required={beneficiarySelectionRequired}
                label="Beneficiary"
                fieldName={
                  `${namespace}.beneficiaryId` as const satisfies EntityBeneficiariesSubformSubformPath
                }
                addNewOption={{
                  onClick: () => setIsOpen(true),
                }}
                options={beneficiaryOptions}
                startAdornment={
                  <InputAdornment position="start">
                    <User01Icon size={20} />
                  </InputAdornment>
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </>
      )}
      {currentTab === 'unitrust' && (
        <>
          <FormLayoutRow>
            {!isClt && (
              <FormLayoutItem width={12}>
                <FormAwareRadioGroup<EntityBeneficiariesSubformFields>
                  label=""
                  fieldName={
                    `${namespace}.beneficiaryFormAccessParameters.accessParametersPartial.${0}.kind` as const satisfies EntityBeneficiariesSubformSubformPath
                  }
                  control={control}
                  options={UNITRUST_TYPE_ITEMS_V2}
                  row
                />
              </FormLayoutItem>
            )}
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem width={8}>
              <FormAwarePercentInput<EntityBeneficiariesSubformFields>
                label="Distribution percentage"
                fieldName={
                  `${namespace}.beneficiaryFormAccessParameters.accessParametersPartial.${0}.percentage` as const satisfies EntityBeneficiariesSubformSubformPath
                }
                control={control}
                isDecimalJSInput
              />
            </FormLayoutItem>
            <FormLayoutItem width={4}>
              <FormAwareSelectInput<EntityBeneficiariesSubformFields>
                label="Distribution frequency"
                fieldName={
                  `${namespace}.beneficiaryFormAccessParameters.accessParametersPartial.${0}.frequency` as const satisfies EntityBeneficiariesSubformSubformPath
                }
                control={control}
                options={BENEFICIARY_DISTRIBUTION_FREQUENCY_ITEMS_V2}
              />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              <FormAwareSelectInput
                control={control}
                label="Beneficiary"
                required={beneficiarySelectionRequired}
                fieldName={
                  `${namespace}.beneficiaryId` as const satisfies EntityBeneficiariesSubformSubformPath
                }
                addNewOption={{
                  onClick: () => setIsOpen(true),
                }}
                options={beneficiaryOptions}
                startAdornment={
                  <InputAdornment position="start">
                    <User01Icon size={20} />
                  </InputAdornment>
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </>
      )}
    </>
  );
}
