import { ApolloError } from '@apollo/client';
import { useCallback, useState } from 'react';

import { Accordion } from '@/components/Accordion/Accordion';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { useReportError } from '@/hooks/useReportError';

import { isIgnorableProjectionError } from './CharitableTrustDesignerAnalysis.utils';
import {
  CharitableTrustDesignerDistributionTableRow,
  mapDataToCharitableTrustDesignerDistributionTableRows,
} from './CharitableTrustDesignerDistributionTable.utils';
import {
  CharitableTrustDesignerDistributionTableDataQuery,
  useCharitableTrustDesignerDistributionTableDataLazyQuery,
} from './graphql/CharitableTrustDesignerDistributionTableData.generated';
import {
  BaseCharitableProjectionVariables,
  useCharitableProjection,
} from './hooks/useCharitableProjection';

export function CharitableTrustDesignerDistributionTable() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [rows, setRows] = useState<
    CharitableTrustDesignerDistributionTableRow[]
  >([]);

  const [isOpen, setOpen] = useState<boolean>(false);

  const [charitableProjectionLazyQuery] =
    useCharitableTrustDesignerDistributionTableDataLazyQuery({
      onError: (error: ApolloError) => {
        // do not show the error banner if the path includes the projection, as that's a valid case
        if (isIgnorableProjectionError(error)) {
          return;
        }
        showFeedback('Could not load distribution table');
        reportError('Could not load distribution table', error);
      },
    });

  const setRowsFromData = useCallback(
    (data: CharitableTrustDesignerDistributionTableDataQuery) => {
      setRows(mapDataToCharitableTrustDesignerDistributionTableRows(data));
    },
    []
  );

  const getVariables = useCallback(
    (baseVariables: BaseCharitableProjectionVariables) => ({
      ...baseVariables,
      isOpen,
    }),
    [isOpen]
  );

  useCharitableProjection(
    charitableProjectionLazyQuery,
    setRowsFromData,
    getVariables
  );

  return (
    <Accordion
      variant="default"
      title="Distribution schedule"
      onChange={setOpen}
    >
      <DataTable
        columns={[
          {
            field: 'year',
            headerName: 'Year',
            width: 64,
            renderCell: TextRenderer({
              textProps: {
                sx: { fontWeight: 'bold' },
              },
            }),
          },
          {
            field: 'beginningPrincipal',
            headerName: 'Beginning principal',
            flex: 1,
          },
          {
            field: 'principalGrowth',
            headerName: 'Principal growth',
            flex: 1,
          },
          {
            field: 'incomePayment',
            headerName: 'Income payment',
            flex: 1,
          },
          {
            field: 'remainderValue',
            headerName: 'Remainder value',
            flex: 1,
          },
        ]}
        rows={rows}
        pageSize={10}
      />
    </Accordion>
  );
}
