// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SeedAiOnboardingSuggestionsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type SeedAiOnboardingSuggestionsMutation = { __typename?: 'Mutation', seedAIOnboardingSuggestions: { __typename?: 'Household', id: string } };


export const SeedAiOnboardingSuggestionsDocument = gql`
    mutation SeedAIOnboardingSuggestions {
  seedAIOnboardingSuggestions {
    id
  }
}
    `;
export type SeedAiOnboardingSuggestionsMutationFn = Apollo.MutationFunction<SeedAiOnboardingSuggestionsMutation, SeedAiOnboardingSuggestionsMutationVariables>;

/**
 * __useSeedAiOnboardingSuggestionsMutation__
 *
 * To run a mutation, you first call `useSeedAiOnboardingSuggestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeedAiOnboardingSuggestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seedAiOnboardingSuggestionsMutation, { data, loading, error }] = useSeedAiOnboardingSuggestionsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSeedAiOnboardingSuggestionsMutation(baseOptions?: Apollo.MutationHookOptions<SeedAiOnboardingSuggestionsMutation, SeedAiOnboardingSuggestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SeedAiOnboardingSuggestionsMutation, SeedAiOnboardingSuggestionsMutationVariables>(SeedAiOnboardingSuggestionsDocument, options);
      }
export type SeedAiOnboardingSuggestionsMutationHookResult = ReturnType<typeof useSeedAiOnboardingSuggestionsMutation>;
export type SeedAiOnboardingSuggestionsMutationResult = Apollo.MutationResult<SeedAiOnboardingSuggestionsMutation>;
export type SeedAiOnboardingSuggestionsMutationOptions = Apollo.BaseMutationOptions<SeedAiOnboardingSuggestionsMutation, SeedAiOnboardingSuggestionsMutationVariables>;
export const Operations = {
  Mutation: {
    SeedAIOnboardingSuggestions: 'SeedAIOnboardingSuggestions' as const
  }
}