import React from 'react';

import { Alert } from '@/components/notifications/Alert/Alert';
import { getGuardedNodeType } from '@/graphql/types';
import { useReportError } from '@/hooks/useReportError';

import { TaskHeaderCard } from '../../TaskHeaderCard';
import { TaskBodyProps } from '../../types';
import { ConfirmTrustDetailsTaskBody } from './ConfirmTrustDetailsTaskBody';
import {
  GetTrustDetailsQuery,
  useGetTrustDetailsQuery,
} from './graphql/ConfirmTrustDetails.generated';
import { QueryReturnType } from './types';

function Error({ task, footer }: Pick<TaskBodyProps, 'task' | 'footer'>) {
  return (
    <TaskHeaderCard task={task} footer={footer}>
      <Alert severity="error" size="lg">
        Sorry, we weren&apos;t able to load the trust details. Please try
        refreshing the page.
      </Alert>
    </TaskHeaderCard>
  );
}

export function ConfirmTrustDetailsTask({
  task,
  footer,
  onCompleteTask,
}: TaskBodyProps) {
  // queries
  const { data, error: queryError } = useGetTrustDetailsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      entityID: task.entityId,
    },
  });

  // error reporting
  useReportError(
    'failed to load data for ConfirmTrustDetailsTask',
    queryError,
    { householdId: task.householdId, entityId: task.entityId }
  );

  const { entity: untypedEntity } = data ?? { entity: null };

  const entity = getGuardedNodeType<
    GetTrustDetailsQuery['entity'],
    QueryReturnType
  >(untypedEntity, 'Entity');

  if (queryError) {
    return <Error task={task} footer={footer} />;
  }

  if (!data || !entity) {
    return <TaskHeaderCard task={task} footer={footer} loading={true} />;
  }

  if (!entity.gratTrust) {
    return <Error task={task} footer={footer} />;
  }

  return (
    <ConfirmTrustDetailsTaskBody
      task={task}
      footer={footer}
      onCompleteTask={onCompleteTask}
    />
  );
}
