import { createContext, useContext } from 'react';

import { diagnostics } from '@/utils/diagnostics';

export interface InteractionParadigmContextType {
  viewOnly: boolean;
}
export const InteractionParadigmContext =
  createContext<InteractionParadigmContextType>({
    viewOnly: false,
  });

export function useViewOnly(): boolean {
  const context = useContext(InteractionParadigmContext);
  if (!context) {
    diagnostics.debug({
      message:
        'Attempted to call useViewOnly outside of an InteractionParadigmContext.  Make sure the component is wrapped in InteractionParadigmContext.Provider.  Returning false as a failsafe.',
    });
    return false;
  }
  return context.viewOnly;
}
