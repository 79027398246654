import {
  Box,
  Card,
  CardProps,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

export type HeaderCardProps = React.PropsWithChildren<{
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  rightHeaderContent?: React.ReactNode;
  headerSx?: SxProps<Theme>;
  footer?: React.ReactNode;
  id?: string;
}> &
  CardProps;

export function HeaderCard({
  heading,
  subheading,
  children,
  footer,
  rightHeaderContent,
  sx,
  headerSx,
  id,
  ...cardProps
}: HeaderCardProps) {
  const mergedSx = Object.assign({ p: 3, pb: 4 }, sx || {});
  return (
    <Card sx={mergedSx} {...cardProps}>
      <Stack>
        <Stack
          id={id}
          sx={{
            mb: 3,
            ...(sx || {}),
          }}
          component="header"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="column"
            sx={headerSx}
            display="flex"
            justifyContent="center"
          >
            <Typography mb={subheading ? 1 : 0} variant="h2">
              {heading}
            </Typography>
            {subheading && (
              <Typography variant="body1">{subheading}</Typography>
            )}
          </Stack>
          {rightHeaderContent && rightHeaderContent}
        </Stack>
        {children}
        {footer && <Box mt={3}>{footer}</Box>}
      </Stack>
    </Card>
  );
}
