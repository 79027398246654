import { useCallback, useMemo } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Loader } from '@/components/progress/Loader/Loader';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';
import {
  AugmentedCreateLifetimeExclusionEventInput,
  AugmentedUpdateLifetimeExclusionEventInput,
} from '@/types/schema';

import {
  useCreateLifetimeExclusionEventMutation,
  useDeleteLifetimeExclusionEventMutation,
  useUpdateLifetimeExclusionEventMutation,
} from '../graphql/LifetimeExclusionEvents.generated';
import { defaultValues } from './RecordExemptionUsedModal.constants';
import {
  RecordExemptionUsedModalForm,
  useFormOptions,
} from './RecordExemptionUsedModal.form';
import { RecordExemptionUsedForm } from './RecordExemptionUsedModal.types';
import {
  getCreateLifetimeExclusionEventInput,
  getUpdateLifetimeExclusionEventInput,
  useSyncDataToForm,
} from './RecordExemptionUsedModal.utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  householdId: string;
  lifetimeExclusionEventId?: string;
  isDuplicate?: boolean;
  isEdit?: boolean;
}

export function RecordExemptionUsed({
  isOpen,
  onClose,
  householdId,
  lifetimeExclusionEventId,
  isDuplicate,
  isEdit,
}: Props) {
  const { showFeedback, createErrorFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { formRef, handleSave } = useFormSaveHandler();

  const { primaryClientOptions } = useFormOptions({ householdId });

  const { handleSubmit, reset, shouldBlockNavigation, formState } =
    useFormContext<RecordExemptionUsedForm>();

  const { loading: loadingSyncDataToForm, originalDocumentIds } =
    useSyncDataToForm({
      lifetimeExclusionEventId,
      isDuplicate,
      isOpen,
    });

  const [createLifetimeExclusionEvent] =
    useCreateLifetimeExclusionEventMutation({
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onError: (error) => {
        createErrorFeedback('Error recording exemption, please try again')();
        reportError('could not create lifetime exclusion event', error, {
          householdId,
        });
      },
      onCompleted: () => {
        showFeedback('Additional exemption recorded successfully', {
          variant: 'success',
        });
      },
    });

  const [updateLifetimeExclusionEvent] =
    useUpdateLifetimeExclusionEventMutation({
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onError: (error) => {
        showFeedback('Error recording exemption, please try again');
        reportError('could not update lifetime exclusion event', error, {
          householdId,
        });
      },
      onCompleted: () => {
        showFeedback('Additional exemption recorded successfully', {
          variant: 'success',
        });
      },
    });

  const [deleteLifetimeExclusionEvent, { loading: isDeleting }] =
    useDeleteLifetimeExclusionEventMutation({
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onError: (error) => {
        showFeedback('Error deleting exemption, please try again');
        reportError('could not delete lifetime exclusion event', error, {
          householdId,
        });
      },
      onCompleted: () => {
        showFeedback('Exemption deleted successfully', {
          variant: 'success',
        });
      },
    });

  const onValidSubmission: SubmitHandler<RecordExemptionUsedForm> = (
    values
  ) => {
    if (isEdit) {
      const updateLifetimeExclusionEventInput: AugmentedUpdateLifetimeExclusionEventInput =
        getUpdateLifetimeExclusionEventInput({
          form: values,
          lifetimeExclusionEventId,
          originalDocumentIds,
        });

      // submit update form
      return updateLifetimeExclusionEvent({
        variables: {
          input: updateLifetimeExclusionEventInput,
        },
      });
    } else {
      const createLifetimeExclusionEventInput: AugmentedCreateLifetimeExclusionEventInput =
        getCreateLifetimeExclusionEventInput(values);

      // submit create form
      return createLifetimeExclusionEvent({
        variables: {
          input: createLifetimeExclusionEventInput,
        },
      });
    }
  };

  const submitHandler = handleSubmit(onValidSubmission);

  const closeModal = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  useSubmitSuccessHandler(() => {
    closeModal();
  });

  const onDelete = useCallback(async () => {
    await deleteLifetimeExclusionEvent({
      variables: {
        deleteLifetimeExclusionEventId: lifetimeExclusionEventId ?? '',
      },
    });

    closeModal();
  }, [closeModal, deleteLifetimeExclusionEvent, lifetimeExclusionEventId]);

  let submitButtonText = 'Record exemption';

  if (isEdit) {
    submitButtonText = 'Save changes';
  }

  if (isDuplicate) {
    submitButtonText = 'Duplicate addition';
  }

  const actions = (
    <FormModalActions.Provider
      formState={formState}
      isDeleting={isDeleting}
      disabled={loadingSyncDataToForm}
    >
      {isEdit && <FormModalActions.DeleteButton onConfirmDelete={onDelete} />}
      <FormModalActions.CancelButton onClick={closeModal} />
      <FormModalActions.SaveButton onClick={handleSave}>
        {submitButtonText}
      </FormModalActions.SaveButton>
    </FormModalActions.Provider>
  );

  const heading = useMemo(() => {
    if (isEdit) {
      return 'Edit additional lifetime exemption';
    }
    if (isDuplicate) {
      return 'Duplicate additional lifetime';
    }
    return 'Record additional exemption used';
  }, [isDuplicate, isEdit]);

  return (
    <FormModal
      isOpen={isOpen}
      heading={heading}
      onClose={closeModal}
      actions={actions}
      shouldBlockClose={shouldBlockNavigation}
    >
      {loadingSyncDataToForm ? (
        <Loader
          boxProps={{
            sx: {
              textAlign: 'center',
              my: 3,
            },
          }}
        />
      ) : (
        <form ref={formRef} onSubmit={submitHandler} noValidate>
          <RecordExemptionUsedModalForm
            primaryClientOptions={primaryClientOptions}
            householdId={householdId}
          />
        </form>
      )}
    </FormModal>
  );
}

export function RecordExemptionUsedModal({ ...props }: Props) {
  const formMethods = useForm<RecordExemptionUsedForm>({ defaultValues });

  return (
    <FormProvider {...formMethods}>
      <RecordExemptionUsed {...props} />
    </FormProvider>
  );
}
