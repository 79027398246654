import { Box, Stack, styled, TextField, Typography } from '@mui/material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import * as React from 'react';

import { UserPlus01Icon } from '@/components/icons/UserPlus01Icon';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { COLORS } from '@/styles/tokens/colors';

import { FormControl } from '../FormControl';
import {
  BaseTypeaheadSelectInputProps,
  FormControlTypeaheadSelectInputProps,
  HelpTextVariant,
  TypeaheadSelectInputOption,
} from '../inputTypes';
import { SelectItemGroupLabel } from '../SelectInput/SelectItemGroupLabel';

export interface TypeaheadSelectProps<V>
  extends FormControlTypeaheadSelectInputProps<V> {
  label: string;
  id?: string;
  // the presence of contextualHelp indicates that there's help text related to this input, which
  // will cause the "info" icon to show up next to the input label
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  hideLabel?: boolean;
  autoSelect?: boolean;
}

const GroupItems = styled('ul')({
  padding: 0,
});

const BaseTypeaheadSelectInput = ({
  options,
  testId,
  required,
  inputRef,
  startAdornment,
  dropdownProps = {},
  emptyOptionDisplay = EMPTY_CONTENT_HYPHEN,
  addNewOption,
  ...inputProps // eslint-disable-next-line @typescript-eslint/no-explicit-any -- legacy types
}: BaseTypeaheadSelectInputProps<any>) => {
  const { disabled } = inputProps;
  // explicitly splitting off error and autocomplete so we don't pass them to
  // the top-level Autocomplete element, which doesn't accept them
  const { autoComplete, error, ...otherInputProps } = inputProps;
  const disabledStyle = disabled ? { background: COLORS.GRAY[50] } : {};
  const mergedSx = Object.assign({ background: 'white' }, disabledStyle);

  const finalOptions = React.useMemo(() => {
    const baseOptions =
      required && options.length > 0
        ? options
        : [
            { display: emptyOptionDisplay, value: '', disabled: required },
            ...options,
          ];

    if (!addNewOption) return baseOptions;

    return [
      {
        display: 'add_new_option',
        value: 'add_new_option',
        renderOption: (props: React.HTMLAttributes<HTMLLIElement>) => (
          <MenuItem {...props} onClick={() => addNewOption.onClick()}>
            <ListItemIcon>
              {addNewOption.icon || <UserPlus01Icon size={16} />}
            </ListItemIcon>
            <ListItemText>{addNewOption.text || 'Add new'}</ListItemText>
          </MenuItem>
        ),
      },
      ...baseOptions,
    ];
  }, [emptyOptionDisplay, options, required, addNewOption]);

  const optionValueLabelMap = React.useMemo(() => {
    return finalOptions.reduce<Record<string, string>>((acc, option) => {
      acc[JSON.stringify(option.value)] = option.display;
      return acc;
    }, {});
  }, [finalOptions]);

  return (
    // this select is a non-optimal experience for mobile; consider using NativeSelect here for a mobile device
    <Autocomplete<TypeaheadSelectInputOption<unknown>, false, boolean>
      autoHighlight
      openOnFocus
      disableClearable
      {...otherInputProps}
      onChange={(event, option, reason, details) =>
        otherInputProps.onChange?.(event, option?.value, reason, details)
      }
      options={finalOptions}
      isOptionEqualToValue={(option, value) => option?.value === value}
      // this method name is very misleading and poorly built. it actually passes the option when the
      // selection popover is opened, and passes just the value when computing the label when the
      // selection popover is closed. in github issues, they seem to feel strongly about keeping the `value`
      // as the exact value of one of the options.
      // see: https://github.com/mui/material-ui/issues/31192
      getOptionLabel={(valueOrOption) => {
        if (typeof valueOrOption === 'object' && 'display' in valueOrOption) {
          return valueOrOption.display;
        }

        return optionValueLabelMap[JSON.stringify(valueOrOption)] || '';
      }}
      componentsProps={{
        popper: dropdownProps,
      }}
      getOptionDisabled={(option) => !!option.disabled}
      renderGroup={(params) => (
        <li key={params.key}>
          {params.group && (
            <SelectItemGroupLabel sx={{ top: '-20px' }} label={params.group} />
          )}
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderOption={(props, option) => {
        if (option.renderOption) {
          return option.renderOption(props);
        }

        return (
          <li {...props} key={`option-${option.value as string}`}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <Box flex={1}>{option.display}</Box>
              <Stack spacing={2}>
                {option.endLabel && (
                  <Typography variant="caption">{option.endLabel}</Typography>
                )}
                {option.badgeText && (
                  <Badge
                    variant={BadgeVariants.Gray}
                    display={option.badgeText}
                  />
                )}
              </Stack>
            </Stack>
          </li>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="outlined"
            error={error}
            inputRef={inputRef}
            placeholder={inputProps.placeholder}
            InputProps={{
              ...params.InputProps,
              startAdornment,
              sx: mergedSx,
              inputProps: {
                ...params.inputProps,
                required,
                autoComplete,
                'data-testid':
                  testId ?? `select-input-${inputProps.name ?? ''}`,
              },
            }}
          />
        );
      }}
    />
  );
};

export function TypeaheadSelectInput<V>({
  id,
  label,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant,
  hideLabel,
  ...inputProps
}: TypeaheadSelectProps<V>) {
  return (
    <FormControl<BaseTypeaheadSelectInputProps<V>>
      id={id}
      hideLabel={hideLabel}
      contextualHelp={contextualHelp}
      component={BaseTypeaheadSelectInput}
      label={label}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
      required={inputProps.required}
      errorMessage={errorMessage}
      inputProps={inputProps}
    />
  );
}
