import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { SetRequired } from 'type-fest';

import { Button } from '@/components/form/baseInputs/Button';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { FullScreenModalProps } from '@/components/modals/FullScreenModal/FullScreenModal';
import { Modal } from '@/components/modals/Modal/Modal';
import { ClientMultiDocumentUploader } from '@/modules/aiOnboarding/components/ClientMultiDocumentUploader';
import { useTriggerAIOnboardingWorkflow } from '@/modules/aiOnboarding/hooks/useTriggerAIOnboardingWorkflow';
import { useMultiDocumentUploaderContext } from '@/modules/documents/MultiDocumentUploader/context/multiDocumentUploader.context';
import { MultiDocumentUploaderProvider } from '@/modules/documents/MultiDocumentUploader/context/MultiDocumentUploader.provider';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

export type GenerateFromDocumentsModalProps = SetRequired<
  Pick<FullScreenModalProps, 'isOpen' | 'onClose'>,
  'isOpen' | 'onClose'
>;

function GenerateFromDocumentsModalInner({
  isOpen,
  onClose,
}: GenerateFromDocumentsModalProps) {
  const { householdId } = useHouseholdDetailsContext();

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const { loading, triggerWorkflow } = useTriggerAIOnboardingWorkflow();

  const { uploadedFiles, setUploaderErrorMessage } =
    useMultiDocumentUploaderContext();

  const handleSubmit = useCallback(async () => {
    if (!householdId) {
      // This shouldn't happen, but is for type safety
      throw new Error('Household ID is required to generate from documents');
    }

    if (isEmpty(uploadedFiles)) {
      setUploaderErrorMessage('Please upload a document to continue');
      return;
    }

    await triggerWorkflow(householdId, handleClose);
  }, [
    handleClose,
    householdId,
    setUploaderErrorMessage,
    triggerWorkflow,
    uploadedFiles,
  ]);

  return (
    <Modal
      heading="Generate from documents"
      isOpen={isOpen}
      onClose={handleClose}
      actions={
        <>
          <FormModalActions.CancelButton onClick={handleClose} />
          <Button
            data-testid="GenerateFromDocumentsModal-processDocuments"
            variant="primary"
            size="sm"
            onClick={handleSubmit}
            loading={loading}
          >
            Process documents
          </Button>
        </>
      }
    >
      {householdId && <ClientMultiDocumentUploader householdId={householdId} />}
    </Modal>
  );
}

export function GenerateFromDocumentsModal(
  props: GenerateFromDocumentsModalProps
) {
  return (
    <MultiDocumentUploaderProvider>
      <GenerateFromDocumentsModalInner {...props} />
    </MultiDocumentUploaderProvider>
  );
}
