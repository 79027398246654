import { COLORS } from '@/styles/tokens/colors';
import { getPublicImageUrl } from '@/utils/staticFiles';

import { getHexColorWithOpacity } from './colorUtils';

const background = getPublicImageUrl('/placeholder-background.svg');

export const BACKGROUND_IMAGE_PROPERTY_VALUE = `url(${background})`;

// default to the error background color
export function generateBrandedBackgroundWithColor(color = COLORS.ORANGE[400]) {
  const rgbDefinition = getHexColorWithOpacity(color, 0.04);

  // create a linear-gradient transform that's actually static. this is because
  // using the `backgroundImage` property and a linear-gradient transform, we can *layer two backgrounds* without having to make
  // an additional DOM element.
  // thank you, Stack Overflow: https://stackoverflow.com/a/47964080/1906955
  const linearGradientDefinition = `linear-gradient(to right, ${rgbDefinition}, ${rgbDefinition})`;
  // finally, layer the two backgrounds in the order we want them.
  return `${linearGradientDefinition}, ${BACKGROUND_IMAGE_PROPERTY_VALUE}`;
}
