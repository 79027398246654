import Decimal from 'decimal.js';
import { compact, includes } from 'lodash';

import { BASIC_ASSETS_SUBFORM } from '@/modules/entities/BasicAssetsSubform/BasicAssetsSubform.types';
import { makeCreateEntityInput } from '@/modules/entities/EntitySubforms/createUtils';
import {
  PartialSubformsCombinedType,
  SubformsCombinedType,
} from '@/modules/entities/EntitySubforms/EntitySubforms.types';
import {
  AugmentedCreateEntityInput,
  EntityAttributionSource,
  EntityStage,
  JointPersonalAccountType,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { determineDefaultAssetClassId } from '../assets/utils';
import {
  ACCOUNT_ENTITY_TYPES,
  BUSINESS_ENTITY_TYPES,
  ENTITY_TYPES,
} from '../entities/entities.constants';
import {
  getTaxStatusAPIValuesFromEntityType,
  getTaxStatusValuesFromEntityType,
} from '../entities/TaxStatusSubform/TaxStatusSubform.utils';
import { EntityType } from '../entities/types/EntityType';
import { AssetClassFragment } from '../tenant/graphql/TenantInformation.generated';
import {
  BULK_IMPORT_FLOW_ADDEPAR,
  BulkImportFlowType,
} from './BulkImportFlowContext';
import {
  EntityImportTableClient,
  EntityImportTableEntity,
  EntityImportTableForm,
  NAMESPACE,
} from './EntityImportTable.types';

interface MapFormDataToMutationParams {
  defaultAssetClassId: string;
  assetClassesById: Record<string, AssetClassFragment>;
}

export const mapFormDataToMutation = (
  formData: EntityImportTableForm,
  flowType: BulkImportFlowType,
  params: MapFormDataToMutationParams
): AugmentedCreateEntityInput[] => {
  return Object.values(
    formData[NAMESPACE].householdMap
  ).flatMap<AugmentedCreateEntityInput>((client) => {
    const newEntities = compact(
      Object.values(client.entityMap)
        .filter(({ shouldImport }) => !!shouldImport)
        .map((entity) => {
          if (!entity.entityKind) {
            return null;
          }

          // GRATs have a notably different shape (even though they share the same
          // AugemntedCreateEntityInput type), so handle that case as a one-off
          if (entity.entityKind === ENTITY_TYPES.GRAT) {
            const taxStatusDefaultValues = getTaxStatusAPIValuesFromEntityType(
              entity.entityKind
            );
            const create: AugmentedCreateEntityInput['create'] = {
              stage: EntityStage.Draft,
              householdID: client.luminaryClientID,
              attributionSource: EntityAttributionSource.AddeparBulkImport,
            };
            switch (flowType) {
              case BULK_IMPORT_FLOW_ADDEPAR:
                create.integrationEntityIDs = [entity.integrationEntityId];
                break;

              default:
                throw new UnreachableError({
                  case: flowType,
                  message: `Invalid flow type on importing entity: ${flowType}`,
                });
            }
            const gratOutput: AugmentedCreateEntityInput = {
              create,
              withGratTrust: {
                create: {
                  displayName: entity.displayName,
                  inEstateStatus: taxStatusDefaultValues.inEstateStatus,
                  taxStatus: taxStatusDefaultValues.taxStatus,
                  gstStatus: taxStatusDefaultValues.gstStatus,
                },
                withGrantor: {
                  create: {
                    individualID: entity.commaSeparatedOwnerIDs.split(',')[0],
                  },
                },
                withDesignerAccount: {
                  create: {
                    displayName: 'Default account',
                  },
                },
              },
            };
            return gratOutput;
          }
          const mappedEntity = mapEntityToSubformsCombinedType(
            entity,
            client,
            flowType,
            params
          );
          if (!mappedEntity[BASIC_ASSETS_SUBFORM]) {
            return null;
          }
          const output = makeCreateEntityInput(
            mappedEntity,
            entity.entityKind,
            client.luminaryClientID
          );
          output.create.attributionSource =
            EntityAttributionSource.AddeparBulkImport;
          return output;
        })
    );
    return newEntities;
  });
};

const mapEntityToSubformsCombinedType = (
  entity: EntityImportTableEntity,
  client: EntityImportTableClient,
  _flowType: BulkImportFlowType,
  { defaultAssetClassId, assetClassesById }: MapFormDataToMutationParams
): SubformsCombinedType => {
  const entityKind = entity.entityKind;
  const outputObject: PartialSubformsCombinedType = {};

  outputObject.basicInformationSubform = {
    legalName: '',
    displayName: entity.displayName,
    doingBusinessAsName: '',
    requiresCtaReporting: false,
    finCenID: '',
    effectiveDate: null,
    jurisdiction: '',
    description: '',
    documentIds: [],
    termLength: '',
    termLengthNumber: '',
    taxableGift: null,
    rate7520: null,
  };

  if (entityKind === ENTITY_TYPES.JOINT_ACCOUNT) {
    outputObject.basicInformationSubform.jointAccountType =
      JointPersonalAccountType.CommunityProperty;
  }

  outputObject.trustDetailsSubform = {
    trustees: [],
    successorTrustees: [],
    trustAdvisors: [],
  };

  if (ENTITY_TYPES.SLAT === entityKind) {
    outputObject.basicInformationSubform = {
      ...outputObject.basicInformationSubform,
      grantor: {
        clientProfileId: entity.commaSeparatedOwnerIDs,
      },
    };
  } else {
    let grantors = [];
    if (entity.commaSeparatedOwnerIDs.includes(',')) {
      grantors = client.grantors.map(({ id }) => ({ clientProfileId: id }));
    } else {
      grantors = [{ clientProfileId: entity.commaSeparatedOwnerIDs }];
    }
    outputObject.basicInformationSubform = {
      ...outputObject.basicInformationSubform,
      grantors,
    };
  }

  outputObject.basicAssetsSubform = {
    basicAssets: {
      accountId: '',
      valuationId: '',
      assetCategoryId: determineDefaultAssetClassId(
        entityKind,
        defaultAssetClassId,
        assetClassesById
      ),
      _previousIntegrationEntityIds: [],
      integrationEntityIds: [entity.integrationEntityId],
      linkToAllAddeparEntities: false,
      dirtyState: 'clean',
      currentMarketValue: null,
      // we don't want to create a valuation because we want to show the addepar "pending" state, and
      // if we have an active valuation we'll show that right away instead of the addepar pending state
      dateOfValuation: null,
      description: '',
      // this field is required on the type, so default it to blank if not addepar
    },
  };

  if (includes(BUSINESS_ENTITY_TYPES, entityKind)) {
    outputObject.businessEntityDetailsSubform = {
      keyPeople: [],
      ownershipAsOfDate: null,
      keyPeopleNotes: '',
      owners: entity.commaSeparatedOwnerIDs.includes(',')
        ? client.grantors.map(({ id }) => ({
            ownerId: id,
            percentOwned: new Decimal(100 / client.grantors.length),
          }))
        : [
            {
              ownerId: entity.commaSeparatedOwnerIDs,
              percentOwned: new Decimal(100),
            },
          ],
      _ownershipSum: null,
    };
  }

  if (
    includes(
      [
        ...ACCOUNT_ENTITY_TYPES,
        ENTITY_TYPES.DAF,
        ENTITY_TYPES.PRIVATE_FOUNDATION,
      ],
      entityKind
    )
  ) {
    if (entity.commaSeparatedOwnerIDs.includes(',')) {
      outputObject.basicInformationSubform = {
        ...outputObject.basicInformationSubform,
        controllingParties: [],
        owners: client.grantors.map(({ id }) => ({
          ownerId: id,
          percentOwned: new Decimal(100 / client.grantors.length),
        })),
      } as SubformsCombinedType['basicInformationSubform'];
    } else {
      outputObject.basicInformationSubform = {
        ...outputObject.basicInformationSubform,
        controllingParties: [],
        owner: {
          ownerId: entity.commaSeparatedOwnerIDs,
        },
      } as SubformsCombinedType['basicInformationSubform'];
    }
  }

  outputObject.taxStatusSubform = getTaxStatusValuesFromEntityType(
    entity.entityKind as EntityType
  );

  return outputObject as SubformsCombinedType;
};
