import { useMemo } from 'react';

import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { EntityStage } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';
import { sortAlpha } from '@/utils/sortUtils';

import { useLiabilitiesIssuersAndRecipientsQuery } from '../graphql/LiabilityModal.generated';

interface useIssuerAndRecipientOptionsOpts {
  currentEntityId?: string;
}

export function useIssuerAndRecipientOptions({
  currentEntityId,
}: useIssuerAndRecipientOptionsOpts) {
  const { householdId } = useHouseholdDetailsContext();

  const { data: clientData } = useLiabilitiesIssuersAndRecipientsQuery({
    variables: {
      where: { id: householdId },
    },
  });

  const household = getNodes(clientData?.households)[0];

  return useMemo(() => {
    if (!household) {
      return {
        options: [],
      };
    }

    const entityRecipients = household.possibleBeneficiariesV2.entities;
    const entityRecipientOptions = entityRecipients
      .flatMap((e) => {
        if (e.stage !== EntityStage.Active) return [];

        return {
          display:
            e.id === currentEntityId
              ? `This entity (${e.subtype.displayName})`
              : e.subtype.displayName,
          value: e.id,
          groupName: 'Entities',
        };
      })
      .sort((a, b) => {
        // sort the current entity to the top first, and then make all the others alphabetical
        if (a.value === currentEntityId) return -1;
        if (b.value === currentEntityId) return 1;
        return sortAlpha(a.display, b.display);
      });

    return {
      options: entityRecipientOptions,
    };
  }, [currentEntityId, household]);
}
