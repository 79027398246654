import { PopperContent } from '@/components/poppers/PopperContent';

export function EntityMemberLevel() {
  return (
    <PopperContent
      body={
        <span>
          Ordering represents the order of succession in the event an individual
          is unable to act as trustee, trust advisor, or beneficiary. Enter
          multiple individuals at the same level to represent concurrent
          beneficiaries or co-trustees.
        </span>
      }
    />
  );
}
