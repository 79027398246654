import Decimal from 'decimal.js';

import {
  EstateWaterfall_EdgeFragment,
  EstateWaterfall_NodeFragment,
  EstateWaterfallFragment,
} from '@/modules/estateWaterfall/graphql/EstateWaterfall.generated';
import {
  GetWaterfallSummary_EstateWaterfallFragment,
  GetWaterfallSummary_EstateWaterfallVizEdgeFragment,
  GetWaterfallSummary_EstateWaterfallVizNodeFragment,
} from '@/modules/estateWaterfall/graphql/GetWaterfallSummary.generated';

export type TaxOverviewTableWaterfallType =
  | GetWaterfallSummary_EstateWaterfallFragment
  | EstateWaterfallFragment;

export type TaxOverviewTableEdgeFragment =
  | GetWaterfallSummary_EstateWaterfallVizEdgeFragment
  | EstateWaterfall_EdgeFragment;

export type TaxOverviewTableNodeFragment =
  | GetWaterfallSummary_EstateWaterfallVizNodeFragment
  | EstateWaterfall_NodeFragment;

export interface TaxOverviewTableRowData {
  id: string;
  name: {
    lineOne: string;
    lineTwo: string | undefined;
  };
  path: string[];
  projectedValue: Decimal | undefined | null;
  noDeathValue?: Decimal | undefined | null;
  hasChildren: boolean;
  variant?: TaxOverviewTableRowVariant;
  displayAsNegative?: boolean;
  boldNameOverride?: boolean;
  subsection: TaxOverviewTableRowSubsection;
}

export enum TaxOverviewTableRowVariant {
  Subtotal = 'subtotal',
  Subtitle = 'subtitle',
  CategoryLabel = 'categoryLabel',
}

export enum TaxOverviewTableRowSubsection {
  NetAssets = 'netAssets',
  TransfersDuringLife = 'transfersDuringLife',
  TaxesAndExpenses = 'taxesAndExpenses',
  GiftTaxes = 'giftTaxes',
  AfterTaxDistribution = 'afterTaxDistribution',
  RemainingExemption = 'remainingExemption',
}

export enum TaxOverviewTableRowParentCategory {
  InEstate = 'inEstate',
  OutOfEstateFamily = 'outOfEstateFamily',
  OutOfEstateCharity = 'outOfEstateCharity',
  Tax = 'tax',
  Transfers = 'transfers',
  GiftTaxes = 'giftTaxes',
  NetAssets = 'netAssets',
  NetAssetsInEstate = 'netAssetsInEstate',
  NetAssetsOutOfEstateFboFamily = 'netAssetsOutOfEstateFboFamily',
  NetAssetsOutOfEstateFboCharity = 'netAssetsOutOfEstateFboCharity',
  RemainingExemption = 'remainingExemption',
  TaxesAndExpenses = 'taxesAndExpenses',
  AfterTaxDistribution = 'afterTaxDistribution',
}
