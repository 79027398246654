import { isEmpty } from 'lodash';
import { PropsWithChildren, useMemo } from 'react';

import { TypeaheadSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { EntityKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import {
  SaleLoanForm_HouseholdFragment,
  useSaleLoanFormQuery,
} from '../graphql/SaleLoanForm.generated';
import { SaleLoanFormContext } from './SaleLoanForm.context';

function getRecipientOptions(
  household: SaleLoanForm_HouseholdFragment | null
): TypeaheadSelectInputOption<string>[] {
  // just to be defensive
  if (!household) return [];
  const { possibleBeneficiariesV2 } = household;
  const options: TypeaheadSelectInputOption<string>[] = [];

  possibleBeneficiariesV2.clients.forEach((client) => {
    options.push({
      value: client.id,
      display: client.displayName,
      groupName: 'Individuals',
    });
  });

  possibleBeneficiariesV2.entities
    .filter((entity) =>
      [EntityKind.IrrevocableTrust, EntityKind.SlatTrust].includes(entity.kind)
    )
    .forEach((entity) => {
      options.push({
        value: entity.id,
        display: entity.subtype.displayName,
        groupName: 'Entities',
      });
    });

  return options;
}

function getSellerLenderOptions(
  household: SaleLoanForm_HouseholdFragment | null
): TypeaheadSelectInputOption<string>[] {
  if (!household) return [];

  const sellerIndividuals = household.sellerIndividuals
    .filter((client) => !isEmpty(client.ownedOwnershipStakes))
    .map((client) => ({
      value: client.id,
      display: client.displayName,
      groupName: 'Individuals',
    }));

  const sellerEntities = getNodes(household.sellerEntities).map((entity) => ({
    value: entity.id,
    display: entity.subtype.displayName,
    groupName: 'Entities',
  }));

  return [...sellerIndividuals, ...sellerEntities];
}

interface SaleLoanFormProviderProps extends PropsWithChildren {
  waterfallId: string;
  householdId: string;
  loading: boolean;
  // saleLoanId will be null if this is a new sale loan
  saleLoanId: string | null;
  // saleLoanFundingId will be null if this is a new sale loan
  saleLoanFundingId: string | null;
}

export function SaleLoanFormProvider({
  children,
  loading: loadingExternal,
  saleLoanId,
  saleLoanFundingId,
  householdId,
  waterfallId,
}: SaleLoanFormProviderProps) {
  const { data, loading, error } = useSaleLoanFormQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      householdId,
    },
  });

  const { possibleRecipients, possibleSellerLenders } = useMemo(() => {
    const household = getNodes(data?.households)[0];
    const possibleRecipients = getRecipientOptions(household ?? null);
    const possibleSellerLenders = getSellerLenderOptions(household ?? null);

    return {
      possibleRecipients,
      possibleSellerLenders,
    };
  }, [data]);

  const contextValue = useMemo(
    () => ({
      possibleRecipients,
      possibleSellerLenders,

      loading: loading || loadingExternal,
      saleLoanId,
      waterfallId,
      saleLoanFundingId,
      error,
    }),
    [
      possibleRecipients,
      possibleSellerLenders,
      loading,
      loadingExternal,
      saleLoanId,
      waterfallId,
      saleLoanFundingId,
      error,
    ]
  );

  return (
    <SaleLoanFormContext.Provider value={contextValue}>
      {children}
    </SaleLoanFormContext.Provider>
  );
}
