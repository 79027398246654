import { Box } from '@mui/material';

export interface FormContainerProps {
  visible: boolean;
}

/**
 * @description The FormContainer component is used to conditionally render the form. We
 * explicitly don't want to *unmount* the hidden forms when they're not visible because we
 * rely on them being in the DOM when we trigger validation because we need to be able to
 * focus on invalid fields.
 */
export function FormContainer({
  children,
  visible,
}: React.PropsWithChildren<FormContainerProps>) {
  return (
    <Box p={3} display={visible ? 'auto' : 'none'}>
      {children}
    </Box>
  );
}
