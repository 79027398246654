import Decimal from 'decimal.js';

import {
  SuggestableSuggestion,
  SuggestedValue,
} from '@/modules/aiSuggestions/AISuggestedValue/AISuggestedValue.types';
import {
  businessEntityTaxStatusMapper,
  gstStatusMapper,
} from '@/modules/aiSuggestions/AISuggestedValue/subforms/taxStatus';
import { AiSuggestionKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

/**
 * Returns the value and the display text for the given "suggestable" suggestion.
 */
export function getSuggestedValue(
  suggestion: SuggestableSuggestion
): SuggestedValue {
  switch (suggestion.kind) {
    case AiSuggestionKind.EffectiveDate:
      return {
        value: suggestion?.effectiveDate,
        display: suggestion?.effectiveDate
          ? formatDateToMonDDYYYY(suggestion.effectiveDate)
          : '',
      };
    case AiSuggestionKind.GstStatus:
      return gstStatusMapper(suggestion?.gstStatus);
    case AiSuggestionKind.JurisdictionStateCode:
      return {
        value: suggestion?.jurisdictionStateCode,
        display: suggestion?.jurisdictionStateCode || '',
      };
    case AiSuggestionKind.LegalName:
      return {
        value: suggestion?.legalName,
        display: suggestion?.legalName || '',
      };
    case AiSuggestionKind.CrummeyWithdrawalPeriodDays:
      return {
        value: suggestion?.crummeyWithdrawalPeriodDays
          ? new Decimal(suggestion.crummeyWithdrawalPeriodDays)
          : null,
        display: suggestion?.crummeyWithdrawalPeriodDays
          ? `${suggestion.crummeyWithdrawalPeriodDays} days`
          : '',
      };
    case AiSuggestionKind.DoingBusinessAsName:
      return {
        value: suggestion?.doingBusinessAsName,
        display: suggestion?.doingBusinessAsName || '',
      };
    case AiSuggestionKind.FincenId:
      return {
        value: suggestion?.fincenID,
        display: suggestion?.fincenID || '',
      };
    case AiSuggestionKind.TaxId:
      return {
        value: suggestion?.taxID,
        display: suggestion?.taxID || '',
      };
    case AiSuggestionKind.BusinessEntityTaxStatus:
      return businessEntityTaxStatusMapper(suggestion?.businessEntityTaxStatus);
    case AiSuggestionKind.BeneficialOwnersAsOfDate:
      return {
        value: suggestion?.beneficialOwnersAsOfDate,
        display: suggestion?.beneficialOwnersAsOfDate
          ? formatDateToMonDDYYYY(suggestion.beneficialOwnersAsOfDate)
          : '',
      };
    default:
      throw new UnreachableError({
        case: suggestion.kind,
        message: `Unsupported suggestionKind ${suggestion.kind}`,
      });
  }
}
