// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SimulateDispositiveProvisions_SimulateDispositiveProvisionFragment = { __typename?: 'SimulateDispositiveProvision', transferAmount: CurrencyUSD, transferAmountWasClipped: boolean };

export type SimulateDispositiveProvisionsQueryVariables = Types.Exact<{
  input: Types.SimulateDispositiveProvisionsInput;
}>;


export type SimulateDispositiveProvisionsQuery = { __typename?: 'Query', simulateDispositiveProvisions: { __typename?: 'SimulateDispositiveProvisions', valueRemainingAfterFirstDeath: CurrencyUSD, valueRemainingAfterSecondDeath: CurrencyUSD, firstDeath: Array<{ __typename?: 'SimulateDispositiveProvision', transferAmount: CurrencyUSD, transferAmountWasClipped: boolean }>, secondDeath: Array<{ __typename?: 'SimulateDispositiveProvision', transferAmount: CurrencyUSD, transferAmountWasClipped: boolean }> } };

export const SimulateDispositiveProvisions_SimulateDispositiveProvisionFragmentDoc = gql`
    fragment SimulateDispositiveProvisions_SimulateDispositiveProvision on SimulateDispositiveProvision {
  transferAmount
  transferAmountWasClipped
}
    `;
export const SimulateDispositiveProvisionsDocument = gql`
    query SimulateDispositiveProvisions($input: SimulateDispositiveProvisionsInput!) {
  simulateDispositiveProvisions(input: $input) {
    firstDeath {
      ...SimulateDispositiveProvisions_SimulateDispositiveProvision
    }
    secondDeath {
      ...SimulateDispositiveProvisions_SimulateDispositiveProvision
    }
    valueRemainingAfterFirstDeath
    valueRemainingAfterSecondDeath
  }
}
    ${SimulateDispositiveProvisions_SimulateDispositiveProvisionFragmentDoc}`;

/**
 * __useSimulateDispositiveProvisionsQuery__
 *
 * To run a query within a React component, call `useSimulateDispositiveProvisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulateDispositiveProvisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulateDispositiveProvisionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSimulateDispositiveProvisionsQuery(baseOptions: Apollo.QueryHookOptions<SimulateDispositiveProvisionsQuery, SimulateDispositiveProvisionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimulateDispositiveProvisionsQuery, SimulateDispositiveProvisionsQueryVariables>(SimulateDispositiveProvisionsDocument, options);
      }
export function useSimulateDispositiveProvisionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimulateDispositiveProvisionsQuery, SimulateDispositiveProvisionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimulateDispositiveProvisionsQuery, SimulateDispositiveProvisionsQueryVariables>(SimulateDispositiveProvisionsDocument, options);
        }
export function useSimulateDispositiveProvisionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SimulateDispositiveProvisionsQuery, SimulateDispositiveProvisionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SimulateDispositiveProvisionsQuery, SimulateDispositiveProvisionsQueryVariables>(SimulateDispositiveProvisionsDocument, options);
        }
export type SimulateDispositiveProvisionsQueryHookResult = ReturnType<typeof useSimulateDispositiveProvisionsQuery>;
export type SimulateDispositiveProvisionsLazyQueryHookResult = ReturnType<typeof useSimulateDispositiveProvisionsLazyQuery>;
export type SimulateDispositiveProvisionsSuspenseQueryHookResult = ReturnType<typeof useSimulateDispositiveProvisionsSuspenseQuery>;
export type SimulateDispositiveProvisionsQueryResult = Apollo.QueryResult<SimulateDispositiveProvisionsQuery, SimulateDispositiveProvisionsQueryVariables>;
export const Operations = {
  Query: {
    SimulateDispositiveProvisions: 'SimulateDispositiveProvisions' as const
  },
  Fragment: {
    SimulateDispositiveProvisions_SimulateDispositiveProvision: 'SimulateDispositiveProvisions_SimulateDispositiveProvision' as const
  }
}