import { Box, Stack, SxProps, Theme } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useMemo } from 'react';

import {
  SubtleLabelItem,
  SubtleLabelItemProps,
} from '@/components/display/SubtleLabelItem/SubtleLabelItem';

import { Card, CardProps } from '../Card/Card';

export interface CalloutListCardProps {
  loading: boolean;
  variant?: CardProps['variant'];
  items: SubtleLabelItemProps[];
  footer?: React.ReactNode;
  sx?: SxProps<Theme>;
}

const CALLOUT_CARD_PADDING_UNITS = 3;

export function CalloutListCard({
  loading,
  variant = 'filled',
  items,
  footer,
  sx = {},
}: CalloutListCardProps) {
  const renderedItems = useMemo(() => {
    if (loading) {
      // if we're still loading, don't render anything
      return [];
    }

    return items;
  }, [loading, items]);

  if (isEmpty(renderedItems)) {
    return null;
  }

  return (
    <Card
      variant={variant}
      sx={{ p: CALLOUT_CARD_PADDING_UNITS, width: 300, height: '100%', ...sx }}
    >
      <Stack spacing={3} justifyContent="space-between" height="100%">
        <Stack spacing={2}>
          {renderedItems.map((subtleLabelItem, i) => (
            <SubtleLabelItem
              // if there are actions on the label items, separate them out
              sx={{ justifyContent: 'space-between' }}
              {...subtleLabelItem}
              key={i}
            />
          ))}
        </Stack>
        {footer && <Box>{footer}</Box>}
      </Stack>
    </Card>
  );
}
