import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';

import {
  NAMESPACE,
  PreTaxReturnLimitModalForm,
  PreTaxReturnLimitModalFormPaths,
} from './PreTaxReturnLimitModal.types';
import { calculateAfterTaxReturnPercentage } from './PreTaxReturnLimitModal.utils';

export function PreTaxReturnLimitModalBody() {
  const { control } = useFormContext<PreTaxReturnLimitModalForm>();
  const [
    preTaxReturnPercentageLow,
    preTaxReturnPercentageMedium,
    preTaxReturnPercentageHigh,
    taxDragPercentageLow,
    taxDragPercentageMedium,
    taxDragPercentageHigh,
  ] = useWatch({
    control,
    name: [
      `${NAMESPACE}.preTaxReturnPercentageLow`,
      `${NAMESPACE}.preTaxReturnPercentageMedium`,
      `${NAMESPACE}.preTaxReturnPercentageHigh`,
      `${NAMESPACE}.taxDragPercentageLow`,
      `${NAMESPACE}.taxDragPercentageMedium`,
      `${NAMESPACE}.taxDragPercentageHigh`,
    ],
  });

  const afterTaxReturnLow = useMemo(
    () =>
      calculateAfterTaxReturnPercentage(
        preTaxReturnPercentageLow,
        taxDragPercentageLow
      ),
    [preTaxReturnPercentageLow, taxDragPercentageLow]
  );

  const afterTaxReturnMedium = useMemo(
    () =>
      calculateAfterTaxReturnPercentage(
        preTaxReturnPercentageMedium,
        taxDragPercentageMedium
      ),
    [preTaxReturnPercentageMedium, taxDragPercentageMedium]
  );

  const afterTaxReturnHigh = useMemo(
    () =>
      calculateAfterTaxReturnPercentage(
        preTaxReturnPercentageHigh,
        taxDragPercentageHigh
      ),
    [preTaxReturnPercentageHigh, taxDragPercentageHigh]
  );

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="body1">
        Adjust the range of available returns. These values are used when
        generating a client proposal.
      </Typography>
      <ThresholdCard afterTaxReturn={afterTaxReturnLow} thresholdPath="Low" />
      <ThresholdCard
        afterTaxReturn={afterTaxReturnMedium}
        thresholdPath="Medium"
      />
      <ThresholdCard afterTaxReturn={afterTaxReturnHigh} thresholdPath="High" />
    </Stack>
  );
}

function ThresholdCard({
  afterTaxReturn,
  thresholdPath,
}: {
  afterTaxReturn: string;
  thresholdPath: 'Low' | 'Medium' | 'High';
}) {
  const { control } = useFormContext<PreTaxReturnLimitModalForm>();
  return (
    <Card variant="filled" sx={{ p: 2 }}>
      <FormLayoutRow>
        <FormLayoutItem width={6}>
          <FormAwareFormattedNumberInput
            isDecimalJSInput
            control={control}
            allowNegative
            label={`${thresholdPath} pre-tax return`}
            fieldName={
              `${NAMESPACE}.preTaxReturnPercentage${thresholdPath}` as const satisfies PreTaxReturnLimitModalFormPaths
            }
            fixedDecimalScale
            decimalScale={0}
            endAdornment="%"
            contextualHelp={
              <PopperContent body="Represents the pre-tax annual projected rate of return." />
            }
            required
          />
        </FormLayoutItem>
        <FormLayoutItem width={6}>
          <FormAwareFormattedNumberInput
            control={control}
            fieldName={`${NAMESPACE}.taxDragPercentage${thresholdPath}`}
            fixedDecimalScale
            decimalScale={0}
            isDecimalJSInput
            required
            endAdornment="%"
            label="Associated tax drag"
            contextualHelp={
              <PopperContent body="Tax drag is the amount of annual return assumed to be used to pay taxes on investment income. Enter a value between 0-100%. For example, if a $1,000,000 portfolio has a 10% pre-tax return and a 20% tax drag, then 20% of the $100,000 pre-tax investment return, or $20,000, is assumed to be the tax due on the portfolio's investment income." />
            }
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <Callout severity="info-high">
            Calculated after-tax return of {afterTaxReturn}
          </Callout>
        </FormLayoutItem>
      </FormLayoutRow>
    </Card>
  );
}
