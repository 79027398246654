import { cx } from '@emotion/css';
import {
  Box,
  Stack,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { GridApiPro, GridColumnGroupingModel } from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import { compact, groupBy, isEmpty, isEqual } from 'lodash';
import { MutableRefObject, useCallback, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { LinkExternal01Icon } from '@/components/icons/LinkExternal01Icon';
import { Draghandle } from '@/components/lists/DragAndDropList/Draghandle';
import { HeaderCellTypography } from '@/components/tables/components/HeaderCellTypography';
import { CellContainer } from '@/components/tables/DataTable/components/cells';
import { SHOW_ON_ROW_HOVER_CLASSNAME } from '@/components/tables/DataTable/components/ThemedDataGrid';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';
import { BalanceSheetRowType } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import {
  formatCurrency,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';

import { RowData } from './BalanceSheetTable.constants';
import { useUnguardedBalanceSheetTableContext } from './BalanceSheetTableContext';
import {
  BalanceSheetTable_BalanceSheetColumnFragment,
  BalanceSheetTable_BalanceSheetFragment,
} from './graphql/BalanceSheetTable.fragments.generated';

export const DISPLAY_NAME_COLUMN_ID = 'name';
export const TOTAL_COLUMN_SUFFIX = '_TOTAL';

function isChildRow(row: RowData): boolean {
  return row.path.length > 1;
}

function getSpacingProps(row: RowData): TypographyProps {
  if (isChildRow(row)) {
    return { pl: 2 };
  }
  return {};
}

const useHeaderStyles = makeStyles()((_theme) => ({
  // eslint-disable-next-line tss-unused-classes/unused-classes
  header: {
    backgroundColor: `${COLORS.GRAY[200]} !important`,
  },
}));

interface GetRowPropsArgs {
  row: RowData;
  isLineTwo?: boolean;
}

function getRowProps({ row, isLineTwo }: GetRowPropsArgs): TypographyProps {
  switch (row.variant) {
    case BalanceSheetRowType.Total:
      return {
        color: COLORS.GRAY[600],
        fontWeight: FONT_WEIGHTS.bold,
      };
    case BalanceSheetRowType.NetWorth:
      return {
        color: 'white',
        fontWeight: isLineTwo ? undefined : FONT_WEIGHTS.bold,
      };
    case BalanceSheetRowType.Category:
      return {
        fontWeight: FONT_WEIGHTS.semibold,
      };
    case BalanceSheetRowType.LineItem:
      return {
        fontWeight: FONT_WEIGHTS.regular,
        ...(isLineTwo ? { title: row.name.lineTwo } : {}),
      };
    default:
      return {};
  }
}

export function getIsTotalColumn(
  column: BalanceSheetTable_BalanceSheetColumnFragment
) {
  return column.ID.endsWith(TOTAL_COLUMN_SUFFIX);
}

function getSortedBalanceSheetColumns(
  balanceSheetColumns: BalanceSheetTable_BalanceSheetColumnFragment[],
  currentColumns: { field: string }[]
): BalanceSheetTable_BalanceSheetColumnFragment[] {
  if (currentColumns.length <= 1) {
    return balanceSheetColumns;
  }

  const currentColumnOrder = compact(
    currentColumns.map((column) => {
      if (
        column.field === DISPLAY_NAME_COLUMN_ID ||
        column.field.startsWith('__')
      ) {
        return null;
      }

      return column.field;
    })
  );
  const balanceSheetColumnOrder = balanceSheetColumns.map(
    (column) => column.ID
  );

  // if we're in a state where the API balance sheet columns haven't yet been persisted onto the grid state, don't
  // attempt to reorder the columns to match the local state
  if (
    isEqual(currentColumnOrder, balanceSheetColumnOrder) ||
    currentColumnOrder.length !== balanceSheetColumnOrder.length
  ) {
    return balanceSheetColumns;
  }

  return [...balanceSheetColumns].sort((a, b) => {
    return currentColumnOrder.indexOf(a.ID) - currentColumnOrder.indexOf(b.ID);
  });
}

export function useBalanceSheetTableColumns(
  balanceSheet: BalanceSheetTable_BalanceSheetFragment | null,
  gridAPIRef: MutableRefObject<GridApiPro> | undefined
) {
  const theme = useTheme();
  const isViewOnly = useViewOnly();
  const { classes: headerClasses } = useHeaderStyles();
  const tableContext = useUnguardedBalanceSheetTableContext();

  const onAssociatedItemsClick = useCallback(
    (row: RowData) => {
      if (!isEmpty(row.entityIds)) {
        tableContext?.setSelectedEntitiesRow({
          entityIds: row.entityIds ?? [],
          row,
        });
      } else if (row.liabilityId) {
        tableContext?.setSelectedLiabilityRow({
          liabilityId: row.liabilityId,
          row,
        });
      }
    },
    [tableContext]
  );

  const columns = useMemo(() => {
    if (!balanceSheet) return [];
    diagnostics.debug('[BALANCE SHEET] Recomputing columns');
    const defaultColumns: Column<RowData>[] = [
      {
        field: DISPLAY_NAME_COLUMN_ID,
        headerName: 'Assets',
        disableReorder: true,
        sortable: false,
        flex: 1,
        minWidth: 250,
        renderCell: TwoLineTextRenderer({
          lineOne: ({ row }) => row.name.lineOne,
          lineTwo: ({ row }) => row.name.lineTwo,
          lineOneProps: ({ row }) => ({
            title: row.name.lineOne,
            sx: {
              fontWeight: isChildRow(row) ? undefined : 'bold',
              ...getSpacingProps(row),
              ...getRowProps({ row }),
            },
          }),
          lineTwoProps: ({ row }) => ({
            ...getSpacingProps(row),
            ...getRowProps({ row, isLineTwo: true }),
          }),
          textAccompaniment: ({ row }) => {
            const hasAssociatedNodes =
              !isEmpty(row.entityIds) || row.liabilityId;
            if (hasAssociatedNodes && !isViewOnly) {
              return (
                <Box className={SHOW_ON_ROW_HOVER_CLASSNAME}>
                  <IconButton
                    icon={LinkExternal01Icon}
                    ariaLabel="View associated entities"
                    size="xs"
                    variant="transparent"
                    onClick={(e) => {
                      e.stopPropagation();
                      onAssociatedItemsClick(row);
                    }}
                  />
                </Box>
              );
            }
            return null;
          },
        }),
      },
    ];

    const currentColumns = gridAPIRef?.current.getAllColumns?.() ?? [];

    const sortedBalanceSheetColumns = getSortedBalanceSheetColumns(
      balanceSheet.columns,
      currentColumns
    );

    const dynamicColumns: Column<RowData>[] = sortedBalanceSheetColumns.map(
      (column, i) => {
        const isTotalColumn = getIsTotalColumn(column);
        const isLastColumn = i === balanceSheet.columns.length - 1;

        return {
          field: column.ID,
          headerName: column.display,
          headerClassName: isTotalColumn ? cx(headerClasses.header) : undefined,
          renderHeader: ({ colDef }) => {
            const canReorder = !colDef.disableReorder;
            return (
              <CellContainer
                align={colDef.align}
                sx={{ flexGrow: 1, pl: canReorder ? 0 : undefined }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  {canReorder && (
                    <Draghandle iconProps={{ sx: { height: 40 } }} />
                  )}
                  <HeaderCellTypography
                    clampLines={true}
                    sx={{ color: isTotalColumn ? COLORS.NAVY[900] : undefined }}
                  >
                    {column.display}
                  </HeaderCellTypography>
                </Stack>
              </CellContainer>
            );
          },
          flex: 1,
          align: 'right',
          type: 'number',
          // don't allow reordering for total columns
          disableReorder: isTotalColumn || !tableContext?.isEditingColumnOrder,
          sortable: false,
          minWidth: 150,
          renderCell: TwoLineTextRenderer({
            cellContainerProps: ({ row }) => {
              const isSumRow =
                row.variant === BalanceSheetRowType.Total ||
                row.variant === BalanceSheetRowType.NetWorth;
              // targeting the IN_ESTATE_TOTAL and OUT_OF_ESTATE_TOTAL columns,
              // but don't want to override the special styling for the Total and NetWorth rows
              const isNonSumTotalColumnRow = isTotalColumn && !isSumRow;
              const isTotalSumCell =
                isTotalColumn && row.variant === BalanceSheetRowType.Total;

              // we also don't want to show the column highlight if there is only one column OR
              // there are two columns and they are both _TOTAL columns.
              const onlyTotalColumns =
                balanceSheet.columns.every(getIsTotalColumn);
              const showColumnHighlight =
                isNonSumTotalColumnRow &&
                balanceSheet.columns.length >= 2 &&
                !onlyTotalColumns;

              const borderRightStyle = (() => {
                const NONE_STYLE = 'none';
                const DEFAULT_STYLE = `1px solid ${COLORS.GRAY[200]}`;
                const HIGHLIGHT_BORDER_STYLE = `2px solid ${theme.palette.primary.main}`;

                if (isLastColumn) {
                  return NONE_STYLE;
                }

                if (row.variant === BalanceSheetRowType.NetWorth) {
                  if (column.ID === 'IN_ESTATE_TOTAL') {
                    return DEFAULT_STYLE;
                  }
                  return NONE_STYLE;
                }

                if (column.ID === 'IN_ESTATE_TOTAL') {
                  return HIGHLIGHT_BORDER_STYLE;
                }

                return DEFAULT_STYLE;
              })();

              return {
                sx: {
                  borderRight: borderRightStyle,
                  ...(showColumnHighlight
                    ? {
                        '&:not(:hover)': {
                          backgroundColor: COLORS.GRAY[50],
                        },
                      }
                    : {}),
                  ...(isTotalSumCell
                    ? { backgroundColor: COLORS.GRAY[200] }
                    : {}),
                },
              };
            },
            lineOne: ({ row }) => {
              const rowValue = row.values[column.ID];
              if (!rowValue) return '';

              const showZeroValueRow =
                row.variant === BalanceSheetRowType.Total ||
                row.variant === BalanceSheetRowType.NetWorth;
              const showZeroValueColumn =
                column.ID === 'IN_ESTATE_TOTAL' ||
                column.ID === 'OUT_OF_ESTATE_TOTAL';

              if (
                (!rowValue.value || rowValue.value.isZero()) &&
                !(showZeroValueRow && showZeroValueColumn)
              ) {
                return '';
              }

              const value = rowValue.value ?? new Decimal(0);

              if (
                new Decimal(0).lessThan(value) &&
                value.lessThan(new Decimal(1))
              ) {
                return formatCurrency(value);
              }

              return formatCurrencyNoDecimalsAccounting(value);
            },
            lineOneProps: ({ row }) => {
              const rowValue = row.values[column.ID];
              if (!rowValue) return {};
              return {
                ...(rowValue.value?.isNegative()
                  ? { color: 'error.main' }
                  : {}),
                ...getRowProps({ row }),
              };
            },
            lineTwo: ({ row }) => {
              const rowValue = row.values[column.ID];
              return rowValue?.description ?? '';
            },
          }),
        };
      }
    ) as Column<RowData>[];

    return [...defaultColumns, ...dynamicColumns];
  }, [
    balanceSheet,
    gridAPIRef,
    isViewOnly,
    onAssociatedItemsClick,
    headerClasses.header,
    tableContext?.isEditingColumnOrder,
    theme.palette.primary.main,
  ]);
  return columns;
}

export function useBalanceSheetTableColumnGroups(
  balanceSheet: BalanceSheetTable_BalanceSheetFragment | null
) {
  const columnGroups = useMemo(() => {
    if (!balanceSheet) return [];

    const groupedColumns = groupBy(balanceSheet.columns, 'group');
    const columnGroupMap = new Map(
      balanceSheet.columnGroups.map((group) => [group.ID, group.display])
    );

    const columnGroupingModel: GridColumnGroupingModel = Object.entries(
      groupedColumns
    ).map(([groupId, columns]) => ({
      groupId,
      children: columns.map((column) => ({ field: column.ID })),
      headerName: columnGroupMap.get(groupId) || groupId,
      renderHeaderGroup: (params) => {
        return (
          <Box p={2}>
            <Typography variant="h6" sx={{ color: 'white' }}>
              {params.headerName}
            </Typography>
          </Box>
        );
      },
    }));

    return columnGroupingModel;
  }, [balanceSheet]);
  return columnGroups;
}
