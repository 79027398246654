// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
export type SummaryBeneficiaryv2Fragment = { __typename?: 'SummaryBeneficiaryv2', id: string, displayName: string, kind: Types.SummaryBeneficiaryKindv2, badgeText?: string | null, additionalContext: Array<string | null>, ownershipPercentage: string, displayType: string };

export const SummaryBeneficiaryv2FragmentDoc = gql`
    fragment SummaryBeneficiaryv2 on SummaryBeneficiaryv2 {
  id
  displayName
  kind
  badgeText
  additionalContext
  ownershipPercentage
  displayType
}
    `;
export const Operations = {
  Fragment: {
    SummaryBeneficiaryv2: 'SummaryBeneficiaryv2' as const
  }
}