import { Theme } from '@emotion/react';
import { Divider, Stack, SxProps, Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { Medallion } from '@/components/display/Medallion/Medallion';
import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { CoinsSwap02Icon } from '@/components/icons/CoinsSwap02Icon';
import { Card } from '@/components/layout/Card/Card';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { ImpliedEstateTaxSavings } from '@/modules/content/tooltipContent/ImpliedEstateTaxSavings';
import { GratPerformanceFragment } from '@/modules/entities/gratTrusts/graphql/GratTrust.generated';
import { getPerformanceForGrat } from '@/modules/entities/gratTrusts/performance/getPerformanceForGrat';
import { COLORS } from '@/styles/tokens/colors';
import { EntityStage } from '@/types/schema';
import {
  formatCurrency,
  formatCurrencyNoDecimals,
} from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { EntityDetailsData } from '../entityDetails.types';

interface RowProps {
  label: React.ReactNode;
  display: React.ReactNode;
  sx?: SxProps<Theme>;
}

function PrimaryRow({ label, display, sx }: RowProps) {
  return (
    <Stack
      sx={sx}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body1" component="span">
        {label}
      </Typography>
      <Typography variant="h5" component="span" color={COLORS.NAVY[900]}>
        {display}
      </Typography>
    </Stack>
  );
}

function SecondaryRow({ label, display, sx }: RowProps) {
  return (
    <Stack
      sx={sx}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="body1" color={COLORS.GRAY[500]}>
        {label}
      </Typography>
      <Typography variant="h5" component="span" color={COLORS.GRAY[500]}>
        {display}
      </Typography>
    </Stack>
  );
}

interface GRATDistributionSumsProps {
  gratTrust: GratPerformanceFragment & { stage: EntityStage };
}

function GRATDistributionSums({ gratTrust }: GRATDistributionSumsProps) {
  const performance = getPerformanceForGrat(gratTrust);

  return (
    <Stack height="100%" justifyContent="space-between">
      <Stack spacing={2} justifyContent="flex-start">
        <Stack spacing={2} divider={<Divider />}>
          <PrimaryRow
            sx={{
              mx: 1,
            }}
            label="GRAT remainder value"
            display={formatCurrency(
              gratTrust.distributionAssetValuation?.valuationValue ??
                new Decimal(0)
            )}
          />
          <SecondaryRow
            sx={{ pl: 4, pr: 1 }}
            label="Taxable gift"
            display={formatCurrency(performance.taxableGift)}
          />
        </Stack>
        <PrimaryRow
          sx={{ px: 1, py: 1, borderTop: `solid ${COLORS.GRAY[500]} 2px` }}
          label="Tax-free transfer"
          display={formatCurrency(performance.taxFreeTransfer)}
        />
      </Stack>

      <Card variant="inner-shadow" sx={{ p: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
        >
          <Medallion
            size={40}
            icon={<CoinsSwap02Icon size={20} />}
            colors={{
              background: COLORS.ORANGE[500],
              iconColor: COLORS.PRIMITIVES.WHITE,
            }}
          />
          <OversizedMetricItem
            title={
              <Stack direction="row" spacing={0.5}>
                <span>Implied estate tax savings</span>
                <ContextualHelpTooltip>
                  <ImpliedEstateTaxSavings />
                </ContextualHelpTooltip>
              </Stack>
            }
            value={formatCurrencyNoDecimals(
              performance.impliedEstateTaxSavings
            )}
          />
        </Stack>
      </Card>
    </Stack>
  );
}

/**
 * @description This is the distribution card to be rendered for completed GRATs. You probably want to use the StageAwareGRATAssetsCard instead,
 * which correctly renders this is or the GRAT distribution card, depending on the stage of the GRAT entity.
 */
export function GRATDistributionSummaryCard({ entity }: EntityDetailsData) {
  if (entity.subtype.__typename !== 'GRATTrust') {
    throw new Error('Invalid trust type');
  }

  return (
    <Stack p={2} justifyContent="space-between" height="100%">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        mb={2}
      >
        {entity.subtype.completionDate && (
          <Typography variant="h1" component="h2">
            Ended on {formatDateToMonDDYYYY(entity.subtype.completionDate)}
          </Typography>
        )}
      </Stack>
      <GRATDistributionSums
        gratTrust={{ ...entity.subtype, stage: entity.stage }}
      />
    </Stack>
  );
}
