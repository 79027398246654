import { Stack, Typography } from '@mui/material';
import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { FieldArrayWithId, useWatch } from 'react-hook-form';

import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { RibbonCard } from '@/components/layout/RibbonCard';
import { DragAndDropList } from '@/components/lists/DragAndDropList/DragAndDropList';
import { TableCell, TableCellContext } from '@/components/lists/TableCell';
import { useFormContext } from '@/components/react-hook-form';
import { EstateTaxScenarioPayoutKind } from '@/types/schema';

import { useEstateTaxScenariosContext } from '../../contexts/estateTaxScenarios.context';
import { payoutMethodOptions } from '../../EstateTaxScenarios.constants';
import {
  EstateTaxScenarioFormData,
  EstateTaxScenarioFormPaths,
} from '../../types';
import {
  SortableListItem,
  useDraggablePayoutOrderItems,
} from './useDraggablePayoutOrderItems';

enum PayoutOrder {
  FIRST = 0,
  SECOND = 1,
}

export interface PaymentMethodFormProps {
  field: FieldArrayWithId<EstateTaxScenarioFormData, 'scenarios', 'id'>;
  identifier: `scenarios.${number}`;
}

export const PaymentMethodForm = ({
  field,
  identifier,
}: PaymentMethodFormProps) => {
  const { control, setValue } = useFormContext<EstateTaxScenarioFormData>();
  const { scenariosByPrimaryClientId, isTwoClientHousehold } =
    useEstateTaxScenariosContext();
  const [payoutOrderIdx, setPayoutOrderIdx] = useState<PayoutOrder>(
    PayoutOrder.FIRST
  );

  const scenario = scenariosByPrimaryClientId[field.firstGrantorDeathID];

  const fieldNames = {
    payoutKind:
      `${identifier}.payoutKind` as const satisfies EstateTaxScenarioFormPaths,
    firstDeathPayoutOrder:
      `${identifier}.firstDeathPayoutOrder` as const satisfies EstateTaxScenarioFormPaths,
    secondDeathPayoutOrder:
      `${identifier}.secondDeathPayoutOrder` as const satisfies EstateTaxScenarioFormPaths,
  };

  const payoutKind = useWatch({ control, name: fieldNames.payoutKind });

  const firstDeathPayoutOrder = useWatch({
    control,
    name: fieldNames.firstDeathPayoutOrder,
  });

  const secondDeathPayoutOrder = useWatch({
    control,
    name: fieldNames.secondDeathPayoutOrder,
  });

  const payoutOrder = (
    [firstDeathPayoutOrder, secondDeathPayoutOrder!] as const
  )[payoutOrderIdx];

  const missingEntities = useMemo(() => {
    const orderEntities = [
      scenario?.firstDeathPayoutOrderMissingEntities ?? [],
      scenario?.secondDeathPayoutOrderMissingEntities ?? [],
    ] as const;
    return orderEntities[payoutOrderIdx].map((s) => s.id) ?? [];
  }, [
    payoutOrderIdx,
    scenario?.firstDeathPayoutOrderMissingEntities,
    scenario?.secondDeathPayoutOrderMissingEntities,
  ]);

  const currentPayoutOrderFieldName = (
    [
      fieldNames.firstDeathPayoutOrder,
      fieldNames.secondDeathPayoutOrder,
    ] as const
  )[payoutOrderIdx];

  const items = useDraggablePayoutOrderItems({
    payoutOrder,
    missingEntities,
  });

  const onDropped = useCallback<
    NonNullable<ComponentProps<typeof DragAndDropList>['onDropped']>
  >(
    (list) => {
      const values = list.map((v) => v.id);
      setValue(currentPayoutOrderFieldName, values);
    },
    [currentPayoutOrderFieldName, setValue]
  );

  return (
    <RibbonCard heading="Estate tax payment method">
      <FormLayoutRow>
        <FormLayoutItem>
          <Typography>
            Calculation method specified here will apply to all estate
            waterfalls created for this client
          </Typography>
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareRadioGroup<EstateTaxScenarioFormData>
            stackingDirection="column"
            control={control}
            fieldName={
              `${identifier}.payoutKind` as const satisfies EstateTaxScenarioFormPaths
            }
            options={payoutMethodOptions}
            row
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {payoutKind === EstateTaxScenarioPayoutKind.Custom && (
        <>
          {isTwoClientHousehold && (
            <FormLayoutRow>
              <FormLayoutItem width={1} />
              <FormLayoutItem width={10}>
                <ButtonGroup
                  label=""
                  variant="dark"
                  onChange={(_event, value) => {
                    setPayoutOrderIdx(value);
                  }}
                  options={[
                    {
                      display: '1st death payout order',
                      value: PayoutOrder.FIRST,
                    },
                    {
                      display: '2nd death payout order',
                      value: PayoutOrder.SECOND,
                    },
                  ]}
                  value={payoutOrderIdx}
                />
              </FormLayoutItem>
              <FormLayoutItem width={1} />
            </FormLayoutRow>
          )}
          <FormLayoutRow>
            <FormLayoutItem width={1} />
            <FormLayoutItem width={10}>
              <Stack spacing={3}>
                <Stack>
                  <DragAndDropList<SortableListItem>
                    key={currentPayoutOrderFieldName}
                    items={items}
                    ItemInnerComponent={({ item, Draghandle }) => (
                      <TableCell
                        RightContent={
                          item.context && <TableCellContext {...item.context} />
                        }
                        {...item}
                        Draghandle={Draghandle}
                      />
                    )}
                    droppableId={`${currentPayoutOrderFieldName}-payout-order`}
                    onDropped={onDropped}
                  ></DragAndDropList>
                </Stack>
              </Stack>
            </FormLayoutItem>
            <FormLayoutItem width={1} />
          </FormLayoutRow>
        </>
      )}
    </RibbonCard>
  );
};
