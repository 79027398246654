import { compose } from '@/utils/reducerUtils';

import { applyAutoLayoutToState, applySectionBoundsToState } from '../state';
import { AutoPositionAction, EstateWaterfallActionFn } from '../types';

export const autoPositionAction: EstateWaterfallActionFn<AutoPositionAction> = (
  state,
  _action,
  queueEffect
) => {
  queueEffect({ type: 'fitViewEffect' });

  return compose(applyAutoLayoutToState, applySectionBoundsToState)(state);
};
