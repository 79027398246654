import { AsyncJobStatus } from '@/types/schema';

export const PENDING_ASYNC_JOB_STATUSES = [
  AsyncJobStatus.InProgress,
  AsyncJobStatus.Queued,
];

export const TERMINAL_ASYNC_JOB_STATUSES = [
  AsyncJobStatus.Completed,
  AsyncJobStatus.Failed,
  AsyncJobStatus.Cancelled,
];
