import { Box, Stack } from '@mui/material';
import React, { ComponentType, useState } from 'react';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { ChevronDownIcon } from '@/components/icons/ChevronDownIcon';
import { EmptyListItem } from '@/components/typography/EmptyListItem';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { useIsElementHovered } from '@/hooks/useIsElementHovered';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';

import {
  ACTIVE_SLIDE_BORDER_WIDTH_PX,
  SIDEBAR_VERTICAL_SPACING,
  sidebarElementMainTextStyles,
} from '../ClientPresentationSidebar.constants';

// This is the presentational base version of the SidebarGroup component.
export type SidebarGroupElementProps = React.PropsWithChildren<{
  label: React.ReactNode;
  RightAction?: ComponentType<{ isHovered: boolean }>;
  LeftAction?: ComponentType<{ isHovered: boolean }>;
}>;

export function SidebarGroupElement({
  label,
  LeftAction,
  RightAction,
  children,
}: SidebarGroupElementProps) {
  const [isHovered, mouseOverProps] = useIsElementHovered();
  const [isExpanded, setIsExpanded] = useState(true);

  const validChildren = React.Children.toArray(children).filter(Boolean);

  return (
    // This parent stack wraps everything: the "tab" component and the children
    <Stack>
      {/* this stack wraps just the tab component */}
      <Stack direction="row" alignItems="center" {...mouseOverProps}>
        {LeftAction && <LeftAction isHovered={isHovered} />}
        <Stack
          direction="row"
          width={20}
          flexGrow={1}
          alignItems="center"
          pl="12px"
          p={0.5}
          pr={0}
          sx={{
            backgroundColor: COLORS.GRAY[200],
            borderRight: `solid transparent ${ACTIVE_SLIDE_BORDER_WIDTH_PX}px`,
          }}
        >
          <ExpandElementButton
            onClick={() => setIsExpanded(!isExpanded)}
            isExpanded={isExpanded}
          />
          <TypographyClamped
            lines={2}
            variant="body1"
            sx={{
              p: 1,
              fontWeight: FONT_WEIGHTS.semibold,
              flexGrow: 1,
              // smaller line-height to keep the element from expanding to be larger
              // than elements with only a single line of text
              lineHeight: '1.1em',
              ...sidebarElementMainTextStyles,
            }}
          >
            {label}
          </TypographyClamped>
          {RightAction && <RightAction isHovered={isHovered} />}
        </Stack>
      </Stack>
      {isExpanded && validChildren.length > 0 && (
        <Stack
          mt={SIDEBAR_VERTICAL_SPACING}
          spacing={SIDEBAR_VERTICAL_SPACING}
          pl={2}
        >
          {children}
        </Stack>
      )}
      {isExpanded && validChildren.length === 0 && (
        <Box px={2}>
          <EmptyListItem>No remaining slides</EmptyListItem>
        </Box>
      )}
    </Stack>
  );
}

interface ExpandElementButtonProps extends Partial<ButtonProps> {
  isExpanded: boolean;
}

function ExpandElementButton({
  isExpanded,
  ...buttonProps
}: ExpandElementButtonProps) {
  return (
    <Button
      variant="transparent"
      size="xs"
      sx={{ height: '100%', px: 0 }}
      {...buttonProps}
    >
      <ChevronDownIcon
        sx={{ transform: `rotate(${isExpanded ? 0 : 270}deg)` }}
        color={COLORS.GRAY[400]}
      />
    </Button>
  );
}
