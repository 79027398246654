import { Stack, Typography } from '@mui/material';
import { keys } from 'lodash';
import { useWatch } from 'react-hook-form';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { Button } from '@/components/form/baseInputs/Button';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';
import {
  EntityImportTable,
  EntityImportTableSubmitButton,
} from '@/modules/import/EntityImportTable';

import { BULK_IMPORT_STEP_CLIENT_SELECTION } from '../BulkImportPage.constants';
import { BulkImportForm, BulkImportStepConfig } from '../BulkImportPage.types';

export function BulkImportEntityStep() {
  const { control } = useFormContext<BulkImportForm>();
  const householdMap = useWatch({
    control,
    name: 'householdMap',
  });
  return (
    <SubpageLayout heading="Step 3: Import entities of linked clients">
      <Card variant="outlined">
        <Stack spacing={3} p={3}>
          <EntityImportTable
            initialExpandedHouseholdIDs={keys(householdMap)}
            tableCaption={
              <Typography variant="body1">
                The following entities are available to import for the newly
                created clients. Entities can also be imported at a later date
                or by creating an entity in Luminary first and linking it from
                there.
              </Typography>
            }
          />
        </Stack>
      </Card>
    </SubpageLayout>
  );
}

function PreviousButton(): JSX.Element {
  const { getValues, setValue } = useFormContext<BulkImportForm>();
  const selectedIntegrationClientIds = getValues(
    'selectedIntegrationClientIds'
  );

  if (selectedIntegrationClientIds.length > 0) {
    return <></>;
  } else {
    return (
      <Button
        variant="transparent"
        size="sm"
        onClick={() => {
          setValue('step', BULK_IMPORT_STEP_CLIENT_SELECTION);
        }}
      >
        Previous step
      </Button>
    );
  }
}

export const BulkImportEntityStepConfig: BulkImportStepConfig = {
  Component: BulkImportEntityStep,
  NextButton: EntityImportTableSubmitButton,
  PreviousButton,
};
