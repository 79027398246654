import { Box, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { get } from 'lodash';
import { FieldValues, RegisterOptions, useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { COLORS } from '@/styles/tokens/colors';
import { FieldNameFromFormShape } from '@/types/react-hook-form';
import { formatPercent } from '@/utils/formatting/percent';

import { FormAwareDisplayValue } from '../FormAwareDisplayValue';
import { useValidateOnChange } from '../hooks/useValidateOnChange';

export function validateExactly100Percent(
  value: Decimal | null | undefined
): string | undefined {
  if (!value) return undefined;
  if (value.eq(new Decimal(100))) return undefined;
  return `This must be exactly 100%`;
}

export function validateAtMost100Percent(
  value: Decimal | null | undefined
): string | undefined {
  if (!value) return undefined;
  if (value.lte(new Decimal(100))) return undefined;
  return `This must be less than or equal to 100%`;
}

interface ValidatedInputRepeaterSumRowProps<Fields extends FieldValues> {
  validationField: FieldNameFromFormShape<Fields>;
  decimalScale: number;
  validation?: RegisterOptions<Fields>['validate'];
  validateOnChange?: boolean;
}

/**
 * @description This component is used when you want to display computed values, but would also like to be able to validate those computed
 * values. It is specifically used in combination with FormAwareInputRepeater to display the sum of a series of inputs, and to allow the
 * sum of those input to be validated, then focused on at form submit in the scenario that they're invalid.
 */
export function ValidatedInputRepeaterPercentSumRow<
  Fields extends FieldValues,
>({
  validationField,
  decimalScale,
  validation,
  validateOnChange,
}: ValidatedInputRepeaterSumRowProps<Fields>) {
  const {
    control,
    formState: { errors, submitCount },
  } = useFormContext<Fields>();
  const fieldValue = useWatch({
    name: validationField,
    control,
  });

  useValidateOnChange<Fields>(
    validateOnChange ?? false,
    validationField,
    control,
    {
      valueType: 'decimal',
    }
  );

  // using get to support array path strings like 'foo.0.bar'
  const fieldIsInvalid =
    Boolean(get(errors, validationField)) && submitCount > 0;
  return (
    <Box
      sx={{
        pt: 0.5,
        pl: 2,
        borderTop: 'solid black 2px',
      }}
    >
      <FormAwareDisplayValue<Fields>
        fieldName={validationField}
        control={control}
        validation={validation}
      >
        <Typography
          variant="h4"
          component="span"
          sx={{
            color: fieldIsInvalid ? COLORS.FUNCTIONAL.ERROR.DEFAULT : undefined,
          }}
        >
          {formatPercent(fieldValue ?? new Decimal(0), decimalScale)}%
        </Typography>
      </FormAwareDisplayValue>
    </Box>
  );
}
