import { compact } from 'lodash';
import { useMemo } from 'react';

import { BigBreadcrumbs } from '@/components/navigation/BigBreadcrumbs';

interface EntitiesBreadcrumbProps {
  pageCrumb?: string;
  householdId: string;
}

export function EntitiesBreadcrumb({ pageCrumb }: EntitiesBreadcrumbProps) {
  const crumbs = useMemo(() => {
    return compact([
      pageCrumb && {
        label: pageCrumb,
      },
    ]);
  }, [pageCrumb]);

  return <BigBreadcrumbs crumbs={crumbs} />;
}
