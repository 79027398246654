import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { PathsOf } from '@/types/subform';
import { sumDecimalJS } from '@/utils/decimalJSUtils';

import {
  ILITAnnualExclusionBeneficiary,
  ILITMakeAnnualExclusionGiftsForm,
} from '../ILITMakeAnnualExclusionGiftsTask.types';

function useBeneficiaries(grantorId: string): ILITAnnualExclusionBeneficiary[] {
  const { control } = useFormContext<ILITMakeAnnualExclusionGiftsForm>();

  const lookupPath =
    `grantors.${grantorId}.beneficiariesById` satisfies PathsOf<ILITMakeAnnualExclusionGiftsForm>;
  const beneficiariesObject = useWatch({
    name: lookupPath,
    control,
  });
  return Object.values(beneficiariesObject || {});
}

export function useTotalGiftedAmount(grantorId: string): Decimal {
  const beneficiaries = useBeneficiaries(grantorId);

  return sumDecimalJS(
    compact(beneficiaries.map((beneficiary) => beneficiary.giftedAmount))
  );
}

export function useTotalAnnualExclusionUsed(grantorId: string): Decimal {
  const beneficiaries = useBeneficiaries(grantorId);
  return sumDecimalJS(
    compact(beneficiaries.map((beneficiary) => beneficiary.annualExclusionUsed))
  );
}

export function useTotalLifetimeExemptionUsed(grantorId: string): Decimal {
  const beneficiaries = useBeneficiaries(grantorId);
  return sumDecimalJS(
    compact(
      beneficiaries.map((beneficiary) => beneficiary.lifetimeExemptionUsed)
    )
  );
}

export function useTotalGSTExemptionUsed(grantorId: string): Decimal {
  const beneficiaries = useBeneficiaries(grantorId);
  return sumDecimalJS(
    compact(beneficiaries.map((beneficiary) => beneficiary.gstExemptionUsed))
  );
}
