import { Node, NodeProps, useReactFlow, useViewport } from '@xyflow/react';

import {
  SectionLabel,
  SectionLabelProps as SectionLabelPropsExternal,
} from '@/components/diagrams/components/SectionLabel';

export interface SectionLabelProps extends Record<string, unknown> {
  classes?: SectionLabelPropsExternal['classes'];
  label: string;
}

export type SectionLabelNodeProps = NodeProps<Node<SectionLabelProps>>;

const labelMargin = 16;

export function SectionLabelNode({
  id,
  data: { classes = {}, label },
}: SectionLabelNodeProps) {
  const { getNode } = useReactFlow();
  const { zoom } = useViewport();
  const node = getNode(id);
  const nodeWidth = node?.width ?? 0;

  const zoomFactor = 1 / zoom;
  const anchorLabelOffset = (1 - zoom) / 2;
  const badgeTranslateX = nodeWidth * anchorLabelOffset + labelMargin;

  return (
    <SectionLabel
      isZoomable
      label={label}
      classes={classes}
      styles={{
        // Scales only height, but leaves width untouched for full width styles
        hr: {
          willChange: 'transform',
          transform: `scale(1, ${zoomFactor}) translateY(-50%)`,
        },
        badgeWrapper: {
          willChange: 'transform',
          transform: `scale(${zoomFactor}) translateX(${badgeTranslateX}px)`,
        },
      }}
    />
  );
}
