import Decimal from 'decimal.js';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useMemo } from 'react';

import { CurrencyUSD, Percent } from '@/graphql/scalars';
import { EntityKind, EntityStage } from '@/types/schema';

import { ProposalBuilderPageQueryQuery } from '../graphql/ProposalBuilderPageQuery.generated';

export interface DisplayEntity {
  displayName: string;
  description: string;
  assetSummaryDisplay: string;
  computedValue: CurrencyUSD;
  id: string;
  projectedRateOfReturn: Percent;
  entityKind: EntityKind;
  termDurationYears: number;
  rollingPeriodYears: number | null;
  projectedMarketValueAtTerm: CurrencyUSD;
  stage: EntityStage;
}

export type DisplayEntities = Maybe<DisplayEntity>[];

export const useClientEntities = (
  proposal: ProposalBuilderPageQueryQuery | undefined
) => {
  const displayEntities = useMemo<DisplayEntities>(() => {
    if (!proposal) return [];

    const entitiesForClient = proposal.entities.edges ?? [];

    return entitiesForClient.flatMap((edge) => {
      const entity = edge?.node;
      if (!entity) return [];

      const id = entity?.id ?? '';
      const isGrat = !!entity?.gratTrust;

      if (!isGrat) return [];

      const entityKind = EntityKind.GratTrust;

      const grat = entity?.gratTrust ?? {
        assetSummaryDisplay: '',
        termDurationYears: 0,
        rollingPeriodYears: 0,
        projectedRateOfReturn: new Decimal(0),
        currentValue: new Decimal(0),
        initialFundingValue: new Decimal(0),
        displayName: '',
        gratSummaryDisplay: '',
        projectedMarketValue: new Decimal(0),
      };

      const assetSummaryDisplay = grat.assetSummaryDisplay ?? '';
      const termDurationYears = grat.termDurationYears ?? 0;
      const projectedRateOfReturn =
        grat.projectedRateOfReturn ?? new Decimal(0);
      const computedValue = grat.currentValue || new Decimal(0);
      const displayName = grat.displayName;
      const description = grat.gratSummaryDisplay ?? '';
      const projectedMarketValueAtTerm =
        grat.projectedMarketValue ?? new Decimal(0);

      const rollingPeriodYears = grat.rollingPeriodYears || null;

      const stage = entity.stage ?? EntityStage.Draft;

      const displayGrat: DisplayEntities[number] = {
        id,
        displayName,
        assetSummaryDisplay,
        description,
        computedValue,
        projectedRateOfReturn,
        projectedMarketValueAtTerm,
        entityKind,
        termDurationYears,
        rollingPeriodYears,
        stage,
      };

      return displayGrat;
    });
  }, [proposal]);

  return { displayEntities };
};
