import { Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

import {
  FormAwareElementProps,
  FormAwareInputProps,
} from '@/components/form/formAwareInputs/FormAwareInput.types';
import { useFormContext } from '@/components/react-hook-form';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { AISuggestedValue } from '@/modules/aiSuggestions/AISuggestedValue/AISuggestedValue';
import {
  isSuggestableSuggestion,
  SuggestableSuggestion,
  SuggestedValue,
} from '@/modules/aiSuggestions/AISuggestedValue/AISuggestedValue.types';
import { SuggestableKind } from '@/modules/aiSuggestions/aiSuggestions.types';
import {
  LocateSuggestionIcon,
  LocateSuggestionIconVariant,
} from '@/modules/aiSuggestions/components/LocateSuggestionIcon';
import { useAISuggestionsEnabled } from '@/modules/aiSuggestions/hooks/useAISuggestionsEnabled';
import { useEditEntitySplitScreenModalContext } from '@/modules/entities/EditEntitySplitScreen/contexts/editEntitySplitScreenModal.context';
import { AiSuggestionAcceptanceStatus } from '@/types/schema';

import { useEntitySuggestionsContext } from '../../context/EntitySuggestions.context';

interface SuggestableFormAwareInputProps<
  S extends FieldValues,
  T extends FormAwareInputProps<S>,
> {
  suggestionKind: SuggestableKind;
  FormAwareElement: FormAwareElementProps<S, T>;
}

type SuggestableFormAwareInputCombinedProps<
  S extends FieldValues,
  T extends FormAwareInputProps<S>,
> = SuggestableFormAwareInputProps<S, T> & T;

/**
 * A wrapper around a FormAwareInput that displays a suggested value for the
 * field, and allows the user to accept or reject the suggestion.
 *
 * If accepted, the suggestion will be set as the field's value, and the form
 * will be marked as dirty.
 *
 * NOTE: This only supports suggestions for fields that are single-valued, like
 * text inputs, single select inputs, date pickers, etc.
 */
export function SuggestableFormAwareInput<
  S extends FieldValues,
  T extends FormAwareInputProps<S>,
>(props: SuggestableFormAwareInputCombinedProps<S, T>) {
  const trackUserEvent = useTrackUserEvent();

  const { entityType } = useEditEntitySplitScreenModalContext();
  const aiSuggestionsEnabled = useAISuggestionsEnabled(entityType);

  const { fieldName, suggestionKind, FormAwareElement } = props;
  const { setValue, setShouldBlockNavigation } = useFormContext();
  const {
    acknowledgedSuggestions,
    getSuggestableSuggestion,
    acknowledgeSuggestion,
  } = useEntitySuggestionsContext();

  const suggestion = useMemo<SuggestableSuggestion | undefined>(() => {
    const result = getSuggestableSuggestion(suggestionKind);
    return isSuggestableSuggestion(result) ? result : undefined;
  }, [getSuggestableSuggestion, suggestionKind]);

  const handleAcceptSuggestion = useCallback(
    (id: string, value: SuggestedValue['value']) => {
      setValue(fieldName, value);
      setShouldBlockNavigation(true);
      acknowledgeSuggestion({
        suggestionID: id,
        status: AiSuggestionAcceptanceStatus.Accepted,
      });
      trackUserEvent('ai_suggestion single-value accept', {
        suggestionKind,
      });
    },
    [
      acknowledgeSuggestion,
      fieldName,
      setShouldBlockNavigation,
      setValue,
      suggestionKind,
      trackUserEvent,
    ]
  );

  const handleRejectSuggestion = useCallback(
    (id: string) => {
      acknowledgeSuggestion({
        suggestionID: id,
        status: AiSuggestionAcceptanceStatus.Rejected,
      });
      trackUserEvent('ai_suggestion single-value reject', {
        suggestionKind,
      });
    },
    [acknowledgeSuggestion, suggestionKind, trackUserEvent]
  );

  if (!aiSuggestionsEnabled) {
    // Return the FormAwareElement as is, if AI suggestions are disabled
    return <FormAwareElement {...props} />;
  }

  return (
    <Stack>
      <FormAwareElement
        {...props}
        labelIconEnd={
          <LocateSuggestionIcon
            suggestion={suggestion}
            variant={LocateSuggestionIconVariant.MATCHER}
          />
        }
      />
      {suggestion && !acknowledgedSuggestions[suggestion.id] && (
        <AISuggestedValue
          suggestion={suggestion}
          onAcceptSuggestion={handleAcceptSuggestion}
          onRejectSuggestion={handleRejectSuggestion}
        />
      )}
    </Stack>
  );
}
