import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';
import { compact } from 'lodash';
import { useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { ConfirmationModal } from '@/components/modals/ConfirmationModal/ConfirmationModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { DisplayTable } from '@/components/tables/DisplayTable/DisplayTable';
import { TableRowSkeleton } from '@/components/tables/TableRowSkeleton/TableRowSkeleton';
import { useReportError } from '@/hooks/useReportError';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import {
  AugmentedCreateEntityInput,
  IntegrationClientKind,
} from '@/types/schema';
import { PathsOf } from '@/types/subform';
import { UnreachableError } from '@/utils/errors';

import { useDefaultAssetClassId } from '../tenant/TenantDetailsContext/hooks/useDefaultAssetClassId';
import { useTenantDetailsContext } from '../tenant/TenantDetailsContext/TenantDetailsContext';
import { mapAddeparEntityQueryResponseToTable } from './addepar/EntityImportTable.addepar';
import {
  AddeparAllHouseholdsEntityImportTableQueryVariables,
  useAddeparAllHouseholdsEntityImportTableQuery,
  useAddeparSingleHouseholdEntityImportTableQuery,
} from './addepar/graphql/AddeparEntityImportTable.generated';
import {
  BULK_IMPORT_FLOW_ADDEPAR,
  BulkImportFlowType,
  useBulkImportFlowContext,
} from './BulkImportFlowContext';
import {
  EntityImportTableClientRow,
  EntityImportTableEmptyRows,
} from './EntityImportTable.components';
import {
  EntityImportHouseholdMap,
  EntityImportTableForm,
  NAMESPACE,
} from './EntityImportTable.types';
import { mapFormDataToMutation } from './EntityImportTable.utils';
import { useEntityTableImportMutation } from './graphql/EntityTableImport.generated';

export interface EntityImportTableProps {
  initialExpandedHouseholdIDs?: string[];
  tableCaption?: JSX.Element;
  singleHouseholdId?: string;
}

export function EntityImportTable(props: EntityImportTableProps) {
  const { flowType } = useBulkImportFlowContext();
  switch (flowType) {
    case BULK_IMPORT_FLOW_ADDEPAR:
      return (
        <EntityImportTableEmbeddableInner
          {...props}
          fetchData={
            props.singleHouseholdId
              ? useAddeparSingleHouseholdEntityImportTableQuery
              : useAddeparAllHouseholdsEntityImportTableQuery
          }
          mapDataToTable={mapAddeparEntityQueryResponseToTable}
        />
      );
    default:
      throw new UnreachableError({
        case: flowType,
        message: `Invalid flow type: ${flowType}`,
      });
  }
}

type EntityLookupVariables =
  AddeparAllHouseholdsEntityImportTableQueryVariables & OperationVariables;

interface EntityImportTableInnerProps<TData> extends EntityImportTableProps {
  fetchData: (
    options: QueryHookOptions<TData, EntityLookupVariables>
  ) => QueryResult<TData, EntityLookupVariables>;
  mapDataToTable: (queryResponse: TData) => EntityImportTableForm;
  singleHouseholdId?: string;
}

function getVariables(
  singleHouseholdId: string | undefined,
  flowType: BulkImportFlowType
): EntityLookupVariables {
  if (flowType === BULK_IMPORT_FLOW_ADDEPAR) {
    if (singleHouseholdId) {
      // for this case, if a household not connected with addepar is selected, they'll see the
      // "No entities availble to import" case, as the query should still check that the entities
      // available for import are associated with of the (missing) addepar integration entities
      return {
        householdsLike: { id: singleHouseholdId },
      };
    } else {
      return {
        householdsLike: {
          hasIntegrationClientsWith: [
            {
              kind: IntegrationClientKind.Addepar,
            },
          ],
        },
      };
    }
  } else {
    // this shouldn't be hit, but if it is, we'll just show all households. we can't just do householdsLike: undefined
    // because we need the HouseholdWhereInput to be non-null for the other queries to work, and this is a nice fallback
    return { householdsLike: { hasClientProfiles: true } };
  }
}

function EntityImportTableEmbeddableInner<TData>({
  initialExpandedHouseholdIDs = [],
  fetchData,
  mapDataToTable,
  tableCaption,
  singleHouseholdId,
}: EntityImportTableInnerProps<TData>) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { flowType } = useBulkImportFlowContext();
  const { reset, control, getValues } = useFormContext<EntityImportTableForm>();
  const isSingleHouseholdMode = !!singleHouseholdId;

  const variables = getVariables(singleHouseholdId, flowType);
  const { loading } = fetchData({
    variables,
    // always pull latest from network, since the local cache
    // may have been made stale by potential earlier steps in the flow
    fetchPolicy: 'network-only',
    onError: (error) => {
      showFeedback(
        'Could not load entities for import.  Please refresh the page and try again.'
      );
      reportError(
        'Caught error while loading households and entities for import',
        error
      );
    },
    onCompleted: (data) => {
      reset({ ...getValues(), ...mapDataToTable(data) });
    },
  });

  const householdMap = useWatch<EntityImportTableForm>({
    control,
    name: `${NAMESPACE}.householdMap` as const satisfies PathsOf<EntityImportTableForm>,
  }) as EntityImportHouseholdMap;

  const householdIds = useMemo<string[]>(
    () => Object.keys(householdMap),
    [householdMap]
  );

  if (!loading && !householdIds.length) {
    return <EntityImportTableEmptyRows />;
  }

  return (
    <>
      {tableCaption}
      <DisplayTable
        columns={compact([
          { headerName: 'Clients + unlinked entities', flex: 1 },
          { headerName: 'Value', width: 150, align: 'right' },
          { headerName: 'Display name', flex: 1 },
          {
            headerName: 'Entity type',
            width: 225,
          },
          {
            headerName: 'Grantors, owners or donors',
            flex: 1,
          },
        ])}
      >
        {loading ? (
          <>
            <TableRowSkeleton columns={5} />
            <TableRowSkeleton columns={5} />
            <TableRowSkeleton columns={5} />
            <TableRowSkeleton columns={5} />
          </>
        ) : (
          compact(
            householdIds
              .filter((householdId) => {
                if (
                  // single house hold mode & matching -- display household
                  isSingleHouseholdMode &&
                  singleHouseholdId === householdId
                ) {
                  return true;
                } else if (!isSingleHouseholdMode) {
                  // not single household mode -- display household
                  return true;
                }
                // single household mode and not matching -- do not display
                return false;
              })
              .map((householdId) => (
                <EntityImportTableClientRow
                  key={householdId}
                  householdId={householdId}
                  isInitiallyExpanded={initialExpandedHouseholdIDs.includes(
                    householdId
                  )}
                  isSingleHouseholdMode={isSingleHouseholdMode}
                />
              ))
          )
        )}
      </DisplayTable>
    </>
  );
}

export interface EntityImportTableSubmitButtonInnerProps {
  householdId?: string;
}

function EntityImportTableSubmitButtonInner({
  householdId,
}: EntityImportTableSubmitButtonInnerProps) {
  const { assetClassesById } = useTenantDetailsContext();
  const defaultAssetClassId = useDefaultAssetClassId();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const navigate = useNavigate();
  const { flowType } = useBulkImportFlowContext();
  const {
    formState: { isSubmitting: formIsSubmitting },
    handleSubmit,
  } = useFormContext<EntityImportTableForm>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [importEntities, { loading: importEntitiesLoading }] =
    useEntityTableImportMutation({
      onCompleted: () => {
        showFeedback(
          'Selected entities were successfully linked into Luminary',
          { variant: 'success' }
        );
      },
      onError: (err) => {
        reportError('error importing entities', err);
        showFeedback(
          'There was an error importing entities.  Please try again.'
        );
      },
    });

  const onSubmit = handleSubmit((formData: EntityImportTableForm) => {
    const inputs: AugmentedCreateEntityInput[] = mapFormDataToMutation(
      formData,
      flowType,
      {
        defaultAssetClassId,
        assetClassesById,
      }
    );

    if (!inputs.length) {
      showFeedback('Please select at least one entity to import', {
        variant: 'info-high',
      });
      return;
    }

    return importEntities({
      variables: {
        inputs,
      },
      onCompleted: () => {
        showFeedback(
          'Selected entities were successfully linked into Luminary',
          { variant: 'success' }
        );
        setModalOpen(true);
      },
    });
  });

  return (
    <>
      <FormFieldsDisabledProvider
        isDisabled={formIsSubmitting || importEntitiesLoading}
      >
        <Button
          variant="primary"
          size="sm"
          onClick={onSubmit}
          loading={formIsSubmitting || importEntitiesLoading}
        >
          Import selected entities
        </Button>
      </FormFieldsDisabledProvider>
      <ConfirmationModal
        heading="Imported entities may require further data input"
        isOpen={modalOpen}
        onClose={() => {
          let path: string;
          if (householdId) {
            path = getCompletePathFromRouteKey(
              ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST,
              { householdId: householdId }
            );
          } else {
            path = getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLDS_LIST, {});
          }
          navigate(path);
        }}
        confirmText={householdId ? 'Go to entities' : 'Go to clients'}
        confirmationOnly
      >
        All available data from Addepar was migrated. Additional details tracked
        in Luminary may require further input.
      </ConfirmationModal>
    </>
  );
}

export function EntityImportTableSingleHouseholdSubmitButton({
  householdId,
}: EntityImportTableSubmitButtonInnerProps) {
  return <EntityImportTableSubmitButtonInner householdId={householdId} />;
}

export function EntityImportTableSubmitButton() {
  return <EntityImportTableSubmitButtonInner />;
}
