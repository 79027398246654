import { useMemo } from 'react';

import { SUPPORTED_AI_SUGGESTION_ENTITY_KINDS } from '@/modules/entities/entities.constants';
import { EntityType, isEntityType } from '@/modules/entities/types/EntityType';
import { getEntityKindFromEntityType } from '@/modules/entities/utils/getEntityKindFromEntityType';
import { useAICapabilitiesEnabled } from '@/modules/tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import { EntityKind } from '@/types/schema';

/**
 * Returns whether AI suggestions are enabled for the given entity type/kind.
 * Checks the relevant feature flag based on the entity type/kind,
 * and tenant-level AI enabled configs.
 *
 * @param entityTypeOrKind
 */
export function useAISuggestionsEnabled(
  entityTypeOrKind: EntityType | EntityKind | undefined | null | ''
) {
  const aiCapabilitiesEnabled = useAICapabilitiesEnabled();

  return useMemo(
    () => getIsAISuggestionsEnabled(entityTypeOrKind, aiCapabilitiesEnabled),
    [aiCapabilitiesEnabled, entityTypeOrKind]
  );
}

export function getIsAISuggestionsEnabled(
  entityTypeOrKind: EntityType | EntityKind | undefined | null | '',
  aiCapabilitiesEnabled: boolean
): boolean {
  if (!entityTypeOrKind || !aiCapabilitiesEnabled) {
    return false;
  }

  const inputKind = isEntityType(entityTypeOrKind)
    ? getEntityKindFromEntityType(entityTypeOrKind)
    : entityTypeOrKind;

  return SUPPORTED_AI_SUGGESTION_ENTITY_KINDS.includes(inputKind);
}
