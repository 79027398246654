import { noop } from 'lodash';
import { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

import { MarkerType, MarkerTypeToProps, Node } from '../types';

export interface MarkerConfig<T extends MarkerType> {
  type: T;
  props: MarkerTypeToProps[T];
}

export type FlowChartBounds = Omit<DOMRect, 'toJSON'>;

export interface FlowChartContext {
  isDirty: boolean;
  flowBounds: FlowChartBounds;
  dropTargetNode: Node | null;
  __internal: {
    setDropTargetNode: Dispatch<
      SetStateAction<FlowChartContext['dropTargetNode']>
    >;
    setIsDirty: Dispatch<SetStateAction<FlowChartContext['isDirty']>>;
    setMarkers: Dispatch<
      SetStateAction<FlowChartContext['__internal']['markers']>
    >;
    markers: Record<string, MarkerConfig<MarkerType>>;
  };
}

export const FlowChartContext = createContext<FlowChartContext>({
  isDirty: false,
  dropTargetNode: null,
  flowBounds: {
    x: 0,
    y: 0,
    top: 0,
    width: 0,
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
  },
  __internal: {
    markers: {},
    setMarkers: noop,
    setIsDirty: () => false,
    setDropTargetNode: noop,
  },
});

export const useFlowChartContext = () => {
  return useGuardedContext(FlowChartContext, 'FlowChartProvider');
};
