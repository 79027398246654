import { DEFAULT_ASSUMPTIONS } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/constants';
import { EstateWaterfallAssumptions_EstateWaterfallFragment } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/graphql/EstateWaterfallAssumptions.generated';
import { EstateWaterfallAssumptionFields } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/types';

/**
 * Takes in an existing waterfall object from the API and maps it to EstateWaterfallAssumptionFields type,
 * for consumption by EstateWaterfallAssumptionFormFields.
 *
 * If no waterfall is provided, it will return the default assumptions.
 *
 * @param waterfall
 */
export const mapWaterfallToAssumptionFields = (
  waterfall:
    | EstateWaterfallAssumptions_EstateWaterfallFragment
    | undefined
    | null
): EstateWaterfallAssumptionFields => ({
  firstGrantorDeathYear:
    waterfall?.firstGrantorDeathYear ||
    DEFAULT_ASSUMPTIONS.firstGrantorDeathYear,
  secondGrantorDeathYear: waterfall?.isTwoGrantor
    ? waterfall?.secondGrantorDeathYear ||
      DEFAULT_ASSUMPTIONS.secondGrantorDeathYear
    : undefined,
  assetGrowthReturn:
    waterfall?.assetGrowthReturn || DEFAULT_ASSUMPTIONS.assetGrowthReturn,
  useCustomGlobalGrowthRate: Boolean(waterfall?.assetGrowthReturn),
  exemptionGrowthRate:
    waterfall?.exemptionGrowthRate || DEFAULT_ASSUMPTIONS.exemptionGrowthRate,
  willExemptionSunset:
    waterfall?.willExemptionSunset === undefined ||
    waterfall?.willExemptionSunset === null
      ? DEFAULT_ASSUMPTIONS.willExemptionSunset
      : waterfall?.willExemptionSunset,
});
