import { Box, Stack, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

export interface SubtleLabelItemProps {
  label: string;
  value: React.ReactNode;
  valueSecondary?: React.ReactNode;
  invertLabel?: boolean;
  action?: JSX.Element | null;
  sx?: SxProps<Theme>;
}

export function SubtleLabelItem({
  label,
  value,
  valueSecondary,
  action,
  invertLabel,
  sx,
}: SubtleLabelItemProps) {
  return (
    <Stack direction="row" sx={sx}>
      <Stack direction={invertLabel ? 'column-reverse' : 'column'}>
        <Typography variant="subtitle2">{label}</Typography>
        <Stack direction="row" spacing={0.5}>
          <Typography variant="h4" component="div">
            {value}
          </Typography>
          {valueSecondary && (
            <Typography
              variant="h4"
              component="div"
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightRegular,
              })}
            >
              {valueSecondary}
            </Typography>
          )}
        </Stack>
      </Stack>
      {action && <Box ml={1}>{action}</Box>}
    </Stack>
  );
}
