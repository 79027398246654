import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { WithClasses } from '@/styles/types';

const useStyles = makeStyles()((_theme) => ({
  root: {},
  headingWrapper: {},
  headingText: {},
  actionsWrapper: {},
  childrenWrapper: {},
}));

export interface SubpageLayoutProps {
  heading: React.ReactNode;
  actions?: React.ReactNode;
  classes?: WithClasses<typeof useStyles>;
}

export function SubpageLayout({
  heading,
  actions,
  children,
  ...props
}: React.PropsWithChildren<SubpageLayoutProps>) {
  const { classes } = useStyles(undefined, { props });
  return (
    <Stack spacing={3} height="100%" className={classes.root}>
      {(heading || actions) && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className={classes.headingWrapper}
        >
          <Typography
            variant="h1"
            component="h2"
            className={classes.headingText}
          >
            {heading}
          </Typography>
          <Box className={classes.actionsWrapper}>{actions && actions}</Box>
        </Stack>
      )}
      <Stack className={classes.childrenWrapper}>{children}</Stack>
    </Stack>
  );
}
