import { EntityGraphNodeAttributes, SupportedSubtypeTypename } from '../types';

export const entitySubtypeToNodeCategorization: Record<
  SupportedSubtypeTypename,
  EntityGraphNodeAttributes['categorizationType']
> = {
  GRATTrust: 'FAMILY_GIVING',
  ILITTrust: 'FAMILY_GIVING',
  IrrevocableTrust: 'FAMILY_GIVING',
  RevocableTrust: 'FAMILY_GIVING',
  QPRTTrust: 'FAMILY_GIVING',
  SLATTrust: 'FAMILY_GIVING',
  RetirementPersonalAccount: 'PERSONAL_ACCOUNT',
  QualifiedTuitionPersonalAccount: 'PERSONAL_ACCOUNT',
  CustodialPersonalAccount: 'PERSONAL_ACCOUNT',
  IndividualPersonalAccount: 'PERSONAL_ACCOUNT',
  InsurancePersonalAccount: 'PERSONAL_ACCOUNT',
  JointPersonalAccount: 'PERSONAL_ACCOUNT',
  DonorAdvisedFund: 'CHARITABLE_ENTITY',
  PrivateFoundation: 'CHARITABLE_ENTITY',
  CLTTrust: 'CHARITABLE_ENTITY',
  CRTTrust: 'CHARITABLE_ENTITY',
  SoleProprietorshipBusinessEntity: 'BUSINESS_ENTITY',
  SCorpBusinessEntity: 'BUSINESS_ENTITY',
  LPBusinessEntity: 'BUSINESS_ENTITY',
  LLCBusinessEntity: 'BUSINESS_ENTITY',
  GPBusinessEntity: 'BUSINESS_ENTITY',
  CCorpBusinessEntity: 'BUSINESS_ENTITY',
};
