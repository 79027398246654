import { Box, Stack } from '@mui/material';
import { FormProvider, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { appbarHeight } from '@/components/architecture/Layout/AppBar/AppBar';
import { Button } from '@/components/form/baseInputs/Button';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { PageHeader } from '@/components/navigation/PageHeader';
import { StepTracker } from '@/components/navigation/StepTracker/StepTracker';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import {
  BulkImportFlowContextProvider,
  BulkImportFlowType,
  getBulkImportFlow,
} from '@/modules/import/BulkImportFlowContext';
import { NAMESPACE as ENTITY_IMPORT_NAMESPACE } from '@/modules/import/EntityImportTable.types';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import {
  BULK_IMPORT_STEP_CLIENT_DETAILS,
  BULK_IMPORT_STEP_CLIENT_SELECTION,
  BULK_IMPORT_STEP_ENTITY_DETAILS,
} from './BulkImportPage.constants';
import {
  BulkImportForm,
  BulkImportStep,
  BulkImportStepConfig,
} from './BulkImportPage.types';
import { BulkImportClientDetailsStepConfig } from './clientDetails/BulkImportClientDetails';
import { ClientImportSelectionStepConfig } from './clientSelection/BulkClientImportSelectionStep';
import { BulkImportEntityStepConfig } from './entityDetails/BulkImportEntityStep';

const STEP_CONFIG: Record<BulkImportStep, BulkImportStepConfig> = {
  [BULK_IMPORT_STEP_CLIENT_SELECTION]: ClientImportSelectionStepConfig,
  [BULK_IMPORT_STEP_CLIENT_DETAILS]: BulkImportClientDetailsStepConfig,
  [BULK_IMPORT_STEP_ENTITY_DETAILS]: BulkImportEntityStepConfig,
};

function BulkImportInner() {
  const navigate = useNavigate();
  const { control, setValue } = useFormContext<BulkImportForm>();

  const currentStep = useWatch<BulkImportForm>({
    name: 'step',
    control,
    exact: true,
  }) as BulkImportStep;

  const {
    Component: FormStepComponent,
    NextButton,
    PreviousButton,
  } = STEP_CONFIG[currentStep];
  return (
    <>
      <Stack
        sx={{
          height: `calc(100vh - ${FOOTER_HEIGHT}px - ${appbarHeight})`,
          overflow: 'scroll',
        }}
      >
        <PageHeader
          heading="Import new clients"
          action={
            <StepTracker
              segments={[
                {
                  display: 'Select individuals',
                  isComplete: true,
                  onClick: () => {
                    setValue('step', BULK_IMPORT_STEP_CLIENT_SELECTION);
                  },
                },
                {
                  display: 'Import as new clients',
                  isComplete:
                    currentStep === BULK_IMPORT_STEP_CLIENT_DETAILS ||
                    currentStep === BULK_IMPORT_STEP_ENTITY_DETAILS,
                },
                {
                  display: 'Import entities',
                  isComplete: currentStep === BULK_IMPORT_STEP_ENTITY_DETAILS,

                  onClick: () => {
                    setValue('step', BULK_IMPORT_STEP_ENTITY_DETAILS);
                    setValue('selectedIntegrationClientIds', []);
                  },
                },
              ]}
              activeSegmentIndex={0}
            />
          }
        />
        <Box sx={{ p: 3 }}>
          <FormStepComponent />
        </Box>
      </Stack>
      <Box>
        <Footer
          leftAction={
            <Button
              variant="secondary"
              size="sm"
              onClick={() =>
                navigate(
                  getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLDS_LIST, {})
                )
              }
            >
              Exit import
            </Button>
          }
          rightAction={
            <>
              <PreviousButton /> <NextButton />
            </>
          }
        />
      </Box>
    </>
  );
}

export function BulkImportPage() {
  const flowType: BulkImportFlowType = getBulkImportFlow(
    useRequiredParam('flowType')
  );
  const formMethods = useForm<BulkImportForm>({
    defaultValues: {
      step: BULK_IMPORT_STEP_CLIENT_SELECTION,
      householdMap: {},
      selectedIntegrationClientIds: [],
      [ENTITY_IMPORT_NAMESPACE]: {
        householdMap: {},
      },
    },
  });

  return (
    <BulkImportFlowContextProvider value={{ flowType }}>
      <FormProvider {...formMethods}>
        <FormFieldsDisabledProvider
          isDisabled={
            formMethods.formState
              .isSubmitting /* common for the combined forms of the import flow -- if one is saving/loading, all buttons should respect it */
          }
        >
          <BulkImportInner />
        </FormFieldsDisabledProvider>
      </FormProvider>
    </BulkImportFlowContextProvider>
  );
}
