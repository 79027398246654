import { SubForm } from '@/types/subform';

import {
  defaultValues,
  Fields,
  INSURANCE_POLICIES_DETAILS_SUBFORM,
  InsurancePolicyDetailsSubformType,
  NamespaceType,
  Props,
  VariantType,
} from './InsurancePolicyDetailsSubform.types';
import { UI } from './InsurancePolicyDetailsSubform.UI';

export class InsurancePolicyDetailsSubform
  implements
    SubForm<
      InsurancePolicyDetailsSubformType,
      Fields,
      NamespaceType,
      VariantType,
      object
    >
{
  FormComponent: (props: Props) => JSX.Element;
  defaultValues: InsurancePolicyDetailsSubformType;
  namespace: NamespaceType;

  constructor() {
    this.FormComponent = UI;
    this.defaultValues = defaultValues;
    this.namespace = INSURANCE_POLICIES_DETAILS_SUBFORM;
  }
}
