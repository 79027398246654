import {
  ButtonGroupInputOption,
  SelectInputOption,
} from '@/components/form/baseInputs/inputTypes';
import { FormAwareRadioGroupProps } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import {
  CharitableTrustCalculationMethod,
  CharitableTrustOptimizationTarget,
  CharitableTrustPayoutFrequency,
  CharitableTrustPayoutKind,
  CharitableTrustTermKind,
  CharitableTrustUnitrustKind,
} from '@/types/schema';

import {
  PAYOUT_FREQUENCY_COPY,
  PAYOUT_KIND_COPY,
} from '../CharitableTrustDesigner.copy';
import { CharitableTrustDesignerAnalysisForm } from './CharitableTrustDesignerAnalysis.types';

export const TERM_KIND_OPTIONS: FormAwareRadioGroupProps<CharitableTrustDesignerAnalysisForm>['options'] =
  [
    {
      label: 'Fixed',
      value: CharitableTrustTermKind.Fixed,
    },
    {
      label: 'Lifetime',
      value: CharitableTrustTermKind.Lifetime,
    },
  ];

export const PAYOUT_KIND_OPTIONS: FormAwareRadioGroupProps<CharitableTrustDesignerAnalysisForm>['options'] =
  [
    {
      value: CharitableTrustPayoutKind.Annuity,
      label: PAYOUT_KIND_COPY[CharitableTrustPayoutKind.Annuity],
    },
    {
      value: CharitableTrustPayoutKind.Unitrust,
      label: PAYOUT_KIND_COPY[CharitableTrustPayoutKind.Unitrust],
    },
  ];

export const UNITRUST_KIND_OPTIONS: SelectInputOption[] = [
  {
    display: 'Standard',
    value: CharitableTrustUnitrustKind.Standard,
  },
  {
    display: 'Net income (NI)',
    value: CharitableTrustUnitrustKind.NetIncome,
  },
  {
    display: 'Net income with makeup (NIM)',
    value: CharitableTrustUnitrustKind.NetIncomeWithMakeup,
  },
  {
    display: 'Flip',
    value: CharitableTrustUnitrustKind.Flip,
  },
];

export const CALCULATION_METHOD_OPTIONS: ButtonGroupInputOption[] = [
  { display: 'Calculated', value: CharitableTrustCalculationMethod.Calculated },
  { display: 'Manual', value: CharitableTrustCalculationMethod.Manual },
];

export const CLT_OPTIMIZATION_TARGET_OPTIONS: FormAwareRadioGroupProps<CharitableTrustDesignerAnalysisForm>['options'] =
  [
    {
      label: 'Wealth transfer',
      value: CharitableTrustOptimizationTarget.NonCharitable,
    },
    {
      label: 'Charitable deduction',
      value: CharitableTrustOptimizationTarget.Charitable,
    },
  ];

export const CRT_OPTIMIZATION_TARGET_OPTIONS: FormAwareRadioGroupProps<CharitableTrustDesignerAnalysisForm>['options'] =
  [
    {
      label: 'Income',
      value: CharitableTrustOptimizationTarget.NonCharitable,
    },
    {
      label: 'Charitable deduction',
      value: CharitableTrustOptimizationTarget.Charitable,
    },
  ];

export const PAYOUT_FREQUENCY_OPTIONS: SelectInputOption[] = [
  {
    display: PAYOUT_FREQUENCY_COPY[CharitableTrustPayoutFrequency.Annually],
    value: CharitableTrustPayoutFrequency.Annually,
  },
  {
    display: PAYOUT_FREQUENCY_COPY[CharitableTrustPayoutFrequency.Semiannually],
    value: CharitableTrustPayoutFrequency.Semiannually,
  },
  {
    display: PAYOUT_FREQUENCY_COPY[CharitableTrustPayoutFrequency.Quarterly],
    value: CharitableTrustPayoutFrequency.Quarterly,
  },
  {
    display: PAYOUT_FREQUENCY_COPY[CharitableTrustPayoutFrequency.Monthly],
    value: CharitableTrustPayoutFrequency.Monthly,
  },
];
