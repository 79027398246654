import { compose } from '@/utils/reducerUtils';

import { applyDerivedDataToState, applyHighlightIdsToState } from '../state';
import { EntityMapReducer } from '../types';

export const entityMapStateTransformReducer: EntityMapReducer = (
  state,
  _action,
  _queueEffect
) => {
  return compose(applyHighlightIdsToState, applyDerivedDataToState)(state);
};
