import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import {
  FullScreenModal,
  FullScreenModalProps,
} from '@/components/modals/FullScreenModal/FullScreenModal';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { DEFAULT_ASSUMPTIONS } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/constants';
import { COLORS } from '@/styles/tokens/colors';
import { getNodes } from '@/utils/graphqlUtils';

import { useNavigateToWaterfall } from '../../hooks/useNavigateToWaterfall';
import { mapInputToFormData } from './CreateEstateWaterfallModal.utils';
import {
  EstateWaterfallForm,
  EstateWaterfallFormProps,
} from './EstateWaterfallForm';
import { EstateWaterfallTrowserLayout } from './EstateWaterfallTrowserLayout';
import { useFiltersForEstateWaterfallQuery } from './graphql/EstateWaterfallModal.generated';
import { useCreateEstateWaterfall } from './hooks/useCreateEstateWaterfall';
import { useWaterfallModalQuery } from './hooks/useWaterfallModalQuery';
import { EstateWaterfallFormData } from './types';

interface CreateEstateWaterfallProps extends FullScreenModalProps {
  householdId: string;
  grantors: EstateWaterfallFormProps['grantors'];
  isTwoGrantor?: boolean;
  sourceEstateWaterfalls?: EstateWaterfallFormProps['sourceEstateWaterfalls'];
  defaultHypothetical?: boolean;
  defaultSelectedRowIds?: string[];
}

export type CreateEstateWaterfallTrowserProps = CreateEstateWaterfallProps;

function CreateEstateWaterfall({
  householdId,
  grantors,
  isTwoGrantor = false,
  sourceEstateWaterfalls,
  defaultHypothetical: _dH,
  defaultSelectedRowIds,
  ...fullScreenModalProps
}: CreateEstateWaterfallProps) {
  const { onClose } = fullScreenModalProps;
  const trackUserEvent = useTrackUserEvent();

  const navigateToWaterfall = useNavigateToWaterfall();
  const { formState, handleSubmit, reset } =
    useFormContext<EstateWaterfallFormData>();

  const { numWaterfalls, entities, hypotheticalTransfers, loading } =
    useWaterfallModalQuery({
      householdId,
    });

  const {
    createWaterfall,
    createEstateWaterfallData,
    duplicateEstateWaterfallData,
  } = useCreateEstateWaterfall({ isTwoGrantor, sourceEstateWaterfalls });

  const onSubmit = handleSubmit(async (formData) => {
    await createWaterfall(formData);
    trackUserEvent('waterfall created');
  });

  const closeModal = () => {
    reset();
    onClose?.();
  };

  useSubmitSuccessHandler(() => {
    const waterfallId =
      createEstateWaterfallData?.createEstateWaterfall.id ??
      duplicateEstateWaterfallData?.duplicateEstateWaterfall.id;
    if (waterfallId) {
      navigateToWaterfall({ householdId, waterfallId });
    }
    closeModal();
  });

  return (
    <FullScreenModal
      backdropSx={{ bgcolor: COLORS.GRAY[50] }}
      {...fullScreenModalProps}
    >
      <Stack
        component="form"
        onSubmit={onSubmit}
        height="100%"
        noValidate
        pb={`${FOOTER_HEIGHT}px`}
      >
        <EstateWaterfallTrowserLayout>
          <Typography variant="h1" component="h2">
            Create a new waterfall
          </Typography>
          <EstateWaterfallForm
            grantors={grantors}
            sourceEstateWaterfalls={sourceEstateWaterfalls}
            shouldShowHypotheticalToggle={numWaterfalls > 0}
            loading={loading}
            entities={entities}
            hypotheticalTransfers={hypotheticalTransfers}
            defaultSelectedRowIds={defaultSelectedRowIds}
            shouldShowAutoGroupToggle={true}
          />
        </EstateWaterfallTrowserLayout>
        <Box flexShrink={0}>
          <Footer
            rightAction={
              <Stack direction="row" spacing={2}>
                <Button
                  variant="secondary"
                  size="sm"
                  loading={formState.isSubmitting}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="sm"
                  loading={formState.isSubmitting}
                >
                  Create waterfall
                </Button>
              </Stack>
            }
          />
        </Box>
      </Stack>
    </FullScreenModal>
  );
}

export const CreateEstateWaterfallTrowser = ({
  householdId,
  grantors,
  defaultHypothetical,
  ...props
}: CreateEstateWaterfallTrowserProps) => {
  const formMethods = useForm<EstateWaterfallFormData>({
    defaultValues: mapInputToFormData({
      householdId,
      grantors,
      isHypothetical: defaultHypothetical,
      shouldAutoGroup: true,
      ...DEFAULT_ASSUMPTIONS,
    }),
  });

  const { control } = formMethods;

  const selectedSourceWaterfallId = useWatch({
    control,
    name: 'parentID' as const satisfies keyof EstateWaterfallFormData,
  });

  const { data: filtersForWaterfall } = useFiltersForEstateWaterfallQuery({
    fetchPolicy: 'cache-and-network',
    skip: !selectedSourceWaterfallId,
    variables: {
      where: {
        id: selectedSourceWaterfallId,
      },
    },
  });

  const defaultSelectedRowIds = useMemo(() => {
    if (!filtersForWaterfall) {
      return undefined;
    }

    const estateWaterfall = getNodes(filtersForWaterfall.estateWaterfalls)[0];
    const waterfallFilter = estateWaterfall?.filter;
    const includedClientIds = getNodes(waterfallFilter?.includedClients).map(
      (c) => c.id
    );
    const includedEntityIDs = getNodes(waterfallFilter?.includedEntities).map(
      (e) => e.id
    );

    const selectedIds = [
      ...(includedClientIds ?? []),
      ...(includedEntityIDs ?? []),
    ];

    if (selectedIds.length === 0) {
      return undefined;
    }

    return selectedIds;
  }, [filtersForWaterfall]);

  return (
    <FormProvider {...formMethods}>
      <CreateEstateWaterfall
        householdId={householdId}
        grantors={grantors}
        isTwoGrantor={grantors.length === 2}
        defaultSelectedRowIds={defaultSelectedRowIds}
        {...props}
      />
    </FormProvider>
  );
};
