import { noop } from 'lodash';
import { createContext } from 'react';

export const ILITTaskContext = createContext<{
  householdId: string;
  entityId: string;
  taskId: string;
  onClose: () => void;
  onCompleteTask: () => void;
}>({
  householdId: '',
  entityId: '',
  taskId: '',
  onClose: noop,
  onCompleteTask: noop,
});
