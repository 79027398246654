import { ListItemIcon } from '@mui/material';
import { compact } from 'lodash';
import { useMemo } from 'react';

import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { User01Icon } from '@/components/icons/User01Icon';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { BUSINESS_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { getEntityTypeFromSubtype } from '@/modules/entities/utils/getEntityTypeFromSubtype';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { EntityStage } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { useDispositiveProvisionsContext } from '../../contexts/dispositiveProvisions.context';
import { DispositionScheme } from '../../dispositiveProvisions.types';
import { useDispositiveProvisionsTemplateSplitScreenModalContext } from '../../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.context';
import { DispositiveProvisionsTemplateRules } from '../DispositiveProvisionsFormRecipients/DispositiveProvisionsRecipient.constants';
import { DispositiveProvisionsRecipientTemplateRecipientConfiguration } from '../DispositiveProvisionsFormRecipients/DispositiveProvisionsRecipient.types';
import {
  DispositiveProvisionsPossibleRecipients_EntityFragment,
  useDispositiveProvisionsPossibleRecipientsQuery,
} from '../DispositiveProvisionsFormRecipients/graphql/DispositiveProvisionsFormRecipients.generated';
import { useDispositionScheme, useFirstClientDeathId } from './utilityHooks';

export const SURVIVING_SPOUSE_SENTINEL = 'surviving-spouse' as const;

interface GetEntityRecipientOptionsInput {
  entities: DispositiveProvisionsPossibleRecipients_EntityFragment[];
  includeStages: EntityStage[];
  excludeEntities?: string[];
  customEntityFilter?: DispositiveProvisionsRecipientTemplateRecipientConfiguration['customEntityFilter'];
}

function getEntityRecipientOptions({
  entities,
  includeStages,
  excludeEntities = [],
  customEntityFilter,
}: GetEntityRecipientOptionsInput) {
  return entities
    .filter((e) => includeStages.includes(e.stage))
    .filter((e) => {
      if (!e.subtype.__typename) {
        return false;
      }
      if (customEntityFilter) {
        return customEntityFilter(e);
      }
      // Do not include business entities in the list of possible recipients
      return !BUSINESS_ENTITY_TYPES.includes(
        getEntityTypeFromSubtype(e.subtype.__typename)
      );
    })
    .map((e) => ({
      display: e.subtype.displayName,
      value: e.id,
    }))
    .filter((e) => !excludeEntities.includes(e.value));
}

export function useRecipientOptionsParams(): UseRecipientOptionsParams {
  const { allClientIds, isTestamentaryEntity } =
    useDispositiveProvisionsContext();
  const dispositionScheme = useDispositionScheme();
  const dyingPrimaryClientId = useFirstClientDeathId();

  const deadPrimaryClientIds = useMemo(() => {
    if (isTestamentaryEntity) return allClientIds;
    if (dispositionScheme !== DispositionScheme.UPON_SECOND_DEATH) {
      return compact([dyingPrimaryClientId]);
    }
    return allClientIds;
  }, [
    isTestamentaryEntity,
    allClientIds,
    dispositionScheme,
    dyingPrimaryClientId,
  ]);

  return {
    deadPrimaryClientIds,
  };
}

function sortDisabledToEnd(
  a: { disabled: boolean; display: string },
  b: { disabled: boolean; display: string }
) {
  if (a.disabled && !b.disabled) return 1;
  if (!a.disabled && b.disabled) return -1;
  return a.display.localeCompare(b.display);
}

interface UseRecipientOptionsParams {
  deadPrimaryClientIds: string[];
}

export function useRecipientOptions(
  { deadPrimaryClientIds }: UseRecipientOptionsParams,
  recipientConfiguration: DispositiveProvisionsRecipientTemplateRecipientConfiguration = DispositiveProvisionsTemplateRules
    .NO_TEMPLATE.recipientConfiguration
) {
  const { householdId, isTwoClientHousehold } = useHouseholdDetailsContext();
  const { entityId } = useEntityDetailsContext();
  const { isOnHypotheticalWaterfall } = useDispositiveProvisionsContext();
  const { isTemplateMode } =
    useDispositiveProvisionsTemplateSplitScreenModalContext();

  const { data: clientData } = useDispositiveProvisionsPossibleRecipientsQuery({
    variables: {
      where: { id: householdId },
      // the hasDeadGrantors var is used to include testamentary entities in the query
      // response; since templates can always have testamentary entities as recipients,
      // and because they aren't associated with a specific death, they should always be
      // included in the query response
      hasDeadGrantors: deadPrimaryClientIds.length > 0 || isTemplateMode,
    },
  });

  const household = getNodes(clientData?.households)[0];

  return useMemo(() => {
    if (!household) {
      return {
        clientProfileRecipients: [],
        entityRecipients: [],
        organizationRecipients: [],
        testamentaryEntityRecipients: [],
        options: [],
      };
    }

    const {
      allowsIndividualRecipient: templateAllowsIndividuals,
      allowsOrganizationRecipient: templateAllowsOrganizations,
      allowsEntityRecipient: templateAllowsEntities,
      allowsTestamentaryEntityRecipient: templateAllowsTestamentaryEntities,
      allowsToSurvivingSpouse: templateAllowsSurvivingSpouse,
      customIndividualFilter,
      customEntityFilter,
    } = recipientConfiguration;

    // CLIENT PROFILES
    const possibleClientProfiles = household.possibleBeneficiariesV2.clients;
    const clientProfileOptions = templateAllowsIndividuals
      ? possibleClientProfiles
          .filter(customIndividualFilter ? customIndividualFilter : () => true)
          .map((c) => {
            const hasDeceased = deadPrimaryClientIds.includes(c.id);
            return {
              display: compact([c.legalName, hasDeceased && '(deceased)']).join(
                ' '
              ),
              value: c.id,
              disabled: hasDeceased,
            };
          })
          .sort(sortDisabledToEnd)
      : [];

    // TESTAMENTARY ENTITIES
    const testamentaryEntityRecipients = templateAllowsTestamentaryEntities
      ? getNodes(household.testamentaryEntities)
      : [];
    const testamentaryEntityRecipientOptions = testamentaryEntityRecipients.map(
      ({ displayName: display, id: value }) => ({ display, value })
    );

    // ORGANIZATIONS
    const organizationRecipients = templateAllowsOrganizations
      ? household.possibleBeneficiariesV2.organizations
      : [];
    const organizationRecipientOptions = organizationRecipients
      .map((c) => ({
        display: c.name,
        value: c.id,
      }))
      .sort((a, b) => a.display.localeCompare(b.display));

    const entityRecipients = household.possibleBeneficiariesV2.entities;
    const entityRecipientOptions = templateAllowsEntities
      ? getEntityRecipientOptions({
          entities: household.possibleBeneficiariesV2.entities,
          includeStages: [EntityStage.Active, EntityStage.Completed],
          excludeEntities: entityId ? [entityId] : undefined,
          customEntityFilter,
        }).sort((a, b) => a.display.localeCompare(b.display))
      : [];

    const draftEntityRecipientOptions = (() => {
      if (!isOnHypotheticalWaterfall) {
        return [];
      }

      return templateAllowsEntities
        ? getEntityRecipientOptions({
            entities: household.possibleBeneficiariesV2.entities,
            includeStages: [EntityStage.Draft],
            excludeEntities: entityId ? [entityId] : undefined,
            customEntityFilter,
          }).sort((a, b) => a.display.localeCompare(b.display))
        : [];
    })();

    let allowSurvivingSpouseOption = true;

    if (!isTwoClientHousehold) {
      allowSurvivingSpouseOption = false;
    } else if (isTemplateMode) {
      allowSurvivingSpouseOption =
        !!templateAllowsSurvivingSpouse && !!templateAllowsIndividuals;
    }

    const options = compact([
      (() =>
        allowSurvivingSpouseOption
          ? {
              display: 'Surviving Spouse',
              startAdornment: (
                <ListItemIcon>
                  <User01Icon size={16} />
                </ListItemIcon>
              ),
              value: SURVIVING_SPOUSE_SENTINEL,
            }
          : null)(),
      (() =>
        clientProfileOptions.length
          ? {
              component: <SelectItemGroupLabel label="Individuals" />,
              type: 'component' as const,
            }
          : null)(),
      ...clientProfileOptions,
      (() =>
        organizationRecipientOptions.length
          ? {
              component: <SelectItemGroupLabel label="Organizations" />,
              type: 'component' as const,
            }
          : null)(),
      ...organizationRecipientOptions,
      (() =>
        testamentaryEntityRecipientOptions.length
          ? {
              component: <SelectItemGroupLabel label="Testamentary entities" />,
              type: 'component' as const,
            }
          : null)(),
      ...testamentaryEntityRecipientOptions,
      (() =>
        entityRecipientOptions.length
          ? {
              component: <SelectItemGroupLabel label="Entities" />,
              type: 'component' as const,
            }
          : null)(),

      ...entityRecipientOptions,
      (() =>
        draftEntityRecipientOptions.length
          ? {
              component: <SelectItemGroupLabel label="Draft Entities" />,
              type: 'component' as const,
            }
          : null)(),

      ...draftEntityRecipientOptions,
    ]);

    return {
      options,
      entityRecipients,
      testamentaryEntityRecipients,
      organizationRecipients,
      clientProfileRecipients: possibleClientProfiles,
    };
  }, [
    household,
    recipientConfiguration,
    entityId,
    isTwoClientHousehold,
    isTemplateMode,
    deadPrimaryClientIds,
    isOnHypotheticalWaterfall,
  ]);
}
