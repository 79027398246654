import { FieldError } from 'react-hook-form';

import { GRATStructuringForm, tabConfigurations, TabName } from './constants';

function getFirstFieldErrorForTab(
  tabErrors: Record<keyof GRATStructuringForm, FieldError>
): {
  fieldName: keyof GRATStructuringForm;
  isRootError: boolean;
  errorMessage?: string;
} | null {
  if (!tabErrors) return null;
  const subFieldNames = Object.keys(tabErrors) as (keyof GRATStructuringForm)[];
  for (const fieldName of subFieldNames) {
    // the default path for non-fieldArray inputs: there's a ref at the top level.
    const fieldError = tabErrors[fieldName];
    if (fieldError?.ref) {
      return { fieldName, isRootError: false };
    } else if (Array.isArray(fieldError)) {
      // with arrays, there's potentially an array of errors. for this particular form,
      // we care about the "root" error, which is exposed when there are no validation
      // errors for the underlying array elements, but rather with the *absence* of array elements.
      if (fieldError.root) {
        return {
          fieldName,
          isRootError: true,
          errorMessage: fieldError.root.message,
        };
      }
    }
  }

  return null;
}

export const getFirstError = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- legacy linter refactor
  formErrors: any,
  currentTab: TabName
): {
  fieldName: keyof GRATStructuringForm;
  isRootError: boolean;
  errorMessage?: string;
  tabName: TabName;
} | null => {
  // first, focus on errors in the current tab if there are any to avoid shifting the user around.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- linter refactor, formErrors is typed as any
  if (formErrors[currentTab]) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument -- linter refactor, formErrors is typed as any
    const result = getFirstFieldErrorForTab(formErrors[currentTab]);
    if (result) {
      return {
        tabName: currentTab,
        ...result,
      };
    }
  }

  // if we didn't return early after finding errors in the current tab, let's iterate through all the tabs
  // to find any errors.
  for (const tab of tabConfigurations) {
    const tabName = tab.value;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument -- linter refactor, formErrors is typed as any
    const result = getFirstFieldErrorForTab(formErrors[tabName]);

    if (result) {
      return {
        tabName,
        ...result,
      };
    }
  }

  return null;
};
