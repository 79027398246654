import { saveAs } from 'file-saver';
import HTMLtoDOCX from 'html-to-docx';
import { compact } from 'lodash';
import { marked } from 'marked';

import { diagnostics } from '@/utils/diagnostics';

/**
 * Extracts the title from the first h1 tag in the HTML content.
 * @param html The HTML content to extract the title from.
 * @returns The title from the first h1 tag or null if no h1 tag is found.
 */
function extractSummaryTitle(html: string): string | undefined {
  const match = html.match(/<h1[^>]*>(.*?)<\/h1>/);
  return match ? match[1] : undefined;
}

/**
 * Preprocesses the Markdown content to adjust spaces for list items but preserve nested structures.
 * /^(\s+)(- .+)/gm: Matches lines with leading spaces followed by a list item.
 * (\s+): Captures leading spaces.
 * (- .+): Captures the list item.
 * @param markdown The Markdown content to preprocess.
 * @returns The preprocessed Markdown content.
 */
function preprocessMarkdown(markdown: string): string {
  return markdown.replace(
    /^(\s+)(- .+)/gm,
    (_, spaces: string, item: string) => {
      const level = Math.floor(spaces.length / 4); // Our prompt uses 4 spaces for each level of nesting
      return '  '.repeat(level) + item.trim();
    }
  );
}

/**
 * Converts Markdown content to a Word document and triggers a download.
 * @param markdownContent The Markdown content to convert.
 */
export const convertMarkdownToWord = async (
  markdownContent: string
): Promise<void> => {
  try {
    const markdown = preprocessMarkdown(markdownContent);
    const htmlContent = await marked(markdown, {
      pedantic: false,
      gfm: true,
    });

    const summaryTitle = compact([
      extractSummaryTitle(htmlContent),
      'Summary',
    ]).join(' ');

    const htmlContentWithWordStructure = `<!DOCTYPE html><html lang="en"><head><meta charset="UTF-8" /><title>${summaryTitle}</title></head><body>${htmlContent}</body></html>`;

    const blob = await HTMLtoDOCX(htmlContentWithWordStructure, null, {
      orientation: 'portrait',
      title: summaryTitle,
      font: 'Calibri',
      margins: {
        top: 1000,
        right: 1400,
        bottom: 1000,
        left: 1400,
        header: 720,
        footer: 720,
        gutter: 0,
      },
      creator: 'Luminary',
      footer: false,
      header: false,
    });

    // Ensure blob is of type Blob
    const blobToSave = blob instanceof Blob ? blob : new Blob([blob]);

    // Trigger file download
    saveAs(blobToSave, `${summaryTitle}.docx`);
  } catch (error) {
    diagnostics.error(
      `Error creating Word document for document summary`,
      error as Error
    );
  }
};
