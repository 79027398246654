import { ApolloError } from '@apollo/client';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';

import { CircleCheckbox } from '@/components/form/baseInputs/Checkbox/CircleCheckbox';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { RefreshCw05Icon } from '@/components/icons/RefreshCw05Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useIsElementHovered } from '@/hooks/useIsElementHovered';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { COLORS } from '@/styles/tokens/colors';

import { useUpdateHouseholdOnboardingStepMutation } from './graphql/UpdateHouseholdOnboarding.generated';
import {
  HOUSEHOLD_ONBOARDING_SIDEBAR_GROUP_HEADERS,
  HOUSEHOLD_ONBOARDING_SIDEBAR_STEP_COPY,
  HouseholdOnboardingGroupKey,
} from './HouseholdOnboardingSidebar.constants';
import { HouseholdOnboardingStepType } from './HouseholdOnboardingSidebar.types';
import { useHouseholdOnboardingSidebarActionContext } from './HouseholdOnboardingSidebarAction.context';

const IncompleteIcon = () => (
  <RefreshCw05Icon size={20} sx={{ color: COLORS.NAVY[100] }} />
);

const CompleteIcon = () => (
  <CheckIcon size={20} sx={{ color: COLORS.FUNCTIONAL.SUCCESS.DEFAULT }} />
);

export type HouseholdOnboardingStepProps = HouseholdOnboardingStepType;
function HouseholdOnboardingStep({
  kind,
  completed,
  stepId,
}: HouseholdOnboardingStepProps) {
  const trackUserEvent = useTrackUserEvent();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const [isHovering, { onMouseEnter, onMouseLeave }] = useIsElementHovered();

  const { useActionForKey } = useHouseholdOnboardingSidebarActionContext();
  const processStepAction = useActionForKey(kind);
  const handleStepAction = useCallback(() => {
    processStepAction();
    trackUserEvent('onboarding_sidebar clicked step', {
      step: kind,
    });
  }, [processStepAction, trackUserEvent, kind]);

  const [markAsComplete, { loading }] =
    useUpdateHouseholdOnboardingStepMutation({
      onCompleted: () => {
        trackUserEvent('onboarding_sidebar manually completed step', {
          step: kind,
        });
      },
      onError: (err: ApolloError) => {
        reportError(`Could not complete step ${kind}`, err);
        showFeedback(
          'Could not mark step as complete.  Please try again later.'
        );
      },
    });

  const displayIcon = useMemo(() => {
    if (completed && !loading) {
      return <CompleteIcon />;
    }

    if (isHovering || loading) {
      return (
        <CircleCheckbox
          disabled={loading}
          value={completed}
          id={`household-onboarding-step-${kind}`}
          onChange={() => {
            if (completed || loading) {
              return;
            }

            void markAsComplete({
              variables: {
                input: {
                  id: stepId,
                  update: {
                    completedAt: new Date(),
                  },
                },
              },
            });
          }}
        />
      );
    }

    return <IncompleteIcon />;
  }, [completed, loading, isHovering, kind, markAsComplete, stepId]);

  return (
    <Stack
      direction="row"
      flexWrap="nowrap"
      justifyContent="flex-start"
      alignItems="center"
      p={1}
      spacing={1.5}
      sx={{
        cursor: 'pointer',
        userSelect: 'none',
        minHeight: 60,
        ':hover': {
          backgroundColor: COLORS.FUNCTIONAL.HOVER,
        },
      }}
      onMouseOver={() => {
        if (completed) return;
        onMouseEnter();
      }}
      onMouseOut={onMouseLeave}
    >
      {displayIcon}
      <Typography
        variant={completed ? 'body1' : 'h5'}
        component="div"
        onClick={handleStepAction}
      >
        {HOUSEHOLD_ONBOARDING_SIDEBAR_STEP_COPY[kind]}
      </Typography>
    </Stack>
  );
}

export interface HouseholdOnboardingStepGroupProps {
  stepGroup: HouseholdOnboardingGroupKey;
  steps: HouseholdOnboardingStepType[];
}

export function HouseholdOnboardingStepGroup({
  stepGroup,
  steps,
}: HouseholdOnboardingStepGroupProps) {
  return (
    <Box>
      <Box
        component="header"
        py={0.5}
        sx={{ borderBottom: `solid 2px ${COLORS.ORANGE[600]}` }}
      >
        <Typography variant="h6">
          {HOUSEHOLD_ONBOARDING_SIDEBAR_GROUP_HEADERS[stepGroup]}
        </Typography>
      </Box>
      <Stack divider={<Divider />}>
        {steps.map((step) => (
          <HouseholdOnboardingStep key={step.kind} {...step} />
        ))}
      </Stack>
    </Box>
  );
}
