import Decimal from 'decimal.js';

import { Cashflows_CashflowFragment } from '@/modules/cashflows/CashflowModal/graphql/Cashflows.generated';

import { CashflowTableRowData } from './CashflowTable';

function isCashflowRecurring({
  startYear,
  endYear,
  endAtClientsDeath,
  afterBothDeaths,
}: Cashflows_CashflowFragment): boolean {
  if (!startYear) {
    return false;
  }

  if (!endYear) {
    return true;
  }

  if (endAtClientsDeath) {
    return true;
  }

  if (afterBothDeaths) {
    return true;
  }

  if (startYear === endYear) {
    return false;
  }

  return true;
}

function mapCashflowToDescription(
  cashflow: Cashflows_CashflowFragment,
  isTwoClientHousehold: boolean
): string {
  const {
    startYear,
    endYear,
    endAtClientsDeath,
    afterBothDeaths,
    entity,
    individual,
  } = cashflow;
  if (!isCashflowRecurring(cashflow)) {
    return `One time in ${startYear}`;
  }

  if (!startYear) {
    return '';
  }

  if (endYear) {
    return `Recurring from ${startYear} to ${endYear}`;
  }

  if (individual) {
    if (endAtClientsDeath) {
      return `Recurring from ${startYear} until ${endAtClientsDeath.firstName}'s death`;
    }

    if (afterBothDeaths && isTwoClientHousehold) {
      return `Recurring from ${startYear} until both deaths`;
    } else if (isTwoClientHousehold) {
      return `Recurring from ${startYear} until death`;
    }
  } else if (entity) {
    return `Recurring from ${startYear} until entity termination`;
  }

  return '';
}

export function mapDataToRows(
  cashflows: Cashflows_CashflowFragment[],
  isTwoClientHousehold: boolean
): CashflowTableRowData[] {
  if (!cashflows.length) {
    return [];
  }

  return cashflows.map<CashflowTableRowData>((cashflow) => {
    return {
      id: cashflow.id,
      amount: cashflow.transferValue || new Decimal(0),
      entityName:
        cashflow.entity?.subtype?.displayName ||
        cashflow.individual?.displayName ||
        '',
      description: mapCashflowToDescription(cashflow, isTwoClientHousehold),
      name: cashflow.displayName,
      growthRate:
        isCashflowRecurring(cashflow) && cashflow.growthPercent
          ? cashflow.growthPercent
          : null,
      action: null,
    };
  });
}
