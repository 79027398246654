import { GetWaterfallSummary_EstateWaterfallVizNodeFragment } from '@/modules/estateWaterfall/graphql/GetWaterfallSummary.generated';

export function isNodeEditable(
  node: GetWaterfallSummary_EstateWaterfallVizNodeFragment
): boolean {
  if (!node) return false;

  return (
    node.node.__typename === 'ClientProfile' ||
    node.node.__typename === 'TestamentaryEntity' ||
    node.node.__typename === 'Entity' ||
    node.node.__typename === 'ClientOrganization'
  );
}

export function canEditEdispositionsForNodeType(
  node: GetWaterfallSummary_EstateWaterfallVizNodeFragment
): boolean {
  if (!node) return false;

  return (
    node.node.__typename === 'ClientProfile' ||
    node.node.__typename === 'TestamentaryEntity' ||
    node.node.__typename === 'Entity'
  );
}

export function isEditablePrimaryClientNode(
  node: GetWaterfallSummary_EstateWaterfallVizNodeFragment,
  primaryClientIds: Set<string>
): boolean {
  return (
    node.node.__typename === 'ClientProfile' && primaryClientIds.has(node.id)
  );
}

export function canNodeEditDispositions(
  node: GetWaterfallSummary_EstateWaterfallVizNodeFragment,
  primaryClientIds: Set<string>
): boolean {
  if (node.node.__typename === 'ClientProfile') {
    return isEditablePrimaryClientNode(node, primaryClientIds);
  }
  return canEditEdispositionsForNodeType(node);
}
