// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateIntegrationEntitiesFromFileMutationVariables = Types.Exact<{
  fileId: Types.Scalars['ID']['input'];
}>;


export type CreateIntegrationEntitiesFromFileMutation = { __typename?: 'Mutation', createIntegrationEntitiesForUploadedCSV: boolean };


export const CreateIntegrationEntitiesFromFileDocument = gql`
    mutation CreateIntegrationEntitiesFromFile($fileId: ID!) {
  createIntegrationEntitiesForUploadedCSV(entityValuationsFileID: $fileId)
}
    `;
export type CreateIntegrationEntitiesFromFileMutationFn = Apollo.MutationFunction<CreateIntegrationEntitiesFromFileMutation, CreateIntegrationEntitiesFromFileMutationVariables>;

/**
 * __useCreateIntegrationEntitiesFromFileMutation__
 *
 * To run a mutation, you first call `useCreateIntegrationEntitiesFromFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIntegrationEntitiesFromFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIntegrationEntitiesFromFileMutation, { data, loading, error }] = useCreateIntegrationEntitiesFromFileMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useCreateIntegrationEntitiesFromFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateIntegrationEntitiesFromFileMutation, CreateIntegrationEntitiesFromFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIntegrationEntitiesFromFileMutation, CreateIntegrationEntitiesFromFileMutationVariables>(CreateIntegrationEntitiesFromFileDocument, options);
      }
export type CreateIntegrationEntitiesFromFileMutationHookResult = ReturnType<typeof useCreateIntegrationEntitiesFromFileMutation>;
export type CreateIntegrationEntitiesFromFileMutationResult = Apollo.MutationResult<CreateIntegrationEntitiesFromFileMutation>;
export type CreateIntegrationEntitiesFromFileMutationOptions = Apollo.BaseMutationOptions<CreateIntegrationEntitiesFromFileMutation, CreateIntegrationEntitiesFromFileMutationVariables>;
export const Operations = {
  Mutation: {
    CreateIntegrationEntitiesFromFile: 'CreateIntegrationEntitiesFromFile' as const
  }
}