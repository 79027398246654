// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HouseholdTaxForWaterfallQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.EstateWaterfallWhereInput>;
}>;


export type HouseholdTaxForWaterfallQuery = { __typename?: 'Query', estateWaterfalls: { __typename?: 'EstateWaterfallConnection', totalCount: number, edges?: Array<{ __typename?: 'EstateWaterfallEdge', node?: { __typename?: 'EstateWaterfall', id: string, createdAt: Time, firstGrantorDeathYear?: number | null, secondGrantorDeathYear?: number | null, parent?: { __typename?: 'EstateWaterfall', id: string } | null, visualizationWithProjections: { __typename?: 'EstateWaterfallViz', _cacheKey: string, nodes: Array<{ __typename?: 'EstateWaterfallVizNode', id: string, _cacheKey: string, afterDeath: Types.AfterDeath, waterfallID: string, transferredToEstateTax?: CurrencyUSD | null, transferredToGiftingTax?: CurrencyUSD | null }> } } | null } | null> | null } };

export type HouseholdTaxForWaterfall_EstateWaterfallFragment = { __typename?: 'EstateWaterfall', id: string, createdAt: Time, firstGrantorDeathYear?: number | null, secondGrantorDeathYear?: number | null, parent?: { __typename?: 'EstateWaterfall', id: string } | null, visualizationWithProjections: { __typename?: 'EstateWaterfallViz', _cacheKey: string, nodes: Array<{ __typename?: 'EstateWaterfallVizNode', id: string, _cacheKey: string, afterDeath: Types.AfterDeath, waterfallID: string, transferredToEstateTax?: CurrencyUSD | null, transferredToGiftingTax?: CurrencyUSD | null }> } };

export const HouseholdTaxForWaterfall_EstateWaterfallFragmentDoc = gql`
    fragment HouseholdTaxForWaterfall_EstateWaterfall on EstateWaterfall {
  id
  createdAt
  parent {
    id
  }
  firstGrantorDeathYear
  secondGrantorDeathYear
  visualizationWithProjections {
    _cacheKey
    nodes {
      id
      _cacheKey
      afterDeath
      waterfallID
      transferredToEstateTax
      transferredToGiftingTax
    }
  }
}
    `;
export const HouseholdTaxForWaterfallDocument = gql`
    query HouseholdTaxForWaterfall($where: EstateWaterfallWhereInput) {
  estateWaterfalls(where: $where) {
    totalCount
    edges {
      node {
        id
        ...HouseholdTaxForWaterfall_EstateWaterfall
      }
    }
  }
}
    ${HouseholdTaxForWaterfall_EstateWaterfallFragmentDoc}`;

/**
 * __useHouseholdTaxForWaterfallQuery__
 *
 * To run a query within a React component, call `useHouseholdTaxForWaterfallQuery` and pass it any options that fit your needs.
 * When your component renders, `useHouseholdTaxForWaterfallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHouseholdTaxForWaterfallQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useHouseholdTaxForWaterfallQuery(baseOptions?: Apollo.QueryHookOptions<HouseholdTaxForWaterfallQuery, HouseholdTaxForWaterfallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HouseholdTaxForWaterfallQuery, HouseholdTaxForWaterfallQueryVariables>(HouseholdTaxForWaterfallDocument, options);
      }
export function useHouseholdTaxForWaterfallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HouseholdTaxForWaterfallQuery, HouseholdTaxForWaterfallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HouseholdTaxForWaterfallQuery, HouseholdTaxForWaterfallQueryVariables>(HouseholdTaxForWaterfallDocument, options);
        }
export function useHouseholdTaxForWaterfallSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HouseholdTaxForWaterfallQuery, HouseholdTaxForWaterfallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HouseholdTaxForWaterfallQuery, HouseholdTaxForWaterfallQueryVariables>(HouseholdTaxForWaterfallDocument, options);
        }
export type HouseholdTaxForWaterfallQueryHookResult = ReturnType<typeof useHouseholdTaxForWaterfallQuery>;
export type HouseholdTaxForWaterfallLazyQueryHookResult = ReturnType<typeof useHouseholdTaxForWaterfallLazyQuery>;
export type HouseholdTaxForWaterfallSuspenseQueryHookResult = ReturnType<typeof useHouseholdTaxForWaterfallSuspenseQuery>;
export type HouseholdTaxForWaterfallQueryResult = Apollo.QueryResult<HouseholdTaxForWaterfallQuery, HouseholdTaxForWaterfallQueryVariables>;
export const Operations = {
  Query: {
    HouseholdTaxForWaterfall: 'HouseholdTaxForWaterfall' as const
  },
  Fragment: {
    HouseholdTaxForWaterfall_EstateWaterfall: 'HouseholdTaxForWaterfall_EstateWaterfall' as const
  }
}