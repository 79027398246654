import { Stack, useTheme } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { Outlet } from 'react-router-dom';

import { ErrorFeedbackFallback } from '@/components/notifications/Feedback/ErrorFeedbackFallback';
import { ContextualHelpDrawer } from '@/modules/content/components/ContextualHelpDrawer';

import { MainContentWrapper } from './MainContentWrapper';

export function NoSidebarLayout() {
  const theme = useTheme();

  return (
    <ErrorBoundary fallback={<ErrorFeedbackFallback />}>
      <Stack data-it="Layout-root" display="flex" direction="column">
        <MainContentWrapper
          sx={{
            width: '100%',
            minHeight: '100vh',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Outlet />
        </MainContentWrapper>
      </Stack>
      <ContextualHelpDrawer />
    </ErrorBoundary>
  );
}
