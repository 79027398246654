// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadGiftExportMutationVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
}>;


export type DownloadGiftExportMutation = { __typename?: 'Mutation', exportLifetimeExclusionEvents: { __typename?: 'File', id: string, download: { __typename?: 'FileDownload', downloadURL: string } } };


export const DownloadGiftExportDocument = gql`
    mutation DownloadGiftExport($householdId: ID!) {
  exportLifetimeExclusionEvents(householdID: $householdId) {
    id
    download {
      downloadURL
    }
  }
}
    `;
export type DownloadGiftExportMutationFn = Apollo.MutationFunction<DownloadGiftExportMutation, DownloadGiftExportMutationVariables>;

/**
 * __useDownloadGiftExportMutation__
 *
 * To run a mutation, you first call `useDownloadGiftExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadGiftExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadGiftExportMutation, { data, loading, error }] = useDownloadGiftExportMutation({
 *   variables: {
 *      householdId: // value for 'householdId'
 *   },
 * });
 */
export function useDownloadGiftExportMutation(baseOptions?: Apollo.MutationHookOptions<DownloadGiftExportMutation, DownloadGiftExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadGiftExportMutation, DownloadGiftExportMutationVariables>(DownloadGiftExportDocument, options);
      }
export type DownloadGiftExportMutationHookResult = ReturnType<typeof useDownloadGiftExportMutation>;
export type DownloadGiftExportMutationResult = Apollo.MutationResult<DownloadGiftExportMutation>;
export type DownloadGiftExportMutationOptions = Apollo.BaseMutationOptions<DownloadGiftExportMutation, DownloadGiftExportMutationVariables>;
export const Operations = {
  Mutation: {
    DownloadGiftExport: 'DownloadGiftExport' as const
  }
}