import { Loader } from '@/components/progress/Loader/Loader';

export function DefaultLoadingOverlay() {
  return (
    <Loader
      boxProps={{
        sx: { textAlign: 'center', my: 3, width: '100%' },
      }}
      circularProgressProps={{ size: 75 }}
    />
  );
}
