import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { Grid } from '@/components/layout/Grid';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { ClientImportExport } from '@/pages/internal/DevToolsPage/ClientImportExport/ClientImportExport';

import { CreateTenantForm } from './CreateTenantForm';
import { TenantSettingsForm } from './TenantSettingsForm/TenantSettingsForm';

export function AdminTenantsPage() {
  return (
    <SubpageLayout heading="Tenants">
      <Grid container spacing={2}>
        <Grid item lg={6} sm={8}>
          <HeaderCard heading="Tenant creation">
            <CreateTenantForm />
          </HeaderCard>
        </Grid>
        <Grid item lg={6} sm={8}>
          <HeaderCard heading="Tenant configuration">
            <TenantSettingsForm />
          </HeaderCard>
        </Grid>
        <Grid item lg={12} sm={8}>
          <ClientImportExport />
        </Grid>
      </Grid>
    </SubpageLayout>
  );
}
