import { useEffect } from 'react';

import { Callout } from '@/components/notifications/Callout/Callout';
import { AssetIntegration_EntityFragment } from '@/modules/assetProviderIntegrations/shared/graphql/assetProviderIntegration.fragments.generated';
import { getIntegrationEntitiesFromEntity } from '@/modules/assetProviderIntegrations/shared/utils';
import { getExtendedAssetFromFragment } from '@/modules/entities/details/trusts/integratedAssetsUtils';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { getNodes } from '@/utils/graphqlUtils';

import { AssetValuationV2Fragment } from '../graphql/entityValuationQueries.generated';
import AssetValuationDisplayTable, {
  AssetValuationDisplayTableVariant,
} from './AssetValuationDisplayTable';
import {
  AssetValuationForTableQuery,
  useAssetValuationForTableLazyQuery,
} from './graphql/AssetValuationTable.generated';

function getGuardedAssetValuation(
  queryData?: AssetValuationForTableQuery
): AssetValuationV2Fragment | null {
  if (!queryData?.assetValuation) {
    return null;
  }

  const assetValuation = queryData.assetValuation;
  if (assetValuation.__typename !== 'AssetValuationV2') {
    throw new Error('Invalid type in AssetValuationTable');
  }
  return assetValuation;
}

function getGuardedEntity(
  queryData?: AssetValuationForTableQuery
): AssetIntegration_EntityFragment | null {
  if (!queryData?.entity) {
    return null;
  }

  const { entity } = queryData;
  if (entity.__typename !== 'Entity') {
    throw new Error('Invalid type in AssetValuationTable');
  }
  return entity;
}

export interface AssetValuationTableProps {
  entityId: string;
  assetValuationId: string | null;
  variant?: AssetValuationDisplayTableVariant;
  hideValues?: boolean;
  shouldShowSumRow?: boolean;
}

export function AssetValuationTable({
  assetValuationId,
  entityId,
  variant = 'full',
  hideValues = false,
  shouldShowSumRow = true,
}: AssetValuationTableProps) {
  const { assetClassesById } = useTenantDetailsContext();
  const [getData, { data, error }] = useAssetValuationForTableLazyQuery();

  useEffect(() => {
    if (!assetValuationId) return;
    void getData({
      variables: {
        assetValuationId,
        entityId,
      },
    });
  }, [getData, assetValuationId, entityId]);

  const assetValuation = getGuardedAssetValuation(data);
  const entity = getGuardedEntity(data);
  const assets = getNodes(assetValuation?.assets).map((asset) =>
    getExtendedAssetFromFragment(asset, assetClassesById)
  );
  const integrationEntities = getIntegrationEntitiesFromEntity(entity);

  if (error) {
    return (
      <Callout severity="error">
        We failed to fetch this asset valuation. Please refresh the page to try
        again.
      </Callout>
    );
  }

  return (
    <AssetValuationDisplayTable
      integrationEntities={integrationEntities}
      variant={variant}
      hideValues={hideValues}
      shouldShowSumRow={shouldShowSumRow}
      assets={assets}
    />
  );
}
