import {
  applyNodeChanges,
  Node as ReactFlowNode,
  NodeChange,
  NodeDimensionChange,
  NodePositionChange,
  useNodesInitialized,
  useOnViewportChange,
  useReactFlow,
  useStoreApi,
  Viewport,
} from '@xyflow/react';
import { useCallback, useEffect, useState } from 'react';

import { useEstateWaterfallContext } from '@/modules/estateWaterfall/contexts/estateWaterfall.context';
import {
  isNodeAwaitingGraveyardLayout,
  NODE_GRAVEYARD_Y_OFFSET,
} from '@/modules/graphViz/graphVizNodeConfig/nodeGraveyard.utils';
import { assertNonNil } from '@/utils/assertUtils';

import { useFlowChartContext } from '../../context/flowChart.context';
import { useCanvasDimensions } from '../../hooks/useCanvasDimensions';
import { Node } from '../../types';
import {
  getBoundingBoxForNodes,
  isSectionLabelChildNode,
} from '../../utils/nodes';

export function createSectionBoundsHandlers(
  changes: NodeChange[],
  nextNodes: Node[],
  padding = 100
) {
  const changeSet = new Set<string>([]);

  function getMutableNode(id: string): Node {
    return assertNonNil(
      nextNodes.find((n) => n.id === id),
      `could not find node ${id}`
    );
  }

  function getSectionNodes() {
    return nextNodes.filter((n) => n.type === 'sectionLabel');
  }

  function getSectionChildren(id: string) {
    return nextNodes.filter(
      (n) => isSectionLabelChildNode(n) && n.data.sectionLabelId === id
    );
  }

  function getBoundsForSectionChildren(sectionNodeId: string) {
    const graveyardNodesForSection = getSectionChildren(sectionNodeId).filter(
      isNodeAwaitingGraveyardLayout
    );
    const nonGraveyardNodesForSection = getSectionChildren(
      sectionNodeId
    ).filter((n) => !isNodeAwaitingGraveyardLayout(n));

    if (nonGraveyardNodesForSection.length === 0) {
      nonGraveyardNodesForSection.push(...graveyardNodesForSection);
    }

    return getBoundingBoxForNodes(nonGraveyardNodesForSection);
  }

  function syncSectionNodeTopToChildren(sectionNodeId: string) {
    const boundsForSectionChildren = getBoundsForSectionChildren(sectionNodeId);
    const graveyardNodesForSection = getSectionChildren(sectionNodeId).filter(
      isNodeAwaitingGraveyardLayout
    );

    // Modify the node graveyard nodes manually to sit above the top of the section nodes
    graveyardNodesForSection
      .map((n) => getMutableNode(n.id))
      .forEach((n) => {
        n.position.y =
          boundsForSectionChildren.top - NODE_GRAVEYARD_Y_OFFSET - padding;
      });

    getMutableNode(sectionNodeId).position.y =
      boundsForSectionChildren.top - padding;
  }

  function addYDiffToSectionChildren(sectionNodeId: string, diff: number) {
    getSectionChildren(sectionNodeId).forEach((n) => {
      getMutableNode(n.id).position.y += diff;
    });
  }

  function initSectionNodeTopBounds() {
    getSectionNodes().forEach((sectionNode) => {
      syncSectionNodeTopToChildren(sectionNode.id);
    });
  }

  function forEachNode(
    nodes: Node[],
    cb: (current: Node, prev?: Node, next?: Node) => void
  ) {
    nodes.forEach((n, i) => cb(n, nodes[i - 1], nodes[i + 1]));
  }

  /**
   * Computes the diff for the "current section", i.e. the one that had a node inside it change
   * position or dimensions
   */
  function computeCurrentSectionDiff() {
    // 1. Traverse in reverse needed for pulling up the bottom of each section
    const sectionNodes = getSectionNodes().reverse();
    forEachNode(sectionNodes, (sectionNode, prevSectionNode) => {
      // 2. Sync node bounds with prev section (bottom line)
      if (prevSectionNode) {
        const { bottom } = getBoundsForSectionChildren(sectionNode.id);
        const yDiff = prevSectionNode.position.y - bottom - padding;
        addYDiffToSectionChildren(sectionNode.id, yDiff);
      }

      // 3. Re-sync the top, but only if we haven't already adjusted it in computeDiff below
      if (!changeSet.has(sectionNode.id)) {
        syncSectionNodeTopToChildren(sectionNode.id);
      }
    });
  }

  /**
   * Computes the diff for the "next section", i.e. the one AFTER the section
   * that had a node inside it change position or dimensions
   */
  function computeNextSectionDiff() {
    const sectionNodes = getSectionNodes();
    forEachNode(sectionNodes, (sectionNode, _, nextSectionNode) => {
      // 1. Sync child bounds to delta of current section node top
      const { top } = getBoundsForSectionChildren(sectionNode.id);
      const yDiff = sectionNode.position.y - top + padding;
      addYDiffToSectionChildren(sectionNode.id, yDiff);

      // 2. Sync next section top to bottom of current children node bounds
      if (nextSectionNode) {
        getMutableNode(nextSectionNode.id).position.y =
          getBoundsForSectionChildren(sectionNode.id).bottom + padding;
      }
    });
  }

  /**
   * @description Runs diff logic and mutates nextNodes whenever there are changes
   * to "position" or "dimensions" that could change the section node bounds
   */
  function computeDiff() {
    let sectionLabelIdThatChanged: string | null = null;
    let nextSectionHasDiff = false;

    for (const change of changes) {
      if (change.type === 'position' || change.type === 'dimensions') {
        const node = getMutableNode(change.id);
        if (isSectionLabelChildNode(node)) {
          sectionLabelIdThatChanged = node.data.sectionLabelId!;
          changeSet.add(sectionLabelIdThatChanged);
          break;
        }
      }
    }

    // No changes were made that we care about
    if (!sectionLabelIdThatChanged) return;

    // Sync top of the current section
    syncSectionNodeTopToChildren(sectionLabelIdThatChanged);

    // Mark the section after the current as part of the change set for diffing
    forEachNode(getSectionNodes(), (sectionNode, _, nextSectionNode) => {
      if (sectionNode.id === sectionLabelIdThatChanged && nextSectionNode) {
        nextSectionHasDiff = true;
        changeSet.add(nextSectionNode.id);
      }
    });

    // Calculate and mutate nodes for current section diff
    if (changeSet.has(sectionLabelIdThatChanged)) {
      computeCurrentSectionDiff();
    }

    /**
     * Calculate and mutate nodes for next section diff
     *
     * IMPORTANT: it's very important that this happens in an if, not an else-if with the condition above.
     * It's possible when you have a single node in a middle section,
     * you need to resize both the top and bottom on position/dimensions change of that node
     */
    if (nextSectionHasDiff) {
      computeNextSectionDiff();
    }
  }

  /**
   * 1. Sync the top node bounds to top of each node
   * 2. "Pull up" each section so that there is appropriate padding between the sections
   */
  function initSectionBounds() {
    initSectionNodeTopBounds();
    computeCurrentSectionDiff();
  }

  return { initSectionBounds, computeDiff };
}

export interface UseSectionNodesBoundsOpts {
  padding?: number;
}

/**
 * @description Computes and initializes section node bounds
 * as changes in dimension / position of child nodes are made by the user
 */
export function useSectionNodesBounds({
  padding,
}: UseSectionNodesBoundsOpts = {}) {
  const { setNodes, getNodes } = useReactFlow();
  const isInitialized = useNodesInitialized();
  const [didRunInit, setDidRunInit] = useState(false);

  const initSectionBounds = useCallback((): void => {
    const nextNodes = getNodes() as Node[];
    const { initSectionBounds: init } = createSectionBoundsHandlers(
      [],
      nextNodes,
      padding
    );

    init();
    setNodes(nextNodes);
  }, [getNodes, padding, setNodes]);

  const applySectionBoundsToNodes = useCallback(
    (changes: NodeChange[], nodes: Node[]): Node[] => {
      const nextNodes = applyNodeChanges(
        changes,
        nodes as ReactFlowNode[]
      ) as Node[];

      // If not initalized, nodes don't have a proper width/height to do any computations with
      // so we can return them right away
      if (!isInitialized) return nextNodes;

      if (!didRunInit) {
        const { initSectionBounds } = createSectionBoundsHandlers(
          changes,
          nextNodes,
          padding
        );
        initSectionBounds();
        setDidRunInit(true);
      } else {
        const { computeDiff } = createSectionBoundsHandlers(
          changes,
          nextNodes,
          padding
        );
        computeDiff();
      }

      return nextNodes;
    },
    [didRunInit, isInitialized, padding]
  );

  return { applySectionBoundsToNodes, initSectionBounds, didRunInit };
}

/**
 * @description Helpers to resize section nodes when the map is resized
 */
export function useSectionNodesResizeHandlers() {
  const { getState } = useStoreApi();

  const getSectionNodeWidthChanges = useCallback(
    ({ x, zoom }: Viewport) => {
      const nodes = getState().nodes;
      const viewportWidth = getState().width;
      const sectionNodes = nodes.filter((n) => n.type === 'sectionLabel');

      // Compute a width/x that always is the maximum, regardless of scaling
      const zoomFactor = 1 / zoom;
      const scaledWidth = viewportWidth * zoomFactor;
      const scaledX = -(x * zoomFactor);

      const changes: NodeChange[] = sectionNodes.flatMap((n) => {
        const dimensions: NodeDimensionChange = {
          id: n.id,
          type: 'dimensions',
          dimensions: { width: scaledWidth, height: n.height ?? 0 },
          setAttributes: true,
        };

        const nextPosition = { ...n.position, x: scaledX };

        const position: NodePositionChange = {
          id: n.id,
          type: 'position',
          position: { ...nextPosition },
          positionAbsolute: { ...nextPosition },
        };

        return [dimensions, position];
      });

      return changes;
    },
    [getState]
  );

  const onChange = useCallback(
    (vp: Viewport) => {
      const nodes = getState().nodes;
      const sectionNodes = nodes.filter((n) => n.type === 'sectionLabel');
      if (!sectionNodes.length) return; // No update needed

      const triggerNodeChanges = getState().triggerNodeChanges;
      triggerNodeChanges(getSectionNodeWidthChanges(vp));
    },
    [getSectionNodeWidthChanges, getState]
  );

  const forceSectionNodesRelayout = useCallback(() => {
    const [x, y, zoom] = getState().transform;
    onChange({ x, y, zoom });
  }, [getState, onChange]);

  return {
    forceSectionNodesRelayout,
    onViewportChange: onChange,
    getSectionNodeWidthChanges,
  };
}

/**
 * @description Locks section nodes to a width that is always the maximum
 * of the viewport accounting for x, y, zoom scaling of the viewport.
 */
export function useSectionNodeResizeListeners() {
  const { state } = useEstateWaterfallContext();

  const { onViewportChange, forceSectionNodesRelayout } =
    useSectionNodesResizeHandlers();
  const { canvasDimensions } = useCanvasDimensions();
  const { flowBounds } = useFlowChartContext();

  useOnViewportChange({ onChange: onViewportChange });

  // Viewport is only the x,y,zoom levels, but we also need to recalc
  // when the entire width of canvas changes
  useEffect(() => {
    if (state.isInitializing) return;
    forceSectionNodesRelayout();
  }, [
    forceSectionNodesRelayout,
    canvasDimensions.width,
    flowBounds.width,
    state.isInitializing,
  ]);
}
