import { Box, Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { EyeIcon } from '@/components/icons/EyeIcon';
import { Share07Icon } from '@/components/icons/Share07Icon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { ShareProposalAction } from '@/modules/proposal/share/ShareProposalAction/ShareProposalAction';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';

export interface FinishProposalModalProps {
  householdId: string;
  proposalId: string;
  isOpen: boolean;
  onClose: () => void;
}

export function FinishProposalModal({
  isOpen,
  onClose,
  householdId,
  proposalId,
}: FinishProposalModalProps) {
  const navigate = useNavigate();
  function handleReturnToClientOverview() {
    const clientOverviewPath = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
      { householdId }
    );
    navigate(clientOverviewPath);
  }

  return (
    <DialogModal
      isOpen={isOpen}
      onClose={onClose}
      heading="What would you like to do next?"
    >
      <Stack spacing={3}>
        <Stack direction="row" spacing={3} justifyContent="space-between">
          <ActionCard
            sx={{ flexGrow: 1 }}
            description="Share a link to your proposal"
            icon={<Share07Icon size={24} color={COLORS.GRAY[400]} />}
            actions={
              <ShareProposalAction
                proposalId={proposalId}
                ActionComponent={({ onClick, disabled }) => (
                  <Button
                    onClick={onClick}
                    disabled={disabled}
                    variant="primary"
                    size="sm"
                    fullWidth
                  >
                    Copy secure link
                  </Button>
                )}
              />
            }
          />
          <ActionCard
            sx={{ flexGrow: 1 }}
            description="View the proposal you've built"
            icon={<EyeIcon size={24} color={COLORS.GRAY[400]} />}
            actions={
              <Button
                target="_blank"
                href={getCompletePathFromRouteKey(
                  ROUTE_KEYS.HOUSEHOLD_PROPOSAL_PRESENTATION,
                  { proposalId },
                  { householdId, preview: true }
                )}
                variant="primary"
                size="sm"
                fullWidth
              >
                View proposal
              </Button>
            }
          />
        </Stack>
        <Box textAlign="center">
          <Button
            onClick={handleReturnToClientOverview}
            variant="transparent"
            size="sm"
          >
            Return to client overview
          </Button>
        </Box>
      </Stack>
    </DialogModal>
  );
}
