import { Stack, Typography } from '@mui/material';
import { ComponentProps, JSXElementConstructor, useMemo } from 'react';

import { DocumentSummaryEntryMode } from '@/types/schema';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { TrustSummaryData } from './TrustDetails.types';

export interface DocumentMetadataSlots<
  RateSummaryProps = Record<string, unknown>,
> {
  RateSummary?: JSXElementConstructor<RateSummaryProps>;
  RateSummaryProps?: RateSummaryProps;
}

interface DocumentMetadataProps<RateSummaryProps = Record<string, unknown>> {
  documentSummary: TrustSummaryData['documentSummary'];
  stackSx?: ComponentProps<typeof Stack>['sx'];
  slots?: DocumentMetadataSlots<RateSummaryProps>;
}

export function DocumentMetadata<RateSummaryProps = Record<string, unknown>>({
  documentSummary,
  stackSx,
  slots,
}: DocumentMetadataProps<RateSummaryProps>) {
  const createdOrEditedBy = useMemo(() => {
    if (!documentSummary) return '';

    const entryMode = documentSummary.entryMode;
    const editedUser = documentSummary.updateUser?.displayName;
    const editedDate = formatDateToMonDDYYYY(documentSummary.updatedAt);

    if (entryMode === DocumentSummaryEntryMode.User) {
      if (editedUser && editedDate) {
        return `Edited by ${documentSummary.updateUser.displayName} ${editedDate}`;
      }
    }

    if (
      entryMode === DocumentSummaryEntryMode.AiAuto ||
      entryMode === DocumentSummaryEntryMode.AiUserInvoked
    ) {
      return `Created by AI ${editedDate}`;
    }

    return '';
  }, [documentSummary]);

  if (!documentSummary) return null;

  return (
    <>
      <Stack
        pt={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={stackSx}
      >
        <Stack direction="row" spacing={3}>
          {slots?.RateSummary && slots.RateSummaryProps && (
            <slots.RateSummary {...slots.RateSummaryProps} />
          )}
        </Stack>
        <Typography variant="subtitle2">{createdOrEditedBy}</Typography>
      </Stack>
    </>
  );
}
