import {
  array,
  assign,
  Describe,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreateLlcBusinessEntityInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateLlcBusinessEntityInput,
} from '@/types/schema';

import {
  commonCreateBusinessEntityProperties,
  commonCreateBusinessEntityTopLevelProperties,
  commonUpdateBusinessEntityEntityUpdateProperties,
  commonUpdateBusinessEntityProperties,
  commonUpdateBusinessEntityTopLevelProperties,
  keyPersonSchema,
} from './businessEntityTypes';
import {
  createInsurancePolicyInput,
  updateInsurancePolicyInput,
} from './insuranceTypes';
import {
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  principalWithOwnershipSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const createLLCSchema: Describe<AugmentedCreateLlcBusinessEntityInput> = object(
  {
    create: commonCreateBusinessEntityProperties,
    withOwners: optional(nullable(array(principalWithOwnershipSchema))),
    withKeyPeople: optional(nullable(array(keyPersonSchema))),
    withDesignerAccount: optional(nullable(createAccountSchema)),
  }
);

const updateLLCSchema: Describe<AugmentedUpdateLlcBusinessEntityInput> = object(
  {
    id: string(),
    update: commonUpdateBusinessEntityProperties,
    withOwners: optional(nullable(array(principalWithOwnershipSchema))),
    withKeyPeople: optional(nullable(array(keyPersonSchema))),
    updateDesignerAccount: optional(nullable(updateAccountSchema)),
    withPolicies: optional(nullable(array(createInsurancePolicyInput))),
    updatePolicies: optional(nullable(array(updateInsurancePolicyInput))),
  }
);

const createSchema: Describe<AugmentedCreateEntityInput> = assign(
  object({
    create: createEntityInput,
    withLlcBusinessEntity: optional(nullable(createLLCSchema)),
    withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
  }),
  commonCreateBusinessEntityTopLevelProperties
);

const updateSchema: Describe<AugmentedUpdateEntityInput> = assign(
  object({
    id: string(),
    update: commonUpdateBusinessEntityEntityUpdateProperties,
    updateLlcBusinessEntity: optional(nullable(updateLLCSchema)),
    withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
  }),
  commonUpdateBusinessEntityTopLevelProperties
);

export function validateLLCCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'LLC create');
}

export function validateLLCUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'LLC update');
}
