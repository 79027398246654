import { Stack } from '@mui/material';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { DataTableCard } from '@/components/tables/DataTable/components/DataTableCard';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { usePaginatedDataTableQuery } from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { ActivityTable } from '@/modules/activities/ActivityTable/ActivityTable';
import { useGetActivityGroupsQuery } from '@/modules/activities/graphql/ActivityGroups.generated';

export function ClientActivityHistoryPage() {
  const householdId = useRequiredParam('householdId');
  const [paginatedTableProps, { data }] = usePaginatedDataTableQuery(
    useGetActivityGroupsQuery,
    {
      pageSize: PageSizes.Five,
      variables: {
        where: { hasHouseholdWith: [{ id: householdId }] },
      },
    }
  );

  return (
    <Stack height="100%" p={3}>
      <SubpageLayout heading="Activity history">
        <Stack height="100%">
          <DataTableCard>
            <ActivityTable data={data} {...paginatedTableProps} />
          </DataTableCard>
        </Stack>
      </SubpageLayout>
    </Stack>
  );
}
