import { AssetValuationV2ValuationReason } from '@/types/schema';
import { PathsOf } from '@/types/subform';

import { Asset } from '../types/asset';

export const NAMESPACE = 'assetsSubform' as const;
export type NamespaceType = typeof NAMESPACE;
const VARIANTS = [
  // initialFundingRevaluation is used to update an existing initial funding valuation
  // after the user has confirmed the initial funding valuation and the entity has become active.
  // it updates the existing valuation, replacing the current initialFundingValue on the designerAccount.
  'initialFundingRevaluation',

  // verifyInitialFunding is the variant used to force users to confirm the initial funding valuation
  // as part of the implementation phase.
  // it updates the existing valuation, replacing the current initialFundingValue on the designerAccount.
  'verifyInitialFunding',

  // updateHoldings is used to update the holdings of an active GRAT.
  // it creates a new valuation on the designerAccount.
  'updateHoldings',

  // structuringDesigner is the variant used by the structuring designer.
  // it updates the existing valuation, replacing the current initialFundingValue on the designerAccount.
  'structuringDesigner',

  // editDesignSummary is the variant used by the edit design summary modal.
  // it is used to update a specific valuation and hides date and document upload.
  'editDesignSummary',
] as const;
export type VariantType = (typeof VARIANTS)[number];

export interface SubformAsset extends Asset {
  confirmed: boolean;
}

// this is the type of the subform's values
export interface AssetsSubformType {
  assets: SubformAsset[];
  valuationId: string | undefined;
  accountId: string | undefined;

  // for initialFundingRevaluation
  confirm?: boolean;

  // for verifyInitialFunding
  fundingDate?: Date | null;

  // for updateHoldings and updateAssetValuation
  valuationDate?: Date | null;
  valuationReason?: AssetValuationV2ValuationReason;
}

// this is what the subform's values will be nested under in the parent form
export interface Fields {
  [NAMESPACE]: AssetsSubformType;
}

export type SubformField = PathsOf<Fields>;

// this is Props for the UI component
export interface SubformComponentProps {
  // standard set of props
  variant: VariantType;
  householdId: string;
  disabled?: boolean;

  // props that are specific to this type of subform
  dateCopy?: string;
  assetSumLabelCopy?: string;
  entityId?: string;
  forceConfirmValuation?: boolean;
}

export const assetsSubformDefaultValues = {
  assets: [],
  valuationId: '',
  accountId: '',
  confirm: false,
  fundingDate: null,
  valuationReason: AssetValuationV2ValuationReason.AssetRevaluation,
};
