import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PoweredByLuminary } from '@/components/brand/PoweredByLuminary/PoweredByLuminary';
import { Button } from '@/components/form/baseInputs/Button';

export interface ErrorOverlayProps {
  heading: React.ReactNode;
  body: React.ReactNode;
  showLogo?: boolean;
  showBackButton?: boolean;
}

export function ErrorOverlay({
  body,
  heading,
  showLogo = true,
  showBackButton,
}: ErrorOverlayProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  let isBackButtonClicked = false;

  // this useEffect is strange -- the hook on its own is a noop, but will check a flag on unmount
  // to see if the button has clicked.  however, that flag can't be stateful as that requires a
  // re-render to update, which isn't guranteed to complete before this component is unmounted.
  // similarly, it can't be a ref, as the original version of the `.current` value will still be
  // present in the useEffect's returned callback, as updating the ref won't update the hook.
  useEffect(() => {
    return () => {
      // if the back button is clicked, force a reload so the user is pushed to the latest version
      if (isBackButtonClicked) {
        window.location.reload();
      }
    };
  }, [isBackButtonClicked]);

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.dark,
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Stack maxWidth={600} textAlign="center" spacing={2}>
        <Typography variant="h1" color={theme.palette.common.white}>
          {heading}
        </Typography>
        <Typography variant="body1" color={theme.palette.common.white}>
          {body}
        </Typography>

        {showBackButton && (
          <Button
            variant="secondary-filled"
            size="sm"
            onClick={() => {
              navigate(-1);
              isBackButtonClicked = true;
            }}
          >
            Go back
          </Button>
        )}
      </Stack>
      {showLogo && (
        <Box mt={6} maxWidth={200}>
          <PoweredByLuminary />
        </Box>
      )}
    </Box>
  );
}
