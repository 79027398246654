import { Box } from '@mui/material';
import { ComponentType, useCallback, useMemo, useState } from 'react';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { IconProps } from '@/components/icons/ChevronDownIcon';
import { CoinsSwap02Icon } from '@/components/icons/CoinsSwap02Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  MenuItem,
  MenuItemProps,
} from '@/components/poppers/MenuPopper/MenuItem';
import { getUserFacingErrorMessages } from '@/graphql/errors';
import { ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES } from '@/modules/assetProviderIntegrations/shared/constants';
import { getEntityAssetsIntegrationProvider } from '@/modules/assetProviderIntegrations/shared/utils';
import { FullScreenStructuredAssetsModal } from '@/modules/assets/FullScreenStructuredAssetsModal/FullScreenStructuredAssetsModal';
import { AssetFullScreenModal } from '@/modules/assetValuation/AssetFullScreenModal/AssetFullScreenModal';
import { EditEntitySection } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { EditEntitySplitScreenModal } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenModal';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { CreateLiabilityModal } from '@/modules/liabilities/LiabilityModal/CreateLiabilityModal';
import { EditLiabilityModal } from '@/modules/liabilities/LiabilityModal/EditLiabilityModal';
import { COLORS } from '@/styles/tokens/colors';
import { EntityKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { useEntityDetailsContext } from '../../contexts/entityDetails/entityDetails.context';
import { EntityDetail_EntityFragment } from '../graphql/EntityDetailPage.generated';
import { useResyncEntityValuationMutation } from './graphql/EntityValuation.generated';

export enum EntityValuationAction {
  MANAGE_VALUATION = 'MANAGE_VALUATION',
  VIEW_ON_INTEGRATED_PLATFORM = 'VIEW_ON_INTEGRATED_PLATFORM',
  OPEN_ENTITY_EDIT_MODAL = 'OPEN_ENTITY_EDIT_MODAL',
  ADD_LIABILITY = 'ADD_LIABILITY',
  EDIT_LIABILITY = 'EDIT_LIABILITY',
  RESYNC_ENTITY_VALUATION = 'RESYNC_ENTITY_VALUATION',
}

export type EntityValuationActionDefinition =
  | {
      kind: EntityValuationAction.MANAGE_VALUATION;
      label: string;
      showDivider?: boolean;
      icon: ComponentType<IconProps>;
    }
  | {
      kind: EntityValuationAction.OPEN_ENTITY_EDIT_MODAL;
      initialEditEntitySection: EditEntitySection;
      label: string;
      showDivider?: boolean;
      icon: ComponentType<IconProps>;
    }
  | {
      kind: EntityValuationAction.ADD_LIABILITY;
      label: string;
      showDivider?: boolean;
      icon: ComponentType<IconProps>;
    }
  | {
      kind: EntityValuationAction.EDIT_LIABILITY;
      liabilityId: string;
      label: string;
      showDivider?: boolean;
      icon: ComponentType<IconProps>;
    }
  | {
      kind: EntityValuationAction.VIEW_ON_INTEGRATED_PLATFORM;
      name: string;
      integrationEntityId: string;
      showDivider?: boolean;
      icon: ComponentType<IconProps>;
    }
  | {
      kind: EntityValuationAction.RESYNC_ENTITY_VALUATION;
      label: string;
      showDivider?: boolean;
      icon: ComponentType<IconProps>;
    };

export interface EntityValuationActionsProps {
  entity: EntityDetail_EntityFragment;
  actions: EntityValuationActionDefinition[];
  onAfterUpdate: () => void;
}

const buttonProps = {
  size: 'sm' as const,
  startIcon: CoinsSwap02Icon,
  variant: 'secondary' as const,
  fullWidth: true,
};

export function EntityValuationActions({
  entity,
  actions,
  onAfterUpdate,
}: EntityValuationActionsProps) {
  const { showFeedback } = useFeedback();
  const [resyncEntityValuation] = useResyncEntityValuationMutation({
    variables: {
      entityId: entity.id,
    },
    onCompleted: () => {
      showFeedback('Initiated resync of valuation data', {
        variant: 'success',
      });
      onAfterUpdate();
    },
    onError: (error) => {
      const errorMessage = getUserFacingErrorMessages(error);
      showFeedback(
        `Failed to initiate valuation resync: ${errorMessage.join(' ')}`
      );
    },
  });
  const [isLiabilityModalOpen, setIsLiabilityModalOpen] = useState(false);
  const [editLiabilityId, setEditLiabilityId] = useState<string | null>(null);
  const [isAssetFullScreenModalOpen, setIsAssetFullScreenModalOpen] =
    useState(false);
  const [isUpdateValuationsModalOpen, setIsUpdateValuationsModalOpen] =
    useState(false);
  const [initialEditEntitySection, setInitialEditEntitySection] =
    useState<EditEntitySection | null>(null);
  const { entityId, entitySubtypeId, entityType } = useEntityDetailsContext();
  const { householdId } = useHouseholdDetailsContext();

  const handleAction = useCallback(
    (action: EntityValuationActionDefinition) => {
      const actionKind = action.kind;
      switch (actionKind) {
        case EntityValuationAction.MANAGE_VALUATION:
          if (entity.kind === EntityKind.GratTrust) {
            setIsAssetFullScreenModalOpen(true);
            break;
          }

          setIsUpdateValuationsModalOpen(true);
          break;
        case EntityValuationAction.VIEW_ON_INTEGRATED_PLATFORM:
          window.open(
            `/api/v1/integration/redirect/integration-entity?householdId=${householdId}&integrationEntityId=${action.integrationEntityId}`,
            '_blank'
          );
          break;
        case EntityValuationAction.OPEN_ENTITY_EDIT_MODAL:
          setInitialEditEntitySection(action.initialEditEntitySection);
          break;
        case EntityValuationAction.ADD_LIABILITY:
          setIsLiabilityModalOpen(true);
          break;
        case EntityValuationAction.EDIT_LIABILITY:
          setEditLiabilityId(action.liabilityId);
          setIsLiabilityModalOpen(true);
          break;
        case EntityValuationAction.RESYNC_ENTITY_VALUATION:
          void resyncEntityValuation();
          break;
        default:
          throw new UnreachableError({
            case: actionKind,
            message: `Unhandled action: ${actionKind}`,
          });
      }
    },
    [entity.kind, householdId, resyncEntityValuation]
  );

  function handleCloseModal() {
    setIsUpdateValuationsModalOpen(false);
    onAfterUpdate();
  }

  const actionButton = useMemo(() => {
    return (
      <ButtonWithPopover
        {...buttonProps}
        label="Edit holdings"
        popperVariant="menuBelow"
      >
        {actions.map((action, i) => {
          const icon = (() => {
            if (!action.icon) return null;
            return <action.icon size={18} color={COLORS.GRAY[500]} />;
          })();

          const menuItemProps: Partial<MenuItemProps> = {
            muiMenuItemProps: {
              divider: action.showDivider,
            },
            icon,
          };

          switch (action.kind) {
            case EntityValuationAction.EDIT_LIABILITY:
            // falls through
            case EntityValuationAction.MANAGE_VALUATION:
              return (
                <MenuItem
                  key={i}
                  onClick={() => handleAction(action)}
                  label={action.label}
                  {...menuItemProps}
                />
              );
            case EntityValuationAction.ADD_LIABILITY:
              return (
                <MenuItem
                  key={i}
                  onClick={() => handleAction(action)}
                  label={action.label}
                  {...menuItemProps}
                />
              );
            case EntityValuationAction.OPEN_ENTITY_EDIT_MODAL: {
              return (
                <MenuItem
                  key={i}
                  onClick={() => handleAction(action)}
                  label={action.label}
                  {...menuItemProps}
                />
              );
            }
            case EntityValuationAction.RESYNC_ENTITY_VALUATION: {
              const provider = getEntityAssetsIntegrationProvider(entity);
              if (!provider) return null; // ts
              const providerName =
                ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES[provider];
              return (
                <MenuItem
                  key={i}
                  onClick={() => handleAction(action)}
                  label={`${action.label} from ${providerName}`}
                  {...menuItemProps}
                />
              );
            }
            case EntityValuationAction.VIEW_ON_INTEGRATED_PLATFORM: {
              const provider = getEntityAssetsIntegrationProvider(entity);
              if (!provider) return null; // ts
              const providerName =
                ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES[provider];

              // note that menuItemProps is intentionally not passed here because
              // it will never have a divider and we have special icon handling
              return (
                <MenuItem
                  key={i}
                  icon={<Box width={20} />} // create spacing so this is indented to match other menu items
                  onClick={() => handleAction(action)}
                  iconAfter={icon}
                  label={`${action.name} on ${providerName}`}
                />
              );
            }
          }
        })}
      </ButtonWithPopover>
    );
  }, [actions, entity, handleAction]);

  return (
    <>
      {isLiabilityModalOpen && editLiabilityId === null && (
        <CreateLiabilityModal
          isOpen={isLiabilityModalOpen}
          entityId={entity.id}
          onClose={() => setIsLiabilityModalOpen(false)}
        />
      )}
      {isLiabilityModalOpen && editLiabilityId !== null && (
        <EditLiabilityModal
          liabilityId={editLiabilityId}
          isOpen={isLiabilityModalOpen}
          entityId={entity.id}
          onClose={() => {
            setIsLiabilityModalOpen(false);
            setEditLiabilityId(null);
            onAfterUpdate();
          }}
        />
      )}
      {isUpdateValuationsModalOpen &&
        entitySubtypeId &&
        householdId &&
        entityType &&
        entityId && (
          <FullScreenStructuredAssetsModal
            householdId={householdId}
            entityId={entityId}
            subtypeId={entitySubtypeId}
            entityName={entity.subtype.displayName}
            entityType={entityType}
            isOpen={isUpdateValuationsModalOpen}
            onClose={handleCloseModal}
          />
        )}
      {entityType && initialEditEntitySection && householdId && entityId && (
        <EditEntitySplitScreenModal
          initialSection={initialEditEntitySection}
          entityType={entityType}
          isOpen={!!initialEditEntitySection}
          onClose={() => setInitialEditEntitySection(null)}
          navigateAfterSave={true}
        />
      )}
      {isAssetFullScreenModalOpen && (
        <AssetFullScreenModal
          onClose={() => setIsAssetFullScreenModalOpen(false)}
          headerText="Update valuation"
          submitCopy="Update valuation"
          entityId={entity.id}
          subtypeId={entity.subtype.id}
          householdId={entity.household.id}
          isOpen={isAssetFullScreenModalOpen}
          assetsSubformType="updateHoldings"
        />
      )}
      {actionButton}
    </>
  );
}
