import { useFederalEstateTaxFormatted } from '@/modules/irs/useIRSConstants';

// Add a search param to the URL so we can link to the comparison view
export const COMPARE_VIEW_SEARCH_PARAM = 'compareView';

// assumptions params
export const COMPARE_VIEW_ASSUMPTION_FIRST_DEATH = 'firstDeath';
export const COMPARE_VIEW_ASSUMPTION_SECOND_DEATH = 'secondDeath';
export const COMPARE_VIEW_ASSUMPTION_ASSET_GROWTH = 'assetGrowth';
export const COMPARE_VIEW_ASSUMPTION_EXEMPTION_GROWTH = 'exemptionGrowth';
export const COMPARE_VIEW_ASSUMPTION_EXEMPTION_SUNSET = 'exemptionSunset';

// Add a search param to the URL so we can link and default to the final passing view
export const COMPARE_SELECTED_PASSING_SEARCH_PARAM =
  'comparisonSelectedPassing';

export function useWaterfallComparisonCopy() {
  const rate = useFederalEstateTaxFormatted();
  return {
    WATERFALL_COMPARISON_CALLOUT: `Hypothetical and source waterfall projections are for
    illustrative purposes only. Assumes that assets transferred to
    non-charitable beneficiaries, in excess of the grantor’s
    remaining lifetime exemption will be taxed at a federal estate
    tax rate of ${rate}%. Assumes assets held outside of the grantor’s
    estate are not subject to estate tax. This does not account
    for any potential state-specific transfer tax.`,
    COMPARE_VIEW_SEARCH_PARAM,
    COMPARE_SELECTED_PASSING_SEARCH_PARAM,
  };
}
