import { Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { EntityKind } from '@/types/schema';

import { EntityDetailsDocument } from '../../details/graphql/EntityDetailPage.generated';
import {
  EntitySubtypeOwnershipFragment,
  OwnerInformationFragment,
  useConvertEntityMutation,
  useFetchOwnerInformationQuery,
} from '../../graphql/Entities.generated';
import { ConvertEntityForm } from './ConvertEntityForm';
import {
  ConvertEntityFormShape,
  ConvertEntityHeadingMap,
} from './ConvertEntityModal.constants';
import { getSelectedOwnerIds } from './ConvertEntityModal.utils';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'> & {
  entityId: string;
  entityKind: EntityKind;
};

interface ConvertEntityModalProps {
  entityId: string;
  entityKind: EntityKind;
  isOpen: boolean;
  onClose: () => void;
}

function ConvertEntity({
  onClose,
  heading = 'Convert Entity',
  entityId,
  entityKind,
  ...formModalProps
}: FormModalOptionalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { formRef, handleSave } = useFormSaveHandler();
  const { handleSubmit, reset, formState } =
    useFormContext<ConvertEntityFormShape>();
  const [currentOwners, setCurrentOwners] = useState<string[]>([]);

  const { possibleGrantors } = useHouseholdDetailsContext();
  const fetchOwnerInformation = useFetchOwnerInformationQuery({
    variables: { entityId },
    onError: (error) => {
      reportError('error fetching current owner(s)', error);
    },
    onCompleted: (data) => {
      const subentity = (data.node as OwnerInformationFragment)
        .subtype as EntitySubtypeOwnershipFragment;
      if (subentity?.__typename === 'IndividualPersonalAccount') {
        setCurrentOwners([subentity.owner!.individual!.id]);
      } else if (subentity?.__typename === 'JointPersonalAccount') {
        const owners = subentity.owners!.map((owner) => {
          return owner.individual!.id;
        });
        setCurrentOwners(owners);
      }
    },
  });

  const [convertEntity] = useConvertEntityMutation({
    onError: (error) => {
      reportError(`failed to convert entity`, error);
      showFeedback(
        'Failed to convert this entity. Please refresh the page and try again.'
      );
    },
    refetchQueries: [EntityDetailsDocument],
    onCompleted: () => {
      showFeedback('Successfully converted entity', {
        variant: 'success',
      });
    },
  });

  useEffect(() => {
    fetchOwnerInformation;
  }, [entityId, fetchOwnerInformation]);

  const onSubmit = handleSubmit((formData) => {
    const ownerIds =
      entityKind === EntityKind.IndividualPersonalAccount
        ? getSelectedOwnerIds(formData.owners)
        : [formData.ownerId];

    void convertEntity({
      variables: {
        entityId: entityId,
        ownerIds: ownerIds,
      },
    });
    closeModal();
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  return (
    <FormModal
      {...formModalProps}
      onClose={onClose}
      heading={heading}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.CancelButton onClick={closeModal} />
          <FormModalActions.SaveButton onClick={handleSave}>
            {ConvertEntityHeadingMap[entityKind]}
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <Stack gap={3}>
        <Typography>
          {entityKind === EntityKind.IndividualPersonalAccount
            ? 'Specify owners to associate with this joint account:'
            : 'Specify an owner to associate with this personal account:'}
        </Typography>
        <form ref={formRef} onSubmit={onSubmit} noValidate>
          {entityKind === EntityKind.IndividualPersonalAccount && (
            <ConvertEntityForm
              variant="joint"
              possibleOwners={possibleGrantors!}
              currentOwnerIds={currentOwners}
            />
          )}
          {entityKind === EntityKind.JointPersonalAccount && (
            <ConvertEntityForm
              variant="individual"
              possibleOwners={possibleGrantors!}
              currentOwnerIds={currentOwners}
            />
          )}
        </form>
      </Stack>
    </FormModal>
  );
}

export function ConvertEntityModal({
  entityId,
  entityKind,
  ...props
}: ConvertEntityModalProps) {
  const formMethods = useForm<ConvertEntityFormShape>();

  return (
    <FormProvider {...formMethods}>
      <ConvertEntity
        {...props}
        entityId={entityId}
        entityKind={entityKind}
        heading={ConvertEntityHeadingMap[entityKind]}
      />
    </FormProvider>
  );
}
