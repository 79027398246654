import { useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';

import { Omit } from '@/components/charts/Legend/Legend.stories';
import { TileProps } from '@/components/diagrams/components/Tile';
import { FormConfigurationProvider } from '@/components/form/context/FormConfigurationContext';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { AfterDeath, UpdateGraphVisualizationGroupInput } from '@/types/schema';

import { GraphVizGroupBasicDetailsForm } from '../../graphViz/graphVizGroup/GraphVizGroupBasicDetailsForm';
import { EstateWaterfallSectionMultiSelectTable } from '../components/EstateWaterfallSectionMulitSelectTable/EstateWaterfallSectionMultiSelectTable';
import { OnSelectionChange } from '../components/EstateWaterfallSectionMulitSelectTable/EstateWaterfallSectionMultiSelectTable.utils';
import { useManageNodeGroup } from './useManageNodeGroup';

interface ManageGroupNode {
  id: string;
  heading: string;
  description: string;
  remove?: () => void;
}

export type ManageGroupFormShape = Omit<
  UpdateGraphVisualizationGroupInput,
  'nodeIds'
> & {
  nodes: ManageGroupNode[];
};

interface ManageGroupModalInnerProps {
  isOpen: boolean;
  onClose: () => void;
  groupId: string;
  namespace: AfterDeath;
}

function ManageGroupModalInner({
  isOpen,
  onClose,
  groupId,
  namespace,
}: ManageGroupModalInnerProps) {
  const theme = useTheme();
  const { formRef, handleSave } = useFormSaveHandler();
  const { formState, handleSubmit, setValue, reset } =
    useFormContext<ManageGroupFormShape>();

  const { saveManageNodeGroup } = useManageNodeGroup(groupId);

  const onSubmit = handleSubmit((formData) => {
    return saveManageNodeGroup(formData);
  });

  const handleClose = () => {
    onClose();
  };

  const handleUngroupAll = () => {
    setValue('nodes', []);
    handleSave();
  };

  const onSuccess = () => {
    onClose();
    setTimeout(() => {
      reset();
    }, theme.transitions.duration.leavingScreen);
  };

  useSubmitSuccessHandler(onSuccess);

  const defaultSelectedRowIds = useMemo(() => {
    return (
      formState.defaultValues?.nodes?.flatMap((node) => node?.id ?? []) ?? []
    );
  }, [formState.defaultValues?.nodes]);

  const onSelectionChange = useCallback<OnSelectionChange>(
    ({ selectedRowIds, rows }) => {
      const selectedRows = rows.filter((row) => {
        return selectedRowIds.includes(row.id);
      });
      const newNodes = selectedRows.map((row) => ({
        _id: row.id,
        id: row.id,
        heading: row.entity.lineOne,
        description: row.entity.lineTwo,
      }));

      setValue('nodes', newNodes);
    },
    [setValue]
  );

  return (
    <FormModal
      isOpen={isOpen}
      onClose={handleClose}
      heading="Manage group"
      rightHeaderContent={null}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.DestructiveButton onClick={handleUngroupAll}>
            Ungroup all
          </FormModalActions.DestructiveButton>
          <FormModalActions.CancelButton onClick={handleClose} />
          <FormModalActions.SaveButton onClick={handleSave}>
            Save
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <GraphVizGroupBasicDetailsForm<ManageGroupFormShape>
          variant="update"
          slots={{
            SelectTable: {
              component: EstateWaterfallSectionMultiSelectTable,
              props: {
                namespace,
                defaultSelectedRowIds,
                onSelectionChange,
              },
            },
          }}
        />
      </form>
    </FormModal>
  );
}

interface ManageGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  namespace: AfterDeath;
  groupedTiles: (TileProps & { tileId: string; nodeId: string })[];
  groupName?: string | null;
  groupDescription?: string | null;
  groupId: string;
}

export function ManageGroupModal({
  isOpen,
  onClose,
  namespace,
  groupedTiles,
  groupName,
  groupDescription,
  groupId,
}: ManageGroupModalProps) {
  const formMethods = useForm<ManageGroupFormShape>({
    defaultValues: {
      displayName: groupName,
      description: groupDescription,
      namespace,
      nodes: groupedTiles.map((tile) => ({
        _id: tile.tileId,
        id: tile.nodeId,
        heading: tile.lineOne,
        description: tile.lineTwo,
      })),
    },
  });

  const { fields, remove } = useFieldArray({
    control: formMethods.control,
    name: 'nodes',
    keyName: '_id',
  });

  fields.forEach((field, index) => {
    field.remove = () => remove(index);
  });

  return (
    <FormProvider {...formMethods}>
      <FormConfigurationProvider
        value={{ optionalDisplayType: 'optional-label' }}
      >
        <ManageGroupModalInner
          isOpen={isOpen}
          onClose={onClose}
          groupId={groupId}
          namespace={namespace}
        />
      </FormConfigurationProvider>
    </FormProvider>
  );
}
