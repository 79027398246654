import { useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { ModelANewStrategyModal } from '@/modules/common/CreateNewEntityOrStrategy/ModelANewStrategyModal';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

export function ModelProposalButton() {
  const { householdId } = useHouseholdDetailsContext();

  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <Button
        variant="primary"
        size="sm"
        onClick={() => setIsModalOpen(true)}
        startIcon={PlusIcon}
        iconProps={{ size: 20 }}
        disabled={!householdId}
      >
        Create analysis
      </Button>
      {isModalOpen && householdId && (
        <ModelANewStrategyModal
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          householdId={householdId}
        />
      )}
    </>
  );
}
