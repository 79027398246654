import { DataTable_LEGACYCard } from '@/components/tables/DataTable_LEGACY/DataTable_LEGACYCard';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import {
  TasksTable,
  TaskTableColumns,
} from '@/modules/tasks/components/TasksTable/TasksTable';
import { ROUTE_KEYS } from '@/navigation/constants';
import { EntityTaskWhereInput } from '@/types/schema';

export function ClientDetailsTasksPage() {
  const householdId = useRequiredParam('householdId');
  const tasksFilter: EntityTaskWhereInput = {
    hasEntityWith: [
      {
        hasHouseholdWith: [
          {
            id: householdId,
          },
        ],
      },
    ],
  };

  return (
    <DataTable_LEGACYCard>
      <TasksTable
        filter={tasksFilter}
        columns={[
          TaskTableColumns.DUE_AT,
          TaskTableColumns.TASK_NAME,
          TaskTableColumns.ENTITY_NAME,
          TaskTableColumns.ASSIGNEE,
        ]}
        from={ROUTE_KEYS.HOUSEHOLD_DETAILS_TASKS}
      />
    </DataTable_LEGACYCard>
  );
}
