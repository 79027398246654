import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';

import { EntityDetailsData } from '../entityDetails.types';
import {
  getGrantorNameFromFragment,
  getSharedSummaryProperties,
} from './trustCardUtils';

export function getSLATTrustSummaryProperties({
  entity,
}: EntityDetailsData): TrustSummaryData {
  if (entity.subtype.__typename !== 'SLATTrust') {
    throw new Error('Invalid trust type');
  }

  const summary = {
    ...getSharedSummaryProperties(entity),
    beneficiariesGroups: [
      {
        heading: undefined,
        items: entity.subtype.lifetimeBeneficiaries || [],
        noItemsText: 'No lifetime beneficiaries',
      },
    ],
    grantorNames: entity.subtype.SLATTrustGrantor
      ? [getGrantorNameFromFragment(entity.subtype.SLATTrustGrantor)]
      : [],
    effectiveDate: entity.subtype.effectiveDate ?? undefined,
    trustDetailsItems: [],
  };

  return summary;
}
