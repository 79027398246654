import { InputOption } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { TenantApplicationConfigurationUserSessionInactivityTimeout } from '@/types/schema';
import { PathsOf } from '@/types/subform';

export interface TenantConfigurationFormShape {
  disableAllAIFeatures: boolean;
  sessionInactivityTimeout: TenantApplicationConfigurationUserSessionInactivityTimeout;
}

export type TenantConfigurationFormPath = PathsOf<TenantConfigurationFormShape>;

export const SESSION_INACTIVITY_TIMEOUT_OPTIONS: InputOption[] = [
  {
    value: TenantApplicationConfigurationUserSessionInactivityTimeout.Off,
    display: 'Off',
  },
  {
    value:
      TenantApplicationConfigurationUserSessionInactivityTimeout.FifteenMinutes,
    display: '15 minutes',
  },
  {
    value: TenantApplicationConfigurationUserSessionInactivityTimeout.OneHour,
    display: '60 minutes',
  },
];
