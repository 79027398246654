import { Box, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';

import { FlowChartProvider } from '@/components/diagrams/FlowChart';
import { EstateWaterfall } from '@/modules/estateWaterfall';
import { EstateWaterfallProvider } from '@/modules/estateWaterfall/contexts/EstateWaterfall.provider';
import { useQueryWaterfall } from '@/modules/estateWaterfall/hooks';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';

import { ClientPresentationDesigner_EstateWaterfallFragment } from '../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { PresentationSlideSecondaryHeading } from '../components/PresentationSlideSecondaryHeading';
import { PresentationSlideRegistrationDetails } from '../entities/entityPresentations.types';
import { PRESENTATION_SPACING_UNITS } from '../presentation.constants';
import { PresentationSlide } from '../PresentationSlide';
import { DisclaimerCallout } from './DisclaimerCallout';
import { GrowthAssumptions } from './GrowthAssumptions';

const MIN_HEIGHT = 500;

export interface EstateWaterfallDiagramSlideProps
  extends PresentationSlideRegistrationDetails {
  waterfall: ClientPresentationDesigner_EstateWaterfallFragment;
}
export function EstateWaterfallDiagramSlide({
  waterfall,
  ...registrationProps
}: EstateWaterfallDiagramSlideProps) {
  const waterfallId = waterfall.id;
  const householdId = waterfall.household?.id;

  // We need to wait for the waterfall to be rendered completely before
  // scaling the container because the waterfall does all its calculations
  // based on the unscaled container size.
  const [waterfallReady, setWaterfallReady] = useState(false);

  useRegisterSlide({
    displayName: 'Diagram',
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  const registerWaterfallReady = useCallback(() => {
    setWaterfallReady(true);
  }, []);

  const {
    waterfall: contextWaterfall, // ick, this shouldn't be making its own query
    primaryClients,
    isFilteredWaterfall,
    isEmptyWaterfall,
    visibleNodeIds,
    hiddenNodeIds,
    initWaterfallNodeIds,
  } = useQueryWaterfall({ waterfallId });

  if (!householdId) return null;

  return (
    <PresentationSlide.Slide
      id={registrationProps.slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading heading={waterfall.displayName} />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={waterfall.household?.displayName ?? null}
        />
      }
      footer={<PresentationSlide.Footer />}
      readyToScale={waterfallReady}
      data-presentationwaterfallready={waterfallReady.toString()}
    >
      <Stack
        spacing={3}
        justifyContent="space-between"
        py={PRESENTATION_SPACING_UNITS}
        height="100%"
      >
        <Stack flexGrow={1}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            px={PRESENTATION_SPACING_UNITS}
          >
            <GrowthAssumptions
              slideTitle="Waterfall diagram"
              waterfall={waterfall}
            />
          </Stack>
          {contextWaterfall && primaryClients && (
            <FlowChartProvider>
              <EstateWaterfallProvider
                waterfall={contextWaterfall}
                primaryClients={primaryClients}
                isFilteredWaterfall={isFilteredWaterfall}
                isEmptyWaterfall={isEmptyWaterfall}
                visibleNodeIds={visibleNodeIds}
                hiddenNodeIds={hiddenNodeIds}
                initWaterfallNodeIds={initWaterfallNodeIds}
                presentationMode
                registerWaterfallReady={registerWaterfallReady}
              >
                <Stack minHeight={MIN_HEIGHT} height="100%" flexGrow={1}>
                  <AutoSizer>
                    {({ height, width }: { height: number; width: number }) => (
                      <EstateWaterfall
                        householdId={householdId}
                        waterfallId={waterfallId}
                        height={Math.max(MIN_HEIGHT, height)}
                        width={width}
                        // Key on waterfallId so all the state is reset when the waterfallId changes
                        key={waterfallId}
                      />
                    )}
                  </AutoSizer>
                </Stack>
              </EstateWaterfallProvider>
            </FlowChartProvider>
          )}
        </Stack>
        <Box px={PRESENTATION_SPACING_UNITS}>
          <DisclaimerCallout waterfall={waterfall} />
        </Box>
      </Stack>
    </PresentationSlide.Slide>
  );
}
