import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Path, UseFormGetValues, UseFormResetField } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { EntityInEstateStatus, StateCode } from '@/types/schema';

import { TAX_STATUS_SUBFORM } from '../TaxStatusSubform.constants';
import { Fields } from '../TaxStatusSubform.types';
import { useTaxStatusAssetLocationOptions } from './useTaxStatusAssetLocationOptions';

const allStates = Object.values(StateCode);

/*******************
 * @start RHF typing
 * @description resetField does not work on when using StateCode enum as the key, so pretend the key is AK
 */
interface ExplicitStateTaxFormShape {
  taxStatusSubform?: {
    stateTax: {
      AK: {
        inEstateStatus: EntityInEstateStatus | null;
      };
    };
  };
}

type ResetField = UseFormResetField<ExplicitStateTaxFormShape>;
type GetValues = UseFormGetValues<ExplicitStateTaxFormShape>;

type ResetFieldName = 'taxStatusSubform.stateTax.AK.inEstateStatus';
/******************* @end RHF typing */

type StateTaxOptions = ReturnType<
  typeof useTaxStatusAssetLocationOptions
>['stateTaxOptions'];

interface UseDefaultTaxStatusValuesParams {
  stateTaxOptions: StateTaxOptions;
}

export function useDefaultTaxStatusValues({
  stateTaxOptions,
}: UseDefaultTaxStatusValuesParams) {
  const { resetField, getValues } = useFormContext<Fields>();

  // Make sure the form has registered the dynamic fields before setting their values
  useEffect(() => {
    // Note, the useDefaultEstateInclusionStatus hook has simliar logic to determine the available state estate taxes.
    if (isEmpty(stateTaxOptions)) {
      return;
    }

    clearHiddenStateTaxFields({ resetField, stateTaxOptions });
    initializeVisibleStateTaxFields({ resetField, getValues, stateTaxOptions });
  }, [getValues, resetField, stateTaxOptions]);
}

function clearHiddenStateTaxFields({
  resetField,
  stateTaxOptions,
}: {
  resetField: ResetField;
  stateTaxOptions: StateTaxOptions;
}) {
  // Clear all state tax fields that are not in the current state tax options
  const fieldNamesToClear = allStates.flatMap((s) => {
    // Do not clear the field if it is in the state tax options
    if (stateTaxOptions.some((o) => o.label === s)) {
      return [];
    }

    return `${TAX_STATUS_SUBFORM}.stateTax.${s}.inEstateStatus` as const satisfies Path<Fields>;
  });

  fieldNamesToClear.forEach((realFieldName) => {
    const fieldName = realFieldName as ResetFieldName;

    resetField(fieldName, {
      defaultValue: null,
    });
  });
}

function initializeVisibleStateTaxFields({
  resetField,
  getValues,
  stateTaxOptions,
}: {
  resetField: ResetField;
  getValues: GetValues;
  stateTaxOptions: StateTaxOptions;
}) {
  const fieldNamesOfCurrentOptions = stateTaxOptions.map((o) => {
    return `${TAX_STATUS_SUBFORM}.stateTax.${o.label}.inEstateStatus` as const satisfies Path<Fields>;
  });

  // Initialize anything that does not have a value
  fieldNamesOfCurrentOptions.forEach((realFieldName) => {
    const fieldName = realFieldName as ResetFieldName;
    const fieldValue = getValues(fieldName);
    const hasValue = fieldValue !== null;

    if (hasValue) {
      return;
    }

    resetField(fieldName, {
      defaultValue: EntityInEstateStatus.InEstate, // Default to InEstate
    });
  });
}
