import {
  PRINT_STATUS_SENTINEL_ID,
  PrintStatuses,
} from '../printOnly.constants';

export interface PrintStatusSentinelProps {
  status: PrintStatuses;
}

/**
 * This component is used to indicate the status of the document to the PDF printing service, so it knows to delay until everything is ready. If you don't use this
 * on a page that you expect to be printed, the PDF printing service will always time out because it's expecting an element with the sentinel ID to be present and have
 * a data-status attribute that matches one of the PrintStatuses.
 */
export function PrintStatusSentinel({ status }: PrintStatusSentinelProps) {
  return (
    <div
      // position is fixed so that it doesn't get considered as an element for the underlying pageBreak logic
      style={{ visibility: 'hidden', position: 'fixed', height: 0, width: 0 }}
      id={PRINT_STATUS_SENTINEL_ID}
      data-status={status}
    />
  );
}
