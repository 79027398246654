// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateProposalMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateProposalInput;
}>;


export type UpdateProposalMutation = { __typename?: 'Mutation', updateProposalV2: { __typename?: 'Proposal', id: string } };


export const UpdateProposalDocument = gql`
    mutation UpdateProposal($input: AugmentedUpdateProposalInput!) {
  updateProposalV2(input: $input) {
    id
  }
}
    `;
export type UpdateProposalMutationFn = Apollo.MutationFunction<UpdateProposalMutation, UpdateProposalMutationVariables>;

/**
 * __useUpdateProposalMutation__
 *
 * To run a mutation, you first call `useUpdateProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProposalMutation, { data, loading, error }] = useUpdateProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProposalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProposalMutation, UpdateProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProposalMutation, UpdateProposalMutationVariables>(UpdateProposalDocument, options);
      }
export type UpdateProposalMutationHookResult = ReturnType<typeof useUpdateProposalMutation>;
export type UpdateProposalMutationResult = Apollo.MutationResult<UpdateProposalMutation>;
export type UpdateProposalMutationOptions = Apollo.BaseMutationOptions<UpdateProposalMutation, UpdateProposalMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateProposal: 'UpdateProposal' as const
  }
}