import { Box, InputAdornment } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { SelectInput } from '@/components/form/baseInputs/SelectInput';
import { Briefcase02Icon } from '@/components/icons/Briefcase02Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useSubBrandOptions } from '@/modules/admin/branding/hooks/useSubBrandOptions';
import { getNodes } from '@/utils/graphqlUtils';

import { AdminBrandingForm } from './AdminBrandingForm';
import { defaultFormValues } from './AdminBrandingForm.constants';
import { FirmBrandingFormShape } from './AdminBrandingForm.types';
import {
  useTenantBrandingQuery,
  useUpdateBrandingMutation,
  useUpdateSubBrandBrandingMutation,
} from './graphql/AdminBrandingPage.generated';

export function AdminBrandingPage() {
  const [selectedActiveBrand, setSelectedActiveBrand] = useState<string>('');
  const { createErrorFeedback, createSuccessFeedback } = useFeedback();
  const { data } = useTenantBrandingQuery({
    onError: createErrorFeedback('Failed to load branding information.'),
  });
  const onError = createErrorFeedback(
    'Failed to save branding update. Please refresh the page and try again.'
  );
  const onCompleted = createSuccessFeedback(
    'Successfully updated branding information.'
  );
  const [updateTenantBranding] = useUpdateBrandingMutation({
    onError,
    onCompleted,
  });

  const [updateSubBrandBranding] = useUpdateSubBrandBrandingMutation({
    onError,
    onCompleted,
  });

  const formMethods = useForm<FirmBrandingFormShape>({
    defaultValues: defaultFormValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const subBrands = useMemo(() => getNodes(data?.subBrands), [data?.subBrands]);
  const tenantBranding = useMemo(() => {
    if (!data) return null;
    if (!selectedActiveBrand) {
      return data.tenantInformation.branding ?? null;
    }

    const brand = subBrands.find(
      (subBrand) => subBrand.id === selectedActiveBrand
    );
    return brand ?? data.tenantInformation.branding ?? null;
  }, [data, selectedActiveBrand, subBrands]);

  useEffect(() => {
    if (!tenantBranding) return;

    reset({
      primaryColor:
        tenantBranding.primaryColor || defaultFormValues.primaryColor,
      secondaryColor:
        tenantBranding.secondaryColor || defaultFormValues.secondaryColor,
      dataVisualizationPrimaryColor:
        tenantBranding.dataVisualizationPrimaryColor ||
        defaultFormValues.dataVisualizationPrimaryColor,
      dataVisualizationSecondaryColor:
        tenantBranding.dataVisualizationSecondaryColor ||
        defaultFormValues.dataVisualizationSecondaryColor,

      dataVisualizationTertiaryColor:
        tenantBranding.dataVisualizationTertiaryColor ||
        defaultFormValues.dataVisualizationTertiaryColor,
      dataVisualizationNegativeColor:
        tenantBranding.dataVisualizationNegativeColor ||
        defaultFormValues.dataVisualizationNegativeColor,
      buttonsLinkColor:
        tenantBranding.buttonsLinkColor || defaultFormValues.buttonsLinkColor,
    });
  }, [data, reset, selectedActiveBrand, subBrands, tenantBranding]);

  const subBrandOptions = useSubBrandOptions(subBrands);

  const onSubmit = async (formData: FirmBrandingFormShape) => {
    if (!data) {
      throw new Error('Invalid submission state');
    }

    if (selectedActiveBrand) {
      return updateSubBrandBranding({
        variables: {
          input: {
            id: selectedActiveBrand,
            update: formData,
          },
        },
      });
    }

    const tenantBrandingId = data.tenantInformation.branding.id;

    return updateTenantBranding({
      variables: {
        id: tenantBrandingId,
        input: formData,
      },
    });
  };

  return (
    <SubpageLayout
      heading="Branding"
      actions={
        subBrandOptions.length > 0 && (
          <Box width={250}>
            <SelectInput
              disabled={isSubmitting}
              options={subBrandOptions}
              showEmptyValue={false}
              startAdornment={
                <InputAdornment
                  sx={{ backgroundColor: 'white' }}
                  position="start"
                >
                  <Briefcase02Icon size={20} />
                </InputAdornment>
              }
              value={selectedActiveBrand}
              onChange={(e) => setSelectedActiveBrand(e.target.value)}
              label="Active brand"
              hideLabel
            />
          </Box>
        )
      }
    >
      <FormProvider {...formMethods}>
        <AdminBrandingForm
          onSubmit={handleSubmit(onSubmit)}
          tenantBranding={tenantBranding}
        />
      </FormProvider>
    </SubpageLayout>
  );
}
