import { compact, difference, isEmpty } from 'lodash';

import { getTypeOrUndefined } from '@/modules/entities/EntitySubforms/utils/shared/common.utils';
import { StructuredAssetsSubform_ValuationFragment } from '@/modules/entities/StructuredAssetsSubform/graphql/StructuredAssetsSubform.generated';
import {
  getFormAssetsFromAssets,
  getIntegratedAssetsFromIntegratedValuation,
  valuationHasAdditionalManualAssets,
} from '@/modules/entities/StructuredAssetsSubform/StructuredAssetsSubform.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import { makeEntityTypeUniversalUpdateInput } from '@/modules/entities/utils/makeEntityTypeUniversalUpdateInput';
import {
  AssetV2IntegrationType,
  AssetValuationV2ValuationSource,
  AugmentedCreateAssetValuationV2Input,
  AugmentedUpdateEntityInput,
  UpdateEntityInput,
} from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { AssetValueV2OwnershipType } from '../AssetValueOwnershipTypes';
import { FullScreenStructuredAssetsModalForm } from './FullScreenStructuredAssetsModal.types';

export function getIntegratedAssetsFromValuation(
  valuation: StructuredAssetsSubform_ValuationFragment | null
) {
  return getNodes(valuation?.assets).filter((a) => Boolean(a.integrationType));
}

function getAssetIntegrationUpdateProperties(
  formValues: FullScreenStructuredAssetsModalForm
): Partial<UpdateEntityInput> {
  const nextIntegrationEntityIds = compact(formValues.integrationEntityIds);
  const previousIntegrationEntityIds = compact(
    formValues._previousIntegrationEntityIds
  );
  const linkedToNonGrantorEntity = getTypeOrUndefined<boolean>(
    formValues.linkToAllAddeparEntities
  );

  const addedIntegrationEntityIds = difference(
    nextIntegrationEntityIds,
    previousIntegrationEntityIds
  );
  const removedIntegrationEntityIds = difference(
    previousIntegrationEntityIds,
    nextIntegrationEntityIds
  );

  const removalProperties = {
    removeIntegrationEntityIDs: removedIntegrationEntityIds,
  };

  if (!isEmpty(nextIntegrationEntityIds)) {
    return {
      addIntegrationEntityIDs: addedIntegrationEntityIds,
      ...removalProperties,
      addeparLinkedToNongrantorEntity: linkedToNonGrantorEntity,
    };
  }

  return {
    // don't use `clearIntegrationEntities` here because it removes *all* integration entities
    // and we don't want to remove any potential CSV_IMPORT integration entities
    ...removalProperties,
    clearAddeparLinkedToNongrantorEntity: true,
  };
}
/**
 * Because the date selected in the form is just going to be a date, and not a timestamp,
 * we copy over the current time to make sure that the valuation date is *right now*.
 *
 * This solves the following issue:
 * 1. User hooks up the entity to addepar at e.g. 11:12am ET on 5/1/2024.
 *    This means that a new valuation is then created with an effective date of ~11:12am ET on 5/1/2024 when
 *    the sync completes.
 * 2. Then, the user goes back and unlinks the entity and creates a manual valuation. The date in the form is still
 *    set to 5/1/2024, but because we're not setting a specific time, it defaults to midnight of the selected date, which is
 *    actually earlier than the sync completion time from step 1, meaning that the step 1 valuation is still the most recent one.
 *
 * This could still cause some issue in the case where the user manually sets a valuation date in the future, but that seems
 * much less likely, and this should result in more expected behavior in the common cases.
 */
function getDateWithCurrentTime(date: Date) {
  // don't mutate the input date
  const nextDate = new Date(date);
  const currentTime = new Date();
  nextDate.setUTCHours(
    currentTime.getUTCHours(),
    currentTime.getUTCMinutes(),
    currentTime.getUTCSeconds(),
    currentTime.getUTCMilliseconds()
  );
  return nextDate;
}

function getValuationSourceFromIntegrationType(
  integrationType: AssetV2IntegrationType | null
) {
  switch (integrationType) {
    case AssetV2IntegrationType.Addepar:
      return AssetValuationV2ValuationSource.Addepar;
    case AssetV2IntegrationType.BlackDiamond:
      return AssetValuationV2ValuationSource.BlackDiamond;
    default:
      return AssetValuationV2ValuationSource.Manual;
  }
}

export interface CreateUpdateEntityAccountInputParams {
  integrationType: AssetV2IntegrationType | null;
  previousValuation: StructuredAssetsSubform_ValuationFragment | null;
  entityId: string;
  entityType: EntityType;
  subtypeId: string;
}

export function createUpdateEntityAccountInput(
  formValues: FullScreenStructuredAssetsModalForm,
  params: CreateUpdateEntityAccountInputParams
): AugmentedUpdateEntityInput {
  if (!formValues.structuredAssetsSubform.accountId) {
    throw new Error('Missing required accountId');
  }

  const integrationUpdateProperties =
    getAssetIntegrationUpdateProperties(formValues);
  const isLinkedToIntegration = !isEmpty(formValues.integrationEntityIds);

  if (!formValues.valuationAsOfDate) {
    throw new Error('Missing required valuationAsOfDate');
  }

  if (isLinkedToIntegration) {
    const previousValuationHadManualAssets = valuationHasAdditionalManualAssets(
      params.previousValuation
    );

    const nextEffectiveDate = getDateWithCurrentTime(
      formValues.valuationAsOfDate
    );
    const createNewValuationInput: AugmentedCreateAssetValuationV2Input | null =
      (() => {
        // this is the base case. we're not attemping to add additional manual assets, and the previous
        // valuation didn't have any so we don't need to worry about clearing them out.
        if (
          !formValues.allowAdditionalManualAssets &&
          !previousValuationHadManualAssets
        ) {
          return null;
        }

        const integratedAssets = getIntegratedAssetsFromIntegratedValuation(
          params.previousValuation
        );
        const assetsToAdd = [
          ...getFormAssetsFromAssets(integratedAssets, params.integrationType),
          // don't add additional manual assets if we're not allowing them
          ...(formValues.allowAdditionalManualAssets
            ? formValues.structuredAssetsSubform.assets
            : []),
        ];

        return {
          create: {
            effectiveDate: nextEffectiveDate,
            valuationSource: getValuationSourceFromIntegrationType(
              params.integrationType
            ),
          },
          withAssets: assetsToAdd.map((a) => {
            return {
              create: {
                classID: a.categoryId,
                displayName: a.title,
                integrationType: a.integrationType,
              },
              withAssetValue: {
                create: {
                  ownedValue: a.value,
                  ownershipType: AssetValueV2OwnershipType.ValueBased,
                },
              },
            };
          }),
        };
      })();

    return makeEntityTypeUniversalUpdateInput(
      params.entityId,
      params.entityType,
      {
        id: params.subtypeId,
        updateDesignerAccount: {
          id: formValues.structuredAssetsSubform.accountId,
          update: {},
          withValuations: createNewValuationInput
            ? [createNewValuationInput]
            : undefined,
        },
      },
      integrationUpdateProperties
    );
  }

  const valuationAsOfDate = getDateWithCurrentTime(
    formValues.valuationAsOfDate
  );
  const updateEntityAccountInput: AugmentedUpdateEntityInput =
    makeEntityTypeUniversalUpdateInput(
      params.entityId,
      params.entityType,
      {
        id: params.subtypeId,
        updateDesignerAccount: {
          id: formValues.structuredAssetsSubform.accountId,
          update: {},
          // if linked to an integration, don't allow adding new valuations manually
          // this isn't generally an issue, but can be if moving *from* a manual valuation
          // scenario to an integrated valuatino scenario
          withValuations: [
            {
              create: {
                effectiveDate: valuationAsOfDate,
                description: formValues.structuredAssetsSubform.description,
                valuationSource: AssetValuationV2ValuationSource.Manual,
                documentIDs: formValues.structuredAssetsSubform.documentIds,
              },
              withAssets: formValues.structuredAssetsSubform.assets.map((a) => {
                return {
                  create: {
                    classID: a.categoryId,
                    displayName: a.title,
                  },
                  withAssetValue: {
                    create: {
                      ownedValue: a.value,
                      ownershipType: AssetValueV2OwnershipType.ValueBased,
                    },
                  },
                };
              }),
            },
          ],
        },
      },
      integrationUpdateProperties
    );

  return updateEntityAccountInput;
}
