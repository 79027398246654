import Decimal from 'decimal.js';

import { RollingType, TermsSubformType } from '../types';
import { GRATTerm } from '../ui/utils';

export const defaultValues: TermsSubformType = {
  type: RollingType.STANDARD,
  termLength: '' as GRATTerm,
  // if the user chooses a GRAT of 10+ years, we use this field.
  termLengthExtended: '',
  rollingPeriod: '',
  rate7520: new Decimal(0),
};
