import { Box, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import Decimal from 'decimal.js';
import { intersection } from 'lodash';
import { useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { SetRequired } from 'type-fest';

import { Accordion } from '@/components/Accordion/Accordion';
import { Button } from '@/components/form/baseInputs/Button';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { formatPercent } from '@/utils/formatting/percent';

import { DEFAULT_ASSUMPTIONS } from '../../EstateWaterfallAssumptions/constants';
import {
  AssetGrowthRateField,
  CustomGlobalGrowthRateToggle,
  ExemptionGrowthRateField,
  ExemptionSunsetField,
  FirstGrantorDeathField,
  SecondGrantorDeathField,
} from '../../EstateWaterfallAssumptions/EstateWaterfallAssumptionFormFields';
import { getAssumptionsPreviewCopy } from '../../EstateWaterfallAssumptions/EstateWaterfallAssumptionsUpdater.utils';
import { EstateWaterfallAssumptionFields } from '../../EstateWaterfallAssumptions/types';
import { EstateWaterfallComparisonTrowserFormShape } from '../EstateWaterfallComparisonTrowser.type';
import { useAccordionCalloutCopy } from '../hooks/useAccordionCalloutCopy';

export interface EstateWaterfallComparisonTrowserAssumptionsInnerProps {
  disabled: boolean;
  firstWaterfallId: string | undefined; // necessary to get household details for tooltip
  onClickSubmit: () => void;
}

function EstateWaterfallComparisonTrowserAssumptionsInner({
  disabled,
  firstWaterfallId,
  onClickSubmit,
}: EstateWaterfallComparisonTrowserAssumptionsInnerProps) {
  const customGrowthRatesEnabled = useFeatureFlag('custom_growth_rates');
  const { control, reset } = useFormContext<EstateWaterfallAssumptionFields>();
  const [
    assetGrowthReturn,
    exemptionGrowthRate,
    willExemptionSunset,
    useCustomGlobalGrowthRate,
  ] = useWatch({
    control,
    name: [
      'assetGrowthReturn',
      'exemptionGrowthRate',
      'willExemptionSunset',
      'useCustomGlobalGrowthRate',
    ],
  });

  const { ILLUSTRATIVE_PURPOSES_ONLY } =
    useAccordionCalloutCopy(firstWaterfallId);
  const isCustomGrowthRatesEnabled = isFeatureFlagEnabled(
    'custom_growth_rates'
  );
  const assumptionsCopy = useMemo<string>(() => {
    if (isCustomGrowthRatesEnabled) {
      // this shouldn't happen, satisfy ts
      if (!exemptionGrowthRate || !assetGrowthReturn) return '';
      return getAssumptionsPreviewCopy({
        exemptionGrowthRate,
        assetGrowthReturn,
        willExemptionSunset,
        useCustomGlobalGrowthRate,
      });
    }

    const formValuesArr = [
      assetGrowthReturn,
      exemptionGrowthRate,
      willExemptionSunset,
      useCustomGlobalGrowthRate,
    ];

    if (formValuesArr.some((value) => value === undefined)) {
      return '';
    }

    const formValues = {
      assetGrowthReturn:
        assetGrowthReturn ?? DEFAULT_ASSUMPTIONS.assetGrowthReturn!,
      exemptionGrowthRate:
        exemptionGrowthRate ?? DEFAULT_ASSUMPTIONS.exemptionGrowthRate!,
      willExemptionSunset:
        willExemptionSunset ?? DEFAULT_ASSUMPTIONS.willExemptionSunset!,
      useCustomGlobalGrowthRate:
        useCustomGlobalGrowthRate ??
        DEFAULT_ASSUMPTIONS.useCustomGlobalGrowthRate!,
    } as const satisfies SetRequired<
      typeof DEFAULT_ASSUMPTIONS,
      | 'assetGrowthReturn'
      | 'exemptionGrowthRate'
      | 'willExemptionSunset'
      | 'useCustomGlobalGrowthRate'
    >;

    const isDefaultAssumptions = intersection(
      Object.keys(DEFAULT_ASSUMPTIONS),
      Object.keys(formValues)
    ).every((key) => {
      const defaultValue =
        DEFAULT_ASSUMPTIONS[key as keyof typeof DEFAULT_ASSUMPTIONS];
      const formValue = formValues[key as keyof typeof formValues];

      if (Decimal.isDecimal(defaultValue) && Decimal.isDecimal(formValue)) {
        return defaultValue.eq(formValue);
      }

      return defaultValue === formValue;
    });

    const defaultOrCustomPrefix = isDefaultAssumptions ? 'Default' : 'Custom';

    // TODO BEFORE MERGE: figure out what to do for custom growth rates
    return `${defaultOrCustomPrefix}: ${formatPercent(formValues.assetGrowthReturn, 2)}% asset growth and ${formatPercent(formValues.exemptionGrowthRate, 2)}% exemption growth ${formValues.willExemptionSunset ? '(exemption sunsets in 2025)' : '(exemption does not sunset in 2025)'}`;
  }, [
    assetGrowthReturn,
    exemptionGrowthRate,
    isCustomGrowthRatesEnabled,
    useCustomGlobalGrowthRate,
    willExemptionSunset,
  ]);

  const isLessThanMedium = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const numInputs = isTwoClientHousehold ? 4 : 3;

  const renderedAssumptionsInputs = !customGrowthRatesEnabled ? (
    <Stack spacing={2} data-testid="EstateWaterfallAssumptionsInputs">
      <Box
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateColumns: isLessThanMedium
            ? 'repeat(2, 1fr)'
            : `repeat(${numInputs}, 1fr)`,
          columnGap: 3,
        }}
      >
        <AssetGrowthRateField disabled={disabled} />
        <ExemptionGrowthRateField disabled={disabled} />
        <FirstGrantorDeathField disabled={disabled} />
        {isTwoClientHousehold && (
          <SecondGrantorDeathField disabled={disabled} />
        )}
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <ExemptionSunsetField disabled={disabled} />
        <Stack direction="row" spacing={2}>
          <Button
            variant="transparent"
            disabled={disabled}
            size="sm"
            onClick={() => {
              reset(DEFAULT_ASSUMPTIONS, { keepDefaultValues: false });
              onClickSubmit();
            }}
          >
            Reset assumptions
          </Button>
          <Button
            variant="primary"
            disabled={disabled}
            size="sm"
            onClick={() => onClickSubmit()}
          >
            Update comparison
          </Button>
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <Stack spacing={2}>
      <Box
        sx={{
          display: 'grid',
          gap: 1,
          gridTemplateColumns: isLessThanMedium
            ? 'repeat(2, 1fr)'
            : `repeat(${numInputs}, 1fr)`,
          columnGap: 3,
        }}
      >
        <Stack spacing={1}>
          <FirstGrantorDeathField disabled={disabled} />
          {isTwoClientHousehold && (
            <SecondGrantorDeathField disabled={disabled} />
          )}
        </Stack>
        <Stack spacing={1}>
          <ExemptionGrowthRateField disabled={disabled} />
          <ExemptionSunsetField disabled={disabled} />
        </Stack>
      </Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <CustomGlobalGrowthRateToggle disabled={disabled} />
        <Stack direction="row" spacing={2}>
          <Button
            variant="transparent"
            disabled={disabled}
            size="sm"
            onClick={() => {
              reset(DEFAULT_ASSUMPTIONS, { keepDefaultValues: false });
              onClickSubmit();
            }}
          >
            Reset assumptions
          </Button>
          <Button
            variant="primary"
            disabled={disabled}
            size="sm"
            onClick={() => onClickSubmit()}
          >
            Update comparison
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );

  return (
    <Accordion
      variant="filled"
      data-testid="EstateWaterfallAssumptionsAccordion"
      title={
        <Stack flexDirection="row">
          Manage assumptions
          <ContextualHelpTooltip>
            <PopperContent body={ILLUSTRATIVE_PURPOSES_ONLY} />
          </ContextualHelpTooltip>
        </Stack>
      }
      subtitle={
        <Stack pl={1} justifyContent="center">
          <Typography variant="subtitle2">{assumptionsCopy}</Typography>
        </Stack>
      }
      hideSubtitleOnOpen
      labelContainerProps={{ direction: 'row' }}
    >
      {renderedAssumptionsInputs}
    </Accordion>
  );
}

export interface EstateWaterfallComparisonTrowserAssumptionsProps
  extends Omit<
    EstateWaterfallComparisonTrowserAssumptionsInnerProps,
    'onClickSubmit'
  > {
  onSubmit: (formValues: EstateWaterfallAssumptionFields) => void;
}

export function EstateWaterfallComparisonTrowserAssumptions({
  disabled,
  firstWaterfallId,
  onSubmit: onSubmitExternal,
}: EstateWaterfallComparisonTrowserAssumptionsProps) {
  const { control } =
    useFormContext<EstateWaterfallComparisonTrowserFormShape>();
  const [legacyAssumptions] = useWatch({
    control,
    name: ['legacyAssumptions'],
  });

  const formMethods = useForm<EstateWaterfallAssumptionFields>({
    defaultValues: legacyAssumptions || {},
  });

  const { handleSubmit } = formMethods;

  const onSubmit = handleSubmit((formValues) => {
    onSubmitExternal(formValues);
  });

  return (
    <FormProvider {...formMethods}>
      <EstateWaterfallComparisonTrowserAssumptionsInner
        disabled={disabled}
        firstWaterfallId={firstWaterfallId}
        onClickSubmit={onSubmit}
      />
    </FormProvider>
  );
}
