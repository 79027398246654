import { compact } from 'lodash';

import { TestamentaryEntityFormShape } from '@/modules/entities/testamentaryEntities/TestamentaryEntityForm/TestamentaryEntityForm.types';
import { AugmentedCreateControllingPartyInput } from '@/types/schema';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { SubformsCombinedType } from '../../EntitySubforms.types';

export const CONTROLLING_PERSONS_LABEL =
  'Controlling person(s) and/or signatory(ies)';

export const CONTROLLING_PERSONS_ADD_LABEL =
  'Add additional person or signatory';

export function makeCreateControllingPartyInputs(
  formData:
    | SubformsCombinedType['basicInformationSubform']
    | NonNullable<TestamentaryEntityFormShape['controllingPartySubform']>
): AugmentedCreateControllingPartyInput[] {
  return compact(
    (formData?.controllingParties || []).map((controllingParty) => {
      if (!controllingParty.controllingPartyId) {
        return null;
      }

      const kind = getPulidKind(controllingParty.controllingPartyId);
      switch (kind) {
        case PulidKind.ClientProfile: {
          return {
            create: {
              individualID: controllingParty.controllingPartyId,
            },
          };
        }
        case PulidKind.ClientOrganization: {
          return {
            create: {
              organizationID: controllingParty.controllingPartyId,
            },
          };
        }
        default:
          throw new Error(`Unexpected PulidKind ${kind}`);
      }
    })
  );
}
