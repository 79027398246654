import { PrintOnlyAssetsCard } from '@/modules/entities/details/shared/ViewOnlyAssetsCard';
import { getQPRTTrustSummaryProperties } from '@/modules/entities/details/trusts/QPRTTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { TrustOverviewSlide } from '../components/Trust.OverviewSlide';
import { QPRTTrustPresentationSlideProps } from './QPRTTrust.types';

export function QPRTTrustOverviewSlide({
  entity,
  subtype,
  ...registrationProps
}: QPRTTrustPresentationSlideProps) {
  const summaryDetails = getQPRTTrustSummaryProperties({
    entity,
    entityType: ENTITY_TYPES.QPRT,
  });

  return (
    <TrustOverviewSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      AssetCard={<PrintOnlyAssetsCard entity={entity} />}
      displayName={subtype.displayName}
    />
  );
}
