import { useMemo, useState } from 'react';
import { useIsomorphicLayoutEffect } from 'react-use';
import { UseMeasureRect } from 'react-use/lib/useMeasure';

export const defaultMeasureRect: UseMeasureRect = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};

/**
 * @description Adapted from react-use's useMeasure, but takes an actual dom Element as an
 * argument rather than returning a ref to connect in a React component
 */
export function useMeasureDOMElement<E extends Element | null = Element>(
  element: E
): UseMeasureRect {
  const [rect, setRect] = useState<UseMeasureRect>(defaultMeasureRect);

  const observer = useMemo(
    () =>
      new window.ResizeObserver((entries) => {
        if (entries[0]) {
          const { x, y, width, height, top, left, bottom, right } =
            entries[0].target.getBoundingClientRect();
          setRect({ x, y, width, height, top, left, bottom, right });
        }
      }),
    []
  );

  useIsomorphicLayoutEffect(() => {
    if (!element) return;
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [element]);

  return rect;
}
