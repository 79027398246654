import React, { useCallback, useState } from 'react';

import { ApplicationAuthorization } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { getEnvironment } from '@/utils/environmentUtils';

import { useAreFeatureFlagsPopulated } from '../featureFlags/useFeatureFlag';
import { Authentication_ApplicationAuthorizationFragment } from './graphql/Authentication.generated';
import {
  GetUserStateQuery,
  useGetUserStateQuery,
} from './graphql/Authentication.generated';
import {
  BehaviorAuthorizationType,
  private_hasBehaviorAuthorization,
} from './hooks/useHasBehaviorAuthorization';

type HydratedUserState = GetUserStateQuery['currentUser'] & {
  loggedInToAddepar: boolean;
  loggedInToBlackDiamond: boolean;
  loggedInToOrion: boolean;
  integrationLoginExpired: boolean;
  apiUserIntegrationLoginExpired: boolean;
  authorization: Authentication_ApplicationAuthorizationFragment;
};

export type CurrentUserState = HydratedUserState | null;

interface IUserContext {
  currentUser: CurrentUserState;
  __setDevImpersonatedAuthorizationOverride: (
    appAuth: ApplicationAuthorization | null
  ) => void;
}

export const UserContext = React.createContext<IUserContext | null>(null);

export const UserProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const areFeatureFlagsPopulated = useAreFeatureFlagsPopulated();
  const [currentUser, setCurrentUser] = useState<CurrentUserState>(null);
  const [authorizationOverride, setAuthorizationOverride] =
    useState<null | ApplicationAuthorization>(null);
  const { data, error } = useGetUserStateQuery({
    // this shouldn't need to go to the network, but in a scenario when the cache is fully cleared
    // for some reason, we don't want to prevent it from doing so
    fetchPolicy: 'cache-first',
  });

  const handleSetAuthorizationOverride = useCallback(
    (appAuth: ApplicationAuthorization | null) => {
      if (getEnvironment() === 'prod') {
        throw new Error('This cannot be used in production.');
      }
      setAuthorizationOverride(appAuth);
    },
    [setAuthorizationOverride]
  );

  React.useEffect(() => {
    if (error) {
      setCurrentUser(null);
      return diagnostics.error('Failed to fetch current user', error);
    } else if (data?.currentUser) {
      // NOTE: isTokenValid will always be false if the user isn't logged into the integration,
      // hence the need to check both sides of the valiadation scenario
      const userIntegrationLoginExpired =
        (data.isUserLoggedInToAddepar.isLoggedIn &&
          !data.isUserLoggedInToAddepar.isTokenValid) ||
        (data.isUserLoggedInToBlackDiamond.isLoggedIn &&
          !data.isUserLoggedInToBlackDiamond.isTokenValid) ||
        (data.isUserLoggedInToOrion.isLoggedIn &&
          !data.isUserLoggedInToOrion.isTokenValid);

      // The login that we use to fetch nightly data is different from the one the users uses. But
      // that one can be expired too, so let's show a notice for it if the user is an admin and can
      // do something about it.
      const apiUserIntegrationLoginExpired =
        (data.isIntegrationUserLoggedInToAddepar.isLoggedIn &&
          !data.isIntegrationUserLoggedInToAddepar.isTokenValid) ||
        (data.isIntegrationUserLoggedInToBlackDiamond.isLoggedIn &&
          !data.isIntegrationUserLoggedInToBlackDiamond.isTokenValid) ||
        (data.isIntegrationUserLoggedInToOrion.isLoggedIn &&
          !data.isIntegrationUserLoggedInToOrion.isTokenValid);

      // we need to reach directly into this API here because the normal hook path relies
      // on *this* context to be set first
      const userCanInteractWithIntegration = private_hasBehaviorAuthorization(
        BehaviorAuthorizationType.CAN_ACCESS_INTEGRATIONS_ADMIN_PAGE,
        data.applicationAuthorization
      );

      setCurrentUser({
        ...data.currentUser,
        loggedInToAddepar: data.isUserLoggedInToAddepar.isLoggedIn,
        loggedInToBlackDiamond: data.isUserLoggedInToBlackDiamond.isLoggedIn,
        loggedInToOrion: data.isUserLoggedInToOrion.isLoggedIn,
        integrationLoginExpired: userIntegrationLoginExpired,
        apiUserIntegrationLoginExpired:
          apiUserIntegrationLoginExpired && userCanInteractWithIntegration,
        authorization: authorizationOverride
          ? authorizationOverride
          : data.applicationAuthorization,
      });
    }
  }, [
    authorizationOverride,
    data,
    error,
    setCurrentUser,
    areFeatureFlagsPopulated,
  ]);

  return (
    <UserContext.Provider
      value={{
        currentUser,
        __setDevImpersonatedAuthorizationOverride:
          handleSetAuthorizationOverride,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
