import { Box, Card, Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { PageHeader } from '@/components/navigation/PageHeader';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useForm } from '@/components/react-hook-form';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { EntityImportTableHeader } from '@/modules/import/addepar/EntityImportTable.header';
import {
  BulkImportFlowContextProvider,
  BulkImportFlowType,
  getBulkImportFlow,
} from '@/modules/import/BulkImportFlowContext';
import {
  EntityImportTable,
  EntityImportTableSingleHouseholdSubmitButton,
} from '@/modules/import/EntityImportTable';
import {
  EntityImportTableForm,
  NAMESPACE,
} from '@/modules/import/EntityImportTable.types';
import { ROUTE_KEYS } from '@/navigation/constants';

export function ImportClientEntitiesPage() {
  const { navigate } = useNavigateToRoute();
  const flowType: BulkImportFlowType = getBulkImportFlow(
    useRequiredParam('flowType')
  );
  const householdId: string = useRequiredParam('householdId');
  const formMethods = useForm<EntityImportTableForm>({
    defaultValues: {
      [NAMESPACE]: {
        householdMap: {},
      },
    },
  });

  const {
    formState: { isSubmitting },
  } = formMethods;

  return (
    <BulkImportFlowContextProvider value={{ flowType }}>
      <FormProvider<EntityImportTableForm> {...formMethods}>
        <FormFieldsDisabledProvider isDisabled={isSubmitting}>
          <Stack
            sx={{
              height: `calc(100vh - ${FOOTER_HEIGHT}px)`,
              overflow: 'auto',
            }}
          >
            <PageHeader heading="Import entities from Addepar" />
            <Box sx={{ p: 3 }}>
              <Card variant="outlined" sx={{ p: 3 }}>
                <EntityImportTable
                  singleHouseholdId={householdId}
                  tableCaption={<EntityImportTableHeader />}
                />
              </Card>
            </Box>
          </Stack>
          <Box>
            <Footer
              leftAction={
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() =>
                    navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST, {
                      householdId,
                    })
                  }
                >
                  Exit import
                </Button>
              }
              rightAction={
                <EntityImportTableSingleHouseholdSubmitButton
                  householdId={householdId}
                />
              }
            />
          </Box>
        </FormFieldsDisabledProvider>
      </FormProvider>
    </BulkImportFlowContextProvider>
  );
}
