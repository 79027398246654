import { useCallback } from 'react';
import { PathValue } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { AISuggestionsMatcherSingleField } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import { BasicInformation_Grantor } from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import { CombinedSupportedSubformFieldTypes } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';

// An object that describes the array field to update in the subform, and new
// inputs to add / merge into that array field.
export interface SingleFieldToUpdate extends SingleFieldWithID {
  // New inputs to add / merge this array field
  newInput: PathValue<
    CombinedSupportedSubformFieldTypes,
    AISuggestionsMatcherSingleField
  >;
}

// An object that describes the array field in a subform, including the idField
// that is used to identify one input element.
export interface SingleFieldWithID {
  // The full path to the array field in the entity form, e.g., 'trustDetailsSubform.trustees'
  singleFieldFullPath: AISuggestionsMatcherSingleField;
  // The id field of the input element that represents the value e.g., 'id' or 'clientProfileId'
  idField: string;
}

interface UseUpdateSubformSingleFieldReturn {
  // Given an array of updates, this function will update the entries of array
  // fields in a form. If there is an existing item in the form's array field
  // with the same id, the new input will overwrite the existing one.
  // This will reset the form to trigger a re-render and block navigation.
  updateSingleFields: (update: SingleFieldToUpdate) => void;
}

/**
 * A hook that exposes a function to update the entries of array fields in a form.
 */
export function useUpdateSubformSingleField(): UseUpdateSubformSingleFieldReturn {
  const { getValues, reset, setValue, setShouldBlockNavigation } =
    useFormContext<CombinedSupportedSubformFieldTypes>();

  const updateSingleFields = useCallback(
    (update: SingleFieldToUpdate) => {
      setValue(
        update.singleFieldFullPath,
        update.newInput as BasicInformation_Grantor
      );
      reset(getValues());
      setShouldBlockNavigation(true);
    },
    [getValues, reset, setShouldBlockNavigation, setValue]
  );

  return {
    updateSingleFields,
  };
}
