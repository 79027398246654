import { Box, Stack, Typography, useTheme } from '@mui/material';
import { KeyboardEvent, useCallback, useMemo } from 'react';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { ArrowLeftIcon } from '@/components/icons/ArrowLeftIcon';
import { ArrowRightIcon } from '@/components/icons/ArrowRightIcon';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useEventListener } from '@/hooks/useEventListener';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { useSubBrand } from '@/modules/tenant/TenantDetailsContext/useSubBrand';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { CustomerThemePicker } from '@/styles/themes/CustomerThemePicker';
import { CustomerThemeProvider } from '@/styles/themes/CustomerThemeProvider';
import { COLORS } from '@/styles/tokens/colors';
import * as diagnostics from '@/utils/diagnostics';
import { isProduction } from '@/utils/featureFlags';

import { AdvisorPreviewIndicator } from './AdvisorPreviewIndicator';
import {
  ADVISOR_PREVIEW_PARAM_NAME,
  useIsAdvisorPreview,
} from './hooks/useIsAdvisorPreview';
import {
  Slide,
  SLIDE_PARAM,
  SlideType,
  usePresentation,
} from './hooks/usePresentation';
import { ProposalKind, useProposalsQuery } from './hooks/useProposalsQuery';
import { PrintClientProposalButton } from './PrintClientProposalButton';
import { CharitableAnalysis } from './slides/CharitableAnalysis/CharitableAnalysis';
import { CharitableDistributionTable } from './slides/CharitableDistributionTable';
import { CharitableOverview } from './slides/CharitableOverview/CharitableOverview';
import { CombinedOverview } from './slides/CombinedOverview';
import { CoverPage } from './slides/CoverPage';
import { GiftOverview } from './slides/GiftOverview/GiftOverview';
import { GiftProjectedWealth } from './slides/GiftProjectedWealth';
import { ImpactComparison } from './slides/ImpactComparison';
import { ScenarioDetail } from './slides/ScenarioDetail';
import { BOTTOM_BAR_HEIGHT } from './slides/SlideContainer';
import { StrategyOverview } from './slides/StrategyOverview';

interface GetSearchParamUpdateOpts {
  householdId: string | null;
  isAdvisorPreview?: boolean;
}

export function getSearchParamUpdateFromSlide(
  slide: Slide,
  opts: GetSearchParamUpdateOpts
): URLSearchParams {
  const searchObject: Record<string, string | boolean> = {};
  if (slide.params.proposalScenario) {
    searchObject.ps = slide.params.proposalScenario;
  }
  if (slide.params.entity) {
    searchObject.entity = slide.params.entity;
  }
  if (slide.params[SLIDE_PARAM]) {
    searchObject.slide = slide.params.slide;
  }
  if (opts.isAdvisorPreview) {
    searchObject[ADVISOR_PREVIEW_PARAM_NAME] = true;
  }
  if (opts.householdId) {
    searchObject.householdId = opts.householdId;
  }

  return searchObject as unknown as URLSearchParams;
}

interface SlideRendererProps {
  proposal: ProposalFragment;
  currentSlide: Slide | null;
  showPostTax?: boolean;
  timeHorizonIdx?: number;
  proposalKind?: ProposalKind;
}

export function SlideRenderer({
  proposal,
  currentSlide,
  showPostTax = false,
  timeHorizonIdx = 0,
  proposalKind = ProposalKind.Entity,
}: SlideRendererProps): JSX.Element {
  const slideMap = useMemo(
    () => ({
      CoverPage: <CoverPage proposal={proposal} proposalKind={proposalKind} />, // https://www.figma.com/file/Jv6op8db2Qe3tIpArzQyr4/Dev-Specs?node-id=2955%3A30014
      CombinedOverview: <CombinedOverview proposal={proposal} />, // https://www.figma.com/file/Jv6op8db2Qe3tIpArzQyr4/Dev-Specs?node-id=2955%3A30709
      StrategyOverview: (
        <StrategyOverview proposal={proposal} currentSlide={currentSlide} />
      ), // https://www.figma.com/file/Jv6op8db2Qe3tIpArzQyr4/Dev-Specs?node-id=2969%3A30675
      ScenarioDetail: (
        <ScenarioDetail
          proposal={proposal}
          currentSlide={currentSlide}
          timeHorizonIdx={timeHorizonIdx}
        />
      ), // https://www.figma.com/file/Jv6op8db2Qe3tIpArzQyr4/Dev-Specs?node-id=2969%3A31183
      ImpactComparison: (
        <ImpactComparison
          proposal={proposal}
          currentSlide={currentSlide}
          initialSelectedTabIndex={showPostTax ? 1 : 0}
          timeHorizonIdx={timeHorizonIdx}
        />
      ), // https://www.figma.com/file/Jv6op8db2Qe3tIpArzQyr4/Dev-Specs?node-id=2969%3A31892
      // Gift proposal
      GiftCoverPage: (
        <CoverPage proposal={proposal} proposalKind={ProposalKind.Gift} />
      ),
      GiftOverview: <GiftOverview proposal={proposal} />,
      GiftProjectedWealth: (
        <GiftProjectedWealth
          proposal={proposal}
          preTaxCategory={currentSlide?.params.preTaxCategory}
          yearIdx={currentSlide?.params.yearIdx}
        />
      ),
      CharitableCoverPage: (
        <CoverPage
          proposal={proposal}
          proposalKind={
            proposal.crtProposal ? ProposalKind.CRT : ProposalKind.CLT
          }
        />
      ),
      CharitableOverview: <CharitableOverview proposal={proposal} />,
      CharitableAnalysis: (
        <CharitableAnalysis
          proposal={proposal}
          charitablePreTaxCategory={
            currentSlide?.params.charitablePreTaxCategory
          }
          yearIdx={currentSlide?.params.yearIdx}
        />
      ),
      CharitableDistributionSchedule: (
        <CharitableDistributionTable proposal={proposal} />
      ),
    }),
    [proposal, proposalKind, currentSlide, timeHorizonIdx, showPostTax]
  );

  return slideMap[currentSlide?.type ?? SlideType.CoverPage];
}

function areEqual(
  prevProps: SlideRendererProps,
  nextProps: SlideRendererProps
) {
  return prevProps?.currentSlide?.name === nextProps?.currentSlide?.name;
}

// Memoize the slide renderer to avoid re-rendering the current slide when the slide changes
const SlideRendererMemo = React.memo(SlideRenderer, areEqual);

export function PresentationPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const isAdvisorPreviewScenario = useIsAdvisorPreview();
  const proposalId = useRequiredParam('proposalId');
  const householdId = searchParams.get('householdId');

  const { proposal, proposalKind, subBrandId } = useProposalsQuery({
    proposalId,
  });

  const { slides, currentSlideIdx, currentSlide } = usePresentation({
    proposal,
    proposalKind,
  });

  useSubBrand(subBrandId || null);

  const goToSlide = useCallback(
    (relativeSlideIdx: number) => {
      const nextIdx = currentSlideIdx + relativeSlideIdx;
      const nextSlide = slides[nextIdx];
      if (!nextSlide) {
        diagnostics.debug(
          `Attempting to go to slide index ${nextIdx}, which doesn't exist.`
        );
        return;
      }

      const slideSearchParams = getSearchParamUpdateFromSlide(nextSlide, {
        householdId,
        isAdvisorPreview: isAdvisorPreviewScenario,
      });
      setSearchParams(slideSearchParams);
    },
    [
      currentSlideIdx,
      slides,
      householdId,
      isAdvisorPreviewScenario,
      setSearchParams,
    ]
  );

  const handleUserKeyPress = useCallback(
    (event: Event) => {
      const { key } = event as unknown as KeyboardEvent;
      if (key === 'ArrowRight') {
        goToSlide(1);
      }
      if (key === 'ArrowLeft') {
        goToSlide(-1);
      }
    },
    [goToSlide]
  );

  useEventListener('keydown', handleUserKeyPress);

  const proposalBuilderPageLink = React.useMemo(() => {
    if (!householdId) {
      return null;
    }

    if (proposalKind === ProposalKind.Gift) {
      return getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER, {
        householdId: householdId,
        proposalId: proposalId,
      });
    }

    if (
      proposalKind === ProposalKind.CRT ||
      proposalKind === ProposalKind.CLT
    ) {
      return getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_MODEL_SCENARIOS,
        {
          householdId,
          proposalId,
          variant: proposalKind === ProposalKind.CRT ? 'crt' : 'clt',
        }
      );
    }

    return getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_PROPOSAL_EDIT, {
      householdId: householdId,
      proposalId: proposalId,
    });
  }, [householdId, proposalId, proposalKind]);

  return (
    <>
      {isAdvisorPreviewScenario && (
        <AdvisorPreviewIndicator
          disabled={!proposalBuilderPageLink}
          onClick={() =>
            proposalBuilderPageLink && navigate(proposalBuilderPageLink)
          }
        />
      )}
      <CustomerThemeProvider>
        {proposal && (
          <>
            {currentSlide && (
              <SlideRendererMemo
                proposal={proposal}
                currentSlide={currentSlide}
                proposalKind={proposalKind || undefined}
              />
            )}
          </>
        )}
        <Box
          width="100%"
          position="fixed"
          bgcolor={COLORS.PRIMITIVES.WHITE}
          bottom="0"
          display="flex"
          justifyContent="space-between"
          borderTop={`1px solid ${COLORS.GRAY[200]}`}
          zIndex={2}
          sx={(theme) => ({
            height: theme.spacing(BOTTOM_BAR_HEIGHT),
          })}
        >
          <Stack alignSelf="center" mx={2}>
            <Box>
              <Typography maxWidth={'200px'} variant="subtitle2">
                Use arrow keys or buttons to the right to navigate this proposal
              </Typography>
            </Box>
          </Stack>
          {!isProduction() && (
            <Stack
              bgcolor={theme.palette.grey[100]}
              px={1}
              justifyContent="center"
              borderRadius={2}
            >
              <Box sx={{ fontFamily: 'Monospace' }}>
                shift + S to toggle theme
              </Box>
              <Box sx={{ fontFamily: 'Monospace' }}>
                shift + D to use demo theme
              </Box>
            </Stack>
          )}

          <Box px={1} alignSelf="center">
            <Stack direction="row" spacing={1} alignItems="center">
              {householdId && (
                <PrintClientProposalButton
                  householdId={householdId}
                  proposalId={proposalId}
                  proposalName={proposal?.displayName ?? 'client-proposal'}
                />
              )}
              <Box marginRight={2}>
                <ButtonWithPopover
                  label="Jump to"
                  variant="secondary"
                  size="sm"
                  popperProps={{
                    PaperProps: {
                      style: {
                        width: 280,
                      },
                    },
                  }}
                >
                  {slides.map((slide, idx) => (
                    <MenuItem
                      key={`item-${idx}`}
                      justifyContent="space-between"
                      onClick={() => {
                        goToSlide(idx - currentSlideIdx);
                      }}
                      label={slide.name}
                      iconAfter={
                        idx === currentSlideIdx && <CheckIcon size={20} />
                      }
                      sx={{
                        height: theme.spacing(5),
                        borderBottom:
                          slide.type === SlideType.CoverPage ||
                          (slide.type === SlideType.ImpactComparison &&
                            idx !== slides.length - 1)
                            ? `1px solid ${theme.palette.grey[300]}`
                            : 'none',
                        paddingLeft:
                          slide.type === SlideType.ScenarioDetail ||
                          slide.type === SlideType.ImpactComparison
                            ? theme.spacing(4)
                            : theme.spacing(2),
                        [`.MuiTypography-body1`]: {
                          fontWeight:
                            currentSlideIdx === idx
                              ? theme.typography.fontWeightBold
                              : theme.typography.fontWeightRegular,
                        },
                      }}
                    />
                  ))}
                </ButtonWithPopover>
              </Box>
              <Button
                data-testid="previous-slide-button"
                disabled={currentSlideIdx === 0}
                size="xs"
                variant="primary"
                onClick={() => {
                  goToSlide(-1);
                }}
              >
                <ArrowLeftIcon />
              </Button>
              <Button
                data-testid="next-slide-button"
                disabled={currentSlideIdx === slides.length - 1}
                size="xs"
                variant="primary"
                onClick={() => {
                  goToSlide(1);
                }}
              >
                <ArrowRightIcon />
              </Button>
            </Stack>
          </Box>
        </Box>
        <CustomerThemePicker />
      </CustomerThemeProvider>
    </>
  );
}
