import { generateAnnuityUpdates } from '@/modules/gratAnnuities/GRATAnnuitiesSubform/utils/mutationUtils';
import { AugmentedUpdateGratTrustInput } from '@/types/schema';

import { PartialSubformsCombinedType } from '../../EntitySubforms.types';

export function makeUpdateGratAnnuities(
  formInput: PartialSubformsCombinedType
): Partial<AugmentedUpdateGratTrustInput> {
  const annuities = formInput.annuitiesSubform?.annuities ?? [];

  const annuityInput = generateAnnuityUpdates({
    annuitiesSubform: {
      annuities: formInput.annuitiesSubform?.annuities ?? [],
    },
  });

  if (annuities.length === 0) {
    return {};
  }

  return {
    withAnnuities: annuityInput.create,
  };
}
