import { GiftDesignerStages } from '@/navigation/constants';
import { GiftingProposalStatus } from '@/types/schema';

export function mapGiftingProposalStatusToGiftDesignerStages(
  status: GiftingProposalStatus | null
): GiftDesignerStages {
  switch (status) {
    case GiftingProposalStatus.Draft:
    case GiftingProposalStatus.ReadyForProposal:
    case GiftingProposalStatus.ProposalCreated:
      return GiftDesignerStages.MODEL_SCENARIOS;
    default:
      return GiftDesignerStages.BASIC_INFORMATION;
  }
}
