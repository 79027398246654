import { Box, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { startsWith } from 'lodash';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';

import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { Lock01Icon } from '@/components/icons/Lock01Icon';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { useFormContext } from '@/components/react-hook-form';
import {
  CellContainer,
  PrimaryCellTypography,
} from '@/components/tables/DataTable/components/cells';
import { SHOW_ON_ROW_HOVER_CLASSNAME } from '@/components/tables/DataTable/components/ThemedDataGrid';
import { COLORS } from '@/styles/tokens/colors';

import {
  NEW_BALANCE_SHEET_CATEGORY_SENTINEL,
  RowData,
} from '../AdminBalanceSheetConfigurationPage.types';
import { useReorderableRows } from '../context/ReorderableRows.context';
import { BalanceSheetCategoryDeletionModal } from './BalanceSheetCategoryDeletionModal/BalanceSheetCategoryDeletionModal';

export function BalanceSheetCategoryNameCell({
  row,
  colDef,
}: GridRenderCellParams<RowData>) {
  const { reorderableRows, setReorderableRows } = useReorderableRows();
  const [isDeleting, setIsDeleting] = useState(false);
  const { control, setValue } = useFormContext();
  const [name, editingRowId] = useWatch({
    control,
    name: [
      `balanceSheetCategoriesById.${row.id}.name`,
      `currentlyEditingRowId`,
    ],
  });

  const isEditing = row.id === editingRowId;
  const setIsEditing = (isEditing: boolean) => {
    if (!row.isSystemClass) {
      setValue('currentlyEditingRowId', isEditing ? row.id : null);
    }
  };

  const handleSave = () => {
    setIsEditing(false);
  };

  const handleDelete = () => {
    if (row.isSystemClass) return;

    if (startsWith(row.id, NEW_BALANCE_SHEET_CATEGORY_SENTINEL)) {
      setIsEditing(false);
      setReorderableRows(reorderableRows.filter((r) => r.id !== row.id));
      return;
    }

    setIsDeleting(true);
  };

  return (
    <>
      {isDeleting && (
        <BalanceSheetCategoryDeletionModal
          onClose={(didDelete) => {
            setIsDeleting(false);
            if (didDelete) {
              setIsEditing(false);
            }
          }}
          deletedCategoryId={row.id}
          isOpen={isDeleting}
        />
      )}
      <CellContainer align={colDef.align}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height={42} // matches the height of the input, so forces all rows to be the same height
        >
          {isEditing && !row.isSystemClass ? (
            <Stack
              display="inherit"
              direction="row"
              alignItems="center"
              width="100%"
              spacing={1}
            >
              <Box flexGrow={1}>
                <FormAwareTextInput
                  autoFocus
                  fieldName={`balanceSheetCategoriesById.${row.id}.name`}
                  label="Category name"
                  hideLabel
                  control={control}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    if (e.key === 'Enter') {
                      handleSave();
                    }
                  }}
                />
              </Box>
              <Stack direction="row" spacing={1} ml={2}>
                <IconButton
                  variant="primary"
                  ariaLabel="Save edits"
                  size="sm"
                  icon={CheckIcon}
                  onClick={handleSave}
                />
                <IconButton
                  variant="destructive-transparent"
                  ariaLabel="Delete category"
                  size="sm"
                  icon={Trash01Icon}
                  onClick={handleDelete}
                />
              </Stack>
            </Stack>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              spacing={1}
            >
              <PrimaryCellTypography>{name}</PrimaryCellTypography>
              <Stack direction="row" width={50} justifyContent="center">
                {!row.isSystemClass ? (
                  <Box className={SHOW_ON_ROW_HOVER_CLASSNAME}>
                    <IconButton
                      ariaLabel="Edit category"
                      icon={Edit02Icon}
                      variant="transparent"
                      size="xs"
                      onClick={() => setIsEditing(true)}
                    />
                  </Box>
                ) : (
                  <Lock01Icon size={16} color={COLORS.GRAY[400]} />
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      </CellContainer>
    </>
  );
}
