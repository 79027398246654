import { SxProps, Theme } from '@mui/material';
import { PropsWithChildren } from 'react';

import { Card } from '@/components/layout/Card/Card';

export function GiftCard({
  children,
  sx,
}: PropsWithChildren<{
  sx?: SxProps<Theme>;
}>) {
  return (
    <Card
      sx={{
        height: '100%',
        flexGrow: 1,
        padding: 3,
        borderRadius: '6px',

        ...sx,
      }}
      variant="outlined"
    >
      {children}
    </Card>
  );
}
