import { Box } from '@mui/material';
import { CSSProperties } from 'react';

import { getPreferredBackground } from '../chartAccessories';

interface VerticalBarSubsectionProps {
  subsection: {
    value: number;
    color: string;
    backgroundCss?: CSSProperties['background'];
  };
  total: number;
}

export function VerticalBarSubsection({
  subsection,
  total,
}: VerticalBarSubsectionProps) {
  const { value, color, backgroundCss } = subsection;
  const heightPercent = (value / total) * 100;

  const backgroundForSubsection = getPreferredBackground({
    backgroundCss,
    color,
  });

  return (
    <Box
      sx={{
        width: '100%',
        background: backgroundForSubsection,
        height: `${heightPercent}%`,
      }}
    />
  );
}
