import Decimal from 'decimal.js';
import { compact } from 'lodash';

import { TableCellContext, TableCellProps } from '@/components/lists/TableCell';
import { BUSINESS_OWNERSHIP_PERCENTAGE_DECIMAL_SCALE } from '@/modules/entities/BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { EntityDetail_OwnedByStakeFragment } from '../../graphql/EntityDetailPage.generated';

export function getFormattedOwnedByStake(
  ownedByStake: EntityDetail_OwnedByStakeFragment,
  currentValue: Decimal
): TableCellProps & { id: string } {
  const ownershipValue = currentValue.times(
    ownedByStake.ownershipPercentage?.div(new Decimal(100)) ?? new Decimal(0)
  );

  const owningEntity = ownedByStake.owningEntity;

  const heading = (() => {
    if (owningEntity) {
      return owningEntity.subtype.displayName;
    }

    return ownedByStake.owningIndividual?.displayName ?? '';
  })();

  return {
    id: ownedByStake.id,
    heading,
    link: owningEntity
      ? getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS, {
          householdId: owningEntity.household.id,
          entityId: owningEntity.id,
        })
      : undefined,
    description: getOwnedByDescription(ownedByStake),
    RightContent: (
      <TableCellContext
        heading={formatCurrency(ownershipValue, { currencySign: 'accounting' })}
        subheading={
          formatPercent(
            ownedByStake.ownershipPercentage ?? new Decimal(0),
            BUSINESS_OWNERSHIP_PERCENTAGE_DECIMAL_SCALE
          ) + '%'
        }
      />
    ),
  };
}

function getOwnedByDescription(
  ownedByStake: EntityDetail_OwnedByStakeFragment
) {
  if (!ownedByStake.isGrantorControlled) {
    return 'Non-controlled ownership';
  }

  const owningEntity = ownedByStake.owningEntity;
  const owningEntityContext = (() => {
    // grantorControlledPercentage will only be present if the owning entity
    // is a business entity
    if (
      owningEntity?.subtype &&
      'grantorControlledPercentage' in owningEntity.subtype
    ) {
      return `(${formatPercent(
        owningEntity.subtype.grantorControlledPercentage
      )}%)`;
    }

    return null;
  })();

  return compact(['Client-controlled ownership', owningEntityContext]).join(
    ' '
  );
}
