import { AiOnboardingModal_AiSuggestionFragment } from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import {
  BUSINESS_ENTITY_KINDS,
  NON_TRUST_CHARITABLE_ENTITY_KINDS,
  PERSONAL_ACCOUNT_ENTITY_KINDS,
  TRUST_ENTITY_KINDS,
} from '@/modules/entities/entities.constants';
import { diagnostics } from '@/utils/diagnostics';

/**
 * Given a suggestion, returns the KG node name used to generate the suggestion.
 * @param suggestion
 */
export function getNodeNameFromSuggestion(
  suggestion: AiOnboardingModal_AiSuggestionFragment
): string {
  return (
    suggestion.onboardingIndividual?.nodeName ??
    suggestion.onboardingOrganization?.nodeName ??
    suggestion.onboardingEntity?.nodeName ??
    suggestion.onboardingTestamentaryEntity?.nodeName ??
    ''
  );
}

/**
 * Given a list of suggestions and a list of document IDs, returns the ordered
 * list of suggestions by the following criteria:
 *
 * 1. Entity kind bucket (trust, business, non-trust charitable, personal account)
 * 2. Document order
 * 3. Node name
 *
 * @param suggestions
 * @param orderedDocumentIds
 */
export function orderSuggestions(
  suggestions: AiOnboardingModal_AiSuggestionFragment[],
  orderedDocumentIds: string[]
) {
  const docIdOrderMap = new Map<string, number>();
  orderedDocumentIds.forEach((id, idx) => docIdOrderMap.set(id, idx));

  function getEntityKindOrder(
    suggestion: AiOnboardingModal_AiSuggestionFragment
  ): number {
    const kind = suggestion.onboardingEntity?.entityKind;

    // Non-entity suggestions won't have a kind. We'll put them last in the list,
    // but they will still be ordered by document order.
    if (!kind) return Infinity;

    if (TRUST_ENTITY_KINDS.includes(kind)) return 1;
    if (BUSINESS_ENTITY_KINDS.includes(kind)) return 2;
    if (NON_TRUST_CHARITABLE_ENTITY_KINDS.includes(kind)) return 3;
    if (PERSONAL_ACCOUNT_ENTITY_KINDS.includes(kind)) return 4;

    diagnostics.warn(`[getEntityKindOrder]: unhandled entity kind: ${kind}`);
    return Infinity;
  }

  return suggestions.sort((a, b) => {
    // First, order by entity kind bucket
    const bucketA = getEntityKindOrder(a);
    const bucketB = getEntityKindOrder(b);
    if (bucketA !== bucketB) {
      return bucketA - bucketB;
    }

    // If buckets are the same, order by document order
    const docA = a.kgNode?.mergedFrom?.[0]?.file?.document?.id;
    const docB = b.kgNode?.mergedFrom?.[0]?.file?.document?.id;

    const docAIndex = docIdOrderMap.get(docA ?? '') ?? Infinity;
    const docBIndex = docIdOrderMap.get(docB ?? '') ?? Infinity;
    if (docAIndex !== docBIndex) {
      return docAIndex - docBIndex;
    }

    // If documents are the same, order alpha by node name
    const nameA = getNodeNameFromSuggestion(a).toLowerCase();
    const nameB = getNodeNameFromSuggestion(b).toLowerCase();
    return nameA.localeCompare(nameB);
  });
}
