import { PopperContent } from '@/components/poppers/PopperContent';

export function EntityOwners() {
  return (
    <PopperContent
      body={'The individual(s) that are beneficial owners of this entity.'}
    />
  );
}

export function InsuranceAccountEntityOwners() {
  return (
    <PopperContent body="Specify the owner(s) of the policies held in this entity. You can add the insured individuals to each policy below." />
  );
}
