// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
export type ExemptionTrackingTable_SummaryBeneficiaryFragment = { __typename?: 'SummaryBeneficiaryv2', id: string, displayName: string };

export type ExemptionTrackingTable_RecipientBeneficiaryFragment = { __typename?: 'Beneficiary', id: string, summary: { __typename?: 'SummaryBeneficiaryv2', id: string, displayName: string }, entity?: { __typename?: 'Entity', id: string, kind: Types.EntityKind } | null };

export type ExemptionTrackingTable_GrantorClientProfileFragment = { __typename?: 'ClientProfile', id: string, displayName: string };

export type ExemptionTrackingTable_LifetimeExclusionEventFragment = { __typename?: 'LifetimeExclusionEvent', id: string, eventDate: Time, giftAmount?: CurrencyUSD | null, giftCategory?: Types.LifetimeExclusionEventGiftCategory | null, kind: Types.LifetimeExclusionEventKind, lifetimeExclusionAmount: CurrencyUSD, gstExclusionAmount: CurrencyUSD, annualExclusionAmount?: CurrencyUSD | null, grantor: { __typename?: 'ClientProfile', id: string, displayName: string }, recipient?: { __typename?: 'Beneficiary', id: string, summary: { __typename?: 'SummaryBeneficiaryv2', id: string, displayName: string }, entity?: { __typename?: 'Entity', id: string, kind: Types.EntityKind } | null } | null };

export const ExemptionTrackingTable_GrantorClientProfileFragmentDoc = gql`
    fragment ExemptionTrackingTable_GrantorClientProfile on ClientProfile {
  id
  displayName
}
    `;
export const ExemptionTrackingTable_SummaryBeneficiaryFragmentDoc = gql`
    fragment ExemptionTrackingTable_SummaryBeneficiary on SummaryBeneficiaryv2 {
  id
  displayName
}
    `;
export const ExemptionTrackingTable_RecipientBeneficiaryFragmentDoc = gql`
    fragment ExemptionTrackingTable_RecipientBeneficiary on Beneficiary {
  id
  summary {
    ...ExemptionTrackingTable_SummaryBeneficiary
  }
  entity {
    id
    kind
  }
}
    ${ExemptionTrackingTable_SummaryBeneficiaryFragmentDoc}`;
export const ExemptionTrackingTable_LifetimeExclusionEventFragmentDoc = gql`
    fragment ExemptionTrackingTable_LifetimeExclusionEvent on LifetimeExclusionEvent {
  id
  eventDate
  giftAmount
  giftCategory
  kind
  grantor {
    ...ExemptionTrackingTable_GrantorClientProfile
  }
  lifetimeExclusionAmount
  gstExclusionAmount
  annualExclusionAmount
  recipient {
    ...ExemptionTrackingTable_RecipientBeneficiary
  }
}
    ${ExemptionTrackingTable_GrantorClientProfileFragmentDoc}
${ExemptionTrackingTable_RecipientBeneficiaryFragmentDoc}`;
export const Operations = {
  Fragment: {
    ExemptionTrackingTable_SummaryBeneficiary: 'ExemptionTrackingTable_SummaryBeneficiary' as const,
    ExemptionTrackingTable_RecipientBeneficiary: 'ExemptionTrackingTable_RecipientBeneficiary' as const,
    ExemptionTrackingTable_GrantorClientProfile: 'ExemptionTrackingTable_GrantorClientProfile' as const,
    ExemptionTrackingTable_LifetimeExclusionEvent: 'ExemptionTrackingTable_LifetimeExclusionEvent' as const
  }
}