import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { Path } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { useTaxStatusAssetLocationOptions } from '@/modules/entities/TaxStatusSubform/hooks/useTaxStatusAssetLocationOptions';
import { IN_ESTATE } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.constants';
import { getTaxStatusValuesFromEntityType } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import { EntityInEstateStatus, StateCode } from '@/types/schema';

import { EntityShortFormShape } from '../CreateEntityShortForm.types';

interface UseDefaultEstateInclusionStatusParams {
  entityType: EntityType;
  stateTaxOptions: ReturnType<
    typeof useTaxStatusAssetLocationOptions
  >['stateTaxOptions'];
}

export function useDefaultEstateInclusionStatus({
  entityType,
  stateTaxOptions,
}: UseDefaultEstateInclusionStatusParams) {
  const { resetField } = useFormContext<EntityShortFormShape>();

  // we want to default the estate inclusion status to the default for the
  // entity type, but still allow users to change it
  const estateInclusionStatus = useMemo(() => {
    const taxStatusFormDefaultValues =
      getTaxStatusValuesFromEntityType(entityType);
    return taxStatusFormDefaultValues.estateInclusionStatus;
  }, [entityType]);

  useEffect(() => {
    resetField('createEntityShortForm.estateInclusionStatus', {
      defaultValue: estateInclusionStatus,
    });
  }, [estateInclusionStatus, resetField]);

  // Make sure the form has registered the dynamic fields before setting their values
  useEffect(() => {
    // Note, the TaxStatusSubform has simliar logic to determine the available state estate taxes.
    if (isEmpty(stateTaxOptions)) {
      return;
    }

    const entityInEstateStatus: EntityInEstateStatus =
      estateInclusionStatus === IN_ESTATE
        ? EntityInEstateStatus.InEstate
        : EntityInEstateStatus.OutOfEstate;

    const allStates = Object.values(StateCode);
    const fieldNamesToClear = allStates.map(
      (s) =>
        `createEntityShortForm.stateTax.${s}.inEstateStatus` as const satisfies Path<EntityShortFormShape>
    );

    // Clear all state tax fields
    fieldNamesToClear.forEach((fieldName) => {
      resetField(fieldName as Path<EntityShortFormShape>, {
        defaultValue: null,
      });
    });

    const fieldNamesToSet = stateTaxOptions.map((o) => o.fieldName);

    // Set the state tax fields to the default value for the entity type
    fieldNamesToSet.forEach((fieldName) => {
      resetField(fieldName as Path<EntityShortFormShape>, {
        defaultValue: entityInEstateStatus,
      });
    });
  }, [estateInclusionStatus, resetField, stateTaxOptions]);
}
