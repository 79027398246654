import { useAsyncFn } from 'react-use';

import { MagicWand01Icon } from '@/components/icons/MagicWand01Icon';
import {
  MenuItem,
  MenuItemProps,
} from '@/components/poppers/MenuPopper/MenuItem';

import { useIsInteractive } from '../../hooks/useIsInteractive';
import { ControlButton, ControlButtonProps } from './ControlButton';

export type ResetButtonProps = ControlButtonProps & {
  onReset?: () => Promise<void>;
};

export const ResetButton = ({
  onClick,
  onReset,
  children,
  ...props
}: ResetButtonProps) => {
  const [{ loading }, resetFn] = useAsyncFn(async () => onReset?.(), [onReset]);
  const { isInteractive, toggleIsInteractive } = useIsInteractive();

  const defaultOnClick: ControlButtonProps['onClick'] = async () => {
    if (onReset) {
      await resetFn();
    } else {
      toggleIsInteractive();
    }
  };

  if (!isInteractive) return null;
  return (
    <ControlButton
      variant="secondary-filled"
      onClick={onClick ?? defaultOnClick}
      title="reset tile positions"
      aria-label="reset tile positions"
      loading={loading}
      startIcon={MagicWand01Icon}
      {...props}
    >
      {children ?? <>Auto position tiles</>}
    </ControlButton>
  );
};

export type ResetButtonMenuItemProps = Partial<MenuItemProps> & {
  onReset: () => Promise<void>;
};

export const ResetButtonMenuItem = ({
  onReset,
  ...props
}: ResetButtonMenuItemProps) => {
  return (
    <MenuItem
      label="Automatically position tiles"
      onClick={onReset}
      {...props}
    />
  );
};
