// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AnnuityFormGratTrustFragment = { __typename?: 'GRATTrust', id: string, annuityAnnualIncreasePercent?: Percent | null, grantorRetainedInterest: CurrencyUSD, taxableGift?: CurrencyUSD | null, currentValue: CurrencyUSD, initialFundingValue?: CurrencyUSD | null, officialInterestRatePercent?: Percent | null, projectedMarketValue?: CurrencyUSD | null, projectedRateOfReturn?: Percent | null, projectedSharePrice?: CurrencyUSD | null, rollingPeriodYears?: number | null, termDurationYears?: number | null, targetTaxableGift?: CurrencyUSD | null };

export type GetAnnuitiesDetailsQueryVariables = Types.Exact<{
  annuitiesLike: Types.GratAnnuityV2WhereInput;
  projectionParams: Types.ProjectionParams;
}>;


export type GetAnnuitiesDetailsQuery = { __typename?: 'Query', gratScenarioProjection: { __typename?: 'Projection', scenarioTableRows: Array<{ __typename?: 'ScenarioTableRow', year: number, beginningPrincipal: CurrencyUSD, principalGrowth: CurrencyUSD, annuityPayment: CurrencyUSD, annuityPaymentPercentOfInitialFunding: Percent, remainderValue: CurrencyUSD }> }, gratAnnuityV2s: { __typename?: 'GRATAnnuityV2Connection', edges?: Array<{ __typename?: 'GRATAnnuityV2Edge', node?: { __typename?: 'GRATAnnuityV2', id: string, termDurationYears: number, yearOfTerm: number, initialFundingValuePercent: Percent, computedPaymentAmount: CurrencyUSD } | null } | null> | null } };

export type GetNpvQueryVariables = Types.Exact<{
  interestRate: Types.Scalars['Percent']['input'];
  cashFlows: Array<Types.Scalars['CurrencyUSD']['input']> | Types.Scalars['CurrencyUSD']['input'];
}>;


export type GetNpvQuery = { __typename?: 'Query', npv: CurrencyUSD };

export const AnnuityFormGratTrustFragmentDoc = gql`
    fragment AnnuityFormGRATTrust on GRATTrust {
  id
  annuityAnnualIncreasePercent
  grantorRetainedInterest
  taxableGift
  currentValue
  initialFundingValue
  officialInterestRatePercent
  projectedMarketValue
  projectedRateOfReturn
  projectedSharePrice
  rollingPeriodYears
  termDurationYears
  targetTaxableGift
}
    `;
export const GetAnnuitiesDetailsDocument = gql`
    query GetAnnuitiesDetails($annuitiesLike: GRATAnnuityV2WhereInput!, $projectionParams: ProjectionParams!) {
  gratScenarioProjection(params: $projectionParams) {
    scenarioTableRows {
      year
      beginningPrincipal
      principalGrowth
      annuityPayment
      annuityPaymentPercentOfInitialFunding
      remainderValue
    }
  }
  gratAnnuityV2s(where: $annuitiesLike) {
    edges {
      node {
        id
        termDurationYears
        yearOfTerm
        initialFundingValuePercent
        computedPaymentAmount
      }
    }
  }
}
    `;

/**
 * __useGetAnnuitiesDetailsQuery__
 *
 * To run a query within a React component, call `useGetAnnuitiesDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnuitiesDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnuitiesDetailsQuery({
 *   variables: {
 *      annuitiesLike: // value for 'annuitiesLike'
 *      projectionParams: // value for 'projectionParams'
 *   },
 * });
 */
export function useGetAnnuitiesDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetAnnuitiesDetailsQuery, GetAnnuitiesDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnuitiesDetailsQuery, GetAnnuitiesDetailsQueryVariables>(GetAnnuitiesDetailsDocument, options);
      }
export function useGetAnnuitiesDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnuitiesDetailsQuery, GetAnnuitiesDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnuitiesDetailsQuery, GetAnnuitiesDetailsQueryVariables>(GetAnnuitiesDetailsDocument, options);
        }
export function useGetAnnuitiesDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAnnuitiesDetailsQuery, GetAnnuitiesDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAnnuitiesDetailsQuery, GetAnnuitiesDetailsQueryVariables>(GetAnnuitiesDetailsDocument, options);
        }
export type GetAnnuitiesDetailsQueryHookResult = ReturnType<typeof useGetAnnuitiesDetailsQuery>;
export type GetAnnuitiesDetailsLazyQueryHookResult = ReturnType<typeof useGetAnnuitiesDetailsLazyQuery>;
export type GetAnnuitiesDetailsSuspenseQueryHookResult = ReturnType<typeof useGetAnnuitiesDetailsSuspenseQuery>;
export type GetAnnuitiesDetailsQueryResult = Apollo.QueryResult<GetAnnuitiesDetailsQuery, GetAnnuitiesDetailsQueryVariables>;
export const GetNpvDocument = gql`
    query GetNPV($interestRate: Percent!, $cashFlows: [CurrencyUSD!]!) {
  npv(discountPercent: $interestRate, cashFlows: $cashFlows)
}
    `;

/**
 * __useGetNpvQuery__
 *
 * To run a query within a React component, call `useGetNpvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNpvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNpvQuery({
 *   variables: {
 *      interestRate: // value for 'interestRate'
 *      cashFlows: // value for 'cashFlows'
 *   },
 * });
 */
export function useGetNpvQuery(baseOptions: Apollo.QueryHookOptions<GetNpvQuery, GetNpvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNpvQuery, GetNpvQueryVariables>(GetNpvDocument, options);
      }
export function useGetNpvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNpvQuery, GetNpvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNpvQuery, GetNpvQueryVariables>(GetNpvDocument, options);
        }
export function useGetNpvSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetNpvQuery, GetNpvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetNpvQuery, GetNpvQueryVariables>(GetNpvDocument, options);
        }
export type GetNpvQueryHookResult = ReturnType<typeof useGetNpvQuery>;
export type GetNpvLazyQueryHookResult = ReturnType<typeof useGetNpvLazyQuery>;
export type GetNpvSuspenseQueryHookResult = ReturnType<typeof useGetNpvSuspenseQuery>;
export type GetNpvQueryResult = Apollo.QueryResult<GetNpvQuery, GetNpvQueryVariables>;
export const Operations = {
  Query: {
    GetAnnuitiesDetails: 'GetAnnuitiesDetails' as const,
    GetNPV: 'GetNPV' as const
  },
  Fragment: {
    AnnuityFormGRATTrust: 'AnnuityFormGRATTrust' as const
  }
}