import { Box, SxProps, Theme, Typography } from '@mui/material';
import { defaults } from 'lodash';
import React from 'react';
import { SetOptional } from 'type-fest';

import { Card, CardProps } from './Card';

export interface CardWithHeaderProps
  extends Omit<SetOptional<CardProps, 'variant'>, 'title'> {
  title: React.ReactNode;
  headerSx?: SxProps<Theme>;
  contentSx?: SxProps<Theme>;
}

export function CardWithHeader({
  title,
  headerSx,
  contentSx,
  children,
  variant = 'filled',
  ...cardProps
}: CardWithHeaderProps) {
  const defaultedSx = defaults({ p: 3 }, cardProps.sx || {});
  return (
    <Card sx={defaultedSx} variant={variant} {...cardProps}>
      <Typography variant="h4" sx={{ mb: 1, ...headerSx }}>
        {title}
      </Typography>
      <Box sx={contentSx}>{children}</Box>
    </Card>
  );
}
