import { styled, Typography } from '@mui/material';
import { GridApiPro, GridGroupNode, GridRowParams } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { TableRowSkeleton } from '@/components/tables/TableRowSkeleton/TableRowSkeleton';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { useReportError } from '@/hooks/useReportError';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { COLORS } from '@/styles/tokens/colors';
import { AfterDeath } from '@/types/schema';
import { formatCurrencyNoDecimalsAccounting } from '@/utils/formatting/currency';

import {
  TaxOverviewTableRowData,
  TaxOverviewTableRowVariant,
  TaxOverviewTableWaterfallType,
} from './TaxOverviewTable.types';
import { getTaxOverviewTableRows } from './TaxOverviewTable.utils';

export interface TaxOverviewTableProps {
  afterDeath: AfterDeath;
  /**
   * Year of the death event.  If AfterDeath.None, use the year of the first death.
   */
  deathYear: number | null | undefined;
  dyingGrantorName: string | undefined;
  isTwoGrantorHousehold: boolean;
  isMiniView?: boolean;
  waterfall: TaxOverviewTableWaterfallType | null | undefined;
  rows?: TaxOverviewTableRowData[];
  gridAPIRef?: React.MutableRefObject<GridApiPro>;
  loading?: boolean;
}

function getHeaderName(
  afterDeath: AfterDeath,
  deathYear: number | null | undefined,
  dyingGrantorName: string | undefined,
  isTwoGrantorHousehold: boolean,
  isMiniView: boolean
) {
  if (afterDeath === AfterDeath.None) {
    if (dyingGrantorName) {
      return `Before ${dyingGrantorName}'s death (${deathYear})`;
    } else if (isTwoGrantorHousehold) {
      return `Before first death (${deathYear})`;
    }
    return `Before death (${deathYear})`;
  }

  // special case -- if mini view and after first death, present it as before the second death
  if (isMiniView && afterDeath === AfterDeath.First) {
    return `Before ${dyingGrantorName}'s death (${deathYear})`;
  }

  if (dyingGrantorName) {
    return `After ${dyingGrantorName}'s death (${deathYear})`;
  }

  if (isTwoGrantorHousehold) {
    return `After ${afterDeath === AfterDeath.First ? 'first' : 'second'} death (${deathYear})`;
  }

  return `After death (${deathYear})`;
}

function getColumns(
  afterDeath: AfterDeath,
  deathYear: number | null | undefined,
  dyingGrantorName: string | undefined,
  isTwoGrantorHousehold: boolean,
  isMiniView: boolean
): Column<TaxOverviewTableRowData>[] {
  const headerName = getHeaderName(
    afterDeath,
    deathYear,
    dyingGrantorName,
    isTwoGrantorHousehold,
    isMiniView
  );
  if (afterDeath === AfterDeath.None) {
    return [
      {
        field: 'name',
        headerName: headerName,
        flex: 1,
        renderCell: TwoLineTextRenderer({
          lineOne: ({ row }) =>
            row.variant === TaxOverviewTableRowVariant.Subtitle ? (
              <Typography
                variant="h6"
                component="span"
                style={{
                  color: COLORS.GRAY[600],
                }}
              >
                {row.name?.lineOne}
              </Typography>
            ) : (
              row.name?.lineOne
            ),
          lineTwo: ({ row }) => row.name?.lineTwo,
          lineOneProps: ({ row }) => ({
            sx: {
              fontWeight:
                row.variant || !row.path || row.path.length < 2
                  ? 'bold'
                  : undefined,
              pl: row.path.length > 1 ? 2 : undefined,
            },
          }),
          lineTwoProps: ({ row }) => ({
            sx: {
              pl: row.path.length > 1 ? 2 : undefined,
            },
          }),
        }),
        sortable: false,
        disableReorder: true,
      },
      {
        field: 'noDeathValue',
        headerName: 'Today',
        flex: 1,
        renderCell: TextRenderer({
          text: ({ row }) =>
            row.noDeathValue
              ? formatCurrencyNoDecimalsAccounting(row.noDeathValue)
              : undefined,
          textProps: ({ row }) => ({
            sx: {
              color:
                row.displayAsNegative && row.noDeathValue?.greaterThan(0)
                  ? COLORS.MATH.NEGATIVE
                  : 'inherit',
              fontWeight:
                row.variant === TaxOverviewTableRowVariant.Subtotal
                  ? 'bold'
                  : 'inherit',
            },
          }),
        }),
        sortable: false,
        disableReorder: true,
        align: 'right',
      },
      {
        field: 'projectedValue',
        headerName: deathYear ? deathYear.toString() : 'At year of death',
        flex: 1,
        renderCell: TextRenderer({
          text: ({ row }) =>
            row.projectedValue
              ? formatCurrencyNoDecimalsAccounting(row.projectedValue)
              : undefined,
          textProps: ({ row }) => ({
            sx: {
              color:
                row.displayAsNegative && row.projectedValue?.greaterThan(0)
                  ? COLORS.MATH.NEGATIVE
                  : 'inherit',
              fontWeight:
                row.variant === TaxOverviewTableRowVariant.Subtotal
                  ? 'bold'
                  : 'inherit',
            },
          }),
        }),
        sortable: false,
        disableReorder: true,
        align: 'right',
      },
    ];
  }
  return [
    {
      field: 'name',
      headerName,
      flex: 1,
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) =>
          row.variant === TaxOverviewTableRowVariant.Subtitle ? (
            <Typography
              variant="h6"
              component="span"
              style={{
                color: COLORS.GRAY[600],
              }}
            >
              {row.name?.lineOne}
            </Typography>
          ) : (
            row.name?.lineOne
          ),
        lineTwo: ({ row }) => row.name?.lineTwo,
        lineOneProps: ({ row }) => ({
          sx: {
            fontWeight:
              row.variant || row.boldNameOverride ? 'bold' : undefined,
            pl: row.path.length > 1 ? 2 : undefined,
          },
        }),
        lineTwoProps: ({ row }) => ({
          sx: {
            pl: row.path.length > 1 ? 2 : undefined,
          },
        }),
      }),
      sortable: false,
      disableReorder: true,
    },
    {
      field: 'projectedValue',
      headerName: deathYear ? deathYear.toString() : 'At year of death',
      flex: 1,
      renderCell: TextRenderer({
        text: ({ row }) =>
          row.projectedValue
            ? formatCurrencyNoDecimalsAccounting(row.projectedValue)
            : undefined,
        textProps: ({ row }) => ({
          sx: {
            color:
              row.displayAsNegative && row.projectedValue?.greaterThan(0)
                ? COLORS.MATH.NEGATIVE
                : 'inherit',
            fontWeight:
              row.variant === TaxOverviewTableRowVariant.Subtotal
                ? 'bold'
                : 'inherit',
          },
        }),
      }),
      sortable: false,
      disableReorder: true,
      align: 'right',
    },
  ];
}

const getTreeDataPath = (row: TaxOverviewTableRowData) => row.path;

const useRowStyles = makeStyles()(() => ({
  subtotalRowStyle: {
    borderTop: `2px solid ${COLORS.NAVY[600]}`,
  },
  subtitleRowStyle: {
    backgroundColor: `${COLORS.GRAY[100]} !important`,
    fontWeight: 'bold !important',
    color: `${COLORS.PRIMITIVES.WHITE} !important`,
  },
}));

const StyledDataTable = styled(DataTable)(({ theme }) => ({
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.main,
    color: `${COLORS.PRIMITIVES.WHITE} !important`,
    fontWeight: 'bold',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  '& .MuiDataGrid-columnHeader:not(:last-child)': {
    borderRight: 'none',
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    '& > .MuiStack-root > .MuiTypography-root': {
      color: `${COLORS.PRIMITIVES.WHITE} !important`,
    },
  },
}));

type ExpansionState = Record<string, boolean>;

export function TaxOverviewTable({
  afterDeath,
  deathYear,
  waterfall,
  dyingGrantorName,
  isTwoGrantorHousehold,
  isMiniView = false,
  rows: externalRows,
  gridAPIRef,
  loading,
}: TaxOverviewTableProps) {
  const { classes } = useRowStyles();
  const { federalEstateTaxPercent } = useIRSConstants();
  const viewOnly = useViewOnly();
  const expansionState = useRef<ExpansionState>({});
  const { reportError } = useReportError();

  const rows = useMemo(() => {
    if (loading) {
      let rowCount;
      if (isMiniView) rowCount = 4;
      else if (afterDeath === AfterDeath.None) rowCount = 20;
      else if (afterDeath === AfterDeath.First) rowCount = 16;
      else if (afterDeath === AfterDeath.Second) rowCount = 14;
      return new Array(rowCount)
        .fill(null)
        .map((_, index) => (
          <TableRowSkeleton
            key={`${afterDeath}-${index}`}
            columns={afterDeath === AfterDeath.None ? 3 : 2}
          />
        ));
    }
    if (externalRows) return externalRows;

    return getTaxOverviewTableRows({
      afterDeath,
      federalEstateTaxPercent,
      waterfall,
      isTwoGrantorHousehold,
      isMiniView,
    });
  }, [
    loading,
    externalRows,
    afterDeath,
    federalEstateTaxPercent,
    waterfall,
    isTwoGrantorHousehold,
    isMiniView,
  ]);

  // use updateRows to update the rows and retain tree data expansion state; simply updating the
  // rows prop destroys the row expansion state
  // https://github.com/mui/mui-x/issues/7771
  const initialRowsRef = useRef(rows);
  useEffect(() => {
    if (!gridAPIRef) return;
    if (rows !== initialRowsRef.current) {
      gridAPIRef.current.setRows(rows);
    }
  }, [gridAPIRef, rows]);

  useEffect(() => {
    if (!gridAPIRef) return;

    // also support rowExpansionChange events, which will happen when the user clicks on the button
    // directly rather than through the row click handler
    return gridAPIRef.current.subscribeEvent('rowExpansionChange', (node) => {
      expansionState.current[node.id] = node.childrenExpanded ?? false;
    });
  }, [gridAPIRef]);

  const handleRowClick = useCallback(
    (params: GridRowParams<TaxOverviewTableRowData>) => {
      if (!gridAPIRef) return;
      const isExpanded = expansionState.current[params.row.id];

      // can only expand/collapse rows with children
      if (!params.row.hasChildren) {
        return;
      }

      // just because a row has the children toggle doesn't mean it has children
      const path = params.row.path.join(' ');
      const hasChildren = rows.some((row) => {
        const rowPath = (row as TaxOverviewTableRowData).path.join(' ');
        return rowPath.startsWith(path) && rowPath !== path;
      });

      if (!hasChildren) {
        return;
      }

      try {
        gridAPIRef.current?.setRowChildrenExpansion(params.row.id, !isExpanded);
        expansionState.current[params.row.id] = !isExpanded;
      } catch (err) {
        // hide the error from the user, but still report it to sentry
        reportError('Error expanding/collapsing row', err as Error);
      }
    },
    [gridAPIRef, reportError, rows]
  );

  const isGroupExpanded = useCallback(
    (node: GridGroupNode) => expansionState.current[node.id] ?? false,
    []
  );
  return (
    <StyledDataTable
      loading={loading}
      apiRef={gridAPIRef}
      data-testid={`tax-overview-table-${afterDeath}`}
      treeData={!viewOnly}
      columns={getColumns(
        afterDeath,
        deathYear,
        dyingGrantorName,
        isTwoGrantorHousehold,
        isMiniView
      )}
      rows={rows}
      disableColumnReorder
      disableRowSelectionOnClick
      disableMultipleRowSelection
      disableVirtualization
      disableColumnResize
      disableAutosize
      defaultGroupingExpansionDepth={viewOnly ? -1 : 0}
      pagination={false}
      getTreeDataPath={viewOnly ? undefined : getTreeDataPath}
      isGroupExpandedByDefault={isGroupExpanded}
      onRowClick={handleRowClick}
      slots={{
        footer: () => null,
        columnResizeIcon: () => null,
      }}
      getRowClassName={({ row }) => {
        switch ((row as TaxOverviewTableRowData).variant) {
          case TaxOverviewTableRowVariant.Subtotal:
            return classes.subtotalRowStyle;
          case TaxOverviewTableRowVariant.Subtitle:
            return classes.subtitleRowStyle;
          default:
            return '';
        }
      }}
    />
  );
}
