import { Box } from '@mui/material';
import { isNumber } from 'lodash';
import React from 'react';
import { Control, UseFormResetField } from 'react-hook-form';

import { Link } from '@/components/form/baseInputs/Link/Link';
import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Grid } from '@/components/layout/Grid';
import { Alert } from '@/components/notifications/Alert/Alert';
import { GRATTerm } from '@/modules/content/tooltipContent/GRATTerm';
import { GRATType } from '@/modules/content/tooltipContent/GRATType';
import { Rate7520 } from '@/modules/content/tooltipContent/Rate7520';
import { Rate7520Input } from '@/modules/trusts/Rate7520Input/Rate7520Input';

import { Fields, NAMESPACE, RollingType, TermsSubformType } from '../types';
import { useGet7520RateQuery } from './graphql/InterestRate.generated';
import {
  getInterestRateSummaryText,
  getRollingGRATFieldDescription,
  getTermLengthValue,
  GRAT_TERM_OPTIONS,
  GRAT_TERM_VALUES,
} from './utils';

interface Props {
  control: Control<Fields>;
  subformValues: TermsSubformType;
  resetField: UseFormResetField<Fields>;
  itemWidth: 6 | 12;
}

export function TermsFormBody({ control, subformValues, itemWidth }: Props) {
  const { data: interestRateSummary } = useGet7520RateQuery();
  // if the user chooses a GRAT of 10+ years, we use the termsLengthExtended field.
  const termLength = getTermLengthValue(
    subformValues.termLength,
    subformValues.termLengthExtended
  );

  return (
    <Grid container spacing={3} columns={12}>
      <Grid item sm={itemWidth}>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareButtonGroup<Fields>
              fieldName={`${NAMESPACE}.type`}
              label="Rolling GRAT"
              control={control}
              contextualHelp={<GRATType />}
              required
              options={[
                { display: 'Yes', value: RollingType.ROLLING },
                { display: 'No', value: RollingType.STANDARD },
              ]}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      </Grid>
      <Grid item sm={itemWidth}>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareButtonGroup<Fields>
              label="GRAT term"
              fieldName={`${NAMESPACE}.termLength`}
              contextualHelp={<GRATTerm />}
              control={control}
              required
              options={GRAT_TERM_OPTIONS}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      </Grid>
      {subformValues.termLength === GRAT_TERM_VALUES.TEN_OR_MORE && (
        <Grid item sm={itemWidth} order={4}>
          <FormLayoutRow>
            <FormLayoutItem width={4}>
              <FormAwareFormattedNumberInput<Fields>
                control={control}
                label="Term length"
                fieldName={`${NAMESPACE}.termLengthExtended`}
                fixedDecimalScale={true}
                decimalScale={0}
                required
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </Grid>
      )}
      {subformValues.type === RollingType.ROLLING &&
        subformValues.termLength && (
          <Grid
            item
            sm={itemWidth}
            sx={{
              order: 4,
            }}
          >
            <FormLayoutRow>
              <FormLayoutItem width={4}>
                <FormAwareFormattedNumberInput<Fields>
                  control={control}
                  label="Rolling period"
                  fieldName={`${NAMESPACE}.rollingPeriod`}
                  fixedDecimalScale={true}
                  decimalScale={0}
                  validation={{
                    required: (value) => {
                      if (subformValues.type === RollingType.STANDARD)
                        return undefined;
                      if (!value && subformValues.type === RollingType.ROLLING)
                        return `Rolling period is required.`;
                      if (
                        value &&
                        isNumber(value) &&
                        value <= parseInt(termLength)
                      )
                        return `Rolling period must be greater than the term length.`;
                      return undefined;
                    },
                  }}
                  required
                />
              </FormLayoutItem>
              <FormLayoutItem width={8}>
                <Alert size="sm" sx={{ mt: 3 }} severity="info-high">
                  {getRollingGRATFieldDescription(
                    termLength,
                    subformValues.rollingPeriod
                  )}
                </Alert>
              </FormLayoutItem>
            </FormLayoutRow>
          </Grid>
        )}

      <Grid
        item
        sm={itemWidth}
        sx={{
          order: 3,
        }}
      >
        <FormLayoutRow>
          <FormLayoutItem width={4}>
            <Rate7520Input<Fields>
              control={control}
              fieldName={`${NAMESPACE}.rate7520`}
              contextualHelp={<Rate7520 />}
              required
            />
          </FormLayoutItem>
          <FormLayoutItem width={8}>
            <Alert size="sm" sx={{ mt: 3 }} severity="info-low">
              {getInterestRateSummaryText(
                interestRateSummary?.official7520InterestRate
              )}
              <Box mt={1}>
                <Link
                  display="Learn more"
                  href="https://www.irs.gov/businesses/small-businesses-self-employed/section-7520-interest-rates"
                  external
                />
              </Box>
            </Alert>
          </FormLayoutItem>
        </FormLayoutRow>
      </Grid>
    </Grid>
  );
}
