import Decimal from 'decimal.js';

import { TileSummary } from '@/components/diagrams/components/Tile/TileSummary';
import { TileSummaryItem } from '@/components/diagrams/components/Tile/TileSummaryItem';

import { useWaterfallSummaryContext } from '../../contexts/waterfallSummary.context';
import { GetWaterfallSummary_EstateWaterfallFragment } from '../../graphql/GetWaterfallSummary.generated';
import { useShowSummaryTileDetails } from '../../hooks/useShowSummaryTileDetails.hook';
import { WaterfallBarChartSection } from '../EstateWaterfallSummaryBarCharts/hooks/useWaterfallSummaryBarChartSections';
import { TileSection } from './components/TileSection';
import {
  formatCompactValue,
  getNameFromNode,
  getSummaryTileNode,
} from './EstateWaterfallSummaryTiles.utils';
import { useShowMoreSplit } from './hooks/useShowMoreSplit.hook';

export interface FirstDeathSummarySectionProps {
  waterfall: GetWaterfallSummary_EstateWaterfallFragment;
  section: WaterfallBarChartSection;
  showDetails?: boolean;
}

export const FirstDeathSummarySection = ({
  waterfall,
  section,
}: FirstDeathSummarySectionProps) => {
  const showSummaryTileDetails = useShowSummaryTileDetails(waterfall.id);
  const inEstate = section.waterfallSections[0];
  const grantorName = waterfall.household?.possiblePrimaryClients.find(
    (c) => c.id !== waterfall.firstGrantorDeath.id
  )?.firstName;
  const householdId = waterfall.household?.id as unknown as string;

  const firstDeathGrantorName =
    waterfall.household?.possiblePrimaryClients.find(
      (c) => c.id === waterfall.firstGrantorDeath.id
    )?.firstName;

  const { visible, remainingLabel, remaining, remainingSum } = useShowMoreSplit(
    inEstate.nodes
  );

  const {
    summaryPanel: { openModal },
  } = useWaterfallSummaryContext();

  const title = `Net to ${grantorName}`;

  const inEstateDetails = (
    <>
      {visible.map(({ id, node, value }) => {
        return (
          <TileSummaryItem
            key={id}
            label={getNameFromNode(node)}
            value={formatCompactValue(value)}
          />
        );
      })}
      {!!remaining.length && (
        <TileSummaryItem
          label={remainingLabel}
          value={formatCompactValue(remainingSum)}
        />
      )}
    </>
  );

  return (
    <TileSection label={section.label}>
      <TileSummary
        lineOne={title}
        lineThree={formatCompactValue(inEstate.value)}
        onClick={() =>
          openModal({
            type: 'summaryTileDetails',
            nodes: inEstate.nodes.map(getSummaryTileNode),
            subtitle: `Combined value of assets owned by ${grantorName} and received from ${firstDeathGrantorName} after ${firstDeathGrantorName}'s death in ${waterfall.firstGrantorDeathYear}`,
            title,
            total: new Decimal(inEstate.value),
            firstPrimaryClientDeathId: waterfall.firstGrantorDeath.id,
            householdId,
            waterfallId: waterfall.id,
          })
        }
      >
        {showSummaryTileDetails ? inEstateDetails : null}
      </TileSummary>
    </TileSection>
  );
};
