import { useReactFlow, useStore } from '@xyflow/react';

export function useZoom() {
  const { zoomIn, zoomOut, getZoom } = useReactFlow();

  // Reading maxZoom and minZoom independently isn't enough to trigger re-renders,
  // for some reason they both must be read to get update values
  const { maxZoom, minZoom } = useStore(({ maxZoom, minZoom }) => ({
    maxZoom,
    minZoom,
  }));

  const canZoomIn = getZoom() !== maxZoom;
  const canZoomOut = getZoom() !== minZoom;

  return { canZoomIn, canZoomOut, zoomIn, zoomOut };
}
