import { Stack } from '@mui/material';

import {
  EntityValuationItem,
  EntityValuationItemProps,
} from './EntityValuationItem';

export interface EntityValuationBreakdownProps {
  valuationItems: EntityValuationItemProps[];
}

export function EntityValuationBreakdown({
  valuationItems,
}: EntityValuationBreakdownProps) {
  return (
    <Stack spacing={1}>
      {valuationItems.map((itemProps, i) => {
        return <EntityValuationItem key={i} {...itemProps} />;
      })}
    </Stack>
  );
}
