import { EntityTaskType } from '@/types/schema';

type PickEnum<T, K extends T> = {
  [P in keyof K]: P extends K ? P : never;
};

export const GRAT_IMPLEMENTATION_TASKS = [
  EntityTaskType.GratCompleteDesignSummary,
  EntityTaskType.GratAttorneySelection,
  EntityTaskType.GratShareDesignSummary,
  EntityTaskType.GratUploadTrustDocument,
  EntityTaskType.GratCreateTrustAccount,
  EntityTaskType.GratConfirmFunding,
  EntityTaskType.GratConfirmAnnuities,
  EntityTaskType.GratConfirmAssetTransfer,
  EntityTaskType.GratSetSubstitutionThresholds,
  EntityTaskType.GratFinalizeImplementation,
  EntityTaskType.GratConfirmTrustDetails, // LUM-590 - implement when task body is done
] as const;

// GRAT implementation tasks are a subset of all tasks. We choose to redeclare
export type GRATImplementationTask = PickEnum<
  EntityTaskType,
  (typeof GRAT_IMPLEMENTATION_TASKS)[number]
>;
