import { Stack } from '@mui/material';
import { useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { TypeaheadSelectInput } from '@/components/form/baseInputs/TypeaheadSelectInput/TypeaheadSelectInput';
import {
  DialogModal,
  DialogModalProps,
} from '@/components/modals/DialogModal/DialogModal';
import { Callout } from '@/components/notifications/Callout/Callout';

import { CopyDispositionsResult } from './CopyDispositiveProvisionsModal.types';
import {
  getCopyDispositionsResult,
  useCopyDispositionOptions,
} from './CopyDispositiveProvisionsModal.utils';

export interface CopyDispositiveProvisionsModalProps
  extends Omit<DialogModalProps, 'onClose' | 'heading'> {
  onClose: (result: CopyDispositionsResult | null) => void;
  hasRecipients: boolean;
}

export function CopyDispositiveProvisionsModal({
  onClose,
  hasRecipients,
  ...modalProps
}: CopyDispositiveProvisionsModalProps) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedCopyFromEntityId, setSelectedCopyFromEntityId] = useState<
    string | null
  >(null);
  const { options, data } = useCopyDispositionOptions();

  function handleCopyDispositions() {
    if (!data) return;
    if (!selectedCopyFromEntityId) {
      setErrorMessage('Please select a source to copy dispositions from');
      return;
    }

    const result = getCopyDispositionsResult(selectedCopyFromEntityId, data);
    if (!result) {
      setErrorMessage('No dispositions to copy');
      return;
    }

    onClose(result);
  }

  function handleChange(value: string | null) {
    setErrorMessage(null);
    setSelectedCopyFromEntityId(value);
  }

  return (
    <DialogModal
      heading="Select a source to copy dispositions from"
      onClose={() => onClose(null)}
      {...modalProps}
    >
      <Stack spacing={2}>
        <TypeaheadSelectInput<string>
          groupBy={(option) => option.groupName ?? ''}
          placeholder="Select a source"
          value={selectedCopyFromEntityId ?? ''}
          onChange={(_e, value) => handleChange(value)}
          options={options}
          label="Source to copy from"
          hideLabel
          errorMessage={errorMessage ?? undefined}
          data-testid="copy-dispositions-from-select"
        />

        {hasRecipients && (
          <Callout severity="info-high">
            Any existing dispositions will be replaced with those from the
            source selected above
          </Callout>
        )}

        <Button
          variant="primary"
          size="lg"
          fullWidth
          onClick={handleCopyDispositions}
        >
          Copy dispositions
        </Button>
      </Stack>
    </DialogModal>
  );
}
