// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from '../../../../graphql/fragments/pageInfo.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminBalanceSheetConfigurationPage_AssetClassFragment = { __typename?: 'AssetClass', id: string, displayName: string };

export type AdminBalanceSheetConfigurationPage_BalanceSheetCategoryFragment = { __typename?: 'BalanceSheetCategory', id: string, displayName: string, sortOrder: number, isSystemCategory: boolean, type: Types.BalanceSheetCategoryType, assetClasses: { __typename?: 'AssetClassConnection', edges?: Array<{ __typename?: 'AssetClassEdge', node?: { __typename?: 'AssetClass', id: string, displayName: string } | null } | null> | null } };

export type BalanceSheetCategoriesQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.BalanceSheetCategoryWhereInput>;
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['Cursor']['input']>;
}>;


export type BalanceSheetCategoriesQuery = { __typename?: 'Query', paginatedData: { __typename?: 'BalanceSheetCategoryConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: any | null, endCursor?: any | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'BalanceSheetCategoryEdge', node?: { __typename?: 'BalanceSheetCategory', id: string, displayName: string, sortOrder: number, isSystemCategory: boolean, type: Types.BalanceSheetCategoryType, assetClasses: { __typename?: 'AssetClassConnection', edges?: Array<{ __typename?: 'AssetClassEdge', node?: { __typename?: 'AssetClass', id: string, displayName: string } | null } | null> | null } } | null } | null> | null } };

export type UpdateBalanceSheetCategoriesMutationVariables = Types.Exact<{
  clearAssetClassInputs: Array<Types.InputMaybe<Types.AugmentedUpdateBalanceSheetCategoryInput>> | Types.InputMaybe<Types.AugmentedUpdateBalanceSheetCategoryInput>;
  createInputs: Array<Types.InputMaybe<Types.AugmentedCreateBalanceSheetCategoryInput>> | Types.InputMaybe<Types.AugmentedCreateBalanceSheetCategoryInput>;
  updateInputs: Array<Types.InputMaybe<Types.AugmentedUpdateBalanceSheetCategoryInput>> | Types.InputMaybe<Types.AugmentedUpdateBalanceSheetCategoryInput>;
}>;


export type UpdateBalanceSheetCategoriesMutation = { __typename?: 'Mutation', clearBalanceSheetAssetClasses: Array<{ __typename?: 'BalanceSheetCategory', id: string, displayName: string, sortOrder: number, isSystemCategory: boolean, type: Types.BalanceSheetCategoryType, assetClasses: { __typename?: 'AssetClassConnection', edges?: Array<{ __typename?: 'AssetClassEdge', node?: { __typename?: 'AssetClass', id: string, displayName: string } | null } | null> | null } } | null>, createBalanceSheetCategories: Array<{ __typename?: 'BalanceSheetCategory', id: string, displayName: string, sortOrder: number, isSystemCategory: boolean, type: Types.BalanceSheetCategoryType, assetClasses: { __typename?: 'AssetClassConnection', edges?: Array<{ __typename?: 'AssetClassEdge', node?: { __typename?: 'AssetClass', id: string, displayName: string } | null } | null> | null } } | null>, updateBalanceSheetCategories: Array<{ __typename?: 'BalanceSheetCategory', id: string, displayName: string, sortOrder: number, isSystemCategory: boolean, type: Types.BalanceSheetCategoryType, assetClasses: { __typename?: 'AssetClassConnection', edges?: Array<{ __typename?: 'AssetClassEdge', node?: { __typename?: 'AssetClass', id: string, displayName: string } | null } | null> | null } } | null> };

export type DeleteBalanceSheetCategoryMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteBalanceSheetCategoryMutation = { __typename?: 'Mutation', deleteBalanceSheetCategory: string };

export const AdminBalanceSheetConfigurationPage_AssetClassFragmentDoc = gql`
    fragment AdminBalanceSheetConfigurationPage_AssetClass on AssetClass {
  id
  displayName
}
    `;
export const AdminBalanceSheetConfigurationPage_BalanceSheetCategoryFragmentDoc = gql`
    fragment AdminBalanceSheetConfigurationPage_BalanceSheetCategory on BalanceSheetCategory {
  id
  displayName
  sortOrder
  isSystemCategory
  type
  assetClasses {
    edges {
      node {
        ...AdminBalanceSheetConfigurationPage_AssetClass
      }
    }
  }
}
    ${AdminBalanceSheetConfigurationPage_AssetClassFragmentDoc}`;
export const BalanceSheetCategoriesDocument = gql`
    query BalanceSheetCategories($where: BalanceSheetCategoryWhereInput, $first: Int!, $after: Cursor) {
  paginatedData: balanceSheetCategories(
    where: $where
    first: $first
    after: $after
    orderBy: [{direction: ASC, field: sort_order}]
  ) {
    totalCount
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        ...AdminBalanceSheetConfigurationPage_BalanceSheetCategory
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${AdminBalanceSheetConfigurationPage_BalanceSheetCategoryFragmentDoc}`;

/**
 * __useBalanceSheetCategoriesQuery__
 *
 * To run a query within a React component, call `useBalanceSheetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBalanceSheetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBalanceSheetCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBalanceSheetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<BalanceSheetCategoriesQuery, BalanceSheetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BalanceSheetCategoriesQuery, BalanceSheetCategoriesQueryVariables>(BalanceSheetCategoriesDocument, options);
      }
export function useBalanceSheetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BalanceSheetCategoriesQuery, BalanceSheetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BalanceSheetCategoriesQuery, BalanceSheetCategoriesQueryVariables>(BalanceSheetCategoriesDocument, options);
        }
export function useBalanceSheetCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BalanceSheetCategoriesQuery, BalanceSheetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BalanceSheetCategoriesQuery, BalanceSheetCategoriesQueryVariables>(BalanceSheetCategoriesDocument, options);
        }
export type BalanceSheetCategoriesQueryHookResult = ReturnType<typeof useBalanceSheetCategoriesQuery>;
export type BalanceSheetCategoriesLazyQueryHookResult = ReturnType<typeof useBalanceSheetCategoriesLazyQuery>;
export type BalanceSheetCategoriesSuspenseQueryHookResult = ReturnType<typeof useBalanceSheetCategoriesSuspenseQuery>;
export type BalanceSheetCategoriesQueryResult = Apollo.QueryResult<BalanceSheetCategoriesQuery, BalanceSheetCategoriesQueryVariables>;
export const UpdateBalanceSheetCategoriesDocument = gql`
    mutation UpdateBalanceSheetCategories($clearAssetClassInputs: [AugmentedUpdateBalanceSheetCategoryInput]!, $createInputs: [AugmentedCreateBalanceSheetCategoryInput]!, $updateInputs: [AugmentedUpdateBalanceSheetCategoryInput]!) {
  clearBalanceSheetAssetClasses: updateBalanceSheetCategories(
    inputs: $clearAssetClassInputs
  ) {
    ...AdminBalanceSheetConfigurationPage_BalanceSheetCategory
  }
  createBalanceSheetCategories(inputs: $createInputs) {
    ...AdminBalanceSheetConfigurationPage_BalanceSheetCategory
  }
  updateBalanceSheetCategories(inputs: $updateInputs) {
    ...AdminBalanceSheetConfigurationPage_BalanceSheetCategory
  }
}
    ${AdminBalanceSheetConfigurationPage_BalanceSheetCategoryFragmentDoc}`;
export type UpdateBalanceSheetCategoriesMutationFn = Apollo.MutationFunction<UpdateBalanceSheetCategoriesMutation, UpdateBalanceSheetCategoriesMutationVariables>;

/**
 * __useUpdateBalanceSheetCategoriesMutation__
 *
 * To run a mutation, you first call `useUpdateBalanceSheetCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBalanceSheetCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBalanceSheetCategoriesMutation, { data, loading, error }] = useUpdateBalanceSheetCategoriesMutation({
 *   variables: {
 *      clearAssetClassInputs: // value for 'clearAssetClassInputs'
 *      createInputs: // value for 'createInputs'
 *      updateInputs: // value for 'updateInputs'
 *   },
 * });
 */
export function useUpdateBalanceSheetCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBalanceSheetCategoriesMutation, UpdateBalanceSheetCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBalanceSheetCategoriesMutation, UpdateBalanceSheetCategoriesMutationVariables>(UpdateBalanceSheetCategoriesDocument, options);
      }
export type UpdateBalanceSheetCategoriesMutationHookResult = ReturnType<typeof useUpdateBalanceSheetCategoriesMutation>;
export type UpdateBalanceSheetCategoriesMutationResult = Apollo.MutationResult<UpdateBalanceSheetCategoriesMutation>;
export type UpdateBalanceSheetCategoriesMutationOptions = Apollo.BaseMutationOptions<UpdateBalanceSheetCategoriesMutation, UpdateBalanceSheetCategoriesMutationVariables>;
export const DeleteBalanceSheetCategoryDocument = gql`
    mutation DeleteBalanceSheetCategory($id: ID!) {
  deleteBalanceSheetCategory(id: $id)
}
    `;
export type DeleteBalanceSheetCategoryMutationFn = Apollo.MutationFunction<DeleteBalanceSheetCategoryMutation, DeleteBalanceSheetCategoryMutationVariables>;

/**
 * __useDeleteBalanceSheetCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteBalanceSheetCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBalanceSheetCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBalanceSheetCategoryMutation, { data, loading, error }] = useDeleteBalanceSheetCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBalanceSheetCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBalanceSheetCategoryMutation, DeleteBalanceSheetCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBalanceSheetCategoryMutation, DeleteBalanceSheetCategoryMutationVariables>(DeleteBalanceSheetCategoryDocument, options);
      }
export type DeleteBalanceSheetCategoryMutationHookResult = ReturnType<typeof useDeleteBalanceSheetCategoryMutation>;
export type DeleteBalanceSheetCategoryMutationResult = Apollo.MutationResult<DeleteBalanceSheetCategoryMutation>;
export type DeleteBalanceSheetCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteBalanceSheetCategoryMutation, DeleteBalanceSheetCategoryMutationVariables>;
export const Operations = {
  Query: {
    BalanceSheetCategories: 'BalanceSheetCategories' as const
  },
  Mutation: {
    UpdateBalanceSheetCategories: 'UpdateBalanceSheetCategories' as const,
    DeleteBalanceSheetCategory: 'DeleteBalanceSheetCategory' as const
  },
  Fragment: {
    AdminBalanceSheetConfigurationPage_AssetClass: 'AdminBalanceSheetConfigurationPage_AssetClass' as const,
    AdminBalanceSheetConfigurationPage_BalanceSheetCategory: 'AdminBalanceSheetConfigurationPage_BalanceSheetCategory' as const
  }
}