import { BaseMutationOptions, FetchResult } from '@apollo/client';
import {
  Box,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { Button } from '@/components/form/baseInputs/Button';
import { Lock01Icon } from '@/components/icons/Lock01Icon';
import { UploadCloud01Icon } from '@/components/icons/UploadCloud01Icon';
import { ProgressBar } from '@/components/progress/ProgressBar/ProgressBar';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { COLORS } from '@/styles/tokens/colors';

import {
  acceptedFileTypes,
  BackgroundStyleState,
  getBackgroundStyle,
  getErrorMessageFromCode,
} from './fileUploaderUtils';
import { UploadedFileData, useUploadFile } from './useUploadFile';

export interface NewFileUpload {
  fileId: string;
  householdId: string;
  fileName: string;
  uploadedByName: string;
  uploadedAt: Date;
}

type Result<Mutation> = FetchResult<Mutation>;

export interface FileUploaderProps<Mutation> {
  sx?: SxProps<Theme>;
  errorMessage?: string;
  generateSignedUploadURL: (
    hash: string,
    options: Partial<BaseMutationOptions>
  ) => Promise<
    Result<Mutation> & {
      signedUploadURL: string;
    }
  >;
  /**
   * onUploadStarted will be called when the upload process has started, with
   * the files that are being uploaded.
   */
  onUploadStarted?: (files: File[]) => unknown;
  /**
   * onUploadComplete will be called when the upload process has completed, with
   * the uploaded files and mutation data.
   * @param uploaded
   */
  onUploadComplete: (uploaded: UploadedFileData<Mutation>[]) => unknown;
  /**
   * onUploadError will be called when the upload process has errored.
   * @param err
   */
  onUploadError?: (err: Error) => unknown;
  acceptTypes?: Partial<typeof acceptedFileTypes>;
  disabled?: boolean;
  allowMultiple?: boolean;
}

/**
 * The FileUploader is responsible for invoking a function to generate a presigned upload URL, and then using that
 * link to persist a given file to S3. At that point, it calls the onUploadComplete callback such that the upstream
 * caller can do whatever it needs to do, e.g. create a Document.
 *
 * It is intentionally decoupled from the backend's concept of a File, because we want to be able to use it in
 * other scenarios, like uploading a tenant's logo.
 */
export function FileUploader<Mutation>({
  sx,
  errorMessage: externalErrorMessage,
  generateSignedUploadURL,
  onUploadStarted,
  onUploadError,
  onUploadComplete,
  acceptTypes = acceptedFileTypes,
  disabled = false,
  allowMultiple = false,
}: FileUploaderProps<Mutation>) {
  const { reportError } = useReportError();
  const trackUserEvent = useTrackUserEvent();

  const [uploadErrorMessage, setUploadErrorMessage] = React.useState<
    string | null
  >(null);

  const [totalFiles, setTotalFiles] = React.useState<number>(0);
  const [singleFileUploadProgress, setSingleFileUploadProgress] =
    React.useState<number>(0);
  const [multipleFileUploadProgress, setMultipleFileUploadProgress] =
    React.useState<number>(0);

  const { uploadFile } = useUploadFile({
    generateSignedUploadURL,
    setSingleFileUploadProgress,
    setMultipleFileUploadProgress,
  });

  const onDrop = React.useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      // NOTE: Currently, uploading file(s) is an all-or-nothing operation.
      // We could probably support partial uploads in the future, but for now,
      // we're keeping it simple.
      // Would require some updated designs / user interactions / error handling.

      const rejectedFile = rejectedFiles[0];

      if (!allowMultiple && acceptedFiles.length > 1) {
        throw new Error(
          'This component does not currently support multiple files.'
        );
      } else if (rejectedFile) {
        setUploadErrorMessage(getErrorMessageFromCode(rejectedFile));
        return;
      }

      try {
        setTotalFiles(acceptedFiles.length);
        setUploadErrorMessage(null);
        onUploadStarted?.(acceptedFiles);
        const uploadResults = await Promise.all(
          acceptedFiles.map((file) => uploadFile(file))
        );
        onUploadComplete(uploadResults);
        trackUserEvent('document uploaded', { count: acceptedFiles.length });
      } catch (error) {
        setUploadErrorMessage(
          "We weren't able to upload your file. Please try again."
        );
        onUploadError?.(error as Error);
        reportError('failed to upload file', error as Error);
      } finally {
        setSingleFileUploadProgress(0);
        setMultipleFileUploadProgress(0);
        setTotalFiles(0);
      }
    },
    [
      allowMultiple,
      onUploadComplete,
      onUploadError,
      onUploadStarted,
      reportError,
      trackUserEvent,
      uploadFile,
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: allowMultiple,
    onDrop,
    accept: acceptTypes,
    disabled,
  });

  const errorMessage = externalErrorMessage || uploadErrorMessage;
  const state: BackgroundStyleState = (() => {
    if (errorMessage) return 'error';
    if (isDragActive) return 'hover';
    return 'normal';
  })();
  const backgroundStyle = getBackgroundStyle(state);
  const theme = useTheme();

  return (
    <Box>
      <Stack>
        <Box
          sx={{
            ...sx,
            ...backgroundStyle,
            p: 3,
            borderRadius: `${theme.spacing(0.75)} ${theme.spacing(0.75)} 0 0`,
            border: `solid ${COLORS.GRAY[200]} 1px`,
            borderBottom: 'none',
          }}
          {...getRootProps()}
        >
          <Stack direction="row" alignItems="center">
            <UploadCloud01Icon color={COLORS.GRAY[400]} size={50} />
            {/* minheight here to keep the box size more consistent while the contents of the box change */}
            <Stack
              ml={3}
              flexGrow={1}
              minHeight={72}
              direction="row"
              alignItems="center"
            >
              {singleFileUploadProgress && totalFiles ? (
                <Box width="100%">
                  {singleFileUploadProgress && totalFiles === 1 && (
                    <ProgressBar
                      variant="determinate"
                      value={singleFileUploadProgress}
                    />
                  )}
                  {totalFiles > 1 && (
                    <ProgressBar
                      variant="determinate"
                      value={(multipleFileUploadProgress / totalFiles) * 100}
                    />
                  )}
                  <Typography variant="body1" mt={0.5}>
                    Uploading...
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Box>
                    <Typography variant="h4" component="span">
                      Drag & drop
                    </Typography>
                    &nbsp;
                    <Typography variant="body2" component="span">
                      to upload file{allowMultiple ? 's' : ''} or
                    </Typography>
                  </Box>
                  <Box mt={1}>
                    <Button
                      type="button"
                      variant="primary"
                      size="sm"
                      disabled={disabled}
                    >
                      {errorMessage
                        ? 'Try again'
                        : `Browse file${allowMultiple ? 's' : ''}`}
                    </Button>
                  </Box>
                  {errorMessage && (
                    <Typography
                      mt={1}
                      variant="subtitle2"
                      fontSize={14}
                      color={COLORS.FUNCTIONAL.ERROR.DEFAULT}
                    >
                      {errorMessage}
                    </Typography>
                  )}
                </Box>
              )}
              <input
                data-testid="file-uploader"
                {...getInputProps()}
                disabled={disabled}
              />
            </Stack>
          </Stack>
        </Box>
        <Stack
          bgcolor={COLORS.GRAY[200]}
          p={1}
          sx={{
            borderRadius: `0 0 ${theme.spacing(0.75)} ${theme.spacing(0.75)}`,
          }}
          direction="row"
          alignItems="center"
          spacing={1}
        >
          <Stack
            bgcolor={COLORS.GRAY[400]}
            borderRadius={theme.spacing(0.5)}
            padding={theme.spacing(0.2)}
          >
            <Lock01Icon
              sx={{
                color: COLORS.GRAY[200],
                display: 'flex',
                alignItems: 'center',
              }}
              size={16}
            />
          </Stack>
          <Typography variant="subtitle2" display="flex">
            Luminary is equipped with enterprise-grade security and every file
            is encrypted using AES 256-bit encryption.
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
