import { Box, Stack } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { WithClasses } from '@/styles/types';

import { HeaderList, HeaderListVariant } from '../HeaderList/HeaderList';
import { UnorderedList } from '../UnorderedList';
import { GroupedHeaderListItem } from './GroupedHeaderList.types';

const useStyles = makeStyles()((_theme) => ({
  parentList: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  parentListItem: {
    margin: 0,
    padding: 0,
  },
}));

export interface GroupedHeaderListProps<T> {
  classes?: WithClasses<typeof useStyles>;
  rowGroups: GroupedHeaderListItem<T>[];
  listVariant?: HeaderListVariant;
  ItemComponent: React.ComponentType<{ item: T }>;
  withDividers?: boolean;
}

function useGroupedRows<T>(
  rowGroups: GroupedHeaderListItem<T>[]
): GroupedHeaderListItem<T>[] {
  return rowGroups.map((group) => ({
    heading: group.heading,
    noItemsText: group.noItemsText,
    items: group.items,
  }));
}

function getNoItemsText(heading?: string, noItemsText?: string) {
  if (noItemsText) return noItemsText;
  if (heading) return `No ${heading.toLowerCase()}`;
  return null;
}

// https://www.figma.com/file/FlleF4k1X9St9vv3EgiSGY/Design-System?node-id=1319%3A10920&mode=dev
export function GroupedHeaderList<T extends { id: string }>({
  rowGroups,
  listVariant = 'light',
  ItemComponent,
  withDividers,
  ...props
}: GroupedHeaderListProps<T>) {
  const groupedItems = useGroupedRows<T>(rowGroups);
  const { classes } = useStyles(undefined, { props });

  return (
    <Stack spacing={3} component="ul" className={classes.parentList}>
      {groupedItems.map((group, idx) => (
        <Box
          component="li"
          className={classes.parentListItem}
          // display="flex"
          key={`${group.heading}-${idx}`}
        >
          <HeaderList
            variant={listVariant}
            key={`${group.heading}-${idx}`}
            heading={group.heading}
            noItemsText={getNoItemsText(group.heading, group.noItemsText)}
          >
            {/* we need to explicitly render null here rather than an empty unordered list to have the HeaderList noItemsText behave correctly */}
            {group.items.length === 0 ? null : (
              <UnorderedList withDividers={withDividers}>
                {group.items.map((item) => (
                  <ItemComponent key={item.id} item={item} />
                ))}
              </UnorderedList>
            )}
          </HeaderList>
        </Box>
      ))}
    </Stack>
  );
}
