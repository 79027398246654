import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

export function useLayoutDefinition() {
  const theme = useTheme();
  const isLargeOrLess = useMediaQuery(theme.breakpoints.down('lg'));

  const gridTemplateAreas = `
    "about summary"`;

  const gridTemplateColumns = useMemo(() => {
    if (isLargeOrLess) {
      // 50% 50%
      return '1fr 1fr';
    }

    // 420px rest
    return 'minmax(0, 420px) 1fr';
  }, [isLargeOrLess]);

  return {
    gridTemplateAreas,
    gridTemplateColumns,
  };
}
