import { SubForm } from '@/types/subform';

import {
  annuitiesSubformDefaultValues,
  Fields,
  GRATAnnuitiesSubformType,
  NAMESPACE,
  NamespaceType,
  SubformComponentProps,
  VariantType,
} from './types';

export { NAMESPACE };
export type { Fields };

import {
  EntityFormGRATAnnuitiesSubformComponent,
  GRATAnnuitiesSubformComponent,
} from './ui/GRATAnnuitiesSubformComponent';

export class GRATAnnuitiesSubform
  implements
    SubForm<
      GRATAnnuitiesSubformType,
      Fields,
      NamespaceType,
      VariantType,
      object
    >
{
  FormComponent: (props: SubformComponentProps) => JSX.Element;
  EntityFormComponent: (props: SubformComponentProps) => JSX.Element;
  defaultValues: GRATAnnuitiesSubformType;
  namespace: NamespaceType;

  constructor() {
    this.FormComponent = GRATAnnuitiesSubformComponent;
    this.EntityFormComponent = EntityFormGRATAnnuitiesSubformComponent;
    this.defaultValues = annuitiesSubformDefaultValues;
    this.namespace = NAMESPACE;
  }
}
