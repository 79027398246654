import { Box } from '@mui/material';
import { DocumentNode } from 'graphql';
import { FormProvider } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import { DeleteButton } from '@/components/form/baseInputs/Button/DeleteButton';
import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { shouldRefetchQuery } from '@/graphql/client.utils';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';

import { ClientOrganizationForm } from './ClientOrganizationForm';
import {
  ClientOrganizationFormShape,
  defaultValues,
} from './ClientOrganizationModal.constants';
import {
  mapFormDataToUpdateInput,
  useSyncClientOrganizationDataToForm,
} from './EditClientOrganizationModal.utils';
import {
  useDeleteClientOrganizationMutation,
  useUpdateClientOrganizationForModaMutation,
} from './graphql/ClientOrganizationModal.generated';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'> & {
  organizationId: string;
  requirement?: 'mustBeTrustee' | 'mustBeBeneficiary';
  ignoredQueryDocuments?: DocumentNode[];
};

function EditClientOrganizationModalInner({
  onClose,
  heading = 'Edit organization',
  organizationId,
  requirement,
  ignoredQueryDocuments,
  ...formModalProps
}: FormModalOptionalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { formRef } = useFormSaveHandler();
  const { reset, handleSubmit, shouldBlockNavigation, formState } =
    useFormContext<ClientOrganizationFormShape>();

  const [updateOrganization, { loading: updateLoading }] =
    useUpdateClientOrganizationForModaMutation({
      refetchQueries: 'active',
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        reportError('Could not update client organization', error);
      },
    });

  // Use the delete mutation
  const [deleteOrganization, { loading: deleteLoading }] =
    useDeleteClientOrganizationMutation({
      refetchQueries: 'active',
      onQueryUpdated: (query) => {
        return shouldRefetchQuery(query.queryName, {
          ignoredQueryDocuments,
        });
      },
      onError: (error) => {
        showFeedback(
          'Sorry, we failed to delete this organization. Please try again.'
        );
        reportError('Could not delete client organization', error);
      },
    });

  // Handle delete function
  const handleDelete = async () => {
    if (!organizationId) {
      throw new Error('Cannot delete organization without an id!');
    }
    await deleteOrganization({ variables: { id: organizationId } });
    closeModal();
  };

  // Close modal function
  const closeModal = () => {
    reset();
    onClose();
  };

  useSubmitSuccessHandler(() => {
    closeModal();
  });

  const submitHandler = handleSubmit((formData) => {
    return updateOrganization({
      variables: { input: mapFormDataToUpdateInput(formData, organizationId) },
    });
  });

  return (
    <FormModal
      {...formModalProps}
      onClose={onClose}
      heading={heading}
      shouldBlockClose={shouldBlockNavigation}
      actions={
        <FormModalActions.Provider formState={formState}>
          {/* Add the DeleteButton */}
          <DeleteButton
            loading={deleteLoading}
            onConfirmDelete={handleDelete}
          />
          <Box flexGrow={1} />
          <FormModalActions.CancelButton onClick={closeModal} />
          <FormModalActions.SaveButton
            data-testid="client-organization-modal-save"
            onClick={submitHandler}
            loading={updateLoading}
          >
            Save changes
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={submitHandler} noValidate>
        <ClientOrganizationForm requirement={requirement} variant="edit" />
      </form>
    </FormModal>
  );
}

export const EditClientOrganizationModal = ({
  organizationId,
  ...props
}: FormModalOptionalProps) => {
  const formMethods = useForm<ClientOrganizationFormShape>({ defaultValues });
  useSyncClientOrganizationDataToForm(formMethods.reset, organizationId);

  return (
    <FormProvider {...formMethods}>
      <EditClientOrganizationModalInner
        organizationId={organizationId}
        {...props}
      />
    </FormProvider>
  );
};
