import { includes } from 'lodash';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { EntityKind } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import { ENTITY_TYPES } from '../entities.constants';
import { EntityType } from '../types/EntityType';
import {
  EntityDisplayName,
  getDisplayNameFromEntityType,
} from '../utils/getDisplayNameFromEntityType';
import { getEntityTypeFromEntityKind } from '../utils/getEntityTypeFromEntityKind';

function normalizeParam(param: string): EntityType {
  return param.toLowerCase() as EntityType;
}

export function stringToDisplayType(
  paramType: string
): EntityDisplayName | 'Entity' {
  const normalizedParamType = normalizeParam(paramType);
  const displayName = getDisplayNameFromEntityType(normalizedParamType);
  if (!displayName) {
    diagnostics.warn('unknown entity type', { entity: normalizedParamType });
  }
  return displayName ?? 'Entity';
}

export function stringToEntityType(paramType: string): EntityType {
  const normalizedParamType = normalizeParam(paramType);
  const entityTypes = Object.values(ENTITY_TYPES);
  if (!includes(entityTypes, normalizedParamType)) {
    throw new Error(`Unrecognized entity ${normalizedParamType}`);
  }

  return normalizedParamType;
}

export function entityKindToDisplayName(
  kind: EntityKind
): EntityDisplayName | typeof EMPTY_CONTENT_HYPHEN {
  try {
    const entityType = getEntityTypeFromEntityKind(kind);

    return getDisplayNameFromEntityType(entityType);
  } catch (e) {
    diagnostics.warn('unknown entity kind', { entity: kind });

    return EMPTY_CONTENT_HYPHEN;
  }
}
