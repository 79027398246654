// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { HouseholdHasIntegration_HouseholdFragmentDoc } from './HouseholdHasIntegration.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteHouseholdMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteHouseholdMutation = { __typename?: 'Mutation', deleteHouseholds: number };

export type HouseholdClientProfileFragment = { __typename?: 'ClientProfile', id: string, displayName: string, email?: string | null, isGrantor: boolean, isTrustee: boolean, isBeneficiary: boolean };

export type HouseholdFragment = { __typename?: 'Household', id: string, billableKind: Types.HouseholdBillableKind, primaryRelationshipOwner: { __typename?: 'User', id: string, email: string, displayName: string }, clientProfiles?: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, email?: string | null, isGrantor: boolean, isTrustee: boolean, isBeneficiary: boolean }> | null, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind }> | null };

export type CreateHouseholdMutationVariables = Types.Exact<{
  input: Types.CreateHouseholdInput;
}>;


export type CreateHouseholdMutation = { __typename?: 'Mutation', createHousehold: { __typename?: 'Household', id: string, billableKind: Types.HouseholdBillableKind, primaryRelationshipOwner: { __typename?: 'User', id: string, email: string, displayName: string }, clientProfiles?: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, email?: string | null, isGrantor: boolean, isTrustee: boolean, isBeneficiary: boolean }> | null, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind }> | null } };

export type UpdateHouseholdMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateHouseholdInput;
}>;


export type UpdateHouseholdMutation = { __typename?: 'Mutation', updateHousehold: { __typename?: 'Household', id: string, billableKind: Types.HouseholdBillableKind, primaryRelationshipOwner: { __typename?: 'User', id: string, email: string, displayName: string }, clientProfiles?: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, email?: string | null, isGrantor: boolean, isTrustee: boolean, isBeneficiary: boolean }> | null, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind }> | null } };

export type UpdateHouseholdAndCreateClientProfilesMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateHouseholdInput;
  clientProfileInputs: Array<Types.CreateClientProfileWithDetailsInput> | Types.CreateClientProfileWithDetailsInput;
  relationshipToBetweenPrimaryClients?: Types.InputMaybe<Types.ClientProfileRelationshipType>;
}>;


export type UpdateHouseholdAndCreateClientProfilesMutation = { __typename?: 'Mutation', createClientProfiles: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, email?: string | null, isGrantor: boolean, isTrustee: boolean, isBeneficiary: boolean }>, updateHousehold: { __typename?: 'Household', id: string, billableKind: Types.HouseholdBillableKind, primaryRelationshipOwner: { __typename?: 'User', id: string, email: string, displayName: string }, clientProfiles?: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, email?: string | null, isGrantor: boolean, isTrustee: boolean, isBeneficiary: boolean }> | null, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind }> | null } };

export type UpdateHouseholdsMutationVariables = Types.Exact<{
  inputs: Array<Types.InputMaybe<Types.AugmentedUpdateHouseholdInput>> | Types.InputMaybe<Types.AugmentedUpdateHouseholdInput>;
}>;


export type UpdateHouseholdsMutation = { __typename?: 'Mutation', updateHouseholds: Array<{ __typename?: 'Household', id: string, billableKind: Types.HouseholdBillableKind, primaryRelationshipOwner: { __typename?: 'User', id: string, email: string, displayName: string }, clientProfiles?: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, email?: string | null, isGrantor: boolean, isTrustee: boolean, isBeneficiary: boolean }> | null, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind }> | null } | null> };

export const HouseholdClientProfileFragmentDoc = gql`
    fragment HouseholdClientProfile on ClientProfile {
  id
  displayName
  email
  isGrantor
  isTrustee
  isBeneficiary
}
    `;
export const HouseholdFragmentDoc = gql`
    fragment Household on Household {
  id
  billableKind
  primaryRelationshipOwner {
    id
    email
    displayName
  }
  clientProfiles {
    ...HouseholdClientProfile
  }
  ...HouseholdHasIntegration_Household
}
    ${HouseholdClientProfileFragmentDoc}
${HouseholdHasIntegration_HouseholdFragmentDoc}`;
export const DeleteHouseholdDocument = gql`
    mutation DeleteHousehold($id: ID!) {
  deleteHouseholds(ids: [$id])
}
    `;
export type DeleteHouseholdMutationFn = Apollo.MutationFunction<DeleteHouseholdMutation, DeleteHouseholdMutationVariables>;

/**
 * __useDeleteHouseholdMutation__
 *
 * To run a mutation, you first call `useDeleteHouseholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHouseholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHouseholdMutation, { data, loading, error }] = useDeleteHouseholdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHouseholdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHouseholdMutation, DeleteHouseholdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHouseholdMutation, DeleteHouseholdMutationVariables>(DeleteHouseholdDocument, options);
      }
export type DeleteHouseholdMutationHookResult = ReturnType<typeof useDeleteHouseholdMutation>;
export type DeleteHouseholdMutationResult = Apollo.MutationResult<DeleteHouseholdMutation>;
export type DeleteHouseholdMutationOptions = Apollo.BaseMutationOptions<DeleteHouseholdMutation, DeleteHouseholdMutationVariables>;
export const CreateHouseholdDocument = gql`
    mutation CreateHousehold($input: CreateHouseholdInput!) {
  createHousehold(input: $input) {
    ...Household
  }
}
    ${HouseholdFragmentDoc}`;
export type CreateHouseholdMutationFn = Apollo.MutationFunction<CreateHouseholdMutation, CreateHouseholdMutationVariables>;

/**
 * __useCreateHouseholdMutation__
 *
 * To run a mutation, you first call `useCreateHouseholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHouseholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHouseholdMutation, { data, loading, error }] = useCreateHouseholdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateHouseholdMutation(baseOptions?: Apollo.MutationHookOptions<CreateHouseholdMutation, CreateHouseholdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHouseholdMutation, CreateHouseholdMutationVariables>(CreateHouseholdDocument, options);
      }
export type CreateHouseholdMutationHookResult = ReturnType<typeof useCreateHouseholdMutation>;
export type CreateHouseholdMutationResult = Apollo.MutationResult<CreateHouseholdMutation>;
export type CreateHouseholdMutationOptions = Apollo.BaseMutationOptions<CreateHouseholdMutation, CreateHouseholdMutationVariables>;
export const UpdateHouseholdDocument = gql`
    mutation UpdateHousehold($id: ID!, $input: UpdateHouseholdInput!) {
  updateHousehold(id: $id, input: $input) {
    ...Household
  }
}
    ${HouseholdFragmentDoc}`;
export type UpdateHouseholdMutationFn = Apollo.MutationFunction<UpdateHouseholdMutation, UpdateHouseholdMutationVariables>;

/**
 * __useUpdateHouseholdMutation__
 *
 * To run a mutation, you first call `useUpdateHouseholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHouseholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHouseholdMutation, { data, loading, error }] = useUpdateHouseholdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHouseholdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHouseholdMutation, UpdateHouseholdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHouseholdMutation, UpdateHouseholdMutationVariables>(UpdateHouseholdDocument, options);
      }
export type UpdateHouseholdMutationHookResult = ReturnType<typeof useUpdateHouseholdMutation>;
export type UpdateHouseholdMutationResult = Apollo.MutationResult<UpdateHouseholdMutation>;
export type UpdateHouseholdMutationOptions = Apollo.BaseMutationOptions<UpdateHouseholdMutation, UpdateHouseholdMutationVariables>;
export const UpdateHouseholdAndCreateClientProfilesDocument = gql`
    mutation UpdateHouseholdAndCreateClientProfiles($id: ID!, $input: UpdateHouseholdInput!, $clientProfileInputs: [CreateClientProfileWithDetailsInput!]!, $relationshipToBetweenPrimaryClients: ClientProfileRelationshipType) {
  createClientProfiles(input: $clientProfileInputs) {
    ...HouseholdClientProfile
  }
  updateHousehold(
    id: $id
    input: $input
    relationshipToBetweenPrimaryClients: $relationshipToBetweenPrimaryClients
  ) {
    ...Household
  }
}
    ${HouseholdClientProfileFragmentDoc}
${HouseholdFragmentDoc}`;
export type UpdateHouseholdAndCreateClientProfilesMutationFn = Apollo.MutationFunction<UpdateHouseholdAndCreateClientProfilesMutation, UpdateHouseholdAndCreateClientProfilesMutationVariables>;

/**
 * __useUpdateHouseholdAndCreateClientProfilesMutation__
 *
 * To run a mutation, you first call `useUpdateHouseholdAndCreateClientProfilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHouseholdAndCreateClientProfilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHouseholdAndCreateClientProfilesMutation, { data, loading, error }] = useUpdateHouseholdAndCreateClientProfilesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      clientProfileInputs: // value for 'clientProfileInputs'
 *      relationshipToBetweenPrimaryClients: // value for 'relationshipToBetweenPrimaryClients'
 *   },
 * });
 */
export function useUpdateHouseholdAndCreateClientProfilesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHouseholdAndCreateClientProfilesMutation, UpdateHouseholdAndCreateClientProfilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHouseholdAndCreateClientProfilesMutation, UpdateHouseholdAndCreateClientProfilesMutationVariables>(UpdateHouseholdAndCreateClientProfilesDocument, options);
      }
export type UpdateHouseholdAndCreateClientProfilesMutationHookResult = ReturnType<typeof useUpdateHouseholdAndCreateClientProfilesMutation>;
export type UpdateHouseholdAndCreateClientProfilesMutationResult = Apollo.MutationResult<UpdateHouseholdAndCreateClientProfilesMutation>;
export type UpdateHouseholdAndCreateClientProfilesMutationOptions = Apollo.BaseMutationOptions<UpdateHouseholdAndCreateClientProfilesMutation, UpdateHouseholdAndCreateClientProfilesMutationVariables>;
export const UpdateHouseholdsDocument = gql`
    mutation UpdateHouseholds($inputs: [AugmentedUpdateHouseholdInput]!) {
  updateHouseholds(inputs: $inputs) {
    ...Household
  }
}
    ${HouseholdFragmentDoc}`;
export type UpdateHouseholdsMutationFn = Apollo.MutationFunction<UpdateHouseholdsMutation, UpdateHouseholdsMutationVariables>;

/**
 * __useUpdateHouseholdsMutation__
 *
 * To run a mutation, you first call `useUpdateHouseholdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHouseholdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHouseholdsMutation, { data, loading, error }] = useUpdateHouseholdsMutation({
 *   variables: {
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateHouseholdsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHouseholdsMutation, UpdateHouseholdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHouseholdsMutation, UpdateHouseholdsMutationVariables>(UpdateHouseholdsDocument, options);
      }
export type UpdateHouseholdsMutationHookResult = ReturnType<typeof useUpdateHouseholdsMutation>;
export type UpdateHouseholdsMutationResult = Apollo.MutationResult<UpdateHouseholdsMutation>;
export type UpdateHouseholdsMutationOptions = Apollo.BaseMutationOptions<UpdateHouseholdsMutation, UpdateHouseholdsMutationVariables>;
export const Operations = {
  Mutation: {
    DeleteHousehold: 'DeleteHousehold' as const,
    CreateHousehold: 'CreateHousehold' as const,
    UpdateHousehold: 'UpdateHousehold' as const,
    UpdateHouseholdAndCreateClientProfiles: 'UpdateHouseholdAndCreateClientProfiles' as const,
    UpdateHouseholds: 'UpdateHouseholds' as const
  },
  Fragment: {
    HouseholdClientProfile: 'HouseholdClientProfile' as const,
    Household: 'Household' as const
  }
}