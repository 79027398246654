import { Box, Stack, Typography } from '@mui/material';
import { uniq } from 'lodash';
import { useMemo } from 'react';

import { ColorBox } from '@/components/display/ColorBox/ColorBox';
import { COLORS } from '@/styles/tokens/colors';
import { AfterDeath } from '@/types/schema';

import { useEstateWaterfallContext } from '../../contexts/estateWaterfall.context';
import { isNodeIdForSection } from '../../waterfallGraph/utils';

const DIAGRAM_LEGEND_ITEMS = [
  {
    label: 'In estate',
    color: COLORS.CATEGORIES.IN_ESTATE[300],
  },
  {
    label: 'Out of estate (family)',
    color: COLORS.CATEGORIES.FAMILY_GIVING[300],
  },
  {
    label: 'Out of estate (charity)',
    color: COLORS.CATEGORIES.CHARITABLE_GIVING[300],
  },
  {
    label: 'Gift & estate tax',
    color: COLORS.CATEGORIES.ESTATE_TAX_SAVINGS[400],
  },
] as const;

export function EstateWaterfallLegend() {
  const {
    state: { isFilteredWaterfall, visibleNodeIds, hiddenNodeIds },
  } = useEstateWaterfallContext();

  const filterStatusCopy = useMemo(() => {
    const AllEntitiesCopy = 'Showing all entities';
    const visibleNoneSectionNodeIds = visibleNodeIds.filter((nodeId) =>
      isNodeIdForSection(nodeId, AfterDeath.None)
    );

    const hiddenNoneSectionNodeIds = hiddenNodeIds.filter((nodeId) =>
      isNodeIdForSection(nodeId, AfterDeath.None)
    );

    const visibleCount = uniq(visibleNoneSectionNodeIds).length;
    const totalCount = visibleCount + uniq(hiddenNoneSectionNodeIds).length;

    return isFilteredWaterfall
      ? `Showing ${visibleCount} of ${totalCount} entities`
      : AllEntitiesCopy;
  }, [hiddenNodeIds, isFilteredWaterfall, visibleNodeIds]);

  return (
    <Stack
      direction="row"
      spacing={2}
      p={1.5}
      sx={{
        backgroundColor: COLORS.GRAY[100],
        borderTopRightRadius: '4px',
      }}
    >
      {DIAGRAM_LEGEND_ITEMS.map((item) => (
        <Stack
          direction="row"
          alignItems="center"
          key={item.label}
          spacing={0.5}
        >
          <ColorBox color={item.color} />
          <Typography variant="subtitle2">{item.label}</Typography>
        </Stack>
      ))}
      <Box
        width="1px"
        height="16px"
        sx={{ backgroundColor: COLORS.GRAY[300] }}
      />
      <Typography variant="subtitle2">{filterStatusCopy}</Typography>
    </Stack>
  );
}
