// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { AiOnboarding_KgRootFragmentDoc } from '../../../aiOnboarding/graphql/aiOnboarding.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FetchInitialAsyncJobNotificationsQueryVariables = Types.Exact<{
  userID: Types.Scalars['ID']['input'];
}>;


export type FetchInitialAsyncJobNotificationsQuery = { __typename?: 'Query', aiOnboarding: { __typename?: 'KGRootConnection', edges?: Array<{ __typename?: 'KGRootEdge', node?: { __typename?: 'KGRoot', id: string, status: Types.KgRootStatus, household: { __typename?: 'Household', id: string, displayName: string }, files?: Array<{ __typename?: 'File', id: string, document?: { __typename?: 'Document', id: string } | null }> | null, asyncJob?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null, aiSuggestions?: Array<{ __typename?: 'AISuggestion', id: string, kind: Types.AiSuggestionKind }> | null } | null } | null> | null } };


export const FetchInitialAsyncJobNotificationsDocument = gql`
    query FetchInitialAsyncJobNotifications($userID: ID!) {
  aiOnboarding: kgRoots(
    where: {hasUserWith: {id: $userID}, statusIn: [COMPLETE, IN_PROGRESS]}
    orderBy: [{field: updated_at, direction: DESC}]
  ) {
    edges {
      node {
        ...AIOnboarding_KGRoot
      }
    }
  }
}
    ${AiOnboarding_KgRootFragmentDoc}`;

/**
 * __useFetchInitialAsyncJobNotificationsQuery__
 *
 * To run a query within a React component, call `useFetchInitialAsyncJobNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchInitialAsyncJobNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchInitialAsyncJobNotificationsQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useFetchInitialAsyncJobNotificationsQuery(baseOptions: Apollo.QueryHookOptions<FetchInitialAsyncJobNotificationsQuery, FetchInitialAsyncJobNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchInitialAsyncJobNotificationsQuery, FetchInitialAsyncJobNotificationsQueryVariables>(FetchInitialAsyncJobNotificationsDocument, options);
      }
export function useFetchInitialAsyncJobNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchInitialAsyncJobNotificationsQuery, FetchInitialAsyncJobNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchInitialAsyncJobNotificationsQuery, FetchInitialAsyncJobNotificationsQueryVariables>(FetchInitialAsyncJobNotificationsDocument, options);
        }
export function useFetchInitialAsyncJobNotificationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FetchInitialAsyncJobNotificationsQuery, FetchInitialAsyncJobNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchInitialAsyncJobNotificationsQuery, FetchInitialAsyncJobNotificationsQueryVariables>(FetchInitialAsyncJobNotificationsDocument, options);
        }
export type FetchInitialAsyncJobNotificationsQueryHookResult = ReturnType<typeof useFetchInitialAsyncJobNotificationsQuery>;
export type FetchInitialAsyncJobNotificationsLazyQueryHookResult = ReturnType<typeof useFetchInitialAsyncJobNotificationsLazyQuery>;
export type FetchInitialAsyncJobNotificationsSuspenseQueryHookResult = ReturnType<typeof useFetchInitialAsyncJobNotificationsSuspenseQuery>;
export type FetchInitialAsyncJobNotificationsQueryResult = Apollo.QueryResult<FetchInitialAsyncJobNotificationsQuery, FetchInitialAsyncJobNotificationsQueryVariables>;
export const Operations = {
  Query: {
    FetchInitialAsyncJobNotifications: 'FetchInitialAsyncJobNotifications' as const
  }
}