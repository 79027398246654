import { Maybe } from 'graphql/jsutils/Maybe';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { EstateTaxSummaryPanel_TaxSummaryFragment } from '@/modules/summaryPanels/EstateTaxSummaryPanel/graphql/EstateTaxSummaryPanel.generated';
import { getSummaryPanelDataForDeathTarget } from '@/modules/summaryPanels/EstateTaxSummaryPanel/hooks/useSummaryPanelQuery';
import { AfterDeath, EstateWaterfallProjectionInput } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { EstateWaterfallComparisonWaterfall } from '../../EstateWaterfallComparison.type';
import { EstateWaterfallComparisonTrowserFormShape } from '../EstateWaterfallComparisonTrowser.type';
import { useEstateWaterfallComparisonTrowserTaxDetailsQuery } from '../graphql/EstateWaterfallComparisonTrowserTaxDetails.generated';

export function useTaxSummary(waterfallId: string) {
  const { showFeedback } = useFeedback();

  const { control } =
    useFormContext<EstateWaterfallComparisonTrowserFormShape>();

  const [legacyAssumptions, comparisonState] = useWatch({
    control,
    name: ['legacyAssumptions', 'comparisonState'],
  });

  const comparisonInfo: EstateWaterfallComparisonWaterfall | undefined =
    comparisonState?.waterfalls.find(
      (waterfall) => waterfall.waterfallId === waterfallId
    );

  const assumptions: EstateWaterfallProjectionInput = {
    assetGrowthReturn: comparisonInfo
      ? undefined
      : legacyAssumptions?.assetGrowthReturn,
    exemptionGrowthRate: legacyAssumptions?.exemptionGrowthRate,
    firstGrantorDeathYear:
      comparisonInfo?.firstDeathYear ||
      legacyAssumptions?.firstGrantorDeathYear,
    secondGrantorDeathYear:
      comparisonInfo?.secondDeathYear ||
      legacyAssumptions?.secondGrantorDeathYear,
    willExemptionSunset: comparisonInfo
      ? undefined
      : legacyAssumptions?.willExemptionSunset,
    sourceWaterfallID: waterfallId,
    growthProfileID: comparisonInfo?.growthProfileId,
  };

  const { data, ...rest } = useEstateWaterfallComparisonTrowserTaxDetailsQuery({
    fetchPolicy: 'cache-and-network', // We can use cache-and-network here because we have a custom merge policy for the visualizationWithProjections field
    variables: {
      where: { id: waterfallId },
      assumptions,
    },
    onError: (error) => {
      showFeedback(
        `Could not get tax information. Please refresh the page and try again.`
      );
      diagnostics.error(`failed to fetch estate tax summary info`, error, {
        waterfallId,
      });
    },
  });

  const firstDeathData = useMemo(() => {
    const estateWaterfall = getNodes(data?.estateWaterfalls)[0] ?? undefined;

    return getSummaryPanelDataForDeathTarget({
      estateWaterfall,
      afterDeath: AfterDeath.First,
    });
  }, [data]);

  const secondDeathData = useMemo(() => {
    const estateWaterfall = getNodes(data?.estateWaterfalls)[0] ?? undefined;

    return getSummaryPanelDataForDeathTarget({
      estateWaterfall,
      afterDeath: AfterDeath.Second,
    });
  }, [data]);

  const taxSummary: {
    beforeFirstDeath: Maybe<EstateTaxSummaryPanel_TaxSummaryFragment>;
    firstDeath: Maybe<EstateTaxSummaryPanel_TaxSummaryFragment>;
    secondDeath: Maybe<EstateTaxSummaryPanel_TaxSummaryFragment>;
  } = useMemo(() => {
    const estateWaterfall = getNodes(data?.estateWaterfalls)[0] ?? undefined;

    return {
      beforeFirstDeath:
        estateWaterfall?.visualizationWithProjections
          .beforeFirstDeathTaxSummary,
      firstDeath:
        estateWaterfall?.visualizationWithProjections.firstDeathTaxSummary,
      secondDeath:
        estateWaterfall?.visualizationWithProjections.secondDeathTaxSummary,
    };
  }, [data]);

  return { data, firstDeathData, secondDeathData, taxSummary, ...rest };
}
