import { Stack, Typography } from '@mui/material';
import { useCallback } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { shouldRefetchQuery } from '@/graphql/client.utils';
import { useReportError } from '@/hooks/useReportError';

import { EntityDetailsDocument } from '../details/graphql/EntityDetailPage.generated';
import { useDeleteEntityMutation } from '../graphql/Entities.generated';

interface EntityDeleteModalProps {
  entityId?: string;
  isOpen: boolean;
  requestClose: () => void;
  onSuccess: () => void;
}

export function HypotheticalEntityDeleteModal({
  entityId,
  isOpen,
  requestClose,
  onSuccess,
}: EntityDeleteModalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [deleteEntity, { loading: deleting }] = useDeleteEntityMutation({
    variables: { id: entityId! },
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onQueryUpdated: (query) => {
      // Prevent showing an error due to trying to refetch the entity that was just deleted
      return shouldRefetchQuery(query.queryName, {
        ignoredQueryDocuments: [EntityDetailsDocument],
      });
    },
    onError: (error) => {
      reportError(`failed to delete entity`, error);
      showFeedback(
        'Failed to delete this entity. Please refresh the page and try again.'
      );
    },
    onCompleted: () => {
      showFeedback('Entity deleted successfully', { variant: 'success' });
      requestClose();
      onSuccess();
    },
  });

  const handleDelete = useCallback(async () => {
    await deleteEntity();
  }, [deleteEntity]);

  return (
    <DialogModal
      isOpen={isOpen}
      onClose={requestClose}
      heading="Delete entity"
      actions={
        <Stack direction="row" justifyContent="center" width="100%" spacing={2}>
          <Button
            onClick={requestClose}
            variant="secondary"
            size="sm"
            fullWidth
          >
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            variant="destructive-prominent"
            size="sm"
            fullWidth
            loading={deleting}
          >
            Delete
          </Button>
        </Stack>
      }
    >
      <Stack maxWidth={280}>
        <Typography variant="subtitle2">
          Transfers to this draft entity will also be deleted. Are you sure
          you’d like to delete?
        </Typography>
      </Stack>
    </DialogModal>
  );
}
