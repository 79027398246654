import { Stack } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { useRequiredParam } from '@/hooks/useRequiredParam';

import { ReportingBreadcrumb } from '../ClientDetailsReportingPage/components/ReportingBreadcrumb';
import { ClientPresentationsTable } from './ClientPresentationsTable/ClientPresentationsTable';
import { CreatePresentationButton } from './components/CreatePresentationButton';

interface ClientDetailsPresentationsPageInnerProps {
  householdId: string;
}

function ClientDetailsPresentationsPageInner({
  householdId,
}: ClientDetailsPresentationsPageInnerProps) {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pb={3}
      >
        <ReportingBreadcrumb reportingPageCrumb="Reports" />
        <CreatePresentationButton householdId={householdId} />
      </Stack>
      <Card variant="outlined" sx={{ p: 3 }}>
        <ClientPresentationsTable householdId={householdId} />
      </Card>
    </>
  );
}

export function ClientDetailsPresentationsPage() {
  const householdId = useRequiredParam('householdId');

  return <ClientDetailsPresentationsPageInner householdId={householdId} />;
}
