import { first } from 'lodash';
import { useCallback, useMemo } from 'react';

import { Stars01Icon } from '@/components/icons/Stars01Icon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import {
  AsyncJobNotificationInfoType,
  AsyncJobNotificationStatus,
} from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';

interface GenerateFromDocumentsMenuItemProps {
  householdId: string;
  onOpenGenerateFromDocumentsModal: () => void;
  onOpenAIOnboardingModal: (kgRootId: string) => void;
}

export function GenerateFromDocumentsMenuItem({
  householdId,
  onOpenGenerateFromDocumentsModal,
  onOpenAIOnboardingModal,
}: GenerateFromDocumentsMenuItemProps) {
  const trackUserEvent = useTrackUserEvent();

  const { getNotifications } = useAsyncJobNotificationsContext();

  const inProgressNotif = useMemo(
    () =>
      first(
        getNotifications(
          householdId,
          AsyncJobNotificationInfoType.AI_ONBOARDING,
          AsyncJobNotificationStatus.IN_PROGRESS
        )
      ),
    [getNotifications, householdId]
  );

  const needsReviewNotif = useMemo(
    () =>
      first(
        getNotifications(
          householdId,
          AsyncJobNotificationInfoType.AI_ONBOARDING,
          AsyncJobNotificationStatus.COMPLETED
        )
      ),
    [getNotifications, householdId]
  );

  const handleClick = useCallback(() => {
    if (!inProgressNotif && !needsReviewNotif) {
      // There isn't an in-progress job, or a completed job needing review,
      // so open the GenerateFromDocumentsModal
      onOpenGenerateFromDocumentsModal();
      return;
    }
    if (needsReviewNotif) {
      // There's a completed job needing review, so open the AIOnboardingModal
      onOpenAIOnboardingModal(needsReviewNotif.id);
      trackUserEvent('ai_onboarding review button clicked', {
        source: 'create-new-menu-item',
        kgRootId: needsReviewNotif.id,
        householdId: needsReviewNotif.household.id,
      });
      return;
    }
  }, [
    inProgressNotif,
    needsReviewNotif,
    onOpenGenerateFromDocumentsModal,
    onOpenAIOnboardingModal,
    trackUserEvent,
  ]);

  return (
    <MenuItem
      icon={<Stars01Icon size={20} />}
      label="Generate from documents"
      onClick={handleClick}
      disabled={!!inProgressNotif}
    />
  );
}
