import { useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { useAssetProviderDisplayName } from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import { IntegrationInputCard } from '@/modules/assetProviderIntegrations/shared/IntegrationInputCard';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { ConnectHouseholdToAssetProviderModal } from './ConnectHouseholdToAssetProviderModal';

export function ConnectHouseholdToAssetProviderCard() {
  const { householdId } = useHouseholdDetailsContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const assetProviderName = useAssetProviderDisplayName();
  if (!assetProviderName || !householdId) return null;
  return (
    <>
      {isModalOpen && (
        <ConnectHouseholdToAssetProviderModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          householdId={householdId}
        />
      )}
      <IntegrationInputCard providerName={assetProviderName}>
        <Button
          variant="primary"
          size="sm"
          fullWidth
          onClick={() => setIsModalOpen(true)}
        >
          Enable for this client
        </Button>
      </IntegrationInputCard>
    </>
  );
}
