import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { useClientDetailsGiftingPageQuery as useClientDetailsGiftingPageQueryInternal } from '../graphql/ClientDetailsGiftingPage.generated';

export function useClientDetailsGiftingPageQuery(householdId: string) {
  const { showFeedback } = useFeedback();

  const {
    data,
    error,
    loading: _loading,
    ...rest
  } = useClientDetailsGiftingPageQueryInternal({
    variables: {
      householdWhere: { id: householdId },
      lifetimeExclusionEventsWhere: {
        hasGrantorWith: [
          {
            hasHouseholdWith: [
              {
                id: householdId,
              },
            ],
          },
        ],
      },
    },
    fetchPolicy: 'cache-and-network',
    onError: (error) => {
      showFeedback(
        `Sorry, we failed to load the gifting page. Please refresh the page and try again.`
      );
      diagnostics.error('gifting page load failure', error, {
        householdId,
      });
    },
  });

  const { household, hasExclusionEvents, lifetimeExclusionEvents } = useMemo(
    () => ({
      household: getNodes(data?.households)[0],
      hasExclusionEvents: (data?.lifetimeExclusionEvents.totalCount ?? 0) > 0,
      lifetimeExclusionEvents: getNodes(data?.lifetimeExclusionEvents),
    }),
    [data?.households, data?.lifetimeExclusionEvents]
  );

  const loading = !household;

  return {
    data,
    error,
    household,
    hasExclusionEvents,
    lifetimeExclusionEvents,
    loading,
    ...rest,
  };
}
