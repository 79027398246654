import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { PageHeader } from '@/components/navigation/PageHeader';

export function UserProfilePage() {
  return (
    <>
      <PageHeader heading="Profile" />
      <Stack
        spacing={1}
        p={3}
        // set position to relative so this is the canonical offset parent of the outlet children
        sx={{ width: '100%', height: '100%', position: 'relative' }}
      >
        <Outlet />
      </Stack>
    </>
  );
}
