import Decimal from 'decimal.js';

import { SummaryBeneficiaryKindv2 } from '@/types/schema';
import { PathsOf } from '@/types/subform';

export const NAMESPACE = 'beneficiariesSubform' as const;
export type NamespaceType = typeof NAMESPACE;
const VARIANTS = [
  'editableList',
  'editableListFancyEmptyState',
  'none',
] as const;
export type VariantType = (typeof VARIANTS)[number];

interface Beneficiary {
  beneficiaryId: string;
  percent: Decimal;
  displayType: string;
  type?: SummaryBeneficiaryKindv2;
  badgeText?: string;
  heading: string;
  additionalItems?: (string | null)[];
}

// this is the type of the subform's values
export interface BeneficiariesSubformType {
  beneficiaries: Beneficiary[];
}

// this is what the subform's values will be nested under in the parent form
export interface Fields {
  [NAMESPACE]: BeneficiariesSubformType;
}

export type SubformField = PathsOf<Fields>;

// this is Props for the UI component
export interface Props {
  variant: VariantType;
  householdId: string;
  subformValues: BeneficiariesSubformType;
  grantorProfileId: string;
  externalModalControl?: boolean;
}
