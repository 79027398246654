import { getGRATTrustSummaryProperties } from '@/modules/entities/details/grat/GRATTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { TrustBeneficiariesSlide } from '../components/Trust.Beneficiaries';
import { GRATTrustPresentationSlideProps } from './GRATTrust.types';

export function GRATTrustBeneficiariesSlide({
  entity,
  subtype,
  ...registrationProps
}: GRATTrustPresentationSlideProps) {
  const summaryDetails = getGRATTrustSummaryProperties(
    {
      entity,
      entityType: ENTITY_TYPES.GRAT,
    },
    { variant: 'print' }
  );

  return (
    <TrustBeneficiariesSlide
      entity={entity}
      summary={summaryDetails}
      displayName={subtype.displayName}
      {...registrationProps}
    />
  );
}
