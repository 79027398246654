// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { AddeparClientImportLookup_AddeparClientFragmentDoc } from '../../../../pages/import/bulkImport/addepar/graphql/AddeparClientImportLookup.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddeparEntityImportTable_IntegrationEntityChildFragment = { __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null };

export type AddeparEntityImportTable_IntegrationEntityFragment = { __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, childEntities?: Array<{ __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, childEntities?: Array<{ __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, childEntities?: Array<{ __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, childEntities?: Array<{ __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null }> | null }> | null }> | null }> | null, integrationClient?: { __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind, name: string } | null };

export type AddeparEntityImportTable_HouseholdFragment = { __typename?: 'Household', id: string, displayName: string, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string }> | null, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string }> };

export type AddeparAllHouseholdsEntityImportTableQueryVariables = Types.Exact<{
  householdsLike: Types.HouseholdWhereInput;
}>;


export type AddeparAllHouseholdsEntityImportTableQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, displayName: string, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string }> | null, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string }> } | null } | null> | null }, integrationEntities: { __typename?: 'IntegrationEntityConnection', edges?: Array<{ __typename?: 'IntegrationEntityEdge', node?: { __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, childEntities?: Array<{ __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, childEntities?: Array<{ __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, childEntities?: Array<{ __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, childEntities?: Array<{ __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null }> | null }> | null }> | null }> | null, integrationClient?: { __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind, name: string } | null } | null } | null> | null } };

export type AddeparSingleHouseholdEntityImportTableQueryVariables = Types.Exact<{
  householdsLike: Types.HouseholdWhereInput;
}>;


export type AddeparSingleHouseholdEntityImportTableQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, displayName: string, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string }> | null, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string }> } | null } | null> | null }, integrationEntities: { __typename?: 'IntegrationEntityConnection', edges?: Array<{ __typename?: 'IntegrationEntityEdge', node?: { __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, childEntities?: Array<{ __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, childEntities?: Array<{ __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, childEntities?: Array<{ __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, childEntities?: Array<{ __typename?: 'IntegrationEntity', id: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null }> | null }> | null }> | null }> | null, integrationClient?: { __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind, name: string } | null } | null } | null> | null } };

export type InferEntityKindsQueryVariables = Types.Exact<{
  input: Array<Types.InferEntityKindInput> | Types.InferEntityKindInput;
}>;


export type InferEntityKindsQuery = { __typename?: 'Query', inferEntityKinds: Array<{ __typename?: 'InferEntityKindOutput', id: string, entityName: string, entityKind: Types.EntityKind }> };

export const AddeparEntityImportTable_IntegrationEntityChildFragmentDoc = gql`
    fragment AddeparEntityImportTable_IntegrationEntityChild on IntegrationEntity {
  id
  name
  externalKind
  value
}
    `;
export const AddeparEntityImportTable_IntegrationEntityFragmentDoc = gql`
    fragment AddeparEntityImportTable_IntegrationEntity on IntegrationEntity {
  id
  name
  externalKind
  value
  childEntities {
    ...AddeparEntityImportTable_IntegrationEntityChild
    childEntities {
      ...AddeparEntityImportTable_IntegrationEntityChild
      childEntities {
        ...AddeparEntityImportTable_IntegrationEntityChild
        childEntities {
          ...AddeparEntityImportTable_IntegrationEntityChild
        }
      }
    }
  }
  integrationClient {
    ...AddeparClientImportLookup_AddeparClient
  }
}
    ${AddeparEntityImportTable_IntegrationEntityChildFragmentDoc}
${AddeparClientImportLookup_AddeparClientFragmentDoc}`;
export const AddeparEntityImportTable_HouseholdFragmentDoc = gql`
    fragment AddeparEntityImportTable_Household on Household {
  id
  displayName
  integrationClients {
    id
  }
  possiblePrimaryClients {
    id
    displayName
  }
}
    `;
export const AddeparAllHouseholdsEntityImportTableDocument = gql`
    query AddeparAllHouseholdsEntityImportTable($householdsLike: HouseholdWhereInput!) {
  households(
    where: $householdsLike
    orderBy: {direction: DESC, field: created_at}
  ) {
    edges {
      node {
        ...AddeparEntityImportTable_Household
      }
    }
  }
  integrationEntities(
    where: {and: [{hasEntities: false}, {hasParentEntity: false}, {hasIntegrationClientWith: [{kind: ADDEPAR}]}]}
    orderBy: {direction: DESC, field: created_at}
  ) {
    edges {
      node {
        ...AddeparEntityImportTable_IntegrationEntity
      }
    }
  }
}
    ${AddeparEntityImportTable_HouseholdFragmentDoc}
${AddeparEntityImportTable_IntegrationEntityFragmentDoc}`;

/**
 * __useAddeparAllHouseholdsEntityImportTableQuery__
 *
 * To run a query within a React component, call `useAddeparAllHouseholdsEntityImportTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddeparAllHouseholdsEntityImportTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddeparAllHouseholdsEntityImportTableQuery({
 *   variables: {
 *      householdsLike: // value for 'householdsLike'
 *   },
 * });
 */
export function useAddeparAllHouseholdsEntityImportTableQuery(baseOptions: Apollo.QueryHookOptions<AddeparAllHouseholdsEntityImportTableQuery, AddeparAllHouseholdsEntityImportTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddeparAllHouseholdsEntityImportTableQuery, AddeparAllHouseholdsEntityImportTableQueryVariables>(AddeparAllHouseholdsEntityImportTableDocument, options);
      }
export function useAddeparAllHouseholdsEntityImportTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddeparAllHouseholdsEntityImportTableQuery, AddeparAllHouseholdsEntityImportTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddeparAllHouseholdsEntityImportTableQuery, AddeparAllHouseholdsEntityImportTableQueryVariables>(AddeparAllHouseholdsEntityImportTableDocument, options);
        }
export function useAddeparAllHouseholdsEntityImportTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AddeparAllHouseholdsEntityImportTableQuery, AddeparAllHouseholdsEntityImportTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddeparAllHouseholdsEntityImportTableQuery, AddeparAllHouseholdsEntityImportTableQueryVariables>(AddeparAllHouseholdsEntityImportTableDocument, options);
        }
export type AddeparAllHouseholdsEntityImportTableQueryHookResult = ReturnType<typeof useAddeparAllHouseholdsEntityImportTableQuery>;
export type AddeparAllHouseholdsEntityImportTableLazyQueryHookResult = ReturnType<typeof useAddeparAllHouseholdsEntityImportTableLazyQuery>;
export type AddeparAllHouseholdsEntityImportTableSuspenseQueryHookResult = ReturnType<typeof useAddeparAllHouseholdsEntityImportTableSuspenseQuery>;
export type AddeparAllHouseholdsEntityImportTableQueryResult = Apollo.QueryResult<AddeparAllHouseholdsEntityImportTableQuery, AddeparAllHouseholdsEntityImportTableQueryVariables>;
export const AddeparSingleHouseholdEntityImportTableDocument = gql`
    query AddeparSingleHouseholdEntityImportTable($householdsLike: HouseholdWhereInput!) {
  households(
    where: $householdsLike
    orderBy: {direction: DESC, field: created_at}
  ) {
    edges {
      node {
        ...AddeparEntityImportTable_Household
      }
    }
  }
  integrationEntities(
    where: {and: [{hasEntities: false}, {hasParentEntity: false}, {hasIntegrationClientWith: [{kind: ADDEPAR}, {hasClientHouseholdsWith: [$householdsLike]}]}]}
    orderBy: {direction: DESC, field: created_at}
  ) {
    edges {
      node {
        ...AddeparEntityImportTable_IntegrationEntity
      }
    }
  }
}
    ${AddeparEntityImportTable_HouseholdFragmentDoc}
${AddeparEntityImportTable_IntegrationEntityFragmentDoc}`;

/**
 * __useAddeparSingleHouseholdEntityImportTableQuery__
 *
 * To run a query within a React component, call `useAddeparSingleHouseholdEntityImportTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddeparSingleHouseholdEntityImportTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddeparSingleHouseholdEntityImportTableQuery({
 *   variables: {
 *      householdsLike: // value for 'householdsLike'
 *   },
 * });
 */
export function useAddeparSingleHouseholdEntityImportTableQuery(baseOptions: Apollo.QueryHookOptions<AddeparSingleHouseholdEntityImportTableQuery, AddeparSingleHouseholdEntityImportTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddeparSingleHouseholdEntityImportTableQuery, AddeparSingleHouseholdEntityImportTableQueryVariables>(AddeparSingleHouseholdEntityImportTableDocument, options);
      }
export function useAddeparSingleHouseholdEntityImportTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddeparSingleHouseholdEntityImportTableQuery, AddeparSingleHouseholdEntityImportTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddeparSingleHouseholdEntityImportTableQuery, AddeparSingleHouseholdEntityImportTableQueryVariables>(AddeparSingleHouseholdEntityImportTableDocument, options);
        }
export function useAddeparSingleHouseholdEntityImportTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AddeparSingleHouseholdEntityImportTableQuery, AddeparSingleHouseholdEntityImportTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddeparSingleHouseholdEntityImportTableQuery, AddeparSingleHouseholdEntityImportTableQueryVariables>(AddeparSingleHouseholdEntityImportTableDocument, options);
        }
export type AddeparSingleHouseholdEntityImportTableQueryHookResult = ReturnType<typeof useAddeparSingleHouseholdEntityImportTableQuery>;
export type AddeparSingleHouseholdEntityImportTableLazyQueryHookResult = ReturnType<typeof useAddeparSingleHouseholdEntityImportTableLazyQuery>;
export type AddeparSingleHouseholdEntityImportTableSuspenseQueryHookResult = ReturnType<typeof useAddeparSingleHouseholdEntityImportTableSuspenseQuery>;
export type AddeparSingleHouseholdEntityImportTableQueryResult = Apollo.QueryResult<AddeparSingleHouseholdEntityImportTableQuery, AddeparSingleHouseholdEntityImportTableQueryVariables>;
export const InferEntityKindsDocument = gql`
    query InferEntityKinds($input: [InferEntityKindInput!]!) {
  inferEntityKinds(input: $input) {
    id
    entityName
    entityKind
  }
}
    `;

/**
 * __useInferEntityKindsQuery__
 *
 * To run a query within a React component, call `useInferEntityKindsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInferEntityKindsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInferEntityKindsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInferEntityKindsQuery(baseOptions: Apollo.QueryHookOptions<InferEntityKindsQuery, InferEntityKindsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InferEntityKindsQuery, InferEntityKindsQueryVariables>(InferEntityKindsDocument, options);
      }
export function useInferEntityKindsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InferEntityKindsQuery, InferEntityKindsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InferEntityKindsQuery, InferEntityKindsQueryVariables>(InferEntityKindsDocument, options);
        }
export function useInferEntityKindsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InferEntityKindsQuery, InferEntityKindsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InferEntityKindsQuery, InferEntityKindsQueryVariables>(InferEntityKindsDocument, options);
        }
export type InferEntityKindsQueryHookResult = ReturnType<typeof useInferEntityKindsQuery>;
export type InferEntityKindsLazyQueryHookResult = ReturnType<typeof useInferEntityKindsLazyQuery>;
export type InferEntityKindsSuspenseQueryHookResult = ReturnType<typeof useInferEntityKindsSuspenseQuery>;
export type InferEntityKindsQueryResult = Apollo.QueryResult<InferEntityKindsQuery, InferEntityKindsQueryVariables>;
export const Operations = {
  Query: {
    AddeparAllHouseholdsEntityImportTable: 'AddeparAllHouseholdsEntityImportTable' as const,
    AddeparSingleHouseholdEntityImportTable: 'AddeparSingleHouseholdEntityImportTable' as const,
    InferEntityKinds: 'InferEntityKinds' as const
  },
  Fragment: {
    AddeparEntityImportTable_IntegrationEntityChild: 'AddeparEntityImportTable_IntegrationEntityChild' as const,
    AddeparEntityImportTable_IntegrationEntity: 'AddeparEntityImportTable_IntegrationEntity' as const,
    AddeparEntityImportTable_Household: 'AddeparEntityImportTable_Household' as const
  }
}