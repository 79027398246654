import { compact } from 'lodash';

import {
  useCanUserConnectToAddepar,
  useIsUserLoggedIntoAddepar,
} from '@/modules/assetProviderIntegrations/addepar/hooks/useIsAddeparEnabled';
import {
  useCanUserConnectToBlackDiamond,
  useIsUserLoggedIntoBlackDiamond,
} from '@/modules/assetProviderIntegrations/blackDiamond/hooks/useIsBlackDiamondEnabled';

import {
  useCanUserConnectToOrion,
  useIsUserLoggedIntoOrion,
} from '../../orion/hooks/useIsOrionEnabled';
import {
  ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES,
  AssetIntegrationProviders,
} from '../constants';

export function useAssetProviderDisplayName() {
  const { canConnectToIntegrations } = useIsAssetProviderIntegrationEnabled();
  if (canConnectToIntegrations.length > 1) {
    return 'External provider';
  }

  if (canConnectToIntegrations.includes(AssetIntegrationProviders.ADDEPAR)) {
    return ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES.ADDEPAR;
  }

  if (
    canConnectToIntegrations.includes(AssetIntegrationProviders.BLACK_DIAMOND)
  ) {
    return ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES.BLACK_DIAMOND;
  }

  if (canConnectToIntegrations.includes(AssetIntegrationProviders.ORION)) {
    return ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES.ORION;
  }

  return null;
}

/**
 * Returns a list of third-party integrations which a user has connected to and can start using;
 * eg: for linking households/entities.
 */
export function useLoggedInAssetsIntegrations(): AssetIntegrationProviders[] {
  const shouldExposeAddeparIntegration = useIsUserLoggedIntoAddepar();
  const shouldExposeBlackDiamondIntegration = useIsUserLoggedIntoBlackDiamond();
  const shouldExposeOrionIntegration = useIsUserLoggedIntoOrion();

  return compact([
    shouldExposeAddeparIntegration && AssetIntegrationProviders.ADDEPAR,
    shouldExposeBlackDiamondIntegration &&
      AssetIntegrationProviders.BLACK_DIAMOND,
    shouldExposeOrionIntegration && AssetIntegrationProviders.ORION,
  ]);
}

/**
 * Returns a list of third-party integrations to which a user can connect their individual account;
 * eg: tenant has the integration set up, but a user hasn't gone through the connection flow.
 */
function useUserCanConnectToIntegrations(): AssetIntegrationProviders[] {
  const canConnectToAddepar = useCanUserConnectToAddepar();
  const canConnectToBlackDiamond = useCanUserConnectToBlackDiamond();
  const canConnectToOrion = useCanUserConnectToOrion();

  return compact([
    canConnectToAddepar && AssetIntegrationProviders.ADDEPAR,
    canConnectToBlackDiamond && AssetIntegrationProviders.BLACK_DIAMOND,
    canConnectToOrion && AssetIntegrationProviders.ORION,
  ]);
}

export interface AssetProviderIntegrationEnabledReturnType {
  /** Reports whether or not a user has connected to a third-party data provider. */
  hasConnectedIntegration: boolean;
  /** A list of third-party data integrations to which a user has connected. */
  integrationsConnected: AssetIntegrationProviders[];
  /** A list of third-party data integrations set up for the tenant to which a user can connect. */
  canConnectToIntegrations: AssetIntegrationProviders[];
}

/**
 * Check the state of the user and the tenant to determine the user's status for third-party data
 * integrations.
 *
 * An integration can exist in one of three states:
 *
 * - Not set up for the tenant, and unable to be connected to (integration will not be present in either list)
 * - Set up for the tenant, but not connected to on the user level (integration will be present in `canConnectToIntegrations` but not `integrationsConnected`)
 * - Set up for the tenant and connected to on the user level (integration _should_ be present in both lists)
 *
 * If a user is connected to any integration, the `hasConnectedIntegration` field will be true.
 */
export function useIsAssetProviderIntegrationEnabled(): AssetProviderIntegrationEnabledReturnType {
  const integrationsConnected: AssetIntegrationProviders[] =
    useLoggedInAssetsIntegrations();
  const canConnectToIntegrations: AssetIntegrationProviders[] =
    useUserCanConnectToIntegrations();

  const hasConnectedIntegration = integrationsConnected.length > 0;

  return {
    hasConnectedIntegration,
    integrationsConnected,
    canConnectToIntegrations,
  };
}
