import { ProfessionalTeamRoleKind } from '@/types/schema';

export const ADVISOR_CLIENT_TEAM_ORDER_LIST: ProfessionalTeamRoleKind[] = [
  ProfessionalTeamRoleKind.AccountantCpa,
  ProfessionalTeamRoleKind.Attorney,
  ProfessionalTeamRoleKind.InsuranceAgent,
  ProfessionalTeamRoleKind.BusinessManager,
  ProfessionalTeamRoleKind.Bookkeeper,
  ProfessionalTeamRoleKind.Other,
];

export const PRINCIPAL_CLIENT_TEAM_ORDER_LIST: ProfessionalTeamRoleKind[] = [
  ProfessionalTeamRoleKind.PowerOfAttorney,
  ProfessionalTeamRoleKind.Physician,
  ProfessionalTeamRoleKind.HealthcareAgent,
  ProfessionalTeamRoleKind.Guardian,
  ProfessionalTeamRoleKind.Conservator,
  ProfessionalTeamRoleKind.EstateExecutor,
  ProfessionalTeamRoleKind.Other,
];
