import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

import { Card } from '@/components/layout/Card/Card';
import { ContextualHelpTrigger } from '@/modules/content/components/ContextualHelpTrigger';

export function HouseholdEstateValueMetricsCard({
  children,
}: PropsWithChildren) {
  return (
    <Card
      variant="outlined"
      sx={{
        p: 1,
        pl: 3,
        pb: 3,
        overflow: 'visible',
      }}
    >
      <Stack height="100%">
        <Stack direction="row" justifyContent="end">
          <ContextualHelpTrigger contextualHelpId="howIsThisCalculatedEntitiesList" />
        </Stack>
        {children}
      </Stack>
    </Card>
  );
}
