import {
  array,
  boolean as booleanType,
  date,
  Describe,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateDonorAdvisedFundInput,
  AugmentedCreateEntityInput,
  AugmentedUpdateDonorAdvisedFundInput,
  AugmentedUpdateEntityInput,
  CreateDonorAdvisedFundInput,
  UpdateDonorAdvisedFundInput,
} from '@/types/schema';

import {
  createInsurancePolicyInput,
  updateInsurancePolicyInput,
} from './insuranceTypes';
import { controllingPartySchema } from './personalAccountTypes';
import {
  beneficiarySchema,
  commonUpdateEntityProperties,
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  gstStatusSchema,
  inEstateStatusSchema,
  nonTrustEntityTaxStatus,
  principalWithOwnershipSchema,
  stageSchema,
  trusteeSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const dafCreateUpdateProperties = {
  // share trust properties
  legalName: optional(nullable(string())),
  effectiveDate: optional(nullable(date())),
  description: optional(nullable(string())),
  gstStatus: gstStatusSchema,
};

const createDafInput: Describe<CreateDonorAdvisedFundInput> = object({
  displayName: string(),
  taxStatus: nonTrustEntityTaxStatus,
  inEstateStatus: inEstateStatusSchema,
  ...dafCreateUpdateProperties,
});

const createDafSchema: Describe<AugmentedCreateDonorAdvisedFundInput> = object({
  create: createDafInput,
  withBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withDonors: optional(nullable(array(principalWithOwnershipSchema))),
  withControllingParties: optional(nullable(array(controllingPartySchema))),
  withDesignerAccount: optional(nullable(createAccountSchema)),
});

const updateDafInput: Describe<UpdateDonorAdvisedFundInput> = object({
  displayName: optional(nullable(string())),
  ...dafCreateUpdateProperties,
  taxStatus: optional(nullable(nonTrustEntityTaxStatus)),
  inEstateStatus: inEstateStatusSchema,
  clearBeneficiaries: optional(nullable(booleanType())),
  clearDonors: optional(nullable(booleanType())),
  clearControllingParties: optional(nullable(booleanType())),
  clearDescription: optional(nullable(booleanType())),
  clearGstStatus: optional(nullable(booleanType())),
  clearTaxStatus: optional(nullable(booleanType())),
  removePolicyIDs: optional(nullable(array(string()))),
});

const updateDafSchema: Describe<AugmentedUpdateDonorAdvisedFundInput> = object({
  id: string(),
  update: updateDafInput,
  withBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withDonors: optional(nullable(array(principalWithOwnershipSchema))),
  withControllingParties: optional(nullable(array(trusteeSchema))),
  updateDesignerAccount: optional(nullable(updateAccountSchema)),
  withPolicies: optional(nullable(array(createInsurancePolicyInput))),
  updatePolicies: optional(nullable(array(updateInsurancePolicyInput))),
});

const createSchema: Describe<AugmentedCreateEntityInput> = object({
  create: createEntityInput,
  withDonorAdvisedFund: optional(nullable(createDafSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object({
    ...commonUpdateEntityProperties,
    stage: optional(nullable(stageSchema)),
  }),
  updateDonorAdvisedFund: optional(nullable(updateDafSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export function validateDafCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'DAF create');
}

export function validateDafUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'DAF update');
}
