import { EntityPresentationSlideType } from '../entityPresentations.types';

export const ALL_ENTITY_SLIDE_TYPES: EntityPresentationSlideType[] = [
  EntityPresentationSlideType.OVERVIEW,
  EntityPresentationSlideType.SUMMARY,
  EntityPresentationSlideType.TRUST_FIDUCIARIES,
  EntityPresentationSlideType.BENEFICIARIES,
  EntityPresentationSlideType.BUSINESS_OWNERSHIP,
  EntityPresentationSlideType.BUSINESS_KEY_PEOPLE,
  EntityPresentationSlideType.DISPOSITIVE_PROVISIONS,
  EntityPresentationSlideType.INSURANCE_POLICIES,
];
