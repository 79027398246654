import { Stack } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { PropsWithChildren, useMemo } from 'react';
import { Navigate, Outlet, To, useNavigate } from 'react-router-dom';

import { appbarHeight } from '@/components/architecture/Layout/AppBar/AppBar';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { PageHeader } from '@/components/navigation/PageHeader';
import { ErrorFeedbackFallback } from '@/components/notifications/Feedback/ErrorFeedbackFallback';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Loader } from '@/components/progress/Loader/Loader';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { DeleteProposalMenuItem } from '@/modules/proposal/components/DeleteProposalMenuItem/DeleteProposalMenuItem';
import { DuplicateProposalMenuItem } from '@/modules/proposal/components/DuplicateProposalMenuItem/DuplicateProposalMenuItem';
import { ProposalDetailsAwareRoute } from '@/modules/proposal/contexts/ProposalDetailsAwareRoute';
import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';
import { GiftDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { useRouteKeyMatch } from '@/navigation/useRouteKeyMatch';
import { ProposalKind } from '@/pages/client-proposal/hooks/useProposalsQuery';
import { GiftingProposalStatus } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { mapGiftingProposalStatusToGiftDesignerStages } from './GiftingDesigner.utils';
import { GiftingDesignerStepTracker } from './GiftingDesignerStepTracker';
import { useGiftProposalDetails } from './hooks/useGiftProposalDetails';

const StackWrapper = ({ children }: PropsWithChildren) => (
  <Stack height={`calc(100vh - ${appbarHeight})`}>{children}</Stack>
);

export function GiftDesignerPage() {
  const householdId = useRequiredParam('householdId');
  const proposalId = useRequiredParam('proposalId');
  const navigate = useNavigate();
  const canDeleteProposal = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_DELETE_ENTITIES_AND_PROPOSALS
  );

  const { showFeedback } = useFeedback();
  const { giftProposal, loading } = useGiftProposalDetails(proposalId);

  const giftProposalStatus: GiftingProposalStatus | null = useMemo(() => {
    if (loading) {
      // If we're still loading the gift proposal, we don't know its status yet
      return null;
    }

    if (typeof giftProposal === 'object' && giftProposal.giftingProposal) {
      // If we have a gifting proposal, we can use its status
      return giftProposal.giftingProposal.status;
    }

    return null;
  }, [giftProposal, loading]);

  const designerStage: GiftDesignerStages | null =
    mapGiftingProposalStatusToGiftDesignerStages(giftProposalStatus);

  const route: To | null = useMemo(() => {
    switch (designerStage) {
      case GiftDesignerStages.BASIC_INFORMATION:
        return getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER_BASIC_INFORMATION,
          {
            householdId,
            proposalId,
            designerStage: GiftDesignerStages.BASIC_INFORMATION,
          }
        );
      case GiftDesignerStages.MODEL_SCENARIOS:
        return getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER_MODEL_SCENARIOS,
          {
            householdId,
            proposalId,
            designerStage: GiftDesignerStages.MODEL_SCENARIOS,
          }
        );
      default:
        throw new UnreachableError({
          case: designerStage,
          message: `Unknown designer stage: ${designerStage}`,
        });
    }
  }, [householdId, designerStage, proposalId]);

  const isOnModelPage = useRouteKeyMatch(
    ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER_MODEL_SCENARIOS
  );

  return (
    <ErrorBoundary fallback={<ErrorFeedbackFallback />}>
      <ProposalDetailsAwareRoute proposalId={proposalId}>
        <StackWrapper>
          <PageHeader
            heading="Model a gift analysis"
            action={
              <Stack direction="row" spacing={2} alignItems="center">
                <GiftingDesignerStepTracker
                  activeStepIndex={isOnModelPage ? 1 : 0}
                  householdId={householdId}
                  proposalId={proposalId}
                />
                <ContextMenuButton
                  disabled={
                    proposalId === NEW_PROPOSAL_ID || !canDeleteProposal
                  }
                >
                  <DuplicateProposalMenuItem
                    proposalId={proposalId}
                    kind={ProposalKind.Gift}
                    afterDuplicate={(proposalId: string) => {
                      showFeedback('Gift analysis duplicated successfully.', {
                        variant: 'success',
                      });
                      navigate(
                        getCompletePathFromRouteKey(
                          ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER,
                          {
                            householdId,
                            proposalId,
                            designerStage: GiftDesignerStages.BASIC_INFORMATION,
                          }
                        )
                      );
                    }}
                  />
                  <DeleteProposalMenuItem
                    label="Delete analysis"
                    proposalId={proposalId}
                    onDelete={() =>
                      navigate(
                        getCompletePathFromRouteKey(
                          ROUTE_KEYS.HOUSEHOLD_DETAILS_PROPOSALS,
                          { householdId }
                        )
                      )
                    }
                  />
                </ContextMenuButton>
              </Stack>
            }
          />
          {loading && (
            <Loader
              boxProps={{
                sx: {
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              }}
            />
          )}
          {!loading && (
            <>
              <Navigate to={route} replace />
              <Outlet />
            </>
          )}
        </StackWrapper>
      </ProposalDetailsAwareRoute>
    </ErrorBoundary>
  );
}
