import { Stack } from '@mui/system';
import { GridApiPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import { useCallback, useLayoutEffect, useRef } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Card } from '@/components/layout/Card/Card';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { useUpdateBalanceSheetViewConfigurationMutation } from '@/modules/balanceSheet/BalanceSheetConfigurationPopper/graphql/BalanceSheetConfigurationPopper.generated';
import { BalanceSheetExportDropdown } from '@/modules/balanceSheet/BalanceSheetExportDropdown/BalanceSheetExportDropdown';
import { AssociatedEntitiesModal } from '@/modules/balanceSheet/BalanceSheetTable/AssociatedEntitiesModal/AssociatedEntitiesModal';
import { BalanceSheetTable } from '@/modules/balanceSheet/BalanceSheetTable/BalanceSheetTable';
import {
  DISPLAY_NAME_COLUMN_ID,
  TOTAL_COLUMN_SUFFIX,
} from '@/modules/balanceSheet/BalanceSheetTable/BalanceSheetTable.columns';
import {
  BalanceSheetTableProvider,
  useBalanceSheetTableContext,
} from '@/modules/balanceSheet/BalanceSheetTable/BalanceSheetTableContext';
import { ConfigureViewButton } from '@/modules/balanceSheet/ConfigureViewButton/ConfigureViewButton';
import { EditLiabilityModal } from '@/modules/liabilities/LiabilityModal/EditLiabilityModal';
import { CustomerThemeProvider } from '@/styles/themes/CustomerThemeProvider';
import { diagnostics } from '@/utils/diagnostics';

import { EntitiesBreadcrumb } from '../../ClientDetailsPage/ClientDetailsEntitiesPage/components/EntitiesBreadcrumb';
import { useBalanceSheetQuery } from './graphql/BalanceSheet.generated';

interface ClientBalanceSheetPageInnerProps {
  householdId: string;
  loading: boolean;
  refetch: () => void;
  data: ReturnType<typeof useBalanceSheetQuery>['data'];
}

function ClientBalanceSheetPageInner({
  householdId,
  loading,
  data,
  refetch,
}: ClientBalanceSheetPageInnerProps) {
  const gridAPIRef = useGridApiRef();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const {
    setIsEditingColumnOrder,
    isEditingColumnOrder,
    selectedEntitiesRow,
    setSelectedEntitiesRow,
    selectedLiabilityRow,
    setSelectedLiabilityRow,
    renderWhiteLabeled,
    viewConfigurationId,
    viewConfiguration,
  } = useBalanceSheetTableContext();

  const [updateBalanceSheet] = useUpdateBalanceSheetViewConfigurationMutation({
    onError: (error) => {
      reportError('Error updating balance sheet columns order', error);
      showFeedback(
        'Error updating the balance sheet column order. Please refresh the page and try again.'
      );
    },
    onCompleted: () => {
      if (gridAPIRef.current) {
        setInitialState(gridAPIRef.current);
      }
      setIsEditingColumnOrder(false);
    },
  });

  // save the "default" state of the grid so we can reset to this later
  const fullDefaultState = useRef<GridInitialStatePro | null>(null);

  const handleStateReset = useCallback(() => {
    if (fullDefaultState.current && gridAPIRef.current) {
      diagnostics.debug('[BALANCE SHEET] Resetting state');
      gridAPIRef.current.restoreState(fullDefaultState.current);
    }
  }, [gridAPIRef]);

  // reset the "initial" state of the grid when it's intentionally changed, so future "resets" or cancel actions
  // reset it back to the last saved state, rather than the state from when the page was first loaded
  function setInitialState(apiRef: GridApiPro) {
    diagnostics.debug('[BALANCE SHEET] Setting initial state');
    fullDefaultState.current = apiRef.exportState();
  }

  // initialize the initial state of the grid on load
  useLayoutEffect(() => {
    if (gridAPIRef.current) {
      setInitialState(gridAPIRef.current);
    }
  }, [gridAPIRef]);

  const handleColumnOrderSave = useCallback(() => {
    const nextColumns = gridAPIRef.current.getAllColumns();
    const nextColumnOrder = nextColumns
      .map((column) => column.field)
      .filter((columnId) => {
        return (
          columnId !== DISPLAY_NAME_COLUMN_ID &&
          !columnId.endsWith(TOTAL_COLUMN_SUFFIX) &&
          !columnId.startsWith('__') // mui internal columns
        );
      });

    if (!viewConfigurationId) return;
    diagnostics.debug('[BALANCE SHEET] Updating column order', {
      nextColumnOrder,
    });
    void updateBalanceSheet({
      variables: {
        input: {
          id: viewConfigurationId,
          update: {
            columnOrder: nextColumnOrder,
          },
        },
      },
    });
  }, [gridAPIRef, updateBalanceSheet, viewConfigurationId]);

  const resetColumnOrder = useCallback(() => {
    setIsEditingColumnOrder(false);
    handleStateReset();
  }, [setIsEditingColumnOrder, handleStateReset]);

  // if none of these is present, there are no columns to reorder
  const canReorderColumns =
    viewConfiguration?.separateInEstateDirectlyHeld ||
    viewConfiguration?.separateInEstateTrusts ||
    viewConfiguration?.separateOutOfEstateTrusts;

  const handleCloseLiabilityModal = useCallback(() => {
    refetch();
    setSelectedLiabilityRow(null);
  }, [refetch, setSelectedLiabilityRow]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pb={3}
      >
        <EntitiesBreadcrumb
          pageCrumb="Balance sheet"
          householdId={householdId}
        />
        <Stack direction="row" spacing={2}>
          {!isEditingColumnOrder ? (
            <>
              <BalanceSheetExportDropdown gridAPIRef={gridAPIRef} />
              {canReorderColumns && (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => setIsEditingColumnOrder(true)}
                >
                  Edit column order
                </Button>
              )}
              <ConfigureViewButton />
            </>
          ) : (
            <>
              <Button variant="secondary" size="sm" onClick={resetColumnOrder}>
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={handleColumnOrderSave}
              >
                Save column order
              </Button>
            </>
          )}
        </Stack>
      </Stack>
      <Card
        variant="outlined"
        sx={{
          p: 3,
          overflow: 'auto',
        }}
      >
        <CustomerThemeProvider forceLuminaryTheme={!renderWhiteLabeled}>
          <BalanceSheetTable
            hideExpansionCarets={renderWhiteLabeled}
            gridAPIRef={gridAPIRef}
            loading={loading}
            balanceSheet={data?.balanceSheet ?? null}
          />
        </CustomerThemeProvider>
        {selectedEntitiesRow && (
          <AssociatedEntitiesModal
            isOpen={!!selectedEntitiesRow}
            onClose={() => setSelectedEntitiesRow(null)}
            entityIds={selectedEntitiesRow.entityIds ?? []}
          />
        )}
        {selectedLiabilityRow && (
          <EditLiabilityModal
            isOpen={!!selectedLiabilityRow}
            onClose={handleCloseLiabilityModal}
            liabilityId={selectedLiabilityRow.liabilityId}
          />
        )}
      </Card>
    </>
  );
}

export function ClientBalanceSheetPage() {
  const householdId = useRequiredParam('householdId');
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { data, loading, refetch } = useBalanceSheetQuery({
    fetchPolicy: 'network-only',
    variables: { householdID: householdId },
    onError: (err) => {
      reportError('error loading balance sheet', err);
      showFeedback(
        `We weren't able to load data for the balance sheet. Please refresh the page to try again.`
      );
    },
  });

  const viewConfig = (() => {
    if (data?.node?.__typename === 'Household') {
      return data.node.balanceSheetViewConfiguration;
    }
    return null;
  })();

  return (
    <BalanceSheetTableProvider
      viewConfiguration={viewConfig ?? null}
      loading={loading}
    >
      <ClientBalanceSheetPageInner
        householdId={householdId}
        loading={loading}
        data={data}
        refetch={refetch}
      />
    </BalanceSheetTableProvider>
  );
}
