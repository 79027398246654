import { DataTable } from '@/components/tables/DataTable/DataTable';
import { entityKindToDisplayName } from '@/modules/entities/EntityForm/utils';
import { getAssetLocationDisplay } from '@/modules/entities/utils/getAssetLocationDisplay';
import { getGstStatusDisplay } from '@/modules/entities/utils/getGstStatusDisplay';

import {
  columns,
  EntitiesSummaryTableRow,
} from './EntitiesSummaryTable.constants';
import { AllEntitiesSlide_EntityFragment } from './graphql/AllEntitiesSlide.generated';

export interface EntitiesSummaryTableProps {
  entities: AllEntitiesSlide_EntityFragment[];
}

function mapEntitiesToRows(
  entities: AllEntitiesSlide_EntityFragment[]
): EntitiesSummaryTableRow[] {
  return entities.map((entity) => {
    return {
      id: entity.id,
      displayName: entity.subtype.displayName,
      entityDisplayType: entityKindToDisplayName(entity.kind),
      currentValue: entity.subtype.currentValue,
      currentValueAsOfDate: entity.subtype.mostRecentValuationDate ?? null,
      gstDisplayStatus: (() => {
        if ('gstStatus' in entity.subtype) {
          return getGstStatusDisplay(entity.subtype);
        }

        return null;
      })(),
      inEstateDisplayStatus: (() => {
        if ('inEstateStatus' in entity.subtype) {
          return getAssetLocationDisplay({
            inEstateStatus: entity.subtype.inEstateStatus,
            stateTaxes: entity.stateTaxes ?? [],
            gstStatus: entity.subtype.gstStatus,
          }).locationDisplayShort;
        }

        return null;
      })(),
    };
  });
}

export function EntitiesSummaryTable({ entities }: EntitiesSummaryTableProps) {
  const rows = mapEntitiesToRows(entities);
  return <DataTable hideFooterPagination columns={columns} rows={rows} />;
}
