import { groupBy } from 'lodash';
import { useMemo } from 'react';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { EntityKind } from '@/types/schema';

import { getRows } from './ExpandableBeneficiaryTable.utils';
import { RowData } from './FlatBeneficiaryTable.types';
import { getColumns } from './FlatBeneficiaryTable.utils';
import { TrustBeneficiariesList_BeneficiaryFragment } from './graphql/TrustBeneficiariesList.fragments.generated';

interface FlatBeneficiaryTableProps {
  heading?: string;
  beneficiaries: TrustBeneficiariesList_BeneficiaryFragment[];
  entityKind?: EntityKind;
}

export function FlatBeneficiaryTable({
  heading,
  beneficiaries,
  entityKind,
}: FlatBeneficiaryTableProps) {
  const columns = useMemo(() => {
    return getColumns({ heading });
  }, [heading]);

  const rows = useMemo(() => {
    // Get the rows using the same logic as the ExpandableBeneficiaryTable
    const rows = getRows(beneficiaries, entityKind);
    const groupedRows = groupBy(rows, (row) => row.path[0] ?? 'beneficiary');
    // Reduce the rows to a single row per benefit
    const reducedRows = Object.entries(groupedRows).flatMap(([_, benefits]) => {
      const beneficiaryName =
        benefits.find((row) => row.path[1] === undefined)?.beneficiary
          .lineOne ?? '';
      return benefits.flatMap((benefit) => {
        const access: RowData['access'] = {};
        const details: RowData['details'] = {};
        if (benefit.path[1]) {
          // If the row has a path[1], it is a scheduled distribution or access parameter
          access.component = benefit.beneficiary.rightContent;
          details.lineOne = benefit.beneficiary.lineOne;
          details.lineTwo = benefit.beneficiary.lineTwo;

          return {
            id: benefit.path.join('.'),
            beneficiary: beneficiaryName,
            access,
            details,
          };
        }

        return [];
      });
    });

    return reducedRows;
  }, [beneficiaries, entityKind]);

  return (
    <DataTable
      columns={columns}
      rows={rows}
      hideFooterPagination
      pagination={false}
    />
  );
}
