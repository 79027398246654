import { DEFAULT_ASSUMPTIONS } from '../EstateWaterfallAssumptions/constants';
import { EstateWaterfallAssumptionFields } from '../EstateWaterfallAssumptions/types';

export interface EstateWaterfallComparisonModalFormShapeWaterfalls {
  waterfallA: string;
  waterfallB: string;
  waterfallC: string;
  waterfallD: string;
}

export type EstateWaterfallComparisonModalFormShape =
  EstateWaterfallComparisonModalFormShapeWaterfalls &
    EstateWaterfallAssumptionFields;

export function getDefaultEstateWaterfallComparisonModalValues(
  waterfallId: string | null
): EstateWaterfallComparisonModalFormShape {
  return {
    waterfallA: waterfallId || '',
    waterfallB: '',
    waterfallC: '',
    waterfallD: '',
    ...DEFAULT_ASSUMPTIONS,
  };
}
