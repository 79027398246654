import { Stack } from '@mui/material';
import { useState } from 'react';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { Button } from '@/components/form/baseInputs/Button';
import { Upload01Icon } from '@/components/icons/Upload01Icon';
import { BigBreadcrumbs } from '@/components/navigation/BigBreadcrumbs';
import { DataTableCard } from '@/components/tables/DataTable/components/DataTableCard';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { DocumentsTable } from '@/modules/documents/DocumentsTable/DocumentsTable';
import { MultiDocumentUploaderProvider } from '@/modules/documents/MultiDocumentUploader/context/MultiDocumentUploader.provider';
import { DocumentWhereInput } from '@/types/schema';

function getDocumentsLike(
  entityId: string,
  householdId: string
): DocumentWhereInput {
  return {
    hasHouseholdWith: [{ id: householdId }],
    or: [
      { hasEntityWith: [{ id: entityId }] },
      { hasAdditionalEntitiesWith: [{ id: entityId }] },
    ],
  };
}

export function EntityDocumentsPage() {
  const householdId = useRequiredParam('householdId');
  const entityId = useRequiredParam('entityId');
  const [createDocumentModalOpen, setCreateDocumentModalOpen] = useState(false);

  return (
    <MultiDocumentUploaderProvider>
      <Stack height="100%" p={3} pb={4}>
        <SubpageLayout
          heading={<BigBreadcrumbs crumbs={[{ label: 'Documents' }]} />}
          actions={
            <Button
              size="sm"
              onClick={() => setCreateDocumentModalOpen(true)}
              variant="primary"
              startIcon={Upload01Icon}
            >
              Upload documents
            </Button>
          }
        >
          <DataTableCard>
            <DocumentsTable
              householdId={householdId}
              entityId={entityId}
              createDocumentModalOpen={createDocumentModalOpen}
              handleCreateDocumentModalClose={() =>
                setCreateDocumentModalOpen(false)
              }
              documentsLike={getDocumentsLike(entityId, householdId)}
            />
          </DataTableCard>
        </SubpageLayout>
      </Stack>
    </MultiDocumentUploaderProvider>
  );
}
