// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GrowthProfiles_GrowthProfileFragment = { __typename?: 'GrowthProfile', id: string, displayName: string, isDefault: boolean };

export type GrowthProfilesQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.GrowthProfileWhereInput>;
}>;


export type GrowthProfilesQuery = { __typename?: 'Query', growthProfiles: { __typename?: 'GrowthProfileConnection', edges?: Array<{ __typename?: 'GrowthProfileEdge', node?: { __typename?: 'GrowthProfile', id: string, displayName: string, isDefault: boolean } | null } | null> | null } };

export const GrowthProfiles_GrowthProfileFragmentDoc = gql`
    fragment GrowthProfiles_GrowthProfile on GrowthProfile {
  id
  displayName
  isDefault
}
    `;
export const GrowthProfilesDocument = gql`
    query GrowthProfiles($where: GrowthProfileWhereInput) {
  growthProfiles(where: $where) {
    edges {
      node {
        ...GrowthProfiles_GrowthProfile
      }
    }
  }
}
    ${GrowthProfiles_GrowthProfileFragmentDoc}`;

/**
 * __useGrowthProfilesQuery__
 *
 * To run a query within a React component, call `useGrowthProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrowthProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrowthProfilesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGrowthProfilesQuery(baseOptions?: Apollo.QueryHookOptions<GrowthProfilesQuery, GrowthProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GrowthProfilesQuery, GrowthProfilesQueryVariables>(GrowthProfilesDocument, options);
      }
export function useGrowthProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GrowthProfilesQuery, GrowthProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GrowthProfilesQuery, GrowthProfilesQueryVariables>(GrowthProfilesDocument, options);
        }
export function useGrowthProfilesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GrowthProfilesQuery, GrowthProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GrowthProfilesQuery, GrowthProfilesQueryVariables>(GrowthProfilesDocument, options);
        }
export type GrowthProfilesQueryHookResult = ReturnType<typeof useGrowthProfilesQuery>;
export type GrowthProfilesLazyQueryHookResult = ReturnType<typeof useGrowthProfilesLazyQuery>;
export type GrowthProfilesSuspenseQueryHookResult = ReturnType<typeof useGrowthProfilesSuspenseQuery>;
export type GrowthProfilesQueryResult = Apollo.QueryResult<GrowthProfilesQuery, GrowthProfilesQueryVariables>;
export const Operations = {
  Query: {
    GrowthProfiles: 'GrowthProfiles' as const
  },
  Fragment: {
    GrowthProfiles_GrowthProfile: 'GrowthProfiles_GrowthProfile' as const
  }
}