import { Stack } from '@mui/material';
import { useCallback } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { XCloseIcon } from '@/components/icons/XCloseIcon';
import { ConfirmationModal } from '@/components/modals/ConfirmationModal/ConfirmationModal';
import { Footer } from '@/components/navigation/Footer';
import { useFormContext } from '@/components/react-hook-form';
import { useModalState } from '@/hooks/useModalState';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { AI_ONBOARDING_MODAL_STEP_CONFIGS } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.constants';
import { AIOnboardingModalFormShape } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import {
  ConfirmEndAIOnboardingWorkflowModal,
  EndAIOnboardingWorkflowAction,
} from '@/modules/aiOnboarding/AIOnboardingModal/ConfirmEndAIOnboardingWorkflowModal';
import { useAIOnboardingModalContext } from '@/modules/aiOnboarding/AIOnboardingModal/context/AIOnboardingModal.context';

export function AIOnboardingModalFooter() {
  const trackUserEvent = useTrackUserEvent();

  const [
    { isModalOpen: isConfirmCloseModalOpen },
    { setModalIsOpen: setConfirmCloseModalOpen },
  ] = useModalState();

  const [
    { isModalOpen: isConfirmEndWorkflowModalOpen },
    { setModalIsOpen: setConfirmEndWorkflowModalOpen },
  ] = useModalState();

  const {
    shouldBlockNavigation,
    formState: { isSubmitting: formIsSubmitting },
  } = useFormContext<AIOnboardingModalFormShape>();

  const { activeStep, onSubmit, onClose, isSubmitting, endWorkflow } =
    useAIOnboardingModalContext();

  const handleConfirmCloseModalClose = useCallback(
    (reason: 'cancel' | 'confirm') => {
      if (reason === 'confirm') {
        onClose();
      }
      setConfirmCloseModalOpen(false);
    },
    [onClose, setConfirmCloseModalOpen]
  );

  const handleRequestClose = useCallback(() => {
    if (shouldBlockNavigation) {
      setConfirmCloseModalOpen(true);
      return;
    }
    onClose();
  }, [onClose, setConfirmCloseModalOpen, shouldBlockNavigation]);

  const handleConfirmEndWorkflowModalClose = useCallback(
    (action: EndAIOnboardingWorkflowAction) => {
      setConfirmEndWorkflowModalOpen(false);
      if (action === EndAIOnboardingWorkflowAction.CANCEL) {
        return;
      }

      const shouldDeleteDocuments =
        action === EndAIOnboardingWorkflowAction.CONFIRM_DELETE_DOCUMENTS;

      endWorkflow(shouldDeleteDocuments);

      trackUserEvent('ai_onboarding end workflow', {
        shouldDeleteDocuments,
        activeStep,
      });
    },
    [activeStep, endWorkflow, setConfirmEndWorkflowModalOpen, trackUserEvent]
  );

  if (!activeStep) {
    return null;
  }

  const { primaryButtonText } = AI_ONBOARDING_MODAL_STEP_CONFIGS[activeStep];

  return (
    <>
      <Footer
        leftAction={
          <Button
            variant="secondary"
            size="sm"
            startIcon={XCloseIcon}
            disabled={isSubmitting || formIsSubmitting}
            onClick={() => setConfirmEndWorkflowModalOpen(true)}
          >
            End this workflow
          </Button>
        }
        rightAction={
          <Stack spacing={2} direction="row">
            <Button
              variant="secondary"
              size="sm"
              disabled={isSubmitting || formIsSubmitting}
              onClick={handleRequestClose}
            >
              Cancel
            </Button>
            {primaryButtonText && (
              <Button
                variant="primary"
                size="sm"
                type="submit"
                onClick={onSubmit}
                loading={isSubmitting || formIsSubmitting}
              >
                {primaryButtonText}
              </Button>
            )}
          </Stack>
        }
      />
      <ConfirmationModal
        isOpen={isConfirmCloseModalOpen}
        heading="You have unsaved changes that will be discarded"
        cancelText="Cancel"
        confirmText="Proceed"
        confirmButtonProps={{ variant: 'primary' }}
        onClose={handleConfirmCloseModalClose}
      />
      <ConfirmEndAIOnboardingWorkflowModal
        isOpen={isConfirmEndWorkflowModalOpen}
        onClose={handleConfirmEndWorkflowModalClose}
      />
    </>
  );
}
