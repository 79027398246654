import { Stack } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { useBeneficiaryReportingContext } from '@/modules/beneficiaryReporting/beneficiaryReporting.context';
import { BenefitsOverviewBarCharts } from '@/modules/beneficiaryReporting/benefitsOverview/BenefitsOverviewBarCharts';
import { BenefitsOverviewMetrics } from '@/modules/beneficiaryReporting/benefitsOverview/BenefitsOverviewMetrics';

export function ClientDetailsEstateWaterfallBeneficiaryReportingOverviewPage() {
  const {
    firstGrantor,
    secondGrantor,
    firstGrantorDeathYear,
    secondGrantorDeathYear,
  } = useBeneficiaryReportingContext();

  return (
    <Stack spacing={3}>
      <Card variant="outlined" sx={{ p: 3 }}>
        <BenefitsOverviewMetrics />
      </Card>
      <Card variant="outlined" sx={{ p: 3 }}>
        <BenefitsOverviewBarCharts
          firstGrantor={firstGrantor}
          secondGrantor={secondGrantor}
          firstGrantorDeathYear={firstGrantorDeathYear}
          secondGrantorDeathYear={secondGrantorDeathYear}
        />
      </Card>
    </Stack>
  );
}
