import { useContext, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { EntityKind, LoggedTransferPurpose } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { LoggedTransferPurposeCopyMap } from './gifting.copy';
import { ModalTypeContext } from './LogNewTransferModal/LogNewTransferModal';
import { NAMESPACE } from './LogNewTransferModal/LogNewTransferModal.constants';
import {
  LogNewTransferForm,
  LogNewTransferFormPaths,
  TransferType,
} from './LogNewTransferModal/LogNewTransferModal.types';
import { TransferOption } from './TransferOption.type';

const getFieldName = (field: keyof LogNewTransferForm[typeof NAMESPACE]) =>
  `${NAMESPACE}.${field}` as const satisfies LogNewTransferFormPaths;

const commonOptions: SelectInputProps['options'] = [
  {
    value: LoggedTransferPurpose.Other,
    display: 'Other',
  },
];

const getEntitySpecificOptions = (
  entityKind: EntityKind | undefined | null,
  transferType: TransferType,
  isInboundDistribution: boolean,
  selectedTransferOption?: TransferOption
): SelectInputProps['options'] => {
  // If this is an inbound disposition, we need to use the selected source
  // to know the entity kind of the initiator
  if (isInboundDistribution) {
    const sourceEntityKind = selectedTransferOption?.entityKind;

    switch (sourceEntityKind) {
      case EntityKind.DonorAdvisedFund:
      case EntityKind.PrivateFoundation:
        return [
          {
            value: LoggedTransferPurpose.Grant,
            display: LoggedTransferPurposeCopyMap[LoggedTransferPurpose.Grant],
          },
        ];
      default:
        return [
          {
            value: LoggedTransferPurpose.BeneficiaryDistribution,
            display:
              LoggedTransferPurposeCopyMap[
                LoggedTransferPurpose.BeneficiaryDistribution
              ],
          },
          {
            value: LoggedTransferPurpose.CharitableGift,
            display:
              LoggedTransferPurposeCopyMap[
                LoggedTransferPurpose.CharitableGift
              ],
          },
        ];
    }
  }

  switch (transferType) {
    case TransferType.Contribution:
      switch (entityKind) {
        case EntityKind.DonorAdvisedFund:
        case EntityKind.PrivateFoundation:
          return [
            {
              value: LoggedTransferPurpose.CharitableGift,
              display:
                LoggedTransferPurposeCopyMap[
                  LoggedTransferPurpose.CharitableGift
                ],
            },
          ];
        default:
          return [
            {
              value: LoggedTransferPurpose.NonGiftAddition,
              display:
                LoggedTransferPurposeCopyMap[
                  LoggedTransferPurpose.NonGiftAddition
                ],
            },
          ];
      }
    case TransferType.Distribution:
      switch (entityKind) {
        case EntityKind.DonorAdvisedFund:
        case EntityKind.PrivateFoundation:
          return [
            {
              value: LoggedTransferPurpose.Grant,
              display:
                LoggedTransferPurposeCopyMap[LoggedTransferPurpose.Grant],
            },
          ];
        default:
          return [
            {
              value: LoggedTransferPurpose.BeneficiaryDistribution,
              display:
                LoggedTransferPurposeCopyMap[
                  LoggedTransferPurpose.BeneficiaryDistribution
                ],
            },
            {
              value: LoggedTransferPurpose.CharitableGift,
              display:
                LoggedTransferPurposeCopyMap[
                  LoggedTransferPurpose.CharitableGift
                ],
            },
          ];
      }
    default:
      throw new UnreachableError({
        case: transferType,
        message: 'Invalid transfer type provided',
      });
  }
};

export function Purpose() {
  const { control } = useFormContext<LogNewTransferForm>();
  const {
    transferType,
    initiatorEntityKind,
    isInboundDistribution,
    transferOptions,
  } = useContext(ModalTypeContext);

  const purpose = useWatch({
    control,
    name: getFieldName('purpose'),
  });

  const selectedTargetId = useWatch({
    control,
    name: getFieldName('targetId'),
  });

  const selectedTransferOption = transferOptions.find(
    (option) => option.id === selectedTargetId
  );

  const purposeOptions: SelectInputProps['options'] = useMemo(() => {
    const entitySpecificOptions = getEntitySpecificOptions(
      initiatorEntityKind,
      transferType,
      isInboundDistribution,
      selectedTransferOption
    );
    return [...entitySpecificOptions, ...commonOptions];
  }, [
    isInboundDistribution,
    initiatorEntityKind,
    selectedTransferOption,
    transferType,
  ]);

  return (
    <>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareSelectInput
            fieldName={getFieldName('purpose')}
            control={control}
            label="Purpose"
            options={purposeOptions}
          />
        </FormLayoutItem>
      </FormLayoutRow>

      {purpose === LoggedTransferPurpose.Other ? (
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareTextInput
              fieldName={getFieldName('otherPurpose')}
              control={control}
              label="Specify purpose"
            />
          </FormLayoutItem>
        </FormLayoutRow>
      ) : null}
    </>
  );
}
