// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProposalMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateProposalInput;
}>;


export type CreateProposalMutation = { __typename?: 'Mutation', createProposalV2: { __typename?: 'Proposal', id: string } };


export const CreateProposalDocument = gql`
    mutation CreateProposal($input: AugmentedCreateProposalInput!) {
  createProposalV2(input: $input) {
    id
  }
}
    `;
export type CreateProposalMutationFn = Apollo.MutationFunction<CreateProposalMutation, CreateProposalMutationVariables>;

/**
 * __useCreateProposalMutation__
 *
 * To run a mutation, you first call `useCreateProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProposalMutation, { data, loading, error }] = useCreateProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProposalMutation(baseOptions?: Apollo.MutationHookOptions<CreateProposalMutation, CreateProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProposalMutation, CreateProposalMutationVariables>(CreateProposalDocument, options);
      }
export type CreateProposalMutationHookResult = ReturnType<typeof useCreateProposalMutation>;
export type CreateProposalMutationResult = Apollo.MutationResult<CreateProposalMutation>;
export type CreateProposalMutationOptions = Apollo.BaseMutationOptions<CreateProposalMutation, CreateProposalMutationVariables>;
export const Operations = {
  Mutation: {
    CreateProposal: 'CreateProposal' as const
  }
}