import { Stack } from '@mui/material';
import { useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { Footer } from '@/components/navigation/Footer';
import { GratDesignerStages } from '@/navigation/constants';
// eslint-disable-next-line luminary-rules/no-page-imports
import { GRATDesigner } from '@/pages/designer/GRATDesigner/GRATDesigner';
// eslint-disable-next-line luminary-rules/no-page-imports
import {
  TAB_NAMES,
  TabName,
} from '@/pages/designer/GRATDesigner/StructuringDesigner/constants';
import { COLORS } from '@/styles/tokens/colors';

import { DesignerStagePicker } from './DesignerStagePicker';

interface GRATDesignerTrowserProps {
  isOpen?: boolean;
  householdId: string;
  entityId: string;
  onAfterUpdate: () => void;
  initialStage: GratDesignerStages;
  initialStructuringTab?: TabName;
}

export function GRATDesignerTrowser({
  isOpen,
  householdId,
  entityId,
  initialStage,
  initialStructuringTab,
  onAfterUpdate,
}: GRATDesignerTrowserProps) {
  const [currentStage, setCurrentStage] =
    useState<GratDesignerStages>(initialStage);
  return (
    <FullScreenModal isOpen={isOpen} backdropSx={{ bgcolor: COLORS.GRAY[50] }}>
      {isOpen && (
        <GRATDesigner
          householdId={householdId}
          entityId={entityId}
          designerStage={currentStage}
          initialStructuringTab={initialStructuringTab || TAB_NAMES.ASSETS}
          isFullScreenModal={true}
          onAfterUpdate={onAfterUpdate}
          DesignerFooter={({ disabled }) => (
            <GRATDesignerModalFooter
              disabled={disabled}
              currentStage={currentStage}
              onRequestClose={onAfterUpdate}
              onChangeStage={(newStage) => setCurrentStage(newStage)}
            />
          )}
        />
      )}
    </FullScreenModal>
  );
}

interface GRATDesignerModalFooterProps {
  disabled?: boolean;
  currentStage: GratDesignerStages;
  onRequestClose: () => void;
  onChangeStage: (newStage: GratDesignerStages) => void;
}

export function GRATDesignerModalFooter({
  disabled,
  currentStage,
  onRequestClose,
  onChangeStage,
}: GRATDesignerModalFooterProps) {
  return (
    <Footer
      leftAction={
        <Button
          disabled={disabled}
          onClick={onRequestClose}
          variant="secondary"
          size="sm"
        >
          Exit without saving
        </Button>
      }
      rightAction={
        <Stack direction="row" spacing={2}>
          <DesignerStagePicker
            currentStage={currentStage}
            onChangeStage={onChangeStage}
          />
          <Button disabled={disabled} type="submit" variant="primary" size="sm">
            Save and exit
          </Button>
        </Stack>
      }
    />
  );
}
