import { Typography } from '@mui/material';
import React from 'react';
import { FieldValues } from 'react-hook-form';
import { SetRequired } from 'type-fest';

import {
  InputFieldItem,
  InputFieldItemProps,
} from '@/components/form/formAwareInputs/FormAwareInputRepeater/InputFieldItem';
import { DuplicatedFieldGroup } from '@/components/form/formAwareInputs/formAwareRepeater/FormAwareRepeater.types';
import { Card } from '@/components/layout/Card/Card';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';

export interface DuplicatedInputFieldGroupProps<
  FormShape extends FieldValues,
  FieldArrayKeyName extends string,
> extends Omit<
    InputFieldItemProps<FormShape, FieldArrayKeyName>,
    'i' | 'value' | 'onDeleteItemClick'
  > {
  group: SetRequired<
    DuplicatedFieldGroup<FormShape, FieldArrayKeyName>,
    'existingFieldWithIndex' | 'suggestedFieldWithIndex'
  >;
  fieldArrayKeyName: FieldArrayKeyName;
  label: string;
  caption: string;
}

export function DuplicatedInputFieldGroup<
  FormShape extends FieldValues,
  FieldArrayKeyName extends string,
>({
  group,
  fieldArrayKeyName,
  label,
  caption,
  ...rest
}: DuplicatedInputFieldGroupProps<FormShape, FieldArrayKeyName>) {
  const trackUserEvent = useTrackUserEvent();

  return (
    <Card
      variant="filled-teal"
      sx={{
        p: 3,
        pt: 2,
        pr: 1,
        mt: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'visible',
      }}
      data-testid="duplicated-repeater-input-group"
    >
      <InputFieldItem<FormShape, FieldArrayKeyName>
        key={group.suggestedFieldWithIndex.field[fieldArrayKeyName]}
        i={group.suggestedFieldWithIndex.index}
        value={group.suggestedFieldWithIndex.field}
        fieldArrayKeyName={fieldArrayKeyName}
        additionalItemRendererProps={{
          mainLabelOverride: label,
          hideLabelOverride: false,
          showAISparkleIcon: true,
        }}
        onDeleteItemClick={() => {
          trackUserEvent('ai_suggestion resolve duplicates', {
            removedVariant: 'suggested',
          });
        }}
        {...rest}
      />
      <InputFieldItem<FormShape, FieldArrayKeyName>
        key={group.existingFieldWithIndex.field[fieldArrayKeyName]}
        i={group.existingFieldWithIndex.index}
        value={group.existingFieldWithIndex.field}
        fieldArrayKeyName={fieldArrayKeyName}
        additionalItemRendererProps={{
          hideLabelOverride: true,
          hideLocateSuggestionIconOverride: true,
        }}
        onDeleteItemClick={() => {
          trackUserEvent('ai_suggestion resolve duplicates', {
            removedVariant: 'existing',
          });
        }}
        {...rest}
      />
      <Typography variant="subtitle2" mt={1}>
        {caption}
      </Typography>
    </Card>
  );
}
