import { Box, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { Switch } from '@/components/form/baseInputs/Switch';
import { TextInput } from '@/components/form/baseInputs/TextInput';
import { CheckCircleIcon } from '@/components/icons/CheckCircleIcon';
import { EyeIcon } from '@/components/icons/EyeIcon';
import { EyeOffIcon } from '@/components/icons/EyeOffIcon';
import { Card } from '@/components/layout/Card/Card';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import {
  AdminIntegrationsPage_IntegrationConfigurationFragment,
  useUpdateIntegrationConfigurationMutation,
} from '@/pages/admin/AdminIntegrationsPage/graphql/AdminIntegrationsPage.generated';
import { COLORS } from '@/styles/tokens/colors';
import { getPublicImageUrl } from '@/utils/staticFiles';

export interface OpenAIIntegrationCardProps {
  configuration: AdminIntegrationsPage_IntegrationConfigurationFragment;
}

export function OpenAIIntegrationCard({
  configuration,
}: OpenAIIntegrationCardProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [updateConfiguration, { loading }] =
    useUpdateIntegrationConfigurationMutation({
      onError: (err) => {
        showFeedback('Failed to update OpenAI API key');
        reportError('Failed to update OpenAI API key', err);
      },
    });

  const [shouldUseCustomKey, setShouldUseCustomKey] = useState(false);
  const [customKey, setCustomKey] = useState('');
  const [showCustomKey, setShowCustomKey] = useState(false);

  useEffect(() => {
    setShouldUseCustomKey(!!configuration?.hasCustomOpenAIAPIKey);
  }, [configuration?.hasCustomOpenAIAPIKey]);

  const handleToggleUseCustomKey = useCallback(
    async (value: boolean) => {
      if (value) {
        setShouldUseCustomKey(true);
        return;
      }

      // If the user toggled it to FALSE, set the custom key to null.
      await updateConfiguration({
        variables: {
          input: {
            id: configuration.id,
            update: {
              clearOpenAiAPIKey: true,
            },
          },
        },
        onCompleted: () => {
          setShouldUseCustomKey(false);
          setCustomKey('');
          showFeedback("Now using Luminary's default OpenAI API key", {
            variant: 'success',
            autoHideDuration: 3000,
          });
        },
      });
    },
    [configuration.id, showFeedback, updateConfiguration]
  );

  const handleSaveCustomKey = useCallback(async () => {
    if (!customKey) return;
    setShowCustomKey(false);

    await updateConfiguration({
      variables: {
        input: {
          id: configuration.id,
          update: {
            openAiAPIKey: customKey,
          },
        },
      },
      onCompleted: () => {
        setCustomKey('');
        showFeedback('Successfully set custom OpenAI API key', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      },
    });
  }, [configuration?.id, showFeedback, updateConfiguration, customKey]);

  return (
    <Stack>
      <Box textAlign="center">
        <img
          alt="OpenAI"
          src={getPublicImageUrl('/openAILogo.png')}
          height={40}
        />
      </Box>
      <Stack mt={1} spacing={2}>
        <Typography variant="body1">
          Set your own OpenAI API key. This will be used for all AI-related
          tasks on the Luminary Platform. Or leave this empty to use
          Luminary&#39;s default API key.
        </Typography>
        <Stack direction="row" alignItems="center" gap={1.5}>
          <CheckCircleIcon color={COLORS.FUNCTIONAL.SUCCESS.DEFAULT} />
          <Typography variant="h6">
            Currently using{' '}
            {configuration?.hasCustomOpenAIAPIKey
              ? 'custom'
              : "Luminary's default"}{' '}
            OpenAI API key
          </Typography>
        </Stack>
        <Card
          variant="filled-light"
          sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }}
        >
          <Switch
            labelPosition="right"
            value={shouldUseCustomKey}
            label="Use custom API key"
            onChange={(e) => handleToggleUseCustomKey(e.target.checked)}
          />
          {shouldUseCustomKey && (
            <TextInput
              fullWidth
              label="OpenAI API key"
              type={showCustomKey ? 'text' : 'password'}
              value={customKey}
              onChange={(e) => setCustomKey(e.target.value)}
              disabled={loading}
              InputProps={{
                componentsProps: { root: { style: { paddingRight: 8 } } },
              }}
              endAdornment={
                <IconButton
                  variant="transparent"
                  size="sm"
                  icon={showCustomKey ? EyeOffIcon : EyeIcon}
                  disabled={!customKey || loading}
                  onClick={() => setShowCustomKey((prev) => !prev)}
                  ariaLabel="Toggle visibility"
                  sx={{ borderRadius: '50%', p: 1 }}
                />
              }
            />
          )}
        </Card>
      </Stack>
      <Box mt={3} mb={2} textAlign="center">
        <Button
          size="lg"
          variant="primary"
          fullWidth
          disabled={!customKey}
          loading={loading}
          onClick={handleSaveCustomKey}
        >
          Save
        </Button>
      </Box>
    </Stack>
  );
}
