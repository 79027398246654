import { PropsWithChildren, useState } from 'react';

import { GiftDesignerModelScenariosContext } from './GiftDesignerModelScenarios.context';

type GiftDesignerModelScenariosProviderProps = PropsWithChildren;

function useGiftDesignerModelScenariosContextValue(): GiftDesignerModelScenariosContext {
  const [proposal, setProposal] =
    useState<GiftDesignerModelScenariosContext['proposal']>(null);

  const [scenarioTabState, setScenarioTabState] = useState<
    GiftDesignerModelScenariosContext['scenarioTabState']
  >({});

  return {
    proposal,
    setProposal,
    scenarioTabState,
    setScenarioTabState,
  };
}

export const GiftDesignerModelScenariosProvider = ({
  children,
}: GiftDesignerModelScenariosProviderProps) => {
  const value = useGiftDesignerModelScenariosContextValue();

  return (
    <GiftDesignerModelScenariosContext.Provider value={value}>
      {children}
    </GiftDesignerModelScenariosContext.Provider>
  );
};
