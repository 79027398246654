import { Box, Typography } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { Outlet } from 'react-router-dom';

function PrintableRouteFallback() {
  return (
    <Typography variant="h1">Failed to generate printable document</Typography>
  );
}

export function PrintOnlyLayout() {
  return (
    <ErrorBoundary fallback={PrintableRouteFallback}>
      <Box
        sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}
      >
        <Outlet />
      </Box>
    </ErrorBoundary>
  );
}
