import { Typography } from '@mui/material';

import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '@/components/modals/ConfirmationModal/ConfirmationModal';
import { pluralize } from '@/utils/formatting/strings';

export interface CSVUploadConfirmationModalProps
  extends Omit<
    ConfirmationModalProps,
    'heading' | 'confirmText' | 'confirmationOnly'
  > {
  updatedValuations: number;
}

export function CSVUploadConfirmationModal({
  updatedValuations,
  ...modalProps
}: CSVUploadConfirmationModalProps) {
  return (
    <ConfirmationModal
      heading="Valuation update completed"
      confirmationOnly
      confirmText="OK"
      {...modalProps}
    >
      <Typography variant="body1">
        Updated {updatedValuations} {pluralize(updatedValuations, 'valuation')}{' '}
        from the uploaded CSV file
      </Typography>
    </ConfirmationModal>
  );
}
