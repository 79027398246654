// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrintPresentationMutationVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
  pathToPrint: Types.Scalars['String']['input'];
}>;


export type PrintPresentationMutation = { __typename?: 'Mutation', print: { __typename?: 'File', id: string, download: { __typename?: 'FileDownload', downloadURL: string } } };


export const PrintPresentationDocument = gql`
    mutation PrintPresentation($householdId: ID!, $pathToPrint: String!) {
  print(input: {householdID: $householdId, relativePath: $pathToPrint}) {
    id
    download {
      downloadURL
    }
  }
}
    `;
export type PrintPresentationMutationFn = Apollo.MutationFunction<PrintPresentationMutation, PrintPresentationMutationVariables>;

/**
 * __usePrintPresentationMutation__
 *
 * To run a mutation, you first call `usePrintPresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintPresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printPresentationMutation, { data, loading, error }] = usePrintPresentationMutation({
 *   variables: {
 *      householdId: // value for 'householdId'
 *      pathToPrint: // value for 'pathToPrint'
 *   },
 * });
 */
export function usePrintPresentationMutation(baseOptions?: Apollo.MutationHookOptions<PrintPresentationMutation, PrintPresentationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintPresentationMutation, PrintPresentationMutationVariables>(PrintPresentationDocument, options);
      }
export type PrintPresentationMutationHookResult = ReturnType<typeof usePrintPresentationMutation>;
export type PrintPresentationMutationResult = Apollo.MutationResult<PrintPresentationMutation>;
export type PrintPresentationMutationOptions = Apollo.BaseMutationOptions<PrintPresentationMutation, PrintPresentationMutationVariables>;
export const Operations = {
  Mutation: {
    PrintPresentation: 'PrintPresentation' as const
  }
}