import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { isUndefined, last } from 'lodash';
import { ReactNode, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';
import {
  DispositiveProvisionDispositionKind,
  DispositiveProvisionTemplateKind,
  DispositiveProvisionTransferTaxKind,
  TestamentaryEntityKind,
} from '@/types/schema';

import { DISPOSITIVE_PROVISIONS_FORM_NAMESPACE } from '../../DispositiveProvisionsForm/DispositiveProvisionsForm.constants';
import { Recipient } from '../../DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import { DraggableListItemRecipient } from '../../DispositiveProvisionsForm/DispositiveProvisionsFormRecipients/DraggableListItemRecipient';
import { BLANK_DISCLAIMER_TRUST } from '../DispositiveProvisionsTemplateSplitScreenModal.constants';
import {
  DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE,
  DispositiveProvisionsTemplateShape,
  MaritalTrustType,
} from '../DispositiveProvisionsTemplateSplitScreenModal.types';
import {
  BLANK_MARITAL_TRUST,
  BypassTrustRecipient,
  DisclaimerTrust,
  MaritalTrustRecipient,
  TrustRecipientProps,
} from './DispositiveProvisionsTemplateSplitScreenModal.components';

function ClaytonQTIPTrustRecipient({ index }: TrustRecipientProps) {
  return (
    <DraggableListItemRecipient
      index={index}
      Draghandle={null}
      simulatedValue={null}
      onRemove={null}
      defaultExpanded
      templateType={DispositiveProvisionTemplateKind.MaritalTrust}
      emptyRecipientOptionDisplay={'Select QTIP marital trust'}
      recipientConfiguration={{
        testamentaryEntityTaxSettings: {
          estateInclusionStatus: 'in-estate',
          survivingSpouseStateInEstateStatus: 'in-estate',
        },
        defaultNewTestamentaryEntityName: 'QTIP marital trust',
        defaultNewTestamentaryEntityKind:
          TestamentaryEntityKind.TestamentaryTrust,
      }}
    />
  );
}

function handleAdditionalTrustChange(
  recipients: Recipient[],
  newAdditionalTrust: MaritalTrustType
): Recipient[] {
  // when removing the additional trust:
  // - remove the first item (disclaimer/QTIP trust)
  // - reset the remaining marital trust to a 100% distribution
  if (newAdditionalTrust === MaritalTrustType.None) {
    const lastRecipient: Recipient = {
      ...(last(recipients) || BLANK_MARITAL_TRUST),
      dispositionKind_ButtonGroup:
        DispositiveProvisionDispositionKind.Percentage,
      dispositionKind_Select: '',
      dispositionAmount: null,
      dispositionPercentage: new Decimal(100),
    };

    return [lastRecipient];
  }

  // when adding an additional trust:
  // - reset the marital trust's payout to anything left over...
  const lastRecipient: Recipient = {
    ...(last(recipients) || BLANK_MARITAL_TRUST),
    dispositionKind_ButtonGroup: 'other',
    dispositionKind_Select:
      DispositiveProvisionDispositionKind.AnythingLeftOver,
    dispositionAmount: null,
    dispositionPercentage: null,
  };

  // ...and prepend a QTIP/disclaimer trust, based on user input
  if (newAdditionalTrust === MaritalTrustType.ClaytonQTIPTrust) {
    return [BLANK_CLAYTON_QTIP_TRUST, lastRecipient];
  } else {
    return [BLANK_DISCLAIMER_TRUST, lastRecipient];
  }
}

export function DispositiveProvisionsTemplateMaritalTrustPreset() {
  const { control, setValue } =
    useFormContext<DispositiveProvisionsTemplateShape>();
  const [maritalTrustType, recipients] = useWatch({
    control,
    name: [
      `${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.maritalTrustType`,
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients`,
    ],
  });

  const maritalTrustTypePrevious = usePrevious(maritalTrustType);

  useEffect(() => {
    if (isUndefined(maritalTrustTypePrevious)) return;
    if (maritalTrustType === maritalTrustTypePrevious) return;

    const newRecipients = handleAdditionalTrustChange(
      recipients,
      maritalTrustType
    );

    setValue(
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients`,
      newRecipients
    );
  }, [maritalTrustType, maritalTrustTypePrevious, recipients, setValue]);

  let body: ReactNode = (
    <MaritalTrustRecipient
      index={0}
      recipientConfiguration={{
        testamentaryEntityTaxSettings: {
          estateInclusionStatus: 'in-estate',
          survivingSpouseStateInEstateStatus: 'in-estate',
        },
        defaultNewTestamentaryEntityName: 'Marital trust',
        defaultNewTestamentaryEntityKind:
          TestamentaryEntityKind.TestamentaryTrust,
      }}
    />
  );

  if (
    maritalTrustType === MaritalTrustType.DisclaimerTrust &&
    recipients.length > 1
  ) {
    body = (
      <>
        <DisclaimerTrust index={0} />
        <MaritalTrustRecipient
          index={1}
          recipientConfiguration={{
            testamentaryEntityTaxSettings: {
              estateInclusionStatus: 'in-estate',
              survivingSpouseStateInEstateStatus: 'in-estate',
            },
            defaultNewTestamentaryEntityName: 'Marital trust',
            defaultNewTestamentaryEntityKind:
              TestamentaryEntityKind.TestamentaryTrust,
          }}
        />
      </>
    );
  } else if (
    maritalTrustType === MaritalTrustType.ClaytonQTIPTrust &&
    recipients.length > 1
  ) {
    body = (
      <>
        <BypassTrustRecipient
          index={0}
          templateType={DispositiveProvisionTemplateKind.MaritalTrust}
          recipientConfiguration={{
            testamentaryEntityTaxSettings: {
              estateInclusionStatus: 'out-of-estate',
              survivingSpouseStateInEstateStatus: 'out-of-estate',
            },
            defaultNewTestamentaryEntityName: 'Bypass trust',
            defaultNewTestamentaryEntityKind:
              TestamentaryEntityKind.TestamentaryTrust,
          }}
        />
        <ClaytonQTIPTrustRecipient index={1} />
      </>
    );
  }

  return (
    <Stack direction="column" spacing={3}>
      <Card variant="filled-callout" sx={{ padding: 3 }}>
        <FormAwareRadioGroup<DispositiveProvisionsTemplateShape>
          control={control}
          label="Include disclaimer trust"
          hideLabel
          fieldName={`${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.maritalTrustType`}
          options={[
            {
              label: 'No additional trust',
              value: MaritalTrustType.None,
            },
            {
              label: 'With a disclaimer trust',
              value: MaritalTrustType.DisclaimerTrust,
            },
            {
              label: 'With a Clayton QTIP trust',
              value: MaritalTrustType.ClaytonQTIPTrust,
            },
          ]}
          row={false}
          stackingDirection="row"
        />
      </Card>
      {body}
    </Stack>
  );
}

const BLANK_CLAYTON_QTIP_TRUST: Recipient = {
  recipientId: '', // blank string instead of null to avoid MUI warning
  recipientKind: null,
  dispositionAmount: null,
  dispositionPercentage: null,
  notes: '',
  _dispositiveProvisionId: null,
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseCreditShelter,
  dispositionKind_ButtonGroup: 'other',
  dispositionKind_Select:
    DispositiveProvisionDispositionKind.RemainingLifetimeExclusionOfGrantor,
};
