// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { AsyncJobStatus_AsyncJobFragmentDoc } from '../../../modules/asyncJobs/graphql/AsyncJobs.generated';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/pageInfo.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientListAdvisorClientFragment = { __typename?: 'Household', id: string, billableKind: Types.HouseholdBillableKind, displayName: string, primaryRelationshipOwner: { __typename?: 'User', id: string, displayName: string }, estateValueMetrics: { __typename?: 'HouseholdMetrics', totalEstateValue: CurrencyUSD }, nextTaskDue?: { __typename?: 'EntityTask', id: string, dueAt: Time } | null, subBrand?: { __typename?: 'SubBrand', id: string, displayName: string } | null, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind }> | null, ingestingEntities: { __typename?: 'EntityConnection', edges?: Array<{ __typename?: 'EntityEdge', node?: { __typename?: 'Entity', id: string, asyncJobs: { __typename?: 'AsyncJobConnection', edges?: Array<{ __typename?: 'AsyncJobEdge', node?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null } | null> | null } } | null } | null> | null } };

export type ListClientsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.HouseholdWhereInput>;
  orderBy?: Types.InputMaybe<Array<Types.HouseholdOrder> | Types.HouseholdOrder>;
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['Cursor']['input']>;
}>;


export type ListClientsQuery = { __typename?: 'Query', paginatedData: { __typename?: 'HouseholdConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: any | null, endCursor?: any | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, billableKind: Types.HouseholdBillableKind, displayName: string, primaryRelationshipOwner: { __typename?: 'User', id: string, displayName: string }, estateValueMetrics: { __typename?: 'HouseholdMetrics', totalEstateValue: CurrencyUSD }, nextTaskDue?: { __typename?: 'EntityTask', id: string, dueAt: Time } | null, subBrand?: { __typename?: 'SubBrand', id: string, displayName: string } | null, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind }> | null, ingestingEntities: { __typename?: 'EntityConnection', edges?: Array<{ __typename?: 'EntityEdge', node?: { __typename?: 'Entity', id: string, asyncJobs: { __typename?: 'AsyncJobConnection', edges?: Array<{ __typename?: 'AsyncJobEdge', node?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null } | null> | null } } | null } | null> | null } } | null } | null> | null } };

export const ClientListAdvisorClientFragmentDoc = gql`
    fragment ClientListAdvisorClient on Household {
  id
  billableKind
  primaryRelationshipOwner {
    id
    displayName
  }
  displayName
  estateValueMetrics {
    totalEstateValue
  }
  nextTaskDue {
    id
    dueAt
  }
  subBrand {
    id
    displayName
  }
  integrationClients {
    id
    kind
  }
  ingestingEntities: entities(
    where: {hasAsyncJobsWith: [{kind: ASSET_INGEST, statusIn: [QUEUED, IN_PROGRESS]}]}
  ) {
    edges {
      node {
        id
        asyncJobs(
          first: 1
          where: {kind: ASSET_INGEST, statusIn: [QUEUED, IN_PROGRESS]}
          orderBy: [{direction: DESC, field: created_at}]
        ) {
          edges {
            node {
              ...AsyncJobStatus_AsyncJob
            }
          }
        }
      }
    }
  }
}
    ${AsyncJobStatus_AsyncJobFragmentDoc}`;
export const ListClientsDocument = gql`
    query ListClients($where: HouseholdWhereInput, $orderBy: [HouseholdOrder!], $first: Int!, $after: Cursor) {
  paginatedData: households(
    where: $where
    orderBy: $orderBy
    first: $first
    after: $after
  ) {
    totalCount
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        id
        ...ClientListAdvisorClient
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${ClientListAdvisorClientFragmentDoc}`;

/**
 * __useListClientsQuery__
 *
 * To run a query within a React component, call `useListClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListClientsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useListClientsQuery(baseOptions: Apollo.QueryHookOptions<ListClientsQuery, ListClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListClientsQuery, ListClientsQueryVariables>(ListClientsDocument, options);
      }
export function useListClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListClientsQuery, ListClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListClientsQuery, ListClientsQueryVariables>(ListClientsDocument, options);
        }
export function useListClientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListClientsQuery, ListClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListClientsQuery, ListClientsQueryVariables>(ListClientsDocument, options);
        }
export type ListClientsQueryHookResult = ReturnType<typeof useListClientsQuery>;
export type ListClientsLazyQueryHookResult = ReturnType<typeof useListClientsLazyQuery>;
export type ListClientsSuspenseQueryHookResult = ReturnType<typeof useListClientsSuspenseQuery>;
export type ListClientsQueryResult = Apollo.QueryResult<ListClientsQuery, ListClientsQueryVariables>;
export const Operations = {
  Query: {
    ListClients: 'ListClients' as const
  },
  Fragment: {
    ClientListAdvisorClient: 'ClientListAdvisorClient' as const
  }
}