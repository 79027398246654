import { GridCellParams, GridEventListener } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { FC, useCallback } from 'react';

import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';
import { LoggedTransferPurpose } from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';

import { EntityHistoryModals } from './EntityValuationHistoryPage';
import { TransferRowData } from './EntityValuationHistoryPage.utils';

interface TableRowData extends TransferRowData {
  icon: unknown;
}

const columns: Column<TableRowData>[] = [
  {
    headerName: 'Transaction date',
    field: 'updatedOn',
    flex: 1,
    sortable: false,
    renderCell: TextRenderer({
      text: ({ row }) => format(row.updatedOn, 'MMMM d, yyyy'),
    }),
  },
  {
    headerName: 'Transaction amount',
    field: 'amount',
    flex: 2,
    sortable: false,
    align: 'right',
    renderCell: TextRenderer({
      text: ({ row }) => formatCurrency(row.amount),
      textProps: { fontWeight: FONT_WEIGHTS.bold },
    }),
  },
  {
    headerName: 'Transaction type',
    field: 'transferType',
    flex: 2,
    sortable: false,
    renderCell: TwoLineTextRenderer({
      lineOne: ({ row }) => row.transferType,
      lineTwo: ({ row }) => row.transferTypeDescription,
    }),
  },
  {
    headerName: 'Updated by',
    field: 'updatedBy',
    flex: 2,
    sortable: false,
  },
  {
    field: 'actionable',
    headerName: '',
    align: 'center',
    sortable: false,
    width: 64,
    minWidth: 64,
    maxWidth: 64,
    renderCell: IconRenderer({
      icon: ChevronRightIcon,
    }),
  },
];

export const EntityTransferHistoryTable: FC<{
  rows: TransferRowData[];
  onClick: (transferId: string, targetModal: EntityHistoryModals) => void;
  entityId: string;
}> = ({ rows, onClick, entityId }) => {
  const onCellClick: GridEventListener<'cellClick'> = useCallback(
    (params: GridCellParams<TableRowData>) => {
      const { id, data: rowData, actionable } = params.row;

      if (!actionable) {
        return;
      }

      if (rowData.purpose === LoggedTransferPurpose.TaxableGift) {
        const lifetimeExclusionEventId = rowData.lifetimeExclusionEvents?.find(
          ({ id }) => !!id
        )?.id;
        if (!lifetimeExclusionEventId) {
          throw new Error('Could not get exclusion event ID for gift');
        }
        onClick(lifetimeExclusionEventId, EntityHistoryModals.Gift);
        return;
      } else if (rowData.receivingEntity?.id === entityId) {
        onClick(id, EntityHistoryModals.Contribution);
        return;
      } else if (rowData.sourceEntity?.id === entityId) {
        onClick(id, EntityHistoryModals.Distribution);
        return;
      }
      throw new Error('Unable to determine modal to show for transfer');
    },
    [onClick, entityId]
  );

  return (
    <DataTable
      columns={columns}
      rows={rows}
      disableColumnReorder
      disableChildrenSorting
      disableMultipleColumnsSorting
      onCellClick={onCellClick}
    />
  );
};
