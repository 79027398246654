import { ActionTile } from '@/components/layout/ActionTile/ActionTile';
import { Grid } from '@/components/layout/Grid';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';

export type HypotheticalEventType =
  | 'GIFT_TRANSFER'
  | 'INSTALLMENT_SALE'
  | 'INTRA_FAMILY_LOAN';

interface CreateHypotheticalEventModalProps {
  isOpen: boolean;
  // null means the modal was closed without selecting an option
  onClose: (selection: HypotheticalEventType | null) => void;
}

export function CreateHypotheticalEventModal({
  isOpen,
  onClose,
}: CreateHypotheticalEventModalProps) {
  return (
    <DialogModal
      heading="Create a hypothetical transfer"
      isOpen={isOpen}
      onClose={() => onClose(null)}
    >
      <Grid container spacing={2} flex={1} columns={12}>
        <Grid item sm={4}>
          <ActionTile
            containerSx={{ height: '100%', width: '100%' }}
            variant="small"
            onClick={() => onClose('GIFT_TRANSFER')}
            heading="Gift/transfer"
            body="Model a gift or trust transfer within entities"
          />
        </Grid>
        <Grid item sm={4}>
          <ActionTile
            containerSx={{ height: '100%', width: '100%' }}
            variant="small"
            onClick={() => onClose('INSTALLMENT_SALE')}
            heading="Installment sale"
            body="Model the sale of an asset to an irrevocable trust"
          />
        </Grid>
        <Grid item sm={4}>
          <ActionTile
            containerSx={{ height: '100%', width: '100%' }}
            variant="small"
            onClick={() => onClose('INTRA_FAMILY_LOAN')}
            heading="Intra-family loan"
            body="Model the loaning of an asset to an irrevocable trust"
          />
        </Grid>
      </Grid>
    </DialogModal>
  );
}
