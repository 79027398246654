import { Stack, Typography } from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';
import { FONT_SIZING } from '@/styles/tokens/fonts';

interface DocumentSearchGeneratedResponseProps {
  searchResponse: string;
}

export function DocumentSearchGeneratedResponse({
  searchResponse,
}: DocumentSearchGeneratedResponseProps) {
  return (
    <Stack direction="column" gap={0.75}>
      <Stack
        direction="column"
        bgcolor={COLORS.FUNCTIONAL.WARNING[50]}
        p={2}
        pb={3}
        gap={0.5}
      >
        <Typography
          variant="h6"
          fontWeight="bold"
          color={COLORS.FUNCTIONAL.WARNING[600]}
        >
          Summarized answer
        </Typography>
        <Typography variant="h6" fontSize={FONT_SIZING.sm} fontWeight="normal">
          {searchResponse}
        </Typography>
      </Stack>
      <Typography variant="subtitle2" textAlign="center">
        This is not legal advice. Answers are generated by AI and may contain
        inaccuracies.
      </Typography>
    </Stack>
  );
}
