import { Box } from '@mui/material';
import { first } from 'lodash';
import { MouseEventHandler, useCallback } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { LinkExternal01Icon } from '@/components/icons/LinkExternal01Icon';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import {
  DisplayTable,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { useModalState } from '@/hooks/useModalState';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { DocumentsTableRowAssociatedEntity } from '../DocumentsTable.types';

export interface AssociatedEntitiesRightContentProps {
  associatedEntities: DocumentsTableRowAssociatedEntity[];
}

export function AssociatedEntitiesRightContent({
  associatedEntities,
}: AssociatedEntitiesRightContentProps) {
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();
  const { householdId } = useHouseholdDetailsContext();
  const { navigate } = useNavigateToRoute();

  const handleRowClick = useCallback(
    (entity: DocumentsTableRowAssociatedEntity) => {
      if (!householdId) return;
      if (getPulidKind(entity.id) === PulidKind.TestamentaryEntity) return;

      navigate(ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS, {
        entityId: entity.id,
        householdId,
      });
    },
    [householdId, navigate]
  );

  const handleModalOpen: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      const firstEntity = first(associatedEntities);
      if (firstEntity && associatedEntities.length === 1) {
        handleRowClick(firstEntity);
      } else {
        openModal();
      }
    },
    [associatedEntities, handleRowClick, openModal]
  );

  if (associatedEntities.length === 0) {
    return null;
  }

  return (
    <>
      <DialogModal
        isOpen={isModalOpen}
        heading="Document is shared with the following entities"
        onClose={closeModal}
        dialogContentSx={{ pb: 4 }}
      >
        <DisplayTable
          hideTableHead
          columns={[{ headerName: 'Used with' }, { headerName: 'Action' }]}
        >
          {associatedEntities.map((entity) => {
            const isTestamentaryEntity =
              getPulidKind(entity.id) === PulidKind.TestamentaryEntity;
            let description = entity.extendedDisplayKind;
            if (isTestamentaryEntity) {
              description = 'Testamentary entity';
            }

            return (
              <StyledTableRow
                key={entity.id}
                onClick={() => handleRowClick(entity)}
              >
                <StyledTableCell>
                  <RichListItem
                    heading={entity.description}
                    description={description}
                  />
                </StyledTableCell>
                <StyledTableCell align="right">
                  <Button
                    variant="transparent"
                    size="sm"
                    onClick={() => handleRowClick(entity)}
                    endIcon={ChevronRightIcon}
                    disabled={isTestamentaryEntity}
                  />
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </DisplayTable>
      </DialogModal>
      <Box>
        <IconButton
          icon={LinkExternal01Icon}
          ariaLabel="View associated entities"
          onClick={handleModalOpen}
          variant="transparent"
          size={'sm'}
        />
      </Box>
    </>
  );
}
