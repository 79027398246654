// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StructuredAssetsSubform_AssetFragment = { __typename?: 'AssetV2', id: string, displayName: string, integrationType?: Types.AssetV2IntegrationType | null, assetValue: { __typename?: 'AssetValueV2', id: string, ownedValue?: CurrencyUSD | null }, class: { __typename?: 'AssetClass', id: string, displayName: string } };

export type StructuredAssetsSubform_ValuationFragment = { __typename?: 'AssetValuationV2', id: string, effectiveDate: Time, description?: string | null, valuationSource: Types.AssetValuationV2ValuationSource, assets: { __typename?: 'AssetV2Connection', totalCount: number, edges?: Array<{ __typename?: 'AssetV2Edge', node?: { __typename?: 'AssetV2', id: string, displayName: string, integrationType?: Types.AssetV2IntegrationType | null, assetValue: { __typename?: 'AssetValueV2', id: string, ownedValue?: CurrencyUSD | null }, class: { __typename?: 'AssetClass', id: string, displayName: string } } | null } | null> | null }, documents?: Array<{ __typename?: 'Document', id: string }> | null };

export type StructuredAssetsSubform_AccountFragment = { __typename?: 'Account', id: string, valuations: { __typename?: 'AssetValuationV2Connection', edges?: Array<{ __typename?: 'AssetValuationV2Edge', node?: { __typename?: 'AssetValuationV2', id: string, effectiveDate: Time, description?: string | null, valuationSource: Types.AssetValuationV2ValuationSource, assets: { __typename?: 'AssetV2Connection', totalCount: number, edges?: Array<{ __typename?: 'AssetV2Edge', node?: { __typename?: 'AssetV2', id: string, displayName: string, integrationType?: Types.AssetV2IntegrationType | null, assetValue: { __typename?: 'AssetValueV2', id: string, ownedValue?: CurrencyUSD | null }, class: { __typename?: 'AssetClass', id: string, displayName: string } } | null } | null> | null }, documents?: Array<{ __typename?: 'Document', id: string }> | null } | null } | null> | null }, initialValuation?: { __typename?: 'AssetValuationV2', id: string, effectiveDate: Time, description?: string | null, valuationSource: Types.AssetValuationV2ValuationSource, assets: { __typename?: 'AssetV2Connection', totalCount: number, edges?: Array<{ __typename?: 'AssetV2Edge', node?: { __typename?: 'AssetV2', id: string, displayName: string, integrationType?: Types.AssetV2IntegrationType | null, assetValue: { __typename?: 'AssetValueV2', id: string, ownedValue?: CurrencyUSD | null }, class: { __typename?: 'AssetClass', id: string, displayName: string } } | null } | null> | null }, documents?: Array<{ __typename?: 'Document', id: string }> | null } | null };

export type StructuredAssetsSubform_AssetClassFragment = { __typename?: 'AssetClass', id: string, displayName: string, sortOrder: number };

export type AssetCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AssetCategoriesQuery = { __typename?: 'Query', assetClasses: { __typename?: 'AssetClassConnection', edges?: Array<{ __typename?: 'AssetClassEdge', node?: { __typename?: 'AssetClass', id: string, displayName: string, sortOrder: number } | null } | null> | null } };

export const StructuredAssetsSubform_AssetFragmentDoc = gql`
    fragment StructuredAssetsSubform_Asset on AssetV2 {
  id
  displayName
  integrationType
  assetValue {
    id
    ownedValue
  }
  class {
    id
    displayName
  }
}
    `;
export const StructuredAssetsSubform_ValuationFragmentDoc = gql`
    fragment StructuredAssetsSubform_Valuation on AssetValuationV2 {
  id
  effectiveDate
  description
  valuationSource
  assets {
    totalCount
    edges {
      node {
        ...StructuredAssetsSubform_Asset
      }
    }
  }
  documents {
    id
  }
}
    ${StructuredAssetsSubform_AssetFragmentDoc}`;
export const StructuredAssetsSubform_AccountFragmentDoc = gql`
    fragment StructuredAssetsSubform_Account on Account {
  id
  valuations(
    orderBy: [{field: effective_date, direction: DESC}, {field: created_at, direction: DESC}]
    first: 1
  ) {
    edges {
      node {
        ...StructuredAssetsSubform_Valuation
      }
    }
  }
  initialValuation {
    ...StructuredAssetsSubform_Valuation
  }
}
    ${StructuredAssetsSubform_ValuationFragmentDoc}`;
export const StructuredAssetsSubform_AssetClassFragmentDoc = gql`
    fragment StructuredAssetsSubform_AssetClass on AssetClass {
  id
  displayName
  sortOrder
}
    `;
export const AssetCategoriesDocument = gql`
    query AssetCategories {
  assetClasses {
    edges {
      node {
        ...StructuredAssetsSubform_AssetClass
      }
    }
  }
}
    ${StructuredAssetsSubform_AssetClassFragmentDoc}`;

/**
 * __useAssetCategoriesQuery__
 *
 * To run a query within a React component, call `useAssetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssetCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<AssetCategoriesQuery, AssetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetCategoriesQuery, AssetCategoriesQueryVariables>(AssetCategoriesDocument, options);
      }
export function useAssetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetCategoriesQuery, AssetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetCategoriesQuery, AssetCategoriesQueryVariables>(AssetCategoriesDocument, options);
        }
export function useAssetCategoriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AssetCategoriesQuery, AssetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AssetCategoriesQuery, AssetCategoriesQueryVariables>(AssetCategoriesDocument, options);
        }
export type AssetCategoriesQueryHookResult = ReturnType<typeof useAssetCategoriesQuery>;
export type AssetCategoriesLazyQueryHookResult = ReturnType<typeof useAssetCategoriesLazyQuery>;
export type AssetCategoriesSuspenseQueryHookResult = ReturnType<typeof useAssetCategoriesSuspenseQuery>;
export type AssetCategoriesQueryResult = Apollo.QueryResult<AssetCategoriesQuery, AssetCategoriesQueryVariables>;
export const Operations = {
  Query: {
    AssetCategories: 'AssetCategories' as const
  },
  Fragment: {
    StructuredAssetsSubform_Asset: 'StructuredAssetsSubform_Asset' as const,
    StructuredAssetsSubform_Valuation: 'StructuredAssetsSubform_Valuation' as const,
    StructuredAssetsSubform_Account: 'StructuredAssetsSubform_Account' as const,
    StructuredAssetsSubform_AssetClass: 'StructuredAssetsSubform_AssetClass' as const
  }
}