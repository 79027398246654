import { Stack, Typography } from '@mui/material';

import { zIndexes } from '@/styles/zIndexes';

export interface TableEmptyStateProps {
  text: React.ReactNode;
}

export function TableEmptyState({
  text,
  children,
}: React.PropsWithChildren<TableEmptyStateProps>) {
  return (
    <Stack
      // need to elevate the zIndex a little bit because of some MUI internals
      zIndex={zIndexes.CARD}
      position="relative"
      p={4}
      alignItems="center"
      justifyContent="center"
      width="100%"
    >
      <Typography mb={2} variant="subtitle1">
        {text}
      </Typography>
      {children}
    </Stack>
  );
}
