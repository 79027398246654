import { ApolloError, QueryHookOptions, QueryResult } from '@apollo/client';
import { Stack, Typography } from '@mui/material';
import { compact, map } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { Button } from '@/components/form/baseInputs/Button';
import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { Card } from '@/components/layout/Card/Card';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import {
  PaginationQuery,
  PaginationQueryVariables,
} from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import {
  DisplayTable,
  DisplayTableColumn,
} from '@/components/tables/DisplayTable/DisplayTable';
import { TableRowSkeleton } from '@/components/tables/TableRowSkeleton/TableRowSkeleton';
import { useReportError } from '@/hooks/useReportError';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import {
  BULK_IMPORT_FLOW_ADDEPAR,
  BulkImportFlowContext,
} from '@/modules/import/BulkImportFlowContext';
import { usePossibleRelationshipOwnerQuery } from '@/modules/possibleRelationshipOwner/graphql/PossibleRelationshipOwner.generated';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';

import { mapAddeparDataToClientDataMap } from '../addepar/BulkImportPage.addepar';
import {
  AddeparClientImportLookupQuery,
  AddeparClientImportLookupQueryVariables,
  useAddeparClientImportLookupQuery,
} from '../addepar/graphql/AddeparClientImportLookup.generated';
import {
  BULK_IMPORT_STEP_CLIENT_DETAILS,
  BULK_IMPORT_STEP_CLIENT_SELECTION,
  BULK_IMPORT_STEP_ENTITY_DETAILS,
} from '../BulkImportPage.constants';
import { BulkImportForm, BulkImportStepConfig } from '../BulkImportPage.types';
import { useBulkClientImportMutationMutation } from '../graphql/BulkClientImportMutation.generated';
import { BulkImportClientDetailRow } from './BulkImportClientDetails.components';
import { mapFormDataToMutation } from './BulkImportClientDetails.utils';

function getColumns(headerName: string): DisplayTableColumn[] {
  return [
    {
      headerName,
      flex: 2,
    },
    {
      headerName: 'Luminary first name',
      flex: 2,
    },
    {
      headerName: 'Luminary last name',
      flex: 2,
    },
    {
      headerName: 'State of residence',
      flex: 1,
    },
    {
      headerName: 'Relationship owner',
      flex: 2,
    },
    {
      headerName: 'Merge into single household',
      flex: 2,
    },
  ];
}

export function BulkImportClientDetails() {
  const { flowType } = useContext(BulkImportFlowContext);

  switch (flowType) {
    case BULK_IMPORT_FLOW_ADDEPAR:
      return (
        <BulkImportClientDetailsInner<
          AddeparClientImportLookupQuery,
          AddeparClientImportLookupQueryVariables
        >
          headerName="Addepar name"
          getVariables={(integrationClientIdsToImport: string[]) => {
            return {
              first: integrationClientIdsToImport.length,
              where: {
                idIn: integrationClientIdsToImport,
              },
            };
          }}
          fetchData={useAddeparClientImportLookupQuery}
          mapDataToTable={mapAddeparDataToClientDataMap}
          tableCaption={
            <Typography variant="body1">
              Correct the first & last name fields as needed and specify the
              relationship owner for each new client. If two individuals in
              Addepar should form a single client in Luminary, select the second
              individual from the “Merge into single household” dropdown in the
              right-most column.
            </Typography>
          }
        />
      );
    default:
      throw new UnreachableError({
        case: flowType,
        message: `Invalid flowType: ${flowType}`,
      });
  }
}

interface BulkImportClientDetailsInnerProps<
  TData extends PaginationQuery,
  TVariables extends PaginationQueryVariables,
> {
  fetchData: (
    options: QueryHookOptions<TData, TVariables>
  ) => QueryResult<TData, TVariables>;
  mapDataToTable: (
    queryData: TData,
    currentUserID?: string
  ) => BulkImportForm['householdMap'];
  getVariables: (integrationClientIdsToImport: string[]) => TVariables;
  headerName: string;
  tableCaption?: JSX.Element;
}

function BulkImportClientDetailsInner<
  TData extends PaginationQuery,
  TVariables extends PaginationQueryVariables,
>({
  headerName,
  getVariables,
  fetchData,
  mapDataToTable,
  tableCaption,
}: BulkImportClientDetailsInnerProps<TData, TVariables>) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { control, reset, getValues } = useFormContext<BulkImportForm>();
  const selectedIntegrationClientIds = useWatch<BulkImportForm>({
    name: 'selectedIntegrationClientIds',
    control,
  }) as string[];
  diagnostics.info('selectedIntegrationClientIds: ', {
    selectedIntegrationClientIds,
  });
  const currentUserId = useCurrentUser()?.id;

  // doing this lookup here so the possible owner list is visible and ready on first render
  const {
    data: possibleRelationshipOwnersResponse,
    loading: loadingPossibleRelationshipOwners,
  } = usePossibleRelationshipOwnerQuery({
    onError: (error) => {
      showFeedback(
        'Could not fetch bulk import client details.  Please go back and try again.'
      );
      reportError(
        'Caught error when loading possible relationship owners',
        error
      );
    },
  });

  const ownerOptions = useMemo(() => {
    return compact(
      possibleRelationshipOwnersResponse?.possibleRelationshipOwners
    ).map<SelectInputOption<string>>((possibleOwner) => ({
      value: possibleOwner.user.id,
      display: possibleOwner.user.displayName,
    }));
  }, [possibleRelationshipOwnersResponse]);

  const onCompletedClientLookup = useCallback(
    (data: TData) => {
      const householdMap = mapDataToTable(data, currentUserId);
      const integrationClientIds = getValues('selectedIntegrationClientIds');
      reset({
        ...getValues(),
        householdMap,
        step: BULK_IMPORT_STEP_CLIENT_DETAILS,
        selectedIntegrationClientIds: integrationClientIds,
      });
    },
    [currentUserId, getValues, mapDataToTable, reset]
  );

  const { loading: loadingRemoteClients } = fetchData({
    variables: getVariables(selectedIntegrationClientIds),
    onCompleted: onCompletedClientLookup,
    onError: (error) => {
      showFeedback(
        'Could not fetch client details.  Please go back and try again.'
      );
      reportError('Caught error when loading client details', error);
    },
  });

  const householdMap = useWatch({
    name: 'householdMap',
    control,
  });

  const loading = loadingRemoteClients || loadingPossibleRelationshipOwners;

  const columns = useMemo(() => getColumns(headerName), [headerName]);

  return (
    <SubpageLayout heading="Step 2: Link individuals in Luminary">
      <Card variant="outlined" sx={{ p: 3 }}>
        <Stack spacing={3}>
          {tableCaption}
          <DisplayTable columns={columns}>
            {loading
              ? map(selectedIntegrationClientIds, (remoteID) => (
                  <TableRowSkeleton key={remoteID} columns={columns.length} />
                ))
              : map(householdMap, (_, householdId) => (
                  <BulkImportClientDetailRow
                    key={householdId}
                    householdId={householdId}
                    ownerOptions={ownerOptions}
                  />
                ))}
          </DisplayTable>
        </Stack>
      </Card>
    </SubpageLayout>
  );
}

function NextButton() {
  const { setValue, handleSubmit } = useFormContext<BulkImportForm>();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [runMutation] = useBulkClientImportMutationMutation({
    onError: (error: ApolloError) => {
      showFeedback('Could not import clients. Please try again.');
      reportError('Caught error when importing clients', error);
    },
    onCompleted: () => {
      showFeedback(
        'Selected individuals were successfully linked into Luminary',
        { variant: 'success' }
      );
      setValue('step', BULK_IMPORT_STEP_ENTITY_DETAILS);
    },
  });

  const onSubmit = handleSubmit((formData) => {
    const payload = mapFormDataToMutation(formData);
    return runMutation({ variables: { inputs: payload } });
  });

  return (
    <Button variant="primary" size="md" onClick={onSubmit}>
      Import clients & continue
    </Button>
  );
}

function PreviousButton() {
  const { setValue } = useFormContext<BulkImportForm>();
  return (
    <Button
      variant="transparent"
      size="sm"
      onClick={() => {
        setValue('step', BULK_IMPORT_STEP_CLIENT_SELECTION);
      }}
    >
      Previous step
    </Button>
  );
}

export const BulkImportClientDetailsStepConfig: BulkImportStepConfig = {
  Component: BulkImportClientDetails,
  NextButton,
  PreviousButton,
};
