import { ProposalKind } from '@/modules/proposal/types/ProposalKind';
import { EntityProposalFragment } from '@/pages/proposal/graphql/EntityProposalFragment.generated';

import { ListClientProposals_ProposalFragment } from '../graphql/ListClientProposals.generated';
import {
  CharitableProposalActions,
  EntityProposalActions,
  GiftingProposalActions,
} from './Actions';

export function getProposalKind(
  proposal: ListClientProposals_ProposalFragment
): ProposalKind | undefined {
  if (proposal.entityProposals?.length) {
    return ProposalKind.Entity;
  }

  if (proposal.giftingProposal) {
    return ProposalKind.Gift;
  }

  if (proposal.cltProposal) {
    return ProposalKind.CLT;
  }

  if (proposal.crtProposal) {
    return ProposalKind.CRT;
  }

  return;
}

export function mapProposalsToRows(
  proposals: ListClientProposals_ProposalFragment[],
  opts: { refetch: () => void }
) {
  return (
    proposals?.flatMap((proposal) => {
      if (!proposal) return [];

      const kind = getProposalKind(proposal);
      if (!kind) return [];

      const actions = (() => {
        if (proposal.entityProposals?.length) {
          return (
            <EntityProposalActions
              householdId={proposal.household.id}
              refetch={opts.refetch}
              proposalId={proposal.id}
              proposal={proposal}
            />
          );
        }

        if (proposal.giftingProposal) {
          // Some TS coercion to set required giftProposal
          const { giftingProposal } = proposal;
          return (
            <GiftingProposalActions
              householdId={proposal.household.id}
              refetch={opts.refetch}
              proposal={{
                ...proposal,
                giftingProposal,
              }}
            />
          );
        }

        if (proposal.cltProposal || proposal.crtProposal) {
          return (
            <CharitableProposalActions
              refetch={opts.refetch}
              proposal={proposal}
            />
          );
        }

        return null;
      })();

      return {
        id: proposal.id,
        kind,
        displayName: {
          lineOne: proposal.displayName,
          lineTwo: getEntityDisplayNames(proposal.entityProposals).join('\n'),
        },
        updatedAt: proposal.updatedAt,
        createdAt: proposal.createdAt,
        actions,
      };
    }) ?? []
  );
}

function getEntityDisplayNames(
  entityProposals: EntityProposalFragment[] | null | undefined
) {
  return (entityProposals || []).map((entityProposal) => {
    return entityProposal?.entity?.gratTrust?.displayName;
  });
}
