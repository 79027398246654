// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProposalYearProjectionOptionsQueryVariables = Types.Exact<{
  entityId: Types.Scalars['ID']['input'];
}>;


export type ProposalYearProjectionOptionsQuery = { __typename?: 'Query', proposalYearProjectionOptionsV2: Array<{ __typename?: 'YearProjectionOption', display: string, value: number }> };


export const ProposalYearProjectionOptionsDocument = gql`
    query ProposalYearProjectionOptions($entityId: ID!) {
  proposalYearProjectionOptionsV2(entityId: $entityId) {
    display
    value
  }
}
    `;

/**
 * __useProposalYearProjectionOptionsQuery__
 *
 * To run a query within a React component, call `useProposalYearProjectionOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProposalYearProjectionOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProposalYearProjectionOptionsQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useProposalYearProjectionOptionsQuery(baseOptions: Apollo.QueryHookOptions<ProposalYearProjectionOptionsQuery, ProposalYearProjectionOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProposalYearProjectionOptionsQuery, ProposalYearProjectionOptionsQueryVariables>(ProposalYearProjectionOptionsDocument, options);
      }
export function useProposalYearProjectionOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProposalYearProjectionOptionsQuery, ProposalYearProjectionOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProposalYearProjectionOptionsQuery, ProposalYearProjectionOptionsQueryVariables>(ProposalYearProjectionOptionsDocument, options);
        }
export function useProposalYearProjectionOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProposalYearProjectionOptionsQuery, ProposalYearProjectionOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProposalYearProjectionOptionsQuery, ProposalYearProjectionOptionsQueryVariables>(ProposalYearProjectionOptionsDocument, options);
        }
export type ProposalYearProjectionOptionsQueryHookResult = ReturnType<typeof useProposalYearProjectionOptionsQuery>;
export type ProposalYearProjectionOptionsLazyQueryHookResult = ReturnType<typeof useProposalYearProjectionOptionsLazyQuery>;
export type ProposalYearProjectionOptionsSuspenseQueryHookResult = ReturnType<typeof useProposalYearProjectionOptionsSuspenseQuery>;
export type ProposalYearProjectionOptionsQueryResult = Apollo.QueryResult<ProposalYearProjectionOptionsQuery, ProposalYearProjectionOptionsQueryVariables>;
export const Operations = {
  Query: {
    ProposalYearProjectionOptions: 'ProposalYearProjectionOptions' as const
  }
}