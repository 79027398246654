import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export enum DebugLevel {
  DEFAULT = 1,
  // Add verbose, etc.
}

export interface DebugContext {
  debugLevel: DebugLevel | null;
}

export const DebugContext = createContext<DebugContext>({
  debugLevel: null,
});

export const useDebugContext = () => {
  return useGuardedContext(DebugContext, 'DebugContextProvider');
};
