import { Stack } from '@mui/material';

import { EqualIcon } from '@/components/icons/EqualIcon';
import { MinusIcon } from '@/components/icons/MinusIcon';
import { Card } from '@/components/layout/Card/Card';
import { COLORS } from '@/styles/tokens/colors';

import {
  OversizedMetricItem,
  OversizedMetricItemProps,
} from '../OversizedMetricItem/OversizedMetricItem';

export interface MathSummaryProps {
  valueOne: OversizedMetricItemProps;
  valueTwo: OversizedMetricItemProps;
  sum: OversizedMetricItemProps;
}

export function MathSummary({ valueOne, valueTwo, sum }: MathSummaryProps) {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <OversizedMetricItem {...valueOne} />
      <MinusIcon sx={{ color: COLORS.GRAY[400] }} size={18} />
      <OversizedMetricItem {...valueTwo} />
      <EqualIcon sx={{ color: COLORS.GRAY[400] }} size={18} />
      <Card sx={{ p: 2 }} variant="filled-callout">
        <OversizedMetricItem {...sum} />
      </Card>
    </Stack>
  );
}
