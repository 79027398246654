import {
  AugmentedCreateClientOrganizationInput,
  ClientOrganizationKind,
} from '@/types/schema';

import { ClientOrganizationFormShape } from './ClientOrganizationModal.constants';

export function mapFormDataToInput(
  formValues: ClientOrganizationFormShape,
  householdId: string
): AugmentedCreateClientOrganizationInput {
  const { address, ...clientOrganization } = formValues;

  return {
    create: {
      ...clientOrganization,
      kind: clientOrganization.kind || ClientOrganizationKind.Other,
      householdID: householdId,
      pointOfContactEmail: clientOrganization.pointOfContactEmail || null,
      pointOfContactPhone: clientOrganization.pointOfContactPhone || null,
      pointOfContactName: clientOrganization.pointOfContactName || null,
      pointOfContactLastName: clientOrganization.pointOfContactLastName || null,
      isBeneficiary: clientOrganization.isBeneficiary || false,
      isTrustee: clientOrganization.isTrustee || false,
    },
    ...(address.cityName && address.stateCode
      ? { withAddress: { create: address } }
      : {}),
  };
}
