import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';

import { useDuplicateProposal } from '../../hooks/useDuplicateProposal';
import { ProposalKind } from '../../types/ProposalKind';

export interface DuplicateProposalMenuItemProps {
  proposalId: string;
  kind: ProposalKind;
  afterDuplicate: (createdProposalId: string) => void;
}

export function DuplicateProposalMenuItem({
  proposalId,
  kind,
  afterDuplicate,
}: DuplicateProposalMenuItemProps) {
  const [onDuplicateProposal, { loading: isDuplicatingProposal }] =
    useDuplicateProposal(proposalId);

  const showProposal = [ProposalKind.Entity].includes(kind);

  return (
    <MenuItem
      label={`Duplicate ${showProposal ? 'proposal' : 'analysis'}`}
      onClick={async () => {
        await onDuplicateProposal(afterDuplicate);
      }}
      disabled={isDuplicatingProposal}
      muiMenuItemProps={{
        divider: true,
      }}
    />
  );
}
