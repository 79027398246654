import { ApolloClient } from '@apollo/client';

import { grantorsFieldTrustDataToForm } from '@/modules/entities/principals/GrantorsField/GrantorsField.utils';
import { getNonGrantorPrincipalFieldsDataToForm } from '@/modules/entities/principals/NonGrantorPrincipalFields/NonGrantorPrincipalFields.utils';
import { getEntityTypeFromSubtype } from '@/modules/entities/utils/getEntityTypeFromSubtype';
import { EntityStage } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import { EntityFormStage } from '../EntityForm/types';
import { EntityType } from '../types/EntityType';
import {
  BasicInformationSubformType,
  BasicInformationSubformType_SingleGrantor,
  BasicInformationSubformType_WithGrantors,
  defaultValues,
  VariantType,
} from './BasicInformationSubform.types';
import {
  BasicInformation_BusinessEntityFragment,
  BasicInformation_CltTrustFragment,
  BasicInformation_CrtTrustFragment,
  BasicInformation_CustodialPersonalAccountFragment,
  BasicInformation_DafFragment,
  BasicInformation_GratTrustFragment,
  BasicInformation_IlitTrustFragment,
  BasicInformation_IndividualPersonalAccountFragment,
  BasicInformation_InsurancePersonalAccountFragment,
  BasicInformation_IrrevocableTrustFragment,
  BasicInformation_JointPersonalAccountFragment,
  BasicInformation_PrivateFoundationFragment,
  BasicInformation_QprtTrustFragment,
  BasicInformation_QualifiedTuitionPersonalAccountFragment,
  BasicInformation_RetirementPersonalAccountFragment,
  BasicInformation_RevocableTrustFragment,
  BasicInformation_SlatTrustFragment,
  GetBasicInformationDocument,
  GetBasicInformationQuery,
} from './graphql/GetBasicInformation.generated';

type BasicInformationBusinessEntitySubtype =
  BasicInformation_BusinessEntityFragment;

type BasicInformationTrustSubtype =
  | BasicInformation_IrrevocableTrustFragment
  | BasicInformation_RevocableTrustFragment
  | BasicInformation_SlatTrustFragment
  | BasicInformation_IlitTrustFragment
  | BasicInformation_GratTrustFragment
  | BasicInformation_QprtTrustFragment
  | BasicInformation_CltTrustFragment
  | BasicInformation_CrtTrustFragment;

function getSharedTrustProperties(
  subtype: BasicInformationTrustSubtype,
  formData: Partial<
    | BasicInformationSubformType
    | BasicInformationSubformType_WithGrantors
    | BasicInformationSubformType_SingleGrantor
  >
) {
  if (!subtype.__typename) {
    throw new Error(`Unknown entity subtype`);
  }
  const entityType = getEntityTypeFromSubtype(subtype.__typename);

  const grantorsFieldFormValues = grantorsFieldTrustDataToForm(
    entityType,
    subtype
  );

  return {
    legalName: subtype.legalName ?? '',
    displayName: subtype.displayName ?? '',
    effectiveDate: subtype.effectiveDate ?? null,
    jurisdiction: subtype.trustJurisdictionStateCode ?? '',
    description: subtype.description ?? '',
    subtypeId: subtype.id,
    taxableGift: null,
    rate7520: null,
    // these aren't used, but need to be returned to satisfy the form type
    doingBusinessAsName: '',
    requiresCtaReporting: false,
    finCenID: '',
    // Extra form data that's not connected to the subtype
    ...formData,
    // Grantors field data. These fields will be used when ai suggestions
    // are enabled, and we start showing the grantors field in the
    // BasicInformationSubform.
    ...grantorsFieldFormValues,
  };
}

function getBusinessEntityProperties(
  subtype: BasicInformationBusinessEntitySubtype,
  formData: Partial<BasicInformationSubformType>
) {
  return {
    legalName: subtype.legalName ?? '',
    displayName: subtype.displayName ?? '',
    effectiveDate: subtype.effectiveDate ?? null,
    description: subtype.description ?? '',
    subtypeId: subtype.id,
    doingBusinessAsName: subtype.doingBusinessAsName ?? '',
    requiresCtaReporting: subtype.requiresCtaReporting ?? false,
    finCenID: subtype.finCenID ?? '',
    jurisdiction: subtype.formationJurisdictionStateCode ?? '',
    ...formData,
  };
}

type BasicInformationPersonalAccountSubtype =
  | BasicInformation_JointPersonalAccountFragment
  | BasicInformation_IndividualPersonalAccountFragment
  | BasicInformation_CustodialPersonalAccountFragment
  | BasicInformation_QualifiedTuitionPersonalAccountFragment
  | BasicInformation_InsurancePersonalAccountFragment
  | BasicInformation_RetirementPersonalAccountFragment;

function getSharedPersonalAccountProperties(
  subtype: BasicInformationPersonalAccountSubtype,
  formData: Partial<BasicInformationSubformType>
) {
  return {
    legalName: subtype.legalName ?? '',
    displayName: subtype.displayName ?? '',
    effectiveDate: subtype.effectiveDate ?? null,
    description: subtype.description ?? '',
    subtypeId: subtype.id,

    // these aren't applicable to accounts, but need to be returned to satisfy the form type
    jurisdiction: '',
    doingBusinessAsName: '',
    requiresCtaReporting: false,
    finCenID: '',
    termLength: '',
    termLengthNumber: '',
    rate7520: null,
    isCompleted: false,
    taxableGift: null,
    ...formData,
  };
}

function getSharedNonTrustCharitableEntityProperties(
  subtype:
    | BasicInformation_DafFragment
    | BasicInformation_PrivateFoundationFragment,
  formData: Partial<BasicInformationSubformType>
) {
  return {
    legalName: subtype.legalName ?? '',
    displayName: subtype.displayName ?? '',
    effectiveDate: subtype.effectiveDate ?? null,
    description: subtype.description ?? '',
    subtypeId: subtype.id,

    // these aren't applicable to foundations, but need to be returned to satisfy the form type
    jurisdiction: '',
    doingBusinessAsName: '',
    requiresCtaReporting: false,
    finCenID: '',
    termLength: '',
    termLengthNumber: '',
    rate7520: null,
    isCompleted: false,
    taxableGift: null,
    ...formData,
  };
}

function dataToForm(
  data: GetBasicInformationQuery
):
  | BasicInformationSubformType
  | BasicInformationSubformType_WithGrantors
  | BasicInformationSubformType_SingleGrantor {
  if (data.node?.__typename !== 'Entity') {
    throw new Error('Unexpected node type');
  }

  const { subtype } = data.node;
  const docs = getNodes(data.node.documents);

  const documentIds = docs.map((d) => d.id);

  const termLength = '';
  const termLengthNumber = '';

  const entityStage = data.node.stage;

  const sharedFormValues: Partial<BasicInformationSubformType> = {
    stage: entityStage,
  };

  switch (subtype.__typename) {
    // Charitable non-trust entities
    case 'DonorAdvisedFund':
    case 'PrivateFoundation':
      return {
        ...getSharedNonTrustCharitableEntityProperties(
          subtype,
          sharedFormValues
        ),
        documentIds,
        ...getNonGrantorPrincipalFieldsDataToForm(data),
      };
    // Personal account entities
    case 'CustodialPersonalAccount':
    case 'QualifiedTuitionPersonalAccount':
    case 'InsurancePersonalAccount':
    case 'IndividualPersonalAccount':
      return {
        documentIds,
        ...getSharedPersonalAccountProperties(subtype, sharedFormValues),
        ...getNonGrantorPrincipalFieldsDataToForm(data),
      };
    case 'JointPersonalAccount':
      return {
        documentIds,
        jointAccountType: subtype.jointAccountType ?? '',
        ...getSharedPersonalAccountProperties(subtype, sharedFormValues),
        ...getNonGrantorPrincipalFieldsDataToForm(data),
      };
    case 'RetirementPersonalAccount':
      return {
        documentIds,
        retirementAccountType: subtype.retirementAccountType ?? '',
        ...getSharedPersonalAccountProperties(subtype, sharedFormValues),
        ...getNonGrantorPrincipalFieldsDataToForm(data),
      };
    // Trust entities
    case 'GRATTrust':
      return {
        ...getSharedTrustProperties(subtype, sharedFormValues),
        termLength: subtype.termDurationYears?.toString() ?? '',
        taxableGift: subtype.taxableGift ?? null,
        rate7520: subtype.officialInterestRatePercent ?? null,
        initialFundingValue: subtype.initialFundingValue ?? null,
        documentIds,
        termLengthNumber,
      };
    case 'QPRTTrust':
      return {
        ...getSharedTrustProperties(subtype, sharedFormValues),
        termLengthNumber: subtype.termDurationYears?.toString() ?? '',
        taxableGift: subtype.taxableGift ?? null,
        rate7520: subtype.officialInterestRatePercent ?? null,
        documentIds,
        termLength,
        isCompleted: entityStage === EntityStage.Completed,
      };
    case 'ILITTrust':
    case 'RevocableTrust':
    case 'IrrevocableTrust':
    case 'SLATTrust':
      return {
        ...getSharedTrustProperties(subtype, sharedFormValues),
        documentIds,
        termLength,
        termLengthNumber,
      };
    case 'CLTTrust':
    case 'CRTTrust':
      return {
        ...getSharedTrustProperties(subtype, sharedFormValues),
        termLengthNumber: subtype.termDurationYears?.toString() ?? '',
        charitableDeduction: subtype.charitableDeduction ?? null,
        rate7520: subtype.officialInterestRatePercent ?? null,
        documentIds,
        termLength,
        isCompleted: entityStage === EntityStage.Completed,
        isLifetime: subtype.termDurationLifetime,
      };

    // Business entities
    case 'SoleProprietorshipBusinessEntity':
    case 'CCorpBusinessEntity':
    case 'SCorpBusinessEntity':
    case 'LLCBusinessEntity':
    case 'LPBusinessEntity':
    case 'GPBusinessEntity':
      return {
        ...defaultValues,
        ...getBusinessEntityProperties(subtype, {
          stage: entityStage,
        }),
        documentIds,
      };

    default:
      throw new UnreachableError({
        case: subtype.__typename as NonNullable<typeof subtype.__typename>,
        message: `Unexpected subtype ${subtype.__typename}}`,
      });
  }
}

export async function basicInformationDataFetcher(
  apolloClient: ApolloClient<object>,
  nodeId: string
): Promise<BasicInformationSubformType> {
  const { data, error } = await apolloClient.query<GetBasicInformationQuery>({
    query: GetBasicInformationDocument,
    variables: {
      nodeId: nodeId,
    },
  });

  if (error) {
    diagnostics.error(
      'Could not fetch data for basic information subform',
      error
    );

    throw error;
  }

  return dataToForm(data);
}

export function getBasicInformationVariant(
  type: EntityType,
  stage: EntityFormStage
): VariantType {
  switch (type) {
    case 'grat':
      switch (stage) {
        case EntityFormStage.GRAT_IMPLEMENTATION_EDIT:
          return 'GRATImplementationEdit';
        case EntityFormStage.GRAT_IMPLEMENTATION_CONFIRM:
          return 'GRATImplementationConfirm';
        default:
          return 'GRATActive';
      }

    case 'qprt':
      return 'QPRT';

    case 'clt':
      return 'CLT';

    case 'crt':
      return 'CRT';

    case 'joint-account':
      return 'JointAccount';

    case 'retirement-account':
      return 'RetirementAccount';

    case 'custodial-account':
    case 'individual-account':
    case 'qualified-tuition-account':
      return 'Account';

    case 'insurance-account':
      return 'InsuranceAccount';

    case 'daf':
    case 'private-foundation':
      return 'NonTrustCharitableEntity';

    case 'llc':
    case 'lp':
    case 'gp':
    case 'sole-proprietorship':
    case 'c-corp':
    case 's-corp':
      return 'BusinessEntity';

    default:
      return 'default';
  }
}
