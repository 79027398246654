// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserHasCreatedHouseholdsQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type UserHasCreatedHouseholdsQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string } | null } | null> | null } };


export const UserHasCreatedHouseholdsDocument = gql`
    query UserHasCreatedHouseholds($userId: ID!) {
  households(
    where: {createdBy: $userId}
    first: 1
    orderBy: {field: created_at, direction: DESC}
  ) {
    edges {
      node {
        id
      }
    }
  }
}
    `;

/**
 * __useUserHasCreatedHouseholdsQuery__
 *
 * To run a query within a React component, call `useUserHasCreatedHouseholdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasCreatedHouseholdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasCreatedHouseholdsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserHasCreatedHouseholdsQuery(baseOptions: Apollo.QueryHookOptions<UserHasCreatedHouseholdsQuery, UserHasCreatedHouseholdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserHasCreatedHouseholdsQuery, UserHasCreatedHouseholdsQueryVariables>(UserHasCreatedHouseholdsDocument, options);
      }
export function useUserHasCreatedHouseholdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserHasCreatedHouseholdsQuery, UserHasCreatedHouseholdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserHasCreatedHouseholdsQuery, UserHasCreatedHouseholdsQueryVariables>(UserHasCreatedHouseholdsDocument, options);
        }
export function useUserHasCreatedHouseholdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserHasCreatedHouseholdsQuery, UserHasCreatedHouseholdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserHasCreatedHouseholdsQuery, UserHasCreatedHouseholdsQueryVariables>(UserHasCreatedHouseholdsDocument, options);
        }
export type UserHasCreatedHouseholdsQueryHookResult = ReturnType<typeof useUserHasCreatedHouseholdsQuery>;
export type UserHasCreatedHouseholdsLazyQueryHookResult = ReturnType<typeof useUserHasCreatedHouseholdsLazyQuery>;
export type UserHasCreatedHouseholdsSuspenseQueryHookResult = ReturnType<typeof useUserHasCreatedHouseholdsSuspenseQuery>;
export type UserHasCreatedHouseholdsQueryResult = Apollo.QueryResult<UserHasCreatedHouseholdsQuery, UserHasCreatedHouseholdsQueryVariables>;
export const Operations = {
  Query: {
    UserHasCreatedHouseholds: 'UserHasCreatedHouseholds' as const
  }
}