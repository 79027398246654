import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { GRANTOR_RELATIONSHIP_COPY_MAP } from '@/components/form/PersonFormFragment/constants';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { useFormContext } from '@/components/react-hook-form';
import { StyledTableCell } from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { PathsOf } from '@/types/subform';

import {
  ILITAnnualExclusionBeneficiary,
  ILITMakeAnnualExclusionGiftsForm,
} from '../ILITMakeAnnualExclusionGiftsTask.types';

interface ILITMakeAnnualGrantorGiftBeneficiaryRowProps {
  beneficiary: ILITAnnualExclusionBeneficiary;
  grantorId: string;
}

const getFieldNameGenerator = (grantorId: string, beneficiaryId: string) => {
  return <T extends keyof ILITAnnualExclusionBeneficiary>(
    fieldName: T
  ): `grantors.${string}.beneficiariesById.${string}.${T}` => {
    return `grantors.${grantorId}.beneficiariesById.${beneficiaryId}.${fieldName}` satisfies PathsOf<ILITMakeAnnualExclusionGiftsForm>;
  };
};

export function ILITMakeAnnualGrantorGiftBeneficiaryRow({
  beneficiary,
  grantorId,
}: ILITMakeAnnualGrantorGiftBeneficiaryRowProps) {
  const { control } = useFormContext<ILITMakeAnnualExclusionGiftsForm>();
  const getFieldName = getFieldNameGenerator(grantorId, beneficiary.id);
  const giftedAmountPath = getFieldName('giftedAmount');

  const giftedAmount = useWatch({
    name: giftedAmountPath,
    control,
  });

  const shouldValidateFields = useMemo<boolean>(
    () => giftedAmount?.isPositive() ?? false,
    [giftedAmount]
  );
  return (
    <StyledTableRow>
      <StyledTableCell>
        <RichListItem
          heading={beneficiary.displayName}
          description={
            beneficiary.relationToGrantor
              ? GRANTOR_RELATIONSHIP_COPY_MAP[beneficiary.relationToGrantor]
              : ''
          }
        />
      </StyledTableCell>
      <StyledTableCell>
        <FormAwareCurrencyInput<ILITMakeAnnualExclusionGiftsForm>
          isDecimalJSInput
          control={control}
          label="Gifted amount"
          hideLabel
          fieldName={giftedAmountPath}
          decimalScale={0}
        />
      </StyledTableCell>
      <StyledTableCell>
        <FormAwareCurrencyInput<ILITMakeAnnualExclusionGiftsForm>
          isDecimalJSInput
          control={control}
          label="Annual exclusion used"
          hideLabel
          fieldName={getFieldName('annualExclusionUsed')}
          decimalScale={0}
          required={shouldValidateFields}
        />
      </StyledTableCell>
      <StyledTableCell>
        <FormAwareCurrencyInput<ILITMakeAnnualExclusionGiftsForm>
          isDecimalJSInput
          control={control}
          label="Lifetime exemption used"
          hideLabel
          fieldName={getFieldName('lifetimeExemptionUsed')}
          decimalScale={0}
          required={shouldValidateFields}
        />
      </StyledTableCell>
      <StyledTableCell>
        <FormAwareCurrencyInput<ILITMakeAnnualExclusionGiftsForm>
          isDecimalJSInput
          control={control}
          label="GST exemption used"
          hideLabel
          fieldName={getFieldName('gstExemptionUsed')}
          decimalScale={0}
          required={shouldValidateFields}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
}
