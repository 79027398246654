import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { FC } from 'react';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { SubtotalCard } from '@/components/display/SubtotalCard/SubtotalCard';
import { CoinsHandIcon } from '@/components/icons/CoinsHandIcon';
import { PopperContent } from '@/components/poppers/PopperContent';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { ESTATE_TAX_RATE_FRIENDLY } from '@/modules/entities/entities.constants';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

export const IMPLIED_TAX_HELP_COPY = `Implied estate tax savings assumes assets would otherwise be taxable at a ${ESTATE_TAX_RATE_FRIENDLY} federal estate tax rate. Does not account for state transfer taxes.`;

export const ImpliedEstateTaxSavingsCallout: FC<{
  impliedEstateTaxSavings: Decimal;
}> = ({ impliedEstateTaxSavings }) => (
  <SubtotalCard
    wrapperSx={{
      flex: '1 1 50%',
    }}
    icon={<CoinsHandIcon />}
    iconColor={COLORS.CATEGORIES.ESTATE_TAX_SAVINGS[500]}
    caption={
      <OversizedMetricItem
        title={
          <Stack direction="row" flexWrap="nowrap">
            Implied estate tax savings
            <ContextualHelpTooltip>
              <Typography>
                <PopperContent body={IMPLIED_TAX_HELP_COPY} />
              </Typography>
            </ContextualHelpTooltip>
          </Stack>
        }
        value={formatCurrencyNoDecimals(impliedEstateTaxSavings)}
      />
    }
  />
);
