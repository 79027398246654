import { styled } from '@mui/material';
import { GridApiPro, GridGroupNode, GridRowParams } from '@mui/x-data-grid-pro';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Draghandle } from '@/components/lists/DragAndDropList/Draghandle';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { defaultGroupColDef } from '@/components/tables/DataTable/hooks/internal/useDefaultProps';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { COLORS } from '@/styles/tokens/colors';
import { BalanceSheetRowType } from '@/types/schema';

import {
  useBalanceSheetTableColumnGroups,
  useBalanceSheetTableColumns,
} from './BalanceSheetTable.columns';
import {
  BALANCE_SHEET_TABLE_CLASS_NAME,
  RowData,
} from './BalanceSheetTable.constants';
import { mapDataToRows } from './BalanceSheetTable.utils';
import { BalanceSheetTableLoadingOverlay } from './BalanceSheetTableLoadingOverlay';
import { BalanceSheetTable_BalanceSheetFragment } from './graphql/BalanceSheetTable.fragments.generated';

const getTreeDataPath = (row: RowData) => row.path;

const useRowStyles = makeStyles()((theme) => ({
  netWorthRowStyle: {
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  totalRowStyle: {
    backgroundColor: `${COLORS.GRAY[100]} !important`,
  },
}));

export interface BalanceSheetTableProps {
  balanceSheet: BalanceSheetTable_BalanceSheetFragment | null;
  loading: boolean;
  // gridAPIRef is optional because it's only needed for export functionality and row expansion
  gridAPIRef?: React.MutableRefObject<GridApiPro>;
  hideExpansionCarets?: boolean;
}

// Custom styles for grouped headers
const StyledDataTable = styled(DataTable)(({ theme }) => ({
  '& .MuiDataGrid-columnHeader--filledGroup': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-columnHeader--emptyGroup': {
    backgroundColor: 'white', // transparent doesn't work
  },
  '& .MuiDataGrid-columnHeaders': {
    borderTopRightRadius: 0,
  },
  '& .MuiDataGrid-columnHeaderTitleContainerContent': {
    // take up the whole width so the resize handles are on the far-left
    // side of the header cell
    width: '100%',
  },
}));

type ExpansionState = Record<string, boolean>;

function BalanceSheetTableContent({
  balanceSheet,
  loading,
  gridAPIRef,
}: BalanceSheetTableProps) {
  const isViewOnly = useViewOnly();
  const { classes } = useRowStyles();
  const columns = useBalanceSheetTableColumns(balanceSheet, gridAPIRef);
  const columnGroups = useBalanceSheetTableColumnGroups(balanceSheet);

  const expansionState = useRef<ExpansionState>({});
  const currentRows = useMemo<RowData[]>(
    () => mapDataToRows(balanceSheet, { isViewOnly }),
    [balanceSheet, isViewOnly]
  );
  // use updateRows to update the rows and retain tree data expansion state; simply updating the
  // rows prop destroys the row expansion state
  // https://github.com/mui/mui-x/issues/7771
  const initialRowsRef = useRef(currentRows);
  useEffect(() => {
    if (!gridAPIRef) return;
    if (currentRows !== initialRowsRef.current) {
      gridAPIRef.current.setRows(currentRows);
    }
  }, [balanceSheet, currentRows, gridAPIRef, isViewOnly]);

  const handleRowClick = (params: GridRowParams<RowData>) => {
    if (!gridAPIRef) return;
    const isExpanded = expansionState.current[params.row.id];

    // can only expand/collapse rows with children
    if (!params.row.hasChildren) {
      return;
    }
    gridAPIRef.current?.setRowChildrenExpansion(params.row.id, !isExpanded);
    expansionState.current[params.row.id] = !isExpanded;
  };

  useEffect(() => {
    if (!gridAPIRef) return;

    // also support rowExpansionChange events, which will happen when the user clicks on the button
    // directly rather than through the row click handler
    gridAPIRef.current.subscribeEvent('rowExpansionChange', (node) => {
      expansionState.current[node.id] = node.childrenExpanded ?? false;
    });
  }, [gridAPIRef]);

  const isGroupExpanded = useCallback(
    (node: GridGroupNode) => expansionState.current[node.id] ?? false,
    []
  );

  return (
    <>
      <StyledDataTable
        hideFooterPagination
        className={BALANCE_SHEET_TABLE_CLASS_NAME}
        treeData={!isViewOnly}
        columns={columns}
        rows={initialRowsRef.current}
        // disable virtualization because there will never be a large number of rows or columns,
        // and virtualization sometimes causes the header to lag behind the rows when scrolling
        // horizontally. see: https://github.com/mui/mui-x/issues/5433
        disableVirtualization
        loading={loading}
        apiRef={gridAPIRef}
        disableRowSelectionOnClick
        getTreeDataPath={getTreeDataPath}
        isGroupExpandedByDefault={isGroupExpanded}
        pagination={false}
        initialState={{}}
        getRowHeight={() => 60}
        columnGroupingModel={columnGroups}
        hideHeaderSeparator
        groupingColDef={defaultGroupColDef}
        experimentalFeatures={{ columnGrouping: true }}
        getRowClassName={({ row }) => {
          const typedRow = row as RowData;
          if (typedRow.variant === BalanceSheetRowType.NetWorth) {
            return classes.netWorthRowStyle;
          }
          if (typedRow.variant === BalanceSheetRowType.Total) {
            return classes.totalRowStyle;
          }

          // doesn't allow returning null or undefined
          return '';
        }}
        slots={{
          loadingOverlay: BalanceSheetTableLoadingOverlay,
          columnReorderIcon: Draghandle,
        }}
        onRowClick={handleRowClick}
      />
    </>
  );
}

export function BalanceSheetTable(props: BalanceSheetTableProps) {
  return <BalanceSheetTableContent {...props} />;
}
