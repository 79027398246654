import { Stack, Typography } from '@mui/material';

import { LuminaryAiBetaLogo } from '@/modules/documents/components/LuminaryAiLogo';
import { COLORS } from '@/styles/tokens/colors';

export const SUGGESTIONS_ENABLED_HEADER =
  'Upload documents for Luminary AI to suggest entity details';
export const SUGGESTIONS_DISABLED_HEADER =
  'Upload a document to summarize and search';

export const SUGGESTIONS_ENABLED_SUBHEADER =
  'We’ll also provide a summary and enable you to search the document. After uploading, confirm the document types are correct for each file.';
export const SUGGESTIONS_DISABLED_SUBHEADER =
  'Luminary AI will provide a detailed summary for this document and enable you to more easily find information with document search.';

interface LuminaryAIDocumentUploaderTextProps {
  headerText: string;
  subheaderText: string;
}

export function LuminaryAIDocumentUploaderText({
  headerText,
  subheaderText,
}: LuminaryAIDocumentUploaderTextProps) {
  return (
    <Stack direction="row" gap={2} alignItems="center">
      <LuminaryAiBetaLogo />
      <Stack direction="column">
        <Typography variant="body1" fontWeight={600} color={COLORS.NAVY[600]}>
          {headerText}
        </Typography>
        <Typography variant="subtitle2" color={COLORS.GRAY[500]}>
          {subheaderText}
        </Typography>
      </Stack>
    </Stack>
  );
}
