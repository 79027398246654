import { FamilyTreeEffectsMap } from '../types';
import { useCompleteInitialiationEffect } from './completeInitialization.effect';
import { useFitViewEffect } from './fitView.effect';
import { useQueryFamilyTreeEffect } from './queryFamilyTree.effect';

export function useEffectsMap(): FamilyTreeEffectsMap {
  const completeInitializationEffect = useCompleteInitialiationEffect();
  const queryFamilyTreeEffect = useQueryFamilyTreeEffect();
  const fitViewEffect = useFitViewEffect();

  return {
    completeInitializationEffect,
    fitViewEffect,
    queryFamilyTreeEffect,
  };
}
