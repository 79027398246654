import { PopperContent } from '@/components/poppers/PopperContent';

export function AnnualExclusionLifetime() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body={
        "This is the total amount of annual exclusion gifts made during a client's lifetime."
      }
    />
  );
}
