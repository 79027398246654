import { GratDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { EntityStage } from '@/types/schema';

import { ENTITY_TYPES } from '../entities.constants';
import { NavigationEntityFragment } from '../graphql/Entities.generated';
import { EntityType } from '../types/EntityType';
import { getEntityTypeFromSubtype } from './getEntityTypeFromSubtype';

/**
 * Draft GRATs go to structuring designer
 * Draft non-GRAT entities go to details page
 * Non-hypothetical entities go to the appropriate page based on the stage
 * Active entities go to details page
 */
export function getStageAwareLinkForEntity(
  entity: NavigationEntityFragment,
  isHypotheticalEntity = false
) {
  if (entity.subtype.__typename === 'GRATTrust') {
    if (isHypotheticalEntity) {
      return getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER, {
        entityId: entity.id,
        entityType: getEntityTypeFromSubtype(entity.subtype.__typename),
        householdId: entity.household.id,
        designerStage: GratDesignerStages.STRUCTURING,
      });
    } else if (
      entity.stage === EntityStage.Draft ||
      entity.stage === EntityStage.ReadyForProposal
    ) {
      return getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER, {
        entityId: entity.id,
        entityType: getEntityTypeFromSubtype(entity.subtype.__typename),
        householdId: entity.household.id,
        designerStage: GratDesignerStages.STRUCTURING,
      });
    } else if (entity.stage === EntityStage.Implementation) {
      return getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_ENTITY_IMPLEMENTATION,
        {
          entityId: entity.id,
          householdId: entity.household.id,
        }
      );
    }
  }

  return getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS, {
    entityId: entity.id,
    householdId: entity.household.id,
  });
}

/**
 * Non-GRAT entities go to details page
 * GRAT entities go to the appropriate page based on the stage
 */
export function getEntityNavigationPath(
  householdId: string,
  entityId: string,
  entityType: EntityType,
  entityStage: EntityStage
): string {
  if (entityType !== 'grat') {
    // Currently, only GRATs have special navigation requirements.
    // All other cases go to the entity details page.
    return getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS, {
      householdId,
      entityId,
    });
  }

  // For GRATs, we need to navigate to the appropriate page based on the stage.
  switch (entityStage) {
    case EntityStage.Draft:
    case EntityStage.ReadyForProposal:
      return getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER, {
        householdId,
        designerStage: GratDesignerStages.STRUCTURING,
        entityId,
        entityType: ENTITY_TYPES.GRAT,
      });
    case EntityStage.Implementation:
      return getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_ENTITY_IMPLEMENTATION,
        {
          householdId,
          entityId,
        }
      );
    default:
    case EntityStage.Active:
      return getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS, {
        householdId,
        entityId,
      });
  }
}
