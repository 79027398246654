import Decimal from 'decimal.js';
import { compact, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { businessEntityKinds } from '@/modules/entities/BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.constants';
import { GraphNodeCategorizationType } from '@/modules/estateWaterfall/types';
import { kindToCategorizationType } from '@/modules/estateWaterfall/waterfallGraph/constants';
import {
  EntityStage,
  EstateWaterfallHypotheticalTransferDestinationKind,
} from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { diagnostics } from '@/utils/diagnostics';
import { formatEnumCase } from '@/utils/formatting/strings';
import { getNodes } from '@/utils/graphqlUtils';

import {
  apiOwnershipStakeToContextStake,
  apiPolicyToContextPolicy,
  HypotheticalTransferModalContextShape,
  useHypotheticalTransferModalContext,
} from '../../../HypotheticalTransferModal/HypotheticalTransferModal.context';
import { useGetTransferDestinationOptionsV2Query } from '../graphql/GetTransferDestinationOptions.generated';
import {
  HypotheticalTransferFormShape,
  TransferDirection,
} from '../HypotheticalTransferForm';
import {
  EXTERNAL_ENTITY_SENTINEL,
  getExternalTransferCopy,
} from '../hypotheticalTransfers.utils';

const formInitErrorMessage =
  "We weren't able to initialize this form. Please refresh the page and try again.";

type TransferDestinationOption = (
  | {
      display: string;
      value: string;
      kind: EstateWaterfallHypotheticalTransferDestinationKind | '';
    }
  | {
      component: JSX.Element;
      type: 'component';
      kind: EstateWaterfallHypotheticalTransferDestinationKind | '';
    }
)[];

type PossibleDestinations = {
  id: string;
  displayName: string;
  kind: GraphNodeCategorizationType;
  currentValue: Decimal;
  extendedDisplayKind: string;
  isDraft?: boolean;
}[];

export function useTransferDestinationOptions(
  householdId: string,
  direction: TransferDirection = TransferDirection.Outbound
) {
  const { showFeedback } = useFeedback();
  const { control } = useFormContext<HypotheticalTransferFormShape>();

  const { setDerivedData } = useHypotheticalTransferModalContext();

  const [typeOptionsV2, setTypeOptionsV2] = useState<TransferDestinationOption>(
    []
  );
  const [possibleDestinationsV2, setPossibleDestinationsV2] =
    useState<PossibleDestinations>([]);

  const transferDirection =
    useWatch({
      control,
      name: 'transferDirection',
    }) || direction;

  const destinationOptionsOpts = useMemo(() => {
    if (transferDirection === TransferDirection.Inbound) {
      return {
        includeKinds: [
          EstateWaterfallHypotheticalTransferDestinationKind.Entity,
          EstateWaterfallHypotheticalTransferDestinationKind.External,
          EstateWaterfallHypotheticalTransferDestinationKind.Individual,
          '' as const,
        ],
      };
    }

    return undefined;
  }, [transferDirection]);

  const queryMethodsV2 = useGetTransferDestinationOptionsV2Query({
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        hasHouseholdWith: [
          {
            id: householdId,
          },
        ],
      },
    },
    onError: (error) => {
      showFeedback(formInitErrorMessage);
      diagnostics.error('failed to load transfer destination options', error, {
        householdId,
      });
    },
  });

  const { data: dataV2 } = queryMethodsV2;

  useEffect(
    function onData() {
      if (!dataV2) {
        return;
      }

      const ownershipStakesByOwnerId: HypotheticalTransferModalContextShape['derivedData']['ownershipStakesByOwnerId'] =
        {};
      const policiesByEntityId: HypotheticalTransferModalContextShape['derivedData']['policiesByEntityId'] =
        {};
      const discountRateByEntityId: HypotheticalTransferModalContextShape['derivedData']['discountRateByEntityId'] =
        {};
      const ilitValueByEntityId: HypotheticalTransferModalContextShape['derivedData']['ilitValueByEntityId'] =
        {};

      const possibleEntities = getNodes(
        dataV2.possibleHypotheticalTransferEntities
      );

      const household = possibleEntities[0]?.household;
      const possibleClientOptions =
        uniqBy(
          [
            ...(household?.possiblePrimaryClients ?? []),
            ...(household?.possibleBeneficiariesV2.clients ?? []),
          ],
          'id'
        ).map((cp) => {
          if (cp.ownedOwnershipStakes?.length) {
            ownershipStakesByOwnerId[cp.id] = cp.ownedOwnershipStakes.map(
              apiOwnershipStakeToContextStake
            );
          }

          return {
            display: cp.displayName,
            value: cp.id,
            kind: EstateWaterfallHypotheticalTransferDestinationKind.Individual,
          };
        }) ?? [];

      const possibleEntityOptions = compact(
        possibleEntities.map((e) => {
          if (!e.subtype.__typename) {
            return null;
          }

          if (e.stage === EntityStage.Draft) {
            return null;
          }

          // We don't want to show business entities as options because
          // we currently only support transferring to entities
          // explicitly in the waterfall. Business entities are
          // represented in the waterfall as an individual, so it becomes
          // quite complicated.
          // https://withluminary.slack.com/archives/C064KAQRA3B/p1704835308837909
          if (businessEntityKinds.has(e.kind)) {
            return null;
          }

          if (e.ownedOwnershipStakes?.length) {
            ownershipStakesByOwnerId[e.id] = e.ownedOwnershipStakes.map(
              apiOwnershipStakeToContextStake
            );
          }

          if ('policies' in e.subtype && e.subtype.policies) {
            policiesByEntityId[e.id] = e.subtype.policies.flatMap(
              apiPolicyToContextPolicy
            );
          }

          if (
            e.subtype.__typename === 'ILITTrust' &&
            e.subtype.mostRecentValuation?.valuationValue
          ) {
            ilitValueByEntityId[e.id] =
              e.subtype.mostRecentValuation?.valuationValue;
          }

          if (e.subtype.taxDiscount) {
            discountRateByEntityId[e.id] = e.subtype.taxDiscount;
          }

          return {
            display: e.subtype.displayName,
            value: e.id,
            kind: EstateWaterfallHypotheticalTransferDestinationKind.Entity,
          };
        })
      );

      const possibleHypotheticalEntityOptions = compact(
        possibleEntities.map((e) => {
          if (!e.subtype.__typename) {
            return null;
          }

          if (e.stage !== EntityStage.Draft) {
            return null;
          }

          if (e.ownedOwnershipStakes?.length) {
            ownershipStakesByOwnerId[e.id] = e.ownedOwnershipStakes.map(
              apiOwnershipStakeToContextStake
            );
          }

          if (e.subtype.taxDiscount) {
            discountRateByEntityId[e.id] = e.subtype.taxDiscount;
          }

          return {
            display: e.subtype.displayName,
            value: e.id,
            kind: EstateWaterfallHypotheticalTransferDestinationKind.Entity,
          };
        })
      );

      const possibleOrganizationOptions =
        household?.possibleBeneficiariesV2.organizations.map((o) => {
          return {
            display: o.name,
            value: o.id,
            kind: EstateWaterfallHypotheticalTransferDestinationKind.Organization,
          };
        }) ?? [];

      const selectOptions = compact([
        {
          display: EMPTY_CONTENT_HYPHEN,
          value: '',
          kind: '' as const,
        },
        (() =>
          possibleClientOptions.length
            ? {
                component: <SelectItemGroupLabel label="Individuals" />,
                type: 'component' as const,
                kind: EstateWaterfallHypotheticalTransferDestinationKind.Individual,
              }
            : null)(),

        ...possibleClientOptions.sort((a, b) =>
          a.display.localeCompare(b.display)
        ),
        (() =>
          possibleOrganizationOptions.length
            ? {
                component: <SelectItemGroupLabel label="Organizations" />,
                type: 'component' as const,
                kind: EstateWaterfallHypotheticalTransferDestinationKind.Organization,
              }
            : null)(),

        ...possibleOrganizationOptions.sort((a, b) =>
          a.display.localeCompare(b.display)
        ),
        (() =>
          possibleEntityOptions.length
            ? {
                component: <SelectItemGroupLabel label="Existing Entities" />,
                type: 'component' as const,
                kind: EstateWaterfallHypotheticalTransferDestinationKind.Entity,
              }
            : null)(),

        ...possibleEntityOptions.sort((a, b) =>
          a.display.localeCompare(b.display)
        ),
        (() =>
          possibleHypotheticalEntityOptions.length
            ? {
                component: <SelectItemGroupLabel label="Draft Entities" />,
                type: 'component' as const,
                kind: EstateWaterfallHypotheticalTransferDestinationKind.Entity,
              }
            : null)(),

        ...possibleHypotheticalEntityOptions.sort((a, b) =>
          a.display.localeCompare(b.display)
        ),
        {
          component: <SelectItemGroupLabel label="External" />,
          type: 'component' as const,
          kind: EstateWaterfallHypotheticalTransferDestinationKind.External,
        },
        {
          display: getExternalTransferCopy(transferDirection),
          value: EXTERNAL_ENTITY_SENTINEL,
          kind: EstateWaterfallHypotheticalTransferDestinationKind.External,
        },
      ]);

      // This is used to fill the dropdown card in the transfer form
      // with the correct data for the selected item.
      const possibleDestinations = uniqBy(
        [
          ...(household?.possiblePrimaryClients.map((c) => ({
            id: c.id,
            displayName: c.displayName,
            kind: GraphNodeCategorizationType.Individual,
            currentValue: sumDecimalJS(
              c.ownedOwnershipStakes?.map((s) => s.ownedValue) ?? [
                new Decimal(0),
              ]
            ),
            extendedDisplayKind: 'Individual',
          })) ?? []),
          ...(household?.possibleBeneficiariesV2.clients.map((p) => ({
            id: p.id,
            displayName: p.displayName,
            kind: GraphNodeCategorizationType.Individual,
            currentValue: sumDecimalJS(
              p.ownedOwnershipStakes?.map((s) => s.ownedValue) ?? [
                new Decimal(0),
              ]
            ),
            extendedDisplayKind: 'Individual',
          })) ?? []),
          ...possibleEntities.map((e) => ({
            id: e.id,
            displayName: e.subtype.displayName,
            kind:
              kindToCategorizationType[e.kind] ??
              GraphNodeCategorizationType.FamilyGiving,
            currentValue: e.subtype.currentValue,
            extendedDisplayKind: e.extendedDisplayKind,
            isDraft: e.stage === EntityStage.Draft,
          })),
          ...(household?.possibleBeneficiariesV2.organizations.map((p) => ({
            id: p.id,
            displayName: p.name,
            kind: GraphNodeCategorizationType.CharitableEntity,
            currentValue: new Decimal(0),
            extendedDisplayKind: formatEnumCase(p.kind),
          })) ?? []),
          {
            id: EXTERNAL_ENTITY_SENTINEL,
            displayName: getExternalTransferCopy(transferDirection),
            kind: GraphNodeCategorizationType.PersonalAccount,
            currentValue: new Decimal(0),
            extendedDisplayKind: EMPTY_CONTENT_HYPHEN,
          },
        ],
        'id'
      );

      setDerivedData({
        ownershipStakesByOwnerId,
        policiesByEntityId,
        discountRateByEntityId,
        ilitValueByEntityId: ilitValueByEntityId,
      });
      setPossibleDestinationsV2(possibleDestinations);
      setTypeOptionsV2(selectOptions);
    },
    [dataV2, householdId, setDerivedData, showFeedback, transferDirection]
  );

  const filteredTypeOptionsV2 = typeOptionsV2.filter((item) => {
    // If we have a list of kinds to include, filter out any items
    // that are not in that list.
    if (destinationOptionsOpts?.includeKinds) {
      if (destinationOptionsOpts.includeKinds.includes(item.kind)) {
        return true;
      }
      return false;
    }

    return true;
  });

  return {
    typeOptions: filteredTypeOptionsV2,
    possibleDestinations: possibleDestinationsV2,

    ...queryMethodsV2,
  };
}
