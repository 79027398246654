import { Box } from '@mui/material';

import { useFederalEstateTaxFormatted } from '@/modules/irs/useIRSConstants';

export function DefaultLuminaryDisclaimer() {
  const rate = useFederalEstateTaxFormatted();
  return (
    <>
      <section>
        <p>
          The estate tax calculations and assumptions made in this web-based
          platform are based on our current understanding of estate tax laws and
          regulations. These calculations and assumptions are subject to change
          as tax laws, regulations, rates, and other factors are updated over
          time. The latest assumptions and calculations used by the platform are
          always available and accessible within the application itself. The
          information provided here and in the platform should not be construed
          as legal or tax advice.
        </p>

        <p>
          Grantor Retained Annuity Trust (GRAT) Proposals: The material
          presented is designed to provide illustrative information about ideas
          and strategies and does not constitute tax or legal advice. It is for
          discussion purposes only, and the availability and effectiveness of
          any strategies are dependent upon your individual facts and
          circumstances. Always consult with your independent attorney, tax
          advisor, investment manager, and insurance agent for final
          recommendations before changing or implementing any financial, tax, or
          investment strategy.
        </p>

        <p>
          The only assets included in this analysis are assets used to fund the
          GRAT strategy analyzed. This analysis does not include any other
          assets held by the grantor.
        </p>
        <p>
          The analysis above is for illustrative purposes only and is not a
          guarantee of future strategy performance. The analysis assumes that
          all assets remaining in the grantor&apos;s estate at the end of the
          time period listed above will be taxed at a federal estate tax rate of
          {rate}% and that assets moved out of the grantor&apos;s estate are
          passed to heirs free of estate tax. Projections do not account for any
          remaining gift tax exclusion, or state inheritance or estate taxes.
          The analysis also assumes straight-line asset growth based on the
          return projections specified above.
        </p>

        <p>
          Estate waterfall: The estate waterfall is for illustrative purposes
          only and does not constitute tax or legal advice. Assumes that assets
          transferred to non-charitable beneficiaries, in excess of the
          grantor&apos;s remaining lifetime exemption, will be taxed at a
          federal estate tax rate of {rate}%. Assumes assets held outside of the
          grantor&apos;s estate are not subject to estate tax. This does not
          account for any potential state-specific transfer tax.
        </p>

        <p>
          Exemption sunsets: Exemptions increase annually at the specified
          exemption growth rate, and are set to sunset, or be reduced to half of
          the current exemption amount, at the end of 2025.
        </p>

        <p>
          Estate tax calculations (pro rata): Estimated estate taxes due are
          automatically deducted from pro rata from each entity subject to
          estate tax
        </p>

        <p>
          Estate tax calculations (custom selection): Estate taxes will be paid
          in the order specified
        </p>
      </section>
      <Box sx={{ breakBefore: 'column' }} />
      <section>
        <p>Value creation reporting</p>
        <ol>
          <li>
            Implied wealth transferred: Implied wealth transferred is the sum
            of:
            <br />
            <ul>
              <li>
                The total value transferred out of the grantor&apos;s estate at
                the term end of all completed strategies
              </li>
              <li>
                The total value projected to be transferred out of the estate by
                all active strategies
              </li>
            </ul>
          </li>

          <li>
            Implied estate tax savings: Implied estate tax savings assumes the
            amount of implied wealth transferred, less any taxable gifts made to
            fund the strategies, would otherwise be taxed at a {rate}% federal
            estate tax rate. This calculation does not account for state
            transfer taxes.
          </li>
        </ol>

        <p>
          Luminary does not provide legal or tax advice. Consult with your legal
          and tax professionals prior to making any estate planning decisions.
        </p>
      </section>
    </>
  );
}
