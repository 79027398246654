import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { DesignerLayoutGrid } from '@/components/layout/DesignerLayoutGrid/DesignerLayoutGrid';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import {
  useCharitableTrustDesignerContext,
  useIsCRT,
} from '../CharitableTrustDesignerContext';
import {
  useCreateCharitableTrustProposalMutation,
  useUpdateCharitableTrustProposalMutation,
} from '../graphql/CharitableTrustProposalMutations.generated';
import { DEFAULT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION_FORM } from './CharitableTrustDesignerBasicInformation.constants';
import {
  CharitableTrustDesignerBasicInformationForm,
  NAMESPACE,
} from './CharitableTrustDesignerBasicInformation.types';
import {
  CharitableTrustDesignerBasicInformationColumn,
  CharitableTrustDesignerBeneficiariesColumn,
} from './CharitableTrustDesignerBasicInformation.UI';
import {
  mapExistingProposalDataToFrom,
  mapFormDataToCreateProposalInput,
  mapFormDataToUpdateProposalInput,
} from './CharitableTrustDesignerBasicInformation.utils';
import {
  CharitableTrustDesignerDataQuery,
  useCharitableTrustDesignerDataQuery,
} from './graphql/CharitableTrustDesignerData.generated';

export function CharitableTrustDesignerBasicInformation() {
  const navigate = useNavigate();
  const { householdId, proposalId, variant } =
    useCharitableTrustDesignerContext();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const isCRT = useIsCRT();

  const defaultValues = {
    ...DEFAULT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION_FORM,
    [NAMESPACE]: {
      ...DEFAULT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION_FORM[NAMESPACE],
      name: `Charitable ${
        isCRT ? 'Remainder' : 'Lead'
      } Trust Analysis ${formatDateToMonDDYYYY(new Date())}`,
    },
  };
  const formMethods = useForm<CharitableTrustDesignerBasicInformationForm>({
    defaultValues,
  });

  const { reset, handleSubmit } = formMethods;

  const { data: loadedData, loading: loadingData } =
    useCharitableTrustDesignerDataQuery({
      variables: {
        hasProposalID: proposalId !== NEW_PROPOSAL_ID,
        proposalId,
      },
      onError: (error) => {
        showFeedback(
          'Could not load proposal details.  Please refresh the page and try again.'
        );
        reportError('Caught error loading proposal details', error);
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data: CharitableTrustDesignerDataQuery) => {
        const mappedData = mapExistingProposalDataToFrom(data);
        if (mappedData) {
          reset(mappedData);
        } else if (!mappedData && proposalId !== NEW_PROPOSAL_ID) {
          showFeedback(
            'Could not load proposal details.  Please refresh the page and try again.'
          );
          reportError(
            `Got invalid state: got no data for proposal ${proposalId} on load`
          );
        }
      },
    });

  const [createCharitableTrustProposal, { loading: savingCreate }] =
    useCreateCharitableTrustProposalMutation({
      onError: (error) => {
        const displayVariant = isCRT ? 'CRT' : 'CLT';
        showFeedback(
          `Could not create a ${displayVariant} proposal.  Please try again.`
        );
        reportError(`Caught error when creating a ${displayVariant}`, error);
      },
      onCompleted: (data) => {
        showFeedback('Proposal created', { variant: 'success' });
        const newProposalId = data?.createProposalV2?.id;
        if (!newProposalId) {
          showFeedback(
            'Could not navigate to the newly-created proposal.  Please return to the Proposals tab'
          );
          reportError('Did not get new proposal ID after creating proposal');
          return;
        }
        navigate(
          getCompletePathFromRouteKey(
            ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_MODEL_SCENARIOS,
            {
              householdId,
              variant,
              proposalId: newProposalId,
            }
          )
        );
      },
    });

  const [updateCharitableTrustProposal, { loading: savingUpdate }] =
    useUpdateCharitableTrustProposalMutation({
      onError: (error) => {
        const displayVariant = isCRT ? 'CRT' : 'CLT';
        showFeedback(
          `Could not update the ${displayVariant} proposal.  Please try again.`
        );
        reportError(`Caught error when updating the ${displayVariant}`, error);
      },
      onCompleted: () => {
        showFeedback('Proposal updated', { variant: 'success' });
        navigate(
          getCompletePathFromRouteKey(
            ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_MODEL_SCENARIOS,
            {
              householdId,
              variant,
              proposalId,
            }
          )
        );
      },
    });

  const onSubmit = handleSubmit(async (formData) => {
    if (formData[NAMESPACE].id === NEW_PROPOSAL_ID) {
      const input = mapFormDataToCreateProposalInput(
        formData,
        householdId,
        isCRT
      );
      return createCharitableTrustProposal({ variables: { input } });
    } else {
      const input = mapFormDataToUpdateProposalInput(
        formData,
        householdId,
        isCRT,
        loadedData
      );
      return updateCharitableTrustProposal({
        variables: {
          input,
          updateDonorProfiles: false,
          householdID: '',
          donorProfiles: [],
        },
      });
    }
  });

  return (
    <FormFieldsDisabledProvider
      isDisabled={loadingData || savingCreate || savingUpdate}
    >
      <FormProvider {...formMethods}>
        <DesignerLayoutGrid
          LeftColumn={<CharitableTrustDesignerBasicInformationColumn />}
          RightColumn={<CharitableTrustDesignerBeneficiariesColumn />}
          footerLeftAction={
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                navigate(
                  getCompletePathFromRouteKey(
                    ROUTE_KEYS.HOUSEHOLD_DETAILS_PROPOSALS,
                    {
                      householdId,
                    }
                  )
                );
              }}
            >
              Cancel
            </Button>
          }
          footerRightAction={
            <Button
              variant="primary"
              size="sm"
              onClick={onSubmit}
              loading={savingCreate || savingUpdate}
            >
              Save and proceed
            </Button>
          }
        />
      </FormProvider>
    </FormFieldsDisabledProvider>
  );
}
