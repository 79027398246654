import { gridStringOrNumberComparator } from '@mui/x-data-grid-pro';
import { isNil } from 'lodash';

import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { BENEFICIARY_LEVEL_DISPLAY_NAMES } from '@/modules/beneficiaries/beneficiaries.constants';
import { sortBeneficiariesByLevel } from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.utils';
import {
  AccessParameterFrequency,
  AccessParameterKind,
  EntityKind,
  ScheduledDistributionFrequency,
  ScheduledDistributionKind,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';
import { getNodes } from '@/utils/graphqlUtils';

import { RowData } from './ExpandableBeneficiaryTable.types';
import {
  TrustBeneficiariesList_AccessParameterFragment,
  TrustBeneficiariesList_BeneficiaryFragment,
  TrustBeneficiariesList_ScheduledDistributionFragment,
} from './graphql/TrustBeneficiariesList.fragments.generated';
import { createBeneficiaryRow } from './TrustBeneficiariesList.utils';

interface GetColumnsInput {
  heading?: string;
}

export function getColumns({
  heading = 'Beneficiaries',
}: GetColumnsInput): Column<RowData>[] {
  return [
    {
      field: 'beneficiary',
      headerName: heading,
      flex: 1,
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => row.beneficiary.lineOne,
        lineOneProps: ({ row }) => row.beneficiary.lineOneProps ?? {},
        lineTwo: ({ row }) => row.beneficiary.lineTwo,
        rightContent: ({ row }) => row.beneficiary.rightContent,
        textAccompaniment: ({ row }) => row.beneficiary.textAccompaniment,
      }),
      sortComparator: (
        v1: RowData['beneficiary'],
        v2: RowData['beneficiary'],
        p1,
        p2
      ) => {
        return gridStringOrNumberComparator(v1.lineOne, v2.lineOne, p1, p2);
      },
    },
  ];
}

export function getAgeBoundsCopy(
  ageBoundFragment:
    | TrustBeneficiariesList_ScheduledDistributionFragment
    | TrustBeneficiariesList_AccessParameterFragment
) {
  const getAgeBoundsCopyFromAgeBounds = (bounds: {
    ageRequirementStart?: number | null;
    ageRequirementEnd?: number | null;
  }) => {
    const hasAgeStart = !isNil(bounds.ageRequirementStart);
    const hasAgeEnd = !isNil(bounds.ageRequirementEnd);

    if (hasAgeStart && hasAgeEnd) {
      return `Between ages ${bounds.ageRequirementStart} and ${bounds.ageRequirementEnd}`;
    }

    if (hasAgeStart && !hasAgeEnd) {
      return `Upon age ${bounds.ageRequirementStart}`;
    }

    if (!hasAgeStart && hasAgeEnd) {
      // Should not be used
      return `Until age ${bounds.ageRequirementEnd}`;
    }

    return '';
  };

  if (ageBoundFragment.__typename === 'ScheduledDistribution') {
    return getAgeBoundsCopyFromAgeBounds(ageBoundFragment);
  }

  if (ageBoundFragment.__typename === 'AccessParameter') {
    const ageParams = getNodes(ageBoundFragment.accessAgeParameters);

    return ageParams
      .map((param) => getAgeBoundsCopyFromAgeBounds(param))
      .join(', ');
  }

  return '';
}

export function getAccessParameterCopy(
  parameter: TrustBeneficiariesList_AccessParameterFragment,
  entityKind?: EntityKind
) {
  const getFrequencyCopy = (frequency: AccessParameterFrequency) => {
    switch (frequency) {
      case AccessParameterFrequency.Monthly:
        return 'monthly';
      case AccessParameterFrequency.Quarterly:
        return 'quarterly';
      case AccessParameterFrequency.Annually:
        return 'annually';
      case AccessParameterFrequency.Semiannually:
        return 'semiannually';
      default:
        throw new UnreachableError({
          case: frequency,
          message: `Unrecognized AccessParameterFrequency ${frequency}`,
        });
    }
  };

  const appendFrequency = (copy: string) => {
    if (parameter.frequency) {
      return `${copy} ${getFrequencyCopy(parameter.frequency)}`;
    }
    return copy;
  };

  switch (parameter.kind) {
    case AccessParameterKind.Full:
      return 'Full access to trust';
    case AccessParameterKind.AllTrustIncome:
      return 'Access to all trust income';
    case AccessParameterKind.Percentage:
      if (!parameter.percentage) {
        const msg = `AccessParameterKind.Percentage has no percentage`;
        diagnostics.error(msg, new Error(msg));

        return '';
      }

      if (
        entityKind === EntityKind.CltTrust ||
        entityKind === EntityKind.CrtTrust
      ) {
        return `${formatPercent(parameter.percentage, 0)}% of trust`;
      }

      return appendFrequency(
        `Access to ${formatPercent(parameter.percentage, 0)}% of trust`
      );
    case AccessParameterKind.Amount:
      if (!parameter.amount) {
        const msg = `AccessParameterKind.Amount has no amount`;
        diagnostics.error(msg, new Error(msg));

        return '';
      }

      if (
        entityKind === EntityKind.CltTrust ||
        entityKind === EntityKind.CrtTrust
      ) {
        return `${formatCurrencyNoDecimals(parameter.amount)}`;
      }

      return appendFrequency(
        `Access to ${formatCurrencyNoDecimals(parameter.amount)}`
      );
    case AccessParameterKind.FullDiscretion:
      return 'Access under full discretion of trustee';
    case AccessParameterKind.Hems:
      return 'Access under HEMS';
    case AccessParameterKind.Hms:
      return 'Access under HMS';
    case AccessParameterKind.Other:
      return 'Access under other parameters';
    case AccessParameterKind.NetIncome:
      return 'Access to net income';
    case AccessParameterKind.NetIncomeWithMakeup:
      return 'Access to net income with makeup';
    default:
      throw new UnreachableError({
        case: parameter.kind,
        message: `Unrecognized access parameter kind ${parameter.kind}`,
      });
  }
}

export function getScheduledDistributionCopy(
  distribution: TrustBeneficiariesList_ScheduledDistributionFragment
) {
  const getFrequencyCopy = (frequency: ScheduledDistributionFrequency) => {
    switch (frequency) {
      case ScheduledDistributionFrequency.Monthly:
        return 'monthly';
      case ScheduledDistributionFrequency.Quarterly:
        return 'quarterly';
      case ScheduledDistributionFrequency.Annually:
        return 'annually';
      case ScheduledDistributionFrequency.Semiannually:
        return 'semiannually';
      case ScheduledDistributionFrequency.OneTime:
        return 'once';
      default:
        throw new UnreachableError({
          case: frequency,
          message: `Unrecognized ScheduledDistributionFrequency ${frequency}`,
        });
    }
  };

  const appendFrequency = (copy: string) => {
    if (distribution.frequency) {
      return `${copy} ${getFrequencyCopy(distribution.frequency)}`;
    }
    return copy;
  };

  switch (distribution.kind) {
    case ScheduledDistributionKind.AllIncome:
      return appendFrequency('All income');
    case ScheduledDistributionKind.Amount:
      if (!distribution.amount) {
        const msg = `ScheduledDistributionKind.Amount has no amount`;
        diagnostics.error(msg, new Error(msg));

        return '';
      }
      return appendFrequency(formatCurrencyNoDecimals(distribution.amount));
    case ScheduledDistributionKind.Percentage:
      if (!distribution.percentage) {
        if (!distribution.percentage) {
          const msg = `ScheduledDistributionKind.Percentage has no percentage`;
          diagnostics.error(msg, new Error(msg));

          return '';
        }
      }
      return appendFrequency(`${formatPercent(distribution.percentage, 0)}%`);
    default:
      throw new UnreachableError({
        case: distribution.kind,
        message: `Unrecognized scheduled distribution kind ${distribution.kind}`,
      });
  }
}

export function getRows(
  beneficiaries: TrustBeneficiariesList_BeneficiaryFragment[],
  entityKind?: EntityKind
): RowData[] {
  const beneficiaryRows: RowData[] = [];
  const scheduledDistributionRows: RowData[] = [];
  const accessParameterRows: RowData[] = [];

  const orderedBeneficiaries = sortBeneficiariesByLevel(beneficiaries);

  orderedBeneficiaries.forEach((beneficiary) => {
    const { heading } = createBeneficiaryRow(beneficiary);

    beneficiaryRows.push({
      id: beneficiary.id,
      path: [beneficiary.id],
      beneficiary: {
        lineOne: heading,
        lineOneProps: {
          variant: 'h5',
        },
        lineTwo: beneficiary.level
          ? BENEFICIARY_LEVEL_DISPLAY_NAMES[beneficiary.level]
          : undefined,
      },
    });

    getNodes(beneficiary.scheduledDistributions).forEach((distribution) => {
      scheduledDistributionRows.push({
        id: distribution.id,
        path: [beneficiary.id, distribution.id],
        beneficiary: {
          lineOne: getScheduledDistributionCopy(distribution),
          lineTwo: getAgeBoundsCopy(distribution),
          rightContent: (
            <Badge
              sx={{ whiteSpace: 'nowrap' }}
              variant={BadgeVariants.Primary}
              display="Scheduled distribution"
            />
          ),
        },
      });
    });

    getNodes(beneficiary.accessParameters).forEach((parameter) => {
      let rightContent = null;
      if (parameter.kind === AccessParameterKind.Full) {
        rightContent = (
          <Badge
            sx={{ whiteSpace: 'nowrap' }}
            variant={BadgeVariants.NavyLight}
            display="Full access"
          />
        );
      } else {
        rightContent = (
          <Badge
            sx={{ whiteSpace: 'nowrap' }}
            variant={BadgeVariants.Gray}
            display={
              entityKind === EntityKind.CltTrust ||
              entityKind === EntityKind.CrtTrust
                ? 'Scheduled distribution'
                : 'Partial access'
            }
          />
        );
      }

      accessParameterRows.push({
        id: parameter.id,
        path: [beneficiary.id, parameter.id],
        beneficiary: {
          lineOne: getAccessParameterCopy(parameter, entityKind),
          lineTwo: getAgeBoundsCopy(parameter),
          rightContent,
        },
      });
    });
  });

  return [
    ...beneficiaryRows,
    ...scheduledDistributionRows,
    ...accessParameterRows,
  ];
}
