import { Typography } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';

import { generateUniqueId } from '@/components/utils/inputUtils';
import {
  AssetCategoryGroup,
  AssetV2QsbsEligibility,
  AssetValueV2OwnershipType,
} from '@/types/schema';
import { debug } from '@/utils/diagnostics';
import { formatCurrency } from '@/utils/formatting/currency';
import { commaDecimal } from '@/utils/formatting/numbers';

import { NormalizedAssetValuation } from '../assetValuation/useAssetValuation';
import { BUSINESS_ENTITY_TYPES } from '../entities/entities.constants';
import { EntityType } from '../entities/types/EntityType';
import { AssetDisplayName } from './AssetDetailCard/AssetDisplayName';
import { SubformAsset } from './AssetsSubform/types';
import { Asset, NEW_ASSET_ID } from './types/asset';

interface MapQueryAssetsOpts {
  markAsConfirmed: boolean;
  setValuesToZero?: boolean;
}

// the intent of these functions is to retain the general null state (if null, maintain null; if undefined, maintain undefined)
// of the underlying value, but if it's a truthy value, return a zero-value instead because we want to assets in a fully
// zeroed-out state
function getZeroDecimalValueIfAppropriate(
  value: Decimal | null | undefined,
  opts = { setValueToZero: false }
): Decimal | null | undefined {
  if (!value || !opts.setValueToZero) return value;
  return new Decimal(0);
}

function mapQueryAssetToAsset(
  assetValue: NormalizedAssetValuation['assetValues'][number],
  opts: MapQueryAssetsOpts
): SubformAsset {
  return {
    id: assetValue.asset.id,
    qsbsEligibility: assetValue.asset.qsbsEligibility,
    categoryId: assetValue.asset.categoryId,
    displayName: assetValue.asset.displayName,
    valuationMethod: assetValue.ownershipType,
    marketValue: getZeroDecimalValueIfAppropriate(assetValue.marketValue, {
      setValueToZero: !!opts.setValuesToZero,
    }),
    totalValue: getZeroDecimalValueIfAppropriate(assetValue.totalValue, {
      setValueToZero: !!opts.setValuesToZero,
    }),
    ownedPercent: assetValue.ownedPercent,
    shareCount: assetValue.shareCount,
    sharePrice: assetValue.shareValue,
    doDelete: false,
    valueId: assetValue.id,
    confirmed: opts.markAsConfirmed,
  };
}

export const mapQueryAssetsToAssets = (
  assetValues: NormalizedAssetValuation['assetValues'],
  opts: MapQueryAssetsOpts
): SubformAsset[] => {
  return assetValues.map((assetValue) =>
    mapQueryAssetToAsset(assetValue, opts)
  );
};

export function getValidAssets(assets: Asset[]): Asset[] {
  if (!assets) return [];
  return assets.filter((a) => !a.doDelete);
}

export function getMarketValueForAsset(asset: Asset): Decimal {
  const valuationMethod = asset.valuationMethod as AssetValueV2OwnershipType;
  if (
    valuationMethod === AssetValueV2OwnershipType.ValueBased &&
    asset.marketValue
  ) {
    return asset.marketValue;
  } else if (
    valuationMethod === AssetValueV2OwnershipType.ShareBased &&
    asset.sharePrice &&
    asset.shareCount
  ) {
    return asset.sharePrice.times(asset.shareCount);
  } else if (
    valuationMethod === AssetValueV2OwnershipType.PercentBased &&
    asset.totalValue &&
    asset.ownedPercent
  ) {
    return asset.totalValue.times(asset.ownedPercent.div(100));
  } else {
    debug('Asset for unrecognized valuation method', asset);
    throw new Error(`Unrecognized valuation method ${asset.valuationMethod}`);
  }
}

export function getCalculatedFundingValueFromAssets(
  assets: Asset[]
): Decimal | null {
  const assetsToSum = getValidAssets(assets);
  if (assetsToSum.length === 0) {
    return null;
  }

  let total = new Decimal(0);
  assetsToSum.forEach((asset) => {
    total = total.plus(getMarketValueForAsset(asset));
  });
  return total;
}

export function getFormattedSharePriceForAsset(asset: Asset): string {
  if (
    asset.sharePrice &&
    (asset.valuationMethod as AssetValueV2OwnershipType) ===
      AssetValueV2OwnershipType.ShareBased
  ) {
    return formatCurrency(asset.sharePrice, {
      maximumFractionDigits: 3,
      minimumFractionDigits: 3,
    });
  }

  return '';
}

export function getFormattedShareCountForAsset(asset: Asset): string {
  if (
    asset.shareCount &&
    (asset.valuationMethod as AssetValueV2OwnershipType) ===
      AssetValueV2OwnershipType.ShareBased
  ) {
    return commaDecimal(asset.shareCount ?? new Decimal(0));
  }

  return '';
}

function getDefaultEligibilityForCategory(
  group: AssetCategoryGroup
): AssetV2QsbsEligibility {
  switch (group) {
    case AssetCategoryGroup.CashAndCashEquivalents:
      return AssetV2QsbsEligibility.No;
    case AssetCategoryGroup.PrivateAsset:
    // falls through
    case AssetCategoryGroup.PublicAsset:
      return AssetV2QsbsEligibility.NotConfirmed;
    default:
      throw new Error(`Unhandled asset group: ${group}`);
  }
}

export function getSelectedAsset(
  assets: Asset[],
  assetId: string | null
): Asset {
  // i don't love this !assetId pattern, but we need to pass something in to allow the modal to render itself. if we
  // return early/null here, we can't create a valid set of initialValues in the AssetDetailsModal.
  if (assetId === NEW_ASSET_ID || !assetId) {
    return {
      id: generateUniqueId(`${NEW_ASSET_ID}_`),
      categoryId: '',
      displayName: '',
      marketValue: null,
      valuationMethod: '',
      qsbsEligibility: getDefaultEligibilityForCategory(
        AssetCategoryGroup.PublicAsset
      ),
    };
  }

  const asset = assets.find((asset) => asset.id === assetId);
  if (!asset) {
    throw new Error(`No asset found with ID ${assetId}`);
  }
  return asset;
}

export function getAssetCellDefinition({
  displayName,
  displayCategory,
  accountName,
}: {
  displayName: string;
  displayCategory?: string;
  accountName?: string;
}): GridValidRowModel {
  return {
    lineOne: <AssetDisplayName displayName={displayName} />,
    lineTwo: (() => {
      const lineContent = (() => {
        if (accountName) return accountName;
        if (displayCategory) return displayCategory;
        return null;
      })();

      if (!lineContent) return null;

      return (
        <Typography noWrap variant="subtitle2">
          {lineContent}
        </Typography>
      );
    })(),
    value: displayName,
  };
}

/**
 * @description determineDefaultAssetClassId is a function that determines the
 * default asset class ID for an entity based on the entity type.
 */
export function determineDefaultAssetClassId(
  entityType: EntityType | '',
  defaultAssetClassId: string,
  assetClassesById: Record<string, { displayName: string }>
): string {
  const findAssetClassIdByName = (name: string): string | undefined => {
    return Object.entries(assetClassesById).find(
      ([, assetClass]) => assetClass.displayName === name
    )?.[0];
  };

  if (!entityType) {
    return defaultAssetClassId;
  }

  // NOTE NOTE NOTE: This is somewhat brittle, and may break, but will work most of the time
  // and we are aware of the tradeoffs. If this becomes a problem, we could consider adding more persistent
  // concepts of e.g. retirement and business ownership to the asset class definitions, or allow marking
  // asset classes as default for entity types.
  if (entityType === 'retirement-account') {
    const retirementBlendedId = findAssetClassIdByName('Retirement blended');
    return retirementBlendedId || defaultAssetClassId;
  }

  if (
    entityType === 'qualified-tuition-account' ||
    entityType === 'custodial-account'
  ) {
    const investmentsBlendedId = findAssetClassIdByName('Investments blended');
    return investmentsBlendedId || defaultAssetClassId;
  }

  if (entityType === 'qprt') {
    const qprtId = findAssetClassIdByName('Real estate');
    return qprtId || defaultAssetClassId;
  }

  if (BUSINESS_ENTITY_TYPES.includes(entityType)) {
    const privatelyHeldBusinessesId = findAssetClassIdByName(
      'Privately held businesses'
    );
    return privatelyHeldBusinessesId || defaultAssetClassId;
  }

  return defaultAssetClassId;
}
