import { ThemeOptions } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { merge } from 'lodash';
import React from 'react';

import { useFullscreenContext } from '@/components/Fullscreen/fullscreen.context';

import { COLORS } from '../tokens/colors';
import { COMMON_THEME_PROPERTIES } from './common';

// The complete theme object, along with defined defaults, is available
// at: https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette

type LuminaryThemeVariant = 'standard' | 'darkText';

// The container element for popper components when in fullscreen mode
function container() {
  return document.fullscreenElement ?? document.body;
}

export function getTheme(variant: LuminaryThemeVariant, isFullscreen = false) {
  const darkTextColors = {
    h1: {
      color: COLORS.GRAY[900],
    },
    h2: {
      color: COLORS.GRAY[900],
    },
    h3: {
      color: COLORS.GRAY[900],
    },
    h4: {
      color: COLORS.GRAY[900],
    },
    h5: {
      color: COLORS.GRAY[900],
    },
    h6: {
      color: COLORS.GRAY[900],
    },
  };

  // Override the container element for popper components when in fullscreen mode because
  // the default container is not guaranteed to be included in the fullscreen frame
  const fullscreenOverrides: ThemeOptions = isFullscreen
    ? {
        components: {
          MuiMenu: {
            defaultProps: {
              container,
            },
          },
          MuiTooltip: {
            defaultProps: {
              PopperProps: {
                container,
              },
            },
          },
          MuiPopover: {
            defaultProps: {
              container,
            },
          },
          MuiDialog: {
            defaultProps: {
              container,
            },
          },
        },
      }
    : {};

  const baseTheme = merge(
    {},
    COMMON_THEME_PROPERTIES,
    variant === 'darkText' ? { typography: darkTextColors } : {},
    fullscreenOverrides
  );

  return createTheme(baseTheme, {
    palette: {
      primary: {
        dark: COLORS.NAVY[900],
        main: COLORS.NAVY[700],
        light: COLORS.NAVY[600],
        contrastText: '#ffffff',
      },
      secondary: {
        dark: COLORS.BLUE[900],
        main: COLORS.BLUE[700],
        light: COLORS.GRAY[100],
        contrastText: '#ffffff',
      },
      buttons: {
        dark: COLORS.NAVY[900],
        main: COLORS.NAVY[700],
        light: COLORS.NAVY[200],
        contrastText: '#ffffff',
      },
      education: {
        main: COLORS.FUNCTIONAL.WARNING[400],
      },
      background: {
        default: COLORS.GRAY[50],
        paper: '#ffffff',
      },
    },
  });
}

interface LuminaryThemeProviderProps {
  variant?: LuminaryThemeVariant;
  fullScreen?: boolean;
}

export function useLuminaryTheme(variant?: LuminaryThemeVariant) {
  const { isFullscreen } = useFullscreenContext();
  return getTheme(variant ?? 'standard', isFullscreen);
}

export function LuminaryThemeProvider({
  children,
  variant = 'standard',
}: React.PropsWithChildren<LuminaryThemeProviderProps>) {
  const theme = useLuminaryTheme(variant);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
