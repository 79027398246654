import Decimal from 'decimal.js';

import { getNodes } from '@/utils/graphqlUtils';

import { BeneficiariesSubformType } from '../types';
import { BeneficiaryFormDetailsv2Fragment } from './../graphql/BeneficiariesFormDetails.generated';

export function getBeneficiariesFormValuesFromQueryv2(
  gratTrust: BeneficiaryFormDetailsv2Fragment
): BeneficiariesSubformType {
  const rawBeneficiaries = gratTrust?.beneficiaries ?? [];
  const formBeneficiaries = rawBeneficiaries.map((beneficiary) => {
    const summary = beneficiary.summary;

    const percent = (() => {
      return (
        getNodes(beneficiary.accessParameters)[0]?.percentage ?? new Decimal(0)
      );
    })();

    return {
      beneficiaryId: summary.id,
      heading: summary.displayName,
      displayType: summary.displayType,
      type: summary.kind,
      // note that we only read and write a single distribution for the GRAT beneficiary scenario
      percent,
      badgeText: summary.badgeText ?? undefined,
      additionalItems: summary.additionalContext,
    };
  });

  return {
    beneficiaries: formBeneficiaries,
  };
}
