// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DuplicateProposalMutationVariables = Types.Exact<{
  duplicateProposalId: Types.Scalars['ID']['input'];
}>;


export type DuplicateProposalMutation = { __typename?: 'Mutation', duplicateProposal: { __typename: 'Proposal', id: string } };


export const DuplicateProposalDocument = gql`
    mutation DuplicateProposal($duplicateProposalId: ID!) {
  duplicateProposal(id: $duplicateProposalId) {
    __typename
    id
  }
}
    `;
export type DuplicateProposalMutationFn = Apollo.MutationFunction<DuplicateProposalMutation, DuplicateProposalMutationVariables>;

/**
 * __useDuplicateProposalMutation__
 *
 * To run a mutation, you first call `useDuplicateProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProposalMutation, { data, loading, error }] = useDuplicateProposalMutation({
 *   variables: {
 *      duplicateProposalId: // value for 'duplicateProposalId'
 *   },
 * });
 */
export function useDuplicateProposalMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateProposalMutation, DuplicateProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateProposalMutation, DuplicateProposalMutationVariables>(DuplicateProposalDocument, options);
      }
export type DuplicateProposalMutationHookResult = ReturnType<typeof useDuplicateProposalMutation>;
export type DuplicateProposalMutationResult = Apollo.MutationResult<DuplicateProposalMutation>;
export type DuplicateProposalMutationOptions = Apollo.BaseMutationOptions<DuplicateProposalMutation, DuplicateProposalMutationVariables>;
export const Operations = {
  Mutation: {
    DuplicateProposal: 'DuplicateProposal' as const
  }
}