import {
  array,
  boolean as booleanType,
  Describe,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreateQualifiedTuitionPersonalAccountInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateQualifiedTuitionPersonalAccountInput,
} from '@/types/schema';

import {
  commonCreatePersonalAccountProperties,
  commonUpdatePersonalAccountProperties,
  controllingPartySchema,
} from './personalAccountTypes';
import {
  beneficiarySchema,
  commonUpdateEntityProperties,
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  grantorSchema,
  gstStatusSchema,
  inEstateStatusSchema,
  nonTrustEntityTaxStatus,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const createQualifiedTuitionAccountSchema: Describe<AugmentedCreateQualifiedTuitionPersonalAccountInput> =
  object({
    create: object({
      ...commonCreatePersonalAccountProperties,
      taxStatus: nonTrustEntityTaxStatus,
      gstStatus: gstStatusSchema,
      inEstateStatus: inEstateStatusSchema,
    }),
    withOwner: optional(nullable(grantorSchema)),
    withBeneficiaries: optional(nullable(array(beneficiarySchema))),
    withControllingParties: optional(nullable(array(controllingPartySchema))),
    withDesignerAccount: optional(nullable(createAccountSchema)),
  });

const updateQualifiedTuitionAccountSchema: Describe<AugmentedUpdateQualifiedTuitionPersonalAccountInput> =
  object({
    id: string(),
    update: object({
      ...commonUpdatePersonalAccountProperties,
      taxStatus: optional(nullable(nonTrustEntityTaxStatus)),
      gstStatus: gstStatusSchema,
      inEstateStatus: inEstateStatusSchema,
      clearControllingParties: optional(nullable(booleanType())),
      clearOwner: optional(nullable(booleanType())),
      clearBeneficiaries: optional(nullable(booleanType())),
      clearDescription: optional(nullable(booleanType())),
      clearGstStatus: optional(nullable(booleanType())),
      clearTaxStatus: optional(nullable(booleanType())),
    }),
    withOwner: optional(nullable(grantorSchema)),
    withBeneficiaries: optional(nullable(array(beneficiarySchema))),
    withControllingParties: optional(nullable(array(controllingPartySchema))),
    updateDesignerAccount: optional(nullable(updateAccountSchema)),
  });

const createSchema: Describe<AugmentedCreateEntityInput> = object({
  create: createEntityInput,
  withQualifiedTuitionPersonalAccount: optional(
    nullable(createQualifiedTuitionAccountSchema)
  ),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object(commonUpdateEntityProperties),
  updateQualifiedTuitionPersonalAccount: optional(
    nullable(updateQualifiedTuitionAccountSchema)
  ),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export function validateQualifiedTuitionAccountCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(
    input,
    createSchema,
    'QualifiedTuition account create'
  );
}

export function validateQualifiedTuitionAccountUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(
    input,
    updateSchema,
    'QualifiedTuition account update'
  );
}
