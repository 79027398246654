import { useApolloClient } from '@apollo/client';
import Decimal from 'decimal.js';
import { useEffect, useState } from 'react';

import { ProposalScenarioFragment } from '@/modules/proposal/graphql/ProposalScenarioFragment.generated';
import { queryProposalForScenario } from '@/modules/proposal/hooks/useProjectionForScenario';
import { ScenarioSummaryCompare } from '@/modules/proposal/share/ScenarioSummaryCompare';
import { VisualizationInput } from '@/modules/proposal/types';
import { getBeneficiariesFromProposal } from '@/modules/proposal/utils';
import { EntityProposalAssetValuationProjectionType } from '@/types/schema';
import { assertNonNil } from '@/utils/assertUtils';
import { maxDecimalJS } from '@/utils/decimalJSUtils';

import { tabConfigurations } from './ImpactComparison';

interface Props {
  proposalScenarios: ProposalScenarioFragment[];
  entityId: string;
  maxPositive?: Decimal;
  taxDrag?: Decimal;
  selectedYearOptionIndex: number;
  proposalYearProjectionOptions: {
    value: string;
    valueDecimal: Decimal;
    display: string;
  }[];
  selectedTabIndex: number;
  assetValuationProjectionType: EntityProposalAssetValuationProjectionType;
  beneficiaries: ReturnType<typeof getBeneficiariesFromProposal>;
}

export function ScenariosDataComponent({
  proposalScenarios,
  proposalYearProjectionOptions,
  selectedYearOptionIndex,
  entityId,
  selectedTabIndex,
  taxDrag,
  assetValuationProjectionType,
  beneficiaries,
}: Props) {
  const client = useApolloClient();
  // The max column value across all scenarios and time periods
  const [overallMax, setOverallMax] = useState<Decimal>(new Decimal(0));
  const [state, setState] = useState<{
    maxPositives: Decimal[];
    chartValues: {
      preTax: {
        noPlan: VisualizationInput;
        withPlan: VisualizationInput;
      };
      postTax: {
        noPlan: VisualizationInput;
        withPlan: VisualizationInput;
      };
    }[];
  }>({
    maxPositives: [],
    chartValues: [],
  });

  const isTermEndProjectedValue =
    assetValuationProjectionType ===
    EntityProposalAssetValuationProjectionType.ProjectedValue;

  const [maxHeight, setMaxHeight] = useState<{
    preTax: number | null;
    postTax: number | null;
  }>({
    preTax: null,
    postTax: null,
  });

  useEffect(() => {
    async function fetchData() {
      const promises = [];

      for (const proposalScenario of proposalScenarios) {
        const maxTimelineYears =
          proposalYearProjectionOptions[
            proposalYearProjectionOptions.length - 1
          ]?.valueDecimal.toNumber() ?? 0;
        const projectionTimelineYearsValue =
          proposalYearProjectionOptions[selectedYearOptionIndex]?.value;
        const projectionTimelineYearsInt = projectionTimelineYearsValue
          ? parseInt(projectionTimelineYearsValue)
          : undefined;

        const queryInputs = {
          afterTermAnnualRateOfReturn:
            proposalScenario.gratProposalScenario
              ?.projectedAnnualReturnAfterTerm ?? new Decimal(0),
          inTermAnnualRateOfReturn: isTermEndProjectedValue
            ? undefined
            : proposalScenario.gratProposalScenario
                ?.projectedAnnualReturnThroughTerm,
          projectionTimelineYears:
            projectionTimelineYearsInt ??
            proposalScenario.gratProposalScenario?.projectionTimePeriodYears ??
            0,
          entityID: entityId,
          taxDragEstimate: taxDrag ?? new Decimal(20),
          endTermProjectedValue: isTermEndProjectedValue
            ? proposalScenario.gratProposalScenario?.projectedMarketValueAtTerm
            : new Decimal(0),
        };

        const queryInputsMaxTerm = {
          ...queryInputs,
          projectionTimelineYears: maxTimelineYears,
        };

        promises.push(
          queryProposalForScenario({
            currentTab: 'preTax',
            termEndAssetValuationType: assetValuationProjectionType,
            queryInputs,
            client,
            queryInputsMaxTerm,
          })
        );
        promises.push(
          queryProposalForScenario({
            currentTab: 'postTax',
            termEndAssetValuationType: assetValuationProjectionType,
            queryInputs,
            client,
            queryInputsMaxTerm,
          })
        );
      }

      const results = await Promise.all(promises);
      const chartValues: {
        preTax: {
          noPlan: VisualizationInput;
          withPlan: VisualizationInput;
        };
        postTax: {
          noPlan: VisualizationInput;
          withPlan: VisualizationInput;
        };
      }[] = [];
      const maxPositives: Decimal[] = [];

      for (let i = 0; i < results.length; i += 2) {
        const {
          noPlan: noPlanPreTax,
          withPlan: withPlanPreTax,
          maxProjectionValue,
        } = results[i]!;
        const { noPlan: noPlanPostTax, withPlan: withPlanPostTax } =
          results[i + 1]!;

        const thisChartValues = {
          preTax: {
            noPlan: noPlanPreTax,
            withPlan: withPlanPreTax,
          },
          postTax: {
            noPlan: noPlanPostTax,
            withPlan: withPlanPostTax,
          },
        };

        const noPlanNoTax = thisChartValues.preTax.noPlan[0].plus(
          thisChartValues.preTax.noPlan[1]
        );
        const withPlanNoTax = thisChartValues.preTax.withPlan[0].plus(
          thisChartValues.preTax.withPlan[1]
        );
        const noPlanWithTax = thisChartValues.postTax.noPlan[0].plus(
          thisChartValues.postTax.noPlan[1]
        );
        const withPlanWithTax = thisChartValues.postTax.withPlan[0].plus(
          thisChartValues.postTax.withPlan[1]
        );

        chartValues.push(thisChartValues);

        if (maxProjectionValue.gt(overallMax)) {
          setOverallMax(maxProjectionValue);
        }

        maxPositives.push(
          maxDecimalJS(
            [noPlanNoTax, withPlanNoTax, noPlanWithTax, withPlanWithTax],
            new Decimal(0)
          )
        );
      }

      setState({
        maxPositives,
        chartValues,
      });
    }

    void fetchData();
  }, [
    proposalScenarios,
    isTermEndProjectedValue,
    proposalYearProjectionOptions,
    selectedYearOptionIndex,
    entityId,
    taxDrag,
    assetValuationProjectionType,
    client,
    overallMax,
  ]);

  if (state.maxPositives.length !== proposalScenarios.length) {
    return null;
  }

  if (state.chartValues.length !== proposalScenarios.length) {
    return null;
  }

  return (
    <>
      {proposalScenarios.map((proposalScenario, idx) => {
        const chartValues = assertNonNil(
          state.chartValues[idx],
          `Could not find chart values: ${proposalScenario.id}`
        );
        const currentTab = assertNonNil(
          tabConfigurations[selectedTabIndex]?.value,
          `Could not find currentTab: ${proposalScenario.id}`
        );

        return (
          <ScenarioSummaryCompare
            key={proposalScenario.id}
            chartValues={chartValues}
            scenarioNumber={idx + 1}
            currentTab={currentTab}
            maxPositive={maxDecimalJS(state.maxPositives, new Decimal(0))}
            entityId={entityId}
            beneficiaries={beneficiaries}
            gratProposalScenario={proposalScenario.gratProposalScenario}
            taxDrag={taxDrag ?? new Decimal(20)}
            maxHeight={maxHeight}
            setMaxHeight={setMaxHeight}
            maxProjectionValue={overallMax}
          />
        );
      })}
    </>
  );
}
