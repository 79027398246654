import { Stack } from '@mui/material';

import { SubformConfig } from '@/modules/entities/EntitySubforms/entitySubformConfigs';
import { assertNonNil } from '@/utils/assertUtils';

export interface EntitySubformsFormsProps {
  subforms: JSX.Element[];
  subformConfig: SubformConfig[];
}

export const EntitySubformsForms = ({
  subforms,
  subformConfig,
}: EntitySubformsFormsProps) => {
  return (
    <>
      {subforms.map((Form, i) => {
        const { htmlId } = assertNonNil(
          subformConfig[i],
          `could not find subform config`
        );

        const isLast = i === subforms.length - 1;

        return (
          <Stack
            key={`subform-item-${i}`}
            id={htmlId}
            sx={{ pb: isLast ? 3 : undefined }} // adds padding to the last child element inside this to create space between it and the footer
          >
            {Form}
          </Stack>
        );
      })}
    </>
  );
};
