import { Box, Stack, Typography } from '@mui/material';

import { ColorBox } from '@/components/display/ColorBox/ColorBox';
import { COLORS } from '@/styles/tokens/colors';

import { useEntityMapContext } from '../contexts/entityMap.context';

const DIAGRAM_LEGEND_ITEMS = [
  {
    label: 'In estate',
    color: COLORS.CATEGORIES.IN_ESTATE[300],
  },
  {
    label: 'Out of estate (family)',
    color: COLORS.CATEGORIES.FAMILY_GIVING[300],
  },
  {
    label: 'Out of estate (charity)',
    color: COLORS.CATEGORIES.CHARITABLE_GIVING[300],
  },
] as const;

function NodeCountDisplay() {
  const { state } = useEntityMapContext();
  const allNodesCount = state.derivedData.allPossibleNodes.length;
  const currentNodesCount = state.nodes.length;

  if (allNodesCount === currentNodesCount) return null;

  return (
    <>
      <Box
        width="1px"
        height="16px"
        sx={{ backgroundColor: COLORS.GRAY[300] }}
      />

      <Typography variant="subtitle2">
        Showing {currentNodesCount} of {allNodesCount} entities
      </Typography>
    </>
  );
}

export function EntityMapLegend() {
  return (
    <Stack
      direction="row"
      spacing={2}
      p={1.5}
      sx={{
        backgroundColor: COLORS.GRAY[100],
        borderTopRightRadius: '4px',
      }}
    >
      {DIAGRAM_LEGEND_ITEMS.map((item) => (
        <Stack
          direction="row"
          alignItems="center"
          key={item.label}
          spacing={0.5}
        >
          <ColorBox color={item.color} />
          <Typography variant="subtitle2">{item.label}</Typography>
        </Stack>
      ))}
      <NodeCountDisplay />
    </Stack>
  );
}
