import { Stack, StackProps, Typography } from '@mui/material';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useModalState } from '@/hooks/useModalState';
import { zIndexes } from '@/styles/zIndexes';

export interface LargeTableEmptyStateProps {
  title: string;
  description: string;
  ModalComponent: React.FC<{ isOpen: boolean; onClose: () => void }>;
  buttonProps?: Partial<ButtonProps>;
  containerProps?: Partial<StackProps>;
  buttonText: string;
}

export function LargeTableEmptyState({
  title,
  description,
  buttonText,
  ModalComponent,
  buttonProps = {},
  containerProps = {},
}: LargeTableEmptyStateProps) {
  const [{ isModalOpen }, { closeModal, openModal }] = useModalState();

  return (
    <Stack
      // Need to elevate the zIndex a little bit because of some MUI internals
      zIndex={zIndexes.CARD}
      {...containerProps}
      sx={{
        width: '100%',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        p: 4,
        py: 22,
        ...(containerProps?.sx ?? {}),
      }}
    >
      <Typography variant="h1" sx={{ pb: 1 }}>
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{ pb: 3, maxWidth: 400, textAlign: 'center' }}
      >
        {description}
      </Typography>
      <Button
        startIcon={PlusIcon}
        variant="primary"
        size="sm"
        onClick={openModal}
        {...buttonProps}
      >
        {buttonText}
      </Button>
      <ModalComponent isOpen={isModalOpen} onClose={closeModal} />
    </Stack>
  );
}
