import { getGRATTrustSummaryProperties } from '@/modules/entities/details/grat/GRATTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { TrustFiduciariesSlide } from '../components/Trust.Fiduciaries';
import { GRATTrustPresentationSlideProps } from './GRATTrust.types';

export function GRATTrustFiduciariesSlide({
  entity,
  subtype,
  ...registrationProps
}: GRATTrustPresentationSlideProps) {
  const summaryDetails = getGRATTrustSummaryProperties(
    {
      entity,
      entityType: ENTITY_TYPES.GRAT,
    },
    { variant: 'print' }
  );

  return (
    <TrustFiduciariesSlide
      entity={entity}
      summary={summaryDetails}
      displayName={subtype.displayName}
      {...registrationProps}
    />
  );
}
