import hashsum from 'hash-sum';
import { omit } from 'lodash';
import { useEffect, useLayoutEffect, useMemo } from 'react';

import { useFlowChartContext } from '../context/flowChart.context';
import { MarkerType, MarkerTypeToProps } from '../types';

export function useMarkerId<T extends MarkerType>(
  type: T,
  props: MarkerTypeToProps[T]
): string {
  const {
    __internal: { markers, setMarkers },
  } = useFlowChartContext();

  const objectHash = useMemo(() => {
    return hashsum({ __marker_type__: type, ...props });
  }, [props, type]);

  // Add marker if it doesn't already exist in global context
  // Layout effect needed to compute marker data before rendering
  useLayoutEffect(() => {
    if (!markers[objectHash]) {
      setMarkers((markers) => ({ ...markers, [objectHash]: { type, props } }));
    }
  }, [markers, objectHash, props, setMarkers, type]);

  // Unregister markers when the component unmounts
  useEffect(() => {
    return () => setMarkers((markers) => omit(markers, objectHash));
  }, [objectHash, setMarkers]);

  return `url(#${objectHash})`;
}
