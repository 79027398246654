import { Grid, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { useForm } from '@/components/react-hook-form';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

interface TimezoneForm {
  date: Date;
}

const dateFormatUK = new Intl.DateTimeFormat('en-GB', {
  timeZone: 'Europe/London',
  timeZoneName: 'short',
});

const dateFormatAU = new Intl.DateTimeFormat('en-AU', {
  timeZone: 'Australia/Sydney',
  timeZoneName: 'short',
});

const dateFormatHI = new Intl.DateTimeFormat('en-US', {
  timeZone: 'Pacific/Honolulu',
  timeZoneName: 'short',
});

const dateFormatNY = new Intl.DateTimeFormat('en-US', {
  timeZone: 'America/New_York',
  timeZoneName: 'short',
});

const dateFormatFiji = new Intl.DateTimeFormat('en-FJ', {
  timeZone: 'Pacific/Fiji',
  timeZoneName: 'short',
});

export function DatesSandbox() {
  const formMethods = useForm<TimezoneForm>({
    defaultValues: {
      date: new Date(),
    },
  });

  const {
    control,
    formState: { isDirty },
  } = formMethods;

  const date = useWatch({
    control: formMethods.control,
    name: 'date',
  });

  const dateForm = date?.toString();
  const dateSerialized = useMemo(() => {
    try {
      return date?.toISOString();
    } catch (error) {
      return null;
    }
  }, [date]);
  const dateParsed = dateSerialized ? new Date(dateSerialized) : null;
  const dateLondon = dateParsed ? dateFormatUK.format(dateParsed) : null;
  const dateSydney = dateParsed ? dateFormatAU.format(dateParsed) : null;
  const dateHonolulu = dateParsed ? dateFormatHI.format(dateParsed) : null;
  const dateNY = dateParsed ? dateFormatNY.format(dateParsed) : null;
  const dateFiji = dateParsed ? dateFormatFiji.format(dateParsed) : null;

  return (
    <FormProvider {...formMethods}>
      <SubpageLayout heading="Dates sandbox">
        <Grid container spacing={3}>
          <Grid item sm={3}>
            <Stack spacing={3}>
              <Typography variant="body1">Form isDirty</Typography>
              <Typography variant="subtitle2">{isDirty.toString()}</Typography>
              <FormAwareDatePickerInput<TimezoneForm>
                control={control}
                label="Date"
                fieldName="date"
              />
              <Typography variant="body1">Form state</Typography>
              <Typography variant="subtitle2">{dateForm}</Typography>
              <Typography variant="body1">Serialized</Typography>
              <Typography variant="subtitle2">{dateSerialized}</Typography>
              <Typography variant="body1">Formatted</Typography>
              {dateParsed && (
                <Typography variant="subtitle2">
                  {formatDateToMonDDYYYY(dateParsed)}
                </Typography>
              )}
              <Typography variant="body1">London</Typography>
              {dateLondon && (
                <Typography variant="subtitle2">{dateLondon}</Typography>
              )}
              <Typography variant="body1">Sydney</Typography>
              {dateSydney && (
                <Typography variant="subtitle2">{dateSydney}</Typography>
              )}
              <Typography variant="body1">Honolulu</Typography>
              {dateHonolulu && (
                <Typography variant="subtitle2">{dateHonolulu}</Typography>
              )}
              <Typography variant="body1">New York</Typography>
              {dateNY && <Typography variant="subtitle2">{dateNY}</Typography>}
              <Typography variant="body1">Fiji</Typography>
              {dateFiji && (
                <Typography variant="subtitle2">{dateFiji}</Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </SubpageLayout>
    </FormProvider>
  );
}
