import { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export interface ProposalDetailsContext {
  proposalId: string | null;
  setProposalId: Dispatch<SetStateAction<ProposalDetailsContext['proposalId']>>;

  displayName: string | null;
  setDisplayName: Dispatch<
    SetStateAction<ProposalDetailsContext['displayName']>
  >;
}

export const ProposalDetailsContext = createContext<ProposalDetailsContext>({
  proposalId: null,
  setProposalId: () => null,

  displayName: null,
  setDisplayName: () => null,
});

export const useProposalDetailsContext = () => {
  return useGuardedContext(ProposalDetailsContext, 'ProposalDetailsProvider');
};
