// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLifetimeExemptionDetails_ClientProfileFragment = { __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD };

export type GetLifetimeExemptionDetails_HouseholdFragment = { __typename?: 'Household', possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD }> };

export type GetLifetimeExemptionDetailsQueryVariables = Types.Exact<{
  householdWhere?: Types.InputMaybe<Types.HouseholdWhereInput>;
}>;


export type GetLifetimeExemptionDetailsQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD }> } | null } | null> | null } };

export const GetLifetimeExemptionDetails_ClientProfileFragmentDoc = gql`
    fragment GetLifetimeExemptionDetails_ClientProfile on ClientProfile {
  id
  gstExclusionUsed
  displayName
  lifetimeExclusionUsed
  currentAnnualExclusionUsed
  totalAnnualExclusionUsed
}
    `;
export const GetLifetimeExemptionDetails_HouseholdFragmentDoc = gql`
    fragment GetLifetimeExemptionDetails_Household on Household {
  possiblePrimaryClients {
    ...GetLifetimeExemptionDetails_ClientProfile
  }
}
    ${GetLifetimeExemptionDetails_ClientProfileFragmentDoc}`;
export const GetLifetimeExemptionDetailsDocument = gql`
    query GetLifetimeExemptionDetails($householdWhere: HouseholdWhereInput) {
  households(where: $householdWhere) {
    edges {
      node {
        id
        ...GetLifetimeExemptionDetails_Household
      }
    }
  }
}
    ${GetLifetimeExemptionDetails_HouseholdFragmentDoc}`;

/**
 * __useGetLifetimeExemptionDetailsQuery__
 *
 * To run a query within a React component, call `useGetLifetimeExemptionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLifetimeExemptionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLifetimeExemptionDetailsQuery({
 *   variables: {
 *      householdWhere: // value for 'householdWhere'
 *   },
 * });
 */
export function useGetLifetimeExemptionDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetLifetimeExemptionDetailsQuery, GetLifetimeExemptionDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLifetimeExemptionDetailsQuery, GetLifetimeExemptionDetailsQueryVariables>(GetLifetimeExemptionDetailsDocument, options);
      }
export function useGetLifetimeExemptionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLifetimeExemptionDetailsQuery, GetLifetimeExemptionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLifetimeExemptionDetailsQuery, GetLifetimeExemptionDetailsQueryVariables>(GetLifetimeExemptionDetailsDocument, options);
        }
export function useGetLifetimeExemptionDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLifetimeExemptionDetailsQuery, GetLifetimeExemptionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLifetimeExemptionDetailsQuery, GetLifetimeExemptionDetailsQueryVariables>(GetLifetimeExemptionDetailsDocument, options);
        }
export type GetLifetimeExemptionDetailsQueryHookResult = ReturnType<typeof useGetLifetimeExemptionDetailsQuery>;
export type GetLifetimeExemptionDetailsLazyQueryHookResult = ReturnType<typeof useGetLifetimeExemptionDetailsLazyQuery>;
export type GetLifetimeExemptionDetailsSuspenseQueryHookResult = ReturnType<typeof useGetLifetimeExemptionDetailsSuspenseQuery>;
export type GetLifetimeExemptionDetailsQueryResult = Apollo.QueryResult<GetLifetimeExemptionDetailsQuery, GetLifetimeExemptionDetailsQueryVariables>;
export const Operations = {
  Query: {
    GetLifetimeExemptionDetails: 'GetLifetimeExemptionDetails' as const
  },
  Fragment: {
    GetLifetimeExemptionDetails_ClientProfile: 'GetLifetimeExemptionDetails_ClientProfile' as const,
    GetLifetimeExemptionDetails_Household: 'GetLifetimeExemptionDetails_Household' as const
  }
}