import Decimal from 'decimal.js';
import { compact } from 'lodash';

import { AfterDeath, EstateWaterfallEdgeKind } from '@/types/schema';
import { getProvisionsByDeathForScenario } from '@/utils/dispositiveProvisions';
import { getNodes } from '@/utils/graphqlUtils';

import {
  GetDispositiveProvisions_EstateWaterfallVizEdgeFragment,
  GetDispositiveProvisions_EstateWaterfallVizNodeFragment,
} from '../DispositiveProvisionsForm/graphql/GetDispositiveProvisions.generated';
import {
  DispositiveProvisions_DispositionScenarioFragment,
  DispositiveProvisions_DispositiveProvisionFragment,
} from '../graphql/DispositiveProvisions.fragments.generated';
import {
  DispositiveProvisionDirection,
  DispositiveProvisionWithEdges,
  UponDeath,
} from './DispositiveProvisionsByDeath.types';

export function getFirstDeathFilter(
  id: string
): (edge: GetDispositiveProvisions_EstateWaterfallVizEdgeFragment) => boolean {
  return (
    edge: GetDispositiveProvisions_EstateWaterfallVizEdgeFragment
  ): boolean => {
    if (edge.to.id !== id) return false;

    if (edge.kind === EstateWaterfallEdgeKind.Disposition)
      return edge.to.afterDeath === AfterDeath.First;

    if (edge.kind === EstateWaterfallEdgeKind.Pourover)
      return edge.to.afterDeath === AfterDeath.None;
    return false;
  };
}

export function getSecondDeathFilter(
  id: string
): (edge: GetDispositiveProvisions_EstateWaterfallVizEdgeFragment) => boolean {
  return (
    edge: GetDispositiveProvisions_EstateWaterfallVizEdgeFragment
  ): boolean => {
    if (edge.to.id !== id) return false;

    if (edge.kind === EstateWaterfallEdgeKind.Disposition)
      return edge.to.afterDeath === AfterDeath.Second;

    if (edge.kind === EstateWaterfallEdgeKind.Pourover)
      return edge.to.afterDeath === AfterDeath.First;
    return false;
  };
}

/**
 * since provisions are always described as outbound, use data from the source vizNode
 * to repopulate a temporary provision with the right data
 */
export function getProvisionSourceFromAssociatedVizNode(
  provision: DispositiveProvisions_DispositiveProvisionFragment,
  vizNode: GetDispositiveProvisions_EstateWaterfallVizNodeFragment | undefined
): DispositiveProvisions_DispositiveProvisionFragment {
  const newProvision = {
    ...provision,
  };

  delete newProvision.individual;
  delete newProvision.entity;
  delete newProvision.testamentaryEntity;
  delete newProvision.organization;

  // since all the fields are selected as a type `Node`, all will always be present and
  // have their common `.id` field set, regardless of the resolved type; instead, check the
  // `__typename` field to narrow to the actual type of the node, and then use that value
  if (vizNode?.testamentaryEntity.__typename === 'TestamentaryEntity') {
    newProvision.testamentaryEntity = vizNode.testamentaryEntity;
  } else if (vizNode?.entity.__typename === 'Entity') {
    newProvision.entity = vizNode.entity;
  } else if (vizNode?.individual.__typename === 'ClientProfile') {
    newProvision.individual = vizNode.individual;
  } else if (vizNode?.organization.__typename === 'ClientOrganization') {
    newProvision.organization = vizNode.organization;
  } else {
    throw new Error(
      `No source found for provision ${provision.id} on vizNode ${vizNode?.id}`
    );
  }

  return newProvision;
}

export function getCaptionText(
  direction: DispositiveProvisionDirection,
  hasAnyProvisionsInView: boolean
): string {
  if (!hasAnyProvisionsInView) {
    if (direction === DispositiveProvisionDirection.Receiving) {
      return 'This entity does not receive any distributions.';
    } else {
      return 'This entity does not make any distributions.';
    }
  }

  if (direction === DispositiveProvisionDirection.Receiving) {
    return 'This entity receives the following distributions.';
  } else {
    return 'This entity distributes to the following entities and people.';
  }
}

function mapWaterfallEdgesToDispositiveProvisionWithEdges(
  edges: GetDispositiveProvisions_EstateWaterfallVizEdgeFragment[]
): DispositiveProvisionWithEdges[] {
  return edges.reduce((acc, edge) => {
    const provisions =
      edge.associatedDispositiveProvisions ||
      edge.associatedPouroverDispositions ||
      [];

    const provisionsWithEdges = compact(
      provisions
    ).map<DispositiveProvisionWithEdges>(({ dispositiveProvision, value }) => ({
      dispositiveProvision,
      value,
      from: edge.from,
      to: edge.to,
    }));

    return acc.concat(provisionsWithEdges);
  }, [] as DispositiveProvisionWithEdges[]);
}

function getDispositionsFromScenario(
  provisionList:
    | DispositiveProvisions_DispositionScenarioFragment['dispositiveProvisions']
    | undefined,
  waterfallEdges: GetDispositiveProvisions_EstateWaterfallVizEdgeFragment[]
): DispositiveProvisionWithEdges[] {
  return getNodes(provisionList)
    .sort((a, b) => a.dispositionOrder - b.dispositionOrder)
    .map((dispositiveProvision) => {
      const waterfallEdge = waterfallEdges?.find((edge) =>
        edge.associatedDispositiveProvisions?.find(
          (adp) => adp?.dispositiveProvision.id === dispositiveProvision.id
        )
      );
      return {
        dispositiveProvision,
        value: waterfallEdge?.value || new Decimal(0),
        from: waterfallEdge?.from,
        to: waterfallEdge?.to,
      };
    });
}

export function getDispositiveProvisionsWithEdgesByDeath(
  waterfallEdges: GetDispositiveProvisions_EstateWaterfallVizEdgeFragment[],
  currentNodeId: string,
  direction: DispositiveProvisionDirection,
  dispositionScenario?:
    | DispositiveProvisions_DispositionScenarioFragment
    | undefined
): Record<UponDeath, DispositiveProvisionWithEdges[]> {
  if (direction === DispositiveProvisionDirection.Receiving) {
    const waterfallEdgesForId: GetDispositiveProvisions_EstateWaterfallVizEdgeFragment[] =
      waterfallEdges.filter((edge) => {
        if (edge.from.id !== currentNodeId && edge.to.id !== currentNodeId)
          return false;

        if (
          edge.kind === EstateWaterfallEdgeKind.Disposition &&
          !!edge.associatedDispositiveProvisions
        ) {
          return true;
        }

        if (
          edge.kind === EstateWaterfallEdgeKind.Pourover &&
          !!edge.associatedPouroverDispositions
        ) {
          return true;
        }

        return false;
      });

    // receiving edges are represented in the viz nodes after the death, so look head to the next death
    return {
      uponFirstDeath: mapWaterfallEdgesToDispositiveProvisionWithEdges(
        waterfallEdgesForId.filter(getFirstDeathFilter(currentNodeId))
      ),
      uponSecondDeath: mapWaterfallEdgesToDispositiveProvisionWithEdges(
        waterfallEdgesForId.filter(getSecondDeathFilter(currentNodeId))
      ),
    };
  }

  const { firstDeathProvisions, secondDeathProvisions } =
    getProvisionsByDeathForScenario(dispositionScenario);

  // ...while distributing edges are represented in the viz nodes before the death and get sorted
  return {
    uponFirstDeath: getDispositionsFromScenario(
      firstDeathProvisions,
      waterfallEdges
    ),
    uponSecondDeath: getDispositionsFromScenario(
      secondDeathProvisions,
      waterfallEdges
    ),
  };
}
