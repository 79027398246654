import { Button } from '@/components/form/baseInputs/Button';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';

export function ManageDispositionsButton() {
  const { householdId } = useHouseholdDetailsContext();
  const { navigate } = useNavigateToRoute();
  if (!householdId) {
    return null;
  }

  return (
    <Button
      data-testid="ManageDispositionsButton"
      variant="secondary"
      size="sm"
      onClick={() =>
        navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS, {
          householdId,
        })
      }
    >
      Manage dispositions
    </Button>
  );
}
