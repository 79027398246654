import Decimal from 'decimal.js';

import { MatchableField } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import { EntityType } from '@/modules/entities/types/EntityType';
import { KeyPersonRoleKind } from '@/types/schema';
import { PathsOf } from '@/types/subform';

export const BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE =
  'businessEntityDetailsSubform' as const;
export type BusinessEntityDetailsSubformNamespaceType =
  typeof BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE;
const VARIANTS = ['default'] as const;
export type VariantType = (typeof VARIANTS)[number];

export const BUSINESS_OWNERSHIP_PERCENTAGE_DECIMAL_SCALE = 5;

export interface BusinessEntityDetails_Owner extends MatchableField {
  ownerId: string;
  percentOwned?: Decimal | null;
}

export interface BusinessEntityDetails_KeyPerson extends MatchableField {
  keyPersonId: string;
  roles: KeyPersonRoleKind[];
}

// this is the type of the subform's values
export interface BusinessEntityDetailsSubformType {
  keyPeople: BusinessEntityDetails_KeyPerson[];
  ownershipAsOfDate: Date | null;
  keyPeopleNotes: string;
  owners: BusinessEntityDetails_Owner[];

  // this input is a computed value used to expose an error-aware calculated value to other subforms;
  // it is not entered directly by the user and is not intended to be submitted to the backend
  // as part of the form.
  _ownershipSum: Decimal | null;
}

// this is what the subform's values will be nested under in the parent form
export interface Fields {
  [BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE]: BusinessEntityDetailsSubformType;
}

export type SubformField = PathsOf<Fields>;

// this is Props for the UI component
export interface Props {
  variant: VariantType;
  householdId: string;
  // can be undefined in the case of a new entity
  entityId?: string;
  entityType?: EntityType;
}

export const defaultValues: BusinessEntityDetailsSubformType = {
  owners: [{ ownerId: '', percentOwned: null }],
  keyPeople: [{ keyPersonId: '', roles: [] }],
  keyPeopleNotes: '',
  ownershipAsOfDate: null,
  _ownershipSum: null,
};
