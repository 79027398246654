import { Stack, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import {
  AddableType,
  AddNewAnythingModal,
} from '@/modules/common/AddNewAnythingModal/AddNewAnythingModal';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import {
  GetClientDetails_HouseholdFragment,
  useGetClientDetailsQuery,
} from '@/modules/household/contexts/graphql/GetClientDetails.generated';
import { TrustTaxStatus } from '@/types/schema';

import {
  useCharitableTrustDesignerContext,
  useIsCRT,
} from '../CharitableTrustDesignerContext';
import {
  CharitableBeneficiariesCard,
  NonCharitableBeneficiariesCard,
} from './CharitableTrustDesignerBasicInformation.components';
import {
  CharitableTrustDesignerBasicInformationDonor,
  CharitableTrustDesignerBasicInformationForm,
  CharitableTrustDesignerBasicInformationFormPaths,
  NAMESPACE,
} from './CharitableTrustDesignerBasicInformation.types';
import { mapBeneficiaryDataToDisplay } from './CharitableTrustDesignerBasicInformation.utils';
import { useCharitableTrustDesignerBeneficiariesQuery } from './graphql/CharitableTrustDesignerBeneficiaries.generated';

export function CharitableTrustDesignerBasicInformationColumn() {
  const { control } =
    useFormContext<CharitableTrustDesignerBasicInformationForm>();
  const isCRT = useIsCRT();

  const { householdId } = useCharitableTrustDesignerContext();
  const { data } = useGetClientDetailsQuery({
    variables: {
      householdId,
    },
  });

  const household =
    data?.household as GetClientDetails_HouseholdFragment | null;
  const possibleDonors = useMemo<SelectInputOption<string>[]>(() => {
    return (
      household?.possiblePrimaryClients.map<SelectInputOption<string>>(
        (possibleGrantor) => ({
          value: possibleGrantor.id,
          display: possibleGrantor.legalName,
        })
      ) || []
    );
  }, [household?.possiblePrimaryClients]);

  const donorIdsFieldName =
    `${NAMESPACE}.donors` as const satisfies CharitableTrustDesignerBasicInformationFormPaths;
  const selectedDonors = useWatch<CharitableTrustDesignerBasicInformationForm>({
    control,
    name: donorIdsFieldName,
  }) as CharitableTrustDesignerBasicInformationDonor[];

  return (
    <>
      <Typography variant="h2">Basic information</Typography>
      <Card variant="outlined" sx={{ p: 3, pb: 4 }}>
        <Stack>
          <FormLayoutRow columns={1}>
            <FormLayoutItem width={1}>
              <FormAwareTextInput
                control={control}
                fieldName={
                  `${NAMESPACE}.name` as const satisfies CharitableTrustDesignerBasicInformationFormPaths
                }
                label="Name"
                required
                helpText="Enter the name used in client proposals."
              />
            </FormLayoutItem>
          </FormLayoutRow>
          {!isCRT && (
            <FormLayoutRow columns={1}>
              <FormLayoutItem width={1}>
                <FormAwareSelectInput<CharitableTrustDesignerBasicInformationForm>
                  control={control}
                  fieldName={
                    `${NAMESPACE}.taxStatus` as const satisfies CharitableTrustDesignerBasicInformationFormPaths
                  }
                  label="Tax status"
                  required
                  options={[
                    {
                      display: 'Grantor trust',
                      value: TrustTaxStatus.GrantorTrust,
                    },
                    {
                      display: 'Non-grantor trust',
                      value: TrustTaxStatus.NonGrantorTrust,
                    },
                  ]}
                />
              </FormLayoutItem>
            </FormLayoutRow>
          )}
          <FormLayoutRow columns={1}>
            <FormLayoutItem width={1}>
              <FormAwareInputRepeater<CharitableTrustDesignerBasicInformationForm>
                control={control}
                name={
                  `${NAMESPACE}.donors` as const satisfies CharitableTrustDesignerBasicInformationFormPaths
                }
                emptyValue={{ id: '' }}
                addAnotherItemText="Add donor"
                maxItems={possibleDonors.length}
                render={(i: number) => (
                  <FormAwareSelectInput<CharitableTrustDesignerBasicInformationForm>
                    control={control}
                    required
                    fieldName={
                      `${NAMESPACE}.donors.${i}.id` as const satisfies CharitableTrustDesignerBasicInformationFormPaths
                    }
                    options={possibleDonors.filter(
                      ({ value }) =>
                        !selectedDonors.find(
                          ({ id }, index) =>
                            id === value && id !== selectedDonors[index]?.id
                        )
                    )}
                    label="Donor"
                    hideLabel={i >= 1}
                  />
                )}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </Stack>
      </Card>
    </>
  );
}
export function CharitableTrustDesignerBeneficiariesColumn() {
  const isCRT = useIsCRT();
  const { householdId } = useCharitableTrustDesignerContext();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const variables = useMemo(
    () => ({
      where: {
        id: householdId,
      },
    }),
    [householdId]
  );
  const { data, refetch } = useCharitableTrustDesignerBeneficiariesQuery({
    variables,
    onError: (error) => {
      showFeedback(
        'Could not display the list of beneficiaries.  Please refresh the page and try again.'
      );
      reportError(
        'Caught error while loading beneficiaries for chartiable trust designer',
        error
      );
    },
  });

  const { nonCharitableBeneficiaries, charitableBeneficiaries } = useMemo(
    () => mapBeneficiaryDataToDisplay(data),
    [data]
  );

  const [addableTypes, setAddableTypes] = useState<AddableType[]>([]);

  const handleAddNewAnythingModalClose = useCallback(async () => {
    setAddableTypes([]);
    await refetch(variables);
  }, [refetch, variables]);

  const nonCharitableCard = (
    <NonCharitableBeneficiariesCard
      key="nonCharitable"
      options={nonCharitableBeneficiaries}
      onClickAddNew={() => setAddableTypes(['individual', 'entity'])}
    />
  );
  const charitableCard = (
    <CharitableBeneficiariesCard
      key="charitable"
      options={charitableBeneficiaries}
      onClickAddNew={() => setAddableTypes(['organization', 'entity'])}
    />
  );

  const cards = isCRT ? (
    <>
      {nonCharitableCard}
      {charitableCard}
    </>
  ) : (
    <>
      {charitableCard}
      {nonCharitableCard}
    </>
  );

  return (
    <>
      <AddNewAnythingModal
        handleClose={handleAddNewAnythingModalClose}
        onAfterCreate={handleAddNewAnythingModalClose}
        isOpen={addableTypes.length > 0}
        householdId={householdId}
        addableTypes={addableTypes}
      />
      <Stack direction="row">
        <Typography variant="h2">Beneficiaries</Typography>
        <ContextualHelpTooltip>
          <PopperContent body="Optionally enter the beneficiaries for this trust. The analysis can be run without specifying beneficiaries." />
        </ContextualHelpTooltip>
      </Stack>
      <Stack spacing={3}>
        <>{cards}</>
      </Stack>
    </>
  );
}
