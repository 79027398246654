import React from 'react';

import { PopperContent } from '@/components/poppers/PopperContent';

export function EstateWaterfallSecondGrantorDeath() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body="Must be greater than or equal to 1st client death year"
    />
  );
}
