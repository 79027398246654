import { ApolloError } from '@apollo/client';
import { noop, upperFirst } from 'lodash';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Loader } from '@/components/progress/Loader/Loader';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useOnBeforeUnloadPrompt } from '@/hooks/useOnBeforeUnloadPrompt';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import {
  AugmentedCreateLoggedTransferInput,
  AugmentedUpdateLoggedTransferInput,
  EntityKind,
} from '@/types/schema';

import { TransferOption, TransferOptionType } from '../TransferOption.type';
import { useCreateLoggedTransferMutation } from './graphql/CreateLoggedTransfer.generated';
import { useDeleteLoggedTransferMutation } from './graphql/DeleteLoggedTransfer.generated';
import { useUpdateLoggedTransferMutation } from './graphql/UpdateLoggedTransfer.generated';
import { getDefaultValues } from './LogNewTransferModal.constants';
import { LogNewTransferModalForm } from './LogNewTransferModal.form';
import {
  getTransferTypeDisplay,
  LogNewTransferForm,
  TransferModalContext,
  TransferType,
} from './LogNewTransferModal.types';
import {
  getCreateLoggedTransferInput,
  getUpdateLoggedTransferInput,
  useSyncDataToForm,
} from './LogNewTransferModal.utils';

interface LogNewTransferModalInnerProps {
  isOpen: boolean;
  onClose: () => void;
}

interface LogNewTransferModalProps
  extends Omit<LogNewTransferModalInnerProps, 'setInitiatorEntityKind'> {
  transferType: TransferType;
  initiatorId: string;
  initiatorKind: TransferOptionType;
  transferId?: string;
  householdId: string;
  initiatorEntityKind: EntityKind | null;
  isInboundDistribution?: boolean;
}

function LogNewTransferInner({
  isOpen,
  onClose,
}: LogNewTransferModalInnerProps) {
  const {
    transferType,
    initiatorId,
    initiatorKind,
    transferId,
    isInboundDistribution,
  } = useContext(ModalTypeContext);
  const trackUserEvent = useTrackUserEvent();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { formRef, handleSave } = useFormSaveHandler();
  const { loading: loadingSyncDataToForm } = useSyncDataToForm(
    transferId || null,
    transferType,
    initiatorId,
    {
      skipQuery: !isOpen,
    }
  );
  const isEdit = !!transferId;

  const { formState, handleSubmit, reset } =
    useFormContext<LogNewTransferForm>();

  useOnBeforeUnloadPrompt(formState.isDirty && isOpen);

  const transferTypeCopy = getTransferTypeDisplay(
    isInboundDistribution ? TransferType.Distribution : transferType
  );

  const [createLoggedTransfer] = useCreateLoggedTransferMutation({
    refetchQueries: 'active',
    onError: (error: ApolloError) => {
      showFeedback(
        `We weren't able to add this ${transferTypeCopy}.  Please refresh the page and try again.`
      );
      reportError(`could not create ${transferTypeCopy}`, error, {
        entityId: initiatorId,
      });
    },
    onCompleted: () => {
      showFeedback(`${upperFirst(transferTypeCopy)} created successfully`, {
        variant: 'success',
      });
    },
  });

  const [updateLoggedTransfer] = useUpdateLoggedTransferMutation({
    refetchQueries: 'active',
    onError: (error: ApolloError) => {
      showFeedback(
        `We weren't able to update this ${transferTypeCopy}.  Please refresh the page and try again.`
      );
      reportError(`could not update ${transferTypeCopy}`, error, {
        entityId: initiatorId,
        transferId,
      });
    },
    onCompleted: () => {
      showFeedback('Transfer updated successfully', {
        variant: 'success',
      });
    },
  });

  const [deleteLoggedTransfer, { loading: isDeleting }] =
    useDeleteLoggedTransferMutation({
      refetchQueries: 'active',
      onError: (error: ApolloError) => {
        showFeedback(
          "We weren't able to delete this transfer.  Please refresh the page and try again."
        );
        reportError('could not delete transfer', error, {
          entityId: initiatorId,
          transferId,
        });
      },
      onCompleted: () => {
        showFeedback('Transfer deleted successfully', {
          variant: 'success',
        });
        closeModal();
      },
    });

  const onValidSubmission: SubmitHandler<LogNewTransferForm> = async (
    values
  ) => {
    if (isEdit) {
      const updateLoggedTransferInput: AugmentedUpdateLoggedTransferInput =
        getUpdateLoggedTransferInput({
          loggedContributionId: transferId,
          form: values,
          transferType,
          initiatorId,
          initiatorKind,
        });

      // submit update form
      const output = await updateLoggedTransfer({
        variables: {
          input: updateLoggedTransferInput,
        },
      });
      trackUserEvent('transfer updated');
      return output;
    } else {
      const createLifetimeExclusionEventInput: AugmentedCreateLoggedTransferInput =
        getCreateLoggedTransferInput({
          form: values,
          transferType,
          initiatorId,
          initiatorKind,
        });

      const output = await createLoggedTransfer({
        variables: {
          input: createLifetimeExclusionEventInput,
        },
      });

      trackUserEvent('transfer created');

      return output;
    }
  };

  const submitHandler = handleSubmit(onValidSubmission);

  const closeModal = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  useSubmitSuccessHandler(() => {
    closeModal();
  });

  let submitButtonText = `Log new ${transferTypeCopy}`;

  if (isEdit) {
    submitButtonText = 'Save changes';
  }

  const onDelete = useCallback(async () => {
    if (!transferId) {
      throw new Error('No transfer ID found for deletion');
    }
    await deleteLoggedTransfer({
      variables: {
        deleteLoggedTransferId: transferId,
      },
    });
  }, [deleteLoggedTransfer, transferId]);

  const actions = useMemo(() => {
    return (
      <FormModalActions.Provider
        formState={formState}
        isDeleting={isDeleting}
        disabled={loadingSyncDataToForm}
      >
        {isEdit && <FormModalActions.DeleteButton onConfirmDelete={onDelete} />}
        <FormModalActions.CancelButton onClick={closeModal} />
        <FormModalActions.SaveButton onClick={handleSave}>
          {submitButtonText}
        </FormModalActions.SaveButton>
      </FormModalActions.Provider>
    );
  }, [
    closeModal,
    formState,
    handleSave,
    isEdit,
    isDeleting,
    loadingSyncDataToForm,
    onDelete,
    submitButtonText,
  ]);

  const heading = useMemo(
    () => (isEdit ? `Edit ${transferTypeCopy}` : `Log new ${transferTypeCopy}`),
    [isEdit, transferTypeCopy]
  );

  return (
    <FormModal
      isOpen={isOpen}
      heading={heading}
      onClose={closeModal}
      actions={actions}
    >
      {loadingSyncDataToForm ? (
        <Loader
          boxProps={{
            sx: {
              textAlign: 'center',
              my: 3,
            },
          }}
        />
      ) : (
        <form ref={formRef} onSubmit={submitHandler} noValidate>
          <LogNewTransferModalForm />
        </form>
      )}
    </FormModal>
  );
}

export const ModalTypeContext = createContext<TransferModalContext>({
  initiatorId: '',
  initiatorKind: TransferOptionType.Entity,
  transferType: TransferType.Contribution,
  householdId: '',
  isInboundDistribution: false,
  transferOptions: [],
  setTransferOptions: noop,
});

export function LogNewTransferModal({
  transferType,
  initiatorId,
  initiatorKind,
  initiatorEntityKind,
  transferId,
  householdId,
  isInboundDistribution,
  ...props
}: LogNewTransferModalProps) {
  const formMethods = useForm<LogNewTransferForm>({
    defaultValues: getDefaultValues(),
  });

  const [transferOptions, setTransferOptions] = useState<TransferOption[]>([]);

  return (
    <ModalTypeContext.Provider
      value={{
        initiatorId,
        initiatorKind,
        transferType,
        transferId,
        initiatorEntityKind,
        householdId,
        isInboundDistribution: isInboundDistribution ?? false,
        transferOptions,
        setTransferOptions,
      }}
    >
      <FormProvider {...formMethods}>
        <LogNewTransferInner {...props} />
      </FormProvider>
    </ModalTypeContext.Provider>
  );
}
