import {
  Box,
  Divider,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Decimal from 'decimal.js';

import { useChartColorDefinitions } from '@/components/charts/constants';
import { LegendItem } from '@/components/charts/Legend/Legend';
import { ColorBox } from '@/components/display/ColorBox/ColorBox';
import { DiagonalSplitColorBox } from '@/components/display/ColorBox/DiagonalSplitColorBox';
import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';

import { useHouseholdEstateValueMetrics } from './hooks/useHouseholdEstateValueMetrics';
import { EstateValuePieChart } from './HouseholdEstateValueMetrics.utils';
import { HouseholdEstateValueMetricsCard } from './HouseholdEstateValueMetricsCard';

const currencyFormatOptions = {
  notation: 'compact' as const,
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
  currencySign: 'accounting',
};

interface HouseholdEstateValueMetrics {
  totalEstateValue: Decimal;
  inEstateValue: Decimal;
  outOfEstateValue: Decimal;
  outOfEstateCharityValue: Decimal;
  outOfEstateFamilyValue: Decimal;
}

export interface HouseholdEstateValueMetricsProps {
  metrics?: HouseholdEstateValueMetrics | null;
}

export function HouseholdEstateValueMetrics({
  metrics,
}: HouseholdEstateValueMetricsProps) {
  const colors = useChartColorDefinitions();
  const isLessThanMedium = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const { pieChartSeriesData, onePercentOfSum } =
    useHouseholdEstateValueMetrics({
      metrics,
    });

  return (
    <HouseholdEstateValueMetricsCard>
      <Stack
        direction="row"
        divider={
          <Divider sx={{ width: '1px', backgroundColor: COLORS.GRAY[200] }} />
        }
        spacing={3}
      >
        <Stack spacing={1.5} alignItems="center" direction="row">
          <EstateValuePieChart
            pieChartSeriesData={pieChartSeriesData}
            onePercentOfSum={onePercentOfSum}
          />
          <OversizedMetricItem
            title="Net value"
            titleVariant="h4"
            valueVariant="h1"
            valueColor={COLORS.NAVY[600]}
            value={
              metrics
                ? formatCurrency(
                    metrics.totalEstateValue,
                    currencyFormatOptions
                  )
                : EMPTY_CONTENT_HYPHEN
            }
          />
        </Stack>
        <Stack spacing={1.5} alignItems="center" direction="row">
          <ColorBox color={colors.PRIMARY.backgroundColor} width={20} />
          <OversizedMetricItem
            title="In estate"
            titleVariant="h4"
            valueVariant="h1"
            valueColor={COLORS.NAVY[600]}
            value={
              metrics
                ? formatCurrency(metrics.inEstateValue, currencyFormatOptions)
                : EMPTY_CONTENT_HYPHEN
            }
          />
        </Stack>
        <Stack
          spacing={isLessThanMedium ? 1.5 : 3}
          alignItems={isLessThanMedium ? 'start' : 'center'}
          direction={isLessThanMedium ? 'column' : 'row'}
        >
          <Stack spacing={1.5} alignItems="center" direction="row">
            <DiagonalSplitColorBox
              topLeftColor={colors.SECONDARY.backgroundColor}
              bottomRightColor={colors.TERTIARY.backgroundColor}
              width={20}
            />
            <OversizedMetricItem
              title="Out of estate"
              titleVariant="h4"
              valueVariant="h1"
              valueColor={COLORS.NAVY[600]}
              value={
                metrics
                  ? formatCurrency(
                      metrics.outOfEstateValue,
                      currencyFormatOptions
                    )
                  : EMPTY_CONTENT_HYPHEN
              }
            />
          </Stack>
          <Stack direction="row">
            <Box width={isLessThanMedium ? 32 : 0} />
            <LegendItem
              primaryText={
                <Typography variant="label">
                  {formatCurrency(
                    metrics?.outOfEstateFamilyValue ?? new Decimal(0),
                    currencyFormatOptions
                  )}
                </Typography>
              }
              secondaryText="Family giving"
              legendItemColor={colors.SECONDARY.backgroundColor}
            />
          </Stack>
          <Stack direction="row">
            <Box width={isLessThanMedium ? 32 : 0} />
            <LegendItem
              primaryText={
                <Typography variant="label">
                  {formatCurrency(
                    metrics?.outOfEstateCharityValue ?? new Decimal(0),
                    currencyFormatOptions
                  )}
                </Typography>
              }
              secondaryText="Charitable giving"
              legendItemColor={colors.TERTIARY.backgroundColor}
            />
          </Stack>
        </Stack>
      </Stack>
    </HouseholdEstateValueMetricsCard>
  );
}
