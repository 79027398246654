import { getCLTTrustSummaryProperties } from '@/modules/entities/details/trusts/CLTTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { TrustFiduciariesSlide } from '../components/Trust.Fiduciaries';
import { CLTTrustPresentationSlideProps } from './CLTTrust.types';

export function CLTTrustFiduciariesSlide({
  entity,
  subtype,
  ...registrationProps
}: CLTTrustPresentationSlideProps) {
  const summaryDetails = getCLTTrustSummaryProperties({
    entity,
    entityType: ENTITY_TYPES.CLT,
  });

  return (
    <TrustFiduciariesSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      displayName={subtype.displayName}
    />
  );
}
