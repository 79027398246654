import dagre from 'dagre';

import { Node, TileNode } from '@/components/diagrams/FlowChart';
import {
  buildDagreGraphFromReactFlow,
  buildNodesFromDagreGraph,
} from '@/components/diagrams/FlowChart/utils/dagre';
import { isNodeEligibleForGraveyard } from '@/modules/graphViz/graphVizNodeConfig/nodeGraveyard.utils';

import { EstateWaterfallState } from '../types';
import { applyDerivedDataToState } from './applyDerivedDataToState';

function hasNonZeroPosition({ position }: Node): boolean {
  return !!(position.x || position.y);
}

/**
 * @description A view config is "fully initialized" as a UI only concept when
 * there are nodes with positions that contain non-zero values
 */
export function hasFullyInitializedNodes(state: EstateWaterfallState) {
  const { derivedData } = applyDerivedDataToState(state);

  // We only check tile nodes, section group position is managed by a different process
  const nodes = derivedData.nodesByType.tile;
  return nodes?.length && nodes.some(hasNonZeroPosition);
}

/**
 * @description Sets the `isNewTile` flag to false for all nodes in the graveyard
 */
function removeNodeFromGraveyard(node: Node): Node {
  if (isNodeEligibleForGraveyard(node)) {
    // We want to remove all nodes from the graveyard
    return { ...node, data: { ...node.data, isNewTile: false } } as TileNode;
  }
  return node;
}

export function applyAutoLayoutToState(
  state: EstateWaterfallState
): EstateWaterfallState {
  const {
    derivedData: { nodesByType },
  } = applyDerivedDataToState(state);

  // Section group layout managed by a different process
  const tileNodes = nodesByType.tile ?? [];
  const tileNodesLeftToRight = [...tileNodes]
    .sort((a, b) => a.position.x - b.position.x)
    .map((n, idx) => ({
      ...n,
      order: idx,
    }));

  const sectionLabelNodes = nodesByType.sectionLabel ?? [];

  const graph = buildDagreGraphFromReactFlow({
    nodes: tileNodesLeftToRight,
    edges: state.edges,
    direction: 'top-bottom',
    graphLayoutConfig: { ranksep: 50, edgesep: 10, nodesep: 25 },
  });

  dagre.layout(graph);

  const nodesFromLayout = buildNodesFromDagreGraph({
    nodes: tileNodesLeftToRight,
    getNodePosition: (id) => graph.node(id),
  }).map(removeNodeFromGraveyard);

  return {
    ...state,
    nodes: [...sectionLabelNodes, ...nodesFromLayout],
  };
}
