import { compact, map, values } from 'lodash';

import {
  HouseholdOnboardingSidebar_HouseholdFragment,
  HouseholdOnboardingSidebar_HouseholdOnboardingStepFragment,
} from './graphql/HouseholdOnboardingSidebar.generated';
import {
  HOUSEHOLD_ONBOARDING_SIDEBAR_STEP_GROUPS,
  HouseholdOnboardingGroupKey,
} from './HouseholdOnboardingSidebar.constants';
import {
  HouseholdOnboardingStepGroupType,
  HouseholdOnboardingStepType,
} from './HouseholdOnboardingSidebar.types';

export function isHouseholdOnboardingStepComplete(
  step: HouseholdOnboardingSidebar_HouseholdOnboardingStepFragment
): boolean {
  return !!step.completedAt;
}

export function mapDataToOnboardingStepGroups(
  household: HouseholdOnboardingSidebar_HouseholdFragment | undefined | null
): HouseholdOnboardingStepGroupType[] {
  const onboardingData = household?.onboarding;
  if (!onboardingData) {
    return [];
  }

  const onboardingSteps = onboardingData?.steps || [];

  const keys = values(HouseholdOnboardingGroupKey);
  return map(keys, (key) => {
    const associatedKeys = HOUSEHOLD_ONBOARDING_SIDEBAR_STEP_GROUPS[key];
    const associatedSteps = associatedKeys.map((stepKey) =>
      onboardingSteps.find(({ kind }) => stepKey === kind)
    );
    const steps: HouseholdOnboardingStepType[] = compact(
      associatedSteps.map((associatedStep) => {
        if (!associatedStep?.kind) {
          return null;
        }

        const output: HouseholdOnboardingStepType = {
          stepId: associatedStep.id,
          kind: associatedStep.kind,
          completed: isHouseholdOnboardingStepComplete(associatedStep),
        };

        return output;
      })
    );

    return { key, steps };
  });
}
