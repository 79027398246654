import Decimal from 'decimal.js';

import { PathsOf } from '@/types/subform';

export const NAMESPACE = 'PRE_TAX_RETURN_LIMIT_MODAL' as const;

export interface PreTaxReturnLimitModalForm {
  [NAMESPACE]: {
    charitableProposalId: string;
    preTaxReturnPercentageHigh: Decimal;
    preTaxReturnPercentageMedium: Decimal;
    preTaxReturnPercentageLow: Decimal;
    taxDragPercentageHigh: Decimal;
    taxDragPercentageMedium: Decimal;
    taxDragPercentageLow: Decimal;
  };
}

export type PreTaxReturnLimitModalFormPaths =
  PathsOf<PreTaxReturnLimitModalForm>;
