import { DispositiveProvisions_DispositionScenarioFragment } from '@/modules/dispositiveProvisions/graphql/DispositiveProvisions.fragments.generated';
import { EntityDetail_EntityFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { BusinessEntityPresentationBundle } from '@/modules/presentation/entities/businessEntities/BusinessEntityPresentationBundle';
import { EntityPresentationBundleProps } from '@/modules/presentation/entities/entityPresentations.types';
import { DonorAdvisedFundPresentationBundle } from '@/modules/presentation/entities/nonTrustCharitableEntities/DonorAdvisedFund/DonorAdvisedFundPresentationBundle';
import { PrivateFoundationPresentationBundle } from '@/modules/presentation/entities/nonTrustCharitableEntities/PrivateFoundation/PrivateFoundationPresentationBundle';
import { CustodialPersonalAccountPresentationBundle } from '@/modules/presentation/entities/personalAccounts/CustodialPersonalAccount/CustodialPersonalAccountPresentationBundle';
import { IndividualPersonalAccountPresentationBundle } from '@/modules/presentation/entities/personalAccounts/IndividualPersonalAccount/IndividualPersonalAccountPresentationBundle';
import { JointPersonalAccountPresentationBundle } from '@/modules/presentation/entities/personalAccounts/JointPersonalAccount/JointPersonalAccountPresentationBundle';
import { QualifiedTuitionPersonalAccountPresentationBundle } from '@/modules/presentation/entities/personalAccounts/QualifiedTuitionPersonalAccount/QualifiedTuitionPersonalAccountPresentationBundle';
import { RetirementPersonalAccountPresentationBundle } from '@/modules/presentation/entities/personalAccounts/RetirementPersonalAccount/RetirementPersonalAccountPresentationBundle';
import { CLTTrustPresentationBundle } from '@/modules/presentation/entities/trusts/CLTTrust/CLTTrustPresentationBundle';
import { CRTTrustPresentationBundle } from '@/modules/presentation/entities/trusts/CRTTrust/CRTTrustPresentationBundle';
import { GRATTrustPresentationBundle } from '@/modules/presentation/entities/trusts/GRATTrust/GRATTrustPresentationBundle';
import { ILITTrustPresentationBundle } from '@/modules/presentation/entities/trusts/ILITTrust/ILITTrustPresentationBundle';
import { QPRTTrustPresentationBundle } from '@/modules/presentation/entities/trusts/QPRTTrust/QPRTTrustPresentationBundle';
import { RevocableIrrevocableTrustPresentationBundle } from '@/modules/presentation/entities/trusts/RevocableTrust/RevocableIrrevocableTrustPresentationBundle';
import { SLATTrustPresentationBundle } from '@/modules/presentation/entities/trusts/SLATTrust/SLATTrustPresentationBundle';
import { UnreachableError } from '@/utils/errors';

import { InsurancePersonalAccountPresentationBundle } from '../personalAccounts/InsurancePersonalAccount/InsurancePersonalAccountPresentationBundle';

type EntityPresentationBundle = EntityPresentationBundleProps<
  EntityDetail_EntityFragment['subtype']
>;
export interface SingleEntityPresentationBundleProps
  extends Omit<EntityPresentationBundle, 'entity' | 'subtype' | ''> {
  // we want to handle the loading state here, in a central place, as opposed to deferring it down to each slide.
  //
  // pros:
  // it's simpler to reason about (everything downstream of this component is guaranteed to have a subtype and entity).
  // this allows us to have logic downstream that can perform logic based on the underlying data.
  //
  // cons:
  // we can't be particularly intelligent about the loading placeholders; for example, we'll show a few loading placeholders
  // here, but we might be wrong about the number of slides to render because that logic is controlled below this.
  subtype: EntityPresentationBundle['subtype'] | null;
  entity: EntityPresentationBundle['entity'] | null;
  dispositionScenarios: DispositiveProvisions_DispositionScenarioFragment[];
  loading?: boolean;
}

export function SingleEntityPresentationBundle({
  ...props
}: SingleEntityPresentationBundleProps) {
  const { subtype, entity, dispositionScenarios } = props;
  if (!entity || !subtype) {
    return null;
  }

  const sharedBundleProps = {
    entity,
    dispositionScenarios,
  };

  switch (subtype.__typename) {
    // Trusts
    case 'IrrevocableTrust':
    case 'RevocableTrust':
      return (
        <RevocableIrrevocableTrustPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'SLATTrust':
      return (
        <SLATTrustPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'QPRTTrust':
      return (
        <QPRTTrustPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'ILITTrust':
      return (
        <ILITTrustPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'CRTTrust':
      return (
        <CRTTrustPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'CLTTrust':
      return (
        <CLTTrustPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'GRATTrust':
      return (
        <GRATTrustPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );

    // Personal Accounts
    case 'IndividualPersonalAccount':
      return (
        <IndividualPersonalAccountPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'JointPersonalAccount':
      return (
        <JointPersonalAccountPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'InsurancePersonalAccount':
      return (
        <InsurancePersonalAccountPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'QualifiedTuitionPersonalAccount':
      return (
        <QualifiedTuitionPersonalAccountPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'RetirementPersonalAccount':
      return (
        <RetirementPersonalAccountPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'CustodialPersonalAccount':
      return (
        <CustodialPersonalAccountPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );

    // Non-trust charitable entities
    case 'DonorAdvisedFund':
      return (
        <DonorAdvisedFundPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'PrivateFoundation':
      return (
        <PrivateFoundationPresentationBundle
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );

    // Business entities
    case 'LLCBusinessEntity':
      return (
        <BusinessEntityPresentationBundle
          entityType={ENTITY_TYPES.LLC}
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'SoleProprietorshipBusinessEntity':
      return (
        <BusinessEntityPresentationBundle
          entityType={ENTITY_TYPES.SOLE_PROPRIETORSHIP}
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'SCorpBusinessEntity':
      return (
        <BusinessEntityPresentationBundle
          entityType={ENTITY_TYPES.S_CORP}
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'CCorpBusinessEntity':
      return (
        <BusinessEntityPresentationBundle
          entityType={ENTITY_TYPES.C_CORP}
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'GPBusinessEntity':
      return (
        <BusinessEntityPresentationBundle
          entityType={ENTITY_TYPES.GP}
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );
    case 'LPBusinessEntity':
      return (
        <BusinessEntityPresentationBundle
          entityType={ENTITY_TYPES.LP}
          {...props}
          subtype={subtype}
          {...sharedBundleProps}
        />
      );

    default:
      if (!subtype.__typename) {
        throw new Error('Unexpected missing subtype typename');
      }

      throw new UnreachableError({
        case: subtype.__typename,
        message: `unknown subtype ${subtype.__typename}`,
      });
  }
}
