// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { GraphViz_MinimalViewFragmentDoc } from '../../../../../modules/graphViz/graphql/GraphVizView.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientDetailsEntityMapQueryVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
}>;


export type ClientDetailsEntityMapQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, entityGraphViews?: Array<{ __typename?: 'EntityGraphView', id: string, displayName: string, kind?: Types.EntityGraphViewKind | null, orientation?: Types.EntityGraphViewOrientation | null }> | null } | null } | null> | null } };


export const ClientDetailsEntityMapDocument = gql`
    query ClientDetailsEntityMap($householdId: ID!) {
  households(where: {id: $householdId}) {
    edges {
      node {
        id
        entityGraphViews {
          ...GraphViz_MinimalView
        }
      }
    }
  }
}
    ${GraphViz_MinimalViewFragmentDoc}`;

/**
 * __useClientDetailsEntityMapQuery__
 *
 * To run a query within a React component, call `useClientDetailsEntityMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsEntityMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsEntityMapQuery({
 *   variables: {
 *      householdId: // value for 'householdId'
 *   },
 * });
 */
export function useClientDetailsEntityMapQuery(baseOptions: Apollo.QueryHookOptions<ClientDetailsEntityMapQuery, ClientDetailsEntityMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientDetailsEntityMapQuery, ClientDetailsEntityMapQueryVariables>(ClientDetailsEntityMapDocument, options);
      }
export function useClientDetailsEntityMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientDetailsEntityMapQuery, ClientDetailsEntityMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientDetailsEntityMapQuery, ClientDetailsEntityMapQueryVariables>(ClientDetailsEntityMapDocument, options);
        }
export function useClientDetailsEntityMapSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientDetailsEntityMapQuery, ClientDetailsEntityMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientDetailsEntityMapQuery, ClientDetailsEntityMapQueryVariables>(ClientDetailsEntityMapDocument, options);
        }
export type ClientDetailsEntityMapQueryHookResult = ReturnType<typeof useClientDetailsEntityMapQuery>;
export type ClientDetailsEntityMapLazyQueryHookResult = ReturnType<typeof useClientDetailsEntityMapLazyQuery>;
export type ClientDetailsEntityMapSuspenseQueryHookResult = ReturnType<typeof useClientDetailsEntityMapSuspenseQuery>;
export type ClientDetailsEntityMapQueryResult = Apollo.QueryResult<ClientDetailsEntityMapQuery, ClientDetailsEntityMapQueryVariables>;
export const Operations = {
  Query: {
    ClientDetailsEntityMap: 'ClientDetailsEntityMap' as const
  }
}