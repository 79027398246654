import { compact } from 'lodash';

import { getTypeOrUndefined } from '@/modules/entities/EntitySubforms/utils/shared/common.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  AugmentedCreateDispositionScenarioInput,
  AugmentedCreateDispositiveProvisionInput,
  AugmentedUpdateDispositionScenarioInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateHouseholdInput,
  AugmentedUpdateTestamentaryEntityInput,
  DispositiveProvisionDispositionKind,
  DispositiveProvisionRecipientKind,
  UpdateTestamentaryEntityInput,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getProvisionsByDeathForScenario } from '@/utils/dispositiveProvisions';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { DispositionScheme } from '../dispositiveProvisions.types';
import {
  mapProvisionToRecipient,
  sortDispositionOrder,
} from '../dispositiveProvisions.utils';
import {
  DispositiveProvisions_DispositionScenarioFragment,
  DispositiveProvisions_DispositiveProvisionFragment,
  DispositiveProvisions_DispositiveProvisionTemplateFragment,
} from '../graphql/DispositiveProvisions.fragments.generated';
import { DISPOSITIVE_PROVISIONS_FORM_NAMESPACE } from './DispositiveProvisionsForm.constants';
import {
  DispositiveProvisionsFormShape,
  Recipient,
  RecipientKind,
  RecipientWithId,
} from './DispositiveProvisionsForm.types';
import { SURVIVING_SPOUSE_SENTINEL } from './hooks/useRecipientOptions';

function getRecipientTypeFromFormInput(recipientId: string) {
  const pulidKind = getPulidKind(recipientId);

  const recipientIds: {
    entityID?: string;
    individualID?: string;
    organizationID?: string;
    testamentaryEntityID?: string;
  } = {};

  if (recipientId === SURVIVING_SPOUSE_SENTINEL) {
    return recipientIds;
  }

  if (!pulidKind) {
    throw new Error('Could not get pulid kind');
  }

  if (pulidKind === PulidKind.Entity) {
    recipientIds.entityID = recipientId;
  } else if (pulidKind === PulidKind.ClientProfile) {
    recipientIds.individualID = recipientId;
  } else if (pulidKind === PulidKind.ClientOrganization) {
    recipientIds.organizationID = recipientId;
  } else if (pulidKind === PulidKind.TestamentaryEntity) {
    recipientIds.testamentaryEntityID = recipientId;
  } else {
    throw new UnreachableError({
      case: pulidKind as never,
      message: 'Unrecognized pulid kind',
    });
  }

  return recipientIds;
}

// Convert the form disposition kind, which is split across two fields
// (dispositionKind_ButtonGroup and dispositionKind_Select), into the API disposition kind (which is a single field
export function $getApiDispositionKind(r: Recipient) {
  const kind = r.dispositionKind_ButtonGroup;

  if (!kind || kind === 'other') {
    const otherKind = r.dispositionKind_Select;

    if (!otherKind) {
      throw new Error('Disposition kind is required when other is selected');
    }

    return otherKind;
  }

  return kind;
}

export type GetCreateDispositiveProvisionsInputOpts =
  | {
      entityId: string;
      entityType: EntityType;
      entitySubtypeId: string;
      isOnHypotheticalWaterfall: boolean;
      waterfallId?: string;
    }
  | {
      testamentaryEntityId: string;
      isOnHypotheticalWaterfall: boolean;
      waterfallId?: string;
    }
  | {
      clientProfileId: string;
      householdId: string;
      isOnHypotheticalWaterfall: boolean;
      waterfallId?: string;
    };

export enum NodesWithDispositiveProvisions {
  Entity = 'entity',
  TestamentaryEntity = 'testamentaryEntity',
  ClientProfile = 'clientProfile',
}

type DispositiveProvisionsInput =
  | {
      type: NodesWithDispositiveProvisions.TestamentaryEntity;
      input: AugmentedUpdateTestamentaryEntityInput;
    }
  | {
      type: NodesWithDispositiveProvisions.Entity;
      input: AugmentedUpdateEntityInput;
    }
  | {
      type: NodesWithDispositiveProvisions.ClientProfile;
      input: [AugmentedUpdateHouseholdInput];
    };

export function isRecipientWithId(
  recipient: Recipient
): recipient is RecipientWithId {
  return !!recipient.recipientId;
}

export const makeCreateProvisionInputFromRecipient = (
  r: RecipientWithId,
  order: number,
  opts: GetCreateDispositiveProvisionsInputOpts
): AugmentedCreateDispositiveProvisionInput => {
  const { entityID, individualID, organizationID, testamentaryEntityID } =
    getRecipientTypeFromFormInput(r.recipientId);

  const { isOnHypotheticalWaterfall, waterfallId } = opts;

  if (!r.transferTaxKind) {
    throw new Error('Transfer tax kind is required');
  }
  return {
    create: {
      dispositionAmount: r.dispositionAmount,
      dispositionPercentage: r.dispositionPercentage,
      dispositionKind: $getApiDispositionKind(r),
      dispositionOrder: order,
      notes: r.notes,
      transferTaxKind: r.transferTaxKind,
      recipientKind:
        r.recipientId === SURVIVING_SPOUSE_SENTINEL
          ? DispositiveProvisionRecipientKind.SurvivingSpouse
          : undefined,
      entityID,
      individualID,
      organizationID,
      testamentaryEntityID,
      // TODO figure out how important it is to have DPs associated with multiple different
      // hypothetical waterfalls?s
      associatedHypotheticalWaterfallID: isOnHypotheticalWaterfall
        ? waterfallId
        : undefined,
    },
  };
};

function makeDispositiveProvisionsInputs(
  scenarioFormValues: DispositiveProvisionsFormShape['dispositiveProvisions'],
  opts: GetCreateDispositiveProvisionsInputOpts
): {
  withDispositiveProvisions: AugmentedCreateDispositiveProvisionInput[];
  withSecondDeathDispositiveProvisions: AugmentedCreateDispositiveProvisionInput[];
} {
  const { dispositionScheme, templateId } = scenarioFormValues;

  // if a template is used, don't create provisions, as the ones from the template will be used instead
  if (templateId) {
    return {
      withDispositiveProvisions: [],
      withSecondDeathDispositiveProvisions: [],
    };
  }

  const inputs = compact(
    scenarioFormValues.recipients?.map((r, idx) => {
      // if no recipient is selected, don't include then
      if (!isRecipientWithId(r)) return null;
      return makeCreateProvisionInputFromRecipient(r, idx, opts);
    }) ?? []
  );

  return {
    withDispositiveProvisions:
      dispositionScheme === DispositionScheme.UPON_FIRST_DEATH ? inputs : [],
    withSecondDeathDispositiveProvisions:
      dispositionScheme === DispositionScheme.UPON_SECOND_DEATH ? inputs : [],
  };
}

// there's no "delete" with disposition scenarios because there's always one per primary client,
// and we automatically delete them on the backend when we delete the client (if that happens)
type WithDispositionScenariosReturn =
  | { type: 'update'; input: AugmentedUpdateDispositionScenarioInput }
  | { type: 'create'; input: AugmentedCreateDispositionScenarioInput };

function makeDispositionScenariosInputs(
  formValues: DispositiveProvisionsFormShape['dispositiveProvisions'],
  initialFormValues:
    | DispositiveProvisionsFormShape['dispositiveProvisions']
    | null,
  opts: GetCreateDispositiveProvisionsInputOpts
): WithDispositionScenariosReturn {
  const { withDispositiveProvisions, withSecondDeathDispositiveProvisions } =
    makeDispositiveProvisionsInputs(formValues, opts);

  const sharedProperties = {
    firstGrantorDeathID: formValues.selectedFirstPrimaryClientDeathId,
    associatedHypotheticalWaterfallID: opts.isOnHypotheticalWaterfall
      ? opts.waterfallId
      : undefined,
    reviewedAt: new Date(),
    dispositiveProvisionsTemplateID:
      formValues.dispositionScheme === DispositionScheme.UPON_FIRST_DEATH
        ? formValues.templateId
        : undefined,
    secondDeathDispositiveProvisionsTemplateID:
      formValues.dispositionScheme === DispositionScheme.UPON_SECOND_DEATH
        ? formValues.templateId
        : undefined,
  };

  const shouldCreateNewHypotheticalScenario = (() => {
    if (!opts.isOnHypotheticalWaterfall) return false;
    return (
      opts.waterfallId !==
      initialFormValues?._dispositionScenarioAssociatedWaterfallId
    );
  })();

  if (
    formValues.dispositionScenarioId &&
    !shouldCreateNewHypotheticalScenario
  ) {
    return {
      type: 'update',
      input: {
        id: formValues.dispositionScenarioId,
        update: {
          ...sharedProperties,
          clearDispositiveProvisions: true,
          clearSecondDeathDispositiveProvisions: true,
          clearAssociatedHypotheticalWaterfall:
            !sharedProperties.associatedHypotheticalWaterfallID,
          clearDispositiveProvisionsTemplate:
            sharedProperties.dispositiveProvisionsTemplateID ? undefined : true,
          clearSecondDeathDispositiveProvisionsTemplate:
            sharedProperties.secondDeathDispositiveProvisionsTemplateID
              ? undefined
              : true,
        },
        withDispositiveProvisions,
        withSecondDeathDispositiveProvisions,
      },
    };
  }

  const { firstGrantorDeathID } = sharedProperties;

  if (!firstGrantorDeathID) {
    throw new Error('First grantor death ID is required');
  }

  return {
    type: 'create',
    input: {
      create: {
        ...sharedProperties,
        firstGrantorDeathID,
      },
      withDispositiveProvisions,
      withSecondDeathDispositiveProvisions,
    },
  };
}

function getTestamentaryEntityDispositiveProvisionsInput(
  formValues: DispositiveProvisionsFormShape['dispositiveProvisions'],
  initialFormValues:
    | DispositiveProvisionsFormShape['dispositiveProvisions']
    | null,
  opts: GetCreateDispositiveProvisionsInputOpts
): DispositiveProvisionsInput {
  if (!('testamentaryEntityId' in opts)) {
    throw new Error('Testamentary entity id is required');
  }
  const { testamentaryEntityId, isOnHypotheticalWaterfall } = opts;

  const update: UpdateTestamentaryEntityInput = {
    // anything handled at the top level by provisions has been moved to the scenario
    clearDispositiveProvisions: true,
    clearHypotheticalDispositiveProvisions: true,
    clearHypotheticalDispositiveProvisionsTemplates: true,
  };

  const { type, input } = makeDispositionScenariosInputs(
    formValues,
    initialFormValues,
    opts
  );

  const withDispositionScenariosInput = isOnHypotheticalWaterfall
    ? {
        withHypotheticalDispositionScenarios:
          type === 'create' ? [input] : undefined,
        updateHypotheticalDispositionScenarios:
          type === 'update' ? [input] : undefined,
      }
    : {
        withDispositionScenarios: type === 'create' ? [input] : undefined,
        updateDispositionScenarios: type === 'update' ? [input] : undefined,
      };

  if (
    !withDispositionScenariosInput.withDispositionScenarios?.length &&
    !withDispositionScenariosInput.updateDispositionScenarios?.length &&
    !withDispositionScenariosInput.withHypotheticalDispositionScenarios
      ?.length &&
    !withDispositionScenariosInput.updateHypotheticalDispositionScenarios
      ?.length
  ) {
    diagnostics.error(
      `No disposition scenarios present on input for ${opts.testamentaryEntityId}`,
      undefined,
      {
        ...opts,
        templateId: formValues.templateId,
        dispositionScheme: formValues.dispositionScheme,
      }
    );
  }

  return {
    type: NodesWithDispositiveProvisions.TestamentaryEntity,
    input: {
      id: testamentaryEntityId,
      update: {
        ...update,
        reviewedAt: new Date(),
        dispositiveProvisionsTemplateID: getTypeOrUndefined(
          formValues.templateId
        ),
        clearDispositiveProvisionsTemplate: formValues.templateId
          ? undefined
          : true,
      },
      ...withDispositionScenariosInput,
    },
  };
}

function getClientProfileDispositiveProvisionsInput(
  formValues: DispositiveProvisionsFormShape['dispositiveProvisions'],
  initialFormValues:
    | DispositiveProvisionsFormShape['dispositiveProvisions']
    | null,
  opts: GetCreateDispositiveProvisionsInputOpts
): DispositiveProvisionsInput {
  if (!('clientProfileId' in opts)) {
    throw new Error(
      'Invalid data passed to getClientProfileDispositiveProvisionsInput'
    );
  }
  const { householdId, clientProfileId, isOnHypotheticalWaterfall } = opts;

  const { type, input } = makeDispositionScenariosInputs(
    formValues,
    initialFormValues,
    opts
  );

  const withDispositionScenariosInput = isOnHypotheticalWaterfall
    ? {
        withHypotheticalDispositionScenarios:
          type === 'create' ? [input] : undefined,
        updateHypotheticalDispositionScenarios:
          type === 'update' ? [input] : undefined,
      }
    : {
        withDispositionScenarios: type === 'create' ? [input] : undefined,
        updateDispositionScenarios: type === 'update' ? [input] : undefined,
      };

  return {
    type: NodesWithDispositiveProvisions.ClientProfile,
    input: [
      {
        id: householdId,
        update: {},
        updateClientProfiles: [
          {
            id: clientProfileId,
            update: {},
            ...withDispositionScenariosInput,
          },
        ],
      },
    ],
  };
}

function getEntityDispositiveProvisionsInput(
  formValues: DispositiveProvisionsFormShape['dispositiveProvisions'],
  initialFormValues:
    | DispositiveProvisionsFormShape['dispositiveProvisions']
    | null,
  opts: GetCreateDispositiveProvisionsInputOpts
): DispositiveProvisionsInput {
  if (!('entityId' in opts)) {
    throw new Error(
      'Invalid data passed to getEntityDispositiveProvisionsInput'
    );
  }
  const { entityId, entityType, entitySubtypeId } = opts;

  const sharedProperties = {
    id: entityId,
    update: {},
  };

  const { type, input } = makeDispositionScenariosInputs(
    formValues,
    initialFormValues,
    opts
  );

  const withDispositionScenariosInput = opts.isOnHypotheticalWaterfall
    ? {
        withHypotheticalDispositionScenarios:
          type === 'create' ? [input] : undefined,
        updateHypotheticalDispositionScenarios:
          type === 'update' ? [input] : undefined,
      }
    : {
        withDispositionScenarios: type === 'create' ? [input] : undefined,
        updateDispositionScenarios: type === 'update' ? [input] : undefined,
      };

  const updateEntitySubtypeSharedProperties = {
    id: entitySubtypeId,
    update: {},
    ...withDispositionScenariosInput,
  };

  if (
    (updateEntitySubtypeSharedProperties?.withDispositionScenarios?.length ??
      0) > 2
  ) {
    throw new Error('Too many disposition scenarios. There can only be two.');
  }

  if (
    (updateEntitySubtypeSharedProperties?.withHypotheticalDispositionScenarios
      ?.length ?? 0) > 2
  ) {
    throw new Error(
      'Too many hypothetical disposition scenarios. There can only be two.'
    );
  }

  // TODO here: consider writing directly to the disposition scenarios here, if we're only going to be updating one
  // at a time versus updating both at once as happens currently
  const entityInput: AugmentedUpdateEntityInput = (() => {
    switch (entityType) {
      case 'revocable-trust':
        return {
          ...sharedProperties,
          updateRevocableTrust: updateEntitySubtypeSharedProperties,
        };
      case 'irrevocable-trust':
        return {
          ...sharedProperties,
          updateIrrevocableTrust: updateEntitySubtypeSharedProperties,
        };
      case 'slat':
        return {
          ...sharedProperties,
          updateSlatTrust: updateEntitySubtypeSharedProperties,
        };
      case 'grat':
        return {
          ...sharedProperties,
          updateGratTrust: updateEntitySubtypeSharedProperties,
        };
      case 'qprt':
        return {
          ...sharedProperties,
          updateQprtTrust: updateEntitySubtypeSharedProperties,
        };
      case 'ilit':
        return {
          ...sharedProperties,
          updateIlitTrust: updateEntitySubtypeSharedProperties,
        };
      case 'individual-account':
        return {
          ...sharedProperties,
          updateIndividualPersonalAccount: updateEntitySubtypeSharedProperties,
        };
      case 'joint-account':
        return {
          ...sharedProperties,
          updateJointPersonalAccount: updateEntitySubtypeSharedProperties,
        };
      case 'custodial-account':
        return {
          ...sharedProperties,
          updateCustodialPersonalAccount: updateEntitySubtypeSharedProperties,
        };
      case 'retirement-account':
        return {
          ...sharedProperties,
          updateRetirementPersonalAccount: updateEntitySubtypeSharedProperties,
        };
      case 'qualified-tuition-account':
        return {
          ...sharedProperties,
          updateQualifiedTuitionPersonalAccount:
            updateEntitySubtypeSharedProperties,
        };
      case 'clt':
        return {
          ...sharedProperties,
          updateCltTrust: updateEntitySubtypeSharedProperties,
        };
      case 'crt':
        return {
          ...sharedProperties,
          updateCrtTrust: updateEntitySubtypeSharedProperties,
        };
      case 'daf':
        return {
          ...sharedProperties,
          updateDonorAdvisedFund: updateEntitySubtypeSharedProperties,
        };
      case 'private-foundation':
        return {
          ...sharedProperties,
          updatePrivateFoundation: updateEntitySubtypeSharedProperties,
        };

      case 'insurance-account':
        return {
          ...sharedProperties,
          updateInsurancePersonalAccount: updateEntitySubtypeSharedProperties,
        };

      case 'sole-proprietorship':
      case 'c-corp':
      case 's-corp':
      case 'llc':
      case 'lp':
      case 'gp':
        // https://linear.app/luminary/issue/LUM-1878/add-dispositive-provisions-to-business-entities
        throw new Error('Not implemented');

      default:
        throw new UnreachableError({
          case: entityType,
          message: `Unrecognized entity type ${entityType}`,
        });
    }
  })();

  return {
    type: NodesWithDispositiveProvisions.Entity,
    input: entityInput,
  };
}

export function getCreateDispositiveProvisionsInput(
  form: DispositiveProvisionsFormShape,
  initialForm: DispositiveProvisionsFormShape | undefined,
  opts: GetCreateDispositiveProvisionsInputOpts
): DispositiveProvisionsInput {
  const formValues = form[DISPOSITIVE_PROVISIONS_FORM_NAMESPACE];
  const initialFormValues =
    initialForm?.[DISPOSITIVE_PROVISIONS_FORM_NAMESPACE] ?? null;

  if ('testamentaryEntityId' in opts) {
    return getTestamentaryEntityDispositiveProvisionsInput(
      formValues,
      initialFormValues,
      opts
    );
  }

  if ('clientProfileId' in opts) {
    return getClientProfileDispositiveProvisionsInput(
      formValues,
      initialFormValues,
      opts
    );
  }

  return getEntityDispositiveProvisionsInput(
    formValues,
    initialFormValues,
    opts
  );
}

/**
 * @description Returns the recipient id and kind for a given provision.
 */
export function getRecipientDetails(
  provision: DispositiveProvisions_DispositiveProvisionFragment
): {
  recipientId: string;
  recipientKind: RecipientKind;
} {
  if (
    provision.recipientKind ===
    DispositiveProvisionRecipientKind.SurvivingSpouse
  ) {
    return {
      recipientId: SURVIVING_SPOUSE_SENTINEL,
      recipientKind: RecipientKind.SurvivingSpouse,
    };
  }
  if (provision.entity) {
    return {
      recipientId: provision.entity.id,
      recipientKind: RecipientKind.Entity,
    };
  }

  if (provision.individual) {
    return {
      recipientId: provision.individual.id,
      recipientKind: RecipientKind.Individual,
    };
  }

  if (provision.organization) {
    return {
      recipientId: provision.organization.id,
      recipientKind: RecipientKind.Organization,
    };
  }

  if (provision.testamentaryEntity) {
    return {
      recipientId: provision.testamentaryEntity.id,
      recipientKind: RecipientKind.TestamentaryEntity,
    };
  }

  throw new UnreachableError({
    case: provision as never,
    message: 'Could not find recipient kind',
  });
}

/**
 * @description Returns the disposition kind and disposition kind other for a given provision.
 */
export function getDispositionKind(
  provision: DispositiveProvisions_DispositiveProvisionFragment
): {
  dispositionKind_ButtonGroup: Recipient['dispositionKind_ButtonGroup'];
  dispositionKind_Select: Recipient['dispositionKind_Select'];
} {
  if (provision.dispositionKind) {
    if (
      provision.dispositionKind ===
        DispositiveProvisionDispositionKind.AnythingLeftOver ||
      provision.dispositionKind ===
        DispositiveProvisionDispositionKind.RemainingGstExclusionOfGrantorInExcessOfLifetimeExclusion ||
      provision.dispositionKind ===
        DispositiveProvisionDispositionKind.RemainingLifetimeExclusionOfGrantor ||
      provision.dispositionKind ===
        DispositiveProvisionDispositionKind.RemainingStateExemption ||
      provision.dispositionKind ===
        DispositiveProvisionDispositionKind.RemainingFederalLifetimeExemptionInExcessOfStateExemption
    ) {
      return {
        dispositionKind_ButtonGroup: 'other',
        dispositionKind_Select: provision.dispositionKind,
      };
    }

    if (
      provision.dispositionKind !==
        DispositiveProvisionDispositionKind.Amount &&
      provision.dispositionKind !==
        DispositiveProvisionDispositionKind.Percentage
    ) {
      throw new Error(
        'Invalid disposition kind, expected amount or percentage'
      );
    }

    return {
      dispositionKind_ButtonGroup: provision.dispositionKind,
      dispositionKind_Select: '',
    };
  }

  return {
    dispositionKind_ButtonGroup: DispositiveProvisionDispositionKind.Percentage,
    dispositionKind_Select: '',
  };
}

/**
 * @description Intuits the disposition scheme based on the types of provisions.
 * Used to set the default disposition scheme.
 */
function getDispositionScheme({
  dispositionSchemeForClientProfile,
  provisions,
  dispositiveProvisionsTemplate,
  secondDeathProvisions,
  secondDeathDispositiveProvisionsTemplate,
  isTestamentaryEntity,
}: {
  dispositionSchemeForClientProfile: DispositionScheme | null | undefined;
  provisions: DispositiveProvisions_DispositiveProvisionFragment[];
  dispositiveProvisionsTemplate:
    | DispositiveProvisions_DispositiveProvisionTemplateFragment
    | null
    | undefined;
  secondDeathProvisions: DispositiveProvisions_DispositiveProvisionFragment[];
  secondDeathDispositiveProvisionsTemplate:
    | DispositiveProvisions_DispositiveProvisionTemplateFragment
    | null
    | undefined;
  isTestamentaryEntity: boolean;
}): DispositionScheme {
  if (dispositionSchemeForClientProfile) {
    return dispositionSchemeForClientProfile;
  }

  if (
    (provisions.length > 0 && secondDeathProvisions.length === 0) ||
    dispositiveProvisionsTemplate
  ) {
    return DispositionScheme.UPON_FIRST_DEATH;
  }

  if (
    (provisions.length === 0 && secondDeathProvisions.length > 0) ||
    secondDeathDispositiveProvisionsTemplate
  ) {
    return DispositionScheme.UPON_SECOND_DEATH;
  }

  if (isTestamentaryEntity) {
    return DispositionScheme.UPON_SECOND_DEATH;
  }

  return DispositionScheme.NONE;
}

interface GetInitialValuesParams {
  primaryClientIds: string[];
  dispositionScenarios?: DispositiveProvisions_DispositionScenarioFragment[];
  isClientProfile: boolean;
  clientProfileOrEntityOrTestamentaryEntityId: string;
  firstDeathPrimaryClientId: string;
  isTestamentaryEntity: boolean;
}

/**
 * @description If we are showing dispositions for a client profile, we want to set the disposition scheme associated
 * with the client profile. For example, if the grantor is the first to die, we want to set the disposition scheme
 * to UponFirstDeathOnly and if the grantor dies second, we want to test the disposition scheme to UponSecondDeathOnly.
 */
function getDispositionSchemaForClientProfiles(
  activeClientProfileId: string,
  primaryClientIds: string[]
): Record<string, DispositionScheme> {
  return primaryClientIds.reduce(
    (acc, clientId) => {
      return {
        ...acc,
        [clientId]:
          clientId === activeClientProfileId
            ? DispositionScheme.UPON_FIRST_DEATH
            : DispositionScheme.UPON_SECOND_DEATH,
      };
    },
    {} as Record<string, DispositionScheme>
  );
}

export function getInitialValues({
  primaryClientIds,
  dispositionScenarios,
  isClientProfile,
  clientProfileOrEntityOrTestamentaryEntityId,
  firstDeathPrimaryClientId,
  isTestamentaryEntity,
}: GetInitialValuesParams): DispositiveProvisionsFormShape {
  const dispositionSchemesForClientProfile = (() => {
    if (isClientProfile && clientProfileOrEntityOrTestamentaryEntityId) {
      return getDispositionSchemaForClientProfiles(
        clientProfileOrEntityOrTestamentaryEntityId,
        primaryClientIds
      );
    }

    return null;
  })();

  if (!primaryClientIds[0]) {
    throw new Error(`must be at least one primary client`);
  }

  if (primaryClientIds.length > 2) {
    throw new Error('Too many grantor ids. There can only be two.');
  }

  if (!dispositionScenarios) {
    throw new Error(
      'Disposition scenarios are required if not using dispositive provisions'
    );
  }

  // If we have dispositive provisions, we want to use those to populate the form.
  if (dispositionScenarios.length > 0) {
    // We only support up to two disposition scenarios, i.e., one for each grantor.
    if (dispositionScenarios.length > 2) {
      throw new Error('Too many disposition scenarios. There can only be two.');
    }

    const relevantScenario = dispositionScenarios.find(
      (ds) => ds.firstGrantorDeath.id === firstDeathPrimaryClientId
    );

    const scenarioDispositionScheme = getDispositionScheme({
      provisions: getNodes(relevantScenario?.dispositiveProvisions),
      secondDeathProvisions: getNodes(
        relevantScenario?.secondDeathDispositiveProvisions
      ),
      dispositionSchemeForClientProfile:
        dispositionSchemesForClientProfile?.[firstDeathPrimaryClientId],
      dispositiveProvisionsTemplate:
        relevantScenario?.dispositiveProvisionsTemplate,
      secondDeathDispositiveProvisionsTemplate:
        relevantScenario?.secondDeathDispositiveProvisionsTemplate,
      isTestamentaryEntity,
    });

    const relevantDispositiveProvisions = (() => {
      switch (scenarioDispositionScheme) {
        case DispositionScheme.NONE:
          return null;
        case DispositionScheme.UPON_FIRST_DEATH:
          return (
            getProvisionsByDeathForScenario(relevantScenario)
              ?.firstDeathProvisions ?? null
          );
        case DispositionScheme.UPON_SECOND_DEATH:
          return (
            getProvisionsByDeathForScenario(relevantScenario)
              ?.secondDeathProvisions ?? null
          );
        default:
          throw new UnreachableError({
            case: scenarioDispositionScheme,
            message: `Unhandled case for scenarioDispositionScheme ${scenarioDispositionScheme}`,
          });
      }
    })();

    const recipients = getNodes(relevantDispositiveProvisions)
      .sort(sortDispositionOrder)
      .map(mapProvisionToRecipient);

    return {
      [DISPOSITIVE_PROVISIONS_FORM_NAMESPACE]: {
        dispositionScenarioId: relevantScenario?.id ?? null,
        _dispositionScenarioAssociatedWaterfallId:
          relevantScenario?.associatedHypotheticalWaterfall?.id ?? null,
        _hasBeenReviewed: relevantScenario?.reviewedAt ? true : false,
        selectedFirstPrimaryClientDeathId: firstDeathPrimaryClientId,
        dispositionScheme: scenarioDispositionScheme,
        templateId:
          relevantScenario?.dispositiveProvisionsTemplate?.id ??
          relevantScenario?.secondDeathDispositiveProvisionsTemplate?.id,
        recipients,
      },
    };
  } else if (isTestamentaryEntity) {
    // if the entity is a testamentary entity that has no previous disposition scenarios (something that)
    // should be resolved by an upcoming migration), default to the second death disposition scheme
    return {
      [DISPOSITIVE_PROVISIONS_FORM_NAMESPACE]: {
        dispositionScenarioId: null,
        _dispositionScenarioAssociatedWaterfallId: null,
        selectedFirstPrimaryClientDeathId: firstDeathPrimaryClientId,
        _hasBeenReviewed: false,
        dispositionScheme: DispositionScheme.UPON_SECOND_DEATH,
        recipients: [],
      },
    };
  }

  // Return the default empty form state
  return {
    [DISPOSITIVE_PROVISIONS_FORM_NAMESPACE]: {
      dispositionScenarioId: null,
      _dispositionScenarioAssociatedWaterfallId: null,
      selectedFirstPrimaryClientDeathId: firstDeathPrimaryClientId,
      _hasBeenReviewed: false,
      dispositionScheme: DispositionScheme.NONE,
      recipients: [],
    },
  };
}

export function getDeathOrderCopy(dispositionScheme: DispositionScheme) {
  return dispositionScheme === DispositionScheme.UPON_FIRST_DEATH
    ? '1st death'
    : '2nd death';
}
