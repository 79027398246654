import { useEffect } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Loader } from '@/components/progress/Loader/Loader';
import { DispositiveProvisionsModalBody } from '@/modules/dispositiveProvisions/DispositiveProvisionsSplitScreenModal/DispositiveProvisionsModalBody';
import { useEditEntitySplitScreenModalContext } from '@/modules/entities/EditEntitySplitScreen/contexts/editEntitySplitScreenModal.context';
import { EditEntitySection } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { getGrantorIdForSection } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.utils';
import { EditEntitySplitScreenForm } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenForm';
import { EditEntitySplitScreenFormSectionSwitcher } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenFormSectionSwitcher';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

export function EditEntitySplitScreenRightContent() {
  const { showFeedback } = useFeedback();

  const {
    currentSection,
    formActionsPortalRef,
    initialSubformValues,
    entityValueOverride,
    hasError,
    handleCloseModal,
  } = useEditEntitySplitScreenModalContext();

  const { primaryClients } = useHouseholdDetailsContext();

  const showDPForm =
    currentSection === EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1 ||
    currentSection === EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2;

  useEffect(() => {
    if (!hasError) return;
    showFeedback(
      'Could not load form data. Please refresh the page and try again.'
    );
  }, [hasError, showFeedback]);

  if (showDPForm) {
    // This is responsible for showing its own loading state
    return (
      <DispositiveProvisionsModalBody
        key={currentSection} // force the component to re-render when the section changes
        footerRef={formActionsPortalRef}
        nodeValue={entityValueOverride}
        onClose={handleCloseModal}
        variant="editEntity"
        firstDeathClientId={getGrantorIdForSection(
          currentSection,
          primaryClients
        )}
        topRightContent={
          <EditEntitySplitScreenFormSectionSwitcher
            onSwitchSection={() => {
              // TODO: Trigger submit of the DP form
            }}
          />
        }
      />
    );
  }

  if (!initialSubformValues) {
    // Loading state for the Entity Forms
    return (
      <Loader
        boxProps={{
          sx: {
            textAlign: 'center',
            my: 3,
          },
        }}
      />
    );
  }

  // Only initialize Entity Form's FormProvider/Context once we've finished
  // loading the data. This fixes a bug where we overwrite the initialized
  // FormRepeaters with empty data.
  return (
    <EditEntitySplitScreenForm initialSubformValues={initialSubformValues} />
  );
}
