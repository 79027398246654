import { Box, Stack } from '@mui/material';
import { first } from 'lodash';
import { useMemo } from 'react';
import { FieldValues, useWatch } from 'react-hook-form';

import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FieldNameFromFormShape } from '@/types/react-hook-form';
import { diagnostics } from '@/utils/diagnostics';

import { AssetIntegrationProviders } from './constants';
import {
  useAssetProviderDisplayName,
  useIsAssetProviderIntegrationEnabled,
} from './hooks/useEnabledAssetsIntegrations';
import {
  IntegrationEntitiesTypeahead,
  IntegrationEntitiesTypeaheadProps,
} from './IntegrationEntitiesTypeahead/IntegrationEntitiesTypeahead';
import { IntegrationInputCard } from './IntegrationInputCard';

export type SharedIntegrationEntityTypeaheadProps<
  FormShape extends FieldValues,
> = Omit<
  IntegrationEntitiesTypeaheadProps<FormShape>,
  // These properties are all controlled internally by this component
  'provider' | 'allowAllEntities' | 'notLoggedIn' | 'label' | 'hideLabel'
> & {
  // includeAllEntitiesFieldName is the field name for the switch that controls whether
  // the user can select from entities for any integration_client rather than being limited to the
  // integration_clients that are connected to the household. This is only exposed for Addepar as of
  // 12/2024.
  includeAllEntitiesFieldName: FieldNameFromFormShape<FormShape>;
};

/**
 * @description SharedIntegrationEntityTypeahead is a component that allows the user to select integration_entities
 * for a given integration_client. It's the canonical component that should be used for all asset provider integrations.
 */
export function SharedIntegrationEntityTypeahead<
  FormShape extends FieldValues,
>({
  includeAllEntitiesFieldName,
  control,
  ...typeaheadProps
}: SharedIntegrationEntityTypeaheadProps<FormShape>) {
  const { canConnectToIntegrations, integrationsConnected } =
    useIsAssetProviderIntegrationEnabled();
  const assetProviderName = useAssetProviderDisplayName();
  const allowAllEntities = useWatch({
    control,
    name: includeAllEntitiesFieldName,
  });

  const enabledIntegration = useMemo(() => {
    if (canConnectToIntegrations.length > 1) {
      diagnostics.warn(
        'Multiple enabled integrations found. This should not happen.'
      );
      return first(canConnectToIntegrations);
    }

    if (canConnectToIntegrations.length === 1) {
      return canConnectToIntegrations[0];
    }

    return null;
  }, [canConnectToIntegrations]);

  // If there is no enabled integration, don't show this control.
  // The check for assetProviderName is really just for TS, since it'll be present
  // if there's an enabled integration.
  if (!enabledIntegration || !assetProviderName) {
    return null;
  }

  const isLoggedIn = integrationsConnected.includes(enabledIntegration);
  return (
    <IntegrationInputCard providerName={assetProviderName}>
      <Box width="100%">
        <Stack spacing={2}>
          <IntegrationEntitiesTypeahead<FormShape>
            provider={enabledIntegration}
            notLoggedIn={!isLoggedIn}
            allowAllEntities={allowAllEntities}
            control={control}
            label={`Associated ${assetProviderName} accounts or portfolio groups`}
            hideLabel
            {...typeaheadProps}
          />
          {enabledIntegration === AssetIntegrationProviders.ADDEPAR && (
            <FormAwareSwitch
              labelPosition="right"
              control={control}
              disabled={!isLoggedIn}
              label="Select from all entities across Addepar"
              fieldName={includeAllEntitiesFieldName}
            />
          )}
        </Stack>
      </Box>
    </IntegrationInputCard>
  );
}
