import { Maybe } from 'graphql/jsutils/Maybe';

import { EstateWaterfallVizEdge } from '@/types/schema';

import { EstateWaterfall_EstateWaterfallHypotheticalTransferFragment } from './EstateWaterfall.generated';

export function isRecipientOfDistribution(
  nodeId: string,
  edges: Maybe<{ to: Pick<EstateWaterfallVizEdge['to'], 'id' | 'value'> }[]>
): boolean {
  return !!edges?.some((e) => e.to.id === nodeId && !!e.to.value.toNumber());
}

export function isRecipientOfExternalTransfer(
  nodeId: string,
  nodes: EstateWaterfall_EstateWaterfallHypotheticalTransferFragment[]
): boolean {
  return nodes.some((node) => node.destinationIndividual?.id === nodeId);
}
