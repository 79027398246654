import { Box, Stack, Typography } from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

interface SummaryItemDefinition {
  label: string;
  value: React.ReactNode;
}

interface SummaryItemProps extends SummaryItemDefinition {
  showUnderline: boolean;
}

function SummaryItem({ label, value, showUnderline }: SummaryItemProps) {
  const borderBottomProp = showUnderline
    ? `solid ${COLORS.GRAY[200]} 1px`
    : 'none';
  return (
    <Stack
      direction="row"
      component="li"
      alignItems="center"
      spacing={1}
      sx={{ height: 56, borderBottom: borderBottomProp }}
    >
      <Box width="40%">
        <Typography variant="body1">{label}</Typography>
      </Box>
      <Box width="60%">
        <Typography component="span" variant="h5" fontWeight="bold">
          {value}
        </Typography>
      </Box>
    </Stack>
  );
}

export interface SummaryProps {
  items: SummaryItemDefinition[];
}

export function Summary({ items }: SummaryProps) {
  return (
    <Box component="ul" sx={{ margin: 0, padding: 0 }}>
      {items.map((item, i) => (
        <SummaryItem showUnderline={i !== items.length - 1} {...item} key={i} />
      ))}
    </Box>
  );
}
