import { ComponentProps, useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  DeleteMenuItemWithConfirmationModal,
  DeleteMenuItemWithConfirmationModalProps,
} from '@/components/poppers/MenuPopper/DeleteMenuItemWithConfirmationModal';
import { useReportError } from '@/hooks/useReportError';

import { useDeleteDocumentMutation } from '../../files/DocumentRepresentation/graphql/DocumentRepresentation.generated';

export interface DeleteDocumentMenuItemProps
  extends Partial<DeleteMenuItemWithConfirmationModalProps> {
  documentId: string;
  onDelete?: () => void;
  showDivider?: boolean;
}

export function DeleteDocumentMenuItem({
  documentId,
  onDelete,
  modalProps = {},
  menuItemProps = {},
  showDivider = false,
  ...deleteProps
}: DeleteDocumentMenuItemProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [deleteProposal, { loading: deleting }] = useDeleteDocumentMutation({
    variables: { documentId },
    onError: (error) => {
      reportError(`failed to delete document`, error);
      showFeedback(
        'Failed to delete this document. Please refresh the page and try again.'
      );
    },
    onCompleted: () => {
      showFeedback('Document deleted successfully', { variant: 'success' });
      onDelete?.();
    },
  });

  const combinedMenuItemProps: ComponentProps<
    typeof DeleteMenuItemWithConfirmationModal
  >['menuItemProps'] = useMemo(
    () => ({
      ...menuItemProps,
      muiMenuItemProps: {
        ...menuItemProps.muiMenuItemProps,
        divider: showDivider,
      },
    }),
    [menuItemProps, showDivider]
  );

  return (
    <DeleteMenuItemWithConfirmationModal
      label="Delete"
      onConfirmDelete={() => deleteProposal()}
      {...deleteProps}
      menuItemProps={{ loading: deleting, ...combinedMenuItemProps }}
      modalProps={{
        heading: 'Are you sure you want to delete this document?',
        ...modalProps,
      }}
    />
  );
}
