import { TransferOptionType } from '../TransferOption.type';
import { LogNewTransferForm } from './LogNewTransferModal.types';

export const NAMESPACE = 'logNewContributionDistributionModal';

export const getDefaultValues = () => {
  return {
    [NAMESPACE]: {
      targetId: '',
      targetType: TransferOptionType.Entity,
      amount: null,
      documentIds: [] as string[],
      transactionDate: null,
      purpose: '',
      otherPurpose: '',
    },
  } as const satisfies LogNewTransferForm;
};
