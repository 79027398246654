import { Box, Skeleton, Stack } from '@mui/material';
import { range } from 'lodash';
import { PropsWithChildren } from 'react';
import { FormProvider, SubmitHandler, useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { Card } from '@/components/layout/Card/Card';
import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { Footer } from '@/components/navigation/Footer';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { TableHeaderBar } from '@/components/tables/components/TableHeaderBar/TableHeaderBar';
import { useReportError } from '@/hooks/useReportError';
import { useAssetProviderDisplayName } from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import { useUpdateEntityMutation } from '@/modules/entities/graphql/UpdateEntity.generated';
import { StructuredAssetsSubform_ValuationFragment } from '@/modules/entities/StructuredAssetsSubform/graphql/StructuredAssetsSubform.generated';
import { StructuredAssetsSubform } from '@/modules/entities/StructuredAssetsSubform/StructuredAssetsSubform';
import { STRUCTURED_ASSETS_SUBFORM_NAMESPACE } from '@/modules/entities/StructuredAssetsSubform/StructuredAssetsSubform.types';
import { useAssetIntegrationType } from '@/modules/entities/StructuredAssetsSubform/StructuredAssetsSubform.utils';
import { EntityType } from '@/modules/entities/types/EntityType';

import { AssetIntegrationField } from './AssetIntegrationField';
import {
  FullScreenStructuredAssetsModalForm,
  FullScreenStructuredAssetsModalFormPath,
} from './FullScreenStructuredAssetsModal.types';
import { createUpdateEntityAccountInput } from './FullScreenStructuredAssetsModal.utils';
import { useFullScreenStructuredAssetFormData } from './hooks/useFullScreenStructuredAssetFormData';
import { useValuationIntegrationDetails } from './hooks/useValuationLinkDetails';
import { IntegrationContextBanner } from './IntegrationContextBanner';
import { IntegrationValuationSummary } from './IntegrationValuationSummary';
import { LayoutWithSidePanel } from './LayoutWithSidePanel';
import { ValuationDateField } from './ValuationDateField';

interface FullScreenStructuredAssetsModelProps {
  householdId: string;
  entityType: EntityType;
  entityName: string;
  entityId: string;
  subtypeId: string;
  isOpen: boolean;
  onClose: () => void;
  headerText?: string;
  submitText?: string;
}

const {
  FormComponent: AssetsFormComponent,
  defaultValues: assetsFormDefaultValues,
} = new StructuredAssetsSubform();

const initialFormValues: FullScreenStructuredAssetsModalForm = {
  [STRUCTURED_ASSETS_SUBFORM_NAMESPACE]: assetsFormDefaultValues,
  valuationAsOfDate: null,
  _previousIntegrationEntityIds: [],
  integrationEntityIds: [],
  linkToAllAddeparEntities: false,
  allowAdditionalManualAssets: false,
};

function FullScreenStructuredAssetsModalInner({
  entityId,
  subtypeId,
  householdId,
  entityType,
  headerText = 'Update asset details',
  submitText = 'Update asset details',
  entityName,
  isOpen,
  onClose,
}: FullScreenStructuredAssetsModelProps) {
  const integrationType = useAssetIntegrationType();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [updateEntity] = useUpdateEntityMutation({
    onCompleted: () => {
      showFeedback('New valuation saved', { variant: 'success' });
      onClose();
    },
    onError: (err) => {
      showFeedback(
        'Failed to update asset details. Please refresh the page and try again.'
      );
      reportError('could not update structured asset details', err);
    },
  });

  const {
    handleSubmit: handleFormSubmit,
    reset,
    formState: { isSubmitting },
  } = useFormContext<FullScreenStructuredAssetsModalForm>();
  const { valuation, loading: loadingFormData } =
    useFullScreenStructuredAssetFormData(entityId, reset);

  const handleClose = () => {
    onClose();
    reset(initialFormValues);
  };

  const onValidSubmission: SubmitHandler<
    FullScreenStructuredAssetsModalForm
  > = (values) => {
    const updateEntityAccountInput = createUpdateEntityAccountInput(values, {
      integrationType,
      previousValuation: valuation,
      entityId,
      entityType,
      subtypeId,
    });

    return updateEntity({
      variables: {
        input: updateEntityAccountInput,
      },
    });
  };
  const handleSubmit = handleFormSubmit(onValidSubmission);

  return (
    <FullScreenModal isOpen={isOpen}>
      {isOpen && (
        <Stack component="form" noValidate onSubmit={handleSubmit}>
          <LayoutWithSidePanel
            heading={headerText}
            subheading={entityName}
            MainContent={
              loadingFormData ? (
                <LoadingSkeleton />
              ) : (
                <MainContentComponent
                  valuation={valuation}
                  householdId={householdId}
                  entityId={entityId}
                  entityType={entityType}
                />
              )
            }
            SidebarContent={
              <Stack justifyContent="space-between" height="100%">
                <Box sx={{ p: 3 }}>
                  <AssetIntegrationField
                    entityType={entityType}
                    householdId={householdId}
                  />
                </Box>
                <ValuationDateField valuation={valuation} />
              </Stack>
            }
            Footer={
              <Footer
                rightAction={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <Button size="sm" variant="secondary" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      loading={isSubmitting}
                      size="sm"
                      variant="primary"
                      onClick={handleSubmit}
                    >
                      {submitText}
                    </Button>
                  </Stack>
                }
              />
            }
          />
        </Stack>
      )}
    </FullScreenModal>
  );
}

export function FullScreenStructuredAssetsModal(
  props: FullScreenStructuredAssetsModelProps
) {
  const formMethods = useForm<FullScreenStructuredAssetsModalForm>({
    defaultValues: initialFormValues,
  });
  return (
    <FormProvider {...formMethods}>
      <FullScreenStructuredAssetsModalInner {...props} />
    </FormProvider>
  );
}

interface MainContentComponentProps {
  householdId: string;
  entityId: string;
  entityType: EntityType;
  valuation: StructuredAssetsSubform_ValuationFragment | null;
}

function MainContentComponent({
  valuation,
  householdId,
  entityId,
  entityType,
}: MainContentComponentProps) {
  const { isIntegratedScenario: isLinkedToIntegration } =
    useValuationIntegrationDetails();
  const integrationDisplayName = useAssetProviderDisplayName();
  const { control } = useFormContext<FullScreenStructuredAssetsModalForm>();
  const allowAdditionalManualAssets = useWatch({
    control: control,
    name: `allowAdditionalManualAssets` as const satisfies FullScreenStructuredAssetsModalFormPath,
  });

  const shouldRenderAssetFormComponent = (() => {
    if (isLinkedToIntegration && !allowAdditionalManualAssets) return false;
    return true;
  })();

  const assetFormComponent = shouldRenderAssetFormComponent && (
    <AssetFormComponentWrapper>
      <AssetsFormComponent
        hideDescriptionInput={isLinkedToIntegration}
        entityId={entityId}
        entityType={entityType}
        householdId={householdId}
      />
    </AssetFormComponentWrapper>
  );

  return (
    <Stack spacing={3}>
      {isLinkedToIntegration && allowAdditionalManualAssets && (
        <IntegrationContextBanner variant="linked-with-manual" mb={3} />
      )}
      {assetFormComponent}
      {isLinkedToIntegration && (
        <>
          {!allowAdditionalManualAssets && (
            <IntegrationContextBanner variant="linked" mb={3} />
          )}
          <Box>
            <TableHeaderBar
              header={`Assets tracked in ${integrationDisplayName}`}
            />
            <IntegrationValuationSummary valuation={valuation} />
          </Box>
        </>
      )}
    </Stack>
  );
}

function LoadingSkeleton() {
  const rows = range(5);

  return (
    <Stack p={2}>
      <Stack spacing={1} direction="row">
        <Skeleton component="div" sx={{ width: '33%' }} height={50} />
      </Stack>
      {rows.map((row) => (
        <Stack key={row} spacing={1} direction="row">
          <Skeleton component="div" sx={{ width: '75%' }} height={50} />
          <Skeleton component="div" sx={{ flexBasis: '25%' }} height={50} />
        </Stack>
      ))}
    </Stack>
  );
}

function AssetFormComponentWrapper({ children }: PropsWithChildren<unknown>) {
  return (
    <Box>
      <TableHeaderBar header="Manually tracked assets" />
      <Card
        variant="filled"
        sx={{ p: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      >
        {children}
      </Card>
    </Box>
  );
}
