import { Dispatch, SetStateAction } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  MenuItem,
  MenuItemProps,
} from '@/components/poppers/MenuPopper/MenuItem';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { EntityKind } from '@/types/schema';

import { EntityDetailsDocument } from '../../details/graphql/EntityDetailPage.generated';
import { useConvertEntityMutation } from '../../graphql/Entities.generated';
import { ConvertEntityHeadingMap } from './ConvertEntityModal.constants';

export interface ConvertEntityMenuItemProps extends Partial<MenuItemProps> {
  entityId: string;
  entityKind: EntityKind;
  setOpenConvertModal: Dispatch<SetStateAction<boolean>>;
}

export function ConvertEntityMenuItem({
  entityId,
  entityKind,
  setOpenConvertModal,
  ...buttonProps
}: ConvertEntityMenuItemProps) {
  const trackUserEvent = useTrackUserEvent();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [convertTrustEntity, { loading }] = useConvertEntityMutation({
    variables: { entityId: entityId },
    onError: (error) => {
      reportError(`failed to convert entity`, error);
      showFeedback(
        'Failed to convert this entity. Please refresh the page and try again.'
      );
    },
    refetchQueries: [EntityDetailsDocument],
    onCompleted: () => {
      trackUserEvent('entity converted', {
        entityKind,
      });
      showFeedback('Successfully converted entity', {
        variant: 'success',
      });
    },
  });

  async function convertEntity() {
    if (
      entityKind === EntityKind.RevocableTrust ||
      entityKind === EntityKind.IrrevocableTrust
    ) {
      await convertTrustEntity();
    } else if (
      entityKind === EntityKind.IndividualPersonalAccount ||
      entityKind === EntityKind.JointPersonalAccount
    ) {
      setOpenConvertModal(true);
    }
  }

  const label = ConvertEntityHeadingMap[entityKind];

  // We only support converting between entities included in the conversion map
  if (!label) return null;

  return (
    <MenuItem
      label={label}
      onClick={() => convertEntity()}
      loading={loading}
      {...buttonProps}
    />
  );
}
