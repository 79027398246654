import Decimal from 'decimal.js';

import {
  CHART_SEMANTIC_COLOR_VALUE,
  ChartSemanticColorValue,
} from '@/components/charts/constants';
import { EntityKind } from '@/types/schema';

import { EntityType } from './types/EntityType';
import { getEntityKindFromEntityType } from './utils/getEntityKindFromEntityType';

export const NEW_ENTITY_ID = 'new' as const;

export const ESTATE_TAX_RATE = new Decimal(0.4);
export const ESTATE_TAX_RATE_FRIENDLY = `${ESTATE_TAX_RATE.mul(
  new Decimal(100)
).toNumber()}%`;

// this is currently only used for the GRAT designer
export const NEW_ENTITY_SENTINEL = 'new';

export const ENTITY_TYPES = {
  QPRT: 'qprt',
  GRAT: 'grat',
  SLAT: 'slat',
  ILIT: 'ilit',
  REVOCABLE_TRUST: 'revocable-trust',
  IRREVOCABLE_TRUST: 'irrevocable-trust',
  INDIVIDUAL_ACCOUNT: 'individual-account',
  INSURANCE_ACCOUNT: 'insurance-account',
  JOINT_ACCOUNT: 'joint-account',
  RETIREMENT_ACCOUNT: 'retirement-account',
  QUALIFIED_TUITION_ACCOUNT: 'qualified-tuition-account',
  CUSTODIAL_ACCOUNT: 'custodial-account',
  CLT: 'clt',
  CRT: 'crt',
  DAF: 'daf',
  PRIVATE_FOUNDATION: 'private-foundation',
  SOLE_PROPRIETORSHIP: 'sole-proprietorship',
  C_CORP: 'c-corp',
  S_CORP: 's-corp',
  LLC: 'llc',
  LP: 'lp',
  GP: 'gp',
} as const satisfies Record<string, EntityType>;

// Doc that specifies the properties of each entity type
// https://docs.google.com/spreadsheets/d/1nfGj2n1Cvh69CV1bBGJu2KfbEtDCkPWZ/edit?usp=sharing&ouid=118445342775593070681&rtpof=true&sd=true

export const BUSINESS_ENTITY_TYPES: Readonly<EntityType[]> = [
  'sole-proprietorship',
  'c-corp',
  's-corp',
  'llc',
  'lp',
  'gp',
] as const;

export const BUSINESS_ENTITY_KINDS: Readonly<EntityKind[]> =
  BUSINESS_ENTITY_TYPES.map((t) => getEntityKindFromEntityType(t));

export const ACCOUNT_ENTITY_TYPES: Readonly<EntityType[]> = [
  'individual-account',
  'joint-account',
  'retirement-account',
  'qualified-tuition-account',
  'custodial-account',
];

export const ACCOUNT_ENTITY_KINDS: Readonly<EntityKind[]> =
  ACCOUNT_ENTITY_TYPES.map((t) => getEntityKindFromEntityType(t));

export const SINGLE_GRANTOR_TRUST_TYPES: Readonly<EntityType[]> = [
  'slat',
  'grat',
];

export const SINGLE_GRANTOR_TRUST_KINDS: Readonly<EntityKind[]> =
  SINGLE_GRANTOR_TRUST_TYPES.map((t) => getEntityKindFromEntityType(t));

export const CHARITABLE_ENTITY_TYPES: Readonly<EntityType[]> = [
  'clt',
  'crt',
  'daf',
  'private-foundation',
];

export const CHARITABLE_ENTITY_KINDS: Readonly<EntityKind[]> =
  CHARITABLE_ENTITY_TYPES.map((t) => getEntityKindFromEntityType(t));

export const NON_TRUST_CHARITABLE_ENTITY_TYPES: Readonly<EntityType[]> = [
  'daf',
  'private-foundation',
];

export const GRANTOR_ENTITY_TYPES: Readonly<EntityType[]> = [
  'irrevocable-trust',
  'revocable-trust',
  'slat',
  'grat',
  'ilit',
  'qprt',
];

export const TRUST_ENTITY_TYPES: Readonly<EntityType[]> = [
  ...GRANTOR_ENTITY_TYPES,
  'clt',
  'crt',
];

export const GRANTOR_ENTITY_KINDS: Readonly<EntityKind[]> =
  GRANTOR_ENTITY_TYPES.map((t) => getEntityKindFromEntityType(t));

export const OWNER_ENTITY_TYPES: Readonly<EntityType[]> = [
  'joint-account',
  'retirement-account',
  'qualified-tuition-account',
];

export const OWNER_ENTITY_KINDS: Readonly<EntityKind[]> =
  OWNER_ENTITY_TYPES.map((t) => getEntityKindFromEntityType(t));

export const DONOR_ENTITY_TYPES: Readonly<EntityType[]> = [
  'crt',
  'clt',
  'daf',
  'private-foundation',
];

export const DONOR_ENTITY_KINDS: Readonly<EntityKind[]> =
  DONOR_ENTITY_TYPES.map((t) => getEntityKindFromEntityType(t));

export const SINGLE_PRINCIPAL_ENTITY_TYPES: Readonly<EntityType[]> = [
  ...SINGLE_GRANTOR_TRUST_TYPES,
  ...ACCOUNT_ENTITY_TYPES.filter(
    (entityType) => entityType !== 'joint-account'
  ),
];

export const SINGLE_PRINCIPAL_ENTITY_KINDS: Readonly<EntityKind[]> =
  SINGLE_PRINCIPAL_ENTITY_TYPES.map((t) => getEntityKindFromEntityType(t));

export const ALLOW_MULTIPLE_PRINCIPALS_TYPES: Readonly<EntityType[]> = [
  'qprt',
  'irrevocable-trust',
  'insurance-account',
  'revocable-trust',
  'ilit',
  'joint-account',
  'crt',
  'clt',
  'daf',
  'private-foundation',
];

export const ALLOW_MULTIPLE_PRINCIPALS_TYPES_WITH_BUSINESS_ENTITIES: Readonly<
  EntityType[]
> = [...ALLOW_MULTIPLE_PRINCIPALS_TYPES, ...BUSINESS_ENTITY_TYPES];

export const ALLOW_MULTIPLE_PRINCIPALS_KINDS: Readonly<EntityKind[]> =
  ALLOW_MULTIPLE_PRINCIPALS_TYPES.map((t) => getEntityKindFromEntityType(t));

export const ENTITY_SEMANTIC_COLORS: Record<
  'inEstate' | 'outOfEstateFamily' | 'outOfEstateCharity' | 'tax',
  ChartSemanticColorValue
> = {
  inEstate: CHART_SEMANTIC_COLOR_VALUE.PRIMARY,
  outOfEstateFamily: CHART_SEMANTIC_COLOR_VALUE.SECONDARY,
  outOfEstateCharity: CHART_SEMANTIC_COLOR_VALUE.TERTIARY,
  tax: CHART_SEMANTIC_COLOR_VALUE.NEGATIVE,
};

export const PERSONAL_FAMILY_TRUST_TYPES: Readonly<EntityType[]> = [
  'revocable-trust',
  'irrevocable-trust',
  'slat',
  'ilit',
  'qprt',
  'grat',
];

export const PERSONAL_FAMILY_ACCOUNT_TYPES: Readonly<EntityType[]> = [
  'individual-account',
  'joint-account',
  'retirement-account',
  'custodial-account',
  'qualified-tuition-account',
  'insurance-account',
];

export const NON_GRANTOR_PRINCIPAL_TYPES: Readonly<EntityType[]> = [
  ...NON_TRUST_CHARITABLE_ENTITY_TYPES,
  ...PERSONAL_FAMILY_ACCOUNT_TYPES,
];

// A list of the entity types that can have insurance policies
export const INSURANCE_POLICY_ENTITY_TYPES: Readonly<EntityType[]> = [
  'insurance-account',
  'ilit',
  'slat',
  'crt',
  'clt',
  'irrevocable-trust',
  'revocable-trust',
  'daf',
  'private-foundation',
  'c-corp',
  's-corp',
  'llc',
  'lp',
  'gp',
];

//////////////////////////////////////////////////////////////////////////////
// Entity kinds grouped by edit entity split screen & ai suggestions rollout
//////////////////////////////////////////////////////////////////////////////

/**
 * AI Suggestions features are always on for these entity kinds.
 */
export const SUPPORTED_AI_SUGGESTION_ENTITY_KINDS: Readonly<EntityKind[]> = [
  EntityKind.RevocableTrust,
  EntityKind.IrrevocableTrust,
  EntityKind.SlatTrust,
  EntityKind.IlitTrust,
  ...BUSINESS_ENTITY_KINDS,
];

export const TRUST_ENTITY_KINDS: Readonly<EntityKind[]> = [
  EntityKind.RevocableTrust,
  EntityKind.IrrevocableTrust,
  EntityKind.SlatTrust,
  EntityKind.IlitTrust,
  EntityKind.QprtTrust,
  EntityKind.GratTrust,
  EntityKind.CltTrust,
  EntityKind.CrtTrust,
];

export const NON_TRUST_CHARITABLE_ENTITY_KINDS: Readonly<EntityKind[]> = [
  EntityKind.DonorAdvisedFund,
  EntityKind.PrivateFoundation,
];

export const PERSONAL_ACCOUNT_ENTITY_KINDS: Readonly<EntityKind[]> = [
  EntityKind.IndividualPersonalAccount,
  EntityKind.JointPersonalAccount,
  EntityKind.RetirementPersonalAccount,
  EntityKind.InsurancePersonalAccount,
  EntityKind.QualifiedTuitionPersonalAccount,
  EntityKind.CustodialPersonalAccount,
];

export enum FilterOptions {
  ACTIVE = 'active',
  COMPLETED = 'completed',
  // Testamentary entities created at either grantors death.
  TESTAMENTARY = 'testamentary',
  // Entities created via document extraction that need to be reviewed by the user,
  // draft GRATs, GRATs in implementation, entities created for a hypothetical waterfall.
  DRAFTS = 'drafts',
}
