import { Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, Path } from 'react-hook-form';

import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { getMaxLengthValidation } from '@/components/utils/validators';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';

import {
  ClientPresentationDesigner_ClientPresentationCoverSlideDetailsFragment,
  useUpdateClientPresentationMutation,
} from '../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { useCoverSlideDetailsLazyQuery } from './graphql/CoverSlide.generated';

interface EditCoverSlideFormShape {
  title: string;
  description: string;
}
type CoverSlideFormField = Path<EditCoverSlideFormShape>;

interface EditCoverSlideModalProps {
  presentationId: string;
  isOpen: boolean;
  onClose: () => void;
}

function EditCoverSlideModalInner({
  presentationId,
  isOpen,
  onClose,
}: EditCoverSlideModalProps) {
  const { showFeedback, createSuccessFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { formRef, handleSave } = useFormSaveHandler();
  const [updateCoverSlide] = useUpdateClientPresentationMutation({
    onError: (error) => {
      showFeedback(
        'Failed to save cover slide updates. Please refresh the page and try again.'
      );
      reportError('failed to save cover slide updates', error);
    },
    onCompleted: createSuccessFeedback('Cover slide details updated'),
  });

  const { reset, handleSubmit, formState, control } =
    useFormContext<EditCoverSlideFormShape>();

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const actions = useMemo(() => {
    return (
      <FormModalActions.Provider<EditCoverSlideFormShape> formState={formState}>
        <FormModalActions.CancelButton onClick={handleClose} />
        <FormModalActions.SaveButton onClick={handleSave}>
          Save changes
        </FormModalActions.SaveButton>
      </FormModalActions.Provider>
    );
  }, [formState, handleClose, handleSave]);

  const onSubmit = handleSubmit((formData) => {
    return updateCoverSlide({
      variables: {
        input: {
          id: presentationId,
          update: {
            coverSlideTitle: formData.title,
            coverSlideDescription: formData.description,
          },
        },
      },
    });
  });

  useSubmitSuccessHandler(() => {
    handleClose();
  });

  return (
    <FormModal
      isOpen={isOpen}
      heading="Customize cover"
      onClose={handleClose}
      actions={actions}
    >
      <Stack component="form" ref={formRef} onSubmit={onSubmit} noValidate>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareTextInput<EditCoverSlideFormShape>
              control={control}
              fieldName={'title' as const satisfies CoverSlideFormField}
              label="Presentation title"
              disabled={formState.isLoading}
              required
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareTextInput<EditCoverSlideFormShape>
              control={control}
              fieldName={'description' as const satisfies CoverSlideFormField}
              label="Short description"
              rows={3}
              validation={{
                maxLength: getMaxLengthValidation(1024),
              }}
              multiline
              disabled={formState.isLoading}
              required
            />
          </FormLayoutItem>
        </FormLayoutRow>
      </Stack>
    </FormModal>
  );
}

export function EditCoverSlideModal(props: EditCoverSlideModalProps) {
  const [getCoverSlideDetails] = useCoverSlideDetailsLazyQuery({
    variables: {
      presentationId: props.presentationId,
    },
    // we're actually fetching/refetching/updating this value upstream (in the top-level designer query and here in the query response
    // for any edits), so we just want to read the values here
    fetchPolicy: 'cache-first',
  });

  const formMethods = useForm<EditCoverSlideFormShape>({
    defaultValues: async () => {
      const { data, error } = await getCoverSlideDetails();
      if (error || !data) {
        return {
          title: '',
          description: '',
        };
      }

      const coverSlideDetails =
        data.presentation as ClientPresentationDesigner_ClientPresentationCoverSlideDetailsFragment;

      return {
        title: coverSlideDetails.coverSlideTitle ?? '',
        description: coverSlideDetails.coverSlideDescription ?? '',
      };
    },
  });

  return (
    <FormProvider {...formMethods}>
      <EditCoverSlideModalInner {...props} />
    </FormProvider>
  );
}
