import Decimal from 'decimal.js';

import {
  DispositiveProvisionDispositionKind,
  DispositiveProvisionTemplateKind,
  DispositiveProvisionTransferTaxKind,
  TestamentaryEntityKind,
} from '@/types/schema';

import { Recipient } from '../../DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import {
  DispositiveProvisionsRecipientTemplateRecipientConfiguration,
  DispositiveProvisionsRecipientTypes,
} from '../../DispositiveProvisionsForm/DispositiveProvisionsFormRecipients/DispositiveProvisionsRecipient.types';
import { DraggableListItemRecipient } from '../../DispositiveProvisionsForm/DispositiveProvisionsFormRecipients/DraggableListItemRecipient';

export const DISCLAIMER_TRUST_RECIPIENT_CONFIGURATION: DispositiveProvisionsRecipientTemplateRecipientConfiguration =
  {
    allowsIndividualRecipient: false,
    allowsOrganizationRecipient: false,
    allowsEntityRecipient: false,
    allowsTestamentaryEntityRecipient: true,
    createRecipientTypes: [
      DispositiveProvisionsRecipientTypes.TestamentaryEntity,
    ],
    testamentaryEntityTaxSettings: {
      estateInclusionStatus: 'out-of-estate',
      survivingSpouseStateInEstateStatus: 'out-of-estate',
    },
    defaultNewTestamentaryEntityName: 'Disclaimer Trust',
    defaultNewTestamentaryEntityKind: TestamentaryEntityKind.TestamentaryTrust,
  };

export interface TrustRecipientProps {
  index: number;
  templateType?: DispositiveProvisionTemplateKind;
  recipientConfiguration?: Partial<DispositiveProvisionsRecipientTemplateRecipientConfiguration>;
}

export function DisclaimerTrust({
  index,
  templateType,
}: Omit<TrustRecipientProps, 'recipientConfiguration'>) {
  return (
    <DraggableListItemRecipient
      index={index}
      Draghandle={null}
      simulatedValue={null}
      onRemove={null}
      defaultExpanded
      templateType={
        templateType ||
        DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse
      }
      recipientConfiguration={DISCLAIMER_TRUST_RECIPIENT_CONFIGURATION}
      emptyRecipientOptionDisplay="Select disclaimer trust"
    />
  );
}

export function BypassTrustRecipient({
  index,
  templateType,
  recipientConfiguration,
}: TrustRecipientProps) {
  return (
    <DraggableListItemRecipient
      index={index}
      Draghandle={null}
      simulatedValue={null}
      onRemove={null}
      defaultExpanded
      templateType={
        templateType ||
        DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse
      }
      emptyRecipientOptionDisplay={'Select bypass trust'}
      recipientConfiguration={recipientConfiguration}
    />
  );
}

export const BLANK_BYPASS_TRUST: Recipient = {
  recipientId: '', // blank string instead of null to avoid MUI warning
  recipientKind: null,
  dispositionAmount: null,
  dispositionPercentage: null,
  notes: '',
  _dispositiveProvisionId: null,
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseCreditShelter,
  dispositionKind_ButtonGroup: 'other',
  dispositionKind_Select:
    DispositiveProvisionDispositionKind.RemainingLifetimeExclusionOfGrantor,
};

export interface MaritalTrustRecipientProps extends TrustRecipientProps {
  emptyRecipientOptionDisplay?: string;
  recipientConfiguration?: Partial<DispositiveProvisionsRecipientTemplateRecipientConfiguration>;
  isOptional?: boolean;
}
export function MaritalTrustRecipient({
  index,
  templateType,
  emptyRecipientOptionDisplay = 'Select marital trust',
  recipientConfiguration,
  isOptional = false,
}: MaritalTrustRecipientProps) {
  return (
    <DraggableListItemRecipient
      index={index}
      Draghandle={null}
      simulatedValue={null}
      onRemove={null}
      defaultExpanded
      templateType={
        templateType || DispositiveProvisionTemplateKind.MaritalTrust
      }
      emptyRecipientOptionDisplay={emptyRecipientOptionDisplay}
      recipientConfiguration={recipientConfiguration}
      isOptional={isOptional}
    />
  );
}

export const BLANK_MARITAL_TRUST: Recipient = {
  recipientId: '', // blank string instead of null to avoid MUI warning
  recipientKind: null,
  dispositionKind_ButtonGroup: DispositiveProvisionDispositionKind.Percentage,
  dispositionKind_Select: '',
  dispositionAmount: null,
  dispositionPercentage: new Decimal(100),
  notes: '',
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion,
  _dispositiveProvisionId: null,
};

export const BLANK_MARITAL_TRUST_FOR_DISCLAIMER: Recipient = {
  recipientId: '', // blank string instead of null to avoid MUI warning
  recipientKind: null,
  dispositionKind_ButtonGroup: 'other',
  dispositionKind_Select: DispositiveProvisionDispositionKind.AnythingLeftOver,
  dispositionAmount: null,
  dispositionPercentage: null,
  notes: '',
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion,
  _dispositiveProvisionId: null,
};
