import { FitViewOptions, useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { useViewportTransform } from '@/components/diagrams/FlowChart/hooks/useViewportTransform';

import { FamilyTreeEffectFn, FitViewEffect } from '../types';

export function useFitViewEffect(): FamilyTreeEffectFn<FitViewEffect> {
  const { fitView } = useReactFlow();
  const { getTransform } = useViewportTransform();

  return useCallback<FamilyTreeEffectFn<FitViewEffect>>(
    (_state, { opts, perserveZoomLevel }, _dispatch) => {
      const currentZoom = getTransform()[2];
      const preservedZoomLevelOpts: FitViewOptions = {
        minZoom: currentZoom,
        maxZoom: currentZoom,
      };

      const finalOpts = {
        ...opts,
        ...(perserveZoomLevel && preservedZoomLevelOpts),
      };

      void fitView(finalOpts);
    },
    [fitView, getTransform]
  );
}
