import { Theme } from '@emotion/react';
import { Box, Stack, SxProps } from '@mui/material';
import { ComponentProps } from 'react';

import { COLORS } from '@/styles/tokens/colors';

import { RibbonHorizontal } from '../display/Ribbon/RibbonHorizontal';
import { RibbonVertical } from '../display/Ribbon/RibbonVertical';
import { Card } from './Card/Card';

export type RibbonCardVariant = 'default' | 'vertical';

interface RibbonCardBaseProps extends React.PropsWithChildren {
  variant?: RibbonCardVariant;
  footer?: React.ReactNode;
  ribbonColor?: string;
  cardVariant?: ComponentProps<typeof Card>['variant'];
  icon?: React.ReactElement;
  heading?: string;
}

type RibbonCardVerticalProps = RibbonCardBaseProps;

interface RibbonCardHorizontalProps extends RibbonCardBaseProps {
  rightHeaderContent?: React.ReactNode;
  padding?: number;
  sx?: SxProps<Theme>;
}

export type RibbonCardProps =
  | RibbonCardVerticalProps
  | RibbonCardHorizontalProps;

export const RIBBON_CARD_DEFAULT_PADDING = 3;

const RIBBON_CARD_DEFAULT_CARD_VARAINT = 'outlined' as const;
const RIBBON_CARD_DEFAULT_RIBBON_COLOR = COLORS.NAVY[600];

function RibbonCardVertical({
  cardVariant = RIBBON_CARD_DEFAULT_CARD_VARAINT,
  ribbonColor = RIBBON_CARD_DEFAULT_RIBBON_COLOR,
  heading,
  footer,
  children,
  icon,
}: RibbonCardVerticalProps) {
  return (
    <Card
      variant={cardVariant}
      sx={{ px: 2, py: 3, mt: 1, overflow: 'visible' }}
      width="100%"
    >
      <Stack direction="row" spacing={3} width="100%">
        <Box
          sx={(theme) => ({
            position: 'relative',
            height: theme.spacing(9),
            top: theme.spacing(-5),
            left: theme.spacing(1.5),
          })}
        >
          <RibbonVertical
            ribbonColor={ribbonColor}
            ribbonText={heading}
            icon={icon}
            alignItems="center"
          />
        </Box>
        <Stack direction="column" sx={{ pl: 5 }} width="100%">
          {children}
        </Stack>
      </Stack>
      {footer}
    </Card>
  );
}

function RibbonCardHorizontal({
  padding = RIBBON_CARD_DEFAULT_PADDING,
  cardVariant = RIBBON_CARD_DEFAULT_CARD_VARAINT,
  sx,
  heading,
  ribbonColor = RIBBON_CARD_DEFAULT_RIBBON_COLOR,
  rightHeaderContent,
  children,
  footer,
  icon,
}: RibbonCardHorizontalProps) {
  // use this to align the ribbon directly with the left edge of the card; this effectively
  // is used to override the padding
  const offsetPadding = padding * -1;
  return (
    <Card
      variant={cardVariant}
      sx={{
        p: padding,
        pb: RIBBON_CARD_DEFAULT_PADDING + 1,
        overflow: 'visible',
        ...sx,
      }}
    >
      <Stack spacing={3} height="100%">
        <Stack
          component="header"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <RibbonHorizontal
            ribbonColor={ribbonColor}
            ribbonText={heading}
            // Move left by the padding amount plus the amount we want to offset
            moveLeftBy={4}
            // We already have padding on the card, so we don't need to add any
            // explicit adjustment moving it down
            paddingTop={0}
            icon={icon}
            ribbonPosition="relative"
          />
          {rightHeaderContent && (
            <Box sx={{ maxWidth: '50%', display: 'flex' }}>
              {rightHeaderContent}
            </Box>
          )}
        </Stack>

        <Box>{children}</Box>
        <Stack flexGrow={1} />
        {footer && (
          <Box mt={padding} mx={offsetPadding} mb={offsetPadding}>
            {footer}
          </Box>
        )}
      </Stack>
    </Card>
  );
}
export function RibbonCard(props: RibbonCardProps) {
  if (props.variant === 'vertical') {
    return <RibbonCardVertical {...props} />;
  } else {
    return <RibbonCardHorizontal {...props} />;
  }
}
