import { compact, isUndefined } from 'lodash';

import { ClientPresentationBundleTypes } from './clientPresentation/clientPresentation.types';

/**
 *
 * @param baseTitle The title of the presentation
 * @param slideIndex Which slide of the set we're currently generating a title for (0-indexed)
 * @param numberOfSlides How many slides are in the current set. (If only one, we don't need to number the slide title)
 * @returns
 */
export function getNumberedSlideTitle(
  baseTitle: string,
  slideIndex: number,
  numberOfSlides: number
) {
  if (numberOfSlides === 1) {
    return baseTitle;
  }

  return `${baseTitle} (${slideIndex + 1})`;
}

export function getFinalSlideIndex(globalIndex: number, localIndex?: number) {
  // using this undefined check because i expect localIndex to frequently
  // have a value of 0
  if (isUndefined(localIndex)) {
    return globalIndex;
  }

  // localIndex will be zero-indexed, but we want to start at 1
  return globalIndex + (localIndex + 1);
}

/**
 * @param bundleType the type of bundle we're generating an id for, for namespacing purposes
 * @param id if there's an additional id to include in the bundle id
 */
export function generateBundleId(
  bundleType: ClientPresentationBundleTypes,
  id: string | null
) {
  return compact([bundleType, 'bundle', id]).join('_');
}
