import { Box } from '@mui/material';
import { useMemo } from 'react';

import { COLORS } from '@/styles/tokens/colors';

import { useChartColorDefinitions } from '../constants';
import { PieChart, PieChartProps } from './PieChart';

export interface SimplePieChartProps {
  size?: number;
  series: PieChartProps['series'];
}

export function SimplePieChart({ series, size = 50 }: SimplePieChartProps) {
  const colors = useChartColorDefinitions();

  const isEmpty = useMemo(() => {
    return series.every((s) => s.data.every((d) => d.value === 0));
  }, [series]);

  if (isEmpty) {
    return (
      <Box
        width={size}
        height={size}
        borderRadius={size / 2}
        sx={{
          bgcolor: COLORS.GRAY[100],
          boxShadow: (theme) => theme.palette.shadows.mdInset,
        }}
      />
    );
  }

  return (
    <PieChart
      colors={[
        colors.PRIMARY.backgroundColor,
        colors.SECONDARY.backgroundColor,
        colors.TERTIARY.backgroundColor,
        colors.NEGATIVE.backgroundColor,
      ]}
      slotProps={{ legend: { hidden: true } }}
      height={size}
      width={size}
      sx={{
        '.MuiPieArc-root': {
          strokeWidth: 0,
        },
      }}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      series={series}
      skipAnimation
    />
  );
}
