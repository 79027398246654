import { Stack, Theme, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { COLORS } from '@/styles/tokens/colors';
import {
  formatCurrency,
  formatCurrencyNoDecimals,
} from '@/utils/formatting/currency';

import { VisualizationInput } from '../types';

function useComparisonColors() {
  const theme = useTheme();
  return {
    headerBackground: theme.palette.primary.main,
    footerBackground: theme.palette.divider,
  };
}

interface RowProps {
  rowTitle: string;
  valueNoPlan: string;
  valueWithPlan: string;
  rowColor: string;
}
function Row({ rowTitle, valueNoPlan, valueWithPlan, rowColor }: RowProps) {
  return (
    <>
      <Stack
        width="100%"
        alignItems="start"
        padding={1}
        justifyContent="center"
      >
        <Typography color={COLORS.GRAY[500]} variant="subtitle2">
          {rowTitle}
        </Typography>
      </Stack>
      <Stack width="100%" alignItems="end" padding={1} justifyContent="center">
        <Typography color={rowColor} variant="h6">
          {valueNoPlan}
        </Typography>
      </Stack>
      <Stack width="100%" alignItems="end" padding={1} justifyContent="center">
        <Typography color={rowColor} variant="h6">
          {valueWithPlan}
        </Typography>
      </Stack>
    </>
  );
}

interface Props {
  chartValues: {
    preTax: {
      noPlan: VisualizationInput;
      withPlan: VisualizationInput;
    };
    postTax: {
      noPlan: VisualizationInput;
      withPlan: VisualizationInput;
    };
  };
}

const getRows = (chartValues: Props['chartValues'], theme: Theme) => [
  {
    rowTitle: 'Pre-tax in estate',
    color: theme.palette.dataVisualizationPrimary.dark,
    valueNoPlan: formatCurrencyNoDecimals(chartValues.preTax.noPlan[1]),
    valueWithPlan: formatCurrencyNoDecimals(chartValues.preTax.withPlan[1]),
  },
  {
    rowTitle: 'Estate tax liability',
    color: theme.palette.dataVisualizationNegative.dark,
    valueNoPlan: formatCurrency(chartValues.postTax.noPlan[2], {
      maximumFractionDigits: 0,
      currencySign: 'accounting',
    }),
    valueWithPlan: formatCurrency(chartValues.postTax.withPlan[2], {
      maximumFractionDigits: 0,
      currencySign: 'accounting',
    }),
  },
  {
    rowTitle: 'Transferred out of estate',
    color: theme.palette.dataVisualizationSecondary.dark,
    valueNoPlan: EMPTY_CONTENT_HYPHEN,
    valueWithPlan: formatCurrencyNoDecimals(chartValues.postTax.withPlan[0]),
  },
];

const HeaderCell = ({ children }: React.PropsWithChildren<unknown>) => {
  const { headerBackground } = useComparisonColors();
  return (
    <Stack
      width="100%"
      alignItems="end"
      padding={1}
      justifyContent="center"
      sx={{
        backgroundColor: headerBackground,
      }}
    >
      {children}
    </Stack>
  );
};

function Header() {
  const theme = useTheme();
  return (
    <>
      <HeaderCell />
      <HeaderCell>
        <Typography color={theme.palette.primary.contrastText} variant="h5">
          No plan
        </Typography>
      </HeaderCell>
      <HeaderCell>
        <Typography color={theme.palette.primary.contrastText} variant="h5">
          With plan
        </Typography>
      </HeaderCell>
    </>
  );
}

const FooterCell = ({
  children,
  alignItems,
}: {
  children: React.ReactNode;
  alignItems: 'start' | 'end';
}) => {
  const { headerBackground } = useComparisonColors();
  return (
    <Stack
      width="100%"
      alignItems={alignItems}
      padding={1}
      justifyContent="center"
      sx={{
        borderTop: `1px solid ${headerBackground}`,
      }}
    >
      {children}
    </Stack>
  );
};

export function ScenarioComparisonTable({ chartValues }: Props) {
  const theme = useTheme();
  const rows = useMemo(() => getRows(chartValues, theme), [chartValues, theme]);

  const footer = useMemo(
    () => (
      <>
        <FooterCell alignItems="start">
          <Typography color={COLORS.GRAY[500]} variant="subtitle2">
            Net to beneficiaries
          </Typography>
        </FooterCell>
        <FooterCell alignItems="end">
          <Typography
            color={theme.palette.dataVisualizationSecondary.dark}
            variant="h6"
          >
            {formatCurrencyNoDecimals(
              chartValues.postTax.noPlan[0].plus(chartValues.postTax.noPlan[1])
            )}
          </Typography>
        </FooterCell>
        <FooterCell alignItems="end">
          <Typography
            color={theme.palette.dataVisualizationSecondary.dark}
            variant="h6"
          >
            {formatCurrencyNoDecimals(
              chartValues.postTax.withPlan[0].plus(
                chartValues.postTax.withPlan[1]
              )
            )}
          </Typography>
        </FooterCell>
      </>
    ),
    [
      chartValues.postTax.noPlan,
      chartValues.postTax.withPlan,
      theme.palette.dataVisualizationSecondary.dark,
    ]
  );

  return (
    <Stack display="grid" gridTemplateColumns="auto auto auto" m={2}>
      <Header />
      {rows.map((row, idx) => (
        <Row
          key={`scenario-comparison-table-${idx}`}
          rowTitle={row.rowTitle}
          valueNoPlan={row.valueNoPlan}
          valueWithPlan={row.valueWithPlan}
          rowColor={row.color}
        />
      ))}
      {footer}
    </Stack>
  );
}
