import { Stack, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FieldArrayWithId } from 'react-hook-form';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { useModalState } from '@/hooks/useModalState';
import { COLORS } from '@/styles/tokens/colors';

import {
  BaselineIncomeAndExpensesFormShape,
  GiftDesignerBasicInformationFormShape,
} from '../../GiftDesignerBasicInformationForm.types';
import { BaselineIncomeAndExpensesModal } from '../BaselineIncomeAndExpensesModal/BaselineIncomeAndExpensesModal';
import { AnnuallyRecurringValue } from '../BaselineIncomeAndExpensesModal/BaselineIncomeAndExpensesModal.fields';
import { columns } from './BaselineIncomeAndExpensesTable.columns';
import { IncomeRow } from './BaselineIncomeAndExpensesTable.types';

function useSlots() {
  return {
    noRowsOverlay: () => (
      <Stack
        spacing={1}
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Typography variant="h4">No cash flows</Typography>
        <Typography variant="subtitle2" color={COLORS.GRAY[500]}>
          Add baseline cash flows that will be applied across ALL scenarios
        </Typography>
      </Stack>
    ),
  };
}

interface BaselineIncomeAndExpensesTableProps {
  incomeAndExpenses: FieldArrayWithId<
    GiftDesignerBasicInformationFormShape,
    'baselineIncomeAndExpenses',
    '_id'
  >[];
  onSubmitSuccess: (
    idx: number,
    formData: BaselineIncomeAndExpensesFormShape
  ) => void;
  onDelete?: (idx: number) => void;
  isLoadingForm: boolean;
}

type BaselineIncomeAndExpensesTableRow =
  BaselineIncomeAndExpensesTableProps['incomeAndExpenses'][0];

export function BaselineIncomeAndExpensesTable({
  incomeAndExpenses,
  onSubmitSuccess,
  onDelete,
  isLoadingForm,
}: BaselineIncomeAndExpensesTableProps) {
  const slots = useSlots();
  const [{ isModalOpen, data: incomeData }, { openModal, closeModal }] =
    useModalState<BaselineIncomeAndExpensesTableRow & { idx: number }>();

  const openEditModal = useCallback(
    (incomeToOpen: BaselineIncomeAndExpensesTableRow, idx: number) => {
      openModal({ ...incomeToOpen, idx });
    },
    [openModal]
  );

  const rows: IncomeRow[] = useMemo(() => {
    return incomeAndExpenses.map((i, idx) => ({
      _id: i._id,
      cashFlowId: i.cashFlowId,
      name: i.displayName,
      dates: {
        startYear: i.startYear.toString(),
        endYear: i.endYear?.toString() ?? null,
        frequency:
          i.annuallyRecurring === AnnuallyRecurringValue.true
            ? 'Annual'
            : 'One-time',
      },
      growthRate: i.growthPercentage,
      value: i.amount,
      cashFlowType: i.cashFlowType,
      handleEdit: () => {
        openEditModal(i, idx);
      },
      edit: null,
    }));
  }, [incomeAndExpenses, openEditModal]);

  return (
    <>
      {isLoadingForm === false && (
        <BaselineIncomeAndExpensesModal
          incomeData={incomeData}
          isOpen={isModalOpen}
          onClose={() => {
            closeModal();
          }}
          onSubmitSuccess={(formData) => {
            onSubmitSuccess(incomeData?.idx ?? Infinity, formData);
            closeModal();
          }}
          onDelete={() => {
            onDelete?.(incomeData?.idx ?? Infinity);
            closeModal();
          }}
        />
      )}

      <DataTable
        getRowId={(row: IncomeRow) => row._id}
        rows={rows}
        columns={columns}
        slots={slots}
        sx={{ '--DataGrid-overlayHeight': '120px' }}
        hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS
        data-testid="BaselineIncomeAndExpensesTable"
      />
    </>
  );
}
