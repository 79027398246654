import { ApolloClient } from '@apollo/client';

import {
  assetsSubformDefaultValues as AssetsSubformDefaultValues,
  NamespaceType as AssetsNamespace,
  VariantType as AssetsVariantType,
} from '@/modules/assets/AssetsSubform/types';
import { determineDefaultAssetClassId } from '@/modules/assets/utils';
import { getGrantorsFieldVariant } from '@/modules/entities/principals/GrantorsField/GrantorsField.utils';
import {
  annuitiesSubformDefaultValues as AnnuitiesDefaultValues,
  NamespaceType as AnnuitiesNamespace,
  VariantType as AnnuitiesVariantTypes,
} from '@/modules/gratAnnuities/GRATAnnuitiesSubform/types';
import { AssetClassFragment } from '@/modules/tenant/graphql/TenantInformation.generated';

import {
  defaultValues_initialFunding as BasicAssetsInitialFundingDefaultValues,
  NamespaceType as BasicAssetsNamespace,
} from '../BasicAssetsSubform/BasicAssetsSubform.types';
import { basicAssetsDataFetcher } from '../BasicAssetsSubform/BasicAssetsSubform.utils';
import {
  getBasicInformationDefaultValues,
  NamespaceType as BasicInformationNamespace,
  VariantType as BasicInformationVariantType,
} from '../BasicInformationSubform/BasicInformationSubform.types';
import {
  basicInformationDataFetcher,
  getBasicInformationVariant,
} from '../BasicInformationSubform/BasicInformationSubform.utils';
import {
  defaultValues as businessEntityTaxStatusDefaultValues,
  NamespaceType as BusinessEntitiesTaxStatusNamespace,
} from '../BusinessEntitiesTaxStatusSubform/BusinessEntitiesTaxStatusSubform.types';
import { businessEntitiesTaxStatusDataFetcher } from '../BusinessEntitiesTaxStatusSubform/BusinessEntitiesTaxStatusSubform.utils';
import {
  BusinessEntityDetailsSubformNamespaceType as BusinessEntityDetailsNamespace,
  defaultValues as businessEntityDetailsDefaultValues,
} from '../BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import { businessEntityDetailsDataFetcher } from '../BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.utils';
import { entityBeneficiariesSubformDefaultValues } from '../EntityBeneficiariesSubform/EntityBeneficiariesSubform.defaults';
import {
  EntityBeneficiariesFormNamespace,
  EntityBeneficiariesFormVariant,
} from '../EntityBeneficiariesSubform/EntityBeneficiariesSubform.types';
import {
  beneficiariesDataFetcher,
  getBeneficiariesVariant,
} from '../EntityBeneficiariesSubform/EntityBeneficiariesSubform.utils';
import { EntityFormStage } from '../EntityForm/types';
import {
  defaultValues as InsurancePolicyDetailsDefaultValues,
  NamespaceType as InsurancePolicyDetailsNamespace,
  VariantType as InsurancePolicyVariantType,
} from '../InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform.types';
import {
  getInsurancePolicyVariant,
  insurancePolicyDataFetcher,
} from '../InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform.utils';
import { NamespaceType as TaxStatusNamespace } from '../TaxStatusSubform/TaxStatusSubform.types';
import {
  getTaxStatusDefaultValues,
  getTaxStatusVariant,
  taxStatusDataFetcher,
} from '../TaxStatusSubform/TaxStatusSubform.utils';
import {
  getTrustDetailsDefaultValues,
  NamespaceType as TrustDetailsNamespace,
} from '../TrustDetailsSubform/TrustDetailsSubform.types';
import { trustDetailsDataFetcher } from '../TrustDetailsSubform/TrustDetailsSubform.utils';
import { EntityType } from '../types/EntityType';

export enum EntitySubformCategory {
  BASIC_ENTITY_INFORMATION = 'BASIC_ENTITY_INFORMATION',
  BUSINESS_ENTITY_DETAILS = 'BUSINESS_ENTITY_DETAILS',
  TRUST_DETAILS = 'TRUST_DETAILS',
  TRUST_TAX_STATUS = 'TRUST_TAX_STATUS',
  BUSINESS_TAX_STATUS = 'BUSINESS_TAX_STATUS',
  BENEFICIARIES = 'BENEFICIARIES',
  ASSETS = 'ASSETS',
  INSURANCE_POLICIES = 'INSURANCE_POLICIES',
  GRAT_ANNUITIES = 'GRAT_ANNUITIES',
}

interface BaseSubformConfig {
  category: EntitySubformCategory;
  type:
    | BasicInformationNamespace
    | BusinessEntityDetailsNamespace
    | TaxStatusNamespace
    | TrustDetailsNamespace
    | EntityBeneficiariesFormNamespace
    | BasicAssetsNamespace
    | AssetsNamespace
    | BusinessEntitiesTaxStatusNamespace
    | AnnuitiesNamespace
    | InsurancePolicyDetailsNamespace;
  entityType: EntityType;
  props: {
    variant: unknown;
    householdId?: string;
    entityId?: string;
    namespace?: string;
    heading?: string;
  };
  sidebar: {
    label: string;
  };
  dataFetcher:
    | ((apolloClient: ApolloClient<object>, nodeId: string) => unknown)
    | undefined;
  defaultData: unknown;
}

export interface SubformConfigOpts {
  stage: EntityFormStage;
  heading?: string;
}

export interface AssetsSubformConfigOpts extends SubformConfigOpts {
  assetClassesById: Record<string, AssetClassFragment>;
  defaultAssetClassId: string;
}

export const SUBFORM_TYPE_COPY_MAP = {
  annuitiesSubform: 'Annuities',
  assetsSubform: 'Assets',
  basicAssetsInitialFundingSubform: 'Initial funding valuation',
  basicAssetsSubform: 'Current valuation',
  basicInformationSubform: 'Basic information',
  beneficiariesAfterBothGrantorsDie: 'Beneficiaries',
  beneficiariesSubform: 'Current beneficiaries',
  businessEntityDetailsSubform: 'Entity details', // TODO: This will be renamed to "Ownerships & key people"
  businessEntityTaxStatusSubform: 'Tax status',
  incomeBeneficiarySubform: 'Income beneficiary',
  lifetimeBeneficiariesSubform: 'Lifetime beneficiaries',
  leadBeneficiarySubform: 'Lead beneficiary',
  remainderBeneficiariesSubform: 'Remainder beneficiaries',
  trustDetailsSubform: 'Trust details', // TODO: https://linear.app/luminary/issue/T2-2546/cleanup-grantors-field-variant-logic
  taxStatusSubform: 'Tax status',
  insurancePolicyDetailsSubform: 'Insurance policies',
};

export function basicInformationSubformConfig(
  householdId: string,
  entityType: EntityType,
  entityId: string | undefined,
  opts: SubformConfigOpts
) {
  const variant: BasicInformationVariantType = getBasicInformationVariant(
    entityType,
    opts.stage
  );
  const grantorsFieldVariant = getGrantorsFieldVariant(entityType);
  const defaultData = getBasicInformationDefaultValues(grantorsFieldVariant);

  return {
    type: 'basicInformationSubform',
    category: EntitySubformCategory.BASIC_ENTITY_INFORMATION,
    entityType,
    props: {
      variant,
      householdId,
      entityId,
    },
    dataFetcher: basicInformationDataFetcher,
    defaultData,
    sidebar: {
      label: SUBFORM_TYPE_COPY_MAP.basicInformationSubform,
    },
  } as const satisfies BaseSubformConfig;
}

export function insurancePolicyDetailsSubformConfig(
  householdId: string,
  entityType: EntityType,
  entityId: string | undefined
) {
  const variant: InsurancePolicyVariantType =
    getInsurancePolicyVariant(entityType);
  return {
    type: 'insurancePolicyDetailsSubform',
    category: EntitySubformCategory.INSURANCE_POLICIES,
    entityType,
    props: {
      householdId,
      entityId,
      variant,
    },
    dataFetcher: insurancePolicyDataFetcher,
    defaultData: InsurancePolicyDetailsDefaultValues,
    sidebar: {
      label: SUBFORM_TYPE_COPY_MAP.insurancePolicyDetailsSubform,
    },
  } as const satisfies BaseSubformConfig;
}

export function businessEntityDetailsSubformConfig(
  householdId: string,
  entityType: EntityType,
  entityId: string | undefined,
  opts: SubformConfigOpts
) {
  return {
    type: 'businessEntityDetailsSubform',
    category: EntitySubformCategory.BUSINESS_ENTITY_DETAILS,
    entityType,
    props: {
      householdId,
      entityId,
      variant: 'default',
      heading: opts.heading,
    },
    dataFetcher: businessEntityDetailsDataFetcher,
    defaultData: businessEntityDetailsDefaultValues,
    sidebar: {
      label: opts.heading || SUBFORM_TYPE_COPY_MAP.businessEntityDetailsSubform,
    },
  } as const satisfies BaseSubformConfig;
}

export function taxStatusSubformConfig(
  entityType: EntityType,
  entityId?: string
) {
  const variant = getTaxStatusVariant(entityType);

  return {
    type: 'taxStatusSubform',
    category: EntitySubformCategory.TRUST_TAX_STATUS,
    entityType,
    props: {
      variant,
      entityId,
    },
    dataFetcher: taxStatusDataFetcher,
    defaultData: getTaxStatusDefaultValues(variant, entityType),
    sidebar: {
      label: SUBFORM_TYPE_COPY_MAP.taxStatusSubform,
    },
  } as const satisfies BaseSubformConfig;
}

export function businessEntitiesTaxStatusSubformConfig(entityType: EntityType) {
  return {
    type: 'businessEntityTaxStatusSubform',
    category: EntitySubformCategory.BUSINESS_TAX_STATUS,
    entityType,
    props: {
      variant: 'default',
    },
    dataFetcher: businessEntitiesTaxStatusDataFetcher,
    defaultData: businessEntityTaxStatusDefaultValues,
    sidebar: {
      label: SUBFORM_TYPE_COPY_MAP.businessEntityTaxStatusSubform,
    },
  } as const satisfies BaseSubformConfig;
}

export function trustDetailsSubformConfig(
  entityType: EntityType,
  householdId: string,
  opts: SubformConfigOpts
) {
  const defaultData = getTrustDetailsDefaultValues('default');

  const isRevIrrev =
    entityType === 'revocable-trust' || entityType === 'irrevocable-trust';
  const heading = isRevIrrev ? 'Trustees' : opts.heading;

  return {
    type: 'trustDetailsSubform',
    category: EntitySubformCategory.TRUST_DETAILS,
    entityType,
    props: {
      variant: 'default',
      householdId,
      heading,
    },
    dataFetcher: trustDetailsDataFetcher,
    defaultData,
    sidebar: {
      label: heading || SUBFORM_TYPE_COPY_MAP.trustDetailsSubform,
    },
  } as const satisfies BaseSubformConfig;
}

export function beneficiariesSubformConfig(
  entityType: EntityType,
  householdId: string,
  type: EntityBeneficiariesFormNamespace,
  opts: SubformConfigOpts
) {
  const variant: EntityBeneficiariesFormVariant = getBeneficiariesVariant(
    entityType,
    type
  );

  return {
    type,
    category: EntitySubformCategory.BENEFICIARIES,
    entityType,
    props: {
      variant,
      householdId,
      namespace: type,
      heading: opts.heading,
    },
    dataFetcher: beneficiariesDataFetcher,
    defaultData: entityBeneficiariesSubformDefaultValues,
    sidebar: {
      label: opts.heading || SUBFORM_TYPE_COPY_MAP[type],
    },
  } as const satisfies BaseSubformConfig;
}

export function basicAssetsInitialFundingSubformConfig(
  entityType: EntityType,
  householdId: string,
  opts: AssetsSubformConfigOpts
) {
  const defaultAssetClassId = determineDefaultAssetClassId(
    entityType,
    opts.defaultAssetClassId,
    opts.assetClassesById
  );
  const defaultData = {
    ...BasicAssetsInitialFundingDefaultValues,
    basicAssetsInitialFunding: {
      ...BasicAssetsInitialFundingDefaultValues.basicAssetsInitialFunding,
      assetCategoryId: defaultAssetClassId,
    },
  };
  return {
    type: 'basicAssetsInitialFundingSubform',
    category: EntitySubformCategory.ASSETS,
    entityType,
    props: {
      variant: 'default',
      householdId,
      heading: opts.heading,
    },
    dataFetcher: (...args) =>
      basicAssetsDataFetcher(
        'basicAssetsInitialFundingSubform',
        ...args,
        defaultAssetClassId
      ),
    defaultData: defaultData,
    sidebar: {
      label:
        opts.heading || SUBFORM_TYPE_COPY_MAP.basicAssetsInitialFundingSubform,
    },
  } as const satisfies BaseSubformConfig;
}

export function assetsSubformConfig(
  entityType: EntityType,
  householdId: string,
  entityId: string | undefined,
  opts: SubformConfigOpts
) {
  let variant: AssetsVariantType = 'updateHoldings' as const;

  if (opts.stage === EntityFormStage.GRAT_IMPLEMENTATION_EDIT) {
    variant = 'editDesignSummary' as const;
  }

  return {
    type: 'assetsSubform',
    category: EntitySubformCategory.ASSETS,
    entityType,
    props: {
      variant,
      householdId,
      namespace: 'assetsSubform',
      entityId,
      heading: opts.heading,
    },
    dataFetcher: undefined,
    defaultData: AssetsSubformDefaultValues,
    sidebar: {
      label: opts.heading || SUBFORM_TYPE_COPY_MAP.assetsSubform,
    },
  } as const satisfies BaseSubformConfig;
}

export function annuitiesSubformConfig(
  entityType: EntityType,
  householdId: string,
  entityId: string | undefined,
  opts: SubformConfigOpts
) {
  let variant: AnnuitiesVariantTypes =
    'initialFundingPercentAndPaymentAmount' as const;

  if (opts.stage === EntityFormStage.GRAT_IMPLEMENTATION_EDIT) {
    variant = 'initialFundingPercentOnly' as const;
  }

  return {
    type: 'annuitiesSubform',
    category: EntitySubformCategory.GRAT_ANNUITIES,
    entityType,
    props: {
      variant,
      householdId,
      namespace: 'annuitiesSubform',
      entityId,
      heading: opts.heading,
    },
    dataFetcher: undefined,
    defaultData: AnnuitiesDefaultValues,
    sidebar: {
      label: opts.heading || SUBFORM_TYPE_COPY_MAP.annuitiesSubform,
    },
  } as const satisfies BaseSubformConfig;
}

export type SubformConfigWithoutHtmlId =
  | ReturnType<typeof basicInformationSubformConfig>
  | ReturnType<typeof taxStatusSubformConfig>
  | ReturnType<typeof businessEntitiesTaxStatusSubformConfig>
  | ReturnType<typeof trustDetailsSubformConfig>
  | ReturnType<typeof beneficiariesSubformConfig>
  | ReturnType<typeof businessEntityDetailsSubformConfig>
  | ReturnType<typeof basicAssetsInitialFundingSubformConfig>
  | ReturnType<typeof assetsSubformConfig>
  | ReturnType<typeof insurancePolicyDetailsSubformConfig>
  | ReturnType<typeof annuitiesSubformConfig>;

export type SubformConfig = SubformConfigWithoutHtmlId & { htmlId: string };

export type EntitySubformNamespaceType = SubformConfig['type'];
