import { FormProvider } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';

import { ClientOrganizationForm } from './ClientOrganizationForm';
import {
  ClientOrganizationFormShape,
  defaultValues,
} from './ClientOrganizationModal.constants';
import { mapFormDataToInput } from './CreateClientOrganizationModal.utils';
import { useCreateClientOrganizationMutation } from './graphql/ClientOrganizationModal.generated';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'> & {
  householdId: string;
  requirement?: 'mustBeTrustee' | 'mustBeBeneficiary';
  onAfterCreate?: (newId: string) => void;
  initialOrganizationName?: string;
};

function CreateClientOrganizationModalComponent({
  onClose,
  heading = 'Add an organization',
  householdId,
  onAfterCreate,
  initialOrganizationName: _iON,
  requirement,
  ...formModalProps
}: FormModalOptionalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const trackUserEvent = useTrackUserEvent();
  const { formRef, handleSave } = useFormSaveHandler();
  const { handleSubmit, reset, shouldBlockNavigation, formState } =
    useFormContext<ClientOrganizationFormShape>();

  const [createMutation] = useCreateClientOrganizationMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      trackUserEvent('client_organization created');
      if (data.createClientOrganization) {
        onAfterCreate?.(data.createClientOrganization.id);
      }
    },
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError('Could not create client organization', error);
    },
  });

  const onSubmit = handleSubmit(async (formData) => {
    const output = await createMutation({
      variables: { input: mapFormDataToInput(formData, householdId) },
    });

    return output;
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  useSubmitSuccessHandler(() => {
    closeModal();
  });

  return (
    <FormModal
      {...formModalProps}
      onClose={onClose}
      heading={heading}
      shouldBlockClose={shouldBlockNavigation}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.CancelButton onClick={closeModal} />
          <FormModalActions.SaveButton
            data-testid="client-organization-modal-save"
            onClick={handleSave}
          >
            Add organization
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <ClientOrganizationForm requirement={requirement} variant="create" />
      </form>
    </FormModal>
  );
}

export const CreateClientOrganizationModal = ({
  householdId,
  initialOrganizationName,
  ...props
}: FormModalOptionalProps) => {
  const formMethods = useForm<ClientOrganizationFormShape>({
    defaultValues: {
      ...defaultValues,
      name: initialOrganizationName || '',
    },
  });

  return (
    <FormProvider {...formMethods}>
      <CreateClientOrganizationModalComponent
        householdId={householdId}
        initialOrganizationName={initialOrganizationName}
        {...props}
      />
    </FormProvider>
  );
};
