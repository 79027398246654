import { PopperContent } from '@/components/poppers/PopperContent';

export function TrustTrustees() {
  return (
    <PopperContent
      body={
        'The individual(s) or corporate trustee(s) that oversee the management of this entity.'
      }
    />
  );
}
