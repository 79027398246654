import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';

export interface RowData {
  id: string;
  name: string;
  email: string;
  clientAccessSummary: string;
  organizationName: string;
  title: string;
  role: string;
  action: unknown;
}

export const columns: Column<RowData>[] = [
  {
    field: 'name',
    headerName: 'Collaborator',
    flex: 1,
    renderCell: TwoLineTextRenderer({
      lineOne: ({ row: { name } }) => name,
      lineTwo: ({ row: { email } }) => email,
    }),
  },
  {
    field: 'clientAccessSummary',
    headerName: 'Client access',
    flex: 1,
  },
  {
    field: 'organizationName',
    headerName: 'Organization',
    width: 300,
    renderCell: TwoLineTextRenderer({
      lineOne: ({ row: { organizationName } }) => organizationName,
      lineTwo: ({ row: { title } }) => title,
    }),
  },
  {
    field: 'role',
    headerName: 'Role',
    flex: 1,
  },
  {
    field: 'action',
    headerName: '',
    align: 'center',
    renderCell: IconRenderer({
      icon: Edit02Icon,
    }),
    sortable: false,
    width: 64,
    minWidth: 64,
    maxWidth: 64,
  },
];
