// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BalanceSheetConfigurationPopper_BalanceSheetViewConfigurationFragment = { __typename?: 'BalanceSheetViewConfiguration', id: string, separateInEstateDirectlyHeld: boolean, separateInEstateTrusts: boolean, separateOutOfEstateTrusts: boolean, showInEstate: boolean, showOutOfEstate: boolean, hideSmallValues: boolean };

export type UpdateBalanceSheetViewConfigurationMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateBalanceSheetViewConfigurationInput;
}>;


export type UpdateBalanceSheetViewConfigurationMutation = { __typename?: 'Mutation', updateBalanceSheetViewConfiguration: { __typename?: 'BalanceSheetViewConfiguration', id: string, separateInEstateDirectlyHeld: boolean, separateInEstateTrusts: boolean, separateOutOfEstateTrusts: boolean, showInEstate: boolean, showOutOfEstate: boolean, hideSmallValues: boolean } };

export const BalanceSheetConfigurationPopper_BalanceSheetViewConfigurationFragmentDoc = gql`
    fragment BalanceSheetConfigurationPopper_BalanceSheetViewConfiguration on BalanceSheetViewConfiguration {
  id
  separateInEstateDirectlyHeld
  separateInEstateTrusts
  separateOutOfEstateTrusts
  showInEstate
  showOutOfEstate
  hideSmallValues
}
    `;
export const UpdateBalanceSheetViewConfigurationDocument = gql`
    mutation UpdateBalanceSheetViewConfiguration($input: AugmentedUpdateBalanceSheetViewConfigurationInput!) {
  updateBalanceSheetViewConfiguration(input: $input) {
    ...BalanceSheetConfigurationPopper_BalanceSheetViewConfiguration
  }
}
    ${BalanceSheetConfigurationPopper_BalanceSheetViewConfigurationFragmentDoc}`;
export type UpdateBalanceSheetViewConfigurationMutationFn = Apollo.MutationFunction<UpdateBalanceSheetViewConfigurationMutation, UpdateBalanceSheetViewConfigurationMutationVariables>;

/**
 * __useUpdateBalanceSheetViewConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateBalanceSheetViewConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBalanceSheetViewConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBalanceSheetViewConfigurationMutation, { data, loading, error }] = useUpdateBalanceSheetViewConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBalanceSheetViewConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBalanceSheetViewConfigurationMutation, UpdateBalanceSheetViewConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBalanceSheetViewConfigurationMutation, UpdateBalanceSheetViewConfigurationMutationVariables>(UpdateBalanceSheetViewConfigurationDocument, options);
      }
export type UpdateBalanceSheetViewConfigurationMutationHookResult = ReturnType<typeof useUpdateBalanceSheetViewConfigurationMutation>;
export type UpdateBalanceSheetViewConfigurationMutationResult = Apollo.MutationResult<UpdateBalanceSheetViewConfigurationMutation>;
export type UpdateBalanceSheetViewConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateBalanceSheetViewConfigurationMutation, UpdateBalanceSheetViewConfigurationMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateBalanceSheetViewConfiguration: 'UpdateBalanceSheetViewConfiguration' as const
  },
  Fragment: {
    BalanceSheetConfigurationPopper_BalanceSheetViewConfiguration: 'BalanceSheetConfigurationPopper_BalanceSheetViewConfiguration' as const
  }
}