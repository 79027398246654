import { Stack } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { useBackfillSummariesMutation } from '@/pages/internal/DocumentInferenceSandbox/toolsTab/backfillSummaries/graphql/BackfillSummaries.generated';
import { DocumentSummaryEntryMode } from '@/types/schema';

export const BackfillSummariesCard = () => {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const { id: tenantId } = useTenantDetailsContext();

  const [backfillMutation, { loading: loadingMutation }] =
    // Only allow mass summary regen/backfill if the user has not manually updated it yet.
    useBackfillSummariesMutation({
      variables: {
        where: {
          where: {
            tenantID: tenantId,
            hasDocumentSummaryWith: [
              { entryModeNEQ: DocumentSummaryEntryMode.User },
            ],
          },
        },
      },
      onCompleted: (data) => {
        showFeedback(
          `Found ${data.asyncSummarizeDocuments.length} documents to summarize.`,
          {
            variant: 'success',
            autoHideDuration: 1000,
          }
        );
      },
      onError: (error) => {
        showFeedback('Failed to start the summarize documents process');
        reportError('Failed to start the summarize documents process', error);
      },
    });

  return (
    <HeaderCard
      heading="Summarize Documents"
      subheading="Generate summaries for all documents in the tenant."
    >
      <Stack sx={{ gap: 3 }}>
        <Button
          size="md"
          variant="primary"
          loading={loadingMutation}
          onClick={() => backfillMutation()}
        >
          Generate Summaries
        </Button>
      </Stack>
    </HeaderCard>
  );
};
