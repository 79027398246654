import { Box, Stack } from '@mui/system';
import { DocumentNode } from 'graphql';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { DropdownButton } from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { ArrowBlockRightIcon } from '@/components/icons/ArrowBlockRightIcon';
import { File07Icon } from '@/components/icons/File07Icon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import { EntityStage } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import {
  useGetExistingProposalsv2Query,
  useUpdateEntityForStructuringMutation,
} from './graphql/StructuringDesigner.generated';

export interface FinishStructuringModalProps {
  queriesToRefetch: DocumentNode[];
  householdId: string;
  entityId: string;
  isOpen: boolean;
  onClose: () => void;
}

function useProposalData(entityId: string, opts = { skipQuery: false }) {
  const { data, error, loading } = useGetExistingProposalsv2Query({
    variables: {
      entityId,
    },
    fetchPolicy: 'cache-and-network',
    skip: opts.skipQuery,
  });

  useReportError(
    'failed to fetch proposal data for "finish structuring" modal',
    error,
    {
      entityId,
    }
  );

  const existingProposalOptions = useMemo(
    () =>
      data?.proposals?.edges?.flatMap((edge) => {
        if (!edge?.node) return [];
        return {
          value: edge.node.id,
          display: edge.node.displayName,
        };
      }) ?? [],
    [data]
  );

  return { options: existingProposalOptions, error, loading };
}

export function FinishStructuringModal({
  isOpen,
  onClose,
  householdId,
  entityId,
  queriesToRefetch,
}: FinishStructuringModalProps) {
  const navigate = useNavigate();
  const { options } = useProposalData(entityId, { skipQuery: !isOpen });
  const { createErrorFeedback } = useFeedback();
  const [updateEntity, { loading: submittingUpdate, error: updateError }] =
    useUpdateEntityForStructuringMutation({
      onError: createErrorFeedback(
        "We weren't able to implement this strategy. Please try again."
      ),
    });

  useReportError(
    'failed to implement strategy in the structuring designer',
    updateError,
    {
      householdId,
      entityId,
    }
  );

  function handleReturnToClientOverview() {
    const clientOverviewPath = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
      { householdId }
    );
    navigate(clientOverviewPath);
  }

  function handleCreateNewProposal() {
    const proposalLink = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_PROPOSAL_CREATE,
      {
        householdId: householdId,
        proposalId: NEW_PROPOSAL_ID,
      },
      {
        initialEntityId: entityId,
      }
    );
    navigate(proposalLink);
  }

  const handleImplementation = async () => {
    try {
      await updateEntity({
        variables: {
          input: {
            id: entityId,
            update: {
              stage: EntityStage.Implementation,
            },
          },
        },
        refetchQueries: queriesToRefetch,
      });

      // this is communicated to the user directly in the render function
      if (!updateError) {
        const implementationLink = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_ENTITY_IMPLEMENTATION,
          { householdId, entityId: entityId }
        );
        return navigate(implementationLink);
      }
    } catch (err) {
      diagnostics.error(
        'unhandled error when implementing strategy',
        err as Error,
        {
          householdId,
          entityId,
        }
      );
    }
  };

  function goToExistingProposal(proposalId: string) {
    const proposalLink = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_PROPOSAL_EDIT,
      {
        householdId: householdId,
        proposalId: proposalId,
      }
    );
    navigate(proposalLink);
  }

  const buttonSize = 'sm' as const;
  const disableButtons = submittingUpdate;
  const showExistingProposalsDropdown = options.length > 0;

  return (
    <DialogModal
      isOpen={isOpen}
      onClose={onClose}
      heading="What would you like to do next?"
    >
      <Stack spacing={3}>
        <Stack direction="row" spacing={3} justifyContent="space-between">
          <ActionCard
            sx={{ flexGrow: 1 }}
            description="Demonstrate potential outcomes with a client proposal"
            icon={<File07Icon size={24} color={COLORS.GRAY[400]} />}
            actions={
              <Stack direction="row" spacing={2} width="100%">
                {showExistingProposalsDropdown && (
                  <DropdownButton
                    sx={{ flexGrow: 1 }}
                    name="ChooseProposal"
                    variant="primary"
                    buttonContent="Existing"
                    showArrow={true}
                    size={buttonSize}
                    items={options.map((option) => ({
                      name: option.display,
                      clickHandler: () => goToExistingProposal(option.value),
                    }))}
                  />
                )}
                <Button
                  sx={{ flexGrow: 1 }}
                  onClick={handleCreateNewProposal}
                  variant={
                    showExistingProposalsDropdown ? 'secondary' : 'primary'
                  }
                  size={buttonSize}
                  disabled={disableButtons}
                >
                  Create new
                </Button>
              </Stack>
            }
          />
          <ActionCard
            sx={{ flexGrow: 1 }}
            description="Proceed directly to implementation of this GRAT"
            icon={<ArrowBlockRightIcon size={24} color={COLORS.GRAY[400]} />}
            actions={
              <Button
                onClick={handleImplementation}
                variant="primary"
                size={buttonSize}
                fullWidth
                disabled={disableButtons}
              >
                Implement GRAT
              </Button>
            }
          />
        </Stack>
        <Box textAlign="center">
          <Button
            onClick={handleReturnToClientOverview}
            variant="transparent"
            size="md"
            disabled={disableButtons}
          >
            Return to client overview
          </Button>
        </Box>
      </Stack>
    </DialogModal>
  );
}
