import { styled } from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';

import { COLORS } from '@/styles/tokens/colors';

export const AppNotificationLinearProgress = styled(LinearProgress)(() => ({
  height: 8,
  borderRadius: 4,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: COLORS.GRAY['300'],
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: COLORS.TEAL['500'],
    // setting this to `none` so that when the notification is opened,
    // the progress bar doesn't animate into place, which looks strange
    // if you're e.g. opening it for the second time
    transition: 'none',
  },
}));
