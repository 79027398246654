import { GridRowParams } from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import { useState } from 'react';

import { LargeTableEmptyState } from '@/components/tables/components/LargeTableEmptyState/LargeTableEmptyState';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { usePaginatedDataTableQuery } from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { TableEmptyState } from '@/components/tables/DataTable/TableEmptyState';
import { useReportError } from '@/hooks/useReportError';
import { CreateLiabilityModal } from '@/modules/liabilities/LiabilityModal/CreateLiabilityModal';
import { EditLiabilityModal } from '@/modules/liabilities/LiabilityModal/EditLiabilityModal';
import { NO_LIABILITY_SOURCE_RECIPIENT_VALUE_DISPLAY } from '@/modules/liabilities/LiabilityModal/LiabilityForm';
import { LiabilityOrderField, OrderDirection } from '@/types/schema';

import { CreateLiabilityButton } from '../CreateLiabilityButton';
import {
  LiabilitiesTable_LiabilityFragment,
  useLiabilitiesTableQuery,
} from './graphql/LiabilitiesTable.generated';
import { columns, RowData } from './LiabilitiesTable.constants';

export interface LiabilitiesTableProps {
  householdId: string;
}

function LiabilitiesTableEmptyState() {
  return (
    <TableEmptyState text="No existing liabilities">
      <CreateLiabilityButton />
    </TableEmptyState>
  );
}

function mapLiabilitiesToRows(
  liabilities: LiabilitiesTable_LiabilityFragment[],
  householdId: string
): RowData[] {
  return (
    liabilities
      .map((liability) => ({
        id: liability.id,
        name: liability.displayName,
        currentValue: liability.currentAmount ?? new Decimal(0),
        issuerName:
          liability.sourceClientOrganization?.name ??
          liability.sourceClientOrganization?.name ??
          liability.sourceEntity?.subtype?.displayName ??
          NO_LIABILITY_SOURCE_RECIPIENT_VALUE_DISPLAY,
        recipientName:
          liability.recipientEntity?.subtype?.displayName ??
          NO_LIABILITY_SOURCE_RECIPIENT_VALUE_DISPLAY,
        householdId,
      }))
      // sort so the most negative values are at the top. the right thing to do here is to make the value field
      // sortable via the API, but it's
      .sort((a, b) => b.currentValue.minus(a.currentValue).toNumber())
  );
}

export function LiabilitiesTable({ householdId }: LiabilitiesTableProps) {
  const [editingLiabilityId, setEditingLiabilityId] = useState<string | null>(
    null
  );
  const { reportError } = useReportError();
  const [paginatedTableProps, { data, refetch: updateTable, loading }] =
    usePaginatedDataTableQuery(useLiabilitiesTableQuery, {
      pageSize: PageSizes.Twenty,
      variables: {
        where: {
          hasHouseholdWith: [
            {
              id: householdId,
            },
          ],
        },
        orderBy: [
          {
            field: LiabilityOrderField.UpdatedAt,
            direction: OrderDirection.Desc,
          },
        ],
      },
      onError: (error) => {
        reportError('failed to load liabilities', error);
      },
    });

  const rows = mapLiabilitiesToRows(data ?? [], householdId);

  if (!loading && rows.length === 0) {
    return (
      <LargeTableEmptyState
        title="Add liabilities"
        description="Liabilities will impact current entity valuations and future projections in the estate waterfall"
        ModalComponent={({ isOpen, onClose }) => (
          <CreateLiabilityModal isOpen={isOpen} onClose={onClose} />
        )}
        buttonText="Add liability"
      />
    );
  }

  return (
    <>
      {editingLiabilityId && (
        <EditLiabilityModal
          onClose={() => {
            setEditingLiabilityId(null);
            void updateTable();
          }}
          isOpen={editingLiabilityId !== null}
          liabilityId={editingLiabilityId}
        />
      )}
      <DataTable
        {...paginatedTableProps}
        rows={rows}
        columns={columns}
        slots={{
          noRowsOverlay: LiabilitiesTableEmptyState,
        }}
        onRowClick={({ row }: GridRowParams<RowData>) => {
          setEditingLiabilityId(row.id);
        }}
      />
    </>
  );
}
