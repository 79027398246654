import Decimal from 'decimal.js';

import { CurrencyUSD, Percent } from '@/graphql/scalars';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';

import { EditAssumptionsForm } from '../modals/EditAssumptionsModal/constants';

export const DEFAULT_TAX_DRAG = new Decimal(20);

export const FORM_NAME = 'proposal' as const;
export const defaultValues: FormShape = {
  [FORM_NAME]: {
    name: '',
    description: '',
    includeCumulativeView: false,
    syncPostTermReturns: false,
    entities: {},
  },
};

export interface TScenario {
  _id: string;
  entityProposalId?: string;
  proposalScenarioId?: string;
  gratProposalScenarioId?: string;
  projectedRateOfReturn: Percent;
  preTaxProjectedRateOfReturn: Percent;
  selectedYearOption: string;
  projectedMarketValueAtTerm: CurrencyUSD;
}
export type TScenarios = TScenario[];
type Entities = Record<string, { scenarios: TScenarios }>;
export interface FormShape {
  [FORM_NAME]: {
    name: string;
    description: string;
    includeCumulativeView: boolean;
    syncPostTermReturns: boolean;
    entities: Entities;
  };
}

export const entitiesAndAssumptionsFromDisplayProposal = (
  proposal: ProposalFragment
) => {
  const entities: Entities = {};
  const assumptions: EditAssumptionsForm = {};

  proposal.entityProposals?.forEach((entityProposal) => {
    const entityProposalId = entityProposal.id;
    const scenarios: TScenarios =
      entityProposal?.proposalScenarios?.map((proposalScenario) => {
        return {
          _id: '',
          entityProposalId,
          proposalScenarioId: proposalScenario.id,
          gratProposalScenarioId:
            proposalScenario?.gratProposalScenario?.id ?? '',
          projectedRateOfReturn:
            proposalScenario?.gratProposalScenario
              ?.projectedAnnualReturnThroughTerm ?? new Decimal(0),
          preTaxProjectedRateOfReturn:
            proposalScenario?.gratProposalScenario
              ?.projectedAnnualReturnAfterTerm ?? new Decimal(0),
          selectedYearOption:
            proposalScenario?.gratProposalScenario?.projectionTimePeriodYears.toString() ??
            '0',
          projectedMarketValueAtTerm:
            proposalScenario?.gratProposalScenario
              ?.projectedMarketValueAtTerm ?? new Decimal(0),
        };
      }) ?? [];

    entities[entityProposal.entity.id] = {
      scenarios,
    };

    assumptions[entityProposal.entity.id] = {
      termEndAssetValuationType: entityProposal.assetValuationProjectionType,
      taxDragPercent: entityProposal?.taxDrag ?? new Decimal(0),
    };
  });

  return { entities, assumptions };
};
