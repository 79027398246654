import { Grid, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useState } from 'react';

import { MathSummary } from '@/components/display/MathSummary/MathSummary';
import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { Card } from '@/components/layout/Card/Card';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { entityHasAssetsIntegration } from '@/modules/assetProviderIntegrations/shared/utils';
import { AssetFullScreenModal } from '@/modules/assetValuation/AssetFullScreenModal/AssetFullScreenModal';
import { UpdateHoldingsButton } from '@/modules/assetValuation/UpdateHoldingsButton/UpdateHoldingsButton';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { ImpliedEstateTaxSavings } from '@/modules/content/tooltipContent/ImpliedEstateTaxSavings';
import { ImpliedEstateTaxSavingsCompleted } from '@/modules/content/tooltipContent/ImpliedEstateTaxSavingsCompleted';
import { getPerformanceForGrat } from '@/modules/entities/gratTrusts/performance/getPerformanceForGrat';
import { GratPerformanceBar } from '@/modules/entities/gratTrusts/performance/GratPerformanceBar';
import { GratPerformanceSums } from '@/modules/entities/gratTrusts/performance/GratPerformanceSums';
import { GratPerformanceTable } from '@/modules/entities/gratTrusts/performance/GratPerformanceTable';
import { EntityStage } from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { useGetGratPerformanceQuery } from './graphql/GetGratPerformance.generated';

interface Props {
  entityId: string;
  householdId: string;
}

export function GratPerformancePage({ entityId, householdId }: Props) {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const { createErrorFeedback } = useFeedback();

  const { data, error } = useGetGratPerformanceQuery({
    variables: { entityId },
    fetchPolicy: 'cache-and-network',
    onError: createErrorFeedback(
      'Failed to fetch GRAT performance. Please refresh the page to try again.'
    ),
  });

  useReportError(
    'failed to load GRAT performance on GRAT performance page',
    error,
    {
      entityId,
      householdId,
    }
  );

  if (data?.node?.__typename !== 'Entity') {
    return null;
  }

  if (data.node.subtype.__typename !== 'GRATTrust') {
    return null;
  }

  const grat = data.node.subtype;

  const gratPerformance = getPerformanceForGrat({
    ...grat,
    stage: data.node.stage,
  });

  return (
    <>
      <AssetFullScreenModal
        subtypeId={grat.id}
        householdId={householdId}
        entityId={entityId}
        headerText={'Update asset valuation'}
        isOpen={isAddOpen}
        onClose={() => setIsAddOpen(false)}
        assetsSubformType="updateHoldings"
      />

      <Stack gap={3} p={3} pb={4}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h1">
            Performance as of{' '}
            {formatDateToMonDDYYYY(grat.mostRecentValuationDate ?? new Date())}
          </Typography>
          {data.node.stage === EntityStage.Active &&
            !entityHasAssetsIntegration(data.node) && (
              <UpdateHoldingsButton
                variant="primary"
                size="sm"
                onClick={() => setIsAddOpen(true)}
              />
            )}
        </Stack>
        <Grid container columns={12} columnSpacing={6}>
          {data.node.stage === EntityStage.Active && (
            <>
              <Grid item sm={12} md={6} lg={8} xl={9} mb={2}>
                <GratPerformanceBar
                  grats={
                    grat
                      ? [
                          {
                            ...grat,
                            stage: data.node.stage,
                          },
                        ]
                      : []
                  }
                />
              </Grid>
              <Grid item sm={12} md={6} lg={4} xl={3}>
                <GratPerformanceSums
                  grats={
                    grat
                      ? [
                          {
                            ...grat,
                            stage: data.node.stage,
                          },
                        ]
                      : []
                  }
                  variant="narrow"
                />
              </Grid>
            </>
          )}
          {data.node.stage === EntityStage.Completed && (
            <>
              <Grid item sm={12} md={7} lg={9} xl={9} mb={2}>
                <MathSummary
                  valueOne={{
                    title: 'GRAT remainder value',
                    value: formatCurrency(
                      grat.distributionAssetValuation?.valuationValue ??
                        new Decimal(0)
                    ),
                  }}
                  valueTwo={{
                    title: 'Taxable gift',
                    value: formatCurrency(gratPerformance.taxableGift),
                  }}
                  sum={{
                    title: 'Tax-free transfer',
                    value: formatCurrency(gratPerformance.taxFreeTransfer),
                  }}
                />
              </Grid>
              <Grid item sm={12} md={5} lg={3} xl={3}>
                <Stack direction="row" justifyContent="end">
                  <Card sx={{ p: 2, display: 'inline-block' }} variant="filled">
                    <OversizedMetricItem
                      title={
                        <Stack direction="row" spacing={0.5}>
                          <span>Implied estate tax savings</span>
                          <ContextualHelpTooltip>
                            {data.node.stage === EntityStage.Completed ? (
                              <ImpliedEstateTaxSavingsCompleted />
                            ) : (
                              <ImpliedEstateTaxSavings />
                            )}
                          </ContextualHelpTooltip>
                        </Stack>
                      }
                      value={formatCurrency(
                        gratPerformance.impliedEstateTaxSavings
                      )}
                    />
                  </Card>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
        <Card
          variant="outlined"
          sx={{
            padding: 3,
          }}
        >
          <GratPerformanceTable
            entityId={entityId}
            grat={grat ?? null}
            isCompleted={data.node.stage === EntityStage.Completed}
          />
        </Card>
      </Stack>
    </>
  );
}
