import { useCallback } from 'react';

import { RefreshCw05Icon } from '@/components/icons/RefreshCw05Icon';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import {
  MenuItem,
  MenuItemProps,
} from '@/components/poppers/MenuPopper/MenuItem';
import { useReportError } from '@/hooks/useReportError';

import { useUpdateEntityMutation } from '../../graphql/UpdateEntity.generated';
import { EntityType } from '../../types/EntityType';
import { makeActivateEntityUpdateInput } from './makeActivateEntityUpdateInput';

interface ConvertToActiveMenuItemProps {
  entityId: string;
  subtypeId: string;
  entityType: EntityType;
  entityDisplayName: string;
  menuItemProps?: Partial<MenuItemProps>;
}

export function ConvertToActiveMenuItem({
  entityId,
  subtypeId,
  entityType,
  entityDisplayName,
  menuItemProps = {},
}: ConvertToActiveMenuItemProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [updateEntity, { loading }] = useUpdateEntityMutation({
    refetchQueries: 'active',
    onError: (error) => {
      reportError(`failed to update entity stage`, error);
      showFeedback(FeedbackMessages.formSaveError);
    },
    onCompleted: () => {
      showFeedback(
        `${entityDisplayName} has been converted to an active entity`,
        { variant: 'success' }
      );
    },
  });

  const handleMoveToActive = useCallback(() => {
    const input = makeActivateEntityUpdateInput({
      entityId,
      subtypeId,
      entityType,
    });

    void updateEntity({
      variables: {
        input,
      },
    });
  }, [entityId, entityType, subtypeId, updateEntity]);

  return (
    <MenuItem
      icon={<RefreshCw05Icon size={18} />}
      label="Convert to active entity"
      loading={loading}
      onClick={handleMoveToActive}
      {...menuItemProps}
    />
  );
}
