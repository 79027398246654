import { Box, Stack } from '@mui/material';

import { SpinningIcon } from '@/components/animation/SpinningIcon/SpinningIcon';
import { RefreshCw05Icon } from '@/components/icons/RefreshCw05Icon';
import { Card, CardProps } from '@/components/layout/Card/Card';
import { COLORS } from '@/styles/tokens/colors';

export interface LoadingBannerProps {
  cardProps?: Partial<CardProps>;
  content: React.ReactNode;
}

export function LoadingBanner({ cardProps = {}, content }: LoadingBannerProps) {
  const { sx: cardSx = {}, ...otherCardProps } = cardProps;
  return (
    <Card
      variant="filled-teal"
      sx={{ p: 3, flexShrink: 0, ...cardSx }}
      {...otherCardProps}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Box>{content}</Box>
        <SpinningIcon
          Icon={RefreshCw05Icon}
          size={24}
          sx={{ color: COLORS.NAVY[600] }}
        />
      </Stack>
    </Card>
  );
}
