import { ApolloError } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Loader } from '@/components/progress/Loader/Loader';
import { useFormContext } from '@/components/react-hook-form';
import { useForm, useSubmitSuccessHandler } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import {
  AugmentedCreateLoggedTransferInput,
  AugmentedUpdateLoggedTransferInput,
} from '@/types/schema';

import { useClientDetailsGiftingPageQuery } from '../hooks/useClientDetailsGiftingPageQuery';
import { useCreateLoggedTransferMutation } from '../LogNewTransferModal/graphql/CreateLoggedTransfer.generated';
import { useUpdateLoggedTransferMutation } from '../LogNewTransferModal/graphql/UpdateLoggedTransfer.generated';
import { useDeleteTransferAndExclusionEventMutation } from './graphql/LogNewGiftModal.generated';
import { getDefaultValues } from './LogNewGiftModal.constants';
import { LogNewGiftModalForm, useFormOptions } from './LogNewGiftModal.form';
import { LogNewGiftForm } from './LogNewGiftModal.types';
import {
  getCreateLoggedTransferEventInput,
  getUpdateLifetimeExclusionEventInput,
  useSyncDataToForm,
} from './LogNewGiftModal.utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  householdId: string;
  lifetimeExclusionEventId?: string;
  beneficiaryId?: string;
  isDuplicate?: boolean;
  isEdit?: boolean;
}

function LogNewGift({
  isOpen,
  onClose,
  householdId,
  lifetimeExclusionEventId,
  isDuplicate,
  isEdit,
  beneficiaryId,
}: Props) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { formRef, handleSave } = useFormSaveHandler();
  const trackUserEvent = useTrackUserEvent();

  const { grantorOptions, beneficiaryOptions, possibleBeneficiaries } =
    useFormOptions({ householdId });

  const { handleSubmit, reset, shouldBlockNavigation, formState, getValues } =
    useFormContext<LogNewGiftForm>();

  const { loading: loadingSyncDataToForm, originalDocumentIds } =
    useSyncDataToForm({
      lifetimeExclusionEventId,
      isDuplicate,
      defaultBeneficiaryId: beneficiaryId,
      isOpen,
    });

  const clientDetailsGiftingPageState =
    useClientDetailsGiftingPageQuery(householdId);

  const loadingForm =
    loadingSyncDataToForm || clientDetailsGiftingPageState.loading;

  const [createLoggedTransfer] = useCreateLoggedTransferMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback('Error logging gift, please try again', {
        variant: 'error',
      });
      reportError('could not create lifetime exclusion event', error, {
        householdId,
      });
    },
    onCompleted: () => {
      showFeedback('Gift logged successfully', { variant: 'success' });
    },
  });

  const [updateLoggedTransfer] = useUpdateLoggedTransferMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback('Error logging gift, please try again');
      reportError('could not update lifetime exclusion event', error, {
        householdId,
      });
    },
    onCompleted: () => {
      showFeedback('Gift logged successfully', {
        variant: 'success',
      });
    },
  });

  const onDeleteGiftError = (error: ApolloError) => {
    showFeedback('Error deleting gift, please try again');
    reportError('could not delete lifetime exclusion event', error, {
      householdId,
    });
  };

  const [
    deleteLoggedTransferAndLifetimeExclusionEvent,
    { loading: isDeleting },
  ] = useDeleteTransferAndExclusionEventMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: onDeleteGiftError,
    onCompleted: () =>
      showFeedback('Gift deleted successfully', {
        variant: 'success',
      }),
  });

  const onValidSubmission: SubmitHandler<LogNewGiftForm> = async (values) => {
    if (isEdit) {
      const updateLoggedTransferInput: AugmentedUpdateLoggedTransferInput =
        getUpdateLifetimeExclusionEventInput({
          form: values,
          originalDocumentIds,
          possibleBeneficiaries,
        });

      // submit update form
      const output = await updateLoggedTransfer({
        variables: {
          input: updateLoggedTransferInput,
        },
      });
      trackUserEvent('gift updated');
      return output;
    } else {
      const createLifetimeExclusionEventInput: AugmentedCreateLoggedTransferInput =
        getCreateLoggedTransferEventInput(values, possibleBeneficiaries);

      // submit create form
      const output = await createLoggedTransfer({
        variables: {
          input: createLifetimeExclusionEventInput,
        },
      });
      trackUserEvent('gift logged');
      return output;
    }
  };

  const submitHandler = handleSubmit(onValidSubmission);

  const closeModal = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  useSubmitSuccessHandler(() => {
    closeModal();
  });

  const onDelete = useCallback(async () => {
    await deleteLoggedTransferAndLifetimeExclusionEvent({
      variables: {
        deleteLoggedTransferId: getValues('logNewGiftModal.loggedTransferId'),
        deleteLifetimeExclusionEventId: getValues(
          'logNewGiftModal.lifetimeExclusionEventId'
        ),
      },
    });

    closeModal();
  }, [closeModal, deleteLoggedTransferAndLifetimeExclusionEvent, getValues]);

  let submitButtonText = 'Add gift';

  if (isEdit) {
    submitButtonText = 'Save changes';
  }

  if (isDuplicate) {
    submitButtonText = 'Duplicate gift';
  }

  const actions = (
    <FormModalActions.Provider
      formState={formState}
      isDeleting={isDeleting}
      disabled={loadingSyncDataToForm}
    >
      {isEdit && <FormModalActions.DeleteButton onConfirmDelete={onDelete} />}
      <FormModalActions.CancelButton onClick={closeModal} />
      <FormModalActions.SaveButton onClick={handleSave}>
        {submitButtonText}
      </FormModalActions.SaveButton>
    </FormModalActions.Provider>
  );

  const heading = useMemo(() => {
    if (isEdit) {
      return 'Edit gift';
    }

    if (isDuplicate) {
      return 'Duplicate as new gift';
    }

    return 'Add gift';
  }, [isDuplicate, isEdit]);

  return (
    <FormModal
      isOpen={isOpen}
      heading={heading}
      onClose={closeModal}
      actions={actions}
      shouldBlockClose={shouldBlockNavigation}
    >
      {loadingForm ? (
        <Loader
          boxProps={{
            sx: {
              textAlign: 'center',
              my: 3,
            },
          }}
        />
      ) : (
        <form ref={formRef} onSubmit={submitHandler} noValidate>
          <LogNewGiftModalForm
            grantorOptions={grantorOptions}
            beneficiaryOptions={beneficiaryOptions}
            householdId={householdId}
            advisorClient={clientDetailsGiftingPageState.household!}
          />
        </form>
      )}
    </FormModal>
  );
}

export function LogNewGiftModal({ ...props }: Props) {
  const formMethods = useForm<LogNewGiftForm>({
    defaultValues: getDefaultValues({
      defaultBeneficiaryId: props.beneficiaryId,
      isForcedBeneficiary: !!props.beneficiaryId,
    }),
  });

  return (
    <FormProvider {...formMethods}>
      <LogNewGift {...props} />
    </FormProvider>
  );
}
