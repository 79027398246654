import { orderBy } from 'lodash';
import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { useGetScenarioCashFlowPortfolioOptionsQuery } from './graphql/GetScenarioCashFlowPortfolioOptions.generated';

const errorMessage =
  "We weren't able to initialize this form. Please refresh the page and try again.";

export function useScenarioCashFlowPortfolioOptions(proposalId: string) {
  const { showFeedback } = useFeedback();

  const queryMethods = useGetScenarioCashFlowPortfolioOptionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        id: proposalId,
      },
    },
    onError: (error) => {
      diagnostics.error(errorMessage, error, { proposalId });
      showFeedback(errorMessage);
    },
  });

  const { data } = queryMethods;

  const portfolioOptions = useMemo(() => {
    if (!data) {
      return [];
    }

    const proposals = getNodes(data.proposals);
    const portfolios =
      proposals[0]?.giftingProposal?.outOfEstatePortfolios ?? [];

    return (
      orderBy(
        portfolios.map((portfolio) => ({
          display: portfolio.displayName,
          value: portfolio.id,
        })),
        ['display'],
        ['asc']
      ) ?? []
    );
  }, [data]);

  return { portfolioOptions, ...queryMethods };
}
