import { PopperContent } from '@/components/poppers/PopperContent';

export function SharePriceContent() {
  return (
    <PopperContent
      body={
        'For publicly traded assets, use the average between the high and the low share prices on the transfer date. Privately held assets may require a valuation appraisal.'
      }
    />
  );
}
