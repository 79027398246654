import { Box, Stack } from '@mui/material';
import { CSSProperties, PropsWithChildren } from 'react';

import { HeaderWithPopover } from '@/components/form/baseInputs/Button/HeaderWithPopover';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { TypographyClamped } from '@/components/typography/TypographyClamped';

import { isHypotheticalWaterfall } from '../../EstateWaterfall.utils';
import { HypotheticalEstateWaterfallBadge } from '../HypotheticalEstateWaterfallBadge';
import { useEstateWaterfallMenuItems } from './hooks/useEstateWaterfallMenuItems';

export interface EstateWaterfallsHeadingDropdownProps {
  householdId: string;
  waterfallId: string | null;
}

type WaterfallHeaderProps = PropsWithChildren<{
  isHypothetical?: boolean;
  color?: CSSProperties['color'];
}>;

export const WaterfallHeader = ({
  children,
  isHypothetical = false,
  color,
}: WaterfallHeaderProps) => {
  return (
    <Stack spacing={1} direction="row" alignItems="center">
      <TypographyClamped
        lines={1}
        variant="h1"
        component="h2"
        color={color}
        textAlign="left"
        display="flex"
        data-testid="EstateWaterfallBreadcrumbNavigation-waterfallSelector"
      >
        {children}
      </TypographyClamped>
      {isHypothetical && <HypotheticalEstateWaterfallBadge />}
    </Stack>
  );
};

export function EstateWaterfallsHeadingDropdown({
  householdId,
  waterfallId,
}: EstateWaterfallsHeadingDropdownProps) {
  const { currentWaterfall, dropdownItems, loading } =
    useEstateWaterfallMenuItems({
      householdId,
      waterfallId,
    });

  const isHypothetical = isHypotheticalWaterfall(currentWaterfall);

  return (
    <HeaderWithPopover
      slots={{
        Header: {
          component: WaterfallHeader,
          props: {
            isHypothetical,
          },
        },
      }}
      heading={currentWaterfall?.displayName ?? EMPTY_CONTENT_HYPHEN}
      buttonWithPopoverProps={{
        loading,
        buttonSx: {
          '&:hover': {
            backgroundColor: 'transparent',
            borderColor: 'transparent',
          },
        },
      }}
    >
      {dropdownItems?.map((item, idx) => (
        <Box key={`waterfall-menu-item-${idx}`}>
          {'clickHandler' in item ? (
            <MenuItem
              justifyContent="space-between"
              onClick={item.clickHandler}
              label={item.component}
              iconAfter={item.iconAfter}
            />
          ) : (
            item.component
          )}
        </Box>
      ))}
    </HeaderWithPopover>
  );
}
