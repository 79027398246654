import { BaseMutationOptions } from '@apollo/client';

import { useCreateFileMutation } from './graphql/CreateFile.generated';

/**
 * Hook to generate a signed URL for uploading a file to S3. This also creates a
 * record in the database for the file. The householdId is optional, since
 * the file may not be associated with a household at this time.
 *
 * @param householdId
 */
export function useGenerateSignedFileUploadURL(householdId?: string | null) {
  const [uploadFile] = useCreateFileMutation();

  async function generateUploadURL(
    hash: string,
    mutationOptions: Partial<BaseMutationOptions>
  ) {
    const queryProps = await uploadFile({
      ...mutationOptions,
      variables: {
        input: {
          householdID: householdId,
          // we check the file integrity S3 side.
          contentsSha256: hash,
        },
      },
    });

    // finally, take the signed upload URL and upload the file to it
    const signedUploadURL = queryProps.data?.uploadFile?.uploadURL;
    if (!signedUploadURL) {
      throw new Error('No errors, but no signed upload URL');
    }

    return { signedUploadURL, ...queryProps };
  }

  return { generateUploadURL };
}
