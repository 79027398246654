import Decimal from 'decimal.js';

import {
  NAMESPACE,
  PreTaxReturnLimitModalForm,
} from './PreTaxReturnLimitModal.types';

export const DEFAULT_PRE_TAX_RETURN_LIMIT_MODAL_FORM: PreTaxReturnLimitModalForm =
  {
    [NAMESPACE]: {
      charitableProposalId: '',
      preTaxReturnPercentageHigh: new Decimal(15),
      preTaxReturnPercentageMedium: new Decimal(7),
      preTaxReturnPercentageLow: new Decimal(0),
      taxDragPercentageHigh: new Decimal(20),
      taxDragPercentageMedium: new Decimal(20),
      taxDragPercentageLow: new Decimal(20),
    },
  };
