import { IntegrationClientExternalKind } from '@/types/schema';

export const INTEGRATION_CLIENT_EXTERNAL_KIND_DISPLAY: Record<
  IntegrationClientExternalKind,
  string | undefined
> = {
  ADDEPAR_CLIENT: 'Client',
  ADDEPAR_GROUP: 'Group',
  ADDEPAR_HOUSEHOLD: 'Household',
  // because there's only one kind of Black Diamond/Orion integration client, we don't need to differentiate
  BLACK_DIAMOND_RELATIONSHIP: undefined,
  ORION_CLIENT: undefined,
};
