import Decimal from 'decimal.js';

import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment } from './graphql/CharitableTrustDesignerIllustrationData.generated';
import {
  MAXIMUM_UNITRUST_PAYOUT,
  MINIMUM_CLUT_PAYOUT,
  MINIMUM_CRUT_PAYOUT,
} from './hooks/useValidPayout';

export const MINIMUM_ANNUAL_CLAT_TARGET = new Decimal('0');
export const MINIMUM_ANNUAL_CRT_TARGET = new Decimal('0.05');

export function getValidateMinimumCLUTPayout() {
  return (value: unknown): string | undefined => {
    if (!value) {
      return undefined;
    }

    const intendedPercentage = new Decimal(value as Decimal.Value);
    if (intendedPercentage.isNaN()) {
      return 'Payout must be a percentage';
    }

    if (intendedPercentage.lessThan(MINIMUM_CLUT_PAYOUT)) {
      return `Payout must be at least ${formatPercent(MINIMUM_CLUT_PAYOUT)}%`;
    }

    return undefined;
  };
}

export function getValidateMaximumCLUTPayout() {
  return (value: unknown): string | undefined => {
    if (!value) {
      return undefined;
    }

    const intendedPercentage = new Decimal(value as Decimal.Value);
    if (intendedPercentage.isNaN()) {
      return 'Payout must be a percentage';
    }

    if (intendedPercentage.greaterThan(MAXIMUM_UNITRUST_PAYOUT)) {
      return `Payout must be no more than ${formatPercent(
        MAXIMUM_UNITRUST_PAYOUT
      )}%`;
    }

    return undefined;
  };
}

export function getValidateMinimumCLATPayout() {
  return (value: unknown): string | undefined => {
    if (!value) {
      return undefined;
    }

    const intendedAmount = new Decimal(value as Decimal.Value);
    if (intendedAmount.isNaN()) {
      return 'Payout must be a number';
    }

    if (intendedAmount.lessThanOrEqualTo(MINIMUM_ANNUAL_CLAT_TARGET)) {
      return `Payout must be at least ${formatCurrency(
        MINIMUM_ANNUAL_CLAT_TARGET
      )}`;
    }
    return undefined;
  };
}

export function getValidateMaximumCLATPayout(fundingAmount: Decimal | null) {
  return (value: unknown): string | undefined => {
    if (!value || !fundingAmount) {
      return;
    }

    const intendedAmount = new Decimal(value as Decimal.Value);
    if (intendedAmount.isNaN()) {
      return 'Payout must be a number';
    }

    if (intendedAmount.greaterThan(fundingAmount)) {
      return `Payment must be less than the funding amount`;
    }

    return;
  };
}

export function getValidateMinimumCRUTPayout(
  crtProjection:
    | CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment
    | undefined
) {
  return (value: unknown): string | undefined => {
    if (!value) {
      return undefined;
    }

    const intendedPercentage = new Decimal(value as Decimal.Value);
    if (intendedPercentage.isNaN()) {
      return 'Payout percentage must be a number';
    }

    if (intendedPercentage.lessThan(MINIMUM_CRUT_PAYOUT)) {
      return `Payout percentage must be at least ${formatPercent(
        MINIMUM_CRUT_PAYOUT
      )}%`;
    }

    // explicit false check
    if (crtProjection?.metCharitableThreshold === false) {
      return `The charitable deduction must be at least 10% of the initial funding value.`;
    }

    return undefined;
  };
}

export function getValidateMaximumCRUTPayout(
  crtProjection:
    | CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment
    | undefined
) {
  return (value: unknown): string | undefined => {
    if (!value) {
      return undefined;
    }

    const intendedPercentage = new Decimal(value as Decimal.Value);
    if (intendedPercentage.isNaN()) {
      return 'Payout percentage must be a number';
    }

    if (intendedPercentage.greaterThan(MAXIMUM_UNITRUST_PAYOUT)) {
      return `Payout percentage must less than ${formatPercent(
        MAXIMUM_UNITRUST_PAYOUT
      )}%`;
    }

    if (crtProjection && !crtProjection.metCharitableThreshold) {
      return `The charitable deduction must be at least 10% of the initial funding value.`;
    }

    return undefined;
  };
}

export function getValidateMinimumCRATPayout(
  fundingAmount: Decimal | null,
  crtProjection:
    | CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment
    | undefined
) {
  return (value: unknown): string | undefined => {
    if (!value || !fundingAmount || fundingAmount.isZero()) {
      return undefined;
    }

    const intendedAmount = new Decimal(value as Decimal.Value);
    if (intendedAmount.isNaN()) {
      return 'Payout amount must be a number';
    }

    if (
      intendedAmount
        .dividedBy(fundingAmount)
        .mul(100)
        .lessThan(MINIMUM_CRUT_PAYOUT)
    ) {
      return `The payout must be at least ${formatCurrency(
        fundingAmount.mul(0.05)
      )}`;
    }

    if (crtProjection && !crtProjection.metCharitableThreshold) {
      return `The charitable deduction must be at least 10% of the initial funding value.`;
    }

    return;
  };
}

export function getValidateMaximumCRATPayout(
  crtProjection:
    | CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment
    | undefined
) {
  return (value: unknown): string | undefined => {
    if (!value) {
      return undefined;
    }

    if (crtProjection && crtProjection.metCharitableThreshold === false) {
      return `The charitable deduction must be at least 10% of the initial funding value.`;
    }

    return undefined;
  };
}
