import { ReactFlowState, useStore, useStoreApi } from '@xyflow/react';
import { useCallback } from 'react';

export type ReactFlowSelector<T> = (s: ReactFlowState) => T;

/**
 * @description Helper to return either the value, or a getter to select the value from the ReactFlow state.
 * This is useful in situations where you don't always want a useEffect subscribing to value changes,
 * but you do want the latest value.
 */
export function useGetValue<T>(selector: ReactFlowSelector<T>) {
  const store = useStoreApi();
  const value = useStore(selector);

  const get = useCallback(() => {
    return selector(store.getState());
    // Ignoring store in the dependencies array because we don't want this to run on every store change.
    // The store value is stable, but it causes re-renders. This doesn't matter because we access it via helpers like getState().
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selector]);

  return { value, get, store };
}
