import { Box, Grid } from '@mui/material';
import { PropsWithChildren } from 'react';

export function ModalFormLayout({ children }: PropsWithChildren<unknown>) {
  return (
    <Grid container spacing={2} flex={1} columns={12}>
      <Grid height={'100%'} item sm={12}>
        <Box>{children}</Box>
      </Grid>
    </Grid>
  );
}
