import { Box, BoxProps } from '@mui/material';
import React, { PropsWithChildren, ReactNode } from 'react';

import { Card, CardProps } from '@/components/layout/Card/Card';

export type FrameProps = BoxProps &
  PropsWithChildren<{
    variant: CardProps['variant'];
    badge?: ReactNode;
  }>;

export function Frame(props: FrameProps) {
  const { children, badge, variant, ...boxProps } = props;

  return (
    <Card variant={variant}>
      {badge && (
        /* wrappers are necessary to add a relative parent so the absolutely-positioned can be repositioned based on the Frame's parent */
        <Box sx={{ position: 'absolute', left: 0, right: 0 }}>
          <Box sx={{ position: 'relative', width: '100%', height: 0 }}>
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                top: '-4px',
              }}
            >
              {badge}
            </Box>
          </Box>
        </Box>
      )}
      <Box {...boxProps}>{children}</Box>
    </Card>
  );
}
