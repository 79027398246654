import { useMemo } from 'react';

import { MultiSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useIntegrationAssetClassesQuery } from '@/modules/assetCategories/graphql/AssetCategories.generated';
import { getNodes } from '@/utils/graphqlUtils';

export function useIntegrationCategoriesWithDisabledState() {
  const { createErrorFeedback } = useFeedback();
  const { data, ...queryProps } = useIntegrationAssetClassesQuery({
    onError: createErrorFeedback(
      'Failed to fetch integration categories. Please refresh the page to try again.'
    ),
  });

  const options: MultiSelectInputOption[] = useMemo(() => {
    return getNodes(data?.integrationAssetClasses).map((iac) => ({
      display: iac.integrationName,
      value: iac.id,
      disabled: iac.assetClass !== null,
    }));
  }, [data?.integrationAssetClasses]);

  return {
    options,
    ...queryProps,
  };
}
