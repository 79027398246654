import { Box, Stack, Typography } from '@mui/material';

import { CheckIcon } from '@/components/icons/CheckIcon';
import { RefreshCcw05Icon } from '@/components/icons/RefreshCcw05Icon';
import { COLORS } from '@/styles/tokens/colors';

export enum TaskListItemStatus {
  NONE = 'NONE',
  INVALID = 'INVALID',
  COMPLETED = 'COMPLETED',
}

interface TaskListItemIconProps {
  status: TaskListItemStatus;
  width: number;
}

function TaskListItemIcon({ status, width }: TaskListItemIconProps) {
  const icon = (() => {
    switch (status) {
      case TaskListItemStatus.COMPLETED:
        return <CheckIcon color={COLORS.FUNCTIONAL.SUCCESS.DEFAULT} />;
      case TaskListItemStatus.INVALID:
        return <RefreshCcw05Icon color={COLORS.GRAY[400]} />;
      case TaskListItemStatus.NONE:
        return null;
    }
  })();
  return (
    <Box
      height="100%"
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      width={width}
      minWidth={width}
    >
      {icon}
    </Box>
  );
}

interface TaskListItemProps {
  heading: React.ReactNode;
  statusSummary: React.ReactElement;
  status: TaskListItemStatus;
  selected?: boolean;
  onClick: () => void;
}

export function TaskListItem({
  heading,
  statusSummary,
  status,
  selected,
  onClick,
}: TaskListItemProps) {
  const iconWidth = 40;
  return (
    <Stack
      onClick={onClick}
      height={60}
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      px={2}
      sx={{
        cursor: 'pointer',
        bgcolor: selected ? COLORS.FUNCTIONAL.HOVER : 'transparent',
        borderRight: selected ? `solid ${COLORS.NAVY[600]} 6px` : 'none',
      }}
      tabIndex={0}
      role="button"
      aria-pressed={!!selected}
    >
      <TaskListItemIcon width={iconWidth} status={status} />
      <Box sx={{ maxWidth: `calc(100% - ${iconWidth / 2}px)` }}>
        <Typography variant="h4" component="div" noWrap>
          {heading}
        </Typography>
        {statusSummary && (
          <Typography component="div" variant="subtitle2" noWrap>
            {statusSummary}
          </Typography>
        )}
      </Box>
    </Stack>
  );
}
