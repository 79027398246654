import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { ModalFormLayout } from '@/components/form/layout/ModalFormLayout';
import { ArrowRightIcon } from '@/components/icons/ArrowRightIcon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { COLORS } from '@/styles/tokens/colors';
import {
  CreateEstateWaterfallHypotheticalTransferInput,
  EntityKind,
  EstateWaterfallHypotheticalTransferDestinationKind,
} from '@/types/schema';

import { GetHypotheticalTransfers_HypotheticalTransferFragment } from '../graphql/GetHypotheticalTransfers.generated';
import { DestinationEntityFragment } from './graphql/GetTransferById.generated';
import { HypotheticalTransferFormFields } from './HypotheticalTransferForm.fields';
import { TransferReferenceObj } from './HypotheticalTransferForm.types';
import { TransferKindInput } from './TransferKindInput';

export enum TransferDirection {
  Inbound = 'inbound',
  Outbound = 'outbound',
}

export type HypotheticalTransferFormShape =
  Partial<CreateEstateWaterfallHypotheticalTransferInput> & {
    transferKind?: string;
    shouldTransferToNewHypotheticalEntity: boolean;
    transferDirection: TransferDirection;
    hypotheticalEntityGrantor: string;
    hypotheticalEntityName: string;
    hypotheticalEntityKind: EntityKind | '';
    _hypotheticalDestinationEntity?: DestinationEntityFragment;
  };
export interface HypotheticalTransferFormProps {
  transferReferenceObj: TransferReferenceObj;
  householdId: string;
  waterfallId: string;
  isEdit: boolean;
  transferId?: string;
  transfers: {
    inboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
    outboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
  };
}

export function HypotheticalTransferForm({
  transferReferenceObj,
  waterfallId,
  isEdit,
  householdId,
}: HypotheticalTransferFormProps) {
  const { control, setValue } = useFormContext<HypotheticalTransferFormShape>();

  const transferDirection = useWatch({
    control,
    name: 'transferDirection',
  });

  const isShouldTransferToNewHypotheticalEntitySwitchEnabled = useWatch({
    control,
    name: 'shouldTransferToNewHypotheticalEntity',
  });

  useEffect(() => {
    // Effect to handle setting the transfer direction
    // when creating a draft entity
    if (isShouldTransferToNewHypotheticalEntitySwitchEnabled && !isEdit) {
      setValue('transferDirection', TransferDirection.Outbound);
      setValue(
        'destinationKind',
        EstateWaterfallHypotheticalTransferDestinationKind.Entity
      );
      setValue('destinationEntityID', undefined);
      setValue('destinationIndividualID', undefined);
      setValue('destinationOrganizationID', undefined);
    }
  }, [isEdit, isShouldTransferToNewHypotheticalEntitySwitchEnabled, setValue]);

  return (
    <ModalFormLayout>
      <FormLayoutRow>
        <FormLayoutItem>
          <HypotheticalTransferFormFields.ShouldTransferToNewHypotheticalEntity
            isEdit={isEdit}
            transferReferenceObj={transferReferenceObj}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <HypotheticalTransferFormFields.TransferValue
            transferReferenceObj={transferReferenceObj}
            householdId={householdId}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {isShouldTransferToNewHypotheticalEntitySwitchEnabled && (
        <FormLayoutRow>
          <FormLayoutItem>
            <HypotheticalTransferFormFields.HypotheticalEntityName />
          </FormLayoutItem>
          <FormLayoutItem>
            <HypotheticalTransferFormFields.HypotheticalEntityKind
              isEdit={isEdit}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      <FormLayoutRow>
        {isShouldTransferToNewHypotheticalEntitySwitchEnabled && (
          <FormLayoutItem>
            <HypotheticalTransferFormFields.Grantor
              transferReferenceObj={transferReferenceObj}
            />
          </FormLayoutItem>
        )}
        <FormLayoutItem>
          <TransferKindInput waterfallId={waterfallId} />
        </FormLayoutItem>
      </FormLayoutRow>

      <Card
        sx={{
          p: 3,
          pb: 4,
        }}
        variant="filled"
      >
        <Stack
          direction={
            // Switch the to and from items if the transfer is inbound
            transferDirection === TransferDirection.Inbound
              ? 'row-reverse'
              : 'row'
          }
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <HypotheticalTransferFormFields.SourceEntity
            transferReferenceObj={transferReferenceObj}
          />
          <Stack>
            {/* Offset to push the arrow down the height of the label */}
            <Box height="22px" />
            <ArrowRightIcon size={24} color={COLORS.GRAY[400]} />
          </Stack>
          <HypotheticalTransferFormFields.DestinationEntity
            householdId={householdId}
          />
        </Stack>
      </Card>
    </ModalFormLayout>
  );
}
