import { Dialog } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';

import { SpinningIcon } from '@/components/animation/SpinningIcon/SpinningIcon';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { AssetIntegrationProviders } from '@/modules/assetProviderIntegrations/shared/constants';
import { useIsAssetProviderIntegrationEnabled } from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import { useMultiDocumentUploaderContext } from '@/modules/documents/MultiDocumentUploader/context/multiDocumentUploader.context';
import { CreateEntityShortFormModal } from '@/modules/entities/EntityShortFormModal/CreateEntityShortFormModal';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useAICapabilitiesEnabled } from '@/modules/tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import { ROUTE_KEYS } from '@/navigation/constants';
import { UnreachableError } from '@/utils/errors';

import { CreateHouseholdModal } from '../CreateHouseholdModal/CreateHouseholdModal';
import {
  CreateHouseholdFlowContext,
  CreateHouseholdFlowStep,
  useCreateHouseholdFlowContext,
} from './CreateHouseholdFlow.context';
import {
  ConnectSelectProvider,
  FlowPicker,
  OnboardingNoIntegration,
  PostClientCreation,
} from './CreateHouseholdFlow.steps';
import { useGetPostClientIntakeAction } from './useGetPostClientIntakeAction';
import { useUserHasCreatedHousehold } from './useUserHasCreatedHousehold';

export interface CreateHouseholdFlowProps {
  isOpen: boolean;
  onClose: () => void;
  continueFrom?: CreateHouseholdFlowStep | null;
  continueFromHouseholdId?: string;
}

function CreateHouseholdFlowInner() {
  const { currentStep } = useCreateHouseholdFlowContext();

  switch (currentStep) {
    case CreateHouseholdFlowStep.Loading:
      return <SpinningIcon />;
    case CreateHouseholdFlowStep.OnboardingNoIntegration:
      return <OnboardingNoIntegration />;
    case CreateHouseholdFlowStep.FlowPicker:
      return <FlowPicker />;
    case CreateHouseholdFlowStep.ClientDataIntake:
    case CreateHouseholdFlowStep.ClientDataIntakeWithAIOnboarding:
      return null; // handled elsewhere by the CreateHouseholdModal
    case CreateHouseholdFlowStep.ConnectSelectProvider:
      return <ConnectSelectProvider />;
    case CreateHouseholdFlowStep.PostHouseholdCreation:
      return <PostClientCreation />;
    case CreateHouseholdFlowStep.EntityIntake:
      return null; // handled elsewhere by the CreateEntityShortFormModal
    case CreateHouseholdFlowStep.PostIngest:
      return null;
    default: {
      throw new UnreachableError({
        case: currentStep,
        message: `Invalid step: ${currentStep}`,
      });
    }
  }
}

/** Used when the user is logging in to Addepar -- after connecting, this query param should be checked to see if the modal should reopen */
export const PRE_OPEN_ONBOARDING_MODAL_PARAM =
  'preOpenOnboardingModal' as const;
/** Query param to provide a "continue from" step */
export const CONTINUE_ONBOARDING_FLOW_PARAM =
  'continueOnboardingFlowFrom' as const;

export function shouldPreopenOnboardingModal(): boolean {
  const params = new URLSearchParams(window.location.search);
  return params.get(PRE_OPEN_ONBOARDING_MODAL_PARAM) === 'true';
}

interface GetInitialStepParams {
  isOpen: boolean;
  continueFrom: CreateHouseholdFlowStep | null | undefined;
  canConnectToIntegrations: AssetIntegrationProviders[];
  hasCreatedHousehold: boolean;
  aiOnboardingEnabled: boolean;
  shouldPreopen: boolean;
}

export function getInitialStep({
  isOpen,
  continueFrom,
  canConnectToIntegrations,
  hasCreatedHousehold,
  aiOnboardingEnabled,
  shouldPreopen,
}: GetInitialStepParams): CreateHouseholdFlowStep {
  if (continueFrom) {
    return continueFrom;
  }

  if (!isOpen) {
    return CreateHouseholdFlowStep.Loading;
  }

  // shouldPreopen is specifically used for the integration login flow
  if (shouldPreopen) {
    if (canConnectToIntegrations.length) {
      return CreateHouseholdFlowStep.FlowPicker;
    }

    return CreateHouseholdFlowStep.ClientDataIntake;
  }

  if (canConnectToIntegrations.length) {
    return CreateHouseholdFlowStep.FlowPicker;
  }

  if (hasCreatedHousehold && !aiOnboardingEnabled) {
    return CreateHouseholdFlowStep.ClientDataIntake;
  }

  return CreateHouseholdFlowStep.OnboardingNoIntegration;
}

export function useInitialStep(
  isOpen: boolean,
  continueFrom: CreateHouseholdFlowStep | null | undefined
): {
  initialStep: CreateHouseholdFlowStep;
} {
  const aiOnboardingIsOn = useFeatureFlag('ai_onboarding');
  const aiCapabilitiesEnabled = useAICapabilitiesEnabled();
  const aiOnboardingEnabled = aiOnboardingIsOn && aiCapabilitiesEnabled;

  const { hasCreatedHousehold } = useUserHasCreatedHousehold();
  const { canConnectToIntegrations } = useIsAssetProviderIntegrationEnabled();

  const initialStep = getInitialStep({
    isOpen,
    continueFrom,
    canConnectToIntegrations,
    hasCreatedHousehold,
    aiOnboardingEnabled,
    // preOpen is specifically used for the integration login flow, where we
    // push users through an integration login and then redirect them to
    // onboarding
    shouldPreopen: shouldPreopenOnboardingModal(),
  });

  return { initialStep };
}

export function CreateHouseholdFlow({
  isOpen,
  onClose: onCloseProp,
  continueFrom,
  continueFromHouseholdId,
}: CreateHouseholdFlowProps) {
  const { navigate } = useNavigateToRoute();
  const { initialStep } = useInitialStep(isOpen, continueFrom);
  const [currentStep, setCurrentStep] = useState<CreateHouseholdFlowStep>(
    CreateHouseholdFlowStep.Loading
  );
  const [householdId, setHouseholdId] = useState<string | undefined>(
    continueFromHouseholdId
  );
  const previousOpen = usePrevious<boolean>(isOpen);
  const handlePostClientIntakeAction =
    useGetPostClientIntakeAction(currentStep);
  const { clearUploadedFiles, clearUploaderErrorMessage } =
    useMultiDocumentUploaderContext();

  useEffect(() => {
    if (isOpen && !previousOpen) {
      setCurrentStep(initialStep);
    }
  }, [initialStep, isOpen, previousOpen]);

  useEffect(() => {
    setCurrentStep(initialStep);
  }, [initialStep]);

  const onClose = useCallback(() => {
    setCurrentStep(CreateHouseholdFlowStep.Loading);
    clearUploadedFiles();
    clearUploaderErrorMessage();
    onCloseProp();
  }, [clearUploadedFiles, clearUploaderErrorMessage, onCloseProp]);

  return (
    <CreateHouseholdFlowContext.Provider
      value={{
        currentStep,
        setNextStep: setCurrentStep,
        onClose,
        householdId,
        setHouseholdId,
      }}
    >
      <Dialog
        open={
          isOpen &&
          currentStep !== CreateHouseholdFlowStep.ClientDataIntake &&
          currentStep !==
            CreateHouseholdFlowStep.ClientDataIntakeWithAIOnboarding
        }
        onClose={onClose}
      >
        <CreateHouseholdFlowInner />
      </Dialog>

      <CreateHouseholdModal
        isOpen={currentStep === CreateHouseholdFlowStep.ClientDataIntake}
        onClose={onClose}
        onAfterCreate={handlePostClientIntakeAction}
        showRelationshipToOtherPrimaryClient
      />
      <CreateHouseholdModal
        isOpen={
          currentStep ===
          CreateHouseholdFlowStep.ClientDataIntakeWithAIOnboarding
        }
        onClose={onClose}
        onAfterCreate={handlePostClientIntakeAction}
        showDocumentUploader
        showRelationshipToOtherPrimaryClient
      />
      {householdId && (
        <CreateEntityShortFormModal
          householdId={householdId}
          isOpen={currentStep === CreateHouseholdFlowStep.EntityIntake}
          onClose={onClose}
          onAfterCreate={() =>
            navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST, {
              householdId,
            })
          }
          isOnboardingFlow
        />
      )}
    </CreateHouseholdFlowContext.Provider>
  );
}
