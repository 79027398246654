import { ApolloError } from '@apollo/client';
import { compact } from 'lodash';
import { useCallback } from 'react';
import { flushSync } from 'react-dom';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { Modal } from '@/components/modals/Modal/Modal';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form/hooks';
import { useReportError } from '@/hooks/useReportError';
import { useSubBrandOptions } from '@/modules/admin/branding/hooks/useSubBrandOptions';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { PostClientIntakeActionType } from '@/modules/createHousehold/CreateHouseholdFlow/useGetPostClientIntakeAction';
import { usePreCreateHousehold } from '@/modules/createHousehold/CreateHouseholdModal/hooks/usePreCreateHousehold';
import { useMultiDocumentUploaderContext } from '@/modules/documents/MultiDocumentUploader/context/multiDocumentUploader.context';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { AsteriskRequiredLabel } from '@/modules/forms/formAccessories';
import {
  HouseholdFragment,
  useUpdateHouseholdAndCreateClientProfilesMutation,
} from '@/modules/household/graphql/Households.generated';
import { getHouseholdIntegration } from '@/modules/household/householdIntegrations';
import { ROUTE_KEYS } from '@/navigation/constants';
import {
  TAB_VALUE_FAMILY_TREE,
  TAB_VALUE_LIST_VIEW,
} from '@/types/ClientDetailsPeopleOrganizationsTabValue';
import { HouseholdBillableKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { mapFormToCreateHouseholdShape } from './CreateHouseholdModal.utils';
import { CreateHouseholdModalForm } from './CreateHouseholdModalForm';
import { useCreateHouseholdModalQueryQuery } from './graphql/CreateHouseholdModal.generated';
import {
  CreateHouseholdFormShape,
  emptyInitialCreateGrantorClientProfileValue,
} from './types';

export interface CreateHouseholdModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAfterCreate?: PostClientIntakeActionType;
  showDocumentUploader?: boolean;
  showRelationshipToOtherPrimaryClient?: boolean;
}

export function CreateHouseholdModal({
  isOpen,
  onClose,
  onAfterCreate,
  showDocumentUploader,
  showRelationshipToOtherPrimaryClient,
}: CreateHouseholdModalProps) {
  const currentUser = useCurrentUser();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { navigate } = useNavigateToRoute();
  const navigateToFamilyTree = useFeatureFlag('client_family_tree');

  const {
    isReady,
    preCreatedHouseholdId,
    error: preCreateError,
  } = usePreCreateHousehold({
    isOpen,
    needsPreCreateHousehold: true,
  });

  // We want to show the document uploader if this modal is being used for the
  // AI onboarding flow, and the pre-created household is ready. The household
  // ID is required to upload documents.
  const resolvedShowDocumentUploader =
    showDocumentUploader &&
    isReady &&
    !preCreateError &&
    !!preCreatedHouseholdId;

  const { uploadedFiles, setUploaderErrorMessage, clearUploaderErrorMessage } =
    useMultiDocumentUploaderContext();

  const initialValues: CreateHouseholdFormShape = {
    profiles: [emptyInitialCreateGrantorClientProfileValue],
    primaryRelationshipOwnerId: currentUser?.id ?? '',
    subBrandId: '',
    isProspect: false,
    integrationClientIds: [],
    relationshipToBetweenPrimaryClients: '',
  };

  const formMethods = useForm<CreateHouseholdFormShape>({
    defaultValues: initialValues,
  });

  const { reset, handleSubmit, formState, setShouldBlockNavigation } =
    formMethods;

  const { data } = useCreateHouseholdModalQueryQuery({
    onError: (error: ApolloError) => {
      showFeedback('Could not fetch list of possible relationship owners');
      reportError(
        'Caught exception fetching list of possible relationship owners',
        error
      );
    },
  });

  const handleCreateHouseholdComplete = useCallback(
    (household: HouseholdFragment | undefined | null) => {
      const householdId = household?.id;
      if (!householdId) {
        showFeedback('Could not create client. Please try again.');
        reportError('Could not get ID from created household');
        return;
      }

      showFeedback('Client created successfully', { variant: 'success' });
      // don't show the nav warning modal after save
      flushSync(() => {
        setShouldBlockNavigation(false);
      });

      if (onAfterCreate) {
        const connectedIntegration = getHouseholdIntegration(household);
        onAfterCreate(householdId, connectedIntegration, {
          isProspectHousehold:
            household.billableKind === HouseholdBillableKind.Prospect,
        });
      } else {
        navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_PEOPLE_ORGANIZATIONS, {
          householdId: householdId,
          tab: navigateToFamilyTree
            ? TAB_VALUE_FAMILY_TREE
            : TAB_VALUE_LIST_VIEW,
        });
      }
    },
    [
      navigate,
      navigateToFamilyTree,
      onAfterCreate,
      reportError,
      setShouldBlockNavigation,
      showFeedback,
    ]
  );

  /**
   * Use this mutation to update a household that was pre-created for the AI
   * onboarding flow. This is necessary to upload documents.
   */
  const [
    updateHouseholdAndCreateClientProfiles,
    { loading: updateHouseholdLoading },
  ] = useUpdateHouseholdAndCreateClientProfilesMutation({
    onError: (error: ApolloError) => {
      showFeedback(
        'Encountered an error creating a new client. Please try again.'
      );
      reportError(
        'Caught error when updating household as part of create flow',
        error
      );
    },
    onCompleted: (data) => handleCreateHouseholdComplete(data?.updateHousehold),
    refetchQueries: ['UserHasCreatedHouseholdsQuery'],
  });

  const subBrandOptions = useSubBrandOptions(getNodes(data?.subBrands));

  const onValidSubmit = useCallback(
    (values: CreateHouseholdFormShape) => {
      // this _theoretically_ shouldn't happen (since the button to trigger it will be disabled
      // if the pre-created household ID is not available), but make it sane & TS-safe
      if (!preCreatedHouseholdId) {
        showFeedback(
          'Could not create client.  Please wait a moment and try again.'
        );
        throw new Error('No pre-created household ID');
      }

      const variables = {
        input: mapFormToCreateHouseholdShape(values, preCreatedHouseholdId),
      };
      const { profiles, integrationClientIDs, ...rest } = variables.input;
      return updateHouseholdAndCreateClientProfiles({
        variables: {
          id: preCreatedHouseholdId,
          input: {
            ...rest,
            addIntegrationClientIDs: compact([...(integrationClientIDs || [])]),
          },
          clientProfileInputs: compact(profiles),
          relationshipToBetweenPrimaryClients:
            values.relationshipToBetweenPrimaryClients || undefined,
        },
      });
    },
    [
      preCreatedHouseholdId,
      showFeedback,
      updateHouseholdAndCreateClientProfiles,
    ]
  );

  const onSubmit = handleSubmit(onValidSubmit);

  return (
    <FormProvider {...formMethods}>
      <FormFieldsDisabledProvider isDisabled={updateHouseholdLoading}>
        <Modal
          heading="Create new client"
          rightHeaderContent={<AsteriskRequiredLabel />}
          isOpen={isOpen}
          onClose={() => {
            reset();
            onClose();
          }}
          fullHeight
          actions={
            <>
              <Button
                variant="secondary"
                size="sm"
                disabled={formState.isSubmitting}
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                loading={formState.isSubmitting}
                disabled={!preCreatedHouseholdId}
                onClick={() => {
                  clearUploaderErrorMessage();
                  if (resolvedShowDocumentUploader && !uploadedFiles.length) {
                    setUploaderErrorMessage(
                      'Please upload a document to continue'
                    );
                    return;
                  }
                  void onSubmit();
                }}
              >
                Create client
              </Button>
            </>
          }
        >
          <form noValidate onSubmit={onSubmit}>
            <CreateHouseholdModalForm
              subBrandOptions={subBrandOptions}
              showDocumentUploader={resolvedShowDocumentUploader}
              preCreatedHouseholdId={preCreatedHouseholdId}
              showRelationshipToOtherPrimaryClient={
                showRelationshipToOtherPrimaryClient
              }
            />
          </form>
        </Modal>
      </FormFieldsDisabledProvider>
    </FormProvider>
  );
}
