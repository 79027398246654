import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { AssetValuationV2ValuationReason } from '@/types/schema';

const copy: Record<AssetValuationV2ValuationReason, string> = {
  [AssetValuationV2ValuationReason.GratAnnuityPayment]: 'Annuity Payment',
  [AssetValuationV2ValuationReason.AssetRevaluation]: 'Asset Revaluation',
  [AssetValuationV2ValuationReason.InitialFunding]: 'Initial Funding Value',
  [AssetValuationV2ValuationReason.GratAssetSubstitution]: 'Asset Substitution',
  [AssetValuationV2ValuationReason.GratRemainderDistribution]:
    'Remainder Distribution',
};

const styles: Record<AssetValuationV2ValuationReason, BadgeVariants> = {
  [AssetValuationV2ValuationReason.GratAnnuityPayment]: BadgeVariants.Yellow,
  [AssetValuationV2ValuationReason.AssetRevaluation]: BadgeVariants.Gray,
  [AssetValuationV2ValuationReason.InitialFunding]: BadgeVariants.Teal,
  [AssetValuationV2ValuationReason.GratAssetSubstitution]: BadgeVariants.Teal,
  [AssetValuationV2ValuationReason.GratRemainderDistribution]:
    BadgeVariants.Teal,
};

interface Props {
  reason: AssetValuationV2ValuationReason;
}

export function ValuationReasonLabel({ reason }: Props) {
  return (
    <Badge
      variant={styles[reason]}
      display={copy[reason]}
      textTransform="none"
    />
  );
}
