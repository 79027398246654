// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MostRecentlyViewedWaterfallQueryVariables = Types.Exact<{
  lastViewedWaterfallWhereInput?: Types.InputMaybe<Types.LastViewedWaterfallWhereInput>;
}>;


export type MostRecentlyViewedWaterfallQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, localMostRecentlyViewedWaterfall?: { __typename?: 'MostRecentlyViewedWaterfall', id?: string | null } | null } | null };


export const MostRecentlyViewedWaterfallDocument = gql`
    query MostRecentlyViewedWaterfall($lastViewedWaterfallWhereInput: LastViewedWaterfallWhereInput) {
  currentUser {
    id
    localMostRecentlyViewedWaterfall(where: $lastViewedWaterfallWhereInput) @client {
      id
    }
  }
}
    `;

/**
 * __useMostRecentlyViewedWaterfallQuery__
 *
 * To run a query within a React component, call `useMostRecentlyViewedWaterfallQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostRecentlyViewedWaterfallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostRecentlyViewedWaterfallQuery({
 *   variables: {
 *      lastViewedWaterfallWhereInput: // value for 'lastViewedWaterfallWhereInput'
 *   },
 * });
 */
export function useMostRecentlyViewedWaterfallQuery(baseOptions?: Apollo.QueryHookOptions<MostRecentlyViewedWaterfallQuery, MostRecentlyViewedWaterfallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MostRecentlyViewedWaterfallQuery, MostRecentlyViewedWaterfallQueryVariables>(MostRecentlyViewedWaterfallDocument, options);
      }
export function useMostRecentlyViewedWaterfallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MostRecentlyViewedWaterfallQuery, MostRecentlyViewedWaterfallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MostRecentlyViewedWaterfallQuery, MostRecentlyViewedWaterfallQueryVariables>(MostRecentlyViewedWaterfallDocument, options);
        }
export function useMostRecentlyViewedWaterfallSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MostRecentlyViewedWaterfallQuery, MostRecentlyViewedWaterfallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MostRecentlyViewedWaterfallQuery, MostRecentlyViewedWaterfallQueryVariables>(MostRecentlyViewedWaterfallDocument, options);
        }
export type MostRecentlyViewedWaterfallQueryHookResult = ReturnType<typeof useMostRecentlyViewedWaterfallQuery>;
export type MostRecentlyViewedWaterfallLazyQueryHookResult = ReturnType<typeof useMostRecentlyViewedWaterfallLazyQuery>;
export type MostRecentlyViewedWaterfallSuspenseQueryHookResult = ReturnType<typeof useMostRecentlyViewedWaterfallSuspenseQuery>;
export type MostRecentlyViewedWaterfallQueryResult = Apollo.QueryResult<MostRecentlyViewedWaterfallQuery, MostRecentlyViewedWaterfallQueryVariables>;
export const Operations = {
  Query: {
    MostRecentlyViewedWaterfall: 'MostRecentlyViewedWaterfall' as const
  }
}