import Decimal from 'decimal.js';

import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { formatEnumCase } from '@/utils/formatting/strings';

import { EstateTaxSummaryPanel_NodeFragment } from './graphql/EstateTaxSummaryPanel.generated';

export function getTotalTaxableEstate(
  nodes: EstateTaxSummaryPanel_NodeFragment[]
) {
  return nodes.reduce((acc, node) => {
    if (node.taxableValue) {
      return acc.plus(node.taxableValue);
    }
    return acc;
  }, new Decimal(0));
}

export function getTotalGiftTaxDue(
  nodes: EstateTaxSummaryPanel_NodeFragment[]
) {
  return sumDecimalJS(
    nodes.map((n) => n.transferredToGiftingTax || new Decimal(0))
  );
}

export function getTaxableGiftsMade(
  giftTax: Decimal,
  federalEstateTaxPercent: Decimal | null | undefined,
  lifetimeExemptionAppliedTowardsGiftTax: Decimal | null | undefined
): Decimal {
  // (x - lifetimeExemptionAppliedTowardsGiftTax) * 0.4 = giftTax
  return giftTax
    .abs()
    .dividedBy(federalEstateTaxPercent?.div(100) ?? new Decimal(0.4))
    .plus(lifetimeExemptionAppliedTowardsGiftTax ?? new Decimal(0));
}

export function getEstateTaxDue(nodes: EstateTaxSummaryPanel_NodeFragment[]) {
  return sumDecimalJS(
    nodes.map((n) => n.transferredToEstateTax || new Decimal(0))
  );
}

export function getNodeDisplayValues(node: EstateTaxSummaryPanel_NodeFragment) {
  let displayName = '';
  let type = '';

  if (node.node.__typename === 'Entity') {
    displayName = node.node.subtype.displayName;
    type = node.node.extendedDisplayKind;
  } else if (node.node.__typename === 'TestamentaryEntity') {
    displayName = node.node.displayName;
    type = formatEnumCase(node.node.testamentaryEntityKind);
  } else if (node.node.__typename === 'ClientProfile') {
    displayName = node.node.displayName;
    type = 'Individual';
  }

  return { displayName, type };
}
