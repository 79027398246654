// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateIntegrationConfigurationMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateIntegrationConfigurationInput;
}>;


export type UpdateIntegrationConfigurationMutation = { __typename?: 'Mutation', updateIntegrationConfiguration?: { __typename?: 'IntegrationConfiguration', id: string, addeparSubdomain?: string | null, addeparFirmID?: string | null, blackDiamondEnabled: boolean, orionEnabled: boolean, hasCustomOpenAIAPIKey: boolean } | null };

export type AdminIntegrationsPage_IntegrationConfigurationFragment = { __typename?: 'IntegrationConfiguration', id: string, addeparSubdomain?: string | null, addeparFirmID?: string | null, addeparClientType: Types.IntegrationConfigurationAddeparClientType, orionEnabled: boolean, updatedAt: Time, blackDiamondEnabled: boolean, hasCustomOpenAIAPIKey: boolean };

export type GetIntegrationConfigurationQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetIntegrationConfigurationQuery = { __typename?: 'Query', integrationConfiguration?: { __typename?: 'IntegrationConfiguration', id: string, addeparSubdomain?: string | null, addeparFirmID?: string | null, addeparClientType: Types.IntegrationConfigurationAddeparClientType, orionEnabled: boolean, updatedAt: Time, blackDiamondEnabled: boolean, hasCustomOpenAIAPIKey: boolean } | null, isIntegrationUserLoggedInToAddepar: { __typename?: 'IsLoggedInToAddepar', isLoggedIn: boolean, isTokenValid: boolean }, isIntegrationUserLoggedInToBlackDiamond: { __typename?: 'IsLoggedIn', isLoggedIn: boolean, isTokenValid: boolean }, isIntegrationUserLoggedInToOrion: { __typename?: 'IsLoggedIn', isLoggedIn: boolean, isTokenValid: boolean } };

export const AdminIntegrationsPage_IntegrationConfigurationFragmentDoc = gql`
    fragment AdminIntegrationsPage_IntegrationConfiguration on IntegrationConfiguration {
  id
  addeparSubdomain
  addeparFirmID
  addeparClientType
  orionEnabled
  updatedAt
  blackDiamondEnabled
  hasCustomOpenAIAPIKey
}
    `;
export const UpdateIntegrationConfigurationDocument = gql`
    mutation UpdateIntegrationConfiguration($input: AugmentedUpdateIntegrationConfigurationInput!) {
  updateIntegrationConfiguration(input: $input) {
    id
    addeparSubdomain
    addeparFirmID
    blackDiamondEnabled
    orionEnabled
    hasCustomOpenAIAPIKey
  }
}
    `;
export type UpdateIntegrationConfigurationMutationFn = Apollo.MutationFunction<UpdateIntegrationConfigurationMutation, UpdateIntegrationConfigurationMutationVariables>;

/**
 * __useUpdateIntegrationConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateIntegrationConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIntegrationConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIntegrationConfigurationMutation, { data, loading, error }] = useUpdateIntegrationConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIntegrationConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIntegrationConfigurationMutation, UpdateIntegrationConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIntegrationConfigurationMutation, UpdateIntegrationConfigurationMutationVariables>(UpdateIntegrationConfigurationDocument, options);
      }
export type UpdateIntegrationConfigurationMutationHookResult = ReturnType<typeof useUpdateIntegrationConfigurationMutation>;
export type UpdateIntegrationConfigurationMutationResult = Apollo.MutationResult<UpdateIntegrationConfigurationMutation>;
export type UpdateIntegrationConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateIntegrationConfigurationMutation, UpdateIntegrationConfigurationMutationVariables>;
export const GetIntegrationConfigurationDocument = gql`
    query GetIntegrationConfiguration {
  integrationConfiguration {
    ...AdminIntegrationsPage_IntegrationConfiguration
  }
  isIntegrationUserLoggedInToAddepar {
    isLoggedIn
    isTokenValid
  }
  isIntegrationUserLoggedInToBlackDiamond {
    isLoggedIn
    isTokenValid
  }
  isIntegrationUserLoggedInToOrion {
    isLoggedIn
    isTokenValid
  }
}
    ${AdminIntegrationsPage_IntegrationConfigurationFragmentDoc}`;

/**
 * __useGetIntegrationConfigurationQuery__
 *
 * To run a query within a React component, call `useGetIntegrationConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIntegrationConfigurationQuery(baseOptions?: Apollo.QueryHookOptions<GetIntegrationConfigurationQuery, GetIntegrationConfigurationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntegrationConfigurationQuery, GetIntegrationConfigurationQueryVariables>(GetIntegrationConfigurationDocument, options);
      }
export function useGetIntegrationConfigurationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntegrationConfigurationQuery, GetIntegrationConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntegrationConfigurationQuery, GetIntegrationConfigurationQueryVariables>(GetIntegrationConfigurationDocument, options);
        }
export function useGetIntegrationConfigurationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIntegrationConfigurationQuery, GetIntegrationConfigurationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIntegrationConfigurationQuery, GetIntegrationConfigurationQueryVariables>(GetIntegrationConfigurationDocument, options);
        }
export type GetIntegrationConfigurationQueryHookResult = ReturnType<typeof useGetIntegrationConfigurationQuery>;
export type GetIntegrationConfigurationLazyQueryHookResult = ReturnType<typeof useGetIntegrationConfigurationLazyQuery>;
export type GetIntegrationConfigurationSuspenseQueryHookResult = ReturnType<typeof useGetIntegrationConfigurationSuspenseQuery>;
export type GetIntegrationConfigurationQueryResult = Apollo.QueryResult<GetIntegrationConfigurationQuery, GetIntegrationConfigurationQueryVariables>;
export const Operations = {
  Query: {
    GetIntegrationConfiguration: 'GetIntegrationConfiguration' as const
  },
  Mutation: {
    UpdateIntegrationConfiguration: 'UpdateIntegrationConfiguration' as const
  },
  Fragment: {
    AdminIntegrationsPage_IntegrationConfiguration: 'AdminIntegrationsPage_IntegrationConfiguration' as const
  }
}