import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Accordion, AccordionProps } from '@/components/Accordion/Accordion';
import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { useFormContext } from '@/components/react-hook-form';
import { useEntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { BENEFICIARY_LEVEL_DISPLAY_NAMES } from '@/modules/beneficiaries/beneficiaries.constants';
import { COLORS } from '@/styles/tokens/colors';

import {
  EntityBeneficiariesFormBeneficiary,
  EntityBeneficiariesFormBodyProps,
  EntityBeneficiariesFormNamespace,
  EntityBeneficiariesFormSecondNamespace,
} from './EntityBeneficiariesSubform.types';
import {
  EntityBeneficiaryFormBodyV2,
  EntityBeneficiaryFormBodyV2Props,
  getBeneficiaryFormFieldName,
} from './EntityBeneficiaryFormBodyV2';

interface UseAccordionPropsInput {
  fieldName: `${EntityBeneficiariesFormNamespace}.${EntityBeneficiariesFormSecondNamespace}.${number}`;
  beneficiaryOptions: SelectInputProps['options'];
  beneficiaryIndex: number;
  listItemId: string;
}

function useAccordionProps({
  fieldName,
  beneficiaryOptions,
  beneficiaryIndex,
  listItemId,
}: UseAccordionPropsInput): Omit<AccordionProps, 'children'> {
  const { control } = useFormContext<EntityBeneficiariesFormBeneficiary>();

  const beneficiaryId = useWatch({
    name: getBeneficiaryFormFieldName(fieldName, 'beneficiaryId'),
    control,
  });

  const level = useWatch({
    name: getBeneficiaryFormFieldName(fieldName, 'level'),
    control,
  });

  const title = useMemo(() => {
    const beneficiary = beneficiaryOptions.find(
      (b) => b.value === beneficiaryId
    );

    if (beneficiary && 'display' in beneficiary) {
      return beneficiary.display;
    }

    return `Beneficiary ${beneficiaryIndex + 1}`;
  }, [beneficiaryId, beneficiaryOptions, beneficiaryIndex]);

  const subtitle = useMemo(() => {
    if (!level) return '';

    return BENEFICIARY_LEVEL_DISPLAY_NAMES[level];
  }, [level]);

  return {
    variant: 'filled',
    title,
    subtitle,
    isDefaultExpanded: !beneficiaryId,
    hideSubtitleOnOpen: !subtitle,
    id: listItemId,
  };
}

const useAccordionStyles = makeStyles()((theme) => ({
  root: {
    transition: theme.transitions.create(['border'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    backgroundColor: COLORS.GRAY[100],
    border: `1px solid transparent !important`,
    '&:hover': {
      border: `1px solid ${COLORS.GRAY[300]} !important`,
    },
    '&.MuiAccordion-rounded': {
      borderRadius: '6px !important',
    },
  },
}));

export function EntityBeneficiaryFormBody(
  props: EntityBeneficiariesFormBodyProps & { listItemId: string }
) {
  const { control } = useFormContext<EntityBeneficiariesFormBeneficiary>();
  const fieldName =
    `${props.namespace}.${props.secondNamespace}.${props.i}` as const;

  const { classes } = useAccordionStyles();

  const { acknowledgedSuggestions, previouslyAcknowledgedSuggestions } =
    useEntitySuggestionsContext();
  const beneficiaryId = useWatch({
    name: getBeneficiaryFormFieldName(fieldName, 'beneficiaryId'),
    control,
  });

  // We want to filter out suggestions that have been acknowledged previously and only
  // consider suggestions that have been acknowledged during this edit session
  const newAcknowledgedSuggestions = useMemo(() => {
    if (!acknowledgedSuggestions || !previouslyAcknowledgedSuggestions)
      return [];

    return Object.values(acknowledgedSuggestions).filter(
      (suggestion) =>
        !Object.values(previouslyAcknowledgedSuggestions).some(
          (prevSuggestion) =>
            prevSuggestion.clientProfileId === suggestion.clientProfileId &&
            prevSuggestion.clientOrganizationId ===
              suggestion.clientOrganizationId
        )
    );
  }, [acknowledgedSuggestions, previouslyAcknowledgedSuggestions]);

  // Checks to see if the beneficiary has been populated from AI suggestions during this edit session
  const isPopulatedFromAI = useMemo(() => {
    if (
      newAcknowledgedSuggestions.some(
        (suggestion) =>
          suggestion.clientProfileId === beneficiaryId ||
          suggestion.clientOrganizationId === beneficiaryId
      )
    ) {
      return true;
    }
    return false;
  }, [beneficiaryId, newAcknowledgedSuggestions]);

  if (
    props.secondNamespace === 'leadBeneficiary' ||
    props.secondNamespace === 'incomeBeneficiary'
  ) {
    throw new Error(
      'This component should not be used for charitable beneficiaries'
    );
  }

  const v2Props: EntityBeneficiaryFormBodyV2Props = useMemo(
    () => ({
      beneficiaryOptions: props.beneficiaryOptions,
      householdId: props.householdId,
      variant: props.variant,
      splitScreen: props.splitScreen,
      additionalItemRendererProps: props.additionalItemRendererProps,
      fieldName,
      beneficiaryIndex: props.i,
      remove: props.remove,
    }),
    [
      fieldName,
      props.additionalItemRendererProps,
      props.beneficiaryOptions,
      props.householdId,
      props.i,
      props.remove,
      props.splitScreen,
      props.variant,
    ]
  );

  const accordionProps = useAccordionProps({
    fieldName,
    beneficiaryOptions: props.beneficiaryOptions,
    beneficiaryIndex: props.i,
    listItemId: props.listItemId,
  });

  return (
    <Accordion
      {...accordionProps}
      isDefaultExpanded={isPopulatedFromAI || !beneficiaryId} // If the beneficiary is populated from AI or if the form is empty, we want to expand the accordion
      expanded={isPopulatedFromAI}
      classes={{ root: classes.root }}
    >
      <EntityBeneficiaryFormBodyV2 {...v2Props} />
    </Accordion>
  );
}
