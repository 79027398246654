import { Box } from '@mui/material';
import { useMemo } from 'react';

import { SpinningIcon } from '@/components/animation/SpinningIcon/SpinningIcon';
import { AppNotificationLinearProgress } from '@/components/AppNotifications/AppNotificationLinearProgress';
import { AppNotificationListItem } from '@/components/AppNotifications/AppNotificationListItem';
import { Button } from '@/components/form/baseInputs/Button';
import { ArrowRightIcon } from '@/components/icons/ArrowRightIcon';
import { CheckCircleIcon } from '@/components/icons/CheckCircleIcon';
import { RefreshCw05Icon } from '@/components/icons/RefreshCw05Icon';
import { useEstimatedProgress } from '@/components/progress/ProgressBar/useEstimatedProgress';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import {
  AsyncJobNotificationInfo,
  AsyncJobNotificationStatus,
} from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';
import { COLORS } from '@/styles/tokens/colors';

interface AIOnboardingNotificationItemProps {
  notification: AsyncJobNotificationInfo;
  onCompletedActionClick: () => void;
}

export function AIOnboardingNotificationItem({
  notification,
  onCompletedActionClick,
}: AIOnboardingNotificationItemProps) {
  const trackUserEvent = useTrackUserEvent();

  const {
    status,
    household,
    estimatedDurationMs,
    newNotificationMessage,
    completedNotificationMessage,
    asyncJob,
  } = notification;

  const { title, subtitle, icon, completedButtonText, completedButtonOnClick } =
    useMemo(() => {
      return {
        title:
          status === AsyncJobNotificationStatus.COMPLETED
            ? completedNotificationMessage
            : newNotificationMessage,
        subtitle: `for ${household.displayName}`,
        icon:
          status === AsyncJobNotificationStatus.COMPLETED ? (
            <CheckCircleIcon
              color={COLORS.FUNCTIONAL.SUCCESS.DEFAULT}
              size={16}
            />
          ) : (
            <SpinningIcon Icon={RefreshCw05Icon} size={16} />
          ),
        completedButtonText: 'Review details',
        completedButtonOnClick: () => {
          onCompletedActionClick();
          trackUserEvent('ai_onboarding review button clicked', {
            source: 'notification-item',
            kgRootId: notification.id,
            householdId: household.id,
          });
        },
      };
    }, [
      completedNotificationMessage,
      household.displayName,
      household.id,
      newNotificationMessage,
      notification.id,
      onCompletedActionClick,
      status,
      trackUserEvent,
    ]);

  const isCompleted = status === AsyncJobNotificationStatus.COMPLETED;
  const progress = useEstimatedProgress({
    startTime: asyncJob.createdAt.getTime(),
    estimatedDuration: estimatedDurationMs,
    isCompleted,
  });

  return (
    <AppNotificationListItem icon={icon} title={title} subtitle={subtitle}>
      {isCompleted && (
        <Box>
          <Button
            variant="primary"
            size="sm"
            sx={{ marginTop: 2 }}
            endIcon={ArrowRightIcon}
            onClick={completedButtonOnClick}
          >
            {completedButtonText}
          </Button>
        </Box>
      )}
      {status === AsyncJobNotificationStatus.IN_PROGRESS && (
        <AppNotificationLinearProgress
          variant="determinate"
          value={progress}
          sx={{ marginTop: 1 }}
        />
      )}
    </AppNotificationListItem>
  );
}
