import { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { COMBINED } from '@/modules/assetValuation/useAssetValuation';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { CharitableTrustDesignerPreTaxReturnModel } from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesignerAnalysis/CharitableTrustDesignerAnalysis.types';
import { GiftingProposalSelectedPreTaxReturnCategory } from '@/types/schema';

import { ProposalKind } from './useProposalsQuery';

export enum SlideType {
  ScenarioDetail = 'ScenarioDetail',
  StrategyOverview = 'StrategyOverview',
  CombinedOverview = 'CombinedOverview',
  CoverPage = 'CoverPage',
  ImpactComparison = 'ImpactComparison',
  // Gift proposal types
  GiftCoverPage = 'GiftCoverPage',
  GiftOverview = 'GiftOverview',
  GiftProjectedWealth = 'GiftProjectedWealth',
  CharitableCoverPage = 'CharitableCoverPage',
  CharitableOverview = 'CharitableOverview',
  CharitableAnalysis = 'CharitableAnalysis',
  CharitableDistributionSchedule = 'CharitableDistributionSchedule',
}
export interface Slide {
  name: string;
  type: SlideType;
  params: {
    entity?: string;
    proposalScenario?: string;
    entityProposal?: string;
    slide?: SlideType;
    preTaxCategory?: GiftingProposalSelectedPreTaxReturnCategory;
    charitablePreTaxCategory?: CharitableTrustDesignerPreTaxReturnModel;
    yearIdx?: number;
  };
}

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function isSelectedSlideFromParams(
  slide: Slide,
  searchParams: URLSearchParams
): boolean {
  const strategySearchParam = searchParams.get('entity') || undefined;
  const proposalScenarioSearchParam = searchParams.get('ps') || undefined;
  const { params: slideParams } = slide;
  return (
    slideParams.proposalScenario === proposalScenarioSearchParam &&
    slideParams.entity === strategySearchParam
  );
}

export function getEntitySlides(proposal?: ProposalFragment | null) {
  const slides: Slide[] = [
    {
      name: 'Intro',
      type: SlideType.CoverPage,
      params: {},
    },
  ];

  slides.push({
    name: 'Combined overview',
    type: SlideType.CombinedOverview,
    params: {
      entity: COMBINED,
    },
  });

  proposal?.entityProposals?.forEach((entityProposal) => {
    slides.push({
      name: entityProposal.entity.gratTrust?.displayName ?? '',
      type: SlideType.StrategyOverview,
      params: {
        entity: entityProposal.entity.id,
      },
    });

    entityProposal?.proposalScenarios?.forEach((proposalScenario, idx) => {
      slides.push({
        name: `Scenario ${idx + 1}`,
        type: SlideType.ScenarioDetail,
        params: {
          entity: entityProposal.entity.id,
          proposalScenario: proposalScenario.id,
          entityProposal: entityProposal.id,
        },
      });
    });

    const numberOfScenarios = entityProposal?.proposalScenarios?.length ?? 0;

    if (numberOfScenarios > 1) {
      slides.push({
        name: 'Comparing impact',
        type: SlideType.ImpactComparison,
        params: {
          entity: entityProposal.entity.id,
          proposalScenario: 'compare',
        },
      });
    }
  });

  return slides;
}

export function getGiftSlides(isPrint: boolean) {
  const giftProjectedWealthSlidesBundle = (() => {
    if (!isPrint) {
      return [
        {
          name: 'Projected wealth',
          type: SlideType.GiftProjectedWealth,
          params: {
            slide: SlideType.GiftProjectedWealth,
          },
        },
      ];
    }

    const printGiftProjectedWealthSlides: Slide[] = [];
    for (const preTaxCategory of [
      GiftingProposalSelectedPreTaxReturnCategory.Low,
      GiftingProposalSelectedPreTaxReturnCategory.Medium,
      GiftingProposalSelectedPreTaxReturnCategory.High,
    ]) {
      for (let yearIdx = 0; yearIdx < 3; yearIdx++) {
        printGiftProjectedWealthSlides.push({
          name: 'Projected wealth',
          type: SlideType.GiftProjectedWealth,
          params: {
            slide: SlideType.GiftProjectedWealth,
            preTaxCategory,
            yearIdx,
          },
        });
      }
    }

    return printGiftProjectedWealthSlides;
  })();

  const slides: Slide[] = [
    {
      name: 'Intro',
      type: SlideType.GiftCoverPage,
      params: {},
    },
    {
      name: 'Scenario overview',
      type: SlideType.GiftOverview,
      params: {
        slide: SlideType.GiftOverview,
      },
    },
    ...giftProjectedWealthSlidesBundle,
  ];

  return slides;
}

export function getCharitableSlides(
  proposal: ProposalFragment | null | undefined,
  isPrint: boolean
): Slide[] {
  const slides: Slide[] = [
    {
      name: 'Intro',
      type: SlideType.CharitableCoverPage,
      params: {},
    },
    {
      name: 'Analysis overview',
      type: SlideType.CharitableOverview,
      params: {
        slide: SlideType.CharitableOverview,
      },
    },
  ];

  if (isPrint) {
    const preTaxCategories: CharitableTrustDesignerPreTaxReturnModel[] = [
      'low',
      'medium',
      'high',
    ];
    for (const preTaxCategory of preTaxCategories) {
      for (let yearIdx = 0; yearIdx < 3; yearIdx++) {
        slides.push({
          name: `${proposal?.displayName} Analysis`,
          type: SlideType.CharitableAnalysis,
          params: {
            slide: SlideType.CharitableAnalysis,
            charitablePreTaxCategory: preTaxCategory,
            yearIdx,
          },
        });
      }
    }
  } else {
    slides.push({
      name: `${proposal?.displayName} Analysis`,
      type: SlideType.CharitableAnalysis,
      params: {
        slide: SlideType.CharitableAnalysis,
      },
    });
  }

  slides.push({
    name: 'Distribution schedule',
    type: SlideType.CharitableDistributionSchedule,
    params: {
      slide: SlideType.CharitableDistributionSchedule,
    },
  });

  return slides;
}

export const SLIDE_PARAM = 'slide' as const;

interface UsePresentationProps {
  proposal?: ProposalFragment | null;
  proposalKind: ProposalKind | null;
}

export function usePresentation({
  proposal,
  proposalKind,
}: UsePresentationProps): {
  slides: Slide[];
  currentSlideIdx: number;
  currentSlide: Slide | null | undefined;
} {
  const [currentSlideIdx, setCurrentSlideIdx] = useState<number>(-1);
  const [searchParams] = useSearchParams();
  const viewOnly = useViewOnly();

  const slides = useMemo(() => {
    switch (proposalKind) {
      case ProposalKind.Entity:
        return getEntitySlides(proposal);
      case ProposalKind.Gift:
        return getGiftSlides(!!viewOnly);
      case ProposalKind.CLT:
      case ProposalKind.CRT:
        return getCharitableSlides(proposal, !!viewOnly);
      default:
        return [];
    }
  }, [proposal, proposalKind, viewOnly]);

  useEffect(() => {
    let currentSlideIdx = -1;
    switch (proposalKind) {
      case ProposalKind.Entity: {
        const currentSlides = slides.reduce((acc, slide, idx) => {
          if (isSelectedSlideFromParams(slide, searchParams)) {
            currentSlideIdx = idx;
            return [...acc, slide];
          }
          return acc;
        }, [] as Slide[]);

        if (currentSlides.length > 1) {
          throw new Error('Found multiple current slides');
        }
        break;
      }
      case ProposalKind.Gift:
      case ProposalKind.CRT:
      case ProposalKind.CLT: {
        const slideType = searchParams.get(SLIDE_PARAM) as SlideType;
        if (!slideType) {
          currentSlideIdx = 0;
        } else {
          currentSlideIdx = slides.findIndex(
            (slide) => slide.params[SLIDE_PARAM] === slideType
          );
        }
        break;
      }
      default:
        break;
    }

    setCurrentSlideIdx(currentSlideIdx);
  }, [slides, searchParams, proposalKind]);

  return {
    slides,
    currentSlideIdx,
    currentSlide: slides[currentSlideIdx],
  };
}
