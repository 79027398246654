import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

import {
  EstateTaxScenarios_ClientProfileFragment,
  EstateTaxScenarios_EntityFragment,
  EstateTaxScenarios_EstateTaxScenarioFragment,
  EstateTaxScenarios_HouseholdFragment,
  EstateTaxScenarios_PayoutOrderEntityFragment,
  EstateTaxScenarios_TestamentaryEntityFragment,
} from '../graphql/EstateTaxScenarios.generated';

export interface EstateTaxScenariosContext {
  loading: boolean;
  scenarios: EstateTaxScenarios_EstateTaxScenarioFragment[];
  scenariosByPrimaryClientId: Record<
    string,
    EstateTaxScenarios_EstateTaxScenarioFragment | undefined
  >;
  household: EstateTaxScenarios_HouseholdFragment;
  primaryClients: EstateTaxScenarios_ClientProfileFragment[];
  primaryClientsById: Record<
    string,
    EstateTaxScenarios_ClientProfileFragment | undefined
  >;
  entities: EstateTaxScenarios_EntityFragment[];
  entitiesById: Record<string, EstateTaxScenarios_EntityFragment | undefined>;
  testamentaryEntities: EstateTaxScenarios_TestamentaryEntityFragment[];
  activeClientId: string;
  isTwoClientHousehold: boolean;
  defaultPayoutOrderByPrimaryClientId: Record<
    string,
    {
      first: EstateTaxScenarios_PayoutOrderEntityFragment[];
      second: EstateTaxScenarios_PayoutOrderEntityFragment[];
    }
  >;
}

export const EstateTaxScenariosContext =
  createContext<EstateTaxScenariosContext>({
    loading: true,
    scenarios: [],
    household: {} as EstateTaxScenariosContext['household'],
    scenariosByPrimaryClientId:
      {} as EstateTaxScenariosContext['scenariosByPrimaryClientId'],
    primaryClients: [],
    primaryClientsById: {} as EstateTaxScenariosContext['primaryClientsById'],
    entities: [],
    entitiesById: {} as EstateTaxScenariosContext['entitiesById'],
    testamentaryEntities: [],
    activeClientId: '',
    isTwoClientHousehold: false,
    defaultPayoutOrderByPrimaryClientId: {},
  });

export const useEstateTaxScenariosContext = () => {
  return useGuardedContext(
    EstateTaxScenariosContext,
    'EstateTaxScenariosProvider'
  );
};
