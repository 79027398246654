import { Skeleton, Stack, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { compact } from 'lodash';
import React from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import { SubtleLabelItem } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { Button } from '@/components/form/baseInputs/Button';
import { BankIcon } from '@/components/icons/BankIcon';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { ArrowCard } from '@/components/layout/ArrowCard/ArrowCard';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useUpdateAnnuityPaymentsAndCompleteTaskMutation } from '@/modules/gratAnnuities/GRATAnnuitiesSubform/graphql/AnnuityPayments.generated';
import {
  GetGratDetailsQuery,
  useGetGratDetailsQuery,
} from '@/modules/gratAnnuities/GRATAnnuitiesSubform/graphql/GetGratDetails.generated';
import { GRATAnnuitiesSubform } from '@/modules/gratAnnuities/GRATAnnuitiesSubform/GRATAnnuitiesSubform';
import {
  useGetNPVDetails,
  useQueryAnnuitiesDetails,
} from '@/modules/gratAnnuities/GRATAnnuitiesSubform/hooks/useQueryAnnuitiesDetails';
import { useSyncAnnuitiesDataToForm } from '@/modules/gratAnnuities/GRATAnnuitiesSubform/hooks/useSyncAnnuitiesDataToForm';
import {
  Annuity,
  NAMESPACE as ANNUITIES_SUBFORM_NAMESPACE,
} from '@/modules/gratAnnuities/GRATAnnuitiesSubform/types';
import { generateAnnuityUpdates } from '@/modules/gratAnnuities/GRATAnnuitiesSubform/utils/mutationUtils';
import * as diagnostics from '@/utils/diagnostics';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { GetImplementationTasksV2Document } from '../../graphql/ImplementationTasks.generated';
import { TaskHeaderCard } from '../../TaskHeaderCard';
import { TaskBodyProps } from '../../types';

interface AnnuityForm {
  [ANNUITIES_SUBFORM_NAMESPACE]: {
    annuities: Annuity[];
  };
}

function getStrategyInstanceFromNode(data?: GetGratDetailsQuery) {
  if (data?.node?.__typename !== 'Entity') return null;
  return data.node.gratTrust;
}

const {
  FormComponent: AnnuitiesForm,
  defaultValues: annuitiesFormDefaultValues,
} = new GRATAnnuitiesSubform();

export function ConfirmAnnuityPaymentsTaskBody({
  task,
  footer,
  onCompleteTask,
}: TaskBodyProps) {
  const theme = useTheme();
  const { showFeedback } = useFeedback();

  // mutations
  const [updateAnnuitiesAndCompleteTask, { loading }] =
    useUpdateAnnuityPaymentsAndCompleteTaskMutation();

  // hooks
  const formProperties = useForm<AnnuityForm>({
    defaultValues: {
      [ANNUITIES_SUBFORM_NAMESPACE]: annuitiesFormDefaultValues,
    },
  });

  const { handleSubmit, setValue, watch } = formProperties;

  const annuitiesWatcher = watch('annuitiesSubform');

  // queries
  const { data } = useGetGratDetailsQuery({
    variables: {
      nodeId: task.entityId,
    },
    // we need to make sure the data is fresh here because there was a caching bug that caused this task to believe that annuities hadn't
    // been created yet, when in fact they'd been created by an edit through the EntityFormTrowser in the design task.
    fetchPolicy: 'network-only',
  });

  const grat = getStrategyInstanceFromNode(data);
  const { annuitiesData } = useQueryAnnuitiesDetails(grat);
  useSyncAnnuitiesDataToForm(
    setValue,
    annuitiesData,
    grat ?? null,
    grat?.termDurationYears ?? null,
    grat?.taxableGift ?? null
  );

  // compact because it takes a render cycle for the payment amounts to be calculated/populated
  const annuitiesPaymentAmounts = compact(
    annuitiesWatcher?.annuities?.map((annuity) => {
      return annuity.paymentAmount;
    })
  );

  const { npv } = useGetNPVDetails(
    grat?.officialInterestRatePercent ?? null,
    annuitiesPaymentAmounts
  );

  if (!grat) {
    return <TaskHeaderCard task={task} footer={footer} loading={true} />;
  }

  const taxableGift = npv ? grat.initialFundingValue?.minus(npv) : null;
  const onValidSubmission: SubmitHandler<AnnuityForm> = async (values) => {
    try {
      // this should never happen, but let's guarantee that
      if (!grat?.termDurationYears) {
        throw new Error(
          'unsafely attempting to submit the form without a loaded strategy'
        );
      }

      if (!taxableGift) {
        throw new Error(
          'unsafely attempting to submit the form without a defined taxableGift'
        );
      }

      // TODO: re-enable with LUM-1228
      // if (taxableGift.lessThan(new Decimal(0))) {
      //   showFeedback('The taxable gift amount cannot be negative.');
      //   return;
      // }

      const annuityInput = generateAnnuityUpdates(values);

      // clear the GRAT's annuities and create new ones
      await updateAnnuitiesAndCompleteTask({
        variables: {
          taskID: task.id,
          input: {
            id: task.entityId,
            update: {},
            updateGratTrust: {
              id: grat.id,
              update: {
                taxableGift,
                clearAnnuities: true,
              },
              withAnnuities: annuityInput.create,
            },
          },
        },
        refetchQueries: [GetImplementationTasksV2Document],
      });

      onCompleteTask();
    } catch (err) {
      diagnostics.diagnostics.error(
        'Failed to save AnnuityPayments changes',
        err as Error
      );
      showFeedback('Sorry, we failed to save your data. Please try again.');
    }
  };

  const submitHandler = handleSubmit(onValidSubmission);

  return (
    <FormProvider {...formProperties}>
      <TaskHeaderCard task={task} footer={footer}>
        <Stack spacing={4} component="form" noValidate onSubmit={submitHandler}>
          <AnnuitiesForm
            householdId={task.householdId}
            variant="initialFundingPercentAndPaymentAmount"
            termDurationYears={grat?.termDurationYears || 0}
            subformValues={annuitiesWatcher}
            initialFundingValue={grat?.initialFundingValue ?? new Decimal(0)}
          />
          <Stack
            direction="row"
            spacing={3}
            alignItems="center"
            justifyContent="center"
          >
            <Stack direction="row" alignItems="center" spacing={1}>
              <BankIcon color={theme.palette.primary.main} />
              <SubtleLabelItem
                value={formatCurrency(
                  grat.initialFundingValue ?? new Decimal(0)
                )}
                label="Initial funding value"
                invertLabel
              />
            </Stack>
            <SubtleLabelItem
              value={`${formatPercent(
                grat.officialInterestRatePercent || new Decimal(0),
                1
              )}%`}
              label="7520 rate"
              invertLabel
            />
            <ArrowCard>
              <Stack spacing={1}>
                <SubtleLabelItem
                  value={npv ? formatCurrency(npv) : <Skeleton />}
                  label="Grantor-retained interest"
                  invertLabel
                />
                <SubtleLabelItem
                  value={
                    taxableGift ? formatCurrency(taxableGift) : <Skeleton />
                  }
                  label="Taxable gift"
                  invertLabel
                />
              </Stack>
            </ArrowCard>
          </Stack>
          <Button
            disabled={loading}
            variant="primary"
            fullWidth
            size="sm"
            type="submit"
            startIcon={CheckIcon}
          >
            I confirm that these details are correct
          </Button>
        </Stack>
      </TaskHeaderCard>
    </FormProvider>
  );
}
