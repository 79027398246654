import { CompoundComponentProps } from '@/types/common';

import { Footer, MainHeading, SecondaryHeading } from './accessoryComponents';
import { PresentationSlide as Slide } from './PresentationSlide';

export const PresentationSlide = {
  Slide,
  MainHeading,
  SecondaryHeading,
  Footer,
};

export type PresentationSlideProps = CompoundComponentProps<
  typeof PresentationSlide
>;
