import { Box, Stack, Typography } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';

export interface FullScreenFormLayoutProps {
  heading: React.ReactNode;
  Content: React.ReactElement;
  Footer: React.ReactElement;
}

interface ModalFooterProps {
  disabled?: boolean;
  onRequestClose: () => void;
  submitCopy: string;
}

export function ModalFooter({
  disabled,
  onRequestClose,
  submitCopy,
}: ModalFooterProps) {
  return (
    <Footer
      leftAction={
        <Button
          disabled={disabled}
          onClick={onRequestClose}
          variant="secondary"
          size="md"
        >
          Exit without saving
        </Button>
      }
      rightAction={
        <Button disabled={disabled} type="submit" variant="primary" size="md">
          {submitCopy}
        </Button>
      }
    />
  );
}

export function FullScreenFormLayout({
  heading,
  Content,
  Footer,
}: FullScreenFormLayoutProps) {
  return (
    <Box>
      <Stack
        paddingTop={3}
        paddingX={18}
        height={`calc(100vh - ${FOOTER_HEIGHT}px)`}
        overflow="auto"
      >
        <Typography variant="h1">{heading}</Typography>
        <Box my={3}>{Content}</Box>
      </Stack>
      <Box>{Footer}</Box>
    </Box>
  );
}
