import { ClientProfileRelationshipType } from '@/types/schema';

export const emptyInitialCreateGrantorClientProfileValue: CreateClientProfileFormShape =
  {
    firstName: '',
    lastName: '',
    email: '',
    address: {
      cityName: '',
      stateCode: '',
    },
    isGrantor: true,
    isTrustee: true,
    isBeneficiary: true,
  };

export interface CreateClientProfileFormShape {
  firstName: string;
  lastName: string;
  email: string;
  address: {
    cityName: string;
    stateCode: string;
  };
  isGrantor: boolean;
  isTrustee: boolean;
  isBeneficiary: boolean;
}

export interface CreateHouseholdFormShape {
  primaryRelationshipOwnerId: string;
  subBrandId: string;
  integrationClientIds: string[];
  profiles: CreateClientProfileFormShape[];
  isProspect: boolean;
  relationshipToBetweenPrimaryClients: ClientProfileRelationshipType | '';
}
