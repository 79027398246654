import { includes, isEmpty } from 'lodash';

import { EntityDetail_IntegrationEntityFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { IntegrationEntityKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { AssetIntegrationProviders } from './constants';
import { AssetIntegration_EntityFragment } from './graphql/assetProviderIntegration.fragments.generated';

export function entityHasAssetsIntegration(
  entity: AssetIntegration_EntityFragment | undefined | null
) {
  return !isEmpty(
    entity?.integrationEntities?.filter((ie) =>
      includes(RELEVANT_INTEGRATION_TYPES, ie.kind)
    ) ?? []
  );
}

export function getIntegrationEntityProvider(
  integrationEntities: EntityDetail_IntegrationEntityFragment[] | null
): AssetIntegrationProviders | null {
  // we have a guarantee in the backend that all integration entities for a given entity will be of the same provider,
  // so we only need to check the first one
  const integrationEntityKind = integrationEntities?.[0]?.kind;
  if (!integrationEntityKind) return null;

  switch (integrationEntityKind) {
    case IntegrationEntityKind.BlackDiamondAccount:
      return AssetIntegrationProviders.BLACK_DIAMOND;
    case IntegrationEntityKind.BlackDiamondPortfolio:
      return AssetIntegrationProviders.BLACK_DIAMOND;
    case IntegrationEntityKind.Addepar:
      return AssetIntegrationProviders.ADDEPAR;
    case IntegrationEntityKind.Orion:
      return AssetIntegrationProviders.ORION;
    case IntegrationEntityKind.CsvImport:
      return AssetIntegrationProviders.CSV_IMPORT;

    default:
      throw new UnreachableError({
        case: integrationEntityKind,
        message: `Unknown integration entity kind: ${integrationEntityKind}`,
      });
  }
}

export function getEntityAssetsIntegrationProvider(
  entity: AssetIntegration_EntityFragment | undefined | null
): AssetIntegrationProviders | null {
  // we only expect there to be one integration entity per entity, except for the CSV import scenario,
  // but even in that scenario we only care about the first one
  const integrationEntities = getIntegrationEntitiesFromEntity(entity ?? null);
  return getIntegrationEntityProvider(integrationEntities);
}

const RELEVANT_INTEGRATION_TYPES: IntegrationEntityKind[] = [
  IntegrationEntityKind.Addepar,
  IntegrationEntityKind.BlackDiamondAccount,
  IntegrationEntityKind.BlackDiamondPortfolio,
];

/**
 * @description Get all of the non-CSV integration entities for a given entity.
 */
export function getIntegrationEntitiesFromEntity(
  entity: AssetIntegration_EntityFragment | null
) {
  if (
    !entity?.integrationEntities ||
    entity?.integrationEntities.length === 0
  ) {
    return [];
  }

  return entity.integrationEntities.filter((ie) =>
    includes(RELEVANT_INTEGRATION_TYPES, ie.kind)
  );
}
