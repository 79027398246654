import { useMemo } from 'react';

import {
  TrustAdvisorLevel,
  TrustAdvisorRoleKind,
  TrusteeDutyKind,
  TrusteeLevel,
} from '@/types/schema';

import { SubformValues } from '../TrustDetailsSubform.types';
import {
  hasSuccessorTrustees,
  hasTrustAdvisors,
  hasTrustees,
} from '../TrustDetailsSubform.utils';

export function useNotes({
  subformValues,
  isTrusteesAndSuccessTrusteesOnly,
}: {
  subformValues: SubformValues | null;
  isTrusteesAndSuccessTrusteesOnly: boolean;
}) {
  return useMemo(() => {
    const shouldShowTrusteeNotes = (() => {
      if (
        subformValues &&
        hasTrustees(subformValues) &&
        !isTrusteesAndSuccessTrusteesOnly
      ) {
        const trustees = subformValues.trustees;
        return trustees.some(
          (t) => t.duties?.includes(TrusteeDutyKind.Special) && !!t.trusteeId
        );
      }

      return false;
    })();

    const shouldShowSuccessorTrusteeNotes = (() => {
      if (
        subformValues &&
        hasSuccessorTrustees(subformValues) &&
        !isTrusteesAndSuccessTrusteesOnly
      ) {
        const successorTrustees = subformValues.successorTrustees;
        return successorTrustees.some(
          (st) =>
            (st.level === TrusteeLevel.Other ||
              st.duties?.includes(TrusteeDutyKind.Special)) &&
            !!st.successorTrusteeId
        );
      }

      return false;
    })();

    // Returns true if there are trust advisors with a level of other or a role of other
    const shouldShowTrustAdvisorNotes = (() => {
      if (subformValues && hasTrustAdvisors(subformValues)) {
        const trustAdvisors = subformValues.trustAdvisors ?? [];
        return trustAdvisors.some(
          (ta) =>
            (ta.level === TrustAdvisorLevel.Other ||
              ta.role?.includes(TrustAdvisorRoleKind.Other)) &&
            !!ta.trustAdvisorId
        );
      }

      return false;
    })();

    return {
      shouldShowTrusteeNotes,
      shouldShowSuccessorTrusteeNotes,
      shouldShowTrustAdvisorNotes,
    };

    // stringify to force subformValues changes to actually trigger this hook; they don't otherwise
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subformValues), isTrusteesAndSuccessTrusteesOnly]);
}
