import {
  MenuItem,
  MenuItemProps,
} from '@/components/poppers/MenuPopper/MenuItem';

import { usePrintEntity } from './usePrintEntity';

export interface PrintEntityExportMenuItemProps extends Partial<MenuItemProps> {
  label: string;
  householdId: string;
  entityId: string;
  entityDisplayName: string;
}

export function PrintEntityExportMenuItem({
  householdId,
  entityId,
  entityDisplayName,
  ...buttonProps
}: PrintEntityExportMenuItemProps) {
  const { loading, handlePrintAndDownloadDocument } = usePrintEntity(
    entityId,
    householdId,
    entityDisplayName
  );

  return (
    <MenuItem
      loading={loading}
      onClick={handlePrintAndDownloadDocument}
      {...buttonProps}
    />
  );
}
