// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { ClientProfessionalTeam_ClientProfileFragmentDoc } from './ClientProfessionalTeam.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateClientProfilesMutationVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
  inputs: Array<Types.InputMaybe<Types.AugmentedUpdateClientProfileInput>> | Types.InputMaybe<Types.AugmentedUpdateClientProfileInput>;
}>;


export type UpdateClientProfilesMutation = { __typename?: 'Mutation', updateClientProfiles: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, legalName: string, professionalTeamRoles: { __typename?: 'ProfessionalTeamRoleConnection', edges?: Array<{ __typename?: 'ProfessionalTeamRoleEdge', node?: { __typename?: 'ProfessionalTeamRole', id: string, kind: Types.ProfessionalTeamRoleKind, otherRoleName?: string | null, powerOfAttorneyKind?: Types.ProfessionalTeamRolePowerOfAttorneyKind | null, household?: { __typename?: 'Household', id: string } | null, associatedClientIndividual?: { __typename?: 'ClientProfile', id: string, displayName: string, legalName: string } | null } | null } | null> | null } } | null> };


export const UpdateClientProfilesDocument = gql`
    mutation UpdateClientProfiles($householdId: ID!, $inputs: [AugmentedUpdateClientProfileInput]!) {
  updateClientProfiles(householdId: $householdId, inputs: $inputs) {
    id
    ...ClientProfessionalTeam_ClientProfile
  }
}
    ${ClientProfessionalTeam_ClientProfileFragmentDoc}`;
export type UpdateClientProfilesMutationFn = Apollo.MutationFunction<UpdateClientProfilesMutation, UpdateClientProfilesMutationVariables>;

/**
 * __useUpdateClientProfilesMutation__
 *
 * To run a mutation, you first call `useUpdateClientProfilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientProfilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientProfilesMutation, { data, loading, error }] = useUpdateClientProfilesMutation({
 *   variables: {
 *      householdId: // value for 'householdId'
 *      inputs: // value for 'inputs'
 *   },
 * });
 */
export function useUpdateClientProfilesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientProfilesMutation, UpdateClientProfilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientProfilesMutation, UpdateClientProfilesMutationVariables>(UpdateClientProfilesDocument, options);
      }
export type UpdateClientProfilesMutationHookResult = ReturnType<typeof useUpdateClientProfilesMutation>;
export type UpdateClientProfilesMutationResult = Apollo.MutationResult<UpdateClientProfilesMutation>;
export type UpdateClientProfilesMutationOptions = Apollo.BaseMutationOptions<UpdateClientProfilesMutation, UpdateClientProfilesMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateClientProfiles: 'UpdateClientProfiles' as const
  }
}