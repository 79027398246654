import { Stack, Typography } from '@mui/material';
import { compact } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { Download01Icon } from '@/components/icons/Download01Icon';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { ActionButtonRenderer } from '@/components/tables/DataTable/renderers/cell/ActionButtonRenderer';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { useEntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { useDocumentDownload } from '@/modules/files/hooks/useFileDownload';
import { LIFETIME_EXCLUSION_KIND_COPY_MAP } from '@/modules/gifting/gifting.constants';
import { useAICapabilitiesEnabled } from '@/modules/tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatDateToMMDDYY } from '@/utils/formatting/dates';

import { DocumentFragment } from '../../graphql/DocumentFragment.generated';
import { AssociatedEntitiesRightContent } from '../components/AssociatedEntitiesRightContent';
import { CopyDocumentTextActionItem } from '../components/CopyDocumentTextActionItem';
import { DeleteDocumentActionItem } from '../components/DeleteDocumentActionItem';
import { DocumentsTableRowData } from '../DocumentsTable.types';

function getAssociatedEntities(document: DocumentFragment) {
  if (document.entity?.subtype?.displayName) {
    return document.entity?.subtype?.displayName;
  }

  if (document.lifetimeExclusionEvent?.length) {
    return document.lifetimeExclusionEvent
      ?.map((event) =>
        compact([
          formatDateToMMDDYY(event.eventDate),
          event.grantor.displayName,
          LIFETIME_EXCLUSION_KIND_COPY_MAP[event.kind],
          event.giftAmount ? formatCurrencyNoDecimals(event.giftAmount) : null,
        ]).join(', ')
      )
      .join(' | ');
  }

  return '';
}

interface MapDocumentsToRowsAndColumnsOpts {
  onEditClick: (documentId: string) => void;
  refetch: () => void;
  hideGoToEntityActionItem: boolean;
  entityId?: string;
}

interface DocumentBadgesProps {
  isDefaultOfEntity: boolean;
  hasAiDataExtraction: boolean;
}

function DocumentBadges({
  isDefaultOfEntity,
  hasAiDataExtraction,
}: DocumentBadgesProps) {
  const aiEnabled = useAICapabilitiesEnabled();
  return (
    <Stack direction="row" gap={1}>
      {isDefaultOfEntity && (
        <Badge variant={BadgeVariants.Primary} display="Default" />
      )}
      {/* Check for tenant-level AI enabled when displaying badge */}
      {aiEnabled && hasAiDataExtraction && (
        <Badge
          variant={BadgeVariants.Teal}
          display="AI data extraction"
          whiteSpace="nowrap"
        />
      )}
    </Stack>
  );
}

export function useMapDocumentsToRowsAndColumns(
  documents: DocumentFragment[],
  opts: MapDocumentsToRowsAndColumnsOpts
) {
  const [downloadingDocumentIds, setDownloadingDocumentIds] = useState<
    string[]
  >([]);
  const [downloadDocument] = useDocumentDownload();
  const handleDownloadDocument = useCallback(
    async (documentId: string) => {
      setDownloadingDocumentIds((prev) => [...prev, documentId]);
      await downloadDocument(documentId);
      setDownloadingDocumentIds((prev) =>
        prev.filter((id) => id !== documentId)
      );
    },
    [downloadDocument]
  );

  const currentUser = useCurrentUser();
  // using `hideGoToEntityActionItem` as a proxy to see if this is shown for entity (true) or household (false)
  const viewMode = opts.hideGoToEntityActionItem ? 'entity' : 'household';

  const { refetchSuggestions } = useEntitySuggestionsContext();

  const actions: Column<DocumentsTableRowData> = useMemo(() => {
    return {
      field: 'actions',
      headerName: '',
      width: 64,
      minWidth: 64,
      maxWidth: 64,
      sortable: false,
      align: 'center',
      renderCell: ActionButtonRenderer({
        loading: (row) => downloadingDocumentIds.includes(row.id as string),
        actions: compact([
          currentUser?.isInternalUser && {
            MenuItem: (row) => <CopyDocumentTextActionItem row={row} />,
          },
          {
            label: () => 'Download document',
            handleClick: (row) => handleDownloadDocument(row.id),
            icon: () => <Download01Icon size={16} />,
          },
          {
            MenuItem: (row) => (
              <DeleteDocumentActionItem
                row={row}
                modalProps={{
                  children:
                    row.associatedEntities.length >= 2 ? (
                      <Typography variant="subtitle1">
                        Deleting will remove this document from all shared
                        entities. To remove it for this entity only, unselect it
                        from the shared list.
                      </Typography>
                    ) : undefined,
                }}
                onDelete={() => {
                  opts?.refetch();
                  if (row.associatedEntity?.id && row.hasAiDataExtraction) {
                    // When deleting a document that has an entity with suggestions,
                    // refetch the suggestions, so the AIStatusBanner can update
                    // the banner state. Like don't show the banner if this was
                    // the only document with suggestions.
                    refetchSuggestions({
                      entityID: row.associatedEntity.id,
                    });
                  }
                }}
              />
            ),
          },
        ]),
      }),
    };
  }, [
    currentUser?.isInternalUser,
    downloadingDocumentIds,
    handleDownloadDocument,
    opts,
    refetchSuggestions,
  ]);

  return useMemo(() => {
    const columns: Column<DocumentsTableRowData>[] = [
      {
        field: 'fileName',
        headerName: 'Document',
        width: 300,
        renderCell: TwoLineTextRenderer({
          lineOne: ({ row }) => row.fileName,
          lineOneProps: { variant: 'h5' },
          lineTwo: ({ row }) =>
            viewMode === 'entity' ? (
              <DocumentBadges
                isDefaultOfEntity={
                  row.isDefaultOfEntity &&
                  row.associatedEntity?.id === opts.entityId
                }
                hasAiDataExtraction={row.hasAiDataExtraction}
              />
            ) : undefined,
        }),
        flex: 1,
      },
      {
        field: 'associatedEntity',
        headerName: 'Entities',
        width: 300,
        renderCell: TextRenderer({
          text: ({ row }) =>
            compact([
              row.associatedEntity?.description,
              ...row.associatedEntities.map(
                (associatedEntity) => associatedEntity.description
              ),
              ...row.associatedTestamentaryEntities.map(
                (associatedTestamentaryEntity) =>
                  associatedTestamentaryEntity.description
              ),
            ]).join(', '),
          textProps: {
            textOverflow: 'ellipsis',
          },
          textTitle: ({ row }) =>
            compact([
              row.associatedEntity?.description,
              ...row.associatedEntities.map(
                (associatedEntity) => associatedEntity.description
              ),
            ]).join(', '),
          textAccompaniment: ({ row }) => (
            <AssociatedEntitiesRightContent
              associatedEntities={compact([
                row.associatedEntity,
                ...row.associatedEntities,
                ...row.associatedTestamentaryEntities,
              ])}
            />
          ),
        }),
        flex: 1,
      },
      {
        field: 'documentType',
        headerName: 'Type',
        width: 160,
      },
      {
        field: 'createdAt',
        headerName: 'Uploaded',
        width: 160,
        renderCell: TwoLineTextRenderer({
          lineOne: ({ row }) => formatDateToMMDDYY(row.createdAt),
          lineTwo: ({ row }) => row.createdBy,
        }),
      },
      actions,
      {
        field: 'icon',
        headerName: '',
        align: 'center',
        width: 64,
        minWidth: 64,
        maxWidth: 64,
        renderCell: IconRenderer({
          icon: ChevronRightIcon,
        }),
        sortable: false,
      },
    ];

    const rows: DocumentsTableRowData[] = documents.map((document) => {
      return {
        id: document.id,
        fileName: document.name,
        associatedEntity: document.entity
          ? {
              id: document.entity.id,
              description: getAssociatedEntities(document),
              extendedDisplayKind: document.entity.extendedDisplayKind,
            }
          : null,
        associatedEntities: compact(document.additionalEntities).map(
          (entity) => ({
            id: entity.id,
            description: entity.subtype?.displayName ?? '',
            extendedDisplayKind: entity.extendedDisplayKind,
          })
        ),
        associatedTestamentaryEntities: compact(
          document.additionalTestamentaryEntities
        ).map((entity) => ({
          id: entity.id,
          description: entity.displayName,
        })),
        documentType: document.displayType,
        createdAt: document.file?.createdAt,
        createdBy: document.file?.user?.displayName,
        aiSummary: document.documentSummary?.summary ?? null,
        isDefaultOfEntity: !!document.defaultDocumentOfEntity,
        hasAiDataExtraction: !!document.enableAiSuggestions,
      };
    });

    return { rows, columns };
  }, [actions, documents, opts.entityId, viewMode]);
}
