import { Stack } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { Card } from '@/components/layout/Card/Card';
import { Loader } from '@/components/progress/Loader/Loader';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { CreateEstateWaterfallButton } from '@/modules/estateWaterfall/components/EstateWaterfallActionButtons/CreateEstateWaterfallButton';
import { WaterfallEmptyState } from '@/modules/estateWaterfall/components/WaterfallEmptyState';

const useStyles = makeStyles()((_theme) => ({
  childrenWrapper: {
    height: '100%',
  },
}));

interface ClientDetailsEstateWaterfallPlaceholderCardProps {
  loading: boolean;
}

export function ClientDetailsEstateWaterfallPlaceholderCard({
  loading,
}: ClientDetailsEstateWaterfallPlaceholderCardProps) {
  const householdId = useRequiredParam('householdId');
  const { classes } = useStyles();

  return (
    <Stack height="100%">
      <SubpageLayout
        heading="Estate Waterfall"
        classes={{ childrenWrapper: classes.childrenWrapper }}
      >
        <Card variant="outlined" sx={{ p: 6, height: '100%', width: '100%' }}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            {loading ? (
              <Loader
                boxProps={{
                  sx: { textAlign: 'center' },
                }}
                circularProgressProps={{ size: 40 }}
              />
            ) : (
              <WaterfallEmptyState
                heading="Create an estate waterfall map"
                description="Create unique & personalized visualizations to show dispositions and estate tax implications."
                action={
                  <CreateEstateWaterfallButton
                    householdId={householdId}
                    waterfallId={null}
                    variant="primary"
                    size="lg"
                    startIcon={undefined}
                  />
                }
              />
            )}
          </Stack>
        </Card>
      </SubpageLayout>
    </Stack>
  );
}
