import {
  AccessParameterFrequency,
  AccessParameterKind,
  BeneficiaryDistributionDistributionKind,
  BeneficiaryPowerOfAppointmentPower,
  ScheduledDistributionFrequency,
  ScheduledDistributionKind,
} from '@/types/schema';

import { AccessParameterRadioGroupKind } from './EntityBeneficiariesSubform.types';

export const BENEFICIARIES_SUBFORM = 'beneficiariesSubform' as const;
export const LIFETIME_BENEFICIARIES_SUBFORM =
  'lifetimeBeneficiariesSubform' as const;
export const REMAINDER_BENEFICIARIES_SUBFORM =
  'remainderBeneficiariesSubform' as const;
export const LEAD_BENEFICIARY_SUBFORM = 'leadBeneficiarySubform' as const;
export const INCOME_BENEFICIARY_SUBFORM = 'incomeBeneficiarySubform' as const;
export const BENEFICIARIES_AFTER_BOTH_GRANTORS_DIE =
  'beneficiariesAfterBothGrantorsDie' as const;

export const ENTITY_BENEFICIARIES_FORM_VARIANTS = [
  'percentOnly',
  'default',
  'leadBeneficiary',
  'incomeBeneficiary',
  'personalAccount',
  'nonTrustCharitableEntity',
  'testamentaryEntity',
] as const;

export const ACCESS_PARAMETER_KINDS: {
  display: string;
  value: AccessParameterKind;
}[] = [
  {
    display: '%',
    value: AccessParameterKind.Percentage,
  },
  {
    display: '$',
    value: AccessParameterKind.Amount,
  },
];

export const ACCESS_PARAMETER_RADIO_GROUP_KINDS: {
  label: string;
  value: AccessParameterRadioGroupKind;
}[] = [
  {
    label: 'Full access',
    value: AccessParameterRadioGroupKind.Full,
  },
  {
    label: '$ or %',
    value: AccessParameterRadioGroupKind.Partial,
  },
  {
    label: 'Other',
    value: AccessParameterRadioGroupKind.Other,
  },
];

export const UNITRUST_TYPE_ITEMS: {
  label: string;
  summaryLabel: string;
  value: BeneficiaryDistributionDistributionKind;
}[] = [
  {
    label: 'Standard',
    summaryLabel: 'standard',
    value: BeneficiaryDistributionDistributionKind.Percentage,
  },
  {
    label: 'Net income (NI)',
    summaryLabel: 'net income',
    value: BeneficiaryDistributionDistributionKind.NetIncome,
  },
  {
    label: 'Net income with makeup (NIM)',
    summaryLabel: 'net income with makeup',
    value: BeneficiaryDistributionDistributionKind.NetIncomeWithMakeup,
  },
];

export const UNITRUST_TYPE_ITEMS_V2: {
  label: string;
  summaryLabel: string;
  value: AccessParameterKind;
}[] = [
  {
    label: 'Standard',
    summaryLabel: 'standard',
    value: AccessParameterKind.Percentage,
  },
  {
    label: 'Net income (NI)',
    summaryLabel: 'net income',
    value: AccessParameterKind.NetIncome,
  },
  {
    label: 'Net income with makeup (NIM)',
    summaryLabel: 'net income with makeup',
    value: AccessParameterKind.NetIncomeWithMakeup,
  },
];

export const BENEFICIARY_TYPE_ITEMS: {
  display: string;
  value: BeneficiaryDistributionDistributionKind;
}[] = [
  {
    display: '%',
    value: BeneficiaryDistributionDistributionKind.Percentage,
  },
  {
    display: '$',
    value: BeneficiaryDistributionDistributionKind.Amount,
  },
  {
    display: 'Other',
    value: BeneficiaryDistributionDistributionKind.Other,
  },
];

export const SCHEDULED_DISTRIBUTION_KINDS: {
  display: string;
  value: ScheduledDistributionKind;
}[] = [
  {
    display: '%',
    value: ScheduledDistributionKind.Percentage,
  },
  {
    display: '$',
    value: ScheduledDistributionKind.Amount,
  },
  {
    display: 'ƒ(x)',
    value: ScheduledDistributionKind.AllIncome,
  },
];

export const SCHEDULED_DISTRIBUTION_FREQUENCY_ITEMS: {
  display: string;
  value: ScheduledDistributionFrequency;
}[] = [
  {
    display: 'One time',
    value: ScheduledDistributionFrequency.OneTime,
  },
  {
    display: 'Annually',
    value: ScheduledDistributionFrequency.Annually,
  },
  {
    display: 'Semiannually',
    value: ScheduledDistributionFrequency.Semiannually,
  },
  {
    display: 'Quarterly',
    value: ScheduledDistributionFrequency.Quarterly,
  },
  {
    display: 'Monthly',
    value: ScheduledDistributionFrequency.Monthly,
  },
];

export const POWER_OF_APPOINTMENT_ITEMS: {
  display: string;
  value: BeneficiaryPowerOfAppointmentPower;
}[] = [
  {
    display: 'General',
    value: BeneficiaryPowerOfAppointmentPower.General,
  },
  {
    display: 'Special',
    value: BeneficiaryPowerOfAppointmentPower.Special,
  },
  {
    display: 'Other',
    value: BeneficiaryPowerOfAppointmentPower.Other,
  },
];

export const ACCESS_PARAMETER_FREQUENCY_ITEMS: {
  display: string;
  value: AccessParameterFrequency;
}[] = [
  {
    display: 'Annually',
    value: AccessParameterFrequency.Annually,
  },
  {
    display: 'Semiannually',
    value: AccessParameterFrequency.Semiannually,
  },
  {
    display: 'Quarterly',
    value: AccessParameterFrequency.Quarterly,
  },
  {
    display: 'Monthly',
    value: AccessParameterFrequency.Monthly,
  },
];

export const validUnitrustTypes = UNITRUST_TYPE_ITEMS.map((item) => item.value);
export const validUnitrustTypesV2 = UNITRUST_TYPE_ITEMS_V2.map(
  (item) => item.value
);
