import { useSearchParams } from 'react-router-dom';

export const DISPLAY_NAME_SEARCH_PARAM_NAME = 'displayName';

export function useBasicInformationDesignerDataFromSearch() {
  const [searchParams] = useSearchParams();

  return {
    displayName: searchParams.get(DISPLAY_NAME_SEARCH_PARAM_NAME) ?? '',
  };
}
