// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { CharitableTrustDesignerIllustrationData_NoPlanProjectionYearlyFragmentDoc } from './CharitableTrustDesignerIllustrationData.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CharitableTrustDesignerDistributionTableData_CrtProposalYearlyProjectionFragment = { __typename?: 'CRTProposalYearlyProjection', year: number, beginningOfYear: CurrencyUSD, growth: CurrencyUSD, payout: CurrencyUSD, remainderValue: CurrencyUSD };

export type CharitableTrustDesignerDistributionTableData_CltProposalYearlProjectionFragment = { __typename?: 'CLTProposalYearlyProjection', year: number, beginningOfYear: CurrencyUSD, growth: CurrencyUSD, payout: CurrencyUSD, remainderValue: CurrencyUSD };

export type CharitableTrustDesignerDistributionTableDataQueryVariables = Types.Exact<{
  crtProjectionInput: Types.CrtProposalProjectionInput;
  cltProjectionInput: Types.CltProposalProjectionInput;
  noPlanProjectionInput: Types.NoPlanProjectionInput;
  isCRT: Types.Scalars['Boolean']['input'];
  isOpen: Types.Scalars['Boolean']['input'];
}>;


export type CharitableTrustDesignerDistributionTableDataQuery = { __typename?: 'Query', crtDistributionTableProjection?: { __typename?: 'CRTProposalProjection', yearly?: Array<{ __typename?: 'CRTProposalYearlyProjection', year: number, beginningOfYear: CurrencyUSD, growth: CurrencyUSD, payout: CurrencyUSD, remainderValue: CurrencyUSD }> }, cltDistributionTableProjection?: { __typename?: 'CLTProposalProjection', yearly?: Array<{ __typename?: 'CLTProposalYearlyProjection', year: number, beginningOfYear: CurrencyUSD, growth: CurrencyUSD, payout: CurrencyUSD, remainderValue: CurrencyUSD }> }, noPlanDistributionTableProjection: { __typename?: 'NoPlanProjection', yearly?: Array<{ __typename?: 'NoPlanProjectionYearly', endOfYear: CurrencyUSD, year: number }> } };

export const CharitableTrustDesignerDistributionTableData_CrtProposalYearlyProjectionFragmentDoc = gql`
    fragment CharitableTrustDesignerDistributionTableData_CRTProposalYearlyProjection on CRTProposalYearlyProjection {
  year
  beginningOfYear
  growth
  payout
  remainderValue
}
    `;
export const CharitableTrustDesignerDistributionTableData_CltProposalYearlProjectionFragmentDoc = gql`
    fragment CharitableTrustDesignerDistributionTableData_CLTProposalYearlProjection on CLTProposalYearlyProjection {
  year
  beginningOfYear
  growth
  payout
  remainderValue
}
    `;
export const CharitableTrustDesignerDistributionTableDataDocument = gql`
    query CharitableTrustDesignerDistributionTableData($crtProjectionInput: CRTProposalProjectionInput!, $cltProjectionInput: CLTProposalProjectionInput!, $noPlanProjectionInput: NoPlanProjectionInput!, $isCRT: Boolean!, $isOpen: Boolean!) {
  crtDistributionTableProjection: crtProposalProjection(
    input: $crtProjectionInput
  ) @include(if: $isCRT) {
    yearly @include(if: $isOpen) {
      ...CharitableTrustDesignerDistributionTableData_CRTProposalYearlyProjection
    }
  }
  cltDistributionTableProjection: cltProposalProjection(
    input: $cltProjectionInput
  ) @skip(if: $isCRT) {
    yearly @include(if: $isOpen) {
      ...CharitableTrustDesignerDistributionTableData_CLTProposalYearlProjection
    }
  }
  noPlanDistributionTableProjection: noPlanProjection(
    input: $noPlanProjectionInput
  ) {
    yearly @include(if: $isOpen) {
      ...CharitableTrustDesignerIllustrationData_NoPlanProjectionYearly
    }
  }
}
    ${CharitableTrustDesignerDistributionTableData_CrtProposalYearlyProjectionFragmentDoc}
${CharitableTrustDesignerDistributionTableData_CltProposalYearlProjectionFragmentDoc}
${CharitableTrustDesignerIllustrationData_NoPlanProjectionYearlyFragmentDoc}`;

/**
 * __useCharitableTrustDesignerDistributionTableDataQuery__
 *
 * To run a query within a React component, call `useCharitableTrustDesignerDistributionTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useCharitableTrustDesignerDistributionTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCharitableTrustDesignerDistributionTableDataQuery({
 *   variables: {
 *      crtProjectionInput: // value for 'crtProjectionInput'
 *      cltProjectionInput: // value for 'cltProjectionInput'
 *      noPlanProjectionInput: // value for 'noPlanProjectionInput'
 *      isCRT: // value for 'isCRT'
 *      isOpen: // value for 'isOpen'
 *   },
 * });
 */
export function useCharitableTrustDesignerDistributionTableDataQuery(baseOptions: Apollo.QueryHookOptions<CharitableTrustDesignerDistributionTableDataQuery, CharitableTrustDesignerDistributionTableDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CharitableTrustDesignerDistributionTableDataQuery, CharitableTrustDesignerDistributionTableDataQueryVariables>(CharitableTrustDesignerDistributionTableDataDocument, options);
      }
export function useCharitableTrustDesignerDistributionTableDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CharitableTrustDesignerDistributionTableDataQuery, CharitableTrustDesignerDistributionTableDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CharitableTrustDesignerDistributionTableDataQuery, CharitableTrustDesignerDistributionTableDataQueryVariables>(CharitableTrustDesignerDistributionTableDataDocument, options);
        }
export function useCharitableTrustDesignerDistributionTableDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CharitableTrustDesignerDistributionTableDataQuery, CharitableTrustDesignerDistributionTableDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CharitableTrustDesignerDistributionTableDataQuery, CharitableTrustDesignerDistributionTableDataQueryVariables>(CharitableTrustDesignerDistributionTableDataDocument, options);
        }
export type CharitableTrustDesignerDistributionTableDataQueryHookResult = ReturnType<typeof useCharitableTrustDesignerDistributionTableDataQuery>;
export type CharitableTrustDesignerDistributionTableDataLazyQueryHookResult = ReturnType<typeof useCharitableTrustDesignerDistributionTableDataLazyQuery>;
export type CharitableTrustDesignerDistributionTableDataSuspenseQueryHookResult = ReturnType<typeof useCharitableTrustDesignerDistributionTableDataSuspenseQuery>;
export type CharitableTrustDesignerDistributionTableDataQueryResult = Apollo.QueryResult<CharitableTrustDesignerDistributionTableDataQuery, CharitableTrustDesignerDistributionTableDataQueryVariables>;
export const Operations = {
  Query: {
    CharitableTrustDesignerDistributionTableData: 'CharitableTrustDesignerDistributionTableData' as const
  },
  Fragment: {
    CharitableTrustDesignerDistributionTableData_CRTProposalYearlyProjection: 'CharitableTrustDesignerDistributionTableData_CRTProposalYearlyProjection' as const,
    CharitableTrustDesignerDistributionTableData_CLTProposalYearlProjection: 'CharitableTrustDesignerDistributionTableData_CLTProposalYearlProjection' as const
  }
}