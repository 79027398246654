import {
  AugmentedUpdateEntityTaskInput,
  UpdateEntityTaskInput,
} from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { UNASSIGNED_TASK_SENTINEL } from '../tasks.constants';
import { useIlitTaskSidebarQueryQuery } from './graphql/ILITTaskSidebarQuery.generated';
import { ILITTaskSidebarForm } from './ILITTaskSidebar.types';

export function getILITTaskMutationInput(
  formData: ILITTaskSidebarForm,
  taskId: string,
  currentUserId: string | undefined
): AugmentedUpdateEntityTaskInput {
  const update: UpdateEntityTaskInput = {
    dueAt: formData.dueDate,
  };
  if (formData.assigneeId !== UNASSIGNED_TASK_SENTINEL) {
    update.assignedToID = formData.assigneeId;
  } else {
    update.clearAssignedTo = true;
  }

  if (
    formData.assigneeId !== formData.initialAssigneeId &&
    formData.initialAssigneeId !== UNASSIGNED_TASK_SENTINEL
  ) {
    update.assignedByID = currentUserId;
  }

  return {
    id: taskId,
    update,
  };
}

export function useTaskDetailsData(taskID: string) {
  const { data } = useIlitTaskSidebarQueryQuery({
    variables: { taskID },
  });
  const task = getNodes(data?.entityTasks)?.find(({ id }) => id === taskID);
  if (!task) {
    return undefined;
  }
  return {
    dueDate: task.dueAt || null,
    assigneeId: task.assignedTo?.id ?? UNASSIGNED_TASK_SENTINEL,
    initialAssigneeId: task.assignedTo?.id ?? UNASSIGNED_TASK_SENTINEL,
  };
}
