import Decimal from 'decimal.js';

import {
  SUGGESTABLE_KINDS,
  SuggestableKind,
} from '@/modules/aiSuggestions/aiSuggestions.types';
import { CommonAiSuggestionFragment } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';

/**
 * We only support suggesting values for single-value fields, where the value
 * can be represented as a string. Such as text inputs, single select inputs,
 * date pickers, etc.
 */
export interface SuggestableSuggestion
  extends Omit<CommonAiSuggestionFragment, 'kind'> {
  kind: SuggestableKind;
}

export function isSuggestableSuggestion(
  suggestion: CommonAiSuggestionFragment | undefined
): suggestion is SuggestableSuggestion {
  return (
    suggestion !== undefined &&
    SUGGESTABLE_KINDS.includes(suggestion.kind as SuggestableKind)
  );
}

export interface SuggestedValue {
  value: string | number | Date | Decimal | undefined | null;
  display: string;
}
