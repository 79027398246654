import { TileNode, TileNodeProps } from './TileNode';
import { TileNodeGroup, TileNodeGroupProps } from './TileNodeGroup';

export type GenericTileNode = TileNodeProps | TileNodeGroupProps;

export function GenericTileNode(props: GenericTileNode) {
  if ('group' in props.data) {
    return <TileNodeGroup {...(props as TileNodeGroupProps)} />;
  }

  return <TileNode {...props} />;
}
