import Decimal from 'decimal.js';

import {
  OversizedMetricItem,
  OversizedMetricItemProps,
} from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';

const currencyOptions = {
  notation: 'compact' as const,
};

interface BenefitsMetricsItemProps {
  title: string;
  tooltip?: OversizedMetricItemProps['tooltip'];
  value?: Decimal;
}

export function BenefitsMetricsItem({
  title,
  tooltip,
  value,
}: BenefitsMetricsItemProps) {
  return (
    <OversizedMetricItem
      title={title}
      titleVariant="h4"
      valueVariant="h1"
      valueColor={COLORS.NAVY[600]}
      valueProps={{
        sx: {
          fontSize: '40px',
          lineHeight: '48px',
        },
      }}
      tooltip={tooltip}
      value={
        value ? formatCurrency(value, currencyOptions) : EMPTY_CONTENT_HYPHEN
      }
    />
  );
}
