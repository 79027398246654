import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { getNodes } from '@/utils/graphqlUtils';

import { useAssetCategoriesQuery } from '../graphql/StructuredAssetsSubform.generated';

export function useAssetCategoryOptions() {
  const { createErrorFeedback } = useFeedback();
  const { data, ...queryProps } = useAssetCategoriesQuery({
    fetchPolicy: 'cache-and-network',
    onError: createErrorFeedback('Failed to fetch asset categories'),
  });

  const options = getNodes(data?.assetClasses)
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((ac) => ({
      display: ac.displayName,
      value: ac.id,
    }));

  return { options, data, ...queryProps };
}
