import { compact } from 'lodash';
import { useMemo } from 'react';

import { BigBreadcrumbs } from '@/components/navigation/BigBreadcrumbs';

export function ReportingBreadcrumb({
  reportingPageCrumb,
}: {
  reportingPageCrumb?: string;
}) {
  const crumbs = useMemo(() => {
    return compact([
      reportingPageCrumb && {
        label: reportingPageCrumb,
      },
    ]);
  }, [reportingPageCrumb]);

  return <BigBreadcrumbs crumbs={crumbs} />;
}
