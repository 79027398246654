import { Box, BoxProps } from '@mui/system';
import React from 'react';
import { useMeasure } from 'react-use';
import { makeStyles } from 'tss-react/mui';

import { Loader } from '@/components/progress/Loader/Loader';
import { WithClasses } from '@/styles/types';

interface Dimensions {
  width: number;
  height: number;
}

function getRenderedSlideDimensionsPx({
  scaleWidthPx,
  aspectRatio,
}: {
  scaleWidthPx: number;
  aspectRatio: number;
}): Dimensions {
  const heightFromRatioPx = Math.floor(scaleWidthPx / aspectRatio);

  return {
    width: scaleWidthPx,
    height: heightFromRatioPx,
  };
}

type StylesProps = Dimensions & {
  scaleRatio: number;
};

const useStyles = makeStyles<StylesProps>()(
  (_theme, { width, height, scaleRatio }) => ({
    root: {
      position: 'relative',
    },
    container: {
      width: `${width}px`,
      height: `${height}px`,
      transform: `scale(${scaleRatio})`,
      transformOrigin: `left top`,
    },
  })
);

export type ScalableSlideProps = React.PropsWithChildren<{
  classes?: WithClasses<typeof useStyles>;
  // this is the ratio that the height will be constrained to relative to the width. e.g. if you want
  // a 16:9 aspect ratio, you'd pass in 16/9
  aspectRatio: number;
  // this is the width of the base slide in pixels. you should build all the slide content relative to
  // this width, and then we'll use `transform: scale` to scale the content up/down to the correct
  // size relative to the available offset parent (container) width.
  scaleWidthPx: number;
  // readyToScale is an optional property that can be used to delay the scaling of the slide
  // which may be useful if the slide needs to be initialized before it can be scaled correctly
  readyToScale?: boolean;
}> &
  Partial<BoxProps>;

export function ScalableSlide({
  children,
  aspectRatio,
  scaleWidthPx,
  readyToScale = true,
  ...props
}: ScalableSlideProps) {
  // we want to trust the wrapping element to provide us with an accurate
  // width, and we then want to calculate the available hight based off of that.
  const [ref, { width: containerWidth }] = useMeasure();
  const containerHeight = containerWidth / aspectRatio;

  // from there, since we're guaranteed to have the exact same aspect ratio
  // in the actual content container, we can use use static dimensions and
  //  `transform: scale` to scale that content into our root element without
  // needing to worry about the fit issues
  const slideDimensions = getRenderedSlideDimensionsPx({
    aspectRatio,
    scaleWidthPx,
  });
  const containerSlideDimensionRatio = readyToScale
    ? containerWidth / slideDimensions.width
    : 1;

  const { classes } = useStyles(
    {
      ...slideDimensions,
      scaleRatio: containerSlideDimensionRatio,
    },
    { props }
  );

  return (
    <>
      {!readyToScale && (
        <Loader
          boxProps={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          circularProgressProps={{ size: 40 }}
        />
      )}
      <Box
        ref={ref}
        data-name="ScalableSlide"
        className={classes.root}
        sx={{
          height: containerHeight,
          pageBreakAfter: 'auto',
          pageBreakBefore: 'always',
          visibility: readyToScale ? 'visible' : 'hidden',
        }}
        {...props}
      >
        <div className={classes.container}>{children}</div>
      </Box>
    </>
  );
}
