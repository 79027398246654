import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { WithClasses } from '@/styles/types';

const useStyles = makeStyles()((_theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    lineHeight: '1.25rem',
  },
  value: {
    fontWeight: 700,
  },
}));

export interface TileSummaryProps {
  label: string;
  value: string;
  classes?: WithClasses<typeof useStyles>;
}

export const TileSummaryItem = ({
  label,
  value,
  ...props
}: TileSummaryProps) => {
  const { classes } = useStyles(undefined, { props });
  return (
    <div className={classes.root}>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      <Typography className={classes.value}>{value}</Typography>
    </div>
  );
};
