import { FlowChartProvider } from '@/components/diagrams/FlowChart';
import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';

import { EmptyState } from './components/EmptyState';
import { EntityMapContextContainer } from './components/EntityMapContextContainer';
import { EntityMapFlowChartWrapper } from './components/EntityMapFlowChartWrapper';
import { MemoizedNodeSummaryPanel } from './components/NodeSummaryPanel/NodeSummaryPanel';
import { EntityMapProvider } from './contexts/entityMap.provider';
import { EntityMapFlowChart } from './EntityMapFlowChart';
import { useEntityMapQuery } from './hooks/useEntityMapQuery';
import { EntityMapProps } from './types';

export const EntityMapInner = ({
  height,
  width,
  householdId,
  entityGraphViewId,
}: EntityMapProps) => {
  const {
    isInitialLoading,
    isEmptyMap,
    isUpdating,
    household,
    entityGraphView,
    allEntityGraphViews,
  } = useEntityMapQuery({ householdId, entityGraphViewId });
  return (
    <>
      <LoadingOverlay open={isInitialLoading} updatingText="Loading..." />
      <LoadingOverlay
        /** UX optimization: we don't need to show any UI if there is no data */
        open={isUpdating && !isEmptyMap}
        updatingText="Syncing with latest..."
      />
      {!isInitialLoading && (
        <EntityMapProvider
          household={household!}
          entityGraphView={entityGraphView!}
          allEntityGraphViews={allEntityGraphViews}
        >
          <EntityMapContextContainer height={height} width={width}>
            <EntityMapFlowChartWrapper height={height}>
              {isEmptyMap ? (
                <EmptyState householdId={householdId} />
              ) : (
                <EntityMapFlowChart householdId={householdId} />
              )}
            </EntityMapFlowChartWrapper>
            <MemoizedNodeSummaryPanel
              householdId={householdId}
              style={{ height }}
            />
          </EntityMapContextContainer>
        </EntityMapProvider>
      )}
    </>
  );
};

export const EntityMap = (props: EntityMapProps) => {
  return (
    <FlowChartProvider>
      <EntityMapInner {...props} />
    </FlowChartProvider>
  );
};
