import Decimal from 'decimal.js';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import {
  GetLifetimeExclusionAmounts_ClientProfileFragment,
  useGetLifetimeExclusionAmountsQuery,
} from '../graphql/GetLifetimeExclusionAmounts.generated';

export function useRemainingExemptionAmountForClient({
  clientId,
}: {
  clientId: string | null;
}) {
  const { householdId } = useHouseholdDetailsContext();

  const { lifetimeExemptionAmount, gstExemptionAmount } = useIRSConstants();
  const loadingIrsConstants = !lifetimeExemptionAmount || !gstExemptionAmount;

  const { showFeedback } = useFeedback();
  const { data, loading } = useGetLifetimeExclusionAmountsQuery({
    variables: {
      householdWhere: { id: householdId },
    },
    onError: (error) => {
      showFeedback(
        `Sorry, we failed to fetch the data for this page. Please refresh the page and try again.`
      );
      diagnostics.error('failed to get the lifetime exclusion amounts', error, {
        householdId,
      });
    },
    skip: !householdId || clientId === null,
  });

  return useMemo(() => {
    const household = getNodes(data?.households)[0];

    if (loading || loadingIrsConstants || !household || clientId === null) {
      return {
        data: null,
      };
    }

    const deadClient: Maybe<GetLifetimeExclusionAmounts_ClientProfileFragment> =
      household.possiblePrimaryClients.find((c) => c.id === clientId);

    const gstExclusionUsed = deadClient?.gstExclusionUsed ?? new Decimal(0);

    const lifetimeExclusionUsed =
      deadClient?.lifetimeExclusionUsed ?? new Decimal(0);

    const remainingLifetimeExclusion =
      lifetimeExemptionAmount?.minus(lifetimeExclusionUsed) ?? new Decimal(0);

    const remainingStateExemption =
      deadClient?.remainingStateExemption ?? new Decimal(0);

    const remainingFederalLifetimeExemptionInExcessOfStateExemption = (() => {
      const availableFederalExemption = remainingLifetimeExclusion.minus(
        remainingStateExemption
      );

      return availableFederalExemption.greaterThan(0)
        ? availableFederalExemption
        : new Decimal(0);
    })();

    const remainingGstExclusionInExcessOfLifetime = (() => {
      const availableGst =
        gstExemptionAmount?.minus(gstExclusionUsed) ?? new Decimal(0);

      if (availableGst.greaterThan(remainingLifetimeExclusion)) {
        return availableGst.minus(remainingLifetimeExclusion);
      } else {
        return new Decimal(0);
      }
    })();

    return {
      data: {
        gstExclusionUsed,
        lifetimeExclusionUsed,
        remainingGstExclusionInExcessOfLifetime,
        remainingLifetimeExclusion,
        remainingStateExemption,
        remainingFederalLifetimeExemptionInExcessOfStateExemption,
        stateCode: deadClient?.address?.stateCode,
      },
    };
  }, [
    clientId,
    data?.households,
    gstExemptionAmount,
    lifetimeExemptionAmount,
    loading,
    loadingIrsConstants,
  ]);
}
