// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragmentDoc, CharitableTrustDesignerIllustrationData_CltProposalProjectionFragmentDoc, CharitableTrustDesignerIllustrationData_NoPlanProjectionFragmentDoc } from '../../designer/CharitableTrustDesigner/CharitableTrustDesignerAnalysis/graphql/CharitableTrustDesignerIllustrationData.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCharitableProjectionQueryVariables = Types.Exact<{
  crtProjectionInput: Types.CrtProposalProjectionInput;
  cltProjectionInput: Types.CltProposalProjectionInput;
  noPlanProjectionInput: Types.NoPlanProjectionInput;
  isCRT: Types.Scalars['Boolean']['input'];
}>;


export type GetCharitableProjectionQuery = { __typename?: 'Query', crtProposalProjection?: { __typename?: 'CRTProposalProjection', projectedCharitableRemainder: CurrencyUSD, charitableRemainder: CurrencyUSD, personalPortfolio: CurrencyUSD, personalBenefit: CurrencyUSD, capitalGainsDeferred: CurrencyUSD, capitalGainsTaxDeferred: CurrencyUSD, ordinaryIncomeTaxSaved: CurrencyUSD, metCharitableThreshold: boolean, yearly: Array<{ __typename?: 'CRTProposalYearlyProjection', year: number, beginningOfYear: CurrencyUSD, remainderValue: CurrencyUSD, payout: CurrencyUSD, taxed: CurrencyUSD, growth: CurrencyUSD, cumulativeTaxed: CurrencyUSD, cumulativeIncome: CurrencyUSD }> }, cltProposalProjection?: { __typename?: 'CLTProposalProjection', projectedCharitableBenefit: CurrencyUSD, personalRemainder: CurrencyUSD, personalBenefit: CurrencyUSD, capitalGainsDeferred: CurrencyUSD, capitalGainsTaxDeferred: CurrencyUSD, estateTaxSaved: CurrencyUSD, ordinaryIncomeTaxSaved: CurrencyUSD, taxableGift: CurrencyUSD, yearly: Array<{ __typename?: 'CLTProposalYearlyProjection', year: number, beginningOfYear: CurrencyUSD, remainderValue: CurrencyUSD, payout: CurrencyUSD, taxed: CurrencyUSD, growth: CurrencyUSD, cumulativeTaxed: CurrencyUSD, cumulativeCharity: CurrencyUSD }> }, noPlanProjection: { __typename?: 'NoPlanProjection', projectedValue: CurrencyUSD, yearly: Array<{ __typename?: 'NoPlanProjectionYearly', endOfYear: CurrencyUSD, year: number }> } };


export const GetCharitableProjectionDocument = gql`
    query GetCharitableProjection($crtProjectionInput: CRTProposalProjectionInput!, $cltProjectionInput: CLTProposalProjectionInput!, $noPlanProjectionInput: NoPlanProjectionInput!, $isCRT: Boolean!) {
  crtProposalProjection(input: $crtProjectionInput) @include(if: $isCRT) {
    ...CharitableTrustDesignerIllustrationData_CRTProposalProjection
  }
  cltProposalProjection(input: $cltProjectionInput) @skip(if: $isCRT) {
    ...CharitableTrustDesignerIllustrationData_CLTProposalProjection
  }
  noPlanProjection(input: $noPlanProjectionInput) {
    ...CharitableTrustDesignerIllustrationData_NoPlanProjection
  }
}
    ${CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragmentDoc}
${CharitableTrustDesignerIllustrationData_CltProposalProjectionFragmentDoc}
${CharitableTrustDesignerIllustrationData_NoPlanProjectionFragmentDoc}`;

/**
 * __useGetCharitableProjectionQuery__
 *
 * To run a query within a React component, call `useGetCharitableProjectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCharitableProjectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCharitableProjectionQuery({
 *   variables: {
 *      crtProjectionInput: // value for 'crtProjectionInput'
 *      cltProjectionInput: // value for 'cltProjectionInput'
 *      noPlanProjectionInput: // value for 'noPlanProjectionInput'
 *      isCRT: // value for 'isCRT'
 *   },
 * });
 */
export function useGetCharitableProjectionQuery(baseOptions: Apollo.QueryHookOptions<GetCharitableProjectionQuery, GetCharitableProjectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCharitableProjectionQuery, GetCharitableProjectionQueryVariables>(GetCharitableProjectionDocument, options);
      }
export function useGetCharitableProjectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCharitableProjectionQuery, GetCharitableProjectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCharitableProjectionQuery, GetCharitableProjectionQueryVariables>(GetCharitableProjectionDocument, options);
        }
export function useGetCharitableProjectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCharitableProjectionQuery, GetCharitableProjectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCharitableProjectionQuery, GetCharitableProjectionQueryVariables>(GetCharitableProjectionDocument, options);
        }
export type GetCharitableProjectionQueryHookResult = ReturnType<typeof useGetCharitableProjectionQuery>;
export type GetCharitableProjectionLazyQueryHookResult = ReturnType<typeof useGetCharitableProjectionLazyQuery>;
export type GetCharitableProjectionSuspenseQueryHookResult = ReturnType<typeof useGetCharitableProjectionSuspenseQuery>;
export type GetCharitableProjectionQueryResult = Apollo.QueryResult<GetCharitableProjectionQuery, GetCharitableProjectionQueryVariables>;
export const Operations = {
  Query: {
    GetCharitableProjection: 'GetCharitableProjection' as const
  }
}