// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EstateWaterfallAssumptions_EstateWaterfallFragment = { __typename?: 'EstateWaterfall', id: string, displayName: string, firstGrantorDeathYear?: number | null, secondGrantorDeathYear?: number | null, assetGrowthReturn?: Percent | null, exemptionGrowthRate?: Percent | null, willExemptionSunset?: boolean | null, isTwoGrantor: boolean, firstGrantorDeath: { __typename?: 'ClientProfile', id: string }, parent?: { __typename?: 'EstateWaterfall', id: string } | null, visualizationWithProjections: { __typename?: 'EstateWaterfallViz', _cacheKey: string, beforeFirstDeathTaxSummary?: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null } | null, firstDeathTaxSummary: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null }, secondDeathTaxSummary?: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null } | null } };

export type EstateWaterfallAssumptions_TaxDetailFragment = { __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string };

export type EstateWaterfallAssumptions_TaxSummaryFragment = { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null };

export type QueryEstateWaterfallAssumptionsQueryVariables = Types.Exact<{
  where: Types.EstateWaterfallWhereInput;
}>;


export type QueryEstateWaterfallAssumptionsQuery = { __typename?: 'Query', estateWaterfalls: { __typename?: 'EstateWaterfallConnection', edges?: Array<{ __typename?: 'EstateWaterfallEdge', node?: { __typename?: 'EstateWaterfall', id: string, displayName: string, firstGrantorDeathYear?: number | null, secondGrantorDeathYear?: number | null, assetGrowthReturn?: Percent | null, exemptionGrowthRate?: Percent | null, willExemptionSunset?: boolean | null, isTwoGrantor: boolean, firstGrantorDeath: { __typename?: 'ClientProfile', id: string }, parent?: { __typename?: 'EstateWaterfall', id: string } | null, visualizationWithProjections: { __typename?: 'EstateWaterfallViz', _cacheKey: string, beforeFirstDeathTaxSummary?: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null } | null, firstDeathTaxSummary: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null }, secondDeathTaxSummary?: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null } | null } } | null } | null> | null } };

export type UpdateEstateWaterfallAssumptionsMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateEstateWaterfallInput;
}>;


export type UpdateEstateWaterfallAssumptionsMutation = { __typename?: 'Mutation', updateEstateWaterfall: { __typename?: 'EstateWaterfall', id: string, displayName: string, firstGrantorDeathYear?: number | null, secondGrantorDeathYear?: number | null, assetGrowthReturn?: Percent | null, exemptionGrowthRate?: Percent | null, willExemptionSunset?: boolean | null, isTwoGrantor: boolean, firstGrantorDeath: { __typename?: 'ClientProfile', id: string }, parent?: { __typename?: 'EstateWaterfall', id: string } | null, visualizationWithProjections: { __typename?: 'EstateWaterfallViz', _cacheKey: string, beforeFirstDeathTaxSummary?: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null } | null, firstDeathTaxSummary: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null }, secondDeathTaxSummary?: { __typename?: 'TaxSummary', _cacheKey: string, stateTax?: Array<{ __typename?: 'TaxDetail', stateCode?: Types.StateCode | null, stateDisclosure?: string | null, _cacheKey: string }> | null } | null } } };

export const EstateWaterfallAssumptions_TaxDetailFragmentDoc = gql`
    fragment EstateWaterfallAssumptions_TaxDetail on TaxDetail {
  stateCode
  stateDisclosure
  _cacheKey
}
    `;
export const EstateWaterfallAssumptions_TaxSummaryFragmentDoc = gql`
    fragment EstateWaterfallAssumptions_TaxSummary on TaxSummary {
  stateTax {
    ...EstateWaterfallAssumptions_TaxDetail
  }
  _cacheKey
}
    ${EstateWaterfallAssumptions_TaxDetailFragmentDoc}`;
export const EstateWaterfallAssumptions_EstateWaterfallFragmentDoc = gql`
    fragment EstateWaterfallAssumptions_EstateWaterfall on EstateWaterfall {
  id
  displayName
  firstGrantorDeathYear
  secondGrantorDeathYear
  assetGrowthReturn
  exemptionGrowthRate
  willExemptionSunset
  isTwoGrantor
  firstGrantorDeath {
    id
  }
  parent {
    id
  }
  visualizationWithProjections {
    _cacheKey
    beforeFirstDeathTaxSummary {
      ...EstateWaterfallAssumptions_TaxSummary
    }
    firstDeathTaxSummary {
      ...EstateWaterfallAssumptions_TaxSummary
    }
    secondDeathTaxSummary {
      ...EstateWaterfallAssumptions_TaxSummary
    }
  }
}
    ${EstateWaterfallAssumptions_TaxSummaryFragmentDoc}`;
export const QueryEstateWaterfallAssumptionsDocument = gql`
    query QueryEstateWaterfallAssumptions($where: EstateWaterfallWhereInput!) {
  estateWaterfalls(where: $where) {
    edges {
      node {
        ...EstateWaterfallAssumptions_EstateWaterfall
      }
    }
  }
}
    ${EstateWaterfallAssumptions_EstateWaterfallFragmentDoc}`;

/**
 * __useQueryEstateWaterfallAssumptionsQuery__
 *
 * To run a query within a React component, call `useQueryEstateWaterfallAssumptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryEstateWaterfallAssumptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryEstateWaterfallAssumptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useQueryEstateWaterfallAssumptionsQuery(baseOptions: Apollo.QueryHookOptions<QueryEstateWaterfallAssumptionsQuery, QueryEstateWaterfallAssumptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QueryEstateWaterfallAssumptionsQuery, QueryEstateWaterfallAssumptionsQueryVariables>(QueryEstateWaterfallAssumptionsDocument, options);
      }
export function useQueryEstateWaterfallAssumptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QueryEstateWaterfallAssumptionsQuery, QueryEstateWaterfallAssumptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QueryEstateWaterfallAssumptionsQuery, QueryEstateWaterfallAssumptionsQueryVariables>(QueryEstateWaterfallAssumptionsDocument, options);
        }
export function useQueryEstateWaterfallAssumptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<QueryEstateWaterfallAssumptionsQuery, QueryEstateWaterfallAssumptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QueryEstateWaterfallAssumptionsQuery, QueryEstateWaterfallAssumptionsQueryVariables>(QueryEstateWaterfallAssumptionsDocument, options);
        }
export type QueryEstateWaterfallAssumptionsQueryHookResult = ReturnType<typeof useQueryEstateWaterfallAssumptionsQuery>;
export type QueryEstateWaterfallAssumptionsLazyQueryHookResult = ReturnType<typeof useQueryEstateWaterfallAssumptionsLazyQuery>;
export type QueryEstateWaterfallAssumptionsSuspenseQueryHookResult = ReturnType<typeof useQueryEstateWaterfallAssumptionsSuspenseQuery>;
export type QueryEstateWaterfallAssumptionsQueryResult = Apollo.QueryResult<QueryEstateWaterfallAssumptionsQuery, QueryEstateWaterfallAssumptionsQueryVariables>;
export const UpdateEstateWaterfallAssumptionsDocument = gql`
    mutation UpdateEstateWaterfallAssumptions($input: AugmentedUpdateEstateWaterfallInput!) {
  updateEstateWaterfall(input: $input) {
    ...EstateWaterfallAssumptions_EstateWaterfall
  }
}
    ${EstateWaterfallAssumptions_EstateWaterfallFragmentDoc}`;
export type UpdateEstateWaterfallAssumptionsMutationFn = Apollo.MutationFunction<UpdateEstateWaterfallAssumptionsMutation, UpdateEstateWaterfallAssumptionsMutationVariables>;

/**
 * __useUpdateEstateWaterfallAssumptionsMutation__
 *
 * To run a mutation, you first call `useUpdateEstateWaterfallAssumptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEstateWaterfallAssumptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEstateWaterfallAssumptionsMutation, { data, loading, error }] = useUpdateEstateWaterfallAssumptionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEstateWaterfallAssumptionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEstateWaterfallAssumptionsMutation, UpdateEstateWaterfallAssumptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEstateWaterfallAssumptionsMutation, UpdateEstateWaterfallAssumptionsMutationVariables>(UpdateEstateWaterfallAssumptionsDocument, options);
      }
export type UpdateEstateWaterfallAssumptionsMutationHookResult = ReturnType<typeof useUpdateEstateWaterfallAssumptionsMutation>;
export type UpdateEstateWaterfallAssumptionsMutationResult = Apollo.MutationResult<UpdateEstateWaterfallAssumptionsMutation>;
export type UpdateEstateWaterfallAssumptionsMutationOptions = Apollo.BaseMutationOptions<UpdateEstateWaterfallAssumptionsMutation, UpdateEstateWaterfallAssumptionsMutationVariables>;
export const Operations = {
  Query: {
    QueryEstateWaterfallAssumptions: 'QueryEstateWaterfallAssumptions' as const
  },
  Mutation: {
    UpdateEstateWaterfallAssumptions: 'UpdateEstateWaterfallAssumptions' as const
  },
  Fragment: {
    EstateWaterfallAssumptions_EstateWaterfall: 'EstateWaterfallAssumptions_EstateWaterfall' as const,
    EstateWaterfallAssumptions_TaxDetail: 'EstateWaterfallAssumptions_TaxDetail' as const,
    EstateWaterfallAssumptions_TaxSummary: 'EstateWaterfallAssumptions_TaxSummary' as const
  }
}