import { compact, includes, isEmpty } from 'lodash';

import { EntityDetailPropsKind } from '@/modules/entities/components/EntityDetails';
import { getFormattedNonTrustEntityTaxStatus } from '@/modules/entities/details/entityDetailPageUtils';
import {
  EntityDetailsData,
  NonTrustCharitableEntityDetailsTabs,
} from '@/modules/entities/details/entityDetails.types';
import { INSURANCE_POLICY_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { EntityType } from '@/modules/entities/types/EntityType';
import { getAssetLocationDisplay } from '@/modules/entities/utils/getAssetLocationDisplay';
import { FeatureFlagMap } from '@/modules/featureFlags/featureFlags.types';
import { getNodes } from '@/utils/graphqlUtils';

import {
  NonTrustCharitableEntityDetailsCard_ControllingPartyFragment,
  NonTrustCharitableEntityDetailsCard_DonorPrincipalFragment,
} from './graphql/NonTrustCharitableEntityDetailsCard.generated';
import { NonTrustCharitableEntitySummaryData } from './NonTrustCharitableEntityDetails.types';

export function getNonTrustCharitableEntityDetailsTabs(
  summaryData: NonTrustCharitableEntitySummaryData,
  _featureFlags: FeatureFlagMap = {}
) {
  const Summary = {
    display: 'Summary',
    value: NonTrustCharitableEntityDetailsTabs.SUMMARY,
    props: summaryData,
  };

  const Beneficiaries = {
    display: 'Beneficiaries',
    value: NonTrustCharitableEntityDetailsTabs.BENEFICIARIES,
    props: summaryData,
  };

  const Dispositions = {
    display: 'Dispositions',
    value: NonTrustCharitableEntityDetailsTabs.DISPOSITIONS,
    props: summaryData,
  };

  const InsurancePolicies = {
    display: 'Policies',
    value: NonTrustCharitableEntityDetailsTabs.POLICIES,
    props: summaryData,
  };

  const showPolicies = (() => {
    const hasPolicies = !isEmpty(summaryData.insurancePolicies);
    return (
      includes(
        INSURANCE_POLICY_ENTITY_TYPES,
        summaryData.entityType as EntityType
      ) && hasPolicies
    );
  })();

  return compact([
    Summary,
    summaryData.beneficiaries && Beneficiaries,
    Dispositions,
    showPolicies && InsurancePolicies,
  ]);
}

export function getNonTrustCharitableEntitySummaryProperties({
  entity,
}: EntityDetailsData): NonTrustCharitableEntitySummaryData {
  if (
    entity.subtype.__typename !== 'DonorAdvisedFund' &&
    entity.subtype.__typename !== 'PrivateFoundation'
  ) {
    throw new Error('Invalid non trust charitable entity type');
  }

  return {
    _kind: EntityDetailPropsKind.NonTrustCharitableEntitySummaryData as const,
    entityId: entity.id,
    legalName: entity.subtype.legalName || undefined,
    description: entity.subtype.description || undefined,
    entityType: entity.extendedDisplayKind,
    extendedDisplayKind: entity.extendedDisplayKind,
    taxStatus: getFormattedNonTrustEntityTaxStatus(
      entity.subtype.nonTrustEntityTaxStatus ?? null
    ),
    assetLocation:
      getAssetLocationDisplay({
        inEstateStatus: entity.subtype.inEstateStatus,
        stateTaxes: entity.stateTaxes ?? [],
        gstStatus: entity.subtype.gstStatus,
      }).locationDisplayShort ?? undefined,
    donorNames: entity.subtype.donors?.map(getDonorNameFromFragment) ?? [],
    effectiveDate: entity.subtype.effectiveDate ?? undefined,
    controllingPartyNames:
      entity.subtype.controllingParties?.map(
        getControllingPartyNameFromFragment
      ) ?? [],
    beneficiaries: entity.subtype.beneficiaries || [],
    detailsItems: [],
    cashflows: getNodes(entity.cashflows) || [],
    insurancePolicies: entity.subtype.policies ?? [],
  };
}

function getDonorNameFromFragment(
  owner: NonTrustCharitableEntityDetailsCard_DonorPrincipalFragment
): string {
  if (owner.individual) {
    return owner.individual.legalName;
  }

  return 'Unknown donor';
}

function getControllingPartyNameFromFragment(
  controllingParty: NonTrustCharitableEntityDetailsCard_ControllingPartyFragment
): string {
  if (controllingParty.individual) {
    return controllingParty.individual.legalName;
  }

  if (controllingParty.organization) {
    return controllingParty.organization.name;
  }

  return 'Unknown controlling party or signatory';
}
