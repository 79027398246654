import { CalloutListCard } from '@/components/layout/CalloutListCard/CalloutListCard';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';
import { TrustSummary } from '@/modules/trusts/TrustDetails/TrustSummary';

import { PresentationSlideSecondaryHeading } from '../../../components/PresentationSlideSecondaryHeading';
import { OverviewSlideLayout } from '../../components/OverviewSlideLayout';
import { PresentationSlideRegistrationDetails } from '../../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../../graphql/EntityPresentation.generated';

/**
 * @description The TrustOverviewSlide is a generic component that can be shared between trust types that have
 * similar overview slides in their presentations.
 */

export interface TrustOverviewSlideProps
  extends PresentationSlideRegistrationDetails {
  entity: EntityPresentation_EntityFragment;
  summary: TrustSummaryData;
  displayName: string;
  AssetCard: JSX.Element;
}

export function TrustOverviewSlide({
  displayName,
  entity,
  summary,
  AssetCard,
  ...registrationProps
}: TrustOverviewSlideProps) {
  useRegisterSlide({
    displayName: 'Overview',
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  const { description, ...summaryProps } = summary;
  return (
    <PresentationSlide.Slide
      id={registrationProps.slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading heading={displayName} />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={entity.household.displayName}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <OverviewSlideLayout
        Assets={AssetCard}
        Summary={<TrustSummary loading={false} {...summaryProps} />}
        description={description}
        Callout={
          <CalloutListCard
            loading={false}
            sx={{ width: '100%' }}
            variant="filled"
            items={summary.trustDetailsItems}
          />
        }
      />
    </PresentationSlide.Slide>
  );
}
