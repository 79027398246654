import { GridApiPro, GridGroupingColDefOverride } from '@mui/x-data-grid-pro';
import React from 'react';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { defaultGroupColDef } from '@/components/tables/DataTable/hooks/internal/useDefaultProps';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { $downloadFileFromURL } from '@/modules/files/utils/fileUtils';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { useCaptureElementAsPng } from '@/utils/printUtils';

import { BALANCE_SHEET_TABLE_CLASS_NAME } from '../BalanceSheetTable/BalanceSheetTable.constants';
import { useBalanceSheetTableContext } from '../BalanceSheetTable/BalanceSheetTableContext';
import { useDownloadBalanceSheetExcelFileMutation } from './graphql/BalanceSheetExportDropdown.generated';

enum ExportFormat {
  EXCEL = 'EXCEL',
  PNG = 'PNG',
}

interface BalanceSheetExportDropdownProps {
  gridAPIRef: React.MutableRefObject<GridApiPro>;
}

export function BalanceSheetExportDropdown({
  gridAPIRef,
}: BalanceSheetExportDropdownProps) {
  const trackUserEvent = useTrackUserEvent();
  const { setRenderWhiteLabeled } = useBalanceSheetTableContext();
  const { householdId, displayName } = useHouseholdDetailsContext();
  const captureElementAsPng = useCaptureElementAsPng();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [downloadBalanceSheetExcelFile, { loading }] =
    useDownloadBalanceSheetExcelFileMutation({
      onCompleted: (data) => {
        const downloadURL =
          data.downloadBalanceSheetExcel?.download.downloadURL;
        if (downloadURL) {
          try {
            void $downloadFileFromURL(
              downloadURL,
              `${displayName} Balance Sheet.xlsx`
            );
          } catch (err) {
            showFeedback('Failed to generate excel file. Please try again.');
          }
        } else {
          showFeedback('Failed to generate excel file. Please try again.');
        }
      },
      onError: (error) => {
        reportError('Error downloading balance sheet excel file', error);
        showFeedback(
          'An error occurred while generating the excel file. Please try again.'
        );
      },
    });

  const handleExport = async (format: ExportFormat) => {
    if (!householdId) {
      // satisfies typescript
      throw new Error('Household ID is not set');
    }

    trackUserEvent('balance_sheet exported', {
      format,
    });

    if (format === ExportFormat.EXCEL) {
      void downloadBalanceSheetExcelFile({
        variables: { householdID: householdId },
      });
    } else {
      // set as white-labeled to get client colors, wait a tick for the colors to load,
      // then capture the element and turn whitelabeling back off
      setRenderWhiteLabeled(true);
      // reset the scroll position of the table, otherwise the header will be misaligned because of
      // some MUI datatable fun
      gridAPIRef.current.scroll({
        left: 0,
      });
      // time for the scroll + rerender
      await new Promise((resolve) => setTimeout(resolve, 10));
      const element = document.querySelector(
        `.${BALANCE_SHEET_TABLE_CLASS_NAME}`
      );
      setRenderWhiteLabeled(false);
      if (!element) {
        throw new Error('no element found');
      }

      // dom-to-image will not render anything outside of the viewport, so we need to
      // actually measure the width of the table, and then pass that to the capture function
      // the columnHeadersInner is the "full-width" child of the table, so picking that
      // somewhat arbitrarily
      const elementForWidth = document.querySelector(
        `.${BALANCE_SHEET_TABLE_CLASS_NAME} .MuiDataGrid-columnHeadersInner`
      );
      let width = elementForWidth?.clientWidth;
      if (width) {
        width += 2; // arbitrary buffer to get rid of the scrollbar
      }
      void captureElementAsPng(
        element as HTMLElement,
        `${displayName} Balance Sheet`,
        {
          width,
          // we want to trim the left side of the table by the width of the grouping column,
          // to hide the expansion carets on export
          trimLeft: (defaultGroupColDef as GridGroupingColDefOverride).width,
        }
      );
    }
  };

  return (
    <ButtonWithPopover
      variant="secondary"
      label="Export"
      size="sm"
      loading={loading}
      popperProps={{
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        transformOrigin: { horizontal: 'left', vertical: 0 },
      }}
    >
      <MenuItem
        loading={loading}
        onClick={() => handleExport(ExportFormat.EXCEL)}
        label="Export as Excel"
      />
      <MenuItem
        onClick={() => handleExport(ExportFormat.PNG)}
        label="Export as PNG"
      />
    </ButtonWithPopover>
  );
}
