import { uniq } from 'lodash';

import {
  ProfessionalTeamRoleKind,
  ProfessionalTeamRolePowerOfAttorneyKind,
} from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { ProfessionalTeamSlide_ClientProfileFragment } from './graphql/ProfessionalTeamSlide.generated';
import {
  ADVISOR_CLIENT_TEAM_ORDER_LIST,
  PRINCIPAL_CLIENT_TEAM_ORDER_LIST,
} from './ProfessionalTeamSlide.constants';

export interface ProfessionalTeamSlideMember {
  name: string;
  principalClient: string | null;
  email: string | null;
  kind: ProfessionalTeamRoleKind;
  otherRoleName: string | null;
  powerOfAttorneyKind: ProfessionalTeamRolePowerOfAttorneyKind | null;
}

function getRolesForOrderList(
  clientProfiles: ProfessionalTeamSlide_ClientProfileFragment[],
  orderList: ProfessionalTeamRoleKind[],
  principalClientId?: string
): ProfessionalTeamSlideMember[] {
  return orderList.reduce<ProfessionalTeamSlideMember[]>((acc, orderRole) => {
    clientProfiles.forEach((profile) => {
      const roles = getNodes(profile.professionalTeamRoles);
      const role = roles.find((role) => {
        if (role.kind !== orderRole) {
          return false;
        }

        if (role.associatedClientIndividual) {
          return role.associatedClientIndividual.id === principalClientId;
        } else if (principalClientId) {
          return false;
        }
        return true;
      });

      if (role) {
        acc.push({
          name: profile.displayName,
          email: profile.email || null,
          kind: orderRole,
          otherRoleName: role.otherRoleName || null,
          powerOfAttorneyKind: role.powerOfAttorneyKind || null,
          principalClient: role.associatedClientIndividual?.displayName || null,
        });
      }
    });
    return acc;
  }, []);
}
export function mapDataToTable(
  clientProfiles: ProfessionalTeamSlide_ClientProfileFragment[]
): ProfessionalTeamSlideMember[] {
  const principalClientIds = uniq(
    clientProfiles.flatMap((profile) =>
      getNodes(profile.professionalTeamRoles).flatMap(
        (role) => role.associatedClientIndividual?.id ?? []
      )
    )
  );

  // roles need to be displayed in a specific order, with the roles within specified by the associated list type:
  // 1. household-level roles
  const advisorClientRoles = getRolesForOrderList(
    clientProfiles,
    ADVISOR_CLIENT_TEAM_ORDER_LIST
  );

  // 2. principal client-level roles (represented as an array here since there may be two principal clients)
  const principalClientRoles = principalClientIds.map<
    ProfessionalTeamSlideMember[]
  >((principalClientId) =>
    getRolesForOrderList(
      clientProfiles,
      PRINCIPAL_CLIENT_TEAM_ORDER_LIST,
      principalClientId
    )
  );

  return advisorClientRoles.concat(...principalClientRoles);
}
