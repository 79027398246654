import { SplitScreenLayout } from '@/components/layout/SplitScreenLayout/SplitScreenLayout';
import { DocumentSearchResult } from '@/modules/pdf/DocumentSearch/DocumentSearch.types';

import {
  HouseholdDocumentsViewerProvider,
  HouseholdDocumentsViewerProviderProps,
} from '../HouseholdDocumentsViewer/context/HouseholdDocumentsViewer.provider';
import { HouseholdDocumentsViewer } from '../HouseholdDocumentsViewer/HouseholdDocumentsViewer';

export interface SplitScreenLayoutWithDocumentsProps
  extends Omit<HouseholdDocumentsViewerProviderProps, 'householdId'> {
  householdId: string | null;
  rightContent: React.ReactNode;
  header?: React.ReactNode;
  footer: React.ReactNode;
  overrideAnnotations?: DocumentSearchResult[];
  overrideSearchQuery?: string;
  onUpdateSearchQuery?: () => void;
}

export function SplitScreenLayoutWithDocuments({
  householdId,
  rightContent,
  header,
  footer,
  overrideAnnotations,
  overrideSearchQuery,
  onUpdateSearchQuery,
  overrideSearchLoading,
  ...props
}: SplitScreenLayoutWithDocumentsProps) {
  return (
    <>
      {householdId && (
        <HouseholdDocumentsViewerProvider householdId={householdId} {...props}>
          <SplitScreenLayout
            // TODO: support testamentary entities and client profile IDs
            leftContent={
              householdId && (
                <HouseholdDocumentsViewer
                  overrideAnnotations={overrideAnnotations}
                  overrideSearchQuery={overrideSearchQuery}
                  onUpdateSearchQuery={onUpdateSearchQuery}
                  overrideSearchLoading={overrideSearchLoading}
                />
              )
            }
            rightContent={rightContent}
            header={header}
            footer={footer}
          />
        </HouseholdDocumentsViewerProvider>
      )}
    </>
  );
}
