import { Header } from '@/components/navigation/Header';
import { CompoundComponentProps } from '@/types/common';

import { Content, Footer, Panel, Section } from './SidePanel';

export const SidePanel = {
  Panel,
  Footer,
  Content,
  Section,
  // Currently just the normal header, but could be wrapped later specific to side panel
  Header,
};

export type SidePanelProps = CompoundComponentProps<typeof SidePanel>;
