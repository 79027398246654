import { HeadingLargeIcon } from '@/components/icons/HeadingLargeIcon';
import { HeadingSmallIcon } from '@/components/icons/HeadingSmallIcon';
import { TextListBulletedIcon } from '@/components/icons/TextListBulletedIcon';
import { TextListNumberedIcon } from '@/components/icons/TextListNumberedIcon';
import { Type01Icon } from '@/components/icons/Type01Icon';

export const blockTypeToBlockName = {
  code: 'Code Block',
  h1: 'Large Heading',
  h2: 'Small Heading',
  h3: 'Heading',
  h4: 'Heading',
  h5: 'Heading',
  h6: 'Heading',
  ol: 'Numbered List',
  paragraph: 'Body',
  quote: 'Quote',
  ul: 'Bulleted List',
} as const;

export const iconProps = {
  size: 20,
};

export const blockTypeToBlockIcon = {
  code: null,
  h1: HeadingLargeIcon,
  h2: HeadingSmallIcon,
  h3: null,
  h4: null,
  h5: null,
  h6: null,
  ol: TextListNumberedIcon,
  paragraph: Type01Icon,
  quote: null,
  ul: TextListBulletedIcon,
} as const;

export type Block = keyof typeof blockTypeToBlockName;
