import { PropsWithChildren, useState } from 'react';

import { TenantPresentationConfiguration_TenantDisclaimerConfigurationFragment } from '../graphql/TenantPresentationConfiguration.generated';
import { TenantPresentationConfigurationContext } from './tenantPresentationConfiguration.context';

function useTenantPresentationConfigurationContextValue(): TenantPresentationConfigurationContext {
  const [legalDisclaimers, setLegalDisclaimers] =
    useState<TenantPresentationConfiguration_TenantDisclaimerConfigurationFragment | null>(
      null
    );

  return {
    legalDisclaimers,
    setLegalDisclaimers,
  };
}

export const TenantPresentationConfigurationProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const value = useTenantPresentationConfigurationContextValue();
  return (
    <TenantPresentationConfigurationContext.Provider value={value}>
      {children}
    </TenantPresentationConfigurationContext.Provider>
  );
};
