import DOMPurify, { Config } from 'dompurify';

const defaultOptions = {
  ALLOWED_TAGS: ['b', 'i', 'em', 'strong'],
  ALLOWED_ATTR: [],
};

/**
 * @description Given potentially unsafe HTML or a string with HTML entities, sanitize it into a string
 */
export const sanitizeToString = (dirtyHTML: string, options: Config = {}) => {
  return DOMPurify.sanitize(dirtyHTML, {
    ...defaultOptions,
    ...options,
    // only return a string, not a DOM element. this can be relaxed when we have use cases
    // where we need to render DOM elements, but we currently don't so there's no need to
    // allow this.
    RETURN_DOM: false,
    RETURN_DOM_FRAGMENT: false,
  });
};

interface SanitizeHTMLProps {
  html: string;
  options?: Config;
}

export const SanitizeHTML = ({ html, options = {} }: SanitizeHTMLProps) => (
  // we are okay with using dangerouslySetInnerHTML here because we are explicitly sanitizing the input
  // before writing it to the DOM
  // nosemgrep:typescript.react.security.audit.react-dangerouslysetinnerhtml.react-dangerouslysetinnerhtml
  <div dangerouslySetInnerHTML={{ __html: sanitizeToString(html, options) }} />
);
