import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import {
  FullScreenModal,
  FullScreenModalProps,
} from '@/components/modals/FullScreenModal/FullScreenModal';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { DEFAULT_ASSUMPTIONS } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/constants';
import { COLORS } from '@/styles/tokens/colors';

import { useNavigateToWaterfall } from '../../hooks/useNavigateToWaterfall';
import { mapInputToFormData } from './CreateEstateWaterfallModal.utils';
import {
  EstateWaterfallForm,
  EstateWaterfallFormProps,
} from './EstateWaterfallForm';
import { EstateWaterfallTrowserLayout } from './EstateWaterfallTrowserLayout';
import { useCreateEstateWaterfall } from './hooks/useCreateEstateWaterfall';
import { useWaterfallModalQuery } from './hooks/useWaterfallModalQuery';
import { EstateWaterfallFormData } from './types';

interface DuplicateEstateWaterfallProps extends FullScreenModalProps {
  householdId: string;
  grantors: EstateWaterfallFormProps['grantors'];
  isTwoGrantor?: boolean;
  sourceEstateWaterfalls: EstateWaterfallFormProps['sourceEstateWaterfalls'];
  defaultSelectedRowIds?: string[];
  isHypothetical?: boolean;
  sourceWaterfallId?: string;
}

export type DuplicateEstateWaterfallTrowserProps = {
  sourceWaterfallId: string;
  waterfallFilter: EstateWaterfallFormData['waterfallFilter'];
  waterfallName: string;
} & DuplicateEstateWaterfallProps;

function DuplicateEstateWaterfall({
  householdId,
  grantors,
  isTwoGrantor = false,
  sourceEstateWaterfalls,
  defaultSelectedRowIds,
  sourceWaterfallId,
  isHypothetical,
  ...fullScreenModalProps
}: DuplicateEstateWaterfallProps) {
  const { onClose } = fullScreenModalProps;

  const navigateToWaterfall = useNavigateToWaterfall();
  const { formState, handleSubmit, reset } =
    useFormContext<EstateWaterfallFormData>();
  const trackUserEvent = useTrackUserEvent();

  const { entities, hypotheticalTransfers, loading } = useWaterfallModalQuery({
    householdId,
  });

  const {
    createWaterfall,
    createEstateWaterfallData,
    duplicateEstateWaterfallData,
  } = useCreateEstateWaterfall({
    isTwoGrantor,
    sourceEstateWaterfalls,
    isDuplicate: true,
  });

  const onSubmit = handleSubmit(createWaterfall);

  const closeModal = () => {
    reset();
    onClose?.();
  };

  useSubmitSuccessHandler(() => {
    trackUserEvent('waterfall duplicated', { isHypothetical });
    const waterfallId =
      createEstateWaterfallData?.createEstateWaterfall.id ??
      duplicateEstateWaterfallData?.duplicateEstateWaterfall.id;
    if (waterfallId) {
      navigateToWaterfall({ householdId, waterfallId });
    }
    closeModal();
  });

  return (
    <FullScreenModal
      backdropSx={{ bgcolor: COLORS.GRAY[50] }}
      {...fullScreenModalProps}
    >
      <Stack
        component="form"
        onSubmit={onSubmit}
        height="100%"
        noValidate
        pb={`${FOOTER_HEIGHT}px`}
      >
        <EstateWaterfallTrowserLayout>
          <Typography variant="h1" component="h2">
            Duplicate waterfall
          </Typography>
          <EstateWaterfallForm
            grantors={grantors}
            sourceEstateWaterfalls={sourceEstateWaterfalls}
            shouldShowHypotheticalToggle={true}
            loading={loading}
            entities={entities}
            hypotheticalTransfers={hypotheticalTransfers}
            hypotheticalParentId={
              isHypothetical ? sourceWaterfallId : undefined
            }
            isDuplicate
            defaultSelectedRowIds={defaultSelectedRowIds}
            shouldShowAutoGroupToggle={false}
          />
        </EstateWaterfallTrowserLayout>
        <Box flexShrink={0}>
          <Footer
            rightAction={
              <Stack direction="row" spacing={2}>
                <Button
                  variant="secondary"
                  size="sm"
                  loading={formState.isSubmitting}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="sm"
                  loading={formState.isSubmitting}
                >
                  Duplicate waterfall
                </Button>
              </Stack>
            }
          />
        </Box>
      </Stack>
    </FullScreenModal>
  );
}

export const DuplicateEstateWaterfallTrowser = ({
  sourceWaterfallId,
  waterfallFilter,
  householdId,
  grantors,
  waterfallName,
  isHypothetical,
  ...props
}: DuplicateEstateWaterfallTrowserProps) => {
  const formMethods = useForm<EstateWaterfallFormData>({
    defaultValues: mapInputToFormData({
      householdId,
      grantors,
      isHypothetical: isHypothetical,
      isDuplicate: true,
      waterfallFilter,
      parentID: sourceWaterfallId,
      displayName: `${waterfallName} (copy)`,
      ...DEFAULT_ASSUMPTIONS,
    }),
  });

  const defaultSelectedRowIds = useMemo(() => {
    const selectedIds = [
      ...(waterfallFilter?.includedClientIDs ?? []),
      ...(waterfallFilter?.includedEntityIDs ?? []),
    ];

    if (selectedIds.length === 0) {
      return undefined;
    }

    return selectedIds;
  }, [waterfallFilter?.includedClientIDs, waterfallFilter?.includedEntityIDs]);

  return (
    <FormProvider {...formMethods}>
      <DuplicateEstateWaterfall
        householdId={householdId}
        grantors={grantors}
        isTwoGrantor={grantors.length === 2}
        defaultSelectedRowIds={defaultSelectedRowIds}
        sourceWaterfallId={sourceWaterfallId}
        isHypothetical={isHypothetical}
        {...props}
      />
    </FormProvider>
  );
};
