import { EffectObject, EffectReducer, EventObject } from 'use-effect-reducer';

export function logger<
  State,
  Action extends EventObject,
  QueueEffect extends EffectObject<State, Action>,
>(
  reducer: EffectReducer<State, Action, QueueEffect>
): EffectReducer<State, Action, QueueEffect> {
  return (state, action, queueEffect) => {
    const next = reducer(state, action, queueEffect);

    queueEffect(() => {
      /* eslint-disable ban/ban */
      console.groupCollapsed(`Action: ${action.type}`);
      console.log('%cAction:', 'color: #00A7F7; font-weight: 700;', action);
      console.log(
        '%cPrevious State:',
        'color: #9E9E9E; font-weight: 700;',
        state
      );
      console.log('%cNext State:', 'color: #47B04B; font-weight: 700;', next);
      console.groupEnd();
      /* eslint-enable ban/ban */
    });

    return next;
  };
}

export function reduceEffectReducers<
  State,
  Action extends EventObject,
  QueueEffect extends EffectObject<State, Action>,
>(
  reducers: EffectReducer<State, Action, QueueEffect>[]
): EffectReducer<State, Action, QueueEffect> {
  return (state, action, queueEffect) => {
    return reducers.reduce(
      (acc, nextReducer) => nextReducer(acc, action, queueEffect),
      state
    );
  };
}

export type ComposeStateFn<T> = (s: T) => T;

export function compose<T>(...fns: ComposeStateFn<T>[]): ComposeStateFn<T> {
  return (s) => fns.reduce((acc, fn) => fn(acc), s);
}
