import copy from 'copy-to-clipboard';

import { diagnostics } from '@/utils/diagnostics';

const copyToClipboard = (
  clipboardContent: string,
  onCopyToClipboard: (content: string) => void,
  onWriteError: (error: Error) => void
) => {
  try {
    // copies to clipboard if browser supports it
    // otherwise, prompts user to copy manually
    copy(clipboardContent);

    // default to happy path unless explicit error
    onCopyToClipboard(clipboardContent);
  } catch (err) {
    diagnostics.error(`Failed to copy to clipboard`, err as Error);

    onWriteError(
      new Error(
        'Failed to copy to the clipboard. Please check your browser settings and try again.'
      )
    );
  }
};

export function useCopyToClipboard(): [typeof copyToClipboard] {
  return [copyToClipboard];
}
