import { cx } from '@emotion/css';
import { keyframes } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { WithClasses } from '@/styles/types';

export enum WiggleStatus {
  None = 'NONE',
  Wiggle = 'WIGGLE',
}

export interface WiggleProps {
  wiggleStatus: WiggleStatus;
  animation?: {
    cycleDurationMs?: number;
    numCycles?: number;
  };
  classes?: WithClasses<typeof useStyles>;
}

const wiggleKeyframes = keyframes({
  '10%, 90%': {
    transform: `translate3d(-1px, 0, 0)`,
  },
  '20%, 80%': {
    transform: `translate3d(2px, 0, 0)`,
  },
  '30%, 50%, 70%': {
    transform: `translate3d(-6px, 0, 0)`,
  },
  '40%, 60%': {
    transform: `translate3d(6px, 0, 0)`,
  },
});

const useStyles = makeStyles<{
  durationMs: number;
}>()((_theme, { durationMs }) => ({
  root: {},
  wiggleAnimationInfinite: {
    animation: `${wiggleKeyframes} ${durationMs}ms infinite`, // Infinite loop
  },
}));

export function Wiggle({
  wiggleStatus,
  animation: { cycleDurationMs = 1000, numCycles = undefined } = {
    cycleDurationMs: 1000,
    numCycles: undefined,
  },
  children,
  classes: externalClasses,
}: PropsWithChildren<WiggleProps>) {
  const viewOnly = useViewOnly();

  const [isWiggling, setIsWiggling] = useState(
    wiggleStatus === WiggleStatus.Wiggle
  );
  const [animationTick, setAnimationTick] = useState(0);
  const internalCycles = useRef(0);

  const { classes } = useStyles(
    { durationMs: cycleDurationMs },
    {
      props: { classes: externalClasses },
    }
  );

  useEffect(() => {
    if (viewOnly) {
      return;
    }

    const interval = setInterval(() => {
      setAnimationTick((tick) => tick + 1);
    }, cycleDurationMs);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (wiggleStatus) {
      case WiggleStatus.None:
        internalCycles.current = 0;
        setIsWiggling(false);
        break;
      case WiggleStatus.Wiggle: {
        if (numCycles) {
          if (internalCycles.current >= numCycles) {
            setIsWiggling(false);
            break;
          }
          internalCycles.current += 1;
        }
        setIsWiggling(true);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animationTick]); // Update wiggle status when animation tick changes

  const className = useMemo(() => {
    if (viewOnly) {
      return classes.root;
    }

    return cx(isWiggling && classes.wiggleAnimationInfinite, classes.root);
  }, [isWiggling, classes.wiggleAnimationInfinite, classes.root, viewOnly]);

  return <div className={className}>{children}</div>;
}
