import Decimal from 'decimal.js';
import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { getComparableDecimalJS } from '@/utils/decimalJSUtils';

import {
  AnnuityFormGratTrustFragment,
  GetAnnuitiesDetailsQuery,
} from '../graphql/AnnuitiesDetails.generated';
import { Fields, NAMESPACE, SubformField } from '../types';

export function useSyncAnnuitiesDataToForm(
  setValue: UseFormSetValue<Fields>,
  annuitiesData: GetAnnuitiesDetailsQuery | null,
  gratTrust: AnnuityFormGratTrustFragment | null,
  termDurationYears: number | null,
  taxableGift: Decimal | null
) {
  const comparableTaxableGift = getComparableDecimalJS(
    taxableGift ?? new Decimal(0)
  );
  // TODO: This function should return the values that should be set in the form by the parent, rather than
  // actually setting them in the form itself.
  useEffect(
    function syncFormWithAnnuitiesData() {
      if (!annuitiesData || !gratTrust) {
        return;
      }

      const savedAnnuityDataEdges = annuitiesData.gratAnnuityV2s?.edges ?? [];

      // we can either use the projection or the saved annuity data
      // we want to use the projection if
      //    the taxable gift has changed
      // or if the term duration has changed
      // or if we don't have any saved annuity data
      const shouldUseProjection =
        !savedAnnuityDataEdges.length ||
        termDurationYears !== savedAnnuityDataEdges?.length ||
        !taxableGift?.eq(gratTrust?.taxableGift ?? new Decimal(0));

      if (!shouldUseProjection) {
        setValue(`${NAMESPACE}.annuities` as const satisfies SubformField, []);

        savedAnnuityDataEdges?.forEach((edge) => {
          if (!edge?.node) return;
          const inputIndex = edge.node.yearOfTerm - 1;
          // NOTE: it's not clear to me why, but setting "annuities.${i}" to an object rather than
          // individually setting these fields doesn't work

          // as we move from the paymentAmount being the source of truth to the paymentPercentage being the source of truth,
          // handle this optimistically
          setValue(
            `${NAMESPACE}.annuities.${inputIndex}.paymentPercentage` as const satisfies SubformField,
            edge.node.initialFundingValuePercent ?? new Decimal(0)
          );
          setValue(
            `${NAMESPACE}.annuities.${inputIndex}.paymentAmount` as const satisfies SubformField,
            edge.node.computedPaymentAmount ?? new Decimal(0)
          );

          setValue(
            `${NAMESPACE}.annuities.${inputIndex}.id` as const satisfies SubformField,
            edge.node.id
          );
        });
      } else {
        setValue(`${NAMESPACE}.annuities` as const satisfies SubformField, []);

        // otherwise, if we don't yet have saved annuity information, we want to use the projection values
        // prefill these fields
        annuitiesData?.gratScenarioProjection?.scenarioTableRows?.forEach(
          (projection, i) => {
            // it's not clear to me why, but setting "annuities.${i}" to an object rather than
            // individually setting these fields doesn't work
            setValue(
              `${NAMESPACE}.annuities.${i}.paymentAmount` as const satisfies SubformField,
              projection.annuityPayment
            );
            setValue(
              `${NAMESPACE}.annuities.${i}.paymentPercentage` as const satisfies SubformField,
              projection.annuityPaymentPercentOfInitialFunding
            );
            setValue(
              `${NAMESPACE}.annuities.${i}.id` as const satisfies SubformField,
              null
            );
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      annuitiesData,
      setValue,
      gratTrust,
      termDurationYears,
      comparableTaxableGift,
    ]
  );
}
