import { DocumentNode } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { Footer } from '@/components/navigation/Footer';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { DesignerStagePicker } from '@/modules/entities/gratTrusts/GRATDesignerTrowser/DesignerStagePicker';
import { GratDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { FinishStructuringModal } from './FinishStructuringModal';

export interface StructuringDesignerFooterProps {
  householdId: string;
  shouldBlockNavigation: boolean;
  disabled?: boolean;
  entityId: string;
  onSave: () => Promise<boolean>;
  onSaveAndExit: (e: React.MouseEvent) => void;
  queriesToRefetch: DocumentNode[];
}

export function StructuringDesignerFooter({
  householdId,
  disabled,
  entityId,
  shouldBlockNavigation,
  queriesToRefetch,
  onSave,
  onSaveAndExit,
}: StructuringDesignerFooterProps) {
  const [isFinishStructuringModalOpen, setIsFinishStructuringModalOpen] =
    useState(false);
  const navigate = useNavigate();

  const handleSaveAndFinish = async () => {
    const saveSuccessful = await onSave();
    if (saveSuccessful) {
      setIsFinishStructuringModalOpen(true);
    }
  };

  return (
    <>
      <FinishStructuringModal
        queriesToRefetch={queriesToRefetch}
        householdId={householdId}
        entityId={entityId}
        isOpen={isFinishStructuringModalOpen}
        onClose={() => setIsFinishStructuringModalOpen(false)}
      />
      <Footer
        leftAction={
          <Button onClick={onSaveAndExit} variant="secondary" size="sm">
            {shouldBlockNavigation ? 'Save & close' : 'Close'}
          </Button>
        }
        rightAction={
          <Stack direction="row" spacing={2}>
            <DesignerStagePicker
              disabled={disabled}
              currentStage={GratDesignerStages.STRUCTURING}
              onChangeStage={(newStage) => {
                if (newStage === GratDesignerStages.STRUCTURING) return;
                navigate(
                  getCompletePathFromRouteKey(
                    ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER,
                    {
                      householdId,
                      entityId,
                      entityType: ENTITY_TYPES.GRAT,
                      designerStage: GratDesignerStages.BASIC_INFORMATION,
                    }
                  )
                );
              }}
            />
            <Box>
              <Button
                disabled={disabled}
                data-testid="SaveAndFinishButton"
                size="sm"
                variant="primary"
                onClick={handleSaveAndFinish}
              >
                {shouldBlockNavigation
                  ? 'Save & finish design'
                  : 'Finish design'}
              </Button>
            </Box>
          </Stack>
        }
      />
    </>
  );
}
