import { ChevronDownIcon } from '@/components/icons/ChevronDownIcon';

export interface RotatingChevronIconProps {
  isExpanded: boolean;
  size?: number;
}

export function RotatingChevronIcon({
  isExpanded,
  size = 24,
}: RotatingChevronIconProps) {
  return (
    <ChevronDownIcon
      size={size}
      sx={{
        transform: isExpanded ? undefined : 'rotate(-0.25turn)',
        transition: 'transform 0.2s ease-in-out',
      }}
    />
  );
}
