// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocumentEvidenceFragment = { __typename?: 'BoundingRegion', PageNumber: number, Polygon: Array<{ __typename?: 'Coordinate', x: number, y: number }> };

export type SearchDocumentWithResponseQueryVariables = Types.Exact<{
  input: Types.DocumentBoundingRegionQueryInput;
}>;


export type SearchDocumentWithResponseQuery = { __typename?: 'Query', queryDocument: { __typename?: 'QueryDocumentResultWithResponse', response: string, boundingRegions: Array<{ __typename?: 'BoundingRegion', PageNumber: number, Polygon: Array<{ __typename?: 'Coordinate', x: number, y: number }> }> } };

export const DocumentEvidenceFragmentDoc = gql`
    fragment DocumentEvidence on BoundingRegion {
  PageNumber
  Polygon {
    x
    y
  }
}
    `;
export const SearchDocumentWithResponseDocument = gql`
    query SearchDocumentWithResponse($input: DocumentBoundingRegionQueryInput!) {
  queryDocument(input: $input) {
    response
    boundingRegions {
      ...DocumentEvidence
    }
  }
}
    ${DocumentEvidenceFragmentDoc}`;

/**
 * __useSearchDocumentWithResponseQuery__
 *
 * To run a query within a React component, call `useSearchDocumentWithResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchDocumentWithResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchDocumentWithResponseQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSearchDocumentWithResponseQuery(baseOptions: Apollo.QueryHookOptions<SearchDocumentWithResponseQuery, SearchDocumentWithResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchDocumentWithResponseQuery, SearchDocumentWithResponseQueryVariables>(SearchDocumentWithResponseDocument, options);
      }
export function useSearchDocumentWithResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchDocumentWithResponseQuery, SearchDocumentWithResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchDocumentWithResponseQuery, SearchDocumentWithResponseQueryVariables>(SearchDocumentWithResponseDocument, options);
        }
export function useSearchDocumentWithResponseSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SearchDocumentWithResponseQuery, SearchDocumentWithResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchDocumentWithResponseQuery, SearchDocumentWithResponseQueryVariables>(SearchDocumentWithResponseDocument, options);
        }
export type SearchDocumentWithResponseQueryHookResult = ReturnType<typeof useSearchDocumentWithResponseQuery>;
export type SearchDocumentWithResponseLazyQueryHookResult = ReturnType<typeof useSearchDocumentWithResponseLazyQuery>;
export type SearchDocumentWithResponseSuspenseQueryHookResult = ReturnType<typeof useSearchDocumentWithResponseSuspenseQuery>;
export type SearchDocumentWithResponseQueryResult = Apollo.QueryResult<SearchDocumentWithResponseQuery, SearchDocumentWithResponseQueryVariables>;
export const Operations = {
  Query: {
    SearchDocumentWithResponse: 'SearchDocumentWithResponse' as const
  },
  Fragment: {
    DocumentEvidence: 'DocumentEvidence' as const
  }
}