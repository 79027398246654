import { Stack, Typography } from '@mui/material';

import { AddEntity } from '@/modules/entities/components/AddEntity';

export interface EmptyStateProps {
  householdId: string;
}

export const EmptyState = ({ householdId }: EmptyStateProps) => {
  return (
    <Stack gap={2} height="100%" alignItems="center" justifyContent="center">
      <Typography variant="h5">No entities available</Typography>
      <Typography>
        There are no entities on this client, add entities to the client in
        order to view the legacy blueprint.
      </Typography>
      <AddEntity householdId={householdId} />
    </Stack>
  );
};
