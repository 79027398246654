import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { MenuItem as MUIMenuItem } from '@mui/material';
import React from 'react';

import { Loader } from '@/components/progress/Loader/Loader';

export interface MenuItemProps {
  icon?: React.ReactNode;
  iconAfter?: React.ReactNode;
  label: React.ReactNode;
  onClick: (e: React.MouseEvent) => void;
  justifyContent?: 'left' | 'right' | 'space-between';
  loading?: boolean;
  disabled?: boolean;
  sx?: SxProps;
  typographyProps?: React.ComponentProps<typeof Typography>;
  muiMenuItemProps?: React.ComponentProps<typeof MUIMenuItem>;
}

export function MenuItem({
  icon,
  iconAfter,
  label,
  onClick,
  justifyContent,
  disabled,
  loading,
  sx,
  typographyProps,
  muiMenuItemProps,
}: MenuItemProps) {
  const theme = useTheme();
  return (
    <MUIMenuItem
      sx={{
        ...sx,
        justifyContent: justifyContent ?? 'left',
      }}
      onClick={onClick}
      disabled={disabled || loading}
      {...muiMenuItemProps}
    >
      <Stack
        direction="row"
        justifyContent={justifyContent}
        alignItems="center"
        spacing={1}
        width={'100%'}
      >
        {icon && <Box display="flex">{icon}</Box>}
        <Typography variant="body1" {...typographyProps}>
          {label}
        </Typography>
        {iconAfter && !loading && iconAfter}
        {loading && (
          <Loader
            boxProps={{
              sx: {
                display: 'flex',
              },
            }}
            circularProgressProps={{
              size: 14,
              sx: {
                color: theme.palette.primary.main,
              },
            }}
          />
        )}
      </Stack>
    </MUIMenuItem>
  );
}
