import { Box } from '@mui/material';
import React from 'react';

import { Avatar, AvatarProps } from '@/components/Avatar';
import { WarningIndicator } from '@/components/notifications/WarningIndicator/WarningIndicator';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { CurrentUserState } from '@/modules/authentication/UserContext';

import { SIDEBAR_ANIMATION_TIMING_MS } from './sidebarConstants';

interface SidebarAvatarProps {
  isExpanded: boolean;
  size?: AvatarProps['size'];
}

function getAvatarDisplayProperties(user: CurrentUserState) {
  if (!user) {
    return {
      initials: 'U',
      fullName: 'User',
    };
  }

  return {
    initials: user.initials,
    fullName: user.displayName,
  };
}

export function SidebarAvatar({ isExpanded, size }: SidebarAvatarProps) {
  const currentUser = useCurrentUser();
  return (
    <Box position="relative">
      {currentUser?.integrationLoginExpired && (
        <Box position="absolute" top={-8} right={-8}>
          <IntegrationLoginExpiredIndicator />
        </Box>
      )}
      <Avatar
        animationTimingMs={SIDEBAR_ANIMATION_TIMING_MS}
        onlyShowInitials={!isExpanded}
        size={size ?? 'md'}
        {...getAvatarDisplayProperties(currentUser)}
        variant="light"
      />
    </Box>
  );
}

function IntegrationLoginExpiredIndicator() {
  return (
    <WarningIndicator
      size={16}
      sx={{ position: 'absolute', top: 0, right: 0, zIndex: 1 }}
    />
  );
}
