import { Stack } from '@mui/material';
import { compact, flatMap, groupBy, isEmpty, partition } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';

import { ClientPresentationSection } from '@/types/schema';

import { EditCoverSlideModal } from '../../CoverSlide/EditCoverSlideModal';
import {
  ClientPresentationBundleTypes,
  ClientPresentationSlide,
  ClientPresentationStandaloneSlideType,
} from '../clientPresentation.types';
import { useGuardedClientPresentationDesignerContext } from '../contexts/clientPresentationDesigner.context';
import { sortItemsToPulidOrder } from '../contexts/ClientPresentationDesigner.provider.utils';
import { SIDEBAR_VERTICAL_SPACING } from './ClientPresentationSidebar.constants';
import { ClientPresentationSidebarEmptyState } from './ClientPresentationSidebarEmptyStates';
import { SidebarGroup } from './SidebarGroup/SidebarGroup';
import {
  SidebarSlideElement,
  SidebarSlideElementProps,
} from './SidebarSlideElement';

/**
 * @description This component is responsible for rendering the list of slide elements in the sidebar, as well as allowing for the management of
 * the visibility of those elements.
 */

export function SlideElementList() {
  const [editCoverSlideModalOpen, setEditCoverSlideModalOpen] =
    React.useState(false);
  const {
    presentationId,
    orderedBundles,
    renderedSectionOrder,
    standaloneSlides,
    shouldShowItem,
    setActiveSlideId,
    setItemVisibility,
    showHiddenItems,
    activeSlideId,
    visibleEntityPulids,
    visibleWaterfallPulids,
  } = useGuardedClientPresentationDesignerContext();
  const onToggleVisibility = useCallback(
    (identifier: string, isCurrentlyVisible: boolean) => {
      setItemVisibility(
        identifier,
        isCurrentlyVisible && !showHiddenItems ? 'hide' : 'show'
      );
    },
    [setItemVisibility, showHiddenItems]
  );

  const shouldShow = useCallback(
    (identifier: string | undefined) => {
      return identifier ? shouldShowItem(identifier) : true;
    },
    [shouldShowItem]
  );

  const getSidebarSlideElementProps = useCallback(
    (slide: ClientPresentationSlide): SidebarSlideElementProps => {
      return {
        slideId: slide.id,
        label: slide.displayName,
        isActive: slide.id === activeSlideId,
        onSelectSlide: () =>
          setActiveSlideId(slide.id, {
            doScroll: true,
            scrollBehavior: 'smooth',
          }),

        ...(slide.identifier
          ? {
              onToggleVisibility: () =>
                onToggleVisibility(
                  slide.identifier!,
                  shouldShow(slide.identifier)
                ),
            }
          : {}),
      };
    },
    [activeSlideId, onToggleVisibility, setActiveSlideId, shouldShow]
  );

  function handleEditCoverSlideClick() {
    setEditCoverSlideModalOpen(true);
  }

  const standaloneSlidesByIdentifier = groupBy(standaloneSlides, 'identifier');
  const coverSlides =
    standaloneSlidesByIdentifier[ClientPresentationStandaloneSlideType.COVER] ??
    [];
  const disclaimerSlides =
    standaloneSlidesByIdentifier[
      ClientPresentationStandaloneSlideType.DISCLAIMER
    ] ?? [];

  const [estateWaterfallBundles, entityBundles] = useMemo(() => {
    return partition(
      orderedBundles,
      (b) => b.type === ClientPresentationBundleTypes.ESTATE_WATERFALL
    );
  }, [orderedBundles]);

  const orderedEntityBundles = useMemo(() => {
    return sortItemsToPulidOrder(entityBundles, visibleEntityPulids ?? []);
  }, [entityBundles, visibleEntityPulids]);

  const orderedWaterfallBundles = useMemo(() => {
    return sortItemsToPulidOrder(
      estateWaterfallBundles,
      visibleWaterfallPulids ?? []
    );
  }, [estateWaterfallBundles, visibleWaterfallPulids]);

  const sectionToSidebarElementMap: Record<
    ClientPresentationSection,
    React.ReactNode
  > = useMemo(() => {
    const proTeamSlides =
      standaloneSlidesByIdentifier[
        ClientPresentationStandaloneSlideType.PROFESSIONAL_TEAM
      ] ?? [];
    const entityListSlides =
      standaloneSlidesByIdentifier[
        ClientPresentationStandaloneSlideType.ENTITIES_LIST
      ] ?? [];
    const balanceSheetSlides =
      standaloneSlidesByIdentifier[
        ClientPresentationStandaloneSlideType.BALANCE_SHEET
      ] ?? [];

    const shouldShowAnyEntityBundles = entityBundles.some((bundle) =>
      shouldShow(bundle.identifier)
    );

    const shouldShowAnyWaterfallBundles = estateWaterfallBundles.some(
      (bundle) => shouldShow(bundle.identifier)
    );

    return {
      BALANCE_SHEET: balanceSheetSlides.flatMap((slide) => {
        const shouldShowSlide = shouldShow(slide.identifier);
        if (!shouldShowSlide) return [];

        return (
          <SidebarSlideElement
            key={slide.id}
            {...getSidebarSlideElementProps(slide)}
          />
        );
      }),
      ALL_ENTITIES_SLIDE: entityListSlides.flatMap((slide) => {
        const shouldShowSlide = shouldShow(slide.identifier);
        if (!shouldShowSlide) return [];

        return (
          <SidebarSlideElement
            key={slide.id}
            {...getSidebarSlideElementProps(slide)}
          />
        );
      }),
      PROFESSIONAL_TEAM: proTeamSlides.flatMap((slide, i) => {
        const shouldShowSlide = shouldShow(slide.identifier);
        if (!shouldShowSlide) return [];

        return (
          <SidebarSlideElement
            key={`${slide.id}-${i}`}
            {...getSidebarSlideElementProps(slide)}
          />
        );
      }),
      ENTITIES_GROUP: shouldShowAnyEntityBundles && (
        <SidebarGroup isHidden={!shouldShowAnyEntityBundles} label="Entities">
          {orderedEntityBundles.map((bundle) => {
            const shouldShowBundle = shouldShow(bundle.identifier);

            return (
              <SidebarGroup
                key={bundle.id}
                label={bundle.displayName}
                isHidden={!shouldShowBundle}
                {...(bundle.identifier
                  ? {
                      onToggleVisibility: () =>
                        onToggleVisibility(
                          bundle.identifier!,
                          shouldShowBundle
                        ),
                    }
                  : {})}
              >
                {bundle.slides.flatMap((slide) => {
                  const shouldShowSlide = shouldShow(slide.identifier);
                  if (!shouldShowSlide) return [];
                  return (
                    <SidebarSlideElement
                      key={slide.id}
                      {...getSidebarSlideElementProps(slide)}
                    />
                  );
                })}
              </SidebarGroup>
            );
          })}
        </SidebarGroup>
      ),
      ESTATE_WATERFALLS_GROUP: shouldShowAnyWaterfallBundles && (
        <SidebarGroup
          isHidden={!shouldShowAnyWaterfallBundles}
          label="Estate waterfalls"
        >
          {orderedWaterfallBundles.map((bundle) => {
            const shouldShowBundle = shouldShow(bundle.identifier);

            return (
              <SidebarGroup
                key={bundle.id}
                label={bundle.displayName}
                isHidden={!shouldShowBundle}
                {...(bundle.identifier
                  ? {
                      onToggleVisibility: () =>
                        onToggleVisibility(
                          bundle.identifier!,
                          shouldShowBundle
                        ),
                    }
                  : {})}
              >
                {bundle.slides.flatMap((slide) => {
                  const shouldShowSlide = shouldShow(slide.identifier);
                  if (!shouldShowSlide) return [];
                  return (
                    <SidebarSlideElement
                      key={slide.id}
                      {...getSidebarSlideElementProps(slide)}
                    />
                  );
                })}
              </SidebarGroup>
            );
          })}
        </SidebarGroup>
      ),
    };
  }, [
    entityBundles,
    estateWaterfallBundles,
    getSidebarSlideElementProps,
    onToggleVisibility,
    orderedEntityBundles,
    orderedWaterfallBundles,
    shouldShow,
    standaloneSlidesByIdentifier,
  ]);

  const allRenderedElements = [
    // cover slide always comes first. there will only be one cover slide,
    // but `partition` returns an array, so we're just handling that here.
    ...compact(
      coverSlides.map(
        (s) =>
          s.identifier &&
          shouldShowItem(s.identifier) && (
            <SidebarSlideElement
              key={s.id}
              // only cover slides can be edited
              onEdit={handleEditCoverSlideClick}
              {...getSidebarSlideElementProps(s)}
            />
          )
      )
    ),
    ...flatMap(renderedSectionOrder, (section, key) => {
      const sectionContents = sectionToSidebarElementMap[section];
      if (isEmpty(sectionContents)) return [];
      return (
        <Fragment key={key}>{sectionToSidebarElementMap[section]}</Fragment>
      );
    }),

    /* disclaimer slides always come last */
    ...disclaimerSlides.flatMap((slide) => {
      const shouldShowSlide = shouldShow(slide.identifier);
      if (!shouldShowSlide) return [];

      // we've decided that the disclaimer slide can't be hidden for now, so omitting that property here
      // https://withluminary.slack.com/archives/C060DKAQBN1/p1704816945968679
      const { onToggleVisibility: _oTV, ...props } =
        getSidebarSlideElementProps(slide);
      return <SidebarSlideElement key={slide.id} {...props} />;
    }),
  ];

  if (allRenderedElements.length === 0) {
    if (showHiddenItems) {
      return (
        <ClientPresentationSidebarEmptyState
          heading="No hidden slides"
          description="All available slides are currently included in this presentation"
        />
      );
    }
    return (
      <ClientPresentationSidebarEmptyState
        heading="All slides are hidden"
        description="Click on the “View hidden” button at the bottom left to view & unhide hidden slides"
      />
    );
  }

  return (
    <>
      {editCoverSlideModalOpen && (
        <EditCoverSlideModal
          isOpen={editCoverSlideModalOpen}
          onClose={() => setEditCoverSlideModalOpen(false)}
          presentationId={presentationId ?? ''}
        />
      )}

      <Stack spacing={SIDEBAR_VERTICAL_SPACING}>
        {allRenderedElements.map((element, i) => (
          <Fragment key={i}>{element}</Fragment>
        ))}
      </Stack>
    </>
  );
}
