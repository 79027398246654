import { Stack } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { last } from 'lodash';
import { PropsWithChildren } from 'react';
import { Outlet, useMatches, useParams } from 'react-router-dom';

import { appbarHeight } from '@/components/architecture/Layout/AppBar/AppBar';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { PageHeader } from '@/components/navigation/PageHeader';
import { StepTracker } from '@/components/navigation/StepTracker/StepTracker';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { ErrorFeedbackFallback } from '@/components/notifications/Feedback/ErrorFeedbackFallback';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { DeleteProposalMenuItem } from '@/modules/proposal/components/DeleteProposalMenuItem/DeleteProposalMenuItem';
import { DuplicateProposalMenuItem } from '@/modules/proposal/components/DuplicateProposalMenuItem/DuplicateProposalMenuItem';
import { ProposalDetailsAwareRoute } from '@/modules/proposal/contexts/ProposalDetailsAwareRoute';
import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';
import {
  CharitableTrustDesignerStages,
  ROUTE_KEYS,
} from '@/navigation/constants';
import { ProposalKind } from '@/pages/client-proposal/hooks/useProposalsQuery';

import {
  CharitableTrustDesignerProvider,
  useCharitableTrustDesignerContext,
  useIsCRT,
} from './CharitableTrustDesignerContext';

const StackWrapper = ({ children }: PropsWithChildren) => (
  <Stack height={`calc(100vh - ${appbarHeight})`}>{children}</Stack>
);

function CharitableTrustDesignerInner() {
  const { variant, proposalId, householdId } =
    useCharitableTrustDesignerContext();
  const { navigate } = useNavigateToRoute();
  const isCRT = useIsCRT();
  const { showFeedback } = useFeedback();
  const matches = useMatches();

  const isNewProposal = proposalId === NEW_PROPOSAL_ID;
  const lastMatchPath = last(matches)?.pathname || '';

  const activeSegmentIndex = lastMatchPath.includes(
    CharitableTrustDesignerStages.BASIC_INFORMATION
  )
    ? 1
    : 2;

  const kind: ProposalKind = isCRT ? ProposalKind.CRT : ProposalKind.CLT;

  return (
    <StackWrapper>
      <PageHeader
        heading={`Model a ${variant.toUpperCase()} analysis`}
        action={
          <Stack
            direction="row"
            alignItems="center"
            alignContent="center"
            spacing={2}
          >
            <StepTracker
              segments={[
                {
                  display: 'Basic information',
                  isComplete: activeSegmentIndex >= 1,
                  onClick: () =>
                    navigate(
                      ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION,
                      { variant, proposalId, householdId }
                    ),
                },
                {
                  display: 'Analysis illustration',
                  isComplete: activeSegmentIndex >= 2,
                  onClick: () => {
                    if (!isNewProposal) {
                      navigate(
                        ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_MODEL_SCENARIOS,
                        { variant, proposalId, householdId }
                      );
                    }
                  },
                },
              ]}
              activeSegmentIndex={activeSegmentIndex}
            />
            <ContextMenuButton variant="secondary" disabled={isNewProposal}>
              <DuplicateProposalMenuItem
                proposalId={proposalId}
                kind={kind}
                afterDuplicate={(createdProposalId: string) => {
                  showFeedback('Proposal duplicated successfully.', {
                    variant: 'success',
                  });
                  navigate(
                    ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION,
                    {
                      householdId,
                      proposalId: createdProposalId,
                      variant: isCRT ? 'crt' : 'clt',
                    }
                  );
                }}
              />
              <DeleteProposalMenuItem
                proposalId={proposalId}
                kind={kind}
                onDelete={() => {
                  navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_PROPOSALS, {
                    householdId,
                  });
                }}
              />
            </ContextMenuButton>
          </Stack>
        }
      />
      <Outlet />
    </StackWrapper>
  );
}

export function CharitableTrustDesignerPage() {
  const { householdId, proposalId, variant } = useParams<{
    householdId: string;
    proposalId: string;
    variant: string;
  }>();

  if (!proposalId) {
    throw new Error(
      `Unrecognized proposalId is not a uuid or "${NEW_PROPOSAL_ID}".`
    );
  }

  if (!householdId) {
    throw new Error(`No householdId provided.`);
  }

  return (
    <ErrorBoundary fallback={<ErrorFeedbackFallback />}>
      <ProposalDetailsAwareRoute proposalId={proposalId}>
        <CharitableTrustDesignerProvider
          variant={variant}
          householdId={householdId}
          proposalId={proposalId}
        >
          <CharitableTrustDesignerInner />
        </CharitableTrustDesignerProvider>
      </ProposalDetailsAwareRoute>
    </ErrorBoundary>
  );
}
