import { Box, Stack, Typography } from '@mui/material';

import {
  BACKGROUND_IMAGE_PROPERTY_VALUE,
  generateBrandedBackgroundWithColor,
} from '@/components/utils/brandedBackgroundUtils';
import { COLORS } from '@/styles/tokens/colors';

import { Card } from '../../layout/Card/Card';
import { Button } from '../baseInputs/Button';

export type EmptyPlaceholderProps = React.PropsWithChildren<{
  buttonText?: React.ReactNode;
  errorMessage?: string;
  onClick?: () => void;
  inputRef?: React.Ref<unknown>;
  actionComponent?: JSX.Element;
}> &
  // the `ref` definition of HTMLProps<DivElement> conflicts with the definition that `Card` expects, and we don't
  // actually need it, so omitting it
  Omit<React.HTMLProps<HTMLDivElement>, 'ref'>;

export function EmptyPlaceholder({
  children,
  buttonText,
  errorMessage,
  onClick,
  inputRef,
  actionComponent,
  ...htmlProps
}: EmptyPlaceholderProps) {
  const errorProps = errorMessage
    ? {
        border: `solid ${COLORS.FUNCTIONAL.ERROR.DEFAULT} 1px`,
        backgroundImage: generateBrandedBackgroundWithColor(),
      }
    : { background: BACKGROUND_IMAGE_PROPERTY_VALUE };
  const mergedSx = Object.assign(
    {
      p: 6,
      ':focus': {
        outline: `solid ${COLORS.FUNCTIONAL.ERROR.DEFAULT} 2px`,
        outlineOffset: 2,
      },
    },
    errorProps
  );

  return (
    <Card
      {...htmlProps}
      sx={mergedSx}
      variant="filled"
      tabIndex={-1}
      inputRef={inputRef}
    >
      <Stack spacing={3}>
        <Box mb={3}>
          <Typography component="div" variant="body2">
            {children}
          </Typography>
        </Box>
        <Box component="footer">
          {actionComponent ? (
            actionComponent
          ) : (
            <Button size="sm" variant="primary" onClick={onClick} fullWidth>
              {buttonText}
            </Button>
          )}
        </Box>
        {errorMessage && (
          <Box>
            <Typography
              variant="subtitle2"
              fontSize={14}
              color={COLORS.FUNCTIONAL.ERROR.DEFAULT}
            >
              {errorMessage}
            </Typography>
          </Box>
        )}
      </Stack>
    </Card>
  );
}
