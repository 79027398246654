import { Box, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';

import { FileShield02Icon } from '@/components/icons/FileShield02Icon';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { COLORS } from '@/styles/tokens/colors';
import { TrustTaxStatus } from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

interface Props {
  displayName: string;
  percentOwnership: Decimal;
  distributedValue: Decimal | null;
  trustTaxableStatus: TrustTaxStatus | null;
}

const GRANTOR_TRUST = TrustTaxStatus.GrantorTrust;
const TAXABLE_TRUST = TrustTaxStatus.NonGrantorTrust;

export const REMAINDER_TRUST_COPY_MAP: Record<TrustTaxStatus, string> = {
  [GRANTOR_TRUST]: 'Grantor trust',
  [TAXABLE_TRUST]: 'Non-grantor trust',
  [TrustTaxStatus.NonTaxableTrust]: 'Non-taxable trust',
};

export function TrustLeaf({
  displayName,
  percentOwnership,
  trustTaxableStatus,
  distributedValue,
}: Props) {
  const theme = useTheme();

  return (
    <Box
      bgcolor={theme.palette.common.white}
      padding={2}
      borderRadius={1.5}
      border={`2.5px ${COLORS.GRAY[300]} solid`}
    >
      <Stack spacing={0.5}>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={1}
          color={theme.palette.grey[500]}
        >
          <FileShield02Icon size={32} />
          <Stack
            sx={{
              borderRadius: '2px',
              backgroundColor: theme.palette.grey[300],
              px: 1,
              py: '2px',
            }}
          >
            <Typography variant="h6">
              {`${formatPercent(percentOwnership, 2)}%`}
            </Typography>
          </Stack>
        </Stack>
        <TypographyClamped variant="h5" lines={2}>
          {displayName}
        </TypographyClamped>
        {trustTaxableStatus && (
          <Typography variant="subtitle2">
            {REMAINDER_TRUST_COPY_MAP[trustTaxableStatus]}
          </Typography>
        )}
        {distributedValue && (
          <Typography variant="h5" component="div">
            {formatCurrency(distributedValue)}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
