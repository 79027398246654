import { Box, Stack } from '@mui/material';
import React from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { DeleteButton } from '@/components/form/baseInputs/Button/DeleteButton';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Modal, ModalProps } from '@/components/modals/Modal/Modal';
import { useForm } from '@/components/react-hook-form';
import { useAssetCategoryOptions } from '@/modules/entities/StructuredAssetsSubform/hooks/useAssetCategoryOptions';
import { AssetValueV2OwnershipType } from '@/types/schema';

import { ASSET_QSBS_ELIGIBILITY_OPTIONS } from '../AssetQSBSEligibility';
import { Asset } from '../types/asset';
import { AssetCategoryField } from './AssetCategoryField';
import { AssetDetailsForm } from './AssetDetailsModal.types';

export type AssetDetailsModalProps = Omit<
  ModalProps,
  'children' | 'heading'
> & {
  asset: Asset;
  isNewAsset: boolean;
  onSubmit: (
    value: Asset,
    opts: { isRemoveAsset: boolean; isNewAsset: boolean }
  ) => void;
};

function generateInitialValues(asset: Asset): AssetDetailsForm {
  return {
    categoryId: asset.categoryId || '',
    displayName: asset.displayName,
    marketValue: asset.marketValue,
    qsbsEligibility: asset.qsbsEligibility,
  };
}

function normalizeAssetForSave(
  assetId: string,
  originalAsset: Asset,
  formValues: AssetDetailsForm
): Asset {
  return {
    id: assetId,
    categoryId: formValues.categoryId,
    displayName: formValues.displayName,
    valueId: originalAsset.valueId,
    qsbsEligibility: formValues.qsbsEligibility,
    marketValue: formValues.marketValue,
    valuationMethod: AssetValueV2OwnershipType.ValueBased,
  };
}

function AssetDetailsModalInner({
  asset,
  onClose,
  onSubmit,
  isOpen,
  isNewAsset,
  ...modalProps
}: AssetDetailsModalProps) {
  const { options: assetCategoryOptions, loading: formOptionsLoading } =
    useAssetCategoryOptions();
  const initialValues = generateInitialValues(asset);
  const formMethods = useForm<AssetDetailsForm>({
    defaultValues: initialValues,
  });
  const { control, handleSubmit, reset } = formMethods;

  const onValidSubmit: SubmitHandler<AssetDetailsForm> = (values) => {
    onSubmit(normalizeAssetForSave(asset.id, asset, values), {
      isRemoveAsset: false,
      isNewAsset,
    });
    reset();
  };

  const onFormSubmit = handleSubmit(onValidSubmit);

  const modalActions = (
    <Stack px={1.5} direction="row" justifyContent="space-between" width="100%">
      <Box>
        {!isNewAsset && (
          <DeleteButton
            confirmDeleteText="Delete asset"
            aria-label="Delete asset"
            onConfirmDelete={() =>
              onSubmit(asset, { isRemoveAsset: true, isNewAsset: false })
            }
          />
        )}
      </Box>
      <Stack direction="row" spacing={1}>
        <Button onClick={onClose} size="sm" variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={onFormSubmit}
          size="sm"
          variant="primary"
          type="submit"
        >
          {isNewAsset ? 'Add asset' : 'Save asset'}
        </Button>
      </Stack>
    </Stack>
  );

  return (
    <FormProvider {...formMethods}>
      <Modal
        isOpen={isOpen}
        actions={modalActions}
        onClose={onClose}
        {...modalProps}
        heading={'Asset details'}
      >
        <Stack
          component="form"
          noValidate
          onSubmit={onFormSubmit}
          flex={1}
          sx={{ minHeight: 450 }}
        >
          <FormLayoutRow>
            <FormLayoutItem>
              <FormFieldsDisabledProvider isDisabled={formOptionsLoading}>
                <AssetCategoryField options={assetCategoryOptions ?? []} />
              </FormFieldsDisabledProvider>
            </FormLayoutItem>
          </FormLayoutRow>

          <FormLayoutRow>
            <FormLayoutItem>
              <FormAwareTextInput<AssetDetailsForm>
                control={control}
                fieldName="displayName"
                required
                label="Asset name"
              />
            </FormLayoutItem>
          </FormLayoutRow>

          <FormLayoutRow>
            <FormLayoutItem width={8}>
              <FormAwareCurrencyInput<AssetDetailsForm>
                control={control}
                fieldName="marketValue"
                required
                isDecimalJSInput
                label="Market value"
              />
            </FormLayoutItem>
            <FormLayoutItem width={8}>
              <FormAwareSelectInput<AssetDetailsForm>
                control={control}
                testId="assetQsbsEligibility"
                fieldName="qsbsEligibility"
                required
                label="QSBS eligibility"
                options={ASSET_QSBS_ELIGIBILITY_OPTIONS}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </Stack>
      </Modal>
    </FormProvider>
  );
}

export function AssetDetailsModal(modalProps: AssetDetailsModalProps) {
  return <AssetDetailsModalInner {...modalProps} />;
}
