import { Box, InputAdornment, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Path, UseFieldArrayRemove, useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { useFormFieldsDisabled } from '@/components/form/context/formFieldsDisabled.context';
import { AdditionalItemRendererProps } from '@/components/form/formAwareInputs/FormAwareInput.types';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import {
  FormAwareSelectInput,
  FormAwareSelectInputProps,
} from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { User01Icon } from '@/components/icons/User01Icon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { getMaxLengthValidation } from '@/components/utils/validators';
import { LocatableFormAwareInput } from '@/modules/aiSuggestions/components/forms/LocatableFormAwareInput';
import { BENEFICIARY_LEVEL_ITEMS } from '@/modules/beneficiaries/beneficiaries.constants';
import { AddNewAnythingModal } from '@/modules/common/AddNewAnythingModal/AddNewAnythingModal';
import { EntityMemberLevel } from '@/modules/content/tooltipContent/EntityMemberLevel';
import { EntityDocumentViewerDocument } from '@/modules/documents/components/HouseholdDocumentsViewer/graphql/EntityDocumentViewer.generated';
import { AccessParameterKind, AiSuggestionKind } from '@/types/schema';

import { AccessParametersForm } from './AccessParametersForm';
import {
  AccessParameterRadioGroupKind,
  EntityBeneficiariesFormBeneficiary,
  EntityBeneficiariesFormNamespace,
  EntityBeneficiariesFormSecondNamespace,
  EntityBeneficiariesFormVariant,
} from './EntityBeneficiariesSubform.types';
import { PowerOfAppointmentForm } from './PowerOfAppointmentForm';
import { ScheduledDistributionsForm } from './ScheduledDistributionsForm';

type BeneficiaryFormPaths = Path<EntityBeneficiariesFormBeneficiary>;

export function getBeneficiaryFormFieldName<T extends BeneficiaryFormPaths>(
  prefix: EntityBeneficiaryFormBodyV2Props['fieldName'],
  scheduledDistributionFormPath: T
): T {
  return `${prefix}.${scheduledDistributionFormPath}` as T;
}

export interface EntityBeneficiaryFormBodyV2Props {
  beneficiaryOptions: SelectInputProps['options'];
  householdId: string;
  variant: EntityBeneficiariesFormVariant;
  splitScreen: boolean;
  additionalItemRendererProps?: AdditionalItemRendererProps;
  fieldName: `${EntityBeneficiariesFormNamespace}.${EntityBeneficiariesFormSecondNamespace}.${number}`;
  beneficiaryIndex: number;
  remove: UseFieldArrayRemove;
}

export function EntityBeneficiaryFormBodyV2({
  beneficiaryOptions,
  householdId,
  variant,
  splitScreen,
  additionalItemRendererProps,
  fieldName,
  beneficiaryIndex,
  remove,
}: EntityBeneficiaryFormBodyV2Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [newlyCreatedId, setNewlyCreatedId] = useState('');

  const { control, setShouldBlockNavigation, setFocus, setValue } =
    useFormContext<EntityBeneficiariesFormBeneficiary>();

  const notes = useWatch({
    name: getBeneficiaryFormFieldName(fieldName, 'notes'),
    control,
  });

  const [notesAreExpanded, setNotesAreExpanded] = useState(Boolean(notes));

  function handleExpandNotes() {
    setNotesAreExpanded(true);
    // allow a loop for the notes to expand
    setTimeout(() => {
      setFocus(getBeneficiaryFormFieldName(fieldName, 'notes'));
    });
  }

  const { disabled: disabledFromContext } = useFormFieldsDisabled();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const accessParameters = useWatch({
    name: getBeneficiaryFormFieldName(
      fieldName,
      'beneficiaryFormAccessParameters'
    ),
    control,
  });

  const scheduledDistributions = useWatch({
    name: getBeneficiaryFormFieldName(
      fieldName,
      'beneficiaryFormScheduledDistributions'
    ),
    control,
  });

  // generally speaking, it's not required to select a beneficiary. however, we want to make sure that
  // a user isn't losing valuable data that they've entered because they forget to select the underlying beneficiary.
  // so, we mark the beneficiary dropdown as "required" if the user has entered data in what we deem to be
  // "high-value" fields in the subform.
  const beneficiarySelectionRequired = useMemo(() => {
    if (notes) return true;
    if (accessParameters?.accessParametersPartial?.length) return true;
    if (
      Object.values(
        accessParameters?.accessParametersOther?.checkboxes ?? {}
      ).filter((checked) => {
        return Boolean(checked);
      }).length
    )
      return true;
    if (scheduledDistributions?._hasScheduledDistributions) return true;
    return false;
  }, [
    accessParameters?.accessParametersOther?.checkboxes,
    accessParameters?.accessParametersPartial?.length,
    notes,
    scheduledDistributions?._hasScheduledDistributions,
  ]);

  useEffect(() => {
    if (splitScreen && newlyCreatedId && !isOpen) {
      // Closing the modal after creating a new entity/individual/organization
      // should block navigation for the parent form.
      setShouldBlockNavigation(true);
      setNewlyCreatedId('');
    }
  }, [newlyCreatedId, setShouldBlockNavigation, isOpen, splitScreen]);

  // Effect to set the access parameter kind to partial for the percentOnly variant
  // since the percentOnly variant will always just be a single percent parameter
  useEffect(() => {
    if (variant === 'percentOnly') {
      setValue(
        `${getBeneficiaryFormFieldName(
          fieldName,
          'beneficiaryFormAccessParameters'
        )}._accessParameterKind`,
        AccessParameterRadioGroupKind.Partial
      );
      setValue(
        `${getBeneficiaryFormFieldName(
          fieldName,
          'beneficiaryFormAccessParameters'
        )}.accessParametersPartial.0.kind`,
        AccessParameterKind.Percentage
      );
    }
  }, [fieldName, setValue, variant]);

  return (
    <>
      <AddNewAnythingModal
        addableTypes={['entity', 'individual', 'organization']}
        handleClose={handleClose}
        isOpen={isOpen}
        householdId={householdId}
        onAfterCreate={setNewlyCreatedId}
        addClientProfileModalProps={{
          ignoredQueryDocuments: [EntityDocumentViewerDocument],
        }}
        addClientOrganizationModalProps={{
          ignoredQueryDocuments: [EntityDocumentViewerDocument],
        }}
      />
      <Stack data-testid={`EntityBeneficiary-${beneficiaryIndex}`}>
        <FormLayoutRow sx={{ alignItems: 'center' }}>
          <FormLayoutItem width={8}>
            <LocatableFormAwareInput<
              EntityBeneficiariesFormBeneficiary,
              FormAwareSelectInputProps<EntityBeneficiariesFormBeneficiary>
            >
              FormAwareElement={FormAwareSelectInput}
              suggestionKind={AiSuggestionKind.BeneficiaryV2}
              control={control}
              label="Beneficiary"
              fieldName={getBeneficiaryFormFieldName(
                fieldName,
                'beneficiaryId'
              )}
              addNewOption={{
                onClick: () => setIsOpen(true),
              }}
              options={beneficiaryOptions}
              required={beneficiarySelectionRequired}
              startAdornment={
                <InputAdornment position="start">
                  <User01Icon size={20} />
                </InputAdornment>
              }
              additionalItemRendererProps={additionalItemRendererProps}
            />
          </FormLayoutItem>
          <FormLayoutItem width={4}>
            <FormAwareSelectInput<EntityBeneficiariesFormBeneficiary>
              control={control}
              label="Ordering"
              contextualHelp={<EntityMemberLevel />}
              fieldName={getBeneficiaryFormFieldName(fieldName, 'level')}
              options={BENEFICIARY_LEVEL_ITEMS}
            />
          </FormLayoutItem>
        </FormLayoutRow>
        {variant === 'percentOnly' ? (
          <FormLayoutRow>
            <FormLayoutItem width={4}>
              <FormAwarePercentInput<EntityBeneficiariesFormBeneficiary>
                label="Distribution percentage"
                // these fields are typically not required, but in the case of a percentOnly variant (currently only GRATs),
                // we force collection of this field
                required
                fieldName={
                  // the percent-only variant only has one distribution, so we can safely zero-index
                  `${getBeneficiaryFormFieldName(
                    fieldName,
                    'beneficiaryFormAccessParameters'
                  )}.accessParametersPartial.0.percentage`
                }
                control={control}
                isDecimalJSInput
                decimalScale={2}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        ) : (
          <Stack spacing={1}>
            <AccessParametersForm
              fieldName={getBeneficiaryFormFieldName(
                fieldName,
                'beneficiaryFormAccessParameters'
              )}
            />
            <ScheduledDistributionsForm
              fieldName={getBeneficiaryFormFieldName(
                fieldName,
                'beneficiaryFormScheduledDistributions'
              )}
            />
            <PowerOfAppointmentForm
              fieldName={getBeneficiaryFormFieldName(
                fieldName,
                'beneficiaryFormPowerOfAppointment'
              )}
            />
          </Stack>
        )}
        <FormLayoutRow
          sx={{
            display: notesAreExpanded ? 'initial' : 'none',
            pt: notesAreExpanded ? 3 : 0,
          }}
        >
          <FormLayoutItem width={12}>
            <FormAwareTextInput<EntityBeneficiariesFormBeneficiary>
              label="Notes"
              multiline
              fieldName={getBeneficiaryFormFieldName(fieldName, 'notes')}
              control={control}
              rows={2}
              validation={{
                maxLength: (value) =>
                  getMaxLengthValidation(2048)(value as string),
              }}
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <Stack
          direction="row"
          justifyContent="space-between"
          pt={notesAreExpanded ? 0 : 3}
        >
          {!notesAreExpanded && (
            <Button
              variant="secondary"
              size="sm"
              onClick={handleExpandNotes}
              startIcon={PlusIcon}
            >
              Add notes
            </Button>
          )}
          <Box />
          <Button
            disabled={disabledFromContext}
            variant="destructive-transparent"
            size="sm"
            onClick={() => {
              remove(beneficiaryIndex);
            }}
            startIcon={Trash01Icon}
            data-testid="button-remove-repeater-input-item"
          >
            Remove beneficiary
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
