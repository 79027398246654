// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
export type ProfessionalTeamRoleSlide_ProfessionalTeamRoleFragment = { __typename?: 'ProfessionalTeamRole', id: string, kind: Types.ProfessionalTeamRoleKind, powerOfAttorneyKind?: Types.ProfessionalTeamRolePowerOfAttorneyKind | null, otherRoleName?: string | null, associatedClientIndividual?: { __typename?: 'ClientProfile', id: string, displayName: string } | null };

export type ProfessionalTeamSlide_ClientProfileFragment = { __typename?: 'ClientProfile', id: string, displayName: string, email?: string | null, professionalTeamRoles: { __typename?: 'ProfessionalTeamRoleConnection', edges?: Array<{ __typename?: 'ProfessionalTeamRoleEdge', node?: { __typename?: 'ProfessionalTeamRole', id: string, kind: Types.ProfessionalTeamRoleKind, powerOfAttorneyKind?: Types.ProfessionalTeamRolePowerOfAttorneyKind | null, otherRoleName?: string | null, associatedClientIndividual?: { __typename?: 'ClientProfile', id: string, displayName: string } | null } | null } | null> | null } };

export const ProfessionalTeamRoleSlide_ProfessionalTeamRoleFragmentDoc = gql`
    fragment ProfessionalTeamRoleSlide_ProfessionalTeamRole on ProfessionalTeamRole {
  id
  kind
  powerOfAttorneyKind
  otherRoleName
  associatedClientIndividual {
    id
    displayName
  }
}
    `;
export const ProfessionalTeamSlide_ClientProfileFragmentDoc = gql`
    fragment ProfessionalTeamSlide_ClientProfile on ClientProfile {
  id
  displayName
  email
  professionalTeamRoles {
    edges {
      node {
        ...ProfessionalTeamRoleSlide_ProfessionalTeamRole
      }
    }
  }
}
    ${ProfessionalTeamRoleSlide_ProfessionalTeamRoleFragmentDoc}`;
export const Operations = {
  Fragment: {
    ProfessionalTeamRoleSlide_ProfessionalTeamRole: 'ProfessionalTeamRoleSlide_ProfessionalTeamRole' as const,
    ProfessionalTeamSlide_ClientProfile: 'ProfessionalTeamSlide_ClientProfile' as const
  }
}