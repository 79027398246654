import { SubForm } from '@/types/subform';

import {
  BeneficiariesSubformType,
  Fields,
  NAMESPACE,
  NamespaceType,
  Props,
  VariantType,
} from './types';
import { UI } from './ui/UI';
import { defaultValues } from './utils/defaultValues';

export class BeneficiariesSubform
  implements
    SubForm<
      BeneficiariesSubformType,
      Fields,
      NamespaceType,
      VariantType,
      object
    >
{
  FormComponent: (props: Props) => JSX.Element;
  defaultValues: BeneficiariesSubformType;
  namespace: NamespaceType;

  constructor() {
    this.FormComponent = UI;
    this.defaultValues = defaultValues;
    this.namespace = NAMESPACE;
  }
}
