import { createContext, PropsWithChildren } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export interface EstateWaterfallComparisonModalContextShape {
  isPresentation: boolean;
  baseWaterfallId?: string;
}

export const EstateWaterfallComparisonModalContext = createContext<
  EstateWaterfallComparisonModalContextShape | undefined
>(undefined);

export function useEstateWaterfallComparisonModalContext() {
  return useGuardedContext(
    EstateWaterfallComparisonModalContext,
    'EstateWaterfallComparisonModalContext'
  );
}

export interface EstateWaterfallComparisonModalContextProviderProps {
  isPresentation?: boolean;
  baseWaterfallId?: string;
}

export function EstateWaterfallComparisonModalContextProvider({
  children,
  isPresentation = false,
  baseWaterfallId,
}: PropsWithChildren<EstateWaterfallComparisonModalContextProviderProps>) {
  return (
    <EstateWaterfallComparisonModalContext.Provider
      value={{ isPresentation, baseWaterfallId }}
    >
      {children}
    </EstateWaterfallComparisonModalContext.Provider>
  );
}
