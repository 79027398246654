import { Box, Stack, Typography, useTheme } from '@mui/material';

import { User01Icon } from '@/components/icons/User01Icon';
import { TypographyClamped } from '@/components/typography/TypographyClamped';

interface Props {
  displayName: string;
}

export function GrantorLeaf({ displayName }: Props) {
  const theme = useTheme();

  return (
    <Box padding={2} borderRadius={1.5} bgcolor={theme.palette.common.white}>
      <Stack spacing={0.5}>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={1}
          color={theme.palette.grey[400]}
        >
          <User01Icon size={32} />
        </Stack>
        <TypographyClamped variant="h5" lines={2}>
          {displayName}
        </TypographyClamped>
        <Typography variant="subtitle2">Grantor</Typography>
      </Stack>
    </Box>
  );
}
