import Decimal from 'decimal.js';

import { formatPercent } from '@/utils/formatting/percent';

interface GetAssumptionsPreviewCopyArgs {
  exemptionGrowthRate: Decimal;
  assetGrowthReturn: Decimal;
  willExemptionSunset?: boolean;
  useCustomGlobalGrowthRate?: boolean;
}

export function getAssumptionsPreviewCopy({
  exemptionGrowthRate,
  assetGrowthReturn,
  willExemptionSunset,
  useCustomGlobalGrowthRate,
}: GetAssumptionsPreviewCopyArgs) {
  const exemptionGrowth = formatPercent(exemptionGrowthRate, 2);
  const exemptionSunsetCopy = willExemptionSunset
    ? '(exemption sunsets in 2025)'
    : '(exemption does not sunset in 2025)';

  if (!useCustomGlobalGrowthRate) {
    return `Asset category growth rates applied, ${exemptionGrowth}% exemption growth ${exemptionSunsetCopy}`;
  }

  const assetGrowth = formatPercent(assetGrowthReturn, 2);
  return `Blended growth rate override applied (${assetGrowth}%), ${exemptionGrowth}% exemption growth ${exemptionSunsetCopy}`;
}
