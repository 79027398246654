import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { useDownloadProposalMutation } from './graphql/DownloadProposal.generated';

function getPathToPrint(householdId: string, proposalId: string) {
  const path = getCompletePathFromRouteKey(
    ROUTE_KEYS.__PRINT_ONLY_CLIENT_PROPOSAL,
    {
      householdId,
      proposalId,
    }
  );

  // the lambda actually expects this whole path EXCEPT for the leading slash.
  return path.slice(1, path.length);
}

export function useDownloadProposal(householdId: string, proposalId: string) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  return useDownloadProposalMutation({
    variables: {
      householdId,
      pathToPrint: getPathToPrint(householdId, proposalId),
    },
    onError: (err) => {
      reportError('Failed to print proposal', err);
      showFeedback(`We weren't able to generate the PDF. Please try again.`);
    },
  });
}
