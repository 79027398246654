import { InputAdornment, Stack, Typography } from '@mui/material';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareTypeaheadSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';
import { ModalFormLayout } from '@/components/form/layout/ModalFormLayout';
import { User01Icon } from '@/components/icons/User01Icon';
import { useFormContext } from '@/components/react-hook-form';

import { TaskReassignmentFormShape } from './TaskReassignmentModal.types';

interface TaskReassignmentFormProps {
  reassignmentOptions: SelectInputOption<string>[];
}

export function TaskReassignmentForm({
  reassignmentOptions,
}: TaskReassignmentFormProps) {
  const { control } = useFormContext<TaskReassignmentFormShape>();

  return (
    <ModalFormLayout>
      <Stack spacing={1}>
        <Typography variant="body1">
          You may reassign all tasks associated with this user to a new user,
          the client&apos;s relationship manager, or leave the tasks unassigned.
        </Typography>

        <FormAwareTypeaheadSelectInput<TaskReassignmentFormShape>
          control={control}
          fieldName="reassignedTo"
          label="Choose a user to reassign tasks to"
          hideLabel
          placeholder="Select a user to reassign tasks to"
          options={reassignmentOptions}
          required
          startAdornment={
            <InputAdornment position="start">
              <User01Icon size={20} />
            </InputAdornment>
          }
        />
      </Stack>
    </ModalFormLayout>
  );
}
