import { ApolloError } from '@apollo/client';
import { Box, Grid, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { ClientProfessionalTeamForm } from '@/modules/professionalTeam/ClientProfessionalTeam.types';

import {
  ClientProfessionalTeamContextProvider,
  ClientProfessionalTeamContextType,
} from './ClientProfessionalTeam.context';
import {
  mapDataToColumn,
  mapDataToPrincipalClientDisplay,
  mapDataToTeamMemberOptions,
  mapFormDataToAugmentedClientProfileUpdate,
} from './ClientProfessionalTeam.utils';
import { ClientProfessionalTeamAdvisorClient } from './ClientProfessionalTeamAdvisorClient';
import { ClientProfessionalTeamPrincipalClients } from './ClientProfessionalTeamPrincipalClients';
import {
  ClientProfessionalTeamQuery,
  useClientProfessionalTeamQuery,
} from './graphql/ClientProfessionalTeam.generated';
import { useUpdateClientProfilesMutation } from './graphql/UpdateClientProfiles.generated';

export interface ClientProfessionalTeamProps {
  householdId: string;
}

function ClientProfessionalTeamInner({
  householdId,
}: ClientProfessionalTeamProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const formMethods = useForm<ClientProfessionalTeamForm>({
    defaultValues: {
      advisorClientRoles: {},
      principalClientIdTeamMemberMap: {},
    },
  });
  const { reset, handleSubmit, formState } = formMethods;
  const resetForm = useCallback(
    (queryData: ClientProfessionalTeamQuery) => {
      reset(mapDataToColumn(queryData));
    },
    [reset]
  );
  const { data, loading } = useClientProfessionalTeamQuery({
    variables: { householdId },
    onError: (error: ApolloError) => {
      showFeedback(
        "Could not load the client's professional team.  Please refresh the page and try again."
      );
      reportError('Caught error when loading professional team', error);
    },
    onCompleted: (queryData) => {
      resetForm(queryData);
    },
  });

  const [updateClientProfiles, { loading: isSaving }] =
    useUpdateClientProfilesMutation({
      onError: (error: ApolloError) => {
        showFeedback(
          'Could not save client professional team changes. Please try again.'
        );
        reportError(
          'Encountered an error when saving client professional team',
          error
        );
      },
      onCompleted: () => {
        showFeedback('Client professional team updated', {
          variant: 'success',
        });
        reset({}, { keepValues: true });
      },
    });

  const onValidSubmit: SubmitHandler<ClientProfessionalTeamForm> = (
    formData: ClientProfessionalTeamForm
  ) => {
    const augmentedInputs = mapFormDataToAugmentedClientProfileUpdate(
      formData,
      data,
      householdId
    );
    return updateClientProfiles({
      variables: { inputs: augmentedInputs, householdId: householdId },
    });
  };

  const onSubmit = handleSubmit(onValidSubmit);

  const teamMemberOptions = useMemo<
    ClientProfessionalTeamContextType['teamMemberOptions']
  >(
    () => [
      {
        component: <SelectItemGroupLabel />,
        type: 'component' as const,
      },
      ...mapDataToTeamMemberOptions(data),
    ],
    [data]
  );

  const principalClientDisplayInfo = useMemo<Record<string, string>>(() => {
    return mapDataToPrincipalClientDisplay(data);
  }, [data]);

  return (
    <FormFieldsDisabledProvider isDisabled={loading || isSaving}>
      <ClientProfessionalTeamContextProvider
        teamMemberOptions={teamMemberOptions}
        principalClientDisplayInfo={principalClientDisplayInfo}
        householdId={householdId}
      >
        <FormProvider {...formMethods}>
          <form onSubmit={onSubmit} noValidate>
            <Grid
              container
              columns={12}
              columnSpacing={3}
              mb={`${FOOTER_HEIGHT}px`}
            >
              <Grid item md={12} lg={6}>
                <ClientProfessionalTeamAdvisorClient />
              </Grid>
              <Grid item md={12} lg={6}>
                <ClientProfessionalTeamPrincipalClients />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </ClientProfessionalTeamContextProvider>

      <Box position="fixed" sx={{ bottom: 0, left: 0, right: 0 }}>
        <Footer
          rightAction={
            <Stack direction="row" flexWrap="nowrap" spacing={2}>
              {formState.isDirty && (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    if (data) {
                      resetForm(data);
                    }
                    showFeedback('Client team changes cleared', {
                      variant: 'info-high',
                    });
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="primary"
                size="sm"
                onClick={onSubmit}
                disabled={!formState.isDirty}
                loading={formMethods.formState.isSubmitting}
              >
                Save changes
              </Button>
            </Stack>
          }
        />
      </Box>
    </FormFieldsDisabledProvider>
  );
}

export function ClientProfessionalTeam() {
  const householdId = useRequiredParam('householdId');
  return <ClientProfessionalTeamInner householdId={householdId} />;
}
