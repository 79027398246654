import { Controller, FieldValues, RegisterOptions } from 'react-hook-form';

import { FormAwareInputProps } from '@/components/form/formAwareInputs/FormAwareInput.types';
import { useFormContext } from '@/components/react-hook-form';
import {
  getFieldErrorValue,
  getValidations,
  minimumDateValidation,
  validDateValidation,
} from '@/components/utils/inputUtils';

import { DatePickerInput } from '../baseInputs/DatePickerInput/DatePickerInput';
import { HelpTextVariant } from '../baseInputs/inputTypes';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

export interface FormAwareDatePickerInputProps<FormShape extends FieldValues>
  extends FormAwareInputProps<FormShape> {
  hideLabel?: boolean;
  onFocus?: () => void;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  required?: boolean;
  disabled?: boolean;
  validation?: RegisterOptions<FormShape>['validate'];
  contextualHelp?: JSX.Element;
  minDate?: Date;
  validateOnChange?: boolean;
}

export function FormAwareDatePickerInput<FormShape extends FieldValues>({
  fieldName,
  label,
  hideLabel,
  helpText,
  helpTextVariant,
  required,
  control,
  disabled,
  onFocus,
  validation,
  contextualHelp,
  minDate,
  labelIconEnd,
  validateOnChange,
}: FormAwareDatePickerInputProps<FormShape>) {
  const { setValue } = useFormContext();

  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const validations = getValidations(label, !!required, {
    // this is intentionally first so it acts as the default, and any more
    // specific minDate validations that are passed in should supercede it.
    minDate: minimumDateValidation,
    validDate: validDateValidation,
    ...validation,
  });
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validations }}
      render={({ field, fieldState, formState }) => {
        return (
          <DatePickerInput
            label={label}
            hideLabel={hideLabel}
            inputRef={field.ref}
            required={required}
            name={field.name}
            onChange={(date, { shouldDirty }) => {
              // use onChange handler to set the value in the form context
              if (shouldDirty) {
                // if it's a regular onChange event, call the onChange handler
                field.onChange(date);
              } else {
                // if we don't want to dirty the form, call setValue directly
                setValue(field.name, date, { shouldDirty: false });
              }
            }}
            onBlur={field.onBlur}
            onFocus={onFocus}
            disabled={disabledFromContext ?? disabled}
            value={field.value as Date}
            helpText={helpText}
            helpTextVariant={helpTextVariant}
            minDate={minDate}
            errorMessage={getFieldErrorValue(
              fieldState,
              formState.isSubmitted,
              validateOnChange
            )}
            contextualHelp={contextualHelp}
            labelIconEnd={labelIconEnd}
          />
        );
      }}
    />
  );
}
