//EstateWaterfallHypotheticalTransfer

import Decimal from 'decimal.js';

import { getTransferDisplayName } from '@/modules/entities/details/HypotheticalTransfersCard/HypotheticalTransfersCard.utils';
import {
  EstateWaterfallHypotheticalTransferDestinationKind,
  EstateWaterfallHypotheticalTransferSourceKind,
  EstateWaterfallHypotheticalTransferTransferTaxKind,
} from '@/types/schema';

import { UnreachableError } from '../errors';
import { HypotheticalTransferUtils_EstateWaterfallHypotheticalTransferFragment } from './graphql/HypotheticalTransferUtils.generated';

export function getTransferSourceName({
  sourceEntity,
  sourceIndividual,
  sourceKind,
}: HypotheticalTransferUtils_EstateWaterfallHypotheticalTransferFragment): string {
  if (
    sourceKind === EstateWaterfallHypotheticalTransferSourceKind.Entity &&
    sourceEntity?.id
  ) {
    return sourceEntity.subtype.displayName;
  } else if (
    sourceKind === EstateWaterfallHypotheticalTransferSourceKind.Individual &&
    sourceIndividual?.id
  ) {
    return sourceIndividual.displayName;
  } else if (
    sourceKind === EstateWaterfallHypotheticalTransferSourceKind.External
  ) {
    return 'External source';
  }
  return '';
}

export function getTransferSourceKind({
  sourceKind,
  sourceEntity,
  sourceIndividual,
}: HypotheticalTransferUtils_EstateWaterfallHypotheticalTransferFragment): string {
  if (
    sourceKind === EstateWaterfallHypotheticalTransferSourceKind.Entity &&
    sourceEntity?.id
  ) {
    return sourceEntity.extendedDisplayKind;
  } else if (
    sourceKind === EstateWaterfallHypotheticalTransferSourceKind.Individual &&
    sourceIndividual?.id
  ) {
    return 'Individual';
  } else if (
    sourceKind === EstateWaterfallHypotheticalTransferSourceKind.External
  ) {
    return 'External';
  }

  return '';
}

export function getTransferDestinationName({
  destinationKind,
  destinationEntity,
  destinationIndividual,
  destinationOrganization,
}: HypotheticalTransferUtils_EstateWaterfallHypotheticalTransferFragment): string {
  if (
    destinationKind ===
      EstateWaterfallHypotheticalTransferDestinationKind.Entity &&
    destinationEntity?.id
  ) {
    return destinationEntity.subtype.displayName;
  } else if (
    destinationKind ===
      EstateWaterfallHypotheticalTransferDestinationKind.Individual &&
    destinationIndividual?.id
  ) {
    return destinationIndividual.displayName;
  } else if (
    destinationKind ===
      EstateWaterfallHypotheticalTransferDestinationKind.Organization &&
    destinationOrganization?.id
  ) {
    return destinationOrganization.name;
  } else if (
    destinationKind ===
    EstateWaterfallHypotheticalTransferDestinationKind.External
  ) {
    return 'External destination';
  }

  return '';
}

export function getTransferDestinationKind({
  destinationKind,
  destinationEntity,
  destinationIndividual,
  destinationOrganization,
}: HypotheticalTransferUtils_EstateWaterfallHypotheticalTransferFragment): string {
  if (
    destinationKind ===
      EstateWaterfallHypotheticalTransferDestinationKind.Entity &&
    destinationEntity?.id
  ) {
    return destinationEntity.extendedDisplayKind;
  } else if (
    destinationKind ===
      EstateWaterfallHypotheticalTransferDestinationKind.Individual &&
    destinationIndividual?.id
  ) {
    return 'Individual';
  } else if (
    destinationKind ===
      EstateWaterfallHypotheticalTransferDestinationKind.Organization &&
    destinationOrganization?.id
  ) {
    return 'Organization';
  } else if (
    destinationKind ===
    EstateWaterfallHypotheticalTransferDestinationKind.External
  ) {
    return 'External';
  }
  return '';
}

export function getTransferDateDisplay({
  startYear,
  endYear,
}: HypotheticalTransferUtils_EstateWaterfallHypotheticalTransferFragment): string {
  if (startYear && endYear && startYear !== endYear) {
    return `${startYear.toString()}-${endYear.toString()}`;
  } else if (startYear) {
    return startYear.toString();
  }
  return '';
}

export function getTransferTaxTypeDisplay({
  transferTaxKind,
}: HypotheticalTransferUtils_EstateWaterfallHypotheticalTransferFragment): string {
  switch (transferTaxKind) {
    case EstateWaterfallHypotheticalTransferTransferTaxKind.AnualExclusionGift:
      return 'Annual Exclusion Gift';
    case EstateWaterfallHypotheticalTransferTransferTaxKind.CharitableGift:
      return 'Charitable Gift';
    case EstateWaterfallHypotheticalTransferTransferTaxKind.GenericNonTaxableGift:
      return 'Non-taxable transfer';
    case EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift:
      return 'Taxable gift';
    case EstateWaterfallHypotheticalTransferTransferTaxKind.NonGiftTransfer:
      return 'Non-gift transfer';
    default:
      throw new UnreachableError({
        case: transferTaxKind,
        message: 'Invalid transfer type specified',
      });
  }
}

export interface GetTransferDetailsOutput {
  /** Checks the source type and returns the entity name, individual name, organization name, or "External source". */
  sourceName: string;
  /** Checks the source type and returns the extended entity display type, "Individual", or blank. */
  sourceKind: string;
  /** Checks the destination type and returns the entity name, individual name, organization name, or "External destination". */
  destinationName: string;
  /** Checks the destination type and returns the extended entity display type, "Individual", "Organization", or blank. */
  destinationKind: string;
  /** Returns either the recurring range of transfers or the single year of transfer. */
  date: string;
  /** Returns a user-friendly string that explains the transferTaxType */
  taxType: string;
  /** Transfer amount */
  amount: Decimal;
  /** Start year of the transfer.  If one-time transfer, the years will match. */
  startYear: number;
  name: string;
}

export function getTransferDetails(
  transfer: HypotheticalTransferUtils_EstateWaterfallHypotheticalTransferFragment
): GetTransferDetailsOutput {
  return {
    name: getTransferDisplayName(transfer),
    sourceName: getTransferSourceName(transfer),
    sourceKind: getTransferSourceKind(transfer),
    destinationName: getTransferDestinationName(transfer),
    destinationKind: getTransferDestinationKind(transfer),
    date: getTransferDateDisplay(transfer),
    taxType: getTransferTaxTypeDisplay(transfer),
    amount: transfer.transferValue || transfer.taxableValue || new Decimal(0),
    startYear: transfer.startYear || 0,
  };
}
