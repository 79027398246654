import { Box, Stack, Typography } from '@mui/material';

import { SubtleLabelItem } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { LoadingSkeletonOrValue } from '@/components/progress/LoadingSkeletonOrValue/LoadingSkeletonOrValue';
import { CONTROLLING_PERSONS_LABEL } from '@/modules/entities/EntitySubforms/utils/shared/controllingParties.utils';
import { EntityKind } from '@/types/schema';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { PersonalAccountSummaryData } from './PersonalAccountDetails.types';

/**
 *
 * @description This component is responsible for rendering the properties that are shared
 * between all the different types of personal accounts, and so takes specific property names rather
 * than a generalized set of labels and values.
 *
 */
export type PersonalAccountSummaryProps = Omit<
  PersonalAccountSummaryData,
  'accountDetailsItems'
>;

export function PersonalAccountSummary({
  loading = false,
  taxStatus,
  entityKind,
  extendedDisplayKind,
  accountType,
  ownerNames,
  effectiveDate,
  controllingPartyNames,
  description,
  locationDisplay,
  hideControllingPerson,
  hideTaxStatus,
}: PersonalAccountSummaryProps) {
  const shouldShowEffectiveDate =
    entityKind === EntityKind.IndividualPersonalAccount ||
    entityKind === EntityKind.JointPersonalAccount ||
    entityKind === EntityKind.CustodialPersonalAccount ||
    entityKind === EntityKind.QualifiedTuitionPersonalAccount;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Box>
        <Stack spacing={2}>
          <SubtleLabelItem
            label="Entity type"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={extendedDisplayKind}
              />
            }
            valueSecondary={accountType ?? undefined}
          />
          <SubtleLabelItem
            label="Owner(s)"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={ownerNames?.join(', ') ?? ''}
              />
            }
          />
          {shouldShowEffectiveDate && (
            <SubtleLabelItem
              label="Effective Date"
              value={
                <LoadingSkeletonOrValue
                  loading={loading}
                  value={
                    effectiveDate
                      ? formatDateToMonDDYYYY(effectiveDate)
                      : undefined
                  }
                />
              }
            />
          )}
          {!hideControllingPerson && (
            <SubtleLabelItem
              label={CONTROLLING_PERSONS_LABEL}
              value={
                <LoadingSkeletonOrValue
                  loading={loading}
                  value={controllingPartyNames?.join(', ') ?? ''}
                />
              }
            />
          )}
          {!hideTaxStatus && (
            <SubtleLabelItem
              label="Tax status"
              value={
                <LoadingSkeletonOrValue
                  loading={loading}
                  value={taxStatus?.value ?? ''}
                />
              }
            />
          )}
          <SubtleLabelItem
            label="Asset location"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={locationDisplay}
              />
            }
          />
        </Stack>
        {description && (
          <Box mt={3}>
            <Typography variant="body2">{description}</Typography>
          </Box>
        )}
      </Box>
    </Stack>
  );
}
