import React from 'react';

interface IContextualHelpContext {
  contextualHelpId: string | null;
  setContextualHelpId: (nextContextualHelpId: string | null) => void;
}

export const ContextualHelpContext =
  React.createContext<IContextualHelpContext | null>(null);

export const ContextualHelpProvider = ({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) => {
  const [contextualHelpId, setContextualHelpId] = React.useState<string | null>(
    null
  );

  return (
    <ContextualHelpContext.Provider
      value={{
        contextualHelpId,
        setContextualHelpId,
      }}
    >
      {children}
    </ContextualHelpContext.Provider>
  );
};
