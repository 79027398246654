import { Panel } from '@xyflow/react';
import { ComponentProps } from 'react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

const useStyles = makeStyles()((_theme) => ({
  root: {
    backgroundColor: COLORS.GRAY[100],
    display: 'inline-flex',
    borderTopLeftRadius: 6,
    margin: 0,
    flexDirection: 'column',
  },
}));

type PanelProps = ComponentProps<typeof Panel>;
export interface SumLineContainerProps extends Partial<PanelProps> {
  classes?: WithClasses<typeof useStyles>;
}

export function SumLineContainer({
  children,
  classes: externalClasses,
  position = 'bottom-right',
  ...panelProps
}: SumLineContainerProps) {
  const { classes } = useStyles(undefined, {
    props: { classes: externalClasses },
  });

  return (
    <Panel className={classes.root} position={position} {...panelProps}>
      {children}
    </Panel>
  );
}
