import Decimal from 'decimal.js';
import { isEmpty } from 'lodash';

import { serializers } from '@/modules/assetValuation/AssetFullScreenModal/serializers';
import {
  Fields as BasicAssetsSubformFields,
  NamespaceType as BasicAssetsNamespaceType,
} from '@/modules/entities/BasicAssetsSubform/BasicAssetsSubform.types';
import { makeCreateValuationInput } from '@/modules/entities/BasicAssetsSubform/BasicAssetsSubform.utils';
import { DEFAULT_ASSET_DISPLAY_NAME } from '@/modules/entities/StructuredAssetsSubform/StructuredAssetsSubform.constants';
import {
  AssetValueV2OwnershipType,
  AugmentedCreateAccountInput,
  AugmentedCreateAssetV2Input,
  AugmentedCreateAssetValuationV2Input,
  AugmentedUpdateAccountInput,
  AugmentedUpdateAssetValuationV2Input,
  UpdateAccountInput,
} from '@/types/schema';
import { ValidationError } from '@/utils/errors';

import {
  BASIC_ASSETS_INITIAL_FUNDING_SUBFORM,
  BASIC_ASSETS_SUBFORM as BASIC_ASSETS,
  BasicAssetsSubformProperties,
} from '../../../BasicAssetsSubform/BasicAssetsSubform.types';
import { SubformsCombinedType } from '../../EntitySubforms.types';
import { getDescriptionUpdate } from './common.utils';

function makeAugmentedCreateAssetInput(
  value: Decimal,
  assetClassId: string
): AugmentedCreateAssetV2Input {
  return {
    create: {
      displayName: DEFAULT_ASSET_DISPLAY_NAME,
      classID: assetClassId,
    },
    withAssetValue: {
      create: {
        ownedValue: value,
        ownershipType: AssetValueV2OwnershipType.ValueBased,
      },
    },
  };
}

/**
 * This function is responsible for taking the basic assets subform (and in some cases also the
 * "initial funding valuation" variant of the basic assets subform) and returning the account input
 * creation definition.
 *
 * @param formValues The complete set of form values from all the entity subforms.
 * @param opts
 * @returns AugmentedCreateAccountInput
 */
export function makeAccountInput(
  formValues: BasicAssetsSubformFields
): AugmentedCreateAccountInput {
  if (!formValues[BASIC_ASSETS]) {
    throw new Error('Could not find basic assets subform values');
  }
  // Use the currentMarketValue field as an indication of whether or not to create a valuation,
  // because we prefill dateOfValuation with the current date in some scenarios
  if (!formValues[BASIC_ASSETS]?.basicAssets?.currentMarketValue) {
    return {
      create: {
        displayName: 'Default account',
      },
    };
  }

  // the BASIC_ASSETS_INITIAL_FUNDING_SUBFORM isn't always present, but if it is then we want to map
  // the value from that subform to the initialValuation property of the account creation input
  // note that this scenario is only used for QPRTs as of june 2023
  const initialFundingValuationCreationInput: null | AugmentedCreateAssetValuationV2Input =
    (() => {
      const initialFundingValues =
        formValues[BASIC_ASSETS_INITIAL_FUNDING_SUBFORM]
          ?.basicAssetsInitialFunding;
      if (!initialFundingValues?.dateOfValuation) return null;
      return {
        create: makeCreateValuationInput(initialFundingValues),
      };
    })();

  const basicAssetsValues = formValues[BASIC_ASSETS].basicAssets;
  return {
    create: {
      displayName: 'Default account',
    },
    withInitialValuation: initialFundingValuationCreationInput,
    withValuations: [
      {
        withAssets: [
          makeAugmentedCreateAssetInput(
            basicAssetsValues.currentMarketValue ?? new Decimal(0),
            basicAssetsValues.assetCategoryId
          ),
        ],
        create: makeCreateValuationInput(formValues[BASIC_ASSETS].basicAssets),
      },
    ],
  };
}

/**
 * This function is responsible for taking the complete assets subform and returning the account input
 * update definition.
 *
 * @param accountInput The assets subform form values
 * @returns AugmentedCreateAccountInput
 */
export function makeUpdateAccountWithAssetsInput(
  accountInput: SubformsCombinedType['assetsSubform']
): AugmentedUpdateAccountInput {
  if (!accountInput.accountId) {
    throw new Error('Account ID is required for updates');
  }

  const valuation = serializers.editDesignSummary(
    accountInput
  ) as AugmentedUpdateAssetValuationV2Input;

  return {
    id: accountInput.accountId,
    update: {},
    updateValuations: [valuation],
  };
}

export function makeUpdateAccountInput(
  formValues: Partial<SubformsCombinedType>,
  basicAssetsNamespace: BasicAssetsNamespaceType = 'basicAssetsSubform'
): AugmentedUpdateAccountInput {
  const basicAssetsFormValues =
    basicAssetsNamespace === 'basicAssetsSubform'
      ? formValues[basicAssetsNamespace]?.basicAssets
      : formValues[basicAssetsNamespace]?.basicAssetsInitialFunding;

  if (!basicAssetsFormValues) {
    throw new Error('Basic assets subform is required for updates');
  }

  if (!basicAssetsFormValues.accountId) {
    throw new Error('Account ID is required for updates');
  }

  const currentValuationUpdateProperties = getCurrentValuationUpdateProperties(
    basicAssetsFormValues
  );

  const { clearInitialValuation, withInitialValuation } =
    getValuationUpdateProperties(formValues);

  return {
    id: basicAssetsFormValues.accountId,
    update: {
      ...clearInitialValuation,
    },
    ...withInitialValuation,
    ...currentValuationUpdateProperties,
  };
}

function getValuationUpdateProperties(
  formValues: Partial<SubformsCombinedType>
): {
  clearInitialValuation: UpdateAccountInput;
  withInitialValuation: AugmentedCreateAssetValuationV2Input | object;
} {
  // clearing initial valuations only exists for QPRTs as of june 2023
  let shouldClearInitialValuation = false;

  const recreateInitialFundingValuationInput: null | AugmentedCreateAssetValuationV2Input =
    (() => {
      const initialFundingAssetsFormValues =
        formValues[BASIC_ASSETS_INITIAL_FUNDING_SUBFORM]
          ?.basicAssetsInitialFunding;
      if (!initialFundingAssetsFormValues) return null;

      const hasValueInput = !!initialFundingAssetsFormValues.currentMarketValue;

      if (hasValueInput && !initialFundingAssetsFormValues.dateOfValuation) {
        throw new Error(
          'dateOfValuation is required for updates to the initial funding valuation'
        );
      }

      shouldClearInitialValuation = true;

      if (!hasValueInput) {
        return null;
      }

      return {
        create: {
          description: initialFundingAssetsFormValues.description,
          effectiveDate: initialFundingAssetsFormValues.dateOfValuation!,
          fixedValuationAmount:
            initialFundingAssetsFormValues.currentMarketValue ?? new Decimal(0),
        },
        withAssets: [
          makeAugmentedCreateAssetInput(
            initialFundingAssetsFormValues.currentMarketValue ?? new Decimal(0),
            initialFundingAssetsFormValues.assetCategoryId
          ),
        ],
      };
    })();

  const shouldRecreateInitialFundingValuation =
    !!recreateInitialFundingValuationInput;

  const withInitialValuation: AugmentedCreateAssetValuationV2Input | object =
    shouldRecreateInitialFundingValuation
      ? { withInitialValuation: recreateInitialFundingValuationInput }
      : {};

  const clearInitialValuation: UpdateAccountInput = shouldClearInitialValuation
    ? { clearInitialValuation: true }
    : {};

  return {
    clearInitialValuation,
    withInitialValuation,
  };
}

function getCurrentValuationUpdateProperties(
  formValues: BasicAssetsSubformProperties
): Partial<AugmentedUpdateAccountInput> {
  // if the user hasn't made any changes at all to the form, or their update is to change the asset integration linked entity, we shouldn't need to update or create any valuations.
  if (
    !isEmpty(formValues.integrationEntityIds) ||
    formValues.dirtyState === 'clean'
  ) {
    return {};
  }

  // if the user has only updated the description, we should update their existing valuation rather than creating a new one.
  if (formValues.dirtyState === 'descriptionOnlyDirty') {
    if (!formValues.valuationId) {
      throw new Error('Valuation ID is required for updates');
    }

    return {
      updateValuations: [
        {
          id: formValues.valuationId,
          update: {
            ...getDescriptionUpdate(formValues.description),
          },
        },
      ],
    };
  }

  if (!formValues.dateOfValuation && !formValues.currentMarketValue) {
    // if form is dirty and empty, don't send anything
    return {};
  } else if (!formValues.dateOfValuation || !formValues.currentMarketValue) {
    // if an entity has a stored current valuation but the form has been emptied, we should alert the user that a valuation is required.
    throw new ValidationError({
      message: 'A valuation is required when updating the current valuation.',
    });
  }

  // otherwise, the user has made updates to the valuation properties of the form and we should create a new valuation.
  return {
    withValuations: [
      {
        create: {
          description: formValues.description,
          effectiveDate: formValues.dateOfValuation,
          fixedValuationAmount: formValues.currentMarketValue,
          documentIDs: formValues.documentIds,
        },
        withAssets: [
          makeAugmentedCreateAssetInput(
            formValues.currentMarketValue,
            formValues.assetCategoryId
          ),
        ],
      },
    ],
  };
}
