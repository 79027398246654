import { partition } from 'lodash';
import { useMemo } from 'react';

import { NO_REVIEW_REQUIRED_ENTITY_KINDS } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.constants';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { useManageDispositionsContext } from '../context/ManageDispositions.context';
import { ManageDispositions_EntityFragment } from '../graphql/ManageDispositionsPage.generated';
import {
  ManageDispositionPageSections,
  ManageDispositions_AugmentedClientProfileRow,
} from '../ManageDispositionPage.types';
import { mapTestamentaryEntitiesToRows } from '../ManageDispositionsTestamentaryEntitiesTable/ManageDispositionsTestamentaryEntitiesTable.utils';

function clientIsReviewed(
  clientProfileRows: ManageDispositions_AugmentedClientProfileRow[]
) {
  const clientProfile = clientProfileRows[0];
  if (!clientProfile) return true;

  const expectedScenarioCount = clientProfileRows.length;
  if (clientProfile.dispositionScenarios?.length !== expectedScenarioCount) {
    return false;
  }

  return (
    clientProfile.dispositionScenarios?.every((scenario) =>
      Boolean(scenario.reviewedAt)
    ) ?? false
  );
}

function entityIsReviewed(
  entity: ManageDispositions_EntityFragment,
  opts = { isTwoClientHousehold: false }
) {
  if (NO_REVIEW_REQUIRED_ENTITY_KINDS.includes(entity.kind)) {
    return true;
  }

  const expectedScenarioCount = opts.isTwoClientHousehold ? 2 : 1;
  if (entity.subtype.dispositionScenarios?.length !== expectedScenarioCount) {
    return false;
  }

  return (
    entity.subtype.dispositionScenarios?.every((scenario) =>
      Boolean(scenario.reviewedAt)
    ) ?? false
  );
}

export function usePageSectionsActionNeededDetails() {
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const {
    inEstateEntities,
    primaryClientRows,
    outOfEstateEntities,
    testamentaryEntities,
  } = useManageDispositionsContext();

  const clientProfilesNeedAction: boolean = useMemo(() => {
    const firstPrimaryClient = primaryClientRows?.[0] ?? null;
    if (!firstPrimaryClient) return false;

    const [primaryClientOneRows, primaryClientTwoRows] = partition(
      primaryClientRows,
      (row) => row.id === firstPrimaryClient.id
    );

    return (
      !clientIsReviewed(primaryClientOneRows) ||
      !clientIsReviewed(primaryClientTwoRows)
    );
  }, [primaryClientRows]);

  const inEstateNeedActionCount = useMemo(() => {
    return inEstateEntities.filter((e) => {
      return !entityIsReviewed(e, { isTwoClientHousehold });
    }).length;
  }, [inEstateEntities, isTwoClientHousehold]);

  const outOfEstateNeedActionCount = useMemo(() => {
    return outOfEstateEntities.filter((e) => {
      return !entityIsReviewed(e, { isTwoClientHousehold });
    }).length;
  }, [isTwoClientHousehold, outOfEstateEntities]);

  const testamentariesNeedActionCount = useMemo(() => {
    const rows = mapTestamentaryEntitiesToRows(testamentaryEntities);
    return rows.filter((row) => !row.hasDispositionsSet).length;
  }, [testamentaryEntities]);

  return useMemo(() => {
    return {
      [ManageDispositionPageSections.IN_ESTATE_ENTITIES]:
        inEstateNeedActionCount > 0 || clientProfilesNeedAction,
      [ManageDispositionPageSections.OUT_OF_ESTATE_ENTITIES]:
        outOfEstateNeedActionCount > 0,
      [ManageDispositionPageSections.TESTAMENTARY_ENTITIES]:
        testamentariesNeedActionCount > 0,
    };
  }, [
    clientProfilesNeedAction,
    inEstateNeedActionCount,
    outOfEstateNeedActionCount,
    testamentariesNeedActionCount,
  ]);
}
