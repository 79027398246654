import { MenuItem, Stack, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';

interface AppNotificationListItemProps {
  icon: JSX.Element;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}

export function AppNotificationListItem({
  icon,
  title,
  subtitle,
  children,
}: PropsWithChildren<AppNotificationListItemProps>) {
  return (
    <MenuItem sx={{ p: 2, pb: 3 }} data-testid="app-notification-item">
      <Stack direction="column">
        <Stack direction="row" alignItems="center" gap={1} width={352}>
          {icon}
          <Stack>
            {typeof title === 'string' ? (
              <Typography variant="h6">{title}</Typography>
            ) : (
              title
            )}
            {typeof subtitle === 'string' ? (
              <Typography variant="subtitle2">{subtitle}</Typography>
            ) : (
              subtitle
            )}
          </Stack>
        </Stack>
        {children}
      </Stack>
    </MenuItem>
  );
}
