import { cx } from '@emotion/css';
import { Box, Stack, Typography } from '@mui/material';
import {
  GridEventListener,
  UncapitalizedGridProSlotsComponent,
} from '@mui/x-data-grid-pro';
import { includes, partition, uniqueId } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Button } from '@/components/form/baseInputs/Button';
import { CommonButtonProps } from '@/components/form/baseInputs/Button/types';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Draghandle } from '@/components/lists/DragAndDropList/Draghandle';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { CellContainer } from '@/components/tables/DataTable/components/cells';
import { DataTableCard } from '@/components/tables/DataTable/components/DataTableCard';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import {
  PaginatedTableProps,
  usePaginatedDataTableQuery,
} from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { useReportError } from '@/hooks/useReportError';
import { COLORS } from '@/styles/tokens/colors';
import { BalanceSheetCategoryType } from '@/types/schema';

import { useBalanceSheetCategoriesColumns } from './AdminBalanceSheetConfigurationPage.columns';
import {
  BalanceSheetCategoriesForm,
  NEW_BALANCE_SHEET_CATEGORY_SENTINEL,
  RowData,
} from './AdminBalanceSheetConfigurationPage.types';
import {
  getDefaultValues,
  getNextRowOrder,
  mapDataToRows,
  mapFormValuesToInputs,
} from './AdminBalanceSheetConfigurationPage.utils';
import {
  ReorderableRowsProvider,
  useReorderableRows,
} from './context/ReorderableRows.context';
import {
  useBalanceSheetCategoriesQuery,
  useUpdateBalanceSheetCategoriesMutation,
} from './graphql/AdminBalanceSheetConfigurationPage.generated';

const useStyles = makeStyles()((_theme) => ({
  virtualScroller: {
    minHeight: 170,
  },
  pinnedRows: {
    borderTop: `2px solid ${COLORS.GRAY[900]}`,
    boxShadow: `none`,
    position: 'relative',
    zIndex: 0,
  },
}));

const slots: Partial<UncapitalizedGridProSlotsComponent> = {
  rowReorderIcon: () => (
    <CellContainer>
      <Draghandle />
    </CellContainer>
  ),
  footer: Box,
};

interface AdminBalanceSheetConfigurationPageInnerProps {
  pinnedRows: RowData[];
  paginatedTableProps: PaginatedTableProps;
  pageToggle: React.ReactNode;
}

// This limit is imposed because it's the maximum number of categories that can be
// rendered onto a single slide in balance sheet slide for the presentation. We're imposing this
// rather than figuring out how to chunk up categories between slides because it's not clear
// if people want more than 10.
const MAX_CATEGORY_COUNT = 10;

function AdminBalanceSheetConfigurationPageInner({
  paginatedTableProps,
  pageToggle,
  pinnedRows,
}: AdminBalanceSheetConfigurationPageInnerProps) {
  const { reorderableRows, setReorderableRows } = useReorderableRows();
  const { setValue } = useFormContext<BalanceSheetCategoriesForm>();
  const columns = useBalanceSheetCategoriesColumns();
  const styles = useStyles();

  const addNewBalanceSheetCategory = useCallback(() => {
    const id = uniqueId(NEW_BALANCE_SHEET_CATEGORY_SENTINEL);

    setValue(`balanceSheetCategoriesById.${id}`, {
      id,
      name: '',
      assetClassIds: [],
      _isSystemClass: false,
    });

    setReorderableRows((prev) => [
      {
        __reorder__: 'New category',
        id,
        name: '',
        isSystemClass: false,
        assetClasses: null,
        action: null,
        type: BalanceSheetCategoryType.Asset,
      },
      ...prev,
    ]);

    setValue(`currentlyEditingRowId`, id);
  }, [setReorderableRows, setValue]);

  const handleRowOrderChange: GridEventListener<'rowOrderChange'> = useCallback(
    (params) => {
      const nextRowOrder = getNextRowOrder(reorderableRows, params);
      setReorderableRows(nextRowOrder);
    },
    [reorderableRows, setReorderableRows]
  );

  const isAtMaxCategoryCount = useMemo(() => {
    return reorderableRows.length >= MAX_CATEGORY_COUNT;
  }, [reorderableRows.length]);

  return (
    <DataTableCard>
      <Stack spacing={2}>
        <Stack
          spacing={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {pageToggle}
          <AddBalanceSheetCategoryButton
            disabled={isAtMaxCategoryCount}
            onClick={addNewBalanceSheetCategory}
            tooltip={
              isAtMaxCategoryCount
                ? `Maximum of ${MAX_CATEGORY_COUNT} balance sheet categories allowed`
                : undefined
            }
          />
        </Stack>

        <Typography variant="body1">
          Manage balance sheet categories and reorder as desired. Associating
          asset categories determines which assets will display under each
          balance sheet category.
        </Typography>

        <DataTable
          {...paginatedTableProps}
          classes={{
            virtualScroller: cx(styles.classes.virtualScroller),
            pinnedRows: cx(styles.classes.pinnedRows),
          }}
          disableColumnReorder
          disableColumnResize
          pagination={false}
          slots={slots}
          columns={columns}
          rows={reorderableRows}
          pinnedRows={{
            bottom: pinnedRows,
          }}
          onRowOrderChange={handleRowOrderChange}
          rowReordering
          rowHoverVariant="transparent"
        />
      </Stack>
    </DataTableCard>
  );
}

interface AdminBalanceSheetConfigurationPageProps {
  pageToggle: React.ReactNode;
}

export function AdminBalanceSheetConfigurationPage(
  props: AdminBalanceSheetConfigurationPageProps
) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const [reorderableRows, setReorderableRows] = useState<RowData[]>([]);

  const [paginatedTableProps, { data, refetch }] = usePaginatedDataTableQuery(
    useBalanceSheetCategoriesQuery,
    {
      pageSize: PageSizes.Fifty,
      variables: {
        where: {},
      },
      // without this, the query will use cached data if it exists even right after
      // the mutation
      fetchPolicy: 'network-only',
    }
  );

  const [updateBalanceSheetCategories] =
    useUpdateBalanceSheetCategoriesMutation({
      onCompleted: () => {
        showFeedback('Successfully updated balance sheet categories', {
          variant: 'success',
        });
        void refetch();
      },
      onError: (err) => {
        reportError('failed to update balance sheet categories', err);
        showFeedback(
          'Failed to update balance sheet categories. Please refresh the page and try again.'
        );
      },
    });

  const rows = useMemo(() => mapDataToRows(data ?? []), [data]);

  // initialize reorderable rows from the query into the local state
  const { reorderableRows: reorderableRowsFromQuery, pinnedRows } =
    useMemo(() => {
      const specialCategoryTypes = [
        BalanceSheetCategoryType.Liabilities,
        BalanceSheetCategoryType.Insurance,
      ];

      const [pinnedRows, reorderableRows] = partition(rows, (row) =>
        includes(specialCategoryTypes, row.type)
      );
      return { pinnedRows, reorderableRows };
    }, [rows]);

  useEffect(() => {
    if (reorderableRowsFromQuery.length > 0) {
      setReorderableRows(reorderableRowsFromQuery);
    }
  }, [reorderableRowsFromQuery]);

  const formMethods = useForm<BalanceSheetCategoriesForm>({
    defaultValues: {
      balanceSheetCategoriesById: {},
    },
  });

  const {
    formState: { isSubmitting },
  } = formMethods;

  const { reset } = formMethods;
  useEffect(() => {
    if (!data) return;
    const defaultValues = getDefaultValues(data ?? []);
    reset(defaultValues);
  }, [data, reset]);

  const handleSubmit = formMethods.handleSubmit((formValues) => {
    const { creates, updates, clears } = mapFormValuesToInputs(formValues, {
      reorderableRows,
    });
    return updateBalanceSheetCategories({
      variables: {
        createInputs: creates,
        updateInputs: updates,
        clearAssetClassInputs: clears,
      },
    });
  });

  return (
    <ReorderableRowsProvider
      reorderableRows={reorderableRows}
      setReorderableRows={setReorderableRows}
    >
      <Box>
        <FormProvider {...formMethods}>
          <Box mb={`${FOOTER_HEIGHT + 10}px`}>
            <AdminBalanceSheetConfigurationPageInner
              {...props}
              paginatedTableProps={paginatedTableProps}
              pinnedRows={pinnedRows}
            />
          </Box>
        </FormProvider>
        <Box position="fixed" sx={{ bottom: 0, left: 0, right: 0 }}>
          <Footer
            rightAction={
              <Button
                variant="primary"
                size="sm"
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                Save changes
              </Button>
            }
          />
        </Box>
      </Box>
    </ReorderableRowsProvider>
  );
}

function AddBalanceSheetCategoryButton(
  props: Partial<CommonButtonProps & { tooltip: string }>
) {
  const button = (
    <Button variant="secondary" size="sm" startIcon={PlusIcon} {...props}>
      Add balance sheet category
    </Button>
  );

  return props.tooltip ? (
    <Tooltip title={props.tooltip}>{button}</Tooltip>
  ) : (
    button
  );
}
