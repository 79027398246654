// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
export type MinimalClientOrganizationFragment = { __typename?: 'ClientOrganization', id: string, name: string, kind?: Types.ClientOrganizationKind | null };

export type ClientOrganizationFragment = { __typename?: 'ClientOrganization', isTrustee: boolean, isBeneficiary: boolean, pointOfContactName?: string | null, pointOfContactLastName?: string | null, pointOfContactPhone?: string | null, pointOfContactEmail?: string | null, id: string, name: string, kind?: Types.ClientOrganizationKind | null, address?: { __typename?: 'Address', id: string, cityName?: string | null, stateCode: string } | null };

export const MinimalClientOrganizationFragmentDoc = gql`
    fragment MinimalClientOrganization on ClientOrganization {
  id
  name
  kind
}
    `;
export const ClientOrganizationFragmentDoc = gql`
    fragment ClientOrganization on ClientOrganization {
  ...MinimalClientOrganization
  isTrustee
  isBeneficiary
  pointOfContactName
  pointOfContactLastName
  pointOfContactPhone
  pointOfContactEmail
  address {
    id
    cityName
    stateCode
  }
}
    ${MinimalClientOrganizationFragmentDoc}`;
export const Operations = {
  Fragment: {
    MinimalClientOrganization: 'MinimalClientOrganization' as const,
    ClientOrganization: 'ClientOrganization' as const
  }
}