// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteTestamentaryEntityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteTestamentaryEntityMutation = { __typename?: 'Mutation', deleteTestamentaryEntity: string };


export const DeleteTestamentaryEntityDocument = gql`
    mutation DeleteTestamentaryEntity($id: ID!) {
  deleteTestamentaryEntity(id: $id)
}
    `;
export type DeleteTestamentaryEntityMutationFn = Apollo.MutationFunction<DeleteTestamentaryEntityMutation, DeleteTestamentaryEntityMutationVariables>;

/**
 * __useDeleteTestamentaryEntityMutation__
 *
 * To run a mutation, you first call `useDeleteTestamentaryEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTestamentaryEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTestamentaryEntityMutation, { data, loading, error }] = useDeleteTestamentaryEntityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTestamentaryEntityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTestamentaryEntityMutation, DeleteTestamentaryEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTestamentaryEntityMutation, DeleteTestamentaryEntityMutationVariables>(DeleteTestamentaryEntityDocument, options);
      }
export type DeleteTestamentaryEntityMutationHookResult = ReturnType<typeof useDeleteTestamentaryEntityMutation>;
export type DeleteTestamentaryEntityMutationResult = Apollo.MutationResult<DeleteTestamentaryEntityMutation>;
export type DeleteTestamentaryEntityMutationOptions = Apollo.BaseMutationOptions<DeleteTestamentaryEntityMutation, DeleteTestamentaryEntityMutationVariables>;
export const Operations = {
  Mutation: {
    DeleteTestamentaryEntity: 'DeleteTestamentaryEntity' as const
  }
}