import { ApolloError } from '@apollo/client';
import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useDebouncedFn } from '@/hooks/useDebouncedFn';
import { useReportError } from '@/hooks/useReportError';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { GiftDesignerModelScenariosFormShape } from '@/modules/gifting/proposal/designer/form';
import { GiftProposalProjection } from '@/modules/gifting/proposal/giftProposal.types';
import { GiftingProposalClientProjectionParams } from '@/types/schema';

import { useGetGiftProjectedWealthLazyQuery } from '../graphql/GetGiftProjectedWealth.generated';

export function useGetGiftProjectedWealth({
  giftingProposalId,
}: {
  giftingProposalId: string;
}) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const isGiftProposalEnhancementsEnabled = isFeatureFlagEnabled(
    'gift_proposal_enhancements'
  );

  const [projections, setProjections] = useState<GiftProposalProjection[]>([]);

  const {
    control,
    formState: { isValid },
    trigger,
  } = useFormContext<GiftDesignerModelScenariosFormShape>();

  const [
    exemptionSunsets,
    exemptionGrowthRate,
    preTaxReturnCategory,
    yearOfAnalysisClientProposal,
    yearOfAnalysis,
  ] = useWatch({
    control,
    name: [
      'exemptionSunsets',
      'exemptionGrowthRate',
      'annualPreTaxReturn',
      'yearOfAnalysisClientProposal',
      'yearOfAnalysis',
    ],
  });

  const [projectionLazyQuery, queryProps] = useGetGiftProjectedWealthLazyQuery({
    onError: (error: ApolloError) => {
      showFeedback('Could not load projections for proposal');
      reportError('Could not load projections for proposal', error);
    },
  });

  const runQuery = useCallback(
    (inputParams: GiftingProposalClientProjectionParams | null) => {
      if (!inputParams) {
        return;
      }

      void projectionLazyQuery({
        fetchPolicy: 'network-only', // always fetch data, use the client cache so `isBaseline` is populated
        variables: {
          params: inputParams,
        },
      });
    },
    [projectionLazyQuery]
  );

  const debouncedQueryCall = useDebouncedFn(runQuery, 250, {
    leading: false,
    trailing: true,
  });

  const params: GiftingProposalClientProjectionParams | null = useMemo(() => {
    const year =
      parseInt(yearOfAnalysisClientProposal ?? '0') || yearOfAnalysis || 0;

    if (
      year < getYear(new Date()) ||
      !yearOfAnalysis ||
      !exemptionGrowthRate ||
      !isValid
    ) {
      // Manually trigger validation to ensure the error message is shown
      void trigger();
      return null;
    }

    return {
      exemptionSunsets: isGiftProposalEnhancementsEnabled
        ? undefined
        : exemptionSunsets,
      exemptionGrowthRate: new Decimal(exemptionGrowthRate),
      giftingProposalId,
      preTaxReturnCategory,
      year,
    };
  }, [
    yearOfAnalysisClientProposal,
    yearOfAnalysis,
    exemptionGrowthRate,
    isValid,
    isGiftProposalEnhancementsEnabled,
    exemptionSunsets,
    giftingProposalId,
    preTaxReturnCategory,
    trigger,
  ]);

  useEffect(() => {
    debouncedQueryCall(params);
  }, [debouncedQueryCall, params]);

  useEffect(() => {
    if (queryProps.data) {
      setProjections(queryProps.data.giftingProposalClientProjections);
    }
  }, [queryProps.data]);

  return {
    ...queryProps,
    projections,
  };
}
