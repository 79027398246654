import { identity } from 'lodash';

import { compose } from '@/utils/reducerUtils';

import {
  applyAutoLayoutToState,
  applySectionBoundsToState,
  hasFullyInitializedNodes,
} from '../state';
import {
  EstateWaterfallActionFn,
  InitializeNodePositionsAction,
} from '../types';

export const initializeNodePositionsAction: EstateWaterfallActionFn<
  InitializeNodePositionsAction
> = (state, _action, queueEffect) => {
  queueEffect({ type: 'fitViewEffect', kind: 'init' });
  queueEffect({ type: 'completeInitializationEffect' });

  // Only run auto layout when we don't already have a layout to begin with
  const autoLayoutFn = hasFullyInitializedNodes(state)
    ? identity
    : applyAutoLayoutToState;

  return compose(autoLayoutFn, applySectionBoundsToState)(state);
};
