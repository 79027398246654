import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { Operations as DocumentDetailsOperations } from '@/modules/documents/graphql/DocumentDetails.generated';
import { Operations as EntityDetailsOperations } from '@/modules/entities/details/graphql/EntityDetailPage.generated';

import { useSaveSummaryMutation } from '../graphql/SaveSummary.generated';

interface UseSaveSummaryInput {
  documentId: string;
}

export function useSaveSummary({ documentId }: UseSaveSummaryInput) {
  const { showFeedback, createSuccessFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [saveSummary] = useSaveSummaryMutation({
    refetchQueries: [
      EntityDetailsOperations.Query.EntityDetails,
      DocumentDetailsOperations.Query.DocumentDetails,
    ],
    onCompleted: createSuccessFeedback('Summary saved successfully'),
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError('Failed to save summary for document', error, {
        documentId,
      });
    },
  });

  return { saveSummary };
}
