import { applyNodeChanges } from '@xyflow/react';
import { useCallback } from 'react';

import { Node } from '@/components/diagrams/FlowChart';

import { FamilyTreeActionFn, NodesChangedAction } from '../types';

export function useNodesChangedAction() {
  return useCallback<FamilyTreeActionFn<NodesChangedAction>>(
    (state, { changes }, _queueEffect) => {
      const nextNodes = applyNodeChanges<Node>(changes, state.nodes);
      return { ...state, nodes: nextNodes };
    },
    []
  );
}
