import { Stack } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useModalState } from '@/hooks/useModalState';
import { CashflowModal } from '@/modules/cashflows/CashflowModal/CashflowModal';
import { CashflowsTable } from '@/modules/cashflows/CashflowTable/CashflowTable';
import { ClientProfileSummaryPanel_ClientProfileFragment } from '@/modules/summaryPanels/ClientProfileSummaryPanel/graphql/ClientProfileSummaryPanel.generated';
import { getNodes } from '@/utils/graphqlUtils';

import { EntityDetail_EntityFragment } from '../graphql/EntityDetailPage.generated';

export interface CashFlowsCardProps {
  entity?: EntityDetail_EntityFragment;
  individual?: ClientProfileSummaryPanel_ClientProfileFragment;
}

export function CashFlowsCard({ entity, individual }: CashFlowsCardProps) {
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();
  const cashflows = getNodes(entity?.cashflows);
  const entityId = entity?.id;
  const individualId = individual?.id;
  return (
    <Stack spacing={3}>
      <CashflowsTable
        cashflows={cashflows || []}
        entityId={entityId}
        individualId={individualId}
      />
      {!cashflows?.length ? null : (
        <>
          <Button
            variant="secondary"
            size="sm"
            fullWidth
            startIcon={PlusIcon}
            onClick={openModal}
          >
            Add cash flow
          </Button>
          <CashflowModal
            isOpen={isModalOpen}
            onClose={closeModal}
            recipient={entityId || individualId}
            cashflowId={null}
          />
        </>
      )}
    </Stack>
  );
}
