import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { useRouteKeyMatch } from '@/navigation/useRouteKeyMatch';

export const useNavigateToWaterfall = () => {
  const navigate = useNavigate();

  const waterfallDiagramRouteKey =
    ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM;
  const waterfallSummaryRouteKey =
    ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY;

  const isOnEstateWaterfall = useRouteKeyMatch(waterfallDiagramRouteKey);

  const isOnEstateWaterfallSummary = useRouteKeyMatch(waterfallSummaryRouteKey);

  return useCallback(
    ({
      householdId,
      waterfallId,
    }: {
      householdId: string;
      waterfallId?: string | null;
    }) => {
      const path = (() => {
        // If a waterfall does not exist...
        if (!waterfallId) {
          // ...with the new tabs navigation, go to the base waterfall tab to create a new entity/waterfall
          return getCompletePathFromRouteKey(
            ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB,
            {
              householdId,
            }
          );
        }

        // If on the waterfall, keep them on the waterfall
        if (isOnEstateWaterfall) {
          return getCompletePathFromRouteKey(waterfallDiagramRouteKey, {
            householdId,
            waterfallId,
          });
        }

        return getCompletePathFromRouteKey(waterfallSummaryRouteKey, {
          householdId,
          waterfallId,
        });
      })();

      navigate(path, {
        replace: isOnEstateWaterfall || isOnEstateWaterfallSummary,
      });
    },
    [
      isOnEstateWaterfall,
      isOnEstateWaterfallSummary,
      navigate,
      waterfallDiagramRouteKey,
      waterfallSummaryRouteKey,
    ]
  );
};
