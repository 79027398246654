import {
  AugmentedCreateTestamentaryEntityInput,
  AugmentedUpdateTestamentaryEntityInput,
  TestamentaryEntityKind,
} from '@/types/schema';

import {
  BENEFICIARIES_AFTER_BOTH_GRANTORS_DIE,
  BENEFICIARIES_SUBFORM,
} from '../../EntityBeneficiariesSubform/EntityBeneficiariesSubform.constants';
import { defaultBeneficiary } from '../../EntityBeneficiariesSubform/EntityBeneficiariesSubform.defaults';
import { EntityBeneficiariesFormBeneficiary } from '../../EntityBeneficiariesSubform/EntityBeneficiariesSubform.types';
import { makeCreateBeneficiaryInputs } from '../../EntitySubforms/utils/shared/beneficiaries.utils';
import { makeCreateControllingPartyInputs } from '../../EntitySubforms/utils/shared/controllingParties.utils';
import {
  entityGstStatusToApiTypeMap,
  estateInclusionStatusToApiTypeMap,
} from '../../EntitySubforms/utils/shared/taxStatus.utils';
import {
  makeCreateSuccessorTrusteeInputs,
  makeCreateTrustAdvisorInputs,
  makeCreateTrusteeInputs,
} from '../../EntitySubforms/utils/shared/trustees.utils';
import { OUT_OF_ESTATE } from '../../TaxStatusSubform/TaxStatusSubform.constants';
import {
  defaultValues_TrusteesAndSuccessorTrustees,
  TRUST_DETAILS_SUBFORM_NAMESPACE,
} from '../../TrustDetailsSubform/TrustDetailsSubform.types';
import {
  CONTROLLING_PARTY_SUBFORM,
  defaultControllingParty,
} from './ControllingPartyInput';
import { TESTAMENTARY_ENTITY } from './TestamentaryEntityForm.constants';
import {
  DefaultEstateTaxStatus,
  TestamentaryEntityFormShape,
} from './TestamentaryEntityForm.types';

export interface EntityPayloadInputOptions {
  isTestamentaryEntityNotClientBound: boolean;
  isTemplateMode: boolean;
  fallbackGrantorDeathId: string;
}

export function getCreateTestamentaryEntityInput(
  form: TestamentaryEntityFormShape
): AugmentedCreateTestamentaryEntityInput {
  const testamentaryEntityForm = form[TESTAMENTARY_ENTITY];
  const trustDetailsForm = form[TRUST_DETAILS_SUBFORM_NAMESPACE];
  const controllingPartiesSubform = form[CONTROLLING_PARTY_SUBFORM];
  const beneficiariesSubform = form[BENEFICIARIES_SUBFORM];
  const beneficiariesAfterBothGrantorDeathsSubform =
    form[BENEFICIARIES_AFTER_BOTH_GRANTORS_DIE];

  if (
    !testamentaryEntityForm.displayName ||
    !testamentaryEntityForm.testamentaryEntityKind
  ) {
    throw new Error('missing required field for testamentary entity');
  }

  const {
    displayName,
    testamentaryEntityKind,
    _primaryClientDeathId,
    estateInclusionStatus,
    gstStatus,
    entityDetailsNotes,
    householdId,
    survivingSpouseStateInEstateStatus,
  } = testamentaryEntityForm;

  if (!householdId && !_primaryClientDeathId) {
    throw new Error('a household ID or primary client death ID is required');
  }

  const beneficiaries: EntityBeneficiariesFormBeneficiary[] =
    beneficiariesSubform.beneficiaries ?? [];
  const beneficiariesAfterBothDie: EntityBeneficiariesFormBeneficiary[] =
    beneficiariesAfterBothGrantorDeathsSubform?.beneficiaries ?? [];
  const withBeneficiaries = makeCreateBeneficiaryInputs(
    beneficiaries,
    householdId
  );
  const withBeneficiariesAfterBothGrantorDeaths =
    beneficiariesAfterBothDie.length > 0
      ? {
          withBeneficiariesAfterBothGrantorDeaths: makeCreateBeneficiaryInputs(
            beneficiariesAfterBothDie,
            householdId
          ),
        }
      : {};

  const output: AugmentedCreateTestamentaryEntityInput = {
    create: {
      displayName,
      kind: testamentaryEntityKind,
      grantorDeathID: _primaryClientDeathId,
      inEstateStatus: estateInclusionStatus
        ? estateInclusionStatusToApiTypeMap[estateInclusionStatus]
        : undefined,
      gstStatus: gstStatus ? entityGstStatusToApiTypeMap[gstStatus] : undefined,
      description: entityDetailsNotes || undefined,
      householdID: householdId,
      survivingSpouseStateInEstateStatus: survivingSpouseStateInEstateStatus
        ? estateInclusionStatusToApiTypeMap[survivingSpouseStateInEstateStatus]
        : undefined,
    },
    withTrustees: makeCreateTrusteeInputs(trustDetailsForm),
    withSuccessorTrustees: makeCreateSuccessorTrusteeInputs(trustDetailsForm),
    withTrustAdvisors: makeCreateTrustAdvisorInputs(trustDetailsForm),
    withDispositiveProvisions: [],
    withControllingParties: controllingPartiesSubform
      ? makeCreateControllingPartyInputs(controllingPartiesSubform)
      : undefined,
    withBeneficiaries,
    ...withBeneficiariesAfterBothGrantorDeaths,
  };

  return output;
}

interface UpdateTestamentaryEntityInputArg {
  formValues: TestamentaryEntityFormShape;
  testamentaryEntityId: string;
}
export function getUpdateTestamentaryEntityInput({
  formValues,
  testamentaryEntityId,
}: UpdateTestamentaryEntityInputArg): AugmentedUpdateTestamentaryEntityInput {
  // Get the form values as if we were creating
  const createInput = getCreateTestamentaryEntityInput(formValues);
  const {
    gstStatus,
    inEstateStatus,
    survivingSpouseStateInEstateStatus,
    displayName,
    taxStatus,
    description,
    kind,
  } = createInput.create;

  const {
    withTrustees,
    withSuccessorTrustees,
    withTrustAdvisors,
    withControllingParties,
    withBeneficiaries,
    withBeneficiariesAfterBothGrantorDeaths,
  } = createInput;

  return {
    id: testamentaryEntityId,
    update: {
      clearTrustees: true,
      clearSuccessorTrustees: true,
      clearTrustAdvisors: true,
      clearControllingParties: true,
      clearDescription: !description,
      clearBeneficiaries: true,
      clearBeneficiariesAfterBothGrantorDeaths: true,
      clearGstStatus: !gstStatus,
      gstStatus,
      inEstateStatus,
      taxStatus,
      displayName,
      survivingSpouseStateInEstateStatus,
      description: description || undefined,
      kind,
    },
    withTrustees,
    withSuccessorTrustees,
    withTrustAdvisors,
    withControllingParties,
    withBeneficiaries,
    withBeneficiariesAfterBothGrantorDeaths,
  };
}

export interface GetDefaultValuesParameter extends DefaultEstateTaxStatus {
  dyingPrimaryClientId: string | null;
  areAllClientsDead: boolean;
  householdId: string | null;
  defaultName?: string;
  defaultKind?: TestamentaryEntityKind;
}

export function getDefaultValues({
  dyingPrimaryClientId,
  areAllClientsDead,
  householdId,
  estateInclusionStatus,
  survivingSpouseStateInEstateStatus,
  gstStatus,
  defaultName,
  defaultKind,
}: GetDefaultValuesParameter): TestamentaryEntityFormShape {
  const beneficiariesAfterBothDie = areAllClientsDead
    ? {}
    : {
        [BENEFICIARIES_AFTER_BOTH_GRANTORS_DIE]: {
          beneficiaries: [defaultBeneficiary()],
        },
      };

  return {
    [TESTAMENTARY_ENTITY]: {
      displayName: defaultName ?? '',
      testamentaryEntityKind: defaultKind ?? '',

      // Default this to out-of-estate, since when we don't give the user the
      // option to set this, the assumption is that the testamentary entity is
      // being created after all grantors have died
      estateInclusionStatus: estateInclusionStatus ?? OUT_OF_ESTATE,
      gstStatus: gstStatus ?? '',
      entityDetailsNotes: '',
      _primaryClientDeathId: dyingPrimaryClientId,
      householdId: householdId ?? '',
      survivingSpouseStateInEstateStatus:
        survivingSpouseStateInEstateStatus ?? OUT_OF_ESTATE,
    },
    [CONTROLLING_PARTY_SUBFORM]: {
      controllingParties: [defaultControllingParty],
    },
    [TRUST_DETAILS_SUBFORM_NAMESPACE]:
      defaultValues_TrusteesAndSuccessorTrustees,
    [BENEFICIARIES_SUBFORM]: {
      beneficiaries: [defaultBeneficiary()],
    },
    ...beneficiariesAfterBothDie,
  };
}
