import { Box } from '@mui/material';
import { chunk } from 'lodash';

import { EntityDetail_SlatTrustFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { getSLATTrustSummaryProperties } from '@/modules/entities/details/trusts/SLATTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { ClientPresentationBundleTypes } from '@/modules/presentation/clientPresentation/clientPresentation.types';
import { useUnguardedClientPresentationDesignerContext } from '@/modules/presentation/clientPresentation/contexts/clientPresentationDesigner.context';
import { useRegisterBundle } from '@/modules/presentation/clientPresentation/hooks/useRegisterBundle';

import { DispositionScenarioSlide } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide';
import { getFilteredDispositionSlideProps } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide.utils';
import { InsurancePoliciesSlide } from '../../components/InsurancePoliciesSlide';
import {
  EntityPresentationBundleProps,
  EntityPresentationSlideType,
  EntitySlideMap,
} from '../../entityPresentations.types';
import {
  generateEntityBundleId,
  getPresentationEntitySlideProps,
} from '../../entityPresentations.utils';
import { useOrderedEntitySlides } from '../../hooks/useFilteredEntitySlides';
import { SLATTrustBeneficiariesSlide } from './SLATTrust.Beneficiaries';
import { SLATTrustFiduciariesSlide } from './SLATTrust.Fiduciaries';
import { SLATTrustOverviewSlide } from './SLATTrust.Overview';

type RevocableTrustPresentationBundleProps =
  EntityPresentationBundleProps<EntityDetail_SlatTrustFragment>;

export function SLATTrustPresentationBundle({
  slideTypes,
  SlideWrapper = Box,
  dispositionScenarios,
  ...slideProps
}: RevocableTrustPresentationBundleProps) {
  const { entityId } = slideProps;
  const bundleId = generateEntityBundleId(entityId);
  useRegisterBundle({
    type: ClientPresentationBundleTypes.ENTITY,
    id: bundleId,
    displayName: slideProps.subtype.displayName,
    identifier: entityId,
  });
  const grantors =
    slideProps.entity.household.possiblePrimaryClients?.map((grantor) => ({
      displayName: grantor.displayName,
      id: grantor.id,
    })) ?? [];

  const { insurancePolicies } = getSLATTrustSummaryProperties({
    entity: slideProps.entity,
    entityType: ENTITY_TYPES.SLAT,
  });

  const policyPages = chunk(insurancePolicies, 2);

  const SLIDE_MAP: EntitySlideMap = {
    [EntityPresentationSlideType.OVERVIEW]: (
      <SLATTrustOverviewSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.OVERVIEW,
          slideTypes
        )}
        {...slideProps}
      />
    ),
    [EntityPresentationSlideType.TRUST_FIDUCIARIES]: (
      <SLATTrustFiduciariesSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.TRUST_FIDUCIARIES,
          slideTypes
        )}
        {...slideProps}
      />
    ),
    [EntityPresentationSlideType.BENEFICIARIES]: (
      <SLATTrustBeneficiariesSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.BENEFICIARIES,
          slideTypes
        )}
        {...slideProps}
      />
    ),
    [EntityPresentationSlideType.DISPOSITIVE_PROVISIONS]:
      getFilteredDispositionSlideProps({
        dispositionScenarios,
        entityId,
        slideTypes,
      }).map((props, i) => (
        <DispositionScenarioSlide
          {...props}
          key={i}
          displayName={slideProps.subtype.displayName}
          entity={slideProps.entity}
          grantors={grantors}
        />
      )),
    [EntityPresentationSlideType.INSURANCE_POLICIES]: policyPages.map(
      (pagePolicies, i) => (
        <InsurancePoliciesSlide
          key={i}
          entity={slideProps.entity}
          pagePolicies={pagePolicies}
          slideIndex={i}
          numberOfPolicySlides={policyPages.length}
          displayName={slideProps.subtype.displayName}
          {...getPresentationEntitySlideProps(
            entityId,
            EntityPresentationSlideType.INSURANCE_POLICIES,
            slideTypes,
            {
              localSlideTypeIndex: i,
              slideTypeId: pagePolicies?.[0]?.id ?? `slide-${i}`,
            }
          )}
        />
      )
    ),
  };

  const slides = useOrderedEntitySlides(slideTypes, SLIDE_MAP);
  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  if (!shouldShowItem(entityId)) {
    return null;
  }

  return (
    <>
      {slides.map((slide, index) => {
        return <SlideWrapper key={index}>{slide}</SlideWrapper>;
      })}
    </>
  );
}
