import { Box, Stack } from '@mui/material';
import React from 'react';

import { ArrowRightChunkyIcon } from '@/components/icons/ArrowRightChunkyIcon';

import { Card } from '../Card/Card';

export function ArrowCard({ children }: React.PropsWithChildren<unknown>) {
  return (
    <Card variant="filled">
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <ArrowRightChunkyIcon fill="white" size={56} />
        <Box flexGrow={1} p={3}>
          {children}
        </Box>
      </Stack>
    </Card>
  );
}
