/* eslint-disable react/display-name */
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';

import { ColorBox } from '@/components/display/ColorBox/ColorBox';

import { CellContainer } from '../../components/cells';
import { CellRenderProp } from '../../types';
import { getCellRenderProp } from '../../utils/getRenderProp';

interface LegendColorValueShape {
  backgroundColor: string;
}

interface LegendColorRendererProps<
  R extends GridValidRowModel,
  V,
  F,
  N extends GridTreeNodeWithRender,
> {
  backgroundColor?: CellRenderProp<
    R,
    V,
    F,
    N,
    LegendColorValueShape['backgroundColor']
  >;
}

export const LegendColorRenderer = <
  R extends GridValidRowModel = GridValidRowModel,
  V extends LegendColorValueShape = LegendColorValueShape,
  F = V,
  N extends GridTreeNodeWithRender = GridTreeNodeWithRender,
>(
  factoryProps?: LegendColorRendererProps<R, V, F, N>
) => {
  return (props: GridRenderCellParams<R, V, F, N>) => {
    const boxColor = getCellRenderProp({
      props,
      prop: factoryProps?.backgroundColor,
      defaultValue: {} as string,
    });

    return (
      <CellContainer align={props.colDef.align}>
        <ColorBox color={boxColor} />
      </CellContainer>
    );
  };
};
