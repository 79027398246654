import { CreateEstateWaterfallButton } from './CreateEstateWaterfallButton';
import { ManageDispositionsButton } from './ManageDispositionsButton';

export interface EstateWaterfallViewsButtonProps {
  householdId: string;
  waterfallId: string | null;
}

export function EstateWaterfallActionButtons({
  householdId,
  waterfallId,
}: EstateWaterfallViewsButtonProps) {
  return (
    <>
      <ManageDispositionsButton />
      <CreateEstateWaterfallButton
        householdId={householdId}
        waterfallId={waterfallId}
      />
    </>
  );
}
