import { useEffect, useState } from 'react';

import { useReportError } from '@/hooks/useReportError';
import { PENDING_ASYNC_JOB_STATUSES } from '@/modules/asyncJobs/graphql/asyncJobs.constants';
import {
  DocumentStatus_DocumentFragment,
  useDocumentStatusQuery,
} from '@/modules/documents/graphql/DocumentStatus.generated';
import { AsyncJobKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

interface UseDocumentDetailsQueryResult
  extends ReturnType<typeof useDocumentStatusQuery> {
  isDocumentBeingAsyncSummarized: boolean;
  document?: DocumentStatus_DocumentFragment;
}

export function useWatchDocumentStatus(
  documentId: string
): UseDocumentDetailsQueryResult {
  const { reportError } = useReportError();
  const [isDocumentBeingAsyncSummarized, setIsDocumentBeingAsyncSummarized] =
    useState(true); // Assume the document is being processed until we receive an update about the async jobs

  const queryProps = useDocumentStatusQuery({
    pollInterval: 5000,
    fetchPolicy: 'no-cache',
    variables: {
      where: {
        id: documentId,
      },
    },
    onError: (error) => {
      reportError(`failed to get document status`, error);
    },
  });

  const document = getNodes(queryProps.data?.documents)[0];

  useEffect(() => {
    if (!document) return;
    const jobs = getNodes(document.summaryAsyncJobs);
    const genSummaryJobs = jobs.filter(
      (job) => job.kind === AsyncJobKind.DocumentSummary
    );
    const activeJobs = genSummaryJobs.filter((job) =>
      PENDING_ASYNC_JOB_STATUSES.includes(job.status)
    );

    if (!activeJobs.length) {
      queryProps.stopPolling();
      setIsDocumentBeingAsyncSummarized(false);
    } else if (!isDocumentBeingAsyncSummarized) {
      setIsDocumentBeingAsyncSummarized(true);
    }
  }, [document, isDocumentBeingAsyncSummarized, queryProps]);

  return {
    ...queryProps,
    isDocumentBeingAsyncSummarized,
    document,
  };
}
