// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LifetimeExemptionCard_ClientProfileFragment = { __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD };

export type LifetimeExemptionCard_HouseholdFragment = { __typename?: 'Household', possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD }> };

export type LifetimeExemptionCardQueryVariables = Types.Exact<{
  householdWhere?: Types.InputMaybe<Types.HouseholdWhereInput>;
}>;


export type LifetimeExemptionCardQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD }> } | null } | null> | null } };

export const LifetimeExemptionCard_ClientProfileFragmentDoc = gql`
    fragment LifetimeExemptionCard_ClientProfile on ClientProfile {
  id
  gstExclusionUsed
  displayName
  lifetimeExclusionUsed
  currentAnnualExclusionUsed
  totalAnnualExclusionUsed
}
    `;
export const LifetimeExemptionCard_HouseholdFragmentDoc = gql`
    fragment LifetimeExemptionCard_Household on Household {
  possiblePrimaryClients {
    ...LifetimeExemptionCard_ClientProfile
  }
}
    ${LifetimeExemptionCard_ClientProfileFragmentDoc}`;
export const LifetimeExemptionCardDocument = gql`
    query LifetimeExemptionCard($householdWhere: HouseholdWhereInput) {
  households(where: $householdWhere) {
    edges {
      node {
        id
        ...LifetimeExemptionCard_Household
      }
    }
  }
}
    ${LifetimeExemptionCard_HouseholdFragmentDoc}`;

/**
 * __useLifetimeExemptionCardQuery__
 *
 * To run a query within a React component, call `useLifetimeExemptionCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useLifetimeExemptionCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLifetimeExemptionCardQuery({
 *   variables: {
 *      householdWhere: // value for 'householdWhere'
 *   },
 * });
 */
export function useLifetimeExemptionCardQuery(baseOptions?: Apollo.QueryHookOptions<LifetimeExemptionCardQuery, LifetimeExemptionCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LifetimeExemptionCardQuery, LifetimeExemptionCardQueryVariables>(LifetimeExemptionCardDocument, options);
      }
export function useLifetimeExemptionCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LifetimeExemptionCardQuery, LifetimeExemptionCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LifetimeExemptionCardQuery, LifetimeExemptionCardQueryVariables>(LifetimeExemptionCardDocument, options);
        }
export function useLifetimeExemptionCardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LifetimeExemptionCardQuery, LifetimeExemptionCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LifetimeExemptionCardQuery, LifetimeExemptionCardQueryVariables>(LifetimeExemptionCardDocument, options);
        }
export type LifetimeExemptionCardQueryHookResult = ReturnType<typeof useLifetimeExemptionCardQuery>;
export type LifetimeExemptionCardLazyQueryHookResult = ReturnType<typeof useLifetimeExemptionCardLazyQuery>;
export type LifetimeExemptionCardSuspenseQueryHookResult = ReturnType<typeof useLifetimeExemptionCardSuspenseQuery>;
export type LifetimeExemptionCardQueryResult = Apollo.QueryResult<LifetimeExemptionCardQuery, LifetimeExemptionCardQueryVariables>;
export const Operations = {
  Query: {
    LifetimeExemptionCard: 'LifetimeExemptionCard' as const
  },
  Fragment: {
    LifetimeExemptionCard_ClientProfile: 'LifetimeExemptionCard_ClientProfile' as const,
    LifetimeExemptionCard_Household: 'LifetimeExemptionCard_Household' as const
  }
}