import { PopperContent } from '@/components/poppers/PopperContent';

export function Lender() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body="Select the entity that holds the liability as a receivable. If the individual client is the lender, select a personal account entity."
    />
  );
}
