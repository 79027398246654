import { Box } from '@mui/material';
import { chunk } from 'lodash';

import { EntityDetail_PrivateFoundationFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { getNonTrustCharitableEntitySummaryProperties } from '@/modules/nonTrustCharitableEntities/NonTrustCharitableEntityDetails/NonTrustCharitableEntityDetails.utils';
import { ClientPresentationBundleTypes } from '@/modules/presentation/clientPresentation/clientPresentation.types';
import { useUnguardedClientPresentationDesignerContext } from '@/modules/presentation/clientPresentation/contexts/clientPresentationDesigner.context';
import { useRegisterBundle } from '@/modules/presentation/clientPresentation/hooks/useRegisterBundle';

import { InsurancePoliciesSlide } from '../../components/InsurancePoliciesSlide';
import {
  EntityPresentationBundleProps,
  EntityPresentationSlideType,
  EntitySlideMap,
} from '../../entityPresentations.types';
import {
  generateEntityBundleId,
  getPresentationEntitySlideProps,
} from '../../entityPresentations.utils';
import { useOrderedEntitySlides } from '../../hooks/useFilteredEntitySlides';
import { NonTrustCharitableEntityBeneficiariesSlide } from '../components/NonTrustCharitableEntity.Beneficiaries';
import { NonTrustCharitableEntityOverviewSlide } from '../components/NonTrustCharitableEntity.OverviewSlide';

type PrivateFoundationPresentationBundleProps =
  EntityPresentationBundleProps<EntityDetail_PrivateFoundationFragment>;

export function PrivateFoundationPresentationBundle({
  slideTypes,
  SlideWrapper = Box,
  entity,
  subtype,
  entityId,
}: PrivateFoundationPresentationBundleProps) {
  const displayName = subtype.displayName;
  const summary = getNonTrustCharitableEntitySummaryProperties({
    entity,
    entityType: ENTITY_TYPES.PRIVATE_FOUNDATION,
  });
  const bundleId = generateEntityBundleId(entityId);
  useRegisterBundle({
    type: ClientPresentationBundleTypes.ENTITY,
    id: bundleId,
    displayName: displayName,
    identifier: entityId,
  });

  const policyPages = chunk(summary.insurancePolicies, 2);

  const SLIDE_MAP: EntitySlideMap = {
    [EntityPresentationSlideType.OVERVIEW]: (
      <NonTrustCharitableEntityOverviewSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.OVERVIEW,
          slideTypes
        )}
        entity={entity}
        summary={summary}
        displayName={displayName}
      />
    ),
    [EntityPresentationSlideType.BENEFICIARIES]: (
      <NonTrustCharitableEntityBeneficiariesSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.BENEFICIARIES,
          slideTypes
        )}
        displayName={displayName}
        entity={entity}
        summary={summary}
      />
    ),
    [EntityPresentationSlideType.INSURANCE_POLICIES]: policyPages.map(
      (pagePolicies, i) => (
        <InsurancePoliciesSlide
          key={i}
          entity={entity}
          pagePolicies={pagePolicies}
          slideIndex={i}
          numberOfPolicySlides={policyPages.length}
          displayName={displayName}
          {...getPresentationEntitySlideProps(
            entityId,
            EntityPresentationSlideType.INSURANCE_POLICIES,
            slideTypes,
            {
              localSlideTypeIndex: i,
              slideTypeId: pagePolicies?.[0]?.id ?? `slide-${i}`,
            }
          )}
        />
      )
    ),
  };

  const slides = useOrderedEntitySlides(slideTypes, SLIDE_MAP);
  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  if (!shouldShowItem(entityId)) {
    return null;
  }

  return (
    <>
      {slides.map((slide, index) => {
        return <SlideWrapper key={index}>{slide}</SlideWrapper>;
      })}
    </>
  );
}
