import { ApolloError } from '@apollo/client';
import { Stack } from '@mui/material';
import { compact } from 'lodash';
import { useContext } from 'react';
import { FormProvider } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';

import { FullScreenTaskLayout } from '../FullScreenTaskLayout';
import { ILITTaskContext } from '../ILITTaskContext';
import { ILITTaskFooter } from '../ILITTaskFooter/ILITTaskFooter';
import { usePremiumPaymentMutationMutation } from './graphql/PremiumPaymentMutation.generated';
import { ILITPremiumPaymentTaskForm } from './ILITPremiumPaymentTask.types';
import {
  ILITPremiumPaymentTaskBody,
  ILITPremiumPaymentTaskSidebar,
} from './ILITPremiumPaymentTask.UI';
import {
  generateCompleteTaskPayload,
  useFormData,
} from './ILITPremiumPaymentTask.utils';

function ILITSendCrummeyLetterTaskInner() {
  const { taskId, entityId, onCompleteTask } = useContext(ILITTaskContext);
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const formMethods = useForm<ILITPremiumPaymentTaskForm>({
    defaultValues: {
      documentIds: [],
      policyId: '',
      ilitTrustId: '',
      premiumPaymentId: '',
      premiumPaymentDate: null,
      premiumAmount: null,
      premiumDueDate: null,
      premiumFrequency: null,
      carrierName: '',
      policyNumber: '',
      policyKind: null,
    },
  });

  const { reset, handleSubmit, formState } = formMethods;

  const { loading: isLoading } = useFormData(taskId, reset);
  const [markPremiumPaymentComplete, { loading: isSaving }] =
    usePremiumPaymentMutationMutation({
      onError: (error: ApolloError) => {
        showFeedback(
          'Could not mark the premium as paid.  Please refresh and try again.',
          { variant: 'error' }
        );
        reportError('Encountered error when marking premium as paid', error);
      },
      onCompleted: () => {
        showFeedback('Premium marked as paid.', { variant: 'success' });
        onCompleteTask();
      },
    });

  const handleCompleteTask = handleSubmit(
    (formData: ILITPremiumPaymentTaskForm) => {
      const variables = generateCompleteTaskPayload(
        formData,
        taskId,
        entityId,
        compact(formState.defaultValues?.documentIds)
      );
      void markPremiumPaymentComplete({ variables });
    }
  );

  const loading = isLoading;
  const isSubmitting = isSaving;

  return (
    <FormProvider {...formMethods}>
      <Stack height="100dvh">
        <FullScreenTaskLayout
          LeftPaneContent={<ILITPremiumPaymentTaskSidebar />}
          RightPaneContent={<ILITPremiumPaymentTaskBody />}
          Footer={
            <ILITTaskFooter
              onComplete={handleCompleteTask}
              disabled={loading}
              submitting={isSubmitting}
            />
          }
        />
      </Stack>
    </FormProvider>
  );
}

export interface ILITPremiumPaymentTaskProps {
  householdId: string;
  entityId: string;
  taskId: string;
  onClose: () => void;
  onCompleteTask: () => void;
}

export function ILITPremiumPaymentTask({
  householdId,
  entityId,
  taskId,
  onClose,
  onCompleteTask,
}: ILITPremiumPaymentTaskProps) {
  return (
    <ILITTaskContext.Provider
      value={{ householdId, entityId, taskId, onClose, onCompleteTask }}
    >
      <ILITSendCrummeyLetterTaskInner />
    </ILITTaskContext.Provider>
  );
}
