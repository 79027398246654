import { Stack } from '@mui/material';

import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { Slot } from '@/components/utils/slots';
import { EstateWaterfallSectionMultiSelectTable } from '@/modules/estateWaterfall/components/EstateWaterfallSectionMulitSelectTable/EstateWaterfallSectionMultiSelectTable';

interface GraphVizGroupBasicDetailsFormShape {
  displayName?: string | null;
  description?: string | null;
  applyToAllSections?: boolean;
}

interface GraphVizGroupBasicDetailsFormProps {
  variant: 'create' | 'update';
  slots: {
    SelectTable: Slot<typeof EstateWaterfallSectionMultiSelectTable>;
  };
}

export function GraphVizGroupBasicDetailsForm<
  FormShape extends GraphVizGroupBasicDetailsFormShape,
>({ slots, variant }: GraphVizGroupBasicDetailsFormProps) {
  const { control } = useFormContext<FormShape>();

  return (
    <Stack spacing={3}>
      <Card variant="filled" sx={{ p: 2, pb: 4 }}>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareTextInput<FormShape>
              control={control}
              required
              label="Group name"
              fieldName={'displayName' as const satisfies keyof FormShape}
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareTextInput<FormShape>
              control={control}
              label="Short description"
              fieldName={'description' as const satisfies keyof FormShape}
            />
          </FormLayoutItem>
        </FormLayoutRow>
        {variant === 'create' && (
          <FormLayoutRow>
            <FormLayoutItem>
              <FormAwareCheckbox<FormShape>
                control={control}
                label="Apply group to other sections"
                helpText="If the same entities exist across other sections of this waterfall, the same group will be created."
                fieldName={
                  'applyToAllSections' as const satisfies keyof FormShape
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}
      </Card>
      <slots.SelectTable.component {...slots.SelectTable.props} />
    </Stack>
  );
}
