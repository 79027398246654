import { Stack } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { PageHeader } from '@/components/navigation/PageHeader';

export function ClientTasksPage() {
  return (
    <Stack>
      <PageHeader heading="Tasks" />
      <Stack p={3} pb={4} flex={1}>
        <Outlet />
      </Stack>
    </Stack>
  );
}
