import { Stack } from '@mui/material';
import Decimal from 'decimal.js';

import { Certificate01Icon } from '@/components/icons/Certificate01Icon';
import { EmptyListActionCard } from '@/components/lists/EmptyListActionCard';
import { SpecifyDispositiveProvisionsButton } from '@/modules/dispositiveProvisions/SpecifyDispositiveProvisionsButton';

import { ActiveDispositionTab } from './hooks/useDispositiveProvisionsListViewTab';

type NoDispositionsProps = {
  currentNodeValue?: Decimal;
  firstDeathPrimaryClientId?: string | null;
} & ActiveDispositionTab;

export function NoDispositions({
  currentNodeValue,
  ...rest
}: NoDispositionsProps) {
  return (
    <Stack px={3}>
      <EmptyListActionCard
        heading="Specify dispositive provisions"
        description="No dispositive provisions have been specified for this entity"
        icon={Certificate01Icon}
        action={
          <SpecifyDispositiveProvisionsButton
            currentNodeValue={currentNodeValue}
            {...rest}
          />
        }
      />
    </Stack>
  );
}
