// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResyncEntityValuationMutationVariables = Types.Exact<{
  entityId: Types.Scalars['ID']['input'];
}>;


export type ResyncEntityValuationMutation = { __typename?: 'Mutation', syncIntegratedEntity: string };


export const ResyncEntityValuationDocument = gql`
    mutation ResyncEntityValuation($entityId: ID!) {
  syncIntegratedEntity(id: $entityId)
}
    `;
export type ResyncEntityValuationMutationFn = Apollo.MutationFunction<ResyncEntityValuationMutation, ResyncEntityValuationMutationVariables>;

/**
 * __useResyncEntityValuationMutation__
 *
 * To run a mutation, you first call `useResyncEntityValuationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResyncEntityValuationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resyncEntityValuationMutation, { data, loading, error }] = useResyncEntityValuationMutation({
 *   variables: {
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useResyncEntityValuationMutation(baseOptions?: Apollo.MutationHookOptions<ResyncEntityValuationMutation, ResyncEntityValuationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResyncEntityValuationMutation, ResyncEntityValuationMutationVariables>(ResyncEntityValuationDocument, options);
      }
export type ResyncEntityValuationMutationHookResult = ReturnType<typeof useResyncEntityValuationMutation>;
export type ResyncEntityValuationMutationResult = Apollo.MutationResult<ResyncEntityValuationMutation>;
export type ResyncEntityValuationMutationOptions = Apollo.BaseMutationOptions<ResyncEntityValuationMutation, ResyncEntityValuationMutationVariables>;
export const Operations = {
  Mutation: {
    ResyncEntityValuation: 'ResyncEntityValuation' as const
  }
}