import { SxProps, Theme } from '@mui/material';

import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';

interface HypotheticalEstateWaterfallBadgeProps {
  sx?: SxProps<Theme>;
  display?: string;
}

export function HypotheticalEstateWaterfallBadge({
  sx,
  display = 'Hypothetical',
}: HypotheticalEstateWaterfallBadgeProps) {
  return (
    <Badge
      data-testid="HypotheticalEstateWaterfallBadge"
      variant={BadgeVariants.Yellow}
      display={display}
      sx={sx}
    />
  );
}
