import { Divider, Stack, Typography } from '@mui/material';
import { noop } from 'lodash';
import { useCallback } from 'react';
import { useWatch } from 'react-hook-form';

import { CompoundHeader } from '@/components/display/CompoundHeader/CompoundHeader';
import { LabelItem } from '@/components/display/LabelItem/LabelItem';
import { Button } from '@/components/form/baseInputs/Button';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { LinkBroken01Icon } from '@/components/icons/LinkBroken01Icon';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';
import { useModalState } from '@/hooks/useModalState';
import { DispositiveProvisionTemplateKind } from '@/types/schema';
import { formatCurrencyNoDecimalsAccounting } from '@/utils/formatting/currency';

import { useDispositiveProvisionsContext } from '../../contexts/dispositiveProvisions.context';
import { DispositionScheme } from '../../dispositiveProvisions.types';
import { DispositiveProvisionsTemplateSplitScreenModal } from '../../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal';
import { SimulateDispositiveProvisionsQuery } from '../../graphql/SimulateDispositiveProvisions.generated';
import { DISPOSITIVE_PROVISIONS_FORM_NAMESPACE } from '../DispositiveProvisionsForm.constants';
import {
  DispositiveProvisionsFormPaths,
  DispositiveProvisionsFormShape,
  Recipient,
} from '../DispositiveProvisionsForm.types';
import {
  DispositiveProvisionTemplateRecipientDisplayData,
  useDispositiveProvisionTemplateDisplayData,
} from './hooks/useDispositiveProvisionTemplateDisplayData';
import { RecipientNotesBadge } from './RecipientNotesBadge';

function DispositiveProvisionRecipientFromTemplate({
  displayName,
  kind,
  amount,
  notes,
}: DispositiveProvisionTemplateRecipientDisplayData) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ px: 2 }}
    >
      <LabelItem
        label={displayName}
        value={<Typography variant="subtitle2">{kind}</Typography>}
      />
      <Stack
        direction="column"
        sx={{ height: '100%', justifyContent: 'center', alignItems: 'end' }}
        spacing={0.5}
      >
        <Typography>
          {formatCurrencyNoDecimalsAccounting(amount)} distribution
        </Typography>
        {notes && <RecipientNotesBadge notes={notes} />}
      </Stack>
    </Stack>
  );
}

export interface DispositiveProvisionsReceipientsFromTemplateProps {
  simulationResults: SimulateDispositiveProvisionsQuery | undefined;
  templateId: string;
  onClose: () => void;
}
export function DispositiveProvisionsRecipientsFromTemplate({
  simulationResults,
  templateId,
  onClose: onCloseExternal,
}: DispositiveProvisionsReceipientsFromTemplateProps) {
  const { control, setValue } =
    useFormContext<DispositiveProvisionsFormShape>();
  const { isFromTemplateEditor } = useDispositiveProvisionsContext();

  const [dispositionScheme] = useWatch({
    control,
    name: [
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.dispositionScheme` satisfies DispositiveProvisionsFormPaths,
    ],
  });
  const [
    { isModalOpen: isEditTemplateModalOpen },
    { openModal: openEditTemplateModal, closeModal: closeEditTemplateModal },
  ] = useModalState();

  const relevantSimulationResults =
    dispositionScheme === DispositionScheme.UPON_FIRST_DEATH
      ? simulationResults?.simulateDispositiveProvisions.firstDeath
      : simulationResults?.simulateDispositiveProvisions.secondDeath;
  const { displayName, recipients, template } =
    useDispositiveProvisionTemplateDisplayData(
      templateId,
      relevantSimulationResults
    );

  const onEditTemplate = useCallback(() => {
    openEditTemplateModal();
  }, [openEditTemplateModal]);

  const onCloseEditTemplateModal = useCallback(
    (templateId?: string, recipients?: Recipient[], isDeleted?: boolean) => {
      if (isDeleted) {
        setValue(
          `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.templateId` satisfies DispositiveProvisionsFormPaths,
          null
        );
        setValue(
          `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients` satisfies DispositiveProvisionsFormPaths,
          []
        );
        closeEditTemplateModal();
        onCloseExternal();
      }

      if (!templateId || !recipients) {
        closeEditTemplateModal();
        return;
      }

      setValue(
        `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.templateId` satisfies DispositiveProvisionsFormPaths,
        templateId
      );
      setValue(
        `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients` satisfies DispositiveProvisionsFormPaths,
        recipients ?? []
      );

      closeEditTemplateModal();
    },
    [closeEditTemplateModal, onCloseExternal, setValue]
  );

  const onUnlink = useCallback(() => {
    setValue(
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.templateId` satisfies DispositiveProvisionsFormPaths,
      null
    );
    // if the template is a pourover will, also clear the recipients
    // https://linear.app/luminary/issue/T1-2153/unlinking-entity-from-pour-over-template#comment-c1740de3
    if (template?.kind === DispositiveProvisionTemplateKind.PouroverWill) {
      setValue(
        `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients` satisfies DispositiveProvisionsFormPaths,
        []
      );
    }
  }, [setValue, template?.kind]);

  return (
    <>
      <Stack spacing={3}>
        <Card variant="filled-callout" sx={{ p: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <CompoundHeader
              heading="Linked to template"
              subheading={displayName}
            />
            <Stack direction="row" spacing={2}>
              {!isFromTemplateEditor && (
                <Button
                  variant="transparent"
                  startIcon={Edit02Icon}
                  size="sm"
                  onClick={onEditTemplate}
                >
                  Edit template
                </Button>
              )}
              <Button
                variant="primary"
                startIcon={LinkBroken01Icon}
                size="sm"
                onClick={onUnlink}
              >
                Unlink
              </Button>
            </Stack>
          </Stack>
        </Card>
        <Stack spacing={3} divider={<Divider />}>
          {recipients.map((recipient) => {
            return (
              <DispositiveProvisionRecipientFromTemplate
                key={recipient.id}
                {...recipient}
              />
            );
          })}
        </Stack>
      </Stack>
      <DispositiveProvisionsTemplateSplitScreenModal
        templateDetails={{
          templateId,
        }}
        isOpen={isEditTemplateModalOpen}
        onClose={onCloseEditTemplateModal}
        isFromEditingDispositions
        setDispositiveProvisionsModalDetails={noop}
      />
    </>
  );
}
