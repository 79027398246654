import { FetchResult } from '@apollo/client';

import { CreateFileMutation } from '../files/FileUploader/graphql/CreateFile.generated';

export interface NormalizedUploadedFileData {
  fileId: string;
  uploadedByName: string;
  uploadedAt: Date;
  fileName: string;
  downloadUrl: string;
}

export function getNewFileUploadFromUploadResult(
  file: File | null,
  result: FetchResult<CreateFileMutation>['data'] | null
): NormalizedUploadedFileData | null {
  if (!file || !result) {
    return null;
  }

  return {
    fileId: result.uploadFile?.file?.id,
    uploadedByName: result.uploadFile.file?.user?.displayName,
    uploadedAt: result.uploadFile.file?.createdAt,
    fileName: file.name,
    downloadUrl: result.uploadFile.file.download.downloadURL,
  };
}
