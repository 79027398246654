import { TermsSubformType } from '@/modules/entities/gratTrusts/TermsSubform/types';
import {
  GRAT_TERM_VALUES,
  GRATTerm,
} from '@/modules/entities/gratTrusts/TermsSubform/ui/utils';

export function getInitialTermLengthProperties(
  termDurationYears: number | null
): Pick<TermsSubformType, 'termLength' | 'termLengthExtended'> {
  if (termDurationYears === null) {
    return {
      termLength: '' as GRATTerm,
      termLengthExtended: '',
    };
  }

  // the important thing to remember here is that termLength doesn't correspond to a normal input; it corresponds to
  // a ButtonGroup with a predefined set of values, and if the "10+" value is selected, termLengthExtended is populated.
  if (termDurationYears < 10) {
    return {
      termLength: termDurationYears.toString() as GRATTerm,
      termLengthExtended: '',
    };
  }

  return {
    termLength: GRAT_TERM_VALUES.TEN_OR_MORE,
    termLengthExtended: termDurationYears.toString(),
  };
}
