import { compact, difference, forEach, isEmpty, keyBy } from 'lodash';

import { AugmentedUpdateEntityInput } from '@/types/schema';

import {
  ImportCsvValuationPage_IntegrationEntityFragment,
  UpdateEntitiesAndSyncValuationsMutationVariables,
} from './graphql/ImportCSVValuationsPage.generated';
import { CSVUploadFormShape } from './ImportCSVValuationsPage.types';
import { DEFAULT_VALUATION_ATTRIBUTION_NAME } from './ImportCSVVvaluationsPage.constants';

/**
 * @description Given a list of integration entities of kind CSV_IMPORT, generate the
 * initial form state for the accountIdsByHousehold field, which powers the table.
 */
export function getInitialAccountIdsByHousehold(
  integrationEntities: ImportCsvValuationPage_IntegrationEntityFragment[]
): CSVUploadFormShape['accountIdsByHousehold'] {
  const res: CSVUploadFormShape['accountIdsByHousehold'] = {};
  const entitiesToIntegrationEntities =
    getEntitiesToIntegrationEntities(integrationEntities);

  forEach(entitiesToIntegrationEntities, (integrationEntitiesForEntity) => {
    // the integrationID on the integration entity is the account ID
    const accountIdsForEntity = integrationEntitiesForEntity.map(
      (ie) => ie.integrationID
    );

    forEach(integrationEntitiesForEntity, (integrationEntity) => {
      forEach(integrationEntity.entities, (entity) => {
        // we want to group by household, and if this isn't the first entity we're adding to this household key,
        // we don't want to overwrite it
        const existingValue = res[entity.household.id];
        if (existingValue) {
          existingValue.entityIdToAccountIds[entity.id] =
            accountIdsForEntity.join(', ');
          return;
        }

        // first entity for this household, so create the object
        res[entity.household.id] = {
          entityIdToAccountIds: {
            [entity.id]: accountIdsForEntity.join(', '),
          },
        };
      });
    });
  });

  return res;
}

export interface GetUpdatesFromFormDataParams {
  integrationConfigId: string | null;
  integrationEntities: ImportCsvValuationPage_IntegrationEntityFragment[];
}

export function getUpdatesFromFormData(
  formData: CSVUploadFormShape,
  params: GetUpdatesFromFormDataParams
): UpdateEntitiesAndSyncValuationsMutationVariables {
  if (!formData.fileId) {
    throw new Error('Invalid CSV upload state: no file ID');
  }

  if (!params.integrationConfigId) {
    throw new Error('Invalid CSV upload state: no integration config ID');
  }

  const entityUpdates = getEntityUpdates(
    formData.accountIdsByHousehold,
    params.integrationEntities
  );
  return {
    fileId: formData.fileId,
    updateIntegrationConfiguration: {
      id: params.integrationConfigId,
      update: {
        csvValuationImportName:
          formData.attributionName || DEFAULT_VALUATION_ATTRIBUTION_NAME,
      },
    },
    updateEntities: entityUpdates,
  };
}

/**
 * @description takes a potentially messy string of comma-separated account IDs and returns a cleaned array
 * e.g. 'account1, account2,account3,' => ['account1', 'account2', 'account3']
 */
export function getCleanedAccountIdsFromString(
  commaSeparatedAccountIds: string
) {
  return compact(commaSeparatedAccountIds.split(',').map((id) => id.trim()));
}

export function getEntityUpdates(
  mapping: CSVUploadFormShape['accountIdsByHousehold'],
  integrationEntities: ImportCsvValuationPage_IntegrationEntityFragment[]
): AugmentedUpdateEntityInput[] {
  const entityUpdates: AugmentedUpdateEntityInput[] = [];
  const entitiesToIntegrationEntities =
    getEntitiesToIntegrationEntities(integrationEntities);
  const accountIdsToIntegrationEntity = keyBy(
    integrationEntities,
    (ie) => ie.integrationID
  );

  forEach(mapping, ({ entityIdToAccountIds }) => {
    forEach(entityIdToAccountIds, (accountIdString, entityId) => {
      const previousIntegrationEntityIds =
        entitiesToIntegrationEntities[entityId]
          ?.map((ie) => {
            return ie.id;
          })
          .sort() ?? [];

      const accountIds = getCleanedAccountIdsFromString(accountIdString ?? '');
      const nextIntegrationEntityIds = compact(
        accountIds.map((accountId) => {
          return accountIdsToIntegrationEntity[accountId]?.id ?? null;
        })
      ).sort();

      // we don't need to perform updates for entities that are unaffected by this, but we do want to make sure
      // that if users are removing all account IDs from an entity, that we remove the integration entity IDs
      const idsToRemove = difference(
        previousIntegrationEntityIds,
        nextIntegrationEntityIds
      );

      const idsToAdd = difference(
        nextIntegrationEntityIds,
        previousIntegrationEntityIds
      );

      const noDifference = isEmpty(idsToAdd) && isEmpty(idsToRemove);

      if (
        (isEmpty(accountIds) && isEmpty(previousIntegrationEntityIds)) ||
        noDifference
      ) {
        return;
      }

      entityUpdates.push({
        id: entityId,
        update: {
          removeIntegrationEntityIDs: idsToRemove,
          addIntegrationEntityIDs: idsToAdd,
        },
      });
    });
  });

  return entityUpdates;
}

type EntitiesToIntegrationEntities = Record<
  string,
  ImportCsvValuationPage_IntegrationEntityFragment[]
>;

function getEntitiesToIntegrationEntities(
  integrationEntities: ImportCsvValuationPage_IntegrationEntityFragment[]
) {
  const res: EntitiesToIntegrationEntities = {};

  forEach(integrationEntities, (ie) => {
    forEach(ie.entities, (e) => {
      const existingValue = res[e.id];
      if (existingValue) {
        existingValue.push(ie);
        return;
      }
      res[e.id] = [ie];
    });
  });

  return res;
}
