import { isUndefined } from 'lodash';

// splitting it up this way to enforce that either min or max are required
interface RangeBase {
  fieldName?: string;
  unit?: string;
}

interface MinType extends RangeBase {
  min: number;
  max?: number;
}

interface MaxType extends RangeBase {
  min?: number;
  max: number;
}

export type ValidateNumberRangeType = MinType | MaxType;

const symbolCheckRegex = /^[a-zA-Z]/;

export function validateNumberRange({
  min,
  max,
  fieldName = 'Value',
  unit,
}: ValidateNumberRangeType) {
  return (value: unknown): string | undefined => {
    if (!value) {
      return `${fieldName} is required`;
    }
    const analysisLength = Number(value);
    if (isNaN(analysisLength)) {
      return `${fieldName} must be a number`;
    }

    let postfix = '';
    if (unit) {
      if (unit.match(symbolCheckRegex)) {
        postfix = ` ${unit}`;
      } else {
        postfix = unit;
      }
    }

    if (!isUndefined(min) && analysisLength < min) {
      return `${fieldName} must be at least ${min}${postfix}`;
    }

    if (!isUndefined(max) && analysisLength > max) {
      return `${fieldName} must be no more than ${max}${postfix}`;
    }
    return undefined;
  };
}
