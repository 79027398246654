import { Box, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { memo } from 'react';

import { BenefitPill, ZeroLine } from '@/components/charts/chartAccessories';
import {
  CHART_SEMANTIC_COLOR_VALUE,
  ChartSemanticColorValue,
  useChartColorDefinitions,
} from '@/components/charts/constants';
import { BarChartBox } from '@/components/charts/StackedHorizontalBar/bar';
import { maxDecimalJS } from '@/utils/decimalJSUtils';
import { formatCurrency } from '@/utils/formatting/currency';

function LabelBox({
  height,
  heightPx,
  label,
  textAlign,
  color,
}: {
  height?: number;
  heightPx?: number;
  label: string;
  textAlign: string;
  color: string;
}) {
  const theme = useTheme();
  const hasHeight = (height ?? 0) + (heightPx ?? 0) > 0;

  return (
    <Box
      sx={{
        minHeight: hasHeight ? theme.spacing(2) : 0,
        height: heightPx ? `${heightPx}px` : `${height}%`,
        width: '100%',
        justifyContent: textAlign,
        px: 1,
        zIndex: 1,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography color={color} variant="subtitle2">
        {label}
      </Typography>
    </Box>
  );
}

function TotalRow({ columnWidth }: { columnWidth: number }) {
  return (
    <Stack display="grid" py={1} gridTemplateColumns="25% 25% 25% 25%">
      <div />
      <Stack width="100%" alignItems="start" spacing="1px">
        <Box
          sx={{
            width: '90%',
            maxWidth: columnWidth,
            textAlign: 'center',
          }}
        >
          <Typography color="black" variant="label">
            No plan
          </Typography>
        </Box>
      </Stack>

      <Stack width="100%" alignItems="end" spacing="1px">
        <Box
          sx={{
            width: '90%',
            maxWidth: columnWidth,
            textAlign: 'center',
          }}
        >
          <Typography color="black" variant="label">
            With plan
          </Typography>
        </Box>
      </Stack>
      <div />
    </Stack>
  );
}

function TaxColumns({
  noPlanHeightPx,
  withPlanHeightPx,
  noPlanLabel,
  withPlanLabel,
  colors,
  columnWidth,
}: {
  noPlanHeightPx: number;
  withPlanHeightPx: number;
  noPlanLabel: string;
  withPlanLabel: string;
  colors: [string, string];
  columnWidth: number;
}) {
  const theme = useTheme();
  const graphColorDefinitions = useChartColorDefinitions();
  const maxHeight = Math.max(noPlanHeightPx, withPlanHeightPx);

  let noPlanHeightPercent = 0;
  let withPlanHeightPercent = 0;

  if (maxHeight > 0) {
    if (maxHeight === noPlanHeightPx) {
      noPlanHeightPercent = 100;
      withPlanHeightPercent = (withPlanHeightPx / maxHeight) * 100;
    } else {
      withPlanHeightPercent = 100;
      noPlanHeightPercent = (noPlanHeightPx / maxHeight) * 100;
    }
  }

  return (
    <Stack
      display="grid"
      gridTemplateColumns="25% 25% 25% 25%"
      height={maxHeight}
    >
      <Stack width="100%" alignItems="start" spacing="1px">
        <LabelBox
          heightPx={maxHeight}
          label={noPlanLabel}
          textAlign="end"
          color={
            graphColorDefinitions[CHART_SEMANTIC_COLOR_VALUE.NEGATIVE].text
          }
        />
      </Stack>

      <Stack
        data-testid="tax-left-bar"
        width="100%"
        alignItems="start"
        spacing="1px"
      >
        <BarChartBox
          colors={colors}
          heightPercent={noPlanHeightPercent}
          borderColor={
            noPlanHeightPercent > 0
              ? theme.palette.dataVisualizationNegative.main
              : undefined
          }
          columnWidthPx={columnWidth}
        />
      </Stack>
      <Stack width="100%" alignItems="end" spacing="1px">
        <BarChartBox
          colors={colors}
          heightPercent={withPlanHeightPercent}
          borderColor={
            withPlanHeightPercent > 0
              ? theme.palette.dataVisualizationNegative.main
              : undefined
          }
          columnWidthPx={columnWidth}
        />
      </Stack>
      <Stack width="100%" alignItems="start" spacing="1px">
        <LabelBox
          height={withPlanHeightPercent}
          label={withPlanLabel}
          textAlign="start"
          color={
            graphColorDefinitions[CHART_SEMANTIC_COLOR_VALUE.NEGATIVE].text
          }
        />
      </Stack>
    </Stack>
  );
}

interface BarDefinition {
  value: Decimal;
  color: ChartSemanticColorValue;
}

function getBarDefinitions(
  noPlan: [
    outOfEstateAssets: Decimal,
    inEstateAssets: Decimal,
    estateTax: Decimal,
  ],
  withPlan: [
    outOfEstateAssets: Decimal,
    inEstateAssets: Decimal,
    estateTax: Decimal,
  ]
) {
  return {
    noPlan: [
      {
        value: noPlan[0],
        color: CHART_SEMANTIC_COLOR_VALUE.SECONDARY,
      },
      {
        value: noPlan[1],
        color: CHART_SEMANTIC_COLOR_VALUE.PRIMARY,
      },
      {
        value: noPlan[2],
        color: CHART_SEMANTIC_COLOR_VALUE.NEGATIVE,
      },
    ],
    withPlan: [
      {
        value: withPlan[0],
        color: CHART_SEMANTIC_COLOR_VALUE.SECONDARY,
      },
      {
        value: withPlan[1],
        color: CHART_SEMANTIC_COLOR_VALUE.PRIMARY,
      },
      {
        value: withPlan[2],
        color: CHART_SEMANTIC_COLOR_VALUE.NEGATIVE,
      },
    ],
  } satisfies Record<string, [BarDefinition, BarDefinition, BarDefinition]>;
}

interface ComparisonChartProps {
  noPlan: [
    outOfEstateAssets: Decimal,
    inEstateAssets: Decimal,
    estateTax: Decimal,
  ];
  withPlan: [
    outOfEstateAssets: Decimal,
    inEstateAssets: Decimal,
    estateTax: Decimal,
  ];
  columnHeight: number;
  columnWidth: number;
  widthPx?: number;
  maxInGroup?: Decimal;
  scenario: 'pre-tax' | 'post-tax';
  maxProjectionValue?: Decimal;
}

export function FunctionComparisonChart({
  noPlan,
  withPlan,
  columnHeight,
  columnWidth,
  widthPx,
  maxInGroup,
  scenario,
  maxProjectionValue,
}: ComparisonChartProps) {
  const graphColorDefinitions = useChartColorDefinitions();
  const barDefinitions = getBarDefinitions(noPlan, withPlan);
  const noPlanOutOfEstateAssets = barDefinitions.noPlan[0].value;
  const noPlanInEstateAssets = barDefinitions.noPlan[1].value;
  const noPlanEstateTax = barDefinitions.noPlan[2].value;
  const withPlanOutOfEstateAssets = barDefinitions.withPlan[0].value;
  const withPlanInEstateAssets = barDefinitions.withPlan[1].value;
  const withPlanEstateTax = barDefinitions.withPlan[2].value;

  const noPlanLessTax = noPlanOutOfEstateAssets.plus(noPlanInEstateAssets);
  const withPlanLessTax = withPlanOutOfEstateAssets.plus(
    withPlanInEstateAssets
  );

  const maxPositiveSum =
    maxInGroup ||
    maxDecimalJS([noPlanLessTax, withPlanLessTax], new Decimal(0));

  const scaleFactor = maxProjectionValue
    ? maxPositiveSum.div(maxProjectionValue)
    : new Decimal(1);

  const benefit = withPlanLessTax.minus(noPlanLessTax);
  const providesBenefit = benefit.greaterThan(0);

  return (
    <Box width={widthPx ? `${widthPx}px` : '100%'}>
      <TotalRow columnWidth={columnWidth} />
      <Box mt={1} mb={2}>
        {scenario === 'pre-tax' ? (
          <BenefitPill
            benefit={benefit}
            variant="transferredToBeneficiaries"
            outOfEstate={withPlanOutOfEstateAssets}
          />
        ) : providesBenefit && scenario === 'post-tax' ? (
          <BenefitPill benefit={benefit} />
        ) : (
          <BenefitPill benefit={new Decimal(0)} />
        )}
      </Box>
      <Stack>
        <Stack
          display="grid"
          gridTemplateColumns="25% 25% 25% 25%"
          alignItems="end"
        >
          <Stack
            width="100%"
            height={columnHeight}
            alignItems="start"
            spacing="1px"
            justifyContent="end"
          >
            {barDefinitions.noPlan.map(({ value, color }, i) => {
              if (i === 2) {
                return null;
              }

              return (
                <LabelBox
                  key={`label-${i}`}
                  label={
                    value.lessThanOrEqualTo(new Decimal(0))
                      ? ''
                      : formatCurrency(value, {
                          notation: 'compact',
                        })
                  }
                  heightPx={value
                    .dividedBy(maxPositiveSum)
                    .times(columnHeight)
                    .times(scaleFactor)
                    .toNumber()}
                  textAlign="end"
                  color={graphColorDefinitions[color].text}
                />
              );
            })}
          </Stack>
          <Stack
            width="100%"
            height={columnHeight}
            alignItems="start"
            spacing="1px"
            justifyContent="end"
          >
            {barDefinitions.noPlan.map(({ value, color }, i) => {
              if (i === 2) {
                return null;
              }

              return (
                <BarChartBox
                  key={i}
                  colors={graphColorDefinitions[color].radialGradient}
                  heightPx={value
                    .dividedBy(maxPositiveSum)
                    .times(columnHeight)
                    .times(scaleFactor)
                    .toNumber()}
                  columnWidthPx={columnWidth}
                />
              );
            })}
          </Stack>

          <Stack
            width="100%"
            height={columnHeight}
            alignItems="end"
            spacing="1px"
            justifyContent="end"
          >
            {barDefinitions.withPlan.map(({ value, color }, i) => {
              if (i === 2) {
                return null;
              }
              return (
                <BarChartBox
                  key={i}
                  colors={graphColorDefinitions[color].radialGradient}
                  heightPx={value
                    .dividedBy(maxPositiveSum)
                    .times(columnHeight)
                    .times(scaleFactor)
                    .toNumber()}
                  columnWidthPx={columnWidth}
                />
              );
            })}
          </Stack>
          <Stack
            width="100%"
            height={columnHeight}
            alignItems="start"
            spacing="1px"
            justifyContent="end"
          >
            {barDefinitions.withPlan.map(({ value, color }, i) => {
              if (i === 2) {
                return null;
              }

              return (
                <LabelBox
                  key={`label-${i}`}
                  label={
                    value.lessThanOrEqualTo(new Decimal(0))
                      ? ''
                      : formatCurrency(value, {
                          notation: 'compact',
                        })
                  }
                  heightPx={value
                    .dividedBy(maxPositiveSum)
                    .times(columnHeight)
                    .times(scaleFactor)
                    .toNumber()}
                  textAlign="start"
                  color={graphColorDefinitions[color].text}
                />
              );
            })}
          </Stack>
        </Stack>
        <ZeroLine />
        <TaxColumns
          colors={
            graphColorDefinitions[barDefinitions.noPlan[2].color].radialGradient
          }
          noPlanHeightPx={noPlanEstateTax
            .abs()
            .dividedBy(maxPositiveSum)
            .times(columnHeight)
            .times(scaleFactor)
            .toNumber()}
          withPlanHeightPx={withPlanEstateTax
            .abs()
            .dividedBy(maxPositiveSum)
            .times(columnHeight)
            .times(scaleFactor)
            .toNumber()}
          noPlanLabel={
            noPlanEstateTax.isZero()
              ? ''
              : formatCurrency(noPlanEstateTax, {
                  currencySign: 'accounting',
                  notation: 'compact',
                })
          }
          withPlanLabel={
            withPlanEstateTax.isZero()
              ? ''
              : formatCurrency(withPlanEstateTax, {
                  currencySign: 'accounting',
                  notation: 'compact',
                })
          }
          columnWidth={columnWidth}
        />
        <Box mt={1} mb={2}>
          {scenario === 'post-tax' && (
            <BenefitPill
              variant="reduction"
              reduction={withPlanEstateTax.minus(noPlanEstateTax)}
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
}

export const ComparisonChart = memo(FunctionComparisonChart);
