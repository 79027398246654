import {
  applyNodeChanges,
  Node as ReactFlowNode,
  NodeChange,
} from '@xyflow/react';
import { useCallback, useState } from 'react';

import { Node } from '../../types';
import { HelperLinesProps } from './HelperLines';
import { getHelperLines } from './HelperLines.utils';

/**
 * @description Computes helper lines for HelperLines.tsx component
 *
 * A lot of this is adapted from: https://reactflow.dev/docs/examples/interaction/helper-lines/,
 * but with our own modifications as well
 */
export function useHelperLines() {
  const [helperLineHorizontal, setHelperLineHorizontal] =
    useState<HelperLinesProps['horizontal']>(undefined);
  const [helperLineVertical, setHelperLineVertical] =
    useState<HelperLinesProps['vertical']>(undefined);

  const getHelperLinesChanges = useCallback(
    (changes: NodeChange[], nodes: Node[]): NodeChange[] => {
      // reset the helper lines (clear existing lines, if any)
      setHelperLineHorizontal(undefined);
      setHelperLineVertical(undefined);

      // this will be true if it's a single node being dragged
      // inside we calculate the helper lines and snap position for the position where the node is being moved to
      if (
        changes[0] &&
        changes[0].type === 'position' &&
        changes[0].dragging &&
        changes[0].position
      ) {
        const helperLines = getHelperLines(changes[0], nodes);

        // if we have a helper line, we snap the node to the helper line position
        // this is being done by manipulating the node position inside the change object
        changes[0].position.x =
          helperLines.snapPosition.x ?? changes[0].position.x;
        changes[0].position.y =
          helperLines.snapPosition.y ?? changes[0].position.y;

        // if helper lines are returned, we set them so that they can be displayed
        setHelperLineHorizontal(helperLines.horizontal);
        setHelperLineVertical(helperLines.vertical);
      }

      return changes;
    },
    []
  );

  const applyHelperLinesToNodes = useCallback(
    (changes: NodeChange[], nodes: Node[]): Node[] => {
      return applyNodeChanges(
        getHelperLinesChanges(changes, nodes),
        nodes as ReactFlowNode[]
      ) as Node[];
    },
    [getHelperLinesChanges]
  );

  return {
    helperLineHorizontal,
    helperLineVertical,
    getHelperLinesChanges,
    applyHelperLinesToNodes,
  };
}
