import { useRequiredParam } from '@/hooks/useRequiredParam';
import { GratPerformancePage } from '@/modules/entities/details/grat/GRATPerformancePage/GratPerformancePage';

export function EntityPerformancePage() {
  const entityId = useRequiredParam('entityId');
  const householdId = useRequiredParam('householdId');
  // TODO: currently only supporting GRATs; when we move to supporting performance pages for other trust types,
  // we should lift up the querying logic to this level
  return <GratPerformancePage entityId={entityId} householdId={householdId} />;
}
