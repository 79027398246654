import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useGetSubstitutionThresholdFormValues } from '@/modules/entities/gratTrusts/SubstitutionThresholdSubform/hooks/useGetSubstitutionThresholdFormValues';
import { useQuerySubstitutionThresholdDetails } from '@/modules/entities/gratTrusts/SubstitutionThresholdSubform/hooks/useQuerySubstitutionThresholdDetails';
import { SubstitutionThresholdSubform } from '@/modules/entities/gratTrusts/SubstitutionThresholdSubform/SubstitutionThresholdSubform';
import {
  Fields as SubsitutionThresholdSubformFields,
  NAMESPACE as SUBSTITUTION_THRESHOLD_SUBFORM_NAMESPACE,
} from '@/modules/entities/gratTrusts/SubstitutionThresholdSubform/types';
import { generateThresholdDetailsUpdates } from '@/modules/entities/gratTrusts/SubstitutionThresholdSubform/utils/mutationUtils';
import { COLORS } from '@/styles/tokens/colors';
import * as diagnostics from '@/utils/diagnostics';

import { GetImplementationTasksV2Document } from '../../graphql/ImplementationTasks.generated';
import { TaskHeaderCard } from '../../TaskHeaderCard';
import { TaskBodyProps } from '../../types';
import { useCompleteEntityTaskMutation } from '../graphql/CompleteEntityTask.generated';
import { useSetEntityThresholdsMutation } from './graphql/SetEntityThresholds.generated';

type SubstitutionThresholdsForm = SubsitutionThresholdSubformFields;

const {
  FormComponent: SubstitutionThresholdForm,
  defaultValues: substitutionThresholdFormDefaultValues,
} = new SubstitutionThresholdSubform();

export function SubstitutionThresholdsTaskBody({
  task,
  footer,
  onCompleteTask,
}: TaskBodyProps) {
  const { showFeedback } = useFeedback();
  const formProperties = useForm<SubstitutionThresholdsForm>({
    defaultValues: {
      [SUBSTITUTION_THRESHOLD_SUBFORM_NAMESPACE]:
        substitutionThresholdFormDefaultValues,
    },
  });

  const { handleSubmit, watch, reset } = formProperties;

  const watchedSubformFields = watch(SUBSTITUTION_THRESHOLD_SUBFORM_NAMESPACE);

  const [setEntityThresholdsMutation, { loading }] =
    useSetEntityThresholdsMutation();

  const [completeEntityTaskMutation, { loading: completeTaskLoading }] =
    useCompleteEntityTaskMutation();

  const { thresholdsData, loading: queryLoading } =
    useQuerySubstitutionThresholdDetails(task.entityId, true);
  const initialFormValues =
    useGetSubstitutionThresholdFormValues(thresholdsData);

  useEffect(() => {
    if (!initialFormValues) return;
    reset({
      [SUBSTITUTION_THRESHOLD_SUBFORM_NAMESPACE]: initialFormValues,
    });
  }, [initialFormValues, reset]);

  const handleCompleteTask = async (
    skip = false,
    // values is null in the case where skip is true
    values: SubstitutionThresholdsForm | null
  ) => {
    try {
      if (skip) {
        await completeEntityTaskMutation({
          variables: {
            taskID: task.id,
          },
          refetchQueries: [GetImplementationTasksV2Document],
        });

        onCompleteTask();
        return;
      }

      if (values === null || !thresholdsData) {
        throw new Error(
          'Invalid submission scenario for substitution thresholds task.'
        );
      }

      const gratTrustInput = generateThresholdDetailsUpdates(values, {
        clearThresholds: false,
      });
      await setEntityThresholdsMutation({
        variables: {
          input: {
            id: task.entityId,
            update: {},
            updateGratTrust: {
              id: thresholdsData.subtype.id,
              update: gratTrustInput,
            },
          },
          taskID: task.id,
        },
        refetchQueries: [GetImplementationTasksV2Document],
      });

      onCompleteTask();
    } catch (err) {
      diagnostics.error(
        'Failed to save SubstitutionThresholds changes',
        err as Error
      );
      showFeedback('Sorry, we failed to save your data. Please try again.');
    }
  };

  const submitHandler = handleSubmit((values) =>
    handleCompleteTask(false, values)
  );

  return (
    <FormProvider {...formProperties}>
      <TaskHeaderCard task={task} footer={footer}>
        <Stack gap={3}>
          <Callout severity="info-low">
            Asset substitution refers to swapping assets out of a GRAT to either
            lock in GRAT winnings, or to take assets out of failing GRATs with
            the intent of using the depreciated assets to fund a new GRAT
          </Callout>
          <Stack gap={3} alignSelf="center">
            <Stack
              justifyContent="center"
              borderRadius={1}
              padding={3}
              border={`1px ${COLORS.GRAY[200]} solid`}
              alignItems="center"
              gap={3}
            >
              <Stack component="form" noValidate onSubmit={submitHandler}>
                {!queryLoading && (
                  <SubstitutionThresholdForm
                    subformValues={watchedSubformFields}
                    householdId={task.householdId}
                    variant="standard"
                  />
                )}

                <Button
                  type="submit"
                  disabled={loading}
                  variant="primary"
                  fullWidth
                  size="sm"
                >
                  Set threshold alerts
                </Button>
                <Button
                  onClick={() => handleCompleteTask(true, null)}
                  disabled={completeTaskLoading}
                  variant="transparent"
                  fullWidth
                  size="sm"
                >
                  Skip this
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </TaskHeaderCard>
    </FormProvider>
  );
}
