import { ClientProfileRelationshipType } from '@/types/schema';

export interface ClientProfileRelationshipFormShape {
  relationshipId: string | null;
  toClientProfileId: string;
  display: string;
  // this needs to be an empty string because this is used to prepopulate the value
  // of a select dropdown, and `''` is a valid value, while `null` isn't.
  type: ClientProfileRelationshipType | '';
}

export interface ClientProfileNameFields {
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
}

export interface ClientProfileFormShape extends ClientProfileNameFields {
  id: string | null;
  email: string;
  address: {
    id: string | null;
    cityName: string;
    stateCode: string;
  };
  dateOfBirth: Date | null;
  isBeneficiary: boolean;
  isTrustee: boolean;
  isGrantor: boolean;
  // this can be present when the individual is a primary client, but isn't always
  isPrimary?: boolean;
  relationships: ClientProfileRelationshipFormShape[];
}

interface GetDefaultFormValuesOpts {
  hasBeneficiaryEnabled?: boolean;
  hasTrusteeEnabled?: boolean;
  hasGrantorEnabled?: boolean;
  relationshipOptions: ClientProfileRelationshipFormShape[];
  householdId: string;
  initialClientProfileName?: ClientProfileNameFields;
}

export const getDefaultClientProfileFormValues = ({
  hasBeneficiaryEnabled,
  hasTrusteeEnabled,
  hasGrantorEnabled,
  relationshipOptions,
  initialClientProfileName,
}: GetDefaultFormValuesOpts): ClientProfileFormShape => {
  return {
    id: null,
    firstName: initialClientProfileName?.firstName ?? '',
    middleName: initialClientProfileName?.middleName ?? '',
    lastName: initialClientProfileName?.lastName ?? '',
    suffix: initialClientProfileName?.suffix ?? '',
    email: '',
    address: {
      id: null,
      cityName: '',
      stateCode: '',
    },
    dateOfBirth: null,
    isBeneficiary: hasBeneficiaryEnabled ?? true,
    isTrustee: hasTrusteeEnabled ?? true,
    isGrantor: hasGrantorEnabled ?? false,
    relationships: relationshipOptions,
  };
};
