import { Control } from 'react-hook-form';

import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { TrusteeDutyKind, TrusteeLevel } from '@/types/schema';

import { NotesContainer } from './NotesContainer';
import {
  Fields,
  SubformField,
  TRUST_DETAILS_SUBFORM_NAMESPACE,
  TrustDetailsSubformType,
  TrustDetailsSubformType_TrusteesAndSuccessorTrustees,
} from './TrustDetailsSubform.types';

interface Props {
  control: Control<Fields>;
  subformValues:
    | TrustDetailsSubformType
    | TrustDetailsSubformType_TrusteesAndSuccessorTrustees;
  trusteeOptions: SelectInputProps['options'];
}

export function SuccessorTrusteeNotes({
  control,
  subformValues,
  trusteeOptions,
}: Props) {
  return (
    <NotesContainer>
      <>
        {subformValues?.successorTrustees.map((successorTrustee, i) => {
          const fieldName =
            `${TRUST_DETAILS_SUBFORM_NAMESPACE}.successorTrustees.${i}.note` as const satisfies SubformField;
          const isOtherLevel = successorTrustee.level === TrusteeLevel.Other;
          const isSpecialDuty =
            successorTrustee.duties?.includes(TrusteeDutyKind.Special) ?? false;

          const name = (
            trusteeOptions.find((option) => {
              if ('value' in option) {
                return option.value === successorTrustee.successorTrusteeId;
              }
              return false;
            }) as { display: string; value: string } | undefined
          )?.display;

          const shouldShowNotes = (isOtherLevel || isSpecialDuty) && !!name;
          const labelMap = {
            levelAndDuty: `Level & duties for ${name}`,
            level: `Level for ${name}`,
            duty: `Duties for ${name}`,
          };

          let label = '';
          if (isOtherLevel && isSpecialDuty) {
            label = labelMap.levelAndDuty;
          } else if (isOtherLevel) {
            label = labelMap.level;
          } else if (isSpecialDuty) {
            label = labelMap.duty;
          }

          if (shouldShowNotes) {
            return (
              <FormAwareTextInput<Fields>
                key={fieldName}
                control={control}
                fieldName={fieldName}
                label={label}
                multiline
                rows={2}
              />
            );
          }

          return null;
        })}
      </>
    </NotesContainer>
  );
}
