import React from 'react';

import { Lock01Icon } from '@/components/icons/Lock01Icon';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { COLORS } from '@/styles/tokens/colors';
import { AssetClassLiquidityStatus } from '@/types/schema';

interface SystemClassIndicatorProps {
  liquidityStatus: AssetClassLiquidityStatus;
}

export function SystemClassIndicator({
  liquidityStatus,
}: SystemClassIndicatorProps) {
  const tooltipContent =
    liquidityStatus === AssetClassLiquidityStatus.Liquid
      ? 'If a valuation is specified when creating a new entity, it will default to this category until further details are provided. Uncategorized assets are not reflected on the balance sheet but will be accounted for in liquidity analysis.'
      : 'Assets categorized as unspecified are not reflected on the balance sheet but will be accounted for in liquidity analysis.';

  return (
    <Tooltip title={tooltipContent} placement="top">
      <Lock01Icon size={16} color={COLORS.GRAY[400]} />
    </Tooltip>
  );
}
