import { TAX_STATUS_SUBFORM as TAX_STATUS } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.constants';
import {
  EntityGstStatus,
  EntityInEstateStatus,
  NonTrustEntityTaxStatus,
  TrustTaxStatus,
} from '@/types/schema';

import {
  EstateInclusionStatus,
  GenericNonTrustEntityTaxStatus,
  TaxStatusSubformType,
} from '../../../TaxStatusSubform/TaxStatusSubform.types';
import { SubformsCombinedType } from '../../EntitySubforms.types';

export const entityGstStatusToApiTypeMap = {
  'gst-exempt': EntityGstStatus.GstExempt,
  'gst-non-exempt': EntityGstStatus.GstNonExempt,
  'mixed-gst': EntityGstStatus.MixedGst,
};

export const trustTaxStatusToApiTypeMap = {
  'grantor-trust': TrustTaxStatus.GrantorTrust,
  'non-grantor-trust': TrustTaxStatus.NonGrantorTrust,
  'non-taxable-trust': TrustTaxStatus.NonTaxableTrust,
};

export const nonTrustEntityTaxStatusToApiTypeMap: Record<
  GenericNonTrustEntityTaxStatus,
  NonTrustEntityTaxStatus
> = {
  'taxable-entity': NonTrustEntityTaxStatus.Taxable,
  'non-taxable-entity': NonTrustEntityTaxStatus.NonTaxable,
};

export const estateInclusionStatusToApiTypeMap: Record<
  EstateInclusionStatus,
  EntityInEstateStatus
> = {
  'in-estate': EntityInEstateStatus.InEstate,
  'out-of-estate': EntityInEstateStatus.OutOfEstate,
};

export function getNonTrustEntityTaxStatusUpdates(
  formDetails: TaxStatusSubformType | undefined
) {
  if (!formDetails) {
    throw new Error(`Tax status subform is required`);
  }

  return {
    taxStatus: formDetails.nonTrustEntityTaxStatus
      ? nonTrustEntityTaxStatusToApiTypeMap[formDetails.nonTrustEntityTaxStatus]
      : undefined,
    gstStatus: formDetails.gstStatus
      ? entityGstStatusToApiTypeMap[formDetails.gstStatus]
      : undefined,
    inEstateStatus: formDetails.estateInclusionStatus
      ? estateInclusionStatusToApiTypeMap[formDetails.estateInclusionStatus]
      : undefined,
  };
}

export function sharedClearTaxStatusUpdate(
  formInput: Partial<SubformsCombinedType>
) {
  return {
    clearGstStatus:
      formInput[TAX_STATUS]?.gstStatus === '' ||
      formInput[TAX_STATUS]?.estateInclusionStatus === 'in-estate',
    clearTaxStatus:
      formInput[TAX_STATUS]?.nonTrustEntityTaxStatus === '' &&
      formInput[TAX_STATUS]?.taxStatus === '',
  };
}
