import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';

import { EntityDetailsData } from '../entityDetails.types';
import {
  getGrantorNameFromFragment,
  getSharedSummaryProperties,
} from './trustCardUtils';

export function getRevocableIrrevocableTrustSummaryProperties({
  entity,
}: EntityDetailsData): TrustSummaryData {
  if (
    entity.subtype.__typename !== 'IrrevocableTrust' &&
    entity.subtype.__typename !== 'RevocableTrust'
  ) {
    throw new Error('Invalid trust type');
  }

  return {
    ...getSharedSummaryProperties(entity),
    grantorNames:
      entity.subtype.grantors?.map(getGrantorNameFromFragment) || [],
    effectiveDate: entity.subtype.effectiveDate ?? undefined,
    beneficiariesGroups: [
      {
        heading: undefined,
        items: entity.subtype.beneficiaries || [],
        noItemsText: 'No beneficiaries',
      },
    ],
    trustDetailsItems: [],
    documentSummary: entity.inferenceDocuments?.[0]?.documentSummary,
  };
}
