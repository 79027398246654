import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useDefaultClientPresentationSections } from '@/modules/presentation/clientPresentation/hooks/useDefaultClientPresentationSections';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { useCreateClientPresentationMutation } from '../graphql/ClientPresentations.generated';

interface CreatePresentationButtonProps {
  householdId: string;
}

export function CreatePresentationButton({
  householdId,
}: CreatePresentationButtonProps) {
  const navigate = useNavigate();
  const trackUserEvent = useTrackUserEvent();
  const defaultSectionOrder = useDefaultClientPresentationSections();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const [createClientPresentationAndNavigate, { loading }] =
    useCreateClientPresentationMutation({
      variables: {
        input: {
          create: {
            name: 'Untitled presentation',
            householdID: householdId,
            entityOrder: [],
            estateWaterfallOrder: [],
            sectionOrder: defaultSectionOrder,
          },
        },
      },
      onError: (err) => {
        reportError('Failed to create presentation', err);
        showFeedback('Failed to create a new presentation; please try again');
      },
      onCompleted: (data) => {
        trackUserEvent('presentation created');
        navigate(
          getCompletePathFromRouteKey(
            ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATION,
            {
              householdId,
              presentationId: data.createClientPresentation.id,
            }
          )
        );
      },
    });

  return (
    <Button
      startIcon={PlusIcon}
      loading={loading}
      variant="primary"
      size="sm"
      onClick={() => createClientPresentationAndNavigate()}
      data-testid="CreatePresentation"
    >
      Create report
    </Button>
  );
}
