import { LazyQueryExecFunction } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { useDebouncedFn } from '@/hooks/useDebouncedFn';
import {
  CharitableTrustCalculationMethod,
  CltOptimizationInput,
  CrtOptimizationInput,
  Exact,
} from '@/types/schema';

import { useIsCRT } from '../../CharitableTrustDesignerContext';
import {
  CharitableTrustDesignerAnalysisForm,
  NAMESPACE,
} from '../CharitableTrustDesignerAnalysis.types';
import {
  GetCharitableDesignerOptimizationQuery,
  GetCharitableDesignerOptimizationQueryVariables,
} from '../graphql/GetCharitatbleDesignerOptimization.generated';
import { useDonorsYearOfBirth } from './useDonorsYearOfBirth';
import { useValidTerm } from './useValidTerm';

export function useCharitableOptimization(
  getCharitableDesignerOptimizationLazy: LazyQueryExecFunction<
    GetCharitableDesignerOptimizationQuery,
    Exact<{
      cltOptimizationInput: CltOptimizationInput;
      crtOptimizationInput: CrtOptimizationInput;
      isCRT: boolean;
    }>
  >
) {
  const { control, setValue } =
    useFormContext<CharitableTrustDesignerAnalysisForm>();
  const isCRT = useIsCRT();

  const [
    fundingAmount,
    payoutKind,
    payoutFrequency,
    calculationMethod,
    optimizationTarget,
  ] = useWatch({
    control,
    name: [
      `${NAMESPACE}.assets.value`,
      `${NAMESPACE}.payouts.kind`,
      `${NAMESPACE}.payouts.frequency`,
      `${NAMESPACE}.payouts.calculationMethod`,
      `${NAMESPACE}.payouts.optimizationTarget`,
    ],
  });

  const donorsYearOfBirth = useDonorsYearOfBirth();
  const term = useValidTerm();

  const optimizationLookupVariables =
    useMemo<GetCharitableDesignerOptimizationQueryVariables | null>(() => {
      const hasValidGrantorsYearOfBirth = true;

      if (
        calculationMethod === CharitableTrustCalculationMethod.Calculated &&
        fundingAmount?.greaterThan(0) &&
        hasValidGrantorsYearOfBirth &&
        term &&
        donorsYearOfBirth
      ) {
        const optimziationInput: CltOptimizationInput & CrtOptimizationInput = {
          fundingAmount,
          optimizationTarget,
          payoutKind,
          payoutFrequency,
          donorsYearOfBirth,
          ...term,
        };
        return {
          cltOptimizationInput: optimziationInput,
          crtOptimizationInput: optimziationInput,
          isCRT,
        };
      }
      return null;
    }, [
      calculationMethod,
      donorsYearOfBirth,
      fundingAmount,
      isCRT,
      optimizationTarget,
      payoutFrequency,
      payoutKind,
      term,
    ]);

  const optimizationQuery = useCallback(
    async (
      variables: GetCharitableDesignerOptimizationQueryVariables | null
    ) => {
      if (!variables) {
        return;
      }
      const { data } = await getCharitableDesignerOptimizationLazy({
        variables,
      });
      const { unitrustPayoutPercent, annuityPayoutAmount } =
        data?.cltOptimization || data?.crtOptimization || {};

      if (unitrustPayoutPercent) {
        setValue(
          `${NAMESPACE}.payouts.unitrustPayoutPercent`,
          unitrustPayoutPercent
        );
      }
      if (annuityPayoutAmount) {
        setValue(
          `${NAMESPACE}.payouts.annuityPaymentAmount`,
          annuityPayoutAmount
        );
      }
    },
    [getCharitableDesignerOptimizationLazy, setValue]
  );

  const debouncedOptimizationQuery = useDebouncedFn(optimizationQuery, 250, {
    leading: false,
    trailing: true,
  });

  useEffect(() => {
    void debouncedOptimizationQuery(optimizationLookupVariables);
  }, [debouncedOptimizationQuery, optimizationLookupVariables]);
}
