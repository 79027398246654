// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientProfessionalTeam_ProfessionalTeamRoleFragment = { __typename?: 'ProfessionalTeamRole', id: string, kind: Types.ProfessionalTeamRoleKind, otherRoleName?: string | null, powerOfAttorneyKind?: Types.ProfessionalTeamRolePowerOfAttorneyKind | null, household?: { __typename?: 'Household', id: string } | null, associatedClientIndividual?: { __typename?: 'ClientProfile', id: string, displayName: string, legalName: string } | null };

export type ClientProfessionalTeam_ClientProfileFragment = { __typename?: 'ClientProfile', id: string, displayName: string, legalName: string, professionalTeamRoles: { __typename?: 'ProfessionalTeamRoleConnection', edges?: Array<{ __typename?: 'ProfessionalTeamRoleEdge', node?: { __typename?: 'ProfessionalTeamRole', id: string, kind: Types.ProfessionalTeamRoleKind, otherRoleName?: string | null, powerOfAttorneyKind?: Types.ProfessionalTeamRolePowerOfAttorneyKind | null, household?: { __typename?: 'Household', id: string } | null, associatedClientIndividual?: { __typename?: 'ClientProfile', id: string, displayName: string, legalName: string } | null } | null } | null> | null } };

export type ClientProfessionalTeamQueryVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
}>;


export type ClientProfessionalTeamQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, principalClients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string }> } | null } | null> | null }, clientProfiles: { __typename?: 'ClientProfileConnection', edges?: Array<{ __typename?: 'ClientProfileEdge', node?: { __typename?: 'ClientProfile', id: string, displayName: string, legalName: string, professionalTeamRoles: { __typename?: 'ProfessionalTeamRoleConnection', edges?: Array<{ __typename?: 'ProfessionalTeamRoleEdge', node?: { __typename?: 'ProfessionalTeamRole', id: string, kind: Types.ProfessionalTeamRoleKind, otherRoleName?: string | null, powerOfAttorneyKind?: Types.ProfessionalTeamRolePowerOfAttorneyKind | null, household?: { __typename?: 'Household', id: string } | null, associatedClientIndividual?: { __typename?: 'ClientProfile', id: string, displayName: string, legalName: string } | null } | null } | null> | null } } | null } | null> | null } };

export const ClientProfessionalTeam_ProfessionalTeamRoleFragmentDoc = gql`
    fragment ClientProfessionalTeam_ProfessionalTeamRole on ProfessionalTeamRole {
  id
  kind
  otherRoleName
  powerOfAttorneyKind
  household {
    id
  }
  associatedClientIndividual {
    id
    displayName
    legalName
  }
}
    `;
export const ClientProfessionalTeam_ClientProfileFragmentDoc = gql`
    fragment ClientProfessionalTeam_ClientProfile on ClientProfile {
  id
  displayName
  legalName
  professionalTeamRoles {
    edges {
      node {
        ...ClientProfessionalTeam_ProfessionalTeamRole
      }
    }
  }
}
    ${ClientProfessionalTeam_ProfessionalTeamRoleFragmentDoc}`;
export const ClientProfessionalTeamDocument = gql`
    query ClientProfessionalTeam($householdId: ID!) {
  households(where: {id: $householdId}) {
    edges {
      node {
        id
        principalClients: possiblePrimaryClients {
          id
          displayName
        }
      }
    }
  }
  clientProfiles(where: {and: [{hasHouseholdWith: {id: $householdId}}]}) {
    edges {
      node {
        ...ClientProfessionalTeam_ClientProfile
      }
    }
  }
}
    ${ClientProfessionalTeam_ClientProfileFragmentDoc}`;

/**
 * __useClientProfessionalTeamQuery__
 *
 * To run a query within a React component, call `useClientProfessionalTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientProfessionalTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientProfessionalTeamQuery({
 *   variables: {
 *      householdId: // value for 'householdId'
 *   },
 * });
 */
export function useClientProfessionalTeamQuery(baseOptions: Apollo.QueryHookOptions<ClientProfessionalTeamQuery, ClientProfessionalTeamQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientProfessionalTeamQuery, ClientProfessionalTeamQueryVariables>(ClientProfessionalTeamDocument, options);
      }
export function useClientProfessionalTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientProfessionalTeamQuery, ClientProfessionalTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientProfessionalTeamQuery, ClientProfessionalTeamQueryVariables>(ClientProfessionalTeamDocument, options);
        }
export function useClientProfessionalTeamSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientProfessionalTeamQuery, ClientProfessionalTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientProfessionalTeamQuery, ClientProfessionalTeamQueryVariables>(ClientProfessionalTeamDocument, options);
        }
export type ClientProfessionalTeamQueryHookResult = ReturnType<typeof useClientProfessionalTeamQuery>;
export type ClientProfessionalTeamLazyQueryHookResult = ReturnType<typeof useClientProfessionalTeamLazyQuery>;
export type ClientProfessionalTeamSuspenseQueryHookResult = ReturnType<typeof useClientProfessionalTeamSuspenseQuery>;
export type ClientProfessionalTeamQueryResult = Apollo.QueryResult<ClientProfessionalTeamQuery, ClientProfessionalTeamQueryVariables>;
export const Operations = {
  Query: {
    ClientProfessionalTeam: 'ClientProfessionalTeam' as const
  },
  Fragment: {
    ClientProfessionalTeam_ProfessionalTeamRole: 'ClientProfessionalTeam_ProfessionalTeamRole' as const,
    ClientProfessionalTeam_ClientProfile: 'ClientProfessionalTeam_ClientProfile' as const
  }
}