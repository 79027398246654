import { EstateWaterfallComparisonTrowserAssumptions } from './components/Assumptions';
import { EstateWaterfallComparisonTrowserBarGraph } from './components/BarGraph';
import { EstateWaterfallComparisonTrowserParentComparisonSummary } from './components/ParentComparisonSummary';
import { EstateWaterfallComparisonTrowserSummaryTable } from './components/SummaryTable';
import { EstateWaterfallComparisonTrowserTabs } from './components/Tabs';
import { EstateWaterfallComparisonTrowserHeader } from './components/TrowserHeader';
import { EstateWaterfallComparisonWaterfallWithAssumptions } from './EstateWaterfallComparisonTrowser.type';

export interface EstateWaterfallComparisonTrowserComponentsProps {
  waterfalls: EstateWaterfallComparisonWaterfallWithAssumptions[];
}

export const EstateWaterfallComparisonTrowserComponents = {
  Header: EstateWaterfallComparisonTrowserHeader,
  Assumptions: EstateWaterfallComparisonTrowserAssumptions,
  Tabs: EstateWaterfallComparisonTrowserTabs,
  ParentComparisonSummary:
    EstateWaterfallComparisonTrowserParentComparisonSummary,
  BarGraph: EstateWaterfallComparisonTrowserBarGraph,
  SummaryTable: EstateWaterfallComparisonTrowserSummaryTable,
};
