import { useEffect, useMemo } from 'react';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import {
  BENEFICIARIES_AFTER_BOTH_GRANTORS_DIE,
  BENEFICIARIES_SUBFORM,
} from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.constants';
import { defaultBeneficiary } from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.defaults';
import { EntityBeneficiariesFormBeneficiary } from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.types';
import {
  beneficiaryFragmentToFormBeneficiary,
  sortBeneficiariesByLevel,
} from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.utils';
import {
  gstStatusToFormGSTStatus,
  inEstateStatusToFormEstateInclusionStatus,
} from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.utils';
import { TRUST_DETAILS_SUBFORM_NAMESPACE } from '@/modules/entities/TrustDetailsSubform/TrustDetailsSubform.types';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { TestamentaryEntityKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { CONTROLLING_PARTY_SUBFORM } from '../ControllingPartyInput';
import { useGetTestamentaryEntityQuery } from '../graphql/GetTestamentaryEntity.generated';
import { TESTAMENTARY_ENTITY } from '../TestamentaryEntityForm.constants';
import {
  DefaultEstateTaxStatus,
  TestamentaryEntityFormShape,
} from '../TestamentaryEntityForm.types';
import { getDefaultValues } from '../TestamentaryEntityForm.utils';

export function useSyncDataToForm({
  testamentaryEntityId,
  areAllClientsDead,
  dyingPrimaryClientId,
  defaultEstateTaxStatus,
  defaultName,
  defaultKind,
}: {
  testamentaryEntityId?: string;
  areAllClientsDead: boolean;
  dyingPrimaryClientId: string | null;
  defaultEstateTaxStatus?: DefaultEstateTaxStatus;
  defaultName?: string;
  defaultKind?: TestamentaryEntityKind;
}) {
  const { reset } = useFormContext<TestamentaryEntityFormShape>();
  const { householdId } = useHouseholdDetailsContext();

  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const defaultValues = useMemo(() => {
    return getDefaultValues({
      dyingPrimaryClientId: dyingPrimaryClientId,
      areAllClientsDead: areAllClientsDead,
      householdId,
      estateInclusionStatus: defaultEstateTaxStatus?.estateInclusionStatus,
      survivingSpouseStateInEstateStatus:
        defaultEstateTaxStatus?.survivingSpouseStateInEstateStatus,
      gstStatus: defaultEstateTaxStatus?.gstStatus,
      defaultName,
      defaultKind,
    });
  }, [
    areAllClientsDead,
    defaultEstateTaxStatus?.estateInclusionStatus,
    defaultEstateTaxStatus?.gstStatus,
    defaultEstateTaxStatus?.survivingSpouseStateInEstateStatus,
    defaultKind,
    defaultName,
    dyingPrimaryClientId,
    householdId,
  ]);

  useEffect(
    function handleResetFormOnLoad() {
      if (!testamentaryEntityId) {
        // Set the default values on the form
        reset(defaultValues);
      }
    },
    [defaultValues, reset, testamentaryEntityId]
  );

  const queryResult = useGetTestamentaryEntityQuery({
    variables: {
      where: {
        id: testamentaryEntityId,
      },
    },
    skip: !testamentaryEntityId,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      showFeedback(FeedbackMessages.queryError);
      reportError('failed to load testamentary entity event', error, {
        testamentaryEntityId,
      });
    },
    onCompleted: (data) => {
      const testamentaryEntities = getNodes(data.testamentaryEntities);

      const testamentaryEntity = testamentaryEntities[0];
      if (!testamentaryEntity) {
        return;
      }

      const {
        trustees,
        successorTrustees,
        trustAdvisors,
        displayName,
        kind,
        grantorDeath,
        gstStatus,
        inEstateStatus,
        survivingSpouseStateInEstateStatus,
        description,
        controllingParties,
        beneficiaries,
        beneficiariesAfterBothGrantorDeaths,
        householdID,
      } = testamentaryEntity;

      const formTrustees = (() => {
        if (trustees?.length) {
          return trustees.map((t) => ({
            trusteeId: (t?.individual?.id || t?.organization?.id) ?? '',
          }));
        }

        return [
          {
            trusteeId: '',
          },
        ];
      })();

      const formSuccessorTrustees = (() => {
        if (successorTrustees?.length) {
          return successorTrustees.map((t) => ({
            successorTrusteeId:
              (t?.individual?.id || t?.organization?.id) ?? '',
            level: t?.level ?? ('' as const),
            note: t?.notes ?? '',
          }));
        }

        return [
          {
            successorTrusteeId: '',
            level: '' as const,
            note: '',
          },
        ];
      })();

      const formTrustAdvisors = (() => {
        if (trustAdvisors?.length) {
          return trustAdvisors.map((t) => ({
            trustAdvisorId: (t?.individual?.id || t?.organization?.id) ?? '',
            level: t?.level ?? ('' as const),
            note: t?.note ?? '',
          }));
        }

        return [
          {
            trustAdvisorId: '',
            level: '' as const,
            notes: '',
          },
        ];
      })();

      const formControllingParties = (() => {
        if (controllingParties?.length) {
          const parties = controllingParties.map((t) => ({
            controllingPartyId: t?.individual?.id ?? '',
          }));
          return {
            [CONTROLLING_PARTY_SUBFORM]: {
              controllingParties: parties,
            },
          };
        }

        return {};
      })();

      const formBeneficiaries: EntityBeneficiariesFormBeneficiary[] = (() => {
        if (!beneficiaries?.length) {
          return [defaultBeneficiary()];
        }

        return sortBeneficiariesByLevel(beneficiaries).map((b) => {
          return beneficiaryFragmentToFormBeneficiary(b);
        });
      })();

      const formBeneficiariesAfterBothDie = (() => {
        const beneficiaries = sortBeneficiariesByLevel(
          beneficiariesAfterBothGrantorDeaths ?? []
        ).map((b) => {
          return beneficiaryFragmentToFormBeneficiary(b);
        });

        if (!beneficiaries.length && !areAllClientsDead) {
          // We should initialize the form with a default beneficiary if there are no beneficiaries
          // and we expect to display the second beneficiaries tab
          beneficiaries.push(defaultBeneficiary());
        }

        return {
          [BENEFICIARIES_AFTER_BOTH_GRANTORS_DIE]: {
            beneficiaries,
          },
        };
      })();

      const formData: TestamentaryEntityFormShape = {
        [TESTAMENTARY_ENTITY]: {
          displayName: displayName,
          householdId: householdID ?? '',
          testamentaryEntityKind: kind,
          _primaryClientDeathId: grantorDeath?.id ?? null,
          gstStatus: gstStatus
            ? gstStatusToFormGSTStatus[gstStatus]
            : ('' as const),
          estateInclusionStatus: inEstateStatus
            ? inEstateStatusToFormEstateInclusionStatus[inEstateStatus]
            : 'in-estate',
          survivingSpouseStateInEstateStatus: survivingSpouseStateInEstateStatus
            ? inEstateStatusToFormEstateInclusionStatus[
                survivingSpouseStateInEstateStatus
              ]
            : 'in-estate',
          entityDetailsNotes: description ?? '',
        },
        [TRUST_DETAILS_SUBFORM_NAMESPACE]: {
          trustees: formTrustees,
          successorTrustees: formSuccessorTrustees,
          trustAdvisors: formTrustAdvisors,
        },
        [BENEFICIARIES_SUBFORM]: {
          beneficiaries: formBeneficiaries,
        },
        ...formBeneficiariesAfterBothDie,
        ...formControllingParties,
      };

      reset(formData);
    },
  });

  return queryResult;
}
