import { useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { Path, UseFieldArraySwap } from 'react-hook-form';

import { iconSizeByButtonSize } from '@/components/form/baseInputs/Button/styles';
import { FormAwareCardRepeater } from '@/components/form/formAwareInputs/FormAwareCardRepeater/FormAwareCardRepeater';
import { ArrowNarrowDownIcon } from '@/components/icons/ArrowNarrowDownIcon';
import { ArrowNarrowUpIcon } from '@/components/icons/ArrowNarrowUpIcon';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useFormContext } from '@/components/react-hook-form';
import { EntityProposalAssetValuationProjectionType } from '@/types/schema';

import { DEFAULT_TAX_DRAG, FORM_NAME, FormShape } from '../form/constants';
import { DisplayEntity } from '../hooks/useClientEntities';
import { EditAssumptionsForm } from '../modals/EditAssumptionsModal/constants';
import { useProposalYearProjectionOptionsQuery } from './graphql/ProposalYearProjectionOptions.generated';
import { Scenario } from './scenario/Scenario';

interface Props {
  selectedEntity: DisplayEntity;
  assumptions: EditAssumptionsForm;
  setIsEditAssumptionsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function Scenarios({
  selectedEntity,
  assumptions,
  setIsEditAssumptionsModalOpen,
}: Props) {
  const { control, getValues } = useFormContext<FormShape>();
  const theme = useTheme();

  const { data } = useProposalYearProjectionOptionsQuery({
    variables: {
      entityId: selectedEntity.id,
    },
  });

  if (!data) {
    return null;
  }

  const proposalYearProjectionOptions =
    data?.proposalYearProjectionOptionsV2.map((option) => ({
      value: option.value.toString(),
      valueDecimal: new Decimal(option.value),
      display: `${option.display} yr`,
    }));

  const handleMoveUp = (i: number, swap?: UseFieldArraySwap) => {
    if (swap) {
      if (i <= 0) {
        swap(i, i);
      } else {
        swap(i, i - 1);
      }
    }
  };

  const handleMoveDown = (
    i: number,
    size: number,
    swap?: UseFieldArraySwap
  ) => {
    if (swap) {
      if (i >= size) {
        swap(i, i);
      } else {
        swap(i, i + 1);
      }
    }
  };

  return (
    <FormAwareCardRepeater
      name={`${FORM_NAME}.entities.${selectedEntity.id}.scenarios` as const}
      maxItems={3}
      addAnotherItemText="Add another scenario"
      emptyValue={{
        projectedRateOfReturn: new Decimal(0),
        preTaxProjectedRateOfReturn: new Decimal(0),
        selectedYearOption: proposalYearProjectionOptions[0]?.value,
        projectedMarketValueAtTerm: selectedEntity.projectedMarketValueAtTerm,
      }}
      control={control}
      showRemove={false}
      variant="transparent"
      shouldInit={true}
      render={(i, fieldProps, id) => {
        const cardActions = [
          <MenuItem
            label="Move up"
            key={'scenario-button-0'}
            typographyProps={{
              sx: {
                ...theme.typography.button2,
              },
            }}
            disabled={i === 0}
            onClick={() => handleMoveUp(i, fieldProps?.swap)}
            icon={<ArrowNarrowUpIcon size={iconSizeByButtonSize.xs} />}
          />,
          <MenuItem
            label="Move down"
            key={'scenario-button-1'}
            typographyProps={{
              sx: {
                ...theme.typography.button2,
              },
            }}
            disabled={
              i !== (fieldProps?.fields?.length ?? 0) - 1 ? false : true
            }
            onClick={() =>
              handleMoveDown(
                i,
                fieldProps?.fields?.length ?? 0,
                fieldProps?.swap
              )
            }
            icon={<ArrowNarrowDownIcon size={iconSizeByButtonSize.xs} />}
          />,
          <MenuItem
            label="Remove"
            key={'scenario-button-2'}
            typographyProps={{
              sx: {
                ...theme.typography.button2,
              },
            }}
            disabled={fieldProps?.fields?.length === 1}
            onClick={() => {
              fieldProps?.remove && fieldProps.remove(i);
            }}
            icon={<Trash01Icon size={iconSizeByButtonSize.xs} />}
          />,
        ];

        if (!assumptions[selectedEntity.id]) {
          assumptions[selectedEntity.id] = {
            termEndAssetValuationType:
              selectedEntity.projectedMarketValueAtTerm.equals(0)
                ? EntityProposalAssetValuationProjectionType.ProjectedRateOfReturn
                : EntityProposalAssetValuationProjectionType.ProjectedValue,
            taxDragPercent: DEFAULT_TAX_DRAG,
          };
        }

        const formPrefix =
          `${FORM_NAME}.entities.${selectedEntity.id}.scenarios.${i}` as const satisfies Path<FormShape>;

        const thisScenario = getValues(formPrefix);

        return (
          <>
            <Scenario
              id={id}
              formPrefix={formPrefix}
              cardActions={cardActions}
              idx={i}
              control={control}
              assumptions={assumptions}
              selectedEntity={selectedEntity}
              scenario={thisScenario}
              proposalYearProjectionOptions={proposalYearProjectionOptions}
              setIsEditAssumptionsModalOpen={setIsEditAssumptionsModalOpen}
            />
          </>
        );
      }}
    />
  );
}
