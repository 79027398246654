import { Stack, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';
import { useWatch } from 'react-hook-form';

import { SectionHeader } from '@/components/display/SectionHeader/SectionHeader';
import { Button } from '@/components/form/baseInputs/Button';
import { Download01Icon } from '@/components/icons/Download01Icon';
import { Scales01Icon } from '@/components/icons/Scales01Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { useFormContext } from '@/components/react-hook-form';
import { useModalState } from '@/hooks/useModalState';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { $downloadFileFromURL } from '@/modules/files/utils/fileUtils';

import { EstateWaterfallComparisonShape } from '../../EstateWaterfallComparison.type';
import { EstateWaterfallComparisonModal } from '../../EstateWaterfallComparisonModal/EstateWaterfallComparisonModal';
import { EstateWaterfallComparisonTrowserFormShape } from '../EstateWaterfallComparisonTrowser.type';
import { useDownload } from '../hooks/useDownload';

export function EstateWaterfallComparisonTrowserHeader() {
  return (
    <Stack>
      <SectionHeader
        title={
          <Typography variant="h1">Estate waterfall comparison</Typography>
        }
        rightHeaderContent={
          <Stack direction="row" spacing={1}>
            <EditComparisonButton />
            <DownloadButton />
          </Stack>
        }
      />
    </Stack>
  );
}

function DownloadButton() {
  const trackUserEvent = useTrackUserEvent();
  const [printDocument, { loading }] = useDownload();
  const { showFeedback } = useFeedback();

  const handlePrintAndDownloadDocument: MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();
    trackUserEvent('waterfall comparison exported');

    void printDocument({
      onCompleted: (data) => {
        const downloadUrl = data.print.download.downloadURL;
        try {
          void $downloadFileFromURL(downloadUrl, 'Waterfall comparison');
          showFeedback('PDF generated successfully', { variant: 'success' });
        } catch (err) {
          showFeedback('Failed to download PDF. Please try again.');
        }
      },
    });
  };

  return (
    <Tooltip
      title={
        <Typography variant="subtitle2">Download comparison as PDF</Typography>
      }
    >
      <Button
        variant="secondary"
        size="sm"
        loading={loading}
        square
        onClick={handlePrintAndDownloadDocument}
      >
        <Download01Icon size={20} />
      </Button>
    </Tooltip>
  );
}

function EditComparisonButton() {
  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');
  const { control, setValue } =
    useFormContext<EstateWaterfallComparisonTrowserFormShape>();

  const [comparisonState] = useWatch({
    control,
    name: ['comparisonState'],
  });

  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  if (!isGrowthProfilesEnabled || !comparisonState) return null;

  return (
    <>
      {isModalOpen && (
        <EstateWaterfallComparisonModal
          isEditing
          comparisonState={comparisonState}
          onClose={closeModal}
          onSubmit={(newComparisonState: EstateWaterfallComparisonShape) => {
            setValue('comparisonState', newComparisonState);
          }}
          legacyProps={undefined}
        />
      )}
      <Button
        variant="secondary"
        onClick={openModal}
        size="sm"
        startIcon={Scales01Icon}
      >
        Edit comparison
      </Button>
    </>
  );
}
