import { ApolloError } from '@apollo/client';
import { Typography } from '@mui/material';
import { useContext, useMemo } from 'react';

import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';

import { ILITTaskContext } from '../ILITTaskContext';
import { useMarkTaskSkippedMutation } from './graphql/MarkTaskSkipped.generated';
import { mapFormToSkipMutation } from './ILITTaskFooter.utils';

export interface ILITSkipTaskModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface ILITSkipTaskModalForm {
  skipReason: string;
}

export function ILITSkipTaskModal({
  isOpen,
  onClose: onCloseModal,
}: ILITSkipTaskModalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { taskId, onCompleteTask } = useContext(ILITTaskContext);
  const { control, handleSubmit, getValues, formState } =
    useForm<ILITSkipTaskModalForm>({
      defaultValues: { skipReason: '' },
    });

  const [markTaskSkippedMutation, { loading }] = useMarkTaskSkippedMutation({
    onCompleted: () => {
      showFeedback('Task marked as skipped', { variant: 'success' });
      onCloseModal();
      onCompleteTask();
    },
    onError: (error: ApolloError) => {
      showFeedback('Encountered error when updating the task', {
        variant: 'error',
      });
      reportError(`Encountered error when updating task "${taskId}"`, error);
    },
  });

  function onClickSubmit() {
    const variables = mapFormToSkipMutation(getValues(), taskId);
    void markTaskSkippedMutation({ variables });
  }

  const handleSkip = handleSubmit(onClickSubmit);

  const actions = useMemo(() => {
    return (
      <FormModalActions.Provider formState={formState} isSubmitting={loading}>
        <FormModalActions.CancelButton onClick={onCloseModal} />
        <FormModalActions.SaveButton onClick={handleSkip}>
          Mark task as skipped
        </FormModalActions.SaveButton>
      </FormModalActions.Provider>
    );
  }, [formState, handleSkip, loading, onCloseModal]);
  return (
    <FormModal
      actions={actions}
      isOpen={isOpen}
      heading="Skip this task"
      onClose={() => {
        onCloseModal();
      }}
    >
      <Typography>
        Please provide a reason for skipping this task in the field below:
      </Typography>
      <FormAwareTextInput<ILITSkipTaskModalForm>
        fieldName="skipReason"
        label="Skip reason"
        control={control}
        multiline
        rows={4}
        hideLabel
        required
      />
    </FormModal>
  );
}
