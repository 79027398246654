import { Box, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useRequiredParam } from '@/hooks/useRequiredParam';
import {
  EntityMap,
  setMostRecentlyViewedEntityMapId,
} from '@/modules/entityMap';

const MIN_HEIGHT = 720;

export function ClientDetailsEntityMapPage() {
  const householdId = useRequiredParam('householdId');
  const entityGraphViewId = useRequiredParam('id');

  useEffect(() => {
    setMostRecentlyViewedEntityMapId({ id: entityGraphViewId, householdId });
  }, [entityGraphViewId, householdId]);

  return (
    <Stack flex={1}>
      <Box flex="1" minHeight={MIN_HEIGHT}>
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => (
            <EntityMap
              householdId={householdId}
              entityGraphViewId={entityGraphViewId}
              height={Math.max(MIN_HEIGHT, height)}
              width={width}
              // Key on entityGraphViewId so all the state is reset when the view config changes
              key={entityGraphViewId}
            />
          )}
        </AutoSizer>
      </Box>
      <Outlet />
    </Stack>
  );
}
