import { CurrencyUSD } from '@/graphql/scalars';
import { PathsOf } from '@/types/subform';

import { EntityType } from '../types/EntityType';

export const BASIC_ASSETS_SUBFORM = 'basicAssetsSubform' as const;
export const BASIC_ASSETS_INITIAL_FUNDING_SUBFORM =
  'basicAssetsInitialFundingSubform' as const;
export type NamespaceType =
  | typeof BASIC_ASSETS_SUBFORM
  | typeof BASIC_ASSETS_INITIAL_FUNDING_SUBFORM;
const VARIANTS = [
  'default',
  // addeparOnly is used for the GRAT and only shows the addepar field
  'assetIntegrationOnly',
  // - used for ILITs only
  // - hides the addepar field
  // - shows a sum of policy values and an optional cash valuation
  'ilitPolicySummary',
  // - used for directly-held life insurance only
  // - hides the addepar field
  // - table of policy cash values and their sum total line (no valuation option)
  'insuranceAccountPolicies',
] as const;
export type VariantType = (typeof VARIANTS)[number];

export type BasicAssetsSubformDirtyState =
  | 'clean'
  | 'valuationFieldsDirty'
  | 'integrationOnlyDirty'
  | 'descriptionOnlyDirty';

export interface BasicAssetsSubformProperties {
  accountId?: string;
  assetCategoryId: string;
  currentMarketValue: CurrencyUSD | null;
  dateOfValuation: Date | null;
  description: string;
  valuationId?: string;
  // we use isDirty to determine if the user has made any changes to the subform, so we know whether
  // or not to create a new valuation (or just leave or update the existing valuation) when the form is submitted
  dirtyState: BasicAssetsSubformDirtyState;
  integrationEntityIds: string[];
  // we use _previousIntegrationEntityIds so we have a reference for which integration entities
  // to remove the linkage of if the property is updated
  _previousIntegrationEntityIds: string[];
  linkToAllAddeparEntities: boolean;
  documentIds?: string[];
}

// this is the type of the subform's values
export interface BasicAssetsSubformType {
  basicAssets?: BasicAssetsSubformProperties;
  basicAssetsInitialFunding?: BasicAssetsSubformProperties;
}

// this is what the subform's values will be nested under in the parent form
export interface Fields {
  [BASIC_ASSETS_SUBFORM]?: BasicAssetsSubformType;
  [BASIC_ASSETS_INITIAL_FUNDING_SUBFORM]?: BasicAssetsSubformType;
}

export type SubformField = PathsOf<Fields>;

// this is Props for the UI component
export interface Props {
  variant: VariantType;
  namespace: NamespaceType;
  // this will be shown below the valuation update fields
  assetUpdateDescription?: React.ReactNode;
  requireValuation?: boolean;
  householdId?: string;
  entityType: EntityType;
  entityId?: string;
  disabled?: boolean;
  hideDescription?: boolean;
}

export const defaultValues: BasicAssetsSubformType = {
  basicAssets: {
    accountId: '',
    assetCategoryId: '',
    valuationId: '',
    dirtyState: 'clean',
    currentMarketValue: null,
    dateOfValuation: null,
    description: '',
    integrationEntityIds: [],
    _previousIntegrationEntityIds: [],
    linkToAllAddeparEntities: false,
    documentIds: [],
  },
};

export const defaultValues_initialFunding: BasicAssetsSubformType = {
  basicAssetsInitialFunding: {
    accountId: '',
    assetCategoryId: '',
    dirtyState: 'clean',
    valuationId: '',
    currentMarketValue: null,
    dateOfValuation: null,
    description: '',
    integrationEntityIds: [],
    _previousIntegrationEntityIds: [],
    linkToAllAddeparEntities: false,
    documentIds: [],
  },
};
