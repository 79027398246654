import { DEFAULT_FAMILY_TREE_STATE } from '../contexts';
import { FamilyTreeState, FamilyTreeStateProps } from '../types';

export function generateDefaultState(
  defaultStateProps: FamilyTreeStateProps
): FamilyTreeState {
  return {
    ...DEFAULT_FAMILY_TREE_STATE,
    ...defaultStateProps,
  };
}
