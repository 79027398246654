import { CSSProperties } from 'react';

/**
 * @description hides an element visually without making it inaccessible to screen readers
 */
export const visuallyHidden: CSSProperties = {
  clip: `rect(0 0 0 0)`,
  clipPath: `inset(50%)`,
  height: `1px`,
  overflow: `hidden`,
  position: `absolute`,
  whiteSpace: `nowrap`,
  width: `1px`,
};
