import { useMemo, useState } from 'react';

import { useDispositiveProvisionsContext } from '@/modules/dispositiveProvisions/contexts/dispositiveProvisions.context';

export type ActiveDispositionTab = ReturnType<
  typeof useDispositiveProvisionsListViewTab
>;

export function useDispositiveProvisionsListViewTab(
  firstDeathPrimaryClientId?: string | null
) {
  const { primaryClients } = useDispositiveProvisionsContext();

  const defaultTab = useMemo(() => {
    if (firstDeathPrimaryClientId) {
      return firstDeathPrimaryClientId;
    }

    return primaryClients[0]?.id ?? '';
  }, [firstDeathPrimaryClientId, primaryClients]);

  const [activePrimaryClientIdTab, setActivePrimaryClientIdTab] =
    useState<string>(defaultTab);

  return {
    activePrimaryClientIdTab,
    setActivePrimaryClientIdTab,
    defaultTab,
  };
}
