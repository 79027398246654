import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import { SubtleLabelItem } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { CalloutListCard } from '@/components/layout/CalloutListCard/CalloutListCard';
import { LoadingSkeletonOrValue } from '@/components/progress/LoadingSkeletonOrValue/LoadingSkeletonOrValue';
import { EntityKind } from '@/types/schema';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { TrustSummaryData } from './TrustDetails.types';
import { SummaryContainer, SummaryWrapper } from './TrustDetails.utils';

/**
 *
 * @description This component is responsible for rendering the properties that are shared
 * between all the different types of trusts, and so takes specific property names rather
 * than a generalized set of labels and values.
 *
 */
export interface TrustSummaryProps {
  loading: boolean;
  legalName?: string;
  entityKind: EntityKind;
  extendedDisplayKind: string;
  grantorNames?: string[];
  effectiveDate?: Date;
  taxStatus?: {
    value?: string;
    valueSecondary?: string;
  };
  trustJurisdiction?: string;
  description?: string;
  assetLocation?: string;
}

export function TrustSummary({
  loading,
  legalName,
  entityKind,
  extendedDisplayKind,
  grantorNames,
  effectiveDate,
  taxStatus,
  trustJurisdiction,
  description,
  assetLocation,
}: TrustSummaryProps) {
  const includeEffectiveDate =
    entityKind === EntityKind.RevocableTrust ||
    entityKind === EntityKind.IrrevocableTrust ||
    entityKind === EntityKind.IlitTrust ||
    entityKind === EntityKind.SlatTrust;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Box>
        <Stack spacing={2}>
          <SubtleLabelItem
            label="Legal name"
            value={
              <LoadingSkeletonOrValue loading={loading} value={legalName} />
            }
          />
          <SubtleLabelItem
            label="Entity type"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={extendedDisplayKind}
              />
            }
          />
          <SubtleLabelItem
            label="Grantor(s)"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={grantorNames?.join(', ') ?? ''}
              />
            }
          />
          {includeEffectiveDate && (
            <SubtleLabelItem
              label="Effective Date"
              value={
                <LoadingSkeletonOrValue
                  loading={loading}
                  value={
                    effectiveDate
                      ? formatDateToMonDDYYYY(effectiveDate)
                      : undefined
                  }
                />
              }
            />
          )}
          <SubtleLabelItem
            label="Tax status"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={taxStatus?.value ?? ''}
              />
            }
          />

          <SubtleLabelItem
            label="Asset location"
            value={
              <LoadingSkeletonOrValue loading={loading} value={assetLocation} />
            }
          />
          <SubtleLabelItem
            label="Trust jurisdiction"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={trustJurisdiction}
              />
            }
          />
        </Stack>
        {description && (
          <Box mt={3}>
            <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
              {description}
            </Typography>
          </Box>
        )}
      </Box>
    </Stack>
  );
}

export function TrustSummaryWithWrapper(props: TrustSummaryData) {
  const {
    legalName,
    entityKind,
    extendedDisplayKind,
    grantorNames,
    effectiveDate,
    taxStatus,
    trustJurisdiction,
    description,
    loading,
    trustDetailsItems,
    assetLocation,
    onShowEditEntity,
  } = props;

  useEffect(() => {
    onShowEditEntity?.();
  }, [onShowEditEntity]);

  return (
    <SummaryWrapper>
      <SummaryContainer>
        <Box flexGrow={1} mt={2}>
          <TrustSummary
            loading={!!loading}
            legalName={legalName}
            entityKind={entityKind}
            extendedDisplayKind={extendedDisplayKind}
            grantorNames={grantorNames}
            effectiveDate={effectiveDate}
            taxStatus={taxStatus}
            trustJurisdiction={trustJurisdiction}
            description={description}
            assetLocation={assetLocation}
          />
        </Box>
        <Box>
          <CalloutListCard loading={!!loading} items={trustDetailsItems} />
        </Box>
      </SummaryContainer>
    </SummaryWrapper>
  );
}
