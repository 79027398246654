import { useCallback, useEffect } from 'react';

export function useKeypress(callback: () => void, keys: string[]) {
  const handleShortcutKeypress = useCallback(
    (event: KeyboardEvent) => {
      if (!keys.includes(event.key)) {
        return;
      }

      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement
      ) {
        return;
      }

      if (event.target instanceof HTMLElement) {
        const contentEditable = event.target.getAttribute('contenteditable');

        // content editable if:
        // - the attr is set to an explicit 'true'
        // - the attr is present and not set to false
        const isContentEditable =
          contentEditable === 'true' ||
          (!!contentEditable && contentEditable !== 'false');

        if (isContentEditable) {
          return;
        }
      }

      event.preventDefault();
      callback();
    },
    [callback, keys]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleShortcutKeypress);
    return () => {
      document.removeEventListener('keydown', handleShortcutKeypress);
    };
  }, [handleShortcutKeypress]);
}
