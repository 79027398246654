import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { getNodes } from '@/utils/graphqlUtils';

import {
  useGetAssignableEntitiesQuery,
  useGetAssignableTestamentaryEntitiesQuery,
} from './graphql/GetAssignableEntities.generated';

export interface UseGetAssignableEntitiesOpts {
  skip?: boolean;
  includeTestamentaryEntities?: boolean;
}

export function useGetAssignableEntities(
  householdId: string,
  opts: UseGetAssignableEntitiesOpts = {
    skip: false,
    includeTestamentaryEntities: false,
  }
) {
  const { data, ...rest } = useGetAssignableEntitiesQuery({
    variables: {
      // okay to do this here because we're skipping the call when documentId is null below
      entitiesLike: {
        hasHouseholdWith: [
          {
            id: householdId,
          },
        ],
      },
    },
    skip: opts.skip,
  });

  const { data: testamentaryEntitiesData } =
    useGetAssignableTestamentaryEntitiesQuery({
      variables: {
        testamentaryEntitiesLike: {
          hasHouseholdWith: [{ id: householdId }],
        },
      },
      skip: opts.skip || !opts.includeTestamentaryEntities,
    });

  const entityOptions: SelectInputOption<string>[] = useMemo(() => {
    const nodes = (getNodes(data?.entities) || []).map((entity) => {
      return {
        value: entity.id,
        display: entity.subtype.displayName,
      };
    });

    const testamentaryEntityNodes = (
      getNodes(testamentaryEntitiesData?.testamentaryEntities) || []
    ).map((testamentaryEntity) => ({
      value: testamentaryEntity.id,
      display: testamentaryEntity.displayName,
    }));
    return nodes.concat(testamentaryEntityNodes);
  }, [data?.entities, testamentaryEntitiesData?.testamentaryEntities]);

  const entitiesByID = useMemo(() => {
    const nodes = getNodes(data?.entities) || [];
    return keyBy(nodes, (e) => e.id);
  }, [data]);

  return {
    entityOptions,
    entitiesByID,
    ...rest,
  };
}
