import { concat, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useFormContext } from '@/components/react-hook-form';
import { AIOnboardingModalFormShape } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { useAIOnboardingModalContext } from '@/modules/aiOnboarding/AIOnboardingModal/context/AIOnboardingModal.context';
import {
  ALLOW_MULTIPLE_PRINCIPALS_TYPES_WITH_BUSINESS_ENTITIES,
  BUSINESS_ENTITY_TYPES,
} from '@/modules/entities/entities.constants';
import { EntityType } from '@/modules/entities/types/EntityType';

interface UseOnboardingEntityPrincipalOptionsProps {
  suggestionID: string | undefined;
  entityType: EntityType | '';
}

interface UseOnboardingEntityPrincipalOptionsReturn {
  clientProfileOptions: SelectInputOption<string>[];
  businessEntityOwnerEntityOptions: SelectInputOption<string>[];
  allowMultiplePrincipals: boolean;
}

/**
 * Returns the grantor/owners/donors options for the suggested entity to onboard.
 *
 * Primary clients and Client Profiles marked as "isGrantor" (created from the
 * previous Create Individuals & Organizations step) will always be included.
 *
 * If the suggested entity is a business entity, we also need to include
 * options that are other suggested onboarding entities.
 *
 * @param suggestionID
 * @param entityType
 */
export function useOnboardingEntityPrincipalOptions({
  suggestionID,
  entityType,
}: UseOnboardingEntityPrincipalOptionsProps): UseOnboardingEntityPrincipalOptionsReturn {
  const {
    household: { possiblePrimaryClients, possibleGrantors },
    suggestionsByID,
  } = useAIOnboardingModalContext();

  const { control } = useFormContext<AIOnboardingModalFormShape>();

  const entityFieldValues = useWatch({
    control,
    name: 'entities',
  });

  const clientProfileOptions = useMemo(
    () =>
      uniqBy([...possibleGrantors, ...possiblePrimaryClients], 'id').map(
        (client) => ({
          display: client.displayName,
          value: client.id,
        })
      ),
    [possibleGrantors, possiblePrimaryClients]
  );

  const businessEntityOwnerEntityOptions = useMemo(() => {
    if (!BUSINESS_ENTITY_TYPES.includes(entityType as EntityType)) {
      // Only business entities might have owners that are other entity suggestions.
      return [];
    }

    // These are the KG node names that we identified as a potential "owner"
    // for this suggested entity to onboard.
    const suggestedNodeNames = suggestionID
      ? suggestionsByID[suggestionID]?.onboardingEntity?.ownerEntityNodes || []
      : [];

    return entityFieldValues
      .filter((f) => f._suggestionID)
      .filter((f) => f._suggestionID !== suggestionID)
      .filter((f) => suggestedNodeNames.includes(f._nodeName || ''))
      .map((f) => ({
        display: f.entityName,
        value: f._suggestionID!,
      }));
  }, [entityFieldValues, entityType, suggestionID, suggestionsByID]);

  const allowMultiplePrincipals = useMemo(() => {
    return concat(
      ALLOW_MULTIPLE_PRINCIPALS_TYPES_WITH_BUSINESS_ENTITIES
    ).includes(entityType as EntityType);
  }, [entityType]);

  return {
    clientProfileOptions,
    businessEntityOwnerEntityOptions,
    allowMultiplePrincipals,
  };
}
