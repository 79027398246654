import { PopperContent } from '@/components/poppers/PopperContent';

export function TrustSuccessorTrustees() {
  return (
    <PopperContent
      body={
        'The individual(s) or corporate trustee(s) that oversee the entity in the event the initial trustee is unable to serve.'
      }
    />
  );
}
