import { UnreachableError } from '@/utils/errors';

import {
  autoPositionAction,
  closeSummaryPanelAction,
  completeInitializationAction,
  edgeMouseEnterAction,
  edgeMouseLeaveAction,
  editSaveAction,
  flowBoundsChangedAction,
  initializeNodePositionsAction,
  nodeMouseEnterAction,
  nodeMouseLeaveAction,
  nodesChangedAction,
  openSummaryPanelAction,
  reactFlowNodeMeasurementEventAction,
  updateEntityMapAction,
} from '../actions';
import { EntityMapAction, EntityMapReducer } from '../types';

export const entityMapReducer: EntityMapReducer = (
  state,
  action,
  queueEffect
) => {
  switch (action.type) {
    case 'EDGE_MOUSE_ENTER':
      return edgeMouseEnterAction(state, action, queueEffect);
    case 'EDGE_MOUSE_LEAVE':
      return edgeMouseLeaveAction(state, action, queueEffect);
    case 'NODE_MOUSE_ENTER':
      return nodeMouseEnterAction(state, action, queueEffect);
    case 'NODE_MOUSE_LEAVE':
      return nodeMouseLeaveAction(state, action, queueEffect);
    case 'UPDATE_ENTITY_MAP':
      return updateEntityMapAction(state, action, queueEffect);
    case 'OPEN_SUMMARY_PANEL':
      return openSummaryPanelAction(state, action, queueEffect);
    case 'CLOSE_SUMMARY_PANEL':
      return closeSummaryPanelAction(state, action, queueEffect);
    case 'INITIALIZE_NODE_POSITIONS':
      return initializeNodePositionsAction(state, action, queueEffect);
    case 'NODES_CHANGED':
      return nodesChangedAction(state, action, queueEffect);
    case 'AUTO_POSITION':
      return autoPositionAction(state, action, queueEffect);
    case 'COMPLETE_INITIALIZATION':
      return completeInitializationAction(state, action, queueEffect);
    case 'EDIT_SAVE':
      return editSaveAction(state, action, queueEffect);
    case 'FLOW_BOUNDS_CHANGED':
      return flowBoundsChangedAction(state, action, queueEffect);
    case 'REACTFLOW_NODE_MEASUREMENT_EVENT':
      return reactFlowNodeMeasurementEventAction(state, action, queueEffect);
    default:
      throw new UnreachableError({
        case: (action as EntityMapAction).type as never,
        message: `Unknown action ${(action as EntityMapAction).type}`,
      });
  }
};
