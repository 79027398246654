import { compact } from 'lodash';
import { useEffect } from 'react';

import { useFormContext } from '@/components/react-hook-form/hooks';
import {
  GrantorsFieldSubformsFieldsType,
  GrantorsFieldSubformsNamespaceType,
  GrantorsFieldSubformValuesType,
} from '@/modules/entities/principals/GrantorsField/GrantorsField.types';

import { PrimaryClientDropdown_PossibleGrantorFragment } from '../../inputs/PrimaryClientDropdown/graphql/PrimaryClientDropdown.generated';

export function usePersistNonClientGrantorIndicator(
  possibleGrantors: PrimaryClientDropdown_PossibleGrantorFragment[],
  subformNamespace: GrantorsFieldSubformsNamespaceType,
  subformValues: GrantorsFieldSubformValuesType | null
) {
  const { setValue } = useFormContext<GrantorsFieldSubformsFieldsType>();
  useEffect(
    function persistNonClientGrantorIndicator() {
      const primaryClientProfileIds = possibleGrantors.flatMap((cp) => {
        if (!cp.isPrimary) return [];
        return cp.id;
      });

      const selectedTrustGrantorIds = (() => {
        if (!subformValues) return [];
        if ('grantors' in subformValues) {
          return subformValues.grantors.map((g) => g.clientProfileId);
        } else if ('grantor' in subformValues) {
          if (typeof subformValues.grantor === 'string') {
            return compact([subformValues.grantor]);
          }

          return compact([subformValues.grantor.clientProfileId]);
        }

        return [];
      })();

      const isNonPrimaryClientGrantorSelected = (() => {
        if (selectedTrustGrantorIds.length === 0) {
          return false;
        }

        return !selectedTrustGrantorIds.every((grantorId) =>
          primaryClientProfileIds.includes(grantorId)
        );
      })();

      setValue(
        `${subformNamespace}._hasNonClientGrantor`,
        isNonPrimaryClientGrantorSelected
      );
    },
    // stringify to force subformValues changes to actually trigger this hook; they don't otherwise
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [possibleGrantors, setValue, JSON.stringify(subformValues)]
  );
}
