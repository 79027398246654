import { TypographyClamped } from '@/components/typography/TypographyClamped';

export function AssetDisplayName({
  displayName,
  variant = 'h5',
}: {
  displayName: string;
  variant?: 'h5' | 'h6';
}) {
  return (
    <TypographyClamped variant={variant} lines={2}>
      {displayName}
    </TypographyClamped>
  );
}
