import { css } from '@emotion/css';
import { Box, Stack, Typography } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { useListColumnStyles } from '@/components/lists/lists.utils';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';
import { PRESENTATION_SPACING_UNITS } from '@/modules/presentation/presentation.constants';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { TrustAdvisorsList } from '@/modules/trusts/TrustAdvisorsList/TrustAdvisorsList';
import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';
import { TrustTrusteesList } from '@/modules/trusts/TrustTrusteesList/TrustTrusteesList';

import { PresentationSlideSecondaryHeading } from '../../../components/PresentationSlideSecondaryHeading';
import { RIGHT_SIDE_CALLOUT_CARD_WIDTH_PX } from '../../components/entityPresentationComponents.constants';
import { PresentationSlideRegistrationDetails } from '../../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../../graphql/EntityPresentation.generated';

export interface TrustFiduciariesSlideProps
  extends PresentationSlideRegistrationDetails {
  entity: EntityPresentation_EntityFragment;
  summary: TrustSummaryData;
  displayName: string;
}

const SLIDE_TITLE = 'Trustees';

export function TrustFiduciariesSlide({
  entity,
  summary,
  displayName,
  ...registrationProps
}: TrustFiduciariesSlideProps) {
  useRegisterSlide({
    displayName: SLIDE_TITLE,
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  const trustAdvisors = summary.trustAdvisors ?? [];
  const listColumnsStyles = useListColumnStyles();

  return (
    <PresentationSlide.Slide
      id={registrationProps.slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading
          heading={SLIDE_TITLE}
          subheading={displayName}
        />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={entity.household.displayName}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <Stack
        direction="row"
        p={PRESENTATION_SPACING_UNITS}
        spacing={PRESENTATION_SPACING_UNITS}
        justifyContent="space-between"
        height="100%"
      >
        <Stack spacing={1}>
          <Typography variant="h1" component="h2">
            Primary & successor trustees
          </Typography>
          <TrustTrusteesList
            classes={{
              parentList: css({
                ...listColumnsStyles,
                minHeight: '90%',
              }),
            }}
            trustees={summary.trustees}
            successorTrustees={summary.successorTrustees ?? []}
          />
        </Stack>
        <Box>
          {trustAdvisors.length > 0 && (
            <Card
              variant="filled-dark"
              sx={{
                p: 3,
                height: '100%',
                width: RIGHT_SIDE_CALLOUT_CARD_WIDTH_PX,
              }}
            >
              <Stack spacing={1}>
                <Typography variant="h1" component="h2">
                  Trust advisors
                </Typography>
                <TrustAdvisorsList trustAdvisors={trustAdvisors} />
              </Stack>
            </Card>
          )}
        </Box>
      </Stack>
    </PresentationSlide.Slide>
  );
}
