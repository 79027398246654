import { Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';

import { UsersPlusIcon } from '@/components/icons/UsersPlusIcon';
import { Card } from '@/components/layout/Card/Card';
import {
  GroupedHeaderList,
  GroupedHeaderListProps,
} from '@/components/lists/GroupedHeaderList/GroupedHeaderList';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { EmptyListEditEntityActionCard } from '@/modules/entities/components/EmptyListEditEntityActionCard';
import { EntitySubformCategory } from '@/modules/entities/EntitySubforms/entitySubformConfigs';

import { TrustSummaryData } from '../TrustDetails/TrustDetails.types';
import {
  SummaryContainer,
  SummaryWrapper,
} from '../TrustDetails/TrustDetails.utils';
import { TrustTrusteesList } from '../TrustTrusteesList/TrustTrusteesList';
import { TrustAdvisorsList_TrustAdvisorFragment } from './graphql/TrustAdvisorsList.fragments.generated';
import {
  getFormattedTrustAdvisor,
  getGroupedTrustAdvisorRows,
} from './TrustAdvisorsList.utils';

type RowType = ReturnType<typeof getFormattedTrustAdvisor> & { id: string };

export interface TrustAdvisorsListProps
  extends Partial<GroupedHeaderListProps<RowType>> {
  trustAdvisors: TrustAdvisorsList_TrustAdvisorFragment[];
}

export function TrustAdvisorsList({
  trustAdvisors,
  ...props
}: TrustAdvisorsListProps) {
  const trustAdvisorRows = useMemo(
    () => getGroupedTrustAdvisorRows(trustAdvisors),
    [trustAdvisors]
  );

  return (
    <GroupedHeaderList<RowType>
      rowGroups={trustAdvisorRows}
      listVariant="dark"
      ItemComponent={({ item }) => <RichListItem {...item} />}
      {...props}
    />
  );
}

export function TrustTrusteesListWithWrapper(props: TrustSummaryData) {
  const { trustees, successorTrustees, trustAdvisors, onShowEditEntity } =
    props;

  const hasTrustees = !isEmpty([
    ...trustees,
    ...successorTrustees,
    ...trustAdvisors,
  ]);

  useEffect(() => {
    if (hasTrustees) {
      onShowEditEntity?.();
    }
  }, [hasTrustees, onShowEditEntity]);

  if (!hasTrustees) {
    return (
      <SummaryWrapper>
        <EmptyListEditEntityActionCard
          heading="Specify trustees"
          icon={UsersPlusIcon}
          buttonText="Add trustees"
          description="No trustees, successor trustees or trust advisors have been specified for this entity"
          editSubformCategory={EntitySubformCategory.TRUST_DETAILS}
        />
      </SummaryWrapper>
    );
  }

  return (
    <SummaryWrapper>
      <SummaryContainer>
        <Box flexGrow={1} mt={3} minWidth="50%">
          <TrustTrusteesList
            trustees={trustees}
            successorTrustees={successorTrustees}
          />
        </Box>
        {/* because it's fairly common to not have trust advisors, we'd prefer to hide this card and give more space to trustees
            rather than show it  */}
        {trustAdvisors.length > 0 && (
          <Card variant="filled-dark" sx={{ p: 3 }}>
            <TrustAdvisorsList trustAdvisors={trustAdvisors} />
          </Card>
        )}
      </SummaryContainer>
    </SummaryWrapper>
  );
}
