import { AfterDeath } from '@/types/schema';

export const estateTaxTabs: Record<AfterDeath, string> = {
  [AfterDeath.None]: 'Before 1st Death',
  [AfterDeath.First]: 'After 1st Death',
  [AfterDeath.Second]: 'After 2nd Death',
};

export function getEstateTaxTabs(isTwoGrantorClient: boolean) {
  if (isTwoGrantorClient) {
    return [AfterDeath.None, AfterDeath.First, AfterDeath.Second] as const;
  } else {
    return [AfterDeath.None, AfterDeath.First] as const;
  }
}
