import { Badge, BadgeProps, Stack } from '@mui/material';
import { styled } from '@mui/system';
import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { useTimeoutFn } from 'react-use';

import { Jiggle } from '@/components/animation/Jiggle/Jiggle';
import { AppNotificationPopup } from '@/components/AppNotifications/AppNotificationPopup';
import { SidebarMenuItem } from '@/components/architecture/Sidebar/SidebarMenuItem';
import { Divider } from '@/components/Divider';
import { Bell04Icon } from '@/components/icons/Bell04Icon';
import { MenuPopper } from '@/components/poppers/MenuPopper/MenuPopper';
import { COLORS } from '@/styles/tokens/colors';

const StyledCounter = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: 6,
    top: 6,
    fontSize: 10,
    height: 16,
    width: 16, // TODO: the width of the badge does not get smaller than 20px
  },
}));

export interface AppNotificationsProps {
  colorTheme: 'light' | 'dark';
  /**
   * count is the number of notifications to display above the icon
   */
  count: number;
  /**
   * newNotificationPopup is a temporary popup that will be displayed when a
   * new notification is received.
   */
  newNotificationPopup?: {
    message: string;
    duration?: number;
    onClose: () => void;
  } | null;
}

const DEFAULT_POPUP_DURATION = 5 * 1000;

export function AppNotifications({
  colorTheme,
  count,
  newNotificationPopup,
  children,
}: PropsWithChildren<AppNotificationsProps>) {
  const [menuIsOpen, setMenuIsOpen] = React.useState(false);
  const menuButtonRef = useRef<HTMLButtonElement>(null);

  const [_, cancel, reset] = useTimeoutFn(() => {
    newNotificationPopup?.onClose();
  }, newNotificationPopup?.duration || DEFAULT_POPUP_DURATION);

  useEffect(() => {
    if (newNotificationPopup) {
      reset();
    } else {
      cancel();
    }
  }, [newNotificationPopup, reset, cancel]);

  return (
    <>
      <MenuPopper
        anchorRef={menuButtonRef}
        open={menuIsOpen}
        onClose={() => {
          setMenuIsOpen(false);
        }}
        elevation={8}
      >
        <Stack divider={<Divider />}>{children}</Stack>
      </MenuPopper>
      <AppNotificationPopup
        open={!!newNotificationPopup}
        message={newNotificationPopup?.message || ''}
      >
        <StyledCounter
          ref={menuButtonRef}
          badgeContent={count}
          color="error"
          overlap="circular"
          onClick={() => setMenuIsOpen(true)}
          data-testid="app-notifications-counter"
        >
          <SidebarMenuItem
            text="Notifications"
            icon={
              <Jiggle animate={!!newNotificationPopup}>
                <Bell04Icon size={16} color={COLORS.FUNCTIONAL.WARNING[400]} />
              </Jiggle>
            }
            variant={'small' as const}
            colorTheme={colorTheme}
            highlightWhenActive={true}
            isActive={menuIsOpen}
          />
        </StyledCounter>
      </AppNotificationPopup>
    </>
  );
}
