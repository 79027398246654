import { useEffect, useRef, useState } from 'react';

const SETTLE_TIME_MS = 2000;
const POLL_INTERVAL_MS = 50;

function getNumberOfInFlightQueries() {
  return window.__print?.inFlightRequests ?? undefined;
}

function getApolloErrorCount() {
  return window.__print?.apolloErrorCount ?? undefined;
}

// Polls the window.inFlightRequests to determine if the network is done loading.
// ONLY WORKS FOR __PRINT ROUTES
export function useWaitForNetworkToSettle() {
  const [isDoneLoading, setIsDoneLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const timeout = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    const interval = setInterval(() => {
      const numberOfInFlightQueries = getNumberOfInFlightQueries();
      const apolloErrorCount = getApolloErrorCount();
      if (apolloErrorCount) {
        setHasErrors(true);
        // Stop polling
        clearInterval(interval);
      }

      if (numberOfInFlightQueries === 0) {
        // It may be done
        timeout.current = setTimeout(() => {
          // Check again after a bit
          const numberOfInFlightQueries = getNumberOfInFlightQueries();

          if (numberOfInFlightQueries === 0) {
            // After waiting a bit, if the number of active queries is still 0, we're done loading
            setIsDoneLoading(true);
            // Stop polling
            clearInterval(interval);
          }
        }, SETTLE_TIME_MS);
      }
    }, POLL_INTERVAL_MS);

    return () => {
      clearInterval(interval);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return {
    isDoneLoading,
    hasErrors,
  };
}
