import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { usePrintPresentationMutation } from './graphql/UseDownloadClientPresentation.generated';

function getPathToPrint(householdId: string, presentationId: string) {
  const path = getCompletePathFromRouteKey(
    ROUTE_KEYS.__PRINT_ONLY_CLIENT_PRESENTATION,
    {
      householdId,
      presentationId,
    }
  );

  // the lambda actually expects this whole path EXCEPT for the leading slash.
  return path.slice(1, path.length);
}

export function useDownloadClientPresentation(
  householdId: string,
  presentationId: string
) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  return usePrintPresentationMutation({
    variables: {
      householdId,
      pathToPrint: getPathToPrint(householdId, presentationId),
    },
    onError: (err) => {
      reportError('Failed to print presentation', err);
      showFeedback(`We weren't able to generate the PDF. Please try again.`);
    },
  });
}
