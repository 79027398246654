import { BoxProps } from '@mui/material';
import Decimal from 'decimal.js';
import { map } from 'lodash';
import { ComponentType, Fragment, useEffect, useMemo } from 'react';

import { InteractionParadigmContext } from '@/contexts/InteractionParadigm.context';
import { BalanceSheetTable_BalanceSheetFragment } from '@/modules/balanceSheet/BalanceSheetTable/graphql/BalanceSheetTable.fragments.generated';
import { CustomerThemeProvider } from '@/styles/themes/CustomerThemeProvider';
import { ClientPresentationSection } from '@/types/schema';
import { sortAlpha } from '@/utils/sortUtils';

import { BalanceSheetSlide } from '../../BalanceSheetSlide/BalanceSheetSlide';
import { useGuardedTenantPresentationConfigurationContext } from '../../context/tenantPresentationConfiguration.context';
import { TenantPresentationConfigurationProvider } from '../../context/TenantPresentationConfiguration.provider';
import { CoverSlide } from '../../CoverSlide/CoverSlide';
import { AllEntitiesSlide } from '../../entities/AllEntitiesSlide/AllEntitiesSlide';
import { AllEntitiesSlide_EntityFragment } from '../../entities/AllEntitiesSlide/graphql/AllEntitiesSlide.generated';
import { getGuardedEntityDetails } from '../../entities/hooks/useEntityPresentationDetails.utils';
import { SingleEntityPresentationBundle } from '../../entities/SingleEntityPresentationBundle/SingleEntityPresentationBundle';
import { ALL_ENTITY_SLIDE_TYPES } from '../../entities/SingleEntityPresentationBundle/SingleEntityPresentationBundle.constants';
import {
  EstateWaterfallPresentationBundle,
  EstateWaterfallPresentationBundleProps,
} from '../../estateWaterfall/EstateWaterfallPresentationBundle';
import { TenantPresentationConfiguration_TenantDisclaimerConfigurationFragment } from '../../graphql/TenantPresentationConfiguration.generated';
import { ProfessionalTeamSlide_ClientProfileFragment } from '../../ProfessionalTeamSlide/graphql/ProfessionalTeamSlide.generated';
import { ProfessionalTeamSlide } from '../../ProfessionalTeamSlide/ProfessionalTeamSlide';
import { mapDataToTable } from '../../ProfessionalTeamSlide/ProfessionalTeamSlide.utils';
import {
  ClientPresentationDesigner_ClientPresentationCoverSlideDetailsFragment,
  ClientPresentationDesigner_HouseholdFragment,
} from '../ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { useGuardedClientPresentationDesignerContext } from '../contexts/clientPresentationDesigner.context';
import { CustomDisclaimerSlide } from '../DisclaimerSlide/CustomDisclaimerSlide';
import { LuminaryDisclaimerSlide } from '../DisclaimerSlide/LuminaryDisclaimerSlide';

export interface ClientPresentationContentProps {
  legalDisclaimers: TenantPresentationConfiguration_TenantDisclaimerConfigurationFragment | null;
  estateWaterfalls: EstateWaterfallPresentationBundleProps['waterfall'][];
  entitiesDetails: ReturnType<typeof getGuardedEntityDetails>[];
  entities: AllEntitiesSlide_EntityFragment[];
  balanceSheet: BalanceSheetTable_BalanceSheetFragment | null;
  client: ClientPresentationDesigner_HouseholdFragment;
  professionalTeam: ProfessionalTeamSlide_ClientProfileFragment[];
  federalEstateTaxPercent: Decimal | null | undefined;
  // coverSlideDetails is optional because it's only passed through this path in a print scenario, when we want to make sure that all
  // the data is fetched and passed as part of the one core query to ensure that everything is rendered at the time we're printing
  coverSlideDetails?: ClientPresentationDesigner_ClientPresentationCoverSlideDetailsFragment;
  PresentationSlideWrapper?: ComponentType<BoxProps>;
}

function ClientPresentationContentInner({
  legalDisclaimers,
  estateWaterfalls,
  entitiesDetails,
  entities,
  balanceSheet,
  client,
  federalEstateTaxPercent,
  professionalTeam,
  coverSlideDetails,
  PresentationSlideWrapper,
}: ClientPresentationContentProps) {
  const { setLegalDisclaimers } =
    useGuardedTenantPresentationConfigurationContext();

  const { renderedSectionOrder, visibleEntityPulids, visibleWaterfallPulids } =
    useGuardedClientPresentationDesignerContext();

  useEffect(() => {
    setLegalDisclaimers(legalDisclaimers);
  }, [legalDisclaimers, setLegalDisclaimers]);

  // do this mapping here so it doesn't populate the sidebar
  const professionalTeamMembers = mapDataToTable(professionalTeam);
  const showProfessionalTeamSlide = !!professionalTeamMembers.length;

  // first, build up a map of the section to the component,
  // so that we can then go map over the list of sections in the defined order and render it appropriately
  const sectionToBundleMap: Record<ClientPresentationSection, React.ReactNode> =
    useMemo(() => {
      const orderedEntitiesDetails = entitiesDetails.sort((a, b) => {
        if (!visibleEntityPulids)
          return sortAlpha(
            a.entityDetails?.subtype.displayName ?? '',
            a.entityDetails?.subtype.displayName ?? ''
          );
        const aIndex = visibleEntityPulids.indexOf(a.entityDetails?.id ?? '');
        const bIndex = visibleEntityPulids.indexOf(b.entityDetails?.id ?? '');

        return aIndex - bIndex;
      });

      const orderedEstateWaterfalls = estateWaterfalls.sort((a, b) => {
        if (!visibleWaterfallPulids)
          return sortAlpha(a.displayName ?? '', b.displayName ?? '');

        const aIndex = visibleWaterfallPulids.indexOf(a.id);
        const bIndex = visibleWaterfallPulids.indexOf(b.id);
        return aIndex - bIndex;
      });

      return {
        ALL_ENTITIES_SLIDE: (
          <AllEntitiesSlide
            client={client}
            SlideWrapper={PresentationSlideWrapper}
            entities={entities}
          />
        ),
        BALANCE_SHEET: balanceSheet && (
          <BalanceSheetSlide
            client={client}
            balanceSheet={balanceSheet}
            SlideWrapper={PresentationSlideWrapper}
          />
        ),
        PROFESSIONAL_TEAM: showProfessionalTeamSlide && (
          <ProfessionalTeamSlide
            client={client}
            professionalTeamMembers={professionalTeamMembers}
            SlideWrapper={PresentationSlideWrapper}
          />
        ),
        ESTATE_WATERFALLS_GROUP: orderedEstateWaterfalls.map((waterfall) => (
          <EstateWaterfallPresentationBundle
            key={waterfall.id}
            waterfall={waterfall}
            federalEstateTaxPercent={federalEstateTaxPercent}
            SlideWrapper={PresentationSlideWrapper}
          />
        )),
        ENTITIES_GROUP: orderedEntitiesDetails.map(
          (details) =>
            details.entityDetails && (
              <SingleEntityPresentationBundle
                key={details.entityDetails.id}
                SlideWrapper={PresentationSlideWrapper}
                slideTypes={ALL_ENTITY_SLIDE_TYPES}
                entity={details.entityDetails}
                subtype={details.subtypeDetails}
                dispositionScenarios={details.dispositionScenariosDetails}
                entityId={details.entityDetails.id}
              />
            )
        ),
      };
    }, [
      PresentationSlideWrapper,
      balanceSheet,
      client,
      entities,
      entitiesDetails,
      estateWaterfalls,
      federalEstateTaxPercent,
      professionalTeamMembers,
      showProfessionalTeamSlide,
      visibleEntityPulids,
      visibleWaterfallPulids,
    ]);

  return (
    <>
      <CoverSlide
        coverSlideDetails={coverSlideDetails}
        client={client}
        SlideWrapper={PresentationSlideWrapper}
      />
      {map(renderedSectionOrder, (section, key) => {
        return <Fragment key={key}>{sectionToBundleMap[section]}</Fragment>;
      })}
      {legalDisclaimers?.presentationStandaloneDisclaimerBody && (
        <CustomDisclaimerSlide
          client={client}
          SlideWrapper={PresentationSlideWrapper}
        />
      )}
      {legalDisclaimers?.includeLuminaryDisclaimer && (
        <LuminaryDisclaimerSlide
          client={client}
          SlideWrapper={PresentationSlideWrapper}
        />
      )}
    </>
  );
}

export function ClientPresentationContent(
  props: ClientPresentationContentProps
) {
  return (
    <InteractionParadigmContext.Provider value={{ viewOnly: true }}>
      <CustomerThemeProvider>
        <TenantPresentationConfigurationProvider>
          <ClientPresentationContentInner {...props} />
        </TenantPresentationConfigurationProvider>
      </CustomerThemeProvider>
    </InteractionParadigmContext.Provider>
  );
}
