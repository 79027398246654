import { compact } from 'lodash';

import { ENTITY_TYPES } from '../entities.constants';
import { EntityType } from '../types/EntityType';

export interface EntityOption {
  label: string;
  value: EntityType;
  disabled?: boolean;
}

export function getEntityOptions(
  opts: {
    isInlineCreationScenario?: boolean;
  } = {
    isInlineCreationScenario: false,
  }
) {
  const trusts: EntityOption[] = compact([
    {
      label: 'Irrevocable trust',
      value: ENTITY_TYPES.IRREVOCABLE_TRUST,
    },
    {
      label: 'Revocable trust',
      value: ENTITY_TYPES.REVOCABLE_TRUST,
    },
    {
      label: 'Spousal lifetime access trust (SLAT)',
      value: ENTITY_TYPES.SLAT,
    },
    // the GRAT designer pattern is too complicated to support inline creation
    !opts.isInlineCreationScenario && {
      label: 'Grantor-retained annuity trust (GRAT)',
      value: ENTITY_TYPES.GRAT,
    },
    {
      label: 'Irrevocable life insurance trust (ILIT)',
      value: ENTITY_TYPES.ILIT,
    },
    {
      label: 'Qualified personal residence trust (QPRT)',
      value: ENTITY_TYPES.QPRT,
    },
  ]);

  const personalAccounts: EntityOption[] = compact([
    {
      label: 'Personal account',
      value: ENTITY_TYPES.INDIVIDUAL_ACCOUNT,
    },
    {
      label: 'Joint account',
      value: ENTITY_TYPES.JOINT_ACCOUNT,
    },
    {
      label: 'Retirement account',
      value: ENTITY_TYPES.RETIREMENT_ACCOUNT,
    },
    {
      label: 'Directly held life insurance',
      value: ENTITY_TYPES.INSURANCE_ACCOUNT,
    },
    {
      label: 'UGMA/UTMA custodial account',
      value: ENTITY_TYPES.CUSTODIAL_ACCOUNT,
    },
    {
      label: '529 plan',
      value: ENTITY_TYPES.QUALIFIED_TUITION_ACCOUNT,
    },
  ]);

  const charitableEntities: EntityOption[] = [
    {
      label: 'Charitable remainder trust (CRT)',
      value: ENTITY_TYPES.CRT,
    },
    {
      label: 'Charitable lead trust (CLT)',
      value: ENTITY_TYPES.CLT,
    },
    {
      label: 'Donor advised fund (DAF)',
      value: ENTITY_TYPES.DAF,
    },
    {
      label: 'Private foundation',
      value: ENTITY_TYPES.PRIVATE_FOUNDATION,
    },
  ];

  const businessEntities: EntityOption[] = [
    {
      label: 'Limited liability company (LLC)',
      value: ENTITY_TYPES.LLC,
    },
    {
      label: 'General partnership (GP)',
      value: ENTITY_TYPES.GP,
    },
    {
      label: 'Limited partnership (LP)',
      value: ENTITY_TYPES.LP,
    },
    {
      label: 'S-corporation',
      value: ENTITY_TYPES.S_CORP,
    },
    {
      label: 'C-corporation',
      value: ENTITY_TYPES.C_CORP,
    },
    {
      label: 'Sole proprietorship',
      value: ENTITY_TYPES.SOLE_PROPRIETORSHIP,
    },
  ];

  return { trusts, personalAccounts, charitableEntities, businessEntities };
}
