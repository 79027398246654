import { Grid, Stack, Typography } from '@mui/material';
import { Children, ReactNode } from 'react';
import { useMeasure } from 'react-use';

import { Card } from '@/components/layout/Card/Card';

import { PRESENTATION_SPACING_UNITS } from '../../presentation.constants';

export interface SummarySlideLayoutProps {
  LeftContent: ReactNode[];
  RightContent: ReactNode;
}

export function SummarySlideLayout({
  LeftContent,
  RightContent,
}: SummarySlideLayoutProps) {
  const [summaryTitleRef, { height: summaryTitleHeight }] =
    useMeasure<HTMLDivElement>();

  return (
    <Stack px={PRESENTATION_SPACING_UNITS} py={1} spacing={2} height="100%">
      <Stack
        ref={summaryTitleRef}
        direction="row"
        justifyContent="space-between"
      >
        <Typography variant="h2">Summary</Typography>
      </Stack>
      <Grid container height={`calc(100% - ${summaryTitleHeight}px)`}>
        <Grid item sm={4} sx={{ height: '100%' }}>
          <Card variant="outlined" sx={{ height: '100%', p: 2 }}>
            <Stack spacing={2}>
              {Children.map(LeftContent, (child, idx) => (
                <Stack key={`left-content-${idx}`}>{child}</Stack>
              ))}
            </Stack>
          </Card>
        </Grid>
        <Grid item sm={8} sx={{ height: '100%', pl: 3 }}>
          {RightContent}
        </Grid>
      </Grid>
    </Stack>
  );
}
