import { Stack } from '@mui/material';
import { Children, cloneElement, ComponentProps, ReactElement } from 'react';

import { ControlButton } from './ControlButton';

interface ControlButtonGroupProps {
  children: ReactElement<ComponentProps<typeof ControlButton>>[];
}

export function ControlButtonGroup({ children }: ControlButtonGroupProps) {
  const childrenLength = Children.count(children);
  return (
    <Stack direction="row">
      {Children.map(children, (child, idx) => {
        if (idx === 0) {
          return cloneElement(child, {
            sx: {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          });
        }

        if (idx === childrenLength - 1) {
          return cloneElement(child, {
            sx: {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeft: 'none',
            },
          });
        }

        return cloneElement(child, {
          sx: {
            borderLeft: 'none',
            borderRadius: 0,
          },
        });
      })}
    </Stack>
  );
}
