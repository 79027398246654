import { Box, Stack, Typography } from '@mui/material';
import { Control, FieldValues } from 'react-hook-form';

import { TaskDetailsFormFragment } from '../TaskDetailsFormFragment/TaskDetailsFormFragment';

interface FullScreenTaskLeftPaneLayoutProps<FormShape extends FieldValues> {
  heading: React.ReactNode;
  householdId: string;
  subheading?: string;
  rightHeaderContent?: React.ReactNode;
  body?: React.ReactNode;
  control: Control<FormShape>;
  taskControlsDisabled?: boolean;
  taskDescription: string;
  showSave?: boolean;
  onSave?: () => void;
}

export function FullScreenTaskLeftPaneLayout<FormShape extends FieldValues>({
  heading,
  subheading,
  householdId,
  body,
  control,
  taskControlsDisabled,
  taskDescription,
  rightHeaderContent,
  ...saveProps
}: FullScreenTaskLeftPaneLayoutProps<FormShape>) {
  return (
    <Stack spacing={3} p={2}>
      <Box>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="h1">{heading}</Typography>
          {rightHeaderContent && rightHeaderContent}
        </Stack>
        {subheading && (
          <Box mt={1}>
            <Typography variant="body1">{subheading}</Typography>
          </Box>
        )}
      </Box>
      {body && body}
      <Box>
        <TaskDetailsFormFragment
          householdId={householdId}
          control={control}
          description={taskDescription}
          controlsDisabled={taskControlsDisabled}
          {...saveProps}
        />
      </Box>
    </Stack>
  );
}
