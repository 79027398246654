export function createValueToDisplayLookup<ValueType extends string>(
  types: { value: ValueType; display: string }[]
): Record<ValueType, string> {
  return types.reduce(
    (res, key) => {
      res[key.value] = key.display;
      return res;
    },
    Object.create(null) as Record<ValueType, string>
  );
}

export function createValueEnum<ValueType extends string>(
  types: { value: ValueType }[]
): Record<ValueType, ValueType> {
  return types.reduce(
    (res, key) => {
      res[key.value] = key.value;
      return res;
    },
    Object.create(null) as Record<ValueType, ValueType>
  );
}
