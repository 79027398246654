import { QueryResult } from '@apollo/client';
import { useEffect } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import {
  AugmentedCreateLoggedTransferInput,
  AugmentedUpdateLoggedTransferInput,
  CreateLoggedTransferInput,
  LoggedTransferPurpose,
  UpdateLoggedTransferInput,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import { TransferOptionType } from '../TransferOption.type';
import {
  LogNewTransferModalQuery,
  LogNewTransferModalQueryVariables,
  useLogNewTransferModalLazyQuery,
} from './graphql/LogNewTransferModal.generated';
import { getDefaultValues, NAMESPACE } from './LogNewTransferModal.constants';
import { LogNewTransferForm, TransferType } from './LogNewTransferModal.types';

const getModalTypeText = (transferType: TransferType) => {
  switch (transferType) {
    case TransferType.Contribution:
      return 'Contribution';
    case TransferType.Distribution:
      return 'Distribution';
    default:
      throw new UnreachableError({
        case: transferType,
        message: 'Invalid modal type provided',
      });
  }
};

export interface BaseLoggedTransferInput {
  form: LogNewTransferForm;
  transferType: TransferType;
  initiatorId: string;
  initiatorKind: TransferOptionType;
}

export function getCreateLoggedTransferInput({
  form,
  transferType,
  initiatorId,
  initiatorKind,
}: BaseLoggedTransferInput): AugmentedCreateLoggedTransferInput {
  const prefix = getModalTypeText(transferType);
  if (!form[NAMESPACE].transactionDate) {
    throw new Error(`${prefix} date is required`);
  }

  if (!form[NAMESPACE].amount) {
    throw new Error(`${prefix} amount used is required`);
  }

  if (!form[NAMESPACE].targetId) {
    throw new Error(`${prefix} target is required`);
  }

  // the API is one-directional, in that the source always sends the amount to the receiver;
  // however, the modal is bi-directional, in that the initiator can be either send or receive
  // the amount to the selected entity/grantor (and org, but only if sending).
  //
  // as such, it's necessary to map the initiator (entity under the modal) and the selected
  // entity/grantor/organization based on the flow direction
  const receivingData: Partial<CreateLoggedTransferInput> = {};
  const sourceData: Partial<CreateLoggedTransferInput> = {};

  const { targetId: selectedId, targetType: selectedKind } = form[NAMESPACE];

  let sourceId = '';
  let sourceKind: TransferOptionType = TransferOptionType.Entity;
  let receivingId = '';
  let receivingKind: TransferOptionType = TransferOptionType.Entity;

  switch (transferType) {
    case TransferType.Contribution:
      sourceId = selectedId;
      sourceKind = selectedKind;
      receivingId = initiatorId;
      receivingKind = initiatorKind;
      break;
    case TransferType.Distribution:
      receivingId = selectedId;
      receivingKind = selectedKind;
      sourceId = initiatorId;
      sourceKind = initiatorKind;
      break;
    default:
      throw new UnreachableError({
        case: transferType,
        message: 'Transfer type not found',
      });
  }

  // receiver can be an entity, grantor, or organization -- but
  // only one of those values should be populated
  switch (receivingKind) {
    case TransferOptionType.Entity:
      receivingData.receivingEntityID = receivingId;
      break;
    case TransferOptionType.Grantor:
      receivingData.receivingGrantorID = receivingId;
      break;
    case TransferOptionType.Organization:
      receivingData.receivingOrganizationID = receivingId;
      break;
    default:
      throw new UnreachableError({
        case: receivingKind,
        message: 'Transfer receiver not found',
      });
  }

  // similarly, the source can either be an entity or a grantor (but
  // not an organiztion), and only one of those values should be populated
  switch (sourceKind) {
    case TransferOptionType.Entity:
      sourceData.sourceEntityID = sourceId;
      break;
    case TransferOptionType.Grantor:
      sourceData.sourceGrantorID = sourceId;
      break;
    case TransferOptionType.Organization:
      sourceData.sourceOrganizationID = sourceId;
      break;
    default:
      throw new UnreachableError({
        case: sourceKind,
        message: 'Transfer receiver not found',
      });
  }

  return {
    create: {
      ...receivingData,
      ...sourceData,
      amount: form[NAMESPACE].amount,
      documentIDs: form[NAMESPACE].documentIds,
      transactionDate: form[NAMESPACE].transactionDate,
      purpose: form[NAMESPACE].purpose || undefined,
      otherPurposeDescription: form[NAMESPACE].otherPurpose,
    },
  };
}

export function getUpdateLoggedTransferInput({
  loggedContributionId,
  ...inputs
}: BaseLoggedTransferInput & {
  loggedContributionId?: string;
}): AugmentedUpdateLoggedTransferInput {
  if (!loggedContributionId) {
    throw new Error('Contribution ID is required');
  }

  // multiple steps necessary to delete the documentIDs field that
  // will break updates
  const { create } = getCreateLoggedTransferInput(inputs);
  delete create.documentIDs;

  const update: UpdateLoggedTransferInput = create;

  update.clearSourceEntity = true;
  update.clearSourceGrantor = true;
  update.clearSourceOrganization = true;

  if (!update.purpose) {
    update.clearPurpose = true;
  }

  if (update.purpose !== LoggedTransferPurpose.Other) {
    update.clearOtherPurposeDescription = true;
    delete update.otherPurposeDescription;
  }

  return {
    id: loggedContributionId,
    update,
  };
}

interface UseSyncDataToFormParams {
  skipQuery: boolean;
}

export function useSyncDataToForm(
  transferId: string | null,
  transferType: TransferType,
  initiatorId: string,
  params: UseSyncDataToFormParams
): QueryResult<LogNewTransferModalQuery, LogNewTransferModalQueryVariables> {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { reset } = useFormContext<LogNewTransferForm>();

  const [fetchLogNewTransferModalQuery, queryResult] =
    useLogNewTransferModalLazyQuery({
      onError: (error) => {
        showFeedback(
          "We weren't able to load this form. Please refresh the page and try again."
        );
        reportError(
          'failed to load form options for the LogNewTransferModal',
          error,
          {
            entityId: initiatorId,
          }
        );
      },
      skipPollAttempt: () => params.skipQuery,
    });

  useEffect(() => {
    async function fetchData() {
      const { data } = await fetchLogNewTransferModalQuery({
        variables: {
          entityId: initiatorId,
          transferId: transferId || '',
          hasTransferId: !!transferId,
        },
      });

      const loggedTransfer = getNodes(data?.loggedTransfers)[0];
      let targetId: string | undefined;
      let targetType: TransferOptionType | undefined;

      if (loggedTransfer) {
        switch (transferType) {
          case TransferType.Contribution:
            targetId =
              loggedTransfer.sourceEntity?.id ||
              loggedTransfer.sourceGrantor?.id ||
              loggedTransfer.sourceOrganization?.id ||
              '';
            targetType = loggedTransfer.sourceEntity?.id
              ? TransferOptionType.Entity
              : TransferOptionType.Grantor;
            break;
          case TransferType.Distribution:
            targetId =
              loggedTransfer.receivingEntity?.id ||
              loggedTransfer.receivingGrantor?.id ||
              loggedTransfer.receivingOrganization?.id ||
              '';
            if (loggedTransfer.receivingEntity?.id) {
              targetType = TransferOptionType.Entity;
            } else if (loggedTransfer.receivingGrantor?.id) {
              targetType = TransferOptionType.Grantor;
            } else {
              targetType = TransferOptionType.Organization;
            }
            break;
          default:
            throw new UnreachableError({
              case: transferType,
              message: 'Invalid transfer provided',
            });
        }
      }

      const transferData: LogNewTransferForm[typeof NAMESPACE] =
        !!loggedTransfer && targetId && targetType
          ? {
              targetId,
              targetType,
              amount: loggedTransfer.amount,
              documentIds: [] as string[],
              transactionDate: loggedTransfer.transactionDate,
              purpose: loggedTransfer.purpose || '',
              otherPurpose: loggedTransfer.otherPurposeDescription || '',
            }
          : getDefaultValues()[NAMESPACE];
      reset({
        [NAMESPACE]: transferData,
      });
    }

    void fetchData();
  }, [
    fetchLogNewTransferModalQuery,
    initiatorId,
    reset,
    transferId,
    transferType,
  ]);
  return queryResult;
}
