import { EntityCardProps } from '../entityDetailPageUtils';
import { EntityValuation } from '../EntityValuation/EntityValuation';

/**
 * @description This is the assets card to be rendered for active GRATs. You probably want to use the StageAwareGRATAssetsCard instead,
 * which correctly renders this is or the GRAT distribution card, depending on the stage of the GRAT entity. This wraps the GRATAssetDetails
 * component and augments it with the ability to update the valuation of the GRAT.
 */
export function GRATAssetDetailsCard({ entity, ...rest }: EntityCardProps) {
  const { onUpdate, isPanelView } = rest;
  return (
    <EntityValuation
      entity={entity}
      onAfterUpdate={onUpdate}
      variant={isPanelView ? 'plain' : 'card'}
    />
  );
}
