import { Box, Stack, Typography } from '@mui/material';
import { first } from 'lodash';
import { useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import { useModalState } from '@/hooks/useModalState';
import { COLORS } from '@/styles/tokens/colors';

import { DispositiveProvisionTemplateMenu } from '../../components/DispositiveProvisionTemplatesMenu/DispositiveProvisionTemplateMenu';
import { useDispositiveProvisionsContext } from '../../contexts/dispositiveProvisions.context';
import { DispositionScheme } from '../../dispositiveProvisions.types';
import { DispositiveProvisionsTemplateSplitScreenModal } from '../../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal';
import { useDispositiveProvisionsTemplateSplitScreenModalContext } from '../../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.context';
import {
  DispositiveProvisionTemplateDetails,
  NEW_TEMPLATE_SENTINEL,
} from '../../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.types';
import { DISPOSITIVE_PROVISIONS_FORM_NAMESPACE } from '../DispositiveProvisionsForm.constants';
import {
  DispositiveProvisionsFormPaths,
  DispositiveProvisionsFormShape,
  Recipient,
} from '../DispositiveProvisionsForm.types';

function hasCompleteRecipient(recipients: Recipient[]): boolean {
  if (recipients.length >= 2) return true;
  const firstRecipient = first(recipients);
  if (!firstRecipient) return false;

  // if it's saved, don't show the template picker
  if (firstRecipient._dispositiveProvisionId) return true;

  return false;
}

export interface DispositiveProvisionsSelectTemplateProps {
  onSelect?: (templateId: string) => void;
}

export function DispositiveProvisionsSelectTemplate({
  onSelect: onSelectExternal,
}: DispositiveProvisionsSelectTemplateProps) {
  const { householdId } = useDispositiveProvisionsContext();
  const { isTemplateMode } =
    useDispositiveProvisionsTemplateSplitScreenModalContext();

  const { setValue, control } =
    useFormContext<DispositiveProvisionsFormShape>();

  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();
  const [recipients, dispositionScheme, templateId] = useWatch({
    control,
    name: [
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients` satisfies DispositiveProvisionsFormPaths,
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.dispositionScheme` satisfies DispositiveProvisionsFormPaths,
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.templateId` satisfies DispositiveProvisionsFormPaths,
    ],
  });

  const [templateDetails, setTemplateDetails] =
    useState<DispositiveProvisionTemplateDetails | null>(null);

  const shouldSkipDisplay =
    isTemplateMode ||
    dispositionScheme === DispositionScheme.NONE ||
    hasCompleteRecipient(recipients) ||
    templateId;

  const onClickHandler = useCallback(
    (templateDetails: DispositiveProvisionTemplateDetails) => {
      setTemplateDetails(templateDetails);
      if (templateDetails.templateId === NEW_TEMPLATE_SENTINEL) {
        openModal();
      } else {
        onSelectExternal?.(templateDetails.templateId);
        setValue(
          `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.templateId` satisfies DispositiveProvisionsFormPaths,
          templateDetails.templateId
        );
        if (templateDetails.recipients) {
          setValue(
            `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients` satisfies DispositiveProvisionsFormPaths,
            templateDetails.recipients
          );
        }
      }
    },
    [onSelectExternal, openModal, setValue]
  );

  const onCloseCreateModal = useCallback(
    (templateId?: string, recipients?: Recipient[]) => {
      if (!templateId || !recipients) {
        closeModal();
        return;
      }

      onSelectExternal?.(templateId);
      setValue(
        `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.templateId` satisfies DispositiveProvisionsFormPaths,
        templateId
      );
      setValue(
        `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients` satisfies DispositiveProvisionsFormPaths,
        recipients
      );
      closeModal();
    },
    [closeModal, onSelectExternal, setValue]
  );

  if (shouldSkipDisplay) return null;

  return (
    <Callout severity="info-high" sx={{ mb: 2 }}>
      <Stack spacing={1}>
        <Typography
          variant="h6"
          component="div"
          sx={{ color: COLORS.BLUE[900] }}
        >
          Have multiple entities that distribute identically upon death?
        </Typography>
        <Typography variant="subtitle2" sx={{ color: COLORS.BLUE[900] }}>
          Create templates that can be applied across multiple entities to save
          time. Presets for common distribution patterns make template setup
          even easier!
        </Typography>
        <Box>
          <DispositiveProvisionTemplateMenu
            householdId={householdId}
            label="Get started with templates"
            variant="primary"
            fullWidth={false}
            onClick={onClickHandler}
          />
        </Box>
      </Stack>
      {templateDetails && isModalOpen && (
        <DispositiveProvisionsTemplateSplitScreenModal
          isOpen={isModalOpen}
          onClose={onCloseCreateModal}
          templateDetails={templateDetails}
        />
      )}
    </Callout>
  );
}
