import { find, noop, values } from 'lodash';
import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export enum CreateHouseholdFlowStep {
  Loading = 'LOADING',
  /** Used when user can connect/is connected to external data provider integration (Addepar/Black Diamond). */
  FlowPicker = 'FLOW_PICKER',
  /** Used if a user has no households and isn't able to connect to an external data provider integration. */
  OnboardingNoIntegration = 'ONBOARDING_NO_INTEGRATION',
  /** If a user can connect, select the provider they're going to connect to. */
  ConnectSelectProvider = 'COLLECT_SELECT_PROVIDER',
  /** Display the `CreateHouseholdModal` to manually create a client. */
  ClientDataIntake = 'CLIENT_DATA_INTAKE',
  /** Display the `CreateHouseholdModal` with the multi-document uploader. */
  ClientDataIntakeWithAIOnboarding = 'CLIENT_DATA_INTAKE_WITH_AI_ONBOARDING',
  /** Display the after-household-create step. */
  PostHouseholdCreation = 'POST_HOUSEHOLD_CREATION',
  /** Display the short form create entity modal. */
  EntityIntake = 'ENTITY_INTAKE',
  /** Cleanup step; either display the short form entity modal or navigate to the bulk import page. */
  PostIngest = 'POST_INGEST',
}

const FLOW_STEPS = values(CreateHouseholdFlowStep);
export function getCreateHouseholdFlowStep(
  input: string | null
): CreateHouseholdFlowStep | null {
  if (!input) return null;
  const castInput = input as CreateHouseholdFlowStep;
  return find(FLOW_STEPS, (flowStep) => flowStep === castInput) ?? null;
}

export interface CreateHouseholdFlowContextType {
  /** Current step in the flow */
  currentStep: CreateHouseholdFlowStep;
  /** Set the next step of the flow, and whether the flow incorporates a third-party data provider */
  setNextStep: (
    nextStep: CreateHouseholdFlowStep,
    isProviderSubflow?: boolean
  ) => void;
  /** Callback to close the modal */
  onClose: () => void;
  /** Created household ID */
  householdId: string | undefined;
  /** Set created household ID */
  setHouseholdId: (householdId: string | undefined) => void;
}

export const CreateHouseholdFlowContext =
  createContext<CreateHouseholdFlowContextType>({
    currentStep: CreateHouseholdFlowStep.Loading,
    setNextStep: noop,
    onClose: noop,
    householdId: undefined,
    setHouseholdId: noop,
  });

export function useCreateHouseholdFlowContext() {
  return useGuardedContext(
    CreateHouseholdFlowContext,
    'CreateHouseholdFlowContextProvider'
  );
}
