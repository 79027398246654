// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { ClientProfileFragmentDoc, ClientProfileMinimalGrantorRelationshipFragmentDoc, RelationshipFragmentDoc, ClientProfileBasicFragmentDoc } from '../../../clientProfiles/graphql/ClientProfile.generated';
import { SubBrandFragmentDoc } from '../../../admin/branding/graphql/Branding.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientFormDataQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.HouseholdWhereInput>;
}>;


export type ClientFormDataQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, billableKind: Types.HouseholdBillableKind, primaryRelationshipOwner: { __typename?: 'User', id: string, displayName: string }, clientProfiles?: Array<{ __typename?: 'ClientProfile', id: string, firstName: string, lastName: string, email?: string | null, dateOfBirth?: Time | null, isGrantor: boolean, isTrustee: boolean, isPrimary: boolean, isBeneficiary: boolean, displayName: string, legalName: string, middleName?: string | null, suffix?: string | null, relationships?: Array<{ __typename?: 'ClientProfileRelationship', id: string, type: Types.ClientProfileRelationshipType, toClientProfile: { __typename?: 'ClientProfile', id: string } }> | null, address?: { __typename?: 'Address', id: string, cityName?: string | null, stateCode: string } | null }> | null, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, legalName: string, firstName: string }>, subBrand?: { __typename?: 'SubBrand', id: string } | null } | null } | null> | null }, subBrands: { __typename?: 'SubBrandConnection', edges?: Array<{ __typename?: 'SubBrandEdge', node?: { __typename?: 'SubBrand', id: string, displayName: string } | null } | null> | null } };


export const ClientFormDataDocument = gql`
    query ClientFormData($where: HouseholdWhereInput) {
  households(where: $where) {
    edges {
      node {
        id
        billableKind
        primaryRelationshipOwner {
          id
          displayName
        }
        clientProfiles {
          ...ClientProfile
          relationships {
            ...Relationship
          }
        }
        possiblePrimaryClients {
          ...ClientProfileBasic
        }
        subBrand {
          id
        }
      }
    }
  }
  subBrands {
    edges {
      node {
        ...SubBrand
      }
    }
  }
}
    ${ClientProfileFragmentDoc}
${RelationshipFragmentDoc}
${ClientProfileBasicFragmentDoc}
${SubBrandFragmentDoc}`;

/**
 * __useClientFormDataQuery__
 *
 * To run a query within a React component, call `useClientFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientFormDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useClientFormDataQuery(baseOptions?: Apollo.QueryHookOptions<ClientFormDataQuery, ClientFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientFormDataQuery, ClientFormDataQueryVariables>(ClientFormDataDocument, options);
      }
export function useClientFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientFormDataQuery, ClientFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientFormDataQuery, ClientFormDataQueryVariables>(ClientFormDataDocument, options);
        }
export function useClientFormDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientFormDataQuery, ClientFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientFormDataQuery, ClientFormDataQueryVariables>(ClientFormDataDocument, options);
        }
export type ClientFormDataQueryHookResult = ReturnType<typeof useClientFormDataQuery>;
export type ClientFormDataLazyQueryHookResult = ReturnType<typeof useClientFormDataLazyQuery>;
export type ClientFormDataSuspenseQueryHookResult = ReturnType<typeof useClientFormDataSuspenseQuery>;
export type ClientFormDataQueryResult = Apollo.QueryResult<ClientFormDataQuery, ClientFormDataQueryVariables>;
export const Operations = {
  Query: {
    ClientFormData: 'ClientFormData' as const
  }
}