// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { SubBrandFragmentDoc } from '../../../../modules/admin/branding/graphql/Branding.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminBrandingPage_Branding_SubBrand_Fragment = { __typename?: 'SubBrand', id: string, primaryColor: string, secondaryColor: string, dataVisualizationPrimaryColor: string, dataVisualizationSecondaryColor: string, dataVisualizationTertiaryColor?: string | null, dataVisualizationNegativeColor?: string | null, buttonsLinkColor: string, logoURL: string, darkSquareLogoURL?: string | null, darkWideLogoURL?: string | null };

export type AdminBrandingPage_Branding_TenantBranding_Fragment = { __typename?: 'TenantBranding', id: string, primaryColor: string, secondaryColor: string, dataVisualizationPrimaryColor: string, dataVisualizationSecondaryColor: string, dataVisualizationTertiaryColor?: string | null, dataVisualizationNegativeColor?: string | null, buttonsLinkColor: string, logoURL: string, darkSquareLogoURL?: string | null, darkWideLogoURL?: string | null };

export type AdminBrandingPage_BrandingFragment = AdminBrandingPage_Branding_SubBrand_Fragment | AdminBrandingPage_Branding_TenantBranding_Fragment;

export type AdminBrandingPage_TenantBrandingFragment = { __typename?: 'TenantBranding', id: string, primaryColor: string, secondaryColor: string, dataVisualizationPrimaryColor: string, dataVisualizationSecondaryColor: string, dataVisualizationTertiaryColor?: string | null, dataVisualizationNegativeColor?: string | null, buttonsLinkColor: string, logoURL: string, darkSquareLogoURL?: string | null, darkWideLogoURL?: string | null };

export type TenantBrandingQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TenantBrandingQuery = { __typename?: 'Query', tenantInformation: { __typename?: 'TenantInformation', id: string, branding: { __typename?: 'TenantBranding', id: string, primaryColor: string, secondaryColor: string, dataVisualizationPrimaryColor: string, dataVisualizationSecondaryColor: string, dataVisualizationTertiaryColor?: string | null, dataVisualizationNegativeColor?: string | null, buttonsLinkColor: string, logoURL: string, darkSquareLogoURL?: string | null, darkWideLogoURL?: string | null } }, subBrands: { __typename?: 'SubBrandConnection', edges?: Array<{ __typename?: 'SubBrandEdge', node?: { __typename?: 'SubBrand', id: string, displayName: string, primaryColor: string, secondaryColor: string, dataVisualizationPrimaryColor: string, dataVisualizationSecondaryColor: string, dataVisualizationTertiaryColor?: string | null, dataVisualizationNegativeColor?: string | null, buttonsLinkColor: string, logoURL: string, darkSquareLogoURL?: string | null, darkWideLogoURL?: string | null } | null } | null> | null } };

export type UpdateBrandingMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateTenantBrandingInput;
}>;


export type UpdateBrandingMutation = { __typename?: 'Mutation', updateTenantBranding: { __typename?: 'TenantBranding', id: string, primaryColor: string, secondaryColor: string, dataVisualizationPrimaryColor: string, dataVisualizationSecondaryColor: string, dataVisualizationTertiaryColor?: string | null, dataVisualizationNegativeColor?: string | null, buttonsLinkColor: string, logoURL: string, darkSquareLogoURL?: string | null, darkWideLogoURL?: string | null } };

export type UpdateSubBrandBrandingMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateSubBrandInput;
}>;


export type UpdateSubBrandBrandingMutation = { __typename?: 'Mutation', updateSubBrand: { __typename?: 'SubBrand', id: string, primaryColor: string, secondaryColor: string, dataVisualizationPrimaryColor: string, dataVisualizationSecondaryColor: string, dataVisualizationTertiaryColor?: string | null, dataVisualizationNegativeColor?: string | null, buttonsLinkColor: string, logoURL: string, darkSquareLogoURL?: string | null, darkWideLogoURL?: string | null } };

export type UploadFirmLogoMutationVariables = Types.Exact<{
  input: Types.CreateTenantBrandingLogoInput;
}>;


export type UploadFirmLogoMutation = { __typename?: 'Mutation', uploadTenantBrandingLogo: { __typename?: 'TenantBrandingLogoUpload', uploadMethod: string, uploadURL: string, uploadURLExpiresInSeconds: number } };

export const AdminBrandingPage_BrandingFragmentDoc = gql`
    fragment AdminBrandingPage_Branding on Branded {
  id
  primaryColor
  secondaryColor
  dataVisualizationPrimaryColor
  dataVisualizationSecondaryColor
  dataVisualizationTertiaryColor
  dataVisualizationNegativeColor
  buttonsLinkColor
  logoURL
  darkSquareLogoURL
  darkWideLogoURL
}
    `;
export const AdminBrandingPage_TenantBrandingFragmentDoc = gql`
    fragment AdminBrandingPage_TenantBranding on TenantBranding {
  id
  primaryColor
  secondaryColor
  dataVisualizationPrimaryColor
  dataVisualizationSecondaryColor
  dataVisualizationTertiaryColor
  dataVisualizationNegativeColor
  buttonsLinkColor
  logoURL
  darkSquareLogoURL
  darkWideLogoURL
}
    `;
export const TenantBrandingDocument = gql`
    query TenantBranding {
  tenantInformation {
    id
    branding {
      ...AdminBrandingPage_TenantBranding
      ...AdminBrandingPage_Branding
    }
  }
  subBrands {
    edges {
      node {
        ...SubBrand
        ...AdminBrandingPage_Branding
      }
    }
  }
}
    ${AdminBrandingPage_TenantBrandingFragmentDoc}
${AdminBrandingPage_BrandingFragmentDoc}
${SubBrandFragmentDoc}`;

/**
 * __useTenantBrandingQuery__
 *
 * To run a query within a React component, call `useTenantBrandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantBrandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantBrandingQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantBrandingQuery(baseOptions?: Apollo.QueryHookOptions<TenantBrandingQuery, TenantBrandingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantBrandingQuery, TenantBrandingQueryVariables>(TenantBrandingDocument, options);
      }
export function useTenantBrandingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantBrandingQuery, TenantBrandingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantBrandingQuery, TenantBrandingQueryVariables>(TenantBrandingDocument, options);
        }
export function useTenantBrandingSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TenantBrandingQuery, TenantBrandingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TenantBrandingQuery, TenantBrandingQueryVariables>(TenantBrandingDocument, options);
        }
export type TenantBrandingQueryHookResult = ReturnType<typeof useTenantBrandingQuery>;
export type TenantBrandingLazyQueryHookResult = ReturnType<typeof useTenantBrandingLazyQuery>;
export type TenantBrandingSuspenseQueryHookResult = ReturnType<typeof useTenantBrandingSuspenseQuery>;
export type TenantBrandingQueryResult = Apollo.QueryResult<TenantBrandingQuery, TenantBrandingQueryVariables>;
export const UpdateBrandingDocument = gql`
    mutation UpdateBranding($id: ID!, $input: UpdateTenantBrandingInput!) {
  updateTenantBranding(id: $id, input: $input) {
    ...AdminBrandingPage_TenantBranding
  }
}
    ${AdminBrandingPage_TenantBrandingFragmentDoc}`;
export type UpdateBrandingMutationFn = Apollo.MutationFunction<UpdateBrandingMutation, UpdateBrandingMutationVariables>;

/**
 * __useUpdateBrandingMutation__
 *
 * To run a mutation, you first call `useUpdateBrandingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandingMutation, { data, loading, error }] = useUpdateBrandingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBrandingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandingMutation, UpdateBrandingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBrandingMutation, UpdateBrandingMutationVariables>(UpdateBrandingDocument, options);
      }
export type UpdateBrandingMutationHookResult = ReturnType<typeof useUpdateBrandingMutation>;
export type UpdateBrandingMutationResult = Apollo.MutationResult<UpdateBrandingMutation>;
export type UpdateBrandingMutationOptions = Apollo.BaseMutationOptions<UpdateBrandingMutation, UpdateBrandingMutationVariables>;
export const UpdateSubBrandBrandingDocument = gql`
    mutation UpdateSubBrandBranding($input: AugmentedUpdateSubBrandInput!) {
  updateSubBrand(input: $input) {
    id
    ...AdminBrandingPage_Branding
  }
}
    ${AdminBrandingPage_BrandingFragmentDoc}`;
export type UpdateSubBrandBrandingMutationFn = Apollo.MutationFunction<UpdateSubBrandBrandingMutation, UpdateSubBrandBrandingMutationVariables>;

/**
 * __useUpdateSubBrandBrandingMutation__
 *
 * To run a mutation, you first call `useUpdateSubBrandBrandingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubBrandBrandingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubBrandBrandingMutation, { data, loading, error }] = useUpdateSubBrandBrandingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSubBrandBrandingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubBrandBrandingMutation, UpdateSubBrandBrandingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubBrandBrandingMutation, UpdateSubBrandBrandingMutationVariables>(UpdateSubBrandBrandingDocument, options);
      }
export type UpdateSubBrandBrandingMutationHookResult = ReturnType<typeof useUpdateSubBrandBrandingMutation>;
export type UpdateSubBrandBrandingMutationResult = Apollo.MutationResult<UpdateSubBrandBrandingMutation>;
export type UpdateSubBrandBrandingMutationOptions = Apollo.BaseMutationOptions<UpdateSubBrandBrandingMutation, UpdateSubBrandBrandingMutationVariables>;
export const UploadFirmLogoDocument = gql`
    mutation UploadFirmLogo($input: CreateTenantBrandingLogoInput!) {
  uploadTenantBrandingLogo(input: $input) {
    uploadMethod
    uploadURL
    uploadURLExpiresInSeconds
  }
}
    `;
export type UploadFirmLogoMutationFn = Apollo.MutationFunction<UploadFirmLogoMutation, UploadFirmLogoMutationVariables>;

/**
 * __useUploadFirmLogoMutation__
 *
 * To run a mutation, you first call `useUploadFirmLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFirmLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFirmLogoMutation, { data, loading, error }] = useUploadFirmLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadFirmLogoMutation(baseOptions?: Apollo.MutationHookOptions<UploadFirmLogoMutation, UploadFirmLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFirmLogoMutation, UploadFirmLogoMutationVariables>(UploadFirmLogoDocument, options);
      }
export type UploadFirmLogoMutationHookResult = ReturnType<typeof useUploadFirmLogoMutation>;
export type UploadFirmLogoMutationResult = Apollo.MutationResult<UploadFirmLogoMutation>;
export type UploadFirmLogoMutationOptions = Apollo.BaseMutationOptions<UploadFirmLogoMutation, UploadFirmLogoMutationVariables>;
export const Operations = {
  Query: {
    TenantBranding: 'TenantBranding' as const
  },
  Mutation: {
    UpdateBranding: 'UpdateBranding' as const,
    UpdateSubBrandBranding: 'UpdateSubBrandBranding' as const,
    UploadFirmLogo: 'UploadFirmLogo' as const
  },
  Fragment: {
    AdminBrandingPage_Branding: 'AdminBrandingPage_Branding' as const,
    AdminBrandingPage_TenantBranding: 'AdminBrandingPage_TenantBranding' as const
  }
}