import { Box, Typography } from '@mui/material';
import MUIStep from '@mui/material/Step';
import MUIStepButton from '@mui/material/StepButton';
import MUIStepIcon from '@mui/material/StepIcon';
import MUIStepper, {
  StepperProps as MUIStepperProps,
} from '@mui/material/Stepper';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';

interface SegmentProps {
  display: string;
  onClick?: () => void;
  isComplete: boolean;
}

interface StepTrackerProps {
  segments: SegmentProps[];
  activeSegmentIndex: number;
}

const useSegmentStyles = makeStyles<{ isComplete: boolean; content: string }>()(
  (_theme, { isComplete, content }) => ({
    text: {
      color: isComplete ? COLORS.NAVY[600] : COLORS.GRAY[500],
      fontWeight: isComplete ? FONT_WEIGHTS.bold : FONT_WEIGHTS.regular,
      '&::before': {
        display: 'block',
        content: `"${content}"`,
        fontWeight: FONT_WEIGHTS.bold,
        height: 0,
        overflow: 'hidden',
        visibility: 'hidden',
      },
    },
  })
);

function CircleIcon({ idx, isComplete }: { idx: number; isComplete: boolean }) {
  return (
    <Box
      width="20px"
      height="20px"
      borderRadius="50%"
      bgcolor={isComplete ? COLORS.ORANGE[400] : COLORS.GRAY[400]}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography color={COLORS.PRIMITIVES.WHITE} variant="label">
        {idx + 1}
      </Typography>
    </Box>
  );
}

function Segment({
  display,
  onClick,
  isComplete,
  idx,
}: SegmentProps & { idx: number }) {
  const { classes, cx } = useSegmentStyles({
    isComplete,
    content: display,
  });

  return (
    <MUIStepButton
      color="inherit"
      onClick={onClick}
      icon={
        <MUIStepIcon icon={<CircleIcon idx={idx} isComplete={isComplete} />} />
      }
    >
      <Typography variant="body1" className={cx(classes.text)}>
        {display}
      </Typography>
    </MUIStepButton>
  );
}

export function StepTracker(
  props: StepTrackerProps & Partial<MUIStepperProps>
) {
  const { segments, activeSegmentIndex, ...muiStepperProps } = props;

  return (
    <Box>
      <MUIStepper
        nonLinear
        activeStep={activeSegmentIndex}
        {...muiStepperProps}
      >
        {segments.map((segment, idx) => (
          <MUIStep
            key={`${segment.display}-${idx}`}
            completed={segment.isComplete}
          >
            <Segment
              display={segment.display}
              onClick={segment.onClick}
              isComplete={segment.isComplete}
              idx={idx}
            />
          </MUIStep>
        ))}
      </MUIStepper>
    </Box>
  );
}
