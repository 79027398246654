import Decimal from 'decimal.js';
import { first } from 'lodash';

import { getCreateDraftEntityInput } from '@/modules/entities/draftEntities/getCreateDraftEntityInput';
import { getTypeOrUndefined } from '@/modules/entities/EntitySubforms/utils/shared/common.utils';
import {
  AugmentedCreateEntityInput,
  AugmentedUpdateEstateWaterfallHypotheticalTransferInput,
  AugmentedUpdateEstateWaterfallInput,
} from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { HypotheticalTransferModalQuery } from '../graphql/HypotheticalTransferModal.generated';
import {
  AnnuallyRecurringValue,
  HypotheticalTransferFormModalShape,
  HypotheticalTransferUpdateEstateWaterfallPayload,
} from '../HypotheticalTransferModal.types';
import {
  getHypotheticalTransferDestination,
  getHypotheticalTransferSource,
  getHypotheticalTransferTaxDetails,
} from '../HypotheticalTransferModal.utils';

export function getUpdatePayload(
  formData: HypotheticalTransferFormModalShape,
  waterfallData: HypotheticalTransferModalQuery | undefined,
  transferId: string,
  householdId: string
): HypotheticalTransferUpdateEstateWaterfallPayload {
  const waterfall = first(getNodes(waterfallData?.estateWaterfalls));

  const destination = getHypotheticalTransferDestination(formData);
  const source = getHypotheticalTransferSource(formData);
  const { transferTaxKind, giftingGrantorIDs } =
    getHypotheticalTransferTaxDetails(formData);

  if (!transferId) {
    return { message: 'Could not update transfer without an ID' };
  } else if (!waterfall) {
    return { message: 'Could not get waterfall details' };
  } else if (!destination) {
    return { message: 'Could not get destination details' };
  } else if (!source) {
    return { message: 'Could not get source details' };
  } else if (!transferTaxKind) {
    return { message: 'Could not get transfer tax kind' };
  }

  let startYear: number | undefined = undefined;
  let endYear: number | undefined = undefined;
  if (formData.transferFrequency === AnnuallyRecurringValue.true) {
    startYear = getTypeOrUndefined<number>(formData.transferStartYear);
    endYear = getTypeOrUndefined<number>(formData.transferEndYear);
  } else {
    startYear = getTypeOrUndefined<number>(formData.transferStartYear);
    endYear = getTypeOrUndefined<number>(formData.transferStartYear);
  }

  let withDestinationEntity: undefined | AugmentedCreateEntityInput = undefined;
  if (formData.transferToNewDraft && formData.draftEntityType) {
    withDestinationEntity = getCreateDraftEntityInput({
      draftEntityType: formData.draftEntityType,
      draftEntityName: formData.draftEntityName,
      draftEntityGrantorId: formData.draftEntityGrantorId,
      householdId,
    });
  }

  const updatedTransfer: AugmentedUpdateEstateWaterfallHypotheticalTransferInput =
    {
      id: transferId,
      update: {
        ...destination,
        ...source,
        transferTaxKind,
        name: getTypeOrUndefined<string>(formData.transferName),
        transferValue: getTypeOrUndefined<Decimal>(formData.transferAmount),
        taxableValue: getTypeOrUndefined<Decimal>(formData.taxableValue),
        taxDiscount: formData._discountRateToggle
          ? getTypeOrUndefined<Decimal>(formData.taxDiscountRate)
          : undefined,
        isGstGift: !!formData.transferGstGift,
        assetGrowthReturn: getTypeOrUndefined<Decimal>(
          formData.transferGrowthRate
        ),
        startYear,
        endYear,
        clearGiftingGrantors: true,
        addGiftingGrantorIDs: giftingGrantorIDs,
      },
    };

  if (formData.transferToNewDraft) {
    updatedTransfer.update.clearDestinationEntity = true;
    updatedTransfer.update.clearDestinationIndividual = true;
    updatedTransfer.update.clearDestinationOrganization = true;
    updatedTransfer.withDestinationEntity = withDestinationEntity;
  }

  const output: AugmentedUpdateEstateWaterfallInput = {
    id: waterfall.id,
    update: {},
    updateHypotheticalTransfers: [updatedTransfer],
  };

  return { input: output };
}
