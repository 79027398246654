import {
  ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES,
  AssetIntegrationProviders,
} from '@/modules/assetProviderIntegrations/shared/constants';

import { CSVUploadFormShape } from './ImportCSVValuationsPage.types';

export const DEFAULT_VALUATION_ATTRIBUTION_NAME =
  ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES[
    AssetIntegrationProviders.CSV_IMPORT
  ];

export const defaultFormValues: CSVUploadFormShape = {
  fileId: null,
  attributionName: '',
  accountIdsByHousehold: {},
};
