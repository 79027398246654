import { PersonalAccountSummaryData } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountDetails.types';

import { EntityDetailsData } from '../entityDetails.types';
import {
  getControllingPartyNameFromFragment,
  getOwnerNameFromFragment,
  getSharedSummaryProperties,
} from './accountsCardUtils';

export function getJointPersonalAccountSummaryProperties({
  entity,
}: EntityDetailsData): PersonalAccountSummaryData {
  if (entity.subtype.__typename !== 'JointPersonalAccount') {
    throw new Error('Invalid personal account type');
  }

  return {
    ...getSharedSummaryProperties(entity),
    ownerNames: entity.subtype.owners?.map(getOwnerNameFromFragment) ?? [],
    effectiveDate: entity.subtype.effectiveDate || undefined,
    beneficiaries: entity.subtype.beneficiaries || [],
    controllingPartyNames:
      entity.subtype.controllingParties?.map(
        getControllingPartyNameFromFragment
      ) ?? [],
    accountDetailsItems: [],
  };
}
