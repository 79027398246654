import { PopperContent } from '@/components/poppers/PopperContent';

export function AnnualExclusionCurrentYear() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body={
        'This is the total amount of annual exclusion gifts made in the current year.'
      }
    />
  );
}
