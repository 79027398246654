import { useCallback } from 'react';

import { compose } from '@/utils/reducerUtils';

import { applyAutoLayout } from '../state/autoLayout';
import { FamilyTreeActionFn, InitializeNodePositionsAction } from '../types';

export function useInitializeNodePositionsAction() {
  return useCallback<FamilyTreeActionFn<InitializeNodePositionsAction>>(
    (state, _action, queueEffect) => {
      queueEffect({ type: 'fitViewEffect' });
      queueEffect({ type: 'completeInitializationEffect' });

      return compose(applyAutoLayout())(state);
    },
    []
  );
}
