import { useCallback } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { diagnostics } from '@/utils/diagnostics';

import { getUpdateWaterfallVariables } from '../EstateWaterfall.utils';
import { useEstateWaterfallUpdateNodesLayoutMutation } from '../graphql/EstateWaterfall.generated';
import { EditMutationEffect, EstateWaterfallEffectFn } from '../types';

export function useEditMutationEffect(): EstateWaterfallEffectFn<EditMutationEffect> {
  const { showFeedback } = useFeedback();
  const [updateWaterfall] = useEstateWaterfallUpdateNodesLayoutMutation({
    onQueryUpdated() {
      // We don't want to perform any refetches as this is done optimistically
      return false;
    },
  });

  return useCallback<EstateWaterfallEffectFn<EditMutationEffect>>(
    (state, _effect, dispatch) => {
      const waterfallId = state.waterfall.id;

      void updateWaterfall({
        variables: {
          ...getUpdateWaterfallVariables(state.waterfall, state.nodes),
          refetchWaterfall: false,
        },
      }).catch((err) => {
        showFeedback(`Something went wrong. Please try again.`);
        diagnostics.error(
          `couldn't save estate waterfall configuration`,
          err as Error,
          { waterfallId }
        );
      });

      dispatch({
        type: 'UPDATE_WATERFALL',
        waterfall: state.waterfall,
        primaryClients: state.primaryClients,
        isTwoClientHousehold: state.isTwoClientHousehold,
        featureFlags: state.featureFlags,
        isEmptyWaterfall: state.isEmptyWaterfall,
        isFilteredWaterfall: state.isFilteredWaterfall,
        visibleNodeIds: state.visibleNodeIds,
        hiddenNodeIds: state.hiddenNodeIds,
        initWaterfallNodeIds: state.initWaterfallNodeIds,
        presentationMode: state.presentationMode,
      });
    },
    [showFeedback, updateWaterfall]
  );
}
