import { Grid } from '@/components/layout/Grid';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';

import { SlideBodyWithHeader } from '../SlideBodyWithHeader';
import { GiftOverviewScenarios } from './GiftOverviewScenarios';
import { GridOverviewStartingAssets } from './GridOverviewStartingAssets';

interface GiftOverviewProps {
  proposal: ProposalFragment;
}

export function GiftOverview({ proposal }: GiftOverviewProps) {
  return (
    <SlideBodyWithHeader header="Scenario overview">
      <Grid container direction="row">
        <Grid item sm={5}>
          <GridOverviewStartingAssets proposal={proposal} />
        </Grid>
        <Grid paddingLeft={10} item sm={7}>
          <GiftOverviewScenarios proposal={proposal} />
        </Grid>
      </Grid>
    </SlideBodyWithHeader>
  );
}
