import { Box } from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';

import { Link } from '@/components/form/baseInputs/Link';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareTypeaheadSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';
import { CardWithHeader } from '@/components/layout/Card/CardWithHeader';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import { AddNewAnythingModal } from '@/modules/common/AddNewAnythingModal/AddNewAnythingModal';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { getPreviousDay } from '@/utils/dateUtils';

import {
  HypotheticalSaleLoanFormPaths,
  HypotheticalSaleLoanFormShape,
} from '../EstateWaterfallHypotheticalSaleLoanModal.types';
import { useSaleLoanFormContext } from './context/SaleLoanForm.context';

export function SaleLoanBasicInformationForm() {
  const [isAddRecipientModalOpen, setIsAddRecipientModalOpen] = useState(false);
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const { householdId } = useHouseholdDetailsContext();
  const { possibleSellerLenders, possibleRecipients } =
    useSaleLoanFormContext();
  return (
    <>
      {householdId && (
        <AddNewAnythingModal
          handleClose={() => setIsAddRecipientModalOpen(false)}
          isOpen={isAddRecipientModalOpen}
          householdId={householdId}
          addableTypes={['individual', 'entity', 'draftEntity']}
        />
      )}
      <Box>
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <FormAwareTypeaheadSelectInput<HypotheticalSaleLoanFormShape>
              fieldName={
                'sellerId' as const satisfies HypotheticalSaleLoanFormPaths
              }
              label="Seller"
              control={control}
              groupBy={(o) => o.groupName ?? ''}
              required
              options={possibleSellerLenders}
            />
          </FormLayoutItem>
        </FormLayoutRow>

        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <FormAwareTypeaheadSelectInput<HypotheticalSaleLoanFormShape>
              fieldName={
                'recipientId' as const satisfies HypotheticalSaleLoanFormPaths
              }
              label="Recipient"
              control={control}
              groupBy={(o) => o.groupName ?? ''}
              required
              options={possibleRecipients}
              addNewOption={{
                text: 'Add new recipient',
                onClick: () => setIsAddRecipientModalOpen(true),
              }}
            />
          </FormLayoutItem>
        </FormLayoutRow>

        <FormLayoutRow>
          <FormLayoutItem width={4}>
            <FormAwareDatePickerInput<HypotheticalSaleLoanFormShape>
              fieldName={
                'startDate' as const satisfies HypotheticalSaleLoanFormPaths
              }
              label="Start date"
              control={control}
              required
              minDate={getPreviousDay(new Date())}
            />
          </FormLayoutItem>
          <FormLayoutItem width={4}>
            <FormAwareFormattedNumberInput<HypotheticalSaleLoanFormShape>
              fieldName={
                'termLength' as const satisfies HypotheticalSaleLoanFormPaths
              }
              label="Term length (years)"
              validation={{
                min: (value) => {
                  if (isNil(value)) return;
                  const number = Number(value);
                  if (number < 2) {
                    return 'Must be at least 2 years';
                  }
                  if (number > 30) {
                    return 'Must be no more than 30 years';
                  }

                  return undefined;
                },
              }}
              control={control}
              required
              decimalScale={0}
              fixedDecimalScale
            />
          </FormLayoutItem>
          <FormLayoutItem width={4}>
            <FormAwarePercentInput<HypotheticalSaleLoanFormShape>
              fieldName={
                'applicableInterestRate' as const satisfies HypotheticalSaleLoanFormPaths
              }
              label="Applicable AFR"
              control={control}
              required
              decimalScale={2}
              fixedDecimalScale
              isDecimalJSInput
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <Callout severity="info-high">
              <strong>Must use appropriate AFR rate</strong>
              <p>
                Reference the table below to determine what rate is applicable
                to the term length specified above
              </p>
              <Link
                href="https://www.irs.gov/applicable-federal-rates"
                target="_blank"
                external
                display="View IRS rate table"
              />
            </Callout>
          </FormLayoutItem>
        </FormLayoutRow>

        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <CardWithHeader title="If first death occurs before term end">
              <FormAwareRadioGroup<HypotheticalSaleLoanFormShape>
                fieldName={
                  'pretermGrantorDeathHandling' as const satisfies HypotheticalSaleLoanFormPaths
                }
                hideLabel
                label="If first death occurs before term end"
                required
                sx={{ ':not(:last-child)': { mb: 1 } }}
                control={control}
                row={false}
                options={[
                  {
                    label: 'Sale is repaid at time of death',
                    helpText: 'No tax event is triggered',
                    value: 'repaid',
                  },
                  {
                    label: 'Sale is canceled',
                    helpText: 'Remaining balance included in taxable estate',
                    value: 'canceled',
                  },
                ]}
              />
            </CardWithHeader>
          </FormLayoutItem>
        </FormLayoutRow>
      </Box>
    </>
  );
}
