// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GrowthProfilesTable_GrowthProfileFragment = { __typename?: 'GrowthProfile', id: string, displayName: string, exemptionGrowthRate: Percent, isDefault: boolean, willExemptionSunset: boolean, assetGrowthReturn?: Percent | null, growthType: Types.GrowthProfileGrowthType, user: { __typename?: 'User', id: string, displayName: string }, estateWaterfalls: { __typename?: 'EstateWaterfallConnection', totalCount: number, edges?: Array<{ __typename?: 'EstateWaterfallEdge', node?: { __typename?: 'EstateWaterfall', id: string } | null } | null> | null } };

export type GrowthProfilesTable_EstateWaterfallConnectionFragment = { __typename?: 'EstateWaterfallConnection', totalCount: number, edges?: Array<{ __typename?: 'EstateWaterfallEdge', node?: { __typename?: 'EstateWaterfall', id: string } | null } | null> | null };

export type GrowthProfilesTableQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.GrowthProfileWhereInput>;
}>;


export type GrowthProfilesTableQuery = { __typename?: 'Query', growthProfiles: { __typename?: 'GrowthProfileConnection', totalCount: number, edges?: Array<{ __typename?: 'GrowthProfileEdge', node?: { __typename?: 'GrowthProfile', id: string, displayName: string, exemptionGrowthRate: Percent, isDefault: boolean, willExemptionSunset: boolean, assetGrowthReturn?: Percent | null, growthType: Types.GrowthProfileGrowthType, user: { __typename?: 'User', id: string, displayName: string }, estateWaterfalls: { __typename?: 'EstateWaterfallConnection', totalCount: number, edges?: Array<{ __typename?: 'EstateWaterfallEdge', node?: { __typename?: 'EstateWaterfall', id: string } | null } | null> | null } } | null } | null> | null } };

export const GrowthProfilesTable_EstateWaterfallConnectionFragmentDoc = gql`
    fragment GrowthProfilesTable_EstateWaterfallConnection on EstateWaterfallConnection {
  totalCount
  edges {
    node {
      id
    }
  }
}
    `;
export const GrowthProfilesTable_GrowthProfileFragmentDoc = gql`
    fragment GrowthProfilesTable_GrowthProfile on GrowthProfile {
  id
  displayName
  exemptionGrowthRate
  user {
    id
    displayName
  }
  isDefault
  willExemptionSunset
  assetGrowthReturn
  growthType
  estateWaterfalls {
    ...GrowthProfilesTable_EstateWaterfallConnection
  }
}
    ${GrowthProfilesTable_EstateWaterfallConnectionFragmentDoc}`;
export const GrowthProfilesTableDocument = gql`
    query GrowthProfilesTable($where: GrowthProfileWhereInput) {
  growthProfiles(where: $where) {
    totalCount
    edges {
      node {
        ...GrowthProfilesTable_GrowthProfile
      }
    }
  }
}
    ${GrowthProfilesTable_GrowthProfileFragmentDoc}`;

/**
 * __useGrowthProfilesTableQuery__
 *
 * To run a query within a React component, call `useGrowthProfilesTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGrowthProfilesTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGrowthProfilesTableQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGrowthProfilesTableQuery(baseOptions?: Apollo.QueryHookOptions<GrowthProfilesTableQuery, GrowthProfilesTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GrowthProfilesTableQuery, GrowthProfilesTableQueryVariables>(GrowthProfilesTableDocument, options);
      }
export function useGrowthProfilesTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GrowthProfilesTableQuery, GrowthProfilesTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GrowthProfilesTableQuery, GrowthProfilesTableQueryVariables>(GrowthProfilesTableDocument, options);
        }
export function useGrowthProfilesTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GrowthProfilesTableQuery, GrowthProfilesTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GrowthProfilesTableQuery, GrowthProfilesTableQueryVariables>(GrowthProfilesTableDocument, options);
        }
export type GrowthProfilesTableQueryHookResult = ReturnType<typeof useGrowthProfilesTableQuery>;
export type GrowthProfilesTableLazyQueryHookResult = ReturnType<typeof useGrowthProfilesTableLazyQuery>;
export type GrowthProfilesTableSuspenseQueryHookResult = ReturnType<typeof useGrowthProfilesTableSuspenseQuery>;
export type GrowthProfilesTableQueryResult = Apollo.QueryResult<GrowthProfilesTableQuery, GrowthProfilesTableQueryVariables>;
export const Operations = {
  Query: {
    GrowthProfilesTable: 'GrowthProfilesTable' as const
  },
  Fragment: {
    GrowthProfilesTable_GrowthProfile: 'GrowthProfilesTable_GrowthProfile' as const,
    GrowthProfilesTable_EstateWaterfallConnection: 'GrowthProfilesTable_EstateWaterfallConnection' as const
  }
}