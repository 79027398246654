import { TypeaheadSelectInputOption } from '@/components/form/baseInputs/inputTypes';

import { EntityOption, getEntityOptions } from '../../inputs/entityOptions';
import { EntityType } from '../../types/EntityType';

function getSelectOptionsFromEntityOptions(
  entityOptions: EntityOption[],
  groupName: string
): TypeaheadSelectInputOption<EntityType>[] {
  return entityOptions.map((e) => ({
    display: e.label,
    value: e.value,
    disabled: e.disabled,
    groupName,
  }));
}

export function getEntityShortFormOptions(): TypeaheadSelectInputOption<EntityType>[] {
  const { trusts, businessEntities, charitableEntities, personalAccounts } =
    getEntityOptions();

  return [
    ...getSelectOptionsFromEntityOptions(trusts, 'Personal & family trusts'),
    ...getSelectOptionsFromEntityOptions(
      personalAccounts,
      'Personal & family accounts'
    ),
    ...getSelectOptionsFromEntityOptions(
      charitableEntities,
      'Charitable entities'
    ),
    ...getSelectOptionsFromEntityOptions(businessEntities, 'Business entities'),
  ];
}
