import { createContext, useContext } from 'react';

export interface CreateEntityShortFormContextType {
  isShortForm: boolean;
}

export const CreateEntityShortFormContext =
  createContext<CreateEntityShortFormContextType>({
    isShortForm: false,
  });

export function useIsShortForm(): boolean {
  const context = useContext(CreateEntityShortFormContext);
  if (!context) {
    return false;
  }
  return context.isShortForm;
}
