// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadComparisonMutationVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
  pathToPrint: Types.Scalars['String']['input'];
}>;


export type DownloadComparisonMutation = { __typename?: 'Mutation', print: { __typename?: 'File', id: string, download: { __typename?: 'FileDownload', downloadURL: string } } };


export const DownloadComparisonDocument = gql`
    mutation DownloadComparison($householdId: ID!, $pathToPrint: String!) {
  print(input: {householdID: $householdId, relativePath: $pathToPrint}) {
    id
    download {
      downloadURL
    }
  }
}
    `;
export type DownloadComparisonMutationFn = Apollo.MutationFunction<DownloadComparisonMutation, DownloadComparisonMutationVariables>;

/**
 * __useDownloadComparisonMutation__
 *
 * To run a mutation, you first call `useDownloadComparisonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadComparisonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadComparisonMutation, { data, loading, error }] = useDownloadComparisonMutation({
 *   variables: {
 *      householdId: // value for 'householdId'
 *      pathToPrint: // value for 'pathToPrint'
 *   },
 * });
 */
export function useDownloadComparisonMutation(baseOptions?: Apollo.MutationHookOptions<DownloadComparisonMutation, DownloadComparisonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadComparisonMutation, DownloadComparisonMutationVariables>(DownloadComparisonDocument, options);
      }
export type DownloadComparisonMutationHookResult = ReturnType<typeof useDownloadComparisonMutation>;
export type DownloadComparisonMutationResult = Apollo.MutationResult<DownloadComparisonMutation>;
export type DownloadComparisonMutationOptions = Apollo.BaseMutationOptions<DownloadComparisonMutation, DownloadComparisonMutationVariables>;
export const Operations = {
  Mutation: {
    DownloadComparison: 'DownloadComparison' as const
  }
}