import { UnreachableError } from '@/utils/errors';
import { FlowChartGraph } from '@/utils/graphology/FlowChartGraph';

import { BusinessEntityKinds } from '../constants';
import {
  EntityGraphAttributes,
  EntityGraphBuilderFn,
  EntityGraphEdgeAttributes,
  EntityGraphNodeAttributes,
} from '../types';
import { $getSupportedEntityNodeTypename } from '../utils/nodes';
import { normalizeById } from '../utils/normalize';
import {
  addBeneficiaries,
  createBeneficiaryEdge,
  createBeneficiaryNode,
} from './beneficiaries';
import { entitySubtypeToNodeCategorization } from './constants';
import { addGrantorBusinessEntitiesSummary } from './entities/businessEntities';
import { addCLT } from './entities/clt';
import { addCRT } from './entities/crt';
import { addGRAT } from './entities/grat';
import { addGrantors } from './grantors';
import { entitySubtypeToTile } from './tiles';

export const createEntityGraph: EntityGraphBuilderFn = (props) => {
  const { entities, possiblePrimaryClients, orientation } = props;
  const byId = normalizeById(props);
  const graph = new FlowChartGraph<
    EntityGraphNodeAttributes,
    EntityGraphEdgeAttributes,
    EntityGraphAttributes
  >();

  graph.setAttribute('byId', byId);
  graph.setAttribute('orientation', orientation);

  const excludedEntityKinds = new Set([...BusinessEntityKinds]);

  entities.forEach((entity) => {
    // We first always add a node for the current entity, all other connections
    // traverse outwards from the entity level

    if (excludedEntityKinds.has(entity.kind)) return;

    const supportedEntityTypename = $getSupportedEntityNodeTypename(
      entity.subtype.__typename!
    );
    const getEntityTile = entitySubtypeToTile[supportedEntityTypename];
    const entityTile = getEntityTile(entity);
    graph.addNodeSafe(entityTile.id, {
      node: entityTile,
      data: entity,
      categorizationType:
        entitySubtypeToNodeCategorization[supportedEntityTypename],
    });

    switch (entity.subtype.__typename) {
      case 'GRATTrust': {
        addGRAT(graph, entity);
        return;
      }

      case 'ILITTrust':
      case 'IrrevocableTrust':
      case 'RevocableTrust':
      case 'QPRTTrust': {
        const { beneficiaries, grantors } = entity.subtype;
        addBeneficiaries(graph, entity.id, beneficiaries);
        addGrantors(graph, entity.id, grantors);
        return;
      }

      case 'SLATTrust': {
        const { lifetimeBeneficiaries, remainderBeneficiaries, grantor } =
          entity.subtype;
        addBeneficiaries(graph, entity.id, lifetimeBeneficiaries, {
          data: { edgeLabel: { label: 'Lifetime' } },
        });
        addGrantors(graph, entity.id, [grantor]);

        // Iterate beneficiaries just added, add remainder out edges for each
        // See visualization here: https://withluminary.slack.com/files/U04N61VPV71/F058NQGRXU3/img_3743.jpg
        lifetimeBeneficiaries?.forEach((lifetimeBeneficiary) => {
          const lbNode = createBeneficiaryNode(graph, lifetimeBeneficiary);
          if (!lbNode || !lifetimeBeneficiary) return;

          remainderBeneficiaries?.forEach((remainderBeneficiary) => {
            const rbNode = createBeneficiaryNode(graph, remainderBeneficiary);
            if (!rbNode || !remainderBeneficiary) return;
            const source = lbNode.node.id;
            const target = rbNode.node.id;

            graph.addNodeSafe(rbNode.node.id, rbNode);
            const edge = createBeneficiaryEdge(graph, remainderBeneficiary, {
              source,
              target,
              data: {
                edgeLabel: {
                  label: 'Remainder',
                },
              },
            });
            graph.addEdgeSafe(source, target, {
              edge,
              type: 'remainder-beneficiary',
            });
          });
        });
        return;
      }

      case 'RetirementPersonalAccount':
      case 'QualifiedTuitionPersonalAccount':
      case 'CustodialPersonalAccount':
      case 'IndividualPersonalAccount': {
        const { beneficiaries, owner } = entity.subtype;
        addBeneficiaries(graph, entity.id, beneficiaries);
        addGrantors(graph, entity.id, [owner]);
        return;
      }

      case 'JointPersonalAccount': {
        const { beneficiaries, owners } = entity.subtype;
        addBeneficiaries(graph, entity.id, beneficiaries);
        addGrantors(graph, entity.id, owners);
        return;
      }

      case 'DonorAdvisedFund':
      case 'PrivateFoundation': {
        const { beneficiaries, donors } = entity.subtype;
        addBeneficiaries(graph, entity.id, beneficiaries);
        addGrantors(graph, entity.id, donors);
        return;
      }

      case 'CLTTrust': {
        addCLT(graph, entity);
        return;
      }

      case 'CRTTrust': {
        addCRT(graph, entity);
        return;
      }

      // TODO: LUM-1848 implement business entities
      case 'SoleProprietorshipBusinessEntity':
      case 'CCorpBusinessEntity':
      case 'LLCBusinessEntity':
      case 'LPBusinessEntity':
      case 'SCorpBusinessEntity':
      case 'GPBusinessEntity': {
        return;
      }

      case 'InsurancePersonalAccount': {
        const { owners } = entity.subtype;
        addGrantors(graph, entity.id, owners);
        return;
      }

      default:
        throw new UnreachableError({
          // Type fix for undefined __typename
          case: entity.subtype.__typename as NonNullable<
            typeof entity.subtype.__typename
          >,
          message: `Unhandled entity subtype of ${entity.subtype.__typename}`,
        });
    }
  });

  possiblePrimaryClients?.forEach((grantor) => {
    addGrantorBusinessEntitiesSummary(graph, grantor);
  });

  return graph;
};
