import { Stack } from '@mui/material';
import { ReactNode } from 'react';

import { useEntityMapContext } from '../contexts/entityMap.context';

export interface EntityMapContextContainerProps {
  height: number;
  width: number;
  children: ReactNode;
}

export const EntityMapContextContainer = ({
  height,
  width,
  children,
}: EntityMapContextContainerProps) => {
  const { state } = useEntityMapContext();
  const { isSummaryPanelOpen } = state.derivedData;

  return (
    <Stack
      display="grid"
      gridTemplateColumns={isSummaryPanelOpen ? 'auto 430px' : 'auto'}
      gap={4}
      height={height}
      width={width}
    >
      {children}
    </Stack>
  );
};
