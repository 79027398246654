import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useEffect, useMemo } from 'react';
import { Path, useWatch } from 'react-hook-form';

import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { COLORS } from '@/styles/tokens/colors';
import { AccessParameterKind } from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { ACCESS_PARAMETER_RADIO_GROUP_KINDS } from './EntityBeneficiariesSubform.constants';
import { defaultAccessParameter } from './EntityBeneficiariesSubform.defaults';
import {
  AccessParameterRadioGroupKind,
  BeneficiaryFormAccessParameters,
} from './EntityBeneficiariesSubform.types';
import { OtherAccessParameterForm } from './OtherAccessParameterForm';
import { PartialAccessParameterForm } from './PartialAccessParameterForm';

type AccessParametersFormPaths = Path<BeneficiaryFormAccessParameters>;

interface AccessParametersFormProps {
  fieldName: 'beneficiaryFormAccessParameters';
}

export function AccessParametersForm({ fieldName }: AccessParametersFormProps) {
  const { control, setValue } =
    useFormContext<BeneficiaryFormAccessParameters>();

  const selectedAccessParameterKind = useWatch({
    control,
    name: `${fieldName}._accessParameterKind` as '_accessParameterKind' satisfies AccessParametersFormPaths,
  });

  const partialAccessParameters = useWatch({
    control,
    name: `${fieldName}.accessParametersPartial` as 'accessParametersPartial' satisfies AccessParametersFormPaths,
  });

  const otherParamFieldName =
    `${fieldName}.accessParametersOther` as 'accessParametersOther';

  useEffect(
    function setFull() {
      if (selectedAccessParameterKind === AccessParameterRadioGroupKind.Full) {
        setValue(
          `${fieldName}.accessParametersFull` as 'accessParametersFull' satisfies AccessParametersFormPaths,
          defaultAccessParameter
        );
      }
    },
    [fieldName, selectedAccessParameterKind, setValue]
  );

  const { cumulativeTotal, totalKind } = useMemo(() => {
    if (
      !(selectedAccessParameterKind === AccessParameterRadioGroupKind.Partial)
    ) {
      return {
        cumulativeTotal: null,
        totalKind: null,
      };
    }

    const moreThanOneAccessParameter =
      (partialAccessParameters?.length ?? 0) > 1;

    const allPercent = partialAccessParameters
      ?.map((param) => param.kind)
      .every((param) => param === AccessParameterKind.Percentage);
    const allAmount = partialAccessParameters
      ?.map((param) => param.kind)
      .every((param) => param === AccessParameterKind.Amount);

    if (allPercent && moreThanOneAccessParameter) {
      const successiveDistributions = (distributions: Decimal[]): Decimal => {
        return new Decimal(1).minus(
          distributions.reduce(
            (remaining, percentage) =>
              remaining.times(new Decimal(1).minus(percentage)),
            new Decimal(1)
          )
        );
      };

      // Return the successive percentage reductions all percentages
      const cumulativeTotal = successiveDistributions(
        partialAccessParameters?.map((param) => {
          if (!param.percentage) return new Decimal(0);

          return (param.percentage as unknown as Decimal).div(100);
        }) ?? []
      ).times(100);

      return {
        cumulativeTotal,
        totalKind: 'percentage' as const,
      };
    }

    if (allAmount && moreThanOneAccessParameter) {
      // Return the sum of all amounts
      const cumulativeTotal = partialAccessParameters?.reduce((acc, param) => {
        if (!param.amount) return acc;

        return acc.plus(param.amount as Decimal);
      }, new Decimal(0));

      return {
        cumulativeTotal,
        totalKind: 'amount' as const,
      };
    }

    return {
      cumulativeTotal: null,
      totalKind: null,
    };
  }, [partialAccessParameters, selectedAccessParameterKind]);

  return (
    <Stack>
      <Card
        variant="filled-dark"
        sx={{
          p: 2,
          pb: 3,
          borderRadius: '6px',
          borderBottomLeftRadius: cumulativeTotal ? 0 : '6px',
          borderBottomRightRadius: cumulativeTotal ? 0 : '6px',
        }}
      >
        <Typography
          sx={{
            pb: 2,
          }}
          variant="h4"
        >
          Access parameters
        </Typography>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareRadioGroup<BeneficiaryFormAccessParameters>
              control={control}
              row
              fieldName={
                `${fieldName}._accessParameterKind` as '_accessParameterKind' satisfies AccessParametersFormPaths
              }
              options={ACCESS_PARAMETER_RADIO_GROUP_KINDS}
            />
          </FormLayoutItem>
        </FormLayoutRow>
        {selectedAccessParameterKind ===
          AccessParameterRadioGroupKind.Partial && (
          <FormLayoutRow>
            <FormLayoutItem>
              <FormAwareInputRepeater<BeneficiaryFormAccessParameters>
                control={control}
                name={
                  `${fieldName}.accessParametersPartial` as 'accessParametersPartial' satisfies AccessParametersFormPaths
                }
                emptyValue={{
                  ...defaultAccessParameter,
                  kind: AccessParameterKind.Percentage,
                }}
                addAnotherItemText="Add additional parameter"
                shouldInit
                isSingleLineRepeatedInput={false}
                render={(idx) => {
                  return (
                    <PartialAccessParameterForm
                      fieldName={
                        `${fieldName}.accessParametersPartial.${idx}` as `accessParametersPartial.${number}`
                      }
                    />
                  );
                }}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}
        {selectedAccessParameterKind ===
          AccessParameterRadioGroupKind.Other && (
          <Card
            variant="filled-dark"
            sx={{ p: 2, bgcolor: COLORS.GRAY[300], borderRadius: '6px' }}
          >
            <Stack spacing={2}>
              <OtherAccessParameterForm
                kind={AccessParameterKind.FullDiscretion}
                fieldName={otherParamFieldName}
                label="Under full discretion of trustee"
              />
              <OtherAccessParameterForm
                kind={AccessParameterKind.Hms}
                fieldName={otherParamFieldName}
                label="HMS"
              />
              <OtherAccessParameterForm
                kind={AccessParameterKind.Hems}
                fieldName={otherParamFieldName}
                label="HEMS"
              />
              <OtherAccessParameterForm
                kind={AccessParameterKind.AllTrustIncome}
                fieldName={otherParamFieldName}
                label="All trust income"
              />
              <OtherAccessParameterForm
                kind={AccessParameterKind.Other}
                fieldName={otherParamFieldName}
                label="Other"
              />
            </Stack>
          </Card>
        )}
      </Card>
      {cumulativeTotal && totalKind && (
        <CumulativeTotalsBanner
          cumulativeTotal={cumulativeTotal}
          totalKind={totalKind}
        />
      )}
    </Stack>
  );
}

function CumulativeTotalsBanner({
  cumulativeTotal,
  totalKind,
}: {
  cumulativeTotal: Decimal;
  totalKind: 'percentage' | 'amount';
}) {
  const formattedCumulativeTotal = useMemo(() => {
    if (totalKind === 'percentage') {
      return `${formatPercent(cumulativeTotal, 0)}%`;
    }
    return formatCurrencyNoDecimals(cumulativeTotal);
  }, [cumulativeTotal, totalKind]);

  return (
    <Stack
      bgcolor={COLORS.NAVY[700]}
      px={2}
      py={1}
      sx={{
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
      }}
    >
      <Stack
        direction="row"
        spacing={0.5}
        justifyContent="right"
        alignItems="baseline"
      >
        <Typography variant="subtitle2" color={COLORS.PRIMITIVES.WHITE}>
          Cumulative access totals to
        </Typography>

        <Typography variant="h4" component="p" color={COLORS.PRIMITIVES.WHITE}>
          {formattedCumulativeTotal}
        </Typography>
      </Stack>
    </Stack>
  );
}
