import { SubForm } from '@/types/subform';

import {
  BusinessEntitiesTaxStatusSubformType,
  defaultValues,
  Fields,
  NAMESPACE,
  NamespaceType,
  Props,
  VariantType,
} from './BusinessEntitiesTaxStatusSubform.types';
import { UI } from './BusinessEntitiesTaxStatusSubform.UI';

export class BusinessEntitiesTaxStatusSubform
  implements
    SubForm<
      BusinessEntitiesTaxStatusSubformType,
      Fields,
      NamespaceType,
      VariantType,
      object
    >
{
  FormComponent: (props: Props) => JSX.Element;
  defaultValues: BusinessEntitiesTaxStatusSubformType;
  namespace: NamespaceType;

  constructor() {
    this.FormComponent = UI;
    this.defaultValues = defaultValues;
    this.namespace = NAMESPACE;
  }
}
