import { partition } from 'lodash';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import {
  ContextPrimaryClient,
  useHouseholdDetailsContext,
} from '@/modules/household/contexts/householdDetails.context';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';

import { useDispositiveProvisionsContext } from '../../contexts/dispositiveProvisions.context';
import { DispositionScheme } from '../../dispositiveProvisions.types';
import { useDispositiveProvisionsTemplateSplitScreenModalContext } from '../../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.context';
import { DispositiveProvisionsFormShape } from '../DispositiveProvisionsForm.types';

export function useFirstClientDeathId(): string | null {
  const { control } = useFormContext<DispositiveProvisionsFormShape>();
  const firstClientDeathId = useWatch({
    name: 'dispositiveProvisions.selectedFirstPrimaryClientDeathId',
    control,
  });

  return firstClientDeathId;
}

export function useOrderedDyingClients(): [
  ContextPrimaryClient,
  ContextPrimaryClient | undefined,
] {
  const firstClientDeathId = useFirstClientDeathId();
  const { primaryClients } = useDispositiveProvisionsContext();
  const { isTemplateMode } =
    useDispositiveProvisionsTemplateSplitScreenModalContext();

  if (isTemplateMode) {
    return [] as unknown as [
      ContextPrimaryClient,
      ContextPrimaryClient | undefined,
    ];
  }

  // this is kind of odd because each of these arrays with either have zero or one
  // item, but `partition` is kind of the cleanest way to divide these
  const [dyingClients, nonDyingClients] = partition(
    primaryClients,
    (c) => c.id === firstClientDeathId
  );

  if (!dyingClients[0]) {
    throw new Error('Invalid state: no dying client found');
  }

  return [dyingClients[0], nonDyingClients[0]];
}

interface UseActiveClientProfileReturnValue {
  client: ContextPrimaryClient | null;
  isFirstToDie: boolean;
}

/**
 * @description Returns the active client in the dispositive provisions context in the scenario where `isClientProfile: true`.
 * That is, this will return a value when you are setting the dispositions for an individual client and null otherwise.
 */
export function useActiveClientProfile(): UseActiveClientProfileReturnValue {
  const [firstDyingClient] = useOrderedDyingClients();
  const { clientProfileOrEntityOrTestamentaryEntityId, primaryClients } =
    useDispositiveProvisionsContext();

  const client =
    primaryClients.find(
      (client) => client.id === clientProfileOrEntityOrTestamentaryEntityId
    ) ?? null;

  return {
    client,
    isFirstToDie: firstDyingClient.id === client?.id,
  };
}

export function useDispositionScheme(): DispositionScheme {
  const { control } = useFormContext<DispositiveProvisionsFormShape>();
  return useWatch({
    name: 'dispositiveProvisions.dispositionScheme',
    control,
  });
}

/**
 * @description We don't allow switching between "upon first death" and "upon second death" for testamentary entities or client profiles.
 */
export function useIsImmutableDeathOrder() {
  const { isClientProfile, isTestamentaryEntity } =
    useDispositiveProvisionsContext();
  return isClientProfile || isTestamentaryEntity;
}

export function useDeathOrderAwareClient(): ContextPrimaryClient {
  const dispositionScheme = useDispositionScheme();
  const dyingClients = useOrderedDyingClients();
  const { isTemplateMode } =
    useDispositiveProvisionsTemplateSplitScreenModalContext();

  if (isTemplateMode) {
    return {} as unknown as ContextPrimaryClient;
  }

  switch (dispositionScheme) {
    case DispositionScheme.UPON_FIRST_DEATH:
      return dyingClients[0];
    case DispositionScheme.UPON_SECOND_DEATH:
      if (!dyingClients[1]) {
        throw new Error('Invalid state: no second dying client found');
      }

      return dyingClients[1];
    default:
      throw new Error(
        `Requesting client death order in unknown scenario: ${dispositionScheme}`
      );
  }
}

export function useHasGrantorWithStateTax() {
  const { primaryClients } = useHouseholdDetailsContext();
  const { availableStateEstateTaxes } = useIRSConstants();

  return primaryClients?.some(
    (client) =>
      client.stateCode && availableStateEstateTaxes?.includes(client.stateCode)
  );
}
