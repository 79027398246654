import { Box } from '@mui/material';
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import { getValidations } from '@/components/utils/inputUtils';
import { COLORS } from '@/styles/tokens/colors';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

export type FormAwareDisplayValueProps<FormShape extends FieldValues> =
  React.PropsWithChildren<{
    fieldName: FieldNameFromFormShape<FormShape>;
    validation?: RegisterOptions<FormShape>['validate'];
    control: Control<FormShape>;
    testId?: string;
  }>;

/**
 * @description This component is used when you want to display computed values, but would also like to be able to validate those computed
 * values and focus on them in the scenario that they're invalid. This is useful in a scenario like a series of inputs that must add up to 100%;
 * each input can individually be validated, but the sum of all inputs must also be validated.
 */
export function FormAwareDisplayValue<FormShape extends FieldValues>({
  fieldName,
  control,
  testId,
  validation,
  children,
}: FormAwareDisplayValueProps<FormShape>) {
  const validations = getValidations('', false, validation);
  return (
    <Controller
      rules={{ validate: validations }}
      control={control}
      name={fieldName}
      render={({ field }) => {
        const { ref, ...fieldWithoutRef } = field;
        return (
          <Box
            tabIndex={-1}
            ref={ref}
            sx={{
              display: 'inline-block',
              // show an error focus ring
              ':focus': {
                // use outline/offset rather than border/padding to avoid shifting the layout
                outline: `solid ${COLORS.FUNCTIONAL.ERROR.DEFAULT} 2px`,
                outlineOffset: 2,
                borderRadius: 1,
              },
            }}
          >
            <input type="hidden" data-testid={testId} {...fieldWithoutRef} />
            {children}
          </Box>
        );
      }}
    />
  );
}
