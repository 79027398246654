import { transformNodesWithViewConfig } from '@/modules/graphViz/graphVizNodeConfig';

import { DEFAULT_ESTATE_WATERFALL_STATE } from '../contexts/estateWaterfall.context';
import { EstateWaterfallState, EstateWaterfallStateProps } from '../types';
import { extractReactFlowNodesAndEdges } from '../waterfallGraph/utils';
import { createWaterfallGraph } from '../waterfallGraph/waterfallGraph';

export function generateWaterfallGraphState({
  waterfall,
  primaryClients,
  isTwoClientHousehold,
  featureFlags,
  visibleNodeIds,
  isGeneratingDefaultState = false,
  initWaterfallNodeIds,
}: EstateWaterfallStateProps & {
  isGeneratingDefaultState?: boolean;
}): Pick<EstateWaterfallState, 'waterfallGraph' | 'nodes' | 'edges'> {
  const waterfallGraph = createWaterfallGraph({
    waterfall: waterfall,
    grantors: primaryClients,
    isTwoClientHousehold,
    featureFlags,
    visibleNodeIds,
    isGeneratingDefaultState,
    initWaterfallNodeIds,
  });

  const { nodes, edges } = extractReactFlowNodesAndEdges(waterfallGraph);

  const finalNodes = transformNodesWithViewConfig({
    nodes,
    nodeConfigurations: waterfall.nodeConfigurations,
  });

  return {
    waterfallGraph,
    nodes: finalNodes,
    edges,
  };
}

export function generateDefaultState(
  defaultStateProps: EstateWaterfallStateProps
): EstateWaterfallState {
  const waterfallGraphProps = generateWaterfallGraphState({
    ...defaultStateProps,
    isGeneratingDefaultState: true,
  });

  return {
    ...DEFAULT_ESTATE_WATERFALL_STATE,
    ...defaultStateProps,
    ...waterfallGraphProps,
  };
}
