import { first } from 'lodash';

import { useUnguardedHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { getNodes } from '@/utils/graphqlUtils';

import {
  HouseholdOnboardingSidebar_HouseholdFragment,
  HouseholdOnboardingSidebarQueryHookResult,
  useHouseholdOnboardingSidebarQuery,
} from '../graphql/HouseholdOnboardingSidebar.generated';

export const HOUSEHOLD_ONBOARDING_POLLING_RATE_MS = 3000 as const;

export type HouseholdOnboardingSidebarData =
  HouseholdOnboardingSidebar_HouseholdFragment | null;

export interface useGetDisplayHouseholdOnboardingSidebarQueryResult
  extends HouseholdOnboardingSidebarQueryHookResult {
  household: HouseholdOnboardingSidebarData;
}

export function useGetDisplayHouseholdOnboardingSidebarQuery(): useGetDisplayHouseholdOnboardingSidebarQueryResult {
  const { householdId, householdOnboardingCompletedAt } =
    useUnguardedHouseholdDetailsContext();

  const skip = !householdId || !!householdOnboardingCompletedAt;

  const { data, ...rest } = useHouseholdOnboardingSidebarQuery({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip,
    variables: {
      householdId: householdId || '',
    },
  });

  return {
    household: first(getNodes(data?.households)) || null,
    data,
    ...rest,
  };
}

export enum OnboardingStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
}

export function useOnboardingStatusForHousehold(): OnboardingStatus {
  const { householdId, householdOnboardingCompletedAt } =
    useUnguardedHouseholdDetailsContext();
  const skip = !householdId || !!householdOnboardingCompletedAt;
  const { data } = useHouseholdOnboardingSidebarQuery({
    variables: {
      householdId: householdId || '',
    },
    skip,
  });

  const household = first(getNodes(data?.households));
  const hasOnboardingStatus = !!household?.onboarding;

  if (!hasOnboardingStatus) {
    return OnboardingStatus.Completed;
  }

  const isCompleted = !!household.onboarding?.completedAt;
  if (!isCompleted) {
    return OnboardingStatus.InProgress;
  }

  return OnboardingStatus.Completed;
}
