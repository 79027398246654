import {
  Box,
  createTheme,
  Divider,
  Stack,
  Theme,
  ThemeProvider,
  Typography,
} from '@mui/material';

import { CompoundHeader } from '@/components/display/CompoundHeader/CompoundHeader';
import { Button } from '@/components/form/baseInputs/Button';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { Card } from '@/components/layout/Card/Card';
import { useActiveTab } from '@/components/navigation/NavigationTabs/useActiveTab';
import { useFormContext } from '@/components/react-hook-form';
import { COMMON_THEME_PROPERTIES } from '@/styles/themes/common';
import { ColorKey, makeThemeFromBrand } from '@/styles/themes/themes.utils';

import {
  colorInputDefinitionsDataviz,
  colorInputDefinitionsPrimary,
} from './AdminBrandingForm.constants';
import { FirmBrandingFormShape } from './AdminBrandingForm.types';
import { AdminBrandingPreview } from './AdminBrandingPreview';
import { AdminLogosForm } from './AdminLogosForm';
import { BrandingColorInput } from './BrandingColorInput';
import { AdminBrandingPage_BrandingFragment } from './graphql/AdminBrandingPage.generated';

export interface AdminBrandingFormProps {
  tenantBranding: AdminBrandingPage_BrandingFragment | null;
  onSubmit: () => void;
}

enum AdminBrandingTab {
  COLORS = 'colors',
  LOGOS = 'logos',
}

// The color parsing utilities throw if the color isn't valid, so we need to ensure that the color is valid
function getSafeColor(color: string): string {
  const isValidHex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  if (!color || !isValidHex.test(color)) {
    return '#FFFFFF';
  }
  return color;
}

function getThemeFromConfiguredColors(values: FirmBrandingFormShape): Theme {
  const brandColors: Record<ColorKey, string> = {
    PRIMARY: getSafeColor(values.primaryColor),
    SECONDARY: getSafeColor(values.secondaryColor),
    BUTTONS_LINKS: getSafeColor(values.buttonsLinkColor),
    DATA_VISUALIZATION_PRIMARY: getSafeColor(
      values.dataVisualizationPrimaryColor
    ),
    DATA_VISUALIZATION_SECONDARY: getSafeColor(
      values.dataVisualizationSecondaryColor
    ),
    DATA_VISUALIZATION_TERTIARY: getSafeColor(
      values.dataVisualizationTertiaryColor
    ),
    DATA_VISUALIZATION_NEGATIVE: getSafeColor(
      values.dataVisualizationNegativeColor
    ),
  };

  const theme = makeThemeFromBrand(brandColors);
  return createTheme(COMMON_THEME_PROPERTIES, theme);
}

export function AdminBrandingForm({
  tenantBranding,
  onSubmit,
}: AdminBrandingFormProps) {
  const { activeTab, setActiveTab } = useActiveTab<AdminBrandingTab>({
    defaultTab: AdminBrandingTab.LOGOS,
  });

  const {
    control,
    watch,
    formState: { isSubmitting },
  } = useFormContext<FirmBrandingFormShape>();

  const values = watch();
  const configuredTheme = getThemeFromConfiguredColors(values);

  return (
    <Card variant="outlined" sx={{ p: 3 }}>
      <Box width={275} mb={4}>
        <ButtonGroup
          label="Branding section"
          hideLabel
          options={[
            { display: 'Brand logos', value: AdminBrandingTab.LOGOS },
            { display: 'Custom colors', value: AdminBrandingTab.COLORS },
          ]}
          value={activeTab}
          onChange={(_e, value) => setActiveTab(value)}
        />
      </Box>
      {activeTab === AdminBrandingTab.LOGOS && (
        <Stack spacing={3}>
          <CompoundHeader
            heading="Firm logos"
            subheading="Image files must be in a supported file type (jpg, png, or gif) and cannot be larger than 2MB."
          />
          <AdminLogosForm tenantBranding={tenantBranding} />
        </Stack>
      )}
      {activeTab === AdminBrandingTab.COLORS && (
        <Stack spacing={3} maxWidth={1200}>
          <CompoundHeader
            heading="Custom colors"
            subheading="Customize your presentations by applying your firm's brand colors to the options below."
          />
          <Divider />
          <Stack component="form" spacing={3} onSubmit={onSubmit} noValidate>
            <Stack
              spacing={4}
              mb={2}
              direction="row"
              justifyContent="flex-start"
            >
              <Stack spacing={2}>
                <Typography variant="h4">Brand colors</Typography>
                {colorInputDefinitionsPrimary.map((cid) => (
                  <BrandingColorInput
                    {...cid}
                    key={cid.fieldName}
                    control={control}
                    colorValue={values[cid.fieldName] || '#FFFFFF'}
                  />
                ))}
              </Stack>

              <Stack spacing={2}>
                <Typography variant="h4">Data visualization colors</Typography>
                {colorInputDefinitionsDataviz.map((cid) => (
                  <BrandingColorInput
                    {...cid}
                    key={cid.fieldName}
                    control={control}
                    colorValue={values[cid.fieldName] || '#FFFFFF'}
                  />
                ))}
              </Stack>
            </Stack>
            <ThemeProvider theme={configuredTheme}>
              <AdminBrandingPreview />
            </ThemeProvider>
            <Box textAlign="right">
              <Button
                size="lg"
                variant="primary"
                type="submit"
                loading={isSubmitting}
              >
                Save
              </Button>
            </Box>
          </Stack>
        </Stack>
      )}
    </Card>
  );
}
