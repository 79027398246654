import { Navigate } from 'react-router-dom';

import { RouteKey } from './navigationUtils';
import { useDefaultLandingPage } from './useDefaultLandingPage';
import { useCanAccessPage } from './usePageAccessLookup';

export interface GuardedPageRedirectProps {
  route: RouteKey;
}

export function GuardedPageRedirect({
  route,
  children,
}: React.PropsWithChildren<GuardedPageRedirectProps>) {
  const defaultLandingPage = useDefaultLandingPage();
  const canAccessCurrentPage = useCanAccessPage(route);

  if (canAccessCurrentPage) {
    return <>{children}</>;
  }

  return <Navigate to={defaultLandingPage} replace />;
}
