import { Grid, Stack } from '@mui/material';

import { HeaderCard } from '@/components/layout/HeaderCard';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { DuplicateHouseholdButton } from '@/pages/manage/editClient/ClientAdminPage/DuplicateHouseholdButton/DuplicateHouseholdButton';

import { HouseholdAdminBillingActions } from './HouseholdAdminBillingActions';

export function HouseholdAdminPage() {
  const householdId = useRequiredParam('householdId');

  return (
    <Stack height="100%" sx={{ p: 3 }}>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <HeaderCard heading="Billing">
            <HouseholdAdminBillingActions householdId={householdId} />
          </HeaderCard>
        </Grid>
        <Grid item lg={6} sm={12}>
          <HeaderCard heading="Danger zone">
            <DuplicateHouseholdButton householdId={householdId} />
          </HeaderCard>
        </Grid>
      </Grid>
    </Stack>
  );
}
