import { getYear } from 'date-fns';
import Decimal from 'decimal.js';

import { EstateWaterfallAssumptionFields } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/types';
import {
  CreateEstateWaterfallInput,
  EntityGraphNodeConfigurationInput,
} from '@/types/schema';
import { assertNonNil } from '@/utils/assertUtils';

import { EstateWaterfallFormProps } from './EstateWaterfallForm';
import { EstateWaterfallFormData } from './types';

interface MapInputToFormData extends EstateWaterfallAssumptionFields {
  householdId: string;
  grantors: EstateWaterfallFormProps['grantors'];
  displayName?: string;
  isHypothetical?: boolean;
  waterfallFilter?: EstateWaterfallFormData['waterfallFilter'];
  isDuplicate?: boolean;
  parentID?: string;
  shouldAutoGroup?: boolean;
}

export const mapInputToFormData = ({
  householdId,
  grantors,
  displayName = '',
  isHypothetical,
  waterfallFilter = null,
  parentID,
  shouldAutoGroup,
  ...projectionFields
}: MapInputToFormData): EstateWaterfallFormData => {
  const { id: firstGrantorDeathID } = assertNonNil(
    grantors[0],
    `Grantor is required to create a waterfall`
  );
  return {
    householdID: householdId,
    displayName,
    firstGrantorDeathID,
    _isHypothetical: isHypothetical ?? false,
    waterfallFilter,
    parentID,
    shouldAutoGroup: shouldAutoGroup ?? false,
    ...projectionFields,
  };
};

interface MapFormDataToInputInput {
  formData: EstateWaterfallFormData;
  isTwoGrantor: boolean;
  sourceEstateWaterfalls?: EstateWaterfallFormProps['sourceEstateWaterfalls'];
}

export const mapFormDataToInput = ({
  formData,
  isTwoGrantor,
  sourceEstateWaterfalls,
}: MapFormDataToInputInput): CreateEstateWaterfallInput => {
  const {
    _isHypothetical,
    waterfallFilter: _wF,
    shouldAutoGroup: _sAG,
    useCustomGlobalGrowthRate: _uCG,
    ...formDataRest
  } = formData;

  const nodeConfigurations: EntityGraphNodeConfigurationInput[] | undefined =
    (() => {
      if (formData.parentID) {
        const parent = sourceEstateWaterfalls?.find(
          (ew) => ew.id === formData.parentID
        );

        return parent?.nodeConfigurations || undefined;
      }

      return undefined;
    })();

  return {
    ...formDataRest,
    // The user could manually clear these fields on the form, so we want to send these default values to the backend instead of empty strings
    firstGrantorDeathYear:
      formData.firstGrantorDeathYear || getYear(new Date()),
    secondGrantorDeathYear: isTwoGrantor
      ? formData.secondGrantorDeathYear || getYear(new Date())
      : undefined,
    assetGrowthReturn: formData.assetGrowthReturn || new Decimal(0),
    exemptionGrowthRate: formData.exemptionGrowthRate || new Decimal(0),
    nodeConfigurations,
  };
};
