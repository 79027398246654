import { Stack } from '@mui/material';
import { useMemo } from 'react';

import { LegendItem } from '@/components/charts/Legend/Legend';
import {
  Section,
  StackedHorizontalBar,
} from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import { AfterDeath } from '@/types/schema';

import { BeneficiaryReport } from '../beneficiaryReporting.types';
import {
  getDirectGiftSection,
  getFullAccessSection,
  getPartialAccessSection,
  getScheduledDistributionSection,
} from '../beneficiaryReporting.utils';

interface BenefitsBarChartProps {
  beneficiaryReports: {
    beneficiaryReport: BeneficiaryReport;
    afterDeath: AfterDeath;
  }[];
}

export function BenefitsBarChart({
  beneficiaryReports,
}: BenefitsBarChartProps) {
  const sections: Section[] = useMemo(() => {
    const allScheduledDistributions = beneficiaryReports.flatMap(
      ({ beneficiaryReport: br }) => br.scheduledDistributions
    );
    const allDirectGifts = beneficiaryReports.flatMap(
      ({ beneficiaryReport: br }) => br.directGifts
    );
    const allFullAccess = beneficiaryReports.flatMap(
      ({ beneficiaryReport: br }) => br.fullAccess
    );
    const allPartialAccess = beneficiaryReports.flatMap(
      ({ beneficiaryReport: br }) => br.partialAccess
    );

    const scheduledDistributionSection = getScheduledDistributionSection(
      allScheduledDistributions
    );
    const directGiftSection = getDirectGiftSection(allDirectGifts);
    const fullAccessSection = getFullAccessSection(allFullAccess);
    const partialAccessSection = getPartialAccessSection(allPartialAccess);
    return [
      scheduledDistributionSection,
      directGiftSection,
      fullAccessSection,
      partialAccessSection,
    ].filter((section) => section.value > 0);
  }, [beneficiaryReports]);

  const legendItems = useMemo(() => {
    // We just need the labels, colors, and backgroundCss for the legend so the
    // inputs do not matter
    const scheduledDistributionSection = getScheduledDistributionSection([]);
    const directGiftSection = getDirectGiftSection([]);
    const fullAccessSection = getFullAccessSection([]);
    const partialAccessSection = getPartialAccessSection([]);
    return [
      scheduledDistributionSection,
      directGiftSection,
      fullAccessSection,
      partialAccessSection,
    ];
  }, []);

  return (
    <Stack spacing={2}>
      <StackedHorizontalBar sections={sections} />
      <Stack direction="row" spacing={3}>
        {legendItems.map((i, idx) => (
          <LegendItem
            key={`legend-item-${idx}`}
            primaryText={i.label}
            legendItemColor={i.color}
            backgroundCss={i.backgroundCss}
          />
        ))}
      </Stack>
    </Stack>
  );
}
