import { compact } from 'lodash';
import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';

import { UNASSIGNED_TASK_SENTINEL } from '../tasks.constants';
import { useTaskReassignmentModalOptionsQuery } from './graphql/TaskReassignmentModal.generated';

export function useGetTaskReassignmentOptions({
  householdId,
  assignedAwayFromUserId,
}: {
  householdId: string;
  assignedAwayFromUserId: string;
}) {
  const { createErrorFeedback } = useFeedback();
  const { data, ...queryProps } = useTaskReassignmentModalOptionsQuery({
    variables: {
      householdId,
    },
    fetchPolicy: 'cache-and-network',
    onError: createErrorFeedback('Failed to fetch task reassignment options'),
  });

  const reassignmentOptions = useMemo(() => {
    if (data?.node?.__typename !== 'Household') {
      return [];
    }

    const relationshipOwnerName =
      data.node.primaryRelationshipOwner?.displayName;
    const relationshipOwnerId = data.node.primaryRelationshipOwner?.id;

    const userReassignmentOptions = compact(
      data.node.possibleTaskAssignees.map((possibleAssignee) => {
        if (
          !possibleAssignee ||
          // don't include the user we're assigning away from
          // as a possible assignee
          possibleAssignee.id === assignedAwayFromUserId ||
          // don't include the relationship owner in the full list, since
          // they're already at the top
          possibleAssignee.id === relationshipOwnerId
        ) {
          return null;
        }

        return {
          value: possibleAssignee.id,
          display: possibleAssignee.displayName,
        };
      })
    );

    const leaveTasksUnassignedOption: SelectInputOption<string> = {
      value: UNASSIGNED_TASK_SENTINEL,
      display: 'Leave tasks unassigned',
    };

    const relationshipOwnerReassignmentOption: SelectInputOption<string> = {
      value: relationshipOwnerId ?? '',
      display: compact([
        'Current relationship owner',
        relationshipOwnerName && `(${relationshipOwnerName})`,
      ]).join(' '),
    };

    return [
      relationshipOwnerReassignmentOption,
      leaveTasksUnassignedOption,
      ...userReassignmentOptions,
    ];
  }, [assignedAwayFromUserId, data]);

  return { reassignmentOptions, ...queryProps };
}
