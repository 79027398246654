import { Box, Stack, SxProps, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { ComponentProps, ReactNode } from 'react';

import { COLORS } from '@/styles/tokens/colors';

import { Medallion } from '../Medallion/Medallion';

export interface OversizedMetricItemProps {
  title: React.ReactNode;
  value: React.ReactNode;
  sx?: SxProps<Theme>;
  action?: JSX.Element;
  titleColor?: string;
  valueColor?: string;
  valueProps?: ComponentProps<typeof Typography>;
  // when invertLabel is true, the title will be displayed below the value
  invertLabel?: boolean;
  // when centered is true, the title and value will be center-aligned
  centered?: boolean;
  tooltip?: ReactNode;
  titleVariant?: ComponentProps<typeof Typography>['variant'];
  valueVariant?: ComponentProps<typeof Typography>['variant'];
  valueLabel?: string;
  valueLabelVariant?: ComponentProps<typeof Typography>['variant'];
  icon?: ReactNode;
  iconBackground?: string;
  iconForeground?: string;
}

export function OversizedMetricItem({
  title,
  value,
  sx,
  action,
  titleColor,
  valueColor,
  valueProps,
  invertLabel,
  tooltip,
  titleVariant = 'subtitle2',
  valueVariant = 'h1',
  centered,
  valueLabel,
  valueLabelVariant = 'subtitle2',
  icon,
  iconBackground,
  iconForeground = undefined,
}: OversizedMetricItemProps) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {icon && (
        <Medallion
          icon={icon}
          size={40}
          colors={{
            background: iconBackground || '',
            iconColor: iconForeground,
          }}
        />
      )}
      <Stack
        direction={invertLabel ? 'column-reverse' : 'column'}
        alignItems={centered ? 'center' : undefined}
        spacing={0.5}
        sx={sx}
      >
        <Stack direction="row" justifyContent="flex-start">
          <Typography
            variant={titleVariant}
            component="span"
            color={titleColor}
          >
            {title}
          </Typography>
          {tooltip}
        </Stack>
        <Stack direction="row" spacing={0.75}>
          <Typography
            variant={valueVariant}
            component="span"
            color={valueColor || COLORS.NAVY[900]}
            {...valueProps}
          >
            {value}
          </Typography>
          {valueLabel && (
            <Typography
              sx={{
                pt: 1.2,
              }}
              variant={valueLabelVariant}
            >
              {valueLabel}
            </Typography>
          )}
        </Stack>
      </Stack>
      {action && <Box ml={1}>{action}</Box>}
    </Stack>
  );
}
