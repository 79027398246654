import React from 'react';

import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';

interface EditEstateWaterfallAssumptionsUpdatingOverlayProps {
  show: boolean;
}

export const EditEstateWaterfallAssumptionsUpdatingOverlay = ({
  show,
}: EditEstateWaterfallAssumptionsUpdatingOverlayProps) => {
  return <LoadingOverlay open={show} updatingText="Updating waterfall..." />;
};
