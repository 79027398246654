import { useCallback } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { AugmentedUpdateTenantDisclaimerConfigurationInput } from '@/types/schema';
import { getValueOrClear } from '@/utils/graphqlFormUtils';

import { useLegalDisclaimersLazyQuery } from './graphql/AdminLegalDisclaimers.generated';
import { LegalDisclaimersFormShape } from './LegalDisclaimersForm';

export function useLegalDisclaimersFormDefaultValues() {
  const { createErrorFeedback } = useFeedback();

  const [queryFormData, queryProps] = useLegalDisclaimersLazyQuery({
    onError: createErrorFeedback(
      'There was an error fetching disclaimer details. Please refresh the page to try again.'
    ),
  });

  const getDefaultValues = useCallback<
    () => Promise<LegalDisclaimersFormShape>
  >(async () => {
    const { data } = await queryFormData();

    const disclosuresConfiguration =
      data?.tenantInformation?.disclosuresConfiguration;
    return {
      presentationCoverDisclaimerBody:
        disclosuresConfiguration?.presentationCoverDisclaimerBody ?? '',
      presentationCoverDisclaimerTitle:
        disclosuresConfiguration?.presentationCoverDisclaimerTitle ?? '',
      presentationFooterDisclaimerTitle:
        disclosuresConfiguration?.presentationFooterDisclaimerTitle ?? '',
      presentationFooterDisclaimerBody:
        disclosuresConfiguration?.presentationFooterDisclaimerBody ?? '',
      presentationStandaloneDisclaimerTitle:
        disclosuresConfiguration?.presentationStandaloneDisclaimerTitle ?? '',
      presentationStandaloneDisclaimerBody:
        disclosuresConfiguration?.presentationStandaloneDisclaimerBody ?? '',
      enableLuminaryDisclaimer:
        disclosuresConfiguration?.includeLuminaryDisclaimer ?? false,
    } as const satisfies LegalDisclaimersFormShape;
  }, [queryFormData]);

  return {
    getDefaultValues,
    ...queryProps,
  };
}

export function getUpdateDisclaimersInput(
  disclaimerId: string,
  formValues: LegalDisclaimersFormShape
): AugmentedUpdateTenantDisclaimerConfigurationInput {
  return {
    id: disclaimerId,
    update: {
      ...getValueOrClear(
        `presentationCoverDisclaimerBody`,
        formValues.presentationCoverDisclaimerBody
      ),
      ...getValueOrClear(
        `presentationCoverDisclaimerTitle`,
        formValues.presentationCoverDisclaimerTitle
      ),
      ...getValueOrClear(
        `presentationFooterDisclaimerTitle`,
        formValues.presentationFooterDisclaimerTitle
      ),
      ...getValueOrClear(
        `presentationFooterDisclaimerBody`,
        formValues.presentationFooterDisclaimerBody
      ),
      ...getValueOrClear(
        `presentationStandaloneDisclaimerTitle`,
        formValues.presentationStandaloneDisclaimerTitle
      ),
      ...getValueOrClear(
        `presentationStandaloneDisclaimerBody`,
        formValues.presentationStandaloneDisclaimerBody
      ),
      includeLuminaryDisclaimer: formValues.enableLuminaryDisclaimer,
    },
  };
}
