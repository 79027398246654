import Decimal from 'decimal.js';

export interface CompoundedGrowthAtYear {
  year: number;
  value: Decimal;
}

export interface CompoundingInput {
  /** Initial amount at year zero. */
  initialAmount: Decimal;
  /** Growth rate, where 1.00 is no growth. A 5% growth rate should be passed in as `Decimal(1.05)` */
  growthRate: Decimal;
  /** First year (growth will not be included) */
  startYear: number;
  /** Last year to include */
  endYear: number;
}

export function compoundedGrowthOverYears({
  initialAmount,
  growthRate,
  startYear,
  endYear,
}: CompoundingInput): {
  log: CompoundedGrowthAtYear[];
  value: Decimal;
} {
  if (!startYear || !endYear || endYear < startYear) {
    return { log: [], value: new Decimal(0) };
  }

  const log: CompoundedGrowthAtYear[] = [
    {
      year: startYear,
      value: initialAmount,
    },
  ];

  if (endYear === startYear) {
    return { log, value: initialAmount };
  }

  let runningAmount = initialAmount;

  for (let currentYear = startYear + 1; currentYear <= endYear; currentYear++) {
    runningAmount = runningAmount.times(growthRate);
    log.push({
      year: currentYear,
      value: runningAmount,
    });
  }

  return { log, value: runningAmount };
}
