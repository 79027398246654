import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { DispositiveProvisionsTotalLineVariants } from '@/modules/dispositiveProvisions/dispositiveProvisions.types';
import { SimulateDispositiveProvisionsQuery } from '@/modules/dispositiveProvisions/graphql/SimulateDispositiveProvisions.generated';
import { sumDecimalJS } from '@/utils/decimalJSUtils';

interface UseGetTotalLineDetailsForEntityInput {
  entityTotalMarketValue: Decimal;
  simulationResults?: SimulateDispositiveProvisionsQuery;
}

export function useGetTotalLineDetailsForEntity({
  entityTotalMarketValue,
  simulationResults,
}: UseGetTotalLineDetailsForEntityInput) {
  const sumOfAllDistributions = useMemo(() => {
    const sumFirst = sumDecimalJS(
      simulationResults?.simulateDispositiveProvisions.firstDeath.map(
        ({ transferAmount }) => transferAmount
      ) ?? []
    );

    const sumSecond = sumDecimalJS(
      simulationResults?.simulateDispositiveProvisions.secondDeath.map(
        ({ transferAmount }) => transferAmount
      ) ?? []
    );

    return sumFirst.plus(sumSecond);
  }, [simulationResults]);

  const isAnyDistributionClipped = useMemo(() => {
    const firstDeathClipped =
      simulationResults?.simulateDispositiveProvisions.firstDeath.some(
        ({ transferAmountWasClipped }) => transferAmountWasClipped
      );

    const secondDeathClipped =
      simulationResults?.simulateDispositiveProvisions.secondDeath.some(
        ({ transferAmountWasClipped }) => transferAmountWasClipped
      );

    return firstDeathClipped || secondDeathClipped;
  }, [simulationResults]);

  const variant: DispositiveProvisionsTotalLineVariants = useMemo(() => {
    // Order matters, we need to see if any of the values are clipped, because
    // if they are, the sum always equals the total
    if (isAnyDistributionClipped) {
      return DispositiveProvisionsTotalLineVariants.ExceedsAssets;
    }

    // Give a little wiggle room for floating point arithmetic
    if (entityTotalMarketValue.minus(sumOfAllDistributions).greaterThan(0.1)) {
      return DispositiveProvisionsTotalLineVariants.LessThanAssets;
    }

    return DispositiveProvisionsTotalLineVariants.AccountsForAllAssets;
  }, [entityTotalMarketValue, isAnyDistributionClipped, sumOfAllDistributions]);

  return { variant, sumOfAllDistributions };
}
