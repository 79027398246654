import { diagnostics } from '@/utils/diagnostics';
import { formatEnumCase, formatPascalCase } from '@/utils/formatting/strings';
import { isNumberLike } from '@/utils/numberUtils';
import { isEnumLike, isPascalCaseLike } from '@/utils/regex';

import {
  ActivityFragment,
  ActivityGroupFragment,
} from '../graphql/ActivityGroups.generated';
import { groupCopy, verbCopy } from './constants';
import { RowData } from './types';

function attemptDefaultChangeValueParse(
  value?: string | null
): string | null | undefined {
  // Numbers are tricky, could be a percent or money or int etc...
  if (isNumberLike(value)) return value;
  // AssetValue --> Asset Value
  if (isEnumLike(value)) return formatEnumCase(value);
  // SOME_CONST --> Some const
  if (isPascalCaseLike(value)) return formatPascalCase(value);
  // No idea, display the raw value or handle it yourself on a case-by-case basis
  return value;
}

function getActivityDescription(
  activity: ActivityFragment
): RowData['description'] {
  const action = verbCopy[activity.verb];

  switch (activity.target.__typename) {
    case 'TenantBranding': {
      return { lineOne: `Branding ${action}` };
    }
    default: {
      return {
        lineOne: `${formatPascalCase(activity.target.__typename)} ${
          verbCopy[activity.verb]
        }`,
      };
    }
  }
}

function getActivityChanges(activity: ActivityFragment): RowData['changes'] {
  const { __typename } = activity.target;
  const changes = (activity.changes || []).flatMap((c) => c || []);

  switch (__typename) {
    // Add cases here to do custom logic outside of the default for each target type if needed
    // e.g. case 'AssetValue': { ... }

    // TODO: handle more specific formatting such as currency, date, etc once the backend passes information about
    // the type of value each change is
    default: {
      return changes.map(({ newValue, oldValue, fieldName, ...change }) => {
        return {
          newValue: attemptDefaultChangeValueParse(newValue),
          oldValue: attemptDefaultChangeValueParse(oldValue),
          fieldName,
          ...change,
        };
      });
    }
  }
}

function buildActivityRow(activity: ActivityFragment): RowData {
  return {
    id: activity.id,
    timestamp: activity.createdAt,
    user: activity.user,
    path: [activity.id],
    description: getActivityDescription(activity),
    changes: getActivityChanges(activity),
  };
}

export function mapDataToRows(groups: ActivityGroupFragment[]): RowData[] {
  return groups.flatMap(({ activities }) => {
    const firstActivity = activities[0];
    if (!firstActivity) {
      const msg = `Encountered zero activities for an activity group`;
      diagnostics.error(msg, new Error(msg));
      return [];
    }

    const { id, groupName, entities, requestID: groupId } = firstActivity;
    const entityName = entities?.[0]?.subtype?.displayName;
    const rowData = buildActivityRow(firstActivity);

    // Single activity case
    if (!groupName) {
      rowData.description.lineTwo = rowData.description.lineTwo ?? entityName;
      return rowData;
    }

    if (!groupId) {
      const msg = `requestID undefined for activity: ${id}`;
      diagnostics.error(msg, new Error(msg), { activityId: id });
      return [];
    }

    // Activity group case
    const group: RowData = {
      ...rowData,
      id: groupId,
      description: {
        lineOne: groupCopy[groupName],
        lineTwo: entityName,
      },
      path: [groupId],
    };

    const groupedActivies = activities.map((activity) => {
      const { path, ...rowData } = buildActivityRow(activity);
      // path explanation:
      // creates a virtual tree data path corresponding to [groupId (parent), activity.id (child)]
      // to be used in DataTable's treeData look up API
      return { ...rowData, path: [groupId, ...path] };
    });

    return [group, ...groupedActivies];
  });
}
