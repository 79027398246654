import { noop } from 'lodash';

import { LinkButton } from '@/components/form/baseInputs/Link';
import { PopperContent } from '@/components/poppers/PopperContent';

interface Props {
  handleClick?: () => void;
}

export function ProjectedReturnValue({ handleClick = noop }: Props) {
  return (
    <PopperContent
      heading={'Projected return'}
      body={
        'The projected value of the GRAT at the end of the GRAT term. This can also be expressed as an annual return rate.'
      }
      actions={
        <LinkButton display="Switch input method" onClick={handleClick} />
      }
    />
  );
}
