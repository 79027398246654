import { useCallback } from 'react';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import {
  AfterDeath,
  AugmentedCreateGraphVisualizationGroupInput,
} from '@/types/schema';
import { assertNonNil } from '@/utils/assertUtils';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { useEstateWaterfallContext } from '../contexts/estateWaterfall.context';
import { getUpdateWaterfallVariables } from '../EstateWaterfall.utils';
import {
  useEstateWaterfallUpdateMutation,
  useEstateWaterfallUpdateNodesLayoutMutation,
} from '../graphql/EstateWaterfall.generated';
import { CreateNewGroupFormShape } from './CreateNewGroupModal';
import { getCreateNodeGroupVariables } from './CreateNewGroupModal.utils';
import { getNodeId, isUIOnlyGroupNode } from './utils';

export function useCreateNodeGroup() {
  const { showFeedback } = useFeedback();
  const { state } = useEstateWaterfallContext();
  const { waterfall, nodes } = state;

  const waterfallId = waterfall.id;

  const [updateWaterfallLayout] = useEstateWaterfallUpdateNodesLayoutMutation({
    fetchPolicy: 'no-cache',
    onQueryUpdated: () => false,
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      diagnostics.error(
        `couldn't save estate waterfall node positions`,
        error,
        { waterfallId }
      );
    },
  });

  const [updateWaterfallWithFullResponse] = useEstateWaterfallUpdateMutation({
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      diagnostics.error(
        `couldn't save estate waterfall node positions`,
        error,
        { waterfallId }
      );
    },
  });

  const createNodeGroup = useCallback(
    async (formData: CreateNewGroupFormShape) => {
      const {
        description,
        displayName,
        namespace,
        nodeIds,
        applyToAllSections,
      } = formData;

      if (!waterfall) return;

      const groupInput: AugmentedCreateGraphVisualizationGroupInput[] = [];

      if (applyToAllSections) {
        const allSections = Object.values(AfterDeath);
        for (const section of allSections) {
          groupInput.push({
            create: {
              description,
              displayName,
              nodeIds,
              namespace: section,
            },
          });
        }
      } else {
        groupInput.push({
          create: {
            description,
            displayName,
            nodeIds,
            namespace,
          },
        });
      }

      const {
        variables: createNodeGroupVariables,
        count: countOfNodeGroupsCreated,
      } = getCreateNodeGroupVariables(waterfall.id, groupInput);

      /*******************
       * @start Node Group creation code block
       * @description This is quite hacky, but is a limitation on the backend of not being able to
       * save nodeConfigurations on the EstateWaterfall entity for a specific graph viz group at the
       * time of creation for those visualization groups.
       *
       * What we do here is make two calls in series:
       * 1. Create any new graph viz groups and save them to the waterfall
       * 2. Save graph viz group positions, along with any other waterfall updates in a 2nd call
       * @context https://withluminary.slack.com/archives/C05PFLRCCT1/p1700517092658749
       */
      const { data } = await updateWaterfallLayout({
        variables: createNodeGroupVariables!,
      });

      const updatedWF = assertNonNil(
        data?.updateEstateWaterfall,
        'no waterfall'
      );

      const createdVizGroups = getNodes(updatedWF.visualizationGroups).slice(
        // Assumes the backend returns these in order, we grab them accounting for any previously existing groups
        // e.g., when 2 groups added to [group1] --> [group1, group2, group3] === [group2, group3]
        -countOfNodeGroupsCreated
      );

      const updatedNodes = nodes.map((n) => {
        // We only need to update properties for UI Only group nodes
        if (!isUIOnlyGroupNode(n.id)) return n;

        const { id, namespace } = assertNonNil(
          // Pull off the first one to maintain order
          createdVizGroups.shift(),
          `Could not find viz group for ${n.id}`
        );

        const nodeId = getNodeId({ id, afterDeath: namespace as AfterDeath });
        // Re-associate that new group id back to this local node.
        // Return all the node attributes, but specifically we are targeting extraction of the position
        // of that node so that we can save it to the node configurations
        return { ...n, id: nodeId };
      });

      return updateWaterfallWithFullResponse({
        variables: getUpdateWaterfallVariables(updatedWF, updatedNodes),
      });
      /******************* @end Node Group creation code block */
    },
    [nodes, updateWaterfallLayout, updateWaterfallWithFullResponse, waterfall]
  );

  return { createNodeGroup };
}
