import Decimal from 'decimal.js';
import { first } from 'lodash';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import {
  AugmentedUpdateCltProposalInput,
  AugmentedUpdateCrtProposalInput,
  AugmentedUpdateProposalInput,
} from '@/types/schema';
import { formatPercent } from '@/utils/formatting/percent';
import { getNodes } from '@/utils/graphqlUtils';

import { PreTaxReturnLimitModalQuery } from './graphql/PreTaxReturnLimitModalQuery.generated';
import { DEFAULT_PRE_TAX_RETURN_LIMIT_MODAL_FORM as DEFAULT_FORM } from './PreTaxReturnLimitModal.constants';
import {
  NAMESPACE,
  PreTaxReturnLimitModalForm,
} from './PreTaxReturnLimitModal.types';

const DEFAULT_VALUES = DEFAULT_FORM[NAMESPACE];

export function mapPreTaxReturnLimitQueryToForm(
  data: PreTaxReturnLimitModalQuery
): PreTaxReturnLimitModalForm {
  const proposal = first(getNodes(data?.proposals));
  const charitableProposal = proposal?.crtProposal || proposal?.cltProposal;

  if (!charitableProposal) {
    throw new Error('Could not find proposal');
  }

  return {
    [NAMESPACE]: {
      charitableProposalId: charitableProposal.id || '',
      preTaxReturnPercentageLow:
        charitableProposal.preTaxReturnPercentageLow ||
        DEFAULT_VALUES.preTaxReturnPercentageLow,
      preTaxReturnPercentageMedium:
        charitableProposal.preTaxReturnPercentageMedium ||
        DEFAULT_VALUES.preTaxReturnPercentageMedium,
      preTaxReturnPercentageHigh:
        charitableProposal.preTaxReturnPercentageHigh ||
        DEFAULT_VALUES.preTaxReturnPercentageHigh,
      taxDragPercentageLow:
        charitableProposal.taxDragPercentageLow ||
        DEFAULT_VALUES.taxDragPercentageLow,
      taxDragPercentageMedium:
        charitableProposal.taxDragPercentageMedium ||
        DEFAULT_VALUES.taxDragPercentageMedium,
      taxDragPercentageHigh:
        charitableProposal.taxDragPercentageHigh ||
        DEFAULT_VALUES.taxDragPercentageHigh,
    },
  };
}

export function calculateAfterTaxReturnPercentage(
  preTaxReturnPercentage: Decimal | undefined,
  taxDragPercentage: Decimal | undefined
): string {
  if (!preTaxReturnPercentage || !taxDragPercentage) {
    return EMPTY_CONTENT_HYPHEN;
  }

  // have to use decimal representations here, since multiplying numbers <1 will result in smaller values
  const preTaxReturnDecimal = preTaxReturnPercentage.dividedBy(100);
  const taxDragDecimal = taxDragPercentage.dividedBy(100);

  const afterTaxReturnDecimal = preTaxReturnDecimal.minus(
    preTaxReturnDecimal.times(taxDragDecimal).absoluteValue()
  );

  const afterTaxReturnPercentage = afterTaxReturnDecimal.times(100);

  return `${formatPercent(afterTaxReturnPercentage, 2)}%`;
}

export function mapFormToAugmentedUpdateProposalInput(
  data: PreTaxReturnLimitModalForm,
  proposalId: string,
  isCRT: boolean
): AugmentedUpdateProposalInput {
  const formData = data[NAMESPACE];
  let updateCltProposal: AugmentedUpdateCltProposalInput | undefined =
    undefined;
  let updateCrtProposal: AugmentedUpdateCrtProposalInput | undefined =
    undefined;

  if (isCRT) {
    updateCrtProposal = {
      id: formData.charitableProposalId,
      update: {
        preTaxReturnPercentageLow: formData.preTaxReturnPercentageLow,
        preTaxReturnPercentageMedium: formData.preTaxReturnPercentageMedium,
        preTaxReturnPercentageHigh: formData.preTaxReturnPercentageHigh,
        taxDragPercentageLow: formData.taxDragPercentageLow,
        taxDragPercentageMedium: formData.taxDragPercentageMedium,
        taxDragPercentageHigh: formData.taxDragPercentageHigh,
      },
    };
  } else {
    updateCltProposal = {
      id: formData.charitableProposalId,
      update: {
        preTaxReturnPercentageLow: formData.preTaxReturnPercentageLow,
        preTaxReturnPercentageMedium: formData.preTaxReturnPercentageMedium,
        preTaxReturnPercentageHigh: formData.preTaxReturnPercentageHigh,
        taxDragPercentageLow: formData.taxDragPercentageLow,
        taxDragPercentageMedium: formData.taxDragPercentageMedium,
        taxDragPercentageHigh: formData.taxDragPercentageHigh,
      },
    };
  }
  const output: AugmentedUpdateProposalInput = {
    id: proposalId,
    update: {},
    updateCltProposal,
    updateCrtProposal,
  };
  return output;
}
