import { alpha, Backdrop, Typography } from '@mui/material';
import { ComponentProps } from 'react';

import { PRIMITIVES } from '@/styles/tokens/colors';
import { zIndexes } from '@/styles/zIndexes';

import { Loader } from '../Loader/Loader';

interface LoadingOverlayProps extends ComponentProps<typeof Backdrop> {
  updatingText?: string;
}

export function LoadingOverlay({
  updatingText,
  ...backdropProps
}: LoadingOverlayProps) {
  return (
    <Backdrop
      sx={{
        zIndex: zIndexes.HIGHLIGHT,
        position: 'absolute',
        backgroundColor: alpha(PRIMITIVES.WHITE, 0.88),
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
      {...backdropProps}
    >
      <Loader circularProgressProps={{ size: 40 }} />
      {updatingText && <Typography variant="h5">{updatingText}</Typography>}
    </Backdrop>
  );
}
