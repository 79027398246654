// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientDetailsEstateWaterfall_EntityFragment = { __typename?: 'Entity', id: string };

export type ClientDetailsEstateWaterfall_EstateWaterfallFragment = { __typename?: 'EstateWaterfall', id: string, createdAt: Time, updatedAt: Time };

export type ClientDetailsEstateWaterfallQueryVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
}>;


export type ClientDetailsEstateWaterfallQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, entities: { __typename?: 'EntityConnection', edges?: Array<{ __typename?: 'EntityEdge', node?: { __typename?: 'Entity', id: string } | null } | null> | null }, estateWaterfalls?: Array<{ __typename?: 'EstateWaterfall', id: string, createdAt: Time, updatedAt: Time }> | null } | null } | null> | null } };

export const ClientDetailsEstateWaterfall_EntityFragmentDoc = gql`
    fragment ClientDetailsEstateWaterfall_Entity on Entity {
  id
}
    `;
export const ClientDetailsEstateWaterfall_EstateWaterfallFragmentDoc = gql`
    fragment ClientDetailsEstateWaterfall_EstateWaterfall on EstateWaterfall {
  id
  createdAt
  updatedAt
}
    `;
export const ClientDetailsEstateWaterfallDocument = gql`
    query ClientDetailsEstateWaterfall($householdId: ID!) {
  households(where: {id: $householdId}) {
    edges {
      node {
        id
        entities {
          edges {
            node {
              ...ClientDetailsEstateWaterfall_Entity
            }
          }
        }
        estateWaterfalls {
          ...ClientDetailsEstateWaterfall_EstateWaterfall
        }
      }
    }
  }
}
    ${ClientDetailsEstateWaterfall_EntityFragmentDoc}
${ClientDetailsEstateWaterfall_EstateWaterfallFragmentDoc}`;

/**
 * __useClientDetailsEstateWaterfallQuery__
 *
 * To run a query within a React component, call `useClientDetailsEstateWaterfallQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsEstateWaterfallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsEstateWaterfallQuery({
 *   variables: {
 *      householdId: // value for 'householdId'
 *   },
 * });
 */
export function useClientDetailsEstateWaterfallQuery(baseOptions: Apollo.QueryHookOptions<ClientDetailsEstateWaterfallQuery, ClientDetailsEstateWaterfallQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientDetailsEstateWaterfallQuery, ClientDetailsEstateWaterfallQueryVariables>(ClientDetailsEstateWaterfallDocument, options);
      }
export function useClientDetailsEstateWaterfallLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientDetailsEstateWaterfallQuery, ClientDetailsEstateWaterfallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientDetailsEstateWaterfallQuery, ClientDetailsEstateWaterfallQueryVariables>(ClientDetailsEstateWaterfallDocument, options);
        }
export function useClientDetailsEstateWaterfallSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientDetailsEstateWaterfallQuery, ClientDetailsEstateWaterfallQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientDetailsEstateWaterfallQuery, ClientDetailsEstateWaterfallQueryVariables>(ClientDetailsEstateWaterfallDocument, options);
        }
export type ClientDetailsEstateWaterfallQueryHookResult = ReturnType<typeof useClientDetailsEstateWaterfallQuery>;
export type ClientDetailsEstateWaterfallLazyQueryHookResult = ReturnType<typeof useClientDetailsEstateWaterfallLazyQuery>;
export type ClientDetailsEstateWaterfallSuspenseQueryHookResult = ReturnType<typeof useClientDetailsEstateWaterfallSuspenseQuery>;
export type ClientDetailsEstateWaterfallQueryResult = Apollo.QueryResult<ClientDetailsEstateWaterfallQuery, ClientDetailsEstateWaterfallQueryVariables>;
export const Operations = {
  Query: {
    ClientDetailsEstateWaterfall: 'ClientDetailsEstateWaterfall' as const
  },
  Fragment: {
    ClientDetailsEstateWaterfall_Entity: 'ClientDetailsEstateWaterfall_Entity' as const,
    ClientDetailsEstateWaterfall_EstateWaterfall: 'ClientDetailsEstateWaterfall_EstateWaterfall' as const
  }
}