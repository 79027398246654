import { PopperContent } from '@/components/poppers/PopperContent';

export function GRATTerm() {
  return (
    <PopperContent
      body={
        <span>
          Consider the expected return profile and volatility of GRAT assets as
          well as the grantor&apos;s age when selecting a GRAT term.
        </span>
      }
    />
  );
}
