import { Box } from '@mui/material';
import {
  FieldArrayWithId,
  FieldValues,
  UseFieldArrayReturn,
} from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { useFormFieldsDisabled } from '@/components/form/context/formFieldsDisabled.context';
import { FormAwareCardRepeaterProps } from '@/components/form/formAwareInputs/FormAwareCardRepeater/FormAwareCardRepeater';
import { AdditionalItemRendererProps } from '@/components/form/formAwareInputs/FormAwareInput.types';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { Card, CardVariant } from '@/components/layout/Card/Card';
import { ArrayFieldNameFromFormShape } from '@/types/react-hook-form';

export interface CardFieldItemProps<
  FormShape extends FieldValues,
  FieldArrayKeyName extends string,
> {
  i: number;
  value: FieldArrayWithId<
    FormShape,
    ArrayFieldNameFromFormShape<FormShape>,
    FieldArrayKeyName
  >;
  render: FormAwareCardRepeaterProps<FormShape, FieldArrayKeyName>['render'];
  fieldArrayProps: Pick<
    UseFieldArrayReturn<
      FormShape,
      ArrayFieldNameFromFormShape<FormShape>,
      FieldArrayKeyName
    >,
    'swap' | 'fields' | 'remove'
  >;
  showDeleteButton: boolean;
  removeButtonText?: string;
  variant: CardVariant;
  spacing: number;
  fieldArrayKeyName: FieldArrayKeyName;
  additionalItemRendererProps?: AdditionalItemRendererProps;
  // An optional callback when the "delete" button is clicked. This is mainly
  // used to track which item was deleted from a DuplicatedCardFieldGroup.
  onDeleteItemClick?: () => void;
}

/**
 * A single card item of a FormAwareCardRepeater.
 */
export function CardFieldItem<
  FormShape extends FieldValues,
  FieldArrayKeyName extends string,
>({
  i,
  value,
  render,
  fieldArrayProps: { swap, fields, remove },
  showDeleteButton,
  removeButtonText,
  variant,
  spacing,
  fieldArrayKeyName,
  additionalItemRendererProps,
  onDeleteItemClick,
}: CardFieldItemProps<FormShape, FieldArrayKeyName>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();

  return (
    <Card
      key={value[fieldArrayKeyName]}
      variant={variant}
      sx={{
        mb: spacing,
        borderRadius: '6px',
      }}
      data-testid="repeater-input-item"
    >
      {render(
        i,
        { swap, fields, remove },
        value[fieldArrayKeyName],
        additionalItemRendererProps
      )}
      {showDeleteButton && (
        <Box
          sx={{
            textAlign: 'right',
            p: spacing,
            pt: 1,
            pb: spacing + 1,
          }}
        >
          <Button
            disabled={disabledFromContext}
            variant="destructive-transparent"
            size="sm"
            onClick={() => {
              remove(i);
              onDeleteItemClick?.();
            }}
            startIcon={Trash01Icon}
            data-testid="button-remove-repeater-input-item"
          >
            {removeButtonText || 'Remove'}
          </Button>
        </Box>
      )}
    </Card>
  );
}
