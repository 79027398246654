import { Typography } from '@mui/material';
import { useEffect } from 'react';

import { FormAwareCard } from '@/components/form/formAwareInputs/FormAwareCard';
import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { CheckboxListWrapper } from '@/components/form/formAwareInputs/FormAwareCheckboxList/FormAwareCheckboxList';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { ModalFormLayout } from '@/components/form/layout/ModalFormLayout';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { PathsOf } from '@/types/subform';

import { PrimaryClientDropdown_PossibleGrantorFragment } from '../../inputs/PrimaryClientDropdown/graphql/PrimaryClientDropdown.generated';
import { ConvertEntityFormShape } from './ConvertEntityModal.constants';
import { getSelectedOwnerIds } from './ConvertEntityModal.utils';

interface ConvertEntityFormProps {
  variant: 'individual' | 'joint';
  possibleOwners: PrimaryClientDropdown_PossibleGrantorFragment[];
  currentOwnerIds: string[];
}

export function ConvertEntityForm({
  variant,
  possibleOwners,
  currentOwnerIds,
}: ConvertEntityFormProps) {
  const { control, setValue } = useFormContext<ConvertEntityFormShape>();

  useEffect(() => {
    if (variant === 'joint') {
      // Set the values in the form to true for the current owner
      currentOwnerIds.forEach((currOwner) => {
        setValue(`owners.${currOwner}`, true);
      });
    }
  }, [currentOwnerIds, setValue, variant]);

  return (
    <ModalFormLayout>
      <FormLayoutRow>
        <FormLayoutItem>
          {variant === 'individual' && (
            <Card variant="filled" sx={{ p: 3 }}>
              <Typography variant="h4" sx={{ pb: 2 }}>
                Owners *
              </Typography>
              <FormAwareRadioGroup<ConvertEntityFormShape>
                control={control}
                fieldName="ownerId"
                options={possibleOwners.map((o) => ({
                  value: o.id,
                  label: o.displayName,
                }))}
                hideLabel
                required
                row={false}
              />
            </Card>
          )}
          {variant === 'joint' && (
            <FormAwareCard<ConvertEntityFormShape>
              variant="filled"
              control={control}
              validation={{
                minLength: (value) => {
                  if (
                    getSelectedOwnerIds(value as Record<string, boolean>)
                      .length < 1
                  ) {
                    return 'At least one owner is required';
                  }
                  return undefined;
                },
              }}
              fieldName={
                `owners` as const satisfies PathsOf<ConvertEntityFormShape>
              }
              sx={{ p: 3 }}
            >
              <Typography variant="h4" sx={{ pb: 2 }}>
                Owners *
              </Typography>
              <CheckboxListWrapper>
                {possibleOwners.map((owner) => {
                  return (
                    <FormAwareCheckbox
                      key={owner.id}
                      label={owner.displayName}
                      fieldName={`owners.${owner.id}`}
                      control={control}
                    />
                  );
                })}
              </CheckboxListWrapper>
            </FormAwareCard>
          )}
        </FormLayoutItem>
      </FormLayoutRow>
    </ModalFormLayout>
  );
}
