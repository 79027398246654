// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { AsyncJobStatus_AsyncJobFragmentDoc } from '../../asyncJobs/graphql/AsyncJobs.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocumentAiDetails_DocumentFragment = { __typename?: 'Document', id: string, enableAiSuggestions?: boolean | null, asyncJobs: { __typename?: 'AsyncJobConnection', edges?: Array<{ __typename?: 'AsyncJobEdge', node?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null } | null> | null } };

export type DocumentAiDetailsQueryVariables = Types.Exact<{
  documentId: Types.Scalars['ID']['input'];
}>;


export type DocumentAiDetailsQuery = { __typename?: 'Query', documents: { __typename?: 'DocumentConnection', edges?: Array<{ __typename?: 'DocumentEdge', node?: { __typename?: 'Document', id: string, enableAiSuggestions?: boolean | null, asyncJobs: { __typename?: 'AsyncJobConnection', edges?: Array<{ __typename?: 'AsyncJobEdge', node?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null } | null> | null } } | null } | null> | null } };

export type UpdateDocumentAiEnabledMutationVariables = Types.Exact<{
  documentId: Types.Scalars['ID']['input'];
}>;


export type UpdateDocumentAiEnabledMutation = { __typename?: 'Mutation', updateDocument: { __typename?: 'Document', id: string, enableAiSuggestions?: boolean | null, asyncJobs: { __typename?: 'AsyncJobConnection', edges?: Array<{ __typename?: 'AsyncJobEdge', node?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null } | null> | null } } };

export const DocumentAiDetails_DocumentFragmentDoc = gql`
    fragment DocumentAiDetails_Document on Document {
  id
  enableAiSuggestions
  asyncJobs(
    where: {kind: DOCUMENT_INFERENCE_AI_SUGGESTION, statusIn: [QUEUED, IN_PROGRESS]}
    orderBy: [{direction: DESC, field: created_at}]
    first: 1
  ) {
    edges {
      node {
        ...AsyncJobStatus_AsyncJob
      }
    }
  }
}
    ${AsyncJobStatus_AsyncJobFragmentDoc}`;
export const DocumentAiDetailsDocument = gql`
    query DocumentAiDetails($documentId: ID!) {
  documents(where: {id: $documentId}) {
    edges {
      node {
        ...DocumentAiDetails_Document
      }
    }
  }
}
    ${DocumentAiDetails_DocumentFragmentDoc}`;

/**
 * __useDocumentAiDetailsQuery__
 *
 * To run a query within a React component, call `useDocumentAiDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentAiDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentAiDetailsQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentAiDetailsQuery(baseOptions: Apollo.QueryHookOptions<DocumentAiDetailsQuery, DocumentAiDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentAiDetailsQuery, DocumentAiDetailsQueryVariables>(DocumentAiDetailsDocument, options);
      }
export function useDocumentAiDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentAiDetailsQuery, DocumentAiDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentAiDetailsQuery, DocumentAiDetailsQueryVariables>(DocumentAiDetailsDocument, options);
        }
export function useDocumentAiDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentAiDetailsQuery, DocumentAiDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentAiDetailsQuery, DocumentAiDetailsQueryVariables>(DocumentAiDetailsDocument, options);
        }
export type DocumentAiDetailsQueryHookResult = ReturnType<typeof useDocumentAiDetailsQuery>;
export type DocumentAiDetailsLazyQueryHookResult = ReturnType<typeof useDocumentAiDetailsLazyQuery>;
export type DocumentAiDetailsSuspenseQueryHookResult = ReturnType<typeof useDocumentAiDetailsSuspenseQuery>;
export type DocumentAiDetailsQueryResult = Apollo.QueryResult<DocumentAiDetailsQuery, DocumentAiDetailsQueryVariables>;
export const UpdateDocumentAiEnabledDocument = gql`
    mutation UpdateDocumentAiEnabled($documentId: ID!) {
  updateDocument(id: $documentId, input: {enableAiSuggestions: true}) {
    ...DocumentAiDetails_Document
  }
}
    ${DocumentAiDetails_DocumentFragmentDoc}`;
export type UpdateDocumentAiEnabledMutationFn = Apollo.MutationFunction<UpdateDocumentAiEnabledMutation, UpdateDocumentAiEnabledMutationVariables>;

/**
 * __useUpdateDocumentAiEnabledMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentAiEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentAiEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentAiEnabledMutation, { data, loading, error }] = useUpdateDocumentAiEnabledMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useUpdateDocumentAiEnabledMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentAiEnabledMutation, UpdateDocumentAiEnabledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentAiEnabledMutation, UpdateDocumentAiEnabledMutationVariables>(UpdateDocumentAiEnabledDocument, options);
      }
export type UpdateDocumentAiEnabledMutationHookResult = ReturnType<typeof useUpdateDocumentAiEnabledMutation>;
export type UpdateDocumentAiEnabledMutationResult = Apollo.MutationResult<UpdateDocumentAiEnabledMutation>;
export type UpdateDocumentAiEnabledMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentAiEnabledMutation, UpdateDocumentAiEnabledMutationVariables>;
export const Operations = {
  Query: {
    DocumentAiDetails: 'DocumentAiDetails' as const
  },
  Mutation: {
    UpdateDocumentAiEnabled: 'UpdateDocumentAiEnabled' as const
  },
  Fragment: {
    DocumentAiDetails_Document: 'DocumentAiDetails_Document' as const
  }
}