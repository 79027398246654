import { compact } from 'lodash';
import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { getOptionsWithGroupLabels } from '@/components/form/baseInputs/SelectInput/SelectInput.utils';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { EntityKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { businessEntityKinds } from './BusinessEntityDetailsSubform.constants';
import {
  BusinessEntitiesFormOptionsQuery,
  useBusinessEntitiesFormOptionsQuery,
} from './graphql/BusinessEntityDetailsSubform.generated';

export function useFetchBusinessEntityDetailsOptions(
  householdId: string,
  currentEntityId: string | null,
  selectedOwnerIds: string[] = [],
  selectedKeyPersonIds: string[] = []
) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { data, ...queryProps } = useBusinessEntitiesFormOptionsQuery({
    variables: {
      householdId,
    },
    onError: (error) => {
      reportError('failed to fetch business entities form options', error);
      showFeedback(
        'Failed to fetch ownership and key people options. Please refresh the page to try again.'
      );
    },
    fetchPolicy: 'cache-and-network',
  });

  const { ownerOptions, keyPeopleOptions } =
    useCreateBusinessEntityDetailsOptions(
      data,
      currentEntityId,
      selectedOwnerIds,
      selectedKeyPersonIds
    );

  return { ownerOptions, keyPeopleOptions, ...queryProps };
}

interface UseGetBeneficiaryOptionsReturnValue {
  ownerOptions: SelectInputProps['options'];
  keyPeopleOptions: SelectInputProps['options'];
}

export function useCreateBusinessEntityDetailsOptions(
  queryData: BusinessEntitiesFormOptionsQuery | undefined,
  // this can be null in the case where we're creating a new entity
  currentEntityId: string | null,
  selectedOwnerIds: string[] = [],
  selectedKeyPersonIds: string[] = []
): UseGetBeneficiaryOptionsReturnValue {
  const household = queryData?.households?.edges?.[0]?.node;
  const entities = getNodes(queryData?.entities);

  // ownedEntityIds is a list of entities that this entity currently owns; we don't
  // want to allow this entity to own another entity that in turns owns it, because it makes
  // for a circular ownership structure that is impossible to calculate valuations for
  // NOTE: this only guards against *two levels* of circular ownership; it's not clear how
  // many levels we should guard against, but this seems sufficient for now
  const ownedEntityIds = compact(
    entities.flatMap((e) => {
      if (e.id !== currentEntityId) return [];
      return (
        e.ownedOwnershipStakes?.flatMap((s) => {
          // get the second-level owned entity ids
          const nextLevelOwnedEntityIds =
            s.ownedEntity?.ownedOwnershipStakes?.map(
              (s2) => s2.ownedEntity?.id
            ) ?? [];

          // return the first-level owned entity id and the second-level owned entity ids
          return compact([s.ownedEntity?.id, ...nextLevelOwnedEntityIds]);
        }) ?? []
      );
    })
  );

  // OWNERS OPTIONS
  const ownerEntityOptions: SelectInputOption<string>[] = entities
    .filter((e) => {
      // the only kind of entity that cannot be an owner of a business entity right now is a GRAT
      // https://www.notion.so/withluminary/Don-t-support-GRATs-owning-business-entities-for-MVP-a2a4c82bd8604140ae2835b6ec968d01
      // and an entity can't own itself
      return e.kind !== EntityKind.GratTrust && e.id !== currentEntityId;
    })
    .map((e) => {
      const isCircularOwnershipOption = ownedEntityIds.includes(e.id);
      const notEligibleToBeOwner =
        selectedOwnerIds.includes(e.id) || isCircularOwnershipOption;
      return {
        value: e.id,
        display: compact([
          e.subtype.displayName,
          isCircularOwnershipOption && '(circular ownership)',
        ]).join(' '),
        disabled: notEligibleToBeOwner,
      };
    });

  // KEY PEOPLE OPTIONS
  const entityKeyPeopleOptions: SelectInputOption<string>[] = entities
    .filter((e) => {
      // business entities can't be key people for other business entities,
      // and a business entity can't be a key person for itself
      return businessEntityKinds.has(e.kind) && e.id !== currentEntityId;
    })
    .map((e) => ({
      value: e.id,
      display: e.subtype.displayName,
      disabled: selectedKeyPersonIds.includes(e.id),
    }));

  const organizationKeyPeopleOptions: SelectInputOption<string>[] = (
    household?.clientOrganizations ?? []
  )
    .filter((o) => o.isTrustee)
    .map((o) => ({
      value: o.id,
      display: o.name,
      disabled: selectedKeyPersonIds.includes(o.id),
    }));

  // KEY PEOPLE CLIENT PROFILE OPTIONS
  const keyPeopleClientProfileOptions: SelectInputOption<string>[] = (
    household?.clientProfiles ?? []
  ).map((b) => ({
    display: b.legalName,
    value: b.id,
    disabled: selectedKeyPersonIds.includes(b.id),
  }));

  // OWNER CLIENT PROFILE OPTIONS (needs to be different from the key people client profile
  // options because we can't use the same person in two different key people slots, but the
  // same person can be both an owner and a key person)
  const ownerClientProfileOptions: SelectInputOption<string>[] = (
    household?.clientProfiles ?? []
  ).map((b) => ({
    display: b.legalName,
    value: b.id,
    disabled: selectedOwnerIds.includes(b.id),
  }));

  const keyPeopleOptions = useMemo(
    () =>
      getOptionsWithGroupLabels([
        {
          label: 'Individuals',
          options: keyPeopleClientProfileOptions.sort((a, b) =>
            a.display.localeCompare(b.display)
          ),
        },
        {
          label: 'Organizations',
          options: organizationKeyPeopleOptions.sort((a, b) =>
            a.display.localeCompare(b.display)
          ),
        },
        {
          label: 'Entities',
          options: entityKeyPeopleOptions.sort((a, b) =>
            a.display.localeCompare(b.display)
          ),
        },
      ]),
    [
      keyPeopleClientProfileOptions,
      entityKeyPeopleOptions,
      organizationKeyPeopleOptions,
    ]
  );

  const ownerOptions = useMemo(
    () =>
      getOptionsWithGroupLabels([
        {
          label: 'Individuals',
          options: ownerClientProfileOptions.sort((a, b) =>
            a.display.localeCompare(b.display)
          ),
        },
        {
          label: 'Entities',
          options: ownerEntityOptions.sort((a, b) =>
            a.display.localeCompare(b.display)
          ),
        },
      ]),
    [ownerClientProfileOptions, ownerEntityOptions]
  );

  return { ownerOptions, keyPeopleOptions };
}
