import { Divider, Stack } from '@mui/material';
import { BaseSyntheticEvent, useEffect, useRef } from 'react';
import {
  FieldArrayWithId,
  Path,
  UseFieldArrayInsert,
  UseFieldArrayReturn,
  useWatch,
} from 'react-hook-form';

import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { ButtonGroupInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { TabContent } from '@/components/navigation/NavigationTabs';
import { TabsProvider } from '@/components/navigation/NavigationTabs/Tabs.provider';
import { useFormContext } from '@/components/react-hook-form';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { COLORS } from '@/styles/tokens/colors';

import { useGiftDesignerModelScenariosContext } from '../../contexts/GiftDesignerModelScenarios.context';
import { GiftDesignerModelScenariosFormShape } from '../../GiftDesignerModelScenariosForm.types';
import { GiftScenarioCardTitle } from './GiftScenarioCardTitle';
import {
  GiftScenarioTab,
  GiftScenarioTabContent,
} from './GiftScenarioTabContent';

type GiftScenarioProps = UseFieldArrayReturn<
  GiftDesignerModelScenariosFormShape,
  'scenarios',
  '_id'
> & {
  scenario: FieldArrayWithId<
    GiftDesignerModelScenariosFormShape,
    'scenarios',
    '_id'
  >;
  duplicate: UseFieldArrayInsert<
    GiftDesignerModelScenariosFormShape,
    'scenarios'
  >;
  idx: number;
  triggerSave: (
    e?: BaseSyntheticEvent<object, unknown, unknown> | undefined
  ) => Promise<void>;
  proposalId: string;
  householdId: string;
};

export function GiftScenario({
  scenario,
  remove,
  move,
  duplicate,
  idx,
  triggerSave,
  proposalId,
  householdId,
}: GiftScenarioProps) {
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();
  const shouldShowAssumeExemptionSunsetsToggle = isFeatureFlagEnabled(
    'gift_proposal_enhancements'
  );

  const isNoGiftingScenario =
    'isNoGiftingScenario' in scenario && scenario.isNoGiftingScenario;

  const { scenarioTabState, setScenarioTabState } =
    useGiftDesignerModelScenariosContext();

  const currentTab = scenarioTabState[idx] ?? GiftScenarioTab.GIFT;

  const options: ButtonGroupInputOption<GiftScenarioTab>[] = [
    {
      display: 'Gift details',
      value: GiftScenarioTab.GIFT,
    },
    {
      display: 'Cash flow',
      value: GiftScenarioTab.INCOME_OR_EXPENSE,
    },
  ];

  const exemptionSunsets = useWatch({
    control,
    name: `scenarios.${idx}.exemptionSunsets` as const satisfies Path<GiftDesignerModelScenariosFormShape>,
  });
  // Use a ref to track when the exemption sunsets value changes
  const exemptionSunsetsRef = useRef(exemptionSunsets);

  // Effect to trigger a save when the exemption sunsets toggle is changed
  useEffect(() => {
    if (exemptionSunsets === undefined) {
      return;
    }

    if (exemptionSunsetsRef.current !== exemptionSunsets) {
      void triggerSave?.();
    }

    exemptionSunsetsRef.current = exemptionSunsets;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exemptionSunsets]);

  return (
    <Card
      variant="outlined"
      sx={{
        p: 3,
        borderRadius: '6px',
      }}
    >
      <Stack
        divider={
          <Divider
            sx={{
              borderColor: COLORS.GRAY[200],
            }}
          />
        }
      >
        <Stack>
          <GiftScenarioCardTitle
            scenario={scenario}
            remove={remove}
            move={move}
            duplicate={duplicate}
            idx={idx}
            proposalId={proposalId}
            householdId={householdId}
          />
        </Stack>
        {isNoGiftingScenario && shouldShowAssumeExemptionSunsetsToggle && (
          <Stack pt={3}>
            <FormAwareSwitch<GiftDesignerModelScenariosFormShape>
              control={control}
              fieldName={
                `scenarios.${idx}.exemptionSunsets` as const satisfies Path<GiftDesignerModelScenariosFormShape>
              }
              label="Assume exemption sunsets"
              labelPosition="right"
            />
          </Stack>
        )}
        {!isNoGiftingScenario && (
          <Stack spacing={3} pt={3}>
            <FormAwareTextInput<GiftDesignerModelScenariosFormShape>
              control={control}
              fieldName={
                `scenarios.${idx}.name` as const satisfies Path<GiftDesignerModelScenariosFormShape>
              }
              label="Name"
              required
            />
            {shouldShowAssumeExemptionSunsetsToggle && (
              <FormAwareSwitch<GiftDesignerModelScenariosFormShape>
                control={control}
                fieldName={
                  `scenarios.${idx}.exemptionSunsets` as const satisfies Path<GiftDesignerModelScenariosFormShape>
                }
                label="Assume exemption sunsets"
                labelPosition="right"
              />
            )}
            <TabsProvider currentTab={currentTab}>
              <ButtonGroup<GiftScenarioTab>
                label=""
                testId="GiftScenarioTabButtonGroup"
                value={currentTab}
                options={options}
                onChange={(_, value) => {
                  setScenarioTabState({
                    ...scenarioTabState,
                    [idx]: value,
                  });
                }}
              />

              <TabContent<GiftScenarioTab> tabKey={GiftScenarioTab.GIFT}>
                <GiftScenarioTabContent
                  tabKey={GiftScenarioTab.GIFT}
                  scenarioIdx={idx}
                  triggerSave={triggerSave}
                  proposalId={proposalId}
                />
              </TabContent>
              <TabContent<GiftScenarioTab>
                tabKey={GiftScenarioTab.INCOME_OR_EXPENSE}
              >
                <GiftScenarioTabContent
                  tabKey={GiftScenarioTab.INCOME_OR_EXPENSE}
                  scenarioIdx={idx}
                  triggerSave={triggerSave}
                  proposalId={proposalId}
                />
              </TabContent>
            </TabsProvider>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
