import { isUndefined } from 'lodash';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { useForm } from '@/components/react-hook-form';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { diagnostics } from '@/utils/diagnostics';

import { EstateWaterfallComparisonShape } from '../EstateWaterfallComparison.type';
import {
  EstateWaterfallComparisonModalLegacy,
  EstateWaterfallComparisonModalLegacyProps,
} from '../EstateWaterfallComparisonModalLegacy/EstateWaterfallComparisonModalLegacy';
import { EstateWaterfallComparisonModalContextProvider } from './EstateWatefallComparisonModal.context';
import { EstateWaterfallComparisonModalBody } from './EstateWaterfallComparisonModal.components';
import { useDefaultEstateWaterfallComparisonFormState } from './EstateWaterfallComparisonModal.hooks';

interface EstateWaterfallComparisonModalPropsBase {
  legacyProps?: Omit<EstateWaterfallComparisonModalLegacyProps, 'isOpen'>;
  onClose: () => void;
  onSubmit: (comparison: EstateWaterfallComparisonShape) => void;
  isEditing?: boolean;
  isPresentation?: boolean;
  comparisonState?: EstateWaterfallComparisonShape | null | undefined;
  baseWaterfallId?: string;
}
interface EstateWaterfallComparisonModalPropsWithComparisonState
  extends EstateWaterfallComparisonModalPropsBase {
  comparisonState: EstateWaterfallComparisonShape;
}

interface EstateWaterfallComparisonModalPropsWithBaseWaterfallId
  extends EstateWaterfallComparisonModalPropsBase {
  baseWaterfallId: string;
}

export type EstateWaterfallComparisonModalProps =
  | EstateWaterfallComparisonModalPropsWithComparisonState
  | EstateWaterfallComparisonModalPropsWithBaseWaterfallId;

export function EstateWaterfallComparisonModal({
  legacyProps,
  comparisonState: comparisonStateExternal,
  onClose,
  onSubmit: onSubmitExternal,
  isEditing,
  isPresentation,
  baseWaterfallId,
}: EstateWaterfallComparisonModalProps) {
  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');
  const { loading, form: defaultValues } =
    useDefaultEstateWaterfallComparisonFormState(
      comparisonStateExternal,
      baseWaterfallId
    );
  const formMethods = useForm<EstateWaterfallComparisonShape>({
    defaultValues,
  });
  const wasLoading = usePrevious(loading);

  const { reset } = formMethods;

  useEffect(() => {
    if (!isGrowthProfilesEnabled) return;

    if (isUndefined(wasLoading)) return;
    if (loading) return;

    if (wasLoading && !loading) {
      reset(defaultValues);
    }
  }, [defaultValues, isGrowthProfilesEnabled, loading, reset, wasLoading]);

  if (!isGrowthProfilesEnabled) {
    if (legacyProps) {
      return <EstateWaterfallComparisonModalLegacy {...legacyProps} isOpen />;
    }
    diagnostics.error(
      'EstateWaterfallComparisonModal: no legacy props provided and flag is off'
    );
    return null;
  }

  const onSubmit = formMethods.handleSubmit((data) => {
    onSubmitExternal(data);
    onClose();
  });

  return (
    <EstateWaterfallComparisonModalContextProvider
      isPresentation={isPresentation}
      baseWaterfallId={baseWaterfallId}
    >
      <FormProvider {...formMethods}>
        <FormModal
          heading="Compare waterfalls"
          isOpen
          onClose={onClose}
          actions={
            <FormModalActions.Provider>
              <FormModalActions.CancelButton onClick={onClose} />
              <FormModalActions.SaveButton onClick={onSubmit}>
                {isEditing ? 'Update comparison' : 'Create comparison'}
              </FormModalActions.SaveButton>
            </FormModalActions.Provider>
          }
        >
          <EstateWaterfallComparisonModalBody />
        </FormModal>
      </FormProvider>
    </EstateWaterfallComparisonModalContextProvider>
  );
}
