import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useMinimumLoading } from '@/hooks/useMinimumLoading';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { applyWaterfallFilter } from '../EstateWaterfall.utils';
import { useEstateWaterfallQuery } from '../graphql/EstateWaterfall.generated';
import { getNodeId } from '../waterfallGraph/utils';

interface UseQueryWaterfallProps {
  waterfallId: string;
}

export function useQueryWaterfall({ waterfallId }: UseQueryWaterfallProps) {
  // The whole waterfall gets remounted when the waterfallId changes, so this resets to an empty set automatically
  const [initWaterfallNodeIds, setInitWaterfallNodeIds] = useState<Set<string>>(
    new Set<string>()
  );
  const { primaryClients } = useHouseholdDetailsContext();
  const { showFeedback } = useFeedback();

  const {
    data,
    loading: isFetchingFromNetwork,
    ...rest
  } = useEstateWaterfallQuery({
    variables: {
      where: { id: waterfallId },
    },
    fetchPolicy: 'cache-and-network',
    // This makes loading / isFetchingFromNetwork update in response to refetch queries
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      showFeedback(
        `Sorry, we failed to load the page. Please refresh and try again.`
      );
      diagnostics.error('estate waterfall load failure', error, {
        waterfallId,
      });
    },
  });

  const waterfall = useMemo(() => {
    return getNodes(data?.estateWaterfalls)[0] ?? null;
  }, [data?.estateWaterfalls]);

  const hasData = !!waterfall && !!primaryClients;
  const isRefetching = isFetchingFromNetwork && !!waterfall;

  const isEmptyWaterfall = isEmpty(
    waterfall?.visualizationWithProjections.nodes
  );

  const isInitialLoading = !hasData;
  const isUpdatingWaterfall = useMinimumLoading(isRefetching);

  const waterfallWithFilter = useMemo(() => {
    return applyWaterfallFilter(waterfall);
  }, [waterfall]);

  const isFilteredWaterfall =
    waterfallWithFilter?.visualizationWithProjections.nodes.length !==
    waterfall?.visualizationWithProjections.nodes.length;

  const visibleNodeIds = useMemo(
    () =>
      waterfallWithFilter?.visualizationWithProjections.nodes.map((node) =>
        getNodeId({
          id: node.id,
          afterDeath: node.afterDeath,
        })
      ) ?? [],
    [waterfallWithFilter?.visualizationWithProjections.nodes]
  );

  const hiddenNodeIds = useMemo(() => {
    return (
      waterfall?.visualizationWithProjections.nodes
        .filter(
          (node) =>
            !visibleNodeIds?.includes(
              getNodeId({
                id: node.id,
                afterDeath: node.afterDeath,
              })
            )
        )
        .map((node) =>
          getNodeId({
            id: node.id,
            afterDeath: node.afterDeath,
          })
        ) ?? []
    );
  }, [waterfall?.visualizationWithProjections.nodes, visibleNodeIds]);

  useEffect(() => {
    if (initWaterfallNodeIds.size > 0) return;
    if (!waterfall) return;

    const waterfallIds = new Set<string>();
    waterfall.visualizationWithProjections.nodes.forEach((node) => {
      const nodeId = getNodeId({
        id: node.group?.id ?? node.id,
        afterDeath: node.afterDeath,
      });

      waterfallIds.add(nodeId);
    });

    setInitWaterfallNodeIds(waterfallIds);
  }, [initWaterfallNodeIds.size, waterfall]);

  return {
    isInitialLoading,
    isUpdatingWaterfall,
    isEmptyWaterfall,
    primaryClients,
    waterfall: waterfallWithFilter,
    loading: isFetchingFromNetwork,
    isFilteredWaterfall,
    visibleNodeIds,
    hiddenNodeIds,
    initWaterfallNodeIds,
    ...rest,
  };
}
