import { Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { memo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';

import { StyledCheckbox } from '../Checkbox/Checkbox';
import { FormControl } from '../FormControl';
import {
  BaseMultiSelectInputProps,
  FormControlMultiSelectInputProps,
  HelpTextVariant,
  MultiSelectInputOption,
} from '../inputTypes';

interface MultiSelectProps extends FormControlMultiSelectInputProps {
  label: string;
  id?: string;
  // the presence of contextualHelp indicates that there's help text related to this input, which
  // will cause the "info" icon to show up next to the input label
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  hideLabel?: boolean;
}

interface RenderValueProps {
  selected: string[];
  options: MultiSelectInputOption[];
}

function areEqual(prevProps: RenderValueProps, nextProps: RenderValueProps) {
  if (prevProps.selected.length !== nextProps.selected.length) {
    return false;
  }

  for (let i = 0; i < prevProps.selected.length; i++) {
    if (prevProps.selected[i] !== nextProps.selected[i]) {
      return false;
    }
  }

  return true;
}

function RenderValue({ selected, options }: RenderValueProps) {
  if (selected?.length) {
    return (
      <>
        {selected
          .map((s) => {
            const foundValue = options.find((option) => option?.value === s);
            if (foundValue) {
              return foundValue.abbreviation || foundValue.display;
            }

            return '';
          })
          .join(', ')}
      </>
    );
  }
  return <>—</>;
}

const MemoizedRenderValue = memo(RenderValue, areEqual);

const useStyles = makeStyles<{ disabled: boolean }>()(
  (_theme, { disabled }) => ({
    root: {
      background: disabled ? COLORS.GRAY[50] : 'white',
      maxWidth: 'inherit',
      maxHeight: 500,
    },
    option: {
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    menuPaper: {
      maxHeight: 400,
    },
  })
);

const BaseMultiSelectInput = ({
  options,
  testId,
  required,
  value,
  disabled,
  startAdornment,
  ...props
}: BaseMultiSelectInputProps) => {
  const { classes, cx } = useStyles({ disabled: disabled ?? false });

  return (
    // this select is a non-optimal experience for mobile; consider using NativeSelect here for a mobile device
    <Select
      className={cx(classes.root)}
      MenuProps={{ classes: { paper: cx(classes.menuPaper) } }}
      disabled={disabled}
      displayEmpty
      multiple
      inputProps={{ 'data-testid': testId }}
      value={value}
      required={required}
      renderValue={(selected) => (
        <MemoizedRenderValue selected={selected} options={options} />
      )}
      startAdornment={startAdornment}
      data-testid={`multi-select-${props.name ?? ''}`}
      {...props}
    >
      {options.map((option, i) => {
        return (
          <MenuItem
            key={i}
            value={option.value}
            disabled={option.disabled}
            className={cx(classes.option)}
          >
            <StyledCheckbox
              disabled={option.disabled}
              value={
                // checkbox is driven by the values
                // checked if the option is in the value array
                value.includes(option.value)
              }
              data-testid={`checkbox-${option.value}`}
            />
            <Box ml={1}>{option.display}</Box>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const MultiSelectInput = ({
  id,
  label,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant,
  hideLabel,
  ...inputProps
}: MultiSelectProps) => {
  return (
    <FormControl<BaseMultiSelectInputProps>
      id={id}
      contextualHelp={contextualHelp}
      component={BaseMultiSelectInput}
      componentKind="multiSelect"
      label={label}
      hideLabel={hideLabel}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
      required={inputProps.required}
      errorMessage={errorMessage}
      inputProps={inputProps}
    />
  );
};
