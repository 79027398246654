import Decimal from 'decimal.js';

export const NAMESPACE = 'CHARITABLE_ANALYSIS';

interface CharitableProjection {
  preTaxReturnPercentage: Decimal;
  taxDragPercentage: Decimal;
  noPlan: Decimal;
}

export interface CharitableAnalysisForm {
  [NAMESPACE]: {
    selectedYearIdx: number;
    years: number[];
    selectedProjectionIdx: number;
    projections: CharitableProjection[];
    termYears: number;
  };
  CHARITABLE_TRUST_DESIGNER_ANALYSIS: {
    analysis: {
      yearOfAnalysis: number;
    };
  };
}
