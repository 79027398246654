import Decimal from 'decimal.js';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import {
  AugmentedCreateGrowthProfileOverrideInput,
  GrowthProfileOverrideKind,
} from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';
import { getNodes } from '@/utils/graphqlUtils';

import { GrowthProfileModal_GrowthProfileFragment } from './graphql/GrowthProfileModal.generated';
import {
  CreateGrowthProfileMutationVariables,
  UpdateGrowthProfileMutationVariables,
} from './graphql/GrowthProfileModalMutations.generated';
import {
  CustomEntityGrowthCustomizationType,
  defaultGrowthProfileModalFormShape,
  GrowthProfileCustomGrowthOverrideRowData,
  GrowthProfileModalFormShape,
  GrowthProfileModalGrowthOverride,
} from './GrowthProfileModal.types';

function getGrowthProfileInput(data: GrowthProfileModalFormShape) {
  return {
    displayName: data.displayName,
    exemptionGrowthRate: data.exemptionGrowthRate,
    growthType: data.growthType,
    assetGrowthReturn: data.assetGrowthReturn,
    willExemptionSunset: data.willExemptionSunset,
  };
}

function getGrowthProfileOverrideInput(
  override: GrowthProfileModalGrowthOverride,
  householdID: string
): AugmentedCreateGrowthProfileOverrideInput {
  const {
    id: _id,
    customizationType: _customizationType,
    entity,
    entityId,
    assetClass,
    ...rest
  } = override;
  return {
    create: {
      householdID,
      entityID: entityId ?? entity?.id,
      assetClassID: assetClass?.id,
      ...rest,
    },
  };
}

export function mapFormDataToCreateGrowthProfileInput(
  data: GrowthProfileModalFormShape,
  householdID: string
): CreateGrowthProfileMutationVariables {
  return {
    input: {
      create: {
        householdID,
        ...getGrowthProfileInput(data),
      },
      withGrowthProfileOverrides:
        data.growthProfileOverrides.map<AugmentedCreateGrowthProfileOverrideInput>(
          (override) => getGrowthProfileOverrideInput(override, householdID)
        ),
    },
  };
}

export function mapFormDataToUpdateGrowthProfileInput(
  data: GrowthProfileModalFormShape,
  householdID: string,
  growthProfileId: string
): UpdateGrowthProfileMutationVariables {
  return {
    input: {
      id: growthProfileId,
      update: {
        ...getGrowthProfileInput(data),
        clearGrowthProfileOverrides: true,
      },
      withGrowthProfileOverrides:
        data.growthProfileOverrides.map<AugmentedCreateGrowthProfileOverrideInput>(
          (override) => getGrowthProfileOverrideInput(override, householdID)
        ),
    },
  };
}

export function mapIncomingDataToForm(
  growthProfile: GrowthProfileModal_GrowthProfileFragment
): GrowthProfileModalFormShape {
  return {
    displayName:
      growthProfile?.displayName ??
      defaultGrowthProfileModalFormShape.displayName,
    exemptionGrowthRate:
      growthProfile?.exemptionGrowthRate ??
      defaultGrowthProfileModalFormShape.exemptionGrowthRate,
    willExemptionSunset:
      growthProfile?.willExemptionSunset ??
      defaultGrowthProfileModalFormShape.willExemptionSunset,
    growthType:
      growthProfile?.growthType ??
      defaultGrowthProfileModalFormShape.growthType,
    assetGrowthReturn:
      growthProfile?.assetGrowthReturn ??
      defaultGrowthProfileModalFormShape.assetGrowthReturn,
    growthProfileOverrides: getNodes(growthProfile?.growthProfileOverrides).map(
      (growthProfile, index) => ({
        ...growthProfile,
        id: index,
        customizationType: CustomEntityGrowthCustomizationType.None, // TODO
      })
    ),
  };
}

export function mapGrowthOverrideToTableRow({
  id,
  entity,
  assetClass,
  customGrowthRate,
  entityFutureValuationYear,
  entityFutureValuationValue,
  entityGrowthBeforeFutureValuation,
  entityGrowthAfterFutureValuation,
}: GrowthProfileModalGrowthOverride): GrowthProfileCustomGrowthOverrideRowData {
  if (assetClass) {
    return {
      id,
      kind: GrowthProfileOverrideKind.Asset,
      displayName: assetClass?.displayName ?? 'Unknown',
      displayType: 'Asset class',
      futureValuationValue: EMPTY_CONTENT_HYPHEN,
      futureValuationYear: undefined,
      growthRateValue: `${formatPercent(
        customGrowthRate ?? new Decimal(0),
        2
      )}%`,
      growthRateSubtitle: undefined,
    };
  }

  const futureValuationValue = entityFutureValuationValue
    ? formatCurrency(entityFutureValuationValue)
    : EMPTY_CONTENT_HYPHEN;

  const futureValuationYear: string | undefined =
    entityFutureValuationValue && entityFutureValuationYear
      ? `at year ${entityFutureValuationYear.toString()}`
      : undefined;

  let growthRateValue = customGrowthRate
    ? `${formatPercent(customGrowthRate, 2)}%`
    : EMPTY_CONTENT_HYPHEN;
  let growthRateSubtitle: string | undefined = undefined;

  if (entityGrowthBeforeFutureValuation && entityGrowthAfterFutureValuation) {
    growthRateValue = `${formatPercent(entityGrowthBeforeFutureValuation, 2)}% / ${formatPercent(entityGrowthAfterFutureValuation, 2)}%`;
    growthRateSubtitle = 'before/after future valuation';
  }

  return {
    id,
    kind: GrowthProfileOverrideKind.Entity,
    displayName: entity?.subtype?.displayName ?? 'Unknown',
    displayType: 'Entity',
    futureValuationValue,
    futureValuationYear,
    growthRateValue,
    growthRateSubtitle,
  };
}
