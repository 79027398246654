import {
  Box,
  Stack,
  StackProps,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { ComponentProps, CSSProperties } from 'react';

import { VerticalBarSection } from './VerticalBarSection';
import { VerticalBarSubsection } from './VerticalBarSubsection';

interface StackedVerticalBarProps {
  sections: {
    value: number;
    color: string;
    /** subsections allows you to break up the section into differently-formatted pieces */
    subsections?: ComponentProps<typeof VerticalBarSubsection>['subsection'][];
    backgroundCss?: CSSProperties['background'];
    sectionTextColor?: string;
    onClick?: () => void;
    hideValue?: boolean;
    typographyProps?: TypographyProps;
  }[];
  hideLegend?: boolean;
  scaleDenominator?: number; // If provided, the bar chart's height will be scaled by the ratio of the total value of the sections to this value
  height?: StackProps['height'];
  width?: StackProps['width'];
}

export function StackedVerticalBar({
  sections,
  scaleDenominator,
  height,
  width,
}: StackedVerticalBarProps) {
  const theme = useTheme();
  // use Math.abs to make sure each bar section can be scaled properly
  const barTotal = sections.reduce(
    (acc, { value }) => acc + Math.abs(value),
    0
  );

  const filteredSections =
    barTotal > 0 ? sections.filter(({ value }) => value > 0) : sections;

  return (
    <Stack height={height} width={width}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          minWidth: theme.spacing(6),
          height: scaleDenominator
            ? `${(barTotal / scaleDenominator) * 100}%`
            : '100%',
        }}
      >
        {filteredSections.map((section, idx) => (
          <VerticalBarSection
            key={`vertical-bar-${idx}`}
            section={section}
            isFirst={idx === 0}
            isLast={idx === filteredSections.length - 1}
            data-testid={`StackedVerticalBar-${idx}`}
            total={barTotal}
          />
        ))}
      </Box>
    </Stack>
  );
}
