import { Box, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Card } from '@/components/layout/Card/Card';
import { LuminaryAIBannerCard } from '@/components/layout/LuminaryAIBannerCard';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { LoadingBanner } from '@/components/notifications/LoadingBanner/LoadingBanner';
import { useReportError } from '@/hooks/useReportError';
import { useEntityPendingAiSuggestionsAsyncJob } from '@/modules/aiSuggestions/hooks/useEntityPendingAiSuggestionsAsyncJob';
import { AsyncJobStatus_AsyncJobFragment } from '@/modules/asyncJobs/graphql/AsyncJobs.generated';
import {
  DEFAULT_POLL_INTERVAL,
  usePollForJobCompletion,
} from '@/modules/asyncJobs/hooks/usePollForJobCompletion';
import { useHouseholdDocumentsViewerContext } from '@/modules/documents/components/HouseholdDocumentsViewer/context/HouseholdDocumentsViewer.context';
import { LuminaryAiBetaLogo } from '@/modules/documents/components/LuminaryAiLogo';
import { useUpdateDocumentAiEnabledMutation } from '@/modules/documents/graphql/DocumentAiDetails.generated';

import { useEntityDetailsContext } from '../../entities/contexts/entityDetails/entityDetails.context';
import { useEntitySuggestionsContext } from '../context/EntitySuggestions.context';
import { useAISuggestionsEnabled } from '../hooks/useAISuggestionsEnabled';

type BannerState = 'NOT_ENABLED' | 'IN_PROGRESS' | 'SUGGESTIONS_AVAILABLE';

interface AIDocumentStatusBannerProps {
  onReviewSuggestionsClick?: () => void;
  onJobCompleted?: () => void;
}

export function AIDocumentStatusBanner({
  onReviewSuggestionsClick,
  onJobCompleted,
}: AIDocumentStatusBannerProps) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const { activeDocument } = useHouseholdDocumentsViewerContext();
  const { aiSuggestionsAsyncJobs, entityType, entityId } =
    useEntityDetailsContext();
  const { refetchSuggestions } = useEntitySuggestionsContext();
  const { refetch: refetchPendingAiSuggestionJobs } =
    useEntityPendingAiSuggestionsAsyncJob();
  const aiSuggestionsEnabled = useAISuggestionsEnabled(entityType);

  const [initialJob, setInitialJob] =
    useState<AsyncJobStatus_AsyncJobFragment | null>();

  const [bannerState, setBannerState] = useState<BannerState | undefined>(
    undefined
  );

  useEffect(() => {
    void refetchPendingAiSuggestionJobs();
  }, [activeDocument, refetchPendingAiSuggestionJobs]);

  useEffect(() => {
    setInitialJob(aiSuggestionsAsyncJobs?.[0] || null);
  }, [aiSuggestionsAsyncJobs]);

  const { startPolling } = usePollForJobCompletion(initialJob, {
    skip: !initialJob,
    onJobComplete: async () => {
      await refetchPendingAiSuggestionJobs();
      if (entityId) {
        refetchSuggestions({ entityID: entityId });
      }
      onJobCompleted?.();
    },
  });

  useEffect(() => {
    if (initialJob) {
      startPolling?.(DEFAULT_POLL_INTERVAL);
    }
  }, [initialJob, startPolling]);

  const [updateDocumentAiEnabledMutation] = useUpdateDocumentAiEnabledMutation({
    variables: {
      documentId: activeDocument?.id || '',
    },
    onCompleted: async () => {
      showFeedback('Successfully enabled AI suggestions for this document.', {
        variant: 'success',
      });
      await refetchPendingAiSuggestionJobs();
    },
    onError: (error) => {
      reportError(`failed to enable AI suggestions for document`, error);
      showFeedback(
        'We were unable to enable AI suggestions for this document. Please try again.'
      );
    },
  });

  const onEnableAiSuggestionsOnDocument = useCallback(async () => {
    if (!activeDocument?.id) return;
    return await updateDocumentAiEnabledMutation();
  }, [activeDocument?.id, updateDocumentAiEnabledMutation]);

  useEffect(() => {
    if (!aiSuggestionsEnabled) {
      setBannerState(undefined);
      return;
    }

    // If there is an in progress job and suggestions available, show the in progress banner
    if (initialJob) {
      setBannerState('IN_PROGRESS');
      return;
    }

    if (onReviewSuggestionsClick) {
      setBannerState('SUGGESTIONS_AVAILABLE');
      return;
    }

    if (activeDocument?.primaryEntity?.id !== entityId) {
      setBannerState(undefined);
      return;
    }

    if (activeDocument && !activeDocument?.enableAiSuggestions && !initialJob) {
      setBannerState('NOT_ENABLED');
      return;
    }

    setBannerState(undefined);
  }, [
    activeDocument,
    aiSuggestionsEnabled,
    entityId,
    initialJob,
    onReviewSuggestionsClick,
  ]);

  if (!bannerState) return null;

  return (
    <>
      {activeDocument && bannerState === 'NOT_ENABLED' && (
        <Card variant="filled-teal" sx={{ p: 3, flexShrink: 0 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Box>
              <Stack direction="row" alignItems="center" gap={2}>
                <LuminaryAiBetaLogo />
                <Stack direction="column" gap={0.25} flexGrow={1}>
                  <Typography variant="label">
                    Enable data suggestions from this document
                  </Typography>
                  <Typography variant="subtitle2">
                    Data already entered will not be removed. You can accept or
                    reject the suggestions.
                  </Typography>
                </Stack>
              </Stack>
            </Box>
            <Button
              onClick={onEnableAiSuggestionsOnDocument}
              variant="primary"
              size="sm"
            >
              Enable
            </Button>
          </Stack>
        </Card>
      )}
      {bannerState === 'IN_PROGRESS' && (
        <LoadingBanner
          content={
            <Stack direction="row" alignItems="center" gap={2}>
              <LuminaryAiBetaLogo />
              <Stack direction="column" gap={0.25} flexGrow={1}>
                <Typography variant="label">
                  Processing an uploaded document
                </Typography>
                <Typography variant="subtitle2">
                  Suggestions will appear for you to accept or reject and no
                  data already inputted will be removed
                </Typography>
              </Stack>
            </Stack>
          }
        />
      )}
      {bannerState === 'SUGGESTIONS_AVAILABLE' && (
        <LuminaryAIBannerCard
          bannerText={
            <Stack direction="column" gap={0.25} flexGrow={1}>
              <Typography variant="label">
                Suggestions for entity details identified
              </Typography>
              <Typography variant="subtitle2">
                Suggestions are provided from the associated trust document
              </Typography>
            </Stack>
          }
          primaryButtonProps={{
            onClick: onReviewSuggestionsClick,
            size: 'sm',
            variant: 'secondary',
            children: 'Review suggestions',
          }}
        />
      )}
    </>
  );
}
