import { CreateFilterOptionsConfig, PopperProps } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import {
  getFieldErrorValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import {
  AddNewOptionDefinition,
  HelpTextVariant,
  SelectInputOption,
  TypeaheadSelectInputOption,
} from '../baseInputs/inputTypes';
import { TypeaheadSelectInput } from '../baseInputs/TypeaheadSelectInput/TypeaheadSelectInput';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

export interface FormAwareTypeaheadSelectInputProps<
  FormShape extends FieldValues,
  V = string,
> {
  fieldName: FieldNameFromFormShape<FormShape>;
  label: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  options: TypeaheadSelectInputOption<V>[];
  required?: boolean;
  control: Control<FormShape>;
  disabled?: boolean;
  validation?: RegisterOptions<FormShape>['validate'];
  contextualHelp?: JSX.Element;
  startAdornment?: React.ReactNode;
  testId?: string;
  // this `config` is used to generate filter options: https://mui.com/material-ui/react-autocomplete/#custom-filter
  // it's possible that we'll want to make this more flexible in the future and allow passing the factory function
  // at this level, but i think exposing the simpler API is reasonable for now
  config?: CreateFilterOptionsConfig<SelectInputOption<V>>;
  placeholder?: string;
  hideLabel?: boolean;
  dropdownProps?: Partial<PopperProps>;
  groupBy?: (option: TypeaheadSelectInputOption<V>) => string;
  addNewOption?: AddNewOptionDefinition;
  emptyOptionDisplay?: string;
}

export function FormAwareTypeaheadSelectInput<
  FormShape extends FieldValues,
  V = string,
>({
  fieldName,
  label,
  helpText,
  helpTextVariant,
  options,
  required,
  control,
  disabled,
  validation,
  contextualHelp,
  startAdornment,
  testId,
  config,
  placeholder,
  hideLabel,
  dropdownProps,
  groupBy,
  emptyOptionDisplay,
  addNewOption,
}: FormAwareTypeaheadSelectInputProps<FormShape, V>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const validations = getValidations(label, !!required, validation);
  const filterOptions = createFilterOptions(config);
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validations }}
      render={({ field, fieldState, formState }) => {
        return (
          <TypeaheadSelectInput<V>
            testId={testId}
            label={label}
            filterOptions={filterOptions}
            options={options}
            addNewOption={addNewOption}
            onChange={(_e, value) => {
              // the underlying event doesn't have the appropriate value,
              // so we explicitly pass it through here.
              field.onChange({
                target: {
                  value,
                },
              });
            }}
            hideLabel={hideLabel}
            groupBy={groupBy}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            inputRef={field.ref}
            required={required}
            helpText={helpText}
            dropdownProps={dropdownProps}
            helpTextVariant={helpTextVariant}
            disabled={disabledFromContext ?? disabled}
            contextualHelp={contextualHelp}
            errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
            startAdornment={startAdornment}
            placeholder={placeholder}
            emptyOptionDisplay={emptyOptionDisplay}
          />
        );
      }}
    />
  );
}
