import { useState } from 'react';

import { TypeaheadSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useFormContext } from '@/components/react-hook-form/hooks';
import { CreateAssetCategoryModal } from '@/modules/assetCategories/CreateAssetCategoryModal/CreateAssetCategoryModal';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';

import {
  BasicAssetsSubformType,
  Fields,
  NamespaceType,
  SubformField,
} from '../BasicAssetsSubform.types';

interface AssetCategoryFieldProps {
  namespace: NamespaceType;
  secondNamespace: keyof BasicAssetsSubformType;
  hideLabel?: boolean;
  options: TypeaheadSelectInputOption<string>[];
  disabled?: boolean;
}

export const AssetCategoryField = ({
  namespace,
  secondNamespace,
  disabled,
  hideLabel,
  options,
}: AssetCategoryFieldProps) => {
  const userCanCreateAssetCategories = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_MODIFY_ASSET_CATEGORIES
  );
  const { control } = useFormContext<Fields>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <FormAwareSelectInput<Fields>
        fieldName={
          `${namespace}.${secondNamespace}.assetCategoryId` as const satisfies SubformField
        }
        label="Asset category"
        required
        showEmptyValue={false}
        hideLabel={hideLabel}
        control={control}
        options={options}
        disabled={disabled}
        addNewOption={
          userCanCreateAssetCategories
            ? {
                onClick: () => setIsModalOpen(true),
                icon: <PlusIcon size={16} />,
              }
            : undefined
        }
      />
      <CreateAssetCategoryModal
        isOpen={isModalOpen}
        existingOptionsCount={options.length}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};
