import { useApolloClient } from '@apollo/client';
import { Box, Skeleton, Stack, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { compact } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import { flushSync } from 'react-dom';
import {
  FormProvider,
  Path,
  SubmitErrorHandler,
  SubmitHandler,
} from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { iconSizeByButtonSize } from '@/components/form/baseInputs/Button/styles';
import { FormConfigurationProvider } from '@/components/form/context/FormConfigurationContext';
import { useDebouncedFormValidationTrigger } from '@/components/form/formAwareInputs/hooks/useDebouncedFormValidationTrigger';
import { ArrowLeftIcon } from '@/components/icons/ArrowLeftIcon';
import { ArrowRightIcon } from '@/components/icons/ArrowRightIcon';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { ButtonTab, Tabs } from '@/components/navigation/NavigationTabs';
import { getSearchParamsObject } from '@/components/navigation/navigationUtils';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { getCalculatedFundingValueFromAssets } from '@/modules/assets/utils';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { TermsSubform } from '@/modules/entities/gratTrusts/TermsSubform/TermsSubform';
import { RollingType } from '@/modules/entities/gratTrusts/TermsSubform/types';
import { getTermLengthValue } from '@/modules/entities/gratTrusts/TermsSubform/ui/utils';
import { GratDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { GetDesignSummaryDetailsDocument } from '@/pages/implementation/taskBodies/components/TrustDetailsSummary/graphql/TrustDetailsSummary.generated';
import { EntityStage } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import { RenderDesignerFooter } from '../../types';
import { DesignerLayout } from '../DesignerLayout';
import { AnnuityForm } from './AnnuityForm/AnnuityForm';
import { AssetForm } from './AssetsForm/AssetsForm';
import {
  GRATStructuringForm,
  initialValues,
  TAB_NAMES,
  tabConfigurations,
  TabName,
} from './constants';
import { getFirstError } from './formUtils';
import {
  GetStructuringGratDocument,
  useUpdateEntityForStructuringMutation,
} from './graphql/StructuringDesigner.generated';
import { ScenarioOverview } from './ScenarioOverview/ScenarioOverview';
import { StructuringDesignerFooter } from './StructuringDesignerFooter';
import {
  getEstimatedRateOfReturn,
  normalizeValuesForUpdate,
} from './structuringDesignerUtils';
import { useSyncStructuringDesignerData } from './useSyncStructuringDesignerData';

const { FormComponent: TermsSubformComponent, namespace: termsNamespace } =
  new TermsSubform();

export interface StructuringDesignerProps {
  entityId: string;
  householdId: string;
  isFullScreenModal?: boolean;
  initialTab: TabName;
  Footer?: RenderDesignerFooter;
  onAfterUpdate?: () => void;
}

const TAB_SEARCH_PARAM = 'structuringTab' as const;

function FormContainer({
  visible,
  children,
}: React.PropsWithChildren<{ visible: boolean }>) {
  // specifically using `visible` rather than `display` here because we actually want all of the forms
  // to be rendered at the same time so that we can trigger validation checks and focus on those inputs
  // even when another tab is active.
  return (
    <FormConfigurationProvider
      value={{ optionalDisplayType: 'optional-label' }}
    >
      <Box display={visible ? 'auto' : 'none'}>{children}</Box>
    </FormConfigurationProvider>
  );
}

function getIntegerValueOrNull(value: string): number | null {
  const integerValue = parseInt(value);
  if (isNaN(integerValue)) return null;
  return integerValue;
}

export function StructuringDesigner({
  entityId,
  householdId,
  isFullScreenModal = false,
  initialTab,
  Footer,
  onAfterUpdate,
}: StructuringDesignerProps) {
  const apolloClient = useApolloClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const locationState = location.state;
  const { showFeedback, createErrorFeedback, createSuccessFeedback } =
    useFeedback();

  function setSelectedTab(name: TabName) {
    // without these calls to `setShouldBlock`, the user is prompted to save any unsaved
    // changes when they toggle between tabs
    flushSync(() => {
      setShouldBlockNavigation(false);
    });
    setSearchParams(
      {
        // make sure that we're retaining other search param values that this page doesn't care about. specifically, we
        // render this page in a full-screen modal in a couple of situations and don't want to break any other functionality.
        ...getSearchParamsObject(searchParams),
        [TAB_SEARCH_PARAM]: name,
      },
      { replace: true, state: locationState }
    );
    flushSync(() => {
      setShouldBlockNavigation(true);
    });
  }

  function getValidatedCurrentTab(): TabName | null {
    const currentSearchParamTabValue = searchParams.get(TAB_SEARCH_PARAM);
    const validSearchTab = tabConfigurations.find(
      ({ value }) => value === currentSearchParamTabValue
    );
    return validSearchTab?.value ?? null;
  }

  useEffect(function setInitialSearchParams() {
    // if there's not currently a valid tab value, set a default one.
    const validSearchTabValue = getValidatedCurrentTab();
    if (!validSearchTabValue || validSearchTabValue !== initialTab) {
      // i'm wrapping the call to the initial setSelectedTab in a setTimeout because in the fullscreen
      // modal scenario, we want to defer the flushSync-wrapped state changes in here until parent
      // components' state changes are complete
      setTimeout(() => {
        setSelectedTab(initialTab);
      });
    }
    // only want this to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  const theme = useTheme();
  const [updateEntity, { loading: submittingUpdate }] =
    useUpdateEntityForStructuringMutation();

  const formMethods = useForm<GRATStructuringForm>({
    defaultValues: initialValues,
    shouldFocusError: false, // we want to do this manually, because it could require switching tabs
  });

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    setFocus,
    setError,
    trigger,
    formState: { errors },
    shouldBlockNavigation,
    setShouldBlockNavigation,
  } = formMethods;

  const setBlockingBehavior = useCallback(
    (shouldBlockNavigation: boolean) => {
      flushSync(() => {
        setShouldBlockNavigation(shouldBlockNavigation);
      });
    },
    [setShouldBlockNavigation]
  );

  const { gratData, entityData, syncEntityDataToForm } =
    useSyncStructuringDesignerData(entityId, reset);

  const queriesToRefetch = compact([
    GetStructuringGratDocument,
    // this is a little hacky/too aware of its surroundings, but we only want to attempt
    // to refetch this query if this component is being rendered inside the summary details
    // update screens
    isFullScreenModal && GetDesignSummaryDetailsDocument,
  ]);

  // this is required to keep the form error state constantly up-to-date, because we use them
  // to calculate the subform validity to populate the indicators in the tabs.
  useDebouncedFormValidationTrigger(trigger, watch);

  const onValidStructuringSubmit: SubmitHandler<GRATStructuringForm> = async (
    values
  ) => {
    if (!gratData || !entityData) return;
    const stageToSet =
      entityData.stage === EntityStage.Draft
        ? EntityStage.ReadyForProposal
        : undefined;

    const res = await updateEntity({
      variables: {
        input: normalizeValuesForUpdate(
          values,
          stageToSet,
          entityId,
          gratData.id
        ),
      },
      onError: createErrorFeedback(
        'Sorry, we failed to save your data. Please try again.'
      ),
      onCompleted: createSuccessFeedback(
        'Your information was saved successfully'
      ),
    });

    await apolloClient.refetchQueries({
      include: queriesToRefetch,
    });

    if (res.data) {
      syncEntityDataToForm(res.data.updateEntity);

      // showFeedback('Your information was saved successfully', {
      //   variant: 'success',
      // });

      if (isFullScreenModal) {
        onAfterUpdate && onAfterUpdate();
      }
    }
  };

  const onInvalidStructuringSubmit: SubmitErrorHandler<GRATStructuringForm> = (
    errors
  ) => {
    const currentTab = getValidatedCurrentTab();
    if (!currentTab) {
      diagnostics.error(
        'attempting to get tab for submit, but no valid tab found',
        new Error('invalid tab set at submit'),
        {
          definedTab: searchParams.get('tab'),
        }
      );
      return;
    }

    const errorDetails = getFirstError(errors, currentTab);
    if (!errorDetails) return;
    const fullFieldName =
      `${errorDetails.tabName}.${errorDetails.fieldName}` as Path<GRATStructuringForm>;

    // this is odd; errors for fieldArrays that aren't specific to a single field in the array come back mapped
    // very strangely. an example is an error where the length of the fieldArray needs to be >1 and isn't.
    // they come back as "root errors" and we have to do some specific handling to map them, because
    // react hook form doesn't do it yet: https://react-hook-form.com/api/usefieldarray
    if (errorDetails.isRootError) {
      setError(fullFieldName, {
        message: errorDetails.errorMessage,
        // clear the error onChange
        type: 'onChange',
      });
    }

    setSelectedTab(errorDetails.tabName);
    // wrapped in a setTimeout to give the interface time to switch to the next tab
    // prior to attempting to focus on the element.
    setTimeout(() => setFocus(fullFieldName));
  };

  function getNextTab(direction: number): TabName | null {
    const currentTabName = searchParams.get(TAB_SEARCH_PARAM);
    const selectedTabIndex = tabConfigurations.findIndex(
      ({ value }) => value === currentTabName
    );
    const nextTabIndex = selectedTabIndex + direction;

    // this is a valid next index, return the index
    const nextTab = tabConfigurations[nextTabIndex];
    if (
      nextTabIndex >= 0 &&
      nextTabIndex < tabConfigurations.length &&
      nextTab
    ) {
      return nextTab.value;
    }

    return null;
  }

  function handleTabChange(direction: number) {
    const nextTab = getNextTab(direction);
    if (nextTab === null) {
      diagnostics.debug(
        'Attempting to transition to an invalid tab. Safely returning.'
      );
      return;
    }

    setSelectedTab(nextTab);
  }

  const onStructuringSubmit = handleSubmit(
    onValidStructuringSubmit,
    onInvalidStructuringSubmit
  );

  const assetsFormValues = watch('assets');
  const termsFormValues = watch(termsNamespace);
  const annuityFormValues = watch('annuity');

  const handleCalculatedTaxableGiftAmountChange = React.useCallback(
    (calculatedTaxableGiftAmount: Decimal | null) => {
      setValue(
        'annuity.calculatedTaxableGiftAmount',
        calculatedTaxableGiftAmount
      );
    },
    [setValue]
  );

  const calculatedInitialFundingValue = React.useMemo(
    () => getCalculatedFundingValueFromAssets(assetsFormValues.assets),
    [assetsFormValues.assets]
  );

  const saveAndHandleNavigationBlocking = async () => {
    // if there are no pending changes, you should always be able to leave
    // right away
    if (!shouldBlockNavigation) {
      flushSync(() => {
        setShouldBlockNavigation(false);
      });
      return true;
    }

    try {
      await onStructuringSubmit();
    } catch (err) {
      diagnostics.error(
        'Failed to save and navigate in StructuringDesigner',
        err as Error,
        {
          entityId,
        }
      );
      showFeedback(`We weren't able to save your updates. Please try again.`);
      return false;
    }

    const currentTab = getValidatedCurrentTab();
    if (!currentTab) {
      diagnostics.error(
        'attempting to compute errors for an invalid tab',
        new Error('cannot compute errors on invalid tab'),
        {
          definedTab: searchParams.get(TAB_SEARCH_PARAM),
        }
      );
      return false;
    }
    const errorDetails = getFirstError(errors, currentTab);
    if (errorDetails) return false;
    flushSync(() => {
      setShouldBlockNavigation(false);
    });
    return true;
  };

  const handleSaveAndNavigate = async (path: string) => {
    const shouldNavigate = await saveAndHandleNavigationBlocking();
    if (shouldNavigate) {
      navigate(path);
    }
  };

  const handleSaveAndExit = (e: React.MouseEvent) => {
    e.stopPropagation();
    const clientOverviewLink = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
      {
        householdId,
      }
    );
    void handleSaveAndNavigate(clientOverviewLink);
  };

  const handleSaveAndBasicInfo = (e: React.MouseEvent) => {
    e.stopPropagation();
    const basicInfoLink = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER,
      {
        householdId,
        entityId,
        entityType: ENTITY_TYPES.GRAT,
        designerStage: GratDesignerStages.BASIC_INFORMATION,
      }
    );
    navigate(basicInfoLink);
  };

  // we don't clear the rolling period form value when we hide the field because we want to allow people to switch back
  // and forth easily. instead, we perform this logic here and should only use computedRollingPeriodYearsInteger, not
  // termsFormValues.rollingPeriod everywhere.
  const computedRollingPeriodYearsInteger = (() => {
    if (termsFormValues.type === RollingType.STANDARD) return null;
    return getIntegerValueOrNull(termsFormValues.rollingPeriod);
  })();

  return (
    <FormProvider {...formMethods}>
      <DesignerLayout
        setNavigationBlocking={setBlockingBehavior}
        entityId={entityId}
        householdId={householdId}
        heading={
          <Stack direction="row" alignItems="center">
            {gratData?.displayName ?? <Skeleton />}
            <EditButton
              sx={{ ml: 1 }}
              aria-label="Edit name"
              onClick={handleSaveAndBasicInfo}
            />
          </Stack>
        }
        hideHeader={isFullScreenModal}
        onFormSubmit={onStructuringSubmit}
        LeftPaneContent={
          <HeaderCard
            sx={{ height: '100%', position: 'relative' }}
            heading="GRAT structure"
          >
            <Tabs fullWidth>
              {tabConfigurations.map(({ display, value }) => (
                <ButtonTab
                  key={value}
                  display={display}
                  isActive={getValidatedCurrentTab() === value}
                  status={
                    Object.keys(errors[value] || {}).length === 0
                      ? 'complete'
                      : 'pending'
                  }
                  onClick={() => setSelectedTab(value)}
                />
              ))}
            </Tabs>
            {/*
            the top margin is for space away from the tabs. the bottom margin is for space
            away from the buttons to toggle between sections
           */}
            <Box mt={3} mb={7}>
              <FormContainer
                visible={
                  searchParams.get(TAB_SEARCH_PARAM) === TAB_NAMES.ASSETS
                }
              >
                <AssetForm values={assetsFormValues} />
              </FormContainer>
              <FormContainer
                visible={searchParams.get(TAB_SEARCH_PARAM) === TAB_NAMES.TERMS}
              >
                <TermsSubformComponent
                  variant="oneColumn"
                  subformValues={termsFormValues}
                />
              </FormContainer>
              <FormContainer
                visible={
                  searchParams.get(TAB_SEARCH_PARAM) === TAB_NAMES.ANNUITY
                }
              >
                <AnnuityForm
                  initialFundingValue={calculatedInitialFundingValue}
                  values={annuityFormValues}
                />
              </FormContainer>
            </Box>
            <Stack
              component="footer"
              direction="row"
              justifyContent="flex-end"
              spacing={1}
              sx={{
                position: 'absolute',
                bottom: theme.spacing(2),
                right: theme.spacing(2),
                width: '100%',
              }}
            >
              <Button
                onClick={() => handleTabChange(-1)}
                disabled={getNextTab(-1) === null}
                variant="primary"
                square
                size="xs"
              >
                <ArrowLeftIcon size={iconSizeByButtonSize.sm} />
              </Button>
              <Button
                onClick={() => handleTabChange(1)}
                disabled={getNextTab(1) === null}
                variant="primary"
                square
                size="xs"
              >
                <ArrowRightIcon size={iconSizeByButtonSize.sm} />
              </Button>
            </Stack>
          </HeaderCard>
        }
        RightPaneContent={
          <ScenarioOverview
            onCalculatedTaxableGiftAmountChange={
              handleCalculatedTaxableGiftAmountChange
            }
            grantorRetainedInterest={annuityFormValues.grantorRetainedInterest}
            estimatedReturn={getEstimatedRateOfReturn({
              returnProjectionType: assetsFormValues.returnProjectionType,
              projectedRateOfReturn: assetsFormValues.projectedRateOfReturn,
              projectedMarketValue: assetsFormValues.projectedMarketValue,
              projectedSharePrice: assetsFormValues.projectedSharePrice,
              initialFundingValue:
                calculatedInitialFundingValue || new Decimal(0),
              rollingPeriodYears: computedRollingPeriodYearsInteger,
              termDurationYears:
                getIntegerValueOrNull(
                  getTermLengthValue(
                    termsFormValues.termLength,
                    termsFormValues.termLengthExtended
                  )
                ) || 0,
            })}
            taxableGiftAmount={annuityFormValues.taxableGiftAmount}
            termDuration={getIntegerValueOrNull(
              getTermLengthValue(
                termsFormValues.termLength,
                termsFormValues.termLengthExtended
              )
            )}
            initialFundingValue={calculatedInitialFundingValue}
            officialInterestRate={termsFormValues.rate7520}
            annualAnnuityIncrease={
              annuityFormValues.annuityAnnualIncreasePercent
            }
            rollingPeriodYears={computedRollingPeriodYearsInteger}
          />
        }
        FooterActionContent={
          (Footer && <Footer disabled={submittingUpdate} />) || (
            <StructuringDesignerFooter
              entityId={entityId}
              householdId={householdId}
              queriesToRefetch={queriesToRefetch}
              disabled={submittingUpdate}
              shouldBlockNavigation={shouldBlockNavigation}
              onSave={saveAndHandleNavigationBlocking}
              onSaveAndExit={handleSaveAndExit}
            />
          )
        }
      />
    </FormProvider>
  );
}
