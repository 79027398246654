import { Stack } from '@mui/material';
import { values } from 'lodash';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Share06Icon } from '@/components/icons/Share06Icon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { SidePanel } from '@/components/modals/SidePanel';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { HypotheticalTransferModal } from '@/modules/entities/details/HypotheticalTransferModal/HypotheticalTransferModal';
import { useEstateWaterfallSummaryBarChartsSectionColors } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useEstateWaterfallSummaryBarChartsSectionColors.hook';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { COLORS } from '@/styles/tokens/colors';

import { SummaryPanelLoader } from '../components/SummaryPanelLoader';
import { CreateHypotheticalEventButton } from './CreateHypotheticalEventButton';
import { useHypotheticalTransfersSummaryQueryQuery } from './graphql/HypotheticalTransfersSummary.generated';
import { HypotheticalTransferSummarySource } from './HypotheticalTransfersSummaryPanel.components';
import { HypotheticalTransfersSummaryMap } from './HypotheticalTransfersSummaryPanel.types';
import {
  mapDataToEntityTransfersMap,
  mapDataToIncludedIds,
} from './HypotheticalTransfersSummaryPanel.utils';

interface HypotheticalTransferSummaryPanelInnerProps {
  waterfallId: string;
  householdId: string;
  /** sourceId is the id of the transfer source, which can be used by the consuming code to change the summary panel */
  onManageSource: (sourceId: string) => void;
  defaultOpenTransferId?: string;
}
export interface HypotheticalTransferSummaryPanelProps
  extends HypotheticalTransferSummaryPanelInnerProps {
  onClose: () => void;
}

function HypotheticalTransferSummaryPanelInner({
  waterfallId,
  householdId,
  onManageSource,
  defaultOpenTransferId,
}: HypotheticalTransferSummaryPanelInnerProps) {
  const isHypotheticalSaleLoanEnabled = isFeatureFlagEnabled(
    'hypothetical_sale_loan'
  );
  const { reportError } = useReportError();
  const [{ isModalOpen, data: currentTransferId }, { openModal, closeModal }] =
    useModalState<string>();

  useEffect(() => {
    if (defaultOpenTransferId) {
      openModal(defaultOpenTransferId);
    }
  }, [defaultOpenTransferId, openModal]);

  const colorResult = useEstateWaterfallSummaryBarChartsSectionColors();

  const [sources, setSources] =
    useState<HypotheticalTransfersSummaryMap | null>(null);
  const [colors] = useState(colorResult);

  const { data, error, loading } = useHypotheticalTransfersSummaryQueryQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      estateWaterfallsWhere: {
        id: waterfallId,
      },
    },
    onError: (error) => {
      reportError(
        `Failed to load transfers for waterfall ${waterfallId}`,
        error
      );
    },
  });

  useEffect(() => {
    setSources(mapDataToEntityTransfersMap(data, colors));
  }, [colors, data]);

  const visibleNodeIds = useMemo(() => mapDataToIncludedIds(data), [data]);

  let body: ReactNode = null;
  if (error) {
    body = `Could not load transfers. Please try again later.`;
  } else if (loading) {
    body = <SummaryPanelLoader />;
  } else if (!sources) {
    body = (
      <ActionCard
        variant="noCard"
        icon={
          <Share06Icon
            sx={{ color: COLORS.GRAY[200], height: 36, width: 36 }}
          />
        }
        heading={`Specify hypothetical transfers`}
        description={`No hypothetical transfers have been created in this waterfall`}
        actions={
          isHypotheticalSaleLoanEnabled ? (
            <CreateHypotheticalEventButton
              waterfallId={waterfallId}
              householdId={householdId}
              variant="primary"
              size="lg"
            />
          ) : (
            <Button variant="primary" size="lg" onClick={() => openModal()}>
              Add a hypothetical transfer
            </Button>
          )
        }
      />
    );
  } else {
    body = (
      <Stack spacing={3}>
        {values(sources).map((source) => (
          <HypotheticalTransferSummarySource
            key={source.id}
            source={source}
            onManageSource={onManageSource}
            onManageTransfer={openModal}
            visibleNodeIds={visibleNodeIds}
          />
        ))}
        {isHypotheticalSaleLoanEnabled ? (
          <CreateHypotheticalEventButton
            waterfallId={waterfallId}
            householdId={householdId}
            variant="secondary"
            size="sm"
            startIcon={PlusIcon}
            fullWidth
          />
        ) : (
          <Button
            variant="secondary"
            onClick={() => openModal()}
            startIcon={PlusIcon}
            size="sm"
            fullWidth
          >
            Add hypothetical transfer
          </Button>
        )}
      </Stack>
    );
  }

  return (
    <>
      <SidePanel.Section>{body}</SidePanel.Section>
      {isModalOpen && (
        <HypotheticalTransferModal
          waterfallId={waterfallId}
          householdId={householdId}
          isOpen={isModalOpen}
          onClose={closeModal}
          transferId={currentTransferId ?? undefined}
        />
      )}
    </>
  );
}

export function HypotheticalTransfersSummaryPanel({
  onClose,
  ...props
}: HypotheticalTransferSummaryPanelProps) {
  return (
    <SidePanel.Panel>
      <SidePanel.Header title="Transfers" onClose={onClose} />
      <SidePanel.Content>
        <HypotheticalTransferSummaryPanelInner {...props} />
      </SidePanel.Content>
    </SidePanel.Panel>
  );
}
