import { Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { compact } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { RibbonCard } from '@/components/layout/RibbonCard';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import { ClientProfile } from '@/types/schema';

import { isHypotheticalWaterfall } from '../../EstateWaterfall.utils';
import { EntityMultiSelectTable } from './EntityMultiSelectTable/EntityMultiSelectTable';
import {
  EstateWaterfallModal_EntityFragment,
  EstateWaterfallModal_NodeConfigurationFragment,
} from './graphql/EstateWaterfallModal.generated';
import { useUpdateFilterModel } from './hooks/useUpdateFilterModel';
import { useWaterfallModalQuery } from './hooks/useWaterfallModalQuery';
import { EstateWaterfallFormData } from './types';

export interface EstateWaterfallFormProps {
  grantors: Pick<ClientProfile, 'id' | 'firstName' | 'lastName'>[];
  sourceEstateWaterfalls?: {
    id: string;
    displayName: string;
    parent?:
      | {
          id: string;
        }
      | undefined
      | null;
    firstGrantorDeath: {
      id: string;
    };
    nodeConfigurations: EstateWaterfallModal_NodeConfigurationFragment[];
  }[];
  shouldShowHypotheticalToggle: boolean;
  loading: boolean;
  entities: EstateWaterfallModal_EntityFragment[];
  hypotheticalTransfers: ReturnType<
    typeof useWaterfallModalQuery
  >['hypotheticalTransfers'];
  isDuplicate?: boolean;
  isEdit?: boolean;
  defaultSelectedRowIds?: string[];
  shouldShowAutoGroupToggle: boolean;
  hypotheticalParentId?: string;
}

export function EstateWaterfallForm({
  grantors,
  sourceEstateWaterfalls,
  shouldShowHypotheticalToggle,
  loading,
  entities,
  hypotheticalTransfers,
  isDuplicate,
  isEdit,
  defaultSelectedRowIds,
  shouldShowAutoGroupToggle,
  hypotheticalParentId,
}: EstateWaterfallFormProps) {
  const forceHypothetical = Boolean(hypotheticalParentId);
  const theme = useTheme();
  const {
    control,
    setValue,
    formState: { isDirty },
    getValues,
  } = useFormContext<EstateWaterfallFormData>();

  const updateFilterModel = useUpdateFilterModel();

  const sourceWaterfallToGrantorDeathIdMap = useMemo(() => {
    const map = new Map<string, string>();
    sourceEstateWaterfalls?.forEach((waterfall) => {
      map.set(waterfall.id, waterfall.firstGrantorDeath.id);
    });
    return map;
  }, [sourceEstateWaterfalls]);

  const isHypotheticalToggled = useWatch({
    control,
    name: '_isHypothetical',
  });

  const selectedSourceWaterfall = useWatch({
    control,
    name: 'parentID',
  });

  useEffect(
    function setHypotheticalOnMount() {
      if (forceHypothetical) {
        setValue('_isHypothetical', true);
        setValue('parentID', hypotheticalParentId);
      }
    },
    [forceHypothetical, hypotheticalParentId, setValue]
  );

  useEffect(
    function resetParentIdOnToggleOff() {
      if (isDuplicate || forceHypothetical) {
        // If we're duplicating or we're already in hypothetical mode,
        // we don't ever want to reset the parentID
        return;
      }

      if (!isHypotheticalToggled && isDirty) {
        setValue('parentID', null);
      }
    },
    [forceHypothetical, isDirty, isDuplicate, isHypotheticalToggled, setValue]
  );

  useEffect(
    function untoggleAutoGroupWhenHypothetical() {
      if (!isHypotheticalToggled) return;

      getValues('shouldAutoGroup') && setValue('shouldAutoGroup', false);
    },
    [getValues, isHypotheticalToggled, setValue]
  );

  useEffect(
    function setSelectedGrantorDeathIdOnSelect() {
      if (!selectedSourceWaterfall) {
        return;
      }

      const firstGrantorDeathIdForSelectedSourceWaterfall =
        sourceWaterfallToGrantorDeathIdMap.get(selectedSourceWaterfall);

      setValue(
        'firstGrantorDeathID',
        firstGrantorDeathIdForSelectedSourceWaterfall ?? ''
      );
    },
    [
      isDirty,
      selectedSourceWaterfall,
      setValue,
      sourceWaterfallToGrantorDeathIdMap,
    ]
  );

  const sourceWaterfallOptions: SelectInputOption<string>[] = useMemo(() => {
    return (sourceEstateWaterfalls ?? []).map((waterfall) => ({
      display: waterfall.displayName,
      value: waterfall.id,
      endAdornment: isHypotheticalWaterfall(waterfall) ? (
        <Badge variant={BadgeVariants.Yellow} display="Hypothetical" />
      ) : undefined,
    }));
  }, [sourceEstateWaterfalls]);

  const grantorOptions: SelectInputOption<string>[] = useMemo(() => {
    const grantorA = grantors[0];
    const grantorB = grantors[1];
    // We only support two grantors on the platform, and don't think we ever will support more
    if (!grantorA || !grantorB) {
      const grantor = compact([grantorA, grantorB])[0];

      // this should never happen, but let's be defensive
      if (!grantor) {
        return [];
      }

      return [
        {
          display: `${grantor.firstName} ${grantor.lastName} dies first`,
          value: grantor.id,
        },
      ];
    }
    const grantorAFullName = [grantorA.firstName, grantorA.lastName].join(' ');
    const grantorBFullName = [grantorB.firstName, grantorB.lastName].join(' ');

    return [
      {
        display: `${grantorAFullName} dies first`,
        value: grantorA.id,
      },
      {
        display: `${grantorBFullName} dies first`,
        value: grantorB.id,
      },
    ];
  }, [grantors]);

  const inferredDeathOrderFromSourceWaterfall = useMemo(() => {
    if (!selectedSourceWaterfall) {
      return null;
    }

    const sourceWaterfall = sourceEstateWaterfalls?.find(
      (waterfall) => waterfall.id === selectedSourceWaterfall
    );

    const grantorA = grantors[0];
    const grantorB = grantors[1];
    if (!sourceWaterfall || !grantorA || !grantorB) {
      return null;
    }

    if (sourceWaterfall.firstGrantorDeath.id === grantorA.id) {
      return grantorOptions[0]?.display ?? null;
    }

    if (sourceWaterfall.firstGrantorDeath.id === grantorB.id) {
      return grantorOptions[1]?.display ?? null;
    }

    return null;
  }, [
    grantorOptions,
    grantors,
    selectedSourceWaterfall,
    sourceEstateWaterfalls,
  ]);

  return (
    <Stack>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput<EstateWaterfallFormData>
            control={control}
            required
            label="Name"
            fieldName={
              'displayName' as const satisfies keyof EstateWaterfallFormData
            }
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {loading && (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={theme.spacing(10)}
        />
      )}
      {shouldShowAutoGroupToggle && (
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareSwitch<EstateWaterfallFormData>
              control={control}
              label="Automatically group tiles"
              fieldName={
                'shouldAutoGroup' as const satisfies keyof EstateWaterfallFormData
              }
              labelPosition="right"
              helpText="Tiles will automatically be grouped into the following categories: In estate (grantor 1-owned), In estate (grantor 2-owned), In estate (jointly-owned), Out of estate (family), and Out of estate (charity)."
              data-testid="estate-waterfall-form-auto-group-switch"
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      {shouldShowHypotheticalToggle && (
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareSwitch<EstateWaterfallFormData>
              control={control}
              label="Hypothetical"
              disabled={forceHypothetical}
              fieldName={
                '_isHypothetical' as const satisfies keyof EstateWaterfallFormData
              }
              labelPosition="right"
              helpText="Create a hypothetical waterfall in which you can model transfers, etc."
              data-testid="estate-waterfall-form-hypothetical-switch"
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      {isHypotheticalToggled && (
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <FormAwareSelectInput<EstateWaterfallFormData>
              control={control}
              required={isHypotheticalToggled}
              disabled={forceHypothetical || isDuplicate}
              label="Source waterfall"
              fieldName={
                'parentID' as const satisfies keyof EstateWaterfallFormData
              }
              options={sourceWaterfallOptions}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      {isEdit && selectedSourceWaterfall && (
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <FormAwareSelectInput<EstateWaterfallFormData>
              control={control}
              disabled
              required={isHypotheticalToggled}
              label="Source waterfall"
              fieldName={
                'parentID' as const satisfies keyof EstateWaterfallFormData
              }
              options={sourceWaterfallOptions}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      {!(isEdit || isDuplicate) && (
        <FormLayoutRow>
          <FormLayoutItem>
            {selectedSourceWaterfall &&
              inferredDeathOrderFromSourceWaterfall && (
                <Stack pt={1}>
                  <Callout severity="info-high" textVariant="subtitle2">
                    The order of events will be&nbsp;
                    <b>{`“${inferredDeathOrderFromSourceWaterfall},”`}</b>
                    &nbsp;based on the source waterfall.
                  </Callout>
                </Stack>
              )}
            {!isHypotheticalToggled && (
              <FormAwareSelectInput<EstateWaterfallFormData>
                control={control}
                required
                label="Death order"
                fieldName={
                  'firstGrantorDeathID' as const satisfies keyof EstateWaterfallFormData
                }
                options={grantorOptions}
              />
            )}
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      <RibbonCard heading="Entities to display" sx={{ mt: 2 }}>
        <Stack spacing={3}>
          <Typography variant="body1">
            Select the entities that you’d like to display in this view. Any
            entities that are selected, along with all entities that they
            distribute to, will show up in this view. If you’ve set this
            waterfall to be “Hypothetical”, all entities that are sources or
            recipients of hypothetical transfers will be shown.
          </Typography>
          <EntityMultiSelectTable
            entities={entities}
            hypotheticalTransfers={hypotheticalTransfers}
            onSelectionChange={updateFilterModel}
            defaultSelectedRowIds={defaultSelectedRowIds}
            key={JSON.stringify(defaultSelectedRowIds)}
          />
        </Stack>
      </RibbonCard>
    </Stack>
  );
}
