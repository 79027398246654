import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

import { Card } from '@/components/layout/Card/Card';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { GratBeneficiaryTree } from '@/modules/entities/gratTrusts/GratBeneficiaryTree/GratBeneficiaryTree';

import { EntityCardProps } from '../../entityDetailPageUtils';
import { useGratDistributionCardQuery } from './graphql/GRATDistributionCard.generated';

function useDistributionCardData(entityId: string) {
  const { createErrorFeedback } = useFeedback();
  const { data, ...rest } = useGratDistributionCardQuery({
    variables: { entityId },
    fetchPolicy: 'cache-and-network',
    onError: createErrorFeedback('Failed to load trust distribution data.'),
  });

  const cardEntity = useMemo(() => {
    if (
      !data?.entity ||
      data?.entity.__typename !== 'Entity' ||
      data.entity.subtype.__typename !== 'GRATTrust'
    ) {
      return null;
    }

    return data.entity;
  }, [data?.entity]);

  return { cardEntity, ...rest };
}

export function GRATDistributionCard({ entity }: EntityCardProps) {
  const { cardEntity } = useDistributionCardData(entity.id);

  if (!cardEntity) return null;
  if (cardEntity?.subtype.__typename !== 'GRATTrust') {
    throw new Error('Invalid trust type');
  }

  return (
    <Card variant="inner-shadow-light" sx={{ p: 3 }}>
      <Stack spacing={3}>
        <Typography variant="h1" component="h2">
          Distribution to beneficiaries
        </Typography>
        <GratBeneficiaryTree
          gratTrust={cardEntity.subtype}
          stage={entity.stage}
        />
      </Stack>
    </Card>
  );
}
