// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEmployeeQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.EmployeeWhereInput>;
}>;


export type GetEmployeeQuery = { __typename?: 'Query', employees: { __typename?: 'EmployeeConnection', edges?: Array<{ __typename?: 'EmployeeEdge', node?: { __typename?: 'Employee', id: string, isAdmin?: boolean | null, user: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string }, assignedHouseholds?: Array<{ __typename?: 'Household', id: string, displayName: string }> | null, roles?: Array<{ __typename?: 'Role', id: string }> | null } | null } | null> | null } };

export type GetEmployeeRoleOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetEmployeeRoleOptionsQuery = { __typename?: 'Query', roles: { __typename?: 'RoleConnection', edges?: Array<{ __typename?: 'RoleEdge', node?: { __typename?: 'Role', id: string, isDefault: boolean, displayName: string } | null } | null> | null } };


export const GetEmployeeDocument = gql`
    query GetEmployee($where: EmployeeWhereInput) {
  employees(where: $where) {
    edges {
      node {
        id
        isAdmin
        user {
          id
          email
          firstName
          lastName
        }
        assignedHouseholds {
          id
          displayName
        }
        roles {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useGetEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetEmployeeQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
      }
export function useGetEmployeeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
        }
export function useGetEmployeeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, options);
        }
export type GetEmployeeQueryHookResult = ReturnType<typeof useGetEmployeeQuery>;
export type GetEmployeeLazyQueryHookResult = ReturnType<typeof useGetEmployeeLazyQuery>;
export type GetEmployeeSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeSuspenseQuery>;
export type GetEmployeeQueryResult = Apollo.QueryResult<GetEmployeeQuery, GetEmployeeQueryVariables>;
export const GetEmployeeRoleOptionsDocument = gql`
    query GetEmployeeRoleOptions {
  roles {
    edges {
      node {
        id
        isDefault
        displayName
      }
    }
  }
}
    `;

/**
 * __useGetEmployeeRoleOptionsQuery__
 *
 * To run a query within a React component, call `useGetEmployeeRoleOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeRoleOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeRoleOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeRoleOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetEmployeeRoleOptionsQuery, GetEmployeeRoleOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEmployeeRoleOptionsQuery, GetEmployeeRoleOptionsQueryVariables>(GetEmployeeRoleOptionsDocument, options);
      }
export function useGetEmployeeRoleOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEmployeeRoleOptionsQuery, GetEmployeeRoleOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEmployeeRoleOptionsQuery, GetEmployeeRoleOptionsQueryVariables>(GetEmployeeRoleOptionsDocument, options);
        }
export function useGetEmployeeRoleOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEmployeeRoleOptionsQuery, GetEmployeeRoleOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEmployeeRoleOptionsQuery, GetEmployeeRoleOptionsQueryVariables>(GetEmployeeRoleOptionsDocument, options);
        }
export type GetEmployeeRoleOptionsQueryHookResult = ReturnType<typeof useGetEmployeeRoleOptionsQuery>;
export type GetEmployeeRoleOptionsLazyQueryHookResult = ReturnType<typeof useGetEmployeeRoleOptionsLazyQuery>;
export type GetEmployeeRoleOptionsSuspenseQueryHookResult = ReturnType<typeof useGetEmployeeRoleOptionsSuspenseQuery>;
export type GetEmployeeRoleOptionsQueryResult = Apollo.QueryResult<GetEmployeeRoleOptionsQuery, GetEmployeeRoleOptionsQueryVariables>;
export const Operations = {
  Query: {
    GetEmployee: 'GetEmployee' as const,
    GetEmployeeRoleOptions: 'GetEmployeeRoleOptions' as const
  }
}