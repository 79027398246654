import { Stack, Typography } from '@mui/material';
import { flatMap, uniq } from 'lodash';

import { CloseButton } from '@/components/form/baseInputs/Button/CloseButton';
import { Modal } from '@/components/modals/Modal/Modal';
import {
  DisplayTable,
  DisplayTableColumn,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { GetTransferDetailsOutput } from '@/utils/hypotheticalTransfers/transferUtils';

const TRANSFER_MODAL_COLUMNS: DisplayTableColumn[] = [
  { headerName: 'Name', width: '30%' },
  { headerName: 'From', width: '22%' },
  { headerName: 'To', width: '22%' },
  { headerName: 'Type', width: '13%' },
  { headerName: 'Transfer', width: '13%' },
];

interface TransferModalProps {
  isOpen: boolean;
  onClose: () => void;
  transfers: GetTransferDetailsOutput[];
}

export function TransfersModal({
  isOpen,
  onClose,
  transfers,
}: TransferModalProps) {
  // Sort the transfers by:
  // 1. start year, earliest first
  // 2. transfer amount, largest first
  const years = uniq(transfers.map(({ startYear }) => startYear)).sort();
  const sortedTransfers = flatMap<number, GetTransferDetailsOutput>(
    years,
    (year) =>
      transfers
        .filter(({ startYear }) => startYear === year)
        .sort(({ amount: a }, { amount: b }) => b.minus(a).toNumber())
  );

  if (!isOpen) return null;

  return (
    <Modal
      maxWidth="md"
      isOpen
      onClose={onClose}
      heading="Transfers"
      rightHeaderContent={
        <CloseButton variant="transparent" onClick={onClose} />
      }
    >
      <DisplayTable columns={TRANSFER_MODAL_COLUMNS}>
        {sortedTransfers.map((transfer, idx) => {
          return (
            <StyledTableRow key={`transfer-${idx}`} inactive>
              <StyledTableCell>
                <Typography variant="h5" component="span">
                  {transfer.name}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Stack direction="column">
                  <Typography variant="body1">{transfer.sourceName}</Typography>
                  <Typography variant="subtitle2">
                    {transfer.sourceKind}
                  </Typography>
                </Stack>
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="body1">
                  {transfer.destinationName}
                </Typography>
                <Typography variant="subtitle2">
                  {transfer.destinationKind}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography>{transfer.taxType}</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Stack alignItems="flex-end">
                  <Typography>
                    {formatCurrencyNoDecimals(transfer.amount)}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {transfer.date}
                  </Typography>
                </Stack>
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </DisplayTable>
    </Modal>
  );
}
