import { createContext, PropsWithChildren } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export interface EstateWaterfallComaprisonTrowserContextShape {
  waterfallIds: string[];
}

export const EstateWaterfallComparisonTrowserContext =
  createContext<EstateWaterfallComaprisonTrowserContextShape>({
    waterfallIds: [],
  });

export function EstateWaterfallComparisonTrowserContextProvider({
  waterfallIds,
  children,
}: PropsWithChildren<EstateWaterfallComaprisonTrowserContextShape>) {
  return (
    <EstateWaterfallComparisonTrowserContext.Provider value={{ waterfallIds }}>
      {children}
    </EstateWaterfallComparisonTrowserContext.Provider>
  );
}

export function useEstateWaterfallComparisonTrowserContext() {
  return useGuardedContext(
    EstateWaterfallComparisonTrowserContext,
    'EstateWaterfallComparisonTrowserContextProvider'
  );
}
