import { SubForm } from '@/types/subform';

import { BENEFICIARIES_SUBFORM } from './EntityBeneficiariesSubform.constants';
import { entityBeneficiariesSubformDefaultValues } from './EntityBeneficiariesSubform.defaults';
import {
  EntityBeneficiariesFormNamespace,
  EntityBeneficiariesFormVariant,
  EntityBeneficiariesSubformFields,
  EntityBeneficiariesSubformProps,
  EntityBeneficiariesSubformShape,
} from './EntityBeneficiariesSubform.types';
import { UI } from './EntityBeneficiariesSubform.UI';

export class EntityBeneficiariesSubform
  implements
    SubForm<
      EntityBeneficiariesSubformShape,
      EntityBeneficiariesSubformFields,
      EntityBeneficiariesFormNamespace,
      EntityBeneficiariesFormVariant,
      object
    >
{
  FormComponent: (props: EntityBeneficiariesSubformProps) => JSX.Element;
  defaultValues: EntityBeneficiariesSubformShape;
  namespace: EntityBeneficiariesFormNamespace;

  constructor() {
    this.FormComponent = UI;
    this.defaultValues = entityBeneficiariesSubformDefaultValues;
    this.namespace = BENEFICIARIES_SUBFORM;
  }
}
