import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';

import { HeaderCellTypography } from '@/components/tables/components/HeaderCellTypography';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';

import { useEstateWaterfallContext } from '../../contexts/estateWaterfall.context';
import {
  entityColumn,
  OnSelectionChange,
  sectionIndicatorColumn,
} from './EstateWaterfallSectionMultiSelectTable.utils';
import { useEstateWaterfallSectionMultiSelectTableRows } from './useEstateWaterfallSectionMultiSelectTableRows';

interface EstateWaterfallSectionMultiSelectTableProps {
  namespace?: AfterDeath;
  onSelectionChange?: OnSelectionChange;
  defaultSelectedRowIds?: string[];
}

export function EstateWaterfallSectionMultiSelectTable(
  props: EstateWaterfallSectionMultiSelectTableProps
) {
  const { namespace = AfterDeath.None, onSelectionChange } = props;
  const { primaryClients } = useHouseholdDetailsContext();
  const {
    state: { waterfall },
  } = useEstateWaterfallContext();
  const [selectedIds, setSelectedIds] = useState<string[]>(
    props.defaultSelectedRowIds || []
  );

  const rowMap = useEstateWaterfallSectionMultiSelectTableRows(
    waterfall,
    primaryClients || [],
    props.defaultSelectedRowIds || []
  );

  useEffect(() => {
    onSelectionChange?.({
      selectedRowIds: selectedIds,
      rows: rowMap[namespace],
    });
  }, [namespace, onSelectionChange, rowMap, selectedIds]);

  const columns = useMemo(() => {
    return [
      sectionIndicatorColumn,
      entityColumn,
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        width: 120,
        align: 'right' as const,
        renderHeader: () => (
          <HeaderCellTypography>Include in group</HeaderCellTypography>
        ),
      },
    ];
  }, []);

  return (
    <DataTable
      rows={rowMap[namespace]}
      sx={{
        '& .MuiDataGrid-cellCheckbox': {
          paddingRight: '12px',
        },
      }}
      columns={columns}
      checkboxSelection
      disableVirtualization
      disableRowSelectionOnClick
      pagination={false}
      slots={{ footer: () => <></> }}
      rowSelectionModel={selectedIds}
      onRowSelectionModelChange={(gridRowIds) => {
        const selectedIds = gridRowIds.map((gridRowId) => gridRowId.toString());
        setSelectedIds(selectedIds);
      }}
    />
  );
}
