import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { Path, useWatch } from 'react-hook-form';

import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { useFormContext } from '@/components/react-hook-form';
import { AccessParameterKind } from '@/types/schema';

import { AccessParameterAgeRequirementForm } from './AccessParameterAgeRequirementForm';
import { BeneficiaryFormAccessParameters } from './EntityBeneficiariesSubform.types';

type OtherAccessParameters = Exclude<
  BeneficiaryFormAccessParameters['accessParametersOther'],
  undefined
>;

type OtherAccessParameterFormPaths = Path<
  BeneficiaryFormAccessParameters['accessParametersOther']
>;

interface OtherAccessParameterFieldProps {
  kind: AccessParameterKind;
  fieldName: 'accessParametersOther';
  label: string;
}

export function OtherAccessParameterForm({
  kind,
  fieldName,
  label,
}: OtherAccessParameterFieldProps) {
  const itemFieldName =
    `${fieldName}.values.${kind}` as `values.${AccessParameterKind}` satisfies OtherAccessParameterFormPaths;
  const checkboxFieldName =
    `${fieldName}.checkboxes.${kind}` as `checkboxes.${AccessParameterKind}` satisfies OtherAccessParameterFormPaths;
  const kindFieldName =
    `${itemFieldName}.kind` as const satisfies OtherAccessParameterFormPaths;
  const notesFieldName =
    `${itemFieldName}.accessParameterNotes` as const satisfies OtherAccessParameterFormPaths;

  const { control, getValues, setValue } =
    useFormContext<OtherAccessParameters>();

  const itemIsSelected = useWatch({
    name: checkboxFieldName,
    control,
  }) as boolean | undefined;

  useEffect(
    function initKind() {
      if (itemIsSelected && !getValues(kindFieldName)) {
        setValue(kindFieldName, kind as never);
      }
    },
    [getValues, itemIsSelected, kind, kindFieldName, setValue]
  );

  return (
    <Stack>
      <FormAwareCheckbox<OtherAccessParameters>
        fieldName={checkboxFieldName}
        label={label}
        control={control}
      />
      {itemIsSelected && (
        <Stack pl={4} py={1} spacing={2}>
          <FormAwareTextInput<OtherAccessParameters>
            label="Notes"
            hideLabel
            placeholder="Specify details"
            multiline
            fieldName={notesFieldName}
            control={control}
            rows={2}
          />
          <AccessParameterAgeRequirementForm
            variant="multiple"
            fieldName={itemFieldName}
          />
        </Stack>
      )}
    </Stack>
  );
}
