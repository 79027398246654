import { compact } from 'lodash';
import { useMemo } from 'react';

import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { getNodes } from '@/utils/graphqlUtils';

import { EntityBeneficiariesFormBeneficiary } from './EntityBeneficiariesSubform.types';
import { BeneficiariesFormOptionsQuery } from './graphql/Beneficiaries.generated';

interface UseGetBeneficiaryOptionsReturnValue {
  options: SelectInputProps['options'];
}

export function useGetBeneficiaryOptions(
  currentEntityId: string | null,
  queryData: BeneficiariesFormOptionsQuery | undefined,
  subformValues: EntityBeneficiariesFormBeneficiary[] | undefined
): UseGetBeneficiaryOptionsReturnValue {
  const selectedBeneficiaryIds = useMemo(() => {
    return subformValues?.map((b) => b.beneficiaryId) ?? [];
  }, [subformValues]);

  const householdEntities: {
    display: string;
    value: string;
    disabled: boolean;
  }[] = getNodes(queryData?.entities).map((e) => ({
    value: e.id,
    display: e.subtype.displayName,
    disabled: selectedBeneficiaryIds.includes(e.id) || e.id === currentEntityId,
  }));

  const individualBeneficiaryOptions = (
    queryData?.households.edges?.[0]?.node?.possibleBeneficiariesV2.clients ??
    []
  ).map((b) => ({
    display: b.legalName,
    value: b.id,
    disabled: selectedBeneficiaryIds.includes(b.id),
  }));

  const organizationBeneficiaryOptions = (
    queryData?.households.edges?.[0]?.node?.possibleBeneficiariesV2
      .organizations ?? []
  ).map((b) => ({
    display: b.name,
    value: b.id,
    disabled: selectedBeneficiaryIds.includes(b.id),
  }));

  const beneficiaryOptions = useMemo(
    () =>
      compact([
        (() =>
          individualBeneficiaryOptions.length
            ? {
                component: <SelectItemGroupLabel label="Individuals" />,
                type: 'component' as const,
              }
            : null)(),

        ...individualBeneficiaryOptions.sort((a, b) =>
          a.display.localeCompare(b.display)
        ),
        (() =>
          organizationBeneficiaryOptions.length
            ? {
                component: <SelectItemGroupLabel label="Organizations" />,

                type: 'component' as const,
              }
            : null)(),

        ...organizationBeneficiaryOptions.sort((a, b) =>
          a.display.localeCompare(b.display)
        ),
        (() =>
          householdEntities.length
            ? {
                component: <SelectItemGroupLabel label="Entities" />,
                type: 'component' as const,
              }
            : null)(),

        ...householdEntities.sort((a, b) => a.display.localeCompare(b.display)),
      ]),
    [
      organizationBeneficiaryOptions,
      individualBeneficiaryOptions,
      householdEntities,
    ]
  );

  return { options: beneficiaryOptions };
}
