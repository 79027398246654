import { styled } from '@mui/system';
import { CSSProperties } from 'react';

import { Card, CardProps } from '@/components/layout/Card/Card';
import { COLORS } from '@/styles/tokens/colors';

export const Footer = styled('footer')({
  padding: '12px 16px',
  borderTop: `1px solid ${COLORS.NAVY[600]}`,
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '12px',
});

export const Content = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  padding: '24px 24px 32px 24px',
  gap: '24px',
  overflow: 'auto',
  height: '100%',
});

export const Section = styled('div')({
  display: 'grid',
  gap: '12px',
  width: '100%',
});

const panelStyles: CSSProperties = {
  backgroundColor: COLORS.PRIMITIVES.WHITE,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

export const Panel = ({
  variant = 'inner-shadow',
  style,
  ...props
}: Partial<CardProps>) => {
  return (
    <Card variant={variant} style={{ ...panelStyles, ...style }} {...props} />
  );
};
