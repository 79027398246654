import { compact } from 'lodash';

import { LoadingSkeletonOrValue } from '@/components/progress/LoadingSkeletonOrValue/LoadingSkeletonOrValue';
import { SummaryTable } from '@/components/tables/SummaryTable';
import { getTrusteeDisplayNames } from '@/modules/trustees/trusteeUtils';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { GratAnnuityPaymentAnnuityFragment } from './graphql/GratAnnuityPaymentTask.generated';

interface AnnuityDetailsSummaryProps {
  annuity: GratAnnuityPaymentAnnuityFragment | null;
  annuityLoading: boolean;
}

export function AnnuityDetailsSummary({
  annuity,
  annuityLoading,
}: AnnuityDetailsSummaryProps) {
  return (
    <SummaryTable
      rows={compact([
        {
          label: 'GRAT name',
          value: (
            <LoadingSkeletonOrValue
              loading={annuityLoading}
              value={annuity?.gratTrust?.displayName}
            />
          ),
        },
        {
          label: 'Grantor',
          value: (
            <LoadingSkeletonOrValue
              loading={annuityLoading}
              value={annuity?.gratTrust?.grantor?.individual?.displayName}
            />
          ),
        },
        {
          label: 'GRAT trustee',
          value: (
            <LoadingSkeletonOrValue
              loading={annuityLoading}
              value={getTrusteeDisplayNames(
                annuity?.gratTrust?.trustees ?? []
              ).join(', ')}
            />
          ),
        },
        {
          label: 'Payment window start date',
          value: (
            <LoadingSkeletonOrValue
              loading={annuityLoading}
              value={
                annuity?.paymentWindowStartDate
                  ? formatDateToMonDDYYYY(
                      new Date(annuity?.paymentWindowStartDate)
                    )
                  : undefined
              }
            />
          ),
        },
        {
          label: 'Grace period end date',
          value: (
            <LoadingSkeletonOrValue
              loading={annuityLoading}
              value={
                annuity?.paymentWindowEndDate
                  ? formatDateToMonDDYYYY(
                      new Date(annuity?.paymentWindowEndDate)
                    )
                  : undefined
              }
            />
          ),
        },
      ])}
    />
  );
}
