import { ApolloError, useApolloClient } from '@apollo/client';
import Decimal from 'decimal.js';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { Modal } from '@/components/modals/Modal/Modal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form/hooks';
import { invalidateCacheField } from '@/graphql/client.utils';
import { useReportError } from '@/hooks/useReportError';
import { AssetClassLiquidityStatus } from '@/types/schema';

import { CreateAssetCategoryFormShape } from './CreateAssetCategoryModal.types';
import { mapFormToCreateAssetCategoryShape } from './CreateAssetCategoryModal.utils';
import { CreateAssetCategoryModalForm } from './CreateAssetCategoryModalForm';
import { useCreateAssetClassMutation } from './graphql/CreateAssetCategoryModal.generated';

export interface CreateAssetCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  existingOptionsCount: number;
}

export function CreateAssetCategoryModal({
  isOpen,
  onClose,
  existingOptionsCount,
}: CreateAssetCategoryModalProps) {
  const apolloClient = useApolloClient();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [addAssetCategory, { loading }] = useCreateAssetClassMutation({
    onError: (error: ApolloError) => {
      showFeedback(
        'Encountered an error creating a new asset category. Please try again.'
      );
      reportError('Caught error when creating new asset category', error);
    },
    onCompleted: () => {
      showFeedback('Asset category created successfully', {
        variant: 'success',
      });
      onClose();
      // clear the cache for AssetClasses to trigger refetches if necessary
      return invalidateCacheField('assetClasses', apolloClient);
    },
  });

  const initialValues: CreateAssetCategoryFormShape = {
    name: '',
    associatedIntegrationAssetCategoryIDs: [],
    growthRate: new Decimal(0),
    liquidityStatus: AssetClassLiquidityStatus.Liquid,
  };

  const formMethods = useForm<CreateAssetCategoryFormShape>({
    defaultValues: initialValues,
  });

  const { reset, handleSubmit, formState } = formMethods;

  const onValidSubmit = (values: CreateAssetCategoryFormShape) => {
    const variables = {
      input: mapFormToCreateAssetCategoryShape(values, {
        existingOptionsCount,
      }),
    };
    return addAssetCategory({
      variables,
    });
  };

  const onSubmit = handleSubmit(onValidSubmit);

  return (
    <FormProvider {...formMethods}>
      <FormFieldsDisabledProvider isDisabled={loading}>
        <Modal
          heading="Add new asset category"
          isOpen={isOpen}
          onClose={() => {
            reset();
            onClose();
          }}
          actions={
            <>
              <Button
                variant="secondary"
                size="sm"
                disabled={formState.isSubmitting}
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                size="sm"
                loading={formState.isSubmitting}
                onClick={() => {
                  void onSubmit();
                }}
              >
                Create asset category
              </Button>
            </>
          }
        >
          <form noValidate onSubmit={onSubmit}>
            <CreateAssetCategoryModalForm />
          </form>
        </Modal>
      </FormFieldsDisabledProvider>
    </FormProvider>
  );
}
