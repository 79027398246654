import { Position } from '@xyflow/react';
import { partition } from 'lodash';

import { EntityMap_NodeFragment } from '../../graphql/EntityMap.generated';
import { EntityGraph } from '../../types';
import { getBeneficiaryId } from '../../utils/beneficiaries';
import { getGrantorId } from '../../utils/grantors';
import { getGrantorNodeId } from '../../utils/normalize';
import { addBeneficiaries, createBeneficiaryEdge } from '../beneficiaries';
import { addGrantors } from '../grantors';

// Note, this file is very similar to clt.ts, but not quite enough that it makes sense to de-duplicate it at the moment
export function addCRT(graph: EntityGraph, entity: EntityMap_NodeFragment) {
  if (entity.subtype.__typename !== 'CRTTrust') {
    throw new Error(`Unexpected type: ${entity.subtype.__typename}`);
  }

  const { lifetimeBeneficiaries, remainderBeneficiaries, grantors } =
    entity.subtype;

  const grantorIds = new Set(
    (grantors || []).flatMap((g) => getGrantorId(g) || [])
  );

  // Similar to GRATs, grantor payments go back to the original grantor tile, not a new beneficiary tile
  const [grantorLTBeneficiaries, nonGrantorLTBeneficiaries] = partition(
    lifetimeBeneficiaries,
    (b) => grantorIds.has(getBeneficiaryId(b)!)
  );

  addBeneficiaries(
    graph,
    entity.id,
    nonGrantorLTBeneficiaries,
    {
      targetHandle: Position.Bottom,
      data: {
        variant: 'secondary',
        edgeLabel: { label: 'Income', variant: 'secondary' },
      },
    },
    { type: 'payment' }
  );

  // Unlink SLATs, CRTs and CLTs treat remainder beneficiaries like any other kind
  // https://withluminary.slack.com/archives/C04S5L55MT6/p1686784240619969?thread_ts=1686782255.520049&cid=C04S5L55MT6
  addBeneficiaries(graph, entity.id, remainderBeneficiaries, {
    data: { edgeLabel: { label: 'Remainder' } },
  });

  addGrantors(graph, entity.id, grantors);

  grantorLTBeneficiaries?.forEach((b) => {
    const grantor = grantors?.find(
      (g) => getGrantorId(g) === getBeneficiaryId(b)
    );
    const source = entity.id;
    const target = getGrantorNodeId(getGrantorId(grantor)!);

    const edge = createBeneficiaryEdge(graph, b, {
      source: entity.id,
      target: target,
      targetHandle: Position.Bottom,
      data: {
        variant: 'secondary',
        edgeLabel: { label: 'Income', variant: 'secondary' },
      },
    });

    graph.addEdgeSafe(source, target, { edge, type: 'payment' });
  });
}
