import { createContext, useContext } from 'react';

export interface AccordionContext {
  closedAccordionIds: string[];
}

export const AccordionContext = createContext<AccordionContext>({
  closedAccordionIds: [],
});

export function useUnguardedAccordionContext() {
  return useContext(AccordionContext);
}
