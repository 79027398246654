// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
export type FileFragment = { __typename?: 'File', id: string, createdAt: Time, user: { __typename?: 'User', displayName: string, id: string }, download: { __typename?: 'FileDownload', downloadURL: string } };

export const FileFragmentDoc = gql`
    fragment File on File {
  id
  createdAt
  user {
    displayName
    id
  }
  download {
    downloadURL
  }
}
    `;
export const Operations = {
  Fragment: {
    File: 'File' as const
  }
}