import { AugmentedCreateGratAnnuityV2Input } from '@/types/schema';

import { Fields } from '../types';

export function generateAnnuityUpdates<ParentFormShape extends Fields>(
  formValues: ParentFormShape
): {
  create: AugmentedCreateGratAnnuityV2Input[];
} {
  const annuities = formValues.annuitiesSubform.annuities;

  if (annuities.length === 0) {
    return {
      create: [],
    };
  }

  const creationInputs: AugmentedCreateGratAnnuityV2Input[] = annuities.map(
    (annuity, i) => {
      return {
        create: {
          // assume the number of annuities is the source of truth for the GRAT term length
          // because the user is explicitly setting that here in the case where the annuit
          // schedule and the term length is being modified in one go
          termDurationYears: annuities.length,
          yearOfTerm: i + 1,
          initialFundingValuePercent: annuity.paymentPercentage,
        },
      };
    }
  );

  return {
    create: creationInputs,
  };
}
