import { first } from 'lodash';

import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { getNodes } from '@/utils/graphqlUtils';

import { useUserHasCreatedHouseholdsQuery } from './graphql/UserHasCreatedHousehold.generated';

export function useUserHasCreatedHousehold() {
  const currentUser = useCurrentUser();

  const userId = currentUser?.id || '';

  const { data, ...queryProps } = useUserHasCreatedHouseholdsQuery({
    variables: {
      userId,
    },
    skip: !userId,
  });

  const lastCreatedHouseholdId = first(getNodes(data?.households))?.id;

  return {
    hasCreatedHousehold: !!lastCreatedHouseholdId,
    lastCreatedHouseholdId,
    ...queryProps,
  };
}
