import { Stack } from '@mui/material';

import { GroupedHeaderList } from '@/components/lists/GroupedHeaderList/GroupedHeaderList';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';

import { TrustDetailsSummary_SummaryPersonFragment } from './graphql/TrustDetailsSummary.generated';
import { mapSummaryPersonToRichListItemProps } from './trustDetailsSummaryUtils';

interface Props {
  trustees: TrustDetailsSummary_SummaryPersonFragment[];
  successorTrustees: TrustDetailsSummary_SummaryPersonFragment[];
  trustAdvisors: TrustDetailsSummary_SummaryPersonFragment[];
}

export function TrusteeDetailsSummaryList({
  trustees,
  trustAdvisors,
  successorTrustees,
}: Props) {
  const normalizedTrusteeRows = [
    {
      heading: 'Trustees',
      items: trustees.map((t) => ({
        ...mapSummaryPersonToRichListItemProps(t),
        id: t.displayName,
      })),
    },
  ];

  const normalizedSuccessorTrusteeRows = [
    {
      heading: 'Successor trustees',
      items: successorTrustees.map((t) => ({
        ...mapSummaryPersonToRichListItemProps(t),
        id: t.displayName,
      })),
    },
  ];

  const normalizedTrustAdvisorRows = [
    {
      heading: 'Trust advisors',
      items: trustAdvisors.map((t) => ({
        ...mapSummaryPersonToRichListItemProps(t),
        id: t.displayName,
      })),
    },
  ];

  return (
    <Stack spacing={2}>
      <GroupedHeaderList<
        ReturnType<typeof mapSummaryPersonToRichListItemProps> & { id: string }
      >
        rowGroups={normalizedTrusteeRows}
        ItemComponent={({ item }) => <RichListItem {...item} />}
      />
      <GroupedHeaderList<
        ReturnType<typeof mapSummaryPersonToRichListItemProps> & { id: string }
      >
        rowGroups={normalizedSuccessorTrusteeRows}
        ItemComponent={({ item }) => <RichListItem {...item} />}
      />
      <GroupedHeaderList<
        ReturnType<typeof mapSummaryPersonToRichListItemProps> & { id: string }
      >
        rowGroups={normalizedTrustAdvisorRows}
        ItemComponent={({ item }) => <RichListItem {...item} />}
      />
    </Stack>
  );
}
