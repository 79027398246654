import {
  date,
  Describe,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import { AugmentedCreateControllingPartyInput } from '@/types/schema';

import {
  gstStatusSchema,
  inEstateStatusSchema,
  nonTrustEntityTaxStatus,
} from './types';

export const controllingPartySchema: Describe<AugmentedCreateControllingPartyInput> =
  object({
    create: object({
      individualID: optional(nullable(string())),
      organizationID: optional(nullable(string())),
    }),
  });

export const commonCreatePersonalAccountProperties = {
  legalName: optional(nullable(string())),
  effectiveDate: optional(nullable(date())),
  displayName: string(),
  description: optional(nullable(string())),
};

export const commonUpdatePersonalAccountProperties = {
  description: optional(nullable(string())),
  displayName: optional(nullable(string())),
  effectiveDate: optional(nullable(date())),
  legalName: optional(nullable(string())),
  taxStatus: nonTrustEntityTaxStatus,
  gstStatus: gstStatusSchema,
  inEstateStatus: inEstateStatusSchema,
};
