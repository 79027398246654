import type { Options as HTMLToImageOptions } from 'html-to-image/lib/types';

import { REACT_FLOW_CLASSES } from '../../constants';
import { CONTROLS_ID } from './constants';

type DownloadResolutionScale = '8k';

const resolutionToMaximumPixel: Record<DownloadResolutionScale, number> = {
  '8k': 7680,
};

export interface DownloadOptions extends HTMLToImageOptions {
  fileName?: string;
  elementSelector?: string;
  resolution?: '8k';
}

export const defaultDownloadOptions = {
  fileName: 'image',
  elementSelector: `.${REACT_FLOW_CLASSES.ROOT}`,
  resolution: '8k' as const,
  // We want a new image each time in case nodes have been moved around
  cacheBust: true,
  filter: (el: HTMLElement) => {
    // Exclude the controls from the image
    return el.id !== CONTROLS_ID;
  },
} as const satisfies DownloadOptions;

export function getFlowChartDownloadSizeOptions(
  element: Element,
  resolution: DownloadOptions['resolution']
) {
  const { clientHeight, clientWidth } = element;
  const desiredPixels =
    resolutionToMaximumPixel[resolution ?? defaultDownloadOptions.resolution];
  const largestClientPixelSize = Math.max(clientWidth, clientHeight);
  const resolutionFactor =
    desiredPixels / Math.min(largestClientPixelSize, desiredPixels) / 2;

  return {
    canvasWidth: clientWidth * resolutionFactor,
    canvasHeight: clientHeight * resolutionFactor,
  };
}
