import { Box, Stack, Typography } from '@mui/material';
import { gridStringOrNumberComparator } from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import { compact, isEmpty } from 'lodash';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Divider } from '@/components/Divider';
import { Button } from '@/components/form/baseInputs/Button';
import {
  DropdownButton,
  DropdownButtonProps,
} from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { DataIcon } from '@/components/icons/DataIcon';
import { Link03Icon } from '@/components/icons/Link03Icon';
import { UserPlus01Icon } from '@/components/icons/UserPlus01Icon';
import { Card } from '@/components/layout/Card/Card';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { Loader } from '@/components/progress/Loader/Loader';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { usePaginatedDataTableQuery } from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { TableEmptyState } from '@/components/tables/DataTable/TableEmptyState';
import { Column } from '@/components/tables/DataTable/types';
import { DataTable_LEGACYProps } from '@/components/tables/DataTable_LEGACY/DataTable_LEGACY';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { AIOnboardingAnnouncementBanner } from '@/modules/aiOnboarding/components/AIOnboardingAnnouncementBanner';
import { useIsUserLoggedIntoAddepar } from '@/modules/assetProviderIntegrations/addepar/hooks/useIsAddeparEnabled';
import { ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES } from '@/modules/assetProviderIntegrations/shared/constants';
import { usePollForJobCompletion } from '@/modules/asyncJobs/hooks/usePollForJobCompletion';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import {
  CreateHouseholdFlow,
  shouldPreopenOnboardingModal,
} from '@/modules/createHousehold/CreateHouseholdFlow/CreateHouseholdFlow';
import { MultiDocumentUploaderProvider } from '@/modules/documents/MultiDocumentUploader/context/MultiDocumentUploader.provider';
import { getHouseholdIntegration } from '@/modules/household/householdIntegrations';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';
import { HouseholdBillableKind } from '@/types/schema';
import { formatCurrencyNoDecimalsAccounting } from '@/utils/formatting/currency';
import { formatDateToMMDDYY } from '@/utils/formatting/dates';
import { getNodes } from '@/utils/graphqlUtils';

import {
  ClientListAdvisorClientFragment,
  useListClientsQuery,
} from './graphql/GetClients.generated';

type ClientRowData = ReturnType<typeof mapDataToRows>[number];
interface ClientTable extends ClientRowData {
  icon: unknown;
}

interface UseColumnsParams {
  showSubBrand: boolean;
  rows: ClientRowData[];
  refetch: () => void;
}

const useColumns = ({ showSubBrand, rows, refetch }: UseColumnsParams) => {
  const { displayName: defaultTenantDisplayName } = useTenantDetailsContext();

  // we don't really care about this being perfectly accurate; we're going to grab the first one we find, poll until it completes,
  // and then refetch and see if there's another outstanding one to poll on.
  const ingestJobToPoll = useMemo(() => {
    const pendingIngestRow = rows.find((row) => Boolean(row.pendingIngestJob));
    return pendingIngestRow?.pendingIngestJob ?? null;
  }, [rows]);

  usePollForJobCompletion(ingestJobToPoll, {
    onJobComplete: refetch,
  });

  return useMemo(() => {
    const columns: Column<ClientTable>[] = compact([
      {
        field: 'displayName',
        headerName: 'Client',
        minWidth: 200,
        flex: 1,
        renderCell: TextRenderer({
          textAccompaniment: ({ row }) => {
            if (!row.integrationProvider) return null;
            return (
              <Tooltip
                placement="top"
                title={`Linked to ${row.integrationProvider}`}
              >
                <Link03Icon size={17} />
              </Tooltip>
            );
          },
          rightContent: ({ row }) => {
            // there's not really enough space on smaller screens to show both the ingest indicator and the prospect indicator.
            // we prioritize the time-sensitive one, keeping in mind that it's highly unlikely that they will be ingesting assets
            // from addepar for a prospect, because loading assets into addepar is a long and expensive process
            if (row.pendingIngestJob) {
              return (
                <Tooltip placement="top" title="Currently ingesting assets">
                  <Loader circularProgressProps={{ size: 15 }} />
                </Tooltip>
              );
            }

            return (
              (row.isProspect && (
                <Badge variant={BadgeVariants.Yellow} display="Prospect" />
              )) ??
              null
            );
          },
          textProps: { fontWeight: FONT_WEIGHTS.bold },
        }),
      },
      showSubBrand && {
        field: 'subBrand',
        headerName: 'Sub-brand',
        minWidth: 200,
        renderCell: TextRenderer({
          text: ({ row }) => row.subBrand ?? defaultTenantDisplayName ?? '',
        }),
      },
      {
        field: 'totalAssets',
        headerName: 'Net value',
        width: 200,
        sortComparator: (
          v1: ClientRowData['totalAssets'],
          v2: ClientRowData['totalAssets'],
          p1,
          p2
        ) => {
          return gridStringOrNumberComparator(v1, v2, p1, p2);
        },
        renderCell: TextRenderer({
          textProps: { fontWeight: FONT_WEIGHTS.bold },
          text: ({ row }) =>
            formatCurrencyNoDecimalsAccounting(
              row.totalAssets ?? new Decimal(0)
            ),
        }),
      },
      {
        field: 'primaryRelationshipOwner',
        headerName: 'Relationship owner',
        minWidth: 175,
        flex: 1,
      },
      {
        field: 'nextTaskDueDate',
        headerName: 'Next task due',
        width: 150,
      },
      {
        field: 'icon',
        headerName: '',
        align: 'center',
        sortable: false,
        width: 64,
        minWidth: 64,
        maxWidth: 64,
        renderCell: IconRenderer({
          icon: ChevronRightIcon,
        }),
      },
    ]);

    return columns;
  }, [defaultTenantDisplayName, showSubBrand]);
};

function mapDataToRows(households: ClientListAdvisorClientFragment[]) {
  return (
    households?.flatMap((household) => {
      if (!household) return [];
      const taskDueDate = household.nextTaskDue?.dueAt ?? null;
      const householdIngestingEntities = getNodes(household.ingestingEntities);
      const firstIngestingJob =
        getNodes(householdIngestingEntities[0]?.asyncJobs)[0] ?? null;

      const integrationProvider = getHouseholdIntegration(household);

      return {
        id: household.id,
        displayName: household.displayName,
        totalAssets: household.estateValueMetrics.totalEstateValue,
        pendingIngestJob: firstIngestingJob ?? null,
        primaryRelationshipOwner:
          household.primaryRelationshipOwner?.displayName ??
          EMPTY_CONTENT_HYPHEN,
        nextTaskDueDate: taskDueDate
          ? formatDateToMMDDYY(taskDueDate)
          : EMPTY_CONTENT_HYPHEN,
        subBrand: household.subBrand?.displayName,
        isProspect: household.billableKind === HouseholdBillableKind.Prospect,
        integrationProvider: integrationProvider
          ? ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES[integrationProvider]
          : null,
      };
    }) ?? []
  );
}

const ManageExternalDataButton: FC = () => {
  const canCreateClients = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_CREATE_CLIENTS
  );
  const { navigate } = useNavigateToRoute();
  const isUserLoggedIntoAddepar = useIsUserLoggedIntoAddepar();
  if (!canCreateClients) {
    return null;
  }
  const addeparItems: DropdownButtonProps['items'] = isUserLoggedIntoAddepar
    ? [
        {
          name: 'Import new clients',
          clickHandler: () =>
            navigate(ROUTE_KEYS.IMPORT_ADDEPAR_CONTENT, {
              flowType: 'addepar',
            }),
        },
        {
          name: 'Import entities for linked clients',
          clickHandler: () =>
            navigate(ROUTE_KEYS.IMPORT_BULK_ENTITIES, {
              flowType: 'addepar',
            }),
        },
      ]
    : [];

  const items: DropdownButtonProps['items'] = compact([
    ...addeparItems,
    {
      name: 'Upload CSV with valuation data',
      clickHandler: () => navigate(ROUTE_KEYS.IMPORT_CSV_VALUATIONS, {}),
    },
  ]);

  if (isEmpty(items)) return null;

  return (
    <DropdownButton
      name="manage-external-data"
      variant="secondary"
      showArrow
      size="sm"
      buttonContent="Manage external data"
      startIcon={DataIcon}
      items={items}
    />
  );
};

const CreateClientButton: FC = () => {
  const canCreateClients = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_CREATE_CLIENTS
  );
  const onboardingModalInitialState = shouldPreopenOnboardingModal();
  const [modalState, { setModalIsOpen, closeModal }] = useModalState<boolean>(
    onboardingModalInitialState
  );

  const handleClick = () => {
    setModalIsOpen(true);
  };

  if (!canCreateClients) return null;

  return (
    <>
      <Button
        size="sm"
        variant="primary"
        onClick={handleClick}
        startIcon={UserPlus01Icon}
      >
        Add client
      </Button>
      <MultiDocumentUploaderProvider>
        <CreateHouseholdFlow
          isOpen={modalState.isModalOpen}
          onClose={closeModal}
        />
      </MultiDocumentUploaderProvider>
    </>
  );
};

export function ClientListPage() {
  const navigate = useNavigate();
  const { hasSubBrands } = useTenantDetailsContext();

  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [paginatedTableProps, { data, refetch }] = usePaginatedDataTableQuery(
    useListClientsQuery,
    {
      variables: {
        where: { hasClientProfiles: true },
      },
      pageSize: PageSizes.Fifty,
      onError: (error) => {
        reportError('failed to fetch clients list', error);
        showFeedback(
          'Failed to load clients. Please refresh the page to try again.'
        );
      },
    }
  );

  const rows = mapDataToRows(data ?? []);
  const columns = useColumns({
    showSubBrand: hasSubBrands,
    rows,
    refetch,
  });

  const onRowClick: DataTable_LEGACYProps['onRowClick'] = (params, event) => {
    const householdId = params.id;
    const clientDetailsPath = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
      { householdId: householdId.toString() }
    );

    if (event.metaKey) {
      // if the user cmd-clicks the row, open it in a new window
      window.open(clientDetailsPath, '_blank');
      return;
    }

    navigate(clientDetailsPath);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <Typography variant="h1">Clients</Typography>
        <Stack direction="row" spacing={1}>
          <ManageExternalDataButton />
          <CreateClientButton />
        </Stack>
      </Stack>
      <Divider
        sx={{
          borderColor: COLORS.GRAY[200],
        }}
      />
      <Stack p={3} gap={3}>
        <AIOnboardingAnnouncementBanner variant="clients" />
        <Stack flex={1}>
          <Card
            variant="outlined"
            sx={{
              flex: 1,
            }}
          >
            <Box p={3}>
              <DataTable
                {...paginatedTableProps}
                rows={rows}
                columns={columns}
                onRowClick={onRowClick}
                slots={{
                  noRowsOverlay: () => (
                    <TableEmptyState text="No clients">
                      <CreateClientButton />
                    </TableEmptyState>
                  ),
                }}
              />
            </Box>
          </Card>
        </Stack>
      </Stack>
    </>
  );
}
