import { SubForm } from '@/types/subform';

import {
  assetsSubformDefaultValues,
  AssetsSubformType,
  Fields,
  NAMESPACE,
  NamespaceType,
  SubformComponentProps,
  VariantType,
} from './types';

export { NAMESPACE };
export type { AssetsSubformType, Fields, VariantType };

import { AssetsSubformComponent } from './AssetsSubformComponent';

export class AssetsSubform
  implements
    SubForm<AssetsSubformType, Fields, NamespaceType, VariantType, object>
{
  FormComponent: (props: SubformComponentProps) => JSX.Element;
  defaultValues: AssetsSubformType;
  namespace: NamespaceType;

  constructor() {
    this.FormComponent = AssetsSubformComponent;
    this.defaultValues = assetsSubformDefaultValues;
    this.namespace = NAMESPACE;
  }
}
