import { keyBy } from 'lodash';

import { Node } from '@/components/diagrams/FlowChart';
import { UpdateEntityGraphViewInput } from '@/types/schema';

import { GraphViz_ViewFragment } from '../graphql/GraphVizView.generated';
import { mapFormDataToInput as mapFormDataToCreateInput } from './CreateGraphVizViewModal.utils';
import { GraphVizViewFormData } from './types';

interface MapInputToFormData {
  householdId: string;
  view: GraphViz_ViewFragment;
  nodes: Node[];
}
export const mapInputToFormData = ({
  nodes,
  householdId,
  view: { id, nodeConfigurations, displayName, kind, orientation },
}: MapInputToFormData): GraphVizViewFormData => {
  const nodeConfigurationByNodeId = keyBy(nodeConfigurations, (n) => n.nodeID);

  return {
    id,
    householdID: householdId,
    displayName,
    kind,
    orientation,
    nodeConfigurations: nodes.flatMap((node) => {
      const config = nodeConfigurationByNodeId[node.id];
      if (config) {
        const { hidden, ...rest } = config;
        return { visible: !hidden, node, ...rest };
      }

      return {
        nodeID: node.id,
        node,
        visible: true,
        xPosition: node.position.x,
        yPosition: node.position.y,
      };
    }),
  };
};

export const mapFormDataToInput = (
  formData: GraphVizViewFormData
): UpdateEntityGraphViewInput => {
  const { kind: _kind, ...createInput } = mapFormDataToCreateInput(formData);
  return createInput;
};
