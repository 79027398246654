import { PopperContent } from '@/components/poppers/PopperContent';

export function GRATType() {
  return (
    <PopperContent
      body={
        <span>
          Select a single GRAT, or a series of “rolling” GRATs that continue for
          the duration of the specified rolling period.
        </span>
      }
    />
  );
}
