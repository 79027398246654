// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PremiumPaymentMutationMutationVariables = Types.Exact<{
  updateEntityInput: Types.AugmentedUpdateEntityInput;
  taskId: Types.Scalars['ID']['input'];
}>;


export type PremiumPaymentMutationMutation = { __typename?: 'Mutation', updateEntity: { __typename?: 'Entity', id: string }, completeEntityTask: { __typename?: 'EntityTask', id: string } };


export const PremiumPaymentMutationDocument = gql`
    mutation PremiumPaymentMutation($updateEntityInput: AugmentedUpdateEntityInput!, $taskId: ID!) {
  updateEntity(input: $updateEntityInput) {
    id
  }
  completeEntityTask(id: $taskId) {
    id
  }
}
    `;
export type PremiumPaymentMutationMutationFn = Apollo.MutationFunction<PremiumPaymentMutationMutation, PremiumPaymentMutationMutationVariables>;

/**
 * __usePremiumPaymentMutationMutation__
 *
 * To run a mutation, you first call `usePremiumPaymentMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePremiumPaymentMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [premiumPaymentMutationMutation, { data, loading, error }] = usePremiumPaymentMutationMutation({
 *   variables: {
 *      updateEntityInput: // value for 'updateEntityInput'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function usePremiumPaymentMutationMutation(baseOptions?: Apollo.MutationHookOptions<PremiumPaymentMutationMutation, PremiumPaymentMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PremiumPaymentMutationMutation, PremiumPaymentMutationMutationVariables>(PremiumPaymentMutationDocument, options);
      }
export type PremiumPaymentMutationMutationHookResult = ReturnType<typeof usePremiumPaymentMutationMutation>;
export type PremiumPaymentMutationMutationResult = Apollo.MutationResult<PremiumPaymentMutationMutation>;
export type PremiumPaymentMutationMutationOptions = Apollo.BaseMutationOptions<PremiumPaymentMutationMutation, PremiumPaymentMutationMutationVariables>;
export const Operations = {
  Mutation: {
    PremiumPaymentMutation: 'PremiumPaymentMutation' as const
  }
}