import { Stack } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';

import { Card } from '@/components/layout/Card/Card';
import { DataTable } from '@/components/tables/DataTable/DataTable';

import {
  ROW_TYPE_INDIVIDUAL,
  ROW_TYPE_ORGANIZATION,
  RowType,
  TABLE_COLUMNS,
} from './ClientPeopleListView.utils';

export interface ClientDetailsPeopleOrganizationsTableProps {
  listContent: RowType[];
  openClientProfileModal: (clientProfileId: string | null) => void;
  openClientOrganizationModal: (clientOrganizationId: string | null) => void;
}

export function ClientDetailsPeopleOrganizationsTable({
  listContent,
  openClientProfileModal,
  openClientOrganizationModal,
}: ClientDetailsPeopleOrganizationsTableProps) {
  return (
    <Card sx={{ p: 3 }} variant="outlined">
      <Stack direction="column">
        <DataTable
          columns={TABLE_COLUMNS}
          rows={listContent}
          onRowClick={(params: GridRowParams<RowType>) => {
            const { row } = params;
            if (row.kind === ROW_TYPE_INDIVIDUAL) {
              openClientProfileModal(row.id);
            } else if (row.kind === ROW_TYPE_ORGANIZATION) {
              openClientOrganizationModal(row.id);
            }
          }}
        />
      </Stack>
    </Card>
  );
}
