import { compact } from 'lodash';

import {
  INCOME_BENEFICIARY_SUBFORM,
  LEAD_BENEFICIARY_SUBFORM,
} from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.constants';
import {
  AccessParameterRadioGroupKind,
  BeneficiaryFormAccessParameter,
  EntityBeneficiariesFormBeneficiary,
} from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.types';
import { parseStringToInteger } from '@/modules/entities/gratTrusts/TermsSubform/utils/mutationInputUtils';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  AccessParameterKind,
  AugmentedCreateAccessAgeParameterInput,
  AugmentedCreateAccessParameterInput,
  AugmentedCreateBeneficiaryInput,
  AugmentedCreateScheduledDistributionInput,
  BeneficiaryPowerOfAppointmentPower,
  ScheduledDistributionKind,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { SubformsCombinedType } from '../../EntitySubforms.types';
import { getRelatedModelInputProperties } from './common.utils';

export function getCharitablePrimaryBeneficiary(
  entityType: EntityType,
  formInput: Partial<SubformsCombinedType>
): EntityBeneficiariesFormBeneficiary | undefined {
  const validCharitableEntities: EntityType[] = ['crt', 'clt'];

  if (!validCharitableEntities.includes(entityType)) {
    throw new Error('Invalid entity type, not a charitable entity');
  }

  const isCrt = entityType === 'crt';

  return isCrt
    ? formInput[INCOME_BENEFICIARY_SUBFORM]?.incomeBeneficiary
    : formInput[LEAD_BENEFICIARY_SUBFORM]?.leadBeneficiary;
}

export function makeCreateBeneficiaryInputs(
  beneficiaries: EntityBeneficiariesFormBeneficiary[],
  householdId: string
): AugmentedCreateBeneficiaryInput[] {
  return compact(
    beneficiaries.map((beneficiary) => {
      if (!beneficiary.beneficiaryId) {
        return null;
      }

      const beneficiaryId = beneficiary.beneficiaryId;
      const beneficiaryLevel = beneficiary.level || undefined;
      const beneficiaryIds = getRelatedModelInputProperties(beneficiaryId);

      const hasScheduledDistributions =
        beneficiary.beneficiaryFormScheduledDistributions
          ?._hasScheduledDistributions;

      const scheduledDistributionCreateInputs: AugmentedCreateScheduledDistributionInput[] =
        beneficiary.beneficiaryFormScheduledDistributions?.scheduledDistributions?.flatMap(
          (sd) => {
            if (!hasScheduledDistributions) return [];

            const kind: ScheduledDistributionKind =
              sd.scheduledDistribution.kind;

            const ageParams: {
              ageRequirementStart: number | null | undefined;
              ageRequirementEnd: number | null | undefined;
            } = {
              ageRequirementStart: undefined,
              ageRequirementEnd: undefined,
            };
            switch (sd._ageRequirementKind) {
              case 'upon':
                ageParams.ageRequirementStart = sd._hasAgeParams
                  ? parseStringToInteger(
                      sd.scheduledDistribution.ageRequirementStart ?? ''
                    )
                  : undefined;
                break;
              case 'until':
                ageParams.ageRequirementEnd = sd._hasAgeParams
                  ? parseStringToInteger(
                      sd.scheduledDistribution.ageRequirementEnd ?? ''
                    )
                  : undefined;
                break;
              case 'between':
                ageParams.ageRequirementStart = sd._hasAgeParams
                  ? parseStringToInteger(
                      sd.scheduledDistribution.ageRequirementStart ?? ''
                    )
                  : undefined;
                ageParams.ageRequirementEnd = sd._hasAgeParams
                  ? parseStringToInteger(
                      sd.scheduledDistribution.ageRequirementEnd ?? ''
                    )
                  : undefined;
                break;
            }

            return {
              create: {
                ...ageParams,
                amount:
                  kind === ScheduledDistributionKind.Amount
                    ? sd.scheduledDistribution.amount
                    : undefined,
                frequency: sd.scheduledDistribution.frequency || undefined,
                kind: sd.scheduledDistribution.kind,
                percentage:
                  kind === ScheduledDistributionKind.Percentage
                    ? sd.scheduledDistribution.percentage
                    : undefined,
                scheduledDistributionNotes:
                  sd.scheduledDistribution.scheduledDistributionNotes,
                householdID: householdId,
              },
            };
          }
        ) ?? [];

      const accessParameterKind =
        beneficiary.beneficiaryFormAccessParameters?._accessParameterKind;

      const accessParametersAsList: BeneficiaryFormAccessParameter[] = [];
      if (
        accessParameterKind === AccessParameterRadioGroupKind.Full &&
        beneficiary.beneficiaryFormAccessParameters?.accessParametersFull
      ) {
        accessParametersAsList.push(
          beneficiary.beneficiaryFormAccessParameters.accessParametersFull
        );
      }

      if (accessParameterKind === AccessParameterRadioGroupKind.Partial) {
        accessParametersAsList.push(
          ...(beneficiary.beneficiaryFormAccessParameters
            ?.accessParametersPartial ?? [])
        );
      }

      if (accessParameterKind === AccessParameterRadioGroupKind.Other) {
        const checkboxes =
          beneficiary.beneficiaryFormAccessParameters?.accessParametersOther
            ?.checkboxes ?? {};
        const values =
          beneficiary.beneficiaryFormAccessParameters?.accessParametersOther
            ?.values ?? {};

        const includeKeys: AccessParameterKind[] = (
          Object.keys(checkboxes) as AccessParameterKind[]
        ).filter((key) => checkboxes[key] === true);

        (
          Object.entries(values) as [
            AccessParameterKind,
            BeneficiaryFormAccessParameter,
          ][]
        ).forEach(([key, value]) => {
          if (includeKeys.includes(key)) {
            accessParametersAsList.push(value);
          }
        });

        // If only the radio button is selected, create an empty access parameter
        if (includeKeys.length === 0) {
          accessParametersAsList.push({
            kind: AccessParameterKind.Other,
            _hasFrequency: false,
            _hasAgeParams: false,
            ageParams: [],
          });
        }
      }

      const accessParameterCreateInputs: AugmentedCreateAccessParameterInput[] =
        compact(accessParametersAsList).map(
          (ap: BeneficiaryFormAccessParameter) => {
            const ageParams: AugmentedCreateAccessAgeParameterInput[] =
              ap.ageParams?.flatMap(
                (ageParam): AugmentedCreateAccessAgeParameterInput[] => {
                  if (!ageParam._ageRequirementKind || !ap._hasAgeParams)
                    return [];

                  const commonAgeParams = {
                    notes: ageParam.notes,
                    householdID: householdId,
                  };

                  switch (ageParam._ageRequirementKind) {
                    case 'upon':
                      return [
                        {
                          create: {
                            ...commonAgeParams,
                            ageRequirementStart: ap._hasAgeParams
                              ? parseStringToInteger(
                                  ageParam.ageRequirementStart ?? ''
                                )
                              : undefined,
                          },
                        },
                      ];
                    case 'until':
                      return [
                        {
                          create: {
                            ...commonAgeParams,
                            ageRequirementEnd: ap._hasAgeParams
                              ? parseStringToInteger(
                                  ageParam.ageRequirementEnd ?? ''
                                )
                              : undefined,
                          },
                        },
                      ];
                    case 'between':
                      return [
                        {
                          create: {
                            ...commonAgeParams,
                            ageRequirementStart: ap._hasAgeParams
                              ? parseStringToInteger(
                                  ageParam.ageRequirementStart ?? ''
                                )
                              : undefined,
                            ageRequirementEnd: ap._hasAgeParams
                              ? parseStringToInteger(
                                  ageParam.ageRequirementEnd ?? ''
                                )
                              : undefined,
                          },
                        },
                      ];
                    default:
                      throw new UnreachableError({
                        case: ageParam._ageRequirementKind,
                        message: `Unexpected age requirement kind ${ageParam._ageRequirementKind}`,
                      });
                  }
                }
              ) ?? [];

            return {
              create: {
                accessParameterNotes: ap.accessParameterNotes,
                amount:
                  ap.kind === AccessParameterKind.Amount
                    ? ap.amount
                    : undefined,
                frequency:
                  ap._hasFrequency && ap.frequency ? ap.frequency : undefined,
                kind: ap.kind,
                percentage:
                  ap.kind === AccessParameterKind.Percentage ||
                  ap.kind === AccessParameterKind.NetIncome ||
                  ap.kind === AccessParameterKind.NetIncomeWithMakeup
                    ? ap.percentage
                    : undefined,
                householdID: householdId,
              },
              withAccessAgeParameters: ageParams,
            };
          }
        ) ?? [];

      const hasPowerOfAppointment =
        beneficiary.beneficiaryFormPowerOfAppointment?._hasPowerOfAppointment &&
        beneficiary.beneficiaryFormPowerOfAppointment?.power;

      const powerIsGeneral =
        beneficiary.beneficiaryFormPowerOfAppointment?.power ===
        BeneficiaryPowerOfAppointmentPower.General;

      return {
        create: {
          notes: beneficiary.notes,
          level: beneficiaryLevel,
          ...beneficiaryIds,
        },
        withScheduledDistributions: scheduledDistributionCreateInputs,
        withAccessParameters: accessParameterCreateInputs,
        withPowerOfAppointment: hasPowerOfAppointment
          ? {
              create: {
                householdID: householdId,
                power:
                  beneficiary.beneficiaryFormPowerOfAppointment?.power ||
                  undefined,
                powerOtherNote: powerIsGeneral
                  ? undefined
                  : beneficiary.beneficiaryFormPowerOfAppointment
                      ?.powerOtherNote,
              },
            }
          : undefined,
      };
    })
  );
}
