import { Decimal } from 'decimal.js';
import { compact, parseInt } from 'lodash';
import { useCallback, useState } from 'react';

import { GratTrustFragment } from '@/modules/entities/gratTrusts/graphql/GratTrust.generated';
import { EntityKind, EntityStage, TrustTaxStatus } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

export const NODE_HEIGHT = 150;
export const NODE_WIDTH = 158;
export const SEPARATION_SCALE = 1.2;

export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>();

  // getDimensions is a callback that is passed to the Tree component
  // and takes a ref to the svg container element as an argument
  const getDimensions = useCallback((containerElem: HTMLDivElement) => {
    // function to center the tree
    let hasBeenObserved = false;
    const centerEl = (containerElem: HTMLDivElement) => {
      // distance between the origin and the
      let min = 0;
      containerElem.querySelectorAll('g').forEach((g) => {
        try {
          const transform = g.getAttribute('transform') ?? '';
          const regExp = /\(([^)]+)\)/;
          const matches = regExp.exec(transform);
          if (matches) {
            min = Math.min(parseInt(matches[1]!.split(',')[1]!), min);
          }
        } catch (e) {
          // ignore error
        }
      });

      // gets the width of the svg container element
      const { width } = containerElem.getBoundingClientRect();

      // gets the maximum width and height of the svg element
      const svgWidth = containerElem.querySelector('g')?.getBBox().width ?? 0;
      const svgHeight = containerElem.querySelector('g')?.getBBox().height ?? 0;

      setDimensions({ width: svgWidth, height: svgHeight });

      // centers the svg element in the svg container element
      setTranslate({
        x: width / 2 - svgWidth / 2,
        // either shift the tree down by the tallest leaf y translation
        // or by half the height of the svg element
        y: Math.max(min * -1 + Math.floor(NODE_HEIGHT / 2), svgHeight / 2),
      });

      hasBeenObserved = true;
    };

    const config = { attributes: true, childList: true, subtree: true };

    const callback = (mutationList: MutationRecord[]) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes') {
          if (
            mutation.attributeName === 'height' ||
            mutation.attributeName === 'width'
          ) {
            centerEl(containerElem);
          }
        }
      }
    };

    // observe the svg container element for changes in width and height
    // because layout is not immediate
    const observer = new MutationObserver(callback);
    observer.observe(containerElem, config);

    setTimeout(() => {
      // if we never observed the svg container element, center it now
      !hasBeenObserved && centerEl(containerElem);

      // disconnect observer after 2 seconds which should be enough time to get the initial dimensions
      observer.disconnect();
    }, 2000);
  }, []);
  return [dimensions, translate, getDimensions] as const;
};

interface Leaf<T, C> {
  name: string;
  attributes: T;
  children: C[];
}

export type IndividualLeaf = Leaf<
  {
    type: 'Individual';
    percentOwnership: Decimal;
    distributedValue: Decimal | null;
  },
  never
>;

export type OrganizationLeaf = Leaf<
  {
    type: 'Organization';
    percentOwnership: Decimal;
    distributedValue: Decimal | null;
  },
  never
>;

export type EntityBeneficiaryLeaf = Leaf<
  {
    type: 'Entity';
    percentOwnership: Decimal;
    distributedValue: Decimal | null;
    trustType?: EntityKind;
  },
  never
>;

export type NoBeneficiaryLeaf = Leaf<
  {
    type: 'NoBeneficiary';
  },
  never
>;

export type TrustLeaf = Leaf<
  {
    type: 'Trust';
    trustType: TrustTaxStatus | null;
    percentOwnership: Decimal;
    distributedValue: Decimal | null;
  },
  IndividualLeaf | OrganizationLeaf | EntityBeneficiaryLeaf
>;

export type GratLeaf = Leaf<
  {
    type: 'Grat';
    gratSummaryDisplay: string;
    initialFundingValue?: Decimal;
    grantorRetainedInterest?: Decimal | null;
    calculatedTaxableGift?: Decimal | null;
    rate7520: Decimal | null;
  },
  | TrustLeaf
  | IndividualLeaf
  | NoBeneficiaryLeaf
  | OrganizationLeaf
  | EntityBeneficiaryLeaf
>;

export interface BeneficiaryTree {
  name: string;
  attributes: {
    type: 'Grantor';
  };
  children: GratLeaf[];
}

/**
 * scenarios to handle are:
 * 1) GRAT is completed with a distribution (show the distributed value)
 * 2) GRAT is completed but no distribution (show $0)
 * 3) GRAT is incomplete (show nothing)
 */
function getDistributedValue(
  baseValue: Decimal | null,
  accessPercentage: Decimal
): Decimal | null {
  if (!baseValue) {
    return null;
  }

  return baseValue.times(accessPercentage.div(new Decimal(100)));
}

function getDistributedValueForFirstLevelBeneficiary({
  stage,
  gratTrust,
  beneficiaryDistributionPercentage,
}: {
  stage: EntityStage;
  gratTrust: GratTrustFragment;
  beneficiaryDistributionPercentage?: Decimal | null;
}) {
  if (stage !== EntityStage.Completed || !beneficiaryDistributionPercentage) {
    return null;
  }

  const totalDistributedToIndividual =
    gratTrust.distributionAssetValuation?.valuationValue ?? new Decimal(0);

  return getDistributedValue(
    totalDistributedToIndividual,
    beneficiaryDistributionPercentage
  );
}

function getDistributedValueForNestedBeneficiary({
  stage,
  totalDistributedToTrust,
  accessPercentage,
}: {
  stage: EntityStage;
  totalDistributedToTrust: Decimal;
  accessPercentage?: Decimal | null;
}) {
  if (stage !== EntityStage.Completed) {
    return null;
  }

  return getDistributedValue(
    totalDistributedToTrust,
    accessPercentage ?? new Decimal(0)
  );
}

export function makeBeneficiaryTreeV2(
  gratTrust: GratTrustFragment,
  stage: EntityStage
): BeneficiaryTree {
  const grantor: BeneficiaryTree = {
    name: gratTrust.grantor?.individual?.displayName ?? '',
    attributes: {
      type: 'Grantor' as const,
    },
    children: [],
  };

  const beneficiaries: (
    | TrustLeaf
    | IndividualLeaf
    | NoBeneficiaryLeaf
    | OrganizationLeaf
    | EntityBeneficiaryLeaf
  )[] =
    compact(
      gratTrust?.beneficiaries?.map((beneficiary) => {
        const topLevelBeneficiaryAccessParam = getNodes(
          beneficiary.accessParameters
        )[0];
        if (!topLevelBeneficiaryAccessParam) {
          diagnostics.warn(
            'Attempting to show beneficiary with no defined distribution in GratBeneficiaryTree',
            {
              beneficiaryId: beneficiary.id,
            }
          );
          return null;
        }

        if (beneficiary.entity?.irrevocableTrust) {
          const irrevocableTrustOwnershipPercent =
            topLevelBeneficiaryAccessParam.percentage?.div(new Decimal(100)) ??
            new Decimal(0);

          const totalDistribution =
            gratTrust.distributionAssetValuation?.valuationValue ??
            new Decimal(0);

          const totalDistributedToTrust = totalDistribution.times(
            topLevelBeneficiaryAccessParam.percentage?.div(new Decimal(100)) ??
              new Decimal(0)
          );

          const children: (
            | IndividualLeaf
            | OrganizationLeaf
            | EntityBeneficiaryLeaf
          )[] =
            beneficiary.entity?.irrevocableTrust?.beneficiaries?.map(
              ({ accessParameters, individual, organization, entity }) => {
                const accessPercentage =
                  getNodes(accessParameters)[0]?.percentage;

                if (individual) {
                  return {
                    name: individual?.displayName ?? '',
                    attributes: {
                      type: 'Individual' as const,
                      percentOwnership: accessPercentage ?? new Decimal(0),
                      distributedValue: getDistributedValueForNestedBeneficiary(
                        {
                          stage,
                          totalDistributedToTrust,
                          accessPercentage,
                        }
                      ),
                    },
                    children: [],
                  };
                }

                if (organization) {
                  return {
                    name: organization?.name ?? '',
                    attributes: {
                      type: 'Organization' as const,
                      percentOwnership: accessPercentage ?? new Decimal(0),
                      distributedValue: getDistributedValueForNestedBeneficiary(
                        {
                          stage,
                          totalDistributedToTrust,
                          accessPercentage,
                        }
                      ),
                    },
                    children: [],
                  };
                }

                if (entity) {
                  return {
                    name: entity.subtype.displayName ?? '',
                    attributes: {
                      type: 'Entity' as const,
                      trustType: entity.kind,
                      percentOwnership: accessPercentage ?? new Decimal(0),
                      distributedValue: getDistributedValueForNestedBeneficiary(
                        {
                          stage,
                          totalDistributedToTrust,
                          accessPercentage,
                        }
                      ),
                    },
                    children: [],
                  };
                }

                throw new Error('Could not map beneficiary to leaf');
              }
            ) ?? [];

          return {
            name: beneficiary.entity.irrevocableTrust.displayName,
            children: [...children],
            attributes: {
              type: 'Trust' as const,
              trustType: beneficiary.entity.irrevocableTrust.taxStatus ?? null,
              percentOwnership:
                topLevelBeneficiaryAccessParam.percentage ?? new Decimal(0),
              // we don't care about showing the value distributed to the trust unless
              // the trust has no beneficiaries
              distributedValue: (() => {
                if (children.length > 0 || stage !== EntityStage.Completed) {
                  return null;
                }

                return getDistributedValue(
                  totalDistributedToTrust,
                  irrevocableTrustOwnershipPercent
                );
              })(),
            },
          };
        }

        if (beneficiary.individual) {
          return {
            name: beneficiary?.individual?.displayName ?? '',
            attributes: {
              type: 'Individual' as const,
              percentOwnership:
                topLevelBeneficiaryAccessParam?.percentage ?? new Decimal(0),
              distributedValue: getDistributedValueForFirstLevelBeneficiary({
                stage,
                gratTrust,
                beneficiaryDistributionPercentage:
                  topLevelBeneficiaryAccessParam.percentage,
              }),
            },
            children: [],
          };
        }

        if (beneficiary.organization) {
          return {
            name: beneficiary?.organization.name ?? '',
            attributes: {
              type: 'Organization' as const,
              percentOwnership:
                topLevelBeneficiaryAccessParam?.percentage ?? new Decimal(0),
              distributedValue: getDistributedValueForFirstLevelBeneficiary({
                stage,
                gratTrust,
                beneficiaryDistributionPercentage:
                  topLevelBeneficiaryAccessParam.percentage,
              }),
            },
            children: [],
          };
        }

        if (beneficiary.entity) {
          return {
            name: beneficiary.entity.subtype.displayName ?? '',
            attributes: {
              type: 'Entity' as const,
              trustType: beneficiary.entity.kind,
              percentOwnership:
                topLevelBeneficiaryAccessParam?.percentage ?? new Decimal(0),
              distributedValue: getDistributedValueForFirstLevelBeneficiary({
                stage,
                gratTrust,
                beneficiaryDistributionPercentage:
                  topLevelBeneficiaryAccessParam.percentage,
              }),
            },
            children: [],
          };
        }

        throw new Error('Could not map beneficiary to leaf');
      })
    ) ?? [];

  const gratDetails: GratLeaf = {
    name: gratTrust.displayName,
    attributes: {
      type: 'Grat' as const,
      gratSummaryDisplay: gratTrust.gratSummaryDisplay ?? '',
      initialFundingValue: gratTrust.initialFundingValue ?? undefined,
      grantorRetainedInterest: gratTrust.grantorRetainedInterest,
      calculatedTaxableGift: gratTrust.taxableGift,
      rate7520: gratTrust.officialInterestRatePercent ?? null,
    },
    children: [],
  };

  if (beneficiaries.length === 0) {
    beneficiaries.push({
      name: 'No beneficiary',
      attributes: {
        type: 'NoBeneficiary' as const,
      },
      children: [],
    });
  }

  gratDetails.children.push(...(beneficiaries ?? []));

  grantor.children.push(gratDetails);
  const beneficiaryTree = grantor;

  return beneficiaryTree;
}
