import { ComponentProps, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { TrustDetailsSubform } from '../../TrustDetailsSubform/TrustDetailsSubform';
import { TRUST_DETAILS_SUBFORM_NAMESPACE } from '../../TrustDetailsSubform/TrustDetailsSubform.types';
import { ControllingPartyInput } from './ControllingPartyInput';
import {
  controllingPartyEntityKindOptions,
  TESTAMENTARY_ENTITY,
} from './TestamentaryEntityForm.constants';
import {
  TestamentaryEntityFormField,
  TestamentaryEntityFormShape,
} from './TestamentaryEntityForm.types';

const { FormComponent: TrustDetailsFormComponent } = new TrustDetailsSubform();

export function EntityDetailsTab({
  deadClientProfileIds,
}: {
  deadClientProfileIds: string[];
}) {
  const { householdId } = useHouseholdDetailsContext();
  const { control } = useFormContext<TestamentaryEntityFormShape>();

  const subformValues = useWatch({
    control,
    name: `${TRUST_DETAILS_SUBFORM_NAMESPACE}` as const satisfies TestamentaryEntityFormField,
  });

  const entityKind = useWatch({
    control,
    name: `${TESTAMENTARY_ENTITY}.testamentaryEntityKind` as const satisfies TestamentaryEntityFormField,
  });

  const isControllingPartyEntityKind = useMemo(() => {
    if (entityKind === '') {
      return false;
    }

    return controllingPartyEntityKindOptions.includes(entityKind);
  }, [entityKind]);

  const trusteeSubformVariant: ComponentProps<
    typeof TrustDetailsFormComponent
  >['variant'] = useMemo(() => {
    if (isControllingPartyEntityKind) {
      return 'TrusteesAndSuccessorTrustees';
    }

    return 'TrusteesSuccessorTrusteesAndTrustAdvisors';
  }, [isControllingPartyEntityKind]);

  return (
    <>
      {isControllingPartyEntityKind && (
        <FormLayoutRow>
          {householdId && (
            <FormLayoutItem>
              <ControllingPartyInput
                subformValues={subformValues}
                filterClientProfileIds={deadClientProfileIds}
                householdId={householdId}
              />
            </FormLayoutItem>
          )}
        </FormLayoutRow>
      )}
      <FormLayoutRow>
        {!isControllingPartyEntityKind && householdId && (
          <FormLayoutItem>
            <TrustDetailsFormComponent
              variant={trusteeSubformVariant}
              householdId={householdId}
              subformValues={subformValues}
              filterClientProfileIds={deadClientProfileIds}
            />
          </FormLayoutItem>
        )}
      </FormLayoutRow>

      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput<TestamentaryEntityFormShape>
            control={control}
            fieldName={
              `${TESTAMENTARY_ENTITY}.entityDetailsNotes` as const satisfies TestamentaryEntityFormField
            }
            label="Notes"
            multiline
            rows={2}
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </>
  );
}
