import { EditEntitySection } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { EntitySubformNamespaceType } from '@/modules/entities/EntitySubforms/entitySubformConfigs';

export const NO_ENTITY_SECTIONS = 'NO_ENTITY_SECTIONS' as const;

export const ENTITY_SECTION_TO_SUBFORM_MAP: Record<
  EditEntitySection,
  EntitySubformNamespaceType | typeof NO_ENTITY_SECTIONS
> = {
  [EditEntitySection.BASIC_INFORMATION]: 'basicInformationSubform',
  [EditEntitySection.TRUSTEES]: 'trustDetailsSubform',
  [EditEntitySection.TAX_STATUS]: 'taxStatusSubform',
  [EditEntitySection.BENEFICIARIES]: 'beneficiariesSubform',
  [EditEntitySection.LIFETIME_BENEFICIARIES]: 'lifetimeBeneficiariesSubform',
  [EditEntitySection.REMAINDER_BENEFICIARIES]: 'remainderBeneficiariesSubform',
  [EditEntitySection.INCOME_BENEFICIARIES]: 'incomeBeneficiarySubform',
  [EditEntitySection.LEAD_BENEFICIARIES]: 'leadBeneficiarySubform',
  [EditEntitySection.INSURANCE_POLICIES]: 'insurancePolicyDetailsSubform',
  [EditEntitySection.INITIAL_FUNDING_ASSETS]:
    'basicAssetsInitialFundingSubform',
  [EditEntitySection.ANNUITY_PAYMENTS]: 'annuitiesSubform',
  [EditEntitySection.BUSINESS_ENTITY_TAX_STATUS]:
    'businessEntityTaxStatusSubform',
  [EditEntitySection.BUSINESS_ENTITY_DETAILS]: 'businessEntityDetailsSubform',
  // DP sections map to NO_ENTITY_SECTIONS, since they don't have corresponding entity subforms
  [EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1]: NO_ENTITY_SECTIONS,
  [EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2]: NO_ENTITY_SECTIONS,
};

export const ENTITY_SUBFORM_TO_SECTION_MAP: Record<
  EntitySubformNamespaceType,
  EditEntitySection
> = Object.keys(ENTITY_SECTION_TO_SUBFORM_MAP)
  // NOTE: We're doing a manual reduce to invert the map, because invert() from
  // lodash returns the keys as strings, which fails the type check.
  .reduce(
    (acc, key) => {
      const section = key as EditEntitySection;
      const subform = ENTITY_SECTION_TO_SUBFORM_MAP[section];
      if (!subform || subform === NO_ENTITY_SECTIONS) return acc;
      acc[subform] = section;
      return acc;
    },
    {} as Record<EntitySubformNamespaceType, EditEntitySection>
  );

export const EDIT_ENTITY_SECTION_DISPLAY: Record<EditEntitySection, string> = {
  [EditEntitySection.BASIC_INFORMATION]: 'Edit details',
  [EditEntitySection.TRUSTEES]: 'Edit trustees',
  [EditEntitySection.TAX_STATUS]: 'Edit tax status',
  [EditEntitySection.BENEFICIARIES]: 'Edit beneficiaries',
  [EditEntitySection.LIFETIME_BENEFICIARIES]: 'Edit lifetime beneficiaries',
  [EditEntitySection.REMAINDER_BENEFICIARIES]: 'Edit remainder beneficiaries',
  [EditEntitySection.INCOME_BENEFICIARIES]: 'Edit income beneficiaries',
  [EditEntitySection.LEAD_BENEFICIARIES]: 'Edit lead beneficiaries',
  [EditEntitySection.INSURANCE_POLICIES]: 'Edit insurance policies',
  [EditEntitySection.INITIAL_FUNDING_ASSETS]: 'Edit initial funding assets',
  [EditEntitySection.ANNUITY_PAYMENTS]: 'Edit annuity payments',
  [EditEntitySection.BUSINESS_ENTITY_DETAILS]: 'Edit ownership & key people',
  [EditEntitySection.BUSINESS_ENTITY_TAX_STATUS]: 'Edit tax status',
  [EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1]: 'Edit dispositions',
  [EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2]: 'Edit dispositions',
};
