import Decimal from 'decimal.js';

import { Card } from '@/components/layout/Card/Card';
import {
  MiniTable,
  MiniTableRow,
} from '@/components/tables/MiniTable/MiniTable';
import { COLORS } from '@/styles/tokens/colors';
import {
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';

import { GetHypotheticalTransfers_HypotheticalTransferFragment } from './graphql/GetHypotheticalTransfers.generated';
import { getNetHypotheticalTransfers } from './HypotheticalTransfersCard.utils';

export interface HypotheticalTransfersSummaryTableProps {
  currentValue?: Decimal;
  transfers: {
    inboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
    outboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
  };
  isIndividual: boolean;
  isEntity: boolean;
  growthRate: Decimal;
}

export function HypotheticalTransfersSummaryTable({
  currentValue = new Decimal(0),
  transfers: { inboundTransfers, outboundTransfers },
  isIndividual,
  growthRate: wholePercentGrowthRate,
}: HypotheticalTransfersSummaryTableProps) {
  // emtpy state
  if (!inboundTransfers.length && !outboundTransfers.length) {
    return null;
  }

  const growthRate = wholePercentGrowthRate.dividedBy(100).plus(1);

  const {
    totalNormalizedTransfers,
    totalValue: netResult,
    finalYear,
  } = getNetHypotheticalTransfers({
    currentValue,
    inboundTransfers,
    outboundTransfers,
    growthRate,
  });

  const rows: MiniTableRow[] = [
    {
      label: isIndividual ? 'Current value' : 'Current entity value',
      value: formatCurrencyNoDecimals(currentValue),
    },
    {
      label: 'Total net transfers',
      value: formatCurrencyNoDecimalsAccounting(totalNormalizedTransfers),
      valueProps: totalNormalizedTransfers.isNegative()
        ? { sx: { color: COLORS.MATH.NEGATIVE } }
        : undefined,
    },
    {
      label: `Value after transfers (${finalYear})`,
      value: formatCurrencyNoDecimalsAccounting(netResult),
      valueProps: netResult.isNegative()
        ? { sx: { color: COLORS.MATH.NEGATIVE } }
        : undefined,
    },
  ];
  return (
    <Card variant="filled" sx={{ p: 2 }}>
      <MiniTable rows={rows} variant="tally" />
    </Card>
  );
}
