// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IrsConstantsQueryVariables = Types.Exact<{
  at?: Types.InputMaybe<Types.Scalars['Time']['input']>;
}>;


export type IrsConstantsQuery = { __typename?: 'Query', irsConstants: { __typename?: 'IRSConstants', rate7520: Percent, lifetimeExemptionAmount: CurrencyUSD, gstExemptionAmount: CurrencyUSD, annualGiftTaxExclusionAmount: CurrencyUSD, federalEstateTaxPercent: Percent, federalLongTermCapitalGrainsTaxPercent: Percent, federalOrdinaryIncomeTaxPercentMaxBracket: Percent, availableStateEstateTaxes: Array<Types.StateCode>, localFederalEstateTaxPercentFormatted?: string | null } };


export const IrsConstantsDocument = gql`
    query IRSConstants($at: Time) {
  irsConstants(at: $at) {
    rate7520
    lifetimeExemptionAmount
    gstExemptionAmount
    annualGiftTaxExclusionAmount
    federalEstateTaxPercent
    federalLongTermCapitalGrainsTaxPercent
    federalOrdinaryIncomeTaxPercentMaxBracket
    availableStateEstateTaxes
    localFederalEstateTaxPercentFormatted @client
  }
}
    `;

/**
 * __useIrsConstantsQuery__
 *
 * To run a query within a React component, call `useIrsConstantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIrsConstantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIrsConstantsQuery({
 *   variables: {
 *      at: // value for 'at'
 *   },
 * });
 */
export function useIrsConstantsQuery(baseOptions?: Apollo.QueryHookOptions<IrsConstantsQuery, IrsConstantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IrsConstantsQuery, IrsConstantsQueryVariables>(IrsConstantsDocument, options);
      }
export function useIrsConstantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IrsConstantsQuery, IrsConstantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IrsConstantsQuery, IrsConstantsQueryVariables>(IrsConstantsDocument, options);
        }
export function useIrsConstantsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IrsConstantsQuery, IrsConstantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IrsConstantsQuery, IrsConstantsQueryVariables>(IrsConstantsDocument, options);
        }
export type IrsConstantsQueryHookResult = ReturnType<typeof useIrsConstantsQuery>;
export type IrsConstantsLazyQueryHookResult = ReturnType<typeof useIrsConstantsLazyQuery>;
export type IrsConstantsSuspenseQueryHookResult = ReturnType<typeof useIrsConstantsSuspenseQuery>;
export type IrsConstantsQueryResult = Apollo.QueryResult<IrsConstantsQuery, IrsConstantsQueryVariables>;
export const Operations = {
  Query: {
    IRSConstants: 'IRSConstants' as const
  }
}