import { BaseMutationOptions } from '@apollo/client';

import { useCreateCsvFileMutation } from './graphql/CreateFile.generated';

export function useGenerateSignedCSVFileUploadURL() {
  const [uploadFile] = useCreateCsvFileMutation();

  async function generateUploadURL(
    hash: string,
    mutationOptions: Partial<BaseMutationOptions>
  ) {
    const queryProps = await uploadFile({
      ...mutationOptions,
      variables: {
        input: {
          // we check the file integrity S3 side.
          contentsSha256: hash,
        },
      },
    });

    // finally, take the signed upload URL and upload the file to it
    const signedUploadURL = queryProps.data?.uploadFile?.uploadURL;
    if (!signedUploadURL) {
      throw new Error('No errors, but no signed upload URL');
    }

    return { signedUploadURL, ...queryProps };
  }

  return { generateUploadURL };
}
