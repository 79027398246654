import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React, { ComponentProps, useMemo, useState } from 'react';
import { useLocation, useResolvedPath } from 'react-router-dom';

import { UnstyledNavLink } from '@/components/navigation/UnstyledNavLink';
import { WarningIndicator } from '@/components/notifications/WarningIndicator/WarningIndicator';
import { COLORS } from '@/styles/tokens/colors';
import { zIndexes } from '@/styles/zIndexes';

import { SIDEBAR_ANIMATION_TIMING_MS } from './sidebarConstants';

type SidebarMenuItemVariant = 'small' | 'large';

export interface SidebarMenuItemProps {
  text: string;
  icon: React.ReactElement;
  to?: string;
  // if the link is to an external route, we don't want to use the NavLink component.
  external?: boolean;
  variant: SidebarMenuItemVariant;
  onClick?: () => void;
  typographyProps?: ComponentProps<typeof Typography>;
  colorTheme?: 'light' | 'dark';
  highlightWhenActive?: boolean;
  menuItemSxProps?: ComponentProps<typeof MenuItem>['sx'];
  isActive?: boolean;
  showWarningBadge?: boolean;
}

function LinkComponent({
  external,
  to,
  onClick,
  children,
}: React.PropsWithChildren<{
  to?: string;
  external?: boolean;
  onClick?: () => void;
}>) {
  if (!to) {
    return <>{children}</>;
  }

  if (external) {
    return (
      <a
        href={to}
        rel="noreferrer"
        target="_blank"
        style={{ textDecoration: 'none' }}
      >
        {children}
      </a>
    );
  } else if (onClick) {
    return (
      <a style={{ textDecoration: 'none' }} onClick={onClick}>
        {children}
      </a>
    );
  }

  return <UnstyledNavLink to={to}>{children}</UnstyledNavLink>;
}

function getMenuItemStyling(opts: {
  isMenuItemActive: boolean;
  isHovered: boolean;
  colorTheme: 'light' | 'dark';
  highlightWhenActive?: boolean;
}) {
  let background = undefined;

  if (opts.highlightWhenActive && opts.isMenuItemActive) {
    if (opts.colorTheme === 'dark') {
      background = COLORS.NAVY[500];
    } else {
      background = COLORS.FUNCTIONAL.HOVER;
    }
  }

  if (opts.colorTheme === 'dark') {
    if (opts.isHovered) {
      background = COLORS.NAVY[500];
    }

    return { background, content: COLORS.PRIMITIVES.WHITE };
  }

  if (opts.isHovered) {
    background = COLORS.FUNCTIONAL.HOVER;
  }

  return {
    background,
    content: COLORS.GRAY[500],
  };
}

export function SidebarMenuItem({
  text,
  icon,
  to,
  external,
  variant,
  onClick,
  typographyProps,
  colorTheme = 'dark',
  highlightWhenActive = true,
  menuItemSxProps,
  isActive,
  showWarningBadge,
}: SidebarMenuItemProps) {
  const path = useResolvedPath(to ?? '');
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  // i want to style the MenuItem component based on whether or not its active, rather than styling
  // the react-router NavLink component, so i have to derive this here. i use the same heuristic that
  // react-router uses for NavLinks: https://github.com/remix-run/react-router/blob/f3d87dcc91fbd6fd646064b88b4be52c15114603/packages/react-router-dom/index.tsx#L346
  // but loosened because we actually want it to also match every subpath of the top-level route
  // so this should return true for if the location.pathname is /households/<householdId>/whatever
  const isMenuItemActive = useMemo(() => {
    if (isActive === undefined) {
      return location.pathname.startsWith(path.pathname);
    }

    return isActive;
  }, [isActive, location.pathname, path.pathname]);
  const { background, content } = getMenuItemStyling({
    isMenuItemActive,
    isHovered,
    colorTheme,
    highlightWhenActive,
  });

  return (
    <Box sx={{ overflow: 'hidden', position: 'relative' }}>
      {showWarningBadge && (
        <Box
          sx={{
            zIndex: zIndexes.TOOLTIP,
            position: 'absolute',
            top: 0,
            right: 0,
          }}
        >
          <WarningIndicator size={16} />
        </Box>
      )}
      <LinkComponent to={to} external={external} onClick={onClick}>
        <MenuItem
          sx={{
            '&:hover': {
              backgroundColor: 'transparent', // Remove default hover effect
            },

            color: content,
            padding: '8px',
            borderRadius: 2,
            cursor: to ? 'pointer' : 'default',
            ...menuItemSxProps,
          }}
          style={{
            backgroundColor: background,
          }}
          selected={false}
          onMouseOver={() => setIsHovered(true)}
          onMouseOut={() => setIsHovered(false)}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {icon}
            <Fade
              unmountOnExit={true}
              appear={false}
              timeout={SIDEBAR_ANIMATION_TIMING_MS}
              in={variant === 'large'}
            >
              <Typography
                color={content}
                variant="body2"
                ml={1}
                {...typographyProps}
              >
                {text}
              </Typography>
            </Fade>
          </Box>
        </MenuItem>
      </LinkComponent>
    </Box>
  );
}
