import { Stack, Typography } from '@mui/material';
import { JSXElementConstructor, useCallback } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { useModalState } from '@/hooks/useModalState';
import { DOCUMENT_DETAILS_VIEW_BUTTON_OPTIONS } from '@/modules/documents/documents.constants';
import { EditSummaryTrowser } from '@/modules/documents/EditSummaryTrowser';
import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';

export interface DocumentSummaryHeaderSlots<
  ContextMenuProps = Record<string, unknown>,
> {
  ContextMenu?: JSXElementConstructor<ContextMenuProps>;
  ContextMenuProps?: ContextMenuProps;
}

interface DocumentSummaryHeaderProps<
  ContextMenuProps = Record<string, unknown>,
> {
  documentId: string;
  documentSrc: string;
  documentSummary?: TrustSummaryData['documentSummary'];
  activeTab: string;
  hasAdditionalEntities?: boolean;
  setActiveTab: (value: string) => void;
  slots?: DocumentSummaryHeaderSlots<ContextMenuProps>;
}

export function DocumentSummaryHeader<
  ContextMenuProps = Record<string, unknown>,
>({
  documentId,
  documentSrc,
  documentSummary,
  activeTab,
  setActiveTab,
  hasAdditionalEntities,
  slots,
}: DocumentSummaryHeaderProps<ContextMenuProps>) {
  const [
    { isModalOpen: isEditSummaryTrowserOpen },
    { openModal: openEditSummaryTrowser, closeModal: closeEditSummaryTrowser },
  ] = useModalState();

  const handleEditSummary = useCallback(() => {
    openEditSummaryTrowser();
  }, [openEditSummaryTrowser]);

  if (!documentSummary) return <Typography variant="h2">Summary</Typography>;

  return (
    <>
      {/* We need to reset the state of the form in the trowser upon open because the editor and form
      both maintain their own state and if they differ the trowser content and page content might differ */}
      {isEditSummaryTrowserOpen && (
        <EditSummaryTrowser
          isOpen={isEditSummaryTrowserOpen}
          onClose={closeEditSummaryTrowser}
          documentId={documentId}
          documentSrc={documentSrc}
          documentSummary={documentSummary}
          hasAdditionalEntities={hasAdditionalEntities}
        />
      )}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <ButtonGroup
          label=""
          variant="dark"
          value={activeTab}
          options={DOCUMENT_DETAILS_VIEW_BUTTON_OPTIONS}
          onChange={(_, value) => setActiveTab(value)}
          sx={{
            width: 258,
          }}
        />
        <Stack direction="row" spacing={2}>
          <Button variant="secondary" size="sm" onClick={handleEditSummary}>
            Edit Summary
          </Button>
          {slots?.ContextMenu && slots.ContextMenuProps && (
            <slots.ContextMenu {...slots.ContextMenuProps} />
          )}
        </Stack>
      </Stack>
    </>
  );
}
