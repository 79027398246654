// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { FileFragmentDoc } from '../../../../../modules/files/graphql/FileFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCurrentHouseholdAccountsMappingMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCurrentHouseholdAccountsMappingMutation = { __typename?: 'Mutation', exportHouseholdAccounts: { __typename?: 'File', id: string, createdAt: Time, user: { __typename?: 'User', displayName: string, id: string }, download: { __typename?: 'FileDownload', downloadURL: string } } };

export type UpdateHouseholdAccountsMappingMutationVariables = Types.Exact<{
  fileId: Types.Scalars['ID']['input'];
}>;


export type UpdateHouseholdAccountsMappingMutation = { __typename?: 'Mutation', importEntityAccountAssociations?: { __typename?: 'CSVAccountImportResponse', numEntitiesUpdated: number } | null };


export const GetCurrentHouseholdAccountsMappingDocument = gql`
    mutation GetCurrentHouseholdAccountsMapping {
  exportHouseholdAccounts {
    ...File
  }
}
    ${FileFragmentDoc}`;
export type GetCurrentHouseholdAccountsMappingMutationFn = Apollo.MutationFunction<GetCurrentHouseholdAccountsMappingMutation, GetCurrentHouseholdAccountsMappingMutationVariables>;

/**
 * __useGetCurrentHouseholdAccountsMappingMutation__
 *
 * To run a mutation, you first call `useGetCurrentHouseholdAccountsMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentHouseholdAccountsMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCurrentHouseholdAccountsMappingMutation, { data, loading, error }] = useGetCurrentHouseholdAccountsMappingMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentHouseholdAccountsMappingMutation(baseOptions?: Apollo.MutationHookOptions<GetCurrentHouseholdAccountsMappingMutation, GetCurrentHouseholdAccountsMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetCurrentHouseholdAccountsMappingMutation, GetCurrentHouseholdAccountsMappingMutationVariables>(GetCurrentHouseholdAccountsMappingDocument, options);
      }
export type GetCurrentHouseholdAccountsMappingMutationHookResult = ReturnType<typeof useGetCurrentHouseholdAccountsMappingMutation>;
export type GetCurrentHouseholdAccountsMappingMutationResult = Apollo.MutationResult<GetCurrentHouseholdAccountsMappingMutation>;
export type GetCurrentHouseholdAccountsMappingMutationOptions = Apollo.BaseMutationOptions<GetCurrentHouseholdAccountsMappingMutation, GetCurrentHouseholdAccountsMappingMutationVariables>;
export const UpdateHouseholdAccountsMappingDocument = gql`
    mutation UpdateHouseholdAccountsMapping($fileId: ID!) {
  importEntityAccountAssociations(fileID: $fileId) {
    numEntitiesUpdated
  }
}
    `;
export type UpdateHouseholdAccountsMappingMutationFn = Apollo.MutationFunction<UpdateHouseholdAccountsMappingMutation, UpdateHouseholdAccountsMappingMutationVariables>;

/**
 * __useUpdateHouseholdAccountsMappingMutation__
 *
 * To run a mutation, you first call `useUpdateHouseholdAccountsMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHouseholdAccountsMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHouseholdAccountsMappingMutation, { data, loading, error }] = useUpdateHouseholdAccountsMappingMutation({
 *   variables: {
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useUpdateHouseholdAccountsMappingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHouseholdAccountsMappingMutation, UpdateHouseholdAccountsMappingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHouseholdAccountsMappingMutation, UpdateHouseholdAccountsMappingMutationVariables>(UpdateHouseholdAccountsMappingDocument, options);
      }
export type UpdateHouseholdAccountsMappingMutationHookResult = ReturnType<typeof useUpdateHouseholdAccountsMappingMutation>;
export type UpdateHouseholdAccountsMappingMutationResult = Apollo.MutationResult<UpdateHouseholdAccountsMappingMutation>;
export type UpdateHouseholdAccountsMappingMutationOptions = Apollo.BaseMutationOptions<UpdateHouseholdAccountsMappingMutation, UpdateHouseholdAccountsMappingMutationVariables>;
export const Operations = {
  Mutation: {
    GetCurrentHouseholdAccountsMapping: 'GetCurrentHouseholdAccountsMapping' as const,
    UpdateHouseholdAccountsMapping: 'UpdateHouseholdAccountsMapping' as const
  }
}