import { getUpdateWaterfallVariables } from '../EstateWaterfall.utils';
import { EditSaveAction, EstateWaterfallActionFn } from '../types';

export const editSaveAction: EstateWaterfallActionFn<EditSaveAction> = (
  state,
  _action,
  queueEffect
) => {
  queueEffect({ type: 'editMutationEffect' });

  const vars = getUpdateWaterfallVariables(state.waterfall, state.nodes);
  const nodeConfigurations = vars.input.update.nodeConfigurations ?? [];

  return {
    ...state,
    waterfall: {
      ...state.waterfall,
      nodeConfigurations,
    },
  };
};
