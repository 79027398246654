import { Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { TypeaheadSelectInput } from '@/components/form/baseInputs/TypeaheadSelectInput/TypeaheadSelectInput';
import {
  DialogModal,
  DialogModalProps,
} from '@/components/modals/DialogModal/DialogModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';

import { useRemapAndDeleteCategoryMutation } from './graphql/AssetCategoryDeletionModal.generated';
import { useAssetCategoryOptions } from './hooks/useAssetCategoryOptions';

export interface AssetCategoryDeletionModalProps
  extends Omit<DialogModalProps, 'heading' | 'onClose'> {
  deletedAssetCategoryId: string;
  onClose(didDelete: boolean): void;
}

export function AssetCategoryDeletionModal({
  onClose,
  deletedAssetCategoryId,
  ...modalProps
}: AssetCategoryDeletionModalProps) {
  const { showFeedback } = useFeedback();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedRemapToAssetCategoryId, setSelectedRemapToAssetCategoryId] =
    useState<string | null>(null);
  const { options, data } = useAssetCategoryOptions(deletedAssetCategoryId);
  const [remapAndDeleteCategory] = useRemapAndDeleteCategoryMutation({
    onCompleted: () => {
      showFeedback('Successfully recategorized and deleted asset category', {
        variant: 'success',
      });
      onClose(true);
    },
    onError: () => {
      showFeedback('Failed to recategorize and delete asset category');
    },
    update: (cache) => {
      const normalizedId = cache.identify({
        id: deletedAssetCategoryId,
        __typename: 'AssetClass',
      });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  function handleAssetCategoryDeletion() {
    if (!data) return;
    if (!selectedRemapToAssetCategoryId) {
      setErrorMessage('Please select a new category to recategorize to');
      return;
    }

    void remapAndDeleteCategory({
      variables: {
        categoryToDeleteID: deletedAssetCategoryId,
        categoryToRemapID: selectedRemapToAssetCategoryId,
      },
    });
  }

  function handleChange(value: string | null) {
    setErrorMessage(null);
    setSelectedRemapToAssetCategoryId(value);
  }

  return (
    <DialogModal
      heading="Recategorize existing assets before deleting"
      onClose={() => onClose(false)}
      {...modalProps}
    >
      <Stack spacing={2}>
        <Typography variant="body1">
          Assets associated with the category you wish to delete will be
          recategorized to the following:
        </Typography>
        <TypeaheadSelectInput<string>
          placeholder="Select a category"
          value={selectedRemapToAssetCategoryId ?? ''}
          onChange={(_e, value) => handleChange(value)}
          options={options}
          label="Category to recategorize existing assets to"
          hideLabel
          errorMessage={errorMessage ?? undefined}
        />

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            variant="transparent"
            size="sm"
            onClick={() => onClose(false)}
          >
            Cancel
          </Button>
          <Button
            variant="destructive-prominent"
            size="sm"
            onClick={handleAssetCategoryDeletion}
          >
            Delete category and recategorize assets
          </Button>
        </Stack>
      </Stack>
    </DialogModal>
  );
}
