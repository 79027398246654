import { Link, Typography } from '@mui/material';
import { useContext } from 'react';

import { LinkButton } from '@/components/form/baseInputs/Link';

import { ContextualHelpId } from '../types';
import { ContextualHelpContext } from './ContextualHelpContext';
import { HelpIconButton } from './HelpIconButton';

export interface ContextualHelpTriggerProps {
  display?: string;
  contextualHelpId: ContextualHelpId;
  variant?: 'default' | 'helpText';
}

export function ContextualHelpTrigger({
  display,
  contextualHelpId,
  variant = 'default',
}: ContextualHelpTriggerProps) {
  const contextualHelpCtx = useContext(ContextualHelpContext);
  if (!contextualHelpCtx) {
    throw new Error(
      'ContextualHelpDrawer must be used inside a ContextualHelpProvider.'
    );
  }

  const handleClick = () =>
    contextualHelpCtx.setContextualHelpId(contextualHelpId);

  if (!display) {
    return (
      <HelpIconButton
        buttonProps={{
          onClick: (e) => {
            e.stopPropagation();
            handleClick();
          },
        }}
      />
    );
  }

  if (variant === 'helpText') {
    return (
      <Typography variant="subtitle2">
        <Link
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
          sx={{ cursor: 'pointer' }}
        >
          {display}
        </Link>
      </Typography>
    );
  }
  return <LinkButton display={display} onClick={handleClick} />;
}
