import { ComposeStateFn } from '@/utils/reducerUtils';

import { FamilyTreeQuery } from '../graphql/FamilyTree.generated';
import { FamilyTreeState } from '../types';

export function loadFamilyTreeQuery(
  query: FamilyTreeQuery
): ComposeStateFn<FamilyTreeState> {
  return (state) => {
    return { ...state, familyTree: query, isInitializingData: false };
  };
}
