import { NavigationEntityFragment } from '@/modules/entities/graphql/Entities.generated';
import { getStageAwareLinkForEntity } from '@/modules/entities/utils/entitiesNavigationUtils';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { UnreachableError } from '@/utils/errors';

import {
  RecentClientFragment,
  RecentProposalFragment,
} from './graphql/RecentEntitiesSidebar.generated';

export type RecentEntity =
  | RecentProposalFragment
  | RecentClientFragment
  | NavigationEntityFragment;

export function getLinkForRecentEntity(entity: RecentEntity) {
  switch (entity.__typename) {
    case 'Household':
      return getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
        {
          householdId: entity.id,
        }
      );

    case 'Proposal': {
      const isGiftProposal = !!entity.giftingProposal;

      if (isGiftProposal) {
        return getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER, {
          householdId: entity.household.id,
          proposalId: entity.id,
        });
      }
      if (entity.crtProposal) {
        return getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION,
          {
            householdId: entity.household.id,
            proposalId: entity.id,
            variant: 'crt',
          }
        );
      } else if (entity.cltProposal) {
        return getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION,
          {
            householdId: entity.household.id,
            proposalId: entity.id,
            variant: 'clt',
          }
        );
      }

      return getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_PROPOSAL_EDIT, {
        proposalId: entity.id,
        householdId: entity.household.id,
      });
    }

    case 'Entity':
      return getStageAwareLinkForEntity(entity);

    default:
      throw new UnreachableError({
        case: entity as never,
        message: 'Unknown entity',
      });
  }
}
