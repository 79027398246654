import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { Edit02Icon } from '@/components/icons/Edit02Icon';

export type EditButtonProps = Partial<ButtonProps>;
export const EditButton = ({
  size = 'sm',
  variant = 'secondary',
  startIcon = Edit02Icon,
  ...props
}: EditButtonProps) => {
  return (
    <Button size={size} variant={variant} startIcon={startIcon} {...props} />
  );
};
