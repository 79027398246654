/* eslint-disable react/display-name */
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';

import {
  ChevronRightIcon,
  IconProps,
} from '@/components/icons/ChevronRightIcon';
import { COLORS } from '@/styles/tokens/colors';

import { CellContainer } from '../../components/cells';
import { CellRenderProp } from '../../types';
import { getCellRenderProp } from '../../utils/getRenderProp';

type IconType = typeof ChevronRightIcon;

interface IconRendererProps<
  R extends GridValidRowModel,
  V,
  F,
  N extends GridTreeNodeWithRender,
> {
  icon: CellRenderProp<R, V, F, N, IconType>;
  iconProps?: CellRenderProp<R, V, F, N, Partial<IconProps>>;
}

export const IconRenderer = <
  R extends GridValidRowModel = GridValidRowModel,
  V extends IconType = IconType,
  F = V,
  N extends GridTreeNodeWithRender = GridTreeNodeWithRender,
>(
  factoryProps?: IconRendererProps<R, V, F, N>
) => {
  return (props: GridRenderCellParams<R, V, F, N>) => {
    const Icon = getCellRenderProp({
      props,
      prop: factoryProps?.icon,
      defaultValue: props.value!,
    });

    const iconProps = getCellRenderProp({
      props,
      prop: factoryProps?.iconProps,
      defaultValue: {
        sx: { color: COLORS.GRAY[500] },
      },
    });

    return (
      <CellContainer align={props.colDef.align}>
        <Icon size={20} {...iconProps} />
      </CellContainer>
    );
  };
};
