import { Box } from '@mui/material';
import { compact } from 'lodash';
import { useEffect } from 'react';

import { CalloutListCard } from '@/components/layout/CalloutListCard/CalloutListCard';
import {
  PersonalAccountDetailsTabs,
  TrustDetailsTabs,
} from '@/modules/entities/details/entityDetails.types';
import { FeatureFlagMap } from '@/modules/featureFlags/featureFlags.types';
import {
  SummaryContainer,
  SummaryWrapper,
} from '@/modules/trusts/TrustDetails/TrustDetails.utils';

import { PersonalAccountSummaryData } from './PersonalAccountDetails.types';
import { PersonalAccountSummary } from './PersonalAccountSummary';

export function PersonalAccountDetailsSummary(
  props: PersonalAccountSummaryData
) {
  const { loading, accountDetailsItems, onShowEditEntity, ...rest } = props;

  useEffect(() => {
    onShowEditEntity?.();
  }, [onShowEditEntity]);

  return (
    <SummaryWrapper>
      <SummaryContainer>
        <Box flexGrow={1} mt={2}>
          <PersonalAccountSummary loading={!!loading} {...rest} />
        </Box>
        <Box>
          <CalloutListCard loading={!!loading} items={accountDetailsItems} />
        </Box>
      </SummaryContainer>
    </SummaryWrapper>
  );
}

export function getPersonalAccountDetailsTabs(
  props: PersonalAccountSummaryData,
  _featureFlags: FeatureFlagMap
) {
  const Summary = {
    display: 'Summary',
    value: PersonalAccountDetailsTabs.SUMMARY,
    props,
  };

  const Beneficiaries = {
    display: 'Beneficiaries',
    value: PersonalAccountDetailsTabs.BENEFICIARIES,
    props,
  };

  const Dispositions = {
    display: 'Dispositions',
    value: PersonalAccountDetailsTabs.DISPOSITIONS,
    props,
  };

  return compact([Summary, props.beneficiaries && Beneficiaries, Dispositions]);
}

export function getInsuranceAccountDetailsTabs(
  props: PersonalAccountSummaryData,
  _featureFlags: FeatureFlagMap = {}
) {
  const Summary = {
    display: 'Summary',
    value: PersonalAccountDetailsTabs.SUMMARY,
    props,
  };

  const Policies = {
    display: 'Policies',
    value: TrustDetailsTabs.POLICIES,
    props,
  };

  const Dispositions = {
    display: 'Dispositions',
    value: PersonalAccountDetailsTabs.DISPOSITIONS,
    props,
  };

  return compact([Summary, Policies, Dispositions]);
}
