import { Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { DataTable_LEGACYProps } from '@/components/tables/DataTable_LEGACY/DataTable_LEGACY';
import { ControlledFilterableTable } from '@/components/tables/FilterableTable/ControlledFilterableTable';
import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { GiftDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { OrderDirection, ProposalOrderField } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import { ProposalKind } from '../../../modules/proposal/types/ProposalKind';
import { useListClientProposalsQuery } from '../graphql/ListClientProposals.generated';
import { ModelProposalButton } from './ModelProposalButton';
import { columns } from './types';
import { mapProposalsToRows } from './utils';

interface Props {
  rows: ReturnType<typeof mapProposalsToRows>;
  loading: boolean;
  householdId: string;
}

function ClientDetailsProposalsPageInner({
  rows,
  loading,
  householdId,
}: Props) {
  const navigate = useNavigate();
  const onRowClick: DataTable_LEGACYProps['onRowClick'] = (params, event) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const kind = params.row.kind as ProposalKind;

    const proposalId = params.id;

    let path: string | null = null;

    if (kind === ProposalKind.Entity) {
      path = getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_PROPOSAL_EDIT, {
        householdId,
        proposalId: proposalId.toString(),
      });
    }

    if (kind === ProposalKind.Gift) {
      path = getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER, {
        householdId,
        proposalId: proposalId.toString(),
        designerStage: GiftDesignerStages.BASIC_INFORMATION,
      });
    }

    if (kind === ProposalKind.CRT || kind === ProposalKind.CLT) {
      path = getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION,
        {
          householdId,
          proposalId: proposalId.toString(),
          variant: kind,
        }
      );
    }

    if (!path) {
      throw new UnreachableError({
        case: kind as never,
        message: `Unrecognized proposal type in list view`,
      });
    }

    if (event.metaKey) {
      // if the user cmd-clicks the row, open it in a new window
      window.open(path, '_blank');
      return;
    }

    navigate(path, {
      state: { from: ROUTE_KEYS.HOUSEHOLD_DETAILS_PROPOSALS },
    });
  };

  return (
    <ControlledFilterableTable
      tabs={[]}
      rows={rows}
      columns={columns}
      filterField="status"
      title="Proposals"
      emptyStateText="No proposals"
      actions={
        <Stack direction="row" alignItems="center">
          <ModelProposalButton />
        </Stack>
      }
      emptyStateContent={<ModelProposalButton />}
      loading={loading}
      onRowClick={onRowClick}
    />
  );
}

export function ClientDetailsProposalsPage() {
  const householdId = useRequiredParam('householdId');
  const { createErrorFeedback, showFeedback } = useFeedback();
  const { loading, error, data, refetch } = useListClientProposalsQuery({
    variables: {
      where: {
        hasHouseholdWith: [
          {
            id: householdId,
          },
        ],
      },
      orderBy: [
        {
          field: ProposalOrderField.UpdatedAt,
          direction: OrderDirection.Desc,
        },
      ],
    },
    fetchPolicy: 'cache-and-network',
    onError: createErrorFeedback(
      "We weren't able to load your proposals. Please refresh the page and try again."
    ),
    errorPolicy: 'all',
  });

  useReportError('failed to load data for proposals page', error, {
    householdId,
  });

  const rows = useMemo(() => {
    if (data) {
      try {
        return mapProposalsToRows(getNodes(data.proposals), { refetch });
      } catch (err) {
        showFeedback('An error occurred. Please refresh the page.');
        diagnostics.error(
          'failed to parse data for proposals page',
          err as Error,
          {
            householdId,
          }
        );
        return [];
      }
    } else {
      return [];
    }
  }, [data, refetch, showFeedback, householdId]);

  return (
    <ClientDetailsProposalsPageInner
      rows={rows}
      // only show the loading indicator on the first load
      loading={loading && rows.length === 0}
      householdId={householdId}
    />
  );
}
