import { useShowWaterfallSummaryTileDetailsQuery } from '../graphql/ShowWaterfallSummaryTileDetails.generated';

export function useShowSummaryTileDetails(waterfallId: string): boolean {
  const { data } = useShowWaterfallSummaryTileDetailsQuery({
    variables: { waterfallSummaryTileDetailsWhereInput: { waterfallId } },
  });

  const waterfall = data?.estateWaterfalls?.edges?.find(
    (edge) => edge?.node?.id === waterfallId
  )?.node;

  return waterfall?.localShowSummaryTileDetails ?? false;
}
