import { DesignerLayoutGrid } from '@/components/layout/DesignerLayoutGrid/DesignerLayoutGrid';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';

import { SlideBodyWithHeader } from '../SlideBodyWithHeader';
import {
  CharitableOverviewLeftColumn,
  CharitableOverviewRightColumn,
} from './CharitableOverview.components';

export interface CharitableOverviewProps {
  proposal: ProposalFragment;
}

export function CharitableOverview({ proposal }: CharitableOverviewProps) {
  return (
    <SlideBodyWithHeader header="Analysis overview">
      <DesignerLayoutGrid
        spacing={0}
        LeftColumn={<CharitableOverviewLeftColumn proposal={proposal} />}
        RightColumn={<CharitableOverviewRightColumn proposal={proposal} />}
      />
    </SlideBodyWithHeader>
  );
}
