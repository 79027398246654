import { getYear } from 'date-fns';
import Decimal from 'decimal.js';

import {
  CharitableTrustCalculationMethod,
  CharitableTrustOptimizationTarget,
  CharitableTrustPayoutFrequency,
  CharitableTrustPayoutKind,
  CharitableTrustTermKind,
  CharitableTrustUnitrustKind,
} from '@/types/schema';

import { DEFAULT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION_FORM } from '../CharitableTrustDesignerBasicInformation/CharitableTrustDesignerBasicInformation.constants';
import { DEFAULT_PRE_TAX_RETURN_LIMIT_MODAL_FORM } from '../PreTaxReturnLimitModal/PreTaxReturnLimitModal.constants';
import {
  CharitableTrustDesignerAnalysisForm,
  NAMESPACE,
} from './CharitableTrustDesignerAnalysis.types';

export const DEFAULT_CHARITABLE_TRUST_DESIGNER_ANALYSIS_FORM: CharitableTrustDesignerAnalysisForm =
  {
    [NAMESPACE]: {
      assets: {
        value: new Decimal(0),
        costBasis: new Decimal(0),
        notes: '',
      },
      term: {
        kind: CharitableTrustTermKind.Fixed,
        donors: [],
        length: 10, // default to 10
        rate7520: null,
      },
      payouts: {
        kind: CharitableTrustPayoutKind.Annuity,
        unitrustKind: CharitableTrustUnitrustKind.Standard,
        calculationMethod: CharitableTrustCalculationMethod.Calculated,
        optimizationTarget: CharitableTrustOptimizationTarget.Charitable,
        frequency: CharitableTrustPayoutFrequency.Annually,
        annuityPaymentAmount: new Decimal(0),
        unitrustPayoutPercent: new Decimal(5),
      },
      analysis: {
        preTaxReturnModel: 'medium',
        yearOfAnalysis: getYear(new Date()),
      },
    },
    ...DEFAULT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION_FORM,
    ...DEFAULT_PRE_TAX_RETURN_LIMIT_MODAL_FORM,
  };
