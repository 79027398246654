import { Box, Stack, Typography } from '@mui/material';
import { uniq } from 'lodash';
import { useContext } from 'react';
import { useWatch } from 'react-hook-form';

import { RibbonCard } from '@/components/layout/RibbonCard';
import { useFormContext } from '@/components/react-hook-form';
import { RichDocumentUploaderWithList } from '@/modules/documents/RichDocumentUploaderWithList/RichDocumentUploaderWithList';

import { ILITTaskContext } from '../ILITTaskContext';
import { ILITMakeAnnualExclusionGiftsForm } from './ILITMakeAnnualExclusionGiftsTask.types';
import { ILITMakeAnnualGrantorGift } from './ILITMakeAnnualGrantorGift/ILITMakeAnnualGrantorGift';

export function ILITMakeAnnualExclusionGiftsTaskBody() {
  const { householdId, entityId } = useContext(ILITTaskContext);
  const { control, setValue } =
    useFormContext<ILITMakeAnnualExclusionGiftsForm>();

  const grantors = useWatch<ILITMakeAnnualExclusionGiftsForm>({
    name: 'grantors',
    control,
  }) as ILITMakeAnnualExclusionGiftsForm['grantors'];

  const documentIds = useWatch<ILITMakeAnnualExclusionGiftsForm>({
    name: 'documentIds',
    control,
  }) as ILITMakeAnnualExclusionGiftsForm['documentIds'];

  return (
    <Stack>
      <RibbonCard
        heading="Confirm gifted amounts"
        rightHeaderContent={
          <Typography variant="subtitle2">
            Source value after gift is optional
          </Typography>
        }
      >
        <Stack direction="column" spacing={6}>
          {Object.values(grantors)?.map((grantor, index) => (
            <ILITMakeAnnualGrantorGift
              grantor={grantor}
              key={`grantor-${index}`}
            />
          ))}
          <Box sx={{ py: 3, px: 10 }}>
            <RichDocumentUploaderWithList
              hideEntityPicker
              variant="card"
              cardVariant="filled"
              entityId={entityId}
              householdId={householdId}
              listDocumentsLike={{
                hasEntityWith: [{ id: entityId }],
              }}
              documentIds={documentIds}
              onDocumentUploadSuccess={(documentId) => {
                const uniqueDocumentIds = uniq([...documentIds, documentId]);
                setValue('documentIds', uniqueDocumentIds);
              }}
            />
          </Box>
        </Stack>
      </RibbonCard>
    </Stack>
  );
}
