import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { Maybe } from 'graphql/jsutils/Maybe';
import { compact } from 'lodash';
import { useMemo } from 'react';

import { SubtleLabelItem } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { DropdownButtonProps } from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { Card } from '@/components/layout/Card/Card';
import { GroupedHeaderList } from '@/components/lists/GroupedHeaderList/GroupedHeaderList';
import { TableCell, TableCellContext } from '@/components/lists/TableCell';
import { SidePanel } from '@/components/modals/SidePanel';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { DispositiveProvisionsListView } from '@/modules/dispositiveProvisions/DispositiveProvisionsListView/DispositiveProvisionsListView';
import { HypotheticalTransfersCard } from '@/modules/entities/details/HypotheticalTransfersCard/HypotheticalTransfersCard';
import {
  SummaryContainer,
  SummaryWrapper,
} from '@/modules/trusts/TrustDetails/TrustDetails.utils';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { getSummaryFields } from './ClientProfileSummaryPanel.utils';
import { ClientProfileSummaryPanel_ClientProfileFragment } from './graphql/ClientProfileSummaryPanel.generated';

export enum ClientProfileTabs {
  Summary = 'SUMMARY',
  Assets = 'ASSETS',
  Disposition = 'DISPOSITION',
  Transfers = 'TRANSFERS',
}

interface UseClientProfilePanelsInput {
  summaryFields: ReturnType<typeof getSummaryFields>;
  cp: Maybe<ClientProfileSummaryPanel_ClientProfileFragment>;
  currentNodeValue?: Decimal;
}

type ClientProfilePanel = DropdownButtonProps['items'][number] & {
  value: ClientProfileTabs;
};

export function useClientProfilePanels({
  summaryFields,
  cp,
  currentNodeValue,
}: UseClientProfilePanelsInput): ClientProfilePanel[] {
  const assets = useMemo(() => {
    return [
      {
        heading: 'Direct business ownership',
        items:
          cp?.ownedOwnershipStakes?.map((s) => ({
            id: s.id,
            heading: s.ownedEntity?.subtype.displayName ?? EMPTY_CONTENT_HYPHEN,
            value: formatCurrencyNoDecimals(s.ownedValue),
            percentage: s.ownershipPercentage
              ? `${formatPercent(s.ownershipPercentage)}%`
              : EMPTY_CONTENT_HYPHEN,
          })) ?? [],
      },
    ];
  }, [cp?.ownedOwnershipStakes]);

  return useMemo(() => {
    if (!cp) {
      return [];
    }

    return compact([
      {
        name: 'Summary',
        value: ClientProfileTabs.Summary,
        component: (
          <SummaryWrapper>
            <SummaryContainer>
              <Box flexGrow={1} mt={2}>
                <Stack spacing={2}>
                  <SubtleLabelItem
                    label="Full legal name"
                    value={summaryFields.legalName}
                  />
                  <SubtleLabelItem
                    label="Location"
                    value={summaryFields.location}
                  />
                  <SubtleLabelItem label="Email" value={summaryFields.email} />
                  <Card variant="filled" sx={{ p: 2 }}>
                    <SidePanel.Section>
                      <Typography variant="h4" component="h3" mb={1}>
                        Relationships to client(s)
                      </Typography>
                      {summaryFields.grantorRelationships?.map(
                        ({ name, type }) => (
                          <SubtleLabelItem
                            key={name}
                            label={name}
                            value={type}
                          />
                        )
                      )}
                    </SidePanel.Section>
                  </Card>
                </Stack>
              </Box>
            </SummaryContainer>
          </SummaryWrapper>
        ),
      },
      {
        name: 'Holdings',
        value: ClientProfileTabs.Assets,
        component: (
          <>
            <GroupedHeaderList<{
              id: string;
              heading: string;
              value: string;
              percentage: string;
            }>
              rowGroups={assets}
              ItemComponent={({ item }) => (
                <TableCell
                  heading={item.heading}
                  RightContent={
                    <Stack spacing={1}>
                      <TableCellContext
                        heading={item.value}
                        subheading={item.percentage}
                      />
                    </Stack>
                  }
                />
              )}
              withDividers
            />
          </>
        ),
      },
      {
        name: 'Dispositions',
        value: ClientProfileTabs.Disposition,
        component: (
          <DispositiveProvisionsListView currentNodeValue={currentNodeValue} />
        ),
      },
      {
        name: 'Transfers',
        value: ClientProfileTabs.Transfers,
        component: <HypotheticalTransfersCard individual={cp} />,
      },
    ]);
  }, [
    assets,
    cp,
    currentNodeValue,
    summaryFields.email,
    summaryFields.grantorRelationships,
    summaryFields.legalName,
    summaryFields.location,
  ]);
}
