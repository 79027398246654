import { Box, Stack } from '@mui/material';
import { FC } from 'react';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { SubtotalCard } from '@/components/display/SubtotalCard/SubtotalCard';
import { Gift01Icon } from '@/components/icons/Gift01Icon';
import { PopperContent } from '@/components/poppers/PopperContent';
import {
  MiniTable,
  MiniTableRow,
} from '@/components/tables/MiniTable/MiniTable';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { COLORS } from '@/styles/tokens/colors';
import { EntityKind } from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { EntityValuationHistoryPage_EntityFragment } from './graphql/GetEntityValuationHistory.generated';

export interface TotalCharitableValueProps {
  entity: EntityValuationHistoryPage_EntityFragment | undefined | null;
}

export const TotalCharitableValue: FC<TotalCharitableValueProps> = ({
  entity,
}) => {
  if (
    entity?.subtype?.__typename !== 'DonorAdvisedFund' &&
    entity?.subtype?.__typename !== 'PrivateFoundation'
  ) {
    return null;
  }

  const { charitableWealthTransferred, grantsToCharity } =
    entity.subtype.performanceReport;

  const totalCharitableValue = formatCurrencyNoDecimals(
    charitableWealthTransferred.add(grantsToCharity)
  );

  const tableRows: MiniTableRow[] = [
    {
      label: `Current value of ${
        entity.kind === EntityKind.PrivateFoundation ? 'foundation' : 'DAF'
      }`,
      value: formatCurrencyNoDecimals(charitableWealthTransferred),
    },
    {
      label: 'Total distributions to charity',
      value: formatCurrencyNoDecimals(grantsToCharity),
    },
    {
      label: 'Total charitable value',
      value: totalCharitableValue,
    },
  ];

  return (
    <SubtotalCard
      wrapperSx={{ flex: '1 1 100%' }}
      caption={
        <OversizedMetricItem
          title={
            <Stack direction="row">
              Total charitable value&nbsp;
              <ContextualHelpTooltip>
                <PopperContent body="Total charitable value is defined as the sum of the current value of the charitable entity plus total grants distributed to charity" />
              </ContextualHelpTooltip>
            </Stack>
          }
          value={totalCharitableValue}
        />
      }
      icon={<Gift01Icon />}
      iconColor={COLORS.CATEGORIES.CHARITABLE_GIVING[500]}
    >
      <Box sx={{ width: '30%' }}>
        <MiniTable rows={tableRows} variant="tally" />
      </Box>
    </SubtotalCard>
  );
};
