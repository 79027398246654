import { compact } from 'lodash';

import { SubtleLabelItemProps } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { EntityDetailsData } from '../entityDetails.types';
import { EntityDetail_InsurancePolicyFragment } from '../graphql/EntityDetailPage.generated';
import {
  getGrantorNameFromFragment,
  getSharedSummaryProperties,
} from './trustCardUtils';

export function getTotalDeathBenefitAmountLabelItem(
  policies?: EntityDetail_InsurancePolicyFragment[] | null
): SubtleLabelItemProps | undefined {
  if (!policies?.length) {
    return undefined;
  }

  const totalDeathBenefitAmount = sumDecimalJS(
    policies.map((p) => p.deathBenefitAmount)
  );

  return {
    label: 'Total death benefit amount',
    value: formatCurrencyNoDecimals(totalDeathBenefitAmount),
  };
}

export function getILITTrustSummaryProperties({
  entity,
}: EntityDetailsData): TrustSummaryData {
  if (entity.subtype.__typename !== 'ILITTrust') {
    throw new Error(`Invalid trust type ${entity.subtype.__typename}`);
  }

  const summary: TrustSummaryData = {
    ...getSharedSummaryProperties(entity),
    grantorNames:
      entity.subtype.grantors?.map(getGrantorNameFromFragment) || [],
    effectiveDate: entity.subtype.effectiveDate ?? undefined,
    beneficiariesGroups: [
      {
        heading: undefined,
        items: entity.subtype.beneficiaries || [],
        noItemsText: 'No beneficiaries',
      },
    ],
    trustDetailsItems: compact([
      getTotalDeathBenefitAmountLabelItem(entity.subtype.policies),
    ]),
    insurancePolicies: entity.subtype.policies,
  };

  return summary;
}
