// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { AsyncJobStatus_AsyncJobFragmentDoc } from '../../../../asyncJobs/graphql/AsyncJobs.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetIntegrationClientsMultipleQueryVariables = Types.Exact<{
  optionsLike?: Types.InputMaybe<Types.IntegrationClientWhereInput>;
  selectedOptionsLike?: Types.InputMaybe<Types.IntegrationClientWhereInput>;
  first: Types.Scalars['Int']['input'];
}>;


export type GetIntegrationClientsMultipleQuery = { __typename?: 'Query', queryData: { __typename?: 'IntegrationClientConnection', totalCount: number, edges?: Array<{ __typename?: 'IntegrationClientEdge', node?: { __typename?: 'IntegrationClient', id: string, integrationID: string, name: string, externalKind?: Types.IntegrationClientExternalKind | null } | null } | null> | null }, selectedData: { __typename?: 'IntegrationClientConnection', totalCount: number, edges?: Array<{ __typename?: 'IntegrationClientEdge', node?: { __typename?: 'IntegrationClient', id: string, integrationID: string, name: string } | null } | null> | null } };

export type InitialIntegrationSyncQueryVariables = Types.Exact<{
  pendingJobsLike: Types.AsyncJobWhereInput;
  completedJobsLike: Types.AsyncJobWhereInput;
}>;


export type InitialIntegrationSyncQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, pendingJobs: { __typename?: 'AsyncJobConnection', edges?: Array<{ __typename?: 'AsyncJobEdge', node?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null } | null> | null }, completedJobs: { __typename?: 'AsyncJobConnection', edges?: Array<{ __typename?: 'AsyncJobEdge', node?: { __typename?: 'AsyncJob', id: string, status: Types.AsyncJobStatus, kind: Types.AsyncJobKind, createdAt: Time, updatedAt: Time } | null } | null> | null } } | null };


export const GetIntegrationClientsMultipleDocument = gql`
    query GetIntegrationClientsMultiple($optionsLike: IntegrationClientWhereInput, $selectedOptionsLike: IntegrationClientWhereInput, $first: Int!) {
  queryData: integrationClients(where: $optionsLike, first: $first) {
    totalCount
    edges {
      node {
        id
        integrationID
        name
        externalKind
      }
    }
  }
  selectedData: integrationClients(where: $selectedOptionsLike) {
    totalCount
    edges {
      node {
        id
        integrationID
        name
      }
    }
  }
}
    `;

/**
 * __useGetIntegrationClientsMultipleQuery__
 *
 * To run a query within a React component, call `useGetIntegrationClientsMultipleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationClientsMultipleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationClientsMultipleQuery({
 *   variables: {
 *      optionsLike: // value for 'optionsLike'
 *      selectedOptionsLike: // value for 'selectedOptionsLike'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetIntegrationClientsMultipleQuery(baseOptions: Apollo.QueryHookOptions<GetIntegrationClientsMultipleQuery, GetIntegrationClientsMultipleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntegrationClientsMultipleQuery, GetIntegrationClientsMultipleQueryVariables>(GetIntegrationClientsMultipleDocument, options);
      }
export function useGetIntegrationClientsMultipleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntegrationClientsMultipleQuery, GetIntegrationClientsMultipleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntegrationClientsMultipleQuery, GetIntegrationClientsMultipleQueryVariables>(GetIntegrationClientsMultipleDocument, options);
        }
export function useGetIntegrationClientsMultipleSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIntegrationClientsMultipleQuery, GetIntegrationClientsMultipleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIntegrationClientsMultipleQuery, GetIntegrationClientsMultipleQueryVariables>(GetIntegrationClientsMultipleDocument, options);
        }
export type GetIntegrationClientsMultipleQueryHookResult = ReturnType<typeof useGetIntegrationClientsMultipleQuery>;
export type GetIntegrationClientsMultipleLazyQueryHookResult = ReturnType<typeof useGetIntegrationClientsMultipleLazyQuery>;
export type GetIntegrationClientsMultipleSuspenseQueryHookResult = ReturnType<typeof useGetIntegrationClientsMultipleSuspenseQuery>;
export type GetIntegrationClientsMultipleQueryResult = Apollo.QueryResult<GetIntegrationClientsMultipleQuery, GetIntegrationClientsMultipleQueryVariables>;
export const InitialIntegrationSyncDocument = gql`
    query InitialIntegrationSync($pendingJobsLike: AsyncJobWhereInput!, $completedJobsLike: AsyncJobWhereInput!) {
  currentUser {
    id
    pendingJobs: asyncJobs(
      where: $pendingJobsLike
      first: 1
      orderBy: {direction: DESC, field: created_at}
    ) {
      edges {
        node {
          ...AsyncJobStatus_AsyncJob
        }
      }
    }
    completedJobs: asyncJobs(
      where: $completedJobsLike
      first: 1
      orderBy: {direction: DESC, field: created_at}
    ) {
      edges {
        node {
          ...AsyncJobStatus_AsyncJob
        }
      }
    }
  }
}
    ${AsyncJobStatus_AsyncJobFragmentDoc}`;

/**
 * __useInitialIntegrationSyncQuery__
 *
 * To run a query within a React component, call `useInitialIntegrationSyncQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitialIntegrationSyncQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitialIntegrationSyncQuery({
 *   variables: {
 *      pendingJobsLike: // value for 'pendingJobsLike'
 *      completedJobsLike: // value for 'completedJobsLike'
 *   },
 * });
 */
export function useInitialIntegrationSyncQuery(baseOptions: Apollo.QueryHookOptions<InitialIntegrationSyncQuery, InitialIntegrationSyncQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitialIntegrationSyncQuery, InitialIntegrationSyncQueryVariables>(InitialIntegrationSyncDocument, options);
      }
export function useInitialIntegrationSyncLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitialIntegrationSyncQuery, InitialIntegrationSyncQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitialIntegrationSyncQuery, InitialIntegrationSyncQueryVariables>(InitialIntegrationSyncDocument, options);
        }
export function useInitialIntegrationSyncSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<InitialIntegrationSyncQuery, InitialIntegrationSyncQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InitialIntegrationSyncQuery, InitialIntegrationSyncQueryVariables>(InitialIntegrationSyncDocument, options);
        }
export type InitialIntegrationSyncQueryHookResult = ReturnType<typeof useInitialIntegrationSyncQuery>;
export type InitialIntegrationSyncLazyQueryHookResult = ReturnType<typeof useInitialIntegrationSyncLazyQuery>;
export type InitialIntegrationSyncSuspenseQueryHookResult = ReturnType<typeof useInitialIntegrationSyncSuspenseQuery>;
export type InitialIntegrationSyncQueryResult = Apollo.QueryResult<InitialIntegrationSyncQuery, InitialIntegrationSyncQueryVariables>;
export const Operations = {
  Query: {
    GetIntegrationClientsMultiple: 'GetIntegrationClientsMultiple' as const,
    InitialIntegrationSync: 'InitialIntegrationSync' as const
  }
}