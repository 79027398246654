import { useMemo } from 'react';

import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.constants';
import { AIOnboardingModalFormShape } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { testamentaryEntityKindToDisplayName } from '@/modules/entities/testamentaryEntities/testamentaryEntities.utils';
import { TestamentaryEntityKind } from '@/types/schema';
import { PathsOf } from '@/types/subform';

interface TestamentaryEntityFieldsRowProps {
  index: number;
}

export function TestamentaryEntityFieldsRow({
  index,
}: TestamentaryEntityFieldsRowProps) {
  const { control } = useFormContext<AIOnboardingModalFormShape>();

  const testamentaryEntityKindOptions = useMemo(() => {
    return Object.values(TestamentaryEntityKind).map((kind) => ({
      value: kind as TestamentaryEntityKind,
      display: testamentaryEntityKindToDisplayName(
        kind as TestamentaryEntityKind
      ),
    }));
  }, []);

  const { styles, columns } =
    AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS.testamentaryEntities;

  return (
    <>
      <FormLayoutRow sx={{ gap: styles.columnPaddingHorizontal }}>
        <FormLayoutItem width={columns.testamentaryEntityKind.columnWidth}>
          <FormAwareSelectInput<AIOnboardingModalFormShape>
            control={control}
            fieldName={
              `testamentaryEntities.${index}.testamentaryEntityKind` as const satisfies PathsOf<AIOnboardingModalFormShape>
            }
            label={columns.testamentaryEntityKind.columnHeader}
            options={testamentaryEntityKindOptions}
            hideLabel
            required
          />
        </FormLayoutItem>
        <FormLayoutItem width={columns.testamentaryEntityName.columnWidth}>
          <FormAwareTextInput<AIOnboardingModalFormShape>
            control={control}
            fieldName={
              `testamentaryEntities.${index}.testamentaryEntityName` as const satisfies PathsOf<AIOnboardingModalFormShape>
            }
            label={columns.testamentaryEntityName.columnHeader}
            hideLabel
            required
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </>
  );
}
