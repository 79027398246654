import { Box } from '@mui/material';
import { isEmpty } from 'lodash';

import { LinkBroken01Icon } from '@/components/icons/LinkBroken01Icon';
import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '@/components/modals/ConfirmationModal/ConfirmationModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  MenuItem,
  MenuItemProps,
} from '@/components/poppers/MenuPopper/MenuItem';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES } from '@/modules/assetProviderIntegrations/shared/constants';
import { AssetIntegration_EntityFragment } from '@/modules/assetProviderIntegrations/shared/graphql/assetProviderIntegration.fragments.generated';
import {
  getEntityAssetsIntegrationProvider,
  getIntegrationEntitiesFromEntity,
} from '@/modules/assetProviderIntegrations/shared/utils';
import { COLORS } from '@/styles/tokens/colors';

import { useUnlinkIntegrationEntityMutation } from './graphql/UnlinkFromIntegrationMenuItem.generated';

interface UnlinkFromIntegrationMenuItemProps extends Partial<MenuItemProps> {
  entity: AssetIntegration_EntityFragment | null;
  modalProps?: Partial<ConfirmationModalProps>;
}

export function UnlinkFromIntegrationMenuItem({
  entity,
  modalProps = {},
  ...menuItemProps
}: UnlinkFromIntegrationMenuItemProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [removeIntegrationEntity, { loading }] =
    useUnlinkIntegrationEntityMutation({
      onError: (error) => {
        reportError(`Failed to unlink entity`, error);
        showFeedback(
          'Failed to unlink this entity. Please refresh the page and try again.'
        );
      },
      onCompleted: () => {
        showFeedback('Entity unlinked successfully', { variant: 'success' });
      },
    });

  const handleModalClose: ConfirmationModalProps['onClose'] = async (
    result
  ) => {
    if (result === 'confirm') {
      const integrationEntities = getIntegrationEntitiesFromEntity(entity);

      if (!entity || isEmpty(integrationEntities)) {
        throw new Error(
          'Entity or integration entity not found when trying to unlink from integration.'
        );
      }

      const integrationEntityIds = integrationEntities.map((ie) => ie.id);
      await removeIntegrationEntity({
        variables: {
          entityId: entity.id,
          integrationEntityIds,
        },
      });
    }
  };

  const [{ isModalOpen }, { setModalIsOpen }] = useModalState();

  const integrationProviderName = getEntityAssetsIntegrationProvider(entity);
  if (!integrationProviderName) return null;
  const integrationProviderDisplayName =
    ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES[integrationProviderName];

  return (
    <>
      <ConfirmationModal
        isOpen={isModalOpen}
        heading={`Are you sure you wish to unlink from ${integrationProviderDisplayName}?`}
        cancelText="Cancel"
        confirmText="Proceed"
        confirmButtonProps={{ variant: 'destructive-prominent' }}
        {...modalProps}
        onClose={handleModalClose}
      >
        Existing valuations will be retained but no future valuations will be
        recorded.
      </ConfirmationModal>
      <MenuItem
        icon={<LinkBroken01Icon size={20} sx={{ color: COLORS.GRAY[500] }} />}
        label={
          <Box color={COLORS.NAVY[900]}>
            {`Unlink from ${integrationProviderDisplayName}`}
          </Box>
        }
        onClick={(event) => {
          event.stopPropagation();
          setModalIsOpen(true);
        }}
        loading={loading}
        {...menuItemProps}
      />
    </>
  );
}
