import { ComponentType, useEffect } from 'react';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { Edit02Icon, IconProps } from '@/components/icons/Edit02Icon';
import { useModalState } from '@/hooks/useModalState';

import { SubformConfig } from '../EntitySubforms/entitySubformConfigs';
import { EntityType } from '../types/EntityType';
import { EntityFormTrowser } from './EntityFormTrowser';
import { EntityFormStage } from './types';

export interface EntityFormEditButtonProps extends Partial<ButtonProps> {
  householdId: string;
  entityId: string;
  entityType?: EntityType | null;
  isHypotheticalEntity?: boolean;
  openOnMount?: boolean;
  initialFocusedSubformType?: SubformConfig['type'];
  label?: string;
  showIcon?: boolean;
  icon?: ComponentType<IconProps>;
}

export const EntityFormEditButton = ({
  entityType,
  householdId,
  entityId,
  isHypotheticalEntity = false,
  openOnMount = false,
  initialFocusedSubformType,
  label = 'Edit entity details',
  showIcon = true,
  icon = Edit02Icon,
  size = 'sm',
  ...rest
}: EntityFormEditButtonProps) => {
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  useEffect(() => {
    if (openOnMount && !isModalOpen) {
      openModal();
    }
  }, [isModalOpen, openModal, openOnMount]);

  const hasRequiredDetails = entityType && entityId && householdId;
  return (
    <>
      <Button
        size={size}
        variant="secondary"
        onClick={openModal}
        startIcon={showIcon ? icon : undefined}
        disabled={!hasRequiredDetails}
        {...rest}
      >
        {label}
      </Button>

      {entityType && (
        <EntityFormTrowser
          stage={EntityFormStage.EDIT}
          isOpen={isModalOpen}
          type={entityType}
          handleClose={closeModal}
          householdId={householdId}
          entityId={entityId}
          isHypotheticalEntity={isHypotheticalEntity}
          initialFocusedSubformType={initialFocusedSubformType}
        />
      )}
    </>
  );
};
