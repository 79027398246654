import { difference, isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';

import { FullScreenStructuredAssetsModalForm } from '../FullScreenStructuredAssetsModal.types';

export interface ValuationIntegrationDetails {
  /**
   * Whether the form has a linked integration entity.
   */
  isIntegratedScenario: boolean;
  /**
   * Whether the integrated scenario has been modified. This will be true both if the
   * scenario has been modified to add/update existing entities, or if all entities have
   * been removed (e.g. the user has toggled back to the manual scenario).
   */
  isIntegratedScenarioDirty: boolean;
}

export function useValuationIntegrationDetails(): ValuationIntegrationDetails {
  const { control } = useFormContext<FullScreenStructuredAssetsModalForm>();

  const [integratedEntityIds, _previousIntegrationEntityIds] = useWatch({
    control,
    name: ['integrationEntityIds', '_previousIntegrationEntityIds'],
  });

  const isIntegratedScenario = useMemo(() => {
    return !isEmpty(integratedEntityIds);
  }, [integratedEntityIds]);

  const isIntegratedScenarioDirty = useMemo(() => {
    return (
      difference(integratedEntityIds, _previousIntegrationEntityIds).length >
        0 ||
      difference(_previousIntegrationEntityIds, integratedEntityIds).length > 0
    );
  }, [integratedEntityIds, _previousIntegrationEntityIds]);

  return {
    isIntegratedScenario,
    isIntegratedScenarioDirty,
  };
}
