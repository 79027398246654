import { Box, BoxProps, Divider, Stack, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSubBrand } from '@/modules/tenant/TenantDetailsContext/useSubBrand';
import { COLORS } from '@/styles/tokens/colors';

import { PresentationSlideLoadingSkeleton } from '../../PresentationSlide/PresentationSlideLoadingSkeleton';
import { ClientPresentationContent } from '../ClientPresentationContent/ClientPresentationContent';
import { ClientPresentationSidebar } from '../ClientPresentationSidebar/ClientPresentationSidebar';
import { useGuardedClientPresentationDesignerContext } from '../contexts/clientPresentationDesigner.context';
import {
  ACTIVE_SLIDE_QUERY_PARAM,
  ClientPresentationDesignerProvider,
} from '../contexts/ClientPresentationDesigner.provider';
import {
  ClientPresentationDesignerHeader,
  DESIGNER_HEADER_HEIGHT_PX,
} from './ClientPresentationDesignerHeader/ClientPresentationDesignerHeader';
import { useGetClientPresentationDetails } from './hooks/useGetClientPresentationDetails';
import { usePersistPresentationUpdates } from './hooks/usePersistPresentationUpdates';

function PresentationSlideWrapper({ sx = {}, ...otherProps }: BoxProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        border: `solid 1px ${COLORS.NEUTRAL_GRAY[100]}`,
        backgroundColor: 'white',
        boxShadow: theme.palette.shadows.lg,
        maxWidth: '80%',
        ...sx,
      }}
      {...otherProps}
    />
  );
}

export interface ClientPresentationDesignerProps {
  householdId: string;
  presentationId: string;
}

export function ClientPresentationDesignerInner({
  householdId,
  presentationId,
}: ClientPresentationDesignerProps) {
  const [haveSetInitialValues, setHaveSetInitialValues] = useState(false);
  const [searchParams] = useSearchParams();

  const theme = useTheme();
  const {
    setHouseholdId,
    setPresentationId,
    activeSlideId,
    setActiveSlideId,
    _setInitialItemVisibility,
  } = useGuardedClientPresentationDesignerContext();

  useEffect(() => {
    setHouseholdId(householdId);
    setPresentationId(presentationId);
  }, [householdId, presentationId, setHouseholdId, setPresentationId]);

  const {
    name,
    showCoverSlide: initialShowCoverSlide,
    sectionOrder: initialSectionOrder,
    entities,
    client,
    waterfalls,
    federalEstateTaxPercent,
    legalDisclaimers,
    loading,
    allEntitiesOverview,
    professionalTeam,
    balanceSheet,
    visibleEntityPulids: initialVisibleEntityPulids,
    visibleWaterfallPulids: initialVisibleWaterfallPulids,
  } = useGetClientPresentationDetails({
    variables: {
      presentationId,
      householdId,
    },
  });

  const shouldShowLoading = loading || !haveSetInitialValues;

  useSubBrand(client.subBrand?.id ?? null);

  useEffect(() => {
    // if we don't have data yet, do nothing.
    if (!entities) return;

    const activeSlideQueryValue = searchParams.get(ACTIVE_SLIDE_QUERY_PARAM);

    // the initial pass: we have the active slide id in state,
    // but it's not persisted into the context yet
    if (activeSlideQueryValue && activeSlideId !== activeSlideQueryValue) {
      // we want to scroll to the active slideId on load if it's present as a query param
      const doScroll = !activeSlideId;

      // persist it into the context and then
      setActiveSlideId(activeSlideQueryValue, { doScroll });
    }
  }, [entities, searchParams, activeSlideId, setActiveSlideId]);

  useEffect(() => {
    if (loading) return;
    _setInitialItemVisibility({
      shouldShowCoverSlide: initialShowCoverSlide ?? false,
      visibleEntityPulids: initialVisibleEntityPulids,
      visibleWaterfallPulids: initialVisibleWaterfallPulids,
      sectionOrder: initialSectionOrder ?? [],
    });

    setTimeout(() => {
      setHaveSetInitialValues(true);
    });
  }, [
    loading,
    initialVisibleEntityPulids,
    initialVisibleWaterfallPulids,
    _setInitialItemVisibility,
    initialShowCoverSlide,
    initialSectionOrder,
  ]);

  usePersistPresentationUpdates({
    initialShowCoverSlide: initialShowCoverSlide ?? false,
    hasInitializedState: haveSetInitialValues,
    initialVisibleEntityPulids,
    initialVisibleWaterfallPulids,
    initialSectionOrder: initialSectionOrder ?? [],
    presentationId,
  });

  return (
    <Stack
      height="100vh"
      divider={<Divider sx={{ backgroundColor: COLORS.ORANGE[400] }} />}
      sx={{ backgroundColor: 'white' }}
    >
      <ClientPresentationDesignerHeader
        name={name ?? 'Untitled presentation'}
        loading={loading}
        householdId={householdId}
        presentationId={presentationId}
      />
      <Stack
        direction="row"
        flexGrow={1}
        position="relative"
        // +1 for the height of the Divider component
        height={`calc(100% - ${DESIGNER_HEADER_HEIGHT_PX + 1}px)`}
      >
        <Stack
          justifyContent="space-between"
          height="100%"
          width="20%"
          // 320 is based on the width required to fit the
          // two buttons in the SlideManagementMenu
          minWidth={320}
          sx={{ boxShadow: theme.palette.shadows.innerShadowLeftward }}
        >
          <ClientPresentationSidebar loading={shouldShowLoading} />
        </Stack>
        <Box flex={1} overflow="auto" py={3}>
          <Stack
            spacing={3}
            alignItems="center"
            position="relative"
            height="100%"
          >
            {shouldShowLoading && (
              <>
                <PresentationSlideLoadingSkeleton
                  SlideWrapper={PresentationSlideWrapper}
                />
                <PresentationSlideLoadingSkeleton
                  SlideWrapper={PresentationSlideWrapper}
                />
                <PresentationSlideLoadingSkeleton
                  SlideWrapper={PresentationSlideWrapper}
                />
                <PresentationSlideLoadingSkeleton
                  SlideWrapper={PresentationSlideWrapper}
                />
              </>
            )}
            <ClientPresentationContent
              client={client}
              balanceSheet={balanceSheet}
              entities={allEntitiesOverview}
              estateWaterfalls={waterfalls}
              entitiesDetails={entities}
              legalDisclaimers={legalDisclaimers}
              professionalTeam={professionalTeam}
              federalEstateTaxPercent={federalEstateTaxPercent}
              PresentationSlideWrapper={PresentationSlideWrapper}
            />
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}

export const ClientPresentationDesigner = (
  props: ClientPresentationDesignerProps
) => {
  return (
    <ClientPresentationDesignerProvider>
      <ClientPresentationDesignerInner {...props} />
    </ClientPresentationDesignerProvider>
  );
};
