import { Box, Stack, Typography } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { Users01Icon } from '@/components/icons/Users01Icon';
import { Card } from '@/components/layout/Card/Card';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { DocumentRepresentation } from '@/modules/files/DocumentRepresentation/DocumentRepresentation';
import { COLORS } from '@/styles/tokens/colors';
import { DocumentType } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { GetImplementationTasksV2Document } from '../../graphql/ImplementationTasks.generated';
import { TaskHeaderCard } from '../../TaskHeaderCard';
import { TaskBodyProps } from '../../types';
import { useCompleteEntityTaskMutation } from '../graphql/CompleteEntityTask.generated';
import {
  DesignSummaryFilesQuery,
  useDesignSummaryFilesQuery,
} from './graphql/DesignSummaryFiles.generated';

function getGuardedRelatedDocuments(data?: DesignSummaryFilesQuery) {
  if (
    data?.entityWithDocuments?.__typename !== 'Entity' ||
    data.entityWithDocuments.subtype.__typename !== 'GRATTrust'
  ) {
    return [];
  }
  return (
    data.entityWithDocuments.subtype.beneficiaries?.flatMap((beneficiary) => {
      if (!beneficiary.entity) return [];
      const docs = getNodes(beneficiary.entity?.documents);

      return docs.map((document) => document) ?? [];
    }) ?? []
  );
}

const CARD_SPACING = 3;

export function ShareDesignSummaryTaskBody({
  task,
  footer,
  onCompleteTask,
}: TaskBodyProps) {
  // mutations
  const [completeTask, { loading }] = useCompleteEntityTaskMutation();
  const { showFeedback } = useFeedback();

  // query
  const { data, error } = useDesignSummaryFilesQuery({
    variables: {
      designSummaryDocumentsLike: {
        type: DocumentType.GratDesignSummary,
        hasEntityWith: [
          {
            id: task.entityId,
          },
        ],
      },
      entityId: task.entityId,
    },
  });

  // handlers
  async function handleTaskComplete() {
    await completeTask({
      variables: {
        taskID: task.id,
      },
      refetchQueries: [GetImplementationTasksV2Document],
      onCompleted: onCompleteTask,
      onError: (err) => {
        showFeedback(
          'Failed to complete task. Please refresh the page and try again.'
        );
        diagnostics.error(
          `failed to complete ${task.body.type}`,
          err as Error,
          {
            taskID: task.id,
            taskType: task.type,
            householdId: task.householdId,
            entityId: task.entityId,
          }
        );
      },
    });
  }

  const designSummaryDocuments = data?.designSummaryDocuments?.edges ?? [];
  const noGeneratedDocuments = !error && designSummaryDocuments.length === 0;
  const relatedDocuments = getGuardedRelatedDocuments(data);
  return (
    <TaskHeaderCard task={task} footer={footer}>
      <Box>
        <Card variant="filled-light" sx={{ p: CARD_SPACING }}>
          <Box>
            {error && (
              <Callout severity="error">
                We failed to fetch your documents. Please refresh to try again.
              </Callout>
            )}
            {noGeneratedDocuments && (
              <Callout severity="warning">
                Please generate a design summary in the previous task.
              </Callout>
            )}
            {designSummaryDocuments.map((documentEdge) => {
              if (!documentEdge?.node) return null;
              return (
                <DocumentRepresentation
                  showDelete={false}
                  showCopySharingURL={true}
                  key={documentEdge?.node?.id}
                  fileName={documentEdge?.node?.name}
                  uploadedAt={documentEdge?.node?.file?.createdAt}
                  uploadedBy={documentEdge?.node?.file?.user?.displayName}
                  documentId={documentEdge?.node?.id}
                />
              );
            })}
          </Box>
          {relatedDocuments.length > 0 && (
            <Card variant="filled" sx={{ p: CARD_SPACING, mt: 2 }}>
              <Stack direction="row" alignItems="center" mb={2} spacing={2}>
                <Users01Icon size={24} color={COLORS.GRAY[400]} />
                <Typography variant="body1">
                  Documents associated with remainder trusts referenced in the
                  design summary may also be shared.
                </Typography>
              </Stack>
              {relatedDocuments.map((document, idx) => {
                return (
                  <DocumentRepresentation
                    sx={{ mt: idx === 0 ? 0 : 1 }}
                    showCopySharingURL={true}
                    showDelete={false}
                    key={document.id}
                    fileName={document.name}
                    uploadedAt={document.file?.createdAt}
                    uploadedBy={document.file?.user?.displayName}
                    documentId={document.id}
                  />
                );
              })}
            </Card>
          )}
          <Box mt={5}>
            <Button
              disabled={!!error || loading || noGeneratedDocuments}
              variant="primary"
              size="sm"
              fullWidth
              onClick={handleTaskComplete}
            >
              I confirm design summary was sent to attorney
            </Button>
            <Button
              onClick={handleTaskComplete}
              disabled={loading}
              variant="transparent"
              fullWidth
              size="sm"
            >
              Skip this
            </Button>
          </Box>
        </Card>
      </Box>
    </TaskHeaderCard>
  );
}
