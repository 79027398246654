import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { PlusCircleIcon } from '@/components/icons/PlusCircleIcon';
import { XCircleIcon } from '@/components/icons/XCircleIcon';
import { COLORS } from '@/styles/tokens/colors';

export type ToggleVisibilityButtonProps = Partial<ButtonProps> & {
  isVisible?: boolean;
  toggleVariant: 'add' | 'remove';
};

export function ToggleVisibilityButton({
  isVisible,
  toggleVariant,
  sx,
  ...buttonProps
}: ToggleVisibilityButtonProps) {
  const Icon = toggleVariant === 'remove' ? XCircleIcon : PlusCircleIcon;
  const finalSx: SxProps<Theme> = {
    visibility: isVisible ? 'visible' : 'hidden',
    ...(sx || {}),
  };
  return (
    <Button
      data-testid="ToggleVisibilityButton"
      size="xs"
      variant="transparent"
      sx={finalSx}
      {...buttonProps}
    >
      <Icon color={COLORS.GRAY[400]} size={18} />
    </Button>
  );
}
