import { ApolloError } from '@apollo/client';
import Decimal from 'decimal.js';
import { compact, isUndefined } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import {
  CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment,
  CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment,
  CharitableTrustDesignerIllustrationData_NoPlanProjectionFragment,
} from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesignerAnalysis/graphql/CharitableTrustDesignerIllustrationData.generated';
import { useIsCRT } from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesignerContext';
import {
  CltProposalProjectionInput,
  CrtProposalProjectionInput,
  NoPlanProjectionInput,
} from '@/types/schema';

import { useGetCharitableProjectionQuery } from '../graphql/GetCharitableProjection.generated';
import {
  CharitableAnalysisForm,
  NAMESPACE,
} from '../slides/CharitableAnalysis/CharitableAnalysis.types';

export interface GetCharitableProjectionResponse {
  id: string;
  crtProjection:
    | CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment
    | undefined;
  cltProjection:
    | CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment
    | undefined;
  noPlanProjection:
    | CharitableTrustDesignerIllustrationData_NoPlanProjectionFragment
    | undefined;
  loading: boolean | undefined;
}

export function useGetCharitableProjection({
  proposal,
}: {
  proposal: ProposalFragment;
}): GetCharitableProjectionResponse {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { control } = useFormContext<CharitableAnalysisForm>();

  const [years, selectedYearIdx, projections, selectedProjectionIdx] = useWatch(
    {
      control,
      name: [
        `${NAMESPACE}.years`,
        `${NAMESPACE}.selectedYearIdx`,
        `${NAMESPACE}.projections`,
        `${NAMESPACE}.selectedProjectionIdx`,
      ],
    }
  );

  const targetYear = years[selectedYearIdx];

  if (isUndefined(targetYear)) {
    throw new Error('Got invalid target year');
  }

  const isCRT = useIsCRT();

  const charitableProposal = proposal.crtProposal || proposal.cltProposal;
  const relevantProjection = projections[selectedProjectionIdx];

  if (!charitableProposal || !relevantProjection) {
    throw new Error('Could not get relevant proposal');
  }

  const params: CrtProposalProjectionInput & CltProposalProjectionInput =
    useMemo(
      () => ({
        costBasis: charitableProposal.assetCostBasis,
        fundingAmount: charitableProposal.assetValue,
        payoutKind: charitableProposal.payoutKind,
        payoutFrequency: charitableProposal.payoutFrequency,
        preTaxReturnPercentage: relevantProjection.preTaxReturnPercentage,
        taxDrag: relevantProjection.taxDragPercentage,
        termYears: charitableProposal.termYears,
        annuityPayoutAmount: charitableProposal.annuityPayoutAmount,
        unitrustPayoutPercent: charitableProposal.unitrustPayoutPercent,
        termKind: charitableProposal.termKind,
        donorsYearOfBirth: compact(
          charitableProposal.donors.map((donor) =>
            donor.dateOfBirth?.getFullYear()
          )
        ),
      }),
      [
        charitableProposal.annuityPayoutAmount,
        charitableProposal.assetCostBasis,
        charitableProposal.assetValue,
        charitableProposal.donors,
        charitableProposal.payoutFrequency,
        charitableProposal.payoutKind,
        charitableProposal.termKind,
        charitableProposal.termYears,
        charitableProposal.unitrustPayoutPercent,
        relevantProjection.preTaxReturnPercentage,
        relevantProjection.taxDragPercentage,
      ]
    );

  const noPlanProjectionInput: NoPlanProjectionInput = useMemo(
    () => ({
      assetValue: charitableProposal.assetValue,
      costBasis: charitableProposal.assetCostBasis,
      taxDrag: isCRT ? relevantProjection.taxDragPercentage : new Decimal(0),
      growthRate: relevantProjection.preTaxReturnPercentage,
      termYears: charitableProposal.termYears,
      ignoreCapitalGainsTax: !isCRT, // ignore capital gains tax for CLT
    }),
    [
      charitableProposal.assetCostBasis,
      charitableProposal.assetValue,
      charitableProposal.termYears,
      isCRT,
      relevantProjection.preTaxReturnPercentage,
      relevantProjection.taxDragPercentage,
    ]
  );

  const { data, loading } = useGetCharitableProjectionQuery({
    variables: {
      crtProjectionInput: params,
      cltProjectionInput: params,
      noPlanProjectionInput,
      isCRT,
    },
    skip: charitableProposal.assetValue.lessThanOrEqualTo(new Decimal(0)),
    onError: (error: ApolloError) => {
      showFeedback('Could not fetch updated projection data');
      reportError(
        'Encountered exception loading updated projection data for presentation',
        error
      );
    },
  });

  const output: GetCharitableProjectionResponse = {
    id: '', // dummy value so this can fill in for CharitableTrustDesignerIllustrationData_ProposalFragment
    crtProjection: data?.crtProposalProjection,
    cltProjection: data?.cltProposalProjection,
    noPlanProjection: data?.noPlanProjection,
    loading,
  };

  return output;
}
