import { useCallback } from 'react';

import { UnreachableError } from '@/utils/errors';

import { useCompleteInitializationAction } from '../actions/completeInitialization.action';
import { useInitializeAction } from '../actions/initialize.action';
import { useInitializeNodePositionsAction } from '../actions/initializeNodePositions.action';
import { useNodesChangedAction } from '../actions/nodesChanged.action';
import { useQueryFamilyTreeSuccessAction } from '../actions/queryFamilyTreeSuccess.action';
import { FamilyTreeAction, FamilyTreeReducer } from '../types';

export function useFamilyTreeReducer(): FamilyTreeReducer {
  const completeInitializationAction = useCompleteInitializationAction();
  const queryFamilyTreeSuccessAction = useQueryFamilyTreeSuccessAction();
  const initializeAction = useInitializeAction();
  const nodesChangedAction = useNodesChangedAction();
  const initializeNodePositionsAction = useInitializeNodePositionsAction();

  return useCallback<FamilyTreeReducer>(
    (state, action, queueEffect) => {
      switch (action.type) {
        case 'COMPLETE_INITIALIZATION':
          return completeInitializationAction(state, action, queueEffect);
        case 'QUERY_FAMILY_TREE_SUCCESS':
          return queryFamilyTreeSuccessAction(state, action, queueEffect);
        case 'INITIALIZE':
          return initializeAction(state, action, queueEffect);
        case 'NODES_CHANGED':
          return nodesChangedAction(state, action, queueEffect);
        case 'INITIALIZE_NODE_POSITIONS':
          return initializeNodePositionsAction(state, action, queueEffect);
        default:
          throw new UnreachableError({
            case: (action as FamilyTreeAction).type as never,
            message: `Unknown action ${(action as FamilyTreeAction).type}`,
          });
      }
    },
    [
      completeInitializationAction,
      initializeAction,
      initializeNodePositionsAction,
      nodesChangedAction,
      queryFamilyTreeSuccessAction,
    ]
  );
}
