import { compact } from 'lodash';
import { useMemo } from 'react';

import {
  EntityPresentationSlideType,
  EntitySlideMap,
} from '../entityPresentations.types';

export function useOrderedEntitySlides(
  slideTypes: EntityPresentationSlideType[],
  slideTypeMap: EntitySlideMap
) {
  return useMemo(() => {
    // the slideTypes prop controls the ordering of how the slides are rendered, as well as
    // allowing the caller to render a subset of all possible slides.
    return compact(
      // flatMapping here because each EntitySlideMap entry can have more than one slide
      slideTypes.flatMap((slideType) => {
        // defend against an invalid slideType being passed in; it's onerous to typecheck that, so
        // a runtime check will do
        if (!(slideType in slideTypeMap)) {
          return null;
        }

        return slideTypeMap[slideType];
      })
    );
  }, [slideTypeMap, slideTypes]);
}
