import { Callout } from '@/components/notifications/Callout/Callout';
import { PulidKind } from '@/utils/pulid';

export function getAdditionalDetailsForPulidKind(kind: PulidKind | null) {
  switch (kind) {
    case PulidKind.TestamentaryEntity:
      return (
        <Callout severity="info-high">
          Any document can be uploaded here, but only documents associated with
          entities that distribute into this testamentary entity will be
          displayed in the document viewer.
        </Callout>
      );
    case PulidKind.ClientProfile:
      return (
        <Callout severity="info-high">
          Any document can be uploaded here, but only wills and operating
          agreements will be displayed in the document viewer.
        </Callout>
      );
    default:
      return null;
  }
}
