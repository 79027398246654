import { Stack, Typography } from '@mui/material';

import { AlertTriangleIcon } from '@/components/icons/AlertTriangleIcon';
import {
  ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES,
  AssetIntegrationProviders,
} from '@/modules/assetProviderIntegrations/shared/constants';
import { COLORS } from '@/styles/tokens/colors';

export interface InitialIntegrationSyncErrorProps {
  provider: AssetIntegrationProviders;
}

export function InitialIntegrationSyncError({
  provider,
}: InitialIntegrationSyncErrorProps) {
  const ICON_SIZE = 60;
  const providerName = ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES[provider];
  return (
    <Stack spacing={5} alignItems="center" justifyContent="center">
      <Stack spacing={3} alignItems="center" justifyContent="center">
        <AlertTriangleIcon size={ICON_SIZE} color={COLORS.GRAY[400]} />
        <Typography variant="body2" textAlign="center">
          Unable to retrieve data from {providerName}
        </Typography>
      </Stack>
    </Stack>
  );
}
