// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
export type TrustAdvisorsList_TrustAdvisorPartyFragment = { __typename?: 'TrustAdvisor', id: string, individual?: { __typename?: 'ClientProfile', displayName: string, id: string } | null, organization?: { __typename?: 'ClientOrganization', name: string, id: string } | null };

export type TrustAdvisorsList_TrustAdvisorFragment = { __typename?: 'TrustAdvisor', id: string, level: Types.TrustAdvisorLevel, note?: string | null, roles?: Array<{ __typename?: 'TrustAdvisorRole', id: string, kind: Types.TrustAdvisorRoleKind }> | null, individual?: { __typename?: 'ClientProfile', displayName: string, id: string } | null, organization?: { __typename?: 'ClientOrganization', name: string, id: string } | null };

export const TrustAdvisorsList_TrustAdvisorPartyFragmentDoc = gql`
    fragment TrustAdvisorsList_TrustAdvisorParty on TrustAdvisor {
  id
  individual {
    displayName
    id
  }
  organization {
    name
    id
  }
}
    `;
export const TrustAdvisorsList_TrustAdvisorFragmentDoc = gql`
    fragment TrustAdvisorsList_TrustAdvisor on TrustAdvisor {
  id
  level
  note
  roles {
    id
    kind
  }
  ...TrustAdvisorsList_TrustAdvisorParty
}
    ${TrustAdvisorsList_TrustAdvisorPartyFragmentDoc}`;
export const Operations = {
  Fragment: {
    TrustAdvisorsList_TrustAdvisorParty: 'TrustAdvisorsList_TrustAdvisorParty' as const,
    TrustAdvisorsList_TrustAdvisor: 'TrustAdvisorsList_TrustAdvisor' as const
  }
}