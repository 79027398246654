import { EntityMap_GrantorClientProfileFragment } from '../../graphql/EntityMap.generated';
import { EntityGraph } from '../../types';
import { createEdge } from '../../utils/edges';
import { getGrantorNodeId } from '../../utils/normalize';
import { createGrantorNodeFromClientProfile } from '../grantors';
import { grantorBusinessEntitiesSummaryTile } from '../tiles';

export function addGrantorBusinessEntitiesSummary(
  graph: EntityGraph,
  grantor: EntityMap_GrantorClientProfileFragment
) {
  // If the grantor has no business entities, don't add the summary tile.
  if (!grantor.ownedOwnershipStakes?.length) {
    return;
  }

  // first, make sure that the node for the grantor exists
  const grantorNode = createGrantorNodeFromClientProfile(graph, grantor);
  if (!grantorNode) return;
  graph.addNodeSafe(grantorNode.node.id, grantorNode);

  // then, add the business entities summary tile
  const grantorSummaryTile = grantorBusinessEntitiesSummaryTile(grantor);
  graph.addNodeSafe(grantorSummaryTile.id, {
    node: grantorSummaryTile,
    data: grantor,
    categorizationType: 'GRANTOR_BUSINESS_ENTITIES_SUMMARY',
  });

  // finally, associate the grantor node and the business entities summary tile node
  const edge = createEdge(graph, {
    source: getGrantorNodeId(grantor.id),
    target: grantorSummaryTile.id,
  });
  graph.addEdgeSafe(getGrantorNodeId(grantor.id), grantorSummaryTile.id, {
    edge,
    type: 'beneficiary',
  });
}
