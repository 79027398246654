import { Theme } from '@mui/material';
import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { FONT_SIZING as FONT_SIZING_EXTERNAL } from '@/styles/tokens/fonts';
import { UnreachableError } from '@/utils/errors';

import { TextEditorContext } from '../contexts/textEditor.context';

function getUserGeneratedTheme(theme: Theme) {
  // We're redefining this font sizing ramp specific for this markdown component so that users can
  // differentiate the hierarchy of headings, but without having a large number of very large h1
  // elements on the list and without impacting the global font sizing in the app.
  // https://withluminary.slack.com/archives/C05HVB27NFJ/p1701815624999279
  const FONT_SIZING: typeof FONT_SIZING_EXTERNAL = {
    xs: {
      fontSize: '0.7rem',
      lineHeight: '0.875rem',
    },
    sm: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
    md: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
    },
    lg: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    xl: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
    },
    xxl: {
      fontSize: '1.25rem',
      lineHeight: '1.875rem',
    },
  } as const;

  const blockquoteStyle = {
    margin: 0,
    marginLeft: '20px',
    borderLeftColor: COLORS.NEUTRAL_GRAY[300],
    borderLeftWidth: '4px',
    borderLeftStyle: 'solid' as const,
    paddingLeft: '16px',
    '& *': {
      paddingBottom: '0',
      ...FONT_SIZING.md,
    },
  };

  const codeStyle = {
    backgroundColor: COLORS.NEUTRAL_GRAY[100],
    padding: '2px 4px',
    borderRadius: '4px',
  };

  const sharedStyles = {
    margin: 0,
    paddingBottom: theme.spacing(1),
  };

  return {
    h1: {
      ...(theme.typography.h1 as CSSObject),
      ...FONT_SIZING.xxl,
      ...sharedStyles,
    },
    h2: {
      ...(theme.typography.h2 as CSSObject),
      ...FONT_SIZING.xl,
      ...sharedStyles,
    },
    h3: {
      ...(theme.typography.h3 as CSSObject),
      ...FONT_SIZING.lg,
      ...sharedStyles,
    },
    h4: {
      ...(theme.typography.h4 as CSSObject),
      ...FONT_SIZING.md,
      ...sharedStyles,
    },
    h5: {
      ...(theme.typography.h5 as CSSObject),
      ...FONT_SIZING.sm,
      ...sharedStyles,
    },
    h6: {
      ...(theme.typography.h6 as CSSObject),
      ...FONT_SIZING.xs,
      ...sharedStyles,
    },
    li: {
      ...(theme.typography.body1 as CSSObject),
      margin: `0 ${theme.spacing(3)}`,
      '&::marker': {
        fontVariantNumeric: 'normal',
      },
    },
    nestedListItem: {
      listStyleType: 'none',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    ul: {
      padding: 0,
      listStylePosition: 'outside',
      ...sharedStyles,
    },
    ol1: {
      padding: 0,
      listStylePosition: 'outside',
      ...sharedStyles,
    },
    ol2: {
      padding: 0,
      margin: 0,
      listStyleType: 'upper-alpha',
      llistStylePosition: 'outside',
    },
    ol3: {
      padding: 0,
      margin: 0,
      listStyleType: 'lower-alpha',
      listStylePosition: 'outside',
    },
    ol4: {
      padding: 0,
      margin: 0,
      listStyleType: 'upper-roman',
      listStylePosition: 'outside',
    },
    ol5: {
      padding: 0,
      margin: 0,
      listStyleType: 'lower-roman',
      listStylePosition: 'outside',
    },
    p: {
      ...(theme.typography.body1 as CSSObject),
      ...sharedStyles,
    },
    blockquote: blockquoteStyle,
    bold: {
      fontWeight: 700,
    },
    code: codeStyle,
    italic: {
      fontStyle: 'italic',
    },
    strikethrough: {
      textDecoration: 'line-through',
    },
    subscript: {
      verticalAlign: 'sub',
    },
    superscript: {
      verticalAlign: 'super',
    },
    underline: {
      textDecoration: 'underline',
    },
    underlineStrikethrough: {
      textDecoration: 'underline line-through',
    },
  } as const;
}

function getContextContentTheme(theme: Theme) {
  const blockquoteStyle = {
    margin: 0,
    marginLeft: '20px',
    borderLeftColor: COLORS.NEUTRAL_GRAY[300],
    borderLeftWidth: '4px',
    borderLeftStyle: 'solid' as const,
    paddingLeft: '16px',
    '& *': {
      paddingBottom: '0',
      ...FONT_SIZING_EXTERNAL.md,
    },
  };

  const codeStyle = {
    backgroundColor: COLORS.NEUTRAL_GRAY[100],
    padding: '2px 4px',
    borderRadius: '4px',
  };

  const sharedStyles = {
    margin: 0,
    paddingBottom: theme.spacing(0.5),
  };

  return {
    h1: {
      ...(theme.typography.h1 as CSSObject),

      ...sharedStyles,
    },
    h2: {
      ...(theme.typography.h2 as CSSObject),

      ...sharedStyles,
    },
    h3: {
      ...(theme.typography.h3 as CSSObject),

      ...sharedStyles,
    },
    h4: {
      ...(theme.typography.h4 as CSSObject),

      ...sharedStyles,
    },
    h5: {
      ...(theme.typography.h5 as CSSObject),
      ...sharedStyles,
    },
    h6: {
      ...(theme.typography.h6 as CSSObject),

      ...sharedStyles,
    },
    li: {
      ...(theme.typography.body1 as CSSObject),
      margin: 0,
      padding: 0,
      paddingLeft: '10px',
      listStyleImage: `url('data:image/svg+xml,<svg transform-origin="center" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="8" height="8" rx="2" fill="%23FF594E"/></svg>');`,
      paddingBottom: '16px',

      '&::marker': {
        fontSize: '1.5em',
        lineHeight: '0.1em',
      },
    },

    nestedListItem: {
      listStyleType: 'none',
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
    },
    ul: {
      listStyle: 'none',
      padding: 0,
      listStylePosition: 'outside',
      paddingInlineStart: '20px',
      ...sharedStyles,
    },
    ol1: {
      padding: 0,
      listStylePosition: 'outside',
      ...sharedStyles,
    },
    ol2: {
      padding: 0,
      margin: 0,
      listStyleType: 'upper-alpha',
      llistStylePosition: 'outside',
    },
    ol3: {
      padding: 0,
      margin: 0,
      listStyleType: 'lower-alpha',
      listStylePosition: 'outside',
    },
    ol4: {
      padding: 0,
      margin: 0,
      listStyleType: 'upper-roman',
      listStylePosition: 'outside',
    },
    ol5: {
      padding: 0,
      margin: 0,
      listStyleType: 'lower-roman',
      listStylePosition: 'outside',
    },
    p: {
      ...(theme.typography.body1 as CSSObject),
      ...sharedStyles,
    },
    blockquote: blockquoteStyle,
    bold: {
      fontWeight: 700,
    },
    code: codeStyle,
    italic: {
      fontStyle: 'italic',
    },
    strikethrough: {
      textDecoration: 'line-through',
    },
    subscript: {
      verticalAlign: 'sub',
    },
    superscript: {
      verticalAlign: 'super',
    },
    underline: {
      textDecoration: 'underline',
    },
    underlineStrikethrough: {
      textDecoration: 'underline line-through',
    },
  } as const;
}

/* eslint-disable tss-unused-classes/unused-classes */
export const useTextEditorClasses = makeStyles<{
  textEditorTheme: TextEditorContext['theme'];
}>()((theme, { textEditorTheme }) => {
  switch (textEditorTheme) {
    case 'userGeneratedContent':
      return getUserGeneratedTheme(theme);
    case 'contextContent':
      return getContextContentTheme(theme);
    default:
      throw new UnreachableError({
        case: textEditorTheme,
        message: `Invalid text editor theme: ${textEditorTheme}`,
      });
  }
});
