import { transformNodesWithViewConfig } from '@/modules/graphViz/graphVizNodeConfig';
import { EntityGraphViewOrientation } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { entityGraphViewToBuilderFn } from '../constants';
import { DEFAULT_ENTITY_MAP_STATE } from '../contexts/entityMap.context';
import { EntityMapState, EntityMapStateProps } from '../types';

export function generateEntityGraphState({
  household,
  entityGraphView,
}: EntityMapStateProps): Pick<
  EntityMapState,
  'entityGraph' | 'nodes' | 'edges'
> {
  const { possibleBeneficiariesV2, possibleGrantors } = household;
  const entities = getNodes(household.entities);
  // all primary clients will be grantors, but not all grantors will be primary clients
  const possiblePrimaryClients = possibleGrantors.filter((g) => g.isPrimary);

  const builderFn = entityGraphViewToBuilderFn[entityGraphView.kind!];

  const entityGraph = builderFn({
    entities,
    possiblePrimaryClients,
    possibleBeneficiaries: possibleBeneficiariesV2,
    possibleGrantors,
    orientation:
      entityGraphView.orientation ?? EntityGraphViewOrientation.LeftRight,
  });

  const nodes = entityGraph.getNodes();
  const edges = entityGraph.getEdges();

  const finalNodes = transformNodesWithViewConfig({
    nodes,
    nodeConfigurations: entityGraphView.nodeConfigurations,
  });

  return {
    entityGraph,
    nodes: finalNodes,
    edges,
  };
}

export function generateDefaultState(
  defaultStateProps: EntityMapStateProps
): EntityMapState {
  const entityGraphProps = generateEntityGraphState(defaultStateProps);

  return {
    ...DEFAULT_ENTITY_MAP_STATE,
    ...defaultStateProps,
    ...entityGraphProps,
  };
}
