import { GridRowOrderChangeParams } from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import { useMemo } from 'react';

import {
  AugmentedCreateAssetClassInput,
  AugmentedUpdateAssetClassInput,
  UpdateAssetClassInput,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import {
  AssetCategoriesForm,
  NEW_ASSET_CATEGORY_SENTINEL,
  RowData,
} from './AdminAssetCategoriesConfigurationPage.types';
import { AdminAssetCategoriesConfigurationPage_AssetClassFragment } from './graphql/AdminAssetCategoriesConfigurationPage.generated';

export interface MapFormValuesToInputsReturn {
  updates: AugmentedUpdateAssetClassInput[];
  creates: AugmentedCreateAssetClassInput[];
  clears: AugmentedUpdateAssetClassInput[];
}

interface MapFormValuesToInputsParams {
  pinnedRows: RowData[];
  reorderableRows: RowData[];
}

export function mapFormValuesToInputs(
  formValues: AssetCategoriesForm,
  { pinnedRows, reorderableRows }: MapFormValuesToInputsParams
): MapFormValuesToInputsReturn {
  const allRows = [...pinnedRows, ...reorderableRows];
  const updates: AugmentedUpdateAssetClassInput[] = [];
  const creates: AugmentedCreateAssetClassInput[] = [];
  const clears: AugmentedUpdateAssetClassInput[] = [];

  Object.entries(formValues.assetCategoriesById).forEach(([id, category]) => {
    const {
      _isSystemClass,
      integrationCategoryIds,
      name,
      liquidityStatus,
      growthRate,
    } = category;

    const associatedTableRow = allRows.find((row) => row.id === id);

    if (!associatedTableRow) {
      // this is a deleted row
      return;
    }

    const reorderableRowSortOrder = (() => {
      // system classes already have their row order set and we don't want to change that
      if (_isSystemClass) return null;
      const reorderableRowIndex = reorderableRows.findIndex(
        (row) => row.id === id
      );

      if (reorderableRowIndex === -1) {
        throw new Error(
          'Unexpected state: attempting to reorder a nonexistent row'
        );
      }

      // we want the reorderable row sort orders to always come *after* those of the system classes,
      // which are represented by the pinned rows
      return reorderableRowIndex + pinnedRows.length;
    })();

    if (id.startsWith(NEW_ASSET_CATEGORY_SENTINEL)) {
      const create: AugmentedCreateAssetClassInput = {
        create: {
          integrationAssetClassIDs: integrationCategoryIds,
          displayName: name,
          liquidityStatus,
          growthRate,
          sortOrder: reorderableRowSortOrder,
        },
      };

      creates.push(create);
    } else {
      const update: UpdateAssetClassInput = {
        addIntegrationAssetClassIDs:
          integrationCategoryIds.length > 0
            ? integrationCategoryIds
            : undefined,
        displayName: _isSystemClass ? null : name,
        liquidityStatus: _isSystemClass ? null : liquidityStatus,
        sortOrder: reorderableRowSortOrder,
        growthRate,
      };

      updates.push({
        id,
        update,
      });

      // Clear all associations first
      clears.push({
        id,
        update: {
          clearIntegrationAssetClasses: true,
        },
      });
    }
  });

  return { updates, creates, clears };
}

export function useMapDataToRows(
  assetClasses: AdminAssetCategoriesConfigurationPage_AssetClassFragment[]
): RowData[] {
  return useMemo(
    () =>
      assetClasses.map<RowData>((assetClass) => ({
        __reorder__: assetClass.displayName,
        id: assetClass.id,
        name: assetClass.displayName,
        isSystemClass: assetClass.isSystemClass,
        growthRate: null,
        action: null,
        integrationCategories: null,
        liquidity: null,
      })),
    [assetClasses]
  );
}

export function getDefaultValues(
  data: AdminAssetCategoriesConfigurationPage_AssetClassFragment[]
): AssetCategoriesForm {
  const assetCategoriesById = data.reduce(
    (acc, assetClass) => {
      const integrationCategoryIds = getNodes(
        assetClass.integrationAssetClasses
      ).map((ic) => ic.id);
      acc[assetClass.id] = {
        integrationCategoryIds,
        _isSystemClass: assetClass.isSystemClass,
        growthRate: assetClass.growthRate || new Decimal(0),
        name: assetClass.displayName,
        liquidityStatus: assetClass.liquidityStatus,
      };
      return acc;
    },
    {} as AssetCategoriesForm['assetCategoriesById']
  );

  return { assetCategoriesById, currentlyEditingRowId: null };
}

/**
 * @description given the current set of reorderable rows and and a GridRowOrderChange event,
 * return an updated list of reorderable rows
 */
export function getNextRowOrder(
  reorderableRows: RowData[],
  params: GridRowOrderChangeParams
): RowData[] {
  const { oldIndex, targetIndex, row } = params;
  const updatedRows = [...reorderableRows];
  const [removed] = updatedRows.splice(oldIndex, 1);

  if (!removed) {
    const msg = 'Unexpected state: attempting to reorder a nonexistent row';
    diagnostics.error(msg, new Error(msg), {
      row: JSON.stringify(row),
      oldIndex,
      targetIndex,
    });
    return reorderableRows;
  }

  updatedRows.splice(targetIndex, 0, removed);
  return updatedRows;
}
