import { GridRowParams } from '@mui/x-data-grid-pro';
import { useMemo, useState } from 'react';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { DataTableCard } from '@/components/tables/DataTable/components/DataTableCard';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import {
  PaginatedTableProps,
  usePaginatedDataTableQuery,
} from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { TableEmptyState } from '@/components/tables/DataTable/TableEmptyState';
import { useReportError } from '@/hooks/useReportError';
import { EmployeeModal } from '@/modules/employees/EmployeeModal/EmployeeModal';
import {
  mapDataToRows,
  useUserTableColumns,
} from '@/pages/admin/AdminUsersPage/AdminUsersPage.utils';

import { useGetAdminUsersQuery } from './graphql/AdminUsers.generated';

type Row = ReturnType<typeof mapDataToRows>[number];

export interface Props {
  rows: Row[];
  loading: boolean;
  paginatedTableProps: PaginatedTableProps;
}

const slots = {
  noRowsOverlay: () => <TableEmptyState text="No users" />,
};

function AdminUsersPageInner({ rows, loading, paginatedTableProps }: Props) {
  const columns = useUserTableColumns();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employeeId, setEmployeeId] = useState<null | string>(null);
  const handleAddNewUserClick = () => {
    setIsModalOpen(true);
    setEmployeeId(null);
  };

  return (
    <SubpageLayout
      heading="All internal users"
      actions={
        <Button
          size="sm"
          variant="secondary"
          startIcon={PlusIcon}
          onClick={handleAddNewUserClick}
        >
          Add user
        </Button>
      }
    >
      <EmployeeModal
        isOpen={isModalOpen}
        closeHandler={() => {
          setIsModalOpen(false);
        }}
        employeeId={employeeId}
      />
      <DataTableCard>
        <DataTable
          {...paginatedTableProps}
          columns={columns}
          rows={rows}
          loading={loading}
          slots={slots}
          onRowClick={(row: GridRowParams<Row>) => {
            setIsModalOpen(true);
            setEmployeeId(row.row.id);
          }}
        />
      </DataTableCard>
    </SubpageLayout>
  );
}

export function AdminUsersPage() {
  const { showFeedback } = useFeedback();
  const [paginatedTableProps, { data, loading, error }] =
    usePaginatedDataTableQuery(useGetAdminUsersQuery, {
      pageSize: PageSizes.Ten,
      onError: () => {
        showFeedback(
          "We weren't able to load users. Please refresh the page and try again."
        );
      },
    });

  useReportError('failed to load data for users page', error);

  const rows = useMemo(() => mapDataToRows(data), [data]);

  return (
    <>
      <AdminUsersPageInner
        rows={rows}
        loading={loading}
        paginatedTableProps={paginatedTableProps}
      />
    </>
  );
}
