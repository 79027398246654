import { Typography } from '@mui/material';
import { HTMLAttributes } from 'react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

export const ROW_HEIGHT_PX = 24;
export const ROW_PADDING_PX = 8;

const useStyles = makeStyles()((_theme) => ({
  root: {
    padding: `${ROW_PADDING_PX}px`,
    display: 'flex',
    gap: '4px',
    '&:hover': {
      backgroundColor: COLORS.FUNCTIONAL.HOVER,
    },
  },
  description: {
    color: COLORS.GRAY[900],
  },
}));

export interface TileGroupItemProps extends HTMLAttributes<HTMLDivElement> {
  classes?: WithClasses<typeof useStyles>;
  description: string;
}

export const TileGroupItem = ({
  classes: externalClasses,
  description,
  ...props
}: TileGroupItemProps): JSX.Element => {
  const { classes } = useStyles(undefined, {
    props: { classes: externalClasses },
  });

  return (
    <div className={classes.root} {...props}>
      <Typography variant="h4" className={classes.description}>
        {description}
      </Typography>
    </div>
  );
};
