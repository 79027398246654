import Decimal from 'decimal.js';
import { compact, keyBy, uniq } from 'lodash';
import { useMemo } from 'react';

import { useEstateWaterfallSummaryBarChartsSectionColors } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useEstateWaterfallSummaryBarChartsSectionColors.hook';
import { EstateWaterfallSummaryBarChartsSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/types';
import { WaterfallSummaryTableItem } from '@/modules/estateWaterfall/contexts/waterfallSummary.context';
import {
  getIsCharitableNode,
  getWaterfallItemName,
} from '@/modules/estateWaterfall/EstateWaterfall.utils';
import {
  GetWaterfallSummary_EstateWaterfallFragment,
  GetWaterfallSummary_EstateWaterfallVizNodeFragment,
} from '@/modules/estateWaterfall/graphql/GetWaterfallSummary.generated';
import {
  ContextPrimaryClient,
  useHouseholdDetailsContext,
} from '@/modules/household/contexts/householdDetails.context';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { AfterDeath, EntityInEstateStatus } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { useWaterfallSummaryTableTaxRows } from './useWaterfallSummaryTableTaxRows';

interface MapVizNodeToTableItemInput {
  node: GetWaterfallSummary_EstateWaterfallVizNodeFragment;
  colorChoices: Record<EstateWaterfallSummaryBarChartsSections, string>;
  grantorName?: string;
  noGrowthNode: GetWaterfallSummary_EstateWaterfallVizNodeFragment | undefined;
  afterDeath: AfterDeath;
  hasHypotheticalTransfer?: boolean;
}

function mapVizNodeToTableItem({
  node: vizNode,
  colorChoices,
  grantorName,
  noGrowthNode,
  afterDeath,
  hasHypotheticalTransfer,
}: MapVizNodeToTableItemInput): WaterfallSummaryTableItem {
  const colorForNode = (() => {
    const estateStatus = vizNode.inEstateStatus;
    if (estateStatus === EntityInEstateStatus.OutOfEstate) {
      const isCharitableNode = getIsCharitableNode(vizNode.node);

      if (isCharitableNode) {
        return colorChoices[
          EstateWaterfallSummaryBarChartsSections.OutOfEstateCharity
        ];
      } else {
        // Out of estate family includes all other entities, accounts, and
        // individuals
        return colorChoices[
          EstateWaterfallSummaryBarChartsSections.OutOfEstateFamily
        ];
      }
    }

    return colorChoices[EstateWaterfallSummaryBarChartsSections.InEstate];
  })();

  return {
    node: vizNode,
    kind: 'node',
    afterDeath,
    id: vizNode.id,
    sectionIndicator: {
      backgroundColor: colorForNode,
    },
    entity: getWaterfallItemName(vizNode, grantorName),
    value: noGrowthNode?.value ?? vizNode.value,
    projectedValue: noGrowthNode?.value ? vizNode.value : new Decimal(0),
    valueBeforeTransfers: vizNode?.valueBeforeTransfers || null,
    hasHypotheticalTransfer,
  };
}

export function useWaterfallSummaryTableRows(
  waterfall: GetWaterfallSummary_EstateWaterfallFragment | null,
  grantors: ContextPrimaryClient[]
): Record<AfterDeath, WaterfallSummaryTableItem[]> {
  const colorChoices = useEstateWaterfallSummaryBarChartsSectionColors();
  const makeTaxRows = useWaterfallSummaryTableTaxRows();
  const { primaryClients } = useHouseholdDetailsContext();
  const { availableStateEstateTaxes } = useIRSConstants();

  const stateCodesFromClientAddress = useMemo(() => {
    // Deduplicate and sort the state codes for the primary clients
    return uniq(
      primaryClients?.flatMap((client) => {
        if (client.stateCode) {
          return client.stateCode;
        }

        return [];
      }) ?? []
    ).sort();
  }, [primaryClients]);

  const idsWithHypotheticalTransfers = useMemo(() => {
    return uniq(
      getNodes(waterfall?.hypotheticalTransfers).reduce<string[]>((acc, ht) => {
        return acc.concat(
          compact([
            ht.sourceEntity?.id,
            ht.sourceIndividual?.id,
            ht.destinationEntity?.id,
            ht.destinationIndividual?.id,
            ht.destinationOrganization?.id,
          ])
        );
      }, [])
    );
  }, [waterfall?.hypotheticalTransfers]);

  const {
    beforeFirstDeathTaxSummary: beforeFirstDeathTaxSummaryProjected,
    firstDeathTaxSummary: firstDeathTaxSummaryProjected,
    secondDeathTaxSummary: secondDeathTaxSummaryProjected,
  } = waterfall?.visualizationWithProjections ?? {};

  const {
    beforeFirstDeathTaxSummary,
    firstDeathTaxSummary,
    secondDeathTaxSummary,
  } = waterfall?.visualization ?? {};

  return useMemo(() => {
    const firstDeathGrantor = grantors.find(
      (g) => g.id === waterfall?.firstGrantorDeath.id
    );
    const secondDeathGrantor = grantors.find(
      (g) => g.id !== waterfall?.firstGrantorDeath.id
    );

    // This is the version of the visualization to use in order to construct all the rows.
    const viz = waterfall?.visualizationWithProjections;

    const nodes = viz?.nodes ?? [];

    const currentEligible = nodes.filter(
      (n) => n.afterDeath === AfterDeath.None
    );
    const firstEligible = nodes.filter(
      (n) => n.afterDeath === AfterDeath.First
    );
    const secondEligible = nodes.filter(
      (n) => n.afterDeath === AfterDeath.Second
    );

    // For each section, we also want the no-growth node values, so we can compare the asset values
    // with and without the projected growth. The node ids SHOULD all exist in the maps, but just
    // in case they don't, we'll allow the `noGrowthNode` to be undefined in `mapVizNodeToTableItem`,
    // in which case, we just use the node's value.
    const currentNoGrowthNodes = keyBy(
      waterfall?.visualization.nodes.filter(
        (n) => n.afterDeath === AfterDeath.None
      ),
      'id'
    );
    const firstNoGrowthNodes = keyBy(
      waterfall?.visualization.nodes.filter(
        (n) => n.afterDeath === AfterDeath.First
      ),
      'id'
    );
    const secondNoGrowthNodes = keyBy(
      waterfall?.visualization.nodes.filter(
        (n) => n.afterDeath === AfterDeath.Second
      ),
      'id'
    );

    return {
      [AfterDeath.None]: [
        ...currentEligible.map((node) =>
          mapVizNodeToTableItem({
            node,
            colorChoices,
            noGrowthNode: currentNoGrowthNodes[node.id],
            afterDeath: AfterDeath.None,
            hasHypotheticalTransfer:
              !!node.id && idsWithHypotheticalTransfers.includes(node.id),
          })
        ),
        ...makeTaxRows({
          taxSummary: beforeFirstDeathTaxSummary,
          taxSummaryProjection: beforeFirstDeathTaxSummaryProjected,
          afterDeath: AfterDeath.None,
          stateCodesFromClientAddress: [],
          availableStateEstateTaxes,
        }),
      ],
      [AfterDeath.First]: [
        ...firstEligible.map((node) =>
          mapVizNodeToTableItem({
            node,
            colorChoices,
            grantorName: firstDeathGrantor?.displayName,
            noGrowthNode: firstNoGrowthNodes[node.id],
            afterDeath: AfterDeath.First,
          })
        ),
        ...makeTaxRows({
          taxSummary: firstDeathTaxSummary,
          taxSummaryProjection: firstDeathTaxSummaryProjected,
          afterDeath: AfterDeath.First,
          stateCodesFromClientAddress,
          availableStateEstateTaxes,
        }),
      ],
      [AfterDeath.Second]: [
        ...secondEligible.map((node) =>
          mapVizNodeToTableItem({
            node,
            colorChoices,
            grantorName: secondDeathGrantor?.displayName,
            noGrowthNode: secondNoGrowthNodes[node.id],
            afterDeath: AfterDeath.Second,
          })
        ),
        ...makeTaxRows({
          taxSummary: secondDeathTaxSummary,
          taxSummaryProjection: secondDeathTaxSummaryProjected,
          afterDeath: AfterDeath.Second,
          stateCodesFromClientAddress,
          availableStateEstateTaxes,
        }),
      ],
    };
  }, [
    availableStateEstateTaxes,
    beforeFirstDeathTaxSummary,
    beforeFirstDeathTaxSummaryProjected,
    colorChoices,
    firstDeathTaxSummary,
    firstDeathTaxSummaryProjected,
    grantors,
    idsWithHypotheticalTransfers,
    makeTaxRows,
    secondDeathTaxSummary,
    secondDeathTaxSummaryProjected,
    stateCodesFromClientAddress,
    waterfall?.firstGrantorDeath.id,
    waterfall?.visualization.nodes,
    waterfall?.visualizationWithProjections,
  ]);
}
