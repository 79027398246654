import { Box, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Accordion } from '@/components/Accordion/Accordion';
import { PopperContent } from '@/components/poppers/PopperContent';
import { Loader } from '@/components/progress/Loader/Loader';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import {
  AssetGrowthRateField,
  CustomGlobalGrowthRateToggle,
  ExemptionGrowthRateField,
  ExemptionSunsetField,
  FirstGrantorDeathField,
  SecondGrantorDeathField,
} from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/EstateWaterfallAssumptionFormFields';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';

interface EstateWaterfallAssumptionsAccordionProps {
  loading?: boolean;
  // if true, cannot toggle expand/collapse. used while loading initial values
  disabled?: boolean;
  submitButton?: React.ReactNode;
  resetAssumptionsButton?: React.ReactNode;
  isTwoGrantor?: boolean;
  assumptionsCopy: string;
  assumptionsHelpCopy: string;
}

const ASSUMPTIONS_QUERY_PARAM = 'assumptions';
const ASSUMPTIONS_OPEN_QUERY_PARAM_VALUE = 'open';

/**
 * EstateWaterfallAssumptionsAccordion presents an accordion containing the "assumption" fields when creating / editing
 * an Estate Waterfall.
 *
 * @param loading
 * @param disabled
 * @param submitButton
 * @param resetAssumptionsButton
 * @param isTwoGrantor
 */
export const EstateWaterfallAssumptionsAccordion = ({
  loading,
  disabled,
  submitButton,
  resetAssumptionsButton,
  isTwoGrantor = false,
  assumptionsCopy,
  assumptionsHelpCopy,
}: EstateWaterfallAssumptionsAccordionProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const subtitle = (
    <Stack pl={1} justifyContent="center">
      <Typography variant="subtitle2">{assumptionsCopy}</Typography>
    </Stack>
  );

  return (
    <Accordion
      variant="filled"
      data-testid="EstateWaterfallAssumptionsAccordion"
      title={
        <Stack flexDirection="row">
          Manage assumptions
          <ContextualHelpTooltip>
            <PopperContent body={assumptionsHelpCopy} />
          </ContextualHelpTooltip>
        </Stack>
      }
      subtitle={subtitle}
      hideSubtitleOnOpen
      labelContainerProps={{ direction: 'row' }}
      isDefaultExpanded={
        searchParams.get(ASSUMPTIONS_QUERY_PARAM) ===
        ASSUMPTIONS_OPEN_QUERY_PARAM_VALUE
      }
      onChange={(expanded) => {
        if (expanded) {
          setSearchParams((prevParams) => {
            prevParams.set(
              ASSUMPTIONS_QUERY_PARAM,
              ASSUMPTIONS_OPEN_QUERY_PARAM_VALUE
            );

            return prevParams;
          });
        } else {
          if (searchParams.has(ASSUMPTIONS_QUERY_PARAM)) {
            searchParams.delete(ASSUMPTIONS_QUERY_PARAM);
            setSearchParams(searchParams);
          }
        }
      }}
    >
      {loading ? (
        <Loader
          boxProps={{
            sx: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            },
          }}
          circularProgressProps={{ size: 40 }}
        />
      ) : (
        <EditEstateWaterfallAssumptionsLayout
          disabled={!!disabled}
          submitButton={submitButton}
          resetAssumptionsButton={resetAssumptionsButton}
          isTwoGrantor={isTwoGrantor}
        />
      )}
    </Accordion>
  );
};

interface LayoutProps {
  isTwoGrantor?: boolean;
}

interface EditLayoutProps extends LayoutProps {
  // if true, cannot toggle expand/collapse. used while loading initial values
  disabled: boolean;
  submitButton: React.ReactNode;
  resetAssumptionsButton: React.ReactNode;
}

const EditEstateWaterfallAssumptionsLayout = ({
  disabled,
  submitButton,
  resetAssumptionsButton,
  isTwoGrantor = false,
}: EditLayoutProps) => {
  const customGrowthRatesEnabled = useFeatureFlag('custom_growth_rates');
  const isLessThanMedium = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const numInputs = isTwoGrantor ? 4 : 3;

  if (!customGrowthRatesEnabled) {
    return (
      <Stack spacing={2}>
        <Box
          sx={{
            display: 'grid',
            gap: 1,
            gridTemplateColumns: isLessThanMedium
              ? 'repeat(2, 1fr)'
              : `repeat(${numInputs}, 1fr)`,
            columnGap: 3,
          }}
        >
          <AssetGrowthRateField disabled={disabled} />
          <ExemptionGrowthRateField disabled={disabled} />
          <FirstGrantorDeathField disabled={disabled} />
          {isTwoGrantor && <SecondGrantorDeathField disabled={disabled} />}
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ExemptionSunsetField disabled={disabled} />
          <Stack direction="row" spacing={2}>
            {resetAssumptionsButton}
            {submitButton}
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={3} alignItems="start">
        <Box>
          <FirstGrantorDeathField disabled={disabled} />
        </Box>
        {isTwoGrantor && (
          <Box>
            <SecondGrantorDeathField disabled={disabled} />
          </Box>
        )}
        <Box>
          <ExemptionGrowthRateField disabled={disabled} />
        </Box>
        <Box pt={4}>
          <ExemptionSunsetField disabled={disabled} />
        </Box>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <CustomGlobalGrowthRateToggle disabled={disabled} />
        <Stack direction="row" spacing={2}>
          {resetAssumptionsButton}
          {submitButton}
        </Stack>
      </Stack>
    </Stack>
  );
};
