import { Box } from '@mui/material';
import { Stack } from '@mui/system';

import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { DocumentUploader } from '@/modules/documents/DocumentUploader/DocumentUploader';
import { CreateDocumentInput } from '@/types/schema';

import { Purpose } from '../Purpose';
import { NAMESPACE } from './LogNewTransferModal.constants';
import {
  LogNewTransferForm,
  LogNewTransferFormPaths,
} from './LogNewTransferModal.types';
import { SourceRecipientInput } from './SourceRecipientInput';

const getFieldName = (field: keyof LogNewTransferForm[typeof NAMESPACE]) =>
  `${NAMESPACE}.${field}` as const satisfies LogNewTransferFormPaths;

export function LogNewTransferModalForm() {
  const { control } = useFormContext<LogNewTransferForm>();

  return (
    <Stack flex={1} sx={{ minHeight: '450px' }}>
      <FormLayoutRow>
        <FormLayoutItem>
          <SourceRecipientInput />
        </FormLayoutItem>
      </FormLayoutRow>

      <FormLayoutRow>
        <FormLayoutItem>
          <Stack direction="row">
            <Box sx={{ mr: 3 }} flexGrow={1}>
              <FormAwareCurrencyInput
                isDecimalJSInput
                label="Amount"
                fieldName={getFieldName('amount')}
                control={control}
                required
              />
            </Box>
            <FormAwareDatePickerInput
              label="Transaction date"
              fieldName={getFieldName('transactionDate')}
              control={control}
              required
            />
          </Stack>
        </FormLayoutItem>
      </FormLayoutRow>

      <Purpose />

      <FormLayoutRow>
        <FormLayoutItem>
          <DocumentUploader
            householdId={''}
            getCreateDocumentInput={function (): CreateDocumentInput {
              throw new Error('Function not implemented.');
            }}
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </Stack>
  );
}
