import Decimal from 'decimal.js';

import { getTypeOrUndefined } from '@/modules/entities/EntitySubforms/utils/shared/common.utils';
import {
  AugmentedCreateCashflowInput,
  AugmentedUpdateCashflowInput,
} from '@/types/schema';

import {
  CashflowFrequency,
  CashflowIncomeOrExpsense,
  CashflowModalFormShape,
  END_AT_BOTH_DEATHS_SENTINEL,
} from './CashflowModal.types';
import { Cashflows_CashflowFragment } from './graphql/Cashflows.generated';

export function private_getEndYearPayload({
  targetId,
  frequency,
  startYear,
  endYear,
  endAtTermination,
  endAtDeathClientProfileId,
}: CashflowModalFormShape): {
  afterBothDeaths?: boolean;
  endAtClientsDeathID?: string;
  endYear?: number;
} {
  const isClientTarget = getTargetType(targetId) === TargetType.ClientProfile;
  if (frequency === CashflowFrequency.OneTime) return { endYear: startYear };

  if (!endAtTermination) return { endYear };

  if (
    endAtTermination &&
    endAtDeathClientProfileId === END_AT_BOTH_DEATHS_SENTINEL &&
    isClientTarget
  )
    return { afterBothDeaths: true };

  if (endAtDeathClientProfileId && isClientTarget)
    return { endAtClientsDeathID: endAtDeathClientProfileId };

  return {};
}

function getTargetPayload({ targetId }: CashflowModalFormShape): {
  entityID?: string;
  individualID?: string;
} {
  const targetType = getTargetType(targetId);

  if (targetType === TargetType.ClientProfile)
    return { individualID: targetId };

  if (targetType === TargetType.Entity) return { entityID: targetId };

  return {};
}

export function mapFormDataToUpdatePayload(
  formData: CashflowModalFormShape,
  cashflowId: string
): AugmentedUpdateCashflowInput {
  const { startYear, growthRate, amount, incomeOrExpense, name } = formData;
  const { afterBothDeaths, endAtClientsDeathID, endYear } =
    private_getEndYearPayload(formData);

  const { entityID, individualID } = getTargetPayload(formData);

  return {
    id: cashflowId,
    update: {
      displayName: name,
      entityID,
      individualID,
      clearEntity: !entityID,
      clearIndividual: !individualID,
      afterBothDeaths,
      endAtClientsDeathID,
      endYear,
      clearEndYear: !endYear,
      clearEndAtClientsDeath: !endAtClientsDeathID,
      clearAfterBothDeaths: !afterBothDeaths,
      startYear,
      growthPercent: getTypeOrUndefined(growthRate),
      transferValue: amount?.times(
        incomeOrExpense === CashflowIncomeOrExpsense.Expense ? -1 : 1
      ),
    },
  };
}

export function mapFormDataToCreatePayload(
  formData: CashflowModalFormShape,
  householdId: string | null
): AugmentedCreateCashflowInput {
  const targetPayload = getTargetPayload(formData);
  const endYearPayload = private_getEndYearPayload(formData);

  const { startYear, growthRate, amount, incomeOrExpense, name } = formData;

  if (!householdId) {
    throw new Error('No household available to create cashflow');
  }

  return {
    create: {
      ...targetPayload,
      ...endYearPayload,
      displayName: name,
      householdID: householdId,
      startYear: startYear,
      growthPercent: getTypeOrUndefined(growthRate),
      transferValue: amount?.times(
        incomeOrExpense === CashflowIncomeOrExpsense.Expense ? -1 : 1
      ),
    },
  };
}

export enum TargetType {
  Entity = 'entity',
  ClientProfile = 'cp',
}

export function getTargetType(targetId: string): TargetType | null {
  if (targetId.toLowerCase().startsWith('entity_')) {
    return TargetType.Entity;
  } else if (targetId.toLowerCase().startsWith('cp_')) {
    return TargetType.ClientProfile;
  }

  return null;
}

export function mapDataToCashflowModal(
  data: Cashflows_CashflowFragment
): CashflowModalFormShape {
  let endAtDeath: CashflowModalFormShape['endAtDeathClientProfileId'] = '';

  if (data.endAtClientsDeath?.id) {
    endAtDeath = data.endAtClientsDeath.id;
  } else if (data.afterBothDeaths) {
    endAtDeath = END_AT_BOTH_DEATHS_SENTINEL;
  }

  let endAtTermination: CashflowModalFormShape['endAtTermination'] = false;
  if (
    (!data.endYear && data.entity) ||
    data.afterBothDeaths ||
    data.endAtClientsDeath
  ) {
    endAtTermination = true;
  }

  return {
    amount: (data.transferValue || new Decimal(0)).abs(),
    endAtDeathClientProfileId: endAtDeath,
    name: data.displayName,
    targetId: data.individual?.id || data.entity?.id || '',
    growthRate: data.growthPercent || new Decimal(0),
    frequency:
      data.startYear === data.endYear
        ? CashflowFrequency.OneTime
        : CashflowFrequency.Annual,
    startYear: data.startYear || new Date().getFullYear(),
    endAtTermination,
    endYear: data.endYear || new Date().getFullYear(),
    incomeOrExpense: (
      data.transferValue || new Decimal(0)
    ).greaterThanOrEqualTo(0)
      ? CashflowIncomeOrExpsense.Income
      : CashflowIncomeOrExpsense.Expense,
  };
}
