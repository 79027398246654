import { compact } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { CharitableTrustTermKind } from '@/types/schema';

import {
  CharitableTrustDesignerAnalysisForm,
  NAMESPACE,
} from '../CharitableTrustDesignerAnalysis.types';

export const CHARITABLE_DESIGNER_LIFETIME_MAX_AGE = 109;

// for lifetime trusts, only fetch the model if all donors have valid birth years
export function useDonorsYearOfBirth(): number[] | undefined {
  const { control } = useFormContext<CharitableTrustDesignerAnalysisForm>();
  const [termKind, donors] = useWatch({
    control,
    name: [`${NAMESPACE}.term.kind`, `${NAMESPACE}.term.donors`],
  });

  const currentYear = new Date().getFullYear();
  const minYOB = currentYear - CHARITABLE_DESIGNER_LIFETIME_MAX_AGE;

  const donorsYearOfBirth: number[] = useMemo(() => {
    return compact(
      donors.map((donor) => {
        const donorYOB = donor.dateOfBirth?.getFullYear();
        if (!donorYOB) return null;
        if (minYOB < donorYOB && donorYOB < currentYear) return donorYOB;
        return null;
      })
    );
  }, [currentYear, donors, minYOB]);

  // valid cases: either the term is fixed (and donor years of birth don't matter) or
  // the term is lifetime and all donors have valid years of birth
  const haveYOBForAllDonors = donorsYearOfBirth.length === donors.length;
  if (termKind === CharitableTrustTermKind.Fixed || haveYOBForAllDonors) {
    return donorsYearOfBirth;
  }

  // if the term is lifetime and not all donors have valid years of birth, return `undefined`
  // so downstream code can skip making the call
  return undefined;
}
