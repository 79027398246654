import { useApolloClient } from '@apollo/client';
import { Stack, Typography } from '@mui/material';
import dynamic from 'next/dynamic';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import {
  CheckboxListWrapper,
  FormAwareCheckboxListItem,
} from '@/components/form/formAwareInputs/FormAwareCheckboxList/FormAwareCheckboxList';
import { Modal } from '@/components/modals/Modal/Modal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { getGuardedNodeType } from '@/graphql/types';
import { ClientProfileModal } from '@/modules/clientProfiles/ClientProfileForm/ClientProfileModal';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { AddItemButton } from './AddItemButton';
import {
  AdvisorClientForBeneficiariesModalFragment,
  GetBeneficiariesPickerModalOptionsDocument,
  GetBeneficiariesPickerModalOptionsQuery,
  useGetBeneficiariesPickerModalOptionsQuery,
} from './graphql/BeneficiariesPickerModalOptions.generated';
import { FormType, Option, TAB_OPTIONS, TABS } from './types';
import { getInitialOptions, getSelectedIds } from './utils';

const CreateEntityShortFormModal = dynamic(
  () =>
    import(
      '@/modules/entities/EntityShortFormModal/CreateEntityShortFormModal'
    ).then((mod) => mod.CreateEntityShortFormModal),
  {
    ssr: false,
  }
);

interface Props {
  householdId: string;
  selectedIds: string[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  returnSelectedIds: (selectedIds: {
    trusts: string[];
    individuals: string[];
  }) => void;
  variant?: 'canNotCreateItems' | 'canCreateItems';
  grantorProfileId: string;
}

type TabIndex = 0 | 1;

export function BeneficiariesPickerModal({
  householdId,
  grantorProfileId,
  selectedIds,
  isOpen,
  setIsOpen,
  returnSelectedIds,
  variant,
}: Props) {
  const apolloClient = useApolloClient();
  const [tabIdx, setTabIdx] = useState<TabIndex>(0);
  const [isAddEntityModalOpen, setIsAddEntityModalOpen] = useState(false);
  const [isIndividualModalOpen, setIsIndividualModalOpen] = useState(false);
  const [beneficiaryOptions, setBeneficiaryOptions] = useState<{
    trusts: Option[];
    individuals: Option[];
  }>({
    trusts: [],
    individuals: [],
  });

  const { createErrorFeedback } = useFeedback();
  const formMethods = useForm<FormType>({
    defaultValues: {
      selectedTrusts: [],
      selectedIndividuals: [],
    },
  });

  const { control, reset, watch, handleSubmit } = formMethods;

  const onValidSubmit: SubmitHandler<FormType> = (values) => {
    const selectedIds = getSelectedIds(values);

    returnSelectedIds(selectedIds);
    setIsOpen(false);
  };

  const onSubmit = handleSubmit(onValidSubmit);

  const selectedTrusts = watch('selectedTrusts');
  const selectedIndividuals = watch('selectedIndividuals');

  const { data, loading } = useGetBeneficiariesPickerModalOptionsQuery({
    variables: {
      householdId,
    },
    onError: createErrorFeedback(
      "We weren't able to fetch possible beneficiaries. Please refresh the page to try again."
    ),
  });

  useEffect(
    function initializeOptions() {
      if (data) {
        const household = getGuardedNodeType<
          GetBeneficiariesPickerModalOptionsQuery['node'],
          AdvisorClientForBeneficiariesModalFragment
        >(data.node, 'Household');

        if (!household) {
          return;
        }

        const allBeneficiaries = household.possibleBeneficiaries;
        const allTrusts = household.entities ?? [];

        const initialOptions = getInitialOptions(
          allBeneficiaries,
          allTrusts,
          grantorProfileId
        );

        setBeneficiaryOptions(initialOptions);

        reset({
          selectedTrusts: initialOptions.trusts.map((option) => ({
            id: option.id,
            selected: selectedIds.includes(option.id),
          })),
          selectedIndividuals: initialOptions.individuals.map((option) => ({
            id: option.id,
            selected: selectedIds.includes(option.id),
          })),
        });
      }
    },
    [data, setBeneficiaryOptions, reset, selectedIds, grantorProfileId]
  );

  const actions = useMemo(() => {
    return (
      <Stack direction="row" spacing={2}>
        <Button size="sm" variant="secondary" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          disabled={loading}
          size="sm"
          variant="primary"
          onClick={onSubmit}
        >
          Save
        </Button>
      </Stack>
    );
  }, [setIsOpen, loading, onSubmit]);

  const currentTab = TABS[tabIdx].value;
  const currentFormNamespace =
    currentTab === 'trusts' ? 'selectedTrusts' : 'selectedIndividuals';

  const addItemText = useMemo(() => {
    if (currentTab === 'trusts') {
      return 'Add a new trust';
    }

    return 'Add a new individual beneficiary';
  }, [currentTab]);

  function handleEntityTrowserClose() {
    setIsAddEntityModalOpen(false);
  }

  function handleEntityCreation() {
    void apolloClient.refetchQueries({
      include: [GetBeneficiariesPickerModalOptionsDocument],
    });
  }

  const selectedCount = [...selectedTrusts, ...selectedIndividuals].filter(
    (option) => option.selected
  ).length;

  return (
    <FormProvider {...formMethods}>
      <CreateEntityShortFormModal
        isOpen={isAddEntityModalOpen}
        onClose={handleEntityTrowserClose}
        onAfterCreate={handleEntityCreation}
        householdId={householdId}
        initialEntityType={ENTITY_TYPES.IRREVOCABLE_TRUST}
      />
      <ClientProfileModal
        setIsOpen={setIsIndividualModalOpen}
        isOpen={isIndividualModalOpen}
        clientProfileId={null}
        householdId={householdId}
        clientProfileTypeDisplay="beneficiary"
        forceBeneficiaryIfNoClientProfileId
      />

      <Modal
        rightHeaderContent={
          <Typography variant="subtitle2">
            {`${selectedCount} selected`}
          </Typography>
        }
        isOpen={isOpen}
        heading="Available beneficiaries"
        onClose={() => setIsOpen(false)}
        actions={actions}
      >
        <Stack spacing={3} flex={1} sx={{ minHeight: 450 }}>
          <ButtonGroup
            onChange={(_, value) => {
              const idx = TABS.findIndex((option) => {
                if (option.value === value) {
                  return true;
                }

                return false;
              }) as TabIndex;

              setTabIdx(idx);
            }}
            label=""
            options={TAB_OPTIONS}
            value={currentTab}
          />

          <CheckboxListWrapper>
            {beneficiaryOptions[currentTab]?.map(
              ({ id, displayName, description, summary }, idx) => {
                return (
                  <FormAwareCheckboxListItem<FormType>
                    key={id}
                    fieldName={
                      `${currentFormNamespace}.${idx}.selected` as const
                    }
                    title={displayName}
                    lineTwo={description}
                    lineThree={summary}
                    control={control}
                  />
                );
              }
            )}
          </CheckboxListWrapper>
          {variant === 'canCreateItems' && (
            <AddItemButton
              setIsIndividualModalOpen={setIsIndividualModalOpen}
              setIsTrustModalOpen={setIsAddEntityModalOpen}
              currentTab={currentTab}
              addItemText={addItemText}
            />
          )}
        </Stack>
      </Modal>
    </FormProvider>
  );
}
