import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export interface ClientProfileDetailsContext {
  clientProfileId: string | null;
  setClientProfileId: (clientProfileId: string | null) => void;
}

export const ClientProfileDetailsContext =
  createContext<ClientProfileDetailsContext>({
    clientProfileId: null,
    setClientProfileId: () => null,
  });

export const useClientProfileDetailsContext = () => {
  return useGuardedContext(
    ClientProfileDetailsContext,
    'ClientProfileDetailsProvider'
  );
};
