import { SxProps, Theme } from '@mui/material';

import { zIndexes } from '@/styles/zIndexes';

import { Dialog } from '../Dialog';

export type FullScreenModalProps = React.PropsWithChildren<{
  onClose?: () => void;
  isOpen?: boolean;
  dialogSx?: SxProps<Theme>;
  backdropSx?: SxProps<Theme>;
}>;

export function FullScreenModal({
  isOpen,
  onClose,
  children,
  backdropSx = {},
  dialogSx = {},
}: FullScreenModalProps) {
  return (
    <Dialog
      fullScreen
      open={!!isOpen}
      PaperProps={{ sx: backdropSx }}
      sx={{ zIndex: zIndexes.MODAL, ...dialogSx }}
      onClose={onClose}
    >
      {children}
    </Dialog>
  );
}
