import { cx } from '@emotion/css';
import { Typography, TypographyProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import {
  TypographyClamped,
  TypographyClampedProps,
} from '@/components/typography/TypographyClamped';

import { getTextColorForVariant } from './Tile.utils';
import { TileVariant } from './types';

export interface TileThemedTypographyProps extends TypographyProps {
  tileVariant: TileVariant;
}

const useStyles = makeStyles<{ tileVariant: TileVariant }>()((
  theme,
  { tileVariant }
) => {
  return {
    root: {
      color: getTextColorForVariant(theme, tileVariant),
    },
  };
});

export const TileThemedTypography = ({
  tileVariant,
  className,
  ...otherProps
}: TileThemedTypographyProps) => {
  const { classes } = useStyles({ tileVariant });

  return <Typography {...otherProps} className={cx(classes.root, className)} />;
};

export interface TileThemedTypographyClampedProps
  extends TypographyClampedProps {
  tileVariant: TileVariant;
}

export const TileThemedTypographyClamped = ({
  tileVariant,
  className,
  ...otherProps
}: TileThemedTypographyClampedProps) => {
  const { classes } = useStyles({ tileVariant });

  return (
    <TypographyClamped
      {...otherProps}
      className={cx(classes.root, className)}
    />
  );
};
