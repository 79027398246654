/**
 * Note that this ID and these statuses are referenced from the printing Lambda, so please
 * be careful about making any changes and ensure they're backwards-compatible
 */
export const PRINT_STATUS_SENTINEL_ID = 'print-status-sentinel';

export enum PrintStatuses {
  LOADING = 'loading',
  ERROR = 'error',
  READY = 'ready',
}
