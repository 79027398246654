import { Stack, Typography } from '@mui/material';
import { BaseSyntheticEvent, useCallback } from 'react';
import { useFormState } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useModalState } from '@/hooks/useModalState';
import { COLORS } from '@/styles/tokens/colors';

import { GiftDesignerModelScenariosFormShape } from '../../GiftDesignerModelScenariosForm.types';
import { ScenarioGiftModal } from '../ScenarioGiftModal/ScenarioGiftModal';
import { ScenarioIncomeAndExpensesModal } from '../ScenarioIncomeAndExpensesModal/ScenarioIncomeAndExpensesModal';
import {
  GiftListItem,
  GiftScenarioListItem,
  IncomeOrExpenseListItem,
} from './GiftScenarioListItem';
import { useGiftScenarioListData } from './useGiftScenarioListData';

export enum GiftScenarioTab {
  GIFT = 'gift',
  INCOME_OR_EXPENSE = 'income-or-expense',
}

interface GiftScenarioTabContentProps {
  tabKey: GiftScenarioTab;
  scenarioIdx: number;
  triggerSave: (
    e?: BaseSyntheticEvent<object, unknown, unknown> | undefined
  ) => Promise<void>;
  proposalId: string;
}

export function GiftScenarioTabContent({
  tabKey,
  scenarioIdx,
  triggerSave,
  proposalId,
}: GiftScenarioTabContentProps) {
  const isGift = tabKey === GiftScenarioTab.GIFT;
  const [{ isModalOpen, data: modalData }, { openModal, closeModal }] =
    useModalState<
      | (GiftListItem & { idx: number })
      | (IncomeOrExpenseListItem & { idx: number })
      | null
    >();

  const { isLoading } = useFormState<GiftDesignerModelScenariosFormShape>();

  const { giftFieldArrayMethods, incomeAndExpensesFieldArrayMethods } =
    useGiftScenarioListData(scenarioIdx);

  const {
    fields: gifts,
    remove: removeGift,
    append: appendGift,
    update: updateGift,
  } = giftFieldArrayMethods;
  const {
    fields: incomeAndExpenses,
    remove: removeIncomeOrExpense,
    append: appendIncomeOrExpense,
    update: updateIncomeOrExpense,
  } = incomeAndExpensesFieldArrayMethods;

  const handleAddClick = useCallback(() => {
    openModal();
  }, [openModal]);

  const currentList = isGift ? gifts : incomeAndExpenses;

  return (
    <>
      {isGift ? (
        <ScenarioGiftModal
          giftData={(modalData as GiftListItem) ?? null}
          isOpen={isModalOpen}
          proposalId={proposalId}
          onClose={() => {
            closeModal();
          }}
          onSubmitSuccess={async (formData) => {
            if (modalData?.idx !== undefined) {
              updateGift(modalData.idx, formData);
            } else {
              appendGift(formData);
            }

            await triggerSave();
            closeModal();
          }}
          onDelete={async () => {
            if (modalData?.idx !== undefined) {
              removeGift(modalData.idx);
            }

            await triggerSave();
            closeModal();
          }}
        />
      ) : (
        <ScenarioIncomeAndExpensesModal
          incomeData={(modalData as IncomeOrExpenseListItem) ?? null}
          isOpen={isModalOpen}
          proposalId={proposalId}
          onClose={() => {
            closeModal();
          }}
          onSubmitSuccess={async (formData) => {
            if (modalData?.idx !== undefined) {
              updateIncomeOrExpense(modalData.idx, formData);
            } else {
              appendIncomeOrExpense(formData);
            }

            await triggerSave();
            closeModal();
          }}
          onDelete={async () => {
            if (modalData?.idx !== undefined) {
              removeIncomeOrExpense(modalData.idx);
            }

            await triggerSave();
            closeModal();
          }}
        />
      )}

      <Stack spacing={2}>
        {currentList.length > 0 ? (
          <Stack
            spacing={1}
            data-testid={`GiftScenarioTabContent-${
              isGift ? 'gift' : 'cash flow'
            }`}
          >
            <Typography variant="h6">
              {isGift ? 'Gifts' : 'Cash flows'}
            </Typography>
            {currentList.map((item, idx) => (
              <GiftScenarioListItem
                key={item._id}
                item={item}
                handleEditClick={(item) => openModal({ ...item, idx })}
              />
            ))}
          </Stack>
        ) : (
          <Typography
            color={COLORS.GRAY[500]}
            textAlign="center"
            py={2}
            variant="body1"
          >
            {isGift ? 'No gifts added' : 'No cash flows added'}
          </Typography>
        )}
        <Button
          size="lg"
          fullWidth
          variant="secondary"
          startIcon={PlusIcon}
          onClick={handleAddClick}
          disabled={isLoading}
        >
          {isGift ? 'Add gift' : 'Add cash flow'}
        </Button>
      </Stack>
    </>
  );
}
