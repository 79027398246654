import { Box, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import {
  FullScreenModal,
  FullScreenModalProps,
} from '@/components/modals/FullScreenModal/FullScreenModal';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { DEFAULT_ASSUMPTIONS } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/constants';
import { COLORS } from '@/styles/tokens/colors';

import { mapInputToFormData } from './CreateEstateWaterfallModal.utils';
import {
  EstateWaterfallForm,
  EstateWaterfallFormProps,
} from './EstateWaterfallForm';
import { EstateWaterfallTrowserLayout } from './EstateWaterfallTrowserLayout';
import { useEditEstateWaterfall } from './hooks/useEditEstateWaterfall';
import { useWaterfallModalQuery } from './hooks/useWaterfallModalQuery';
import { EstateWaterfallFormData } from './types';

interface EditEstateWaterfallProps extends FullScreenModalProps {
  householdId: string;
  grantors: EstateWaterfallFormProps['grantors'];
  sourceEstateWaterfalls: EstateWaterfallFormProps['sourceEstateWaterfalls'];
  defaultSelectedRowIds?: string[];
  waterfallId: string;
}

export type EditEstateWaterfallTrowserProps = {
  waterfallFilter: EstateWaterfallFormData['waterfallFilter'];
  waterfallName: string;
  sourceWaterfallId?: string;
} & EditEstateWaterfallProps;

function EditEstateWaterfall({
  waterfallId,
  householdId,
  grantors,
  sourceEstateWaterfalls,
  defaultSelectedRowIds,
  ...fullScreenModalProps
}: EditEstateWaterfallProps) {
  const { onClose } = fullScreenModalProps;
  const { formState, handleSubmit, reset } =
    useFormContext<EstateWaterfallFormData>();

  const { entities, hypotheticalTransfers, loading } = useWaterfallModalQuery({
    householdId,
    waterfallId,
  });

  const { editWaterfall } = useEditEstateWaterfall({
    waterfallId,
  });

  const onSubmit = handleSubmit(editWaterfall);

  const closeModal = () => {
    reset();
    onClose?.();
  };

  useSubmitSuccessHandler(() => {
    onClose?.();
  });

  return (
    <FullScreenModal
      backdropSx={{ bgcolor: COLORS.GRAY[50] }}
      {...fullScreenModalProps}
    >
      <Stack
        component="form"
        onSubmit={onSubmit}
        height="100%"
        noValidate
        pb={`${FOOTER_HEIGHT}px`}
      >
        <EstateWaterfallTrowserLayout>
          <Typography variant="h1" component="h2">
            Edit waterfall
          </Typography>
          <EstateWaterfallForm
            grantors={grantors}
            sourceEstateWaterfalls={sourceEstateWaterfalls}
            shouldShowHypotheticalToggle={false}
            loading={loading}
            entities={entities}
            hypotheticalTransfers={hypotheticalTransfers}
            defaultSelectedRowIds={defaultSelectedRowIds}
            isEdit
            shouldShowAutoGroupToggle={false}
          />
        </EstateWaterfallTrowserLayout>
        <Box flexShrink={0}>
          <Footer
            rightAction={
              <Stack direction="row" spacing={2}>
                <Button
                  variant="secondary"
                  size="sm"
                  loading={formState.isSubmitting}
                  onClick={closeModal}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  size="sm"
                  loading={formState.isSubmitting}
                >
                  Save changes
                </Button>
              </Stack>
            }
          />
        </Box>
      </Stack>
    </FullScreenModal>
  );
}

export const EditEstateWaterfallTrowser = ({
  waterfallId,
  waterfallFilter,
  householdId,
  grantors,
  waterfallName,
  sourceWaterfallId,
  ...props
}: EditEstateWaterfallTrowserProps) => {
  const formMethods = useForm<EstateWaterfallFormData>({
    defaultValues: mapInputToFormData({
      householdId,
      grantors,
      waterfallFilter,
      displayName: waterfallName,
      parentID: sourceWaterfallId,
      ...DEFAULT_ASSUMPTIONS,
    }),
  });

  const defaultSelectedRowIds = useMemo(() => {
    if (!waterfallFilter) return undefined;

    const selectedIds = [
      ...(waterfallFilter.includedClientIDs ?? []),
      ...(waterfallFilter.includedEntityIDs ?? []),
    ];

    return selectedIds;
  }, [waterfallFilter]);

  return (
    <FormProvider {...formMethods}>
      <EditEstateWaterfall
        waterfallId={waterfallId}
        householdId={householdId}
        grantors={grantors}
        defaultSelectedRowIds={defaultSelectedRowIds}
        {...props}
      />
    </FormProvider>
  );
};
