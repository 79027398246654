import { Typography } from '@mui/material';
import { Decimal } from 'decimal.js';

import { StyledTableCell } from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { formatCurrency } from '@/utils/formatting/currency';

interface WaterfallSummaryFooterProps {
  total: Decimal;
}

export function WaterfallSummaryFooter({ total }: WaterfallSummaryFooterProps) {
  return (
    <StyledTableRow sx={{ borderTop: '2px solid' }}>
      <StyledTableCell>
        <Typography fontWeight="bold">Total</Typography>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Typography
          fontWeight="bold"
          color={total.lessThan(0) ? 'error' : undefined}
        >
          {formatCurrency(total, {
            currencySign: 'accounting',
            maximumFractionDigits: 0,
          })}
        </Typography>
      </StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  );
}
