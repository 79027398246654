import { Loader } from '@/components/progress/Loader/Loader';

export function FlowChartLoader() {
  return (
    <Loader
      boxProps={{
        sx: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
      circularProgressProps={{ size: 80 }}
    />
  );
}
