import { cx } from '@emotion/css';
import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import React, { MouseEventHandler } from 'react';
import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';

export interface ActionTileProps {
  icon?: React.ReactNode;
  heading?: React.ReactNode;
  footer?: React.ReactNode;
  containerSx?: SxProps<Theme>;
  innerSx?: SxProps<Theme>;
  spacing?: number;
  onClick: MouseEventHandler<HTMLButtonElement>;
  body?: React.ReactNode;
  variant: 'large' | 'small';
  disabled?: boolean;
  dataTestId?: string;
}

const useStyles = makeStyles<{ disabled?: boolean }>()((
  theme,
  { disabled }
) => {
  const baseStyle: CSSObject = {
    position: 'relative',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    border: `solid ${theme.palette.grey[100]} 1px`,
    boxShadow: theme.palette.shadows.md,
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.grey[50],
    cursor: 'pointer',
    ':hover': {
      backgroundColor: COLORS.PRIMITIVES.WHITE,
      boxShadow: theme.palette.shadows.lg,
      border: `solid ${theme.palette.grey[300]} 1px`,
    },
  };
  if (disabled) {
    return {
      root: {
        ...baseStyle,
        ':hover': undefined,
        boxShadow: 'none',
        backgroundColor: COLORS.PRIMITIVES.WHITE,
        cursor: 'not-allowed',
      },
    };
  }
  return {
    root: baseStyle,
  };
});

export function ActionTile({
  icon,
  heading,
  containerSx,
  innerSx,
  onClick,
  spacing = 1,
  body,
  variant,
  footer,
  disabled = false,
  dataTestId,
}: ActionTileProps) {
  const { classes } = useStyles({ disabled });
  const isLarge = variant === 'large';

  return (
    <Stack
      className={cx(classes.root)}
      component="button"
      onClick={onClick}
      sx={containerSx}
      data-testid={dataTestId}
    >
      <Stack
        spacing={spacing}
        alignItems="center"
        direction="column"
        sx={innerSx}
      >
        {icon && <Box>{icon}</Box>}
        {heading && (
          <Typography
            variant={isLarge ? 'h1' : 'h4'}
            component="span"
            sx={{ color: disabled ? COLORS.GRAY[400] : undefined }}
          >
            {heading}
          </Typography>
        )}
        {body && (
          <Typography
            variant={isLarge ? 'body1' : 'subtitle2'}
            sx={{ color: disabled ? COLORS.GRAY[500] : undefined }}
          >
            {body}
          </Typography>
        )}
      </Stack>
      {footer && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        >
          {footer}
        </Box>
      )}
    </Stack>
  );
}
