import { gql, useLazyQuery } from '@apollo/client';
import { Grid, Stack } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { Button } from '@/components/form/baseInputs/Button';
import { ErrorFeedbackFallback } from '@/components/notifications/Feedback/ErrorFeedbackFallback';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';

function useThrowsError() {
  useEffect(() => {
    throw new Error('useThrowsError error');
  }, []);
}

function RenderError() {
  useThrowsError();

  return <>Render error</>;
}

function ComponentError() {
  const something = null;

  return (
    <div>{(something as unknown as Record<string, string>).something}</div>
  );
}

export function ErrorsSandbox() {
  const { createErrorFeedback } = useFeedback();
  const [showRenderErrorWithBoundary, setShowRenderErrorWithBoundary] =
    useState(false);
  const [showRenderError, setShowRenderError] = useState(false);
  const [showRenderComponentError, setShowRenderComponentError] =
    useState(false);

  const throwError = useCallback(() => {
    throw new Error('Test error');
  }, []);

  const [query] = useLazyQuery(
    gql`
      query Test {
        hello {
          world
        }
      }
    `,
    {
      onError: createErrorFeedback('Query error'),
    }
  );

  if (showRenderErrorWithBoundary) {
    return (
      <ErrorBoundary fallback={<ErrorFeedbackFallback />}>
        <RenderError />
      </ErrorBoundary>
    );
  }

  if (showRenderError) {
    return <RenderError />;
  }

  if (showRenderComponentError) {
    return <ComponentError />;
  }

  return (
    <SubpageLayout heading="Errors sandbox">
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Stack spacing={3}>
            <Button size="md" variant="destructive" onClick={throwError}>
              Throw error
            </Button>
            <Button
              size="md"
              variant="destructive"
              onClick={() => {
                void query();
              }}
            >
              Throw error query
            </Button>
            <Button
              size="md"
              variant="destructive"
              onClick={() => {
                setShowRenderComponentError(true);
              }}
            >
              Component render error
            </Button>
            <Button
              size="md"
              variant="destructive"
              onClick={() => {
                setShowRenderErrorWithBoundary(true);
              }}
            >
              Throw render error with boundary
            </Button>
            <Button
              size="md"
              variant="destructive"
              onClick={() => {
                setShowRenderError(true);
              }}
            >
              Throw render error
            </Button>
            <Button
              external
              size="md"
              variant="destructive"
              href="/i-throw-an-error"
            >
              Throw SSR error
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </SubpageLayout>
  );
}
