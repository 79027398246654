import { IntegrationClientKind } from '@/types/schema';

import { HouseholdHasIntegration_HouseholdFragment } from './graphql/HouseholdHasIntegration.generated';

export function householdHasAddeparIntegration<
  T extends HouseholdHasIntegration_HouseholdFragment,
>(household: T | null | undefined): boolean {
  if (!household) {
    return false;
  }
  return (
    household.integrationClients?.some(
      (integrationClient) =>
        integrationClient.kind === IntegrationClientKind.Addepar
    ) ?? false
  );
}

export function householdHasBlackDiamondIntegration<
  T extends HouseholdHasIntegration_HouseholdFragment,
>(household: T | null | undefined): boolean {
  if (!household) {
    return false;
  }
  return (
    household.integrationClients?.some(
      (integrationClient) =>
        integrationClient.kind === IntegrationClientKind.BlackDiamond
    ) ?? false
  );
}

export function householdHasOrionIntegration<
  T extends HouseholdHasIntegration_HouseholdFragment,
>(household: T | null | undefined): boolean {
  if (!household) {
    return false;
  }
  return (
    household.integrationClients?.some(
      (integrationClient) =>
        integrationClient.kind === IntegrationClientKind.Orion
    ) ?? false
  );
}

export function getHouseholdIntegration<
  T extends HouseholdHasIntegration_HouseholdFragment,
>(household: T | null | undefined): IntegrationClientKind | null {
  if (householdHasAddeparIntegration(household)) {
    return IntegrationClientKind.Addepar;
  }

  if (householdHasBlackDiamondIntegration(household)) {
    return IntegrationClientKind.BlackDiamond;
  }

  if (householdHasOrionIntegration(household)) {
    return IntegrationClientKind.Orion;
  }

  return null;
}
