import {
  CharitableTrustPayoutFrequency,
  CharitableTrustPayoutKind,
} from '@/types/schema';

export const PAYOUT_KIND_COPY: Record<CharitableTrustPayoutKind, string> = {
  [CharitableTrustPayoutKind.Annuity]: 'Annuity',
  [CharitableTrustPayoutKind.Unitrust]: 'Unitrust',
};

export const PAYOUT_FREQUENCY_COPY: Record<
  CharitableTrustPayoutFrequency,
  string
> = {
  [CharitableTrustPayoutFrequency.Annually]: 'Annually',
  [CharitableTrustPayoutFrequency.Semiannually]: 'Semi-annually',
  [CharitableTrustPayoutFrequency.Quarterly]: 'Quarterly',
  [CharitableTrustPayoutFrequency.Monthly]: 'Monthly',
};
