// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { ClientProfileFragmentDoc, ClientProfileMinimalGrantorRelationshipFragmentDoc, RelationshipFragmentDoc, ClientProfileBasicFragmentDoc } from '../../../clientProfiles/graphql/ClientProfile.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateClientProfileMutationVariables = Types.Exact<{
  input: Types.CreateClientProfileWithDetailsInput;
}>;


export type CreateClientProfileMutation = { __typename?: 'Mutation', createClientProfile: { __typename?: 'ClientProfile', id: string, firstName: string, lastName: string, email?: string | null, dateOfBirth?: Time | null, isGrantor: boolean, isTrustee: boolean, isPrimary: boolean, isBeneficiary: boolean, displayName: string, legalName: string, middleName?: string | null, suffix?: string | null, address?: { __typename?: 'Address', id: string, cityName?: string | null, stateCode: string } | null } };


export const CreateClientProfileDocument = gql`
    mutation CreateClientProfile($input: CreateClientProfileWithDetailsInput!) {
  createClientProfile(input: $input) {
    ...ClientProfile
  }
}
    ${ClientProfileFragmentDoc}`;
export type CreateClientProfileMutationFn = Apollo.MutationFunction<CreateClientProfileMutation, CreateClientProfileMutationVariables>;

/**
 * __useCreateClientProfileMutation__
 *
 * To run a mutation, you first call `useCreateClientProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientProfileMutation, { data, loading, error }] = useCreateClientProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientProfileMutation, CreateClientProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientProfileMutation, CreateClientProfileMutationVariables>(CreateClientProfileDocument, options);
      }
export type CreateClientProfileMutationHookResult = ReturnType<typeof useCreateClientProfileMutation>;
export type CreateClientProfileMutationResult = Apollo.MutationResult<CreateClientProfileMutation>;
export type CreateClientProfileMutationOptions = Apollo.BaseMutationOptions<CreateClientProfileMutation, CreateClientProfileMutationVariables>;
export const Operations = {
  Mutation: {
    CreateClientProfile: 'CreateClientProfile' as const
  }
}