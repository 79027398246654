import { Stack } from '@mui/material';
import { Control } from 'react-hook-form';

import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';

import { Fields, NAMESPACE } from '../types';

interface Props {
  id: string; // client profile id or remainder trust id
  heading: string;
  badgeText?: string;
  type: string;
  additionalItems?: (string | null)[];
  control: Control<Fields>;
  itemIdx: number;
}

export function ListItem({
  id,
  heading,
  badgeText,
  type,
  additionalItems,
  control,
  itemIdx,
}: Props) {
  return (
    <Stack
      data-testid={`beneficiary-list-item-${id}`}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <RichListItem
        heading={heading}
        description={type}
        badgeText={badgeText}
        additionalItems={
          (additionalItems?.filter((line) => !!line) as string[]) ?? []
        }
      />

      <div data-testid={`beneficiary-list-item-percent-${itemIdx}`}>
        <FormAwarePercentInput
          fieldName={`${NAMESPACE}.beneficiaries.${itemIdx}.percent`}
          label={`Beneficiary percentage`}
          control={control}
          isDecimalJSInput
          decimalScale={2}
          hideLabel={true}
          required
        />
      </div>
    </Stack>
  );
}
