import { Stack } from '@mui/material';

import { DataTableCard } from '@/components/tables/DataTable/DataTableCard';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { DocumentsTable } from '@/modules/documents/DocumentsTable/DocumentsTable';
import { useClientDocumentsPageContext } from '@/pages/ClientPages/ClientDocumentsPage';

export function ClientDetailsDocumentsPage() {
  const householdId = useRequiredParam('householdId');
  const { modalOpen, setModalOpen } = useClientDocumentsPageContext();

  return (
    <Stack height="100%">
      <DataTableCard>
        <DocumentsTable
          householdId={householdId}
          handleCreateDocumentModalClose={() => setModalOpen(false)}
          createDocumentModalOpen={modalOpen}
          documentsLike={{
            hasHouseholdWith: [
              {
                id: householdId,
              },
            ],
          }}
        />
      </DataTableCard>
    </Stack>
  );
}
