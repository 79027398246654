import { QueryHookOptions } from '@apollo/client';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { getAssetCategoryDisplay } from '@/modules/assets/assetCategoryUtils';
import { AssetV2Fragment } from '@/modules/assets/graphql/Asset.generated';
import { AssetV2Extended } from '@/modules/assetValuation/AssetValuationTable/AssetValuationDisplayTable';
import { AccountCurrentAssetValuationFragment } from '@/modules/assetValuation/graphql/entityValuationQueries.generated';
import {
  AsyncJobStatusQuery,
  AsyncJobStatusQueryVariables,
} from '@/modules/asyncJobs/graphql/AsyncJobs.generated';
import { usePollForJobCompletion } from '@/modules/asyncJobs/hooks/usePollForJobCompletion';
import { AssetClassFragment } from '@/modules/tenant/graphql/TenantInformation.generated';
import { getNodes } from '@/utils/graphqlUtils';

import { EntityDetail_EntityAsyncJobFragment } from '../graphql/EntityDetailPage.generated';

const EMPTY_PREVIEW_ASSETS_RESULT = {
  assets: [],
  valuation: null,
};

/**
 * @description This function is responsible for returning a max of four assets from the current
 * valuation, sorted by highest value. Some integrated accounts have a very high number of assets,
 * and we don't have room to show them all, so we limit them to a max of NUMBER_PREVIEW_ASSETS,
 * but also return the totalCount of assets to indicate to the user whether or not
 * we're showing all of the assets.
 */
export function getSortedAssetsFromAccount(
  account: AccountCurrentAssetValuationFragment | null | undefined,
  assetClassesById: Record<string, AssetClassFragment>
) {
  if (!account) return EMPTY_PREVIEW_ASSETS_RESULT;

  // if we have a valid value, this will return 1 valuation. otherwise, it will return an empty array
  const mostRecentValuations = getNodes(account.valuations);
  const mostRecentValuation = mostRecentValuations[0];
  if (!mostRecentValuation) {
    return EMPTY_PREVIEW_ASSETS_RESULT;
  }

  return {
    assets: getSortedPreviewAssets(
      getNodes(mostRecentValuation.assets),
      assetClassesById
    ),
    valuation: mostRecentValuation,
  };
}

export function getSortedPreviewAssets(
  assets: AssetV2Fragment[],
  assetClassesById: Record<string, AssetClassFragment>
): AssetV2Extended[] {
  const previewAssets: AssetV2Extended[] = assets
    .map((asset) => getExtendedAssetFromFragment(asset, assetClassesById))
    .sort((a, b) => {
      if (a.assetValue.value.greaterThan(b.assetValue.value)) {
        return -1;
      }
      return 1;
    });

  return previewAssets;
}

export function getExtendedAssetFromFragment(
  asset: AssetV2Fragment,
  assetClassesById: Record<string, AssetClassFragment>
): AssetV2Extended {
  const displayCategory = getAssetCategoryDisplay(
    // TODO (T2-2751) make this required
    { categoryId: asset.class?.id },
    assetClassesById
  );
  return {
    ...asset,
    displayCategory,
  };
}

interface HandlePendingIngestJobsOpts
  extends QueryHookOptions<AsyncJobStatusQuery, AsyncJobStatusQueryVariables> {
  onJobComplete: () => void;
}

export function useHandlePendingIngestJobs(
  asyncJobDetails: EntityDetail_EntityAsyncJobFragment | null | undefined,
  { onJobComplete, ...queryOpts }: HandlePendingIngestJobsOpts
) {
  const { showFeedback } = useFeedback();
  const asyncJobNodes = getNodes(asyncJobDetails?.asyncJobs);

  return usePollForJobCompletion(asyncJobNodes[0], {
    onJobComplete,
    onError: () => {
      showFeedback('Failed to fetch live updates for assets sync.');
    },
    ...queryOpts,
  });
}
