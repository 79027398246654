import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';

import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { usePaginatedDataTableQuery } from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { TableEmptyState } from '@/components/tables/DataTable/TableEmptyState';
import { useReportError } from '@/hooks/useReportError';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { ClientPresentationOrderField, OrderDirection } from '@/types/schema';

import { CreatePresentationButton } from '../components/CreatePresentationButton';
import { getColumns, RowData } from './ClientPresentationsTable.constants';
import {
  ClientPresentationsTable_ClientPresentationFragment,
  useClientPresentationsTableQuery,
} from './graphql/ClientPresentationsTable.generated';

export interface ClientPresentationsTableProps {
  householdId: string;
}

function ClientPresentationTableEmptyState({
  householdId,
}: ClientPresentationsTableProps) {
  return (
    <TableEmptyState text="No reports">
      <Box>
        <CreatePresentationButton householdId={householdId} />
      </Box>
    </TableEmptyState>
  );
}

function mapPresentationsToRows(
  presentations: ClientPresentationsTable_ClientPresentationFragment[]
): RowData[] {
  return presentations.map((presentation) => ({
    id: presentation.id,
    name: presentation.name,
    lastModified: presentation.updatedAt,
    createdBy: presentation.createdBy,
    householdId: presentation.household?.id ?? '',
  }));
}

export function ClientPresentationsTable({
  householdId,
}: ClientPresentationsTableProps) {
  const { reportError } = useReportError();
  const navigate = useNavigate();
  const [paginatedTableProps, { data }] = usePaginatedDataTableQuery(
    useClientPresentationsTableQuery,
    {
      pageSize: PageSizes.Ten,
      variables: {
        where: {
          hasHouseholdWith: [
            {
              id: householdId,
            },
          ],
        },
        orderBy: [
          {
            field: ClientPresentationOrderField.UpdatedAt,
            direction: OrderDirection.Desc,
          },
        ],
      },
      onError: (error) => {
        reportError('failed to load client presentations', error);
      },
    }
  );

  const rows = mapPresentationsToRows(data ?? []);

  return (
    <DataTable
      {...paginatedTableProps}
      rows={rows}
      columns={getColumns()}
      slots={{
        noRowsOverlay: () => (
          <ClientPresentationTableEmptyState householdId={householdId} />
        ),
      }}
      onRowClick={({ row }: GridRowParams<RowData>) =>
        navigate(
          getCompletePathFromRouteKey(
            ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATION,
            {
              householdId,
              presentationId: row.id,
            }
          )
        )
      }
    />
  );
}
