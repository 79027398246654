import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { sumDecimalJS } from '@/utils/decimalJSUtils';

export function useShowMoreSplit<Entity extends { value: Decimal | number }>(
  entities: Entity[]
) {
  return useMemo(() => {
    const nonZeroEntities = entities
      .filter((e) => new Decimal(e.value).toNumber() > 0)
      .sort((a, b) =>
        new Decimal(a.value).greaterThan(new Decimal(b.value)) ? -1 : 1
      );

    // If there are 3 entities, show all 3. Otherwise, show 2, and +2 more... as the remaining entities.
    const visibleEntitiesIndex = nonZeroEntities.length === 3 ? 3 : 2;

    const visible = nonZeroEntities.slice(0, visibleEntitiesIndex);
    const remaining = nonZeroEntities.slice(visibleEntitiesIndex);
    const remainingLabel = `${remaining.length} more...`;
    const remainingSum = sumDecimalJS(
      remaining.map(({ value }) => new Decimal(value))
    );

    return { visible, remaining, remainingSum, remainingLabel };
  }, [entities]);
}
