import { CalloutListCard } from '@/components/layout/CalloutListCard/CalloutListCard';
import { BusinessEntitySummaryData } from '@/modules/entities/details/businessEntities/BusinessEntityDetailsCard/BusinessEntityDetails.types';
import { BusinessEntitySummary } from '@/modules/entities/details/businessEntities/BusinessEntityDetailsCard/BusinessEntitySummary';
import { PrintOnlyAssetsCard } from '@/modules/entities/details/shared/ViewOnlyAssetsCard';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';

import { PresentationSlideSecondaryHeading } from '../../../components/PresentationSlideSecondaryHeading';
import { OverviewSlideLayout } from '../../components/OverviewSlideLayout';
import { PresentationSlideRegistrationDetails } from '../../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../../graphql/EntityPresentation.generated';

/**
 * @description The BusinessEntityOverviewSlide is a generic component that can be shared between business entity types that have
 * similar overview slides in their presentations.
 */

export interface BusinessEntityOverviewSlideProps
  extends PresentationSlideRegistrationDetails {
  entity: EntityPresentation_EntityFragment;
  summary: BusinessEntitySummaryData;
  displayName: string;
}

export function BusinessEntityOverviewSlide({
  displayName,
  entity,
  summary,
  ...registrationProps
}: BusinessEntityOverviewSlideProps) {
  useRegisterSlide({
    displayName: 'Overview',
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  const { description, ...summaryProps } = summary;

  return (
    <PresentationSlide.Slide
      id={registrationProps.slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading heading={displayName} />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={entity.household.displayName}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <OverviewSlideLayout
        Assets={<PrintOnlyAssetsCard entity={entity} />}
        Summary={<BusinessEntitySummary loading={false} {...summaryProps} />}
        Callout={
          <CalloutListCard
            sx={{ width: '100%' }}
            loading={false}
            variant="filled"
            items={summary.detailsItems}
          />
        }
        description={description}
      />
    </PresentationSlide.Slide>
  );
}
