import { Typography } from '@mui/material';
import React, { useCallback } from 'react';
import { useLocalStorage } from 'react-use';

import { MagicWand01Icon } from '@/components/icons/MagicWand01Icon';
import { LuminaryAIBannerCard } from '@/components/layout/LuminaryAIBannerCard';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { useModalState } from '@/hooks/useModalState';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useTriggerAIOnboardingWorkflow } from '@/modules/aiOnboarding/hooks/useTriggerAIOnboardingWorkflow';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { CreateHouseholdModal } from '@/modules/createHousehold/CreateHouseholdModal/CreateHouseholdModal';
import { useMultiDocumentUploaderContext } from '@/modules/documents/MultiDocumentUploader/context/multiDocumentUploader.context';
import { MultiDocumentUploaderProvider } from '@/modules/documents/MultiDocumentUploader/context/MultiDocumentUploader.provider';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { ROUTE_KEYS } from '@/navigation/constants';
import { COLORS } from '@/styles/tokens/colors';

interface AnnouncementBannerConfig {
  bannerText: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  localStorageKey: string;
}

const BANNER_CONFIGS: Record<
  AIOnboardingAnnouncementBannerProps['variant'],
  AnnouncementBannerConfig
> = {
  clients: {
    bannerText:
      'Upload documents in bulk to build out a client profile with Luminary AI',
    primaryButtonText: 'Create client with AI',
    secondaryButtonText: 'Dismiss',
    localStorageKey:
      LOCAL_STORAGE_KEYS.AI_ONBOARDING_ANNOUNCEMENT_CLIENTS_PAGE_DISMISSED,
  },
  entities: {
    // TODO: Add configs for entities variant (not implementing for now)
    bannerText: '',
    primaryButtonText: '',
    secondaryButtonText: '',
    localStorageKey:
      LOCAL_STORAGE_KEYS.AI_ONBOARDING_ANNOUNCEMENT_ENTITIES_PAGE_DISMISSED,
  },
};

interface AIOnboardingAnnouncementBannerProps {
  variant: 'clients' | 'entities';
}

function AIOnboardingAnnouncementBannerInner({
  variant,
}: AIOnboardingAnnouncementBannerProps) {
  const trackUserEvent = useTrackUserEvent();
  const aiOnboardingEnabled = useFeatureFlag('ai_onboarding');
  const canCreateClients = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_CREATE_CLIENTS
  );
  const { navigate } = useNavigateToRoute();
  const { triggerWorkflow } = useTriggerAIOnboardingWorkflow();
  const { clearUploadedFiles, clearUploaderErrorMessage } =
    useMultiDocumentUploaderContext();

  const {
    bannerText,
    primaryButtonText,
    secondaryButtonText,
    localStorageKey,
  } = BANNER_CONFIGS[variant];

  const [bannerDismissed, setBannerDismissed] =
    useLocalStorage<boolean>(localStorageKey);

  const [{ isModalOpen }, { setModalIsOpen }] = useModalState();

  const onClose = useCallback(() => {
    clearUploadedFiles();
    clearUploaderErrorMessage();
    setModalIsOpen(false);
  }, [clearUploadedFiles, clearUploaderErrorMessage, setModalIsOpen]);

  const onAfterCreate = useCallback(
    async (householdId: string) => {
      await triggerWorkflow(householdId);
      navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_DOCUMENTS, {
        householdId,
      });
      setModalIsOpen(false);
    },
    [navigate, setModalIsOpen, triggerWorkflow]
  );

  if (!aiOnboardingEnabled || !canCreateClients || bannerDismissed) return null;

  return (
    <>
      <LuminaryAIBannerCard
        cardSx={{ border: '1px solid', borderColor: COLORS.TEAL['600'] }}
        bannerText={<Typography variant="label">{bannerText}</Typography>}
        primaryButtonProps={{
          onClick: () => {
            trackUserEvent('ai_onboarding announcement banner click');
            setModalIsOpen(true);
          },
          startIcon: MagicWand01Icon,
          size: 'lg',
          variant: 'primary',
          children: primaryButtonText,
        }}
        secondaryButtonProps={{
          onClick: () => {
            setBannerDismissed(true);
          },
          size: 'lg',
          variant: 'transparent',
          children: secondaryButtonText,
        }}
      />
      {isModalOpen && (
        <CreateHouseholdModal
          isOpen={isModalOpen}
          onClose={onClose}
          onAfterCreate={onAfterCreate}
          showDocumentUploader
          showRelationshipToOtherPrimaryClient
        />
      )}
    </>
  );
}

export function AIOnboardingAnnouncementBanner(
  props: AIOnboardingAnnouncementBannerProps
) {
  return (
    <MultiDocumentUploaderProvider>
      <AIOnboardingAnnouncementBannerInner {...props} />
    </MultiDocumentUploaderProvider>
  );
}
