import Decimal from 'decimal.js';
import { useEffect } from 'react';

import { CurrencyUSD, Percent } from '@/graphql/scalars';
import {
  AssetV2QsbsEligibility,
  AssetValueV2OwnershipType,
} from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { getNodes } from '@/utils/graphqlUtils';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { NEW_ENTITY_ID } from '../entities/entities.constants';
import {
  AssetValuationV2Fragment,
  useCurrentAssetValuationLazyQuery,
  useInitialAssetValuationLazyQuery,
  useSpecificAssetValuationLazyQuery,
} from './graphql/entityValuationQueries.generated';

export const COMBINED = 'combined' as const;

export interface NormalizedAssetValuation {
  id: string;
  accountId: string;
  effectiveDate: Date;
  displayEffectiveDate: string;
  value: CurrencyUSD;
  assetValues: {
    id: string;
    ownershipType: AssetValueV2OwnershipType;
    // `value` is the computed value of the asset
    value: CurrencyUSD;

    // `marketValue` is the source of truth for VALUE_BASED assets
    marketValue: CurrencyUSD | null;

    // `shareCount`/`shareValue` are the source of truth for SHARE_BASED assets
    shareCount: Decimal | null;
    shareValue: CurrencyUSD | null;

    // `totalValue`/`ownedPercent` are the source of truth for PERCENT_BASED assets
    totalValue: CurrencyUSD | null;
    ownedPercent: Percent | null;

    asset: {
      id: string;
      displayName: string;
      categoryId: string;
      qsbsEligibility: AssetV2QsbsEligibility;
    };
  }[];
}

export function normalizeValuation(
  node: AssetValuationV2Fragment
): NormalizedAssetValuation {
  const assets = getNodes(node.assets);
  return {
    effectiveDate: node.effectiveDate,
    accountId: node.account?.id ?? '',
    displayEffectiveDate: formatDateToMonDDYYYY(node.effectiveDate),
    id: node.id,
    value: node.valuationValue ?? new Decimal(0),
    assetValues:
      assets.map((asset) => ({
        id: asset.assetValue.id,
        ownershipType: asset.assetValue.ownershipType,
        value: asset.assetValue.value,
        marketValue: asset.assetValue.ownedValue ?? null,
        shareCount: asset.assetValue.shareCount ?? null,
        shareValue: asset.assetValue.shareValue ?? null,
        ownedPercent: asset.assetValue.ownedPercent ?? null,
        totalValue: asset.assetValue.totalValue ?? null,
        asset: {
          id: asset.id,
          displayName: asset.displayName,
          // TODO (T2-2751) make this required
          categoryId: asset.class?.id ?? '',
          qsbsEligibility:
            asset?.qsbsEligibility ?? AssetV2QsbsEligibility.NotConfirmed,
        },
      })) ?? [],
  };
}

// use this to get a specific asset valuation
export function useSpecificAssetValuation(
  assetValuationId: string | undefined
): NormalizedAssetValuation | null {
  const [getSpecificAssetValuation, { data: assetValuationData, error }] =
    useSpecificAssetValuationLazyQuery();

  useEffect(() => {
    if (!assetValuationId) return;
    void getSpecificAssetValuation({
      variables: {
        assetValuationId,
      },
    });
  }, [assetValuationId, getSpecificAssetValuation]);

  if (error) {
    diagnostics.error(
      'Failed to fetch asset valuations for entity',
      error as Error
    );
  }

  if (!assetValuationData) {
    return null;
  }

  if (assetValuationData.node?.__typename !== 'AssetValuationV2') {
    throw new Error('Expected AssetValuationV2 node');
  }

  return normalizeValuation(assetValuationData?.node);
}

// use this to get the current asset valuation for a entity
export function useCurrentAssetValuation(
  entityId: string
): NormalizedAssetValuation | null {
  const [getAssetValuations, { data: assetValuationData, error }] =
    useCurrentAssetValuationLazyQuery();

  useEffect(() => {
    if (!entityId || entityId === NEW_ENTITY_ID || entityId === COMBINED) {
      return;
    }

    if (getPulidKind(entityId) === PulidKind.Entity) {
      void getAssetValuations({
        variables: {
          entityId,
        },
      });
    }
  }, [entityId, getAssetValuations]);

  if (error) {
    diagnostics.error(
      'Failed to fetch asset valuations for entity',
      error as Error
    );
  }

  if (!assetValuationData) {
    return null;
  }

  if (assetValuationData.node?.__typename !== 'Entity') {
    throw new Error('Expected Entity node');
  }
  if (assetValuationData.node.subtype.__typename !== 'GRATTrust') {
    throw new Error(
      'useCurrentAssetValuation is currently only supported for GRATs'
    );
  }

  const assetValuations = getNodes(
    assetValuationData.node.subtype.designerAccount?.valuations
  );
  const currentFundingAssetValuation =
    assetValuations.length === 1 ? assetValuations[0] : null;

  if (!currentFundingAssetValuation) {
    return {
      id: '',
      accountId: '',
      effectiveDate: new Date(),
      displayEffectiveDate: '',
      value: new Decimal(0),
      assetValues: [],
    };
  }

  const normalizedValuation = normalizeValuation(currentFundingAssetValuation);

  return normalizedValuation;
}

// use this to get the initial asset valuation for an entity
export function useInitialAssetValuation(
  entityId: string
): NormalizedAssetValuation | null {
  const [getAssetValuations, { data: assetValuationData, error }] =
    useInitialAssetValuationLazyQuery({
      variables: {
        entityId,
      },
    });

  useEffect(() => {
    if (entityId === NEW_ENTITY_ID) {
      return;
    }
    void getAssetValuations();
  }, [entityId, getAssetValuations]);

  if (error) {
    diagnostics.error(
      'Failed to fetch asset valuations for entity',
      error as Error
    );
  }

  if (!assetValuationData) {
    return null;
  }
  if (assetValuationData.node?.__typename !== 'Entity') {
    throw new Error('Expected Entity node');
  }

  if (assetValuationData?.node?.subtype?.__typename !== 'GRATTrust') {
    throw new Error(
      'useCurrentAssetValuation is currently only supported for GRATs'
    );
  }

  const initialFundingAssetValuation =
    assetValuationData.node.subtype.designerAccount?.initialValuation;

  if (!initialFundingAssetValuation) {
    return {
      id: '',
      accountId: '',
      effectiveDate: new Date(),
      displayEffectiveDate: '',
      value: new Decimal(0),
      assetValues: [],
    };
  }

  const normalizedValuation = normalizeValuation(initialFundingAssetValuation);

  return normalizedValuation;
}
