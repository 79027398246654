import { Stack } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Dialog } from '@/components/modals/Dialog';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useDebouncedFn } from '@/hooks/useDebouncedFn';
import { useKeypress } from '@/hooks/useKeypress';
import { useReportError } from '@/hooks/useReportError';

import { useCurrentUser } from '../authentication/hooks/useCurrentUser';
import { useRecentEntitiesQuery } from '../recents/RecentEntitiesSidebar/graphql/RecentEntitiesSidebar.generated';
import { SearchContext } from './context/SearchContext';
import { useSearchResults } from './GlobalSearch.utils';
import { useGlobalSearchQuery } from './graphql/GlobalSearchQuery.generated';
import { SearchField } from './SearchField';
import { SearchResults } from './SearchResults';

export interface GlobalSearchProps {
  isOpen: boolean;
  onClose: () => void;
  openModal: () => void;
}

export function GlobalSearch({
  isOpen,
  onClose: onCloseProp,
  openModal,
}: GlobalSearchProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const handleKeyboardShortcut = useCallback(() => {
    openModal();
  }, [openModal]);

  useKeypress(handleKeyboardShortcut, ['/']);

  const currentUser = useCurrentUser();

  const [searchParams] = useSearchParams();
  const showWeights = searchParams.get('showWeights') === 'true';

  // we want to show immediate feedback to the user, so we'll update the input string live as they type. but because we don't
  // want to trigger a search on every keystroke, we'll debounce the update to the query string (which is used for the search query)
  const [inputString, setInputString] = useState<string>('');
  const [queryString, setQueryString] = useState<string>('');
  const debouncedCopyInputStringToQueryString = useDebouncedFn(
    setQueryString,
    250,
    {
      trailing: true,
    }
  );
  const handleSetInputString = useCallback(
    (newString: string) => {
      setInputString(newString);
      debouncedCopyInputStringToQueryString(newString);
    },
    [debouncedCopyInputStringToQueryString]
  );

  const { data: recentsData } = useRecentEntitiesQuery({
    variables: {
      first: 4,
    },
    fetchPolicy: 'cache-and-network',
    skip: !currentUser || !isOpen,
  });

  const onClose = useCallback(() => {
    setInputString('');
    setQueryString('');
    onCloseProp();
  }, [onCloseProp]);

  const queryArgs = useMemo(() => {
    return {
      variables: {
        query: queryString,
        skipQueries: !isOpen || !queryString,
        first: 20,
        showWeights,
      },
      skip: !isOpen || !queryString,
    };
  }, [isOpen, queryString, showWeights]);

  const { data, loading } = useGlobalSearchQuery({
    ...queryArgs,
    errorPolicy: 'all',
    nextFetchPolicy: 'standby', // don't refetch for refetch queries
    onError: (error) => {
      reportError('error occurred while executing search', error);
      showFeedback('Error fetching search results');
    },
  });

  const { results, source } = useSearchResults(
    recentsData,
    data,
    queryString,
    loading,
    showWeights
  );

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <SearchContext.Provider value={{ onClose }}>
        <Stack direction="column" sx={{ minWidth: '600px' }}>
          <SearchField
            searchString={inputString}
            setSearchString={handleSetInputString}
          />
          <SearchResults results={results} loading={loading} source={source} />
        </Stack>
      </SearchContext.Provider>
    </Dialog>
  );
}
