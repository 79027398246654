export function getPulidPrefix(pulid: string): string | null {
  const prefixMatch = pulid.match(/^([^\s_]+)_/);
  const preUnderscore = prefixMatch ? prefixMatch[1] : null;
  // we've gone through a few different variations of PULIDS already.
  // The original ones were e.g. CP1234, and newly-created client profiles
  // are e.g. CP_1234. we want to make sure to support both scenarios.
  if (preUnderscore) {
    return preUnderscore;
  } else {
    // there is no underscore in the pulid
    let pulidKind: string | null = null;
    Object.values(pulidMap).forEach((prefix) => {
      // there's only a handful of pre-underscore pulids types we support
      // so we can be pretty confident if the pulid starts with one of them
      // that it's a valid pulid of an existing type
      if (pulid.startsWith(prefix)) {
        pulidKind = prefix;
      }
    });
    return pulidKind;
  }
}

// must be lowercase
const pulidPrefixes = [
  'ac', // note that 'ac' (for "advisor_client") is the deprecated prefix for "household"
  'household',

  'cp',
  'entity',
  'st',
  'vh',
  'do',
  'org',
  'tstentity',
  'ew',
  'dpt',
  'as',
] as const;
type Pulid = (typeof pulidPrefixes)[number];

// must preserve casing of pulid string or mutations won't work correctly
export enum PulidKind {
  AdvisorClient = 'AC', // note that 'ac' (for "advisor_client") is the deprecated prefix for "household"
  Household = 'household',

  Entity = 'entity',
  EstateWaterfall = 'ew',
  ClientProfile = 'CP',
  Strategy = 'ST',
  Valuation = 'VH',
  Document = 'DO',
  ClientOrganization = 'org',
  TestamentaryEntity = 'tstentity',
  DispositiveProvisionTemplate = 'dpt',
  AiSuggestion = 'as',
}

const pulidMap: Record<Pulid, PulidKind> = {
  ac: PulidKind.AdvisorClient, // note that 'ac' (for "advisor_client") is the deprecated prefix for "household"
  household: PulidKind.Household,

  entity: PulidKind.Entity,
  cp: PulidKind.ClientProfile,
  st: PulidKind.Strategy,
  vh: PulidKind.Valuation,
  do: PulidKind.Document,
  org: PulidKind.ClientOrganization,
  tstentity: PulidKind.TestamentaryEntity,
  ew: PulidKind.EstateWaterfall,
  dpt: PulidKind.DispositiveProvisionTemplate,
  as: PulidKind.AiSuggestion,
};

function isKnownPulidPrefix(
  maybePulidPrefix: unknown
): maybePulidPrefix is Pulid {
  return pulidPrefixes.includes(maybePulidPrefix as Pulid);
}

export function isValidPulid(maybePulid: string): boolean {
  // make a copy of the input string to not mutate the input
  const prefix = getPulidPrefix(maybePulid.slice());
  return isKnownPulidPrefix(prefix);
}

export function getPulidKind(pulid: string): PulidKind | null {
  // Make a copy of the input string to not mutate the input
  const copyPulid = pulid.slice();

  const prefix = getPulidPrefix(copyPulid);

  if (!prefix) {
    return null;
  }

  const lowerCasePulid = prefix.toLowerCase();

  if (!isKnownPulidPrefix(lowerCasePulid)) {
    return null;
  }

  return pulidMap[lowerCasePulid];
}

export function isDocumentId(pulid: string): boolean {
  return getPulidKind(pulid) === PulidKind.Document;
}

export function isEntityId(pulid: string): boolean {
  // getPulidKind will throw an error if the pulid is invalid,
  // but there are scenarios when we want to check if a pulid is an entity
  // and other, non-supported pulids will be passed in.
  try {
    return getPulidKind(pulid) === PulidKind.Entity;
  } catch {
    return false;
  }
}
