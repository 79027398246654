// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PrintEntityMutationVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
  pathToPrint: Types.Scalars['String']['input'];
}>;


export type PrintEntityMutation = { __typename?: 'Mutation', print: { __typename?: 'File', id: string, download: { __typename?: 'FileDownload', downloadURL: string, downloadURLExpiresInSeconds: number } } };


export const PrintEntityDocument = gql`
    mutation PrintEntity($householdId: ID!, $pathToPrint: String!) {
  print(input: {householdID: $householdId, relativePath: $pathToPrint}) {
    id
    download {
      downloadURL
      downloadURLExpiresInSeconds
    }
  }
}
    `;
export type PrintEntityMutationFn = Apollo.MutationFunction<PrintEntityMutation, PrintEntityMutationVariables>;

/**
 * __usePrintEntityMutation__
 *
 * To run a mutation, you first call `usePrintEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printEntityMutation, { data, loading, error }] = usePrintEntityMutation({
 *   variables: {
 *      householdId: // value for 'householdId'
 *      pathToPrint: // value for 'pathToPrint'
 *   },
 * });
 */
export function usePrintEntityMutation(baseOptions?: Apollo.MutationHookOptions<PrintEntityMutation, PrintEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintEntityMutation, PrintEntityMutationVariables>(PrintEntityDocument, options);
      }
export type PrintEntityMutationHookResult = ReturnType<typeof usePrintEntityMutation>;
export type PrintEntityMutationResult = Apollo.MutationResult<PrintEntityMutation>;
export type PrintEntityMutationOptions = Apollo.BaseMutationOptions<PrintEntityMutation, PrintEntityMutationVariables>;
export const Operations = {
  Mutation: {
    PrintEntity: 'PrintEntity' as const
  }
}