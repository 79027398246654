import {
  array,
  boolean as booleanType,
  Describe,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreateIndividualPersonalAccountInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateIndividualPersonalAccountInput,
} from '@/types/schema';

import {
  commonCreatePersonalAccountProperties,
  commonUpdatePersonalAccountProperties,
  controllingPartySchema,
} from './personalAccountTypes';
import {
  beneficiarySchema,
  commonUpdateEntityProperties,
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  grantorSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const createIndividualAccountSchema: Describe<AugmentedCreateIndividualPersonalAccountInput> =
  object({
    create: object(commonCreatePersonalAccountProperties),
    withOwner: optional(nullable(grantorSchema)),
    withBeneficiaries: optional(nullable(array(beneficiarySchema))),
    withControllingParties: optional(nullable(array(controllingPartySchema))),
    withDesignerAccount: optional(nullable(createAccountSchema)),
  });

const updateIndividualAccountSchema: Describe<AugmentedUpdateIndividualPersonalAccountInput> =
  object({
    id: string(),
    update: object({
      ...commonUpdatePersonalAccountProperties,
      clearControllingParties: optional(nullable(booleanType())),
      clearBeneficiaries: optional(nullable(booleanType())),
      clearOwner: optional(nullable(booleanType())),
      clearDescription: optional(nullable(booleanType())),
      clearGstStatus: optional(nullable(booleanType())),
      clearTaxStatus: optional(nullable(booleanType())),
    }),
    withOwner: optional(nullable(grantorSchema)),
    withBeneficiaries: optional(nullable(array(beneficiarySchema))),
    withControllingParties: optional(nullable(array(controllingPartySchema))),
    updateDesignerAccount: optional(nullable(updateAccountSchema)),
  });

export const createSchema: Describe<AugmentedCreateEntityInput> = object({
  create: createEntityInput,
  withIndividualPersonalAccount: optional(
    nullable(createIndividualAccountSchema)
  ),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object(commonUpdateEntityProperties),
  updateIndividualPersonalAccount: optional(
    nullable(updateIndividualAccountSchema)
  ),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export function validateIndividualAccountCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'Individual account create');
}

export function validateIndividualAccountUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'Individual account update');
}
