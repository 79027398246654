import { MouseEventHandler } from 'react';

import { PrintButton } from '@/components/form/baseInputs/Button/PrintButton';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { $downloadFileFromURL } from '@/modules/files/utils/fileUtils';

import { useDownloadProposal } from '../ClientDetailsPage/ClientDetailsProposalsPage/useDownloadProposal';

interface PrintClientProposalButtonProps {
  householdId: string;
  proposalId: string;
  proposalName: string;
}

export function PrintClientProposalButton({
  householdId,
  proposalId,
  proposalName,
}: PrintClientProposalButtonProps) {
  const { showFeedback } = useFeedback();
  const trackUserEvent = useTrackUserEvent();

  const [printDocument, { loading: printing }] = useDownloadProposal(
    householdId,
    proposalId
  );

  const handlePrintAndDownloadDocument: MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.stopPropagation();

    trackUserEvent('proposal printed');

    void printDocument({
      onCompleted: (data) => {
        const downloadUrl = data.print.download.downloadURL;
        try {
          void $downloadFileFromURL(downloadUrl, proposalName);
          showFeedback('PDF generated successfully', { variant: 'success' });
        } catch (err) {
          showFeedback('Failed to download PDF. Please try again.');
        }
      },
    });
  };

  return (
    <PrintButton onClick={handlePrintAndDownloadDocument} loading={printing} />
  );
}
