import { ReactNode } from 'react';

import { FlowChartWrapper } from '@/components/diagrams/FlowChart/components/FlowChartWrapper';

export interface EntityMapFlowChartWrapperProps {
  height: number;
  children: ReactNode;
}

export const EntityMapFlowChartWrapper = ({
  height,
  children,
}: EntityMapFlowChartWrapperProps) => {
  return <FlowChartWrapper style={{ height }}>{children}</FlowChartWrapper>;
};
