import dynamic from 'next/dynamic';

import { Loader } from '@/components/progress/Loader/Loader';

export const PDFViewerLazy = dynamic(
  () => import('./PDFViewer').then((mod) => mod.PDFViewer),
  {
    ssr: false,
    loading: () => (
      <Loader
        boxProps={{
          sx: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          },
        }}
      />
    ),
  }
);
