import { css, cx } from '@emotion/css';
import { useMemo } from 'react';

import { REACT_FLOW_CLASSES } from '../../constants';
import { ExternalFlowChartProps, InternalFlowChartProps } from '../../types';

const VIEWPORT_TARGET = `* .${REACT_FLOW_CLASSES.VIEWPORT}`;

/**
 * @description Ideally we could do this in ThemedReactFlow, but we can't because that themed component only takes InternalFlowChartProps
 * due to the nature of how themed components work, and this relies on ExternalFlowChartProps
 */
export const useClassName = ({
  loading,
  className,
}: Pick<ExternalFlowChartProps, 'loading'> &
  Pick<InternalFlowChartProps, 'className'>) => {
  return useMemo(() => {
    // If auto layout is integrated, we need to render the initial nodes before it can run,
    // but we don't want to actually show the chart to prevent flashing when re-positioning occurs.
    const hideViewportWhenLoadingStyle = css({
      [VIEWPORT_TARGET]: {
        opacity: loading ? 0 : undefined,
      },
    });

    return cx(loading && hideViewportWhenLoadingStyle, className);
  }, [className, loading]);
};
