import { useCallback } from 'react';

import { CompleteInitializationAction, FamilyTreeActionFn } from '../types';

export function useCompleteInitializationAction() {
  return useCallback<FamilyTreeActionFn<CompleteInitializationAction>>(
    (state, _action, _queueEffect) => {
      return { ...state, isInitializingFlowchart: false };
    },
    []
  );
}
