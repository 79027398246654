import { Box, FormControlLabel, Stack, Typography } from '@mui/material';
import { includes, map, omit } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { get, useWatch } from 'react-hook-form';
import { useLocalStorage } from 'react-use';

import { ExpansionCaret } from '@/components/display/ExpansionCaret/ExpansionCaret';
import { RotatingChevronIcon } from '@/components/display/RotatingChevronIcon/RotatingChevronIcon';
import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { LoadingAdornment } from '@/components/form/baseInputs/SelectInput/LoadingAdornment';
import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { CheckCircleBrokenIcon } from '@/components/icons/CheckCircleBrokenIcon';
import { CornerDownRightIcon } from '@/components/icons/CornerDownRightIcon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { Coachmark } from '@/components/poppers/Coachmark/Coachmark';
import { useFormContext } from '@/components/react-hook-form';
import { StyledTableCell } from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { useGuardedContext } from '@/hooks/useGuardedContext';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';

import { EXTERNAL_KIND_DISPLAY_NAME_MAP } from '../assetProviderIntegrations/addepar/addepar.constants';
import { INTEGRATION_EXCLUDED_ENTITY_TYPES } from '../assetProviderIntegrations/shared/constants';
import {
  ENTITY_TYPES,
  SINGLE_PRINCIPAL_ENTITY_TYPES,
} from '../entities/entities.constants';
import { EntityType } from '../entities/types/EntityType';
import { getDisplayNameFromEntityType } from '../entities/utils/getDisplayNameFromEntityType';
import { getEntityTypeFromEntityKind } from '../entities/utils/getEntityTypeFromEntityKind';
import { useAICapabilitiesEnabled } from '../tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import { useInferEntityKindsLazyQuery } from './addepar/graphql/AddeparEntityImportTable.generated';
import {
  BulkImportFlowContext,
  BulkImportFlowTitleMap,
} from './BulkImportFlowContext';
import {
  EntityImportTableEntity,
  EntityImportTableForm,
  NAMESPACE,
} from './EntityImportTable.types';

export interface EntityImportTableClientRowProps {
  householdId: string;
  isInitiallyExpanded: boolean;
  isSingleHouseholdMode: boolean;
}

const getEntityFieldName = (householdId: string, entityID: string) => {
  return <T extends keyof EntityImportTableEntity>(
    fieldName: T
  ): `${typeof NAMESPACE}.householdMap.${string}.entityMap.${string}.${T}` =>
    `${NAMESPACE}.householdMap.${householdId}.entityMap.${entityID}.${fieldName}`;
};

export function EntityImportTableClientRow({
  householdId,
  isInitiallyExpanded,
  isSingleHouseholdMode,
}: EntityImportTableClientRowProps) {
  const [isExpanded, setExpanded] = useState<boolean>(isInitiallyExpanded);
  const [expandedRootEntityIds, setExpandedRootEntityIds] = useState<string[]>(
    []
  );
  const { control } = useFormContext<EntityImportTableForm>();
  const client = useWatch({
    control,
    name: `${NAMESPACE}.householdMap.${householdId}`,
  });

  const handleSetExpandedRootEntity = useCallback(
    (entityId: string) => {
      if (expandedRootEntityIds.includes(entityId)) {
        setExpandedRootEntityIds(
          expandedRootEntityIds.filter((id) => id !== entityId)
        );
      } else {
        setExpandedRootEntityIds([...expandedRootEntityIds, entityId]);
      }
    },
    [expandedRootEntityIds]
  );

  return (
    <>
      {isSingleHouseholdMode ? null : (
        <StyledTableRow>
          <StyledTableCell colSpan={4} sx={{ px: 0 }}>
            <Stack
              direction="row"
              alignItems="center"
              onClick={() => setExpanded(!isExpanded)}
            >
              <Box
                sx={(theme) => ({
                  width: theme.spacing(6),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                })}
              >
                <RotatingChevronIcon isExpanded={isExpanded} />
              </Box>
              <Typography variant="body1" sx={{ pr: 1.5 }}>
                {client.displayName}
              </Typography>
              <Badge
                variant={BadgeVariants.Yellow}
                display={`${Object.keys(client.entityMap).length} unlinked`}
              />
            </Stack>
          </StyledTableCell>
        </StyledTableRow>
      )}
      {(isExpanded || isSingleHouseholdMode) &&
        map(client.entityMap, (entityRow, entityID) => (
          <EntityImportTableEntityRow
            entityID={entityID}
            householdId={householdId}
            key={`${householdId}-${entityID}`}
            isExpanded={expandedRootEntityIds.includes(entityID)}
            toggleExpandedRootEntity={handleSetExpandedRootEntity}
            hidden={
              !!entityRow.parentEntityId &&
              !expandedRootEntityIds.includes(entityRow.parentEntityId)
            }
          />
        ))}
    </>
  );
}

interface EntityImportTableEntityRowProps {
  entityID: string;
  householdId: string;
  hidden?: boolean;
  isExpanded: boolean;
  toggleExpandedRootEntity: (entityId: string) => void;
}

function EntityImportTableEntityRow({
  entityID,
  householdId,
  hidden,
  isExpanded,
  toggleExpandedRootEntity,
}: EntityImportTableEntityRowProps) {
  const aiEnabled = useAICapabilitiesEnabled();
  const [getInferredEntityKinds, { loading: guessingEntityKind }] =
    useInferEntityKindsLazyQuery();
  const {
    control,
    getValues,
    setValue,
    formState: { dirtyFields },
  } = useFormContext<EntityImportTableForm>();
  const [coachmarkSeen, setCoachmarkSeen] = useLocalStorage<boolean>(
    LOCAL_STORAGE_KEYS.COACHMARK_EXPAND_BULK_IMPORT_ENTITY
  );
  // use getValues on these, as they won't change within a single page view
  const remoteName = getValues(
    `${NAMESPACE}.householdMap.${householdId}.entityMap.${entityID}.remoteName`
  );
  const clientGrantors = getValues(
    `${NAMESPACE}.householdMap.${householdId}.grantors`
  );

  const getFieldName = getEntityFieldName(householdId, entityID);
  const shouldImportPath = getFieldName('shouldImport');
  const entityKindPath = getFieldName('entityKind');
  const entityValuePath = getFieldName('entityValue');
  const externalKindPath = getFieldName('externalKind');
  const entityLevelPath = getFieldName('level');
  const entityLevelIndexPath = getFieldName('levelIndex');
  const hasChildrenPath = getFieldName('hasChildren');
  const entityKind = useWatch({
    control,
    name: entityKindPath,
  });

  const entityLevel =
    useWatch({
      control,
      name: entityLevelPath,
    }) || 0;

  const entityLevelIndex =
    useWatch({
      control,
      name: entityLevelIndexPath,
    }) || 0;

  const enabled = useWatch({
    control,
    name: shouldImportPath,
  });

  const externalKind = useWatch({
    control,
    name: externalKindPath,
  });

  const externalValue = useWatch({
    control,
    name: entityValuePath,
  });

  const hasChildren = useWatch({
    control,
    name: hasChildrenPath,
  });

  function handleEntitySelection(entityId: string) {
    if (!aiEnabled) return;
    void getInferredEntityKinds({
      variables: { input: [{ id: entityId, entityName: remoteName }] },
      onCompleted: (data) => {
        const entityKind = data.inferEntityKinds[0]?.entityKind;
        if (!entityKind) return;

        // Only set the value if the field hasn't been modified by the user
        const isDirty = get(dirtyFields, entityKindPath);
        if (!isDirty) {
          const entityType = getEntityTypeFromEntityKind(entityKind);
          setValue(entityKindPath, entityType);
        }
      },
    });
  }

  const grantorOptions: SelectInputOption<string>[] = useMemo<
    SelectInputOption<string>[]
  >(() => {
    const grantors = clientGrantors.map<SelectInputOption<string>>(
      ({ id, displayName }) => ({
        value: id,
        display: displayName,
      })
    );

    if (
      clientGrantors.length > 1 &&
      !includes(SINGLE_PRINCIPAL_ENTITY_TYPES, entityKind) &&
      entityKind
    ) {
      grantors.push({
        value: clientGrantors.map(({ id }) => id).join(','),
        display: clientGrantors
          .map(({ displayName }) => displayName)
          .join(' and '),
      });
    }
    return grantors;
  }, [clientGrantors, entityKind]);

  const entityOptions: SelectInputOption<EntityType | null>[] = useMemo<
    SelectInputOption<EntityType | null>[]
  >(() => {
    const supportedEntityTypes: EntityType[] = Object.values(
      omit(ENTITY_TYPES, INTEGRATION_EXCLUDED_ENTITY_TYPES)
    );
    const options: SelectInputOption<EntityType | null>[] =
      supportedEntityTypes.map<SelectInputOption<EntityType | null>>(
        (entityType) => ({
          display: getDisplayNameFromEntityType(entityType),
          value: entityType,
        })
      );

    return options;
  }, []);

  if (hidden) return null;

  return (
    <StyledTableRow key={entityID}>
      <StyledTableCell
        sx={{
          px: 0,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ pl: `${entityLevel * 30 + 15}px` }}
        >
          {entityLevel === 0 && (
            <Box sx={{ visibility: hasChildren ? 'visible' : 'hidden' }}>
              <Coachmark
                id={LOCAL_STORAGE_KEYS.COACHMARK_EXPAND_BULK_IMPORT_ENTITY}
                maxInstances={1}
                initialOpen={!coachmarkSeen && isExpanded}
                onClose={() => setCoachmarkSeen(true)}
                title="Import separate entities"
                body="Selecting items nested below a top level row will create separate entities for each."
                buttonLabel="Got it"
                placement="top-start"
              >
                <ExpansionCaret
                  iconProps={{ size: 17 }}
                  isExpanded={isExpanded}
                  onClick={() => toggleExpandedRootEntity(entityID)}
                />
              </Coachmark>
            </Box>
          )}
          <FormControlLabel
            sx={{ m: 0 }}
            control={
              <Box
                sx={{
                  display: 'flex',
                  flexShrink: 0,
                  alignItems: 'center',
                  justifyContent: 'center',
                  mr: 1,
                  ml: 0,
                }}
              >
                {entityLevel > 0 && (
                  <CornerDownRightIcon
                    size={20}
                    sx={{
                      // basically, we want to show this arrow for the first entity of each given "new level"
                      // but we want the other items to be indented the exact same amount. the easiest way to
                      // do that is to just... make the icon invisible.
                      color:
                        entityLevelIndex === 0
                          ? COLORS.GRAY[400]
                          : 'transparent',
                      position: 'relative',
                      top: -2,
                    }}
                  />
                )}
                <FormAwareCheckbox<EntityImportTableForm>
                  fieldName={shouldImportPath}
                  control={control}
                  onChange={() => handleEntitySelection(entityID)}
                />
              </Box>
            }
            label={
              <Stack>
                <Typography variant="body1">{remoteName}</Typography>
                <Typography variant="subtitle2">
                  {EXTERNAL_KIND_DISPLAY_NAME_MAP[externalKind]}
                </Typography>
              </Stack>
            }
          />
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="right">
        <Typography variant="body1">
          {externalValue ? formatCurrency(externalValue) : EMPTY_CONTENT_HYPHEN}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <FormAwareTextInput<EntityImportTableForm>
          fieldName={getFieldName('displayName')}
          control={control}
          label="Display name"
          hideLabel
          disabled={!enabled}
          required={enabled}
        />
      </StyledTableCell>
      <StyledTableCell>
        <Box width={225}>
          <FormAwareSelectInput<EntityImportTableForm>
            fieldName={entityKindPath}
            control={control}
            label="Entity type"
            hideLabel
            disabled={!enabled}
            required={enabled}
            options={entityOptions}
            startAdornment={guessingEntityKind && <LoadingAdornment />}
          />
        </Box>
      </StyledTableCell>
      <StyledTableCell>
        <FormAwareSelectInput<EntityImportTableForm>
          fieldName={getFieldName('commaSeparatedOwnerIDs')}
          control={control}
          label="Grantors, owners or donors"
          hideLabel
          disabled={!enabled}
          required={enabled}
          options={grantorOptions}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
}

export function EntityImportTableEmptyRows() {
  const { flowType } = useGuardedContext(
    BulkImportFlowContext,
    'BulkImportFlowContextProvider'
  );
  const flowTitle = BulkImportFlowTitleMap[flowType];
  return (
    <ActionCard
      variant="noCard"
      grow
      icon={<CheckCircleBrokenIcon size={48} color={COLORS.GRAY[200]} />}
      heading="No entities available to import"
      description={`All entities in ${flowTitle} are linked to clients in Luminary. To modify existing links to ${flowTitle}, navigate to the entity under the client in question.`}
      actions={null}
    />
  );
}
