import { useState } from 'react';

import { TypeaheadSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useFormContext } from '@/components/react-hook-form';
import { CreateAssetCategoryModal } from '@/modules/assetCategories/CreateAssetCategoryModal/CreateAssetCategoryModal';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';

import {
  Fields,
  STRUCTURED_ASSETS_SUBFORM_NAMESPACE,
  SubformField,
} from './StructuredAssetsSubform.types';

interface AssetCategoryFieldProps {
  index: number;
  hideLabel?: boolean;
  options: TypeaheadSelectInputOption<string>[];
}

export const AssetCategoryField = ({
  index,
  hideLabel,
  options,
}: AssetCategoryFieldProps) => {
  const userCanCreateAssetCategories = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_MODIFY_ASSET_CATEGORIES
  );
  const { control } = useFormContext<Fields>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <FormAwareSelectInput<Fields>
        fieldName={
          `${STRUCTURED_ASSETS_SUBFORM_NAMESPACE}.assets.${index}.categoryId` as const satisfies SubformField
        }
        label="Asset category"
        required
        showEmptyValue={false}
        hideLabel={hideLabel}
        control={control}
        options={options}
        addNewOption={
          userCanCreateAssetCategories
            ? {
                onClick: () => setIsModalOpen(true),
                icon: <PlusIcon size={16} />,
              }
            : undefined
        }
      />
      <CreateAssetCategoryModal
        isOpen={isModalOpen}
        existingOptionsCount={options.length}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

interface AssetTitleFieldProps {
  index: number;
  hideLabel?: boolean;
}

export const AssetTitleField = ({ index, hideLabel }: AssetTitleFieldProps) => {
  const { control } = useFormContext<Fields>();

  return (
    <FormAwareTextInput<Fields>
      fieldName={
        `${STRUCTURED_ASSETS_SUBFORM_NAMESPACE}.assets.${index}.title` as const satisfies SubformField
      }
      label="Title"
      required
      hideLabel={hideLabel}
      control={control}
    />
  );
};

interface AssetValueFieldProps {
  index: number;
  hideLabel?: boolean;
}

export const AssetValueField = ({ index, hideLabel }: AssetValueFieldProps) => {
  const { control } = useFormContext<Fields>();

  return (
    <FormAwareCurrencyInput<Fields>
      isDecimalJSInput
      fieldName={
        `${STRUCTURED_ASSETS_SUBFORM_NAMESPACE}.assets.${index}.value` as const satisfies SubformField
      }
      label="Value"
      required
      hideLabel={hideLabel}
      control={control}
    />
  );
};
