import { ApolloClient } from '@apollo/client';
import { compact } from 'lodash';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { KeyPersonRoleKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { keyPersonRoleDisplayNames } from '../details/businessEntities/businessEntities.constants';
import { BusinessEntityDetailsSubformType } from './BusinessEntityDetailsSubform.types';
import {
  GetBusinessEntityDetailsDocument,
  GetBusinessEntityDetailsQuery,
} from './graphql/BusinessEntityDetailsSubform.generated';

function dataToForm(
  data: GetBusinessEntityDetailsQuery
): BusinessEntityDetailsSubformType {
  if (
    data.node?.__typename !== 'Entity' ||
    !('keyPeopleNotes' in data.node.subtype)
  ) {
    throw new Error('Unexpected node type');
  }

  const { subtype } = data.node;

  return {
    keyPeopleNotes: subtype.keyPeopleNotes ?? '',
    ownershipAsOfDate: subtype.ownershipAsOfDate ?? null,
    _ownershipSum: null, // this will be automatically computed within the form
    keyPeople:
      subtype.keyPeople?.map((kp) => ({
        keyPersonId:
          kp.individual?.id ?? kp.entity?.id ?? '' ?? kp.organization?.id ?? '',
        roles: compact(kp.roles?.map((role) => role.kind)),
      })) ?? [],
    owners:
      data.node.ownedByStakes?.map((ownershipStake) => ({
        ownerId:
          ownershipStake.owningIndividual?.id ??
          ownershipStake.owningEntity?.id ??
          '',
        percentOwned: ownershipStake.ownershipPercentage ?? null,
      })) ?? [],
  };
}

export async function businessEntityDetailsDataFetcher(
  apolloClient: ApolloClient<object>,
  nodeId: string
): Promise<BusinessEntityDetailsSubformType> {
  const { data, error } =
    await apolloClient.query<GetBusinessEntityDetailsQuery>({
      query: GetBusinessEntityDetailsDocument,
      variables: {
        nodeId: nodeId,
      },
    });

  if (error) {
    diagnostics.error(
      'Could not fetch data for business entity details subform',
      error
    );

    throw error;
  }

  return dataToForm(data);
}

export const KEY_PERSON_ROLE_KIND_ITEMS: SelectInputOption<KeyPersonRoleKind>[] =
  [
    KeyPersonRoleKind.ControllingPerson,
    KeyPersonRoleKind.BoardMember,
    KeyPersonRoleKind.Director,
    KeyPersonRoleKind.GeneralPartner,
    KeyPersonRoleKind.Manager,
    KeyPersonRoleKind.Officer,
    KeyPersonRoleKind.Other,
    KeyPersonRoleKind.President,
    KeyPersonRoleKind.Secretary,
    KeyPersonRoleKind.Signatory,
    KeyPersonRoleKind.VicePresident,
  ].map((value) => ({
    value,
    display: keyPersonRoleDisplayNames[value],
  }));
