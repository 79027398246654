import { Box, Stack, Typography } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { SetRequired } from 'type-fest';

import { Button } from '@/components/form/baseInputs/Button';
import {
  ButtonWithPopover,
  ButtonWithPopoverProps,
} from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useModalState } from '@/hooks/useModalState';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { isHypotheticalWaterfall } from '../../EstateWaterfall.utils';
import { CreateEstateWaterfallTrowser } from '../EstateWaterfallModal/CreateEstateWaterfallTrowser';
import { HypotheticalEstateWaterfallBadge } from '../HypotheticalEstateWaterfallBadge';
import { useEstateWaterfallMenuItems } from './hooks/useEstateWaterfallMenuItems';

export interface EstateWaterfallViewsButtonProps {
  householdId: string;
  buttonProps: SetRequired<Partial<ButtonWithPopoverProps>, 'size'>;
  showCreateNew?: boolean;
  waterfallId: string | null;
  onClick?: (waterfallId: string) => void;
  setSelectedWaterfallId?: Dispatch<SetStateAction<string | null>>;
  showHypotheticalBadge?: boolean;
}

export function EstateWaterfallViewsButton({
  householdId,
  buttonProps,
  showCreateNew = true,
  waterfallId,
  onClick,
  setSelectedWaterfallId,
  showHypotheticalBadge = true,
}: EstateWaterfallViewsButtonProps) {
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();
  const [isWaterfallSelectorOpen, setIsWaterfallSelectorOpen] = useState(false);
  const { primaryClients: grantors } = useHouseholdDetailsContext();

  const { currentWaterfall, dropdownItems, clientWaterfalls } =
    useEstateWaterfallMenuItems({
      householdId,
      waterfallId,
      onClick,
    });

  // Effect to initialize the parent's waterfallId
  useEffect(() => {
    if (setSelectedWaterfallId && !waterfallId && currentWaterfall) {
      setSelectedWaterfallId(currentWaterfall.id);
    }
  }, [currentWaterfall, setSelectedWaterfallId, waterfallId]);

  const labelContent = useMemo(() => {
    if (!currentWaterfall && !showCreateNew) {
      return EMPTY_CONTENT_HYPHEN;
    }

    if (!currentWaterfall) {
      return 'Select or create new';
    }

    if (isHypotheticalWaterfall(currentWaterfall) && showHypotheticalBadge) {
      return (
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="body1">
            {currentWaterfall.displayName}
          </Typography>
          <HypotheticalEstateWaterfallBadge />
        </Stack>
      );
    }

    return (
      <Typography
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        variant="body1"
      >
        {currentWaterfall.displayName}
      </Typography>
    );
  }, [currentWaterfall, showCreateNew, showHypotheticalBadge]);

  // If there are no waterfalls, show the create waterfall button
  if (dropdownItems?.length === 0) {
    return (
      <>
        {/* RHF preserves form state by default, but we don't want this because the update should
      always reflect the current state of the map so we fully unmount on open / close */}
        {isModalOpen && (
          <CreateEstateWaterfallTrowser
            isOpen={isModalOpen}
            onClose={closeModal}
            householdId={householdId}
            grantors={grantors ?? []}
            sourceEstateWaterfalls={clientWaterfalls.filter((wf) => !wf.parent)}
          />
        )}
        <Button
          variant="primary"
          fullWidth
          onClick={openModal}
          {...buttonProps}
        >
          Create a waterfall view
        </Button>
      </>
    );
  }

  return (
    <ButtonWithPopover
      {...buttonProps}
      buttonSx={{
        ...buttonProps.sx,
      }}
      data-testid="EstateWaterfall-waterfallSelector"
      size="sm"
      variant="secondary-filled"
      popperVariant="menuBelow"
      onClick={() => setIsWaterfallSelectorOpen(true)}
      loading={!dropdownItems}
      isOpen={isWaterfallSelectorOpen}
      label={labelContent}
    >
      {dropdownItems?.map((item, idx) => (
        <Box key={`waterfall-menu-item-${idx}`}>
          {'clickHandler' in item ? (
            <MenuItem
              justifyContent="space-between"
              onClick={item.clickHandler}
              label={item.component}
              iconAfter={item.iconAfter}
            />
          ) : (
            item.component
          )}
        </Box>
      ))}
    </ButtonWithPopover>
  );
}
