import { useMemo } from 'react';

import { REMAINDER_TRUST_COPY_MAP } from '@/modules/entities/gratTrusts/GratBeneficiaryTree/TrustLeaf';
import { getAssetLocationDisplay } from '@/modules/entities/utils/getAssetLocationDisplay';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import {
  EntityGstStatus,
  EntityInEstateStatus,
  TrustTaxStatus,
} from '@/types/schema';

interface Props {
  primaryClients: ContextPrimaryClient[];
  primaryClientDeathId: string;
  firstPrimaryClientDeathId: string;
  inEstateStatus?: EntityInEstateStatus | null;
  gstStatus: EntityGstStatus | null;
}

export function getTestamentaryEntitySummaryData({
  primaryClients,
  primaryClientDeathId,
  firstPrimaryClientDeathId,
  inEstateStatus,
  gstStatus,
}: Props) {
  const hasAliveSpouse = !!(
    primaryClients.length >= 2 &&
    primaryClientDeathId &&
    primaryClientDeathId === firstPrimaryClientDeathId
  );

  const deadPrimaryClientIds = hasAliveSpouse
    ? [firstPrimaryClientDeathId]
    : primaryClients.map((g) => g.id);

  const taxStatus = REMAINDER_TRUST_COPY_MAP[TrustTaxStatus.NonGrantorTrust];

  const assetLocation = getAssetLocationDisplay({
    inEstateStatus,
    stateTaxes: [],
    gstStatus,
  }).locationDisplay;

  return {
    hasAliveSpouse,
    deadPrimaryClientIds,
    taxStatus,
    assetLocation,
  };
}

export function useTestamentaryEntitySummaryData({
  primaryClients,
  primaryClientDeathId,
  firstPrimaryClientDeathId,
  inEstateStatus,
  gstStatus,
}: Props) {
  return useMemo(
    () =>
      getTestamentaryEntitySummaryData({
        primaryClients,
        primaryClientDeathId,
        firstPrimaryClientDeathId,
        inEstateStatus,
        gstStatus,
      }),
    [
      primaryClients,
      primaryClientDeathId,
      firstPrimaryClientDeathId,
      inEstateStatus,
      gstStatus,
    ]
  );
}
