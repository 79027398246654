import { Box } from '@mui/material';
import { flatMap } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { StackedHorizontalBarGroup } from '@/components/charts/StackedHorizontalBarGroup/StackedHorizontalBarGroup';
import { CloseButton } from '@/components/form/baseInputs/Button/CloseButton';
import { Modal } from '@/components/modals/Modal/Modal';
import { TabsProvider } from '@/components/navigation/NavigationTabs/Tabs.provider';
import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';
import { useFormContext } from '@/components/react-hook-form';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { useModalState } from '@/hooks/useModalState';
import { Calculations } from '@/modules/summaryPanels/EstateTaxSummaryPanel/Calculations';
import { EstateTaxSummaryPanelTaxes } from '@/modules/summaryPanels/EstateTaxSummaryPanel/EstateTaxSummaryPanelTaxes';
import { AfterDeath } from '@/types/schema';

import {
  getWaterfallSummaryBarChartSections,
  useGetDefaultSections,
} from '../../EstateWaterfallSummaryBarCharts/hooks/useWaterfallSummaryBarChartSections';
import { EstateWaterfallComparisonTrowserComponentsProps } from '../EstateWaterfallComparisonTrowser.components';
import { COMPARISON_WATERFALL_LABELS } from '../EstateWaterfallComparisonTrowser.constants';
import { EstateWaterfallComparisonTrowserFormShape } from '../EstateWaterfallComparisonTrowser.type';
import { useTaxSummary } from '../hooks/useTaxSummary';

export interface EstateWaterfallComparisonTrowserBarGraphProps
  extends EstateWaterfallComparisonTrowserComponentsProps {
  afterDeath: AfterDeath;
}

export function EstateWaterfallComparisonTrowserBarGraph({
  waterfalls,
  afterDeath,
}: EstateWaterfallComparisonTrowserBarGraphProps) {
  const viewOnly = useViewOnly();
  const [{ isModalOpen, data }, { openModal, closeModal }] =
    useModalState<string>();

  const getDefaultSections = useGetDefaultSections();

  const waterfallsByDeath = useMemo(
    () =>
      flatMap(
        waterfalls.map(({ waterfallNode }) =>
          getWaterfallSummaryBarChartSections({
            waterfall: waterfallNode,
            getDefaultSections,
            onOpenSectionTaxModal: openModal,
            isTwoGrantorClient: waterfallNode.household?.isTwoGrantor ?? false,
            beforeFirstDeathTaxSummary: null,
            firstDeathTaxSummary:
              waterfallNode.visualizationWithProjections.firstDeathTaxSummary,
            secondDeathTaxSummary:
              waterfallNode.visualizationWithProjections.secondDeathTaxSummary,
          })
        )
      ),
    [getDefaultSections, openModal, waterfalls]
  );

  const bars = waterfallsByDeath
    .filter((wf) => wf.afterDeath === afterDeath)
    .map((wf, idx) => ({
      label: COMPARISON_WATERFALL_LABELS[idx],
      sections: wf.waterfallSections.map((section) => ({
        ...section,
        groupName: section.kind || '',
      })),
    }));

  return (
    <Box data-testid="waterfall-comparison-bar-graph">
      <StackedHorizontalBarGroup
        bars={bars}
        data-testid="waterfall-comparison-bar-graph"
      />
      {viewOnly ? null : (
        <TaxModalBody
          taxWaterfallId={data || ''}
          isOpen={isModalOpen}
          closeModal={() => closeModal()}
        />
      )}
    </Box>
  );
}

interface TaxModalBodyProps {
  taxWaterfallId: string;
  closeModal: () => void;
  isOpen: boolean;
}

function TaxModalBody({
  taxWaterfallId,
  isOpen: isModalOpen,
  closeModal,
}: TaxModalBodyProps) {
  const { control } =
    useFormContext<EstateWaterfallComparisonTrowserFormShape>();
  const [afterDeath] = useWatch({ control, name: ['afterDeath'] });

  const { firstDeathData, secondDeathData, taxSummary, loading } =
    useTaxSummary(taxWaterfallId);

  const totalsElements = {
    [AfterDeath.None]: (
      <EstateTaxSummaryPanelTaxes
        summaryPanelData={firstDeathData}
        afterDeath={AfterDeath.None}
        taxSummary={taxSummary.beforeFirstDeath}
      />
    ),
    [AfterDeath.First]: (
      <EstateTaxSummaryPanelTaxes
        summaryPanelData={firstDeathData}
        afterDeath={AfterDeath.First}
        taxSummary={taxSummary.firstDeath}
      />
    ),
    [AfterDeath.Second]: (
      <EstateTaxSummaryPanelTaxes
        summaryPanelData={secondDeathData}
        afterDeath={AfterDeath.Second}
        taxSummary={taxSummary.secondDeath}
      />
    ),
  };
  return (
    <Modal
      maxWidth="sm"
      isOpen={isModalOpen}
      onClose={closeModal}
      heading="Gift & estate tax due"
      rightHeaderContent={<CloseButton onClick={closeModal} />}
    >
      <LoadingOverlay open={loading} />

      <TabsProvider currentTab={afterDeath} disableExternalChange>
        <Calculations
          firstDeathData={firstDeathData}
          secondDeathData={secondDeathData}
          totalsElements={totalsElements}
        />
      </TabsProvider>
    </Modal>
  );
}
