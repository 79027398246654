import { useCallback } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { DeleteMenuItemWithConfirmationModal } from '@/components/poppers/MenuPopper/DeleteMenuItemWithConfirmationModal';
import { useReportError } from '@/hooks/useReportError';

import { useDeleteGrowthProfileMutation } from './graphql/DeleteGrowthProfile.generated';
import { GrowthProfilesTable_EstateWaterfallConnectionFragment } from './graphql/GrowthProfilesTable.generated';
import { Operations as GrowthProfilesTableOperations } from './graphql/GrowthProfilesTable.generated';

export interface GrowthProfileDeleteMenuItemProps {
  growthProfileId: string;
  isDefault: boolean;
  estateWaterfalls: GrowthProfilesTable_EstateWaterfallConnectionFragment;
  openDeleteUsedGrowthProfileModal: () => void;
}

export function GrowthProfileDeleteMenuItem({
  growthProfileId,
  isDefault,
  estateWaterfalls,
  openDeleteUsedGrowthProfileModal,
}: GrowthProfileDeleteMenuItemProps) {
  const isDeleteOnlyFlow = isDefault || estateWaterfalls?.totalCount === 0;

  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [deleteGrowthProfile, { loading: loadingDeleteGrowthProfile }] =
    useDeleteGrowthProfileMutation({
      refetchQueries: [GrowthProfilesTableOperations.Query.GrowthProfilesTable],
      onCompleted: () => {
        showFeedback('Growth profile deleted', { variant: 'success' });
      },
      onError: (error) => {
        showFeedback(
          'Failed to delete growth profile.  Please try again later.'
        );
        reportError('Failed to delete growth profile', error);
      },
    });

  const onConfirmDelete = useCallback(async () => {
    if (!isDeleteOnlyFlow) {
      openDeleteUsedGrowthProfileModal();
      return;
    }

    await deleteGrowthProfile({
      variables: {
        growthProfileId,
        updatedEstateWaterfalls: [],
        migrateEstateWaterfalls: false,
      },
    });
  }, [
    deleteGrowthProfile,
    growthProfileId,
    isDeleteOnlyFlow,
    openDeleteUsedGrowthProfileModal,
  ]);

  return (
    <DeleteMenuItemWithConfirmationModal
      label="Delete profile"
      key="menu-item-delete"
      onConfirmDelete={onConfirmDelete}
      modalProps={{
        heading: 'Are you sure you want to delete this profile?',
        confirmText: 'Delete',
        confirmButtonProps: {
          variant: 'destructive-prominent',
          loading: loadingDeleteGrowthProfile,
        },
        cancelButtonProps: {
          disabled: loadingDeleteGrowthProfile,
        },
      }}
      menuItemProps={{
        disabled: isDefault,
      }}
    />
  );
}
