import { ApolloError, BaseMutationOptions } from '@apollo/client';
import { Box, Card, Stack, Typography } from '@mui/material';
import { isEmpty, pick } from 'lodash';
import { useCallback } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import {
  FileUploader,
  FileUploaderProps,
} from '@/modules/files/FileUploader/FileUploader';
import { acceptedFileTypes } from '@/modules/files/FileUploader/fileUploaderUtils';
import { UploadedFileData } from '@/modules/files/FileUploader/useUploadFile';
import { getFullyQualifiedTenantURL, getTenant } from '@/utils/tenantUtils';

import {
  AdminBrandingPage_BrandingFragment,
  TenantBrandingDocument,
  UploadFirmLogoMutation,
  useUpdateBrandingMutation,
  useUpdateSubBrandBrandingMutation,
  useUploadFirmLogoMutation,
} from './graphql/AdminBrandingPage.generated';

const acceptedBrandingFileTypes = pick(acceptedFileTypes, [
  'image/png',
  'image/jpeg',
  'image/gif',
]);

export enum LogoType { // maps descriptive name to BE field name
  PrimaryWide = 'logoURL', // dark text for use on light backgrounds; used on proposals
  SecondarySquare = 'darkSquareLogoURL', // light logo only on dark background; used on collapsed sidebar
  SecondaryWide = 'darkWideLogoURL', // light full logo on dark background; used on expanded sidebar
}

const LogoTypeHeadings: Record<
  LogoType,
  { heading: string; subheading: string }
> = {
  [LogoType.PrimaryWide]: {
    heading: 'Default logo',
    subheading:
      'The primary logo for your firm, for use in presentations and emails. Should be optimized for use on a light background.',
  },
  [LogoType.SecondaryWide]: {
    heading: 'Default logo on dark background',
    subheading:
      'Optionally upload a light-colored version of your logo for use in the application side menu.',
  },
  [LogoType.SecondarySquare]: {
    heading: 'Square logo mark on dark background',
    subheading:
      'Upload a square version of your logo that works against dark backgrounds for use in the application side menu.',
  },
};

interface BrandingLogoUploaderProps {
  logoKey: LogoType;
  tenantBranding: AdminBrandingPage_BrandingFragment | undefined | null;
}

/**
 * @param path this is the presigned pathname to which we uploaded the logo file in AWS. It'll have a bunch of AWS-specific query params on it
 * and will look something like: "https://luminary-staging-ui-origin.s3.us-east-2.amazonaws.com/assets/tenant-branding/lum/logo-87829a8a-3056-4140-9831-cd49d2aa9f80?X-Amz-Algorithm=AWS4-HMAC-SHA256\u0026X-Amz-Credential=<encodedproperties>longX-Amz-SignedHeaders=host\u0026x-id=PutObject\u0026X-Amz-Signature=<signature>"
 * The only thing we actually care about is the upload path, which is in the shape of `/assets/tenant-branding/<subdomain>/logo-<uuid>`
 * @returns The `/assets/tenant-branding/<subdomain>/logo-${uuid}` string. Note that there's no extension, because AWS abstracts that away from us and we don't need to care.
 */
function getPathnameFromUploadURL(uploadUrl: string) {
  const url = new URL(uploadUrl);
  const pathName = url.pathname;
  if (!pathName) {
    throw new Error('Could not parse filename from upload path');
  }
  return pathName;
}

export function BrandingLogoUploader({
  logoKey,
  tenantBranding,
}: BrandingLogoUploaderProps) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const onError = useCallback(
    (err: ApolloError) => {
      showFeedback('There was an error updating the logo. Please try again.');
      reportError('error persisting the tenant branding change', err);
    },
    [reportError, showFeedback]
  );

  const onCompleted = useCallback(() => {
    showFeedback('Logo updated successfully.', { variant: 'success' });
  }, [showFeedback]);

  const [updateBranding] = useUpdateBrandingMutation({
    onError,
    onCompleted,
  });
  const [updateSubBrandBranding] = useUpdateSubBrandBrandingMutation({
    onError,
    onCompleted,
  });
  const [generatePresignedURL] = useUploadFirmLogoMutation({
    onError: (err) => {
      showFeedback('There was an error updating the logo. Please try again.');
      reportError('error fetching firm logo presigned URL', err);
    },
  });

  if (!tenantBranding) {
    return null;
  }

  const tenantLogoURL = tenantBranding[logoKey];

  async function generateUploadURL(
    hash: string,
    mutationOptions: Partial<BaseMutationOptions>
  ) {
    const queryProps = await generatePresignedURL({
      ...mutationOptions,
      variables: {
        input: {
          contentsSha256: hash,
        },
      },
    });

    const signedUploadURL = queryProps.data?.uploadTenantBrandingLogo.uploadURL;
    if (!signedUploadURL) {
      throw new Error('No errors, but no signed upload URL');
    }

    return { signedUploadURL, ...queryProps };
  }

  const updateBrandingLogo: FileUploaderProps<UploadFirmLogoMutation>['onUploadComplete'] =
    async (uploaded: UploadedFileData<UploadFirmLogoMutation>[]) => {
      if (isEmpty(uploaded)) {
        return;
      }

      // We're only handling one file at a time for now.
      const { result } = uploaded[0]!;

      const uploadPath = getPathnameFromUploadURL(
        result?.data?.uploadTenantBrandingLogo.uploadURL ?? ''
      );
      const tenantSubdomain = getTenant();
      const tenantUrl = getFullyQualifiedTenantURL(tenantSubdomain);

      if (tenantBranding.__typename === 'SubBrand') {
        await updateSubBrandBranding({
          variables: {
            input: {
              id: tenantBranding.id,
              update: {
                [logoKey]: `${tenantUrl}${uploadPath}`,
              },
            },
          },
        });
      } else {
        await updateBranding({
          variables: {
            id: tenantBranding.id,
            input: {
              [logoKey]: `${tenantUrl}${uploadPath}`,
            },
          },
          refetchQueries: [TenantBrandingDocument],
        });
      }
    };

  const { heading, subheading } = LogoTypeHeadings[logoKey];
  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h3">{heading}</Typography>
        <Typography variant="caption">{subheading}</Typography>
      </Box>
      <Stack spacing={2}>
        <FileUploader<UploadFirmLogoMutation>
          acceptTypes={acceptedBrandingFileTypes}
          errorMessage={''}
          onUploadComplete={updateBrandingLogo}
          generateSignedUploadURL={generateUploadURL}
        />
        {/* TODO was the logo URL placeholder on the backend for a while; can delete this at some point */}
        {tenantLogoURL && tenantLogoURL !== 'TODO' && (
          <Card variant="outlined" sx={{ p: 1 }}>
            <img
              // use the natural height of the image, but constrain it to the bounds of the container
              style={{ width: '100%' }}
              src={tenantLogoURL}
              alt=""
            />
          </Card>
        )}
      </Stack>
    </Box>
  );
}
