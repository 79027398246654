import React from 'react';

import { PopperContent } from '@/components/poppers/PopperContent';

export function EstateWaterfallExemptionSunset() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body="Exemptions increase annually at the specified exemption growth rate, and are set to sunset, or be reduced to half of the current exemption amount, at the end of 2025."
    />
  );
}
