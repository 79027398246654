import { Typography } from '@mui/material';
import { useCallback } from 'react';

import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { Stars01Icon } from '@/components/icons/Stars01Icon';
import { ConfirmationModal } from '@/components/modals/ConfirmationModal/ConfirmationModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { COPY_ALL_EVENT } from '@/components/TextEditor/plugins/CopyAllPlugin/CopyAllPlugin';
import { getEditorElement } from '@/components/TextEditor/TextEditor.utils';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { useRegenAiSummary } from '@/modules/documents/RegenAiSummaryMenuItem/hooks/useRegenAiSummary';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { COLORS } from '@/styles/tokens/colors';
import { getNodes } from '@/utils/graphqlUtils';

import { convertMarkdownToWord } from '../printOnly/hooks/useMarkdownToWordDocument';
import { useDocumentDetailsQuery } from './hooks/useDocumentDetailsQuery';

interface DocumentDetailsPageContextMenuProps {
  documentId: string;
  documentSummaryId: string;
  startPollingDocumentSummary: (interval: number) => void;
}

export function DocumentDetailsPageContextMenu({
  documentId,
  documentSummaryId,
  startPollingDocumentSummary,
}: DocumentDetailsPageContextMenuProps) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  const { asyncRegen } = useRegenAiSummary(documentId, documentSummaryId);

  const docSummaryExportEnabled = useFeatureFlag('document_summary_exports');

  const handleCloseConfirmationModal = useCallback(
    (result: 'confirm' | 'cancel') => {
      if (result === 'confirm') {
        void asyncRegen();
        // Shorter poll interval than the default 5 seconds
        // because just summarizing the document should be faster
        startPollingDocumentSummary(1000);
      }
      closeModal();
    },
    [asyncRegen, closeModal, startPollingDocumentSummary]
  );

  const handleCopySummary = useCallback(() => {
    // We use DOM events to communicate between the context menu and the editor
    const editor = getEditorElement();
    if (editor) {
      editor.dispatchEvent(new Event(COPY_ALL_EVENT));
    } else {
      reportError(
        'Failed to copy summary to clipboard',
        new Error('Could not find editor element')
      );
      showFeedback('Failed to copy summary to clipboard');
    }
  }, [reportError, showFeedback]);

  const documentDetailsQueryProps = useDocumentDetailsQuery(documentId);

  const documentSummary =
    getNodes(documentDetailsQueryProps.data?.documents)[0]?.documentSummary
      ?.summary || '';

  const handleExportToWord = useCallback(async () => {
    try {
      await convertMarkdownToWord(documentSummary);
    } catch (error) {
      reportError('Failed to export summary to Word document', error as Error);
      showFeedback('Failed to export summary to Word document');
    }
  }, [documentSummary, reportError, showFeedback]);

  return (
    <>
      <ConfirmationModal
        isOpen={isModalOpen}
        heading="Generate a new summary using Luminary AI"
        cancelText="Cancel"
        confirmText="Rerun"
        confirmButtonProps={{ variant: 'primary' }}
        onClose={handleCloseConfirmationModal}
      >
        <Typography variant="h6" component="p" color={COLORS.ORANGE[600]}>
          This cannot be undone, any previous edits will be lost.
        </Typography>
      </ConfirmationModal>
      <ContextMenuButton>
        {docSummaryExportEnabled && (
          <>
            <SelectItemGroupLabel label="Export summary as" />
            <MenuItem
              label="Microsoft Word"
              onClick={handleExportToWord}
              muiMenuItemProps={{
                divider: true,
              }}
            />
          </>
        )}
        <MenuItem
          label="Re-generate AI summary"
          icon={<Stars01Icon size={18} color={COLORS.TEAL[600]} />}
          onClick={openModal}
          muiMenuItemProps={{
            divider: !docSummaryExportEnabled,
          }}
        />
        <MenuItem
          label="Copy summary to clipboard"
          onClick={handleCopySummary}
        />
      </ContextMenuButton>
    </>
  );
}
