import { compact, difference, isEmpty } from 'lodash';

import {
  ENTITY_TYPES,
  TRUST_ENTITY_TYPES,
} from '@/modules/entities/entities.constants';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  CreateEntityInput,
  DocumentType,
  EntityAttributionSource,
  EntityStage,
  UpdateEntityInput,
} from '@/types/schema';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { BASIC_ASSETS_SUBFORM as BASIC_ASSETS } from '../../../BasicAssetsSubform/BasicAssetsSubform.types';
import { BASIC_INFORMATION_SUBFORM_NAMESPACE as BASIC_INFORMATION } from '../../../BasicInformationSubform/BasicInformationSubform.types';
import { SubformsCombinedType } from '../../EntitySubforms.types';

/**
 * @description this function generates the update input for the entity body to set or clear the addepar entity ID.
 * Note that it *always* returns a set or clear update, so there is a risk that if we choose to decompose the form and
 * reuse these inputs, we may add a check to see if the user is actually interacting with something that would indicate
 * that they actually want to change or clear the addepar entity ID.
 */
export function getSharedAssetIntegrationUpdateProperties(
  formInput: Partial<SubformsCombinedType>
): Partial<UpdateEntityInput> {
  const nextIntegrationEntityIds = compact(
    getTypeOrUndefined<string[]>(
      formInput[BASIC_ASSETS]?.basicAssets?.integrationEntityIds
    )
  );
  const previousIntegrationEntityIds = compact(
    getTypeOrUndefined<string[]>(
      formInput[BASIC_ASSETS]?.basicAssets?._previousIntegrationEntityIds
    )
  );
  const linkedToNonGrantorEntity = getTypeOrUndefined<boolean>(
    formInput[BASIC_ASSETS]?.basicAssets?.linkToAllAddeparEntities
  );

  const addedIntegrationEntityIds = difference(
    nextIntegrationEntityIds,
    previousIntegrationEntityIds
  );
  const removedIntegrationEntityIds = difference(
    previousIntegrationEntityIds,
    nextIntegrationEntityIds
  );

  const removalProperties = {
    removeIntegrationEntityIDs: removedIntegrationEntityIds,
  };

  if (!isEmpty(nextIntegrationEntityIds)) {
    return {
      addIntegrationEntityIDs: addedIntegrationEntityIds,
      ...removalProperties,
      addeparLinkedToNongrantorEntity: linkedToNonGrantorEntity,
    };
  }

  return {
    // don't use `clearIntegrationEntities` here because it removes *all* integration entities
    // and we don't want to remove any potential CSV_IMPORT integration entities
    ...removalProperties,
    clearAddeparLinkedToNongrantorEntity: true,
  };
}

/**
 * @description This function gets the shared top-level properties that go under the `create` property for new entities.
 */
export function getCreateEntityInputCommonProps(
  formInput: SubformsCombinedType,
  householdId: string
): CreateEntityInput {
  const integrationEntityIDs = compact(
    getTypeOrUndefined<string[]>(
      formInput[BASIC_ASSETS]?.basicAssets?.integrationEntityIds
    )
  );
  return {
    integrationEntityIDs: isEmpty(integrationEntityIDs)
      ? null
      : integrationEntityIDs,
    householdID: householdId,
    documentIDs: formInput[BASIC_INFORMATION].documentIds,
    attributionSource: EntityAttributionSource.IntakeForm,
    // Not every variant of basicInformation form has this isCompleted field, but because
    // entities created with the entity subforms default to active, it feels okay to put this
    // isCompleted logic here.
    stage: formInput[BASIC_INFORMATION]?.isCompleted
      ? EntityStage.Completed
      : EntityStage.Active,
  };
}

/**
 * @param beneficiaryModelId this is a pulid; either a client profile, entity, or organization
 */
export function getRelatedModelInputProperties(modelId: string) {
  const pulidKind = getPulidKind(modelId);

  if (!pulidKind) {
    throw new Error('Could not get pulid kind');
  }

  const modelInputIds: {
    entityID?: string;
    individualID?: string;
    organizationID?: string;
  } = {};

  if (pulidKind === PulidKind.Entity) {
    modelInputIds.entityID = modelId;
  } else if (pulidKind === PulidKind.ClientProfile) {
    modelInputIds.individualID = modelId;
  } else if (pulidKind === PulidKind.ClientOrganization) {
    modelInputIds.organizationID = modelId;
  } else {
    throw new Error(`Unexpected pulid kind ${pulidKind}`);
  }

  return modelInputIds;
}

export function getTypeOrUndefined<T>(value: unknown): T | undefined {
  if (value === '') {
    return undefined;
  }

  if (Number.isNaN(value)) {
    return undefined;
  }

  if (value === null) {
    return undefined;
  }

  return value as T;
}

export function getDescriptionUpdate(text: string | undefined) {
  const description = getTypeOrUndefined<string>(text);
  if (!description) {
    return {
      clearDescription: true,
    };
  }

  return {
    description,
  };
}

/**
 * @description This function determines what document type to associate with a document uploaded on entity creation
 * @param entityType the type selected in the create entity form
 * @returns the type the document should have when created. Defaults to OTHER.
 */
export function getDocumentTypeFromEntityType(
  entityType: EntityType | ''
): DocumentType {
  if (TRUST_ENTITY_TYPES.includes(entityType as EntityType))
    return DocumentType.SignedTrustDocument;
  else if (entityType === ENTITY_TYPES.LLC) return DocumentType.LlcAgreement;
  else if (entityType === ENTITY_TYPES.LP || entityType === ENTITY_TYPES.GP)
    return DocumentType.PartnershipAgreement;
  else if (
    entityType === ENTITY_TYPES.S_CORP ||
    entityType === ENTITY_TYPES.C_CORP ||
    entityType === ENTITY_TYPES.PRIVATE_FOUNDATION
  )
    return DocumentType.OperatingAgreement;
  else if (entityType === ENTITY_TYPES.DAF)
    return DocumentType.AccountDocumentationStatement;
  return DocumentType.Other;
}
