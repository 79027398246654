import { Typography } from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

export interface RelativeFormattedDateProps {
  severity: 'error' | 'warning' | 'success' | 'standard';
}

export function RelativeFormattedDate({
  severity,
  children,
}: React.PropsWithChildren<RelativeFormattedDateProps>) {
  switch (severity) {
    case 'error':
      return (
        <Typography
          variant="h5"
          component="div"
          color={COLORS.FUNCTIONAL.ERROR[700]}
        >
          {children}
        </Typography>
      );
    case 'warning':
      return (
        <Typography
          variant="h5"
          component="div"
          color={COLORS.FUNCTIONAL.WARNING[700]}
        >
          {children}
        </Typography>
      );
    case 'success':
      return (
        <Typography
          variant="h5"
          component="div"
          color={COLORS.FUNCTIONAL.SUCCESS[700]}
        >
          {children}
        </Typography>
      );
    default:
      return (
        <Typography variant="body1" component="div">
          {children}
        </Typography>
      );
  }
}
