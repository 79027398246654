import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

import { GrowthProfilesTable_GrowthProfileFragment } from './graphql/GrowthProfilesTable.generated';

export interface GrowthProfilesTableContextType {
  growthProfiles: GrowthProfilesTable_GrowthProfileFragment[];
}

export const GrowthProfilesTableContext = createContext<
  GrowthProfilesTableContextType | undefined
>(undefined);

export interface GrowthProfilesTableProviderProps
  extends GrowthProfilesTableContextType {
  children: React.ReactNode;
}

export function GrowthProfilesTableProvider({
  children,
  growthProfiles,
}: GrowthProfilesTableProviderProps) {
  return (
    <GrowthProfilesTableContext.Provider value={{ growthProfiles }}>
      {children}
    </GrowthProfilesTableContext.Provider>
  );
}

export function useGrowthProfilesTableContext() {
  return useGuardedContext(
    GrowthProfilesTableContext,
    'GrowthProfilesTableContext'
  );
}
