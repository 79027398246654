import { Grid } from '@mui/material';

import {
  FormAwareFormattedNumberInput,
  FormAwareFormattedNumberInputProps,
} from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import {
  FormAwareSelectInput,
  FormAwareSelectInputProps,
} from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { SuggestableFormAwareInput } from '@/modules/aiSuggestions/components/forms/SuggestableFormAwareInput';
import { AiSuggestionKind } from '@/types/schema';

import {
  BUSINESS_ENTITY_TAX_STATUS_ITEMS,
  Fields,
  NAMESPACE,
  SubformField,
} from './BusinessEntitiesTaxStatusSubform.types';

export function UI() {
  const { control } = useFormContext<Fields>();

  return (
    <Grid container spacing={3} columns={12}>
      <Grid item sm={12}>
        <FormLayoutRow>
          <FormLayoutItem width={6}>
            <SuggestableFormAwareInput<
              Fields,
              FormAwareFormattedNumberInputProps<Fields>
            >
              FormAwareElement={FormAwareFormattedNumberInput}
              control={control}
              formatHashString="##-#######"
              fieldName={`${NAMESPACE}.taxId` as const satisfies SubformField}
              label="Tax ID (EIN)"
              suggestionKind={AiSuggestionKind.TaxId}
            />
          </FormLayoutItem>
          <FormLayoutItem width={6}>
            <SuggestableFormAwareInput<
              Fields,
              FormAwareSelectInputProps<Fields>
            >
              FormAwareElement={FormAwareSelectInput}
              control={control}
              fieldName={
                `${NAMESPACE}.taxStatus` as const satisfies SubformField
              }
              label="Tax status"
              options={BUSINESS_ENTITY_TAX_STATUS_ITEMS}
              suggestionKind={AiSuggestionKind.BusinessEntityTaxStatus}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      </Grid>
    </Grid>
  );
}
