import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';

import { ArrowCircleRightIcon } from '@/components/icons/ArrowCircleRightIcon';
import {
  CellContainer,
  PrimaryCellTypography,
  SecondaryCellTypography,
} from '@/components/tables/DataTable/components/cells';
import { AvatarRenderer } from '@/components/tables/DataTable/renderers/cell/AvatarRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { renderNestedCellsEmpty } from '@/components/tables/DataTable/utils/renderUtils';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { COLORS } from '@/styles/tokens/colors';
import { formatDateToTimestamp } from '@/utils/formatting/dates';
import { emptyDefault } from '@/utils/formatting/emptyDefault';
import { getFormattedDate } from '@/utils/formatting/getFormattedDate';

import { RowData } from './types';

const timestampRenderer = TwoLineTextRenderer<RowData>({
  lineOne: ({ row: { timestamp } }) => getFormattedDate(timestamp),
  lineTwo: ({ row: { timestamp } }) => formatDateToTimestamp(timestamp),
});

const userRenderer = AvatarRenderer<RowData>({
  avatarProps: ({ row }) => ({
    fullName: `${row.user?.firstName} ${row.user?.lastName}`,
    initials: row.user?.initials,
  }),
});

export const columns: Column<RowData>[] = [
  {
    field: 'description',
    headerName: 'Activity',
    flex: 4,
    renderCell: TwoLineTextRenderer(),
    sortable: false,
  },
  {
    field: 'datapoint',
    headerName: 'Datapoint',
    flex: 2,
    renderCell: ({ row: { changes }, rowNode }) => {
      const isGroup = rowNode.type === 'group';

      if (isEmpty(changes)) {
        return (
          <CellContainer>
            <Stack textOverflow="ellipsis" overflow="hidden">
              <SecondaryCellTypography>
                No tracked fields changed
              </SecondaryCellTypography>
            </Stack>
          </CellContainer>
        );
      }

      return (
        <CellContainer>
          <Stack textOverflow="ellipsis" overflow="hidden">
            {changes.map(({ fieldName, fieldDisplayName }) => {
              if (isGroup) {
                return (
                  <SecondaryCellTypography key={fieldName}>
                    Multiple actions
                  </SecondaryCellTypography>
                );
              }

              return (
                <PrimaryCellTypography key={fieldName} fontWeight={700}>
                  {fieldDisplayName}
                </PrimaryCellTypography>
              );
            })}
          </Stack>
        </CellContainer>
      );
    },
  },
  {
    field: 'delta',
    headerName: 'Delta',
    flex: 4,
    renderCell: ({ row: { changes }, rowNode }) => {
      const isGroup = rowNode.type === 'group';
      if (isGroup) return <CellContainer />;
      return (
        <CellContainer>
          <Stack textOverflow="ellipsis" overflow="hidden">
            {changes.map(({ fieldName, oldValue, newValue }) => {
              return (
                <Stack direction="row" key={fieldName} gap={1}>
                  <PrimaryCellTypography>
                    {emptyDefault(oldValue, EMPTY_CONTENT_HYPHEN)}
                  </PrimaryCellTypography>
                  <ArrowCircleRightIcon
                    size={16}
                    sx={{ color: COLORS.GRAY[300] }}
                  />
                  <PrimaryCellTypography>
                    {emptyDefault(newValue, EMPTY_CONTENT_HYPHEN)}
                  </PrimaryCellTypography>
                </Stack>
              );
            })}
          </Stack>
        </CellContainer>
      );
    },
  },
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    flex: 1,
    renderCell: renderNestedCellsEmpty(timestampRenderer),
  },
  {
    field: 'user',
    headerName: 'User',
    flex: 2,
    renderCell: renderNestedCellsEmpty(userRenderer),
  },
];
