import { ApolloError, QueryResult } from '@apollo/client';
import { addDays, isAfter, isBefore } from 'date-fns';
import { difference, noop } from 'lodash';
import { UseFormReset } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import {
  AugmentedUpdateEntityInput,
  EntityTaskCompletionState,
  Exact,
  UpdateInsurancePremiumPaymentInput,
} from '@/types/schema';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { getNodes } from '@/utils/graphqlUtils';

import {
  GetIlitPremiumPaymentTaskDetails_IlitCrummeyLetterFragment,
  GetIlitPremiumPaymentTaskDetailsQuery,
  useGetIlitPremiumPaymentTaskDetailsQuery,
} from './graphql/GetILITPremiumPaymentTaskDetails.generated';
import { ILITPremiumPaymentTaskForm } from './ILITPremiumPaymentTask.types';

export function getCrummeyLetterMessage(
  associatedCrummeyLetter: GetIlitPremiumPaymentTaskDetails_IlitCrummeyLetterFragment | null
): {
  showCrummeyLetterMessage: boolean;
  crummeyLetterMessageHeader: string;
  crummeyLetterMessage: string;
} | null {
  if (!associatedCrummeyLetter) {
    return null;
  }

  let showCrummeyLetterMessage = false;
  let crummeyLetterMessageHeader = ``;
  let crummeyLetterMessage = ``;

  const crummeyWithdrawalPeriod =
    associatedCrummeyLetter?.crummeyWithdrawalPeriodDays;

  if (typeof crummeyWithdrawalPeriod === 'undefined') {
    throw new Error('Invalid Crummey withdrawal period provided');
  }

  const crummeyLetterSendDate = associatedCrummeyLetter?.sentOn || null;
  const crummeyLetterSkipped =
    !associatedCrummeyLetter?.sentOn &&
    associatedCrummeyLetter?.associatedTask?.completionState ===
      EntityTaskCompletionState.Cancelled;

  const currentDate = new Date();
  if (!associatedCrummeyLetter.sendBefore) {
    throw new Error('Could not get Crummey letter send by date');
  }

  if (crummeyLetterSkipped) {
    // don't show the message if the crummey letter was skipped
    noop();
  } else if (!crummeyLetterSendDate) {
    // show message if the letter hasn't been sent
    showCrummeyLetterMessage = true;
    crummeyLetterMessageHeader = `Do NOT submit payment`;
    crummeyLetterMessage = `Crummey letters have not been sent, and must be sent by ${formatDateToMonDDYYYY(
      associatedCrummeyLetter.sendBefore
    )}`;
  } else {
    const crummeyWithdrawalPeriodEndDate = addDays(
      crummeyLetterSendDate,
      crummeyWithdrawalPeriod
    );

    if (isBefore(crummeyWithdrawalPeriodEndDate, currentDate)) {
      // don't show the message if the crummey letter was sent, but the withdrawal period ended
      noop();
    } else if (isAfter(crummeyWithdrawalPeriodEndDate, currentDate)) {
      // show message if the letter was sent, but still in the withdrawal period
      showCrummeyLetterMessage = true;
      crummeyLetterMessageHeader = `Do NOT submit payment prior to ${formatDateToMonDDYYYY(
        addDays(crummeyLetterSendDate, crummeyWithdrawalPeriod)
      )}`;
      crummeyLetterMessage = `Crummey letters were sent ${formatDateToMonDDYYYY(
        crummeyLetterSendDate
      )} with a ${crummeyWithdrawalPeriod} day withdrawal period`;
    }
  }

  return {
    showCrummeyLetterMessage,
    crummeyLetterMessageHeader,
    crummeyLetterMessage,
  };
}

export function useFormData(
  taskId: string,
  reset: UseFormReset<ILITPremiumPaymentTaskForm>
): QueryResult<
  GetIlitPremiumPaymentTaskDetailsQuery,
  Exact<{
    taskId: string;
  }>
> {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  return useGetIlitPremiumPaymentTaskDetailsQuery({
    variables: { taskId },
    onError: (error: ApolloError) => {
      showFeedback(
        'Could not load premium payment task details.  Please refresh and try again later.',
        { variant: 'error' }
      );
      reportError('Could not load premium payment task details.', error);
    },
    onCompleted: (data) => {
      const task = getNodes(data?.entityTasks).find(
        (task) => task.id === taskId
      );

      const policy = task?.entity?.ilitTrust?.policies?.find(
        (policy) => !!policy
      );

      if (!policy) {
        throw new Error('Could not get associated policy.');
      }

      const premiumPayment = task?.premiumPayment;

      if (!premiumPayment) {
        throw new Error('Could not find associated premium payment');
      }

      const crummeyLetter = task?.taskDependencies?.[0]?.ilitCrummeyLetter;

      reset({
        documentIds: [],
        policyId: policy.id,
        ilitTrustId: task?.entity?.ilitTrust?.id,
        premiumPaymentDate: premiumPayment?.paidOn || null,
        premiumPaymentId: premiumPayment.id,
        premiumAmount: policy.premiumAmount || null,
        premiumDueDate: premiumPayment.dueOn,
        premiumFrequency: policy.premiumFrequency,
        carrierName: policy.carrierName ?? '',
        policyNumber: policy.policyNumber || '',
        policyKind: policy.kind,
        associatedCrummeyLetter: crummeyLetter || null,
      });
    },
  });
}

export function generateCompleteTaskPayload(
  formData: ILITPremiumPaymentTaskForm,
  taskId: string,
  entityId: string,
  initialDocumentIds: string[]
): {
  updateEntityInput: AugmentedUpdateEntityInput;
  taskId: string;
} {
  if (!entityId) {
    throw new Error('Entity ID is required');
  }

  if (!formData.ilitTrustId) {
    throw new Error('ILIT Trust ID is required');
  }

  if (!formData.policyId) {
    throw new Error('Policy ID is required');
  }

  if (!formData.premiumPaymentId) {
    throw new Error('Payment ID is required');
  }

  if (!formData.premiumPaymentDate) {
    throw new Error('Premium payment date is required');
  }

  const update: UpdateInsurancePremiumPaymentInput = {
    paidOn: formData.premiumPaymentDate,
    premiumAmount: formData.premiumAmount,
  };

  const addDocumentIDs = difference(formData.documentIds, initialDocumentIds);
  const removeDocumentIDs = difference(
    initialDocumentIds,
    formData.documentIds
  );

  if (
    !addDocumentIDs.length &&
    removeDocumentIDs.length === initialDocumentIds.length
  ) {
    update.clearDocuments = true;
  } else {
    update.addDocumentIDs = addDocumentIDs;
    update.removeDocumentIDs = removeDocumentIDs;
  }

  const updateEntityInput: AugmentedUpdateEntityInput = {
    id: entityId,
    updateIlitTrust: {
      id: formData.ilitTrustId,
      update: {},
      updatePolicies: [
        {
          id: formData.policyId,
          update: {},
          updatePremiumPayments: [
            {
              id: formData.premiumPaymentId,
              update,
            },
          ],
        },
      ],
    },
    update: {},
  };
  return { updateEntityInput, taskId };
}
