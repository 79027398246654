import { FitViewOptions, useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { Node } from '@/components/diagrams/FlowChart';

export function useFitViewOptions() {
  const { getNodes } = useReactFlow();

  const getFitViewOptions = useCallback(
    (includeSectionLabels = false): FitViewOptions => {
      return {
        nodes: (getNodes() as Node[]).filter((n) => {
          if (includeSectionLabels) return true;
          return n.type !== 'sectionLabel';
        }),
      };
    },
    [getNodes]
  );

  return { getFitViewOptions };
}
