import React, { useCallback, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { ConfirmationModal } from '@/components/modals/ConfirmationModal/ConfirmationModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useAsyncJobNotificationsContext } from '@/modules/asyncJobs/AsyncJobNotifications/context/AsyncJobNotifications.context';
import {
  FetchInitialAsyncJobNotificationsDocument,
  FetchInitialAsyncJobNotificationsQuery,
} from '@/modules/asyncJobs/AsyncJobNotifications/graphql/asyncJobNotifications.generated';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { useDeleteHouseholdMutation } from '@/modules/household/graphql/Households.generated';

interface DeleteHouseholdButtonProps {
  householdId: string;
  onDelete: () => void;
}

export function DeleteHouseholdButton({
  householdId,
  onDelete,
}: DeleteHouseholdButtonProps) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const user = useCurrentUser();
  const { removeHouseholdNotifications } = useAsyncJobNotificationsContext();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [deleteHousehold] = useDeleteHouseholdMutation({
    onCompleted: () => {
      showFeedback('Client deleted successfully', { variant: 'success' });
      onDelete();
      removeHouseholdNotifications(householdId);
      setIsModalOpen(false);
    },
    onError: (error) => {
      showFeedback('Failed to delete household');
      reportError('Failed to delete household', error);
      setIsModalOpen(false);
    },
    update(cache) {
      // Evict the deleted household
      cache.evict({
        id: cache.identify({
          __typename: 'Household',
          id: householdId,
        }),
      });

      // Update the cache for the AIOnboarding notifications related to the deleted household
      const existingData =
        cache.readQuery<FetchInitialAsyncJobNotificationsQuery>({
          query: FetchInitialAsyncJobNotificationsDocument,
          variables: { userID: user?.id },
        });
      // Filter out AIOnboarding notification nodes that reference the deleted household
      if (!existingData?.aiOnboarding?.edges) return;
      const nonDeletedEdges = existingData.aiOnboarding.edges?.filter(
        (edge) => {
          return edge?.node?.household?.id !== householdId;
        }
      );
      // Write the updated data back to the cache
      cache.writeQuery<FetchInitialAsyncJobNotificationsQuery>({
        query: FetchInitialAsyncJobNotificationsDocument,
        variables: { userID: user?.id },
        data: {
          ...existingData,
          aiOnboarding: {
            ...existingData.aiOnboarding,
            edges: nonDeletedEdges,
          },
        },
      });
    },
  });

  const handleDelete = useCallback(() => {
    void deleteHousehold({ variables: { id: householdId } });
  }, [deleteHousehold, householdId]);

  return (
    <>
      <Button
        variant="destructive"
        size="sm"
        data-testid="DeleteHouseholdButton"
        onClick={() => setIsModalOpen(true)}
      >
        Delete client
      </Button>
      <ConfirmationModal
        isOpen={isModalOpen}
        heading="Delete client"
        onClose={(reason) => {
          if (reason === 'confirm') {
            handleDelete();
          } else {
            setIsModalOpen(false);
          }
        }}
        confirmText="Delete"
        confirmButtonProps={{
          variant: 'destructive-prominent',
        }}
      >
        Are you sure you want to delete this client? This action is
        irreversible.
      </ConfirmationModal>
    </>
  );
}
