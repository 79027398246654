// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientPeopleListViewTable_ClientProfileFragment = { __typename?: 'ClientProfile', id: string, legalName: string, displayName: string, isGrantor: boolean, isPrimary: boolean, isBeneficiary: boolean, isTrustee: boolean, professionalTeamRoles: { __typename?: 'ProfessionalTeamRoleConnection', edges?: Array<{ __typename?: 'ProfessionalTeamRoleEdge', node?: { __typename?: 'ProfessionalTeamRole', id: string, kind: Types.ProfessionalTeamRoleKind, otherRoleName?: string | null, powerOfAttorneyKind?: Types.ProfessionalTeamRolePowerOfAttorneyKind | null } | null } | null> | null } };

export type ClientPeopleListViewTable_ClientOrganizationFragment = { __typename?: 'ClientOrganization', id: string, kind?: Types.ClientOrganizationKind | null, name: string };

export type ClientPeopleListViewTableQueryVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
}>;


export type ClientPeopleListViewTableQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string }> | null, clientProfiles?: Array<{ __typename?: 'ClientProfile', id: string, legalName: string, displayName: string, isGrantor: boolean, isPrimary: boolean, isBeneficiary: boolean, isTrustee: boolean, professionalTeamRoles: { __typename?: 'ProfessionalTeamRoleConnection', edges?: Array<{ __typename?: 'ProfessionalTeamRoleEdge', node?: { __typename?: 'ProfessionalTeamRole', id: string, kind: Types.ProfessionalTeamRoleKind, otherRoleName?: string | null, powerOfAttorneyKind?: Types.ProfessionalTeamRolePowerOfAttorneyKind | null } | null } | null> | null } }> | null, clientOrganizations?: Array<{ __typename?: 'ClientOrganization', id: string, kind?: Types.ClientOrganizationKind | null, name: string }> | null } | null } | null> | null }, primaryClients: { __typename?: 'ClientProfileConnection', edges?: Array<{ __typename?: 'ClientProfileEdge', node?: { __typename?: 'ClientProfile', id: string, legalName: string, displayName: string, isGrantor: boolean, isPrimary: boolean, isBeneficiary: boolean, isTrustee: boolean, professionalTeamRoles: { __typename?: 'ProfessionalTeamRoleConnection', edges?: Array<{ __typename?: 'ProfessionalTeamRoleEdge', node?: { __typename?: 'ProfessionalTeamRole', id: string, kind: Types.ProfessionalTeamRoleKind, otherRoleName?: string | null, powerOfAttorneyKind?: Types.ProfessionalTeamRolePowerOfAttorneyKind | null } | null } | null> | null } } | null } | null> | null } };

export const ClientPeopleListViewTable_ClientProfileFragmentDoc = gql`
    fragment ClientPeopleListViewTable_ClientProfile on ClientProfile {
  id
  legalName
  displayName
  isGrantor
  isPrimary
  isBeneficiary
  isTrustee
  professionalTeamRoles {
    edges {
      node {
        id
        kind
        otherRoleName
        powerOfAttorneyKind
      }
    }
  }
}
    `;
export const ClientPeopleListViewTable_ClientOrganizationFragmentDoc = gql`
    fragment ClientPeopleListViewTable_ClientOrganization on ClientOrganization {
  id
  kind
  name
}
    `;
export const ClientPeopleListViewTableDocument = gql`
    query ClientPeopleListViewTable($householdId: ID!) {
  households(where: {id: $householdId}) {
    edges {
      node {
        integrationClients {
          id
        }
        clientProfiles {
          id
          ...ClientPeopleListViewTable_ClientProfile
        }
        clientOrganizations {
          id
          ...ClientPeopleListViewTable_ClientOrganization
        }
        id
      }
    }
  }
  primaryClients: clientProfiles(
    where: {and: [{hasHouseholdWith: {id: $householdId}}, {isPrimary: true}]}
    orderBy: {direction: ASC, field: created_at}
  ) {
    edges {
      node {
        id
        ...ClientPeopleListViewTable_ClientProfile
      }
    }
  }
}
    ${ClientPeopleListViewTable_ClientProfileFragmentDoc}
${ClientPeopleListViewTable_ClientOrganizationFragmentDoc}`;

/**
 * __useClientPeopleListViewTableQuery__
 *
 * To run a query within a React component, call `useClientPeopleListViewTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientPeopleListViewTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientPeopleListViewTableQuery({
 *   variables: {
 *      householdId: // value for 'householdId'
 *   },
 * });
 */
export function useClientPeopleListViewTableQuery(baseOptions: Apollo.QueryHookOptions<ClientPeopleListViewTableQuery, ClientPeopleListViewTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientPeopleListViewTableQuery, ClientPeopleListViewTableQueryVariables>(ClientPeopleListViewTableDocument, options);
      }
export function useClientPeopleListViewTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientPeopleListViewTableQuery, ClientPeopleListViewTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientPeopleListViewTableQuery, ClientPeopleListViewTableQueryVariables>(ClientPeopleListViewTableDocument, options);
        }
export function useClientPeopleListViewTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientPeopleListViewTableQuery, ClientPeopleListViewTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientPeopleListViewTableQuery, ClientPeopleListViewTableQueryVariables>(ClientPeopleListViewTableDocument, options);
        }
export type ClientPeopleListViewTableQueryHookResult = ReturnType<typeof useClientPeopleListViewTableQuery>;
export type ClientPeopleListViewTableLazyQueryHookResult = ReturnType<typeof useClientPeopleListViewTableLazyQuery>;
export type ClientPeopleListViewTableSuspenseQueryHookResult = ReturnType<typeof useClientPeopleListViewTableSuspenseQuery>;
export type ClientPeopleListViewTableQueryResult = Apollo.QueryResult<ClientPeopleListViewTableQuery, ClientPeopleListViewTableQueryVariables>;
export const Operations = {
  Query: {
    ClientPeopleListViewTable: 'ClientPeopleListViewTable' as const
  },
  Fragment: {
    ClientPeopleListViewTable_ClientProfile: 'ClientPeopleListViewTable_ClientProfile' as const,
    ClientPeopleListViewTable_ClientOrganization: 'ClientPeopleListViewTable_ClientOrganization' as const
  }
}