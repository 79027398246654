import { ViewOnlyEntityValuation } from '../EntityValuation/EntityValuation';
import { EntityDetail_EntityFragment } from '../graphql/EntityDetailPage.generated';

export interface ViewOnlyAssetsCardProps {
  entity: EntityDetail_EntityFragment;
}

export function PrintOnlyAssetsCard({ entity }: ViewOnlyAssetsCardProps) {
  return <ViewOnlyEntityValuation variant="card" entity={entity} />;
}
