import Decimal from 'decimal.js';
import { compact } from 'lodash';

import {
  AugmentedCreateLifetimeExclusionEventInput,
  AugmentedCreateLoggedTransferInput,
  AugmentedUpdateAccountInput,
  AugmentedUpdateEntityInput,
  ClientProfileRelationshipType,
  CreateLoggedTransferInput,
  EntityKind,
  LifetimeExclusionEventKind,
} from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import { GetIlitAnnualExclusionGiftsTaskDetailsQuery } from './graphql/GetILITAnnualExclusionGiftsTaskDetails.generated';
import { IlitMakeAnnualExclusionGiftMutationVariables } from './graphql/ILITMakeAnnualExclusionGift.generated';
import {
  ILITAnnualExclusionGrantor,
  ILITMakeAnnualExclusionGiftsForm,
  ILITSourceOption,
} from './ILITMakeAnnualExclusionGiftsTask.types';

type GiftSourceOptionMap =
  ILITMakeAnnualExclusionGiftsForm['giftSourceOptionsBySourceEntityId'];

export function mapDataToForm(
  data: GetIlitAnnualExclusionGiftsTaskDetailsQuery,
  entityId: string
): ILITMakeAnnualExclusionGiftsForm {
  const giftSourceOptionsBySourceEntityId: GiftSourceOptionMap = getNodes(
    data.households
  )
    .flatMap((advisorClient) => getNodes(advisorClient.entities))
    .reduce<GiftSourceOptionMap>((acc, entity) => {
      const entityInfo: ILITSourceOption = {
        label: entity.subtype.displayName,
        designerAccountId: entity.subtype.designerAccount?.id || '',
        entityType: entity.kind,
        subtypeId: entity.subtype.id,
      };
      acc[entity.id] = entityInfo;
      return acc;
    }, {});

  const entityList = getNodes(data.entities);
  const entity = entityList.find(({ id }) => id === entityId);
  if (!entity) {
    throw new Error('Could not load entity');
  }

  const { subtype } = entity;
  if (subtype.__typename !== 'ILITTrust') {
    throw new Error('Wrong entity type loaded for task');
  }
  const { designerAccount } = subtype;
  if (!designerAccount) {
    throw new Error('Got entity without designer account');
  }

  const grantors: ILITMakeAnnualExclusionGiftsForm['grantors'] =
    subtype.grantors?.reduce<ILITMakeAnnualExclusionGiftsForm['grantors']>(
      (acc, grantor) => {
        acc[grantor.id] = {
          id: grantor.id,
          sourceId: '',
          sourceValueAfterGift: null,
          giftDate: null,
          displayName: grantor.individual?.displayName || '',
          clientProfileId: grantor.individual?.id || '',
          beneficiariesById:
            subtype.beneficiaries
              ?.filter((beneficiaries) => !!beneficiaries.individual) // don't show non-individual beneficiaries
              .reduce<ILITAnnualExclusionGrantor['beneficiariesById']>(
                (acc, beneficiary) => {
                  const relationToGrantor: ClientProfileRelationshipType | null =
                    beneficiary.individual?.relationships?.find(
                      (relation) =>
                        relation.relationID === grantor.individual?.id
                    )?.type ?? null;
                  acc[beneficiary.id] = {
                    id: beneficiary.id,
                    giftedAmount: null,
                    annualExclusionUsed: null,
                    lifetimeExemptionUsed: new Decimal(0),
                    gstExemptionUsed: new Decimal(0),
                    displayName: beneficiary.summary?.displayName || '',
                    relationToGrantor,
                    clientProfileId: beneficiary.individual?.id || '',
                  };
                  return acc;
                },
                {}
              ) || {},
        };
        return acc;
      },
      {}
    ) || {};

  const output: ILITMakeAnnualExclusionGiftsForm = {
    grantors,
    giftSourceOptionsBySourceEntityId,
    documentIds: [],
    trustSubtypeId: subtype.id,
    trustDesignerAccountId: designerAccount.id,
  };

  return output;
}

interface GrantorOutput {
  loggedTransfer: AugmentedCreateLoggedTransferInput;
  entity: AugmentedUpdateEntityInput;
}

function getGrantorTransferAndEntity(
  grantor: ILITAnnualExclusionGrantor | undefined,
  formData: ILITMakeAnnualExclusionGiftsForm,
  entityId: string,
  taskId: string
): GrantorOutput {
  if (!grantor) {
    return {
      loggedTransfer: {
        create: {
          amount: new Decimal(0),
          transactionDate: new Date(),
        },
      },
      entity: {
        id: '',
        update: {},
      },
    };
  }
  const { giftDate } = grantor;
  if (!giftDate) {
    throw new Error('Exclusion gift date is required');
  }
  const amount = sumDecimalJS(
    compact(
      Object.values(grantor.beneficiariesById).map(
        (beneficiary) => beneficiary.giftedAmount
      )
    )
  );
  const create: CreateLoggedTransferInput = {
    amount,
    sourceEntityID: grantor.sourceId,
    receivingEntityID: entityId,
    transactionDate: giftDate,
    associatedTaskID: taskId,
  };

  const withLifetimeExclusionEvents: AugmentedCreateLifetimeExclusionEventInput[] =
    Object.values(grantor.beneficiariesById)
      .filter((beneficiary) => beneficiary.giftedAmount?.greaterThan(0))
      .map((beneficiary) => ({
        create: {
          eventDate: giftDate,
          grantorID: grantor.clientProfileId,
          gstExclusionAmount: beneficiary.gstExemptionUsed,
          lifetimeExclusionAmount: beneficiary.lifetimeExemptionUsed,
          kind: LifetimeExclusionEventKind.Gift,
          giftAmount: beneficiary.giftedAmount,
          annualExclusionAmount:
            beneficiary.annualExclusionUsed || new Decimal(0),
          recipientID: entityId,
          benefitOfID: beneficiary.clientProfileId,
          associatedTaskID: taskId,
        },
        withRecipient: {
          create: {
            entityID: entityId,
          },
        },
      }));

  const grantorOutput: GrantorOutput = {
    loggedTransfer: { create, withLifetimeExclusionEvents },
    entity: {
      id: '',
      update: {},
    },
  };

  if (grantor.sourceValueAfterGift) {
    const updatedEntity =
      formData.giftSourceOptionsBySourceEntityId[grantor.sourceId];
    if (!updatedEntity) {
      throw new Error('Invalid gift source');
    }

    const updateDesignerAccount: AugmentedUpdateAccountInput = {
      id: updatedEntity.designerAccountId,
      update: {},
      withValuations: [
        {
          create: {
            effectiveDate: giftDate,
            documentIDs: formData.documentIds,
            fixedValuationAmount: grantor.sourceValueAfterGift,
          },
        },
      ],
    };

    const entityOutput: AugmentedUpdateEntityInput = {
      id: grantor.sourceId,
      update: {},
    };

    const updatedTrust: {
      id: string;
      update: Record<string, never>;
      updateDesignerAccount: AugmentedUpdateAccountInput;
    } = {
      id: updatedEntity.subtypeId,
      update: {},
      updateDesignerAccount,
    };

    switch (updatedEntity.entityType) {
      // the list for invalid gift source should match the kindNotIn list in
      // GetILITAnnualExclusionGiftsTaskDetails.graphql
      case EntityKind.CustodialPersonalAccount:
      case EntityKind.QualifiedTuitionPersonalAccount:
      case EntityKind.GratTrust:
      case EntityKind.IlitTrust:
      case EntityKind.QprtTrust:
      case EntityKind.CrtTrust:
      case EntityKind.CltTrust:
      case EntityKind.PrivateFoundation:
      case EntityKind.DonorAdvisedFund:
        throw new Error('Invalid gift source');
      case EntityKind.CcorpBusinessEntity:
        entityOutput.updateCcorpBusinessEntity = updatedTrust;
        break;
      case EntityKind.GpBusinessEntity:
        entityOutput.updateGpBusinessEntity = updatedTrust;
        break;
      case EntityKind.IndividualPersonalAccount:
        entityOutput.updateIndividualPersonalAccount = updatedTrust;
        break;
      case EntityKind.IrrevocableTrust:
        entityOutput.updateIrrevocableTrust = updatedTrust;
        break;
      case EntityKind.JointPersonalAccount:
        entityOutput.updateJointPersonalAccount = updatedTrust;
        break;
      case EntityKind.LlcBusinessEntity:
        entityOutput.updateLlcBusinessEntity = updatedTrust;
        break;
      case EntityKind.LpBusinessEntity:
        entityOutput.updateLpBusinessEntity = updatedTrust;
        break;
      case EntityKind.RetirementPersonalAccount:
        entityOutput.updateRetirementPersonalAccount = updatedTrust;
        break;
      case EntityKind.RevocableTrust:
        entityOutput.updateRevocableTrust = updatedTrust;
        break;
      case EntityKind.ScorpBusinessEntity:
        entityOutput.updateScorpBusinessEntity = updatedTrust;
        break;
      case EntityKind.SlatTrust:
        entityOutput.updateSlatTrust = updatedTrust;
        break;
      case EntityKind.SoleProprietorshipBusinessEntity:
        entityOutput.updateSoleProprietorshipBusinessEntity = updatedTrust;
        break;
      case EntityKind.InsurancePersonalAccount:
        entityOutput.updateInsurancePersonalAccount = updatedTrust;
        break;
      default:
        throw new UnreachableError({
          message:
            'Selected source must be added to updated valuation process.',
          case: updatedEntity.entityType,
        });
    }

    grantorOutput.entity = entityOutput;
  }
  return grantorOutput;
}

export function generateTaskCompletionPayload({
  formData,
  entityId,
  taskId,
}: {
  formData: ILITMakeAnnualExclusionGiftsForm;
  entityId: string;
  taskId: string;
  currentUserId: string;
}):
  | {
      variables: IlitMakeAnnualExclusionGiftMutationVariables;
      errorMessage: null;
    }
  | {
      variables: null;
      errorMessage: string;
    } {
  const grantorIDsWithGifts = Object.keys(formData.grantors).filter((key) => {
    const grantor = formData.grantors[key];
    if (!grantor) {
      return false;
    }
    const grantorHasBeneficiaryWithGift = Object.keys(
      grantor.beneficiariesById || {}
    )?.find((beneficiaryId) =>
      grantor.beneficiariesById[beneficiaryId]?.giftedAmount?.greaterThan(0)
    );
    return (
      grantor.giftDate !== null &&
      grantor.sourceId !== '' &&
      grantorHasBeneficiaryWithGift
    );
  });

  if (grantorIDsWithGifts.length === 0) {
    return {
      variables: null,
      errorMessage:
        'At least one grantor must give at least one beneficiary a gift.  If no grantors are making gifts, mark the task as skipped.',
    };
  }

  // all of the first/second grantor/entity stuff is because there isn't an endpoint
  // for creating multiple logged transfers in a single call; instead, pass each one
  // individually and conditionally make changes with @include(if: *) clauses and the
  // $has* flags
  let firstGrantor: ILITAnnualExclusionGrantor | undefined;
  let secondGrantor: ILITAnnualExclusionGrantor | undefined;

  if (grantorIDsWithGifts[0]) {
    firstGrantor = formData.grantors[grantorIDsWithGifts[0]];
  }

  if (grantorIDsWithGifts[1]) {
    secondGrantor = formData.grantors[grantorIDsWithGifts[1]];
  }

  if (!firstGrantor) {
    return {
      errorMessage: 'At least one grantor must give a gift',
      variables: null,
    };
  }

  const {
    loggedTransfer: firstGrantorLoggedTransfer,
    entity: firstGrantorEntity,
  } = getGrantorTransferAndEntity(firstGrantor, formData, entityId, taskId);

  const {
    loggedTransfer: secondGrantorLoggedTransfer,
    entity: secondGrantorEntity,
  } = getGrantorTransferAndEntity(secondGrantor, formData, entityId, taskId);

  const output: IlitMakeAnnualExclusionGiftMutationVariables = {
    firstGrantorLoggedTransfer,
    secondGrantorLoggedTransfer,
    firstGrantorEntity,
    secondGrantorEntity,
    hasSecondGrantor: !!secondGrantor,
    hasFirstGrantorUpdatedValuation: !!firstGrantorEntity.id,
    hasSecondGrantorValuation: !!secondGrantorEntity.id,
    taskId,
  };

  return { variables: output, errorMessage: null };
}
