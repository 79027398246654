import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { CharitableTrustTermKind } from '@/types/schema';

import {
  CharitableTrustDesignerAnalysisForm,
  NAMESPACE,
} from '../CharitableTrustDesignerAnalysis.types';

interface Term {
  termKind: CharitableTrustTermKind;
  termYears: number;
  rate7520: Decimal | null;
}

const MINIMUM_TERM_YEARS = 1 as const;
const MAXIMUM_TERM_YEARS_FIXED = 20 as const;
const MAXIMUM_TERM_YEARS_LIFETIME = 50 as const;

export function useValidTerm(): Term | undefined {
  const { control } = useFormContext<CharitableTrustDesignerAnalysisForm>();
  const [termKind, termYears, rate7520] = useWatch({
    control,
    name: [
      `${NAMESPACE}.term.kind`,
      `${NAMESPACE}.term.length`,
      `${NAMESPACE}.term.rate7520`,
    ],
  });

  const term = useMemo<Term | undefined>(() => {
    if (termYears < MINIMUM_TERM_YEARS || !rate7520) {
      return;
    }

    if (termKind === CharitableTrustTermKind.Fixed) {
      if (termYears > MAXIMUM_TERM_YEARS_FIXED) {
        return;
      }
    } else if (termKind === CharitableTrustTermKind.Lifetime) {
      if (termYears > MAXIMUM_TERM_YEARS_LIFETIME) {
        return;
      }
    }

    return { termKind, termYears, rate7520 };
  }, [rate7520, termKind, termYears]);

  return term;
}
