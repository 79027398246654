import { findIndex, orderBy, pull } from 'lodash';

import { UnreachableError } from '@/utils/errors';

import {
  ClientPresentationSlide,
  StringArrayOrNull,
} from '../clientPresentation.types';

export function getOrderedSlides(slides: ClientPresentationSlide[]) {
  return orderBy(slides, 'index');
}

export function getNextList(
  visibleIds: StringArrayOrNull,
  identifier: string,
  action: 'add' | 'remove'
): StringArrayOrNull {
  if (visibleIds === null) {
    switch (action) {
      case 'add':
        return [identifier];
      case 'remove':
        // this is a no-op; we're trying to remove from a null set
        return null;
      default:
        throw new UnreachableError({
          case: action,
          message: `unhandled action type ${action}`,
        });
    }
  }

  // avoid mutating
  const newPulidList = [...visibleIds];
  switch (action) {
    case 'add':
      newPulidList.push(identifier);
      break;
    case 'remove':
      pull(newPulidList, identifier);
      break;
    default:
      throw new UnreachableError({
        case: action,
        message: `unhandled action type ${action}`,
      });
  }

  return newPulidList;
}

interface ItemWithIdentifier {
  identifier?: string;
}

export function sortItemsToPulidOrder<T extends ItemWithIdentifier>(
  items: T[],
  pulids: string[]
): T[] {
  return items.sort((a, b) => {
    return (
      findIndex(pulids, (p) => p === a.identifier) -
      findIndex(pulids, (p) => p === b.identifier)
    );
  });
}
