import { useCallback } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { diagnostics } from '@/utils/diagnostics';

import { useFamilyTreeLazyQuery } from '../graphql/FamilyTree.generated';
import { FamilyTreeEffectFn, QueryFamilyTreeEffect } from '../types';

export function useQueryFamilyTreeEffect() {
  const { showFeedback } = useFeedback();
  const [queryFamilyTree] = useFamilyTreeLazyQuery();

  return useCallback<FamilyTreeEffectFn<QueryFamilyTreeEffect>>(
    (state, _action, dispatch) => {
      async function invokeQuery() {
        try {
          const response = await queryFamilyTree({
            fetchPolicy: 'network-only',
            variables: {
              where: { hasHouseholdWith: [{ id: state.householdId }] },
            },
          });

          dispatch({
            type: 'QUERY_FAMILY_TREE_SUCCESS',
            familyTree: response.data!,
          });
        } catch (error) {
          showFeedback(
            `Could not get family tree. Please refresh the page and try again.`
          );
          diagnostics.error(`family tree query error`, error as Error);
        }
      }

      void invokeQuery();
    },
    [queryFamilyTree, showFeedback]
  );
}
