import { BoxProps } from '@mui/material';

import { UnstyledButton } from '@/components/form/baseInputs/Button/UnstyledButton';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useIsElementHovered } from '@/hooks/useIsElementHovered';
import { PulidKind } from '@/utils/pulid';

import { useHouseholdDocumentsViewerContext } from '../context/HouseholdDocumentsViewer.context';
import {
  EntityDocumentViewer_DocumentFragment,
  useSetDefaultDocumentMutation,
} from '../graphql/EntityDocumentViewer.generated';

function DefaultDocumentBadge() {
  return <Badge variant={BadgeVariants.Primary} display="Default" />;
}

function SetDefaultDocumentButton(props: BoxProps<'button'>) {
  return (
    <UnstyledButton component="button" {...props}>
      <Badge variant={BadgeVariants.GrayOutline} display="Set as default" />
    </UnstyledButton>
  );
}

interface DocumentSelectorMenuItemProps {
  document: EntityDocumentViewer_DocumentFragment;
}

export function DocumentSelectorMenuItem({
  document,
}: DocumentSelectorMenuItemProps) {
  const [isHovered, elementProps] = useIsElementHovered();
  const [setDefaultDocument] = useSetDefaultDocumentMutation();
  const { showFeedback } = useFeedback();
  const {
    activeDocumentId,
    updateActiveDocumentId,
    defaultDocumentId,
    targetId,
    targetKind,
    setDefaultDocumentId,
  } = useHouseholdDocumentsViewerContext();
  const isEntity = targetKind === PulidKind.Entity;

  function handleSetDefaultDocument(documentId: string) {
    if (!isEntity) {
      throw new Error(
        'Invalid state: handleSetDefaultDocument can only be called for entities'
      );
    }

    const lastDefaultDocumentId = defaultDocumentId;
    // optimistically set it right away, so the user gets instant feedback
    // on what should be a trivial update
    setDefaultDocumentId(documentId);

    void setDefaultDocument({
      variables: { entityId: targetId, documentId },
      onError: () => {
        // if the mutation fails, revert the change
        setDefaultDocumentId(lastDefaultDocumentId);
        showFeedback(
          'Failed to set the default document. Please refresh the page and try again.'
        );
      },
    });
  }

  const isDefaultDocument = document.id === defaultDocumentId;
  return (
    <MenuItem
      justifyContent="space-between"
      sx={{ minWidth: 200 }}
      label={document.name}
      iconAfter={
        isDefaultDocument ? (
          <DefaultDocumentBadge />
        ) : (
          <SetDefaultDocumentButton
            sx={{ visibility: isHovered && isEntity ? 'visible' : 'hidden' }}
            onClick={() => {
              handleSetDefaultDocument(document.id);
            }}
          />
        )
      }
      key={document.id}
      onClick={() =>
        updateActiveDocumentId({
          documentId: document.id,
          shouldClearSearchResults: true,
        })
      }
      muiMenuItemProps={{
        selected: document.id === activeDocumentId,
        ...elementProps,
      }}
    />
  );
}
