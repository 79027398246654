import { compact } from 'lodash';
import { ReactNode, useMemo } from 'react';

import { SpinningIcon } from '@/components/animation/SpinningIcon/SpinningIcon';
import { Button } from '@/components/form/baseInputs/Button';
import { TrendUp01Icon } from '@/components/icons/TrendUp01Icon';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';

import { useWaterfallSummaryContext } from '../contexts/waterfallSummary.context';
import { EstateWaterfallFragment } from '../graphql/EstateWaterfall.generated';
import {
  GetWaterfallSummary_EstateWaterfallFragment,
  GetWaterfallSummary_GrowthProfileFragment,
} from '../graphql/GetWaterfallSummary.generated';

function getButtonLabel(waterfall: {
  growthProfile?: GetWaterfallSummary_GrowthProfileFragment | undefined | null;
  firstGrantorDeathYear?: number | null | undefined;
  secondGrantorDeathYear?: number | null | undefined;
}): string {
  const growthProfile = waterfall?.growthProfile;
  if (!growthProfile) return 'Unknown';

  const deathYears = compact([
    waterfall.firstGrantorDeathYear,
    waterfall.secondGrantorDeathYear,
  ]);

  return `${growthProfile.displayName ?? 'Unknown'}, ${deathYears.join(' & ')} death`;
}

export interface GrowthProfilesButtonProps {
  /**
   * Callback that handles opening the sidebar to the growth profiles view
   */
  onClick: () => void;
  /**
   * Waterfall to check against
   */
  waterfall:
    | GetWaterfallSummary_EstateWaterfallFragment
    | EstateWaterfallFragment;
}

export function GrowthProfilesButton({
  onClick,
  waterfall,
}: GrowthProfilesButtonProps) {
  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');

  const { loading } = useWaterfallSummaryContext();

  const buttonLabel: string | ReactNode = useMemo(() => {
    if (loading) return <SpinningIcon />;
    return getButtonLabel(waterfall);
  }, [loading, waterfall]);

  if (!isGrowthProfilesEnabled) return null;

  return (
    <Button
      variant="secondary"
      size="sm"
      startIcon={TrendUp01Icon}
      iconProps={{ size: 20 }}
      onClick={onClick}
    >
      {buttonLabel}
    </Button>
  );
}

export const GROWTH_PROFILES_SENTINEL = '__GROWTH_PROFILES__' as string;
