import { Skeleton, Stack } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { EditableHeader } from '@/components/form/EditableHeader/EditableHeader';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { useUpdateClientPresentationMutation } from '../graphql/ClientPresentationDesigner.generated';
import { ClientPresentationDesignerActions } from './ClientPresentationDesignerActions';

export interface ClientPresentationDesignerHeaderProps {
  householdId: string;
  presentationId: string;
  loading?: boolean;
  name: string;
}

export const DESIGNER_HEADER_HEIGHT_PX = 78;

export function ClientPresentationDesignerHeader({
  householdId,
  presentationId,
  loading,
  name,
}: ClientPresentationDesignerHeaderProps) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const [updateName] = useUpdateClientPresentationMutation({
    onError: (err) => {
      reportError('failed to update client presentation header', err);
      showFeedback(
        "We weren't able to update the presentation name. Please refresh the page and try again."
      );
    },
    onCompleted: () => {
      showFeedback('Successfully updated presentation name', {
        variant: 'success',
      });
    },
  });

  function handleNameChange(newName: string) {
    void updateName({
      variables: {
        input: {
          id: presentationId,
          update: {
            name: newName,
          },
        },
      },
    });
  }

  const listLink = getCompletePathFromRouteKey(
    ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATIONS,
    {
      householdId: householdId,
    }
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      p={2}
      px={4}
      height={DESIGNER_HEADER_HEIGHT_PX}
    >
      {loading ? (
        <Skeleton width={200} height={20} />
      ) : (
        <EditableHeader
          value={name}
          onChange={handleNameChange}
          testId="ClientPresentationHeader-titleInput"
        />
      )}

      <Stack direction="row" spacing={2}>
        <ClientPresentationDesignerActions
          householdId={householdId}
          presentationId={presentationId}
        />
        <Button href={listLink} size="md" variant="secondary">
          Back to list
        </Button>
      </Stack>
    </Stack>
  );
}
