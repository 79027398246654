import { Box, Stack, Typography } from '@mui/material';
import { createTheme, Theme, useTheme } from '@mui/material/styles';
import React, {
  FC,
  KeyboardEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { DropdownButton } from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { PaletteIcon } from '@/components/icons/PaletteIcon';
import { useEventListener } from '@/hooks/useEventListener';
import { BrandingModal } from '@/modules/admin/branding/BrandingModal';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { useTenantInformationQuery } from '@/modules/tenant/graphql/TenantInformation.generated';
import * as diagnostics from '@/utils/diagnostics';
import { isProduction } from '@/utils/featureFlags';

import { COMMON_THEME_PROPERTIES } from './common';
import { CustomerThemeContext } from './CustomerThemeProvider';
import { getDemoTheme, getRandomPalette } from './paletteGenerator';
import { ColorKey, dataToBrand, makeThemeFromBrand } from './themes.utils';

interface ThemePickerOption {
  name: string;
  theme: Theme;
}

const Preview: FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  const theme = useTheme();

  const displayMapping: Record<string, string> = {
    Primary: theme.palette.primary.main,
    Secondary: theme.palette.secondary.main,
    Buttons: theme.palette.buttons.main,
    'Viz primary': theme.palette.dataVisualizationPrimary.main,
    'Viz secondary': theme.palette.dataVisualizationSecondary.main,
    'Viz tertiary': theme.palette.dataVisualizationTertiary.main,
    'Viz negative': theme.palette.dataVisualizationNegative.main,
    Education: theme.palette.education.main,
    'Body text': theme.palette.background.default,
  };

  const displayColors = Object.keys(displayMapping).map((key: string, idx) => {
    const color = displayMapping[key];
    return (
      <div
        key={`${color}-${idx}`}
        style={{
          backgroundColor: color,
          width: 80,
          height: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="label"
          color={color && theme.palette.getContrastText(color)}
        >
          {key}
        </Typography>
      </div>
    );
  });

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'fixed',
        top: '15px',
        left: '50%',
        transform: 'translate(-50%)',
        zIndex: 10,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Stack flexDirection="row">{displayColors}</Stack>
    </Box>
  );
};

export const CustomerThemePicker: FC = () => {
  const currentUser = useCurrentUser();
  const { customerTheme, setTheme } = useContext(CustomerThemeContext);
  const [showTheme, setShowTheme] = useState(false);
  const [showBrandingModal, setShowBrandingModal] = useState(false);
  const [themePickerThemes, setThemePickerThemes] = useState<
    ThemePickerOption[]
  >([{ name: 'Default theme', theme: customerTheme }]);
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const { data, error } = useTenantInformationQuery();

  if (error) {
    diagnostics.error('Error loading tenant information', error);
  }

  useEffect(() => {
    if (data?.tenantInformation.otherTenantBranding) {
      const brandings: ThemePickerOption[] = [];

      data.tenantInformation.otherTenantBranding.forEach((otherBrands) => {
        if (otherBrands) {
          const { brand, logos } = dataToBrand(otherBrands.branding);

          const themeOptions = makeThemeFromBrand(brand, logos);
          brandings.push({
            name: otherBrands.name,
            theme: createTheme(COMMON_THEME_PROPERTIES, themeOptions),
          });
        }
      });

      brandings.push({ name: 'Default theme', theme: customerTheme });

      setThemePickerThemes(brandings);
    }
  }, [customerTheme, data?.tenantInformation.otherTenantBranding]);

  const keyCallback = useCallback<KeyboardEventHandler>(
    (e) => {
      if (e.shiftKey === true && e.key === 'P') {
        setShowBrandingModal(true);
      }

      if (!isProduction()) {
        if (e.shiftKey === true) {
          if (e.key === 'S') {
            if (showTheme) {
              setTheme(customerTheme);
            }
            setShowTheme(!showTheme);
            setShowPreview(!showTheme);
          }

          if (e.key === 'R') {
            const colors = getRandomPalette();

            const themeOptions = makeThemeFromBrand({
              PRIMARY: colors[0], // dark vibes
              SECONDARY: colors[1], // dark vibes
              BUTTONS_LINKS: colors[2], // dark vibes
              DATA_VISUALIZATION_PRIMARY: colors[3], // dark vibes
              DATA_VISUALIZATION_SECONDARY: colors[4], // dark vibes
              DATA_VISUALIZATION_TERTIARY: colors[5], // dark vibes
              DATA_VISUALIZATION_NEGATIVE: '#FA9F9B', // orange
            });

            const newRandomTheme = createTheme(
              COMMON_THEME_PROPERTIES,
              themeOptions
            );

            setShowPreview(true);
            setTheme(newRandomTheme);
          }

          if (e.key === 'D') {
            const [demoTheme] = getDemoTheme();
            const themeOptions = makeThemeFromBrand(
              demoTheme as Record<ColorKey, string>
            );

            const newDemoTheme = createTheme(
              COMMON_THEME_PROPERTIES,
              themeOptions
            );
            setShowPreview(true);
            setTheme(newDemoTheme);
          }
        }
      }
    },
    [customerTheme, setTheme, showTheme]
  );

  useEventListener('keydown', keyCallback as unknown as EventListener);

  // if the user is a Luminary employee, show the branding modal
  if (currentUser?.isInternalUser && !isProduction()) {
    return (
      <>
        <BrandingModal
          isOpen={showBrandingModal}
          onClose={() => setShowBrandingModal(false)}
        />
        {showPreview && (
          <Preview
            onClick={() => {
              setShowPreview(false);
            }}
          />
        )}
        {themePickerThemes.length > 0 && (
          <Box
            sx={{
              display: 'flex',
              position: 'fixed',
              bottom: '15px',
              left: '200px',
              zIndex: 10,
            }}
          >
            <DropdownButton
              startIcon={PaletteIcon}
              showArrow={true}
              variant="transparent"
              size="sm"
              buttonContent="Select a theme"
              items={themePickerThemes.map(({ name, theme }) => ({
                name,
                clickHandler: () => {
                  setTheme(theme);
                  setShowPreview(true);
                },
              }))}
              name="themePicker"
            />
          </Box>
        )}
      </>
    );
  }

  return null;
};
