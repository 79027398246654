import { QueryHookOptions } from '@apollo/client';
import { compact } from 'lodash';
import { useMemo } from 'react';

import { getNodes } from '@/utils/graphqlUtils';

import {
  GrowthProfiles_GrowthProfileFragment,
  GrowthProfilesQuery,
  GrowthProfilesQueryHookResult,
  GrowthProfilesQueryVariables,
  Operations,
  useGrowthProfilesQuery,
} from '../graphql/GrowthProfiles.generated';

export type HookOptions = Omit<
  QueryHookOptions<GrowthProfilesQuery, GrowthProfilesQueryVariables>,
  'variables'
>;

export interface UseAvailableGrowthProfilesResult
  extends GrowthProfilesQueryHookResult {
  growthProfiles: GrowthProfiles_GrowthProfileFragment[];
}

export function useAvailableGrowthProfiles(
  householdId: string | null,
  options: HookOptions = {}
): UseAvailableGrowthProfilesResult {
  const { data, ...rest } = useGrowthProfilesQuery({
    variables: {
      where: {
        hasHouseholdWith: [{ id: householdId }],
      },
    },
    ...options,
  });

  const growthProfiles = useMemo(
    () => compact(getNodes(data?.growthProfiles)),
    [data?.growthProfiles]
  );

  return {
    growthProfiles,
    data,
    ...rest,
  };
}

export const GrowthProfilesOperations = Operations;
