import { useWatch } from 'react-hook-form';

import { ButtonTab, Tabs } from '@/components/navigation/NavigationTabs';
import { useFormContext } from '@/components/react-hook-form';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';

import { EstateWaterfallComparisonTrowserFormShape } from '../EstateWaterfallComparisonTrowser.type';

export interface EstateWaterfallComparisonTrowserTabsProps {
  loading: boolean;
}

export function EstateWaterfallComparisonTrowserTabs({
  loading,
}: EstateWaterfallComparisonTrowserTabsProps) {
  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');
  const { control, setValue } =
    useFormContext<EstateWaterfallComparisonTrowserFormShape>();
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const [afterDeath, legacyAssumptions] = useWatch({
    control,
    name: ['afterDeath', 'legacyAssumptions'],
  });

  const { firstGrantorDeathYear, secondGrantorDeathYear } = legacyAssumptions;

  return (
    <Tabs>
      <ButtonTab
        display={`Before 1st Death${isGrowthProfilesEnabled ? '' : ` (${firstGrantorDeathYear})`}`}
        onClick={() => setValue('afterDeath', AfterDeath.None)}
        disabled={loading}
        isActive={afterDeath === AfterDeath.None}
        data-testid="before-1st-death-tab"
      />
      <ButtonTab
        display={`After 1st Death${isGrowthProfilesEnabled ? '' : ` (${firstGrantorDeathYear})`}`}
        onClick={() => setValue('afterDeath', AfterDeath.First)}
        disabled={loading}
        isActive={afterDeath === AfterDeath.First}
        data-testid="after-1st-death-tab"
      />
      {isTwoClientHousehold && (
        <ButtonTab
          display={`After 2nd Death${isGrowthProfilesEnabled ? '' : ` (${secondGrantorDeathYear})`}`}
          onClick={() => setValue('afterDeath', AfterDeath.Second)}
          disabled={loading}
          isActive={afterDeath === AfterDeath.Second}
          data-testid="after-2nd-death-tab"
        />
      )}
    </Tabs>
  );
}
