import { Stack, Typography } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { PersonalAccountSummaryData } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountDetails.types';
import { TrustBeneficiariesList } from '@/modules/trusts/TrustBeneficiariesList/TrustBeneficiariesList';

interface PersonalAccountBeneficiariesV2Props {
  summary: PersonalAccountSummaryData;
}

export function PersonalAccountBeneficiariesV2({
  summary,
}: PersonalAccountBeneficiariesV2Props) {
  return (
    <Card
      variant="outlined"
      sx={{
        width: '100%',
        p: 2,
      }}
    >
      <Stack spacing={2} width="100%">
        <Typography variant="h6" component="span">
          Beneficiaries
        </Typography>
        <TrustBeneficiariesList
          variant="flat"
          beneficiariesGroups={[
            {
              heading: undefined,
              items: summary.beneficiaries ?? [],
              noItemsText: 'No beneficiaries specified',
            },
          ]}
        />
      </Stack>
    </Card>
  );
}
