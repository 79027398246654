import { Box, InputAdornment, Stack } from '@mui/material';
import { isUndefined } from 'lodash';
import { useEffect } from 'react';
import { PathValue, useWatch } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareCardRepeater } from '@/components/form/formAwareInputs/FormAwareCardRepeater/FormAwareCardRepeater';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { PersonFormFragment } from '@/components/form/PersonFormFragment';
import { Briefcase02Icon } from '@/components/icons/Briefcase02Icon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form/hooks';
import { ClientMultiDocumentUploader } from '@/modules/aiOnboarding/components/ClientMultiDocumentUploader';
import { IntegrationClientsTypeaheadCard } from '@/modules/assetProviderIntegrations/shared/IntegrationClientsTypeaheadCard';
import { MAXIMUM_CLIENT_COUNT } from '@/modules/clientProfiles/utils';
import { RelationshipOwner } from '@/modules/content/tooltipContent/RelationshipOwner';
import { PossibleRelationshipOwnersTypeahead } from '@/modules/household/components/PossibleRelationshipOwnersTypeahead/PossibleRelationshipOwnersTypeahead';
import {
  ArrayFieldNameFromFormShape,
  FieldNameFromFormShape,
} from '@/types/react-hook-form';
import { ClientProfileRelationshipType } from '@/types/schema';

import {
  CreateHouseholdFormShape,
  emptyInitialCreateGrantorClientProfileValue,
} from './types';

export interface CreateHouseholdModalFormProps {
  subBrandOptions: SelectInputOption[];
  showDocumentUploader?: boolean;
  preCreatedHouseholdId: string | null;
  showRelationshipToOtherPrimaryClient?: boolean;
}

export function CreateHouseholdModalForm({
  subBrandOptions,
  showDocumentUploader,
  preCreatedHouseholdId,
  showRelationshipToOtherPrimaryClient,
}: CreateHouseholdModalFormProps) {
  const { control, setValue, formState } =
    useFormContext<CreateHouseholdFormShape>();

  const [profiles] = useWatch({
    control,
    name: ['profiles'],
  });

  const isRelationshipFieldDirty =
    formState.dirtyFields.relationshipToBetweenPrimaryClients;
  const profilesLength = profiles.length;
  const previousProfilesLength = usePrevious(profilesLength);

  useEffect(() => {
    if (isUndefined(previousProfilesLength)) return;
    if (isRelationshipFieldDirty) return;

    if (previousProfilesLength === 1 && profilesLength === 2) {
      setValue(
        'relationshipToBetweenPrimaryClients',
        ClientProfileRelationshipType.Spouse,
        {
          shouldDirty: true,
        }
      );
    }
  }, [
    profilesLength,
    previousProfilesLength,
    setValue,
    isRelationshipFieldDirty,
  ]);

  return (
    <Stack spacing={3}>
      <Card variant="filled-callout" sx={{ p: 3 }}>
        {subBrandOptions.length > 0 && (
          <FormLayoutRow>
            <FormLayoutItem>
              <FormAwareSelectInput
                label="Sub-brand"
                showEmptyValue={false}
                fieldName={
                  `subBrandId` as FieldNameFromFormShape<CreateHouseholdFormShape>
                }
                options={subBrandOptions}
                control={control}
                startAdornment={
                  <InputAdornment position="start">
                    <Briefcase02Icon size={20} />
                  </InputAdornment>
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}
        <FormLayoutRow>
          <FormLayoutItem>
            <PossibleRelationshipOwnersTypeahead
              label="Relationship owner"
              required={true}
              fieldName={
                `primaryRelationshipOwnerId` as FieldNameFromFormShape<CreateHouseholdFormShape>
              }
              control={control}
              contextualHelp={<RelationshipOwner />}
            />
          </FormLayoutItem>
        </FormLayoutRow>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareSwitch
              label="This household is a prospect"
              labelPosition="right"
              fieldName={
                `isProspect` as FieldNameFromFormShape<CreateHouseholdFormShape>
              }
              control={control}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      </Card>
      <IntegrationClientsTypeaheadCard<CreateHouseholdFormShape>
        control={control}
        fieldName={
          `integrationClientIds` as FieldNameFromFormShape<CreateHouseholdFormShape>
        }
      />
      <FormAwareCardRepeater<CreateHouseholdFormShape>
        maxItems={MAXIMUM_CLIENT_COUNT}
        name="profiles"
        showRemove={true}
        addAnotherItemText="Add a second person to client"
        emptyValue={
          emptyInitialCreateGrantorClientProfileValue as PathValue<
            CreateHouseholdFormShape,
            ArrayFieldNameFromFormShape<CreateHouseholdFormShape>
          >
        }
        control={control}
        render={(i) => (
          <Box key={`profiles-${i}`} p={3} pb={4}>
            <PersonFormFragment
              relationshipOptions={[]}
              control={control}
              fieldNamePrefix={`profiles[${i}]`}
              showTrusteeSwitch={false}
              showBeneficiarySwitch={false}
              showRelationshipToOtherPrimaryClient={
                // On the second primary client, show the relationship selector
                // to the first primary client.
                showRelationshipToOtherPrimaryClient && i === 1
              }
            />
          </Box>
        )}
      />
      {showDocumentUploader && preCreatedHouseholdId && (
        <ClientMultiDocumentUploader householdId={preCreatedHouseholdId} />
      )}
    </Stack>
  );
}
