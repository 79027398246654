import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import { Node } from '@/components/diagrams/FlowChart';
import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useForm, useSubmitSuccessHandler } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { diagnostics } from '@/utils/diagnostics';

import {
  GraphViz_ViewFragment,
  useDeleteGraphVizViewMutation,
  useUpdateGraphVizViewMutation,
} from '../graphql/GraphVizView.generated';
import {
  mapFormDataToInput,
  mapInputToFormData,
} from './EditGraphVizView.utils';
import { GraphVizViewForm, GraphVizViewFormProps } from './GraphVizViewForm';
import { GraphVizViewFormData } from './types';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'>;

export interface EditGraphVizViewModalProps<TabValue extends string>
  extends FormModalOptionalProps,
    GraphVizViewFormProps<TabValue> {
  view: GraphViz_ViewFragment;
  householdId: string;
  allowDelete?: boolean;
  nodes: Node[];
  onSuccess?: (view: GraphViz_ViewFragment) => void;
  onDelete?: (viewId: string) => void;
}

function EditGraphVizView({
  view,
  householdId,
  onClose,
  onSuccess: onSuccessExternal,
  onDelete: onDeleteExternal,
  allowDelete,
  nodes,
  tabs,
  getTabForField,
  ...formModalProps
}: EditGraphVizViewModalProps<string>) {
  const { showFeedback } = useFeedback();
  const { formRef, handleSave } = useFormSaveHandler();
  const { formState, handleSubmit, reset, shouldBlockNavigation } =
    useFormContext<GraphVizViewFormData>();

  const [deleteEntityGraphView, { loading: isDeleting }] =
    useDeleteGraphVizViewMutation({
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        diagnostics.error(`Could not delete entity map`, error, {
          id: view.id,
        });
      },
    });

  const [updateMutation] = useUpdateGraphVizViewMutation({
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      diagnostics.error(`Could not update entity map`, error, {
        id: view.id,
      });
    },
  });

  const onSubmit = handleSubmit((formData) => {
    return updateMutation({
      variables: {
        input: { id: view.id, update: mapFormDataToInput(formData) },
      },
    }).then((res) => {
      if (!res.data?.updateEntityGraphView) return;

      reset(
        mapInputToFormData({
          householdId,
          nodes,
          view: res.data.updateEntityGraphView,
        })
      );

      onSuccessExternal?.(res.data.updateEntityGraphView);
    });
  });

  const closeModal = () => {
    onClose();
    reset();
  };

  const onDelete = async () => {
    await deleteEntityGraphView({ variables: { id: view.id } });
    onDeleteExternal?.(view.id);
    closeModal();
  };

  useSubmitSuccessHandler(closeModal);

  return (
    <FormModal
      {...formModalProps}
      onClose={closeModal}
      heading="Edit custom map"
      fullHeight
      shouldBlockClose={shouldBlockNavigation}
      maxWidth="md"
      actions={
        <FormModalActions.Provider
          // TODO (LUM-1552): Causes infinite type recursion with latest TS, need to upgrade to RHF v8
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
          formState={formState as any}
          isDeleting={isDeleting}
        >
          <FormModalActions.DeleteButton
            disabled={!allowDelete}
            onConfirmDelete={onDelete}
          />
          <FormModalActions.CancelButton onClick={closeModal} />
          <FormModalActions.SaveButton onClick={handleSave} />
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <GraphVizViewForm tabs={tabs} getTabForField={getTabForField} />
      </form>
    </FormModal>
  );
}

export const EditGraphVizViewModal = <TabValue extends string>({
  householdId,
  view,
  nodes,
  ...props
}: EditGraphVizViewModalProps<TabValue>) => {
  const defaultValues = useMemo(
    () => mapInputToFormData({ householdId, nodes, view }),
    [view, householdId, nodes]
  );

  const formMethods = useForm<GraphVizViewFormData>({
    // the form type for this (the intersection of all the nested node types in combination with
    // the form typings) is too complex for the ts compiler to handle. doing this to unblock,
    // but another fix could also be to refactor the form to just be aware of the node visibility statuses
    // and IDs, then map those things back onto the initial node configurations in the submit/mutation handler
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any -- TODO (LUM-1552): Causes infinite type recursion with latest TS, need to upgrade to RHF v8
    defaultValues: defaultValues as any,
  });

  return (
    <FormProvider {...formMethods}>
      <EditGraphVizView
        householdId={householdId}
        view={view}
        nodes={nodes}
        {...props}
      />
    </FormProvider>
  );
};
