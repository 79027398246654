import Decimal from 'decimal.js';
import { Control, UseFieldArrayRemove } from 'react-hook-form';

import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { AdditionalItemRendererProps } from '@/components/form/formAwareInputs/FormAwareInput.types';
import { MatchableField } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  AccessParameterFrequency,
  AccessParameterKind,
  BeneficiaryLevel,
  BeneficiaryPowerOfAppointmentPower,
  ScheduledDistributionFrequency,
  ScheduledDistributionKind,
} from '@/types/schema';
import { PathsOf } from '@/types/subform';

import {
  BENEFICIARIES_AFTER_BOTH_GRANTORS_DIE,
  BENEFICIARIES_SUBFORM,
  ENTITY_BENEFICIARIES_FORM_VARIANTS,
  INCOME_BENEFICIARY_SUBFORM,
  LEAD_BENEFICIARY_SUBFORM,
  LIFETIME_BENEFICIARIES_SUBFORM,
  REMAINDER_BENEFICIARIES_SUBFORM,
} from './EntityBeneficiariesSubform.constants';

export type EntityBeneficiariesFormNamespace =
  | typeof BENEFICIARIES_SUBFORM
  | typeof LIFETIME_BENEFICIARIES_SUBFORM
  | typeof REMAINDER_BENEFICIARIES_SUBFORM
  | typeof LEAD_BENEFICIARY_SUBFORM
  | typeof INCOME_BENEFICIARY_SUBFORM
  | typeof BENEFICIARIES_AFTER_BOTH_GRANTORS_DIE;

export type EntityBeneficiariesFormSecondNamespace =
  | 'beneficiaries'
  | 'lifetimeBeneficiaries'
  | 'remainderBeneficiaries'
  | 'leadBeneficiary'
  | 'incomeBeneficiary';

export type EntityBeneficiariesFormVariant =
  (typeof ENTITY_BENEFICIARIES_FORM_VARIANTS)[number];

export enum AccessParameterRadioGroupKind {
  Full = 'full',
  Partial = 'partial',
  Other = 'Other',
}

export type AgeRequirementKind = 'upon' | 'until' | 'between';

export interface BeneficiaryFormAccessParameterAgeParam {
  _ageRequirementKind: AgeRequirementKind;
  ageRequirementEnd?: string;
  ageRequirementStart?: string;
  notes?: string;
}

// TS_PERF this blocks TS from using the Decimal type here
// because when we use Decimal, tsc takes exponentially longer to compile
type OpaqueDecimal = undefined;

export interface BeneficiaryFormAccessParameter {
  kind: AccessParameterKind;
  accessParameterNotes?: string;
  amount?: OpaqueDecimal;
  _hasFrequency: boolean;
  frequency?: AccessParameterFrequency | '';
  percentage?: OpaqueDecimal;
  _hasAgeParams: boolean;
  ageParams?: BeneficiaryFormAccessParameterAgeParam[];
}

export interface BeneficiaryFormAccessParameters {
  _accessParameterKind?: AccessParameterRadioGroupKind;
  accessParametersFull?: BeneficiaryFormAccessParameter;
  accessParametersPartial?: BeneficiaryFormAccessParameter[];
  accessParametersOther?: {
    checkboxes: Partial<Record<AccessParameterKind, boolean>>;
    values: Partial<
      Record<AccessParameterKind, BeneficiaryFormAccessParameter>
    >;
  };
}

export interface BeneficiaryFormScheduledDistribution {
  _hasAgeParams: boolean;
  _ageRequirementKind: AgeRequirementKind | '';
  scheduledDistribution: {
    ageRequirementEnd?: string;
    ageRequirementStart?: string;
    amount?: Decimal;
    frequency: ScheduledDistributionFrequency | '';
    kind: ScheduledDistributionKind;
    percentage?: Decimal;
    scheduledDistributionNotes?: string;
  };
}

export interface BeneficiaryFormScheduledDistributions {
  _hasScheduledDistributions: boolean;
  scheduledDistributions: BeneficiaryFormScheduledDistribution[];
}

export interface BeneficiaryFormPowerOfAppointment {
  _hasPowerOfAppointment: boolean;
  power?: BeneficiaryPowerOfAppointmentPower | '';
  powerOtherNote?: string;
}

export interface EntityBeneficiariesFormBeneficiary extends MatchableField {
  id: string | null;
  beneficiaryId: string;
  level: BeneficiaryLevel | '';
  notes: string;
  charitableBeneficiaryType?: 'annuity' | 'unitrust';
  beneficiaryFormScheduledDistributions?: BeneficiaryFormScheduledDistributions;
  beneficiaryFormAccessParameters?: BeneficiaryFormAccessParameters;
  beneficiaryFormPowerOfAppointment?: BeneficiaryFormPowerOfAppointment;
}

// this is the type of the subform's values
export interface EntityBeneficiariesSubformShape {
  beneficiaries?: EntityBeneficiariesFormBeneficiary[];
  lifetimeBeneficiaries?: EntityBeneficiariesFormBeneficiary[];
  remainderBeneficiaries?: EntityBeneficiariesFormBeneficiary[];
  leadBeneficiary?: EntityBeneficiariesFormBeneficiary;
  incomeBeneficiary?: EntityBeneficiariesFormBeneficiary;
}

// this is what the subform's values will be nested under in the parent form
export type EntityBeneficiariesSubformFields = Partial<
  Record<EntityBeneficiariesFormNamespace, EntityBeneficiariesSubformShape>
>;

export type EntityBeneficiariesSubformSubformPath =
  PathsOf<EntityBeneficiariesSubformFields>;

// this is Props for the UI component
export interface EntityBeneficiariesSubformProps {
  variant: EntityBeneficiariesFormVariant;
  householdId: string;
  namespace: EntityBeneficiariesFormNamespace;
  subformValues?: EntityBeneficiariesSubformShape;
  entityType?: EntityType;
}

export interface EntityBeneficiariesFormBodyProps {
  control: Control<EntityBeneficiariesSubformFields>;
  beneficiaryOptions: SelectInputProps['options'];
  namespace: EntityBeneficiariesFormNamespace;
  subformValues?: EntityBeneficiariesFormBeneficiary[];
  secondNamespace: EntityBeneficiariesFormSecondNamespace;
  householdId: string;
  variant: EntityBeneficiariesFormVariant;
  i: number;
  splitScreen: boolean;
  additionalItemRendererProps?: AdditionalItemRendererProps;
  remove: UseFieldArrayRemove;
}
