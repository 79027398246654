import { Box, Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import {
  AugmentedUpdateTenantApplicationConfigurationInput,
  TenantApplicationConfigurationUserSessionInactivityTimeout,
} from '@/types/schema';

import { useUpdateTenantConfigurationFormMutation } from './graphql/TenantConfigurationForm.generated';
import { useTenantConfigurationFormDefaultValues } from './hooks/useTenantConfigurationFormDefaultValues';
import {
  SESSION_INACTIVITY_TIMEOUT_OPTIONS,
  TenantConfigurationFormPath,
  TenantConfigurationFormShape,
} from './TenantConfigurationForm.constants';

export function TenantConfigurationForm() {
  const formMethods = useForm<TenantConfigurationFormShape>({
    defaultValues: {
      disableAllAIFeatures: false,
      sessionInactivityTimeout:
        TenantApplicationConfigurationUserSessionInactivityTimeout.Off,
    },
  });
  const { createSuccessFeedback, showFeedback } = useFeedback();
  const { data: initialData } = useTenantConfigurationFormDefaultValues(
    formMethods.reset
  );
  const [updateTenantConfiguration, { loading }] =
    useUpdateTenantConfigurationFormMutation({
      onCompleted: createSuccessFeedback('Successfully updated configuration'),
      onError: () => {
        showFeedback(
          'There was an error updating the configuration. Please try again.'
        );
      },
    });

  const onSubmit = formMethods.handleSubmit((formValues) => {
    const tacId = initialData?.tenantApplicationConfiguration?.id;
    if (!tacId) {
      throw new Error('No tenant configuration id found');
    }

    const input: AugmentedUpdateTenantApplicationConfigurationInput = {
      id: tacId,
      update: {
        disableAiCapabilities: formValues.disableAllAIFeatures,
        userSessionInactivityTimeout: formValues.sessionInactivityTimeout,
      },
    };
    return updateTenantConfiguration({ variables: { input } });
  });

  return (
    <FormProvider {...formMethods}>
      <Box component="form" onSubmit={onSubmit}>
        <Stack spacing={3} maxWidth={400}>
          <Callout severity="info-high">
            Updates here require an app refresh in order to take effect for the
            current user
          </Callout>
          <FormAwareCheckbox
            control={formMethods.control}
            label="Disable AI functionality"
            fieldName={
              `disableAllAIFeatures` as const satisfies TenantConfigurationFormPath
            }
          />
          <FormAwareSelectInput
            control={formMethods.control}
            showEmptyValue={false}
            required
            options={SESSION_INACTIVITY_TIMEOUT_OPTIONS}
            label="Session timeout duration"
            helpText="The amount of time before a user is logged out of the app"
            fieldName={
              `sessionInactivityTimeout` as const satisfies TenantConfigurationFormPath
            }
          />
          <Box>
            <Button loading={loading} variant="primary" type="submit" size="sm">
              Save updates
            </Button>
          </Box>
        </Stack>
      </Box>
    </FormProvider>
  );
}
