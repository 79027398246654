import { ApolloError } from '@apollo/client';

import { useReportError } from '@/hooks/useReportError';
import { DocumentType } from '@/types/schema';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { useUpdateGenericDocumentMutation } from './graphql/UseUpdateDocument.generated';

interface UpdateDocumentOptions {
  documentId: string;
  fileName: string;
  documentType: DocumentType;

  // only pass fileId if you want to update this to a new file
  fileId?: string;
  entityId?: string;
  associatedEntityIds: string[];

  enableAiSuggestions: boolean;
  isDefaultForEntity: boolean;
}

type UseUpdateDocumentReturn = [
  (options: UpdateDocumentOptions) => Promise<void>,
  { loading: boolean; error: ApolloError | undefined },
];

export function useUpdateDocument(): UseUpdateDocumentReturn {
  const [updateDocument, { loading, error }] =
    useUpdateGenericDocumentMutation();

  useReportError('error updating document', error);

  async function handleUpdateDocument(options: UpdateDocumentOptions) {
    const entityIds = options.associatedEntityIds.filter(
      (id) => getPulidKind(id) === PulidKind.Entity
    );
    const testamentaryEntityIds = options.associatedEntityIds.filter(
      (id) => getPulidKind(id) === PulidKind.TestamentaryEntity
    );
    await updateDocument({
      variables: {
        documentId: options.documentId,
        documentInput: {
          entityID: options.entityId,
          clearEntity: !options.entityId,
          clearAdditionalEntities: true,
          clearAdditionalTestamentaryEntities: true,
          addAdditionalEntityIDs: entityIds,
          addAdditionalTestamentaryEntityIDs: testamentaryEntityIds,
          type: options.documentType,
          // this is kind of weird, but we can't just "replace" the fileId;
          // we need to both pass clearFile *and* the fileId
          // needed to comment this out to replace a blocking type error, but some upgrade
          // caused a bug with the "replace" functionality
          // clearFile: !!options.fileId,
          fileID: options.fileId,
          name: options.fileName,
          enableAiSuggestions: options.enableAiSuggestions,
          defaultDocumentOfEntityID:
            options.isDefaultForEntity && options.entityId
              ? options.entityId
              : null,
        },
      },
    });
  }

  return [handleUpdateDocument, { loading, error }];
}
