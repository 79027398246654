import { ApolloError } from '@apollo/client';
import { difference, first } from 'lodash';

import { UpdateIlitCrummeyLetterInput } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { CrummeyLetterTaskMutationMutationVariables } from './graphql/CrummeyLetterMutations.generated';
import {
  GetIlitCrummeyLetterTaskDetails_IlitCrummeyLetterFragment,
  GetIlitCrummeyLetterTaskDetailsQuery,
  GetIlitCrummeyLetterTaskDetailsQueryVariables,
  useGetIlitCrummeyLetterTaskDetailsQuery,
} from './graphql/GetILITCrummeyLetterTaskDetails.generated';
import {
  ILITSendCrummeyLetterTaskDisplayData,
  ILITSendCrummeyLetterTaskForm,
} from './ILITSendCrummeyLetterTask.types';

export function useFormQueryData(
  variables: GetIlitCrummeyLetterTaskDetailsQueryVariables
): {
  formData?: ILITSendCrummeyLetterTaskForm;
  displayData?: ILITSendCrummeyLetterTaskDisplayData;
  loading: boolean;
  error?: ApolloError;
  data?: GetIlitCrummeyLetterTaskDetailsQuery;
} {
  const { data, error, loading } = useGetIlitCrummeyLetterTaskDetailsQuery({
    variables,
  });

  if (!data || error) {
    return { loading, error };
  }

  const task = getNodes(data.entityTasks).find(
    (task) => task.id === variables.taskId
  );

  if (!task) {
    throw new Error('Could not load Crummey letter task data');
  }

  const relevantCrummeyLetter: GetIlitCrummeyLetterTaskDetails_IlitCrummeyLetterFragment | null =
    task.ilitCrummeyLetter || null;

  const relevantAnnualExclusionTask = first(task.taskDependencies || []);

  const gifts = (relevantAnnualExclusionTask?.loggedTransfers || []).reduce<
    ILITSendCrummeyLetterTaskDisplayData['gifts']
  >((acc, loggedTransfer) => {
    const exclusionEvent = first(loggedTransfer.lifetimeExclusionEvents);
    if (!exclusionEvent?.giftAmount) {
      return acc;
    }
    acc.push({
      grantorName: exclusionEvent?.grantor?.displayName,
      amount: exclusionEvent?.giftAmount,
      giftDate: exclusionEvent?.eventDate,
    });
    return acc;
  }, []);

  const formData = {
    documentIds:
      relevantCrummeyLetter?.documents?.map(
        (attachedDocument) => attachedDocument.id
      ) ?? [],
    letterDate: relevantCrummeyLetter?.sentOn ?? null,
    withdrawalPeriodDays:
      relevantCrummeyLetter?.crummeyWithdrawalPeriodDays ?? 0,
    crummeyLetterId: relevantCrummeyLetter?.id ?? '',
    ilitTrustId: task.entity?.ilitTrust?.id ?? '',
  };

  const displayData: ILITSendCrummeyLetterTaskDisplayData = {
    beneficiaryName: relevantCrummeyLetter?.recipient?.displayName ?? '',
    gifts,
  };
  return { formData, displayData, data, loading, error };
}

export function generateCompleteTaskPayload({
  taskId,
  entityId,
  formData,
  initialDocumentIds,
  currentUserId,
}: {
  taskId: string;
  entityId: string;
  formData: ILITSendCrummeyLetterTaskForm;
  initialDocumentIds: string[];
  currentUserId: string;
}): CrummeyLetterTaskMutationMutationVariables {
  const addDocumentIDs = difference(formData.documentIds, initialDocumentIds);
  const removeDocumentIDs = difference(
    initialDocumentIds,
    formData.documentIds
  );

  const crummeyLetterUpdate: UpdateIlitCrummeyLetterInput = {
    crummeyWithdrawalPeriodDays: formData.withdrawalPeriodDays,
    sentOn: formData.letterDate,
  };

  if (
    !addDocumentIDs.length &&
    removeDocumentIDs.length === initialDocumentIds.length
  ) {
    crummeyLetterUpdate.clearDocuments = true;
  } else {
    crummeyLetterUpdate.addDocumentIDs = addDocumentIDs;
    crummeyLetterUpdate.removeDocumentIDs = removeDocumentIDs;
  }

  return {
    updateEntityInput: {
      id: entityId,
      update: {},
      updateIlitTrust: {
        id: formData.ilitTrustId,
        update: {},
        updateCrummeyLetters: [
          {
            id: formData.crummeyLetterId,
            update: crummeyLetterUpdate,
          },
        ],
      },
    },
    updateEntityTaskInput: {
      id: taskId,
      update: { completedAt: new Date(), completedByID: currentUserId },
    },
  };
}
