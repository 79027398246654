// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientDetailsOverviewPageQueryVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
}>;


export type ClientDetailsOverviewPageQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, estateValueMetrics: { __typename?: 'HouseholdMetrics', totalEstateValue: CurrencyUSD, inEstateValue: CurrencyUSD, outOfEstateValue: CurrencyUSD, outOfEstateCharityValue: CurrencyUSD, outOfEstateFamilyValue: CurrencyUSD }, clientPerformanceReport: { __typename?: 'ClientOverallPerformanceReport', totalWealthTransferred: CurrencyUSD, impliedEstateTaxSavings: CurrencyUSD }, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD }>, entities: { __typename?: 'EntityConnection', totalCount: number, edges?: Array<{ __typename?: 'EntityEdge', node?: { __typename?: 'Entity', id: string } | null } | null> | null } } | null } | null> | null } };

export type ClientDetailsOverviewPage__ClientProfileFragment = { __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD };

export type ClientDetailsOverviewPage_HouseholdFragment = { __typename?: 'Household', id: string, estateValueMetrics: { __typename?: 'HouseholdMetrics', totalEstateValue: CurrencyUSD, inEstateValue: CurrencyUSD, outOfEstateValue: CurrencyUSD, outOfEstateCharityValue: CurrencyUSD, outOfEstateFamilyValue: CurrencyUSD }, clientPerformanceReport: { __typename?: 'ClientOverallPerformanceReport', totalWealthTransferred: CurrencyUSD, impliedEstateTaxSavings: CurrencyUSD }, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD }>, entities: { __typename?: 'EntityConnection', totalCount: number, edges?: Array<{ __typename?: 'EntityEdge', node?: { __typename?: 'Entity', id: string } | null } | null> | null } };

export type ClientDetailsOverviewPage_HouseholdMetricsFragment = { __typename?: 'HouseholdMetrics', totalEstateValue: CurrencyUSD, inEstateValue: CurrencyUSD, outOfEstateValue: CurrencyUSD, outOfEstateCharityValue: CurrencyUSD, outOfEstateFamilyValue: CurrencyUSD };

export type ClientDetailsOverviewPage_ClientDetailsOverviewPageQueryFragment = { __typename?: 'ClientOverallPerformanceReport', totalWealthTransferred: CurrencyUSD, impliedEstateTaxSavings: CurrencyUSD };

export const ClientDetailsOverviewPage_HouseholdMetricsFragmentDoc = gql`
    fragment ClientDetailsOverviewPage_HouseholdMetrics on HouseholdMetrics {
  totalEstateValue
  inEstateValue
  outOfEstateValue
  outOfEstateCharityValue
  outOfEstateFamilyValue
}
    `;
export const ClientDetailsOverviewPage_ClientDetailsOverviewPageQueryFragmentDoc = gql`
    fragment ClientDetailsOverviewPage_ClientDetailsOverviewPageQuery on ClientOverallPerformanceReport {
  totalWealthTransferred
  impliedEstateTaxSavings
}
    `;
export const ClientDetailsOverviewPage__ClientProfileFragmentDoc = gql`
    fragment ClientDetailsOverviewPage__ClientProfile on ClientProfile {
  id
  gstExclusionUsed
  displayName
  lifetimeExclusionUsed
  currentAnnualExclusionUsed
  totalAnnualExclusionUsed
}
    `;
export const ClientDetailsOverviewPage_HouseholdFragmentDoc = gql`
    fragment ClientDetailsOverviewPage_Household on Household {
  id
  estateValueMetrics {
    ...ClientDetailsOverviewPage_HouseholdMetrics
  }
  clientPerformanceReport {
    ...ClientDetailsOverviewPage_ClientDetailsOverviewPageQuery
  }
  possiblePrimaryClients {
    ...ClientDetailsOverviewPage__ClientProfile
  }
  entities {
    totalCount
    edges {
      node {
        id
      }
    }
  }
}
    ${ClientDetailsOverviewPage_HouseholdMetricsFragmentDoc}
${ClientDetailsOverviewPage_ClientDetailsOverviewPageQueryFragmentDoc}
${ClientDetailsOverviewPage__ClientProfileFragmentDoc}`;
export const ClientDetailsOverviewPageDocument = gql`
    query ClientDetailsOverviewPage($householdId: ID!) {
  households(where: {id: $householdId}) {
    edges {
      node {
        id
        ...ClientDetailsOverviewPage_Household
      }
    }
  }
}
    ${ClientDetailsOverviewPage_HouseholdFragmentDoc}`;

/**
 * __useClientDetailsOverviewPageQuery__
 *
 * To run a query within a React component, call `useClientDetailsOverviewPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsOverviewPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsOverviewPageQuery({
 *   variables: {
 *      householdId: // value for 'householdId'
 *   },
 * });
 */
export function useClientDetailsOverviewPageQuery(baseOptions: Apollo.QueryHookOptions<ClientDetailsOverviewPageQuery, ClientDetailsOverviewPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientDetailsOverviewPageQuery, ClientDetailsOverviewPageQueryVariables>(ClientDetailsOverviewPageDocument, options);
      }
export function useClientDetailsOverviewPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientDetailsOverviewPageQuery, ClientDetailsOverviewPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientDetailsOverviewPageQuery, ClientDetailsOverviewPageQueryVariables>(ClientDetailsOverviewPageDocument, options);
        }
export function useClientDetailsOverviewPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientDetailsOverviewPageQuery, ClientDetailsOverviewPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientDetailsOverviewPageQuery, ClientDetailsOverviewPageQueryVariables>(ClientDetailsOverviewPageDocument, options);
        }
export type ClientDetailsOverviewPageQueryHookResult = ReturnType<typeof useClientDetailsOverviewPageQuery>;
export type ClientDetailsOverviewPageLazyQueryHookResult = ReturnType<typeof useClientDetailsOverviewPageLazyQuery>;
export type ClientDetailsOverviewPageSuspenseQueryHookResult = ReturnType<typeof useClientDetailsOverviewPageSuspenseQuery>;
export type ClientDetailsOverviewPageQueryResult = Apollo.QueryResult<ClientDetailsOverviewPageQuery, ClientDetailsOverviewPageQueryVariables>;
export const Operations = {
  Query: {
    ClientDetailsOverviewPage: 'ClientDetailsOverviewPage' as const
  },
  Fragment: {
    ClientDetailsOverviewPage__ClientProfile: 'ClientDetailsOverviewPage__ClientProfile' as const,
    ClientDetailsOverviewPage_Household: 'ClientDetailsOverviewPage_Household' as const,
    ClientDetailsOverviewPage_HouseholdMetrics: 'ClientDetailsOverviewPage_HouseholdMetrics' as const,
    ClientDetailsOverviewPage_ClientDetailsOverviewPageQuery: 'ClientDetailsOverviewPage_ClientDetailsOverviewPageQuery' as const
  }
}