import React, { useState } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useFormContext } from '@/components/react-hook-form';
import { CreateAssetCategoryModal } from '@/modules/assetCategories/CreateAssetCategoryModal/CreateAssetCategoryModal';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';

import {
  AssetDetailsForm,
  AssetDetailsFormPath,
} from './AssetDetailsModal.types';

interface AssetCategoryFieldProps {
  options: SelectInputOption<string>[];
}

export const AssetCategoryField = ({ options }: AssetCategoryFieldProps) => {
  const userCanCreateAssetCategories = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_MODIFY_ASSET_CATEGORIES
  );
  const { control } = useFormContext<AssetDetailsForm>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <FormAwareSelectInput<AssetDetailsForm>
        fieldName={'categoryId' as const satisfies AssetDetailsFormPath}
        label="Asset category"
        required
        showEmptyValue={false}
        control={control}
        options={options}
        addNewOption={
          userCanCreateAssetCategories
            ? {
                onClick: () => setIsModalOpen(true),
                icon: <PlusIcon size={16} />,
              }
            : undefined
        }
      />
      <CreateAssetCategoryModal
        isOpen={isModalOpen}
        existingOptionsCount={options.length}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};
