import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { Control, UseFormResetField } from 'react-hook-form';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { Button } from '@/components/form/baseInputs/Button';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { ArrowRightChunkyIcon } from '@/components/icons/ArrowRightChunkyIcon';
import { Modal } from '@/components/modals/Modal/Modal';
import { Callout } from '@/components/notifications/Callout/Callout';
import { CurrencyUSD } from '@/graphql/scalars';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';

import { AnnuityTaskDetailsForm } from './types';

interface EditAnnuityPaymentAmountModalProps {
  paymentAmount: CurrencyUSD;
  control: Control<AnnuityTaskDetailsForm>;
  resetField: UseFormResetField<AnnuityTaskDetailsForm>;
  isOpen: boolean;
  onClose: (markAsFailed: boolean) => void;
}

enum PaymentChangeReason {
  FAILED_GRAT = 'FAILED_GRAT',
  OTHER = 'OTHER',
}

const EDIT_PAYMENT_REASON_OPTIONS = [
  {
    value: PaymentChangeReason.FAILED_GRAT,
    display: 'Failed GRAT',
  },
  {
    value: PaymentChangeReason.OTHER,
    display: 'Other reason',
  },
];

export function EditAnnuityPaymentAmountModal({
  paymentAmount,
  control,
  resetField,
  isOpen,
  onClose,
}: EditAnnuityPaymentAmountModalProps) {
  const [changeReason, setChangeReason] = useState<PaymentChangeReason>(
    PaymentChangeReason.FAILED_GRAT
  );

  const handleOnClose = () => {
    resetField('failedAnnuityPaymentAmount');
    onClose(false);
  };

  const handleSubmit = () => {
    onClose(true);
  };

  return (
    <Modal
      heading="Adjust annuity payment"
      onClose={handleOnClose}
      isOpen={isOpen}
      actions={
        <>
          <Button size="sm" variant="secondary" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button size="sm" variant="primary" onClick={handleSubmit}>
            Update payment
          </Button>
        </>
      }
    >
      <Stack spacing={2} component="form" onSubmit={handleSubmit}>
        <ButtonGroup
          value={changeReason}
          onChange={(_, value) => setChangeReason(value)}
          options={EDIT_PAYMENT_REASON_OPTIONS}
          label=""
        />
        {changeReason === PaymentChangeReason.FAILED_GRAT && (
          <Stack spacing={2}>
            <Typography variant="body1" component="p">
              Payment amounts are most commonly modified due to a failed GRAT.
              Please input your revised annuity payment amount below.
            </Typography>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <OversizedMetricItem
                title="Current payment amount"
                value={formatCurrency(paymentAmount)}
              />
              <Box width={56}>
                <ArrowRightChunkyIcon fill={COLORS.GRAY[200]} size={50} />
              </Box>
              <FormAwareCurrencyInput<AnnuityTaskDetailsForm>
                control={control}
                isDecimalJSInput
                fieldName="failedAnnuityPaymentAmount"
                label=""
              />
            </Stack>
            <Box mt={4}>
              <Callout severity="error">
                Completing this process will automatically set the market value
                of the GRAT to $0.00 and mark the GRAT as completed and failed.
              </Callout>
            </Box>
          </Stack>
        )}
        {changeReason === PaymentChangeReason.OTHER && (
          <Typography variant="body1" component="p">
            Please contact us directly at{' '}
            <a href="mailto:support@withluminary.com">
              support@withluminary.com
            </a>{' '}
            to make changes to an annuity payment for any reason other than for
            a failed GRAT.
          </Typography>
        )}
      </Stack>
    </Modal>
  );
}
