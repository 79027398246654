// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrustDetailsSummary_SummaryPersonFragment = { __typename?: 'SummaryPerson', displayName: string, additionalContext?: Array<string | null> | null, badgeText?: string | null, displayType?: string | null, address: string };

export type TrustDetailsSummary_DesignSummaryDetailsFragment = { __typename?: 'GRATDesignSummaryDetails', displayName: string, legalName?: string | null, attorneyNotes?: string | null, spouseDisplayName?: string | null, termDescription: string, officialInterestRate: string, initialFundingValue: string, grantorRetainedInterest: string, taxableGiftAmount: string, annuityPaymentAnnualIncreasePercentage: string, trustSitus?: string | null, trustees: Array<{ __typename?: 'SummaryPerson', displayName: string, additionalContext?: Array<string | null> | null, badgeText?: string | null, displayType?: string | null, address: string }>, successorTrustees: Array<{ __typename?: 'SummaryPerson', displayName: string, additionalContext?: Array<string | null> | null, badgeText?: string | null, displayType?: string | null, address: string }>, trustAdvisors: Array<{ __typename?: 'SummaryPerson', displayName: string, additionalContext?: Array<string | null> | null, badgeText?: string | null, displayType?: string | null, address: string }>, beneficiaries: Array<{ __typename?: 'SummaryBeneficiaryv2', id: string, displayName: string, displayType: string, badgeText?: string | null, kind: Types.SummaryBeneficiaryKindv2, additionalContext: Array<string | null>, ownershipPercentage: string }>, grantor: { __typename?: 'SummaryPerson', address: string, displayName: string }, assets: Array<{ __typename?: 'SummaryAsset', displayName: string, qsbsDetails?: string | null, shareCount?: string | null, sharePrice?: string | null, type: string, value: string }>, annuityPayments: Array<{ __typename?: 'SummaryAnnuityPaymentDetails', label: string, paymentPercentage: string }>, termDetailsInfo: { __typename?: 'TermDetailsInfo', display: string, displayType: Types.InfoMessageDisplayType } };

export type GetDesignSummaryDetailsQueryVariables = Types.Exact<{
  entityId: Types.Scalars['ID']['input'];
}>;


export type GetDesignSummaryDetailsQuery = { __typename?: 'Query', gratDesignSummaryDetails: { __typename?: 'GRATDesignSummaryDetails', displayName: string, legalName?: string | null, attorneyNotes?: string | null, spouseDisplayName?: string | null, termDescription: string, officialInterestRate: string, initialFundingValue: string, grantorRetainedInterest: string, taxableGiftAmount: string, annuityPaymentAnnualIncreasePercentage: string, trustSitus?: string | null, trustees: Array<{ __typename?: 'SummaryPerson', displayName: string, additionalContext?: Array<string | null> | null, badgeText?: string | null, displayType?: string | null, address: string }>, successorTrustees: Array<{ __typename?: 'SummaryPerson', displayName: string, additionalContext?: Array<string | null> | null, badgeText?: string | null, displayType?: string | null, address: string }>, trustAdvisors: Array<{ __typename?: 'SummaryPerson', displayName: string, additionalContext?: Array<string | null> | null, badgeText?: string | null, displayType?: string | null, address: string }>, beneficiaries: Array<{ __typename?: 'SummaryBeneficiaryv2', id: string, displayName: string, displayType: string, badgeText?: string | null, kind: Types.SummaryBeneficiaryKindv2, additionalContext: Array<string | null>, ownershipPercentage: string }>, grantor: { __typename?: 'SummaryPerson', address: string, displayName: string }, assets: Array<{ __typename?: 'SummaryAsset', displayName: string, qsbsDetails?: string | null, shareCount?: string | null, sharePrice?: string | null, type: string, value: string }>, annuityPayments: Array<{ __typename?: 'SummaryAnnuityPaymentDetails', label: string, paymentPercentage: string }>, termDetailsInfo: { __typename?: 'TermDetailsInfo', display: string, displayType: Types.InfoMessageDisplayType } } };

export const TrustDetailsSummary_SummaryPersonFragmentDoc = gql`
    fragment TrustDetailsSummary_SummaryPerson on SummaryPerson {
  displayName
  additionalContext
  badgeText
  displayType
  address
}
    `;
export const TrustDetailsSummary_DesignSummaryDetailsFragmentDoc = gql`
    fragment TrustDetailsSummary_DesignSummaryDetails on GRATDesignSummaryDetails {
  displayName
  legalName
  trustees {
    ...TrustDetailsSummary_SummaryPerson
  }
  successorTrustees {
    ...TrustDetailsSummary_SummaryPerson
  }
  trustAdvisors {
    ...TrustDetailsSummary_SummaryPerson
  }
  beneficiaries {
    id
    displayName
    displayType
    badgeText
    kind
    additionalContext
    ownershipPercentage
  }
  attorneyNotes
  grantor {
    address
    displayName
  }
  spouseDisplayName
  assets {
    displayName
    qsbsDetails
    shareCount
    sharePrice
    type
    value
  }
  annuityPayments {
    label
    paymentPercentage
  }
  termDescription
  officialInterestRate
  termDetailsInfo {
    display
    displayType
  }
  initialFundingValue
  grantorRetainedInterest
  taxableGiftAmount
  annuityPaymentAnnualIncreasePercentage
  trustSitus
}
    ${TrustDetailsSummary_SummaryPersonFragmentDoc}`;
export const GetDesignSummaryDetailsDocument = gql`
    query GetDesignSummaryDetails($entityId: ID!) {
  gratDesignSummaryDetails(entityID: $entityId) {
    ...TrustDetailsSummary_DesignSummaryDetails
  }
}
    ${TrustDetailsSummary_DesignSummaryDetailsFragmentDoc}`;

/**
 * __useGetDesignSummaryDetailsQuery__
 *
 * To run a query within a React component, call `useGetDesignSummaryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDesignSummaryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDesignSummaryDetailsQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useGetDesignSummaryDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetDesignSummaryDetailsQuery, GetDesignSummaryDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDesignSummaryDetailsQuery, GetDesignSummaryDetailsQueryVariables>(GetDesignSummaryDetailsDocument, options);
      }
export function useGetDesignSummaryDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDesignSummaryDetailsQuery, GetDesignSummaryDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDesignSummaryDetailsQuery, GetDesignSummaryDetailsQueryVariables>(GetDesignSummaryDetailsDocument, options);
        }
export function useGetDesignSummaryDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDesignSummaryDetailsQuery, GetDesignSummaryDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDesignSummaryDetailsQuery, GetDesignSummaryDetailsQueryVariables>(GetDesignSummaryDetailsDocument, options);
        }
export type GetDesignSummaryDetailsQueryHookResult = ReturnType<typeof useGetDesignSummaryDetailsQuery>;
export type GetDesignSummaryDetailsLazyQueryHookResult = ReturnType<typeof useGetDesignSummaryDetailsLazyQuery>;
export type GetDesignSummaryDetailsSuspenseQueryHookResult = ReturnType<typeof useGetDesignSummaryDetailsSuspenseQuery>;
export type GetDesignSummaryDetailsQueryResult = Apollo.QueryResult<GetDesignSummaryDetailsQuery, GetDesignSummaryDetailsQueryVariables>;
export const Operations = {
  Query: {
    GetDesignSummaryDetails: 'GetDesignSummaryDetails' as const
  },
  Fragment: {
    TrustDetailsSummary_SummaryPerson: 'TrustDetailsSummary_SummaryPerson' as const,
    TrustDetailsSummary_DesignSummaryDetails: 'TrustDetailsSummary_DesignSummaryDetails' as const
  }
}