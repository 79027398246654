import { isEmpty } from 'lodash';
import { PropsWithChildren, useMemo } from 'react';

import { useModalState } from '@/hooks/useModalState';
import {
  SummaryModalState,
  WaterfallSummaryContext,
} from '@/modules/estateWaterfall/contexts/waterfallSummary.context';
import { applyWaterfallFilter } from '@/modules/estateWaterfall/EstateWaterfall.utils';
import { getNodes } from '@/utils/graphqlUtils';

import { useWaterfallSummaryData } from '../useWaterfallSummaryData';

interface WaterfallSummaryContextProps {
  waterfallId: string;
}

function useWaterfallSummaryContextValue({
  waterfallId,
}: WaterfallSummaryContextProps): WaterfallSummaryContext {
  const { data, loading } = useWaterfallSummaryData(waterfallId);

  const [summaryPanelState, summaryPanelMethods] =
    useModalState<SummaryModalState>();

  const waterfall = useMemo(() => {
    return applyWaterfallFilter(getNodes(data?.estateWaterfalls)[0] || null);
  }, [data?.estateWaterfalls]);

  return useMemo(() => {
    const isLoadingFromNetwork = loading && !waterfall;
    const hasNoWaterfallNodes =
      !isLoadingFromNetwork &&
      isEmpty(waterfall?.visualizationWithProjections.nodes);

    return {
      loading: isLoadingFromNetwork,
      waterfall,
      hasNoWaterfallNodes,
      summaryPanel: {
        ...summaryPanelState,
        ...summaryPanelMethods,
      },
    };
  }, [loading, summaryPanelMethods, summaryPanelState, waterfall]);
}

export const WaterfallSummaryProvider = ({
  children,
  ...props
}: PropsWithChildren<WaterfallSummaryContextProps>) => {
  const value = useWaterfallSummaryContextValue(props);
  return (
    <WaterfallSummaryContext.Provider value={value}>
      {children}
    </WaterfallSummaryContext.Provider>
  );
};
