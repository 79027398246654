import { useApolloClient } from '@apollo/client';
import React from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Card } from '@/components/layout/Card/Card';
import { Alert } from '@/components/notifications/Alert/Alert';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { EntityFormTrowser } from '@/modules/entities/EntityForm/EntityFormTrowser';
import { EntityFormStage } from '@/modules/entities/EntityForm/types';

import { GetImplementationTasksV2Document } from '../../graphql/ImplementationTasks.generated';
import { TaskHeaderCard } from '../../TaskHeaderCard';
import { TaskBodyProps } from '../../types';
import {
  GetDesignSummaryDetailsDocument,
  useGetDesignSummaryDetailsQuery,
} from '../components/TrustDetailsSummary/graphql/TrustDetailsSummary.generated';
import { TrustDetailsSummary } from '../components/TrustDetailsSummary/TrustDetailsSummary';
import { useCompleteEntityTaskMutation } from '../graphql/CompleteEntityTask.generated';
import { useCompleteSummaryTemplateTaskMutation } from './graphql/DesignSummary.generated';

export function CompleteDesignSummaryTaskBody({
  task,
  onCompleteTask,
  footer,
}: TaskBodyProps) {
  const apolloClient = useApolloClient();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const client = useApolloClient();
  const [isEntityModalOpen, setIsEntityModalOpen] = React.useState(false);
  const [completeTask, { loading: submittingSkipTask }] =
    useCompleteEntityTaskMutation();
  const [updateStrategyAndCompleteTask, { loading: submitting }] =
    useCompleteSummaryTemplateTaskMutation();
  const {
    data,
    error,
    loading: loadingEntity,
  } = useGetDesignSummaryDetailsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      entityId: task.entityId,
    },
  });

  function handleOpenEditModal() {
    setIsEntityModalOpen(true);
  }

  function handleClose() {
    setIsEntityModalOpen(false);

    void apolloClient.refetchQueries({
      include: [
        GetImplementationTasksV2Document,
        GetDesignSummaryDetailsDocument,
      ],
    });
  }

  if (error) {
    return (
      <TaskHeaderCard task={task} footer={footer}>
        <Alert severity="error" size="lg">
          Sorry, we weren&apos;t able to load the summary information. Please
          try refreshing the page.
        </Alert>
      </TaskHeaderCard>
    );
  }

  if (!data) {
    return <TaskHeaderCard task={task} footer={footer} loading={true} />;
  }

  const { gratDesignSummaryDetails: designSummary } = data;

  async function handleFormSubmit() {
    await updateStrategyAndCompleteTask({
      variables: {
        taskID: task.id,
        entityId: task.entityId ?? '',
      },
      onCompleted: async (data) => {
        await client.refetchQueries({
          updateCache(cache) {
            data.generateGRATDesignSummaryV2.entity &&
              cache.evict({
                id: cache.identify(data.generateGRATDesignSummaryV2.entity),
              });
            cache.evict({ fieldName: 'entities' });
            cache.gc();
          },
        });
        onCompleteTask();
      },
      onError: (err) => {
        reportError('Failed to save design summary and generate PDF', err);
        showFeedback(
          `We weren't able to save your changes and generate the summary. Please try again.`
        );
      },
    });
  }

  async function handleSkipTask() {
    await completeTask({
      variables: {
        taskID: task.id,
      },
      onCompleted: () => onCompleteTask(),
      onError: (err) => {
        reportError('failed to skip/complete design summary task', err);
        showFeedback(`We weren't able to skip this task. Please try again.`);
      },
    });
  }

  return (
    <>
      {isEntityModalOpen && (
        <EntityFormTrowser
          isOpen={true}
          stage={EntityFormStage.GRAT_IMPLEMENTATION_EDIT}
          type="grat"
          handleClose={handleClose}
          householdId={task.householdId}
          entityId={task.entityId}
          header={'Edit design summary'}
        />
      )}
      <TaskHeaderCard task={task} footer={footer}>
        <TrustDetailsSummary
          designSummary={designSummary}
          onEditClick={handleOpenEditModal}
          variant="completeDesignSummary"
        />

        <Card variant="filled" sx={{ p: 2 }}>
          <Button
            disabled={loadingEntity || submittingSkipTask}
            loading={submitting}
            variant="primary"
            fullWidth
            size="sm"
            type="submit"
            onClick={handleFormSubmit}
          >
            {submitting ? 'Generating summary...' : 'Generate design summary'}
          </Button>
          <Button
            onClick={handleSkipTask}
            loading={submittingSkipTask}
            disabled={loadingEntity}
            variant="transparent"
            fullWidth
            size="sm"
          >
            Skip this
          </Button>
        </Card>
      </TaskHeaderCard>
    </>
  );
}
