import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import { Card, CardProps } from '@/components/layout/Card/Card';
import { getFieldErrorValue } from '@/components/utils/inputUtils';
import { COLORS } from '@/styles/tokens/colors';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

export type FormAwareCardProps<FormShape extends FieldValues> = {
  fieldName: FieldNameFromFormShape<FormShape>;
  control: Control<FormShape>;
  validation?: RegisterOptions<FormShape>['validate'];
} & CardProps;

/**
 * @description FormAwareCard can be used to indicate form validity for inputs inside a card component.
 */
export function FormAwareCard<FormShape extends FieldValues>({
  fieldName,
  control,
  children,
  validation,
  sx = {},
  ...inputProps
}: FormAwareCardProps<FormShape>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validation }}
      render={({ field, fieldState, formState }) => {
        const errorMessage = getFieldErrorValue(
          fieldState,
          formState.isSubmitted
        );

        const errorProps = errorMessage
          ? {
              border: `solid ${COLORS.FUNCTIONAL.ERROR.DEFAULT} 1px`,
              ':focus': {
                outline: `solid ${COLORS.FUNCTIONAL.ERROR.DEFAULT} 2px`,
                outlineOffset: 2,
              },
            }
          : {};

        const mergedSx = Object.assign({}, sx, errorProps);

        return (
          <Card
            tabIndex={-1} // allow this element to be focused, but not tabbable
            {...inputProps}
            sx={mergedSx}
            inputRef={field.ref}
          >
            {children}
          </Card>
        );
      }}
    />
  );
}
