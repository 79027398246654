import { useMemo } from 'react';

import { getIsAISuggestionsEnabled } from '@/modules/aiSuggestions/hooks/useAISuggestionsEnabled';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { useAICapabilitiesEnabled } from '@/modules/tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import { EntityKind } from '@/types/schema';

export function useShowAISuggestionsToggle<E extends { kind: EntityKind }>(
  selectedEntityId: string,
  entitiesByID: Record<string, E>
) {
  const aiCapabilitiesEnabled = useAICapabilitiesEnabled();
  const entityCtx = useEntityDetailsContext();

  return useMemo(() => {
    if (!selectedEntityId) {
      return false;
    }

    const selectedEntity = entitiesByID?.[selectedEntityId];
    if (!selectedEntity) {
      return false;
    }

    if (!!entityCtx.entityId && entityCtx.entityId !== selectedEntityId) {
      return false;
    }

    return getIsAISuggestionsEnabled(
      selectedEntity.kind,
      aiCapabilitiesEnabled
    );
  }, [
    selectedEntityId,
    entitiesByID,
    entityCtx.entityId,
    aiCapabilitiesEnabled,
  ]);
}
