import { Box, Stack, Typography } from '@mui/material';
import { Path, useWatch } from 'react-hook-form';

import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form/hooks';
import { COLORS } from '@/styles/tokens/colors';
import { ScheduledDistributionKind } from '@/types/schema';

import {
  SCHEDULED_DISTRIBUTION_FREQUENCY_ITEMS,
  SCHEDULED_DISTRIBUTION_KINDS,
} from './EntityBeneficiariesSubform.constants';
import { BeneficiaryFormScheduledDistribution } from './EntityBeneficiariesSubform.types';

// The ScheduledDistributionForm is part of the larger EntityBeneficiariesSubform, however
// we use BeneficiaryFormScheduledDistribution as the type for this particular form component
// so it is less coupled with the larger form context. This also helps with TS performance.
function getScheduledDistributionFieldName<
  T extends ScheduledDistributionFormPaths,
>(
  prefix: ScheduledDistributionFormProps['fieldName'],
  scheduledDistributionFormPath: T
): T {
  return `${prefix}.${scheduledDistributionFormPath}` as T;
}

type ScheduledDistributionFormPaths =
  Path<BeneficiaryFormScheduledDistribution>;

interface ScheduledDistributionFormProps {
  fieldName: `scheduledDistributions.${number}`;
}

export function ScheduledDistributionForm({
  fieldName,
}: ScheduledDistributionFormProps) {
  const { control } = useFormContext<BeneficiaryFormScheduledDistribution>();

  const selectedKind = useWatch({
    name: getScheduledDistributionFieldName(
      fieldName,
      'scheduledDistribution.kind'
    ),
    control,
  });

  const hasAgeRequirementToggled = useWatch({
    name: getScheduledDistributionFieldName(fieldName, '_hasAgeParams'),
    control,
  });

  const selectedAgeParamKind = useWatch({
    name: getScheduledDistributionFieldName(fieldName, '_ageRequirementKind'),
    control,
  });

  const isPercentage = selectedKind === ScheduledDistributionKind.Percentage;
  const isAmount = selectedKind === ScheduledDistributionKind.Amount;
  const isFunction = selectedKind === ScheduledDistributionKind.AllIncome;

  return (
    <Card
      variant="filled-dark"
      sx={{ p: 2, pb: 3, bgcolor: COLORS.GRAY[300], borderRadius: '6px' }}
      data-testid={`${fieldName}-scheduled-distribution-form`}
    >
      <Stack spacing={2}>
        <Stack direction="row" spacing={3} justifyContent="space-between">
          <Stack width="153px">
            <FormAwareButtonGroup<BeneficiaryFormScheduledDistribution>
              variant="dark"
              label="Scheduled distribution"
              hideLabel
              fieldName={getScheduledDistributionFieldName(
                fieldName,
                'scheduledDistribution.kind'
              )}
              control={control}
              options={SCHEDULED_DISTRIBUTION_KINDS}
            />
          </Stack>
          {isPercentage && (
            <FormAwarePercentInput<BeneficiaryFormScheduledDistribution>
              required
              label="Distribution percentage"
              hideLabel
              fieldName={getScheduledDistributionFieldName(
                fieldName,
                'scheduledDistribution.percentage'
              )}
              control={control}
              isDecimalJSInput
              decimalScale={2}
            />
          )}
          {isAmount && (
            <FormAwareCurrencyInput<BeneficiaryFormScheduledDistribution>
              required
              label="Distribution amount"
              hideLabel
              fieldName={getScheduledDistributionFieldName(
                fieldName,
                'scheduledDistribution.amount'
              )}
              control={control}
              isDecimalJSInput
            />
          )}
          {isFunction && (
            <Stack alignItems="end" alignSelf="center" flex={1}>
              <Typography variant="label">All trust income</Typography>
            </Stack>
          )}
          <Stack flex={1} minWidth="140px">
            <FormAwareSelectInput<BeneficiaryFormScheduledDistribution>
              required
              label="Distribution frequency"
              hideLabel
              fieldName={getScheduledDistributionFieldName(
                fieldName,
                'scheduledDistribution.frequency'
              )}
              control={control}
              options={SCHEDULED_DISTRIBUTION_FREQUENCY_ITEMS}
            />
          </Stack>
        </Stack>
        <FormAwareTextInput<BeneficiaryFormScheduledDistribution>
          label="Notes"
          hideLabel
          placeholder="Specify details"
          multiline
          fieldName={getScheduledDistributionFieldName(
            fieldName,
            'scheduledDistribution.scheduledDistributionNotes'
          )}
          control={control}
          rows={2}
        />
        <FormAwareSwitch<BeneficiaryFormScheduledDistribution>
          label="Age requirement"
          labelPosition="right"
          fieldName={getScheduledDistributionFieldName(
            fieldName,
            '_hasAgeParams'
          )}
          control={control}
        />
        {hasAgeRequirementToggled && (
          <Stack spacing={2} pl={7}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Stack minWidth="120px">
                <FormAwareSelectInput<BeneficiaryFormScheduledDistribution>
                  label="Age requirement"
                  hideLabel
                  fieldName={getScheduledDistributionFieldName(
                    fieldName,
                    '_ageRequirementKind'
                  )}
                  control={control}
                  options={[
                    { value: 'upon', display: 'Upon' },
                    { value: 'until', display: 'Until' },
                    { value: 'between', display: 'Between' },
                  ]}
                />
              </Stack>
              <Typography variant="label" width="30px">
                {selectedAgeParamKind === 'between' ? 'ages' : 'age'}
              </Typography>
              {(selectedAgeParamKind === 'upon' ||
                selectedAgeParamKind === 'between') && (
                <Box width="80px">
                  <FormAwareFormattedNumberInput<BeneficiaryFormScheduledDistribution>
                    control={control}
                    label="Age"
                    hideLabel
                    fieldName={getScheduledDistributionFieldName(
                      fieldName,
                      'scheduledDistribution.ageRequirementStart'
                    )}
                    fixedDecimalScale
                    decimalScale={0}
                  />
                </Box>
              )}
              {selectedAgeParamKind === 'between' && (
                <Typography variant="label">to</Typography>
              )}
              {(selectedAgeParamKind === 'until' ||
                selectedAgeParamKind === 'between') && (
                <Box width="80px">
                  <FormAwareFormattedNumberInput<BeneficiaryFormScheduledDistribution>
                    control={control}
                    label="Age"
                    hideLabel
                    fieldName={getScheduledDistributionFieldName(
                      fieldName,
                      'scheduledDistribution.ageRequirementEnd'
                    )}
                    fixedDecimalScale
                    decimalScale={0}
                  />
                </Box>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
