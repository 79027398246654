import Decimal from 'decimal.js';
import { ComponentProps } from 'react';

import { PrimaryCellTypography } from '@/components/tables/DataTable/components/cells';
import {
  AfterDeath,
  EntityGstStatus,
  EstateWaterfallHypotheticalTransferTransferTaxKind,
  LoggedTransferPurpose,
} from '@/types/schema';

import {
  BeneficiaryReporting_AccessParameterFragment,
  BeneficiaryReporting_PowerOfAppointmentFragment,
  BeneficiaryReporting_ScheduledDistributionFragment,
} from './graphql/BeneficiaryReporting.generated';

export type ClientOrOrgId = string;

export enum BeneficiaryReportingRowVariant {
  Item = 'ITEM',
  Subtitle = 'SUBTITLE',
}

export enum BeneficiaryReportBenefitKind {
  ScheduledDistribution = 'SCHEDULED_DISTRIBUTION',
  DirectGift = 'DIRECT_GIFT',
  FullAccess = 'FULL_ACCESS',
  PartialAccess = 'PARTIAL_ACCESS',
}

export enum BeneficiaryReportScheduledDistributionKind {
  Distribution = 'DISTRIBUTION',
  Disposition = 'DISPOSITION',
}

export enum BeneficiaryReportDirectGiftKind {
  Hypothetical = 'HYPOTHETICAL',
  LoggedTransfer = 'LOGGED_TRANSFER',
}

interface BeneficiaryReportingTableRow {
  id: string;
  variant: BeneficiaryReportingRowVariant;
  benefitKind: BeneficiaryReportBenefitKind;
  sourceId: string;
  sourceName: string;
  entityGstStatus: EntityGstStatus | null;
  notes: Record<string, string> | null;
  sourceKind:
    | 'Entity'
    | 'ClientProfile'
    | 'ClientOrganization'
    | 'TestamentaryEntity';
}

export interface BeneficiaryReportScheduledDistribution
  extends BeneficiaryReportingTableRow {
  kind: BeneficiaryReportScheduledDistributionKind;
  scheduledDistribution: BeneficiaryReporting_ScheduledDistributionFragment | null;
  dispositionAmount: Decimal;
  projectedEntityValue: Decimal;
  powerOfAppointment: BeneficiaryReporting_PowerOfAppointmentFragment | null;
  dispositionParameters: string | null;
}

export interface BeneficiaryReportDirectGift
  extends BeneficiaryReportingTableRow {
  associatedLifetimeExclusionEventId: string | null;
  kind: BeneficiaryReportDirectGiftKind;
  giftAmount: Decimal;
  giftDate: Date | null;
  transferTaxKind: EstateWaterfallHypotheticalTransferTransferTaxKind | null;
  purpose: LoggedTransferPurpose | null;
}

export interface BeneficiaryReportFullAccess
  extends BeneficiaryReportingTableRow {
  accessParameter: BeneficiaryReporting_AccessParameterFragment;
  projectedEntityValue: Decimal;
  powerOfAppointment: BeneficiaryReporting_PowerOfAppointmentFragment | null;
}

export interface BeneficiaryReportPartialAccess
  extends BeneficiaryReportingTableRow {
  accessParameter: BeneficiaryReporting_AccessParameterFragment;
  projectedEntityValue: Decimal;
  powerOfAppointment: BeneficiaryReporting_PowerOfAppointmentFragment | null;
}

export type BeneficiaryReportingTableRowData =
  | BeneficiaryReportScheduledDistribution
  | BeneficiaryReportDirectGift
  | BeneficiaryReportFullAccess
  | BeneficiaryReportPartialAccess;

interface TwoLineRowData {
  lineOne: string;
  lineOneProps?: ComponentProps<typeof PrimaryCellTypography>;
  lineTwo?: string;
  rightContent?: JSX.Element;
  textAccompaniment?: JSX.Element;
}

export interface BenefitsTableRowData {
  id: string;
  variant: BeneficiaryReportingRowVariant;
  sectionIndicator?: {
    backgroundColor: string;
  };
  entity: TwoLineRowData;
  parameters?: TwoLineRowData;
  distributionValue?: TwoLineRowData | Decimal | string;
  projectedEntityValue: TwoLineRowData | Decimal | string;
}

export interface BeneficiaryReport {
  scheduledDistributions: BeneficiaryReportScheduledDistribution[];
  directGifts: BeneficiaryReportDirectGift[];
  fullAccess: BeneficiaryReportFullAccess[];
  partialAccess: BeneficiaryReportPartialAccess[];
}

export type BeneficiariesData = Record<
  AfterDeath,
  Record<ClientOrOrgId, BeneficiaryReport>
>;
