import { Control } from 'react-hook-form';

import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { GRATDisplayName } from '@/modules/content/tooltipContent/GRATDisplayName';

import { BasicInformationDesignerForm } from '../../BasicInformationDesigner/constants';

export interface GRATDisplayNameInputProps {
  control: Control<BasicInformationDesignerForm>;
  disabled?: boolean;
}

export function GRATDisplayNameInput({
  control,
  disabled,
}: GRATDisplayNameInputProps) {
  return (
    <FormAwareTextInput<BasicInformationDesignerForm>
      label="GRAT display name"
      required={true}
      disabled={disabled}
      fieldName="displayName"
      control={control}
      contextualHelp={<GRATDisplayName />}
    />
  );
}
