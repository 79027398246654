import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { Grid } from '@/components/layout/Grid';
import { BackfillOCRCard } from '@/pages/internal/DocumentInferenceSandbox/toolsTab/backfillOCR/BackfillOCRCard';
import { BackfillSummariesCard } from '@/pages/internal/DocumentInferenceSandbox/toolsTab/backfillSummaries/BackfillSummariesCard';

export function DocumentInferenceSandbox() {
  return (
    <SubpageLayout heading="Document Inference Sandbox">
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12}>
          <BackfillOCRCard />
        </Grid>
        <Grid item lg={6} sm={12}>
          <BackfillSummariesCard />
        </Grid>
      </Grid>
    </SubpageLayout>
  );
}
