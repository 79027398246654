import Decimal from 'decimal.js';
import { set } from 'lodash';

import {
  EMPTY_CHART_BACKGROUND_CSS,
  useChartColorDefinitions,
} from '@/components/charts/constants';
import { Section } from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import { PopperContent } from '@/components/poppers/PopperContent';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { COLORS } from '@/styles/tokens/colors';
import {
  formatCurrency,
  formatCurrencyNoDecimals,
} from '@/utils/formatting/currency';

import { ClientDetailsGiftingPage_HouseholdFragment } from '../graphql/ClientDetailsGiftingPage.generated';

export const ZERO_DECIMAL = new Decimal(0);

interface GetGSTExemptionSectionsInput {
  colors: ReturnType<typeof useChartColorDefinitions>;
  clampNegativeValuesToZero: (input: Decimal) => Decimal;
  gstExemptionAmountForAllGrantors?: Decimal;
  possiblePrimaryClients: ClientDetailsGiftingPage_HouseholdFragment['possiblePrimaryClients'];
  gstExclusionUsedTotal: Decimal;
  groupByGrantor: boolean;
  gstExemptionAmountPerGrantor: Decimal | null;
  grantorIdToExemptionsMap: Record<
    string,
    {
      lifetimeExemptionAmount: Decimal;
      gstExemptionAmount: Decimal;
    }
  >;
}

export function getGSTExemptionSections({
  colors,
  clampNegativeValuesToZero,
  gstExemptionAmountForAllGrantors,
  possiblePrimaryClients,
  gstExclusionUsedTotal,
  groupByGrantor,
  gstExemptionAmountPerGrantor,
  grantorIdToExemptionsMap,
}: GetGSTExemptionSectionsInput) {
  const value = clampNegativeValuesToZero(
    gstExemptionAmountForAllGrantors ?? ZERO_DECIMAL
  );

  const sectionsByGrantor: Section[] = [];
  const sectionForAllGrantors: Section[] = [
    {
      value: value.toNumber(),
      legendPrimaryText: formatCurrency(value, {
        notation: 'compact',
        currencySign: 'accounting',
      }),
      color: getColorForIndex(0, colors),
      label: 'Total remaining GST exemption',
      tooltip: (
        <PopperContent
          sx={{
            width: 'auto',
          }}
          body={
            <MiniTable
              variant="default"
              rows={possiblePrimaryClients.map((client) => {
                // this value is how much GST exemption is left for this grantor
                const lifetimeGSTExemptionLeftForGrantor =
                  gstExemptionAmountPerGrantor?.minus(
                    client.gstExclusionUsed ?? 0
                  ) ?? ZERO_DECIMAL;

                return {
                  label: client.displayName,
                  value: formatCurrencyNoDecimals(
                    lifetimeGSTExemptionLeftForGrantor
                  ),
                };
              })}
            />
          }
        />
      ),
    },
  ];

  possiblePrimaryClients.forEach((client, i) => {
    const color = getColorForIndex(i, colors);

    // this value is how much GST exemption is left for this grantor
    const lifetimeGSTExemptionLeftForGrantor =
      gstExemptionAmountPerGrantor?.minus(client.gstExclusionUsed ?? 0) ??
      ZERO_DECIMAL;

    set(
      grantorIdToExemptionsMap,
      `${client.id}.gstExemptionAmount`,
      clampNegativeValuesToZero(lifetimeGSTExemptionLeftForGrantor)
    );

    const value = clampNegativeValuesToZero(lifetimeGSTExemptionLeftForGrantor);

    sectionsByGrantor.push({
      value: value.toNumber(),
      legendPrimaryText: formatCurrency(value, {
        notation: 'compact',
        currencySign: 'accounting',
      }),
      color,
      label: client.displayName,
      tooltip: (
        <PopperContent
          sx={{
            width: 'auto',
          }}
          body={
            <MiniTable
              variant="default"
              rows={[
                {
                  label: client.displayName,
                  value: formatCurrencyNoDecimals(
                    lifetimeGSTExemptionLeftForGrantor
                  ),
                },
              ]}
            />
          }
        />
      ),
    });
  });

  return [
    ...(groupByGrantor ? sectionsByGrantor : sectionForAllGrantors),
    // include one section that shows the used exemption
    {
      value: clampNegativeValuesToZero(gstExclusionUsedTotal).toNumber(),
      legendPrimaryText: formatCurrency(
        clampNegativeValuesToZero(gstExclusionUsedTotal),
        {
          notation: 'compact',
          currencySign: 'accounting',
        }
      ),
      color: COLORS.GRAY[200],
      backgroundCss: EMPTY_CHART_BACKGROUND_CSS,
      omitFromLegend: true,
      tooltip: (
        <PopperContent
          body={`${formatCurrency(
            clampNegativeValuesToZero(gstExclusionUsedTotal),
            {
              notation: 'compact',
            }
          )} used`}
        />
      ),
    },
  ];
}

interface GetLifetimeExemptionSectionsInput {
  colors: ReturnType<typeof useChartColorDefinitions>;
  clampNegativeValuesToZero: (input: Decimal) => Decimal;
  lifetimeExemptionAmountForAllGrantors?: Decimal;
  possiblePrimaryClients: ClientDetailsGiftingPage_HouseholdFragment['possiblePrimaryClients'];
  lifetimeExclusionUsedTotal: Decimal;
  groupByGrantor: boolean;
  lifetimeExemptionAmountPerGrantor: Decimal | null;
  grantorIdToExemptionsMap: Record<
    string,
    {
      lifetimeExemptionAmount: Decimal;
      gstExemptionAmount: Decimal;
    }
  >;
}

export function getLifetimeExemptionSections({
  colors,
  clampNegativeValuesToZero,
  lifetimeExemptionAmountForAllGrantors,
  possiblePrimaryClients,
  lifetimeExclusionUsedTotal,
  groupByGrantor,
  lifetimeExemptionAmountPerGrantor,
  grantorIdToExemptionsMap,
}: GetLifetimeExemptionSectionsInput) {
  const value = clampNegativeValuesToZero(
    lifetimeExemptionAmountForAllGrantors ?? ZERO_DECIMAL
  );

  const sectionsByGrantor: Section[] = [];
  const sectionForAllGrantors: Section[] = [
    {
      value: value.toNumber(),
      legendPrimaryText: formatCurrency(value, {
        notation: 'compact',
        currencySign: 'accounting',
      }),
      color: getColorForIndex(0, colors),
      label: 'Total remaining lifetime exemption',
      tooltip: (
        <PopperContent
          sx={{
            width: 'auto',
          }}
          body={
            <MiniTable
              variant="default"
              rows={possiblePrimaryClients.map((client) => {
                // this value is how much lifetime exemption is left for this grantor
                const lifetimeExemptionLeftForGrantor =
                  lifetimeExemptionAmountPerGrantor?.minus(
                    client.lifetimeExclusionUsed ?? 0
                  ) ?? ZERO_DECIMAL;

                return {
                  label: client.displayName,
                  value: formatCurrencyNoDecimals(
                    lifetimeExemptionLeftForGrantor
                  ),
                };
              })}
            />
          }
        />
      ),
    },
  ];

  possiblePrimaryClients.forEach((client, i) => {
    const color = getColorForIndex(i, colors);

    // this value is how much lifetime exemption is left for this grantor
    const lifetimeExemptionLeftForGrantor =
      lifetimeExemptionAmountPerGrantor?.minus(
        client.lifetimeExclusionUsed ?? 0
      ) ?? ZERO_DECIMAL;

    const value = clampNegativeValuesToZero(lifetimeExemptionLeftForGrantor);

    set(
      grantorIdToExemptionsMap,
      `${client.id}.lifetimeExemptionAmount`,
      clampNegativeValuesToZero(lifetimeExemptionLeftForGrantor)
    );

    sectionsByGrantor.push({
      value: value.toNumber(),
      legendPrimaryText: formatCurrency(value, {
        notation: 'compact',
        currencySign: 'accounting',
      }),
      color,
      label: client.displayName,
      tooltip: (
        <PopperContent
          sx={{
            width: 'auto',
          }}
          body={
            <MiniTable
              variant="default"
              rows={[
                {
                  label: client.displayName,
                  value: formatCurrencyNoDecimals(
                    lifetimeExemptionLeftForGrantor
                  ),
                },
              ]}
            />
          }
        />
      ),
    });
  });

  return [
    ...(groupByGrantor ? sectionsByGrantor : sectionForAllGrantors),
    // include one section that shows the used exemption
    {
      value: clampNegativeValuesToZero(lifetimeExclusionUsedTotal).toNumber(),
      legendPrimaryText: formatCurrency(
        clampNegativeValuesToZero(lifetimeExclusionUsedTotal),
        {
          notation: 'compact',
          currencySign: 'accounting',
        }
      ),
      color: COLORS.GRAY[200],
      backgroundCss: EMPTY_CHART_BACKGROUND_CSS,
      omitFromLegend: true,
      tooltip: (
        <PopperContent
          body={`${formatCurrency(
            clampNegativeValuesToZero(lifetimeExclusionUsedTotal),
            {
              notation: 'compact',
            }
          )} used`}
        />
      ),
    },
  ];
}

export function getColorForIndex(
  index: number,
  colors: ReturnType<typeof useChartColorDefinitions>
): string {
  // alternate between primary and secondary colors
  const colorsToPickFrom = [
    colors.PRIMARY.backgroundColor,
    colors.SECONDARY.backgroundColor,
  ];

  return colorsToPickFrom[index % colorsToPickFrom.length]!;
}

export function createClampNegativeValuesToZero(returnNegativeValues: boolean) {
  // all of these functions are to bind Decimal/Decimal | undefined types to their outputs,
  // as all cases share the same logic but there are cases where the input is Decimal (and
  // expects Decimal output) or Decimal | undefined (and doesn't care about the output)
  function clampNegativeValuesToZero(input: Decimal): Decimal;
  function clampNegativeValuesToZero(
    input: Decimal | undefined
  ): Decimal | undefined;
  function clampNegativeValuesToZero(input: Decimal | undefined) {
    if (returnNegativeValues || !Decimal.isDecimal(input)) {
      return input;
    }
    return input.lessThanOrEqualTo(ZERO_DECIMAL) ? ZERO_DECIMAL : input;
  }
  return clampNegativeValuesToZero;
}
