// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IlitMakeAnnualExclusionGiftMutationVariables = Types.Exact<{
  firstGrantorLoggedTransfer: Types.AugmentedCreateLoggedTransferInput;
  secondGrantorLoggedTransfer: Types.AugmentedCreateLoggedTransferInput;
  firstGrantorEntity: Types.AugmentedUpdateEntityInput;
  secondGrantorEntity: Types.AugmentedUpdateEntityInput;
  hasSecondGrantor: Types.Scalars['Boolean']['input'];
  hasFirstGrantorUpdatedValuation: Types.Scalars['Boolean']['input'];
  hasSecondGrantorValuation: Types.Scalars['Boolean']['input'];
  taskId: Types.Scalars['ID']['input'];
}>;


export type IlitMakeAnnualExclusionGiftMutation = { __typename?: 'Mutation', firstGrantorTransfer: { __typename?: 'LoggedTransfer', id: string }, secondGrantorTransfer?: { __typename?: 'LoggedTransfer', id: string }, updateFirstGrantorEntity?: { __typename?: 'Entity', id: string }, updateSecondGrantorEntity?: { __typename?: 'Entity', id: string }, completeEntityTask: { __typename?: 'EntityTask', id: string } };


export const IlitMakeAnnualExclusionGiftDocument = gql`
    mutation ILITMakeAnnualExclusionGift($firstGrantorLoggedTransfer: AugmentedCreateLoggedTransferInput!, $secondGrantorLoggedTransfer: AugmentedCreateLoggedTransferInput!, $firstGrantorEntity: AugmentedUpdateEntityInput!, $secondGrantorEntity: AugmentedUpdateEntityInput!, $hasSecondGrantor: Boolean!, $hasFirstGrantorUpdatedValuation: Boolean!, $hasSecondGrantorValuation: Boolean!, $taskId: ID!) {
  firstGrantorTransfer: createLoggedTransfer(input: $firstGrantorLoggedTransfer) {
    id
  }
  secondGrantorTransfer: createLoggedTransfer(input: $secondGrantorLoggedTransfer) @include(if: $hasSecondGrantor) {
    id
  }
  updateFirstGrantorEntity: updateEntity(input: $firstGrantorEntity) @include(if: $hasFirstGrantorUpdatedValuation) {
    id
  }
  updateSecondGrantorEntity: updateEntity(input: $secondGrantorEntity) @include(if: $hasSecondGrantorValuation) {
    id
  }
  completeEntityTask(id: $taskId) {
    id
  }
}
    `;
export type IlitMakeAnnualExclusionGiftMutationFn = Apollo.MutationFunction<IlitMakeAnnualExclusionGiftMutation, IlitMakeAnnualExclusionGiftMutationVariables>;

/**
 * __useIlitMakeAnnualExclusionGiftMutation__
 *
 * To run a mutation, you first call `useIlitMakeAnnualExclusionGiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIlitMakeAnnualExclusionGiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ilitMakeAnnualExclusionGiftMutation, { data, loading, error }] = useIlitMakeAnnualExclusionGiftMutation({
 *   variables: {
 *      firstGrantorLoggedTransfer: // value for 'firstGrantorLoggedTransfer'
 *      secondGrantorLoggedTransfer: // value for 'secondGrantorLoggedTransfer'
 *      firstGrantorEntity: // value for 'firstGrantorEntity'
 *      secondGrantorEntity: // value for 'secondGrantorEntity'
 *      hasSecondGrantor: // value for 'hasSecondGrantor'
 *      hasFirstGrantorUpdatedValuation: // value for 'hasFirstGrantorUpdatedValuation'
 *      hasSecondGrantorValuation: // value for 'hasSecondGrantorValuation'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useIlitMakeAnnualExclusionGiftMutation(baseOptions?: Apollo.MutationHookOptions<IlitMakeAnnualExclusionGiftMutation, IlitMakeAnnualExclusionGiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IlitMakeAnnualExclusionGiftMutation, IlitMakeAnnualExclusionGiftMutationVariables>(IlitMakeAnnualExclusionGiftDocument, options);
      }
export type IlitMakeAnnualExclusionGiftMutationHookResult = ReturnType<typeof useIlitMakeAnnualExclusionGiftMutation>;
export type IlitMakeAnnualExclusionGiftMutationResult = Apollo.MutationResult<IlitMakeAnnualExclusionGiftMutation>;
export type IlitMakeAnnualExclusionGiftMutationOptions = Apollo.BaseMutationOptions<IlitMakeAnnualExclusionGiftMutation, IlitMakeAnnualExclusionGiftMutationVariables>;
export const Operations = {
  Mutation: {
    ILITMakeAnnualExclusionGift: 'ILITMakeAnnualExclusionGift' as const
  }
}