import { Node } from '@/components/diagrams/FlowChart';

import { applyDerivedDataToState } from '../state/applyDerivedDataToState';
import { applySectionBoundsToState } from '../state/applySectionBoundsToState';
import { generateWaterfallGraphState } from '../state/generateDefaultState';
import { EstateWaterfallActionFn, UpdateWaterfallAction } from '../types';

export const updateWaterfallAction: EstateWaterfallActionFn<
  UpdateWaterfallAction
> = (state, action, _queueEffect) => {
  const stateProps = action;
  const { nodes, edges, waterfallGraph } =
    generateWaterfallGraphState(stateProps);

  const { derivedData } = applyDerivedDataToState(state);
  const { nodesById } = derivedData;

  const updatedNodes = nodes.map((n) => {
    const existingNode = nodesById[n.id];
    // Omit position data for new nodes because it will be recalculated
    if (!existingNode) {
      return n;
    }
    // We only update the node data, but leave everything about position, height, width, etc. alone
    // since we've already computed it from when the chart first initialized
    return { ...existingNode, data: { ...n.data } } as Node;
  });

  return applySectionBoundsToState({
    ...state,
    ...stateProps,
    waterfallGraph,
    nodes: updatedNodes,
    edges,
  });
};
