import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { StepTracker } from '@/components/navigation/StepTracker/StepTracker';
import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';
import { GiftDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

interface GiftingDesignerStepTrackerProps {
  activeStepIndex: number;
  householdId: string;
  proposalId: string;
}

export function GiftingDesignerStepTracker({
  activeStepIndex,
  householdId,
  proposalId,
}: GiftingDesignerStepTrackerProps) {
  const navigate = useNavigate();

  return (
    <Box width="25%" maxWidth={480} minWidth={360}>
      <StepTracker
        segments={[
          {
            display: 'Basic information',
            onClick: () => {
              navigate(
                getCompletePathFromRouteKey(
                  ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER_BASIC_INFORMATION,
                  {
                    householdId,
                    proposalId,
                    designerStage: GiftDesignerStages.BASIC_INFORMATION,
                  }
                )
              );
            },
            isComplete: activeStepIndex >= 0,
          },
          {
            display: 'Model scenarios',
            onClick: () => {
              if (proposalId === NEW_PROPOSAL_ID) {
                return;
              }

              navigate(
                getCompletePathFromRouteKey(
                  ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER_MODEL_SCENARIOS,
                  {
                    householdId,
                    proposalId,
                    designerStage: GiftDesignerStages.MODEL_SCENARIOS,
                  }
                )
              );
            },
            isComplete: activeStepIndex >= 1,
          },
        ]}
        activeSegmentIndex={activeStepIndex}
      />
    </Box>
  );
}
