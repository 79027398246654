import { DocumentNode } from 'graphql';

import { CreateClientOrganizationModal } from './CreateClientOrganizationModal';
import { EditClientOrganizationModal } from './EditClientOrganizationModal';
import { GetClientOrganizationDocument } from './graphql/ClientOrganizationModal.generated';

export interface ClientOrganizationModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  organizationId: string | null;
  householdId: string;
  requirement?: 'mustBeTrustee' | 'mustBeBeneficiary';
  // callback to run after creating a new client organization that passes back the
  // new client organization id
  onAfterCreate?: (newId: string) => void;
  // initially populate the organization name field with this value
  initialOrganizationName?: string;
  // after a mutation, the modal will trigger a refetch of the active queries.
  // pass any queries you want to ignore from the refetch here.
  ignoredQueryDocuments?: DocumentNode[];
}

export function ClientOrganizationModal({
  isOpen,
  setIsOpen,
  organizationId,
  householdId,
  requirement,
  onAfterCreate,
  initialOrganizationName,
  ignoredQueryDocuments,
}: ClientOrganizationModalProps) {
  if (organizationId) {
    return (
      <EditClientOrganizationModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        organizationId={organizationId}
        requirement={requirement}
        ignoredQueryDocuments={[
          ...(ignoredQueryDocuments ?? []),
          GetClientOrganizationDocument,
        ]}
      />
    );
  }

  return (
    <CreateClientOrganizationModal
      isOpen={isOpen}
      requirement={requirement}
      onClose={() => setIsOpen(false)}
      householdId={householdId}
      onAfterCreate={onAfterCreate}
      initialOrganizationName={initialOrganizationName}
    />
  );
}
