export const REACT_FLOW_CLASSES = {
  ROOT: 'react-flow',
  CANVAS: 'react-flow__canvas',
  NODE: 'react-flow__node',
  VIEWPORT: 'react-flow__viewport',
  EDGE_LABEL: 'react-flow__edgelabel-renderer',
  PANE: 'react-flow__pane',
  EDGE: 'react-flow__edge',
} as const;

// These are semi magic values, some are pulled from the DOM
// others are hardcoded into the source code of react-flow
// Change them if they no longer work!
export const REACT_FLOW_ZINDEX = {
  NODE_BELOW_OVERLAY: -1,
  DEFAULT_NODE: 0,
  NODE_ABOVE_OVERLAY: 1,
  HELPER_LINES_CANVAS: 10,
  DRAGGING_NODE: 1000,
  EDGE_LABEL_RENDERER: 1001,
};
