import {
  GridComparatorFn,
  gridDateComparator,
  GridSortCellParams,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid-pro';

import * as diagnostics from '@/utils/diagnostics';

import { CellFormatType } from './tableTypes';

export const textFieldComparator: GridComparatorFn<{ value: string }> = (
  value1,
  value2,
  param1,
  param2
) => {
  const sortKey = 'value' as const;

  if (Object.hasOwn(value1, sortKey) && Object.hasOwn(value2, sortKey)) {
    // if both look like a date, then sort by date
    if (
      !isNaN(Date.parse(value1[sortKey])) &&
      !isNaN(Date.parse(value2[sortKey]))
    ) {
      return gridDateComparator(
        value1[sortKey],
        value2[sortKey],
        param1,
        param2
      );
    }

    return gridStringOrNumberComparator(
      value1[sortKey],
      value2[sortKey],
      param1,
      param2
    );
  }

  return gridStringOrNumberComparator(value1, value2, param1, param2);
};

export const taskDetailsSummaryFieldComparator: GridComparatorFn<{
  display: string;
}> = (value1, value2, param1, param2) => {
  const sortKey = 'display' as const;

  if (Object.hasOwn(value1, sortKey) && Object.hasOwn(value2, sortKey)) {
    return gridStringOrNumberComparator(
      value1[sortKey],
      value2[sortKey],
      param1,
      param2
    );
  }

  return gridStringOrNumberComparator(value1, value2, param1, param2);
};

export const taskDueDateFieldComparator: GridComparatorFn = (
  value1,
  value2,
  param1,
  param2
) => {
  return gridDateComparator(value1, value2, param1, param2);
};

export const lastActivityFieldComparator: GridComparatorFn = (
  value1,
  value2,
  param1,
  param2
) => {
  return gridDateComparator(value1, value2, param1, param2);
};

export const defaultFieldComparator = (
  value1: { value: string },
  value2: { value: string },
  param1: GridSortCellParams<{ value: string }>,
  param2: GridSortCellParams<{ value: string }>,
  cellFormat: CellFormatType
): number => {
  if (typeof value1 === 'object' && typeof value2 === 'object') {
    diagnostics.warn(
      'Attempting to sort unknown value, you may want to define a sort function for this cell format',
      {
        cellFormat,
      }
    );
    return textFieldComparator(value1, value2, param1, param2);
  }

  return gridStringOrNumberComparator(value1, value2, param1, param2);
};
