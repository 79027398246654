import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { RichListItemProps } from '@/components/lists/RichListItem/RichListItem';
import { TableCellContextProps } from '@/components/lists/TableCell';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { CLIENT_OWNERSHIP_DESCRIPTION } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.constants';
import { assertNonNil } from '@/utils/assertUtils';
import { formatEnumCase } from '@/utils/formatting/strings';

import { useEstateTaxScenariosContext } from '../../contexts/estateTaxScenarios.context';

export interface SortableListItem extends RichListItemProps {
  id: string;
  context?: TableCellContextProps;
}

interface UseDraggablePayoutOrderItemsProps {
  payoutOrder: string[];
  missingEntities: string[];
}

export function useDraggablePayoutOrderItems({
  payoutOrder,
  missingEntities,
}: UseDraggablePayoutOrderItemsProps): SortableListItem[] {
  const { primaryClients, entities, testamentaryEntities } =
    useEstateTaxScenariosContext();

  return useMemo(() => {
    const missingEntitiesIds = new Set(missingEntities);
    const allPossibleEntitiesById = keyBy(
      [...entities, ...primaryClients, ...testamentaryEntities],
      (v) => v.id
    );

    return payoutOrder.map((id): SortableListItem => {
      const entity = assertNonNil(
        allPossibleEntitiesById[id],
        `Could not find entity for ${id} in payout order`
      );
      const NewEntityBadge = missingEntitiesIds.has(entity.id) ? (
        <Badge display="New entity" variant={BadgeVariants.Teal} />
      ) : undefined;

      if (entity.__typename === 'Entity') {
        return {
          id: entity.id,
          heading: entity.subtype.displayName,
          description: entity.extendedDisplayKind,
          badge: NewEntityBadge,
        };
      }

      if (entity.__typename === 'TestamentaryEntity') {
        return {
          id: entity.id,
          heading: entity.displayName,
          description: formatEnumCase(entity.kind),
          badge: (
            <>
              {NewEntityBadge}
              <Badge display="Testamentary" variant={BadgeVariants.Yellow} />
            </>
          ),
        };
      }

      if (entity.__typename === 'ClientProfile') {
        return {
          id: entity.id,
          heading: entity.displayName,
          description: CLIENT_OWNERSHIP_DESCRIPTION,
          badge: NewEntityBadge,
        };
      }

      throw new Error(
        `Unhandled typename in calculation of payout order: ${entity.__typename}`
      );
    });
  }, [
    missingEntities,
    entities,
    primaryClients,
    testamentaryEntities,
    payoutOrder,
  ]);
}
