import {
  getCumulativeGratPerformance,
  GratPerformance,
} from './getPerformanceForGrat';
import { SumTable } from './SumTable';

export function GratPerformanceSums({
  grats,
  variant = 'narrow',
}: {
  grats: GratPerformance[] | null;
  variant: 'narrow' | 'wide';
}) {
  const {
    displayImpliedSurplus,
    displayTaxFreeTransfer,
    displayImpliedEstateTaxSavings,
    taxableGift,
  } = getCumulativeGratPerformance(grats ? grats : []);

  if (!grats) {
    return null;
  }

  const noGrats = grats.length === 0;

  return (
    <SumTable
      variant={variant}
      noGrats={noGrats}
      displayImpliedSurplus={displayImpliedSurplus}
      displayTaxFreeTransfer={displayTaxFreeTransfer}
      displayImpliedEstateTaxSavings={displayImpliedEstateTaxSavings}
      taxableGift={taxableGift}
    />
  );
}
