import { Stack } from '@mui/material';
import { isEmpty, pick } from 'lodash';
import { useState } from 'react';

import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { FormAwareCard } from '@/components/form/formAwareInputs/FormAwareCard';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { getNewFileUploadFromUploadResult } from '@/modules/documents/documentUploaderUtils';
import { FileRepresentation } from '@/modules/files/FileRepresentation/FileRepresentation';
import {
  FileUploader,
  NewFileUpload,
} from '@/modules/files/FileUploader/FileUploader';
import { acceptedFileTypes } from '@/modules/files/FileUploader/fileUploaderUtils';
import { CreateFileMutation } from '@/modules/files/FileUploader/graphql/CreateFile.generated';
import { useGenerateSignedCSVFileUploadURL } from '@/modules/files/FileUploader/useGenerateSignedCSVFileUploadURL';
import { UploadedFileData } from '@/modules/files/FileUploader/useUploadFile';
import { pluralize } from '@/utils/formatting/strings';

import {
  CSVUploadFormPaths,
  CSVUploadFormShape,
} from '../ImportCSVValuationsPage/ImportCSVValuationsPage.types';
import { useUpdateHouseholdAccountsMappingMutation } from './graphql/ClientEntityCSVUploadDownload.generated';

const csvUploadFileTypes = pick(acceptedFileTypes, ['application/csv']);

interface ClientEntityCSVUploadProps {
  onUploadComplete: () => void;
}

export function ClientEntityCSVUpload({
  onUploadComplete,
}: ClientEntityCSVUploadProps) {
  const { setValue, control } = useFormContext<CSVUploadFormShape>();
  const { showFeedback } = useFeedback();
  const [pendingFile, setPendingFile] = useState<null | Omit<
    NewFileUpload,
    'householdId'
  >>(null);

  function clearFileState() {
    setPendingFile(null);
    setValue('fileId', null);
  }

  useSubmitSuccessHandler(() => {
    clearFileState();
  });

  const { generateUploadURL } = useGenerateSignedCSVFileUploadURL();
  const [updateMappingFromFile] = useUpdateHouseholdAccountsMappingMutation({
    onCompleted: (res) => {
      const updatedMutations =
        res.importEntityAccountAssociations?.numEntitiesUpdated ?? 0;
      const noun = pluralize(updatedMutations, 'entity', 'entities');
      showFeedback(`Successfully updated ${updatedMutations} ${noun}`, {
        variant: 'success',
      });
      onUploadComplete();
    },
    onError: (error) => {
      clearFileState();
      showFeedback(`Failed to parse the CSV file: ${error.message}`);
    },
  });

  function handlePendingFile(uploaded: UploadedFileData<CreateFileMutation>[]) {
    if (isEmpty(uploaded)) {
      return;
    }

    // We're only handling one file at a time for now.
    const { file, result } = uploaded[0]!;

    const pendingFile = getNewFileUploadFromUploadResult(file, result?.data);
    if (pendingFile === null) {
      clearFileState();
      return;
    }

    setPendingFile({ ...pendingFile });
    setValue('fileId', pendingFile.fileId);
    void updateMappingFromFile({
      variables: {
        fileId: pendingFile.fileId,
      },
    });
  }

  return (
    <Stack spacing={2}>
      {pendingFile ? (
        <FileRepresentation
          fileName={pendingFile.fileName}
          uploadedAt={pendingFile.uploadedAt}
          uploadedBy={pendingFile.uploadedByName}
          actions={
            <IconButton
              size="sm"
              variant="transparent"
              ariaLabel="Remove file"
              onClick={() => setPendingFile(null)}
              icon={Trash01Icon}
              iconProps={{ color: 'error' }}
            />
          }
        />
      ) : (
        <FormAwareCard
          variant="transparent"
          control={control}
          fieldName={`fileId` as const satisfies CSVUploadFormPaths}
        >
          <FileUploader
            onUploadComplete={handlePendingFile}
            generateSignedUploadURL={generateUploadURL}
            acceptTypes={csvUploadFileTypes}
          />
        </FormAwareCard>
      )}
    </Stack>
  );
}
