import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { Loader } from '@/components/progress/Loader/Loader';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { useMostRecentlyViewedEntityMap } from '@/modules/user/hooks/useMostRecentlyViewedWaterfall';
import { ROUTE_KEYS } from '@/navigation/constants';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { useClientDetailsEntityMapQuery } from './graphql/ClientDetailsEntityMap.generated';

function ClientDetailsEntityMapBasePagePlaceholder() {
  const householdId = useRequiredParam('householdId');
  const { showFeedback } = useFeedback();
  const { navigate } = useNavigateToRoute();

  const { mostRecentlyEntityMapId } =
    useMostRecentlyViewedEntityMap(householdId);

  useClientDetailsEntityMapQuery({
    variables: { householdId },
    onCompleted: (data) => {
      const household = getNodes(data.households)[0];
      if (!household?.entityGraphViews?.length) {
        showFeedback(FeedbackMessages.queryError);
        diagnostics.error(
          `could not find matching entity graph config id for ${householdId}`
        );
        return;
      }

      const firstEntityGraphViewId = household.entityGraphViews?.[0]?.id;
      const allEntityGraphViewIds = new Set(
        household.entityGraphViews.map((v) => v.id)
      );

      const id = allEntityGraphViewIds.has(mostRecentlyEntityMapId!)
        ? mostRecentlyEntityMapId!
        : firstEntityGraphViewId!;

      navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITY_MAP_DIAGRAM, {
        householdId,
        id,
      });
    },
    onError: (error) => {
      showFeedback(FeedbackMessages.queryError);
      diagnostics.error('entity map client details query error', error);
    },
  });

  return <Loader />;
}

export function ClientDetailsEntityMapBasePage() {
  const { id } = useParams();

  if (id) {
    return <Outlet />;
  }

  return <ClientDetailsEntityMapBasePagePlaceholder />;
}
