import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { XCircleIcon } from '@/components/icons/XCircleIcon';
import { COLORS } from '@/styles/tokens/colors';

export type ClearInputTextButtonProps = Partial<ButtonProps>;
export const ClearInputTextButton = ({
  size = 'sm',
  variant = 'transparent',
  sx = {},
  ...props
}: ClearInputTextButtonProps) => {
  return (
    <Button
      className="clear-button"
      variant={variant}
      size={size}
      sx={{
        padding: 0,
        margin: 0,
        // disable default hover styling
        ':hover': {
          background: 'transparent',
          borderColor: 'transparent',
        },
        ...sx,
      }}
      {...props}
    >
      <XCircleIcon
        size={16}
        sx={{
          display: 'flex',
          color: COLORS.GRAY[400],
          '.clear-button:hover &': {
            color: COLORS.GRAY[600],
          },
        }}
      />
    </Button>
  );
};
