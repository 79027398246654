import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';

export interface UnorderedListItemProps {
  emphasizedPart?: React.ReactNode;
  children: React.ReactNode;
}

// trimming the `#` and replacing it with `%23` is necessary because the string needs to be HTTP encoded for the `url()` css function
const ORANGE = `%23${COLORS.ORANGE[400].substring(1)}`;

const styles = makeStyles()((_theme) => ({
  root: {
    margin: 0,
    padding: 0,
    paddingLeft: '10px',
    listStyleImage: `url('data:image/svg+xml,<svg transform-origin="center" width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="8" height="8" rx="2" fill="${ORANGE}"/></svg>');`,
    paddingBottom: '16px',

    '&::marker': {
      fontSize: '1.5em',
      lineHeight: '0.1em',
    },
  },
}));

export function UnorderedListItem({
  emphasizedPart,
  children,
}: UnorderedListItemProps) {
  const { classes } = styles();
  return (
    <li className={classes.root}>
      {emphasizedPart && (
        <Typography
          variant="body1"
          sx={{ fontWeight: 'bold' }}
          component="span"
        >
          {emphasizedPart}&nbsp;
        </Typography>
      )}
      {children}
    </li>
  );
}
