import { ReactNode } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  DeleteMenuItemWithConfirmationModal,
  DeleteMenuItemWithConfirmationModalProps,
} from '@/components/poppers/MenuPopper/DeleteMenuItemWithConfirmationModal';
import { useReportError } from '@/hooks/useReportError';

import { ProposalKind } from '../../types/ProposalKind';
import { useDeleteProposalMutation } from './graphql/DeleteProposal.generated';

interface DeleteProposalMenuItemProps
  extends Partial<DeleteMenuItemWithConfirmationModalProps> {
  proposalId: string;
  onDelete?: () => void;
  kind?: ProposalKind;
}

export function DeleteProposalMenuItem({
  proposalId,
  onDelete,
  modalProps = {},
  menuItemProps = {},
  kind,
  label: externalLabel,
  ...deleteProps
}: DeleteProposalMenuItemProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [deleteProposal, { loading: deleting }] = useDeleteProposalMutation({
    variables: { proposalId },
    onError: (error) => {
      reportError(`failed to delete proposal`, error);
      showFeedback(
        'Failed to delete this proposal. Please refresh the page and try again.'
      );
    },
    onCompleted: () => {
      showFeedback('Proposal deleted successfully', { variant: 'success' });
      onDelete?.();
    },
  });

  let label: ReactNode = 'Delete';
  if (externalLabel) {
    label = externalLabel;
  } else if (kind) {
    if ([ProposalKind.Entity].includes(kind)) {
      label = 'Delete proposal';
    } else {
      label = 'Delete analysis';
    }
  }

  return (
    <DeleteMenuItemWithConfirmationModal
      label={label}
      onConfirmDelete={() => deleteProposal()}
      {...deleteProps}
      menuItemProps={{ loading: deleting, ...menuItemProps }}
      modalProps={{
        heading: 'Are you sure you want to delete this proposal?',
        ...modalProps,
      }}
    />
  );
}
