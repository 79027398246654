import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { FileQuestion03Icon } from '@/components/icons/FileQuestion03Icon';
import { COLORS } from '@/styles/tokens/colors';

export interface NotFoundErrorProps {
  title: string;
  message: string;
  buttonText: string;
  returnPath: string;
  sx?: SxProps<Theme>;
}

export function NotFoundError({
  title,
  message,
  buttonText,
  returnPath,
  sx = {},
}: NotFoundErrorProps) {
  const navigate = useNavigate();

  const handleReturn = () => {
    navigate(returnPath);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        ...sx,
      }}
    >
      <Stack spacing={1} alignItems="center">
        <FileQuestion03Icon size={40} color={COLORS.GRAY[300]} />
        <Typography variant="h1">{title}</Typography>
        <Typography variant="body1">{message}</Typography>
        <Box pt={2}>
          <Button size="sm" variant="primary" onClick={handleReturn}>
            {buttonText}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
}
