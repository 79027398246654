import { noop } from 'lodash';
import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { DispositiveProvisionTemplateDetails } from './DispositiveProvisionsTemplateSplitScreenModal.types';

export interface DispositiveProvisionsTemplateSplitScreenModalContextShape {
  isTemplateMode: boolean;

  dispositiveProvisionTemplateDetails: DispositiveProvisionTemplateDetails | null;
  setDispositiveProvisionTemplateDetails: (
    details: DispositiveProvisionTemplateDetails | null
  ) => void;
}

export const DispositiveProvisionsTemplateSplitScreenModalContext =
  createContext<DispositiveProvisionsTemplateSplitScreenModalContextShape>({
    isTemplateMode: false,
    dispositiveProvisionTemplateDetails: null,
    setDispositiveProvisionTemplateDetails: noop,
  });

export interface DispositiveProvisionsTemplateSplitScreenModalContextProviderProps
  extends PropsWithChildren {
  isTemplateMode: boolean;
}

export function DispositiveProvisionsTemplateSplitScreenModalContextProvider({
  children,
  isTemplateMode,
}: DispositiveProvisionsTemplateSplitScreenModalContextProviderProps) {
  const [
    dispositiveProvisionTemplateDetails,
    setDispositiveProvisionTemplateDetails,
  ] = useState<DispositiveProvisionTemplateDetails | null>(null);

  return (
    <DispositiveProvisionsTemplateSplitScreenModalContext.Provider
      value={{
        isTemplateMode,
        dispositiveProvisionTemplateDetails,
        setDispositiveProvisionTemplateDetails,
      }}
    >
      {children}
    </DispositiveProvisionsTemplateSplitScreenModalContext.Provider>
  );
}

export function useDispositiveProvisionsTemplateSplitScreenModalContext() {
  const context = useContext(
    DispositiveProvisionsTemplateSplitScreenModalContext
  );

  return (
    context ?? {
      isTemplateMode: false,
      dispositiveProvisionTemplateDetails: null,
      setDispositiveProvisionTemplateDetails: noop,
    }
  );
}
