import { useMemo } from 'react';

import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';

import { useBeneficiaryReportingContext } from '../../beneficiaryReporting.context';
import {
  BeneficiaryReport,
  ClientOrOrgId,
} from '../../beneficiaryReporting.types';
import { getBenefitsOverviewMetrics } from '../BenefitsOverviewMetrics.utils';

export function useBenefitsOverviewData() {
  const { possibleBeneficiaries } = useHouseholdDetailsContext();
  const { beneficiariesData, firstGrantor, secondGrantor } =
    useBeneficiaryReportingContext();

  const metrics = useMemo(() => {
    if (!possibleBeneficiaries) {
      return {
        allAccessValueForSecondGrantor: undefined,
        allAccessValueForIndividuals: undefined,
        allAccessValueForOrganizations: undefined,
      };
    }

    const beneficiaryDataByAfterDeath = Object.entries(beneficiariesData) as [
      AfterDeath,
      Record<ClientOrOrgId, BeneficiaryReport>,
    ][];

    return getBenefitsOverviewMetrics({
      beneficiaryDataByAfterDeath,
      secondGrantor,
      possibleBeneficiaries,
    });
  }, [beneficiariesData, secondGrantor, possibleBeneficiaries]);

  return {
    firstGrantor,
    secondGrantor,
    metrics,
  };
}
