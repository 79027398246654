import { PropsWithChildren, useEffect, useState } from 'react';

import { DocumentSearchResult } from '@/modules/pdf/DocumentSearch/DocumentSearch.types';

import { AnnotatedPDFViewerContext } from './AnnotatedPDFViewer.context';

function useAnnotatedPDFViewerContextValue({
  documentId,
  overrideAnnotations,
  overrideSearchQuery,
  onUpdateSearchQuery,
  overrideSearchLoading,
}: AnnotatedPDFViewerProviderProps) {
  const [annotations, setAnnotations] = useState<
    DocumentSearchResult[] | undefined
  >(overrideAnnotations);

  const [activeAnnotationIndex, setActiveAnnotationIndex] = useState<number>(0);

  useEffect(() => {
    setAnnotations(overrideAnnotations);
    setActiveAnnotationIndex(0);
  }, [overrideAnnotations]);

  return {
    documentId,
    annotations,
    setAnnotations,
    activeAnnotationIndex,
    setActiveAnnotationIndex,
    overrideSearchQuery,
    onUpdateSearchQuery,
    overrideSearchLoading,
  };
}

export interface AnnotatedPDFViewerProviderProps {
  documentId: string | null;
  overrideAnnotations?: DocumentSearchResult[];
  overrideSearchQuery?: string;
  onUpdateSearchQuery?: () => void;
  overrideSearchLoading?: boolean;
}

export const AnnotatedPDFViewerProvider = ({
  children,
  ...props
}: PropsWithChildren<AnnotatedPDFViewerProviderProps>) => {
  const value = useAnnotatedPDFViewerContextValue(props);

  return (
    <AnnotatedPDFViewerContext.Provider value={value}>
      {children}
    </AnnotatedPDFViewerContext.Provider>
  );
};
