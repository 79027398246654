import { cx } from '@emotion/css';
import { ComponentType, ReactNode, useEffect, useState } from 'react';

import {
  Wiggle,
  WiggleProps,
  WiggleStatus,
} from '@/components/animation/Wiggle/Wiggle';
import { IconProps } from '@/components/icons/CheckDone01Icon';
import { BadgeProps } from '@/components/notifications/Badge/Badge';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { WithClasses } from '@/styles/types';

import { useStyles, useTileClasses } from './styles';
import { TileCard } from './TileCard';
import { TileFrame } from './TileFrame';
import { TileVariant } from './types';

export interface TileProps {
  icon?: ComponentType<IconProps>;
  lineOne: string;
  lineTwo?: string;
  lineThree?: string;
  frameText?: string;
  variant?: TileVariant;
  isGrouped?: boolean;
  isSelected?: boolean;
  children?: ReactNode;
  classes?: WithClasses<typeof useStyles>;
  wiggleClasses?: WiggleProps['classes'];
  badgeProps?: Partial<BadgeProps>;
  dragging?: boolean;
  hover?: boolean;
  isNewTile?: boolean;
  slots?: {
    EditButton?: ReactNode;
  };
}

export function Tile(props: TileProps) {
  const viewOnly = useViewOnly();
  const {
    variant = TileVariant.Primary,
    frameText,
    isNewTile,
    wiggleClasses,
    ...otherProps
  } = props;

  const hasFrame = Boolean(frameText);

  const tileClasses = useTileClasses({
    variant,
    viewOnly,
    ...otherProps,
  });
  const { classes, variantClasses } = tileClasses;

  const tileCardProps = {
    ...props,
    ...tileClasses,
  };

  const [wiggleStatus, setWiggleStatus] = useState<WiggleStatus>(
    WiggleStatus.None
  );

  useEffect(() => {
    if (isNewTile) {
      setWiggleStatus(WiggleStatus.Wiggle);
    } else {
      setWiggleStatus(WiggleStatus.None);
    }
  }, [isNewTile]);

  return (
    <div className={cx(variantClasses[variant].wrapper, classes.wrapper)}>
      <Wiggle
        wiggleStatus={wiggleStatus}
        animation={{
          numCycles: 5,
        }}
        classes={wiggleClasses}
      >
        {hasFrame ? (
          <TileFrame frameText={frameText}>
            <TileCard {...tileCardProps} />
          </TileFrame>
        ) : (
          <TileCard {...tileCardProps} />
        )}
      </Wiggle>
    </div>
  );
}
