import { ReactFlowState } from '@xyflow/react';

import { useGetValue } from './internal/useGetValue';

const transformSelector = (state: ReactFlowState) => state.transform;

export function useViewportTransform() {
  const { value: transform, get: getTransform } =
    useGetValue(transformSelector);

  return { transform, getTransform };
}
