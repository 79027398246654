import { noop } from 'lodash';
import { createContext, Dispatch } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';

import { EstateWaterfallFragment } from '../graphql/EstateWaterfall.generated';
import { EstateWaterfallGraph } from '../types';
import { EstateWaterfallAction } from '../types/actions';
import {
  EstateWaterfallDerivedData,
  EstateWaterfallState,
} from '../types/state';

export interface EstateWaterfallContext {
  dispatch: Dispatch<EstateWaterfallAction>;
  state: EstateWaterfallState;
  getState: () => EstateWaterfallState;
}

const defaultDerivedData: EstateWaterfallDerivedData = {
  isHypothetical: false,
  firstPrimaryClientDeathId: '',
  nodesById: {},
  nodesByType: { default: [], sectionLabel: [], tile: [] },
  nodesBySectionId: {},
  edgesById: {},
  isSummaryPanelOpen: false,
  summaryNode: null,
};

const defaultState: EstateWaterfallState = {
  isInitializing: true,
  waterfall: null as unknown as EstateWaterfallFragment,
  waterfallGraph: null as unknown as EstateWaterfallGraph,
  primaryClients: null as unknown as ContextPrimaryClient[],
  isTwoClientHousehold: false,
  nodes: [],
  edges: [],
  derivedData: { ...defaultDerivedData },
  summaryNodeId: null,
  highlightedIds: new Set(),
  undoGroupingState: null,
  isExporting: false,
  isFilteredWaterfall: false,
  isEmptyWaterfall: false,
  visibleNodeIds: [],
  hiddenNodeIds: [],
  initWaterfallNodeIds: new Set(),
  presentationMode: false,
};

export const DEFAULT_ESTATE_WATERFALL_STATE: Readonly<EstateWaterfallState> =
  Object.freeze(defaultState);

export const EstateWaterfallContext = createContext<EstateWaterfallContext>({
  dispatch: noop,
  state: { ...DEFAULT_ESTATE_WATERFALL_STATE },
  getState: () => ({ ...DEFAULT_ESTATE_WATERFALL_STATE }),
});

export const useEstateWaterfallContext = () => {
  return useGuardedContext(EstateWaterfallContext, 'EstateWaterfallProvider');
};
