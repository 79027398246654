import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { PageHeader } from '@/components/navigation/PageHeader';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { ROUTE_KEYS } from '@/navigation/constants';
import { diagnostics } from '@/utils/diagnostics';

import { useAuthorizedSettingsPages } from './AdminUsersPage/hooks/useAuthorizedAdminPages';

export function AdminPage() {
  const { navigate } = useNavigateToRoute();
  const tabs = useAuthorizedSettingsPages();

  useEffect(() => {
    if (tabs.length > 0) return;
    diagnostics.info('No authorized admin pages found; redirecting');
    return navigate(ROUTE_KEYS.HOUSEHOLDS_LIST, {});
  }, [navigate, tabs.length]);

  return (
    <>
      <PageHeader heading="Settings" tabs={tabs} />
      <Stack
        spacing={1}
        p={3}
        pb={4}
        // set position to relative so this is the canonical offset parent of the outlet children
        sx={{
          width: '100%',
          position: 'relative',
        }}
      >
        <Outlet />
      </Stack>
    </>
  );
}
