import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';

/**
 * @description Get the key for the coachmark in localStorage
 */
export function getStorageKey(id: string): string {
  const baseKey = `${LOCAL_STORAGE_KEYS.COACHMARK_SEEN_PREFIX}${id}`;
  return baseKey;
}

/**
 * @description Get the count of how many times a coachmark has been seen
 */
export function getCoachmarkCount(id: string): number {
  const key = getStorageKey(id);
  return parseInt(localStorage.getItem(key) || '0', 10);
}

/**
 * @description Increment the count of how many times a coachmark has been seen
 */
export function incrementCoachmarkCount(id: string): void {
  const key = getStorageKey(id);
  const count = getCoachmarkCount(id);
  localStorage.setItem(key, (count + 1).toString());
}

/**
 * @description Determine if a coachmark should be shown based on the number of times it has been seen
 */
export function shouldShowCoachmark(id: string, maxInstances: number): boolean {
  return getCoachmarkCount(id) <= maxInstances;
}
