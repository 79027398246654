import { createContext, ReactElement } from 'react';

export interface ClientProfileModalContextShape {
  householdId: string | null;
  clientProfileId: string | null;
  isPrimary: boolean;
  isCreate: boolean;
}

export const EMPTY_CLIENT_PROFILE_MODAL_CONTEXT = {
  householdId: null,
  clientProfileId: null,
  isPrimary: false,
  isCreate: false,
};

export const ClientProfileModalContext =
  createContext<ClientProfileModalContextShape>(
    EMPTY_CLIENT_PROFILE_MODAL_CONTEXT
  );

export interface ClientProfileModalContextProviderProps
  extends Omit<ClientProfileModalContextShape, 'isCreate'> {
  children: ReactElement;
}

export function ClientProfileModalContextProvider({
  children,
  ...props
}: ClientProfileModalContextProviderProps) {
  return (
    <ClientProfileModalContext.Provider
      value={{ ...props, isCreate: !props.clientProfileId }}
    >
      {children}
    </ClientProfileModalContext.Provider>
  );
}
