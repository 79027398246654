import React from 'react';

import { PopperContent } from '@/components/poppers/PopperContent';

export function EstateWaterfallExemptionGrowthRate() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body="Projected annual rate of growth for the estate tax exemption"
    />
  );
}
