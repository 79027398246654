import { PopperContent } from '@/components/poppers/PopperContent';

export function GiftPreTaxGrowthRate() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body={`Represents the pre-tax annual projected rate of return.`}
    />
  );
}
