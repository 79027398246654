import { Stack } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';

import { Card } from '@/components/layout/Card/Card';
import { EstateTaxSummaryPanel_TaxSummaryFragment } from '@/modules/summaryPanels/EstateTaxSummaryPanel/graphql/EstateTaxSummaryPanel.generated';
import { FederalTaxMiniTable } from '@/modules/taxes/components/FederalTaxMiniTable/FederalTaxMiniTable';
import { StateTaxMiniTable } from '@/modules/taxes/components/StateTaxMiniTable/StateTaxMiniTable';

interface EstateTaxTotalsCardProps {
  taxSummary: Maybe<EstateTaxSummaryPanel_TaxSummaryFragment>;
}

export const EstateTaxTotalsCard = ({
  taxSummary,
}: EstateTaxTotalsCardProps) => {
  return (
    <Card variant="filled" sx={{ borderRadius: '6px', padding: 2 }}>
      <Stack spacing={4}>
        {taxSummary?.stateTax && taxSummary.stateTax.length > 0 && (
          <>
            {taxSummary.stateTax.map((stateTax) => (
              <StateTaxMiniTable key={stateTax._cacheKey} stateTax={stateTax} />
            ))}
          </>
        )}
        {taxSummary?.federalTax && (
          <FederalTaxMiniTable federalTax={taxSummary.federalTax} />
        )}
      </Stack>
    </Card>
  );
};
