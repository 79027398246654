// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetEntityDetails_EntityFragment = { __typename?: 'Entity', id: string, subtype: { __typename?: 'CCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'CLTTrust', id: string, displayName: string } | { __typename?: 'CRTTrust', id: string, displayName: string } | { __typename?: 'CustodialPersonalAccount', id: string, displayName: string } | { __typename?: 'DonorAdvisedFund', id: string, displayName: string } | { __typename?: 'GPBusinessEntity', id: string, displayName: string } | { __typename?: 'GRATTrust', id: string, displayName: string } | { __typename?: 'ILITTrust', id: string, displayName: string } | { __typename?: 'IndividualPersonalAccount', id: string, displayName: string } | { __typename?: 'InsurancePersonalAccount', id: string, displayName: string } | { __typename?: 'IrrevocableTrust', id: string, displayName: string } | { __typename?: 'JointPersonalAccount', id: string, displayName: string } | { __typename?: 'LLCBusinessEntity', id: string, displayName: string } | { __typename?: 'LPBusinessEntity', id: string, displayName: string } | { __typename?: 'PrivateFoundation', id: string, displayName: string } | { __typename?: 'QPRTTrust', id: string, displayName: string } | { __typename?: 'QualifiedTuitionPersonalAccount', id: string, displayName: string } | { __typename?: 'RetirementPersonalAccount', id: string, displayName: string } | { __typename?: 'RevocableTrust', id: string, displayName: string } | { __typename?: 'SCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'SLATTrust', id: string, displayName: string } | { __typename?: 'SoleProprietorshipBusinessEntity', id: string, displayName: string }, documents: { __typename?: 'DocumentConnection', edges?: Array<{ __typename?: 'DocumentEdge', node?: { __typename?: 'Document', id: string } | null } | null> | null } };

export type GetEntityDetailsQueryVariables = Types.Exact<{
  entityId: Types.Scalars['ID']['input'];
}>;


export type GetEntityDetailsQuery = { __typename?: 'Query', entities: { __typename?: 'EntityConnection', edges?: Array<{ __typename?: 'EntityEdge', node?: { __typename?: 'Entity', id: string, subtype: { __typename?: 'CCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'CLTTrust', id: string, displayName: string } | { __typename?: 'CRTTrust', id: string, displayName: string } | { __typename?: 'CustodialPersonalAccount', id: string, displayName: string } | { __typename?: 'DonorAdvisedFund', id: string, displayName: string } | { __typename?: 'GPBusinessEntity', id: string, displayName: string } | { __typename?: 'GRATTrust', id: string, displayName: string } | { __typename?: 'ILITTrust', id: string, displayName: string } | { __typename?: 'IndividualPersonalAccount', id: string, displayName: string } | { __typename?: 'InsurancePersonalAccount', id: string, displayName: string } | { __typename?: 'IrrevocableTrust', id: string, displayName: string } | { __typename?: 'JointPersonalAccount', id: string, displayName: string } | { __typename?: 'LLCBusinessEntity', id: string, displayName: string } | { __typename?: 'LPBusinessEntity', id: string, displayName: string } | { __typename?: 'PrivateFoundation', id: string, displayName: string } | { __typename?: 'QPRTTrust', id: string, displayName: string } | { __typename?: 'QualifiedTuitionPersonalAccount', id: string, displayName: string } | { __typename?: 'RetirementPersonalAccount', id: string, displayName: string } | { __typename?: 'RevocableTrust', id: string, displayName: string } | { __typename?: 'SCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'SLATTrust', id: string, displayName: string } | { __typename?: 'SoleProprietorshipBusinessEntity', id: string, displayName: string }, documents: { __typename?: 'DocumentConnection', edges?: Array<{ __typename?: 'DocumentEdge', node?: { __typename?: 'Document', id: string } | null } | null> | null } } | null } | null> | null } };

export const GetEntityDetails_EntityFragmentDoc = gql`
    fragment GetEntityDetails_Entity on Entity {
  id
  subtype {
    id
    displayName
  }
  documents {
    edges {
      node {
        id
      }
    }
  }
}
    `;
export const GetEntityDetailsDocument = gql`
    query GetEntityDetails($entityId: ID!) {
  entities(where: {id: $entityId}) {
    edges {
      node {
        ...GetEntityDetails_Entity
      }
    }
  }
}
    ${GetEntityDetails_EntityFragmentDoc}`;

/**
 * __useGetEntityDetailsQuery__
 *
 * To run a query within a React component, call `useGetEntityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityDetailsQuery({
 *   variables: {
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useGetEntityDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>(GetEntityDetailsDocument, options);
      }
export function useGetEntityDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>(GetEntityDetailsDocument, options);
        }
export function useGetEntityDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>(GetEntityDetailsDocument, options);
        }
export type GetEntityDetailsQueryHookResult = ReturnType<typeof useGetEntityDetailsQuery>;
export type GetEntityDetailsLazyQueryHookResult = ReturnType<typeof useGetEntityDetailsLazyQuery>;
export type GetEntityDetailsSuspenseQueryHookResult = ReturnType<typeof useGetEntityDetailsSuspenseQuery>;
export type GetEntityDetailsQueryResult = Apollo.QueryResult<GetEntityDetailsQuery, GetEntityDetailsQueryVariables>;
export const Operations = {
  Query: {
    GetEntityDetails: 'GetEntityDetails' as const
  },
  Fragment: {
    GetEntityDetails_Entity: 'GetEntityDetails_Entity' as const
  }
}