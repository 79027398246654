import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { ProgressPieChart } from '@/components/charts/PieChart/ProgressPieChart';
import { COLORS } from '@/styles/tokens/colors';
import { formatPercent } from '@/utils/formatting/percent';

import { HouseholdOnboardingStepGroupType } from './HouseholdOnboardingSidebar.types';

export interface HouseholdOnboardingSidebarHeaderProps {
  groups: HouseholdOnboardingStepGroupType[];
}
export function HouseholdOnboardingSidebarHeader({
  groups,
}: HouseholdOnboardingSidebarHeaderProps) {
  const totalSteps = groups.reduce((acc, { steps }) => acc + steps.length, 0);
  const completedSteps = groups.reduce(
    (acc, { steps }) => acc + steps.filter(({ completed }) => completed).length,
    0
  );

  const completedPercentage = new Decimal(completedSteps / totalSteps).times(
    100
  );

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        top: 0,
        left: 0,
        right: 0,
        py: 2,
        pl: 3,
        pr: 2,
        backgroundColor: COLORS.NAVY[800],
        alignItems: 'center',
      }}
    >
      <Stack>
        <Typography
          variant="h4"
          sx={{
            color: COLORS.PRIMITIVES.WHITE,
            lineBreak: 'strict',
            whiteSpace: 'nowrap',
          }}
        >
          Client action items
        </Typography>
        <Typography variant="subtitle2" sx={{ color: COLORS.NAVY[100] }}>
          {formatPercent(completedPercentage, 0)}% done
        </Typography>
      </Stack>
      <ProgressPieChart progressPercent={completedPercentage.toNumber()} />
    </Stack>
  );
}
