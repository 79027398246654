// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { HouseholdOnboardingSidebar_HouseholdOnboardingFragmentDoc, HouseholdOnboardingSidebar_HouseholdOnboardingStepFragmentDoc } from './HouseholdOnboardingSidebar.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateHouseholdOnboardingMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateHouseholdOnboardingInput;
}>;


export type UpdateHouseholdOnboardingMutation = { __typename?: 'Mutation', updateHouseholdOnboarding: { __typename?: 'HouseholdOnboarding', id: string, completedAt?: Time | null, steps?: Array<{ __typename?: 'HouseholdOnboardingStep', id: string, kind: Types.HouseholdOnboardingStepKind, completedAt?: Time | null }> | null } };

export type UpdateHouseholdOnboardingStepMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateHouseholdOnboardingStepInput;
}>;


export type UpdateHouseholdOnboardingStepMutation = { __typename?: 'Mutation', updateHouseholdOnboardingStep: { __typename?: 'HouseholdOnboardingStep', id: string, kind: Types.HouseholdOnboardingStepKind, completedAt?: Time | null } };


export const UpdateHouseholdOnboardingDocument = gql`
    mutation UpdateHouseholdOnboarding($input: AugmentedUpdateHouseholdOnboardingInput!) {
  updateHouseholdOnboarding(input: $input) {
    ...HouseholdOnboardingSidebar_HouseholdOnboarding
  }
}
    ${HouseholdOnboardingSidebar_HouseholdOnboardingFragmentDoc}`;
export type UpdateHouseholdOnboardingMutationFn = Apollo.MutationFunction<UpdateHouseholdOnboardingMutation, UpdateHouseholdOnboardingMutationVariables>;

/**
 * __useUpdateHouseholdOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateHouseholdOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHouseholdOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHouseholdOnboardingMutation, { data, loading, error }] = useUpdateHouseholdOnboardingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHouseholdOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHouseholdOnboardingMutation, UpdateHouseholdOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHouseholdOnboardingMutation, UpdateHouseholdOnboardingMutationVariables>(UpdateHouseholdOnboardingDocument, options);
      }
export type UpdateHouseholdOnboardingMutationHookResult = ReturnType<typeof useUpdateHouseholdOnboardingMutation>;
export type UpdateHouseholdOnboardingMutationResult = Apollo.MutationResult<UpdateHouseholdOnboardingMutation>;
export type UpdateHouseholdOnboardingMutationOptions = Apollo.BaseMutationOptions<UpdateHouseholdOnboardingMutation, UpdateHouseholdOnboardingMutationVariables>;
export const UpdateHouseholdOnboardingStepDocument = gql`
    mutation UpdateHouseholdOnboardingStep($input: AugmentedUpdateHouseholdOnboardingStepInput!) {
  updateHouseholdOnboardingStep(input: $input) {
    ...HouseholdOnboardingSidebar_HouseholdOnboardingStep
  }
}
    ${HouseholdOnboardingSidebar_HouseholdOnboardingStepFragmentDoc}`;
export type UpdateHouseholdOnboardingStepMutationFn = Apollo.MutationFunction<UpdateHouseholdOnboardingStepMutation, UpdateHouseholdOnboardingStepMutationVariables>;

/**
 * __useUpdateHouseholdOnboardingStepMutation__
 *
 * To run a mutation, you first call `useUpdateHouseholdOnboardingStepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHouseholdOnboardingStepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHouseholdOnboardingStepMutation, { data, loading, error }] = useUpdateHouseholdOnboardingStepMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateHouseholdOnboardingStepMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHouseholdOnboardingStepMutation, UpdateHouseholdOnboardingStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHouseholdOnboardingStepMutation, UpdateHouseholdOnboardingStepMutationVariables>(UpdateHouseholdOnboardingStepDocument, options);
      }
export type UpdateHouseholdOnboardingStepMutationHookResult = ReturnType<typeof useUpdateHouseholdOnboardingStepMutation>;
export type UpdateHouseholdOnboardingStepMutationResult = Apollo.MutationResult<UpdateHouseholdOnboardingStepMutation>;
export type UpdateHouseholdOnboardingStepMutationOptions = Apollo.BaseMutationOptions<UpdateHouseholdOnboardingStepMutation, UpdateHouseholdOnboardingStepMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateHouseholdOnboarding: 'UpdateHouseholdOnboarding' as const,
    UpdateHouseholdOnboardingStep: 'UpdateHouseholdOnboardingStep' as const
  }
}