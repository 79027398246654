// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from '../../../../graphql/fragments/pageInfo.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployeeFragment = { __typename?: 'Employee', id: string, displayName: string, user: { __typename?: 'User', id: string, email: string }, assignedHouseholds?: Array<{ __typename?: 'Household', id: string, displayName: string }> | null, roles?: Array<{ __typename?: 'Role', id: string, displayName: string }> | null };

export type GetAdminUsersQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.EmployeeWhereInput>;
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['Cursor']['input']>;
}>;


export type GetAdminUsersQuery = { __typename?: 'Query', paginatedData: { __typename?: 'EmployeeConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: any | null, endCursor?: any | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'EmployeeEdge', node?: { __typename?: 'Employee', id: string, displayName: string, user: { __typename?: 'User', id: string, email: string }, assignedHouseholds?: Array<{ __typename?: 'Household', id: string, displayName: string }> | null, roles?: Array<{ __typename?: 'Role', id: string, displayName: string }> | null } | null } | null> | null } };

export const EmployeeFragmentDoc = gql`
    fragment Employee on Employee {
  id
  displayName
  user {
    id
    email
  }
  assignedHouseholds {
    id
    displayName
  }
  roles {
    id
    displayName
  }
}
    `;
export const GetAdminUsersDocument = gql`
    query GetAdminUsers($where: EmployeeWhereInput, $first: Int!, $after: Cursor) {
  paginatedData: employees(where: $where, first: $first, after: $after) {
    totalCount
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        ...Employee
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${EmployeeFragmentDoc}`;

/**
 * __useGetAdminUsersQuery__
 *
 * To run a query within a React component, call `useGetAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetAdminUsersQuery(baseOptions: Apollo.QueryHookOptions<GetAdminUsersQuery, GetAdminUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminUsersQuery, GetAdminUsersQueryVariables>(GetAdminUsersDocument, options);
      }
export function useGetAdminUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminUsersQuery, GetAdminUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminUsersQuery, GetAdminUsersQueryVariables>(GetAdminUsersDocument, options);
        }
export function useGetAdminUsersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdminUsersQuery, GetAdminUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdminUsersQuery, GetAdminUsersQueryVariables>(GetAdminUsersDocument, options);
        }
export type GetAdminUsersQueryHookResult = ReturnType<typeof useGetAdminUsersQuery>;
export type GetAdminUsersLazyQueryHookResult = ReturnType<typeof useGetAdminUsersLazyQuery>;
export type GetAdminUsersSuspenseQueryHookResult = ReturnType<typeof useGetAdminUsersSuspenseQuery>;
export type GetAdminUsersQueryResult = Apollo.QueryResult<GetAdminUsersQuery, GetAdminUsersQueryVariables>;
export const Operations = {
  Query: {
    GetAdminUsers: 'GetAdminUsers' as const
  },
  Fragment: {
    Employee: 'Employee' as const
  }
}