import { getRevocableIrrevocableTrustSummaryProperties } from '@/modules/entities/details/trusts/RevocableIrrevocableTrustDetails';
import { getEntityTypeFromSubtype } from '@/modules/entities/utils/getEntityTypeFromSubtype';

import { TrustBeneficiariesSlide } from '../components/Trust.Beneficiaries';
import { RevocableIrrevocableTrustPresentationSlideProps } from './RevocableIrrevocableTrust.types';

export function RevocableIrrevocableTrustBeneficiariesSlide({
  entity,
  subtype,
  ...registrationProps
}: RevocableIrrevocableTrustPresentationSlideProps) {
  const summaryDetails = getRevocableIrrevocableTrustSummaryProperties({
    entity,
    entityType: getEntityTypeFromSubtype(subtype.__typename!),
  });

  return (
    <TrustBeneficiariesSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      displayName={subtype.displayName}
    />
  );
}
