import { Stack, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { CheckCircleBrokenIcon } from '@/components/icons/CheckCircleBrokenIcon';
import { UploadCloud01Icon } from '@/components/icons/UploadCloud01Icon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { useFormContext } from '@/components/react-hook-form';
import { SearchSelectionToolbar } from '@/components/tables/DataTable/components/SearchSelectionToolbar';
import { useGuardedContext } from '@/hooks/useGuardedContext';
import {
  BulkImportFlowContext,
  BulkImportFlowTitleMap,
} from '@/modules/import/BulkImportFlowContext';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import { pluralize } from '@/utils/formatting/strings';

import { BulkImportForm } from '../BulkImportPage.types';

export function BulkClientImportSelectionStepToolbar() {
  const { control } = useFormContext<BulkImportForm>();
  const selectedIntegrationClientIds = useWatch<BulkImportForm>({
    control,
    name: 'selectedIntegrationClientIds' as const,
  }) as BulkImportForm['selectedIntegrationClientIds'];
  const count = selectedIntegrationClientIds.length;

  return (
    <SearchSelectionToolbar
      label="Search within list below"
      details={
        count > 0 && (
          <Badge
            variant={BadgeVariants.Yellow}
            display={`${count} ${pluralize(count, 'individual')} selected`}
          />
        )
      }
    />
  );
}

export function BulkClientImportSelectionStepEmptySet() {
  const { flowType } = useGuardedContext(
    BulkImportFlowContext,
    'BulkImportFlowContextProvider'
  );
  const flowTitle = BulkImportFlowTitleMap[flowType];
  const navigate = useNavigate();
  return (
    <ActionCard
      grow
      variant="noCard"
      icon={
        <CheckCircleBrokenIcon
          size={48}
          color={COLORS.GRAY[200]}
          fill={COLORS.GRAY[200]}
          stroke={COLORS.GRAY[200]}
        />
      }
      heading="No individuals available to import"
      description={`All individuals in ${flowTitle} are linked to clients in Luminary. To modify existing links to ${flowTitle}, navigate to the client profile for the client in question.`}
      actions={
        <Button
          variant="primary"
          size="md"
          fullWidth
          onClick={() =>
            navigate(
              getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLDS_LIST, {})
            )
          }
          startIcon={UploadCloud01Icon}
        >
          Import entities for linked clients
        </Button>
      }
    />
  );
}

export function BulkClientImportSelectionStepNoRows() {
  return (
    <Stack
      direction="column"
      alignItems="center"
      width="100%"
      p={3}
      justifyContent="center"
    >
      <Typography variant="body1">No matching results</Typography>
    </Stack>
  );
}
