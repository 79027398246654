import { useEffect } from 'react';

import { useFeedback } from './useFeedback';

interface ErrorFeedbackFallbackProps {
  message?: string;
}

export const ErrorFeedbackFallback = ({
  message = 'Please refresh the page and try again.',
}: ErrorFeedbackFallbackProps) => {
  const { showFeedback } = useFeedback();
  useEffect(() => {
    showFeedback(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on mount
  }, []);
  return <></>;
};
