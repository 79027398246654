import { isDate } from 'lodash';

export function validateYearAfter(
  fieldName = 'Date',
  minYear: number,
  errorMessage: string
) {
  return (value: unknown): string | undefined => {
    if (!value) {
      return `${fieldName} is required`;
    }

    if (!isDate(value)) {
      return `${fieldName} must be a date`;
    }

    const date = value;

    const targetYear = new Date().getFullYear() - minYear;

    if (date.getFullYear() <= targetYear) {
      return errorMessage;
    }

    return undefined;
  };
}
