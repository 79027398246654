// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportAdvisorClientMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ExportAdvisorClientMutation = { __typename?: 'Mutation', exportHousehold: string };

export type ImportAdvisorClientMutationVariables = Types.Exact<{
  data: Types.Scalars['String']['input'];
}>;


export type ImportAdvisorClientMutation = { __typename?: 'Mutation', importHousehold: { __typename?: 'Household', id: string, displayName: string } };

export type CopyArchetypeClientMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type CopyArchetypeClientMutation = { __typename?: 'Mutation', copyArchetypeHousehold: { __typename?: 'Household', id: string, displayName: string } };

export type ClientImportExport_HouseholdFragment = { __typename?: 'Household', id: string, displayName: string };

export type CloneCardAdvisorClientsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CloneCardAdvisorClientsQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, displayName: string } | null } | null> | null } };

export type ArchetypeClientsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ArchetypeClientsQuery = { __typename?: 'Query', archetypeHouseholds: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, displayName: string } | null } | null> | null } };

export const ClientImportExport_HouseholdFragmentDoc = gql`
    fragment ClientImportExport_Household on Household {
  id
  displayName
}
    `;
export const ExportAdvisorClientDocument = gql`
    mutation ExportAdvisorClient($id: ID!) {
  exportHousehold(id: $id)
}
    `;
export type ExportAdvisorClientMutationFn = Apollo.MutationFunction<ExportAdvisorClientMutation, ExportAdvisorClientMutationVariables>;

/**
 * __useExportAdvisorClientMutation__
 *
 * To run a mutation, you first call `useExportAdvisorClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAdvisorClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAdvisorClientMutation, { data, loading, error }] = useExportAdvisorClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExportAdvisorClientMutation(baseOptions?: Apollo.MutationHookOptions<ExportAdvisorClientMutation, ExportAdvisorClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportAdvisorClientMutation, ExportAdvisorClientMutationVariables>(ExportAdvisorClientDocument, options);
      }
export type ExportAdvisorClientMutationHookResult = ReturnType<typeof useExportAdvisorClientMutation>;
export type ExportAdvisorClientMutationResult = Apollo.MutationResult<ExportAdvisorClientMutation>;
export type ExportAdvisorClientMutationOptions = Apollo.BaseMutationOptions<ExportAdvisorClientMutation, ExportAdvisorClientMutationVariables>;
export const ImportAdvisorClientDocument = gql`
    mutation ImportAdvisorClient($data: String!) {
  importHousehold(data: $data) {
    id
    displayName
  }
}
    `;
export type ImportAdvisorClientMutationFn = Apollo.MutationFunction<ImportAdvisorClientMutation, ImportAdvisorClientMutationVariables>;

/**
 * __useImportAdvisorClientMutation__
 *
 * To run a mutation, you first call `useImportAdvisorClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportAdvisorClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importAdvisorClientMutation, { data, loading, error }] = useImportAdvisorClientMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useImportAdvisorClientMutation(baseOptions?: Apollo.MutationHookOptions<ImportAdvisorClientMutation, ImportAdvisorClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportAdvisorClientMutation, ImportAdvisorClientMutationVariables>(ImportAdvisorClientDocument, options);
      }
export type ImportAdvisorClientMutationHookResult = ReturnType<typeof useImportAdvisorClientMutation>;
export type ImportAdvisorClientMutationResult = Apollo.MutationResult<ImportAdvisorClientMutation>;
export type ImportAdvisorClientMutationOptions = Apollo.BaseMutationOptions<ImportAdvisorClientMutation, ImportAdvisorClientMutationVariables>;
export const CopyArchetypeClientDocument = gql`
    mutation CopyArchetypeClient($id: ID!) {
  copyArchetypeHousehold(id: $id) {
    id
    displayName
  }
}
    `;
export type CopyArchetypeClientMutationFn = Apollo.MutationFunction<CopyArchetypeClientMutation, CopyArchetypeClientMutationVariables>;

/**
 * __useCopyArchetypeClientMutation__
 *
 * To run a mutation, you first call `useCopyArchetypeClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyArchetypeClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyArchetypeClientMutation, { data, loading, error }] = useCopyArchetypeClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyArchetypeClientMutation(baseOptions?: Apollo.MutationHookOptions<CopyArchetypeClientMutation, CopyArchetypeClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyArchetypeClientMutation, CopyArchetypeClientMutationVariables>(CopyArchetypeClientDocument, options);
      }
export type CopyArchetypeClientMutationHookResult = ReturnType<typeof useCopyArchetypeClientMutation>;
export type CopyArchetypeClientMutationResult = Apollo.MutationResult<CopyArchetypeClientMutation>;
export type CopyArchetypeClientMutationOptions = Apollo.BaseMutationOptions<CopyArchetypeClientMutation, CopyArchetypeClientMutationVariables>;
export const CloneCardAdvisorClientsDocument = gql`
    query CloneCardAdvisorClients {
  households {
    edges {
      node {
        ...ClientImportExport_Household
      }
    }
  }
}
    ${ClientImportExport_HouseholdFragmentDoc}`;

/**
 * __useCloneCardAdvisorClientsQuery__
 *
 * To run a query within a React component, call `useCloneCardAdvisorClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloneCardAdvisorClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloneCardAdvisorClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCloneCardAdvisorClientsQuery(baseOptions?: Apollo.QueryHookOptions<CloneCardAdvisorClientsQuery, CloneCardAdvisorClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CloneCardAdvisorClientsQuery, CloneCardAdvisorClientsQueryVariables>(CloneCardAdvisorClientsDocument, options);
      }
export function useCloneCardAdvisorClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CloneCardAdvisorClientsQuery, CloneCardAdvisorClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CloneCardAdvisorClientsQuery, CloneCardAdvisorClientsQueryVariables>(CloneCardAdvisorClientsDocument, options);
        }
export function useCloneCardAdvisorClientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CloneCardAdvisorClientsQuery, CloneCardAdvisorClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CloneCardAdvisorClientsQuery, CloneCardAdvisorClientsQueryVariables>(CloneCardAdvisorClientsDocument, options);
        }
export type CloneCardAdvisorClientsQueryHookResult = ReturnType<typeof useCloneCardAdvisorClientsQuery>;
export type CloneCardAdvisorClientsLazyQueryHookResult = ReturnType<typeof useCloneCardAdvisorClientsLazyQuery>;
export type CloneCardAdvisorClientsSuspenseQueryHookResult = ReturnType<typeof useCloneCardAdvisorClientsSuspenseQuery>;
export type CloneCardAdvisorClientsQueryResult = Apollo.QueryResult<CloneCardAdvisorClientsQuery, CloneCardAdvisorClientsQueryVariables>;
export const ArchetypeClientsDocument = gql`
    query ArchetypeClients {
  archetypeHouseholds {
    edges {
      node {
        ...ClientImportExport_Household
      }
    }
  }
}
    ${ClientImportExport_HouseholdFragmentDoc}`;

/**
 * __useArchetypeClientsQuery__
 *
 * To run a query within a React component, call `useArchetypeClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArchetypeClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArchetypeClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useArchetypeClientsQuery(baseOptions?: Apollo.QueryHookOptions<ArchetypeClientsQuery, ArchetypeClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArchetypeClientsQuery, ArchetypeClientsQueryVariables>(ArchetypeClientsDocument, options);
      }
export function useArchetypeClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArchetypeClientsQuery, ArchetypeClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArchetypeClientsQuery, ArchetypeClientsQueryVariables>(ArchetypeClientsDocument, options);
        }
export function useArchetypeClientsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ArchetypeClientsQuery, ArchetypeClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ArchetypeClientsQuery, ArchetypeClientsQueryVariables>(ArchetypeClientsDocument, options);
        }
export type ArchetypeClientsQueryHookResult = ReturnType<typeof useArchetypeClientsQuery>;
export type ArchetypeClientsLazyQueryHookResult = ReturnType<typeof useArchetypeClientsLazyQuery>;
export type ArchetypeClientsSuspenseQueryHookResult = ReturnType<typeof useArchetypeClientsSuspenseQuery>;
export type ArchetypeClientsQueryResult = Apollo.QueryResult<ArchetypeClientsQuery, ArchetypeClientsQueryVariables>;
export const Operations = {
  Query: {
    CloneCardAdvisorClients: 'CloneCardAdvisorClients' as const,
    ArchetypeClients: 'ArchetypeClients' as const
  },
  Mutation: {
    ExportAdvisorClient: 'ExportAdvisorClient' as const,
    ImportAdvisorClient: 'ImportAdvisorClient' as const,
    CopyArchetypeClient: 'CopyArchetypeClient' as const
  },
  Fragment: {
    ClientImportExport_Household: 'ClientImportExport_Household' as const
  }
}