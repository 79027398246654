import {
  array,
  boolean as booleanType,
  date,
  Describe,
  enums,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateKeyPersonInput,
  AugmentedCreateKeyPersonRoleInput,
  AugmentedCreateOwnershipStakeInput,
  BusinessEntityTaxStatus,
  CreateLlcBusinessEntityInput,
  KeyPersonRoleKind,
  UpdateLlcBusinessEntityInput,
} from '@/types/schema';

import { commonUpdateEntityProperties, decimal } from './types';

const keyPersonRoleSchema: Describe<AugmentedCreateKeyPersonRoleInput> = object(
  {
    create: object({
      kind: optional(
        nullable(
          enums([
            KeyPersonRoleKind.BoardMember,
            KeyPersonRoleKind.ControllingPerson,
            KeyPersonRoleKind.Director,
            KeyPersonRoleKind.GeneralPartner,
            KeyPersonRoleKind.Manager,
            KeyPersonRoleKind.Officer,
            KeyPersonRoleKind.Other,
            KeyPersonRoleKind.President,
            KeyPersonRoleKind.Secretary,
            KeyPersonRoleKind.Signatory,
            KeyPersonRoleKind.VicePresident,
          ])
        )
      ),
    }),
  }
);

export const keyPersonSchema: Describe<AugmentedCreateKeyPersonInput> = object({
  create: object({
    individualID: optional(nullable(string())),
    entityID: optional(nullable(string())),
    organizationID: optional(nullable(string())),
  }),
  withRoles: optional(nullable(array(keyPersonRoleSchema))),
});

export const businessEntityTaxStatus = optional(
  nullable(
    enums([
      BusinessEntityTaxStatus.Corporation,
      BusinessEntityTaxStatus.DisregardedEntity,
      BusinessEntityTaxStatus.NonTaxableEntity,
      BusinessEntityTaxStatus.Partnership,
    ])
  )
);

export const commonCreateBusinessEntityProperties: Describe<CreateLlcBusinessEntityInput> =
  object({
    legalName: optional(nullable(string())),
    displayName: string(),
    doingBusinessAsName: optional(nullable(string())),

    effectiveDate: optional(nullable(date())),
    description: optional(nullable(string())),
    formationJurisdictionStateCode: optional(nullable(string())),

    keyPeopleNotes: optional(nullable(string())),
    ownershipAsOfDate: optional(nullable(date())),

    taxStatus: businessEntityTaxStatus,
    taxID: optional(nullable(string())),
  });

export const commonUpdateBusinessEntityProperties: Describe<UpdateLlcBusinessEntityInput> =
  object({
    // required or pseudo-required properties
    displayName: optional(nullable(string())),
    ownershipAsOfDate: optional(nullable(date())),

    legalName: optional(nullable(string())),
    clearLegalName: optional(nullable(booleanType())),

    doingBusinessAsName: optional(nullable(string())),
    clearDoingBusinessAsName: optional(nullable(booleanType())),

    effectiveDate: optional(nullable(date())),
    clearEffectiveDate: optional(nullable(booleanType())),

    description: optional(nullable(string())),
    clearDescription: optional(nullable(booleanType())),

    formationJurisdictionStateCode: optional(nullable(string())),
    clearFormationJurisdictionStateCode: optional(nullable(booleanType())),

    keyPeopleNotes: optional(nullable(string())),
    clearKeyPeopleNotes: optional(nullable(booleanType())),

    taxStatus: businessEntityTaxStatus,
    clearTaxStatus: optional(nullable(booleanType())),

    taxID: optional(nullable(string())),
    clearTaxID: optional(nullable(booleanType())),

    // adding key people is handled from a level above, but is cleared here
    clearKeyPeople: optional(nullable(booleanType())),

    requiresCtaReporting: optional(nullable(booleanType())),
    finCenID: optional(nullable(string())),
    clearFinCenID: optional(nullable(booleanType())),

    removePolicyIDs: optional(nullable(array(string()))),
  });

const ownershipStake: Describe<AugmentedCreateOwnershipStakeInput> = object({
  create: object({
    owningIndividualID: optional(nullable(string())),
    owningEntityID: optional(nullable(string())),
    ownershipPercentage: optional(nullable(decimal)),
  }),
});

export const commonCreateBusinessEntityTopLevelProperties = object({
  withOwnedByStakes: optional(nullable(array(ownershipStake))),
});

export const commonUpdateBusinessEntityTopLevelProperties = object({
  withOwnedByStakes: optional(nullable(array(ownershipStake))),
});

export const commonUpdateBusinessEntityEntityUpdateProperties = object({
  ...commonUpdateEntityProperties,
  clearOwnedByStakes: optional(nullable(booleanType())),
});
