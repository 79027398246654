import { css } from '@emotion/css';
import { Box } from '@mui/material';

import { useListColumnStyles } from '@/components/lists/lists.utils';
import { BusinessEntitySummaryData } from '@/modules/entities/details/businessEntities/BusinessEntityDetailsCard/BusinessEntityDetails.types';
import { BusinessEntityOwnershipList } from '@/modules/entities/details/businessEntities/BusinessEntityDetailsCard/BusinessEntityOwnershipList';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';

import { PresentationSlideSecondaryHeading } from '../../../components/PresentationSlideSecondaryHeading';
import { PRESENTATION_SPACING_UNITS } from '../../../presentation.constants';
import { PresentationSlide } from '../../../PresentationSlide';
import { PresentationSlideRegistrationDetails } from '../../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../../graphql/EntityPresentation.generated';

export interface BusinessEntityOwnershipSlideProps
  extends PresentationSlideRegistrationDetails {
  entity: EntityPresentation_EntityFragment;
  summary: BusinessEntitySummaryData;
  displayName: string;
}

const SLIDE_TITLE = 'Ownership';

export function BusinessEntityOwnershipSlide({
  entity,
  summary,
  displayName,
  ...registrationProps
}: BusinessEntityOwnershipSlideProps) {
  const listColumnsStyles = useListColumnStyles();
  useRegisterSlide({
    displayName: SLIDE_TITLE,
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  return (
    <PresentationSlide.Slide
      id={registrationProps.slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading
          heading={SLIDE_TITLE}
          subheading={displayName}
        />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={entity.household.displayName}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <Box p={PRESENTATION_SPACING_UNITS}>
        <BusinessEntityOwnershipList
          classes={{
            parentList: css(listColumnsStyles),
          }}
          ownershipAsOfDate={summary.ownershipAsOfDate}
          ownedByStakes={summary.ownedByStakes}
          currentValue={summary.currentValue}
        />
      </Box>
    </PresentationSlide.Slide>
  );
}
