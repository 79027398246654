import { Box, Stack, Typography } from '@mui/material';
import { compact } from 'lodash';

import { SidebarTab } from '@/components/navigation/SidebarTab/SidebarTab';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { useManageDispositionsContext } from '../context/ManageDispositions.context';
import { usePageSectionsActionNeededDetails } from '../hooks/usePageSectionsActionNeededDetails';
import { ManageDispositionPageSections } from '../ManageDispositionPage.types';

function useSidebarSections() {
  const { isTwoClientHousehold } = useHouseholdDetailsContext();

  return compact([
    {
      label: 'In estate',
      type: ManageDispositionPageSections.IN_ESTATE_ENTITIES,
    },
    {
      label: 'Out of estate',
      type: ManageDispositionPageSections.OUT_OF_ESTATE_ENTITIES,
    },
    // for single grantor clients, there is only one death event, so the TEs
    // can’t have dispositions.. since they by definition are created once everyone is dead
    isTwoClientHousehold && {
      label: 'Testamentary entities',
      type: ManageDispositionPageSections.TESTAMENTARY_ENTITIES,
    },
  ]);
}

export function ManageDispositionsSidebar() {
  const sidebarSections = useSidebarSections();
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const { activeSection, setActiveSection } = useManageDispositionsContext();

  const sectionNeedsAction = usePageSectionsActionNeededDetails();

  return (
    <Stack>
      <Box py={3} px={1.5}>
        <Typography variant="h2" mb={0.5}>
          How do I manage dispositions?
        </Typography>
        <Typography variant="body1">
          {isTwoClientHousehold ? (
            <>
              Select a category below and specify provisions{' '}
              <strong>for each death order</strong> on the entities listed.
            </>
          ) : (
            <>
              Select a category below and specify provisions upon death on the
              entities listed.
            </>
          )}
        </Typography>
      </Box>
      <Stack>
        {sidebarSections.map((section) => (
          <SidebarTab
            key={section.type}
            data-testid={section.type}
            onClick={() => {
              setActiveSection(section.type);
            }}
            isActive={section.type === activeSection}
          >
            <Stack direction="row" justifyContent="space-between">
              <Box>{section.label}</Box>
              {sectionNeedsAction[section.type] && (
                <Badge variant={BadgeVariants.Yellow} display="Action needed" />
              )}
            </Stack>
          </SidebarTab>
        ))}
      </Stack>
    </Stack>
  );
}
