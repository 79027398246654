// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEntityAndAcknowledgeSuggestionsMutationVariables = Types.Exact<{
  entityInput: Types.AugmentedUpdateEntityInput;
  acknowledgeSuggestionsInput: Array<Types.AcknowledgeAiSuggestionInput> | Types.AcknowledgeAiSuggestionInput;
}>;


export type UpdateEntityAndAcknowledgeSuggestionsMutation = { __typename?: 'Mutation', updateEntity: { __typename?: 'Entity', id: string, stage: Types.EntityStage, household: { __typename?: 'Household', id: string }, subtype: { __typename?: 'CCorpBusinessEntity', id: string } | { __typename?: 'CLTTrust', id: string } | { __typename?: 'CRTTrust', id: string } | { __typename?: 'CustodialPersonalAccount', id: string } | { __typename?: 'DonorAdvisedFund', id: string } | { __typename?: 'GPBusinessEntity', id: string } | { __typename?: 'GRATTrust', id: string } | { __typename?: 'ILITTrust', id: string } | { __typename?: 'IndividualPersonalAccount', id: string } | { __typename?: 'InsurancePersonalAccount', id: string } | { __typename?: 'IrrevocableTrust', id: string } | { __typename?: 'JointPersonalAccount', id: string } | { __typename?: 'LLCBusinessEntity', id: string } | { __typename?: 'LPBusinessEntity', id: string } | { __typename?: 'PrivateFoundation', id: string } | { __typename?: 'QPRTTrust', id: string } | { __typename?: 'QualifiedTuitionPersonalAccount', id: string } | { __typename?: 'RetirementPersonalAccount', id: string } | { __typename?: 'RevocableTrust', id: string } | { __typename?: 'SCorpBusinessEntity', id: string } | { __typename?: 'SLATTrust', id: string } | { __typename?: 'SoleProprietorshipBusinessEntity', id: string } }, acknowledgeAISuggestions: Array<{ __typename?: 'AISuggestion', id: string, acceptanceStatus?: Types.AiSuggestionAcceptanceStatus | null }> };


export const UpdateEntityAndAcknowledgeSuggestionsDocument = gql`
    mutation UpdateEntityAndAcknowledgeSuggestions($entityInput: AugmentedUpdateEntityInput!, $acknowledgeSuggestionsInput: [AcknowledgeAISuggestionInput!]!) {
  updateEntity(input: $entityInput) {
    id
    stage
    household {
      id
    }
    subtype {
      id
    }
  }
  acknowledgeAISuggestions(input: $acknowledgeSuggestionsInput) {
    id
    acceptanceStatus
  }
}
    `;
export type UpdateEntityAndAcknowledgeSuggestionsMutationFn = Apollo.MutationFunction<UpdateEntityAndAcknowledgeSuggestionsMutation, UpdateEntityAndAcknowledgeSuggestionsMutationVariables>;

/**
 * __useUpdateEntityAndAcknowledgeSuggestionsMutation__
 *
 * To run a mutation, you first call `useUpdateEntityAndAcknowledgeSuggestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityAndAcknowledgeSuggestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityAndAcknowledgeSuggestionsMutation, { data, loading, error }] = useUpdateEntityAndAcknowledgeSuggestionsMutation({
 *   variables: {
 *      entityInput: // value for 'entityInput'
 *      acknowledgeSuggestionsInput: // value for 'acknowledgeSuggestionsInput'
 *   },
 * });
 */
export function useUpdateEntityAndAcknowledgeSuggestionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntityAndAcknowledgeSuggestionsMutation, UpdateEntityAndAcknowledgeSuggestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntityAndAcknowledgeSuggestionsMutation, UpdateEntityAndAcknowledgeSuggestionsMutationVariables>(UpdateEntityAndAcknowledgeSuggestionsDocument, options);
      }
export type UpdateEntityAndAcknowledgeSuggestionsMutationHookResult = ReturnType<typeof useUpdateEntityAndAcknowledgeSuggestionsMutation>;
export type UpdateEntityAndAcknowledgeSuggestionsMutationResult = Apollo.MutationResult<UpdateEntityAndAcknowledgeSuggestionsMutation>;
export type UpdateEntityAndAcknowledgeSuggestionsMutationOptions = Apollo.BaseMutationOptions<UpdateEntityAndAcknowledgeSuggestionsMutation, UpdateEntityAndAcknowledgeSuggestionsMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateEntityAndAcknowledgeSuggestions: 'UpdateEntityAndAcknowledgeSuggestions' as const
  }
}