import { InputAdornment, Stack, Typography } from '@mui/material';

import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormAwareTypeaheadListInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadListInput';
import { ModalFormLayout } from '@/components/form/layout/ModalFormLayout';
import { Mail01Icon } from '@/components/icons/Mail01Icon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { EmptyListItem } from '@/components/typography/EmptyListItem';
import { CollaboratorAccessWarning } from '@/modules/collaborators/content/CollaboratorAccessWarning';

import { useAdvisorClientOptions } from './CollaboratorForm.utils';
import {
  CollaboratorFormShape,
  ROLE_OPTION_ITEMS,
} from './CollaboratorModal.constants';

interface CollaboratorFormProps {
  variant: 'create' | 'edit';
}

export function CollaboratorForm({ variant }: CollaboratorFormProps) {
  const { control } = useFormContext<CollaboratorFormShape>();
  const { options: advisorClientOptions, loading } = useAdvisorClientOptions();

  return (
    <ModalFormLayout>
      <FormLayoutRow>
        <FormLayoutItem>
          <Card variant="filled" sx={{ p: 2 }}>
            <FormAwareSelectInput<CollaboratorFormShape>
              control={control}
              label="Role"
              // TODO (LUM-1552): Typed as string, fix to be more specific
              fieldName="role"
              options={ROLE_OPTION_ITEMS}
              required
            />
          </Card>
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={6}>
          <FormAwareTextInput<CollaboratorFormShape>
            control={control}
            fieldName="firstName"
            label="First name"
            required
          />
        </FormLayoutItem>
        <FormLayoutItem width={6}>
          <FormAwareTextInput<CollaboratorFormShape>
            control={control}
            fieldName="lastName"
            label="Last name"
            required
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput<CollaboratorFormShape>
            control={control}
            // don't allow changing the email address for existing collaborators; it could have unintended consequences
            // and isn't supported by the backend
            disabled={variant === 'edit'}
            type="email"
            fieldName="email"
            label="Email"
            startAdornment={
              <InputAdornment position="start">
                <Mail01Icon size={20} />
              </InputAdornment>
            }
            required
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={6}>
          <FormAwareTextInput<CollaboratorFormShape>
            control={control}
            fieldName="title"
            label="Title"
          />
        </FormLayoutItem>
        <FormLayoutItem width={6}>
          <FormAwareTextInput<CollaboratorFormShape>
            control={control}
            fieldName="organization"
            label="Organization"
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <Stack mb={2}>
        <Typography variant="h3">Client access</Typography>
        <Typography variant="body1">
          <CollaboratorAccessWarning />
        </Typography>
      </Stack>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTypeaheadListInput
            placeholder="Search for clients"
            control={control}
            emptyListPlaceholder={
              <EmptyListItem>No clients associated</EmptyListItem>
            }
            name="associatedClients"
            options={advisorClientOptions}
            loading={loading}
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </ModalFormLayout>
  );
}
