import { compose } from '@/utils/reducerUtils';

import { applyAutoLayoutToState } from '../state';
import { AutoPositionAction, EntityMapActionFn } from '../types';

export const autoPositionAction: EntityMapActionFn<AutoPositionAction> = (
  state,
  _action,
  queueEffect
) => {
  // Normally this is handled for us in the internalOnNodesChanged of FlowChart,
  // however since we are programatically changing positions the nodes change handler never triggers event
  // though we now our state is effectively "dirty" because we've repositioned nodes.
  queueEffect({ type: 'setIsDirtyEffect', isDirty: true });
  queueEffect({ type: 'fitViewEffect' });

  return compose(applyAutoLayoutToState)(state);
};
