import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useEntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { useDeleteDocumentMutation } from '@/modules/files/DocumentRepresentation/graphql/DocumentRepresentation.generated';
import { useUpdateGenericDocumentMutation } from '@/modules/files/hooks/graphql/UseUpdateDocument.generated';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { DocumentDetailsDocument } from '../../graphql/DocumentDetails.generated';
import {
  DocumentDetailsFormShape,
  ValidatedDocumentDetailsFormShape,
} from '../DocumentDetailsForm.types';

export function useSaveDocumentDetailsForm() {
  const client = useApolloClient();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const { refetchSuggestions } = useEntitySuggestionsContext();

  const [updateDocumentMutation, { loading: updateLoading }] =
    useUpdateGenericDocumentMutation({
      refetchQueries: [DocumentDetailsDocument],
      onCompleted: (data) => {
        showFeedback(FeedbackMessages.formSaveSuccess, {
          variant: 'success',
        });

        const entityID = data.updateDocument?.entity?.id;
        const aiSuggestionsEnabled = data.updateDocument?.enableAiSuggestions;
        if (entityID && aiSuggestionsEnabled) {
          refetchSuggestions({ entityID });
        }
      },
      onError: (error) => {
        reportError(`failed to update document`, error);
        showFeedback(FeedbackMessages.formSaveError);
      },
    });
  const [deleteDocumentMutation, { loading: deleteLoading }] =
    useDeleteDocumentMutation({
      onCompleted: async () => {
        await client.refetchQueries({
          updateCache(cache) {
            cache.evict({ fieldName: 'documents' });
            cache.gc();
          },
        });
      },
      onError: (error) => {
        reportError(`failed to delete document`, error);
        showFeedback(FeedbackMessages.deleteError);
      },
    });

  const updateDocument = useCallback(
    (formData: ValidatedDocumentDetailsFormShape) => {
      const {
        _documentId: documentId,
        fileName,
        documentType,
        entityId,
        enableAiSuggestions,
        isDefaultForEntity,
        associatedEntityIds,
      } = formData;
      const entityIds = associatedEntityIds.filter(
        (id) => getPulidKind(id) === PulidKind.Entity
      );
      const testamentaryEntityIds = associatedEntityIds.filter(
        (id) => getPulidKind(id) === PulidKind.TestamentaryEntity
      );

      return updateDocumentMutation({
        variables: {
          documentId,
          documentInput: {
            // handle the case where we're clearing the entity, in which case
            // entityID is ppassed here as an empty string
            entityID: entityId || null,
            clearEntity: !entityId,
            addAdditionalEntityIDs: entityIds,
            clearAdditionalEntities: true,
            addAdditionalTestamentaryEntityIDs: testamentaryEntityIds,
            clearAdditionalTestamentaryEntities: true,
            type: documentType,
            name: fileName,
            enableAiSuggestions,
            clearDefaultDocumentOfEntity: !isDefaultForEntity,
            defaultDocumentOfEntityID:
              isDefaultForEntity && entityId ? entityId : null,
          },
        },
      });
    },
    [updateDocumentMutation]
  );

  const deleteDocument = useCallback(
    (documentId: string) => {
      return deleteDocumentMutation({
        variables: {
          documentId,
        },
      });
    },
    [deleteDocumentMutation]
  );

  const validateForm = useCallback(
    (form: DocumentDetailsFormShape): ValidatedDocumentDetailsFormShape => {
      if (form.documentKind === '') {
        throw new Error('Document type is required');
      } else {
        return {
          ...form,
          documentType: form.documentKind,
        };
      }
    },
    []
  );

  return {
    updateDocument,
    deleteDocument,
    loading: updateLoading || deleteLoading,
    validateForm,
  };
}
