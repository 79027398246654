import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { compact, first } from 'lodash';

import { Card } from '@/components/layout/Card/Card';
import {
  PAYOUT_FREQUENCY_COPY,
  PAYOUT_KIND_COPY,
} from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesigner.copy';
import { CharitableProposalFragment_BeneficiaryFragment } from '@/pages/proposal/graphql/CharitableProposalFragment.generated';
import { COLORS } from '@/styles/tokens/colors';
import { CharitableTrustPayoutKind } from '@/types/schema';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';
import { getNodes } from '@/utils/graphqlUtils';

import { CharitableOverviewProps } from './CharitableOverview';

export function CharitableOverviewLeftColumn({
  proposal,
}: CharitableOverviewProps) {
  const charitableProposal = proposal?.cltProposal || proposal.crtProposal;

  if (!charitableProposal) {
    return null;
  }
  return (
    <Stack direction="column" spacing={3} divider={<Divider />}>
      <Typography variant="h2">Basic information</Typography>
      <Box>
        {charitableProposal?.donors?.map(({ displayName }, idx) => (
          <TextRow
            key={`donor-${idx}`}
            label={<Typography variant="h6">Donor</Typography>}
            value={<Typography variant="h3">{displayName}</Typography>}
          />
        ))}
      </Box>
      <Stack spacing={1} direction="column">
        <Typography variant="h6">Assets</Typography>
        <TextRow
          label={<Typography variant="h3">Funding value</Typography>}
          value={
            <Typography variant="h3">
              {formatCurrencyNoDecimals(charitableProposal.assetValue)}
            </Typography>
          }
        />
        <TextRow
          label={<Typography variant="h3">Cost basis</Typography>}
          value={
            <Typography variant="h3">
              {formatCurrencyNoDecimals(charitableProposal.assetCostBasis)}
            </Typography>
          }
        />
        {charitableProposal.assetNotes && (
          <Typography variant="h6">{charitableProposal.assetNotes}</Typography>
        )}
        {proposal?.cltProposal && (
          <TextRow
            label={
              <Typography variant="h3">Taxable gift from funding</Typography>
            }
            value={
              <Typography variant="h3">
                {formatCurrencyNoDecimals(
                  proposal.cltProposal.projectionMedium.taxableGift
                )}
              </Typography>
            }
          />
        )}
      </Stack>
      <Stack spacing={1} direction="column">
        <Typography variant="h6">Payouts</Typography>
        <TextRow
          label={<Typography variant="h3">Type</Typography>}
          value={
            <Typography variant="h3">
              {PAYOUT_KIND_COPY[charitableProposal.payoutKind]}
            </Typography>
          }
        />
        <TextRow
          label={<Typography variant="h3">Amount</Typography>}
          value={
            <Typography variant="h3">
              {charitableProposal.payoutKind ===
              CharitableTrustPayoutKind.Annuity
                ? formatCurrencyNoDecimals(
                    charitableProposal.annuityPayoutAmount || new Decimal(0)
                  )
                : `${formatPercent(
                    charitableProposal.unitrustPayoutPercent || new Decimal(0),
                    2
                  )}%`}
            </Typography>
          }
        />
        <TextRow
          label={<Typography variant="h3">Frequency</Typography>}
          value={
            <Typography variant="h3">
              {PAYOUT_FREQUENCY_COPY[charitableProposal.payoutFrequency]}
            </Typography>
          }
        />
      </Stack>
    </Stack>
  );
}

export function CharitableOverviewRightColumn({
  proposal,
}: CharitableOverviewProps) {
  const isCRT = !!proposal?.crtProposal?.id;

  return (
    <Stack spacing={3} sx={{ pl: 10 }}>
      <Typography variant="h2">Beneficiaries</Typography>
      {isCRT ? (
        <>
          <BeneficiariesCard
            beneficiaries={proposal.crtProposal?.incomeBeneficiaries}
          />
          <BeneficiariesCard
            beneficiaries={
              proposal.crtProposal?.charitableRemainderBeneficiaries
            }
            isCharitable
            isRemainder
          />
        </>
      ) : (
        <>
          <BeneficiariesCard
            beneficiaries={proposal.cltProposal?.charitableIncomeBeneficiaries}
            isCharitable
          />
          <BeneficiariesCard
            beneficiaries={proposal.cltProposal?.remainderBeneficiaries}
            isRemainder
          />
        </>
      )}
    </Stack>
  );
}

interface BeneficiariesCardProps {
  isCharitable?: boolean;
  isRemainder?: boolean;
  beneficiaries:
    | CharitableProposalFragment_BeneficiaryFragment[]
    | null
    | undefined;
}

function BeneficiariesCard({
  beneficiaries = [],
  isCharitable,
  isRemainder,
}: BeneficiariesCardProps) {
  const theme = useTheme();
  let header = '';
  let description = '';
  if (isCharitable && isRemainder) {
    header = 'Charitable remainder';
    description =
      "Charitable beneficiaries that will receive the trust's remainder at the end of the trust's term.";
  } else if (isCharitable) {
    header = 'Charitable income beneficiaries';
    description =
      'Charitable beneficiaries that will receive the income payments from the trust.';
  } else if (isRemainder) {
    header = 'Remainder beneficiaries';
    description =
      "Non-charitable beneficiaries that will receive the trust's remainder at the end of the trust’s term.";
  } else {
    header = 'Income';
    description =
      'Beneficiaries that will receive the income payments from the trust.';
  }

  const getPercent = (
    beneficiary: CharitableProposalFragment_BeneficiaryFragment
  ) =>
    first(getNodes(beneficiary.accessParameters))?.percentage || new Decimal(0);

  return (
    <Card variant="filled-light" sx={{ p: 3 }}>
      <Stack spacing={3} divider={<Divider sx={{ color: COLORS.GRAY[300] }} />}>
        <Stack spacing={1} direction="column">
          <Typography variant="h2" color={theme.palette.primary.main}>
            {header}
          </Typography>
          <Typography>{description}</Typography>
        </Stack>
        {(beneficiaries || []).length > 0 ? (
          compact(beneficiaries || []).map((beneficiary, idx) => (
            <TextRow
              key={`beneficiary-${isCharitable}-${isRemainder}-${idx}`}
              label={
                <Typography variant="h3">
                  {beneficiary.organization?.name ||
                    beneficiary.individual?.displayName ||
                    beneficiary.entity?.subtype?.displayName}
                </Typography>
              }
              value={
                <Typography variant="h3">
                  {formatPercent(getPercent(beneficiary), 0)}%
                </Typography>
              }
            />
          ))
        ) : (
          <Box width="100%">
            <Typography variant="subtitle2">None specified</Typography>
          </Box>
        )}
      </Stack>
    </Card>
  );
}

function TextRow({ label, value }: { label: JSX.Element; value: JSX.Element }) {
  return (
    <Stack direction="row" alignItems="baseline" justifyContent="space-between">
      {label}
      {value}
    </Stack>
  );
}
