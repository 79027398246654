import { Divider, Skeleton, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useCallback } from 'react';

import { UnstyledButton } from '@/components/form/baseInputs/Button/UnstyledButton';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { RichListItemRow } from '@/components/lists/RichListItem/RichListItemRow';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { COLORS } from '@/styles/tokens/colors';
import { getNodes } from '@/utils/graphqlUtils';

import { useAssociatedEntitiesModalQuery } from './graphql/AssociatedEntitiesModal.generated';

interface AssociatedEntitiesModalProps {
  isOpen: boolean;
  onClose: () => void;
  entityIds: string[];
}

export function AssociatedEntitiesModal({
  isOpen,
  onClose,
  entityIds,
}: AssociatedEntitiesModalProps) {
  const { householdId } = useHouseholdDetailsContext();
  const { navigate } = useNavigateToRoute();
  const { data, loading } = useAssociatedEntitiesModalQuery({
    variables: {
      entityIds,
    },
    skip: isEmpty(entityIds),
  });

  const handleRowClick = useCallback(
    (entityId: string) => {
      if (!householdId) {
        throw new Error('No household ID provided');
      }
      navigate(ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS, {
        entityId,
        householdId,
      });
    },
    [householdId, navigate]
  );

  return (
    <DialogModal
      isOpen={isOpen}
      onClose={onClose}
      heading={'Asset exists in the following entities'}
      dialogContentSx={{ minHeight: 250 }}
    >
      {loading && (
        <Stack spacing={0}>
          <Skeleton height={70} width="100%" />
          <Skeleton height={70} width="100%" />
          <Skeleton height={70} width="100%" />
          <Skeleton height={70} width="100%" />
        </Stack>
      )}
      {data && (
        <Stack spacing={1} divider={<Divider />}>
          {getNodes(data.entities).map(({ id, subtype }) => (
            <UnstyledButton
              key={id}
              onClick={() => handleRowClick(id)}
              sx={{
                textAlign: 'left',
                ':hover': { backgroundColor: COLORS.FUNCTIONAL.HOVER },
              }}
            >
              <RichListItemRow
                heading={subtype.displayName}
                RightContent={
                  <ChevronRightIcon size={20} color={COLORS.GRAY[400]} />
                }
              />
            </UnstyledButton>
          ))}
        </Stack>
      )}
    </DialogModal>
  );
}
