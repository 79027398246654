import { Button } from '@/components/form/baseInputs/Button';
import { ButtonProps } from '@/components/form/baseInputs/Button/Button';
import { CoinsSwap02Icon } from '@/components/icons/CoinsSwap02Icon';

export function UpdateHoldingsButton(props: ButtonProps) {
  return (
    <Button {...props} startIcon={CoinsSwap02Icon}>
      {props.children || 'Update valuation'}
    </Button>
  );
}
