import Decimal from 'decimal.js';

import { PathsOf } from '@/types/subform';

export enum CashflowIncomeOrExpsense {
  Income = 'income',
  Expense = 'expense',
}

export enum CashflowFrequency {
  OneTime = 'one-time',
  Annual = 'annual',
}

export const END_AT_BOTH_DEATHS_SENTINEL = 'both' as string;

export interface CashflowModalFormShape {
  name: string;
  targetId: string;
  amount: Decimal | null;
  growthRate: Decimal | null;
  frequency: CashflowFrequency;
  startYear: number;
  endAtTermination: boolean;
  endYear: number;
  endAtDeathClientProfileId: string;
  incomeOrExpense: CashflowIncomeOrExpsense;
}

export type CashflowModalFormPaths = PathsOf<CashflowModalFormShape>;

export const CashflowModalDefaultValues: CashflowModalFormShape = {
  name: '',
  targetId: '',
  amount: null,
  growthRate: new Decimal(0),
  frequency: CashflowFrequency.OneTime,
  startYear: new Date().getFullYear(),
  endAtTermination: false,
  endYear: new Date().getFullYear(),
  endAtDeathClientProfileId: END_AT_BOTH_DEATHS_SENTINEL,
  incomeOrExpense: CashflowIncomeOrExpsense.Income,
};
