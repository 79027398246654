import { CSSProperties } from 'react';

export const sidebarElementMainTextStyles: CSSProperties = {
  height: '100%',
  flexGrow: 1,
  textAlign: 'left',
  justifyContent: 'flex-start',
};

export const ACTIVE_SLIDE_BORDER_WIDTH_PX = 8;

export const SIDEBAR_VERTICAL_SPACING = '3px';
