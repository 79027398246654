import { HeaderCard } from '@/components/layout/HeaderCard';
import { EntityTaskType } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import { CompleteDesignSummaryTaskBody } from './taskBodies/CompleteDesignSummary/CompleteDesignSummaryTaskBody';
import { ConfirmAnnuityPaymentsTaskBody } from './taskBodies/ConfirmAnnuityPaymentsTaskBody/ConfirmAnnuityPaymentsTaskBody';
import { ConfirmTrustDetailsTask } from './taskBodies/ConfirmTrustDetailsTaskBody/ConfirmTrustDetailsTask';
import { CreateTrustAccountTaskBody } from './taskBodies/CreateTrustAccountTaskBody/CreateTrustAccountTaskBody';
import { FinalizeImplementationTaskBody } from './taskBodies/FinalizeImplementationTaskBody/FinalizeImplementationTaskBody';
import { ShareDesignSummaryTaskBody } from './taskBodies/ShareDesignSummaryTaskBody/ShareDesignSummaryTaskBody';
import { SubstitutionThresholdsTaskBody } from './taskBodies/SubstitutionThresholdsTaskBody/SubstitutionThresholdsTaskBody';
import { UploadConfirmationOfAssetTransferTaskBody } from './taskBodies/UploadConfirmationOfAssetTransferTaskBody/UploadConfirmationOfAssetTransferTaskBody';
import { UploadTrustDocumentTaskBody } from './taskBodies/UploadTrustDocumentTaskBody/UploadTrustDocumentTaskBody';
import { VerifyInitialFundingTaskBody } from './taskBodies/VerifyInitialFundingTaskBody/VerifyInitialFundingTaskBody';
import { TaskFooter } from './TaskFooter';
import { DenormalizedImplementationTask } from './taskUtils';
import { ExtendedTaskBodyProps } from './types';

function EntityTaskBody({
  task,
  otherTasks,
  footer,
  onCompleteTask,
  onTaskChange,
  getFirstOpenTask,
}: ExtendedTaskBodyProps) {
  switch (task.body.type) {
    case EntityTaskType.GratCompleteDesignSummary: {
      return (
        <CompleteDesignSummaryTaskBody
          footer={footer}
          task={task}
          onCompleteTask={onCompleteTask}
        />
      );
    }
    case EntityTaskType.GratAttorneySelection: {
      return <div>GratAttorneySelection</div>;
    }
    case EntityTaskType.GratConfirmAnnuities: {
      return (
        <ConfirmAnnuityPaymentsTaskBody
          footer={footer}
          task={task}
          onCompleteTask={onCompleteTask}
        />
      );
    }
    case EntityTaskType.GratConfirmFunding: {
      return (
        <VerifyInitialFundingTaskBody
          footer={footer}
          task={task}
          onCompleteTask={onCompleteTask}
        />
      );
    }
    case EntityTaskType.GratConfirmAssetTransfer: {
      return (
        <UploadConfirmationOfAssetTransferTaskBody
          footer={footer}
          task={task}
          onCompleteTask={onCompleteTask}
        />
      );
    }
    case EntityTaskType.GratCreateTrustAccount: {
      return (
        <CreateTrustAccountTaskBody
          footer={footer}
          task={task}
          onCompleteTask={onCompleteTask}
        />
      );
    }
    case EntityTaskType.GratSetSubstitutionThresholds: {
      return (
        <SubstitutionThresholdsTaskBody
          footer={footer}
          task={task}
          onCompleteTask={onCompleteTask}
        />
      );
    }
    case EntityTaskType.GratShareDesignSummary: {
      return (
        <ShareDesignSummaryTaskBody
          footer={footer}
          task={task}
          onCompleteTask={onCompleteTask}
        />
      );
    }
    case EntityTaskType.GratUploadTrustDocument: {
      return (
        <UploadTrustDocumentTaskBody
          footer={footer}
          task={task}
          onCompleteTask={onCompleteTask}
        />
      );
    }
    case EntityTaskType.GratFinalizeImplementation: {
      return (
        <FinalizeImplementationTaskBody
          footer={footer}
          task={task}
          otherTasks={otherTasks}
          onCompleteTask={onCompleteTask}
          onTaskChange={onTaskChange}
          getFirstOpenTask={getFirstOpenTask}
        />
      );
    }
    case EntityTaskType.GratConfirmTrustDetails: {
      return (
        <ConfirmTrustDetailsTask
          footer={footer}
          task={task}
          onCompleteTask={onCompleteTask}
        />
      );
    }
    default: {
      diagnostics.error(
        `Attempting to render unknown task type in Task`,
        new Error(`Failed to render unknown task type ${task.body.type}`),
        {
          taskId: task.id,
        }
      );
      return (
        <div>{`Task of type ${task.body.type} isn't currently supported.`}</div>
      );
    }
  }
}

export interface TaskProps {
  task?: DenormalizedImplementationTask | null;
  otherTasks: DenormalizedImplementationTask[];
  onCompleteTask: () => void;
  getFirstOpenTask: () => DenormalizedImplementationTask | null;
  onTaskChange: (task: DenormalizedImplementationTask) => void;
}

export function EntityTask({
  task,
  otherTasks,
  onCompleteTask,
  getFirstOpenTask,
  onTaskChange,
}: TaskProps) {
  if (!task) {
    return <HeaderCard heading="">Loading...</HeaderCard>;
  }

  return (
    <EntityTaskBody
      onCompleteTask={onCompleteTask}
      task={task}
      getFirstOpenTask={getFirstOpenTask}
      onTaskChange={onTaskChange}
      otherTasks={otherTasks}
      footer={<TaskFooter otherTasks={otherTasks} task={task} />}
    />
  );
}
