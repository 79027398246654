// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MostRecentlyViewedEntityMapQueryVariables = Types.Exact<{
  mostRecentlyViewedEntityMapWhereInput?: Types.InputMaybe<Types.MostRecentlyViewedEntityMapWhereInput>;
}>;


export type MostRecentlyViewedEntityMapQuery = { __typename?: 'Query', currentUser?: { __typename?: 'User', id: string, localMostRecentlyViewedEntityMap?: { __typename?: 'MostRecentlyViewedEntityMap', id?: string | null } | null } | null };


export const MostRecentlyViewedEntityMapDocument = gql`
    query MostRecentlyViewedEntityMap($mostRecentlyViewedEntityMapWhereInput: MostRecentlyViewedEntityMapWhereInput) {
  currentUser {
    id
    localMostRecentlyViewedEntityMap(where: $mostRecentlyViewedEntityMapWhereInput) @client {
      id
    }
  }
}
    `;

/**
 * __useMostRecentlyViewedEntityMapQuery__
 *
 * To run a query within a React component, call `useMostRecentlyViewedEntityMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useMostRecentlyViewedEntityMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMostRecentlyViewedEntityMapQuery({
 *   variables: {
 *      mostRecentlyViewedEntityMapWhereInput: // value for 'mostRecentlyViewedEntityMapWhereInput'
 *   },
 * });
 */
export function useMostRecentlyViewedEntityMapQuery(baseOptions?: Apollo.QueryHookOptions<MostRecentlyViewedEntityMapQuery, MostRecentlyViewedEntityMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MostRecentlyViewedEntityMapQuery, MostRecentlyViewedEntityMapQueryVariables>(MostRecentlyViewedEntityMapDocument, options);
      }
export function useMostRecentlyViewedEntityMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MostRecentlyViewedEntityMapQuery, MostRecentlyViewedEntityMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MostRecentlyViewedEntityMapQuery, MostRecentlyViewedEntityMapQueryVariables>(MostRecentlyViewedEntityMapDocument, options);
        }
export function useMostRecentlyViewedEntityMapSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MostRecentlyViewedEntityMapQuery, MostRecentlyViewedEntityMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MostRecentlyViewedEntityMapQuery, MostRecentlyViewedEntityMapQueryVariables>(MostRecentlyViewedEntityMapDocument, options);
        }
export type MostRecentlyViewedEntityMapQueryHookResult = ReturnType<typeof useMostRecentlyViewedEntityMapQuery>;
export type MostRecentlyViewedEntityMapLazyQueryHookResult = ReturnType<typeof useMostRecentlyViewedEntityMapLazyQuery>;
export type MostRecentlyViewedEntityMapSuspenseQueryHookResult = ReturnType<typeof useMostRecentlyViewedEntityMapSuspenseQuery>;
export type MostRecentlyViewedEntityMapQueryResult = Apollo.QueryResult<MostRecentlyViewedEntityMapQuery, MostRecentlyViewedEntityMapQueryVariables>;
export const Operations = {
  Query: {
    MostRecentlyViewedEntityMap: 'MostRecentlyViewedEntityMap' as const
  }
}