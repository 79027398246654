import { compact, pickBy, uniq } from 'lodash';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLocalStorage } from 'react-use';

import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { useForm } from '@/components/react-hook-form';
import { useWaterfallRouteContext } from '@/modules/entities/contexts/waterfallRoute/waterfallRoute.context';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { EstateWaterfallAssumptionFields } from '../EstateWaterfallAssumptions/types';
import { EstateWaterfallComparisonModalComponents } from './EstateWaterfallComparisonModal.components';
import { EstateWaterfallComparisonModalContextProvider } from './EstateWaterfallComparisonModal.context';
import {
  EstateWaterfallComparisonModalFormShape,
  getDefaultEstateWaterfallComparisonModalValues,
} from './EstateWaterfallComparisonModal.type';
import { mapDataToEstateWaterfallComparisonModal } from './EstateWaterfallComparisonModal.utils';
import {
  EstateWaterfallComparisonModalQuery,
  useEstateWaterfallComparisonModalQuery,
} from './graphql/EstateWaterfallComparisonModal.generated';

export interface EstateWaterfallComparisonModalLegacyProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    waterfallIds: string[],
    assumptions: EstateWaterfallAssumptionFields
  ) => void;
}

export function EstateWaterfallComparisonModalLegacy({
  isOpen,
  onClose,
  onSubmit: onSubmitExternal,
}: EstateWaterfallComparisonModalLegacyProps) {
  const { waterfallId } = useWaterfallRouteContext();
  const { householdId } = useHouseholdDetailsContext();

  const formMethods = useForm<EstateWaterfallComparisonModalFormShape>({
    defaultValues: getDefaultEstateWaterfallComparisonModalValues(waterfallId),
  });

  const { handleSubmit, reset } = formMethods;
  const [localStorageWaterfallIds, setLocalStorageWaterfallIds] =
    useLocalStorage<string[]>(`comparisonWaterfallIds-${householdId}`, [], {
      raw: false,
      serializer: (value: string[]) => value.join(','),
      deserializer: (value: string) => value.split(','),
    });

  const onCompleted = useCallback(
    (data: EstateWaterfallComparisonModalQuery) => {
      const newFormData = mapDataToEstateWaterfallComparisonModal(
        data,
        waterfallId,
        localStorageWaterfallIds || []
      );

      if (newFormData) {
        reset(newFormData, { keepDefaultValues: false });
      }
    },
    [localStorageWaterfallIds, reset, waterfallId]
  );

  const { loading: isLoadingDefaultValues } =
    useEstateWaterfallComparisonModalQuery({
      variables: {
        where: {
          id: waterfallId,
        },
      },
      skip: !waterfallId,
      notifyOnNetworkStatusChange: true,
      onCompleted,
    });

  const onSubmit = handleSubmit((data) => {
    const waterfallList = uniq(
      compact([
        data.waterfallA || '',
        data.waterfallB || '',
        data.waterfallC || '',
        data.waterfallD || '',
      ])
    );
    setLocalStorageWaterfallIds(waterfallList);
    onSubmitExternal(
      waterfallList,
      pickBy(data, (_, key) =>
        [
          'firstGrantorDeathYear',
          'secondGrantorDeathYear',
          'assetGrowthReturn',
          'exemptionGrowthRate',
          'willExemptionSunset',
          'useCustomGlobalGrowthRate',
        ].includes(key)
      )
    );
  });

  const isLoading = isLoadingDefaultValues;
  return (
    <EstateWaterfallComparisonModalContextProvider
      householdId={householdId}
      waterfallId={waterfallId}
    >
      <FormFieldsDisabledProvider isDisabled={isLoading}>
        <FormModal
          isOpen={isOpen}
          onClose={onClose}
          heading="Compare waterfalls"
          actions={
            <FormModalActions.Provider>
              <FormModalActions.CancelButton onClick={onClose} />
              <FormModalActions.SaveButton onClick={onSubmit}>
                Compare
              </FormModalActions.SaveButton>
            </FormModalActions.Provider>
          }
        >
          <FormProvider {...formMethods}>
            <EstateWaterfallComparisonModalComponents.Waterfall
              label="Waterfall A"
              waterfallKey="waterfallA"
            />
            <EstateWaterfallComparisonModalComponents.Waterfall
              label="Waterfall B"
              waterfallKey="waterfallB"
            />
            <EstateWaterfallComparisonModalComponents.Waterfall
              label="Waterfall C"
              waterfallKey="waterfallC"
            />
            <EstateWaterfallComparisonModalComponents.Waterfall
              label="Waterfall D"
              waterfallKey="waterfallD"
            />
            <EstateWaterfallComparisonModalComponents.Assumptions />
          </FormProvider>
        </FormModal>
      </FormFieldsDisabledProvider>
    </EstateWaterfallComparisonModalContextProvider>
  );
}
