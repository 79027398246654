import { Box, Stack, Typography } from '@mui/material';
import { Control } from 'react-hook-form';

import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';

import { FirmBrandingFormShape } from './AdminBrandingForm.types';

function validateColorFormat(value: string | undefined | null) {
  if (!value) {
    return undefined;
  }

  const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return hexRegex.test(value) ? undefined : 'Color must be hex (e.g. #FFFFFF)';
}

export interface BrandingColorInputProps {
  control: Control<FirmBrandingFormShape>;
  heading: React.ReactNode;
  subheading: React.ReactNode;
  fieldName: keyof FirmBrandingFormShape;
  colorValue: string;
}

export function BrandingColorInput({
  heading,
  subheading,
  fieldName,
  control,
  colorValue,
}: BrandingColorInputProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={2}
    >
      <Stack
        width={`calc(100% - 100px)`}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={1}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: colorValue,
            minHeight: theme.spacing(6),
            minWidth: theme.spacing(6),
            borderRadius: theme.spacing(6),
          })}
        />
        <Box>
          <Typography variant="h5" component="div">
            {heading}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {subheading}
          </Typography>
        </Box>
      </Stack>
      <Box maxWidth={100}>
        <FormAwareTextInput
          validation={{
            format: validateColorFormat,
          }}
          required
          fieldName={fieldName}
          control={control}
          hideLabel
          label="Color"
        />
      </Box>
    </Stack>
  );
}
