import { GrowthProfilesTable_GrowthProfileFragment } from './graphql/GrowthProfilesTable.generated';

export enum GrowthProfilesTableColumns {
  NAME = 'displayName',
  EXEMPTION_GROWTH_RATE = 'exemptionGrowthRate',
  CREATED_BY = 'user',
  ACTIONS = 'actions',
}

export interface RowData extends GrowthProfilesTable_GrowthProfileFragment {
  action?: string;
}
