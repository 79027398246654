import { useCallback } from 'react';

import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { DocumentSearchResult } from '@/modules/pdf/DocumentSearch/DocumentSearch.types';
import { useSearchDocumentWithResponseLazyQuery } from '@/modules/pdf/DocumentSearch/graphql/DocumentSearch.generated';

interface UseMultiDocumentSearchReturn {
  doMultiDocSearch: (
    query: string,
    documentIds: string[]
  ) => Promise<DocumentSearchResult[]>;
}

/**
 * A hook that provides a function to search for a given query across the given
 * document ids.
 *
 * The function takes care of tracking the user event on success/failure, but is
 * up to the caller to handle the actual results and show/report error feedback.
 */
export function useMultiDocumentSearch(): UseMultiDocumentSearchReturn {
  const trackUserEvent = useTrackUserEvent();

  const [searchDocumentQuery] = useSearchDocumentWithResponseLazyQuery();

  const doMultiDocSearch = useCallback(
    async (query: string, documentIds: string[]) => {
      const startTime = Date.now();

      try {
        const boundingRegionsResults = await Promise.all(
          documentIds.map<Promise<DocumentSearchResult[]>>(async (docID) => {
            const { data } = await searchDocumentQuery({
              variables: {
                input: {
                  documentID: docID,
                  query: query,
                  first: 1,
                  skipGeneratedResponse: true,
                },
              },
            });

            return (
              data?.queryDocument?.boundingRegions || []
            ).map<DocumentSearchResult>((r) => ({
              ...r,
              documentID: docID,
            }));
          })
        );

        trackUserEvent('document searched', {
          query: query,
          multiDocSearch: true,
          success: true,
          duration: Date.now() - startTime,
        });

        return boundingRegionsResults.flatMap((br) => br);
      } catch (e) {
        trackUserEvent('document searched', {
          query: query,
          multiDocSearch: true,
          success: false,
          duration: Date.now() - startTime,
        });
        throw e;
      }
    },
    [searchDocumentQuery, trackUserEvent]
  );

  return {
    doMultiDocSearch,
  };
}
