import { useEffect } from 'react';

import { useFlowChartContext } from '../../context/flowChart.context';
import { useIsInteractive } from '../useIsInteractive';

/**
 * @description Resets our dirty state to false when isInteractive flips back to non-interactive
 * Dirty state is set to true by useInternalOnNodesChange.ts
 */
export function useResetIsDirty() {
  const { isInteractive } = useIsInteractive();
  const {
    isDirty,
    __internal: { setIsDirty },
  } = useFlowChartContext();

  useEffect(() => {
    if (!isInteractive && isDirty) {
      setIsDirty(false);
    }
  }, [isDirty, isInteractive, setIsDirty]);
}
