import { Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { compact } from 'lodash';
import React from 'react';
import { Control, useWatch } from 'react-hook-form';

import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { useFormContext } from '@/components/react-hook-form';
import {
  DisplayTable,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import {
  BASIC_INFORMATION_SUBFORM_NAMESPACE as BASIC_INFORMATION_SUBFORM,
  SubformField as BasicInformationSubformField,
} from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import { SubformsCombinedType } from '@/modules/entities/EntitySubforms/EntitySubforms.types';
import { COLORS } from '@/styles/tokens/colors';
import { getComparableDecimalJS } from '@/utils/decimalJSUtils';

import { GRAT_ANNUITIES_SUPPORTED_DECIMAL_PLACES } from '../../gratAnnuities.constants';
import {
  GetGratDetailsQuery,
  useGetGratDetailsQuery,
} from '../graphql/GetGratDetails.generated';
import { useQueryAnnuitiesDetails } from '../hooks/useQueryAnnuitiesDetails';
import { useSyncAnnuitiesDataToForm } from '../hooks/useSyncAnnuitiesDataToForm';
import {
  Fields,
  NAMESPACE,
  SubformComponentProps,
  SubformField,
} from '../types';

enum RowInputModes {
  PAYMENT_AMOUNT = 'PAYMENT_AMOUNT',
  PAYMENT_PERCENTAGE = 'PAYMENT_PERCENTAGE',
}

export function getEntityFromNode(data?: GetGratDetailsQuery) {
  if (data?.node?.__typename !== 'Entity') return null;
  return data.node.gratTrust;
}

export function GRATAnnuitiesSubformComponent({
  variant,
  subformValues,
  disabled,
  termDurationYears,
  initialFundingValue,
  loading,
}: SubformComponentProps) {
  const { control, setValue } = useFormContext<Fields>();
  const [currentInputRow, setCurrentInputRow] = React.useState<number>(0);
  const [rowInputMode, setRowInputMode] = React.useState<RowInputModes>(
    RowInputModes.PAYMENT_PERCENTAGE
  );

  const rowValues = subformValues.annuities;
  const currentRowValue = rowValues[currentInputRow];
  const syncPaymentPercentageToAmount = React.useCallback(
    (
      inputIndex: number,
      paymentPercentage: Decimal | null, // can be null if the input is empty
      initialFundingValue: Decimal
    ) => {
      const calculatedPaymentAmountValue = initialFundingValue.times(
        (paymentPercentage ?? new Decimal(0)).dividedBy(100)
      );

      setValue(
        `${NAMESPACE}.annuities.${Number(
          inputIndex
        )}.paymentAmount` as const satisfies SubformField,
        // TODO FIX THIS
        // unfortunately, there's no great way to create a strongly-typed interface for this
        // that asserts that there's a key of this type and that the fieldName that's
        // passed in actually maps correctly to that type. they're working on making a solution, though:
        // https://github.com/react-hook-form/react-hook-form/issues/9748
        calculatedPaymentAmountValue
      );
    },
    [setValue]
  );

  React.useEffect(
    function handleInitialSync() {
      if (rowValues.length === 0 || !initialFundingValue) return;
      rowValues.forEach((rowValue, i) => {
        syncPaymentPercentageToAmount(
          i,
          rowValue.paymentPercentage,
          initialFundingValue
        );
      });
    },
    // explicitly only want this to run when rows are instantiated, and not continually
    // as rowValues change
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      rowValues.length,
      getComparableDecimalJS(
        initialFundingValue || new Decimal(0),
        GRAT_ANNUITIES_SUPPORTED_DECIMAL_PLACES
      ),
      syncPaymentPercentageToAmount,
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  React.useEffect(
    function handleInputModeComputation() {
      if (
        !initialFundingValue ||
        !termDurationYears ||
        !currentRowValue?.paymentPercentage
      ) {
        return;
      }
      // users can either:
      // 1) enter the value by the payment amount, in which case we calculate the corresponding
      //    percentage of the initial funding value, or
      // 2) enter the value by the percentage of the initial funding value, in which case we
      //    calculate the corresponding payment amount.
      //
      // either way, we'll persist the actual percentage of the initial funding value because it's the most specific, but allowing
      // both input modes makes it easier to reason about.
      if (rowInputMode === RowInputModes.PAYMENT_PERCENTAGE) {
        syncPaymentPercentageToAmount(
          currentInputRow,
          currentRowValue.paymentPercentage,
          initialFundingValue
        );
      } else if (rowInputMode === RowInputModes.PAYMENT_AMOUNT) {
        const calculatedPaymentPercentageValue = currentRowValue.paymentAmount
          .dividedBy(initialFundingValue)
          .times(100);
        // TODO FIX THIS
        // unfortunately, there's no great way to create a strongly-typed interface for this
        // that asserts that there's a key of this type and that the fieldName that's
        // passed in actually maps correctly to that type. they're working on making a solution, though:
        // https://github.com/react-hook-form/react-hook-form/issues/9748
        setValue(
          `${NAMESPACE}.annuities.${currentInputRow}.paymentPercentage` as const satisfies SubformField,
          calculatedPaymentPercentageValue
        );
      }
    },
    // disabling the exhaustive deps because doing comparisons on complex objects causes the hook to incorrectly rerender,
    // and the lint rule isn't smart enough to be able to understand this via static analysis
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      currentInputRow,
      rowInputMode,
      getComparableDecimalJS(
        currentRowValue?.paymentPercentage ?? new Decimal(0),
        GRAT_ANNUITIES_SUPPORTED_DECIMAL_PLACES
      ),
      getComparableDecimalJS(
        currentRowValue?.paymentAmount ?? new Decimal(0),
        GRAT_ANNUITIES_SUPPORTED_DECIMAL_PLACES
      ),
      getComparableDecimalJS(
        initialFundingValue ?? new Decimal(0),
        GRAT_ANNUITIES_SUPPORTED_DECIMAL_PLACES
      ),
      termDurationYears,
      setValue,
      syncPaymentPercentageToAmount,
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  function setInputModeForRow(rowIndex: number, inputMode: RowInputModes) {
    setCurrentInputRow(rowIndex);
    setRowInputMode(inputMode);
  }

  return (
    <DisplayTable
      columns={compact([
        { headerName: 'Year', width: '50%' },
        variant === 'initialFundingPercentAndPaymentAmount' && {
          headerName: 'Annuity payment amount',
        },
        { headerName: '% of initial funding value' },
      ])}
    >
      {rowValues.map((_, i) => {
        return (
          <StyledTableRow key={i}>
            <StyledTableCell>
              <Typography
                variant="h5"
                component="span"
                fontWeight={700}
                color={COLORS.NAVY[900]}
              >
                Year {i + 1}
              </Typography>
            </StyledTableCell>
            {variant === 'initialFundingPercentAndPaymentAmount' && (
              <StyledTableCell>
                <FormAwareCurrencyInput<Fields>
                  control={control}
                  fieldName={`${NAMESPACE}.annuities.${i}.paymentAmount`}
                  disabled={disabled || loading}
                  onFocus={() =>
                    setInputModeForRow(i, RowInputModes.PAYMENT_AMOUNT)
                  }
                  label=""
                  isDecimalJSInput
                />
              </StyledTableCell>
            )}
            <StyledTableCell>
              <FormAwarePercentInput<Fields>
                control={control}
                fieldName={`${NAMESPACE}.annuities.${i}.paymentPercentage`}
                disabled={disabled || loading}
                label=""
                onFocus={() =>
                  setInputModeForRow(i, RowInputModes.PAYMENT_PERCENTAGE)
                }
                decimalScale={GRAT_ANNUITIES_SUPPORTED_DECIMAL_PLACES}
                fixedDecimalScale={true}
                isDecimalJSInput
              />
            </StyledTableCell>
          </StyledTableRow>
        );
      })}
    </DisplayTable>
  );
}

export function EntityFormGRATAnnuitiesSubformComponent({
  variant,
  subformValues,
  entityId,
  householdId,
}: SubformComponentProps) {
  const { control, setValue } = useFormContext<Fields>();

  const { data } = useGetGratDetailsQuery({
    variables: {
      nodeId: entityId ?? '',
    },
  });

  // Watch the term length so that we can update the annuities table when it changes
  const termLength = useWatch({
    name: `${BASIC_INFORMATION_SUBFORM}.termLength` as const satisfies BasicInformationSubformField,
    control: control as unknown as Control<SubformsCombinedType>,
  });

  // Watch the taxable gift so that we can update the annuities table when it changes
  const taxableGift = useWatch({
    name: `${BASIC_INFORMATION_SUBFORM}.taxableGift` as const satisfies BasicInformationSubformField,
    control: control as unknown as Control<SubformsCombinedType>,
  });

  const grat = getEntityFromNode(data);

  const termDurationYears = React.useMemo(() => {
    if (termLength && typeof termLength === 'number') {
      return termLength;
    } else {
      return grat?.termDurationYears || 0;
    }
  }, [termLength, grat]);

  // Get the initial annuities data and the annuities data that reacts to changes in the term length
  const { annuitiesData: initialAnnuitiesData, reactiveAnnuitiesData } =
    useQueryAnnuitiesDetails(grat, termDurationYears, taxableGift);

  const annuitiesData = reactiveAnnuitiesData || initialAnnuitiesData;

  useSyncAnnuitiesDataToForm(
    setValue,
    annuitiesData,
    grat ?? null,
    termDurationYears,
    taxableGift
  );

  return (
    <GRATAnnuitiesSubformComponent
      variant={variant}
      subformValues={subformValues}
      termDurationYears={termDurationYears}
      initialFundingValue={grat?.initialFundingValue ?? new Decimal(0)}
      householdId={householdId}
    />
  );
}
