import { PropsWithChildren, useCallback, useMemo } from 'react';

import { useEffectReducer } from '@/hooks/useEffectReducer';

import { useEffectsMap } from '../effects/useEffectsMap.hook';
import { useFamilyTreeReducer } from '../reducers/familyTree.reducer';
import { generateDefaultState } from '../state/generateDefaultState';
import {
  FamilyTreeAction,
  FamilyTreeEffect,
  FamilyTreeInitialStateGetter,
  FamilyTreeState,
  FamilyTreeStateProps,
} from '../types';
import { FamilyTreeContext } from './familyTree.context';

function useFamilyTreeContextValue({
  householdId,
}: FamilyTreeStateProps): FamilyTreeContext {
  const reducer = useFamilyTreeReducer();

  const effectsMap = useEffectsMap();

  const getInitialState = useCallback<FamilyTreeInitialStateGetter>(
    (_queueEffect) => {
      return generateDefaultState({ householdId });
    },
    [householdId]
  );

  const [state, dispatch] = useEffectReducer<
    FamilyTreeState,
    FamilyTreeAction,
    FamilyTreeEffect
  >(reducer, getInitialState, effectsMap);

  return useMemo(() => ({ state, dispatch }), [state, dispatch]);
}

export interface FamilyTreeProviderProps extends FamilyTreeStateProps {
  children: JSX.Element;
}

export const FamilyTreeProvider = ({
  children,
  ...props
}: PropsWithChildren<FamilyTreeProviderProps>) => {
  const value = useFamilyTreeContextValue(props);
  return (
    <FamilyTreeContext.Provider value={value}>
      {children}
    </FamilyTreeContext.Provider>
  );
};
