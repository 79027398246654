import { Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useUpdateHouseholdMutation } from '@/modules/household/graphql/Households.generated';
import { HouseholdBillableKind, UpdateHouseholdInput } from '@/types/schema';

import {
  HouseholdBillingFormShape,
  useSyncHouseholdDataToForm,
} from './HouseholdAdminDangerousActions.utils';

export interface HouseholdAdminBillingActionsProps {
  householdId: string;
}

export function HouseholdAdminBillingActions({
  householdId,
}: HouseholdAdminBillingActionsProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [updateClient] = useUpdateHouseholdMutation({
    onError: (err) => {
      showFeedback('Failed to update client billing');
      reportError('could not update household billing', err);
    },
    onCompleted: () =>
      showFeedback('Client billing updated', { variant: 'success' }),
  });

  const formMethods = useForm<HouseholdBillingFormShape>({
    defaultValues: {
      billableKind: HouseholdBillableKind.Billable,
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting: submittingForm },
  } = formMethods;

  const { loading: dataLoading } = useSyncHouseholdDataToForm(
    reset,
    householdId
  );

  async function onSubmit(formValues: HouseholdBillingFormShape) {
    const updateInput: UpdateHouseholdInput = {
      billableKind: formValues.billableKind,
    };
    return updateClient({
      variables: {
        id: householdId,
        input: updateInput,
      },
    });
  }

  const disableInputs = submittingForm || dataLoading;

  return (
    <FormProvider {...formMethods}>
      <Stack
        spacing={2}
        noValidate
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormAwareSelectInput<HouseholdBillingFormShape>
          label="Billing type"
          fieldName="billableKind"
          required
          disabled={disableInputs}
          control={control}
          options={[
            {
              display: 'Billable (full client)',
              value: HouseholdBillableKind.Billable,
            },
            {
              display: 'Prospect (no billing, but monitored)',
              value: HouseholdBillableKind.Prospect,
            },
            {
              display: 'Non-billable (sample or duplicate client)',
              value: HouseholdBillableKind.NonBillable,
            },
          ]}
        />

        <Button
          loading={submittingForm}
          variant="primary"
          size="md"
          type="submit"
        >
          Update billing
        </Button>
      </Stack>
    </FormProvider>
  );
}
