import { useCallback } from 'react';
import {
  Control,
  FieldArrayPath,
  FieldValues,
  useFieldArray,
} from 'react-hook-form';

import { SubformAsset } from '../AssetsSubform/types';
import { Asset } from '../types/asset';
import { getValidAssets } from '../utils';
import { AssetDetailsModalProps } from './AssetDetailsModal';

// unfortunately, there's no great way to create a strongly-typed interface for this
// that asserts that there's a key of type `SubformAsset[]` and that the fieldName that's
// passed in actually maps correctly to that type. they're working on making a solution, though:
// https://github.com/react-hook-form/react-hook-form/issues/9748
export function useAssetsFieldArray<FormShape extends FieldValues>(
  fieldName: FieldArrayPath<FormShape>,
  control: Control<FormShape>,
  assets: SubformAsset[]
) {
  const { append: appendToAssets, update: updateAsset } = useFieldArray<
    FormShape,
    typeof fieldName,
    'id'
  >({
    control,
    name: fieldName,
    rules: {
      validate: {
        required: (value) => {
          return getValidAssets(value as SubformAsset[]).length > 0
            ? undefined
            : 'You need to add at least one asset.';
        },
      },
    },
  });

  const removeByAsset = useCallback(
    (value: Asset) => {
      const assetIndex = assets.findIndex((asset) => asset.id === value.id);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- linter refactor
      updateAsset(assetIndex, {
        ...value,
        doDelete: true,
        confirmed: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- linter refactor
      } as any);
    },
    [assets, updateAsset]
  );

  const updateByAsset = useCallback(
    (value: Asset) => {
      const assetIndex = assets.findIndex((asset) => asset.id === value.id);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- linter refactor
      updateAsset(assetIndex, {
        ...value,
        confirmed: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- linter refactor
      } as any);
    },
    [assets, updateAsset]
  );

  const handleAssetsModalSubmit: AssetDetailsModalProps['onSubmit'] = (
    value,
    { isNewAsset, isRemoveAsset }
  ) => {
    // remove becomes first because there's a case where we're removing a new asset
    if (isRemoveAsset) {
      removeByAsset(value);
    } else if (isNewAsset) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- linter refactor
      appendToAssets({
        ...value,
        confirmed: true,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- linter refactor
      } as any);
    }
  };

  return {
    handleAssetsModalSubmit,
    updateByAsset,
    removeByAsset,
    appendToAssets,
  };
}
