import { GridColDef } from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import { compact, includes } from 'lodash';
import { ReactNode } from 'react';

import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { PopperContent } from '@/components/poppers/PopperContent';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { ContextualHelpHeaderRenderer } from '@/components/tables/DataTable/renderers/header/ContextualHelpHeaderRenderer';
import { entityKindToDisplayName } from '@/modules/entities/EntityForm/utils';
import {
  EntityKind,
  EntityStage,
  GrantsToCharitableOrganizations,
} from '@/types/schema';
import { formatCurrencyNoDecimalsAccounting } from '@/utils/formatting/currency';

import {
  ClientValuePerformanceDashboard_ClientOrganizationFragment,
  ClientValuePerformanceDashboard_EntityFragment,
  ClientValuePerformanceDashboard_HouseholdFragment,
} from './graphql/ClientValuePerformanceDashboard.generated';

export interface Row {
  id: string;
  entityId?: string;
  hierarchy: string[];
  wealthTransferred: string;
  outOfEstateGrowth: string;
  impliedEstateTaxSavings: string;
  line1: string;
  line2?: string;
  shouldShowBold?: boolean;
  entityKind?: EntityKind;
  nameRightContent?: ReactNode;
}

const rootColumn: Partial<GridColDef> = {
  align: 'right',
  headerAlign: 'right',
  disableColumnMenu: true,
  disableReorder: true,
  sortable: false,
};

const baseColumnInteractive: Partial<GridColDef> = {
  ...rootColumn,
  flex: 1,
};

const baseColumnPrint: Partial<GridColDef> = {
  ...rootColumn,
  width: 150,
};

const CellRenderer = TextRenderer<Row>({
  textProps: ({ row }) => ({
    sx: {
      fontWeight: row.shouldShowBold ? 'bold' : 'auto',
      '&:has(.textRendererWrapper)': {
        border: 'none !important',
      },
    },
  }),
});

export function getClientValuePerformanceDashboardColumns(
  isViewOnly: boolean
): GridColDef[] {
  const baseColumn = isViewOnly ? baseColumnPrint : baseColumnInteractive;
  return [
    {
      ...baseColumn,
      field: 'wealthTransferred',
      headerName: 'Net entity value',
      renderCell: CellRenderer,
    },
    {
      ...baseColumn,
      field: 'outOfEstateGrowth',
      renderHeader: ContextualHelpHeaderRenderer({
        contextualHelp: isViewOnly ? undefined : (
          <PopperContent
            body={
              <>
                <p>
                  Growth out of the estate is defined as the current entity
                  value, netting out any distributions and contributions made to
                  the entity over time.
                </p>
                <p>
                  This value may be overstated if no transactions have been
                  logged on the entity.
                </p>
              </>
            }
          />
        ),
        header: 'Growth outside of the estate',
      }),
      renderCell: CellRenderer,
    },
    {
      ...baseColumn,
      field: 'impliedEstateTaxSavings',
      headerName: 'Implied estate tax savings',
      renderCell: CellRenderer,
    },
  ];
}

export const HEADING_FAMILY_GIVING = 'Family giving';
const HEADING_FAMILY_GIVING_DIRECT_GIFTS = 'Direct gifts';
const HEADING_FAMILY_GIVING_IRREV_TRUSTS = 'Irrevocable trusts';
const HEADING_FAMILY_GIVING_GRAT_TRUSTS = 'GRATs';
export const HEADING_CHARITABLE_GIVING = 'Charitable giving';

const getCurrencyDisplay = (
  arg: Decimal,
  opts?: { showZero: boolean }
): string => {
  if (arg.toNumber() === 0 && !opts?.showZero) {
    return '';
  }
  return formatCurrencyNoDecimalsAccounting(arg);
};

function mapEntityArrayToRowData(
  entities: ClientValuePerformanceDashboard_EntityFragment[],
  hierarchy: string[]
): {
  rows: Row[];
  wealthTransferred: Decimal;
  outOfEstateGrowth: Decimal;
  impliedEstateTaxSavings: Decimal;
} {
  let wealthTransferred = new Decimal(0);
  let outOfEstateGrowth = new Decimal(0);
  let impliedEstateTaxSavings = new Decimal(0);

  const rows: Row[] = [];
  entities.forEach(
    (trust: ClientValuePerformanceDashboard_EntityFragment, i: number) => {
      if (
        trust.subtype.__typename === 'IrrevocableTrust' ||
        trust.subtype.__typename === 'SLATTrust' ||
        trust.subtype.__typename === 'GRATTrust'
      ) {
        const row: Row = {
          id: `${trust.id}-${i}`,
          hierarchy: [...hierarchy, trust.id],
          entityId: trust.id,
          wealthTransferred: getCurrencyDisplay(
            trust.subtype.performanceReport.wealthTransferred,
            { showZero: true }
          ),
          outOfEstateGrowth: getCurrencyDisplay(
            trust.subtype.performanceReport.outOfEstateGrowth,
            { showZero: true }
          ),
          impliedEstateTaxSavings: getCurrencyDisplay(
            trust.subtype.performanceReport.impliedEstateTaxSavings,
            { showZero: true }
          ),
          line1: trust.subtype.displayName,
          line2: entityKindToDisplayName(trust.kind),
          entityKind: trust.kind,
        };

        if (
          trust.subtype.__typename === 'GRATTrust' &&
          trust.stage === EntityStage.Completed
        ) {
          row.nameRightContent = (
            <Badge variant={BadgeVariants.Teal} display="Completed" />
          );

          row.wealthTransferred = getCurrencyDisplay(
            trust.subtype.performanceReport.wealthTransferred,
            { showZero: true }
          );
          row.outOfEstateGrowth = getCurrencyDisplay(
            trust.subtype.performanceReport.outOfEstateGrowth,
            { showZero: true }
          );
          row.impliedEstateTaxSavings = getCurrencyDisplay(
            trust.subtype.performanceReport.impliedEstateTaxSavings,
            { showZero: true }
          );
        }

        rows.push(row);

        wealthTransferred = wealthTransferred.plus(
          trust.subtype.performanceReport.wealthTransferred
        );
        outOfEstateGrowth = outOfEstateGrowth.plus(
          trust.subtype.performanceReport.outOfEstateGrowth
        );
        impliedEstateTaxSavings = impliedEstateTaxSavings.plus(
          trust.subtype.performanceReport.impliedEstateTaxSavings
        );
      }
    }
  );

  return {
    rows,
    wealthTransferred,
    outOfEstateGrowth,
    impliedEstateTaxSavings,
  };
}

const mapOrganizationGrantToRows = (
  grants: GrantsToCharitableOrganizations[],
  organizationId: string
): Row[] =>
  grants
    .filter((grant) => grant.wealthTransferred.toNumber() > 0)
    .map<Row>((grant, index) => {
      const grantId = `${grant.grantingEntityName}-${index}`;
      return {
        id: grantId,
        hierarchy: [HEADING_CHARITABLE_GIVING, organizationId, grantId],
        line1: `Grants from ${grant.grantingEntityName}`,
        wealthTransferred: getCurrencyDisplay(grant.wealthTransferred),
        outOfEstateGrowth: '',
        impliedEstateTaxSavings: '',
        entityId: grant.entityID,
      };
    });

function mapClientOrganizationToData({
  id: organizationId,
  performanceReport,
  name,
}: ClientValuePerformanceDashboard_ClientOrganizationFragment): Row[] {
  const dafRows = mapOrganizationGrantToRows(
    performanceReport.grantsFromDAFs || [],
    organizationId
  );

  const pfRows = mapOrganizationGrantToRows(
    performanceReport.grantsFromPrivateFoundations || [],
    organizationId
  );

  const directGiftRow: Row[] = [];
  if (performanceReport.directGifts.greaterThan(0)) {
    const directGiftId = organizationId + `-directGift`;
    directGiftRow.push({
      id: directGiftId,
      hierarchy: [HEADING_CHARITABLE_GIVING, organizationId, directGiftId],
      line1: 'Direct charitable gifts',
      wealthTransferred: getCurrencyDisplay(performanceReport.directGifts),
      outOfEstateGrowth: '',
      impliedEstateTaxSavings: '',
    });
  }

  const rows = [...dafRows, ...pfRows, ...directGiftRow];

  return rows.length
    ? [
        {
          id: organizationId,
          hierarchy: [HEADING_CHARITABLE_GIVING, organizationId],
          line1: name,
          wealthTransferred: getCurrencyDisplay(
            performanceReport.totalWealthTransferred
          ),
          outOfEstateGrowth: '',
          impliedEstateTaxSavings: '',
          shouldShowBold: true,
        },
        ...rows,
      ]
    : [];
}

export function mapAdvisorClientToValueDashboardRows(
  household?: ClientValuePerformanceDashboard_HouseholdFragment | null
): {
  rows: Row[];
  familyWealthTransferred: Decimal;
  charityWealthTransferred: Decimal;
} {
  let familyWealthTransferred = new Decimal(0);
  let outOfEstateGrowth = new Decimal(0);
  let impliedEstateTaxSavings = new Decimal(0);
  let charityWealthTransferred = new Decimal(0);

  let rows: Row[] = [];

  if (household?.possibleBeneficiariesV2?.clients.length) {
    let directGivingWealthTransferred = new Decimal(0);
    const directGivingRows: Row[] = [];

    household?.possibleBeneficiariesV2?.clients
      .filter((beneficiary) => {
        return beneficiary.performanceReportGifts.wealthTransferred.greaterThan(
          0
        );
      })
      .forEach((beneficiary, i) => {
        directGivingWealthTransferred = directGivingWealthTransferred.plus(
          beneficiary.performanceReportGifts.wealthTransferred
        );
        directGivingRows.push({
          id: `${beneficiary.id}-${i}`,
          hierarchy: [
            HEADING_FAMILY_GIVING,
            HEADING_FAMILY_GIVING_DIRECT_GIFTS,
            beneficiary.id,
          ],
          wealthTransferred: formatCurrencyNoDecimalsAccounting(
            beneficiary.performanceReportGifts.wealthTransferred
          ),
          outOfEstateGrowth: '',
          impliedEstateTaxSavings: '',
          line1: beneficiary.legalName,
        });
      });

    if (directGivingRows.length) {
      rows = rows.concat(
        [
          {
            id: HEADING_FAMILY_GIVING_DIRECT_GIFTS,
            hierarchy: [
              HEADING_FAMILY_GIVING,
              HEADING_FAMILY_GIVING_DIRECT_GIFTS,
            ],
            wealthTransferred: formatCurrencyNoDecimalsAccounting(
              directGivingWealthTransferred
            ),
            outOfEstateGrowth: '',
            impliedEstateTaxSavings: '',
            line1: HEADING_FAMILY_GIVING_DIRECT_GIFTS,
            shouldShowBold: true,
          },
        ],
        directGivingRows
      );
    }
    familyWealthTransferred = familyWealthTransferred.plus(
      directGivingWealthTransferred
    );
  }

  // casting the type because reportingEntities is a `node` type, so doesn't naturally pass through the type guard
  const entityNodes = compact(
    household?.reportingEntities
  ) as ClientValuePerformanceDashboard_EntityFragment[];
  const irrevTrustGroup = entityNodes.filter((entity) =>
    includes([EntityKind.IrrevocableTrust, EntityKind.SlatTrust], entity.kind)
  );

  if (irrevTrustGroup.length) {
    const {
      rows: irrevTrustRows,
      wealthTransferred: irrevTrustGroupWealthTransferred,
      outOfEstateGrowth: irrevTrustGroupOutOfEstateGrowth,
      impliedEstateTaxSavings: irrevTrustGroupImpliedEstateTaxSavings,
    } = mapEntityArrayToRowData(irrevTrustGroup, [
      HEADING_FAMILY_GIVING,
      HEADING_FAMILY_GIVING_IRREV_TRUSTS,
    ]);

    if (irrevTrustRows.length) {
      rows = [
        ...rows,
        {
          id: HEADING_FAMILY_GIVING_IRREV_TRUSTS,
          hierarchy: [
            HEADING_FAMILY_GIVING,
            HEADING_FAMILY_GIVING_IRREV_TRUSTS,
          ],
          wealthTransferred: getCurrencyDisplay(
            irrevTrustGroupWealthTransferred,
            { showZero: true }
          ),
          outOfEstateGrowth: getCurrencyDisplay(
            irrevTrustGroupOutOfEstateGrowth,
            { showZero: true }
          ),
          impliedEstateTaxSavings: getCurrencyDisplay(
            irrevTrustGroupImpliedEstateTaxSavings,
            { showZero: true }
          ),
          line1: HEADING_FAMILY_GIVING_IRREV_TRUSTS,
          shouldShowBold: true,
        },
        ...irrevTrustRows,
      ];

      familyWealthTransferred = familyWealthTransferred.plus(
        irrevTrustGroupWealthTransferred
      );
      outOfEstateGrowth = outOfEstateGrowth.plus(
        irrevTrustGroupOutOfEstateGrowth
      );
      impliedEstateTaxSavings = impliedEstateTaxSavings.plus(
        irrevTrustGroupImpliedEstateTaxSavings
      );
    }
  }

  const gratTrustGroup = entityNodes.filter(
    (entity) => EntityKind.GratTrust === entity.kind
  );

  if (gratTrustGroup.length) {
    const {
      rows: gratTrustRows,
      wealthTransferred: gratTrustGroupWealthTransferred,
      outOfEstateGrowth: gratTrustGroupOutOfEstateGrowth,
      impliedEstateTaxSavings: gratTrustGroupImpliedEstateTaxSavings,
    } = mapEntityArrayToRowData(gratTrustGroup, [
      HEADING_FAMILY_GIVING,
      HEADING_FAMILY_GIVING_GRAT_TRUSTS,
    ]);

    if (gratTrustRows.length) {
      rows = [
        ...rows,
        {
          id: HEADING_FAMILY_GIVING_GRAT_TRUSTS,
          hierarchy: [HEADING_FAMILY_GIVING, HEADING_FAMILY_GIVING_GRAT_TRUSTS],
          wealthTransferred: getCurrencyDisplay(
            gratTrustGroupWealthTransferred,
            { showZero: true }
          ),
          outOfEstateGrowth: getCurrencyDisplay(
            gratTrustGroupOutOfEstateGrowth,
            { showZero: true }
          ),
          impliedEstateTaxSavings: getCurrencyDisplay(
            gratTrustGroupImpliedEstateTaxSavings,
            { showZero: true }
          ),
          line1: HEADING_FAMILY_GIVING_GRAT_TRUSTS,
          shouldShowBold: true,
        },
        ...gratTrustRows,
      ];

      familyWealthTransferred = familyWealthTransferred.plus(
        gratTrustGroupWealthTransferred
      );
      outOfEstateGrowth = outOfEstateGrowth.plus(
        gratTrustGroupOutOfEstateGrowth
      );
      impliedEstateTaxSavings = impliedEstateTaxSavings.plus(
        gratTrustGroupImpliedEstateTaxSavings
      );
    }
  }

  const dafRows: Row[] = [];
  entityNodes
    .filter((entity) => entity.kind === EntityKind.DonorAdvisedFund)
    .forEach((entity, index) => {
      if (entity.subtype.__typename !== 'DonorAdvisedFund') {
        return;
      }
      charityWealthTransferred = charityWealthTransferred.plus(
        entity.subtype.performanceReport.wealth
      );
      const dafId = `${entity.id}-${index}`;
      dafRows.push({
        id: dafId,
        hierarchy: [HEADING_CHARITABLE_GIVING, dafId],
        wealthTransferred: getCurrencyDisplay(
          entity.subtype.performanceReport.wealth
        ),
        entityId: entity.id,
        line1: entity.subtype.displayName,
        line2: entityKindToDisplayName(entity.kind),
        outOfEstateGrowth: '',
        impliedEstateTaxSavings: '',
      });
    });

  const privateFoundationRows: Row[] = [];
  entityNodes
    .filter((entity) => entity.kind === EntityKind.PrivateFoundation)
    .forEach((entity, index) => {
      if (entity.subtype.__typename !== 'PrivateFoundation') {
        return;
      }
      charityWealthTransferred = charityWealthTransferred.plus(
        entity.subtype.performanceReport.wealth
      );
      const pfId = `${entity.id}-${index}`;
      privateFoundationRows.push({
        id: pfId,
        hierarchy: [HEADING_CHARITABLE_GIVING, pfId],
        wealthTransferred: getCurrencyDisplay(
          entity.subtype.performanceReport.wealth
        ),
        entityId: entity.id,
        line1: entity.subtype.displayName,
        line2: entityKindToDisplayName(entity.kind),
        outOfEstateGrowth: '',
        impliedEstateTaxSavings: '',
      });
    });

  const organizationsRows: Row[] = [];
  (household?.clientOrganizations || []).forEach((organization) => {
    organizationsRows.push(...mapClientOrganizationToData(organization));
    charityWealthTransferred = charityWealthTransferred.plus(
      organization.performanceReport.totalWealthTransferred
    );
  });

  if (
    dafRows.length ||
    privateFoundationRows.length ||
    organizationsRows.length
  ) {
    rows = [
      ...rows,
      {
        id: HEADING_CHARITABLE_GIVING,
        hierarchy: [HEADING_CHARITABLE_GIVING],
        wealthTransferred: getCurrencyDisplay(charityWealthTransferred),
        outOfEstateGrowth: '',
        impliedEstateTaxSavings: '',
        line1: HEADING_CHARITABLE_GIVING,
        shouldShowBold: true,
      },
      ...dafRows,
      ...privateFoundationRows,
      ...organizationsRows,
    ];
  }

  return {
    rows: [
      {
        id: HEADING_FAMILY_GIVING,
        hierarchy: [HEADING_FAMILY_GIVING],
        wealthTransferred: getCurrencyDisplay(familyWealthTransferred),
        outOfEstateGrowth: getCurrencyDisplay(outOfEstateGrowth),
        impliedEstateTaxSavings: getCurrencyDisplay(impliedEstateTaxSavings),
        line1: HEADING_FAMILY_GIVING,
        shouldShowBold: true,
      },
      ...rows,
    ],
    familyWealthTransferred,
    charityWealthTransferred,
  };
}
