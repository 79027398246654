// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTestamentaryEntityMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateTestamentaryEntityInput;
}>;


export type CreateTestamentaryEntityMutation = { __typename?: 'Mutation', createTestamentaryEntity: { __typename?: 'TestamentaryEntity', id: string } };

export type DuplicateTestamentaryEntityMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DuplicateTestamentaryEntityMutation = { __typename?: 'Mutation', duplicateTestamentaryEntity: { __typename?: 'TestamentaryEntity', id: string } };


export const CreateTestamentaryEntityDocument = gql`
    mutation CreateTestamentaryEntity($input: AugmentedCreateTestamentaryEntityInput!) {
  createTestamentaryEntity(input: $input) {
    id
  }
}
    `;
export type CreateTestamentaryEntityMutationFn = Apollo.MutationFunction<CreateTestamentaryEntityMutation, CreateTestamentaryEntityMutationVariables>;

/**
 * __useCreateTestamentaryEntityMutation__
 *
 * To run a mutation, you first call `useCreateTestamentaryEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestamentaryEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestamentaryEntityMutation, { data, loading, error }] = useCreateTestamentaryEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTestamentaryEntityMutation(baseOptions?: Apollo.MutationHookOptions<CreateTestamentaryEntityMutation, CreateTestamentaryEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTestamentaryEntityMutation, CreateTestamentaryEntityMutationVariables>(CreateTestamentaryEntityDocument, options);
      }
export type CreateTestamentaryEntityMutationHookResult = ReturnType<typeof useCreateTestamentaryEntityMutation>;
export type CreateTestamentaryEntityMutationResult = Apollo.MutationResult<CreateTestamentaryEntityMutation>;
export type CreateTestamentaryEntityMutationOptions = Apollo.BaseMutationOptions<CreateTestamentaryEntityMutation, CreateTestamentaryEntityMutationVariables>;
export const DuplicateTestamentaryEntityDocument = gql`
    mutation DuplicateTestamentaryEntity($id: ID!) {
  duplicateTestamentaryEntity(id: $id) {
    id
  }
}
    `;
export type DuplicateTestamentaryEntityMutationFn = Apollo.MutationFunction<DuplicateTestamentaryEntityMutation, DuplicateTestamentaryEntityMutationVariables>;

/**
 * __useDuplicateTestamentaryEntityMutation__
 *
 * To run a mutation, you first call `useDuplicateTestamentaryEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateTestamentaryEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateTestamentaryEntityMutation, { data, loading, error }] = useDuplicateTestamentaryEntityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateTestamentaryEntityMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateTestamentaryEntityMutation, DuplicateTestamentaryEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateTestamentaryEntityMutation, DuplicateTestamentaryEntityMutationVariables>(DuplicateTestamentaryEntityDocument, options);
      }
export type DuplicateTestamentaryEntityMutationHookResult = ReturnType<typeof useDuplicateTestamentaryEntityMutation>;
export type DuplicateTestamentaryEntityMutationResult = Apollo.MutationResult<DuplicateTestamentaryEntityMutation>;
export type DuplicateTestamentaryEntityMutationOptions = Apollo.BaseMutationOptions<DuplicateTestamentaryEntityMutation, DuplicateTestamentaryEntityMutationVariables>;
export const Operations = {
  Mutation: {
    CreateTestamentaryEntity: 'CreateTestamentaryEntity' as const,
    DuplicateTestamentaryEntity: 'DuplicateTestamentaryEntity' as const
  }
}