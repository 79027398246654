import { css } from '@emotion/css';
import { Stack, Typography } from '@mui/material';

import { Divider } from '@/components/Divider';
import { HeaderList } from '@/components/lists/HeaderList/HeaderList';
import { useListColumnStyles } from '@/components/lists/lists.utils';
import { DispositiveProvisionDirection } from '@/modules/dispositiveProvisions/DispositiveProvisionsListView/DispositiveProvisionsByDeath.types';
import { sortDispositiveProvisions } from '@/modules/dispositiveProvisions/DispositiveProvisionsListView/DispositiveProvisionsListView.utils';
import { DispositiveProvisionRow } from '@/modules/dispositiveProvisions/DispositiveProvisionsListView/DispositiveProvisionsRow';
import {
  DispositiveProvisions_DispositionScenarioFragment,
  DispositiveProvisions_DispositiveProvisionFragment,
} from '@/modules/dispositiveProvisions/graphql/DispositiveProvisions.fragments.generated';
import { COLORS } from '@/styles/tokens/colors';

import { useRegisterSlide } from '../../../clientPresentation/hooks/useRegisterSlide';
import { PresentationSlideSecondaryHeading } from '../../../components/PresentationSlideSecondaryHeading';
import { PRESENTATION_SPACING_UNITS } from '../../../presentation.constants';
import { getNumberedSlideTitle } from '../../../presentation.utils';
import { PresentationSlide } from '../../../PresentationSlide';
import { PresentationSlideRegistrationDetails } from '../../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../../graphql/EntityPresentation.generated';
import { getColumnsToRenderCount } from './DispositionScenarioSlide.utils';

export interface DispositionScenarioSlideProps
  extends PresentationSlideRegistrationDetails {
  entity: EntityPresentation_EntityFragment;

  // there are two ordering concepts here: the the count of the *scenario* (either 1 or 2)
  // and the count of the number of slides detailing the dispositive provisions for each scenario
  // (which has no definite upper limit)
  dispositionScenarioSlideIndex: number;
  dispositionScenarioSlideTotalCount: number;
  dispositiveProvisionSlideIndex: number;
  dispositiveProvisionsSlideTotalCount: number;

  displayName: string;
  dispositionScenario: DispositiveProvisions_DispositionScenarioFragment;
  // first/secondDeathProvisions would be empty arrays if no provisions are defined, or `null` if they may be defined but we don't want to
  // show them on this slide
  firstDeathProvisions:
    | DispositiveProvisions_DispositiveProvisionFragment[]
    | null;
  secondDeathProvisions:
    | DispositiveProvisions_DispositiveProvisionFragment[]
    | null;
  grantors: {
    displayName: string;
    id: string;
  }[];
}

const SLIDE_TITLE = 'Distribution after death';

export function DispositionScenarioSlide({
  entity,
  dispositionScenario,
  firstDeathProvisions,
  secondDeathProvisions,
  displayName,
  grantors,
  dispositionScenarioSlideIndex,
  dispositionScenarioSlideTotalCount,
  dispositiveProvisionSlideIndex,
  dispositiveProvisionsSlideTotalCount,
  ...registrationProps
}: DispositionScenarioSlideProps) {
  useRegisterSlide({
    displayName: getNumberedSlideTitle(
      SLIDE_TITLE,
      dispositionScenarioSlideIndex,
      dispositionScenarioSlideTotalCount
    ),
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  const isSingleGrantorHousehold = grantors.length === 1;
  const firstDeath = firstDeathProvisions
    ? {
        heading: 'Upon first death',
        noItemsText: 'No dispositions specified upon first death',
        items: firstDeathProvisions.sort(sortDispositiveProvisions),
      }
    : null;

  const secondDeath =
    isSingleGrantorHousehold || !secondDeathProvisions
      ? null
      : {
          heading: 'Upon second death',
          noItemsText: 'No dispositions specified upon second death',
          items: secondDeathProvisions.sort(sortDispositiveProvisions),
        };

  const { firstDeathColumns, secondDeathColumns } = getColumnsToRenderCount({
    firstDeathProvisions,
    secondDeathProvisions,
  });
  const firstDeathColumnsStyles = useListColumnStyles({
    // unfortunately, CSS will spread items evenly across these columns even if it's not required,
    // so we need to specify the number of columns we actually want rendered
    columnCount: firstDeathColumns,
  });

  const secondDeathDeathColumnsStyles = useListColumnStyles({
    // unfortunately, CSS will spread items evenly across these columns even if it's not required,
    // so we need to specify the number of columns we actually want rendered
    columnCount: secondDeathColumns,
  });

  const slideSubheader = (() => {
    if (isSingleGrantorHousehold) return null;
    const baseSubheader = `If ${dispositionScenario.firstGrantorDeath.displayName} dies first`;
    return getNumberedSlideTitle(
      baseSubheader,
      dispositiveProvisionSlideIndex,
      dispositiveProvisionsSlideTotalCount
    );
  })();

  return (
    <PresentationSlide.Slide
      id={registrationProps.slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading
          heading={SLIDE_TITLE}
          subheading={displayName}
        />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={entity.household.displayName}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <Stack
        p={PRESENTATION_SPACING_UNITS}
        spacing={PRESENTATION_SPACING_UNITS}
        minHeight="90%"
      >
        {slideSubheader && (
          <Typography variant="h1" component="h2">
            {slideSubheader}
          </Typography>
        )}
        <Stack
          direction="row"
          flexGrow={1}
          spacing={3}
          divider={
            <Divider
              sx={{ width: '1px', backgroundColor: COLORS.NEUTRAL_GRAY[900] }}
            />
          }
        >
          {firstDeath && (
            <HeaderList
              heading={firstDeath.heading}
              noItemsText={firstDeath.noItemsText}
              classes={{
                root: css(firstDeathColumnsStyles),
              }}
            >
              {firstDeath.items.map((provision) => (
                <DispositiveProvisionRow
                  key={provision.id}
                  dyingPrimaryClientId={
                    dispositionScenario.firstGrantorDeath.id
                  }
                  primaryClients={grantors}
                  provision={provision}
                  calculatedProvisionAmount={null}
                  direction={DispositiveProvisionDirection.Distributing}
                />
              ))}
            </HeaderList>
          )}
          {/* there won't be a second death in the case of a single-grantor household */}
          {secondDeath && secondDeath.items.length > 0 && (
            <HeaderList
              heading={secondDeath.heading}
              noItemsText={secondDeath.noItemsText}
              classes={{
                root: css(secondDeathDeathColumnsStyles),
              }}
            >
              {secondDeath.items.map((provision) => (
                <DispositiveProvisionRow
                  key={provision.id}
                  dyingPrimaryClientId={
                    dispositionScenario.firstGrantorDeath.id
                  }
                  primaryClients={grantors}
                  provision={provision}
                  calculatedProvisionAmount={null}
                  direction={DispositiveProvisionDirection.Distributing}
                />
              ))}
            </HeaderList>
          )}
        </Stack>
      </Stack>
    </PresentationSlide.Slide>
  );
}
