import { Box, Stack, Typography } from '@mui/material';
import { useContext } from 'react';

import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { UnstyledNavLink } from '@/components/navigation/UnstyledNavLink';
import { COLORS } from '@/styles/tokens/colors';

import { SearchContext } from './context/SearchContext';
import { SearchResult } from './GlobalSearch.utils';

export interface SearchResultItemProps extends SearchResult {
  index: number;
}

export function SearchResultItem({
  heading,
  subheading,
  path,
  index,
}: SearchResultItemProps) {
  const { onClose } = useContext(SearchContext);

  return (
    <UnstyledNavLink
      to={path}
      onClick={onClose}
      tabIndex={index + 1}
      role="button"
    >
      <Box
        px={1.5}
        py={2}
        sx={{
          border: 'none',
          textAlign: 'left',
          cursor: 'pointer',
          textDecoration: 'none',
          background: 'transparent',
          ':hover': { background: COLORS.FUNCTIONAL.HOVER },
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box width="100%">
            <Typography variant="body1">{heading}</Typography>
            <Typography variant="subtitle2">{subheading}</Typography>
          </Box>
          <Box>
            <ChevronRightIcon color={COLORS.GRAY[400]} size={16} />
          </Box>
        </Stack>
      </Box>
    </UnstyledNavLink>
  );
}
