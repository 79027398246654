import { InputAdornment, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { Briefcase02Icon } from '@/components/icons/Briefcase02Icon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { PossibleRelationshipOwnersTypeahead } from '@/modules/household/components/PossibleRelationshipOwnersTypeahead/PossibleRelationshipOwnersTypeahead';
import { HouseholdBillableKind, UpdateHouseholdInput } from '@/types/schema';

import {
  UpdateAdvisorClientMutationVariables,
  useUpdateAdvisorClientMutation,
} from './graphql/Update.generated';
import { useExistingHouseholdFormData } from './hooks/useExistingValues';
import { ManageHouseholdAccessForm } from './ManageHouseholdAccessForm/ManageHouseholdAccessForm';
import {
  getEmptyClientProfileValue,
  UpdateHouseholdFormPaths,
  UpdateHouseholdFormShape,
} from './types';

export const getDefaultValues = (): UpdateHouseholdFormShape => ({
  primaryRelationshipOwnerId: '',
  subBrandId: '',
  profiles: [getEmptyClientProfileValue({ relationshipOptions: [] })],
  isProspect: false,
});

interface UpdateClientProps {
  householdId: string;
}

export function UpdateClient({ householdId }: UpdateClientProps) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const formMethods = useForm<UpdateHouseholdFormShape>({
    defaultValues: getDefaultValues(),
  });
  const { reset, control, handleSubmit } = formMethods;
  const canEditRelationshipOwner = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_REASSIGN_RELATIONSHIP_OWNER
  );
  const canUpdateSubBrand = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_UPDATE_SUB_BRAND
  );
  const { createSuccessFeedback } = useFeedback();

  const [updateClient, { loading }] = useUpdateAdvisorClientMutation({
    onError: (err) => {
      reportError('failed to load household data', err);
      showFeedback(
        'We failed to load data for this client. Please refresh the page to try again.'
      );
    },
    onCompleted: createSuccessFeedback('Client updates saved successfully'),
  });

  const { formData: existingValues, subBrandOptions } =
    useExistingHouseholdFormData({ householdId });

  useEffect(() => {
    if (!existingValues) return;
    reset(existingValues);
  }, [existingValues, reset]);

  const showSubBrandOptions = useMemo(
    () => subBrandOptions.length > 0,
    [subBrandOptions.length]
  );

  const getVariablesFromValues = useCallback(
    (
      values: UpdateHouseholdFormShape
    ): UpdateAdvisorClientMutationVariables => {
      const input: UpdateHouseholdInput = {
        primaryRelationshipOwnerID: values.primaryRelationshipOwnerId,
        billableKind: values.isProspect
          ? HouseholdBillableKind.Prospect
          : HouseholdBillableKind.Billable,
      };

      if (values.subBrandId) {
        input.subBrandID = values.subBrandId;
      } else {
        input.clearSubBrand = true;
      }

      return { updateHouseholdId: householdId, input };
    },
    [householdId]
  );

  const onSubmit = handleSubmit((values) => {
    const variables = getVariablesFromValues(values);
    return updateClient({
      variables,
    });
  });

  return (
    <FormProvider {...formMethods}>
      <Stack spacing={2} direction="column">
        <Stack direction="row" spacing={2} m={3}>
          <Card variant="inner-shadow" sx={{ p: 3, flexGrow: 1 }}>
            <Stack component="form" noValidate>
              <FormLayoutRow>
                <FormLayoutItem>
                  <Stack direction="column">
                    <Typography variant="h1">Key details</Typography>
                  </Stack>
                </FormLayoutItem>
              </FormLayoutRow>
              <FormLayoutRow sx={{ alignItems: 'bottom' }}>
                <FormLayoutItem width={4}>
                  <PossibleRelationshipOwnersTypeahead
                    label="Relationship owner"
                    required={true}
                    fieldName={
                      `primaryRelationshipOwnerId` as const satisfies UpdateHouseholdFormPaths
                    }
                    disabled={!canEditRelationshipOwner}
                    control={control}
                  />
                </FormLayoutItem>
                {showSubBrandOptions && (
                  <FormLayoutItem width={4}>
                    <FormAwareSelectInput
                      label="Sub-brand"
                      disabled={!canUpdateSubBrand}
                      fieldName={
                        `subBrandId` as const satisfies UpdateHouseholdFormPaths
                      }
                      options={subBrandOptions}
                      control={control}
                      showEmptyValue={false}
                      startAdornment={
                        <InputAdornment position="start">
                          <Briefcase02Icon size={20} />
                        </InputAdornment>
                      }
                    />
                  </FormLayoutItem>
                )}
                <FormLayoutItem
                  width={showSubBrandOptions ? 4 : 8}
                  sx={{ display: 'flex', alignItems: 'end' }}
                >
                  <Button
                    disabled={!canEditRelationshipOwner && !canUpdateSubBrand}
                    loading={loading}
                    variant="primary"
                    size="sm"
                    type="button"
                    onClick={onSubmit}
                  >
                    Save
                  </Button>
                </FormLayoutItem>
              </FormLayoutRow>
              <FormLayoutRow>
                <FormLayoutItem>
                  <FormAwareSwitch
                    label="This household is a prospect"
                    labelPosition="right"
                    fieldName={
                      `isProspect` as const satisfies UpdateHouseholdFormPaths
                    }
                    control={control}
                  />
                </FormLayoutItem>
              </FormLayoutRow>
            </Stack>
          </Card>
        </Stack>
        <ManageHouseholdAccessForm householdId={householdId} />
      </Stack>
    </FormProvider>
  );
}
