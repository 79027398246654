import { useIRSConstants } from '@/modules/irs/useIRSConstants';

export function useGSTExemptionAmount({
  numGrantors,
}: {
  numGrantors: number;
}) {
  const { gstExemptionAmount } = useIRSConstants();

  return {
    forAllGrantors: gstExemptionAmount?.times(numGrantors),
    perGrantor: gstExemptionAmount,
  };
}
