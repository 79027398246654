import { Copy06Icon } from '@/components/icons/Copy06Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';

import { DocumentsTableRowData } from '../DocumentsTable.types';
import { useCopyDocumentTextActionItemLazyQuery } from './graphql/CopyDocumentTextActionItem.generated';

interface CopyDocumentTextActionItemProps {
  row: DocumentsTableRowData;
}

/**
 * @description This an action item component for use by the table ActionButtonRenderer
 */
export function CopyDocumentTextActionItem({
  row,
}: CopyDocumentTextActionItemProps) {
  const [copyToClipboard] = useCopyToClipboard();
  const { createErrorFeedback, showFeedback } = useFeedback();
  const [copyDocumentText, { loading }] =
    useCopyDocumentTextActionItemLazyQuery({
      variables: {
        documentId: row.id,
      },
      onError: createErrorFeedback('Failed to copy document text'),
      onCompleted: (data) => {
        // typeguard
        if (data?.node?.__typename !== 'Document') return;

        // protect against un-ocr'd docs
        if (!data.node.ocrExtractedMarkdownContent) {
          return showFeedback(`No OCR'd content found`);
        }

        return copyToClipboard(
          data.node.ocrExtractedMarkdownContent,
          () =>
            showFeedback('Document text copied to clipboard', {
              variant: 'success',
            }),
          () => showFeedback('Failed to copy to clipboard')
        );
      },
    });

  return (
    <MenuItem
      icon={<Copy06Icon size={18} />}
      loading={loading}
      label="Copy to clipboard"
      onClick={() => copyDocumentText()}
    />
  );
}
