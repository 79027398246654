import { Node } from '@/components/diagrams/FlowChart';
import {
  CreateEntityGraphViewInput,
  EntityGraphViewKind,
  EntityGraphViewOrientation,
} from '@/types/schema';

import { GraphVizViewFormData } from './types';

interface MapInputToFormData {
  householdId: string;
  nodes: Node[];
}
export const mapInputToFormData = ({
  householdId,
  nodes,
}: MapInputToFormData): GraphVizViewFormData => {
  return {
    id: null,
    householdID: householdId,
    displayName: '',
    kind: EntityGraphViewKind.Beneficiary,
    orientation: EntityGraphViewOrientation.TopBottom,
    nodeConfigurations: nodes.map((node) => ({
      visible: true,
      nodeID: node.id,
      xPosition: node.position.x,
      yPosition: node.position.y,
      node: node,
    })),
  };
};

export const mapFormDataToInput = ({
  id: _id,
  nodeConfigurations,
  ...formData
}: GraphVizViewFormData): CreateEntityGraphViewInput => ({
  ...formData,
  nodeConfigurations: nodeConfigurations.map(
    ({ node: _node, visible, ...nodeConfig }) => ({
      ...nodeConfig,
      hidden: !visible,
    })
  ),
});
