import { Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { SelectInput } from '@/components/form/baseInputs/SelectInput';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { useGuardedContext } from '@/hooks/useGuardedContext';
import { UserContext } from '@/modules/authentication/UserContext';
import {
  ApplicationAuthorization,
  AuthorizationLevel,
  UserKind,
} from '@/types/schema';

enum ImpersonatedApplicationAuthorizations {
  COLLABORATOR = 'COLLABORATOR',
  WEALTH_ADVISOR = 'WEALTH_ADVISOR',
  EMPLOYEE_ADMIN = 'EMPLOYEE_ADMIN',
  LUMINARY_USER = 'LUMINARY_USER',
}

const IMPERSONATED_APPLICATION_AUTHORIZATION_OPTIONS = [
  {
    display: 'Default',
    value: '',
  },
  {
    display: 'Collaborator',
    value: ImpersonatedApplicationAuthorizations.COLLABORATOR,
  },
  {
    display: 'Wealth advisor',
    value: ImpersonatedApplicationAuthorizations.WEALTH_ADVISOR,
  },
  {
    display: 'Employee admin',
    value: ImpersonatedApplicationAuthorizations.EMPLOYEE_ADMIN,
  },
  {
    display: 'Luminary user',
    value: ImpersonatedApplicationAuthorizations.LUMINARY_USER,
  },
];

const PREDEFINED_APPLICATION_AUTHORIZATIONS: Record<
  ImpersonatedApplicationAuthorizations,
  ApplicationAuthorization
> = {
  [ImpersonatedApplicationAuthorizations.COLLABORATOR]: {
    userKind: UserKind.ThirdParty,
    households: AuthorizationLevel.None,
    branding: AuthorizationLevel.None,
    collaborators: AuthorizationLevel.None,
    internalEmployees: AuthorizationLevel.None,
    canActAsRelationshipOwner: false,
    hasAllClientsAccess: false,
    canModifyGlobalCategories: false,
  },
  [ImpersonatedApplicationAuthorizations.WEALTH_ADVISOR]: {
    userKind: UserKind.Employee,
    households: AuthorizationLevel.Editor,
    branding: AuthorizationLevel.None,
    collaborators: AuthorizationLevel.Editor,
    internalEmployees: AuthorizationLevel.None,
    canActAsRelationshipOwner: true,
    hasAllClientsAccess: false,
    canModifyGlobalCategories: false,
  },
  [ImpersonatedApplicationAuthorizations.EMPLOYEE_ADMIN]: {
    userKind: UserKind.Employee,
    households: AuthorizationLevel.Admin,
    branding: AuthorizationLevel.Admin,
    collaborators: AuthorizationLevel.Admin,
    internalEmployees: AuthorizationLevel.Admin,
    canActAsRelationshipOwner: false,
    hasAllClientsAccess: true,
    canModifyGlobalCategories: true,
  },
  [ImpersonatedApplicationAuthorizations.LUMINARY_USER]: {
    userKind: UserKind.LuminaryInternal,
    households: AuthorizationLevel.Admin,
    branding: AuthorizationLevel.Admin,
    collaborators: AuthorizationLevel.Admin,
    internalEmployees: AuthorizationLevel.Admin,
    canActAsRelationshipOwner: false,
    hasAllClientsAccess: true,
    canModifyGlobalCategories: true,
  },
};

export function AuthorizationEmulation() {
  const [impersonatedAuthenticationLevel, setImpersonatedAuthenticationLevel] =
    useState<ImpersonatedApplicationAuthorizations | ''>('');
  const userContext = useGuardedContext(UserContext, 'UserProvider');

  const handleOnChange = useCallback(
    (value: ImpersonatedApplicationAuthorizations) => {
      setImpersonatedAuthenticationLevel(value);
    },
    [setImpersonatedAuthenticationLevel]
  );

  useEffect(() => {
    if (!impersonatedAuthenticationLevel) return;
    const authorizationToSet =
      PREDEFINED_APPLICATION_AUTHORIZATIONS[impersonatedAuthenticationLevel];
    userContext?.__setDevImpersonatedAuthorizationOverride(authorizationToSet);
  }, [impersonatedAuthenticationLevel, userContext]);

  return (
    <HeaderCard heading="Impersonate authorization levels">
      <Stack sx={{ p: 3 }}>
        <SelectInput
          label="UI-only authorization level"
          options={IMPERSONATED_APPLICATION_AUTHORIZATION_OPTIONS}
          value={impersonatedAuthenticationLevel}
          onChange={(e) =>
            handleOnChange(
              e.target.value as ImpersonatedApplicationAuthorizations
            )
          }
        />
      </Stack>
    </HeaderCard>
  );
}
