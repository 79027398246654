import { useMemo } from 'react';
import { FieldValues, useWatch } from 'react-hook-form';

import {
  AdditionalItemRendererProps,
  FormAwareElementProps,
  FormAwareInputProps,
} from '@/components/form/formAwareInputs/FormAwareInput.types';
import { useFormContext } from '@/components/react-hook-form';
import { MatchableKind } from '@/modules/aiSuggestions/aiSuggestions.types';
import {
  LocateSuggestionIcon,
  LocateSuggestionIconVariant,
} from '@/modules/aiSuggestions/components/LocateSuggestionIcon';
import { CommonAiSuggestionFragment } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { useAISuggestionsEnabled } from '@/modules/aiSuggestions/hooks/useAISuggestionsEnabled';
import { useEditEntitySplitScreenModalContext } from '@/modules/entities/EditEntitySplitScreen/contexts/editEntitySplitScreenModal.context';

import { useEntitySuggestionsContext } from '../../context/EntitySuggestions.context';

interface LocatableFormAwareInputProps<
  S extends FieldValues,
  T extends FormAwareInputProps<S>,
> {
  suggestionKind: MatchableKind;
  additionalItemRendererProps?: AdditionalItemRendererProps;
  FormAwareElement: FormAwareElementProps<S, T>;
}

type LocatableFormAwareInputCombinedProps<
  S extends FieldValues,
  T extends FormAwareInputProps<S>,
> = LocatableFormAwareInputProps<S, T> & T;

/**
 * A wrapper around a FormAwareInput that shows a locator in the field's label.
 */
export function LocatableFormAwareInput<
  S extends FieldValues,
  T extends FormAwareInputProps<S>,
>(props: LocatableFormAwareInputCombinedProps<S, T>) {
  const { entityType } = useEditEntitySplitScreenModalContext();
  const aiSuggestionsEnabled = useAISuggestionsEnabled(entityType);

  const {
    fieldName,
    suggestionKind,
    FormAwareElement,
    additionalItemRendererProps,
  } = props;
  const { getMatchableSuggestion } = useEntitySuggestionsContext();
  const { control } = useFormContext();
  const fieldValue = useWatch({
    name: fieldName,
    control,
  }) as string | undefined;

  const suggestion = useMemo<CommonAiSuggestionFragment | undefined>(() => {
    return getMatchableSuggestion(suggestionKind, fieldValue || '');
  }, [fieldValue, getMatchableSuggestion, suggestionKind]);

  if (!aiSuggestionsEnabled) {
    // Return the FormAwareElement as is, if AI suggestions are disabled
    return <FormAwareElement {...props} />;
  }

  const hideLabel = additionalItemRendererProps?.mainLabelOverride
    ? false
    : props.hideLabel;

  return (
    <FormAwareElement
      {...props}
      hideLabel={hideLabel}
      label={additionalItemRendererProps?.mainLabelOverride || props.label}
      labelIconEnd={
        <LocateSuggestionIcon
          suggestion={suggestion}
          variant={LocateSuggestionIconVariant.FIELD}
          additionalItemRendererProps={additionalItemRendererProps}
          sx={hideLabel ? { mb: 0.5 } : {}}
        />
      }
    />
  );
}
