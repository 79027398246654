import Decimal from 'decimal.js';

import { Percent } from '@/graphql/scalars';
import {
  AssetV2QsbsEligibility,
  AssetValueV2OwnershipType,
} from '@/types/schema';

// i'm not sure exactly what an asset is going to look like yet -- that'll be the next phase -- but i don't
// think it'll map perfectly to the form state, so i'm keeping it defined as a separate (duplicated) type
export interface Asset {
  id: string;
  valueId?: string; // if theres's already a Value entity in the backend, use this to associate
  qsbsEligibility: AssetV2QsbsEligibility;
  categoryId?: string;
  marketValue?: Decimal | null;
  displayName: string;
  doDelete?: boolean;
  valuationMethod: AssetValueV2OwnershipType | string; // string to support initial empty value
  shareCount?: Decimal | null;
  sharePrice?: Decimal | null;
  totalValue?: Decimal | null;
  ownedPercent?: Percent | null;
}

export const NEW_ASSET_ID = 'NEW_ASSET';
