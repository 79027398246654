import { useCallback } from 'react';

import { compose } from '@/utils/reducerUtils';

import { rebuildFamilyTreeGraph } from '../state/familyTreeGraph';
import { loadFamilyTreeQuery } from '../state/loadNetworkQuery';
import { FamilyTreeActionFn, QueryFamilyTreeSuccessAction } from '../types';

export function useQueryFamilyTreeSuccessAction() {
  return useCallback<FamilyTreeActionFn<QueryFamilyTreeSuccessAction>>(
    (state, action, _queueEffect) => {
      return compose(
        loadFamilyTreeQuery(action.familyTree),
        rebuildFamilyTreeGraph()
      )(state);
    },
    []
  );
}
