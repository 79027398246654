import { Grid } from '@mui/material';
import Decimal from 'decimal.js';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { FormAwareHiddenInput } from '@/components/form/formAwareInputs/FormAwareHiddenInput';
import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import { InputRepeaterSumRow } from '@/components/form/formAwareInputs/FormAwareInputRepeater/InputRepeaterSumRow';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { determineDefaultAssetClassId } from '@/modules/assets/utils';
import { useDefaultAssetClassId } from '@/modules/tenant/TenantDetailsContext/hooks/useDefaultAssetClassId';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { formatCurrency } from '@/utils/formatting/currency';

import { useAssetCategoryOptions } from './hooks/useAssetCategoryOptions';
import {
  AssetCategoryField,
  AssetTitleField,
  AssetValueField,
} from './StructuredAssetsSubform.fields';
import {
  Fields,
  Props,
  STRUCTURED_ASSETS_SUBFORM_NAMESPACE,
  SubformField,
} from './StructuredAssetsSubform.types';
import { getAssetValueSum } from './StructuredAssetsSubform.utils';

export function UI(props: Props) {
  const { entityType, disabled, hideDescriptionInput } = props;
  const { assetClassesById } = useTenantDetailsContext();
  const defaultAssetClassId = useDefaultAssetClassId();
  const { options: assetCategoryOptions, loading: formOptionsLoading } =
    useAssetCategoryOptions();

  const defaultCategoryId = useMemo(() => {
    return determineDefaultAssetClassId(
      entityType,
      defaultAssetClassId,
      assetClassesById
    );
  }, [defaultAssetClassId, entityType, assetClassesById]);

  const { control, setValue } = useFormContext<Fields>();
  const assetsValues = useWatch({
    control,
    name: `${STRUCTURED_ASSETS_SUBFORM_NAMESPACE}.assets`,
  });

  // set the _assetsValueSum property to allow consumers to be able to access the sum
  // without redoing it themselves
  useEffect(() => {
    const assetsValuesSum = getAssetValueSum(assetsValues);
    setValue(
      `${STRUCTURED_ASSETS_SUBFORM_NAMESPACE}._assetsValueSum` as const satisfies SubformField,
      assetsValuesSum
    );
  }, [assetsValues, setValue]);

  const assetValuesSum = (assetsValues || []).reduce((acc, a) => {
    acc = acc.add(a.value ?? new Decimal(0));
    return acc;
  }, new Decimal(0));

  return (
    <FormFieldsDisabledProvider
      isDisabled={Boolean(formOptionsLoading || disabled)}
    >
      <Grid container spacing={3} columns={12}>
        <Grid item sm={12}>
          <FormAwareHiddenInput<Fields>
            control={control}
            fieldName={
              `${STRUCTURED_ASSETS_SUBFORM_NAMESPACE}.valuationId` as const satisfies SubformField
            }
          />
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              {/*
                guarding the initial render of the repeater until we have the default category option
                so that we can initialize the empty value with a valid option
               */}
              {defaultCategoryId && (
                <FormAwareInputRepeater<Fields>
                  name={`${STRUCTURED_ASSETS_SUBFORM_NAMESPACE}.assets`}
                  emptyValue={{
                    categoryId: defaultCategoryId,
                    title: '',
                    value: null,
                  }}
                  control={control}
                  shouldInit
                  allowEmpty={false}
                  addAnotherItemText={`Add additional asset or account`}
                  rightFooterContent={
                    <InputRepeaterSumRow
                      content={formatCurrency(assetValuesSum)}
                    />
                  }
                  render={(i: number) => {
                    const isFirstInput = i === 0;
                    return (
                      <FormLayoutRow>
                        <FormLayoutItem width={4}>
                          <AssetCategoryField
                            index={i}
                            hideLabel={!isFirstInput}
                            options={assetCategoryOptions}
                          />
                        </FormLayoutItem>
                        <FormLayoutItem width={4}>
                          <AssetTitleField
                            index={i}
                            hideLabel={!isFirstInput}
                          />
                        </FormLayoutItem>
                        <FormLayoutItem width={4}>
                          <AssetValueField
                            index={i}
                            hideLabel={!isFirstInput}
                          />
                        </FormLayoutItem>
                      </FormLayoutRow>
                    );
                  }}
                />
              )}
            </FormLayoutItem>
          </FormLayoutRow>
          {!hideDescriptionInput && (
            <FormLayoutRow>
              <FormLayoutItem width={12}>
                <FormAwareTextInput<Fields>
                  control={control}
                  label="Description"
                  fieldName={
                    `${STRUCTURED_ASSETS_SUBFORM_NAMESPACE}.description` as const satisfies SubformField
                  }
                  placeholder="Optional"
                  multiline
                  rows={3}
                />
              </FormLayoutItem>
            </FormLayoutRow>
          )}
        </Grid>
      </Grid>
      <FormAwareHiddenInput<Fields>
        control={control}
        fieldName={
          `${STRUCTURED_ASSETS_SUBFORM_NAMESPACE}.accountId` as const satisfies SubformField
        }
      />
    </FormFieldsDisabledProvider>
  );
}
