import { noop } from 'lodash';
import { createContext, Dispatch } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

import { EntityMapAction } from '../types/actions';
import { EntityMapDerivedData, EntityMapState } from '../types/state';

export interface EntityMapContext {
  dispatch: Dispatch<EntityMapAction>;
  state: EntityMapState;
  getState: () => EntityMapState;
}

const defaultDerivedData: EntityMapDerivedData = {
  nodesById: {},
  nodesByType: { default: [], sectionLabel: [], tile: [] },
  edgesById: {},
  allPossibleNodes: [],
  nodeConfigurationsByNodeId: {},
  isSummaryPanelOpen: false,
  summaryNode: null,
};

const defaultState: EntityMapState = {
  isInitializing: true,
  household: null as unknown as EntityMapState['household'],
  entityGraphView: null as unknown as EntityMapState['entityGraphView'],
  entityGraph: null as unknown as EntityMapState['entityGraph'],
  allEntityGraphViews: null as unknown as EntityMapState['allEntityGraphViews'],
  nodes: [],
  edges: [],
  derivedData: { ...defaultDerivedData },
  summaryNodeId: null,
  highlightedIds: new Set(),
};

export const DEFAULT_ENTITY_MAP_STATE: Readonly<EntityMapState> =
  Object.freeze(defaultState);

export const EntityMapContext = createContext<EntityMapContext>({
  dispatch: noop,
  state: { ...DEFAULT_ENTITY_MAP_STATE },
  getState: () => ({ ...DEFAULT_ENTITY_MAP_STATE }),
});

export const useEntityMapContext = () => {
  return useGuardedContext(EntityMapContext, 'EntityMapProvider');
};
