import { FormProvider } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useForm, useSubmitSuccessHandler } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';

import { CollaboratorForm } from './CollaboratorForm';
import {
  CollaboratorFormShape,
  defaultValues,
} from './CollaboratorModal.constants';
import { mapFormDataToInput } from './CreateCollaboratorModal.utils';
import { useCreateCollaboratorMutation } from './graphql/CollaboratorModal.generated';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'>;
export type CreateCollaboratorModalProps = FormModalOptionalProps;

function CreateCollaborator({
  onClose,
  heading = 'Add new collaborator',
  ...formModalProps
}: FormModalOptionalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { formRef, handleSave } = useFormSaveHandler();
  const { handleSubmit, reset, shouldBlockNavigation, formState } =
    useFormContext<CollaboratorFormShape>();

  const [createMutation] = useCreateCollaboratorMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError(`Could not create collaborator`, error);
    },
  });

  const onSubmit = handleSubmit((formData) => {
    return createMutation({
      variables: { input: mapFormDataToInput(formData) },
    });
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  useSubmitSuccessHandler(() => {
    closeModal();
  });

  return (
    <FormModal
      {...formModalProps}
      onClose={onClose}
      heading={heading}
      shouldBlockClose={shouldBlockNavigation}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.CancelButton onClick={closeModal} />
          <FormModalActions.SaveButton onClick={handleSave}>
            Add collaborator
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <CollaboratorForm variant="create" />
      </form>
    </FormModal>
  );
}

export const CreateCollaboratorModal = ({
  ...props
}: CreateCollaboratorModalProps) => {
  const formMethods = useForm<CollaboratorFormShape>({ defaultValues });

  return (
    <FormProvider {...formMethods}>
      <CreateCollaborator {...props} />
    </FormProvider>
  );
};
