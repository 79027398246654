import {
  Background as ReactFlowBackground,
  BackgroundProps as ReactFlowBackgroundProps,
  BackgroundVariant,
} from '@xyflow/react';

import { useIsInteractive } from '../hooks/useIsInteractive';

export interface BackgroundProps extends ReactFlowBackgroundProps {
  variant?: BackgroundVariant;
}
export const Background = ({ style, ...props }: BackgroundProps) => {
  const { isInteractive } = useIsInteractive();
  return (
    <ReactFlowBackground
      {...props}
      style={{ opacity: isInteractive ? 1 : 0, ...style }}
    />
  );
};
