// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from '../../../../graphql/fragments/pageInfo.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminAssetCategoriesConfigurationPage_AssetClassFragment = { __typename?: 'AssetClass', id: string, displayName: string, growthRate?: Percent | null, isSystemClass: boolean, liquidityStatus: Types.AssetClassLiquidityStatus, sortOrder: number, integrationAssetClasses: { __typename?: 'IntegrationAssetClassConnection', edges?: Array<{ __typename?: 'IntegrationAssetClassEdge', node?: { __typename?: 'IntegrationAssetClass', id: string, integrationName: string } | null } | null> | null } };

export type AssetClassesQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.AssetClassWhereInput>;
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['Cursor']['input']>;
}>;


export type AssetClassesQuery = { __typename?: 'Query', paginatedData: { __typename?: 'AssetClassConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: any | null, endCursor?: any | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'AssetClassEdge', node?: { __typename?: 'AssetClass', id: string, displayName: string, growthRate?: Percent | null, isSystemClass: boolean, liquidityStatus: Types.AssetClassLiquidityStatus, sortOrder: number, integrationAssetClasses: { __typename?: 'IntegrationAssetClassConnection', edges?: Array<{ __typename?: 'IntegrationAssetClassEdge', node?: { __typename?: 'IntegrationAssetClass', id: string, integrationName: string } | null } | null> | null } } | null } | null> | null } };

export type UpdateAssetClassesMutationVariables = Types.Exact<{
  updateInputs: Array<Types.InputMaybe<Types.AugmentedUpdateAssetClassInput>> | Types.InputMaybe<Types.AugmentedUpdateAssetClassInput>;
  createInputs: Array<Types.InputMaybe<Types.AugmentedCreateAssetClassInput>> | Types.InputMaybe<Types.AugmentedCreateAssetClassInput>;
  clearIntegrationAssetClassInputs: Array<Types.InputMaybe<Types.AugmentedUpdateAssetClassInput>> | Types.InputMaybe<Types.AugmentedUpdateAssetClassInput>;
}>;


export type UpdateAssetClassesMutation = { __typename?: 'Mutation', clearIntegrationAssetClasses: Array<{ __typename?: 'AssetClass', id: string, displayName: string, growthRate?: Percent | null, isSystemClass: boolean, liquidityStatus: Types.AssetClassLiquidityStatus, sortOrder: number, integrationAssetClasses: { __typename?: 'IntegrationAssetClassConnection', edges?: Array<{ __typename?: 'IntegrationAssetClassEdge', node?: { __typename?: 'IntegrationAssetClass', id: string, integrationName: string } | null } | null> | null } } | null>, updateAssetClasses: Array<{ __typename?: 'AssetClass', id: string, displayName: string, growthRate?: Percent | null, isSystemClass: boolean, liquidityStatus: Types.AssetClassLiquidityStatus, sortOrder: number, integrationAssetClasses: { __typename?: 'IntegrationAssetClassConnection', edges?: Array<{ __typename?: 'IntegrationAssetClassEdge', node?: { __typename?: 'IntegrationAssetClass', id: string, integrationName: string } | null } | null> | null } } | null>, createAssetClasses: Array<{ __typename?: 'AssetClass', id: string, displayName: string, growthRate?: Percent | null, isSystemClass: boolean, liquidityStatus: Types.AssetClassLiquidityStatus, sortOrder: number, integrationAssetClasses: { __typename?: 'IntegrationAssetClassConnection', edges?: Array<{ __typename?: 'IntegrationAssetClassEdge', node?: { __typename?: 'IntegrationAssetClass', id: string, integrationName: string } | null } | null> | null } } | null> };

export const AdminAssetCategoriesConfigurationPage_AssetClassFragmentDoc = gql`
    fragment AdminAssetCategoriesConfigurationPage_AssetClass on AssetClass {
  id
  displayName
  growthRate
  isSystemClass
  liquidityStatus
  sortOrder
  integrationAssetClasses {
    edges {
      node {
        id
        integrationName
      }
    }
  }
}
    `;
export const AssetClassesDocument = gql`
    query AssetClasses($where: AssetClassWhereInput, $first: Int!, $after: Cursor) {
  paginatedData: assetClasses(
    where: $where
    first: $first
    after: $after
    orderBy: [{direction: ASC, field: sort_order}]
  ) {
    totalCount
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        id
        ...AdminAssetCategoriesConfigurationPage_AssetClass
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${AdminAssetCategoriesConfigurationPage_AssetClassFragmentDoc}`;

/**
 * __useAssetClassesQuery__
 *
 * To run a query within a React component, call `useAssetClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssetClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssetClassesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAssetClassesQuery(baseOptions: Apollo.QueryHookOptions<AssetClassesQuery, AssetClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssetClassesQuery, AssetClassesQueryVariables>(AssetClassesDocument, options);
      }
export function useAssetClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssetClassesQuery, AssetClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssetClassesQuery, AssetClassesQueryVariables>(AssetClassesDocument, options);
        }
export function useAssetClassesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AssetClassesQuery, AssetClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AssetClassesQuery, AssetClassesQueryVariables>(AssetClassesDocument, options);
        }
export type AssetClassesQueryHookResult = ReturnType<typeof useAssetClassesQuery>;
export type AssetClassesLazyQueryHookResult = ReturnType<typeof useAssetClassesLazyQuery>;
export type AssetClassesSuspenseQueryHookResult = ReturnType<typeof useAssetClassesSuspenseQuery>;
export type AssetClassesQueryResult = Apollo.QueryResult<AssetClassesQuery, AssetClassesQueryVariables>;
export const UpdateAssetClassesDocument = gql`
    mutation UpdateAssetClasses($updateInputs: [AugmentedUpdateAssetClassInput]!, $createInputs: [AugmentedCreateAssetClassInput]!, $clearIntegrationAssetClassInputs: [AugmentedUpdateAssetClassInput]!) {
  clearIntegrationAssetClasses: updateAssetClasses(
    inputs: $clearIntegrationAssetClassInputs
  ) {
    ...AdminAssetCategoriesConfigurationPage_AssetClass
  }
  updateAssetClasses(inputs: $updateInputs) {
    ...AdminAssetCategoriesConfigurationPage_AssetClass
  }
  createAssetClasses(inputs: $createInputs) {
    ...AdminAssetCategoriesConfigurationPage_AssetClass
  }
}
    ${AdminAssetCategoriesConfigurationPage_AssetClassFragmentDoc}`;
export type UpdateAssetClassesMutationFn = Apollo.MutationFunction<UpdateAssetClassesMutation, UpdateAssetClassesMutationVariables>;

/**
 * __useUpdateAssetClassesMutation__
 *
 * To run a mutation, you first call `useUpdateAssetClassesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetClassesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetClassesMutation, { data, loading, error }] = useUpdateAssetClassesMutation({
 *   variables: {
 *      updateInputs: // value for 'updateInputs'
 *      createInputs: // value for 'createInputs'
 *      clearIntegrationAssetClassInputs: // value for 'clearIntegrationAssetClassInputs'
 *   },
 * });
 */
export function useUpdateAssetClassesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetClassesMutation, UpdateAssetClassesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetClassesMutation, UpdateAssetClassesMutationVariables>(UpdateAssetClassesDocument, options);
      }
export type UpdateAssetClassesMutationHookResult = ReturnType<typeof useUpdateAssetClassesMutation>;
export type UpdateAssetClassesMutationResult = Apollo.MutationResult<UpdateAssetClassesMutation>;
export type UpdateAssetClassesMutationOptions = Apollo.BaseMutationOptions<UpdateAssetClassesMutation, UpdateAssetClassesMutationVariables>;
export const Operations = {
  Query: {
    AssetClasses: 'AssetClasses' as const
  },
  Mutation: {
    UpdateAssetClasses: 'UpdateAssetClasses' as const
  },
  Fragment: {
    AdminAssetCategoriesConfigurationPage_AssetClass: 'AdminAssetCategoriesConfigurationPage_AssetClass' as const
  }
}