import { useApolloClient } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import { DeleteButton } from '@/components/form/baseInputs/Button/DeleteButton';
import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';

import {
  mapFormDataToUpdateInput,
  useSyncSubBrandDataToForm,
} from './EditSubBrandModal.utils';
import {
  useDeleteSubBrandMutation,
  useUpdateSubBrandMutation,
} from './graphql/SubBrandModal.generated';
import { SubBrandForm } from './SubBrandForm';
import { defaultValues, SubBrandFormShape } from './SubBrandModal.constants';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'> & {
  subBrandId: string;
};
export type EditSubBrandModalProps = FormModalOptionalProps;

function EditSubBrand({
  onClose,
  heading = 'Edit sub-brand',
  subBrandId,
  ...formModalProps
}: FormModalOptionalProps) {
  const apolloClient = useApolloClient();
  const { displayName: tenantDisplayName } = useTenantDetailsContext();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { formRef, handleSave } = useFormSaveHandler();
  const { handleSubmit, reset, shouldBlockNavigation, formState } =
    useFormContext<SubBrandFormShape>();

  const [updateMutation] = useUpdateSubBrandMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError(`Could not create sub-brand`, error);
    },
  });

  const [deleteSubBrand] = useDeleteSubBrandMutation({
    onError: (error) => {
      showFeedback(
        `We weren't able to delete the sub-brand; please refresh the page and try again.`
      );
      reportError(`Could not delete sub-brand`, error);
    },
  });

  const onSubmit = handleSubmit((formData) => {
    return updateMutation({
      variables: { input: mapFormDataToUpdateInput(formData, subBrandId) },
    });
  });

  const handleDelete = useCallback(async () => {
    await deleteSubBrand({
      variables: {
        id: subBrandId,
      },
    });

    showFeedback(
      `The sub-brand was successfully deleted. Any clients associated with this sub-brand were reassigned to ${tenantDisplayName}.`,
      { variant: 'success' }
    );

    // invalidate the cache for employees
    await apolloClient.refetchQueries({
      updateCache(cache) {
        cache.evict({ fieldName: 'subBrands' });
      },
    });

    onClose();
  }, [
    apolloClient,
    deleteSubBrand,
    onClose,
    showFeedback,
    subBrandId,
    tenantDisplayName,
  ]);

  const closeModal = () => {
    reset();
    onClose();
  };

  useSubmitSuccessHandler(() => {
    closeModal();
  });

  return (
    <FormModal
      {...formModalProps}
      onClose={onClose}
      heading={heading}
      shouldBlockClose={shouldBlockNavigation}
      actions={
        <FormModalActions.Provider formState={formState}>
          <DeleteButton
            confirmDeleteText="Delete sub-brand"
            onConfirmDelete={handleDelete}
          />
          <Box flexGrow={1} />
          <Stack direction="row" spacing={1}>
            <FormModalActions.CancelButton onClick={closeModal} />
            <FormModalActions.SaveButton onClick={handleSave}>
              Save changes
            </FormModalActions.SaveButton>
          </Stack>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <SubBrandForm />
      </form>
    </FormModal>
  );
}

export const EditSubBrandModal = ({
  subBrandId: SubBrandId,
  ...props
}: EditSubBrandModalProps) => {
  const formMethods = useForm<SubBrandFormShape>({ defaultValues });
  useSyncSubBrandDataToForm(formMethods.reset, SubBrandId);

  return (
    <FormProvider {...formMethods}>
      <EditSubBrand {...props} subBrandId={SubBrandId} />
    </FormProvider>
  );
};
