import { Stack, Typography } from '@mui/material';
import { includes } from 'lodash';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { Loader } from '@/components/progress/Loader/Loader';
import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { EntityDetailsAwareRoute } from '@/modules/entities/contexts/entityDetails/EntityDetailsAwareRoute';
import { HouseholdDetailsAwareRoute } from '@/modules/household/contexts/HouseholdDetailsAwareRoute';
import { GRATAnnuityPaymentTask } from '@/modules/tasks/GRATAnnuityPaymentTask/GRATAnnuityPaymentTask';
import { GRATDistributeTrustRemainderTask } from '@/modules/tasks/GRATDistributeTrustRemainderTask/GRATDistributeTrustRemainderTask';
import { ILITPremiumPaymentTask } from '@/modules/tasks/ILIIPremiumPaymentTask/ILITPremiumPaymentTask';
import { ILITMakeAnnualExclusionGiftsTask } from '@/modules/tasks/ILITMakeAnnualExclusionGiftsTask/ILITMakeAnnualExclusionGiftsTask';
import { ILITSendCrummeyLetterTask } from '@/modules/tasks/ILITSendCrummeyLetterTask/ILITSendCrummeyLetterTask';
import { GRAT_IMPLEMENTATION_TASKS } from '@/modules/tasks/taskTypes';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { useDefaultLandingPage } from '@/navigation/useDefaultLandingPage';
import { COLORS } from '@/styles/tokens/colors';
import { EntityTaskType } from '@/types/schema';

import {
  GetStandaloneTaskPageDetailsQuery,
  useGetStandaloneTaskPageDetailsQuery,
} from './graphql/StandaloneTaskPage.generated';

function getGuardedTaskData(
  taskData: GetStandaloneTaskPageDetailsQuery['task']
) {
  if (taskData?.__typename !== 'EntityTask') {
    throw new Error('Invalid data fetched in StandaloneTaskPage');
  }

  return taskData;
}

// This describes the reason that the task screen was closed.
// 'complete' maps to onComplete and 'close' maps to onClose.
type TaskStateCloseReason = 'complete' | 'close';

// This describes where the caller wants the user redirected
// after the task is completed.
//
// Because this is a standalone page that we link to from other pages within the application,
// but will also likely be a page that we link to directly from e.g. emails, we want to support
// a few different post-task completion behaviors.
type PostTaskRedirect = 'defaultLandingPage' | 'back';

interface StandaloneTaskBodyProps {
  taskId: string;
  taskType: EntityTaskType;
  entityId: string;
  householdId: string;
  handlePostTaskBehavior: (reason: TaskStateCloseReason) => void;
}

function StandaloneTaskBody({
  taskId,
  entityId,
  taskType,
  householdId,
  handlePostTaskBehavior,
}: StandaloneTaskBodyProps) {
  switch (taskType) {
    case EntityTaskType.IlitMakeAnnualExclusionGifts:
      return (
        <ILITMakeAnnualExclusionGiftsTask
          taskId={taskId}
          entityId={entityId}
          householdId={householdId}
          onCompleteTask={() => handlePostTaskBehavior('complete')}
          onClose={() => handlePostTaskBehavior('close')}
        />
      );
    case EntityTaskType.IlitSendCrummeyLetters:
      return (
        <ILITSendCrummeyLetterTask
          taskId={taskId}
          entityId={entityId}
          householdId={householdId}
          onCompleteTask={() => handlePostTaskBehavior('complete')}
          onClose={() => handlePostTaskBehavior('close')}
        />
      );
    case EntityTaskType.IlitMakePremiumPayment:
      return (
        <ILITPremiumPaymentTask
          taskId={taskId}
          entityId={entityId}
          householdId={householdId}
          onCompleteTask={() => handlePostTaskBehavior('complete')}
          onClose={() => handlePostTaskBehavior('close')}
        />
      );
    case EntityTaskType.GratAnnuityPayment:
      return (
        <GRATAnnuityPaymentTask
          taskId={taskId}
          entityId={entityId}
          onCompleteTask={() => handlePostTaskBehavior('complete')}
          onClose={() => handlePostTaskBehavior('close')}
        />
      );
    case EntityTaskType.GratDistributeTrustRemainder:
      return (
        <GRATDistributeTrustRemainderTask
          taskId={taskId}
          entityId={entityId}
          householdId={householdId}
          onCompleteTask={() => handlePostTaskBehavior('complete')}
          onClose={() => handlePostTaskBehavior('close')}
        />
      );
    default:
      // TODO: should support redirecting straight to implementation tasks from here
      throw new Error(`Unsupported task type: ${taskType}`);
  }
}

export function StandaloneTaskPage() {
  const defaultLandingPage = useDefaultLandingPage();
  const taskId = useRequiredParam('taskId');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // TODO: Add some extra validation around this.
  const postTaskBehavior = (searchParams.get('postTaskRedirect') ||
    'defaultLandingPage') as PostTaskRedirect;
  const { data, error, loading } = useGetStandaloneTaskPageDetailsQuery({
    variables: {
      taskId,
    },
  });

  useReportError('error loading data for StandaloneTaskPage', error, {
    taskId,
  });

  if (loading || error) {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        {loading && <Loader />}
        {error && (
          <Typography variant="h1" sx={{ maxWidth: 300 }}>
            There was an error loading this task. Please refresh the page to try
            again.
          </Typography>
        )}
      </Stack>
    );
  }

  const task = getGuardedTaskData(data?.task);

  function handlePostTaskBehavior(_reason: TaskStateCloseReason) {
    if (postTaskBehavior === 'defaultLandingPage') {
      return navigate(defaultLandingPage);
    } else if (postTaskBehavior === 'back') {
      return navigate(-1);
    }

    throw new Error(`Unsupported post-task behavior: ${postTaskBehavior}`);
  }

  // the StandaloneTaskPage acts as a catchall for redirects for all task types, but implementation tasks have a totally
  // different experience and chrome, so we redirect to that experience for those task types
  if (task?.type && includes(GRAT_IMPLEMENTATION_TASKS, task?.type)) {
    const implementationTaskPage = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_ENTITY_IMPLEMENTATION,
      {
        householdId: task.entity.household.id,
        entityId: task.entity.id,
      },
      {
        taskId: task.id,
      }
    );
    return <Navigate to={implementationTaskPage} replace />;
  }

  return (
    <HouseholdDetailsAwareRoute householdId={task.entity.household.id}>
      <EntityDetailsAwareRoute entityId={task.entity.id}>
        <Stack height="100%">
          <FullScreenModal
            isOpen={true}
            backdropSx={{ backgroundColor: COLORS.GRAY[50] }}
          >
            <StandaloneTaskBody
              taskId={taskId}
              taskType={task.type}
              entityId={task.entity.id}
              householdId={task.entity.household.id}
              handlePostTaskBehavior={handlePostTaskBehavior}
            />
          </FullScreenModal>
        </Stack>
      </EntityDetailsAwareRoute>
    </HouseholdDetailsAwareRoute>
  );
}
