import { Box, Stack } from '@mui/material';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import {
  FormAwareButtonGroup,
  FormAwareButtonGroupProps,
} from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareTypeaheadMultiSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadMultiSelectInput';
import { CardWithHeader } from '@/components/layout/CardWithHeader';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';

import {
  HypotheticalSaleLoanFormPaths,
  HypotheticalSaleLoanFormShape,
} from '../EstateWaterfallHypotheticalSaleLoanModal.types';
import { useAssetOptions } from './hooks/useAssetOptions';

const DollarPercentValues = {
  DOLLAR: 'amount',
  PERCENT: 'percent',
} as const;

function DollarPercentButtonGroup(
  props: SetOptional<
    FormAwareButtonGroupProps<HypotheticalSaleLoanFormShape>,
    'options'
  >
) {
  return (
    <Box width={80}>
      <FormAwareButtonGroup
        variant="onDark"
        options={[
          { display: '$', value: DollarPercentValues.DOLLAR },
          { display: '%', value: DollarPercentValues.PERCENT },
        ]}
        {...props}
      />
    </Box>
  );
}

export function SaleLoanAssetsForm() {
  const [assetTypeaheadInputValue, setAssetTypeaheadInputValue] = useState('');
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();
  const proRataPattern = useWatch({
    control,
    name: 'proRataPattern',
  });

  const { sellerId, assetOptions, loading } = useAssetOptions();

  return (
    <Box>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <CardWithHeader title="Fund sale using">
            <FormAwareRadioGroup<HypotheticalSaleLoanFormShape>
              fieldName="fundingSource"
              label="Fund sale using"
              hideLabel
              required
              control={control}
              row={false}
              options={[
                {
                  label: 'Pro-rata from all available assets',
                  value: 'proRata',
                  selectedOptionAdornment: (
                    <Stack
                      direction="row"
                      spacing={1}
                      alignItems="flex-start"
                      py={1}
                    >
                      <DollarPercentButtonGroup
                        fieldName={
                          'proRataPattern' as const satisfies HypotheticalSaleLoanFormPaths
                        }
                        label="Dollar or percent input for pro-rata"
                        hideLabel
                        control={control}
                      />
                      {proRataPattern === DollarPercentValues.DOLLAR && (
                        <FormAwareCurrencyInput
                          fieldName={
                            'proRataAmount' as const satisfies HypotheticalSaleLoanFormPaths
                          }
                          label="Pro-rata dollar value"
                          hideLabel
                          helpText="Current entity net value"
                          control={control}
                          isDecimalJSInput
                        />
                      )}
                      {proRataPattern === DollarPercentValues.PERCENT && (
                        <FormAwarePercentInput
                          fieldName={
                            'proRataPercent' as const satisfies HypotheticalSaleLoanFormPaths
                          }
                          label="Pro-rata percent value"
                          hideLabel
                          helpText="Current entity net value"
                          control={control}
                          isDecimalJSInput
                        />
                      )}
                    </Stack>
                  ),
                },
                {
                  label: 'Specific owned businesses & asset categories',
                  value: 'specific',
                  selectedOptionAdornment: (
                    <Stack
                      direction="row"
                      py={1}
                      spacing={1}
                      alignItems="flex-start"
                    >
                      <DollarPercentButtonGroup
                        fieldName={
                          'proRataPattern' as const satisfies HypotheticalSaleLoanFormPaths
                        }
                        label="Dollar or percent input for pro-rata"
                        hideLabel
                        control={control}
                      />
                      <Box flexGrow={1}>
                        <FormAwareTypeaheadMultiSelectInput
                          inputValue={assetTypeaheadInputValue}
                          onInputChange={setAssetTypeaheadInputValue}
                          groupBy={(option) => option.groupName ?? ''}
                          fieldName={
                            'selectedAssetClassesAndBusinessIds' as const satisfies HypotheticalSaleLoanFormPaths
                          }
                          label="Asset classes and businesses"
                          hideLabel
                          control={control}
                          disabled={!sellerId}
                          helpText={
                            sellerId
                              ? undefined
                              : 'Select a seller to see available assets'
                          }
                          options={assetOptions}
                          loading={loading}
                        />
                      </Box>
                    </Stack>
                  ),
                },
              ]}
            />
          </CardWithHeader>
        </FormLayoutItem>
      </FormLayoutRow>
    </Box>
  );
}
