import {
  AugmentedCreateClientProfileInput,
  AugmentedCreateHouseholdInput,
  CreateClientProfileInput,
} from '@/types/schema';

import {
  BulkImportForm,
  ClientImportSelectionStepHousehold,
} from '../BulkImportPage.types';

function makeCreateClientProfileInput(
  client: ClientImportSelectionStepHousehold
): CreateClientProfileInput {
  const output: CreateClientProfileInput = {
    isGrantor: true,
    isPrimary: true,
    isBeneficiary: true,
    isTrustee: true,
    firstName: client.firstName,
    lastName: client.lastName,
  };

  return output;
}

export function mapFormDataToMutation(
  data: BulkImportForm
): AugmentedCreateHouseholdInput[] {
  const { householdMap } = data;

  return Object.values(householdMap).reduce<AugmentedCreateHouseholdInput[]>(
    (acc, household) => {
      // don't include the "mergedWith" side of merged clients -- they'll be handled
      // by the other side of the relationship, the client they were "merged into"
      if (household.mergedWithID) {
        return acc;
      }

      const integrationClientIDs = [household.remoteID];
      const create: CreateClientProfileInput =
        makeCreateClientProfileInput(household);

      // build the list of ClientProfiles
      const clientProfiles: AugmentedCreateClientProfileInput[] = [
        {
          create,
        },
      ];

      if (household.mergeHouseholdID) {
        const mergedHousehold = householdMap[household.mergeHouseholdID];

        if (!mergedHousehold) {
          throw new Error('Could not look up client for merge');
        }

        const mergedCreate = makeCreateClientProfileInput(mergedHousehold);

        clientProfiles.push({
          create: mergedCreate,
        });
        integrationClientIDs.push(mergedHousehold.remoteID);
      }

      // build the list of Clients
      const clientInput: AugmentedCreateHouseholdInput = {
        create: {
          primaryRelationshipOwnerID: household.ownerID,
          integrationClientIDs,
        },
        withClientProfiles: clientProfiles,
      };

      return [...acc, clientInput];
    },
    []
  );
}
