import Decimal from 'decimal.js';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import {
  DispositiveProvisionDispositionKind,
  DispositiveProvisionTemplateKind,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { otherDispositionKindOptions } from '../DispositiveProvisionsForm.constants';
import { useRemainingExemptionAmountForClient } from '../hooks/useRemainingExemptionAmountForClient';
import { DispositiveProvisionsTemplateRules } from './DispositiveProvisionsRecipient.constants';
import {
  DispositiveProvisionsRecipientTemplateRecipientConfiguration,
  DispositiveProvisionsRecipientTemplateRules,
} from './DispositiveProvisionsRecipient.types';

function getCurrencyDisplayValue(value?: Decimal) {
  if (value === undefined) {
    return '';
  }

  return ` (currently ${formatCurrencyNoDecimals(value)})`;
}

export function getDispositionKindOption(
  option: (typeof otherDispositionKindOptions)[0],
  remainingExemptionAmountForGrantor: ReturnType<
    typeof useRemainingExemptionAmountForClient
  >['data'],
  filterDispositionKindSelectOptions: DispositiveProvisionDispositionKind[]
) {
  const { display, value } = option;

  if (typeof display === 'function') {
    switch (value) {
      case DispositiveProvisionDispositionKind.RemainingLifetimeExclusionOfGrantor: {
        return {
          display: display(
            getCurrencyDisplayValue(
              remainingExemptionAmountForGrantor?.remainingLifetimeExclusion
            )
          ),
          value,
          disabled: filterDispositionKindSelectOptions.includes(value),
        };
      }
      case DispositiveProvisionDispositionKind.RemainingGstExclusionOfGrantorInExcessOfLifetimeExclusion: {
        return {
          display: display(
            getCurrencyDisplayValue(
              remainingExemptionAmountForGrantor?.remainingGstExclusionInExcessOfLifetime
            )
          ),
          value,
          disabled: filterDispositionKindSelectOptions.includes(value),
        };
      }
      case DispositiveProvisionDispositionKind.RemainingStateExemption: {
        const stateCode = remainingExemptionAmountForGrantor?.stateCode;
        return {
          display: display(
            stateCode ?? 'state',
            getCurrencyDisplayValue(
              stateCode
                ? remainingExemptionAmountForGrantor?.remainingStateExemption
                : undefined
            )
          ),
          value,
          disabled: filterDispositionKindSelectOptions.includes(value),
        };
      }
      case DispositiveProvisionDispositionKind.RemainingFederalLifetimeExemptionInExcessOfStateExemption: {
        const stateCode = remainingExemptionAmountForGrantor?.stateCode;
        return {
          display: display(
            stateCode ?? 'state',
            getCurrencyDisplayValue(
              stateCode
                ? remainingExemptionAmountForGrantor?.remainingFederalLifetimeExemptionInExcessOfStateExemption
                : undefined
            )
          ),
          value,
          disabled: filterDispositionKindSelectOptions.includes(value),
        };
      }
      default:
        throw new UnreachableError({
          case: value as never,
          message: 'Unhandled disposition option kind',
        });
    }
  } else {
    return {
      display,
      value,
    };
  }
}

export function validateRecipient(
  recipientId: string,
  recipientOptions: SelectInputOption[]
): string | undefined {
  const pulidKind = getPulidKind(recipientId);
  switch (pulidKind) {
    case PulidKind.ClientProfile: {
      const recipientOption = recipientOptions.find((option) => {
        return 'value' in option && option.value === recipientId;
      });

      // this is currently the only way for a client profile recipient to be invalid
      if (recipientOption?.disabled) {
        return 'Recipient must be a living client';
      }

      return undefined;
    }

    case PulidKind.TestamentaryEntity: {
      const recipientOption = recipientOptions.find((option) => {
        return 'value' in option && option.value === recipientId;
      });

      if (recipientOption?.disabled) {
        return 'Testamentary entity must be valid for this death scenario';
      }

      return undefined;
    }

    default:
      return undefined;
  }
}

export function getTemplateRulesForTemplateType(
  templateType: DispositiveProvisionTemplateKind | null | undefined,
  externalRecipientConfiguration?: Partial<DispositiveProvisionsRecipientTemplateRecipientConfiguration>
): {
  templateRules: DispositiveProvisionsRecipientTemplateRules;
  recipientConfiguration: DispositiveProvisionsRecipientTemplateRecipientConfiguration;
} {
  if (templateType) {
    return {
      templateRules: DispositiveProvisionsTemplateRules[templateType],
      recipientConfiguration: {
        ...(DispositiveProvisionsTemplateRules[templateType]
          .recipientConfiguration || {}),
        ...(externalRecipientConfiguration || {}),
      },
    };
  }
  return {
    templateRules: DispositiveProvisionsTemplateRules.NO_TEMPLATE,
    recipientConfiguration: {
      ...(DispositiveProvisionsTemplateRules.NO_TEMPLATE
        .recipientConfiguration || {}),
      ...(externalRecipientConfiguration || {}),
    },
  };
}
