import Decimal from 'decimal.js';

import { ManageDispositions_ClientProfileFragment } from './graphql/ManageDispositionsPage.generated';

export enum ManageDispositionPageSections {
  IN_ESTATE_ENTITIES = 'IN_ESTATE_ENTITIES',
  OUT_OF_ESTATE_ENTITIES = 'OUT_OF_ESTATE_ENTITIES',
  TESTAMENTARY_ENTITIES = 'TESTAMENTARY_ENTITIES',
}

/**
 * @description We need to extend the basic ClientProfile information for primary clients with
 * the valueToDistribute field, which is computed based on a waterfall. This represents a client profile
 * _for a given death order_.
 */
export interface ManageDispositions_AugmentedClientProfileRow
  extends ManageDispositions_ClientProfileFragment {
  /**
   * the valueToDistribute for a given client profile is computed from an estate waterfall that is tied to a specific death order
   */
  valueToDistribute: Decimal;
  firstDeathClientProfileId: string;
}
