import { noop } from 'lodash';
import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export interface GlobalSidebarContext {
  /** Flag stating if the sidebar is in a collapsed state -- if true, a toggle to reveal the collapsed sidebar will be rendered. */
  isSidebarCollapsed: boolean;
  setSidebarCollapsed: (collapsed: boolean) => void;
}

export const GlobalSidebarContext = createContext<GlobalSidebarContext>({
  isSidebarCollapsed: false,
  setSidebarCollapsed: noop,
});

export const useGlobalSidebarContext = () =>
  useGuardedContext(GlobalSidebarContext, 'GlobalSidebarProvider');
