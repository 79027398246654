import { RadioGroupOption } from '@/components/form/baseInputs/inputTypes';

import { CustomEntityGrowthCustomizationType } from '../GrowthProfileModal/GrowthProfileModal.types';

export const ENTITY_CUSTOM_GROWTH_OPTIONS: RadioGroupOption<CustomEntityGrowthCustomizationType>[] =
  [
    {
      label: 'Custom entity-level growth rate',
      value: CustomEntityGrowthCustomizationType.GrowthRate,
    },
    {
      label: 'Specify future valuation with default growth rates',
      value: CustomEntityGrowthCustomizationType.FutureValuation,
    },
    {
      label: 'Specify future valuation with custom entity-level growth rates',
      value: CustomEntityGrowthCustomizationType.GrowthRateAndFutureValuation,
    },
  ];
