import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

import { TenantPresentationConfiguration_TenantDisclaimerConfigurationFragment } from '../graphql/TenantPresentationConfiguration.generated';

export interface TenantPresentationConfigurationContext {
  legalDisclaimers?: TenantPresentationConfiguration_TenantDisclaimerConfigurationFragment | null;
  setLegalDisclaimers: Dispatch<
    SetStateAction<TenantPresentationConfiguration_TenantDisclaimerConfigurationFragment | null>
  >;
}

export const TenantPresentationConfigurationContext =
  createContext<TenantPresentationConfigurationContext>({
    // We set this to undefined so when we try to use the context outside of the provider
    // the value is undefined.
    legalDisclaimers: undefined,
    setLegalDisclaimers: () => null,
  });

export const useGuardedTenantPresentationConfigurationContext = () => {
  return useGuardedContext(
    TenantPresentationConfigurationContext,
    'TenantPresentationConfigurationProvider'
  );
};

export const useUnguardedTenantPresentationConfigurationContext = () => {
  return useContext(TenantPresentationConfigurationContext);
};
