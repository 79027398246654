import { AugmentedCreateAssetClassInput } from '@/types/schema';

import { CreateAssetCategoryFormShape } from './CreateAssetCategoryModal.types';

interface MapFormToCreateAssetCategoryShapeParams {
  existingOptionsCount: number;
}

export function mapFormToCreateAssetCategoryShape(
  form: CreateAssetCategoryFormShape,
  { existingOptionsCount }: MapFormToCreateAssetCategoryShapeParams
): AugmentedCreateAssetClassInput {
  return {
    create: {
      displayName: form.name,
      integrationAssetClassIDs: form.associatedIntegrationAssetCategoryIDs,
      growthRate: form.growthRate,
      liquidityStatus: form.liquidityStatus,
      // add it to the end of the zero-indexed sort order list
      sortOrder: existingOptionsCount,
    },
  };
}
