import { SubForm } from '@/types/subform';

import {
  Fields as TermsSubformFields,
  NAMESPACE as TermsSubformNamespace,
  NamespaceType,
  Props,
  TermsSubformType,
  VariantType,
} from './types';
import { UI } from './ui/UI';
import { defaultValues } from './utils/defaultValues';

export class TermsSubform
  implements
    SubForm<
      TermsSubformType,
      TermsSubformFields,
      NamespaceType,
      VariantType,
      object
    >
{
  FormComponent: (props: Props) => JSX.Element;
  defaultValues: TermsSubformType;
  namespace: NamespaceType;

  constructor() {
    this.FormComponent = UI;
    this.defaultValues = defaultValues;
    this.namespace = TermsSubformNamespace;
  }
}
