import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import {
  ClientProfessionalTeamRoleMap,
  getEmptyRoleForKind,
} from '@/modules/professionalTeam/ClientProfessionalTeam.types';
import {
  ProfessionalTeamRoleKind,
  ProfessionalTeamRolePowerOfAttorneyKind,
} from '@/types/schema';
import { formatEnumCase } from '@/utils/formatting/strings';

export function getEmptyAdvisorClientRoleMap(): Partial<ClientProfessionalTeamRoleMap> {
  return {
    [ProfessionalTeamRoleKind.AccountantCpa]: getEmptyRoleForKind(
      ProfessionalTeamRoleKind.AccountantCpa
    ),
    [ProfessionalTeamRoleKind.Attorney]: getEmptyRoleForKind(
      ProfessionalTeamRoleKind.Attorney
    ),
    [ProfessionalTeamRoleKind.InsuranceAgent]: getEmptyRoleForKind(
      ProfessionalTeamRoleKind.InsuranceAgent
    ),
    [ProfessionalTeamRoleKind.BusinessManager]: getEmptyRoleForKind(
      ProfessionalTeamRoleKind.BusinessManager
    ),
    [ProfessionalTeamRoleKind.Bookkeeper]: getEmptyRoleForKind(
      ProfessionalTeamRoleKind.Bookkeeper
    ),
  };
}

export function getEmptyPrincipalClientRoleMap(): Partial<ClientProfessionalTeamRoleMap> {
  return {
    [ProfessionalTeamRoleKind.PowerOfAttorney]: getEmptyRoleForKind(
      ProfessionalTeamRoleKind.PowerOfAttorney
    ),
    [ProfessionalTeamRoleKind.Physician]: getEmptyRoleForKind(
      ProfessionalTeamRoleKind.Physician
    ),
    [ProfessionalTeamRoleKind.HealthcareAgent]: getEmptyRoleForKind(
      ProfessionalTeamRoleKind.HealthcareAgent
    ),
    [ProfessionalTeamRoleKind.Guardian]: getEmptyRoleForKind(
      ProfessionalTeamRoleKind.Guardian
    ),
    [ProfessionalTeamRoleKind.Conservator]: getEmptyRoleForKind(
      ProfessionalTeamRoleKind.Conservator
    ),
    [ProfessionalTeamRoleKind.EstateExecutor]: getEmptyRoleForKind(
      ProfessionalTeamRoleKind.EstateExecutor
    ),
  };
}

export const POWER_OF_ATTORNEY_OPTIONS: SelectInputOption<ProfessionalTeamRolePowerOfAttorneyKind>[] =
  [
    {
      display: formatEnumCase(ProfessionalTeamRolePowerOfAttorneyKind.General),
      value: ProfessionalTeamRolePowerOfAttorneyKind.General,
    },
    {
      display: formatEnumCase(ProfessionalTeamRolePowerOfAttorneyKind.Durable),
      value: ProfessionalTeamRolePowerOfAttorneyKind.Durable,
    },
    {
      display: formatEnumCase(ProfessionalTeamRolePowerOfAttorneyKind.Limited),
      value: ProfessionalTeamRolePowerOfAttorneyKind.Limited,
    },
  ];
