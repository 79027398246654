import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { HeaderList } from '@/components/lists/HeaderList/HeaderList';
import { useDispositiveProvisionsContext } from '@/modules/dispositiveProvisions/contexts/dispositiveProvisions.context';
import { SpecifyDispositiveProvisionsButton } from '@/modules/dispositiveProvisions/SpecifyDispositiveProvisionsButton';
import { getNodes } from '@/utils/graphqlUtils';

import { DispositionScheme } from '../dispositiveProvisions.types';
import {
  mapProvisionToRecipient,
  sortDispositionOrder,
} from '../dispositiveProvisions.utils';
import { useSimulateDispositions } from '../DispositiveProvisionsForm/hooks/useSimulateDispositions';
import {
  DispositiveProvisions_DispositionScenarioFragment,
  DispositiveProvisions_DispositiveProvisionFragment,
} from '../graphql/DispositiveProvisions.fragments.generated';
import { DispositiveProvisionDirection } from './DispositiveProvisionsByDeath.types';
import { sortDispositiveProvisions } from './DispositiveProvisionsListView.utils';
import { DispositiveProvisionRow } from './DispositiveProvisionsRow';
import { ActiveDispositionTab } from './hooks/useDispositiveProvisionsListViewTab';

type DispositiveProvisionsForSingleClientHouseholdProps = {
  dispositionScenarioForTab?: DispositiveProvisions_DispositionScenarioFragment | null;
  currentNodeValue?: Decimal;
  firstDeathPrimaryClientId: string;
  dispositiveProvisions?: DispositiveProvisions_DispositiveProvisionFragment[];
} & ActiveDispositionTab;

export function DispositiveProvisionsForSingleClientHousehold({
  dispositionScenarioForTab,
  firstDeathPrimaryClientId,
  currentNodeValue,
  dispositiveProvisions,
  activePrimaryClientIdTab,
  setActivePrimaryClientIdTab,
  defaultTab,
}: DispositiveProvisionsForSingleClientHouseholdProps) {
  const { primaryClients, firstGrantorDeathId, totalMarketValue } =
    useDispositiveProvisionsContext();
  const recipients = useMemo(() => {
    return getNodes(dispositionScenarioForTab?.dispositiveProvisions)
      .sort(sortDispositionOrder)
      .map(mapProvisionToRecipient);
  }, [dispositionScenarioForTab?.dispositiveProvisions]);

  const { simulationResults } = useSimulateDispositions({
    firstGrantorId: firstGrantorDeathId || '',
    secondGrantorId: undefined,
    recipients,
    entityTotalMarketValue: totalMarketValue,
    selectedDispositionScheme: DispositionScheme.UPON_FIRST_DEATH,
  });

  const items = useMemo(() => {
    if (dispositiveProvisions) {
      return dispositiveProvisions.sort(sortDispositiveProvisions);
    }

    if (dispositionScenarioForTab?.dispositiveProvisionsTemplate) {
      return getNodes(
        dispositionScenarioForTab?.dispositiveProvisionsTemplate
          .dispositiveProvisions
      ).sort(sortDispositiveProvisions);
    }

    return getNodes(dispositionScenarioForTab?.dispositiveProvisions).sort(
      sortDispositiveProvisions
    );
  }, [
    dispositiveProvisions,
    dispositionScenarioForTab?.dispositiveProvisionsTemplate,
    dispositionScenarioForTab?.dispositiveProvisions,
  ]);

  const normalizedDispositionScenariosFirstDeath = {
    heading: 'Upon first death',
    noItemsText: 'No dispositions specified upon first death',
    items,
  };

  return (
    <>
      <HeaderList
        heading={normalizedDispositionScenariosFirstDeath.heading}
        noItemsText={normalizedDispositionScenariosFirstDeath.noItemsText}
      >
        {normalizedDispositionScenariosFirstDeath.items.map((item, index) => (
          <DispositiveProvisionRow
            primaryClients={primaryClients}
            key={item.id}
            provision={item}
            dyingPrimaryClientId={firstDeathPrimaryClientId}
            calculatedProvisionAmount={
              simulationResults?.simulateDispositiveProvisions?.firstDeath?.[
                index
              ]?.transferAmount
            }
            direction={DispositiveProvisionDirection.Distributing}
          />
        ))}
      </HeaderList>
      <SpecifyDispositiveProvisionsButton
        buttonProps={{
          fullWidth: true,
          sx: {
            px: 3,
          },
        }}
        behavior="edit"
        currentNodeValue={currentNodeValue}
        activePrimaryClientIdTab={activePrimaryClientIdTab}
        setActivePrimaryClientIdTab={setActivePrimaryClientIdTab}
        defaultTab={defaultTab}
      />
    </>
  );
}
