import { SectionHeaderWithAccent } from '@/components/display/SectionHeaderWithAccent/SectionHeaderWithAccent';
import { AIOnboardingModalStepTracker } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalStepTracker';

export function AIOnboardingModalHeader() {
  return (
    <SectionHeaderWithAccent
      containerProps={{
        pt: 2,
        pb: 2,
        pl: 3,
        pr: 3,
      }}
      header={{
        heading: 'Generate from documents',
        headingProps: {
          variant: 'h1',
        },
      }}
      rightHeaderContent={<AIOnboardingModalStepTracker />}
    />
  );
}
