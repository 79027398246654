import { first } from 'lodash';
import { useMemo } from 'react';

import { useEstateWaterfallCalloutCopy } from '@/modules/estateWaterfall/hooks/useEstateWaterfallCalloutCopy';
import { getNodes } from '@/utils/graphqlUtils';

import { useEstateWaterfallComparisonModalContext } from '../EstateWaterfallComparisonModal.context';
import { useEstateWaterfallComparisonModalAssumptionsQuery } from '../graphql/EstateWaterfallComparisonModalAssumptions.generated';

export function useAccordionCalloutCopy() {
  const { waterfallId } = useEstateWaterfallComparisonModalContext();
  const { data } = useEstateWaterfallComparisonModalAssumptionsQuery({
    variables: { where: { id: waterfallId } },
    skip: !waterfallId,
    fetchPolicy: 'no-cache',
  });

  const waterfall = first(getNodes(data?.estateWaterfalls));
  const stateDisclosuresData = useMemo(() => {
    const {
      beforeFirstDeathTaxSummary,
      firstDeathTaxSummary,
      secondDeathTaxSummary,
    } = waterfall?.visualizationWithProjections ?? {};

    const stateData = [
      beforeFirstDeathTaxSummary?.stateTax,
      firstDeathTaxSummary?.stateTax,
      secondDeathTaxSummary?.stateTax,
    ];

    return stateData.flatMap((state) => state ?? []);
  }, [waterfall?.visualizationWithProjections]);

  return useEstateWaterfallCalloutCopy({ stateData: stateDisclosuresData });
}
