// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOrUpdateHypotheticalTransfersMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateEstateWaterfallInput;
}>;


export type CreateOrUpdateHypotheticalTransfersMutation = { __typename?: 'Mutation', updateEstateWaterfall: { __typename?: 'EstateWaterfall', id: string } };


export const CreateOrUpdateHypotheticalTransfersDocument = gql`
    mutation CreateOrUpdateHypotheticalTransfers($input: AugmentedUpdateEstateWaterfallInput!) {
  updateEstateWaterfall(input: $input) {
    id
  }
}
    `;
export type CreateOrUpdateHypotheticalTransfersMutationFn = Apollo.MutationFunction<CreateOrUpdateHypotheticalTransfersMutation, CreateOrUpdateHypotheticalTransfersMutationVariables>;

/**
 * __useCreateOrUpdateHypotheticalTransfersMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateHypotheticalTransfersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateHypotheticalTransfersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateHypotheticalTransfersMutation, { data, loading, error }] = useCreateOrUpdateHypotheticalTransfersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrUpdateHypotheticalTransfersMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateHypotheticalTransfersMutation, CreateOrUpdateHypotheticalTransfersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateHypotheticalTransfersMutation, CreateOrUpdateHypotheticalTransfersMutationVariables>(CreateOrUpdateHypotheticalTransfersDocument, options);
      }
export type CreateOrUpdateHypotheticalTransfersMutationHookResult = ReturnType<typeof useCreateOrUpdateHypotheticalTransfersMutation>;
export type CreateOrUpdateHypotheticalTransfersMutationResult = Apollo.MutationResult<CreateOrUpdateHypotheticalTransfersMutation>;
export type CreateOrUpdateHypotheticalTransfersMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateHypotheticalTransfersMutation, CreateOrUpdateHypotheticalTransfersMutationVariables>;
export const Operations = {
  Mutation: {
    CreateOrUpdateHypotheticalTransfers: 'CreateOrUpdateHypotheticalTransfers' as const
  }
}