// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEntityMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateEntityInput;
}>;


export type CreateEntityMutation = { __typename?: 'Mutation', createEntity: { __typename?: 'Entity', id: string, stage: Types.EntityStage, household: { __typename?: 'Household', id: string }, subtype: { __typename?: 'CCorpBusinessEntity', id: string } | { __typename?: 'CLTTrust', id: string } | { __typename?: 'CRTTrust', id: string } | { __typename?: 'CustodialPersonalAccount', id: string } | { __typename?: 'DonorAdvisedFund', id: string } | { __typename?: 'GPBusinessEntity', id: string } | { __typename?: 'GRATTrust', id: string } | { __typename?: 'ILITTrust', id: string } | { __typename?: 'IndividualPersonalAccount', id: string } | { __typename?: 'InsurancePersonalAccount', id: string } | { __typename?: 'IrrevocableTrust', id: string } | { __typename?: 'JointPersonalAccount', id: string } | { __typename?: 'LLCBusinessEntity', id: string } | { __typename?: 'LPBusinessEntity', id: string } | { __typename?: 'PrivateFoundation', id: string } | { __typename?: 'QPRTTrust', id: string } | { __typename?: 'QualifiedTuitionPersonalAccount', id: string } | { __typename?: 'RetirementPersonalAccount', id: string } | { __typename?: 'RevocableTrust', id: string } | { __typename?: 'SCorpBusinessEntity', id: string } | { __typename?: 'SLATTrust', id: string } | { __typename?: 'SoleProprietorshipBusinessEntity', id: string } } };


export const CreateEntityDocument = gql`
    mutation CreateEntity($input: AugmentedCreateEntityInput!) {
  createEntity(input: $input) {
    id
    stage
    household {
      id
    }
    subtype {
      id
    }
  }
}
    `;
export type CreateEntityMutationFn = Apollo.MutationFunction<CreateEntityMutation, CreateEntityMutationVariables>;

/**
 * __useCreateEntityMutation__
 *
 * To run a mutation, you first call `useCreateEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityMutation, { data, loading, error }] = useCreateEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEntityMutation(baseOptions?: Apollo.MutationHookOptions<CreateEntityMutation, CreateEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEntityMutation, CreateEntityMutationVariables>(CreateEntityDocument, options);
      }
export type CreateEntityMutationHookResult = ReturnType<typeof useCreateEntityMutation>;
export type CreateEntityMutationResult = Apollo.MutationResult<CreateEntityMutation>;
export type CreateEntityMutationOptions = Apollo.BaseMutationOptions<CreateEntityMutation, CreateEntityMutationVariables>;
export const Operations = {
  Mutation: {
    CreateEntity: 'CreateEntity' as const
  }
}