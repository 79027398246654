import { PrintOnlyAssetsCard } from '@/modules/entities/details/shared/ViewOnlyAssetsCard';
import { getCLTTrustSummaryProperties } from '@/modules/entities/details/trusts/CLTTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { TrustOverviewSlide } from '../components/Trust.OverviewSlide';
import { CLTTrustPresentationSlideProps } from './CLTTrust.types';

export function CLTTrustOverviewSlide({
  entity,
  subtype,
  ...registrationProps
}: CLTTrustPresentationSlideProps) {
  const summaryDetails = getCLTTrustSummaryProperties({
    entity,
    entityType: ENTITY_TYPES.CLT,
  });

  return (
    <TrustOverviewSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      AssetCard={<PrintOnlyAssetsCard entity={entity} />}
      displayName={subtype.displayName}
    />
  );
}
