import * as Apollo from '@apollo/client';
import Decimal from 'decimal.js';
import { compact, every, sortBy } from 'lodash';
import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { getGuardedEntityDetails } from '@/modules/presentation/entities/hooks/useEntityPresentationDetails.utils';
import { getNodes } from '@/utils/graphqlUtils';

import {
  ClientPresentationDesigner_HouseholdFragment,
  ClientPresentationDetailsQuery,
  ClientPresentationDetailsQueryVariables,
  useClientPresentationDetailsQuery,
} from '../graphql/ClientPresentationDesigner.generated';

/**
 * @description This hook fetches the data needed to render the presentation for a single entity type.
 */
export function useGetClientPresentationDetails(
  opts: Apollo.QueryHookOptions<
    ClientPresentationDetailsQuery,
    ClientPresentationDetailsQueryVariables
  >
) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const queryProps = useClientPresentationDetailsQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      reportError('Failed to load the client presentation', error);
      showFeedback(
        "We weren't able to load this presentation. Please refresh the page to try again."
      );
    },
    ...opts,
  });

  const presentation = (() => {
    if (queryProps.data?.presentation?.__typename !== 'ClientPresentation') {
      return null;
    }

    return queryProps.data.presentation;
  })();

  const {
    presentationEntities,
    presentationWaterfalls,
    household,
    ...presentationDetails
  } = presentation ?? {};

  const allEntitiesOverview = useMemo(() => {
    return getNodes(household?.entities);
  }, [household?.entities]);

  const { entities, visibleEntityPulids } = useMemo(() => {
    const es = compact(
      sortBy(presentationEntities, 'index').map((e) => {
        if (
          e?.__typename !== 'ClientPresentationEntity' ||
          e.entity.__typename !== 'Entity'
        ) {
          return null;
        }

        return {
          entity: getGuardedEntityDetails(e.entity),
          index: e.index,
        };
      })
    );

    const visibleEntityPulids = (() => {
      // differentiate between "every single entity is hidden" and "we have no entities hidden, and should
      // therefore be showing all entities"
      if (every(es, (e) => e.index === -1)) {
        // null indicates "no entities are hidden, show all of them"
        return null;
      }

      return es
        .filter((e) => e.index >= 0)
        .map((e) => e.entity.entityDetails?.id ?? '');
    })();

    return {
      entities: es.map((e) => e.entity),
      visibleEntityPulids,
    };
  }, [presentationEntities]);

  const { waterfalls, visibleWaterfallPulids } = useMemo(() => {
    const ws = compact(
      sortBy(presentationWaterfalls, 'index')?.map((ew) => {
        if (
          ew.__typename !== 'ClientPresentationWaterfall' ||
          ew.waterfall.__typename !== 'EstateWaterfall'
        ) {
          return null;
        }
        return {
          waterfall: ew.waterfall,
          index: ew.index,
        };
      })
    );

    const visibleWaterfallPulids = (() => {
      // differentiate between "every single waterfall is hidden" and "we have no waterfalls hidden, and should
      // therefore be showing all waterfalls"
      if (every(ws, (w) => w.index === -1)) {
        // null indicates "no waterfalls are hidden, show all of them"
        return null;
      }

      return ws.filter((w) => w.index >= 0).map((w) => w.waterfall.id);
    })();

    return {
      waterfalls: ws.map((w) => w.waterfall),
      visibleWaterfallPulids,
    };
  }, [presentationWaterfalls]);

  const legalDisclaimers =
    queryProps.data?.tenantInformation?.disclosuresConfiguration ?? null;

  // just picking some properties off rather than returning the whole object with
  // all of the entities repeated
  const client: ClientPresentationDesigner_HouseholdFragment = useMemo(() => {
    return {
      displayName: household?.displayName ?? '',
      id: household?.id ?? '',
      estateValueMetrics: household?.estateValueMetrics ?? {
        totalEstateValue: new Decimal(0),
        inEstateValue: new Decimal(0),
        outOfEstateValue: new Decimal(0),
        outOfEstateFamilyValue: new Decimal(0),
        outOfEstateCharityValue: new Decimal(0),
      },
      subBrand: household?.subBrand ?? null,
    };
  }, [
    household?.displayName,
    household?.estateValueMetrics,
    household?.id,
    household?.subBrand,
  ]);
  const professionalTeam = getNodes(queryProps.data?.professionalTeam);
  const federalEstateTaxPercent =
    queryProps.data?.irsConstants?.federalEstateTaxPercent ?? null;

  return {
    ...presentationDetails,
    ...queryProps,
    client,
    entities,
    allEntitiesOverview,
    visibleEntityPulids,
    waterfalls,
    visibleWaterfallPulids,
    legalDisclaimers,
    professionalTeam,
    federalEstateTaxPercent,
    balanceSheet: queryProps.data?.balanceSheet ?? null,
  };
}
