// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
export type BeneficiaryFormDetailsv2Fragment = { __typename?: 'GRATTrust', id: string, beneficiaries?: Array<{ __typename?: 'Beneficiary', id: string, accessParameters: { __typename?: 'AccessParameterConnection', edges?: Array<{ __typename?: 'AccessParameterEdge', node?: { __typename?: 'AccessParameter', id: string, percentage?: Percent | null } | null } | null> | null }, summary: { __typename?: 'SummaryBeneficiaryv2', id: string, displayName: string, additionalContext: Array<string | null>, badgeText?: string | null, kind: Types.SummaryBeneficiaryKindv2, displayType: string, ownershipPercentage: string }, individual?: { __typename?: 'ClientProfile', id: string } | null, organization?: { __typename?: 'ClientOrganization', id: string } | null, entity?: { __typename?: 'Entity', id: string } | null }> | null };

export const BeneficiaryFormDetailsv2FragmentDoc = gql`
    fragment BeneficiaryFormDetailsv2 on GRATTrust {
  id
  beneficiaries {
    id
    accessParameters {
      edges {
        node {
          id
          percentage
        }
      }
    }
    summary {
      id
      displayName
      additionalContext
      badgeText
      kind
      displayType
      ownershipPercentage
    }
    individual {
      id
    }
    organization {
      id
    }
    entity {
      id
    }
  }
}
    `;
export const Operations = {
  Fragment: {
    BeneficiaryFormDetailsv2: 'BeneficiaryFormDetailsv2' as const
  }
}