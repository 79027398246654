import { RibbonVertical } from '@/components/display/Ribbon/RibbonVertical';
import { CheckDone01Icon } from '@/components/icons/CheckDone01Icon';
import { COLORS } from '@/styles/tokens/colors';

export function TaskRibbon() {
  return (
    <RibbonVertical
      ribbonColor={COLORS.ORANGE[400]}
      ribbonText="Task"
      icon={<CheckDone01Icon sx={{ fill: 'white' }} />}
    />
  );
}
