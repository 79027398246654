export enum DocumentDetailsPageVariant {
  Household = 'household',
  Entity = 'entity',
}

export enum AboutLoadingState {
  Loading = 'loading',
  Done = 'done',
}

export enum SummaryLoadingState {
  Loading = 'loading',
  Processing = 'processing',
  WithSummary = 'withSummary',
  NoSummary = 'noSummary',
}
export interface DocumentDetailsPageLoadingState {
  error: boolean;
  about: {
    loading: AboutLoadingState;
  };
  summary: {
    loading: SummaryLoadingState;
  };
}

export interface LoadingReducerInput {
  doneLoading: boolean;
  doneProcessingDocument: boolean;
  hasDocument: boolean;
  hasSummary: boolean;
}
