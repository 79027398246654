import styled from '@emotion/styled';
import {
  Box,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { COLORS } from '@/styles/tokens/colors';

import { incrementCoachmarkCount, shouldShowCoachmark } from './coachmarkUtils';

export interface CoachmarkProps {
  /** Emphasized title */
  title?: string;
  /** Body text */
  body: string;
  /** Placement relative to the wrapped component */
  placement?: TooltipProps['placement'];
  /** Button props; same as `ButtonProps`, but omits size, variant, href, and onClick. */
  buttonProps?: Omit<ButtonProps, 'size' | 'variant' | 'onClick' | 'href'>;
  /** Text to display on the button */
  buttonLabel: string;
  /** Component to be wrapped with coachmark */
  children?: JSX.Element;
  /** Whether it should display on first render; note that `isOpen` will override this if present */
  initialOpen?: boolean;
  /** Callback when being closed (eg: for recording that it was seen) */
  onClose: () => void;
  /** Don't show an anchor arrow */
  hideArrow?: boolean;
  /** Forcibly set visibility.  Useful for chaining coachmarks or closing on outside action.
   * If undefined, the coachmark will manage its own visibility.
   */
  isOpen?: boolean;
  /** Unique identifier for this coachmark */
  id: string;
  /** Maximum number of times this coachmark should be shown */
  maxInstances?: number;
}

const CoachmarkInner = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.NAVY[500],
    color: COLORS.PRIMITIVES.WHITE,
    maxWidth: 320,
    border: `1px solid ${COLORS.NAVY[500]}`,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.NAVY[500],
    backgroundColor: COLORS.PRIMITIVES.WHITE,
  },
}));

export function Coachmark({
  title,
  body,
  buttonProps = {},
  buttonLabel,
  placement = 'bottom-start',
  children,
  initialOpen = false,
  onClose: externalOnClose,
  hideArrow = false,
  isOpen: externalOpen,
  id,
  maxInstances = Infinity,
}: CoachmarkProps) {
  const [isOpen, setOpen] = useState<boolean>(
    () => initialOpen && shouldShowCoachmark(id, maxInstances)
  );

  useEffect(() => {
    if (!isOpen && initialOpen && shouldShowCoachmark(id, maxInstances)) {
      setOpen(true);
      incrementCoachmarkCount(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialOpen, id, maxInstances]);

  const onClose = useCallback(() => {
    setOpen(false);
    externalOnClose();
  }, [externalOnClose]);

  // Don't render anything if we've exceeded maxInstances or if it's been seen
  if (!shouldShowCoachmark(id, maxInstances) && externalOpen === undefined) {
    return <>{children}</>;
  }

  return (
    <CoachmarkInner
      arrow={!hideArrow}
      placement={placement}
      open={externalOpen ?? isOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      onClose={onClose}
      title={
        <Stack sx={{ p: 1.5 }}>
          <Typography variant="h6" sx={{ color: COLORS.TEAL[300], pb: 0.5 }}>
            {title}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: COLORS.PRIMITIVES.WHITE, pb: 1.5 }}
          >
            {body}
          </Typography>
          <Stack direction="row" justifyContent="end">
            <Button
              variant="primary"
              size="sm"
              onClick={onClose}
              {...buttonProps}
            >
              {buttonLabel}
            </Button>
          </Stack>
        </Stack>
      }
    >
      <Box>{children}</Box>
    </CoachmarkInner>
  );
}
