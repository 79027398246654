// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResendInviteMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type ResendInviteMutation = { __typename?: 'Mutation', reInviteTenantUser: { __typename?: 'User', id: string } };


export const ResendInviteDocument = gql`
    mutation ResendInvite($email: String!) {
  reInviteTenantUser(email: $email) {
    id
  }
}
    `;
export type ResendInviteMutationFn = Apollo.MutationFunction<ResendInviteMutation, ResendInviteMutationVariables>;

/**
 * __useResendInviteMutation__
 *
 * To run a mutation, you first call `useResendInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteMutation, { data, loading, error }] = useResendInviteMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendInviteMutation(baseOptions?: Apollo.MutationHookOptions<ResendInviteMutation, ResendInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInviteMutation, ResendInviteMutationVariables>(ResendInviteDocument, options);
      }
export type ResendInviteMutationHookResult = ReturnType<typeof useResendInviteMutation>;
export type ResendInviteMutationResult = Apollo.MutationResult<ResendInviteMutation>;
export type ResendInviteMutationOptions = Apollo.BaseMutationOptions<ResendInviteMutation, ResendInviteMutationVariables>;
export const Operations = {
  Mutation: {
    ResendInvite: 'ResendInvite' as const
  }
}