import { compact, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';

import { LegendProps } from '@/components/charts/Legend/Legend';

import { EstateWaterfallSummaryBarChartsSections } from '../types';
import { useEstateWaterfallSummaryBarChartsSectionColors } from './useEstateWaterfallSummaryBarChartsSectionColors.hook';
import { WaterfallBarChartSection } from './useWaterfallSummaryBarChartSections';

export function useEstateWaterfallSummaryLegendSections(
  waterfallSections: WaterfallBarChartSection[]
): LegendProps['sections'] {
  const sectionColors = useEstateWaterfallSummaryBarChartsSectionColors();

  const allSections = useMemo(() => {
    return waterfallSections.flatMap(({ waterfallSections }) => {
      return waterfallSections;
    });
  }, [waterfallSections]);

  const containsKind = useCallback(
    (kind: EstateWaterfallSummaryBarChartsSections) => {
      return allSections.some((section) => section.kind === kind);
    },
    [allSections]
  );

  const legendSections = useMemo(() => {
    const includeInEstate = containsKind(
      EstateWaterfallSummaryBarChartsSections.InEstate
    );
    const includeOutOfEstateFamily = containsKind(
      EstateWaterfallSummaryBarChartsSections.OutOfEstateFamily
    );
    const includeOutOfEstateCharity = containsKind(
      EstateWaterfallSummaryBarChartsSections.OutOfEstateCharity
    );
    const includeGiftAndEstateTax = containsKind(
      EstateWaterfallSummaryBarChartsSections.GiftAndEstateTax
    );
    const includeLifeInsuranceBenefit = allSections.some(
      // this is a little bit brittle, but the only "subsections" ever used here are life insurance death benefits
      (section) => !isEmpty(section.subsections)
    );

    return compact([
      includeInEstate && EstateWaterfallSummaryBarChartsSections.InEstate,
      includeOutOfEstateFamily &&
        EstateWaterfallSummaryBarChartsSections.OutOfEstateFamily,
      includeOutOfEstateCharity &&
        EstateWaterfallSummaryBarChartsSections.OutOfEstateCharity,
      includeGiftAndEstateTax &&
        EstateWaterfallSummaryBarChartsSections.GiftAndEstateTax,
      includeLifeInsuranceBenefit &&
        EstateWaterfallSummaryBarChartsSections.LifeInsuranceDeathBenefit,
    ]).map((label) => ({
      color: sectionColors[label],
      label,
    }));
  }, [allSections, containsKind, sectionColors]);

  return legendSections;
}
