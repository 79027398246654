import { LifetimeExclusionEventGiftCategory } from '@/types/schema';

import { LogNewGiftForm } from './LogNewGiftModal.types';

export const NAMESPACE = 'logNewGiftModal';

export const getDefaultValues = ({
  defaultBeneficiaryId = '',
  isForcedBeneficiary,
}: {
  defaultBeneficiaryId?: string;
  isForcedBeneficiary?: boolean;
}) =>
  ({
    [NAMESPACE]: {
      grantor: {
        clientProfileId: '',
      },
      beneficiary: {
        beneficiaryId: defaultBeneficiaryId || '',
      },
      giftAmount: null,
      dateOfGift: null,
      discountedValueOfGift: null,
      annualExclusionApplied: null,
      lifetimeExemptionUsed: null,
      gstExemptionApplied: null,
      assetType: null,
      notes: '',
      documentIds: [] as string[],
      lifetimeExclusionEventId: '',
      loggedTransferId: '',
      isForcedBeneficiary,
    },
  }) as const satisfies LogNewGiftForm;

export const assetTypeOptions = [
  {
    value: LifetimeExclusionEventGiftCategory.Cash,
    label: 'Cash',
  },
  {
    value: LifetimeExclusionEventGiftCategory.PublicStockSingle,
    label: 'Securities',
  },
  {
    value: LifetimeExclusionEventGiftCategory.Other,
    label: 'Other',
  },
];
