import Decimal from 'decimal.js';

import { GratAnnuityFragment } from '../graphql/GratTrust.generated';

export function getAnnuitiesStatistics(
  annuities: Pick<
    GratAnnuityFragment,
    'actualPaymentAmount' | 'paidOn' | 'computedPaymentAmount'
  >[]
) {
  let totalPayments = new Decimal(0);
  let paymentsMade = new Decimal(0);
  let remainingPayments = new Decimal(0);

  annuities.forEach((annuity) => {
    const plannedAnnuityPaymentAmount =
      annuity.computedPaymentAmount || new Decimal(0);
    totalPayments = totalPayments.add(plannedAnnuityPaymentAmount);
    if (annuity.paidOn) {
      paymentsMade = paymentsMade.add(annuity.actualPaymentAmount);
    } else {
      remainingPayments = remainingPayments.add(plannedAnnuityPaymentAmount);
    }
  });

  return { totalPayments, paymentsMade, remainingPayments };
}
