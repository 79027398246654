import Decimal from 'decimal.js';

import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';
import { formatCurrencyNoDecimalsAccounting } from '@/utils/formatting/currency';
import { formatDateToMMDDYY } from '@/utils/formatting/dates';

export interface EntitiesSummaryTableRow {
  id: string;
  displayName: string;
  entityDisplayType: string;
  inEstateDisplayStatus: string | null;
  gstDisplayStatus: string | null;
  currentValue: Decimal | null;
  currentValueAsOfDate: Date | null;
}

export const columns: Column<EntitiesSummaryTableRow>[] = [
  {
    field: 'displayName',
    headerName: 'Entity',
    minWidth: 200,
    flex: 1,
    renderCell: TwoLineTextRenderer({
      lineOneProps: { fontWeight: FONT_WEIGHTS.bold },
      lineOne: ({ row }) => row.displayName,
      lineTwo: ({ row }) => row.entityDisplayType,
    }),
  },
  {
    field: 'inEstateDisplayStatus',
    headerName: 'Asset location',
    width: 240,
    renderCell: TwoLineTextRenderer({
      lineOne: ({ row }) => row.inEstateDisplayStatus ?? EMPTY_CONTENT_HYPHEN,
      lineTwo: ({ row }) => row.gstDisplayStatus ?? '',
    }),
  },
  {
    field: 'currentValue',
    headerName: 'Net value',
    width: 200,
    align: 'right',
    renderCell: TwoLineTextRenderer({
      lineOne: ({ row }) => {
        if (!row.currentValue) {
          return EMPTY_CONTENT_HYPHEN;
        }

        return formatCurrencyNoDecimalsAccounting(row.currentValue);
      },
      lineTwo: ({ row }) => {
        if (!row.currentValueAsOfDate) {
          return EMPTY_CONTENT_HYPHEN;
        }

        if (
          row.currentValueAsOfDate.toDateString() === new Date().toDateString()
        ) {
          return `as of today`;
        }

        return `as of ${formatDateToMMDDYY(row.currentValueAsOfDate)}`;
      },
    }),
  },
];
