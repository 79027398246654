import { Typography } from '@mui/material';
import { FieldArrayWithId } from 'react-hook-form';

import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { RibbonCard } from '@/components/layout/RibbonCard';
import { GroupedHeaderList } from '@/components/lists/GroupedHeaderList/GroupedHeaderList';
import { TableCell, TableCellContext } from '@/components/lists/TableCell';
import { useFormContext } from '@/components/react-hook-form';
import { UpdateGiftingButton } from '@/modules/gifting/UpdateGiftingButton/UpdateGiftingButton';
import { formatCurrency } from '@/utils/formatting/currency';

import { useEstateTaxScenariosContext } from '../../contexts/estateTaxScenarios.context';
import { EstateTaxScenarioFormData } from '../../types';

export interface RemainingExemptionsFormProps {
  field: FieldArrayWithId<EstateTaxScenarioFormData['scenarios']>;
  identifier: `scenarios.${number}`;
}

export const RemainingExemptionsForm = ({
  identifier,
}: RemainingExemptionsFormProps) => {
  const { control } = useFormContext<EstateTaxScenarioFormData>();
  const { household, primaryClients } = useEstateTaxScenariosContext();
  return (
    <RibbonCard
      heading="Remaining exemptions"
      rightHeaderContent={<UpdateGiftingButton householdId={household.id} />}
    >
      <FormLayoutRow>
        <FormLayoutItem>
          <Typography>
            Confirm the remaining exemption amounts, as they will be used for
            estimated estate tax calculations.
          </Typography>
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        {primaryClients.map((g) => {
          return (
            <FormLayoutItem key={g.id} width={6}>
              <GroupedHeaderList<{ id: string; label: string; value: string }>
                key={g.id}
                withDividers
                rowGroups={[
                  {
                    heading: g.displayName,
                    items: [
                      {
                        id: `${g.id}-lifetime-exemption`,
                        label: 'Lifetime exemption',
                        value: formatCurrency(g.remainingLifetimeExclusion),
                      },
                      {
                        id: `${g.id}-gs-exemption`,
                        label: 'GST exemption',
                        value: formatCurrency(g.remainingGSTExclusion),
                      },
                    ],
                  },
                ]}
                ItemComponent={({ item }) => {
                  return (
                    <li>
                      <TableCell
                        heading={item.label}
                        RightContent={<TableCellContext heading={item.value} />}
                      />
                    </li>
                  );
                }}
              />
            </FormLayoutItem>
          );
        })}
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareSwitch
            control={control}
            label="Assume portability"
            fieldName={`${identifier}.isExemptionPortable`}
            helpText="If portability is assumed, any unused lifetime exemption remaining after the first client’s death will be transferred to the surviving spouse (GST exemption can not be ported)"
            labelPosition="right"
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </RibbonCard>
  );
};
