import { compact } from 'lodash';

import { AISuggestionMatch } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import {
  BusinessEntityDetails_KeyPerson,
  BusinessEntityDetails_Owner,
  BusinessEntityDetailsSubformType,
} from '@/modules/entities/BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import { AiSuggestionKind } from '@/types/schema';
import { parseDecimalJS } from '@/utils/decimalJSUtils';

export function suggestionsToBeneficialOwnersSubform(
  matches: AISuggestionMatch[]
): BusinessEntityDetailsSubformType['owners'] {
  return compact(
    matches
      .filter((m) => m.clientProfileId || m.entityId)
      .filter((m) => m.suggestion.kind === AiSuggestionKind.BeneficialOwner)
      .filter((m) => m.suggestion.beneficialOwner)
      .map(suggestionToBeneficialOwner)
  );
}

export function suggestionsToKeyPeopleSubform(
  matches: AISuggestionMatch[]
): BusinessEntityDetailsSubformType['keyPeople'] {
  return compact(
    matches
      .filter((m) => m.clientProfileId || m.clientOrganizationId || m.entityId)
      .filter((m) => m.suggestion.kind === AiSuggestionKind.KeyPerson)
      .filter((m) => m.suggestion.keyPerson)
      .map(suggestionToKeyPerson)
  );
}

function suggestionToBeneficialOwner(
  suggestionMatch: AISuggestionMatch
): BusinessEntityDetails_Owner | null {
  const clientProfileEntityId =
    suggestionMatch.clientProfileId || suggestionMatch.entityId;
  if (!clientProfileEntityId) {
    // This shouldn't happen since we pre-filtered the matches. This is for type safety.
    return null;
  }

  const { beneficialOwner } = suggestionMatch.suggestion;
  if (!beneficialOwner) {
    // This shouldn't happen since we pre-filtered the matches. This is for type safety.
    return null;
  }

  return {
    ownerId: clientProfileEntityId,
    percentOwned: beneficialOwner.percentage
      ? parseDecimalJS(beneficialOwner.percentage)
      : null,
    isSuggestion: true,
  };
}

function suggestionToKeyPerson(
  suggestionMatch: AISuggestionMatch
): BusinessEntityDetails_KeyPerson | null {
  const clientProfileOrgEntityId =
    suggestionMatch.clientProfileId ||
    suggestionMatch.clientOrganizationId ||
    suggestionMatch.entityId;
  if (!clientProfileOrgEntityId) {
    // This shouldn't happen since we pre-filtered the matches. This is for type safety.
    return null;
  }

  const { keyPerson } = suggestionMatch.suggestion;
  if (!keyPerson) {
    // This shouldn't happen since we pre-filtered the matches. This is for type safety.
    return null;
  }

  return {
    keyPersonId: clientProfileOrgEntityId,
    roles: keyPerson.roles,
    isSuggestion: true,
  };
}
