import { compact } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import {
  ButtonWithPopover,
  ButtonWithPopoverProps,
} from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { DataIcon } from '@/components/icons/DataIcon';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useModalState } from '@/hooks/useModalState';
import { AIOnboardingModal } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal';
import { GenerateFromDocumentsMenuItem } from '@/modules/aiOnboarding/components/GenerateFromDocumentsMenuItem';
import { GenerateFromDocumentsModal } from '@/modules/aiOnboarding/components/GenerateFromDocumentsModal';
import { AssetIntegrationProviders } from '@/modules/assetProviderIntegrations/shared/constants';
import { useIsAssetProviderIntegrationEnabled } from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import { AddNewAnythingModal } from '@/modules/common/AddNewAnythingModal/AddNewAnythingModal';
import { CreateEntityShortFormModal } from '@/modules/entities/EntityShortFormModal/CreateEntityShortFormModal';
import { TestamentaryEntityModal } from '@/modules/entities/testamentaryEntities/TestamentaryEntityForm/TestamentaryEntityModal';
import { CreateEstateWaterfallTrowser } from '@/modules/estateWaterfall/components/EstateWaterfallModal/CreateEstateWaterfallTrowser';
import { useEstateWaterfallMenuItems } from '@/modules/estateWaterfall/components/EstateWaterfallsDropdown/hooks/useEstateWaterfallMenuItems';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';

import { ModelANewStrategyModal } from './ModelANewStrategyModal';

type CreateNewKind =
  | 'entity_from_addepar'
  | 'entity'
  | 'testamentary_entity'
  | 'individual_or_organization'
  | 'waterfall'
  | 'strategy';

export type CreateNewEntityOrStrategyVariant = 'default' | 'entity_only';

interface CreateNewEntityOrStrategyProps
  extends Omit<Partial<ButtonWithPopoverProps>, 'variant'> {
  householdId: string;
  variant?: CreateNewEntityOrStrategyVariant;
}

export function CreateNewEntityOrStrategy({
  householdId,
  variant = 'default',
  ...buttonProps
}: CreateNewEntityOrStrategyProps) {
  const aiOnboardingEnabled = useFeatureFlag('ai_onboarding');

  const { navigate } = useNavigateToRoute();
  const { integrationsConnected } = useIsAssetProviderIntegrationEnabled();
  const { isHouseholdLinkedToAddepar } = useHouseholdDetailsContext();
  const { primaryClients: grantors, isTwoClientHousehold } =
    useHouseholdDetailsContext();
  const { clientWaterfalls } = useEstateWaterfallMenuItems({
    householdId,
    waterfallId: null,
  });
  const [
    { isModalOpen: isGenerateFromDocumentsModalOpen },
    { setModalIsOpen: setIsGenerateFromDocumentsModalOpen },
  ] = useModalState();
  const [openAIOnboardingModalID, setOpenAIOnboardingModalID] = useState<
    string | null
  >(null);
  const [
    { isModalOpen: isShortCreateModalFormOpen },
    { setModalIsOpen: setIsShortCreateModalFormOpen },
  ] = useModalState();
  const [
    { isModalOpen: isCreateTestamentaryEntityModalOpen },
    { setModalIsOpen: setIsCreateTestamentaryEntityModalOpen },
  ] = useModalState();
  const [
    { isModalOpen: isAddNewIndividualOrOrganizationModalOpen },
    { setModalIsOpen: setIsAddNewIndividualOrOrganizationModalOpen },
  ] = useModalState();
  const [
    { isModalOpen: isCreateWaterfallModalOpen },
    { setModalIsOpen: setIsCreateWaterfallModalOpen },
  ] = useModalState();
  const [
    { isModalOpen: isModelANewStrategyOpen },
    { setModalIsOpen: setIsModelANewStrategyOpen },
  ] = useModalState();

  const handleClose = useCallback(() => {
    setIsGenerateFromDocumentsModalOpen(false);
    setOpenAIOnboardingModalID(null);
    setIsShortCreateModalFormOpen(false);
    setIsCreateTestamentaryEntityModalOpen(false);
    setIsAddNewIndividualOrOrganizationModalOpen(false);
    setIsCreateWaterfallModalOpen(false);
    setIsModelANewStrategyOpen(false);
  }, [
    setIsGenerateFromDocumentsModalOpen,
    setIsShortCreateModalFormOpen,
    setIsCreateTestamentaryEntityModalOpen,
    setIsAddNewIndividualOrOrganizationModalOpen,
    setIsCreateWaterfallModalOpen,
    setIsModelANewStrategyOpen,
  ]);

  const handleClick = useCallback(
    (kind: CreateNewKind) => {
      if (kind === 'entity_from_addepar') {
        navigate(ROUTE_KEYS.HOUSEHOLD_ENTITY_IMPORT, {
          householdId,
          flowType: 'addepar',
        });
      }
      if (kind === 'entity') {
        setIsShortCreateModalFormOpen(true);
      }
      if (kind === 'testamentary_entity') {
        setIsCreateTestamentaryEntityModalOpen(true);
      }
      if (kind === 'individual_or_organization') {
        setIsAddNewIndividualOrOrganizationModalOpen(true);
      }
      if (kind === 'waterfall') {
        setIsCreateWaterfallModalOpen(true);
      }
      if (kind === 'strategy') {
        setIsModelANewStrategyOpen(true);
      }
    },
    [
      householdId,
      navigate,
      setIsShortCreateModalFormOpen,
      setIsCreateTestamentaryEntityModalOpen,
      setIsAddNewIndividualOrOrganizationModalOpen,
      setIsCreateWaterfallModalOpen,
      setIsModelANewStrategyOpen,
    ]
  );

  const showAddparMenuItem =
    integrationsConnected.includes(AssetIntegrationProviders.ADDEPAR) &&
    isHouseholdLinkedToAddepar;

  const menuItems = useMemo(() => {
    const defaultVariantMenuItems =
      variant === 'default'
        ? [
            <MenuItem
              key="newIndividualOrOrganization"
              label="New individual or organization"
              onClick={() => handleClick('individual_or_organization')}
            />,
            <MenuItem
              key="newWaterfall"
              label="New waterfall"
              onClick={() => handleClick('waterfall')}
            />,
            <MenuItem
              key="newAnalysis"
              label="New analysis"
              onClick={() => handleClick('strategy')}
            />,
          ]
        : [];

    return compact([
      (aiOnboardingEnabled || showAddparMenuItem) && (
        <SelectItemGroupLabel key="automated" label="Automated" />
      ),
      aiOnboardingEnabled && (
        <GenerateFromDocumentsMenuItem
          householdId={householdId}
          onOpenGenerateFromDocumentsModal={() =>
            setIsGenerateFromDocumentsModalOpen(true)
          }
          onOpenAIOnboardingModal={(kgRootId) =>
            setOpenAIOnboardingModalID(kgRootId)
          }
        />
      ),
      showAddparMenuItem && (
        <MenuItem
          key="fromAddepar"
          icon={<DataIcon size={20} />}
          label="Import from Addepar"
          onClick={() => handleClick('entity_from_addepar')}
        />
      ),
      (aiOnboardingEnabled || showAddparMenuItem) && (
        <SelectItemGroupLabel key="manual" label="Manual" />
      ),
      <MenuItem
        key="newEntity"
        label="New entity"
        onClick={() => handleClick('entity')}
      />,
      ...defaultVariantMenuItems,
      variant === 'entity_only' && (
        <MenuItem
          key="newTestamentaryEntity"
          label="New testamentary entity"
          onClick={() => handleClick('testamentary_entity')}
        />
      ),
    ]);
  }, [
    aiOnboardingEnabled,
    handleClick,
    householdId,
    setIsGenerateFromDocumentsModalOpen,
    showAddparMenuItem,
    variant,
  ]);

  return (
    <>
      {isGenerateFromDocumentsModalOpen && (
        <GenerateFromDocumentsModal
          isOpen={isGenerateFromDocumentsModalOpen}
          onClose={() => setIsGenerateFromDocumentsModalOpen(false)}
        />
      )}
      {openAIOnboardingModalID && (
        <AIOnboardingModal
          householdId={householdId}
          kgRootId={openAIOnboardingModalID}
          isOpen={!!openAIOnboardingModalID}
          onClose={() => setOpenAIOnboardingModalID(null)}
        />
      )}
      {isShortCreateModalFormOpen && (
        <CreateEntityShortFormModal
          onClose={handleClose}
          isOpen={isShortCreateModalFormOpen}
          householdId={householdId}
        />
      )}
      {isCreateTestamentaryEntityModalOpen && (
        <TestamentaryEntityModal
          isOpen={isCreateTestamentaryEntityModalOpen}
          onClose={() => setIsCreateTestamentaryEntityModalOpen(false)}
          dyingPrimaryClientId={null}
          deadClientIds={[]}
          isTwoClientHousehold={isTwoClientHousehold}
        />
      )}
      {isAddNewIndividualOrOrganizationModalOpen && (
        <AddNewAnythingModal
          handleClose={() =>
            setIsAddNewIndividualOrOrganizationModalOpen(false)
          }
          isOpen={isAddNewIndividualOrOrganizationModalOpen}
          householdId={householdId}
          addableTypes={['individual', 'organization']}
        />
      )}
      {isCreateWaterfallModalOpen && (
        <CreateEstateWaterfallTrowser
          isOpen={isCreateWaterfallModalOpen}
          onClose={() => setIsCreateWaterfallModalOpen(false)}
          householdId={householdId}
          grantors={grantors ?? []}
          sourceEstateWaterfalls={clientWaterfalls}
        />
      )}
      <ModelANewStrategyModal
        isOpen={isModelANewStrategyOpen}
        handleClose={handleClose}
        householdId={householdId}
      />
      <ButtonWithPopover
        label="Create"
        startIcon={PlusIcon}
        variant="primary"
        size="sm"
        popperVariant="menuBelow"
        {...buttonProps}
      >
        {menuItems}
      </ButtonWithPopover>
    </>
  );
}
