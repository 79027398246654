import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { SubstitutionThresholdDetailsv2Fragment } from '../graphql/SubstitutionThresholdSubform.generated';
import { SubstitutionThresholdSubformType } from '../types';

export function useGetSubstitutionThresholdFormValues(
  thresholdsData: SubstitutionThresholdDetailsv2Fragment | null
): SubstitutionThresholdSubformType | null {
  return useMemo(() => {
    if (!thresholdsData) return null;
    const { subtype: gratTrust } = thresholdsData;
    if (gratTrust?.__typename !== 'GRATTrust') throw new Error('Invalid data');

    const upsidePercent = (() => {
      if (
        !gratTrust.upsideThresholdPercent ||
        gratTrust.upsideThresholdPercent.equals(new Decimal(0))
      ) {
        return null;
      }

      return gratTrust.upsideThresholdPercent;
    })();

    const upsideValue = (() => {
      if (
        !gratTrust.upsideThresholdValue ||
        gratTrust.upsideThresholdValue.equals(new Decimal(0))
      ) {
        return null;
      }

      return gratTrust.upsideThresholdValue;
    })();

    const downsidePercent = (() => {
      if (
        !gratTrust.downsideThresholdPercent ||
        gratTrust.downsideThresholdPercent.equals(new Decimal(0))
      ) {
        return null;
      }

      return gratTrust.downsideThresholdPercent;
    })();

    // this should default to true if there are no values, or should be false if there's a defined downside
    const upsideAsTarget = (() => {
      if (
        gratTrust.upsideThresholdPercent &&
        !gratTrust.upsideThresholdPercent.equals(new Decimal(0))
      ) {
        return false;
      }

      return true;
    })();

    const valuesToSet: SubstitutionThresholdSubformType = {
      upsideAsTarget,
      downsidePercent: downsidePercent,
      upsidePercent: upsidePercent,
      upsideValue: upsideValue,
    };

    return valuesToSet;
  }, [thresholdsData]);
}
