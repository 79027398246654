import { useFormContext } from '@/components/react-hook-form';

import { Fields, Props } from '../types';
import { EditableList } from './EditableList';

export function UI(props: Props) {
  const { variant, householdId, grantorProfileId, subformValues } = props;
  const { control } = useFormContext<Fields>();

  if (variant === 'editableList') {
    return (
      <EditableList
        control={control}
        householdId={householdId}
        grantorProfileId={grantorProfileId}
        formFields={subformValues}
        emptyStateVariant="plain"
      />
    );
  }

  if (variant === 'editableListFancyEmptyState') {
    return (
      <EditableList
        control={control}
        householdId={householdId}
        grantorProfileId={grantorProfileId}
        formFields={subformValues}
        emptyStateVariant="fancy"
        beneficiaryPickerModalVariant="canCreateItems"
      />
    );
  }

  if (variant === 'none') {
    return <></>;
  }

  return <></>;
}
