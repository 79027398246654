import { findIndex } from 'lodash';
import { useMemo } from 'react';

import { useReportError } from '@/hooks/useReportError';
import { ClientPresentationSection } from '@/types/schema';

import {
  ClientPresentationBundleTypes,
  isStandaloneClientPresentationSection,
  PRESENTATION_SECTION_TO_STANDALONE_SLIDE_TYPE,
} from '../../clientPresentation.types';
import { useGuardedClientPresentationDesignerContext } from '../../contexts/clientPresentationDesigner.context';
import {
  PRESENTATION_SECTION_TO_REORDERABLE_SLIDE_TYPE,
  ReorderableSlideTypes,
} from './ReorderSlideElementList.types';

export function useReorderableItems() {
  const { reportError } = useReportError();
  const {
    visibleEntityPulids,
    visibleWaterfallPulids,
    visibleSectionOrder,
    standaloneSlides,
    orderedBundles,
    shouldShowItem,
  } = useGuardedClientPresentationDesignerContext();

  return useMemo(() => {
    return visibleSectionOrder.flatMap((section, index) => {
      // first, check if we're dealing with a standalone slide
      if (isStandaloneClientPresentationSection(section)) {
        const slide = standaloneSlides.find(
          (s) =>
            s.identifier ===
            PRESENTATION_SECTION_TO_STANDALONE_SLIDE_TYPE[section]
        );

        if (!slide?.identifier || !shouldShowItem(slide.identifier)) {
          return [];
        }

        const slideType =
          PRESENTATION_SECTION_TO_REORDERABLE_SLIDE_TYPE[section];

        return {
          id: slide.id,
          slideId: slide.id,
          slideType: slideType,
          display: slide.displayName,
          index,
        };
      }

      // otherwise, we're dealing with a bundle
      if (section === ClientPresentationSection.EstateWaterfallsGroup) {
        const estateWaterfallBundles = orderedBundles.filter(
          (b) => b.type === ClientPresentationBundleTypes.ESTATE_WATERFALL
        );

        const waterfallItems = {
          // this id is only used as a distinct identifier for the list item,
          // so it doesn't need to tie back to anything
          id: 'all-waterfalls',
          slideId: null,
          slideType: ReorderableSlideTypes.ESTATE_WATERFALL_BUNDLE,
          display: 'Estate waterfalls',
          index,
          children: estateWaterfallBundles
            .flatMap((b, index) => {
              if (b.identifier && !shouldShowItem(b.identifier)) return [];
              return {
                id: b.id,
                // there should always be at least one slide in an entity bundle,
                // but doing this for typesafety
                slideId: b.slides?.[0]?.id ?? null,
                slideType: ReorderableSlideTypes.SINGLE_WATERFALL,
                display: b.displayName,
                itemPulid: b.identifier,
                // visibleWaterfallPulids can be null in the initial case when we're showing *all*
                // the waterfalls; in that case, show them in the order they're returned from the API
                index: visibleWaterfallPulids
                  ? findIndex(
                      visibleWaterfallPulids,
                      (id) => id === b.identifier
                    )
                  : index,
              };
            })
            .sort((a, b) => a.index - b.index),
        };

        return waterfallItems;
      }

      if (section === ClientPresentationSection.EntitiesGroup) {
        const entitiesBundles = orderedBundles.filter(
          (b) => b.type === ClientPresentationBundleTypes.ENTITY
        );

        const entitiesItems = {
          // this id is only used as a distinct identifier for the list item,
          // so it doesn't need to tie back to anything
          id: 'all-entities',
          slideId: null,
          slideType: ReorderableSlideTypes.ENTITIES_BUNDLE,
          display: 'Entities',
          index,
          children: entitiesBundles
            .flatMap((b, index) => {
              if (b.identifier && !shouldShowItem(b.identifier)) return [];
              return {
                id: b.id,
                // there should always be at least one slide in an entity bundle,
                // but doing this for typesafety
                slideId: b.slides?.[0]?.id ?? null,
                slideType: ReorderableSlideTypes.SINGLE_ENTITY,
                display: b.displayName,
                itemPulid: b.identifier,
                // visibleEntityPulids can be null in the initial case when we're showing *all*
                // the entities; in that case, show them in the order they're returned from the API
                index: visibleEntityPulids
                  ? findIndex(visibleEntityPulids, (id) => id === b.identifier)
                  : index,
              };
            })
            .sort((a, b) => a.index - b.index),
        };

        return entitiesItems;
      }

      reportError(
        `Unexpected section ${section} in reorderable list; rendering nothing`,
        new Error('Unexpected section in reorderable list')
      );
      return [];
    });
  }, [
    orderedBundles,
    reportError,
    shouldShowItem,
    standaloneSlides,
    visibleEntityPulids,
    visibleSectionOrder,
    visibleWaterfallPulids,
  ]);
}
