import { UnreachableError } from '@/utils/errors';

import type { EntityType } from '../types/EntityType';

export const getDisplayNameFromEntityType = (entityType: EntityType) => {
  switch (entityType) {
    case 'grat':
      return 'GRAT';
    case 'ilit':
      return 'ILIT';
    case 'qprt':
      return 'QPRT';
    case 'slat':
      return 'SLAT';
    case 'irrevocable-trust':
      return 'Irrevocable trust';
    case 'revocable-trust':
      return 'Revocable trust';
    case 'individual-account':
      return 'Personal account';
    case 'joint-account':
      return 'Joint account';
    case 'retirement-account':
      return 'Retirement account';
    case 'qualified-tuition-account':
      return '529 plan';
    case 'custodial-account':
      return 'UTMA/UGMA custodial account';
    case 'clt':
      return 'CLT';
    case 'crt':
      return 'CRT';
    case 'daf':
      return 'DAF';
    case 'private-foundation':
      return 'Private foundation';
    case 'sole-proprietorship':
      return 'Sole proprietorship';
    case 'c-corp':
      return 'C corporation';
    case 's-corp':
      return 'S corporation';
    case 'llc':
      return 'LLC';
    case 'lp':
      return 'LP';
    case 'gp':
      return 'GP';
    case 'insurance-account':
      return 'Directly held life insurance';
    default:
      throw new UnreachableError({
        case: entityType,
        message: 'Unexpected entity type',
      });
  }
};
export type EntityDisplayName = ReturnType<typeof getDisplayNameFromEntityType>;
