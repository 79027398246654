import { PropsWithChildren, useState } from 'react';

import { TextEditorContext } from './textEditor.context';

interface UseTextEditorContextValueInput {
  theme: TextEditorContext['theme'];
}

function useTextEditorContextValue({
  theme: themeExternal,
}: UseTextEditorContextValueInput): TextEditorContext {
  const [theme, setTheme] = useState<TextEditorContext['theme']>(
    themeExternal || 'userGeneratedContent'
  );

  return {
    theme,
    setTheme,
  };
}

export const TextEditorProvider = ({
  children,
  theme,
}: PropsWithChildren<UseTextEditorContextValueInput>) => {
  const value = useTextEditorContextValue({
    theme,
  });
  return (
    <TextEditorContext.Provider value={value}>
      {children}
    </TextEditorContext.Provider>
  );
};
