import { useState } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';
import { getNodes } from '@/utils/graphqlUtils';

import {
  GetGiftProposalDetails_ProposalFragment,
  useGetGiftProposalDetailsQuery,
} from '../graphql/GetGiftProposalDetails.generated';

type GiftProposalDetails = ReturnType<typeof useGetGiftProposalDetailsQuery> &
  (
    | {
        giftProposal: GetGiftProposalDetails_ProposalFragment;
        loading: false;
      }
    | {
        giftProposal: typeof NEW_PROPOSAL_ID;
        loading: false;
      }
    | {
        giftProposal: null | undefined;
        loading: true;
      }
  );

export function useGiftProposalDetails(
  giftProposalId: string
): GiftProposalDetails {
  const isNewProposal = giftProposalId === NEW_PROPOSAL_ID;

  const [loadedGiftProposal, setLoadedGiftProposal] = useState<
    GetGiftProposalDetails_ProposalFragment | null | undefined
  >(null);

  const { createErrorFeedback } = useFeedback();

  const queryProps = useGetGiftProposalDetailsQuery({
    variables: {
      where: {
        id: giftProposalId,
      },
    },
    skip: isNewProposal,
    onError: createErrorFeedback(
      'There was an error loading the gift proposal details.'
    ),
    onCompleted: (data) => {
      if (!(data.proposals?.edges?.length === 1)) {
        throw new Error('Expected exactly one gift proposal to be returned.');
      }

      setLoadedGiftProposal(getNodes(data.proposals)[0]);
    },
  });

  const giftProposal = isNewProposal ? NEW_PROPOSAL_ID : loadedGiftProposal;
  const loading = !giftProposal;

  // Separate returns for better type inference
  return loading
    ? {
        ...queryProps,
        giftProposal,
        loading,
      }
    : {
        ...queryProps,
        giftProposal,
        loading,
      };
}
