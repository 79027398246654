// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGiftProposalDetails_ProposalFragment = { __typename?: 'Proposal', id: string, giftingProposal?: { __typename?: 'GiftingProposal', id: string, status: Types.GiftingProposalStatus } | null };

export type GetGiftProposalDetailsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ProposalWhereInput>;
}>;


export type GetGiftProposalDetailsQuery = { __typename?: 'Query', proposals: { __typename?: 'ProposalConnection', edges?: Array<{ __typename?: 'ProposalEdge', node?: { __typename?: 'Proposal', id: string, giftingProposal?: { __typename?: 'GiftingProposal', id: string, status: Types.GiftingProposalStatus } | null } | null } | null> | null } };

export const GetGiftProposalDetails_ProposalFragmentDoc = gql`
    fragment GetGiftProposalDetails_Proposal on Proposal {
  id
  giftingProposal {
    id
    status
  }
}
    `;
export const GetGiftProposalDetailsDocument = gql`
    query GetGiftProposalDetails($where: ProposalWhereInput) {
  proposals(where: $where) {
    edges {
      node {
        id
        ...GetGiftProposalDetails_Proposal
      }
    }
  }
}
    ${GetGiftProposalDetails_ProposalFragmentDoc}`;

/**
 * __useGetGiftProposalDetailsQuery__
 *
 * To run a query within a React component, call `useGetGiftProposalDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftProposalDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftProposalDetailsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetGiftProposalDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetGiftProposalDetailsQuery, GetGiftProposalDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiftProposalDetailsQuery, GetGiftProposalDetailsQueryVariables>(GetGiftProposalDetailsDocument, options);
      }
export function useGetGiftProposalDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiftProposalDetailsQuery, GetGiftProposalDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiftProposalDetailsQuery, GetGiftProposalDetailsQueryVariables>(GetGiftProposalDetailsDocument, options);
        }
export function useGetGiftProposalDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGiftProposalDetailsQuery, GetGiftProposalDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGiftProposalDetailsQuery, GetGiftProposalDetailsQueryVariables>(GetGiftProposalDetailsDocument, options);
        }
export type GetGiftProposalDetailsQueryHookResult = ReturnType<typeof useGetGiftProposalDetailsQuery>;
export type GetGiftProposalDetailsLazyQueryHookResult = ReturnType<typeof useGetGiftProposalDetailsLazyQuery>;
export type GetGiftProposalDetailsSuspenseQueryHookResult = ReturnType<typeof useGetGiftProposalDetailsSuspenseQuery>;
export type GetGiftProposalDetailsQueryResult = Apollo.QueryResult<GetGiftProposalDetailsQuery, GetGiftProposalDetailsQueryVariables>;
export const Operations = {
  Query: {
    GetGiftProposalDetails: 'GetGiftProposalDetails' as const
  },
  Fragment: {
    GetGiftProposalDetails_Proposal: 'GetGiftProposalDetails_Proposal' as const
  }
}