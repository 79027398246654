import { compact, first } from 'lodash';

import {
  ClientProfileFormShape,
  ClientProfileRelationshipFormShape,
} from '@/modules/clientProfiles/ClientProfile.types';
import { getTypeOrUndefined } from '@/modules/entities/EntitySubforms/utils/shared/common.utils';
import {
  ClientProfileRelationshipType,
  CreateClientProfileWithDetailsInput,
  CreateNewClientProfileRelationshipInput,
  UpdateClientProfileRelationship,
} from '@/types/schema';
import { getValueOrClear } from '@/utils/graphqlFormUtils';
import { getNodes } from '@/utils/graphqlUtils';

import { ClientProfileModalContextShape } from './ClientProfileModal.context';
import { BeneficiaryFormDataQuery } from './graphql/ClientProfileFormData.generated';
import { UpdateClientProfileModalMutationVariables } from './graphql/UpdateClientProfileModal.generated';

export function mapDataToForm({
  data,
  clientProfileId,
}: {
  data: BeneficiaryFormDataQuery;
  clientProfileId: string | null;
}): ClientProfileFormShape {
  const household = first(getNodes(data?.households));

  const clientProfile =
    household?.clientProfiles?.find(
      (profile) => profile.id === clientProfileId
    ) ?? null;

  const relationships: ClientProfileRelationshipFormShape[] =
    household?.possiblePrimaryClients
      .filter((primaryClient) => primaryClient.id !== clientProfileId)
      .map((primaryClient) => {
        const existingRelationship = clientProfile?.relationships?.find(
          (relationship) => relationship.toClientProfile.id === primaryClient.id
        );
        return {
          relationshipId: existingRelationship?.id || '',
          display: primaryClient.displayName,
          toClientProfileId: primaryClient.id,
          type: existingRelationship?.type || '',
        };
      }) || [];

  const formValues: ClientProfileFormShape = {
    id: clientProfile?.id ?? null,
    firstName: clientProfile?.firstName || '',
    middleName: clientProfile?.middleName || '',
    lastName: clientProfile?.lastName || '',
    suffix: clientProfile?.suffix || '',
    email: clientProfile?.email || '',
    address: {
      id: clientProfile?.address?.id ?? null,
      cityName: clientProfile?.address?.cityName ?? '',
      stateCode: clientProfile?.address?.stateCode || '',
    },
    dateOfBirth: clientProfile?.dateOfBirth || null,
    isBeneficiary: clientProfile?.isBeneficiary ?? false,
    isTrustee: clientProfile?.isTrustee ?? false,
    isGrantor: clientProfile?.isGrantor ?? false,
    relationships,
  };
  return formValues;
}

export function mapFormToCreatePayload(
  formData: ClientProfileFormShape,
  householdId: ClientProfileModalContextShape['householdId'],
  isPrimary: ClientProfileModalContextShape['isPrimary']
) {
  const addressInput = {
    cityName: formData.address.cityName || undefined,
    stateCode: formData.address.stateCode,
  };

  const createClientProfileInput: CreateClientProfileWithDetailsInput = {
    clientProfile: {
      address: addressInput.stateCode ? addressInput : undefined,
      householdID: householdId,
      email: formData.email,
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      suffix: formData.suffix,
      isBeneficiary: formData.isBeneficiary,
      isGrantor: formData.isGrantor,
      isPrimary: isPrimary,
      isTrustee: formData.isTrustee,
      dateOfBirth: getTypeOrUndefined(formData.dateOfBirth),
    },
    relationships: compact(
      formData.relationships.map<CreateNewClientProfileRelationshipInput | null>(
        (relationship) => {
          if (relationship.type === '') {
            return null;
          }
          return {
            toClientProfileID: relationship.toClientProfileId,
            type: relationship.type,
          };
        }
      )
    ),
  };

  return { input: createClientProfileInput };
}

export function mapFormToUpdatePayload(
  formData: ClientProfileFormShape,
  householdId: ClientProfileModalContextShape['householdId'],
  clientProfileId: ClientProfileModalContextShape['clientProfileId']
):
  | {
      variables: UpdateClientProfileModalMutationVariables;
      errorMessage?: never;
    }
  | { variables?: never; errorMessage: string } {
  if (!clientProfileId) {
    return { errorMessage: 'No client profile ID found' };
  }

  if (!householdId) {
    return { errorMessage: 'No household ID found' };
  }

  const clientRelationships = formData.relationships
    .filter((relationship) => !!relationship.type)
    .map<UpdateClientProfileRelationship>((relationship) => ({
      fromClientProfileID: clientProfileId,
      toClientProfileID: relationship.toClientProfileId,
      id: relationship.relationshipId,
      relationship: {
        type: relationship.type as ClientProfileRelationshipType,
      },
    }));

  const variables: UpdateClientProfileModalMutationVariables = {
    clientProfileId,
    clientProfile: {
      address: formData.address.stateCode
        ? {
            cityName: getTypeOrUndefined<string>(formData.address.cityName),
            stateCode: getTypeOrUndefined<string>(formData.address.stateCode),
            clearCityName: !formData.address.cityName,
          }
        : undefined,
      clearAddress: !formData.address.stateCode,
      householdID: householdId,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      isBeneficiary: formData.isBeneficiary,
      isGrantor: formData.isGrantor,
      isPrimary: formData.isPrimary,
      isTrustee: formData.isTrustee,
      ...getValueOrClear('dateOfBirth', formData.dateOfBirth),
      ...getValueOrClear('suffix', formData.suffix),
      ...getValueOrClear('middleName', formData.middleName),
    },
    hasRelationships: !!clientRelationships.length,
    householdId: householdId,
    clientRelationships,
  };
  return { variables };
}
