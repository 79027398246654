// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EntityMapTotalValueMetricsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.HouseholdWhereInput>;
  entityWhere?: Types.InputMaybe<Types.EntityWhereInput>;
}>;


export type EntityMapTotalValueMetricsQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, estateValueMetrics: { __typename?: 'HouseholdMetrics', inEstateValue: CurrencyUSD, outOfEstateValue: CurrencyUSD, totalEstateValue: CurrencyUSD } } | null } | null> | null } };


export const EntityMapTotalValueMetricsDocument = gql`
    query EntityMapTotalValueMetrics($where: HouseholdWhereInput, $entityWhere: EntityWhereInput) {
  households(where: $where) {
    edges {
      node {
        id
        estateValueMetrics(where: $entityWhere) {
          inEstateValue
          outOfEstateValue
          totalEstateValue
        }
      }
    }
  }
}
    `;

/**
 * __useEntityMapTotalValueMetricsQuery__
 *
 * To run a query within a React component, call `useEntityMapTotalValueMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityMapTotalValueMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityMapTotalValueMetricsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      entityWhere: // value for 'entityWhere'
 *   },
 * });
 */
export function useEntityMapTotalValueMetricsQuery(baseOptions?: Apollo.QueryHookOptions<EntityMapTotalValueMetricsQuery, EntityMapTotalValueMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EntityMapTotalValueMetricsQuery, EntityMapTotalValueMetricsQueryVariables>(EntityMapTotalValueMetricsDocument, options);
      }
export function useEntityMapTotalValueMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EntityMapTotalValueMetricsQuery, EntityMapTotalValueMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EntityMapTotalValueMetricsQuery, EntityMapTotalValueMetricsQueryVariables>(EntityMapTotalValueMetricsDocument, options);
        }
export function useEntityMapTotalValueMetricsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<EntityMapTotalValueMetricsQuery, EntityMapTotalValueMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EntityMapTotalValueMetricsQuery, EntityMapTotalValueMetricsQueryVariables>(EntityMapTotalValueMetricsDocument, options);
        }
export type EntityMapTotalValueMetricsQueryHookResult = ReturnType<typeof useEntityMapTotalValueMetricsQuery>;
export type EntityMapTotalValueMetricsLazyQueryHookResult = ReturnType<typeof useEntityMapTotalValueMetricsLazyQuery>;
export type EntityMapTotalValueMetricsSuspenseQueryHookResult = ReturnType<typeof useEntityMapTotalValueMetricsSuspenseQuery>;
export type EntityMapTotalValueMetricsQueryResult = Apollo.QueryResult<EntityMapTotalValueMetricsQuery, EntityMapTotalValueMetricsQueryVariables>;
export const Operations = {
  Query: {
    EntityMapTotalValueMetrics: 'EntityMapTotalValueMetrics' as const
  }
}