import { Stack, SxProps } from '@mui/material';
import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import React from 'react';

import { diagnostics } from '@/utils/diagnostics';

import { SidebarTab } from '../SidebarTab/SidebarTab';

interface RowType {
  label: ReactNode;
  id: string;
}

const rowHasVisibleTop = ({ id }: RowType): boolean => {
  const rowEl = document.getElementById(id);
  if (!rowEl) {
    diagnostics.warn(
      `Got row ID "#${id}" that does not have associated row after scroll`
    );
    return false;
  }

  const { top } = rowEl?.getBoundingClientRect() || {};

  return top >= 0;
};

export interface InPageNavigationBoxProps {
  rows: RowType[];
  observerOptions: IntersectionObserverInit;
  contentContainerId: string;
  sx?: SxProps;
  scrollOptions?: ScrollIntoViewOptions | boolean;
}

export interface InPageNavigationContextType {
  initialRowIdSubstring?: string;
}

const InPageNavigationContext = createContext<InPageNavigationContextType>({
  initialRowIdSubstring: undefined,
});

export function InPageNavigationContextProvider({
  initialRowIdSubstring,
  children,
}: InPageNavigationContextType & { children: ReactNode }) {
  return (
    <InPageNavigationContext.Provider value={{ initialRowIdSubstring }}>
      {children}
    </InPageNavigationContext.Provider>
  );
}

export const InPageNavigationBox: FC<InPageNavigationBoxProps> = ({
  rows,
  sx,
  observerOptions,
  scrollOptions,
  contentContainerId,
}) => {
  const [clickedElement, setClickedElement] = useState<string>('');

  // these refs are used to communicate between event handlers, and should never trigger redraws of the component
  const ignoreVisibilityObserver = useRef<boolean>(true);
  const prevScrollTop = useRef<number>(0);
  const scrollTimer = useRef<number | null>(null);

  // set the currently-scrolled element and navigate to it
  let initialRowSubstring: string | undefined = undefined;

  try {
    const context = useContext(InPageNavigationContext);
    initialRowSubstring = context.initialRowIdSubstring;
  } catch (err) {
    diagnostics.warn(
      'InPageNavigationContext unavailable, initial scrolling unavailable'
    );
  }

  const initialRowId = useMemo(() => {
    if (!initialRowSubstring) {
      return undefined;
    }
    // undefined case is handled above
    return rows.find(({ id }) => id.includes(initialRowSubstring!))?.id;
  }, [initialRowSubstring, rows]);

  const [scrollElementId, setScrollElementId] = useState<string>(() => {
    return initialRowId || rows[0]?.id || '';
  });

  useEffect(() => {
    /*
     * IntersectionObserver is used to track the user's position in the sidebar; as the
     * page is scrolled, the lowest fully-visible element will be highlighted in the sidebar.
     *
     * In cases where two shorter elements follow each other, it's possible both will be visible
     * in the viewport at the same time.  In these cases, preference will be given to the lower
     * element.  This can be tweaked by changing the `threshold` value of `observerOptions`
     * (defaults to 1.0, element fully visible).
     */
    const observer = new IntersectionObserver(
      (intersections) => {
        if (ignoreVisibilityObserver.current) {
          return;
        }
        const visibleElements = intersections
          .filter(({ isIntersecting }) => isIntersecting)
          .map(({ target }) => target.id);
        const lastElement = visibleElements.pop() || null;
        if (!lastElement) {
          return;
        }
        setScrollElementId(lastElement);
      },
      // using Object.assign to provide a sane default that won't get overridden if `threshold` isn't passed in
      Object.assign({ threshold: 1.0 }, observerOptions)
    );

    rows.forEach(({ id }) => {
      const target = document.querySelector(`#${id}`);
      if (target) {
        observer.observe(target);
      } else {
        diagnostics.warn(
          `Attempting to watch ID "#${id}" for visibility changes, but the element was not found on the page.`
        );
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [observerOptions, rows]);

  useLayoutEffect(() => {
    // give react another event loop to make sure everything is rendered
    setTimeout(() => {
      const element =
        (initialRowId && document.getElementById(initialRowId)) || null;
      if (element) {
        ignoreVisibilityObserver.current = true;
        element.scrollIntoView({
          behavior: 'instant',
          block: 'start',
        });
        ignoreVisibilityObserver.current = false;
      }
    });
  }, [initialRowId]);

  /*
   * to handle some weirdness around clicking elements, ignore the intersection observer results
   * until the scroll has ended; once the scroll ends, capture the top of the scrolled container
   * and listen for the first scroll.  on first scroll, determine the direction of the scroll
   * (compare against the top of the scrolled container after click scroll), and manually
   * update the scrolled position before continuing to listen to the intersection observer
   */
  const firstScrollAfterEndHandler = () => {
    const scrollContainer = document.getElementById(contentContainerId || '');
    if (!scrollContainer) {
      diagnostics.warn(
        `Tried to get top position of "#${contentContainerId}", but element was not found`
      );
      window.removeEventListener('scroll', firstScrollAfterEndHandler, true);
      return;
    }

    const { top: currentScrollTop } = scrollContainer.getBoundingClientRect();

    const direction: 'UP' | 'DOWN' =
      currentScrollTop >= prevScrollTop.current ? 'UP' : 'DOWN';

    // give a little wiggle room when scrolling after click
    if (Math.abs(currentScrollTop - prevScrollTop.current) < 40) {
      return;
    }

    // reset for next click
    prevScrollTop.current = 0;
    window.removeEventListener('scroll', firstScrollAfterEndHandler, true);

    if (!ignoreVisibilityObserver.current == true) {
      setClickedElement('');
    }

    if (direction === 'DOWN') {
      // if scrolling down, find the first element whose top is visible
      const topRow = rows.find(rowHasVisibleTop);

      if (topRow) {
        setScrollElementId(topRow.id);
      }
    } else {
      // if scrolling up, find the last element whose top is not visible
      const firstTopVisibleIndex = rows.findIndex(rowHasVisibleTop);

      if (firstTopVisibleIndex === -1) {
        diagnostics.warn('Could not find first visible element on page');
      } else if (firstTopVisibleIndex === 0 && rows[0]) {
        setScrollElementId(rows[0].id);
      } else {
        setScrollElementId(rows[firstTopVisibleIndex - 1]!.id);
      }
    }
  };

  const scrollEndHandler = () => {
    ignoreVisibilityObserver.current = false;
    const scrollContainer = document.getElementById(contentContainerId || '');
    if (!scrollContainer) {
      diagnostics.warn(
        `Tried to get top position of "#${contentContainerId}", but element was not found`
      );
    } else {
      const { top } = scrollContainer.getBoundingClientRect();
      prevScrollTop.current = top;
    }
    window.addEventListener('scroll', firstScrollAfterEndHandler, true);
    if ('onscrollend' in window) {
      window.removeEventListener('scrollend', scrollEndHandler, true);
    }
  };

  // this should not be necessary once safari ships support for the `scrollend` event
  const afterClickScrollHandler = () => {
    if (scrollTimer.current !== null) {
      window.clearTimeout(scrollTimer.current);
      scrollTimer.current = null;
      window.removeEventListener('scroll', afterClickScrollHandler);
    }
    scrollTimer.current = window.setTimeout(scrollEndHandler, 100);
  };

  const onClickRow = (id: string) => () => {
    setClickedElement(id);
    const element = document.getElementById(id);
    if (element) {
      ignoreVisibilityObserver.current = true;
      if ('onscrollend' in window) {
        window.addEventListener('scrollend', scrollEndHandler, true);
      } else {
        (window as Window).addEventListener(
          'scroll',
          afterClickScrollHandler,
          true
        );
      }

      element.scrollIntoView(
        Object.assign(
          {
            behavior: 'smooth',
            block: 'center',
          },
          scrollOptions
        )
      );
    } else {
      diagnostics.warn(
        `User attempted to navigate to ID "#${id}", but it was not found on the page.`
      );
    }
  };

  const activeElement = clickedElement || scrollElementId;

  return (
    <Stack sx={sx}>
      {rows.map(({ label, id }, index) => {
        return (
          <SidebarTab
            key={index}
            isActive={activeElement === id}
            onClick={onClickRow(id)}
          >
            {label}
          </SidebarTab>
        );
      })}
    </Stack>
  );
};
