import { useApolloClient } from '@apollo/client';
import { Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { createPortal, flushSync } from 'react-dom';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { SectionHeaderWithAccent } from '@/components/display/SectionHeaderWithAccent/SectionHeaderWithAccent';
import { useDebouncedFormValidationTrigger } from '@/components/form/formAwareInputs/hooks/useDebouncedFormValidationTrigger';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { AISuggestionsMatcherProvider } from '@/modules/aiSuggestions/AISuggestionsMatcher/context/AISuggestionsMatcher.provider';
import { useUpdateSubformArrayField } from '@/modules/aiSuggestions/AISuggestionsMatcher/hooks/useUpdateSubformArrayField';
import { useEntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { SelectedSuggestionsProvider } from '@/modules/aiSuggestions/context/SelectedSuggestion.provider';
import { useHouseholdDocumentsViewerContext } from '@/modules/documents/components/HouseholdDocumentsViewer/context/HouseholdDocumentsViewer.context';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { useEditEntitySplitScreenModalContext } from '@/modules/entities/EditEntitySplitScreen/contexts/editEntitySplitScreenModal.context';
import {
  ConfirmResolveDuplicatesModal,
  ConfirmSectionSwitchModal,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.components';
import {
  CombinedSupportedSubformFieldTypes,
  EditEntitySection,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import {
  getActiveTabForSection,
  getArrayFieldsWithIDForSection,
  getUpdateInputFromFormValuesOrError,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.utils';
import {
  CloseEditEntityFormButton,
  SubmitEditEntityFormButton,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenFooter';
import { EditEntitySplitScreenFormSectionSwitcher } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenFormSectionSwitcher';
import { EditEntitySplitScreenFormSuggestionsMatcher } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenFormSuggestionsMatcher';
import { useUpdateEntityAndAcknowledgeSuggestionsMutation } from '@/modules/entities/EditEntitySplitScreen/graphql/editEntitySplitScreen.generated';
import { SUBMIT_ERROR_MESSAGE } from '@/modules/entities/EntitySubforms/EntitySubforms.constants';
import { useSubforms } from '@/modules/entities/EntitySubforms/useSubforms';
import { EstateWaterfallDocument } from '@/modules/estateWaterfall/graphql/EstateWaterfall.generated';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { AugmentedUpdateEntityInput } from '@/types/schema';

import { AIDocumentStatusBanner } from '../../aiSuggestions/components/AIDocumentStatusBanner';

function EditEntitySplitScreenFormInner() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const navigate = useNavigate();

  const client = useApolloClient();

  const [updateEntityAndSuggestions] =
    useUpdateEntityAndAcknowledgeSuggestionsMutation({
      onError: (error) => {
        showFeedback(SUBMIT_ERROR_MESSAGE);
        reportError('error in update entity mutation', error);
      },
    });

  const { householdId } = useHouseholdDetailsContext();

  const { newlyUploadedDocumentId } = useHouseholdDocumentsViewerContext();

  const {
    entityId,
    entityType,
    entitySubtypeId: subtypeId,
    displayName,
  } = useEntityDetailsContext();

  const {
    currentSection,
    currentSectionSubformConfig,
    formActionsPortalRef,
    navigateAfterSave,
    setCurrentSection,
    handleCloseModal,
  } = useEditEntitySplitScreenModalContext();

  const { acknowledgedSuggestions } = useEntitySuggestionsContext();

  const subforms = useSubforms(
    currentSectionSubformConfig ? [currentSectionSubformConfig] : [],
    { renderFromSplitScreen: true }
  );

  const {
    handleSubmit,
    watch,
    trigger,
    shouldBlockNavigation,
    setShouldBlockNavigation,
    setFocus,
    formState: { isDirty },
  } = useFormContext<CombinedSupportedSubformFieldTypes>();

  useDebouncedFormValidationTrigger(trigger, watch);

  const { getFirstDuplicateFieldName } = useUpdateSubformArrayField();

  const [showResolveDuplicatesModal, setShowResolveDuplicatesModal] =
    useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [requestedSection, setRequestedSection] =
    useState<EditEntitySection | null>(null);

  useEffect(() => {
    setShouldBlockNavigation(false);
  }, [currentSection, setShouldBlockNavigation]);

  useEffect(() => {
    if (newlyUploadedDocumentId) {
      // Submitting the DocumentUploaderForm clears the global shouldBlockNavigation state.
      // We need to set it back to true if the entity form was dirty before the document upload.
      setShouldBlockNavigation(isDirty);
    }
  }, [newlyUploadedDocumentId, setShouldBlockNavigation, isDirty]);

  const submitUpdateEntityMutation = useCallback(
    async (input: AugmentedUpdateEntityInput, afterSaveAction: () => void) => {
      return await updateEntityAndSuggestions({
        variables: {
          entityInput: input,
          acknowledgeSuggestionsInput: Object.values(
            acknowledgedSuggestions
          ).map((s) => ({
            suggestionID: s.suggestionID,
            status: s.status,
            clientProfileID: s.clientProfileId,
            clientOrganizationID: s.clientOrganizationId,
            entityID: s.entityId,
          })),
        },
        onCompleted: async (data) => {
          showFeedback('Entity updated successfully', {
            variant: 'success',
          });

          await client.refetchQueries({
            include: [EstateWaterfallDocument],
            updateCache(cache) {
              cache.evict({ id: cache.identify(data.updateEntity) });
              cache.evict({ fieldName: 'entities' });
              cache.gc();
            },
          });

          afterSaveAction();
        },
      });
    },
    [acknowledgedSuggestions, client, showFeedback, updateEntityAndSuggestions]
  );

  const onValidSubmission = useCallback(
    async (
      values: CombinedSupportedSubformFieldTypes,
      afterSaveAction: () => void
    ) => {
      if (!entityId || !entityType || !subtypeId || !householdId) {
        throw new Error(
          'Missing required entityId, entityType, subtypeId, or householdId'
        );
      }

      const result = getUpdateInputFromFormValuesOrError(
        {
          entityType,
          subtypeId,
          entityId,
          householdId,
        },
        values
      );

      if (result.input) {
        return submitUpdateEntityMutation(result.input, afterSaveAction);
      }

      if (result.validationError) {
        showFeedback(result.validationError.message);
        return Promise.resolve();
      }

      showFeedback(SUBMIT_ERROR_MESSAGE);
      reportError(
        'unknown error when validating update entity form values',
        result.error
      );
    },
    [
      entityId,
      entityType,
      householdId,
      reportError,
      showFeedback,
      submitUpdateEntityMutation,
      subtypeId,
    ]
  );

  const handleSubmitAndClose = useCallback(() => {
    flushSync(() => {
      setShouldBlockNavigation(false);
    });

    function closeModalAndNavigate() {
      if (!entityType) {
        return;
      }

      const entityDetailsTab = getActiveTabForSection(
        entityType,
        currentSection
      );

      if (navigateAfterSave && entityDetailsTab && householdId && entityId) {
        const entityDetailsPage = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
          {
            householdId,
            entityId,
          },
          { entityTab: entityDetailsTab }
        );

        navigate(entityDetailsPage);
      }

      handleCloseModal();
    }

    void handleSubmit(
      (values) => {
        return onValidSubmission(values, closeModalAndNavigate);
      },
      () => {
        setShouldBlockNavigation(isDirty);
      }
    )();
  }, [
    currentSection,
    entityId,
    entityType,
    handleCloseModal,
    handleSubmit,
    householdId,
    isDirty,
    navigate,
    navigateAfterSave,
    onValidSubmission,
    setShouldBlockNavigation,
  ]);

  const handleSubmitAndSwitchSection = useCallback(
    (newSection: EditEntitySection) => {
      flushSync(() => {
        setShouldBlockNavigation(false);
      });

      void handleSubmit(
        (values) => {
          return onValidSubmission(values, () => {
            setCurrentSection(newSection);
            setShouldBlockNavigation(false);
          });
        },
        () => {
          setShouldBlockNavigation(isDirty);
        }
      )();
    },
    [
      handleSubmit,
      isDirty,
      onValidSubmission,
      setCurrentSection,
      setShouldBlockNavigation,
    ]
  );

  const handlePreSubmit = useCallback(
    (afterSubmitSection?: EditEntitySection) => {
      const currentSectionFieldsToCheck = getArrayFieldsWithIDForSection(
        entityType,
        currentSection
      );

      const firstDuplicate = getFirstDuplicateFieldName(
        currentSectionFieldsToCheck
      );

      // If there are duplicates, show the modal and focus on the first one.
      if (firstDuplicate) {
        setShowResolveDuplicatesModal(true);
        setFocus(firstDuplicate);
        return;
      }

      // No duplicates. Carry on with the submission.
      if (afterSubmitSection) {
        if (shouldBlockNavigation) {
          setRequestedSection(afterSubmitSection);
          setShowConfirmationModal(true);
          return;
        }
        handleSubmitAndSwitchSection(afterSubmitSection);
      } else {
        handleSubmitAndClose();
      }
    },
    [
      entityType,
      currentSection,
      getFirstDuplicateFieldName,
      setFocus,
      shouldBlockNavigation,
      handleSubmitAndSwitchSection,
      handleSubmitAndClose,
    ]
  );

  const handleCloseResolveDuplicatesModal = useCallback(() => {
    setShowResolveDuplicatesModal(false);
  }, []);

  const handleCloseConfirmationModal = useCallback(
    (reason: 'confirm' | 'cancel') => {
      setRequestedSection(null);
      setShowConfirmationModal(false);

      if (reason === 'confirm' && requestedSection) {
        // When confirming the section switch, save the current section's form first.
        handleSubmitAndSwitchSection(requestedSection);
      }
    },
    [handleSubmitAndSwitchSection, requestedSection]
  );

  const onAiJobCompleted = useCallback(() => {
    showFeedback('Suggestions refreshed from new document', {
      autoHideDuration: 1000,
      variant: 'success',
    });
  }, [showFeedback]);

  return (
    <>
      <SectionHeaderWithAccent
        header={{
          heading: displayName,
        }}
        rightHeaderContent={
          <EditEntitySplitScreenFormSectionSwitcher
            onSwitchSection={handlePreSubmit}
          />
        }
      />
      <AIDocumentStatusBanner onJobCompleted={onAiJobCompleted} />
      <EditEntitySplitScreenFormSuggestionsMatcher />
      {subforms?.[0]}
      {formActionsPortalRef.current &&
        createPortal(
          <CloseEditEntityFormButton onClose={handleCloseModal} />,
          formActionsPortalRef.current
        )}
      {formActionsPortalRef.current &&
        createPortal(
          <SubmitEditEntityFormButton onSubmit={handlePreSubmit} />,
          formActionsPortalRef.current
        )}
      <ConfirmResolveDuplicatesModal
        isOpen={showResolveDuplicatesModal}
        onClose={handleCloseResolveDuplicatesModal}
      />
      <ConfirmSectionSwitchModal
        isOpen={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
      />
    </>
  );
}

interface EditEntitySplitScreenFormProps {
  initialSubformValues: CombinedSupportedSubformFieldTypes;
}

export function EditEntitySplitScreenForm({
  initialSubformValues,
}: EditEntitySplitScreenFormProps) {
  const { setSelectedSuggestion } = useEditEntitySplitScreenModalContext();

  const formMethods = useForm<CombinedSupportedSubformFieldTypes>({
    defaultValues: initialSubformValues,
  });

  return (
    <Stack
      direction="column"
      height="100%"
      p={3}
      gap={3}
      sx={{ overflowY: 'scroll' }}
    >
      <FormProvider {...formMethods}>
        <SelectedSuggestionsProvider onLocateSuggestion={setSelectedSuggestion}>
          <AISuggestionsMatcherProvider>
            <EditEntitySplitScreenFormInner />
          </AISuggestionsMatcherProvider>
        </SelectedSuggestionsProvider>
      </FormProvider>
    </Stack>
  );
}
