import { Box, Grid, Typography } from '@mui/material';
import { noop } from 'lodash';
import { ComponentProps, useCallback } from 'react';

import { ActionTile } from '@/components/layout/ActionTile/ActionTile';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import {
  ENTITY_TYPES,
  NEW_ENTITY_SENTINEL,
} from '@/modules/entities/entities.constants';
import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';
import {
  GiftDesignerStages,
  GratDesignerStages,
  ROUTE_KEYS,
} from '@/navigation/constants';
import { COLORS } from '@/styles/tokens/colors';

interface ModelANewStrategyModalProps {
  isOpen: boolean;
  handleClose: () => void;
  householdId: string;
}

function GridTile({
  ...props
}: Omit<ComponentProps<typeof ActionTile>, 'variant'>) {
  return (
    <Grid item sm={12} md={4}>
      <ActionTile
        {...props}
        containerSx={{ height: '100%', width: '100%', py: 3 }}
        innerSx={{ justifyContent: 'flex-start', height: '100%' }}
        variant="small"
      />
    </Grid>
  );
}

function ComingSoon() {
  return (
    <Box
      sx={{
        px: 1,
        borderLeft: `1px solid ${COLORS.GRAY[100]}`,
        borderTop: `1px solid ${COLORS.GRAY[100]}`,
        borderTopLeftRadius: 4,
        backgroundColor: COLORS.GRAY[100],
      }}
    >
      <Typography variant="h6" component="span" color={COLORS.GRAY[400]}>
        Coming soon
      </Typography>
    </Box>
  );
}

export function ModelANewStrategyModal({
  isOpen,
  handleClose,
  householdId,
}: ModelANewStrategyModalProps) {
  const { navigate } = useNavigateToRoute();
  const trackUserEvent = useTrackUserEvent();

  const handleGratClick = useCallback(() => {
    trackUserEvent('proposal created grat');
    navigate(ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER, {
      householdId,
      entityId: NEW_ENTITY_SENTINEL,
      designerStage: GratDesignerStages.BASIC_INFORMATION,
      entityType: ENTITY_TYPES.GRAT,
    });
    handleClose();
  }, [handleClose, householdId, navigate, trackUserEvent]);

  const handleGiftingClick = useCallback(() => {
    trackUserEvent('proposal created gifting');
    navigate(ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER, {
      householdId,
      proposalId: NEW_PROPOSAL_ID,
      designerStage: GiftDesignerStages.BASIC_INFORMATION,
    });
    handleClose();
  }, [handleClose, householdId, navigate, trackUserEvent]);

  const handleCharitableDesignerClick = useCallback(
    (variant: 'crt' | 'clt') => {
      return () => {
        trackUserEvent(`proposal created ${variant}`);
        navigate(
          ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION,
          { householdId, proposalId: NEW_PROPOSAL_ID, variant }
        );
        handleClose();
      };
    },
    [handleClose, householdId, navigate, trackUserEvent]
  );

  return (
    <DialogModal
      isOpen={isOpen}
      onClose={handleClose}
      heading="Create a new analysis"
    >
      <Grid container spacing={3}>
        <GridTile
          heading="GRAT"
          body="Model the impact of funding a Grantor Retained Annuity Trust"
          onClick={handleGratClick}
        />
        <GridTile
          heading="Gift"
          body="Model the impact of gifts, such as funding a SLAT, FLP, DAF, or making annual exclusion gifts"
          onClick={handleGiftingClick}
        />
        <GridTile
          heading="CRT"
          body="Model the impact of funding a Charitable Remainder Trust"
          onClick={handleCharitableDesignerClick('crt')}
        />
        <GridTile
          heading="CLT"
          body="Model the impact of funding a Charitable Lead Trust"
          onClick={handleCharitableDesignerClick('clt')}
        />
        <GridTile
          heading="Installment sale"
          body="Model the impact of selling an asset through an installment sale to a trust"
          footer={<ComingSoon />}
          onClick={noop}
          disabled
        />
        <GridTile
          heading="Intra-family loan"
          body="Model the impact of loaning an asset through an intra-family loan to a trust"
          footer={<ComingSoon />}
          onClick={noop}
          disabled
        />
      </Grid>
    </DialogModal>
  );
}
