// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConnectHouseholdToAssetProviderMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.UpdateHouseholdInput;
}>;


export type ConnectHouseholdToAssetProviderMutation = { __typename?: 'Mutation', updateHousehold: { __typename?: 'Household', id: string, integrationClients?: Array<{ __typename?: 'IntegrationClient', id: string }> | null } };


export const ConnectHouseholdToAssetProviderDocument = gql`
    mutation ConnectHouseholdToAssetProvider($id: ID!, $input: UpdateHouseholdInput!) {
  updateHousehold(id: $id, input: $input) {
    id
    integrationClients {
      id
    }
  }
}
    `;
export type ConnectHouseholdToAssetProviderMutationFn = Apollo.MutationFunction<ConnectHouseholdToAssetProviderMutation, ConnectHouseholdToAssetProviderMutationVariables>;

/**
 * __useConnectHouseholdToAssetProviderMutation__
 *
 * To run a mutation, you first call `useConnectHouseholdToAssetProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectHouseholdToAssetProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectHouseholdToAssetProviderMutation, { data, loading, error }] = useConnectHouseholdToAssetProviderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConnectHouseholdToAssetProviderMutation(baseOptions?: Apollo.MutationHookOptions<ConnectHouseholdToAssetProviderMutation, ConnectHouseholdToAssetProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectHouseholdToAssetProviderMutation, ConnectHouseholdToAssetProviderMutationVariables>(ConnectHouseholdToAssetProviderDocument, options);
      }
export type ConnectHouseholdToAssetProviderMutationHookResult = ReturnType<typeof useConnectHouseholdToAssetProviderMutation>;
export type ConnectHouseholdToAssetProviderMutationResult = Apollo.MutationResult<ConnectHouseholdToAssetProviderMutation>;
export type ConnectHouseholdToAssetProviderMutationOptions = Apollo.BaseMutationOptions<ConnectHouseholdToAssetProviderMutation, ConnectHouseholdToAssetProviderMutationVariables>;
export const Operations = {
  Mutation: {
    ConnectHouseholdToAssetProvider: 'ConnectHouseholdToAssetProvider' as const
  }
}