import { Box, Stack, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { ClearInputTextButton } from '@/components/form/baseInputs/Button/ClearInputTextButton';
import { TextInput } from '@/components/form/baseInputs/TextInput';
import { SearchSmIcon } from '@/components/icons/SearchSmIcon';
import { FloatingMenuContainer } from '@/components/layout/FloatingMenuContainer';
import { InfoTooltip } from '@/components/poppers/Tooltip/InfoTooltip';
import { useDebouncedFn } from '@/hooks/useDebouncedFn';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { AsyncJobStatus_AsyncJobFragment } from '@/modules/asyncJobs/graphql/AsyncJobs.generated';
import { usePollForJobCompletion } from '@/modules/asyncJobs/hooks/usePollForJobCompletion';
import { LuminaryAiBetaLogo } from '@/modules/documents/components/LuminaryAiLogo';
import { useDocumentStatusQuery } from '@/modules/documents/graphql/DocumentStatus.generated';
import { DocumentSearchGeneratedResponse } from '@/modules/pdf/DocumentSearch/DocumentSearchGeneratedResponse';
import { useAICapabilitiesEnabled } from '@/modules/tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import { COLORS } from '@/styles/tokens/colors';
import { AsyncJobStatus } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { useAnnotatedPDFViewerContext } from '../PDFViewer/AnnotatedPDFViewer/context/AnnotatedPDFViewer.context';
import { DocumentFooterProps } from '../PDFViewer/DocumentFooter';
import { DocumentSearchResults } from './DocumentSearchResults';
import { useSearchDocumentWithResponseLazyQuery } from './graphql/DocumentSearch.generated';

export function DocumentSearchBar({ isDocumentLoading }: DocumentFooterProps) {
  const trackUserEvent = useTrackUserEvent();
  const enableAiCapabilities = useAICapabilitiesEnabled();

  const {
    documentId,
    annotations,
    setAnnotations,
    activeAnnotationIndex,
    setActiveAnnotationIndex,
    overrideSearchQuery,
    onUpdateSearchQuery,
    overrideSearchLoading,
  } = useAnnotatedPDFViewerContext();

  const containerElementRef = useRef<HTMLDivElement>(null);
  const [positioningYOffset, setPositioningYOffset] = useState<null | number>(
    0
  );
  const [searchQuery, setSearchQuery] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [initialAsyncJob, setInitialAsyncJob] = useState<
    AsyncJobStatus_AsyncJobFragment | undefined | null
  >(undefined);
  const [searchResponse, setSearchResponse] = useState<string>('');
  const [searchWithResponseQuery] = useSearchDocumentWithResponseLazyQuery();

  useEffect(() => {
    setSearchQuery(overrideSearchQuery || '');
    setSearchResponse('');
  }, [overrideSearchQuery]);

  const handleSearch = useCallback(async () => {
    const startTime = Date.now();
    if (!documentId) {
      // We should never get here, but just in case and for type safety.
      throw new Error('Document ID is required to search');
    }
    if (!searchQuery.length) return;

    // Reset various state variables
    setLoadingSearch(true);
    setAnnotations(undefined);
    setActiveAnnotationIndex(0);
    setSearchError(false);
    setSearchResponse('');
    onUpdateSearchQuery?.();

    await searchWithResponseQuery({
      variables: {
        input: {
          documentID: documentId,
          query: searchQuery,
          first: 1,
        },
      },
      onCompleted: (data) => {
        trackUserEvent('document searched', {
          query: searchQuery,
          success: true,
          duration: Date.now() - startTime,
        });
        const results = data.queryDocument.boundingRegions || [];
        const boundingRegions = results.flatMap((result) => {
          return {
            ...result,
            documentID: documentId,
          };
        });
        setAnnotations(boundingRegions);
        setSearchResponse(data.queryDocument.response);
      },
      onError: () => {
        trackUserEvent('document searched', {
          query: searchQuery,
          success: false,
          duration: Date.now() - startTime,
        });
        setAnnotations(undefined);
        setSearchError(true);
      },
    });
    setLoadingSearch(false);
  }, [
    documentId,
    searchQuery,
    setAnnotations,
    setActiveAnnotationIndex,
    onUpdateSearchQuery,
    searchWithResponseQuery,
    trackUserEvent,
  ]);

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
    setSearchError(false);
    setAnnotations(undefined);
    setActiveAnnotationIndex(0);
    setSearchResponse('');
    onUpdateSearchQuery?.();
  }, [onUpdateSearchQuery, setActiveAnnotationIndex, setAnnotations]);

  const TextInputEndAdornment = () => {
    if (searchQuery.length === 0) {
      return (
        <InfoTooltip
          title={
            <Stack spacing={1} p={0.5}>
              <Typography variant="h6">What can I ask?</Typography>
              <Typography variant="subtitle2">
                You can search for any information found in the document such as
                details on dispositions & trustees
              </Typography>
            </Stack>
          }
          placement="top-end"
          sx={{ color: COLORS.GRAY[500] }}
        />
      );
    }
    return <ClearInputTextButton onClick={handleClearSearch} />;
  };

  const debouncedSetPositioningYOffset = useDebouncedFn(
    (nextOffset: number) => {
      setPositioningYOffset(nextOffset);
    },
    50
  );

  // Get the initial status of the document prior to polling for search readiness
  useDocumentStatusQuery({
    variables: {
      where: {
        id: documentId,
      },
    },
    onError: (err) => {
      diagnostics.error('Failed to query for document status', err);
    },
    onCompleted: (data) => {
      const documents = getNodes(data.documents);
      if (!documents?.length) return;
      const document = documents[0];
      if (!document) return;
      // we care about just the document inference metadata job, because that's what
      // needs to be done before we can search the document
      const metaDataAsyncJobs = getNodes(document.metadataJobs);
      setInitialAsyncJob(
        orderBy(
          metaDataAsyncJobs,
          'createdAt',
          'desc' // Order the jobs by most recent
        )[0]
      );
    },
  });

  // Continuously poll for the completion of the document inference metadata job
  const jobCompletionDetails = usePollForJobCompletion(initialAsyncJob, {});

  useLayoutEffect(() => {
    if (!containerElementRef.current) return;

    const options = {
      threshold: [0.01, 0.25, 0.5, 0.75, 1],
    };

    // The observer looks for the intersection of the search bar and the viewport.
    // It is updated as the user scrolls the page.
    const observer = new IntersectionObserver((entries) => {
      const firstEntry = entries[0];
      if (!firstEntry?.rootBounds) return;
      const searchBarHeight = firstEntry.boundingClientRect.height;
      const viewportHeight = firstEntry.rootBounds.height;
      const searchbarDistanceFromTopPx = firstEntry.boundingClientRect.y;
      // Calculate how far the search bar needs to be translated to be visible in the viewport
      const proposedNextYOffset = -(
        searchbarDistanceFromTopPx -
        viewportHeight +
        searchBarHeight +
        16
      );

      // We only want to translate the search bar up to be within view.
      // It cannot go past it's original position at the bottom of the PDFViewer.
      const nextYOffset = Math.ceil(Math.min(0, proposedNextYOffset));

      // De-dupe and debounce to prevent unnecessary re-renders
      if (nextYOffset !== positioningYOffset) {
        debouncedSetPositioningYOffset(nextYOffset);
      }
    }, options);

    observer.observe(containerElementRef.current);

    return () => {
      observer.disconnect();
    };
  }, [debouncedSetPositioningYOffset, positioningYOffset]);

  if (
    isDocumentLoading ||
    !documentId ||
    !enableAiCapabilities || // Never show search bar if tenant has disabled AI capabilities
    jobCompletionDetails.mostRecentJobStatus !== AsyncJobStatus.Completed // Don't show search bar until document inference metadata job is complete
  ) {
    return null;
  }

  return (
    <FloatingMenuContainer ref={containerElementRef} offset={{ bottom: 16 }}>
      <Box
        sx={(t) => ({
          transform: positioningYOffset
            ? `translateY(${positioningYOffset}px)`
            : 'none',
          transition: 'transform 0.1s linear',
          boxShadow: t.palette.shadows.lg,
          background: COLORS.TEAL[50],
          borderRadius: 1.5,
          p: 2,
          mx: 1,
          mb: 1,
          display: 'flex',
        })}
      >
        <Stack direction="column" width="100%" display="flex">
          <Stack direction="column" width="100%" display="flex" gap={2}>
            <Stack
              direction="row"
              display="flex"
              gap={3}
              alignItems="center"
              width="100%"
              component="form"
              onSubmit={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await handleSearch();
              }}
            >
              <LuminaryAiBetaLogo />
              <Stack gap={2} sx={{ flexGrow: 1 }}>
                <TextInput
                  hideLabel
                  label="Search within this document"
                  placeholder="Ask something about this document"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  startAdornment={
                    <SearchSmIcon size={20} sx={{ color: COLORS.GRAY[500] }} />
                  }
                  endAdornment={<TextInputEndAdornment />}
                />
              </Stack>
              <Button
                type="submit"
                variant="primary"
                size="sm"
                onClick={handleSearch}
                disabled={!searchQuery.length}
                loading={loadingSearch || overrideSearchLoading}
              >
                Search
              </Button>
            </Stack>
            {!searchError && searchResponse && (
              <DocumentSearchGeneratedResponse
                searchResponse={searchResponse}
              />
            )}
          </Stack>
          {searchError && (
            <DocumentSearchResults
              variant="error"
              resultIndex={activeAnnotationIndex}
              setResultIndex={setActiveAnnotationIndex}
            />
          )}
          {!searchError && annotations && (
            <DocumentSearchResults
              variant="success"
              results={annotations}
              resultIndex={activeAnnotationIndex}
              setResultIndex={setActiveAnnotationIndex}
            />
          )}
        </Stack>
      </Box>
    </FloatingMenuContainer>
  );
}
