import { Stack } from '@mui/material';

import { useRequiredParam } from '@/hooks/useRequiredParam';
import { UpdateClient } from '@/modules/forms/household/Update';

export function KeyDetailsPage() {
  const householdId = useRequiredParam('householdId');

  return (
    <Stack height="100%">
      <Stack p={3} flex={1}>
        <UpdateClient householdId={householdId} />
      </Stack>
    </Stack>
  );
}
