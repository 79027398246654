import { FormProvider } from 'react-hook-form';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { Card } from '@/components/layout/Card/Card';
import { Grid } from '@/components/layout/Grid';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';

import {
  getUpdateDisclaimersInput,
  useLegalDisclaimersFormDefaultValues,
} from './AdminLegalDisclaimersPage.utils';
import { useUpdateLegalDisclaimersMutation } from './graphql/AdminLegalDisclaimers.generated';
import {
  LegalDisclaimersForm,
  LegalDisclaimersFormShape,
} from './LegalDisclaimersForm';

export function AdminLegalDisclaimersPage() {
  const { createSuccessFeedback, showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { getDefaultValues, data: initialData } =
    useLegalDisclaimersFormDefaultValues();
  const [updateDisclaimers] = useUpdateLegalDisclaimersMutation({
    onCompleted: createSuccessFeedback(
      'Successfully updated legal disclaimers'
    ),
    onError: (err) => {
      showFeedback(
        'There was an error updating the legal disclaimers. Please try again.'
      );
      reportError('failed to update legal disclaimers', err);
    },
  });
  const formMethods = useForm<LegalDisclaimersFormShape>({
    defaultValues: async () => getDefaultValues(),
  });

  const onSubmit = formMethods.handleSubmit((data) => {
    const disclaimerId =
      initialData?.tenantInformation?.disclosuresConfiguration?.id;
    if (!disclaimerId) throw new Error('disclaimerId not available');
    const input = getUpdateDisclaimersInput(disclaimerId, data);
    return updateDisclaimers({ variables: { input } });
  });

  return (
    <FormProvider {...formMethods}>
      <SubpageLayout heading="Legal disclaimers for presentations">
        <Grid container>
          <Grid item lg={8} sm={12}>
            <Card variant="outlined" sx={{ p: 3 }}>
              <LegalDisclaimersForm onSubmit={onSubmit} />
            </Card>
          </Grid>
        </Grid>
      </SubpageLayout>
    </FormProvider>
  );
}
