import { Divider, SxProps, Typography } from '@mui/material';
import { useMemo } from 'react';

import { COLORS } from '@/styles/tokens/colors';
import { AfterDeath } from '@/types/schema';

export interface TaxOverviewTableDividerProps {
  dyingGrantorFirstName?: string;
  afterDeath: AfterDeath;
}

const flyoutDividerSx = {
  // custom dashes -- because CSS doesn't let you do this, work around it
  // by drawing a 1px tall box and creating a background SVG
  // https://codepen.io/amit_sheen/pen/xxZeyjO
  borderStyle: 'none',
  height: '1px',
  backgroundImage: `repeating-linear-gradient(0deg, ${COLORS.GRAY[300]}, ${COLORS.GRAY[300]} 4px, transparent 4px, transparent 8px, ${COLORS.GRAY[300]} 8px), repeating-linear-gradient(90deg, ${COLORS.GRAY[300]}, ${COLORS.GRAY[300]} 4px, transparent 4px, transparent 8px, ${COLORS.GRAY[300]} 8px), repeating-linear-gradient(180deg, ${COLORS.GRAY[300]}, ${COLORS.GRAY[300]} 4px, transparent 4px, transparent 8px, ${COLORS.GRAY[300]} 8px), repeating-linear-gradient(270deg, ${COLORS.GRAY[300]}, ${COLORS.GRAY[300]} 4px, transparent 4px, transparent 8px, ${COLORS.GRAY[300]} 8px);`,
  backgroundSize: '1px 100%, 100% 1px, 1px 100% , 100% 1px;',
  backgroundPosition: '0 0, 0 0, 100% 0, 0 100%;',
  backgroundRepeat: 'no-repeat;',
};

export function TaxOverviewTableDivider({
  dyingGrantorFirstName,
  afterDeath,
}: TaxOverviewTableDividerProps) {
  const label = useMemo(() => {
    if (dyingGrantorFirstName) {
      return `${dyingGrantorFirstName}'s projected death`;
    }
    return `${afterDeath === AfterDeath.Second ? 'Second' : 'First'} projected death`;
  }, [afterDeath, dyingGrantorFirstName]);

  const sx: SxProps = {
    py: 3,
    '::before': flyoutDividerSx,
    '::after': flyoutDividerSx,
    '> .MuiDivider-wrapper': {
      // necessary to close the gap between the flyout lines and the label
      px: 0,
    },
  };

  return (
    <Divider sx={sx}>
      <Typography
        variant="h6"
        component="span"
        sx={{
          backgroundColor: COLORS.GRAY[300],
          px: 1,
          py: 0.5,
          borderRadius: 0.5,
        }}
      >
        {label}
      </Typography>
    </Divider>
  );
}
