import { gridStringOrNumberComparator } from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import React from 'react';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { TableEmptyState } from '@/components/tables/DataTable/TableEmptyState';
import { Column } from '@/components/tables/DataTable/types';
import { StructuredAssetsSubform_ValuationFragment } from '@/modules/entities/StructuredAssetsSubform/graphql/StructuredAssetsSubform.generated';
import { AssetValuationV2ValuationSource } from '@/types/schema';
import { formatCurrencyNoDecimalsAccounting } from '@/utils/formatting/currency';

import { getIntegratedAssetsFromValuation } from './FullScreenStructuredAssetsModal.utils';
import { useValuationIntegrationDetails } from './hooks/useValuationLinkDetails';

interface AssetData {
  id: string;
  assetCategory: string;
  title: string;
  value: Decimal;
}

const columns: Column<AssetData>[] = [
  { field: 'assetCategory', headerName: 'Asset category', flex: 1 },
  { field: 'title', headerName: 'Title', flex: 2 },
  {
    field: 'value',
    headerName: 'Value',
    flex: 1,
    renderCell: TextRenderer({
      text: ({ row }) => formatCurrencyNoDecimalsAccounting(row.value),
    }),
    sortComparator: (
      v1: AssetData['value'],
      v2: AssetData['value'],
      p1,
      p2
    ) => {
      return gridStringOrNumberComparator(v1, v2, p1, p2);
    },
  },
];

function getRows(
  valuation: StructuredAssetsSubform_ValuationFragment | null
): AssetData[] {
  if (!valuation) return [];
  // only show assets from an integration; the manual assets are shown in the form.
  const assets = getIntegratedAssetsFromValuation(valuation);
  return assets.map((asset) => ({
    id: asset.id,
    assetCategory: asset.class?.displayName ?? 'Unknown',
    title: asset.displayName,
    value: asset.assetValue.ownedValue ?? new Decimal(0),
  }));
}

interface IntegrationValuationSummaryProps {
  valuation: StructuredAssetsSubform_ValuationFragment | null;
}

const MAX_ASSET_COUNT = 20;
export function IntegrationValuationSummary({
  valuation,
}: IntegrationValuationSummaryProps) {
  const { isIntegratedScenarioDirty } = useValuationIntegrationDetails();
  const hasValidIntegrationValuation =
    valuation &&
    valuation.valuationSource !== AssetValuationV2ValuationSource.Manual &&
    !isIntegratedScenarioDirty;

  // Don't render manual valuation details as if they are linked to an integration
  const rows = hasValidIntegrationValuation ? getRows(valuation) : [];

  return (
    <DataTable
      columns={columns}
      rows={rows}
      slots={{
        noRowsOverlay: () => (
          <TableEmptyState
            text={
              hasValidIntegrationValuation
                ? 'No assets found in this valuation.'
                : 'Detailed assets will appear after saving and replace any existing data.'
            }
          />
        ),
      }}
      pageSize={MAX_ASSET_COUNT}
      pagination={rows.length > MAX_ASSET_COUNT}
      autoHeight
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      disableRowSelectionOnClick
    />
  );
}
