import { Stack } from '@mui/material';

import { StackedHorizontalBar } from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { Button } from '@/components/form/baseInputs/Button';
import { Grid } from '@/components/layout/Grid';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { CurrencyUSD } from '@/graphql/scalars';
import { useGiftingExemptionsForClient } from '@/modules/gifting/hooks/useGiftingExemptionsForClient';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';

interface HouseholdForExemptions {
  id: string;
  possiblePrimaryClients: {
    id: string;
    gstExclusionUsed: CurrencyUSD;
    displayName: string;
    lifetimeExclusionUsed: CurrencyUSD;
    currentAnnualExclusionUsed: CurrencyUSD;
    totalAnnualExclusionUsed: CurrencyUSD;
  }[];
}

interface ClientDetailsExemptionsProps {
  household?: HouseholdForExemptions;
}

const currencyFormatOptions = {
  notation: 'compact' as const,
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
};

export function HouseholdExemptionsOverview({
  household,
}: ClientDetailsExemptionsProps) {
  const {
    availableLifetimeExemption,
    lifetimeExemptionSections,
    availableGstExemption,
    gstExemptionSections,
  } = useGiftingExemptionsForClient({
    household,
    returnNegativeValues: false,
    groupByGrantor: false,
  });

  const giftingPageUrl =
    household &&
    getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_DETAILS_GIFTING, {
      householdId: household.id,
    });

  return (
    <Stack
      sx={{ width: '100%', height: '100%', p: 2, pb: 0 }}
      justifyContent="space-between"
    >
      <Grid container spacing={6}>
        <Grid item sm={6}>
          <Stack spacing={1.5}>
            <OversizedMetricItem
              title="Lifetime exemption"
              value={
                availableLifetimeExemption
                  ? formatCurrency(
                      availableLifetimeExemption,
                      currencyFormatOptions
                    )
                  : EMPTY_CONTENT_HYPHEN
              }
              titleVariant="h4"
              valueVariant="h1"
              valueColor={COLORS.NAVY[600]}
              valueLabel="available"
            />
            <StackedHorizontalBar
              sections={lifetimeExemptionSections}
              variant="legend"
              hideLegend
            />
          </Stack>
        </Grid>
        <Grid item sm={6}>
          <Stack spacing={1.5}>
            <OversizedMetricItem
              title="GST exemption"
              value={
                availableGstExemption
                  ? formatCurrency(availableGstExemption, currencyFormatOptions)
                  : EMPTY_CONTENT_HYPHEN
              }
              titleVariant="h4"
              valueVariant="h1"
              valueColor={COLORS.NAVY[600]}
              valueLabel="available"
            />
            <StackedHorizontalBar
              sections={gstExemptionSections}
              variant="legend"
              hideLegend
            />
          </Stack>
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="end">
        <Button
          disabled={!giftingPageUrl}
          variant="secondary"
          size="sm"
          href={giftingPageUrl ?? ''}
        >
          Track exemption usage
        </Button>
      </Stack>
    </Stack>
  );
}
