import { Control, Controller, FieldValues } from 'react-hook-form';

import { getFieldErrorValue } from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import {
  EmptyPlaceholder,
  EmptyPlaceholderProps,
} from '../EmptyPlaceholder/EmptyPlaceholder';

export type FormAwareEmptyPlaceholderProps<FormShape extends FieldValues> = {
  fieldName: FieldNameFromFormShape<FormShape>;
  control: Control<FormShape>;
} & EmptyPlaceholderProps;

export function FormAwareEmptyPlaceholder<FormShape extends FieldValues>({
  fieldName,
  control,
  children,
  ...inputProps
}: FormAwareEmptyPlaceholderProps<FormShape>) {
  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field, fieldState, formState }) => {
        return (
          <EmptyPlaceholder
            {...inputProps}
            inputRef={field.ref}
            errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
          >
            {children}
          </EmptyPlaceholder>
        );
      }}
    />
  );
}
