import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { FC } from 'react';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { SubtotalCard } from '@/components/display/SubtotalCard/SubtotalCard';
import { CoinsSwap01Icon } from '@/components/icons/CoinsSwap01Icon';
import { PopperContent } from '@/components/poppers/PopperContent';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { COLORS } from '@/styles/tokens/colors';
import {
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';

const COLOR_FAMILY_GIVING = COLORS.TEAL[500];
const COLOR_CHARITABLE_GIVING = COLORS.BLUE[500];

export const WEALTH_TRANSFER_HELP_COPY =
  'Wealth transferred is defined as the sum of direct gifts to individuals/charity, and the current value of DAFs & foundations, GRATs, and irrevocable trusts/SLATs.';

// reused in the column header
export const WealthTransferredPopper: FC = () => (
  <PopperContent body={WEALTH_TRANSFER_HELP_COPY} />
);

export const WealthTransferredCallout: FC<{
  amountToFamily: Decimal;
  amountToCharity: Decimal;
  totalWealthTransferred: Decimal;
}> = ({ amountToFamily, amountToCharity, totalWealthTransferred }) => {
  return (
    <SubtotalCard
      wrapperSx={{ flex: '1 1 50%' }}
      icon={<CoinsSwap01Icon />}
      iconColor={COLOR_FAMILY_GIVING}
      caption={
        <OversizedMetricItem
          title={
            <Stack direction="row" flexWrap="nowrap">
              Wealth transferred&nbsp;
              <ContextualHelpTooltip>
                <Typography>
                  <WealthTransferredPopper />
                </Typography>
              </ContextualHelpTooltip>
            </Stack>
          }
          value={formatCurrencyNoDecimalsAccounting(totalWealthTransferred)}
        />
      }
    >
      <Stack>
        <Stack sx={{ flexFlow: 'row nowrap', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              background: COLOR_FAMILY_GIVING,
              height: '12px',
              width: '12px',
              borderRadius: '4px',
            }}
          />
          <Typography variant="h6">
            {formatCurrencyNoDecimals(amountToFamily)} to family
          </Typography>
        </Stack>
        <Stack sx={{ flexFlow: 'row nowrap', alignItems: 'center', gap: 1 }}>
          <Box
            sx={{
              background: COLOR_CHARITABLE_GIVING,
              height: '12px',
              width: '12px',
              borderRadius: '4px',
            }}
          />
          <Typography variant="h6">
            {formatCurrencyNoDecimals(amountToCharity)} to charity
          </Typography>
        </Stack>
      </Stack>
    </SubtotalCard>
  );
};
