import { entitySubtypeToNodeCategorization } from '../entityGraph/constants';
import { EntityMap_NodeFragment } from '../graphql/EntityMap.generated';
import { SupportedSubtypeTypename } from '../types';

/**
 * @description We don't render nodes for all entity subtypes, but the subtype.__typename is a union type
 * of all possible entity subtypes. This function narrows that union type to only the subset of supported
 * entity subtypes for which there are nodes.
 */
export function $getSupportedEntityNodeTypename(
  typename: NonNullable<EntityMap_NodeFragment['subtype']['__typename']>
): SupportedSubtypeTypename {
  if (
    !entitySubtypeToNodeCategorization[typename as SupportedSubtypeTypename]
  ) {
    throw new Error(`Unsupported entity subtype: ${typename}`);
  }

  return typename as SupportedSubtypeTypename;
}
