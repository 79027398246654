import { BoxProps, Stack } from '@mui/material';
import { getYear } from 'date-fns';
import { chunk } from 'lodash';
import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  BeneficiaryReportingContext,
  useBeneficiaryReportingContext,
} from '@/modules/beneficiaryReporting/beneficiaryReporting.context';
import {
  BenefitsOverviewBarCharts,
  MAX_NUMBER_OF_BARS,
} from '@/modules/beneficiaryReporting/benefitsOverview/BenefitsOverviewBarCharts';
import { usePrimaryBeneficiaryBars } from '@/modules/beneficiaryReporting/benefitsOverview/hooks/usePrimaryBeneficiaryBars';
import { useBeneficiariesData } from '@/modules/beneficiaryReporting/hooks/useBeneficiariesData';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';
import { getNodes } from '@/utils/graphqlUtils';

import { ClientPresentationDesigner_EstateWaterfallFragment } from '../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { PresentationSlideSecondaryHeading } from '../components/PresentationSlideSecondaryHeading';
import { PresentationSlideRegistrationDetails } from '../entities/entityPresentations.types';
import { PRESENTATION_SPACING_UNITS } from '../presentation.constants';
import { PresentationSlide } from '../PresentationSlide';

interface EstateWaterfallBeneficiariesSlideInnerProps
  extends PresentationSlideRegistrationDetails {
  waterfall: ClientPresentationDesigner_EstateWaterfallFragment;
  SlideWrapper: ComponentType<BoxProps>;
}

function EstateWaterfallBeneficiariesSlideInner({
  waterfall,
  SlideWrapper,
  ...registrationProps
}: EstateWaterfallBeneficiariesSlideInnerProps) {
  // We need to wait for the waterfall to be rendered completely before
  // scaling the container because the waterfall does all its calculations
  // based on the unscaled container size.
  const [waterfallReady, setWaterfallReady] = useState(false);

  const {
    beneficiariesData,
    firstGrantor,
    secondGrantor,
    firstGrantorDeathYear,
    secondGrantorDeathYear,
  } = useBeneficiaryReportingContext();

  const registerWaterfallReady = useCallback(() => {
    setWaterfallReady(true);
  }, []);

  useEffect(() => {
    if (!beneficiariesData) {
      return;
    }

    registerWaterfallReady();
  }, [registerWaterfallReady, beneficiariesData]);

  const bars = usePrimaryBeneficiaryBars();
  const chunks = chunk(bars, MAX_NUMBER_OF_BARS);

  return (
    <>
      {chunks.map((_chunk, idx) => (
        <SlideWrapper key={`${waterfall.id}-beneficiaries-${idx}`}>
          <PresentationSlide.Slide
            id={registrationProps.slideId}
            leftHeaderContent={
              <PresentationSlide.MainHeading
                heading={waterfall.displayName}
                subheading="Beneficiaries"
              />
            }
            rightHeaderContent={
              <PresentationSlideSecondaryHeading
                clientDisplayName={waterfall.household?.displayName ?? null}
              />
            }
            footer={<PresentationSlide.Footer />}
            readyToScale={waterfallReady}
            data-presentationwaterfallready={waterfallReady.toString()}
          >
            <Stack
              spacing={3}
              justifyContent="space-between"
              p={PRESENTATION_SPACING_UNITS}
              height="100%"
            >
              <BenefitsOverviewBarCharts
                firstGrantor={firstGrantor}
                secondGrantor={secondGrantor}
                firstGrantorDeathYear={firstGrantorDeathYear}
                secondGrantorDeathYear={secondGrantorDeathYear}
                page={idx}
              />
            </Stack>
          </PresentationSlide.Slide>
        </SlideWrapper>
      ))}
    </>
  );
}

export interface EstateWaterfallBeneficiariesSlideProps
  extends PresentationSlideRegistrationDetails {
  waterfall: ClientPresentationDesigner_EstateWaterfallFragment;
  SlideWrapper: ComponentType<BoxProps>;
}
export function EstateWaterfallBeneficiariesSlide({
  waterfall,
  SlideWrapper,
  ...registrationProps
}: EstateWaterfallBeneficiariesSlideProps) {
  const waterfallId = waterfall.id;
  const householdId = waterfall.household?.id;

  const { primaryClients } = useHouseholdDetailsContext();

  const { beneficiariesData, data: queryData } =
    useBeneficiariesData(waterfallId);

  const { firstGrantorDeathId, firstGrantorDeathYear, secondGrantorDeathYear } =
    useMemo(() => {
      if (!queryData) {
        return {
          firstGrantorDeathId: undefined,
          firstGrantorDeathYear: undefined,
          secondGrantorDeathYear: undefined,
        };
      }

      const waterfall = getNodes(queryData.estateWaterfalls)[0];

      return {
        firstGrantorDeathId: waterfall?.firstGrantorDeath.id,
        firstGrantorDeathYear:
          waterfall?.firstGrantorDeathYear ?? getYear(new Date()),
        secondGrantorDeathYear:
          waterfall?.secondGrantorDeathYear ?? getYear(new Date()),
      };
    }, [queryData]);

  const [firstGrantor, secondGrantor] = useMemo(() => {
    const firstGrantor = primaryClients?.find(
      (g) => g.id === firstGrantorDeathId
    );
    const secondGrantor = primaryClients?.find(
      (g) => g.id !== firstGrantorDeathId
    );

    return [firstGrantor, secondGrantor];
  }, [firstGrantorDeathId, primaryClients]);

  useRegisterSlide({
    displayName: 'Beneficiaries',
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  if (!householdId || !beneficiariesData || !queryData) {
    return <div data-presentationwaterfallready="false"></div>;
  }

  return (
    <BeneficiaryReportingContext.Provider
      value={{
        clientOrOrgId: null,
        beneficiariesData,
        firstGrantor,
        secondGrantor,
        firstGrantorDeathYear,
        secondGrantorDeathYear,
        queryData,
      }}
    >
      <EstateWaterfallBeneficiariesSlideInner
        waterfall={waterfall}
        SlideWrapper={SlideWrapper}
        {...registrationProps}
      />
    </BeneficiaryReportingContext.Provider>
  );
}
