import {
  AssetsSubformType,
  VariantType as AssetsSubformVariant,
} from '@/modules/assets/AssetsSubform/AssetsSubform';
import { SubformAsset } from '@/modules/assets/AssetsSubform/types';
import { Asset, NEW_ASSET_ID } from '@/modules/assets/types/asset';
import {
  AssetValuationV2ValuationReason,
  AssetValueV2OwnershipType,
  AugmentedCreateAssetV2Input,
  AugmentedCreateAssetValuationV2Input,
  AugmentedUpdateAssetV2Input,
  CreateAssetV2Input,
  CreateAssetValueV2Input,
} from '@/types/schema';
import { AugmentedUpdateAssetValuationV2Input } from '@/types/schema';

// this function is responsible for mapping the frontend Asset concept to the variables
// required to update the Asset and AssetValue models in the backend
function getAssetValuationPropsForMutation(
  asset: Asset,
  opts = { isUpdate: false }
): CreateAssetValueV2Input {
  switch (asset.valuationMethod) {
    case AssetValueV2OwnershipType.ShareBased:
      return {
        shareCount: asset.shareCount || null,
        shareValue: asset.sharePrice || null,
        ownershipType: asset.valuationMethod,
        ...(opts.isUpdate
          ? {
              clearOwnedValue: true,
              clearTotalValue: true,
              clearOwnedPercent: true,
            }
          : {}),
      };
    case AssetValueV2OwnershipType.ValueBased:
      return {
        ownedValue: asset.marketValue || null,
        ownershipType: asset.valuationMethod,
        ...(opts.isUpdate
          ? {
              clearShareCount: true,
              clearShareValue: true,
              clearTotalValue: true,
              clearOwnedPercent: true,
            }
          : {}),
      };
    case AssetValueV2OwnershipType.PercentBased:
      return {
        totalValue: asset.totalValue || null,
        ownedPercent: asset.ownedPercent || null,
        ownershipType: asset.valuationMethod,
        ...(opts.isUpdate
          ? {
              clearShareCount: true,
              clearShareValue: true,
              clearOwnedValue: true,
            }
          : {}),
      };
    default:
      throw new Error(`Unrecognized valuationMethod ${asset.valuationMethod}`);
  }
}

export function getAssetMutationUpdates(
  assets: (Asset | SubformAsset)[],
  isAssetSubstitution = false
) {
  const newAssets: AugmentedCreateAssetV2Input[] = [];
  const existingAssetsUpdate: AugmentedUpdateAssetV2Input[] = [];
  const existingAssetsCreate: AugmentedCreateAssetV2Input[] = [];
  const removedAssetIds: string[] = [];

  assets.forEach((asset: SubformAsset | Asset) => {
    if (asset.doDelete) {
      removedAssetIds.push(asset.id);
      return;
    }

    const assetDetails: CreateAssetV2Input = {
      displayName: asset.displayName,
      classID: asset.categoryId || '',
      qsbsEligibility: asset.qsbsEligibility,
    };

    const assetValueCreate = getAssetValuationPropsForMutation(asset);
    const assetValueUpdate = getAssetValuationPropsForMutation(asset, {
      isUpdate: true,
    });

    if (asset.id.startsWith(NEW_ASSET_ID) || isAssetSubstitution) {
      newAssets.push({
        create: assetDetails,
        withAssetValue: {
          create: assetValueCreate,
        },
      });
    } else {
      // it's a little funny to return mutations for both `existingAssetsUpdate` and
      // `existingAssetsCreate`, but the expectation is that the serializers that consume this
      // set of possible mutation updates won't be using both of them, but rather will be using
      // one or the other depending on its own use case. for example, in order to update the
      // holdings, we would use `existingAssetsCreate`. to do the initial funding revaluation, we
      // would use existingAssetsUpdate.

      // this is intended for the scenario when you are creating a new asset value for an existing asset,
      // but also want to potentially update the asset details at the same time
      if (asset.valueId) {
        existingAssetsUpdate.push({
          id: asset.id,
          update: assetDetails,
          updateAssetValue: {
            id: asset.valueId,
            update: assetValueUpdate,
          },
        });
      }
      // this is intended for creating a net-new asset with a value
      existingAssetsCreate.push({
        create: assetDetails,
        withAssetValue: {
          create: assetValueCreate,
        },
      });
    }
  });

  return {
    newAssets,
    existingAssetsUpdate,
    existingAssetsCreate,
    removedAssetIds,
  };
}

export const serializers: Record<
  AssetsSubformVariant,
  (
    input: AssetsSubformType
  ) =>
    | AugmentedUpdateAssetValuationV2Input
    | AugmentedCreateAssetValuationV2Input
> = {
  initialFundingRevaluation: (
    formValues
  ): AugmentedUpdateAssetValuationV2Input => {
    if (!formValues?.assets) {
      throw new Error('Trying to parse empty form');
    }

    const { newAssets, existingAssetsCreate } = getAssetMutationUpdates(
      formValues.assets
    );

    return {
      id: formValues.valuationId!,
      update: {
        clearAssets: true,
      },
      withAssets: [...newAssets, ...existingAssetsCreate],
    };
  },
  verifyInitialFunding: (formValues): AugmentedUpdateAssetValuationV2Input => {
    if (!formValues?.assets || !formValues.valuationId) {
      throw new Error('Trying to parse empty form');
    }

    const { newAssets, existingAssetsCreate } = getAssetMutationUpdates(
      formValues.assets,
      false
    );

    return {
      id: formValues.valuationId,
      update: {
        effectiveDate: formValues.fundingDate,
        valuationReason: AssetValuationV2ValuationReason.InitialFunding,
        clearAssets: true,
      },
      withAssets: [...newAssets, ...existingAssetsCreate],
    };
  },
  structuringDesigner: (formValues): AugmentedUpdateAssetValuationV2Input => {
    if (!formValues?.assets || !formValues.valuationId) {
      throw new Error('Trying to parse empty form');
    }

    const { newAssets, existingAssetsCreate } = getAssetMutationUpdates(
      formValues.assets
    );

    if (true as unknown) {
      throw new Error(
        'TODO: VALIDATE THAT THIS WORKS. THIS WAS PORTED BUT NOT VALIDATED.'
      );
    }

    return {
      id: formValues.valuationId,
      update: {
        clearAssets: true,
        effectiveDate: new Date(new Date(0).toISOString()),
      },
      withAssets: [...newAssets, ...existingAssetsCreate],
    };
  },
  editDesignSummary: (formValues): AugmentedUpdateAssetValuationV2Input => {
    if (!formValues?.assets || !formValues.valuationId) {
      throw new Error('Trying to parse empty form');
    }

    const { newAssets, existingAssetsCreate } = getAssetMutationUpdates(
      formValues.assets
    );

    return {
      id: formValues.valuationId,
      update: {
        clearAssets: true,
      },
      withAssets: [...newAssets, ...existingAssetsCreate],
    };
  },
  updateHoldings: (formValues): AugmentedCreateAssetValuationV2Input => {
    if (
      !formValues?.assets ||
      !formValues.valuationReason ||
      !formValues.valuationDate
    ) {
      throw new Error('Trying to parse empty form');
    }

    const isAssetSubstitution =
      formValues.valuationReason ===
      AssetValuationV2ValuationReason.GratAssetSubstitution;

    const { newAssets, existingAssetsCreate } = getAssetMutationUpdates(
      formValues.assets,
      isAssetSubstitution
    );

    return {
      create: {
        effectiveDate: formValues.valuationDate,
        valuationReason: formValues.valuationReason,
      },
      withAssets: [...newAssets, ...existingAssetsCreate],
    };
  },
};
