const ENTITY_TYPES = [
  'qprt',
  'grat',
  'slat',
  'ilit',
  'revocable-trust',
  'irrevocable-trust',
  'individual-account',
  'joint-account',
  'retirement-account',
  'custodial-account',
  'qualified-tuition-account',
  'clt',
  'crt',
  'daf',
  'private-foundation',
  'sole-proprietorship',
  'c-corp',
  's-corp',
  'llc',
  'lp',
  'gp',
  'insurance-account',
] as const;

export type EntityType = (typeof ENTITY_TYPES)[number];

export function isEntityType(value: unknown): value is EntityType {
  return ENTITY_TYPES.includes(value as EntityType);
}
