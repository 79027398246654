/* eslint-disable react/display-name */
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { ReactNode, useState } from 'react';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { iconSizeByButtonSize } from '@/components/form/baseInputs/Button/styles';
import { DotsVerticalIcon } from '@/components/icons/DotsVerticalIcon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';

import { CellContainer } from '../../components/cells';
import { CellRenderProp } from '../../types';
import { getCellRenderProp } from '../../utils/getRenderProp';

type ActionType<R extends GridValidRowModel> =
  | {
      label: (row: R) => string;
      icon?: (row: R) => ReactNode;
      loading?: boolean;
      handleClick: (row: R) => void;
    }
  | {
      MenuItem: (row: R) => ReactNode;
    };

function ActionButton<R extends GridValidRowModel = GridValidRowModel>({
  actions,
  row,
  loading,
}: {
  actions: ActionType<R>[];
  row: R;
  loading?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ButtonWithPopover
      variant="secondary"
      size="sm"
      popperVariant="menuBelow"
      label={<DotsVerticalIcon size={iconSizeByButtonSize.sm} />}
      square
      hideChevron
      isOpen={isOpen}
      onClick={() => setIsOpen(true)}
      loading={loading}
    >
      {actions.map((action, idx) => {
        if ('MenuItem' in action) {
          return action.MenuItem(row);
        }

        const label = action.label(row);
        const icon = action.icon?.(row) ?? null;
        const loading = action.loading ?? false;

        return (
          <MenuItem
            icon={icon}
            loading={loading}
            sx={{
              minWidth: 200,
            }}
            key={`row-action-item-${idx}`}
            label={label}
            onClick={() => {
              action.handleClick(row);
              setIsOpen(false);
            }}
          />
        );
      })}
    </ButtonWithPopover>
  );
}

interface ActionButtonRendererProps<
  R extends GridValidRowModel,
  V,
  F,
  N extends GridTreeNodeWithRender,
> {
  actions: CellRenderProp<R, V, F, N, ActionType<R>[]>;
  loading?: CellRenderProp<R, V, F, N, boolean>;
}

export const ActionButtonRenderer = <
  R extends GridValidRowModel = GridValidRowModel,
  V extends string = string,
  F = V,
  N extends GridTreeNodeWithRender = GridTreeNodeWithRender,
>(
  factoryProps?: ActionButtonRendererProps<R, V, F, N>
) => {
  return (props: GridRenderCellParams<R, V, F, N>) => {
    const actions = getCellRenderProp({
      props,
      prop: factoryProps?.actions,
      defaultValue: [],
    });

    const loading = getCellRenderProp({
      props,
      prop: factoryProps?.loading,
      defaultValue: false,
    });

    return (
      <CellContainer align={props.colDef.align}>
        <ActionButton actions={actions} row={props.row} loading={loading} />
      </CellContainer>
    );
  };
};
