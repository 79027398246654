import {
  ProfessionalTeamRoleKind,
  ProfessionalTeamRolePowerOfAttorneyKind,
} from '@/types/schema';
import { formatEnumCase } from '@/utils/formatting/strings';

import { ClientProfessionalTeamMemberRoleKey } from './ClientProfessionalTeam.types';

export const CLIENT_PROFESSIONAL_POWER_OF_ATTORNEY_KIND_COPY: Record<
  ProfessionalTeamRolePowerOfAttorneyKind,
  string
> = {
  [ProfessionalTeamRolePowerOfAttorneyKind.Durable]: formatEnumCase(
    ProfessionalTeamRolePowerOfAttorneyKind.Durable
  ),
  [ProfessionalTeamRolePowerOfAttorneyKind.General]: formatEnumCase(
    ProfessionalTeamRolePowerOfAttorneyKind.General
  ),
  [ProfessionalTeamRolePowerOfAttorneyKind.Limited]: formatEnumCase(
    ProfessionalTeamRolePowerOfAttorneyKind.Limited
  ),
};

export const CLIENT_PROFESSIONAL_ROLE_KIND_COPY: Record<
  ClientProfessionalTeamMemberRoleKey,
  string
> = {
  [ProfessionalTeamRoleKind.AccountantCpa]: 'Accountant/CPA',
  [ProfessionalTeamRoleKind.Attorney]: formatEnumCase(
    ProfessionalTeamRoleKind.Attorney
  ),
  [ProfessionalTeamRoleKind.Bookkeeper]: formatEnumCase(
    ProfessionalTeamRoleKind.Bookkeeper
  ),
  [ProfessionalTeamRoleKind.BusinessManager]: formatEnumCase(
    ProfessionalTeamRoleKind.BusinessManager
  ),
  [ProfessionalTeamRoleKind.Conservator]: formatEnumCase(
    ProfessionalTeamRoleKind.Conservator
  ),
  [ProfessionalTeamRoleKind.EstateExecutor]: formatEnumCase(
    ProfessionalTeamRoleKind.EstateExecutor
  ),
  [ProfessionalTeamRoleKind.Guardian]: formatEnumCase(
    ProfessionalTeamRoleKind.Guardian
  ),
  [ProfessionalTeamRoleKind.HealthcareAgent]: formatEnumCase(
    ProfessionalTeamRoleKind.HealthcareAgent
  ),
  [ProfessionalTeamRoleKind.InsuranceAgent]: formatEnumCase(
    ProfessionalTeamRoleKind.InsuranceAgent
  ),
  [ProfessionalTeamRoleKind.Other]: formatEnumCase(
    ProfessionalTeamRoleKind.Other
  ),
  [ProfessionalTeamRoleKind.Physician]: formatEnumCase(
    ProfessionalTeamRoleKind.Physician
  ),
  [ProfessionalTeamRoleKind.PowerOfAttorney]: formatEnumCase(
    ProfessionalTeamRoleKind.PowerOfAttorney
  ),
};

export function getRoleKindLabel({
  kind,
  otherRoleName,
}: {
  kind: ProfessionalTeamRoleKind;
  otherRoleName?: string;
}): string {
  if (kind === ProfessionalTeamRoleKind.Other) {
    return `${otherRoleName || 'Unknown'} (custom role)`;
  }

  return CLIENT_PROFESSIONAL_ROLE_KIND_COPY[kind];
}

export function getRoleKindDisplayName({
  kind,
  otherRoleName,
  powerOfAttorneyKind,
}: {
  kind: ProfessionalTeamRoleKind;
  otherRoleName?: string | null;
  powerOfAttorneyKind?: ProfessionalTeamRolePowerOfAttorneyKind | null;
}): string {
  if (
    kind === ProfessionalTeamRoleKind.PowerOfAttorney &&
    powerOfAttorneyKind
  ) {
    return `Power of attorney (${CLIENT_PROFESSIONAL_POWER_OF_ATTORNEY_KIND_COPY[
      powerOfAttorneyKind
    ]?.toLowerCase()})`;
  } else if (kind === ProfessionalTeamRoleKind.Other && otherRoleName) {
    return otherRoleName;
  }
  return CLIENT_PROFESSIONAL_ROLE_KIND_COPY[kind];
}
