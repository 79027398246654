import { Box, SxProps, Theme } from '@mui/material';
import { useEffect, useState } from 'react';

import { Callout } from '@/components/notifications/Callout/Callout';
import { DocumentFragment } from '@/modules/documents/graphql/DocumentFragment.generated';
import { DocumentWhereInput } from '@/types/schema';

import { DocumentRepresentation } from './DocumentRepresentation';
import {
  GetDocumentRepresentationListDocumentsDocument,
  useGetDocumentRepresentationListDocumentsLazyQuery,
} from './graphql/DocumentRepresentationList.generated';

export interface DocumentRepresentationListProps {
  documentsLike?: DocumentWhereInput;
  showDelete?: boolean;
  sx?: SxProps<Theme>;
  // If documentIds is passed, this list will be used when querying for documents
  // If an empty array is passed, the query for documents will not be made
  // If not passed, the documentsLike will be used to query for documents
  documentIds?: string[];
}

// Use this to force a requery
export const RequeryDocumentRepresentationListDocuments =
  GetDocumentRepresentationListDocumentsDocument;

export function useGetDocumentsForList({
  documentIds,
  documentsLike,
}: {
  documentIds: string[] | undefined;
  documentsLike: DocumentWhereInput | null;
}) {
  const [documents, setDocuments] = useState<DocumentFragment[]>([]);
  const [queryList, { error }] =
    useGetDocumentRepresentationListDocumentsLazyQuery();

  // the comparisonDocumentsLike input is a known JSON object, so we can safely stringify it
  // to make it comparable
  const comparisonDocumentsLike = documentsLike
    ? JSON.stringify(documentsLike)
    : null;

  useEffect(
    function respondToInputUpdate() {
      if (documentIds && !documentIds.length) {
        setDocuments([]);
        return;
      }
      if (!comparisonDocumentsLike) return;
      // the comparisonDocumentsLike input is a known JSON object, so we can safely parse it
      const documentsLike = JSON.parse(
        comparisonDocumentsLike
      ) as DocumentWhereInput;

      async function query() {
        await queryList({
          variables: {
            documentsLike,
          },
          onCompleted: (data) => {
            const newDocuments =
              data?.documents?.edges
                ?.map((edge) => edge?.node ?? null)
                .filter((d): d is DocumentFragment => d !== null) ?? [];
            setDocuments(newDocuments);
          },
        });
      }

      void query();
    },
    [comparisonDocumentsLike, documentIds, queryList]
  );

  return [
    documents,
    {
      error,
    },
  ] as const;
}

export function DocumentRepresentationList({
  documentsLike,
  showDelete,
  sx,
  documentIds,
}: DocumentRepresentationListProps) {
  // query by documentIds if they are provided, otherwise query by documentsLike
  let input: DocumentWhereInput | null = null;

  if (documentIds) {
    input = { idIn: documentIds };
  } else if (documentsLike) {
    input = documentsLike;
  }

  const [documents, { error }] = useGetDocumentsForList({
    documentIds,
    documentsLike: input,
  });

  if (error) {
    return (
      <Callout severity="error">
        Sorry, we weren&apos;t able to load these documents. Please refresh the
        page to try again.
      </Callout>
    );
  }

  return (
    <Box sx={sx}>
      {documents?.map((document, i) => {
        return (
          <Box mt={i === 0 ? 0 : 1} key={document.id}>
            <DocumentRepresentation
              key={document.id}
              showDelete={showDelete}
              fileName={document.name}
              documentType={document.type}
              uploadedAt={document.file.createdAt}
              uploadedBy={document.file.user.displayName}
              documentId={document.id}
            />
          </Box>
        );
      })}
    </Box>
  );
}
