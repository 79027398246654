import { Stack, Typography } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useState } from 'react';

import { Card } from '@/components/layout/Card/Card';
import {
  ButtonTab,
  TabContent,
  Tabs,
} from '@/components/navigation/NavigationTabs';
import { TabsProvider } from '@/components/navigation/NavigationTabs/Tabs.provider';
import { LoadingSkeletonOrValue } from '@/components/progress/LoadingSkeletonOrValue/LoadingSkeletonOrValue';
import { TableRowSkeleton } from '@/components/tables/TableRowSkeleton/TableRowSkeleton';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { WaterfallRouteProvider } from '@/modules/entities/contexts/waterfallRoute/WaterfallRoute.provider';
import { EstateWaterfallSubPanelButtons } from '@/modules/estateWaterfall/components/EstateWaterfallActionButtons/EstateWaterfallSubpanelButtons';
import { EstateWaterfallAssumptionsUpdater } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/EstateWaterfallAssumptionsUpdater';
import { useWaterfallSummaryContext } from '@/modules/estateWaterfall/contexts/waterfallSummary.context';
import { useQueryWaterfall } from '@/modules/estateWaterfall/hooks';
import { TaxOverviewTable } from '@/modules/summaryPanels/TaxOverviewTable/TaxOverviewTable';
import { TaxOverviewTableDivider } from '@/modules/summaryPanels/TaxOverviewTable/TaxOverviewTable.components';
import { AfterDeath } from '@/types/schema';

import { WaterfallSummaryProvider } from '../ClientDetailsEstateWaterfallSummaryListPage/contexts/WaterfallSummary.provider';
import { SummaryPanel } from '../ClientDetailsEstateWaterfallSummaryListPage/SummaryPanel';

interface ClientDetailsEstateWaterfallTaxOverviewPageInnerProps {
  waterfallId: string;
  householdId: string;
}
function ClientDetailsEstateWaterfallTaxOverviewPageInner({
  waterfallId,
}: ClientDetailsEstateWaterfallTaxOverviewPageInnerProps) {
  const topGridAPIRef = useGridApiRef();
  const bottomGridAPIRef = useGridApiRef();
  const { primaryClients, waterfall, refetch, loading } = useQueryWaterfall({
    waterfallId,
  });
  const [afterDeath, setAfterDeath] = useState<AfterDeath>(AfterDeath.None);

  const isTwoGrantorHousehold = primaryClients?.length === 2;

  const firstGrantorDeathId = waterfall?.firstGrantorDeath?.id;

  const firstGrantorDeathClientProfile = primaryClients?.find(
    (client) => client.id === firstGrantorDeathId
  );
  const secondGrantorDeathClientProfile = primaryClients?.find(
    (client) => client.id !== firstGrantorDeathId
  );

  const {
    summaryPanel: { isModalOpen },
  } = useWaterfallSummaryContext();

  return (
    <EstateWaterfallAssumptionsUpdater
      waterfallId={waterfallId}
      onSubmit={() => refetch()}
    >
      <Stack
        display="grid"
        gridTemplateColumns={isModalOpen ? 'auto 430px' : 'auto'}
        gap={4}
      >
        <Card variant="outlined" sx={{ p: 3 }}>
          <Stack spacing={3}>
            <TabsProvider<AfterDeath> currentTab={afterDeath}>
              <Tabs fullWidth={false}>
                <ButtonTab
                  display={
                    <LoadingSkeletonOrValue
                      sx={{ width: 250 }}
                      loading={loading}
                      value={`Before ${firstGrantorDeathClientProfile?.firstName}'s death (${waterfall?.firstGrantorDeathYear})`}
                      emptyValue="Before first death"
                    />
                  }
                  isActive={afterDeath === AfterDeath.None}
                  onClick={() => setAfterDeath(AfterDeath.None)}
                />
                <ButtonTab
                  display={
                    <LoadingSkeletonOrValue
                      sx={{ width: 250 }}
                      loading={loading}
                      value={`After ${firstGrantorDeathClientProfile?.firstName}'s death (${waterfall?.firstGrantorDeathYear})`}
                      emptyValue="After first death"
                    />
                  }
                  isActive={afterDeath === AfterDeath.First}
                  onClick={() => setAfterDeath(AfterDeath.First)}
                />
                {isTwoGrantorHousehold && (
                  <ButtonTab
                    display={
                      <LoadingSkeletonOrValue
                        sx={{ width: 250 }}
                        loading={loading}
                        emptyValue="After second death"
                        value={`After ${secondGrantorDeathClientProfile?.firstName}'s death (${waterfall?.secondGrantorDeathYear})`}
                      />
                    }
                    isActive={afterDeath === AfterDeath.Second}
                    onClick={() => setAfterDeath(AfterDeath.Second)}
                  />
                )}
              </Tabs>
              <TabContent tabKey={AfterDeath.None}>
                {afterDeath === AfterDeath.None && (
                  <>
                    {loading ? (
                      <Stack>
                        {new Array(20).fill(null).map((_, index) => (
                          <TableRowSkeleton
                            columns={2}
                            key={index}
                            sx={{ width: '100%' }}
                          />
                        ))}
                      </Stack>
                    ) : (
                      <TaxOverviewTable
                        afterDeath={AfterDeath.None}
                        deathYear={waterfall?.firstGrantorDeathYear ?? null}
                        waterfall={waterfall}
                        dyingGrantorName={
                          firstGrantorDeathClientProfile?.firstName
                        }
                        isTwoGrantorHousehold={isTwoGrantorHousehold}
                        gridAPIRef={topGridAPIRef}
                        loading={loading}
                      />
                    )}
                  </>
                )}
              </TabContent>
              <TabContent tabKey={AfterDeath.First}>
                {afterDeath === AfterDeath.First && (
                  <>
                    {loading ? (
                      <Stack>
                        {new Array(4).fill(null).map((_, index) => (
                          <TableRowSkeleton columns={2} key={index} />
                        ))}
                      </Stack>
                    ) : (
                      <TaxOverviewTable
                        afterDeath={AfterDeath.None}
                        deathYear={waterfall?.firstGrantorDeathYear ?? null}
                        waterfall={waterfall}
                        dyingGrantorName={
                          firstGrantorDeathClientProfile?.firstName
                        }
                        isTwoGrantorHousehold={isTwoGrantorHousehold}
                        isMiniView
                        gridAPIRef={topGridAPIRef}
                        loading={loading}
                      />
                    )}
                    <TaxOverviewTableDivider
                      dyingGrantorFirstName={
                        firstGrantorDeathClientProfile?.firstName
                      }
                      afterDeath={AfterDeath.First}
                    />
                    {loading ? (
                      <Stack>
                        {new Array(16).fill(null).map((_, index) => (
                          <TableRowSkeleton columns={2} key={index} />
                        ))}
                      </Stack>
                    ) : (
                      <TaxOverviewTable
                        afterDeath={AfterDeath.First}
                        deathYear={waterfall?.firstGrantorDeathYear ?? null}
                        waterfall={waterfall}
                        dyingGrantorName={
                          firstGrantorDeathClientProfile?.firstName
                        }
                        isTwoGrantorHousehold={isTwoGrantorHousehold}
                        gridAPIRef={bottomGridAPIRef}
                        loading={loading}
                      />
                    )}
                  </>
                )}
              </TabContent>
              <TabContent tabKey={AfterDeath.Second}>
                {afterDeath === AfterDeath.Second && (
                  <>
                    {loading ? (
                      <Stack>
                        {new Array(4).fill(null).map((_, index) => (
                          <TableRowSkeleton columns={2} key={index} />
                        ))}
                      </Stack>
                    ) : (
                      <TaxOverviewTable
                        afterDeath={AfterDeath.First}
                        deathYear={waterfall?.secondGrantorDeathYear ?? null}
                        waterfall={waterfall}
                        dyingGrantorName={
                          secondGrantorDeathClientProfile?.firstName
                        }
                        isTwoGrantorHousehold={isTwoGrantorHousehold}
                        isMiniView
                        gridAPIRef={topGridAPIRef}
                        loading={loading}
                      />
                    )}
                    <TaxOverviewTableDivider
                      dyingGrantorFirstName={
                        secondGrantorDeathClientProfile?.firstName
                      }
                      afterDeath={AfterDeath.Second}
                    />
                    {loading ? (
                      <Stack>
                        {new Array(14).fill(null).map((_, index) => (
                          <TableRowSkeleton columns={2} key={index} />
                        ))}
                      </Stack>
                    ) : (
                      <TaxOverviewTable
                        afterDeath={AfterDeath.Second}
                        deathYear={waterfall?.secondGrantorDeathYear ?? null}
                        waterfall={waterfall}
                        dyingGrantorName={
                          secondGrantorDeathClientProfile?.firstName
                        }
                        isTwoGrantorHousehold={isTwoGrantorHousehold}
                        gridAPIRef={bottomGridAPIRef}
                        loading={loading}
                      />
                    )}
                  </>
                )}
              </TabContent>
            </TabsProvider>
          </Stack>
        </Card>
        <SummaryPanel />
      </Stack>
    </EstateWaterfallAssumptionsUpdater>
  );
}

function Header() {
  const {
    summaryPanel: { openModal },
  } = useWaterfallSummaryContext();

  return (
    <Stack direction="row" justifyContent="space-between">
      <Typography variant="h1" component="h2">
        Tax overview
      </Typography>
      <EstateWaterfallSubPanelButtons onClick={(type) => openModal({ type })} />
    </Stack>
  );
}

export function ClientDetailsEstateWaterfallTaxOverviewPage() {
  const waterfallId = useRequiredParam('waterfallId');
  const householdId = useRequiredParam('householdId');

  return (
    <Stack>
      <WaterfallRouteProvider waterfallId={waterfallId}>
        <WaterfallSummaryProvider waterfallId={waterfallId}>
          <Stack spacing={3}>
            <Header />
            <ClientDetailsEstateWaterfallTaxOverviewPageInner
              waterfallId={waterfallId}
              householdId={householdId}
            />
          </Stack>
        </WaterfallSummaryProvider>
      </WaterfallRouteProvider>
    </Stack>
  );
}
