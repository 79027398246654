import { Box } from '@mui/system';
import { CSSProperties } from 'react';

interface DiagonalSplitColorBoxProps {
  width: number;
  topLeftColor: CSSProperties['backgroundColor'];
  bottomRightColor: CSSProperties['backgroundColor'];
}

export function DiagonalSplitColorBox({
  width,
  topLeftColor,
  bottomRightColor,
}: DiagonalSplitColorBoxProps) {
  return (
    <Box
      sx={{
        height: width,
        width,
        overflow: 'hidden',
        bgcolor: bottomRightColor,
        borderRadius: `${Math.min(width / 3, 6)}px`,
      }}
    >
      <Box
        sx={{
          width: 0,
          height: 0,
          borderTop: `${width}px solid ${topLeftColor}`,
          borderRight: `${width}px solid transparent`,
        }}
      ></Box>
    </Box>
  );
}
