import { useMemo } from 'react';

import { MathSummary } from '@/components/display/MathSummary/MathSummary';
import { GratAnnuityFragment } from '@/modules/entities/gratTrusts/graphql/GratTrust.generated';
import { getAnnuitiesStatistics } from '@/modules/entities/gratTrusts/performance/getAnnuitiesStatistics';
import { formatCurrency } from '@/utils/formatting/currency';

interface PaymentsSummaryProps {
  annuities: GratAnnuityFragment[];
}

export function PaymentsSummary({ annuities }: PaymentsSummaryProps) {
  const { totalPayments, paymentsMade, remainingPayments } = useMemo(
    () => getAnnuitiesStatistics(annuities),
    [annuities]
  );

  return (
    <MathSummary
      valueOne={{
        title: 'Total payments',
        value: formatCurrency(totalPayments),
      }}
      valueTwo={{
        title: 'Payments made',
        value: formatCurrency(paymentsMade),
      }}
      sum={{
        title: 'Remaining payments',
        value: formatCurrency(remainingPayments),
      }}
    />
  );
}
