import { compact, groupBy, intersection, orderBy, uniqBy } from 'lodash';
import { useMemo } from 'react';

import {
  BUSINESS_ENTITY_KINDS,
  CHARITABLE_ENTITY_KINDS,
} from '@/modules/entities/entities.constants';
import { DEATH_PAYOUT_ENTITY_KINDS } from '@/modules/estateWaterfall/waterfallGraph/constants';
import {
  ContextPrimaryClient,
  useHouseholdDetailsContext,
} from '@/modules/household/contexts/householdDetails.context';
import { EntityInEstateStatus, EntityStage } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { EstateWaterfallModal_EntityFragment } from '../../graphql/EstateWaterfallModal.generated';
import { useWaterfallModalQuery } from '../../hooks/useWaterfallModalQuery';
import {
  EntityRow,
  GroupKind,
  GroupRow,
  OwnershipStakeRow,
  Row,
} from '../EntityMultiSelectTable.types';
import {
  getGroupNameForGroupKind,
  isGroupKind,
} from '../EntityMultiSelectTable.utils';

const getGrantorOrOwnerOrDonorIds = (
  entitySubtype: EstateWaterfallModal_EntityFragment['subtype']
): string[] => {
  if ('grantors' in entitySubtype) {
    return compact(entitySubtype.grantors?.map((g) => g.individual?.id));
  }
  if ('owners' in entitySubtype) {
    return compact(entitySubtype.owners?.map((o) => o.individual?.id));
  }
  if ('donors' in entitySubtype) {
    return compact(entitySubtype.donors?.map((d) => d.individual?.id));
  }
  if ('grantor' in entitySubtype) {
    return compact([entitySubtype.grantor?.individual?.id]);
  }
  if ('owner' in entitySubtype) {
    return compact([entitySubtype.owner?.individual?.id]);
  }
  return [];
};

function getGroupKindForEntity(
  entity: EstateWaterfallModal_EntityFragment,
  primaryClients: ContextPrimaryClient[]
) {
  const subtype = entity.subtype;

  if (BUSINESS_ENTITY_KINDS.includes(entity.kind)) {
    throw new UnreachableError({
      case: entity.subtype.__typename as never,
      message: `Business entities should be filtered out ${subtype.__typename}`,
    });
  }

  const primaryClientIds = primaryClients.map((client) => client.id);
  const isJoint = (ids: string[]) =>
    intersection(ids, primaryClientIds).length > 1;
  const isGrantorA = (ids: string[]) =>
    intersection(ids, primaryClientIds).length === 1 &&
    ids[0] === primaryClientIds[0];
  const isGrantorB = (ids: string[]) =>
    intersection(ids, primaryClientIds).length === 1 &&
    ids[0] === primaryClientIds[1];

  const grantorsOrOwnersOrDonorsIds = getGrantorOrOwnerOrDonorIds(subtype);
  const inEstateStatus =
    ('inEstateStatus' in subtype ? subtype.inEstateStatus : null) ?? null;

  const inEstate = inEstateStatus === EntityInEstateStatus.InEstate;

  if (isJoint(grantorsOrOwnersOrDonorsIds)) {
    if (inEstate) return GroupKind.IN_ESTATE_JOINT;
  }
  if (isGrantorA(grantorsOrOwnersOrDonorsIds)) {
    if (inEstate) return GroupKind.IN_ESTATE_GRANTOR_A;
  }
  if (isGrantorB(grantorsOrOwnersOrDonorsIds)) {
    if (inEstate) return GroupKind.IN_ESTATE_GRANTOR_B;
  }

  if (CHARITABLE_ENTITY_KINDS.includes(entity.kind)) {
    return GroupKind.OUT_OF_ESTATE_CHARITY;
  }

  return GroupKind.OUT_OF_ESTATE_FAMILY_GIVING;
}

interface UseEntityMultiSelectTableRowsInput {
  entities: EstateWaterfallModal_EntityFragment[];
  hypotheticalTransfers: ReturnType<
    typeof useWaterfallModalQuery
  >['hypotheticalTransfers'];
}

export function useEntityMultiSelectTableRows({
  entities,
  hypotheticalTransfers,
}: UseEntityMultiSelectTableRowsInput) {
  const { primaryClients } = useHouseholdDetailsContext();

  const rows: Row[] = useMemo(() => {
    const filteredEntities = entities.filter((entity) => {
      if (DEATH_PAYOUT_ENTITY_KINDS.includes(entity.kind)) {
        return true;
      }

      const entityExists = entity.stage !== EntityStage.Draft;

      if (!entityExists) {
        // If draft, show entity when it is a transfer source
        return hypotheticalTransfers.some((transfer) => {
          const sourceId = transfer.sourceEntity?.id;
          return sourceId && sourceId === entity.id;
        });
      }

      return (
        entityExists && !BUSINESS_ENTITY_KINDS.includes(entity.kind) // Business entities are represented as individuals
      );
    });

    const entitiesGroupedByEstateStatus = groupBy(filteredEntities, (e) =>
      getGroupKindForEntity(e, primaryClients ?? [])
    );

    // Make group rows for ownership stakes
    const groupRowsForOwnershipStakes: GroupRow[] =
      primaryClients?.flatMap((_client, idx) => {
        const root =
          idx === 0
            ? GroupKind.IN_ESTATE_GRANTOR_A
            : GroupKind.IN_ESTATE_GRANTOR_B;

        return {
          id: root,
          group: root,
          path: [root],
          name: getGroupNameForGroupKind(root, {
            grantorA: primaryClients[0]?.firstName,
            grantorB: primaryClients[1]?.firstName,
          }),
        };
      }) ?? [];

    const groupsForEntities = Object.keys(
      entitiesGroupedByEstateStatus
    ) as GroupKind[];

    // Make the group rows for entities
    const groupRowsForEntities: GroupRow[] = groupsForEntities.map((group) => {
      if (!isGroupKind(group)) {
        throw new UnreachableError({
          case: group,
          message: `Unrecognized group ${group}`,
        });
      }

      return {
        id: group,
        group,
        path: [group],
        name: getGroupNameForGroupKind(group, {
          grantorA: primaryClients?.[0]?.firstName,
          grantorB: primaryClients?.[1]?.firstName,
        }),
      };
    });

    const sortOrder = [
      GroupKind.IN_ESTATE_GRANTOR_A,
      GroupKind.IN_ESTATE_GRANTOR_B,
      GroupKind.IN_ESTATE_JOINT,
      GroupKind.OUT_OF_ESTATE_FAMILY_GIVING,
      GroupKind.OUT_OF_ESTATE_CHARITY,
    ];

    const groupRows = orderBy(
      uniqBy(
        [...groupRowsForOwnershipStakes, ...groupRowsForEntities] as GroupRow[],
        'id'
      ),
      (row) => sortOrder.indexOf(row.group),
      'asc'
    );

    // Make the entity rows
    const entityRows: EntityRow[] = Object.entries(
      entitiesGroupedByEstateStatus
    ).flatMap(([group, entities]) => {
      return entities.flatMap((entity) => ({
        id: entity.id,
        entityId: entity.id,
        path: [group, entity.id],
        name: entity.subtype.displayName,
      }));
    });

    // Make a row for ownership stakes
    const ownershipStakesRows: OwnershipStakeRow[] =
      groupRowsForOwnershipStakes?.flatMap((row) => {
        const root = row.id as
          | GroupKind.IN_ESTATE_GRANTOR_A
          | GroupKind.IN_ESTATE_GRANTOR_B;
        const clientId =
          root === GroupKind.IN_ESTATE_GRANTOR_A
            ? primaryClients?.[0]?.id
            : primaryClients?.[1]?.id;
        const owner = primaryClients?.find((client) => client.id === clientId);

        if (!owner) return [];

        const ownerId = owner.id;

        return {
          id: ownerId,
          individualId: ownerId,
          path: [root, ownerId],
          name: `${owner.firstName}’s directly-held assets`,
        };
      }) ?? [];

    return [...groupRows, ...entityRows, ...ownershipStakesRows];
  }, [entities, hypotheticalTransfers, primaryClients]);

  return {
    rows,
  };
}
