import { compact } from 'lodash';
import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

export function useDraftEntityGrantorOptions(): SelectInputOption<string>[] {
  const { possibleGrantors } = useHouseholdDetailsContext();

  return useMemo(() => {
    if (!possibleGrantors) {
      return [];
    }

    const grantors = possibleGrantors.slice();

    return compact([
      grantors.length >= 2
        ? {
            value: grantors
              .sort()
              .map(({ id }) => id)
              .join(','),
            display: grantors
              .sort()
              .map(({ displayName }) => displayName)
              .join(' and '),
          }
        : null,
      ...grantors.map((grantor) => ({
        value: grantor.id,
        display: grantor.displayName,
      })),
    ]);
  }, [possibleGrantors]);
}
