import { Stack, StackProps } from '@mui/material';

import { DotsMoveIcon, IconProps } from '@/components/icons/DotsMoveIcon';

export interface DraghandleProps extends StackProps {
  iconProps?: IconProps;
}

export function Draghandle({ iconProps, ...props }: DraghandleProps) {
  return (
    <Stack px={1} justifyContent="center" {...props}>
      <DotsMoveIcon size={24} {...iconProps} />
    </Stack>
  );
}
