import { compact, includes } from 'lodash';
import { ArrayElement } from 'type-fest/source/internal';

import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { CHARITABLE_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { getEntityTypeFromSubtype } from '@/modules/entities/utils/getEntityTypeFromSubtype';
import { ClientOrganizationKind, EntityKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { TransferOption, TransferOptionType } from '../TransferOption.type';
import {
  TransferModalOptions_ClientOrganizationFragment,
  TransferModalOptions_EntityFragment,
  TransferModalOptionsQuery,
} from './graphql/TransferModalOptions.generated';
import { TransferType } from './LogNewTransferModal.types';

const ENTITY_FILTER_FUNCTIONS: {
  [key in TransferType]: (
    isCharitableDistribution: boolean
  ) => (arg: TransferModalOptions_EntityFragment) => boolean;
} = {
  [TransferType.Distribution]:
    (isCharitableDistribution) =>
    ({ kind }): boolean => {
      if (isCharitableDistribution) {
        return false;
      }
      return ![
        EntityKind.GratTrust,
        EntityKind.CrtTrust,
        EntityKind.CltTrust,
        EntityKind.InsurancePersonalAccount,
      ].includes(kind);
    },
  [TransferType.Contribution]:
    (_) =>
    (entity): boolean => {
      if (!entity.subtype.__typename) {
        return false;
      }
      const entityType = getEntityTypeFromSubtype(entity.subtype.__typename);

      return !includes(
        [...CHARITABLE_ENTITY_TYPES, 'insurance-account'],
        entityType
      );
    },
};

const ORGANIZATION_FILTER_FUNCTIONS: {
  [key in TransferType]: (
    isCharitableDistribution: boolean
  ) => (arg: TransferModalOptions_ClientOrganizationFragment) => boolean;
} = {
  [TransferType.Distribution]: (isCharitableDistribution) => (organization) => {
    if (isCharitableDistribution) {
      return (
        organization.kind === ClientOrganizationKind.CharitableOrganization
      );
    }
    return true;
  },
  [TransferType.Contribution]:
    (_) =>
    ({ kind }) =>
      !!kind && ![ClientOrganizationKind.CharitableOrganization].includes(kind),
};

export function mapDataToSourceRecipientOptions(
  data: TransferModalOptionsQuery | undefined | null,
  initiatorId: string,
  transferType: TransferType,
  isCharitableDistribution: boolean,
  isInboundDistribution: boolean
): {
  transferOptions: TransferOption[];
  selectOptions: SelectInputProps['options'];
} {
  if (!data) {
    return { transferOptions: [], selectOptions: [] };
  }

  const household = getNodes(data?.households)[0];
  const grantorOptions: TransferOption[] =
    (!isCharitableDistribution &&
      household?.possiblePrimaryClients.map(({ id, displayName }) => ({
        id: id,
        label: displayName,
        type: TransferOptionType.Grantor,
      }))) ||
    [];

  const entityOptions: TransferOption[] =
    getNodes(household?.entities)
      .filter(({ id }) => id !== initiatorId)
      .filter((e) => {
        let transferTypeForFilter = transferType;
        if (isInboundDistribution) {
          transferTypeForFilter = TransferType.Distribution;
        }
        const filterFunction = ENTITY_FILTER_FUNCTIONS[transferTypeForFilter](
          isCharitableDistribution
        );
        return filterFunction(e);
      })
      .map(({ id, kind, subtype: { displayName } }) => ({
        id,
        label: displayName,
        type: TransferOptionType.Entity,
        entityKind: kind,
      })) ?? [];

  const organizationOptions: TransferOption[] =
    (household?.clientOrganizations || [])
      .filter((e) => {
        let transferTypeForFilter = transferType;
        if (isInboundDistribution) {
          transferTypeForFilter = TransferType.Distribution;
        }
        const filterFunction = ORGANIZATION_FILTER_FUNCTIONS[
          transferTypeForFilter
        ](isCharitableDistribution);
        return filterFunction(e);
      })
      .map(({ id, name }) => ({
        id,
        label: name,
        type: TransferOptionType.Organization,
      })) ?? [];

  const transferOptions = [
    ...grantorOptions,
    ...entityOptions,
    ...organizationOptions,
  ];

  return {
    transferOptions,
    selectOptions: getOptions(transferOptions),
  };
}

const mapSelectOptionToFormOption = ({
  id,
  label,
}: TransferOption): ArrayElement<SelectInputProps['options']> => ({
  value: id,
  display: label,
});

function getOptions(
  transactionTargetOptions: TransferOption[]
): SelectInputProps['options'] {
  const grantorOptions: SelectInputProps['options'] = transactionTargetOptions
    .filter(({ type }) => type === TransferOptionType.Grantor)
    .map(mapSelectOptionToFormOption);
  const entityOptions: SelectInputProps['options'] = transactionTargetOptions
    .filter(({ type }) => type === TransferOptionType.Entity)
    .map(mapSelectOptionToFormOption);
  const organizationOptions: SelectInputProps['options'] =
    transactionTargetOptions
      .filter(({ type }) => type === TransferOptionType.Organization)
      .map(mapSelectOptionToFormOption);

  return compact([
    grantorOptions.length
      ? {
          component: <SelectItemGroupLabel label="Individuals" />,
          type: 'component' as const,
        }
      : null,
    ...grantorOptions,

    organizationOptions.length
      ? {
          component: <SelectItemGroupLabel label="Organizations" />,
          type: 'component' as const,
        }
      : null,
    ...organizationOptions,

    entityOptions.length
      ? {
          component: <SelectItemGroupLabel label="Entities" />,
          type: 'component' as const,
        }
      : null,
    ...entityOptions,
  ]);
}
