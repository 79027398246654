import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { AccordionProps } from '@/components/Accordion/Accordion';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { useFormContext } from '@/components/react-hook-form';
import { DispositiveProvisionDispositionKind } from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { useDispositiveProvisionsTemplateSplitScreenModalContext } from '../../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.context';
import {
  DISPOSITIVE_PROVISIONS_FORM_NAMESPACE,
  getTransferTaxKindOptions,
} from '../DispositiveProvisionsForm.constants';
import {
  DispositiveProvisionsFormPaths,
  DispositiveProvisionsFormShape,
  Recipient,
} from '../DispositiveProvisionsForm.types';
import {
  useRecipientOptions,
  useRecipientOptionsParams,
} from '../hooks/useRecipientOptions';
import { DispositiveProvisionsRecipientTemplateRecipientConfiguration } from './DispositiveProvisionsRecipient.types';
import { RecipientNotesBadge } from './RecipientNotesBadge';

export function useRecipientIdDisplayNameMap() {
  const recipientOptionsParams = useRecipientOptionsParams();
  const { options: recipientOptions } = useRecipientOptions(
    recipientOptionsParams
  );
  const recipientIdToDisplayName = useMemo(() => {
    return recipientOptions.reduce(
      (acc, recipientOption) => {
        if ('component' in recipientOption) return acc;
        return {
          ...acc,
          [recipientOption.value]: recipientOption.display,
        };
      },
      {} as Record<string, string>
    );
  }, [recipientOptions]);

  return recipientIdToDisplayName;
}

export function useRecipientAccordionProps(
  recipientIndex: number,
  simulatedValue: Decimal | null,
  hasStateTax: boolean,
  recipientConfiguration: DispositiveProvisionsRecipientTemplateRecipientConfiguration,
  isOptional = false
): Pick<
  AccordionProps,
  'title' | 'subtitle' | 'description' | 'rightAdornment'
> {
  const { control } = useFormContext<DispositiveProvisionsFormShape>();
  const recipientIdToDisplayName = useRecipientIdDisplayNameMap();
  const { isTemplateMode } =
    useDispositiveProvisionsTemplateSplitScreenModalContext();

  function getRecipientNameFromId(recipientId: string | null) {
    if (!recipientId) return 'Recipient';
    const possibleDisplayName = recipientIdToDisplayName[recipientId];
    return possibleDisplayName || 'Recipient';
  }

  const transferTaxKind = useWatch({
    control,
    name: `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients.${recipientIndex}.transferTaxKind` as const satisfies DispositiveProvisionsFormPaths,
  });

  const recipientId = useWatch({
    control,
    name: `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients.${recipientIndex}.recipientId` as const satisfies DispositiveProvisionsFormPaths,
  });

  const recipient = useWatch({
    control,
    name: `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients.${recipientIndex}` as const satisfies DispositiveProvisionsFormPaths,
  });

  if (!recipient)
    return {
      title: 'Recipient',
    };

  return {
    title: getRecipientNameFromId(recipientId),
    subtitle:
      getTransferTaxKindOptions({
        hasStateTax,
        recipientConfiguration,
      }).find((o) => o.value === transferTaxKind)?.display ?? undefined,
    description: getDistributionSummary(recipient, recipientConfiguration),
    rightAdornment: (
      <Stack spacing={0.5} alignItems="flex-end">
        <Typography variant="body1">
          {isTemplateMode
            ? null
            : formatCurrency(simulatedValue ?? new Decimal(0))}
        </Typography>
        {recipient.notes && <RecipientNotesBadge notes={recipient.notes} />}
        {isOptional && (
          <Badge variant={BadgeVariants.Blue} display="Optional" />
        )}
      </Stack>
    ),
  };
}

function getDistributionSummary(
  recipient: Recipient,
  recipientConfiguration: DispositiveProvisionsRecipientTemplateRecipientConfiguration
): string {
  const { dispositionKind_ButtonGroup, dispositionKind_Select } = recipient;

  // if no kind is selected, there's nothing we can do
  if (!dispositionKind_ButtonGroup && !dispositionKind_Select) return '';
  switch (recipient.dispositionKind_ButtonGroup) {
    case DispositiveProvisionDispositionKind.Percentage:
      if (!recipient.dispositionPercentage) return '';
      return `${formatPercent(
        recipient.dispositionPercentage ?? new Decimal(0),
        2
      )}%`;
    case DispositiveProvisionDispositionKind.Amount:
      if (!recipient.dispositionAmount) return '';
      return formatCurrency(recipient.dispositionAmount ?? new Decimal(0));
    case 'other':
      switch (recipient.dispositionKind_Select) {
        case DispositiveProvisionDispositionKind.AnythingLeftOver:
          return 'Anything left over';
        case DispositiveProvisionDispositionKind.RemainingGstExclusionOfGrantorInExcessOfLifetimeExclusion:
          if (
            recipientConfiguration.displayHeaderAsInExcessOfLifetimeExclusion
          ) {
            return 'Remaining GST exemption in excess of lifetime exclusion';
          }
          return 'Remaining GST exemption';
        case DispositiveProvisionDispositionKind.RemainingLifetimeExclusionOfGrantor:
          return 'Remaining lifetime exemption';
        case DispositiveProvisionDispositionKind.RemainingStateExemption:
          return 'Remaining state exemption';
        case DispositiveProvisionDispositionKind.RemainingFederalLifetimeExemptionInExcessOfStateExemption:
          return 'Remaining federal lifetime exemption (in excess of state exemption)';
        case '':
          return '';
      }
  }
}
