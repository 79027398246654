import React from 'react';

import { Card, CardProps } from '@/components/layout/Card/Card';

export type DataTableCardProps = Partial<CardProps>;

export function DataTableCard({
  children,
  sx,
  variant = 'outlined',
  ...cardProps
}: DataTableCardProps) {
  return (
    <Card
      variant={variant}
      // Note: for some reason classes isn't specific enough here so sx is needed, not sure why
      sx={{ p: 3, height: '100%', flexGrow: 1, ...sx }}
      {...cardProps}
    >
      {children}
    </Card>
  );
}
