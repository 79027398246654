// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProposalScenarioProjectionQueryVariables = Types.Exact<{
  input: Types.ProposalScenarioProjectionParamsV2;
}>;


export type ProposalScenarioProjectionQuery = { __typename?: 'Query', proposalScenarioProjectionV2: { __typename?: 'ProposalScenarioProjectionResult', preTax: { __typename?: 'ProposalScenarioProjection', noPlan: { __typename?: 'ProposalScenarioProjectionValue', inEstate: CurrencyUSD, outOfEstate: CurrencyUSD, estateTax: CurrencyUSD }, withPlan: { __typename?: 'ProposalScenarioProjectionValue', inEstate: CurrencyUSD, outOfEstate: CurrencyUSD, estateTax: CurrencyUSD } }, postTax: { __typename?: 'ProposalScenarioProjection', noPlan: { __typename?: 'ProposalScenarioProjectionValue', inEstate: CurrencyUSD, outOfEstate: CurrencyUSD, estateTax: CurrencyUSD }, withPlan: { __typename?: 'ProposalScenarioProjectionValue', inEstate: CurrencyUSD, outOfEstate: CurrencyUSD, estateTax: CurrencyUSD } } } };


export const ProposalScenarioProjectionDocument = gql`
    query ProposalScenarioProjection($input: ProposalScenarioProjectionParamsV2!) {
  proposalScenarioProjectionV2(input: $input) {
    preTax {
      noPlan {
        inEstate
        outOfEstate
        estateTax
      }
      withPlan {
        inEstate
        outOfEstate
        estateTax
      }
    }
    postTax {
      noPlan {
        inEstate
        outOfEstate
        estateTax
      }
      withPlan {
        inEstate
        outOfEstate
        estateTax
      }
    }
  }
}
    `;

/**
 * __useProposalScenarioProjectionQuery__
 *
 * To run a query within a React component, call `useProposalScenarioProjectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useProposalScenarioProjectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProposalScenarioProjectionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProposalScenarioProjectionQuery(baseOptions: Apollo.QueryHookOptions<ProposalScenarioProjectionQuery, ProposalScenarioProjectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProposalScenarioProjectionQuery, ProposalScenarioProjectionQueryVariables>(ProposalScenarioProjectionDocument, options);
      }
export function useProposalScenarioProjectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProposalScenarioProjectionQuery, ProposalScenarioProjectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProposalScenarioProjectionQuery, ProposalScenarioProjectionQueryVariables>(ProposalScenarioProjectionDocument, options);
        }
export function useProposalScenarioProjectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProposalScenarioProjectionQuery, ProposalScenarioProjectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProposalScenarioProjectionQuery, ProposalScenarioProjectionQueryVariables>(ProposalScenarioProjectionDocument, options);
        }
export type ProposalScenarioProjectionQueryHookResult = ReturnType<typeof useProposalScenarioProjectionQuery>;
export type ProposalScenarioProjectionLazyQueryHookResult = ReturnType<typeof useProposalScenarioProjectionLazyQuery>;
export type ProposalScenarioProjectionSuspenseQueryHookResult = ReturnType<typeof useProposalScenarioProjectionSuspenseQuery>;
export type ProposalScenarioProjectionQueryResult = Apollo.QueryResult<ProposalScenarioProjectionQuery, ProposalScenarioProjectionQueryVariables>;
export const Operations = {
  Query: {
    ProposalScenarioProjection: 'ProposalScenarioProjection' as const
  }
}