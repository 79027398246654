import { useMemo } from 'react';

import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { FormAwareSelectInputProps } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { EstateWaterfallHypotheticalTransferTransferTaxKind } from '@/types/schema';

import { HypotheticalTransferFormModalShape } from '../HypotheticalTransferModal.types';
import { serializeTransferType } from '../HypotheticalTransferModal.utils';

type OutputType =
  FormAwareSelectInputProps<HypotheticalTransferFormModalShape>['options'];

const NON_TAXABLE_TRANSFER_TYPES: OutputType = [
  {
    type: 'component',
    component: <SelectItemGroupLabel label="Non-taxable gifts" />,
  },
  {
    value:
      EstateWaterfallHypotheticalTransferTransferTaxKind.AnualExclusionGift,
    display: 'Annual exclusion gift',
  },
  {
    value: EstateWaterfallHypotheticalTransferTransferTaxKind.CharitableGift,
    display: 'Charitable gift',
  },
  {
    value:
      EstateWaterfallHypotheticalTransferTransferTaxKind.GenericNonTaxableGift,
    display: 'Non-taxable transfer',
  },
];

export function useHypotheticalTransferType(): OutputType {
  const { primaryClients } = useHouseholdDetailsContext();

  const taxableTransferTypes = useMemo(() => {
    if (!primaryClients?.length) {
      return [];
    }
    const output: OutputType = [];
    output.push({
      type: 'component',
      component: <SelectItemGroupLabel label="Taxable gift from" />,
    });

    if (primaryClients.length === 2) {
      const value = serializeTransferType(
        EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift,
        primaryClients.map(({ id }) => id)
      );
      output.push({
        value,
        display: primaryClients
          .map((grantor) => grantor.displayName)
          .join(' and '),
      });
    }

    primaryClients.forEach((grantor) => {
      const value = serializeTransferType(
        EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift,
        [grantor.id]
      );
      output.push({
        value,
        display: grantor.displayName,
      });
    });
    return output;
  }, [primaryClients]);

  return [...taxableTransferTypes, ...NON_TAXABLE_TRANSFER_TYPES];
}
