import Decimal from 'decimal.js';

import { PathsOf } from '@/types/subform';

export const NAMESPACE = 'substitutionThresholdSubform' as const;
export type NamespaceType = typeof NAMESPACE;
const VARIANTS = ['standard'] as const;
export type VariantType = (typeof VARIANTS)[number];

// this is the type of the subform's values
export interface SubstitutionThresholdSubformType {
  downsidePercent: Decimal | null;
  upsidePercent: Decimal | null;
  upsideValue: Decimal | null;
  upsideAsTarget: boolean;
}

// this is what the subform's values will be nested under in the parent form
export interface Fields {
  [NAMESPACE]: SubstitutionThresholdSubformType;
}

export type SubformField = PathsOf<Fields>;

// this is Props for the UI component
export interface SubformComponentProps {
  // standard set of props
  variant: VariantType;
  householdId: string;
  subformValues: SubstitutionThresholdSubformType;
  disabled?: boolean;
}
