import { TenantUsageBucket } from '@/types/schema';

export const usageBucketOptions = [
  {
    display: 'Internal',
    value: TenantUsageBucket.Internal,
  },
  {
    display: 'Unpaid (partner, will never convert to paid)',
    value: TenantUsageBucket.Unpaid,
  },
  {
    display: 'Unpaid trial (may convert to paid)',
    value: TenantUsageBucket.UnpaidTrial,
  },
  {
    display: 'Paid customer',
    value: TenantUsageBucket.Paid,
  },
  {
    display: 'Churned customer',
    value: TenantUsageBucket.Churned,
  },
];
