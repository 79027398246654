import { isEmpty, isEqual } from 'lodash';
import { useEffect } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import {
  ClientPresentationSection,
  UpdateClientPresentationInput,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { StringArrayOrNull } from '../../clientPresentation.types';
import { useGuardedClientPresentationDesignerContext } from '../../contexts/clientPresentationDesigner.context';
import { useUpdateClientPresentationMutation } from '../graphql/ClientPresentationDesigner.generated';

interface PersistPresentationUpdatesParams {
  hasInitializedState: boolean;
  initialVisibleEntityPulids: StringArrayOrNull;
  initialVisibleWaterfallPulids: StringArrayOrNull;
  initialShowCoverSlide: boolean;
  initialSectionOrder: ClientPresentationSection[];
  presentationId: string;
}

export function usePersistPresentationUpdates({
  hasInitializedState,
  initialShowCoverSlide,
  initialVisibleEntityPulids,
  initialVisibleWaterfallPulids,
  initialSectionOrder,
  presentationId,
}: PersistPresentationUpdatesParams) {
  const {
    visibleEntityPulids,
    visibleWaterfallPulids,
    shouldShowCoverSlide,
    visibleSectionOrder: sectionOrder,
  } = useGuardedClientPresentationDesignerContext();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const [updateClientPresentation] = useUpdateClientPresentationMutation({
    onError: (err) => {
      reportError('failed to update client presentation', err);
      showFeedback(
        "We weren't able to save your update. Please refresh the page and try again."
      );
    },
  });

  useEffect(() => {
    // we only want to start persisting changes to the backend once we've set up all of the necessary state
    if (!hasInitializedState) return;

    const update: UpdateClientPresentationInput = {};

    if (!isEqual(sectionOrder, initialSectionOrder)) {
      update.sectionOrder = sectionOrder;
    }

    if (!isEqual(visibleEntityPulids, initialVisibleEntityPulids)) {
      update.includeAllEntities = visibleEntityPulids === null;
      update.entityOrder = visibleEntityPulids ?? [];
    }
    if (!isEqual(visibleWaterfallPulids, initialVisibleWaterfallPulids)) {
      update.includeAllEstateWaterfalls = visibleWaterfallPulids === null;
      update.estateWaterfallOrder = visibleWaterfallPulids ?? [];
    }
    if (!isEqual(shouldShowCoverSlide, initialShowCoverSlide)) {
      update.showCoverSlide = shouldShowCoverSlide;
    }

    // if there are no updates to make... we shouldn't make updates
    if (isEmpty(update)) {
      return;
    }

    diagnostics.debug('Updating client presentation configuration:', update);

    void updateClientPresentation({
      variables: {
        input: {
          id: presentationId,
          update,
        },
      },
    });
  }, [
    hasInitializedState,
    initialSectionOrder,
    initialShowCoverSlide,
    initialVisibleEntityPulids,
    initialVisibleWaterfallPulids,
    presentationId,
    sectionOrder,
    shouldShowCoverSlide,
    updateClientPresentation,
    visibleEntityPulids,
    visibleWaterfallPulids,
  ]);
}
