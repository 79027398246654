import { Stack } from '@mui/material';
import { useContext, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { SplitButton } from '@/components/form/baseInputs/SplitButton/SplitButton';
import { Footer } from '@/components/navigation/Footer';

import { ILITTaskContext } from '../ILITTaskContext';
import { ILITSkipTaskModal } from './ILITTaskFooter.UI';

export interface ILITTaskFooterProps {
  disabled: boolean;
  submitting: boolean;
  onComplete: () => void;
  skippable?: boolean;
}

export function ILITTaskFooter({
  disabled,
  submitting,
  onComplete,
  skippable = false,
}: ILITTaskFooterProps) {
  const { onClose } = useContext(ILITTaskContext);
  const [isSkipModalOpen, setSkipModalOpen] = useState<boolean>(false);
  return (
    <>
      <Footer
        leftAction={
          <Button
            size="sm"
            variant="secondary"
            onClick={onClose}
            loading={submitting}
          >
            Exit without saving
          </Button>
        }
        rightAction={
          <Stack direction="row" spacing={3}>
            {skippable ? (
              <SplitButton
                buttonContent="Mark task as done"
                items={[
                  {
                    name: 'Skip this task',
                    clickHandler: () => setSkipModalOpen(true),
                  },
                ]}
                htmlId="mark-task-complete"
                variant="primary"
                size="sm"
                onClick={onComplete}
                disabled={disabled}
                loading={submitting}
              />
            ) : (
              <Button
                variant="primary"
                size="sm"
                onClick={onComplete}
                disabled={disabled}
                loading={submitting}
              >
                Mark task as done
              </Button>
            )}
          </Stack>
        }
      />
      <ILITSkipTaskModal
        isOpen={isSkipModalOpen}
        onClose={() => setSkipModalOpen(false)}
      />
    </>
  );
}
