import { Box, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePreviousDistinct } from 'react-use';
import { makeStyles } from 'tss-react/mui';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { useMostRecentlyViewedHouseholds } from '@/modules/user/hooks/useMostRecentlyViewedHouseholds';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';

import { AppBar, appbarHeight, AppBarProps } from './AppBar';
import { AppBarMenuItems } from './AppBarMenuItems';

const useStyles = makeStyles()((theme) => ({
  appBar: {
    height: appbarHeight,
    minHeight: appbarHeight,
  },
  toolBar: {
    backgroundColor: COLORS.PRIMITIVES.WHITE,
    borderBottom: `1px solid ${COLORS.GRAY[200]}`,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

export function ClientDetailsAppBar(props: AppBarProps) {
  const { classes } = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { mostRecentlyViewedHouseholds } = useMostRecentlyViewedHouseholds();

  const { householdId, displayName: contextDisplayName } =
    useHouseholdDetailsContext();

  const prevDisplayName = usePreviousDistinct(contextDisplayName);
  const displayName = useMemo(() => {
    if (!contextDisplayName) return prevDisplayName;

    return contextDisplayName;
  }, [contextDisplayName, prevDisplayName]);

  const menuItems = useMemo(() => {
    const menuItemHouseholds = mostRecentlyViewedHouseholds
      .filter(({ id }) => id !== householdId)
      .slice(0, 4);

    const households = menuItemHouseholds.map(({ id, displayName }, idx) => {
      return (
        <MenuItem
          key={id}
          muiMenuItemProps={{
            divider: idx === menuItemHouseholds.length - 1,
          }}
          typographyProps={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            sx: {
              ...theme.typography.button2,
            },
          }}
          label={displayName}
          onClick={() =>
            navigate(
              getCompletePathFromRouteKey(
                ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
                {
                  householdId: id,
                }
              )
            )
          }
        />
      );
    });

    return (
      <>
        {households}
        <MenuItem
          key="back"
          label="Back to all clients"
          typographyProps={{
            sx: {
              ...theme.typography.button2,
            },
          }}
          onClick={() =>
            navigate(
              getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLDS_LIST, {})
            )
          }
        />
      </>
    );
  }, [
    householdId,
    mostRecentlyViewedHouseholds,
    navigate,
    theme.typography.button2,
  ]);

  return (
    <AppBar
      classes={{
        appBar: classes.appBar,
        toolBar: classes.toolBar,
      }}
      showLogomark={false}
      {...props}
    >
      {displayName && (
        <ButtonWithPopover
          size="xs"
          variant="secondary"
          label={displayName}
          popperVariant="menuBelow"
        >
          <Box maxWidth={250} overflow="hidden">
            {menuItems}
          </Box>
        </ButtonWithPopover>
      )}
      <Box flexGrow={1} />
      <AppBarMenuItems disableActiveHighlight appBarTheme="light" />
    </AppBar>
  );
}
