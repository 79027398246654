import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useCallback, useMemo } from 'react';

import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { DragAndDropList } from '@/components/lists/DragAndDropList/DragAndDropList';
import { TableCell, TableCellContext } from '@/components/lists/TableCell';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useDebouncedFn } from '@/hooks/useDebouncedFn';
import { useReportError } from '@/hooks/useReportError';
import { useCreateOrUpdateHypotheticalTransfersMutation } from '@/modules/entities/details/HypotheticalTransfersCard/hypotheticalTransfers/graphql/CreateOrUpdateHypotheticalTransfers.generated';
import { TransferDirection } from '@/modules/entities/details/HypotheticalTransfersCard/hypotheticalTransfers/HypotheticalTransferForm';
import { getUpdateHypotheticalTransfersOrderInput } from '@/modules/entities/details/HypotheticalTransfersCard/hypotheticalTransfers/hypotheticalTransfers.utils';
import { SortableListItem } from '@/modules/estateTaxScenarios/forms/PaymentMethodForm/useDraggablePayoutOrderItems';
import { EstateWaterfallDocument } from '@/modules/estateWaterfall/graphql/EstateWaterfall.generated';
import { COLORS } from '@/styles/tokens/colors';
import {
  EstateWaterfallHypotheticalTransferDestinationKind,
  EstateWaterfallHypotheticalTransferSourceKind,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import {
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';

import { HypotheticalTransfersSummary_EstateWaterfallHypotheticalTransferFragment } from '../HypotheticalTransfersSummaryPanel/graphql/HypotheticalTransfersSummary.generated';

export interface ExternalTransferListProps {
  waterfallId: string;
  direction: TransferDirection;
  transfers: HypotheticalTransfersSummary_EstateWaterfallHypotheticalTransferFragment[];
  openModal: (transferId: string) => void;
}

function getTransferDisplayValues(
  transfer: HypotheticalTransfersSummary_EstateWaterfallHypotheticalTransferFragment,
  isOutbound: boolean
): { heading: string; description: string } {
  const { sourceKind, destinationKind } = transfer;

  if (isOutbound) {
    switch (sourceKind) {
      case EstateWaterfallHypotheticalTransferSourceKind.Entity:
        return {
          heading: transfer.sourceEntity?.subtype.displayName ?? '',
          description: transfer.sourceEntity?.extendedDisplayKind ?? '',
        };
      case EstateWaterfallHypotheticalTransferSourceKind.Individual:
        return {
          heading: transfer.sourceIndividual?.displayName ?? '',
          description: 'Individual',
        };
      case EstateWaterfallHypotheticalTransferSourceKind.External:
        return {
          heading: 'External',
          description: 'External',
        };
      default:
        throw new UnreachableError({
          case: sourceKind,
          message: 'invalid source kind for transfer',
        });
    }
  } else {
    switch (destinationKind) {
      case EstateWaterfallHypotheticalTransferDestinationKind.Entity:
        return {
          heading: transfer.destinationEntity?.subtype.displayName ?? '',
          description: transfer.destinationEntity?.extendedDisplayKind ?? '',
        };
      case EstateWaterfallHypotheticalTransferDestinationKind.Individual:
        return {
          heading: transfer.destinationIndividual?.displayName ?? '',
          description: 'Individual',
        };
      case EstateWaterfallHypotheticalTransferDestinationKind.Organization:
        return {
          heading: transfer.destinationOrganization?.displayName ?? '',
          description: 'Organization',
        };
      case EstateWaterfallHypotheticalTransferDestinationKind.External:
        return {
          heading: 'External',
          description: 'External',
        };
      default:
        throw new UnreachableError({
          case: destinationKind,
          message: 'invalid destination kind for transfer',
        });
    }
  }
}

export function ExternalTransferList({
  waterfallId,
  direction,
  transfers,
  openModal,
}: ExternalTransferListProps) {
  const { showFeedback, createSuccessFeedback } = useFeedback();
  const { reportError } = useReportError();

  const isOutbound = direction === TransferDirection.Outbound;

  const items = useMemo<SortableListItem[]>(() => {
    if (!transfers) {
      return [];
    }

    return transfers?.map<SortableListItem>((transfer) => {
      const { heading, description } = getTransferDisplayValues(
        transfer,
        isOutbound
      );

      const transferValue = transfer.transferValue || new Decimal(0);
      let amount = formatCurrencyNoDecimals(transferValue);

      if (isOutbound) {
        amount = formatCurrencyNoDecimalsAccounting(transferValue.times(-1));
      }

      let amountSx = undefined;
      let transferDuration = undefined;

      const { startYear, endYear } = transfer;
      if (isOutbound) {
        amountSx = { color: COLORS.MATH.NEGATIVE };
      }
      if (startYear && endYear) {
        transferDuration = `${startYear}–${endYear}`;
      } else if (startYear) {
        transferDuration = startYear.toString();
      }

      return {
        id: transfer.id,
        heading,
        description,
        context: {
          heading: amount,
          headingSx: amountSx,
          subheading: transferDuration,
          action: <EditButton onClick={() => openModal(transfer.id)} />,
        },
      };
    });
  }, [isOutbound, openModal, transfers]);

  const [updateEstateWaterfall] =
    useCreateOrUpdateHypotheticalTransfersMutation({
      refetchQueries: [EstateWaterfallDocument],
      onCompleted: createSuccessFeedback('Hypothetical transfers updated'),
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        reportError('Error updating hypothetical transfers', error);
      },
    });

  const debouncedUpdateEstateWaterfall = useDebouncedFn(
    updateEstateWaterfall,
    500,
    {
      leading: false,
      trailing: true,
    }
  );

  const handleDrop = useCallback(
    (sortedList: SortableListItem[]) => {
      if (!sortedList.length) {
        return;
      }

      void debouncedUpdateEstateWaterfall({
        variables: {
          input: getUpdateHypotheticalTransfersOrderInput({
            waterfallId,
            transfers: sortedList,
          }),
        },
      });
    },
    [debouncedUpdateEstateWaterfall, waterfallId]
  );

  return (
    <Stack>
      <Stack p={1.5} direction="row" bgcolor={COLORS.GRAY[100]}>
        <Box>
          <Typography variant="h6" component="p" color={COLORS.GRAY[500]}>
            Transfers
          </Typography>
        </Box>
      </Stack>
      <Box
        sx={{
          borderBottom: `1px solid ${COLORS.GRAY[200]}`,
        }}
      >
        <DragAndDropList<SortableListItem>
          items={items}
          respondToLengthChanges={false}
          ItemInnerComponent={({ item, Draghandle }) => (
            <TableCell
              RightContent={
                item.context && <TableCellContext {...item.context} />
              }
              {...item}
              Draghandle={Draghandle}
            />
          )}
          droppableId={`external-transfers`}
          onDropped={handleDrop}
        />
      </Box>
    </Stack>
  );
}
