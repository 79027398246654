import { useEffect } from 'react';
import { UseFormReset } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { AugmentedUpdateSubBrandInput } from '@/types/schema';

import {
  SubBrandModal_SubBrandFragment,
  useGetSubBrandLazyQuery,
} from './graphql/SubBrandModal.generated';
import { SubBrandFormShape } from './SubBrandModal.constants';

export function getFormValuesFromNode(
  data: SubBrandModal_SubBrandFragment
): SubBrandFormShape {
  return {
    displayName: data.displayName,
  };
}

export function mapFormDataToUpdateInput(
  formValues: SubBrandFormShape,
  subBrandId: string
): AugmentedUpdateSubBrandInput {
  return {
    id: subBrandId,
    update: {
      displayName: formValues.displayName,
    },
  };
}

export function useSyncSubBrandDataToForm(
  reset: UseFormReset<SubBrandFormShape>,
  subBrandId: string
) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [getSubBrand, queryProps] = useGetSubBrandLazyQuery({
    variables: { subBrandId },
    onError: (err) => {
      showFeedback(
        "We weren't able to get the sub-brand information. Please refresh the page and try again."
      );
      reportError('SubBrandEditModal: Could not get SubBrand', err, {
        subBrandId,
      });
    },
  });

  useEffect(() => {
    if (!subBrandId) {
      return;
    }

    async function syncSubBrandDataToForm() {
      const { data } = await getSubBrand();
      if (data?.node?.__typename === 'SubBrand') {
        reset(getFormValuesFromNode(data.node));
      }
    }

    void syncSubBrandDataToForm();
  }, [reset, getSubBrand, subBrandId]);

  return queryProps;
}
