import { Box, Stack, Typography } from '@mui/material';
import { ComponentProps, useCallback } from 'react';

import { Divider } from '@/components/Divider';
import { Button } from '@/components/form/baseInputs/Button';
import { Grid } from '@/components/layout/Grid';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { Badge } from '@/components/notifications/Badge/Badge';
import { BadgeVariants } from '@/components/notifications/Badge/Badge';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Loader } from '@/components/progress/Loader/Loader';
import { useAddeparIntegrationErrorSearchParam } from '@/modules/assetProviderIntegrations/addepar/hooks/useAddeparIntegrationErrorSearchParam';
import {
  getAddeparIntegrationLoginPath,
  getAddeparIntegrationLogoutPath,
} from '@/modules/assetProviderIntegrations/addepar/utils/addeparAuthUtils';
import { useCanUserConnectToBlackDiamond } from '@/modules/assetProviderIntegrations/blackDiamond/hooks/useIsBlackDiamondEnabled';
import { useCanUserConnectToOrion } from '@/modules/assetProviderIntegrations/orion/hooks/useIsOrionEnabled';
import {
  getOrionIntegrationLoginPath,
  getOrionIntegrationLogoutPath,
} from '@/modules/assetProviderIntegrations/orion/utils/orionAuthUtils';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { diagnostics } from '@/utils/diagnostics';
import { getPublicImageUrl } from '@/utils/staticFiles';

import { useGetUserProfileSettingsQuery } from './graphql/UserProfileSettingsPage.generated';
import { ResyncClientsEntitiesButton } from './ResyncClientsEntitiesButton/ResyncClientsEntitiesButton';

interface IntegrationRowProps {
  description?: string;
  integrationEnabled: boolean;
  logo: React.ReactNode;
  buttonProps: Omit<ComponentProps<typeof Button>, 'size' | 'variant'>;
}

function IntegrationRow({
  description,
  integrationEnabled,
  logo,
  buttonProps,
}: IntegrationRowProps) {
  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {logo}
          {integrationEnabled && (
            <Badge variant={BadgeVariants.Yellow} display="Enabled" />
          )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            size="xs"
            rel="noreferrer"
            external
            target="_self"
            variant={integrationEnabled ? 'secondary' : 'primary'}
            {...buttonProps}
          />
          {integrationEnabled && <ResyncClientsEntitiesButton />}
        </Stack>
      </Stack>
      {description && (
        <Typography variant="subtitle2" component="div">
          {description}
        </Typography>
      )}
    </Box>
  );
}

export function UserProfileSettingsPage() {
  const { createErrorFeedback } = useFeedback();
  const canUserConnectToBD = useCanUserConnectToBlackDiamond();
  const canUserConnectToOrion = useCanUserConnectToOrion();
  const { data, loading: configLoading } = useGetUserProfileSettingsQuery({
    onError: (error) => {
      diagnostics.error('failed to get user profile settings', error);
      createErrorFeedback(
        'Failed to fetch user profile settings. Please refresh the page and try again.'
      )();
    },
  });

  useAddeparIntegrationErrorSearchParam();

  const userAccountLinkedToAddepar =
    data?.isUserLoggedInToAddepar.isLoggedIn ?? false;
  const addeparIntegrationConfigured = Boolean(
    data?.integrationConfiguration?.addeparFirmID ?? false
  );
  const getAddeparButtonProps = useCallback(() => {
    if (!addeparIntegrationConfigured) {
      // href doesn't matter because the button is disabled
      return { disabled: true, href: '#', children: 'Link account' };
    }

    const currentPageURL = getCompletePathFromRouteKey('USER_SETTINGS', {});
    if (userAccountLinkedToAddepar) {
      return {
        href: getAddeparIntegrationLogoutPath({ redirect: currentPageURL }),
        children: 'Unlink',
        method: 'post' as const,
      };
    } else {
      return {
        href: getAddeparIntegrationLoginPath({ redirect: currentPageURL }),
        children: 'Link account',
      };
    }
  }, [userAccountLinkedToAddepar, addeparIntegrationConfigured]);

  const userAccountLinkedToBlackDiamond =
    data?.isUserLoggedInToBlackDiamond.isLoggedIn ?? false;
  const getBlackDiamondButtonProps = useCallback(() => {
    if (!canUserConnectToBD) {
      return {
        disabled: true,
        href: '#',
        children: 'Link account',
      };
    }

    const currentPageURL = getCompletePathFromRouteKey('USER_SETTINGS', {});
    if (userAccountLinkedToBlackDiamond) {
      return {
        href: `/api/v1/blackdiamond/auth/logout?redirect=${currentPageURL}`,
        children: 'Unlink',
        method: 'post' as const,
      };
    } else {
      return {
        href: `/api/v1/blackdiamond/auth/initiate?redirect=${currentPageURL}`,
        children: 'Link account',
      };
    }
  }, [canUserConnectToBD, userAccountLinkedToBlackDiamond]);

  const userAccountLinkedToOrion =
    data?.isUserLoggedInToOrion?.isLoggedIn ?? false;

  const getOrionButtonProps = useCallback(() => {
    if (!canUserConnectToOrion) {
      return {
        disabled: true,
        href: '#',
        children: 'Link account',
      };
    }

    const currentPageURL = getCompletePathFromRouteKey('USER_SETTINGS', {});
    if (userAccountLinkedToOrion) {
      return {
        href: getOrionIntegrationLogoutPath({ redirect: currentPageURL }),
        children: 'Unlink',
        method: 'post' as const,
      };
    } else {
      return {
        href: getOrionIntegrationLoginPath({ redirect: currentPageURL }),
        children: 'Link account',
      };
    }
  }, [canUserConnectToOrion, userAccountLinkedToOrion]);

  return (
    <>
      {configLoading ? (
        <Loader
          boxProps={{
            sx: {
              textAlign: 'center',
              my: 3,
            },
          }}
        />
      ) : (
        <Grid container>
          <Grid item sm={4}>
            <Box>
              <HeaderCard
                heading="Integrations"
                subheading="Enable data integrations by connecting Luminary to external services"
              >
                <Stack spacing={2} divider={<Divider />} mt={3} mb={2}>
                  <IntegrationRow
                    integrationEnabled={userAccountLinkedToAddepar}
                    description={
                      !addeparIntegrationConfigured
                        ? 'Contact your administrator'
                        : undefined
                    }
                    logo={
                      <img
                        alt="Addepar"
                        height={24}
                        src={getPublicImageUrl('/addeparLogo.svg')}
                      />
                    }
                    buttonProps={getAddeparButtonProps()}
                  />
                  <IntegrationRow
                    integrationEnabled={userAccountLinkedToBlackDiamond}
                    logo={
                      <img
                        alt="Black Diamond"
                        height={24}
                        src={getPublicImageUrl('/blackDiamondLogo.png')}
                      />
                    }
                    buttonProps={getBlackDiamondButtonProps()}
                  />
                  <IntegrationRow
                    integrationEnabled={userAccountLinkedToOrion}
                    description={
                      !canUserConnectToOrion
                        ? 'Contact your administrator'
                        : undefined
                    }
                    logo={
                      <img
                        alt="Orion"
                        height={24}
                        src={getPublicImageUrl('/orionLogo.jpg')}
                      />
                    }
                    buttonProps={getOrionButtonProps()}
                  />
                </Stack>
              </HeaderCard>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
