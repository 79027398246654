import {
  ConfirmationModal,
  ConfirmationModalProps,
} from '@/components/modals/ConfirmationModal/ConfirmationModal';

export function ConfirmSectionSwitchModal({
  isOpen,
  onClose,
}: Pick<ConfirmationModalProps, 'isOpen' | 'onClose'>) {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      heading="Do you wish to save your changes before proceeding?"
      cancelText="Cancel"
      cancelButtonProps={{
        'data-testid': 'button-cancelSectionSwitch',
      }}
      confirmText="Save"
      confirmButtonProps={{
        variant: 'primary',
        'data-testid': 'button-confirmSectionSwitch',
      }}
      onClose={onClose}
    />
  );
}

export function ConfirmAISuggestionsModal({
  isOpen,
  onClose,
}: Pick<ConfirmationModalProps, 'isOpen' | 'onClose'>) {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      heading="You have pending changes that will not be saved"
      cancelText="Ignore & close"
      confirmText="Update form"
      confirmButtonProps={{ variant: 'primary' }}
      onClose={onClose}
    >
      Update the form with AI suggestions you&#39;ve reviewed or close without
      saving
    </ConfirmationModal>
  );
}

export function ConfirmDiscardChangesModal({
  isOpen,
  onClose,
}: Pick<ConfirmationModalProps, 'isOpen' | 'onClose'>) {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      heading="Are you sure you wish to discard your changes?"
      cancelText="Go back"
      confirmText="Proceed"
      confirmButtonProps={{ variant: 'primary' }}
      onClose={onClose}
    />
  );
}

export function ConfirmResolveDuplicatesModal({
  isOpen,
  onClose,
}: Pick<ConfirmationModalProps, 'isOpen' | 'onClose'>) {
  return (
    <ConfirmationModal
      isOpen={isOpen}
      heading="You have unresolved duplicates."
      confirmText="Return to form"
      onClose={onClose}
      confirmationOnly
    >
      Resolve any duplicates before saving
    </ConfirmationModal>
  );
}
