import { first } from 'lodash';
import { useMemo } from 'react';

import { getNodes } from '@/utils/graphqlUtils';

import { useQueryEstateWaterfallModalQuery } from '../graphql/EstateWaterfallModal.generated';

export function useWaterfallModalQuery({
  householdId,
  waterfallId,
}: {
  householdId: string;
  waterfallId?: string;
}) {
  const variables = useMemo(
    () => ({ where: { id: householdId } }),
    [householdId]
  );

  const { data, ...rest } = useQueryEstateWaterfallModalQuery({
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const household = first(getNodes(data?.households));

  const waterfallIds = (household?.estateWaterfalls || []).map(({ id }) => id);
  const numWaterfalls = waterfallIds.length;
  const currentWaterfall = household?.estateWaterfalls?.find(
    ({ id }) => id === waterfallId
  );
  const entities = getNodes(household?.entities);
  const hypotheticalTransfers = getNodes(
    currentWaterfall?.hypotheticalTransfers
  );

  return {
    household,
    waterfallIds,
    currentWaterfall,
    numWaterfalls,
    entities,
    hypotheticalTransfers,
    data,
    ...rest,
    loading: !data,
  };
}
