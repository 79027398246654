import { DefaultEstateTaxStatus } from '@/modules/entities/testamentaryEntities/TestamentaryEntityForm/TestamentaryEntityForm.types';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  DispositiveProvisionTransferTaxKind,
  TestamentaryEntityKind,
} from '@/types/schema';

import {
  DispositiveProvisionsPossibleRecipients_ClientOrganizationFragment,
  DispositiveProvisionsPossibleRecipients_ClientProfileFragment,
  DispositiveProvisionsPossibleRecipients_EntityFragment,
  DispositiveProvisionsPossibleRecipients_TestamentaryEntityFragment,
} from './graphql/DispositiveProvisionsFormRecipients.generated';

export enum DispositiveProvisionsRecipientTypes {
  Individual = 'individual',
  Entity = 'entity',
  Organization = 'organization',
  TestamentaryEntity = 'testamentaryEntity',
}

export interface DispositiveProvisionsRecipientTemplateRecipientConfiguration {
  allowsToSurvivingSpouse?: boolean;
  allowsIndividualRecipient: boolean;
  allowsOrganizationRecipient: boolean;
  allowsEntityRecipient: boolean;
  allowsTestamentaryEntityRecipient: boolean;
  customEntityFilter?: (
    entity: DispositiveProvisionsPossibleRecipients_EntityFragment
  ) => boolean;
  customOrganizationFilter?: (
    organization: DispositiveProvisionsPossibleRecipients_ClientOrganizationFragment
  ) => boolean;
  customTestamentaryEntityFilter?: (
    testamentaryEntity: DispositiveProvisionsPossibleRecipients_TestamentaryEntityFragment
  ) => boolean;
  customIndividualFilter?: (
    clientProfile: DispositiveProvisionsPossibleRecipients_ClientProfileFragment
  ) => boolean;
  createRecipientTypes: DispositiveProvisionsRecipientTypes[];
  createEntityType?: EntityType;
  forceEntityType?: boolean;
  forceDefaultRecipient?: boolean;
  includeSpouseFederalCreditShelterStateMaritalExclusion?: boolean;
  customTransferTaxKind?: DispositiveProvisionTransferTaxKind;
  displayHeaderAsInExcessOfLifetimeExclusion?: boolean;
  testamentaryEntityTaxSettings?: DefaultEstateTaxStatus;
  defaultNewTestamentaryEntityName?: string;
  defaultNewTestamentaryEntityKind?: TestamentaryEntityKind;
  defaultNewEntityName?: string;
}

export interface DispositiveProvisionsRecipientTemplateRules {
  templateRequiresRecipient: boolean;
  templateRequiresSpecificPayout: boolean;
  templateRequiresSpecificTaxKind: boolean;
  alwaysShowNotes: boolean;
  recipientConfiguration: DispositiveProvisionsRecipientTemplateRecipientConfiguration;
}
