import { css, cx } from '@emotion/css';
import { useReactFlow } from '@xyflow/react';

import { Node } from '../types';
import { isTileGroupNode } from '../utils/nodes';
import { useIsInteractive } from './useIsInteractive';

const styles = {
  default: css({
    cursor: 'default',
  }),
  clickable: css({
    // specifically don't do :hover which would override other cursor styles
    cursor: 'pointer',
  }),
  interactive: css({
    cursor: 'grab',
  }),
  dragging: css({
    cursor: 'grabbing',
  }),
};

export function useCursorStyles(id: string) {
  const { getNode } = useReactFlow();
  const { isInteractive } = useIsInteractive();

  const node = getNode(id) as Node;
  const dragging = !!node?.dragging;

  const cursorClassName = cx(
    styles.default,
    // grouped tile nodes aren't clickable for primary actions, only their children within (but they can be dragged etc.)
    !isTileGroupNode(node) && node.selectable !== false && styles.clickable,
    isInteractive && styles.interactive,
    dragging && styles.dragging
  );

  return { isInteractive, dragging, cursorClassName, styles };
}
