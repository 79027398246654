import {
  array,
  boolean as booleanType,
  date,
  Describe,
  nullable,
  number,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateCltTrustInput,
  AugmentedCreateEntityInput,
  AugmentedUpdateCltTrustInput,
  AugmentedUpdateEntityInput,
  CreateCltTrustInput,
  UpdateCltTrustInput,
} from '@/types/schema';

import {
  createInsurancePolicyInput,
  updateInsurancePolicyInput,
} from './insuranceTypes';
import {
  beneficiarySchema,
  commonUpdateEntityProperties,
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  decimal,
  grantorSchema,
  gstStatusSchema,
  inEstateStatusSchema,
  stageSchema,
  taxStatusSchema,
  trustAdvisorSchema,
  trusteeSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const cltCreateUpdateProperties = {
  // share trust properties
  legalName: optional(nullable(string())),
  trustJurisdictionStateCode: optional(nullable(string())),
  effectiveDate: optional(nullable(date())),
  description: optional(nullable(string())),
  taxStatus: taxStatusSchema,
  inEstateStatus: inEstateStatusSchema,
  gstStatus: gstStatusSchema,

  // CLT-specific properties
  termDurationYears: optional(nullable(number())),
  officialInterestRatePercent: optional(nullable(decimal)),
  charitableDeduction: optional(nullable(decimal)),
  termDurationLifetime: optional(nullable(booleanType())),
};

const createCltTrustInput: Describe<CreateCltTrustInput> = object({
  displayName: string(),
  ...cltCreateUpdateProperties,
});

const createCltTrustSchema: Describe<AugmentedCreateCltTrustInput> = object({
  create: createCltTrustInput,
  withLifetimeBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withRemainderBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withTrustees: optional(nullable(array(trusteeSchema))),
  withSuccessorTrustees: optional(nullable(array(trusteeSchema))),
  withTrustAdvisors: optional(nullable(array(trustAdvisorSchema))),
  withGrantors: optional(nullable(array(grantorSchema))),
  withDesignerAccount: optional(nullable(createAccountSchema)),
});

const updateCltTrustInput: Describe<UpdateCltTrustInput> = object({
  displayName: optional(nullable(string())),
  ...cltCreateUpdateProperties,
  clearEffectiveDate: optional(nullable(booleanType())),
  clearLegalName: optional(nullable(booleanType())),
  clearTrustJurisdictionStateCode: optional(nullable(booleanType())),
  clearLifetimeBeneficiaries: optional(nullable(booleanType())),
  clearRemainderBeneficiaries: optional(nullable(booleanType())),
  clearGrantors: optional(nullable(booleanType())),
  clearSuccessorTrustees: optional(nullable(booleanType())),
  clearTrustAdvisors: optional(nullable(booleanType())),
  clearDescription: optional(nullable(booleanType())),
  clearTrustees: optional(nullable(booleanType())),
  clearGstStatus: optional(nullable(booleanType())),
  clearTaxStatus: optional(nullable(booleanType())),
  removePolicyIDs: optional(nullable(array(string()))),
});

const updateCltTrustSchema: Describe<AugmentedUpdateCltTrustInput> = object({
  id: string(),
  update: updateCltTrustInput,
  withLifetimeBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withRemainderBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withTrustees: optional(nullable(array(trusteeSchema))),
  withSuccessorTrustees: optional(nullable(array(trusteeSchema))),
  withTrustAdvisors: optional(nullable(array(trustAdvisorSchema))),
  withGrantors: optional(nullable(array(grantorSchema))),
  updateDesignerAccount: optional(nullable(updateAccountSchema)),
  withPolicies: optional(nullable(array(createInsurancePolicyInput))),
  updatePolicies: optional(nullable(array(updateInsurancePolicyInput))),
});

const createSchema: Describe<AugmentedCreateEntityInput> = object({
  create: createEntityInput,
  withCltTrust: optional(nullable(createCltTrustSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object({
    ...commonUpdateEntityProperties,
    stage: optional(nullable(stageSchema)),
  }),
  updateCltTrust: optional(nullable(updateCltTrustSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export function validateCltTrustCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'CLT create');
}

export function validateCltTrustUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'CLT update');
}
