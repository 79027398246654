import { IntegrationConfigurationAddeparClientType } from '@/types/schema';

export const ADDEPAR_IMPORT_TYPE_DISPLAY: Record<
  IntegrationConfigurationAddeparClientType,
  string
> = {
  [IntegrationConfigurationAddeparClientType.All]:
    'All (clients, groups, and households)',
  [IntegrationConfigurationAddeparClientType.Client]: 'Clients',
  [IntegrationConfigurationAddeparClientType.Group]: 'Groups',
  [IntegrationConfigurationAddeparClientType.Household]: 'Households',
};
