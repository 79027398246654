// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateTenantMutationVariables = Types.Exact<{
  input: Types.CreateTenantInput;
}>;


export type CreateTenantMutation = { __typename?: 'Mutation', createTenant?: string | null };

export type UpdateTenantMutationVariables = Types.Exact<{
  tenantInput: Types.AugmentedUpdateTenantInput;
  brandingId: Types.Scalars['ID']['input'];
  brandingInput: Types.UpdateTenantBrandingInput;
}>;


export type UpdateTenantMutation = { __typename?: 'Mutation', updateTenant: { __typename?: 'Tenant', id: string, usageBucket: Types.TenantUsageBucket, kind: Types.TenantKind }, updateTenantBranding: { __typename?: 'TenantBranding', id: string, displayName: string } };

export type TenantSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TenantSettingsQuery = { __typename?: 'Query', tenantInformation: { __typename?: 'TenantInformation', id: string, contractStartDate?: Time | null, kind: Types.TenantKind, name: string, usageBucket: Types.TenantUsageBucket, branding: { __typename?: 'TenantBranding', id: string } } };


export const CreateTenantDocument = gql`
    mutation CreateTenant($input: CreateTenantInput!) {
  createTenant(input: $input)
}
    `;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTenantMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
      }
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<CreateTenantMutation, CreateTenantMutationVariables>;
export const UpdateTenantDocument = gql`
    mutation UpdateTenant($tenantInput: AugmentedUpdateTenantInput!, $brandingId: ID!, $brandingInput: UpdateTenantBrandingInput!) {
  updateTenant(input: $tenantInput) {
    id
    usageBucket
    kind
  }
  updateTenantBranding(id: $brandingId, input: $brandingInput) {
    id
    displayName
  }
}
    `;
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      tenantInput: // value for 'tenantInput'
 *      brandingId: // value for 'brandingId'
 *      brandingInput: // value for 'brandingInput'
 *   },
 * });
 */
export function useUpdateTenantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, options);
      }
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<UpdateTenantMutation, UpdateTenantMutationVariables>;
export const TenantSettingsDocument = gql`
    query TenantSettings {
  tenantInformation {
    id
    contractStartDate
    kind
    name
    usageBucket
    branding {
      id
    }
  }
}
    `;

/**
 * __useTenantSettingsQuery__
 *
 * To run a query within a React component, call `useTenantSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantSettingsQuery(baseOptions?: Apollo.QueryHookOptions<TenantSettingsQuery, TenantSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantSettingsQuery, TenantSettingsQueryVariables>(TenantSettingsDocument, options);
      }
export function useTenantSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantSettingsQuery, TenantSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantSettingsQuery, TenantSettingsQueryVariables>(TenantSettingsDocument, options);
        }
export function useTenantSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TenantSettingsQuery, TenantSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TenantSettingsQuery, TenantSettingsQueryVariables>(TenantSettingsDocument, options);
        }
export type TenantSettingsQueryHookResult = ReturnType<typeof useTenantSettingsQuery>;
export type TenantSettingsLazyQueryHookResult = ReturnType<typeof useTenantSettingsLazyQuery>;
export type TenantSettingsSuspenseQueryHookResult = ReturnType<typeof useTenantSettingsSuspenseQuery>;
export type TenantSettingsQueryResult = Apollo.QueryResult<TenantSettingsQuery, TenantSettingsQueryVariables>;
export const Operations = {
  Query: {
    TenantSettings: 'TenantSettings' as const
  },
  Mutation: {
    CreateTenant: 'CreateTenant' as const,
    UpdateTenant: 'UpdateTenant' as const
  }
}