import { Box, Stack, Typography } from '@mui/material';
import { RefObject, useEffect, useRef } from 'react';
import ContentEditable from 'react-contenteditable';

import { EditButton } from '@/components/form/baseInputs/Button/EditButton';

import { selectElementContents } from './EditableHeader.utils';

export interface EditableHeaderProps {
  value: string;
  onChange: (newValue: string) => void;
  disabled?: boolean;
  testId?: string;
}

type ContentEditableRefType = ((instance: ContentEditable | null) => void) &
  RefObject<HTMLDivElement>;

export function EditableHeader({
  value,
  onChange,
  disabled,
  testId = 'EditableHeader-input',
}: EditableHeaderProps) {
  const editableContent = useRef<string>(value ?? '');
  const contentEditableRef = useRef<ContentEditable>(null);

  useEffect(() => {
    editableContent.current = value;
  }, [value]);

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="h1">
        <ContentEditable
          ref={contentEditableRef as ContentEditableRefType}
          role="textbox"
          html={editableContent.current}
          title="Click to edit"
          onChange={(e) => (editableContent.current = e.target.value)}
          tagName="span"
          data-testid={testId}
          onBlur={() => {
            if (editableContent.current !== value) {
              onChange(editableContent.current);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.currentTarget.blur();
            }
          }}
          style={{ padding: '4px' }}
          disabled={disabled}
        />
      </Typography>
      <Box>
        <EditButton
          disabled={disabled}
          size="sm"
          onClick={() => {
            // the `el` in this component is typed as `any`, so need to access it very carefully
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            contentEditableRef.current?.el?.current &&
              selectElementContents(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                contentEditableRef.current.el.current as HTMLDivElement
              );
          }}
        />
      </Box>
    </Stack>
  );
}
