import { GridRowParams } from '@mui/x-data-grid-pro';
import { compact, isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useChartColorDefinitions } from '@/components/charts/constants';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTableProps } from '@/components/tables/DataTable/types';
import { ControlledFilterableTable } from '@/components/tables/FilterableTable/ControlledFilterableTable';
import { getGuardedNodeType } from '@/graphql/types';
import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { AIOnboardingBanner } from '@/modules/aiOnboarding/components/AIOnboardingBanner';
import { AsyncJobStatus_AsyncJobFragment } from '@/modules/asyncJobs/graphql/AsyncJobs.generated';
import { AddEntity } from '@/modules/entities/components/AddEntity';
import { FilterOptions } from '@/modules/entities/entities.constants';
import { TESTAMENTARY_ENTITY_SEARCH_PARAM } from '@/modules/entities/testamentaryEntities/testamentaryEntities.utils';
import { TestamentaryEntityModal } from '@/modules/entities/testamentaryEntities/TestamentaryEntityForm/TestamentaryEntityModal';
import { getEntityNavigationPath } from '@/modules/entities/utils/entitiesNavigationUtils';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { HouseholdEstateValueMetrics } from '@/modules/household/HouseholdEstateValueMetrics/HouseholdEstateValueMetrics';
import { ROUTE_KEYS } from '@/navigation/constants';
import { EntityStage } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { EntitiesIngestBanner } from './EntitiesIngestBanner';
import {
  ClientDetailsEntitiesListPage_HouseholdFragment,
  ClientDetailsEntitiesListPage_HouseholdMetricsFragment,
  ListClientEntitiesQuery,
  useListClientEntitiesQuery,
} from './graphql/ListClientEntities.generated';
import { ClientDetailsEntityListRow, mapEntitiesToRows } from './rowUtils';
import { getColumns, TESTAMENTARY_ENTITY_TABS } from './tabColumnUtils';

interface EditTestamentaryDetails {
  testamentaryEntityId: string;
  dyingPrimaryClientId: string;
}

export type ClientDetailsEntitiesListRows = ReturnType<
  typeof mapEntitiesToRows
>;

interface ClientDetailsEntitiesListPageInnerProps {
  rows: ClientDetailsEntitiesListRows;
  loading: boolean;
  householdId: string;
  ingestJobs: AsyncJobStatus_AsyncJobFragment[];
  metrics: ClientDetailsEntitiesListPage_HouseholdMetricsFragment | null;
  refetch: () => void;
}

function ClientDetailsEntitiesListPageInner({
  rows,
  loading,
  householdId,
  ingestJobs,
  refetch,
  metrics,
}: ClientDetailsEntitiesListPageInnerProps) {
  const navigate = useNavigate();
  const { primaryClients } = useHouseholdDetailsContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const [editTestamentaryDetails, setEditTestamentaryDetails] =
    useState<EditTestamentaryDetails | null>(() => {
      const testamentaryEntityId = searchParams.get(
        TESTAMENTARY_ENTITY_SEARCH_PARAM
      );
      if (testamentaryEntityId) {
        return { testamentaryEntityId, dyingPrimaryClientId: '' };
      }
      return null;
    });

  const columns = useMemo(() => getColumns(), []);

  const onRowClick: DataTableProps['onRowClick'] = (
    params: GridRowParams<ClientDetailsEntityListRow>,
    event
  ) => {
    const entityId = params.id;

    // In these cases, we open a modal
    if (params.row.status === FilterOptions.TESTAMENTARY) {
      const nextSearchParams = new URLSearchParams(searchParams);
      nextSearchParams.set(TESTAMENTARY_ENTITY_SEARCH_PARAM, params.row.id);
      setSearchParams(nextSearchParams, { replace: true });
      setEditTestamentaryDetails({
        testamentaryEntityId: params.row.id,
        dyingPrimaryClientId: params.row.dyingGrantorId,
      });
      return;
    }

    // Otherwise, we'll navigate to a page
    const path = getEntityNavigationPath(
      householdId,
      entityId.toString(),
      getEntityTypeFromEntityKind(params.row.entityKind),
      params.row.entityStage
    );

    if (event.metaKey) {
      // if the user cmd-clicks the row, open it in a new window
      window.open(path, '_blank');
      return;
    }

    navigate(path, {
      state: { from: ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST },
    });
  };

  return (
    <>
      <ControlledFilterableTable
        tabs={TESTAMENTARY_ENTITY_TABS}
        rows={rows}
        columns={columns}
        filterField="status"
        title=""
        emptyStateText="No entities"
        emptyStateContent={<AddEntity householdId={householdId} />}
        loading={loading}
        onRowClick={onRowClick}
        belowHeaderContent={
          <>
            <AIOnboardingBanner householdID={householdId} variant="entities" />
            <HouseholdEstateValueMetrics metrics={metrics} />
          </>
        }
        belowTabContentRenderer={() => (
          <EntitiesIngestBanner onComplete={refetch} activeJobs={ingestJobs} />
        )}
        pageSize={PageSizes.Fifty}
      />
      {editTestamentaryDetails && (
        <TestamentaryEntityModal
          isOpen={!!editTestamentaryDetails}
          onClose={() => {
            setEditTestamentaryDetails(null);
            const nextSearchParams = new URLSearchParams(searchParams);
            nextSearchParams.delete(TESTAMENTARY_ENTITY_SEARCH_PARAM);
            setSearchParams(nextSearchParams, { replace: true });
          }}
          testamentaryEntityId={editTestamentaryDetails.testamentaryEntityId}
          dyingPrimaryClientId={editTestamentaryDetails.dyingPrimaryClientId}
          deadClientIds={[]}
          isTwoClientHousehold={(primaryClients ?? []).length === 2}
        />
      )}
    </>
  );
}

export function ClientDetailsEntitiesListPage() {
  const householdId = useRequiredParam('householdId');
  const { primaryClients } = useHouseholdDetailsContext();
  const colors = useChartColorDefinitions();

  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const {
    loading: loadingEntities,
    data,
    refetch,
  } = useListClientEntitiesQuery({
    skip: isEmpty(primaryClients) || !householdId,
    notifyOnNetworkStatusChange: true, // notifyOnNetworkStatusChange is required to rerun onComplete for each poll
    variables: {
      householdId,
      entitiesLike: {
        stageIn: compact([
          EntityStage.Active,
          EntityStage.Completed,
          EntityStage.Draft, // includes draft GRATs, and hypothetical entities
          EntityStage.Implementation, // should be GRATs only
          EntityStage.ReadyForProposal, // should be GRATs only
        ]),
        hasHouseholdWith: [
          {
            id: householdId,
          },
        ],
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      reportError('failed to load data for entities page', err, {
        householdId,
      });
      showFeedback(
        "We weren't able to load your entities. Please refresh the page and try again."
      );
    },
  });
  const loading = loadingEntities || !primaryClients;
  const entityListData = getNodes(data?.entities);
  const testamentaryListData = getNodes(data?.testamentaryEntities);
  const assetsIngestJobsOnEntities = getNodes(data?.entities).flatMap((e) => {
    return getNodes(e.assetsIngestJobs);
  });

  const rows = useMemo<ClientDetailsEntitiesListRows>(() => {
    if (isEmpty(entityListData) && isEmpty(testamentaryListData)) {
      return [];
    }

    let rows: ClientDetailsEntitiesListRows = [];
    try {
      rows = mapEntitiesToRows({
        entities: entityListData,
        testamentaries: testamentaryListData,
        householdId,
        colors,
        refetch,
      });
    } catch (err) {
      showFeedback('An error occurred. Please refresh the page.');
      diagnostics.error(
        'failed to parse data for entities page',
        err as Error,
        {
          householdId,
        }
      );
    }

    return rows;
  }, [
    entityListData,
    testamentaryListData,
    colors,
    refetch,
    showFeedback,
    householdId,
  ]);

  const client = getGuardedNodeType<
    ListClientEntitiesQuery['client'],
    ClientDetailsEntitiesListPage_HouseholdFragment
  >(data?.client, 'Household');

  return (
    <ClientDetailsEntitiesListPageInner
      rows={rows}
      metrics={client?.estateValueMetrics ?? null}
      refetch={refetch}
      ingestJobs={assetsIngestJobsOnEntities}
      // only show the loading indicator on the first load
      loading={loading && rows.length === 0}
      householdId={householdId}
    />
  );
}
