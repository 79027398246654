import { useEffect, useState } from 'react';

import {
  PENDING_ASYNC_JOB_STATUSES,
  TERMINAL_ASYNC_JOB_STATUSES,
} from '@/modules/asyncJobs/graphql/asyncJobs.constants';
import { usePollForJobCompletion } from '@/modules/asyncJobs/hooks/usePollForJobCompletion';
import { AsyncJobKind, AsyncJobStatus } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { useInitialIntegrationSyncQuery } from '../graphql/IntegrationClientTypeahead.generated';

export enum InitialSyncStatus {
  NONE = 'NONE', // initial sync job has been completed previously; do nothing
  PENDING = 'PENDING', // initial sync job is in progress
  COMPLETE = 'COMPLETE', // initial sync job has been completed in this session
}

export interface UsePollForInitialAssetIntegrationSyncOpts {
  onJobComplete: () => void;
}

export function usePollForInitialAssetIntegrationSync(
  opts?: UsePollForInitialAssetIntegrationSyncOpts
) {
  const [initialSyncStatus, setInitialSyncStatus] = useState<InitialSyncStatus>(
    InitialSyncStatus.NONE
  );
  const { data } = useInitialIntegrationSyncQuery({
    variables: {
      pendingJobsLike: {
        kind: AsyncJobKind.ClientAndEntityFetch,
        statusIn: PENDING_ASYNC_JOB_STATUSES,
      },
      completedJobsLike: {
        kind: AsyncJobKind.ClientAndEntityFetch,
        statusIn: [AsyncJobStatus.Completed],
      },
    },
    onError: (err) => {
      diagnostics.error('Failed to query for async job status', err);
    },
  });

  const pendingAsyncJobs = getNodes(data?.currentUser?.pendingJobs);
  const pendingAsyncJob = pendingAsyncJobs[0];
  const completedAsyncJobs = getNodes(data?.currentUser?.completedJobs);
  const completedAsyncJob = completedAsyncJobs[0];

  const jobCompletionDetails = usePollForJobCompletion(
    pendingAsyncJob,
    opts ?? {
      skip: Boolean(completedAsyncJob),
    }
  );
  const { mostRecentJobStatus } = jobCompletionDetails;

  useEffect(() => {
    // if there's already been a completed sync job, we don't need to
    // show a loading state or poll for anything
    if (!mostRecentJobStatus || completedAsyncJob) {
      return;
    }

    if (PENDING_ASYNC_JOB_STATUSES.includes(mostRecentJobStatus)) {
      setInitialSyncStatus(InitialSyncStatus.PENDING);
    }

    if (TERMINAL_ASYNC_JOB_STATUSES.includes(mostRecentJobStatus)) {
      setInitialSyncStatus(InitialSyncStatus.COMPLETE);
    }
  }, [completedAsyncJob, mostRecentJobStatus, setInitialSyncStatus]);

  return { initialSyncStatus, ...jobCompletionDetails };
}
