import { Stack, Typography } from '@mui/material';
import { useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { CardVariant } from '@/components/layout/Card/Card';
import { DocumentRepresentationList } from '@/modules/files/DocumentRepresentation/DocumentRepresentationList';
import { DocumentWhereInput } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { isDocumentId } from '@/utils/pulid';

import { RichDocumentUploader } from '../RichDocumentUploader/RichDocumentUploader';

export interface RichDocumentUploaderWithListProps {
  listDocumentsLike?: DocumentWhereInput;
  documentIds?: string[];
  householdId: string;
  entityId?: string;
  hideEntityPicker?: boolean;
  onDocumentUploadSuccess?: (documentId: string) => void;
  variant?: 'default' | 'card';
  cardVariant?: CardVariant;
}

export function RichDocumentUploaderWithList({
  listDocumentsLike,
  documentIds,
  householdId,
  entityId,
  hideEntityPicker,
  onDocumentUploadSuccess,
  variant = 'default',
  cardVariant,
}: RichDocumentUploaderWithListProps) {
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      {isModalOpen && (
        <RichDocumentUploader
          variant={variant}
          cardVariant={cardVariant}
          isOpen={isModalOpen}
          entityId={entityId}
          hideEntityPicker={hideEntityPicker}
          documentId={null}
          onClose={(documentId: string | undefined) => {
            if (documentId) {
              if (!isDocumentId(documentId)) {
                diagnostics.warn(
                  'Passed a non-document ID to onDocumentUploadSuccess',
                  { documentId }
                );
              }

              onDocumentUploadSuccess?.(documentId);
            }

            setModalOpen(false);
          }}
          householdId={householdId}
        />
      )}
      <Stack spacing={2}>
        {variant === 'default' && (
          <Stack>
            <Typography variant="label">Document upload</Typography>
            <Typography variant="subtitle2">
              Please upload the signed account documents and other relevant
              supporting documentation such as trusts and appraisals.
            </Typography>
          </Stack>
        )}
        <DocumentRepresentationList
          showDelete={true}
          documentsLike={listDocumentsLike}
          documentIds={documentIds}
        />
        <Button
          fullWidth
          variant="secondary"
          size="sm"
          startIcon={PlusIcon}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Add a document
        </Button>
      </Stack>
    </>
  );
}
