// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { ClientProfileFragmentDoc, ClientProfileMinimalGrantorRelationshipFragmentDoc, RelationshipFragmentDoc, ClientProfileBasicFragmentDoc } from '../../../clientProfiles/graphql/ClientProfile.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAdvisorClientMutationVariables = Types.Exact<{
  updateHouseholdId: Types.Scalars['ID']['input'];
  input: Types.UpdateHouseholdInput;
}>;


export type UpdateAdvisorClientMutation = { __typename?: 'Mutation', updateHousehold: { __typename?: 'Household', id: string, primaryRelationshipOwner: { __typename?: 'User', id: string }, clientProfiles?: Array<{ __typename?: 'ClientProfile', id: string, firstName: string, lastName: string, email?: string | null, dateOfBirth?: Time | null, isGrantor: boolean, isTrustee: boolean, isPrimary: boolean, isBeneficiary: boolean, displayName: string, legalName: string, middleName?: string | null, suffix?: string | null, address?: { __typename?: 'Address', id: string, cityName?: string | null, stateCode: string } | null }> | null } };


export const UpdateAdvisorClientDocument = gql`
    mutation UpdateAdvisorClient($updateHouseholdId: ID!, $input: UpdateHouseholdInput!) {
  updateHousehold(id: $updateHouseholdId, input: $input) {
    id
    primaryRelationshipOwner {
      id
    }
    clientProfiles {
      ...ClientProfile
    }
  }
}
    ${ClientProfileFragmentDoc}`;
export type UpdateAdvisorClientMutationFn = Apollo.MutationFunction<UpdateAdvisorClientMutation, UpdateAdvisorClientMutationVariables>;

/**
 * __useUpdateAdvisorClientMutation__
 *
 * To run a mutation, you first call `useUpdateAdvisorClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdvisorClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvisorClientMutation, { data, loading, error }] = useUpdateAdvisorClientMutation({
 *   variables: {
 *      updateHouseholdId: // value for 'updateHouseholdId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdvisorClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdvisorClientMutation, UpdateAdvisorClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdvisorClientMutation, UpdateAdvisorClientMutationVariables>(UpdateAdvisorClientDocument, options);
      }
export type UpdateAdvisorClientMutationHookResult = ReturnType<typeof useUpdateAdvisorClientMutation>;
export type UpdateAdvisorClientMutationResult = Apollo.MutationResult<UpdateAdvisorClientMutation>;
export type UpdateAdvisorClientMutationOptions = Apollo.BaseMutationOptions<UpdateAdvisorClientMutation, UpdateAdvisorClientMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateAdvisorClient: 'UpdateAdvisorClient' as const
  }
}