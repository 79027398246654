import Decimal from 'decimal.js';
import { groupBy, orderBy } from 'lodash';
import { ComponentProps, useMemo } from 'react';

import { useChartColorDefinitions } from '@/components/charts/constants';
import { StackedHorizontalBarTooltip } from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import { StackedVerticalBar } from '@/components/charts/StackedVerticalBar/StackedVerticalBar';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';
import { maxDecimalJS } from '@/utils/decimalJSUtils';

import { useBeneficiaryReportingContext } from '../../beneficiaryReporting.context';
import {
  BeneficiaryReport,
  ClientOrOrgId,
} from '../../beneficiaryReporting.types';
import { getAccessValueForDeathEvent } from '../../beneficiaryReporting.utils';

interface PrimaryBeneficiaryBar {
  id: string;
  total: Decimal;
  label: string;
  sections: ComponentProps<typeof StackedVerticalBar>['sections'];
}

export function usePrimaryBeneficiaryBars(): PrimaryBeneficiaryBar[] {
  const chartColorDefinitions = useChartColorDefinitions();
  const { possibleBeneficiaries } = useHouseholdDetailsContext();
  const { beneficiariesData, secondGrantor } = useBeneficiaryReportingContext();

  const beneficiaryDataByAfterDeath = useMemo(() => {
    return Object.entries(beneficiariesData) as [
      AfterDeath,
      Record<ClientOrOrgId, BeneficiaryReport>,
    ][];
  }, [beneficiariesData]);

  const beneficiaryDataByBeneficiaryId = useMemo(() => {
    return groupBy(
      beneficiaryDataByAfterDeath.flatMap(([afterDeath, beneficiaryData]) => {
        return Object.entries(beneficiaryData).map(
          ([beneficiaryId, beneficiaryReport]) => ({
            beneficiaryId,
            beneficiaryReport,
            afterDeath,
          })
        );
      }),
      'beneficiaryId'
    );
  }, [beneficiaryDataByAfterDeath]);

  const primaryBeneficiaryDataWithValues = useMemo(() => {
    return Object.entries(beneficiaryDataByBeneficiaryId).flatMap(
      ([beneficiaryId, beneficiaryData]) => {
        // Get the beneficiary name from the possible beneficiaries
        let primaryBeneficiary: { name: string } | undefined =
          possibleBeneficiaries?.clients.find((c) => c.id === beneficiaryId) ??
          possibleBeneficiaries?.organizations.find(
            (c) => c.id === beneficiaryId
          );

        // Make sure we show the second grantor even if they are not a possible beneficiary
        if (beneficiaryId === secondGrantor?.id) {
          primaryBeneficiary = { name: secondGrantor.displayName };
        }

        if (!primaryBeneficiary) {
          return [];
        }

        // This is the total access the beneficiary has before the first death
        const beforeFirstDeathValue = getAccessValueForDeathEvent(
          beneficiaryData,
          [AfterDeath.None]
        );

        // This is the total access the beneficiary has before the first death
        // combined with the access they have at the time of the first death
        // with conflicting benefits deduplicated and the max contributor taken
        const noneAndFirstDeathValue = getAccessValueForDeathEvent(
          beneficiaryData,
          [AfterDeath.None, AfterDeath.First]
        );

        // This is the total access the beneficiary has before the first death
        // combined with the access they have at the time of the first death
        // combined with the access they have at the time of the second death
        // with conflicting benefits deduplicated and the max contributor taken
        const noneFirstAndSecondDeathValue = getAccessValueForDeathEvent(
          beneficiaryData,
          [AfterDeath.None, AfterDeath.First, AfterDeath.Second]
        );

        // If the beneficiary has more access at the time of the first death than they did before, we show that
        // difference in the bar chart
        const afterFirstDeathValue = maxDecimalJS(
          [noneAndFirstDeathValue.minus(beforeFirstDeathValue), new Decimal(0)],
          new Decimal(0)
        );

        // If the beneficiary has more access at the time of the second death than they did at the time of the
        // first death, we show that difference in the bar chart
        const afterSecondDeathValue = maxDecimalJS(
          [
            noneFirstAndSecondDeathValue.minus(noneAndFirstDeathValue),
            new Decimal(0),
          ],
          new Decimal(0)
        );

        return {
          beneficiaryId,
          beneficiaryName: primaryBeneficiary.name,
          beneficiaryData,
          beforeFirstDeathValue,
          afterFirstDeathValue,
          afterSecondDeathValue,
          totalValue: noneFirstAndSecondDeathValue,
        };
      }
    );
  }, [
    beneficiaryDataByBeneficiaryId,
    possibleBeneficiaries?.clients,
    possibleBeneficiaries?.organizations,
    secondGrantor?.displayName,
    secondGrantor?.id,
  ]);

  const bars = orderBy(
    primaryBeneficiaryDataWithValues.map(
      ({
        beneficiaryId,
        beneficiaryName,
        beforeFirstDeathValue,
        afterFirstDeathValue,
        afterSecondDeathValue,
        totalValue,
      }) => ({
        id: beneficiaryId,
        label: beneficiaryName,
        total: totalValue,
        sections: [
          {
            value: afterSecondDeathValue.toNumber(),
            label: 'After second death',
            color: chartColorDefinitions.TERTIARY.backgroundColor,
            sectionTextColor: chartColorDefinitions.TERTIARY.contrastText,
            tooltip: (
              <StackedHorizontalBarTooltip
                value={afterSecondDeathValue}
                label="After second death"
              />
            ),
          },
          {
            value: afterFirstDeathValue.toNumber(),
            label: 'After first death',
            color: chartColorDefinitions.SECONDARY.backgroundColor,
            sectionTextColor: chartColorDefinitions.SECONDARY.contrastText,
            tooltip: (
              <StackedHorizontalBarTooltip
                value={afterFirstDeathValue}
                label="After first death"
              />
            ),
          },
          {
            value: beforeFirstDeathValue.toNumber(),
            label: 'Before first death',
            color: chartColorDefinitions.PRIMARY.backgroundColor,
            sectionTextColor: chartColorDefinitions.PRIMARY.contrastText,
            tooltip: (
              <StackedHorizontalBarTooltip
                value={beforeFirstDeathValue}
                label="Before first death"
              />
            ),
          },
        ],
      })
    ),
    (bar) => bar.total.toNumber(),
    'desc'
  ).filter((bar) => bar.total.gt(0));

  return bars;
}
