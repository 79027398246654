// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignableEntityFragment = { __typename?: 'Entity', id: string, kind: Types.EntityKind, subtype: { __typename?: 'CCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'CLTTrust', id: string, displayName: string } | { __typename?: 'CRTTrust', id: string, displayName: string } | { __typename?: 'CustodialPersonalAccount', id: string, displayName: string } | { __typename?: 'DonorAdvisedFund', id: string, displayName: string } | { __typename?: 'GPBusinessEntity', id: string, displayName: string } | { __typename?: 'GRATTrust', id: string, displayName: string } | { __typename?: 'ILITTrust', id: string, displayName: string } | { __typename?: 'IndividualPersonalAccount', id: string, displayName: string } | { __typename?: 'InsurancePersonalAccount', id: string, displayName: string } | { __typename?: 'IrrevocableTrust', id: string, displayName: string } | { __typename?: 'JointPersonalAccount', id: string, displayName: string } | { __typename?: 'LLCBusinessEntity', id: string, displayName: string } | { __typename?: 'LPBusinessEntity', id: string, displayName: string } | { __typename?: 'PrivateFoundation', id: string, displayName: string } | { __typename?: 'QPRTTrust', id: string, displayName: string } | { __typename?: 'QualifiedTuitionPersonalAccount', id: string, displayName: string } | { __typename?: 'RetirementPersonalAccount', id: string, displayName: string } | { __typename?: 'RevocableTrust', id: string, displayName: string } | { __typename?: 'SCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'SLATTrust', id: string, displayName: string } | { __typename?: 'SoleProprietorshipBusinessEntity', id: string, displayName: string }, defaultDocument?: { __typename?: 'Document', id: string } | null };

export type GetAssignableEntitiesQueryVariables = Types.Exact<{
  entitiesLike: Types.EntityWhereInput;
}>;


export type GetAssignableEntitiesQuery = { __typename?: 'Query', entities: { __typename?: 'EntityConnection', edges?: Array<{ __typename?: 'EntityEdge', node?: { __typename?: 'Entity', id: string, kind: Types.EntityKind, subtype: { __typename?: 'CCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'CLTTrust', id: string, displayName: string } | { __typename?: 'CRTTrust', id: string, displayName: string } | { __typename?: 'CustodialPersonalAccount', id: string, displayName: string } | { __typename?: 'DonorAdvisedFund', id: string, displayName: string } | { __typename?: 'GPBusinessEntity', id: string, displayName: string } | { __typename?: 'GRATTrust', id: string, displayName: string } | { __typename?: 'ILITTrust', id: string, displayName: string } | { __typename?: 'IndividualPersonalAccount', id: string, displayName: string } | { __typename?: 'InsurancePersonalAccount', id: string, displayName: string } | { __typename?: 'IrrevocableTrust', id: string, displayName: string } | { __typename?: 'JointPersonalAccount', id: string, displayName: string } | { __typename?: 'LLCBusinessEntity', id: string, displayName: string } | { __typename?: 'LPBusinessEntity', id: string, displayName: string } | { __typename?: 'PrivateFoundation', id: string, displayName: string } | { __typename?: 'QPRTTrust', id: string, displayName: string } | { __typename?: 'QualifiedTuitionPersonalAccount', id: string, displayName: string } | { __typename?: 'RetirementPersonalAccount', id: string, displayName: string } | { __typename?: 'RevocableTrust', id: string, displayName: string } | { __typename?: 'SCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'SLATTrust', id: string, displayName: string } | { __typename?: 'SoleProprietorshipBusinessEntity', id: string, displayName: string }, defaultDocument?: { __typename?: 'Document', id: string } | null } | null } | null> | null } };

export type AssignableTestamentaryEntityFragment = { __typename?: 'TestamentaryEntity', id: string, displayName: string };

export type GetAssignableTestamentaryEntitiesQueryVariables = Types.Exact<{
  testamentaryEntitiesLike: Types.TestamentaryEntityWhereInput;
}>;


export type GetAssignableTestamentaryEntitiesQuery = { __typename?: 'Query', testamentaryEntities: { __typename?: 'TestamentaryEntityConnection', edges?: Array<{ __typename?: 'TestamentaryEntityEdge', node?: { __typename?: 'TestamentaryEntity', id: string, displayName: string } | null } | null> | null } };

export const AssignableEntityFragmentDoc = gql`
    fragment AssignableEntity on Entity {
  id
  kind
  subtype {
    id
    displayName
  }
  defaultDocument {
    id
  }
}
    `;
export const AssignableTestamentaryEntityFragmentDoc = gql`
    fragment AssignableTestamentaryEntity on TestamentaryEntity {
  id
  displayName
}
    `;
export const GetAssignableEntitiesDocument = gql`
    query GetAssignableEntities($entitiesLike: EntityWhereInput!) {
  entities(where: $entitiesLike) {
    edges {
      node {
        ...AssignableEntity
      }
    }
  }
}
    ${AssignableEntityFragmentDoc}`;

/**
 * __useGetAssignableEntitiesQuery__
 *
 * To run a query within a React component, call `useGetAssignableEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignableEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignableEntitiesQuery({
 *   variables: {
 *      entitiesLike: // value for 'entitiesLike'
 *   },
 * });
 */
export function useGetAssignableEntitiesQuery(baseOptions: Apollo.QueryHookOptions<GetAssignableEntitiesQuery, GetAssignableEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignableEntitiesQuery, GetAssignableEntitiesQueryVariables>(GetAssignableEntitiesDocument, options);
      }
export function useGetAssignableEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignableEntitiesQuery, GetAssignableEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignableEntitiesQuery, GetAssignableEntitiesQueryVariables>(GetAssignableEntitiesDocument, options);
        }
export function useGetAssignableEntitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAssignableEntitiesQuery, GetAssignableEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignableEntitiesQuery, GetAssignableEntitiesQueryVariables>(GetAssignableEntitiesDocument, options);
        }
export type GetAssignableEntitiesQueryHookResult = ReturnType<typeof useGetAssignableEntitiesQuery>;
export type GetAssignableEntitiesLazyQueryHookResult = ReturnType<typeof useGetAssignableEntitiesLazyQuery>;
export type GetAssignableEntitiesSuspenseQueryHookResult = ReturnType<typeof useGetAssignableEntitiesSuspenseQuery>;
export type GetAssignableEntitiesQueryResult = Apollo.QueryResult<GetAssignableEntitiesQuery, GetAssignableEntitiesQueryVariables>;
export const GetAssignableTestamentaryEntitiesDocument = gql`
    query GetAssignableTestamentaryEntities($testamentaryEntitiesLike: TestamentaryEntityWhereInput!) {
  testamentaryEntities(where: $testamentaryEntitiesLike) {
    edges {
      node {
        ...AssignableTestamentaryEntity
      }
    }
  }
}
    ${AssignableTestamentaryEntityFragmentDoc}`;

/**
 * __useGetAssignableTestamentaryEntitiesQuery__
 *
 * To run a query within a React component, call `useGetAssignableTestamentaryEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignableTestamentaryEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignableTestamentaryEntitiesQuery({
 *   variables: {
 *      testamentaryEntitiesLike: // value for 'testamentaryEntitiesLike'
 *   },
 * });
 */
export function useGetAssignableTestamentaryEntitiesQuery(baseOptions: Apollo.QueryHookOptions<GetAssignableTestamentaryEntitiesQuery, GetAssignableTestamentaryEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAssignableTestamentaryEntitiesQuery, GetAssignableTestamentaryEntitiesQueryVariables>(GetAssignableTestamentaryEntitiesDocument, options);
      }
export function useGetAssignableTestamentaryEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAssignableTestamentaryEntitiesQuery, GetAssignableTestamentaryEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAssignableTestamentaryEntitiesQuery, GetAssignableTestamentaryEntitiesQueryVariables>(GetAssignableTestamentaryEntitiesDocument, options);
        }
export function useGetAssignableTestamentaryEntitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAssignableTestamentaryEntitiesQuery, GetAssignableTestamentaryEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAssignableTestamentaryEntitiesQuery, GetAssignableTestamentaryEntitiesQueryVariables>(GetAssignableTestamentaryEntitiesDocument, options);
        }
export type GetAssignableTestamentaryEntitiesQueryHookResult = ReturnType<typeof useGetAssignableTestamentaryEntitiesQuery>;
export type GetAssignableTestamentaryEntitiesLazyQueryHookResult = ReturnType<typeof useGetAssignableTestamentaryEntitiesLazyQuery>;
export type GetAssignableTestamentaryEntitiesSuspenseQueryHookResult = ReturnType<typeof useGetAssignableTestamentaryEntitiesSuspenseQuery>;
export type GetAssignableTestamentaryEntitiesQueryResult = Apollo.QueryResult<GetAssignableTestamentaryEntitiesQuery, GetAssignableTestamentaryEntitiesQueryVariables>;
export const Operations = {
  Query: {
    GetAssignableEntities: 'GetAssignableEntities' as const,
    GetAssignableTestamentaryEntities: 'GetAssignableTestamentaryEntities' as const
  },
  Fragment: {
    AssignableEntity: 'AssignableEntity' as const,
    AssignableTestamentaryEntity: 'AssignableTestamentaryEntity' as const
  }
}