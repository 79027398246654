// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
export type AssetIntegration_IntegrationEntityFragment = { __typename?: 'IntegrationEntity', kind: Types.IntegrationEntityKind, name: string, id: string };

export type AssetIntegration_EntityFragment = { __typename?: 'Entity', id: string, integrationEntities?: Array<{ __typename?: 'IntegrationEntity', kind: Types.IntegrationEntityKind, name: string, id: string }> | null };

export const AssetIntegration_IntegrationEntityFragmentDoc = gql`
    fragment AssetIntegration_IntegrationEntity on IntegrationEntity {
  kind
  name
  id
}
    `;
export const AssetIntegration_EntityFragmentDoc = gql`
    fragment AssetIntegration_Entity on Entity {
  id
  integrationEntities {
    ...AssetIntegration_IntegrationEntity
  }
}
    ${AssetIntegration_IntegrationEntityFragmentDoc}`;
export const Operations = {
  Fragment: {
    AssetIntegration_IntegrationEntity: 'AssetIntegration_IntegrationEntity' as const,
    AssetIntegration_Entity: 'AssetIntegration_Entity' as const
  }
}