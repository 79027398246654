import { compact } from 'lodash';

import { ClientPresentationSection } from '@/types/schema';

export function useDefaultClientPresentationSections() {
  const defaultSectionOrder: ClientPresentationSection[] = compact([
    ClientPresentationSection.BalanceSheet,
    ClientPresentationSection.AllEntitiesSlide,
    ClientPresentationSection.ProfessionalTeam,
    ClientPresentationSection.EstateWaterfallsGroup,
    ClientPresentationSection.EntitiesGroup,
  ]);

  return defaultSectionOrder;
}
