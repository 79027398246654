import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

export function useLayoutDefinition() {
  const theme = useTheme();
  const isLargeOrLess = useMediaQuery(theme.breakpoints.down('lg'));

  const gridTemplateAreas = useMemo(() => {
    if (isLargeOrLess) {
      return `
      "total-assets estate-tax"
      "total-assets tax-savings"
      "exemptions exemptions"`;
    }

    return `
    "total-assets estate-tax tax-savings"
    "total-assets exemptions exemptions"`;
  }, [isLargeOrLess]);

  const gridTemplateColumns = useMemo(() => {
    if (isLargeOrLess) {
      return 'repeat(2, minmax(0, 1fr))';
    }

    return 'repeat(3, minmax(0, 1fr))';
  }, [isLargeOrLess]);

  const gridTemplateRows = useMemo(() => {
    if (isLargeOrLess) {
      return 'repeat(3, 1fr)';
    }

    return 'repeat(2, 1fr)';
  }, [isLargeOrLess]);

  return {
    gridTemplateAreas,
    gridTemplateColumns,
    gridTemplateRows,
  };
}
