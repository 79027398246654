import Decimal from 'decimal.js';

import { SimulateDispositiveProvisionsQuery } from '../../graphql/SimulateDispositiveProvisions.generated';
import { TotalLineForVariant } from './DispositiveProvisionsTotalLineForVariant';
import { useGetTotalLineDetailsForEntity } from './hooks/useGetTotalLineDetailsForEntity';

interface DispositiveProvisionsTotalLineInnerProps {
  entityTotalMarketValue: Decimal;
  simulationResults: SimulateDispositiveProvisionsQuery;
}

function DispositiveProvisionsTotalLineInner({
  entityTotalMarketValue,
  simulationResults,
}: DispositiveProvisionsTotalLineInnerProps) {
  const { variant, sumOfAllDistributions } = useGetTotalLineDetailsForEntity({
    entityTotalMarketValue,
    simulationResults,
  });

  return (
    <TotalLineForVariant
      variant={variant}
      entityTotalMarketValue={entityTotalMarketValue}
      sumOfAllDistributions={sumOfAllDistributions}
    />
  );
}

interface DispositiveProvisionsTotalLineProps {
  entityTotalMarketValue?: Decimal;
  lastSimulationResults: React.MutableRefObject<
    SimulateDispositiveProvisionsQuery | undefined
  >;
  simulationResults?: SimulateDispositiveProvisionsQuery;
}

export function DispositiveProvisionsTotalLine({
  entityTotalMarketValue,
  lastSimulationResults,
  simulationResults,
}: DispositiveProvisionsTotalLineProps) {
  if (lastSimulationResults.current && !simulationResults) {
    // Render the last simulation results if they exist when we're querying for new results
    return (
      <DispositiveProvisionsTotalLineInner
        entityTotalMarketValue={entityTotalMarketValue ?? new Decimal(0)}
        simulationResults={lastSimulationResults.current}
      />
    );
  }

  if (simulationResults) {
    return (
      <DispositiveProvisionsTotalLineInner
        entityTotalMarketValue={entityTotalMarketValue ?? new Decimal(0)}
        simulationResults={simulationResults}
      />
    );
  }

  return null;
}
