import { AiSuggestionAcceptanceStatus, AiSuggestionKind } from '@/types/schema';

// These are the suggestion kind enums that we support suggestable inputs for.
export const SUGGESTABLE_KINDS = [
  AiSuggestionKind.JurisdictionStateCode,
  AiSuggestionKind.LegalName,
  AiSuggestionKind.GstStatus,
  AiSuggestionKind.EffectiveDate,
  AiSuggestionKind.CrummeyWithdrawalPeriodDays,
  AiSuggestionKind.DoingBusinessAsName,
  AiSuggestionKind.FincenId,
  AiSuggestionKind.TaxId,
  AiSuggestionKind.BusinessEntityTaxStatus,
  AiSuggestionKind.BeneficialOwnersAsOfDate,
] as const;

export type SuggestableKind = (typeof SUGGESTABLE_KINDS)[number];

// These are the suggestion kind enums that we support matching suggestions for.
export const MATCHABLE_KINDS = [
  AiSuggestionKind.Principal,
  AiSuggestionKind.Trustee,
  AiSuggestionKind.TrustAdvisor,
  AiSuggestionKind.Beneficiary,
  AiSuggestionKind.BeneficiaryV2,
  AiSuggestionKind.BeneficialOwner,
  AiSuggestionKind.KeyPerson,
] as const;

export type MatchableKind = (typeof MATCHABLE_KINDS)[number];

export interface AcknowledgedSuggestion {
  suggestionID: string;
  status: AiSuggestionAcceptanceStatus;
  clientProfileId?: string;
  clientOrganizationId?: string;
  entityId?: string;
}
