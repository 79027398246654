import { compact } from 'lodash';
import { useMemo } from 'react';

import { TypeaheadListInputOption } from '@/components/form/formAwareInputs/FormAwareTypeaheadListInput';
import { getNodes } from '@/utils/graphqlUtils';
import { sortAlpha } from '@/utils/sortUtils';

import {
  ManageClientAccessForm_CollaboratorFragment,
  ManageClientAccessForm_EmployeeFragment,
  useManageClientAccessFormQuery,
} from './graphql/ManageHouseholdAccessForm.generated';
import { ManageHouseholdAccessFormShape } from './ManageHouseholdAccessForm.types';

function getCollaboratorListOptionFromCollaborator(
  collaborator: ManageClientAccessForm_CollaboratorFragment
): TypeaheadListInputOption {
  return {
    id: collaborator.id,
    heading: collaborator.displayName,
    description: collaborator.organizationName ?? undefined,
    additionalItems: compact([collaborator.title]),
  };
}

function getAssignedEmployeeListOptionFromEmployee(
  employee: ManageClientAccessForm_EmployeeFragment,
  relationshipOwnerId: string | null
): TypeaheadListInputOption {
  return {
    id: employee.user.id,
    heading: employee.user.displayName,
    description: employee.user.email,
    badgeText:
      employee.user.id === relationshipOwnerId
        ? 'Relationship owner'
        : undefined,
  };
}

const defaultValues: ManageHouseholdAccessFormShape = {
  collaborators: [],
  assignedUsers: [],
};

export function useHouseholdAccessFormData({
  householdId,
}: {
  householdId: string;
}) {
  const { data, ...queryProps } = useManageClientAccessFormQuery({
    variables: {
      householdId,
      // when we get client access information, we also fetch details about whether or not the associated user is
      // currently assigned to tasks associated with this client. if they *are*, we present a slightly different
      // removal flow where we also prompt the user to reassign those tasks to another user that still has access.
      assignedTasksLike: {
        hasEntityWith: [
          {
            hasHouseholdWith: [
              {
                id: householdId,
              },
            ],
          },
        ],
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const household = (() => {
    if (data?.household?.__typename !== 'Household') return null;
    return data?.household;
  })();

  const formData: ManageHouseholdAccessFormShape = useMemo(() => {
    if (household?.__typename !== 'Household') {
      return defaultValues;
    }

    return {
      collaborators: (household?.collaborators ?? [])
        .map(getCollaboratorListOptionFromCollaborator)
        .sort((a, b) => sortAlpha(a.heading, b.heading)),
      assignedUsers: (household?.assignedEmployees ?? [])
        .map((e) =>
          getAssignedEmployeeListOptionFromEmployee(
            e,
            household.primaryRelationshipOwner.id
          )
        )
        .sort((a, b) => sortAlpha(a.heading, b.heading)),
    };
  }, [
    household?.__typename,
    household?.assignedEmployees,
    household?.collaborators,
    household?.primaryRelationshipOwner.id,
  ]);

  // these are the collaborators for this tenant who are eligible to be associated with this client
  const collaboratorOptions = useMemo(() => {
    return getNodes(data?.collaborators).map(
      getCollaboratorListOptionFromCollaborator
    );
  }, [data]);

  const assignableEmployeeOptions = useMemo(
    () =>
      getNodes(data?.employees).map((e) =>
        getAssignedEmployeeListOptionFromEmployee(e, null)
      ),
    [data]
  );

  const userToTaskAssignmentMap = useMemo(() => {
    const res: Record<string, boolean> = {};

    getNodes(data?.collaborators).forEach((collab) => {
      if (!collab.user) return;
      res[collab.user.id] = Boolean(collab.user.assignedTasks?.totalCount);
    });

    getNodes(data?.employees).forEach((employee) => {
      res[employee.user.id] = Boolean(employee.user?.assignedTasks?.totalCount);
    });

    return res;
  }, [data]);

  const userIdToEmployeeIdMap = useMemo(() => {
    const res: Record<string, string> = {};

    getNodes(data?.employees).forEach((employee) => {
      res[employee.user.id] = employee.id;
    });

    return res;
  }, [data]);

  return {
    formData,
    collaboratorOptions,
    assignableEmployeeOptions,
    userToTaskAssignmentMap,
    userIdToEmployeeIdMap,
    relationshipOwnerId: household?.primaryRelationshipOwner.id ?? null,
    ...queryProps,
  };
}
