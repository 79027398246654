import { FormProvider } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useForm, useSubmitSuccessHandler } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';

import { CollaboratorForm } from './CollaboratorForm';
import {
  CollaboratorFormShape,
  defaultValues,
} from './CollaboratorModal.constants';
import {
  mapFormDataToUpdateInput,
  useSyncCollaboratorDataToForm,
} from './EditCollaboratorModal.utils';
import { useUpdateCollaboratorMutation } from './graphql/CollaboratorModal.generated';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'> & {
  collaboratorId: string;
};
export type EditCollaboratorModalProps = FormModalOptionalProps;

function EditCollaborator({
  onClose,
  heading = 'Edit collaborator',
  collaboratorId,
  ...formModalProps
}: FormModalOptionalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { formRef, handleSave } = useFormSaveHandler();
  const { handleSubmit, reset, shouldBlockNavigation, formState } =
    useFormContext<CollaboratorFormShape>();

  const [updateMutation] = useUpdateCollaboratorMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError(`Could not create collaborator`, error);
    },
  });

  const onSubmit = handleSubmit((formData) => {
    return updateMutation({
      variables: { input: mapFormDataToUpdateInput(formData, collaboratorId) },
    });
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  useSubmitSuccessHandler(() => {
    closeModal();
  });

  return (
    <FormModal
      {...formModalProps}
      onClose={onClose}
      heading={heading}
      shouldBlockClose={shouldBlockNavigation}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.CancelButton onClick={closeModal} />
          <FormModalActions.SaveButton onClick={handleSave}>
            Save changes
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <CollaboratorForm variant="edit" />
      </form>
    </FormModal>
  );
}

export const EditCollaboratorModal = ({
  collaboratorId,
  ...props
}: EditCollaboratorModalProps) => {
  const formMethods = useForm<CollaboratorFormShape>({ defaultValues });
  useSyncCollaboratorDataToForm(formMethods.reset, collaboratorId);

  return (
    <FormProvider {...formMethods}>
      <EditCollaborator {...props} collaboratorId={collaboratorId} />
    </FormProvider>
  );
};
