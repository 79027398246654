// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TenantConfigurationForm_TenantConfigurationFragment = { __typename?: 'TenantApplicationConfiguration', id: string, disableAiCapabilities: boolean, userSessionInactivityTimeout: Types.TenantApplicationConfigurationUserSessionInactivityTimeout };

export type TenantConfigurationFormQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type TenantConfigurationFormQuery = { __typename?: 'Query', tenantApplicationConfiguration?: { __typename?: 'TenantApplicationConfiguration', id: string, disableAiCapabilities: boolean, userSessionInactivityTimeout: Types.TenantApplicationConfigurationUserSessionInactivityTimeout } | null };

export type UpdateTenantConfigurationFormMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateTenantApplicationConfigurationInput;
}>;


export type UpdateTenantConfigurationFormMutation = { __typename?: 'Mutation', updateTenantApplicationConfiguration?: { __typename?: 'TenantApplicationConfiguration', id: string, disableAiCapabilities: boolean, userSessionInactivityTimeout: Types.TenantApplicationConfigurationUserSessionInactivityTimeout } | null };

export const TenantConfigurationForm_TenantConfigurationFragmentDoc = gql`
    fragment TenantConfigurationForm_tenantConfiguration on TenantApplicationConfiguration {
  id
  disableAiCapabilities
  userSessionInactivityTimeout
}
    `;
export const TenantConfigurationFormDocument = gql`
    query TenantConfigurationForm {
  tenantApplicationConfiguration {
    ...TenantConfigurationForm_tenantConfiguration
  }
}
    ${TenantConfigurationForm_TenantConfigurationFragmentDoc}`;

/**
 * __useTenantConfigurationFormQuery__
 *
 * To run a query within a React component, call `useTenantConfigurationFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useTenantConfigurationFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTenantConfigurationFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useTenantConfigurationFormQuery(baseOptions?: Apollo.QueryHookOptions<TenantConfigurationFormQuery, TenantConfigurationFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantConfigurationFormQuery, TenantConfigurationFormQueryVariables>(TenantConfigurationFormDocument, options);
      }
export function useTenantConfigurationFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantConfigurationFormQuery, TenantConfigurationFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantConfigurationFormQuery, TenantConfigurationFormQueryVariables>(TenantConfigurationFormDocument, options);
        }
export function useTenantConfigurationFormSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<TenantConfigurationFormQuery, TenantConfigurationFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TenantConfigurationFormQuery, TenantConfigurationFormQueryVariables>(TenantConfigurationFormDocument, options);
        }
export type TenantConfigurationFormQueryHookResult = ReturnType<typeof useTenantConfigurationFormQuery>;
export type TenantConfigurationFormLazyQueryHookResult = ReturnType<typeof useTenantConfigurationFormLazyQuery>;
export type TenantConfigurationFormSuspenseQueryHookResult = ReturnType<typeof useTenantConfigurationFormSuspenseQuery>;
export type TenantConfigurationFormQueryResult = Apollo.QueryResult<TenantConfigurationFormQuery, TenantConfigurationFormQueryVariables>;
export const UpdateTenantConfigurationFormDocument = gql`
    mutation UpdateTenantConfigurationForm($input: AugmentedUpdateTenantApplicationConfigurationInput!) {
  updateTenantApplicationConfiguration(input: $input) {
    ...TenantConfigurationForm_tenantConfiguration
  }
}
    ${TenantConfigurationForm_TenantConfigurationFragmentDoc}`;
export type UpdateTenantConfigurationFormMutationFn = Apollo.MutationFunction<UpdateTenantConfigurationFormMutation, UpdateTenantConfigurationFormMutationVariables>;

/**
 * __useUpdateTenantConfigurationFormMutation__
 *
 * To run a mutation, you first call `useUpdateTenantConfigurationFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantConfigurationFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantConfigurationFormMutation, { data, loading, error }] = useUpdateTenantConfigurationFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantConfigurationFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantConfigurationFormMutation, UpdateTenantConfigurationFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantConfigurationFormMutation, UpdateTenantConfigurationFormMutationVariables>(UpdateTenantConfigurationFormDocument, options);
      }
export type UpdateTenantConfigurationFormMutationHookResult = ReturnType<typeof useUpdateTenantConfigurationFormMutation>;
export type UpdateTenantConfigurationFormMutationResult = Apollo.MutationResult<UpdateTenantConfigurationFormMutation>;
export type UpdateTenantConfigurationFormMutationOptions = Apollo.BaseMutationOptions<UpdateTenantConfigurationFormMutation, UpdateTenantConfigurationFormMutationVariables>;
export const Operations = {
  Query: {
    TenantConfigurationForm: 'TenantConfigurationForm' as const
  },
  Mutation: {
    UpdateTenantConfigurationForm: 'UpdateTenantConfigurationForm' as const
  },
  Fragment: {
    TenantConfigurationForm_tenantConfiguration: 'TenantConfigurationForm_tenantConfiguration' as const
  }
}