import { includes } from 'lodash';
import { Control } from 'react-hook-form';

import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import {
  PrimaryClientDropdownMultiple,
  PrimaryClientDropdownMultipleFormShape,
  PrimaryClientDropdownSingle,
  PrimaryClientDropdownSingleFormShape,
} from '@/modules/entities/inputs/PrimaryClientDropdown/PrimaryClientDropdown';
import { getGrantorOptionsFromData } from '@/modules/entities/inputs/PrimaryClientDropdown/PrimaryClientDropdown.utils';
import {
  GrantorsFieldSubformsNamespaceType,
  GrantorsFieldSubformValuesType,
  GrantorsFieldVariantType,
} from '@/modules/entities/principals/GrantorsField/GrantorsField.types';
import { usePersistNonClientGrantorIndicator } from '@/modules/entities/TrustDetailsSubform/hooks/usePersistNotClientGrantorIndicator';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

interface GrantorsFieldProps<
  T extends
    | PrimaryClientDropdownSingleFormShape
    | PrimaryClientDropdownMultipleFormShape,
> {
  control: Control<T>;
  variant: GrantorsFieldVariantType;
  subformNamespace: GrantorsFieldSubformsNamespaceType;
  subformValues: GrantorsFieldSubformValuesType;
  isCharitableEntity: boolean;
}

/**
 * The Grantors fields for the Basic Information subform.
 */
export function GrantorsField<
  T extends
    | PrimaryClientDropdownSingleFormShape
    | PrimaryClientDropdownMultipleFormShape,
>({
  control,
  variant,
  subformNamespace,
  subformValues,
  isCharitableEntity,
}: GrantorsFieldProps<T>) {
  const { possibleGrantors } = useHouseholdDetailsContext();

  const isSingleGrantor = variant === 'SingleGrantor';
  const isMultiGrantor = !includes<GrantorsFieldVariantType>(
    ['SingleGrantor'],
    variant
  );

  // we specifically want to include non-client grantors here
  const grantorOptions = getGrantorOptionsFromData(
    possibleGrantors || [],
    subformValues
  );

  usePersistNonClientGrantorIndicator(
    possibleGrantors || [],
    subformNamespace,
    subformValues || null
  );

  return (
    <>
      {isMultiGrantor && (
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <PrimaryClientDropdownMultiple<PrimaryClientDropdownMultipleFormShape>
              // @ts-ignore -- The problem here is that the "defaultValues"
              // within the nested form control state object is not expected to
              // be undefined. But EntitySubforms.tsx sets up the useForm hook
              // where defaultValues is a Partial<SubformsCombinedType> type.
              control={control}
              fieldName={`${subformNamespace}.grantors`}
              validationFieldName={`${subformNamespace}._grantorAttributionSum`}
              options={grantorOptions}
              isDonors={isCharitableEntity}
              variant={
                variant === 'MultipleGrantorWithPercent'
                  ? 'grantorAndPercent'
                  : 'grantorOnly'
              }
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      {isSingleGrantor && (
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <PrimaryClientDropdownSingle<T>
              control={control}
              namespace={subformNamespace}
              options={grantorOptions}
              // undefined is the non-isCharitableEntity case because we want to use the default handling
              labelOverride={isCharitableEntity ? 'Donor' : undefined}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
    </>
  );
}
