import { EntityKind } from '@/types/schema';

export enum TransferOptionType {
  Grantor = 'grantor',
  Entity = 'entity',
  Organization = 'organization',
}

interface TransferOptionBase {
  id: string;
  label: string;
}

interface TransferOptionGrantorOrganization extends TransferOptionBase {
  type: TransferOptionType.Grantor | TransferOptionType.Organization;
  entityKind?: never;
}

interface TransferOptionEntity extends TransferOptionBase {
  type: TransferOptionType.Entity;
  entityKind: EntityKind | null | undefined;
}

export type TransferOption =
  | TransferOptionGrantorOrganization
  | TransferOptionEntity;
