import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
} from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup/ButtonGroup';
import { PlusCircleIcon } from '@/components/icons/PlusCircleIcon';
import { XCircleIcon } from '@/components/icons/XCircleIcon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Modal, ModalProps } from '@/components/modals/Modal/Modal';
import { Alert } from '@/components/notifications/Alert/Alert';
import { useForm } from '@/components/react-hook-form';
import { COLORS } from '@/styles/tokens/colors';
import { EntityStage } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { formatCurrency } from '@/utils/formatting/currency';

import { DisplayEntities } from '../../hooks/useClientEntities';
import { EditEntitiesForm } from './constants';

export type EditEntitiesModalProps = Omit<
  ModalProps,
  'children' | 'heading'
> & {
  onSubmit: (values: EditEntitiesForm) => void;
  entitiesInProposal: DisplayEntities;
  householdId: string;
  displayEntities: DisplayEntities;
};

export function EditEntitiesModal({
  onClose,
  onSubmit,
  isOpen,
  entitiesInProposal,
  displayEntities,
  ...modalProps
}: EditEntitiesModalProps) {
  const [entitiesEligibleToAdd, setEntitiesEligibleToAdd] =
    useState<DisplayEntities>([]);

  useEffect(() => {
    // only show entities that are ready to be added to a proposal
    // and are not already in the proposal
    const entityIdsInProposal = entitiesInProposal.map(
      (entity) => entity?.id ?? ''
    );

    const entitiesNotInProposal = displayEntities?.filter(
      (entity) => !entityIdsInProposal.includes(entity?.id ?? '')
    );

    const entitiesReadyForProposal = entitiesNotInProposal?.filter((entity) => {
      // include entities only if they are ready to be added to a proposal
      if (entity?.stage !== EntityStage.ReadyForProposal) {
        return false;
      }
      return true;
    });

    setEntitiesEligibleToAdd(entitiesReadyForProposal ?? []);
  }, [entitiesInProposal, displayEntities]);

  const [tab, setTab] = useState<'in-proposal' | 'available-to-add'>(
    'in-proposal'
  );

  const formMethods = useForm<EditEntitiesForm>({
    defaultValues: {
      entitiesInProposal,
    },
  });

  const { control, handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset({ entitiesInProposal });
  }, [entitiesInProposal, reset]);

  const { fields, append, remove } = useFieldArray({
    name: 'entitiesInProposal',
    control,
    keyName: '_id',
  });

  const onValidSubmit: SubmitHandler<EditEntitiesForm> = (values) => {
    onSubmit(values);
    setTimeout(() => {
      reset();
    }, 200);
  };

  const onInvalidSubmit: SubmitErrorHandler<EditEntitiesForm> = (errors) => {
    diagnostics.debug(errors);
  };

  const onFormSubmit = handleSubmit(onValidSubmit, onInvalidSubmit);

  const modalActions = useMemo(
    () => (
      <>
        <Box>
          <Button onClick={onClose} size="sm" variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={onFormSubmit}
            size="sm"
            variant="primary"
            type="submit"
            disabled={fields.length === 0}
          >
            Confim
          </Button>
        </Box>
      </>
    ),
    [onClose, onFormSubmit, fields.length]
  );

  return (
    <FormProvider {...formMethods}>
      <Modal
        isOpen={isOpen}
        actions={modalActions}
        onClose={onClose}
        heading={'Add/remove GRATs'}
        {...modalProps}
      >
        <Stack
          component="form"
          noValidate
          onSubmit={onFormSubmit}
          flex={1}
          sx={{ minHeight: 450 }}
        >
          <Stack flexDirection="column">
            <FormLayoutRow>
              <FormLayoutItem>
                <ButtonGroup
                  label=""
                  value={tab}
                  onChange={(_, value) => setTab(value)}
                  options={[
                    { display: 'In proposal', value: 'in-proposal' },
                    { display: 'Available to add', value: 'available-to-add' },
                  ]}
                />
              </FormLayoutItem>
            </FormLayoutRow>
            {tab === 'in-proposal' ? (
              <>
                <Typography mb={2} variant="body1">
                  These GRATs will appear in this proposal.
                </Typography>
                {fields.length === 0 && (
                  <FormLayoutItem width={8}>
                    <Alert size="sm" sx={{ mt: 3 }} severity="info-high">
                      At least one GRAT is required to build a proposal
                    </Alert>
                  </FormLayoutItem>
                )}
                {fields.map((field, idx) => {
                  return (
                    <Grid
                      key={idx}
                      display="grid"
                      gridTemplateColumns="1fr 88px"
                      alignItems="center"
                      borderBottom={`1px ${COLORS.GRAY[200]} solid`}
                    >
                      <Box p={2}>
                        <Typography variant="h5">
                          {field.displayName}
                        </Typography>
                        <Typography variant="subtitle2">
                          {field.description}
                        </Typography>
                        <Typography variant="subtitle2">
                          {field.assetSummaryDisplay}
                        </Typography>
                        <Typography variant="label">
                          {formatCurrency(field.computedValue)}
                        </Typography>
                      </Box>
                      <Box p={2} justifySelf="center">
                        <RemoveRowButton
                          size="lg"
                          variant="transparent"
                          onClick={() => {
                            setEntitiesEligibleToAdd((entities) => [
                              ...entities,
                              field,
                            ]);

                            remove(idx);
                          }}
                        >
                          <XCircleIcon size={20} />
                        </RemoveRowButton>
                      </Box>
                    </Grid>
                  );
                })}
              </>
            ) : (
              <>
                <Typography mb={2} variant="body1">
                  The following GRATs are available for use in this proposal.
                </Typography>
                {entitiesEligibleToAdd?.length === 0 && (
                  <FormLayoutItem width={8}>
                    <Alert size="sm" sx={{ mt: 3 }} severity="info-high">
                      No additional GRATs are available to add to this proposal.
                    </Alert>
                  </FormLayoutItem>
                )}

                {entitiesEligibleToAdd?.map((entity, idx) => {
                  if (!entity) {
                    return null;
                  }

                  return (
                    <Grid
                      key={idx}
                      display="grid"
                      gridTemplateColumns="1fr 88px"
                      alignItems="center"
                      borderBottom={`1px ${COLORS.GRAY[200]} solid`}
                    >
                      <Box p={2}>
                        <Typography variant="h4">
                          {entity.displayName}
                        </Typography>
                        <Typography variant="subtitle2">
                          {entity.description}
                        </Typography>
                        <Typography variant="subtitle2">
                          {entity.assetSummaryDisplay}
                        </Typography>
                        <Typography variant="label">
                          {formatCurrency(entity.computedValue)}
                        </Typography>
                      </Box>
                      <Box p={2} justifySelf="center">
                        <RemoveRowButton
                          size="lg"
                          variant="transparent"
                          onClick={() => {
                            append(entity);
                            setEntitiesEligibleToAdd(
                              entitiesEligibleToAdd.filter((_, i) => idx !== i)
                            );
                          }}
                        >
                          <PlusCircleIcon size={20} />
                        </RemoveRowButton>
                      </Box>
                    </Grid>
                  );
                })}
              </>
            )}
          </Stack>
        </Stack>
      </Modal>
    </FormProvider>
  );
}

const RemoveRowButton = styled(Button)(() => ({
  margin: 0,
  padding: 0,
}));
