import { Box, ListItemText } from '@mui/material';
import { useMemo } from 'react';

import {
  DropdownButton,
  DropdownButtonProps,
} from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { Map01Icon } from '@/components/icons/Map01Icon';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useModalState } from '@/hooks/useModalState';

import { GraphViz_MinimalViewFragment } from '../graphql/GraphVizView.generated';
import {
  CreateGraphVizViewModal,
  CreateGraphVizViewModalProps,
} from './CreateGraphVizViewModal';

export interface GraphVizViewSelectorProps<TabValue extends string>
  extends Omit<CreateGraphVizViewModalProps<TabValue>, 'isOpen' | 'onClose'> {
  views: GraphViz_MinimalViewFragment[];
  view: GraphViz_MinimalViewFragment;
  onSelectView: (view: GraphViz_MinimalViewFragment) => void;
}

export function GraphVizViewSelector<TabValue extends string>({
  householdId,
  views,
  view,
  onSelectView,
  ...modalProps
}: GraphVizViewSelectorProps<TabValue>) {
  const [{ isModalOpen }, { closeModal, openModal }] = useModalState();

  const items = useMemo<DropdownButtonProps['items']>(() => {
    const savedViews = (views || []).map((view) => ({
      name: view.displayName,
      clickHandler: () => onSelectView(view),
    }));

    const createViewItem: DropdownButtonProps['items'][0] = {
      clickHandler: openModal,
      component: (
        <>
          <PlusIcon size={16} />
          <ListItemText sx={{ ml: 0.5 }}>Create custom map</ListItemText>
        </>
      ),
    };

    return [...savedViews, createViewItem];
  }, [onSelectView, openModal, views]);

  return (
    <>
      <DropdownButton
        name="GraphVizViewSelector"
        variant="secondary-filled"
        showArrow
        buttonContent={
          <Box gap={1} display="flex" alignItems="center">
            {view.displayName}
            <Map01Icon size={20} />
          </Box>
        }
        items={items}
        size="sm"
      />

      <CreateGraphVizViewModal
        isOpen={isModalOpen}
        onClose={closeModal}
        householdId={householdId}
        {...modalProps}
      />
    </>
  );
}
