import { useParams } from 'react-router-dom';

export const useRequiredParams = (): Record<string, string> => {
  const params = useParams();

  const requiredParams: Record<string, string> = {};
  Object.entries(params).map(([key, value]) => {
    if (value) {
      requiredParams[key] = value;
    }
  });

  return requiredParams;
};
