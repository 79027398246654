import Decimal from 'decimal.js';
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { FullScreenModalProps } from '@/components/modals/FullScreenModal/FullScreenModal';
import { useEntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { EditEntitySplitScreenModalContext } from '@/modules/entities/EditEntitySplitScreen/contexts/editEntitySplitScreenModal.context';
import {
  CombinedSupportedSubformFieldTypes,
  EditEntitySection,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { useEditEntitySplitScreenSubformConfigs } from '@/modules/entities/EditEntitySplitScreen/hooks/useEditEntitySplitScreenSubformConfigs';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { EntityType } from '../../types/EntityType';
import { getSectionOptions } from '../EditEntitySplitScreen.utils';

interface EditEntitySplitScreenModalProviderProps {
  initialSection: EditEntitySection;
  entityType: EntityType;
  entityValueOverride?: Decimal;
  formActionsPortalRef: React.RefObject<HTMLDivElement>;
  navigateAfterSave: boolean;
  onClose: FullScreenModalProps['onClose'];
}

function useContextValue({
  initialSection,
  entityType,
  entityValueOverride,
  formActionsPortalRef,
  navigateAfterSave,
  onClose,
}: EditEntitySplitScreenModalProviderProps): EditEntitySplitScreenModalContext {
  const { primaryClients } = useHouseholdDetailsContext();
  const { subformConfigsWithInitialData, hasError } =
    useEditEntitySplitScreenSubformConfigs();

  const { resetAcknowledgedSuggestions } = useEntitySuggestionsContext();

  const [currentSection, setCurrentSection] =
    useState<EditEntitySplitScreenModalContext['currentSection']>(
      initialSection
    );

  const [selectedSuggestion, setSelectedSuggestion] =
    useState<EditEntitySplitScreenModalContext['selectedSuggestion']>(null);

  useEffect(() => {
    resetAcknowledgedSuggestions();
  }, [currentSection, resetAcknowledgedSuggestions]);

  const sectionOptions = useMemo(() => {
    return getSectionOptions(entityType, primaryClients);
  }, [entityType, primaryClients]);

  const { currentSectionSubformConfig, initialSubformValues } = useMemo(() => {
    const sectionConfigsWithData =
      subformConfigsWithInitialData[currentSection];
    if (!sectionConfigsWithData) {
      return {
        currentSectionSubformConfig: undefined,
        initialSubformValues: undefined,
      };
    }

    // Combine the initial data for all sections/subforms.
    const initialSubformValues = Object.values(
      subformConfigsWithInitialData
    ).reduce((prev, curr) => {
      const namespace = curr.subformConfig.type;
      // @ts-ignore -- Currently, we only support a subset of all subform
      // namespace types in the CombinedSupportedSubformFieldTypes object.
      prev[namespace] = curr.initialData;
      return prev;
    }, {} as CombinedSupportedSubformFieldTypes);

    return {
      currentSectionSubformConfig: sectionConfigsWithData.subformConfig,
      initialSubformValues,
    };
  }, [subformConfigsWithInitialData, currentSection]);

  const handleCloseModal = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return {
    entityType,
    entityValueOverride,
    formActionsPortalRef,
    navigateAfterSave,
    sectionOptions,
    currentSectionSubformConfig,
    initialSubformValues,
    hasError,
    currentSection,
    setCurrentSection,
    selectedSuggestion,
    setSelectedSuggestion,
    handleCloseModal,
  };
}

export const EditEntitySplitScreenModalProvider = ({
  children,
  ...rest
}: PropsWithChildren<EditEntitySplitScreenModalProviderProps>) => {
  const value = useContextValue(rest);

  return (
    <EditEntitySplitScreenModalContext.Provider value={value}>
      {children}
    </EditEntitySplitScreenModalContext.Provider>
  );
};
