import { useApolloClient } from '@apollo/client';
import { Box } from '@mui/material';
import React from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Callout } from '@/components/notifications/Callout/Callout';
import { DocumentUploader } from '@/modules/documents/DocumentUploader/DocumentUploader';
import { DocumentRepresentation } from '@/modules/files/DocumentRepresentation/DocumentRepresentation';
import { DocumentType } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import { GetImplementationTasksV2Document } from '../../graphql/ImplementationTasks.generated';
import { TaskHeaderCard } from '../../TaskHeaderCard';
import { TaskBodyProps } from '../../types';
import { useCompleteEntityTaskMutation } from '../graphql/CompleteEntityTask.generated';
import {
  GetAssetTransferConfirmationDocumentsDocument,
  useGetAssetTransferConfirmationDocumentsQuery,
} from './graphql/AssetTransferConfirmationDocuments.generated';

export function UploadConfirmationOfAssetTransferTaskBody({
  task,
  footer,
  onCompleteTask,
}: TaskBodyProps) {
  // mutations
  const [completeTask] = useCompleteEntityTaskMutation();
  const apolloClient = useApolloClient();

  // state
  const [createDocumentErrorMessage, setCreateDocumentErrorMessage] =
    React.useState<string | null>(null);

  // query
  const { data, error: fetchTrustDocumentsError } =
    useGetAssetTransferConfirmationDocumentsQuery({
      variables: {
        where: {
          hasEntityWith: [{ id: task.entityId }],
          type: DocumentType.TransferConfirmation,
        },
      },
    });

  // handlers
  async function handleTaskComplete() {
    try {
      await completeTask({
        variables: {
          taskID: task.id,
        },
        refetchQueries: [GetImplementationTasksV2Document],
      });
      onCompleteTask();
    } catch (err) {
      diagnostics.error(`failed to complete ${task.body.type}`, err as Error, {
        taskID: task.id,
        taskType: task.type,
        householdId: task.householdId,
        entityId: task.entityId,
      });
    }
  }

  async function handleNewDocumentUpload() {
    setCreateDocumentErrorMessage(null);
    await apolloClient.refetchQueries({
      include: [GetAssetTransferConfirmationDocumentsDocument],
    });
  }

  return (
    <TaskHeaderCard task={task} footer={footer}>
      <Box>
        <Box minHeight={400}>
          <DocumentUploader
            errorMessage={createDocumentErrorMessage || undefined}
            householdId={task.householdId}
            onDocumentUploadSuccess={handleNewDocumentUpload}
            onDocumentUploadError={() =>
              setCreateDocumentErrorMessage(
                `We weren't able to complete your document generation process. Please try again.`
              )
            }
            getCreateDocumentInput={({ fileId, fileName }) => ({
              entityID: task.entityId,
              householdID: task.householdId,
              type: DocumentType.TransferConfirmation,
              fileID: fileId,
              name: fileName,
            })}
          />

          {fetchTrustDocumentsError ? (
            <Callout severity="error">
              We weren&apos;t able to get your documents right now. Please
              refresh the page to try again.
            </Callout>
          ) : (
            <Box my={2}>
              {data?.documents?.edges?.flatMap((edge) => {
                if (!edge?.node) return [];
                const document = edge.node;
                return (
                  <DocumentRepresentation
                    key={document.id}
                    sx={{ mt: 1 }}
                    fileName={document.name}
                    uploadedAt={document.file.createdAt}
                    uploadedBy={document.file.user.displayName}
                    documentId={document.id}
                  />
                );
              })}
            </Box>
          )}
        </Box>
        <Button
          variant="primary"
          fullWidth
          size="sm"
          onClick={handleTaskComplete}
        >
          I&apos;m done uploading documents
        </Button>
        <Button
          variant="transparent"
          fullWidth
          size="sm"
          onClick={handleTaskComplete}
        >
          Skip this
        </Button>
      </Box>
    </TaskHeaderCard>
  );
}
