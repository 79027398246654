import { compact, first, isEmpty, partition } from 'lodash';

import { AIOnboardingModalEntityField } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { AiOnboardingModal_AiSuggestionFragment } from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import {
  BUSINESS_ENTITY_TYPES,
  TRUST_ENTITY_TYPES,
} from '@/modules/entities/entities.constants';
import { EntityType } from '@/modules/entities/types/EntityType';
import { DocumentType } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

/**
 * Get the associated documentIDs for a suggestionID. This includes the "source"
 * document from any merged KGNodes that were involved in creating the suggestion.
 *
 * @param suggestionID
 * @param suggestionsByID
 */
export function getAssociatedDocumentsForSuggestionID(
  suggestionID: string | undefined,
  suggestionsByID: Record<string, AiOnboardingModal_AiSuggestionFragment>
): { docID: string; docType: DocumentType }[] {
  if (!suggestionID) return [];
  const suggestionDocs = compact(
    suggestionsByID[suggestionID]?.kgNode?.mergedFrom?.map(
      (n) => n.file?.document
    )
  );
  return suggestionDocs.map((doc) => ({ docID: doc.id, docType: doc.type }));
}

/**
 * NOTE: This is a stop-gap to prevent "ent: constraint failed: one of [DOC_ID] is already connected to a different entity_documents" errors in the backend.
 * We do our best attempt to only suggestion 1 entity per document, but there's sometimes some variability.
 *
 * @param entities
 * @param suggestionsByID
 */
export function assignDocumentsToEntitySuggestions(
  entities: AIOnboardingModalEntityField[],
  suggestionsByID: Record<string, AiOnboardingModal_AiSuggestionFragment>
): Record<string, string[]> {
  // Accumulate the entity suggestionIDs that spawned from each documentID.
  const documentIDToSuggestionIDs: Record<string, string[]> = {};
  const documentIDToType: Record<string, DocumentType> = {};
  entities.forEach((e) => {
    if (!e._suggestionID) return; // ignore manually-added entity rows

    const docs = getAssociatedDocumentsForSuggestionID(
      e._suggestionID,
      suggestionsByID
    );

    docs.forEach(({ docID, docType }) => {
      if (!documentIDToSuggestionIDs[docID]) {
        documentIDToSuggestionIDs[docID] = [];
      }
      documentIDToSuggestionIDs[docID]!.push(e._suggestionID!);
      documentIDToType[docID] = docType;
    });
  });
  diagnostics.debug(
    '[AIOnboardingModal][assignDocumentsToEntitySuggestion] documentIDToSuggestionIDs',
    documentIDToSuggestionIDs
  );
  diagnostics.debug(
    '[AIOnboardingModal][assignDocumentsToEntitySuggestion] documentIDToType',
    documentIDToType
  );

  const [singleUseDocs, multiUseDocs] = partition(
    Object.keys(documentIDToSuggestionIDs),
    (docID) => documentIDToSuggestionIDs[docID]!.length === 1
  );

  const suggestionIDToDocAssignments: Record<string, string[]> = {};

  // Assign single-use documents
  entities.forEach((e) => {
    if (!e._suggestionID) return;
    const docs = getAssociatedDocumentsForSuggestionID(
      e._suggestionID,
      suggestionsByID
    );
    suggestionIDToDocAssignments[e._suggestionID] = docs
      .map((d) => d.docID)
      .filter((docID) => singleUseDocs.includes(docID));
  });

  // Keep track of assigned documents to avoid duplicates
  const assignedDocuments = new Set<string>(singleUseDocs);

  // Go through multi-use documents and try to assign any un-assigned docs an
  // entity suggestion based on document type and entity type
  multiUseDocs.forEach((docID) => {
    if (assignedDocuments.has(docID)) return;

    diagnostics.debug(
      '[AIOnboardingModal][assignDocumentsToEntitySuggestion] trying to assign multiUseDoc to entity suggestion...',
      { docID }
    );

    const docType = documentIDToType[docID];
    const matchingEntityTypes = getMatchingEntityTypesForDocumentType(docType);
    if (isEmpty(matchingEntityTypes)) {
      diagnostics.warn(
        '[AIOnboardingModal][assignDocumentsToEntitySuggestion] no matching suggested entity types for document',
        { docID, docType }
      );
      return;
    }

    // Get the suggestionIDs associated with this document that match the entity type
    const suggestionIDs = documentIDToSuggestionIDs[docID]!.sort().filter(
      (suggestionID) => {
        const entityType = entities.find(
          (e) => e._suggestionID === suggestionID
        )?.entityType;
        return matchingEntityTypes.includes(entityType as EntityType);
      }
    );

    // If there's an entity suggestion that hasn't been assigned to any documents yet,
    // prioritize assigning that one.
    const suggestionWithoutAssignedDocs = first(
      suggestionIDs.filter(
        (suggestionID) =>
          !suggestionIDToDocAssignments[suggestionID] ||
          suggestionIDToDocAssignments[suggestionID]!.length === 0
      )
    );
    if (suggestionWithoutAssignedDocs) {
      suggestionIDToDocAssignments[suggestionWithoutAssignedDocs] = [docID];
      assignedDocuments.add(docID);
      diagnostics.debug(
        '[AIOnboardingModal][assignDocumentsToEntitySuggestion] assigned document to an unassigned entity suggestion',
        { docID, suggestionID: suggestionWithoutAssignedDocs }
      );
      return;
    }

    // Otherwise, just assign it to the first suggestion
    const firstSuggestion = first(suggestionIDs);
    if (firstSuggestion) {
      suggestionIDToDocAssignments[firstSuggestion]!.push(docID);
      assignedDocuments.add(docID);
      diagnostics.debug(
        '[AIOnboardingModal][assignDocumentsToEntitySuggestion] assigned document to the first matching entity suggestion',
        { docID, suggestionID: firstSuggestion }
      );
      return;
    }

    diagnostics.warn(
      '[AIOnboardingModal][assignDocumentsToEntitySuggestion] could not assign document to entity suggestion',
      { docID, docType }
    );
  });

  return suggestionIDToDocAssignments;
}

/**
 * If there's "ambiguity" when assigning documents to entities, we can use the
 * document type to help narrow down the entity types that are relevant.
 * @param docType
 */
function getMatchingEntityTypesForDocumentType(
  docType?: DocumentType
): readonly EntityType[] {
  if (!docType) return [];
  switch (docType) {
    case DocumentType.SignedTrustDocument:
      return TRUST_ENTITY_TYPES;
    case DocumentType.FincenFiling:
    case DocumentType.TaxIdConfirmation:
    case DocumentType.OperatingAgreement:
    case DocumentType.LlcAgreement:
    case DocumentType.PartnershipAgreement:
    case DocumentType.ShareholdersAgreement:
    case DocumentType.AssignmentOfInterest:
      return BUSINESS_ENTITY_TYPES;
    default:
      return [];
  }
}
