import Decimal from 'decimal.js';
import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import {
  EntityGstStatus,
  EntityInEstateStatus,
  NonTrustEntityTaxStatus,
  TrustTaxStatus,
} from '@/types/schema';

import { ContextPrimaryClient } from '../../household/contexts/householdDetails.context';
import { GetDispositiveProvisions_EstateWaterfallFragment } from '../DispositiveProvisionsForm/graphql/GetDispositiveProvisions.generated';
import { DispositiveProvisions_DispositionScenarioFragment } from '../graphql/DispositiveProvisions.fragments.generated';

export type DispositiveProvisionsFormVariant =
  | 'manageDispositions'
  | 'editEntity';

export type DispositiveProvisionsCalculateValues = [
  boolean,
  Dispatch<SetStateAction<boolean>>,
];
export interface DispositiveProvisionsContext {
  id: string;
  primaryClients: ContextPrimaryClient[];
  /** `afterDeath` only applies in waterfall views.  If present, it will trigger a different list display. */
  firstGrantorDeathId: string | null;
  // We expect to either have scenarios or dispositive provisions, but not both
  dispositionScenarios?: DispositiveProvisions_DispositionScenarioFragment[];
  totalMarketValue: Decimal;
  householdId: string;
  isTwoClientHousehold: boolean;
  dyingPrimaryClientId: string | null;
  estateStatus: EntityInEstateStatus | null;
  taxStatus: TrustTaxStatus | NonTrustEntityTaxStatus | null;
  gstStatus: EntityGstStatus | null;
  entityDisplayName: string;
  isTestamentaryEntity: boolean;
  // Used to determine whether to show the initial empty state in the dispositive provisions form
  hasTestamentaryEntityBeenReviewed: boolean;
  isClientProfile: boolean;
  clientProfileOrEntityOrTestamentaryEntityId: string;
  allClientIds: string[];
  waterfall?: GetDispositiveProvisions_EstateWaterfallFragment;
  isOnHypotheticalWaterfall: boolean;
  formVariant: DispositiveProvisionsFormVariant;
  showCalculatedDispositions: boolean;
  setShowCalculatedDispositions: (showCalculatedValues: boolean) => void;
  /**
   * In cases where a user is on the dispositive provision template modal, then opens the dispositive provision
   * form, do not allow the user to then open a second dispositive provision template modal.
   */
  isFromTemplateEditor?: boolean;
}

export const DispositiveProvisionsContext =
  createContext<DispositiveProvisionsContext>(
    {} as DispositiveProvisionsContext
  );

export const useDispositiveProvisionsContext = () =>
  useGuardedContext(
    DispositiveProvisionsContext,
    'DispositiveProvisionsContext'
  );

export const useUnguardedDispositiveProvisionsContext = () =>
  useContext(DispositiveProvisionsContext);
