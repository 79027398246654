import Decimal from 'decimal.js';

import { TRUST_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import {
  grantorsFieldDefaultValue_MultipleGrantor,
  grantorsFieldDefaultValue_SingleGrantor,
  GrantorsFieldTrustData,
  GrantorsFieldTypes_MultipleGrantor,
  GrantorsFieldTypes_SingleGrantor,
  GrantorsFieldVariantType,
} from '@/modules/entities/principals/GrantorsField/GrantorsField.types';
import { EntityType } from '@/modules/entities/types/EntityType';

export function getGrantorsFieldVariant(
  entityType: EntityType
): GrantorsFieldVariantType | undefined {
  switch (entityType) {
    case 'qprt':
      return 'MultipleGrantorWithPercent';
    case 'slat':
    case 'grat':
      return 'SingleGrantor';
    case 'ilit':
    case 'revocable-trust':
    case 'irrevocable-trust':
    case 'clt':
    case 'crt':
      return 'default';
  }

  return undefined;
}

export function getGrantorsFieldDefaultData(
  variant: GrantorsFieldVariantType | undefined
):
  | GrantorsFieldTypes_MultipleGrantor
  | GrantorsFieldTypes_SingleGrantor
  | Record<string, never> {
  if (!variant) {
    // Empty object, for the default value if the entity does not have grantors (instead has owners/donors)
    return {};
  }

  if (variant === 'SingleGrantor') {
    return grantorsFieldDefaultValue_SingleGrantor;
  }

  return grantorsFieldDefaultValue_MultipleGrantor;
}

export function grantorsFieldTrustDataToForm(
  entityType: EntityType,
  trustData: GrantorsFieldTrustData | undefined | null
):
  | GrantorsFieldTypes_MultipleGrantor
  | GrantorsFieldTypes_SingleGrantor
  | Record<string, never> {
  if (!TRUST_ENTITY_TYPES.includes(entityType) || !trustData) {
    // No grantors field for non-trust entities
    return {};
  }

  if (entityType === 'grat') {
    return {
      grantor: {
        clientProfileId: trustData.gratGrantor?.individual?.id ?? '',
      },
      _hasNonClientGrantor: false,
    };
  }

  if (entityType === 'slat') {
    return {
      grantor: {
        clientProfileId: trustData.slatGrantor?.individual?.id ?? '',
      },
      _hasNonClientGrantor: false,
    };
  }

  const grantors =
    trustData.grantors?.map((grantor) => {
      return {
        clientProfileId: grantor?.individual?.id ?? '',
        percentage: grantor?.ownershipPercentage ?? null,
      };
    }) ?? [];

  return {
    grantors,
    _grantorAttributionSum: new Decimal(0),
    _hasNonClientGrantor: false,
  };
}
