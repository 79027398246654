import { PopperContent } from '@/components/poppers/PopperContent';

export function ControllingPersonsOrSignatories() {
  return (
    <PopperContent
      body={
        'The individual(s) or organization(s) that will oversee the management of this entity.'
      }
    />
  );
}
