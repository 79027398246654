import { getYear } from 'date-fns';
import Decimal from 'decimal.js';

import { EstateWaterfallAssumptionFields } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/types';

export const DEFAULT_ASSUMPTIONS: EstateWaterfallAssumptionFields = {
  firstGrantorDeathYear: getYear(new Date()),
  secondGrantorDeathYear: getYear(new Date()),
  assetGrowthReturn: new Decimal(0.0),
  exemptionGrowthRate: new Decimal(2.0),
  willExemptionSunset: true,
  useCustomGlobalGrowthRate: false,
};
