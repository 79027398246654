import { Box, MenuItem, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { File06Icon } from '@/components/icons/File06Icon';
import { StyledTableCell } from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { GetWaterfallSummary_EstateWaterfallVizNodeFragment } from '@/modules/estateWaterfall/graphql/GetWaterfallSummary.generated';
import { formatCurrency } from '@/utils/formatting/currency';

import {
  canNodeEditDispositions,
  isNodeEditable,
} from '../EstateWaterfallSummaryTileSummaryPanel.utils';
import {
  EstateWaterfallSummaryTileSummaryPanelNode,
  EstateWaterfallSummaryTileSummaryPanelNodeWithVizNode,
} from '../types';

interface WaterfallSummaryRowProps {
  node: EstateWaterfallSummaryTileSummaryPanelNode;
  onEdit: (node: EstateWaterfallSummaryTileSummaryPanelNodeWithVizNode) => void;
  onManageDispositions: (
    node: EstateWaterfallSummaryTileSummaryPanelNodeWithVizNode
  ) => void;
  primaryClientIds: Set<string>;
}

function getEditVariantForNode(
  node: GetWaterfallSummary_EstateWaterfallVizNodeFragment
): { label: string; icon: ReactNode } {
  switch (node.node.__typename) {
    case 'ClientProfile':
      return { label: 'Edit individual', icon: <Edit02Icon size={20} /> };
    case 'TestamentaryEntity':
      return { label: 'Edit entity', icon: <Edit02Icon size={20} /> };
    case 'Entity':
      return { label: 'Go to entity', icon: <File06Icon size={20} /> };
    case 'ClientOrganization':
      return { label: 'Edit organization', icon: <Edit02Icon size={20} /> };
    default:
      throw new Error(`Unsupported node type: ${node.node.__typename}`);
  }
}

export function WaterfallSummaryRow({
  node,
  onEdit,
  onManageDispositions,
  primaryClientIds,
}: WaterfallSummaryRowProps) {
  const canEdit = node.vizNode && isNodeEditable(node.vizNode);
  const canEditDispositions =
    node.vizNode && canNodeEditDispositions(node.vizNode, primaryClientIds);

  return (
    <StyledTableRow>
      <StyledTableCell>
        <Typography variant="h5">{node.name}</Typography>
        {node.subtitle && (
          <Typography variant="subtitle2">{node.subtitle}</Typography>
        )}
      </StyledTableCell>
      <StyledTableCell align="right">
        <Typography color={node.value.lessThan(0) ? 'error' : undefined}>
          {formatCurrency(node.value, {
            currencySign: 'accounting',
            maximumFractionDigits: 0,
          })}
        </Typography>
      </StyledTableCell>
      {node.vizNode && (
        <StyledTableCell align="right">
          <ContextMenuButton variant="transparent">
            {canEdit && (
              <MenuItem
                onClick={() =>
                  onEdit(
                    node as EstateWaterfallSummaryTileSummaryPanelNodeWithVizNode
                  )
                }
              >
                <Box display="flex" alignItems="center" gap={0.5}>
                  {getEditVariantForNode(node.vizNode).icon}
                  {getEditVariantForNode(node.vizNode).label}
                </Box>
              </MenuItem>
            )}
            {canEditDispositions && (
              <MenuItem
                onClick={() =>
                  onManageDispositions(
                    node as EstateWaterfallSummaryTileSummaryPanelNodeWithVizNode
                  )
                }
              >
                Manage dispositions
              </MenuItem>
            )}
          </ContextMenuButton>
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
}
