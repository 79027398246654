import { compact, omit, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useIntegrationAssetClassesQuery } from '@/modules/assetCategories/graphql/AssetCategories.generated';
import { IntegrationAssetClassProvider } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import {
  AssetCategoriesForm,
  AssetCategoriesFormPaths,
} from '../AdminAssetCategoriesConfigurationPage.types';

function getProviderDisplayName(
  provider: IntegrationAssetClassProvider
): string {
  switch (provider) {
    case IntegrationAssetClassProvider.Addepar:
      return 'Addepar';
    case IntegrationAssetClassProvider.BlackDiamond:
      return 'Black Diamond';
    default:
      throw new UnreachableError({
        case: provider,
        message: `Unknown integration asset class provider: ${provider}`,
      });
  }
}

interface UseAvailableIntegrationCategoriesParams {
  currentRowId: string;
}

export function useAvailableIntegrationCategories({
  currentRowId,
}: UseAvailableIntegrationCategoriesParams) {
  const { createErrorFeedback } = useFeedback();
  const { data, ...queryProps } = useIntegrationAssetClassesQuery({
    onError: createErrorFeedback(
      'Failed to fetch integration categories. Please refresh the page to try again.'
    ),
  });

  const alreadyAssignedIntegrationCategories =
    useAssignedIntegrationCategoriesInForm({ currentRowId });

  const iacs = getNodes(data?.integrationAssetClasses);
  const hasMultipleProviders = uniqBy(iacs, 'provider').length > 1;

  const options = iacs.map((iac) => {
    const display = compact([
      iac.integrationName,
      hasMultipleProviders && `(${getProviderDisplayName(iac.provider)})`,
    ]).join(' ');
    return {
      display,
      value: iac.id,
      disabled: alreadyAssignedIntegrationCategories.includes(iac.id),
    };
  });

  return {
    options,
    ...queryProps,
  };
}

interface UseAssignedIntegrationCategoriesInFormParams {
  currentRowId: string;
}

function useAssignedIntegrationCategoriesInForm({
  currentRowId,
}: UseAssignedIntegrationCategoriesInFormParams) {
  const { control } = useFormContext<AssetCategoriesForm>();
  const assetCategoriesById = useWatch({
    control,
    name: 'assetCategoriesById' as const satisfies AssetCategoriesFormPaths,
  });

  // exclude the current row's integration categories from the list of all integration categories,
  // since we don't want to disable them.
  return useMemo(() => {
    const otherAssetCategories = omit<typeof assetCategoriesById>(
      assetCategoriesById,
      currentRowId
    );
    return Object.values(otherAssetCategories).reduce<string[]>(
      (acc, assetCategory) => {
        if (!assetCategory) return acc;
        return [...acc, ...assetCategory.integrationCategoryIds];
      },
      [] as string[]
    );
  }, [assetCategoriesById, currentRowId]);
}
