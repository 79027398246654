import {
  AccessParameterKind,
  AugmentedCreateBeneficiaryInput,
  SummaryBeneficiaryKindv2,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { BeneficiaryOptionv2 } from './types';

export function generateCreateEntityBeneficiaryMutationInputsv2(
  beneficiaryOptions: BeneficiaryOptionv2[],
  householdId: string
) {
  const res: AugmentedCreateBeneficiaryInput[] = [];

  beneficiaryOptions.forEach((beneficiary) => {
    // if the beneficiary isn't selected, we don't want to persist it.
    if (!beneficiary.selected) return;

    const beneficiaryDistributionSharedProperties = {
      withAccessParameters: [
        {
          create: {
            kind: AccessParameterKind.Percentage,
            percentage: beneficiary.ownershipPercentageDecimal,
            householdID: householdId,
          },
        },
      ],
    };

    if (beneficiary.type === SummaryBeneficiaryKindv2.ClientProfile) {
      const input: AugmentedCreateBeneficiaryInput = {
        create: {
          individualID: beneficiary.beneficiaryTypeId,
        },
        ...beneficiaryDistributionSharedProperties,
      };
      res.push(input);
    } else if (beneficiary.type === SummaryBeneficiaryKindv2.Entity) {
      const input: AugmentedCreateBeneficiaryInput = {
        create: {
          entityID: beneficiary.beneficiaryTypeId,
        },
        ...beneficiaryDistributionSharedProperties,
      };
      res.push(input);
    } else if (beneficiary.type === SummaryBeneficiaryKindv2.Organization) {
      const input: AugmentedCreateBeneficiaryInput = {
        create: {
          organizationID: beneficiary.beneficiaryTypeId,
        },
        ...beneficiaryDistributionSharedProperties,
      };
      res.push(input);
    } else {
      throw new UnreachableError({
        case: beneficiary.type,
        message: 'Unrecognized beneficiary type',
      });
    }
  });

  return res;
}
