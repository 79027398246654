import { Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { DesignerLayoutGrid } from '@/components/layout/DesignerLayoutGrid/DesignerLayoutGrid';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { FinalizeProposalModal } from '@/modules/gifting/proposal/designer/form/components/FinalizeProposalModal/FinalizeProposalModal';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import {
  useCharitableTrustDesignerContext,
  useIsCRT,
} from '../CharitableTrustDesignerContext';
import { useUpdateCharitableTrustProposalMutation } from '../graphql/CharitableTrustProposalMutations.generated';
import { DEFAULT_CHARITABLE_TRUST_DESIGNER_ANALYSIS_FORM } from './CharitableTrustDesignerAnalysis.constants';
import { CharitableTrustDesignerAnalysisForm } from './CharitableTrustDesignerAnalysis.types';
import {
  mapAnalysisDataToClientProfileUpdatePayload,
  mapAnalysisDataToMutationPayload,
  mapDataToAnalysisForm,
} from './CharitableTrustDesignerAnalysis.utils';
import { CharitableTrustDesignerAnalysisIllustration } from './CharitableTrustDesignerAnalysisIllustration';
import { CharitableTrustDesignerAnalysisTrustStructure } from './CharitableTrustDesignerAnalysisTrustStructure';
import {
  CharitableTrustDesignerAnalysisDataDocument,
  useCharitableTrustDesignerAnalysisDataQuery,
} from './graphql/CharitableTrustDesignerAnalysisData.generated';

export function CharitableTrustDesignerAnalysis() {
  const navigate = useNavigate();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const { rate7520 } = useIRSConstants();
  const { proposalId, householdId, variant } =
    useCharitableTrustDesignerContext();

  const isCRT = useIsCRT();
  const [
    { isModalOpen: isShareModalOpen },
    { openModal: openShareModal, closeModal: closeShareModal },
  ] = useModalState();

  const formMethods = useForm<CharitableTrustDesignerAnalysisForm>({
    defaultValues: DEFAULT_CHARITABLE_TRUST_DESIGNER_ANALYSIS_FORM,
  });

  const {
    reset,
    formState: { isSubmitting: formIsSubmitting },
    handleSubmit,
    getValues,
  } = formMethods;

  const { data: loadedData, loading: loadingFormData } =
    useCharitableTrustDesignerAnalysisDataQuery({
      // necessary to update form after saving the modal
      // https://github.com/apollographql/apollo-client/issues/11306
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      variables: {
        proposalId,
      },
      onError: (error) => {
        showFeedback(
          'Could not load proposal data.  Please refresh the page and try again.'
        );
        reportError(`Caught error when loading ${proposalId}`, error);
      },
      onCompleted: (data) => {
        reset(mapDataToAnalysisForm(data, rate7520, getValues()));
      },
    });

  const [updateCharitableTrustProposalMutation] =
    useUpdateCharitableTrustProposalMutation({
      onError: (error) => {
        showFeedback('Could not update proposal data.  Please try again.');
        reportError(`Caught error when updating ${proposalId}`, error);
      },
      onCompleted: () => {
        showFeedback('Proposal updated', { variant: 'success' });
      },
    });

  const handleSave = handleSubmit(async (formData) => {
    const input = mapAnalysisDataToMutationPayload({
      formData,
      loadedData,
      isCRT,
    });
    const donorProfiles = mapAnalysisDataToClientProfileUpdatePayload({
      formData,
      loadedData,
    });
    const mutationResult = await updateCharitableTrustProposalMutation({
      variables: {
        input,
        updateDonorProfiles: true,
        householdID: householdId,
        donorProfiles,
      },
      refetchQueries: [CharitableTrustDesignerAnalysisDataDocument],
    });
    openShareModal();
    return mutationResult;
  });

  const isDisabled = formIsSubmitting || loadingFormData;

  return (
    <FormProvider {...formMethods}>
      <FormFieldsDisabledProvider isDisabled={isDisabled}>
        <DesignerLayoutGrid
          LeftColumn={<CharitableTrustDesignerAnalysisTrustStructure />}
          RightColumn={<CharitableTrustDesignerAnalysisIllustration />}
          footerLeftAction={
            <Button
              variant="secondary"
              size="md"
              onClick={() =>
                navigate(
                  getCompletePathFromRouteKey(
                    ROUTE_KEYS.HOUSEHOLD_DETAILS_PROPOSALS,
                    {
                      householdId,
                    }
                  )
                )
              }
            >
              Cancel
            </Button>
          }
          footerRightAction={
            <>
              <FinalizeProposalModal<CharitableTrustDesignerAnalysisForm>
                householdId={householdId}
                proposalId={proposalId}
                isOpen={isShareModalOpen}
                onClose={closeShareModal}
                onSave={handleSave}
              />
              <Stack direction="row" spacing={2}>
                <Button
                  variant="transparent"
                  size="md"
                  onClick={() =>
                    navigate(
                      getCompletePathFromRouteKey(
                        ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION,
                        { householdId, proposalId, variant }
                      )
                    )
                  }
                >
                  Previous
                </Button>
                <Button variant="primary" size="md" onClick={handleSave}>
                  Save & share
                </Button>
              </Stack>
            </>
          }
        />
      </FormFieldsDisabledProvider>
    </FormProvider>
  );
}
