import { Stack, Typography } from '@mui/material';
import { useMemo, useState } from 'react';
import {
  FieldArrayWithId,
  Path,
  UseFieldArrayInsert,
  UseFieldArrayMove,
  UseFieldArrayRemove,
  useWatch,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { iconSizeByButtonSize } from '@/components/form/baseInputs/Button/styles';
import { ArrowDownIcon } from '@/components/icons/ArrowDownIcon';
import { ArrowUpIcon } from '@/components/icons/ArrowUpIcon';
import { Copy07Icon } from '@/components/icons/Copy07Icon';
import { DotsVerticalIcon } from '@/components/icons/DotsVerticalIcon';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { DeleteMenuItemWithConfirmationModal } from '@/components/poppers/MenuPopper/DeleteMenuItemWithConfirmationModal';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useFormContext } from '@/components/react-hook-form';
import { GiftDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';

import { GiftDesignerModelScenariosFormShape } from '../../GiftDesignerModelScenariosForm.types';
import { MAX_GIFT_SCENARIOS_COUNT } from './GiftScenarios';

interface GiftScenarioProps {
  scenario: FieldArrayWithId<
    GiftDesignerModelScenariosFormShape,
    'scenarios',
    '_id'
  >;
  remove: UseFieldArrayRemove;
  move: UseFieldArrayMove;
  duplicate: UseFieldArrayInsert<
    GiftDesignerModelScenariosFormShape,
    'scenarios'
  >;
  idx: number;
  proposalId: string;
  householdId: string;
}
export function GiftScenarioCardTitle({
  scenario,
  remove,
  move,
  idx,
  proposalId,
  householdId,
  duplicate,
}: GiftScenarioProps) {
  const navigate = useNavigate();
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scenarios = useWatch({
    control,
    name: 'scenarios' as const satisfies Path<GiftDesignerModelScenariosFormShape>,
    defaultValue: [],
  });

  const currentScenarioName = useMemo(() => {
    const currentScenario = scenarios[idx];

    if (!currentScenario) {
      return null;
    }

    return currentScenario.name;
  }, [idx, scenarios]);

  const isNoGiftingScenario =
    'isNoGiftingScenario' in scenario && scenario.isNoGiftingScenario;

  const numScenarios = scenarios.length;

  const scenarioLabelText = useMemo(() => {
    if (isNoGiftingScenario) {
      return 'Baseline';
    }

    if (currentScenarioName) {
      return currentScenarioName;
    }

    return 'Enter a scenario name...';
  }, [currentScenarioName, isNoGiftingScenario]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      pb={3}
    >
      <Stack spacing={0.5}>
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: FONT_WEIGHTS.bold,
          }}
        >
          Scenario {idx + 1}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: FONT_WEIGHTS.bold,
            color: COLORS.NAVY[600],
          }}
        >
          {scenarioLabelText}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        {isNoGiftingScenario && (
          <Button
            variant="transparent"
            size="sm"
            square
            onClick={() => {
              navigate(
                getCompletePathFromRouteKey(
                  ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER_BASIC_INFORMATION,
                  {
                    householdId,
                    proposalId,
                    designerStage: GiftDesignerStages.BASIC_INFORMATION,
                  }
                )
              );
            }}
          >
            <Edit02Icon size={iconSizeByButtonSize.sm} />
          </Button>
        )}
        <ButtonWithPopover
          size="sm"
          variant="transparent"
          popperVariant="menuBelow"
          onClick={() => setIsMenuOpen(true)}
          isOpen={isMenuOpen}
          label={<DotsVerticalIcon size={iconSizeByButtonSize.sm} />}
          hideChevron={true}
          data-testid="GiftScenarioCardTitle-menuButton"
          square
          highlightWhenOpen
        >
          <MenuItem
            icon={<ArrowUpIcon size={18} />}
            label="Move up"
            onClick={() => {
              setIsMenuOpen(false);
              move(idx, idx - 1);
            }}
            disabled={idx === 0}
          />
          <MenuItem
            icon={<ArrowDownIcon size={18} />}
            label="Move down"
            onClick={() => {
              setIsMenuOpen(false);
              move(idx, idx + 1);
            }}
            disabled={idx === numScenarios - 1}
          />
          <MenuItem
            icon={<Copy07Icon size={18} />}
            label="Duplicate scenario"
            onClick={() => {
              setIsMenuOpen(false);
              // Guaranteed to exist because we are duplicating an existing scenario
              // and disabling the button if the scenario doesn't exist at this index.
              const scenario = scenarios[idx]!;
              duplicate(idx + 1, {
                ...scenario,
                name: `${scenario.name} (copy)`,
              });
            }}
            muiMenuItemProps={{
              divider: true,
            }}
            // Disable the duplicate button if we are at the max number of scenarios
            // or if we are on the special default scenario.
            disabled={
              !scenarios[idx] ||
              numScenarios === MAX_GIFT_SCENARIOS_COUNT ||
              isNoGiftingScenario
            }
          />
          <DeleteMenuItemWithConfirmationModal
            label="Delete scenario"
            modalProps={{
              heading: 'Delete scenario',
              children: (
                <>
                  Are you sure you’d like to delete this scenario? This cannot
                  be undone.
                </>
              ),
            }}
            onConfirmDelete={() => {
              remove(idx);
              setIsMenuOpen(false);
            }}
          />
        </ButtonWithPopover>
      </Stack>
    </Stack>
  );
}
