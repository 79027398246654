import { Typography } from '@mui/material';
import React from 'react';

import { COLORS } from '@/styles/tokens/colors';

import { StyledTableCell } from './DisplayTable';
import { StyledTableRow } from './StyledTableRow';

export interface DisplayTableSummaryRowProps {
  title: string;
  values: string[];
}

export function DisplayTableSummaryRow({
  title,
  values,
}: DisplayTableSummaryRowProps) {
  return (
    <StyledTableRow sx={{ borderTop: `3px solid ${COLORS.NAVY[600]}` }}>
      <StyledTableCell>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
      </StyledTableCell>
      {values.map((value, index) => (
        <StyledTableCell
          key={`summary-${index}-${value}`}
          sx={{ fontWeight: 'bold' }}
          align="right"
        >
          {value}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
}
