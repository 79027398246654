import {
  ClientProfileBasicFragment,
  RelationshipFragment,
} from '@/modules/clientProfiles/graphql/ClientProfile.generated';

import { ClientProfileRelationshipFormShape } from '../../../clientProfiles/ClientProfile.types';

export function getPossibleRelationships(
  currentClientProfileId: string,
  relationships: RelationshipFragment[],
  possiblePrimaryClients: ClientProfileBasicFragment[]
): ClientProfileRelationshipFormShape[] {
  return possiblePrimaryClients.flatMap((client) => {
    if (client.id === currentClientProfileId) return [];
    const existingRelationship = relationships.find(
      (relationship) => relationship.toClientProfile.id === client.id
    );

    if (existingRelationship) {
      return {
        relationshipId: existingRelationship.id,
        display: client.displayName,
        toClientProfileId: existingRelationship.toClientProfile.id,
        type: existingRelationship.type,
      };
    }

    return {
      toClientProfileId: client.id,
      display: client.displayName,
      type: '',
      relationshipId: null,
    };
  });
}
