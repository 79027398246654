import { groupBy, keyBy } from 'lodash';

import { SectionLabelProps } from '@/components/diagrams/FlowChart/nodes/SectionLabelNode';
import { isTileNode } from '@/components/diagrams/FlowChart/utils/nodes';

import { GROWTH_PROFILES_SENTINEL } from '../components/GrowthProfilesButton';
import { HYPOTHETICAL_TRANSFERS_SUMMARY_SENTINEL } from '../components/HypotheticalTransfersButton';
import { isHypotheticalWaterfall } from '../EstateWaterfall.utils';
import { EstateWaterfallDerivedData, EstateWaterfallState } from '../types';

export function applyDerivedDataToState(
  state: EstateWaterfallState
): EstateWaterfallState {
  const isHypothetical = isHypotheticalWaterfall(state.waterfall);

  const firstPrimaryClientDeathId = state.waterfall.firstGrantorDeath.id;

  const nodesById = keyBy(state.nodes, (n) => n.id);
  const edgesById = keyBy(state.edges, (n) => n.id);

  const nodesByType = groupBy(
    state.nodes,
    (n) => n.type
  ) as EstateWaterfallDerivedData['nodesByType'];

  const nodesBySectionId = state.nodes.reduce(
    (acc, n) => {
      if (isTileNode(n) && n.data.sectionLabelId) {
        acc[n.data.sectionLabelId] = acc[n.data.sectionLabelId] ?? [];
        acc[n.data.sectionLabelId]?.push(n);
      }
      return acc;
    },
    {} as EstateWaterfallDerivedData['nodesBySectionId']
  );

  let summaryNode: EstateWaterfallDerivedData['summaryNode'] = null;
  if (
    state.summaryNodeId &&
    [
      HYPOTHETICAL_TRANSFERS_SUMMARY_SENTINEL,
      GROWTH_PROFILES_SENTINEL,
    ].includes(state.summaryNodeId)
  ) {
    summaryNode = {
      node: {
        id: state.summaryNodeId,
        position: { x: 0, y: 0 },

        // since this node doesn't link back to an entity/individual/organizaiton, and isn't clickable,
        // just stub out the data and cast to satisfy the TS check
        data: {} as SectionLabelProps,
      },
      data: null!,
      categorizationType: null!,
    };
  } else if (state.summaryNodeId) {
    summaryNode =
      state.waterfallGraph.getNodesById()[state.summaryNodeId] || null;
  }

  return {
    ...state,
    derivedData: {
      isSummaryPanelOpen: !!state.summaryNodeId,
      nodesById,
      edgesById,
      isHypothetical,
      firstPrimaryClientDeathId,
      nodesByType,
      nodesBySectionId,
      summaryNode,
    },
  };
}
