import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import { LoadingBanner } from '@/components/notifications/LoadingBanner/LoadingBanner';
import { useAssetProviderDisplayName } from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import { AsyncJobStatus_AsyncJobFragment } from '@/modules/asyncJobs/graphql/AsyncJobs.generated';
import { usePollForJobCompletion } from '@/modules/asyncJobs/hooks/usePollForJobCompletion';

export interface EntitiesIngestBannerProps {
  activeJobs: AsyncJobStatus_AsyncJobFragment[];
  onComplete?: () => void;
}

export function EntitiesIngestBanner({
  activeJobs: initialJobs,
  onComplete,
}: EntitiesIngestBannerProps) {
  const haveActiveJobs = initialJobs.length > 0;
  const [showBanner, setShowBanner] = useState(haveActiveJobs);
  const integrationProviderName = useAssetProviderDisplayName();

  useEffect(() => {
    const haveActiveJobs = !isEmpty(initialJobs);
    if (haveActiveJobs != showBanner) return;
    setShowBanner(haveActiveJobs);
  }, [initialJobs, showBanner]);

  // it's fine to just poll for one job, since they all resolve at the same time
  const firstJob = initialJobs?.[0] ?? null;
  usePollForJobCompletion(firstJob, {
    skip: !showBanner,
    onJobComplete: () => {
      setShowBanner(false);
      onComplete?.();
    },
  });

  if (!showBanner) return null;
  return (
    <LoadingBanner
      content={
        <Typography variant="h4" component="div">
          Importing entities from {integrationProviderName}
        </Typography>
      }
    />
  );
}
