import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';

import {
  GenericNonTrustEntityTaxStatus,
  GenericTrustTaxStatus,
  GSTStatus,
} from './TaxStatusSubform.types';

export const TAX_STATUS_SUBFORM = 'taxStatusSubform' as const;

export const GRANTOR_TRUST = 'grantor-trust' as const;
export const NON_GRANTOR_TRUST = 'non-grantor-trust' as const;
export const NON_TAXABLE_TRUST = 'non-taxable-trust' as const;

export const TAXABLE_ENTITY = 'taxable-entity' as const;
export const NON_TAXABLE_ENTITY = 'non-taxable-entity' as const;

export const GST_EXEMPT = 'gst-exempt' as const;
export const GST_NON_EXEMPT = 'gst-non-exempt' as const;
export const MIXED_GST = 'mixed-gst' as const;

export const IN_ESTATE = 'in-estate' as const;
export const OUT_OF_ESTATE = 'out-of-estate' as const;

export const TRUST_TAX_STATUS_ITEMS: SelectInputOption<GenericTrustTaxStatus>[] =
  [
    { display: 'Grantor trust', value: GRANTOR_TRUST },
    { display: 'Non-grantor trust', value: NON_GRANTOR_TRUST },
    { display: 'Non-taxable trust', value: NON_TAXABLE_TRUST },
  ];

export const PERSONAL_ACCOUNT_TAX_STATUS_ITEMS: SelectInputOption<GenericNonTrustEntityTaxStatus>[] =
  [
    { display: 'Taxable entity', value: TAXABLE_ENTITY },
    { display: 'Non-taxable entity', value: NON_TAXABLE_ENTITY },
  ];

export const GST_STATUS_ITEMS: SelectInputOption<GSTStatus>[] = [
  { display: 'GST exempt', value: 'gst-exempt' },
  { display: 'GST non-exempt', value: 'gst-non-exempt' },
  { display: 'Mixed GST', value: 'mixed-gst' },
];
