import { Stack, Typography } from '@mui/material';
import { first, noop } from 'lodash';
import { useState } from 'react';

import { Building07Icon } from '@/components/icons/Building07Icon';
import { FileShield02Icon } from '@/components/icons/FileShield02Icon';
import { User01Icon } from '@/components/icons/User01Icon';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { InfoTooltip } from '@/components/poppers/Tooltip/InfoTooltip';
import { ClientOrganizationModal } from '@/modules/clientOrganizations/ClientOrganizationModal';
import { ClientProfileModal } from '@/modules/clientProfiles/ClientProfileForm/ClientProfileModal';
import { AddNewAnythingActionCard } from '@/modules/common/AddNewAnythingModal/AddNewAnythingActionCard';
import { TestamentaryEntityModal } from '@/modules/entities/testamentaryEntities/TestamentaryEntityForm/TestamentaryEntityModal';
import { UnreachableError } from '@/utils/errors';

import { EntityDocumentViewerDocument } from '../documents/components/HouseholdDocumentsViewer/graphql/EntityDocumentViewer.generated';
import { CreateEntityShortFormModal } from '../entities/EntityShortFormModal/CreateEntityShortFormModal';
import {
  DispositiveProvisionsRecipientTemplateRecipientConfiguration,
  DispositiveProvisionsRecipientTypes,
} from './DispositiveProvisionsForm/DispositiveProvisionsFormRecipients/DispositiveProvisionsRecipient.types';
import { useDispositiveProvisionsTemplateSplitScreenModalContext } from './DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.context';

interface RecipientSelectorModalProps {
  handleClose: () => void;
  isOpen: boolean;
  householdId: string;
  deadPrimaryClientId: string | null;
  deadPrimaryClientIds: string[];
  isTwoClientHousehold: boolean;
  recipientConfiguration: DispositiveProvisionsRecipientTemplateRecipientConfiguration;
}

function TestamentaryEntitiesCardDescription() {
  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography display="flex" variant="body2">
        Testamentary
      </Typography>
      <Stack
        spacing={0.5}
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography display="flex" variant="body2">
          entities
        </Typography>
        <InfoTooltip
          title={`Add an entity that will be created upon the death of the client, such as an estate or a marital trust.`}
          placement="top-start"
        />
      </Stack>
    </Stack>
  );
}

export function RecipientSelectorModal({
  handleClose,
  isOpen,
  householdId,
  deadPrimaryClientId,
  deadPrimaryClientIds,
  isTwoClientHousehold,
  recipientConfiguration,
}: RecipientSelectorModalProps) {
  const [isAddingIndividual, setIsAddingIndividual] = useState(false);
  const [isAddingTestamentaryEntity, setIsAddingTestamentaryEntity] =
    useState(false);
  const [isAddingOrganization, setIsAddingOrganization] = useState(false);
  const [isAddingEntity, setIsAddingEntity] = useState(false);
  const { isTemplateMode } =
    useDispositiveProvisionsTemplateSplitScreenModalContext();

  const { createRecipientTypes, createEntityType, forceEntityType } =
    recipientConfiguration;

  const handleAdd = (
    typeToAdd: 'individual' | 'organization' | 'testamentaryEntity' | 'entity'
  ) => {
    handleClose();
    if (typeToAdd === 'individual') {
      setIsAddingIndividual(true);
    } else if (typeToAdd === 'testamentaryEntity') {
      setIsAddingTestamentaryEntity(true);
    } else if (typeToAdd === 'organization') {
      setIsAddingOrganization(true);
    } else if (typeToAdd === 'entity') {
      setIsAddingEntity(true);
    } else {
      throw new UnreachableError({
        case: typeToAdd,
        message: `Unexpected recipient type: ${typeToAdd}`,
      });
    }
  };

  // in template mode, if there's only a single recipient type, open the corresponding modal directly
  const firstCreateRecipientType = first(createRecipientTypes);
  if (
    isOpen &&
    firstCreateRecipientType &&
    createRecipientTypes.length === 1 &&
    isTemplateMode
  ) {
    switch (firstCreateRecipientType) {
      case DispositiveProvisionsRecipientTypes.Individual:
        return (
          <ClientProfileModal
            setIsOpen={(newIsOpen) => {
              if (newIsOpen) return;
              handleClose();
            }}
            isOpen={isOpen}
            clientProfileId={null}
            householdId={householdId}
            clientProfileTypeDisplay="individual"
            forceBeneficiaryIfNoClientProfileId
            ignoredQueryDocuments={[EntityDocumentViewerDocument]}
          />
        );
      case DispositiveProvisionsRecipientTypes.TestamentaryEntity:
        return (
          <TestamentaryEntityModal
            isOpen={isOpen}
            onClose={handleClose}
            dyingPrimaryClientId={deadPrimaryClientId}
            deadClientIds={deadPrimaryClientIds}
            isTwoClientHousehold={isTwoClientHousehold}
            ignoredQueryDocuments={[EntityDocumentViewerDocument]}
            defaultEstateTaxStatus={
              recipientConfiguration.testamentaryEntityTaxSettings
            }
            defaultName={
              recipientConfiguration.defaultNewTestamentaryEntityName
            }
            defaultKind={
              recipientConfiguration.defaultNewTestamentaryEntityKind
            }
          />
        );
      case DispositiveProvisionsRecipientTypes.Entity:
        return (
          <CreateEntityShortFormModal
            householdId={householdId}
            isOpen={isOpen}
            onClose={handleClose}
            initialEntityType={createEntityType}
            forceEntityType={forceEntityType}
            initialEntityName={recipientConfiguration.defaultNewEntityName}
            onAfterCreate={noop} // don't redirect after creating an entity
            ignoredQueryDocuments={[EntityDocumentViewerDocument]}
          />
        );
      case DispositiveProvisionsRecipientTypes.Organization:
      default:
        break;
    }
  }

  return (
    <>
      {setIsAddingIndividual && (
        <ClientProfileModal
          setIsOpen={setIsAddingIndividual}
          isOpen={isAddingIndividual}
          clientProfileId={null}
          householdId={householdId}
          clientProfileTypeDisplay="individual"
          forceBeneficiaryIfNoClientProfileId
          ignoredQueryDocuments={[EntityDocumentViewerDocument]}
        />
      )}
      {isAddingTestamentaryEntity && (
        <TestamentaryEntityModal
          isOpen={isAddingTestamentaryEntity}
          onClose={() => setIsAddingTestamentaryEntity(false)}
          dyingPrimaryClientId={deadPrimaryClientId}
          deadClientIds={deadPrimaryClientIds}
          isTwoClientHousehold={isTwoClientHousehold}
          ignoredQueryDocuments={[EntityDocumentViewerDocument]}
          defaultEstateTaxStatus={
            recipientConfiguration.testamentaryEntityTaxSettings
          }
        />
      )}
      {isAddingOrganization && (
        <ClientOrganizationModal
          householdId={householdId}
          setIsOpen={setIsAddingOrganization}
          isOpen={isAddingOrganization}
          organizationId={null}
          requirement="mustBeBeneficiary"
          ignoredQueryDocuments={[EntityDocumentViewerDocument]}
        />
      )}
      {isAddingEntity && (
        <CreateEntityShortFormModal
          householdId={householdId}
          isOpen={isAddingEntity}
          onClose={() => setIsAddingEntity(false)}
          initialEntityType={createEntityType}
          forceEntityType={forceEntityType}
          onAfterCreate={noop} // don't redirect after creating an entity
          ignoredQueryDocuments={[EntityDocumentViewerDocument]}
        />
      )}
      <DialogModal
        isOpen={isOpen}
        onClose={handleClose}
        heading="What would you like to add?"
      >
        <Stack spacing={3}>
          <Stack direction="row" spacing={3}>
            {createRecipientTypes.includes(
              DispositiveProvisionsRecipientTypes.Individual
            ) && (
              <AddNewAnythingActionCard
                description="Individual"
                Icon={User01Icon}
                onClick={() => handleAdd('individual')}
              />
            )}

            {createRecipientTypes.includes(
              DispositiveProvisionsRecipientTypes.Organization
            ) && (
              <AddNewAnythingActionCard
                description="Organization"
                Icon={Building07Icon}
                onClick={() => handleAdd('organization')}
              />
            )}
            {createRecipientTypes.includes(
              DispositiveProvisionsRecipientTypes.Entity
            ) && (
              <AddNewAnythingActionCard
                description="Entity"
                Icon={FileShield02Icon}
                onClick={() => handleAdd('entity')}
              />
            )}
            {createRecipientTypes.includes(
              DispositiveProvisionsRecipientTypes.TestamentaryEntity
            ) && (
              <AddNewAnythingActionCard
                description={<TestamentaryEntitiesCardDescription />}
                Icon={FileShield02Icon}
                onClick={() => handleAdd('testamentaryEntity')}
              />
            )}
          </Stack>
        </Stack>
      </DialogModal>
    </>
  );
}
