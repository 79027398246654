import { Callout } from '@/components/notifications/Callout/Callout';
import { useFederalEstateTaxFormatted } from '@/modules/irs/useIRSConstants';

export function ProposalDisclaimer() {
  const rate = useFederalEstateTaxFormatted();
  return (
    <Callout severity="info-low" textVariant="subtitle2">
      The analysis above is for illustrative purposes only and is not a
      guarantee of future strategy performance. The analysis assumes that all
      assets remaining in the client&apos;s estate at the end of the time period
      listed above will be taxed at a federal estate tax rate of {rate}% and
      that assets moved out of the client&apos;s estate are passed to heirs free
      of estate tax. Projections do not account for any remaining gift tax
      exclusion, or state inheritance or estate taxes. The analysis also assumes
      straight-line asset growth based on the return projections specified
      above.
    </Callout>
  );
}
