import { TestamentaryEntityKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

export function testamentaryEntityKindToDisplayName(
  testamentaryEntityKind: TestamentaryEntityKind,
  grantorName?: string
): string {
  switch (testamentaryEntityKind) {
    case TestamentaryEntityKind.CharitableEntity:
      return 'Charitable entity';
    case TestamentaryEntityKind.CharitableTrust:
      return 'Charitable trust';
    case TestamentaryEntityKind.GrantorEstate:
      return `Estate of ${grantorName || 'Grantor'}`;
    case TestamentaryEntityKind.InheritedRetirementAccount:
      return 'Inherited retirement account';
    case TestamentaryEntityKind.TestamentaryTrust:
      return 'Testamentary trust or subtrust';

    default:
      throw new UnreachableError({
        case: testamentaryEntityKind,
        message: `Unknown testamentary entity kind ${testamentaryEntityKind}`,
      });
  }
}

export const CHARITABLE_TESTAMENTARY_ENTITY_KINDS: Readonly<
  TestamentaryEntityKind[]
> = [
  TestamentaryEntityKind.CharitableEntity,
  TestamentaryEntityKind.CharitableTrust,
];

export const TESTAMENTARY_ENTITY_SEARCH_PARAM = 'testamentaryEntityId' as const;
