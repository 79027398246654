import { useFormContext } from '@/components/react-hook-form';

import { Fields, Props } from '../types';
import { TermsFormBody } from './TermsFormBody';

export function UI(props: Props) {
  const { variant, subformValues } = props;
  const { control, resetField } = useFormContext<Fields>();

  if (variant === 'twoColumn') {
    return (
      <TermsFormBody
        control={control}
        subformValues={subformValues}
        resetField={resetField}
        itemWidth={6}
      />
    );
  }

  if (variant === 'oneColumn') {
    return (
      <TermsFormBody
        control={control}
        subformValues={subformValues}
        resetField={resetField}
        itemWidth={12}
      />
    );
  }

  if (variant === 'none') {
    return <></>;
  }

  return <></>;
}
