import { DispositiveProvisionTemplateKind } from '@/types/schema';

import { DraggableListItemRecipient } from '../../DispositiveProvisionsForm/DispositiveProvisionsFormRecipients/DraggableListItemRecipient';

export function DispositiveProvisionsTemplatePouroverWillPreset() {
  return (
    <DraggableListItemRecipient
      index={0}
      Draghandle={null}
      simulatedValue={null}
      // this template type doesn't allow removing its sole recipient, so disable the callback
      onRemove={null}
      defaultExpanded
      templateType={DispositiveProvisionTemplateKind.PouroverWill}
      recipientConfiguration={{
        defaultNewEntityName: 'Pourover will',
      }}
    />
  );
}
