import { useEffect, useRef, useState } from 'react';

import { InternalFlowChartProps } from '../../types';

const INITIAL_SCROLLING = true;

/**
 * @description Enables "default scrolling" by controlling "preventScrolling" prop for reactflow,
 * but allows for zoom within the reactflow pane to be controlled when the metaKey (cmd or ctrl) is being held down
 */
export function usePreventScrolling({
  preventScrolling: preventScrollingExternalProp,
  zoomOnScroll,
  zoomOnPinch,
}: InternalFlowChartProps) {
  // Don't apply this hook if a consumer wants to override any of these
  const shouldApplyInternalLogic =
    zoomOnScroll === false &&
    zoomOnPinch === false &&
    preventScrollingExternalProp === undefined;

  const [preventScrolling, setPreventScrolling] = useState(INITIAL_SCROLLING);
  const timerId = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    function handleMouseWheel(event: WheelEvent) {
      clearTimeout(timerId.current);

      // We are doing a "normal" scroll within the entire window, a.k.a not holding down the meta key cmd / ctrl
      if (!event.metaKey) {
        setPreventScrolling(false);
        // Schedule a timeout to reset our preventScrolling variable after the scroll has completed
        timerId.current = setTimeout(
          () => setPreventScrolling(INITIAL_SCROLLING),
          100
        );
      }
    }

    if (shouldApplyInternalLogic) {
      window.addEventListener('wheel', handleMouseWheel);
    }

    return () => {
      window.removeEventListener('wheel', handleMouseWheel);
      clearTimeout(timerId.current);
    };
  }, [shouldApplyInternalLogic]);

  return shouldApplyInternalLogic
    ? preventScrolling
    : preventScrollingExternalProp;
}
