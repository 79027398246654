import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { Column } from '@/components/tables/DataTable/types';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { SlideBodyWithHeader } from './SlideBodyWithHeader';

const COLUMNS: Column<CharitableDistributionTableRow>[] = [
  {
    field: 'year',
    headerName: 'Year',
    headerAlign: 'left',
  },
  {
    field: 'beginningPrincipal',
    headerName: 'Beginning principal',
    headerAlign: 'right',
    flex: 1,
    align: 'right',
  },
  {
    field: 'principalGrowth',
    headerName: 'Principal growth',
    headerAlign: 'right',
    flex: 1,
    align: 'right',
  },

  {
    field: 'incomePayment',
    headerName: 'Income payment',
    headerAlign: 'right',
    flex: 1,
    align: 'right',
  },
  {
    field: 'remainderValue',
    headerName: 'Remainder value',
    headerAlign: 'right',
    flex: 1,
    align: 'right',
  },
];

interface CharitableDistributionTableRow {
  year: number;
  beginningPrincipal: string;
  principalGrowth: string;
  incomePayment: string;
  remainderValue: string;
}

function mapProposalToDistributionTable(proposal: ProposalFragment): {
  rows: CharitableDistributionTableRow[];
  modeledReturnPercentage: Decimal;
} {
  const charitableProposal = proposal.cltProposal || proposal.crtProposal;

  if (!charitableProposal) {
    throw new Error('Could not find charitable proposal');
  }

  const {
    projectionMedium,
    preTaxReturnPercentageMedium: modeledReturnPercentage,
  } = charitableProposal;

  if (!projectionMedium || !modeledReturnPercentage) {
    throw new Error('Could not find charitable projection');
  }

  const rows = projectionMedium.yearly.map<CharitableDistributionTableRow>(
    (yearlyProjection, idx) => ({
      id: idx,
      year: yearlyProjection.year,
      beginningPrincipal: formatCurrency(yearlyProjection.beginningOfYear),
      principalGrowth: formatCurrency(yearlyProjection.growth),
      incomePayment: formatCurrency(yearlyProjection.payout),
      remainderValue: formatCurrency(yearlyProjection.remainderValue),
    })
  );

  return {
    rows,
    modeledReturnPercentage,
  };
}

export interface CharitableDistributionTableProps {
  proposal: ProposalFragment;
}

export function CharitableDistributionTable({
  proposal,
}: CharitableDistributionTableProps) {
  const { rows, modeledReturnPercentage } = useMemo(
    () => mapProposalToDistributionTable(proposal),
    [proposal]
  );

  return (
    <SlideBodyWithHeader
      header="Distribution schedule"
      rightHeaderContent={`Annual pre-tax return: ${formatPercent(
        modeledReturnPercentage,
        0
      )}%`}
    >
      <DataTable columns={COLUMNS} rows={rows} hideFooterPagination />
    </SlideBodyWithHeader>
  );
}
