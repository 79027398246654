import { Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import { Divider } from '@/components/Divider';
import { Button } from '@/components/form/baseInputs/Button';
import { ArrowLeftIcon } from '@/components/icons/ArrowLeftIcon';
import { ArrowRightIcon } from '@/components/icons/ArrowRightIcon';
import { useHouseholdDocumentsViewerContext } from '@/modules/documents/components/HouseholdDocumentsViewer/context/HouseholdDocumentsViewer.context';
import { DocumentSearchResult } from '@/modules/pdf/DocumentSearch/DocumentSearch.types';
import { COLORS } from '@/styles/tokens/colors';

interface DocumentSearchResultsProps {
  variant: 'success' | 'error';
  results?: DocumentSearchResult[] | undefined;
  resultIndex: number;
  setResultIndex: (index: number) => void;
}

export function DocumentSearchResults({
  variant,
  results,
  resultIndex,
  setResultIndex,
}: DocumentSearchResultsProps) {
  const { updateActiveDocumentId } = useHouseholdDocumentsViewerContext();

  const [displayIndex, setDisplayIndex] = useState(resultIndex + 1);

  useEffect(() => {
    setDisplayIndex(resultIndex + 1);
  }, [resultIndex]);

  const handleSetNewResult = useCallback(
    (newIndex: number) => {
      if (!results?.length) return; // For type-checks; shouldn't happen

      const currResult = results?.[resultIndex];
      const newResult = results?.[newIndex];

      setResultIndex(newIndex);
      if (
        currResult?.documentID !== newResult?.documentID &&
        newResult?.documentID
      ) {
        updateActiveDocumentId({
          documentId: newResult?.documentID,
          shouldClearSearchResults: false,
        });
      }
    },
    [resultIndex, results, setResultIndex, updateActiveDocumentId]
  );

  return (
    <Stack
      direction="column"
      sx={{
        m: -2, // Negative margin to make the color span the full width of the parent
        mt: 2,
        px: 2,
        backgroundColor:
          variant === 'success'
            ? COLORS.TEAL[100]
            : COLORS.FUNCTIONAL.ERROR[50],
      }}
    >
      <Divider
        sx={{
          mx: -2, // Negative margin to make the divider span the full width of the parent
          borderWidth: 1,
          borderColor:
            variant === 'success'
              ? COLORS.TEAL[500]
              : COLORS.FUNCTIONAL.ERROR.DEFAULT,
        }}
      />
      {variant === 'error' && (
        <Typography
          variant="h5"
          color={COLORS.FUNCTIONAL.ERROR[900]}
          sx={{ py: 1.5 }}
        >
          Error searching the document
        </Typography>
      )}
      {variant === 'success' && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {!results?.length && (
            <Typography variant="h5" color={COLORS.TEAL[900]} sx={{ py: 1.5 }}>
              No results were found
            </Typography>
          )}

          {results?.length !== 0 && (
            <Stack direction="column">
              <Typography variant="subtitle2">Currently displaying</Typography>
              <Typography variant="h5" color={COLORS.TEAL[900]}>
                Result {displayIndex} of {results?.length}
              </Typography>
            </Stack>
          )}

          {/* PREV/NEXT BUTTONS */}
          <Stack direction="row" py={1.5} gap={1}>
            <Button
              variant="primary"
              size="sm"
              startIcon={ArrowLeftIcon}
              disabled={!results?.length || results?.length === 1}
              onClick={() =>
                handleSetNewResult(
                  resultIndex === 0 ? results!.length - 1 : resultIndex - 1
                )
              }
            >
              Prev
            </Button>
            <Button
              variant="primary"
              size="sm"
              endIcon={ArrowRightIcon}
              disabled={!results?.length || results?.length === 1}
              onClick={() =>
                handleSetNewResult((resultIndex + 1) % results!.length)
              }
            >
              Next
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
