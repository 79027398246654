import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import { useForm } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';

import { TaskReassignmentForm } from './TaskReassignmentForm';
import {
  TaskReassignmentFormShape,
  TaskReassignmentModalCloseReason,
} from './TaskReassignmentModal.types';
import { useGetTaskReassignmentOptions } from './useGetTaskReassignmentOptions';

export interface TaskReassignmentModalProps {
  userId: string;
  householdId: string;
  isOpen: boolean;
  onClose: (
    reason: TaskReassignmentModalCloseReason,
    reassignToUserId: null | string
  ) => void;
}

export function TaskReassignmentModal({
  userId,
  householdId,
  isOpen,
  onClose,
}: TaskReassignmentModalProps) {
  const { formRef, handleSave } = useFormSaveHandler();
  const formMethods = useForm<TaskReassignmentFormShape>({
    defaultValues: {
      reassignedTo: '',
    },
  });
  const { handleSubmit, formState, shouldBlockNavigation } = formMethods;

  const { reassignmentOptions, loading } = useGetTaskReassignmentOptions({
    assignedAwayFromUserId: userId,
    householdId,
  });

  const onSubmit = handleSubmit((formData) => {
    onClose(TaskReassignmentModalCloseReason.COMPLETE, formData.reassignedTo);
  });

  function handleCancel() {
    onClose(TaskReassignmentModalCloseReason.CLOSE, null);
  }

  const disabled = formState?.isSubmitting || loading;

  return (
    <FormProvider {...formMethods}>
      <FormModal
        isOpen={isOpen}
        heading="Reassign tasks and remove from client"
        onClose={handleCancel}
        shouldBlockClose={shouldBlockNavigation}
        actions={
          <FormModalActions.Provider formState={formState} disabled={disabled}>
            <FormModalActions.CancelButton onClick={handleCancel} />
            <Button
              size="sm"
              variant="destructive-prominent"
              onClick={handleSave}
            >
              Complete reassignment & remove from client
            </Button>
          </FormModalActions.Provider>
        }
      >
        <form ref={formRef} onSubmit={onSubmit}>
          <FormFieldsDisabledProvider isDisabled={disabled}>
            <TaskReassignmentForm reassignmentOptions={reassignmentOptions} />
          </FormFieldsDisabledProvider>
        </form>
      </FormModal>
    </FormProvider>
  );
}
