import { Stack } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';

export function NotesContainer({ children }: React.PropsWithChildren) {
  return (
    <FormLayoutRow>
      <FormLayoutItem
        width={12}
        sx={{
          px: 4,
        }}
      >
        <Card
          variant="filled-light"
          sx={{
            p: 2,
          }}
        >
          <Stack spacing={2}>{children}</Stack>
        </Card>
      </FormLayoutItem>
    </FormLayoutRow>
  );
}
