// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from '../../../graphql/fragments/pageInfo.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CollaboratorsPage_CollaboratorFragment = { __typename?: 'Collaborator', id: string, displayName: string, title?: string | null, organizationName?: string | null, kind: Types.CollaboratorKind, user?: { __typename?: 'User', id: string, email: string } | null, households?: Array<{ __typename?: 'Household', id: string, displayName: string }> | null };

export type GetCollaboratorsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.CollaboratorWhereInput>;
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['Cursor']['input']>;
}>;


export type GetCollaboratorsQuery = { __typename?: 'Query', paginatedData: { __typename?: 'CollaboratorConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: any | null, endCursor?: any | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'CollaboratorEdge', node?: { __typename?: 'Collaborator', id: string, displayName: string, title?: string | null, organizationName?: string | null, kind: Types.CollaboratorKind, user?: { __typename?: 'User', id: string, email: string } | null, households?: Array<{ __typename?: 'Household', id: string, displayName: string }> | null } | null } | null> | null } };

export const CollaboratorsPage_CollaboratorFragmentDoc = gql`
    fragment CollaboratorsPage_Collaborator on Collaborator {
  id
  displayName
  title
  organizationName
  kind
  user {
    id
    email
  }
  households {
    id
    displayName
  }
}
    `;
export const GetCollaboratorsDocument = gql`
    query GetCollaborators($where: CollaboratorWhereInput, $first: Int!, $after: Cursor) {
  paginatedData: collaborators(where: $where, first: $first, after: $after) {
    totalCount
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        id
        ...CollaboratorsPage_Collaborator
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${CollaboratorsPage_CollaboratorFragmentDoc}`;

/**
 * __useGetCollaboratorsQuery__
 *
 * To run a query within a React component, call `useGetCollaboratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaboratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaboratorsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetCollaboratorsQuery(baseOptions: Apollo.QueryHookOptions<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>(GetCollaboratorsDocument, options);
      }
export function useGetCollaboratorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>(GetCollaboratorsDocument, options);
        }
export function useGetCollaboratorsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>(GetCollaboratorsDocument, options);
        }
export type GetCollaboratorsQueryHookResult = ReturnType<typeof useGetCollaboratorsQuery>;
export type GetCollaboratorsLazyQueryHookResult = ReturnType<typeof useGetCollaboratorsLazyQuery>;
export type GetCollaboratorsSuspenseQueryHookResult = ReturnType<typeof useGetCollaboratorsSuspenseQuery>;
export type GetCollaboratorsQueryResult = Apollo.QueryResult<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>;
export const Operations = {
  Query: {
    GetCollaborators: 'GetCollaborators' as const
  },
  Fragment: {
    CollaboratorsPage_Collaborator: 'CollaboratorsPage_Collaborator' as const
  }
}