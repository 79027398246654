import { AugmentedCreateGraphVisualizationGroupInput } from '@/types/schema';
import { assertExact } from '@/utils/assertUtils';

import { UpdateEstateWaterfallMutationVariables } from '../components/EstateWaterfallModal/graphql/EstateWaterfallModal.generated';

export const SHOW_CREATE_GROUP_MODAL_SENTINEL =
  'SHOW_CREATE_GROUP_MODAL_SENTINEL';

export function getCreateNodeGroupVariables(
  waterfallId: string,
  withVisualizationGroups: AugmentedCreateGraphVisualizationGroupInput[]
): {
  variables: UpdateEstateWaterfallMutationVariables | null;
  count: number;
} {
  return {
    variables: assertExact<UpdateEstateWaterfallMutationVariables>()({
      input: {
        id: waterfallId,
        update: {},
        withVisualizationGroups,
      },
      refetchWaterfall: true,
    }),
    count: withVisualizationGroups.length,
  };
}
