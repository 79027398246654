import { LargeTableEmptyState } from '@/components/tables/components/LargeTableEmptyState/LargeTableEmptyState';

import { CashflowModal } from '../CashflowModal/CashflowModal';

export interface CashFlowDataTableEmptyStateProps {
  recipient: string | undefined;
}

export function CashFlowDataTableEmptyState({
  recipient,
}: CashFlowDataTableEmptyStateProps) {
  return (
    <LargeTableEmptyState
      title="Specify cash flows"
      description="Add income and expenses for the client or specific entities. Cash flows will impact entity valuation projections in estate waterfalls and will not impact entity valuations elsewhere."
      buttonText="Add cash flow"
      ModalComponent={({ isOpen, onClose }) => (
        <CashflowModal
          isOpen={isOpen}
          onClose={onClose}
          cashflowId={null}
          recipient={recipient}
        />
      )}
      buttonProps={
        recipient
          ? {
              size: 'lg',
            }
          : undefined
      }
      containerProps={
        recipient
          ? {
              py: 0,
            }
          : undefined
      }
    />
  );
}
