import { Box } from '@mui/material';

import { HeaderCard } from '@/components/layout/HeaderCard';
import { Callout } from '@/components/notifications/Callout/Callout';
import { Loader } from '@/components/progress/Loader/Loader';

import { TaskListItemStatus } from './TaskList';
import { DenormalizedImplementationTask } from './taskUtils';

export interface TaskHeaderCardProps {
  task: DenormalizedImplementationTask;
  loading?: boolean;
  footer: React.ReactNode;
}

export function TaskHeaderCard({
  task,
  loading,
  children,
  footer,
}: React.PropsWithChildren<TaskHeaderCardProps>) {
  return (
    <HeaderCard
      sx={(theme) => ({ height: '100%', p: 0, pt: 3, pr: theme.spacing(20) })}
      headerSx={{ ml: 2, mb: 4 }}
      heading={task.heading}
      subheading={task.subheading}
      id={task.body.type}
    >
      <Box minHeight={400} px={2} mb={7}>
        {task.status === TaskListItemStatus.INVALID && (
          <Callout severity="warning" sx={{ mb: 3 }}>
            Some underlying data has changed since this task was completed.
            Please review the task content and resubmit it.
          </Callout>
        )}
        {loading ? (
          <Loader
            boxProps={{
              sx: {
                display: 'flex',
                justifyContent: 'center',
              },
            }}
          />
        ) : (
          children
        )}
      </Box>
      {footer}
    </HeaderCard>
  );
}
