import { ExtendedCreateUserInput } from '@/types/schema';

import { CollaboratorFormShape } from './CollaboratorModal.constants';

export function mapFormDataToInput(
  formValues: CollaboratorFormShape
): ExtendedCreateUserInput {
  // this all uses the "create" paradigm, but internally to the endpoint
  // we will skip user creation if an email of an existing user already matches
  return {
    create: {
      email: formValues.email,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
    },
    withCollaborator: {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      kind: formValues.role || null,
      title: formValues.title || null,
      organizationName: formValues.organization || null,
      householdIDs: formValues.associatedClients.map((client) => client.id),
    },
  };
}
