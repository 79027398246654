import { useApolloClient } from '@apollo/client';
import { Box } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { DeleteButton } from '@/components/form/baseInputs/Button/DeleteButton';
import { Modal } from '@/components/modals/Modal/Modal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Loader } from '@/components/progress/Loader/Loader';
import { useForm } from '@/components/react-hook-form';
import { useGraphQLErrors, useSpecificGraphQLError } from '@/graphql/errors';
import { useReportError } from '@/hooks/useReportError';
// eslint-disable-next-line luminary-rules/no-page-imports
import { GetAdminUsersDocument } from '@/pages/admin/AdminUsersPage/graphql/AdminUsers.generated';
import { ErrorCodes } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { EmployeeForm } from './EmployeeForm';
import { useCreateEmployeeMutation } from './graphql/CreateEmployee.generated';
import { useDeleteEmployeeMutation } from './graphql/DeleteEmployee.generated';
import { useGetEmployeeQuery } from './graphql/GetEmployee.generated';
import { useResendInviteMutation } from './graphql/ResendInvite.generated';
import { useUpdateEmployeeMutation } from './graphql/UpdateEmployee.generated';
import {
  dataToForm,
  getCreateInput,
  getMutationInput,
  useGetEmployeeRoleOptions,
} from './utils';

const defaultValues = {
  employeeId: '',
  userId: '',
  firstName: '',
  lastName: '',
  email: '',
  roleIds: [],
};

interface Props {
  isOpen: boolean;
  employeeId: string | null;
  closeHandler: () => void;
}

export interface EmployeeFormShape {
  employeeId: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  roleIds: string[];
  isAdmin: boolean;
}

export function EmployeeModal({ isOpen, employeeId, closeHandler }: Props) {
  const isCreatingUser = employeeId === null;
  const apolloClient = useApolloClient();
  const { showFeedback, createErrorFeedback } = useFeedback();
  const { control, handleSubmit, reset, getValues } =
    useForm<EmployeeFormShape>({
      defaultValues,
    });

  const {
    data,
    loading,
    error: queryError,
  } = useGetEmployeeQuery({
    variables: {
      where: {
        id: employeeId,
      },
    },
    skip: !employeeId || isCreatingUser,
    onError: createErrorFeedback(
      "We weren't able to load this user. Please refresh the page and try again."
    ),
  });

  const { roleOptions } = useGetEmployeeRoleOptions();

  const [resendInvite, { loading: isResending }] = useResendInviteMutation();

  const [deleteEmployee, { loading: isDeleting }] = useDeleteEmployeeMutation();

  const [updateEmployee, { loading: isUpdating }] = useUpdateEmployeeMutation();

  const [createEmployee, { loading: isCreating, error: createError }] =
    useCreateEmployeeMutation({
      errorPolicy: 'all',
      refetchQueries: [GetAdminUsersDocument],
      awaitRefetchQueries: true,
    });

  const displayErrors = useGraphQLErrors(createError);
  const userAlreadyExists = useSpecificGraphQLError(
    displayErrors,
    ErrorCodes.UserAlreadyExists
  );

  useEffect(() => {
    if (userAlreadyExists) {
      showFeedback(userAlreadyExists.message);
    } else if (createError) {
      showFeedback("We weren't able to create this user. Please try again.");
    }
  }, [createError, showFeedback, userAlreadyExists]);

  const isSubmitting = isUpdating || isResending || isDeleting || isCreating;

  useEffect(
    function emptyFormIfCreating() {
      if (isCreatingUser) {
        reset(defaultValues);
      }
    },
    [isCreatingUser, reset]
  );

  useEffect(
    function initializeForm() {
      const formData = dataToForm(data);

      if (formData) {
        reset(formData);
      }
    },
    [data, reset]
  );

  useReportError('failed to fetch employee', queryError, {
    employeeId,
  });

  const handleClose = useCallback(() => {
    setTimeout(
      () => {
        if (isCreatingUser) {
          reset(defaultValues);
        }
      },
      250,
      isCreatingUser
    );

    closeHandler();
  }, [closeHandler, reset, isCreatingUser]);

  const onValidSubmit: SubmitHandler<EmployeeFormShape> = async (
    formValues
  ) => {
    if (isCreatingUser) {
      const input = getCreateInput(formValues);

      try {
        const { errors } = await createEmployee({
          variables: {
            input,
          },
        });

        // Check errors due to errorPolicy: 'all'
        if (errors) {
          throw new Error('Could not create employee');
        }

        showFeedback(`An invite has been sent to ${input.create.email}.`, {
          variant: 'success',
        });
        handleClose();
      } catch (err) {
        diagnostics.error('Could not create employee', err as Error, {
          userId: input.create.email,
        });
      }

      // return early to prevent the update mutation from running
      return;
    }

    const input = getMutationInput(formValues);

    try {
      await updateEmployee({
        variables: {
          input,
        },
        refetchQueries: [GetAdminUsersDocument],
      });

      showFeedback(`User successfully updated.`, { variant: 'success' });
      handleClose();
    } catch (err) {
      showFeedback("We weren't able to update this user. Please try again.");
      diagnostics.error('Could not update employee', err as Error, {
        userId: input.userID,
      });
    }
  };

  const handleResendInvite = useCallback(async () => {
    const email = getValues('email');

    try {
      await resendInvite({
        variables: {
          email,
        },
      });
      showFeedback(`An invite has been sent to ${email}.`, {
        variant: 'success',
      });
    } catch (err) {
      showFeedback(
        "We weren't able to resend the invite email. Please try again."
      );
      diagnostics.error('Could not resend invite', err as Error, {
        email,
      });
    } finally {
      handleClose();
    }
  }, [getValues, resendInvite, showFeedback, handleClose]);

  const submitHandler = handleSubmit(onValidSubmit);

  const handleDelete = useCallback(async () => {
    const email = getValues('email');

    try {
      await deleteEmployee({
        variables: {
          email,
        },
      });

      // invalidate the cache for employees
      await apolloClient.refetchQueries({
        updateCache(cache) {
          cache.evict({ fieldName: 'employees' });
        },
      });

      showFeedback('User deactivated successfully.', { variant: 'success' });
    } catch (err) {
      showFeedback(
        "We weren't able to deactivate this user. Please try again."
      );
      diagnostics.error('Could not delete employee', err as Error, {
        email,
      });
    } finally {
      handleClose();
    }
  }, [getValues, deleteEmployee, apolloClient, showFeedback, handleClose]);

  const actions = useMemo(() => {
    if (isCreatingUser) {
      return (
        <>
          <Button size="sm" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            size="sm"
            variant="primary"
            onClick={submitHandler}
            disabled={isSubmitting}
          >
            Save
          </Button>
        </>
      );
    }

    return (
      <>
        <DeleteButton
          promptDeleteText="Deactivate user"
          onConfirmDelete={handleDelete}
        />
        <Button
          variant="secondary"
          size="sm"
          onClick={handleResendInvite}
          disabled={isSubmitting}
        >
          Resend invite
        </Button>
        <Box flexGrow={1} />
        <Button size="sm" variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          type="submit"
          size="sm"
          variant="primary"
          onClick={submitHandler}
          disabled={isSubmitting}
        >
          Save changes
        </Button>
      </>
    );
  }, [
    handleClose,
    isSubmitting,
    submitHandler,
    handleDelete,
    handleResendInvite,
    isCreatingUser,
  ]);

  const employee = getNodes(data?.employees)[0];

  return (
    <Modal
      isOpen={isOpen}
      heading={isCreatingUser ? 'Create user' : 'Edit user'}
      onClose={handleClose}
      actions={actions}
    >
      {loading && (
        <Loader
          boxProps={{
            sx: {
              textAlign: 'center',
              my: 3,
            },
          }}
        />
      )}
      {!loading && (employee || isCreatingUser) && (
        <EmployeeForm
          roleOptions={roleOptions}
          assignedClients={employee?.assignedHouseholds ?? []}
          control={control}
          isCreatingUser={isCreatingUser}
        />
      )}
    </Modal>
  );
}
