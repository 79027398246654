import Decimal from 'decimal.js';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { AugmentedUpdateEstateWaterfallInput } from '@/types/schema';

import { HypotheticalDraftEntityType } from '../../draftEntities/draftEntities.constants';
import { HypotheticalTransferModal_EstateWaterfallHypotheticalTransferFragment } from './graphql/HypotheticalTransferModal.generated';

export enum AnnuallyRecurringValue {
  true = 'isAnnuallyRecurring',
  false = 'isOneTime',
}

export type HypotheticalTransferModalTransferOption = SelectInputOption;

export interface HypotheticalTransferUpdateEstateWaterfallPayload {
  input?: AugmentedUpdateEstateWaterfallInput;
  message?: string;
}

export interface HypotheticalTransferFormModalShape {
  sourceId: string;

  /** If an asset is selected, we store that owned entity as the source of the transfer */
  sourceOwnedEntityId: string;
  sourceInsurancePolicyId: string;
  targetId: string;

  /**
   * If `true`, create a new draft entity as the transfer destination.
   *
   * Note that if a user creates a transfer with a new draft and then edits it, this
   * switch should be set back to false, and if the user turns it on, it'll create a
   * second draft -- the first draft will not be shown with the toggle on.
   */
  transferToNewDraft: boolean;
  draftEntityType: HypotheticalDraftEntityType | '';
  draftEntityName: string;
  draftEntityGrantorId: string;

  transferAmount: Decimal | null;
  taxableValue: Decimal | null;
  transferFrequency: AnnuallyRecurringValue;
  transferStartYear: number;
  transferEndYear: number;
  transferGrowthRate: Decimal;
  transferType: string;
  transferGstGift: boolean;
  transferName: string;

  taxDiscountRate: Decimal | null;

  _yearOptionsDisabled: boolean;
  _discountRateToggle: boolean;
  _policyOrAsset: 'policy' | 'asset' | null;
  _activeTransfer: HypotheticalTransferModal_EstateWaterfallHypotheticalTransferFragment | null;
}
