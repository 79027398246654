import { PropsWithChildren, useEffect } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

import { FullscreenName, useFullscreenContext } from './fullscreen.context';

const useStyles = makeStyles<{ isActive: boolean }>()(
  (theme, { isActive }) => ({
    root: {
      background: COLORS.GRAY[50],
      padding: isActive ? theme.spacing(2) : undefined,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  })
);

interface FullscreenProps {
  name: FullscreenName;
  classes?: WithClasses<typeof useStyles>;
}

export function Fullscreen({
  children,
  name,
  classes: externalClasses,
}: PropsWithChildren<FullscreenProps>) {
  const handle = useFullScreenHandle();
  const { registerFullscreenInstance } = useFullscreenContext();

  const { classes } = useStyles(
    {
      isActive: handle.active,
    },
    {
      props: { classes: externalClasses },
    }
  );

  useEffect(() => {
    registerFullscreenInstance(name, handle);
  }, [handle, name, registerFullscreenInstance]);

  return (
    <FullScreen className={classes.root} handle={handle}>
      {children}
    </FullScreen>
  );
}
