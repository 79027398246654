import { RibbonCard } from '@/components/layout/RibbonCard';
import { useFormContext } from '@/components/react-hook-form';
import { AssetsSubform } from '@/modules/assets/AssetsSubform/AssetsSubform';
import { getBasicInformationDefaultValues } from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import { NON_TRUST_CHARITABLE_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { EntitySubformOptionalRibbonCard } from '@/modules/entities/EntitySubforms/EntitySubformOptionalRibbonCard';
import { getGrantorsFieldVariant } from '@/modules/entities/principals/GrantorsField/GrantorsField.utils';
import { WealthOwnerType } from '@/modules/entities/principals/NonGrantorPrincipalFields/NonGrantorPrincipalFields.types';
import { getNonGrantorPrincipalFieldVariant } from '@/modules/entities/principals/NonGrantorPrincipalFields/NonGrantorPrincipalFields.utils';
import { GRATAnnuitiesSubform } from '@/modules/gratAnnuities/GRATAnnuitiesSubform/GRATAnnuitiesSubform';

import { BasicAssetsSubform } from '../BasicAssetsSubform/BasicAssetsSubform';
import { BasicInformationSubform } from '../BasicInformationSubform/BasicInformationSubform';
import { BASIC_INFORMATION_SUBFORM_NAMESPACE } from '../BasicInformationSubform/BasicInformationSubform.types';
import { BusinessEntitiesTaxStatusSubform } from '../BusinessEntitiesTaxStatusSubform/BusinessEntitiesTaxStatusSubform';
import { BusinessEntityDetailsSubform } from '../BusinessEntityDetailsSubform/BusinessEntityDetailsSubform';
import { EntityBeneficiariesSubform } from '../EntityBeneficiariesSubform/EntityBeneficiariesSubform';
import { InsurancePolicyDetailsSubform } from '../InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform';
import { TaxStatusSubform } from '../TaxStatusSubform/TaxStatusSubform';
import { TrustDetailsSubform } from '../TrustDetailsSubform/TrustDetailsSubform';
import {
  getTrustDetailsDefaultValues,
  TRUST_DETAILS_SUBFORM_NAMESPACE,
} from '../TrustDetailsSubform/TrustDetailsSubform.types';
import { SUBFORM_TYPE_COPY_MAP, SubformConfig } from './entitySubformConfigs';
import { SubformsCombinedType } from './EntitySubforms.types';
import { useSelectedPrincipals } from './useSelectedPrincipals';

export interface UseSubformOpts {
  // Whether we're using this hook from the long-form EntityFormTrowser,
  // or the new split-screen UX, where we show one subform at a time.
  renderFromSplitScreen: boolean;
}

export function useSubforms(
  subformConfig: SubformConfig[],
  opts: UseSubformOpts = { renderFromSplitScreen: false }
): JSX.Element[] {
  const { watch } = useFormContext<SubformsCombinedType>();

  const { FormComponent: BasicInformationFormComponent } =
    new BasicInformationSubform();

  const { FormComponent: TrustDetailsFormComponent } =
    new TrustDetailsSubform();

  const { FormComponent: TaxStatusFormComponent } = new TaxStatusSubform();

  const { FormComponent: BeneficiariesFormComponent } =
    new EntityBeneficiariesSubform();

  const { FormComponent: BasicAssetsFormComponent } = new BasicAssetsSubform();

  const { FormComponent: AssetsFormComponent } = new AssetsSubform();

  const { FormComponent: BusinessEntityDetailsFormComponent } =
    new BusinessEntityDetailsSubform();

  const { FormComponent: BusinessEntitiesTaxStatusFormComponent } =
    new BusinessEntitiesTaxStatusSubform();

  const { FormComponent: InsurancePolicyDetailsComponent } =
    new InsurancePolicyDetailsSubform();

  const {
    EntityFormComponent: AnnuitiesFormComponent,
    defaultValues: annuitiesSubformDefaultValues,
  } = new GRATAnnuitiesSubform();

  const selectedPrincipalClientProfileIds = useSelectedPrincipals();

  const { renderFromSplitScreen } = opts;

  return subformConfig.map((config) => {
    const grantorsFieldVariant = getGrantorsFieldVariant(config.entityType);
    const nonGrantorPrincipalsFieldVariant = getNonGrantorPrincipalFieldVariant(
      config.entityType
    );

    switch (config.type) {
      case 'basicInformationSubform':
        return (
          <BasicInformationFormComponent
            variant={config.props.variant}
            householdId={config.props.householdId}
            entityId={config.props.entityId}
            entityType={config.entityType}
            grantorsFieldVariant={grantorsFieldVariant}
            grantorsFieldSubformValues={watch(
              `${BASIC_INFORMATION_SUBFORM_NAMESPACE}`,
              getBasicInformationDefaultValues(grantorsFieldVariant)
            )}
            isCharitableEntity={['crt', 'clt'].includes(config.entityType)}
            hideDocumentsList={renderFromSplitScreen}
            nonGrantorPrincipalsFieldVariant={nonGrantorPrincipalsFieldVariant}
            wealthOwnerType={
              NON_TRUST_CHARITABLE_ENTITY_TYPES.includes(config.entityType)
                ? WealthOwnerType.DONOR
                : WealthOwnerType.OWNER
            }
          />
        );
      case 'businessEntityDetailsSubform':
        return (
          <EntitySubformOptionalRibbonCard
            heading={
              config.props.heading ??
              SUBFORM_TYPE_COPY_MAP.businessEntityDetailsSubform
            }
            hideRibbon={renderFromSplitScreen}
          >
            <BusinessEntityDetailsFormComponent
              variant={config.props.variant}
              householdId={config.props.householdId}
              entityId={config.props.entityId}
              entityType={config.entityType}
            />
          </EntitySubformOptionalRibbonCard>
        );
      case 'trustDetailsSubform':
        return (
          <EntitySubformOptionalRibbonCard
            heading={
              config.props.heading ?? SUBFORM_TYPE_COPY_MAP.trustDetailsSubform
            }
            hideRibbon={renderFromSplitScreen}
          >
            <TrustDetailsFormComponent
              variant={config.props.variant}
              householdId={config.props.householdId}
              subformValues={watch(
                `${TRUST_DETAILS_SUBFORM_NAMESPACE}`,
                getTrustDetailsDefaultValues(config.props.variant)
              )}
              entityType={config.entityType}
            />
          </EntitySubformOptionalRibbonCard>
        );
      case 'taxStatusSubform':
        return (
          <EntitySubformOptionalRibbonCard
            heading={SUBFORM_TYPE_COPY_MAP.taxStatusSubform}
            hideRibbon={renderFromSplitScreen}
          >
            <TaxStatusFormComponent
              variant={config.props.variant}
              entityId={config.props.entityId}
              selectedGrantorClientProfileIds={
                selectedPrincipalClientProfileIds
              }
            />
          </EntitySubformOptionalRibbonCard>
        );
      case 'businessEntityTaxStatusSubform':
        return (
          <EntitySubformOptionalRibbonCard
            heading={SUBFORM_TYPE_COPY_MAP.businessEntityTaxStatusSubform}
            hideRibbon={renderFromSplitScreen}
          >
            <BusinessEntitiesTaxStatusFormComponent variant="default" />
          </EntitySubformOptionalRibbonCard>
        );
      case 'beneficiariesSubform':
        return (
          <EntitySubformOptionalRibbonCard
            heading={config.props.heading ?? 'Current beneficiaries'}
            hideRibbon={renderFromSplitScreen}
          >
            <BeneficiariesFormComponent
              variant={config.props.variant}
              householdId={config.props.householdId}
              namespace={config.props.namespace}
              entityType={config.entityType}
            />
          </EntitySubformOptionalRibbonCard>
        );
      case 'lifetimeBeneficiariesSubform':
        return (
          <EntitySubformOptionalRibbonCard
            heading={
              config.props.heading ??
              SUBFORM_TYPE_COPY_MAP.lifetimeBeneficiariesSubform
            }
            hideRibbon={renderFromSplitScreen}
          >
            <BeneficiariesFormComponent
              variant={config.props.variant}
              householdId={config.props.householdId}
              namespace={config.props.namespace}
              entityType={config.entityType}
            />
          </EntitySubformOptionalRibbonCard>
        );
      case 'remainderBeneficiariesSubform':
        return (
          <EntitySubformOptionalRibbonCard
            hideRibbon={renderFromSplitScreen}
            heading={
              config.props.heading ??
              SUBFORM_TYPE_COPY_MAP.remainderBeneficiariesSubform
            }
          >
            <BeneficiariesFormComponent
              variant={config.props.variant}
              householdId={config.props.householdId}
              namespace={config.props.namespace}
              entityType={config.entityType}
            />
          </EntitySubformOptionalRibbonCard>
        );
      case 'leadBeneficiarySubform':
        return (
          <EntitySubformOptionalRibbonCard
            heading={
              config.props.heading ??
              SUBFORM_TYPE_COPY_MAP.leadBeneficiarySubform
            }
            hideRibbon={renderFromSplitScreen}
          >
            <BeneficiariesFormComponent
              variant={config.props.variant}
              householdId={config.props.householdId}
              namespace={config.props.namespace}
              entityType={config.entityType}
            />
          </EntitySubformOptionalRibbonCard>
        );
      case 'incomeBeneficiarySubform':
        return (
          <EntitySubformOptionalRibbonCard
            heading={
              config.props.heading ??
              SUBFORM_TYPE_COPY_MAP.incomeBeneficiarySubform
            }
            hideRibbon={renderFromSplitScreen}
          >
            <BeneficiariesFormComponent
              variant={config.props.variant}
              householdId={config.props.householdId}
              namespace={config.props.namespace}
              entityType={config.entityType}
            />
          </EntitySubformOptionalRibbonCard>
        );
      case 'basicAssetsInitialFundingSubform':
        return (
          <EntitySubformOptionalRibbonCard
            heading={config.props.heading ?? 'Initial funding valuation'}
            hideRibbon={renderFromSplitScreen}
          >
            <BasicAssetsFormComponent
              namespace={config.type}
              variant={config.props.variant}
              householdId={config.props.householdId}
              entityType={config.entityType}
            />
          </EntitySubformOptionalRibbonCard>
        );
      case 'assetsSubform':
        return (
          <RibbonCard
            heading={
              config.props.heading ?? SUBFORM_TYPE_COPY_MAP.assetsSubform
            }
          >
            <AssetsFormComponent
              variant={config.props.variant}
              householdId={config.props.householdId}
              entityId={config.props.entityId}
            />
          </RibbonCard>
        );
      case 'annuitiesSubform':
        return (
          <RibbonCard
            heading={
              config.props.heading ?? SUBFORM_TYPE_COPY_MAP.annuitiesSubform
            }
          >
            <AnnuitiesFormComponent
              householdId={config.props.householdId}
              variant={config.props.variant}
              entityId={config.props.entityId}
              subformValues={watch(
                `${config.props.namespace}` as const,
                annuitiesSubformDefaultValues
              )}
            />
          </RibbonCard>
        );
      case 'insurancePolicyDetailsSubform':
        return (
          <EntitySubformOptionalRibbonCard
            heading={SUBFORM_TYPE_COPY_MAP.insurancePolicyDetailsSubform}
            hideRibbon={renderFromSplitScreen}
          >
            <InsurancePolicyDetailsComponent
              variant={config.props.variant}
              householdId={config.props.householdId}
              entityId={config.props.entityId}
            />
          </EntitySubformOptionalRibbonCard>
        );
      default:
        throw new Error(`Unknown subform config: ${config?.type}`);
    }
  });
}
