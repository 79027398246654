import Decimal from 'decimal.js';
import {
  compact,
  differenceBy,
  first,
  isEmpty,
  startCase,
  toLower,
  uniq,
} from 'lodash';

import { AIOnboardingModalEntityField } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { assignDocumentsToEntitySuggestions } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/common.utils';
import {
  AiOnboardingModal_AiSuggestionFragment,
  AiOnboardingModal_HouseholdFragment,
  AiOnboardingModal_PossiblePrincipalClientProfileFragment,
} from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import { BASIC_ASSETS_SUBFORM } from '@/modules/entities/BasicAssetsSubform/BasicAssetsSubform.types';
import {
  BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE,
  BusinessEntityDetailsSubformType,
} from '@/modules/entities/BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import { ALLOW_MULTIPLE_PRINCIPALS_TYPES_WITH_BUSINESS_ENTITIES } from '@/modules/entities/entities.constants';
import {
  CREATE_ENTITY_SHORT_FORM_NAMESPACE,
  EntityShortFormFields,
  EntityShortFormShape,
  PrincipalsOwnershipMap,
} from '@/modules/entities/EntityShortFormModal/CreateEntityShortForm/CreateEntityShortForm.types';
import { makeCreateEntityInput } from '@/modules/entities/EntityShortFormModal/CreateEntityShortForm/formUtils/CreateEntityShortForm.entityUtils';
import { getTaxStatusValuesFromEntityType } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';
import {
  AugmentedCreateEntityInput,
  EntityAttributionSource,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getPulidKind, PulidKind } from '@/utils/pulid';

///////////////////////////////////////////////////////////
// SUGGESTION DATA -> FORM DATA
///////////////////////////////////////////////////////////

/**
 * Maps the ONBOARDING_ENTITY suggestion to form data.
 *
 * @param suggestions
 * @param possiblePrimaryClients
 * @param possibleGrantors
 */
export function mapEntitiesToFormData(
  suggestions: AiOnboardingModal_AiSuggestionFragment[],
  {
    possiblePrimaryClients,
    possibleGrantors,
  }: AiOnboardingModal_HouseholdFragment
): AIOnboardingModalEntityField[] {
  return compact(
    suggestions.map((s) => {
      if (!s.onboardingEntity) return null;

      const suggestedGrantorCPIDs = getSuggestedGrantorNodesClientProfiles(
        s.onboardingEntity?.principalPersonNodes,
        possiblePrimaryClients,
        possibleGrantors
      ).map((g) => g.id);

      const suggestedEntitySuggestionIDs = getSuggestedEntityNodesSuggestions(
        s.onboardingEntity?.ownerEntityNodes,
        s.id,
        suggestions
      );

      const suggestedPrincipalIds = [
        ...(s.onboardingEntity?.principalClientProfileIDs || []),
        ...suggestedGrantorCPIDs,
        ...suggestedEntitySuggestionIDs,
      ];

      return {
        _suggestionID: s.id,
        _nodeName: s.onboardingEntity?.nodeName,
        entityName: startCase(toLower(s.onboardingEntity?.entityName || '')),
        entityType: s.onboardingEntity?.entityKind
          ? getEntityTypeFromEntityKind(s.onboardingEntity?.entityKind)
          : '',
        integrationEntityIds: [],
        // NOTE: We are populating both the multi-select and single-select fields
        // here, because the user might switch entity types. We want to at least
        // show the initially suggested principal(s).
        principalIds: suggestedPrincipalIds,
        principalId: first(suggestedPrincipalIds) || '',
      };
    })
  );
}

function getSuggestedGrantorNodesClientProfiles(
  suggestedNodeNames: string[] | undefined,
  possiblePrimaryClients: AiOnboardingModal_PossiblePrincipalClientProfileFragment[],
  possibleGrantors: AiOnboardingModal_PossiblePrincipalClientProfileFragment[]
): AiOnboardingModal_PossiblePrincipalClientProfileFragment[] {
  if (!suggestedNodeNames) return [];

  const nonPrimaryClientGrantors = differenceBy(
    possibleGrantors,
    possiblePrimaryClients,
    'id'
  );

  return compact(
    suggestedNodeNames.map((n) => {
      return nonPrimaryClientGrantors.find((g) =>
        g.suggestedClientProfile?.find(
          (cp) => cp.onboardingIndividual?.nodeName === n
        )
      );
    })
  );
}

function getSuggestedEntityNodesSuggestions(
  suggestedNodeNames: string[] | undefined,
  suggestionID: string,
  suggestions: AiOnboardingModal_AiSuggestionFragment[]
) {
  if (!suggestedNodeNames) return [];

  const otherEntitySuggestions = suggestions.filter(
    (s) => s.id !== suggestionID
  );

  return compact(
    suggestedNodeNames.map((n) => {
      const ownerEntitySuggestion = otherEntitySuggestions.find(
        (e) => e.onboardingEntity?.nodeName === n
      );
      return ownerEntitySuggestion ? ownerEntitySuggestion.id : null;
    })
  );
}

export function getEmptyEntityField(): AIOnboardingModalEntityField {
  return {
    _suggestionID: '',
    _nodeName: '',
    entityName: '',
    entityType: '',
    principalIds: [],
    principalId: '',
    integrationEntityIds: [],
  };
}

///////////////////////////////////////////////////////////
// FORM DATA -> MUTATION INPUT
///////////////////////////////////////////////////////////

/**
 * Converts the form data to the input data for the mutation.
 *
 * @param entities
 * @param household
 * @param suggestionsByID
 */
export function mapEntitiesToInput(
  entities: AIOnboardingModalEntityField[],
  household: AiOnboardingModal_HouseholdFragment,
  suggestionsByID: Record<string, AiOnboardingModal_AiSuggestionFragment>
): AugmentedCreateEntityInput[] {
  return entities.map((e) => {
    const assignedDocsBySuggestionID = assignDocumentsToEntitySuggestions(
      entities,
      suggestionsByID
    );
    const shortFormInput = adaptEntityInputToEntityShortFormShape(
      e,
      e._suggestionID ? assignedDocsBySuggestionID[e._suggestionID] : undefined
    );
    const createInput = makeCreateEntityInput(shortFormInput, household.id);
    createInput.create = {
      ...createInput.create,
      integrationEntityIDs: e.integrationEntityIds,
      onboardingEntitySuggestionIDs: e._suggestionID
        ? [e._suggestionID]
        : undefined,
      // Business entities can be owned by other entities. However, since we're
      // batch-onboarding entities, we don't have the entity IDs yet. So, we pass
      // the suggestion IDs of the owning entities for now, and the backend will
      // resolve them in the mutation resolver.
      owningOnboardingEntitySuggestionIDs: getPulidsOfKind(
        // We only care about the principalIds field here, because business
        // entities are all multi-principal entities.
        e.principalIds,
        PulidKind.AiSuggestion
      ),
      attributionSource: EntityAttributionSource.DocumentExtraction,
    };

    return createInput;
  });
}

function adaptEntityInputToEntityShortFormShape(
  formInput: AIOnboardingModalEntityField,
  assignedDocIDs: string[] | undefined
): EntityShortFormShape {
  const { entityName, entityType, principalIds, principalId } = formInput;
  if (!entityType) {
    throw new Error('Entity type is required');
  }

  const { estateInclusionStatus } =
    getTaxStatusValuesFromEntityType(entityType);

  const { principals, selectedSinglePrincipal, owners } =
    getClientProfilePrincipalsInput(entityType, principalIds, principalId);

  return {
    [CREATE_ENTITY_SHORT_FORM_NAMESPACE]: {
      name: entityName,
      entityType,
      principals,
      selectedSinglePrincipal,
      _ownershipSum: null,
      estateInclusionStatus,
      stateTax: {} as EntityShortFormFields['stateTax'],
      defaultDocumentId: undefined,
      documentIds: assignedDocIDs,
    },
    [BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE]: {
      keyPeople: [],
      keyPeopleNotes: '',
      owners,
      ownershipAsOfDate: null,
      _ownershipSum: null,
    },
    [BASIC_ASSETS_SUBFORM]: {},
  };
}

function getClientProfilePrincipalsInput(
  entityType: EntityType,
  principalIds: string[],
  principalId: string
): Pick<EntityShortFormFields, 'principals' | 'selectedSinglePrincipal'> & {
  owners: BusinessEntityDetailsSubformType['owners'];
} {
  const allowMultiplePrincipals =
    ALLOW_MULTIPLE_PRINCIPALS_TYPES_WITH_BUSINESS_ENTITIES.includes(entityType);

  const validPrincipalIds = getPulidsOfKind(
    allowMultiplePrincipals ? principalIds : [principalId],
    PulidKind.ClientProfile
  );

  if (isEmpty(validPrincipalIds)) {
    diagnostics.warn(
      'empty validPrincipalIds in getClientProfilePrincipalsInput'
    );
    return {
      principals: {} as PrincipalsOwnershipMap,
      selectedSinglePrincipal: '',
      owners: [],
    };
  }

  const nonOwnerPrincipalsInput = allowMultiplePrincipals
    ? {
        principals: validPrincipalIds.reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              isIncluded: true,
              ownershipPercentage: null,
            },
          }),
          {} as PrincipalsOwnershipMap
        ),
        selectedSinglePrincipal: '',
      }
    : {
        principals: {} as PrincipalsOwnershipMap,
        selectedSinglePrincipal: validPrincipalIds[0]!,
      };

  return {
    ...nonOwnerPrincipalsInput,
    owners: validPrincipalIds.map((id) => ({
      ownerId: id,
      // For business entities, set the ownership percentage to 0 during the
      // onboarding process. The user can update it later using more detailed
      // AI Suggestions on the entity details page. Not setting the ownership
      // percentage here will cause errors when fetching the business entity.
      percentOwned: new Decimal(0),
    })),
  };
}

/**
 * Given a list of strings (pulids), returns the ones that are of the given pulid kind.
 *
 * @param input
 * @param pulidKind
 */
function getPulidsOfKind(input: string[], pulidKind: PulidKind): string[] {
  return uniq(input.filter((p) => getPulidKind(p) === pulidKind));
}
