import { Stack } from '@mui/material';

import { AfterDeath } from '@/types/schema';

import { GetWaterfallSummary_EstateWaterfallFragment } from '../../graphql/GetWaterfallSummary.generated';
import {
  useWaterfallSummaryBarChartSections,
  WaterfallBarChartSection,
} from '../EstateWaterfallSummaryBarCharts/hooks/useWaterfallSummaryBarChartSections';
import { CurrentSummarySection } from './CurrentSummarySection';
import { FinalSummarySection } from './FinalSummarySection';
import { FirstDeathSummarySection } from './FirstDeathSummarySection';

export interface EstateWaterfallSummaryTilesProps {
  waterfall: GetWaterfallSummary_EstateWaterfallFragment;
}

export const EstateWaterfallSummaryTiles = ({
  waterfall,
}: EstateWaterfallSummaryTilesProps) => {
  const waterfallSections = useWaterfallSummaryBarChartSections({
    waterfall,
  });

  const sections = waterfallSections.reduce(
    (acc, val) => ({ ...acc, [val.afterDeath]: val }),
    {} as Partial<Record<AfterDeath, WaterfallBarChartSection>>
  );

  const firstDeathSection = sections.Second ? sections.First : null;
  const finalDeathSection = sections.Second ? sections.Second : sections.First;

  return (
    <Stack gap={3}>
      {sections.None && (
        <CurrentSummarySection waterfall={waterfall} section={sections.None} />
      )}
      {firstDeathSection && (
        <FirstDeathSummarySection
          section={firstDeathSection}
          waterfall={waterfall}
        />
      )}
      {finalDeathSection && (
        <FinalSummarySection
          waterfall={waterfall}
          section={finalDeathSection}
        />
      )}
    </Stack>
  );
};
