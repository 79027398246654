import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { GrantorsFieldSubformValuesType } from '@/modules/entities/principals/GrantorsField/GrantorsField.types';
import { NonGrantorPrincipalFieldsType } from '@/modules/entities/principals/NonGrantorPrincipalFields/NonGrantorPrincipalFields.types';

import { BusinessEntityDetailsSubformType } from '../../BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import {
  PrimaryClientDropdown_HouseholdWithPrimaryClientsFragment,
  PrimaryClientDropdown_PossibleGrantorFragment,
} from './graphql/PrimaryClientDropdown.generated';

/**
 * @description You should use this when you want the literal version of the term "grantor". Specifically, when you don't want to limit
 * the options to just the primary clients, but want to include non-primary client grantors as well. Uses of this are e.g. grantors/owners/donors
 * for entities.
 */
export function getGrantorOptionsFromData(
  possibleGrantors: PrimaryClientDropdown_PossibleGrantorFragment[],
  subformValues?:
    | NonGrantorPrincipalFieldsType
    | BusinessEntityDetailsSubformType
    | GrantorsFieldSubformValuesType
    | string[]
    | { grantor: { clientProfileId: string } }
): SelectInputProps<string>['options'] {
  const selectedIds: string[] = [];
  if (subformValues && 'owners' in subformValues && subformValues.owners) {
    subformValues.owners.forEach((o) => {
      selectedIds.push(o.ownerId);
    });
  }

  if (subformValues && 'owner' in subformValues && subformValues.owner) {
    selectedIds.push(subformValues.owner.ownerId);
  }

  if (subformValues && 'grantors' in subformValues) {
    subformValues.grantors.forEach((g) => {
      selectedIds.push(g.clientProfileId);
    });
  }

  if (subformValues && 'grantor' in subformValues && subformValues.grantor) {
    selectedIds.push(subformValues.grantor.clientProfileId);
  }

  if (subformValues && Array.isArray(subformValues)) {
    selectedIds.push(...subformValues);
  }

  const possibleOptions = possibleGrantors.map((g) => ({
    display: g.legalName,
    value: g.id,
  }));

  return getOptionsWithSelectedDisabled({
    possibleOptions,
    selectedIds,
  });
}

/**
 * @description You should use this when you want to expose the (maximum of two) primary clients as options.
 */
export function getPrimaryClientOptionsFromData(
  data: PrimaryClientDropdown_HouseholdWithPrimaryClientsFragment | null,
  subformValues?: string[]
): SelectInputProps<string>['options'] {
  const selectedIds: string[] = [];

  if (subformValues && Array.isArray(subformValues)) {
    selectedIds.push(...subformValues);
  }

  const possiblePrimaryClients = data?.possiblePrimaryClients ?? [];
  const possibleOptions = possiblePrimaryClients
    .map((c) => ({
      display: c.displayName,
      value: c.id,
    }))
    .sort((a, b) => a.display.localeCompare(b.display));

  return getOptionsWithSelectedDisabled({
    possibleOptions,
    selectedIds,
  });
}

function getOptionsWithSelectedDisabled({
  possibleOptions,
  selectedIds,
}: {
  possibleOptions: SelectInputProps<string>['options'];
  selectedIds: string[];
}) {
  return possibleOptions.map((option) => {
    if (option.type === 'component') return option;
    return {
      ...option,
      disabled: selectedIds.includes(option.value),
    };
  });
}
