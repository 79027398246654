import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';

import { LinkButton, LinkButtonProps } from './LinkButton';

export function SmallLinkButton(props: LinkButtonProps) {
  return (
    <LinkButton
      underline={false}
      endIcon={<ChevronRightIcon size={14} />}
      {...props}
    />
  );
}
