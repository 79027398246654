// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { ExemptionTrackingTable_LifetimeExclusionEventFragmentDoc } from '../ExemptionTrackingTable/graphql/ExemptionTrackingTable.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientDetailsGiftingPage_ClientProfileFragment = { __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD };

export type ClientDetailsGiftingPage_HouseholdFragment = { __typename?: 'Household', possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD }> };

export type ClientDetailsGiftingPageQueryVariables = Types.Exact<{
  householdWhere?: Types.InputMaybe<Types.HouseholdWhereInput>;
  lifetimeExclusionEventsWhere?: Types.InputMaybe<Types.LifetimeExclusionEventWhereInput>;
}>;


export type ClientDetailsGiftingPageQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, currentAnnualExclusionUsed: CurrencyUSD, totalAnnualExclusionUsed: CurrencyUSD }> } | null } | null> | null }, lifetimeExclusionEvents: { __typename?: 'LifetimeExclusionEventConnection', totalCount: number, edges?: Array<{ __typename?: 'LifetimeExclusionEventEdge', node?: { __typename?: 'LifetimeExclusionEvent', id: string, eventDate: Time, giftAmount?: CurrencyUSD | null, giftCategory?: Types.LifetimeExclusionEventGiftCategory | null, kind: Types.LifetimeExclusionEventKind, lifetimeExclusionAmount: CurrencyUSD, gstExclusionAmount: CurrencyUSD, annualExclusionAmount?: CurrencyUSD | null, grantor: { __typename?: 'ClientProfile', id: string, displayName: string }, recipient?: { __typename?: 'Beneficiary', id: string, summary: { __typename?: 'SummaryBeneficiaryv2', id: string, displayName: string }, entity?: { __typename?: 'Entity', id: string, kind: Types.EntityKind } | null } | null } | null } | null> | null } };

export const ClientDetailsGiftingPage_ClientProfileFragmentDoc = gql`
    fragment ClientDetailsGiftingPage_ClientProfile on ClientProfile {
  id
  gstExclusionUsed
  displayName
  lifetimeExclusionUsed
  currentAnnualExclusionUsed
  totalAnnualExclusionUsed
}
    `;
export const ClientDetailsGiftingPage_HouseholdFragmentDoc = gql`
    fragment ClientDetailsGiftingPage_Household on Household {
  possiblePrimaryClients {
    ...ClientDetailsGiftingPage_ClientProfile
  }
}
    ${ClientDetailsGiftingPage_ClientProfileFragmentDoc}`;
export const ClientDetailsGiftingPageDocument = gql`
    query ClientDetailsGiftingPage($householdWhere: HouseholdWhereInput, $lifetimeExclusionEventsWhere: LifetimeExclusionEventWhereInput) {
  households(where: $householdWhere) {
    edges {
      node {
        id
        ...ClientDetailsGiftingPage_Household
      }
    }
  }
  lifetimeExclusionEvents(
    where: $lifetimeExclusionEventsWhere
    orderBy: {field: event_date, direction: DESC}
  ) {
    edges {
      node {
        id
        ...ExemptionTrackingTable_LifetimeExclusionEvent
      }
    }
    totalCount
  }
}
    ${ClientDetailsGiftingPage_HouseholdFragmentDoc}
${ExemptionTrackingTable_LifetimeExclusionEventFragmentDoc}`;

/**
 * __useClientDetailsGiftingPageQuery__
 *
 * To run a query within a React component, call `useClientDetailsGiftingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientDetailsGiftingPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientDetailsGiftingPageQuery({
 *   variables: {
 *      householdWhere: // value for 'householdWhere'
 *      lifetimeExclusionEventsWhere: // value for 'lifetimeExclusionEventsWhere'
 *   },
 * });
 */
export function useClientDetailsGiftingPageQuery(baseOptions?: Apollo.QueryHookOptions<ClientDetailsGiftingPageQuery, ClientDetailsGiftingPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientDetailsGiftingPageQuery, ClientDetailsGiftingPageQueryVariables>(ClientDetailsGiftingPageDocument, options);
      }
export function useClientDetailsGiftingPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientDetailsGiftingPageQuery, ClientDetailsGiftingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientDetailsGiftingPageQuery, ClientDetailsGiftingPageQueryVariables>(ClientDetailsGiftingPageDocument, options);
        }
export function useClientDetailsGiftingPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientDetailsGiftingPageQuery, ClientDetailsGiftingPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientDetailsGiftingPageQuery, ClientDetailsGiftingPageQueryVariables>(ClientDetailsGiftingPageDocument, options);
        }
export type ClientDetailsGiftingPageQueryHookResult = ReturnType<typeof useClientDetailsGiftingPageQuery>;
export type ClientDetailsGiftingPageLazyQueryHookResult = ReturnType<typeof useClientDetailsGiftingPageLazyQuery>;
export type ClientDetailsGiftingPageSuspenseQueryHookResult = ReturnType<typeof useClientDetailsGiftingPageSuspenseQuery>;
export type ClientDetailsGiftingPageQueryResult = Apollo.QueryResult<ClientDetailsGiftingPageQuery, ClientDetailsGiftingPageQueryVariables>;
export const Operations = {
  Query: {
    ClientDetailsGiftingPage: 'ClientDetailsGiftingPage' as const
  },
  Fragment: {
    ClientDetailsGiftingPage_ClientProfile: 'ClientDetailsGiftingPage_ClientProfile' as const,
    ClientDetailsGiftingPage_Household: 'ClientDetailsGiftingPage_Household' as const
  }
}