import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';

import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { PlusIcon } from '@/components/icons/PlusIcon';

import { Button, ButtonProps } from '../../form/baseInputs/Button';

export interface EditableListItemProps {
  title: string;
  lineTwo?: string | null;
  lineThree?: string | null;
  lineFour?: string | null;
  onEditClick: () => void;
  sx?: SxProps<Theme>;
  onClick?: () => void;
}

export function EditableListItem({
  title,
  lineTwo,
  lineThree,
  lineFour,
  onEditClick,
  sx,
  onClick,
}: EditableListItemProps) {
  return (
    <Stack
      sx={sx}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      component="li"
      aria-label={title}
      onClick={onClick}
    >
      <Box mb={1}>
        <Typography variant="h5">{title}</Typography>
        {lineTwo && <Typography variant="subtitle2">{lineTwo}</Typography>}
        {lineThree && <Typography variant="subtitle2">{lineThree}</Typography>}
        {lineFour && <Typography variant="label">{lineFour}</Typography>}
      </Box>
      <Box>
        <EditButton onClick={onEditClick} aria-label={`Edit ${title}`} />
      </Box>
    </Stack>
  );
}

export type EditableListProps = React.PropsWithChildren<{
  footerButton?: JSX.Element;
}>;

export function EditableList({ footerButton, children }: EditableListProps) {
  return (
    <Box component="section">
      <Box sx={{ p: 0, m: 0 }} component="ul">
        {React.Children.map(children, (child) => child)}
      </Box>
      <Box component="footer" mt={3}>
        {footerButton && footerButton}
      </Box>
    </Box>
  );
}

export type EditableListAddItemButtonProps = Partial<ButtonProps>;

export function EditableListAddItemButton({
  ...buttonProps
}: EditableListAddItemButtonProps) {
  return (
    <Button
      fullWidth
      startIcon={PlusIcon}
      variant="secondary"
      size="sm"
      {...buttonProps}
    />
  );
}
