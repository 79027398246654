import {
  RadioGroupOption,
  SelectInputOption,
} from '@/components/form/baseInputs/inputTypes';

import { formatEnumCase } from './formatting/strings';

interface CreateOptionsFromEnumOpts {
  labelFormatter?: (value: string) => string;
}
export function createOptionsFromEnum(
  inputEnum: Record<string, string>,
  { labelFormatter = formatEnumCase }: CreateOptionsFromEnumOpts = {}
): SelectInputOption<string>[] {
  return Object.values(inputEnum).map((value) => ({
    value: value,
    display: labelFormatter(value),
  }));
}

export function createRadioGroupOptionsFromEnum(
  inputEnum: Record<string, string>,
  { labelFormatter = formatEnumCase }: CreateOptionsFromEnumOpts = {}
): RadioGroupOption<string>[] {
  return Object.values(inputEnum).map((value) => ({
    value: value,
    label: labelFormatter(value),
  }));
}
