import { Box, Stack } from '@mui/material';
import { compact, includes, isEqual } from 'lodash';

import { DragAndDropList } from '@/components/lists/DragAndDropList/DragAndDropList';
import { DraggableListItemInnerComponentProps } from '@/components/lists/DragAndDropList/DraggableListItem';
import { useIsElementHovered } from '@/hooks/useIsElementHovered';
import { ClientPresentationSection } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { useGuardedClientPresentationDesignerContext } from '../../contexts/clientPresentationDesigner.context';
import { ClientPresentationSidebarEmptyState } from '../ClientPresentationSidebarEmptyStates';
import { SidebarGroupElement } from '../SidebarGroup/SidebarGroupElement';
import { SidebarButton } from '../SidebarSlideElement';
import {
  REORDERABLE_SLIDE_TYPE_TO_PRESENTATION_SECTION,
  ReorderableSlideItem,
  ReorderableSlideTypes,
} from './ReorderSlideElementList.types';
import { useReorderableItems } from './useReorderableItems';

export function ReorderSlideElementList() {
  const {
    setSectionOrder,
    setVisibleEntityPulids,
    visibleEntityPulids,
    setVisibleWaterfallPulids,
    visibleWaterfallPulids,
  } = useGuardedClientPresentationDesignerContext();

  const reorderableItems = useReorderableItems();

  function handleDrop(sortedList: ReorderableSlideItem[]) {
    // there are three potential lists that we might be passed here, all of which are "ReorderableSlideTypes"
    // 1. the top-level items (standalone slides, estate waterfalls, entities)
    // 2. the entities sub-list
    // 3. the estate waterfalls sub-list
    // let's figure out which one we're dealing with, then operate accordingly.

    // we're dealing with the entities sub-list
    if (sortedList[0]?.slideType === ReorderableSlideTypes.SINGLE_ENTITY) {
      if (!sortedList[0]?.itemPulid) {
        throw new Error('item pulid is required for SINGLE_ENTITY items');
      }

      const orderedEntityPulids = compact(
        sortedList.map((item) => item.itemPulid)
      );
      if (isEqual(orderedEntityPulids, visibleEntityPulids)) return;
      diagnostics.debug(
        'Setting ordered visible entities',
        orderedEntityPulids
      );
      setVisibleEntityPulids(orderedEntityPulids);
      return;
    }

    // we're dealing with the estate waterfalls sub-list
    if (sortedList[0]?.slideType === ReorderableSlideTypes.SINGLE_WATERFALL) {
      if (!sortedList[0]?.itemPulid) {
        throw new Error('item pulid is required for SINGLE_WATERFALL items');
      }

      const orderedWaterfallPulids = compact(
        sortedList.map((item) => item.itemPulid)
      );
      if (isEqual(orderedWaterfallPulids, visibleWaterfallPulids)) return;
      diagnostics.debug(
        'Setting ordered visible waterfalls',
        orderedWaterfallPulids
      );
      setVisibleWaterfallPulids(orderedWaterfallPulids);
      return;
    }

    // we're dealing with the top-level items
    const newSectionOrder = sortedList.map((item) => {
      const section =
        REORDERABLE_SLIDE_TYPE_TO_PRESENTATION_SECTION[item.slideType];
      if (!section) {
        throw new Error(`unhandled slide type ${item.slideType}`);
      }

      return section as ClientPresentationSection;
    });

    setSectionOrder(newSectionOrder);
  }

  if (!reorderableItems.length) {
    return (
      <ClientPresentationSidebarEmptyState
        heading="No reorderable slides"
        description="No slides that can be reordered are currently included in this presentation"
      />
    );
  }

  return (
    <DragAndDropList<ReorderableSlideItem>
      respondToLengthChanges={false}
      onDropped={handleDrop}
      withDividers={false}
      items={reorderableItems}
      ItemInnerComponent={(props) => (
        <ReorderableListItem onChildDropped={handleDrop} {...props} />
      )}
    />
  );
}

function ReorderableListItem({
  item,
  isDragging,
  Draghandle,
  onChildDropped,
}: DraggableListItemInnerComponentProps<ReorderableSlideItem>) {
  const { setActiveSlideId, activeSlideId } =
    useGuardedClientPresentationDesignerContext();
  const [isHovered, elementProps] = useIsElementHovered();

  function onSelectSlide(slideId: string) {
    setActiveSlideId(slideId, {
      doScroll: true,
      scrollBehavior: 'smooth',
    });
  }

  const isGroupItem = includes(
    [
      ReorderableSlideTypes.ENTITIES_BUNDLE,
      ReorderableSlideTypes.ESTATE_WATERFALL_BUNDLE,
    ],
    item.slideType
  );

  if (isGroupItem) {
    return (
      <SidebarGroupElement LeftAction={() => Draghandle} label={item.display}>
        <Box pl={2}>
          <DragAndDropList<ReorderableSlideItem>
            respondToLengthChanges={false}
            onDropped={(items) => onChildDropped?.(items)}
            withDividers={false}
            items={item.children ?? []}
            ItemInnerComponent={(props) => <ReorderableListItem {...props} />}
          />
        </Box>
      </SidebarGroupElement>
    );
  }

  return (
    <Stack direction="row" spacing={0.5} flexGrow={1} alignItems="center">
      {Draghandle}
      <SidebarButton
        onClick={() => item.slideId && onSelectSlide(item.slideId)}
        isActive={item.slideId === activeSlideId}
        isHovered={isHovered || isDragging}
        sx={{ pl: 2, pr: 1, py: 2 }}
        {...elementProps}
      >
        {item.display}
      </SidebarButton>
    </Stack>
  );
}
