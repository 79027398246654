import React from 'react';

import { PopperContent } from '@/components/poppers/PopperContent';

export function EstateWaterfallFirstGrantorDeath() {
  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body="Projected year of first client death"
    />
  );
}
