// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
export type TrustDetailsCard_GrantorPrincipalFragment = { __typename?: 'Principal', id: string, individual?: { __typename?: 'ClientProfile', id: string, legalName: string } | null };

export const TrustDetailsCard_GrantorPrincipalFragmentDoc = gql`
    fragment TrustDetailsCard_GrantorPrincipal on Principal {
  id
  individual {
    id
    legalName
  }
}
    `;
export const Operations = {
  Fragment: {
    TrustDetailsCard_GrantorPrincipal: 'TrustDetailsCard_GrantorPrincipal' as const
  }
}