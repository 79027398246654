import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { showWaterfallSummaryTileDetailsVar } from '@/graphql/reactiveVars';
import { LOCAL_STORAGE_BOOLEAN_SERIALIZER } from '@/utils/localStorageSerializers';

interface SetWaterfallSummaryTileDetailsProps {
  waterfallId: string;
  show: boolean;
}

export function setWaterfallSummaryTileDetails({
  waterfallId,
  show,
}: SetWaterfallSummaryTileDetailsProps) {
  const key = `${LOCAL_STORAGE_KEYS.WATERFALL_SUMMARY_TILE_DETAILS}_${waterfallId}`;
  localStorage.setItem(key, LOCAL_STORAGE_BOOLEAN_SERIALIZER.serializer(show));
  showWaterfallSummaryTileDetailsVar(show);
}
