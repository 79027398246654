import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

import { ButtonRenderer } from '@/components/tables/DataTable/renderers/cell/ButtonRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { DispositiveProvisionsModalDetailsType } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import { testamentaryEntityKindToDisplayName } from '@/modules/entities/testamentaryEntities/testamentaryEntities.utils';
import { sortAlpha } from '@/utils/sortUtils';

import { useManageDispositionsContext } from '../context/ManageDispositions.context';
import { ManageDispositions_TestamentaryEntityFragment } from '../graphql/ManageDispositionsPage.generated';
import { TESTAMENTARY_NOT_TIED_TO_DEATH_SENTINEL } from '../ManageDispositionsPage.constants';
import { TestamentaryEntityRow } from './ManageDispositionsTestamentaryEntitiesTable.types';

export const useTestamentaryEntitiesTableColumns = () => {
  const { activeClientDeathId, setDispositiveProvisionsModalDetails } =
    useManageDispositionsContext();

  const columns: Column<TestamentaryEntityRow>[] = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Entity',
        flex: 1,
        renderCell: TwoLineTextRenderer({
          lineOne: ({ row }) => row.name,
          lineTwo: ({ row }) => testamentaryEntityKindToDisplayName(row.kind),
          rightContent: ({ row }) =>
            row.template ? (
              <Typography variant="subtitle2" sx={{ textAlign: 'right' }}>
                {row.template} applied
              </Typography>
            ) : null,
        }),
      },
      {
        field: 'action',
        headerName: '',
        width: 176,
        renderCell: ButtonRenderer({
          buttonProps: (row) => ({
            fullWidth: true,
            'data-testid': `SetDispositionsButton-${row.id}`,
            handleClick: (row) => {
              setDispositiveProvisionsModalDetails({
                type: DispositiveProvisionsModalDetailsType.TESTAMENTARY_ENTITY,
                testamentaryEntityId: row.id,
                entityDisplayName: row.name,
                firstDeathClientId: activeClientDeathId,
              });
            },
            label: (row) => {
              return row.hasDispositionsSet ? 'Edit' : 'Set dispositions';
            },
            variant: (row) =>
              row.hasDispositionsSet ? 'secondary' : 'primary',
          }),
        }),
      },
    ],
    [activeClientDeathId, setDispositiveProvisionsModalDetails]
  );

  return columns;
};

export function mapTestamentaryEntitiesToRows(
  entities: ManageDispositions_TestamentaryEntityFragment[],
  activeClientDeathId?: string
): TestamentaryEntityRow[] {
  const rows = entities.flatMap<TestamentaryEntityRow>((entity) => {
    const dispositionScenarios = entity.dispositionScenarios ?? [];

    const relevantScenario = dispositionScenarios.find(
      (scenario) => scenario.firstGrantorDeath?.id === activeClientDeathId
    );

    // we know that someone has reviewed the dispositions for this entity if either dispositions are set (obviously)
    // or if they've reviewed the dispositions, even if they've chosen to not enter any.
    const hasDispositionsSet =
      !isEmpty(dispositionScenarios) || Boolean(entity.reviewedAt);

    return {
      id: entity.id,
      name: entity.displayName,
      kind: entity.kind,
      hasDispositionsSet,
      grantorDeathId:
        entity.grantorDeath?.id ?? TESTAMENTARY_NOT_TIED_TO_DEATH_SENTINEL,
      action: null,
      template:
        relevantScenario?.dispositiveProvisionsTemplate?.displayName ||
        relevantScenario?.secondDeathDispositiveProvisionsTemplate?.displayName,
    };
  });

  return rows.sort((a, b) => sortAlpha(a.name, b.name));
}
