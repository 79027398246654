import { SxProps, Typography } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import {
  DisplayTable,
  DisplayTableColumn,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { useModalState } from '@/hooks/useModalState';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { COLORS } from '@/styles/tokens/colors';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { formatCurrencyNoDecimalsAccounting } from '@/utils/formatting/currency';
import { GetTransferDetailsOutput } from '@/utils/hypotheticalTransfers/transferUtils';

import { EstateWaterfallComparisonTrowserComponentsProps } from '../EstateWaterfallComparisonTrowser.components';
import { COMPARISON_WATERFALL_LABELS } from '../EstateWaterfallComparisonTrowser.constants';
import { TransfersModal } from './TransfersModal';

const INTERACTIVE_SX_PROPS: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export function EstateWaterfallComparisonTrowserSummaryTable({
  waterfalls,
}: EstateWaterfallComparisonTrowserComponentsProps) {
  const isViewOnly = useViewOnly();
  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');
  const columnWidth = `${100 / (waterfalls.length + 1)}%`;
  const columns: DisplayTableColumn[] = [
    { headerName: '', width: columnWidth },
    ...waterfalls.map<DisplayTableColumn>((waterfall, idx) => {
      return {
        headerName: COMPARISON_WATERFALL_LABELS[idx],
        headerIconAfter: waterfall.isHypothetical ? (
          <Badge variant={BadgeVariants.Yellow} display="Hypothetical" />
        ) : undefined,
        width: columnWidth,
      };
    }),
  ];

  return (
    <DisplayTable
      columns={columns}
      tableSx={{ tableLayout: 'fixed' }}
      data-testid="waterfall-comparison-summary-table"
    >
      <StyledTableRow inactive>
        <StyledTableCell key="waterfall-name-row-blank" width={columnWidth} />
        {waterfalls.map(
          ({ displayName, comparisonInfo, growthProfile }, idx) => {
            let subheading: string | undefined = undefined;

            if (isGrowthProfilesEnabled && comparisonInfo) {
              let deathYearDisplay = `${comparisonInfo.firstDeathYear} death`;
              if (comparisonInfo.secondDeathYear) {
                deathYearDisplay = `${comparisonInfo.firstDeathYear} & ${comparisonInfo.secondDeathYear} deaths`;
              }
              subheading = `${growthProfile?.displayName || 'Unknown growth profile'}, ${deathYearDisplay}`;
            }
            return (
              <StyledTableCell key={`waterfall-name-row-${idx}`}>
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    ...(isViewOnly ? {} : INTERACTIVE_SX_PROPS),
                  }}
                  title={displayName}
                >
                  {displayName}
                </Typography>
                {subheading && (
                  <Typography
                    variant="subtitle2"
                    sx={{
                      ...(isViewOnly ? {} : INTERACTIVE_SX_PROPS),
                    }}
                  >
                    {subheading}
                  </Typography>
                )}
              </StyledTableCell>
            );
          }
        )}
      </StyledTableRow>
      <StyledTableRow inactive>
        <StyledTableCell>
          <Typography key="waterfall-in-estate-label">
            In-estate (after tax)
          </Typography>
        </StyledTableCell>
        {waterfalls.map(({ inEstate }, idx) => (
          <StyledTableCell key={`waterfall-in-estate-${idx}`}>
            <Typography>
              {formatCurrencyNoDecimalsAccounting(inEstate)}
            </Typography>
          </StyledTableCell>
        ))}
      </StyledTableRow>
      <StyledTableRow inactive>
        <StyledTableCell>
          <Typography key="waterfall-gift-estate-tax-label">
            Gift & estate tax (cumulative)
          </Typography>
        </StyledTableCell>
        {waterfalls.map(({ giftAndEstateTax }, idx) => (
          <StyledTableCell key={`waterfall-in-estate-${idx}`}>
            <Typography
              sx={{
                color: giftAndEstateTax.greaterThan(0)
                  ? COLORS.MATH.NEGATIVE
                  : undefined,
              }}
            >
              {formatCurrencyNoDecimalsAccounting(giftAndEstateTax.neg(), {
                parensDisplay: 'negativeOnly',
              })}
            </Typography>
          </StyledTableCell>
        ))}
      </StyledTableRow>
      <StyledTableRow inactive>
        <StyledTableCell key="out-of-estate-family-label">
          <Typography>Out-of-estate (family)</Typography>
        </StyledTableCell>
        {waterfalls.map(({ outOfEstateFamily }, idx) => (
          <StyledTableCell key={`waterfall-out-of-estate-family-${idx}`}>
            <Typography>
              {formatCurrencyNoDecimalsAccounting(outOfEstateFamily)}
            </Typography>
          </StyledTableCell>
        ))}
      </StyledTableRow>
      <StyledTableRow inactive>
        <StyledTableCell key="out-of-estate-charity-label">
          <Typography>Out-of-estate (charity)</Typography>
        </StyledTableCell>
        {waterfalls.map(({ outOfEstateCharity }, idx) => (
          <StyledTableCell key={`waterfall-out-of-estate-charity-${idx}`}>
            <Typography>
              {formatCurrencyNoDecimalsAccounting(outOfEstateCharity)}
            </Typography>
          </StyledTableCell>
        ))}
      </StyledTableRow>
      {isViewOnly ? null : (
        <StyledTableRow>
          <StyledTableCell key="transfers-label">
            <Typography>Transfers</Typography>
          </StyledTableCell>
          {waterfalls.map(({ transfers }, idx) => (
            <StyledTableCell key={`transfers-${idx}`}>
              <EstateWaterfallComparisonTrowserTransferModal
                transfers={transfers}
              />
            </StyledTableCell>
          ))}
        </StyledTableRow>
      )}
      <StyledTableRow
        sx={{ borderTop: `3px solid ${COLORS.NAVY[600]}` }}
        inactive
      >
        <StyledTableCell key="total-wealth-label">
          <RichListItem
            heading="Total wealth"
            description="Combined in and out-of-estate"
          />
        </StyledTableCell>
        {waterfalls.map(
          ({ outOfEstateCharity, outOfEstateFamily, inEstate }, idx) => (
            <StyledTableCell key={`total-wealth-${idx}`}>
              <Typography>
                {formatCurrencyNoDecimalsAccounting(
                  sumDecimalJS([
                    outOfEstateCharity,
                    outOfEstateFamily,
                    inEstate,
                  ])
                )}
              </Typography>
            </StyledTableCell>
          )
        )}
      </StyledTableRow>
    </DisplayTable>
  );
}

interface EstateWaterfallComparisonTrowserTransferModalProps {
  transfers: GetTransferDetailsOutput[];
}

function EstateWaterfallComparisonTrowserTransferModal({
  transfers,
}: EstateWaterfallComparisonTrowserTransferModalProps) {
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();
  if (!transfers.length) {
    return null;
  }

  return (
    <>
      <TransfersModal
        isOpen={isModalOpen}
        onClose={closeModal}
        transfers={transfers}
      />
      <Button variant="secondary" size="xs" onClick={openModal}>
        View {transfers.length} transfers
      </Button>
    </>
  );
}
