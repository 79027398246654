import { Stack, Typography } from '@mui/material';

import { PopperContent } from '@/components/poppers/PopperContent';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { EntityType } from '@/modules/entities/types/EntityType';

export interface PrincipalsLabelProps {
  label: React.ReactNode;
  entityType?: EntityType;
}

export function PrincipalsLabel({ label, entityType }: PrincipalsLabelProps) {
  return (
    <Stack direction="row">
      <Typography variant="h4">{label} *</Typography>
      {entityType === 'insurance-account' && (
        <ContextualHelpTooltip>
          <PopperContent body="Specify the owner(s) of the policies held in this entity. You can add the insured individuals to each policy after the entity is created." />
        </ContextualHelpTooltip>
      )}
    </Stack>
  );
}
