import Skeleton from '@mui/material/Skeleton';
import { ComponentProps } from 'react';

import { EMPTY_CONTENT_HYPHEN } from '../../typography/placeholders';

interface Props extends ComponentProps<typeof Skeleton> {
  loading: boolean;
  value?: React.ReactNode;
  emptyValue?: React.ReactNode;
}

export function LoadingSkeletonOrValue({
  loading,
  value,
  emptyValue,
  ...skeletonProps
}: Props) {
  if (loading) return <Skeleton {...skeletonProps} />;
  if (!value && emptyValue) return <span>{emptyValue}</span>;
  if (!value) return <span>{EMPTY_CONTENT_HYPHEN}</span>;
  return <span>{value}</span>;
}
