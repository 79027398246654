import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { COLORS } from '@/styles/tokens/colors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

export interface EntityValuationSumLineProps {
  value: Decimal;
  /** the horizontal padding */
  padding: number;
}

export function EntityValuationSumLine({
  value,
  padding,
}: EntityValuationSumLineProps) {
  return (
    <Box sx={{ borderTop: `solid 2px ${COLORS.NAVY[500]}`, mt: 1, pt: 1 }}>
      <Stack direction="row" spacing={0.5} px={padding} alignItems="baseline">
        <Typography variant="h3">{formatCurrencyNoDecimals(value)}</Typography>
        <Typography variant="subtitle2">total value</Typography>
      </Stack>
    </Box>
  );
}
