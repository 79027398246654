import React, { useEffect, useState } from 'react';

import { InteractionParadigmContext } from '@/contexts/InteractionParadigm.context';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { HouseholdDetailsAwareRoute } from '@/modules/household/contexts/HouseholdDetailsAwareRoute';
import { ClientPresentationContent } from '@/modules/presentation/clientPresentation/ClientPresentationContent/ClientPresentationContent';
import { useGetClientPresentationDetails } from '@/modules/presentation/clientPresentation/ClientPresentationDesigner/hooks/useGetClientPresentationDetails';
import { useGuardedClientPresentationDesignerContext } from '@/modules/presentation/clientPresentation/contexts/clientPresentationDesigner.context';
import { ClientPresentationDesignerProvider } from '@/modules/presentation/clientPresentation/contexts/ClientPresentationDesigner.provider';
import { useSubBrand } from '@/modules/tenant/TenantDetailsContext/useSubBrand';
import { sleep } from '@/utils/sleep';

import { PrintStatusSentinel } from '../components/PrintStatusSentinel';
import { PrintStatuses } from '../printOnly.constants';

function PrintOnlyClientPresentationInner() {
  const [printStatus, setPrintStatus] = useState<PrintStatuses>(
    PrintStatuses.LOADING
  );
  const [haveSetInitialValues, setHaveSetInitialValues] = useState(false);
  const { _setInitialItemVisibility } =
    useGuardedClientPresentationDesignerContext();

  const presentationId = useRequiredParam('presentationId');
  const householdId = useRequiredParam('householdId');
  const {
    entities: entitiesDetails,
    waterfalls: estateWaterfalls,
    client,
    allEntitiesOverview,
    professionalTeam,
    legalDisclaimers,
    balanceSheet,
    federalEstateTaxPercent,
    error,
    loading,
    visibleEntityPulids: initialVisibleEntityPulids,
    visibleWaterfallPulids: initialVisibleWaterfallPulids,
    sectionOrder,
    coverSlideDescription,
    coverSlideTitle,
    showCoverSlide,
  } = useGetClientPresentationDetails({
    variables: { presentationId, householdId },
  });

  useEffect(() => {
    if (loading) return;
    _setInitialItemVisibility({
      shouldShowCoverSlide: showCoverSlide ?? false,
      visibleEntityPulids: initialVisibleEntityPulids,
      visibleWaterfallPulids: initialVisibleWaterfallPulids,
      sectionOrder: sectionOrder ?? [],
    });

    setHaveSetInitialValues(true);
  }, [
    loading,
    initialVisibleEntityPulids,
    initialVisibleWaterfallPulids,
    _setInitialItemVisibility,
    sectionOrder,
    showCoverSlide,
  ]);

  useSubBrand(client.subBrand?.id ?? null);

  // we wrap this in a useEffect instead of doing straight computation of the status because
  // the MUI table component needs an extra pass to render properly
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;

    if (loading || !entitiesDetails || !haveSetInitialValues) {
      setPrintStatus(PrintStatuses.LOADING);
      return;
    }
    if (error) {
      setPrintStatus(PrintStatuses.ERROR);
      return;
    }

    async function watchPrintStatus() {
      await sleep(1000);
      const waterfalls = document.querySelectorAll(
        '[data-presentationwaterfallready]'
      );
      if (waterfalls.length === 0) {
        setPrintStatus(PrintStatuses.READY);
        return;
      }

      // Check the status of all the waterfalls every second
      // so we can know if that the waterfalls are initialized and
      // ready to be printed
      interval = setInterval(() => {
        const waterfalls = document.querySelectorAll(
          '[data-presentationwaterfallready]'
        );

        // Check if all waterfalls are ready
        const waterfallStatuses = Array.from(waterfalls).map((overlay) => {
          return overlay.getAttribute('data-presentationwaterfallready');
        });

        if (waterfallStatuses.includes('false')) {
          return;
        }

        setPrintStatus(PrintStatuses.READY);
        interval && clearInterval(interval);
      }, 1000);
    }

    void watchPrintStatus();

    return () => {
      interval && clearInterval(interval);
    };
  }, [entitiesDetails, error, haveSetInitialValues, loading]);

  return (
    <InteractionParadigmContext.Provider value={{ viewOnly: true }}>
      {/* this is used to indicate the status of the document to the PDF printing service, so it knows to delay until everything is ready */}
      <PrintStatusSentinel status={printStatus} />
      <ClientPresentationContent
        coverSlideDetails={{
          id: presentationId,
          coverSlideDescription,
          coverSlideTitle,
        }}
        balanceSheet={balanceSheet}
        client={client}
        entities={allEntitiesOverview}
        legalDisclaimers={legalDisclaimers}
        estateWaterfalls={estateWaterfalls}
        entitiesDetails={entitiesDetails}
        professionalTeam={professionalTeam}
        federalEstateTaxPercent={federalEstateTaxPercent}
      />
    </InteractionParadigmContext.Provider>
  );
}

export const PrintOnlyClientPresentation = () => {
  const householdId = useRequiredParam('householdId');

  return (
    <HouseholdDetailsAwareRoute householdId={householdId}>
      <ClientPresentationDesignerProvider>
        <PrintOnlyClientPresentationInner />
      </ClientPresentationDesignerProvider>
    </HouseholdDetailsAwareRoute>
  );
};
