import { InputAdornment } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { AdditionalItemRendererProps } from '@/components/form/formAwareInputs/FormAwareInput.types';
import {
  FormAwareSelectInput,
  FormAwareSelectInputProps,
} from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { User01Icon } from '@/components/icons/User01Icon';
import { useFormContext } from '@/components/react-hook-form';
import { MatchableKind } from '@/modules/aiSuggestions/aiSuggestions.types';
import { LocatableFormAwareInput } from '@/modules/aiSuggestions/components/forms/LocatableFormAwareInput';
import { PathsOf } from '@/types/subform';

import {
  BasicInformationSubformType,
  BasicInformationSubformType_SingleGrantor,
  BasicInformationSubformType_WithGrantors,
  Fields as BasicInformationSubformFields,
} from '../../BasicInformationSubform/BasicInformationSubform.types';
import { TestamentaryEntityFormShape } from '../../testamentaryEntities/TestamentaryEntityForm/TestamentaryEntityForm.types';
import {
  Fields as TrustDetailsSubformFields,
  TrustDetailsSubformType,
  TrustDetailsSubformType_TrusteesAndSuccessorTrustees,
} from '../../TrustDetailsSubform/TrustDetailsSubform.types';
import { TrusteeDropdown_HouseholdFragment } from './graphql/TrusteeDropdown.generated';
import { TrusteeSelectorModal } from './TrusteeSelectorModal';

type TrusteeDropdownFormShape =
  | BasicInformationSubformFields
  | TrustDetailsSubformFields
  | TestamentaryEntityFormShape;

interface TrusteeDropdownProps {
  label: string;
  fieldName: PathsOf<TrusteeDropdownFormShape>;
  options: SelectInputProps['options'];
  householdId: string;
  contextualHelp?: JSX.Element;
  hideLabel?: boolean;
  labelIconEnd?: React.ReactNode;
  // Optional parameter to associate the dropdown field with a suggestion kind.
  // If given, this will be used to show a suggestion locator icon for the field.
  suggestionKind?: MatchableKind;
  // Block navigation for the parent form after creating a new individual/organization.
  blockNavigationAfterCreate?: boolean;
  additionalItemRendererProps?: AdditionalItemRendererProps;
}

export function getTrusteeOptionsWithSelectedDisabled({
  possibleOptions,
  selectedIds,
}: {
  possibleOptions: ReturnType<typeof getOptionsFromData>;
  selectedIds: string[];
}) {
  return possibleOptions.map((option) => {
    if (option.type === 'component') return option;
    return {
      ...option,
      disabled: selectedIds.includes(option.value),
    };
  });
}

export function getOptionsFromData({
  data,
  subformValues,
}: {
  data: TrusteeDropdown_HouseholdFragment | null;
  subformValues?:
    | BasicInformationSubformType
    | BasicInformationSubformType_SingleGrantor
    | BasicInformationSubformType_WithGrantors
    | TrustDetailsSubformType
    | TrustDetailsSubformType_TrusteesAndSuccessorTrustees;
}): SelectInputProps<string>['options'] {
  if (!data) return [];

  const selectedIds: string[] = [];

  if (subformValues && 'trustees' in subformValues) {
    subformValues.trustees.forEach((t) => {
      selectedIds.push(t.trusteeId);
    });
  }

  if (subformValues && 'controllingParties' in subformValues) {
    (subformValues.controllingParties || []).forEach((t) => {
      selectedIds.push(t.controllingPartyId);
    });
  }

  const possibleTrustees = data.possibleTrusteesV2;

  const trusteeOptionsClients = (() => {
    return (
      possibleTrustees?.clients.map((t) => ({
        display: t.legalName,
        value: t.id,
      })) ?? []
    );
  })();

  const trusteeOptionsOrgs = (() => {
    return (
      possibleTrustees?.organizations.map((t) => ({
        display: t.name,
        value: t.id,
      })) ?? []
    );
  })();

  const possibleOptions = [
    (() =>
      trusteeOptionsClients.length
        ? {
            component: <SelectItemGroupLabel label="Individuals" />,

            type: 'component' as const,
          }
        : null)(),

    ...trusteeOptionsClients.sort((a, b) => a.display.localeCompare(b.display)),
    (() =>
      trusteeOptionsOrgs.length
        ? {
            component: <SelectItemGroupLabel label="Organizations" />,

            type: 'component' as const,
          }
        : null)(),

    ...trusteeOptionsOrgs.sort((a, b) => a.display.localeCompare(b.display)),
  ].filter(
    (trustee): trustee is NonNullable<typeof trustee> => trustee !== null
  );

  return getTrusteeOptionsWithSelectedDisabled({
    possibleOptions,
    selectedIds,
  });
}

export function TrusteeDropdown({
  label,
  fieldName,
  options,
  householdId,
  hideLabel,
  contextualHelp,
  labelIconEnd,
  suggestionKind,
  blockNavigationAfterCreate,
  additionalItemRendererProps,
}: TrusteeDropdownProps) {
  const { control, setShouldBlockNavigation } =
    useFormContext<TrusteeDropdownFormShape>();
  const [newlyCreatedId, setNewlyCreatedId] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (blockNavigationAfterCreate && newlyCreatedId && !isOpen) {
      // Closing the modal after creating a new individual/organization should
      // block navigation for the parent form.
      setShouldBlockNavigation(true);
      setNewlyCreatedId('');
    }
  }, [
    newlyCreatedId,
    setShouldBlockNavigation,
    isOpen,
    blockNavigationAfterCreate,
  ]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const inputProps = useMemo(
    () => ({
      control,
      label,
      hideLabel,
      fieldName,
      contextualHelp,
      addNewOption: {
        onClick: () => setIsOpen(true),
      },
      options,
      startAdornment: (
        <InputAdornment position="start">
          <User01Icon size={20} />
        </InputAdornment>
      ),
      labelIconEnd,
    }),
    [
      contextualHelp,
      control,
      fieldName,
      hideLabel,
      label,
      labelIconEnd,
      options,
    ]
  );

  return (
    <>
      {/* TODO: I think this can be replaced with the AddNewAnythingModal? */}
      <TrusteeSelectorModal
        isOpen={isOpen}
        handleClose={handleClose}
        householdId={householdId}
        onAfterCreate={setNewlyCreatedId}
      />
      {suggestionKind ? (
        <LocatableFormAwareInput<
          TrusteeDropdownFormShape,
          FormAwareSelectInputProps<TrusteeDropdownFormShape>
        >
          FormAwareElement={FormAwareSelectInput}
          suggestionKind={suggestionKind}
          additionalItemRendererProps={additionalItemRendererProps}
          {...inputProps}
        />
      ) : (
        <FormAwareSelectInput {...inputProps} />
      )}
    </>
  );
}
