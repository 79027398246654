import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { ExtendedCreateUserInput, UpdateEmployeeInput } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';
import { sortAlpha } from '@/utils/sortUtils';

import { EmployeeFormShape } from './EmployeeModal';
import {
  GetEmployeeQuery,
  useGetEmployeeRoleOptionsQuery,
} from './graphql/GetEmployee.generated';

export function dataToForm(
  data: GetEmployeeQuery | undefined
): EmployeeFormShape | null {
  const employee = data?.employees?.edges?.[0]?.node ?? null;
  if (!employee) {
    return null;
  }

  return {
    employeeId: employee.id,
    userId: employee.user.id,
    firstName: employee.user.firstName,
    lastName: employee.user.lastName,
    email: employee.user.email,
    isAdmin: employee.isAdmin || false,
    roleIds: employee.roles?.map((role) => role.id) || [],
  };
}

export function useGetEmployeeRoleOptions() {
  const { createErrorFeedback } = useFeedback();
  const { data, ...queryProps } = useGetEmployeeRoleOptionsQuery({
    onError: createErrorFeedback(
      'Failed to fetch roles options, please refresh the page to try again.'
    ),
  });

  const roleOptions: SelectInputOption<string>[] = useMemo(() => {
    if (!data) return [];
    return getNodes(data?.roles)
      .map((role) => ({
        value: role.id,
        display: role.displayName,
      }))
      .sort((a, b) => sortAlpha(a.display, b.display));
  }, [data]);

  return { roleOptions, ...queryProps };
}

export function getMutationInput(form: EmployeeFormShape): UpdateEmployeeInput {
  return {
    isAdmin: form.isAdmin,
    userID: form.userId,
    clearRoles: true,
    addRoleIDs: form.roleIds,
    user: {
      firstName: form.firstName,
      lastName: form.lastName,
    },
  };
}

export function getCreateInput(
  form: EmployeeFormShape
): ExtendedCreateUserInput {
  return {
    create: {
      firstName: form.firstName,
      lastName: form.lastName,
      email: form.email,
    },
    withEmployee: {
      roleIDs: form.roleIds,
    },
  };
}
