import { Box, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

import { Card } from '@/components/layout/Card/Card';

import { AssetProviderLinkIndicator } from '../shared/AssetProviderLinkIndicator';

export interface IntegrationInputCardProps {
  additionalContext?: React.ReactNode;
  providerName: string;
}

export function IntegrationInputCard({
  additionalContext,
  providerName,
  children,
}: PropsWithChildren<IntegrationInputCardProps>) {
  return (
    <Card variant="inner-shadow" sx={{ p: 2 }}>
      <Stack direction="column" alignItems="flex-start" spacing={1}>
        <AssetProviderLinkIndicator providerName={providerName} />
        <Box width="100%" flexGrow={1}>
          {children}
        </Box>
      </Stack>
      {additionalContext && <Box mt={2}>{additionalContext}</Box>}
    </Card>
  );
}
