// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProposalSharingTokenMutationVariables = Types.Exact<{
  proposalId: Types.Scalars['ID']['input'];
}>;


export type CreateProposalSharingTokenMutation = { __typename?: 'Mutation', createProposalSharingToken: { __typename?: 'SharingToken', URL: string } };


export const CreateProposalSharingTokenDocument = gql`
    mutation CreateProposalSharingToken($proposalId: ID!) {
  createProposalSharingToken(proposalID: $proposalId) {
    URL
  }
}
    `;
export type CreateProposalSharingTokenMutationFn = Apollo.MutationFunction<CreateProposalSharingTokenMutation, CreateProposalSharingTokenMutationVariables>;

/**
 * __useCreateProposalSharingTokenMutation__
 *
 * To run a mutation, you first call `useCreateProposalSharingTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProposalSharingTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProposalSharingTokenMutation, { data, loading, error }] = useCreateProposalSharingTokenMutation({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function useCreateProposalSharingTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateProposalSharingTokenMutation, CreateProposalSharingTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProposalSharingTokenMutation, CreateProposalSharingTokenMutationVariables>(CreateProposalSharingTokenDocument, options);
      }
export type CreateProposalSharingTokenMutationHookResult = ReturnType<typeof useCreateProposalSharingTokenMutation>;
export type CreateProposalSharingTokenMutationResult = Apollo.MutationResult<CreateProposalSharingTokenMutation>;
export type CreateProposalSharingTokenMutationOptions = Apollo.BaseMutationOptions<CreateProposalSharingTokenMutation, CreateProposalSharingTokenMutationVariables>;
export const Operations = {
  Mutation: {
    CreateProposalSharingToken: 'CreateProposalSharingToken' as const
  }
}