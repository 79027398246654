import { getSLATTrustSummaryProperties } from '@/modules/entities/details/trusts/SLATTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { TrustBeneficiariesSlide } from '../components/Trust.Beneficiaries';
import { SLATTrustPresentationSlideProps } from './SLATTrust.types';

export function SLATTrustBeneficiariesSlide({
  entity,
  subtype,
  ...registrationProps
}: SLATTrustPresentationSlideProps) {
  const summaryDetails = getSLATTrustSummaryProperties({
    entity,
    entityType: ENTITY_TYPES.SLAT,
  });

  return (
    <TrustBeneficiariesSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      displayName={subtype.displayName}
    />
  );
}
