import { XYPosition } from '@xyflow/react';
import dagre, { GraphLabel } from 'dagre';
import dagreLegacy from 'dagre-legacy';
import { floor } from 'lodash';

import { Edge, Node } from '../types';
import { getNodeMeasuredWidthHeight } from './nodes';

// Typed config options from: https://github.com/dagrejs/dagre/wiki#configuring-the-layout
export interface GraphLayoutConfig extends GraphLabel {
  rankdir?: 'TB' | 'BT' | 'LR' | 'RL';
  align?: 'UL' | 'UR' | 'DL' | 'DR';
  ranker?: 'network-simplex' | 'tight-tree' | 'longest-path';
  labelpos?: 'l' | 'c' | 'r';
  acyclicer?: 'greedy';
}

export type GraphLayoutConstructorOpts = ConstructorParameters<
  typeof dagre.graphlib.Graph
>[0];

export type Direction =
  | 'top-bottom'
  | 'left-right'
  | 'right-left'
  | 'bottom-top';

export const directionToRankdir: Record<
  Direction,
  NonNullable<GraphLayoutConfig['rankdir']>
> = {
  'top-bottom': 'TB',
  'bottom-top': 'BT',
  'left-right': 'LR',
  'right-left': 'RL',
};

export const directionToAlign: Record<
  Direction,
  NonNullable<GraphLayoutConfig['align']>
> = {
  'top-bottom': 'UL',
  'bottom-top': 'UR',
  'left-right': 'DL',
  'right-left': 'DR',
};

interface BuildLegacyDagreGraphFromReactFlowProps {
  nodes: Node[];
  edges: Edge[];
}

export function buildLegacyDagreGraphFromReactFlow({
  nodes,
  edges,
}: BuildLegacyDagreGraphFromReactFlowProps) {
  const graph = new dagreLegacy.Digraph();
  // orderRestarts: -1 forces nodes to apply the order they were passed for a given level of the graph
  // without this, dagre will default to trying to "optimize" the order to minimize space between edges
  graph.graph({ orderRestarts: -1 });

  nodes.forEach(({ id, width, height, data }) => {
    const rank = 'rank' in data ? data.rank : undefined;
    graph.addNode(id, { width, height, rank });
  });

  edges.forEach((edge) => {
    graph.addEdge(null, edge.source, edge.target);
  });

  return graph;
}

interface BuildDagreGraphFromReactFlowProps {
  nodes: (Node & {
    order?: number; // Order determines the horizontal position of nodes within the same rank in a top-to-bottom layout
  })[];
  edges: Edge[];
  direction: Direction;
  graphLayoutConfig?: GraphLayoutConfig;
}

export function buildDagreGraphFromReactFlow({
  nodes,
  edges,
  direction,
  graphLayoutConfig: {
    ranksep = 100,
    edgesep = 10,
    nodesep = 50,
    ...graphLayoutConfig
  } = {},
}: BuildDagreGraphFromReactFlowProps) {
  const graph = new dagre.graphlib.Graph({
    compound: nodes.some((n) => !!n.parentId),
  });

  const config: GraphLayoutConfig = {
    rankdir: directionToRankdir[direction],
    align: directionToAlign[direction],
    ranksep,
    edgesep,
    nodesep,
    ...graphLayoutConfig,
  };

  graph.setGraph(config);
  graph.setDefaultEdgeLabel(() => ({}));

  nodes.forEach((node) => {
    const { id, parentId, order } = node;
    const { width, height } = getNodeMeasuredWidthHeight(node);

    graph.setNode(id, {
      width,
      height,
      order,
    });
    if (parentId) {
      graph.setParent(id, parentId);
    }
  });

  edges.forEach((edge) => {
    graph.setEdge(edge.source, edge.target);
  });

  return graph;
}

interface BuildNodesFromDagreGraphProps {
  nodes: Node[];
  getNodePosition: (id: string) => XYPosition;
}

export function buildNodesFromDagreGraph({
  nodes,
  getNodePosition,
}: BuildNodesFromDagreGraphProps): Node[] {
  return nodes.map((node) => {
    const { x: computedX, y: computedY } = getNodePosition(node.id);
    const { width, height } = getNodeMeasuredWidthHeight(node);
    return {
      ...node,
      // Shifts the dagre computed node position (anchor=center center)
      // so it matches the React Flow node internal anchor point (anchor=top left).
      position: {
        x: floor(computedX - width / 2, 1),
        y: floor(computedY - height / 2, 1),
      },
    };
  });
}
