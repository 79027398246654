import { ButtonGroupInputOption } from '@/components/form/baseInputs/inputTypes';
import { File06Icon } from '@/components/icons/File06Icon';
import { ListIcon } from '@/components/icons/ListIcon';
import { DocumentType } from '@/types/schema';

const standardDocumentTypeOptions = [
  { display: 'Asset valuation', value: DocumentType.AssetValuation },

  { display: 'Trust document', value: DocumentType.SignedTrustDocument },
  {
    display: 'Transfer confirmation',
    value: DocumentType.TransferConfirmation,
  },
  { display: 'Balance sheet', value: DocumentType.BalanceSheet },
  { display: 'Will', value: DocumentType.Will },
  { display: 'Power of attorney', value: DocumentType.PowerOfAttorney },
  {
    display: 'Assignment of interest',
    value: DocumentType.AssignmentOfInterest,
  },
  { display: 'Loan note agreement', value: DocumentType.LoanNoteAgreement },
  {
    display: 'Articles of incorporation',
    value: DocumentType.ArticlesOfIncorporation,
  },
  { display: 'Operating agreement', value: DocumentType.OperatingAgreement },
  { display: 'Insurance policy', value: DocumentType.InsurancePolicy },
  {
    display: 'Insurance premium payment',
    value: DocumentType.InsurancePremiumPayment,
  },
  {
    display: 'Partnership agreement',
    value: DocumentType.PartnershipAgreement,
  },
  {
    display: 'Account documentation statement',
    value: DocumentType.AccountDocumentationStatement,
  },
  { display: 'Tax ID confirmation', value: DocumentType.TaxIdConfirmation },
  { display: 'Gift tax return', value: DocumentType.GiftTaxReturn },
  { display: 'Income tax return', value: DocumentType.IncomeTaxReturn },
  { display: 'Tax receipt', value: DocumentType.TaxReceipt },
  {
    display: 'Bylaws',
    value: DocumentType.CorporateBylaws,
  },
  {
    display: 'LLC agreement',
    value: DocumentType.LlcAgreement,
  },
  {
    display: 'Shareholders agreement',
    value: DocumentType.ShareholdersAgreement,
  },
  {
    display: 'State business filing',
    value: DocumentType.StateBusinessFiling,
  },
  {
    display: 'Crummey letter',
    value: DocumentType.CrummeyLetter,
  },
  {
    display: 'Remainder trust document',
    value: DocumentType.ExistingRemainderTrustDocument,
    disabled: true, // can only create these document through the trust management interface
  },
  {
    display: 'GRAT design summary',
    value: DocumentType.GratDesignSummary,
    disabled: true, // can only create these document via generation
  },
  {
    display: 'Tax filing',
    value: DocumentType.TaxFiling,
  },
  {
    display: `Driver's license/ID`,
    value: DocumentType.DriversLicense,
  },
  {
    display: 'Passport',
    value: DocumentType.Passport,
  },
  {
    display: 'Beneficial ownership information report',
    value: DocumentType.BeneficialOwnershipInformationReport,
  },
  {
    display: 'FinCEN filing',
    value: DocumentType.FincenFiling,
  },
  {
    display: 'Health care proxy',
    value: DocumentType.HealthcareProxy,
  },
].sort((a, b) => {
  return a.display.localeCompare(b.display);
});

// we want OTHER to always be at the end, regardless of alphabetical order
export const DOCUMENT_TYPE_OPTIONS = [
  ...standardDocumentTypeOptions,
  { display: 'Other', value: DocumentType.Other },
];

export const DOCUMENT_DETAILS_VIEW_BUTTON_OPTIONS: ButtonGroupInputOption[] = [
  {
    display: 'Summary',
    value: 'summary',
    buttonProps: {
      startIcon: ListIcon,
    },
  },
  {
    display: 'Document',
    value: 'document',
    buttonProps: {
      startIcon: File06Icon,
    },
  },
];

export const AI_ENABLED_FOR_DOCUMENT_HELP_TEXT =
  'Luminary AI will suggest data from this document to help fill out entity details. Any previous data will be retained, and you can accept or reject suggested data.';

// This is the tooltip text for the inputs where we allow users to associate documents with additional entities
export const ADDITIONAL_DOCUMENTS_POPPER_CONTENT =
  'Document will appear in specified entity’s documents list and can be referenced in document viewer when editing entity details';

export const SUGGESTION_DOCUMENT_TYPES: DocumentType[] = [
  DocumentType.SignedTrustDocument,
  DocumentType.FincenFiling,
  DocumentType.TaxIdConfirmation,
  DocumentType.OperatingAgreement,
  DocumentType.LlcAgreement,
  DocumentType.PartnershipAgreement,
  DocumentType.ShareholdersAgreement,
  DocumentType.AssignmentOfInterest,
];
