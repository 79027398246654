import { compact, keyBy } from 'lodash';
import { useMemo } from 'react';
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
  UseControllerReturn,
} from 'react-hook-form';

import {
  getFieldErrorValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import {
  TypeaheadMultiSelectInput,
  TypeaheadMultiSelectInputProps,
} from '../baseInputs/TypeaheadMultiSelectInput/TypeaheadMultiSelectInput';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

type RelevantExtendedProps = Omit<
  TypeaheadMultiSelectInputProps,
  'values' | 'onChange'
>;

export interface FormAwareTypeaheadMultiSelectInputProps<
  FormShape extends FieldValues,
> extends RelevantExtendedProps {
  fieldName: FieldNameFromFormShape<FormShape>;
  label: string;
  control: Control<FormShape>;
  validation?: RegisterOptions<FormShape>['validate'];
}

export function FormAwareTypeaheadMultiSelectInput<
  FormShape extends FieldValues,
>({
  fieldName,
  control,
  validation,
  ...props
}: FormAwareTypeaheadMultiSelectInputProps<FormShape>) {
  const { label, required } = props;

  const validations = getValidations(label, !!required, validation);
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validations }}
      render={(renderProps) => (
        <FormAwareTypeaheadMultiSelectInputRenderer
          {...renderProps}
          {...props}
        />
      )}
    />
  );
}

type FormAwareTypeaheadMultiSelectInputRendererProps<
  FormShape extends FieldValues,
> = UseControllerReturn<FormShape> & RelevantExtendedProps;

function FormAwareTypeaheadMultiSelectInputRenderer<
  FormShape extends FieldValues,
>({
  field,
  fieldState,
  formState,
  disabled,
  ...props
}: FormAwareTypeaheadMultiSelectInputRendererProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();

  // this is kind of odd, but the TypeaheadMultiSelectInput expects a TypeaheadSelectInputOption<string>[] for values,
  // whereas we really want to persist the value as a string[] in the form state. so we need to do a bit of a conversion here.
  // the tricky part is that the MUI autocomplete component requires a stable reference to the values
  const { options } = props;
  const richValues = useMemo(() => {
    const optionsByValue = keyBy(options, 'value');
    const values = field.value as string[] | undefined;
    return compact(
      values?.map((value) => {
        return optionsByValue[value];
      }) ?? []
    );
  }, [field.value, options]);

  return (
    <TypeaheadMultiSelectInput
      errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
      onBlur={field.onBlur}
      values={richValues}
      name={field.name}
      inputRef={field.ref}
      disabled={disabledFromContext ?? disabled}
      {...props}
      onChange={(_e, values) => {
        // the underlying event doesn't have the appropriate value,
        // so we explicitly pass it through here. we also normalize the other direction, so the form is only aware of the string[]
        // value, not the TypeaheadSelectInputOption<string>[] value
        field.onChange({
          target: {
            value: values.map((v) => v.value),
          },
        });
      }}
    />
  );
}
