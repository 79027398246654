// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { GiftingProposalProjectionFragmentDoc } from '../../../../modules/gifting/proposal/graphql/GiftingProposalProjection.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateGiftProposalMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateProposalInput;
}>;


export type UpdateGiftProposalMutation = { __typename?: 'Mutation', updateProposalV2: { __typename?: 'Proposal', id: string, giftingProposal?: { __typename?: 'GiftingProposal', id: string, giftingScenarios?: Array<{ __typename?: 'GiftingProposalScenario', id: string, projections: { __typename?: 'GiftingProposalProjections', inEstateValueAfterTax: CurrencyUSD, inEstateValueBeforeTax: CurrencyUSD, outOfEstateCharitableValue: CurrencyUSD, outOfEstateFamilyValue: CurrencyUSD, outOfEstateTotalValue: CurrencyUSD, taxableGift: CurrencyUSD, totalValue: CurrencyUSD, remainingLifetimeExemption: CurrencyUSD, lifetimeExemptionAppliedTowardGiftTax: CurrencyUSD, giftTax: CurrencyUSD, scenarioId: string, yearInEstatePortfolioDepleted?: number | null, scenario: { __typename?: 'GiftingProposalScenario', order: number, displayName: string, isDefault: boolean, isBaseline: boolean, id: string }, estateTaxSummary: { __typename?: 'TaxSummary', _cacheKey: string, federalTax?: { __typename?: 'TaxDetail', _cacheKey: string, deductions: CurrencyUSD, exemptionAvailable: CurrencyUSD, exemptionCliff?: CurrencyUSD | null, exemptionUsed: CurrencyUSD, portableExemption?: CurrencyUSD | null, stateCode?: Types.StateCode | null, stateDisclosure?: string | null, tax: CurrencyUSD, taxableAmount: CurrencyUSD, taxableAmountAfterExemption: CurrencyUSD, taxableTransfers: CurrencyUSD, totalExemption: CurrencyUSD, taxableAmountOverCliff?: boolean | null } | null, stateTax?: Array<{ __typename?: 'TaxDetail', _cacheKey: string, deductions: CurrencyUSD, exemptionAvailable: CurrencyUSD, exemptionCliff?: CurrencyUSD | null, exemptionUsed: CurrencyUSD, portableExemption?: CurrencyUSD | null, stateCode?: Types.StateCode | null, stateDisclosure?: string | null, tax: CurrencyUSD, taxableAmount: CurrencyUSD, taxableAmountAfterExemption: CurrencyUSD, taxableTransfers: CurrencyUSD, totalExemption: CurrencyUSD, taxableAmountOverCliff?: boolean | null }> | null } } }> | null } | null } };


export const UpdateGiftProposalDocument = gql`
    mutation UpdateGiftProposal($input: AugmentedUpdateProposalInput!) {
  updateProposalV2(input: $input) {
    id
    giftingProposal {
      id
      giftingScenarios {
        id
        projections {
          ...GiftingProposalProjection
        }
      }
    }
  }
}
    ${GiftingProposalProjectionFragmentDoc}`;
export type UpdateGiftProposalMutationFn = Apollo.MutationFunction<UpdateGiftProposalMutation, UpdateGiftProposalMutationVariables>;

/**
 * __useUpdateGiftProposalMutation__
 *
 * To run a mutation, you first call `useUpdateGiftProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGiftProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGiftProposalMutation, { data, loading, error }] = useUpdateGiftProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGiftProposalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGiftProposalMutation, UpdateGiftProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGiftProposalMutation, UpdateGiftProposalMutationVariables>(UpdateGiftProposalDocument, options);
      }
export type UpdateGiftProposalMutationHookResult = ReturnType<typeof useUpdateGiftProposalMutation>;
export type UpdateGiftProposalMutationResult = Apollo.MutationResult<UpdateGiftProposalMutation>;
export type UpdateGiftProposalMutationOptions = Apollo.BaseMutationOptions<UpdateGiftProposalMutation, UpdateGiftProposalMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateGiftProposal: 'UpdateGiftProposal' as const
  }
}