import Decimal from 'decimal.js';

import { TransferDirection } from '../HypotheticalTransfersCard/hypotheticalTransfers/HypotheticalTransferForm';
import {
  AnnuallyRecurringValue,
  HypotheticalTransferFormModalShape,
} from './HypotheticalTransferModal.types';

export const DEFAULT_VALUES: HypotheticalTransferFormModalShape = {
  sourceId: '',
  sourceOwnedEntityId: '',
  sourceInsurancePolicyId: '',
  targetId: '',

  transferToNewDraft: false,
  draftEntityType: '',
  draftEntityName: '',
  draftEntityGrantorId: '',

  transferName: '',
  transferAmount: null,
  taxableValue: null,
  transferFrequency: AnnuallyRecurringValue.false,
  transferStartYear: new Date().getFullYear(),
  transferEndYear: new Date().getFullYear(),
  transferGrowthRate: new Decimal(0),
  transferType: '',
  transferGstGift: false,

  taxDiscountRate: null,

  _yearOptionsDisabled: false,
  _discountRateToggle: false,
  _policyOrAsset: null,
  _activeTransfer: null,
};

export function getDefaultValues(
  initiatingId: string | undefined,
  transferId: string | undefined,
  initiatingDirection: TransferDirection | undefined
): HypotheticalTransferFormModalShape {
  if (!initiatingId) return { ...DEFAULT_VALUES };
  if (!initiatingDirection) return { ...DEFAULT_VALUES };
  if (transferId) return { ...DEFAULT_VALUES };

  const output = { ...DEFAULT_VALUES };

  if (initiatingDirection === TransferDirection.Outbound) {
    output.sourceId = initiatingId;
  } else if (initiatingDirection === TransferDirection.Inbound) {
    output.targetId = initiatingId;
  }
  return output;
}
