import { getILITTrustSummaryProperties } from '@/modules/entities/details/trusts/ILITTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { TrustBeneficiariesSlide } from '../components/Trust.Beneficiaries';
import { ILITTrustPresentationSlideProps } from './ILITTrust.types';

export function ILITTrustBeneficiariesSlide({
  entity,
  subtype,
  ...registrationProps
}: ILITTrustPresentationSlideProps) {
  const summaryDetails = getILITTrustSummaryProperties({
    entity,
    entityType: ENTITY_TYPES.ILIT,
  });

  return (
    <TrustBeneficiariesSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      displayName={subtype.displayName}
    />
  );
}
