import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { FormAwareTypeaheadListInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadListInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { EmptyListItem } from '@/components/typography/EmptyListItem';
import { useReportError } from '@/hooks/useReportError';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { CollaboratorAccessWarning } from '@/modules/collaborators/content/CollaboratorAccessWarning';

import { useUpdateAdvisorClientMutation } from '../graphql/Update.generated';
import {
  ManageHouseholdAccessFormPaths,
  ManageHouseholdAccessFormShape,
} from './ManageHouseholdAccessForm.types';
import { RemoveAssociatedUserButton } from './RemoveAssociatedUserButton';
import { useHouseholdAccessFormData } from './useHouseholdAccessFormOptions';

interface ManageHouseholdAccessFormProps {
  householdId: string;
}

/**
 * @description This component is responsible for allowing users to manage the access of employees and collaborators
 * to this client.
 */
export function ManageHouseholdAccessForm({
  householdId,
}: ManageHouseholdAccessFormProps) {
  const canEditCollaborators = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_EDIT_CLIENT_COLLABORATORS_AND_EMPLOYEES
  );

  const { createSuccessFeedback, showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [updateClient] = useUpdateAdvisorClientMutation();

  const {
    formData,
    assignableEmployeeOptions,
    collaboratorOptions,
    userToTaskAssignmentMap,
    userIdToEmployeeIdMap,
    relationshipOwnerId,
    loading,
    refetch,
  } = useHouseholdAccessFormData({ householdId });
  const formMethods = useForm<ManageHouseholdAccessFormShape>({
    defaultValues: formData,
  });

  const { control, reset } = formMethods;

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  async function handleAddCollaborator(userId: string) {
    await updateClient({
      variables: {
        updateHouseholdId: householdId,
        input: {
          addCollaboratorIDs: [userId],
        },
      },
      onCompleted: createSuccessFeedback(
        'Successfully added collaborator to client'
      ),
      onError: (err) => {
        showFeedback('Could not add collaborator to client');
        reportError('failed to add collaborator to client', err);
      },
    });
  }

  async function handleAddEmployee(userId: string) {
    const employeeId = userIdToEmployeeIdMap[userId];
    if (!employeeId) {
      throw new Error('Could not look up employee ID for user');
    }

    await updateClient({
      variables: {
        updateHouseholdId: householdId,
        input: {
          addAssignedEmployeeIDs: [employeeId],
        },
      },
      onCompleted: createSuccessFeedback(
        'Successfully added employee to client'
      ),
      onError: (err) => {
        showFeedback('Could not add employee to client');
        reportError('failed to add collaborator to client', err);
      },
    });
  }

  async function handleAfterUserRemoval() {
    await refetch();
  }

  return (
    <FormProvider {...formMethods}>
      <FormFieldsDisabledProvider isDisabled={!canEditCollaborators}>
        <FormLayoutRow>
          <FormLayoutItem width={6}>
            <Card variant="outlined" sx={{ p: 3 }}>
              <Typography variant="h1" sx={{ mb: 3 }}>
                Additional internal users
              </Typography>
              <FormAwareTypeaheadListInput
                control={control}
                disabled={assignableEmployeeOptions.length === 0}
                placeholder={
                  assignableEmployeeOptions.length || loading
                    ? 'Search for users'
                    : 'No users available'
                }
                emptyListPlaceholder={
                  <EmptyListItem>No other assigned users</EmptyListItem>
                }
                name={
                  `assignedUsers` as const satisfies ManageHouseholdAccessFormPaths
                }
                options={assignableEmployeeOptions}
                loading={loading}
                onAddItem={handleAddEmployee}
                RemoveButton={({ id: userId, ...props }) =>
                  userId === relationshipOwnerId ? null : (
                    <RemoveAssociatedUserButton
                      {...props}
                      householdId={householdId}
                      employeeId={userIdToEmployeeIdMap[userId]}
                      userId={userId}
                      hasTasksToReassign={
                        userToTaskAssignmentMap[userId] ?? false
                      }
                      onAfterRemove={handleAfterUserRemoval}
                    />
                  )
                }
              />
            </Card>
          </FormLayoutItem>
          <FormLayoutItem width={6}>
            <Card variant="outlined" sx={{ p: 3 }}>
              <Stack spacing={2}>
                <Typography variant="h1" sx={{ mb: 3 }}>
                  External collaborators
                </Typography>
                <Typography variant="body1">
                  <CollaboratorAccessWarning />
                </Typography>
                <FormAwareTypeaheadListInput
                  control={control}
                  disabled={collaboratorOptions.length === 0}
                  placeholder={
                    collaboratorOptions.length || loading
                      ? 'Search for collaborators'
                      : 'No collaborators available'
                  }
                  emptyListPlaceholder={
                    <EmptyListItem>
                      No collaborators added to this client
                    </EmptyListItem>
                  }
                  name={
                    `collaborators` as const satisfies ManageHouseholdAccessFormPaths
                  }
                  options={collaboratorOptions}
                  loading={loading}
                  onAddItem={(id) => handleAddCollaborator(id)}
                  RemoveButton={({ id: userId, ...props }) => (
                    <RemoveAssociatedUserButton
                      {...props}
                      householdId={householdId}
                      userId={userId}
                      hasTasksToReassign={
                        userToTaskAssignmentMap[userId] ?? false
                      }
                      onAfterRemove={handleAfterUserRemoval}
                    />
                  )}
                />
              </Stack>
            </Card>
          </FormLayoutItem>
        </FormLayoutRow>
      </FormFieldsDisabledProvider>
    </FormProvider>
  );
}
