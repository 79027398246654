import { Grid, Stack } from '@mui/material';

import { useRequiredParam } from '@/hooks/useRequiredParam';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { HouseholdEstateValueMetricsPortrait } from '@/modules/household/HouseholdEstateValueMetrics/HouseholdEstateValueMetricsPortrait';
import { HouseholdEstimatedTaxSavings } from '@/modules/household/HouseholdEstimatedTaxSavings';
import { HouseholdExemptionsOverview } from '@/modules/household/HouseholdExemptionsOverview';
import { HouseholdTaxForWaterfall } from '@/modules/household/HouseholdTaxForWaterfall/HouseholdTaxForWaterfall';
import { TasksDue } from '@/modules/tasks/components/TasksDue/TasksDue';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { ClientDetailsOverviewPageCard } from './ClientDetailsOverviewPageCard';
import { useClientDetailsOverviewPageQuery } from './hooks/useClientDetailsOverviewPageQuery';
import { useLayoutDefinition } from './hooks/useLayoutDefinition';

interface ClientDetailsOverviewPageInnerProps {
  householdId: string;
  household: ReturnType<typeof useClientDetailsOverviewPageQuery>['household'];
  hasEntities: ReturnType<
    typeof useClientDetailsOverviewPageQuery
  >['hasEntities'];
}

function ClientDetailsOverviewPageInner({
  householdId,
  household,
  hasEntities,
}: ClientDetailsOverviewPageInnerProps) {
  const canViewTasks = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_VIEW_TASKS
  );

  const { gridTemplateAreas, gridTemplateColumns, gridTemplateRows } =
    useLayoutDefinition();

  return (
    <Stack spacing={3} height="100%">
      <Grid
        display="grid"
        gap={3}
        container
        gridTemplateColumns={gridTemplateColumns}
        gridTemplateRows={gridTemplateRows}
        gridTemplateAreas={gridTemplateAreas}
      >
        <Grid item gridArea="total-assets">
          <ClientDetailsOverviewPageCard includeHelpContentTrigger>
            <HouseholdEstateValueMetricsPortrait
              metrics={household?.estateValueMetrics}
              householdId={householdId}
              hasEntities={hasEntities}
            />
          </ClientDetailsOverviewPageCard>
        </Grid>
        <Grid item gridArea="estate-tax">
          <ClientDetailsOverviewPageCard includeHelpContentTrigger>
            <HouseholdTaxForWaterfall
              // Make this component reinitialize when the householdId changes
              key={householdId}
              householdId={householdId}
            />
          </ClientDetailsOverviewPageCard>
        </Grid>
        <Grid item gridArea="tax-savings">
          <ClientDetailsOverviewPageCard includeHelpContentTrigger>
            <HouseholdEstimatedTaxSavings
              impliedEstateTaxSavings={
                household?.clientPerformanceReport.impliedEstateTaxSavings
              }
              wealthTransferred={
                household?.clientPerformanceReport.totalWealthTransferred
              }
              householdId={householdId}
            />
          </ClientDetailsOverviewPageCard>
        </Grid>
        <Grid item gridArea="exemptions">
          <ClientDetailsOverviewPageCard>
            <HouseholdExemptionsOverview household={household} />
          </ClientDetailsOverviewPageCard>
        </Grid>
      </Grid>

      {canViewTasks && (
        <Stack width="100%">
          <TasksDue
            householdId={householdId}
            viewAllPath={getCompletePathFromRouteKey(
              ROUTE_KEYS.HOUSEHOLD_DETAILS_TASKS,
              { householdId }
            )}
            from={ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW}
          />
        </Stack>
      )}
    </Stack>
  );
}

export function ClientDetailsOverviewPage() {
  const householdId = useRequiredParam('householdId');

  const { household, hasEntities } = useClientDetailsOverviewPageQuery({
    householdId,
  });

  return (
    <ClientDetailsOverviewPageInner
      householdId={householdId}
      household={household}
      hasEntities={hasEntities}
    />
  );
}
