import { Box } from '@mui/material';

import { getJointPersonalAccountSummaryProperties } from '@/modules/entities/details/accounts/JointPersonalAccountDetails.utils';
import { EntityDetail_JointPersonalAccountFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { ClientPresentationBundleTypes } from '@/modules/presentation/clientPresentation/clientPresentation.types';
import { useUnguardedClientPresentationDesignerContext } from '@/modules/presentation/clientPresentation/contexts/clientPresentationDesigner.context';
import { useRegisterBundle } from '@/modules/presentation/clientPresentation/hooks/useRegisterBundle';

import { DispositionScenarioSlide } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide';
import { getFilteredDispositionSlideProps } from '../../components/DispositionScenarioSlide/DispositionScenarioSlide.utils';
import {
  EntityPresentationBundleProps,
  EntityPresentationSlideType,
  EntitySlideMap,
} from '../../entityPresentations.types';
import {
  generateEntityBundleId,
  getPresentationEntitySlideProps,
} from '../../entityPresentations.utils';
import { useOrderedEntitySlides } from '../../hooks/useFilteredEntitySlides';
import { PersonalAccountBeneficiariesSlide } from '../components/PersonalAccount.Beneficiaries';
import { PersonalAccountOverviewSlide } from '../components/PersonalAccount.OverviewSlide';

type JointPersonalAccountPresentationBundleProps =
  EntityPresentationBundleProps<EntityDetail_JointPersonalAccountFragment>;

export function JointPersonalAccountPresentationBundle({
  slideTypes,
  SlideWrapper = Box,
  entity,
  subtype,
  dispositionScenarios,
  entityId,
}: JointPersonalAccountPresentationBundleProps) {
  const displayName = subtype.displayName;
  const summary = getJointPersonalAccountSummaryProperties({
    entity,
    entityType: ENTITY_TYPES.JOINT_ACCOUNT,
  });
  const grantors =
    entity.household.possiblePrimaryClients?.map((grantor) => ({
      displayName: grantor.displayName,
      id: grantor.id,
    })) ?? [];
  const bundleId = generateEntityBundleId(entityId);
  useRegisterBundle({
    type: ClientPresentationBundleTypes.ENTITY,
    id: bundleId,
    displayName,
    identifier: entityId,
  });

  const SLIDE_MAP: EntitySlideMap = {
    [EntityPresentationSlideType.OVERVIEW]: (
      <PersonalAccountOverviewSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.OVERVIEW,
          slideTypes
        )}
        entity={entity}
        summary={summary}
        displayName={displayName}
      />
    ),
    [EntityPresentationSlideType.BENEFICIARIES]: (
      <PersonalAccountBeneficiariesSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.BENEFICIARIES,
          slideTypes
        )}
        displayName={displayName}
        entity={entity}
        summary={summary}
      />
    ),
    [EntityPresentationSlideType.DISPOSITIVE_PROVISIONS]:
      getFilteredDispositionSlideProps({
        dispositionScenarios,
        entityId,
        slideTypes,
      }).map((slideProps, i) => (
        <DispositionScenarioSlide
          {...slideProps}
          key={i}
          displayName={displayName}
          entity={entity}
          grantors={grantors}
        />
      )),
  };

  const slides = useOrderedEntitySlides(slideTypes, SLIDE_MAP);
  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  if (!shouldShowItem(entityId)) {
    return null;
  }

  return (
    <>
      {slides.map((slide, index) => {
        return <SlideWrapper key={index}>{slide}</SlideWrapper>;
      })}
    </>
  );
}
