import {
  GetDispositiveProvisions_EstateWaterfallVizEdgeAssociatedDispositiveProvisionFragment,
  GetDispositiveProvisions_EstateWaterfallVizNodeFragment,
} from '../DispositiveProvisionsForm/graphql/GetDispositiveProvisions.generated';

export enum DispositiveProvisionDirection {
  Receiving = 'receiving',
  Distributing = 'distributing',
}

export enum UponDeath {
  First = 'uponFirstDeath',
  Second = 'uponSecondDeath',
}

export interface DispositiveProvisionWithEdges
  extends GetDispositiveProvisions_EstateWaterfallVizEdgeAssociatedDispositiveProvisionFragment {
  from?: GetDispositiveProvisions_EstateWaterfallVizNodeFragment;
  to?: GetDispositiveProvisions_EstateWaterfallVizNodeFragment;
}
