import { Box, Stack } from '@mui/material';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareTypeaheadSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';

import { getClientsOptions, useClientData } from './ClientImportExport.utils';
import { useExportAdvisorClientMutation } from './graphql/ClientImportExport.generated';

interface AdvisorClientCloneCardFormShape {
  householdId: string;
}

function triggerJSONFileDownload(data: string, householdId: string) {
  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(data);
  const anchor = document.createElement('a');
  anchor.setAttribute('href', dataStr);
  anchor.setAttribute('download', `export_${householdId}.json`);
  document.body.appendChild(anchor); // required for firefox
  anchor.click();
  anchor.remove();
}

export function ClientExport() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { loading: clientDataLoading, clientsData } = useClientData();

  const [exportHousehold] = useExportAdvisorClientMutation({
    onError: (err) => {
      showFeedback('Failed to export client');
      reportError('could not export client', err);
    },
  });

  const formMethods = useForm<AdvisorClientCloneCardFormShape>();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  async function onClientExport(formValues: AdvisorClientCloneCardFormShape) {
    return exportHousehold({
      variables: { id: formValues.householdId },
      onCompleted: (data) => {
        if (data?.exportHousehold) {
          triggerJSONFileDownload(data.exportHousehold, formValues.householdId);
        }
      },
    });
  }

  return (
    <FormProvider {...formMethods}>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="end"
        noValidate
        component="form"
        onSubmit={handleSubmit(onClientExport)}
      >
        <Box sx={{ flexGrow: 1 }}>
          <FormAwareTypeaheadSelectInput<AdvisorClientCloneCardFormShape>
            label="Client to export"
            fieldName={'householdId'}
            required
            options={getClientsOptions(clientsData)}
            disabled={isSubmitting || clientDataLoading}
            control={control}
          />
        </Box>
        <Box>
          <Button
            disabled={isSubmitting}
            variant="primary"
            size="sm"
            type="submit"
          >
            Export client
          </Button>
        </Box>
      </Stack>
    </FormProvider>
  );
}
