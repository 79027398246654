import { Box, Stack } from '@mui/material';
import { PropsWithChildren, ReactNode, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { diagnostics } from '@/utils/diagnostics';

import { DataTableCard } from '../DataTable/DataTableCard';
import type { Filter, Tab } from './types';

const BUTTON_GROUP_ITEM_DEFAULT_WIDTH = 150;
export const DEFAULT_FILTER_SEARCH_PARAM = 'showType';

export type FilterableTableProps = PropsWithChildren<{
  tabs: Tab[];
  filter: Filter;
  title: string;
  actions?: ReactNode;
  belowHeaderContent?: ReactNode;
  // A function that conditionally renders content below the tabs, but above the rows, based on the selected tab
  belowTabContentRenderer?: (selectedTab: string) => ReactNode;
}>;

// This component is a wrapper to be used around a table
// that allows for filtering of the table's rows.
export function FilterableTable({
  children,
  title,
  filter,
  tabs,
  actions,
  belowHeaderContent,
  belowTabContentRenderer,
}: FilterableTableProps) {
  const defaultTab = tabs[0]?.value;
  useEffect(() => {
    if (!defaultTab) {
      diagnostics.debug(`no tab provided to filterable table`);
    }
  }, [defaultTab]);

  const defaultSearchParams =
    Boolean(tabs.length) &&
    new URLSearchParams({
      [DEFAULT_FILTER_SEARCH_PARAM]: defaultTab ?? '',
    });
  const [searchParams, setSearchParams] = useSearchParams(
    defaultSearchParams || {}
  );
  const filterValue = searchParams.get(DEFAULT_FILTER_SEARCH_PARAM)!;
  const buttonGroupWidth = BUTTON_GROUP_ITEM_DEFAULT_WIDTH * tabs.length;
  const { filterCallback } = filter;
  const hasTabs = tabs.length > 0;

  useEffect(() => {
    if (!filterValue) {
      return;
    }

    filterCallback({ tab: filterValue });
  }, [filterValue, filterCallback]);

  const belowTabContent = useMemo(
    () => belowTabContentRenderer?.(filterValue),
    [belowTabContentRenderer, filterValue]
  );

  return (
    <SubpageLayout heading={title} actions={actions}>
      <Stack spacing={3}>
        {belowHeaderContent && belowHeaderContent}
        <Stack height="100%">
          <DataTableCard>
            {hasTabs && (
              <Stack spacing={2} mb={3} width={buttonGroupWidth}>
                <ButtonGroup
                  onChange={(_e, value) => {
                    searchParams.set(DEFAULT_FILTER_SEARCH_PARAM, value);
                    setSearchParams(searchParams);
                  }}
                  value={filterValue}
                  label=""
                  options={tabs}
                />
              </Stack>
            )}
            {belowTabContent && <Box mb={3}>{belowTabContent}</Box>}
            {children}
          </DataTableCard>
        </Stack>
      </Stack>
    </SubpageLayout>
  );
}
