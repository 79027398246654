import { Box } from '@mui/material';

import { CursorClick02Icon } from '@/components/icons/CursorClick02Icon';
import {
  ActionCard,
  ActionCardProps,
} from '@/components/layout/ActionCard/ActionCard';
import { COLORS } from '@/styles/tokens/colors';

type ClientPresentationSidebarEmptyStateProps = Pick<
  ActionCardProps,
  'heading' | 'description'
>;

export function ClientPresentationSidebarEmptyState(
  props: ClientPresentationSidebarEmptyStateProps
) {
  return (
    <Box height="100%" p={3} display="flex" alignItems="center">
      <ActionCard
        icon={<CursorClick02Icon size={48} sx={{ color: COLORS.GRAY[200] }} />}
        variant="noCard"
        {...props}
      />
    </Box>
  );
}
