import { ApolloError } from '@apollo/client';
import { useState } from 'react';

import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import * as diagnostics from '@/utils/diagnostics';

import { useCreateDocumentSharingTokenMutation } from './graphql/UseCopySharingURL.generated';

export function useCopyDocumentSharingURL(
  documentId: string,
  onSuccess: (message: string) => void,
  onError: (friendlyError: Error) => void
): [() => void, { loading: boolean; error: ApolloError | undefined }] {
  const trackUserEvent = useTrackUserEvent();
  const [generatedShareURL, setGeneratedShareURL] = useState<string | null>(
    null
  );
  const [copyToClipboard] = useCopyToClipboard();
  const [createSharingToken, { loading, error }] =
    useCreateDocumentSharingTokenMutation({
      variables: {
        documentId,
      },
    });

  const handleSuccess = (message: string) => {
    trackUserEvent('document share link copied', {
      documentId,
    });
    onSuccess(message);
  };

  async function copySharingURL() {
    // we've already created it and don't want to create another; just re-use the previously generated one!
    if (generatedShareURL) {
      return copyToClipboard(generatedShareURL, handleSuccess, onError);
    }

    const { data } = await createSharingToken();
    if (data?.createDocumentSharingToken?.URL) {
      setGeneratedShareURL(data.createDocumentSharingToken.URL);
      copyToClipboard(
        data.createDocumentSharingToken.URL,
        handleSuccess,
        onError
      );
    } else {
      if (error) {
        diagnostics.error(
          'failed to generate a sharing token for document',
          error,
          {
            documentId,
          }
        );
      } else {
        diagnostics.error(
          'unknown error in generating a sharing token for document',
          new Error('no document sharing token generated'),
          { documentId }
        );
      }
      onError(
        new Error(
          'We failed to generate a sharing link. Please refresh the page and try again.'
        )
      );
    }
  }

  return [copySharingURL, { loading, error }];
}
