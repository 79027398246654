import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';

import { useCurrentUser } from '../../../authentication/hooks/useCurrentUser';

/**
 * @returns true if Black Diamond is enabled and configured for the tenant and
 * the user is currently logged in to Black Diamond.
 */
export function useIsUserLoggedIntoBlackDiamond() {
  const currentUser = useCurrentUser();
  const userCanConnectToBlackDiamond = useCanUserConnectToBlackDiamond();
  const userIsLoggedIn = currentUser?.loggedInToBlackDiamond ?? false;
  return userIsLoggedIn && userCanConnectToBlackDiamond;
}

/**
 * @returns true if Black Diamond is configured on the tenant.
 */
export function useCanUserConnectToBlackDiamond() {
  const { integrationConfiguration } = useTenantDetailsContext();
  const userCanConnectToBlackDiamond =
    integrationConfiguration?.blackDiamondEnabled ?? false;

  return userCanConnectToBlackDiamond;
}
