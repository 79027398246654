import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';

import { useFormContext } from '@/components/react-hook-form';
import { determineDefaultAssetClassId } from '@/modules/assets/utils';
import { BasicAssetsSubform } from '@/modules/entities/BasicAssetsSubform/BasicAssetsSubform';
import {
  BASIC_ASSETS_INITIAL_FUNDING_SUBFORM,
  BASIC_ASSETS_SUBFORM,
} from '@/modules/entities/BasicAssetsSubform/BasicAssetsSubform.types';
import { getVariantFromEntityType } from '@/modules/entities/BasicAssetsSubform/BasicAssetsSubform.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import { useDefaultAssetClassId } from '@/modules/tenant/TenantDetailsContext/hooks/useDefaultAssetClassId';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';

import {
  EntityShortFormPaths,
  EntityShortFormShape,
} from '../CreateEntityShortForm.types';

const { FormComponent: BasicAssetsFormComponent } = new BasicAssetsSubform();

type AssetsVariant = 'default' | 'initialFunding';

const ASSET_VARIANT_HEADINGS: Record<AssetsVariant, string> = {
  default: 'Current valuation',
  initialFunding: 'Initial funding valuation',
};

export interface AssetsProps {
  entityType: EntityType;
  householdId: string;
  variant?: AssetsVariant;
}

export function Assets({
  entityType,
  householdId,
  variant = 'default',
}: AssetsProps) {
  const defaultAssetClassId = useDefaultAssetClassId();
  const { assetClassesById } = useTenantDetailsContext();
  const assetsSubformNamespace =
    variant === 'default'
      ? BASIC_ASSETS_SUBFORM
      : BASIC_ASSETS_INITIAL_FUNDING_SUBFORM;
  const {
    setValue,
    formState: { isLoading, dirtyFields },
  } = useFormContext<EntityShortFormShape>();

  useEffect(() => {
    const isAssetClassFieldDirty = (() => {
      if (variant === 'default') {
        return dirtyFields[assetsSubformNamespace]?.basicAssets
          ?.assetCategoryId;
      }
      return dirtyFields[assetsSubformNamespace]?.basicAssetsInitialFunding
        ?.assetCategoryId;
    })();

    if (isAssetClassFieldDirty) return;
    const formAssetClassId = determineDefaultAssetClassId(
      entityType,
      defaultAssetClassId,
      assetClassesById
    );

    const assetCategoryField = (() => {
      if (variant === 'default') {
        return `${assetsSubformNamespace}.basicAssets.assetCategoryId` as const satisfies EntityShortFormPaths;
      }
      return `${assetsSubformNamespace}.basicAssetsInitialFunding.assetCategoryId` as const satisfies EntityShortFormPaths;
    })();

    setValue(assetCategoryField, formAssetClassId, {
      shouldDirty: false,
    });
  }, [
    assetClassesById,
    assetsSubformNamespace,
    defaultAssetClassId,
    dirtyFields,
    entityType,
    setValue,
    variant,
  ]);

  return (
    <Box>
      {entityType === 'qprt' && (
        <Typography mb={1} variant="h4">
          {ASSET_VARIANT_HEADINGS[variant]}
        </Typography>
      )}
      <BasicAssetsFormComponent
        hideDescription
        householdId={householdId}
        variant={getVariantFromEntityType(entityType)}
        namespace={assetsSubformNamespace}
        disabled={isLoading}
        entityType={entityType}
      />
    </Box>
  );
}
