import Decimal from 'decimal.js';

import { Callout } from '@/components/notifications/Callout/Callout';

import { ClientPresentationDesigner_EstateWaterfallFragment } from '../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { getModelingDisclaimerText } from './estateWaterfallPresentation.utils';

interface DisclaimerCalloutProps {
  waterfall: ClientPresentationDesigner_EstateWaterfallFragment;
}

export function DisclaimerCallout({ waterfall }: DisclaimerCalloutProps) {
  return (
    <Callout severity="info-low">
      {getModelingDisclaimerText({
        estateTaxExemptionGrowthAssumption:
          waterfall.exemptionGrowthRate ?? new Decimal(0),
        includeExemptionSunset: waterfall.willExemptionSunset ?? false,
        estateTaxRateAssumption: new Decimal(40),
      })}
    </Callout>
  );
}
