import { Box } from '@mui/material';

import { getPublicImageUrl } from '@/utils/staticFiles';

export function LuminaryAILogo() {
  return (
    <Box textAlign="center">
      <img
        src={getPublicImageUrl('/LuminaryAiLogo.svg')}
        alt="Luminary AI"
        height={24}
      />
    </Box>
  );
}

export function LuminaryAiBetaLogo() {
  return (
    <Box textAlign="center">
      <img
        src={getPublicImageUrl('/LuminaryAiBetaLogo.svg')}
        alt="Luminary AI Beta"
        height={24}
      />
    </Box>
  );
}
