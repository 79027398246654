import { noop } from 'lodash';
import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { NormalizedUploadedFileData } from '@/modules/documents/documentUploaderUtils';
import { CreateFileMutation } from '@/modules/files/FileUploader/graphql/CreateFile.generated';
import { UploadedFileData } from '@/modules/files/FileUploader/useUploadFile';
import { DocumentType } from '@/types/schema';

export interface MultiDocumentUploaderContext {
  uploadedFiles: NormalizedUploadedFileData[];
  handleUploadFilesStarted: (files: File[]) => void;
  handleUploadFilesComplete: (
    uploaded: UploadedFileData<CreateFileMutation>[]
  ) => void;
  handleDeleteFile: (fileId: string) => void;
  handleDownloadFile: (downloadUrl: string, filename: string) => void;
  setDocumentTypeOfUploadedFile: (
    fileId: string,
    documentType: DocumentType
  ) => void;
  /**
   * Sends uploaded files within the context to the backend, and returns the document IDs.
   * This will throw on error, which should be handled by the caller.
   *
   * @param householdId
   * @param suggestionsEnabled
   * @param defaultDocumentType
   * @param entityId
   */
  uploadDocuments: ({
    householdId,
    suggestionsEnabled,
    defaultDocumentType,
    entityId,
  }: {
    householdId: string;
    suggestionsEnabled: boolean;
    defaultDocumentType: DocumentType;
    entityId?: string;
  }) => Promise<string[]>;
  fileIDTypeMap: Record<string, DocumentType>;
  clearUploadedFiles: () => void;
  uploaderErrorMessage: string | undefined;
  setUploaderErrorMessage: (errorMessage: string | undefined) => void;
  clearUploaderErrorMessage: () => void;
  inferringDocumentTypes: boolean;
}

export const MultiDocumentUploaderContext =
  createContext<MultiDocumentUploaderContext>({
    uploadedFiles: [],
    handleUploadFilesStarted: noop,
    handleUploadFilesComplete: noop,
    handleDeleteFile: noop,
    handleDownloadFile: noop,
    setDocumentTypeOfUploadedFile: noop,
    uploadDocuments: () => Promise.resolve([]),
    fileIDTypeMap: {},
    clearUploadedFiles: noop,
    uploaderErrorMessage: undefined,
    setUploaderErrorMessage: noop,
    clearUploaderErrorMessage: noop,
    inferringDocumentTypes: false,
  });

export const useMultiDocumentUploaderContext = () => {
  return useGuardedContext(
    MultiDocumentUploaderContext,
    'MultiDocumentUploaderContextProvider'
  );
};
