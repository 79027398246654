// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type Get7520RateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type Get7520RateQuery = { __typename?: 'Query', official7520InterestRate: { __typename?: 'InterestRateSummary', currentMonth: { __typename?: 'InterestRateDetails', interestRate: Percent, monthYearDisplay: string }, nextMonth?: { __typename?: 'InterestRateDetails', interestRate: Percent, monthYearDisplay: string } | null } };


export const Get7520RateDocument = gql`
    query Get7520Rate {
  official7520InterestRate {
    currentMonth {
      interestRate
      monthYearDisplay
    }
    nextMonth {
      interestRate
      monthYearDisplay
    }
  }
}
    `;

/**
 * __useGet7520RateQuery__
 *
 * To run a query within a React component, call `useGet7520RateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet7520RateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet7520RateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet7520RateQuery(baseOptions?: Apollo.QueryHookOptions<Get7520RateQuery, Get7520RateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Get7520RateQuery, Get7520RateQueryVariables>(Get7520RateDocument, options);
      }
export function useGet7520RateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get7520RateQuery, Get7520RateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Get7520RateQuery, Get7520RateQueryVariables>(Get7520RateDocument, options);
        }
export function useGet7520RateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<Get7520RateQuery, Get7520RateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<Get7520RateQuery, Get7520RateQueryVariables>(Get7520RateDocument, options);
        }
export type Get7520RateQueryHookResult = ReturnType<typeof useGet7520RateQuery>;
export type Get7520RateLazyQueryHookResult = ReturnType<typeof useGet7520RateLazyQuery>;
export type Get7520RateSuspenseQueryHookResult = ReturnType<typeof useGet7520RateSuspenseQuery>;
export type Get7520RateQueryResult = Apollo.QueryResult<Get7520RateQuery, Get7520RateQueryVariables>;
export const Operations = {
  Query: {
    Get7520Rate: 'Get7520Rate' as const
  }
}