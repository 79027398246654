import { isToday, isYesterday } from 'date-fns';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';

import { formatDateToMMDDYY } from './dates';

export function getFormattedDate(
  date: Date | null,
  opts?: {
    lowercased: boolean;
  }
): string {
  const { lowercased = false } = opts ?? {};

  if (!date) {
    return EMPTY_CONTENT_HYPHEN;
  }

  if (isToday(date)) {
    const today = 'Today';
    return lowercased ? today.toLowerCase() : today;
  } else if (isYesterday(date)) {
    const yesterday = 'Yesterday';
    return lowercased ? yesterday.toLowerCase() : yesterday;
  }

  return formatDateToMMDDYY(date);
}
