import { isEqual } from 'lodash';
import { HTMLAttributes, memo, useCallback } from 'react';

import { BusinessEntitiesSummaryPanel } from '@/modules/summaryPanels/BusinessEntitiesSummaryPanel/BusinessEntitiesSummaryPanel';
import { ClientProfileSummaryPanel } from '@/modules/summaryPanels/ClientProfileSummaryPanel/ClientProfileSummaryPanel';
import { EntitySummaryPanel } from '@/modules/summaryPanels/EntitySummaryPanel/EntitySummaryPanel';
import { OrganizationSummaryPanel } from '@/modules/summaryPanels/OrganizationSummaryPanel/OrganizationSummaryPanel';

import { useEntityMapContext } from '../../contexts/entityMap.context';

export interface NodeSummaryPanelProps extends HTMLAttributes<HTMLDivElement> {
  householdId: string;
}

const SummaryComponent = ({ householdId }: NodeSummaryPanelProps) => {
  const { state, dispatch } = useEntityMapContext();
  const summaryNode = state.derivedData.summaryNode;

  const onClose = useCallback(() => {
    dispatch({ type: 'CLOSE_SUMMARY_PANEL' });
  }, [dispatch]);

  switch (summaryNode?.data.__typename) {
    case 'ClientProfile': {
      if (
        summaryNode?.categorizationType === 'GRANTOR_BUSINESS_ENTITIES_SUMMARY'
      ) {
        return (
          <BusinessEntitiesSummaryPanel
            householdId={householdId}
            grantorClientProfileId={summaryNode.data.id}
            onClose={onClose}
          />
        );
      }

      return (
        <ClientProfileSummaryPanel
          householdId={householdId}
          clientProfileId={summaryNode.data.id}
          onClose={onClose}
        />
      );
    }
    case 'ClientOrganization': {
      return (
        <OrganizationSummaryPanel
          householdId={householdId}
          organizationId={summaryNode.data.id}
          onClose={onClose}
        />
      );
    }
    case 'Entity': {
      return (
        <EntitySummaryPanel
          householdId={householdId}
          entityId={summaryNode.data.id}
          onClose={onClose}
          isOnHypotheticalWaterfall={false}
          isHypotheticalEntity={false}
          // Key on the entity id so that the summary panel tab
          // is reset when changing entities
          key={summaryNode.data.id}
        />
      );
    }
  }
  return null;
};

export const MemoizedNodeSummaryPanel = memo(function NodeSummaryPanel({
  householdId,
  ...props
}: NodeSummaryPanelProps) {
  const { state } = useEntityMapContext();
  if (!state.derivedData.summaryNode) return null;

  return (
    <div {...props}>
      <SummaryComponent householdId={householdId} />
    </div>
  );
}, isEqual);
