import { Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { ListIcon } from '@/components/icons/ListIcon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useFormContext } from '@/components/react-hook-form';
import { AISuggestionsSparkleIcon } from '@/modules/aiSuggestions/components/AISuggestionsSparkleIcon';
import { useHouseholdDocumentsViewerContext } from '@/modules/documents/components/HouseholdDocumentsViewer/context/HouseholdDocumentsViewer.context';
import { useEditEntitySplitScreenModalContext } from '@/modules/entities/EditEntitySplitScreen/contexts/editEntitySplitScreenModal.context';
import {
  CombinedSupportedSubformFieldTypes,
  EditEntityRichSection,
  EditEntitySection,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { COLORS } from '@/styles/tokens/colors';

const WIDTH = 240;

interface EditEntitySplitScreenFormSectionSwitcherProps {
  onSwitchSection: (newSection: EditEntitySection) => void;
}

export function EditEntitySplitScreenFormSectionSwitcher({
  onSwitchSection,
}: EditEntitySplitScreenFormSectionSwitcherProps) {
  const { currentSection, setCurrentSection, sectionOptions } =
    useEditEntitySplitScreenModalContext();

  const {
    shouldBlockNavigation,
    setShouldBlockNavigation,
    formState: { isDirty },
  } = useFormContext<CombinedSupportedSubformFieldTypes>();

  const { newlyUploadedDocumentId } = useHouseholdDocumentsViewerContext();

  useEffect(() => {
    if (newlyUploadedDocumentId) {
      // Submitting the DocumentUploaderForm clears the global shouldBlockNavigation state.
      // We need to set it back to true if the entity form was dirty before the document upload.
      setShouldBlockNavigation(isDirty);
    }
  }, [isDirty, newlyUploadedDocumentId, setShouldBlockNavigation]);

  const handleRequestSectionSwitch = useCallback(
    (newSection: EditEntitySection) => {
      if (shouldBlockNavigation) {
        onSwitchSection(newSection);
        return;
      }
      setCurrentSection(newSection);
    },
    [onSwitchSection, setCurrentSection, shouldBlockNavigation]
  );

  const sectionLabel = useMemo(() => {
    if (!currentSection) {
      return 'Select section to edit';
    }
    const currentSectionOption = sectionOptions.find(
      (o) => o.section === currentSection
    );
    return currentSectionOption?.label ?? 'Select section to edit';
  }, [currentSection, sectionOptions]);

  return (
    <>
      <ButtonWithPopover
        data-testid="edit-entity-section-switcher"
        sx={{
          width: WIDTH,
        }}
        contentBoxSx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
        size="sm"
        variant="secondary"
        popperVariant="menuBelow"
        startIcon={ListIcon}
        label={
          <Typography
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {sectionLabel}
          </Typography>
        }
      >
        {sectionOptions.map((o) => (
          <EditEntitySectionMenuItem
            key={o.section}
            section={o}
            onRequestSectionSwitch={handleRequestSectionSwitch}
          />
        ))}
      </ButtonWithPopover>
    </>
  );
}

interface EditEntitySectionMenuItemProps {
  section: EditEntityRichSection;
  onRequestSectionSwitch: (section: EditEntitySection) => void;
}

function EditEntitySectionMenuItem({
  section,
  onRequestSectionSwitch,
}: EditEntitySectionMenuItemProps) {
  const { currentSection } = useEditEntitySplitScreenModalContext();

  const isSelected = section.section === currentSection;

  return (
    <MenuItem
      sx={{
        backgroundColor: isSelected ? COLORS.NAVY[50] : undefined,
        minWidth: WIDTH,
      }}
      justifyContent="space-between"
      label={
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography>{section.label}</Typography>
          <AISuggestionsSparkleIcon section={section.section} />
        </Stack>
      }
      iconAfter={
        isSelected && (
          <CheckIcon size={20} color={COLORS.FUNCTIONAL.SUCCESS.DEFAULT} />
        )
      }
      onClick={() => onRequestSectionSwitch(section.section)}
    />
  );
}
