import { Stack } from '@mui/material';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { Link } from '@/components/form/baseInputs/Link';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Alert } from '@/components/notifications/Alert/Alert';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { getUserFacingErrorMessages } from '@/graphql/errors';
import { useReportError } from '@/hooks/useReportError';
import { isValidTenantSubdomain } from '@/modules/tenant/tenant.utils';
import { TenantKind, TenantUsageBucket } from '@/types/schema';
import { getEnvironment } from '@/utils/environmentUtils';
import { getFullyQualifiedTenantURL } from '@/utils/tenantUtils';

import { usageBucketOptions } from './AdminTenantsPage.constants';
import { useCreateTenantMutation } from './graphql/AdminTenantsPage.generated';

interface TenantCreationFormShape {
  name: string;
  subdomain: string;
  kind: TenantKind;
  usageBucket: TenantUsageBucket;
  contractStartDate: Date | null;
}

export function CreateTenantForm() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const environment = getEnvironment();
  const [createdTenantLink, setCreatedTenantLink] = useState<string | null>(
    null
  );
  const formMethods = useForm<TenantCreationFormShape>({
    defaultValues: {
      name: '',
      subdomain: '',
      contractStartDate: null,

      // empty strings to force selection
      kind: '' as TenantKind,
      usageBucket: '' as TenantUsageBucket,
    },
  });
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const [createTenant] = useCreateTenantMutation({
    onError: (err) => {
      const errorMessages = getUserFacingErrorMessages(err);
      const errorMessage = errorMessages.join(' ') || 'Unknown reason';
      showFeedback(`Failed to create tenant: ${errorMessage}`);
      reportError('could not create tenant', err);
    },
  });

  async function onSubmit(formData: TenantCreationFormShape) {
    setCreatedTenantLink(null);
    const res = await createTenant({
      variables: {
        input: formData,
      },
    });
    if (res.data) {
      setCreatedTenantLink(getFullyQualifiedTenantURL(formData.subdomain));
    }
  }

  return (
    <FormProvider {...formMethods}>
      <Stack
        spacing={2}
        noValidate
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Callout severity="info-high">
          You are creating a new tenant in the {environment.toUpperCase()}{' '}
          environment.
        </Callout>
        <FormAwareTextInput<TenantCreationFormShape>
          label="Organization display name"
          helpText="To be used in places like proposals and invitation emails"
          fieldName={'name'}
          required
          disabled={isSubmitting}
          control={control}
        />
        <FormAwareTextInput<TenantCreationFormShape>
          validation={{
            pattern: (value) => {
              if (!value) return undefined;
              if (typeof value !== 'string') {
                throw new Error(
                  'Unexpected type for subdomain validation: ' + typeof value
                );
              }

              if (isValidTenantSubdomain(value)) return undefined;
              return 'Subdomain must be lowercase and contain only letters and hyphens.';
            },
          }}
          label="Tenant subdomain"
          helpText="If you want to create the tenant 'customer.withluminary.com', enter 'customer' here (without the quotes)."
          fieldName={'subdomain'}
          required
          disabled={isSubmitting}
          control={control}
        />
        <FormAwareSelectInput<TenantCreationFormShape>
          label="Production level"
          helpText="Production tenants are for real customers, non-production tenants are for testing, development, and demos."
          fieldName={'kind'}
          required
          disabled={isSubmitting}
          control={control}
          options={[
            {
              display: 'Production',
              value: TenantKind.Production,
            },
            {
              display: 'Non-Production',
              value: TenantKind.NonProduction,
            },
          ]}
        />
        <FormAwareSelectInput<TenantCreationFormShape>
          label="Usage bucket"
          fieldName="usageBucket"
          required
          disabled={isSubmitting}
          control={control}
          options={usageBucketOptions}
        />
        <FormAwareDatePickerInput<TenantCreationFormShape>
          label="Contract start date"
          fieldName="contractStartDate"
          helpText="For billing purposes"
          disabled={isSubmitting}
          control={control}
        />
        <Button
          disabled={isSubmitting}
          variant="primary"
          size="md"
          type="submit"
        >
          Create tenant
        </Button>
        {createdTenantLink && (
          <Alert size="sm" severity="success">
            <Link
              href={createdTenantLink}
              target="_blank"
              display={'Go to the newly-created tenant →'}
            />
          </Alert>
        )}
      </Stack>
    </FormProvider>
  );
}
