import { Box, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';

import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { ThumbsDownIcon } from '@/components/icons/ThumbsDownIcon';
import { ThumbsUpIcon } from '@/components/icons/ThumbsUpIcon';
import { useRateSummary } from '@/modules/documents/hooks/useRateSummary';
import { COLORS } from '@/styles/tokens/colors';
import {
  DocumentSummaryAiSummaryRating,
  DocumentSummaryEntryMode,
} from '@/types/schema';

import { SummaryFeedbackModal } from './hooks/SummaryFeedbackModal';

interface RateSummaryProps {
  documentSummaryId: string;
  documentSummaryEntryMode?: DocumentSummaryEntryMode | null;
  allowFeedback: boolean;
  existingRating: DocumentSummaryAiSummaryRating | null;
}

export function RateSummary({
  documentSummaryId,
  documentSummaryEntryMode,
  allowFeedback,
  existingRating,
}: RateSummaryProps) {
  const useRateSummaryMethods = useRateSummary(
    documentSummaryId,
    existingRating,
    allowFeedback
  );
  const { upRateSummary, downRateSummary, loading } = useRateSummaryMethods;

  const rate = useCallback(
    (isPositive: boolean) => {
      if (isPositive) {
        upRateSummary();
      } else {
        void downRateSummary();
      }
    },
    [downRateSummary, upRateSummary]
  );

  if (
    documentSummaryEntryMode !== DocumentSummaryEntryMode.AiAuto &&
    documentSummaryEntryMode !== DocumentSummaryEntryMode.AiUserInvoked
  ) {
    return null;
  }

  return (
    <>
      <SummaryFeedbackModal rateSummaryMethods={useRateSummaryMethods} />
      <Stack direction="row" spacing={1}>
        <Stack direction="row" alignItems="center">
          <Box display="flex">
            <IconButton
              icon={ThumbsUpIcon}
              size="xs"
              variant="transparent"
              ariaLabel="I like this summary"
              iconProps={{
                sx: {
                  color:
                    existingRating === DocumentSummaryAiSummaryRating.Good
                      ? COLORS.TEAL[500]
                      : COLORS.GRAY[500],
                },
                fill:
                  existingRating === DocumentSummaryAiSummaryRating.Good
                    ? COLORS.TEAL[100]
                    : 'transparent',
              }}
              disabled={loading}
              onClick={() => rate(true)}
            />
          </Box>
          <Box display="flex">
            <IconButton
              icon={ThumbsDownIcon}
              variant="transparent"
              size="xs"
              ariaLabel="I dislike this summary"
              iconProps={{
                sx: {
                  color:
                    existingRating === DocumentSummaryAiSummaryRating.Bad
                      ? COLORS.ORANGE[500]
                      : COLORS.GRAY[500],
                },
                fill:
                  existingRating === DocumentSummaryAiSummaryRating.Bad
                    ? COLORS.ORANGE[100]
                    : 'transparent',
              }}
              disabled={loading}
              onClick={() => rate(false)}
            />
          </Box>
        </Stack>
        <Stack justifyContent="center">
          <Typography variant="subtitle2">Rate AI summary</Typography>
        </Stack>
      </Stack>
    </>
  );
}
