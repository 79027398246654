/**
 * @description a common pattern with our APIs is to have the field name (e.g. `legalName`) and then a boolean property
 * to clear that name, `clearLegalName`. This is a ~typesafe utility to help ensure we're handing the "clearing" path.
 */
export function getValueOrClear<F extends string, V>(
  fieldName: F,
  value: V | undefined
) {
  if (value) {
    return { [fieldName]: value };
  }

  const clearProperty = (() => {
    const upperCasedFieldName =
      fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    return `clear${upperCasedFieldName}`;
  })();

  return {
    [clearProperty]: true,
  };
}
