import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { SetRequired } from 'type-fest';

import {
  FullScreenModal,
  FullScreenModalProps,
} from '@/components/modals/FullScreenModal/FullScreenModal';
import { getSuggestionAsSearchQuery } from '@/modules/aiSuggestions/aiSuggestions.utils';
import { evidenceAdapter } from '@/modules/aiSuggestions/evidence';
import { DocumentViewerTab } from '@/modules/documents/components/HouseholdDocumentsViewer/context/HouseholdDocumentsViewer.context';
import { SplitScreenLayoutWithDocuments } from '@/modules/documents/components/SplitScreenLayoutWithDocuments/SplitScreenLayoutWithDocuments';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { EditEntitySplitScreenModalProvider } from '@/modules/entities/EditEntitySplitScreen/contexts/EditEntitySplitScreenModal.provider';
import {
  EditEntitySection,
  SuggestionEvidence,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { EditEntitySplitScreenFooter } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenFooter';
import { EditEntitySplitScreenRightContent } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenRightContent';
import { useImmediateOpenEditModal } from '@/modules/entities/hooks/useImmediateOpenEditModal';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { EntityType } from '../types/EntityType';
import { useEditEntitySplitScreenModalContext } from './contexts/editEntitySplitScreenModal.context';

export type EditEntitySplitScreenModalProps = {
  entityType: EntityType;
  initialSection: EditEntitySection;
  navigateAfterSave: boolean;
  // entityValueOverride is used when we're editing dispositive provisions from
  // a scenario where we have a projected entity value, like from a waterfall.
  // we show the projected value in the dispositive provisions section of the modal
  entityValueOverride?: Decimal;
} & EditEntitySplitScreenModalInnerProps;

type EditEntitySplitScreenModalInnerProps = SetRequired<
  Partial<FullScreenModalProps>,
  'onClose'
>;

function EditEntitySplitScreenModalInner({
  ...modalProps
}: EditEntitySplitScreenModalInnerProps) {
  const { selectedSuggestion, setSelectedSuggestion } =
    useEditEntitySplitScreenModalContext();
  const { entityId } = useEntityDetailsContext();
  const { householdId } = useHouseholdDetailsContext();

  const [suggestionEvidence, setSuggestionEvidence] =
    useState<SuggestionEvidence>();

  const [documentViewerTabOverride, setDocumentViewerTabOverride] = useState<
    DocumentViewerTab | undefined
  >(undefined);

  useEffect(() => {
    if (!selectedSuggestion) {
      setSuggestionEvidence({});
      setDocumentViewerTabOverride(undefined);
      return;
    }

    // When the user clicks to view the evidence for a suggestion, we show the
    // annotations, prefill the search bar with the suggestion text, and switch
    // the active document to the suggestion source.
    setDocumentViewerTabOverride('document');
    setSuggestionEvidence({
      documentId: selectedSuggestion.file?.document?.id,
      annotations: compact(selectedSuggestion.evidence || []).map((e) =>
        evidenceAdapter(e, selectedSuggestion.file?.document?.id || '')
      ),
      searchQuery: getSuggestionAsSearchQuery(selectedSuggestion),
    });
  }, [selectedSuggestion]);

  return (
    <FullScreenModal {...modalProps}>
      <SplitScreenLayoutWithDocuments
        householdId={householdId}
        targetId={entityId!}
        rightContent={<EditEntitySplitScreenRightContent />}
        footer={<EditEntitySplitScreenFooter />}
        overrideAnnotations={suggestionEvidence?.annotations}
        overrideSearchQuery={suggestionEvidence?.searchQuery}
        onUpdateSearchQuery={() => {
          // Unset the selected suggestion when the user types or clears the search bar.
          setSelectedSuggestion(null);
        }}
        overrideActiveDocumentId={suggestionEvidence?.documentId}
        onUpdateActiveDocumentId={() => {
          // Unset the selected suggestion when the user switches documents.
          setSelectedSuggestion(null);
        }}
        overrideDocumentViewerActiveTab={documentViewerTabOverride}
        onUpdateDocumentViewerActiveTab={() => {
          // Unset the selected suggestion when the user switches between the document/summary views.
          setSelectedSuggestion(null);
        }}
      />
    </FullScreenModal>
  );
}

export function EditEntitySplitScreenModal({
  initialSection,
  navigateAfterSave,
  entityType,
  entityValueOverride,
  ...modalProps
}: EditEntitySplitScreenModalProps) {
  // The reference to the footer of the modal, where the cancel/save buttons
  // will be portaled to.
  const footerRef = useRef<HTMLDivElement>(null);

  const { clearImmediateEditSearchParam } = useImmediateOpenEditModal();

  useEffect(() => {
    // This will clear out the immediate edit search param if it exists,
    // so that we don't keep re-triggering the modal to open.
    // The immediate edit search param gets set by the CreateEntityShortFormModal -> Add additional details button.
    clearImmediateEditSearchParam();
  }, [clearImmediateEditSearchParam]);

  return (
    <EditEntitySplitScreenModalProvider
      entityType={entityType}
      initialSection={initialSection}
      entityValueOverride={entityValueOverride}
      navigateAfterSave={navigateAfterSave}
      formActionsPortalRef={footerRef}
      onClose={modalProps.onClose}
    >
      <EditEntitySplitScreenModalInner {...modalProps} />
    </EditEntitySplitScreenModalProvider>
  );
}
