import { ApolloClient } from '@apollo/client';

import * as diagnostics from '@/utils/diagnostics';

import { BusinessEntitiesTaxStatusSubformType } from './BusinessEntitiesTaxStatusSubform.types';
import {
  GetBusinessEntityTaxStatusDocument,
  GetBusinessEntityTaxStatusQuery,
} from './graphql/BusinessEntitiesTaxStatusSubform.generated';

function dataToForm(
  data: GetBusinessEntityTaxStatusQuery
): BusinessEntitiesTaxStatusSubformType {
  if (data.node?.__typename !== 'Entity' || !('taxID' in data.node.subtype)) {
    throw new Error('Unexpected node type');
  }

  const { taxID, taxStatus } = data.node.subtype;
  return {
    taxId: taxID ?? '',
    taxStatus: taxStatus ?? '',
  };
}

export async function businessEntitiesTaxStatusDataFetcher(
  apolloClient: ApolloClient<object>,
  nodeId: string
): Promise<BusinessEntitiesTaxStatusSubformType> {
  const { data, error } =
    await apolloClient.query<GetBusinessEntityTaxStatusQuery>({
      query: GetBusinessEntityTaxStatusDocument,
      variables: {
        nodeId: nodeId,
      },
    });

  if (error) {
    diagnostics.error('Could not fetch data for tax status subform', error);

    throw error;
  }

  return dataToForm(data);
}
