import { GridCellParams, GridEventListener } from '@mui/x-data-grid-pro';
import { format } from 'date-fns';
import { FC } from 'react';

import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { PopperContent } from '@/components/poppers/PopperContent';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { ContextualHelpHeaderRenderer } from '@/components/tables/DataTable/renderers/header/ContextualHelpHeaderRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';
import { formatCurrency } from '@/utils/formatting/currency';

import { ValuationRowData } from './EntityValuationHistoryPage.utils';

interface TableRowData extends ValuationRowData {
  icon: unknown;
}

interface GetColumnsParams {
  hasBusinessOwnershipStakes: boolean;
}

const getColumns = (params: GetColumnsParams): Column<TableRowData>[] => {
  const columns: Column<TableRowData>[] = [
    {
      headerName: 'Valuation date',
      field: 'updatedOn',
      flex: 1,
      sortable: false,
      renderCell: TextRenderer({
        text: ({ row }) => format(row.updatedOn, 'MMMM d, yyyy'),
      }),
    },
    {
      headerName: 'Entity value',
      field: 'amount',
      flex: 2,
      sortable: false,
      align: 'right',
      renderHeader: ContextualHelpHeaderRenderer({
        header: () => {
          if (params.hasBusinessOwnershipStakes) {
            return 'Value of non-business assets';
          }
          return 'Entity value';
        },
        contextualHelp: (
          <PopperContent body="This value is exclusive of any business entity interests also held by this entity." />
        ),
      }),
      renderCell: TextRenderer({
        text: ({ row }) => formatCurrency(row.amount),
        textProps: { fontWeight: FONT_WEIGHTS.bold },
      }),
    },
    {
      headerName: 'Updated by',
      field: 'updatedBy',
      flex: 2,
      sortable: false,
    },
    {
      field: 'icon',
      headerName: '',
      align: 'center',
      sortable: false,
      width: 64,
      minWidth: 64,
      maxWidth: 64,
      renderCell: IconRenderer({
        icon: ChevronRightIcon,
      }),
    },
  ];

  return columns;
};

interface EntityValuationHistoryTableProps {
  rows: ValuationRowData[];
  onClick: (valuationId: string) => void;
  hasBusinessOwnershipStakes: boolean;
}

export const EntityValuationHistoryTable: FC<
  EntityValuationHistoryTableProps
> = ({ rows, onClick, hasBusinessOwnershipStakes }) => {
  const onCellClick: GridEventListener<'cellClick'> = (
    params: GridCellParams<TableRowData>
  ) => {
    onClick(params.row.id);
  };

  const columns = getColumns({ hasBusinessOwnershipStakes });
  return (
    <DataTable
      columns={columns}
      rows={rows}
      disableColumnReorder
      disableChildrenSorting
      disableMultipleColumnsSorting
      onCellClick={onCellClick}
    />
  );
};
