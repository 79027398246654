import { Stack } from '@mui/material';
import { difference, first, isEmpty } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { AssetProviderLinkIndicator } from '@/modules/assetProviderIntegrations/shared/AssetProviderLinkIndicator';
import { AssetProviderUnlinkWarning } from '@/modules/assetProviderIntegrations/shared/AssetProviderUnlinkWarning';
import {
  useAssetProviderDisplayName,
  useIsAssetProviderIntegrationEnabled,
} from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import { IntegrationClientsTypeahead } from '@/modules/assetProviderIntegrations/shared/IntegrationClientTypeahead/IntegrationClientsTypeahead';
import { PathsOf } from '@/types/subform';
import { diagnostics } from '@/utils/diagnostics';

import { useUpdateHouseholdIntegrationClientsMutation } from '../graphql/ManageHouseholdAccessForm.generated';

export interface IntegrationClientFormProps {
  initialIntegrationClientIds?: string[];
  householdId: string;
}

interface IntegrationClientFormShape {
  integrationClientIds: string[];
  /** used to calculate the diff in the integration client IDs */
  _initialIntegrationClientIds: string[];
}

export type IntegrationClientFormPaths = PathsOf<IntegrationClientFormShape>;

/**
 * @description IntegrationClientForm is responsible for connecting the household to one or more integration clients.
 */
export function IntegrationClientForm({
  initialIntegrationClientIds,
  householdId,
}: IntegrationClientFormProps) {
  const assetProviderName = useAssetProviderDisplayName();
  const { canConnectToIntegrations, integrationsConnected } =
    useIsAssetProviderIntegrationEnabled();
  const { createErrorFeedback, createSuccessFeedback } = useFeedback();
  const [updateHousehold, { loading }] =
    useUpdateHouseholdIntegrationClientsMutation({
      onError: createErrorFeedback(
        'Failed to update client with integration data. Please refresh the page and try again.'
      ),
      onCompleted: createSuccessFeedback('Client updates saved successfully'),
    });
  const formMethods = useForm<IntegrationClientFormShape>({
    defaultValues: {
      integrationClientIds: [],
    },
  });
  const { control, handleSubmit, reset, watch, getFieldState } = formMethods;

  const selectedIntegrationClientIds = watch('integrationClientIds');
  const isFieldChanged = getFieldState('integrationClientIds').isDirty;

  // we do this here instead of passing directly into defaultValues because this value is driven via the API
  // we won't have it at the time we instantiate the form
  useEffect(
    function syncInitialValuesToForm() {
      if (!initialIntegrationClientIds) return;
      reset({
        integrationClientIds: initialIntegrationClientIds,
        _initialIntegrationClientIds: initialIntegrationClientIds,
      });
    },
    [initialIntegrationClientIds, reset]
  );

  const onSubmit = handleSubmit((data) => {
    const addIntegrationClientIDs = difference(
      data.integrationClientIds,
      data._initialIntegrationClientIds
    );
    const removeIntegrationClientIDs = difference(
      data._initialIntegrationClientIds,
      data.integrationClientIds
    );

    return updateHousehold({
      variables: {
        householdId,
        input: {
          ...(addIntegrationClientIDs.length > 0 && {
            addIntegrationClientIDs,
          }),
          ...(removeIntegrationClientIDs.length > 0 && {
            removeIntegrationClientIDs,
          }),
        },
      },
    });
  });

  const enabledIntegration = useMemo(() => {
    if (canConnectToIntegrations.length > 1) {
      diagnostics.warn(
        'Multiple enabled integrations found. This should not happen.'
      );
      return first(canConnectToIntegrations);
    }

    if (canConnectToIntegrations.length === 1) {
      return canConnectToIntegrations[0];
    }

    return null;
  }, [canConnectToIntegrations]);

  if (!enabledIntegration) {
    return null;
  }

  const isLoggedIn = integrationsConnected.includes(enabledIntegration);

  return (
    <FormProvider {...formMethods}>
      <Stack spacing={2} onSubmit={onSubmit} component="form">
        <Stack spacing={1}>
          <AssetProviderLinkIndicator providerName={assetProviderName!} />
          <IntegrationClientsTypeahead
            provider={enabledIntegration}
            control={control}
            notLoggedIn={!isLoggedIn}
            fieldName={
              `integrationClientIds` as const satisfies IntegrationClientFormPaths
            }
            label={`${assetProviderName} client`}
            hideLabel
          />
        </Stack>
        {isFieldChanged && isEmpty(selectedIntegrationClientIds) && (
          <AssetProviderUnlinkWarning
            providerName={assetProviderName ?? 'Unknown'}
          />
        )}
        <Button
          disabled={!isLoggedIn}
          loading={loading}
          type="submit"
          variant="primary"
          size="md"
        >
          Save
        </Button>
      </Stack>
    </FormProvider>
  );
}
