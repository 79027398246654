import { useNodesInitialized } from '@xyflow/react';
import { useEffect } from 'react';
import { useEffectOnce } from 'react-use';

import { useFamilyTreeContext } from '../contexts';

export function useProviderEffects() {
  const isNodesInitialized = useNodesInitialized();

  const { dispatch, state } = useFamilyTreeContext();

  // Once all nodes have been measured by react-flow, we can run auto-layout / initalize their positions
  useEffect(() => {
    if (!isNodesInitialized || !state.isInitializingFlowchart) return;

    dispatch({ type: 'INITIALIZE_NODE_POSITIONS' });
  }, [dispatch, isNodesInitialized, state.isInitializingFlowchart]);

  // Initial data fetch
  useEffectOnce(() => {
    dispatch({ type: 'INITIALIZE' });
  });
}
