import React from "react";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material";
import LinkExternal01SVG from "./svg/link-external-01.svg";

// THIS FILE IS AUTOMATICALLY GENERATED BY A SCRIPT
// PLEASE DO NOT MANUALLY EDIT IT.
// The script can be found in /scripts/convertSVGsToIcons.js

export type IconProps = React.RefAttributes<HTMLSpanElement> & React.HTMLAttributes<HTMLSpanElement> & {
  /** size of the icon and container. if you want the container to be differently sized, use height/width in the sx prop */
  size?: number;
  /** color of the icon -- this will apply to anywhere in the svg files that the currentColor keyword is used */
  color?: string;
  /** fill color of the icon -- this will override the default fill property, if present, in the svg. you don't need this for most cases. */
  fill?: string;
  /** stroke color of the icon -- this will override the default stroke property, if present, in the svg. you don't need this for most cases. */
  stroke?: string;
  className?: string;
  /** sx prop for the icon container */
  sx?: SxProps<Theme>;
};

function LinkExternal01IconInner(
  props: IconProps,
  ref: React.ForwardedRef<HTMLSpanElement>
) {
  const { size = 24, color, fill, stroke, className, sx = {}, ...htmlProps } = props;
  return (
    <Box component="span" className={className} ref={ref} sx={{ height: size, width: size, color, display: 'inline-block', overflow: 'hidden', flexShrink: 0, userSelect: 'none', textAlign: 'center', ...sx }} {...htmlProps}>
      <LinkExternal01SVG height={`${size}px`} width={`${size}px`} style={{ fill, stroke }} />
    </Box>
  );
}

export const LinkExternal01Icon = React.forwardRef<HTMLSpanElement, IconProps>(
  LinkExternal01IconInner
);
