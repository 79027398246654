import { PopperContent } from '@/components/poppers/PopperContent';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';

interface BenefitsTooltipProps {
  content: string;
}

export function BenefitsTooltip({ content }: BenefitsTooltipProps) {
  return (
    <ContextualHelpTooltip>
      <PopperContent body={content} />
    </ContextualHelpTooltip>
  );
}
