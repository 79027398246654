import { createValueToDisplayLookup } from '@/types/definitionsUtils';
import { AssetV2QsbsEligibility } from '@/types/schema';

export const ASSET_QSBS_ELIGIBILITY_ITEMS = [
  { display: 'Not confirmed', value: AssetV2QsbsEligibility.NotConfirmed },
  { display: 'Not QSBS eligible', value: AssetV2QsbsEligibility.No },
  { display: 'QSBS eligible', value: AssetV2QsbsEligibility.Yes },
] as const;
export { AssetV2QsbsEligibility };
export const ASSET_QSBS_ELIGIBILITY_OPTIONS =
  ASSET_QSBS_ELIGIBILITY_ITEMS.slice(); // slice to make it non-readonly
export const ASSET_QSBS_ELIGIBILITY_DISPLAY_BY_VALUE =
  createValueToDisplayLookup(ASSET_QSBS_ELIGIBILITY_OPTIONS);
