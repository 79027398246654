import { css } from '@emotion/css';
import { Stack, useTheme } from '@mui/material';
import { FormProvider, Path } from 'react-hook-form';

import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useRateSummary } from '@/modules/documents/hooks/useRateSummary';

interface SummaryFeedbackModalProps {
  rateSummaryMethods: ReturnType<typeof useRateSummary>;
}
interface SummaryFeedbackForm {
  textFeedback: string;
}

const modalStyles = css({
  height: 'auto',
});

function SummaryFeedbackModalInner({
  rateSummaryMethods: {
    isFeedbackModalOpen,
    closeFeedbackModal,
    provideFeedback,
  },
}: SummaryFeedbackModalProps) {
  const theme = useTheme();
  const { formRef, handleSave } = useFormSaveHandler();
  const { control, formState, shouldBlockNavigation, handleSubmit, reset } =
    useFormContext<SummaryFeedbackForm>();

  useSubmitSuccessHandler(() => {
    closeFeedbackModal();
    setTimeout(() => {
      reset();
    }, theme.transitions.duration.leavingScreen);
  });

  const onSubmit = handleSubmit((formData) => {
    if (!formData.textFeedback) {
      return Promise.resolve();
    }

    return provideFeedback(formData.textFeedback);
  });

  return (
    <FormModal
      heading="Provide feedback"
      rightHeaderContent={null}
      isOpen={isFeedbackModalOpen}
      onClose={closeFeedbackModal}
      shouldBlockClose={shouldBlockNavigation}
      classes={{
        paper: modalStyles, // Let the modal shrink its height to the content
      }}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.CancelButton
            variant="transparent"
            onClick={closeFeedbackModal}
          />
          <FormModalActions.SaveButton onClick={handleSave}>
            Submit
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <Stack spacing={2}>
        <form ref={formRef} onSubmit={onSubmit} noValidate>
          <FormAwareTextInput<SummaryFeedbackForm>
            label="Feedback"
            hideLabel
            placeholder="How could this summary be improved?"
            fieldName={
              'textFeedback' as const satisfies Path<SummaryFeedbackForm>
            }
            helpText="This summary was generated using Luminary AI. We are continuously improving our document summary capabilities based on feedback."
            control={control}
            multiline
            rows={3}
          />
        </form>
      </Stack>
    </FormModal>
  );
}

export function SummaryFeedbackModal(props: SummaryFeedbackModalProps) {
  const formMethods = useForm<SummaryFeedbackForm>({
    defaultValues: {
      textFeedback: '', // Form is always initialized with empty text feedback
    },
  });
  return (
    <FormProvider {...formMethods}>
      <SummaryFeedbackModalInner {...props} />
    </FormProvider>
  );
}
