import { Box, Stack } from '@mui/material';
import { compact } from 'lodash';

import { Mail02Icon } from '@/components/icons/Mail02Icon';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { getRoleKindDisplayName } from '@/modules/professionalTeam/ClientProfessionalTeam.copy';
import { COLORS } from '@/styles/tokens/colors';

import { ProfessionalTeamSlideMember } from './ProfessionalTeamSlide.utils';

export interface ProfessionalTeamMemberProps {
  teamMember: ProfessionalTeamSlideMember;
}

export function ProfessionalTeamMember({
  teamMember,
}: ProfessionalTeamMemberProps) {
  const roleString = getRoleKindDisplayName(teamMember);

  return (
    <Box sx={{ pt: 3 }}>
      <RichListItem
        heading={teamMember.name}
        description={roleString}
        badge={
          teamMember.principalClient && (
            <Badge
              variant={BadgeVariants.Gray}
              display={teamMember.principalClient}
            />
          )
        }
        additionalItems={compact([
          teamMember.email ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <Mail02Icon size={20} sx={{ color: COLORS.GRAY[500], pr: 2 }} />
              &nbsp;{teamMember.email}
            </Stack>
          ) : null,
        ])}
      />
    </Box>
  );
}
