import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';

import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { diagnostics } from '@/utils/diagnostics';

export function UserWatcher() {
  const posthogClient = usePostHog();
  const currentUser = useCurrentUser();
  const { usageBucket, applicationConfiguration } = useTenantDetailsContext();

  useEffect(() => {
    // using applicationConfiguration as a heuristic to make sure the tenant data is
    // loaded fully
    if (posthogClient && currentUser && applicationConfiguration) {
      diagnostics.identifyUserWithSession(
        posthogClient,
        currentUser.email,
        currentUser.id,
        usageBucket
      );
    }
  }, [applicationConfiguration, currentUser, posthogClient, usageBucket]);

  return null;
}
