import { Stack } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import {
  CompactQueryData,
  usePaginatedDataTableQuery,
} from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { CashflowsTable } from '@/modules/cashflows/CashflowTable/CashflowTable';
import { CashflowOrderField, OrderDirection } from '@/types/schema';

import { EntitiesBreadcrumb } from '../components/EntitiesBreadcrumb';
import { ClientDetailsManageIncomeExpensesPageContextProvider } from './ClientDetailsManageIncomeExpensesPage.context';
import {
  CashflowsQuery,
  CashflowsQueryVariables,
  useCashflowsQuery,
} from './graphql/ClientDetailsManageIncomeExpenses.generated';

export type CashflowsQueryData =
  | CompactQueryData<CashflowsQuery, CashflowsQueryVariables>
  | undefined;

function ClientDetailsManageIncomeExpensesPageInner({
  householdId,
}: {
  householdId: string;
}) {
  // doing the table's query at a higher level so the `refetch` function can be passed into the button
  const [paginatedTableProps, { data: cashflows, refetch }] =
    usePaginatedDataTableQuery(useCashflowsQuery, {
      pageSize: 25,
      variables: {
        where: {
          hasHouseholdWith: [
            {
              id: householdId,
            },
          ],
        },
        orderBy: [
          {
            field: CashflowOrderField.TransferValue,
            direction: OrderDirection.Desc,
          },
          {
            field: CashflowOrderField.StartYear,
            direction: OrderDirection.Asc,
          },
        ],
      },
    });

  return (
    <ClientDetailsManageIncomeExpensesPageContextProvider
      refetch={refetch}
      householdId={householdId}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pb={3}
      >
        <EntitiesBreadcrumb pageCrumb="Cash flows" householdId={householdId} />
      </Stack>
      <Card variant="outlined" sx={{ p: 3 }}>
        <CashflowsTable
          paginatedTableProps={paginatedTableProps}
          cashflows={cashflows}
        />
      </Card>
    </ClientDetailsManageIncomeExpensesPageContextProvider>
  );
}

export function ClientDetailsManageIncomeExpensesPage() {
  const householdId = useRequiredParam('householdId');
  return (
    <ClientDetailsManageIncomeExpensesPageInner householdId={householdId} />
  );
}
