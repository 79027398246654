import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { COLORS } from '@/styles/tokens/colors';
import { AfterDeath } from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';

import { formatTaxForCumulativeTaxTable } from './EstateWaterfallCumulativeTaxTable.utils';

interface EstateWaterfallCumulativeTaxTableProps {
  includeHeader?: boolean;
  taxBeforeFirstDeath?: Decimal;
  taxAfterFirstDeath?: Decimal;
  taxAfterSecondDeath?: Decimal;
  afterDeath: AfterDeath;
}

export function EstateWaterfallCumulativeTaxTable({
  includeHeader = false,
  taxBeforeFirstDeath = new Decimal(0),
  taxAfterFirstDeath = new Decimal(0),
  taxAfterSecondDeath = new Decimal(0),
  afterDeath,
}: EstateWaterfallCumulativeTaxTableProps) {
  const rowLabels = useMemo(() => {
    const taxBeforeFirstDeathTuple = [
      'Tax Before 1st Death',
      formatTaxForCumulativeTaxTable(taxBeforeFirstDeath),
    ] as const;
    const taxAfterFirstDeathTuple = [
      'Tax After 1st Death',
      formatTaxForCumulativeTaxTable(taxAfterFirstDeath),
    ] as const;
    const taxAfterSecondDeathTuple = [
      'Tax After 2nd Death',
      formatTaxForCumulativeTaxTable(taxAfterSecondDeath),
    ] as const;

    // Just show one row for the current gift tax without the total row
    if (afterDeath === AfterDeath.None) {
      return [taxBeforeFirstDeathTuple] as const;
    }

    // Show just the gift tax, first death estate tax, and the total
    if (afterDeath === AfterDeath.First) {
      return [
        taxBeforeFirstDeathTuple,
        taxAfterFirstDeathTuple,
        [
          'Total',
          formatTaxForCumulativeTaxTable(
            sumDecimalJS([taxBeforeFirstDeath.abs(), taxAfterFirstDeath.abs()])
          ),
        ],
      ] as const;
    }

    // Show all possible taxes and the total
    return [
      taxBeforeFirstDeathTuple,
      taxAfterFirstDeathTuple,
      taxAfterSecondDeathTuple,
      [
        'Total',
        formatTaxForCumulativeTaxTable(
          sumDecimalJS([
            taxBeforeFirstDeath.abs(),
            taxAfterFirstDeath.abs(),
            taxAfterSecondDeath.abs(),
          ])
        ),
      ],
    ] as const;
  }, [
    afterDeath,
    taxAfterFirstDeath,
    taxAfterSecondDeath,
    taxBeforeFirstDeath,
  ]);

  const rows = useMemo(() => {
    const dataRows = rowLabels.map(([label, value]) => ({
      label,
      value,
      valueProps: { sx: { color: COLORS.MATH.NEGATIVE } },
    }));

    if (includeHeader) {
      return [
        {
          label: 'Cumulative tax',
          labelProps: { variant: 'h6' as const },
          value: '',
        },
        ...dataRows,
      ];
    }

    return dataRows;
  }, [includeHeader, rowLabels]);

  return (
    <Stack>
      <MiniTable
        rows={rows}
        variant={rowLabels.length > 1 ? 'tally' : 'default'}
      />
    </Stack>
  );
}
