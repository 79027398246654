export interface ErrorContent {
  heading: string;
  body: string;
}

export enum ErrorCodes {
  UNKNOWN = 'UNKNOWN',
  PROPOSAL_EXPIRED = 'PROPOSAL_EXPIRED',
  DOCUMENT_SHARING_EXPIRED = 'DOCUMENT_SHARING_EXPIRED',
  NOT_FOUND = 'NOT_FOUND',
}

export const ERROR_CONTENT: Record<ErrorCodes, ErrorContent> = {
  [ErrorCodes.UNKNOWN]: {
    heading: 'An error occurred',
    body: `Something unexpected happened. We were automatically notified, and we'll be looking into it shortly.`,
  },
  [ErrorCodes.DOCUMENT_SHARING_EXPIRED]: {
    heading: 'This document sharing link has expired',
    body: `Please reach out to your primary contact for assistance`,
  },
  [ErrorCodes.PROPOSAL_EXPIRED]: {
    heading: 'This proposal has expired',
    body: `Please reach out to your primary contact for assistance`,
  },
  [ErrorCodes.NOT_FOUND]: {
    heading: `We can't find this page`,
    body: `We weren't able to find a page associated with this link. Please try to find this content in another way or reach out to your primary contact for assistance.`,
  },
};
