import Decimal from 'decimal.js';
import { first } from 'lodash';

import { sortDispositiveProvisions } from '@/modules/dispositiveProvisions/DispositiveProvisionsListView/DispositiveProvisionsListView.utils';
import {
  DispositiveProvisionTemplateData_DispositiveProvisionTemplateFragment,
  DispositiveProvisionTemplateDataQueryHookResult,
  useDispositiveProvisionTemplateDataQuery,
} from '@/modules/dispositiveProvisions/DispositiveProvisionsTemplateSplitScreenModal/graphql/DispositiveProvisionTemplateData.generated';
import { SimulateDispositiveProvisions_SimulateDispositiveProvisionFragment } from '@/modules/dispositiveProvisions/graphql/SimulateDispositiveProvisions.generated';
import { getNodes } from '@/utils/graphqlUtils';

import { TRANSFER_TAX_KIND_DISPLAY_NAME } from '../../DispositiveProvisionsForm.constants';

interface useDispositiveProvisionTemplateDisplayDataOutput
  extends DispositiveProvisionTemplateDataQueryHookResult {
  displayName: string;
  recipients: DispositiveProvisionTemplateRecipientDisplayData[];
  template:
    | DispositiveProvisionTemplateData_DispositiveProvisionTemplateFragment
    | undefined;
}

export interface DispositiveProvisionTemplateRecipientDisplayData {
  id: string;
  displayName: string;
  kind: string;
  amount: Decimal;
  notes: string | null;
}

export function useDispositiveProvisionTemplateDisplayData(
  templateId: string | undefined | null,
  relevantSimulationResult:
    | SimulateDispositiveProvisions_SimulateDispositiveProvisionFragment[]
    | undefined
): useDispositiveProvisionTemplateDisplayDataOutput {
  const response = useDispositiveProvisionTemplateDataQuery({
    variables: {
      templateWhere: {
        id: templateId,
      },
    },
    skip: !templateId,
  });

  const template = first(
    getNodes(response.data?.dispositiveProvisionTemplates)
  );

  const provisions = getNodes(template?.dispositiveProvisions);

  const recipients = provisions
    .sort(sortDispositiveProvisions)
    .map<DispositiveProvisionTemplateRecipientDisplayData>(
      (provision, index) => ({
        id: provision.id,
        displayName:
          provision.entity?.subtype?.displayName ||
          provision.testamentaryEntity?.displayName ||
          provision.individual?.displayName ||
          provision.organization?.name ||
          '',
        kind:
          (provision.transferTaxKind &&
            TRANSFER_TAX_KIND_DISPLAY_NAME[provision.transferTaxKind]) ??
          undefined,
        amount:
          relevantSimulationResult?.[index]?.transferAmount || new Decimal(0),
        notes: provision.notes || null,
      })
    );

  return {
    ...response,
    recipients,
    displayName: template?.displayName || '',
    template,
  };
}
