import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownButton } from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { SidePanel } from '@/components/modals/SidePanel';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useModalState } from '@/hooks/useModalState';
import { ClientOrganizationModal } from '@/modules/clientOrganizations/ClientOrganizationModal';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { EditButton } from '../components/EditButton';
import { SummaryPanelLoader } from '../components/SummaryPanelLoader';
import { useOrganizationSummaryPanelQuery } from './graphql/OrganizationSummaryPanel.generated';
import { getSummaryFields } from './OrganizationSummaryPanel.utils';
import {
  ClientOrganizationTabs,
  useClientOrganizationPanels,
} from './useClientOrganizationPanels';

interface CurrentView {
  name: string;
  value: ClientOrganizationTabs;
}

export interface OrganizationSummaryPanelProps {
  organizationId: string;
  householdId: string;
  isOnSourceWaterfall?: boolean;
  onClose: () => void;
}

export const OrganizationSummaryPanel = ({
  organizationId,
  householdId,
  isOnSourceWaterfall = false,
  onClose,
}: OrganizationSummaryPanelProps) => {
  const [{ isModalOpen }, { closeModal, openModal, setModalIsOpen }] =
    useModalState();
  const { showFeedback } = useFeedback();
  const [currentView, setCurrentView] = useState<CurrentView>();

  const { data, loading } = useOrganizationSummaryPanelQuery({
    variables: { where: { id: organizationId } },
    onError: (error) => {
      showFeedback(
        `Could get organization information. Please refresh the page and try again.`
      );
      diagnostics.error(`failed to fetch organization`, error, {
        organizationId,
        householdId,
      });
    },
  });

  const org = useMemo(() => getNodes(data?.clientOrganizations)[0], [data]);
  const summaryFields = getSummaryFields(org);

  const handleClose = () => {
    closeModal();
    onClose();
  };

  const panels = useClientOrganizationPanels({
    summaryFields,
  });

  const selectItems = useMemo(() => {
    return panels.map(({ name, value }) => {
      return {
        name,
        value,
        clickHandler: () => setCurrentView({ name, value }),
      };
    });
  }, [panels]);

  const handleSetCurrentView = useCallback(
    (views: typeof selectItems) => {
      if (!views?.length) {
        return;
      }

      if (isOnSourceWaterfall) {
        // Set the inital tab to the dispositions tab
        // when on a source waterfall
        setCurrentView(
          views.find((v) => v.value === ClientOrganizationTabs.Disposition) ??
            views[0]
        );
      } else {
        setCurrentView(views[0]);
      }
    },
    [isOnSourceWaterfall]
  );

  useEffect(
    function handleSetCurrentViewOnLoad() {
      // Once our available tabs are loaded, set the current view
      // using the tab reset handler.
      if (selectItems && !currentView) {
        handleSetCurrentView(selectItems);
      }
    },
    [currentView, handleSetCurrentView, selectItems]
  );

  const CurrentPanel = useMemo(
    () =>
      panels.find(({ value }) => value === currentView?.value)?.component ??
      null,
    [currentView?.value, panels]
  );

  return (
    <>
      <SidePanel.Panel>
        <SidePanel.Header title={summaryFields.title} onClose={handleClose}>
          {selectItems && (
            <DropdownButton
              showArrow
              name="Dropdown-selectView"
              variant="secondary"
              size="sm"
              buttonContent={currentView?.name || ''}
              items={selectItems}
              contentBoxSx={{ width: '100%', justifyContent: 'space-between' }}
            />
          )}
        </SidePanel.Header>
        <SidePanel.Content>
          {loading ? (
            <SummaryPanelLoader />
          ) : (
            <SidePanel.Section>{CurrentPanel}</SidePanel.Section>
          )}
        </SidePanel.Content>
        <SidePanel.Footer>
          <EditButton onClick={openModal}>Edit details</EditButton>
        </SidePanel.Footer>
      </SidePanel.Panel>
      <ClientOrganizationModal
        householdId={householdId}
        organizationId={organizationId}
        isOpen={isModalOpen}
        setIsOpen={setModalIsOpen}
      />
    </>
  );
};
