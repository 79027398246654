import { Box, Stack, SxProps, Theme, useTheme } from '@mui/material';
import React, { ComponentProps } from 'react';

import { Lightbulb05Icon } from '@/components/icons/Lightbulb05Icon';
import { COLORS } from '@/styles/tokens/colors';

import { RibbonHorizontal } from '../display/Ribbon/RibbonHorizontal';

export type RibbonCalloutCardProps = React.PropsWithChildren<{
  sx?: SxProps<Theme>;
  contentContainerProps?: ComponentProps<typeof Stack>;
}>;

export function RibbonCalloutCard({
  children,
  sx = {},
  contentContainerProps = {},
}: RibbonCalloutCardProps) {
  const theme = useTheme();
  return (
    <Box
      flexGrow={0}
      flexShrink={0}
      height="fit-content"
      sx={{
        boxShadow: theme.palette.shadows.lgYInvert,
        backgroundColor: COLORS.GRAY[100],
        ...sx,
      }}
      position="relative"
    >
      <RibbonHorizontal
        ribbonColor={theme.palette.education.main}
        icon={<Lightbulb05Icon size={24} />}
      />
      <Stack
        direction="column"
        padding={3}
        paddingLeft={7}
        spacing={1}
        {...contentContainerProps}
      >
        {children}
      </Stack>
    </Box>
  );
}
