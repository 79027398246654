import React, { useMemo } from 'react';
import { useStyles } from 'tss-react/mui';

import { HeaderList } from '@/components/lists/HeaderList/HeaderList';
import { AISuggestionMatcherRow } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionMatcherRow';
import {
  AISuggestionMatch,
  AISuggestionsMatcherVariant,
  SuggestionsGroup,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import { useAiSuggestionsMatcherContext } from '@/modules/aiSuggestions/AISuggestionsMatcher/context/aiSuggestionsMatcher.context';
import { CommonAiSuggestionFragment } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { COLORS } from '@/styles/tokens/colors';

interface AISuggestionsMatcherGroupProps {
  variant: AISuggestionsMatcherVariant;
  suggestionsGroup: SuggestionsGroup;
  onSetSuggestionMatch: (
    suggestionId: string,
    matched: AISuggestionMatch
  ) => void;
  onOpenAddAnythingModal: (suggestion: CommonAiSuggestionFragment) => void;
}

export function AISuggestionsMatcherGroup({
  variant,
  suggestionsGroup,
  onSetSuggestionMatch,
  onOpenAddAnythingModal,
}: AISuggestionsMatcherGroupProps) {
  const { css } = useStyles();
  const {
    matcherConfigs: { suggestionKinds },
  } = useAiSuggestionsMatcherContext();

  // Sanity check to verify suggestions are supported by the current variant.
  const filteredSuggestions = useMemo(
    () =>
      suggestionsGroup.suggestions.filter((s) =>
        suggestionKinds.includes(s.kind)
      ),
    [suggestionKinds, suggestionsGroup.suggestions]
  );

  if (!filteredSuggestions.length) {
    return null;
  }

  return (
    <HeaderList
      heading={suggestionsGroup.groupLabel}
      classes={{
        headingRoot: css({
          backgroundColor: 'transparent',
          borderBottomStyle: 'solid',
          borderBottomColor: COLORS.TEAL[700],
          borderBottomWidth: '1px',
          paddingLeft: 0,
        }),
        headingText: css({
          color: COLORS.TEAL[700],
        }),
      }}
      data-testid="ai-suggestions-matcher-group"
    >
      {filteredSuggestions.map((suggestion) => (
        <AISuggestionMatcherRow
          key={suggestion.id}
          variant={variant}
          suggestion={suggestion}
          onSetSuggestionMatch={(suggestionId, matched) =>
            onSetSuggestionMatch(suggestionId, { ...matched, suggestion })
          }
          onOpenAddAnythingModal={() => onOpenAddAnythingModal(suggestion)}
        />
      ))}
    </HeaderList>
  );
}
