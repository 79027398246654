import { Box, Stack } from '@mui/material';
import { GridApiPro, useGridApiRef } from '@mui/x-data-grid-pro';
import { first } from 'lodash';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { setTimeout } from 'timers';

import { InteractionParadigmContext } from '@/contexts/InteractionParadigm.context';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { ClientValuePerformanceDashboardContent } from '@/pages/ClientDetailsPage/ClientDetailsPerformancePage/ClientValuePerformanceDashboard';
import { ClientValuePerformanceDashboardSummary } from '@/pages/ClientDetailsPage/ClientDetailsPerformancePage/ClientValuePerformanceDashboard.components';
import {
  mapAdvisorClientToValueDashboardRows,
  Row,
} from '@/pages/ClientDetailsPage/ClientDetailsPerformancePage/ClientValuePerformanceDashboard.utils';
import { useClientValuePerformanceDashboardQuery } from '@/pages/ClientDetailsPage/ClientDetailsPerformancePage/graphql/ClientValuePerformanceDashboard.generated';
import { getNodes } from '@/utils/graphqlUtils';
import { requestAnimationFramePromise } from '@/utils/requestAnimationFramePromise';

import { PrintStatusSentinel } from '../components/PrintStatusSentinel';
import { PrintStatuses } from '../printOnly.constants';
import { PrintOnlyClientDashboardFooter } from './PrintOnlyClientDashboard.footer';
import { PrintOnlyClientDashboardHeader } from './PrintOnlyClientDashboard.header';
import { splitRowsForPrint } from './PrintOnlyClientDashboard.utils';

export function PrintOnlyClientDashboard() {
  /*
    an array of gridApiRefs, which will be iterated through after
    everything is loaded to force-resize the individual tables so
    they don't get cut off.  one ref should be set per table, and
    this needs to be a ref so it can be updated by the downstream
    tables.
   */
  const gridApiRefsRef = useRef<MutableRefObject<GridApiPro>[]>([]);
  const householdId = useRequiredParam('householdId');

  const { data, loading, error } = useClientValuePerformanceDashboardQuery({
    variables: { householdId },
  });

  const household = first(getNodes(data?.households));
  const clientPerformanceReport = household?.clientPerformanceReport;
  const { rows, familyWealthTransferred, charityWealthTransferred } =
    mapAdvisorClientToValueDashboardRows(household);

  const [printStatus, setPrintStatus] = useState<PrintStatuses>(
    PrintStatuses.LOADING
  );

  const printRows = splitRowsForPrint(rows);

  // we wrap this in a useEffect instead of doing straight computation of the status because
  // the MUI table component needs an extra pass to render properly
  useEffect(() => {
    if (loading || !data || !clientPerformanceReport) {
      setPrintStatus(PrintStatuses.LOADING);
      return;
    }
    if (error) {
      setPrintStatus(PrintStatuses.ERROR);
      return;
    }

    // check for valid re-render case
    if (gridApiRefsRef.current.length !== printRows.length) {
      setPrintStatus(PrintStatuses.ERROR);
      return;
    }

    // wait 2s for the table to re-render...
    setTimeout(async () => {
      // ...then wait for the next animation frame
      await requestAnimationFramePromise();

      // for each table:
      gridApiRefsRef.current.forEach((gridApiRef) => {
        // disable virtualization
        gridApiRef.current.unstable_setVirtualization(false);
        // force an update
        gridApiRef.current.forceUpdate();
      });

      // then set ready to print
      setPrintStatus(PrintStatuses.READY);
      return;
    }, 200);
  }, [clientPerformanceReport, data, error, loading, printRows.length]);

  return (
    <InteractionParadigmContext.Provider value={{ viewOnly: true }}>
      <style>{'body { background: none }'}</style>
      <PrintStatusSentinel status={printStatus} />
      <Box padding={3}>
        <PrintOnlyClientDashboardHeader
          clientName={household?.displayName || ''}
        />
        <ClientValuePerformanceDashboardSummary
          familyGivingWealthTransferred={familyWealthTransferred}
          charityWealthTransferred={charityWealthTransferred}
          clientPerformanceReport={clientPerformanceReport}
        />
        <Stack spacing={3}>
          {printRows.map(({ rows: printRowSet }, index) => (
            <PrintTable
              rows={printRowSet}
              gridRowApiRefsRef={gridApiRefsRef}
              index={index}
              key={index}
              householdId={householdId}
              pageBreakAfter={
                index === printRows.length - 1 ? 'never' : 'always'
              }
            />
          ))}
        </Stack>
        <Box sx={{ pageBreakInside: 'avoid' }}>
          <PrintOnlyClientDashboardFooter />
        </Box>
      </Box>
    </InteractionParadigmContext.Provider>
  );
}

function PrintTable({
  rows,
  gridRowApiRefsRef,
  index,
  pageBreakAfter,
  householdId,
}: {
  rows: Row[];
  gridRowApiRefsRef: MutableRefObject<MutableRefObject<GridApiPro>[]>;
  pageBreakAfter: 'never' | 'always';
  index: number;
  householdId: string;
}) {
  const gridApiRef = useGridApiRef();
  gridRowApiRefsRef.current[index] = gridApiRef;
  return (
    <Box
      sx={{
        pageBreakAfter,
      }}
    >
      <ClientValuePerformanceDashboardContent
        gridApiRef={gridApiRef}
        rows={rows}
        householdId={householdId}
      />
    </Box>
  );
}
