import { useApolloClient } from '@apollo/client';
import { Box } from '@mui/material';
import React from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { DocumentUploader } from '@/modules/documents/DocumentUploader/DocumentUploader';
import {
  DocumentRepresentationList,
  RequeryDocumentRepresentationListDocuments,
} from '@/modules/files/DocumentRepresentation/DocumentRepresentationList';
import { DocumentType } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import { GetImplementationTasksV2Document } from '../../graphql/ImplementationTasks.generated';
import { TaskHeaderCard } from '../../TaskHeaderCard';
import { TaskBodyProps } from '../../types';
import { useCompleteEntityTaskMutation } from '../graphql/CompleteEntityTask.generated';

export function UploadTrustDocumentTaskBody({
  task,
  footer,
  onCompleteTask,
}: TaskBodyProps) {
  // mutations
  const [completeTask] = useCompleteEntityTaskMutation();
  const apolloClient = useApolloClient();

  // state
  const [createDocumentErrorMessage, setCreateDocumentErrorMessage] =
    React.useState<string | null>(null);

  const documentsQueryFilter = {
    hasEntityWith: [{ id: task.entityId }],
    type: DocumentType.SignedTrustDocument,
  };

  // handlers
  async function handleTaskComplete() {
    try {
      await completeTask({
        variables: {
          taskID: task.id,
        },
        refetchQueries: [GetImplementationTasksV2Document],
      });
      onCompleteTask();
    } catch (err) {
      diagnostics.error(`failed to complete ${task.body.type}`, err as Error, {
        taskID: task.id,
        taskType: task.type,
        householdId: task.householdId,
        entityId: task.entityId,
      });
    }
  }

  async function handleNewDocumentUpload() {
    setCreateDocumentErrorMessage(null);
    await apolloClient.refetchQueries({
      include: [RequeryDocumentRepresentationListDocuments],
    });
  }

  return (
    <TaskHeaderCard task={task} footer={footer}>
      <Box>
        <Box minHeight={400}>
          <DocumentUploader
            errorMessage={createDocumentErrorMessage || undefined}
            householdId={task.householdId}
            onDocumentUploadSuccess={handleNewDocumentUpload}
            onDocumentUploadError={() =>
              setCreateDocumentErrorMessage(
                `We weren't able to complete your document generation process. Please try again.`
              )
            }
            getCreateDocumentInput={({ fileId, fileName }) => ({
              entityID: task.entityId,
              householdID: task.householdId,
              type: DocumentType.SignedTrustDocument,
              fileID: fileId,
              name: fileName,
            })}
          />
          <DocumentRepresentationList
            showDelete={true}
            sx={{ my: 2 }}
            documentsLike={documentsQueryFilter}
          />
        </Box>
        <Button
          variant="primary"
          fullWidth
          size="sm"
          onClick={handleTaskComplete}
        >
          I&apos;m done uploading documents
        </Button>
      </Box>
    </TaskHeaderCard>
  );
}
