const VARIANTS = [
  'default',
  'multipleOwnerNoPercent',
  'multipleOwnerNoPercentNoControllingParty',
] as const;
export type VariantType = (typeof VARIANTS)[number];

export enum WealthOwnerType {
  DONOR = 'Donor',
  OWNER = 'Owner',
}

export interface AccountDetails_ControllingParty {
  controllingPartyId: string;
}

export interface AccountDetails_AccountOwner {
  ownerId: string;
}

// These fields are tacked onto the base fields for BasicInformationSubformType,
// and only apply to DAF, PF, and personal account entities.
export interface NonGrantorPrincipalFieldsType {
  owner?: AccountDetails_AccountOwner | ''; // this should only be used for single owner/donor entities
  owners?: AccountDetails_AccountOwner[]; // this should only be used for multiple owner/donor entities
  controllingParties?: AccountDetails_ControllingParty[]; // insurance-accounts do NOT have this field
}
