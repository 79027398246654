import { Box, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

interface EntitySubformsContainerProps extends PropsWithChildren {
  contentContainerId: string;
  backgroundColor?: string;
}

export const EntitySubformsContainer = ({
  children,
  contentContainerId,
  backgroundColor,
}: EntitySubformsContainerProps) => {
  return (
    <Box
      p={3}
      display="flex"
      justifyContent="center"
      flexGrow={1}
      overflow="auto"
      bgcolor={backgroundColor}
    >
      <Stack
        id={contentContainerId}
        spacing={5}
        width={{ md: '100%', lg: '70%' }}
        maxWidth={800}
        minWidth={{ md: 'auto', lg: 760 }}
      >
        {children}
      </Stack>
    </Box>
  );
};
