import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import {
  useAssetProviderDisplayName,
  useIsAssetProviderIntegrationEnabled,
} from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import { IntegrationClientsTypeahead } from '@/modules/assetProviderIntegrations/shared/IntegrationClientTypeahead/IntegrationClientsTypeahead';
import { PathsOf } from '@/types/subform';
import { diagnostics } from '@/utils/diagnostics';

import { useConnectHouseholdToAssetProviderMutation } from './graphql/ConnectHouseholdToAssetProviderModal.generated';

interface ConnectHouseholdToAssetProviderModalProps {
  isOpen: boolean;
  onClose: () => void;
  householdId: string;
}

interface ConnectHouseholdForm {
  integrationClientIds: string[];
}

type ConnectHouseholdFormPaths = PathsOf<ConnectHouseholdForm>;

function ConnectHouseholdToAssetProvider({
  onClose,
  householdId,
}: Omit<ConnectHouseholdToAssetProviderModalProps, 'isOpen'>) {
  const { showFeedback } = useFeedback();
  const [updateHousehold] = useConnectHouseholdToAssetProviderMutation({
    onCompleted: () => {
      showFeedback('Successfully connected household to provider', {
        variant: 'success',
      });
      onClose();
    },
    onError: () => {
      showFeedback(
        'Failed to connect household. Please refresh the page and try again.'
      );
    },
  });
  const { formRef, handleSave } = useFormSaveHandler();
  const { handleSubmit, formState, control } =
    useFormContext<ConnectHouseholdForm>();
  const assetProviderName = useAssetProviderDisplayName();
  const { canConnectToIntegrations, integrationsConnected } =
    useIsAssetProviderIntegrationEnabled();

  const enabledIntegration = useMemo(() => {
    if (canConnectToIntegrations.length === 1)
      return canConnectToIntegrations[0];

    const msg = 'Expected exactly one enabled integration';
    diagnostics.error(msg, new Error(msg), {
      canConnectToIntegrations: JSON.stringify(canConnectToIntegrations),
    });
    return null;
  }, [canConnectToIntegrations]);

  const onSubmit = handleSubmit((formData) => {
    void updateHousehold({
      variables: {
        id: householdId,
        input: {
          addIntegrationClientIDs: formData.integrationClientIds,
        },
      },
    });
  });

  // just for ts
  if (!enabledIntegration) return null;
  const isLoggedIntoIntegration =
    enabledIntegration && integrationsConnected.includes(enabledIntegration);

  return (
    <DialogModal
      isOpen={true}
      onClose={onClose}
      heading={`Select clients in ${assetProviderName}`}
      actions={
        <FormModalActions.Provider formState={formState}>
          <Button variant="primary" size="sm" fullWidth onClick={handleSave}>
            {`Enable ${assetProviderName} for this client`}
          </Button>
        </FormModalActions.Provider>
      }
    >
      <Stack gap={3}>
        <form ref={formRef} onSubmit={onSubmit} noValidate>
          <FormLayoutRow>
            <FormLayoutItem>
              <IntegrationClientsTypeahead<ConnectHouseholdForm>
                control={control}
                provider={enabledIntegration}
                notLoggedIn={!isLoggedIntoIntegration}
                fieldName={
                  `integrationClientIds` as const satisfies ConnectHouseholdFormPaths
                }
                label="Select integrated accounts"
                hideLabel
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </form>
      </Stack>
    </DialogModal>
  );
}

export function ConnectHouseholdToAssetProviderModal(
  props: ConnectHouseholdToAssetProviderModalProps
) {
  const formMethods = useForm<ConnectHouseholdForm>({
    defaultValues: {
      integrationClientIds: [],
    },
  });

  if (!props.isOpen) return null;

  return (
    <FormProvider {...formMethods}>
      <ConnectHouseholdToAssetProvider {...props} />
    </FormProvider>
  );
}
