import { ApolloError } from '@apollo/client';
import { Stack } from '@mui/material';
import { compact, noop } from 'lodash';
import { createContext, useContext, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import {
  MiniTable,
  MiniTableRow,
} from '@/components/tables/MiniTable/MiniTable';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useReportError } from '@/hooks/useReportError';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { FullScreenTaskLayout } from '../FullScreenTaskLayout';
import { ILITTaskContext } from '../ILITTaskContext';
import { ILITTaskFooter } from '../ILITTaskFooter/ILITTaskFooter';
import { ILITTaskSidebar } from '../ILITTaskSidebar/ILITTaskSidebar';
import { useCrummeyLetterTaskMutationMutation } from './graphql/CrummeyLetterMutations.generated';
import { GetIlitCrummeyLetterTaskDetailsQueryVariables } from './graphql/GetILITCrummeyLetterTaskDetails.generated';
import { ILITSendCrummeyLetterTaskForm } from './ILITSendCrummeyLetterTask.types';
import { ILITSendCrummeyLetterTaskBody } from './ILITSendCrummeyLetterTask.UI';
import {
  generateCompleteTaskPayload,
  useFormQueryData,
} from './ILITSendCrummeyLetterTask.utils';

function ILITSendCrummeyLetterTaskInner() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const currentUser = useCurrentUser();
  const { entityId, taskId, onCompleteTask } = useContext(
    ILITSendCrummeyLetterTaskContext
  );

  const variables: GetIlitCrummeyLetterTaskDetailsQueryVariables = { taskId };
  const { loading, displayData, formData, error } = useFormQueryData(variables);
  const formMethods = useForm<ILITSendCrummeyLetterTaskForm>({
    defaultValues: {
      documentIds: [],
      letterDate: null,
      withdrawalPeriodDays: 0,
      ilitTrustId: '',
    },
    values: formData,
  });

  if (error) {
    showFeedback(
      'Encountered an error loading task data.  Please try again later.'
    );
    reportError('Encountered error when loading Crummey task', error);
  }
  const { getValues, handleSubmit } = formMethods;

  const { gifts, beneficiaryName } = displayData || {
    gifts: [],
    beneficiaryName: '',
  };

  // not creating onCompleted/onError callbacks here since the same mutation is used for
  // saving and marking complete, with only a `task.completedAt` parameter differentiating them
  const [crummeyLetterMutation, { loading: isSubmitting }] =
    useCrummeyLetterTaskMutationMutation();

  function onClickComplete() {
    const variables = generateCompleteTaskPayload({
      taskId,
      entityId,
      formData: getValues(),
      initialDocumentIds:
        compact(formMethods.formState.defaultValues?.documentIds) ?? [],
      currentUserId: currentUser?.id || '',
    });
    void crummeyLetterMutation({
      variables,
      onCompleted: () => {
        onCompleteTask();
        showFeedback('Marked Crummey letter as sent.', { variant: 'success' });
      },
      onError: (error: ApolloError) => {
        showFeedback(
          'Encountered error when marking the task as complete.  Please try again later.',
          { variant: 'error' }
        );
        reportError('Error when completing Crummey letter task', error);
      },
    });
  }

  const handleCompleteTask = handleSubmit(onClickComplete);

  const tableRows: MiniTableRow[] = useMemo(() => {
    if (gifts.length <= 1) {
      return [
        { label: 'Beneficiary', value: beneficiaryName },
        {
          label: 'Date of gift',
          value: gifts[0]?.giftDate
            ? formatDateToMonDDYYYY(gifts[0].giftDate)
            : EMPTY_CONTENT_HYPHEN,
        },
        {
          label: gifts[0]?.grantorName
            ? `Gift from ${gifts[0].grantorName}`
            : 'Gift amount',
          value: gifts[0]?.amount
            ? formatCurrency(gifts[0].amount)
            : EMPTY_CONTENT_HYPHEN,
        },
      ];
    }
    return [
      { label: 'Beneficiary', value: beneficiaryName },
      ...gifts.map((gift) => ({
        label: `Date of gift from ${gift.grantorName}`,
        value: formatDateToMonDDYYYY(gift.giftDate),
      })),
      { label: '', value: '' },
      ...gifts.map((gift) => ({
        label: `Gift from ${gift.grantorName}`,
        value: formatCurrency(gift.amount),
      })),
      {
        label: 'Total gifted',
        value: formatCurrency(sumDecimalJS(gifts.map((gift) => gift.amount))),
      },
    ];
  }, [beneficiaryName, gifts]);

  return (
    <FormProvider {...formMethods}>
      <Stack height="100dvh">
        <FullScreenTaskLayout
          LeftPaneContent={
            <ILITTaskSidebar
              heading="Prepare & send Crummey letter"
              subheading="Create and send a Crummey letter to the following ILIT beneficiary"
            >
              <MiniTable
                variant={gifts.length <= 1 ? 'default' : 'tally'}
                rows={tableRows}
              />
            </ILITTaskSidebar>
          }
          RightPaneContent={<ILITSendCrummeyLetterTaskBody />}
          Footer={
            <ILITTaskFooter
              skippable
              onComplete={handleCompleteTask}
              disabled={loading}
              submitting={isSubmitting}
            />
          }
        />
      </Stack>
    </FormProvider>
  );
}

export interface ILITSendCrummeyLetterTaskProps {
  householdId: string;
  entityId: string;
  taskId: string;
  onClose: () => void;
  onCompleteTask: () => void;
}

export const ILITSendCrummeyLetterTaskContext =
  createContext<ILITSendCrummeyLetterTaskProps>({
    householdId: '',
    entityId: '',
    taskId: '',
    onClose: noop,
    onCompleteTask: noop,
  });

export function ILITSendCrummeyLetterTask({
  householdId,
  entityId,
  taskId,
  onClose,
  onCompleteTask,
}: ILITSendCrummeyLetterTaskProps) {
  return (
    <ILITTaskContext.Provider
      value={{ householdId, entityId, taskId, onClose, onCompleteTask }}
    >
      <ILITSendCrummeyLetterTaskContext.Provider
        value={{
          householdId,
          entityId,
          taskId,
          onClose,
          onCompleteTask,
        }}
      >
        <ILITSendCrummeyLetterTaskInner />
      </ILITSendCrummeyLetterTaskContext.Provider>
    </ILITTaskContext.Provider>
  );
}
