import { compact } from 'lodash';
import { usePrevious } from 'react-use';

import {
  GiftDesignerStages,
  GratDesignerStages,
  ROUTE_KEYS,
} from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { ENTITY_TYPES } from '../entities/entities.constants';
import { getStageAwareLinkForEntity } from '../entities/utils/entitiesNavigationUtils';
import { getLinkForRecentEntity } from '../recents/RecentEntitiesSidebar/entitiesUtils';
import { RecentEntitiesQuery } from '../recents/RecentEntitiesSidebar/graphql/RecentEntitiesSidebar.generated';
import {
  GlobalSearchQuery,
  GlobalSearchQuery_ClientOrganziationResults_SearchResultFragment,
  GlobalSearchQuery_ClientProfileResults_SearchResultFragment,
  GlobalSearchQuery_DocumentResults_SearchResultFragment,
  GlobalSearchQuery_EntityResults_SearchResultFragment,
  GlobalSearchQuery_HouseholdResults_SearchResultFragment,
  GlobalSearchQuery_ProposalResults_SearchResultFragment,
} from './graphql/GlobalSearchQuery.generated';

export interface SearchResult {
  heading: React.ReactNode;
  subheading?: string;
  path: string;
  nodeId: string;
  score: number;
}

// NB -- search result scores are distances from the query string, so weighting with a smaller
// number will give the result higher relative prominence
const SCORE_WEIGHT_HIGH = 0.1 as const;
const SCORE_WEIGHT_MEDIUM = 0.6 as const;
const SCORE_WEIGHT_LOW = 1.0 as const;

function mapHouseholdResult(
  householdResult: GlobalSearchQuery_HouseholdResults_SearchResultFragment
): SearchResult | null {
  const { node, score } = householdResult;
  if (node.__typename !== 'Household') {
    return null;
  }
  return {
    nodeId: node.id,
    heading: node.displayName,
    subheading: 'Client',
    path: getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW, {
      householdId: node.id,
    }),
    score: score * SCORE_WEIGHT_HIGH,
  };
}

function mapEntityResult(
  entityResult: GlobalSearchQuery_EntityResults_SearchResultFragment
): SearchResult | null {
  const { node, score } = entityResult;
  if (node.__typename !== 'Entity') {
    return null;
  }

  return {
    nodeId: node.id,
    heading: node.subtype.displayName,
    subheading: `${node.household.displayName} | Entity`,
    path: getStageAwareLinkForEntity(node),
    score: score * SCORE_WEIGHT_HIGH,
  };
}

function mapIndividualResult(
  individualResult: GlobalSearchQuery_ClientProfileResults_SearchResultFragment
): SearchResult | null {
  const { node, score } = individualResult;
  if (node.__typename !== 'ClientProfile' || !node.household) {
    return null;
  }

  return {
    nodeId: node.id,
    heading: node.displayName,
    subheading: `${node.household.displayName} | Individual`,
    path: getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_DETAILS_PEOPLE_ORGANIZATIONS,
      {
        householdId: node.household.id,
      }
    ),
    score: score * SCORE_WEIGHT_LOW,
  };
}

function mapDocumentResult(
  documentResult: GlobalSearchQuery_DocumentResults_SearchResultFragment
): SearchResult | null {
  const { node, score } = documentResult;

  if (node.__typename !== 'Document' || !node.household) {
    return null;
  }

  return {
    nodeId: node.id,
    heading: node.name,
    subheading: `${node.household.displayName} | Document`,
    score: score * SCORE_WEIGHT_MEDIUM,
    path: getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_DETAILS_DOCUMENT_DETAILS,
      {
        householdId: node.household.id,
        documentId: node.id,
      }
    ),
  };
}

function mapOrganizationResult(
  organizationResult: GlobalSearchQuery_ClientOrganziationResults_SearchResultFragment
): SearchResult | null {
  const { node, score } = organizationResult;

  if (node.__typename !== 'ClientOrganization' || !node.household) {
    return null;
  }

  return {
    nodeId: node.id,
    heading: node.name,
    subheading: `${node.household.displayName} | Organization`,
    path: getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_DETAILS_PEOPLE_ORGANIZATIONS,
      {
        householdId: node.household.id,
      }
    ),
    score: score * SCORE_WEIGHT_LOW,
  };
}

function mapProposalResult(
  proposalResult: GlobalSearchQuery_ProposalResults_SearchResultFragment
): SearchResult | null {
  const { node, score } = proposalResult;
  if (node?.__typename !== 'Proposal' || !node.household) {
    return null;
  }

  // default case -- GRAT
  let path = getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER, {
    householdId: node.household.id,
    entityId: node.id,
    designerStage: GratDesignerStages.BASIC_INFORMATION,
    entityType: ENTITY_TYPES.GRAT,
  });

  if (node.crtProposal) {
    path = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION,
      { householdId: node.household.id, proposalId: node.id, variant: 'crt' }
    );
  } else if (node.cltProposal) {
    path = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_GIFT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION,
      { householdId: node.household.id, proposalId: node.id, variant: 'clt' }
    );
  } else if (node.giftingProposal) {
    path = getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_GIFT_DESIGNER, {
      householdId: node.household.id,
      proposalId: node.id,
      designerStage: GiftDesignerStages.BASIC_INFORMATION,
    });
  }

  return {
    nodeId: node.id,
    heading: node.displayName,
    subheading: `${node.household.displayName} | Proposal`,
    path,
    score: score * SCORE_WEIGHT_MEDIUM,
  };
}

function generateItemsFromSearchData(
  data: GlobalSearchQuery,
  showWeights: boolean
): SearchResult[] {
  const households = compact(
    data.households?.householdResults.map(mapHouseholdResult) ?? []
  );

  const entities = compact(
    data.entities?.entityResults.map(mapEntityResult) ?? []
  );

  const profiles = compact(
    data.profiles?.clientProfileResults.map(mapIndividualResult) ?? []
  );

  const organizations = compact(
    data.organizations?.clientOrganizationResults.map(mapOrganizationResult) ??
      []
  );

  const documents = compact(
    data.documents?.documentResults.map(mapDocumentResult) ?? []
  );

  const proposals = compact(
    data.proposals?.proposalResults.map(mapProposalResult) ?? []
  );
  return [
    ...households,
    ...entities,
    ...profiles,
    ...organizations,
    ...documents,
    ...proposals,
  ]
    .sort((a, b) => {
      if (a.score > b.score) {
        return 1;
      } else if (b.score > a.score) {
        return -1;
      }
      return 0;
    })
    .map((result) => ({
      ...result,
      subheading: showWeights
        ? `${result.subheading} | ${result.score}`
        : result.subheading,
    }));
}

function generateItemsFromRecentsData(
  recentsData: RecentEntitiesQuery
): SearchResult[] {
  if (!recentsData) {
    return [];
  }

  const householdResults = recentsData.recentClients.map<SearchResult>(
    (client) => ({
      heading: client.displayName,
      subheading: 'Recent client',
      path: getLinkForRecentEntity(client),
      nodeId: client.id,
      score: 1,
    })
  );

  const proposalResults = recentsData.recentProposals.map<SearchResult>(
    (proposal) => ({
      heading: proposal.displayName,
      subheading: `${proposal.household.displayName} | Recent proposal`,
      path: getLinkForRecentEntity(proposal),
      nodeId: proposal.id,
      score: 1,
    })
  );

  const entityResults = recentsData.recentEntities.map<SearchResult>(
    (entity) => ({
      nodeId: entity.id,
      heading: entity.subtype.displayName,
      subheading: `${entity.household.displayName} | Recent entity`,
      path: getLinkForRecentEntity(entity),
      score: 1,
    })
  );

  return compact([...householdResults, ...proposalResults, ...entityResults]);
}

export type SearchResultSource = 'recents' | 'search' | 'none';

export function useSearchResults(
  recentsData: RecentEntitiesQuery | undefined,
  searchResults: GlobalSearchQuery | undefined,
  queryString: string,
  loading: boolean,
  showWeights = false
): { results: SearchResult[]; source: SearchResultSource } {
  const previousSearchResults = usePrevious(searchResults);

  if (loading && previousSearchResults) {
    return {
      source: 'search',
      results: generateItemsFromSearchData(previousSearchResults, showWeights),
    };
  }

  if (searchResults && queryString.length > 0) {
    return {
      source: 'search',
      results: generateItemsFromSearchData(searchResults, showWeights),
    };
  }

  if (recentsData) {
    return {
      source: 'recents',
      results: generateItemsFromRecentsData(recentsData),
    };
  }

  return { source: 'none', results: [] };
}
