import { Stack, Typography } from '@mui/material';
import { capitalize, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';

import { Medallion } from '@/components/display/Medallion/Medallion';
import { Button } from '@/components/form/baseInputs/Button';
import { FolderDownloadIcon } from '@/components/icons/FolderDownloadIcon';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { ActionTile } from '@/components/layout/ActionTile/ActionTile';
import { Dialog } from '@/components/modals/Dialog';
import { AI_ONBOARDING_MODAL_STEP_CONFIGS } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.constants';
import { useAIOnboardingModalContext } from '@/modules/aiOnboarding/AIOnboardingModal/context/AIOnboardingModal.context';
import { COLORS } from '@/styles/tokens/colors';
import { pluralize } from '@/utils/formatting/strings';

export enum EndAIOnboardingWorkflowAction {
  CONFIRM_KEEP_DOCUMENTS = 'CONFIRM_KEEP_DOCUMENTS',
  CONFIRM_DELETE_DOCUMENTS = 'CONFIRM_DELETE_DOCUMENTS',
  CANCEL = 'CANCEL',
}

interface ConfirmEndAIOnboardingWorkflowModalProps {
  isOpen: boolean;
  onClose: (action: EndAIOnboardingWorkflowAction) => void;
}

export function ConfirmEndAIOnboardingWorkflowModal({
  isOpen,
  onClose,
}: ConfirmEndAIOnboardingWorkflowModalProps) {
  const { submittedSteps } = useAIOnboardingModalContext();

  const handleCancel = useCallback(() => {
    onClose(EndAIOnboardingWorkflowAction.CANCEL);
  }, [onClose]);

  /**
   * The subtitle text tells users that things they created in previous steps
   * will not be deleted when they end from the current step.
   */
  const subtitle = useMemo(() => {
    if (isEmpty(submittedSteps)) {
      return null;
    }

    const created = submittedSteps
      .map(
        (step) => AI_ONBOARDING_MODAL_STEP_CONFIGS[step].previouslyCreatedText
      )
      .join(', ');

    const steps = pluralize(submittedSteps.length, 'step');

    return capitalize(
      `${created} created in the previous ${steps} will not be deleted`
    );
  }, [submittedSteps]);

  return (
    <Dialog open={isOpen} onClose={handleCancel}>
      <Stack direction="column" p={6} pb={3} gap={3} justifyContent="center">
        <Stack direction="column" gap={0.5} textAlign="center">
          <Typography textAlign="center" variant="h1">
            What would you like to do with documents uploaded for this workflow?
          </Typography>
          {subtitle && <Typography textAlign="center">{subtitle}</Typography>}
        </Stack>
        <Stack direction="row" pt={3} gap={3}>
          <ActionTile
            icon={
              <Medallion
                size={40}
                icon={<FolderDownloadIcon />}
                colors={{
                  background: COLORS.NAVY[500],
                  iconColor: undefined,
                }}
              />
            }
            onClick={() =>
              onClose(EndAIOnboardingWorkflowAction.CONFIRM_KEEP_DOCUMENTS)
            }
            body="End workflow but keep the uploaded documents"
            variant="large"
          />
          <ActionTile
            icon={
              <Medallion
                size={40}
                icon={<Trash01Icon />}
                colors={{
                  background: COLORS.FUNCTIONAL.ERROR.DEFAULT,
                  iconColor: undefined,
                }}
              />
            }
            onClick={() =>
              onClose(EndAIOnboardingWorkflowAction.CONFIRM_DELETE_DOCUMENTS)
            }
            body="End workflow and delete uploaded documents"
            variant="large"
          />
        </Stack>
        <Button variant="transparent" size="sm" onClick={handleCancel}>
          Don&rsquo;t end workflow
        </Button>
      </Stack>
    </Dialog>
  );
}
