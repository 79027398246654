import { Stack } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { Card } from '@/components/layout/Card/Card';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';

import { ADDEPAR_IMPORT_TYPE_DISPLAY } from './graphql/AdminIntegrationsPage.constants';
import { AdminIntegrationsPage_IntegrationConfigurationFragment } from './graphql/AdminIntegrationsPage.generated';

interface AddeparConfigurationSummaryProps {
  configuration: AdminIntegrationsPage_IntegrationConfigurationFragment;
  onEditConnection: () => void;
}
export function AddeparConfigurationSummary({
  configuration,
  onEditConnection,
}: AddeparConfigurationSummaryProps) {
  return (
    <Card variant="filled-light" sx={{ p: 2 }}>
      <Stack spacing={2}>
        <MiniTable
          variant="default"
          rows={[
            {
              label: 'Firm ID',
              value: configuration?.addeparFirmID ?? EMPTY_CONTENT_HYPHEN,
            },
            {
              label: 'Subdomain',
              value: configuration?.addeparSubdomain ?? EMPTY_CONTENT_HYPHEN,
            },
            {
              label: 'Client import type',
              value: configuration?.addeparClientType
                ? ADDEPAR_IMPORT_TYPE_DISPLAY[configuration.addeparClientType]
                : EMPTY_CONTENT_HYPHEN,
            },
          ]}
        />
        <Stack justifyContent="end" direction="row">
          <Button
            variant="secondary"
            size="xs"
            onClick={onEditConnection}
            startIcon={Edit02Icon}
          >
            Edit settings
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}
