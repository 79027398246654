import { useCallback } from 'react';

import { FlowChartProps } from '@/components/diagrams/FlowChart';

import { useFamilyTreeContext } from '../contexts';

export function useFlowChartProps(): FlowChartProps {
  const { state, dispatch } = useFamilyTreeContext();

  const onNodesChange = useCallback<
    NonNullable<FlowChartProps['onNodesChange']>
  >(
    (changes) => {
      dispatch({ type: 'NODES_CHANGED', changes });
    },
    [dispatch]
  );

  return {
    style: state.isInitializingFlowchart ? { opacity: 0 } : undefined,
    nodes: state.nodes,
    edges: state.edges,
    onNodesChange,
  };
}
