import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';

import { HypotheticalTransferFormModalShape } from '../HypotheticalTransferModal.types';

interface PolicyAssetRatioProps {
  disabled: boolean;
  hide: boolean;
}

export function PolicyAssetRatio({ disabled, hide }: PolicyAssetRatioProps) {
  const { control, setValue } =
    useFormContext<HypotheticalTransferFormModalShape>();

  const [_policyOrAsset, _activeTransfer] = useWatch({
    control,
    name: ['_policyOrAsset', '_activeTransfer'],
  });

  useEffect(() => {
    if (_policyOrAsset) return;

    if (_activeTransfer?.sourceOwnedEntity) {
      setValue('_policyOrAsset', 'asset');
      return;
    }

    if (_activeTransfer?.sourceInsurancePolicy) {
      setValue('_policyOrAsset', 'policy');
    }

    setValue('_policyOrAsset', 'policy');
  }, [
    _activeTransfer?.sourceInsurancePolicy,
    _activeTransfer?.sourceOwnedEntity,
    _policyOrAsset,
    setValue,
  ]);

  useEffect(() => {
    if (disabled) return;

    if (_policyOrAsset === 'policy') {
      setValue('sourceOwnedEntityId', '');
    }
    if (_policyOrAsset === 'asset') {
      setValue('sourceInsurancePolicyId', '');
    }
  }, [_policyOrAsset, disabled, setValue]);

  useEffect(() => {
    if (hide) {
      setValue('_policyOrAsset', null);
    }

    if (!hide && !_policyOrAsset) {
      setValue('_policyOrAsset', 'policy');
    }
  }, [_policyOrAsset, hide, setValue]);

  return (
    <FormLayoutRow
      sx={{
        display: hide ? 'none' : undefined,
      }}
    >
      <FormLayoutItem>
        <FormAwareRadioGroup<HypotheticalTransferFormModalShape>
          disabled={disabled}
          control={control}
          fieldName="_policyOrAsset"
          label="Policy or Asset"
          hideLabel
          row
          options={[
            {
              label: 'Insurance policy',
              value: 'policy',
            },
            {
              label: 'Other assets',
              value: 'asset',
            },
          ]}
        />
      </FormLayoutItem>
    </FormLayoutRow>
  );
}
