import { compact } from 'lodash';

import { CompactQueryData } from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { getTypeOrUndefined } from '@/modules/entities/EntitySubforms/utils/shared/common.utils';
import { getNodes } from '@/utils/graphqlUtils';

import { BulkImportForm } from '../BulkImportPage.types';
import { ClientImportSelectionStepHousehold } from '../BulkImportPage.types';
import {
  AddeparClientImportLookup_AddeparClientFragment,
  AddeparClientImportLookupQuery,
  AddeparClientImportLookupQueryVariables,
  useAddeparClientImportLookupQuery,
} from './graphql/AddeparClientImportLookup.generated';

function mapToAddeparImportClient(
  client: AddeparClientImportLookup_AddeparClientFragment
): ClientImportSelectionStepHousehold {
  const integrationClientId = client.id;

  return {
    id: integrationClientId,
    displayName: client.name,
    firstName: '',
    lastName: '',
    ownerID: '',
    state: null,
    mergeHouseholdID: '',
    mergedWithID: '',
    remoteID: integrationClientId,
  };
}

export function mapClientLookupDataToImportClients(
  unlinkedAddeparClients: AddeparClientImportLookup_AddeparClientFragment[]
): ClientImportSelectionStepHousehold[] {
  return compact(unlinkedAddeparClients).map(mapToAddeparImportClient);
}

export function getIntegrationClientSelectionVariables(
  queryString: string
): AddeparClientImportLookupQueryVariables {
  return {
    first: 10,
    where: {
      nameContainsFold: getTypeOrUndefined<string>(queryString),
      hasClientHouseholds: false,
    },
  };
}
export type BulkImportAddeparClientQueryType =
  typeof useAddeparClientImportLookupQuery;
export type BulkImportAddeparClientQueryResponseType = CompactQueryData<
  AddeparClientImportLookupQuery,
  AddeparClientImportLookupQueryVariables
>[];

export function mapAddeparDataToClientDataMap(
  queryData: AddeparClientImportLookupQuery,
  currentUserId?: string
): BulkImportForm['householdMap'] {
  const unlinkedIntegrationClients = getNodes(queryData.paginatedData);
  return unlinkedIntegrationClients.reduce<BulkImportForm['householdMap']>(
    (acc, addeparClient) => {
      const importHousehold = mapToAddeparImportClient(addeparClient);

      const [firstName] = importHousehold.displayName.split(' ', 1);
      const [_, ...lastNameArray] = importHousehold.displayName.split(' ');
      const lastName = lastNameArray.join(' ');

      importHousehold.firstName = firstName || '';
      importHousehold.lastName = lastName;

      importHousehold.ownerID = currentUserId || '';

      acc[addeparClient.id] = importHousehold;

      return acc;
    },
    {}
  );
}
