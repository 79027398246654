import { useMemo } from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { CSSObject } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

import { Draghandle } from './Draghandle';

export interface DraggableListItemInnerComponentProps<T>
  extends DraggableStateSnapshot {
  onChildDropped?: (sortedItems: T[]) => void;
  item: T;
  Draghandle: JSX.Element;
}

interface Props<T> {
  item: T;
  index: number;
  ItemInnerComponent: React.ComponentType<
    DraggableListItemInnerComponentProps<T>
  >;
  isLast: boolean;
  hasDragHandle: boolean;
  withDividers: boolean;
}

interface ListItemWithDragHandleProps<T> extends Props<T> {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  classes?: WithClasses<() => { classes: { root: CSSObject } }>;
}

const useStyles = makeStyles<{
  isDragging: boolean;
  isLast: boolean;
  withDividers: boolean;
  isGrouped: boolean;
}>()((theme, { isDragging, isLast, withDividers, isGrouped }) => {
  const maybeDashedBorder = (() => {
    if (isGrouped) {
      return {
        backgroundImage: `linear-gradient(to right, ${COLORS.GRAY[300]} 50%, rgba(255,255,255,0) 0%)`,
        backgroundPosition: 'bottom',
        backgroundSize: `12px 1px`,
        backgroundRepeat: 'repeat-x',
      };
    }

    return {};
  })();

  return {
    root: {
      backgroundColor: isDragging ? COLORS.GRAY[50] : 'unset',
      opacity: isDragging ? 1 : 'unset',
      borderRadius: isDragging ? theme.spacing(1) : 'unset',
      transition: 'border-color 0.1s ease-in',
      ...maybeDashedBorder,
      borderBottom:
        !withDividers || isDragging || isLast || isGrouped
          ? `1px solid transparent`
          : `1px solid ${COLORS.GRAY[200]}`,
    },
  };
});

function ListItemWithDragHandle<T extends { id: string; isGrouped?: boolean }>(
  props: ListItemWithDragHandleProps<T>
) {
  const { ItemInnerComponent, item, provided, snapshot, hasDragHandle } = props;

  const isGrouped = item.isGrouped ?? false;

  const MemoizedDraghandle = useMemo(() => {
    return <Draghandle {...provided.dragHandleProps} />;
  }, [provided.dragHandleProps]);

  const isDragging = snapshot.isDragging;
  const { isLast, withDividers } = props;

  const { classes } = useStyles(
    {
      isDragging,
      isLast,
      withDividers,
      isGrouped,
    },
    {
      props: { classes: props.classes },
    }
  );

  // This makes the element draggable. If we don't want a drag handle, this gets
  // added to the list item
  const listItemDragProps = useMemo(
    () => (hasDragHandle ? {} : provided.dragHandleProps),
    [hasDragHandle, provided.dragHandleProps]
  );

  return (
    <li
      ref={provided.innerRef}
      {...provided.draggableProps}
      className={classes.root}
      {...listItemDragProps}
    >
      <ItemInnerComponent
        key={item.id}
        item={item}
        Draghandle={MemoizedDraghandle}
        {...snapshot}
      />
    </li>
  );
}

// https://www.figma.com/file/FlleF4k1X9St9vv3EgiSGY/Design-System?node-id=3232%3A28593&mode=dev
export function DraggableListItem<
  T extends { id: string; isGrouped?: boolean },
>(props: Props<T>) {
  return (
    <Draggable draggableId={props.item.id} index={props.index}>
      {(provided, snapshot) => {
        return (
          <ListItemWithDragHandle<T>
            provided={provided}
            snapshot={snapshot}
            {...props}
          />
        );
      }}
    </Draggable>
  );
}
