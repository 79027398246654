import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { TableEmptyState } from '@/components/tables/DataTable/TableEmptyState';
import {
  DisplayTable,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { CurrencyUSD } from '@/graphql/scalars';
import { SummaryBeneficiaryv2Fragment } from '@/modules/beneficiaries/graphql/SummaryBeneficiary.generated';
import { formatCurrency } from '@/utils/formatting/currency';

export interface ExtendedSummaryBeneficiary
  extends SummaryBeneficiaryv2Fragment {
  remainderValue: CurrencyUSD | null;
}

export interface BeneficiaryDistributionSummaryProps {
  summaryBeneficiaries: ExtendedSummaryBeneficiary[];
}

export function BeneficiaryDistributionSummary({
  summaryBeneficiaries,
}: BeneficiaryDistributionSummaryProps) {
  return (
    <DisplayTable
      columns={[
        { headerName: 'Beneficiary', width: '60%' },
        { headerName: 'Distribution %', align: 'right' },
        { headerName: 'Remainder value', align: 'right' },
      ]}
    >
      {summaryBeneficiaries.map((summaryBeneficiary, i) => (
        <BeneficiaryDistributionSummaryRow
          key={i}
          summaryBeneficiary={summaryBeneficiary}
        />
      ))}
      {summaryBeneficiaries.length === 0 && (
        <StyledTableRow>
          <StyledTableCell colSpan={3} sx={{ p: 0 }}>
            <TableEmptyState text="No beneficiaries defined" />
          </StyledTableCell>
        </StyledTableRow>
      )}
    </DisplayTable>
  );
}

interface BeneficiaryDistributionSummaryRowProps {
  summaryBeneficiary: ExtendedSummaryBeneficiary;
}

function BeneficiaryDistributionSummaryRow({
  summaryBeneficiary,
}: BeneficiaryDistributionSummaryRowProps) {
  return (
    <StyledTableRow>
      <StyledTableCell>
        <RichListItem
          heading={summaryBeneficiary.displayName}
          description={summaryBeneficiary.displayType}
          badgeText={summaryBeneficiary.badgeText || undefined}
          additionalItems={
            summaryBeneficiary.additionalContext.filter(
              (line) => !!line
            ) as string[]
          }
        />
      </StyledTableCell>
      <StyledTableCell align="right">
        {summaryBeneficiary.ownershipPercentage}
      </StyledTableCell>
      <StyledTableCell align="right">
        {summaryBeneficiary.remainderValue
          ? formatCurrency(summaryBeneficiary.remainderValue)
          : EMPTY_CONTENT_HYPHEN}
      </StyledTableCell>
    </StyledTableRow>
  );
}
