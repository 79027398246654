import { Stack } from '@mui/material';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { DataTable_LEGACYCard } from '@/components/tables/DataTable_LEGACY/DataTable_LEGACYCard';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import {
  TasksTable,
  TaskTableColumns,
} from '@/modules/tasks/components/TasksTable/TasksTable';
import { EntityTaskWhereInput } from '@/types/schema';

export function EntityTasksPage() {
  const entityId = useRequiredParam('entityId');
  const tasksFilter: EntityTaskWhereInput = {
    hasEntityWith: [
      {
        id: entityId,
      },
    ],
  };

  return (
    <Stack height="100%" p={3} pb={4}>
      <SubpageLayout heading="Tasks">
        <DataTable_LEGACYCard>
          <TasksTable
            filter={tasksFilter}
            columns={[
              TaskTableColumns.DUE_AT,
              TaskTableColumns.TASK_NAME,
              TaskTableColumns.ASSIGNEE,
            ]}
          />
        </DataTable_LEGACYCard>
      </SubpageLayout>
    </Stack>
  );
}
