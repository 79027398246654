import { Stack, Typography } from '@mui/material';
import { every } from 'lodash';

import { PopperContent } from '@/components/poppers/PopperContent';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

export function StateRecipientsContextualHelp() {
  const { isTwoClientHousehold, primaryClients } = useHouseholdDetailsContext();

  if (!isTwoClientHousehold) return null;
  if (!primaryClients?.[0] || !primaryClients?.[1]) return null;
  if (!every(primaryClients, (c) => c.stateCode)) return null;

  const lineOneDisplay = `${primaryClients[0].displayName || 'Grantor 1'}: ${primaryClients[0].stateCode}`;
  const lineTwoDisplay = `${primaryClients[1].displayName || 'Grantor 2'}: ${primaryClients[1].stateCode}`;
  return (
    <ContextualHelpTooltip>
      <PopperContent
        body={
          <Stack direction="column">
            <Typography variant="subtitle2">{lineOneDisplay}</Typography>
            <Typography variant="subtitle2">{lineTwoDisplay}</Typography>
          </Stack>
        }
      />
    </ContextualHelpTooltip>
  );
}
