import { ReactFlowState, useNodesInitialized, useStore } from '@xyflow/react';
import { isNumber } from 'lodash';
import { CSSProperties, useEffect, useRef } from 'react';

import { COLORS } from '@/styles/tokens/colors';

import { REACT_FLOW_CLASSES, REACT_FLOW_ZINDEX } from '../../constants';

const canvasStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: REACT_FLOW_ZINDEX.HELPER_LINES_CANVAS,
  pointerEvents: 'none',
  left: 0,
  top: 0,
};

const storeSelector = (state: ReactFlowState) => ({
  width: state.width,
  height: state.height,
  transform: state.transform,
});

export interface HelperLinesProps {
  horizontal?: number;
  vertical?: number;
  color?: string;
}

/**
 * @description Displays helper / snap to grid lines by putting a canvas
 * on top of the react flow pane and drawing lines with the canvas API
 *
 * A lot of this is adapted from: https://reactflow.dev/docs/examples/interaction/helper-lines/
 */
export function HelperLines({
  horizontal,
  vertical,
  color = COLORS.ORANGE[400],
}: HelperLinesProps) {
  const isInitialized = useNodesInitialized();
  const { width, height, transform } = useStore(storeSelector);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    if (!ctx || !canvas || !isInitialized) return;

    const dpi = window.devicePixelRatio;
    canvas.width = width * dpi;
    canvas.height = height * dpi;

    ctx.scale(dpi, dpi);
    ctx.clearRect(0, 0, width, height);
    ctx.strokeStyle = color;
    ctx.lineWidth = 2;

    if (isNumber(vertical)) {
      ctx.moveTo(vertical * transform[2] + transform[0], 0);
      ctx.lineTo(vertical * transform[2] + transform[0], height);
      ctx.stroke();
    }

    if (isNumber(horizontal)) {
      ctx.moveTo(0, horizontal * transform[2] + transform[1]);
      ctx.lineTo(width, horizontal * transform[2] + transform[1]);
      ctx.stroke();
    }
  }, [width, height, transform, horizontal, vertical, color, isInitialized]);

  return (
    <canvas
      ref={canvasRef}
      className={REACT_FLOW_CLASSES.CANVAS}
      style={canvasStyle}
    />
  );
}
