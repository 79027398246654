import { PropsWithChildren } from 'react';

import { WaterfallRouteContext } from './waterfallRoute.context';

// Provides children with access to a waterfallId without having
// to access the waterfallId from the URL.
export const WaterfallRouteProvider = ({
  waterfallId,
  children,
}: PropsWithChildren<{ waterfallId: string }>) => {
  return (
    <WaterfallRouteContext.Provider value={{ waterfallId }}>
      {children}
    </WaterfallRouteContext.Provider>
  );
};
