import { Box, Stack } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Slot } from '@/components/utils/slots';
import { GlobalSidebar } from '@/modules/globalSidebar/GlobalSidebar';
import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

import { ClientDetailsSidebar } from '../../Sidebar/ClientDetailsSidebar';
import { AppBar, appbarHeight } from '../AppBar/AppBar';

function useSlots(
  slots: NavBarLayoutProps['slots']
): Required<NonNullable<NavBarLayoutProps['slots']>> {
  return {
    AppBar: {
      component: AppBar,
      props: {},
    },
    LeftSidebar: {
      component: () => null,
      props: {},
    },
    RightSidebar: {
      component: () => null,
      props: {},
    },
    ...slots,
  };
}

const useStyles = makeStyles<{
  sidebarLayout: NavBarLayoutProps['sidebarLayout'];
  hideAppBar: NavBarLayoutProps['hideAppBar'];
  hideSidebar: NavBarLayoutProps['hideSidebar'];
}>()((_theme, { sidebarLayout, hideAppBar, hideSidebar }) => ({
  layoutRoot: {
    height:
      sidebarLayout === 'primary' ? `calc(100vh - ${appbarHeight})` : '100vh',
    backgroundColor: COLORS.GRAY[50],
    marginTop:
      sidebarLayout === 'primary' && !hideAppBar ? appbarHeight : undefined,
  },
  sidebar: {
    flexShrink: 0,
    height: '100%',
    display: hideSidebar ? 'none' : undefined,
  },
  page: {
    height: 'auto',
    flexGrow: '1',
    overflowY: 'auto',
  },
}));

export type NavBarLayoutProps = PropsWithChildren<{
  id?: string;
  classes?: WithClasses<typeof useStyles>;
  sidebarLayout?: 'primary' | 'secondary';
  slots?: {
    AppBar?: Slot<typeof AppBar>;
    LeftSidebar?: Slot<
      typeof GlobalSidebar | typeof ClientDetailsSidebar | (() => null)
    >;
    RightSidebar?: Slot<typeof GlobalSidebar | (() => null)>;
  };
  hideAppBar?: boolean;
  hideSidebar?: boolean;
}>;

export function AppBarLayout({
  children,
  classes: externalClasses,
  sidebarLayout = 'primary',
  slots: externalSlots,
  hideAppBar = false,
  hideSidebar = false,
  id,
}: NavBarLayoutProps) {
  const { classes } = useStyles(
    {
      sidebarLayout,
      hideAppBar,
      hideSidebar,
    },
    {
      props: { classes: externalClasses },
    }
  );
  const slots = useSlots(externalSlots);

  const Layout = useMemo(() => {
    if (sidebarLayout === 'secondary') {
      return (
        <Stack
          data-it="Layout-root"
          id={id}
          direction="row"
          className={classes.layoutRoot}
        >
          <Box component="aside" className={classes.sidebar}>
            <slots.LeftSidebar.component {...slots.LeftSidebar.props} />
          </Box>
          <Box component="main" position="relative" className={classes.page}>
            {!hideAppBar && (
              <slots.AppBar.component
                muiAppBarProps={{
                  position: 'sticky',
                  ...(slots.AppBar.props?.muiAppBarProps ?? {}),
                }}
                {...slots.AppBar.props}
              />
            )}
            {children}
          </Box>
          <Box component="aside" className={classes.sidebar}>
            <slots.RightSidebar.component {...slots.RightSidebar.props} />
          </Box>
        </Stack>
      );
    }

    return (
      <>
        {!hideAppBar && <slots.AppBar.component {...slots.AppBar.props} />}
        <Stack
          data-it="Layout-root"
          id={id}
          direction="row"
          className={classes.layoutRoot}
        >
          <Box component="aside" className={classes.sidebar}>
            <slots.LeftSidebar.component {...slots.LeftSidebar.props} />
          </Box>
          <Box component="main" className={classes.page}>
            {children}
          </Box>
          <Box component="aside" className={classes.sidebar}>
            <slots.RightSidebar.component {...slots.RightSidebar.props} />
          </Box>
        </Stack>
      </>
    );
  }, [
    children,
    classes.layoutRoot,
    classes.page,
    classes.sidebar,
    hideAppBar,
    id,
    sidebarLayout,
    slots,
  ]);

  return <>{Layout}</>;
}
