import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { SubtotalCard } from '@/components/display/SubtotalCard/SubtotalCard';
import { CoinsHandIcon } from '@/components/icons/CoinsHandIcon';
import { PopperContent } from '@/components/poppers/PopperContent';
import {
  MiniTable,
  MiniTableRow,
} from '@/components/tables/MiniTable/MiniTable';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { IMPLIED_TAX_HELP_COPY } from '@/modules/content/tooltipContent/ImpliedEstateTaxSavingsDashboard';
import { useFederalEstateTaxFormatted } from '@/modules/irs/useIRSConstants';
import { COLORS } from '@/styles/tokens/colors';
import {
  formatCurrency,
  formatCurrencyNoDecimals,
} from '@/utils/formatting/currency';

import { EntityValuationHistoryPage_EntityFragment } from './graphql/GetEntityValuationHistory.generated';

export interface ImpliedEstateTaxSavingsProps {
  entity: EntityValuationHistoryPage_EntityFragment | undefined | null;
}

export const ImpliedEstateTaxSavings: FC<ImpliedEstateTaxSavingsProps> = ({
  entity,
}) => {
  const rate = useFederalEstateTaxFormatted();
  if (
    entity?.subtype?.__typename !== 'IrrevocableTrust' &&
    entity?.subtype?.__typename !== 'SLATTrust'
  ) {
    return null;
  }
  const { performanceReport } = entity?.subtype || {};
  const { impliedEstateTaxSavings, outOfEstateGrowth } = performanceReport;

  const rows: MiniTableRow[] = [
    {
      label: 'Growth out of estate',
      value: formatCurrencyNoDecimals(outOfEstateGrowth),
    },

    { label: 'Tax rate', value: `x${rate}%` },
    {
      label: 'Tax savings',
      value: formatCurrencyNoDecimals(impliedEstateTaxSavings),
    },
  ];
  return (
    <SubtotalCard
      wrapperSx={{
        flex: '1 1 50%',
      }}
      caption={
        <OversizedMetricItem
          title={
            <Stack direction="row" flexWrap="nowrap">
              Implied estate tax savings
              <ContextualHelpTooltip>
                <Typography>
                  <PopperContent body={IMPLIED_TAX_HELP_COPY} />
                </Typography>
              </ContextualHelpTooltip>
            </Stack>
          }
          value={formatCurrency(impliedEstateTaxSavings, {
            notation: 'compact',
            currencySign: 'accounting',
          })}
        />
      }
      icon={<CoinsHandIcon />}
      iconColor={COLORS.CATEGORIES.ESTATE_TAX_SAVINGS[500]}
    >
      <Box sx={{ flex: '1 1 50%' }}>
        <MiniTable rows={rows} variant="tally" />
      </Box>
    </SubtotalCard>
  );
};
