import Decimal from 'decimal.js';
import { compact, filter, first, last, min } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { EstateWaterfallHypotheticalTransferTransferTaxKind } from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';
import { getNodes } from '@/utils/graphqlUtils';

import {
  HypotheticalTransferModalExemption_EstateWaterfallFragment,
  HypotheticalTransferModalExemption_EstateWaterfallHypotheticalTransferFragment,
  HypotheticalTransferModalExemptionQueryVariables,
  useHypotheticalTransferModalExemptionQuery,
} from '../graphql/HypotheticalTransferModalExemption.generated';
import { useHypotheticalTransferModalContext } from '../HypotheticalTransferModal.context';
import {
  AnnuallyRecurringValue,
  HypotheticalTransferFormModalShape,
} from '../HypotheticalTransferModal.types';
import { deserializeTransferType } from '../HypotheticalTransferModal.utils';
import { HelpTextType } from './useHypotheticalTransferAmountHelpText';

/** Gets the net total of hypothetical transfers.  Per product, does not account for
 *  recurring transfers with a growth factor applied.
 */
export function private_getWaterfallNetHypotheticalTransferAmount(
  transfers: HypotheticalTransferModalExemption_EstateWaterfallHypotheticalTransferFragment[],
  transferId: string
): Decimal {
  let netTransferAmount = new Decimal(0);

  let hypotheticalTransfers = compact(transfers);
  if (transferId) {
    hypotheticalTransfers = compact(
      filter(transfers, ({ id }) => id !== transferId)
    );
  }

  hypotheticalTransfers
    .filter(
      // only taxable transfers use up exemptions
      ({ transferTaxKind }) =>
        transferTaxKind ===
        EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift
    )
    .forEach((transfer) => {
      let currentTransferAmount = transfer?.transferValue || new Decimal(0);

      if (transfer.startYear && transfer.endYear) {
        const years = transfer.endYear - transfer.startYear + 1;
        currentTransferAmount = currentTransferAmount.times(years);
      }

      netTransferAmount = netTransferAmount.plus(currentTransferAmount);
    });

  return netTransferAmount;
}

export function private_getTotalHypotheticalTransferAmount({
  transferAmount,
  transferStartYear,
  transferEndYear,
  transferFrequency,
}: {
  transferAmount: Decimal | null;
  transferStartYear: number;
  transferEndYear?: number | undefined | null;
  transferFrequency: AnnuallyRecurringValue;
}): Decimal {
  if (!transferAmount) {
    return new Decimal(0);
  }

  if (transferFrequency === AnnuallyRecurringValue.false) {
    return transferAmount;
  }

  let netTransferAmount = transferAmount;

  if (transferStartYear && transferEndYear) {
    const years = transferEndYear - transferStartYear + 1;
    netTransferAmount = netTransferAmount.times(years);
  }

  return netTransferAmount;
}

interface GrantorExemptionDetails {
  /** Grantor's remaining exemption amount, including the amounts of other transfers. Does not include the amount of the current transfer. */
  remainingExemptionBeforeTransfer: Decimal;
  /** The amount of the exemption used by the current transfer.  Maxes out at `remainingExemption` */
  exemptionUsedByTransfer: Decimal;
  /** The non-exempt portion of the current transfer. */
  taxedTransferBalance: Decimal;
  /** True if the transfer uses the entire exemption. */
  hasRemainingExemption: boolean;
}

export function private_getGrantorExemptionDetails({
  lifetimeExclusionUsed,
  lifetimeExemptionAmount,
  perGrantorNetTransferAmount,
  perGrantorTransferAmount,
}: {
  lifetimeExclusionUsed: Decimal;
  lifetimeExemptionAmount: Decimal;
  perGrantorNetTransferAmount: Decimal;
  perGrantorTransferAmount: Decimal;
}): GrantorExemptionDetails {
  let remainingExemptionBeforeTransfer = lifetimeExemptionAmount
    .minus(lifetimeExclusionUsed)
    .minus(perGrantorNetTransferAmount);

  if (remainingExemptionBeforeTransfer.lessThan(0)) {
    remainingExemptionBeforeTransfer = new Decimal(0);
  }

  const exemptionUsedByTransfer = new Decimal(
    min([
      remainingExemptionBeforeTransfer.toNumber(),
      perGrantorTransferAmount.toNumber(),
    ]) || 0
  );

  const hasRemainingExemption = remainingExemptionBeforeTransfer
    .minus(perGrantorTransferAmount)
    .greaterThan(0);

  let taxedTransferBalance: Decimal = new Decimal(0);
  if (perGrantorTransferAmount.greaterThan(remainingExemptionBeforeTransfer)) {
    taxedTransferBalance = perGrantorTransferAmount.minus(
      remainingExemptionBeforeTransfer
    );
  }

  return {
    remainingExemptionBeforeTransfer,
    exemptionUsedByTransfer,
    taxedTransferBalance,
    hasRemainingExemption,
  };
}

const COMPACT: Intl.NumberFormatOptions = { notation: 'compact' };

interface GrantorDisplayData extends GrantorExemptionDetails {
  firstName: string;
}

// TODO: is there a way to export these in a way they can be mocked w/o being public?  that way the test can be around the biz logic, not the strings themselves
const getBothOver = (
  firstTaxedTransferBalance: Decimal,
  secondTaxedTransferBalance: Decimal = new Decimal(0)
): string =>
  `Gift of ${formatCurrency(firstTaxedTransferBalance.plus(secondTaxedTransferBalance), COMPACT)} is subject to federal estate taxes.`;

const getBothUnderEqualExemptions = (
  firstFirstName: string,
  secondFirstName: string,
  firstExemptionUsedByTransfer: Decimal,
  firstRemainingExemption: Decimal
): string =>
  `Gift uses ${formatCurrency(firstExemptionUsedByTransfer, COMPACT)} of each ${firstFirstName} and ${secondFirstName}'s remaining ${formatCurrency(firstRemainingExemption, COMPACT)} federal exemptions.`;

const getBothUnderDifferentExemptions = (
  firstFirstName: string,
  secondFirstName: string,
  firstExemptionUsedByTransfer: Decimal,
  secondExemptionUsedByTransfer: Decimal,
  firstRemainingExemption: Decimal,
  secondRemainingExemption: Decimal
): string =>
  `Gift uses ${formatCurrency(firstExemptionUsedByTransfer, COMPACT)} of ${firstFirstName}'s remaining ${formatCurrency(firstRemainingExemption, COMPACT)} federal exemption and ${formatCurrency(secondExemptionUsedByTransfer, COMPACT)} of ${secondFirstName}'s remaining ${formatCurrency(secondRemainingExemption, COMPACT)} federal exemption.`;

const getOneUnderOneOver = (
  underFirstName: string,
  underExemptionUsedByTransfer: Decimal,
  underRemainingExemption: Decimal,
  overFirstName: string,
  overTaxedTransferBalance: Decimal
) =>
  `Gift uses ${formatCurrency(underExemptionUsedByTransfer, COMPACT)} of ${underFirstName}'s remaining ${formatCurrency(underRemainingExemption, COMPACT)} federal exemption and all of ${overFirstName}'s federal exemption.  The remaining ${formatCurrency(overTaxedTransferBalance, COMPACT)} is subject to federal estate taxes.`;

export function private_displayString(
  grantorsData: GrantorDisplayData[]
): string {
  if (grantorsData.length === 2) {
    const firstGrantor = first(grantorsData);
    const secondGrantor = last(grantorsData);

    if (!firstGrantor || !secondGrantor) return '';

    const {
      remainingExemptionBeforeTransfer: firstRemainingExemption,
      taxedTransferBalance: firstTaxedTransferBalance,
      firstName: firstFirstName,
      exemptionUsedByTransfer: firstExemptionUsedByTransfer,
    } = firstGrantor;

    const {
      remainingExemptionBeforeTransfer: secondRemainingExemption,
      taxedTransferBalance: secondTaxedTransferBalance,
      firstName: secondFirstName,
      exemptionUsedByTransfer: secondExemptionUsedByTransfer,
    } = secondGrantor;

    // Transfer over both grantors' remaining exemptions
    if (
      firstTaxedTransferBalance.greaterThan(0) &&
      secondTaxedTransferBalance.greaterThan(0)
    ) {
      return getBothOver(firstTaxedTransferBalance, secondTaxedTransferBalance);
    }

    // Transfer under both grantors' equal remaining exemptions
    if (
      firstTaxedTransferBalance.isZero() &&
      secondTaxedTransferBalance.isZero()
    ) {
      if (firstRemainingExemption.equals(secondRemainingExemption)) {
        return getBothUnderEqualExemptions(
          firstFirstName,
          secondFirstName,
          firstExemptionUsedByTransfer,
          firstRemainingExemption
        );
      }

      // Transfer under both grantors' differeing remaining exemptions
      return getBothUnderDifferentExemptions(
        firstFirstName,
        secondFirstName,
        firstExemptionUsedByTransfer,
        secondExemptionUsedByTransfer,
        firstRemainingExemption,
        secondRemainingExemption
      );
    }

    if (firstTaxedTransferBalance.greaterThan(0)) {
      return getOneUnderOneOver(
        secondFirstName,
        secondExemptionUsedByTransfer,
        secondRemainingExemption,
        firstFirstName,
        firstTaxedTransferBalance
      );
    } else if (secondTaxedTransferBalance.greaterThan(0)) {
      return getOneUnderOneOver(
        firstFirstName,
        firstExemptionUsedByTransfer,
        firstRemainingExemption,
        secondFirstName,
        secondTaxedTransferBalance
      );
    }

    return '';
  } else if (grantorsData.length === 1) {
    const grantorData = first(grantorsData);
    if (!grantorData) return '';
    const {
      remainingExemptionBeforeTransfer: remainingExemption,
      taxedTransferBalance,
      firstName,
      exemptionUsedByTransfer,
      hasRemainingExemption,
    } = grantorData;

    // Transfer over single grantor's remaining exemption
    if (remainingExemption.isZero() && taxedTransferBalance.isPositive()) {
      return `Gift of ${formatCurrency(taxedTransferBalance, COMPACT)} is subject to federal estate taxes.`;
    }

    if (!hasRemainingExemption) {
      return `Gift uses all of ${firstName}'s remaining exemption.  The remaining ${formatCurrency(taxedTransferBalance, COMPACT)} is subject to federal estate taxes.`;
    }

    // Transfer under single grantor's remaining exemption
    return `Gift uses ${formatCurrency(exemptionUsedByTransfer, COMPACT)} of ${firstName}'s remaining ${formatCurrency(remainingExemption, COMPACT)} federal exemption.`;
  }
  return '';
}

export function getGrantorExemptionHelpText(
  estateWaterfall: HypotheticalTransferModalExemption_EstateWaterfallFragment,
  transferId: string,
  lifetimeExemptionAmount: Decimal,
  transferAmount: HypotheticalTransferFormModalShape['transferAmount'],
  transferType: HypotheticalTransferFormModalShape['transferType']
): string {
  const { household, hypotheticalTransfers } = estateWaterfall;
  const netTransferAmount = private_getWaterfallNetHypotheticalTransferAmount(
    compact(getNodes(hypotheticalTransfers)),
    transferId
  );

  const { transferTaxKind, giftingGrantorIDs } =
    deserializeTransferType(transferType);

  const possiblePrimaryClients = household?.possiblePrimaryClients || [];

  if (
    !estateWaterfall ||
    !transferAmount ||
    transferAmount?.lessThanOrEqualTo(0) ||
    transferTaxKind !==
      EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift ||
    giftingGrantorIDs.length > possiblePrimaryClients.length
  ) {
    return '';
  }

  const grantors = compact(
    giftingGrantorIDs.map((grantorId) =>
      possiblePrimaryClients.find(({ id }) => id === grantorId)
    )
  );

  const grantorExemptionDetails = grantors.map<GrantorDisplayData>(
    (grantor) => {
      return {
        ...private_getGrantorExemptionDetails({
          lifetimeExemptionAmount,
          perGrantorNetTransferAmount: netTransferAmount.div(grantors.length),
          perGrantorTransferAmount: transferAmount.div(grantors.length),
          lifetimeExclusionUsed:
            grantor.lifetimeExclusionUsed || new Decimal(0),
        }),
        firstName: grantor.firstName,
      };
    }
  );

  const output = private_displayString(grantorExemptionDetails);
  return output;
}

export function useHypotheticalTransferTypeHelpText(): HelpTextType {
  const { waterfallId, transferId = '' } =
    useHypotheticalTransferModalContext();
  const { control } = useFormContext<HypotheticalTransferFormModalShape>();

  const [
    transferAmount,
    transferType,
    transferStartYear,
    transferEndYear,
    transferFrequency,
  ] = useWatch({
    control,
    name: [
      'transferAmount',
      'transferType',
      'transferStartYear',
      'transferEndYear',
      'transferFrequency',
    ],
  });

  const variables = useMemo<HypotheticalTransferModalExemptionQueryVariables>(
    () => ({
      estateWaterfallWhere: { id: waterfallId },
    }),
    [waterfallId]
  );

  const { data } = useHypotheticalTransferModalExemptionQuery({
    variables,
    skip: !waterfallId,
  });

  const { lifetimeExemptionAmount } = useIRSConstants();

  const estateWaterfall = first(getNodes(data?.estateWaterfalls));

  const totalTransferAmount = useMemo(
    () =>
      private_getTotalHypotheticalTransferAmount({
        transferAmount,
        transferStartYear,
        transferEndYear,
        transferFrequency,
      }),
    [transferAmount, transferEndYear, transferFrequency, transferStartYear]
  );

  if (!estateWaterfall || !lifetimeExemptionAmount) {
    return {};
  }

  return {
    helpText: getGrantorExemptionHelpText(
      estateWaterfall,
      transferId,
      lifetimeExemptionAmount,
      totalTransferAmount,
      transferType
    ),
  };
}
