import { compact } from 'lodash';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import {
  DispositiveProvisionDispositionKind,
  DispositiveProvisionTransferTaxKind,
  EntityKind,
} from '@/types/schema';

import { Recipient } from './DispositiveProvisionsForm.types';
import { DispositiveProvisionsRecipientTemplateRecipientConfiguration } from './DispositiveProvisionsFormRecipients/DispositiveProvisionsRecipient.types';

export const DISPOSITIVE_PROVISIONS_FORM_NAMESPACE = 'dispositiveProvisions';

// CLIENT_OWNERSHIP_DESCRIPTION is the copy we use to describe what the client is transferring to the recipient
// when setting dispositive provisions for a primary client
export const CLIENT_OWNERSHIP_DESCRIPTION =
  'Any received assets and/or direct business ownership';

export const defaultRecipient = {
  recipientId: '',
  recipientKind: null,
  dispositionKind_ButtonGroup: DispositiveProvisionDispositionKind.Percentage,
  dispositionKind_Select: '',
  dispositionAmount: null,
  dispositionPercentage: null,
  notes: '',
  transferTaxKind: '',
  _dispositiveProvisionId: null,
} as const satisfies Recipient;

export const dispositionKindOptions: {
  display: string;
  value: DispositiveProvisionDispositionKind | 'other';
}[] = [
  {
    display: '%',
    value: DispositiveProvisionDispositionKind.Percentage,
  },
  {
    display: '$',
    value: DispositiveProvisionDispositionKind.Amount,
  },
  {
    display: 'ƒ(x)',
    value: 'other',
  },
];

export const otherDispositionKindOptions: (
  | {
      display: string;
      value: DispositiveProvisionDispositionKind;
    }
  | {
      display: (...args: string[]) => string;
      value: DispositiveProvisionDispositionKind;
    }
)[] = [
  {
    display: (stateCode: string) => `Remaining ${stateCode} exemption`,
    value: DispositiveProvisionDispositionKind.RemainingStateExemption,
  },
  {
    display: (suffix: string) =>
      'Remaining federal lifetime exemption' + suffix,
    value:
      DispositiveProvisionDispositionKind.RemainingLifetimeExclusionOfGrantor,
  },
  {
    display: (stateCode: string, suffix: string) =>
      `Remaining federal exemption, in excess of ${stateCode} exemption` +
      suffix,
    value:
      DispositiveProvisionDispositionKind.RemainingFederalLifetimeExemptionInExcessOfStateExemption,
  },
  {
    display: (suffix: string) =>
      'Remaining GST exemption in excess of federal lifetime exemption' +
      suffix,
    value:
      DispositiveProvisionDispositionKind.RemainingGstExclusionOfGrantorInExcessOfLifetimeExclusion,
  },
  {
    display: 'Anything left over',
    value: DispositiveProvisionDispositionKind.AnythingLeftOver,
  },
];

export const TRANSFER_TAX_KIND_DISPLAY_NAME: Record<
  DispositiveProvisionTransferTaxKind,
  string
> = {
  [DispositiveProvisionTransferTaxKind.SpouseCreditShelter]:
    'Spouse (credit shelter)',
  [DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion]:
    'Spouse (marital exclusion)',
  [DispositiveProvisionTransferTaxKind.Generation_2OrOtherIndividual]:
    'Generation 2 or other individual',
  [DispositiveProvisionTransferTaxKind.Generation_2Then_3]: 'Generation 2/3',
  [DispositiveProvisionTransferTaxKind.Generation_3]: 'Generation 3',
  [DispositiveProvisionTransferTaxKind.Charitable]: 'Charitable',
  [DispositiveProvisionTransferTaxKind.SpouseFederalCreditShelterStateMaritalExclusion]:
    'Spouse (credit shelter, marital exclusion - state)',
  [DispositiveProvisionTransferTaxKind.Pourover]: 'Pour-over will',
};

export const getTransferTaxKindOptions = (options: {
  hasStateTax?: boolean;
  recipientConfiguration?: DispositiveProvisionsRecipientTemplateRecipientConfiguration;
}): SelectInputOption[] => {
  const { hasStateTax = false, recipientConfiguration } = options;
  return compact([
    DispositiveProvisionTransferTaxKind.SpouseCreditShelter,
    DispositiveProvisionTransferTaxKind.SpouseFederalCreditShelterStateMaritalExclusion,
    DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion,
    DispositiveProvisionTransferTaxKind.Generation_2OrOtherIndividual,
    DispositiveProvisionTransferTaxKind.Generation_2Then_3,
    DispositiveProvisionTransferTaxKind.Generation_3,
    DispositiveProvisionTransferTaxKind.Charitable,
    recipientConfiguration?.customTransferTaxKind,
  ]).flatMap((kind) => {
    if (
      !hasStateTax &&
      kind ===
        DispositiveProvisionTransferTaxKind.SpouseFederalCreditShelterStateMaritalExclusion &&
      !recipientConfiguration?.includeSpouseFederalCreditShelterStateMaritalExclusion
    ) {
      return [];
    }

    if (
      hasStateTax &&
      kind === DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion
    ) {
      return {
        display: 'Spouse (marital exclusion - federal and state)',
        value: kind,
      };
    }

    return {
      display: TRANSFER_TAX_KIND_DISPLAY_NAME[kind],
      value: kind,
    };
  });
};

export const NO_REVIEW_REQUIRED_ENTITY_KINDS = [
  EntityKind.DonorAdvisedFund,
  EntityKind.PrivateFoundation,
];
