import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { $generateNodesFromDOM } from '@lexical/html';
import { $generateHtmlFromNodes } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { TRANSFORMERS } from '@lexical/markdown';
import {
  $convertFromMarkdownString,
  $convertToMarkdownString,
} from '@lexical/markdown';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { Box } from '@mui/material';
import { $getRoot, EditorState, LexicalEditor } from 'lexical';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { UnreachableError } from '@/utils/errors';

import { useTextEditorContext } from './contexts/textEditor.context';
import { useTextEditorTheme } from './hooks/useTextEditorTheme';
import { CopyAllPlugin } from './plugins/CopyAllPlugin/CopyAllPlugin';
import { ToolbarPlugin } from './plugins/ToolbarPlugin/ToolbarPlugin';
import {
  EditorInner,
  EMPTY_EDITOR_JSON,
  TextEditorContentEditable,
  TextEditorWrapper,
} from './TextEditor.utils';

export type TextEditorKind = 'markdown' | 'html' | 'json';
export interface TextEditorProps {
  initialValue: string;
  initialValueKind?: TextEditorKind;
  onUpdate?: (convertedEditorState: string) => void;
  readOnly?: boolean;
}

export const TextEditor = ({
  initialValue,
  initialValueKind = 'markdown',
  onUpdate,
  readOnly = false,
}: TextEditorProps) => {
  const theme = useTextEditorTheme();
  const { theme: themeVariant } = useTextEditorContext();
  const [editorStateStr, setEditorStateStr] = useState<string>(initialValue);

  // Update the parent component with the editor state
  useEffect(() => {
    onUpdate?.(editorStateStr);
  }, [editorStateStr, onUpdate]);

  const initialConfig = useMemo(() => {
    return {
      onError(error: unknown) {
        throw error;
      },
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
      ],
      namespace: readOnly
        ? 'luminary-text-editor-readonly'
        : 'luminary-text-editor',
      editable: !readOnly,
      theme,
    };
  }, [readOnly, theme]);

  const handleChange = useCallback(
    (editorState: EditorState, editor: LexicalEditor) => {
      switch (initialValueKind) {
        case 'markdown': {
          const markdown = editorState.read(() => {
            return $convertToMarkdownString(TRANSFORMERS);
          });
          setEditorStateStr(markdown);
          break;
        }
        case 'html': {
          const html = editorState.read(() => {
            return $generateHtmlFromNodes(editor);
          });
          setEditorStateStr(html);
          break;
        }
        case 'json': {
          setEditorStateStr(JSON.stringify(editor.getEditorState()));
          break;
        }
        default:
          throw new UnreachableError({
            case: initialValueKind,
            message: `Unrecognized format for text editor ${initialValueKind}`,
          });
      }
    },
    [initialValueKind]
  );

  return (
    <LexicalComposer
      initialConfig={{
        ...initialConfig,
        editorState(editor) {
          switch (initialValueKind) {
            case 'markdown':
              return $convertFromMarkdownString(initialValue, TRANSFORMERS);
            case 'html': {
              const parser = new DOMParser();
              const dom = parser.parseFromString(initialValue, 'text/html');
              const nodes = $generateNodesFromDOM(editor, dom);
              $getRoot().select().insertNodes(nodes);
              break;
            }
            case 'json':
              return editor.setEditorState(
                editor.parseEditorState(initialValue || EMPTY_EDITOR_JSON)
              );

            default:
              throw new UnreachableError({
                case: initialValueKind,
                message: `Unrecognized format for text editor ${initialValueKind}`,
              });
          }
        },
      }}
    >
      {readOnly ? (
        <EditorInner>
          <RichTextPlugin
            contentEditable={
              <Box>
                <TextEditorContentEditable themeVariant={themeVariant} />
              </Box>
            }
            placeholder={<></>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <ListPlugin />
          <LinkPlugin />
          <CopyAllPlugin />
        </EditorInner>
      ) : (
        <TextEditorWrapper>
          <ToolbarPlugin textEditorKind={initialValueKind} />
          <EditorInner>
            <RichTextPlugin
              contentEditable={
                <Box sx={{ height: '100%', overflowY: 'auto' }}>
                  <TextEditorContentEditable themeVariant={themeVariant} />
                </Box>
              }
              placeholder={<></>}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <AutoFocusPlugin />
            <ListPlugin />
            <LinkPlugin />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            <TabIndentationPlugin />
            <HistoryPlugin />
          </EditorInner>
          <OnChangePlugin onChange={handleChange} />
        </TextEditorWrapper>
      )}
    </LexicalComposer>
  );
};
