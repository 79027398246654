import { compact } from 'lodash';
import { useMemo } from 'react';

import { Divider } from '@/components/Divider';
import { PersonalAccountSummaryData } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountDetails.types';
import { PersonalAccountDetailsMiniTable } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountDetailsMiniTable';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { COLORS } from '@/styles/tokens/colors';

import { PresentationSlideSecondaryHeading } from '../../../components/PresentationSlideSecondaryHeading';
import { ControllingPersons } from '../../components/ControllingPersons';
import { EntityValuation } from '../../components/EntityValuation';
import { SummarySlideLayout } from '../../components/SummarySlideLayout';
import { PresentationSlideRegistrationDetails } from '../../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../../graphql/EntityPresentation.generated';
import { PersonalAccountBeneficiariesV2 } from './PersonalAccount.BeneficiariesV2';

export interface PersonalAccountSummarySlideProps
  extends PresentationSlideRegistrationDetails {
  entity: EntityPresentation_EntityFragment;
  summary: PersonalAccountSummaryData;
  displayName: string;
  grantors: {
    displayName: string;
    id: string;
  }[];
}

export function PersonalAccountSummarySlide({
  displayName,
  entity,
  summary,
  grantors: _,
  ...registrationProps
}: PersonalAccountSummarySlideProps) {
  useRegisterSlide({
    displayName: 'Summary',
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  const LeftContent = useMemo(() => {
    return compact([
      <EntityValuation key="entity-valuation" entity={entity} />,
      <PersonalAccountDetailsMiniTable
        key="personal-account-details-mini-table"
        {...summary}
      />,
      <Divider
        key="divider-0"
        sx={{ borderColor: COLORS.NEUTRAL_GRAY[200] }}
      />,
      <ControllingPersons
        key="controlling-persons"
        showControllingPersons={!summary.hideControllingPerson}
        controllingPartyNames={summary.controllingPartyNames ?? []}
      />,
    ]);
  }, [entity, summary]);

  return (
    <PresentationSlide.Slide
      id={registrationProps.slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading heading={displayName} />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={entity.household.displayName}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <SummarySlideLayout
        LeftContent={LeftContent}
        RightContent={<PersonalAccountBeneficiariesV2 summary={summary} />}
      />
    </PresentationSlide.Slide>
  );
}
