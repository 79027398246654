import { CalloutListCard } from '@/components/layout/CalloutListCard/CalloutListCard';
import { PrintOnlyAssetsCard } from '@/modules/entities/details/shared/ViewOnlyAssetsCard';
import { PersonalAccountSummaryData } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountDetails.types';
import { PersonalAccountSummary } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountSummary';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';

import { PresentationSlideSecondaryHeading } from '../../../components/PresentationSlideSecondaryHeading';
import { OverviewSlideLayout } from '../../components/OverviewSlideLayout';
import { PresentationSlideRegistrationDetails } from '../../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../../graphql/EntityPresentation.generated';

/**
 * @description The PersonalAccountOverviewSlide is a generic component that can be shared between personal account types that have
 * similar overview slides in their presentations.
 */

export interface PersonalAccountOverviewSlideProps
  extends PresentationSlideRegistrationDetails {
  entity: EntityPresentation_EntityFragment;
  summary: PersonalAccountSummaryData;
  displayName: string;
}

export function PersonalAccountOverviewSlide({
  displayName,
  entity,
  summary,
  ...registrationProps
}: PersonalAccountOverviewSlideProps) {
  useRegisterSlide({
    displayName: 'Overview',
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  const { description, ...summaryProps } = summary;

  return (
    <PresentationSlide.Slide
      id={registrationProps.slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading heading={displayName} />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={entity.household.displayName}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <OverviewSlideLayout
        Assets={<PrintOnlyAssetsCard entity={entity} />}
        Summary={<PersonalAccountSummary loading={false} {...summaryProps} />}
        Callout={
          <CalloutListCard
            sx={{ width: '100%' }}
            loading={false}
            variant="filled-light"
            items={summary.accountDetailsItems}
          />
        }
        description={description}
      />
    </PresentationSlide.Slide>
  );
}
