import { Stack, Typography } from '@mui/material';
import { map } from 'lodash';
import { useWatch } from 'react-hook-form';

import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form/hooks';

import {
  ClientProfessionalTeamForm,
  ClientProfessionalTeamMemberRoleKey,
  ClientProfessionalTeamRole,
} from '../../../../modules/professionalTeam/ClientProfessionalTeam.types';
import {
  ClientProfessionalTeamAddCustomRoleButton,
  ClientProfessionalTeamRoleCard,
} from './ClientProfessionalTeam.UI';

export function ClientProfessionalTeamAdvisorClient() {
  const { control } = useFormContext<ClientProfessionalTeamForm>();
  const advisorClientRoles = useWatch<
    ClientProfessionalTeamForm,
    'advisorClientRoles'
  >({
    control,
    name: 'advisorClientRoles',
  });

  return (
    <Card variant="outlined" sx={{ px: 3, paddingTop: 3, paddingBottom: 4 }}>
      <Stack direction="column" justifyContent="space-between">
        <Stack direction="column" pb={3}>
          <Typography variant="h1">Client advisors</Typography>
          <Stack spacing={2}>
            {map<ClientProfessionalTeamForm['advisorClientRoles']>(
              advisorClientRoles,
              (
                role: ClientProfessionalTeamRole,
                roleKey: ClientProfessionalTeamMemberRoleKey
              ) => (
                <ClientProfessionalTeamRoleCard
                  key={roleKey}
                  role={role}
                  roleKey={roleKey}
                  pathToRoleList="advisorClientRoles"
                />
              )
            )}
          </Stack>
        </Stack>
        <ClientProfessionalTeamAddCustomRoleButton path="advisorClientRoles" />
      </Stack>
    </Card>
  );
}
