// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResyncTenantValuationsMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type ResyncTenantValuationsMutation = { __typename?: 'Mutation', syncIntegratedTenantEntities: string };


export const ResyncTenantValuationsDocument = gql`
    mutation ResyncTenantValuations {
  syncIntegratedTenantEntities
}
    `;
export type ResyncTenantValuationsMutationFn = Apollo.MutationFunction<ResyncTenantValuationsMutation, ResyncTenantValuationsMutationVariables>;

/**
 * __useResyncTenantValuationsMutation__
 *
 * To run a mutation, you first call `useResyncTenantValuationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResyncTenantValuationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resyncTenantValuationsMutation, { data, loading, error }] = useResyncTenantValuationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useResyncTenantValuationsMutation(baseOptions?: Apollo.MutationHookOptions<ResyncTenantValuationsMutation, ResyncTenantValuationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResyncTenantValuationsMutation, ResyncTenantValuationsMutationVariables>(ResyncTenantValuationsDocument, options);
      }
export type ResyncTenantValuationsMutationHookResult = ReturnType<typeof useResyncTenantValuationsMutation>;
export type ResyncTenantValuationsMutationResult = Apollo.MutationResult<ResyncTenantValuationsMutation>;
export type ResyncTenantValuationsMutationOptions = Apollo.BaseMutationOptions<ResyncTenantValuationsMutation, ResyncTenantValuationsMutationVariables>;
export const Operations = {
  Mutation: {
    ResyncTenantValuations: 'ResyncTenantValuations' as const
  }
}