// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteFeatureFlagFragment = { __typename?: 'FeatureFlag', id: string, createdAt: Time, flagDescription?: string | null, flagName: string, flagStatus: Types.FeatureFlagFlagStatus };

export type AdminFeatureFlagsPageQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminFeatureFlagsPageQuery = { __typename?: 'Query', featureFlagEvaluationResult: Array<{ __typename?: 'FeatureFlagEvaluationResult', flagEvaluationResult: boolean, flagEnablementStatus: Types.FlagEnablementStatus, name: string, flag: { __typename?: 'FeatureFlag', id: string, createdAt: Time, flagDescription?: string | null, flagName: string, flagStatus: Types.FeatureFlagFlagStatus } }> };

export type SetFeatureFlagStatusMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  status: Types.FlagEnablementStatus;
}>;


export type SetFeatureFlagStatusMutation = { __typename?: 'Mutation', setFeatureFlagStatus: { __typename?: 'FeatureFlag', flagStatus: Types.FeatureFlagFlagStatus, flagName: string, id: string } };

export const CompleteFeatureFlagFragmentDoc = gql`
    fragment CompleteFeatureFlag on FeatureFlag {
  id
  createdAt
  flagDescription
  flagName
  flagStatus
}
    `;
export const AdminFeatureFlagsPageDocument = gql`
    query AdminFeatureFlagsPage {
  featureFlagEvaluationResult {
    flagEvaluationResult
    flagEnablementStatus
    name
    flag {
      ...CompleteFeatureFlag
    }
  }
}
    ${CompleteFeatureFlagFragmentDoc}`;

/**
 * __useAdminFeatureFlagsPageQuery__
 *
 * To run a query within a React component, call `useAdminFeatureFlagsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminFeatureFlagsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminFeatureFlagsPageQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminFeatureFlagsPageQuery(baseOptions?: Apollo.QueryHookOptions<AdminFeatureFlagsPageQuery, AdminFeatureFlagsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminFeatureFlagsPageQuery, AdminFeatureFlagsPageQueryVariables>(AdminFeatureFlagsPageDocument, options);
      }
export function useAdminFeatureFlagsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminFeatureFlagsPageQuery, AdminFeatureFlagsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminFeatureFlagsPageQuery, AdminFeatureFlagsPageQueryVariables>(AdminFeatureFlagsPageDocument, options);
        }
export function useAdminFeatureFlagsPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AdminFeatureFlagsPageQuery, AdminFeatureFlagsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminFeatureFlagsPageQuery, AdminFeatureFlagsPageQueryVariables>(AdminFeatureFlagsPageDocument, options);
        }
export type AdminFeatureFlagsPageQueryHookResult = ReturnType<typeof useAdminFeatureFlagsPageQuery>;
export type AdminFeatureFlagsPageLazyQueryHookResult = ReturnType<typeof useAdminFeatureFlagsPageLazyQuery>;
export type AdminFeatureFlagsPageSuspenseQueryHookResult = ReturnType<typeof useAdminFeatureFlagsPageSuspenseQuery>;
export type AdminFeatureFlagsPageQueryResult = Apollo.QueryResult<AdminFeatureFlagsPageQuery, AdminFeatureFlagsPageQueryVariables>;
export const SetFeatureFlagStatusDocument = gql`
    mutation SetFeatureFlagStatus($name: String!, $status: FlagEnablementStatus!) {
  setFeatureFlagStatus(name: $name, status: $status) {
    flagStatus
    flagName
    id
  }
}
    `;
export type SetFeatureFlagStatusMutationFn = Apollo.MutationFunction<SetFeatureFlagStatusMutation, SetFeatureFlagStatusMutationVariables>;

/**
 * __useSetFeatureFlagStatusMutation__
 *
 * To run a mutation, you first call `useSetFeatureFlagStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFeatureFlagStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFeatureFlagStatusMutation, { data, loading, error }] = useSetFeatureFlagStatusMutation({
 *   variables: {
 *      name: // value for 'name'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetFeatureFlagStatusMutation(baseOptions?: Apollo.MutationHookOptions<SetFeatureFlagStatusMutation, SetFeatureFlagStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetFeatureFlagStatusMutation, SetFeatureFlagStatusMutationVariables>(SetFeatureFlagStatusDocument, options);
      }
export type SetFeatureFlagStatusMutationHookResult = ReturnType<typeof useSetFeatureFlagStatusMutation>;
export type SetFeatureFlagStatusMutationResult = Apollo.MutationResult<SetFeatureFlagStatusMutation>;
export type SetFeatureFlagStatusMutationOptions = Apollo.BaseMutationOptions<SetFeatureFlagStatusMutation, SetFeatureFlagStatusMutationVariables>;
export const Operations = {
  Query: {
    AdminFeatureFlagsPage: 'AdminFeatureFlagsPage' as const
  },
  Mutation: {
    SetFeatureFlagStatus: 'SetFeatureFlagStatus' as const
  },
  Fragment: {
    CompleteFeatureFlag: 'CompleteFeatureFlag' as const
  }
}