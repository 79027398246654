// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetIlitPremiumPaymentTaskDetails_InsurancePremiumPaymentFragment = { __typename?: 'InsurancePremiumPayment', id: string, paidOn?: Time | null, premiumAmount: CurrencyUSD, updatedAt: Time, dueOn?: Time | null, associatedTask?: { __typename?: 'EntityTask', id: string } | null };

export type GetIlitPremiumPaymentTaskDetails_IlitCrummeyLetterFragment = { __typename?: 'ILITCrummeyLetter', id: string, crummeyWithdrawalPeriodDays: number, sentOn?: Time | null, sendBefore?: Time | null, associatedTask?: { __typename?: 'EntityTask', id: string, dueAt: Time, completionState: Types.EntityTaskCompletionState } | null };

export type GetIlitPremiumPaymentTaskDetailsQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
}>;


export type GetIlitPremiumPaymentTaskDetailsQuery = { __typename?: 'Query', entityTasks: { __typename?: 'EntityTaskConnection', edges?: Array<{ __typename?: 'EntityTaskEdge', node?: { __typename?: 'EntityTask', id: string, dueAt: Time, entity: { __typename?: 'Entity', id: string, ilitTrust?: { __typename?: 'ILITTrust', id: string, policies?: Array<{ __typename?: 'InsurancePolicy', id: string, carrierName?: string | null, policyNumber?: string | null, kind: Types.InsurancePolicyKind, premiumAmount?: CurrencyUSD | null, premiumFrequency?: Types.InsurancePolicyPremiumFrequency | null, documents?: Array<{ __typename?: 'Document', id: string }> | null }> | null } | null }, premiumPayment?: { __typename?: 'InsurancePremiumPayment', id: string, paidOn?: Time | null, premiumAmount: CurrencyUSD, updatedAt: Time, dueOn?: Time | null, associatedTask?: { __typename?: 'EntityTask', id: string } | null } | null, taskDependencies?: Array<{ __typename?: 'EntityTask', id: string, ilitCrummeyLetter?: { __typename?: 'ILITCrummeyLetter', id: string, crummeyWithdrawalPeriodDays: number, sentOn?: Time | null, sendBefore?: Time | null, associatedTask?: { __typename?: 'EntityTask', id: string, dueAt: Time, completionState: Types.EntityTaskCompletionState } | null } | null }> | null } | null } | null> | null } };

export const GetIlitPremiumPaymentTaskDetails_InsurancePremiumPaymentFragmentDoc = gql`
    fragment GetILITPremiumPaymentTaskDetails_InsurancePremiumPayment on InsurancePremiumPayment {
  id
  paidOn
  premiumAmount
  updatedAt
  dueOn
  associatedTask {
    id
  }
}
    `;
export const GetIlitPremiumPaymentTaskDetails_IlitCrummeyLetterFragmentDoc = gql`
    fragment GetILITPremiumPaymentTaskDetails_ILITCrummeyLetter on ILITCrummeyLetter {
  id
  crummeyWithdrawalPeriodDays
  sentOn
  sendBefore
  associatedTask {
    id
    dueAt
    completionState
  }
}
    `;
export const GetIlitPremiumPaymentTaskDetailsDocument = gql`
    query GetILITPremiumPaymentTaskDetails($taskId: ID!) {
  entityTasks(where: {id: $taskId}) {
    edges {
      node {
        id
        entity {
          id
          ilitTrust {
            id
            policies {
              id
              carrierName
              policyNumber
              kind
              premiumAmount
              premiumFrequency
              documents {
                id
              }
            }
          }
        }
        dueAt
        premiumPayment {
          ...GetILITPremiumPaymentTaskDetails_InsurancePremiumPayment
        }
        taskDependencies {
          id
          ilitCrummeyLetter {
            ...GetILITPremiumPaymentTaskDetails_ILITCrummeyLetter
          }
        }
      }
    }
  }
}
    ${GetIlitPremiumPaymentTaskDetails_InsurancePremiumPaymentFragmentDoc}
${GetIlitPremiumPaymentTaskDetails_IlitCrummeyLetterFragmentDoc}`;

/**
 * __useGetIlitPremiumPaymentTaskDetailsQuery__
 *
 * To run a query within a React component, call `useGetIlitPremiumPaymentTaskDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIlitPremiumPaymentTaskDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIlitPremiumPaymentTaskDetailsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetIlitPremiumPaymentTaskDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetIlitPremiumPaymentTaskDetailsQuery, GetIlitPremiumPaymentTaskDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIlitPremiumPaymentTaskDetailsQuery, GetIlitPremiumPaymentTaskDetailsQueryVariables>(GetIlitPremiumPaymentTaskDetailsDocument, options);
      }
export function useGetIlitPremiumPaymentTaskDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIlitPremiumPaymentTaskDetailsQuery, GetIlitPremiumPaymentTaskDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIlitPremiumPaymentTaskDetailsQuery, GetIlitPremiumPaymentTaskDetailsQueryVariables>(GetIlitPremiumPaymentTaskDetailsDocument, options);
        }
export function useGetIlitPremiumPaymentTaskDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIlitPremiumPaymentTaskDetailsQuery, GetIlitPremiumPaymentTaskDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIlitPremiumPaymentTaskDetailsQuery, GetIlitPremiumPaymentTaskDetailsQueryVariables>(GetIlitPremiumPaymentTaskDetailsDocument, options);
        }
export type GetIlitPremiumPaymentTaskDetailsQueryHookResult = ReturnType<typeof useGetIlitPremiumPaymentTaskDetailsQuery>;
export type GetIlitPremiumPaymentTaskDetailsLazyQueryHookResult = ReturnType<typeof useGetIlitPremiumPaymentTaskDetailsLazyQuery>;
export type GetIlitPremiumPaymentTaskDetailsSuspenseQueryHookResult = ReturnType<typeof useGetIlitPremiumPaymentTaskDetailsSuspenseQuery>;
export type GetIlitPremiumPaymentTaskDetailsQueryResult = Apollo.QueryResult<GetIlitPremiumPaymentTaskDetailsQuery, GetIlitPremiumPaymentTaskDetailsQueryVariables>;
export const Operations = {
  Query: {
    GetILITPremiumPaymentTaskDetails: 'GetILITPremiumPaymentTaskDetails' as const
  },
  Fragment: {
    GetILITPremiumPaymentTaskDetails_InsurancePremiumPayment: 'GetILITPremiumPaymentTaskDetails_InsurancePremiumPayment' as const,
    GetILITPremiumPaymentTaskDetails_ILITCrummeyLetter: 'GetILITPremiumPaymentTaskDetails_ILITCrummeyLetter' as const
  }
}