import Decimal from 'decimal.js';
import { isNumber } from 'lodash';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { CharitableTrustDesignerPreTaxReturnModel } from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesignerAnalysis/CharitableTrustDesignerAnalysis.types';

import { CharitableAnalysisForm, NAMESPACE } from './CharitableAnalysis.types';

export function useSelectedYear() {
  const { control } = useFormContext<CharitableAnalysisForm>();
  const [years, selectedYearIdx] = useWatch({
    control,
    name: [`${NAMESPACE}.years`, `${NAMESPACE}.selectedYearIdx`],
  });

  return years[selectedYearIdx];
}

export function mapProposalToForm(
  proposal: ProposalFragment,
  charitablePreTaxCategory: CharitableTrustDesignerPreTaxReturnModel = 'medium',
  initialYearIdx = 0
): CharitableAnalysisForm {
  const output: CharitableAnalysisForm = {
    [NAMESPACE]: {
      selectedYearIdx: 0,
      years: [],
      selectedProjectionIdx: 0,
      projections: [],
      termYears: 0,
    },
    CHARITABLE_TRUST_DESIGNER_ANALYSIS: {
      analysis: {
        yearOfAnalysis: new Date().getFullYear(),
      },
    },
  };
  const charitableProposal = proposal.cltProposal || proposal.crtProposal;

  if (!charitableProposal) {
    throw new Error('Could not load relevant proposal');
  }

  output[NAMESPACE].termYears = charitableProposal.termYears;

  const firstYear = new Date().getFullYear();
  const lastYear = firstYear + (charitableProposal.termYears || 10) - 1; // last year is inclusive
  const midYear = firstYear + Math.floor((lastYear - firstYear) / 2);
  const years = [firstYear, midYear, lastYear];

  output[NAMESPACE].years = years;
  output[NAMESPACE].selectedYearIdx = isNumber(initialYearIdx)
    ? initialYearIdx
    : 2;
  const selectedYear = years[output[NAMESPACE].selectedYearIdx];
  if (selectedYear) {
    output.CHARITABLE_TRUST_DESIGNER_ANALYSIS.analysis.yearOfAnalysis =
      selectedYear;
  }

  let selectedProjectionIdx = 1;
  if (charitablePreTaxCategory === 'low') {
    selectedProjectionIdx = 0;
  } else if (charitablePreTaxCategory === 'high') {
    selectedProjectionIdx = 2;
  }

  output[NAMESPACE].selectedProjectionIdx = selectedProjectionIdx;

  output[NAMESPACE].projections = [
    {
      preTaxReturnPercentage:
        charitableProposal.preTaxReturnPercentageLow || new Decimal(0),
      taxDragPercentage:
        charitableProposal.taxDragPercentageLow || new Decimal(20),
      noPlan: charitableProposal.noPlanProjectionLow?.projectedValue,
    },
    {
      preTaxReturnPercentage:
        charitableProposal.preTaxReturnPercentageMedium || new Decimal(7),
      taxDragPercentage:
        charitableProposal.taxDragPercentageMedium || new Decimal(20),
      noPlan: charitableProposal.noPlanProjectionMedium?.projectedValue,
    },
    {
      preTaxReturnPercentage:
        charitableProposal.preTaxReturnPercentageHigh || new Decimal(15),
      taxDragPercentage:
        charitableProposal.taxDragPercentageHigh || new Decimal(20),
      noPlan: charitableProposal.noPlanProjectionHigh.projectedValue,
    },
  ];

  return output;
}
