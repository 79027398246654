import { TileVariant } from '@/components/diagrams/components/Tile/types';
import { TileNode } from '@/components/diagrams/FlowChart';
import { FlowChartGraph } from '@/utils/graphology/FlowChartGraph';
import { getNodes } from '@/utils/graphqlUtils';

import { PRIMARY_CLIENT_RELATIONSHIP_TYPES } from '../constants/relationships';
import { FamilyTreeState } from '../types';
import {
  FamilyTreeGraph,
  FamilyTreeGraphAttributes,
  FamilyTreeGraphEdgeAttributes,
  FamilyTreeGraphNodeAttributes,
} from '../types/familyTreeGraph';

export function createFamilyTreeGraph({
  familyTree,
}: FamilyTreeState): FamilyTreeGraph {
  const graph = new FlowChartGraph<
    FamilyTreeGraphNodeAttributes,
    FamilyTreeGraphEdgeAttributes,
    FamilyTreeGraphAttributes
  >();

  const clientProfiles = getNodes(familyTree.clientProfiles);

  const primaryClients = clientProfiles.filter((cp) => cp.isPrimary);

  const nonPrimaryClients = clientProfiles.filter((cp) => !cp.isPrimary);

  const primaryClientIds = new Set(primaryClients.map((cp) => cp.id));

  const validRelationshipTypes = new Set(PRIMARY_CLIENT_RELATIONSHIP_TYPES);

  const clientProfilesWithPrimaryClientRelationship = nonPrimaryClients.filter(
    (cp) => {
      return cp.relationships?.find(
        (r) =>
          validRelationshipTypes.has(r.type) &&
          primaryClientIds.has(r.toClientProfile.id)
      );
    }
  );

  const clientProfilesInFamilyTree = [
    ...primaryClients,
    ...clientProfilesWithPrimaryClientRelationship,
  ];

  // Add primary clients
  primaryClients.forEach((cp) => {
    const node: TileNode = {
      id: cp.id,
      position: { x: 0, y: 0 },
      data: {
        lineOne: cp.displayName,
        lineTwo: 'Client',
        variant: TileVariant.Tertiary,
      },
      type: 'tile',
    };

    graph.addNodeSafe(cp.id, {
      node,
      data: cp,
      categorizationType: 'CLIENT',
    });
  });

  // Add those client profiles that have an existing relationship to a primary client
  clientProfilesWithPrimaryClientRelationship.forEach((cp) => {
    const node: TileNode = {
      id: cp.id,
      position: { x: 0, y: 0 },
      data: {
        lineOne: cp.displayName,
        lineTwo: '',
      },
      type: 'tile',
    };

    graph.addNodeSafe(cp.id, {
      node,
      data: cp,
      categorizationType: 'INDIVIDUAL',
    });
  });

  // Add relationships between client profiles
  clientProfilesInFamilyTree.forEach((cp) => {
    cp.relationships?.forEach((r) => {
      const source = r.fromClientProfile.id;
      const target = r.toClientProfile.id;

      if (!graph.hasNode(source) || !graph.hasNode(target)) return;

      graph.addEdgeSafe(source, target, {
        edge: {
          id: `${source}:${target}`,
          source,
          target,
          type: 'arrow',
          data: { hideLabel: true, hideArrow: true },
        },
        type: 'relationship',
      });
    });
  });

  return graph;
}
