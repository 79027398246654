import { SxProps } from '@mui/material';
import MuiCheckbox from '@mui/material/Checkbox';
import { makeStyles } from 'tss-react/mui';

import { CheckCircleIcon } from '@/components/icons/CheckCircleIcon';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { COLORS } from '@/styles/tokens/colors';

import { BaseCheckboxInputProps } from '../inputTypes';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: 20,
    width: 20,
    borderRadius: 100,
    color: COLORS.GRAY[300],
    backgroundColor: COLORS.GRAY[200],
    '&:hover': {
      backgroundColor: COLORS.GRAY[300],
      color: COLORS.GRAY[400],
    },
    // this places a white background behind the checkbox. we have to do it this janky way
    // because the inherent spacing in the checkbox is created by an svg with a specific path,
    // and just putting a backgroundColor on the svg causes the background to spill outside
    // of just the checkbox location itself
    '&.MuiSvgIcon-root': {
      zIndex: 1,
    },
    '&.Mui-focusVisible': {
      outline: 'solid black 2px',
      // the border radius is also set to be very high from MUI (to make the focus ring circular)
      // so we normalize that here
      borderRadius: '8px',
    },
    '&.Mui-checked': {
      color: COLORS.PRIMITIVES.WHITE,
      backgroundColor: COLORS.FUNCTIONAL.SUCCESS[500],
      '&:hover': {
        color: COLORS.PRIMITIVES.WHITE,
        backgroundColor: COLORS.FUNCTIONAL.SUCCESS[700],
      },
    },
  },
  icon: {
    padding: `0 ${theme.spacing(0.2)} !important`,
  },
}));

export const CircleCheckbox = (props: BaseCheckboxInputProps) => {
  const { id, ...rest } = props;

  // nudge the interior icons left, down a few pixels so they're centered in the circle
  const iconSx: SxProps = {
    ml: '-3px',
    mt: '2px',
  };

  const { classes } = useStyles();
  return (
    <MuiCheckbox
      classes={{ root: classes.root }}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      checked={props.value ?? false}
      inputProps={
        {
          'data-testid': `checkbox-${props.name ?? ''}`,
          id,
        } as React.InputHTMLAttributes<HTMLInputElement>
      }
      {...rest}
      icon={<CheckIcon size={18} sx={iconSx} className={classes.icon} />}
      checkedIcon={<CheckIcon size={18} sx={iconSx} className={classes.icon} />}
      indeterminateIcon={<CheckCircleIcon sx={iconSx} size={18} />}
    />
  );
};
