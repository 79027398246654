import { Box, Stack } from '@mui/material';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { appbarHeight } from '@/components/architecture/Layout/AppBar/AppBar';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { Grid } from '@/components/layout/Grid';
import { FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { PageHeader } from '@/components/navigation/PageHeader';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { useReportError } from '@/hooks/useReportError';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { DeleteEntityMenuItem } from '@/modules/entities/components/DeleteEntityMenuItem';
import { NEW_ENTITY_SENTINEL } from '@/modules/entities/entities.constants';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { useAssociatedEntityProposalCountQuery } from './graphql/DesignerLayout.generated';

export type DesignerLayoutProps = React.PropsWithChildren<{
  householdId: string;
  entityId: string;
  heading: React.ReactNode;
  hideHeader?: boolean;
  LeftPaneContent: React.ReactElement;
  RightPaneContent: React.ReactElement;
  FooterActionContent: React.ReactElement;
  onFormSubmit: () => void;
  setNavigationBlocking: (isNavigationBlocked: boolean) => void;
}>;

const LEFT_PANE_COLUMNS = 4;

function useGetAssociatedProposalCount(entityId: string) {
  const { data, error, loading } = useAssociatedEntityProposalCountQuery({
    variables: {
      entityId,
    },
    fetchPolicy: 'cache-and-network',
    skip: entityId === NEW_ENTITY_SENTINEL,
  });

  useReportError(
    'failed to fetch proposal count for the designer layout',
    error,
    {
      entityId,
    }
  );

  const associatedProposalCount = useMemo(() => {
    if (!data) return 0;
    return data.proposals.totalCount;
  }, [data]);

  return { count: associatedProposalCount, error, loading };
}

function getBadgeDisplayText(associatedProposalCount: number): string {
  if (associatedProposalCount === 1) {
    return `Used in ${associatedProposalCount} proposal`;
  }

  return `Used in ${associatedProposalCount} proposals`;
}

export function DesignerLayout({
  heading,
  LeftPaneContent,
  RightPaneContent,
  FooterActionContent,
  onFormSubmit,
  hideHeader = false,
  setNavigationBlocking,
  entityId,
  householdId,
}: DesignerLayoutProps) {
  const navigate = useNavigate();
  const canDeleteEntity = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_DELETE_ENTITIES_AND_PROPOSALS
  );
  const { count: associatedProposalCount } =
    useGetAssociatedProposalCount(entityId);

  const height = useMemo(() => {
    if (hideHeader) {
      return `calc(100vh - ${FOOTER_HEIGHT}px)`;
    }
    return `calc(100vh - ${FOOTER_HEIGHT}px - ${appbarHeight})`;
  }, [hideHeader]);

  return (
    <Stack component="form" noValidate onSubmit={onFormSubmit}>
      <Stack
        sx={{
          height,
          overflow: 'auto',
        }}
      >
        {!hideHeader && (
          <PageHeader
            heading={heading}
            action={
              <Stack direction="row" spacing={2} alignItems="center">
                {associatedProposalCount !== 0 ? (
                  <Badge
                    variant={BadgeVariants.Yellow}
                    display={getBadgeDisplayText(associatedProposalCount)}
                  />
                ) : undefined}
                {entityId !== NEW_ENTITY_SENTINEL && canDeleteEntity && (
                  <ContextMenuButton>
                    <DeleteEntityMenuItem
                      label="Delete draft"
                      modalProps={{
                        heading: 'Are you sure you want to delete this draft?',
                        children: `Deleting will also remove this draft from any associated proposals and delete any client proposals with no remaining drafts.`,
                      }}
                      entityId={entityId}
                      onDelete={() => {
                        setNavigationBlocking(false);
                        navigate(
                          getCompletePathFromRouteKey(
                            ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
                            { householdId }
                          )
                        );
                      }}
                    />
                  </ContextMenuButton>
                )}
              </Stack>
            }
          />
        )}

        <Stack data-it="DesignerLayout-childrenContainer" flex={1}>
          <Stack flex={1}>
            <Stack p={2} flex={1}>
              <Grid container spacing={2} flex={1} columns={12}>
                <Grid height="100%" item sm={LEFT_PANE_COLUMNS}>
                  {LeftPaneContent}
                </Grid>
                <Grid item sm={12 - LEFT_PANE_COLUMNS}>
                  {RightPaneContent}
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Box>{FooterActionContent}</Box>
    </Stack>
  );
}
