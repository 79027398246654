import React, { SyntheticEvent } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import * as diagnostics from '@/utils/diagnostics';

import { useCreateProposalSharingTokenMutation } from './graphql/ProposalSharingLink.generated';

export interface ShareProposalActionProps {
  proposalId: string;
  onError?: (error: Error) => void;
  onClickBeforeCopy?: () => Promise<void>;
  onSuccess?: (copiedContent: string) => void;
  ActionComponent: (props: {
    onClick: (e: SyntheticEvent) => Promise<void>;
    disabled?: boolean;
  }) => JSX.Element;
}

// this is effectively an HOC -- it takes component and passes it an onClick property,
// and abstracts away the handling of the share link generation and error handling
export function ShareProposalAction({
  proposalId,
  onError,
  onSuccess,
  onClickBeforeCopy,
  ActionComponent,
}: ShareProposalActionProps) {
  const trackUserEvent = useTrackUserEvent();
  const [copyToClipboard] = useCopyToClipboard();
  const { showFeedback } = useFeedback();

  const [createProposalSharingTokenMutation, { loading }] =
    useCreateProposalSharingTokenMutation({
      variables: {
        proposalId,
      },
    });

  function handleOnSuccess(copiedContent: string) {
    showFeedback('Sharing link copied to your clipboard!', {
      variant: 'success',
    });
    trackUserEvent('proposal share link copied', {
      proposalId,
    });
    onSuccess && onSuccess(copiedContent);
  }

  function handleOnError(error: Error) {
    showFeedback('Failed to generate or copy the link', {
      variant: 'success',
    });
    onError && onError(error);
  }

  async function handleShareProposalClick(e: SyntheticEvent) {
    // prevent the click from bubbling up to the parent element
    // (e.g., proposal button on a clickable table row triggers the row click)
    e.stopPropagation();
    // this exists to support the "save & copy to clipboard" behavior in the proposal builder.
    // it's kind of messy, but hopefully will go away before our pilot launch and be replaced
    // with more robust sharing functionality.
    if (onClickBeforeCopy) {
      await onClickBeforeCopy();
    }

    try {
      const { data, errors } = await createProposalSharingTokenMutation();
      if (errors && errors.length > 0) {
        const error = new Error(
          `We weren't able to generate a sharing link. Please refresh the page and try again.`
        );
        onError && onError(error);
        return diagnostics.error(
          'failed to generate a proposal sharing link',
          // this should always be present because of the errors.length check above
          new Error(errors[0]?.message),
          { proposalId }
        );
      }

      const sharingURL = data?.createProposalSharingToken.URL;
      if (!sharingURL) {
        diagnostics.warn('empty sharing url response', { proposalId });
        return;
      }

      copyToClipboard(sharingURL, handleOnSuccess, handleOnError);
    } catch (err) {
      return diagnostics.error(
        'thrown error when attempting to generate a proposal sharing link',
        err as Error,
        { proposalId }
      );
    }
  }

  return (
    <ActionComponent onClick={handleShareProposalClick} disabled={loading} />
  );
}
