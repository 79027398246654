import { FormProvider } from 'react-hook-form';

import { useForm } from '@/components/react-hook-form';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { CharitableTrustDesignerPreTaxReturnModel } from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesignerAnalysis/CharitableTrustDesignerAnalysis.types';
import { CharitableTrustDesignerProvider } from '@/pages/designer/CharitableTrustDesigner/CharitableTrustDesignerContext';

import { SlideBodyWithHeader } from '../SlideBodyWithHeader';
import { CharitableAnalysisForm } from './CharitableAnalysis.types';
import { mapProposalToForm } from './CharitableAnalysis.utils';
import { CharitableAnalysisBody } from './CharitableAnalysisBody';

export interface CharitableAnalysisProps {
  proposal: ProposalFragment;
  charitablePreTaxCategory:
    | CharitableTrustDesignerPreTaxReturnModel
    | undefined;
  yearIdx: number | undefined;
}

export function CharitableAnalysis({
  proposal,
  charitablePreTaxCategory,
  yearIdx: initialYearIdx,
}: CharitableAnalysisProps) {
  const formMethods = useForm<CharitableAnalysisForm>({
    defaultValues: mapProposalToForm(
      proposal,
      charitablePreTaxCategory,
      initialYearIdx
    ),
  });

  const charitableProposal = proposal.cltProposal || proposal.crtProposal;

  if (!charitableProposal) {
    return null;
  }

  const variant = proposal?.crtProposal?.id ? 'crt' : 'clt';

  return (
    <CharitableTrustDesignerProvider
      variant={variant}
      householdId={proposal.household.id}
      proposalId={proposal.id}
    >
      <FormProvider {...formMethods}>
        <SlideBodyWithHeader header={proposal.displayName} spacing={6}>
          <CharitableAnalysisBody proposal={proposal} />
        </SlideBodyWithHeader>
      </FormProvider>
    </CharitableTrustDesignerProvider>
  );
}
