import { Stack, Typography } from '@mui/material';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { UnstyledButton } from '@/components/form/baseInputs/Button/UnstyledButton';
import { UserPlus01Icon } from '@/components/icons/UserPlus01Icon';
import { Card, CardVariant } from '@/components/layout/Card/Card';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { LoadingSkeletonOrValue } from '@/components/progress/LoadingSkeletonOrValue/LoadingSkeletonOrValue';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { useReportError } from '@/hooks/useReportError';

import { ClientPeopleListViewTable_ClientProfileFragment } from './graphql/ClientPeopleListViewTable.generated';

export interface ClientDetailsGrantorCardProps {
  grantor: ClientPeopleListViewTable_ClientProfileFragment | null | undefined;
  loading: boolean;
  onClick: (clientProfileId: string | null) => void;
}

const GRANTOR_CARD_HEIGHT_PX = 80;

export function ClientDetailsGrantorCard({
  grantor,
  loading,
  onClick,
}: ClientDetailsGrantorCardProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  if (!grantor && !loading) {
    return (
      <UnstyledButton
        data-testid="AddSecondPersonButton"
        onClick={() => onClick(null)}
        sx={{ width: '100%', height: GRANTOR_CARD_HEIGHT_PX }}
      >
        <Card variant="outlined" sx={{ width: '100%', height: '100%' }}>
          <Stack
            direction="row"
            width="100%"
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            height="100%"
            spacing={1}
          >
            <UserPlus01Icon />
            <Typography variant="body2">Add a second person</Typography>
          </Stack>
        </Card>
      </UnstyledButton>
    );
  }
  const variant: CardVariant = loading ? 'outlined' : 'filled-callout';
  return (
    <>
      <Card variant={variant} sx={{ p: 2, height: GRANTOR_CARD_HEIGHT_PX }}>
        <Stack direction="row" width="100%" alignItems="center">
          <Stack direction="column" flexGrow={2}>
            <OversizedMetricItem
              invertLabel
              value={
                <TypographyClamped variant="h2" lines={1}>
                  <LoadingSkeletonOrValue
                    loading={loading}
                    value={grantor?.displayName}
                  />
                </TypographyClamped>
              }
              title={
                <LoadingSkeletonOrValue loading={loading} value="Client" />
              }
            />
          </Stack>
          <EditButton
            variant="secondary"
            size="sm"
            sx={{ maxHeight: '36px', maxWidth: '36px' }}
            disabled={loading}
            onClick={() => {
              if (!grantor?.id) {
                showFeedback(
                  'Could not edit the client.  Please refresh and try again'
                );
                reportError(
                  'Attempted to edit a grantor without an ID',
                  new Error('attempted to edit a grantor without an ID'),
                  { grantor: JSON.stringify(grantor) }
                );
                return;
              }
              onClick(grantor.id);
            }}
          />
        </Stack>
      </Card>
    </>
  );
}
