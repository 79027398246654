import { Stack, SxProps, useTheme } from '@mui/material';
import { ComponentProps, PropsWithChildren, useCallback } from 'react';

import { useGlobalSidebarContext } from '@/modules/globalSidebar/globalSidebar.context';
import { COLORS } from '@/styles/tokens/colors';

import { SidebarToggleVisibilityHandle } from './SidebarContainer.visibilityHandle';

export interface SidebarContainerProps extends PropsWithChildren {
  /** Defaults to 16.67%, the equivalent of 2/12 columns. */
  width?: ComponentProps<typeof Stack>['width'];
  sx?: Omit<SxProps, 'width' | 'minWidth' | 'maxWidth' | 'flexBasis'>;
  showVisibilityHandle?: boolean;
  onExpand?: () => void;
  onCollapse?: () => void;
}

export const DEFAULT_SIDEBAR_WIDTH = '240px';

export function SidebarContainer({
  width: expandedSidebarWidth = DEFAULT_SIDEBAR_WIDTH,
  sx = {},
  children,
  showVisibilityHandle = false,
  onExpand,
  onCollapse,
  ...props
}: SidebarContainerProps) {
  const { isSidebarCollapsed, setSidebarCollapsed } = useGlobalSidebarContext();

  const handleSidebarClick = useCallback(() => {
    const nextIsSidebarCollapsed = !isSidebarCollapsed;

    if (nextIsSidebarCollapsed && onCollapse) {
      onCollapse();
    } else if (!nextIsSidebarCollapsed && onExpand) {
      onExpand();
    }
    setSidebarCollapsed(nextIsSidebarCollapsed);
  }, [isSidebarCollapsed, onCollapse, onExpand, setSidebarCollapsed]);

  const theme = useTheme();
  const width =
    isSidebarCollapsed && showVisibilityHandle ? 0 : expandedSidebarWidth;
  return (
    <>
      <Stack
        spacing={3}
        boxShadow={theme.palette.shadows.rightInvertInset}
        {...props}
        sx={{
          height: '100%',
          background: COLORS.PRIMITIVES.WHITE,
          overflowY: 'auto',
          flexGrow: 0,
          flexShrink: 0,
          ...sx,
          flexBasis: width,
          width: width,
          minWidth: width,
          maxWidth: width,
        }}
      >
        {children}
      </Stack>
      <SidebarToggleVisibilityHandle
        visible={showVisibilityHandle}
        sidebarCollapsed={isSidebarCollapsed}
        onClick={handleSidebarClick}
        expandedSidebarWidth={expandedSidebarWidth}
      />
    </>
  );
}
