// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLegalDisclaimersMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateTenantDisclaimerConfigurationInput;
}>;


export type UpdateLegalDisclaimersMutation = { __typename?: 'Mutation', updateTenantDisclaimerConfiguration: { __typename?: 'TenantDisclaimerConfiguration', id: string } };

export type LegalDisclaimersForm_TenantDisclaimerConfigurationFragment = { __typename?: 'TenantDisclaimerConfiguration', id: string, presentationCoverDisclaimerTitle?: string | null, presentationCoverDisclaimerBody?: string | null, presentationFooterDisclaimerTitle?: string | null, presentationFooterDisclaimerBody?: string | null, presentationStandaloneDisclaimerTitle?: string | null, presentationStandaloneDisclaimerBody?: string | null, includeLuminaryDisclaimer: boolean };

export type LegalDisclaimersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LegalDisclaimersQuery = { __typename?: 'Query', tenantInformation: { __typename?: 'TenantInformation', id: string, disclosuresConfiguration?: { __typename?: 'TenantDisclaimerConfiguration', id: string, presentationCoverDisclaimerTitle?: string | null, presentationCoverDisclaimerBody?: string | null, presentationFooterDisclaimerTitle?: string | null, presentationFooterDisclaimerBody?: string | null, presentationStandaloneDisclaimerTitle?: string | null, presentationStandaloneDisclaimerBody?: string | null, includeLuminaryDisclaimer: boolean } | null } };

export const LegalDisclaimersForm_TenantDisclaimerConfigurationFragmentDoc = gql`
    fragment LegalDisclaimersForm_TenantDisclaimerConfiguration on TenantDisclaimerConfiguration {
  id
  presentationCoverDisclaimerTitle
  presentationCoverDisclaimerBody
  presentationFooterDisclaimerTitle
  presentationFooterDisclaimerBody
  presentationStandaloneDisclaimerTitle
  presentationStandaloneDisclaimerBody
  includeLuminaryDisclaimer
}
    `;
export const UpdateLegalDisclaimersDocument = gql`
    mutation UpdateLegalDisclaimers($input: AugmentedUpdateTenantDisclaimerConfigurationInput!) {
  updateTenantDisclaimerConfiguration(input: $input) {
    id
  }
}
    `;
export type UpdateLegalDisclaimersMutationFn = Apollo.MutationFunction<UpdateLegalDisclaimersMutation, UpdateLegalDisclaimersMutationVariables>;

/**
 * __useUpdateLegalDisclaimersMutation__
 *
 * To run a mutation, you first call `useUpdateLegalDisclaimersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLegalDisclaimersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLegalDisclaimersMutation, { data, loading, error }] = useUpdateLegalDisclaimersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLegalDisclaimersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLegalDisclaimersMutation, UpdateLegalDisclaimersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLegalDisclaimersMutation, UpdateLegalDisclaimersMutationVariables>(UpdateLegalDisclaimersDocument, options);
      }
export type UpdateLegalDisclaimersMutationHookResult = ReturnType<typeof useUpdateLegalDisclaimersMutation>;
export type UpdateLegalDisclaimersMutationResult = Apollo.MutationResult<UpdateLegalDisclaimersMutation>;
export type UpdateLegalDisclaimersMutationOptions = Apollo.BaseMutationOptions<UpdateLegalDisclaimersMutation, UpdateLegalDisclaimersMutationVariables>;
export const LegalDisclaimersDocument = gql`
    query LegalDisclaimers {
  tenantInformation {
    id
    disclosuresConfiguration {
      ...LegalDisclaimersForm_TenantDisclaimerConfiguration
    }
  }
}
    ${LegalDisclaimersForm_TenantDisclaimerConfigurationFragmentDoc}`;

/**
 * __useLegalDisclaimersQuery__
 *
 * To run a query within a React component, call `useLegalDisclaimersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegalDisclaimersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegalDisclaimersQuery({
 *   variables: {
 *   },
 * });
 */
export function useLegalDisclaimersQuery(baseOptions?: Apollo.QueryHookOptions<LegalDisclaimersQuery, LegalDisclaimersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LegalDisclaimersQuery, LegalDisclaimersQueryVariables>(LegalDisclaimersDocument, options);
      }
export function useLegalDisclaimersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LegalDisclaimersQuery, LegalDisclaimersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LegalDisclaimersQuery, LegalDisclaimersQueryVariables>(LegalDisclaimersDocument, options);
        }
export function useLegalDisclaimersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LegalDisclaimersQuery, LegalDisclaimersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LegalDisclaimersQuery, LegalDisclaimersQueryVariables>(LegalDisclaimersDocument, options);
        }
export type LegalDisclaimersQueryHookResult = ReturnType<typeof useLegalDisclaimersQuery>;
export type LegalDisclaimersLazyQueryHookResult = ReturnType<typeof useLegalDisclaimersLazyQuery>;
export type LegalDisclaimersSuspenseQueryHookResult = ReturnType<typeof useLegalDisclaimersSuspenseQuery>;
export type LegalDisclaimersQueryResult = Apollo.QueryResult<LegalDisclaimersQuery, LegalDisclaimersQueryVariables>;
export const Operations = {
  Query: {
    LegalDisclaimers: 'LegalDisclaimers' as const
  },
  Mutation: {
    UpdateLegalDisclaimers: 'UpdateLegalDisclaimers' as const
  },
  Fragment: {
    LegalDisclaimersForm_TenantDisclaimerConfiguration: 'LegalDisclaimersForm_TenantDisclaimerConfiguration' as const
  }
}