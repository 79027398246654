import { Typography } from '@mui/material';

import { CloseButton } from '@/components/form/baseInputs/Button/CloseButton';
import { Modal, ModalProps } from '@/components/modals/Modal/Modal';
import { DefaultLuminaryDisclaimer } from '@/modules/presentation/clientPresentation/DisclaimerSlide/DefaultLuminaryDisclaimer';

export type LuminaryDisclaimerModalProps = Omit<ModalProps, 'heading'>;

export function LuminaryDisclaimerModal(props: LuminaryDisclaimerModalProps) {
  return (
    <Modal
      rightHeaderContent={<CloseButton onClick={props.onClose} />}
      heading="Luminary disclaimer"
      maxWidth={'lg'}
      {...props}
    >
      <Typography variant="body2">
        <DefaultLuminaryDisclaimer />
      </Typography>
    </Modal>
  );
}
