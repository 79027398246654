import {
  array,
  boolean as booleanType,
  date,
  Describe,
  nullable,
  number,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreateIlitTrustInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateIlitTrustInput,
} from '@/types/schema';

import {
  createInsurancePolicyInput,
  updateInsurancePolicyInput,
} from './insuranceTypes';
import {
  beneficiarySchema,
  commonUpdateEntityProperties,
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  grantorSchema,
  gstStatusSchema,
  inEstateStatusSchema,
  taxStatusSchema,
  trustAdvisorSchema,
  trusteeSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const createILITTrustSchema: Describe<AugmentedCreateIlitTrustInput> = object({
  create: object({
    crummeyWithdrawalPeriodDays: optional(nullable(number())),
    trustJurisdictionStateCode: optional(nullable(string())),
    taxStatus: taxStatusSchema,
    legalName: optional(nullable(string())),
    inEstateStatus: inEstateStatusSchema,
    gstStatus: gstStatusSchema,
    effectiveDate: optional(nullable(date())),
    displayName: string(),
    description: optional(nullable(string())),
    policyHolderIDs: optional(nullable(array(string()))),
  }),
  withBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withTrustees: optional(nullable(array(trusteeSchema))),
  withSuccessorTrustees: optional(nullable(array(trusteeSchema))),
  withTrustAdvisors: optional(nullable(array(trustAdvisorSchema))),
  withGrantors: optional(nullable(array(grantorSchema))),
  withDesignerAccount: optional(nullable(createAccountSchema)),
  withPolicies: optional(nullable(array(createInsurancePolicyInput))),
});

const updateILITTrustSchema: Describe<AugmentedUpdateIlitTrustInput> = object({
  id: string(),
  update: object({
    crummeyWithdrawalPeriodDays: optional(nullable(number())),
    description: optional(nullable(string())),
    displayName: optional(nullable(string())),
    effectiveDate: optional(nullable(date())),
    gstStatus: gstStatusSchema,
    inEstateStatus: inEstateStatusSchema,
    legalName: optional(nullable(string())),
    taxStatus: taxStatusSchema,
    trustJurisdictionStateCode: optional(nullable(string())),
    removePolicyIDs: optional(nullable(array(string()))),
    clearEffectiveDate: optional(nullable(booleanType())),
    clearLegalName: optional(nullable(booleanType())),
    clearTrustJurisdictionStateCode: optional(nullable(booleanType())),
    clearBeneficiaries: optional(nullable(booleanType())),
    clearCrummeyWithdrawalPeriodDays: optional(nullable(booleanType())),
    clearGrantors: optional(nullable(booleanType())),
    clearSuccessorTrustees: optional(nullable(booleanType())),
    clearTrustAdvisors: optional(nullable(booleanType())),
    clearDescription: optional(nullable(booleanType())),
    clearTrustees: optional(nullable(booleanType())),
    clearGstStatus: optional(nullable(booleanType())),
    clearTaxStatus: optional(nullable(booleanType())),
  }),
  withBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withTrustees: optional(nullable(array(trusteeSchema))),
  withSuccessorTrustees: optional(nullable(array(trusteeSchema))),
  withTrustAdvisors: optional(nullable(array(trustAdvisorSchema))),
  withGrantors: optional(nullable(array(grantorSchema))),
  withPolicies: optional(nullable(array(createInsurancePolicyInput))),
  updateDesignerAccount: optional(nullable(updateAccountSchema)),
  updatePolicies: optional(nullable(array(updateInsurancePolicyInput))),
});

const createSchema = object({
  create: createEntityInput,
  withIlitTrust: optional(nullable(createILITTrustSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object(commonUpdateEntityProperties),
  updateIlitTrust: optional(nullable(updateILITTrustSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export function validateIlitTrustCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'ILIT create');
}

export function validateIlitTrustUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'ILIT update');
}
