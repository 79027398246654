import domtoimage from 'dom-to-image';
import { useCallback } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';

import { diagnostics } from './diagnostics';

interface CaptureElementAsPngOptions {
  /* If not provided, the width of the element in the document will be used. */
  width?: number;
  /** Number of pixels to trim from the top of the image */
  trimTop?: number;
  /** Number of pixels to trim from the right of the image */
  trimRight?: number;
  /** Number of pixels to trim from the bottom of the image */
  trimBottom?: number;
  /** Number of pixels to trim from the left of the image */
  trimLeft?: number;
}

/**
 * Hook that returns a function to capture an element as a PNG and download it.
 * Allows trimming a specific number of pixels from any side of the image.
 * @returns A function that takes an element, a fileName, and optional trimming options as parameters.
 */
export function useCaptureElementAsPng() {
  const { showFeedback } = useFeedback();
  return useCallback(
    async (
      element: HTMLElement | null,
      fileName: string,
      options?: CaptureElementAsPngOptions
    ): Promise<void> => {
      if (element) {
        try {
          let targetElement = element;
          let elementToRemove = null;

          // If the element is too wide, we need to wrap it in a div and set its width appropriately,
          // because dom-to-image will not render anything outside of the viewport
          if (options?.width) {
            targetElement = document.createElement('div');
            targetElement.style.width = `${options.width}px`;
            const clonedElement = element.cloneNode(true) as HTMLElement;
            targetElement.appendChild(clonedElement);
            document.body.appendChild(targetElement);
            elementToRemove = targetElement;
            await new Promise((resolve) => setTimeout(resolve, 100));
          }

          const dataUrl = await domtoimage.toPng(targetElement);

          const {
            trimTop = 0,
            trimRight = 0,
            trimBottom = 0,
            trimLeft = 0,
          } = options || {};

          if (
            trimTop === 0 &&
            trimRight === 0 &&
            trimBottom === 0 &&
            trimLeft === 0
          ) {
            // No trimming needed, proceed to download the image as is
            const downloadLink = document.createElement('a');
            downloadLink.href = dataUrl;
            downloadLink.download = `${fileName}.png`;
            downloadLink.click();
          } else {
            // Create an image element to load the data URL
            const image = new Image();
            image.src = dataUrl;

            // Wait for the image to load
            await new Promise<void>((resolve, reject) => {
              image.onload = () => resolve();
              image.onerror = (err) => reject(err);
            });

            // Calculate the dimensions of the cropped image
            const newWidth = image.width - trimLeft - trimRight;
            const newHeight = image.height - trimTop - trimBottom;

            if (newWidth <= 0 || newHeight <= 0) {
              throw new Error(
                'Trim dimensions are larger than the image dimensions'
              );
            }

            // Create a canvas to draw the cropped image
            const canvas = document.createElement('canvas');
            canvas.width = newWidth;
            canvas.height = newHeight;
            const ctx = canvas.getContext('2d');

            if (!ctx) {
              throw new Error('Failed to get canvas context');
            }

            // Draw the cropped image onto the canvas
            ctx.drawImage(
              image,
              trimLeft, // Start cropping from this x-coordinate
              trimTop, // Start cropping from this y-coordinate
              newWidth, // Width of the cropped image
              newHeight, // Height of the cropped image
              0, // Place the result at x=0 on the canvas
              0, // Place the result at y=0 on the canvas
              newWidth, // Width of the drawn image
              newHeight // Height of the drawn image
            );

            // Convert the canvas back to a data URL
            const croppedDataUrl = canvas.toDataURL('image/png');

            // Download the cropped image
            const downloadLink = document.createElement('a');
            downloadLink.href = croppedDataUrl;
            downloadLink.download = `${fileName}.png`;
            downloadLink.click();
          }

          if (elementToRemove) {
            document.body.removeChild(elementToRemove);
          }
        } catch (err) {
          showFeedback('Failed to generate image');
          diagnostics.error(`failed to generate PNG`, err as Error);
        }
      } else {
        throw new Error('called captureElementAsPng with null element');
      }
    },
    [showFeedback]
  );
}
