import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { Footer } from '@/components/navigation/Footer';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { DesignerStagePicker } from '@/modules/entities/gratTrusts/GRATDesignerTrowser/DesignerStagePicker';
import { GratDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { isNewEntity } from './basicInformationDesignerUtils';

export interface BasicInformationDesignerFooterProps {
  householdId: string;
  entityId: string;
  disabled?: boolean;
}

export function BasicInformationDesignerFooter({
  householdId,
  entityId,
  disabled,
}: BasicInformationDesignerFooterProps) {
  const navigate = useNavigate();
  return (
    <Footer
      rightAction={
        <Stack direction="row" spacing={2}>
          {/* We only want to show the "cancel" button for new GRATs because "cancel" doesn't really make sense
in the context of updating an */}
          {isNewEntity(entityId) && (
            <Button
              disabled={disabled}
              onClick={() => navigate(-1)}
              variant="secondary"
              size="md"
            >
              Cancel
            </Button>
          )}
          {!isNewEntity(entityId) && (
            <DesignerStagePicker
              disabled={disabled}
              currentStage={GratDesignerStages.BASIC_INFORMATION}
              onChangeStage={(newStage) => {
                if (newStage === GratDesignerStages.BASIC_INFORMATION) return;
                navigate(
                  getCompletePathFromRouteKey(
                    ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER,
                    {
                      householdId,
                      entityId,
                      designerStage: GratDesignerStages.STRUCTURING,
                      entityType: ENTITY_TYPES.GRAT,
                    }
                  )
                );
              }}
            />
          )}

          <Button disabled={disabled} type="submit" variant="primary" size="md">
            {isNewEntity(entityId) ? 'Save and proceed' : 'Proceed'}
          </Button>
        </Stack>
      }
    />
  );
}
