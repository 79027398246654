import { Box, Stack, Typography } from '@mui/material';

import { FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { InPageNavigationBox } from '@/components/navigation/InPageNavigation/InPageNavigationBox';
import { Callout } from '@/components/notifications/Callout/Callout';
import {
  EntitySubformCategory,
  SubformConfig,
} from '@/modules/entities/EntitySubforms/entitySubformConfigs';
import { HypotheticalEstateWaterfallBadge } from '@/modules/estateWaterfall/components/HypotheticalEstateWaterfallBadge';

import { SINGLE_PRINCIPAL_ENTITY_TYPES } from '../entities.constants';
import { EntityType } from '../types/EntityType';

interface EntitySubformsSidebarNavProps {
  subformConfigs: SubformConfig[];
  entityType?: EntityType;
  heading?: string;
  contentContainerId: string;
  isHypotheticalEntity?: boolean;
}

export const EntitySubformsSidebarNav = ({
  subformConfigs,
  heading,
  entityType,
  contentContainerId,
  isHypotheticalEntity = false,
}: EntitySubformsSidebarNavProps) => {
  if (!subformConfigs.length) {
    return null;
  }

  const rows = subformConfigs.map(({ sidebar, htmlId }) => ({
    id: htmlId,
    label: sidebar.label,
  }));

  const shouldRenderBeneficiariesWarning = Boolean(
    subformConfigs.find(
      ({ category }) => category === EntitySubformCategory.BENEFICIARIES
    )
  );

  return (
    <Stack>
      {isHypotheticalEntity && (
        <Box pb={1}>
          <HypotheticalEstateWaterfallBadge display="Draft entity" />
        </Box>
      )}
      {heading && (
        <Typography variant="h1" sx={{ pb: 3 }}>
          {heading}
        </Typography>
      )}
      <InPageNavigationBox
        sx={{
          pb: 6,
          mr: -6, // negative margin to allow sidenav header to stick to the right
        }}
        rows={rows}
        observerOptions={{
          rootMargin: `0px 0px ${FOOTER_HEIGHT}px 0px`,
          threshold: 0.8, // to account for the padding below the final element
        }}
        scrollOptions={{
          block: 'start',
        }}
        contentContainerId={contentContainerId}
      />
      {shouldRenderBeneficiariesWarning && (
        <Callout severity="warning">
          <p>
            <b>Note on the beneficiaries section</b>
          </p>
          {entityType && SINGLE_PRINCIPAL_ENTITY_TYPES.includes(entityType) ? (
            <>
              <p>
                Use the beneficiaries section of this form to input recipients
                who are currently benefiting from this entity.
              </p>
              <p>
                Use the &quot;Disposition&quot; tab, accessible from the entity
                summary page, to enter instructions on the recipients of this
                entity upon the death of the grantor.
              </p>
            </>
          ) : (
            <>
              <p>
                Use the beneficiaries section of this form to input recipients
                who are currently benefiting from this entity.
              </p>
              <p>
                Use the &quot;Disposition&quot; tab, accessible from the entity
                summary page, to enter instructions on the recipients of this
                entity upon the death of one or both grantors.
              </p>
            </>
          )}
        </Callout>
      )}
    </Stack>
  );
};
