import { noop } from 'lodash';
import { useCallback, useState } from 'react';

import {
  ButtonWithPopover,
  ButtonWithPopoverProps,
} from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { CashflowModal } from '@/modules/cashflows/CashflowModal/CashflowModal';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { CreateEntityShortFormModal } from '@/modules/entities/EntityShortFormModal/CreateEntityShortFormModal';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { LogNewGiftModal } from '@/modules/gifting/LogNewGiftModal/LogNewGiftModal';
import { RecordExemptionUsedModal } from '@/modules/gifting/RecordExemptionUsedModal/RecordExemptionUsedModal';
import { GrowthProfileModal } from '@/modules/growthProfiles/GrowthProfileModal/GrowthProfileModal';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { CreateLiabilityModal } from '@/modules/liabilities/LiabilityModal/CreateLiabilityModal';
import { Operations as GrowthProfilesTableOperations } from '@/pages/ClientDetailsPage/ClientDetailsGrowthProfilesPage/GrowthProfilesTable/graphql/GrowthProfilesTable.generated';
import { UnreachableError } from '@/utils/errors';

export type ClientWealthPageAddButtonProps = Pick<
  ButtonWithPopoverProps,
  'popperProps' | 'popperVariant'
>;

export function ClientWealthPageAddButton(
  props: ClientWealthPageAddButtonProps
) {
  const { householdId } = useHouseholdDetailsContext();
  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');
  const [isAddEntityModalOpen, setIsAddEntityModalOpen] = useState(false);
  const [isAddLiabilityModalOpen, setIsAddLiabilityModalOpen] = useState(false);
  const [isAddCashflowModalOpen, setIsAddCashflowModalOpen] = useState(false);
  const [isAddGiftModalOpen, setIsAddGiftModalOpen] = useState(false);
  const [isAddExemptionModalOpen, setIsAddExemptionModalOpen] = useState(false);
  const [isAddGrowthProfileModalOpen, setIsAddGrowthProfileModalOpen] =
    useState(false);
  const handleMenuClick = useCallback(
    (
      kind:
        | 'entity'
        | 'liability'
        | 'cashflow'
        | 'gift'
        | 'exemption'
        | 'growthProfile'
    ) => {
      // make sure we close all modals before opening a new one; this *should* never happen,
      // but just in case
      setIsAddEntityModalOpen(false);
      setIsAddLiabilityModalOpen(false);
      setIsAddCashflowModalOpen(false);
      setIsAddGiftModalOpen(false);
      setIsAddExemptionModalOpen(false);

      switch (kind) {
        case 'entity':
          setIsAddEntityModalOpen(true);
          break;
        case 'liability':
          setIsAddLiabilityModalOpen(true);
          break;
        case 'cashflow':
          setIsAddCashflowModalOpen(true);
          break;
        case 'gift':
          setIsAddGiftModalOpen(true);
          break;
        case 'exemption':
          setIsAddExemptionModalOpen(true);
          break;
        case 'growthProfile':
          setIsAddGrowthProfileModalOpen(true);
          break;
        default:
          throw new UnreachableError({
            case: kind,
            message: `Unreachable case in ClientWealthPageAddButton: ${kind}`,
          });
      }
    },
    []
  );

  if (!householdId) return null;

  return (
    <>
      {/* always unmount/remount to clear state */}
      {isAddEntityModalOpen && (
        <CreateEntityShortFormModal
          isOpen={isAddEntityModalOpen}
          onClose={() => setIsAddEntityModalOpen(false)}
          // override the default "navigate to entity" behavior and just stay on this page
          onAfterCreate={noop}
          initialEntityType={ENTITY_TYPES.IRREVOCABLE_TRUST}
          householdId={householdId}
        />
      )}
      <CreateLiabilityModal
        isOpen={isAddLiabilityModalOpen}
        onClose={() => setIsAddLiabilityModalOpen(false)}
      />
      <CashflowModal
        cashflowId={null} // we only create cashflows from here, no edits
        isOpen={isAddCashflowModalOpen}
        onClose={() => setIsAddCashflowModalOpen(false)}
      />
      <LogNewGiftModal
        householdId={householdId}
        isOpen={isAddGiftModalOpen}
        onClose={() => setIsAddGiftModalOpen(false)}
      />
      <RecordExemptionUsedModal
        householdId={householdId}
        isOpen={isAddExemptionModalOpen}
        onClose={() => setIsAddExemptionModalOpen(false)}
      />
      {isAddGrowthProfileModalOpen && (
        <GrowthProfileModal
          householdId={householdId}
          onClose={() => setIsAddGrowthProfileModalOpen(false)}
          refetchQueries={[
            GrowthProfilesTableOperations.Query.GrowthProfilesTable,
          ]}
        />
      )}
      <ButtonWithPopover
        label="Add"
        startIcon={PlusIcon}
        size="sm"
        variant="primary"
        {...props}
      >
        <MenuItem
          label="Create entity"
          onClick={() => handleMenuClick('entity')}
        />
        <MenuItem
          label="Add liability"
          muiMenuItemProps={{
            divider: true,
          }}
          onClick={() => handleMenuClick('liability')}
        />
        {isGrowthProfilesEnabled && (
          <MenuItem
            label="Add growth profile"
            onClick={() => handleMenuClick('growthProfile')}
          />
        )}
        <MenuItem
          label="Add cash flow"
          muiMenuItemProps={{
            divider: true,
          }}
          onClick={() => handleMenuClick('cashflow')}
        />
        <MenuItem label="Log gift" onClick={() => handleMenuClick('gift')} />
        <MenuItem
          label="Record exemption"
          onClick={() => handleMenuClick('exemption')}
        />
      </ButtonWithPopover>
    </>
  );
}
