import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { Alert } from '@/components/notifications/Alert/Alert';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { useSeedAiOnboardingSuggestionsMutation } from '@/pages/internal/DevToolsPage/SeedAIOnboardingSuggestionsButton/graphql/SeedAIOnboardingSuggestions.generated';

export function SeedAIOnboardingSuggestionsButton() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [householdPageLink, setHouseholdPageLink] = useState<string>('');

  const [seedAISuggestions, { loading }] =
    useSeedAiOnboardingSuggestionsMutation({
      onError: (err) => {
        showFeedback(
          `Failed to seed ai onboarding suggestions: ${err.message}`
        );
        reportError('could not seed ai onboarding suggestions', err);
      },
      onCompleted: (data) => {
        const { id } = data.seedAIOnboardingSuggestions;
        const link = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
          {
            householdId: id,
          }
        );
        setHouseholdPageLink(link);
      },
    });

  return (
    <HeaderCard heading="Seed AI Onboarding Suggestions">
      <Stack spacing={2}>
        <Button
          size="md"
          variant="primary"
          onClick={() => seedAISuggestions()}
          loading={loading}
        >
          Create household and seed AI onboarding suggestions
        </Button>
        {householdPageLink && (
          <Alert size="sm" severity="success">
            <Typography variant="body1">
              <NavLink to={householdPageLink}>
                Go to the seeded household {'→'}
              </NavLink>
            </Typography>
          </Alert>
        )}
      </Stack>
    </HeaderCard>
  );
}
