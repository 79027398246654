import { compact } from 'lodash';

import { AIOnboardingModalIndividualField } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import {
  AiOnboardingModal_AiSuggestionFragment,
  AiOnboardingModal_HouseholdFragment,
  AiOnboardingModal_IndividualSuggestionFragment,
} from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import {
  ClientProfileRelationshipType,
  CreateClientProfileWithDetailsInput,
} from '@/types/schema';

///////////////////////////////////////////////////////////
// SUGGESTION DATA -> FORM DATA
///////////////////////////////////////////////////////////

/**
 * Maps the ONBOARDING_INDIVIDUAL suggestion to form data.
 *
 * @param suggestions
 * @param possiblePrimaryClients
 */
export function mapIndividualsToFormData(
  suggestions: AiOnboardingModal_AiSuggestionFragment[],
  { possiblePrimaryClients }: AiOnboardingModal_HouseholdFragment
): AIOnboardingModalIndividualField[] {
  const primaryClient1ID = possiblePrimaryClients[0]?.id || '';
  const primaryClient2ID = possiblePrimaryClients[1]?.id || '';

  return compact(
    suggestions.map((s) => {
      if (!s.onboardingIndividual) return null;

      return {
        _suggestionID: s.id,
        _nodeName: s.onboardingIndividual?.nodeName,
        firstName: s.onboardingIndividual?.firstName || '',
        middleName: s.onboardingIndividual?.middleName || '',
        lastName: s.onboardingIndividual?.lastName || '',
        suffix: s.onboardingIndividual?.suffix || '',
        relationshipToPrimaryClient1: getSuggestedRelationship(
          primaryClient1ID,
          s.onboardingIndividual?.personalRelationships || []
        ),
        relationshipToPrimaryClient2: getSuggestedRelationship(
          primaryClient2ID,
          s.onboardingIndividual?.personalRelationships || []
        ),
      };
    })
  );
}

function getSuggestedRelationship(
  primaryClientID: string,
  personalRelationships: AiOnboardingModal_IndividualSuggestionFragment['personalRelationships']
): ClientProfileRelationshipType | '' {
  const rel = personalRelationships.find(
    (r) => r.clientProfileID === primaryClientID
  );
  return rel?.relationshipType || '';
}

export function getEmptyIndividualField(): AIOnboardingModalIndividualField {
  return {
    _suggestionID: '',
    _nodeName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    relationshipToPrimaryClient1: '',
    relationshipToPrimaryClient2: '',
  };
}

///////////////////////////////////////////////////////////
// FORM DATA -> MUTATION INPUT
///////////////////////////////////////////////////////////

/**
 * Converts the form data to the input data for the mutation.
 *
 * @param individuals
 * @param household
 * @param suggestionsByID
 */
export function mapIndividualsToInput(
  individuals: AIOnboardingModalIndividualField[],
  household: AiOnboardingModal_HouseholdFragment,
  suggestionsByID: Record<string, AiOnboardingModal_AiSuggestionFragment>
): CreateClientProfileWithDetailsInput[] {
  const primaryClient1ID = household.possiblePrimaryClients[0]?.id || '';
  const primaryClient2ID = household.possiblePrimaryClients[1]?.id || '';

  return individuals.map<CreateClientProfileWithDetailsInput>((i) => {
    return {
      clientProfile: {
        householdID: household.id,
        firstName: i.firstName,
        middleName: i.middleName,
        lastName: i.lastName,
        suffix: i.suffix,
        isPrimary: false,
        isGrantor: i._suggestionID
          ? suggestionsByID[i._suggestionID]?.onboardingIndividual
              ?.isSuggestedAsGrantor
          : false,
        isBeneficiary: true,
        isTrustee: true,
        suggestedClientProfileIDs: i._suggestionID
          ? [i._suggestionID]
          : undefined,
      },
      relationships: compact([
        i.relationshipToPrimaryClient1 && {
          toClientProfileID: primaryClient1ID,
          type: i.relationshipToPrimaryClient1,
        },
        primaryClient2ID &&
          i.relationshipToPrimaryClient2 && {
            toClientProfileID: primaryClient2ID,
            type: i.relationshipToPrimaryClient2,
          },
      ]),
    };
  });
}
