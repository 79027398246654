import Decimal from 'decimal.js';
import { useCallback } from 'react';

import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useModalState } from '@/hooks/useModalState';
import {
  DispositiveProvisionsModalDetails,
  DispositiveProvisionsModalDetailsType,
} from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';

import { getTestamentaryEntitySummaryData } from '../../TestamentaryEntitySummaryPanel/useTestamentaryEntitySummaryData';
import { isEditablePrimaryClientNode } from '../EstateWaterfallSummaryTileSummaryPanel.utils';
import { EstateWaterfallSummaryTileSummaryPanelNodeWithVizNode } from '../types';

export function useEditModals(props: {
  householdId: string;
  firstPrimaryClientDeathId: string;
  primaryClients: ContextPrimaryClient[];
  primaryClientIds: Set<string>;
}) {
  const {
    householdId,
    firstPrimaryClientDeathId,
    primaryClients,
    primaryClientIds,
  } = props;
  const { navigate } = useNavigateToRoute();

  const [clientProfileState, clientProfileActions] = useModalState<string>();
  const [testamentaryEntityState, testamentaryEntityActions] = useModalState<{
    testamentaryEntityId: string;
    deadClientIds: string[];
    dyingPrimaryClientId: string;
  }>();
  const [clientOrganizationState, clientOrganizationActions] =
    useModalState<string>();
  const [dispositiveProvisionState, dispositiveProvisionActions] =
    useModalState<DispositiveProvisionsModalDetails>();

  const handleEdit = useCallback(
    (node: EstateWaterfallSummaryTileSummaryPanelNodeWithVizNode) => {
      if (node.vizNode.node.__typename === 'ClientProfile') {
        clientProfileActions.openModal(node.vizNode.id);
      } else if (node.vizNode.node.__typename === 'ClientOrganization') {
        clientOrganizationActions.openModal(node.id);
      } else if (node.vizNode.node.__typename === 'TestamentaryEntity') {
        const { gstStatus, grantorDeath } = node.vizNode.node;
        const primaryClientDeathId = grantorDeath?.id ?? '';
        const { deadPrimaryClientIds } = getTestamentaryEntitySummaryData({
          primaryClients: primaryClients,
          primaryClientDeathId,
          firstPrimaryClientDeathId,
          gstStatus: gstStatus ?? null,
          inEstateStatus: node.vizNode.inEstateStatus,
        });

        testamentaryEntityActions.openModal({
          testamentaryEntityId: node.id,
          deadClientIds: deadPrimaryClientIds,
          dyingPrimaryClientId: primaryClientDeathId,
        });
      } else if (node.vizNode.node.__typename === 'Entity') {
        navigate(ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS, {
          householdId,
          entityId: node.id,
        });
      }
    },
    [
      clientProfileActions,
      clientOrganizationActions,
      primaryClients,
      firstPrimaryClientDeathId,
      testamentaryEntityActions,
      navigate,
      householdId,
    ]
  );

  const handleManageDispositions = useCallback(
    (node: EstateWaterfallSummaryTileSummaryPanelNodeWithVizNode) => {
      if (node.vizNode.node.__typename === 'Entity') {
        dispositiveProvisionActions.openModal({
          type: DispositiveProvisionsModalDetailsType.ENTITY,
          entityId: node.id,
          entityDisplayName: node.name,
          entityType: getEntityTypeFromEntityKind(node.vizNode.node.entityKind),
          entitySubtypeId: node.vizNode.node.subtype.id,
          firstDeathClientId: firstPrimaryClientDeathId,
        });
      } else if (isEditablePrimaryClientNode(node.vizNode, primaryClientIds)) {
        dispositiveProvisionActions.openModal({
          type: DispositiveProvisionsModalDetailsType.CLIENT_PROFILE,
          clientProfileId: node.id,
          firstDeathClientId: firstPrimaryClientDeathId,
          displayName: node.name,
          valueToDistribute:
            node.vizNode.valueBeforeTransfers ?? new Decimal(0),
        });
      } else if (node.vizNode.node.__typename === 'TestamentaryEntity') {
        dispositiveProvisionActions.openModal({
          type: DispositiveProvisionsModalDetailsType.TESTAMENTARY_ENTITY,
          testamentaryEntityId: node.id,
          firstDeathClientId: firstPrimaryClientDeathId,
          entityDisplayName: node.name,
        });
      }
    },
    [dispositiveProvisionActions, firstPrimaryClientDeathId, primaryClientIds]
  );

  return {
    clientProfileModal: [clientProfileState, clientProfileActions] as const,
    testamentaryEntityModal: [
      testamentaryEntityState,
      testamentaryEntityActions,
    ] as const,
    clientOrganizationModal: [
      clientOrganizationState,
      clientOrganizationActions,
    ] as const,
    dispositiveProvisionModal: [
      dispositiveProvisionState,
      dispositiveProvisionActions,
    ] as const,
    handleEdit,
    handleManageDispositions,
  };
}
