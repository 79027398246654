import { Box, Stack, Typography } from '@mui/material';
import { orderBy } from 'lodash';
import { useMemo, useState } from 'react';

import { Divider } from '@/components/Divider';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Loader } from '@/components/progress/Loader/Loader';
import { useReportError } from '@/hooks/useReportError';
import { FlagEnablementStatus } from '@/types/schema';
import { getEnvironment } from '@/utils/environmentUtils';
import { formatDateToMMDDYY } from '@/utils/formatting/dates';

import {
  useAdminFeatureFlagsPageQuery,
  useSetFeatureFlagStatusMutation,
} from './graphql/AdminFeatureFlagsPage.generated';
import { TenantConfigurationForm } from './TenantConfigurationForm/TenantConfigurationForm';

export interface FeatureFlagItemProps {
  name: string;
  description?: string | null;
  createdAt: Date;
  id: string;
  enabledStatus: FlagEnablementStatus;
}

function FeatureFlagItem({
  name,
  description,
  createdAt,
  enabledStatus,
}: FeatureFlagItemProps) {
  const [enablementStatus, setEnablementStatus] =
    useState<FlagEnablementStatus>(enabledStatus);
  const { showFeedback, createErrorFeedback } = useFeedback();

  const [setFlagStatus, { loading, error }] = useSetFeatureFlagStatusMutation({
    onError: createErrorFeedback(
      'Failed to update the flag status. Please refresh and try again.'
    ),
    onCompleted: () => {
      showFeedback(
        `Successfully updated ${name} to "${enablementStatus}" for ${getEnvironment()}. It will take up to 30 seconds for this change to take effect.`,
        {
          variant: 'success',
        }
      );
    },
  });

  useReportError('error updating feature flag status', error);

  const handleToggleStatus = (newStatus: FlagEnablementStatus) => {
    setEnablementStatus(newStatus);
    void setFlagStatus({
      variables: {
        name,
        status: newStatus,
      },
    });
  };

  const featureFlagEnablementOptions = useMemo(
    () => [
      {
        display: 'Off for all',
        value: FlagEnablementStatus.Off,
      },
      {
        display: 'On for tenant',
        value: FlagEnablementStatus.OnForTenant,
        // the user can't move from "on for env" directly to "on for tenant" because the implications of that are ambiguous.
        // we force them to first to go "off", then "on for tenant"
        buttonProps: {
          disabled: enablementStatus === FlagEnablementStatus.OnForEnv,
        },
      },
      {
        display: 'On for all',
        value: FlagEnablementStatus.OnForEnv,
      },
    ],
    [enablementStatus]
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={4}
    >
      <Box flexGrow={1}>
        <Typography mb={1} component="div" variant="label">
          {name}
        </Typography>
        {description && (
          <Typography mb={0.5} component="div" variant="subtitle1">
            {description}
          </Typography>
        )}
        <Typography component="div" variant="subtitle2">
          Added on {formatDateToMMDDYY(createdAt)}
        </Typography>
      </Box>
      <Box minWidth={350}>
        <ButtonGroup
          label=""
          disabled={loading}
          options={featureFlagEnablementOptions}
          value={enablementStatus}
          onChange={(_, value) => handleToggleStatus(value)}
        />
      </Box>
    </Stack>
  );
}

export function AdminFeatureFlagsPage() {
  const { data, error, loading } = useAdminFeatureFlagsPageQuery();

  useReportError('failed to load feature flags on admin page', error);
  const orderedFlagEvaluationResults = orderBy(
    data?.featureFlagEvaluationResult ?? [],
    (result) => result.flag.flagName
  );

  return (
    <Stack spacing={3}>
      <HeaderCard heading="Configurations">
        <TenantConfigurationForm />
      </HeaderCard>
      <HeaderCard heading="Feature flags">
        <Box mb={4}>
          <Callout severity="info-high">
            <p>There are some important things to note about feature flags:</p>
            <ul>
              <li>
                The feature flag cache only refreshes every 30 seconds, so it
                may take up to 30 seconds for a change to take effect. (You will
                also need to refresh the app for the change to take effect
                locally.)
              </li>
              <li>
                You cannot change a flag from &quot;On for all&quot; to &quot;On
                for tenant&quot; right away, because the implications of doing
                that are ambiguous. You must first move the flag to
                &quot;OFF&quot;, which disables the flag for all tenants, and
                then re-enable it for this tenant.
              </li>
            </ul>
          </Callout>
        </Box>
        <Stack spacing={3} width="100%" px={3} divider={<Divider />}>
          {loading && <Loader />}
          {orderedFlagEvaluationResults.map((result) => {
            const { flag } = result;
            return (
              <FeatureFlagItem
                key={flag.id}
                name={flag.flagName}
                description={flag.flagDescription}
                createdAt={flag.createdAt}
                enabledStatus={result.flagEnablementStatus}
                id={flag.id}
              />
            );
          })}
        </Stack>
      </HeaderCard>
    </Stack>
  );
}
