import { EntityCardProps } from '../entityDetailPageUtils';
import { EntityValuation } from '../EntityValuation/EntityValuation';

export function GenericEntityAssetsCard({
  entity,
  onUpdate,
  isPanelView,
}: EntityCardProps) {
  return (
    <EntityValuation
      entity={entity}
      onAfterUpdate={onUpdate}
      variant={isPanelView ? 'plain' : 'card'}
    />
  );
}
