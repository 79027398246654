import { useMatches } from 'react-router-dom';

import {
  OnboardingStatus,
  useOnboardingStatusForHousehold,
} from '@/modules/householdOnboardingSidebar/hooks/useShouldDisplayHouseholdOnboardingSidebar';
import { HouseholdOnboardingSidebar } from '@/modules/householdOnboardingSidebar/HouseholdOnboardingSidebar';
import { RecentEntitiesSidebar } from '@/modules/recents/RecentEntitiesSidebar/RecentEntitiesSidebar';
import { AugmentedHandle, ROUTE_KEYS } from '@/navigation/constants';
import { Match } from '@/navigation/RouteWatcher';
import { useRouteKeyMatch } from '@/navigation/useRouteKeyMatch';

import { useUnguardedHouseholdDetailsContext } from '../household/contexts/householdDetails.context';

function useIsSidebarDisabledForCurrentRoute(): boolean {
  const matches = useMatches() as Match[];

  return matches.some(
    (match) => !!(match.handle as AugmentedHandle)?.features?.hideGlobalSidebar
  );
}

export function GlobalSidebar() {
  const sidebarDisabledForCurrentRoute = useIsSidebarDisabledForCurrentRoute();
  const { householdId } = useUnguardedHouseholdDetailsContext();

  const onboardingStatus = useOnboardingStatusForHousehold();

  const isOnHouseholdList = useRouteKeyMatch(ROUTE_KEYS.HOUSEHOLDS_LIST);
  const isOnHouseholdOverviewRoute = useRouteKeyMatch(
    ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW
  );

  const sidebarToShowOnHouseholdOverview =
    onboardingStatus === OnboardingStatus.InProgress ? 'onboarding' : 'recents';

  if (sidebarDisabledForCurrentRoute) return null;

  if (isOnHouseholdList) {
    return (
      <RecentEntitiesSidebar
        showRecentClients
        data-testid="global-sidebar-recents"
      />
    );
  }

  if (
    isOnHouseholdOverviewRoute &&
    sidebarToShowOnHouseholdOverview === 'recents'
  ) {
    return (
      <RecentEntitiesSidebar
        data-testid="global-sidebar-recents"
        showRecentClients={false}
      />
    );
  }

  if (
    isOnHouseholdOverviewRoute &&
    sidebarToShowOnHouseholdOverview === 'onboarding'
  ) {
    return (
      <HouseholdOnboardingSidebar
        data-testid="global-sidebar-onboarding"
        allowRecents={false}
      />
    );
  }

  if (householdId && onboardingStatus === OnboardingStatus.InProgress) {
    return (
      <HouseholdOnboardingSidebar
        data-testid="global-sidebar-onboarding"
        allowRecents
      />
    );
  }

  return null;
}
