import { Stack } from '@mui/material';

import { Divider } from '@/components/Divider';
import { Card } from '@/components/layout/Card/Card';
import { SidePanel } from '@/components/modals/SidePanel';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { OwnedBusinessEntitiesList } from '@/modules/entities/details/shared/OwnedBusinessEntitiesList';
import { diagnostics } from '@/utils/diagnostics';

import { SummaryPanelLoader } from '../components/SummaryPanelLoader';
import { useBusinessEntitiesSummaryPanelQuery } from './graphql/BusinessEntitiesSummaryPanel.generated';

export interface BusinessEntitiesSummaryPanelProps {
  householdId: string;
  grantorClientProfileId: string;
  onClose: () => void;
}

function useOwnedBusinessEntitiesData(grantorClientProfileId: string) {
  const { showFeedback } = useFeedback();
  const { data, ...queryProps } = useBusinessEntitiesSummaryPanelQuery({
    variables: { grantorClientProfileId },
    onError: (error) => {
      showFeedback(
        `Couldn't get information. Please refresh the page and try again.`
      );
      diagnostics.error(
        `failed to fetch grantor profile for business entities`,
        error,
        {
          grantorClientProfileId,
        }
      );
    },
  });

  if (!data) return { ...queryProps, ownershipStakes: [] };

  if (data.node?.__typename !== 'ClientProfile') {
    throw new Error(`Invalid data`);
  }

  return {
    ...queryProps,
    ownershipStakes: data?.node?.ownedOwnershipStakes ?? [],
  };
}

export function BusinessEntitiesSummaryPanel({
  grantorClientProfileId,
  onClose,
}: BusinessEntitiesSummaryPanelProps) {
  const { ownershipStakes, loading } = useOwnedBusinessEntitiesData(
    grantorClientProfileId
  );

  return (
    <SidePanel.Panel>
      <SidePanel.Header title="Business ownership" onClose={onClose} />
      <SidePanel.Content>
        {loading ? (
          <SummaryPanelLoader />
        ) : (
          <SidePanel.Section>
            <Stack divider={<Divider />} sx={{ textAlign: 'left' }}>
              <Card variant="filled-callout">
                <OwnedBusinessEntitiesList
                  ownedStakes={ownershipStakes}
                  ownerKind="individual"
                />
              </Card>
            </Stack>
          </SidePanel.Section>
        )}
      </SidePanel.Content>
    </SidePanel.Panel>
  );
}
