import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { Path, useWatch } from 'react-hook-form';

import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';
import { BeneficiaryPowerOfAppointmentPower } from '@/types/schema';

import { POWER_OF_APPOINTMENT_ITEMS } from './EntityBeneficiariesSubform.constants';
import { BeneficiaryFormPowerOfAppointment } from './EntityBeneficiariesSubform.types';

type PowerOfAppointmentFormPaths = Path<BeneficiaryFormPowerOfAppointment>;

interface PowerOfAppointmentFormProps {
  fieldName: 'beneficiaryFormPowerOfAppointment';
}

export function PowerOfAppointmentForm({
  fieldName,
}: PowerOfAppointmentFormProps) {
  const { control, setValue } =
    useFormContext<BeneficiaryFormPowerOfAppointment>();

  const isHasPowerOfAppointmentToggled = useWatch({
    name: `${fieldName}._hasPowerOfAppointment` as '_hasPowerOfAppointment' satisfies PowerOfAppointmentFormPaths,
    control,
  });

  const selectedPower = useWatch({
    name: `${fieldName}.power` as 'power' satisfies PowerOfAppointmentFormPaths,
    control,
  });

  const isLimitedPowerSelected =
    selectedPower === BeneficiaryPowerOfAppointmentPower.Special;

  const isOtherPowerSelected =
    selectedPower === BeneficiaryPowerOfAppointmentPower.Other;

  useEffect(
    function initPower() {
      if (isHasPowerOfAppointmentToggled && !selectedPower) {
        setValue(
          `${fieldName}.power` as 'power' satisfies PowerOfAppointmentFormPaths,
          BeneficiaryPowerOfAppointmentPower.General
        );
      }
    },
    [fieldName, isHasPowerOfAppointmentToggled, selectedPower, setValue]
  );

  return (
    <Card
      variant={isHasPowerOfAppointmentToggled ? 'filled-dark' : 'filled'}
      sx={{
        p: 2,
        borderRadius: '6px',
      }}
    >
      <Stack spacing={1}>
        <FormAwareSwitch<BeneficiaryFormPowerOfAppointment>
          variant="dark"
          label="Has power of appointment"
          labelPosition="right"
          fieldName={
            `${fieldName}._hasPowerOfAppointment` as '_hasPowerOfAppointment' satisfies PowerOfAppointmentFormPaths
          }
          control={control}
        />
        {isHasPowerOfAppointmentToggled && (
          <Stack pl={7} spacing={1}>
            <Box maxWidth={250}>
              <FormAwareSelectInput<BeneficiaryFormPowerOfAppointment>
                required
                label="Power of appointment"
                hideLabel
                fieldName={
                  `${fieldName}.power` as 'power' satisfies PowerOfAppointmentFormPaths
                }
                control={control}
                options={POWER_OF_APPOINTMENT_ITEMS}
              />
            </Box>
            {(isLimitedPowerSelected || isOtherPowerSelected) && (
              <FormAwareTextInput<BeneficiaryFormPowerOfAppointment>
                label="Notes"
                hideLabel
                placeholder="Specify details"
                multiline
                fieldName={
                  `${fieldName}.powerOtherNote` as 'powerOtherNote' satisfies PowerOfAppointmentFormPaths
                }
                control={control}
                rows={2}
              />
            )}
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
