import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { HeaderList } from '@/components/lists/HeaderList/HeaderList';
import { RichListItemRow } from '@/components/lists/RichListItem/RichListItemRow';
import { UnstyledNavLink } from '@/components/navigation/UnstyledNavLink';
import { EmptyListItem } from '@/components/typography/EmptyListItem';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';

export interface AssignedClientListProps {
  clients: {
    id: string;
    displayName: string;
  }[];
}

export function AssignedClientList({ clients }: AssignedClientListProps) {
  return (
    <HeaderList heading="Clients this user has access to">
      {clients.length === 0 && (
        <EmptyListItem>No clients assigned</EmptyListItem>
      )}
      {clients.map((client) => {
        const clientPath = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
          { householdId: client.id }
        );
        return (
          <UnstyledNavLink to={clientPath} key={client.id}>
            <RichListItemRow
              heading={client.displayName}
              RightContent={
                <ChevronRightIcon size={20} color={COLORS.GRAY[400]} />
              }
            />
          </UnstyledNavLink>
        );
      })}
    </HeaderList>
  );
}
