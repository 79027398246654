import { SummaryBeneficiaryKindv2 } from '@/types/schema';

export interface FormType {
  selectedTrusts: { id: string; selected: boolean }[];
  selectedIndividuals: { id: string; selected: boolean }[];
}

export const TABS = [
  { display: 'Trusts', value: 'trusts' },
  { display: 'Individual beneficiaries', value: 'individuals' },
] as const;

export const TAB_OPTIONS = TABS.slice();

export interface Option {
  id: string;
  displayName: string;
  description: string;
  type: SummaryBeneficiaryKindv2;
  summary: string;
}
