import { AppBar as MuiAppBar, Stack, Toolbar } from '@mui/material';
import { ComponentProps, PropsWithChildren, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  Logomark,
  LogomarkSize,
  LogomarkVariant,
} from '@/components/brand/Logomark/Logomark';
import { UnstyledNavLink } from '@/components/navigation/UnstyledNavLink';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { WithClasses } from '@/styles/types';
import { zIndexes } from '@/styles/zIndexes';

import { CLIENT_SIDEBAR_WIDTH } from '../../Sidebar/ClientDetailsSidebar';

export const appbarHeight = '56px';

const useStyles = makeStyles()((theme) => ({
  appBar: {
    height: appbarHeight,
    minHeight: appbarHeight,
    zIndex: zIndexes.MENU,
  },
  toolBar: {
    backgroundColor: theme.palette.primary.dark,
    paddingRight: theme.spacing(2),
  },
}));

export type AppBarProps = PropsWithChildren<{
  muiAppBarProps?: ComponentProps<typeof MuiAppBar>;
  classes?: WithClasses<typeof useStyles>;
  showLogomark?: boolean;
}>;

export function AppBar({
  muiAppBarProps = {},
  classes: externalClasses,
  showLogomark = true,
  children,
}: AppBarProps) {
  const { classes } = useStyles(undefined, {
    props: { classes: externalClasses },
  });
  const paddingLeft = useMemo(() => {
    const padding = showLogomark ? 0 : 2;

    return {
      xs: padding,
      sm: padding,
      md: padding,
      lg: padding,
      xl: padding,
    };
  }, [showLogomark]);

  return (
    <MuiAppBar position="fixed" className={classes.appBar} {...muiAppBarProps}>
      <Toolbar
        disableGutters
        className={classes.toolBar}
        sx={{
          paddingLeft,
          minHeight: {
            xs: appbarHeight,
            sm: appbarHeight,
            md: appbarHeight,
            lg: appbarHeight,
            xl: appbarHeight,
          },
        }}
      >
        <Stack direction="row" alignItems="center" width="100%">
          {showLogomark && (
            <Stack
              direction="row"
              width={`${CLIENT_SIDEBAR_WIDTH}px`}
              justifyContent="center"
              height={appbarHeight}
              alignItems="center"
            >
              <UnstyledNavLink
                to={getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLDS_LIST, {})}
              >
                <Logomark
                  size={LogomarkSize.Wide}
                  variant={LogomarkVariant.Secondary}
                  imageSx={{
                    maxHeight: 48,
                    maxWidth: 140,
                    display: 'flex',
                  }}
                />
              </UnstyledNavLink>
            </Stack>
          )}
          {children}
        </Stack>
      </Toolbar>
    </MuiAppBar>
  );
}
