import { useMemo, useState } from 'react';

import { LinkExternal01Icon } from '@/components/icons/LinkExternal01Icon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useModalState } from '@/hooks/useModalState';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { LogNewGiftModal } from '../../LogNewGiftModal/LogNewGiftModal';
import { RecordExemptionUsedModal } from '../../RecordExemptionUsedModal/RecordExemptionUsedModal';

export function useLifetimeExemptionCardMenuItems(householdId: string) {
  const [
    { isModalOpen: isNewGiftModalOpen },
    { closeModal: closeNewGiftModal, openModal: openNewGiftModal },
  ] = useModalState();
  const [
    { isModalOpen: isRecordExemptionUsedModalOpen },
    {
      closeModal: closeRecordExemptionUsedModal,
      openModal: openRecordExemptionUsedModal,
    },
  ] = useModalState();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean | undefined>();

  const menuItems = useMemo(
    () => [
      <MenuItem
        key="log-new-gift"
        label="Add gift"
        onClick={() => {
          setIsMenuOpen(false);
          openNewGiftModal();
        }}
      />,
      <MenuItem
        key="record-new-exemption"
        label="Record exemption used"
        onClick={() => {
          setIsMenuOpen(false);
          openRecordExemptionUsedModal();
        }}
        muiMenuItemProps={{
          divider: true,
        }}
      />,
      <MenuItem
        key="open-in-new-tab"
        icon={<LinkExternal01Icon size={18} />}
        label="Open in new tab"
        onClick={() => {
          setIsMenuOpen(false);
          window.open(
            getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_DETAILS_GIFTING, {
              householdId,
            }),
            '_blank'
          );
        }}
      />,
    ],
    [householdId, openNewGiftModal, openRecordExemptionUsedModal]
  );

  const menuItemModals = useMemo(() => {
    return (
      <>
        <LogNewGiftModal
          isOpen={isNewGiftModalOpen}
          onClose={closeNewGiftModal}
          householdId={householdId}
        />
        <RecordExemptionUsedModal
          isOpen={isRecordExemptionUsedModalOpen}
          onClose={closeRecordExemptionUsedModal}
          householdId={householdId}
        />
      </>
    );
  }, [
    householdId,
    closeNewGiftModal,
    closeRecordExemptionUsedModal,
    isNewGiftModalOpen,
    isRecordExemptionUsedModalOpen,
  ]);

  return {
    menuItems,
    isNewGiftModalOpen,
    closeNewGiftModal,
    isRecordExemptionUsedModalOpen,
    closeRecordExemptionUsedModal,
    menuItemModals,
    isMenuOpen,
  };
}
