import { compact, first, isEmpty, partition } from 'lodash';

import { formatCompactValue } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryTiles/EstateWaterfallSummaryTiles.utils';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { RowData, RowValue } from './BalanceSheetTable.constants';
import {
  BalanceSheetTable_BalanceSheetFragment,
  BalanceSheetTable_BalanceSheetRowFragment,
  BalanceSheetTable_BalanceSheetRowValueFragment,
} from './graphql/BalanceSheetTable.fragments.generated';

function buildBalanceSheetRow(
  // we do this instead of doing the framgnet directly because there's a single level of a recursive call to children
  // and the fragment can't recurse upon itself
  row: BalanceSheetTable_BalanceSheetFragment['rows'][number],
  parentPath: string[]
): RowData {
  return {
    id: row.ID,
    name: {
      lineOne: row.display,
      lineTwo: row.description || undefined,
    },
    path: [...parentPath, row.ID],
    hasChildren: !isEmpty(row.children),
    values: getNormalizedValues(row.values),
    variant: row.type,
    ...getAssociatedNodeIds(row),
  };
}

/**
 * @description Extracts the entity and liability IDs from the associated nodes of a balance sheet row. This is used to power the
 * popover that lets you quickly access the details of an entity or liability from the balance sheet.
 */
function getAssociatedNodeIds(row: BalanceSheetTable_BalanceSheetRowFragment): {
  entityIds?: string[];
  liabilityId?: string;
} {
  if (!row.associatedNodes) return {};

  // make ts happy
  const nodes = compact(
    row.associatedNodes.map((n) => {
      if (!n) return null;
      if (!('id' in n)) return null;
      return n;
    })
  );

  const [entityNodes, liabilityNodes] = partition(nodes, (n) => {
    if (!('id' in n)) return false;
    return getPulidKind(n.id) === PulidKind.Entity;
  });

  return {
    entityIds: entityNodes.map((n) => n.id),
    liabilityId: first(liabilityNodes.map((n) => n.id)),
  };
}

function getNormalizedValues(
  values: BalanceSheetTable_BalanceSheetRowValueFragment[]
): Record<string, RowValue> {
  const normalizedValues: Record<string, RowValue> = {};

  values.forEach((value) => {
    let description: string | undefined = undefined;
    if (value.secondaryValue && value.secondaryValueDescription) {
      description = `${formatCompactValue(value.secondaryValue)} ${value.secondaryValueDescription}`;
    }
    normalizedValues[value.columnID] = {
      value: value.value,
      description,
    };
  });

  return normalizedValues;
}

export function mapDataToRows(
  balanceSheet: BalanceSheetTable_BalanceSheetFragment | null,
  { isViewOnly }: { isViewOnly: boolean }
): RowData[] {
  const rows: RowData[] = [];

  function recurseRow(
    category: BalanceSheetTable_BalanceSheetFragment['rows'][number],
    parentPath: string[]
  ) {
    const categoryRow = buildBalanceSheetRow(category, parentPath);
    rows.push(categoryRow);

    if (category.children && !isViewOnly) {
      category.children.forEach((child) => {
        recurseRow(child, categoryRow.path);
      });
    }
  }

  (balanceSheet?.rows ?? []).forEach((row) => {
    recurseRow(row, []);
  });

  return rows;
}
