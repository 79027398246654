import { EntityKind } from '@/types/schema';

export const SUPPORTED_HYPOTHETICAL_ENTITY_KINDS: EntityKind[] = [
  EntityKind.IrrevocableTrust,
  EntityKind.RevocableTrust,
  EntityKind.SlatTrust,
  EntityKind.GratTrust,
  EntityKind.IlitTrust,
  EntityKind.QprtTrust,
  EntityKind.IndividualPersonalAccount,
  EntityKind.JointPersonalAccount,
  EntityKind.RetirementPersonalAccount,
  EntityKind.QualifiedTuitionPersonalAccount,
  EntityKind.CrtTrust,
  EntityKind.CltTrust,
  EntityKind.DonorAdvisedFund,
  EntityKind.PrivateFoundation,
];

export type HypotheticalDraftEntityType =
  | typeof EntityKind.IrrevocableTrust
  | typeof EntityKind.RevocableTrust
  | typeof EntityKind.SlatTrust
  | typeof EntityKind.GratTrust
  | typeof EntityKind.IlitTrust
  | typeof EntityKind.QprtTrust
  | typeof EntityKind.IndividualPersonalAccount
  | typeof EntityKind.JointPersonalAccount
  | typeof EntityKind.RetirementPersonalAccount
  | typeof EntityKind.QualifiedTuitionPersonalAccount
  | typeof EntityKind.CrtTrust
  | typeof EntityKind.CltTrust
  | typeof EntityKind.DonorAdvisedFund
  | typeof EntityKind.PrivateFoundation;
