import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { getBackgroundColor } from '@/components/charts/chartAccessories';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';

function SubcategoryLabel({
  label,
  value,
  color,
}: {
  label: string;
  value: string;
  color: string;
}) {
  return (
    <Box textAlign="right">
      <Typography color={color} variant="subtitle2">
        {label}
      </Typography>
      <Typography color={color} variant="h5" component="span">
        {value}
      </Typography>
    </Box>
  );
}

function BarChartBox({
  height,
  colors,
}: {
  colors: [string, string];
  height: number;
}) {
  return (
    <Box
      sx={{
        minHeight: 2,
        height: `${height}%`,
        background: getBackgroundColor(colors[0], colors[1]),
        width: 100,
      }}
    ></Box>
  );
}

interface TotalRowProps {
  totalAssetValue: Decimal;
}

function TotalRow({ totalAssetValue }: TotalRowProps) {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{ borderTop: `solid ${COLORS.NAVY[500]} 2px` }}
      pt={1}
      spacing={1}
    >
      <Box textAlign="right" width="50%">
        <Typography color="black" variant="subtitle2">
          Total asset value
        </Typography>
      </Box>
      <Box
        data-testid="projectedTotalAssetValue"
        textAlign="center"
        width="50%"
      >
        <Typography color="black" variant="h5" component="span">
          {formatCurrency(totalAssetValue)}
        </Typography>
      </Box>
    </Stack>
  );
}

const GRAPH_COLORS = {
  TEAL: 'TEAL',
  NAVY: 'NAVY',
} as const;
type GraphColor = (typeof GRAPH_COLORS)[keyof typeof GRAPH_COLORS];

type GraphColorDefinitions = Record<
  GraphColor,
  {
    text: string;
    radialGradient: [string, string];
  }
>;

const GRAPH_COLOR_DEFINITIONS: GraphColorDefinitions = {
  [GRAPH_COLORS.TEAL]: {
    text: COLORS.TEAL[900],
    radialGradient: [COLORS.TEAL[200], COLORS.TEAL[400]],
  },
  [GRAPH_COLORS.NAVY]: {
    text: COLORS.NAVY[600],
    radialGradient: [COLORS.NAVY[200], COLORS.NAVY[400]],
  },
};

interface BarDefinition {
  value: Decimal;
  label: string;
  color: GraphColor;
  heightPercent: number;
}

function getBarDefinitions(
  transferredOutOfEstate: Decimal,
  totalAnnuityPayments: Decimal,
  isRollingGRAT: boolean
): BarDefinition[] {
  const total = transferredOutOfEstate.plus(totalAnnuityPayments);
  return [
    {
      value: transferredOutOfEstate,
      label: 'Transferred out of estate',
      color: GRAPH_COLORS.TEAL,
      heightPercent: transferredOutOfEstate
        .dividedBy(total)
        .times(100)
        .toNumber(),
    },
    {
      value: totalAnnuityPayments,
      // Distinguish here between rolling or not
      // Since annuities behave a bit differently
      label: isRollingGRAT
        ? 'Total annuities returned to estate'
        : 'Total annuity payments',
      color: GRAPH_COLORS.NAVY,
      heightPercent: totalAnnuityPayments
        .dividedBy(total)
        .times(100)
        .toNumber(),
    },
  ];
}

export interface ProjectedValueVisualizationProps {
  totalAssetValue: Decimal;
  transferredOutOfEstate: Decimal;
  totalAnnuityPayments: Decimal;
  isRollingGRAT: boolean;
}

export function ProjectedValueVisualization({
  totalAssetValue,
  transferredOutOfEstate,
  totalAnnuityPayments,
  isRollingGRAT,
}: ProjectedValueVisualizationProps) {
  const barDefinitions = getBarDefinitions(
    transferredOutOfEstate,
    totalAnnuityPayments,
    isRollingGRAT
  );
  return (
    <Stack data-testid="ProjectedValueVisualization">
      <Stack direction="row" spacing={1}>
        <Stack
          width="50%"
          spacing="1px"
          alignItems="flex-end"
          justifyContent="end"
        >
          {barDefinitions.map(({ label, value, color, heightPercent }) => (
            <Box
              key={label}
              height={`${heightPercent}%`}
              minHeight={40}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <SubcategoryLabel
                label={label}
                value={formatCurrency(value)}
                color={GRAPH_COLOR_DEFINITIONS[color].text}
              />
            </Box>
          ))}
        </Stack>
        <Stack
          width="50%"
          height={175}
          alignItems="center"
          spacing="1px"
          justifyContent="end"
        >
          {barDefinitions.map(({ heightPercent, color }, i) => (
            <BarChartBox
              key={i}
              colors={GRAPH_COLOR_DEFINITIONS[color].radialGradient}
              height={heightPercent}
            />
          ))}
        </Stack>
      </Stack>
      <TotalRow totalAssetValue={totalAssetValue} />
    </Stack>
  );
}
