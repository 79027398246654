import { useEffect } from 'react';
import { UseFormReset } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { TenantApplicationConfigurationUserSessionInactivityTimeout } from '@/types/schema';

import { useTenantConfigurationFormQuery } from '../graphql/TenantConfigurationForm.generated';
import { TenantConfigurationFormShape } from '../TenantConfigurationForm.constants';

export function useTenantConfigurationFormDefaultValues(
  reset: UseFormReset<TenantConfigurationFormShape>
) {
  // const { reset } = useFormContext<TenantConfigurationFormShape>();
  const { createErrorFeedback } = useFeedback();

  const { data, ...queryProps } = useTenantConfigurationFormQuery({
    onError: createErrorFeedback(
      'There was an error fetching tenant configuration details. Please refresh the page to try again.'
    ),
  });

  useEffect(() => {
    if (!data) return;
    const formData = {
      disableAllAIFeatures:
        data.tenantApplicationConfiguration?.disableAiCapabilities || false,
      sessionInactivityTimeout:
        data.tenantApplicationConfiguration?.userSessionInactivityTimeout ||
        TenantApplicationConfigurationUserSessionInactivityTimeout.Off,
    } as const satisfies TenantConfigurationFormShape;
    reset(formData);
  }, [data, reset]);

  return {
    data,
    ...queryProps,
  };
}
