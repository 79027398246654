import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { ArrayPath, FieldArrayWithId, useWatch } from 'react-hook-form';

import { AccordionContext } from '@/components/Accordion/Accordion.context';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { FormAwareCardRepeater } from '@/components/form/formAwareInputs/FormAwareCardRepeater/FormAwareCardRepeater';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useAISuggestionsEnabled } from '@/modules/aiSuggestions/hooks/useAISuggestionsEnabled';

import { useEntityDetailsContext } from '../contexts/entityDetails/entityDetails.context';
import { CharitableBeneficiaryFormBody } from './CharitableBeneficiaryFormBody';
import { defaultBeneficiary } from './EntityBeneficiariesSubform.defaults';
import {
  EntityBeneficiariesFormBeneficiary,
  EntityBeneficiariesSubformFields,
  EntityBeneficiariesSubformProps,
  EntityBeneficiariesSubformSubformPath,
} from './EntityBeneficiariesSubform.types';
import { EntityBeneficiaryFormBody } from './EntityBeneficiaryFormBody';
import { useBeneficiariesFormOptionsQuery } from './graphql/Beneficiaries.generated';
import { useGetBeneficiaryOptions } from './useGetBeneficiaryOptions';

export function UI(props: EntityBeneficiariesSubformProps) {
  const { householdId, namespace, variant, entityType } = props;
  const { entityId } = useEntityDetailsContext();
  const { control } = useFormContext<EntityBeneficiariesSubformFields>();
  const { createErrorFeedback } = useFeedback();
  const shouldGroupDuplicates = useAISuggestionsEnabled(entityType);

  const isCharitableBeneficiarySubform =
    variant === 'leadBeneficiary' || variant === 'incomeBeneficiary';

  let secondNamespace:
    | 'beneficiaries'
    | 'lifetimeBeneficiaries'
    | 'remainderBeneficiaries'
    | 'leadBeneficiary'
    | 'incomeBeneficiary' = 'beneficiaries';

  if (namespace === 'lifetimeBeneficiariesSubform') {
    secondNamespace = 'lifetimeBeneficiaries' as const;
  }

  if (namespace === 'remainderBeneficiariesSubform') {
    secondNamespace = 'remainderBeneficiaries' as const;
  }

  if (namespace === 'leadBeneficiarySubform') {
    secondNamespace = 'leadBeneficiary' as const;
  }

  if (namespace === 'incomeBeneficiarySubform') {
    secondNamespace = 'incomeBeneficiary' as const;
  }

  const namespacePath =
    `${namespace}.${secondNamespace}` as const satisfies EntityBeneficiariesSubformSubformPath;

  const subformValues = useWatch({
    name: namespacePath,
    control,
  });

  const beneficiariesAsList: EntityBeneficiariesFormBeneficiary[] | undefined =
    (() => {
      if (!subformValues) return undefined;

      if (Array.isArray(subformValues)) {
        return subformValues;
      }

      return [subformValues];
    })();

  const {
    data,
    loading: loadingFormOptions,
    error,
  } = useBeneficiariesFormOptionsQuery({
    variables: {
      whereAdvisorClients: {
        id: householdId,
      },
      whereEntities: {
        hasHouseholdWith: [
          {
            id: householdId,
          },
        ],
      },
    },
    onError: createErrorFeedback(
      "We weren't able to initialize this form. Please refresh the page and try again."
    ),
  });

  const { options: beneficiaryOptions } = useGetBeneficiaryOptions(
    entityId,
    data,
    beneficiariesAsList
  );

  useReportError('failed to fetch beneficiaries form options', error);

  const [closedAccordionIds, setClosedAccordionIds] = useState<string[]>([]);

  const handleAppend = (
    fields: FieldArrayWithId<
      EntityBeneficiariesSubformFields,
      ArrayPath<EntityBeneficiariesSubformFields>,
      '_id'
    >[]
  ) => {
    const fieldIds = fields.map((field) => field._id);
    const allExceptLast = fieldIds.slice(0, -1);
    setClosedAccordionIds(allExceptLast);
  };

  return (
    <FormFieldsDisabledProvider isDisabled={loadingFormOptions}>
      <Grid container spacing={3} columns={12}>
        <Grid item sm={12}>
          {!isCharitableBeneficiarySubform && (
            <AccordionContext.Provider value={{ closedAccordionIds }}>
              <FormAwareCardRepeater<EntityBeneficiariesSubformFields>
                allowEmpty={true}
                name={namespacePath}
                showRemove={false}
                removeButtonText="Remove beneficiary"
                shouldInit={true}
                emptyValue={defaultBeneficiary()}
                control={control}
                addAnotherItemText="Add additional beneficiary"
                onAppend={(fields) => handleAppend(fields)}
                render={(i, { remove }, _id, additionalItemRendererProps) => (
                  <EntityBeneficiaryFormBody
                    key={`beneficiary-${namespace}-${i}`}
                    control={control}
                    beneficiaryOptions={beneficiaryOptions}
                    namespace={namespace}
                    subformValues={beneficiariesAsList}
                    secondNamespace={secondNamespace}
                    householdId={householdId}
                    variant={variant}
                    i={i}
                    additionalItemRendererProps={additionalItemRendererProps}
                    splitScreen
                    remove={remove}
                    listItemId={_id}
                  />
                )}
                idField="beneficiaryId"
                shouldGroupDuplicates={shouldGroupDuplicates}
                duplicateGroupLabel="Duplicate beneficiary from AI"
                duplicateGroupCaption="Decide which beneficiary's details to retain"
              />
            </AccordionContext.Provider>
          )}
          {isCharitableBeneficiarySubform &&
            (namespacePath === 'leadBeneficiarySubform.leadBeneficiary' ||
              namespacePath ===
                'incomeBeneficiarySubform.incomeBeneficiary') && (
              <Box>
                <CharitableBeneficiaryFormBody
                  control={control}
                  beneficiaryOptions={beneficiaryOptions}
                  householdId={householdId}
                  namespace={namespacePath}
                  splitScreen
                />
              </Box>
            )}
        </Grid>
      </Grid>
    </FormFieldsDisabledProvider>
  );
}
