import { SubForm } from '@/types/subform';

import {
  BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE,
  BusinessEntityDetailsSubformNamespaceType,
  BusinessEntityDetailsSubformType,
  defaultValues,
  Fields,
  Props,
  VariantType,
} from './BusinessEntityDetailsSubform.types';
import { UI } from './BusinessEntityDetailsSubform.UI';

export class BusinessEntityDetailsSubform
  implements
    SubForm<
      BusinessEntityDetailsSubformType,
      Fields,
      BusinessEntityDetailsSubformNamespaceType,
      VariantType,
      Props
    >
{
  FormComponent: (props: Props) => JSX.Element;
  defaultValues: BusinessEntityDetailsSubformType;
  namespace: BusinessEntityDetailsSubformNamespaceType;

  constructor() {
    this.FormComponent = UI;
    this.defaultValues = defaultValues;
    this.namespace = BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE;
  }
}
