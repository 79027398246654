import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useModalState } from '@/hooks/useModalState';

import { OutOfEstatePortfolioFormShape } from '../GiftDesignerBasicInformationForm.types';
import { OutOfEstatePortfolioFormModal } from './OutOfEstatePortfolioFormModal/OutOfEstatePortfolioFormModal';

interface AddOutOfEstatePortfolioButtonProps {
  isLoadingForm: boolean;
  onSubmitSuccess: (formData: OutOfEstatePortfolioFormShape) => void;
}

export function AddOutOfEstatePortfolioButton({
  isLoadingForm,
  onSubmitSuccess,
}: AddOutOfEstatePortfolioButtonProps) {
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  return (
    <>
      <OutOfEstatePortfolioFormModal
        portfolioData={null}
        isOpen={isModalOpen}
        onClose={() => {
          closeModal();
        }}
        onSubmitSuccess={(formData) => {
          onSubmitSuccess(formData);
          closeModal();
        }}
      />
      <Button
        startIcon={PlusIcon}
        variant="secondary"
        size="sm"
        onClick={() => {
          openModal();
        }}
        loading={isLoadingForm}
      >
        Add portfolio
      </Button>
    </>
  );
}
