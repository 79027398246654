import { Stack } from '@mui/material';

import { useEstateWaterfallContext } from '../../contexts/estateWaterfall.context';
import { useWaterfallSummaryContext } from '../../contexts/waterfallSummary.context';
import { GrowthProfilesButton } from '../GrowthProfilesButton';
import { HypotheticalTransfersButton } from '../HypotheticalTransfersButton';

// these should line up with the type fields in SummaryModalState
export enum SubPanelButtonType {
  HypotheticalTransfers = 'hypotheticalTransfers',
  GrowthProfiles = 'growthProfiles',
}

export interface EstateWaterfallSubPanelButtonsProps {
  onClick: (type: SubPanelButtonType) => void;
}

export function EstateWaterfallSubPanelButtons({
  onClick,
}: EstateWaterfallSubPanelButtonsProps) {
  const { waterfall: summaryWaterfall } = useWaterfallSummaryContext();
  const { getState } = useEstateWaterfallContext();
  const { waterfall: diagramWaterfall } = getState();
  const waterfall = summaryWaterfall || diagramWaterfall;
  return (
    <Stack direction="row" spacing={2}>
      <GrowthProfilesButton
        waterfall={waterfall}
        onClick={() => onClick(SubPanelButtonType.GrowthProfiles)}
      />
      <HypotheticalTransfersButton
        waterfall={waterfall}
        onClick={() => onClick(SubPanelButtonType.HypotheticalTransfers)}
      />
    </Stack>
  );
}
