import React, { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

import { RowData } from '../AdminAssetCategoriesConfigurationPage.types';

interface ReorderableRowsContextType {
  reorderableRows: RowData[];
  setReorderableRows: Dispatch<SetStateAction<RowData[]>>;
}

export const ReorderableRowsContext =
  createContext<ReorderableRowsContextType | null>(null);

export const useReorderableRows = () =>
  useGuardedContext(ReorderableRowsContext, 'ReorderableRowsProvider');

export const ReorderableRowsProvider: React.FC<
  ReorderableRowsContextType & { children: React.ReactNode }
> = ({ children, reorderableRows, setReorderableRows }) => (
  <ReorderableRowsContext.Provider
    value={{ reorderableRows, setReorderableRows }}
  >
    {children}
  </ReorderableRowsContext.Provider>
);
