import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { Medallion } from '@/components/display/Medallion/Medallion';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { SlashCircle01Icon } from '@/components/icons/SlashCircle01Icon';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { DispositiveProvisionsTotalLineVariants } from '../../dispositiveProvisions.types';
import { copyMap } from './DispositiveProvisionsTotalLine.constants';

interface TotalLineForVariantProps {
  variant: DispositiveProvisionsTotalLineVariants;
  entityTotalMarketValue?: Decimal;
  sumOfAllDistributions?: Decimal;
}

export function TotalLineForVariant({
  variant,
  entityTotalMarketValue = new Decimal(0),
  sumOfAllDistributions = new Decimal(0),
}: TotalLineForVariantProps) {
  const isSuccessful =
    variant === DispositiveProvisionsTotalLineVariants.AccountsForAllAssets;

  const undistributedValue = entityTotalMarketValue.minus(
    sumOfAllDistributions
  );

  return (
    <Stack
      p={2}
      direction="row"
      justifyContent="space-between"
      spacing={2}
      alignItems="center"
      borderTop={`solid ${COLORS.NAVY[500]} 2px`}
      bgcolor={
        isSuccessful
          ? COLORS.FUNCTIONAL.SUCCESS[50]
          : COLORS.FUNCTIONAL.ERROR[50]
      }
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Medallion
          size={40}
          icon={
            isSuccessful ? (
              <CheckIcon size={20} />
            ) : (
              <SlashCircle01Icon size={20} />
            )
          }
          colors={{
            background: isSuccessful
              ? COLORS.FUNCTIONAL.SUCCESS.DEFAULT
              : COLORS.FUNCTIONAL.ERROR.DEFAULT,
          }}
        />
        <Stack>
          <Typography variant="h5" component="p">
            {copyMap[variant].heading}
          </Typography>
          <Typography variant="subtitle2" component="p">
            {copyMap[variant].subheading}
          </Typography>
        </Stack>
      </Stack>
      {undistributedValue.greaterThan(0) && (
        <Stack textAlign="right">
          <Typography variant="h1" component="p">
            {formatCurrencyNoDecimals(undistributedValue)}
          </Typography>
          <Typography variant="subtitle2" component="p">
            undistributed
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
