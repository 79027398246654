import { SAMPLE_CSV_DATA } from './fixtures/sampleCSVData';

export function downloadSampleFile() {
  // Convert to Blob
  const blob = new Blob([SAMPLE_CSV_DATA], { type: 'text/csv' });

  // Create an invisible anchor element
  const element = document.createElement('a');
  element.href = URL.createObjectURL(blob);
  element.download = 'luminary_valuation_import_template.csv';
  document.body.appendChild(element);
  element.click();

  // Remove the element when done
  document.body.removeChild(element);
}
