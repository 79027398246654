import Decimal from 'decimal.js';

import {
  LiabilityPaymentFrequency,
  LiabilityPaymentKind,
} from '@/types/schema';
import { PathsOf } from '@/types/subform';

export interface LiabilityFormShape {
  // _showExpandedPaymentDetails is a UI-only value that doesn't
  // get persisted to the data model.
  _showExpandedPaymentDetails: boolean;

  name: string;
  issuerId: string;
  recipientId: string;
  outstandingBalance: Decimal | null;
  balanceAsOfDate: Date | null;
  paymentFrequency: LiabilityPaymentFrequency;
  interestRate: Decimal | null;
  termStartDate: Date | null;
  termEndDate: Date | null;
  paymentKind: LiabilityPaymentKind;
  paymentAmount: Decimal | null;
}

export type LiabilityFormPaths = PathsOf<LiabilityFormShape>;

export const defaultValues: LiabilityFormShape = {
  _showExpandedPaymentDetails: false,
  name: '',
  issuerId: '',
  recipientId: '',
  balanceAsOfDate: null,
  outstandingBalance: null,
  paymentFrequency: LiabilityPaymentFrequency.Monthly,
  interestRate: null,
  termStartDate: null,
  termEndDate: null,
  paymentKind: LiabilityPaymentKind.Amortized,
  paymentAmount: null,
};
