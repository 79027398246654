import { EntityStage } from '@/types/schema';

import { EntityCardProps } from '../entityDetailPageUtils';
import { EntityDetailsData } from '../entityDetails.types';
import { ViewOnlyEntityValuation } from '../EntityValuation/EntityValuation';
import { GRATAssetDetailsCard } from './GRATAssetDetailsCard';
import { GRATDistributionSummaryCard } from './GRATDistributionSummaryCard';

/**
 * @description This is the component that correctly renders the asset/distribution summary for GRATs in a way
 * that's aware of the stage of the entity. Because GRATs have zero assets after their distribution, it no longer makes sense to render
 * the assets card for completed GRATs. Instead, we render the distribution summary card.
 */
export function StageAwareGRATAssetsCard({
  entity,
  entityType,
  ...cardProps
}: EntityCardProps) {
  const summaryProps = { entity, entityType };
  if (entity.stage === EntityStage.Completed) {
    return <GRATDistributionSummaryCard {...summaryProps} />;
  }
  return <GRATAssetDetailsCard {...summaryProps} {...cardProps} />;
}

export function StageAwareGRATAssetsCardViewOnly({
  entity,
  entityType,
}: EntityDetailsData) {
  const summaryProps = { entity, entityType };
  if (entity.stage === EntityStage.Completed) {
    return <GRATDistributionSummaryCard {...summaryProps} />;
  }

  return <ViewOnlyEntityValuation entity={entity} variant="card" />;
}
