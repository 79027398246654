import { PropsWithChildren, useState } from 'react';

import { DispositiveProvisionsModalDetails } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.types';

import {
  ManageDispositions_EntityFragment,
  ManageDispositions_TestamentaryEntityFragment,
} from '../graphql/ManageDispositionsPage.generated';
import {
  ManageDispositionPageSections,
  ManageDispositions_AugmentedClientProfileRow,
} from '../ManageDispositionPage.types';
import { ManageDispositionsContext } from './ManageDispositions.context';

function useManageDispositionsContextValue({
  activeSection: initialActiveSection,
}: ManageDispositionsProviderProps): ManageDispositionsContext {
  /*************** State **********************/
  const [testamentaryEntities, setTestamentaryEntities] = useState<
    ManageDispositions_TestamentaryEntityFragment[]
  >([]);
  const [inEstateEntities, setInEstateEntities] = useState<
    ManageDispositions_EntityFragment[]
  >([]);
  const [outOfEstateEntities, setOutOfEstateEntities] = useState<
    ManageDispositions_EntityFragment[]
  >([]);
  const [primaryClientRows, setPrimaryClientRows] = useState<
    ManageDispositions_AugmentedClientProfileRow[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  const [activeClientDeathId, setActiveClientDeathId] = useState('');
  const [activeSection, setActiveSection] =
    useState<ManageDispositionPageSections>(initialActiveSection);

  const [
    dispositiveProvisionsModalDetails,
    setDispositiveProvisionsModalDetails,
  ] = useState<DispositiveProvisionsModalDetails | null>(null);

  return {
    activeSection,
    setActiveSection,

    activeClientDeathId,
    setActiveClientDeathId,

    dispositiveProvisionsModalDetails,
    setDispositiveProvisionsModalDetails,

    inEstateEntities,
    setInEstateEntities,
    outOfEstateEntities,
    setOutOfEstateEntities,
    testamentaryEntities,
    setTestamentaryEntities,
    primaryClientRows,
    setPrimaryClientRows,

    isLoading,
    setIsLoading,
  };
}

export interface ManageDispositionsProviderProps {
  activeSection: ManageDispositionPageSections;
}

export const ManageDispositionsProvider = ({
  children,
  ...props
}: PropsWithChildren<ManageDispositionsProviderProps>) => {
  const value = useManageDispositionsContextValue(props);
  return (
    <ManageDispositionsContext.Provider value={value}>
      {children}
    </ManageDispositionsContext.Provider>
  );
};
