import { useEffect } from 'react';

import {
  DispositiveProvisionsModalDetails,
  DispositiveProvisionsModalDetailsType,
} from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import { DispositiveProvisionsSplitScreenModal } from '@/modules/dispositiveProvisions/DispositiveProvisionsSplitScreenModal/DispositiveProvisionsSplitScreenModal';
import { useClientProfileDetailsContext } from '@/modules/entities/contexts/clientProfileDetails/clientProfileDetails.context';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { useTestamentaryEntityDetailsContext } from '@/modules/entities/contexts/testamentaryEntityDetails/testamentaryEntityDetails.context';

export interface DispositiveProvisionsModalContainerProps {
  details: DispositiveProvisionsModalDetails;
  onClose: () => void;
}

function DispositiveProvisionsContainerInner({
  details,
  onClose,
}: DispositiveProvisionsModalContainerProps) {
  const { setDisplayName, setEntityId, setEntitySubtypeId, setEntityType } =
    useEntityDetailsContext();
  const { setEntityId: setTestamentaryEntityId } =
    useTestamentaryEntityDetailsContext();
  const { setClientProfileId } = useClientProfileDetailsContext();

  useEffect(() => {
    if (details.type !== DispositiveProvisionsModalDetailsType.ENTITY) {
      return;
    }

    setDisplayName(details.entityDisplayName);
    setEntityId(details.entityId);
    setEntitySubtypeId(details.entitySubtypeId);
    setEntityType(details.entityType);

    return () => {
      setDisplayName(null);
      setEntityId(null);
      setEntitySubtypeId(null);
      setEntityType(null);
    };
  }, [details, setDisplayName, setEntityId, setEntitySubtypeId, setEntityType]);

  useEffect(() => {
    if (
      details.type !== DispositiveProvisionsModalDetailsType.TESTAMENTARY_ENTITY
    ) {
      return;
    }

    setTestamentaryEntityId(details.testamentaryEntityId);
    setDisplayName(details.entityDisplayName);

    return () => {
      setTestamentaryEntityId(null);
      setDisplayName(null);
    };
  }, [details, setDisplayName, setTestamentaryEntityId]);

  useEffect(() => {
    if (details.type !== DispositiveProvisionsModalDetailsType.CLIENT_PROFILE) {
      return;
    }

    setClientProfileId(details.clientProfileId);

    return () => {
      setClientProfileId(null);
    };
  }, [details, setClientProfileId]);

  return (
    <DispositiveProvisionsSplitScreenModal
      firstClientDeathId={details.firstDeathClientId}
      // when we're configuring dispositive provisions for individuals, we pass the value to distribute because
      // it's computed based on a waterfall
      currentNodeValue={
        details.type === DispositiveProvisionsModalDetailsType.CLIENT_PROFILE
          ? details.valueToDistribute
          : undefined
      }
      isOpen={true}
      onClose={onClose}
      isFromTemplateEditor={details.isFromTemplateEditor}
    />
  );
}

export function DispositiveProvisionsModalContainer(
  props: DispositiveProvisionsModalContainerProps
) {
  return <DispositiveProvisionsContainerInner {...props} />;
}
