import { Box, BoxProps, Stack } from '@mui/material';

import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { useIsElementHovered } from '@/hooks/useIsElementHovered';
import { COLORS } from '@/styles/tokens/colors';

import { useGuardedClientPresentationDesignerContext } from '../contexts/clientPresentationDesigner.context';
import { ACTIVE_SLIDE_BORDER_WIDTH_PX } from './ClientPresentationSidebar.constants';
import { ToggleVisibilityButton } from './ToggleVisibilityButton';

export interface SidebarSlideElementProps {
  slideId: string;
  isActive?: boolean;
  level?: number;
  label: React.ReactNode;
  isHidden?: boolean;
  onSelectSlide: (slideId: string) => void;
  onToggleVisibility?: () => void;
  onEdit?: () => void;
}

export function SidebarSlideElement({
  slideId,
  label,
  isActive,
  onSelectSlide,
  onToggleVisibility,
  onEdit,
}: SidebarSlideElementProps) {
  const [isHovered, elementProps] = useIsElementHovered();

  return (
    <Stack {...elementProps}>
      <Stack direction="row" alignItems="center">
        <Stack
          direction="row"
          width={20}
          flexGrow={1}
          alignItems="center"
          spacing={2}
        >
          <SidebarButton
            onClick={() => onSelectSlide(slideId)}
            onEdit={onEdit}
            onToggleVisibility={onToggleVisibility}
            isHovered={isHovered}
            isActive={isActive}
          >
            {label}
          </SidebarButton>
        </Stack>
      </Stack>
    </Stack>
  );
}

interface SidebarButtonProps extends BoxProps<'button'> {
  isActive?: boolean;
  onToggleVisibility?: () => void;
  onEdit?: () => void;
  isHovered?: boolean;
}

// there are a lot of styles and interactions (both now and in the future)
// that differ between the sidebar button and the regular button, so just
// re-implementing this here
export function SidebarButton({
  isActive,
  children,
  onToggleVisibility,
  onEdit,
  isHovered,
  ...htmlProps
}: SidebarButtonProps) {
  const { showHiddenItems } = useGuardedClientPresentationDesignerContext();
  return (
    <Stack
      sx={(t) => ({
        borderRight: `solid ${
          isActive ? t.palette.buttons.main : 'transparent'
        } ${ACTIVE_SLIDE_BORDER_WIDTH_PX}px`,
        backgroundColor:
          isActive || isHovered ? COLORS.FUNCTIONAL.HOVER : 'transparent',
        width: '100%',
        // needs the !important because it's wrapped in a stack, which automatically collapses
        // internal margins to 0
      })}
      direction="row"
      alignItems="center"
    >
      <Box
        component="button"
        {...htmlProps}
        sx={{
          backgroundColor: 'transparent',
          textAlign: 'left',
          display: 'block',
          py: 1.5,
          // 4.5 to line up the text in this component with the text
          // in the parent SidebarGroupElement, which is inset by the
          // expand/collapse button
          pl: 4.5,
          pr: 2,
          outline: 'none',
          cursor: 'pointer',
          border: 0,
          flexGrow: 1,
          ...htmlProps.sx,
        }}
      >
        {/* this needs the maxHeight because in the edit scenario the element stretches to be too tall */}
        <Stack direction="row" alignItems="center" spacing={1} maxHeight={20}>
          <TypographyClamped lines={2} variant="body1">
            {children}
          </TypographyClamped>
          {onEdit && (
            <EditButton
              data-testid="EditSlideButton"
              onClick={(e) => {
                // prevent clicking on this button from also triggering the "select this slide"
                // button action
                e.preventDefault();
                e.stopPropagation();
                onEdit();
              }}
            />
          )}
        </Stack>
      </Box>

      {onToggleVisibility && (
        <ToggleVisibilityButton
          toggleVariant={showHiddenItems ? 'add' : 'remove'}
          isVisible={isHovered}
          onClick={() => {
            onToggleVisibility();
          }}
        />
      )}
    </Stack>
  );
}
