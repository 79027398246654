import { useSearchParams } from 'react-router-dom';

import { diagnostics } from '@/utils/diagnostics';

import { EstateWaterfallComparisonShape } from '../../EstateWaterfallComparison.type';

export const WATERFALL_COMPARISON_SEARCH_PARAM = 'comparisonConfig' as const;

export function useWaterfallComparisonSearchParams(): [
  EstateWaterfallComparisonShape | null,
  (state: EstateWaterfallComparisonShape | null) => void,
] {
  const [searchParams, setSearchParams] = useSearchParams();

  const param = searchParams.get(WATERFALL_COMPARISON_SEARCH_PARAM);
  let parsedSearchParams: EstateWaterfallComparisonShape | null = null;
  try {
    if (param) {
      parsedSearchParams = JSON.parse(Buffer.from(param, 'base64').toString());
    }
  } catch (err) {
    diagnostics.error(
      'Caught exception when parsing comparison search params',
      err as Error
    );
  }

  const setComparisonSearchParams = (
    state: EstateWaterfallComparisonShape | null
  ) => {
    if (!state) {
      searchParams.delete(WATERFALL_COMPARISON_SEARCH_PARAM);
      return;
    }
    const encoded = Buffer.from(JSON.stringify(state)).toString('base64');
    setSearchParams({ [WATERFALL_COMPARISON_SEARCH_PARAM]: encoded });
  };

  return [parsedSearchParams, setComparisonSearchParams];
}
