import { createContext, Dispatch, SetStateAction, useContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { PrimaryClientDropdown_PossibleGrantorFragment } from '@/modules/entities/inputs/PrimaryClientDropdown/graphql/PrimaryClientDropdown.generated';
import {
  PossibleHouseholdBeneficiaryFragment,
  PossibleHouseholdTrusteeFragment,
} from '@/modules/household/contexts/graphql/GetClientDetails.generated';
import { RecentClientFragment } from '@/modules/recents/RecentEntitiesSidebar/graphql/RecentEntitiesSidebar.generated';
import { IntegrationClientKind, StateCode } from '@/types/schema';

export interface ContextPrimaryClient {
  id: string;
  displayName: string;
  firstName: string;
  lastName: string;
  stateCode?: StateCode;
}

export interface HouseholdDetailsContext {
  householdId: string | null;
  setHouseholdId: Dispatch<
    SetStateAction<HouseholdDetailsContext['householdId']>
  >;

  displayName: string | null;
  setDisplayName: Dispatch<
    SetStateAction<HouseholdDetailsContext['displayName']>
  >;

  primaryClients: ContextPrimaryClient[] | null;
  setPrimaryClients: Dispatch<
    SetStateAction<HouseholdDetailsContext['primaryClients']>
  >;

  possibleGrantors: PrimaryClientDropdown_PossibleGrantorFragment[] | null;
  setPossibleGrantors: Dispatch<
    SetStateAction<HouseholdDetailsContext['possibleGrantors']>
  >;

  possibleTrustees: PossibleHouseholdTrusteeFragment | null;
  setPossibleTrustees: Dispatch<
    SetStateAction<HouseholdDetailsContext['possibleTrustees']>
  >;

  possibleBeneficiaries: PossibleHouseholdBeneficiaryFragment | null;
  setPossibleBeneficiaries: Dispatch<
    SetStateAction<HouseholdDetailsContext['possibleBeneficiaries']>
  >;

  isTwoClientHousehold: boolean;
  setIsTwoClientHousehold: Dispatch<
    SetStateAction<HouseholdDetailsContext['isTwoClientHousehold']>
  >;

  isHouseholdLinkedToBlackDiamond: boolean;
  isHouseholdLinkedToAddepar: boolean;
  isHouseholdLinkedToAssetProvider: boolean;
  householdIntegrationLink: IntegrationClientKind | null;
  setHouseholdIntegrationLink: Dispatch<
    SetStateAction<HouseholdDetailsContext['householdIntegrationLink']>
  >;

  householdOnboardingCompletedAt: Date | null;
  setHouseholdOnboardingCompletedAt: Dispatch<
    SetStateAction<HouseholdDetailsContext['householdOnboardingCompletedAt']>
  >;

  recentClients: RecentClientFragment[];
  setRecentClients: Dispatch<
    SetStateAction<HouseholdDetailsContext['recentClients']>
  >;

  householdNotFound: boolean;
  setHouseholdNotFound: Dispatch<
    SetStateAction<HouseholdDetailsContext['householdNotFound']>
  >;
}

export const HouseholdDetailsContext = createContext<HouseholdDetailsContext>({
  householdId: null,
  setHouseholdId: () => null,

  displayName: null,
  setDisplayName: () => null,

  primaryClients: null,
  setPrimaryClients: () => null,

  possibleGrantors: null,
  setPossibleGrantors: () => null,

  possibleTrustees: null,
  setPossibleTrustees: () => null,

  possibleBeneficiaries: null,
  setPossibleBeneficiaries: () => null,

  isTwoClientHousehold: false,
  setIsTwoClientHousehold: () => null,

  isHouseholdLinkedToBlackDiamond: false,
  isHouseholdLinkedToAddepar: false,
  isHouseholdLinkedToAssetProvider: false,
  householdIntegrationLink: null,
  setHouseholdIntegrationLink: () => null,

  householdOnboardingCompletedAt: null,
  setHouseholdOnboardingCompletedAt: () => null,

  recentClients: [],
  setRecentClients: () => null,

  householdNotFound: false,
  setHouseholdNotFound: () => null,
});

export const useHouseholdDetailsContext = () => {
  return useGuardedContext(HouseholdDetailsContext, 'ClientDetailsProvider');
};

export const useUnguardedHouseholdDetailsContext = () => {
  return useContext(HouseholdDetailsContext);
};
