// Our dnd library doesn't work with React's StrictMode
// This is a workaround suggested in the issue below

import { useEffect, useState } from 'react';
import { Droppable, DroppableProps } from 'react-beautiful-dnd';

import { isLocal } from '@/utils/featureFlags';

// https://github.com/atlassian/react-beautiful-dnd/issues/2399#issuecomment-1175638194
export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  // this pattern causes a flicker on re-renders; because strict mode is only enabled in dev, it's fine to initialize
  // `enabled` to true in prod and avoid the flicker
  const [enabled, setEnabled] = useState(!isLocal());

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};
