import { PathsOf } from '@/types/subform';

export interface EstateWaterfallComparisonWaterfall {
  waterfallId: string;
  displayName: string;
  growthProfileId: string;
  firstDeathYear: number;
  secondDeathYear: number | undefined;
}

/**
 * A type that describes how waterfalls are compared for the trowser and print view.
 *
 * Because this is only persisted on the client via query parameters, avoid
 * removing or renaming fields, in case a user has bookmarked a comparison.
 */
export interface EstateWaterfallComparisonShape {
  waterfalls: EstateWaterfallComparisonWaterfall[];
  comparisonName: string;
}

export type EstateWaterfallComparisonPaths =
  PathsOf<EstateWaterfallComparisonShape>;

export const EMPTY_ESTATE_WATERFALL_COMPARISON_WATERFALL: EstateWaterfallComparisonWaterfall =
  {
    waterfallId: '',
    displayName: '',
    growthProfileId: '',
    firstDeathYear: new Date().getFullYear(),
    secondDeathYear: new Date().getFullYear(),
  };

export const EMPTY_ESTATE_WATERFALL_COMPARISON: EstateWaterfallComparisonShape =
  {
    waterfalls: [EMPTY_ESTATE_WATERFALL_COMPARISON_WATERFALL],
    comparisonName: '',
  };
