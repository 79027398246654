import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Modal, ModalProps } from '@/components/modals/Modal/Modal';
import { BRAND, themeToBrand } from '@/styles/themes/themes.utils';

type Props = Omit<ModalProps, 'children' | 'heading'>;

function ColorRow({ name, color }: { name: string; color: string }) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      borderBottom="solid 1px #000"
    >
      <Stack>
        <Typography variant="subtitle2">{name}</Typography>
        <Typography variant="body1">{color}</Typography>
      </Stack>
      <Box
        sx={{
          width: 100,
          height: 100,
          backgroundColor: color,
        }}
      ></Box>
    </Stack>
  );
}

export function BrandingModal({ isOpen, onClose }: Props) {
  const theme = useTheme();
  const [branding, setBranding] = useState<typeof BRAND | null>(null);

  useEffect(() => {
    const brand = themeToBrand(theme);

    setBranding(brand);
  }, [theme]);

  const modalActions = (
    <Stack width="100%" px={1.5} direction="row" justifyContent="space-between">
      <Box>
        <Button onClick={onClose} size="sm" variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            // implment save
          }}
          size="sm"
          variant="primary"
          type="submit"
        >
          Save
        </Button>
      </Box>
    </Stack>
  );

  return (
    <Modal
      isOpen={isOpen}
      actions={modalActions}
      onClose={onClose}
      heading={'Branding'}
    >
      <Stack flex={1} sx={{ minHeight: 450 }}>
        {branding &&
          Object.entries(branding).map(([key, color]) => (
            <ColorRow key={key} color={color} name={key} />
          ))}
      </Stack>
    </Modal>
  );
}
