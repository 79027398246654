import { getSLATTrustSummaryProperties } from '@/modules/entities/details/trusts/SLATTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { TrustFiduciariesSlide } from '../components/Trust.Fiduciaries';
import { SLATTrustPresentationSlideProps } from './SLATTrust.types';

export function SLATTrustFiduciariesSlide({
  entity,
  subtype,
  ...registrationProps
}: SLATTrustPresentationSlideProps) {
  const summaryDetails = getSLATTrustSummaryProperties({
    entity,
    entityType: ENTITY_TYPES.SLAT,
  });

  return (
    <TrustFiduciariesSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      displayName={subtype.displayName}
    />
  );
}
