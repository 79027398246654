import { Box, Stack } from '@mui/material';
import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { Footer } from '@/components/navigation/Footer';
import { useForm, useFormContext } from '@/components/react-hook-form';

import {
  HypotheticalSaleLoanFormKind,
  HypotheticalSaleLoanFormShape,
} from './EstateWaterfallHypotheticalSaleLoanModal.types';
import {
  mapFormDataToCreateInput,
  mapFormDataToUpdateInput,
  mapQueryDataToFormData,
} from './EstateWaterfallHypotheticalSaleLoanModal.utils';
import { useSaleLoanFormContext } from './Form/context/SaleLoanForm.context';
import { SaleLoanFormProvider } from './Form/context/SaleLoanForm.provider';
import { SaleLoanModalForm } from './Form/SaleLoanModalForm';
import {
  EstateWaterfallHypotheticalSaleLoanModalQuery,
  useCreateEstateWaterfallHypotheticalSaleLoanMutation,
  useEstateWaterfallHypotheticalSaleLoanModalQuery,
  useUpdateEstateWaterfallHypotheticalSaleLoanMutation,
} from './graphql/EstateWaterfallHypotheticalSaleLoanModal.generated';
import { LayoutWithSidePanel } from './LayoutWithSidePanel';

export interface EstateWaterfallHypotheticalSaleLoanModalProps {
  isOpen: boolean;
  waterfallId: string;
  householdId: string;
  // saleLoanId is null if we are creating a new hypothetical sale loan
  saleLoanId: string | null;
  onClose: () => void;
  initialKind: HypotheticalSaleLoanFormKind;
}

function EstateWaterfallHypotheticalSaleLoanModalInner({
  isOpen,
  onClose,
  waterfallId,
  householdId,
  saleLoanId,
  initialKind,
}: EstateWaterfallHypotheticalSaleLoanModalProps) {
  const { saleLoanFundingId } = useSaleLoanFormContext();
  const [updateHypotheticalSaleLoan] =
    useUpdateEstateWaterfallHypotheticalSaleLoanMutation();
  const [createHypotheticalSaleLoan] =
    useCreateEstateWaterfallHypotheticalSaleLoanMutation();
  // const { showFeedback } = useFeedback();
  // const { reportError } = useReportError();
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext<HypotheticalSaleLoanFormShape>();

  const onSubmit = handleSubmit((data) => {
    // if we have a saleLoanId, we are updating an existing hypothetical sale loan
    if (saleLoanId) {
      if (!saleLoanFundingId) {
        throw new Error('saleLoanFundingId is required');
      }

      return updateHypotheticalSaleLoan({
        variables: {
          input: mapFormDataToUpdateInput(data, saleLoanId, saleLoanFundingId),
        },
      });
    }

    return createHypotheticalSaleLoan({
      variables: {
        input: mapFormDataToCreateInput(
          data,
          initialKind,
          waterfallId,
          householdId
        ),
      },
    });
  });

  return (
    <FullScreenModal isOpen={isOpen}>
      {isOpen && (
        <Stack component="form" noValidate onSubmit={onSubmit}>
          <LayoutWithSidePanel
            MainContent={<Box>Main content</Box>}
            SidebarContent={<SaleLoanModalForm />}
            Footer={
              <Footer
                rightAction={
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={2}
                  >
                    <Button size="sm" variant="secondary" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button
                      loading={isSubmitting}
                      size="sm"
                      variant="primary"
                      onClick={onSubmit}
                    >
                      Create installment sale
                    </Button>
                  </Stack>
                }
              />
            }
          />
        </Stack>
      )}
    </FullScreenModal>
  );
}

export function EstateWaterfallHypotheticalSaleLoanModal(
  props: EstateWaterfallHypotheticalSaleLoanModalProps
) {
  const formMethods = useForm<HypotheticalSaleLoanFormShape>({
    defaultValues: {
      displayName: `Untitled ${props.initialKind.toLowerCase()}`,
      pretermGrantorDeathHandling: 'repaid',
      applicableInterestRate: null,
      recipientId: '',
      startDate: new Date(),
      termLength: null,
      sellerId: '',
      fundingKind: 'proRata',
      proRataPattern: 'amount',
      proRataAmount: null,
      proRataPercent: null,
      assetsPattern: 'amount',
      selectedAssetClassesAndBusinessIds: [],
    },
  });

  const { reset } = formMethods;

  const syncDataToForm = useCallback(
    (data: EstateWaterfallHypotheticalSaleLoanModalQuery) => {
      const formData = mapQueryDataToFormData(data);
      if (formData) {
        reset(formData);
      }
    },
    [reset]
  );

  const { loading: isLoadingDefaultValues, data } =
    useEstateWaterfallHypotheticalSaleLoanModalQuery({
      variables: {
        waterfallId: props.waterfallId,
        hypotheticalSaleLoanId: props.saleLoanId,
        hasExistingHypotheticalSaleLoan: Boolean(props.saleLoanId),
      },
      skip: !props.saleLoanId,
      onCompleted: syncDataToForm,
    });

  return (
    <SaleLoanFormProvider
      loading={isLoadingDefaultValues}
      waterfallId={props.waterfallId}
      saleLoanId={props.saleLoanId}
      saleLoanFundingId={
        // this is kind of hilarious, but it works?
        data?.estateWaterfalls?.edges?.[0]?.node?.hypotheticalSaleLoans
          ?.edges?.[0]?.node?.funding?.id ?? null
      }
      householdId={props.householdId}
    >
      <FormProvider {...formMethods}>
        <EstateWaterfallHypotheticalSaleLoanModalInner {...props} />
      </FormProvider>
    </SaleLoanFormProvider>
  );
}
