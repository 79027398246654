import { Box, Stack } from '@mui/material';
import { Path, useWatch } from 'react-hook-form';

import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';

import { defaultBeneficiaryFormScheduledDistribution } from './EntityBeneficiariesSubform.defaults';
import { BeneficiaryFormScheduledDistributions } from './EntityBeneficiariesSubform.types';
import { ScheduledDistributionForm } from './ScheduledDistributionForm';

type ScheduledDistributionsFormPaths =
  Path<BeneficiaryFormScheduledDistributions>;

interface ScheduledDistributionsFormProps {
  fieldName: 'beneficiaryFormScheduledDistributions';
}

export function ScheduledDistributionsForm({
  fieldName,
}: ScheduledDistributionsFormProps) {
  const { control } = useFormContext<BeneficiaryFormScheduledDistributions>();

  const isHasScheduledDistributionsToggled = useWatch({
    name: `${fieldName}._hasScheduledDistributions` as '_hasScheduledDistributions' satisfies ScheduledDistributionsFormPaths,
    control,
  });

  return (
    <Card
      variant={isHasScheduledDistributionsToggled ? 'filled-dark' : 'filled'}
      sx={{
        p: 2,
        borderRadius: '6px',
      }}
    >
      <Stack spacing={3}>
        <FormAwareSwitch<BeneficiaryFormScheduledDistributions>
          variant="dark"
          label="Scheduled distributions"
          labelPosition="right"
          fieldName={
            `${fieldName}._hasScheduledDistributions` as '_hasScheduledDistributions' satisfies ScheduledDistributionsFormPaths
          }
          control={control}
        />
        {isHasScheduledDistributionsToggled && (
          <Box pl={5}>
            <FormAwareInputRepeater<BeneficiaryFormScheduledDistributions>
              control={control}
              name={
                `${fieldName}.scheduledDistributions` as 'scheduledDistributions' satisfies ScheduledDistributionsFormPaths
              }
              emptyValue={defaultBeneficiaryFormScheduledDistribution}
              addAnotherItemText="Add additional distribution"
              shouldInit
              isSingleLineRepeatedInput={false}
              render={(idx) => {
                return (
                  <ScheduledDistributionForm
                    fieldName={
                      `${fieldName}.scheduledDistributions.${idx}` as `scheduledDistributions.${number}`
                    }
                  />
                );
              }}
            />
          </Box>
        )}
      </Stack>
    </Card>
  );
}
