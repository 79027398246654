/**
 * This is largely adapted from: https://github.com/wbkd/react-flow/blob/main/packages/controls/src/Controls.tsx
 * which is unforunately not very configurable to allow for custom components / interactivity for each control
 */

import { Stack } from '@mui/material';
import { Panel } from '@xyflow/react';
import { ComponentProps, FC, PropsWithChildren } from 'react';

import { CONTROLS_ID } from './constants';

type PanelProps = ComponentProps<typeof Panel>;

export type ControlProps = Partial<PanelProps>;

export const Controls: FC<PropsWithChildren<ControlProps>> = ({
  children,
  position = 'top-right',
  id = CONTROLS_ID,
  ...rest
}) => {
  return (
    <Panel id={id} position={position} {...rest}>
      <Stack flexDirection="row" gap={1} alignItems="center">
        {children}
      </Stack>
    </Panel>
  );
};
