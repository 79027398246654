import {
  OperationVariables,
  QueryHookOptions,
  QueryResult,
} from '@apollo/client';
import { AutocompleteInputChangeReason } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import { compact, find, keyBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getNodes } from '@/utils/graphqlUtils';

import { TypeaheadSelectInputOption } from '../../inputTypes';
import { ValidTypeaheadValue } from '../BackendTypeaheadSelectInput';
import { BackendTypeaheadSelectProps } from '../BackendTypeaheadSelectInput';

export interface AsyncTypeaheadQuery {
  __typename?: 'Query';
  queryData?: {
    totalCount: number;
    edges?: Maybe<Maybe<{ node?: unknown }>[]>;
  };
  selectedData?: {
    totalCount: number;
    edges?: Maybe<Maybe<{ node?: unknown }>[]>;
  };
}

export type TypeaheadQueryVariables = OperationVariables;

type QueryHook<
  Query extends AsyncTypeaheadQuery,
  Variables extends TypeaheadQueryVariables,
> = (
  options: QueryHookOptions<Query, Variables>
) => QueryResult<Query, Variables>;

export type TypeaheadInputProps<V extends ValidTypeaheadValue> = Pick<
  BackendTypeaheadSelectProps<V>,
  'loading' | 'options' | 'emptyOption' | 'onInputChange' | 'inputValue'
>;

export interface ExtendedTypeaheadQueryOpts<
  V extends ValidTypeaheadValue,
  Query extends AsyncTypeaheadQuery,
  Variables extends TypeaheadQueryVariables,
> {
  pageSize?: number;
  // Variables returned from searchTermToVariables power the full set of options for the typeahead
  searchTermToVariables: (searchTerm: string) => Partial<Variables>;
  // Variables returned from getSelectedOptionVariables power the selected option for the typeahead
  getSelectedOptionVariables: () => Partial<Variables>;
  toOptions: (
    data: CompactQueryData<Query, Variables>[]
  ) => BackendTypeaheadSelectProps<V>['options'];
  emptyOption?: TypeaheadInputProps<V>['emptyOption'];
  // If true, the input text will not be reset to an empty string when the user selects an option and the
  // autocomplete component emits a onInputUpdate event with "clear" as the reason
  skipAutocompleteResetClear?: boolean;
}

// Type to extract edges.node so we can pre-extract nested data and
// prevent needing to do a flatMap in every component that uses this
export type CompactQueryData<
  Query extends AsyncTypeaheadQuery,
  Variables extends TypeaheadQueryVariables,
> = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<QueryResult<Query, Variables>['data']>['queryData']
      >['edges']
    >[number]
  >['node']
>;

export function useAsyncTypeaheadProps<
  Query extends AsyncTypeaheadQuery,
  Variables extends TypeaheadQueryVariables,
  V extends ValidTypeaheadValue,
>(
  useQuery: QueryHook<Query, Variables>,
  {
    pageSize = 50,
    skipAutocompleteResetClear: skipResetClear = false,
    ...queryOpts
  }: QueryHookOptions<
    Query,
    // Omit internal variables first and after, these are driven by the hook and should not be provided by the consumer
    Variables
  > &
    ExtendedTypeaheadQueryOpts<V, Query, Variables>
): [
  TypeaheadInputProps<V>,
  Omit<QueryResult<Query, Variables>, 'data'> & {
    data: CompactQueryData<Query, Variables>[];
  },
] {
  const [inputText, setInputText] = useState('');
  const [lastSelectedOption, setLastSelectedOption] =
    useState<TypeaheadSelectInputOption<V> | null>(null);
  const [allOptionsByValue, setAllOptionsByValue] = useState<
    Record<string, TypeaheadSelectInputOption<V>>
  >({});
  const [mostRecentOptions, setMostRecentOptions] = useState<
    TypeaheadSelectInputOption<V>[]
  >([]);

  const queryArgs = useMemo<QueryHookOptions<Query, Variables>>(() => {
    const appliedSearchTerm = (() => {
      const queryIsEmptyDisplayValue =
        inputText === queryOpts.emptyOption?.display;

      const queryIsExactValue = inputText === lastSelectedOption?.display;
      if (queryIsExactValue) return '';
      if (queryIsEmptyDisplayValue) return '';
      return inputText;
    })();

    const variables: TypeaheadQueryVariables = {
      first: pageSize,
      ...queryOpts.variables,
      ...queryOpts.searchTermToVariables(appliedSearchTerm),
      ...queryOpts.getSelectedOptionVariables(),
    };

    return {
      fetchPolicy: 'cache-and-network',
      ...queryOpts,
      variables,
    } as QueryHookOptions<Query, Variables>;
  }, [pageSize, queryOpts, inputText, lastSelectedOption?.display]);

  const queryResult = useQuery(queryArgs);
  const { data, loading: loadingQuery } = queryResult;

  // Apollo's loading variable is true for cache-and-network, even when there is a cache hit.
  // We want to only show a loading spinner when there is no cache hit, AND a fetch is occurring.
  // Context: https://github.com/apollographql/apollo-client/issues/8669#issuecomment-1464016804
  const loading = loadingQuery && !data;
  const compactData = useMemo(() => {
    const searchResultData = getNodes(data?.queryData) as CompactQueryData<
      Query,
      Variables
    >[];
    const selectedOptionsData = getNodes(
      data?.selectedData
    ) as CompactQueryData<Query, Variables>[];
    // always put the selected options first
    return [...selectedOptionsData, ...searchResultData];
  }, [data?.queryData, data?.selectedData]);

  const { toOptions } = queryOpts;
  const queryOptions = useMemo(() => {
    return toOptions(compactData);
  }, [compactData, toOptions]);

  const handleSetInputText = useCallback(
    (newSearchQuery: string, reason: AutocompleteInputChangeReason) => {
      const matchingOption = find(
        allOptionsByValue,
        (v) => v.display === newSearchQuery
      );
      if (reason === 'input') {
        setInputText(newSearchQuery);

        matchingOption &&
          matchingOption.value !== '' &&
          setLastSelectedOption(matchingOption);
        return;
      }

      if (reason === 'reset') {
        if (newSearchQuery === queryOpts.emptyOption?.display) {
          setLastSelectedOption(null);
          setInputText('');
          return;
        }

        matchingOption &&
          matchingOption.value !== '' &&
          setLastSelectedOption(matchingOption);

        if (skipResetClear) {
          return;
        }
      }

      setInputText(newSearchQuery);
    },
    [allOptionsByValue, queryOpts.emptyOption?.display, skipResetClear]
  );

  useEffect(() => {
    const optionsByValue = keyBy(queryOptions, 'value');
    if (queryOptions.length !== 0) {
      setMostRecentOptions(queryOptions);
    }

    setAllOptionsByValue((os) => ({ ...os, ...optionsByValue }));
  }, [queryOptions]);

  const finalOptions = useMemo(() => {
    return compact([
      queryOpts.emptyOption,
      lastSelectedOption,
      ...mostRecentOptions,
    ]);
  }, [lastSelectedOption, mostRecentOptions, queryOpts.emptyOption]);

  const typeaheadProps: TypeaheadInputProps<V> = {
    loading,
    options: finalOptions,
    inputValue: inputText,
    onInputChange: handleSetInputText,
  };

  return [typeaheadProps, { ...queryResult, data: compactData }];
}
