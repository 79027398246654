// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteGrowthProfileMutationVariables = Types.Exact<{
  growthProfileId: Types.Scalars['ID']['input'];
  updatedEstateWaterfalls: Array<Types.AugmentedUpdateEstateWaterfallInput> | Types.AugmentedUpdateEstateWaterfallInput;
  migrateEstateWaterfalls: Types.Scalars['Boolean']['input'];
}>;


export type DeleteGrowthProfileMutation = { __typename?: 'Mutation', deleteGrowthProfile: string, updateEstateWaterfalls?: Array<{ __typename?: 'EstateWaterfall', id: string } | null> };


export const DeleteGrowthProfileDocument = gql`
    mutation DeleteGrowthProfile($growthProfileId: ID!, $updatedEstateWaterfalls: [AugmentedUpdateEstateWaterfallInput!]!, $migrateEstateWaterfalls: Boolean!) {
  updateEstateWaterfalls(inputs: $updatedEstateWaterfalls) @include(if: $migrateEstateWaterfalls) {
    id
  }
  deleteGrowthProfile(id: $growthProfileId)
}
    `;
export type DeleteGrowthProfileMutationFn = Apollo.MutationFunction<DeleteGrowthProfileMutation, DeleteGrowthProfileMutationVariables>;

/**
 * __useDeleteGrowthProfileMutation__
 *
 * To run a mutation, you first call `useDeleteGrowthProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGrowthProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGrowthProfileMutation, { data, loading, error }] = useDeleteGrowthProfileMutation({
 *   variables: {
 *      growthProfileId: // value for 'growthProfileId'
 *      updatedEstateWaterfalls: // value for 'updatedEstateWaterfalls'
 *      migrateEstateWaterfalls: // value for 'migrateEstateWaterfalls'
 *   },
 * });
 */
export function useDeleteGrowthProfileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGrowthProfileMutation, DeleteGrowthProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGrowthProfileMutation, DeleteGrowthProfileMutationVariables>(DeleteGrowthProfileDocument, options);
      }
export type DeleteGrowthProfileMutationHookResult = ReturnType<typeof useDeleteGrowthProfileMutation>;
export type DeleteGrowthProfileMutationResult = Apollo.MutationResult<DeleteGrowthProfileMutation>;
export type DeleteGrowthProfileMutationOptions = Apollo.BaseMutationOptions<DeleteGrowthProfileMutation, DeleteGrowthProfileMutationVariables>;
export const Operations = {
  Mutation: {
    DeleteGrowthProfile: 'DeleteGrowthProfile' as const
  }
}