import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

import { ColorBox } from '@/components/display/ColorBox/ColorBox';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { Link03Icon } from '@/components/icons/Link03Icon';
import { UnstyledNavLink } from '@/components/navigation/UnstyledNavLink';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { COLORS } from '@/styles/tokens/colors';
import { UnreachableError } from '@/utils/errors';

type EntityValuationVariant = 'asset' | 'liability';
export interface EntityValuationItemProps {
  variant: EntityValuationVariant;
  value: React.ReactNode;
  label: React.ReactNode;
  /** showIntegrationBadge: if true, show a link icon to show that this asset value is pulled from an integration */
  showIntegrationBadge?: boolean;
  /**
   * indent: if true, the item gets indented a bit.
   * used for the "owned businesses" line of an integrated entity to show that the
   * value of the child entity *should be* included in the parent entity's valuation, but
   * that we just take the value from addepar, so it's not guaranteed.
   */
  indent?: boolean;
  /** href: if present, the item will have a hover state and a carat and be clickable */
  href?: string;
}

function getColorsForVariant(variant: EntityValuationVariant) {
  switch (variant) {
    case 'asset':
      return {
        swatch: COLORS.NAVY[300],
        text: COLORS.NAVY[600],
      };
    case 'liability':
      return {
        swatch: COLORS.ORANGE[200],
        text: COLORS.ORANGE[600],
      };
    default:
      throw new UnreachableError({
        case: variant,
        message: `Unhandled EntityValuationVariant ${variant}`,
      });
  }
}

/**
 * @description EntityValuationItem represents a single line item in an
 * EntityValuationBreakdown. The main variant is a clickable version,
 * which has a hover state and a carat (triggered by the `href` property
 * being defined)
 */
export function EntityValuationItem({
  variant,
  label,
  value,
  href,
  indent = false,
  showIntegrationBadge = false,
}: EntityValuationItemProps) {
  const isViewOnly = useViewOnly();
  const colors = getColorsForVariant(variant);

  const horizontalPadding = 0.25;
  const content = (
    <Stack
      sx={{
        px: 1.5,
        py: horizontalPadding,
        pl: indent ? 2 : horizontalPadding,
      }}
    >
      <Stack direction="row" spacing={0.5} alignItems="center">
        <ColorBox color={colors.swatch} />
        <Typography color={colors.text} variant="label" component="span">
          {value}
        </Typography>
        {showIntegrationBadge && (
          <Link03Icon size={18} sx={{ color: COLORS.NAVY[600] }} />
        )}
      </Stack>
      <Typography variant="subtitle2">{label}</Typography>
    </Stack>
  );

  if (isViewOnly || !href) {
    return content;
  }

  return (
    <UnstyledNavLink to={href}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          cursor: 'pointer',
          ':hover': {
            backgroundColor: COLORS.FUNCTIONAL.HOVER,
          },
        }}
      >
        <Box>{content}</Box>
        <ChevronRightIcon size={18} sx={{ color: COLORS.GRAY[500] }} />
      </Stack>
    </UnstyledNavLink>
  );
}
