import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { GiftingProposalCashFlowCashFlowType } from '@/types/schema';

import { ScenarioIncomeAndExpensesFormShape } from '../../GiftDesignerModelScenariosForm.types';
import { DEFAULT_GROWTH_PERCENTAGE } from '../../GiftDesignerModelScenariosForm.utils';
import { AnnuallyRecurringValue } from './ScenarioIncomeAndExpensesModal.fields';

interface UseScenarioIncomeAndExpensesFormDefaultValuesProps {
  incomeData: ScenarioIncomeAndExpensesFormShape | null;
  lengthOfAnalysis: Decimal;
}

export function useScenarioIncomeAndExpensesFormDefaultValues({
  incomeData,
  lengthOfAnalysis,
}: UseScenarioIncomeAndExpensesFormDefaultValuesProps) {
  const defaultValues = useMemo(() => {
    if (!incomeData) {
      return {
        _id: '', // This will be replaced by a field array id when the field array is initialized
        amount: null,
        annuallyRecurring: AnnuallyRecurringValue.true,
        cashFlowType: GiftingProposalCashFlowCashFlowType.Income,
        displayName: '',
        endYear: lengthOfAnalysis.plus(getYear(new Date())),
        growthPercentage: DEFAULT_GROWTH_PERCENTAGE,
        order: 0,
        startYear: new Decimal(getYear(new Date())),
        lengthOfAnalysis,
        portfolioID: null,
      } as const satisfies ScenarioIncomeAndExpensesFormShape;
    }

    return incomeData;
  }, [incomeData, lengthOfAnalysis]);

  return { defaultValues };
}
