import { useEffect, useState } from 'react';

import { DocumentType } from '@/types/schema';

import {
  GetDocumentDataQuery,
  useGetDocumentDataLazyQuery,
} from './graphql/UseDocumentData.generated';

interface DocumentData {
  id: string;
  name: string;
  type: DocumentType;
  createdAt: Date;
  createdBy: string;
  fileId: string;
  entityId?: string;
  enableAiSuggestions: boolean;
  isDefaultForEntity: boolean;
}

function getGuardedDocumentData(
  documentData?: GetDocumentDataQuery
): DocumentData | null {
  if (!documentData?.node) return null;
  const document = documentData.node;
  if (document.__typename !== 'Document') {
    throw new Error('Invalid type in UseDocumentData');
  }
  return {
    id: document.id,
    name: document.name,
    type: document.type,
    createdAt: document.createdAt,
    createdBy: document.createdBy,
    fileId: document.file.id,
    entityId: document.entity?.id,
    enableAiSuggestions: document.enableAiSuggestions ?? false,
    isDefaultForEntity: !!document.defaultDocumentOfEntity?.id,
  };
}

export function useDocumentData(documentId: string | null) {
  const [documentData, setDocumentData] = useState<DocumentData | null>(null);
  const [getDocumentData, { data, loading, error }] =
    useGetDocumentDataLazyQuery();

  useEffect(() => {
    if (!documentId) return;
    void getDocumentData({
      variables: {
        documentId,
      },
    });
  }, [documentId, getDocumentData]);

  useEffect(() => {
    if (!data) return;
    setDocumentData(getGuardedDocumentData(data));
  }, [data]);

  return { document: documentData, loading, error };
}
