import { Stack } from '@mui/material';

import { FlowChartProvider } from '@/components/diagrams/FlowChart';
import { FlowChartWrapper } from '@/components/diagrams/FlowChart/components/FlowChartWrapper';
import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';

import { FamilyTreeProvider, useFamilyTreeContext } from './contexts';
import { FamilyTreeFlowChart } from './FamilyTreeFlowChart';
import { useProviderEffects } from './hooks/useProviderEffects.hook';
import { FamilyTreeInteractionMode } from './types';

export interface FamilyTreeProps {
  householdId: string;
  mode: FamilyTreeInteractionMode;
  height: number;
  width: number;
}

const FamilyTreeInner = ({ height, width }: FamilyTreeProps) => {
  const { state } = useFamilyTreeContext();

  useProviderEffects();

  return (
    <>
      <LoadingOverlay
        open={state.isInitializingData}
        updatingText="Loading..."
      />
      <Stack
        display="grid"
        gridTemplateColumns="auto"
        height={height}
        width={width}
      >
        <FlowChartWrapper style={{ height }}>
          {!state.isInitializingData && <FamilyTreeFlowChart />}
        </FlowChartWrapper>
      </Stack>
    </>
  );
};

export const FamilyTree = ({ householdId, ...props }: FamilyTreeProps) => {
  return (
    <FlowChartProvider>
      <FamilyTreeProvider householdId={householdId}>
        <FamilyTreeInner householdId={householdId} {...props} />
      </FamilyTreeProvider>
    </FlowChartProvider>
  );
};
