import { PropsWithChildren } from 'react';

import { CommonAiSuggestionFragment } from '../graphql/aiSuggestions.generated';
import { SelectedSuggestionContext } from './SelectedSuggestion.context';

interface SelectedSuggestionsProviderProps {
  onLocateSuggestion: (suggestion: CommonAiSuggestionFragment) => void;
}

function useContextValue({
  onLocateSuggestion,
}: SelectedSuggestionsProviderProps): SelectedSuggestionContext {
  return {
    locateSuggestion: onLocateSuggestion,
  };
}

export function SelectedSuggestionsProvider({
  children,
  ...rest
}: PropsWithChildren<SelectedSuggestionsProviderProps>) {
  const value = useContextValue({ ...rest });

  return (
    <SelectedSuggestionContext.Provider value={value}>
      {children}
    </SelectedSuggestionContext.Provider>
  );
}
