import { ApolloError, useApolloClient } from '@apollo/client';

import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { DocumentType } from '@/types/schema';

import { useCreateGenericDocumentMutation } from './graphql/UseDocumentCreation.generated';

interface CreateDocumentOptions {
  householdId: string;
  fileId: string;
  fileName: string;
  documentType: DocumentType;
  entityId?: string;
  associatedEntityIds: string[];

  // Only show for rev/irrev trusts
  enableAiSuggestions: boolean;
  isDefaultForEntity: boolean;
}

type UseCreateDocumentReturn = [
  (options: CreateDocumentOptions) => Promise<string | undefined>,
  { loading: boolean; error: ApolloError | undefined },
];

export function useCreateDocument(): UseCreateDocumentReturn {
  const client = useApolloClient();
  const trackUserEvent = useTrackUserEvent();
  const [createDocument, { loading, error }] =
    useCreateGenericDocumentMutation();

  async function handleCreateDocument(options: CreateDocumentOptions) {
    const { data } = await createDocument({
      variables: {
        input: {
          entityID: options.entityId,
          additionalEntityIDs: options.associatedEntityIds,
          householdID: options.householdId,
          type: options.documentType,
          fileID: options.fileId,
          name: options.fileName,
          enableAiSuggestions: options.enableAiSuggestions,
          defaultDocumentOfEntityID:
            options.isDefaultForEntity && options.entityId
              ? options.entityId
              : null,
          // always set aiSuggestionOrder to 0 for new documents, so it will be first
          aiSuggestionOrder: 0,
        },
      },
      onCompleted: async () => {
        if (options.entityId) {
          trackUserEvent('document data extraction enabled', {
            entityId: options.entityId,
          });
        }
        await client.refetchQueries({
          updateCache(cache) {
            cache.evict({ fieldName: 'documents' });
            cache.gc();
          },
        });
      },
    });

    return data?.createDocument.id;
  }

  return [handleCreateDocument, { loading, error }];
}
