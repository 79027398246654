// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
export type MinimalTrusteeFragment = { __typename?: 'Trustee', id: string, individual?: { __typename?: 'ClientProfile', id: string, displayName: string } | null, organization?: { __typename?: 'ClientOrganization', id: string, name: string } | null };

export type TrusteeFragment = { __typename?: 'Trustee', id: string, individual?: { __typename?: 'ClientProfile', displayName: string, id: string } | null, organization?: { __typename?: 'ClientOrganization', id: string, name: string } | null };

export const MinimalTrusteeFragmentDoc = gql`
    fragment MinimalTrustee on Trustee {
  id
  individual {
    id
    displayName
  }
  organization {
    id
    name
  }
}
    `;
export const TrusteeFragmentDoc = gql`
    fragment Trustee on Trustee {
  id
  individual {
    displayName
    id
  }
  organization {
    id
    name
  }
}
    `;
export const Operations = {
  Fragment: {
    MinimalTrustee: 'MinimalTrustee' as const,
    Trustee: 'Trustee' as const
  }
}