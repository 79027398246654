import { Stack } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import { useEffect } from 'react';

import { ErrorFeedbackFallback } from '@/components/notifications/Feedback/ErrorFeedbackFallback';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Loader } from '@/components/progress/Loader/Loader';
import { EntitySubforms } from '@/modules/entities/EntitySubforms/EntitySubforms';

import { BASIC_INFORMATION_SUBFORM_NAMESPACE as BASIC_INFORMATION } from '../BasicInformationSubform/BasicInformationSubform.types';
import { EntityType } from '../types/EntityType';
import { EntityFormCommonProps, EntityFormStage } from './types';
import { useData } from './useData';
import { useEntitySubformConfigs } from './useEntitySubformConfigs';

interface EntityFormProps extends EntityFormCommonProps {
  type: EntityType;
  isTrowser?: boolean;
  stage: EntityFormStage;
  estateWaterfallId?: string;
  isHypotheticalEntity?: boolean;
}

export function EntityForm({
  type,
  handleClose,
  householdId,
  entityId,
  header,
  isTrowser,
  navigateAfterCreate,
  stage,
  isHypotheticalEntity = false,
}: EntityFormProps) {
  const { showFeedback } = useFeedback();
  const subformConfig = useEntitySubformConfigs(
    type,
    householdId,
    entityId,
    stage
  );
  const [data, error] = useData(subformConfig, entityId);

  useEffect(() => {
    if (!error) return;
    showFeedback(
      'Could not load form data. Please refresh the page and try again.'
    );
  }, [error, showFeedback]);

  return (
    <ErrorBoundary fallback={<ErrorFeedbackFallback />}>
      <Stack spacing={1} height="100%">
        {!data && (
          <Loader
            boxProps={{
              sx: {
                textAlign: 'center',
                my: 3,
              },
            }}
          />
        )}
        {data && (
          <EntitySubforms
            subformConfig={subformConfig}
            data={data}
            handleClose={handleClose}
            householdId={householdId}
            header={header}
            isTrowser={isTrowser}
            navigateAfterCreate={navigateAfterCreate}
            isEdit={!!entityId}
            entityId={entityId}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- unforunately data[BASIC_INFORMATION] is typed as any in useSubforms, disabled due to linter refactor
            subtypeId={data[BASIC_INFORMATION]?.subtypeId}
            isHypotheticalEntity={isHypotheticalEntity}
          />
        )}
      </Stack>
    </ErrorBoundary>
  );
}
