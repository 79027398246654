import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { TextInput } from '@/components/form/baseInputs/TextInput';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { Alert } from '@/components/notifications/Alert/Alert';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { useSeedAiSuggestionsMutation } from '@/pages/internal/DevToolsPage/SeedAISuggestionsButton/graphql/SeedAISuggestions.generated';

export function SeedAISuggestionsButton() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [entityId, setEntityId] = useState<string>('');
  const [entityPageLink, setEntityPageLink] = useState<string>('');

  const [seedAISuggestions, { loading }] = useSeedAiSuggestionsMutation({
    onError: (err) => {
      showFeedback(`Failed to seed entity suggestions: ${err.message}`);
      reportError('could not seed entity suggestions', err);
    },
    onCompleted: (data) => {
      const {
        id,
        household: { id: householdId },
      } = data.seedEntityWithAISuggestions;

      const link = getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
        {
          householdId,
          entityId: id,
        }
      );

      setEntityPageLink(link);
    },
  });

  return (
    <HeaderCard heading="Seed Entity with AI Suggestions">
      <Stack spacing={2}>
        <TextInput
          label="Entity ID"
          testId="text-input-devTools.seedAISuggestions.entityId"
          value={entityId}
          onChange={(e) => setEntityId(e.target.value)}
        />
        <Button
          size="md"
          variant="primary"
          onClick={() =>
            seedAISuggestions({ variables: { entityID: entityId } })
          }
          loading={loading}
          disabled={!entityId}
        >
          Seed entity with suggestions
        </Button>
        {entityPageLink && (
          <Alert size="sm" severity="success">
            <NavLink to={entityPageLink}>
              <Typography variant="body1">
                Go to the seeded entity {'→'}
              </Typography>
            </NavLink>
          </Alert>
        )}
      </Stack>
    </HeaderCard>
  );
}
