import Decimal from 'decimal.js';

import {
  CharitableTrustCalculationMethod,
  CharitableTrustOptimizationTarget,
  CharitableTrustPayoutFrequency,
  CharitableTrustPayoutKind,
  CharitableTrustTermKind,
  CharitableTrustUnitrustKind,
} from '@/types/schema';
import { PathsOf } from '@/types/subform';

import { CharitableTrustDesignerBasicInformationForm } from '../CharitableTrustDesignerBasicInformation/CharitableTrustDesignerBasicInformation.types';
import { PreTaxReturnLimitModalForm } from '../PreTaxReturnLimitModal/PreTaxReturnLimitModal.types';

export const NAMESPACE = 'CHARITABLE_TRUST_DESIGNER_ANALYSIS' as const;

export interface CharitableTrustDesignerAnalysisDonor {
  id: string;
  dateOfBirth: Date | null;
  displayName: string;
}

export type CharitableTrustDesignerPreTaxReturnModel =
  | 'low'
  | 'medium'
  | 'high';

export interface CharitableTrustDesignerAnalysisForm
  extends CharitableTrustDesignerBasicInformationForm,
    PreTaxReturnLimitModalForm {
  [NAMESPACE]: {
    assets: {
      value: Decimal | null;
      costBasis: Decimal | null;
      notes: string;
    };
    term: {
      kind: CharitableTrustTermKind;
      length: number;
      donors: CharitableTrustDesignerAnalysisDonor[];
      rate7520: Decimal | null;
    };
    payouts: {
      kind: CharitableTrustPayoutKind;
      unitrustKind: CharitableTrustUnitrustKind;
      calculationMethod: CharitableTrustCalculationMethod;
      optimizationTarget: CharitableTrustOptimizationTarget;
      frequency: CharitableTrustPayoutFrequency;
      annuityPaymentAmount: Decimal;
      unitrustPayoutPercent: Decimal;
    };
    analysis: {
      preTaxReturnModel: CharitableTrustDesignerPreTaxReturnModel;
      yearOfAnalysis: number;
    };
  };
}

export type CharitableTrustDesignerAnalysisFormPaths =
  PathsOf<CharitableTrustDesignerAnalysisForm>;
