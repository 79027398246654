import { EditSaveAction, EntityMapActionFn } from '../types';

export const editSaveAction: EntityMapActionFn<EditSaveAction> = (
  state,
  _action,
  queueEffect
) => {
  queueEffect({ type: 'editMutationEffect' });
  return state;
};
