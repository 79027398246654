import { createContext, PropsWithChildren } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export const VARIANT_CLT = 'clt' as const;
export const VARIANT_CRT = 'crt' as const;

export type CharitableTrustDesignerVariant =
  | typeof VARIANT_CLT
  | typeof VARIANT_CRT;

function isCharitableTrustDesignerVariant(
  variant?: string | null
): variant is CharitableTrustDesignerVariant {
  if (!variant || !['crt', 'clt'].includes(variant)) {
    throw new TypeError('Invalid variant for charitable trust');
  }
  return true;
}

export interface CharitableTrustDesignerContextType {
  variant: CharitableTrustDesignerVariant;
  householdId: string;
  proposalId: string;
}

export const CharitableTrustDesignerContext =
  createContext<CharitableTrustDesignerContextType>({
    variant: 'crt',
    proposalId: '',
    householdId: '',
  });

export function CharitableTrustDesignerProvider({
  variant,
  householdId,
  proposalId,
  children,
}: PropsWithChildren<{
  variant?: string | null;
  householdId: string;
  proposalId: string;
}>) {
  if (!isCharitableTrustDesignerVariant(variant)) {
    throw new TypeError('Invalid variant for charitable trust');
  }

  return (
    <CharitableTrustDesignerContext.Provider
      value={{ variant, householdId, proposalId }}
    >
      {children}
    </CharitableTrustDesignerContext.Provider>
  );
}

/**
 * To check the variant, use `useIsCRT` instead, so all checks are in the same direction.
 * @returns CharitableTrustDesignerContextType
 */
export function useCharitableTrustDesignerContext(): CharitableTrustDesignerContextType {
  return useGuardedContext(
    CharitableTrustDesignerContext,
    'CharitableTrustDesignerProvider'
  );
}

/**
 * To make the code more internally consistent, CRT/CLT differences should use this function to
 * branch in the same direction each time.
 * @returns boolean
 */
export function useIsCRT(): boolean {
  const { variant } = useCharitableTrustDesignerContext();
  return variant === VARIANT_CRT;
}
