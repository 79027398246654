import { Outlet } from 'react-router-dom';

import { GlobalSidebar } from '@/modules/globalSidebar/GlobalSidebar';

import { PrimaryAppBar } from './AppBar/PrimaryAppBar';
import { AppBarLayout } from './AppBarLayout/AppBarLayout';

export function PrimaryLayout() {
  return (
    <AppBarLayout
      sidebarLayout="primary"
      slots={{
        AppBar: {
          component: PrimaryAppBar,
          props: {},
        },

        RightSidebar: {
          component: GlobalSidebar,
          props: {},
        },
      }}
    >
      <Outlet />
    </AppBarLayout>
  );
}
