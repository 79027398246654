// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SyncHouseholdsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.HouseholdWhereInput>;
}>;


export type SyncHouseholdsQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, displayName: string } | null } | null> | null } };


export const SyncHouseholdsDocument = gql`
    query SyncHouseholds($where: HouseholdWhereInput) {
  households(where: $where) {
    edges {
      node {
        id
        displayName
      }
    }
  }
}
    `;

/**
 * __useSyncHouseholdsQuery__
 *
 * To run a query within a React component, call `useSyncHouseholdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSyncHouseholdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSyncHouseholdsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useSyncHouseholdsQuery(baseOptions?: Apollo.QueryHookOptions<SyncHouseholdsQuery, SyncHouseholdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SyncHouseholdsQuery, SyncHouseholdsQueryVariables>(SyncHouseholdsDocument, options);
      }
export function useSyncHouseholdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SyncHouseholdsQuery, SyncHouseholdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SyncHouseholdsQuery, SyncHouseholdsQueryVariables>(SyncHouseholdsDocument, options);
        }
export function useSyncHouseholdsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SyncHouseholdsQuery, SyncHouseholdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SyncHouseholdsQuery, SyncHouseholdsQueryVariables>(SyncHouseholdsDocument, options);
        }
export type SyncHouseholdsQueryHookResult = ReturnType<typeof useSyncHouseholdsQuery>;
export type SyncHouseholdsLazyQueryHookResult = ReturnType<typeof useSyncHouseholdsLazyQuery>;
export type SyncHouseholdsSuspenseQueryHookResult = ReturnType<typeof useSyncHouseholdsSuspenseQuery>;
export type SyncHouseholdsQueryResult = Apollo.QueryResult<SyncHouseholdsQuery, SyncHouseholdsQueryVariables>;
export const Operations = {
  Query: {
    SyncHouseholds: 'SyncHouseholds' as const
  }
}