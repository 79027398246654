import {
  ProfessionalTeamRoleKind,
  ProfessionalTeamRolePowerOfAttorneyKind,
} from '@/types/schema';

export type PowerOfAttorneyCategory =
  | ProfessionalTeamRolePowerOfAttorneyKind
  | '';

export interface ClientProfessionalTeamRoleMember {
  memberId: string;
  roleId: string;
  powerOfAttorneyKind: PowerOfAttorneyCategory;
}
export interface ClientProfessionalTeamRole {
  members: ClientProfessionalTeamRoleMember[];
  isJustCreated: boolean;
  kind: ProfessionalTeamRoleKind;
  otherRoleName?: string;
}

export function getEmptyRoleForKind(
  kind: ProfessionalTeamRoleKind,
  opts?: {
    isJustCreated?: boolean;
  }
): ClientProfessionalTeamRole {
  return {
    isJustCreated: opts?.isJustCreated ?? false,
    kind,
    members: [{ memberId: '', roleId: '', powerOfAttorneyKind: '' }],
    otherRoleName: '',
  };
}

export type ClientProfessionalTeamRoleMap = Record<
  ClientProfessionalTeamMemberRoleKey,
  ClientProfessionalTeamRole
>;

export interface ClientProfessionalTeamForm {
  advisorClientRoles: Partial<ClientProfessionalTeamRoleMap>;
  principalClientIdTeamMemberMap: Record<
    string,
    Partial<ClientProfessionalTeamRoleMap>
  >;
}

export type ClientProfessionalTeamMemberRoleKey =
  | ProfessionalTeamRoleKind
  | `OTHER-${string}`;
