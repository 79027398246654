import { Box, Stack } from '@mui/material';
import { noop } from 'lodash';

import { Legend } from '@/components/charts/Legend/Legend';
import { EstateWaterfallSummaryBarCharts } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/EstateWaterfallSummaryBarCharts';
import { useEstateWaterfallSummaryLegendSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useEstateWaterfallSummaryLegend.hook';
import { useWaterfallSummaryBarChartSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useWaterfallSummaryBarChartSections';
import { EstateWaterfallSummaryTiles } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryTiles/EstateWaterfallSummaryTiles';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';

import { ClientPresentationDesigner_EstateWaterfallFragment } from '../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { PresentationSlideSecondaryHeading } from '../components/PresentationSlideSecondaryHeading';
import { PresentationSlideRegistrationDetails } from '../entities/entityPresentations.types';
import { PRESENTATION_SPACING_UNITS } from '../presentation.constants';
import { PresentationSlide } from '../PresentationSlide';
import { DisclaimerCallout } from './DisclaimerCallout';
import { GrowthAssumptions } from './GrowthAssumptions';

export interface EstateWaterfallSummarySlideProps
  extends PresentationSlideRegistrationDetails {
  waterfall: ClientPresentationDesigner_EstateWaterfallFragment;
}
export function EstateWaterfallSummarySlide({
  waterfall,
  ...registrationProps
}: EstateWaterfallSummarySlideProps) {
  useRegisterSlide({
    displayName: 'Summary',
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  const waterfallSections = useWaterfallSummaryBarChartSections({
    waterfall,
    onOpenSectionSummaryPanel: noop,
  });

  const legendSections =
    useEstateWaterfallSummaryLegendSections(waterfallSections);

  return (
    <PresentationSlide.Slide
      id={registrationProps.slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading heading={waterfall.displayName} />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={waterfall.household?.displayName ?? null}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <Stack
        spacing={3}
        justifyContent="space-between"
        p={PRESENTATION_SPACING_UNITS}
        height="100%"
      >
        <Stack spacing={3} height="100%">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <GrowthAssumptions
              slideTitle="Waterfall summary"
              waterfall={waterfall}
            />
          </Stack>
          <EstateWaterfallSummaryBarCharts sections={waterfallSections} />
          <Box sx={{ maxHeight: '478px' }}>
            <Box sx={{ transform: 'scale(0.8)', transformOrigin: '50% 0' }}>
              <EstateWaterfallSummaryTiles waterfall={waterfall} />
            </Box>
          </Box>
          <div style={{ marginTop: 'auto' }}>
            <Legend sections={legendSections} />
          </div>
        </Stack>
        <DisclaimerCallout waterfall={waterfall} />
      </Stack>
    </PresentationSlide.Slide>
  );
}
