// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteSummaryTemplateTaskMutationVariables = Types.Exact<{
  taskID: Types.Scalars['ID']['input'];
  entityId: Types.Scalars['ID']['input'];
}>;


export type CompleteSummaryTemplateTaskMutation = { __typename?: 'Mutation', completeEntityTask: { __typename?: 'EntityTask', id: string }, generateGRATDesignSummaryV2: { __typename?: 'Document', id: string, entity?: { __typename?: 'Entity', id: string } | null } };


export const CompleteSummaryTemplateTaskDocument = gql`
    mutation CompleteSummaryTemplateTask($taskID: ID!, $entityId: ID!) {
  completeEntityTask(id: $taskID) {
    id
  }
  generateGRATDesignSummaryV2(entityID: $entityId) {
    id
    entity {
      id
    }
  }
}
    `;
export type CompleteSummaryTemplateTaskMutationFn = Apollo.MutationFunction<CompleteSummaryTemplateTaskMutation, CompleteSummaryTemplateTaskMutationVariables>;

/**
 * __useCompleteSummaryTemplateTaskMutation__
 *
 * To run a mutation, you first call `useCompleteSummaryTemplateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSummaryTemplateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSummaryTemplateTaskMutation, { data, loading, error }] = useCompleteSummaryTemplateTaskMutation({
 *   variables: {
 *      taskID: // value for 'taskID'
 *      entityId: // value for 'entityId'
 *   },
 * });
 */
export function useCompleteSummaryTemplateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CompleteSummaryTemplateTaskMutation, CompleteSummaryTemplateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteSummaryTemplateTaskMutation, CompleteSummaryTemplateTaskMutationVariables>(CompleteSummaryTemplateTaskDocument, options);
      }
export type CompleteSummaryTemplateTaskMutationHookResult = ReturnType<typeof useCompleteSummaryTemplateTaskMutation>;
export type CompleteSummaryTemplateTaskMutationResult = Apollo.MutationResult<CompleteSummaryTemplateTaskMutation>;
export type CompleteSummaryTemplateTaskMutationOptions = Apollo.BaseMutationOptions<CompleteSummaryTemplateTaskMutation, CompleteSummaryTemplateTaskMutationVariables>;
export const Operations = {
  Mutation: {
    CompleteSummaryTemplateTask: 'CompleteSummaryTemplateTask' as const
  }
}