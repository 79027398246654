// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AsyncGenerateSummaryMutationVariables = Types.Exact<{
  input: Types.SummarizeDocumentsInput;
}>;


export type AsyncGenerateSummaryMutation = { __typename?: 'Mutation', asyncSummarizeDocuments: Array<{ __typename?: 'AsyncJob', id: string }> };


export const AsyncGenerateSummaryDocument = gql`
    mutation AsyncGenerateSummary($input: SummarizeDocumentsInput!) {
  asyncSummarizeDocuments(input: $input) {
    id
  }
}
    `;
export type AsyncGenerateSummaryMutationFn = Apollo.MutationFunction<AsyncGenerateSummaryMutation, AsyncGenerateSummaryMutationVariables>;

/**
 * __useAsyncGenerateSummaryMutation__
 *
 * To run a mutation, you first call `useAsyncGenerateSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAsyncGenerateSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [asyncGenerateSummaryMutation, { data, loading, error }] = useAsyncGenerateSummaryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAsyncGenerateSummaryMutation(baseOptions?: Apollo.MutationHookOptions<AsyncGenerateSummaryMutation, AsyncGenerateSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AsyncGenerateSummaryMutation, AsyncGenerateSummaryMutationVariables>(AsyncGenerateSummaryDocument, options);
      }
export type AsyncGenerateSummaryMutationHookResult = ReturnType<typeof useAsyncGenerateSummaryMutation>;
export type AsyncGenerateSummaryMutationResult = Apollo.MutationResult<AsyncGenerateSummaryMutation>;
export type AsyncGenerateSummaryMutationOptions = Apollo.BaseMutationOptions<AsyncGenerateSummaryMutation, AsyncGenerateSummaryMutationVariables>;
export const Operations = {
  Mutation: {
    AsyncGenerateSummary: 'AsyncGenerateSummary' as const
  }
}