import { Box, BoxProps } from '@mui/material';
import Decimal from 'decimal.js';
import { ComponentType } from 'react';

import { ClientPresentationBundleTypes } from '../clientPresentation/clientPresentation.types';
import { ClientPresentationDesigner_EstateWaterfallFragment } from '../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { useUnguardedClientPresentationDesignerContext } from '../clientPresentation/contexts/clientPresentationDesigner.context';
import { useRegisterBundle } from '../clientPresentation/hooks/useRegisterBundle';
import { EstateWaterfallBeneficiariesSlide } from './EstateWaterfall.Beneficiaries';
import { EstateWaterfallDiagramSlide } from './EstateWaterfall.Diagram';
import { EstateWaterfallSummarySlide } from './EstateWaterfall.Summary';
import { EstateWaterfallTaxSummarySlide } from './EstateWaterfall.TaxSummary';
import {
  generateWaterfallBundleId,
  getPresentationWaterfallSlideProps,
} from './estateWaterfallPresentation.utils';

export interface EstateWaterfallPresentationBundleProps {
  waterfall: ClientPresentationDesigner_EstateWaterfallFragment;
  federalEstateTaxPercent: Decimal | null | undefined;
  SlideWrapper?: ComponentType<BoxProps>;
}

export function EstateWaterfallPresentationBundle({
  SlideWrapper = Box,
  waterfall,
  federalEstateTaxPercent,
}: EstateWaterfallPresentationBundleProps) {
  const bundleId = generateWaterfallBundleId(waterfall.id);

  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  useRegisterBundle({
    type: ClientPresentationBundleTypes.ESTATE_WATERFALL,
    id: bundleId,
    displayName: waterfall.displayName,
    identifier: waterfall.id,
  });

  if (!shouldShowItem(waterfall.id)) {
    return null;
  }

  return (
    <>
      <SlideWrapper key={`${waterfall.id}-summary`}>
        <EstateWaterfallSummarySlide
          waterfall={waterfall}
          {...getPresentationWaterfallSlideProps(waterfall.id, 'summary')}
        />
      </SlideWrapper>
      <SlideWrapper key={`${waterfall.id}-diagram`}>
        <EstateWaterfallDiagramSlide
          waterfall={waterfall}
          {...getPresentationWaterfallSlideProps(waterfall.id, 'diagram')}
        />
      </SlideWrapper>
      {/* this bundle will render the SlideWrapper itself*/}
      <EstateWaterfallTaxSummarySlide
        key={`${waterfall.id}-tax-summary`}
        waterfall={waterfall}
        federalEstateTaxPercent={federalEstateTaxPercent}
        SlideWrapper={SlideWrapper}
        {...getPresentationWaterfallSlideProps(waterfall.id, 'tax-summary')}
      />
      <EstateWaterfallBeneficiariesSlide
        waterfall={waterfall}
        SlideWrapper={SlideWrapper}
        {...getPresentationWaterfallSlideProps(waterfall.id, 'beneficiaries')}
      />
    </>
  );
}
