// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDispositiveProvisionsTemplateMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateDispositiveProvisionTemplateInput;
}>;


export type CreateDispositiveProvisionsTemplateMutation = { __typename?: 'Mutation', createDispositiveProvisionTemplate: { __typename?: 'DispositiveProvisionTemplate', id: string } };

export type UpdateDispositiveProvisionTemplateMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateDispositiveProvisionTemplateInput;
}>;


export type UpdateDispositiveProvisionTemplateMutation = { __typename?: 'Mutation', updateDispositiveProvisionTemplate: { __typename?: 'DispositiveProvisionTemplate', id: string } };

export type DeleteDispositiveProvisionsTemplateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteDispositiveProvisionsTemplateMutation = { __typename?: 'Mutation', deleteDispositiveProvisionTemplate: string };


export const CreateDispositiveProvisionsTemplateDocument = gql`
    mutation CreateDispositiveProvisionsTemplate($input: AugmentedCreateDispositiveProvisionTemplateInput!) {
  createDispositiveProvisionTemplate(input: $input) {
    id
  }
}
    `;
export type CreateDispositiveProvisionsTemplateMutationFn = Apollo.MutationFunction<CreateDispositiveProvisionsTemplateMutation, CreateDispositiveProvisionsTemplateMutationVariables>;

/**
 * __useCreateDispositiveProvisionsTemplateMutation__
 *
 * To run a mutation, you first call `useCreateDispositiveProvisionsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDispositiveProvisionsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDispositiveProvisionsTemplateMutation, { data, loading, error }] = useCreateDispositiveProvisionsTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDispositiveProvisionsTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateDispositiveProvisionsTemplateMutation, CreateDispositiveProvisionsTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDispositiveProvisionsTemplateMutation, CreateDispositiveProvisionsTemplateMutationVariables>(CreateDispositiveProvisionsTemplateDocument, options);
      }
export type CreateDispositiveProvisionsTemplateMutationHookResult = ReturnType<typeof useCreateDispositiveProvisionsTemplateMutation>;
export type CreateDispositiveProvisionsTemplateMutationResult = Apollo.MutationResult<CreateDispositiveProvisionsTemplateMutation>;
export type CreateDispositiveProvisionsTemplateMutationOptions = Apollo.BaseMutationOptions<CreateDispositiveProvisionsTemplateMutation, CreateDispositiveProvisionsTemplateMutationVariables>;
export const UpdateDispositiveProvisionTemplateDocument = gql`
    mutation UpdateDispositiveProvisionTemplate($input: AugmentedUpdateDispositiveProvisionTemplateInput!) {
  updateDispositiveProvisionTemplate(input: $input) {
    id
  }
}
    `;
export type UpdateDispositiveProvisionTemplateMutationFn = Apollo.MutationFunction<UpdateDispositiveProvisionTemplateMutation, UpdateDispositiveProvisionTemplateMutationVariables>;

/**
 * __useUpdateDispositiveProvisionTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateDispositiveProvisionTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDispositiveProvisionTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDispositiveProvisionTemplateMutation, { data, loading, error }] = useUpdateDispositiveProvisionTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDispositiveProvisionTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDispositiveProvisionTemplateMutation, UpdateDispositiveProvisionTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDispositiveProvisionTemplateMutation, UpdateDispositiveProvisionTemplateMutationVariables>(UpdateDispositiveProvisionTemplateDocument, options);
      }
export type UpdateDispositiveProvisionTemplateMutationHookResult = ReturnType<typeof useUpdateDispositiveProvisionTemplateMutation>;
export type UpdateDispositiveProvisionTemplateMutationResult = Apollo.MutationResult<UpdateDispositiveProvisionTemplateMutation>;
export type UpdateDispositiveProvisionTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateDispositiveProvisionTemplateMutation, UpdateDispositiveProvisionTemplateMutationVariables>;
export const DeleteDispositiveProvisionsTemplateDocument = gql`
    mutation DeleteDispositiveProvisionsTemplate($id: ID!) {
  deleteDispositiveProvisionTemplate(id: $id)
}
    `;
export type DeleteDispositiveProvisionsTemplateMutationFn = Apollo.MutationFunction<DeleteDispositiveProvisionsTemplateMutation, DeleteDispositiveProvisionsTemplateMutationVariables>;

/**
 * __useDeleteDispositiveProvisionsTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteDispositiveProvisionsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDispositiveProvisionsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDispositiveProvisionsTemplateMutation, { data, loading, error }] = useDeleteDispositiveProvisionsTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDispositiveProvisionsTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDispositiveProvisionsTemplateMutation, DeleteDispositiveProvisionsTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDispositiveProvisionsTemplateMutation, DeleteDispositiveProvisionsTemplateMutationVariables>(DeleteDispositiveProvisionsTemplateDocument, options);
      }
export type DeleteDispositiveProvisionsTemplateMutationHookResult = ReturnType<typeof useDeleteDispositiveProvisionsTemplateMutation>;
export type DeleteDispositiveProvisionsTemplateMutationResult = Apollo.MutationResult<DeleteDispositiveProvisionsTemplateMutation>;
export type DeleteDispositiveProvisionsTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteDispositiveProvisionsTemplateMutation, DeleteDispositiveProvisionsTemplateMutationVariables>;
export const Operations = {
  Mutation: {
    CreateDispositiveProvisionsTemplate: 'CreateDispositiveProvisionsTemplate' as const,
    UpdateDispositiveProvisionTemplate: 'UpdateDispositiveProvisionTemplate' as const,
    DeleteDispositiveProvisionsTemplate: 'DeleteDispositiveProvisionsTemplate' as const
  }
}