import { Box, useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface EntitySubformsSidebarProps extends PropsWithChildren {
  widthPx?: number;
  backgroundColor?: string;
}

export const EntitySubformsSidebar = ({
  children,
  widthPx = 400,
  backgroundColor,
}: EntitySubformsSidebarProps) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor || theme.palette.common.white,
        p: 6,
        flexBasis: `${widthPx}px`,
        flexGrow: 0,
        boxShadow: theme.palette.shadows.innerShadowLeftward,
        overflowX: 'hidden',
        overflowY: 'auto',
      }}
    >
      {children}
    </Box>
  );
};
