import Decimal from 'decimal.js';
import { useWatch } from 'react-hook-form';

import { HelpTextVariant } from '@/components/form/baseInputs/inputTypes';
import { useFormContext } from '@/components/react-hook-form';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { useTransferDestinationOptions } from '../../HypotheticalTransfersCard/hypotheticalTransfers/hooks/useTransferDestinationOptions';
import { useHypotheticalTransferModalContext } from '../HypotheticalTransferModal.context';
import { HypotheticalTransferFormModalShape } from '../HypotheticalTransferModal.types';

export interface HelpTextType {
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  value?: Decimal;
}

interface UseHypotheticalTransferAmountHelpTextInput {
  transferAmount: Decimal | null;
  sourceId: string;
  sourceOwnedEntityId?: string;
  sourceInsurancePolicyId?: string;
}

export function useHypotheticalTransferAmountHelpText({
  transferAmount,
  sourceId,
  sourceOwnedEntityId,
  sourceInsurancePolicyId,
}: UseHypotheticalTransferAmountHelpTextInput): HelpTextType {
  const { householdId } = useHypotheticalTransferModalContext();
  const { control } = useFormContext<HypotheticalTransferFormModalShape>();
  const [_policyOrAsset] = useWatch({
    control,
    name: ['_policyOrAsset'],
  });

  const { possibleDestinations } = useTransferDestinationOptions(householdId);

  const {
    derivedData: { ownershipStakesByOwnerId, policiesByEntityId },
  } = useHypotheticalTransferModalContext();

  // We have to go through all the ownership stakes of the selected business entity
  // and calculate the sum of the owned value for this owner
  const totalOwnershipStakeAmount = sumDecimalJS(
    ownershipStakesByOwnerId[sourceId]
      ?.filter((stake) => stake.sourceOwnedEntityId === sourceOwnedEntityId)
      .map((stake) => stake.ownedValue) ?? []
  );

  if (!sourceId) {
    return {};
  }

  if (_policyOrAsset === 'asset' && !sourceOwnedEntityId) {
    return {};
  }

  if (sourceOwnedEntityId) {
    const sourceValueDisplay = formatCurrencyNoDecimals(
      totalOwnershipStakeAmount
    );
    if (transferAmount?.greaterThan(totalOwnershipStakeAmount)) {
      return {
        helpText: `Exceeds current market value of client ownership: ${sourceValueDisplay}`,
        helpTextVariant: 'warning',
      };
    } else {
      return {
        helpText: `Current market value of client ownership: ${sourceValueDisplay}`,
      };
    }
  }

  if (sourceInsurancePolicyId) {
    const policyCashValue =
      policiesByEntityId[sourceId]?.find(
        (p) => p.sourceInsurancePolicyId == sourceInsurancePolicyId
      )?.cashValue ?? new Decimal(0);

    const sourceValueDisplay = formatCurrencyNoDecimals(policyCashValue);
    if (transferAmount?.greaterThan(policyCashValue)) {
      return {
        helpText: `Exceeds current cash value: ${sourceValueDisplay}`,
        helpTextVariant: 'warning',
      };
    } else {
      return {
        helpText: `Current cash value: ${sourceValueDisplay}`,
        value: policyCashValue,
      };
    }
  }

  const source = possibleDestinations.find(
    (destination) => destination.id === sourceId
  );

  const sourceValue = source?.currentValue || new Decimal(0);

  const sourceValueDisplay = formatCurrencyNoDecimals(sourceValue);

  if (transferAmount?.greaterThan(sourceValue)) {
    return {
      helpText: `Exceeds current asset value exclusive of transfers: ${sourceValueDisplay}`,
      helpTextVariant: 'warning',
    };
  } else {
    return {
      helpText: `Max transfer: ${sourceValueDisplay}`,
    };
  }
}
