import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { useMemo } from 'react';
import { Path, useWatch } from 'react-hook-form';

import { ButtonGroupInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { useFormContext } from '@/components/react-hook-form';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { GiftAnnualPreTaxReturn } from '@/modules/content/tooltipContent/GiftAnnualPreTaxReturn';
import { GiftExemptionSunsets } from '@/modules/content/tooltipContent/GiftExemptionSunsets';
import { GiftingProposalSelectedPreTaxReturnCategory } from '@/types/schema';
import { formatPercentNoDecimals } from '@/utils/formatting/percent';

import { GiftDesignerModelScenariosFormShape } from './GiftDesignerModelScenariosForm.types';
import { validateYearRange } from './GiftDesignerModelScenariosForm.utils';

type GiftDesignerModelScenariosFormPaths =
  Path<GiftDesignerModelScenariosFormShape>;

export const AnnualPreTaxReturn = () => {
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();
  const taxReturnLimits = useWatch({
    control,
    name: 'taxReturnLimits',
  });

  const options = useMemo(() => {
    if (!taxReturnLimits) {
      return new Array<ButtonGroupInputOption<string>>(3).fill({
        display: EMPTY_CONTENT_HYPHEN,
        value: '',
        buttonProps: {
          loading: true,
        },
      });
    }

    return compact(
      [
        {
          value: taxReturnLimits.lowPreTaxReturn,
          category: GiftingProposalSelectedPreTaxReturnCategory.Low,
        },
        {
          value: taxReturnLimits.mediumPreTaxReturn,
          category: GiftingProposalSelectedPreTaxReturnCategory.Medium,
        },
        {
          value: taxReturnLimits.highPreTaxReturn,
          category: GiftingProposalSelectedPreTaxReturnCategory.High,
        },
      ].map(({ value, category }) => {
        if (value) {
          return {
            display: `${formatPercentNoDecimals(value)}%`,
            value: category,
          };
        }

        return null;
      })
    );
  }, [taxReturnLimits]);

  return (
    <div className="printVisible">
      <FormAwareButtonGroup<GiftDesignerModelScenariosFormShape>
        control={control}
        fieldName={
          'annualPreTaxReturn' as const satisfies GiftDesignerModelScenariosFormPaths
        }
        variant="dark"
        label="Annual pre-tax return"
        contextualHelp={<GiftAnnualPreTaxReturn />}
        options={options}
      />
    </div>
  );
};

const maxExemptionGrowthRateValidator = (value: unknown) => {
  const rate = Number(value);
  if (isNaN(rate)) {
    return `Rate must be a number`;
  }
  if (rate > 1000) {
    return 'Exemption growth rate is too high';
  }
  return undefined;
};

export const ExemptionGrowth = () => {
  // Omit type to prevent infinite type error
  const { control } = useFormContext();

  return (
    <FormAwareFormattedNumberInput
      control={control}
      fieldName={
        'exemptionGrowthRate' as const satisfies GiftDesignerModelScenariosFormPaths
      }
      label="Exemption growth"
      isDecimalJSInput
      endAdornment="%"
      validateOnChange
      validation={{
        max: maxExemptionGrowthRateValidator,
      }}
    />
  );
};

interface YearOfAnalysisProps {
  lengthOfAnalysis: Decimal;
}

// For some reason including scenarios on this type causes the type of value in the validation functions to blow up
type OptimizedFormShape = Omit<
  GiftDesignerModelScenariosFormShape,
  'scenarios'
>;

export const YearOfAnalysis = ({ lengthOfAnalysis }: YearOfAnalysisProps) => {
  const { control } = useFormContext<OptimizedFormShape>();

  const startYear = getYear(new Date());
  const endYear = lengthOfAnalysis?.plus(startYear).toString();

  const label = `Year of analysis (${startYear}-${endYear})`;
  const errorMessage = `Enter a year between ${startYear} and ${endYear}`;

  return (
    <FormAwareFormattedNumberInput<OptimizedFormShape>
      control={control}
      fieldName={
        'yearOfAnalysis' as const satisfies GiftDesignerModelScenariosFormPaths
      }
      label={label}
      required
      validateOnChange
      validation={{
        minMax: (value) =>
          validateYearRange(value, lengthOfAnalysis, {
            errorMessage,
          }),
      }}
    />
  );
};

export const ShowAfterEstateTax = () => {
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();

  return (
    <FormAwareSwitch<GiftDesignerModelScenariosFormShape>
      control={control}
      fieldName={
        'showAfterEstateTax' as const satisfies GiftDesignerModelScenariosFormPaths
      }
      label="Show after estate tax"
      labelPosition="right"
      variant="dark"
    />
  );
};

export const ExemptionSunsets = () => {
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();

  return (
    <FormAwareSwitch<GiftDesignerModelScenariosFormShape>
      control={control}
      fieldName={
        'exemptionSunsets' as const satisfies GiftDesignerModelScenariosFormPaths
      }
      label="Exemption sunsets in 2025"
      labelPosition="right"
      variant="dark"
      contextualHelp={<GiftExemptionSunsets />}
    />
  );
};
