import { Box, Stack, Typography } from '@mui/material';
import { compact, uniq } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';
import { useTaxStatusAssetLocationOptions } from '@/modules/entities/TaxStatusSubform/hooks/useTaxStatusAssetLocationOptions';
import { EntityType } from '@/modules/entities/types/EntityType';

import {
  CREATE_ENTITY_SHORT_FORM_NAMESPACE,
  EntityShortFormPaths,
  EntityShortFormShape,
} from '../CreateEntityShortForm.types';
import { useDefaultEstateInclusionStatus } from '../hooks/useDefaultEstateInclusionStatus';

export interface AssetLocationProps {
  entityType: EntityType;
}

export function AssetLocation({ entityType }: AssetLocationProps) {
  const { control } = useFormContext<EntityShortFormShape>();

  const form = useWatch({
    name: `${CREATE_ENTITY_SHORT_FORM_NAMESPACE}` as const satisfies EntityShortFormPaths,
    control,
  });

  const { principals, comparablePrincipals, principalClientProfileId } =
    useMemo(() => {
      return {
        principals: form.principals,
        comparablePrincipals: JSON.stringify(form.principals),
        principalClientProfileId: form.selectedSinglePrincipal,
      };
    }, [form.principals, form.selectedSinglePrincipal]);

  const selectedGrantorClientProfileIds = useMemo(() => {
    const selectedPrincipalsClientProfileIds = Object.entries(principals)
      .filter(([_id, { isIncluded }]) => isIncluded)
      .map(([id]) => id);

    return compact(
      uniq([...selectedPrincipalsClientProfileIds, principalClientProfileId])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [principalClientProfileId, comparablePrincipals]);

  // Note, the TaxStatusSubform also is dependent on this hook and performs similar logic
  // to determine the available state estate taxes.
  const { assetLocationOptions: assetLocationOptionsV2, stateTaxOptions } =
    useTaxStatusAssetLocationOptions({
      fieldNamePrefix: CREATE_ENTITY_SHORT_FORM_NAMESPACE,
      selectedGrantorClientProfileIds,
    });

  useDefaultEstateInclusionStatus({ entityType, stateTaxOptions });

  return (
    <Card
      variant="filled"
      sx={{
        pb: 4,
      }}
    >
      <Stack spacing={2}>
        <Typography variant="h4">Asset location</Typography>
        <Stack direction="row" width="100%">
          <Stack justifyContent="flex-end" spacing={1}>
            <Stack direction="row" spacing={3} alignItems="center">
              <Box width="60px" />
              <Typography width="60px" variant="label">
                In estate
              </Typography>
              <Typography width="86px" variant="label">
                Out of estate
              </Typography>
            </Stack>
            {assetLocationOptionsV2.map(({ label, fieldName, options }) => (
              <Stack
                direction="row"
                spacing={3}
                alignItems="center"
                key={label}
              >
                <Typography width="60px" variant="label">
                  {label}
                </Typography>
                <FormAwareRadioGroup<EntityShortFormShape>
                  sx={{
                    flexFlow: 'nowrap',
                  }}
                  labelSx={{
                    width: '84px', // 60px width + 24px padding
                  }}
                  row={true}
                  options={options}
                  fieldName={fieldName}
                  control={control}
                />
              </Stack>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}
