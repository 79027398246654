import { useIRSConstants } from '@/modules/irs/useIRSConstants';

export function useLifetimeExemptionAmount({
  numGrantors,
}: {
  numGrantors: number;
}) {
  const { lifetimeExemptionAmount } = useIRSConstants();

  return {
    forAllGrantors: lifetimeExemptionAmount?.times(numGrantors),
    perGrantor: lifetimeExemptionAmount,
  };
}
