// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateEntityFromShortFormMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateEntityInput;
}>;


export type CreateEntityFromShortFormMutation = { __typename?: 'Mutation', createEntity: { __typename?: 'Entity', id: string, kind: Types.EntityKind, subtype: { __typename?: 'CCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'CLTTrust', id: string, displayName: string } | { __typename?: 'CRTTrust', id: string, displayName: string } | { __typename?: 'CustodialPersonalAccount', id: string, displayName: string } | { __typename?: 'DonorAdvisedFund', id: string, displayName: string } | { __typename?: 'GPBusinessEntity', id: string, displayName: string } | { __typename?: 'GRATTrust', id: string, displayName: string } | { __typename?: 'ILITTrust', id: string, displayName: string } | { __typename?: 'IndividualPersonalAccount', id: string, displayName: string } | { __typename?: 'InsurancePersonalAccount', id: string, displayName: string } | { __typename?: 'IrrevocableTrust', id: string, displayName: string } | { __typename?: 'JointPersonalAccount', id: string, displayName: string } | { __typename?: 'LLCBusinessEntity', id: string, displayName: string } | { __typename?: 'LPBusinessEntity', id: string, displayName: string } | { __typename?: 'PrivateFoundation', id: string, displayName: string } | { __typename?: 'QPRTTrust', id: string, displayName: string } | { __typename?: 'QualifiedTuitionPersonalAccount', id: string, displayName: string } | { __typename?: 'RetirementPersonalAccount', id: string, displayName: string } | { __typename?: 'RevocableTrust', id: string, displayName: string } | { __typename?: 'SCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'SLATTrust', id: string, displayName: string } | { __typename?: 'SoleProprietorshipBusinessEntity', id: string, displayName: string } } };


export const CreateEntityFromShortFormDocument = gql`
    mutation CreateEntityFromShortForm($input: AugmentedCreateEntityInput!) {
  createEntity(input: $input) {
    id
    kind
    subtype {
      id
      displayName
    }
  }
}
    `;
export type CreateEntityFromShortFormMutationFn = Apollo.MutationFunction<CreateEntityFromShortFormMutation, CreateEntityFromShortFormMutationVariables>;

/**
 * __useCreateEntityFromShortFormMutation__
 *
 * To run a mutation, you first call `useCreateEntityFromShortFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityFromShortFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityFromShortFormMutation, { data, loading, error }] = useCreateEntityFromShortFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEntityFromShortFormMutation(baseOptions?: Apollo.MutationHookOptions<CreateEntityFromShortFormMutation, CreateEntityFromShortFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEntityFromShortFormMutation, CreateEntityFromShortFormMutationVariables>(CreateEntityFromShortFormDocument, options);
      }
export type CreateEntityFromShortFormMutationHookResult = ReturnType<typeof useCreateEntityFromShortFormMutation>;
export type CreateEntityFromShortFormMutationResult = Apollo.MutationResult<CreateEntityFromShortFormMutation>;
export type CreateEntityFromShortFormMutationOptions = Apollo.BaseMutationOptions<CreateEntityFromShortFormMutation, CreateEntityFromShortFormMutationVariables>;
export const Operations = {
  Mutation: {
    CreateEntityFromShortForm: 'CreateEntityFromShortForm' as const
  }
}