import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import {
  ClientProfileRelationshipType,
  DispositiveProvisionTemplateKind,
  DispositiveProvisionTransferTaxKind,
  EntityKind,
} from '@/types/schema';

import {
  DispositiveProvisionsRecipientTemplateRules,
  DispositiveProvisionsRecipientTypes,
} from './DispositiveProvisionsRecipient.types';

export const DispositiveProvisionsTemplateRules: Record<
  DispositiveProvisionTemplateKind | 'NO_TEMPLATE',
  DispositiveProvisionsRecipientTemplateRules
> = {
  [DispositiveProvisionTemplateKind.PouroverWill]: {
    templateRequiresRecipient: true,
    templateRequiresSpecificPayout: true,
    templateRequiresSpecificTaxKind: true,
    alwaysShowNotes: true,
    recipientConfiguration: {
      allowsIndividualRecipient: false,
      allowsOrganizationRecipient: false,
      allowsEntityRecipient: true,
      allowsTestamentaryEntityRecipient: false,
      customEntityFilter: (entity) => entity.kind === EntityKind.RevocableTrust,
      createRecipientTypes: [DispositiveProvisionsRecipientTypes.Entity],
      createEntityType: ENTITY_TYPES.REVOCABLE_TRUST,
      forceEntityType: true,
      customTransferTaxKind: DispositiveProvisionTransferTaxKind.Pourover,
    },
  },
  [DispositiveProvisionTemplateKind.Default]: {
    templateRequiresRecipient: false,
    templateRequiresSpecificPayout: false,
    templateRequiresSpecificTaxKind: false,
    alwaysShowNotes: true,
    recipientConfiguration: {
      allowsIndividualRecipient: true,
      allowsOrganizationRecipient: true,
      allowsEntityRecipient: false,
      allowsTestamentaryEntityRecipient: true,
      createRecipientTypes: [
        DispositiveProvisionsRecipientTypes.TestamentaryEntity,
        DispositiveProvisionsRecipientTypes.Organization,
        DispositiveProvisionsRecipientTypes.Individual,
      ],
    },
  },
  [DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse]: {
    templateRequiresRecipient: true,
    templateRequiresSpecificPayout: true,
    templateRequiresSpecificTaxKind: true,
    alwaysShowNotes: true,
    recipientConfiguration: {
      allowsToSurvivingSpouse: true,
      allowsIndividualRecipient: true,
      allowsOrganizationRecipient: false,
      allowsEntityRecipient: false,
      allowsTestamentaryEntityRecipient: false,
      customIndividualFilter: (clientProfile) => {
        return !!clientProfile.relationships?.find((relationship) => {
          return relationship.type === ClientProfileRelationshipType.Spouse;
        });
      },
      createRecipientTypes: [],
    },
  },
  [DispositiveProvisionTemplateKind.MaritalTrust]: {
    templateRequiresRecipient: true,
    templateRequiresSpecificPayout: true,
    templateRequiresSpecificTaxKind: true,
    alwaysShowNotes: true,
    recipientConfiguration: {
      allowsIndividualRecipient: false,
      allowsOrganizationRecipient: false,
      allowsEntityRecipient: false,
      allowsTestamentaryEntityRecipient: true,
      createRecipientTypes: [
        DispositiveProvisionsRecipientTypes.TestamentaryEntity,
      ],
    },
  },
  [DispositiveProvisionTemplateKind.AbTrust]: {
    templateRequiresRecipient: true,
    templateRequiresSpecificPayout: true,
    templateRequiresSpecificTaxKind: true,
    alwaysShowNotes: true,
    recipientConfiguration: {
      allowsIndividualRecipient: false,
      allowsOrganizationRecipient: false,
      allowsEntityRecipient: false,
      allowsTestamentaryEntityRecipient: true,
      createRecipientTypes: [
        DispositiveProvisionsRecipientTypes.TestamentaryEntity,
      ],
      displayHeaderAsInExcessOfLifetimeExclusion: true,
    },
  },
  [DispositiveProvisionTemplateKind.AbcTrust]: {
    templateRequiresRecipient: true,
    templateRequiresSpecificPayout: true,
    templateRequiresSpecificTaxKind: true,
    alwaysShowNotes: true,
    recipientConfiguration: {
      allowsIndividualRecipient: false,
      allowsOrganizationRecipient: false,
      allowsEntityRecipient: false,
      allowsTestamentaryEntityRecipient: true,
      createRecipientTypes: [
        DispositiveProvisionsRecipientTypes.TestamentaryEntity,
      ],
    },
  },
  NO_TEMPLATE: {
    templateRequiresRecipient: false,
    templateRequiresSpecificPayout: false,
    templateRequiresSpecificTaxKind: false,
    alwaysShowNotes: false,
    recipientConfiguration: {
      allowsToSurvivingSpouse: true,
      allowsIndividualRecipient: true,
      allowsOrganizationRecipient: true,
      allowsEntityRecipient: true,
      allowsTestamentaryEntityRecipient: true,
      createRecipientTypes: [
        DispositiveProvisionsRecipientTypes.TestamentaryEntity,
        DispositiveProvisionsRecipientTypes.Organization,
        DispositiveProvisionsRecipientTypes.Individual,
      ],
    },
  },
};
