import Decimal from 'decimal.js';

import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { WaterfallSections } from '../EstateWaterfallSummaryBarCharts/types';
import { WaterfallComparisonValues } from './WaterfallComparison';

export interface WaterfallComparisonMetric {
  value: Decimal;
  isPercent: boolean;
}

// Returns the percent increase when calculable or the difference in value
// when not calculable.
// E.g., 1 -> 1.4 = 40 or 0 -> 100 = 100
export function getPercentIncreaseOrValueForSections(
  sections: (0 | 1 | 2 | 3)[],
  waterfallValues: WaterfallComparisonValues
): WaterfallComparisonMetric {
  const sourceWealth = getDecimalValueForSections(
    sections,
    waterfallValues.source
  );
  const hypotheticalWealth = getDecimalValueForSections(
    sections,
    waterfallValues.hypothetical
  );

  const percentIncrease = hypotheticalWealth
    .minus(sourceWealth)
    .div(sourceWealth)
    .times(100);

  // Was a divide by 0, set the percent increase is 0
  if (percentIncrease.isNaN()) {
    return {
      value: new Decimal(0),
      isPercent: true,
    };
  }

  // Not a calculable percentage, return the value
  if (!percentIncrease.isFinite()) {
    return {
      value: hypotheticalWealth.minus(sourceWealth),
      isPercent: false,
    };
  }

  return {
    value: percentIncrease,
    isPercent: true,
  };
}

export function getDecimalValueForSections(
  sectionsItems: (0 | 1 | 2 | 3)[],
  sections: WaterfallSections
) {
  return sumDecimalJS(
    sectionsItems.map((idx) => new Decimal(sections[idx].value))
  );
}

// Returns a formatted percent when metric is a percent or a formatted value
// when the metric is a value.
export function formatPercentOrValue({
  value,
  isPercent,
}: WaterfallComparisonMetric) {
  let numDecimals = 0;
  if (value.abs().lessThan(1) && !value.isZero()) {
    // If the value has a magnitude less than 1, show 1 decimal places
    // unless the value is 0.
    numDecimals = 1;
  }

  if (isPercent) {
    if (value.isNegative()) {
      return `(${formatPercent(value.abs(), numDecimals)}%)`;
    }
    return `${formatPercent(value.abs(), numDecimals)}%`;
  }

  return formatCurrency(value, {
    maximumFractionDigits: numDecimals,
    currencySign: 'accounting',
  });
}
