import { useReactiveVar } from '@apollo/client';
import { uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { mostRecentlyViewedHouseholdsVar } from '@/graphql/reactiveVars';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { useSyncHouseholdsLazyQuery } from '../graphql/MostRecentlyViewedHouseholds.generated';

function persistMostRecentlyViewedHouseholds(
  mostRecentlyViewedHouseholds: {
    id: string;
    displayName: string;
  }[]
) {
  const key = `${LOCAL_STORAGE_KEYS.LAST_VIEWED_HOUSEHOLDS}_${location.hostname}`;
  localStorage.setItem(key, JSON.stringify(mostRecentlyViewedHouseholds));
}

function getInitialMostRecentlyViewedHouseholds() {
  if (typeof localStorage === 'undefined') return [];

  let households: {
    id: string;
    displayName: string;
  }[] = [];
  const localStorageKey = `${LOCAL_STORAGE_KEYS.LAST_VIEWED_HOUSEHOLDS}_${location.hostname}`;

  try {
    households = JSON.parse(localStorage.getItem(localStorageKey) || '[]') as {
      id: string;
      displayName: string;
    }[];
  } catch (err) {
    diagnostics.error(
      'Error parsing most recently viewed households',
      err as Error,
      {
        localStorageKey,
      }
    );
  }

  return households;
}

export function useMostRecentlyViewedHouseholds() {
  const mostRecentlyViewedHouseholds = useReactiveVar(
    mostRecentlyViewedHouseholdsVar
  );
  const [syncHouseholds, { data }] = useSyncHouseholdsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  // When we view a householdId route, we call this function which optimistically
  // adds the household to the list of most recently viewed households
  // and queries the backend to sync the household data
  const addMostRecentlyViewedHousehold = useCallback(
    async (householdId: string, displayName: string) => {
      const newHousehold = {
        id: householdId,
        displayName: displayName,
      };

      const currentMostRecentlyViewedHouseholds =
        mostRecentlyViewedHouseholdsVar();

      const newMostRecentlyViewedHouseholds = uniqBy(
        [newHousehold, ...currentMostRecentlyViewedHouseholds],
        'id'
      ).slice(0, 5);

      if (newMostRecentlyViewedHouseholds.length > 0) {
        await syncHouseholds({
          variables: {
            where: {
              idIn: newMostRecentlyViewedHouseholds.map((h) => h.id),
            },
          },
          onError: (error) => {
            diagnostics.error('Error syncing households', error);
          },
        });
      }

      mostRecentlyViewedHouseholdsVar(newMostRecentlyViewedHouseholds);
      persistMostRecentlyViewedHouseholds(newMostRecentlyViewedHouseholds);
    },
    [syncHouseholds]
  );

  const removeMostRecentlyViewedHousehold = useCallback(
    (householdId: string) => {
      const currentMostRecentlyViewedHouseholds =
        mostRecentlyViewedHouseholdsVar();
      const updatedHouseholds = currentMostRecentlyViewedHouseholds.filter(
        (household) => household.id !== householdId
      );

      mostRecentlyViewedHouseholdsVar(updatedHouseholds);
      persistMostRecentlyViewedHouseholds(updatedHouseholds);
    },
    []
  );

  useEffect(() => {
    // We might have to initialize the most recently viewed households
    if (mostRecentlyViewedHouseholds.length > 0) return;

    mostRecentlyViewedHouseholdsVar(getInitialMostRecentlyViewedHouseholds());
  }, [mostRecentlyViewedHouseholds.length]);

  // Returns the most recently viewed households with the synced display names
  // and removes any households that have been deleted
  const syncedMostRecentlyViewedHouseholds = useMemo(() => {
    const syncedHouseholds = getNodes(data?.households);
    if (!syncedHouseholds.length) return mostRecentlyViewedHouseholds;

    const syncedHouseholdIds = new Set(
      syncedHouseholds.map((household) => household.id)
    );

    // Remove any deleted households from the list
    // and update the display name if it has changed
    const updatedHouseholds = mostRecentlyViewedHouseholds
      .filter((household) => {
        const exists = syncedHouseholdIds.has(household.id);
        if (!exists) {
          removeMostRecentlyViewedHousehold(household.id);
        }
        return exists;
      })
      .map((household) => ({
        ...household,
        displayName:
          syncedHouseholds.find((h) => h.id === household.id)?.displayName ??
          EMPTY_CONTENT_HYPHEN,
      }));

    return updatedHouseholds;
  }, [
    data?.households,
    mostRecentlyViewedHouseholds,
    removeMostRecentlyViewedHousehold,
  ]);

  return {
    mostRecentlyViewedHouseholds: syncedMostRecentlyViewedHouseholds,
    addMostRecentlyViewedHousehold,
    removeMostRecentlyViewedHousehold,
  };
}
