import { Box, Stack } from '@mui/material';

import { InfoCircleIcon } from '@/components/icons/InfoCircleIcon';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { COLORS } from '@/styles/tokens/colors';

export interface RecipientNotesBadgeProps {
  notes: string;
}

export function RecipientNotesBadge({ notes }: RecipientNotesBadgeProps) {
  return (
    <Tooltip title={notes} PopperProps={{ placement: 'top' }}>
      <Badge
        sx={{
          height: 20,
          display: 'block',
        }}
        display={
          <Stack
            position="relative"
            top={1}
            direction="row"
            spacing={0.5}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>Notes</Box>
            <InfoCircleIcon
              size={14}
              sx={{
                color: COLORS.PRIMITIVES.WHITE,
              }}
            />
          </Stack>
        }
        variant={BadgeVariants.Primary}
      />
    </Tooltip>
  );
}
