import { Box, Skeleton, Stack } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { DataTable_LEGACY } from '@/components/tables/DataTable_LEGACY/DataTable_LEGACY';
import { Column } from '@/components/tables/DataTable_LEGACY/tableTypes';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { SPACING_CONSTANT } from '@/styles/themes/common';
import { formatCurrency } from '@/utils/formatting/currency';

import { getAssetCategoryDisplay } from '../assetCategoryUtils';
import {
  AssetDetailsModal,
  AssetDetailsModalProps,
} from '../AssetDetailsModal/AssetDetailsModal';
import { SubformAsset } from '../AssetsSubform/types';
import { Asset, NEW_ASSET_ID } from '../types/asset';
import {
  getAssetCellDefinition,
  getMarketValueForAsset,
  getSelectedAsset,
} from '../utils';

const COLUMNS: Column[] = [
  {
    field: 'asset',
    headerName: 'Asset',
    width: 130,
    sortable: false,
    flex: 1,
  },
  {
    field: 'totalMarketValue',
    headerName: 'Total market value',
    width: 180,
    sortable: false,
  },
  {
    field: 'action',
    headerName: '',
    width: 110,
    sortable: false,
    align: 'right',
  },
];

function getValidAssets(rowItems: SubformAsset[]): SubformAsset[] {
  if (!rowItems) return [];
  return rowItems.filter((rowItem) => !rowItem.doDelete);
}

interface Props {
  assetList: SubformAsset[];
  removeByAsset: (value: Asset) => void;
  updateByAsset: (value: Asset) => void;
  loading?: boolean;
  disabled?: boolean;
  updateAssetButtonText?: string;
  isInitialValuation: boolean;
}

export function AssetFormTable({
  assetList,
  removeByAsset,
  updateByAsset,
  disabled,
  loading,
  updateAssetButtonText = 'Update',
  isInitialValuation,
}: Props) {
  const { assetClassesById } = useTenantDetailsContext();
  const undeletedAssets = getValidAssets(assetList);
  const [timedOut, setTimedOut] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<Asset>(
    getSelectedAsset([], NEW_ASSET_ID)
  );

  const [initialAssets, setInitialAssets] = useState<Asset[] | null>(null);

  useEffect(() => {
    if (initialAssets === null && undeletedAssets.length > 0) {
      setInitialAssets(undeletedAssets);
    }
  }, [undeletedAssets, setInitialAssets, initialAssets]);

  useEffect(() => {
    setTimeout(() => {
      setTimedOut(true);
    }, 250);
  }, []);

  const renderAction = useCallback(
    (confirmedAsset: boolean, asset: SubformAsset) => {
      return (
        <>
          {confirmedAsset && isInitialValuation ? (
            <EditButton
              disabled={disabled}
              data-testid="EditAssetButton"
              onClick={() => {
                setSelectedAsset(asset);
                setIsModalOpen(true);
              }}
            />
          ) : (
            <Button
              variant="primary"
              data-testid="EditAssetButton"
              size="sm"
              disabled={disabled}
              onClick={() => {
                setSelectedAsset(asset);
                setIsModalOpen(true);
              }}
            >
              {updateAssetButtonText}
            </Button>
          )}
        </>
      );
    },
    [disabled, updateAssetButtonText, isInitialValuation]
  );

  const makeRows = useMemo(
    () => (undeletedAssets: SubformAsset[]) => {
      return undeletedAssets.map((asset) => {
        const confirmedAsset = asset.confirmed;

        return {
          id: asset.id,
          asset: getAssetCellDefinition({
            displayName: asset.displayName,
            displayCategory: getAssetCategoryDisplay(asset, assetClassesById),
          }),
          totalMarketValue: (
            <Box sx={{ opacity: `${confirmedAsset ? `1` : `0.5`}` }}>
              {formatCurrency(getMarketValueForAsset(asset))}
            </Box>
          ),

          action: renderAction(confirmedAsset, asset),
        };
      });
    },
    [assetClassesById, renderAction]
  );

  const rows = makeRows(undeletedAssets);

  const handleAssetsModalSubmit: AssetDetailsModalProps['onSubmit'] = (
    value,
    { isRemoveAsset }
  ) => {
    if (isRemoveAsset) {
      removeByAsset(value);
    } else {
      updateByAsset(value);
    }

    setIsModalOpen(false);
  };

  if (loading && timedOut) {
    return (
      <Stack spacing={1} pb={5}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={SPACING_CONSTANT * 10}
        />
        <Skeleton
          variant="rectangular"
          width="100%"
          height={SPACING_CONSTANT * 10}
        />
      </Stack>
    );
  }

  return (
    <>
      {isModalOpen && (
        <AssetDetailsModal
          onSubmit={handleAssetsModalSubmit}
          asset={selectedAsset}
          isOpen={isModalOpen}
          isNewAsset={false}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <DataTable_LEGACY
        columns={COLUMNS}
        rows={rows}
        variant="long"
        hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS
      />
    </>
  );
}
