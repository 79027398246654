import { Box } from '@mui/material';
import { ChangeEvent, useEffect, useRef } from 'react';

import { ClearInputTextButton } from '@/components/form/baseInputs/Button/ClearInputTextButton';
import { TextInput } from '@/components/form/baseInputs/TextInput';
import { SearchSmIcon } from '@/components/icons/SearchSmIcon';
import { COLORS } from '@/styles/tokens/colors';

export interface SearchFieldProps {
  searchString: string;
  setSearchString: (arg: string) => void;
}

export function SearchField({
  searchString,
  setSearchString,
}: SearchFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <Box sx={{ p: 3, pt: 2.5, background: COLORS.GRAY[50] }}>
      <TextInput
        value={searchString}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setSearchString(event.target.value)
        }
        label="Search"
        hideLabel
        startAdornment={<SearchSmIcon />}
        placeholder="Search for records across Luminary"
        endAdornment={
          searchString.length ? (
            <ClearInputTextButton onClick={() => setSearchString('')} />
          ) : undefined
        }
        inputRef={inputRef}
      />
    </Box>
  );
}
