import { Maybe } from 'graphql/jsutils/Maybe';

import {
  EntityMap_ClientProfileFragment,
  EntityMap_PrincipalConnectionFragment,
} from '../graphql/EntityMap.generated';
import { EntityGraph, EntityGraphNodeAttributes } from '../types';
import { createEdge } from '../utils/edges';
import { getGrantorNodeId } from '../utils/normalize';
import { individualGrantorTile } from './tiles';

export function createGrantorNodeFromClientProfile(
  _graph: EntityGraph,
  individual?: EntityMap_ClientProfileFragment
): EntityGraphNodeAttributes | null {
  if (!individual) return null;
  const id = getGrantorNodeId(individual.id);
  const tile = individualGrantorTile({ ...individual, id });
  return { node: tile, data: individual, categorizationType: 'INDIVIDUAL' };
}

export function createGrantorNodeFromPrincipal(
  graph: EntityGraph,
  g?: EntityMap_PrincipalConnectionFragment | null
): EntityGraphNodeAttributes | null {
  if (!g) return null;

  const { individualGrantorsById } = graph.getAttribute('byId');
  const individual = individualGrantorsById[g.individual?.id ?? ''];
  if (individual) {
    return createGrantorNodeFromClientProfile(graph, individual);
  }

  return null;
}

export function addGrantors(
  graph: EntityGraph,
  target: string,
  grantors: Maybe<Maybe<EntityMap_PrincipalConnectionFragment>[]> = []
) {
  grantors?.forEach((g) => {
    const node = createGrantorNodeFromPrincipal(graph, g);
    if (!node || !g) return;

    const source = node.node.id;
    graph.addNodeSafe(node.node.id, node);
    // Grantors are always are source, the target is always the entity (e.g. a trust)
    const edge = createEdge(graph, { source, target });
    graph.addEdgeSafe(source, target, { edge, type: 'beneficiary' });
  });
}

export function getGrantorOrIndividualNodeId(
  graph: EntityGraph,
  clientProfileId: string
) {
  const byId = graph.getAttribute('byId');
  const isGrantor = !!byId.individualGrantorsById[clientProfileId];
  return isGrantor ? getGrantorNodeId(clientProfileId) : clientProfileId;
}
