import { Stack } from '@mui/material';
import { useMemo } from 'react';

import { Divider } from '@/components/Divider';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import { COLORS } from '@/styles/tokens/colors';
import { AfterDeath } from '@/types/schema';

import { BeneficiaryReport } from '../beneficiaryReporting.types';
import { getAccessValueForDeathEvent } from '../beneficiaryReporting.utils';
import { BenefitsMetricsItem } from '../BenefitsMetricsItem';
import { BenefitsTooltip } from '../BenefitsTooltip';

interface BenefitsMetricsProps {
  beneficiaryReports: {
    beneficiaryReport: BeneficiaryReport;
    afterDeath: AfterDeath;
  }[];
  firstGrantor?: ContextPrimaryClient;
  secondGrantor?: ContextPrimaryClient;
}

export function BenefitsMetrics({
  beneficiaryReports,
  firstGrantor,
  secondGrantor,
}: BenefitsMetricsProps) {
  const allAccessValue = useMemo(() => {
    return getAccessValueForDeathEvent(beneficiaryReports);
  }, [beneficiaryReports]);

  const beforeFirstDeathAccessValue = useMemo(() => {
    return getAccessValueForDeathEvent(beneficiaryReports, [AfterDeath.None]);
  }, [beneficiaryReports]);

  const afterFirstDeathAccessValue = useMemo(() => {
    return getAccessValueForDeathEvent(beneficiaryReports, [AfterDeath.First]);
  }, [beneficiaryReports]);

  const afterBothDeathsAccessValue = useMemo(() => {
    return getAccessValueForDeathEvent(beneficiaryReports, [AfterDeath.Second]);
  }, [beneficiaryReports]);

  return (
    <Stack direction="row" spacing={5}>
      <BenefitsMetricsItem
        title="After both deaths"
        tooltip={
          <BenefitsTooltip
            content="Total amount in beneficiaries name across all events displayed in
            the table below."
          />
        }
        value={allAccessValue}
      />
      <Divider
        sx={{
          borderColor: COLORS.GRAY[200],
        }}
        orientation="vertical"
        flexItem
      />
      <BenefitsMetricsItem
        title={`Before ${firstGrantor?.firstName}’s death`}
        value={beforeFirstDeathAccessValue}
      />
      {firstGrantor && (
        <BenefitsMetricsItem
          title={`After ${firstGrantor.firstName}’s death`}
          value={afterFirstDeathAccessValue}
        />
      )}
      {secondGrantor && (
        <BenefitsMetricsItem
          title={`After ${secondGrantor.firstName}’s death`}
          value={afterBothDeathsAccessValue}
        />
      )}
    </Stack>
  );
}
