import { Handle as ReactFlowHandle } from '@xyflow/react';
import { ComponentProps } from 'react';

import { visuallyHidden } from '@/styles/a11y';

export type HandleProps = ComponentProps<typeof ReactFlowHandle> & {
  hidden?: boolean;
};

export function Handle({ hidden = false, style = {}, ...props }: HandleProps) {
  return (
    <ReactFlowHandle
      style={{ ...(hidden ? visuallyHidden : {}), ...style }}
      {...props}
    />
  );
}
