import { compact } from 'lodash';

import {
  AISuggestionsMatcherArrayField,
  AISuggestionsMatcherSingleField,
  AISuggestionsMatcherVariant,
  AISuggestionsMatcherVariantConfig,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import { suggestionsToGrantorsSubform } from '@/modules/aiSuggestions/AISuggestionsMatcher/subforms/basicInformation';
import {
  suggestionsToBeneficiariesSubform,
  suggestionsToBeneficiariesV2Subform,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/subforms/beneficiaries';
import {
  suggestionsToBeneficialOwnersSubform,
  suggestionsToKeyPeopleSubform,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/subforms/business';
import {
  suggestionsToSuccessorTrusteesSubform,
  suggestionsToTrustAdvisorsSubform,
  suggestionsToTrusteesSubform,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/subforms/trustDetails';
import {
  CommonAiSuggestionActorFragment,
  CommonAiSuggestionFragment,
} from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { ClientProfileNameFields } from '@/modules/clientProfiles/ClientProfile.types';
import { EntityDocumentViewerDocument } from '@/modules/documents/components/HouseholdDocumentsViewer/graphql/EntityDocumentViewer.generated';
import { BASIC_INFORMATION_SUBFORM_NAMESPACE } from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import { BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE } from '@/modules/entities/BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import {
  BENEFICIARIES_SUBFORM,
  LIFETIME_BENEFICIARIES_SUBFORM,
} from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.constants';
import { TRUST_DETAILS_SUBFORM_NAMESPACE } from '@/modules/entities/TrustDetailsSubform/TrustDetailsSubform.types';
import { EntityType } from '@/modules/entities/types/EntityType';
import { AiSuggestionKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getPulidKind, PulidKind } from '@/utils/pulid';

export function getSuggestedActorDisplayName(
  suggestion: CommonAiSuggestionFragment
): string {
  switch (suggestion.kind) {
    case AiSuggestionKind.Beneficiary:
      return formatSuggestedPersonDisplayName(suggestion.beneficiary);
    case AiSuggestionKind.BeneficiaryV2:
      return formatSuggestedPersonDisplayName(suggestion.beneficiaryV2);
    case AiSuggestionKind.Principal:
      return formatSuggestedPersonDisplayName(suggestion.principal);
    case AiSuggestionKind.TrustAdvisor:
      return formatSuggestedPersonDisplayName(suggestion.trustAdvisor);
    case AiSuggestionKind.Trustee:
      return formatSuggestedPersonDisplayName(suggestion.trustee);
    case AiSuggestionKind.BeneficialOwner:
      return formatSuggestedPersonDisplayName(suggestion.beneficialOwner);
    case AiSuggestionKind.KeyPerson:
      return formatSuggestedPersonDisplayName(suggestion.keyPerson);
    default:
      return 'Unknown suggestion';
  }
}

export function formatSuggestedPersonDisplayName(
  actor:
    | {
        firstName?: string | null;
        middleName?: string | null;
        lastName?: string | null;
        suffix?: string | null;
        organizationName?: string | null;
      }
    | undefined
    | null
): string {
  if (!actor) {
    const msg = 'Actor is undefined or null';
    diagnostics.error(msg, new Error(msg));
    return 'Unknown suggestion';
  }

  if (actor?.organizationName) {
    return actor.organizationName;
  }

  return compact([
    actor.firstName,
    actor.middleName,
    actor.lastName,
    actor.suffix,
  ]).join(' ');
}

const DEFAULT_EMPTY_NAME: {
  clientProfileName: ClientProfileNameFields;
  clientOrganizationName: string;
} = {
  clientProfileName: {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
  },
  clientOrganizationName: '',
};

export function getSuggestedActorNameParts(
  suggestion: CommonAiSuggestionFragment
): {
  clientProfileName: ClientProfileNameFields;
  clientOrganizationName: string;
} {
  let actor: CommonAiSuggestionActorFragment | null | undefined = null;

  switch (suggestion.kind) {
    case AiSuggestionKind.Beneficiary:
      actor = suggestion.beneficiary;
      break;
    case AiSuggestionKind.BeneficiaryV2:
      actor = suggestion.beneficiaryV2;
      break;
    case AiSuggestionKind.Principal:
      actor = suggestion.principal;
      break;
    case AiSuggestionKind.TrustAdvisor:
      actor = suggestion.trustAdvisor;
      break;
    case AiSuggestionKind.Trustee:
      actor = suggestion.trustee;
      break;
    case AiSuggestionKind.BeneficialOwner:
      actor = suggestion.beneficialOwner;
      break;
    case AiSuggestionKind.KeyPerson:
      actor = suggestion.keyPerson;
      break;
    default:
      return DEFAULT_EMPTY_NAME;
  }

  if (!actor) {
    return DEFAULT_EMPTY_NAME;
  }

  const { firstName, middleName, lastName, suffix } = actor;

  return {
    clientProfileName: {
      firstName: firstName ?? '',
      middleName: middleName ?? '',
      lastName: lastName ?? '',
      suffix: suffix ?? '',
    },
    clientOrganizationName: actor.organizationName ?? '',
  };
}

export function getMatchFromNewId(
  newId: string
):
  | { clientProfileId: string }
  | { clientOrganizationId: string }
  | { entityId: string }
  | null {
  const selectedPulidKind = getPulidKind(newId);

  if (selectedPulidKind === PulidKind.ClientProfile) {
    return {
      clientProfileId: newId,
    };
  }

  if (selectedPulidKind === PulidKind.ClientOrganization) {
    return {
      clientOrganizationId: newId,
    };
  }

  if (selectedPulidKind === PulidKind.Entity) {
    return {
      entityId: newId,
    };
  }

  return null;
}

export function getConfigsForAISuggestionsMatcherVariant(
  entityType: EntityType | null | undefined,
  variant: AISuggestionsMatcherVariant
): AISuggestionsMatcherVariantConfig {
  const commonGrantorConfig: AISuggestionsMatcherVariantConfig = {
    nounSingular: 'grantor',
    nounPlural: 'grantors',
    suggestionKinds: [AiSuggestionKind.Principal],
    addNewAnythingModalProps: {
      [AiSuggestionKind.Principal]: {
        addableTypes: ['individual'],
        addClientProfileModalProps: {
          forceBeneficiaryIfNoClientProfileId: true,
          forceTrusteeIfNoClientProfileId: true,
          forceGrantorIfNoClientProfileId: true,
          ignoredQueryDocuments: [EntityDocumentViewerDocument],
        },
        addClientOrganizationModalProps: {},
        addEntityModalProps: {},
      },
    },
    subformArrayFieldUpdateProps: [],
    subformArrayFieldOnConflict: 'overwrite',
  };

  switch (variant) {
    case AISuggestionsMatcherVariant.GRANTORS:
      return {
        ...commonGrantorConfig,
        subformArrayFieldUpdateProps: [
          {
            arrayFieldFullPath:
              `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.grantors` as const satisfies AISuggestionsMatcherArrayField,
            mapperFunc: suggestionsToGrantorsSubform,
            idField: 'clientProfileId',
          },
        ],
      };
    case AISuggestionsMatcherVariant.SINGLE_GRANTOR:
      return {
        ...commonGrantorConfig,
        calloutText:
          entityType === 'slat'
            ? 'Only one grantor can be specified for a SLAT.'
            : undefined,
        subformSingleFieldUpdateProps: {
          singleFieldFullPath:
            `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.grantor` as const satisfies AISuggestionsMatcherSingleField,
          mapperFunc: (s) => suggestionsToGrantorsSubform(s)?.[0] || '',
          idField: 'clientProfileId',
        },
      };
    case AISuggestionsMatcherVariant.TRUSTEES:
      return {
        nounSingular: 'trustee',
        nounPlural: 'trustees',
        suggestionKinds: [
          AiSuggestionKind.Trustee,
          AiSuggestionKind.TrustAdvisor,
        ],
        addNewAnythingModalProps: {
          [AiSuggestionKind.Trustee]: {
            addableTypes: ['individual', 'organization'],
            addClientProfileModalProps: {
              forceBeneficiaryIfNoClientProfileId: false,
              forceTrusteeIfNoClientProfileId: true,
              forceGrantorIfNoClientProfileId: false,
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addClientOrganizationModalProps: {
              requirement: 'mustBeTrustee',
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addEntityModalProps: {},
          },
          [AiSuggestionKind.TrustAdvisor]: {
            addableTypes: ['individual', 'organization'],
            addClientProfileModalProps: {
              forceBeneficiaryIfNoClientProfileId: false,
              forceTrusteeIfNoClientProfileId: true,
              forceGrantorIfNoClientProfileId: false,
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addClientOrganizationModalProps: {
              requirement: 'mustBeTrustee',
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addEntityModalProps: {},
          },
        },
        subformArrayFieldUpdateProps: [
          {
            arrayFieldFullPath:
              `${TRUST_DETAILS_SUBFORM_NAMESPACE}.trustees` as const satisfies AISuggestionsMatcherArrayField,
            mapperFunc: suggestionsToTrusteesSubform,
            idField: 'trusteeId',
          },
          {
            arrayFieldFullPath:
              `${TRUST_DETAILS_SUBFORM_NAMESPACE}.successorTrustees` as const satisfies AISuggestionsMatcherArrayField,
            mapperFunc: suggestionsToSuccessorTrusteesSubform,
            idField: 'successorTrusteeId',
          },
          {
            arrayFieldFullPath:
              `${TRUST_DETAILS_SUBFORM_NAMESPACE}.trustAdvisors` as const satisfies AISuggestionsMatcherArrayField,
            mapperFunc: suggestionsToTrustAdvisorsSubform,
            idField: 'trustAdvisorId',
          },
        ],
        subformArrayFieldOnConflict: 'append',
      };

    case AISuggestionsMatcherVariant.BENEFICIARIES:
      return {
        nounSingular: 'beneficiary',
        nounPlural: 'beneficiaries',
        suggestionKinds: [AiSuggestionKind.Beneficiary],
        addNewAnythingModalProps: {
          [AiSuggestionKind.Beneficiary]: {
            addableTypes: ['individual', 'organization', 'entity'],
            addClientProfileModalProps: {
              forceBeneficiaryIfNoClientProfileId: true,
              forceTrusteeIfNoClientProfileId: false,
              forceGrantorIfNoClientProfileId: false,
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addClientOrganizationModalProps: {
              requirement: 'mustBeBeneficiary',
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addEntityModalProps: {
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
          },
        },
        subformArrayFieldUpdateProps: [
          {
            arrayFieldFullPath:
              getBeneficiarySubformArrayFieldFullPath(entityType),
            mapperFunc: suggestionsToBeneficiariesSubform,
            idField: 'beneficiaryId',
          },
        ],
        subformArrayFieldOnConflict: 'append',
      };
    case AISuggestionsMatcherVariant.BENEFICIARIES_V2:
      return {
        nounSingular: 'beneficiary',
        nounPlural: 'beneficiaries',
        suggestionKinds: [AiSuggestionKind.BeneficiaryV2],
        addNewAnythingModalProps: {
          [AiSuggestionKind.BeneficiaryV2]: {
            addableTypes: ['individual', 'organization', 'entity'],
            addClientProfileModalProps: {
              forceBeneficiaryIfNoClientProfileId: true,
              forceTrusteeIfNoClientProfileId: false,
              forceGrantorIfNoClientProfileId: false,
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addClientOrganizationModalProps: {
              requirement: 'mustBeBeneficiary',
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addEntityModalProps: {
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
          },
        },
        subformArrayFieldUpdateProps: [
          {
            arrayFieldFullPath:
              getBeneficiarySubformArrayFieldFullPath(entityType),
            mapperFunc: suggestionsToBeneficiariesV2Subform,
            idField: 'beneficiaryId',
          },
        ],
        subformArrayFieldOnConflict: 'append',
      };
    case AISuggestionsMatcherVariant.BUSINESS_PEOPLE:
      return {
        nounSingular: 'beneficial owner and key person',
        nounPlural: 'beneficial owners and key people',
        suggestionKinds: [
          AiSuggestionKind.BeneficialOwner,
          AiSuggestionKind.KeyPerson,
        ],
        addNewAnythingModalProps: {
          [AiSuggestionKind.BeneficialOwner]: {
            addableTypes: ['individual', 'entity'],
            addClientProfileModalProps: {
              forceBeneficiaryIfNoClientProfileId: false,
              forceTrusteeIfNoClientProfileId: false,
              forceGrantorIfNoClientProfileId: false,
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addClientOrganizationModalProps: {},
            addEntityModalProps: {
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
          },
          [AiSuggestionKind.KeyPerson]: {
            addableTypes: ['individual', 'organization', 'entity'],
            addClientProfileModalProps: {
              forceBeneficiaryIfNoClientProfileId: false,
              forceTrusteeIfNoClientProfileId: false,
              forceGrantorIfNoClientProfileId: false,
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addClientOrganizationModalProps: {
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
            addEntityModalProps: {
              ignoredQueryDocuments: [EntityDocumentViewerDocument],
            },
          },
        },
        subformArrayFieldUpdateProps: [
          {
            arrayFieldFullPath:
              `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.owners` as const satisfies AISuggestionsMatcherArrayField,
            mapperFunc: suggestionsToBeneficialOwnersSubform,
            idField: 'ownerId',
          },
          {
            arrayFieldFullPath:
              `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.keyPeople` as const satisfies AISuggestionsMatcherArrayField,
            mapperFunc: suggestionsToKeyPeopleSubform,
            idField: 'keyPersonId',
          },
        ],
        subformArrayFieldOnConflict: 'append',
      };
    default:
      throw new Error(`Unknown matcher configs for variant: ${variant}`);
  }
}

function getBeneficiarySubformArrayFieldFullPath(
  entityType: EntityType | null | undefined
): AISuggestionsMatcherArrayField {
  if (!entityType) {
    throw new Error('Entity type is required');
  }

  switch (entityType) {
    case 'slat':
      return `${LIFETIME_BENEFICIARIES_SUBFORM}.lifetimeBeneficiaries` as const satisfies AISuggestionsMatcherArrayField;
    default:
      // Add more cases as we support more entity types that have custom beneficiary subform fields
      return `${BENEFICIARIES_SUBFORM}.beneficiaries` as const satisfies AISuggestionsMatcherArrayField;
  }
}
