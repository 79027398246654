import { Box, Typography } from '@mui/material';

import { Link03Icon } from '@/components/icons/Link03Icon';
import { COLORS } from '@/styles/tokens/colors';

export interface AssetProviderFormLinkIndicatorProps {
  providerName: string;
}

export function AssetProviderFormLinkIndicator({
  providerName,
}: AssetProviderFormLinkIndicatorProps) {
  return (
    <Box textAlign="center">
      <Box my={4}>
        <Link03Icon size={80} color={COLORS.GRAY[400]} />
      </Box>
      <Typography variant="body1">
        Valuation data will be automatically captured from {providerName}
      </Typography>
    </Box>
  );
}
