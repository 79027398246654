import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { EmptyListItem } from '../../typography/EmptyListItem';
import {
  DraggableListItem,
  DraggableListItemInnerComponentProps,
} from './DraggableListItem';
import { StrictModeDroppable } from './StrictModeDroppable';

export interface DragAndDropListProps<T> {
  items: T[];
  ItemInnerComponent: React.ComponentType<
    DraggableListItemInnerComponentProps<T>
  >;
  hasDragHandle?: boolean;
  withDividers?: boolean;
  droppableId?: string;
  onDropped?: (sortedList: T[]) => void;
  setValue?: UseFormSetValue<FieldValues>;
  respondToLengthChanges?: boolean;
}

function transposeItemInList<T>(
  list: T[],
  fromIndex: number,
  toIndex: number
): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed!);

  return result;
}

export function DragAndDropList<T extends { id: string; isGrouped?: boolean }>({
  items,
  ItemInnerComponent,
  hasDragHandle = true,
  withDividers = true,
  droppableId,
  onDropped,
  respondToLengthChanges = true,
}: DragAndDropListProps<T>) {
  const [sortedItems, setSortedItems] = useState(items);

  useEffect(
    function handleExternalItems() {
      setSortedItems((internalItems) => {
        const internalItemIds = internalItems.map((item) => item.id);

        // Sort items by the order of the internal items
        // If an item is not in the internal items, it will be sorted to the end
        const sortedItems = items.sort((a, b) => {
          const aIndex =
            internalItemIds.indexOf(a.id) === -1
              ? Infinity
              : internalItemIds.indexOf(a.id);
          const bIndex =
            internalItemIds.indexOf(b.id) === -1
              ? Infinity
              : internalItemIds.indexOf(b.id);

          return aIndex - bIndex;
        });

        return sortedItems;
      });
    },
    [items]
  );

  useEffect(() => {
    if (!respondToLengthChanges) {
      return;
    }

    // This reacts to items being added and removed from the list and
    // calls the onDropped callback to update the parent state
    onDropped?.(sortedItems);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedItems.length]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = transposeItemInList(
      sortedItems,
      source.index,
      destination.index
    );

    setSortedItems(newItems);
    onDropped?.(newItems);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable
          droppableId={droppableId ?? `sortable-list`}
          direction="vertical"
        >
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              component="ul"
              sx={{ listStyle: 'none', p: 0, m: 0 }}
            >
              {sortedItems.map((item, index) => (
                <DraggableListItem<T>
                  item={item}
                  index={index}
                  key={`${item.id}-${index}`}
                  ItemInnerComponent={ItemInnerComponent}
                  isLast={index === sortedItems.length - 1}
                  hasDragHandle={hasDragHandle}
                  withDividers={withDividers}
                />
              ))}
              {provided.placeholder}
            </Box>
          )}
        </StrictModeDroppable>
      </DragDropContext>
      {items.length === 0 && <EmptyListItem>No items</EmptyListItem>}
    </>
  );
}
