import { Stack, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';

import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';
import {
  DisplayTable,
  DisplayTableColumn,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { DisplayTableSummaryRow } from '@/components/tables/DisplayTable/DisplayTableSummaryRow';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { InsurancePolicyKind } from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatDateToMMDDYY } from '@/utils/formatting/dates';

import {
  Fields as InsurancePolicyFields,
  INSURANCE_POLICIES_DETAILS_SUBFORM,
  InsurancePolicyTypeCopyMap,
} from '../InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform.types';
import { useTotalCashValue } from './ILITInsurancePolicyTable';

const COLUMNS: DisplayTableColumn[] = [
  {
    headerName: '',
    flex: 2,
    width: '75%',
  },
  {
    headerName: 'Cash value',
    flex: 1,
    align: 'left',
    width: '25%',
  },
];

export function InsuranceAccountPolicyTable() {
  const { control: insurancePolicyControl } =
    useFormContext<InsurancePolicyFields>();
  const policies = useWatch({
    control: insurancePolicyControl,
    name: `${INSURANCE_POLICIES_DETAILS_SUBFORM}.policies`,
  });
  const totalPoliciesCashValue = useTotalCashValue();

  const rows = policies.map(
    (
      { policyType, policyId, insuranceCarrier, cashValue, cashValueDate },
      index
    ) => {
      // policy type is blank by default, so only look up the display copy in the table if it's a valid key
      const isTermPolicy = policyType === InsurancePolicyKind.Term;
      const policyTypeDisplay =
        policyType !== '' ? InsurancePolicyTypeCopyMap[policyType] : '';
      return (
        <StyledTableRow key={`policy-${policyId}-${index}`}>
          <StyledTableCell>
            <Stack direction="row">
              <RichListItem
                heading={policyTypeDisplay}
                description={insuranceCarrier || 'Unspecified carrier'}
                Tooltip={
                  <ContextualHelpTooltip>
                    <PopperContent
                      body={
                        isTermPolicy
                          ? 'Term life insurance policies do not have cash value.'
                          : 'Update the current cash value on each policy in the section above.'
                      }
                    />
                  </ContextualHelpTooltip>
                }
              />
            </Stack>
          </StyledTableCell>
          <StyledTableCell align="right">
            <Typography variant="body1">
              {cashValue && !isTermPolicy
                ? formatCurrency(cashValue)
                : EMPTY_CONTENT_HYPHEN}
            </Typography>
            <Typography variant="subtitle2">
              {cashValueDate && `as of ${formatDateToMMDDYY(cashValueDate)}`}
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
      );
    }
  );

  return (
    <DisplayTable
      containerSx={{ width: '100%' }}
      columns={COLUMNS}
      Footer={
        <DisplayTableSummaryRow
          title="Total cash value"
          values={[formatCurrency(totalPoliciesCashValue)]}
        />
      }
    >
      {rows}
    </DisplayTable>
  );
}
