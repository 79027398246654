import { Box, Stack, Typography } from '@mui/material';
import { compact } from 'lodash';
import { ReactElement, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';
import { HEADER_BACKGROUND_COLOR } from '@/components/tables/constants';
import {
  DisplayTable,
  DisplayTableColumn,
} from '@/components/tables/DisplayTable/DisplayTable';
import { sumDecimalJS } from '@/utils/decimalJSUtils';

import {
  ILITAnnualExclusionGrantor,
  ILITMakeAnnualExclusionGiftsForm,
  ILITSourceOption,
} from '../ILITMakeAnnualExclusionGiftsTask.types';
import { ILITMakeAnnualGrantorGiftBeneficiaryRow } from './ILITMakeAnnualGrantorGiftBeneficiaryRow';
import { ILITMakeAnnualGrantorGiftSummaryRow } from './ILITMakeAnnualGrantorGiftSummaryRow';

const COLUMNS: DisplayTableColumn[] = [
  {
    headerName: 'Beneficiary',
    flex: 1,
  },
  {
    headerName: 'Gifted amount',
    align: 'right',
    width: '140px',
  },
  {
    headerName: 'Annual exclusion used',
    align: 'right',
    width: '140px',
  },
  {
    headerName: 'Lifetime exemption used',
    align: 'right',
    width: '140px',
  },
  {
    headerName: 'GST exemption used',
    align: 'right',
    width: '140px',
  },
];

function BottomAlignedGrantorItem({
  children,
}: {
  children: ReactElement;
}): ReactElement {
  return (
    <FormLayoutItem width={3}>
      <Stack
        direction="column"
        sx={{
          height: '100%',
          justifyContent: 'flex-end',
        }}
      >
        {children}
      </Stack>
    </FormLayoutItem>
  );
}

export interface ILITMakeAnnualGrantorGiftProps {
  grantor: ILITAnnualExclusionGrantor;
}

export function ILITMakeAnnualGrantorGift({
  grantor,
}: ILITMakeAnnualGrantorGiftProps) {
  const { control } = useFormContext<ILITMakeAnnualExclusionGiftsForm>();
  const beneficiaries = Object.values(
    useWatch({
      name: `grantors.${grantor.id}.beneficiariesById`,
      control,
    }) ?? {}
  );
  const hasGift = sumDecimalJS(
    compact(beneficiaries.map((beneficiary) => beneficiary.giftedAmount))
  ).greaterThan(0);
  const giftSourceOptionsBySourceEntityId: Record<string, ILITSourceOption> =
    useWatch<ILITMakeAnnualExclusionGiftsForm>({
      name: 'giftSourceOptionsBySourceEntityId',
      control,
    }) as Record<string, ILITSourceOption>;

  const options: SelectInputOption[] = useMemo<SelectInputOption[]>(
    () =>
      Object.keys(giftSourceOptionsBySourceEntityId).map((key) => ({
        value: key,
        display: giftSourceOptionsBySourceEntityId[key]?.label || '',
      })),
    [giftSourceOptionsBySourceEntityId]
  );

  const rows = Object.values(grantor.beneficiariesById).map(
    (beneficiary, index) => (
      <ILITMakeAnnualGrantorGiftBeneficiaryRow
        key={`beneficiary-${grantor.id}-${index}`}
        grantorId={grantor.id}
        beneficiary={beneficiary}
      />
    )
  );
  return (
    <Stack direction="column">
      <Stack
        direction="row"
        sx={{
          backgroundColor: HEADER_BACKGROUND_COLOR,
          padding: 2,
          borderBottom: '1px solid white',
        }}
        spacing={3}
        justifyContent="space-between"
      >
        <FormLayoutRow>
          <BottomAlignedGrantorItem>
            <OversizedMetricItem
              value={
                <Typography variant="h4">{grantor.displayName}</Typography>
              }
              title={<Typography variant="subtitle2">Gifting from</Typography>}
            />
          </BottomAlignedGrantorItem>
          <BottomAlignedGrantorItem>
            <FormAwareSelectInput<ILITMakeAnnualExclusionGiftsForm>
              control={control}
              label="Gift source"
              fieldName={`grantors.${grantor.id}.sourceId`}
              options={options}
              required={hasGift}
              validation={{
                required: (value, formShape) => {
                  const grantorWithSource = Object.values(
                    formShape.grantors
                  ).find((grantor) => !!grantor.sourceId);
                  if (!grantorWithSource && !value) {
                    return 'At least one grantor must have a gift source.';
                  }
                  return undefined;
                },
              }}
            />
          </BottomAlignedGrantorItem>
          <BottomAlignedGrantorItem>
            <FormAwareCurrencyInput<ILITMakeAnnualExclusionGiftsForm>
              isDecimalJSInput
              control={control}
              label="Source value after gift"
              fieldName={`grantors.${grantor.id}.sourceValueAfterGift`}
              contextualHelp={
                <PopperContent
                  body={
                    <Box sx={{ maxWidth: '200px' }}>
                      <Typography variant="caption">
                        Provide an updated valuation of the source entity that
                        reflects the value after the gift has been made
                      </Typography>
                    </Box>
                  }
                />
              }
            />
          </BottomAlignedGrantorItem>
          <BottomAlignedGrantorItem>
            <FormAwareDatePickerInput<ILITMakeAnnualExclusionGiftsForm>
              control={control}
              label="Date of gift"
              fieldName={`grantors.${grantor.id}.giftDate`}
              required={hasGift}
              validation={{
                required: (value, formShape) => {
                  const grantorWithGiftDate = Object.values(
                    formShape.grantors
                  ).find((grantor) => !!grantor.giftDate);
                  if (!grantorWithGiftDate && !value) {
                    return 'At least one grantor must have a gift date.';
                  }
                  return undefined;
                },
              }}
            />
          </BottomAlignedGrantorItem>
        </FormLayoutRow>
      </Stack>

      {!!beneficiaries.length && (
        <DisplayTable
          columns={COLUMNS}
          Footer={
            <ILITMakeAnnualGrantorGiftSummaryRow grantorId={grantor.id} />
          }
        >
          {rows}
        </DisplayTable>
      )}
    </Stack>
  );
}
