import { useApolloClient } from '@apollo/client';
import { Stack } from '@mui/material';
import { compact, includes } from 'lodash';
import { FC, useState } from 'react';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { DropdownButton } from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { useActiveTab } from '@/components/navigation/NavigationTabs/useActiveTab';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { DataTableCard } from '@/components/tables/DataTable/components/DataTableCard';
import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { FullScreenStructuredAssetsModal } from '@/modules/assets/FullScreenStructuredAssetsModal/FullScreenStructuredAssetsModal';
import { UpdateHoldingsButton } from '@/modules/assetValuation/UpdateHoldingsButton/UpdateHoldingsButton';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { EntityViewValuationModal } from '@/modules/entities/details/EntityViewValuationModal/EntityViewValuationModal';
import { CHARITABLE_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { EntityType } from '@/modules/entities/types/EntityType';
import { getEntityTypeFromSubtype } from '@/modules/entities/utils/getEntityTypeFromSubtype';
import { LogNewGiftModal } from '@/modules/gifting/LogNewGiftModal/LogNewGiftModal';
import { LogNewTransferModal } from '@/modules/gifting/LogNewTransferModal/LogNewTransferModal';
import { TransferType } from '@/modules/gifting/LogNewTransferModal/LogNewTransferModal.types';
import { TransferOptionType } from '@/modules/gifting/TransferOption.type';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { EntityKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import { EntityTransferHistoryTable } from './EntityTransferHistoryTable';
import {
  entityHasBusinessOwnershipStakes,
  mapTransferRowData,
  mapValuationDataToRows,
  TransferRowData,
  ValuationRowData,
} from './EntityValuationHistoryPage.utils';
import { EntityValuationHistoryTable } from './EntityValuationHistoryTable';
import {
  EntityValuationHistoryPage_EntityFragment,
  GetEntityValuationHistoryDocument,
  useGetEntityValuationHistoryQuery,
} from './graphql/GetEntityValuationHistory.generated';
import { GrowthOutOfEstate } from './GrowthOutOfEstate';
import { ImpliedEstateTaxSavings } from './ImpliedEstateTaxSavings';
import { TotalCharitableValue } from './TotalCharitableValue';

export enum EntityHistoryModals {
  Gift = 'Gift',
  Distribution = 'Distribution',
  Contribution = 'Contribution',
  UpdateValuation = 'UpdateValuation',
  ViewValuation = 'ViewValuation',
}

export enum EntityHistoryTab {
  Valuation = 'Valuations',
  Transaction = 'Transactions',
}

function isEntity(
  node: unknown
): node is EntityValuationHistoryPage_EntityFragment {
  return (
    (node as EntityValuationHistoryPage_EntityFragment).__typename === 'Entity'
  );
}

export const EntityValuationHistoryPage: FC = () => {
  const { displayName: entityDisplayName } = useEntityDetailsContext();
  const [modalId, setModalId] = useState<string | undefined>();
  const { integrationConfiguration } = useTenantDetailsContext();
  const [openModal, setOpenModal] = useState<EntityHistoryModals | null>(null);
  const entityId = useRequiredParam('entityId');
  const householdId = useRequiredParam('householdId');
  const { activeTab: visibleTable, setActiveTab: setVisibleTable } =
    useActiveTab<EntityHistoryTab>({
      defaultTab: EntityHistoryTab.Valuation,
    });
  const { reportError } = useReportError();
  const { createErrorFeedback } = useFeedback();
  const apolloClient = useApolloClient();
  const { data } = useGetEntityValuationHistoryQuery({
    // valuations can be updated from e.g. CSV uploads, and this call isn't all that expensive,
    // so we want to make sure we're always getting the latest data
    fetchPolicy: 'cache-and-network',
    variables: {
      entityId,
    },
    onError: (error) => {
      createErrorFeedback(
        'Failed to load entity valuation history.  Please try again later.'
      );
      reportError('error loading entity valuation history', error);
    },
  });

  const entity = data?.node && isEntity(data.node) ? data.node : null;
  let valuationRows: ValuationRowData[] = [];
  let transactionRows: TransferRowData[] = [];

  let subtypeId: string | null = null;
  let subtypeType: EntityType | null = null;

  if (data?.node?.__typename === 'Entity') {
    if (!data.node.subtype.__typename) {
      return null;
    }
    subtypeId = data.node.subtype.id;
    subtypeType = getEntityTypeFromSubtype(data.node.subtype.__typename);
    valuationRows = mapValuationDataToRows(
      getNodes(data.node.subtype.designerAccount?.valuations),
      integrationConfiguration
    );
    transactionRows = mapTransferRowData(
      getNodes(data.loggedTransfers),
      entityId
    );
  }

  const onClose = () => {
    setOpenModal(null);
    setModalId(undefined);
    void apolloClient.refetchQueries({
      include: [GetEntityValuationHistoryDocument],
    });
  };

  let displayTable: React.ReactNode = null;

  switch (visibleTable) {
    case EntityHistoryTab.Valuation:
      displayTable = (
        <EntityValuationHistoryTable
          hasBusinessOwnershipStakes={entityHasBusinessOwnershipStakes(entity)}
          rows={valuationRows}
          onClick={(valuationId: string) => {
            setModalId(valuationId);
            setOpenModal(EntityHistoryModals.ViewValuation);
          }}
        />
      );
      break;
    case EntityHistoryTab.Transaction:
      displayTable = (
        <EntityTransferHistoryTable
          rows={transactionRows}
          onClick={(transferId: string, modal: EntityHistoryModals) => {
            setModalId(transferId);
            setOpenModal(modal);
          }}
          entityId={entityId}
        />
      );
      break;
    default:
      throw new UnreachableError({
        case: visibleTable,
        message: 'No valid table for display',
      });
  }

  const entityKind =
    (data?.node?.__typename === 'Entity' && data?.node?.kind) || null;

  const giftingButtonOptions = compact([
    {
      name: 'Contribution',
      clickHandler: () => setOpenModal(EntityHistoryModals.Contribution),
    },
    {
      name: 'Distribution',
      clickHandler: () => setOpenModal(EntityHistoryModals.Distribution),
    },
    subtypeType &&
      !CHARITABLE_ENTITY_TYPES.includes(subtypeType) && {
        name: 'Gift',
        clickHandler: () => setOpenModal(EntityHistoryModals.Gift),
      },
  ]);
  return (
    <>
      <Stack p={3} pb={4}>
        <SubpageLayout
          heading="Valuation history"
          actions={
            <Stack spacing={1} direction="row">
              <DropdownButton
                name="Log new"
                items={giftingButtonOptions}
                buttonContent="Log new"
                variant="secondary"
                size="sm"
                showArrow
              />
              <UpdateHoldingsButton
                size="sm"
                onClick={() =>
                  setOpenModal(EntityHistoryModals.UpdateValuation)
                }
                variant="primary"
              >
                Update holdings
              </UpdateHoldingsButton>
            </Stack>
          }
        >
          <Stack spacing={4}>
            {includes(
              [EntityKind.IrrevocableTrust, EntityKind.SlatTrust],
              entityKind
            ) && (
              <Stack direction="row" width="100%" spacing={4}>
                <GrowthOutOfEstate entity={entity} />
                <ImpliedEstateTaxSavings entity={entity} />
              </Stack>
            )}
            {includes(
              [EntityKind.DonorAdvisedFund, EntityKind.PrivateFoundation],
              entityKind
            ) && (
              <Stack direction="row" width="100%">
                <TotalCharitableValue entity={entity} />
              </Stack>
            )}
            <DataTableCard>
              <Stack spacing={4}>
                <ButtonGroup
                  sx={{ maxWidth: '400px' }}
                  label=""
                  value={visibleTable}
                  onChange={(_, tableType: EntityHistoryTab) =>
                    setVisibleTable(tableType)
                  }
                  options={Object.values(EntityHistoryTab).map((value) => ({
                    display: value,
                    value,
                  }))}
                />
                {displayTable}
              </Stack>
            </DataTableCard>
          </Stack>
        </SubpageLayout>
      </Stack>

      <EntityViewValuationModal
        entityId={entityId}
        valuationId={modalId}
        isOpen={openModal === EntityHistoryModals.ViewValuation}
        onClose={onClose}
      />
      {subtypeType && (
        <FullScreenStructuredAssetsModal
          entityId={entityId}
          subtypeId={subtypeId || ''}
          entityType={subtypeType}
          entityName={entityDisplayName || ''}
          householdId={householdId}
          isOpen={openModal === EntityHistoryModals.UpdateValuation}
          onClose={onClose}
        />
      )}
      <LogNewGiftModal
        isOpen={openModal === EntityHistoryModals.Gift}
        onClose={onClose}
        householdId={householdId}
        beneficiaryId={entityId}
        isEdit={!!modalId}
        lifetimeExclusionEventId={modalId}
      />
      <LogNewTransferModal
        isOpen={
          openModal === EntityHistoryModals.Contribution ||
          openModal === EntityHistoryModals.Distribution
        }
        onClose={onClose}
        initiatorId={entityId}
        initiatorKind={TransferOptionType.Entity}
        initiatorEntityKind={entityKind}
        transferId={modalId}
        householdId={householdId}
        transferType={
          openModal === EntityHistoryModals.Contribution
            ? TransferType.Contribution
            : TransferType.Distribution
        }
      />
    </>
  );
};
