import { CSSProperties, PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  Badge,
  BadgeProps,
  BadgeVariants,
} from '@/components/notifications/Badge/Badge';
import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

export const size = 6;

const useStyles = makeStyles<{ isZoomable?: boolean }>()(
  (_theme, { isZoomable = false }) => ({
    root: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    hr: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: '50%',
      bottom: 0,
      padding: 0,
      margin: 0,
      // custom dashes -- because CSS doesn't let you do this, work around it
      // by drawing a 1px tall box and creating a background SVG
      // https://codepen.io/amit_sheen/pen/xxZeyjO
      borderStyle: 'none',
      height: '1px',
      backgroundImage: isZoomable
        ? // This style performs better on the waterfall where the dashes stay consistent when zooming
          `repeating-linear-gradient(0deg, ${COLORS.GRAY[300]}, ${COLORS.GRAY[300]} 4px, transparent 4px, transparent 8px, ${COLORS.GRAY[300]} 8px), repeating-linear-gradient(90deg, ${COLORS.GRAY[300]}, ${COLORS.GRAY[300]} 4px, transparent 4px, transparent 8px, ${COLORS.GRAY[300]} 8px), repeating-linear-gradient(180deg, ${COLORS.GRAY[300]}, ${COLORS.GRAY[300]} 4px, transparent 4px, transparent 8px, ${COLORS.GRAY[300]} 8px), repeating-linear-gradient(270deg, ${COLORS.GRAY[300]}, ${COLORS.GRAY[300]} 4px, transparent 4px, transparent 8px, ${COLORS.GRAY[300]} 8px);`
        : `repeating-linear-gradient(90deg, ${COLORS.GRAY[300]} 0, ${COLORS.GRAY[300]} 4px, transparent 4px, transparent 8px)`,
      backgroundSize: isZoomable
        ? '1px 100%, 100% 1px, 1px 100% , 100% 1px;'
        : '8px 1px',
      backgroundPosition: '0 0, 0 0, 100% 0, 0 100%;',
      backgroundRepeat: isZoomable ? 'no-repeat;' : 'repeat-x;',
    },
    badgeWrapper: {},
  })
);

export interface SectionLabelProps {
  classes?: WithClasses<typeof useStyles>;
  styles?: {
    hr?: CSSProperties;
    badgeWrapper?: CSSProperties;
  };
  badgeProps?: Partial<BadgeProps>;
  label: string;
  isZoomable?: boolean;
}

export function SectionLabel({
  label,
  badgeProps,
  styles,
  isZoomable,
  ...props
}: PropsWithChildren<SectionLabelProps>) {
  const { classes } = useStyles(
    {
      isZoomable,
    },
    { props }
  );
  return (
    <div className={classes.root}>
      <hr className={classes.hr} style={styles?.hr} />
      <div className={classes.badgeWrapper} style={styles?.badgeWrapper}>
        <Badge
          variant={BadgeVariants.Gray}
          display={label}
          {...badgeProps}
          sx={{ paddingY: 0.5, ...badgeProps?.sx }}
        />
      </div>
    </div>
  );
}
