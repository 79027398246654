import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import React from 'react';

import { CurrencyUSD, Percent } from '@/graphql/scalars';
import { RollingGRATScenarioExplanation } from '@/modules/content/disclaimers/RollingGRATScenarioExplanation';
import { GRAT_ANNUITIES_SUPPORTED_DECIMAL_PLACES } from '@/modules/gratAnnuities/gratAnnuities.constants';
import { ScenarioTableRow } from '@/types/schema';
import { getComparableDecimalJS } from '@/utils/decimalJSUtils';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { ProjectedValueVisualization } from './ProjectedValueVisualization';
import { ScenarioIllustrationTable } from './ScenarioIllustrationTable';
import {
  GetScenarioProjectionQuery,
  useGetScenarioProjectionLazyQuery,
} from './ScenarioOverview.generated';
import { Summary } from './Summary';

export interface ScenarioOverviewProps {
  onCalculatedTaxableGiftAmountChange: (
    taxableGiftAmount: Decimal | null
  ) => void;
  initialFundingValue: CurrencyUSD | null;
  grantorRetainedInterest: CurrencyUSD | null;
  taxableGiftAmount: CurrencyUSD | null;
  estimatedReturn: Percent | null;
  termDuration: number | null;

  // rolling when not null
  rollingPeriodYears: number | null;
  officialInterestRate: Percent | null;
  annualAnnuityIncrease: Percent | null;
}

const EMPTY_VALUE_PLACEHOLDER = '—';

function formatPercentOrEmptyValue(
  value: Decimal | null,
  decimalPlaces = 2
): string {
  if (value === null) {
    return EMPTY_VALUE_PLACEHOLDER;
  }

  return `${formatPercent(value, decimalPlaces)}%`;
}

function getFormattedCurrency(value: Decimal | null): string {
  if (value === null) return EMPTY_VALUE_PLACEHOLDER;
  return formatCurrency(value);
}

function getRowLabel(year: number) {
  return `Yr ${year}`;
}

function getEmptyRows(numberRows: number) {
  const res = [];
  for (let i = 0; i < numberRows; i++) {
    res[i] = {
      id: i,
      timeFrame: getRowLabel(i + 1),
      beginningPrincipal: EMPTY_VALUE_PLACEHOLDER,
      principalGrowth: EMPTY_VALUE_PLACEHOLDER,
      annuityPayment: EMPTY_VALUE_PLACEHOLDER,
      remainderValue: EMPTY_VALUE_PLACEHOLDER,
      isFinalRow: i === numberRows - 1,
    };
  }
  return res;
}

function getRowsFromProjection(
  projections: ScenarioTableRow[] | null,
  termDurationYears: number
) {
  if (!projections) return getEmptyRows(termDurationYears);
  return projections.map((projection, i) => {
    return {
      id: i,
      timeFrame: getRowLabel(projection.year),
      beginningPrincipal: getFormattedCurrency(
        projection.beginningPrincipal || null
      ),
      principalGrowth: getFormattedCurrency(projection.principalGrowth || null),
      annuityPayment: {
        lineOne: getFormattedCurrency(projection.annuityPayment || null),
        lineTwo: `${formatPercent(
          projection.annuityPaymentPercentOfInitialFunding,
          GRAT_ANNUITIES_SUPPORTED_DECIMAL_PLACES
        )}%`,
        value: getComparableDecimalJS(projection.annuityPayment || null),
      },
      remainderValue: getFormattedCurrency(projection.remainderValue || null),
      isFinalRow: i === projections.length - 1,
    };
  });
}

export function getRowsFromProjectionForPresentation(
  projections: ScenarioTableRow[] | null,
  termDurationYears: number
) {
  if (!projections) return getEmptyRows(termDurationYears);
  const annuitySum = projections.reduce((sum, projection) => {
    return sum.plus(projection.annuityPayment || new Decimal(0));
  }, new Decimal(0));
  let runningSum = new Decimal(0);
  let runningPercentSum = new Decimal(0);
  return [
    ...projections.map((projection, i) => {
      runningSum = runningSum.plus(projection.annuityPayment || new Decimal(0));
      runningPercentSum = runningPercentSum.plus(
        projection.annuityPaymentPercentOfInitialFunding || new Decimal(0)
      );
      return {
        id: i,
        timeFrame: getRowLabel(projection.year),
        annuityPayments: getFormattedCurrency(
          projection.annuityPayment || null
        ),
        percentOfInitialFunding: `${formatPercent(
          projection.annuityPaymentPercentOfInitialFunding,
          2
        )}%`,
        remainingAmount: getFormattedCurrency(
          annuitySum.minus(runningSum) || null
        ),
      };
    }),
    {
      id: 99,
      timeFrame: '',
      annuityPayments: formatCurrency(runningSum),
      percentOfInitialFunding: `${formatPercent(runningPercentSum, 2)}%`,
      remainingAmount: '',
    },
  ];
}

function getComputedFormattedValue(
  finalValue: Decimal | null,
  estimatedValue: Decimal | null
) {
  if (finalValue === null && estimatedValue === null) {
    return EMPTY_VALUE_PLACEHOLDER;
  }

  if (finalValue !== null) {
    return getFormattedCurrency(finalValue);
  }

  return getFormattedCurrency(estimatedValue);
}

export function ScenarioOverview({
  initialFundingValue,
  grantorRetainedInterest,
  taxableGiftAmount,
  officialInterestRate,
  annualAnnuityIncrease,
  estimatedReturn,
  termDuration,
  rollingPeriodYears,
  onCalculatedTaxableGiftAmountChange,
}: ScenarioOverviewProps) {
  const [projection, setProjection] = React.useState<
    GetScenarioProjectionQuery['gratScenarioProjection'] | null
  >(null);
  const [queryProjection, { data: returnedProjection }] =
    useGetScenarioProjectionLazyQuery({
      variables: {
        params: {
          initialFundingValue: initialFundingValue || new Decimal(0),
          termDurationYears: termDuration || 0,
          officialInterestRate: officialInterestRate || new Decimal(0),
          annualAnnuityIncrease: annualAnnuityIncrease || new Decimal(0),
          projectedRateOfReturn: estimatedReturn || new Decimal(0),
          targetGift: taxableGiftAmount || new Decimal(0),

          // rolling when not null
          rollingPeriodYears: rollingPeriodYears,
        },
      },
    });
  const isRollingGRAT = rollingPeriodYears != null;

  React.useEffect(
    () => {
      const missingRequiredInput =
        !termDuration ||
        grantorRetainedInterest === null ||
        taxableGiftAmount === null ||
        estimatedReturn === null;
      if (missingRequiredInput && projection !== null) {
        return setProjection(null);
      } else if (!missingRequiredInput) {
        void queryProjection();
      }
    },
    // disabling the exhaustive deps because doing comparisons on complex objects causes the hook to incorrectly rerender,
    // and the lint rule isn't smart enough to be able to understand this via static analysis
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      getComparableDecimalJS(officialInterestRate),
      getComparableDecimalJS(annualAnnuityIncrease),
      getComparableDecimalJS(initialFundingValue),
      getComparableDecimalJS(grantorRetainedInterest),
      getComparableDecimalJS(taxableGiftAmount),
      getComparableDecimalJS(estimatedReturn),
      isRollingGRAT,
      termDuration,
      queryProjection,
    ]
    /* eslint-enable react-hooks/exhaustive-deps */
  );

  React.useEffect(() => {
    onCalculatedTaxableGiftAmountChange(
      returnedProjection?.gratScenarioProjection?.taxableGiftAmount ?? null
    );
    setProjection(returnedProjection?.gratScenarioProjection || null);
  }, [
    returnedProjection?.gratScenarioProjection,
    onCalculatedTaxableGiftAmountChange,
  ]);

  const tableRows = getRowsFromProjection(
    projection?.scenarioTableRows || null,
    termDuration || 0
  );
  return (
    <Stack spacing={3} p={3}>
      <Stack direction="row" spacing={3}>
        <Box width="50%" data-testid="ScenarioOverview">
          <Box>
            <Typography mb={1} variant="h2">
              Scenario overview
            </Typography>
          </Box>
          <Summary
            items={[
              {
                label: 'Initial funding value',
                value: getFormattedCurrency(initialFundingValue),
              },
              {
                label: 'Grantor-retained interest',
                value: getComputedFormattedValue(
                  projection?.grantorRetainedInterest || null,
                  grantorRetainedInterest
                ),
              },
              {
                label: 'Taxable gift amount',
                value: getComputedFormattedValue(
                  projection?.taxableGiftAmount || null,
                  taxableGiftAmount
                ),
              },
              {
                label: 'Annual rate of return',
                value: formatPercentOrEmptyValue(estimatedReturn, 1),
              },
            ]}
          />
        </Box>
        <Box width="50%" data-testid="ProjectedValue">
          <Box>
            <Typography mb={3} variant="h2">
              {`Projected value${
                termDuration
                  ? ` at year ${rollingPeriodYears || termDuration}`
                  : ''
              }`}
            </Typography>
          </Box>
          <ProjectedValueVisualization
            totalAssetValue={projection?.totalAssetValue || new Decimal(0)}
            transferredOutOfEstate={
              projection?.transferredOutOfEstate || new Decimal(0)
            }
            totalAnnuityPayments={
              projection?.totalAnnuityPayments || new Decimal(0)
            }
            isRollingGRAT={isRollingGRAT}
          />
        </Box>
      </Stack>
      <Box data-testid="AnnuityPaymentsTable">
        <Typography mb={3} variant="h2">
          Annuity payments
        </Typography>
        <Box>
          <ScenarioIllustrationTable rows={tableRows} />
        </Box>
      </Box>
      {isRollingGRAT && <RollingGRATScenarioExplanation />}
    </Stack>
  );
}
