// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DownloadProposalMutationVariables = Types.Exact<{
  householdId: Types.Scalars['ID']['input'];
  pathToPrint: Types.Scalars['String']['input'];
}>;


export type DownloadProposalMutation = { __typename?: 'Mutation', print: { __typename?: 'File', id: string, download: { __typename?: 'FileDownload', downloadURL: string } } };


export const DownloadProposalDocument = gql`
    mutation DownloadProposal($householdId: ID!, $pathToPrint: String!) {
  print(input: {householdID: $householdId, relativePath: $pathToPrint}) {
    id
    download {
      downloadURL
    }
  }
}
    `;
export type DownloadProposalMutationFn = Apollo.MutationFunction<DownloadProposalMutation, DownloadProposalMutationVariables>;

/**
 * __useDownloadProposalMutation__
 *
 * To run a mutation, you first call `useDownloadProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadProposalMutation, { data, loading, error }] = useDownloadProposalMutation({
 *   variables: {
 *      householdId: // value for 'householdId'
 *      pathToPrint: // value for 'pathToPrint'
 *   },
 * });
 */
export function useDownloadProposalMutation(baseOptions?: Apollo.MutationHookOptions<DownloadProposalMutation, DownloadProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadProposalMutation, DownloadProposalMutationVariables>(DownloadProposalDocument, options);
      }
export type DownloadProposalMutationHookResult = ReturnType<typeof useDownloadProposalMutation>;
export type DownloadProposalMutationResult = Apollo.MutationResult<DownloadProposalMutation>;
export type DownloadProposalMutationOptions = Apollo.BaseMutationOptions<DownloadProposalMutation, DownloadProposalMutationVariables>;
export const Operations = {
  Mutation: {
    DownloadProposal: 'DownloadProposal' as const
  }
}