import { Divider, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { COLORS } from '@/styles/tokens/colors';
import { GiftingProposalCashFlowCashFlowType } from '@/types/schema';
import {
  formatCurrency,
  formatCurrencyNoDecimals,
} from '@/utils/formatting/currency';

import { DataCell, DataItem } from './GiftOverview.components';
import {
  dateRangeOrStartYear,
  formatPercentGrowthOrDiscount,
} from './GiftOverview.utils';

interface GridOverviewStartingAssetsProps {
  proposal: ProposalFragment;
}

export function GridOverviewStartingAssets({
  proposal,
}: GridOverviewStartingAssetsProps) {
  const giftingProposal = proposal.giftingProposal;

  return (
    <>
      <Stack
        divider={
          <Divider
            sx={{
              borderColor: COLORS.GRAY[300],
            }}
          />
        }
      >
        <Stack direction="row" pb={3}>
          <Typography variant="h2">Baseline assumptions</Typography>
        </Stack>
        <DataCell>
          <DataItem
            label="Length of analysis"
            primaryValue={
              giftingProposal?.lengthOfAnalysis
                ? `${giftingProposal.lengthOfAnalysis} years`
                : EMPTY_CONTENT_HYPHEN
            }
            labelVariant="h6"
          />
        </DataCell>
        <DataCell>
          <DataItem
            label="In-estate portfolio"
            primaryValue={formatCurrencyNoDecimals(
              giftingProposal?.inEstatePortfolioValue ?? new Decimal(0)
            )}
            labelVariant="h6"
          />
        </DataCell>
        {giftingProposal?.outOfEstatePortfolios?.length && (
          <DataCell label="Out-of-estate portfolios">
            <Stack spacing={1}>
              {giftingProposal.outOfEstatePortfolios.map((portfolio) => (
                <DataItem
                  key={portfolio.id}
                  label={portfolio.displayName}
                  primaryValue={formatCurrencyNoDecimals(
                    portfolio.startingAssetValue
                  )}
                />
              ))}
            </Stack>
          </DataCell>
        )}
        {giftingProposal?.baseCashFlows?.length && (
          <DataCell label="Baseline cash flows">
            <Stack spacing={1}>
              {giftingProposal.baseCashFlows.map((cf) => (
                <DataItem
                  key={cf.id}
                  label={cf.displayName}
                  primaryValue={formatCurrency(
                    cf.amount.times(
                      cf.cashFlowType ===
                        GiftingProposalCashFlowCashFlowType.Expense
                        ? -1
                        : 1
                    ),
                    {
                      currencySign: 'accounting',
                      maximumFractionDigits: 0,
                    }
                  )}
                  subItemLeft1={dateRangeOrStartYear({
                    startYear: cf.startYear,
                    endYear: cf.endYear,
                  })}
                  subItemRight1={formatPercentGrowthOrDiscount({
                    percent: cf.growthPercentage,
                    isGrowth: true,
                  })}
                />
              ))}
            </Stack>
          </DataCell>
        )}
      </Stack>
      <Divider
        sx={{
          borderColor: COLORS.GRAY[300],
        }}
      />
    </>
  );
}
