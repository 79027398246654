import { Box, Stack } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { ArrowRightIcon } from '@/components/icons/ArrowRightIcon';
import { WithClasses } from '@/styles/types';

import { TileThemedTypography } from './TileThemedTypography';
import { TileVariant } from './types';

const useStyles = makeStyles()((_theme) => {
  return {
    before: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '28px',
    },
    after: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '28px',
    },
  };
});
interface TileBeforeAndAfterProps {
  before: string;
  after: string;
  variant: TileVariant;
  classes?: WithClasses<typeof useStyles>;
}

export function TileBeforeAndAfter({
  before,
  after,
  classes: externalClasses,
  variant,
}: TileBeforeAndAfterProps) {
  const { classes } = useStyles(undefined, {
    props: { classes: externalClasses },
  });

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <TileThemedTypography
        variant="body1"
        tileVariant={variant}
        className={classes.before}
      >
        {before}
      </TileThemedTypography>
      <Box>
        <ArrowRightIcon size={16} />
      </Box>
      <TileThemedTypography
        variant="body1"
        tileVariant={variant}
        className={classes.after}
      >
        {after}
      </TileThemedTypography>
    </Stack>
  );
}
