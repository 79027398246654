import { PopperContent } from '@/components/poppers/PopperContent';

export function GiftLifetimeExemption() {
  return (
    <PopperContent
      body={
        <span>
          The remaining lifetime exemption will be used in estate tax
          calculations in this analysis. Navigate to Gift & exemption tracking
          to update this information.
        </span>
      }
    />
  );
}
