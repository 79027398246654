import { Box, Stack, styled, Typography, TypographyProps } from '@mui/material';
import { CSSObject } from '@mui/styled-engine';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
  GridSortCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { GridStateColDef } from '@mui/x-data-grid-pro/internals';
import { subDays } from 'date-fns';
import { formatDistanceToNowStrict } from 'date-fns';
import addDays from 'date-fns/addDays';
import formatDistance from 'date-fns/formatDistance';
import { upperFirst } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ChartColorDefinitions } from '@/components/charts/constants';
import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { RelativeFormattedDate } from '../../typography/RelativeFormattedDate';
import { HeaderCellTypography } from '../components/HeaderCellTypography';
import { HEADER_BACKGROUND_COLOR, ROW_BORDER_STYLE } from '../constants';
import {
  defaultFieldComparator,
  lastActivityFieldComparator,
  taskDetailsSummaryFieldComparator,
  taskDueDateFieldComparator,
  textFieldComparator,
} from './sortingFunctions';
import { CellFormatType, Column } from './tableTypes';

type RowHoverVariant = 'transparent' | 'default';

const rowHoverVariantStyles: Record<RowHoverVariant, CSSObject> = {
  default: {
    backgroundColor: COLORS.FUNCTIONAL.HOVER,
  },
  transparent: {
    backgroundColor: 'transparent',
  },
};

export interface DataGridStyleConfig {
  rowHoverVariant: 'transparent' | 'default';
}

export const getDataGridSx = (
  graphColors: ChartColorDefinitions,
  { rowHoverVariant }: DataGridStyleConfig
) =>
  ({
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: HEADER_BACKGROUND_COLOR,
    },
    '.MuiDataGrid-row': {
      backgroundColor: 'white',
    },
    // you need to target both :hover and .Mui-hovered to handle all hover effects
    '.MuiDataGrid-row:hover': {
      ...rowHoverVariantStyles[rowHoverVariant],
    },
    '.MuiDataGrid-row.Mui-hovered': {
      ...rowHoverVariantStyles[rowHoverVariant],
    },
    '.MuiDataGrid-columnHeader:focus-within': {
      outline: 'none', // hide weird header cell focus
    },
    '.MuiDataGrid-columnHeader': {
      // hide extra left/right padding around headers; we rely on our header cell replacement for this
      padding: 0,
    },
    '.MuiDataGrid-columnHeader:not(:last-child)': {
      borderRight: '1px solid white',
    },
    '.MuiDataGrid-cell': {
      // all of the padding is managed by the cell type definition itself,
      // rather than doing it globally here.
      padding: 0,
      border: 'none',
      outline: 'none',
      overflow: 'visible !important', // to override a more specific MUI selector
    },
    '.MuiDataGrid-columnHeaderTitleContainer': {
      justifyContent: 'space-between',
      paddingX: 1,
    },
    '.column-header-primary-color': {
      background: `radial-gradient(farthest-corner at 5% 10%, ${graphColors.PRIMARY.radialGradient[0]}, ${graphColors.PRIMARY.radialGradient[1]})`,
    },
    '.column-header-primary-color svg': {
      visibility: 'hidden',
    },
    '.column-header-primary-color p': {
      color: graphColors.PRIMARY.contrastText,
    },
    [`.sum`]: {
      borderTop: `2px ${graphColors.PRIMARY.text} solid`,
      pointerEvents: 'none',
    },
    // hide action buttons in sum rows
    [`.sum [data-field='actions'] > div`]: {
      display: 'none',
    },
  }) as const;

interface ExtendedGridColumnHeaderParams<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- legacy code linter refactor
  V = any,
  R extends GridValidRowModel = GridValidRowModel,
  F = V,
> extends GridColumnHeaderParams<R, V, F> {
  colDef: GridStateColDef<R, V, F> & { contextualHelp?: JSX.Element };
}

const SHARED_CELL_PADDING = { py: 2.5, px: 2 };
export const renderHeader = (params: ExtendedGridColumnHeaderParams) => {
  // this reads weirdly, but we basically want to override a property on
  // SHARED_CELL_PADDING for the header because there's already an extra unit of
  // padding around the header, and this lines it up with the cell content.
  const computedCellPadding = Object.assign({}, SHARED_CELL_PADDING, { px: 1 });

  return (
    <>
      <HeaderCellTypography sx={computedCellPadding}>
        {params.colDef.headerName}
      </HeaderCellTypography>
      {params.colDef.contextualHelp && (
        <ContextualHelpTooltip>
          {params.colDef.contextualHelp}
        </ContextualHelpTooltip>
      )}
    </>
  );
};

const SHARED_CELL_STYLE: React.CSSProperties = {
  cursor: 'pointer',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  color: 'inherit',
  textDecoration: 'inherit',
  borderTop: ROW_BORDER_STYLE,
};

type CommonCellProps = Pick<Column, 'align'>;

const getDynamicCSSProps = (
  align: CommonCellProps['align']
): React.CSSProperties => {
  return {
    justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
    textAlign: align === 'right' ? 'right' : 'inherit',
  };
};

const getGuardedTitleTooltipFromValue = (value: unknown) => {
  // this is definitely not comprehensive; if there are more values you want to support or allow to be
  // safely stringified, please feel free to add them!
  if (typeof value === 'string' || typeof value === 'number') {
    return value.toString();
  }

  return '';
};

/**
 * This is the standard, white background cell container.
 */
const CellContainer = styled('div')<CommonCellProps>(({ align }) => {
  return {
    ...SHARED_CELL_STYLE,
    ...getDynamicCSSProps(align),
  };
});

/**
 * This is the standard input container, white background cell container.
 */
const InputContainer = styled('div')<CommonCellProps>(({ align }) => {
  return {
    ...SHARED_CELL_STYLE,
    cursor: 'auto',
    ...getDynamicCSSProps(align),
    ...{
      alignItems: 'unset',
    },
  };
});

/**
 * This is the cell container with the gray background that can optionally show a box shadow to emphasize
 * a given cell, usually on the bottom.
 */
const EmphasizedCellContainer = styled('div', {
  name: 'EmphasizedCellWrapper',
  // this needs to be done to avoid passing this prop all the way to the DOM
  shouldForwardProp: (propName) =>
    !['showShadow', 'sx', 'align'].includes(propName as string),
})<CommonCellProps & { showShadow: boolean }>(
  ({ showShadow, theme, align }) => ({
    ...SHARED_CELL_STYLE,
    backgroundColor: COLORS.GRAY[50],
    ...(showShadow ? { boxShadow: theme.palette.shadows.sm } : {}),
    ...getDynamicCSSProps(align),
  })
);

/**
 * This is the cell container with the teal background that's used to highlight a given cell.
 */
const HighlightCellContainer = styled('div')<CommonCellProps>(({ align }) => ({
  ...SHARED_CELL_STYLE,
  borderTop: `solid ${COLORS.TEAL[500]} 1px`,
  backgroundColor: COLORS.TEAL[50],
  ...getDynamicCSSProps(align),
}));

const PrimaryCellTypography = ({
  children,
  ...otherProps
}: TypographyProps & Pick<React.HTMLProps<HTMLDivElement>, 'title'>) => (
  <Typography
    {...otherProps}
    noWrap
    variant="subtitle1"
    component="span"
    color={COLORS.GRAY[900]}
  >
    {children}
  </Typography>
);

const SecondaryCellTypography = ({
  children,
  ...otherProps
}: TypographyProps) => (
  <Typography
    noWrap
    variant="subtitle2"
    component="span"
    color={COLORS.GRAY[500]}
    {...otherProps}
  >
    {children}
  </Typography>
);

const HighlightCellTypography = ({
  children,
  ...otherProps
}: TypographyProps) => (
  <Typography
    {...otherProps}
    variant="h5"
    fontWeight="bold"
    component="span"
    color={COLORS.TEAL[700]}
  >
    {children}
  </Typography>
);

const StandardCell = ({
  children,
  ...otherProps
}: React.PropsWithChildren<CommonCellProps>) => (
  <CellContainer {...otherProps} sx={SHARED_CELL_PADDING}>
    <PrimaryCellTypography>{children}</PrimaryCellTypography>
  </CellContainer>
);

const HighlightCell = ({
  children,
  ...otherProps
}: React.PropsWithChildren<CommonCellProps>) => (
  <HighlightCellContainer {...otherProps} sx={SHARED_CELL_PADDING}>
    <HighlightCellTypography>{children}</HighlightCellTypography>
  </HighlightCellContainer>
);

const StandardCellContent = ({
  value,
}: React.PropsWithChildren<{
  value: string | { lineOne: string; lineTwo?: string; value: string };
}>) => {
  if (typeof value === 'object' && Object.hasOwn(value, 'lineOne')) {
    return (
      <Stack spacing={1}>
        <PrimaryCellTypography>{value.lineOne}</PrimaryCellTypography>
        {/* The !important is to override a more specific mui css style */}
        {value.lineTwo && (
          <SecondaryCellTypography sx={{ mt: '0 !important' }}>
            {value.lineTwo}
          </SecondaryCellTypography>
        )}
      </Stack>
    );
  }

  const tooltip = getGuardedTitleTooltipFromValue(value);

  return (
    <PrimaryCellTypography title={tooltip}>
      {value as React.ReactNode}
    </PrimaryCellTypography>
  );
};

type TaskSummaryCellValue =
  | {
      display: string;
      dueAt: Date;
      assignedTo: string;
    }
  | null
  | '';

const TaskSummaryCellContent = ({
  value,
}: React.PropsWithChildren<{
  value: TaskSummaryCellValue;
}>) => {
  if (value === '') {
    return <StandardCellContent value="" />;
  }

  if (!value) {
    return <StandardCellContent value="No next task" />;
  }

  const isOverdue = new Date() > value.dueAt;
  // The !important is to override a more specific mui css style
  const overrideSx = { mt: '0 !important' };
  const dueStatus = isOverdue ? (
    <SecondaryCellTypography color="error" fontWeight={700} sx={overrideSx}>
      Was due on {formatDateToMonDDYYYY(value.dueAt)} | {value.assignedTo}
    </SecondaryCellTypography>
  ) : (
    <SecondaryCellTypography sx={overrideSx}>
      Due on {formatDateToMonDDYYYY(value.dueAt)} | {value.assignedTo}
    </SecondaryCellTypography>
  );

  return (
    <Stack spacing={1}>
      <PrimaryCellTypography>{value.display}</PrimaryCellTypography>
      {dueStatus}
    </Stack>
  );
};

const TaskDueDateContent = ({
  value: dueDate,
}: React.PropsWithChildren<{
  value: Date;
}>) => {
  // inside of 30 days, we want to show the number of remaining days until
  // the task is due.
  const dateToShowWarning = addDays(new Date(), 30);
  const dateToShowError = new Date();
  const severity = (() => {
    if (dueDate <= dateToShowError) return 'error';
    else if (dueDate <= dateToShowWarning) return 'warning';
    return 'standard';
  })();

  const relativeFormattedDate = formatDistance(dueDate, new Date());

  return (
    <Stack>
      <RelativeFormattedDate severity={severity}>
        {upperFirst(relativeFormattedDate)}
      </RelativeFormattedDate>
      <SecondaryCellTypography>
        {formatDateToMonDDYYYY(dueDate)}
      </SecondaryCellTypography>
    </Stack>
  );
};

export const cellFormattingMap: Record<
  CellFormatType,
  (params: GridRenderCellParams) => React.ReactNode
> = {
  EditableCell: (params) => {
    const [isHovered, setIsHovered] = React.useState(false);
    const navigate = useNavigate();
    const editClientPath = getCompletePathFromRouteKey(
      ROUTE_KEYS.MANAGE_HOUSEHOLD_DETAILS,
      {
        householdId: params.id.toString(),
      }
    );
    return (
      <CellContainer
        align={params.colDef.align}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        <Typography
          {...SHARED_CELL_PADDING}
          noWrap
          variant="h5"
          fontWeight={params.field === 'displayName' ? 'bold' : 'regular'}
          component="span"
          color={COLORS.GRAY[900]}
        >
          {params.value}
        </Typography>
        <EditButton
          sx={{
            visibility: isHovered ? 'visible' : 'hidden',
            marginLeft: 'auto',
            marginRight: 1,
          }}
          onClick={(e) => {
            // if you don't stop propagation, the event will get caught by the onRowClick
            // handler of the table
            e.stopPropagation();
            navigate(editClientPath);
          }}
        />
      </CellContainer>
    );
  },
  Input: (params) => {
    return (
      <InputContainer align={params.colDef.align}>
        <Box display="flex" justifyContent="center" width="100%" marginTop={1}>
          <Box width="90%">{params.value}</Box>
        </Box>
      </InputContainer>
    );
  },
  LastActivity: (params) => {
    let outputString = EMPTY_CONTENT_HYPHEN;
    const mostRecentActivity = params.value;
    if (mostRecentActivity instanceof Date) {
      // outside of 30 days, display the last date edited; otherwise, show days/hours/etc
      const monthAgo = subDays(new Date(), 30);
      if (monthAgo > mostRecentActivity) {
        outputString = mostRecentActivity.toLocaleDateString('en-US');
      } else {
        outputString = formatDistanceToNowStrict(mostRecentActivity);
      }
    }
    return (
      <StandardCell align={params.colDef.align}>
        <StandardCellContent value={outputString} />
      </StandardCell>
    );
  },
  Text: (params) => (
    <StandardCell align={params.colDef.align}>
      <StandardCellContent value={params.value} />
    </StandardCell>
  ),
  BoldText: (params) => (
    <StandardCell align={params.colDef.align}>
      <PrimaryCellTypography
        sx={{ fontWeight: 700 }}
        title={getGuardedTitleTooltipFromValue(params.value)}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- linter refactor + legacy table utils */}
        {params.value.component ? params.value.component : params.value}
      </PrimaryCellTypography>
    </StandardCell>
  ),
  Emphasized: (params) => (
    <EmphasizedCellContainer
      align={params.colDef.align}
      sx={{
        ...SHARED_CELL_PADDING,
      }}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- linter refactor + legacy table utils
      showShadow={params.row.isFinalRow}
    >
      <StandardCellContent value={params.value} />
    </EmphasizedCellContainer>
  ),
  HighlightLastCell: (params) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- linter refactor + legacy table utils
    const CellFormat = params.row.isFinalRow ? HighlightCell : StandardCell;
    return <CellFormat align={params.colDef.align}>{params.value}</CellFormat>;
  },
  TaskDetailsSummary: (params) => {
    return (
      <StandardCell align={params.colDef.align}>
        <TaskSummaryCellContent value={params.value} />
      </StandardCell>
    );
  },
  TaskDueDate: (params) => {
    return (
      <StandardCell align={params.colDef.align}>
        <TaskDueDateContent value={params.value} />
      </StandardCell>
    );
  },
  ActionIndicator: (params) => {
    return (
      <StandardCell align={params.colDef.align}>
        <ChevronRightIcon size={20} />
      </StandardCell>
    );
  },
};

function getSortComparator(cellFormat: CellFormatType) {
  switch (cellFormat) {
    case 'Text':
      return {
        sortComparator: textFieldComparator,
      };

    case 'BoldText':
      return {
        sortComparator: textFieldComparator,
      };

    case 'TaskDetailsSummary':
      return {
        sortComparator: taskDetailsSummaryFieldComparator,
      };

    case 'TaskDueDate':
      return {
        sortComparator: taskDueDateFieldComparator,
      };
    case 'LastActivity':
      return {
        sortComparator: lastActivityFieldComparator,
      };

    default:
      return {
        sortComparator: (
          v1: { value: string },
          v2: { value: string },
          param1: GridSortCellParams<{ value: string }>,
          param2: GridSortCellParams<{ value: string }>
        ) => defaultFieldComparator(v1, v2, param1, param2, cellFormat),
      };
  }
}

export function getFormattedColumnDefinitions(columns: Column[]): GridColDef[] {
  return columns.map<Column>((c) => {
    const renderCell = cellFormattingMap[c.cellFormat || 'Text'];
    // using Object.assign to avoid mutating the input
    const sortComparator = getSortComparator(c.cellFormat || 'Text');

    const newColumn: GridColDef = Object.assign(
      {
        renderHeader,
        renderCell,
        headerAlign: c.align || 'inherit',
        minWidth: c.minWidth ?? 100,
        ...sortComparator,
      },
      c
    );
    return newColumn;
  });
}
