import { keyBy } from 'lodash';

import { EntityGraphViewOrientation } from '@/types/schema';
import { assertNonNil } from '@/utils/assertUtils';

import {
  EntityMap_ClientProfileFragment,
  EntityMap_GrantorClientProfileFragment,
  EntityMap_NodeFragment,
  EntityMap_PossibleBeneficiariesV2Fragment,
} from '../graphql/EntityMap.generated';

export interface EntityGraphInput {
  entities: EntityMap_NodeFragment[];
  possibleBeneficiaries: EntityMap_PossibleBeneficiariesV2Fragment;
  possibleGrantors: EntityMap_GrantorClientProfileFragment[];
  possiblePrimaryClients: EntityMap_ClientProfileFragment[];
  orientation: EntityGraphViewOrientation;
}
export type NormalizeByIdOutput = ReturnType<typeof normalizeById>;
export function normalizeById({
  entities,
  possibleBeneficiaries,
  possibleGrantors,
}: EntityGraphInput) {
  const entitiesById = keyBy(entities, (entity) => entity.id);
  const individualBeneficiariesById = keyBy(
    possibleBeneficiaries.clients,
    ({ id }) => id
  );
  const organizationBeneficiariesById = keyBy(
    possibleBeneficiaries.organizations,
    ({ id }) => id
  );
  const individualGrantorsById = keyBy(possibleGrantors, ({ id }) => id);

  return {
    entitiesById,
    individualBeneficiariesById,
    organizationBeneficiariesById,
    individualGrantorsById,
  };
}

export const NAMESPACE_DELIMITER = '_';
export enum NodeNamespaces {
  GRANTOR = 'grantor',
  GRANTOR_BUSINESS_ENTITIES = 'grantorBusinessEntities',
}

// Each grantor can share the same id as a beneficiary, but we want
// grantors to be distinct on the entity map. For example, an individual can be both a
// beneficiary and a grantor, but we don't want them to show up as the same node.
// They should show up as distinct nodes. We namespace this id with the grantor_ prefix
//
// DANGER: this namespace gets persisted into the DB and is therefore brittle. do not change it without considering the implications.
export const getGrantorNodeId = (id: string) =>
  [NodeNamespaces.GRANTOR, id].join(NAMESPACE_DELIMITER);

export const getGrantorBusinessEntitiesNodeId = (id: string) =>
  [NodeNamespaces.GRANTOR_BUSINESS_ENTITIES, id].join(NAMESPACE_DELIMITER);

export const parseNamespacedNodeId = (id: string) => {
  for (const name of Object.values(NodeNamespaces)) {
    const start = `${name}${NAMESPACE_DELIMITER}`;
    if (id.startsWith(start)) {
      return assertNonNil(
        id.split(`${name}${NAMESPACE_DELIMITER}`)[1],
        `Invalid node namepspace for node id ${id}`
      );
    }
  }
  return id;
};
