// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetScenarioProjectionQueryVariables = Types.Exact<{
  params: Types.ProjectionParams;
}>;


export type GetScenarioProjectionQuery = { __typename?: 'Query', gratScenarioProjection: { __typename?: 'Projection', totalAssetValue: CurrencyUSD, transferredOutOfEstate: CurrencyUSD, totalAnnuityPayments: CurrencyUSD, taxableGiftAmount: CurrencyUSD, grantorRetainedInterest: CurrencyUSD, scenarioTableRows: Array<{ __typename?: 'ScenarioTableRow', year: number, beginningPrincipal: CurrencyUSD, principalGrowth: CurrencyUSD, annuityPayment: CurrencyUSD, annuityPaymentPercentOfInitialFunding: Percent, remainderValue: CurrencyUSD }> } };


export const GetScenarioProjectionDocument = gql`
    query GetScenarioProjection($params: ProjectionParams!) {
  gratScenarioProjection(params: $params) {
    totalAssetValue
    transferredOutOfEstate
    totalAnnuityPayments
    taxableGiftAmount
    grantorRetainedInterest
    scenarioTableRows {
      year
      beginningPrincipal
      principalGrowth
      annuityPayment
      annuityPaymentPercentOfInitialFunding
      remainderValue
    }
  }
}
    `;

/**
 * __useGetScenarioProjectionQuery__
 *
 * To run a query within a React component, call `useGetScenarioProjectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioProjectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioProjectionQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetScenarioProjectionQuery(baseOptions: Apollo.QueryHookOptions<GetScenarioProjectionQuery, GetScenarioProjectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenarioProjectionQuery, GetScenarioProjectionQueryVariables>(GetScenarioProjectionDocument, options);
      }
export function useGetScenarioProjectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenarioProjectionQuery, GetScenarioProjectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenarioProjectionQuery, GetScenarioProjectionQueryVariables>(GetScenarioProjectionDocument, options);
        }
export function useGetScenarioProjectionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetScenarioProjectionQuery, GetScenarioProjectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScenarioProjectionQuery, GetScenarioProjectionQueryVariables>(GetScenarioProjectionDocument, options);
        }
export type GetScenarioProjectionQueryHookResult = ReturnType<typeof useGetScenarioProjectionQuery>;
export type GetScenarioProjectionLazyQueryHookResult = ReturnType<typeof useGetScenarioProjectionLazyQuery>;
export type GetScenarioProjectionSuspenseQueryHookResult = ReturnType<typeof useGetScenarioProjectionSuspenseQuery>;
export type GetScenarioProjectionQueryResult = Apollo.QueryResult<GetScenarioProjectionQuery, GetScenarioProjectionQueryVariables>;
export const Operations = {
  Query: {
    GetScenarioProjection: 'GetScenarioProjection' as const
  }
}