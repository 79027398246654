import { GridProps, Stack } from '@mui/material';
import { useLayoutEffect, useRef, useState } from 'react';

import { FOOTER_HEIGHT } from '@/components/navigation/Footer';

import { Grid } from '../Grid';

export interface SplitScreenLayoutProps {
  gridProps?: GridProps;
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  header?: React.ReactNode;
  footer: React.ReactNode;
}

export function SplitScreenLayout({
  header,
  footer,
  leftContent,
  rightContent,
  gridProps = {},
}: SplitScreenLayoutProps) {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  useLayoutEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.clientHeight);
    }
  }, [header]);

  const nonFooterHeight = `calc(100vh - ${FOOTER_HEIGHT}px - ${headerHeight}px)`;

  return (
    <Grid container columns={12} height="100vh" width="100%" {...gridProps}>
      <Grid item sm={12} width="100%">
        <div ref={headerRef}>{header}</div>
      </Grid>
      <Grid item sm={12} height={nonFooterHeight}>
        <Stack direction="row" height="100%">
          <Stack width="50%" flex={1}>
            {leftContent}
          </Stack>
          <Stack flex={1} width="50%" maxWidth="800px">
            {rightContent}
          </Stack>
        </Stack>
      </Grid>
      <Grid item sm={12} height={FOOTER_HEIGHT}>
        {footer}
      </Grid>
    </Grid>
  );
}
