import { Grid, Stack } from '@mui/material';

import { appbarHeight } from '@/components/architecture/Layout/AppBar/AppBar';

import { Footer, FOOTER_HEIGHT, FooterProps } from '../../navigation/Footer';

export interface DesignerLayoutGridProps {
  leftColumns?: number; // grid columns the left content column should fill
  rightColumns?: number; // grid columns the right content column should fill
  spacing?: number;
  footerLeftAction?: FooterProps['leftAction'];
  footerRightAction?: FooterProps['rightAction'];
  LeftColumn: React.ReactElement;
  RightColumn: React.ReactElement;
}

export function DesignerLayoutGrid({
  leftColumns = 4,
  rightColumns = 8,
  spacing = 3,
  footerLeftAction,
  footerRightAction,
  LeftColumn,
  RightColumn,
}: DesignerLayoutGridProps) {
  const totalColumns = leftColumns + rightColumns;

  return (
    <>
      <Stack
        overflow="hidden"
        height={
          footerLeftAction || footerRightAction
            ? `calc(100vh - ${FOOTER_HEIGHT}px - ${appbarHeight})`
            : '100%'
        }
      >
        <Stack p={spacing} spacing={spacing} height="100%" overflow="auto">
          <Grid container columns={totalColumns}>
            <Grid item sm={leftColumns} sx={{ pr: spacing }}>
              <Stack spacing={spacing}>{LeftColumn}</Stack>
            </Grid>
            <Grid item sm={rightColumns}>
              <Stack spacing={spacing}>{RightColumn}</Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      {(footerLeftAction || footerRightAction) && (
        <Footer leftAction={footerLeftAction} rightAction={footerRightAction} />
      )}
    </>
  );
}
