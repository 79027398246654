import { useState } from 'react';

import {
  ButtonWithPopover,
  ButtonWithPopoverProps,
} from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useModalState } from '@/hooks/useModalState';
import { AIOnboardingModal } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal';
import { GenerateFromDocumentsMenuItem } from '@/modules/aiOnboarding/components/GenerateFromDocumentsMenuItem';
import { GenerateFromDocumentsModal } from '@/modules/aiOnboarding/components/GenerateFromDocumentsModal';
import { ClientOrganizationModal } from '@/modules/clientOrganizations/ClientOrganizationModal';
import { ClientProfileModal } from '@/modules/clientProfiles/ClientProfileForm/ClientProfileModal';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';

interface AddIndividualOrOrganizationProps
  extends Partial<ButtonWithPopoverProps> {
  householdId: string;
}

export function AddIndividualOrOrganization({
  householdId,
  ...buttonProps
}: AddIndividualOrOrganizationProps) {
  const aiOnboardingEnabled = useFeatureFlag('ai_onboarding');

  const [
    clientProfileModalState,
    {
      openModal: openClientProfileModal,
      setModalIsOpen: setClientProfileModalIsOpen,
    },
  ] = useModalState();
  const [
    clientOrganizationModalState,
    {
      openModal: openClientOrganizationModal,
      setModalIsOpen: setClientOrganizationModalOpen,
    },
  ] = useModalState();
  const [
    { isModalOpen: isGenerateFromDocumentsModalOpen },
    { setModalIsOpen: setIsGenerateFromDocumentsModalOpen },
  ] = useModalState();
  const [openAIOnboardingModalID, setOpenAIOnboardingModalID] = useState<
    string | null
  >(null);

  return (
    <>
      {clientProfileModalState.isModalOpen && (
        <ClientProfileModal
          isOpen={clientProfileModalState.isModalOpen}
          setIsOpen={setClientProfileModalIsOpen}
          householdId={householdId}
          clientProfileId={null}
          clientProfileTypeDisplay="individual"
          isPrimary={false}
          forceGrantorIfNoClientProfileId={false}
          allowDelete={true}
        />
      )}
      {clientOrganizationModalState.isModalOpen && (
        <ClientOrganizationModal
          isOpen={clientOrganizationModalState.isModalOpen}
          setIsOpen={setClientOrganizationModalOpen}
          organizationId={null}
          householdId={householdId}
        />
      )}
      {isGenerateFromDocumentsModalOpen && (
        <GenerateFromDocumentsModal
          isOpen={isGenerateFromDocumentsModalOpen}
          onClose={() => setIsGenerateFromDocumentsModalOpen(false)}
        />
      )}
      {openAIOnboardingModalID && (
        <AIOnboardingModal
          householdId={householdId}
          kgRootId={openAIOnboardingModalID}
          isOpen={!!openAIOnboardingModalID}
          onClose={() => setOpenAIOnboardingModalID(null)}
        />
      )}
      <ButtonWithPopover
        label="Add"
        variant="primary"
        size="sm"
        popperVariant="menuBelow"
        startIcon={PlusIcon}
        iconProps={{ size: 20 }}
        {...buttonProps}
      >
        {aiOnboardingEnabled && (
          <SelectItemGroupLabel key="automated" label="Automated" />
        )}
        {aiOnboardingEnabled && (
          <GenerateFromDocumentsMenuItem
            householdId={householdId}
            onOpenGenerateFromDocumentsModal={() =>
              setIsGenerateFromDocumentsModalOpen(true)
            }
            onOpenAIOnboardingModal={(kgRootId) =>
              setOpenAIOnboardingModalID(kgRootId)
            }
          />
        )}
        {aiOnboardingEnabled && (
          <SelectItemGroupLabel key="manual" label="Manual" />
        )}
        <MenuItem
          label="New individual"
          onClick={() => openClientProfileModal()}
        />
        <MenuItem
          label="New organization"
          onClick={() => openClientOrganizationModal()}
        />
      </ButtonWithPopover>
    </>
  );
}
