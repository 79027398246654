import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { getNodes } from '@/utils/graphqlUtils';

import {
  CustomEntityGrowthCustomizationType,
  GrowthProfileModalGrowthOverride,
} from '../GrowthProfileModal/GrowthProfileModal.types';
import { CustomEntityGrowthModalBody } from './CustomEntityGrowthModal.components';
import {
  CustomEntityGrowthFormShape,
  CustomEntityGrowthModalFormData,
} from './CustomEntityGrowthModal.types';
import {
  getCustomEntityGrowthModalCustomizationType,
  handleCustomEntityGrowthModalSubmit,
} from './CustomEntityGrowthModal.utils';
import {
  CustomEntityGrowthModal_EntityFragment,
  useCustomEntityGrowthModalQuery,
} from './graphql/CustomEntityGrowthModal.generated';

export interface CustomEntityGrowthModalProps
  extends CustomEntityGrowthModalFormData {
  onClose: () => void;
  canDelete: boolean;
  onDelete: () => void;
  householdId: string;
  onSubmit: (arg: {
    formData: GrowthProfileModalGrowthOverride;
    entity: CustomEntityGrowthModal_EntityFragment;
  }) => void;
}

export function CustomEntityGrowthModal({
  onClose,
  canDelete,
  onDelete,
  householdId,
  onSubmit: onSubmitExternal,
  ...formData
}: CustomEntityGrowthModalProps) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const formMethods = useForm<CustomEntityGrowthFormShape>({
    defaultValues: {
      entityId: formData.entityId || '',
      customGrowthRate: formData.customGrowthRate,
      entityFutureValuationValue: formData.entityFutureValuationValue,
      entityFutureValuationYear: formData.entityFutureValuationYear,
      entityGrowthAfterFutureValuation:
        formData.entityGrowthAfterFutureValuation,
      entityGrowthBeforeFutureValuation:
        formData.entityGrowthBeforeFutureValuation,
      customizationType:
        getCustomEntityGrowthModalCustomizationType(formData) ||
        CustomEntityGrowthCustomizationType.GrowthRate,
    },
  });

  const { handleSubmit } = formMethods;

  const { data, loading } = useCustomEntityGrowthModalQuery({
    variables: { householdId },
    onError: (error) => {
      reportError(
        `Could not fetch list of household entities for ${householdId}`,
        error as Error
      );
      showFeedback(
        'Could not fetch the list of entities.  Please try again later.'
      );
    },
  });

  const entities: CustomEntityGrowthModal_EntityFragment[] = useMemo(
    () => getNodes(data?.entities),
    [data?.entities]
  );

  const onSubmit = handleSubmit((formData) => {
    try {
      const errorMessage = handleCustomEntityGrowthModalSubmit({
        formData,
        entities,
        onSubmit: onSubmitExternal,
      })?.errorMessage;

      if (errorMessage) {
        showFeedback(errorMessage);
      }
    } catch (error) {
      showFeedback(
        'Could not update the growth profile for the selected entity.'
      );
      reportError(
        'Could not save custom entity growth profile',
        error as Error
      );
    }
  });

  return (
    <FormProvider {...formMethods}>
      <FormModal
        onClose={onClose}
        isOpen
        heading="Custom entity-level growth"
        actions={
          <FormModalActions.Provider>
            {canDelete && (
              <FormModalActions.DeleteButton onConfirmDelete={onDelete} />
            )}
            <FormModalActions.CancelButton onClick={onClose} />
            <FormModalActions.SaveButton
              onClick={onSubmit}
              disabled={loading}
            />
          </FormModalActions.Provider>
        }
      >
        <CustomEntityGrowthModalBody entities={entities} />
      </FormModal>
    </FormProvider>
  );
}
