import React, { useEffect } from 'react';

import { featureFlagsVar } from '@/graphql/reactiveVars';
import * as diagnostics from '@/utils/diagnostics';

import { FeatureFlag, FeatureFlagMap } from './featureFlags.types';
import { useFeatureFlagsQuery } from './graphql/FeatureFlags.generated';

export const FeatureFlagsContext = React.createContext<FeatureFlag[]>([]);

export function FeatureFlagsProvider({ children }: React.PropsWithChildren) {
  const { data } = useFeatureFlagsQuery({
    fetchPolicy: 'cache-first',
    onError: (error) => {
      diagnostics.error('Failed to fetch feature flags', error);
    },
  });

  useEffect(() => {
    if (!data?.featureFlagEvaluationResult) {
      return;
    }

    const featureFlags: FeatureFlagMap =
      data.featureFlagEvaluationResult.reduce(
        (acc, flag) => ({
          ...acc,
          [flag.name]: flag.flagEvaluationResult,
        }),
        {}
      );

    featureFlagsVar(featureFlags);
  }, [data?.featureFlagEvaluationResult]);

  return (
    <FeatureFlagsContext.Provider
      value={(data?.featureFlagEvaluationResult ?? []) as FeatureFlag[]}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
}
