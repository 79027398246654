import { GridRowParams } from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { useCallback, useMemo } from 'react';

import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { DefaultPaginatedTableFooter } from '@/components/tables/DataTable/components/DefaultPaginatedTableFooter';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { PaginatedTableProps } from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { useModalState } from '@/hooks/useModalState';
import { CashflowModal } from '@/modules/cashflows/CashflowModal/CashflowModal';
import { Cashflows_CashflowFragment } from '@/modules/cashflows/CashflowModal/graphql/Cashflows.generated';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrencyNoDecimalsAccounting } from '@/utils/formatting/currency';

import { CashFlowDataTableEmptyState } from './CashflowTable.components';
import { mapDataToRows } from './CashflowTable.utils';

export interface CashflowTableProps {
  paginatedTableProps?: PaginatedTableProps;
  cashflows: Cashflows_CashflowFragment[];
  entityId?: string;
  individualId?: string;
}

export interface CashflowTableRowData {
  id: string;
  name: string;
  amount: Decimal;
  description: string;
  entityName: string;
  growthRate: Decimal | null;
  action: null;
}

const getColumns: (includeEntity: boolean) => Column<CashflowTableRowData>[] = (
  includeEntity: boolean
) =>
  compact([
    {
      field: 'name',
      headerName: 'Cash flow',
      width: 200,
      flex: 1,
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => row.name,
        lineTwo: ({ row }) => row.description,
      }),
    },
    includeEntity && {
      field: 'entityName',
      headerName: 'Client/entity',
      width: 200,
      flex: 1,
      renderCell: TextRenderer({
        text: ({ row }) => row.entityName,
      }),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 250,
      flex: 1,
      align: 'right',
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => formatCurrencyNoDecimalsAccounting(row.amount),
        lineTwo: ({ row }) =>
          row.growthRate ? `${row.growthRate.toString()}% growth` : null,
        lineOneProps: ({ row }) => ({
          color: row.amount.isNegative()
            ? COLORS.FUNCTIONAL.ERROR[600]
            : COLORS.NAVY[900],
        }),
      }),
    },
    {
      field: 'action',
      renderCell: IconRenderer({
        icon: Edit02Icon,
      }),
      sortable: false,
      width: 64,
      minWidth: 64,
      maxWidth: 64,
    },
  ]);

export function CashflowsTable({
  cashflows,
  paginatedTableProps = {},
  entityId,
  individualId,
}: CashflowTableProps) {
  const { isTwoClientHousehold } = useHouseholdDetailsContext();
  const [{ isModalOpen, data: cashflowId }, { closeModal, openModal }] =
    useModalState<string>();
  const rows = useMemo(
    () => mapDataToRows(cashflows, isTwoClientHousehold),
    [cashflows, isTwoClientHousehold]
  );
  const hasRecipient = !!individualId || !!entityId;

  const onRowClick = useCallback(
    ({ row }: GridRowParams<CashflowTableRowData>) => {
      openModal(row.id);
    },
    [openModal]
  );

  const columns = useMemo(() => getColumns(!hasRecipient), [hasRecipient]);

  if (!rows.length && !paginatedTableProps?.loading) {
    return <CashFlowDataTableEmptyState recipient={entityId || individualId} />;
  }

  return (
    <>
      <CashflowModal
        isOpen={isModalOpen}
        onClose={closeModal}
        cashflowId={cashflowId}
        recipient={individualId || entityId}
      />
      <DataTable
        columns={columns}
        rows={rows}
        onRowClick={onRowClick}
        {...paginatedTableProps}
        hideFooterPagination={hasRecipient}
        hideFooterRowCount={hasRecipient}
        slots={{
          footer: hasRecipient ? () => <></> : DefaultPaginatedTableFooter,
        }}
      />
    </>
  );
}
