import {
  AugmentedCreateCltTrustInput,
  AugmentedCreateCrtTrustInput,
  AugmentedCreateDonorAdvisedFundInput,
  AugmentedCreateEntityInput,
  AugmentedCreateGratTrustInput,
  AugmentedCreateIlitTrustInput,
  AugmentedCreateIndividualPersonalAccountInput,
  AugmentedCreateIrrevocableTrustInput,
  AugmentedCreateJointPersonalAccountInput,
  AugmentedCreatePrincipalInput,
  AugmentedCreatePrivateFoundationInput,
  AugmentedCreateQprtTrustInput,
  AugmentedCreateQualifiedTuitionPersonalAccountInput,
  AugmentedCreateRetirementPersonalAccountInput,
  AugmentedCreateRevocableTrustInput,
  AugmentedCreateSlatTrustInput,
  CreateEntityInput,
  EntityKind,
  EntityStage,
  Exact,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import {
  entityGstStatusToApiTypeMap,
  estateInclusionStatusToApiTypeMap,
  nonTrustEntityTaxStatusToApiTypeMap,
  trustTaxStatusToApiTypeMap,
} from '../EntitySubforms/utils/shared/taxStatus.utils';
import { getTaxStatusValuesFromEntityKind } from '../TaxStatusSubform/TaxStatusSubform.utils';
import { HypotheticalDraftEntityType } from './draftEntities.constants';

interface GetCreateDraftEntityInputParams {
  draftEntityType: HypotheticalDraftEntityType;
  draftEntityName: string;
  draftEntityGrantorId: string;
  householdId: string;
}

const DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT = {
  create: {
    displayName: 'Default account',
  },
  withInitialValuation: null,
  withValuations: [],
};

export function getCreateDraftEntityInput({
  draftEntityType,
  draftEntityName,
  draftEntityGrantorId,
  householdId,
}: GetCreateDraftEntityInputParams): AugmentedCreateEntityInput {
  const create: CreateEntityInput = {
    stage: EntityStage.Draft,
    householdID: householdId,
  };

  const principals: AugmentedCreatePrincipalInput[] = draftEntityGrantorId
    .split(',')
    .map((id) => ({
      create: {
        individualID: id,
      },
    }));

  const taxStatusValues = getTaxStatusValuesFromEntityKind(draftEntityType);

  const trustTaxStatus = taxStatusValues.taxStatus
    ? trustTaxStatusToApiTypeMap[taxStatusValues.taxStatus]
    : undefined;
  const nonTrustEntityTaxStatus = taxStatusValues.nonTrustEntityTaxStatus
    ? nonTrustEntityTaxStatusToApiTypeMap[
        taxStatusValues.nonTrustEntityTaxStatus
      ]
    : undefined;

  const inEstateStatus =
    estateInclusionStatusToApiTypeMap[taxStatusValues.estateInclusionStatus];

  const gstStatus = taxStatusValues.gstStatus
    ? entityGstStatusToApiTypeMap[taxStatusValues.gstStatus]
    : undefined;

  const createTrust: Exact<
    | AugmentedCreateIrrevocableTrustInput
    | AugmentedCreateRevocableTrustInput
    | AugmentedCreateSlatTrustInput
    | AugmentedCreateGratTrustInput
    | AugmentedCreateIlitTrustInput
    | AugmentedCreateQprtTrustInput
    | AugmentedCreateCrtTrustInput
    | AugmentedCreateCltTrustInput
  > = {
    create: {
      displayName: draftEntityName,
      taxStatus: trustTaxStatus,
      inEstateStatus,
      gstStatus,
    },
    withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
  };

  const createNonTrust: Exact<
    | AugmentedCreateIndividualPersonalAccountInput
    | AugmentedCreateJointPersonalAccountInput
    | AugmentedCreateRetirementPersonalAccountInput
    | AugmentedCreateQualifiedTuitionPersonalAccountInput
    | AugmentedCreateDonorAdvisedFundInput
    | AugmentedCreatePrivateFoundationInput
  > = {
    create: {
      displayName: draftEntityName,
      taxStatus: nonTrustEntityTaxStatus,
      inEstateStatus,
      gstStatus,
    },
  };

  switch (draftEntityType) {
    case EntityKind.IrrevocableTrust:
      return {
        create,
        withIrrevocableTrust: {
          ...createTrust,
          withGrantors: principals,
        },
      };

    case EntityKind.RevocableTrust:
      return {
        create,
        withRevocableTrust: {
          ...createTrust,
          withGrantors: principals,
        },
      };

    case EntityKind.SlatTrust:
      return {
        create,
        withSlatTrust: {
          ...createTrust,
          withGrantor: principals[0],
        },
      };

    case EntityKind.GratTrust:
      return {
        create,
        withGratTrust: {
          ...createTrust,
          withGrantor: principals[0],
        },
      };

    case EntityKind.IlitTrust:
      return {
        create,
        withIlitTrust: {
          ...createTrust,
          withGrantors: principals,
        },
      };

    case EntityKind.QprtTrust:
      return {
        create,
        withQprtTrust: {
          ...createTrust,
          withGrantors: principals,
        },
      };

    case EntityKind.CrtTrust:
      return {
        create,
        withCrtTrust: {
          ...createTrust,
          withGrantors: principals,
        },
      };

    case EntityKind.CltTrust:
      return {
        create,
        withCltTrust: {
          ...createTrust,
          withGrantors: principals,
        },
      };

    case EntityKind.IndividualPersonalAccount:
      return {
        create,
        withIndividualPersonalAccount: {
          ...createNonTrust,
          withOwner: principals[0],
        },
      };

    case EntityKind.JointPersonalAccount:
      return {
        create,
        withJointPersonalAccount: {
          create: {
            ...createNonTrust.create,
            type: undefined,
          },
          withOwners: principals,
        },
      };

    case EntityKind.RetirementPersonalAccount:
      return {
        create,
        withRetirementPersonalAccount: {
          create: {
            ...createNonTrust.create,
            type: undefined,
          },
          withOwner: principals[0],
        },
      };

    case EntityKind.QualifiedTuitionPersonalAccount:
      return {
        create,
        withQualifiedTuitionPersonalAccount: {
          ...createNonTrust,
          withOwner: principals[0],
        },
      };

    case EntityKind.DonorAdvisedFund:
      return {
        create,
        withDonorAdvisedFund: {
          ...createNonTrust,
          withDonors: principals,
        },
      };

    case EntityKind.PrivateFoundation:
      return {
        create,
        withPrivateFoundation: {
          ...createNonTrust,
          withDonors: principals,
        },
      };

    default:
      throw new UnreachableError({
        case: draftEntityType,
        message: `Unrecognized entity type: ${draftEntityType}`,
      });
  }
}
