import { Stack, SxProps, Theme } from '@mui/material';
import React, { FC, ReactNode } from 'react';

import { Medallion } from '@/components/display/Medallion/Medallion';
import { Card, CardProps } from '@/components/layout/Card/Card';

export interface SubtotalCardProps {
  children?: ReactNode;
  caption: ReactNode;
  iconColor: string;
  icon: ReactNode;
  iconSize?: number;
  wrapperSx?: SxProps<Theme>;
  cardVariant?: CardProps['variant'];
}

export const SubtotalCard: FC<SubtotalCardProps> = ({
  cardVariant = 'outlined',
  children,
  caption,
  iconColor,
  icon,
  iconSize = 40,
  wrapperSx = {},
}) => {
  return (
    <Card
      sx={{
        // sane defaults (overridable)
        px: 4,
        py: 2,
        gap: 2,
        // override
        ...wrapperSx,
        // required properties (not overridable)
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      variant={cardVariant}
    >
      <Stack
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
          flex: '1 1 50%',
        }}
      >
        <Medallion
          colors={{ background: iconColor }}
          icon={icon}
          size={iconSize}
        />
        {caption}
      </Stack>
      {children}
    </Card>
  );
};
