import { Stack } from '@mui/material';
import { addDays } from 'date-fns';
import { useMemo } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { RibbonCard } from '@/components/layout/RibbonCard';
import {
  TasksTable,
  TaskTableColumns,
} from '@/modules/tasks/components/TasksTable/TasksTable';
import { RouteKey } from '@/navigation/constants';
import { EntityTaskWhereInput } from '@/types/schema';

interface Props {
  householdId?: string;
  viewAllPath: string;
  from: RouteKey;
}

export function TasksDue({ householdId, viewAllPath, from }: Props) {
  const tasksFilter: EntityTaskWhereInput = useMemo(() => {
    const filter: EntityTaskWhereInput = {
      dueAtLTE: addDays(new Date(), 30),
    };

    if (householdId) {
      filter.hasEntityWith = [
        {
          hasHouseholdWith: [
            {
              id: householdId,
            },
          ],
        },
      ];
    }

    return filter;
  }, [householdId]);

  return (
    <RibbonCard
      heading="Tasks due in 30 days"
      footer={
        <Stack direction="row" justifyContent="flex-end">
          <Button variant="secondary" size="sm" href={viewAllPath}>
            View all tasks
          </Button>
        </Stack>
      }
    >
      <TasksTable
        filter={tasksFilter}
        pageSize={10}
        columns={[
          TaskTableColumns.DUE_AT,
          TaskTableColumns.TASK_NAME,
          TaskTableColumns.ENTITY_NAME,
          TaskTableColumns.ASSIGNEE,
        ]}
        from={from}
      />
    </RibbonCard>
  );
}
