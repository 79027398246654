// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { GiftingProposalProjectionFragmentDoc } from '../../../modules/gifting/proposal/graphql/GiftingProposalProjection.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGiftProjectedWealthQueryVariables = Types.Exact<{
  params: Types.GiftingProposalClientProjectionParams;
}>;


export type GetGiftProjectedWealthQuery = { __typename?: 'Query', giftingProposalClientProjections: Array<{ __typename?: 'GiftingProposalProjections', inEstateValueAfterTax: CurrencyUSD, inEstateValueBeforeTax: CurrencyUSD, outOfEstateCharitableValue: CurrencyUSD, outOfEstateFamilyValue: CurrencyUSD, outOfEstateTotalValue: CurrencyUSD, taxableGift: CurrencyUSD, totalValue: CurrencyUSD, remainingLifetimeExemption: CurrencyUSD, lifetimeExemptionAppliedTowardGiftTax: CurrencyUSD, giftTax: CurrencyUSD, scenarioId: string, yearInEstatePortfolioDepleted?: number | null, scenario: { __typename?: 'GiftingProposalScenario', order: number, displayName: string, isDefault: boolean, isBaseline: boolean, id: string }, estateTaxSummary: { __typename?: 'TaxSummary', _cacheKey: string, federalTax?: { __typename?: 'TaxDetail', _cacheKey: string, deductions: CurrencyUSD, exemptionAvailable: CurrencyUSD, exemptionCliff?: CurrencyUSD | null, exemptionUsed: CurrencyUSD, portableExemption?: CurrencyUSD | null, stateCode?: Types.StateCode | null, stateDisclosure?: string | null, tax: CurrencyUSD, taxableAmount: CurrencyUSD, taxableAmountAfterExemption: CurrencyUSD, taxableTransfers: CurrencyUSD, totalExemption: CurrencyUSD, taxableAmountOverCliff?: boolean | null } | null, stateTax?: Array<{ __typename?: 'TaxDetail', _cacheKey: string, deductions: CurrencyUSD, exemptionAvailable: CurrencyUSD, exemptionCliff?: CurrencyUSD | null, exemptionUsed: CurrencyUSD, portableExemption?: CurrencyUSD | null, stateCode?: Types.StateCode | null, stateDisclosure?: string | null, tax: CurrencyUSD, taxableAmount: CurrencyUSD, taxableAmountAfterExemption: CurrencyUSD, taxableTransfers: CurrencyUSD, totalExemption: CurrencyUSD, taxableAmountOverCliff?: boolean | null }> | null } }> };


export const GetGiftProjectedWealthDocument = gql`
    query GetGiftProjectedWealth($params: GiftingProposalClientProjectionParams!) {
  giftingProposalClientProjections(params: $params) {
    ...GiftingProposalProjection
  }
}
    ${GiftingProposalProjectionFragmentDoc}`;

/**
 * __useGetGiftProjectedWealthQuery__
 *
 * To run a query within a React component, call `useGetGiftProjectedWealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftProjectedWealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftProjectedWealthQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetGiftProjectedWealthQuery(baseOptions: Apollo.QueryHookOptions<GetGiftProjectedWealthQuery, GetGiftProjectedWealthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiftProjectedWealthQuery, GetGiftProjectedWealthQueryVariables>(GetGiftProjectedWealthDocument, options);
      }
export function useGetGiftProjectedWealthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiftProjectedWealthQuery, GetGiftProjectedWealthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiftProjectedWealthQuery, GetGiftProjectedWealthQueryVariables>(GetGiftProjectedWealthDocument, options);
        }
export function useGetGiftProjectedWealthSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGiftProjectedWealthQuery, GetGiftProjectedWealthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGiftProjectedWealthQuery, GetGiftProjectedWealthQueryVariables>(GetGiftProjectedWealthDocument, options);
        }
export type GetGiftProjectedWealthQueryHookResult = ReturnType<typeof useGetGiftProjectedWealthQuery>;
export type GetGiftProjectedWealthLazyQueryHookResult = ReturnType<typeof useGetGiftProjectedWealthLazyQuery>;
export type GetGiftProjectedWealthSuspenseQueryHookResult = ReturnType<typeof useGetGiftProjectedWealthSuspenseQuery>;
export type GetGiftProjectedWealthQueryResult = Apollo.QueryResult<GetGiftProjectedWealthQuery, GetGiftProjectedWealthQueryVariables>;
export const Operations = {
  Query: {
    GetGiftProjectedWealth: 'GetGiftProjectedWealth' as const
  }
}