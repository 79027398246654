import { Stack } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { useRequiredParam } from '@/hooks/useRequiredParam';

import { EntitiesBreadcrumb } from '../components/EntitiesBreadcrumb';
import { LiabilitiesTable } from './LiabilitiesTable/LiabilitiesTable';

interface ClientDetailsManageLiabilitiesPageInnerProps {
  householdId: string;
}

function ClientDetailsManageLiabilitiesPageInner({
  householdId,
}: ClientDetailsManageLiabilitiesPageInnerProps) {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pb={3}
      >
        <EntitiesBreadcrumb pageCrumb="Liabilities" householdId={householdId} />
      </Stack>
      <Card variant="outlined" sx={{ p: 3 }}>
        <LiabilitiesTable householdId={householdId} />
      </Card>
    </>
  );
}

export function ClientDetailsManageLiabilitiesPage() {
  const householdId = useRequiredParam('householdId');

  return <ClientDetailsManageLiabilitiesPageInner householdId={householdId} />;
}
