import { compact } from 'lodash';
import { formatPhoneNumber } from 'react-phone-number-input';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { formatEnumCase } from '@/utils/formatting/strings';

import { OrganizationSummaryPanel_OrganizationFragment } from './graphql/OrganizationSummaryPanel.generated';

export function getSummaryFields(
  org?: OrganizationSummaryPanel_OrganizationFragment
) {
  const location = compact([
    org?.address?.cityName,
    org?.address?.stateCode,
  ]).join(', ');

  const pocName = org?.pointOfContactName
    ? compact([org?.pointOfContactName, org?.pointOfContactLastName]).join(' ')
    : EMPTY_CONTENT_HYPHEN;

  const fields = {
    title: org?.name,
    location: location || EMPTY_CONTENT_HYPHEN,
    kind: org?.kind ? formatEnumCase(org?.kind) : EMPTY_CONTENT_HYPHEN,
    pocName,
    pocEmail: org?.pointOfContactEmail || EMPTY_CONTENT_HYPHEN,
    pocPhone: org?.pointOfContactPhone
      ? formatPhoneNumber(org?.pointOfContactPhone)
      : EMPTY_CONTENT_HYPHEN,
  } as const;

  return fields;
}
