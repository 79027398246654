import { useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { FlowChartProps, Node } from '@/components/diagrams/FlowChart';
import { useHelperLines } from '@/components/diagrams/FlowChart/components/HelperLines';
import { useIsInteractive } from '@/components/diagrams/FlowChart/hooks/useIsInteractive';
import { isTileGroupNode } from '@/components/diagrams/FlowChart/utils/nodes';

import { useEntityMapContext } from '../contexts/entityMap.context';

export function useFlowChartProps() {
  const { getNodes } = useReactFlow();
  const { isInteractive } = useIsInteractive();
  const { state, dispatch, getState } = useEntityMapContext();
  const { helperLineVertical, helperLineHorizontal, applyHelperLinesToNodes } =
    useHelperLines();

  const onNodeClick = useCallback<NonNullable<FlowChartProps['onNodeClick']>>(
    (_event, node) => {
      // We're in an edit state, only the default re-position interaction is allowed
      // or if it's a tile group node since there is no valid summary state for that
      if (isInteractive || isTileGroupNode(node)) return;

      // Clicking on the active summary node should close the pane
      if (node.id === state.summaryNodeId) {
        dispatch({ type: 'CLOSE_SUMMARY_PANEL' });
        return;
      }

      dispatch({ type: 'OPEN_SUMMARY_PANEL', nodeId: node.id });
    },
    [dispatch, isInteractive, state.summaryNodeId]
  );

  const onNodeMouseEnter = useCallback<
    NonNullable<FlowChartProps['onNodeMouseEnter']>
  >(
    (event, node) => {
      // No relevant code paths when non tile node (section group), or in edit mode
      if (node.type !== 'tile' || isInteractive) return;
      dispatch({ type: 'NODE_MOUSE_ENTER', event, node });
    },
    [dispatch, isInteractive]
  );

  const onNodeMouseLeave = useCallback<
    NonNullable<FlowChartProps['onNodeMouseLeave']>
  >(
    (event, node) => {
      // No relevant code paths when non tile node (section group), or in edit mode
      if (node.type !== 'tile' || isInteractive) return;
      dispatch({ type: 'NODE_MOUSE_LEAVE', event, node });
    },
    [dispatch, isInteractive]
  );

  const onNodesChange = useCallback<
    NonNullable<FlowChartProps['onNodesChange']>
  >(
    (changes) => {
      const nodes = applyHelperLinesToNodes(changes, getNodes() as Node[]);

      dispatch({ type: 'NODES_CHANGED', nodes, changes });
    },
    [applyHelperLinesToNodes, dispatch, getNodes]
  );

  const onEdgeMouseEnter = useCallback<
    NonNullable<FlowChartProps['onEdgeMouseEnter']>
  >(
    (event, edge) => {
      // No relevant code paths in edit mode
      if (isInteractive) return;

      /**
       * Note: for some reason, edge mouse enter events can happen in rapid succession.
       * This doesn't occur for nodes, maybe because the edge hover area is so small?
       * Either way, we guard against it here as a stop gap from many events firing.
       */
      if (getState().highlightedIds.has(edge.id)) return;

      dispatch({ type: 'EDGE_MOUSE_ENTER', event, edge });
    },
    [dispatch, getState, isInteractive]
  );

  const onEdgeMouseLeave = useCallback<
    NonNullable<FlowChartProps['onEdgeMouseLeave']>
  >(
    (event, edge) => {
      // No relevant code paths in edit mode
      if (isInteractive) return;
      dispatch({ type: 'EDGE_MOUSE_LEAVE', event, edge });
    },
    [dispatch, isInteractive]
  );

  const onPaneClick = useCallback<
    NonNullable<FlowChartProps['onPaneClick']>
  >(() => {
    if (getState().derivedData.isSummaryPanelOpen) {
      dispatch({ type: 'CLOSE_SUMMARY_PANEL' });
    }
  }, [dispatch, getState]);

  const flowChartProps: FlowChartProps = {
    style: state.isInitializing ? { opacity: 0 } : undefined,
    nodes: state.nodes,
    edges: state.edges,
    onNodeClick,
    onNodesChange,
    onNodeMouseEnter,
    onNodeMouseLeave,
    onEdgeMouseEnter,
    onEdgeMouseLeave,
    onPaneClick,
  };

  return { flowChartProps, helperLineHorizontal, helperLineVertical };
}
