import { LOCAL_STORAGE_KEYS } from '@/constants/localStorageKeys';
import { mostRecentlyViewedEntityMapVar } from '@/graphql/reactiveVars';

interface SetMostRecentlyViewedEntityMapIdProps {
  id: string;
  householdId: string;
}

export function setMostRecentlyViewedEntityMapId({
  id,
  householdId,
}: SetMostRecentlyViewedEntityMapIdProps) {
  const key = `${LOCAL_STORAGE_KEYS.LAST_VIEWED_ENTITY_MAP}_${householdId}`;
  localStorage.setItem(key, id);
  mostRecentlyViewedEntityMapVar({ id });
}
