import { TableFooter, Typography } from '@mui/material';

import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { StyledTableCell } from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import {
  useTotalAnnualExclusionUsed,
  useTotalGiftedAmount,
  useTotalGSTExemptionUsed,
  useTotalLifetimeExemptionUsed,
} from './ILITMakeAnnualGrantorGift.utils';

export interface ILITMakeAnnualGrantorGiftSummaryRowProps {
  grantorId: string;
}

export function ILITMakeAnnualGrantorGiftSummaryRow({
  grantorId,
}: ILITMakeAnnualGrantorGiftSummaryRowProps) {
  return (
    <TableFooter sx={{ borderTop: `2px solid ${COLORS.NAVY[600]}` }}>
      <StyledTableRow>
        <StyledTableCell>
          <RichListItem heading="Total gifted" />
        </StyledTableCell>
        <StyledTableCell align="right">
          <Typography fontWeight="bold">
            {formatCurrencyNoDecimals(useTotalGiftedAmount(grantorId))}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="right">
          <Typography fontWeight="bold">
            {formatCurrencyNoDecimals(useTotalAnnualExclusionUsed(grantorId))}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="right">
          <Typography fontWeight="bold">
            {formatCurrencyNoDecimals(useTotalLifetimeExemptionUsed(grantorId))}
          </Typography>
        </StyledTableCell>
        <StyledTableCell align="right">
          <Typography fontWeight="bold">
            {formatCurrencyNoDecimals(useTotalGSTExemptionUsed(grantorId))}
          </Typography>
        </StyledTableCell>
      </StyledTableRow>
    </TableFooter>
  );
}
