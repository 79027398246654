import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { BusinessEntityTaxStatus } from '@/types/schema';
import { PathsOf } from '@/types/subform';

import { businessTaxStatusDisplayNames } from '../details/businessEntities/businessEntities.constants';

export const NAMESPACE = 'businessEntityTaxStatusSubform' as const;
export type NamespaceType = typeof NAMESPACE;
const VARIANTS = ['default'] as const;
export type VariantType = (typeof VARIANTS)[number];

export interface BusinessEntitiesTaxStatusSubformType {
  taxId: string;
  taxStatus: BusinessEntityTaxStatus | '';
}

// this is what the subform's values will be nested under in the parent form
export interface Fields {
  [NAMESPACE]?: BusinessEntitiesTaxStatusSubformType;
}

export type SubformField = PathsOf<Fields>;

// this is Props for the UI component
export interface Props {
  variant: VariantType;
}

export const defaultValues: BusinessEntitiesTaxStatusSubformType = {
  taxId: '',
  taxStatus: '',
};

export const BUSINESS_ENTITY_TAX_STATUS_ITEMS: SelectInputOption<BusinessEntityTaxStatus>[] =
  [
    BusinessEntityTaxStatus.Corporation,
    BusinessEntityTaxStatus.DisregardedEntity,
    BusinessEntityTaxStatus.NonTaxableEntity,
    BusinessEntityTaxStatus.Partnership,
  ].map((value) => ({
    value,
    display: businessTaxStatusDisplayNames[value],
  }));
