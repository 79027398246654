import { first } from 'lodash';

import { getNodes } from '@/utils/graphqlUtils';

import { DEFAULT_ASSUMPTIONS } from '../EstateWaterfallAssumptions/constants';
import { EstateWaterfallComparisonModalFormShape } from './EstateWaterfallComparisonModal.type';
import { EstateWaterfallComparisonModalQuery } from './graphql/EstateWaterfallComparisonModal.generated';

export function mapDataToEstateWaterfallComparisonModal(
  data: EstateWaterfallComparisonModalQuery,
  waterfallId: string | null,
  localStorageWaterfallIds: string[]
): EstateWaterfallComparisonModalFormShape | null {
  const waterfall = first(getNodes(data?.estateWaterfalls));

  if (!waterfall) {
    return null;
  }

  const [waterfallA, waterfallB, waterfallC = '', waterfallD = ''] =
    localStorageWaterfallIds;

  return {
    waterfallA: waterfallA || waterfallId || '',
    waterfallB: waterfallB || waterfall.parent?.id || '',
    waterfallC,
    waterfallD,
    firstGrantorDeathYear:
      waterfall.firstGrantorDeathYear ||
      DEFAULT_ASSUMPTIONS.firstGrantorDeathYear,
    secondGrantorDeathYear:
      waterfall.secondGrantorDeathYear ||
      DEFAULT_ASSUMPTIONS.secondGrantorDeathYear,
    assetGrowthReturn:
      waterfall.assetGrowthReturn || DEFAULT_ASSUMPTIONS.assetGrowthReturn,
    exemptionGrowthRate:
      waterfall.exemptionGrowthRate || DEFAULT_ASSUMPTIONS.exemptionGrowthRate,
    willExemptionSunset:
      waterfall.willExemptionSunset || DEFAULT_ASSUMPTIONS.willExemptionSunset,
    useCustomGlobalGrowthRate: Boolean(waterfall.assetGrowthReturn),
  };
}
