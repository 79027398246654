import { Stack, Typography, useTheme } from '@mui/material';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { BankIcon } from '@/components/icons/BankIcon';
import { Card } from '@/components/layout/Card/Card';
import { HeaderWrapper } from '@/modules/entities/details/EntityValuation/components/EntityValuationCard';
import { getValuationDetailsFromEntity } from '@/modules/entities/details/EntityValuation/EntityValuation.utils';
import { EntityDetail_EntityFragment } from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { COLORS } from '@/styles/tokens/colors';
import { formatDateToMMDDYY } from '@/utils/formatting/dates';

interface EntityValuationProps {
  entity: EntityDetail_EntityFragment;
}

export function EntityValuation({ entity }: EntityValuationProps) {
  const theme = useTheme();
  const { headlineValue, headlineValueLabel, dateOfValuation } =
    getValuationDetailsFromEntity(entity, {
      isViewOnly: true,
    });

  return (
    <Stack sx={{ height: '100%' }}>
      <Stack spacing={3} sx={{ containerType: 'inline-size' }}>
        <HeaderWrapper data-name="HeaderWrapper">
          <OversizedMetricItem
            titleVariant="h5"
            valueVariant="h1"
            valueColor={COLORS.NEUTRAL_GRAY[900]}
            title={headlineValueLabel}
            value={headlineValue}
            icon={<BankIcon size={20} />}
            iconBackground={theme.palette.primary.main}
          />
          {dateOfValuation && (
            <Card
              variant="filled"
              sx={{ p: 1, borderRadius: '6px', textAlign: 'right' }}
            >
              <Typography variant="subtitle2">As of</Typography>
              <Typography variant="h6">
                {formatDateToMMDDYY(dateOfValuation)}
              </Typography>
            </Card>
          )}
        </HeaderWrapper>
      </Stack>
    </Stack>
  );
}
