import { useApolloClient } from '@apollo/client';
import { Stack } from '@mui/material';
import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import _, { compact } from 'lodash';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { Accordion } from '@/components/Accordion/Accordion';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { RichDocumentUploaderWithList } from '@/modules/documents/RichDocumentUploaderWithList/RichDocumentUploaderWithList';
import { CHARITABLE_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import {
  BeneficiaryDropdownSingle,
  getOptionsFromData as getBeneficiaryOptionsFromData,
} from '@/modules/entities/inputs/BeneficiaryDropdown/BeneficiaryDropdown';
import {} from '@/modules/entities/inputs/BeneficiaryDropdown/BeneficiaryDropdown';
import { PrimaryClientDropdownSingle } from '@/modules/entities/inputs/PrimaryClientDropdown/PrimaryClientDropdown';
import { getPrimaryClientOptionsFromData } from '@/modules/entities/inputs/PrimaryClientDropdown/PrimaryClientDropdown.utils';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { getNodes } from '@/utils/graphqlUtils';

import { ClientDetailsGiftingPage_HouseholdFragment } from '../graphql/ClientDetailsGiftingPage.generated';
import { useGiftingExemptionsForClient } from '../hooks/useGiftingExemptionsForClient';
import { useLogNewGiftModalFormOptionsQuery } from './graphql/LogNewGiftModal.generated';
import { assetTypeOptions, NAMESPACE } from './LogNewGiftModal.constants';
import { LogNewGiftForm, LogNewGiftFormPaths } from './LogNewGiftModal.types';

export function useFormOptions({ householdId }: { householdId: string }) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { control } = useFormContext<LogNewGiftForm>();

  const { data } = useLogNewGiftModalFormOptionsQuery({
    variables: {
      where: {
        id: householdId,
      },
    },
    onError: (error) => {
      showFeedback(
        "We weren't able to load this form. Please refresh the page and try again."
      );
      reportError(
        'failed to load form options for the LogNewGiftModal',
        error,
        {
          householdId,
        }
      );
    },
  });

  const possibleBeneficiaries = getNodes(data?.households)[0];

  const selectedGrantor = useWatch({
    name: `${NAMESPACE}.grantor` as const satisfies LogNewGiftFormPaths,
    control,
  });

  const household = data?.households.edges?.[0]?.node ?? null;
  const primaryClientOptions = getPrimaryClientOptionsFromData(
    household,
    compact([selectedGrantor.clientProfileId])
  );

  const selectedBeneficiaries = useWatch({
    name: `${NAMESPACE}.beneficiary` as const satisfies LogNewGiftFormPaths,
    control,
  });

  const beneficiaryOptions = getBeneficiaryOptionsFromData(
    data?.households.edges?.[0]?.node ?? null,
    [selectedBeneficiaries.beneficiaryId],
    {
      // spreading the array b/c CHARITABLE_ENTITY_TYPES is readonly and filterEntityTypes is mutable
      filterEntityTypes: [...CHARITABLE_ENTITY_TYPES, 'insurance-account'],
    }
  );

  return {
    grantorOptions: primaryClientOptions,
    beneficiaryOptions,
    possibleBeneficiaries,
  };
}

interface Props {
  grantorOptions: ReturnType<typeof useFormOptions>['grantorOptions'];
  beneficiaryOptions: ReturnType<typeof useFormOptions>['beneficiaryOptions'];
  householdId: string;
  advisorClient: ClientDetailsGiftingPage_HouseholdFragment;
}

export function LogNewGiftModalForm({
  grantorOptions,
  beneficiaryOptions,
  householdId,
  advisorClient,
}: Props) {
  const { control, setValue } = useFormContext<LogNewGiftForm>();
  const client = useApolloClient();

  const documentIds = useWatch({
    name: `${NAMESPACE}.documentIds` as const satisfies LogNewGiftFormPaths,
    control,
  });

  const selectedGrantor = useWatch({
    name: `${NAMESPACE}.grantor` as const satisfies LogNewGiftFormPaths,
    control,
  });

  const isForcedBeneficiary = useWatch({
    control,
    name: `${NAMESPACE}.isForcedBeneficiary` as const satisfies LogNewGiftFormPaths,
  });

  const {
    annualGiftTaxExclusionAmount,
    lifetimeExemptionAmount,
    gstExemptionAmount,
  } = useIRSConstants();

  const { grantorIdToExemptionsMap } = useGiftingExemptionsForClient({
    household: advisorClient,
  });

  const availableLifetimeExemptionForSelectedGrantor: Decimal | null =
    grantorIdToExemptionsMap[selectedGrantor.clientProfileId]
      ?.lifetimeExemptionAmount ?? null;
  const availableGSTExemptionForSelectedGrantor: Decimal | null =
    grantorIdToExemptionsMap[selectedGrantor.clientProfileId]
      ?.gstExemptionAmount ?? null;

  const annualExclusionHelpText = useMemo(() => {
    return annualGiftTaxExclusionAmount
      ? `${getYear(new Date())} limit of ${formatCurrencyNoDecimals(
          annualGiftTaxExclusionAmount
        )}/recipient`
      : '';
  }, [annualGiftTaxExclusionAmount]);

  const lifetimeExemptionHelpText = useMemo(() => {
    if (availableLifetimeExemptionForSelectedGrantor) {
      return `${formatCurrencyNoDecimals(
        availableLifetimeExemptionForSelectedGrantor
      )} available`;
    }

    return lifetimeExemptionAmount
      ? `${formatCurrencyNoDecimals(lifetimeExemptionAmount)} available`
      : '';
  }, [availableLifetimeExemptionForSelectedGrantor, lifetimeExemptionAmount]);

  const gstExemptionHelpText = useMemo(() => {
    if (availableGSTExemptionForSelectedGrantor) {
      return `${formatCurrencyNoDecimals(
        availableGSTExemptionForSelectedGrantor
      )} available`;
    }

    return gstExemptionAmount
      ? `${formatCurrencyNoDecimals(gstExemptionAmount)} available`
      : '';
  }, [availableGSTExemptionForSelectedGrantor, gstExemptionAmount]);

  return (
    <Stack flex={1} sx={{ minHeight: 450 }}>
      <FormLayoutRow>
        <FormLayoutItem
          sx={{
            overflow: 'hidden',
          }}
        >
          <PrimaryClientDropdownSingle<LogNewGiftForm>
            control={control}
            namespace={NAMESPACE}
            options={grantorOptions}
            labelOverride="Client"
            required
            hideGrantorContextualHelp
          />
        </FormLayoutItem>
        <FormLayoutItem
          sx={{
            overflow: 'hidden',
          }}
        >
          <BeneficiaryDropdownSingle<LogNewGiftForm>
            control={control}
            namespace={NAMESPACE}
            options={beneficiaryOptions}
            required
            label="Recipient"
            disabled={isForcedBeneficiary}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareCurrencyInput<LogNewGiftForm>
            isDecimalJSInput
            control={control}
            label="Gift amount"
            required
            fieldName={
              `${NAMESPACE}.giftAmount` as const satisfies LogNewGiftFormPaths
            }
          />
        </FormLayoutItem>
        <FormLayoutItem>
          <FormAwareDatePickerInput<LogNewGiftForm>
            control={control}
            label="Date of gift"
            required
            fieldName={
              `${NAMESPACE}.dateOfGift` as const satisfies LogNewGiftFormPaths
            }
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareCurrencyInput<LogNewGiftForm>
            isDecimalJSInput
            control={control}
            label="Discounted value of gift (optional)"
            fieldName={
              `${NAMESPACE}.discountedValueOfGift` as const satisfies LogNewGiftFormPaths
            }
          />
        </FormLayoutItem>
        <FormLayoutItem>
          <FormAwareCurrencyInput<LogNewGiftForm>
            isDecimalJSInput
            control={control}
            label="Annual exclusion applied"
            required
            helpText={annualExclusionHelpText}
            fieldName={
              `${NAMESPACE}.annualExclusionApplied` as const satisfies LogNewGiftFormPaths
            }
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareCurrencyInput<LogNewGiftForm>
            isDecimalJSInput
            control={control}
            label="Lifetime exemption used"
            required
            helpText={lifetimeExemptionHelpText}
            fieldName={
              `${NAMESPACE}.lifetimeExemptionUsed` as const satisfies LogNewGiftFormPaths
            }
          />
        </FormLayoutItem>
        <FormLayoutItem>
          <FormAwareCurrencyInput<LogNewGiftForm>
            isDecimalJSInput
            control={control}
            label="GST exemption applied"
            required
            helpText={gstExemptionHelpText}
            fieldName={
              `${NAMESPACE}.gstExemptionApplied` as const satisfies LogNewGiftFormPaths
            }
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput
            fieldName={
              `${NAMESPACE}.notes` as const satisfies LogNewGiftFormPaths
            }
            label="Add a note"
            control={control}
            multiline
            rows={2}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareRadioGroup<LogNewGiftForm>
            control={control}
            fieldName={
              `${NAMESPACE}.assetType` as const satisfies LogNewGiftFormPaths
            }
            row
            options={assetTypeOptions}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <Accordion variant="filled" title="Document upload">
        <FormLayoutRow>
          <FormLayoutItem>
            <RichDocumentUploaderWithList
              householdId={householdId}
              hideEntityPicker
              variant="card"
              listDocumentsLike={
                documentIds.length
                  ? {
                      idIn: documentIds,
                    }
                  : undefined
              }
              documentIds={documentIds.length ? documentIds : undefined}
              onDocumentUploadSuccess={async (documentId) => {
                const uniqueDocumentIds = _.uniq([...documentIds, documentId]);
                setValue(
                  `${NAMESPACE}.documentIds` as const satisfies LogNewGiftFormPaths,
                  uniqueDocumentIds
                );

                await client.refetchQueries({
                  updateCache(cache) {
                    cache.evict({ fieldName: 'documents' });
                    cache.gc();
                  },
                });
              }}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      </Accordion>
    </Stack>
  );
}
