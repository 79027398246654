import Decimal from 'decimal.js';
import { compact, difference } from 'lodash';
import { ArrayElement } from 'type-fest/source/internal';

import { InsurancePolicyDetails } from '@/modules/entities/InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform.types';
import {
  AugmentedCreateIlitTrustInput,
  AugmentedCreateInsurancePolicyInput,
  AugmentedUpdateInsurancePolicyInput,
  CreateInsurancePolicyInput,
  InsurancePolicyKind,
  InsurancePolicyPremiumFrequency,
  UpdateIlitTrustInput,
  UpdateInsurancePolicyInput,
} from '@/types/schema';

import { SubformsCombinedType } from '../../EntitySubforms.types';

function assertHasValidPolicyHolderList(
  policy: ArrayElement<
    SubformsCombinedType['insurancePolicyDetailsSubform']['policies']
  >
): void {
  if (
    policy.policyType !== InsurancePolicyKind.Term &&
    policy.isConvertible &&
    policy.policyHolderIDs.length < 2
  ) {
    throw new Error('Survivorship policies require two insured individuals.');
  }
  return;
}

function getTermDuration(
  policyType: InsurancePolicyKind,
  termLength: number | string
) {
  // an empty string is used as an empty value for this input
  if (
    typeof termLength === 'string' ||
    policyType !== InsurancePolicyKind.Term
  ) {
    return undefined;
  }

  return termLength;
}

function mapPolicyToCreate(
  policy: ArrayElement<
    SubformsCombinedType['insurancePolicyDetailsSubform']['policies']
  >
): CreateInsurancePolicyInput {
  if (!policy.policyType) {
    throw new Error('No policy type selected');
  }

  assertHasValidPolicyHolderList(policy);

  return {
    carrierName: policy.insuranceCarrier,
    conversionDate: policy.isConvertible ? policy.conversionDate : undefined,
    deathBenefitAmount: policy.deathBenefitAmount || new Decimal(0),
    documentIDs: policy.documentIds,
    initialPremiumDueDate: policy.premiumInitialDueDate || new Date(),
    kind: policy.policyType,
    notes: policy.notes,
    policyHolderIDs: compact(policy.policyHolderIDs),
    policyNumber: policy.policyNumber,
    premiumAmount: policy.premiumAmount || new Decimal(0),
    premiumFrequency:
      policy.premiumFrequency || InsurancePolicyPremiumFrequency.Annually,
    startDate: policy.policyStartDate || new Date(),
    survivorship: policy.isConvertible,
    termDurationYears: getTermDuration(policy.policyType, policy.termLength),
    cashValue: !policy.cashValue?.isZero() ? policy.cashValue : undefined,
    loanBalanceOutstanding: !policy.loanBalanceOutstanding?.isZero()
      ? policy.loanBalanceOutstanding
      : undefined,
  };
}

function mapPolicyToUpdate(
  policy: ArrayElement<
    SubformsCombinedType['insurancePolicyDetailsSubform']['policies']
  >
): UpdateInsurancePolicyInput {
  if (!policy.policyId) {
    throw new Error('Attempted to update a policy without an ID');
  }

  if (!policy.policyType) {
    throw new Error('No policy type selected');
  }

  assertHasValidPolicyHolderList(policy);

  const output: Partial<UpdateInsurancePolicyInput> = {
    deathBenefitAmount: policy.deathBenefitAmount,
    kind: policy.policyType,
    startDate: policy.policyStartDate,
    survivorship: policy.isConvertible,
  };

  if (policy.premiumFrequency) {
    output.premiumFrequency = policy.premiumFrequency;
  } else {
    output.clearPremiumFrequency = true;
  }

  if (policy.premiumInitialDueDate) {
    output.initialPremiumDueDate = policy.premiumInitialDueDate;
  } else {
    output.clearInitialPremiumDueDate = true;
  }

  if (policy.premiumAmount && !policy.premiumAmount?.isZero()) {
    output.premiumAmount = policy.premiumAmount;
  } else {
    output.clearPremiumAmount = true;
  }

  if (policy.isConvertible) {
    output.conversionDate = policy.conversionDate;
  } else {
    output.clearConversionDate = true;
  }

  const nextTermLength = getTermDuration(policy.policyType, policy.termLength);
  if (nextTermLength) {
    output.termDurationYears = nextTermLength;
  } else {
    output.clearTermDurationYears = true;
  }

  if (policy.notes.length) {
    output.notes = policy.notes;
  } else {
    output.clearNotes = true;
  }

  if (policy.policyNumber) {
    output.policyNumber = policy.policyNumber;
  } else {
    output.clearPolicyNumber = true;
  }

  if (policy.insuranceCarrier.length) {
    output.carrierName = policy.insuranceCarrier;
  } else {
    output.clearCarrierName = true;
  }

  if (policy.cashValue && !policy.cashValue?.isZero()) {
    output.cashValue = policy.cashValue;
  } else {
    output.clearCashValue = true;
  }

  if (policy.cashValueDate) {
    output.cashValueDate = policy.cashValueDate;
  } else {
    output.clearCashValueDate = true;
  }

  if (!policy.loanBalanceOutstanding?.isZero()) {
    output.loanBalanceOutstanding = policy.loanBalanceOutstanding;
  } else {
    output.clearLoanBalanceOutstanding = true;
  }

  if (policy.documentIds.length === 0) {
    output.clearDocuments = true;
  } else {
    const { documentIds, initialDocumentIDs } = policy;

    const addDocumentIDs = difference(documentIds, initialDocumentIDs);
    const removeDocumentIDs = difference(initialDocumentIDs, documentIds);

    output.addDocumentIDs = addDocumentIDs.length ? addDocumentIDs : undefined;
    output.removeDocumentIDs = removeDocumentIDs.length
      ? removeDocumentIDs
      : undefined;
  }

  const {
    policyHolderIDs: uncompactPolicyHolderIDs,
    initialPolicyHolderIDs: uncompactInitialPolicyHolderIDs,
  } = policy;
  const policyHolderIDs = compact(uncompactPolicyHolderIDs);
  const initialPolicyHolderIDs = compact(uncompactInitialPolicyHolderIDs);

  if (policyHolderIDs.length === 0) {
    output.clearPolicyHolders = true;
  } else {
    const addPolicyHolderIDs = difference(
      policyHolderIDs,
      initialPolicyHolderIDs
    );
    const removePolicyHolderIDs = difference(
      initialPolicyHolderIDs,
      policyHolderIDs
    );

    output.addPolicyHolderIDs = addPolicyHolderIDs.length
      ? addPolicyHolderIDs
      : undefined;

    output.removePolicyHolderIDs = removePolicyHolderIDs.length
      ? removePolicyHolderIDs
      : undefined;
  }

  return output;
}

function isValidPolicy(
  policy: InsurancePolicyDetails | undefined | null
): boolean {
  // death benefit amount will be null if the policy is clean
  return policy?.deathBenefitAmount !== null;
}

export function makeCreatePolicyInputs(
  input: SubformsCombinedType['insurancePolicyDetailsSubform']
): Partial<AugmentedCreateIlitTrustInput> | undefined {
  const output: CreateInsurancePolicyInput[] = (input?.policies || [])
    .filter(isValidPolicy)
    .map(mapPolicyToCreate);

  if (output.length) {
    return {
      withPolicies: output.map((policy) => ({
        create: policy,
      })),
    };
  }
  return;
}

export interface UpdateOutput {
  updatePolicies: AugmentedUpdateInsurancePolicyInput[];
  withPolicies: AugmentedCreateInsurancePolicyInput[];
  removePolicyIDs: UpdateIlitTrustInput['removePolicyIDs'];
}
export function makeUpdatePolicyInputs(
  input: SubformsCombinedType['insurancePolicyDetailsSubform'] | undefined
): UpdateOutput {
  if (!input) {
    throw new Error('No insurance policies details subform data available');
  }
  const { initialPolicyIDs = [], policies = [] } = input || {};
  const removePolicyIDs = initialPolicyIDs.filter((initialPolicyID) => {
    return !policies.find(({ policyId }) => policyId === initialPolicyID);
  });
  return policies.filter(isValidPolicy).reduce<UpdateOutput>(
    (acc, policy) => {
      if (policy.policyId) {
        acc.updatePolicies.push({
          id: policy.policyId,
          update: mapPolicyToUpdate(policy),
        });
      } else {
        acc.withPolicies.push({ create: mapPolicyToCreate(policy) });
      }
      return acc;
    },
    { updatePolicies: [], withPolicies: [], removePolicyIDs }
  );
}
