import { noop } from 'lodash';
import { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

// Context to manage the loading state associated with discovering which
// waterfall should be shown when navigating to the waterfall tab
export const WaterfallRouting = createContext<{
  isLoadingWaterfall: boolean;
  setIsLoadingWaterfall: Dispatch<SetStateAction<boolean>>;
}>({
  isLoadingWaterfall: true,
  setIsLoadingWaterfall: noop,
});

export const useWaterfallRoutingContext = () => {
  return useGuardedContext(WaterfallRouting, 'WaterfallRouting.Provider');
};
