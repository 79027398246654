import { Box, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';

import { RibbonHorizontal } from '@/components/display/Ribbon/RibbonHorizontal';
import { BankIcon } from '@/components/icons/BankIcon';
import { formatCurrency } from '@/utils/formatting/currency';

import { AssetDisplayName } from './AssetDisplayName';

export const ASSET_CARD_WIDTH_PX = '392' as const;

interface Props {
  displayName: string;
  category: string;
  value: Decimal;
}

export function AssetDetailCard({ displayName, category, value }: Props) {
  const theme = useTheme();

  return (
    <Box
      key={displayName}
      display="flex"
      flexDirection="column"
      position="relative"
      width={`${ASSET_CARD_WIDTH_PX}px`}
      bgcolor={theme.palette.grey[100]}
      borderRadius={1.5}
    >
      <RibbonHorizontal
        ribbonColor={theme.palette.secondary.main}
        icon={<BankIcon size={24} />}
      />
      <Stack padding={3} paddingLeft={7}>
        <AssetDisplayName displayName={displayName} />
        <Typography variant="subtitle2">{category}</Typography>
        <Typography mb={theme.spacing(1)} variant="body2">
          {formatCurrency(value ?? new Decimal(0))}
        </Typography>
      </Stack>
    </Box>
  );
}
