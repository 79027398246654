import { compact, flatMap, isEmpty } from 'lodash';

import { TypeaheadSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { getNodes } from '@/utils/graphqlUtils';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { useDispositiveProvisionsContext } from '../../contexts/dispositiveProvisions.context';
import { DispositionScheme } from '../../dispositiveProvisions.types';
import { mapProvisionToRecipient } from '../../dispositiveProvisions.utils';
import {
  DispositiveProvisions_DispositionScenarioFragment,
  DispositiveProvisions_DispositiveProvisionFragment,
} from '../../graphql/DispositiveProvisions.fragments.generated';
import { Recipient } from '../DispositiveProvisionsForm.types';
import { useOrderedDyingClients } from '../hooks/utilityHooks';
import { CopyDispositionsResult } from './CopyDispositiveProvisionsModal.types';
import {
  CopyDispositiveProvisionsOptions_HouseholdFragment,
  CopyDispositiveProvisionsOptionsQuery,
  useCopyDispositiveProvisionsOptionsQuery,
} from './graphql/CopyDispositiveProvisionsModal.generated';

/**
 * @description Delimiter used to separate the entity ID and scenario ID in the value of the select option. Only used for entities and clients profiles,
 * because testamentary entities don't have scenarios, they just go straight to provisions.
 */
const SCENARIO_ID_DELIMITER = '||';

/**
 * @description Returns a list of filtered, contextually-aware options to copy dispositions from, including other entities, testamentary entities,
 * and client profiles.
 */
export function useCopyDispositionOptions() {
  const { clientProfileOrEntityOrTestamentaryEntityId, householdId } =
    useDispositiveProvisionsContext();
  const orderedDyingClients = useOrderedDyingClients();

  const { data, ...queryProps } = useCopyDispositiveProvisionsOptionsQuery({
    variables: {
      householdId: householdId,
      entitiesLike: {
        hasHouseholdWith: [{ id: householdId }],
      },
      // testamentary entities don't have a link directly to a household
      testamentaryEntitiesLike: {
        hasHouseholdWith: [{ id: householdId }],
        hasDispositionScenarios: true,
      },
    },
  });

  const options = getOptionsFromQueryData(
    data,
    orderedDyingClients,
    clientProfileOrEntityOrTestamentaryEntityId
  );

  return { options, data, ...queryProps };
}

function getOptionsFromQueryData(
  data: CopyDispositiveProvisionsOptionsQuery | undefined,
  deathOrderPrimaryClients: ReturnType<typeof useOrderedDyingClients>,
  clientProfileOrEntityOrTestamentaryEntityId: string
): TypeaheadSelectInputOption<string>[] {
  if (!data) return [];

  const clientsNamesById = getClientNamesById(deathOrderPrimaryClients);

  const templateOptions: TypeaheadSelectInputOption<string>[] = flatMap(
    getNodes(data.dispositiveProvisionTemplates),
    (template) => {
      return {
        groupName: 'Templates',
        display: template.displayName,
        value: template.id,
      };
    }
  );

  const entityOptions: TypeaheadSelectInputOption<string>[] = flatMap(
    getNodes(data.entities),
    (entity) => {
      return flatMap(entity.subtype.dispositionScenarios, (scenario) => {
        const firstDeathClientName =
          clientsNamesById[scenario.firstGrantorDeath.id] ?? 'Unknown';
        const { provisions: provisionsToValidate } =
          getRelevantProvisionsFromScenario(scenario);
        if (isEmpty(provisionsToValidate)) return [];
        if (
          provisionsDistributeToCurrentEntity(
            provisionsToValidate,
            clientProfileOrEntityOrTestamentaryEntityId
          )
        ) {
          return [];
        }

        // don't include the current entity in the current death order in the list of entities to copy from
        if (
          entity.id === clientProfileOrEntityOrTestamentaryEntityId &&
          scenario.firstGrantorDeath.id === deathOrderPrimaryClients[0].id
        ) {
          return [];
        }

        return {
          groupName: 'Entities',
          display: compact([
            entity.subtype.displayName,
            firstDeathClientName && `(${firstDeathClientName} dies first)`,
          ]).join(' '),
          value: [entity.id, scenario.id].join(SCENARIO_ID_DELIMITER),
        };
      });
    }
  );

  const testamentaryEntityOptions: TypeaheadSelectInputOption<string>[] =
    flatMap(getNodes(data.testamentaryEntities), (entity) => {
      if (entity.id === clientProfileOrEntityOrTestamentaryEntityId) return [];

      return flatMap(entity.dispositionScenarios, (scenario) => {
        const firstDeathClientName =
          clientsNamesById[scenario.firstGrantorDeath.id] ?? 'Unknown';
        const { provisions: provisionsToValidate } =
          getRelevantProvisionsFromScenario(scenario);
        if (isEmpty(provisionsToValidate)) return [];
        if (
          provisionsDistributeToCurrentEntity(
            provisionsToValidate,
            clientProfileOrEntityOrTestamentaryEntityId
          )
        ) {
          return [];
        }

        return {
          groupName: 'Testamentary entities',
          display: compact([
            entity.displayName,
            firstDeathClientName && `(${firstDeathClientName} dies first)`,
          ]).join(' '),
          value: [entity.id, scenario.id].join(SCENARIO_ID_DELIMITER),
        };
      });
    });

  const clientProfileOptions: TypeaheadSelectInputOption<string>[] = flatMap(
    (data.household as CopyDispositiveProvisionsOptions_HouseholdFragment)
      .possiblePrimaryClients,
    (client) => {
      if (client.id === clientProfileOrEntityOrTestamentaryEntityId) return [];

      return flatMap(client.dispositionScenarios, (scenario) => {
        const firstDeathClientName =
          clientsNamesById[scenario.firstGrantorDeath.id] ?? 'Unknown';

        const { provisions: provisionsToValidate } =
          getRelevantProvisionsFromScenario(scenario);
        if (isEmpty(provisionsToValidate)) return [];
        if (
          provisionsDistributeToCurrentEntity(
            provisionsToValidate,
            clientProfileOrEntityOrTestamentaryEntityId
          )
        ) {
          return [];
        }

        return {
          groupName: 'Clients',
          display: compact([
            client.displayName,
            firstDeathClientName && `(${firstDeathClientName} dies first)`,
          ]).join(' '),
          value: [client.id, scenario.id].join(SCENARIO_ID_DELIMITER),
        };
      });
    }
  );

  return [
    ...templateOptions,
    ...entityOptions,
    ...testamentaryEntityOptions,
    ...clientProfileOptions,
  ];
}

function getRelevantProvisionsFromScenario(
  scenario: DispositiveProvisions_DispositionScenarioFragment
): {
  provisions: DispositiveProvisions_DispositiveProvisionFragment[];
  scheme: DispositionScheme;
} {
  if (scenario.dispositiveProvisions.totalCount > 0) {
    return {
      provisions: getNodes(scenario.dispositiveProvisions),
      scheme: DispositionScheme.UPON_FIRST_DEATH,
    };
  }

  if (scenario.secondDeathDispositiveProvisions.totalCount > 0) {
    return {
      provisions: getNodes(scenario.secondDeathDispositiveProvisions),
      scheme: DispositionScheme.UPON_SECOND_DEATH,
    };
  }

  return {
    provisions: [],
    scheme: DispositionScheme.NONE,
  };
}

//
/**
 * @description Returns true if any of the provisions in the connection distribute to the current entity or testamentary entity.
 * If the copied-from entity references the current entity, we don’t want show that in the list of entities available to copy from.
 */
function provisionsDistributeToCurrentEntity(
  provisions: DispositiveProvisions_DispositiveProvisionFragment[],
  clientProfileOrEntityOrTestamentaryEntityId: string
) {
  return provisions.some((provision) => {
    const distributesToId =
      provision.entity?.id ?? provision.testamentaryEntity?.id;
    return distributesToId === clientProfileOrEntityOrTestamentaryEntityId;
  });
}

function getClientNamesById(
  deathOrderPrimaryClients: ReturnType<typeof useOrderedDyingClients>
) {
  return deathOrderPrimaryClients.reduce(
    (acc, client) => {
      if (!client) return acc;
      acc[client.id] = client.displayName;
      return acc;
    },
    {} as Record<string, string>
  );
}

// we want to make sure to *not* reference the original recipients via _dispositiveProvisionId;
// we want these "copies" to be totally decoupled in a data sense
function mapProvisionToNewRecipient(
  provision: DispositiveProvisions_DispositiveProvisionFragment
): Recipient {
  return {
    ...mapProvisionToRecipient(provision),
    _dispositiveProvisionId: null,
  };
}

/**
 * @description Given the query result and the ID of the entity/test entity/client to copy dispositions from, returns the
 * list of recipients and a default dispositions scheme to be applied to the target of the copy action.
 */
export function getCopyDispositionsResult(
  clientProfileOrEntityOrTestamentaryEntityIdWithScenario: string,
  data: CopyDispositiveProvisionsOptionsQuery
): CopyDispositionsResult | null {
  const [clientProfileOrEntityOrTestamentaryEntityId, scenarioId] =
    clientProfileOrEntityOrTestamentaryEntityIdWithScenario.split(
      SCENARIO_ID_DELIMITER
    );
  if (!clientProfileOrEntityOrTestamentaryEntityId) return null;

  const pulidKind = getPulidKind(clientProfileOrEntityOrTestamentaryEntityId);

  switch (pulidKind) {
    case PulidKind.Entity: {
      const entity = getNodes(data.entities).find(
        (entity) => entity.id === clientProfileOrEntityOrTestamentaryEntityId
      );
      if (!entity) return null;

      const scenario =
        entity.subtype?.dispositionScenarios?.find(
          (scenario) => scenario.id === scenarioId
        ) ?? null;
      if (!scenario) return null;

      const { provisions, scheme } =
        getRelevantProvisionsFromScenario(scenario);
      if (scheme === DispositionScheme.NONE) return null;

      return {
        recipients: provisions.map(mapProvisionToNewRecipient),
      };
    }

    case PulidKind.TestamentaryEntity: {
      const entity = getNodes(data.testamentaryEntities).find(
        (entity) => entity.id === clientProfileOrEntityOrTestamentaryEntityId
      );
      if (!entity) return null;

      const scenario =
        entity.dispositionScenarios?.find(
          (scenario) => scenario.id === scenarioId
        ) ?? null;
      if (!scenario) return null;

      const { provisions, scheme } =
        getRelevantProvisionsFromScenario(scenario);
      if (scheme === DispositionScheme.NONE) return null;

      return {
        recipients: provisions.map(mapProvisionToNewRecipient),
      };
    }

    case PulidKind.ClientProfile: {
      const clientProfile = (
        data.household as CopyDispositiveProvisionsOptions_HouseholdFragment
      ).possiblePrimaryClients.find(
        (c) => c.id === clientProfileOrEntityOrTestamentaryEntityId
      );
      if (!clientProfile) return null;

      const scenario =
        clientProfile.dispositionScenarios?.find(
          (scenario) => scenario.id === scenarioId
        ) ?? null;
      if (!scenario) return null;

      const { provisions, scheme } =
        getRelevantProvisionsFromScenario(scenario);
      if (scheme === DispositionScheme.NONE) return null;

      return {
        recipients: provisions.map(mapProvisionToNewRecipient),
      };
    }
    case PulidKind.DispositiveProvisionTemplate: {
      const template = getNodes(data.dispositiveProvisionTemplates).find(
        (template) =>
          template.id === clientProfileOrEntityOrTestamentaryEntityId
      );

      if (!template) return null;

      const provisions = getNodes(template.dispositiveProvisions);
      return {
        templateId: template.id,
        recipients: provisions.map(mapProvisionToNewRecipient),
      };
    }
    default:
      return null;
  }
}
