import { noop } from 'lodash';
import React, { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import {
  AISuggestionMatch,
  AISuggestionsMatcherVariant,
  AISuggestionsMatcherVariantConfig,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';

export interface AiSuggestionsMatcherContext {
  // The variant of the AI suggestions matcher. E.g. 'grantors', 'trustees', etc.
  matcherVariant: AISuggestionsMatcherVariant | null;
  setMatcherVariant: Dispatch<
    SetStateAction<AiSuggestionsMatcherContext['matcherVariant']>
  >;

  // Matched suggestions are the "uncommitted" suggestions that the user has
  // matched for the current entity section. These are committed when the user
  // clicks the "Update form with identified suggestions" button, or can be
  // automatically "committed" when the user saves the entity. Committed
  // suggestions are shown in the form.
  matchedSuggestions: Record<string, AISuggestionMatch>;
  setMatchedSuggestions: Dispatch<
    SetStateAction<AiSuggestionsMatcherContext['matchedSuggestions']>
  >;

  // Commits the matched suggestions to the form, and acknowledges each
  // suggestion as accepted or rejected. This should be called when the user
  // clicks the "Update form with identified suggestions" button, or can be
  // automatically "committed" when the user saves the entity. This will clear
  // the matched suggestions.
  commitMatchedSuggestions: () => void;

  // The ref to the AISuggestionsMatcher component.
  matcherRef: React.RefObject<HTMLDivElement>;

  // Various configs for the matcher component.
  matcherConfigs: AISuggestionsMatcherVariantConfig;
}

export const getDefaultValues = (): AiSuggestionsMatcherContext => ({
  matcherVariant: null,
  setMatcherVariant: noop,
  matchedSuggestions: {},
  setMatchedSuggestions: noop,
  commitMatchedSuggestions: noop,
  matcherRef: { current: null },
  matcherConfigs: getDefaultMatcherConfigs(),
});

export const getDefaultMatcherConfigs =
  (): AISuggestionsMatcherVariantConfig => ({
    nounSingular: '',
    nounPlural: '',
    suggestionKinds: [],
    addNewAnythingModalProps: {},
    subformArrayFieldUpdateProps: [],
    subformArrayFieldOnConflict: 'overwrite',
  });

export const AiSuggestionsMatcherContext =
  createContext<AiSuggestionsMatcherContext>(getDefaultValues());

export const useAiSuggestionsMatcherContext = () => {
  return useGuardedContext(
    AiSuggestionsMatcherContext,
    'AiSuggestionsMatcherContextProvider'
  );
};
