import { Box, Stack } from '@mui/material';

import { RichListItem, RichListItemProps } from './RichListItem';

export interface RichListItemRowProps extends RichListItemProps {
  RightContent?: JSX.Element;
}

/**
 * @description RichListItemRow is a component that extends RichListItem horizontally.
 */
export function RichListItemRow({
  RightContent,
  ...richListItemProps
}: RichListItemRowProps) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
      width="100%"
      minHeight={40}
    >
      <RichListItem {...richListItemProps} />
      <Box>{RightContent && RightContent}</Box>
    </Stack>
  );
}
