import { Box, Skeleton, Stack } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { isUndefined } from 'lodash';
import * as React from 'react';
import { ComponentProps, useMemo } from 'react';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { COLORS } from '@/styles/tokens/colors';

import { FormControl } from '../FormControl';
import {
  BaseSelectInputProps,
  FormControlSelectInputProps,
  HelpTextVariant,
} from '../inputTypes';

export const SELECT_END_ADORNMENT_SIZE = 16;

export interface SelectInputProps<V = unknown>
  extends FormControlSelectInputProps<V> {
  label: string;
  id?: string;
  // the presence of contextualHelp indicates that there's help text related to this input, which
  // will cause the "info" icon to show up next to the input label
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  hideLabel?: boolean;
  // Optional boolean that controls the open state of the select input
  // https://mui.com/material-ui/api/select/#Select-prop-open
  open?: ComponentProps<typeof Select>['open'];
  labelIconEnd?: React.ReactNode;
  emptyValueDisplay?: string;
  showEmptyValue?: boolean;
}

// HTMLAttributes<HTMLDivElement> doesn't include `data-*` attributes
interface SelectDisplayPropsType extends React.HTMLAttributes<HTMLDivElement> {
  'data-testid'?: string;
}

const BaseSelectInput = ({
  options,
  testId,
  required,
  emptyValueDisplay,
  showEmptyValue = true,
  labelId,
  loading,
  ...inputProps
}: BaseSelectInputProps<string>) => {
  const { disabled, startAdornment, endAdornment, sx, ...inputPropsRest } =
    inputProps;
  const disabledStyle = disabled ? { background: COLORS.GRAY[50] } : {};
  // Hide the selected option's icon if the parent Select has a startAdornment
  const selectedOptionIconStyle = startAdornment
    ? {
        '& .MuiListItemIcon-root': {
          display: 'none',
        },
      }
    : {};
  // Hide the selected option's icon if it is the current value in the Select
  const selectedOptionEndIconStyle = {
    '& .MuiSelect-select .MuiStack-root .end-adornment': {
      display: 'none',
    },
  };
  const mergedSx = Object.assign(
    { background: 'white' },
    disabledStyle,
    selectedOptionIconStyle,
    selectedOptionEndIconStyle,
    sx
  );

  const disallowEmptySelection = required;

  const emptyValueOption = useMemo(
    () =>
      showEmptyValue && (
        <MenuItem
          value=""
          disabled={disallowEmptySelection}
          sx={(theme) => ({
            height: theme.spacing(5),
          })}
        >
          {emptyValueDisplay || EMPTY_CONTENT_HYPHEN}
        </MenuItem>
      ),
    [disallowEmptySelection, emptyValueDisplay, showEmptyValue]
  );

  return (
    // this select is a non-optimal experience for mobile; consider using NativeSelect here for a mobile device
    <Select
      disabled={disabled}
      displayEmpty
      sx={mergedSx}
      inputProps={{
        sx: mergedSx,
      }}
      {...inputPropsRest}
      required={required}
      startAdornment={startAdornment}
      endAdornment={endAdornment}
      MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
      SelectDisplayProps={
        {
          'data-testid': testId ?? `select-${inputProps.name ?? ''}`,
        } as SelectDisplayPropsType
      }
      labelId={labelId}
    >
      {emptyValueOption}
      {options.map((option, i) => {
        // don't wrap the component if it has a value -- that needs to be persisted so the
        // item can be selected
        if (option.type === 'component' && !isUndefined(option.value)) {
          return option.component;
        } else if (option.type === 'component') {
          const Component = () => option.component;
          return <Component key={i} />;
        } else {
          return (
            <MenuItem
              disabled={option.disabled}
              divider={option.withDividerBelow}
              key={i}
              value={option.value}
              sx={(theme) => ({
                height: theme.spacing(5),
              })}
              data-testid={`select-option-${option.value}`}
            >
              {option.startAdornment || option.endAdornment ? (
                // When displaying the option with icon and text as the selected option,
                // they need to be wrapped in a Stack to ensure horizontal alignment.
                // Within that, the text overflow needs to be handled with ellipses.
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent={
                    option.endAdornment ? 'space-between' : undefined
                  }
                  width={option.endAdornment ? '100%' : undefined}
                >
                  <Stack direction="row" alignItems="center">
                    {option.startAdornment}
                    <Box
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                    >
                      {option.display}
                    </Box>
                  </Stack>
                  {option.endAdornment}
                </Stack>
              ) : (
                // When just displaying the text, the MUI Select Input ellipsizes
                // the overflow text of the selected option.
                option.display
              )}
            </MenuItem>
          );
        }
      })}
      {loading && (
        <>
          {Array.from({ length: 3 }).map((_, idx) => (
            <MenuItem key={idx} sx={{ py: 0 }}>
              <Skeleton height={40} width="100%" />
            </MenuItem>
          ))}
        </>
      )}
    </Select>
  );
};

export function SelectInput<V = unknown>({
  id,
  label,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant,
  hideLabel,
  labelIconEnd,
  ...inputProps
}: SelectInputProps<V>) {
  return (
    <FormControl<BaseSelectInputProps<V>>
      hideLabel={hideLabel}
      id={id}
      contextualHelp={contextualHelp}
      component={BaseSelectInput}
      componentKind="select"
      label={label}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
      required={inputProps.required}
      errorMessage={errorMessage}
      inputProps={inputProps}
      labelIconEnd={labelIconEnd}
    />
  );
}
