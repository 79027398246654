import { PopperContent } from '@/components/poppers/PopperContent';

import { ContextualHelpTrigger } from '../components/ContextualHelpTrigger';

export function GRATRemainder() {
  return (
    <PopperContent
      body={
        'Select “zeroed-out” if you would like to set grantor retained interest equal to the initial funding amount, or select “taxable gift” if you would like to specify a taxable gift amount.'
      }
      actions={
        <ContextualHelpTrigger
          display="Learn more"
          contextualHelpId="zeroedOutGRATs"
        />
      }
    />
  );
}
