import { useCallback, useRef } from 'react';

/**
 * @description Creates a "getter" for getting state variables when you want
 * to access them in a non reactive way.
 */
export function useGetState<T>(state: T): () => T {
  const stateRef = useRef(state);
  stateRef.current = state;
  return useCallback(() => stateRef.current, []);
}
