import { useMemo } from 'react';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { PaginatedTableProps } from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';

import { ActivityGroupFragment } from '../graphql/ActivityGroups.generated';
import { columns } from './columns';
import { RowData } from './types';
import { mapDataToRows } from './utils';

const getTreeDataPath = (row: RowData) => row.path;

export interface ActivityTableProps extends PaginatedTableProps {
  data: ActivityGroupFragment[];
}

export function ActivityTable({
  data,
  ...paginatedTableProps
}: ActivityTableProps) {
  const rows = useMemo<RowData[]>(() => mapDataToRows(data), [data]);

  return (
    <DataTable
      {...paginatedTableProps}
      treeData
      columns={columns}
      rows={rows}
      getTreeDataPath={getTreeDataPath}
    />
  );
}
