import { Box, darken, Typography } from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

interface RibbonHorizontalProps {
  ribbonColor: string;
  ribbonText?: string;
  icon?: JSX.Element;
  moveLeftBy?: number;
  paddingTop?: number;
  // When set to 'relative', the ribbon will take up
  // space in the layout and push other elements down
  // in the container.
  ribbonPosition?: 'relative' | 'absolute';
}

export function RibbonHorizontal({
  ribbonColor,
  ribbonText,
  icon,
  // Default to 1 because we always want the ribbon to stick out a little bit
  moveLeftBy = 1,
  // Default to 3 because we usually want the ribbon to have some space above it
  paddingTop = 3,
  ribbonPosition = 'absolute',
}: RibbonHorizontalProps) {
  return (
    <Box position={ribbonPosition} pt={paddingTop}>
      <Box
        sx={(theme) => ({
          position: 'relative',
          padding: theme.spacing(1),
          left: theme.spacing(moveLeftBy * -1),
          backgroundColor: ribbonColor,
          color: theme.palette.common.white,
          borderBottomRightRadius: theme.spacing(0.75),
          borderTopRightRadius: theme.spacing(0.75),
          zIndex: 2,
          width: 'fit-content',
        })}
      >
        {icon && icon}
        {ribbonText && (
          <Typography color={COLORS.PRIMITIVES.WHITE} variant="h2">
            {ribbonText}
          </Typography>
        )}
      </Box>
      <Box
        sx={(theme) => ({
          position: 'absolute',
          // This moves the triangle left by the same amount as the ribbon
          // plus one to account for the padding and plus a tiny bit to account
          // for a border
          left: theme.spacing((moveLeftBy + 1) * -1 - 0.125),
          // This places the triangle at the bottom of the ribbon
          // and moves it up by 4px, or half the height of the triangle
          top: 'calc(100% - 4px)',
          border: '9px solid transparent',
          borderTop: `${theme.spacing(0.5)} solid transparent`,
          borderBottom: `${theme.spacing(0.5)} solid transparent`,
          borderRight: `7px solid ${darken(ribbonColor, 0.2)}`,
        })}
      ></Box>
    </Box>
  );
}
