import { Box, Stack, SxProps } from '@mui/material';
import { ComponentProps } from 'react';

import { ChevronLeftIcon } from '@/components/icons/ChevronLeftIcon';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { COLORS } from '@/styles/tokens/colors';

import { DEFAULT_SIDEBAR_WIDTH } from './SidebarContainer';

export interface SidebarToggleVisibilityHandleProps {
  onClick: () => void;
  sidebarCollapsed?: boolean;
  visible?: boolean;
  expandedSidebarWidth: ComponentProps<typeof Stack>['width'];
}

export const VISIBILTY_HANDLE_WIDTH_EXPANDED = '24px' as const;
export const VISIBILTY_HANDLE_WIDTH_COLLAPSED = '32px' as const;

export function SidebarToggleVisibilityHandle({
  onClick,
  sidebarCollapsed = false,
  visible = false,
}: SidebarToggleVisibilityHandleProps) {
  if (!visible) {
    return null;
  }

  let stackSx: SxProps = {
    backgroundColor: sidebarCollapsed ? COLORS.ORANGE[400] : COLORS.GRAY[400],
    height: 120,
    width: sidebarCollapsed ? 32 : 24,
    '&:hover': {
      backgroundColor: sidebarCollapsed ? COLORS.ORANGE[700] : COLORS.GRAY[300],
      cursor: 'pointer',
    },
  };

  if (sidebarCollapsed) {
    stackSx = {
      ...stackSx,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    };
  } else {
    stackSx = {
      ...stackSx,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
    };
  }

  const body = (
    <Box
      sx={{
        // if the sidebar is expanded, fix the handle to the sidebar, which responds to overscroll effects on touch/trackpad inputs
        // if the sidebar is collapsed, fix the handle to the window border, which _doesn't_ respond to overscroll effects
        position: sidebarCollapsed ? 'fixed' : 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
    >
      <Stack
        role="button"
        sx={stackSx}
        onClick={onClick}
        alignItems="center"
        justifyContent="center"
      >
        {sidebarCollapsed ? (
          <ChevronLeftIcon sx={{ color: COLORS.PRIMITIVES.WHITE }} />
        ) : (
          <ChevronRightIcon sx={{ color: COLORS.PRIMITIVES.WHITE }} />
        )}
      </Stack>
    </Box>
  );

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: sidebarCollapsed
          ? 0
          : `calc(${DEFAULT_SIDEBAR_WIDTH} - ${VISIBILTY_HANDLE_WIDTH_EXPANDED})`,
        width: sidebarCollapsed
          ? VISIBILTY_HANDLE_WIDTH_COLLAPSED
          : VISIBILTY_HANDLE_WIDTH_EXPANDED,
      }}
    >
      {body}
    </Box>
  );
}
