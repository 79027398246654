import { GroupedHeaderListProps } from '@/components/lists/GroupedHeaderList/GroupedHeaderList';
import { GroupedHeaderListItem } from '@/components/lists/GroupedHeaderList/GroupedHeaderList.types';
import { EntityKind } from '@/types/schema';

import { TrustBeneficiariesList_BeneficiaryFragment } from './graphql/TrustBeneficiariesList.fragments.generated';
import { TrustBeneficiariesListV2 } from './TrustBeneficiaryListV2';

export type BeneficiaryListVariant = 'expandable' | 'flat';

export interface TrustBeneficiariesListProps
  extends Partial<
    GroupedHeaderListProps<TrustBeneficiariesList_BeneficiaryFragment>
  > {
  beneficiariesGroups: GroupedHeaderListItem<TrustBeneficiariesList_BeneficiaryFragment>[];
  // the interactive variant additional distribution context in a tooltip, which doesn't work in print.
  // the print variant prints that extra content in a card below the main row content
  variant?: BeneficiaryListVariant;
  isTestamentaryEntity?: boolean;
  onShowEditEntity?: () => void;
  entityKind?: EntityKind;
}

export function TrustBeneficiariesList(props: TrustBeneficiariesListProps) {
  return <TrustBeneficiariesListV2 {...props} />;
}
