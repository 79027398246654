import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';

import { SubtleLabelItem } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { CalloutListCard } from '@/components/layout/CalloutListCard/CalloutListCard';
import { LoadingSkeletonOrValue } from '@/components/progress/LoadingSkeletonOrValue/LoadingSkeletonOrValue';
import {
  SummaryContainer,
  SummaryWrapper,
} from '@/modules/trusts/TrustDetails/TrustDetails.utils';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { formatTaxId } from '@/utils/formatting/strings';

import { BusinessEntitySummaryData } from './BusinessEntityDetails.types';

export type BusinessEntitySummaryProps = Omit<
  BusinessEntitySummaryData,
  'detailsItems'
>;

/**
 * @description This component is responsible for rendering the properties that are shared
 * between all the different types of business entities, and so takes specific property names rather
 * than a generalized set of labels and values.
 */
export function BusinessEntitySummary({
  loading = false,
  taxStatus,
  legalName,
  entityType,
  taxId,
  effectiveDate,
  dbaName,
  formationJurisdiction,
  description,
  requiresCtaReporting,
  finCenID,
}: BusinessEntitySummaryProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Box>
        <Stack spacing={2}>
          <SubtleLabelItem
            label="Legal name"
            value={
              <LoadingSkeletonOrValue loading={loading} value={legalName} />
            }
          />
          <SubtleLabelItem
            label="Doing business as (DBA) name"
            value={<LoadingSkeletonOrValue loading={loading} value={dbaName} />}
          />
          <SubtleLabelItem
            label="Entity type"
            value={
              <LoadingSkeletonOrValue loading={loading} value={entityType} />
            }
          />
          <SubtleLabelItem
            label="Tax ID (EIN)"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={formatTaxId(taxId)}
              />
            }
          />
          <SubtleLabelItem
            label="Effective Date"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={
                  effectiveDate
                    ? formatDateToMonDDYYYY(effectiveDate)
                    : undefined
                }
              />
            }
          />
          <SubtleLabelItem
            label="Formation jurisdiction"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={formationJurisdiction}
              />
            }
          />
          <SubtleLabelItem
            label="Tax status"
            value={
              <LoadingSkeletonOrValue loading={loading} value={taxStatus} />
            }
          />
          <SubtleLabelItem
            label="Requires Corporate Transparency Act reporting"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={
                  requiresCtaReporting
                    ? 'Yes'
                    : requiresCtaReporting === false
                      ? 'No'
                      : ''
                }
              />
            }
          />
          {requiresCtaReporting && (
            <SubtleLabelItem
              label="FinCEN ID"
              value={
                <LoadingSkeletonOrValue
                  loading={loading}
                  value={requiresCtaReporting && finCenID}
                />
              }
            />
          )}
        </Stack>
        {description && (
          <Box mt={3}>
            <Typography variant="body2">{description}</Typography>
          </Box>
        )}
      </Box>
    </Stack>
  );
}

export function BusinessEntitySummaryWithWrapper(
  props: BusinessEntitySummaryData
) {
  const { loading, detailsItems, onShowEditEntity, ...rest } = props;

  useEffect(() => {
    onShowEditEntity?.();
  }, [onShowEditEntity]);

  return (
    <SummaryWrapper>
      <SummaryContainer>
        <Box flexGrow={1} mt={2}>
          <BusinessEntitySummary loading={!!loading} {...rest} />
        </Box>
        <Box>
          <CalloutListCard loading={!!loading} items={detailsItems} />
        </Box>
      </SummaryContainer>
    </SummaryWrapper>
  );
}
