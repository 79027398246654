import { Box } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';

import { TABS } from './types';

export function AddItemButton({
  setIsIndividualModalOpen,
  setIsTrustModalOpen,
  currentTab,
  addItemText,
}: {
  setIsIndividualModalOpen: (isOpen: boolean) => void;
  setIsTrustModalOpen: (isOpen: boolean) => void;
  currentTab: (typeof TABS)[number]['value'];
  addItemText: string;
}) {
  return (
    <Box component="footer" mt={3}>
      <Button
        fullWidth
        startIcon={PlusIcon}
        onClick={() => {
          if (currentTab === 'individuals') {
            setIsIndividualModalOpen(true);
          } else if (currentTab === 'trusts') {
            setIsTrustModalOpen(true);
          }
        }}
        variant="secondary"
        size="sm"
      >
        {addItemText}
      </Button>
    </Box>
  );
}
