import { noop } from 'lodash';

import { LinkButton } from '@/components/form/baseInputs/Link';
import { PopperContent } from '@/components/poppers/PopperContent';

interface Props {
  handleClick?: () => void;
}

export function ProjectedReturn({ handleClick = noop }: Props) {
  return (
    <PopperContent
      heading={'Projected return'}
      body={
        'The annual rate of return during the GRAT term or rolling period. This can also be expressed as a projected value by clicking “edit assumptions".'
      }
      actions={
        <LinkButton display="Switch input method" onClick={handleClick} />
      }
    />
  );
}
