import { DispositiveProvisions_DispositionScenarioFragment } from '@/modules/dispositiveProvisions/graphql/DispositiveProvisions.fragments.generated';

export function getProvisionsByDeathForScenario(
  scenario: DispositiveProvisions_DispositionScenarioFragment | undefined
) {
  const {
    dispositiveProvisions,
    dispositiveProvisionsTemplate,
    secondDeathDispositiveProvisions,
    secondDeathDispositiveProvisionsTemplate,
  } = scenario || {};

  const firstDeathProvisions =
    dispositiveProvisionsTemplate?.dispositiveProvisions ||
    dispositiveProvisions;

  const secondDeathProvisions =
    secondDeathDispositiveProvisionsTemplate?.dispositiveProvisions ||
    secondDeathDispositiveProvisions;

  return {
    firstDeathProvisions,
    secondDeathProvisions,
  };
}

export function getProvisionsForScenario(
  dispositionScenario: DispositiveProvisions_DispositionScenarioFragment
) {
  const { firstDeathProvisions, secondDeathProvisions } =
    getProvisionsByDeathForScenario(dispositionScenario);

  return firstDeathProvisions || secondDeathProvisions;
}
