export function isProduction() {
  return (
    process.env.NODE_ENV === 'production' &&
    window.location.hostname !== 'lum.withluminary.com'
  );
}

export function isLocal() {
  return (
    process.env.NODE_ENV === 'development' &&
    window.location.hostname.includes('localhost')
  );
}
