// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { SubstitutionThresholdDetailsV2FragmentDoc } from '../../../../../modules/entities/gratTrusts/SubstitutionThresholdSubform/graphql/SubstitutionThresholdSubform.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetEntityThresholdsMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateEntityInput;
  taskID: Types.Scalars['ID']['input'];
}>;


export type SetEntityThresholdsMutation = { __typename?: 'Mutation', updateEntity: { __typename?: 'Entity', id: string, gratTrust?: { __typename?: 'GRATTrust', id: string, upsideThresholdPercent?: Percent | null, upsideThresholdValue?: CurrencyUSD | null, downsideThresholdPercent?: Percent | null, upsideAsTargetSurplus: boolean } | null }, completeEntityTask: { __typename?: 'EntityTask', id: string } };


export const SetEntityThresholdsDocument = gql`
    mutation SetEntityThresholds($input: AugmentedUpdateEntityInput!, $taskID: ID!) {
  updateEntity(input: $input) {
    ...SubstitutionThresholdDetailsV2
  }
  completeEntityTask(id: $taskID) {
    id
  }
}
    ${SubstitutionThresholdDetailsV2FragmentDoc}`;
export type SetEntityThresholdsMutationFn = Apollo.MutationFunction<SetEntityThresholdsMutation, SetEntityThresholdsMutationVariables>;

/**
 * __useSetEntityThresholdsMutation__
 *
 * To run a mutation, you first call `useSetEntityThresholdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEntityThresholdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEntityThresholdsMutation, { data, loading, error }] = useSetEntityThresholdsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskID: // value for 'taskID'
 *   },
 * });
 */
export function useSetEntityThresholdsMutation(baseOptions?: Apollo.MutationHookOptions<SetEntityThresholdsMutation, SetEntityThresholdsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEntityThresholdsMutation, SetEntityThresholdsMutationVariables>(SetEntityThresholdsDocument, options);
      }
export type SetEntityThresholdsMutationHookResult = ReturnType<typeof useSetEntityThresholdsMutation>;
export type SetEntityThresholdsMutationResult = Apollo.MutationResult<SetEntityThresholdsMutation>;
export type SetEntityThresholdsMutationOptions = Apollo.BaseMutationOptions<SetEntityThresholdsMutation, SetEntityThresholdsMutationVariables>;
export const Operations = {
  Mutation: {
    SetEntityThresholds: 'SetEntityThresholds' as const
  }
}