import { Stack } from '@mui/material';

import { BenefitsMetricsItem } from '../BenefitsMetricsItem';
import { BenefitsTooltip } from '../BenefitsTooltip';
import { useBenefitsOverviewData } from './hooks/useBenefitsOverviewData';

export function BenefitsOverviewMetrics() {
  const { firstGrantor, secondGrantor, metrics } = useBenefitsOverviewData();
  const {
    allAccessValueForSecondGrantor,
    allAccessValueForIndividuals,
    allAccessValueForOrganizations,
  } = metrics;

  const hasTwoGrantors = !!secondGrantor && !!firstGrantor;

  return (
    <Stack direction="row" spacing={5}>
      {secondGrantor && (
        <BenefitsMetricsItem
          title={`To ${secondGrantor.firstName} after ${firstGrantor?.firstName}’s death`}
          tooltip={
            <BenefitsTooltip
              content={`The total amount available to ${secondGrantor.firstName} now and after ${firstGrantor?.firstName}’s death.`}
            />
          }
          value={allAccessValueForSecondGrantor}
        />
      )}
      <BenefitsMetricsItem
        title={`To family ${hasTwoGrantors ? 'after both deaths' : `after ${firstGrantor?.firstName}’s death`}`}
        tooltip={
          <BenefitsTooltip
            content={`The total amount available to family now and after ${hasTwoGrantors ? `both ${firstGrantor?.firstName} and ${secondGrantor?.firstName}’s deaths` : `${firstGrantor?.firstName}’s death`}.`}
          />
        }
        value={allAccessValueForIndividuals}
      />
      <BenefitsMetricsItem
        title={`To charity ${hasTwoGrantors ? 'after both deaths' : `after ${firstGrantor?.firstName}’s death`}`}
        tooltip={
          <BenefitsTooltip
            content={`The total amount available to charitable beneficiaries now and after ${hasTwoGrantors ? `both ${firstGrantor?.firstName} and ${secondGrantor?.firstName}’s deaths` : `${firstGrantor?.firstName}’s death`}.`}
          />
        }
        value={allAccessValueForOrganizations}
      />
    </Stack>
  );
}
