import { compact, isEmpty } from 'lodash';
import { useCallback } from 'react';
import { FieldValues, useWatch } from 'react-hook-form';

import {
  ExtendedTypeaheadQueryOpts,
  useAsyncTypeaheadProps,
} from '@/components/form/baseInputs/BackendTypeaheadSelectInput/hooks/useAsyncTypeaheadProps';
import {
  FormAwareTypeaheadMultiSelectInput,
  FormAwareTypeaheadMultiSelectInputProps,
} from '@/components/form/formAwareInputs/FormAwareTypeaheadMultiSelectInput';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { IntegrationClientKind } from '@/types/schema';

import {
  ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES,
  AssetIntegrationProviders,
} from '../constants';
import {
  GetIntegrationClientsMultipleQuery,
  GetIntegrationClientsMultipleQueryVariables,
  useGetIntegrationClientsMultipleQuery,
} from './graphql/IntegrationClientTypeahead.generated';
import {
  InitialSyncStatus,
  usePollForInitialAssetIntegrationSync,
} from './hooks/usePollForInitialAssetIntegrationSync';
import { INTEGRATION_CLIENT_EXTERNAL_KIND_DISPLAY } from './IntegrationClientTypeahead.constants';

export type IntegrationClientsTypeaheadProps<FormShape extends FieldValues> =
  Omit<
    FormAwareTypeaheadMultiSelectInputProps<FormShape>,
    'options' | 'inputValue' | 'onInputChange'
  > & {
    provider: AssetIntegrationProviders;
    notLoggedIn?: boolean;
  };

const EMPTY_OPTION = {
  value: '',
  groupName: '',
  display: EMPTY_CONTENT_HYPHEN,
};
interface GetHelpTextForStatusOptions {
  status: InitialSyncStatus;
  providerName: string;
  notLoggedIn?: boolean;
}

function getHelpTextForStatus({
  status,
  providerName,
  notLoggedIn,
}: GetHelpTextForStatusOptions) {
  if (notLoggedIn) {
    return `You have not linked your ${providerName} account. Go to manage profile under user settings to link.`;
  }

  switch (status) {
    case InitialSyncStatus.PENDING:
      return `Syncing clients and entities from ${providerName}. This may take a few minutes, depending on how many clients and entities you have in your provider.`;
    case InitialSyncStatus.COMPLETE:
      return `Successfully synced clients and entities from ${providerName}`;
    default:
      return undefined;
  }
}

export function IntegrationClientsTypeahead<FormShape extends FieldValues>({
  provider,
  notLoggedIn,
  ...typeaheadProps
}: IntegrationClientsTypeaheadProps<FormShape>) {
  const { initialSyncStatus } = usePollForInitialAssetIntegrationSync();

  const providerName = ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES[provider];
  const { createErrorFeedback } = useFeedback();
  const { control } = useFormContext<FormShape>();
  const selectedIntegrationClientIds = useWatch({
    control,
    name: typeaheadProps.fieldName,
  });

  const getKindFromProvider = (
    provider: AssetIntegrationProviders
  ): IntegrationClientKind => {
    switch (provider) {
      case AssetIntegrationProviders.ADDEPAR:
        return IntegrationClientKind.Addepar;
      case AssetIntegrationProviders.ORION:
        return IntegrationClientKind.Orion;
      case AssetIntegrationProviders.BLACK_DIAMOND:
        return IntegrationClientKind.BlackDiamond;
      default:
        throw new Error(`Unsupported provider: ${provider}`);
    }
  };

  const searchTermToVariables = useCallback(
    (searchTerm: string) => {
      return {
        optionsLike: {
          nameContainsFold: searchTerm,
          kind: getKindFromProvider(provider),
        },
      };
    },
    [provider]
  );

  const getSelectedOptionVariables = useCallback(() => {
    const values = selectedIntegrationClientIds as string[];
    return {
      selectedOptionsLike: {
        idIn: isEmpty(values) ? [''] : compact(values),
      },
    };
  }, [selectedIntegrationClientIds]);

  const toOptions: ExtendedTypeaheadQueryOpts<
    string,
    GetIntegrationClientsMultipleQuery,
    GetIntegrationClientsMultipleQueryVariables
  >['toOptions'] = useCallback((data) => {
    return data.map((integrationClient) => ({
      value: integrationClient.id,
      display: integrationClient.name,
      badgeText: integrationClient.externalKind
        ? INTEGRATION_CLIENT_EXTERNAL_KIND_DISPLAY[
            integrationClient.externalKind
          ]
        : undefined,
    }));
  }, []);

  const [asyncTypeaheadProps] = useAsyncTypeaheadProps(
    useGetIntegrationClientsMultipleQuery,
    {
      emptyOption: EMPTY_OPTION,
      searchTermToVariables,
      getSelectedOptionVariables,
      toOptions,
      skipAutocompleteResetClear: true,
      onError: createErrorFeedback(
        `Error fetching ${providerName} clients. Please refresh the page to try again.`
      ),
    }
  );

  return (
    <FormAwareTypeaheadMultiSelectInput<FormShape>
      {...asyncTypeaheadProps}
      loading={
        asyncTypeaheadProps.loading ||
        initialSyncStatus === InitialSyncStatus.PENDING
      }
      helpText={getHelpTextForStatus({
        status: initialSyncStatus,
        providerName,
        notLoggedIn,
      })}
      placeholder={`Not linked to client`}
      {...typeaheadProps}
      disabled={typeaheadProps.disabled || notLoggedIn}
    />
  );
}
