import { isToday as isTodayFns } from 'date-fns';

export function getNextDay(isoDate: Date) {
  // clone the date over to avoid mutating the input
  const nextDay = new Date(isoDate.toString());
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay;
}

export function getPreviousDay(isoDate: Date) {
  // clone the date over to avoid mutating the input
  const previousDay = new Date(isoDate.toString());
  previousDay.setDate(previousDay.getDate() - 1);
  return previousDay;
}

/**
 * @description Takes a local date and returns a new date object with the time
 * set to noon UTC and the same year, month, and date as the input date.
 */
export function noonifyDate(localDate: Date) {
  const year = localDate.getFullYear();
  const month = localDate.getMonth();
  const date = localDate.getDate();

  if (year < 1900) {
    // JS doesn't handle historical dates in a way that makes sense for our
    // purposes, so we'll just return the original date.
    return localDate;
  }

  // create a new date object with the same year, month, and date, but with
  // the time set to noon
  const noonDate = new Date(Date.UTC(year, month, date, 12, 0, 0));

  return noonDate;
}

/**
 * @description Check if a noonified date is "today" in the user's local time.
 * It ensures that even if the date is in UTC, it compares correctly with the local date.
 *
 * @param {Date} date - A noonified UTC date
 * @returns {boolean} True if the date is today in the local timezone, false otherwise.
 */
export function isTodayNoonified(date: Date): boolean {
  // Get the current local date
  const now = new Date();

  // Convert noonified UTC date back to local time
  const noonifiedLocal = new Date(
    date.getTime() + now.getTimezoneOffset() * 60000
  );

  // Use date-fns to check if the date (now converted to local time) is today
  return isTodayFns(noonifiedLocal);
}
