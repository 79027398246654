import React from 'react';
import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import {
  getFieldErrorValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import {
  HelpTextVariant,
  MultiSelectInputOption,
} from '../baseInputs/inputTypes';
import { MultiSelectInput } from '../baseInputs/MultiSelectInput';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

export interface FormAwareMultiSelectInputProps<FormShape extends FieldValues> {
  fieldName: FieldNameFromFormShape<FormShape>;
  label: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  options: MultiSelectInputOption[];
  required?: boolean;
  control: Control<FormShape>;
  disabled?: boolean;
  validation?: RegisterOptions<FormShape>['validate'];
  contextualHelp?: JSX.Element;
  startAdornment?: React.ReactNode;
  testId?: string;
  hideLabel?: boolean;
  id?: string;
}

export function FormAwareMultiSelectInput<FormShape extends FieldValues>({
  fieldName,
  label,
  helpText,
  helpTextVariant,
  options,
  required,
  control,
  disabled,
  hideLabel,
  validation,
  contextualHelp,
  startAdornment,
  testId,
  id,
}: FormAwareMultiSelectInputProps<FormShape>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();

  // this needs a custom "required" validator because it's an array
  const validations = getValidations(label, !!required, {
    // this is Array<unknown> because we only need to know that it's an array, not what's in it
    required: (value: unknown[]) => {
      if (!required) return undefined;
      if (!value?.length) {
        return `${label} is required.`;
      }
      return undefined;
    },
    ...validation,
  });

  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validations }}
      render={({ field, fieldState, formState }) => {
        return (
          <MultiSelectInput
            testId={testId}
            id={id}
            label={label}
            options={options}
            onChange={field.onChange}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            inputRef={field.ref}
            required={required}
            helpText={helpText}
            helpTextVariant={helpTextVariant}
            disabled={disabledFromContext ?? disabled}
            hideLabel={hideLabel}
            contextualHelp={contextualHelp}
            errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
            startAdornment={startAdornment}
          />
        );
      }}
    />
  );
}
