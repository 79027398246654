import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { RefObject } from 'react';

import { Loader } from '@/components/progress/Loader/Loader';

import {
  DispositiveProvisionsContext,
  DispositiveProvisionsFormVariant,
} from '../contexts/dispositiveProvisions.context';
import { DispositiveProvisionsForm } from '../DispositiveProvisionsForm/DispositiveProvisionsForm';
import { useDispositiveProvisionsContextData } from '../hooks/useDispositiveProvisionsContextData';

export interface DispositiveProvisionsModalBodyProps {
  onClose: () => void;
  firstDeathClientId: string;
  footerRef: RefObject<HTMLDivElement>;
  // topRightContent is used to thread through additional menu items to the header
  topRightContent?: React.ReactNode;
  nodeValue?: Decimal;
  variant: DispositiveProvisionsFormVariant;
  isFromTemplateEditor?: boolean;
}

export function DispositiveProvisionsModalBody({
  onClose,
  firstDeathClientId,
  footerRef,
  nodeValue,
  topRightContent,
  variant,
  isFromTemplateEditor,
}: DispositiveProvisionsModalBodyProps) {
  const data = useDispositiveProvisionsContextData(nodeValue);

  if (data.loading) {
    return (
      <Stack
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Stack>
    );
  }

  return (
    <DispositiveProvisionsContext.Provider
      value={{
        ...data,
        formVariant: variant,
        isFromTemplateEditor: isFromTemplateEditor || false,
      }}
    >
      <DispositiveProvisionsForm
        footerRef={footerRef}
        onAfterSubmit={onClose}
        onClose={onClose}
        firstDeathClientId={firstDeathClientId}
        topRightContent={topRightContent}
      />
    </DispositiveProvisionsContext.Provider>
  );
}
