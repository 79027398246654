import { useApolloClient } from '@apollo/client';
import React from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { Copy02Icon } from '@/components/icons/Copy02Icon';
import { Download01Icon } from '@/components/icons/Download01Icon';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import * as diagnostics from '@/utils/diagnostics';

import {
  FileRepresentation,
  FileRepresentationProps,
} from '../FileRepresentation/FileRepresentation';
import { useCopyDocumentSharingURL } from '../hooks/useCopyDocumentSharingURL';
import { useDocumentDownload } from '../hooks/useFileDownload';
import { useDeleteDocumentMutation } from './graphql/DocumentRepresentation.generated';

export interface DocumentRepresentationProps extends FileRepresentationProps {
  documentId: string;
  showDelete?: boolean;
  showCopySharingURL?: boolean;
  onDelete?: () => void;
}

export function DocumentRepresentation({
  documentId,
  fileName,
  documentType,
  uploadedAt,
  uploadedBy,
  showDelete,
  showCopySharingURL,
  onDelete,
  sx,
}: DocumentRepresentationProps) {
  const client = useApolloClient();
  const { showFeedback, createSuccessFeedback } = useFeedback();
  const [isDocumentDeleted, setDocumentDeleted] = React.useState(false);
  const [deleteDocument] = useDeleteDocumentMutation();
  const [copySharingURL, { loading: isGeneratingShareUrl }] =
    useCopyDocumentSharingURL(
      documentId,
      createSuccessFeedback('Copied a secure sharing link to your clipboard'),
      (err) => showFeedback(err.message)
    );
  const [downloadFile, { loading: isDownloadingFile }] = useDocumentDownload();

  async function handleRemoveFile() {
    try {
      await deleteDocument({
        variables: {
          documentId: documentId,
        },
        onCompleted: async () => {
          await client.refetchQueries({
            updateCache(cache) {
              cache.evict({ fieldName: 'documents' });
              cache.gc();
            },
          });
        },
      });
      // do this locally to cheaply not show this file.
      setDocumentDeleted(true);
      onDelete && onDelete();
    } catch (err) {
      diagnostics.error(`Failed to delete document`, err as Error, {
        documentId,
      });
      showFeedback(
        'We failed to delete your document. Please refresh the page and try again.'
      );
    }
  }

  if (isDocumentDeleted) return null;
  return (
    <FileRepresentation
      uploadedAt={uploadedAt}
      uploadedBy={uploadedBy}
      fileName={fileName}
      documentType={documentType}
      sx={sx}
      actions={
        <>
          {showCopySharingURL && (
            <Button
              startIcon={Copy02Icon}
              disabled={isGeneratingShareUrl}
              onClick={copySharingURL}
              variant="transparent"
              size="sm"
            >
              Copy secure link
            </Button>
          )}
          {showDelete && (
            <IconButton
              icon={Trash01Icon}
              ariaLabel="Delete document"
              disabled={isGeneratingShareUrl}
              onClick={handleRemoveFile}
              variant="destructive-transparent"
              size="sm"
            />
          )}
          <IconButton
            icon={Download01Icon}
            ariaLabel="Download document document"
            disabled={isDownloadingFile}
            onClick={() => downloadFile(documentId)}
            variant="secondary"
            size="sm"
          />
        </>
      }
    />
  );
}
