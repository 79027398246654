import { Box, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Switch } from '@/components/form/baseInputs/Switch';
import { Card } from '@/components/layout/Card/Card';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import {
  getOrionIntegrationLoginPath,
  getOrionIntegrationLogoutPath,
} from '@/modules/assetProviderIntegrations/orion/utils/orionAuthUtils';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { getPublicImageUrl } from '@/utils/staticFiles';

import {
  AdminIntegrationsPage_IntegrationConfigurationFragment,
  useUpdateIntegrationConfigurationMutation,
} from './graphql/AdminIntegrationsPage.generated';
import { ResyncTenantValuationsButton } from './ResyncTenantValuationsButton/ResyncTenantValuationsButton';

export interface OrionIntegrationCardProps {
  isLoggedIn: boolean;
  configuration: AdminIntegrationsPage_IntegrationConfigurationFragment | null;
}

export function OrionIntegrationCard({
  isLoggedIn,
  configuration,
}: OrionIntegrationCardProps) {
  const { integrationConfiguration } = useTenantDetailsContext();
  const { createSuccessFeedback, showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [isOrionEnabled, setIsOrionEnabled] = useState(
    integrationConfiguration?.orionEnabled ?? false
  );
  const [updateConfiguration] = useUpdateIntegrationConfigurationMutation({
    onCompleted: createSuccessFeedback(
      'Orion configuration updated successfully'
    ),
    onError: (err) => {
      showFeedback('Failed to update Orion configuration');
      reportError('Failed to update Orion configuration', err);
    },
  });

  async function handleToggleOrion() {
    if (!configuration) {
      throw new Error('Configuration is not available');
    }

    setIsOrionEnabled(!isOrionEnabled);
    await updateConfiguration({
      variables: {
        input: {
          id: configuration.id,
          update: {
            orionEnabled: !isOrionEnabled,
          },
        },
      },
    });
  }

  const getOrionButtonProps = useCallback(() => {
    const currentPageURL = getCompletePathFromRouteKey(
      'ADMIN_INTEGRATIONS',
      {}
    );

    const queryParams = {
      is_integration_user: 'true',
      redirect: currentPageURL,
    };

    if (isLoggedIn) {
      return {
        children: 'Disconnect integration user',
        variant: 'secondary' as const,
        href: getOrionIntegrationLogoutPath(queryParams),
        method: 'post' as const,
      };
    } else {
      return {
        children: 'Connect integration user',
        variant: 'primary' as const,
        href: getOrionIntegrationLoginPath(queryParams),
        disabled: !isOrionEnabled,
      };
    }
  }, [isOrionEnabled, isLoggedIn]);

  return (
    <Stack position="relative">
      {isLoggedIn && (
        <Box position="absolute" right={-5} top={-5}>
          <ResyncTenantValuationsButton />
        </Box>
      )}
      <Box textAlign="center">
        <img
          alt="Orion"
          src={getPublicImageUrl('/orionLogo.jpg')}
          height={40}
        />
      </Box>
      <Stack mb={2} mt={1} spacing={2}>
        <Typography variant="body1">
          Manage the integration user for your Orion account. This user should
          have access to all clients and portfolio data to enable regular
          syncing of information between systems.
          <br />
        </Typography>
        <Card variant="filled-light" sx={{ p: 2 }}>
          <Switch
            labelPosition="right"
            value={isOrionEnabled}
            label="Enable Orion"
            onChange={() => handleToggleOrion()}
          />
        </Card>
      </Stack>
      <Box mt={3} mb={2} textAlign="center">
        <Button
          size="lg"
          target="_self"
          {...getOrionButtonProps()}
          fullWidth
          external
        />
      </Box>
    </Stack>
  );
}
