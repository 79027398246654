import { EntityGraphViewKind, EntityKind } from '@/types/schema';

import { createEntityGraph } from './entityGraph/entityGraph';
import { createOwnershipEntityGraph } from './entityGraph/ownershipEntityGraph';
import { EntityGraphBuilderFn } from './types';

export const BusinessEntityKinds = [
  EntityKind.CcorpBusinessEntity,
  EntityKind.GpBusinessEntity,
  EntityKind.SoleProprietorshipBusinessEntity,
  EntityKind.LlcBusinessEntity,
  EntityKind.LpBusinessEntity,
  EntityKind.ScorpBusinessEntity,
];

export const TrustEntityKinds = [
  EntityKind.GratTrust,
  EntityKind.IlitTrust,
  EntityKind.IrrevocableTrust,
  EntityKind.RevocableTrust,
  EntityKind.SlatTrust,
  EntityKind.QprtTrust,
  EntityKind.IndividualPersonalAccount,
  EntityKind.JointPersonalAccount,
  EntityKind.CustodialPersonalAccount,
  EntityKind.QualifiedTuitionPersonalAccount,
  EntityKind.RetirementPersonalAccount,
  EntityKind.InsurancePersonalAccount,
  EntityKind.CltTrust,
  EntityKind.CrtTrust,
  EntityKind.DonorAdvisedFund,
  EntityKind.PrivateFoundation,
];

export const entityGraphViewToBuilderFn: Record<
  EntityGraphViewKind,
  EntityGraphBuilderFn
> = {
  BENEFICIARY: createEntityGraph,
  OWNERSHIP: createOwnershipEntityGraph,
};
