import { uniq } from 'lodash';
import { useMemo } from 'react';
import { Path } from 'react-hook-form';

import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { EntityInEstateStatus, StateCode } from '@/types/schema';

import {
  EstateInclusionStatus,
  TaxStatusSubformTypeStateTax,
} from '../TaxStatusSubform.types';

interface UseTaxStatusAssetLocationOptionsInput {
  fieldNamePrefix: string;
  selectedGrantorClientProfileIds?: string[];
}

interface TaxStatusAssetLocationSubformCommonShape {
  estateInclusionStatus: EstateInclusionStatus;
  stateTax: Record<StateCode, TaxStatusSubformTypeStateTax>;
}

type AssetLocationSubformShape<
  T extends UseTaxStatusAssetLocationOptionsInput,
> =
  | Record<T['fieldNamePrefix'], TaxStatusAssetLocationSubformCommonShape>
  | undefined;

export function useTaxStatusAssetLocationOptions({
  fieldNamePrefix,
  selectedGrantorClientProfileIds,
}: UseTaxStatusAssetLocationOptionsInput) {
  const { availableStateEstateTaxes } = useIRSConstants();
  const { primaryClients } = useHouseholdDetailsContext();

  const stateCodesFromClientAddress = useMemo(() => {
    const primaryClientIds = primaryClients?.map((client) => client.id) ?? [];
    // Take care of the case where a non-client grantor exists in the selectedGrantorClientProfileIds
    const hasSelectedIdNotInPrimaryClients =
      selectedGrantorClientProfileIds?.some(
        (id) => !primaryClientIds.includes(id)
      ) ?? false;
    if (hasSelectedIdNotInPrimaryClients) {
      // Return all state options for primary clients on the household
      return uniq(
        primaryClients?.flatMap((client) => {
          if (
            client.stateCode &&
            (availableStateEstateTaxes ?? []).includes(client.stateCode)
          ) {
            return client.stateCode;
          }

          return [];
        }) ?? []
      ).sort();
    }

    // Deduplicate and sort the state codes for the primary clients
    return uniq(
      primaryClients
        ?.filter((client) => {
          const primaryClientId = client.id;

          if (!selectedGrantorClientProfileIds) {
            // If selectedGrantorClientProfileIds is undefined, return all clients
            return true;
          }
          if (selectedGrantorClientProfileIds.includes(primaryClientId)) {
            // If selectedGrantorClientProfileIds includes the client id, return the client
            return true;
          }

          return false;
        })
        .flatMap((client) => {
          if (
            client.stateCode &&
            (availableStateEstateTaxes ?? []).includes(client.stateCode)
          ) {
            return client.stateCode;
          }

          return [];
        }) ?? []
    ).sort();
  }, [
    availableStateEstateTaxes,
    primaryClients,
    selectedGrantorClientProfileIds,
  ]);

  const { assetLocationOptions, stateTaxOptions } = useMemo(() => {
    const federalTaxOption = {
      label: 'Federal',
      fieldName:
        `${fieldNamePrefix}.estateInclusionStatus` as const satisfies Path<
          AssetLocationSubformShape<UseTaxStatusAssetLocationOptionsInput>
        >,
      options: [
        {
          label: '',
          value: 'in-estate',
        },
        {
          label: '',
          value: 'out-of-estate',
        },
      ],
    };

    const stateOptions = stateCodesFromClientAddress.map((stateCode) => ({
      label: stateCode,
      fieldName:
        `${fieldNamePrefix}.stateTax.${stateCode}.inEstateStatus` as const satisfies Path<
          AssetLocationSubformShape<UseTaxStatusAssetLocationOptionsInput>
        >,
      options: [
        {
          label: '',
          value: EntityInEstateStatus.InEstate,
        },
        {
          label: '',
          value: EntityInEstateStatus.OutOfEstate,
        },
      ],
    }));

    return {
      assetLocationOptions: [federalTaxOption, ...stateOptions],
      stateTaxOptions: stateOptions,
    };
  }, [fieldNamePrefix, stateCodesFromClientAddress]);

  return {
    assetLocationOptions,
    stateTaxOptions,
  };
}
