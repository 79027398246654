import { ApolloError, DocumentNode } from '@apollo/client';
import { Stack } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { DeleteButton } from '@/components/form/baseInputs/Button/DeleteButton';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { Modal } from '@/components/modals/Modal/Modal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useForm } from '@/components/react-hook-form';
import { shouldRefetchQuery } from '@/graphql/client.utils';
import { getUserFacingErrorMessages } from '@/graphql/errors';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import {
  ClientProfileFormShape,
  ClientProfileNameFields,
  getDefaultClientProfileFormValues,
} from '@/modules/clientProfiles/ClientProfile.types';
import { AsteriskRequiredLabel } from '@/modules/forms/formAccessories';
import { useCreateClientProfileMutation } from '@/modules/forms/generic/graphql/CreateClientProfile.generated';
import { getIndefiniteArticle } from '@/utils/formatting/strings';

import {
  ClientProfileModalContext,
  ClientProfileModalContextProvider,
} from './ClientProfileModal.context';
import { ClientProfilePerson } from './ClientProfileModal.UI';
import {
  mapDataToForm,
  mapFormToCreatePayload,
  mapFormToUpdatePayload,
} from './ClientProfileModal.utils';
import { useDeleteClientProfileMutation } from './graphql/ClearClientProfileModal.generated';
import { useBeneficiaryFormDataQuery } from './graphql/ClientProfileFormData.generated';
import { useUpdateClientProfileModalMutation } from './graphql/UpdateClientProfileModal.generated';

export interface ClientProfileModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  householdId: string;
  // if clientProfileId is null, we treat it as "create"
  clientProfileId: string | null;
  isPrimary?: boolean;
  allowDelete?: boolean;
  clientProfileTypeDisplay: string;
  // the following flags will only be respected in the component if the client
  // profile ID is null -- they are used to set the default value of the field
  // to true and to disable the field's switch, effecitvely forcing the field
  // value
  forceBeneficiaryIfNoClientProfileId?: boolean;
  forceTrusteeIfNoClientProfileId?: boolean;
  forceGrantorIfNoClientProfileId?: boolean;
  // callback to run after creating a new client profile that passes back the
  // new client profile id
  onAfterCreate?: (newId: string) => void;
  // initially populate the name fields with these values
  initialClientProfileName?: ClientProfileNameFields;
  // after a mutation, the modal will trigger a refetch of the active queries.
  // pass any queries you want to ignore from the refetch here.
  ignoredQueryDocuments?: DocumentNode[];
}

function ClientProfileModalInner({
  isOpen,
  setIsOpen,
  clientProfileTypeDisplay,
  forceBeneficiaryIfNoClientProfileId: forceBeneficiary,
  forceTrusteeIfNoClientProfileId: forceTrustee,
  forceGrantorIfNoClientProfileId: forceGrantor,
  allowDelete = true,
  onAfterCreate,
  initialClientProfileName,
  ignoredQueryDocuments,
}: ClientProfileModalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const trackUserEvent = useTrackUserEvent();
  const { isCreate, householdId, clientProfileId, isPrimary } = useContext(
    ClientProfileModalContext
  );
  const { data, loading: dataFetchLoading } = useBeneficiaryFormDataQuery({
    variables: { where: { id: householdId } },
    onError: (error) => {
      showFeedback('Could not fetch user data.  Please try again');
      reportError('Caught error when fetching user data', error);
    },
  });

  const formMethods = useForm<ClientProfileFormShape>({
    defaultValues: getDefaultClientProfileFormValues({
      relationshipOptions: [],
      householdId: '',
      initialClientProfileName,
      // the nullish coalescing below is necessary to preserve the false state
      hasBeneficiaryEnabled: isCreate ? forceBeneficiary ?? true : true,
      hasTrusteeEnabled: isCreate ? forceTrustee ?? true : true,
      hasGrantorEnabled: isCreate ? forceGrantor ?? false : false,
    }),
  });
  const { reset, handleSubmit, formState, getValues } = formMethods;

  useEffect(() => {
    if (data) {
      const newFormData = mapDataToForm({ data, clientProfileId });
      // if adding a new profile, keep the existing beneficiary/trustee/grantor flags,
      // and the name fields if they were passed in
      if (!clientProfileId) {
        newFormData.isBeneficiary = getValues('isBeneficiary');
        newFormData.isTrustee = getValues('isTrustee');
        newFormData.isGrantor = getValues('isGrantor');
        newFormData.isPrimary = isPrimary;
        newFormData.firstName = getValues('firstName');
        newFormData.middleName = getValues('middleName');
        newFormData.lastName = getValues('lastName');
        newFormData.suffix = getValues('suffix');
      }
      reset(newFormData);
    }
  }, [clientProfileId, data, getValues, isPrimary, reset]);

  const [createClientProfile] = useCreateClientProfileMutation({
    onCompleted: ({ createClientProfile }) => {
      showFeedback(`Created ${clientProfileTypeDisplay} successfully`, {
        variant: 'success',
      });
      onAfterCreate?.(createClientProfile.id);
      setIsOpen(false);
    },
    onError: (error: ApolloError) => {
      showFeedback(
        `Encountered an error creating the ${clientProfileTypeDisplay}.  Please try again.`
      );
      reportError('Encountered error when creating client profile', error);
    },
    onQueryUpdated: (query) => {
      return shouldRefetchQuery(query.queryName, {
        ignoredQueryDocuments,
      });
    },
    refetchQueries: 'active',
    awaitRefetchQueries: true,
  });

  const [deleteClientProfile] = useDeleteClientProfileMutation({
    onCompleted: () => {
      setIsOpen(false);
      showFeedback(`Deleted individual successfully`, {
        variant: 'success',
      });
    },
    onError: (err) => {
      const errorMessages = getUserFacingErrorMessages(err);
      showFeedback(`Failed to delete individual: ${errorMessages.join(', ')}`);
    },
    onQueryUpdated: (query) => {
      return shouldRefetchQuery(query.queryName, {
        ignoredQueryDocuments,
      });
    },
    refetchQueries: 'active',
    awaitRefetchQueries: true,
  });

  const [updateClientProfile] = useUpdateClientProfileModalMutation({
    onCompleted: () => {
      setIsOpen(false);
      showFeedback(`Updated ${clientProfileTypeDisplay} successfully`, {
        variant: 'success',
      });
    },
    onError: (error: ApolloError) => {
      showFeedback(
        `Encountered an error saving the ${clientProfileTypeDisplay}.  Please try again.`
      );
      reportError('Encountered error when updating client profile', error);
    },
    onQueryUpdated: (query) => {
      return shouldRefetchQuery(query.queryName, {
        ignoredQueryDocuments,
      });
    },
    refetchQueries: 'active',
    awaitRefetchQueries: true,
  });

  const handleRemove = useCallback(async () => {
    if (!clientProfileId) {
      showFeedback(
        `Could not remove the ${clientProfileTypeDisplay}.  Please try again.`
      );
      return;
    }

    await deleteClientProfile({
      variables: {
        clientProfileID: clientProfileId,
      },
    });
    setIsOpen(false);
  }, [
    clientProfileId,
    showFeedback,
    clientProfileTypeDisplay,
    deleteClientProfile,
    setIsOpen,
  ]);

  const onSave = handleSubmit(async (formData) => {
    if (isCreate) {
      const { input } = mapFormToCreatePayload(
        formData,
        householdId,
        isPrimary
      );
      const output = await createClientProfile({
        variables: {
          input,
        },
      });

      trackUserEvent('client_profile created', {
        isBeneficiary: formData.isBeneficiary,
        isGrantor: formData.isGrantor,
        isTrustee: formData.isTrustee,
        isPrimary: formData.isPrimary,
      });

      return output;
    } else {
      const { variables, errorMessage } = mapFormToUpdatePayload(
        formData,
        householdId,
        clientProfileId
      );
      if (errorMessage) {
        showFeedback(
          `Unable to save the ${clientProfileTypeDisplay}.  Please try again`
        );
        reportError(
          'Could not generate payload to update profile',
          new Error(errorMessage)
        );
        return;
      }
      return updateClientProfile({ variables });
    }
  });

  const isDisabled = dataFetchLoading;

  const actions = useMemo(() => {
    const baseButtons = (
      <Stack direction="row" spacing={1}>
        <Button
          size="sm"
          variant="secondary"
          onClick={() => setIsOpen(false)}
          data-testid="client-profile-modal-cancel"
        >
          Cancel
        </Button>
        <Button
          data-testid="client-profile-modal-save"
          disabled={isDisabled}
          loading={formState.isSubmitting}
          size="sm"
          variant="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </Stack>
    );

    return isCreate || !allowDelete ? (
      baseButtons
    ) : (
      <Stack direction="row" justifyContent="space-between" width="100%">
        <DeleteButton
          contextualHelp={
            <PopperContent
              body={'Individuals associated with a trust cannot be deleted'}
            />
          }
          onConfirmDelete={handleRemove}
        />
        {baseButtons}
      </Stack>
    );
  }, [
    allowDelete,
    formState.isSubmitting,
    handleRemove,
    isCreate,
    isDisabled,
    onSave,
    setIsOpen,
  ]);

  const modalTitle = useMemo(() => {
    const prefix = isCreate ? 'Create' : 'Update';
    const indefiniteArticle = getIndefiniteArticle(clientProfileTypeDisplay);

    return `${prefix} ${indefiniteArticle} ${clientProfileTypeDisplay}`;
  }, [clientProfileTypeDisplay, isCreate]);

  return (
    <Modal
      rightHeaderContent={<AsteriskRequiredLabel />}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      heading={modalTitle}
      actions={actions}
    >
      <FormProvider {...formMethods}>
        <FormFieldsDisabledProvider isDisabled={isDisabled}>
          <ClientProfilePerson
            disableBeneficiary={isCreate && forceBeneficiary}
            disableTrustee={isCreate && forceTrustee}
            // don't allow untoggling a primary client from being a grantor
            disableGrantor={(isCreate && forceGrantor) || isPrimary}
          />
        </FormFieldsDisabledProvider>
      </FormProvider>
    </Modal>
  );
}

export function ClientProfileModal(props: ClientProfileModalProps) {
  // nulling out the modal if not open resolves issues around resetting the state when reopening the modal,
  // as the default form state is derived from props, but not updated on subsequent renders.
  if (!props.isOpen) {
    return null;
  }

  return (
    <ClientProfileModalContextProvider
      householdId={props.householdId}
      clientProfileId={props.clientProfileId}
      isPrimary={props.isPrimary ?? false}
    >
      <ClientProfileModalInner {...props} />
    </ClientProfileModalContextProvider>
  );
}
