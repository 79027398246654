import { Box } from '@mui/material';

import { Callout } from '@/components/notifications/Callout/Callout';
import { COLORS } from '@/styles/tokens/colors';

export interface HypotheticalWaterfallScenarioCalloutProps {
  waterfallName: string;
}

export function HypotheticalWaterfallScenarioCallout({
  waterfallName,
}: HypotheticalWaterfallScenarioCalloutProps) {
  return (
    <Box
      p={2}
      sx={{ backgroundColor: COLORS.BLUE[50] }}
      borderRadius={1}
      mb={2}
    >
      <Callout severity="info-high">
        Dispositions edited here apply only to the hypothetical waterfall &quot;
        {waterfallName}&quot;
      </Callout>
    </Box>
  );
}
