import { useCallback } from 'react';

import { CompleteInitializationEffect, FamilyTreeEffectFn } from '../types';

export function useCompleteInitialiationEffect(): FamilyTreeEffectFn<CompleteInitializationEffect> {
  return useCallback<FamilyTreeEffectFn<CompleteInitializationEffect>>(
    (_state, _effect, dispatch) => {
      dispatch({ type: 'COMPLETE_INITIALIZATION' });
    },
    []
  );
}
