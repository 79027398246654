import { useCallback } from 'react';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { shouldRefetchQuery } from '@/graphql/client.utils';
import {
  useDeleteGraphVizGroupsMutation,
  useUpdateGraphVizGroupMutation,
} from '@/modules/graphViz/graphql/GraphVizGroup.generated';
import { AugmentedUpdateGraphVisualizationGroupInput } from '@/types/schema';
import { assertExact } from '@/utils/assertUtils';
import { diagnostics } from '@/utils/diagnostics';

import { EstateWaterfallDocument } from '../graphql/EstateWaterfall.generated';
import { ManageGroupFormShape } from './ManageGroupModal';

export function useManageNodeGroup(groupId: string) {
  const { showFeedback } = useFeedback();
  const [deleteMutation] = useDeleteGraphVizGroupsMutation({
    refetchQueries: 'active',
    onQueryUpdated: (query) => {
      return shouldRefetchQuery(query.queryName, {
        allowedQueryDocuments: [EstateWaterfallDocument],
      });
    },
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      diagnostics.error(`Could not delete graph viz group`, error);
    },
  });

  const [updateMutation] = useUpdateGraphVizGroupMutation({
    refetchQueries: 'active',
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      diagnostics.error(`Could not create graph viz group`, error);
    },
    onQueryUpdated: (query) => {
      return shouldRefetchQuery(query.queryName, {
        allowedQueryDocuments: [EstateWaterfallDocument],
      });
    },
  });

  const mapFormDataToInput = useCallback(
    ({
      nodes,
      ...formData
    }: ManageGroupFormShape): AugmentedUpdateGraphVisualizationGroupInput => {
      return assertExact<AugmentedUpdateGraphVisualizationGroupInput>()({
        id: groupId,
        update: { ...formData, nodeIds: nodes.map((n) => n.id) },
      });
    },
    [groupId]
  );

  const saveManageNodeGroup = useCallback(
    (formData: ManageGroupFormShape) => {
      const nodeIdsInGroup = formData.nodes.map((i) => i.id);

      if (nodeIdsInGroup.length < 2) {
        // Delete the group if the group has been reduced to 1 node
        return deleteMutation({ variables: { ids: [groupId] } });
      }

      const input = mapFormDataToInput(formData);
      return updateMutation({ variables: { input } });
    },
    [deleteMutation, groupId, mapFormDataToInput, updateMutation]
  );

  return { saveManageNodeGroup };
}
