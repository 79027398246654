import { GRANTOR_RELATIONSHIP_OPTIONS } from '@/components/form/PersonFormFragment/constants';
import { SummaryBeneficiaryKindv2 } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import {
  AdvisorClientForBeneficiariesModalFragment,
  EntityForBeneficiariesModalFragment,
  TrustBeneficiaryForBeneficiariesModalFragment,
} from './graphql/BeneficiariesPickerModalOptions.generated';
import { FormType } from './types';
import { Option } from './types';

export function getSelectedIds(values: FormType) {
  const selectedTrusts = values.selectedTrusts
    .filter((option) => option.selected)
    .map((option) => option.id);

  const selectedIndividuals = values.selectedIndividuals
    .filter((option) => option.selected)
    .map((option) => option.id);

  return {
    trusts: selectedTrusts,
    individuals: selectedIndividuals,
  };
}

function getJoinedBeneficiaryNames(
  beneficiaries: TrustBeneficiaryForBeneficiariesModalFragment[]
) {
  return beneficiaries
    .map((beneficiary) => {
      if (beneficiary.individual) {
        return beneficiary.individual.displayName;
      } else if (beneficiary.entity) {
        return beneficiary.entity.subtype.displayName;
      } else if (beneficiary.organization) {
        return beneficiary.organization.name;
      }

      throw new UnreachableError({
        case: beneficiary as never,
        message: `Unexpected beneficiary type: ${beneficiary.id}`,
      });
    })
    .join(', ');
}

function getOptionForTrustEntity(
  trustEntity: EntityForBeneficiariesModalFragment
): Option {
  const { id, subtype, extendedDisplayKind } = trustEntity;
  const { displayName } = trustEntity.subtype;
  switch (subtype.__typename) {
    case 'QPRTTrust':
    case 'ILITTrust':
    case 'IrrevocableTrust':
    // falls through, same handling as RevocableTrust
    case 'RevocableTrust':
      return {
        id,
        displayName,
        description: extendedDisplayKind,
        type: SummaryBeneficiaryKindv2.Entity,
        summary: getJoinedBeneficiaryNames(subtype.beneficiaries ?? []),
      };
    case 'SLATTrust': {
      const allBeneficiaries = [
        ...(subtype.lifetimeBeneficiaries ?? []),
        ...(subtype.remainderBeneficiaries ?? []),
      ];
      return {
        id,
        displayName,
        description: extendedDisplayKind,
        type: SummaryBeneficiaryKindv2.Entity,
        summary: getJoinedBeneficiaryNames(allBeneficiaries),
      };
    }
    default:
      throw new UnreachableError({
        case: subtype.__typename as never,
        message: `Unexpected subtype ${subtype.__typename} in getOptionForTrustEntity in BeneficiaryPickerModal2`,
      });
  }
}

export function getInitialOptions(
  beneficiaries: NonNullable<
    AdvisorClientForBeneficiariesModalFragment['possibleBeneficiaries']
  >,
  trustEntities: NonNullable<
    AdvisorClientForBeneficiariesModalFragment['entities']
  >,
  grantorProfileId: string
) {
  const initialOptions = {
    trusts:
      trustEntities.edges?.flatMap((edge) => {
        if (!edge?.node) return [];
        return getOptionForTrustEntity(edge.node);
      }) ?? [],
    individuals: beneficiaries.map((beneficiary) => {
      const { id, displayName, relationships } = beneficiary;

      const relationshipsWithThisClient = (
        relationships?.filter(
          (relationship) => relationship.toClientProfile.id === grantorProfileId
        ) ?? []
      ).map((relationship) => relationship.type);

      const summary = relationshipsWithThisClient?.[0] ?? '';

      const summaryCopy =
        GRANTOR_RELATIONSHIP_OPTIONS.find((item) => item.value === summary)
          ?.display ?? '';

      return {
        id,
        displayName,
        description: 'Individual',
        type: SummaryBeneficiaryKindv2.ClientProfile,
        summary: summaryCopy,
      };
    }),
  };

  return initialOptions;
}
