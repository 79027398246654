import { cx } from '@emotion/css';
import { Box } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { DataTableCard } from '@/components/tables/DataTable/components/DataTableCard';
import { TableEmptyState } from '@/components/tables/DataTable/components/TableEmptyState';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { usePaginatedDataTableQuery } from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { getNodes } from '@/utils/graphqlUtils';

import { AddSubBrandButton } from './AddSubBrandButton';
import { columns, RowData } from './AdminSubBrandsPage.constants';
import {
  AdminSubBrandsPage_SubBrandFragment,
  useGetSubBrandsQuery,
} from './graphql/AdminSubBrandsPage.generated';
import { EditSubBrandModal } from './SubBrandModal/EditSubBrandModal';

const useStyles = makeStyles()((_theme) => ({
  // this is required to give the virtual scroller enough height to not cut off the bottom of the table empty state
  virtualScroller: {
    minHeight: 170,
  },
}));

function mapDataToRows(
  subBrands: AdminSubBrandsPage_SubBrandFragment[]
): RowData[] {
  return subBrands.map((subBrand) => ({
    id: subBrand.id,
    name: subBrand.displayName,
    householdSummary:
      getNodes(subBrand.households)
        .map((household) => household.displayName)
        .join(', ') || EMPTY_CONTENT_HYPHEN,
    action: null,
  }));
}

const slots = {
  noRowsOverlay: () => (
    <TableEmptyState text="No sub-brands">
      <Box>
        <AddSubBrandButton />
      </Box>
    </TableEmptyState>
  ),
};

export function AdminSubBrandsPage() {
  const [editingSubBrandId, setEditingSubBrandId] = useState<string | null>(
    null
  );
  const [paginatedTableProps, { data, refetch }] = usePaginatedDataTableQuery(
    useGetSubBrandsQuery,
    {
      pageSize: PageSizes.Ten,
      variables: {
        where: {},
      },
    }
  );

  const rows = mapDataToRows(data);
  const styles = useStyles();
  const handleUpdate = () => {
    void refetch();
    setEditingSubBrandId(null);
  };

  return (
    <>
      {editingSubBrandId && (
        <EditSubBrandModal
          isOpen={!!editingSubBrandId}
          subBrandId={editingSubBrandId}
          onClose={handleUpdate}
        />
      )}
      <SubpageLayout heading="Sub-brands" actions={<AddSubBrandButton />}>
        <DataTableCard>
          <DataTable
            {...paginatedTableProps}
            onRowClick={({ row }: GridRowParams<RowData>) =>
              setEditingSubBrandId(row.id)
            }
            classes={{
              virtualScroller: cx(styles.classes.virtualScroller),
            }}
            slots={slots}
            columns={columns}
            rows={rows}
          />
        </DataTableCard>
      </SubpageLayout>
    </>
  );
}
