import { SubForm } from '@/types/subform';

import { TAX_STATUS_SUBFORM } from './TaxStatusSubform.constants';
import {
  Fields,
  NamespaceType,
  TaxStatusSubformProps,
  TaxStatusSubformType,
  VariantType,
} from './TaxStatusSubform.types';
import { UI } from './TaxStatusSubform.UI';
import { taxStatusDefaultValues } from './TaxStatusSubform.utils';

export class TaxStatusSubform
  implements
    SubForm<TaxStatusSubformType, Fields, NamespaceType, VariantType, object>
{
  FormComponent: (props: TaxStatusSubformProps) => JSX.Element;
  defaultValues: TaxStatusSubformType;
  namespace: NamespaceType;

  constructor() {
    this.FormComponent = UI;
    this.defaultValues = taxStatusDefaultValues;
    this.namespace = TAX_STATUS_SUBFORM;
  }
}
