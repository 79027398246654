import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { compact } from 'lodash';
import { ReactNode, useMemo } from 'react';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { SubtleLabelItem } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { BankIcon } from '@/components/icons/BankIcon';
import { CoinsHandIcon } from '@/components/icons/CoinsHandIcon';
import { Card } from '@/components/layout/Card/Card';
import { PopperContent } from '@/components/poppers/PopperContent';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { useIsCRT } from '../../CharitableTrustDesignerContext';
import {
  CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment,
  CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment,
} from '../graphql/CharitableTrustDesignerIllustrationData.generated';

export interface CharitableTrustDesignerAnalysisIllustrationSummaryCardsProps {
  crtProjection?: CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment;
  cltProjection?: CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment;
}

interface SummaryValue {
  label: string;
  value: ReactNode;
  tooltip?: JSX.Element;
}

function SummaryCard({
  icon,
  main,
  subValues,
}: {
  icon: ReactNode;
  main: SummaryValue;
  subValues: SummaryValue[];
}) {
  return (
    <Card variant="outlined" sx={{ flex: '1 1 50%' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        sx={{ p: 3 }}
        width="100%"
        height="100%"
      >
        <OversizedMetricItem
          title={main.label}
          tooltip={main.tooltip}
          value={main.value}
          icon={icon}
          iconBackground={COLORS.GRAY[400]}
        />
        <Stack direction="row" spacing={1}>
          {subValues.map(({ value, label, tooltip }, idx) => (
            <Card
              key={`sub-value-${idx}`}
              variant="filled"
              sx={{ p: 2, ml: 2 }}
            >
              <Stack
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
              >
                <SubtleLabelItem label={label} value={value} action={tooltip} />
              </Stack>
            </Card>
          ))}
        </Stack>
      </Stack>
    </Card>
  );
}

export function CharitableTrustDesignerAnalysisIllustrationSummaryCards({
  crtProjection,
  cltProjection,
}: CharitableTrustDesignerAnalysisIllustrationSummaryCardsProps) {
  const isCRT = useIsCRT();
  const theme = useTheme();
  const shouldDisplayStacked = useMediaQuery(theme.breakpoints.down('xl'));

  const charitableDeductionDisplay = useMemo(() => {
    const charitableDeduction = isCRT
      ? crtProjection?.projectedCharitableRemainder
      : cltProjection?.projectedCharitableBenefit;
    if (!charitableDeduction) {
      return EMPTY_CONTENT_HYPHEN;
    }
    return formatCurrency(charitableDeduction, { notation: 'compact' });
  }, [
    cltProjection?.projectedCharitableBenefit,
    crtProjection?.projectedCharitableRemainder,
    isCRT,
  ]);

  const incomeTaxSavingsDisplay = useMemo(() => {
    const incomeTaxSavings = isCRT
      ? crtProjection?.ordinaryIncomeTaxSaved
      : cltProjection?.ordinaryIncomeTaxSaved;
    if (!incomeTaxSavings) {
      return EMPTY_CONTENT_HYPHEN;
    }
    return formatCurrency(incomeTaxSavings, { notation: 'compact' });
  }, [
    cltProjection?.ordinaryIncomeTaxSaved,
    crtProjection?.ordinaryIncomeTaxSaved,
    isCRT,
  ]);

  const capitalGainsDeferredDisplay = useMemo(() => {
    const capitalGainsDeferred = isCRT
      ? crtProjection?.capitalGainsDeferred
      : cltProjection?.personalRemainder;
    if (!capitalGainsDeferred) {
      return EMPTY_CONTENT_HYPHEN;
    }
    return formatCurrency(capitalGainsDeferred, { notation: 'compact' });
  }, [
    cltProjection?.personalRemainder,
    crtProjection?.capitalGainsDeferred,
    isCRT,
  ]);

  const incomeTaxDeferredDisplay = useMemo(() => {
    const ordinaryIncomeTaxDeferred = isCRT
      ? crtProjection?.capitalGainsTaxDeferred
      : cltProjection?.estateTaxSaved;
    if (!ordinaryIncomeTaxDeferred) {
      return EMPTY_CONTENT_HYPHEN;
    }
    return formatCurrency(ordinaryIncomeTaxDeferred, { notation: 'compact' });
  }, [
    cltProjection?.estateTaxSaved,
    crtProjection?.capitalGainsTaxDeferred,
    isCRT,
  ]);

  const taxableGiftDisplay = useMemo<string | null>(() => {
    if (isCRT) {
      return null;
    }

    const taxableGift = cltProjection?.taxableGift;
    return taxableGift
      ? formatCurrency(taxableGift, { notation: 'compact' })
      : EMPTY_CONTENT_HYPHEN;
  }, [cltProjection?.taxableGift, isCRT]);
  const {
    federalOrdinaryIncomeTaxPercentMaxBracket,
    federalEstateTaxPercent,
    federalLongTermCapitalGrainsTaxPercent,
  } = useIRSConstants();

  let federalIncomeTaxDisplay = '';
  let federalEstateTaxDisplay = '';
  let federalCapitalGainsTaxDisplay = '';

  if (federalOrdinaryIncomeTaxPercentMaxBracket) {
    federalIncomeTaxDisplay = formatPercent(
      federalOrdinaryIncomeTaxPercentMaxBracket,
      0
    );
  }

  if (federalEstateTaxPercent) {
    federalEstateTaxDisplay = formatPercent(federalEstateTaxPercent, 0);
  }

  if (federalLongTermCapitalGrainsTaxPercent) {
    federalCapitalGainsTaxDisplay = formatPercent(
      federalLongTermCapitalGrainsTaxPercent,
      0
    );
  }

  return (
    <Stack
      direction={shouldDisplayStacked ? 'column' : 'row'}
      spacing={shouldDisplayStacked ? 3 : 2}
    >
      <SummaryCard
        main={{
          label: 'Charitable deduction',
          value: charitableDeductionDisplay,
          tooltip: (
            <ContextualHelpTooltip sx={{ display: 'inline-block' }}>
              <PopperContent body="The charitable deduction is based on the projected charitable remainder, which is calculated using the initial funding, term length, and payouts modeled." />
            </ContextualHelpTooltip>
          ),
        }}
        subValues={[
          {
            label: 'Income tax savings',
            value: incomeTaxSavingsDisplay,
            tooltip: (
              <ContextualHelpTooltip sx={{ display: 'inline-block' }}>
                <PopperContent
                  body={`Assumes the charitable deduction is used to offset ordinary income that is otherwise taxable at a federal income tax rate of ${federalIncomeTaxDisplay}%.`}
                />
              </ContextualHelpTooltip>
            ),
          },
        ]}
        icon={<BankIcon />}
      />
      <SummaryCard
        main={{
          label: isCRT ? 'Capital gains deferred' : 'Family remainder',
          value: capitalGainsDeferredDisplay,
          tooltip: (
            <ContextualHelpTooltip sx={{ display: 'inline-block' }}>
              <PopperContent
                body={
                  isCRT
                    ? 'The capital gains deferred equals the embedded capital gains (value less cost basis) of the funding assets modeled.'
                    : 'The family remainder represents the amount projected to remain in the trust, at the end of the trust’s term after all charitable income payments have been made.'
                }
              />
            </ContextualHelpTooltip>
          ),
        }}
        subValues={compact([
          taxableGiftDisplay
            ? {
                label: 'Taxable gift',
                value: taxableGiftDisplay,
              }
            : null,
          {
            label: isCRT ? 'Income tax deferred' : 'Estate tax savings',
            value: incomeTaxDeferredDisplay,
            tooltip: (
              <ContextualHelpTooltip sx={{ display: 'inline-block' }}>
                <PopperContent
                  body={
                    isCRT
                      ? `Assumes the capital gains deferred would otherwise be taxable at a federal long-term capital gain tax rate of ${federalCapitalGainsTaxDisplay}%.`
                      : `Assumes the family remainder in excess of the taxable gift created from funding the trust, would otherwise be taxable at a federal estate tax rate of ${federalEstateTaxDisplay}%.`
                  }
                />
              </ContextualHelpTooltip>
            ),
          },
        ])}
        icon={<CoinsHandIcon />}
      />
    </Stack>
  );
}
