import { ActivityGroupName, ActivityVerb } from '@/types/schema';

export const verbCopy: Record<ActivityVerb, string> = {
  create: 'created',
  update: 'updated',
  delete: 'deleted',
};

export const groupCopy: Record<ActivityGroupName, string> = {
  assign_strategy_tasks: 'Assigned tasks',
  create_strategy: 'Created strategy',
  create_team: 'Created team',
  delete_strategy: 'Deleted strategy',
  delete_team: 'Deleted team',
  update_strategy: 'Updated strategy',
  update_team: 'Updated team',
  update_entity_task: 'Updated task',
  assign_entity_tasks: 'Assigned tasks',
  create_entity: 'Created entity',
  delete_entity: 'Deleted entity',
  update_entity: 'Updated entity',
};
