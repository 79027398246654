import { useReportError } from '@/hooks/useReportError';
import { getNodes } from '@/utils/graphqlUtils';

import { useClientDetailsOverviewPageQuery as useClientDetailsOverviewPageQueryExternal } from '../graphql/ClientDetailsOverviewPage.generated';

interface useClientDetailsOverviewPageQueryInput {
  householdId: string;
}

export function useClientDetailsOverviewPageQuery({
  householdId,
}: useClientDetailsOverviewPageQueryInput) {
  const { reportError } = useReportError();

  const queryProps = useClientDetailsOverviewPageQueryExternal({
    variables: {
      householdId,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    onError: (error) => {
      reportError(
        'failed to load data for client details overview page',
        error,
        {
          householdId,
        }
      );
    },
  });

  const household = getNodes(queryProps.data?.households)[0];
  const hasEntities = (household?.entities.totalCount ?? 0) > 0;

  return { household, hasEntities, ...queryProps };
}
