import { Stack } from '@mui/material';

import { PageHeader } from '@/components/navigation/PageHeader';
import { DataTable_LEGACYCard } from '@/components/tables/DataTable_LEGACY/DataTable_LEGACYCard';
import {
  TasksTable,
  TaskTableColumns,
} from '@/modules/tasks/components/TasksTable/TasksTable';
import { ROUTE_KEYS } from '@/navigation/constants';
import { EntityTaskWhereInput } from '@/types/schema';

export function TasksPage() {
  const taskTableFilter: EntityTaskWhereInput = {};

  return (
    <Stack>
      <PageHeader heading="Tasks" />
      <Stack p={3} pb={4} spacing={1} sx={{ width: '100%' }}>
        <DataTable_LEGACYCard>
          <TasksTable
            filter={taskTableFilter}
            columns={[
              TaskTableColumns.DUE_AT,
              TaskTableColumns.SUB_BRAND,
              TaskTableColumns.TASK_NAME,
              TaskTableColumns.ASSIGNEE,
              TaskTableColumns.ENTITY_NAME,
              TaskTableColumns.CLIENT_NAME,
            ]}
            from={ROUTE_KEYS.TASKS}
          />
        </DataTable_LEGACYCard>
      </Stack>
    </Stack>
  );
}
