import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { CharitableTrustDesignerDistributionTableDataQuery } from './graphql/CharitableTrustDesignerDistributionTableData.generated';

export interface CharitableTrustDesignerDistributionTableRow {
  year: number;
  beginningPrincipal: string;
  principalGrowth: string;
  incomePayment: string;
  remainderValue: string;
}

export function mapDataToCharitableTrustDesignerDistributionTableRows(
  data: CharitableTrustDesignerDistributionTableDataQuery | undefined
): CharitableTrustDesignerDistributionTableRow[] {
  if (data?.crtDistributionTableProjection) {
    return (
      data.crtDistributionTableProjection?.yearly?.map(
        (yearlyProjection, idx) => ({
          id: `crt-projection-${idx}`,
          year: yearlyProjection.year,
          beginningPrincipal: formatCurrencyNoDecimals(
            yearlyProjection.beginningOfYear
          ),
          principalGrowth: formatCurrencyNoDecimals(yearlyProjection.growth),
          incomePayment: formatCurrencyNoDecimals(yearlyProjection.payout),
          remainderValue: formatCurrencyNoDecimals(
            yearlyProjection.remainderValue
          ),
        })
      ) || []
    );
  } else if (data?.cltDistributionTableProjection) {
    return (
      data.cltDistributionTableProjection?.yearly?.map(
        (yearlyProjection, idx) => ({
          id: `clt-projection-${idx}`,
          year: yearlyProjection.year,
          beginningPrincipal: formatCurrencyNoDecimals(
            yearlyProjection.beginningOfYear
          ),
          principalGrowth: formatCurrencyNoDecimals(yearlyProjection.growth),
          incomePayment: formatCurrencyNoDecimals(yearlyProjection.payout),
          remainderValue: formatCurrencyNoDecimals(
            yearlyProjection.remainderValue
          ),
        })
      ) || []
    );
  }

  return [];
}
