import { useApolloClient } from '@apollo/client';
import { Box, InputAdornment, Skeleton } from '@mui/material';
import Decimal from 'decimal.js';
import React, { useCallback, useEffect, useState } from 'react';
import { flushSync } from 'react-dom';
import { FormProvider, SubmitHandler, UseFormReset } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { FormConfigurationProvider } from '@/components/form/context/FormConfigurationContext';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { User01Icon } from '@/components/icons/User01Icon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { BeneficiariesSubform } from '@/modules/beneficiaries/beneficiariesSubform/BeneficiariesSubform';
import { getBeneficiaryOptionsv2 } from '@/modules/beneficiaries/beneficiariesSubform/utils/getBeneficiaryOptions';
import { getBeneficiariesFormValuesFromQueryv2 } from '@/modules/beneficiaries/beneficiariesSubform/utils/getFormValues';
import { generateCreateEntityBeneficiaryMutationInputsv2 } from '@/modules/beneficiaries/utils';
import { ClientProfileModal } from '@/modules/clientProfiles/ClientProfileForm/ClientProfileModal';
import { NEW_ENTITY_SENTINEL } from '@/modules/entities/entities.constants';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { GratDesignerStages, ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { GRATDisplayNameInput } from '@/pages/designer/GRATDesigner/StructuringDesigner/TermsForm/inputComponents';
import {
  AugmentedCreateEntityInput,
  AugmentedUpdateEntityInput,
  EntityAttributionSource,
  EntityStage,
} from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import { RenderDesignerFooter } from '../../types';
import { DesignerLayout } from '../DesignerLayout';
import { BasicInformationDesignerFooter } from './BasicInformationDesignerFooter';
import {
  getClientProfileOptions,
  isNewEntity,
} from './basicInformationDesignerUtils';
import { BasicInformationDesignerForm } from './constants';
import {
  BasicInformationDesignerGratFragment,
  DesignerAdvisorClientFragment,
  useCreateGratEntityMutation,
  useGetBasicInformationDesignerEntityLazyQuery,
  useGetDesignerClientLazyQuery,
  useUpdateGratEntityMutation,
} from './graphql/BasicInformationDesigner.generated';
import { useBasicInformationDesignerDataFromSearch } from './hooks/useBasicInformationDesignerDataFromSearch';

const {
  FormComponent: BeneficiariesFormComponent,
  defaultValues: beneficiarySubformDefaultValues,
} = new BeneficiariesSubform();

const defaultFormValues: BasicInformationDesignerForm = {
  displayName: '',
  grantorClientProfileId: '',
  beneficiariesSubform: beneficiarySubformDefaultValues,
};

function useFetchClientData(householdId: string) {
  const { createErrorFeedback } = useFeedback();
  const [clientData, setClientData] =
    useState<DesignerAdvisorClientFragment | null>(null);
  const [getClient, { loading }] = useGetDesignerClientLazyQuery({
    onError: createErrorFeedback(
      'Failed to load grantor details. Please refresh the page to try again.'
    ),
  });

  useEffect(() => {
    async function fetchClientData() {
      const { data } = await getClient({
        variables: {
          householdId,
        },
      });

      if (data?.client?.__typename !== 'Household') {
        throw new Error('invalid data');
      }

      setClientData(data.client);
    }

    void fetchClientData();
  }, [householdId, getClient]);

  return { loading, clientData };
}

function useFetchDataAndSyncToForm(
  entityId: string,
  reset: UseFormReset<BasicInformationDesignerForm>
) {
  const { createErrorFeedback } = useFeedback();
  const [trustData, setTrustData] =
    useState<BasicInformationDesignerGratFragment | null>(null);

  const [getEntity, { loading }] =
    useGetBasicInformationDesignerEntityLazyQuery({
      onError: createErrorFeedback(
        'Failed to load GRAT details. Please refresh the page to try again.'
      ),
    });

  useEffect(() => {
    async function syncDataToForm() {
      if (entityId === 'new') {
        return;
      }

      const { data } = await getEntity({
        variables: {
          entityId,
        },
      });

      if (
        data?.entity?.__typename !== 'Entity' ||
        data?.entity?.subtype.__typename !== 'GRATTrust'
      ) {
        throw new Error('Invalid data');
      }
      const trust = data.entity.subtype;
      setTrustData(trust);
      const beneficiariesFormData =
        getBeneficiariesFormValuesFromQueryv2(trust);

      reset({
        displayName: trust.displayName,
        grantorClientProfileId: trust.grantor?.individual?.id ?? '',
        beneficiariesSubform: beneficiariesFormData,
      });
    }

    void syncDataToForm();
  }, [entityId, reset, getEntity]);

  return { loading, trustData };
}

interface BasicInformationDesignerProps {
  householdId: string;
  entityId: string;
  isFullScreenModal?: boolean;
  Footer?: RenderDesignerFooter;
  onAfterUpdate?: () => void;
}

export function BasicInformationDesigner({
  householdId,
  entityId,
  isFullScreenModal = false,
  Footer,
  onAfterUpdate,
}: BasicInformationDesignerProps) {
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const { showFeedback } = useFeedback();
  const formDefaultOverrides = useBasicInformationDesignerDataFromSearch();

  const formProperties = useForm<BasicInformationDesignerForm>({
    defaultValues: {
      ...defaultFormValues,
      ...formDefaultOverrides,
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty, dirtyFields },
    setShouldBlockNavigation,
  } = formProperties;

  const setBlockingBehavior = useCallback(
    (shouldBlockNavigation: boolean) => {
      flushSync(() => {
        setShouldBlockNavigation(shouldBlockNavigation);
      });
    },
    [setShouldBlockNavigation]
  );

  const { loading: loadingInitialData, trustData } = useFetchDataAndSyncToForm(
    entityId,
    reset
  );
  const { loading: loadingClientData, clientData } =
    useFetchClientData(householdId);
  const grantorClientProfileId = watch('grantorClientProfileId');

  // state
  const [isGrantorModalOpen, setIsGrantorModalOpen] = useState(false);

  // mutations
  const [
    createGRAT,
    { loading: currentlyCreating, error: createStrategyError },
  ] = useCreateGratEntityMutation();
  const [updateGRAT, { loading: currentUpdating, error: updateStrategyError }] =
    useUpdateGratEntityMutation();

  const disableFooter =
    currentUpdating ||
    currentlyCreating ||
    loadingInitialData ||
    loadingClientData;
  const beneficiariesValues = watch('beneficiariesSubform').beneficiaries;

  const handleError = (error: Error) => {
    diagnostics.error('Failed to save BasicInformationDesigner changes', error);
    showFeedback("We weren't able to save your changes. Please try again.");
  };

  const onValidBasicInformationSubmit: SubmitHandler<
    BasicInformationDesignerForm
  > = async (values) => {
    const beneficiaryPercentSum =
      values.beneficiariesSubform.beneficiaries.reduce(
        (sum, beneficiary) => sum.plus(beneficiary.percent),
        new Decimal(0)
      );

    if (beneficiaryPercentSum.greaterThan(100)) {
      showFeedback('The combined ownership percentage must sum to 100%.');
      return;
    }

    const beneficiaryOptions = getBeneficiaryOptionsv2(
      values.beneficiariesSubform,
      trustData
    );

    if (entityId === NEW_ENTITY_SENTINEL) {
      const newGRATInput: AugmentedCreateEntityInput = {
        create: {
          householdID: householdId,
          stage: EntityStage.Draft,
          attributionSource: EntityAttributionSource.StrategyDesigner,
        },
        withGratTrust: {
          create: {
            displayName: values.displayName,
          },
          withGrantor: {
            create: {
              individualID: values.grantorClientProfileId,
            },
          },
          withDesignerAccount: {
            create: {
              displayName: 'Default account',
            },
            withInitialValuation: {
              create: {
                effectiveDate: new Date(new Date(0).toISOString()),
              },
            },
          },
          withBeneficiaries: generateCreateEntityBeneficiaryMutationInputsv2(
            beneficiaryOptions,
            householdId
          ),
        },
      };

      const { data } = await createGRAT({
        variables: { input: newGRATInput },
      });

      // invalidate the cache for entities
      await apolloClient.refetchQueries({
        updateCache(cache) {
          cache.evict({ fieldName: 'entities' });
        },
      });

      if (createStrategyError) {
        // returning here in order to not attempt to proceed to the next page
        return handleError(createStrategyError);
      }

      // this seems kind of odd, but what we're doing is replacing the URL with the `new` entityId parameter
      // in the browser history with the correctly parameterized URL for this page, so that if the user hits
      // the back button, they land on this page with the correct data rather than on the "new GRAT creation"
      // page.
      const newEntityId = data?.createEntity?.id ?? '';
      navigate(
        getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER, {
          householdId: householdId,
          entityId: newEntityId,
          entityType: ENTITY_TYPES.GRAT,
          designerStage: GratDesignerStages.BASIC_INFORMATION,
        }),
        { replace: true }
      );

      const structuringPath = getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER,
        {
          householdId,
          entityId: newEntityId,
          entityType: ENTITY_TYPES.GRAT,
          designerStage: GratDesignerStages.STRUCTURING,
        }
      );
      navigate(structuringPath);
    } else {
      if (isDirty && trustData?.id) {
        const doUpdateBeneficiaries = !!dirtyFields.beneficiariesSubform;
        const updateEntityInput: AugmentedUpdateEntityInput = {
          id: entityId,
          update: {},
          updateGratTrust: {
            id: trustData.id,
            update: {
              displayName: values.displayName,
              clearBeneficiaries: doUpdateBeneficiaries,
            },
            ...(dirtyFields.beneficiariesSubform
              ? {
                  withBeneficiaries:
                    generateCreateEntityBeneficiaryMutationInputsv2(
                      beneficiaryOptions,
                      householdId
                    ),
                }
              : {}),
            ...(dirtyFields.grantorClientProfileId
              ? {
                  withGrantor: {
                    create: {
                      individualID: values.grantorClientProfileId,
                    },
                  },
                }
              : {}),
          },
        };

        await updateGRAT({
          variables: {
            input: updateEntityInput,
          },
        });

        // invalidate the cache for entities
        await apolloClient.refetchQueries({
          updateCache(cache) {
            cache.evict({ fieldName: 'entities' });
          },
        });

        if (updateStrategyError) {
          // returning here in order to not attempt to proceed to the next page
          return handleError(updateStrategyError);
        }
      }

      // if we're in a full screen modal, we don't want to transition to a different page here
      // because we'd lose the context of the page we're on, but we do want to give the caller the ability
      // to perform actions after the save is complete
      if (isFullScreenModal) {
        onAfterUpdate && onAfterUpdate();
      } else {
        const structuringPath = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_ENTITY_DESIGNER,
          {
            householdId,
            entityId,
            entityType: ENTITY_TYPES.GRAT,
            designerStage: GratDesignerStages.STRUCTURING,
          }
        );
        navigate(structuringPath);
      }
    }
  };

  const onBasicInformationSubmit = handleSubmit(onValidBasicInformationSubmit);

  const noBeneficiariesOrTrusts = beneficiariesValues.length === 0;

  return (
    <FormProvider {...formProperties}>
      <DesignerLayout
        setNavigationBlocking={setBlockingBehavior}
        entityId={entityId}
        householdId={householdId}
        onFormSubmit={onBasicInformationSubmit}
        hideHeader={isFullScreenModal}
        heading={
          isNewEntity(entityId)
            ? 'Design a new GRAT'
            : trustData?.displayName ?? <Skeleton />
        }
        LeftPaneContent={
          <HeaderCard sx={{ height: '100%' }} heading="Basic information">
            <Box>
              <FormConfigurationProvider
                value={{ optionalDisplayType: 'optional-label' }}
              >
                <FormLayoutRow columns={1}>
                  <FormLayoutItem width={1}>
                    <GRATDisplayNameInput control={control} />
                  </FormLayoutItem>
                </FormLayoutRow>
                <FormLayoutRow columns={1}>
                  <FormLayoutItem width={1}>
                    <FormAwareSelectInput<BasicInformationDesignerForm>
                      label="Grantor name"
                      testId="grantorNameInput"
                      required={true}
                      fieldName="grantorClientProfileId"
                      addNewOption={{
                        onClick: () => setIsGrantorModalOpen(true),
                      }}
                      options={getClientProfileOptions(
                        clientData?.possibleGrantors ?? []
                      )}
                      control={control}
                      startAdornment={
                        <InputAdornment position="start">
                          <User01Icon size={20} />
                        </InputAdornment>
                      }
                    />
                  </FormLayoutItem>
                </FormLayoutRow>
              </FormConfigurationProvider>
            </Box>
          </HeaderCard>
        }
        RightPaneContent={
          <HeaderCard
            sx={{ height: '100%' }}
            heading="Remainder beneficiaries"
            rightHeaderContent={
              !noBeneficiariesOrTrusts && (
                <Box display={'flex'} alignSelf={'end'} marginBottom={2}>
                  <Button
                    onClick={() =>
                      navigate(
                        getCompletePathFromRouteKey(
                          ROUTE_KEYS.HOUSEHOLD_DETAILS_PEOPLE_ORGANIZATIONS,
                          {
                            householdId,
                          }
                        )
                      )
                    }
                    variant="transparent"
                    size="sm"
                  >
                    Manage all
                  </Button>
                </Box>
              )
            }
          >
            <ClientProfileModal
              setIsOpen={setIsGrantorModalOpen}
              isOpen={isGrantorModalOpen}
              clientProfileId={null}
              householdId={householdId}
              clientProfileTypeDisplay="grantor"
              isPrimary={true}
            />
            <BeneficiariesFormComponent
              subformValues={{
                beneficiaries: beneficiariesValues,
              }}
              variant="editableListFancyEmptyState"
              householdId={householdId}
              grantorProfileId={grantorClientProfileId}
            />
            {!noBeneficiariesOrTrusts && (
              <FormLayoutItem width={8}>
                <Callout sx={{ mt: 2 }} severity="warning">
                  GRATs can be an inefficient transfer vehicle for using the
                  grantor&apos;s Generation Skipping Transfer (GST) tax
                  exemption. Many practitioners recommend using a GST non-exempt
                  trust as the remainder beneficiary. Transferring GRAT
                  remainders directly to grandchildren may trigger GST tax.
                </Callout>
              </FormLayoutItem>
            )}
          </HeaderCard>
        }
        FooterActionContent={
          (Footer && <Footer disabled={disableFooter} />) || (
            <BasicInformationDesignerFooter
              householdId={householdId}
              entityId={entityId}
              disabled={disableFooter}
            />
          )
        }
      />
    </FormProvider>
  );
}
