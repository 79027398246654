import { EntityType } from '../types/EntityType';
import { EntityDetail_EntityFragment } from './graphql/EntityDetailPage.generated';

/**
 * @description: This is the type that's passed to the `get{EntityType}SummaryProperties` functions to
 * generate the summary details for the entity, which is then re-used by multiple consumers.
 */
export interface EntityDetailsData {
  entity: EntityDetail_EntityFragment;
  entityType: EntityType;
}

export enum TrustDetailsTabs {
  SUMMARY = 'summary',
  TRUSTEES = 'trustees',
  BENEFICIARIES = 'beneficiaries',
  DISPOSITIONS = 'dispositions',
  POLICIES = 'policies',
}

export enum BusinessEntityDetailsTabs {
  SUMMARY = 'business-entity-summary',
  OWNERSHIP = 'business-entity-ownership',
  KEY_PEOPLE = 'business-entity-key-people',
  POLICIES = 'business-entity-policies',
}

export enum NonTrustCharitableEntityDetailsTabs {
  SUMMARY = 'non-trust-charitable-entity-summary',
  BENEFICIARIES = 'non-trust-charitable-entity-beneficiaries',
  DISPOSITIONS = 'non-trust-charitable-entity-dispositions',
  POLICIES = 'non-trust-charitable-entity-policies',
}

export enum PersonalAccountDetailsTabs {
  SUMMARY = 'personal-account-summary',
  BENEFICIARIES = 'personal-account-beneficiaries',
  DISPOSITIONS = 'personal-account-dispositions',
  POLICIES = 'personal-account-policies',
}
