import { partition } from 'lodash';

import {
  AugmentedUpdateHouseholdInput,
  EstateTaxScenarioPayoutKind,
} from '@/types/schema';
import { assertExact } from '@/utils/assertUtils';

import { EstateTaxScenariosContext } from './contexts/estateTaxScenarios.context';
import { EstateTaxScenarioFormData } from './types';

interface MapInputToFormData {
  context: EstateTaxScenariosContext;
}
export const mapInputToFormData = ({
  context: {
    primaryClients,
    scenariosByPrimaryClientId,
    defaultPayoutOrderByPrimaryClientId,
  },
}: MapInputToFormData): EstateTaxScenarioFormData => {
  const hydratedScenarios = primaryClients.map((g) => {
    const scenario = scenariosByPrimaryClientId[g.id];
    const defaultPayoutOrder = defaultPayoutOrderByPrimaryClientId[g.id];

    return {
      // Field is not used, need to delete from the backend
      // https://linear.app/luminary/issue/T1-211
      displayName: scenario?.displayName ?? 'N/A',
      firstGrantorDeathID: g.id,
      payoutKind: scenario?.payoutKind ?? EstateTaxScenarioPayoutKind.ProRata,
      isExemptionPortable: scenario?.isExemptionPortable ?? true,
      firstDeathPayoutOrder: [
        ...(scenario?.firstDeathPayoutOrder ?? defaultPayoutOrder?.first ?? []),
        ...(scenario?.firstDeathPayoutOrderMissingEntities ?? []),
      ].map((o) => o.id),
      secondDeathPayoutOrder: [
        ...(scenario?.secondDeathPayoutOrder ??
          defaultPayoutOrder?.second ??
          []),
        ...(scenario?.secondDeathPayoutOrderMissingEntities ?? []),
      ].map((o) => o.id),
    };
  });

  return assertExact<EstateTaxScenarioFormData>()({
    scenarios: hydratedScenarios,
  });
};

interface MapFormDataToInput {
  context: EstateTaxScenariosContext;
  formData: EstateTaxScenarioFormData;
}

export const mapFormDataToInput = ({
  context: { household, scenariosByPrimaryClientId },
  formData,
}: MapFormDataToInput): AugmentedUpdateHouseholdInput => {
  const [updates, creates] = partition(
    formData.scenarios,
    (s) => !!scenariosByPrimaryClientId[s.firstGrantorDeathID]
  );

  const updateEstateTaxScenarios: NonNullable<
    AugmentedUpdateHouseholdInput['updateEstateTaxScenarios']
  > = updates.map((formScenario) => {
    // Guaranteed to exist due to partion above
    const scenario =
      scenariosByPrimaryClientId[formScenario.firstGrantorDeathID]!;
    return { id: scenario.id, update: { ...formScenario } };
  });

  const withEstateTaxScenarios: NonNullable<
    AugmentedUpdateHouseholdInput['withEstateTaxScenarios']
  > = creates.map((formScenario) => {
    return { create: { ...formScenario } };
  });

  return assertExact<AugmentedUpdateHouseholdInput>()({
    id: household.id,
    update: {},
    updateEstateTaxScenarios,
    withEstateTaxScenarios,
  });
};
