import { compact } from 'lodash';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { getGrantorRelationships } from '@/modules/clientProfiles/clientProfileUtils';

import { ClientProfileSummaryPanel_ClientProfileFragment } from './graphql/ClientProfileSummaryPanel.generated';

export function getSummaryFields(
  clientProfile?: ClientProfileSummaryPanel_ClientProfileFragment
) {
  const title = compact([
    clientProfile?.firstName,
    clientProfile?.lastName,
  ]).join(' ');

  const location = clientProfile?.address?.cityName
    ? compact([
        clientProfile?.address?.cityName,
        clientProfile?.address?.stateCode,
      ]).join(', ')
    : EMPTY_CONTENT_HYPHEN;

  const grantorRelationships = getGrantorRelationships(
    clientProfile?.relationships
  );

  const fields = {
    title: title,
    legalName: clientProfile?.legalName || EMPTY_CONTENT_HYPHEN,
    email: clientProfile?.email || EMPTY_CONTENT_HYPHEN,
    location: location || EMPTY_CONTENT_HYPHEN,
    grantorRelationships,
  } as const;

  return fields;
}
