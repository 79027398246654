import {
  Box,
  getContrastRatio,
  lighten,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import Decimal from 'decimal.js';

import { Dataflow01Icon } from '@/components/icons/Dataflow01Icon';
import { formatCurrency } from '@/utils/formatting/currency';

interface Props {
  displayName: string;
  gratSummaryDisplay: string;
  initialFundingValue: Decimal;
  grantorRetainedInterest: Decimal;
  calculatedTaxableGift: Decimal;
  rate7520: Decimal;
}

export function GratLeaf({
  displayName,
  gratSummaryDisplay,
  initialFundingValue,
  grantorRetainedInterest,
  calculatedTaxableGift,
  rate7520,
}: Props) {
  const theme = useTheme();

  const getContrastColor = (text: string, background: string) => {
    return getContrastRatio(text, background) > 3
      ? text
      : theme.palette.common.black;
  };

  return (
    <Box
      bgcolor={lighten(theme.palette.dataVisualizationPrimary.lighter, 0.7)}
      padding={2}
      borderRadius={1.5}
    >
      <Stack spacing={0.5} mb={2}>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={1}
          color={theme.palette.grey[500]}
        >
          <Dataflow01Icon size={32} />
        </Stack>
        <Typography
          variant="h5"
          color={getContrastColor(
            theme.palette.dataVisualizationPrimary.dark,
            theme.palette.dataVisualizationPrimary.lighter
          )}
        >
          {displayName}
        </Typography>
        <Typography
          color={getContrastColor(
            theme.palette.dataVisualizationPrimary.dark,
            theme.palette.dataVisualizationPrimary.lighter
          )}
          variant="subtitle2"
        >
          {gratSummaryDisplay}
        </Typography>
      </Stack>
      <Stack
        bgcolor={theme.palette.common.white}
        borderRadius={1.5}
        padding={2}
        spacing={1.5}
      >
        <Box>
          <Typography variant="subtitle2">Initial funding value</Typography>
          <Typography variant="h5">
            {formatCurrency(initialFundingValue)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2">Grantor-retained interest</Typography>
          <Typography variant="h5">
            {formatCurrency(grantorRetainedInterest)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2">Taxable gift amount</Typography>
          <Typography variant="h5">
            {formatCurrency(calculatedTaxableGift)}
          </Typography>
        </Box>
      </Stack>
      <Stack pt={2} alignItems="end">
        <Stack alignItems="end">
          <Typography variant="subtitle2">
            7520 rate of {rate7520.toNumber()}%
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
