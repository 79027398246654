import { isEmpty } from 'lodash';

import { useReportError } from '@/hooks/useReportError';
import { RequeryDocumentRepresentationListDocuments } from '@/modules/files/DocumentRepresentation/DocumentRepresentationList';
import {
  FileUploader,
  NewFileUpload,
} from '@/modules/files/FileUploader/FileUploader';
import { acceptedFileTypes } from '@/modules/files/FileUploader/fileUploaderUtils';
import { CreateFileMutation } from '@/modules/files/FileUploader/graphql/CreateFile.generated';
import { useGenerateSignedFileUploadURL } from '@/modules/files/FileUploader/useGenerateSignedFileUploadURL';
import { UploadedFileData } from '@/modules/files/FileUploader/useUploadFile';
import { CreateDocumentInput } from '@/types/schema';

import { getNewFileUploadFromUploadResult } from '../documentUploaderUtils';
import { useCreateDocumentMutation } from '../graphql/TrustDocuments.generated';

export interface DocumentUploaderProps {
  shouldTriggerRefetch?: boolean;
  householdId: string;
  onDocumentUploadStarted?: () => void;
  onDocumentUploadSuccess?: ({
    documentId,
    fileId,
    documentName,
    downloadUrl,
  }: {
    documentId: string;
    fileId: string;
    documentName: string;
    downloadUrl: string;
  }) => void;
  onDocumentUploadError?: (error: Error) => void;
  errorMessage?: string;
  getCreateDocumentInput: (newFile: NewFileUpload) => CreateDocumentInput;
  disabled?: boolean;
  acceptTypes?: Partial<typeof acceptedFileTypes>;
}

export function DocumentUploader({
  shouldTriggerRefetch = true,
  onDocumentUploadStarted,
  onDocumentUploadError,
  onDocumentUploadSuccess,
  getCreateDocumentInput,
  errorMessage,
  householdId,
  disabled = false,
}: DocumentUploaderProps) {
  const { reportError } = useReportError();
  const { generateUploadURL } = useGenerateSignedFileUploadURL(householdId);
  const [createEntityDocument] = useCreateDocumentMutation();

  async function handleNewDocumentUpload(
    uploaded: UploadedFileData<CreateFileMutation>[]
  ) {
    if (isEmpty(uploaded)) {
      return;
    }

    // We're only handling one file at a time for now.
    const { file, result } = uploaded[0]!;

    try {
      const normalizedFile = getNewFileUploadFromUploadResult(
        file,
        result?.data
      );
      if (!normalizedFile) {
        return;
      }

      const input = getCreateDocumentInput({ ...normalizedFile, householdId });
      await createEntityDocument({
        variables: {
          input,
        },
        refetchQueries: shouldTriggerRefetch
          ? [RequeryDocumentRepresentationListDocuments]
          : [],
        onCompleted: (data) =>
          onDocumentUploadSuccess?.({
            documentId: data.createDocument.id,
            fileId: normalizedFile.fileId,
            documentName: data.createDocument.name,
            downloadUrl: normalizedFile.downloadUrl,
          }),
      });
    } catch (err) {
      reportError('Failed to create document', err as Error);
      onDocumentUploadError && onDocumentUploadError(err as Error);
    }
  }

  return (
    <FileUploader<CreateFileMutation>
      errorMessage={errorMessage}
      generateSignedUploadURL={generateUploadURL}
      onUploadComplete={handleNewDocumentUpload}
      onUploadStarted={onDocumentUploadStarted}
      disabled={disabled}
    />
  );
}
