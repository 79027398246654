import dagre from 'dagre';

import { Node } from '@/components/diagrams/FlowChart';
import {
  buildDagreGraphFromReactFlow,
  buildNodesFromDagreGraph,
  Direction,
} from '@/components/diagrams/FlowChart/utils/dagre';
import { EntityGraphViewOrientation } from '@/types/schema';

import { EntityMapState } from '../types';
import { applyDerivedDataToState } from './applyDerivedDataToState';

const configOrientationToDirection: Record<
  EntityGraphViewOrientation,
  Direction
> = {
  [EntityGraphViewOrientation.TopBottom]: 'top-bottom',
  [EntityGraphViewOrientation.BottomTop]: 'bottom-top',
  [EntityGraphViewOrientation.LeftRight]: 'left-right',
  [EntityGraphViewOrientation.RightLeft]: 'right-left',
};

function hasNonZeroPosition({ position }: Node): boolean {
  return !!(position.x || position.y);
}

/**
 * @description A view config is "fully initialized" as a UI only concept when
 * there are nodes with positions that contain non-zero values
 */
export function hasFullyInitializedNodes(state: EntityMapState) {
  const { derivedData } = applyDerivedDataToState(state);

  // We only check tile nodes, section group position is managed by a different process
  const nodes = derivedData.nodesByType.tile;
  return nodes.length && nodes.some(hasNonZeroPosition);
}

export function applyAutoLayoutToState(state: EntityMapState): EntityMapState {
  const direction =
    configOrientationToDirection[
      state.entityGraphView.orientation ?? EntityGraphViewOrientation.LeftRight
    ];

  const graph = buildDagreGraphFromReactFlow({
    nodes: state.nodes,
    edges: state.edges,
    direction,
  });

  dagre.layout(graph);

  const nodesFromLayout = buildNodesFromDagreGraph({
    nodes: state.nodes,
    getNodePosition: (id) => graph.node(id),
  });

  return { ...state, nodes: nodesFromLayout };
}
