import Decimal from 'decimal.js';
import { Dispatch, SetStateAction } from 'react';

import { EntityKind, LoggedTransferPurpose } from '@/types/schema';
import { PathsOf } from '@/types/subform';
import { UnreachableError } from '@/utils/errors';

import { TransferOption, TransferOptionType } from '../TransferOption.type';
import { NAMESPACE } from './LogNewTransferModal.constants';

export interface LogNewTransferForm {
  [NAMESPACE]: {
    targetId: string;
    targetType: TransferOptionType;
    amount: Decimal | null;
    documentIds: string[];
    transactionDate: Date | null;
    purpose: LoggedTransferPurpose | '';
    otherPurpose: string;
  };
}

export type LogNewTransferFormPaths = PathsOf<LogNewTransferForm>;

export interface TransferModalContext {
  initiatorId: string;
  initiatorKind: TransferOptionType;
  initiatorEntityKind?: EntityKind | null;
  householdId: string;
  transferType: TransferType;
  transferId?: string;
  isInboundDistribution: boolean;
  transferOptions: TransferOption[];
  setTransferOptions: Dispatch<SetStateAction<TransferOption[]>>;
}

export enum TransferType {
  Contribution = 'Contribution',
  Distribution = 'Distribution',
}

export const getTransferTypeDisplay = (transferType: TransferType): string => {
  switch (transferType) {
    case TransferType.Contribution:
      return 'contribution';
    case TransferType.Distribution:
      return 'distribution';
    default:
      throw new UnreachableError({
        case: transferType,
        message: 'Invalid transfer type provided.',
      });
  }
};
