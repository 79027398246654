import Decimal from 'decimal.js';

import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';

import {
  CharitableTrustDesignerBasicInformationForm,
  NAMESPACE,
} from './CharitableTrustDesignerBasicInformation.types';

export const DEFAULT_CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION_FORM: CharitableTrustDesignerBasicInformationForm =
  {
    [NAMESPACE]: {
      id: NEW_PROPOSAL_ID,
      name: '',
      donors: [{ id: '' }],
      taxStatus: '',
      nonCharitableBeneficiaries: [
        { id: '', percentageOwnership: null, kind: null },
      ],
      charitableBeneficiaries: [
        { id: '', percentageOwnership: null, kind: null },
      ],
      _validationFields: {
        charitableBeneficiaries: new Decimal(0),
        nonCharitableBeneficiaries: new Decimal(0),
      },
    },
  };
