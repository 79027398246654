import { Box, BoxProps, SxProps, Theme } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';

import { zIndexes } from '@/styles/zIndexes';

interface FloatingMenuContainerProps extends PropsWithChildren, BoxProps {
  sx?: SxProps<Theme>;
  offset: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
}

function FloatingMenuContainerInner(
  props: FloatingMenuContainerProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { sx = {}, offset, children, ...boxProps } = props;
  return (
    <Box
      sx={{
        height: 'fit-content',
        zIndex: zIndexes.MENU,
        position: 'sticky',
        top: offset?.top,
        bottom: offset?.bottom,
        left: offset?.left,
        right: offset?.right,
        ...sx,
      }}
      {...boxProps}
      ref={ref}
    >
      {children}
    </Box>
  );
}

export const FloatingMenuContainer = forwardRef<
  HTMLDivElement,
  FloatingMenuContainerProps
>(FloatingMenuContainerInner);
