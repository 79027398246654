import { CreateClientProfileInput, CreateHouseholdInput } from '@/types/schema';

export interface ClientBuilderFormShape {
  lastName: string;
  singleGrantor: boolean;
}

const firstNames = [
  'Jane',
  'John',
  'Jamie',
  'David',
  'Daisuke',
  'Charlotte',
  'Mary',
  'Angela',
  'Vik',
  'Brett',
  'Eduardo',
  'Ian',
  'Sushanth',
  'Stephen',
  'Matthew',
  'Clayton',
];

function makeClientProfileInput(
  lastName: string,
  options: {
    isGrantor: boolean;
    isPrimary: boolean;
    isBeneficiary: boolean;
    isTrustee: boolean;
  }
): CreateClientProfileInput {
  return {
    // get random element from the firstNames array
    firstName:
      firstNames[Math.floor(Math.random() * firstNames.length)] ??
      'Random name',
    lastName: lastName,
    address: {
      cityName: 'San Francisco',
      stateCode: 'CA',
    },
    ...options,
  };
}

export function getInputsForFormValues(
  formValues: ClientBuilderFormShape,
  currentUserId?: string | null
): CreateHouseholdInput {
  const clientProfileInputs = [
    // two grantors, one of which is a bene as well
    makeClientProfileInput(formValues.lastName, {
      isGrantor: true,
      isPrimary: true,
      isBeneficiary: false,
      isTrustee: true,
    }),
    makeClientProfileInput(formValues.lastName, {
      isGrantor: formValues.singleGrantor ? false : true,
      isPrimary: formValues.singleGrantor ? false : true,
      isBeneficiary: true,
      isTrustee: true,
    }),

    // two trustees
    makeClientProfileInput(formValues.lastName, {
      isGrantor: false,
      isPrimary: false,
      isBeneficiary: false,
      isTrustee: true,
    }),
    makeClientProfileInput(formValues.lastName, {
      isGrantor: false,
      isPrimary: false,
      isBeneficiary: false,
      isTrustee: true,
    }),

    // two beneficiaries
    makeClientProfileInput(formValues.lastName, {
      isGrantor: false,
      isPrimary: false,
      isBeneficiary: true,
      isTrustee: false,
    }),
    makeClientProfileInput(formValues.lastName, {
      isGrantor: false,
      isPrimary: false,
      isBeneficiary: true,
      isTrustee: false,
    }),
  ];

  return {
    primaryRelationshipOwnerID: currentUserId ?? '',
    profiles: clientProfileInputs.map((input) => ({
      clientProfile: input,
    })),
  };
}
