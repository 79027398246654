import { RecordExemptionUsedForm } from './RecordExemptionUsedModal.types';

export const NAMESPACE = 'recordExemptionUsedModal';

export const defaultValues = {
  [NAMESPACE]: {
    grantor: {
      clientProfileId: '',
    },
    asOfDate: null,
    additionalLifetimeExemptionApplied: null,
    additionalGstExemptionApplied: null,
    assetType: null,
    notes: '',
    documentIds: [] as string[],
  },
} as const satisfies RecordExemptionUsedForm;
