import { Typography } from '@mui/material';
import { compact, includes } from 'lodash';

import {
  GRAT_IMPLEMENTATION_TASKS,
  GRATImplementationTask,
} from '@/modules/tasks/taskTypes';
import { EntityTaskCompletionState, EntityTaskType } from '@/types/schema';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import {
  GetImplementationTasksV2Query,
  ImplementationPage_EntityFragment,
  ImplementationTaskV2Fragment,
} from './graphql/ImplementationTasks.generated';
import { TaskListItemStatus } from './TaskList';

export enum ImplementationTabs {
  EXECUTION = 'execution',
  SETUP = 'setup',
}

export const tabConfigurations = [
  {
    display: 'Document execution',
    value: ImplementationTabs.EXECUTION,
  },
  {
    display: 'Trust setup',
    value: ImplementationTabs.SETUP,
  },
];

export const IMPLEMENTATION_TASK_TYPE_TO_TAB_NAME: {
  [K in GRATImplementationTask]: ImplementationTabs;
} = {
  [EntityTaskType.GratCompleteDesignSummary]: ImplementationTabs.EXECUTION,
  [EntityTaskType.GratAttorneySelection]: ImplementationTabs.EXECUTION,
  [EntityTaskType.GratShareDesignSummary]: ImplementationTabs.EXECUTION,
  [EntityTaskType.GratUploadTrustDocument]: ImplementationTabs.EXECUTION,
  [EntityTaskType.GratConfirmTrustDetails]: ImplementationTabs.EXECUTION,
  [EntityTaskType.GratCreateTrustAccount]: ImplementationTabs.SETUP,
  [EntityTaskType.GratConfirmFunding]: ImplementationTabs.SETUP,
  [EntityTaskType.GratConfirmAnnuities]: ImplementationTabs.SETUP,
  [EntityTaskType.GratConfirmAssetTransfer]: ImplementationTabs.SETUP,
  [EntityTaskType.GratSetSubstitutionThresholds]: ImplementationTabs.SETUP,
  [EntityTaskType.GratFinalizeImplementation]: ImplementationTabs.SETUP,
};

export interface DenormalizedImplementationTask {
  id: string;
  status: TaskListItemStatus;
  tab: ImplementationTabs;
  householdId: string;
  entityId: string;
  entitySubtypeId: string;
  body: ImplementationTaskV2Fragment;
  type: EntityTaskType;

  // heading and subheading are for the body of the task in the right pane
  heading: string;
  subheading: string;

  // shortheading and statusSummary are used for the task list on the left
  shortHeading: string;
  statusSummary: React.ReactElement;
}

function getTaskValidity(implementationTask: ImplementationTaskV2Fragment) {
  switch (implementationTask.completionState) {
    case EntityTaskCompletionState.Incomplete:
      return TaskListItemStatus.NONE;
    case EntityTaskCompletionState.CompleteWithErrors:
      return TaskListItemStatus.INVALID;
    case EntityTaskCompletionState.Cancelled:
    // there's no scenario where this happens here; assuming it's okay
    // to let it fall through to "complete"
    default:
      return TaskListItemStatus.COMPLETED;
  }
}

export function getDenormalizedTasks(
  entityTasks: GetImplementationTasksV2Query['entityTasks']['edges'],
  entity: ImplementationPage_EntityFragment | null,
  householdId: string
): DenormalizedImplementationTask[] {
  if (!entity) return [];
  return compact(
    entityTasks?.flatMap((task) => {
      if (!task?.node) return [];
      const taskType = task.node.type;
      // this shouldn't happen, but it's not impossible because we don't filter at the query level; regardless,
      // we'd never want to show non-implementation task types in implementation
      if (!includes(GRAT_IMPLEMENTATION_TASKS, taskType)) return null;
      const tab =
        IMPLEMENTATION_TASK_TYPE_TO_TAB_NAME[
          taskType as GRATImplementationTask
        ];
      return {
        id: task.node.id,
        entityId: entity.id,
        entitySubtypeId: entity.subtype.id,
        householdId,
        type: taskType,
        status: getTaskValidity(task.node),
        tab,
        statusSummary: generateSubheadingForTask(task.node),
        body: task.node,
        heading: task.node.title,
        subheading: task.node.description,
        shortHeading: task.node.shortTitle,
      };
    }) ?? []
  );
}

function generateSubheadingText(task: ImplementationTaskV2Fragment): {
  display: string;
  isOverdue?: boolean;
} {
  if (task.completedAt) {
    const formattedCompletedDate = formatDateToMonDDYYYY(task.completedAt);
    if (task.completedBy) {
      return {
        display: `Completed ${formattedCompletedDate} by ${task.completedBy.nameWithLastInitial}`,
      };
    }
    return { display: `Completed on ${formattedCompletedDate}` };
  }

  const formattedDueDate = formatDateToMonDDYYYY(task.dueAt);
  const isOverdue = task.dueAt < new Date();
  if (task.assignedTo) {
    return {
      display: `Due ${formattedDueDate} | ${task.assignedTo.nameWithLastInitial}`,
      isOverdue,
    };
  }
  return { display: `Due ${formattedDueDate}`, isOverdue };
}

export function generateSubheadingForTask(task: ImplementationTaskV2Fragment) {
  const { display, isOverdue } = generateSubheadingText(task);
  return (
    <Typography
      variant="subtitle2"
      component="span"
      color={isOverdue ? 'error' : undefined}
    >
      {display}
    </Typography>
  );
}
