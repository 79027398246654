/* eslint-disable react/display-name */
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';

import { Avatar, AvatarProps } from '@/components/Avatar';

import { CellContainer } from '../../components/cells';
import { CellRenderProp } from '../../types';
import { getCellRenderProp } from '../../utils/getRenderProp';

interface AvatarRendererProps<
  R extends GridValidRowModel,
  V,
  F,
  N extends GridTreeNodeWithRender,
> {
  avatarProps?: CellRenderProp<R, V, F, N, Partial<AvatarProps>>;
}

export const AvatarRenderer = <
  R extends GridValidRowModel = GridValidRowModel,
  V extends string = string,
  F = V,
  N extends GridTreeNodeWithRender = GridTreeNodeWithRender,
>(
  factoryProps?: AvatarRendererProps<R, V, F, N>
) => {
  return (props: GridRenderCellParams<R, V, F, N>) => {
    const avatarProps = getCellRenderProp({
      props,
      prop: factoryProps?.avatarProps,
      defaultValue: {},
    });

    return (
      <CellContainer align={props.colDef.align}>
        <Avatar size="xs" initials="" fullName="" {...avatarProps} />
      </CellContainer>
    );
  };
};
