import { compact, includes, isEmpty, map } from 'lodash';

import { BASIC_ASSETS_SUBFORM } from '@/modules/entities/BasicAssetsSubform/BasicAssetsSubform.types';
import {
  ALLOW_MULTIPLE_PRINCIPALS_TYPES,
  BUSINESS_ENTITY_TYPES,
} from '@/modules/entities/entities.constants';
import { makeAccountInput } from '@/modules/entities/EntitySubforms/utils/shared/accounts.utils';
import { getTypeOrUndefined } from '@/modules/entities/EntitySubforms/utils/shared/common.utils';
import { estateInclusionStatusToApiTypeMap } from '@/modules/entities/EntitySubforms/utils/shared/taxStatus.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  AugmentedCreateAccountInput,
  AugmentedCreatePrincipalInput,
  CreateEntityInput,
  EntityAttributionSource,
  EntityStage,
} from '@/types/schema';

import { OWNERSHIP_PERCENTAGE_ENTITY_TYPES } from '../CreateEntityShortForm.constants';
import {
  CREATE_ENTITY_SHORT_FORM_NAMESPACE,
  EntityShortFormShape,
} from '../CreateEntityShortForm.types';

export function getCreateEntityInputCommonProps(
  formInput: EntityShortFormShape,
  householdId: string
): CreateEntityInput {
  const integrationEntityIds = getTypeOrUndefined<string[]>(
    formInput[BASIC_ASSETS_SUBFORM]?.basicAssets?.integrationEntityIds
  );
  return {
    householdID: householdId,
    stage: EntityStage.Active,
    attributionSource: EntityAttributionSource.IntakeForm,
    integrationEntityIDs: compact(integrationEntityIds),
    addeparLinkedToNongrantorEntity: (() => {
      // we never want to set this to true if there's no integrationEntityId
      if (isEmpty(integrationEntityIds)) return undefined;
      return getTypeOrUndefined<boolean>(
        formInput[BASIC_ASSETS_SUBFORM]?.basicAssets?.linkToAllAddeparEntities
      );
    })(),
    documentIDs: formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE]?.documentIds,
    defaultDocumentID:
      formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE]?.defaultDocumentId,
  };
}

export type PrincipalSelectionPattern =
  | 'singleGrantor'
  | 'multipleGrantor'
  | 'multipleGrantorAndEntity';

export function getPrincipalSelectionPattern(
  entityType: EntityType
): PrincipalSelectionPattern {
  if (includes(ALLOW_MULTIPLE_PRINCIPALS_TYPES, entityType)) {
    return 'multipleGrantor';
  }

  if (includes(BUSINESS_ENTITY_TYPES, entityType)) {
    return 'multipleGrantorAndEntity';
  }

  return 'singleGrantor';
}

export function entityTypeAllowsMultipleSelection(entityType: EntityType) {
  return principalPatternAllowsMultipleSelection(
    getPrincipalSelectionPattern(entityType)
  );
}

export function principalPatternAllowsMultipleSelection(
  pattern: PrincipalSelectionPattern
): boolean {
  return (
    pattern === 'multipleGrantor' || pattern === 'multipleGrantorAndEntity'
  );
}

export function getSelectedPrincipalsFromForm(
  principalsInput: EntityShortFormShape['createEntityShortForm']['principals']
) {
  return compact(
    map(principalsInput, (principal, clientProfileId) => {
      if (!principal.isIncluded) return null;
      return {
        clientProfileId,
        ownershipPercentage: principal.ownershipPercentage,
      };
    })
  );
}

export function getSelectedClientProfileIdsFromForm(
  multiplePrincipalsValue:
    | EntityShortFormShape['createEntityShortForm']['principals']
    | undefined,
  singlePrincipalsValue:
    | EntityShortFormShape['createEntityShortForm']['selectedSinglePrincipal']
    | undefined,
  entityType: EntityType
): string[] {
  if (entityTypeAllowsMultipleSelection(entityType)) {
    if (!multiplePrincipalsValue) return [];
    return map(
      getSelectedPrincipalsFromForm(multiplePrincipalsValue),
      (principal) => {
        return principal.clientProfileId;
      }
    );
  }

  return compact([singlePrincipalsValue]);
}

export function makeCreateMultiplePrincipalInputs(
  formInput: EntityShortFormShape
): AugmentedCreatePrincipalInput[] {
  const selectedPrincipals = getSelectedPrincipalsFromForm(
    formInput.createEntityShortForm.principals
  );

  // just make sure that ownership percentage isn't being incorrectly assigned; this shouldn't happen but form
  // state can be complicated to reason about, so better safe than sorry
  const shouldPersistOwnershipPercentage = includes(
    OWNERSHIP_PERCENTAGE_ENTITY_TYPES,
    formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].entityType
  );

  return selectedPrincipals.map(({ clientProfileId, ownershipPercentage }) => {
    return {
      create: {
        individualID: clientProfileId,
        ownershipPercentage: shouldPersistOwnershipPercentage
          ? ownershipPercentage
          : null,
      },
    };
  });
}

/**
 * @description asserts that there is exactly one SinglePrincipalInput in the array and returns it
 */
export function getSinglePrincipalInput(
  formInput: EntityShortFormShape
): AugmentedCreatePrincipalInput {
  if (!formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].selectedSinglePrincipal) {
    throw new Error(
      `Unexpected number of principal inputs for entity type ${formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].entityType}`
    );
  }

  return {
    create: {
      individualID:
        formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].selectedSinglePrincipal,
      ownershipPercentage: null,
    },
  };
}

export function makeCreateAccountInput(
  formInput: EntityShortFormShape
): AugmentedCreateAccountInput {
  return makeAccountInput(formInput);
}

export function getCreateSubtypeInputCommonProps(
  formInput: EntityShortFormShape
) {
  return {
    displayName: formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].name,
    inEstateStatus:
      estateInclusionStatusToApiTypeMap[
        formInput.createEntityShortForm.estateInclusionStatus
      ],
  };
}
