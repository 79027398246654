import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { getNodes } from '@/utils/graphqlUtils';
import { getTenant } from '@/utils/tenantUtils';

import {
  ClientImportExport_HouseholdFragment,
  useCloneCardAdvisorClientsQuery,
} from './graphql/ClientImportExport.generated';

export function useClientData() {
  // because there can be 10k+ tenants in test, and because we don't use this tool in test,
  // we skip the query in test
  // another approach could be to paginate this, but this is easier for now
  const isTest = getTenant() === 'test';
  const { data, ...queryProps } = useCloneCardAdvisorClientsQuery({
    skip: isTest,
  });

  const clientsData = useMemo(() => {
    if (!data) return null;
    return getNodes(data.households);
  }, [data]);

  return { clientsData, ...queryProps };
}

export function getClientsOptions(
  clients: ClientImportExport_HouseholdFragment[] | null
): SelectInputOption<string>[] {
  return (
    clients?.map((c) => ({
      value: c.id,
      display: c.displayName,
    })) ?? []
  );
}
