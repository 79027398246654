import { ErrorCode, FileRejection } from 'react-dropzone';

import {
  BACKGROUND_IMAGE_PROPERTY_VALUE,
  generateBrandedBackgroundWithColor,
} from '@/components/utils/brandedBackgroundUtils';
import { COLORS } from '@/styles/tokens/colors';
import * as diagnostics from '@/utils/diagnostics';

export type BackgroundStyleState = 'normal' | 'error' | 'hover';
export function getBackgroundStyle(state: BackgroundStyleState): {
  border?: string;
  backgroundImage?: string;
  background?: string;
} {
  switch (state) {
    case 'normal': {
      return { background: BACKGROUND_IMAGE_PROPERTY_VALUE };
    }
    case 'error': {
      return {
        border: `solid ${COLORS.ORANGE[200]} 1px`,
        backgroundImage: generateBrandedBackgroundWithColor(),
      };
    }
    case 'hover': {
      return {
        backgroundImage: generateBrandedBackgroundWithColor(COLORS.GRAY[600]),
      };
    }
    default:
      throw new Error(`Unhandled background state: ${state}`);
  }
}

function buf2hex(buffer: ArrayBuffer) {
  return Array.prototype.map
    .call(new Uint8Array(buffer), (x: number) =>
      ('00' + x.toString(16)).slice(-2)
    )
    .join('');
}

export async function getSha256HashFromFile(file: File) {
  const arrayBuffer = await file.arrayBuffer();
  const sha256Hash = await crypto.subtle.digest('SHA-256', arrayBuffer);
  return buf2hex(sha256Hash);
}

// https://react-dropzone.org/#!/Accepting%20specific%20file%20types
export const acceptedFileTypes = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/tiff': ['.tiff'],
  'image/gif': ['.gif'],
  'image/png': ['.png'],
  'image/bmp': ['.bmp'],
  'text/plain': ['.txt'],

  'application/vnd.apple.pages': ['.pages'],
  'application/vnd.apple.keynote': ['.key'],
  'application/vnd.apple.numbers': ['.numbers'],

  'application/pdf': ['.pdf'],

  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],

  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],

  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],

  'application/oxps': ['.xps', '.oxps'],
  'application/vnd.ms-xpsdocument': ['.xps', '.oxps'],
  'application/csv': ['.csv'],
};

/**
 * A subset of acceptedFileTypes that are supported for document extraction.
 */
export const AI_ACCEPTED_FILE_TYPES: Partial<typeof acceptedFileTypes> = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],

  'text/plain': ['.txt'],

  'application/pdf': ['.pdf'],

  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
};

export function getErrorMessageFromCode(rejection: FileRejection): string {
  const firstError = rejection.errors[0];
  switch (firstError?.code) {
    case ErrorCode.FileInvalidType:
      return `The file you're trying to upload isn't a valid type.`;
    case ErrorCode.FileTooLarge:
      return `The file you're trying to upload is too large.`;
    case ErrorCode.FileTooSmall:
      return `The file you're trying to upload is too small.`;
    case ErrorCode.TooManyFiles:
      return `Please upload one file at a time.`;
    default:
      diagnostics.error(
        `unhandled file upload error type`,
        new Error(`Unhandled file upload error type ${firstError?.code}`),
        {
          fileType: rejection.file.type,
          fileSize: rejection.file.size,
        }
      );
      return 'There was an error uploading your file. Please try again.';
  }
}
