import { DispositiveProvisions_DispositionScenarioFragment } from '@/modules/dispositiveProvisions/graphql/DispositiveProvisions.fragments.generated';

import { EntityPresentation_EntityFragment } from '../graphql/EntityPresentation.generated';

export function getGuardedEntityDetails(
  entity: EntityPresentation_EntityFragment | null
) {
  if (!entity)
    return {
      entityDetails: null,
      subtypeDetails: null,
      dispositionScenariosDetails: [],
    };

  const subtypeDetails = (() => {
    if (!entity) return null;
    return entity.subtype;
  })();

  const dispositionScenariosDetails = (() => {
    if (!entity?.dispositionScenariosSubtype) return [];
    // this value is shared across all entity subtypes, but i can't think of a safe way to access
    // it here without validating every subtype typename
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return (entity?.dispositionScenariosSubtype?.dispositionScenarios ??
      []) as DispositiveProvisions_DispositionScenarioFragment[];
  })();

  return {
    entityDetails: entity,
    subtypeDetails,
    dispositionScenariosDetails,
  };
}
