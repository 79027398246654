import { Stack } from '@mui/material';
import { useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Building07Icon } from '@/components/icons/Building07Icon';
import { User01Icon } from '@/components/icons/User01Icon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { ClientOrganizationModal } from '@/modules/clientOrganizations/ClientOrganizationModal';
import { ClientProfileModal } from '@/modules/clientProfiles/ClientProfileForm/ClientProfileModal';
import { EntityDocumentViewerDocument } from '@/modules/documents/components/HouseholdDocumentsViewer/graphql/EntityDocumentViewer.generated';
import { COLORS } from '@/styles/tokens/colors';
import { UnreachableError } from '@/utils/errors';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  householdId: string;
  onAfterCreate?: (newId: string) => void;
}

export function TrusteeSelectorModal({
  handleClose,
  isOpen,
  householdId,
  onAfterCreate,
}: Props) {
  const [isAddingTrustee, setIsAddingTrustee] = useState(false);
  const [isAddingOrganization, setIsAddingOrganization] = useState(false);

  const handleAdd = (typeToAdd: 'individual' | 'organization') => {
    handleClose();
    if (typeToAdd === 'individual') {
      setIsAddingTrustee(true);
    } else if (typeToAdd === 'organization') {
      setIsAddingOrganization(true);
    } else {
      throw new UnreachableError({
        case: typeToAdd,
        message: `Unexpected trustee type: ${typeToAdd}`,
      });
    }
  };

  return (
    <>
      {isAddingTrustee && (
        <ClientProfileModal
          isOpen={isAddingTrustee}
          setIsOpen={setIsAddingTrustee}
          householdId={householdId}
          clientProfileId={null}
          clientProfileTypeDisplay="trustee"
          forceTrusteeIfNoClientProfileId
          ignoredQueryDocuments={[EntityDocumentViewerDocument]}
          onAfterCreate={onAfterCreate}
        />
      )}

      {isAddingOrganization && (
        <ClientOrganizationModal
          householdId={householdId}
          setIsOpen={setIsAddingOrganization}
          isOpen={isAddingOrganization}
          organizationId={null}
          requirement="mustBeTrustee"
          ignoredQueryDocuments={[EntityDocumentViewerDocument]}
          onAfterCreate={onAfterCreate}
        />
      )}

      <DialogModal
        isOpen={isOpen}
        onClose={handleClose}
        heading="What would you like to add?"
      >
        <Stack spacing={3}>
          <Stack direction="row" spacing={3} justifyContent="space-between">
            <ActionCard
              sx={{ flexGrow: 1, minWidth: 250 }}
              description="Individual"
              icon={<User01Icon size={70} color={COLORS.GRAY[400]} />}
              actions={
                <Button
                  onClick={() => handleAdd('individual')}
                  variant="primary"
                  size="lg"
                  fullWidth
                >
                  Add new
                </Button>
              }
            />
            <ActionCard
              sx={{ flexGrow: 1, minWidth: 250 }}
              description="Organization"
              icon={<Building07Icon size={70} color={COLORS.GRAY[400]} />}
              actions={
                <Button
                  onClick={() => handleAdd('organization')}
                  variant="primary"
                  size="lg"
                  fullWidth
                >
                  Add new
                </Button>
              }
            />
          </Stack>
        </Stack>
      </DialogModal>
    </>
  );
}
