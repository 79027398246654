import Decimal from 'decimal.js';
import { compact } from 'lodash';

import { formatPercent } from '@/utils/formatting/percent';

import { ClientPresentationBundleTypes } from '../clientPresentation/clientPresentation.types';
import { PresentationSlideRegistrationProps } from '../presentation.types';
import { generateBundleId, getFinalSlideIndex } from '../presentation.utils';

function generateWaterfallSlideId(
  entityId: string,
  slideType: 'summary' | 'diagram' | 'tax-summary' | 'beneficiaries'
) {
  return compact(['slide', entityId, slideType]).join('_');
}

export function generateWaterfallBundleId(waterfallId: string) {
  return generateBundleId(
    ClientPresentationBundleTypes.ESTATE_WATERFALL,
    waterfallId
  );
}

interface GetWaterfallSlidePropsOpts {
  localSlideTypeIndex?: number;
}

export function getPresentationWaterfallSlideProps(
  waterfallId: string,
  slideType: 'summary' | 'diagram' | 'tax-summary' | 'beneficiaries',
  opts: GetWaterfallSlidePropsOpts = {}
): PresentationSlideRegistrationProps {
  const { localSlideTypeIndex } = opts;
  // this index describes the order of the slides within the bundle,
  // not in the entire presentation
  const globalSlideTypeIndex =
    ['summary', 'diagram', 'tax-summary', 'beneficiaries'].indexOf(slideType) *
    100;
  const finalIndex = getFinalSlideIndex(
    globalSlideTypeIndex,
    localSlideTypeIndex
  );
  const slideId = generateWaterfallSlideId(waterfallId, slideType);

  return {
    bundleId: generateWaterfallBundleId(waterfallId),
    bundleIndex: finalIndex,
    slideId,
  };
}

interface ModelingDisclaimerTextParams {
  estateTaxRateAssumption: Decimal;
  estateTaxExemptionGrowthAssumption: Decimal;
  includeExemptionSunset: boolean;
}

export function getModelingDisclaimerText({
  estateTaxRateAssumption,
  estateTaxExemptionGrowthAssumption,
  includeExemptionSunset,
}: ModelingDisclaimerTextParams) {
  const estateTaxRate = formatPercent(estateTaxRateAssumption, 0);
  const exemptionGrowth = formatPercent(estateTaxExemptionGrowthAssumption, 2);

  // this should result in something like:
  // In estate assets in excess of the Federal estate tax exemption are assumed to be taxed at 40%. The Federal estate tax exemption is modeled to increase annually by {0%}, and is modeled to “sunset”, or be reduced to half of the exemption amount at the end of 2025.
  const sunsetText = ` and is modeled to “sunset”, or be reduced to half of the exemption amount at the end of 2025.`;
  return `In estate assets in excess of the Federal estate tax exemption are assumed to be taxed at ${estateTaxRate}%. The Federal estate tax exemption is modeled to increase annually by ${exemptionGrowth}%${
    includeExemptionSunset ? sunsetText : '.'
  }`;
}
