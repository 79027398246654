import { EmptyState } from './components/EmptyState';
import { EstateWaterfallContextContainer } from './components/EstateWaterfallContextContainer';
import { EstateWaterfallFlowChartWrapper } from './components/EstateWaterfallFlowChartWrapper';
import { MemoizedNodeSummaryPanel } from './components/NodeSummaryPanel';
import { useEstateWaterfallContext } from './contexts/estateWaterfall.context';
import { EstateWaterfallFlowChart } from './EstateWaterfallFlowChart';
import { EstateWaterfallProps } from './types';

export const EstateWaterfall = ({
  height,
  width,
  householdId,
  waterfallId,
}: EstateWaterfallProps) => {
  const { getState } = useEstateWaterfallContext();
  const { waterfall, presentationMode, isEmptyWaterfall } = getState();

  return (
    <EstateWaterfallContextContainer height={height} width={width}>
      <EstateWaterfallFlowChartWrapper
        height={height}
        presentationMode={presentationMode}
      >
        {isEmptyWaterfall ? (
          <EmptyState householdId={householdId} />
        ) : (
          waterfall && (
            <EstateWaterfallFlowChart
              waterfallId={waterfallId}
              householdId={householdId}
              waterfall={waterfall}
              presentationMode={presentationMode}
            />
          )
        )}
      </EstateWaterfallFlowChartWrapper>
      <MemoizedNodeSummaryPanel
        waterfallId={waterfallId}
        householdId={householdId}
        style={{ height }}
      />
    </EstateWaterfallContextContainer>
  );
};
