import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';
import { StructuredAssetsSubform_ValuationFragment } from '@/modules/entities/StructuredAssetsSubform/graphql/StructuredAssetsSubform.generated';
import { STRUCTURED_ASSETS_SUBFORM_NAMESPACE } from '@/modules/entities/StructuredAssetsSubform/StructuredAssetsSubform.types';
import { AssetValuationV2ValuationSource } from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatDateToMMDDYY } from '@/utils/formatting/dates';

import {
  FullScreenStructuredAssetsModalForm,
  FullScreenStructuredAssetsModalFormPath,
} from './FullScreenStructuredAssetsModal.types';
import { getIntegratedAssetsFromValuation } from './FullScreenStructuredAssetsModal.utils';
import { useValuationIntegrationDetails } from './hooks/useValuationLinkDetails';

interface ValuationDateFieldProps {
  valuation: StructuredAssetsSubform_ValuationFragment | null;
}

export function ValuationDateField({ valuation }: ValuationDateFieldProps) {
  const { control } = useFormContext<FullScreenStructuredAssetsModalForm>();
  const { isIntegratedScenarioDirty, isIntegratedScenario } =
    useValuationIntegrationDetails();
  const [formAssetValueSum, allowAdditionalManualAssets] = useWatch({
    control: control,
    name: [
      `${STRUCTURED_ASSETS_SUBFORM_NAMESPACE}._assetsValueSum`,
      'allowAdditionalManualAssets',
    ],
  });

  const totalAssetValueSum = useMemo(() => {
    // this is kind of annoying, but we need to show a live total of the combination of the manual assets
    // in the form and the integrated assets. so, we actually need to calculate the sum of the integrated assets
    // and append it to the sum of the manual assets in the form.
    if (
      isIntegratedScenario &&
      valuation?.valuationSource !== AssetValuationV2ValuationSource.Manual
    ) {
      const integratedAssets = getIntegratedAssetsFromValuation(valuation);
      const integratedAssetsSum = sumDecimalJS(
        integratedAssets.map((a) => a.assetValue.ownedValue ?? new Decimal(0))
      );
      if (!allowAdditionalManualAssets) {
        return integratedAssetsSum;
      }
      return integratedAssetsSum.plus(formAssetValueSum);
    }
    return formAssetValueSum;
  }, [
    allowAdditionalManualAssets,
    formAssetValueSum,
    isIntegratedScenario,
    valuation,
  ]);

  const isEditableValuationDate = !isIntegratedScenario;

  // We don't have any kind of date-related information to show here; the valuation date
  // will be created when we get the initial sync.
  const isIntegratedScenarioWithoutValuation =
    isIntegratedScenario && !valuation;

  // If there have been changes to the integration setup, but we're still in an integrated state,
  // we don't want to show the date
  const isNotYetSyncedIntegrationScenario =
    isIntegratedScenarioDirty && isIntegratedScenario;
  if (
    isNotYetSyncedIntegrationScenario ||
    isIntegratedScenarioWithoutValuation
  ) {
    return null;
  }

  return (
    <Card variant="filled-callout" sx={{ p: 2 }}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="end"
        justifyContent="flex-end"
      >
        <Typography variant="h1" component="span">
          {formatCurrencyNoDecimals(totalAssetValueSum)}
        </Typography>
        <Typography
          variant="label"
          component="span"
          sx={{ pb: 0.25, flexShrink: 0 }}
        >
          as of
          {!isEditableValuationDate && valuation
            ? ` ${formatDateToMMDDYY(valuation?.effectiveDate)}`
            : ''}
        </Typography>
        {isEditableValuationDate && (
          <Box width={175}>
            <FormAwareDatePickerInput
              control={control}
              label="Valuation date"
              required
              hideLabel
              fieldName={
                `valuationAsOfDate` as const satisfies FullScreenStructuredAssetsModalFormPath
              }
            />
          </Box>
        )}
      </Stack>
    </Card>
  );
}
