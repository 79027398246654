import Decimal from 'decimal.js';

import { AssetClassLiquidityStatus } from '@/types/schema';
import { PathsOf } from '@/types/subform';

export const NEW_ASSET_CATEGORY_SENTINEL = '__NEW_ASSET_CATEGORY__';

export interface AssetCategory {
  integrationCategoryIds: string[];
  _isSystemClass: boolean;
  name: string;
  liquidityStatus: AssetClassLiquidityStatus;
  growthRate: Decimal;
}

export interface AssetCategoriesForm {
  currentlyEditingRowId: string | null;
  assetCategoriesById: Record<string, AssetCategory>;
}

export type AssetCategoriesFormPaths = PathsOf<AssetCategoriesForm>;

export interface RowData {
  /** this is a special property that indicates what value to show when dragging to reorder */
  __reorder__: string;
  id: string;
  name: string;
  isSystemClass: boolean;

  // these others are just here to represent the fact that there are columns for each row that represent this concept.
  // because these values are editable, we read/write to them from the form state.
  integrationCategories: null;
  growthRate: null;
  liquidity: null;
  action: unknown;
}
