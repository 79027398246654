import { useMemo, useState } from 'react';

import { Node } from '@/components/diagrams/FlowChart';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useReportError } from '@/hooks/useReportError';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { getNodes } from '@/utils/graphqlUtils';
import { isEntityId } from '@/utils/pulid';

import { EntityMap_HouseholdFragment } from '../graphql/EntityMap.generated';
import { useEntityMapTotalValueMetricsQuery } from '../graphql/EntityMapTotalValueMetrics.generated';
import { parseNamespacedNodeId } from '../utils/normalize';

interface UseTotalValueProps {
  nodes: Node[];
  household?: EntityMap_HouseholdFragment | null;
}

interface TotalValueDisplayMetrics {
  inEstateTotal: string;
  outOfEstateTotal: string;
  totalValue: string;
}

export function useTotalValue({ nodes, household }: UseTotalValueProps) {
  const { createErrorFeedback } = useFeedback();
  const { reportError } = useReportError();

  const [totalValueMetrics, setTotalValueMetrics] =
    useState<TotalValueDisplayMetrics>({
      inEstateTotal: EMPTY_CONTENT_HYPHEN,
      outOfEstateTotal: EMPTY_CONTENT_HYPHEN,
      totalValue: EMPTY_CONTENT_HYPHEN,
    });

  const excludedEntityIds = useMemo(() => {
    const entityIdsInMap = new Set(
      nodes.flatMap((node) => {
        const id = parseNamespacedNodeId(node.id);
        return isEntityId(id) ? id : [];
      })
    );

    const clientEntities = getNodes(household?.entities);
    const clientEntityIds = clientEntities.map((entity) => entity.id);

    return clientEntityIds.filter(
      (clientEntityId) => !entityIdsInMap.has(clientEntityId)
    );
  }, [household?.entities, nodes]);

  const householdId = household?.id;

  const queryProps = useEntityMapTotalValueMetricsQuery({
    variables: {
      where: {
        id: householdId,
      },
      entityWhere: {
        idNotIn: excludedEntityIds,
      },
    },
    onCompleted: (data) => {
      const fetchedAdvisorClient = getNodes(data.households)[0];

      if (!fetchedAdvisorClient) {
        const errDescription = 'Could not get household total value metrics';
        const err = new Error(errDescription);
        reportError(errDescription, err, {
          householdId,
        });
        throw err;
      }

      const { inEstateValue, outOfEstateValue, totalEstateValue } =
        fetchedAdvisorClient.estateValueMetrics;

      setTotalValueMetrics({
        inEstateTotal: formatCurrencyNoDecimals(inEstateValue),
        outOfEstateTotal: formatCurrencyNoDecimals(outOfEstateValue),
        totalValue: formatCurrencyNoDecimals(totalEstateValue),
      });
    },
    onError: createErrorFeedback(
      'Sorry, we failed to load the entity map. Please refresh the page and try again.'
    ),
  });

  return { ...totalValueMetrics, ...queryProps };
}
