import { useApolloClient } from '@apollo/client';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { first, isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Upload01Icon } from '@/components/icons/Upload01Icon';
import { UploadCloud01Icon } from '@/components/icons/UploadCloud01Icon';
import { EmptyListActionCard } from '@/components/lists/EmptyListActionCard';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { TableEmptyState } from '@/components/tables/DataTable/components/TableEmptyState';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { useReportError } from '@/hooks/useReportError';
import { useAICapabilitiesEnabled } from '@/modules/tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { DocumentType, DocumentWhereInput } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { useMultiDocumentUploaderContext } from '../MultiDocumentUploader/context/multiDocumentUploader.context';
import { MultiDocumentUploaderWithListModal } from '../MultiDocumentUploader/MultiDocumentUploaderWithListModal';
import { RichDocumentUploader } from '../RichDocumentUploader/RichDocumentUploader';
import { DocumentsTableRowData } from './DocumentsTable.types';
import {
  DocumentsTableDocumentsDocument,
  useDocumentsTableDocumentsQuery,
} from './graphql/DocumentsTable.generated';
import { useMapDocumentsToRowsAndColumns } from './hooks/useMapDocumentsToRowsAndColumns';

interface DocumentsTableProps {
  householdId: string;
  documentsLike: DocumentWhereInput;

  // if you want to force associations between created documents and a specific entity
  // or strategy, pass the appropriate ID along with the appropriate variant
  entityId?: string;

  // createDocumentModalOpen and handleCreateDocumentModalClose are used to
  // allow an external component to handle exposing their own "create document"
  // button and open the model from within here.
  createDocumentModalOpen?: boolean;
  handleCreateDocumentModalClose?: () => void;
}

const QUERY_NAME = 'DocumentsTableDocuments';

export function DocumentsTable({
  householdId,
  entityId,
  documentsLike,
  createDocumentModalOpen,
  handleCreateDocumentModalClose,
}: DocumentsTableProps) {
  const apolloClient = useApolloClient();
  const { createErrorFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const aiCapabilitiesEnabled = useAICapabilitiesEnabled();
  const navigate = useNavigate();
  const [documentModalOpen, setDocumentModalOpen] = useState(false);
  const [documentModalEditingDocumentId, setDocumentModalEditingDocumentId] =
    useState<null | string>(null);
  const { clearUploadedFiles, uploadDocuments } =
    useMultiDocumentUploaderContext();
  const [loading, setLoading] = useState<boolean>(false);

  const onEditClick = useCallback((documentId: string) => {
    setDocumentModalEditingDocumentId(documentId);
    setDocumentModalOpen(true);
  }, []);

  // sync the state between the external createDocumentModalOpen prop and the
  // local documentModalOpen state, and guard against the (unlikley) scenario
  // where the external button is clicked while the modal is already open
  useEffect(() => {
    if (!createDocumentModalOpen || documentModalOpen) return;
    setDocumentModalOpen(true);
  }, [documentModalOpen, createDocumentModalOpen, setDocumentModalOpen]);

  const {
    data,
    loading: loadingDocuments,
    refetch,
  } = useDocumentsTableDocumentsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      where: documentsLike,
    },
    onError: createErrorFeedback(
      `We weren't able to load your documents. Please refresh the page to try again.`
    ),
  });

  const { columns, rows } = useMapDocumentsToRowsAndColumns(
    getNodes(data?.documents),
    {
      onEditClick,
      refetch,
      hideGoToEntityActionItem: Boolean(entityId),
      entityId,
    }
  );

  const onRowClick = useCallback(
    ({ row }: GridRowParams<DocumentsTableRowData>) => {
      navigate(`./${row.id}`);
    },
    [navigate]
  );

  const onSubmit = useCallback(async () => {
    setLoading(true);
    let documentIds: string[] = [];
    const suggestionsEnabled = Boolean(entityId) && aiCapabilitiesEnabled;
    try {
      documentIds = await uploadDocuments({
        householdId,
        suggestionsEnabled,
        defaultDocumentType: DocumentType.Other,
        entityId,
      });

      clearUploadedFiles();
      setDocumentModalOpen(false);
      handleCreateDocumentModalClose?.();

      // after upload, refresh the table contents
      await apolloClient.refetchQueries({
        include: [QUERY_NAME],
      });

      const firstDocumentId = first(documentIds);

      if (documentIds.length === 1 && firstDocumentId) {
        if (entityId) {
          navigate(
            getCompletePathFromRouteKey(
              ROUTE_KEYS.HOUSEHOLD_ENTITY_DOCUMENT_DETAILS,
              {
                householdId,
                entityId,
                documentId: firstDocumentId,
              }
            )
          );
        } else {
          navigate(
            getCompletePathFromRouteKey(
              ROUTE_KEYS.HOUSEHOLD_DETAILS_DOCUMENT_DETAILS,
              {
                householdId,
                documentId: firstDocumentId,
              }
            )
          );
        }
      }
    } catch (e) {
      reportError('Caught error while uploading documents', e as Error);
      showFeedback('Error uploading documents.  Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [
    aiCapabilitiesEnabled,
    apolloClient,
    clearUploadedFiles,
    entityId,
    handleCreateDocumentModalClose,
    householdId,
    navigate,
    reportError,
    showFeedback,
    uploadDocuments,
  ]);

  return (
    <>
      <RichDocumentUploader
        isOpen={Boolean(documentModalEditingDocumentId)}
        documentId={documentModalEditingDocumentId}
        onClose={() => {
          setDocumentModalEditingDocumentId(null);
          void apolloClient.refetchQueries({
            include: [DocumentsTableDocumentsDocument],
          });
        }}
        householdId={householdId}
        entityId={entityId}
      />
      <MultiDocumentUploaderWithListModal
        isOpen={documentModalOpen}
        onSubmit={onSubmit}
        onClose={() => {
          clearUploadedFiles();
          setDocumentModalOpen(false);
          handleCreateDocumentModalClose?.();
        }}
        householdId={householdId}
        uploaderTextHeader="Upload all documents for this client to get started"
        uploaderTextSubheader="Verify document types are correct for each uploaded file. You’ll review & confirm extracted details after documents are processed"
        loading={loading}
      />
      {!loadingDocuments && isEmpty(rows) ? (
        <EmptyListActionCard
          buttonOnClick={() => setDocumentModalOpen(true)}
          buttonText="Upload document"
          buttonStartIcon={Upload01Icon}
          icon={UploadCloud01Icon}
          heading="Upload documents"
          description="Store all of your client's estate planning documents and automatically summarize trust documents with Luminary AI"
        />
      ) : (
        <DataTable
          rows={rows}
          onRowClick={onRowClick}
          columns={columns}
          loading={loadingDocuments}
          pageSize="long"
          initialState={{
            columns: {
              columnVisibilityModel: {
                associatedEntity: !entityId,
              },
            },
          }}
          slots={{
            noRowsOverlay: () => <TableEmptyState text="No documents" />,
          }}
        />
      )}
    </>
  );
}
