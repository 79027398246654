import { Box } from '@mui/material';
import { GridOverlay } from '@mui/x-data-grid';

import { TableRowSkeleton } from '@/components/tables/TableRowSkeleton/TableRowSkeleton';

import { useBalanceSheetTableColumns } from './BalanceSheetTable.columns';

export function BalanceSheetTableLoadingOverlay() {
  const columns = useBalanceSheetTableColumns(null, undefined); // Pass null since data is loading
  const columnCount = columns.length;

  return (
    <GridOverlay>
      <Box sx={{ width: '100%' }}>
        <TableRowSkeleton columns={columnCount} />
        <TableRowSkeleton columns={columnCount} />
        <TableRowSkeleton columns={columnCount} />
        <TableRowSkeleton columns={columnCount} />
        <TableRowSkeleton columns={columnCount} />
        <TableRowSkeleton columns={columnCount} />
      </Box>
    </GridOverlay>
  );
}
