// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAnnuityPaymentsAndCompleteTaskMutationVariables = Types.Exact<{
  taskID: Types.Scalars['ID']['input'];
  input: Types.AugmentedUpdateEntityInput;
}>;


export type UpdateAnnuityPaymentsAndCompleteTaskMutation = { __typename?: 'Mutation', updateEntity: { __typename?: 'Entity', id: string, gratTrust?: { __typename?: 'GRATTrust', id: string, annuities?: Array<{ __typename?: 'GRATAnnuityV2', id: string, termDurationYears: number, yearOfTerm: number, initialFundingValuePercent: Percent, computedPaymentAmount: CurrencyUSD }> | null } | null }, completeEntityTask: { __typename?: 'EntityTask', id: string } };


export const UpdateAnnuityPaymentsAndCompleteTaskDocument = gql`
    mutation UpdateAnnuityPaymentsAndCompleteTask($taskID: ID!, $input: AugmentedUpdateEntityInput!) {
  updateEntity(input: $input) {
    id
    gratTrust {
      id
      annuities {
        id
        termDurationYears
        yearOfTerm
        initialFundingValuePercent
        computedPaymentAmount
      }
    }
  }
  completeEntityTask(id: $taskID) {
    id
  }
}
    `;
export type UpdateAnnuityPaymentsAndCompleteTaskMutationFn = Apollo.MutationFunction<UpdateAnnuityPaymentsAndCompleteTaskMutation, UpdateAnnuityPaymentsAndCompleteTaskMutationVariables>;

/**
 * __useUpdateAnnuityPaymentsAndCompleteTaskMutation__
 *
 * To run a mutation, you first call `useUpdateAnnuityPaymentsAndCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnnuityPaymentsAndCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnnuityPaymentsAndCompleteTaskMutation, { data, loading, error }] = useUpdateAnnuityPaymentsAndCompleteTaskMutation({
 *   variables: {
 *      taskID: // value for 'taskID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAnnuityPaymentsAndCompleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnnuityPaymentsAndCompleteTaskMutation, UpdateAnnuityPaymentsAndCompleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnnuityPaymentsAndCompleteTaskMutation, UpdateAnnuityPaymentsAndCompleteTaskMutationVariables>(UpdateAnnuityPaymentsAndCompleteTaskDocument, options);
      }
export type UpdateAnnuityPaymentsAndCompleteTaskMutationHookResult = ReturnType<typeof useUpdateAnnuityPaymentsAndCompleteTaskMutation>;
export type UpdateAnnuityPaymentsAndCompleteTaskMutationResult = Apollo.MutationResult<UpdateAnnuityPaymentsAndCompleteTaskMutation>;
export type UpdateAnnuityPaymentsAndCompleteTaskMutationOptions = Apollo.BaseMutationOptions<UpdateAnnuityPaymentsAndCompleteTaskMutation, UpdateAnnuityPaymentsAndCompleteTaskMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateAnnuityPaymentsAndCompleteTask: 'UpdateAnnuityPaymentsAndCompleteTask' as const
  }
}