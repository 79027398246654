import { ApolloClient } from '@apollo/client';
import Decimal from 'decimal.js';
import { compact, defaults, isNull, omitBy } from 'lodash';

import { InsurancePolicyKind } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { EntityType } from '../types/EntityType';
import {
  InsurancePolicies_InsurancePolicyFragment,
  InsurancePoliciesDocument,
  InsurancePoliciesQuery,
} from './graphql/InsurancePolicies.generated';
import {
  defaultPolicy,
  InsurancePolicyDetails,
  InsurancePolicyDetailsSubformType,
  VariantType,
} from './InsurancePolicyDetailsSubform.types';

function mapInitialPolicyIDs(
  policies: InsurancePolicies_InsurancePolicyFragment[]
): string[] {
  return policies.map((policy) => policy.id);
}

function mapPolicy(
  policy: InsurancePolicies_InsurancePolicyFragment
): InsurancePolicyDetails {
  const policyHolderIDs = compact(
    policy.policyHolders?.map<string>((holder) => holder?.id)
  );

  if (!policyHolderIDs.length) {
    policyHolderIDs.push('');
  }
  if (
    policyHolderIDs.length === 1 &&
    policy.kind !== InsurancePolicyKind.Term
  ) {
    policyHolderIDs.push('');
  }

  const documentIds = compact(
    policy.documents?.map<string>((document) => document?.id)
  );
  const output: InsurancePolicyDetails = defaults(
    omitBy(
      {
        policyId: policy.id,
        policyType: policy.kind,
        isConvertible: policy.survivorship,
        conversionDate: policy.conversionDate,
        insuranceCarrier: policy.carrierName,
        policyNumber: policy.policyNumber,
        policyHolderIDs,
        deathBenefitAmount: policy.deathBenefitAmount,
        policyStartDate: policy.startDate,
        termLength: policy.termDurationYears,
        premiumAmount: policy.premiumAmount,
        premiumFrequency: policy.premiumFrequency,
        premiumInitialDueDate: policy.initialPremiumDueDate,
        notes: policy.notes,
        documentIds,
        initialDocumentIDs: documentIds,
        initialPolicyHolderIDs: policyHolderIDs,
        cashValue: policy.cashValue,
        cashValueDate: policy.cashValueDate,
        loanBalanceOutstanding: policy.loanBalanceOutstanding,
      },
      isNull
    ),
    defaultPolicy
  );

  if (policy.kind === InsurancePolicyKind.Term) {
    output.isConvertible = !!output.conversionDate;
  }
  return output;
}

function dataToForm(
  data: InsurancePoliciesQuery
): InsurancePolicyDetailsSubformType {
  if (data.node?.__typename !== 'Entity') {
    throw new Error('Unexpected node type');
  }

  if (!('policies' in data.node.subtype)) {
    throw new Error(`Unexpected subtype ${data.node.subtype.__typename}`);
  }

  const crummeyWithdrawalPeriodDays: Decimal | null =
    data?.node?.subtype?.__typename === 'ILITTrust' &&
    !!data.node.subtype.crummeyWithdrawalPeriodDays
      ? new Decimal(data?.node.subtype.crummeyWithdrawalPeriodDays)
      : null;

  const policies: InsurancePolicies_InsurancePolicyFragment[] =
    data?.node?.subtype?.policies || [];

  return policies.length
    ? {
        policies: policies.map(mapPolicy),
        initialPolicyIDs: mapInitialPolicyIDs(policies),
        crummeyWithdrawalPeriodDays,
      }
    : {
        policies: [{ ...defaultPolicy }],
        initialPolicyIDs: [],
        crummeyWithdrawalPeriodDays,
      };
}

export async function insurancePolicyDataFetcher(
  apolloClient: ApolloClient<object>,
  nodeId: string
): Promise<InsurancePolicyDetailsSubformType> {
  const { data, error } = await apolloClient.query<InsurancePoliciesQuery>({
    query: InsurancePoliciesDocument,
    variables: { nodeId },
  });

  if (error) {
    diagnostics.error(
      'Could not fetch data for insurance policies form',
      error
    );
    throw error;
  }
  return dataToForm(data);
}

export function getInsurancePolicyVariant(type: EntityType): VariantType {
  if (type === 'ilit') {
    return 'ilit';
  }

  // Currently, this is just ilit
  return 'default';
}
