import { useCallback, useContext } from 'react';

import { ContextualHelpContext } from '@/modules/content/components/ContextualHelpContext';

export function useWaterfallTipsAndTricks() {
  const contextualHelpCtx = useContext(ContextualHelpContext);
  if (!contextualHelpCtx) {
    throw new Error(
      'ContextualHelpDrawer must be used inside a ContextualHelpProvider.'
    );
  }

  const openWaterfallTipsAndTricks = useCallback(() => {
    contextualHelpCtx.setContextualHelpId('waterfallTipsAndTricks');
  }, [contextualHelpCtx]);

  return {
    openWaterfallTipsAndTricks,
  };
}
