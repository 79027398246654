// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLoggedTransferMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateLoggedTransferInput;
}>;


export type CreateLoggedTransferMutation = { __typename?: 'Mutation', createLoggedTransfer: { __typename?: 'LoggedTransfer', id: string } };


export const CreateLoggedTransferDocument = gql`
    mutation CreateLoggedTransfer($input: AugmentedCreateLoggedTransferInput!) {
  createLoggedTransfer(input: $input) {
    id
  }
}
    `;
export type CreateLoggedTransferMutationFn = Apollo.MutationFunction<CreateLoggedTransferMutation, CreateLoggedTransferMutationVariables>;

/**
 * __useCreateLoggedTransferMutation__
 *
 * To run a mutation, you first call `useCreateLoggedTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLoggedTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLoggedTransferMutation, { data, loading, error }] = useCreateLoggedTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLoggedTransferMutation(baseOptions?: Apollo.MutationHookOptions<CreateLoggedTransferMutation, CreateLoggedTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLoggedTransferMutation, CreateLoggedTransferMutationVariables>(CreateLoggedTransferDocument, options);
      }
export type CreateLoggedTransferMutationHookResult = ReturnType<typeof useCreateLoggedTransferMutation>;
export type CreateLoggedTransferMutationResult = Apollo.MutationResult<CreateLoggedTransferMutation>;
export type CreateLoggedTransferMutationOptions = Apollo.BaseMutationOptions<CreateLoggedTransferMutation, CreateLoggedTransferMutationVariables>;
export const Operations = {
  Mutation: {
    CreateLoggedTransfer: 'CreateLoggedTransfer' as const
  }
}