import { Box, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { Divider } from '@/components/Divider';
import { EmptyListItem } from '@/components/typography/EmptyListItem';
import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

const padding = '12px';

const useStyles = makeStyles()((_theme) => ({
  root: {
    breakInside: 'avoid',
  },
  headingRoot: {
    padding: '12px',
  },
  headingText: {},
  childContainer: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  childWrapper: {
    padding: `0 ${padding}`,
  },
  divider: {},
}));

export type HeaderListVariant = 'light' | 'dark';

export interface HeaderList {
  heading?: string;
  variant?: HeaderListVariant;
  belowHeadingControl?: JSX.Element;
  classes?: WithClasses<typeof useStyles>;
  noItemsText?: React.ReactNode;
  'data-testid'?: string;
}

const variantToColors: Record<
  HeaderListVariant,
  { headerBackgroundColor: string; textColor: string }
> = {
  light: {
    headerBackgroundColor: COLORS.GRAY[100],
    textColor: COLORS.GRAY[500],
  },
  dark: {
    headerBackgroundColor: COLORS.GRAY[500],
    textColor: COLORS.PRIMITIVES.WHITE,
  },
};

export function HeaderList({
  heading,
  children,
  variant = 'light',
  belowHeadingControl,
  noItemsText,
  ...props
}: React.PropsWithChildren<HeaderList>) {
  const { classes } = useStyles(undefined, { props });
  const variantColors = useMemo(() => variantToColors[variant], [variant]);
  // we want to filter out null children so we can render an empty list item if there are no items
  const validChildren = React.Children.toArray(children).filter(Boolean);
  return (
    <Stack gap={1} className={classes.root} data-testid={props['data-testid']}>
      {heading && (
        <Box
          component="header"
          sx={{ backgroundColor: variantColors.headerBackgroundColor }}
          className={classes.headingRoot}
        >
          <Typography
            variant="h6"
            color={variantColors.textColor}
            component="div"
            className={classes.headingText}
          >
            {heading}
          </Typography>
        </Box>
      )}
      {belowHeadingControl && <Box mb={1}>{belowHeadingControl}</Box>}
      <Stack
        gap={1}
        className={classes.childContainer}
        divider={<Divider className={classes.divider} />}
        component="ul"
      >
        {validChildren.map((child, i) => (
          <Box component="li" key={i} className={classes.childWrapper}>
            {child}
          </Box>
        ))}
        {validChildren.length === 0 && noItemsText && (
          <EmptyListItem>{noItemsText}</EmptyListItem>
        )}
      </Stack>
    </Stack>
  );
}
