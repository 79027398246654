import { createValueEnum } from '@/types/definitionsUtils';

export const REMAINDER_TYPE_ITEMS = [
  { display: 'Taxable gift', value: 'TAXABLE_GIFT' },
  { display: '"Zeroed-out"', value: 'ZEROED_OUT' },
] as const;

export type RemainderType = (typeof REMAINDER_TYPE_ITEMS)[number]['value'];
export const REMAINDER_TYPE_OPTIONS = REMAINDER_TYPE_ITEMS.slice(); // slice to make it non-readonly
export const REMAINDER_TYPE_VALUES = createValueEnum<RemainderType>(
  REMAINDER_TYPE_OPTIONS
);

export const ANNUITY_GROWTH_TYPE_ITEMS = [
  { display: 'Fixed', value: 'FIXED' },
  { display: 'Increasing', value: 'INCREASING' },
] as const;

export type AnnuityGrowthType =
  (typeof ANNUITY_GROWTH_TYPE_ITEMS)[number]['value'];
export const ANNUITY_GROWTH_TYPE_OPTIONS = ANNUITY_GROWTH_TYPE_ITEMS.slice(); // slice to make it non-readonly
export const ANNUITY_GROWTH_TYPE_VALUES = createValueEnum<AnnuityGrowthType>(
  ANNUITY_GROWTH_TYPE_OPTIONS
);
