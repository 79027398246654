import { keyBy } from 'lodash';
import { useContext, useMemo } from 'react';

import { diagnostics } from '@/utils/diagnostics';

import { FeatureFlagName } from './featureFlags.types';
import { FeatureFlagsContext } from './FeatureFlagsContext';

const dependentFlags: Partial<Record<FeatureFlagName, FeatureFlagName[]>> = {};

export function useFeatureFlag(name: FeatureFlagName): boolean {
  const featureFlags = useContext(FeatureFlagsContext);
  return useMemo(() => {
    if (featureFlags.length === 0) {
      diagnostics.debug(
        "Attempting to read feature flags before they're populated"
      );
      return false;
    }

    const flagsByName = keyBy(featureFlags, (flag) => flag.name);
    const flag = flagsByName[name];

    if (flag === undefined) {
      diagnostics.warn('Feature flag does not exist', { name });
      return false;
    }

    const everyDependencyMet = !dependentFlags[name]?.some(
      (flag) => !flagsByName[flag]?.flagEvaluationResult
    );

    if (
      flag.flagEvaluationResult &&
      dependentFlags[name] &&
      !everyDependencyMet
    ) {
      diagnostics.debug(`Found unmet feature flag dependencies for ${name}`);
    }

    return flag.flagEvaluationResult && everyDependencyMet;
  }, [featureFlags, name]);
}

/**
 * useAreFeatureFlagsPopulated is useful if you need to use feature flag logic
 * during app bootstrapping and there's a possibility that you may need to rerun
 * some initialization once the flags are populated.
 */
export function useAreFeatureFlagsPopulated(): boolean {
  const featureFlags = useContext(FeatureFlagsContext);
  return featureFlags.length !== 0;
}
