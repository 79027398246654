import { useCallback } from 'react';

import { useFlowChartContext } from '../../context/flowChart.context';
import { InternalFlowChartProps } from '../../types';

/**
 * @description Wrapper around node drag stop to set our drop target to null or the target
 */
export function useInternalOnNodeDragStop({
  onNodeDragStop: onNodeDragStopProp,
}: InternalFlowChartProps): InternalFlowChartProps['onNodeDragStop'] {
  const {
    __internal: { setDropTargetNode },
  } = useFlowChartContext();
  const onNodeDragStop = useCallback<
    NonNullable<InternalFlowChartProps['onNodeDragStop']>
  >(
    (...args) => {
      setDropTargetNode(null);
      onNodeDragStopProp?.(...args);
    },
    [onNodeDragStopProp, setDropTargetNode]
  );

  return onNodeDragStop;
}
