import { compact } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useMatches } from 'react-router-dom';
import { useTitle } from 'react-use';

import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

type UnextendedMatch = ReturnType<typeof useMatches>[0];

export interface Match extends UnextendedMatch {
  handle: {
    title?: string;
  };
}

const DEFAULT_TITLE = 'Luminary';

export function RouteWatcher({ children }: PropsWithChildren<unknown>) {
  const location = useLocation();
  const posthog = usePostHog();
  const [title, setTitle] = useState(DEFAULT_TITLE);

  const { displayName: clientName } = useHouseholdDetailsContext();

  const matches = useMatches() as Match[];

  useEffect(() => {
    let title: string | null = null;

    // Match to the most specific specified title
    matches.forEach((match) => {
      if (match.handle?.title) {
        title = match.handle.title;
      }
    });

    setTitle(compact([DEFAULT_TITLE, clientName, title]).join(' | '));
  }, [clientName, matches]);

  // Track pageviews
  useEffect(() => {
    if (posthog) {
      posthog.capture('$pageview', {
        $current_url: window.location.href,
      });
    }
  }, [location, posthog]);

  useTitle(title);

  return <>{children}</>;
}
