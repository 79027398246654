import { useMemo } from 'react';

import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath, EntityInEstateStatus } from '@/types/schema';

import {
  getIsCharitableNode,
  getWaterfallItemName,
} from '../../EstateWaterfall.utils';
import {
  EstateWaterfall_NodeFragment,
  EstateWaterfallFragment,
} from '../../graphql/EstateWaterfall.generated';
import { useEstateWaterfallSummaryBarChartsSectionColors } from '../EstateWaterfallSummaryBarCharts/hooks/useEstateWaterfallSummaryBarChartsSectionColors.hook';
import { EstateWaterfallSummaryBarChartsSections } from '../EstateWaterfallSummaryBarCharts/types';
import { MultiSelectTableRow } from './EstateWaterfallSectionMultiSelectTable.utils';

interface MapVizNodeToTableItemInput {
  node: EstateWaterfall_NodeFragment;
  colorChoices: Record<EstateWaterfallSummaryBarChartsSections, string>;
  grantorName?: string;
}

function mapVizNodeToTableItem({
  node: vizNode,
  colorChoices,
  grantorName,
}: MapVizNodeToTableItemInput): MultiSelectTableRow {
  const colorForNode = (() => {
    const estateStatus = vizNode.inEstateStatus;
    if (estateStatus === EntityInEstateStatus.OutOfEstate) {
      const isCharitableNode = getIsCharitableNode(vizNode.node);

      if (isCharitableNode) {
        return colorChoices[
          EstateWaterfallSummaryBarChartsSections.OutOfEstateCharity
        ];
      } else {
        // Out of estate family includes all other entities, accounts, and
        // individuals
        return colorChoices[
          EstateWaterfallSummaryBarChartsSections.OutOfEstateFamily
        ];
      }
    }

    return colorChoices[EstateWaterfallSummaryBarChartsSections.InEstate];
  })();

  return {
    node: vizNode,
    afterDeath: vizNode.afterDeath,
    id: vizNode.id,
    sectionIndicator: {
      backgroundColor: colorForNode,
    },
    entity: getWaterfallItemName(vizNode, grantorName),
  };
}

export function useEstateWaterfallSectionMultiSelectTableRows(
  waterfall: EstateWaterfallFragment | null,
  grantors: ContextPrimaryClient[],
  defaultSelectedRowIds: string[]
): Record<AfterDeath, MultiSelectTableRow[]> {
  const colorChoices = useEstateWaterfallSummaryBarChartsSectionColors();

  return useMemo(() => {
    const firstDeathGrantor = grantors.find(
      (g) => g.id === waterfall?.firstGrantorDeath.id
    );
    const secondDeathGrantor = grantors.find(
      (g) => g.id !== waterfall?.firstGrantorDeath.id
    );

    // This is the version of the visualization to use in order to construct all the rows.
    const viz = waterfall?.visualizationWithProjections;

    const nodes = viz?.nodes ?? [];

    const currentEligible = nodes.filter(
      (n) => n.afterDeath === AfterDeath.None
    );
    const firstEligible = nodes.filter(
      (n) => n.afterDeath === AfterDeath.First
    );
    const secondEligible = nodes.filter(
      (n) => n.afterDeath === AfterDeath.Second
    );

    const putDefaultSelectedItemsFirst = (
      a: MultiSelectTableRow,
      b: MultiSelectTableRow
    ) => {
      const aIsSelected = defaultSelectedRowIds.includes(a.id);
      const bIsSelected = defaultSelectedRowIds.includes(b.id);

      if (aIsSelected && !bIsSelected) {
        return -1;
      }

      if (!aIsSelected && bIsSelected) {
        return 1;
      }

      return 0;
    };

    return {
      [AfterDeath.None]: [
        ...currentEligible.map((node) =>
          mapVizNodeToTableItem({
            node,
            colorChoices,
          })
        ),
      ].sort(putDefaultSelectedItemsFirst),
      [AfterDeath.First]: [
        ...firstEligible.map((node) =>
          mapVizNodeToTableItem({
            node,
            colorChoices,
            grantorName: firstDeathGrantor?.displayName,
          })
        ),
      ].sort(putDefaultSelectedItemsFirst),
      [AfterDeath.Second]: [
        ...secondEligible.map((node) =>
          mapVizNodeToTableItem({
            node,
            colorChoices,
            grantorName: secondDeathGrantor?.displayName,
          })
        ),
      ].sort(putDefaultSelectedItemsFirst),
    };
  }, [
    colorChoices,
    defaultSelectedRowIds,
    grantors,
    waterfall?.firstGrantorDeath.id,
    waterfall?.visualizationWithProjections,
  ]);
}
