import { Box, Grid } from '@mui/material';

import { FOOTER_HEIGHT } from '@/components/navigation/Footer';

export interface FullScreenTaskLayoutProps {
  LeftPaneContent: React.ReactElement;
  RightPaneContent: React.ReactElement;
  Footer: React.ReactElement;
}

export function FullScreenTaskLayout({
  LeftPaneContent,
  RightPaneContent,
  Footer,
}: FullScreenTaskLayoutProps) {
  return (
    <Box>
      <Grid
        container
        height={`calc(100vh - ${FOOTER_HEIGHT}px)`}
        overflow="auto"
        columnSpacing={3}
        p={3}
      >
        <Grid item sm={4}>
          {LeftPaneContent}
        </Grid>
        <Grid item sm={8}>
          {RightPaneContent}
        </Grid>
      </Grid>
      <Box>{Footer}</Box>
    </Box>
  );
}
