import {
  Divider as MUIDivider,
  DividerProps as MUIDividerProps,
} from '@mui/material';
import { useMemo } from 'react';

import { COLORS } from '@/styles/tokens/colors';

export interface DividerProps extends MUIDividerProps {
  noMargin?: boolean;
}

export function Divider({ sx, noMargin, light, ...otherProps }: DividerProps) {
  const finalSx = useMemo(() => {
    return {
      ...(sx ?? {}),
      ...(noMargin ? { margin: '0px !important' } : {}),
      ...(light
        ? {
            backgroundColor: COLORS.PRIMITIVES.WHITE,
          }
        : {}),
    };
  }, [sx, noMargin, light]);

  return <MUIDivider sx={finalSx} {...otherProps} />;
}
