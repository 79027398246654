import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { CellContainer } from '@/components/tables/DataTable/components/cells';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { ProposalKind } from '@/modules/proposal/types/ProposalKind';
import { UnreachableError } from '@/utils/errors';
import { getFormattedDate } from '@/utils/formatting/getFormattedDate';

import { mapProposalsToRows } from './utils';

function getProposalType(kind: ProposalKind) {
  switch (kind) {
    case ProposalKind.Entity:
      return 'GRAT';
    case ProposalKind.Gift:
      return 'Gift';
    case ProposalKind.CLT:
      return 'CLT';
    case ProposalKind.CRT:
      return 'CRT';
    default:
      throw new UnreachableError({
        case: kind,
        message: `Unexpected proposal kind: ${kind}`,
      });
  }
}

export const columns: Column<
  ReturnType<typeof mapProposalsToRows>[0] & { chevron: unknown }
>[] = [
  {
    field: 'displayName',
    headerName: 'Client proposal',
    width: 175,
    flex: 1,
    valueGetter: ({ row }) => {
      return row.displayName.lineOne;
    },
    renderCell: TwoLineTextRenderer({
      lineOne: ({ row }) => {
        return row.displayName.lineOne;
      },
      lineOneProps: {
        variant: 'h5',
      },
      lineTwo: ({ row }) => {
        return row.displayName.lineTwo;
      },
    }),
  },
  {
    field: 'kind',
    headerName: 'Proposal type',
    width: 175,
    renderCell: TextRenderer({
      text: ({ row }) => getProposalType(row.kind),
    }),
  },
  {
    field: 'updatedAt',
    headerName: 'Last edited',
    width: 200,
    renderCell: TextRenderer({
      text: ({ row }) => getFormattedDate(row.updatedAt),
    }),
  },
  {
    field: 'createdAt',
    headerName: 'Created on',
    width: 150,
    renderCell: TextRenderer({
      text: ({ row }) => getFormattedDate(row.createdAt),
    }),
  },
  {
    field: 'actions',
    headerName: '',
    width: 150,
    align: 'center',
    sortable: false,
    renderCell: ({ row, colDef }) => (
      <CellContainer align={colDef.align}>{row.actions}</CellContainer>
    ),
  },
  {
    field: 'chevron',
    headerName: '',
    align: 'center',
    width: 64,
    minWidth: 64,
    maxWidth: 64,
    renderCell: IconRenderer({
      icon: ChevronRightIcon,
    }),
    sortable: false,
  },
];
