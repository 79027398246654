import { CommonAiSuggestionEvidenceFragment } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { DocumentSearchResult } from '@/modules/pdf/DocumentSearch/DocumentSearch.types';

// Somehow, it has come to be that the backend passes a slightly different
// evidence schema, to different use-cases.
// This adapter is used to normalize the "wrong" type into the "right" type.
// I (linda), will fix, but for now, we'll adapt.
// Examples:
//    [WRONG]: { pageNumber: 1, polygon: [{ x: 1, y: 1 }, { x: 2, y: 2 }] }
//    [RIGHT]: { PageNumber: 1, Polygon: [{ x: 1, y: 1 }, { x: 2, y: 2 }] }
export function evidenceAdapter(
  input: CommonAiSuggestionEvidenceFragment,
  documentId: string
): DocumentSearchResult {
  return {
    PageNumber: input.pageNumber,
    Polygon: input.polygon,
    documentID: documentId,
  } as DocumentSearchResult;
}
