import { BrandingColorInputProps } from './BrandingColorInput';

export const colorInputDefinitionsPrimary: Pick<
  BrandingColorInputProps,
  'heading' | 'subheading' | 'fieldName'
>[] = [
  {
    heading: 'Dark brand color',
    subheading: 'Background color that white text can be overlaid onto',
    fieldName: 'primaryColor',
  },
  {
    heading: 'Button & link color',
    subheading:
      'Used as the background color of buttons and the text color of links.',
    fieldName: 'buttonsLinkColor',
  },
];

export const colorInputDefinitionsDataviz: Pick<
  BrandingColorInputProps,
  'heading' | 'subheading' | 'fieldName'
>[] = [
  {
    heading: 'Main data visualization',
    subheading: 'Prominent colors on graphs',
    fieldName: 'dataVisualizationPrimaryColor',
  },
  {
    heading: 'Secondary data visualization',
    subheading: 'Secondary prominent colors on graphs',
    fieldName: 'dataVisualizationSecondaryColor',
  },
  {
    heading: 'Tertiary data visualization',
    subheading: 'Tertiary prominent colors on graphs',
    fieldName: 'dataVisualizationTertiaryColor',
  },
  {
    heading: 'Negative data visualization',
    subheading: 'Loss- or tax-related colors on graphs',
    fieldName: 'dataVisualizationNegativeColor',
  },
];

export const defaultFormValues = {
  primaryColor: '#FFFFFF',
  secondaryColor: '#FFFFFF',
  dataVisualizationPrimaryColor: '#FFFFFF',
  dataVisualizationSecondaryColor: '#FFFFFF',
  dataVisualizationTertiaryColor: '#FFFFFF',
  dataVisualizationNegativeColor: '#FFFFFF',
  buttonsLinkColor: '#FFFFFF',
};
