import { Box } from '@mui/material';
import { useMemo } from 'react';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { TableEmptyState } from '@/components/tables/DataTable/TableEmptyState';

import { useManageDispositionsContext } from '../context/ManageDispositions.context';
import {
  mapTestamentaryEntitiesToRows,
  useTestamentaryEntitiesTableColumns,
} from './ManageDispositionsTestamentaryEntitiesTable.utils';

export function ManageDispositionsTestamentaryEntitiesTable() {
  const columns = useTestamentaryEntitiesTableColumns();
  const { testamentaryEntities, isLoading, activeClientDeathId } =
    useManageDispositionsContext();

  const rows = useMemo(
    () =>
      mapTestamentaryEntitiesToRows(testamentaryEntities, activeClientDeathId),
    [testamentaryEntities, activeClientDeathId]
  );

  return (
    <DataTable
      loading={isLoading}
      slots={{
        footer: Box, // hide the pagination footer
        noRowsOverlay: () => (
          <TableEmptyState text="No testamentary entities" />
        ),
      }}
      pageSize={100}
      columns={columns}
      rows={rows}
    />
  );
}
