import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { AfterDeath } from '@/types/schema';

import { ContextPrimaryClient } from '../household/contexts/householdDetails.context';
import { BeneficiariesData, ClientOrOrgId } from './beneficiaryReporting.types';
import { BeneficiaryReportingQuery } from './graphql/BeneficiaryReporting.generated';

export interface BeneficiaryReportingContext {
  clientOrOrgId: ClientOrOrgId | null;
  beneficiariesData: BeneficiariesData;
  firstGrantor?: ContextPrimaryClient;
  secondGrantor?: ContextPrimaryClient;
  firstGrantorDeathYear?: number;
  secondGrantorDeathYear?: number;
  queryData: BeneficiaryReportingQuery;
}

export const BeneficiaryReportingContext =
  createContext<BeneficiaryReportingContext>({
    clientOrOrgId: null,
    beneficiariesData: {
      [AfterDeath.None]: {},
      [AfterDeath.First]: {},
      [AfterDeath.Second]: {},
    },
    queryData: {} as BeneficiaryReportingQuery,
  });

export const useBeneficiaryReportingContext = () => {
  return useGuardedContext(
    BeneficiaryReportingContext,
    'BeneficiaryReportingContext.Provider'
  );
};
