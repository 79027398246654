/**
 * @description MS word doesn't support nested list items correctly, so in the copy phase we normalize
 * the list items to remove the nested structure.
 */
export function removeNestedListItems(htmlString: string): string {
  const parser = new DOMParser();

  // Parse the HTML string into a Document object
  const doc = parser.parseFromString(htmlString, 'text/html');

  const allElements = doc.querySelectorAll('*');

  allElements.forEach((element) => {
    if (
      Array.from(element.classList).some((className) =>
        className.includes('nestedListItem')
      ) &&
      element.tagName === 'LI'
    ) {
      // Replace the element with its child nodes
      const parent = element.parentNode;

      if (parent) {
        // Move all child nodes of the element to the parent
        while (element.firstChild) {
          parent.insertBefore(element.firstChild, element);
        }

        // Remove the empty element itself
        parent.removeChild(element);
      }
    }
  });

  // Return the modified HTML string
  return doc.documentElement.outerHTML;
}
