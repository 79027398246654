import { Stack, Typography } from '@mui/material';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { Checkbox } from '@/components/form/baseInputs/Checkbox';
import { CheckboxListWrapper } from '@/components/form/formAwareInputs/FormAwareCheckboxList/FormAwareCheckboxList';
import { Settings04Icon } from '@/components/icons/Settings04Icon';
import { COLORS } from '@/styles/tokens/colors';

import { useEstateWaterfallContext } from '../../contexts/estateWaterfall.context';
import { EstateWaterfall_VizConfigFragment } from '../../graphql/EstateWaterfall.generated';

export const EstateWaterfallVizConfigSettings = () => {
  const { state, dispatch } = useEstateWaterfallContext();
  const { visualizationConfig } = state.waterfall;

  function updateConfigValue(
    key: keyof Omit<EstateWaterfall_VizConfigFragment, '__typename' | 'id'>
  ) {
    return () => {
      dispatch({
        type: 'UPDATE_WATERFALL',
        ...state,
        waterfall: {
          ...state.waterfall,
          visualizationConfig: {
            ...state.waterfall.visualizationConfig!,
            [key]: !state.waterfall.visualizationConfig![key],
          },
        },
      });
    };
  }

  return (
    <ButtonWithPopover
      keepOpen
      label="Settings"
      size="sm"
      variant="secondary"
      buttonSx={{
        backgroundColor: COLORS.PRIMITIVES.WHITE,
        margin: 0,
      }}
      hideChevron
      popperProps={{
        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
        transformOrigin: { horizontal: 'left', vertical: 0 },
      }}
      startIcon={Settings04Icon}
    >
      <Stack gap={1}>
        <Typography
          variant="h6"
          sx={{
            color: COLORS.GRAY[500],
            backgroundColor: COLORS.GRAY[200],
            padding: 0.5,
          }}
        >
          Tile display settings
        </Typography>
        <Stack paddingX={1}>
          <CheckboxListWrapper>
            <Checkbox
              value={!!visualizationConfig?.hideTaxTiles}
              label="Hide tax tiles"
              onChange={updateConfigValue('hideTaxTiles')}
            />
            <Checkbox
              value={!!visualizationConfig?.hideEntityValues}
              label="Hide dollar values"
              onChange={updateConfigValue('hideEntityValues')}
            />
            {/* TODO: need to check with @Edu if the backend is also doing filtering here */}
            {/* <Checkbox
              value={!!visualizationConfig?.showZeroDollarTiles}
              label="Show tiles that have zero dollar values"
              onChange={updateConfigValue('showZeroDollarTiles')}
            /> */}
            <Checkbox
              value={!!visualizationConfig?.hideEntitiesListInGroups}
              label="Hide list of entities in groups"
              onChange={updateConfigValue('hideEntitiesListInGroups')}
            />
            <Checkbox
              value={!!visualizationConfig?.hideEdges}
              label="Hide lines"
              onChange={updateConfigValue('hideEdges')}
            />
            <Checkbox
              value={!!visualizationConfig?.alwaysShowEdgeLabels}
              label="Show labels on lines"
              onChange={updateConfigValue('alwaysShowEdgeLabels')}
            />
          </CheckboxListWrapper>
        </Stack>
      </Stack>
    </ButtonWithPopover>
  );
};
