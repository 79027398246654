import Decimal from 'decimal.js';
import { compact } from 'lodash';

import { getTypeOrUndefined } from '@/modules/entities/EntitySubforms/utils/shared/common.utils';
import { getEntityTypeFromSubtype } from '@/modules/entities/utils/getEntityTypeFromSubtype';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import {
  AugmentedCreateDispositiveProvisionInput,
  AugmentedCreateDispositiveProvisionTemplateInput,
  AugmentedUpdateDispositiveProvisionTemplateInput,
  ClientProfileWhereInput,
  DispositionScenarioWhereInput,
  DispositiveProvisionTemplateKind,
  DispositiveProvisionTemplateSubKind,
  DispositiveProvisionTransferTaxKind,
  EntityWhereInput,
  UpdateDispositiveProvisionTemplateInput,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import { DispositionScheme } from '../dispositiveProvisions.types';
import {
  mapProvisionToRecipient,
  sortDispositionOrder,
} from '../dispositiveProvisions.utils';
import {
  defaultRecipient,
  DISPOSITIVE_PROVISIONS_FORM_NAMESPACE,
} from '../DispositiveProvisionsForm/DispositiveProvisionsForm.constants';
import {
  DispositiveProvisionsModalDetailsType,
  Recipient,
  RecipientKind,
} from '../DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import {
  isRecipientWithId,
  makeCreateProvisionInputFromRecipient,
} from '../DispositiveProvisionsForm/DispositiveProvisionsForm.utils';
import { SURVIVING_SPOUSE_SENTINEL } from '../DispositiveProvisionsForm/hooks/useRecipientOptions';
import { DispositiveProvisionsTemplateLinkedEntitiesProps } from './DispositiveProvisionsTemplateSplitScreenModal.components';
import { BLANK_POUROVER_RECIPIENT } from './DispositiveProvisionsTemplateSplitScreenModal.constants';
import {
  DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE,
  DispositiveProvisionsTemplateLinkedEntryRow,
  DispositiveProvisionsTemplateShape,
  DispositiveProvisionTemplateDetails,
  MaritalTrustType,
  NEW_TEMPLATE_SENTINEL,
} from './DispositiveProvisionsTemplateSplitScreenModal.types';
import { DispositiveProvisionTemplateData_DispositiveProvisionTemplateFragment } from './graphql/DispositiveProvisionTemplateData.generated';
import {
  DispositiveProvisionTemplateUseCount_ClientProfileFragment,
  DispositiveProvisionTemplateUseCount_EntityFragment,
} from './graphql/DispositiveProvisionTemplateUseCount.generated';
import { DEFAULT_ABC_TRUST_RECIPIENTS } from './presets/DispositiveProvisionsTemplateSplitScreenModal.abcTrust';
import {
  BLANK_AB_GST_EXEMPT_MARITAL_TRUST,
  BLANK_AB_GST_NON_EXEMPT_MARITAL_TRUST,
  DEFAULT_AB_TRUST_RECIPIENTS,
} from './presets/DispositiveProvisionsTemplateSplitScreenModal.abTrust';
import { BLANK_MARITAL_TRUST } from './presets/DispositiveProvisionsTemplateSplitScreenModal.components';
import { BLANK_OUTRIGHT_TO_SURVIVING_SPOUSE } from './presets/DispositiveProvisionsTemplateSplitScreenModal.outrightToSurvivingSpouse';

function applySubKind(
  formData: DispositiveProvisionsTemplateShape
): DispositiveProvisionTemplateSubKind {
  const {
    templateType,
    maritalTrustType,
    includeSurvivorsTrust,
    includeDisclaimerTrust,
  } = formData[DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE];
  if (templateType === DispositiveProvisionTemplateKind.MaritalTrust) {
    if (maritalTrustType === MaritalTrustType.ClaytonQTIPTrust) {
      return DispositiveProvisionTemplateSubKind.ClaytonQtip;
    } else if (maritalTrustType === MaritalTrustType.DisclaimerTrust) {
      return DispositiveProvisionTemplateSubKind.Disclaimer;
    }
  } else if (templateType === DispositiveProvisionTemplateKind.AbTrust) {
    if (includeSurvivorsTrust) {
      return DispositiveProvisionTemplateSubKind.Survivor;
    }
  } else if (
    templateType === DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse
  ) {
    if (includeDisclaimerTrust) {
      return DispositiveProvisionTemplateSubKind.Disclaimer;
    }
  }
  return DispositiveProvisionTemplateSubKind.None;
}

export function mapFormDataToCreatePayload(
  formData: DispositiveProvisionsTemplateShape,
  householdID: string
): AugmentedCreateDispositiveProvisionTemplateInput {
  const output: AugmentedCreateDispositiveProvisionTemplateInput = {
    create: {
      displayName:
        formData[DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE].displayName,
      documentID: getTypeOrUndefined(
        formData[DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE].documentId
      ),
      householdID,
      kind: formData[DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE]
        .templateType,
      subKind: applySubKind(formData),
    },
    withDispositiveProvisions: compact(
      formData[
        DISPOSITIVE_PROVISIONS_FORM_NAMESPACE
      ].recipients.map<AugmentedCreateDispositiveProvisionInput | null>(
        (recipient, index) => {
          if (!isRecipientWithId(recipient)) return null;
          return makeCreateProvisionInputFromRecipient(recipient, index, {
            householdId: householdID,
            isOnHypotheticalWaterfall: false,
            clientProfileId: '', // this isn't used by the consuming function in this case
          });
        }
      )
    ),
  };
  return output;
}

export function mapFormDataToUpdatePayload(
  formData: DispositiveProvisionsTemplateShape,
  householdId: string
): AugmentedUpdateDispositiveProvisionTemplateInput {
  const update: UpdateDispositiveProvisionTemplateInput = {
    displayName:
      formData[DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE].displayName,
    clearDispositiveProvisions: true,
    subKind: applySubKind(formData),
  };

  if (formData[DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE].documentId) {
    update.documentID =
      formData[DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE].documentId;
  } else {
    update.clearDocument = true;
  }

  const withDispositiveProvisions:
    | AugmentedCreateDispositiveProvisionInput[]
    | undefined = compact(
    formData[DISPOSITIVE_PROVISIONS_FORM_NAMESPACE].recipients.map(
      (recipient, index) => {
        if (!isRecipientWithId(recipient)) return null;
        return makeCreateProvisionInputFromRecipient(recipient, index, {
          householdId,
          isOnHypotheticalWaterfall: false,
          clientProfileId: '', // this isn't used by the consuming function in this case
        });
      }
    )
  );

  const output: AugmentedUpdateDispositiveProvisionTemplateInput = {
    id: formData[DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE].id,
    update,
    withDispositiveProvisions,
  };

  return output;
}

const COUNT_OF_RECIPIENTS_FOR_AB_TRUST_WITH_SURVIVORS_TRUST = 4;
const COUNT_OF_RECIPIENTS_FOR_OUTRIGHT_TO_SURVIVING_SPOUSE_WITH_DISCLAIMER_TRUST = 2;

export function mapQueryDataToForm(
  templateData: DispositiveProvisionTemplateData_DispositiveProvisionTemplateFragment
): DispositiveProvisionsTemplateShape {
  let includeSurvivorsTrust = false;
  let includeDisclaimerTrust = false;
  let maritalTrustType = MaritalTrustType.None;

  const { kind, subKind } = templateData;
  if (kind === DispositiveProvisionTemplateKind.MaritalTrust) {
    if (subKind === DispositiveProvisionTemplateSubKind.ClaytonQtip) {
      maritalTrustType = MaritalTrustType.ClaytonQTIPTrust;
    } else if (subKind === DispositiveProvisionTemplateSubKind.Disclaimer) {
      maritalTrustType = MaritalTrustType.DisclaimerTrust;
    }
  } else if (kind === DispositiveProvisionTemplateKind.AbTrust) {
    if (
      getNodes(templateData.dispositiveProvisions).length ===
      COUNT_OF_RECIPIENTS_FOR_AB_TRUST_WITH_SURVIVORS_TRUST
    ) {
      includeSurvivorsTrust = true;
    }
  } else if (
    kind === DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse
  ) {
    if (
      getNodes(templateData.dispositiveProvisions).length ===
      COUNT_OF_RECIPIENTS_FOR_OUTRIGHT_TO_SURVIVING_SPOUSE_WITH_DISCLAIMER_TRUST
    ) {
      includeDisclaimerTrust = true;
    }
  }

  let recipients = compact(getNodes(templateData.dispositiveProvisions))
    .sort(sortDispositionOrder)
    .map(mapProvisionToRecipient)
    .map((recipient, idx) => {
      const isLastProvision =
        idx === getNodes(templateData.dispositiveProvisions).length - 1;

      // if there are any existing templates, force them to use the new surviving spouse recipient
      // for the last provision
      if (
        kind === DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse &&
        isLastProvision
      ) {
        return {
          ...recipient,
          recipientId: SURVIVING_SPOUSE_SENTINEL,
          recipientKind: RecipientKind.SurvivingSpouse,
          transferTaxKind:
            DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion,
        };
      }
      return recipient;
    });

  switch (templateData?.kind) {
    case DispositiveProvisionTemplateKind.AbTrust: {
      if (recipients.length === 1) {
        recipients = recipients.concat([
          BLANK_AB_GST_EXEMPT_MARITAL_TRUST,
          BLANK_AB_GST_NON_EXEMPT_MARITAL_TRUST,
        ]);
      } else if (recipients.length === 2) {
        recipients = recipients.concat([BLANK_AB_GST_NON_EXEMPT_MARITAL_TRUST]);
      }
      break;
    }
    default:
      break;
  }

  return {
    [DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE]: {
      id: templateData.id,
      displayName: templateData.displayName,
      documentId: templateData.document?.id || undefined,
      includeDisclaimerTrust,
      includeSurvivorsTrust,
      maritalTrustType,
      templateType:
        templateData?.kind || DispositiveProvisionTemplateKind.Default,
    },
    [DISPOSITIVE_PROVISIONS_FORM_NAMESPACE]: {
      dispositionScenarioId: null,
      _dispositionScenarioAssociatedWaterfallId: null,
      selectedFirstPrimaryClientDeathId: null,
      _hasBeenReviewed: false,
      dispositionScheme: DispositionScheme.UPON_FIRST_DEATH, // stub value; using NONE causes downstream errors
      recipients,
    },
  };
}

function getWhereSegment(id: string): [
  {
    hasDispositionScenariosWith: DispositionScenarioWhereInput[];
  },
] {
  return [
    {
      hasDispositionScenariosWith: [
        {
          or: [
            { hasDispositiveProvisionsTemplateWith: [{ id: id }] },
            {
              hasSecondDeathDispositiveProvisionsTemplateWith: [{ id: id }],
            },
          ],
        },
      ],
    },
  ];
}

export function getDispositiveProvisionTemplateUseCountEntityWhereInput(
  templateId: string
): EntityWhereInput {
  if (templateId === NEW_TEMPLATE_SENTINEL) {
    return {};
  }

  const whereSegment = getWhereSegment(templateId);

  const where: EntityWhereInput = {
    or: [
      { hasCcorpBusinessEntityWith: whereSegment },
      { hasCltTrustWith: whereSegment },
      { hasCrtTrustWith: whereSegment },
      { hasCustodialPersonalAccountWith: whereSegment },
      { hasDonorAdvisedFundWith: whereSegment },
      { hasGpBusinessEntityWith: whereSegment },
      { hasGratTrustWith: whereSegment },
      { hasIlitTrustWith: whereSegment },
      { hasIndividualPersonalAccountWith: whereSegment },
      { hasInsurancePersonalAccountWith: whereSegment },
      { hasIrrevocableTrustWith: whereSegment },
      { hasJointPersonalAccountWith: whereSegment },
      { hasLlcBusinessEntityWith: whereSegment },
      { hasLpBusinessEntityWith: whereSegment },
      { hasPrivateFoundationWith: whereSegment },
      { hasQprtTrustWith: whereSegment },
      { hasQualifiedTuitionPersonalAccountWith: whereSegment },
      { hasRetirementPersonalAccountWith: whereSegment },
      { hasRevocableTrustWith: whereSegment },
      { hasScorpBusinessEntityWith: whereSegment },
      { hasSlatTrustWith: whereSegment },
      { hasSoleProprietorshipBusinessEntityWith: whereSegment },
    ],
  };

  return where;
}

export function getDispositiveProvisionTemplateUseCountIndividualWhereInput(
  templateId: string,
  primaryClients: ContextPrimaryClient[] | null
): ClientProfileWhereInput {
  return {
    and: [
      {
        idIn: primaryClients?.map(({ id }) => id) || [],
      },
      {
        hasDispositionScenariosWith: [
          {
            or: [
              {
                hasDispositiveProvisionsTemplateWith: [{ id: templateId }],
                hasSecondDeathDispositiveProvisionsTemplateWith: [
                  { id: templateId },
                ],
              },
            ],
          },
        ],
      },
    ],
  };
}

function getDefaultDisplayName(templateType: DispositiveProvisionTemplateKind) {
  switch (templateType) {
    case DispositiveProvisionTemplateKind.PouroverWill:
      return 'Untitled pour-over will template';
    case DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse:
      return 'Untitled outright to surviving spouse template';
    case DispositiveProvisionTemplateKind.MaritalTrust:
      return 'Untitled marital trust template';
    case DispositiveProvisionTemplateKind.AbcTrust:
      return 'Untitled ABC trust template';
    case DispositiveProvisionTemplateKind.AbTrust:
      return 'Untitled AB trust template';
    case DispositiveProvisionTemplateKind.Default:
      return 'Untitled template';
    default:
      throw new UnreachableError({
        case: templateType,
        message: 'Invalid template type provided',
      });
  }
}

export function getDefaultValues({
  templateId,
  templateType = DispositiveProvisionTemplateKind.Default,
  recipients: externalRecipients,
}: DispositiveProvisionTemplateDetails): DispositiveProvisionsTemplateShape {
  // get copy of array so the underlying values don't get modified
  let recipients: Recipient[];

  switch (templateType) {
    case DispositiveProvisionTemplateKind.PouroverWill:
      recipients = [BLANK_POUROVER_RECIPIENT];
      break;
    case DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse:
      recipients = [BLANK_OUTRIGHT_TO_SURVIVING_SPOUSE];
      break;
    case DispositiveProvisionTemplateKind.MaritalTrust:
      recipients = [BLANK_MARITAL_TRUST];
      break;
    case DispositiveProvisionTemplateKind.AbcTrust:
      recipients = [...DEFAULT_ABC_TRUST_RECIPIENTS];
      break;
    case DispositiveProvisionTemplateKind.AbTrust:
      recipients = [...DEFAULT_AB_TRUST_RECIPIENTS];
      break;
    default:
      recipients = externalRecipients?.length
        ? [...externalRecipients]
        : [defaultRecipient];
      break;
  }

  return {
    [DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE]: {
      id: templateId,
      displayName: getDefaultDisplayName(templateType),
      documentId: '',
      templateType,
      includeDisclaimerTrust: false,
      includeSurvivorsTrust: false,
      maritalTrustType: MaritalTrustType.None,
    },
    [DISPOSITIVE_PROVISIONS_FORM_NAMESPACE]: {
      dispositionScenarioId: null,
      _dispositionScenarioAssociatedWaterfallId: null,
      selectedFirstPrimaryClientDeathId: null,
      _hasBeenReviewed: false,
      dispositionScheme: DispositionScheme.UPON_FIRST_DEATH, // stub value; using NONE causes downstream errors
      recipients,
    },
  };
}

export function mapEntityDispositionScenariosToLinkedTable(
  entities: DispositiveProvisionTemplateUseCount_EntityFragment[] = [],
  templateId: string,
  setDispositiveProvisionsModalDetails: DispositiveProvisionsTemplateLinkedEntitiesProps['setDispositiveProvisionsModalDetails']
): DispositiveProvisionsTemplateLinkedEntryRow[] {
  return entities.reduce<DispositiveProvisionsTemplateLinkedEntryRow[]>(
    (acc, entity) => {
      const newRows = entity.subtype.dispositionScenarios
        ?.filter(
          (scenario) =>
            scenario.dispositiveProvisionsTemplate?.id === templateId ||
            scenario.secondDeathDispositiveProvisionsTemplate?.id === templateId
        )
        .map<DispositiveProvisionsTemplateLinkedEntryRow>((scenario) => ({
          lineOne: entity.subtype?.displayName || '',
          lineTwo: `${scenario.firstGrantorDeath?.firstName} dies first (upon ${scenario.dispositiveProvisionsTemplate ? 'first' : 'second'} death)`,
          id: `${entity.id}-${scenario.id}`,
          onClick: () => {
            setDispositiveProvisionsModalDetails?.({
              type: DispositiveProvisionsModalDetailsType.ENTITY,
              entityId: entity.id,
              entityDisplayName: entity.subtype?.displayName || '',
              entitySubtypeId: entity.subtype.id,
              entityType: getEntityTypeFromSubtype(entity.subtype.__typename),
              firstDeathClientId: scenario.firstGrantorDeath.id,
              isFromTemplateEditor: true,
            });
          },
        }));

      return acc.concat(newRows || []);
    },
    []
  );
}

export function mapClientProfileScenariosToLinkedTable(
  clientProfiles: DispositiveProvisionTemplateUseCount_ClientProfileFragment[] = [],
  templateId: string,
  setDispositiveProvisionsModalDetails: DispositiveProvisionsTemplateLinkedEntitiesProps['setDispositiveProvisionsModalDetails']
): DispositiveProvisionsTemplateLinkedEntryRow[] {
  return clientProfiles.reduce<DispositiveProvisionsTemplateLinkedEntryRow[]>(
    (acc, individual) => {
      const newRows = individual.dispositionScenarios
        ?.filter(
          (scenario) =>
            scenario.dispositiveProvisionsTemplate?.id === templateId ||
            scenario.secondDeathDispositiveProvisionsTemplate?.id === templateId
        )
        .map<DispositiveProvisionsTemplateLinkedEntryRow>((scenario) => ({
          lineOne: individual.displayName || '',
          lineTwo: `${scenario.firstGrantorDeath?.firstName} dies first (upon ${scenario.dispositiveProvisionsTemplate ? 'first' : 'second'} death)`,
          id: `${individual.id}-${scenario.id}`,
          onClick: () => {
            setDispositiveProvisionsModalDetails?.({
              type: DispositiveProvisionsModalDetailsType.CLIENT_PROFILE,
              clientProfileId: individual.id,
              displayName: individual.displayName,
              valueToDistribute: new Decimal(0),
              firstDeathClientId: scenario.firstGrantorDeath.id,
              isFromTemplateEditor: true,
            });
          },
        }));
      return acc.concat(newRows || []);
    },
    []
  );
}
