import Decimal from 'decimal.js';

import {
  InsurancePolicyKind,
  InsurancePolicyPremiumFrequency,
} from '@/types/schema';
import { PathsOf } from '@/types/subform';

export const INSURANCE_POLICIES_DETAILS_SUBFORM =
  'insurancePolicyDetailsSubform' as const;
export type NamespaceType = typeof INSURANCE_POLICIES_DETAILS_SUBFORM;

// Currently, the "default" variant is basically just the non-ilit version of the form
const VARIANTS = ['default', 'ilit'] as const;
export type VariantType = (typeof VARIANTS)[number];

export const InsurancePolicyTypeCopyMap: Record<InsurancePolicyKind, string> = {
  [InsurancePolicyKind.Term]: 'Term life',
  [InsurancePolicyKind.Whole]: 'Whole life',
  [InsurancePolicyKind.Universal]: 'Universal life',
  [InsurancePolicyKind.Variable]: 'Variable life',
};

export const InsurancePolicyFrequencyCopyMap: Record<
  InsurancePolicyPremiumFrequency,
  string
> = {
  [InsurancePolicyPremiumFrequency.Annually]: 'Annually',
  [InsurancePolicyPremiumFrequency.Monthly]: 'Monthly',
  [InsurancePolicyPremiumFrequency.Semiannually]: 'Semi-annually',
};

export interface InsurancePolicyDetails {
  policyId: string | null;
  policyType: InsurancePolicyKind | '';
  isConvertible: boolean;
  conversionDate: Date | null;
  insuranceCarrier: string;
  policyNumber: string;
  policyHolderIDs: string[];
  deathBenefitAmount: Decimal | null;
  policyStartDate: Date | null;
  termLength: number | '';
  premiumAmount: Decimal | null;
  premiumFrequency: InsurancePolicyPremiumFrequency | '';
  premiumInitialDueDate: Date | null;
  notes: string;
  documentIds: string[];
  initialDocumentIDs: string[];
  initialPolicyHolderIDs: string[];
  cashValue: Decimal | null;
  cashValueDate: Date | null;
  loanBalanceOutstanding: Decimal | null;
}
export interface InsurancePolicyDetailsSubformType {
  policies: InsurancePolicyDetails[];
  initialPolicyIDs: string[];
  crummeyWithdrawalPeriodDays?: Decimal | null;
}

export type InsurancePolicyDetailsSubformTypePaths = PathsOf<Fields>;

export interface Fields {
  [INSURANCE_POLICIES_DETAILS_SUBFORM]: InsurancePolicyDetailsSubformType;
}

export interface Props {
  variant: VariantType;
  householdId: string;
  entityId?: string;
}

export const defaultPolicy: InsurancePolicyDetails = {
  policyId: null,
  conversionDate: null,
  policyType: InsurancePolicyKind.Whole,
  isConvertible: false,
  insuranceCarrier: '',
  policyNumber: '',
  policyHolderIDs: [],
  initialPolicyHolderIDs: [],
  deathBenefitAmount: null,
  policyStartDate: null,
  termLength: '',
  premiumAmount: null,
  premiumFrequency: '',
  premiumInitialDueDate: null,
  notes: '',
  documentIds: [],
  initialDocumentIDs: [],
  cashValue: null,
  cashValueDate: null,
  loanBalanceOutstanding: null,
};

export const defaultValues: InsurancePolicyDetailsSubformType = {
  policies: [defaultPolicy],
  initialPolicyIDs: [],
  crummeyWithdrawalPeriodDays: null,
};
