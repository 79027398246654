import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { TypeaheadSelectInput } from '@/components/form/baseInputs/TypeaheadSelectInput/TypeaheadSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormAwareTypeaheadSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';
import { UsersPlusIcon } from '@/components/icons/UsersPlusIcon';
import { useFormContext } from '@/components/react-hook-form';
import { StyledTableCell } from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { getFieldErrorValue } from '@/components/utils/inputUtils';
import { StateDropdownInput } from '@/modules/common/StateDropdownInput/StateDropdownInput';

import { BulkImportForm } from '../BulkImportPage.types';

export interface BulkImportClientDetailRowProps {
  householdId: string;
  ownerOptions: SelectInputOption<string>[];
}

export function BulkImportClientDetailRow({
  householdId,
  ownerOptions,
}: BulkImportClientDetailRowProps) {
  const { control } = useFormContext<BulkImportForm>();
  const displayName = useWatch({
    name: `householdMap.${householdId}.displayName`,
    control,
  });

  return (
    <StyledTableRow>
      <StyledTableCell>
        <Typography variant="body1">{displayName}</Typography>
      </StyledTableCell>
      <StyledTableCell>
        <FormAwareTextInput<BulkImportForm>
          fieldName={`householdMap.${householdId}.firstName`}
          control={control}
          label="First name"
          hideLabel
          required
        />
      </StyledTableCell>
      <StyledTableCell>
        <FormAwareTextInput<BulkImportForm>
          fieldName={`householdMap.${householdId}.lastName`}
          control={control}
          label="Last name"
          hideLabel
          required
        />
      </StyledTableCell>
      <StyledTableCell>
        <StateDropdownInput<BulkImportForm>
          fieldName={`householdMap.${householdId}.state`}
          control={control}
          label="State"
          hideLabel
          required
        />
      </StyledTableCell>
      <StyledTableCell>
        <FormAwareTypeaheadSelectInput<BulkImportForm>
          fieldName={`householdMap.${householdId}.ownerID`}
          control={control}
          options={ownerOptions}
          label="Relationship owner"
          hideLabel
          required
        />
      </StyledTableCell>
      <BulkImportClientMergeHousehold householdId={householdId} />
    </StyledTableRow>
  );
}

interface BulkImportClientMergeHouseholdProps {
  householdId: string;
}

function BulkImportClientMergeHousehold({
  householdId,
}: BulkImportClientMergeHouseholdProps) {
  const { control } = useFormContext<BulkImportForm>();
  const mergedWithID = useWatch({
    control,
    name: `householdMap.${householdId}.mergedWithID`,
    exact: true,
  });

  if (mergedWithID) {
    return <BulkImportClientMergedView mergedWithID={mergedWithID} />;
  }

  return <BulkImportClientUnmergedView householdId={householdId} />;
}

function BulkImportClientMergedView({
  mergedWithID,
}: {
  mergedWithID: string;
}) {
  const { control } = useFormContext<BulkImportForm>();
  const mergedWithDisplayName = useWatch({
    control,
    name: `householdMap.${mergedWithID}.displayName`,
    exact: true,
  });
  return (
    <StyledTableCell>
      <Typography variant="body1">{mergedWithDisplayName}</Typography>
    </StyledTableCell>
  );
}

function BulkImportClientUnmergedView({
  householdId,
}: BulkImportClientMergeHouseholdProps) {
  const { control, setValue, formState, getFieldState } =
    useFormContext<BulkImportForm>();

  const fieldState = getFieldState(
    `householdMap.${householdId}.mergeHouseholdID`
  );

  const mergeHouseholdID = useWatch({
    control,
    name: `householdMap.${householdId}.mergeHouseholdID`,
    exact: true,
  });
  const householdMap = useWatch({
    control,
    name: 'householdMap',
    exact: true,
  });

  const householdList = Object.values(householdMap);

  const mergeableHouseholdClients: SelectInputOption<string>[] = useMemo(() => {
    const mergedHouseholds = householdList
      .filter((client) => client.mergeHouseholdID || client.mergedWithID)
      .map((client) => client.id);

    const filteredList = householdList.filter(({ id: itemID }) => {
      if (itemID === householdId) {
        // don't show self as option
        return false;
      } else if (itemID === mergeHouseholdID) {
        // always show option if selected
        return true;
      } else if (mergedHouseholds.includes(itemID)) {
        // do not show if someone else has selected
        return false;
      }
      return true;
    });
    const optionList: SelectInputOption<string>[] = filteredList.map(
      ({ id, displayName }) => ({
        value: id,
        display: displayName,
      })
    );

    return optionList;
  }, [householdId, householdList, mergeHouseholdID]);

  return (
    <StyledTableCell>
      <TypeaheadSelectInput
        label="Client to merge with"
        hideLabel
        options={mergeableHouseholdClients}
        disabled={!mergeableHouseholdClients.length}
        onChange={(_, mergeID) => {
          // need to use a custom onChange here because this has to update
          // both itself and the global list of merged households
          if (mergeHouseholdID) {
            // clean up the existing link -- unlink the previous link
            setValue(`householdMap.${mergeHouseholdID}.mergedWithID`, '');
          }

          if (mergeID === '' || !mergeID) {
            // if clearing the previous link, clear data
            setValue(`householdMap.${householdId}.mergeHouseholdID`, '');
          } else {
            setValue(`householdMap.${householdId}.mergeHouseholdID`, mergeID);
            setValue(`householdMap.${mergeID}.mergedWithID`, householdId);
          }
        }}
        value={mergeHouseholdID || ''}
        startAdornment={<UsersPlusIcon />}
        errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
      />
    </StyledTableCell>
  );
}
