import Decimal from 'decimal.js';
import { useEffect, useMemo, useRef } from 'react';

import { useDebouncedFn } from '@/hooks/useDebouncedFn';
import { SimulateDispositiveProvisionsInput } from '@/types/schema';

import { DispositionScheme } from '../../dispositiveProvisions.types';
import {
  SimulateDispositiveProvisionsQuery,
  useSimulateDispositiveProvisionsLazyQuery,
} from '../../graphql/SimulateDispositiveProvisions.generated';
import { Recipient } from '../DispositiveProvisionsForm.types';
import { getSimulateDispositiveProvisionsInput } from './useSimulateDispositions.utils';

interface UseSimulateDispositionsInput {
  firstGrantorId: string;
  secondGrantorId: string | undefined;
  recipients?: Recipient[];
  entityTotalMarketValue?: Decimal;
  selectedDispositionScheme: DispositionScheme;
}

export function useSimulateDispositions({
  firstGrantorId,
  secondGrantorId,
  recipients,
  entityTotalMarketValue,
  selectedDispositionScheme,
}: UseSimulateDispositionsInput) {
  const simulateDispositiveProvisionsInput: SimulateDispositiveProvisionsInput | null =
    useMemo(() => {
      return getSimulateDispositiveProvisionsInput({
        firstGrantorId,
        secondGrantorId,
        recipients: recipients ?? [],
        entityTotalMarketValue,
        dispositionScheme: selectedDispositionScheme,
      });
    }, [
      entityTotalMarketValue,
      firstGrantorId,
      recipients,
      secondGrantorId,
      selectedDispositionScheme,
    ]);

  const [simulateDispositiveProvisions, { data: simulationResults }] =
    useSimulateDispositiveProvisionsLazyQuery();

  const lastSimulationResults = useRef<SimulateDispositiveProvisionsQuery>();

  if (simulationResults) {
    lastSimulationResults.current = simulationResults;
  }

  const debouncedSimulateDispostiveProvisions = useDebouncedFn(
    simulateDispositiveProvisions,
    500,
    {
      leading: true,
      trailing: true,
    }
  );

  useEffect(() => {
    if (!simulateDispositiveProvisionsInput) {
      return;
    }

    void debouncedSimulateDispostiveProvisions({
      variables: {
        input: simulateDispositiveProvisionsInput,
      },
    });
  }, [
    debouncedSimulateDispostiveProvisions,
    simulateDispositiveProvisions,
    simulateDispositiveProvisionsInput,
  ]);

  return {
    lastSimulationResults,
    simulationResults,
  };
}
