import { Path, useWatch } from 'react-hook-form';

import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { COLORS } from '@/styles/tokens/colors';
import { AccessParameterKind } from '@/types/schema';

import { AccessParameterAgeRequirementForm } from './AccessParameterAgeRequirementForm';
import { AccessParameterFrequencyIntervalForm } from './AccessParameterFrequencyIntervalForm';
import { ACCESS_PARAMETER_KINDS } from './EntityBeneficiariesSubform.constants';
import { BeneficiaryFormAccessParameter } from './EntityBeneficiariesSubform.types';

type PartialAccessParameterFormPaths = Path<BeneficiaryFormAccessParameter>;

interface PartialAccessParameterFormProps {
  fieldName: `accessParametersPartial.${number}`;
}

export function PartialAccessParameterForm({
  fieldName,
}: PartialAccessParameterFormProps) {
  const { control } = useFormContext<BeneficiaryFormAccessParameter>();

  const selectedKind = useWatch({
    name: `${fieldName}.kind` as 'kind' satisfies PartialAccessParameterFormPaths,
    control,
  });

  const isPercentage = selectedKind === AccessParameterKind.Percentage;
  const isAmount = selectedKind === AccessParameterKind.Amount;

  return (
    <Card variant="filled-dark" sx={{ p: 2, pb: 3, bgcolor: COLORS.GRAY[300] }}>
      <FormLayoutRow>
        <FormLayoutItem width={3}>
          <FormAwareButtonGroup<BeneficiaryFormAccessParameter>
            label="Access parameter kind"
            hideLabel
            fieldName={
              `${fieldName}.kind` as 'kind' satisfies PartialAccessParameterFormPaths
            }
            control={control}
            options={ACCESS_PARAMETER_KINDS}
          />
        </FormLayoutItem>
        {isPercentage && (
          <FormLayoutItem width={9}>
            <FormAwarePercentInput<BeneficiaryFormAccessParameter>
              required
              label="Access percentage"
              hideLabel
              fieldName={
                `${fieldName}.percentage` as 'percentage' satisfies PartialAccessParameterFormPaths
              }
              control={control}
              isDecimalJSInput
              decimalScale={2}
            />
          </FormLayoutItem>
        )}
        {isAmount && (
          <FormLayoutItem width={9}>
            <FormAwareCurrencyInput<BeneficiaryFormAccessParameter>
              required
              label="Access amount"
              hideLabel
              fieldName={
                `${fieldName}.amount` as 'amount' satisfies PartialAccessParameterFormPaths
              }
              control={control}
              isDecimalJSInput
            />
          </FormLayoutItem>
        )}
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput<BeneficiaryFormAccessParameter>
            label="Notes"
            hideLabel
            placeholder="Specify details"
            multiline
            fieldName={
              `${fieldName}.accessParameterNotes` as 'accessParameterNotes' satisfies PartialAccessParameterFormPaths
            }
            control={control}
            rows={2}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <AccessParameterAgeRequirementForm
            variant="single"
            fieldName={fieldName}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <AccessParameterFrequencyIntervalForm fieldName={fieldName} />
        </FormLayoutItem>
      </FormLayoutRow>
    </Card>
  );
}
