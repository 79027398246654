import { Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { WATERFALL_ASSET_GROWTH_DECIMAL_SCALE } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/EstateWaterfallAssumptionFormFields';
import { formatPercent } from '@/utils/formatting/percent';

import { ClientPresentationDesigner_EstateWaterfallFragment } from '../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';

interface GrowthAssumptionsProps {
  slideTitle: string;
  waterfall: ClientPresentationDesigner_EstateWaterfallFragment;
}

export function GrowthAssumptions({
  slideTitle,
  waterfall,
}: GrowthAssumptionsProps) {
  return (
    <>
      <Typography variant="h2">{slideTitle}</Typography>
      <Typography variant="h6" component="span">
        Assumes annual asset growth of{' '}
        {formatPercent(
          waterfall.assetGrowthReturn ?? new Decimal(0),
          WATERFALL_ASSET_GROWTH_DECIMAL_SCALE
        ) + '%'}
      </Typography>
    </>
  );
}
