import Decimal from 'decimal.js';

import { MatchableField } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import {
  GrantorsFieldTypes_MultipleGrantor,
  GrantorsFieldTypes_SingleGrantor,
  GrantorsFieldVariantType,
} from '@/modules/entities/principals/GrantorsField/GrantorsField.types';
import { getGrantorsFieldDefaultData } from '@/modules/entities/principals/GrantorsField/GrantorsField.utils';
import {
  NonGrantorPrincipalFieldsType,
  VariantType as NonGrantorPrincipalFieldVariantType,
  WealthOwnerType,
} from '@/modules/entities/principals/NonGrantorPrincipalFields/NonGrantorPrincipalFields.types';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  EntityStage,
  JointPersonalAccountType,
  RetirementPersonalAccountType,
} from '@/types/schema';
import { PathsOf } from '@/types/subform';

import {
  JOINT_ACCOUNT_TYPE_DISPLAY_NAMES,
  RETIREMENT_ACCOUNT_TYPE_DISPLAY_NAMES,
} from '../personalAccounts/personalAccounts.constants';

export const BASIC_INFORMATION_SUBFORM_NAMESPACE =
  'basicInformationSubform' as const;
export type NamespaceType = typeof BASIC_INFORMATION_SUBFORM_NAMESPACE;
const VARIANTS = [
  'default',
  'GRATImplementationEdit',
  'GRATImplementationConfirm',
  'GRATActive',
  'QPRT',
  'CLT',
  'Account',
  'JointAccount',
  'RetirementAccount',
  'InsuranceAccount',
  'CRT',
  'NonTrustCharitableEntity',
  'BusinessEntity',
] as const;
export type VariantType = (typeof VARIANTS)[number];

export interface BasicInformation_Grantor extends MatchableField {
  clientProfileId: string;
  // percentage is only used for the MultipleGrantorWithPercent variant
  percentage?: Decimal | null;
}

// This is the type of the subform's values. Excludes grantors fields values,
// but does include optional NonGrantorPrincipalFields.
export interface BasicInformationSubformType
  extends NonGrantorPrincipalFieldsType {
  legalName: string;
  displayName: string;

  // these fields are only applicable to business entities
  doingBusinessAsName: string;
  requiresCtaReporting: boolean;
  finCenID: string; // only shown if requiresCtaReporting is true

  effectiveDate: Date | null;
  jurisdiction: string;
  description: string;
  subtypeId?: string;
  jointAccountType?: JointPersonalAccountType | '';
  retirementAccountType?: RetirementPersonalAccountType | '';
  documentIds: string[];
  termLength: string;
  // termLengthNumber is an alternate way to collect termLength where
  // the user types in a number value rather than choosing from a dropdown
  // it's a `string` rather than a `number` because that's how the FormattedNumberInput handles it
  termLengthNumber: string;
  taxableGift: Decimal | null;
  rate7520: Decimal | null;
  // isCompleted indicates that the entity has been completed
  isCompleted?: boolean;
  stage?: EntityStage;
  charitableDeduction?: Decimal | null;
  isLifetime?: boolean;
  initialFundingValue?: Decimal | null;
}

// this is the type of the subform's values for the MULTIPLE grantors variant
export interface BasicInformationSubformType_WithGrantors
  extends BasicInformationSubformType,
    GrantorsFieldTypes_MultipleGrantor {}

// this is the type of the subform's values for the SINGLE grantor variant
export interface BasicInformationSubformType_SingleGrantor
  extends BasicInformationSubformType,
    GrantorsFieldTypes_SingleGrantor {}

// consolidated subform values for basic info
export type SubformValues =
  | BasicInformationSubformType
  | BasicInformationSubformType_WithGrantors
  | BasicInformationSubformType_SingleGrantor;

// this is what the subform's values will be nested under in the parent form
export interface Fields {
  [BASIC_INFORMATION_SUBFORM_NAMESPACE]:
    | BasicInformationSubformType
    | BasicInformationSubformType_WithGrantors
    | BasicInformationSubformType_SingleGrantor;
}

export type SubformField = PathsOf<Fields>;

// this is Props for the UI component
export interface Props {
  variant: VariantType;
  householdId: string;
  entityId?: string;
  entityType?: EntityType;
  // grantorsFieldVariant is used to determine the variant of the grantors field.
  // If responsibleForGrantors is false, this prop is ignored.
  grantorsFieldVariant?: GrantorsFieldVariantType;
  grantorsFieldSubformValues?: SubformValues;
  // isCharitableEntity is used for the grantors selection field.
  // If responsibleForGrantors is false, this prop is ignored.
  isCharitableEntity?: boolean;
  hideDocumentsList?: boolean;
  // The visual variant of the owners/donors field. This will be undefined if
  // the entity has grantors instead of owners/donors. Typically, will only
  // apply to DAF, PF, and personal accounts.
  nonGrantorPrincipalsFieldVariant?: NonGrantorPrincipalFieldVariantType;
  // If the entity type does not have grantors, this identifies if it has owners or donors.
  wealthOwnerType?: WealthOwnerType;
}

export const defaultValues: BasicInformationSubformType = {
  legalName: '',
  displayName: '',
  doingBusinessAsName: '',
  requiresCtaReporting: false,
  finCenID: '',
  effectiveDate: null,
  jurisdiction: '',
  description: '',
  jointAccountType: '',
  retirementAccountType: '',
  documentIds: [],
  termLength: '',
  termLengthNumber: '',
  taxableGift: null,
  rate7520: null,
  controllingParties: [{ controllingPartyId: '' }],
  owner: {
    ownerId: '',
  },
  owners: [
    {
      ownerId: '',
    },
  ],
};

// This function returns the default values for the BasicInformationSubform.
// Includes the logic to set the default values for the grantors field
// (whether or not this subform is responsible for rendering it).
export function getBasicInformationDefaultValues(
  grantorsFieldVariant: GrantorsFieldVariantType | undefined
): SubformValues {
  return {
    ...defaultValues,
    ...getGrantorsFieldDefaultData(grantorsFieldVariant),
  };
}

export const JOINT_ACCOUNT_TYPE_OPTIONS = [
  JointPersonalAccountType.CommunityProperty,
  JointPersonalAccountType.CommunityPropertyWithRightOfSurvivorship,
  JointPersonalAccountType.JointTenants,
  JointPersonalAccountType.JointTenantsWithRightOfSurvivorship,
  JointPersonalAccountType.TenantsByEntirety,
  JointPersonalAccountType.TenantsInCommon,
].map((value) => ({
  value,
  display: JOINT_ACCOUNT_TYPE_DISPLAY_NAMES[value],
}));

export const RETIREMENT_ACCOUNT_TYPE_OPTIONS = [
  RetirementPersonalAccountType.Ira,
  RetirementPersonalAccountType.InheritedIra,
  RetirementPersonalAccountType.RothIra,
  RetirementPersonalAccountType.InheritedRothIra,
  RetirementPersonalAccountType.Type_401K,
  RetirementPersonalAccountType.DefinedBenefitPlan,
  RetirementPersonalAccountType.Other,
].map((value) => ({
  value,
  display: RETIREMENT_ACCOUNT_TYPE_DISPLAY_NAMES[value],
}));
