import { useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { CreateLiabilityModal } from '@/modules/liabilities/LiabilityModal/CreateLiabilityModal';

export function CreateLiabilityButton() {
  const [isLiabilityModalOpen, setIsLiabilityModalOpen] = useState(false);
  return (
    <>
      {isLiabilityModalOpen && (
        <CreateLiabilityModal
          onClose={() => setIsLiabilityModalOpen(false)}
          isOpen={isLiabilityModalOpen}
        />
      )}
      <Button
        startIcon={PlusIcon}
        variant="primary"
        size="sm"
        onClick={() => setIsLiabilityModalOpen(true)}
      >
        Add liability
      </Button>
    </>
  );
}
