// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from '../../../../../graphql/fragments/pageInfo.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddeparClientImportLookup_AddeparClientFragment = { __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind, name: string };

export type AddeparClientImportLookupQueryVariables = Types.Exact<{
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['Cursor']['input']>;
  where?: Types.InputMaybe<Types.IntegrationClientWhereInput>;
  orderBy?: Types.InputMaybe<Array<Types.IntegrationClientOrder> | Types.IntegrationClientOrder>;
}>;


export type AddeparClientImportLookupQuery = { __typename?: 'Query', paginatedData: { __typename?: 'IntegrationClientConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: any | null, endCursor?: any | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'IntegrationClientEdge', node?: { __typename?: 'IntegrationClient', id: string, kind: Types.IntegrationClientKind, name: string } | null } | null> | null } };

export const AddeparClientImportLookup_AddeparClientFragmentDoc = gql`
    fragment AddeparClientImportLookup_AddeparClient on IntegrationClient {
  id
  kind
  name
}
    `;
export const AddeparClientImportLookupDocument = gql`
    query AddeparClientImportLookup($first: Int!, $after: Cursor, $where: IntegrationClientWhereInput, $orderBy: [IntegrationClientOrder!]) {
  paginatedData: integrationClients(
    first: $first
    after: $after
    where: $where
    orderBy: $orderBy
  ) {
    totalCount
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        ...AddeparClientImportLookup_AddeparClient
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${AddeparClientImportLookup_AddeparClientFragmentDoc}`;

/**
 * __useAddeparClientImportLookupQuery__
 *
 * To run a query within a React component, call `useAddeparClientImportLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddeparClientImportLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddeparClientImportLookupQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useAddeparClientImportLookupQuery(baseOptions: Apollo.QueryHookOptions<AddeparClientImportLookupQuery, AddeparClientImportLookupQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddeparClientImportLookupQuery, AddeparClientImportLookupQueryVariables>(AddeparClientImportLookupDocument, options);
      }
export function useAddeparClientImportLookupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddeparClientImportLookupQuery, AddeparClientImportLookupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddeparClientImportLookupQuery, AddeparClientImportLookupQueryVariables>(AddeparClientImportLookupDocument, options);
        }
export function useAddeparClientImportLookupSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AddeparClientImportLookupQuery, AddeparClientImportLookupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddeparClientImportLookupQuery, AddeparClientImportLookupQueryVariables>(AddeparClientImportLookupDocument, options);
        }
export type AddeparClientImportLookupQueryHookResult = ReturnType<typeof useAddeparClientImportLookupQuery>;
export type AddeparClientImportLookupLazyQueryHookResult = ReturnType<typeof useAddeparClientImportLookupLazyQuery>;
export type AddeparClientImportLookupSuspenseQueryHookResult = ReturnType<typeof useAddeparClientImportLookupSuspenseQuery>;
export type AddeparClientImportLookupQueryResult = Apollo.QueryResult<AddeparClientImportLookupQuery, AddeparClientImportLookupQueryVariables>;
export const Operations = {
  Query: {
    AddeparClientImportLookup: 'AddeparClientImportLookup' as const
  },
  Fragment: {
    AddeparClientImportLookup_AddeparClient: 'AddeparClientImportLookup_AddeparClient' as const
  }
}