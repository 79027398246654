import { Recipient } from './DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import {
  getDispositionKind,
  getRecipientDetails,
} from './DispositiveProvisionsForm/DispositiveProvisionsForm.utils';
import { DispositiveProvisions_DispositiveProvisionFragment } from './graphql/DispositiveProvisions.fragments.generated';

/**
 * @description Returns a recipient for a given provision.
 */
/**
 * @description Returns a recipient for a given provision.
 */
export function mapProvisionToRecipient(
  provision: DispositiveProvisions_DispositiveProvisionFragment
): Recipient {
  const { recipientId, recipientKind } = getRecipientDetails(provision);
  const { dispositionKind_ButtonGroup, dispositionKind_Select } =
    getDispositionKind(provision);

  if (!recipientId) {
    throw new Error('Could not find recipient id');
  }

  return {
    recipientId,
    recipientKind,
    dispositionKind_ButtonGroup,
    dispositionKind_Select,
    dispositionAmount: provision.dispositionAmount ?? null,
    dispositionPercentage: provision.dispositionPercentage ?? null,
    notes: provision.notes ?? '',
    transferTaxKind: provision.transferTaxKind,
    _dispositiveProvisionId: provision.id,
  };
}

export const sortDispositionOrder = (
  a: DispositiveProvisions_DispositiveProvisionFragment,
  b: DispositiveProvisions_DispositiveProvisionFragment
) => (a.dispositionOrder ?? 0) - (b.dispositionOrder ?? 0);
