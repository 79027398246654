import { cx } from '@emotion/css';
import { Node, NodeProps, Position } from '@xyflow/react';

import { Omit } from '@/components/charts/Legend/Legend.stories';

import { Handle, HandleProps } from '../../components/Handle';
import { Tile, TileProps } from '../../components/Tile';
import { useFlowChartContext } from '../context/flowChart.context';
import { useCursorStyles } from '../hooks/useCursorStyles';
import { useDraggingNode } from '../hooks/useDraggingNode';
import { NodeRankAttrs, SectionLabelNodeChildAttrs } from '../types';

//  isSelected is omitted because we compute it based on internal react flow props in this component
type TileNodeData = Record<string, unknown> &
  Omit<TileProps, 'isSelected'> &
  NodeRankAttrs &
  SectionLabelNodeChildAttrs;

export type TileNodeProps = NodeProps<Node<TileNodeData>>;

const AllPositions = Object.values(Position);

export function TileNode({
  id,
  data: { classes: tileClasses = {}, ...props },
  selected,
  isConnectable,
  dragging,
}: TileNodeProps) {
  const { root, ...classes } = tileClasses;
  const { dropTargetNode } = useFlowChartContext();
  const { cursorClassName } = useCursorStyles(id);
  const { draggingNode } = useDraggingNode();
  const commonHandleProps: Partial<HandleProps> = {
    isConnectable,
    hidden: true,
  };
  const isGrouped = id === dropTargetNode?.id && !!draggingNode;

  return (
    <>
      <Tile
        classes={{
          root: cx(cursorClassName, root),
          ...classes,
        }}
        dragging={dragging}
        isSelected={dragging || selected}
        isGrouped={isGrouped}
        {...props}
      />
      {/* Build source/target handles for each four corners of the tile square */}
      {AllPositions.map((position) => (
        <Handle
          key={`target-${position}`}
          type="target"
          {...commonHandleProps}
          id={position}
          position={position}
        />
      ))}
      {/* Build source/target handles for each four corners of the tile square */}
      {AllPositions.map((position) => (
        <Handle
          key={`source-${position}`}
          type="source"
          {...commonHandleProps}
          id={position}
          position={position}
        />
      ))}
    </>
  );
}
