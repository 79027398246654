import React from 'react';

import { PopperContent } from '@/components/poppers/PopperContent';
import { englishJoinStringList } from '@/utils/formatting/strings';

export interface BusinessEntityValuationExplanationProps {
  clientDisplayNames: string[];
}

export function BusinessEntityValuationExplanation({
  clientDisplayNames,
}: BusinessEntityValuationExplanationProps) {
  const clientReferences =
    clientDisplayNames.length > 0
      ? englishJoinStringList(clientDisplayNames, 'and/or')
      : 'the client(s)';

  return (
    <PopperContent
      sx={{
        maxWidth: '200px',
      }}
      body={
        <div>
          <p>
            &quot;Non-controlled ownership&quot; refers to ownership held by any
            non-grantor individual or organization, or any ownership that is
            unspecified in Luminary.
          </p>
          <p>
            &quot;Grantor-controlled ownership&quot; refers to ownership held
            directly by {clientReferences}, or any entity owned or created by
            {clientReferences}.
          </p>
        </div>
      }
    />
  );
}
