import { css } from '@emotion/css';
import { Box } from '@mui/material';

import { useListColumnStyles } from '@/components/lists/lists.utils';
import { NonTrustCharitableEntitySummaryData } from '@/modules/nonTrustCharitableEntities/NonTrustCharitableEntityDetails/NonTrustCharitableEntityDetails.types';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';
import { TrustBeneficiariesList } from '@/modules/trusts/TrustBeneficiariesList/TrustBeneficiariesList';

import { PresentationSlideSecondaryHeading } from '../../../components/PresentationSlideSecondaryHeading';
import { PRESENTATION_SPACING_UNITS } from '../../../presentation.constants';
import { PresentationSlide } from '../../../PresentationSlide';
import { PresentationSlideRegistrationDetails } from '../../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../../graphql/EntityPresentation.generated';

export interface NonTrustCharitableEntityBeneficiariesSlideProps
  extends PresentationSlideRegistrationDetails {
  entity: EntityPresentation_EntityFragment;
  summary: NonTrustCharitableEntitySummaryData;
  displayName: string;
}

const SLIDE_TITLE = 'Beneficiaries';

export function NonTrustCharitableEntityBeneficiariesSlide({
  entity,
  summary,
  displayName,
  ...registrationProps
}: NonTrustCharitableEntityBeneficiariesSlideProps) {
  const listColumnsStyles = useListColumnStyles();
  useRegisterSlide({
    displayName: SLIDE_TITLE,
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });

  return (
    <PresentationSlide.Slide
      id={registrationProps.slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading
          heading={SLIDE_TITLE}
          subheading={displayName}
        />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={entity.household.displayName}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <Box p={PRESENTATION_SPACING_UNITS}>
        <TrustBeneficiariesList
          variant="flat"
          classes={{
            parentList: css(listColumnsStyles),
          }}
          beneficiariesGroups={[
            {
              heading: undefined,
              items: summary.beneficiaries ?? [],
              noItemsText: 'No beneficiaries specified',
            },
          ]}
        />
      </Box>
    </PresentationSlide.Slide>
  );
}
