import { compact } from 'lodash';

import { ClientPresentationBundleTypes } from '../clientPresentation/clientPresentation.types';
import { PresentationSlideRegistrationProps } from '../presentation.types';
import { generateBundleId, getFinalSlideIndex } from '../presentation.utils';
import { EntityPresentationSlideType } from './entityPresentations.types';

function generateEntitySlideId(
  entityId: string,
  slideType: EntityPresentationSlideType,
  slideTypeId?: string
) {
  return compact(['slide', entityId, slideType, slideTypeId]).join('_');
}

export function generateEntityBundleId(entityId: string) {
  return generateBundleId(ClientPresentationBundleTypes.ENTITY, entityId);
}

interface GetPresentationSlidePropsOpts {
  localSlideTypeIndex?: number;
  slideTypeId?: string;
}

export function getPresentationEntitySlideProps(
  entityId: string,
  slideType: EntityPresentationSlideType,
  slideOrder: EntityPresentationSlideType[],
  opts: GetPresentationSlidePropsOpts = {}
): PresentationSlideRegistrationProps {
  const { localSlideTypeIndex, slideTypeId } = opts;
  // this index describes the order of the slides within the bundle,
  // not in the entire presentation
  const globalSlideTypeIndex = slideOrder.indexOf(slideType) * 100;
  const finalIndex = getFinalSlideIndex(
    globalSlideTypeIndex,
    localSlideTypeIndex
  );
  const slideId = generateEntitySlideId(entityId, slideType, slideTypeId);

  return {
    bundleId: generateEntityBundleId(entityId),
    bundleIndex: finalIndex,
    slideId,
  };
}
