import { Box } from '@mui/material';

import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import { EntityShortFormShape } from '@/modules/entities/EntityShortFormModal/CreateEntityShortForm/CreateEntityShortForm.types';
import { PathsOf } from '@/types/subform';

/**
 * This allows the user to enter the non-insurance assets when creating an entity.
 * Currently, this only applies to ILIT entities.
 */
export function NonInsuranceAssets() {
  const { control } = useFormContext<EntityShortFormShape>();
  return (
    <>
      <FormLayoutRow>
        <FormLayoutItem width={8}>
          <FormAwareCurrencyInput
            control={control}
            isDecimalJSInput
            fieldName={
              'basicAssetsSubform.basicAssets.currentMarketValue' as const satisfies PathsOf<EntityShortFormShape>
            }
            label="Value of non-insurance assets"
          />
        </FormLayoutItem>
        <FormLayoutItem width={4}>
          <FormAwareDatePickerInput
            control={control}
            fieldName={
              'basicAssetsSubform.basicAssets.dateOfValuation' as const satisfies PathsOf<EntityShortFormShape>
            }
            label="Date of valuation"
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <Callout severity="warning">
            <Box mb={0.5}>
              <strong>
                Do not include the cash value of policies in the field above
              </strong>
            </Box>
            <Box>
              Any cash value of policies held will be accounted for when you
              enter policy details in a later step
            </Box>
          </Callout>
        </FormLayoutItem>
      </FormLayoutRow>
    </>
  );
}
