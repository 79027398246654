import { Stack, Typography } from '@mui/material';

import { ArrowNarrowDownRightIcon } from '@/components/icons/ArrowNarrowDownRightIcon';
import { ArrowNarrowUpRightIcon } from '@/components/icons/ArrowNarrowUpRightIcon';
import { COLORS } from '@/styles/tokens/colors';

import {
  formatPercentOrValue,
  WaterfallComparisonMetric,
} from './WaterfallComparison.utils';
import { Direction } from './WaterfallComparisonBenefitsCard';

interface BigMetricWithLabelProps {
  label: string;
  value: WaterfallComparisonMetric;
  direction: Direction;
}

export function BigMetricWithLabel({
  label,
  value,
  direction,
}: BigMetricWithLabelProps) {
  return (
    <Stack>
      <Typography variant="subtitle1" color={COLORS.PRIMITIVES.WHITE}>
        {label}
      </Typography>
      <Stack direction="row" alignItems="center">
        <Typography variant="h2" component="p" color={COLORS.PRIMITIVES.WHITE}>
          {formatPercentOrValue(value)}
        </Typography>
        {direction === 'up' && (
          <ArrowNarrowUpRightIcon
            size={24}
            sx={{ color: COLORS.PRIMITIVES.WHITE }}
          />
        )}
        {direction === 'down' && (
          <ArrowNarrowDownRightIcon
            size={24}
            sx={{ color: COLORS.PRIMITIVES.WHITE }}
          />
        )}
      </Stack>
    </Stack>
  );
}

type SmallMetricWithLabelProps = BigMetricWithLabelProps;

export function SmallMetricWithLabel({
  label,
  value,
  direction,
}: SmallMetricWithLabelProps) {
  return (
    <Stack direction="row" justifyContent="end">
      <Typography
        variant="subtitle1"
        color={COLORS.PRIMITIVES.WHITE}
        sx={{
          pr: 1,
        }}
      >
        {label}
      </Typography>
      <Typography
        variant="subtitle1"
        component="p"
        color={COLORS.PRIMITIVES.WHITE}
      >
        {formatPercentOrValue(value)}
      </Typography>
      {direction === 'up' && (
        <ArrowNarrowUpRightIcon
          size={16}
          sx={{ color: COLORS.PRIMITIVES.WHITE }}
        />
      )}
      {direction === 'down' && (
        <ArrowNarrowDownRightIcon
          size={16}
          sx={{ color: COLORS.PRIMITIVES.WHITE }}
        />
      )}
    </Stack>
  );
}
