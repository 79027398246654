import { Box, Grid } from '@mui/material';
import Decimal from 'decimal.js';
import { useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import {
  FormAwareDatePickerInput,
  FormAwareDatePickerInputProps,
} from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import {
  validateAtMost100Percent,
  ValidatedInputRepeaterPercentSumRow,
} from '@/components/form/formAwareInputs/FormAwareInputRepeater/ValidatedInputRepeaterPercentSumRow';
import { FormAwareMultiSelectInput } from '@/components/form/formAwareInputs/FormAwareMultiSelectInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import {
  FormAwareSelectInput,
  FormAwareSelectInputProps,
} from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Loader } from '@/components/progress/Loader/Loader';
import { useFormContext } from '@/components/react-hook-form';
import { LocatableFormAwareInput } from '@/modules/aiSuggestions/components/forms/LocatableFormAwareInput';
import { SuggestableFormAwareInput } from '@/modules/aiSuggestions/components/forms/SuggestableFormAwareInput';
import { useAISuggestionsEnabled } from '@/modules/aiSuggestions/hooks/useAISuggestionsEnabled';
import {
  AddableType,
  AddNewAnythingModal,
} from '@/modules/common/AddNewAnythingModal/AddNewAnythingModal';
import { AiSuggestionKind } from '@/types/schema';
import { getComparableDecimalJS } from '@/utils/decimalJSUtils';

import { useIsShortForm } from '../EntityShortFormModal/CreateEntityShortForm/context/CreateEntityShortForm.context';
import {
  BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE,
  BUSINESS_OWNERSHIP_PERCENTAGE_DECIMAL_SCALE,
  Fields,
  Props,
  SubformField,
} from './BusinessEntityDetailsSubform.types';
import { KEY_PERSON_ROLE_KIND_ITEMS } from './BusinessEntityDetailsSubform.utils';
import { useFetchBusinessEntityDetailsOptions } from './useGetBusinessEntityOwnerOptions';

type AddFromSelection = 'owners' | 'keyPeople';

export function UI(props: Props) {
  const { householdId, entityId, entityType } = props;

  const { control, setValue } = useFormContext<Fields>();
  const isShortFormVariant = useIsShortForm();
  const shouldGroupDuplicates = useAISuggestionsEnabled(entityType);

  const [addFromSelection, setAddFromSelection] =
    useState<AddFromSelection | null>(null);

  const addableTypesFromSelection: AddableType[] = useMemo(() => {
    switch (addFromSelection) {
      case 'keyPeople':
        return ['individual', 'organization', 'entity'];
      case 'owners':
        return ['entity', 'individual'];
      default:
        return [];
    }
  }, [addFromSelection]);

  const subformValues = useWatch({
    name: BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE,
    control,
  });

  const { selectedOwnerIds, selectedKeyPersonIds } = useMemo(() => {
    return {
      selectedOwnerIds: subformValues?.owners?.map((o) => o.ownerId) ?? [],
      selectedKeyPersonIds:
        subformValues?.keyPeople?.map((kp) => kp.keyPersonId) ?? [],
    };
  }, [subformValues]);

  const {
    ownerOptions,
    keyPeopleOptions,
    loading: formOptionsLoading,
  } = useFetchBusinessEntityDetailsOptions(
    householdId,
    entityId ?? null,
    selectedOwnerIds,
    selectedKeyPersonIds
  );

  const ownerships = useWatch({
    name: `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.owners`,
    control,
  });

  const ownershipSum = (ownerships || []).reduce((acc, o) => {
    acc = acc.add(o.percentOwned ?? new Decimal(0));
    return acc;
  }, new Decimal(0));
  useEffect(() => {
    setValue(
      `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}._ownershipSum` as const,
      ownershipSum
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // getComparableDecimalJS is the only way to avoid infinite setValues when the value
    // is a Decimal.js instance
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getComparableDecimalJS(
      ownershipSum,
      BUSINESS_OWNERSHIP_PERCENTAGE_DECIMAL_SCALE
    ),
    setValue,
  ]);

  if (formOptionsLoading) {
    return (
      <Loader
        boxProps={{
          sx: {
            textAlign: 'center',
            my: 3,
          },
        }}
      />
    );
  }

  return (
    <>
      <AddNewAnythingModal
        isOpen={addableTypesFromSelection.length > 0}
        addableTypes={addableTypesFromSelection}
        handleClose={() => setAddFromSelection(null)}
        householdId={householdId}
      />
      <FormFieldsDisabledProvider isDisabled={formOptionsLoading}>
        <Grid container spacing={3} columns={12}>
          <Grid item sm={12}>
            <FormLayoutRow>
              <FormLayoutItem width={12}>
                <FormAwareInputRepeater<Fields>
                  name={`${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.owners`}
                  emptyValue={{
                    ownerId: '',
                    percentOwned: null,
                  }}
                  control={control}
                  shouldInit
                  addAnotherItemText={`Add additional owner`}
                  rightFooterContent={
                    <ValidatedInputRepeaterPercentSumRow<Fields>
                      decimalScale={BUSINESS_OWNERSHIP_PERCENTAGE_DECIMAL_SCALE}
                      validationField={
                        `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}._ownershipSum` as const satisfies SubformField
                      }
                      validation={{
                        totalValue: (value) =>
                          validateAtMost100Percent(value as Decimal | null),
                      }}
                    />
                  }
                  render={(
                    i: number,
                    _props,
                    _id,
                    additionalItemRendererProps
                  ) => {
                    const hideLabel =
                      additionalItemRendererProps?.hideLabelOverride ?? i > 0;

                    return (
                      <FormLayoutRow>
                        <FormLayoutItem width={8}>
                          <LocatableFormAwareInput<
                            Fields,
                            FormAwareSelectInputProps<Fields>
                          >
                            FormAwareElement={FormAwareSelectInput}
                            suggestionKind={AiSuggestionKind.BeneficialOwner}
                            fieldName={
                              `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.owners.${i}.ownerId` as const satisfies SubformField
                            }
                            label={`Beneficial owners`}
                            hideLabel={isShortFormVariant ? true : hideLabel}
                            control={control}
                            options={ownerOptions}
                            disabled={formOptionsLoading}
                            required={true}
                            addNewOption={{
                              onClick: () => setAddFromSelection('owners'),
                            }}
                            additionalItemRendererProps={
                              additionalItemRendererProps
                            }
                          />
                        </FormLayoutItem>
                        <FormLayoutItem width={4} sx={{ alignSelf: 'end' }}>
                          <FormAwarePercentInput<Fields>
                            fieldName={
                              `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.owners.${i}.percentOwned` as const satisfies SubformField
                            }
                            label={'Percentage'}
                            hideLabel={isShortFormVariant ? true : hideLabel}
                            control={control}
                            decimalScale={
                              BUSINESS_OWNERSHIP_PERCENTAGE_DECIMAL_SCALE
                            }
                            isDecimalJSInput
                            required={true}
                          />
                        </FormLayoutItem>
                      </FormLayoutRow>
                    );
                  }}
                  idField="ownerId"
                  shouldGroupDuplicates={shouldGroupDuplicates}
                  duplicateGroupLabel="Duplicate beneficial owner from AI"
                  duplicateGroupCaption="Decide which beneficial owner's details to retain"
                />
              </FormLayoutItem>
            </FormLayoutRow>
            <FormLayoutRow>
              <FormLayoutItem width={12}>
                <Card
                  variant={isShortFormVariant ? 'filled-dark' : 'filled'}
                  sx={{ p: 2, mx: 2, mb: 1 }}
                >
                  <Box sx={{ maxWidth: 200 }}>
                    <SuggestableFormAwareInput<
                      Fields,
                      FormAwareDatePickerInputProps<Fields>
                    >
                      FormAwareElement={FormAwareDatePickerInput}
                      control={control}
                      fieldName={
                        `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.ownershipAsOfDate` as const satisfies SubformField
                      }
                      label="As of date"
                      required={true}
                      suggestionKind={AiSuggestionKind.BeneficialOwnersAsOfDate}
                    />
                  </Box>
                </Card>
              </FormLayoutItem>
            </FormLayoutRow>
            {!isShortFormVariant && (
              <>
                <FormLayoutRow>
                  <FormLayoutItem width={12}>
                    <FormAwareInputRepeater<Fields>
                      name={`${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.keyPeople`}
                      emptyValue={{
                        keyPersonId: '',
                        roles: [],
                      }}
                      control={control}
                      shouldInit
                      addAnotherItemText={`Add additional key person`}
                      render={(
                        i: number,
                        _props,
                        _id,
                        additionalItemRendererProps
                      ) => {
                        const hideLabel =
                          additionalItemRendererProps?.hideLabelOverride ??
                          i > 0;

                        return (
                          <FormLayoutRow>
                            <FormLayoutItem width={8}>
                              <LocatableFormAwareInput<
                                Fields,
                                FormAwareSelectInputProps<Fields>
                              >
                                FormAwareElement={FormAwareSelectInput}
                                suggestionKind={AiSuggestionKind.KeyPerson}
                                fieldName={
                                  `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.keyPeople.${i}.keyPersonId` as const satisfies SubformField
                                }
                                label="Key people"
                                hideLabel={hideLabel}
                                control={control}
                                options={keyPeopleOptions}
                                disabled={formOptionsLoading}
                                addNewOption={{
                                  onClick: () =>
                                    setAddFromSelection('keyPeople'),
                                }}
                                additionalItemRendererProps={
                                  additionalItemRendererProps
                                }
                              />
                            </FormLayoutItem>
                            <FormLayoutItem width={4} sx={{ alignSelf: 'end' }}>
                              <FormAwareMultiSelectInput<Fields>
                                fieldName={
                                  `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.keyPeople.${i}.roles` as const satisfies SubformField
                                }
                                label="Roles"
                                hideLabel={hideLabel}
                                control={control}
                                options={KEY_PERSON_ROLE_KIND_ITEMS}
                              />
                            </FormLayoutItem>
                          </FormLayoutRow>
                        );
                      }}
                      idField="keyPersonId"
                      shouldGroupDuplicates={shouldGroupDuplicates}
                      duplicateGroupLabel="Duplicate key person from AI"
                      duplicateGroupCaption="Decide which key person's details to retain"
                    />
                  </FormLayoutItem>
                </FormLayoutRow>
                <FormLayoutRow>
                  <FormLayoutItem>
                    <FormAwareTextInput<Fields>
                      control={control}
                      label="Notes on key people"
                      fieldName={
                        `${BUSINESS_ENTITY_DETAILS_SUBFORM_NAMESPACE}.keyPeopleNotes` as const satisfies SubformField
                      }
                      multiline
                      rows={2}
                    />
                  </FormLayoutItem>
                </FormLayoutRow>
              </>
            )}
          </Grid>
        </Grid>
      </FormFieldsDisabledProvider>
    </>
  );
}
