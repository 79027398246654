import { FitViewOptions, useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import { useViewportTransform } from '@/components/diagrams/FlowChart/hooks/useViewportTransform';

import { useFitViewOptions } from '../hooks';
import { EstateWaterfallEffectFn, FitViewEffect } from '../types';

export function useFitViewEffect(): EstateWaterfallEffectFn<FitViewEffect> {
  const { fitView, getViewport, setViewport } = useReactFlow();
  const { getFitViewOptions } = useFitViewOptions();
  const { getTransform } = useViewportTransform();

  return useCallback<EstateWaterfallEffectFn<FitViewEffect>>(
    (_state, { opts, perserveZoomLevel, kind }, _dispatch) => {
      const currentZoom = getTransform()[2];
      const preservedZoomLevelOpts: FitViewOptions = {
        minZoom: currentZoom,
        maxZoom: currentZoom,
      };

      const finalOpts = {
        ...(opts ?? getFitViewOptions(kind === 'init')),
        ...(perserveZoomLevel && preservedZoomLevelOpts),
      };

      void fitView(finalOpts);

      if (kind === 'init') {
        // If we are initializing, we want to accomodate the top section label
        const viewport = getViewport();

        void setViewport(viewport);
      }
    },
    [fitView, getFitViewOptions, getTransform, getViewport, setViewport]
  );
}
