import Decimal from 'decimal.js';
import { compact, difference, first, intersection } from 'lodash';

import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import {
  AccessParameterKind,
  AugmentedCreateBeneficiaryInput,
  AugmentedCreateCltProposalInput,
  AugmentedCreateCrtProposalInput,
  AugmentedCreateProposalInput,
  AugmentedUpdateBeneficiaryInput,
  AugmentedUpdateCltProposalInput,
  AugmentedUpdateCrtProposalInput,
  AugmentedUpdateProposalInput,
  CharitableTrustPayoutKind,
  CharitableTrustTermKind,
  CreateBeneficiaryInput,
  CreateProposalInput,
  EntityKind,
  UpdateProposalInput,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import {
  DEFAULT_PRE_TAX_RETURNS,
  DEFAULT_TAX_DRAG_PERCENTAGES,
} from '../../designerConstants';
import {
  CharitableTrustDesignerBasicInformationBeneficiary,
  CharitableTrustDesignerBasicInformationBeneficiaryOption as BeneficiaryOption,
  CharitableTrustDesignerBasicInformationDonor,
  CharitableTrustDesignerBasicInformationForm,
  NAMESPACE,
} from './CharitableTrustDesignerBasicInformation.types';
import { CharitableTrustDesignerBeneficiariesQuery } from './graphql/CharitableTrustDesignerBeneficiaries.generated';
import {
  CharitableTrustDesignerData_BeneficiaryFragment,
  CharitableTrustDesignerDataQuery,
} from './graphql/CharitableTrustDesignerData.generated';

export function mapBeneficiaryDataToDisplay(
  data: CharitableTrustDesignerBeneficiariesQuery | undefined
): {
  nonCharitableBeneficiaries: BeneficiaryOption[];
  charitableBeneficiaries: BeneficiaryOption[];
} {
  const nonCharitableEntityOptions: BeneficiaryOption[] = [];
  const charitableEntityOptions: BeneficiaryOption[] = [];

  const advisorClient = first(getNodes(data?.households));

  const {
    entities,
    clientOrganizations = [],
    clientProfiles = [],
  } = advisorClient || {};

  getNodes(entities).forEach((entity) => {
    switch (entity.kind) {
      case EntityKind.PrivateFoundation:
      case EntityKind.DonorAdvisedFund:
        charitableEntityOptions.push({
          display: entity.subtype.displayName,
          value: entity.id,
          kind: 'entity',
        });
        break;
      case EntityKind.CltTrust:
      case EntityKind.CrtTrust:
        // intentionally left blank
        break;
      default:
        nonCharitableEntityOptions.push({
          display: entity.subtype.displayName,
          value: entity.id,
          kind: 'entity',
        });
        break;
    }
    entity.kind;
  });

  const clientOrganizationOptions =
    clientOrganizations?.map<BeneficiaryOption>((clientOrganization) => ({
      display: clientOrganization.name,
      value: clientOrganization.id,
      kind: 'organization',
    })) || [];

  const clientProfileOptions =
    clientProfiles?.map<BeneficiaryOption>((clientProfile) => ({
      display: clientProfile.legalName,
      value: clientProfile.id,
      kind: 'individual',
    })) || [];

  return {
    nonCharitableBeneficiaries: compact([
      clientProfileOptions.length
        ? {
            component: <SelectItemGroupLabel label="Individuals" />,
            type: 'component' as const,
          }
        : null,
      ...clientProfileOptions,
      nonCharitableEntityOptions.length
        ? {
            component: <SelectItemGroupLabel label="Entities" />,
            type: 'component' as const,
          }
        : null,
      ...nonCharitableEntityOptions,
    ]),
    charitableBeneficiaries: compact([
      clientOrganizationOptions.length
        ? {
            component: <SelectItemGroupLabel label="Organizations" />,
            type: 'component' as const,
          }
        : null,
      ...clientOrganizationOptions,
      charitableEntityOptions.length
        ? {
            component: <SelectItemGroupLabel label="Entities" />,
            type: 'component' as const,
          }
        : null,
      ...charitableEntityOptions,
    ]),
  };
}

function mapBeneficiaryToForm(
  beneficiary: CharitableTrustDesignerData_BeneficiaryFragment
): CharitableTrustDesignerBasicInformationBeneficiary {
  let kind: CharitableTrustDesignerBasicInformationBeneficiary['kind'] = null;
  let id = '';
  if (beneficiary.entity) {
    kind = 'entity';
    id = beneficiary.entity.id;
  } else if (beneficiary.individual) {
    kind = 'individual';
    id = beneficiary.individual.id;
  } else if (beneficiary.organization) {
    kind = 'organization';
    id = beneficiary.organization.id;
  }

  return {
    id,
    percentageOwnership:
      // each beneficiary should only have a single distribution, so disregard the others
      first(getNodes(beneficiary.accessParameters))?.percentage ||
      new Decimal(0),
    kind,
    beneficiaryNodeId: beneficiary.id,
  };
}

export function mapExistingProposalDataToFrom(
  data: CharitableTrustDesignerDataQuery
): CharitableTrustDesignerBasicInformationForm | null {
  const proposal = first(getNodes(data.proposals));

  if (!proposal) {
    return null;
  }

  const donors = (
    proposal.cltProposal?.donors ||
    proposal.crtProposal?.donors ||
    []
  ).map<CharitableTrustDesignerBasicInformationDonor>((donor) => ({
    id: donor.id,
  }));

  let charitableBeneficiaries = (
    proposal.cltProposal?.charitableIncomeBeneficiaries ||
    proposal.crtProposal?.charitableRemainderBeneficiaries ||
    []
  ).map(mapBeneficiaryToForm);

  if (!charitableBeneficiaries.length) {
    charitableBeneficiaries = [
      { id: '', percentageOwnership: null, kind: null },
    ];
  }

  let nonCharitableBeneficiaries = (
    proposal.cltProposal?.remainderBeneficiaries ||
    proposal?.crtProposal?.incomeBeneficiaries ||
    []
  ).map(mapBeneficiaryToForm);

  if (!nonCharitableBeneficiaries.length) {
    nonCharitableBeneficiaries = [
      { id: '', percentageOwnership: null, kind: null },
    ];
  }

  return {
    [NAMESPACE]: {
      id: proposal.id,
      proposalId: proposal.cltProposal?.id || proposal.crtProposal?.id,
      name: proposal.displayName,
      donors,
      taxStatus: proposal.cltProposal?.taxStatus || '',
      charitableBeneficiaries,
      nonCharitableBeneficiaries,
      _validationFields: {
        charitableBeneficiaries: new Decimal(0),
        nonCharitableBeneficiaries: new Decimal(0),
      },
    },
  };
}

function mapToCreateBeneficiary(
  beneficiary: CharitableTrustDesignerBasicInformationBeneficiary,
  householdId: string
): AugmentedCreateBeneficiaryInput {
  const create: CreateBeneficiaryInput = {};
  switch (beneficiary.kind) {
    case 'entity':
      create.entityID = beneficiary.id;
      break;
    case 'individual':
      create.individualID = beneficiary.id;
      break;
    case 'organization':
      create.organizationID = beneficiary.id;
      break;
    case null:
      throw new Error(`Invalid null beneficiary kind for ${beneficiary.id}`);
    default:
      throw new UnreachableError({
        case: beneficiary.kind,
        message: `Invalid beneficiary kind for ${beneficiary.id}`,
      });
  }
  return {
    create,
    withAccessParameters: [
      {
        create: {
          kind: AccessParameterKind.Percentage,
          percentage: beneficiary.percentageOwnership,
          householdID: householdId,
        },
      },
    ],
  };
}

function mapToUpdateBeneficiary(
  {
    beneficiaryNodeId,
    percentageOwnership,
  }: CharitableTrustDesignerBasicInformationBeneficiary,
  loadedAccessParameterId: string | undefined
): AugmentedUpdateBeneficiaryInput {
  if (!beneficiaryNodeId) {
    throw new Error('ID required to update existing distribution');
  }

  // Update both distributions and access parameters
  // depending on what we have in the context
  return {
    id: beneficiaryNodeId,
    update: {},
    updateAccessParameters: loadedAccessParameterId
      ? [
          {
            id: loadedAccessParameterId,
            update: {
              kind: AccessParameterKind.Percentage,
              percentage: percentageOwnership,
            },
          },
        ]
      : [],
  };
}

export function mapFormDataToCreateProposalInput(
  formData: CharitableTrustDesignerBasicInformationForm,
  householdID: string,
  isCRT: boolean
): AugmentedCreateProposalInput {
  const data = formData[NAMESPACE];

  const create: CreateProposalInput = {
    householdID,
    clientNotes: '',
    displayName: data.name,
    includeCumulativeView: false,
  };
  const charitableBeneficiaries: AugmentedCreateBeneficiaryInput[] =
    data.charitableBeneficiaries
      .filter(({ id }) => !!id)
      .map((b) => mapToCreateBeneficiary(b, householdID));

  const nonCharitableBeneficiaries: AugmentedCreateBeneficiaryInput[] =
    data.nonCharitableBeneficiaries
      .filter(({ id }) => !!id)
      .map((b) => mapToCreateBeneficiary(b, householdID));

  let withCrtProposal: AugmentedCreateCrtProposalInput | undefined = undefined;
  let withCltProposal: AugmentedCreateCltProposalInput | undefined = undefined;

  if (isCRT) {
    withCrtProposal = {
      create: {
        assetCostBasis: new Decimal(0),
        assetValue: new Decimal(0),
        donorIDs: data.donors.map<string>((donor) => donor.id),
        payoutKind: CharitableTrustPayoutKind.Annuity,
        annuityPayoutAmount: new Decimal(0),
        termKind: CharitableTrustTermKind.Fixed,
        termYears: 10,
        ...DEFAULT_PRE_TAX_RETURNS,
        ...DEFAULT_TAX_DRAG_PERCENTAGES,
      },
      withCharitableRemainderBeneficiaries: charitableBeneficiaries,
      withIncomeBeneficiaries: nonCharitableBeneficiaries,
    };
  } else {
    if (!data.taxStatus) {
      throw new Error('Tax status is required');
    }
    withCltProposal = {
      create: {
        taxStatus: data.taxStatus,
        assetCostBasis: new Decimal(0),
        assetValue: new Decimal(0),
        donorIDs: data.donors.map<string>((donor) => donor.id),
        payoutKind: CharitableTrustPayoutKind.Annuity,
        annuityPayoutAmount: new Decimal(0),
        termKind: CharitableTrustTermKind.Fixed,
        termYears: 10,
        ...DEFAULT_PRE_TAX_RETURNS,
        ...DEFAULT_TAX_DRAG_PERCENTAGES,
      },
      withCharitableIncomeBeneficiaries: charitableBeneficiaries,
      withRemainderBeneficiaries: nonCharitableBeneficiaries,
    };
  }
  const input: AugmentedCreateProposalInput = {
    create,
    withCrtProposal,
    withCltProposal,
  };
  return input;
}

export function mapFormDataToUpdateProposalInput(
  formData: CharitableTrustDesignerBasicInformationForm,
  householdID: string,
  isCRT: boolean,
  loadedData: CharitableTrustDesignerDataQuery | undefined
): AugmentedUpdateProposalInput {
  const data = formData[NAMESPACE];
  if (!data) {
    throw new Error('Invalid proposal data for update');
  }

  if (!data.id || !data.proposalId) {
    throw new Error('Invalid proposal ID for update');
  }

  if (!loadedData) {
    throw new Error('Could not get existing data to update');
  }

  const loadedProposal = first(getNodes(loadedData?.proposals));

  if (!loadedProposal) {
    throw new Error('Could not get loaded proposal to update');
  }

  const update: UpdateProposalInput = {
    householdID,
    displayName: data.name,
    includeCumulativeView: false,
  };

  let updateCltProposal: AugmentedUpdateCltProposalInput | undefined =
    undefined;
  let updateCrtProposal: AugmentedUpdateCrtProposalInput | undefined =
    undefined;

  const currentDonorIds = data.donors.map<string>(({ id }) => id);
  const initialDonorIds = (
    loadedProposal.cltProposal?.donors ||
    loadedProposal.crtProposal?.donors ||
    []
  ).map<string>(({ id }) => id);

  const addDonorIDs: string[] = difference(currentDonorIds, initialDonorIds);
  const removeDonorIDs: string[] = difference(initialDonorIds, currentDonorIds);

  const currentCharitableBeneficiaryIDs: string[] = compact(
    data.charitableBeneficiaries.map(
      ({ beneficiaryNodeId }) => beneficiaryNodeId
    )
  );
  const loadedCharitableBeneficiaries =
    loadedProposal.cltProposal?.charitableIncomeBeneficiaries ||
    loadedProposal.crtProposal?.charitableRemainderBeneficiaries;

  const initialCharitableBeneficiaryIDs =
    loadedCharitableBeneficiaries?.map<string>(({ id }) => id);
  const updatedCharitableBeneficiearyIDs = intersection(
    currentCharitableBeneficiaryIDs,
    initialCharitableBeneficiaryIDs
  );
  const removedCharitableBeneficiaryIDs = difference(
    initialCharitableBeneficiaryIDs,
    currentCharitableBeneficiaryIDs
  );

  const addedCharitableBeneficiaries = data.charitableBeneficiaries
    .filter(({ id, beneficiaryNodeId }) => !!id && !beneficiaryNodeId) // added if selection has ID, lacks beneficiaryNodeId
    .map((b) => mapToCreateBeneficiary(b, householdID));
  const updatedCharitableBeneficiaries = data.charitableBeneficiaries
    .filter(
      ({ beneficiaryNodeId }) =>
        !!beneficiaryNodeId &&
        updatedCharitableBeneficiearyIDs.includes(beneficiaryNodeId)
    )
    .map((beneficiary) => {
      const loadedBeneficiary = loadedCharitableBeneficiaries?.find(
        ({ id }) => id === beneficiary.beneficiaryNodeId
      );
      const loadedAccessParameterId = first(
        getNodes(loadedBeneficiary?.accessParameters)
      )?.id;
      return mapToUpdateBeneficiary(beneficiary, loadedAccessParameterId);
    });

  const currentNonCharitableBeneficiaryIDs: string[] = compact(
    data.nonCharitableBeneficiaries.map(
      ({ beneficiaryNodeId }) => beneficiaryNodeId
    )
  );
  const loadedNonCharitableBeneficiaries =
    loadedProposal.cltProposal?.remainderBeneficiaries ||
    loadedProposal.crtProposal?.incomeBeneficiaries;
  const initialNonCharitableBeneficiaryIDs = (
    loadedNonCharitableBeneficiaries || []
  ).map(({ id }) => id);

  const updatedNonCharitableBeneficiaryIDs = intersection(
    currentNonCharitableBeneficiaryIDs,
    initialNonCharitableBeneficiaryIDs
  );
  const removedNonCharitableBeneficiaryIDs = difference(
    initialNonCharitableBeneficiaryIDs,
    currentNonCharitableBeneficiaryIDs
  );

  const addedNonCharitableBeneficiaries = data.nonCharitableBeneficiaries
    .filter(({ id, beneficiaryNodeId }) => !!id && !beneficiaryNodeId) // added if selection has ID, lacks beneficiaryNodeId
    .map((b) => mapToCreateBeneficiary(b, householdID));

  const updatedNonCharitableBeneficiaries = data.nonCharitableBeneficiaries
    .filter(
      ({ beneficiaryNodeId }) =>
        !!beneficiaryNodeId &&
        updatedNonCharitableBeneficiaryIDs.includes(beneficiaryNodeId)
    )
    .map((beneficiary) => {
      const loadedBeneficiary = loadedNonCharitableBeneficiaries?.find(
        ({ id }) => id === beneficiary.beneficiaryNodeId
      );
      const loadedAccessParameterId = first(
        getNodes(loadedBeneficiary?.accessParameters)
      )?.id;
      return mapToUpdateBeneficiary(beneficiary, loadedAccessParameterId);
    });

  if (isCRT) {
    updateCrtProposal = {
      id: data.proposalId,
      update: {
        addDonorIDs,
        removeDonorIDs,
        removeCharitableRemainderBeneficiaryIDs:
          removedCharitableBeneficiaryIDs,
        removeIncomeBeneficiaryIDs: removedNonCharitableBeneficiaryIDs,
      },
      updateCharitableRemainderBeneficiaries: updatedCharitableBeneficiaries,
      updateIncomeBeneficiaries: updatedNonCharitableBeneficiaries,
      withCharitableRemainderBeneficiaries: addedCharitableBeneficiaries,
      withIncomeBeneficiaries: addedNonCharitableBeneficiaries,
    };
  } else {
    if (!data.taxStatus) {
      throw new Error('Tax status is required to update CLT');
    }
    updateCltProposal = {
      id: data.proposalId,
      update: {
        addDonorIDs,
        removeDonorIDs,
        taxStatus: data.taxStatus,
        removeCharitableIncomeBeneficiaryIDs: removedCharitableBeneficiaryIDs,
        removeRemainderBeneficiaryIDs: removedNonCharitableBeneficiaryIDs,
      },
      updateCharitableIncomeBeneficiaries: updatedCharitableBeneficiaries,
      updateRemainderBeneficiaries: updatedNonCharitableBeneficiaries,
      withCharitableIncomeBeneficiaries: addedCharitableBeneficiaries,
      withRemainderBeneficiaries: addedNonCharitableBeneficiaries,
    };
  }

  const input: AugmentedUpdateProposalInput = {
    id: data.id,
    update,
    updateCltProposal,
    updateCrtProposal,
  };
  return input;
}
