import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';

import { AssetOption } from '../../HypotheticalTransfersCard/hypotheticalTransfers/hooks/useAssetOptions';
import { HypotheticalTransferFormModalShape } from '../HypotheticalTransferModal.types';

interface AssetOptionsProps {
  options: AssetOption[];
  disabled: boolean;
  hide: boolean;
}

export function AssetOptions({ options, disabled, hide }: AssetOptionsProps) {
  const { control } = useFormContext<HypotheticalTransferFormModalShape>();

  return (
    <FormLayoutRow
      sx={{
        display: hide ? 'none' : undefined,
      }}
    >
      <Card variant="filled-dark" sx={{ p: 2, gridColumn: '1 / -1' }}>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareSelectInput<HypotheticalTransferFormModalShape>
              control={control}
              fieldName={
                'sourceOwnedEntityId' as const satisfies keyof HypotheticalTransferFormModalShape
              }
              label="Asset"
              options={options}
              showEmptyValue={false}
              disabled={disabled}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      </Card>
    </FormLayoutRow>
  );
}
