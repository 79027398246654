import { Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { noop } from 'lodash';

import { LinkButton } from '@/components/form/baseInputs/Link';
import { PopperContent } from '@/components/poppers/PopperContent';
import { formatPercent } from '@/utils/formatting/percent';

interface Props {
  handleClick?: () => void;
  isOpen?: boolean;
  taxDrag: Decimal;
}

export function TaxDragAssumptions({ handleClick = noop, taxDrag }: Props) {
  return (
    <PopperContent
      heading={'Tax drag assumptions'}
      body={
        <Typography variant="subtitle2" component="span">
          All entities other than grantor trusts are modeled with an assumed{' '}
          <b>{formatPercent(taxDrag, 0)}% tax drag </b>applied to the annual
          pre-tax return rate.
        </Typography>
      }
      actions={<LinkButton display="Modify tax drag" onClick={handleClick} />}
    />
  );
}
