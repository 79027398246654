import { Box, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Divider } from '@/components/Divider';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { SidebarContainer } from '@/components/layout/SidebarContainer/SidebarContainer';
import { UnstyledNavLink } from '@/components/navigation/UnstyledNavLink';
import { Callout } from '@/components/notifications/Callout/Callout';
import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';
import { EmptyListItem } from '@/components/typography/EmptyListItem';
import { useReportError } from '@/hooks/useReportError';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { COLORS } from '@/styles/tokens/colors';

import { getLinkForRecentEntity } from './entitiesUtils';
import {
  RecentEntitiesQuery,
  useRecentEntitiesQuery,
} from './graphql/RecentEntitiesSidebar.generated';

interface EntityLinkProps {
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  path: string;
}

function EntityLinkButton({ heading, subheading, path }: EntityLinkProps) {
  return (
    <UnstyledNavLink to={path}>
      <Box
        px={1.5}
        py={2}
        sx={{
          border: 'none',
          background: 'transparent',
          textAlign: 'left',
          cursor: 'pointer',
          textDecoration: 'none',
          ':hover': { background: COLORS.FUNCTIONAL.HOVER },
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="body1">{heading}</Typography>
            {subheading && (
              <Typography variant="subtitle2">{subheading}</Typography>
            )}
          </Box>
          <Box>
            <ChevronRightIcon color={COLORS.GRAY[400]} size={16} />
          </Box>
        </Stack>
      </Box>
    </UnstyledNavLink>
  );
}

interface RecentEntitiesListItem {
  heading: string;
  subheading?: React.ReactNode;
  path: string;
}

interface RecentEntitiesListProps {
  heading: string;
  items: RecentEntitiesListItem[];
}

function RecentEntitiesList({ heading, items }: RecentEntitiesListProps) {
  return (
    <Box>
      <Box
        component="header"
        py={0.5}
        sx={{ borderBottom: `solid 2px ${COLORS.ORANGE[600]}` }}
      >
        <Typography variant="h6">{heading}</Typography>
      </Box>
      <Stack divider={<Divider />}>
        {items.length === 0 && (
          <EmptyListItem>No {heading.toLowerCase()}</EmptyListItem>
        )}
        {items.map((item, i) => (
          <EntityLinkButton key={i} {...item} />
        ))}
      </Stack>
    </Box>
  );
}

function generateRecentItemsFromData(
  data: RecentEntitiesQuery | undefined,
  opts = { showClientNames: true }
): {
  recentClients: RecentEntitiesListItem[];
  recentProposals: RecentEntitiesListItem[];
  recentEntities: RecentEntitiesListItem[];
} {
  if (!data) {
    return {
      recentClients: [],
      recentProposals: [],
      recentEntities: [],
    };
  }

  const recentClients: RecentEntitiesListItem[] = data.recentClients.map(
    (client) => ({
      heading: client.displayName,
      entity: client,
      path: getLinkForRecentEntity(client),
    })
  );

  const recentProposals: RecentEntitiesListItem[] = data.recentProposals.map(
    (proposal) => ({
      heading: proposal.displayName,
      subheading: opts.showClientNames ? proposal.household.displayName : '',
      path: getLinkForRecentEntity(proposal),
    })
  );

  const recentEntities: RecentEntitiesListItem[] = data.recentEntities.map(
    (entity) => ({
      heading: entity.subtype.displayName,
      subheading: opts.showClientNames ? entity.household.displayName : '',
      path: getLinkForRecentEntity(entity),
    })
  );

  return {
    recentClients,
    recentProposals,
    recentEntities,
  };
}

export interface RecentEntitiesSidebarProps {
  showRecentClients?: boolean;
}

export function RecentEntitiesSidebarContents({
  showRecentClients,
}: RecentEntitiesSidebarProps) {
  const currentUser = useCurrentUser();
  const params = useParams();
  const householdId = params.householdId;

  const {
    error,
    data,
    loading: loadingFromNetwork,
  } = useRecentEntitiesQuery({
    variables: {
      householdId,
      first: 4,
    },
    fetchPolicy: 'cache-and-network',
    skip: !currentUser,
  });

  useReportError('error loading recents sidebar', error);

  if (error) {
    return <Callout severity="error">Failed to load recent items.</Callout>;
  }

  // if we're already filtering items down to just those for a single householdId, don't worry about showing
  // what would be very repetitive client names
  const { recentClients, recentProposals, recentEntities } =
    generateRecentItemsFromData(data, {
      showClientNames: !householdId,
    });

  return (
    <>
      <LoadingOverlay open={loadingFromNetwork && !data} />
      <Stack spacing={3} p={3} pt={0} pr={0}>
        {showRecentClients && (
          <RecentEntitiesList heading="Recent clients" items={recentClients} />
        )}
        <RecentEntitiesList
          heading="Recent proposals"
          items={recentProposals}
        />
        <RecentEntitiesList heading="Recent entities" items={recentEntities} />
      </Stack>
    </>
  );
}

export function RecentEntitiesSidebar({
  ...props
}: RecentEntitiesSidebarProps) {
  return (
    <SidebarContainer
      sx={{ p: 0, m: 0, position: 'relative' }}
      showVisibilityHandle
    >
      <RecentEntitiesSidebarContents {...props} />
    </SidebarContainer>
  );
}
