import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import { useCallback, useMemo } from 'react';

import {
  ChartColorDefinitions,
  useChartColorDefinitions,
} from '@/components/charts/constants';
import {
  Section,
  Subsection,
} from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import { LinkButton } from '@/components/form/baseInputs/Link';
import { PopperContent } from '@/components/poppers/PopperContent';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { getStripedBackgroundCSS } from '@/styles/styleUtils';
import { AfterDeath, EntityInEstateStatus } from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { UnreachableError } from '@/utils/errors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import {
  getCombinedTaxStateAndFederal,
  getIsCharitableNode,
  getSectionLabel,
} from '../../../EstateWaterfall.utils';
import {
  GetWaterfallSummary_EstateWaterfallFragment,
  GetWaterfallSummary_EstateWaterfallVizNodeFragment,
} from '../../../graphql/GetWaterfallSummary.generated';
import { EstateWaterfallSummaryBarChartsTooltip } from '../EstateWaterfallSummaryBarChartsTooltip';
import { LifeInsuranceTooltip } from '../LifeInsuranceTooltip';
import {
  EstateWaterfallSummaryBarChartsSections,
  WaterfallSection,
  WaterfallSections,
} from '../types';

export const IN_ESTATE_INDEX = 0 as const;
export const OUT_OF_ESTATE_FAMILY_INDEX = 1 as const;
export const OUT_OF_ESTATE_CHARITY_INDEX = 2 as const;
export const GIFT_AND_ESTATE_TAX_INDEX = 3 as const;

const DEATH_BENEFIT_SUBSECTION_INDEX = 0 as const;

type InEstateSubSections = [Subsection];

function nodeToSections<T extends WaterfallSections>(
  acc: T,
  vizNode: GetWaterfallSummary_EstateWaterfallVizNodeFragment
) {
  const estateStatus = vizNode.inEstateStatus;

  if (estateStatus === EntityInEstateStatus.InEstate) {
    const inEstateSection = acc[IN_ESTATE_INDEX];
    inEstateSection.value += vizNode.value.toNumber();
    inEstateSection.nodes.push(vizNode);
  }

  if (estateStatus === EntityInEstateStatus.OutOfEstate) {
    const isCharitableNode = getIsCharitableNode(vizNode.node);

    if (isCharitableNode) {
      acc[OUT_OF_ESTATE_CHARITY_INDEX].value += vizNode.value.toNumber();
      acc[OUT_OF_ESTATE_CHARITY_INDEX].nodes.push(vizNode);
    } else {
      // Out of estate family includes all other entities, accounts, and
      // individuals
      acc[OUT_OF_ESTATE_FAMILY_INDEX].value += vizNode.value.toNumber();
      acc[OUT_OF_ESTATE_FAMILY_INDEX].nodes.push(vizNode);
    }
  }

  if (vizNode.addedDeathBenefit?.greaterThan(0)) {
    const sectionToUpdate = (() => {
      switch (estateStatus) {
        case EntityInEstateStatus.InEstate:
          return acc[IN_ESTATE_INDEX];
        case EntityInEstateStatus.OutOfEstate:
          return acc[OUT_OF_ESTATE_FAMILY_INDEX];
        default:
          throw new UnreachableError({
            case: estateStatus,
            message: `Unexpected estate status: ${estateStatus}`,
          });
      }
    })();

    const futureDeathBenefit = vizNode.addedDeathBenefit.toNumber();
    sectionToUpdate.nodes.push(vizNode);

    // if no subsections have been set, set them up with the value of the first life insurance entity
    if (!sectionToUpdate.subsections) {
      sectionToUpdate.subsections = [];
      sectionToUpdate.subsections[DEATH_BENEFIT_SUBSECTION_INDEX] = {
        value: futureDeathBenefit,
        color: sectionToUpdate.color,
        backgroundCss: getStripedBackgroundCSS(sectionToUpdate.color),
      };
    } else {
      // if there are multiple life insurance entities, continue to sum up the value
      const subsections = sectionToUpdate.subsections as InEstateSubSections;
      subsections[DEATH_BENEFIT_SUBSECTION_INDEX].value += futureDeathBenefit;
    }
  }

  return acc;
}

export const getDefaultWaterfallBarChartSections = ({
  dataVisColors,
  afterDeath,
}: {
  dataVisColors: ChartColorDefinitions;
  afterDeath: AfterDeath;
}): WaterfallSections => {
  return [
    // In estate
    {
      value: 0,
      color: dataVisColors.PRIMARY.backgroundColor,
      sectionTextColor: dataVisColors.PRIMARY.contrastText,
      kind: EstateWaterfallSummaryBarChartsSections.InEstate,
      afterDeath,
      nodes: [],
    },
    // Out of estate (family)
    {
      value: 0,
      color: dataVisColors.SECONDARY.backgroundColor,
      sectionTextColor: dataVisColors.SECONDARY.contrastText,
      kind: EstateWaterfallSummaryBarChartsSections.OutOfEstateFamily,
      afterDeath,
      nodes: [],
    },
    // Out of estate (charity)
    {
      value: 0,
      color: dataVisColors.TERTIARY.backgroundColor,
      sectionTextColor: dataVisColors.TERTIARY.contrastText,
      kind: EstateWaterfallSummaryBarChartsSections.OutOfEstateCharity,
      afterDeath,
      nodes: [],
    },
    // Estate tax
    {
      value: 0,
      color: dataVisColors.NEGATIVE.backgroundColor,
      sectionTextColor: dataVisColors.NEGATIVE.contrastText,
      kind: EstateWaterfallSummaryBarChartsSections.GiftAndEstateTax,
      afterDeath,
      nodes: [],
    },
  ];
};

export interface UseWaterfallSummaryBarChartSectionsProps {
  waterfall: Omit<GetWaterfallSummary_EstateWaterfallFragment, 'visualization'>;
  onOpenSectionSummaryPanel?: (section: WaterfallSection) => void;
}

export function getWaterfallSummaryBarChartSections({
  waterfall,
  isTwoGrantorClient,
  beforeFirstDeathTaxSummary,
  firstDeathTaxSummary,
  secondDeathTaxSummary,
  getDefaultSections,
  onOpenSectionSummaryPanel,
  onOpenSectionTaxModal,
}: {
  waterfall: Omit<GetWaterfallSummary_EstateWaterfallFragment, 'visualization'>;
  isTwoGrantorClient: boolean;
  beforeFirstDeathTaxSummary: GetWaterfallSummary_EstateWaterfallFragment['visualizationWithProjections']['beforeFirstDeathTaxSummary'];
  firstDeathTaxSummary: GetWaterfallSummary_EstateWaterfallFragment['visualizationWithProjections']['firstDeathTaxSummary'];
  secondDeathTaxSummary: GetWaterfallSummary_EstateWaterfallFragment['visualizationWithProjections']['secondDeathTaxSummary'];
  getDefaultSections: (afterDeath: AfterDeath) => WaterfallSections;
  onOpenSectionSummaryPanel?: (section: WaterfallSection) => void;
  onOpenSectionTaxModal?: (waterfallId: string) => void;
}) {
  const firstDeathYear = waterfall?.firstGrantorDeathYear;
  const secondDeathYear = waterfall?.secondGrantorDeathYear;

  const viz = waterfall?.visualizationWithProjections;

  const nodes = viz?.nodes ?? [];
  const currentSections = nodes
    .filter((n) => n.afterDeath === AfterDeath.None)
    .reduce(nodeToSections, getDefaultSections(AfterDeath.None));
  const firstDeathSections = nodes
    .filter((n) => n.afterDeath === AfterDeath.First)
    .reduce(nodeToSections, getDefaultSections(AfterDeath.First));
  const secondDeathSections = nodes
    .filter((n) => n.afterDeath === AfterDeath.Second)
    .reduce(nodeToSections, getDefaultSections(AfterDeath.Second));

  // Add click handler to all sections
  [...currentSections, ...firstDeathSections, ...secondDeathSections].forEach(
    (section) => {
      if (
        section.kind !==
        EstateWaterfallSummaryBarChartsSections.GiftAndEstateTax
      ) {
        return;
      }

      section.onClick = () => {
        onOpenSectionSummaryPanel?.(section);
        onOpenSectionTaxModal?.(waterfall?.id);
      };
    }
  );

  let taxesPaidAtFirstDeath = new Decimal(0);
  let taxesPaidAtSecondDeath = new Decimal(0);

  (function makeEstateTaxSections() {
    // Pass through all estate tax values to the next section
    // to show the total estate tax paid at each death
    taxesPaidAtFirstDeath = getCombinedTaxStateAndFederal(firstDeathTaxSummary);
    taxesPaidAtSecondDeath = getCombinedTaxStateAndFederal(
      secondDeathTaxSummary
    );

    currentSections[GIFT_AND_ESTATE_TAX_INDEX].value = 0;
    firstDeathSections[GIFT_AND_ESTATE_TAX_INDEX].value = 0;
    secondDeathSections[GIFT_AND_ESTATE_TAX_INDEX].value = 0;

    firstDeathSections[GIFT_AND_ESTATE_TAX_INDEX].value +=
      taxesPaidAtFirstDeath.toNumber();

    secondDeathSections[GIFT_AND_ESTATE_TAX_INDEX].value +=
      taxesPaidAtFirstDeath.toNumber() + taxesPaidAtSecondDeath.toNumber(); // Note this is a rolling sum
  })();

  let giftTaxesPaidNow = new Decimal(0);

  (function makeGiftTaxSection() {
    // Apply the gift tax to the first section.
    // Gift tax will be included in the rolling sum of all taxes paid.
    const currentTransferredToGiftingTax = sumDecimalJS(
      beforeFirstDeathTaxSummary?.stateTax?.map((t) => t.tax) ?? []
    ).plus(beforeFirstDeathTaxSummary?.federalTax?.tax ?? new Decimal(0));

    giftTaxesPaidNow = currentTransferredToGiftingTax;

    const currentTransferredToGiftingTaxNum =
      currentTransferredToGiftingTax.toNumber();

    currentSections[GIFT_AND_ESTATE_TAX_INDEX].value +=
      currentTransferredToGiftingTaxNum;

    firstDeathSections[GIFT_AND_ESTATE_TAX_INDEX].value +=
      currentTransferredToGiftingTaxNum;

    secondDeathSections[GIFT_AND_ESTATE_TAX_INDEX].value +=
      currentTransferredToGiftingTaxNum;
  })();

  (function addTooltipsToSections() {
    const addTooltip = (section: WaterfallSection) => {
      if (section.subsections) {
        const subsections = section.subsections as InEstateSubSections;
        section.tooltip = (
          <LifeInsuranceTooltip
            section={section}
            insuranceValueSubsection={
              subsections[DEATH_BENEFIT_SUBSECTION_INDEX]
            }
          />
        );
      } else if (
        section.kind !==
        EstateWaterfallSummaryBarChartsSections.GiftAndEstateTax
      ) {
        section.tooltip = (
          <PopperContent
            sx={{
              width: 'auto',
            }}
            body={
              <MiniTable
                variant="default"
                rows={[
                  {
                    label: section.kind,
                    value: formatCurrencyNoDecimals(new Decimal(section.value)),
                  },
                ]}
              />
            }
          />
        );
      } else {
        section.tooltip = (
          <EstateWaterfallSummaryBarChartsTooltip
            taxBeforeFirstDeath={giftTaxesPaidNow}
            taxAfterFirstDeath={taxesPaidAtFirstDeath}
            taxAfterSecondDeath={taxesPaidAtSecondDeath}
            afterDeath={section.afterDeath}
            actions={
              onOpenSectionSummaryPanel || onOpenSectionTaxModal ? (
                <LinkButton
                  display="Show calculations"
                  onClick={() => {
                    onOpenSectionSummaryPanel?.(section);
                    onOpenSectionTaxModal?.(waterfall?.id);
                  }}
                />
              ) : null
            }
          />
        );
      }
    };

    currentSections.forEach(addTooltip);
    firstDeathSections.forEach(addTooltip);
    secondDeathSections.forEach(addTooltip);
  })();

  // Remove sections with no value
  // converts WaterfallSection[] type to Section[]
  const hasValue = (section: WaterfallSection) => section.value > 0;
  const displayCurrentSections: Section[] = currentSections.filter(hasValue);
  const displayFirstDeathSections: Section[] =
    firstDeathSections.filter(hasValue);
  const displaySecondDeathSections: Section[] =
    secondDeathSections.filter(hasValue);

  const firstDeathGrantor = waterfall.household?.possiblePrimaryClients.find(
    (c) => c.id === waterfall.firstGrantorDeath.id
  );

  const secondDeathGrantor = waterfall.household?.possiblePrimaryClients.find(
    (c) => c.id !== waterfall.firstGrantorDeath.id
  );

  return [
    {
      label: getSectionLabel({
        afterDeath: AfterDeath.None,
        firstDeathName: firstDeathGrantor?.firstName,
        firstDeathYear: firstDeathYear || getYear(new Date()),
        secondDeathName: secondDeathGrantor?.firstName,
        secondDeathYear: secondDeathYear || getYear(new Date()),
      }),
      sections: displayCurrentSections,
      waterfallSections: currentSections,
      afterDeath: AfterDeath.None,
    },
    {
      label: getSectionLabel({
        afterDeath: AfterDeath.First,
        firstDeathName: firstDeathGrantor?.firstName,
        firstDeathYear: firstDeathYear || getYear(new Date()),
        secondDeathName: secondDeathGrantor?.firstName,
        secondDeathYear: secondDeathYear || getYear(new Date()),
      }),
      sections: displayFirstDeathSections,
      waterfallSections: firstDeathSections,
      afterDeath: AfterDeath.First,
    },
    ...(isTwoGrantorClient
      ? [
          {
            label: getSectionLabel({
              afterDeath: AfterDeath.Second,
              firstDeathName: firstDeathGrantor?.firstName,
              firstDeathYear: firstDeathYear || getYear(new Date()),
              secondDeathName: secondDeathGrantor?.firstName,
              secondDeathYear: secondDeathYear || getYear(new Date()),
            }),
            sections: displaySecondDeathSections,
            waterfallSections: secondDeathSections,
            afterDeath: AfterDeath.Second,
          },
        ]
      : []),
  ];
}

export function useGetDefaultSections() {
  const dataVisColors = useChartColorDefinitions();
  const getDefaultSections = useCallback(
    (afterDeath: AfterDeath): WaterfallSections => {
      return getDefaultWaterfallBarChartSections({
        dataVisColors,
        afterDeath,
      });
    },
    [dataVisColors]
  );

  return getDefaultSections;
}

export interface WaterfallBarChartSection {
  label: string;
  sections: Section[];
  waterfallSections: WaterfallSections;
  afterDeath: AfterDeath;
}

export function useWaterfallSummaryBarChartSections({
  waterfall,
  onOpenSectionSummaryPanel,
}: UseWaterfallSummaryBarChartSectionsProps): WaterfallBarChartSection[] {
  const isTwoGrantorClient = waterfall.household?.isTwoGrantor ?? false;

  const {
    beforeFirstDeathTaxSummary,
    firstDeathTaxSummary,
    secondDeathTaxSummary,
  } = waterfall.visualizationWithProjections;

  const getDefaultSections = useGetDefaultSections();

  return useMemo(
    () =>
      getWaterfallSummaryBarChartSections({
        waterfall,
        isTwoGrantorClient,
        beforeFirstDeathTaxSummary,
        firstDeathTaxSummary,
        secondDeathTaxSummary,
        getDefaultSections,
        onOpenSectionSummaryPanel,
      }),
    [
      waterfall,
      isTwoGrantorClient,
      beforeFirstDeathTaxSummary,
      firstDeathTaxSummary,
      secondDeathTaxSummary,
      getDefaultSections,
      onOpenSectionSummaryPanel,
    ]
  );
}
