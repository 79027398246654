import { Stack, Typography } from '@mui/material';
import { compact, map } from 'lodash';
import { useContext, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { Card } from '@/components/layout/Card/Card';
import {
  ButtonTab,
  TabContent,
  Tabs,
} from '@/components/navigation/NavigationTabs';
import { TabsProvider } from '@/components/navigation/NavigationTabs/Tabs.provider';
import { Loader } from '@/components/progress/Loader/Loader';
import { useFormContext } from '@/components/react-hook-form';
import {
  ClientProfessionalTeamForm,
  ClientProfessionalTeamMemberRoleKey,
  ClientProfessionalTeamRole,
} from '@/modules/professionalTeam/ClientProfessionalTeam.types';

import { ClientProfessionalTeamContext } from './ClientProfessionalTeam.context';
import {
  ClientProfessionalTeamAddCustomRoleButton,
  ClientProfessionalTeamRoleCard,
} from './ClientProfessionalTeam.UI';

interface PrincipalClientTabBodyProps {
  principalClientId: string;
}
function PrincipalClientTabBody({
  principalClientId,
}: PrincipalClientTabBodyProps) {
  const { control } = useFormContext<ClientProfessionalTeamForm>();
  const pathToRoleList =
    `principalClientIdTeamMemberMap.${principalClientId}` as const;
  const principalClientRoles = useWatch<
    ClientProfessionalTeamForm,
    `principalClientIdTeamMemberMap.${string}`
  >({
    control,
    name: pathToRoleList,
  });

  return (
    <Stack
      direction="column"
      height="100%"
      spacing={2}
      justifyContent="space-between"
    >
      <Stack spacing={2}>
        {map<
          ClientProfessionalTeamForm['principalClientIdTeamMemberMap'][string]
        >(
          principalClientRoles,
          (
            role: ClientProfessionalTeamRole,
            roleKey: ClientProfessionalTeamMemberRoleKey
          ) => (
            <ClientProfessionalTeamRoleCard
              key={roleKey}
              role={role}
              roleKey={roleKey}
              pathToRoleList={pathToRoleList}
            />
          )
        )}
      </Stack>

      <ClientProfessionalTeamAddCustomRoleButton path={pathToRoleList} />
    </Stack>
  );
}

interface ClientProfessionalTeamPrincipalClientsInnerProps {
  principalClientIds: string[];
}

function ClientProfessionalTeamPrincipalClientsInner({
  principalClientIds,
}: ClientProfessionalTeamPrincipalClientsInnerProps) {
  const { principalClientDisplayInfo } = useContext(
    ClientProfessionalTeamContext
  );

  const [activeTab, setActiveTab] = useState<string>(
    principalClientIds[0] || ''
  );

  return (
    <TabsProvider currentTab={activeTab}>
      <Tabs>
        {principalClientIds.map((principalClientId) => (
          <ButtonTab
            key={`principal-client-tab-${principalClientId}`}
            display={
              principalClientDisplayInfo[principalClientId] || 'Not found'
            }
            onClick={() => setActiveTab(principalClientId)}
            isActive={principalClientId === activeTab}
            value={principalClientId}
          />
        ))}
      </Tabs>
      {principalClientIds.map((principalClientId) => (
        <TabContent
          key={`principal-client-tab-content-${principalClientId}`}
          tabKey={principalClientId}
        >
          <PrincipalClientTabBody principalClientId={principalClientId} />
        </TabContent>
      ))}
    </TabsProvider>
  );
}

export function ClientProfessionalTeamPrincipalClients() {
  const { control } = useFormContext<ClientProfessionalTeamForm>();
  const principalClientIdTeamMemberMap = useWatch<
    ClientProfessionalTeamForm,
    'principalClientIdTeamMemberMap'
  >({
    control,
    name: 'principalClientIdTeamMemberMap',
  });

  const principalClientIds = compact(
    map(
      principalClientIdTeamMemberMap,
      (_, principalClientId: string) => principalClientId
    )
  );

  return (
    <Card
      variant="outlined"
      sx={{ px: 3, paddingTop: 3, paddingBottom: 4, height: '100%' }}
    >
      <Stack direction="column" justifyContent="space-between" height="100%">
        <Stack direction="column" spacing={3} height="100%">
          <Typography variant="h1">Client fiduciaries</Typography>
          {principalClientIds.length ? (
            <ClientProfessionalTeamPrincipalClientsInner
              principalClientIds={principalClientIds}
            />
          ) : (
            // using a loader here because displaying the tabs before the principal clients have loaded won't set the initial tab correctly
            <Loader
              boxProps={{
                paddingY: 7,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
