import { compact } from 'lodash';
import { useMemo, useState } from 'react';

import { DropdownButton } from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { SidePanel } from '@/components/modals/SidePanel';
import { TabsProvider } from '@/components/navigation/NavigationTabs/Tabs.provider';
import { useNavigateToEstateTaxScenarios } from '@/modules/estateTaxScenarios/hooks/useNavigateToEstateTaxScenarios';
import { AfterDeath } from '@/types/schema';
import { assertNonNil } from '@/utils/assertUtils';

import { EditButton } from '../components/EditButton';
import { SummaryPanelLoader } from '../components/SummaryPanelLoader';
import { Calculations } from './Calculations';
import { EstateTaxSummaryPanelTaxes } from './EstateTaxSummaryPanelTaxes';
import { useSummaryPanelQuery } from './hooks/useSummaryPanelQuery';
import { Payments } from './Payments';

interface SummaryView {
  display: string;
  value: 'payments' | 'calculations';
  component: JSX.Element;
}

interface EstateTaxSummaryPanelProps {
  householdId: string;
  waterfallId: string;
  initialAfterDeathTab: AfterDeath;
  onClose: () => void;
}

export const EstateTaxSummaryPanel = ({
  waterfallId,
  householdId,
  initialAfterDeathTab,
  onClose,
}: EstateTaxSummaryPanelProps) => {
  const navigateToEstateTaxScenarios = useNavigateToEstateTaxScenarios();

  const [currentDropdownView, setCurrentDropdownView] =
    useState<SummaryView['value']>('calculations');

  const { firstDeathData, secondDeathData, taxSummary, loading } =
    useSummaryPanelQuery({
      householdId,
      waterfallId,
    });

  const summary = useMemo<SummaryView[]>(() => {
    const totalsElements = {
      [AfterDeath.None]: (
        <EstateTaxSummaryPanelTaxes
          summaryPanelData={firstDeathData}
          afterDeath={AfterDeath.None}
          taxSummary={taxSummary.beforeFirstDeath}
        />
      ),
      [AfterDeath.First]: (
        <EstateTaxSummaryPanelTaxes
          summaryPanelData={firstDeathData}
          afterDeath={AfterDeath.First}
          taxSummary={taxSummary.firstDeath}
        />
      ),
      [AfterDeath.Second]: (
        <EstateTaxSummaryPanelTaxes
          summaryPanelData={secondDeathData}
          afterDeath={AfterDeath.Second}
          taxSummary={taxSummary.secondDeath}
        />
      ),
    };

    return compact([
      {
        display: 'Calculations',
        value: 'calculations',
        component: (
          <Calculations
            firstDeathData={firstDeathData}
            secondDeathData={secondDeathData}
            totalsElements={totalsElements}
          />
        ),
      },
      {
        display: 'Payments',
        value: 'payments',
        component: (
          <Payments
            firstDeathData={firstDeathData}
            secondDeathData={secondDeathData}
            totalsElements={totalsElements}
          />
        ),
      },
    ]);
  }, [
    firstDeathData,
    secondDeathData,
    taxSummary.beforeFirstDeath,
    taxSummary.firstDeath,
    taxSummary.secondDeath,
  ]);

  const items = useMemo(() => {
    return summary.map(({ display, value }) => {
      return {
        name: display,
        clickHandler: () => setCurrentDropdownView(value),
      };
    });
  }, [summary]);

  const { component: SummaryComponent, display } = summary.find(
    ({ value }) => value === currentDropdownView
  )!;

  return (
    <SidePanel.Panel>
      <SidePanel.Header title="Gift & estate tax due" onClose={onClose}>
        {items.length > 1 && (
          <DropdownButton
            showArrow
            name="Dropdown-selectView"
            variant="secondary"
            size="sm"
            buttonContent={display}
            items={items}
            contentBoxSx={{ width: '100%', justifyContent: 'space-between' }}
          />
        )}
      </SidePanel.Header>
      <SidePanel.Content>
        {loading ? (
          <SummaryPanelLoader />
        ) : (
          <TabsProvider currentTab={initialAfterDeathTab} disableExternalChange>
            <SidePanel.Section>{SummaryComponent}</SidePanel.Section>
          </TabsProvider>
        )}
      </SidePanel.Content>
      <SidePanel.Footer>
        <EditButton
          disabled={!firstDeathData}
          onClick={() => {
            const firstDeathGrantorId = assertNonNil(
              firstDeathData.waterfall?.firstGrantorDeath?.id,
              `no first grantor death`
            );

            navigateToEstateTaxScenarios({
              householdId,
              waterfallId,
              firstDeathPrimaryClientId: firstDeathGrantorId,
            });
          }}
        >
          Manage tax calculations
        </EditButton>
      </SidePanel.Footer>
    </SidePanel.Panel>
  );
};
