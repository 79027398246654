import { compact } from 'lodash';

import {
  AIOnboardingModalFormShape,
  AIOnboardingModalFormShapeKeys,
} from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import {
  mapEntitiesToFormData,
  mapEntitiesToInput,
} from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/entities.fields.utils';
import {
  mapIndividualsToFormData,
  mapIndividualsToInput,
} from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/individuals.fields.utils';
import {
  mapOrganizationsToFormData,
  mapOrganizationsToInput,
} from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/organizations.fields.utils';
import {
  mapTestamentaryEntitiesToFormData,
  mapTestamentaryEntitiesToInput,
} from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/testamentaryEntities.fields.utils';
import {
  AiOnboardingModal_AiSuggestionFragment,
  AiOnboardingModal_HouseholdFragment,
  AiOnboardingModalBulkCreateMutationVariables,
} from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import { AiSuggestionKind } from '@/types/schema';

///////////////////////////////////////////////////////////
// SUGGESTION DATA -> FORM DATA
///////////////////////////////////////////////////////////

/**
 * Converts the onboarding suggestions data to the form data.
 *
 * @param suggestions
 * @param household
 */
export function dataToForm(
  suggestions: AiOnboardingModal_AiSuggestionFragment[],
  household: AiOnboardingModal_HouseholdFragment
): AIOnboardingModalFormShape {
  return {
    individuals: mapIndividualsToFormData(
      suggestions.filter(
        (a) => a.kind === AiSuggestionKind.OnboardingIndividual
      ),
      household
    ),
    organizations: mapOrganizationsToFormData(
      suggestions.filter(
        (a) => a.kind === AiSuggestionKind.OnboardingOrganization
      )
    ),
    entities: mapEntitiesToFormData(
      suggestions.filter((a) => a.kind === AiSuggestionKind.OnboardingEntity),
      household
    ),
    testamentaryEntities: mapTestamentaryEntitiesToFormData(
      suggestions.filter(
        (a) => a.kind === AiSuggestionKind.OnboardingTestamentaryEntity
      )
    ),
  };
}

///////////////////////////////////////////////////////////
// FORM DATA -> MUTATION INPUT
///////////////////////////////////////////////////////////

/**
 * Converts the form data to the input data for the mutation. Only includes the
 * form paths that are included in the formPaths array.
 *
 * @param formData
 * @param formPaths
 * @param household
 * @param suggestionsByID
 */
export function formDataToInput(
  formData: AIOnboardingModalFormShape,
  formPaths: AIOnboardingModalFormShapeKeys[],
  household: AiOnboardingModal_HouseholdFragment,
  suggestionsByID: Record<string, AiOnboardingModal_AiSuggestionFragment>
): {
  input: Omit<
    AiOnboardingModalBulkCreateMutationVariables,
    'acknowledgeSuggestionsInput' | 'updateKGRootInput'
  >;
  acceptedSuggestionIDs: string[];
} {
  const { individuals, organizations, entities, testamentaryEntities } =
    formData;

  return {
    input: {
      individualsInput: formPaths.includes('individuals')
        ? mapIndividualsToInput(individuals, household, suggestionsByID)
        : [],
      organizationsInput: formPaths.includes('organizations')
        ? mapOrganizationsToInput(organizations, household)
        : [],
      entitiesInput: formPaths.includes('entities')
        ? mapEntitiesToInput(entities, household, suggestionsByID)
        : [],
      testamentaryEntitiesInput: formPaths.includes('testamentaryEntities')
        ? mapTestamentaryEntitiesToInput(
            testamentaryEntities,
            household,
            suggestionsByID
          )
        : [],
    },
    acceptedSuggestionIDs: compact(
      formPaths.flatMap((path) => formData[path].map((f) => f._suggestionID))
    ),
  };
}
