import { Box, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';

import { Building07Icon } from '@/components/icons/Building07Icon';
import { FileShield02Icon } from '@/components/icons/FileShield02Icon';
import { User01Icon } from '@/components/icons/User01Icon';
import { TypographyClamped } from '@/components/typography/TypographyClamped';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { entityKindToDisplayName } from '../../EntityForm/utils';

interface Props {
  displayName: string;
  percentOwnership: Decimal;
  distributedValue: Decimal | null;
  type:
    | 'Individual'
    | 'Organization'
    | 'Entity'
    | ReturnType<typeof entityKindToDisplayName>;
}

function Icon({ type }: { type: Props['type'] }) {
  if (type === 'Individual') {
    return <User01Icon />;
  }

  if (type === 'Organization') {
    return <Building07Icon />;
  }

  if (type === 'Entity') {
    return <FileShield02Icon />;
  }

  return <></>;
}

export function BeneficiaryLeaf({
  displayName,
  percentOwnership,
  distributedValue,
  type,
}: Props) {
  const theme = useTheme();

  return (
    <Box
      bgcolor={theme.palette.dataVisualizationSecondary.ultraLight}
      padding={2}
      borderRadius={1.5}
    >
      <Stack spacing={0.5}>
        <Stack
          flexDirection="row"
          alignItems="center"
          gap={1}
          color={theme.palette.dataVisualizationSecondary.main}
        >
          <Icon type={type} />
          {percentOwnership.greaterThan(0) && (
            <Stack
              sx={{
                borderRadius: '2px',
                backgroundColor: theme.palette.dataVisualizationSecondary.main,
                px: 1,
                py: '2px',
              }}
            >
              <Typography
                color={theme.palette.dataVisualizationSecondary.contrastText}
                variant="h6"
              >
                {`${formatPercent(percentOwnership, 2)}%`}
              </Typography>
            </Stack>
          )}
        </Stack>
        <TypographyClamped variant="h5" lines={2}>
          {displayName}
        </TypographyClamped>
        <Typography variant="subtitle2">{type}</Typography>
        {distributedValue && (
          <Typography variant="h5" component="div">
            {formatCurrency(distributedValue)}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
