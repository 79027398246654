import { Stack } from '@mui/material';
import { noop } from 'lodash';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { Upload01Icon } from '@/components/icons/Upload01Icon';
import { BigBreadcrumbs } from '@/components/navigation/BigBreadcrumbs';
import { PageHeader } from '@/components/navigation/PageHeader';
import { useGuardedContext } from '@/hooks/useGuardedContext';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { MultiDocumentUploaderProvider } from '@/modules/documents/MultiDocumentUploader/context/MultiDocumentUploader.provider';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { DocumentBreadcrumb } from '../DocumentDetailsPage/DocumentBreadcrumb';

export const ClientDocumentsPageContext = createContext<{
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
}>({ modalOpen: false, setModalOpen: noop });

export function useClientDocumentsPageContext() {
  return useGuardedContext(
    ClientDocumentsPageContext,
    'ClientDocumentsPageContext'
  );
}

export function ClientDocumentsPage() {
  const householdId = useRequiredParam('householdId');
  const params = useParams();
  const documentId = params.documentId ?? null;
  const [documentModalOpen, setDocumentModalOpen] = useState(false);

  const heading = useMemo(() => {
    if (!documentId)
      return <BigBreadcrumbs crumbs={[{ label: 'Documents' }]} />;

    return (
      <DocumentBreadcrumb
        documentCrumbLabel="Details"
        rootPath={getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_DOCUMENTS,
          {
            householdId,
          }
        )}
      />
    );
  }, [documentId, householdId]);

  const action = useMemo(() => {
    if (documentId) return;

    return (
      <Stack direction="row" alignItems="center" justifyContent="flex-end">
        <Button
          size="sm"
          onClick={() => setDocumentModalOpen(true)}
          variant="primary"
          startIcon={Upload01Icon}
        >
          Upload documents
        </Button>
      </Stack>
    );
  }, [documentId]);

  return (
    <MultiDocumentUploaderProvider>
      <ClientDocumentsPageContext.Provider
        value={{
          modalOpen: documentModalOpen,
          setModalOpen: setDocumentModalOpen,
        }}
      >
        <Stack>
          <PageHeader heading={heading} action={action} />
          <Stack p={3} pb={4} flex={1}>
            <Outlet />
          </Stack>
        </Stack>
      </ClientDocumentsPageContext.Provider>
    </MultiDocumentUploaderProvider>
  );
}
