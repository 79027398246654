import { GridValidRowModel } from '@mui/x-data-grid-pro';

import { CellContainer } from '../components/cells';
import { CellRenderer } from '../types';

export const renderNestedCellsEmpty = <
  R extends GridValidRowModel = GridValidRowModel,
>(
  renderer: CellRenderer<R>,
  EmptyCell = CellContainer
): CellRenderer<R> => {
  // eslint-disable-next-line react/display-name
  return (params) => {
    if (params.rowNode.depth > 0) return <EmptyCell />;
    return renderer(params);
  };
};
