// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetScenarioGiftTypeOptionsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ProposalWhereInput>;
}>;


export type GetScenarioGiftTypeOptionsQuery = { __typename?: 'Query', proposals: { __typename?: 'ProposalConnection', edges?: Array<{ __typename?: 'ProposalEdge', node?: { __typename?: 'Proposal', id: string, household: { __typename?: 'Household', id: string, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string }> } } | null } | null> | null } };


export const GetScenarioGiftTypeOptionsDocument = gql`
    query GetScenarioGiftTypeOptions($where: ProposalWhereInput) {
  proposals(where: $where) {
    edges {
      node {
        id
        household {
          id
          possiblePrimaryClients {
            id
            displayName
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetScenarioGiftTypeOptionsQuery__
 *
 * To run a query within a React component, call `useGetScenarioGiftTypeOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScenarioGiftTypeOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScenarioGiftTypeOptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetScenarioGiftTypeOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetScenarioGiftTypeOptionsQuery, GetScenarioGiftTypeOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetScenarioGiftTypeOptionsQuery, GetScenarioGiftTypeOptionsQueryVariables>(GetScenarioGiftTypeOptionsDocument, options);
      }
export function useGetScenarioGiftTypeOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetScenarioGiftTypeOptionsQuery, GetScenarioGiftTypeOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetScenarioGiftTypeOptionsQuery, GetScenarioGiftTypeOptionsQueryVariables>(GetScenarioGiftTypeOptionsDocument, options);
        }
export function useGetScenarioGiftTypeOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetScenarioGiftTypeOptionsQuery, GetScenarioGiftTypeOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetScenarioGiftTypeOptionsQuery, GetScenarioGiftTypeOptionsQueryVariables>(GetScenarioGiftTypeOptionsDocument, options);
        }
export type GetScenarioGiftTypeOptionsQueryHookResult = ReturnType<typeof useGetScenarioGiftTypeOptionsQuery>;
export type GetScenarioGiftTypeOptionsLazyQueryHookResult = ReturnType<typeof useGetScenarioGiftTypeOptionsLazyQuery>;
export type GetScenarioGiftTypeOptionsSuspenseQueryHookResult = ReturnType<typeof useGetScenarioGiftTypeOptionsSuspenseQuery>;
export type GetScenarioGiftTypeOptionsQueryResult = Apollo.QueryResult<GetScenarioGiftTypeOptionsQuery, GetScenarioGiftTypeOptionsQueryVariables>;
export const Operations = {
  Query: {
    GetScenarioGiftTypeOptions: 'GetScenarioGiftTypeOptions' as const
  }
}