import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { CSSProperties } from 'react';

import { ColorBox } from '@/components/display/ColorBox/ColorBox';
import { formatCurrency } from '@/utils/formatting/currency';

import { getPreferredBackground } from '../chartAccessories';
import { Section } from '../StackedHorizontalBar/StackedHorizontalBar';

export interface LegendSection extends Omit<Section, 'value'> {
  value?: number;
  omitFromLegend?: boolean;
}

export interface LegendProps {
  sections: LegendSection[];
}

export function Legend({ sections }: LegendProps) {
  return (
    <Stack direction="row" spacing={3} pt={1}>
      {sections.map((section, i) => {
        const primaryText =
          section.legendPrimaryText ??
          section.value ??
          section.label ??
          undefined;
        const secondaryText = (() => {
          if (primaryText !== undefined && primaryText !== section.label) {
            return section.label;
          }
          return undefined;
        })();

        if (section.omitFromLegend) {
          return null;
        }

        return (
          <LegendItem
            key={`legend-item-${i}`}
            primaryText={primaryText}
            secondaryText={secondaryText}
            legendItemColor={section.color}
            backgroundCss={section.backgroundCss}
          />
        );
      })}
    </Stack>
  );
}

interface LegendItemPrimaryTextProps {
  primaryText?: string | number | JSX.Element;
}

function LegendItemPrimaryText({ primaryText }: LegendItemPrimaryTextProps) {
  if (!primaryText) {
    return null;
  }

  if (typeof primaryText === 'object') {
    return primaryText;
  }

  if (typeof primaryText === 'string') {
    return <Typography variant="subtitle2">{primaryText}</Typography>;
  }

  return (
    <Typography variant="label">
      {formatCurrency(new Decimal(primaryText), {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        currencySign: 'accounting',
      })}
    </Typography>
  );
}

interface LegendItemProps {
  primaryText?: string | number | JSX.Element;
  secondaryText?: string | number | JSX.Element;
  legendItemColor: string;
  backgroundCss?: CSSProperties['background'];
}

/**
 * @description This component is used to render a single legend item
 * @param props.primaryText The primary text to display in the legend item, numbers are rendered as currency
 * @param props.secondaryText The secondary text to display in the legend item, numbers are not formatted
 * @param props.legendItemColor Optional color for the legend item
 * @param props.backgroundCss Optional background css for the legend item, if provided, overrides the legendItemColor
 */
export function LegendItem({
  primaryText,
  secondaryText,
  legendItemColor,
  backgroundCss,
}: LegendItemProps) {
  const colorForBox = getPreferredBackground({
    backgroundCss,
    color: legendItemColor,
  });

  return (
    <Stack>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <ColorBox color={colorForBox} />

        <LegendItemPrimaryText primaryText={primaryText} />
      </Stack>
      {secondaryText && (
        <Typography variant="subtitle2">{secondaryText}</Typography>
      )}
    </Stack>
  );
}
