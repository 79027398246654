import { MenuItem, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { SelectInputComponentOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { PathsOf } from '@/types/subform';
import { formatPercent } from '@/utils/formatting/percent';
import { getNodes } from '@/utils/graphqlUtils';

import { useCustomAssetCategoryGrowthModalQuery } from './graphql/CustomAssetCategoryGrowthModal.generated';

export interface CustomAssetCategoryGrowthModalFormShape {
  categoryId: string | undefined | null;
  customGrowthRate?: Decimal | undefined | null;
}

export interface CustomAssetCategoryGrowthModalProps
  extends CustomAssetCategoryGrowthModalFormShape {
  onClose: () => void;
  onSubmit: (formData: CustomAssetCategoryGrowthModalFormShape) => void;
  onDelete: () => void;
  canDelete: boolean;
}

export function CustomAssetCategoryGrowthModal({
  onClose,
  onSubmit: onSubmitExternal,
  onDelete,
  canDelete,
  categoryId,
  customGrowthRate = new Decimal(0),
}: CustomAssetCategoryGrowthModalProps) {
  const formMethods = useForm<CustomAssetCategoryGrowthModalFormShape>({
    defaultValues: {
      categoryId: categoryId ?? '',
      customGrowthRate,
    },
  });

  const { handleSubmit } = formMethods;

  const onSubmit = handleSubmit((formData) => onSubmitExternal(formData));
  return (
    <FormProvider {...formMethods}>
      <FormModal
        heading="Custom asset category growth"
        isOpen
        onClose={onClose}
        actions={
          <FormModalActions.Provider>
            {canDelete && (
              <FormModalActions.DeleteButton onConfirmDelete={onDelete} />
            )}
            <FormModalActions.CancelButton onClick={onClose} />
            <FormModalActions.SaveButton onClick={onSubmit} />
          </FormModalActions.Provider>
        }
      >
        <CustomAssetCategoryGrowthModalBody />
      </FormModal>
    </FormProvider>
  );
}

function CustomAssetCategoryGrowthModalBody() {
  const { control } = useFormContext<CustomAssetCategoryGrowthModalFormShape>();
  const { data, loading } = useCustomAssetCategoryGrowthModalQuery();

  const assetClassOptions = useMemo<SelectInputComponentOption<string>[]>(
    () =>
      getNodes(data?.assetClasses).map<SelectInputComponentOption<string>>(
        (assetClass) => ({
          type: 'component' as const,
          component: (
            <MenuItem key={assetClass.id} value={assetClass.id}>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                {assetClass.displayName}
                <Typography variant="caption">
                  {formatPercent(assetClass.growthRate ?? new Decimal(0), 2)}%
                </Typography>
              </Stack>
            </MenuItem>
          ),
          value: assetClass.id,
        })
      ),
    [data?.assetClasses]
  );

  return (
    <Stack>
      <FormLayoutRow>
        <FormLayoutItem width={12}>
          <FormAwareSelectInput<CustomAssetCategoryGrowthModalFormShape>
            control={control}
            fieldName={
              'categoryId' as const satisfies PathsOf<CustomAssetCategoryGrowthModalFormShape>
            }
            options={assetClassOptions}
            label="Asset category"
            required
            disabled={loading}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={6}>
          <FormAwarePercentInput<CustomAssetCategoryGrowthModalFormShape>
            isDecimalJSInput
            required
            control={control}
            fieldName={
              'customGrowthRate' as const satisfies PathsOf<CustomAssetCategoryGrowthModalFormShape>
            }
            label="Growth rate"
            fixedDecimalScale
            decimalScale={2}
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </Stack>
  );
}
