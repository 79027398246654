import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';

import { Card } from '@/components/layout/Card/Card';
import { useTenantHasEnabledAssetIntegration } from '@/modules/assetProviderIntegrations/shared/hooks/useTenantHasEnabledAssetIntegration';
import { IntegrationClientForm } from '@/modules/forms/household/ManageHouseholdAccessForm/IntegrationClientForm/IntegrationClientForm';

import { ClientDetailsGrantorCard } from './ClientPeopleListViewGrantorCard';
import { ClientPeopleListViewTable_ClientProfileFragment } from './graphql/ClientPeopleListViewTable.generated';

export interface ClientDetailsPrimaryClientsProps {
  primaryClients: (
    | ClientPeopleListViewTable_ClientProfileFragment
    | null
    | undefined
  )[];
  loading: boolean;
  onClick: (clientProfileId: string | null) => void;
  householdId: string;
  integrationClientIds: string[];
}

export function ClientDetailsPrimaryClients({
  primaryClients,
  loading,
  onClick,
  householdId,
  integrationClientIds,
}: ClientDetailsPrimaryClientsProps) {
  const hasAssetIntegration = useTenantHasEnabledAssetIntegration();
  const baseProps = useMemo(
    () => ({
      loading,
      onClick,
    }),
    [loading, onClick]
  );

  const cardBody = hasAssetIntegration ? (
    <Stack direction="row" spacing={2}>
      <Stack flexGrow={1} spacing={1} width="50%">
        <ClientDetailsGrantorCard {...baseProps} grantor={primaryClients[0]} />
        <ClientDetailsGrantorCard {...baseProps} grantor={primaryClients[1]} />
      </Stack>
      <Box flexGrow={1} width="50%">
        <Card variant="inner-shadow" sx={{ p: 3, height: '100%' }}>
          <IntegrationClientForm
            householdId={householdId}
            initialIntegrationClientIds={integrationClientIds}
          />
        </Card>
      </Box>
    </Stack>
  ) : (
    <Stack direction="row" spacing={2}>
      <Box flexGrow={1}>
        <ClientDetailsGrantorCard {...baseProps} grantor={primaryClients[0]} />
      </Box>
      <Box flexGrow={1}>
        <ClientDetailsGrantorCard {...baseProps} grantor={primaryClients[1]} />
      </Box>
    </Stack>
  );

  return (
    <Card sx={{ p: 3 }} variant="outlined">
      {cardBody}
    </Card>
  );
}
