import Decimal from 'decimal.js';
import { FieldValues } from 'react-hook-form';

import {
  FormAwarePercentInput,
  FormAwarePercentInputProps,
} from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { interestRate7520Valuation } from '@/components/utils/inputUtils';

type Rate7520InputProps<FormShape extends FieldValues> = Omit<
  FormAwarePercentInputProps<FormShape>,
  'label'
>;

export const RATE_7520_DECIMAL_SCALE = 1 as const;

export function Rate7520Input<FormShape extends FieldValues>({
  ...rest
}: Rate7520InputProps<FormShape>) {
  return (
    <FormAwarePercentInput<FormShape>
      label="7520 rate"
      isDecimalJSInput
      fixedDecimalScale={true}
      validation={{
        validRate: (value) => interestRate7520Valuation(value as Decimal),
      }}
      decimalScale={RATE_7520_DECIMAL_SCALE}
      {...rest}
    />
  );
}
