import Decimal from 'decimal.js';

import { PathsOf } from '@/types/subform';

export const NAMESPACE = 'annuitiesSubform' as const;
export type NamespaceType = typeof NAMESPACE;
const VARIANTS = [
  'initialFundingPercentOnly',
  'initialFundingPercentAndPaymentAmount',
] as const;
export type VariantType = (typeof VARIANTS)[number];

export interface Annuity {
  paymentAmount: Decimal;
  paymentPercentage: Decimal;
  // id will be a string in the scenario where annuity payments are already defined, and
  // null in the case that they're not yet saved and being prepopulated by the projections
  id: string | null;
}

// this is the type of the subform's values
export interface GRATAnnuitiesSubformType {
  annuities: Annuity[];
}

// this is what the subform's values will be nested under in the parent form
export interface Fields {
  [NAMESPACE]: GRATAnnuitiesSubformType;
}

export type SubformField = PathsOf<Fields>;

// this is Props for the UI component
export interface SubformComponentProps {
  // standard set of props
  variant: VariantType;
  householdId: string;
  subformValues: GRATAnnuitiesSubformType;
  disabled?: boolean;
  entityId?: string;

  // props that are specific to this type of subform
  initialFundingValue?: Decimal;
  termDurationYears?: number;
  loading?: boolean;
}

export const annuitiesSubformDefaultValues: GRATAnnuitiesSubformType = {
  annuities: [],
};
