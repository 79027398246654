import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';

import { AppBar, AppBarProps } from './AppBar';
import { AppBarMenuItems } from './AppBarMenuItems';

const useStyles = makeStyles()((theme) => ({
  toolBar: {
    backgroundColor: COLORS.NAVY[800],
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

export function PrimaryAppBar(props: AppBarProps) {
  const { classes } = useStyles();

  return (
    <AppBar
      classes={{
        toolBar: classes.toolBar,
      }}
      {...props}
    >
      <Box flexGrow={1} />
      <AppBarMenuItems />
    </AppBar>
  );
}
