// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { CharitableTrustDesignerAnalysisData_ProposalFragmentDoc } from '../CharitableTrustDesignerAnalysis/graphql/CharitableTrustDesignerAnalysisData.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateCharitableTrustProposalMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateProposalInput;
}>;


export type CreateCharitableTrustProposalMutation = { __typename?: 'Mutation', createProposalV2: { __typename?: 'Proposal', id: string, cltProposal?: { __typename?: 'CLTProposal', id: string } | null, crtProposal?: { __typename?: 'CRTProposal', id: string } | null } };

export type UpdateCharitableTrustProposalMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateProposalInput;
  householdID: Types.Scalars['ID']['input'];
  donorProfiles: Array<Types.InputMaybe<Types.AugmentedUpdateClientProfileInput>> | Types.InputMaybe<Types.AugmentedUpdateClientProfileInput>;
  updateDonorProfiles: Types.Scalars['Boolean']['input'];
}>;


export type UpdateCharitableTrustProposalMutation = { __typename?: 'Mutation', updateProposalV2: { __typename?: 'Proposal', id: string, cltProposal?: { __typename?: 'CLTProposal', id: string, assetValue: CurrencyUSD, assetNotes?: string | null, assetCostBasis: CurrencyUSD, termKind: Types.CharitableTrustTermKind, termYears: number, payoutKind: Types.CharitableTrustPayoutKind, payoutFrequency: Types.CharitableTrustPayoutFrequency, unitrustKind?: Types.CharitableTrustUnitrustKind | null, annuityPayoutAmount?: CurrencyUSD | null, unitrustPayoutPercent?: Percent | null, rate7520?: Percent | null, optimizationTarget?: Types.CharitableTrustOptimizationTarget | null, calculationMethod?: Types.CharitableTrustCalculationMethod | null, donors: Array<{ __typename?: 'ClientProfile', id: string, dateOfBirth?: Time | null, displayName: string }> } | null, crtProposal?: { __typename?: 'CRTProposal', id: string, assetValue: CurrencyUSD, assetNotes?: string | null, assetCostBasis: CurrencyUSD, termKind: Types.CharitableTrustTermKind, termYears: number, payoutKind: Types.CharitableTrustPayoutKind, payoutFrequency: Types.CharitableTrustPayoutFrequency, unitrustKind?: Types.CharitableTrustUnitrustKind | null, annuityPayoutAmount?: CurrencyUSD | null, unitrustPayoutPercent?: Percent | null, rate7520?: Percent | null, optimizationTarget?: Types.CharitableTrustOptimizationTarget | null, calculationMethod?: Types.CharitableTrustCalculationMethod | null, donors: Array<{ __typename?: 'ClientProfile', id: string, dateOfBirth?: Time | null, displayName: string }> } | null }, updateClientProfiles?: Array<{ __typename?: 'ClientProfile', id: string, dateOfBirth?: Time | null } | null> };


export const CreateCharitableTrustProposalDocument = gql`
    mutation CreateCharitableTrustProposal($input: AugmentedCreateProposalInput!) {
  createProposalV2(input: $input) {
    id
    cltProposal {
      id
    }
    crtProposal {
      id
    }
  }
}
    `;
export type CreateCharitableTrustProposalMutationFn = Apollo.MutationFunction<CreateCharitableTrustProposalMutation, CreateCharitableTrustProposalMutationVariables>;

/**
 * __useCreateCharitableTrustProposalMutation__
 *
 * To run a mutation, you first call `useCreateCharitableTrustProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCharitableTrustProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCharitableTrustProposalMutation, { data, loading, error }] = useCreateCharitableTrustProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCharitableTrustProposalMutation(baseOptions?: Apollo.MutationHookOptions<CreateCharitableTrustProposalMutation, CreateCharitableTrustProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCharitableTrustProposalMutation, CreateCharitableTrustProposalMutationVariables>(CreateCharitableTrustProposalDocument, options);
      }
export type CreateCharitableTrustProposalMutationHookResult = ReturnType<typeof useCreateCharitableTrustProposalMutation>;
export type CreateCharitableTrustProposalMutationResult = Apollo.MutationResult<CreateCharitableTrustProposalMutation>;
export type CreateCharitableTrustProposalMutationOptions = Apollo.BaseMutationOptions<CreateCharitableTrustProposalMutation, CreateCharitableTrustProposalMutationVariables>;
export const UpdateCharitableTrustProposalDocument = gql`
    mutation UpdateCharitableTrustProposal($input: AugmentedUpdateProposalInput!, $householdID: ID!, $donorProfiles: [AugmentedUpdateClientProfileInput]!, $updateDonorProfiles: Boolean!) {
  updateProposalV2(input: $input) {
    ...CharitableTrustDesignerAnalysisData_Proposal
    id
    cltProposal {
      id
    }
    crtProposal {
      id
    }
  }
  updateClientProfiles(householdId: $householdID, inputs: $donorProfiles) @include(if: $updateDonorProfiles) {
    id
    dateOfBirth
  }
}
    ${CharitableTrustDesignerAnalysisData_ProposalFragmentDoc}`;
export type UpdateCharitableTrustProposalMutationFn = Apollo.MutationFunction<UpdateCharitableTrustProposalMutation, UpdateCharitableTrustProposalMutationVariables>;

/**
 * __useUpdateCharitableTrustProposalMutation__
 *
 * To run a mutation, you first call `useUpdateCharitableTrustProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCharitableTrustProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCharitableTrustProposalMutation, { data, loading, error }] = useUpdateCharitableTrustProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *      householdID: // value for 'householdID'
 *      donorProfiles: // value for 'donorProfiles'
 *      updateDonorProfiles: // value for 'updateDonorProfiles'
 *   },
 * });
 */
export function useUpdateCharitableTrustProposalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCharitableTrustProposalMutation, UpdateCharitableTrustProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCharitableTrustProposalMutation, UpdateCharitableTrustProposalMutationVariables>(UpdateCharitableTrustProposalDocument, options);
      }
export type UpdateCharitableTrustProposalMutationHookResult = ReturnType<typeof useUpdateCharitableTrustProposalMutation>;
export type UpdateCharitableTrustProposalMutationResult = Apollo.MutationResult<UpdateCharitableTrustProposalMutation>;
export type UpdateCharitableTrustProposalMutationOptions = Apollo.BaseMutationOptions<UpdateCharitableTrustProposalMutation, UpdateCharitableTrustProposalMutationVariables>;
export const Operations = {
  Mutation: {
    CreateCharitableTrustProposal: 'CreateCharitableTrustProposal' as const,
    UpdateCharitableTrustProposal: 'UpdateCharitableTrustProposal' as const
  }
}