// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteClientProfileMutationVariables = Types.Exact<{
  clientProfileID: Types.Scalars['ID']['input'];
}>;


export type DeleteClientProfileMutation = { __typename?: 'Mutation', deleteClientProfile: string };


export const DeleteClientProfileDocument = gql`
    mutation DeleteClientProfile($clientProfileID: ID!) {
  deleteClientProfile(id: $clientProfileID)
}
    `;
export type DeleteClientProfileMutationFn = Apollo.MutationFunction<DeleteClientProfileMutation, DeleteClientProfileMutationVariables>;

/**
 * __useDeleteClientProfileMutation__
 *
 * To run a mutation, you first call `useDeleteClientProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClientProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClientProfileMutation, { data, loading, error }] = useDeleteClientProfileMutation({
 *   variables: {
 *      clientProfileID: // value for 'clientProfileID'
 *   },
 * });
 */
export function useDeleteClientProfileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClientProfileMutation, DeleteClientProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClientProfileMutation, DeleteClientProfileMutationVariables>(DeleteClientProfileDocument, options);
      }
export type DeleteClientProfileMutationHookResult = ReturnType<typeof useDeleteClientProfileMutation>;
export type DeleteClientProfileMutationResult = Apollo.MutationResult<DeleteClientProfileMutation>;
export type DeleteClientProfileMutationOptions = Apollo.BaseMutationOptions<DeleteClientProfileMutation, DeleteClientProfileMutationVariables>;
export const Operations = {
  Mutation: {
    DeleteClientProfile: 'DeleteClientProfile' as const
  }
}