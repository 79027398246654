import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { CSSProperties } from 'react';

import { Subsection } from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import { ColorBox } from '@/components/display/ColorBox/ColorBox';
import { PopperContent } from '@/components/poppers/PopperContent';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { WaterfallSection } from './types';

interface LabelWithColorBoxProps {
  color: CSSProperties['background'];
  label: string;
}

function LabelWithColorBox({ color, label }: LabelWithColorBoxProps) {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <ColorBox color={color} />
      <span>{label}</span>
    </Stack>
  );
}

export interface LifeInsuranceTooltipProps {
  section: WaterfallSection;
  insuranceValueSubsection: Subsection;
}

export function LifeInsuranceTooltip({
  insuranceValueSubsection,
  section,
}: LifeInsuranceTooltipProps) {
  return (
    <PopperContent
      sx={{
        width: 'auto',
      }}
      body={
        <MiniTable
          variant="tally"
          rows={[
            {
              label: (
                <LabelWithColorBox color={section.color} label={section.kind} />
              ),
              value: formatCurrencyNoDecimals(
                new Decimal(section.value).minus(
                  new Decimal(insuranceValueSubsection.value)
                )
              ),
            },
            {
              label: (
                <LabelWithColorBox
                  color={insuranceValueSubsection.backgroundCss}
                  label="Insurance death benefit"
                />
              ),
              value: formatCurrencyNoDecimals(
                new Decimal(insuranceValueSubsection.value)
              ),
            },
            {
              label: 'Total',
              value: formatCurrencyNoDecimals(new Decimal(section.value)),
            },
          ]}
        />
      }
    />
  );
}
