import { Button } from '@/components/form/baseInputs/Button';
import { CheckCircleBrokenIcon } from '@/components/icons/CheckCircleBrokenIcon';
import { UploadCloud01Icon } from '@/components/icons/UploadCloud01Icon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { ROUTE_KEYS } from '@/navigation/constants';
import { COLORS } from '@/styles/tokens/colors';

export function ClientEntityCSVConnectionTableEmptyState() {
  const { navigate } = useNavigateToRoute();
  return (
    <ActionCard
      grow
      variant="noCard"
      icon={
        <CheckCircleBrokenIcon
          size={48}
          fill={COLORS.GRAY[200]}
          stroke={COLORS.GRAY[200]}
          color={COLORS.GRAY[200]}
        />
      }
      heading="No clients have been created in Luminary"
      description={`No clients have been created in Luminary to associate with the CSV valuations. Please create clients and entities, then come back and associate them with the CSV values.`}
      actions={
        <Button
          variant="primary"
          size="md"
          fullWidth
          onClick={() => navigate(ROUTE_KEYS.HOUSEHOLDS_LIST, {})}
          startIcon={UploadCloud01Icon}
        >
          Create clients and entities
        </Button>
      }
    />
  );
}
