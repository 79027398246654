import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';

import { useCurrentUser } from '../../../authentication/hooks/useCurrentUser';

/**
 * @returns true if the current user is logged into Addepar
 */
export function useIsUserLoggedIntoAddepar() {
  const canConnect = useCanUserConnectToAddepar();
  const currentUser = useCurrentUser();
  const userCanInteractWithAddepar = currentUser?.loggedInToAddepar ?? false;
  return canConnect && userCanInteractWithAddepar;
}

/**
 * @returns true if Addepar is configured on the tenant.
 */
export function useCanUserConnectToAddepar() {
  const { integrationConfiguration } = useTenantDetailsContext();

  const userCanConnectToAddepar = Boolean(
    integrationConfiguration?.addeparFirmID &&
      integrationConfiguration.addeparSubdomain
  );

  return userCanConnectToAddepar;
}
