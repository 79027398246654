import { Box, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { ModalProps } from '@/components/modals/Modal/Modal';
import {
  DisplayTable,
  DisplayTableColumn,
  StyledTableCell,
} from '@/components/tables/DisplayTable/DisplayTable';
import { StyledTableRow } from '@/components/tables/DisplayTable/StyledTableRow';
import { pluralize } from '@/utils/formatting/strings';

import { ImportCsvValuationsPage_EntityValuationErrorMessageFragment } from '../ImportCSVValuationsPage/graphql/ImportCSVValuationsPage.generated';

export interface CSVUploadValidationModalProps
  extends Omit<ModalProps, 'heading' | 'onClose'> {
  validationResults: ImportCsvValuationsPage_EntityValuationErrorMessageFragment[];
  updatedValuations: number;
  onClose: (doRetry: boolean) => void;
}

export function CSVUploadValidationModal({
  validationResults,
  updatedValuations,
  onClose,
  ...modalProps
}: CSVUploadValidationModalProps) {
  const getValuationErrorCopy = useCallback(() => {
    if (updatedValuations > 0) {
      return `Successfully updated ${updatedValuations} ${pluralize(
        updatedValuations,
        'valuation'
      )}, but identified ${validationResults.length} ${pluralize(
        validationResults.length,
        'error'
      )} in the file that prevented a complete valuation update:`;
    }

    return `${validationResults.length} ${pluralize(
      validationResults.length,
      'error'
    )}  ${pluralize(
      validationResults.length,
      'was',
      'were'
    )} identified in the file that prevented a valuation update:`;
  }, [updatedValuations, validationResults.length]);

  return (
    <DialogModal
      onClose={() => onClose(true)}
      actions={
        <Stack
          width="100%"
          spacing={2}
          direction="row"
          justifyContent="stretch"
        >
          <Button
            onClick={() => onClose(true)}
            variant="secondary"
            size="md"
            fullWidth
          >
            Retry
          </Button>
          <Button
            onClick={() => onClose(false)}
            variant="primary"
            size="md"
            fullWidth
          >
            Done
          </Button>
        </Stack>
      }
      heading={
        updatedValuations > 0
          ? `Valuation update partially completed`
          : `Valuations could not be updated`
      }
      {...modalProps}
    >
      <Stack spacing={2}>
        <Typography variant="body1">{getValuationErrorCopy()}</Typography>
        <ValidationErrorsTable validationResults={validationResults} />
      </Stack>
    </DialogModal>
  );
}

interface ValidationErrorsTableProps {
  validationResults: ImportCsvValuationsPage_EntityValuationErrorMessageFragment[];
}

const columns: DisplayTableColumn[] = [
  {
    headerName: 'Entity',
    align: 'left',
    width: '50%',
  },
  {
    headerName: 'Validation error',
    align: 'left',
    width: '50%',
  },
];

function ValidationErrorsTable({
  validationResults,
}: ValidationErrorsTableProps) {
  return (
    <Box sx={{ maxHeight: 600, width: '100%', overflow: 'auto' }}>
      <DisplayTable columns={columns}>
        {validationResults.map((validationResult, i) => {
          return (
            <StyledTableRow key={i}>
              <StyledTableCell>
                <RichListItem
                  heading={validationResult.entity.subtype.displayName}
                  description={validationResult.entity.household.displayName}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Typography variant="body1">
                  {validationResult.errorMessage}
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </DisplayTable>
    </Box>
  );
}
