import { BalanceSheetCategoryType } from '@/types/schema';
import { PathsOf } from '@/types/subform';

export const NEW_BALANCE_SHEET_CATEGORY_SENTINEL = 'NEW_BALANCE_SHEET_CATEGORY';

export interface BalanceSheetCategory {
  id: string;
  name: string;
  assetClassIds: string[];
  // used when updating so we don't attempt to change the name of system classes
  _isSystemClass: boolean;
}

export interface BalanceSheetCategoriesForm {
  balanceSheetCategoriesById: Record<string, BalanceSheetCategory>;
  currentlyEditingRowId: string | null;
}

export type BalanceSheetCategoriesFormPaths =
  PathsOf<BalanceSheetCategoriesForm>;

export interface RowData {
  /** this is a special property that indicates what value to show when dragging to reorder */
  __reorder__: string;
  id: string;
  name: string;
  assetClasses: string[] | null;
  action: null;
  isSystemClass: boolean;
  type: BalanceSheetCategoryType;
}
