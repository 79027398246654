import { Box } from '@mui/material';
import { ReactNode } from 'react';

import { COLORS } from '@/styles/tokens/colors';

interface Props {
  size: number;
  icon: ReactNode;
  colors: {
    background: string;
    iconColor?: string;
  };
}

export function Medallion({ size, icon, colors }: Props) {
  return (
    <Box
      display="flex"
      bgcolor={colors.background}
      width={size}
      minWidth={size}
      height={size}
      borderRadius={size / 2}
      justifyContent="center"
      alignItems="center"
      color={colors.iconColor ?? COLORS.PRIMITIVES.WHITE}
    >
      {icon}
    </Box>
  );
}
