// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { SubBrandFragmentDoc } from '../../../admin/branding/graphql/Branding.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateHouseholdModalQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CreateHouseholdModalQueryQuery = { __typename?: 'Query', subBrands: { __typename?: 'SubBrandConnection', edges?: Array<{ __typename?: 'SubBrandEdge', node?: { __typename?: 'SubBrand', id: string, displayName: string } | null } | null> | null } };


export const CreateHouseholdModalQueryDocument = gql`
    query CreateHouseholdModalQuery {
  subBrands {
    edges {
      node {
        ...SubBrand
      }
    }
  }
}
    ${SubBrandFragmentDoc}`;

/**
 * __useCreateHouseholdModalQueryQuery__
 *
 * To run a query within a React component, call `useCreateHouseholdModalQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateHouseholdModalQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateHouseholdModalQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreateHouseholdModalQueryQuery(baseOptions?: Apollo.QueryHookOptions<CreateHouseholdModalQueryQuery, CreateHouseholdModalQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CreateHouseholdModalQueryQuery, CreateHouseholdModalQueryQueryVariables>(CreateHouseholdModalQueryDocument, options);
      }
export function useCreateHouseholdModalQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CreateHouseholdModalQueryQuery, CreateHouseholdModalQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CreateHouseholdModalQueryQuery, CreateHouseholdModalQueryQueryVariables>(CreateHouseholdModalQueryDocument, options);
        }
export function useCreateHouseholdModalQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CreateHouseholdModalQueryQuery, CreateHouseholdModalQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CreateHouseholdModalQueryQuery, CreateHouseholdModalQueryQueryVariables>(CreateHouseholdModalQueryDocument, options);
        }
export type CreateHouseholdModalQueryQueryHookResult = ReturnType<typeof useCreateHouseholdModalQueryQuery>;
export type CreateHouseholdModalQueryLazyQueryHookResult = ReturnType<typeof useCreateHouseholdModalQueryLazyQuery>;
export type CreateHouseholdModalQuerySuspenseQueryHookResult = ReturnType<typeof useCreateHouseholdModalQuerySuspenseQuery>;
export type CreateHouseholdModalQueryQueryResult = Apollo.QueryResult<CreateHouseholdModalQueryQuery, CreateHouseholdModalQueryQueryVariables>;
export const Operations = {
  Query: {
    CreateHouseholdModalQuery: 'CreateHouseholdModalQuery' as const
  }
}