import { compact } from 'lodash';

import { BigBreadcrumbs } from '@/components/navigation/BigBreadcrumbs';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

export function ManageDispositionsBreadcrumbs() {
  const { householdId } = useHouseholdDetailsContext();
  if (!householdId) return null;
  return (
    <BigBreadcrumbs
      crumbs={compact([
        {
          label: 'Dispositions',
        },
      ])}
    />
  );
}
