import { Box } from '@mui/material';
import { compact } from 'lodash';
import { ReactNode } from 'react';

import { BENEFICIARY_LEVEL_DISPLAY_NAMES } from '@/modules/beneficiaries/beneficiaries.constants';
import { EntityStage } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import {
  TrustBeneficiariesList_AccessParameterFragment,
  TrustBeneficiariesList_BeneficiaryFragment,
  TrustBeneficiariesList_ScheduledDistributionFragment,
} from './graphql/TrustBeneficiariesList.fragments.generated';

export interface DistributionDetails {
  id: string;
  heading: string;
  subheading?: string;
  parametersDescription?: string;
}

export interface BeneficiaryRow {
  heading: string;
  description: string;
  additionalItems?: ReactNode[];
  badgeText?: string;
  badgeTextCallout?: string;
  scheduledDistributions?: TrustBeneficiariesList_ScheduledDistributionFragment[];
  accessParameters?: TrustBeneficiariesList_AccessParameterFragment[];
}

export function createBeneficiaryRow(
  beneficiary: TrustBeneficiariesList_BeneficiaryFragment
): BeneficiaryRow {
  const sharedProperties = {
    additionalItems: compact([
      <Box key="notes" sx={{ whiteSpace: 'pre-wrap' }}>
        {beneficiary.notes}
      </Box>,
    ]),
    badgeText: beneficiary.level
      ? BENEFICIARY_LEVEL_DISPLAY_NAMES[beneficiary.level]
      : undefined,
    scheduledDistributions: getNodes(beneficiary.scheduledDistributions),
    accessParameters: getNodes(beneficiary.accessParameters),
  };

  if (beneficiary.individual) {
    return {
      heading: beneficiary.individual.legalName,
      description: 'Individual',
      ...sharedProperties,
    };
  } else if (beneficiary.entity) {
    return {
      heading: beneficiary.entity.subtype.displayName,
      description: beneficiary.entity.extendedDisplayKind,
      badgeTextCallout:
        beneficiary.entity.stage === EntityStage.Active
          ? undefined
          : 'To be created',
      ...sharedProperties,
    };
  } else if (beneficiary.organization) {
    return {
      heading: beneficiary.organization.name,
      description: 'Organization',
      ...sharedProperties,
    };
  }

  throw new Error(`unknown beneficiary type for beneficiary ${beneficiary.id}`);
}

export function getDistributionHeading(distribution: DistributionDetails) {
  const distributionFrequencyDisplay = compact([
    distribution.heading,
    distribution.subheading,
  ])
    .join(' ')
    .toLowerCase();
  return `Parameters for ${distributionFrequencyDisplay}`;
}
