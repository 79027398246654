import { ReactFlowState } from '@xyflow/react';

import { useGetValue } from './internal/useGetValue';

const canvasDimensionsSelector = ({ width, height }: ReactFlowState) => ({
  width,
  height,
});

export function useCanvasDimensions() {
  const { value: canvasDimensions, get: getCanvasDimensions } = useGetValue(
    canvasDimensionsSelector
  );

  return { canvasDimensions, getCanvasDimensions };
}
