// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { ClientProfileBasicFragmentDoc } from '../../../../clientProfiles/graphql/ClientProfile.generated';
export type PrimaryClientDropdown_HouseholdWithPrimaryClientsFragment = { __typename?: 'Household', id: string, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, legalName: string, firstName: string }> };

export type PrimaryClientDropdown_PossibleGrantorFragment = { __typename?: 'ClientProfile', isPrimary: boolean, id: string, displayName: string, legalName: string, firstName: string };

export type PrimaryClientDropdown_HouseholdWithGrantorsFragment = { __typename?: 'Household', id: string, possibleGrantors: Array<{ __typename?: 'ClientProfile', isPrimary: boolean, id: string, displayName: string, legalName: string, firstName: string }> };

export const PrimaryClientDropdown_HouseholdWithPrimaryClientsFragmentDoc = gql`
    fragment PrimaryClientDropdown_HouseholdWithPrimaryClients on Household {
  id
  possiblePrimaryClients {
    ...ClientProfileBasic
  }
}
    ${ClientProfileBasicFragmentDoc}`;
export const PrimaryClientDropdown_PossibleGrantorFragmentDoc = gql`
    fragment PrimaryClientDropdown_PossibleGrantor on ClientProfile {
  ...ClientProfileBasic
  isPrimary
}
    ${ClientProfileBasicFragmentDoc}`;
export const PrimaryClientDropdown_HouseholdWithGrantorsFragmentDoc = gql`
    fragment PrimaryClientDropdown_HouseholdWithGrantors on Household {
  id
  possibleGrantors {
    ...PrimaryClientDropdown_PossibleGrantor
  }
}
    ${PrimaryClientDropdown_PossibleGrantorFragmentDoc}`;
export const Operations = {
  Fragment: {
    PrimaryClientDropdown_HouseholdWithPrimaryClients: 'PrimaryClientDropdown_HouseholdWithPrimaryClients' as const,
    PrimaryClientDropdown_PossibleGrantor: 'PrimaryClientDropdown_PossibleGrantor' as const,
    PrimaryClientDropdown_HouseholdWithGrantors: 'PrimaryClientDropdown_HouseholdWithGrantors' as const
  }
}