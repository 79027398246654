import { useEffect } from 'react';

import { DataTable } from '@/components/tables/DataTable/DataTable';

import { EstateWaterfallModal_EntityFragment } from '../graphql/EstateWaterfallModal.generated';
import { useWaterfallModalQuery } from '../hooks/useWaterfallModalQuery';
import { OnSelectionChange } from './EntityMultiSelectTable.types';
import {
  getRowClassName,
  getTreeDataPath,
  groupingColDef,
} from './EntityMultiSelectTable.utils';
import { useEntityMultiSelectTableRows } from './hooks/useEntityMultiSelectTableRows';
import { useEntityMultiSelectState } from './hooks/useEntityMultiSelectTableState';

interface EntityMultiSelectTableProps {
  entities: EstateWaterfallModal_EntityFragment[];
  hypotheticalTransfers: ReturnType<
    typeof useWaterfallModalQuery
  >['hypotheticalTransfers'];
  onSelectionChange: OnSelectionChange;
  defaultSelectedRowIds?: string[];
}

export function EntityMultiSelectTable({
  entities,
  hypotheticalTransfers,
  onSelectionChange,
  defaultSelectedRowIds,
}: EntityMultiSelectTableProps) {
  const { rows } = useEntityMultiSelectTableRows({
    entities,
    hypotheticalTransfers,
  });

  const { selectedRowIds, setSelectedRowIds } = useEntityMultiSelectState(
    rows,
    defaultSelectedRowIds
  );

  useEffect(() => {
    onSelectionChange({
      selectedRowIds,
      rows,
    });
  }, [onSelectionChange, rows, selectedRowIds]);

  return (
    <DataTable
      treeData
      useTreeStyling
      checkboxSelection
      useEntityAwareStyling
      disableVirtualization
      disableRowSelectionOnClick
      rows={rows}
      columns={[]}
      pagination={false}
      slots={{ footer: () => <></> }}
      groupingColDef={groupingColDef}
      getTreeDataPath={getTreeDataPath}
      getRowClassName={getRowClassName}
      rowSelectionModel={selectedRowIds}
      defaultGroupingExpansionDepth={-1}
      onRowSelectionModelChange={(gridRowIds) => {
        const selectedIds = gridRowIds.map((gridRowId) => gridRowId);
        setSelectedRowIds(selectedIds as string[]);
      }}
    />
  );
}
