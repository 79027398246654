import { InputAdornment } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import * as React from 'react';
import PhoneInput from 'react-phone-number-input/input';

import { PhoneIcon } from '@/components/icons/PhoneIcon';
import { COLORS } from '@/styles/tokens/colors';

import { FormControl } from '../FormControl';
import {
  BasePhoneNumberInputProps,
  FormControlPhoneNumberInputProps,
  HelpTextVariant,
} from '../inputTypes';

interface PhoneNumberInputProps extends FormControlPhoneNumberInputProps {
  label: string;
  // the presence of contextualHelp indicates that there's help text related to this input, which
  // will cause the "info" icon to show up next to the input label
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  hideLabel?: boolean;
}

interface PhoneInputProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  label: string;
  id?: string;
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
}

const PhoneNumberFormatInput = React.forwardRef<
  typeof PhoneInput,
  PhoneInputProps
>(function PhoneNumberFormatCustom({ onChange, name, ...props }, ref) {
  const handleOnChange = (value: string) =>
    onChange({ target: { name, value } });

  return (
    <PhoneInput
      defaultCountry="US"
      onChange={handleOnChange}
      {...props}
      ref={ref}
    />
  );
});

function BasePhoneNumberInput({
  // see here: https://github.com/mui/material-ui/issues/12946
  // stripping off onKeyUp and onKeyDown is required here because TextField doesn't actually implement those props.
  onKeyUp: _onKeyUp,
  onKeyDown: _onKeyDown,
  startAdornment = (
    <InputAdornment position="start">
      <PhoneIcon size={20} />
    </InputAdornment>
  ),
  testId,
  ...inputProps
}: BasePhoneNumberInputProps) {
  const { disabled } = inputProps;
  const disabledStyle = disabled ? { background: COLORS.GRAY[50] } : {};
  const mergedSx = Object.assign({ background: 'white' }, disabledStyle);

  return (
    <TextField
      InputProps={{
        inputComponent: PhoneNumberFormatInput as unknown as NonNullable<
          NonNullable<TextFieldProps['InputProps']>['inputComponent']
        >,
        sx: mergedSx,
        startAdornment,
        inputProps: {
          'data-testid':
            testId ?? `phone-number-input-${inputProps.name ?? ''}`,
        },
      }}
      variant="outlined"
      {...inputProps}
    />
  );
}

export function PhoneNumberInput({
  label,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant,
  hideLabel,
  ...inputProps
}: PhoneNumberInputProps) {
  return (
    <FormControl<BasePhoneNumberInputProps>
      component={BasePhoneNumberInput}
      inputProps={inputProps}
      errorMessage={errorMessage}
      contextualHelp={contextualHelp}
      required={inputProps.required}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
      id={inputProps.id}
      label={label}
      hideLabel={hideLabel}
    />
  );
}
