import {
  array,
  boolean as booleanType,
  Describe,
  enums,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreateJointPersonalAccountInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateJointPersonalAccountInput,
  JointPersonalAccountType,
  UpdateJointPersonalAccountInput,
} from '@/types/schema';

import {
  commonCreatePersonalAccountProperties,
  commonUpdatePersonalAccountProperties,
  controllingPartySchema,
} from './personalAccountTypes';
import {
  beneficiarySchema,
  commonUpdateEntityProperties,
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  principalWithOwnershipSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

export const jointAccountTypeSchema = optional(
  nullable(
    enums([
      JointPersonalAccountType.CommunityProperty,
      JointPersonalAccountType.CommunityPropertyWithRightOfSurvivorship,
      JointPersonalAccountType.JointTenants,
      JointPersonalAccountType.JointTenantsWithRightOfSurvivorship,
      JointPersonalAccountType.TenantsByEntirety,
      JointPersonalAccountType.TenantsInCommon,
    ])
  )
);

const createJointAccountSchema: Describe<AugmentedCreateJointPersonalAccountInput> =
  object({
    create: object({
      ...commonCreatePersonalAccountProperties,
      type: jointAccountTypeSchema,
      clearType: optional(booleanType()),
    }),
    withOwners: optional(nullable(array(principalWithOwnershipSchema))),
    withBeneficiaries: optional(nullable(array(beneficiarySchema))),
    withControllingParties: optional(nullable(array(controllingPartySchema))),
    withDesignerAccount: optional(nullable(createAccountSchema)),
  });

const updateJointPersonalAccountInput: Describe<UpdateJointPersonalAccountInput> =
  object({
    ...commonUpdatePersonalAccountProperties,
    type: jointAccountTypeSchema,
    clearType: optional(nullable(booleanType())),
    clearControllingParties: optional(nullable(booleanType())),
    clearBeneficiaries: optional(nullable(booleanType())),
    clearOwners: optional(nullable(booleanType())),
    clearDescription: optional(nullable(booleanType())),
    clearGstStatus: optional(nullable(booleanType())),
    clearTaxStatus: optional(nullable(booleanType())),
  });

const updateJointAccountSchema: Describe<AugmentedUpdateJointPersonalAccountInput> =
  object({
    id: string(),
    update: updateJointPersonalAccountInput,
    withOwners: optional(nullable(array(principalWithOwnershipSchema))),
    withBeneficiaries: optional(nullable(array(beneficiarySchema))),
    withControllingParties: optional(nullable(array(controllingPartySchema))),
    updateDesignerAccount: optional(nullable(updateAccountSchema)),
  });

export const createSchema: Describe<AugmentedCreateEntityInput> = object({
  create: createEntityInput,
  withJointPersonalAccount: optional(nullable(createJointAccountSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object(commonUpdateEntityProperties),
  updateJointPersonalAccount: optional(nullable(updateJointAccountSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export function validateJointAccountCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'Joint account create');
}

export function validateJointAccountUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'Joint account update');
}
