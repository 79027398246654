import { useApolloClient } from '@apollo/client';
import React from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Alert } from '@/components/notifications/Alert/Alert';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { EntityFormTrowser } from '@/modules/entities/EntityForm/EntityFormTrowser';
import { EntityFormStage } from '@/modules/entities/EntityForm/types';
import * as diagnostics from '@/utils/diagnostics';

import { GetImplementationTasksV2Document } from '../../graphql/ImplementationTasks.generated';
import { TaskHeaderCard } from '../../TaskHeaderCard';
import { TaskBodyProps } from '../../types';
import {
  GetDesignSummaryDetailsDocument,
  useGetDesignSummaryDetailsQuery,
} from '../components/TrustDetailsSummary/graphql/TrustDetailsSummary.generated';
import { TrustDetailsSummary } from '../components/TrustDetailsSummary/TrustDetailsSummary';
import { useCompleteConfirmTrustDetailsTaskMutation } from './graphql/ConfirmTrustDetails.generated';

export function ConfirmTrustDetailsTaskBody({
  task,
  onCompleteTask,
  footer,
}: TaskBodyProps) {
  const apolloClient = useApolloClient();
  const { createErrorFeedback, showFeedback } = useFeedback();
  const [isEntityModalOpen, setIsEntityModalOpen] = React.useState(false);
  const [completeConfirmTrustDetails, { loading: submitting }] =
    useCompleteConfirmTrustDetailsTaskMutation({
      onError: createErrorFeedback(
        "We weren't able to confirm the trust details. Please refresh the page and try again."
      ),
    });

  const {
    data,
    error,
    loading: loadingEntity,
  } = useGetDesignSummaryDetailsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      entityId: task.entityId,
    },
  });

  function handleOpenEditModal() {
    setIsEntityModalOpen(true);
  }

  function handleClose() {
    setIsEntityModalOpen(false);

    void apolloClient.refetchQueries({
      include: [
        GetImplementationTasksV2Document,
        GetDesignSummaryDetailsDocument,
      ],
    });
  }

  if (error) {
    return (
      <TaskHeaderCard task={task} footer={footer}>
        <Alert severity="error" size="lg">
          Sorry, we weren&apos;t able to load the summary information. Please
          try refreshing the page.
        </Alert>
      </TaskHeaderCard>
    );
  }

  if (!data) {
    return <TaskHeaderCard task={task} footer={footer} loading={true} />;
  }

  const { gratDesignSummaryDetails: designSummary } = data;

  async function handleFormSubmit() {
    try {
      const { errors } = await completeConfirmTrustDetails({
        variables: {
          taskID: task.id,
        },
        refetchQueries: [GetImplementationTasksV2Document],
      });

      if (errors) {
        throw new Error(`Failed to confirm trust details and complete task`);
      }

      onCompleteTask();
    } catch (err) {
      diagnostics.error(
        'failed to complete confirm details task',
        err as Error,
        {
          taskID: task.id,
          taskType: task.type,
          householdId: task.householdId,
          entityId: task.entityId,
        }
      );
      showFeedback(
        'Failed to save your data and complete the task. Please try again.'
      );
    }
  }

  return (
    <>
      {isEntityModalOpen && (
        <EntityFormTrowser
          isOpen={true}
          stage={EntityFormStage.GRAT_IMPLEMENTATION_CONFIRM}
          type="grat"
          handleClose={handleClose}
          householdId={task.householdId}
          entityId={task.entityId}
          header={'Edit details to match signed trust document'}
        />
      )}
      <TaskHeaderCard task={task} footer={footer}>
        <TrustDetailsSummary
          variant="confirmTrustDetails"
          designSummary={designSummary}
          onEditClick={handleOpenEditModal}
        />
        <Card variant="filled" sx={{ p: 2 }}>
          <FormLayoutRow>
            <FormLayoutItem>
              <Button
                disabled={loadingEntity || submitting}
                variant="primary"
                startIcon={CheckIcon}
                fullWidth
                size="sm"
                type="submit"
                onClick={handleFormSubmit}
              >
                Confirm details
              </Button>
            </FormLayoutItem>
          </FormLayoutRow>
        </Card>
      </TaskHeaderCard>
    </>
  );
}
