import { compact } from 'lodash';

import { SelectInputOption } from '../inputTypes';
import { SelectInputProps } from './SelectInput';
import { SelectItemGroupLabel } from './SelectItemGroupLabel';

export interface SelectOptionGroup {
  label: React.ReactNode;
  options: SelectInputOption[];
}

export function getOptionsWithGroupLabels(
  optionGroups: SelectOptionGroup[]
): SelectInputProps['options'] {
  return compact(
    optionGroups.flatMap(({ label: groupLabel, options: groupOptions }) => {
      if (groupOptions.length === 0) {
        return null;
      }

      return [
        {
          component: <SelectItemGroupLabel label={groupLabel} />,
          type: 'component' as const,
        },
        ...groupOptions,
      ];
    })
  );
}
