import { PopperContent } from '@/components/poppers/PopperContent';

export function GRATDisplayName() {
  return (
    <PopperContent
      heading={'GRAT display name'}
      body={
        'This will be used in the client presentation and in reporting on the platform.'
      }
    />
  );
}
