import {
  ListItemIcon,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { CompoundSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { SelectInput } from '@/components/form/baseInputs/SelectInput';
import { UserPlus01Icon } from '@/components/icons/UserPlus01Icon';
import { XCloseIcon } from '@/components/icons/XCloseIcon';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { AISuggestionsMatcherVariant } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import {
  getMatchFromNewId,
  getSuggestedActorDisplayName,
} from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.utils';
import { useAiSuggestionsMatcherContext } from '@/modules/aiSuggestions/AISuggestionsMatcher/context/aiSuggestionsMatcher.context';
import { useAISuggestionsMatcherGroupedOptions } from '@/modules/aiSuggestions/AISuggestionsMatcher/hooks/useAISuggestionsMatcherGroupedOptions';
import {
  LocateSuggestionIcon,
  LocateSuggestionIconVariant,
} from '@/modules/aiSuggestions/components/LocateSuggestionIcon';
import { CommonAiSuggestionFragment } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { BASIC_INFORMATION_SUBFORM_NAMESPACE } from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

enum AISuggestionMatchAction {
  ADD = 'ADD',
  IGNORE = 'IGNORE',
}

interface AISuggestedPersonRowProps {
  variant: AISuggestionsMatcherVariant;
  suggestion: CommonAiSuggestionFragment;
  onSetSuggestionMatch: (
    suggestionId: string,
    matched: {
      clientProfileId?: string;
      clientOrganizationId?: string;
      entityId?: string;
    }
  ) => void;
  onOpenAddAnythingModal: () => void;
}

export function AISuggestionMatcherRow({
  variant,
  suggestion,
  onSetSuggestionMatch,
  onOpenAddAnythingModal,
}: AISuggestedPersonRowProps) {
  const trackUserEvent = useTrackUserEvent();

  const { householdId } = useHouseholdDetailsContext();

  const {
    matchedSuggestions,
    matcherConfigs: { nounSingular },
  } = useAiSuggestionsMatcherContext();

  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    const match = matchedSuggestions[suggestion.id];

    if (!match) {
      setSelectedOption('');
      return;
    }

    const matchedId =
      match.clientProfileId || match.clientOrganizationId || match.entityId;
    if (matchedId) {
      setSelectedOption(matchedId);
    } else {
      setSelectedOption(AISuggestionMatchAction.IGNORE);
    }
  }, [matchedSuggestions, suggestion.id]);

  const singleGrantorFieldValue = useWatch({
    name: `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.grantor.clientProfileId`,
  });

  const groupedOptions = useAISuggestionsMatcherGroupedOptions({
    matcherVariant: variant,
    suggestionKind: suggestion.kind,
  });

  const actionOptions: CompoundSelectInputOption<unknown>[] = useMemo(() => {
    return [
      {
        value: AISuggestionMatchAction.ADD,
        display: 'Add new',
        startAdornment: (
          <ListItemIcon>
            <UserPlus01Icon size={16} />
          </ListItemIcon>
        ),
        // Don't allow adding a new item if the single grantor field is already set.
        disabled:
          variant === AISuggestionsMatcherVariant.SINGLE_GRANTOR &&
          singleGrantorFieldValue,
      },
      {
        value: AISuggestionMatchAction.IGNORE,
        display: `Ignore this ${nounSingular}`,
        startAdornment: (
          <ListItemIcon>
            <XCloseIcon size={16} />
          </ListItemIcon>
        ),
      },
    ];
  }, [nounSingular, singleGrantorFieldValue, variant]);

  const updateSuggestionMatch = useCallback(
    (selectedId: string) => {
      const match = getMatchFromNewId(selectedId);

      if (!match) {
        throw new Error(
          `Unsupported selected suggestion match option: ${selectedId}`
        );
      }

      onSetSuggestionMatch(suggestion.id, match);

      trackUserEvent('ai_suggestion people-matcher match-existing', {
        suggestionKind: suggestion.kind,
      });
    },
    [onSetSuggestionMatch, suggestion.id, suggestion.kind, trackUserEvent]
  );

  const handleSelectActionOption = useCallback(
    (e: SelectChangeEvent<unknown>) => {
      const selectedOption = e.target.value as string;

      if (selectedOption === `${AISuggestionMatchAction.ADD}`) {
        onOpenAddAnythingModal();
        trackUserEvent('ai_suggestion people-matcher add-new', {
          suggestionKind: suggestion.kind,
        });
        // Return here to prevent setting the selected value until the user
        // has added the new item.
        return;
      }

      setSelectedOption(selectedOption);

      if (selectedOption === `${AISuggestionMatchAction.IGNORE}`) {
        onSetSuggestionMatch(suggestion.id, {});
        trackUserEvent('ai_suggestion people-matcher ignore', {
          suggestionKind: suggestion.kind,
        });
        return;
      }

      updateSuggestionMatch(selectedOption);
    },
    [
      onOpenAddAnythingModal,
      onSetSuggestionMatch,
      suggestion.id,
      suggestion.kind,
      trackUserEvent,
      updateSuggestionMatch,
    ]
  );

  // Disable the selector if single-variant, and we've already selected
  // another suggestion as a match.
  const disabled = useMemo(() => {
    if (
      variant !== AISuggestionsMatcherVariant.SINGLE_GRANTOR ||
      isEmpty(matchedSuggestions) ||
      selectedOption
    ) {
      return false;
    }

    return Object.values(matchedSuggestions).some(
      (m) => m.clientProfileId || m.clientOrganizationId
    );
  }, [matchedSuggestions, selectedOption, variant]);

  if (!householdId) {
    return null;
  }

  return (
    <>
      <Stack
        p={1.5}
        pl={0}
        width="100%"
        flex={1}
        direction="row"
        alignItems="center"
        gap={2}
        data-testid="ai-suggestions-matcher-row"
      >
        <LocateSuggestionIcon
          variant={LocateSuggestionIconVariant.MATCHER}
          suggestion={suggestion}
        />
        <Typography variant="h5" component="div" flex={1}>
          {getSuggestedActorDisplayName(suggestion)}
        </Typography>
        <SelectInput<unknown>
          sx={{ width: 240 }}
          required
          value={selectedOption}
          hideLabel
          label="Select action"
          showEmptyValue
          emptyValueDisplay="Select action"
          options={[...actionOptions, ...groupedOptions]}
          onChange={handleSelectActionOption}
          disabled={disabled}
        />
      </Stack>
    </>
  );
}
