import { Box, Typography } from '@mui/material';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';

import { useChartColorDefinitions } from '@/components/charts/constants';
import { Loader } from '@/components/progress/Loader/Loader';
import { COLORS } from '@/styles/tokens/colors';

import { TableEmptyState } from '../DataTable/TableEmptyState';
import { DEFAULT_ROW_HEIGHT } from './constants';
import {
  DataGridStyleConfig,
  getDataGridSx,
  getFormattedColumnDefinitions,
} from './tableFormattingUtils';
import { Column, RowClickHandler } from './tableTypes';

export interface DataTable_LEGACYProps
  extends DataGridProProps,
    Partial<DataGridStyleConfig> {
  header?: string;
  onRowClick?: RowClickHandler;
  columns: Column[];
  variant: 'short' | 'long';
  isSumTable?: boolean;
  hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS?: boolean;
}

function DefaultLoadingOverlay() {
  return (
    <Loader
      boxProps={{
        sx: {
          textAlign: 'center',
          my: 3,
        },
      }}
    />
  );
}

function DefaultEmptyState() {
  return <TableEmptyState text="No rows" />;
}

export function DataTable_LEGACY({
  rows,
  columns,
  header,
  variant,
  slots,
  isSumTable,
  initialState,
  hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS,
  sx,
  rowHoverVariant = isSumTable ? 'transparent' : 'default',
  ...dataGridProps
}: DataTable_LEGACYProps) {
  const graphColors = useChartColorDefinitions();
  const formattedColumns = React.useMemo(
    () => getFormattedColumnDefinitions(columns),
    [columns]
  );

  const getPageSize = (
    listType: DataTable_LEGACYProps['variant'],
    hideFooter: boolean
  ) => {
    if (hideFooter) {
      // this is the max number of rows MUI DataGrid will display
      return 100;
    }

    if (listType === 'long') {
      return 20;
    }

    if (listType === 'short') {
      return 10;
    }

    return 10;
  };

  const pageSize = getPageSize(
    variant,
    hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS ?? false
  );
  const dataGridSx = useMemo(
    () => getDataGridSx(graphColors, { rowHoverVariant }),
    [graphColors, rowHoverVariant]
  );
  const style = {
    ...dataGridSx,
    ...sx,
  };

  const shouldUseEmptyRows = isSumTable && rows.length === 1;
  if (isSumTable) {
    // disable sorting for all columns if this is a sum table
    // because I haven't figured out how to exclude the sum row from being sorted
    formattedColumns.forEach((column) => {
      column.sortable = false;
    });
  }

  return (
    <Box data-it="DataTable_LEGACY">
      {header && (
        <Box
          p={1.5}
          mb={3}
          data-it="DataTable_LEGACY-headerContainer"
          component="header"
        >
          <Typography color={COLORS.NAVY[600]} variant="h1" component="h2">
            {header}
          </Typography>
        </Box>
      )}
      <Box data-it="DataTable_LEGACY-tableContainer">
        <DataGridPro
          pagination
          rowHeight={DEFAULT_ROW_HEIGHT}
          sx={style}
          slots={{
            loadingOverlay: DefaultLoadingOverlay,
            noRowsOverlay: DefaultEmptyState,
            ...slots,
          }}
          rows={shouldUseEmptyRows ? [] : rows}
          columns={formattedColumns}
          disableColumnFilter={true}
          disableColumnMenu={true}
          disableColumnSelector={true}
          disableRowSelectionOnClick={true}
          pageSizeOptions={[pageSize]}
          autoHeight={true}
          hideFooter={hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS ?? false}
          getRowClassName={(params) => {
            if (isSumTable) {
              return params.isLastVisible ? 'sum' : '';
            }
            return '';
          }}
          initialState={{
            ...initialState,
            pagination: { paginationModel: { pageSize } },
          }}
          {...dataGridProps}
        />
      </Box>
    </Box>
  );
}
