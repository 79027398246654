import { Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { UsersPlusIcon } from '@/components/icons/UsersPlusIcon';
import { areAllHeaderListGroupsEmpty } from '@/components/lists/GroupedHeaderList/GroupedHeaderList.utils';
import { EmptyListEditEntityActionCard } from '@/modules/entities/components/EmptyListEditEntityActionCard';
import { EntitySubformCategory } from '@/modules/entities/EntitySubforms/entitySubformConfigs';
import { EntityKind } from '@/types/schema';

import { ExpandableBeneficiaryTable } from './ExpandableBeneficiaryTable';
import { FlatBeneficiaryTable } from './FlatBeneficiaryTable';
import { TrustBeneficiariesListProps } from './TrustBeneficiariesList';

export function TrustBeneficiariesListV2({
  beneficiariesGroups,
  variant = 'expandable',
  isTestamentaryEntity,
  onShowEditEntity,
  entityKind,
}: TrustBeneficiariesListProps) {
  const hasBeneficiaries = !areAllHeaderListGroupsEmpty(beneficiariesGroups);

  useEffect(() => {
    if (hasBeneficiaries && variant === 'expandable') {
      onShowEditEntity?.();
    }
  }, [hasBeneficiaries, onShowEditEntity, variant]);

  const TableComponent = useMemo(
    () =>
      variant === 'flat' ? FlatBeneficiaryTable : ExpandableBeneficiaryTable,
    [variant]
  );

  if (variant === 'expandable' && !hasBeneficiaries) {
    const beneficiariesAreLifetime =
      isTestamentaryEntity || entityKind === EntityKind.SlatTrust;

    const labels = beneficiariesAreLifetime
      ? {
          heading: 'Specify lifetime beneficiaries',
          buttonText: 'Add lifetime beneficiaries',
        }
      : {
          heading: 'Specify current beneficiaries',
          buttonText: 'Add current beneficiaries',
        };

    return (
      <EmptyListEditEntityActionCard
        {...labels}
        icon={UsersPlusIcon}
        description="No beneficiaries have been specified for this entity"
        editSubformCategory={EntitySubformCategory.BENEFICIARIES}
      />
    );
  }

  return (
    <Stack spacing={2}>
      {beneficiariesGroups.map((group, idx) => (
        <TableComponent
          key={group.heading ?? `beneficiary-group-${idx}`}
          beneficiaries={group.items}
          heading={group.heading}
          entityKind={entityKind}
        />
      ))}
    </Stack>
  );
}
