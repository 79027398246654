import {
  lighten,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { PropsWithChildren } from 'react';

import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { COLORS } from '@/styles/tokens/colors';
import { sumDecimalJS } from '@/utils/decimalJSUtils';

import { Direction } from '../../EstateWaterfallComparisonTrowserLegacy/WaterfallComparisonBenefitsCard';
import {
  BigMetricWithLabel,
  SmallMetricWithLabel,
} from '../../EstateWaterfallComparisonTrowserLegacy/WaterfallComparisonBenefitsCardLabels';
import { EstateWaterfallComparisonTrowserComponentsProps } from '../EstateWaterfallComparisonTrowser.components';

function getDirection(value: Decimal): Direction {
  if (value.isZero()) {
    return 'none';
  }
  return value.isPositive() ? 'up' : 'down';
}

function ValueContainer({ children }: PropsWithChildren) {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      sx={{
        borderRadius: '6px',
        bgcolor: lighten(theme.palette.primary.main, 0.1),
        px: 2,
        py: 1.5,
      }}
      borderRadius="6px"
      spacing={3}
    >
      {children}
    </Stack>
  );
}

export function EstateWaterfallComparisonTrowserParentComparisonSummary({
  waterfalls,
}: EstateWaterfallComparisonTrowserComponentsProps) {
  const viewOnly = useViewOnly();
  const theme = useTheme();
  const [waterfall1, waterfall2] = compact(waterfalls);
  const isLessThanMedium = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  if (waterfalls.length !== 2) {
    return null;
  }

  if (!waterfall1 || !waterfall2) {
    return null;
  }

  if (waterfall1.isHypothetical && waterfall1.parentId !== waterfall2.id) {
    return null;
  }

  if (waterfall2.isHypothetical && waterfall2.parentId !== waterfall1.id) {
    return null;
  }

  // do not display the comparison bar if both are parents
  if (!waterfall1.isHypothetical && !waterfall2.isHypothetical) {
    return null;
  }

  const hypotheticalWaterfall = waterfall1.isHypothetical
    ? waterfall1
    : waterfall2;
  const parentWaterfall = waterfall1.isHypothetical ? waterfall2 : waterfall1;

  const estateTaxDifference = hypotheticalWaterfall.giftAndEstateTax.minus(
    parentWaterfall.giftAndEstateTax
  );

  const hypotheticalTotalWealth = sumDecimalJS([
    hypotheticalWaterfall.inEstate,
    hypotheticalWaterfall.outOfEstateFamily,
    hypotheticalWaterfall.outOfEstateCharity,
  ]);

  const parentTotalWealth = sumDecimalJS([
    parentWaterfall.inEstate,
    parentWaterfall.outOfEstateFamily,
    parentWaterfall.outOfEstateCharity,
  ]);

  const totalWeatlhDifference =
    hypotheticalTotalWealth.minus(parentTotalWealth);

  const charitableDifference = hypotheticalWaterfall.outOfEstateCharity.minus(
    parentWaterfall.outOfEstateCharity
  );

  const familyDifference = hypotheticalWaterfall.outOfEstateFamily.minus(
    parentWaterfall.outOfEstateFamily
  );

  return (
    <Stack
      direction={isLessThanMedium ? 'column' : 'row'}
      p={3}
      borderRadius="6px"
      bgcolor={theme.palette.primary.main}
      spacing={4}
      justifyContent="space-between"
      data-testid="WaterfallComparisonBenefitsCard"
    >
      <Typography
        variant={viewOnly ? 'h4' : 'h2'}
        color={COLORS.PRIMITIVES.WHITE}
      >
        Hypothetical waterfall benefits
      </Typography>
      <Stack
        direction="row"
        spacing={4}
        justifyContent={isLessThanMedium ? 'flex-start' : 'flex-end'}
      >
        <ValueContainer>
          <BigMetricWithLabel
            label="Estate tax"
            value={{ value: estateTaxDifference, isPercent: false }}
            direction={getDirection(estateTaxDifference)}
          />
        </ValueContainer>
        <ValueContainer>
          <BigMetricWithLabel
            label="Total wealth"
            value={{ value: totalWeatlhDifference, isPercent: false }}
            direction={getDirection(totalWeatlhDifference)}
          />
          <Stack justifyContent="space-between">
            <SmallMetricWithLabel
              label="Charitable"
              value={{ value: charitableDifference, isPercent: false }}
              direction={getDirection(charitableDifference)}
            />
            <SmallMetricWithLabel
              label="Family"
              value={{ value: familyDifference, isPercent: false }}
              direction={getDirection(familyDifference)}
            />
          </Stack>
        </ValueContainer>
      </Stack>
    </Stack>
  );
}
