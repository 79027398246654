import { Box, Stack } from '@mui/system';
import { Control, FieldValues } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';

import { useGetTaskAssigneeOptions } from '../../hooks/useGetTaskAssigneeOptions';
import { TaskRibbon } from '../TaskRibbon/TaskRibbon';

export interface TaskDetailsFormFragmentProps<FormShape extends FieldValues> {
  householdId: string;
  control: Control<FormShape>;
  description: React.ReactNode;
  controlsDisabled?: boolean;
  showSave?: boolean;
  onSave?: () => void;
}

export function TaskDetailsFormFragment<FormShape extends FieldValues>({
  householdId,
  control,
  description,
  controlsDisabled,
  showSave,
  onSave,
}: TaskDetailsFormFragmentProps<FormShape>) {
  const [
    assigneeOptions,
    { error: taskAssigneesError, loading: taskAssigneesLoading },
  ] = useGetTaskAssigneeOptions({
    variables: {
      householdId,
    },
  });

  return (
    <>
      {/* margin on a standalone component is weird, but otherwise there would be overlap between a
          component above this one and the edge of the ribbon */}
      <Card variant="filled" sx={{ px: 2, py: 3, mt: 1, overflow: 'visible' }}>
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Box
              sx={(theme) => ({
                position: 'relative',
                height: theme.spacing(9),
                top: theme.spacing(-5),
                left: theme.spacing(1.5),
              })}
            >
              <TaskRibbon />
            </Box>
            <Box ml={9}>{description}</Box>
          </Stack>
          <Box>
            <FormLayoutRow>
              <FormLayoutItem width={6}>
                <FormAwareSelectInput
                  control={control}
                  required
                  label="Assigned to"
                  fieldName="assigneeId"
                  disabled={
                    controlsDisabled ||
                    taskAssigneesLoading ||
                    !!taskAssigneesError
                  }
                  options={assigneeOptions}
                />
              </FormLayoutItem>
              <FormLayoutItem width={6}>
                <FormAwareDatePickerInput
                  control={control}
                  required
                  disabled={controlsDisabled}
                  label="Due date"
                  fieldName="dueDate"
                />
              </FormLayoutItem>
            </FormLayoutRow>
          </Box>
          {showSave && (
            <Box sx={{ pt: 2 }}>
              <Button
                fullWidth
                variant="primary"
                size="sm"
                onClick={onSave}
                disabled={controlsDisabled}
              >
                Save task assignment changes
              </Button>
            </Box>
          )}
        </Stack>
      </Card>
      {taskAssigneesError && (
        <Callout sx={{ mt: 2 }} severity="error">
          We weren&apos;t able to fetch task assignees. Please refresh the page
          to try again.
        </Callout>
      )}
    </>
  );
}
