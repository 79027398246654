import { Box, darken, Stack, Typography } from '@mui/material';
import { ComponentProps } from 'react';

import { COLORS } from '@/styles/tokens/colors';

interface Props {
  ribbonColor: string;
  ribbonText?: string;
  icon?: JSX.Element;
  alignItems?: ComponentProps<typeof Stack>['alignItems'] | undefined;
}

const BORDER_RADIUS = 6;

export function RibbonVertical({
  ribbonColor,
  ribbonText,
  icon,
  alignItems,
}: Props) {
  return (
    <Box sx={{ position: 'absolute' }}>
      <Stack
        sx={{
          position: 'relative',
          padding: '10px',
          paddingTop: '30px',
          top: '8px',
          left: '-10px',
          backgroundColor: ribbonColor,
          color: 'white',
          borderBottomRightRadius: BORDER_RADIUS,
          borderBottomLeftRadius: BORDER_RADIUS,
          zIndex: 2,
          width: 'fit-content',
        }}
        direction="column"
        alignItems={alignItems}
      >
        {icon && icon}
        {ribbonText && (
          <Typography
            color={COLORS.PRIMITIVES.WHITE}
            variant="h6"
            component="span"
          >
            {ribbonText}
          </Typography>
        )}
      </Stack>
      <Box
        sx={{
          position: 'absolute',
          right: -3,
          top: 8,
          border: '8px solid transparent',
          borderBottom: '0px solid transparent',
          borderLeft: `5px solid ${darken(ribbonColor, 0.2)}`,
        }}
      ></Box>
    </Box>
  );
}
