import { useEffect, useState } from 'react';

export function useDocumentVisibility() {
  const [visibility, setVisibility] = useState(document.visibilityState);

  useEffect(() => {
    function handleVisibilityChange() {
      setVisibility(document.visibilityState);
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return visibility;
}
