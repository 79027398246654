import { ApolloClient } from '@apollo/client';
import { includes } from 'lodash';

import {
  EntityGstStatus,
  EntityInEstateStatus,
  EntityKind,
  NonTrustEntityTaxStatus,
  StateCode,
  TrustTaxStatus,
} from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import {
  entityGstStatusToApiTypeMap,
  estateInclusionStatusToApiTypeMap,
  nonTrustEntityTaxStatusToApiTypeMap,
  trustTaxStatusToApiTypeMap,
} from '../EntitySubforms/utils/shared/taxStatus.utils';
import {
  CltTrustTaxStatusFragment,
  CustodialNonTrustEntityTaxStatusFragment,
  DonorAdvisedFundEntityTaxStatusFragment,
  EntityTaxStatusFragment,
  GratTrustTaxStatusFragment,
  IlitTrustTaxStatusFragment,
  IrrevocableTrustTaxStatusFragment,
  JointAccountTaxStatusFragment,
  PersonalAccountTaxStatusFragment,
  PrivateFoundationEntityTaxStatusFragment,
  QprtTrustTaxStatusFragment,
  QualifiedTuitionNonTrustEntityTaxStatusFragment,
  RevocableTrustTaxStatusFragment,
  SlatTrustTaxStatusFragment,
} from '../graphql/EntityTaxStatus.fragment.generated';
import { EntityType } from '../types/EntityType';
import { getEntityTypeFromEntityKind } from '../utils/getEntityTypeFromEntityKind';
import {
  GetTaxStatusDocument,
  GetTaxStatusQuery,
} from './graphql/TaxStatus.generated';
import {
  GRANTOR_TRUST,
  GST_NON_EXEMPT,
  IN_ESTATE,
  NON_TAXABLE_ENTITY,
  NON_TAXABLE_TRUST,
  OUT_OF_ESTATE,
  TAXABLE_ENTITY,
} from './TaxStatusSubform.constants';
import {
  EstateInclusionStatus,
  GenericNonTrustEntityTaxStatus,
  GenericTrustTaxStatus,
  GSTStatus,
  TaxStatusSubformType,
  TaxStatusSubformTypeStateTax,
  VariantType,
} from './TaxStatusSubform.types';

export const gstStatusToFormGSTStatus: Record<EntityGstStatus, GSTStatus> = {
  GST_EXEMPT: 'gst-exempt',
  GST_NON_EXEMPT: 'gst-non-exempt',
  MIXED_GST: 'mixed-gst',
};

export const inEstateStatusToFormEstateInclusionStatus: Record<
  EntityInEstateStatus,
  EstateInclusionStatus
> = {
  IN_ESTATE: 'in-estate',
  OUT_OF_ESTATE: 'out-of-estate',
};

const trustTaxStatusToFormTaxStatus: Record<
  TrustTaxStatus,
  GenericTrustTaxStatus
> = {
  GRANTOR_TRUST: 'grantor-trust',
  NON_GRANTOR_TRUST: 'non-grantor-trust',
  NON_TAXABLE_TRUST: 'non-taxable-trust',
};

const nonTrustEntityTaxStatusToFormTaxStatus: Record<
  NonTrustEntityTaxStatus,
  GenericNonTrustEntityTaxStatus
> = {
  TAXABLE: 'taxable-entity',
  NON_TAXABLE: 'non-taxable-entity',
};

function genericTrustToForm({
  trustTaxStatus,
  gstStatus,
  inEstateStatus,
  stateTax,
}: {
  trustTaxStatus: TrustTaxStatus | null;
  gstStatus: EntityGstStatus | null;
  inEstateStatus: EntityInEstateStatus | null;
  stateTax: TaxStatusSubformType['stateTax'];
}) {
  return {
    nonTrustEntityTaxStatus: '' as const,
    taxStatus: trustTaxStatus
      ? trustTaxStatusToFormTaxStatus[trustTaxStatus]
      : ('' as const),
    gstStatus: gstStatus ? gstStatusToFormGSTStatus[gstStatus] : ('' as const),
    estateInclusionStatus: inEstateStatus
      ? inEstateStatusToFormEstateInclusionStatus[inEstateStatus]
      : 'in-estate',
    stateTax,
  };
}

function genericNonTrustEntityToForm({
  nonTrustEntityTaxStatus,
  gstStatus,
  inEstateStatus,
  stateTax,
}: {
  nonTrustEntityTaxStatus: NonTrustEntityTaxStatus | null;
  gstStatus: EntityGstStatus | null;
  inEstateStatus: EntityInEstateStatus | null;
  stateTax: TaxStatusSubformType['stateTax'];
}) {
  return {
    taxStatus: '' as const,
    nonTrustEntityTaxStatus: nonTrustEntityTaxStatus
      ? nonTrustEntityTaxStatusToFormTaxStatus[nonTrustEntityTaxStatus]
      : ('' as const),
    gstStatus: gstStatus ? gstStatusToFormGSTStatus[gstStatus] : ('' as const),
    estateInclusionStatus: inEstateStatus
      ? inEstateStatusToFormEstateInclusionStatus[inEstateStatus]
      : 'in-estate',
    stateTax,
  };
}

type TaxStatusSubtypeFragment =
  | IrrevocableTrustTaxStatusFragment
  | RevocableTrustTaxStatusFragment
  | SlatTrustTaxStatusFragment
  | GratTrustTaxStatusFragment
  | IlitTrustTaxStatusFragment
  | QprtTrustTaxStatusFragment
  | CltTrustTaxStatusFragment
  | CustodialNonTrustEntityTaxStatusFragment
  | QualifiedTuitionNonTrustEntityTaxStatusFragment
  | DonorAdvisedFundEntityTaxStatusFragment
  | PrivateFoundationEntityTaxStatusFragment
  | PersonalAccountTaxStatusFragment
  | JointAccountTaxStatusFragment;

function getTaxStatusForEntity({
  entityType,
  stateTaxes,
}: {
  entityType: TaxStatusSubtypeFragment;
  stateTaxes: EntityTaxStatusFragment['stateTaxes'];
}): TaxStatusSubformType {
  const stateTax =
    stateTaxes?.reduce(
      (acc, stateTax) => {
        acc[stateTax.stateCode] = {
          stateCode: stateTax.stateCode,
          inEstateStatus: stateTax.inEstateStatus,
        };
        return acc;
      },
      {} as Partial<Record<StateCode, TaxStatusSubformTypeStateTax>>
    ) ?? ({} as Partial<Record<StateCode, TaxStatusSubformTypeStateTax>>);

  // Merge default state taxes with the state taxes from the entity
  const stateTaxesWithDefaults = Object.values(StateCode).reduce(
    (acc, stateCode) => ({
      ...acc,
      [stateCode]: {
        stateCode,
        inEstateStatus: stateTax[stateCode]?.inEstateStatus ?? null,
      },
    }),
    {} as Record<StateCode, TaxStatusSubformTypeStateTax>
  );

  switch (entityType.__typename) {
    case 'IrrevocableTrust':
    case 'RevocableTrust':
    case 'SLATTrust':
    case 'ILITTrust':
    case 'QPRTTrust':
    case 'CLTTrust':
      return genericTrustToForm({
        trustTaxStatus: entityType.taxStatus ?? null,
        gstStatus: entityType.gstStatus ?? null,
        inEstateStatus: entityType.inEstateStatus ?? null,
        stateTax: stateTaxesWithDefaults,
      });

    case 'GRATTrust':
      // if all the GRAT's tax status fields are null we assume the GRAT is new and
      // we use default values, otherwise we use the values from the saved GRAT
      if (
        entityType.taxStatus === null &&
        entityType.gstStatus === null &&
        entityType.inEstateStatus === null
      ) {
        return genericTrustToForm({
          trustTaxStatus: TrustTaxStatus.GrantorTrust,
          gstStatus: null,
          inEstateStatus: EntityInEstateStatus.InEstate,
          stateTax: stateTaxesWithDefaults,
        });
      }

      return genericTrustToForm({
        trustTaxStatus: entityType.taxStatus ?? null,
        gstStatus: entityType.gstStatus ?? null,
        inEstateStatus: entityType.inEstateStatus ?? null,
        stateTax: stateTaxesWithDefaults,
      });

    case 'QualifiedTuitionPersonalAccount':
    case 'DonorAdvisedFund':
    case 'PrivateFoundation':
    case 'IndividualPersonalAccount':
    case 'JointPersonalAccount':
    case 'CustodialPersonalAccount':
      return genericNonTrustEntityToForm({
        nonTrustEntityTaxStatus: entityType.nonTrustEntityTaxStatus ?? null,
        gstStatus: entityType.gstStatus ?? null,
        inEstateStatus: entityType.nonTrustEntityInEstateStatus ?? null,
        stateTax: stateTaxesWithDefaults,
      });

    default:
      throw new Error(`Unexpected entity type ${entityType.__typename}`);
  }
}

function dataToForm(data: GetTaxStatusQuery): TaxStatusSubformType {
  if (data.node?.__typename !== 'Entity') {
    throw new Error('Unexpected node type');
  }

  return getTaxStatusForEntity({
    entityType: data.node.subtype as TaxStatusSubtypeFragment,
    stateTaxes: data.node.stateTaxes,
  });
}

export async function taxStatusDataFetcher(
  apolloClient: ApolloClient<object>,
  nodeId: string
): Promise<TaxStatusSubformType> {
  const { data, error } = await apolloClient.query<GetTaxStatusQuery>({
    query: GetTaxStatusDocument,
    variables: {
      nodeId,
    },
  });

  if (error) {
    diagnostics.error('Could not fetch data for tax status subform', error);

    throw error;
  }

  return dataToForm(data);
}

export function getTaxStatusVariant(entityType: EntityType): VariantType {
  // there are other personal account entity types, but none that use the tax status subform
  if (
    includes(
      [
        'qualified-tuition-account',
        'custodial-account',
        'daf',
        'private-foundation',
      ],
      entityType
    )
  ) {
    return 'nonTrustEntity';
  }

  if (includes(['individual-account', 'joint-account'], entityType)) {
    return 'nonTrustEntityDefaultInEstate';
  }

  return 'default';
}

export const defaultStateTaxSubformForm = Object.values(StateCode).reduce(
  (acc, stateCode) => ({
    ...acc,
    [stateCode]: {
      stateCode,
      inEstateStatus: null,
    },
  }),
  {} as Record<StateCode, TaxStatusSubformTypeStateTax>
);

export const taxStatusDefaultValues: TaxStatusSubformType = {
  estateInclusionStatus: 'in-estate',
  taxStatus: '',
  nonTrustEntityTaxStatus: '',
  gstStatus: '',
  stateTax: defaultStateTaxSubformForm,
};

export function getTaxStatusDefaultValues(
  variant: VariantType,
  entityType: EntityType
): TaxStatusSubformType {
  switch (variant) {
    case 'default': {
      const trustEntitySharedDefaults = {
        nonTrustEntityTaxStatus: '' as const, // nonTrustEntityTaxStatus is not used for the `default` variant
        stateTax: defaultStateTaxSubformForm,
      };

      switch (entityType) {
        case 'irrevocable-trust':
          return {
            ...trustEntitySharedDefaults,
            estateInclusionStatus: OUT_OF_ESTATE,
            gstStatus: '' as const,
            taxStatus: '' as const,
          };
        case 'revocable-trust':
          return {
            ...trustEntitySharedDefaults,
            estateInclusionStatus: IN_ESTATE,
            gstStatus: '' as const,
            taxStatus: GRANTOR_TRUST,
          };
        case 'slat':
          return {
            ...trustEntitySharedDefaults,
            estateInclusionStatus: OUT_OF_ESTATE,
            gstStatus: '' as const,
            taxStatus: GRANTOR_TRUST,
          };
        case 'grat':
          return {
            ...trustEntitySharedDefaults,
            estateInclusionStatus: IN_ESTATE,
            gstStatus: '' as const,
            taxStatus: GRANTOR_TRUST,
          };
        case 'ilit':
          return {
            ...trustEntitySharedDefaults,
            estateInclusionStatus: OUT_OF_ESTATE,
            gstStatus: '' as const,
            taxStatus: '' as const,
          };
        case 'qprt':
          return {
            ...trustEntitySharedDefaults,
            estateInclusionStatus: IN_ESTATE,
            gstStatus: '' as const,
            taxStatus: GRANTOR_TRUST,
          };
        case 'crt':
          return {
            ...trustEntitySharedDefaults,
            estateInclusionStatus: OUT_OF_ESTATE,
            gstStatus: '' as const,
            taxStatus: NON_TAXABLE_TRUST,
          };
        case 'clt':
          return {
            ...trustEntitySharedDefaults,
            estateInclusionStatus: OUT_OF_ESTATE,
            gstStatus: '' as const,
            taxStatus: '' as const,
          };

        default:
          return taxStatusDefaultValues;
      }
    }
    case 'nonTrustEntity': {
      const nonTrustEntitySharedDefaults = {
        estateInclusionStatus: OUT_OF_ESTATE,
        taxStatus: '' as const, // taxStatus is not used for the `nonTrustEntity` variant
      };

      switch (entityType) {
        case 'custodial-account':
          return {
            ...nonTrustEntitySharedDefaults,
            gstStatus: GST_NON_EXEMPT,
            nonTrustEntityTaxStatus: TAXABLE_ENTITY,
            stateTax: defaultStateTaxSubformForm,
          };

        case 'qualified-tuition-account':
          return {
            ...nonTrustEntitySharedDefaults,
            gstStatus: GST_NON_EXEMPT,
            nonTrustEntityTaxStatus: NON_TAXABLE_ENTITY,
            stateTax: defaultStateTaxSubformForm,
          };
        case 'daf':
        case 'private-foundation':
          return {
            ...nonTrustEntitySharedDefaults,
            gstStatus: '' as const,
            nonTrustEntityTaxStatus: NON_TAXABLE_ENTITY,
            stateTax: defaultStateTaxSubformForm,
          };

        default:
          throw new Error(`Unexpected entityType: ${entityType}`);
      }
    }
    case 'nonTrustEntityDefaultInEstate': {
      switch (entityType) {
        case 'individual-account':
        // Fall through to joint account
        case 'joint-account':
          return {
            taxStatus: '' as const,
            estateInclusionStatus: IN_ESTATE,
            gstStatus: '' as const, // gstStatus is not used for the `nonTrustEntityDefaultInEstate` variant
            nonTrustEntityTaxStatus: TAXABLE_ENTITY,
            stateTax: defaultStateTaxSubformForm,
          };

        default:
          throw new Error(`Unexpected entityType: ${entityType}`);
      }
    }
  }
}

export function getTaxStatusValuesFromEntityKind(entityKind: EntityKind) {
  const entityType = getEntityTypeFromEntityKind(entityKind);
  return getTaxStatusValuesFromEntityType(entityType);
}

export function getTaxStatusValuesFromEntityType(entityType: EntityType) {
  const variant = getTaxStatusVariant(entityType);
  return getTaxStatusDefaultValues(variant, entityType);
}

export function getTaxStatusAPIValuesFromEntityType(entityType: EntityType) {
  const formValues = getTaxStatusValuesFromEntityType(entityType);

  return {
    taxStatus: formValues.taxStatus
      ? trustTaxStatusToApiTypeMap[formValues.taxStatus]
      : null,
    nonTrustEntityTaxStatus: formValues.nonTrustEntityTaxStatus
      ? nonTrustEntityTaxStatusToApiTypeMap[formValues.nonTrustEntityTaxStatus]
      : null,
    gstStatus: formValues.gstStatus
      ? entityGstStatusToApiTypeMap[formValues.gstStatus]
      : null,
    inEstateStatus:
      estateInclusionStatusToApiTypeMap[formValues.estateInclusionStatus],
  };
}
