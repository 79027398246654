import { Callout } from '@/components/notifications/Callout/Callout';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { formatCurrency } from '@/utils/formatting/currency';

export function GiftingInfoCallout() {
  const { lifetimeExemptionAmount, gstExemptionAmount } = useIRSConstants();
  if (!(lifetimeExemptionAmount && gstExemptionAmount)) {
    return null;
  }

  // TIME_SENSITIVE_CONTENT
  return (
    <Callout severity="info-high" textVariant="subtitle2">
      Under current tax law, an estate valued over{' '}
      {formatCurrency(lifetimeExemptionAmount, {
        notation: 'compact',
        maximumFractionDigits: 2,
      })}{' '}
      (per person) is subject to federal estate tax. Additionally, gifts over{' '}
      {formatCurrency(gstExemptionAmount, {
        notation: 'compact',
        maximumFractionDigits: 2,
        style: 'currency',
      })}{' '}
      (per person) made to skip generations, such as grandchildren, are subject
      to generation-skipping transfer (&quot;GST&quot;) tax. The exemptions
      increase annually with inflation and are set to &quot;sunset&quot;, or be
      reduced to roughly half of the current exemption amounts, at the end of
      2025.
    </Callout>
  );
}
