import { GridRowOrderChangeParams } from '@mui/x-data-grid-pro';

import {
  AugmentedCreateBalanceSheetCategoryInput,
  AugmentedUpdateBalanceSheetCategoryInput,
  BalanceSheetCategoryType,
  UpdateBalanceSheetCategoryInput,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import {
  BalanceSheetCategoriesForm,
  NEW_BALANCE_SHEET_CATEGORY_SENTINEL,
  RowData,
} from './AdminBalanceSheetConfigurationPage.types';
import { AdminBalanceSheetConfigurationPage_BalanceSheetCategoryFragment } from './graphql/AdminBalanceSheetConfigurationPage.generated';

export interface MapFormValuesToInputsReturn {
  updates: AugmentedUpdateBalanceSheetCategoryInput[];
  clears: AugmentedUpdateBalanceSheetCategoryInput[];
  creates: AugmentedCreateBalanceSheetCategoryInput[];
}

interface MapFormValuesToInputsParams {
  reorderableRows: RowData[];
}

export function mapFormValuesToInputs(
  formValues: BalanceSheetCategoriesForm,
  { reorderableRows }: MapFormValuesToInputsParams
): MapFormValuesToInputsReturn {
  const updates: AugmentedUpdateBalanceSheetCategoryInput[] = [];
  const creates: AugmentedCreateBalanceSheetCategoryInput[] = [];
  const clears: AugmentedUpdateBalanceSheetCategoryInput[] = [];

  Object.entries(formValues.balanceSheetCategoriesById).forEach(
    ([id, category]) => {
      const { _isSystemClass, assetClassIds, name } = category;

      const associatedTableRow = reorderableRows.find((row) => row.id === id);

      if (!associatedTableRow) {
        // this is a deleted row
        return;
      }

      // Generate clear operation for existing balance sheet categories
      if (!id.startsWith(NEW_BALANCE_SHEET_CATEGORY_SENTINEL)) {
        clears.push({
          id,
          update: {
            clearAssetClasses: true,
          },
        });
      }

      const sortOrder = reorderableRows.findIndex((row) => row.id === id);

      if (id.startsWith(NEW_BALANCE_SHEET_CATEGORY_SENTINEL)) {
        const create: AugmentedCreateBalanceSheetCategoryInput = {
          create: {
            assetClassIDs: assetClassIds,
            displayName: name,
            sortOrder,
            type: BalanceSheetCategoryType.Asset, // Assuming new categories are always assets
          },
        };

        creates.push(create);
      } else {
        const update: UpdateBalanceSheetCategoryInput = {
          addAssetClassIDs:
            assetClassIds.length > 0 ? assetClassIds : undefined,
          displayName: _isSystemClass ? null : name,
          sortOrder,
        };

        // Only include non-undefined fields
        const filteredUpdate = Object.fromEntries(
          Object.entries(update).filter(([_, v]) => v !== undefined)
        );

        if (Object.keys(filteredUpdate).length > 0) {
          updates.push({
            id,
            update: filteredUpdate as UpdateBalanceSheetCategoryInput,
          });
        }
      }
    }
  );

  return { updates, creates, clears };
}

export function getDefaultValues(
  categories: AdminBalanceSheetConfigurationPage_BalanceSheetCategoryFragment[]
): BalanceSheetCategoriesForm {
  const balanceSheetCategoriesById: BalanceSheetCategoriesForm['balanceSheetCategoriesById'] =
    {};

  categories.forEach((category) => {
    balanceSheetCategoriesById[category.id] = {
      id: category.id,
      _isSystemClass: category.isSystemCategory,
      name: category.displayName,
      assetClassIds: getNodes(category.assetClasses).map(
        (assetClass) => assetClass.id
      ),
    };
  });

  return {
    balanceSheetCategoriesById,
    currentlyEditingRowId: null,
  };
}

export function getNextRowOrder(
  currentRows: RowData[],
  params: GridRowOrderChangeParams
): RowData[] {
  const { oldIndex, targetIndex, row } = params;
  const updatedRows = [...currentRows];
  const [removed] = updatedRows.splice(oldIndex, 1);

  if (!removed) {
    const msg = 'Unexpected state: attempting to reorder a nonexistent row';
    diagnostics.error(msg, new Error(msg), {
      row: JSON.stringify(row),
      oldIndex,
      targetIndex,
    });
    return currentRows;
  }

  updatedRows.splice(targetIndex, 0, removed);
  return updatedRows;
}

export function mapDataToRows(
  categories: AdminBalanceSheetConfigurationPage_BalanceSheetCategoryFragment[]
): RowData[] {
  if (!categories) return [];

  return categories.map((category) => ({
    __reorder__: category.displayName,
    id: category.id,
    name: category.displayName,
    assetClasses: getNodes(category.assetClasses).map(
      (assetClass) => assetClass.displayName
    ),
    action: null,
    isSystemClass: category.isSystemCategory,
    type: category.type,
  }));
}
