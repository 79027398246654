import { useMemo } from 'react';

import { Loader } from '@/components/progress/Loader/Loader';

import { DispositiveProvisionsListViewProps } from '../DispositiveProvisionsListView';

export function useDispositiveProvisionsListViewSlots(
  externalSlots: DispositiveProvisionsListViewProps['slots']
): Required<NonNullable<DispositiveProvisionsListViewProps['slots']>> {
  const slots = useMemo(() => {
    return {
      Loader: externalSlots?.Loader ?? Loader,
    };
  }, [externalSlots?.Loader]);

  return slots;
}
