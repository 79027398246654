import { Box, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import React from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';

import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Asset } from '@/modules/assets/types/asset';
import { AssetValueV2OwnershipType } from '@/types/schema';
import { getComparableDecimalJS } from '@/utils/decimalJSUtils';
import { formatCurrency } from '@/utils/formatting/currency';

import { GRATStructuringForm, ReturnProjectionType } from '../constants';

interface RateOfReturnInputProps {
  control: Control<GRATStructuringForm>;
  required?: boolean;
}

function RateOfReturn({ control, required = true }: RateOfReturnInputProps) {
  return (
    <Box>
      <FormLayoutRow>
        <FormLayoutItem>
          <Typography variant="body2">
            Specify an annual projected rate of return for the assets held in
            this GRAT.
          </Typography>
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem width={6}>
          <FormAwarePercentInput<GRATStructuringForm>
            control={control}
            label="Rate of return"
            fieldName="assets.projectedRateOfReturn"
            isDecimalJSInput
            allowNegative
            fixedDecimalScale={true}
            decimalScale={1}
            maxValue={10000}
            required={required}
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </Box>
  );
}

export interface ReturnProjectionCardProps {
  control: Control<GRATStructuringForm>;
  setValue: UseFormSetValue<GRATStructuringForm>;
  returnProjectionType: ReturnProjectionType | null;
  projectedSharePrice: Decimal | null;
  projectedMarketValue: Decimal | null;
  assets: Asset[];
}

type ReturnProjectionEntryPattern =
  | 'PROJECTED_MARKET_VALUE'
  | 'PROJECTED_SHARE_PRICE';

export function ReturnProjectionCard({
  control,
  assets,
  returnProjectionType,
  setValue,
  projectedMarketValue,
  projectedSharePrice,
}: ReturnProjectionCardProps) {
  const [returnProjectionEntryPattern, setReturnProjectionEntryPattern] =
    React.useState<ReturnProjectionEntryPattern>('PROJECTED_MARKET_VALUE');

  // this is the logic to automatically derive projectedSharePrice from projectedMarketValue
  // and vice versa. this functionality is only available under specific conditions.
  React.useEffect(
    () => {
      if (
        assets.length !== 1 ||
        (assets[0]?.valuationMethod as AssetValueV2OwnershipType) !==
          AssetValueV2OwnershipType.ShareBased
      ) {
        return;
      }
      if (
        returnProjectionEntryPattern === 'PROJECTED_MARKET_VALUE' &&
        projectedMarketValue &&
        assets[0]?.shareCount
      ) {
        const calculatedProjectedSharePrice = projectedMarketValue.dividedBy(
          assets[0].shareCount
        );
        setValue('assets.projectedSharePrice', calculatedProjectedSharePrice);
      } else if (
        returnProjectionEntryPattern === 'PROJECTED_SHARE_PRICE' &&
        projectedSharePrice &&
        assets[0]?.shareCount
      ) {
        const calculatedProjectedMarketValue = projectedSharePrice.times(
          assets[0]?.shareCount
        );
        setValue('assets.projectedMarketValue', calculatedProjectedMarketValue);
      }
    },
    // disabling the exhaustive deps because doing comparisons on complex objects causes the hook to incorrectly rerender,
    // and the lint rule isn't smart enough to be able to understand this via static analysis
    /* eslint-disable react-hooks/exhaustive-deps */
    [
      getComparableDecimalJS(projectedMarketValue),
      getComparableDecimalJS(projectedSharePrice),
      assets,
      returnProjectionEntryPattern,
      setValue,
    ] /* eslint-enable react-hooks/exhaustive-deps */
  );

  if (assets.length === 0) return null;

  // to simplify projections, we're only supporting annual rate of return for multi-asset scenarios.
  // it would seem that we could also support a top-level market value input here as well, but we
  // can figure that out later.
  if (assets.length > 1) {
    return (
      <Card variant="filled" sx={{ p: 3 }}>
        <RateOfReturn control={control} />
      </Card>
    );
  }

  // we support the most robust scenario modeling for single-asset scenarios because we can make lots of assumptions.
  const assetToModel = assets[0];
  return (
    <Card variant="filled" sx={{ p: 3 }}>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareButtonGroup<GRATStructuringForm>
            fieldName="assets.returnProjectionType"
            label=""
            variant="light"
            control={control}
            options={[
              {
                display: 'Rate of return',
                value: ReturnProjectionType.RATE_OF_RETURN,
              },
              {
                display: 'Projected value',
                value: ReturnProjectionType.PROJECTED_VALUE,
              },
            ]}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {returnProjectionType === ReturnProjectionType.RATE_OF_RETURN && (
        <RateOfReturn control={control} />
      )}
      {returnProjectionType === ReturnProjectionType.PROJECTED_VALUE && (
        <Box>
          <FormLayoutRow>
            <FormLayoutItem>
              <Typography variant="body2">
                Specify the projected value of this asset at the end of the GRAT
                term or rolling period. Assumes linear growth to projected
                value.
              </Typography>
            </FormLayoutItem>
          </FormLayoutRow>
          {(assetToModel?.valuationMethod as AssetValueV2OwnershipType) ===
            AssetValueV2OwnershipType.ShareBased && (
            <FormLayoutRow>
              <FormLayoutItem>
                <FormAwareCurrencyInput<GRATStructuringForm>
                  control={control}
                  label="Projected share price"
                  fieldName="assets.projectedSharePrice"
                  isDecimalJSInput
                  onFocus={() =>
                    setReturnProjectionEntryPattern('PROJECTED_SHARE_PRICE')
                  }
                  helpText={`Starting share price: ${formatCurrency(
                    assetToModel?.sharePrice || new Decimal(0),
                    {
                      // we allow 3 decimal places of precision for share prices, so permit up to that here.
                      maximumFractionDigits:
                        assetToModel?.sharePrice?.decimalPlaces() || 2,
                    }
                  )}`}
                />
              </FormLayoutItem>
            </FormLayoutRow>
          )}

          <FormLayoutRow>
            <FormLayoutItem>
              <FormAwareCurrencyInput<GRATStructuringForm>
                isDecimalJSInput
                control={control}
                required={true}
                onFocus={() =>
                  setReturnProjectionEntryPattern('PROJECTED_MARKET_VALUE')
                }
                label="Projected market value"
                fieldName="assets.projectedMarketValue"
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </Box>
      )}
    </Card>
  );
}
