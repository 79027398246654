import Decimal from 'decimal.js';
import { compact, first } from 'lodash';

import { EstateWaterfall_NodeFragment } from '@/modules/estateWaterfall/graphql/EstateWaterfall.generated';
import { GraphNodeCategorizationType } from '@/modules/estateWaterfall/types';
import { getCategorizationType } from '@/modules/estateWaterfall/waterfallGraph/utils';
import { EstateTaxSummaryPanel_NodeFragment } from '@/modules/summaryPanels/EstateTaxSummaryPanel/graphql/EstateTaxSummaryPanel.generated';
import {
  getTaxableGiftsMade,
  getTotalGiftTaxDue,
} from '@/modules/summaryPanels/EstateTaxSummaryPanel/utils';
import {
  getFederalTaxFields,
  getStateTaxFields,
} from '@/modules/taxes/taxes.utils';
import {
  AfterDeath,
  DispositiveProvisionTransferTaxKind,
  EntityInEstateStatus,
  EstateWaterfallEdgeKind,
  EstateWaterfallHypotheticalTransferTransferTaxKind,
} from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { UnreachableError } from '@/utils/errors';
import { formatPercent } from '@/utils/formatting/percent';
import { getNodes } from '@/utils/graphqlUtils';

import {
  TaxOverviewTableEdgeFragment,
  TaxOverviewTableNodeFragment,
  TaxOverviewTableRowData,
  TaxOverviewTableRowParentCategory,
  TaxOverviewTableRowSubsection,
  TaxOverviewTableRowVariant,
  TaxOverviewTableWaterfallType,
} from './TaxOverviewTable.types';

export function getTaxOverviewTableRows({
  afterDeath,
  federalEstateTaxPercent,
  waterfall,
  isTwoGrantorHousehold,
  isMiniView,
}: {
  afterDeath: AfterDeath;
  federalEstateTaxPercent: Decimal | null | undefined;
  waterfall: TaxOverviewTableWaterfallType | null | undefined;
  isTwoGrantorHousehold: boolean;
  isMiniView?: boolean;
}): TaxOverviewTableRowData[] {
  if (!waterfall) return [];

  if (isMiniView) {
    switch (afterDeath) {
      case AfterDeath.None:
        return [
          ...getNetAssetsRows(waterfall, afterDeath, {
            hideOutOfEstateRows: true,
            title: 'Assets in estate',
            totalLabel: 'Total in estate',
          }),
        ];
      case AfterDeath.First:
        return [
          ...getNetAssetsRows(waterfall, afterDeath, {
            hideOutOfEstateRows: true,
            title: 'Assets in estate',
            totalLabel: 'Total in estate',
          }),
        ];
      case AfterDeath.Second:
        return [];
      default:
        throw new UnreachableError({
          case: afterDeath,
          message: `Invalid after death value: ${afterDeath}`,
        });
    }
  }

  switch (afterDeath) {
    case AfterDeath.None:
      return [
        ...getNetAssetsRows(waterfall, afterDeath, {
          hideOutOfEstateRows: true,
          id: 'top-row',
          title: 'Assets in estate',
          totalLabel: 'Total in estate',
        }),
        ...getTransfersRows(waterfall, afterDeath),
        ...getGiftTaxRows(waterfall, afterDeath, federalEstateTaxPercent),
        ...getNetAssetsRows(waterfall, afterDeath, {
          id: 'net-assets-summary',
          totalLabel: 'Total net assets',
        }),
        ...getRemainingExemptionRows(waterfall, afterDeath),
      ];
    case AfterDeath.First:
      return [
        ...getTaxesAndExpensesRows(
          waterfall,
          afterDeath,
          federalEstateTaxPercent
        ),
        ...getAfterTaxDistributionRows(waterfall, afterDeath, {
          hideMaritalTransfers: !isTwoGrantorHousehold,
        }),
        ...getNetAssetsRows(waterfall, afterDeath, {
          hideInEstateRows: !isTwoGrantorHousehold,
          totalLabel: 'Total net assets',
        }),
        ...getRemainingExemptionRows(waterfall, afterDeath),
      ];
    case AfterDeath.Second:
      return [
        ...getTaxesAndExpensesRows(
          waterfall,
          afterDeath,
          federalEstateTaxPercent
        ),
        ...getAfterTaxDistributionRows(waterfall, afterDeath, {
          hideMaritalTransfers: true,
        }),
        ...getNetAssetsRows(waterfall, afterDeath, {
          hideInEstateRows: true,
          totalLabel: 'Total net assets',
        }),
        ...getRemainingExemptionRows(waterfall, afterDeath),
      ];
    default:
      throw new UnreachableError({
        case: afterDeath,
        message: `Invalid after death value: ${afterDeath}`,
      });
  }
}

interface StatusGroups {
  inEstate: TaxOverviewTableNodeFragment[];
  outOfEstateFboFamily: TaxOverviewTableNodeFragment[];
  outOfEstateFboCharity: TaxOverviewTableNodeFragment[];
}

function sortNodesIntoInEstateStatusGroups(
  nodes: TaxOverviewTableNodeFragment[]
): StatusGroups {
  return nodes.reduce<StatusGroups>(
    (acc, node) => {
      if (node.inEstateStatus === EntityInEstateStatus.InEstate) {
        acc.inEstate.push(node);
        return acc;
      }

      const categorizationType = getCategorizationType(
        node as EstateWaterfall_NodeFragment
      );
      // if there's no categorization type, this node won't be in the waterfall
      if (!categorizationType) return acc;

      if (categorizationType === GraphNodeCategorizationType.CharitableEntity) {
        acc.outOfEstateFboCharity.push(node);
        return acc;
      }

      if (
        [
          GraphNodeCategorizationType.FamilyGiving,
          GraphNodeCategorizationType.PersonalAccount,
          GraphNodeCategorizationType.InsuranceAccount,
        ].includes(categorizationType)
      ) {
        acc.outOfEstateFboFamily.push(node);
        return acc;
      }

      return acc;
    },
    {
      inEstate: [],
      outOfEstateFboFamily: [],
      outOfEstateFboCharity: [],
    }
  );
}

function getMatchedNode(
  node: EstateTaxSummaryPanel_NodeFragment,
  list: EstateTaxSummaryPanel_NodeFragment[]
): EstateTaxSummaryPanel_NodeFragment | undefined {
  return list.find(({ id }) => id === node.id);
}

function getTransfersRows(
  waterfall: TaxOverviewTableWaterfallType,
  afterDeath: AfterDeath
): TaxOverviewTableRowData[] {
  if (afterDeath !== AfterDeath.None) {
    return [];
  }

  let nonTaxableTransfers = new Decimal(0);
  let taxableTransfers = new Decimal(0);

  // the no-death scenario only looks at hypothetical transfers
  getNodes(waterfall.hypotheticalTransfers).forEach((transfer) => {
    if (
      transfer.transferTaxKind ===
      EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift
    ) {
      taxableTransfers = taxableTransfers.plus(transfer.transferValue ?? 0);
    } else {
      nonTaxableTransfers = nonTaxableTransfers.plus(
        transfer.transferValue ?? 0
      );
    }
  });

  return [
    buildSubtitleRow({
      lineOne:
        afterDeath === AfterDeath.None
          ? 'Transfers during life'
          : 'Transfers upon death',
      parentCategory: TaxOverviewTableRowParentCategory.Transfers,
      afterDeath,
      subsection: TaxOverviewTableRowSubsection.TransfersDuringLife,
    }),
    buildRow({
      lineOne: 'Non-taxable (charity, marital)',
      projectedValue: nonTaxableTransfers,
      parentCategory: TaxOverviewTableRowParentCategory.Transfers,
      path: ['non-taxable-transfers'],
      afterDeath,
      subsection: TaxOverviewTableRowSubsection.TransfersDuringLife,
    }),
    buildRow({
      lineOne: 'Taxable (credit shelter, transfers to out of estate)',
      projectedValue: taxableTransfers,
      parentCategory: TaxOverviewTableRowParentCategory.Transfers,
      path: ['taxable-transfers'],
      afterDeath,
      subsection: TaxOverviewTableRowSubsection.TransfersDuringLife,
    }),
    buildSubtotalRow({
      lineOne: 'Total transfers',
      projectedValue: taxableTransfers.plus(nonTaxableTransfers),
      noDeathValue: undefined,
      parentCategory: TaxOverviewTableRowParentCategory.Transfers,
      afterDeath,
      subsection: TaxOverviewTableRowSubsection.TransfersDuringLife,
    }),
  ];
}

function getGiftTaxRows(
  taxWaterfall: TaxOverviewTableWaterfallType,
  afterDeath: AfterDeath,
  federalEstateTaxPercent: Decimal | null | undefined
): TaxOverviewTableRowData[] {
  const lifetimeExemptionAppliedTowardsGiftTax =
    taxWaterfall.visualizationWithProjections
      ?.lifetimeExemptionAppliedTowardsGiftTax;

  const nodes: EstateTaxSummaryPanel_NodeFragment[] =
    taxWaterfall.visualizationWithProjections.nodes?.filter(
      (node) => node.afterDeath === afterDeath
    ) || [];

  const giftTax = getTotalGiftTaxDue(nodes);

  // this will be undefined if on the first or second death scenarios
  // but should always be defined on the no-death scenario
  let afterFirstDeathTaxNodes:
    | EstateTaxSummaryPanel_NodeFragment[]
    | undefined = undefined;
  if (afterDeath === AfterDeath.None) {
    afterFirstDeathTaxNodes =
      taxWaterfall.visualizationWithProjections.nodes?.filter(
        (node) => node.afterDeath === AfterDeath.First
      );
  }

  // this will be undefined if on the first or second death scenarios
  // but should always be defined on the no-death scenario
  const firstDeathGiftTax = afterFirstDeathTaxNodes
    ? getTotalGiftTaxDue(afterFirstDeathTaxNodes) || new Decimal(0)
    : undefined;

  const taxableGiftsMade = getTaxableGiftsMade(
    giftTax,
    federalEstateTaxPercent,
    lifetimeExemptionAppliedTowardsGiftTax
  );

  // this will be undefined if on the first or second death scenarios
  // but should always be defined on the no-death scenario
  const firstDeathTaxableGiftsMade = firstDeathGiftTax
    ? getTaxableGiftsMade(
        firstDeathGiftTax,
        federalEstateTaxPercent,
        lifetimeExemptionAppliedTowardsGiftTax
      ) ?? new Decimal(0)
    : undefined;

  const amountSubjectToTax = taxableGiftsMade.minus(
    lifetimeExemptionAppliedTowardsGiftTax ?? new Decimal(0)
  );

  // this will be undefined if on the first or second death scenarios
  // but should always be defined on the no-death scenario
  const firstDeathAmountSubjectToTax = firstDeathTaxableGiftsMade
    ? firstDeathTaxableGiftsMade.minus(
        lifetimeExemptionAppliedTowardsGiftTax ?? new Decimal(0)
      )
    : undefined;

  return [
    buildSubtitleRow({
      lineOne: 'Gift taxes',
      parentCategory: TaxOverviewTableRowParentCategory.GiftTaxes,
      afterDeath,
      subsection: TaxOverviewTableRowSubsection.GiftTaxes,
    }),
    buildRow({
      lineOne: 'Taxable gifts made',
      noDeathValue: taxableGiftsMade,
      projectedValue: firstDeathTaxableGiftsMade,
      parentCategory: TaxOverviewTableRowParentCategory.GiftTaxes,
      path: ['taxable-gifts-made'],
      afterDeath,
      subsection: TaxOverviewTableRowSubsection.GiftTaxes,
    }),
    buildRow({
      lineOne: 'Lifetime exemption used',
      noDeathValue:
        taxWaterfall.visualization.lifetimeExemptionAppliedTowardsGiftTax,
      projectedValue:
        taxWaterfall.visualizationWithProjections
          .lifetimeExemptionAppliedTowardsGiftTax,
      parentCategory: TaxOverviewTableRowParentCategory.GiftTaxes,
      path: ['gift-tax'],
      afterDeath,
      subsection: TaxOverviewTableRowSubsection.GiftTaxes,
    }),
    buildRow({
      lineOne: 'Amount subject to tax',
      noDeathValue: amountSubjectToTax,
      projectedValue: firstDeathAmountSubjectToTax,
      parentCategory: TaxOverviewTableRowParentCategory.GiftTaxes,
      path: ['amount-subject-to-tax'],
      afterDeath,
      subsection: TaxOverviewTableRowSubsection.GiftTaxes,
    }),
    buildSubtotalRow({
      lineOne: 'Total gift taxes',
      noDeathValue: giftTax.abs().negated(),
      projectedValue: firstDeathGiftTax?.abs().negated(),
      parentCategory: TaxOverviewTableRowParentCategory.GiftTaxes,
      afterDeath,
      subsection: TaxOverviewTableRowSubsection.GiftTaxes,
    }),
  ];
}

function getNetAssetsRows(
  waterfall: TaxOverviewTableWaterfallType,
  afterDeath: AfterDeath,
  {
    hideInEstateRows,
    hideOutOfEstateRows,
    id,
    title,
    totalLabel,
  }: {
    hideInEstateRows?: boolean;
    hideOutOfEstateRows?: boolean;
    id?: string;
    title?: string;
    totalLabel: string;
  }
): TaxOverviewTableRowData[] {
  const withProjectionNodes: TaxOverviewTableNodeFragment[] =
    waterfall.visualizationWithProjections.nodes.filter(
      (node) => node.afterDeath === afterDeath
    );

  let noProjectionNodes: TaxOverviewTableNodeFragment[] = [];

  if (afterDeath === AfterDeath.None) {
    // these checks are necessary because of the two ways the overview can be displayed
    // case 'visualizationNoProjections': waterfall is an EstateTaxSummaryPanel_EstateWaterfallFragment
    // case 'visualization': waterfall is a GetWaterfallSummary_EstateWaterfallFragment
    noProjectionNodes = waterfall.visualization.nodes.filter(
      (node) => node.afterDeath === afterDeath
    );
  }

  const {
    inEstate: withProjectionInEstateNodes,
    outOfEstateFboCharity: withProjectionOutOfEstateFboCharity,
    outOfEstateFboFamily: withProjectionOutOfEstateFboFamily,
  } = sortNodesIntoInEstateStatusGroups(withProjectionNodes);
  const {
    inEstate: noProjectionInEstateNodes,
    outOfEstateFboCharity: noProjectionOutOfEstateFboCharity,
    outOfEstateFboFamily: noProjectionOutOfEstateFboFamily,
  } = sortNodesIntoInEstateStatusGroups(noProjectionNodes);

  let netAssetsLabel = `In estate of ${waterfall.household?.displayName}`;
  if (afterDeath === AfterDeath.First) {
    const livingGrantorName = waterfall.household?.possiblePrimaryClients.find(
      (client) => client.id !== waterfall.firstGrantorDeath?.id
    )?.displayName;
    if (livingGrantorName) {
      netAssetsLabel = `In estate of ${livingGrantorName}`;
    } else if (waterfall.household?.possiblePrimaryClients.length === 2) {
      netAssetsLabel = `In estate after first death`;
    } else {
      netAssetsLabel = `In estate after death`;
    }
  }

  return [
    buildSubtitleRow({
      lineOne: title ?? 'Net assets',
      parentCategory: TaxOverviewTableRowParentCategory.NetAssets,
      afterDeath,
      id,
      subsection: TaxOverviewTableRowSubsection.NetAssets,
    }),
    ...(hideInEstateRows
      ? []
      : [
          buildCategoryLabelRow({
            lineOne: netAssetsLabel,
            parentCategory: TaxOverviewTableRowParentCategory.NetAssetsInEstate,
            afterDeath,
            projectedValue: sumDecimalJS(
              withProjectionInEstateNodes.map(({ value }) => value)
            ),
            noDeathValue: sumDecimalJS(
              noProjectionInEstateNodes.map(({ value }) => value)
            ),
            id,
            subsection: TaxOverviewTableRowSubsection.NetAssets,
          }),
          ...withProjectionInEstateNodes.map((node) =>
            buildNodeRow(node, {
              parentCategory:
                TaxOverviewTableRowParentCategory.NetAssetsInEstate,
              afterDeath,
              noDeathValue: getMatchedNode(node, noProjectionInEstateNodes)
                ?.value,
              id,
              subsection: TaxOverviewTableRowSubsection.NetAssets,
            })
          ),
        ]),
    ...(hideOutOfEstateRows
      ? []
      : [
          buildCategoryLabelRow({
            lineOne: 'Out of estate fbo family',
            parentCategory:
              TaxOverviewTableRowParentCategory.NetAssetsOutOfEstateFboFamily,
            afterDeath,
            projectedValue: sumDecimalJS(
              withProjectionOutOfEstateFboFamily.map(({ value }) => value)
            ),
            noDeathValue: sumDecimalJS(
              noProjectionOutOfEstateFboFamily.map(({ value }) => value)
            ),
            id,
            subsection: TaxOverviewTableRowSubsection.NetAssets,
          }),
          ...withProjectionOutOfEstateFboFamily.map((node) =>
            buildNodeRow(node, {
              parentCategory:
                TaxOverviewTableRowParentCategory.NetAssetsOutOfEstateFboFamily,
              afterDeath,
              noDeathValue: getMatchedNode(
                node,
                noProjectionOutOfEstateFboFamily
              )?.value,
              id,
              subsection: TaxOverviewTableRowSubsection.NetAssets,
            })
          ),
          buildCategoryLabelRow({
            lineOne: 'Out of estate fbo charity',
            parentCategory:
              TaxOverviewTableRowParentCategory.NetAssetsOutOfEstateFboCharity,
            afterDeath,
            projectedValue: sumDecimalJS(
              withProjectionOutOfEstateFboCharity.map(({ value }) => value)
            ),
            noDeathValue: sumDecimalJS(
              noProjectionOutOfEstateFboCharity.map(({ value }) => value)
            ),
            id,
            subsection: TaxOverviewTableRowSubsection.NetAssets,
          }),
          ...withProjectionOutOfEstateFboCharity.map((node) =>
            buildNodeRow(node, {
              parentCategory:
                TaxOverviewTableRowParentCategory.NetAssetsOutOfEstateFboCharity,
              afterDeath,
              noDeathValue: getMatchedNode(
                node,
                noProjectionOutOfEstateFboCharity
              )?.value,
              id,
              subsection: TaxOverviewTableRowSubsection.NetAssets,
            })
          ),
        ]),
    buildSubtotalRow({
      lineOne: totalLabel,
      afterDeath,
      projectedValue: sumDecimalJS(
        [
          ...(hideInEstateRows ? [] : withProjectionInEstateNodes),
          ...(hideOutOfEstateRows ? [] : withProjectionOutOfEstateFboCharity),
          ...(hideOutOfEstateRows ? [] : withProjectionOutOfEstateFboFamily),
        ].map(({ value }) => value)
      ),
      noDeathValue: sumDecimalJS(
        [
          ...(hideInEstateRows ? [] : noProjectionInEstateNodes),
          ...(hideOutOfEstateRows ? [] : noProjectionOutOfEstateFboCharity),
          ...(hideOutOfEstateRows ? [] : noProjectionOutOfEstateFboFamily),
        ].map(({ value }) => value)
      ),
      parentCategory: TaxOverviewTableRowParentCategory.NetAssets,
      id,
      subsection: TaxOverviewTableRowSubsection.NetAssets,
    }),
  ];
}

function getRemainingExemptionRows(
  waterfall: TaxOverviewTableWaterfallType,
  afterDeath: AfterDeath
): TaxOverviewTableRowData[] {
  switch (afterDeath) {
    case AfterDeath.None: {
      const clients =
        waterfall.household?.possiblePrimaryClients.map((client) => ({
          id: client.id,
          remainingLifetimeExclusion: client.remainingLifetimeExclusion,
          name: client.displayName,
        })) || [];

      return [
        buildSubtitleRow({
          lineOne: 'Remaining exemption',
          parentCategory: TaxOverviewTableRowParentCategory.RemainingExemption,
          afterDeath,
          subsection: TaxOverviewTableRowSubsection.RemainingExemption,
        }),
        buildRow({
          lineOne: 'Lifetime exemption',
          parentCategory: TaxOverviewTableRowParentCategory.RemainingExemption,
          afterDeath,
          projectedValue:
            waterfall.visualizationWithProjections
              .firstDeathRemainingLifetimeExemption,
          noDeathValue: sumDecimalJS(
            compact(
              clients.map(
                ({ remainingLifetimeExclusion }) => remainingLifetimeExclusion
              )
            )
          ),
          path: [TaxOverviewTableRowParentCategory.RemainingExemption],
          subsection: TaxOverviewTableRowSubsection.RemainingExemption,
        }),
      ];
    }
    case AfterDeath.First:
    case AfterDeath.Second: {
      const viz = waterfall.visualizationWithProjections || {};
      return [
        buildSubtitleRow({
          lineOne: 'Remaining exemption',
          parentCategory: TaxOverviewTableRowParentCategory.RemainingExemption,
          afterDeath,
          subsection: TaxOverviewTableRowSubsection.RemainingExemption,
        }),
        buildRow({
          afterDeath,
          lineOne: 'Remaining exemption',
          boldNameOverride: true,
          parentCategory: TaxOverviewTableRowParentCategory.RemainingExemption,
          projectedValue:
            afterDeath === AfterDeath.First
              ? viz.firstDeathTaxSummary.federalTax?.exemptionAvailable
              : viz.secondDeathTaxSummary?.federalTax?.exemptionAvailable,
          path: [TaxOverviewTableRowParentCategory.RemainingExemption],
          subsection: TaxOverviewTableRowSubsection.RemainingExemption,
        }),
      ];
    }
    default: {
      throw new UnreachableError({
        case: afterDeath,
        message: 'Invalid after death value',
      });
    }
  }
}

function getTaxesAndExpensesRows(
  waterfall: TaxOverviewTableWaterfallType,
  afterDeath: AfterDeath,
  federalEstateTaxPercent: Decimal | null | undefined
): TaxOverviewTableRowData[] {
  if (afterDeath === AfterDeath.None) return [];

  const taxSummary =
    afterDeath === AfterDeath.First
      ? waterfall.visualizationWithProjections.firstDeathTaxSummary
      : waterfall.visualizationWithProjections.secondDeathTaxSummary;

  if (!taxSummary) return [];

  let federalEstateTaxRows: TaxOverviewTableRowData[] = [];
  let stateEstateTaxRows: TaxOverviewTableRowData[] = [];

  let totalTaxableAmount = new Decimal(0);

  if (taxSummary.stateTax?.length) {
    taxSummary.stateTax.forEach((stateTax, index) => {
      const {
        stateName,
        stateTaxDue,
        taxableEstate,
        exemptionUsed,
        amountSubjectToTax,
      } = getStateTaxFields(stateTax);

      const stateCode: string = stateName || index.toString();

      const basePath = [
        `${TaxOverviewTableRowParentCategory.TaxesAndExpenses}-${stateCode}`,
      ];

      totalTaxableAmount = totalTaxableAmount.plus(stateTaxDue);
      stateEstateTaxRows = stateEstateTaxRows.concat([
        buildCategoryLabelRow({
          lineOne: `${stateName} estate tax`,
          parentCategory: TaxOverviewTableRowParentCategory.TaxesAndExpenses,
          afterDeath,
          projectedValue: stateTaxDue.abs().times(-1),
          path: basePath,
          id: `state-${stateCode}-tax`,
          displayAsNegative: true,
          subsection: TaxOverviewTableRowSubsection.TaxesAndExpenses,
        }),
        buildRow({
          lineOne: 'Taxable estate',
          parentCategory: TaxOverviewTableRowParentCategory.TaxesAndExpenses,
          path: [...basePath, 'taxable-estate'],
          afterDeath,
          projectedValue: taxableEstate,
          id: `state-${stateCode}-taxable-estate`,
          subsection: TaxOverviewTableRowSubsection.TaxesAndExpenses,
        }),
        buildRow({
          lineOne: 'State exemption used',
          parentCategory: TaxOverviewTableRowParentCategory.TaxesAndExpenses,
          path: [...basePath, 'state-exemption-used'],
          afterDeath,
          projectedValue: exemptionUsed,
          id: `state-${stateCode}-state-exemption-used`,
          subsection: TaxOverviewTableRowSubsection.TaxesAndExpenses,
        }),
        buildRow({
          lineOne: 'Amount subject to tax',
          parentCategory: TaxOverviewTableRowParentCategory.TaxesAndExpenses,
          path: [...basePath, 'amount-subject-to-tax'],
          afterDeath,
          projectedValue: amountSubjectToTax,
          id: `state-${stateCode}-amount-subject-to-tax`,
          subsection: TaxOverviewTableRowSubsection.TaxesAndExpenses,
        }),
      ]);
    });
  }

  if (taxSummary.federalTax) {
    const {
      taxableEstate,
      stateTaxDeduction,
      lifetimeExemptionUsed,
      amountSubjectToTax,
      federalTaxDue,
    } = getFederalTaxFields(taxSummary.federalTax);

    totalTaxableAmount = totalTaxableAmount.plus(federalTaxDue);

    const basePath = [
      `${TaxOverviewTableRowParentCategory.TaxesAndExpenses}-federal`,
    ];

    federalEstateTaxRows = [
      buildCategoryLabelRow({
        lineOne: 'Federal estate tax',
        parentCategory: TaxOverviewTableRowParentCategory.TaxesAndExpenses,
        afterDeath,
        projectedValue: federalTaxDue.abs().times(-1),
        path: basePath,
        displayAsNegative: true,
        subsection: TaxOverviewTableRowSubsection.TaxesAndExpenses,
      }),
      buildRow({
        lineOne: 'Taxable estate',
        parentCategory: TaxOverviewTableRowParentCategory.TaxesAndExpenses,
        path: [...basePath, 'taxable-estate'],
        afterDeath,
        projectedValue: taxableEstate,
        id: 'federal-taxable-estate',
        subsection: TaxOverviewTableRowSubsection.TaxesAndExpenses,
      }),
      buildRow({
        lineOne: 'State tax deduction',
        parentCategory: TaxOverviewTableRowParentCategory.TaxesAndExpenses,
        path: [...basePath, 'state-tax-deduction'],
        afterDeath,
        projectedValue: stateTaxDeduction,
        id: 'federal-state-tax-deduction',
        subsection: TaxOverviewTableRowSubsection.TaxesAndExpenses,
      }),
      buildRow({
        lineOne: 'Lifetime exemption used',
        parentCategory: TaxOverviewTableRowParentCategory.TaxesAndExpenses,
        path: [...basePath, 'lifetime-exemption-used'],
        afterDeath,
        projectedValue: lifetimeExemptionUsed.abs().times(-1),
        id: 'federal-lifetime-exemption-used',
        subsection: TaxOverviewTableRowSubsection.TaxesAndExpenses,
      }),
      buildRow({
        lineOne: `Amount subject to tax ${federalEstateTaxPercent ? `(${formatPercent(federalEstateTaxPercent, 0)}%)` : ''}`,
        parentCategory: TaxOverviewTableRowParentCategory.TaxesAndExpenses,
        afterDeath,
        projectedValue: amountSubjectToTax,
        path: [...basePath, 'amount-subject-to-tax'],
        id: 'federal-amount-subject-to-tax',
        subsection: TaxOverviewTableRowSubsection.TaxesAndExpenses,
      }),
    ];
  }

  return [
    buildSubtitleRow({
      lineOne: 'Taxes and expenses',
      parentCategory: TaxOverviewTableRowParentCategory.TaxesAndExpenses,
      afterDeath,
      subsection: TaxOverviewTableRowSubsection.TaxesAndExpenses,
    }),
    ...stateEstateTaxRows,
    ...federalEstateTaxRows,
    buildSubtotalRow({
      lineOne: 'Total taxes and expenses',
      projectedValue: totalTaxableAmount.abs().times(-1),
      parentCategory: TaxOverviewTableRowParentCategory.TaxesAndExpenses,
      afterDeath,
      displayAsNegative: true,
      subsection: TaxOverviewTableRowSubsection.TaxesAndExpenses,
    }),
  ];
}

function getAfterTaxDistributionRows(
  waterfall: TaxOverviewTableWaterfallType,
  afterDeath: AfterDeath,
  opts?: {
    hideMaritalTransfers: boolean;
  }
): TaxOverviewTableRowData[] {
  const { hideMaritalTransfers } = opts || { hideMaritalTransfers: false };
  if (afterDeath === AfterDeath.None) return [];

  const relevantEdges: TaxOverviewTableEdgeFragment[] =
    waterfall.visualizationWithProjections.edges.filter(
      (edge) =>
        edge.to.afterDeath === afterDeath &&
        edge.kind === EstateWaterfallEdgeKind.Disposition
    );

  const { maritalTransfers, transfersToFamily, transfersToCharity } =
    relevantEdges.reduce<{
      maritalTransfers: TaxOverviewTableEdgeFragment[];
      transfersToFamily: TaxOverviewTableEdgeFragment[];
      transfersToCharity: TaxOverviewTableEdgeFragment[];
    }>(
      (acc, relevantEdge) => {
        const { dispositiveProvision, value } =
          first(compact(relevantEdge.associatedDispositiveProvisions)) || {};

        if (!dispositiveProvision || !value) {
          return acc;
        }

        switch (dispositiveProvision.transferTaxKind) {
          case DispositiveProvisionTransferTaxKind.SpouseCreditShelter:
          case DispositiveProvisionTransferTaxKind.SpouseFederalCreditShelterStateMaritalExclusion:
          case DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion:
            if (hideMaritalTransfers) break;
            acc.maritalTransfers.push(relevantEdge);
            break;
          case DispositiveProvisionTransferTaxKind.Generation_2OrOtherIndividual:
          case DispositiveProvisionTransferTaxKind.Generation_2Then_3:
          case DispositiveProvisionTransferTaxKind.Generation_3:
            acc.transfersToFamily.push(relevantEdge);
            break;
          case DispositiveProvisionTransferTaxKind.Charitable:
            acc.transfersToCharity.push(relevantEdge);
            break;
          case DispositiveProvisionTransferTaxKind.Pourover:
            // intentional no-op -- these won't be displayed because they can only happen before
            // first death, and this section isn't displayed for the none-death state
            break;
          default: {
            throw new UnreachableError({
              case: dispositiveProvision.transferTaxKind,
              message: 'Invalid dispositive provision transfer tax kind',
            });
          }
        }
        return acc;
      },
      {
        maritalTransfers: [],
        transfersToFamily: [],
        transfersToCharity: [],
      }
    );

  return [
    buildSubtitleRow({
      lineOne: 'After tax distributions',
      parentCategory: TaxOverviewTableRowParentCategory.AfterTaxDistribution,
      afterDeath,
      subsection: TaxOverviewTableRowSubsection.AfterTaxDistribution,
    }),
    ...(hideMaritalTransfers
      ? []
      : [
          buildCategoryLabelRow({
            lineOne: 'Marital transfers',
            parentCategory:
              TaxOverviewTableRowParentCategory.AfterTaxDistribution,
            afterDeath,
            projectedValue: sumDecimalJS(
              maritalTransfers.map(({ value }) => value)
            ),
            path: [
              `${TaxOverviewTableRowParentCategory.AfterTaxDistribution}-marital-transfers`,
            ],
            subsection: TaxOverviewTableRowSubsection.AfterTaxDistribution,
          }),
          ...maritalTransfers.map((edge) =>
            buildDispositiveProvisionFromEdgeRow(
              edge,
              `${TaxOverviewTableRowParentCategory.AfterTaxDistribution}-marital-transfers`,
              TaxOverviewTableRowSubsection.AfterTaxDistribution
            )
          ),
        ]),
    buildCategoryLabelRow({
      lineOne: 'Transfers to family',
      parentCategory: TaxOverviewTableRowParentCategory.AfterTaxDistribution,
      afterDeath,
      projectedValue: sumDecimalJS(transfersToFamily.map(({ value }) => value)),
      path: [
        `${TaxOverviewTableRowParentCategory.AfterTaxDistribution}-transfers-to-family`,
      ],
      subsection: TaxOverviewTableRowSubsection.AfterTaxDistribution,
    }),
    ...transfersToFamily.map((edge) =>
      buildDispositiveProvisionFromEdgeRow(
        edge,
        `${TaxOverviewTableRowParentCategory.AfterTaxDistribution}-transfers-to-family`,
        TaxOverviewTableRowSubsection.AfterTaxDistribution
      )
    ),
    buildCategoryLabelRow({
      lineOne: 'Transfers to charity',
      parentCategory: TaxOverviewTableRowParentCategory.AfterTaxDistribution,
      afterDeath,
      projectedValue: sumDecimalJS(
        transfersToCharity.map(({ value }) => value)
      ),
      path: [
        `${TaxOverviewTableRowParentCategory.AfterTaxDistribution}-transfers-to-charity`,
      ],
      subsection: TaxOverviewTableRowSubsection.AfterTaxDistribution,
    }),
    ...transfersToCharity.map((edge) =>
      buildDispositiveProvisionFromEdgeRow(
        edge,
        `${TaxOverviewTableRowParentCategory.AfterTaxDistribution}-transfers-to-charity`,
        TaxOverviewTableRowSubsection.AfterTaxDistribution
      )
    ),
    buildSubtotalRow({
      lineOne: 'Total after tax distributions',
      parentCategory: TaxOverviewTableRowParentCategory.AfterTaxDistribution,
      afterDeath,
      projectedValue: sumDecimalJS(
        compact(relevantEdges.map(getDispositiveProvisionValueForEdge))
      ),
      subsection: TaxOverviewTableRowSubsection.AfterTaxDistribution,
    }),
  ];
}

interface RowBuilderPayload {
  lineOne: string;
  lineTwo?: string | undefined;
  parentCategory: TaxOverviewTableRowParentCategory;
  afterDeath: AfterDeath;
  /**
   * If in the no-death scenario, this should be set to the value of the first-death scenario.
   * In all other scenarios, it should be set to the value of the current scenario.
   */
  projectedValue?: Decimal | undefined | null;
  /**
   * If in the no-death scenario, this should be set to the value for the no-death case.
   * In all other scenarios, it should be left null/undefined.
   */
  noDeathValue?: Decimal | undefined | null;
  path?: string[];
  id?: string;
  displayAsNegative?: boolean;
  /** If true, always display name as bold */
  boldNameOverride?: boolean;
  subsection: TaxOverviewTableRowSubsection;
}

function getNodeDisplayName(node: TaxOverviewTableNodeFragment): string {
  if (node.node.__typename === 'Entity') {
    return node.node.subtype.displayName;
  } else if (node.node.__typename === 'TestamentaryEntity') {
    return node.node.displayName;
  } else if (node.node.__typename === 'ClientProfile') {
    return node.node.displayName;
  } else if (node.node.__typename === 'ClientOrganization') {
    return node.node.name;
  }

  throw new Error(`Could not get display name for node: ${node.node.id}`);
}

function buildNodeRow(
  node: TaxOverviewTableNodeFragment,
  {
    parentCategory,
    afterDeath,
    noDeathValue,
    id,
    subsection,
  }: {
    parentCategory: TaxOverviewTableRowParentCategory;
    afterDeath: AfterDeath;
    noDeathValue?: Decimal | undefined;
    id?: string;
    subsection: TaxOverviewTableRowSubsection;
  }
): TaxOverviewTableRowData {
  const lineOne = getNodeDisplayName(node) || '';
  const lineTwo: string | undefined = undefined;

  return buildRow({
    id: `${id}-${afterDeath}-${parentCategory}-${node.id}`,
    lineOne,
    lineTwo,
    path: [`${id}-${parentCategory}`, node.id],
    projectedValue: node.value,
    noDeathValue,
    parentCategory,
    afterDeath,
    subsection,
  });
}

function getDispositiveProvisionValueForEdge(
  edge: TaxOverviewTableEdgeFragment
) {
  return first(compact(edge.associatedDispositiveProvisions))?.value;
}

function buildDispositiveProvisionFromEdgeRow(
  edge: TaxOverviewTableEdgeFragment,
  pathSection: string,
  subsection: TaxOverviewTableRowSubsection
): TaxOverviewTableRowData {
  return buildRow({
    lineOne: getNodeDisplayName(edge.to),
    lineTwo: `From ${getNodeDisplayName(edge.from)}`,
    projectedValue: getDispositiveProvisionValueForEdge(edge),
    parentCategory: TaxOverviewTableRowParentCategory.AfterTaxDistribution,
    afterDeath: edge.to.afterDeath,
    path: [pathSection, `${edge.from.id}-${edge.to.id}`],
    subsection,
  });
}

function buildRow({
  lineOne,
  lineTwo,
  parentCategory,
  afterDeath,
  projectedValue,
  noDeathValue,
  path,
  id,
  displayAsNegative,
  boldNameOverride,
  subsection,
}: RowBuilderPayload): TaxOverviewTableRowData {
  return {
    id: `${id}-${afterDeath}-${parentCategory}-${lineOne}-${lineTwo}-display`,
    name: {
      lineOne,
      lineTwo,
    },
    projectedValue,
    noDeathValue,
    path: path ?? [`${id}-${parentCategory}`, lineOne],
    hasChildren: false,
    displayAsNegative,
    boldNameOverride,
    subsection,
  };
}

/**
 * Returns a row with "Title" copy and displays values
 */
function buildSubtotalRow({
  lineOne,
  projectedValue,
  noDeathValue,
  parentCategory,
  afterDeath,
  id,
  displayAsNegative,
  subsection,
}: RowBuilderPayload): TaxOverviewTableRowData {
  return {
    id: `${id}-${afterDeath}-${parentCategory}-${lineOne}-subtotal`,
    name: {
      lineOne,
      lineTwo: undefined,
    },
    projectedValue,
    noDeathValue,
    path: [`${id}-${parentCategory}-subtotal`],
    hasChildren: false,
    variant: TaxOverviewTableRowVariant.Subtotal,
    displayAsNegative,
    subsection,
  };
}

function buildSubtitleRow({
  lineOne,
  parentCategory,
  afterDeath,
  id,
  subsection,
}: RowBuilderPayload): TaxOverviewTableRowData {
  return {
    id: `${id}-${afterDeath}-${parentCategory}-${lineOne}-subtitle`,
    name: {
      lineOne,
      lineTwo: undefined,
    },
    projectedValue: undefined,
    noDeathValue: undefined,
    hasChildren: false,
    path: [`${id}-${parentCategory}-subtitle`],
    variant: TaxOverviewTableRowVariant.Subtitle,
    subsection,
  };
}

/**
 * Builds a row that acts as a label for a category that can have children
 */
function buildCategoryLabelRow({
  lineOne,
  afterDeath,
  parentCategory,
  projectedValue,
  noDeathValue,
  id,
  path,
  displayAsNegative,
  subsection,
}: RowBuilderPayload): TaxOverviewTableRowData {
  return {
    id: `${id}-${afterDeath}-${parentCategory}-${lineOne}-category-label`,
    name: {
      lineOne,
      lineTwo: undefined,
    },
    projectedValue,
    noDeathValue,
    path: path ?? [`${id}-${parentCategory}`],
    hasChildren: true,
    displayAsNegative,
    variant: TaxOverviewTableRowVariant.CategoryLabel,
    subsection,
  };
}
