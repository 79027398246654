import { getFirstOrderConnections } from '@/components/diagrams/FlowChart/utils/nodes';

import { EntityMapActionFn, NodeMouseEnterAction } from '../types';

export const nodeMouseEnterAction: EntityMapActionFn<NodeMouseEnterAction> = (
  state,
  { node },
  _queueEffect
) => {
  const highlightedIds = getFirstOrderConnections(
    node,
    state.nodes,
    state.edges
  );

  return { ...state, highlightedIds };
};
