import { Box, Stack } from '@mui/material';
import AutoSizer from 'react-virtualized-auto-sizer';

import { useRequiredParam } from '@/hooks/useRequiredParam';
import { FamilyTree } from '@/modules/familyTree';

interface ClientFamilyTreeProps {
  householdId: string;
}

const MIN_HEIGHT = 720;

export const ClientFamilyTreeInner = ({
  householdId,
}: ClientFamilyTreeProps) => {
  return (
    <Stack flex={1}>
      <Box flex="1" minHeight={MIN_HEIGHT}>
        <AutoSizer>
          {({ height, width }: { height: number; width: number }) => (
            <FamilyTree
              householdId={householdId}
              height={Math.max(MIN_HEIGHT, height)}
              width={width}
              mode="edit"
            />
          )}
        </AutoSizer>
      </Box>
    </Stack>
  );
};

export function ClientFamilyTreePage() {
  const householdId = useRequiredParam('householdId');
  return <ClientFamilyTreeInner householdId={householdId} />;
}
