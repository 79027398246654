import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import {
  FamilyTreeDocument,
  FamilyTreeQuery,
  FamilyTreeQueryVariables,
} from '../graphql/FamilyTree.generated';
import { FamilyTreeState } from '../types';

export function useReadFamilyTreeCache() {
  const client = useApolloClient();

  return useCallback(
    (state: FamilyTreeState) => {
      return client.readQuery<FamilyTreeQuery, FamilyTreeQueryVariables>({
        query: FamilyTreeDocument,
        variables: {
          where: { hasHouseholdWith: [{ id: state.householdId }] },
        },
      });
    },
    [client]
  );
}
