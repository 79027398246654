import { Box, BoxProps, Stack } from '@mui/material';
import { chunk } from 'lodash';
import { ComponentType } from 'react';

import { HouseholdEstateValueMetrics } from '@/modules/household/HouseholdEstateValueMetrics/HouseholdEstateValueMetrics';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';

import { ClientPresentationStandaloneSlideType } from '../../clientPresentation/clientPresentation.types';
import { ClientPresentationDesigner_HouseholdFragment } from '../../clientPresentation/ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { useUnguardedClientPresentationDesignerContext } from '../../clientPresentation/contexts/clientPresentationDesigner.context';
import { PresentationSlideSecondaryHeading } from '../../components/PresentationSlideSecondaryHeading';
import { PRESENTATION_SPACING_UNITS } from '../../presentation.constants';
import { PresentationSlide } from '../../PresentationSlide';
import { EntitiesSummaryTable } from './EntitiesSummaryTable';
import { AllEntitiesSlide_EntityFragment } from './graphql/AllEntitiesSlide.generated';

export interface AllEntitiesSlideProps {
  entities: AllEntitiesSlide_EntityFragment[];
  client: ClientPresentationDesigner_HouseholdFragment;
  SlideWrapper?: ComponentType<BoxProps>;
}

export function AllEntitiesSlide({
  entities,
  client,
  SlideWrapper = Box,
}: AllEntitiesSlideProps) {
  const slideTitle = 'Entities';
  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  const slideId = 'slide_all_entities';

  useRegisterSlide({
    displayName: slideTitle,
    bundleId: '',
    id: 'slide_all_entities',
    identifier: ClientPresentationStandaloneSlideType.ENTITIES_LIST,
    index: 0,
  });

  if (!shouldShowItem(ClientPresentationStandaloneSlideType.ENTITIES_LIST)) {
    return null;
  }

  // 9 rows fit comfortable onto one page, except for the first one, which also has the estate summary metrics and so can have fewer pages.
  const firstEntityPageLength = 7;
  const firstPageEntities = entities.slice(0, firstEntityPageLength);
  const remainingEntitiesPages = chunk(
    entities.slice(firstEntityPageLength),
    9
  );
  const entitiesPages = [firstPageEntities, ...remainingEntitiesPages];

  return (
    <>
      {entitiesPages.map((pageEntities, index) => (
        <SlideWrapper key={index}>
          <PresentationSlide.Slide
            id={slideId}
            leftHeaderContent={
              <PresentationSlide.MainHeading heading={slideTitle} />
            }
            rightHeaderContent={
              <PresentationSlideSecondaryHeading
                clientDisplayName={client.displayName}
              />
            }
            footer={<PresentationSlide.Footer />}
          >
            <Stack
              spacing={3}
              justifyContent="flex-start"
              p={PRESENTATION_SPACING_UNITS}
              height="100%"
            >
              {index === 0 && (
                <HouseholdEstateValueMetrics
                  metrics={client.estateValueMetrics}
                />
              )}
              <EntitiesSummaryTable entities={pageEntities} />
            </Stack>
          </PresentationSlide.Slide>
        </SlideWrapper>
      ))}
    </>
  );
}
