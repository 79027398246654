import { isEmpty } from 'lodash';
import React from 'react';
import { isValidElement, ReactNode } from 'react';

export function isReactNode(x: unknown): x is ReactNode {
  return isValidElement(x);
}

export function compactReactChildren<T extends ReactNode>(
  children: T
): T | null {
  const childCount = React.Children.toArray(children).filter(
    (c) => !isEmpty(c)
  ).length;

  return childCount > 0 ? children : null;
}
