import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { DropdownButton } from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { SidePanel } from '@/components/modals/SidePanel';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useModalState } from '@/hooks/useModalState';
import { ClientProfileModal } from '@/modules/clientProfiles/ClientProfileForm/ClientProfileModal';
import { ClientProfileDetailsAwareRoute } from '@/modules/entities/contexts/clientProfileDetails/ClientProfileDetailsAwareRoute';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { EditButton } from '../components/EditButton';
import { SummaryPanelLoader } from '../components/SummaryPanelLoader';
import { getSummaryFields } from './ClientProfileSummaryPanel.utils';
import { useClientProfileSummaryPanelQuery } from './graphql/ClientProfileSummaryPanel.generated';
import {
  ClientProfileTabs,
  useClientProfilePanels,
} from './useClientProfilePanels';

export interface ClientProfileSummaryPanelProps {
  clientProfileId: string;
  householdId: string;
  afterDeath?: AfterDeath;
  isRecipientOfDistribution?: boolean;
  isRecipientOfExternalTransfer?: boolean;
  onClose: () => void;
  currentNodeValue?: Decimal;
  isOnSourceWaterfall?: boolean;
  isOnHypotheticalWaterfall?: boolean;
}

interface CurrentView {
  name: string;
  value: ClientProfileTabs;
}

export const ClientProfileSummaryPanelInner = ({
  clientProfileId,
  householdId,
  afterDeath,
  onClose,
  currentNodeValue,
  isOnSourceWaterfall = false,
  isOnHypotheticalWaterfall = false,
}: ClientProfileSummaryPanelProps) => {
  const { showFeedback } = useFeedback();
  const { primaryClients } = useHouseholdDetailsContext();
  const [{ isModalOpen }, { setModalIsOpen, closeModal, openModal }] =
    useModalState();

  const [currentView, setCurrentView] = useState<CurrentView>();

  const { data, loading } = useClientProfileSummaryPanelQuery({
    variables: { where: { id: clientProfileId } },
    onError: (error) => {
      showFeedback(
        `Couldn't get information. Please refresh the page and try again.`
      );
      diagnostics.error(`failed to fetch client profile`, error, {
        clientProfileId,
        householdId,
      });
    },
  });

  const cp = useMemo(() => getNodes(data?.clientProfiles)[0], [data]);
  const summaryFields = getSummaryFields(cp);

  // Whether or not this client profile node is a grantor and has direct ownership of businesses
  const isPrimaryClientNodeWithDirectOwnershipOfBusinesses = useMemo(() => {
    const primaryClientIds = new Set(primaryClients?.map((c) => c.id ?? []));
    const isPrimary = primaryClientIds.has(clientProfileId);

    if (!isPrimary) {
      return false;
    }

    const ownedOwnershipStakes = cp?.ownedOwnershipStakes ?? [];
    const hasDirectOwnershipOfBusinesses =
      (cp?.ownedOwnershipStakes?.length ?? 0) > 0;
    if (!hasDirectOwnershipOfBusinesses) {
      return false;
    }

    const ownershipSum = sumDecimalJS(
      ownedOwnershipStakes.map((s) => s.ownedValue)
    );

    let somethingHappenedToThisClientsBusinessEntities = false;
    if (currentNodeValue && !currentNodeValue.equals(ownershipSum)) {
      somethingHappenedToThisClientsBusinessEntities = true;
    }

    if (
      somethingHappenedToThisClientsBusinessEntities &&
      afterDeath !== AfterDeath.None
    ) {
      // It's really hard to say if the primary client node still has direct ownership of businesses
      // after the first primary client dies if this node's value is different than the sum of the
      // owned ownership stakes
      return false;
    }

    return true;
  }, [
    afterDeath,
    clientProfileId,
    cp?.ownedOwnershipStakes,
    currentNodeValue,
    primaryClients,
  ]);

  const panels = useClientProfilePanels({
    summaryFields,
    cp,
    currentNodeValue,
  });

  const selectItems = useMemo(() => {
    if (!panels?.length) {
      return null;
    }

    // For a grantor node, we only show their direct business holdings
    // in the context of a node used to communicated disposition of
    // those business holdings
    const showAssetsForPrimaryClient =
      isPrimaryClientNodeWithDirectOwnershipOfBusinesses;

    return compact(
      panels.map(({ name, value }) => {
        if (!name) {
          return null;
        }

        if (value === ClientProfileTabs.Assets && !showAssetsForPrimaryClient) {
          return null;
        }

        // Hide transfers tab when not on a hypothetical waterfall or hypothetical waterfall
        // summary view
        if (
          !isOnHypotheticalWaterfall &&
          value === ClientProfileTabs.Transfers
        ) {
          return null;
        }

        return {
          name,
          value,
          clickHandler: () => setCurrentView({ name, value }),
        };
      })
    );
  }, [
    isOnHypotheticalWaterfall,
    isPrimaryClientNodeWithDirectOwnershipOfBusinesses,
    panels,
  ]);

  const handleSetCurrentView = useCallback(
    (views: typeof selectItems) => {
      if (!views?.length) {
        return;
      }

      if (isOnHypotheticalWaterfall) {
        // Set the initial tab to the transfers tab
        // when on a hypothetical waterfall
        setCurrentView(
          views.find((v) => v.value === ClientProfileTabs.Transfers) ?? views[0]
        );
      } else if (isOnSourceWaterfall) {
        // Set the inital tab to the dispositions tab
        // when on a source waterfall
        setCurrentView(
          views.find((v) => v.value === ClientProfileTabs.Disposition) ??
            views[0]
        );
      } else {
        setCurrentView(views[0]);
      }
    },
    [isOnHypotheticalWaterfall, isOnSourceWaterfall]
  );

  useEffect(
    function handleSetCurrentViewOnLoad() {
      // Once our available tabs are loaded, set the current view
      // using the tab reset handler.
      if (selectItems && !currentView) {
        handleSetCurrentView(selectItems);
      }
    },
    [currentView, handleSetCurrentView, selectItems]
  );

  useEffect(
    function handleResetCurrentViewOnTabsChange() {
      // If the current view is not in the available tabs
      // call the reset handler to set the default current view.
      if (currentView && selectItems) {
        const activeTab =
          selectItems.find((tab) => tab.value === currentView.value) ?? null;

        if (!activeTab) {
          handleSetCurrentView(selectItems);
        }
      }
    },
    [currentView, handleSetCurrentView, selectItems]
  );

  const CurrentPanel = useMemo(
    () =>
      panels.find(({ value }) => value === currentView?.value)?.component ??
      null,
    [currentView?.value, panels]
  );

  const handleClose = () => {
    closeModal();
    onClose();
  };

  return (
    <>
      <SidePanel.Panel>
        <SidePanel.Header title={summaryFields.title} onClose={handleClose}>
          {selectItems && (
            <DropdownButton
              showArrow
              name="Dropdown-selectView"
              variant="secondary"
              size="sm"
              buttonContent={currentView?.name || ''}
              items={selectItems}
              contentBoxSx={{ width: '100%', justifyContent: 'space-between' }}
            />
          )}
        </SidePanel.Header>
        <SidePanel.Content>
          {loading ? (
            <SummaryPanelLoader />
          ) : (
            <SidePanel.Section>{CurrentPanel}</SidePanel.Section>
          )}
        </SidePanel.Content>
        <SidePanel.Footer>
          <EditButton onClick={openModal}>Edit details</EditButton>
        </SidePanel.Footer>
      </SidePanel.Panel>
      <ClientProfileModal
        clientProfileId={clientProfileId}
        householdId={householdId}
        isOpen={isModalOpen}
        setIsOpen={setModalIsOpen}
        clientProfileTypeDisplay="individual"
      />
    </>
  );
};

export const ClientProfileSummaryPanel = (
  props: ClientProfileSummaryPanelProps
) => {
  const { clientProfileId } = props;

  return (
    <ClientProfileDetailsAwareRoute clientProfileId={clientProfileId}>
      <ClientProfileSummaryPanelInner key={clientProfileId} {...props} />
    </ClientProfileDetailsAwareRoute>
  );
};
