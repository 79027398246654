import { useEffect } from 'react';
import { UseFormReset } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { UpdateCollaboratorByIdInput } from '@/types/schema';

import { CollaboratorFormShape } from './CollaboratorModal.constants';
import {
  CollaboratorModal_CollaboratorFragment,
  useGetCollaboratorLazyQuery,
} from './graphql/CollaboratorModal.generated';

export function getFormValuesFromNode(
  data: CollaboratorModal_CollaboratorFragment
): CollaboratorFormShape {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.user?.email ?? '',
    role: data.kind ?? '',
    organization: data.organizationName ?? '',
    title: data.title ?? '',
    associatedClients:
      data.households?.map((client) => ({
        heading: client.displayName,
        id: client.id,
      })) ?? [],
  };
}

export function mapFormDataToUpdateInput(
  formValues: CollaboratorFormShape,
  collaboratorId: string
): UpdateCollaboratorByIdInput {
  return {
    id: collaboratorId,
    update: {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      kind: formValues.role || null,
      clearHouseholds: true,
      addHouseholdIDs: formValues.associatedClients.map((client) => client.id),
      ...(formValues.title
        ? {
            title: formValues.title,
          }
        : {
            clearTitle: true,
          }),
      ...(formValues.organization
        ? {
            organizationName: formValues.organization ?? null,
          }
        : {
            clearOrganizationName: true,
          }),
    },
  };
}

export function useSyncCollaboratorDataToForm(
  reset: UseFormReset<CollaboratorFormShape>,
  collaboratorId: string
) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [getCollaborator, queryProps] = useGetCollaboratorLazyQuery({
    variables: { collaboratorId: collaboratorId },
    onError: (err) => {
      showFeedback(
        "We weren't able to get the collaborator information. Please refresh the page and try again."
      );
      reportError('CollaboratorEditModal: Could not get collaborator', err, {
        collaboratorId,
      });
    },
  });

  useEffect(() => {
    if (!collaboratorId) {
      return;
    }

    async function syncCollaboratorDataToForm() {
      const { data } = await getCollaborator();
      if (data?.node?.__typename === 'Collaborator') {
        reset(getFormValuesFromNode(data.node));
      }
    }

    void syncCollaboratorDataToForm();
  }, [reset, getCollaborator, collaboratorId]);

  return queryProps;
}
