import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useModalState } from '@/hooks/useModalState';
import { CreateEntityShortFormModal } from '@/modules/entities/EntityShortFormModal/CreateEntityShortFormModal';

interface AddEntityProps extends Partial<ButtonProps> {
  householdId: string;
}

export function AddEntity({ householdId, ...buttonProps }: AddEntityProps) {
  const [
    { isModalOpen: isShortCreateModalFormOpen },
    { setModalIsOpen: setIsShortCreateModalFormOpen },
  ] = useModalState();

  return (
    <>
      {isShortCreateModalFormOpen && (
        <CreateEntityShortFormModal
          onClose={() => setIsShortCreateModalFormOpen(false)}
          isOpen={isShortCreateModalFormOpen}
          householdId={householdId}
        />
      )}

      <Button
        variant="primary"
        size="sm"
        startIcon={PlusIcon}
        iconProps={{ size: 20 }}
        onClick={() => setIsShortCreateModalFormOpen(true)}
        {...buttonProps}
      >
        Add entity
      </Button>
    </>
  );
}
