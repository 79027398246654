import { useMemo } from 'react';

import { useChartColorDefinitions } from '@/components/charts/constants';
import { getStripedBackgroundCSS } from '@/styles/styleUtils';
import { COLORS } from '@/styles/tokens/colors';

import { EstateWaterfallSummaryBarChartsSections } from '../types';

export const useEstateWaterfallSummaryBarChartsSectionColors = (): Record<
  EstateWaterfallSummaryBarChartsSections,
  string
> => {
  const dataVisColors = useChartColorDefinitions();

  return useMemo(
    () => ({
      [EstateWaterfallSummaryBarChartsSections.InEstate]:
        dataVisColors.PRIMARY.backgroundColor,
      [EstateWaterfallSummaryBarChartsSections.OutOfEstateFamily]:
        dataVisColors.SECONDARY.backgroundColor,
      [EstateWaterfallSummaryBarChartsSections.OutOfEstateCharity]:
        dataVisColors.TERTIARY.backgroundColor,
      [EstateWaterfallSummaryBarChartsSections.GiftAndEstateTax]:
        dataVisColors.NEGATIVE.backgroundColor,
      [EstateWaterfallSummaryBarChartsSections.LifeInsuranceDeathBenefit]:
        getStripedBackgroundCSS(COLORS.GRAY[200], 8),
    }),
    [
      dataVisColors.NEGATIVE.backgroundColor,
      dataVisColors.PRIMARY.backgroundColor,
      dataVisColors.SECONDARY.backgroundColor,
      dataVisColors.TERTIARY.backgroundColor,
    ]
  );
};
