import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { GRANTOR_RELATIONSHIP_OPTIONS } from '@/components/form/PersonFormFragment/constants';
import { User01Icon } from '@/components/icons/User01Icon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.constants';
import { AIOnboardingModalFormShape } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { useAIOnboardingModalContext } from '@/modules/aiOnboarding/AIOnboardingModal/context/AIOnboardingModal.context';
import { COLORS } from '@/styles/tokens/colors';
import { PathsOf } from '@/types/subform';

interface IndividualFieldsRowProps {
  index: number;
}

export function IndividualFieldsRow({ index }: IndividualFieldsRowProps) {
  const { styles, columns } =
    AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS.individuals;

  const { control } = useFormContext<AIOnboardingModalFormShape>();

  const {
    household: { possiblePrimaryClients },
  } = useAIOnboardingModalContext();

  const primaryClient1RelationshipOptions = useMemo(() => {
    return GRANTOR_RELATIONSHIP_OPTIONS.map((option) => ({
      display: `${option.display} of ${possiblePrimaryClients[0]?.displayName}`,
      value: option.value,
    }));
  }, [possiblePrimaryClients]);

  const primaryClient2RelationshipOptions = useMemo(() => {
    if (possiblePrimaryClients.length < 2) {
      return [];
    }
    return GRANTOR_RELATIONSHIP_OPTIONS.map((option) => ({
      display: `${option.display} of ${possiblePrimaryClients[1]?.displayName}`,
      value: option.value,
    }));
  }, [possiblePrimaryClients]);

  return (
    <>
      <FormLayoutRow sx={{ gap: styles.columnPaddingHorizontal }}>
        <FormLayoutItem width={columns.firstName.columnWidth}>
          <FormAwareTextInput<AIOnboardingModalFormShape>
            control={control}
            fieldName={
              `individuals.${index}.firstName` as const satisfies PathsOf<AIOnboardingModalFormShape>
            }
            label={columns.firstName.columnHeader}
            hideLabel
            required
          />
        </FormLayoutItem>
        <FormLayoutItem width={columns.middleName.columnWidth}>
          <FormAwareTextInput<AIOnboardingModalFormShape>
            control={control}
            fieldName={
              `individuals.${index}.middleName` as const satisfies PathsOf<AIOnboardingModalFormShape>
            }
            label={columns.middleName.columnHeader}
            hideLabel
          />
        </FormLayoutItem>
        <FormLayoutItem width={columns.lastName.columnWidth}>
          <FormAwareTextInput<AIOnboardingModalFormShape>
            control={control}
            fieldName={
              `individuals.${index}.lastName` as const satisfies PathsOf<AIOnboardingModalFormShape>
            }
            label={columns.lastName.columnHeader}
            hideLabel
            required
          />
        </FormLayoutItem>
        <FormLayoutItem width={columns.suffix.columnWidth}>
          <FormAwareTextInput<AIOnboardingModalFormShape>
            control={control}
            fieldName={
              `individuals.${index}.suffix` as const satisfies PathsOf<AIOnboardingModalFormShape>
            }
            label={columns.suffix.columnHeader}
            hideLabel
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow sx={{ marginTop: -3 }}>
        <FormLayoutItem
          width={columns.relationshipToPrimaryClient1.columnWidth}
        >
          <RelationshipSelectInput
            fieldName={
              `individuals.${index}.relationshipToPrimaryClient1` as const satisfies PathsOf<AIOnboardingModalFormShape>
            }
            options={primaryClient1RelationshipOptions}
            primaryClientDisplayName={
              possiblePrimaryClients[0]?.displayName || 'Primary Client 1'
            }
          />
        </FormLayoutItem>
        {possiblePrimaryClients.length > 1 && (
          <FormLayoutItem
            width={columns.relationshipToPrimaryClient2.columnWidth}
          >
            <RelationshipSelectInput
              fieldName={
                `individuals.${index}.relationshipToPrimaryClient2` as const satisfies PathsOf<AIOnboardingModalFormShape>
              }
              options={primaryClient2RelationshipOptions}
              primaryClientDisplayName={
                possiblePrimaryClients[1]?.displayName || 'Primary Client 2'
              }
            />
          </FormLayoutItem>
        )}
      </FormLayoutRow>
    </>
  );
}

interface RelationshipSelectInputProps {
  fieldName: PathsOf<AIOnboardingModalFormShape>;
  options: SelectInputOption[];
  primaryClientDisplayName: string;
}

function RelationshipSelectInput({
  fieldName,
  options,
  primaryClientDisplayName,
}: RelationshipSelectInputProps) {
  const { control } = useFormContext<AIOnboardingModalFormShape>();

  const label = `Relationship to ${primaryClientDisplayName}`;

  return (
    <FormAwareSelectInput<AIOnboardingModalFormShape>
      control={control}
      fieldName={fieldName}
      label={label}
      options={options}
      hideLabel
      emptyValueDisplay={label}
      startAdornment={<User01Icon color={COLORS.GRAY['500']} />}
      renderValue={(value) => {
        const selectedOption = options.find((o) => o.value === value);

        if (!value || !selectedOption) {
          return (
            <Typography
              color={COLORS.GRAY['500']}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {label}
            </Typography>
          );
        }

        return (
          <Typography
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {selectedOption.display}
          </Typography>
        );
      }}
    />
  );
}
