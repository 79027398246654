import { useEffect } from 'react';
import { UseFormReset } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { HouseholdBillableKind } from '@/types/schema';

import { useGetAdminPageHouseholdQuery } from './graphql/ClientAdminPage.generated';

export interface HouseholdBillingFormShape {
  billableKind: HouseholdBillableKind;
}

export function useSyncHouseholdDataToForm(
  reset: UseFormReset<HouseholdBillingFormShape>,
  householdId: string
) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { data, ...queryProps } = useGetAdminPageHouseholdQuery({
    variables: { id: householdId },
    onError: (err) => {
      showFeedback(
        "We weren't able to get the client information. Please refresh the page and try again."
      );
      reportError('HouseholdAdminPage: Could not get household', err, {
        householdId,
      });
    },
  });

  useEffect(() => {
    if (data?.household?.__typename !== 'Household') {
      return;
    }

    reset({
      billableKind: data.household.billableKind,
    });
  }, [reset, data]);

  return queryProps;
}
