import { first, noop } from 'lodash';
import { PropsWithChildren, useCallback } from 'react';

import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { ENTITY_DETAILS_DEFAULT_FILTER_SEARCH_PARAM } from '@/modules/entities/components/EntityDetailsCard';
import {
  NonTrustCharitableEntityDetailsTabs,
  PersonalAccountDetailsTabs,
  TrustDetailsTabs,
} from '@/modules/entities/details/entityDetails.types';
import { ROUTE_KEYS } from '@/navigation/constants';
import { HouseholdOnboardingStepKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { getNodes } from '@/utils/graphqlUtils';

import {
  BUSINESS_ENTITY_KINDS,
  CHARITABLE_ENTITY_KINDS,
  PERSONAL_ACCOUNT_ENTITY_KINDS,
} from '../entities/entities.constants';
import { getEntityKindFromEntityType } from '../entities/utils/getEntityKindFromEntityType';
import { getEntityTypeFromSubtype } from '../entities/utils/getEntityTypeFromSubtype';
import {
  HouseholdOnboardingSidebar_EntityFragment,
  HouseholdOnboardingSidebar_EntityWithDocumentSummaryFragment,
} from './graphql/HouseholdOnboardingSidebar.generated';
import { HouseholdOnboardingSidebarActionContext } from './HouseholdOnboardingSidebarAction.context';

export interface HouseholdOnboardingSidebarActionProviderProps
  extends PropsWithChildren {
  householdId: string;
  latestEntityWithBeneficiaries:
    | HouseholdOnboardingSidebar_EntityFragment
    | null
    | undefined;
  latestEntityWithDocumentSummary:
    | HouseholdOnboardingSidebar_EntityWithDocumentSummaryFragment
    | null
    | undefined;
  navigate: ReturnType<typeof useNavigateToRoute>['navigate'];
  openCreateEntityModal: () => void;
  openModelANewStrategyModal: () => void;
  openCreateEstateWaterfallModal: () => void;
}

export function HouseholdOnboardingSidebarActionProvider({
  householdId,
  latestEntityWithBeneficiaries,
  latestEntityWithDocumentSummary,
  navigate,
  openCreateEntityModal,
  openModelANewStrategyModal,
  openCreateEstateWaterfallModal,
  children,
}: HouseholdOnboardingSidebarActionProviderProps) {
  const useActionForKey = useCallback(
    (stepKind: HouseholdOnboardingStepKind) => {
      switch (stepKind) {
        case HouseholdOnboardingStepKind.AddEntities:
          return openCreateEntityModal;
        case HouseholdOnboardingStepKind.CreateHypotheticalWaterfall:
          return () => {
            navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB, {
              householdId,
            });
            openCreateEstateWaterfallModal();
          };
        case HouseholdOnboardingStepKind.CreateWealthProfile:
          return () => {
            navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_MANAGE_LIABILITIES, {
              householdId,
            });
          };
        case HouseholdOnboardingStepKind.CustomizeTaxCalc:
          return () =>
            navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS, {
              householdId,
            });
        case HouseholdOnboardingStepKind.GeneratePresentation:
          return () =>
            navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATIONS, {
              householdId,
            });
        case HouseholdOnboardingStepKind.ModelStrategy:
          return openModelANewStrategyModal;
        case HouseholdOnboardingStepKind.ReviewExemption:
          return () =>
            navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_GIFTING, {
              householdId,
            });
        case HouseholdOnboardingStepKind.SpecifyDispositiveProvisions:
          return () =>
            navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS, {
              householdId,
            });
        case HouseholdOnboardingStepKind.SpecifyBeneficiaries:
          // this step relies on having an entity created, which isn't guaranteed; if it's not present, noop
          return latestEntityWithBeneficiaries?.id
            ? () => {
                const entityType = getEntityTypeFromSubtype(
                  latestEntityWithBeneficiaries?.subtype.__typename
                );
                const entityKind = getEntityKindFromEntityType(entityType);

                let tabName: string = TrustDetailsTabs.BENEFICIARIES;

                if (PERSONAL_ACCOUNT_ENTITY_KINDS.includes(entityKind)) {
                  tabName = PersonalAccountDetailsTabs.BENEFICIARIES;
                } else if (CHARITABLE_ENTITY_KINDS.includes(entityKind)) {
                  tabName = NonTrustCharitableEntityDetailsTabs.BENEFICIARIES;
                } else if (BUSINESS_ENTITY_KINDS.includes(entityKind)) {
                  // business entities shouldn't navigate anywhere
                  // https://linear.app/luminary/issue/T2-2447/specify-current-beneficiaries-of-the-householdonboardingsidebar-needs#comment-b2112426
                  return;
                }

                navigate(
                  ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
                  {
                    householdId,
                    entityId: latestEntityWithBeneficiaries?.id,
                  },
                  {
                    [ENTITY_DETAILS_DEFAULT_FILTER_SEARCH_PARAM]: tabName,
                  }
                );
              }
            : noop;
        case HouseholdOnboardingStepKind.SummarizeDocument:
          return () => {
            const documentWithSummary = first(
              getNodes(latestEntityWithDocumentSummary?.documents)
            );
            if (
              latestEntityWithDocumentSummary?.id &&
              documentWithSummary?.id
            ) {
              navigate(ROUTE_KEYS.HOUSEHOLD_ENTITY_DOCUMENT_DETAILS, {
                householdId,
                entityId: latestEntityWithDocumentSummary.id,
                documentId: documentWithSummary.id,
              });
            } else {
              navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_DOCUMENTS, { householdId });
            }
          };
        default:
          throw new UnreachableError({
            case: stepKind,
            message: 'Invalid key provided ',
          });
      }
    },
    [
      openCreateEntityModal,
      openModelANewStrategyModal,
      latestEntityWithBeneficiaries?.id,
      latestEntityWithBeneficiaries?.subtype.__typename,
      navigate,
      householdId,
      openCreateEstateWaterfallModal,
      latestEntityWithDocumentSummary?.documents,
      latestEntityWithDocumentSummary?.id,
    ]
  );

  return (
    <HouseholdOnboardingSidebarActionContext.Provider
      value={{ useActionForKey }}
    >
      {children}
    </HouseholdOnboardingSidebarActionContext.Provider>
  );
}
