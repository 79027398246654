import { Box, Stack, SxProps, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { InfoTooltip } from '../poppers/Tooltip/InfoTooltip';
import { RichListItem, RichListItemProps } from './RichListItem/RichListItem';
import { TABLE_CELL_PADDING_THEME_UNITS } from './TableCell.constants';

export interface TableCellProps extends RichListItemProps {
  RightContent?: JSX.Element;
  Draghandle?: JSX.Element;
}

// https://www.figma.com/file/FlleF4k1X9St9vv3EgiSGY/Design-System?node-id=826%3A13338&mode=dev
export function TableCell({
  RightContent,
  Draghandle,
  ...richListItemProps
}: TableCellProps) {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        breakInside: 'avoid',
      }}
    >
      <Stack direction="row">
        {Draghandle && Draghandle}
        <Stack p={TABLE_CELL_PADDING_THEME_UNITS}>
          <RichListItem {...richListItemProps} />
        </Stack>
      </Stack>
      <Box>{RightContent && RightContent}</Box>
    </Stack>
  );
}

export interface TableCellContextProps {
  heading: string;
  subheading?: string;
  tooltip?: string;
  action?: ReactNode;
  headingSx?: SxProps;
  subheadingSx?: SxProps;
}

export function TableCellContext({
  heading,
  subheading,
  tooltip,
  action,
  headingSx,
  subheadingSx,
}: TableCellContextProps) {
  return (
    <Box textAlign="right">
      <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
        <Stack direction="column">
          <Stack direction="row" justifyContent="flex-end" spacing={0.5}>
            <Typography variant="body1" sx={headingSx}>
              {heading}
            </Typography>
            {tooltip && <InfoTooltip title={tooltip} placement="top-end" />}
          </Stack>
          {subheading && (
            <Typography variant="subtitle2" sx={subheadingSx}>
              {subheading}
            </Typography>
          )}
        </Stack>
        {action && action}
      </Stack>
    </Box>
  );
}
