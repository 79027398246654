import { styled } from '@mui/material';
import { ReactFlow } from '@xyflow/react';

import { REACT_FLOW_CLASSES, REACT_FLOW_ZINDEX } from '../constants';
import { ExternalFlowChartProps, InternalFlowChartProps } from '../types';

type ReactFlowInternalComponent = (
  props: InternalFlowChartProps &
    Pick<ExternalFlowChartProps, 'presentationMode' | 'hideEdges'>
) => JSX.Element;

const EDGE_LABEL_TARGET = `* .${REACT_FLOW_CLASSES.EDGE_LABEL}`;
const EDGE = `.${REACT_FLOW_CLASSES.EDGE}`;
const PANE = `.${REACT_FLOW_CLASSES.PANE}`;
const NODE = `.${REACT_FLOW_CLASSES.NODE}`;

const presentationModeStyles = {
  [PANE]: {
    cursor: 'default',
  },
  [NODE]: {
    cursor: 'default',
  },
};

const hideEdgesStyles = {
  [EDGE]: {
    display: 'none',
  },
};

export const ThemedReactFlow = styled(ReactFlow as ReactFlowInternalComponent)(
  ({ presentationMode, hideEdges }) => ({
    // Forces edge labels to be above nodes and other lines
    [EDGE_LABEL_TARGET]: {
      zIndex: REACT_FLOW_ZINDEX.EDGE_LABEL_RENDERER,
    },
    [EDGE]: {
      cursor: 'default',
    },
    ...(presentationMode ? presentationModeStyles : {}),
    ...(hideEdges ? hideEdgesStyles : {}),
  })
  // Typing fix so that the underlying forwardRef component shows up as a ref prop, otherwise styled masks it
) as unknown as ReactFlowInternalComponent;
