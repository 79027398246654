import { DirectedGraph as GraphInterface } from 'graphology';
import { Attributes } from 'graphology-types';

/**
 * @description Extracts types for a method from the GraphInterface, but extends
 * it to also allow a null return value
 */
type SafeMethod<Fn extends (...args: any[]) => unknown> = (
  ...args: Parameters<Fn>
) => null | ReturnType<Fn>;

/**
 * @description Extends the base Graphology instance with some helpers.
 */
export class DirectedGraph<
  N extends Attributes,
  E extends Attributes,
  G extends Attributes,
> extends GraphInterface<N, E, G> {
  /**
   * @description Returns the undecorated instance.
   * Useful for library utils that require the unadulterated type.
   */
  graphologyInstance = (): GraphInterface<N, E, G> => {
    return GraphInterface.from(this);
  };

  addNodeSafe: SafeMethod<GraphInterface<N, E, G>['addNode']> = (
    node,
    attrs
  ) => {
    if (this.hasNode(node)) return null;
    return this.addNode(node, attrs);
  };

  addEdgeSafe: SafeMethod<GraphInterface<N, E, G>['addEdge']> = (
    source,
    target,
    attrs
  ) => {
    if (this.hasEdge(source, target)) return null;
    return this.addEdge(source, target, attrs);
  };

  clone() {
    return new DirectedGraph<N, E, G>().import(this.export());
  }
}
