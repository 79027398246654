import { Maybe } from 'graphql/jsutils/Maybe';
import { isEqual } from 'lodash';
import { HTMLAttributes, memo, useCallback } from 'react';

import { TileNode } from '@/components/diagrams/FlowChart';
import { ClientProfileSummaryPanel } from '@/modules/summaryPanels/ClientProfileSummaryPanel/ClientProfileSummaryPanel';
import { EntitySummaryPanel } from '@/modules/summaryPanels/EntitySummaryPanel/EntitySummaryPanel';
import { EstateTaxSummaryPanel } from '@/modules/summaryPanels/EstateTaxSummaryPanel/EstateTaxSummaryPanel';
import { ExternalTranfserSummaryPanel } from '@/modules/summaryPanels/ExternalTransferSummaryPanel/ExternalTransferSummaryPanel';
import { GrowthProfilesSummaryPanel } from '@/modules/summaryPanels/GrowthProfilesSummaryPanel/GrowthProfilesSummaryPanel';
import { HypotheticalTransfersSummaryPanel } from '@/modules/summaryPanels/HypotheticalTransfersSummaryPanel/HypotheticalTransfersSummaryPanel';
import { OrganizationSummaryPanel } from '@/modules/summaryPanels/OrganizationSummaryPanel/OrganizationSummaryPanel';
import { TestamentaryEntitySummaryPanel } from '@/modules/summaryPanels/TestamentaryEntitySummaryPanel/TestamentaryEntitySummaryPanel';
import { AfterDeath } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { useEstateWaterfallContext } from '../../contexts/estateWaterfall.context';
import {
  isRecipientOfDistribution,
  isRecipientOfExternalTransfer,
} from '../../graphql';
import { EstateWaterfall_NodeFragment } from '../../graphql/EstateWaterfall.generated';
import {
  EstateTaxNodeData,
  EstateWaterfallGraphNodeAttributes,
  GraphNodeCategorizationType,
} from '../../types';
import { EXTERNAL_TRANSFER_NODE_IDENTIFIER } from '../../waterfallGraph/constants';
import {
  getExternalTransferNodeId,
  getNodeId,
} from '../../waterfallGraph/utils';
import { GROWTH_PROFILES_SENTINEL } from '../GrowthProfilesButton';
import { HYPOTHETICAL_TRANSFERS_SUMMARY_SENTINEL } from '../HypotheticalTransfersButton';

const ValidSummaryTypenames = [
  'Entity',
  'ClientProfile',
  'TestamentaryEntity',
  'ClientOrganization',
] satisfies NonNullable<EstateWaterfall_NodeFragment['node']['__typename']>[];
type ValidSummaryTypename = (typeof ValidSummaryTypenames)[number];

const ValidTypeNamesSet = new Set(ValidSummaryTypenames);

function isEntityNodeFragment(x?: unknown): x is EstateWaterfall_NodeFragment {
  const maybeX = x as Maybe<EstateWaterfall_NodeFragment>;
  return ValidTypeNamesSet.has(
    maybeX?.node?.__typename as ValidSummaryTypename
  );
}

function isGiftTaxNode(
  x: EstateWaterfallGraphNodeAttributes | null
): x is EstateWaterfallGraphNodeAttributes<TileNode, EstateTaxNodeData> {
  return x?.categorizationType === GraphNodeCategorizationType.GiftTax;
}

function isEstateTaxNode(
  x: EstateWaterfallGraphNodeAttributes | null
): x is EstateWaterfallGraphNodeAttributes<TileNode, EstateTaxNodeData> {
  return x?.categorizationType === GraphNodeCategorizationType.EstateTax;
}

export interface NodeSummaryPanelProps extends HTMLAttributes<HTMLDivElement> {
  householdId: string;
  waterfallId: string;
}

const SummaryComponent = ({
  householdId,
  waterfallId,
}: NodeSummaryPanelProps) => {
  const { state, dispatch } = useEstateWaterfallContext();
  const { firstPrimaryClientDeathId, summaryNode, isHypothetical } =
    state.derivedData;

  const isOnSourceWaterfall = !isHypothetical;

  const onClose = useCallback(() => {
    dispatch({ type: 'CLOSE_SUMMARY_PANEL' });
  }, [dispatch]);

  if (isEstateTaxNode(summaryNode) || isGiftTaxNode(summaryNode)) {
    return (
      <EstateTaxSummaryPanel
        householdId={householdId}
        waterfallId={waterfallId}
        onClose={onClose}
        initialAfterDeathTab={summaryNode.data.afterDeath}
      />
    );
  }

  if (summaryNode?.node.id === HYPOTHETICAL_TRANSFERS_SUMMARY_SENTINEL) {
    return (
      <HypotheticalTransfersSummaryPanel
        waterfallId={waterfallId}
        householdId={householdId}
        onClose={onClose}
        onManageSource={(sourceId: string) => {
          let nodeId = '';
          if (sourceId === EXTERNAL_TRANSFER_NODE_IDENTIFIER) {
            nodeId = getExternalTransferNodeId('incoming');
          } else {
            nodeId = getNodeId({
              id: sourceId,
              afterDeath: AfterDeath.None,
            });
          }
          dispatch({ type: 'OPEN_SUMMARY_PANEL', nodeId });
        }}
      />
    );
  }

  if (summaryNode?.node.id === GROWTH_PROFILES_SENTINEL) {
    return (
      <GrowthProfilesSummaryPanel
        waterfall={state.waterfall}
        householdId={householdId}
        onClose={onClose}
      />
    );
  }

  if (summaryNode?.node.id.includes(EXTERNAL_TRANSFER_NODE_IDENTIFIER)) {
    return (
      <ExternalTranfserSummaryPanel
        summaryNodeId={summaryNode.node.id}
        onClose={onClose}
        waterfallId={waterfallId}
        householdId={householdId}
      />
    );
  }

  if (!isEntityNodeFragment(summaryNode?.data)) return null;

  switch (summaryNode?.data?.node.__typename) {
    case 'ClientProfile': {
      return (
        <ClientProfileSummaryPanel
          householdId={householdId}
          clientProfileId={summaryNode.data.id}
          onClose={onClose}
          afterDeath={summaryNode.data.afterDeath}
          isRecipientOfDistribution={isRecipientOfDistribution(
            summaryNode.data.id,
            state.waterfall.visualizationWithProjections.edges
          )}
          isRecipientOfExternalTransfer={isRecipientOfExternalTransfer(
            summaryNode.data.id,
            getNodes(state.waterfall.incomingExternalTransfers ?? [])
          )}
          currentNodeValue={summaryNode.data.value}
          isOnSourceWaterfall={isOnSourceWaterfall}
          isOnHypotheticalWaterfall={isHypothetical}
          // Key on the clientProfile id so that the summary panel tab
          // is reset when changing entities
          key={summaryNode.data.id}
        />
      );
    }
    case 'ClientOrganization': {
      return (
        <OrganizationSummaryPanel
          householdId={householdId}
          organizationId={summaryNode.data.id}
          isOnSourceWaterfall={isOnSourceWaterfall}
          onClose={onClose}
        />
      );
    }
    case 'Entity': {
      return (
        <EntitySummaryPanel
          householdId={householdId}
          entityId={summaryNode.data.id}
          onClose={onClose}
          isOnSourceWaterfall={isOnSourceWaterfall}
          isOnHypotheticalWaterfall={isHypothetical}
          isHypotheticalEntity={summaryNode.data.isHypothetical}
          // Key on the entity id so that the summary panel tab
          // is reset when changing entities
          key={summaryNode.data.id}
        />
      );
    }
    case 'TestamentaryEntity': {
      return (
        <TestamentaryEntitySummaryPanel
          householdId={householdId}
          entityId={summaryNode.data.id}
          onClose={onClose}
          firstPrimaryClientDeathId={firstPrimaryClientDeathId}
          entityValue={summaryNode.data.value}
          isOnWaterfall={true}
        />
      );
    }
    default:
      diagnostics.error(
        `Unhandled node type: ${summaryNode?.data?.node.__typename}`,
        new Error('Unhandled node type in NodeSummaryPanelV2')
      );

      return null;
  }
};

export const MemoizedNodeSummaryPanel = memo(function NodeSummaryPanelV2({
  householdId,
  waterfallId,
  ...props
}: NodeSummaryPanelProps) {
  const { state } = useEstateWaterfallContext();
  if (!state.derivedData.summaryNode) return null;

  return (
    <div {...props}>
      <SummaryComponent householdId={householdId} waterfallId={waterfallId} />
    </div>
  );
}, isEqual);
