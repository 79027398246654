import { Typography } from '@mui/material';
import { Box, BoxProps, SxProps, Theme } from '@mui/system';
import { MouseEventHandler } from 'react';

import { XIcon } from '@/components/icons/XIcon';
import { COLORS } from '@/styles/tokens/colors';

export interface ChipProps extends Omit<BoxProps, 'ref'> {
  typographySx?: SxProps<Theme>;
  label: React.ReactNode;
  onRemove: MouseEventHandler;
  disabled?: boolean;
}

export function Chip({
  typographySx: typographySxOverrides = {},
  label,
  onRemove,
  disabled,
  ...props
}: ChipProps) {
  const typographySx: SxProps<Theme> = {
    px: 1,
    borderRadius: '2px',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    // maxWidth: `calc(100% - 20px)`,
    ...typographySxOverrides,
  };

  return (
    // Container to ensure that the badge takes up only the space it needs
    // without overflowing its parent
    <Box display="inline-block" sx={{ maxWidth: '100%' }} {...props}>
      <Box
        sx={{
          backgroundColor: COLORS.GRAY[300],
          color: COLORS.NAVY[900],
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: '2px',
          position: 'relative',
        }}
      >
        <Typography variant="h6" component="span" sx={typographySx}>
          {label}
        </Typography>
        <RemoveChipButton onClick={onRemove} disabled={disabled} />
      </Box>
    </Box>
  );
}

interface RemoveChipButtonProps {
  onClick?: MouseEventHandler;
  disabled?: boolean;
}

function RemoveChipButton({ onClick, disabled }: RemoveChipButtonProps) {
  return (
    <Box
      onClick={disabled ? undefined : onClick}
      aria-disabled={disabled}
      role="button"
      tabIndex={0}
      aria-label="Remove tag items"
      sx={{
        backgroundColor: disabled ? COLORS.GRAY[400] : COLORS.GRAY[600],
        ':hover': {
          backgroundColor: disabled ? COLORS.GRAY[400] : COLORS.GRAY[700],
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        cursor: 'pointer',
        borderTopRightRadius: '2px',
        borderBottomRightRadius: '2px',
      }}
    >
      <XIcon size={18} sx={{ color: 'white' }} />
    </Box>
  );
}
