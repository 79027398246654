// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDraftEntityModal_EntityFragment = { __typename?: 'Entity', id: string, kind: Types.EntityKind, subtype: { __typename?: 'CCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'CLTTrust', id: string, displayName: string } | { __typename?: 'CRTTrust', id: string, displayName: string } | { __typename?: 'CustodialPersonalAccount', id: string, displayName: string } | { __typename?: 'DonorAdvisedFund', id: string, displayName: string } | { __typename?: 'GPBusinessEntity', id: string, displayName: string } | { __typename?: 'GRATTrust', id: string, displayName: string } | { __typename?: 'ILITTrust', id: string, displayName: string } | { __typename?: 'IndividualPersonalAccount', id: string, displayName: string } | { __typename?: 'InsurancePersonalAccount', id: string, displayName: string } | { __typename?: 'IrrevocableTrust', id: string, displayName: string } | { __typename?: 'JointPersonalAccount', id: string, displayName: string } | { __typename?: 'LLCBusinessEntity', id: string, displayName: string } | { __typename?: 'LPBusinessEntity', id: string, displayName: string } | { __typename?: 'PrivateFoundation', id: string, displayName: string } | { __typename?: 'QPRTTrust', id: string, displayName: string } | { __typename?: 'QualifiedTuitionPersonalAccount', id: string, displayName: string } | { __typename?: 'RetirementPersonalAccount', id: string, displayName: string } | { __typename?: 'RevocableTrust', id: string, displayName: string } | { __typename?: 'SCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'SLATTrust', id: string, displayName: string } | { __typename?: 'SoleProprietorshipBusinessEntity', id: string, displayName: string } };

export type CreateDraftEntityMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateEntityInput;
}>;


export type CreateDraftEntityMutation = { __typename?: 'Mutation', createEntity: { __typename?: 'Entity', id: string, kind: Types.EntityKind, subtype: { __typename?: 'CCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'CLTTrust', id: string, displayName: string } | { __typename?: 'CRTTrust', id: string, displayName: string } | { __typename?: 'CustodialPersonalAccount', id: string, displayName: string } | { __typename?: 'DonorAdvisedFund', id: string, displayName: string } | { __typename?: 'GPBusinessEntity', id: string, displayName: string } | { __typename?: 'GRATTrust', id: string, displayName: string } | { __typename?: 'ILITTrust', id: string, displayName: string } | { __typename?: 'IndividualPersonalAccount', id: string, displayName: string } | { __typename?: 'InsurancePersonalAccount', id: string, displayName: string } | { __typename?: 'IrrevocableTrust', id: string, displayName: string } | { __typename?: 'JointPersonalAccount', id: string, displayName: string } | { __typename?: 'LLCBusinessEntity', id: string, displayName: string } | { __typename?: 'LPBusinessEntity', id: string, displayName: string } | { __typename?: 'PrivateFoundation', id: string, displayName: string } | { __typename?: 'QPRTTrust', id: string, displayName: string } | { __typename?: 'QualifiedTuitionPersonalAccount', id: string, displayName: string } | { __typename?: 'RetirementPersonalAccount', id: string, displayName: string } | { __typename?: 'RevocableTrust', id: string, displayName: string } | { __typename?: 'SCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'SLATTrust', id: string, displayName: string } | { __typename?: 'SoleProprietorshipBusinessEntity', id: string, displayName: string } } };

export const CreateDraftEntityModal_EntityFragmentDoc = gql`
    fragment CreateDraftEntityModal_Entity on Entity {
  id
  kind
  subtype {
    id
    displayName
  }
}
    `;
export const CreateDraftEntityDocument = gql`
    mutation CreateDraftEntity($input: AugmentedCreateEntityInput!) {
  createEntity(input: $input) {
    ...CreateDraftEntityModal_Entity
  }
}
    ${CreateDraftEntityModal_EntityFragmentDoc}`;
export type CreateDraftEntityMutationFn = Apollo.MutationFunction<CreateDraftEntityMutation, CreateDraftEntityMutationVariables>;

/**
 * __useCreateDraftEntityMutation__
 *
 * To run a mutation, you first call `useCreateDraftEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDraftEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDraftEntityMutation, { data, loading, error }] = useCreateDraftEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDraftEntityMutation(baseOptions?: Apollo.MutationHookOptions<CreateDraftEntityMutation, CreateDraftEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDraftEntityMutation, CreateDraftEntityMutationVariables>(CreateDraftEntityDocument, options);
      }
export type CreateDraftEntityMutationHookResult = ReturnType<typeof useCreateDraftEntityMutation>;
export type CreateDraftEntityMutationResult = Apollo.MutationResult<CreateDraftEntityMutation>;
export type CreateDraftEntityMutationOptions = Apollo.BaseMutationOptions<CreateDraftEntityMutation, CreateDraftEntityMutationVariables>;
export const Operations = {
  Mutation: {
    CreateDraftEntity: 'CreateDraftEntity' as const
  },
  Fragment: {
    CreateDraftEntityModal_Entity: 'CreateDraftEntityModal_Entity' as const
  }
}