import { AccessParameterKind, ScheduledDistributionKind } from '@/types/schema';

import {
  AccessParameterRadioGroupKind,
  BeneficiaryFormScheduledDistribution,
  EntityBeneficiariesFormBeneficiary,
  EntityBeneficiariesSubformShape,
} from './EntityBeneficiariesSubform.types';

export const defaultAccessParameter = {
  kind: AccessParameterKind.Full,
  _hasAgeParams: false,
  ageParams: [],
  _hasFrequency: false,
};

export const defaultBeneficiary = (): EntityBeneficiariesFormBeneficiary => ({
  id: null,
  beneficiaryId: '',
  level: '',
  notes: '',
  beneficiaryFormScheduledDistributions: {
    _hasScheduledDistributions: false,
    scheduledDistributions: [],
  },
  beneficiaryFormAccessParameters: {
    _accessParameterKind: AccessParameterRadioGroupKind.Full,
    accessParametersFull: defaultAccessParameter,
  },
  beneficiaryFormPowerOfAppointment: {
    _hasPowerOfAppointment: false,
    power: '',
  },
});

export const defaultCharitableBeneficiary =
  (): EntityBeneficiariesFormBeneficiary => ({
    id: null,
    beneficiaryId: '',
    level: '',
    notes: '',
    charitableBeneficiaryType: 'annuity',
    beneficiaryFormScheduledDistributions: {
      _hasScheduledDistributions: false,
      scheduledDistributions: [],
    },
    beneficiaryFormAccessParameters: {
      _accessParameterKind: AccessParameterRadioGroupKind.Partial,
      accessParametersPartial: [
        {
          kind: AccessParameterKind.Amount,
          _hasAgeParams: false,
          ageParams: [],
          _hasFrequency: true,
        },
      ],
    },
    beneficiaryFormPowerOfAppointment: {
      _hasPowerOfAppointment: false,
      power: '',
    },
  });

export const entityBeneficiariesSubformDefaultValues: EntityBeneficiariesSubformShape =
  {
    beneficiaries: [],
    lifetimeBeneficiaries: [],
    remainderBeneficiaries: [],
    leadBeneficiary: defaultCharitableBeneficiary(),
    incomeBeneficiary: defaultCharitableBeneficiary(),
  };

export const defaultBeneficiaryFormScheduledDistribution: BeneficiaryFormScheduledDistribution =
  {
    _hasAgeParams: false,
    _ageRequirementKind: 'upon' as const,
    scheduledDistribution: {
      kind: ScheduledDistributionKind.Percentage,
      frequency: '',
    },
  };
