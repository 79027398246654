import { ApolloError } from '@apollo/client';
import { Box, Stack, useTheme } from '@mui/material';
import { first } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { SidebarContainer } from '@/components/layout/SidebarContainer/SidebarContainer';
import { ConfirmationModal } from '@/components/modals/ConfirmationModal/ConfirmationModal';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { COLORS } from '@/styles/tokens/colors';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { ModelANewStrategyModal } from '../common/CreateNewEntityOrStrategy/ModelANewStrategyModal';
import { CreateEntityShortFormModal } from '../entities/EntityShortFormModal/CreateEntityShortFormModal';
import { CreateEstateWaterfallTrowser } from '../estateWaterfall/components/EstateWaterfallModal/CreateEstateWaterfallTrowser';
import { useGlobalSidebarContext } from '../globalSidebar/globalSidebar.context';
import { useHouseholdDetailsContext } from '../household/contexts/householdDetails.context';
import {
  RecentEntitiesSidebar,
  RecentEntitiesSidebarContents,
} from '../recents/RecentEntitiesSidebar/RecentEntitiesSidebar';
import { useHouseholdOnboardingSidebarSubscriptionSubscription } from './graphql/HouseholdOnboardingSidebar.generated';
import { useUpdateHouseholdOnboardingMutation } from './graphql/UpdateHouseholdOnboarding.generated';
import {
  HouseholdOnboardingSidebarData,
  useGetDisplayHouseholdOnboardingSidebarQuery,
} from './hooks/useShouldDisplayHouseholdOnboardingSidebar';
import { HouseholdOnboardingStepGroup } from './HouseholdOnboardingSidebar.components';
import { mapDataToOnboardingStepGroups } from './HouseholdOnboardingSidebar.utils';
import { HouseholdOnboardingSidebarActionProvider } from './HouseholdOnboardingSidebarAction.provider';
import { HouseholdOnboardingSidebarHeader } from './HouseholdOnboardingSidebarHeader';

interface HouseholdOnboardingSidebarInnerProps {
  householdId: string;
  household: HouseholdOnboardingSidebarData;
}

function HouseholdOnboardingSidebarInner({
  householdId,
  household,
}: HouseholdOnboardingSidebarInnerProps) {
  const trackUserEvent = useTrackUserEvent();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const { primaryClients } = useHouseholdDetailsContext();
  const { isSidebarCollapsed } = useGlobalSidebarContext();

  // this is used only to update the cache; the household prop has the current state
  useHouseholdOnboardingSidebarSubscriptionSubscription({
    variables: {
      householdId,
    },
    fetchPolicy: 'network-only',
    skip: !householdId || isSidebarCollapsed, // Do not run the subscription if the sidebar is collapsed
    onError: (err: ApolloError) => {
      diagnostics.warn('Household onboarding sidebar subscription error', err);
    },
  });

  const groups = useMemo(
    () => mapDataToOnboardingStepGroups(household),
    [household]
  );

  const [
    { isModalOpen: isDismissModalOpen },
    { openModal: openDismissModal, closeModal: closeDismissModal },
  ] = useModalState();
  const [
    { isModalOpen: isCreateEntityModalOpen },
    { openModal: openCreateEntityModal, closeModal: closeCreateEntityModal },
  ] = useModalState();
  const [
    { isModalOpen: isModelANewStrategyModalOpen },
    {
      openModal: openModelANewStrategyModal,
      closeModal: closeModelANewStrategyModal,
    },
  ] = useModalState();
  const [
    { isModalOpen: isCreateEstateWaterfallModalOpen },
    {
      openModal: openCreateEstateWaterfallModal,
      closeModal: closeCreateEstateWaterfallModal,
    },
  ] = useModalState();

  const { navigate } = useNavigateToRoute();

  const [
    dismissOnboardingMutation,
    { loading: loadingDismissOnboardingMutation },
  ] = useUpdateHouseholdOnboardingMutation({
    onCompleted: () => {
      trackUserEvent('onboarding_sidebar dismissed', {
        householdId,
      });
    },
    onError: (err: ApolloError) => {
      reportError(
        'Encountered an error when marking onboarding as complete',
        err
      );
      showFeedback(
        "Could not dismiss the household's onboarding checklist.  Please try again later."
      );
    },
  });

  const dismissOnboarding = useCallback(() => {
    const onboardingId = household?.onboarding?.id;
    if (!onboardingId) {
      return;
    }
    void dismissOnboardingMutation({
      variables: {
        input: {
          id: onboardingId,
          update: {
            completedAt: new Date(),
          },
        },
      },
    });
  }, [household?.onboarding?.id, dismissOnboardingMutation]);

  // this case should already be handled more gracefully in the useShouldDisplayHouseholdOnboardingSidebar
  // hook, which should prevent this component from ever rendering
  if (!householdId || !household) {
    return null;
  }

  return (
    <HouseholdOnboardingSidebarActionProvider
      householdId={householdId}
      latestEntityWithBeneficiaries={first(
        getNodes(household.latestEntityWithBeneficiaries)
      )}
      latestEntityWithDocumentSummary={first(
        getNodes(household.latestEntityWithDocumentSummary)
      )}
      navigate={navigate}
      openCreateEntityModal={openCreateEntityModal}
      openModelANewStrategyModal={openModelANewStrategyModal}
      openCreateEstateWaterfallModal={openCreateEstateWaterfallModal}
    >
      <HouseholdOnboardingSidebarHeader groups={groups} />
      <Stack
        sx={{ pl: 3, flexGrow: 1, flexShrink: 1, overflowY: 'auto' }}
        spacing={2}
      >
        {groups.map(({ key, steps }) => (
          <HouseholdOnboardingStepGroup
            key={key}
            stepGroup={key}
            steps={steps}
          />
        ))}
      </Stack>
      <Stack alignItems="center" sx={{ px: 3, py: 4, mt: '0 !important' }}>
        <Button
          variant="secondary"
          size="md"
          onClick={openDismissModal}
          fullWidth
        >
          Dismiss checklist
        </Button>
      </Stack>
      <ConfirmationModal
        isOpen={isDismissModalOpen}
        onClose={closeDismissModal}
        confirmButtonProps={{
          onClick: dismissOnboarding,
          disabled: loadingDismissOnboardingMutation,
        }}
        heading="Are you sure you wish to dismiss this checklist?"
      >
        The checklist will be permanently dismissed for this client. To hide it
        temporarily, click the gray tab along the left side.
      </ConfirmationModal>
      {isCreateEntityModalOpen && (
        <CreateEntityShortFormModal
          householdId={householdId}
          isOpen={isCreateEntityModalOpen}
          onClose={closeCreateEntityModal}
        />
      )}
      <ModelANewStrategyModal
        isOpen={isModelANewStrategyModalOpen}
        handleClose={closeModelANewStrategyModal}
        householdId={householdId}
      />
      <CreateEstateWaterfallTrowser
        isOpen={isCreateEstateWaterfallModalOpen}
        onClose={closeCreateEstateWaterfallModal}
        householdId={householdId}
        grantors={primaryClients || []}
        sourceEstateWaterfalls={household.estateWaterfalls || []}
        defaultHypothetical
      />
    </HouseholdOnboardingSidebarActionProvider>
  );
}

export interface HouseholdOnboardingSidebarProps {
  allowRecents?: boolean;
}

enum HouseholdOnboardingSidebarViews {
  Onboarding = 'onboarding',
  Recents = 'recents',
}

interface ToggleViewFooterProps {
  currentView: HouseholdOnboardingSidebarViews;
  onChangeView: (view: HouseholdOnboardingSidebarViews) => void;
  allowRecents: boolean;
}

function ToggleViewFooter({
  currentView,
  onChangeView,
  allowRecents,
}: ToggleViewFooterProps) {
  const theme = useTheme();
  if (!allowRecents) {
    return null;
  }
  return (
    <Box
      sx={{
        p: 2,
        background: COLORS.GRAY[100],
        mt: '0 !important',
        boxShadow: `${theme.palette.shadows.mdYInvert}, ${theme.palette.shadows.rightInvertInset}`,
      }}
    >
      <ButtonGroup<HouseholdOnboardingSidebarViews>
        variant="onDark"
        label="Sidebar view"
        hideLabel
        onChange={(_event, value) => onChangeView(value)}
        sx={{ width: '100%' }}
        options={[
          {
            display: 'Checklist',
            value: 'onboarding',
          },
          {
            display: 'Recents',
            value: 'recents',
          },
        ]}
        value={currentView}
      />
    </Box>
  );
}

export function HouseholdOnboardingSidebar({
  allowRecents = false,
}: HouseholdOnboardingSidebarProps) {
  const { household } = useGetDisplayHouseholdOnboardingSidebarQuery();

  const [sidebarContents, setSidebarContents] =
    useState<HouseholdOnboardingSidebarViews>(
      HouseholdOnboardingSidebarViews.Onboarding
    );

  const householdId = household?.id;
  if (!household || !householdId) {
    return (
      <RecentEntitiesSidebar
        data-testid="global-sidebar-recents"
        showRecentClients={false}
      />
    );
  }

  return (
    <SidebarContainer
      sx={{ p: 0, m: 0, position: 'relative' }}
      // hide the sidebar by setting its width to zero if not displayed, so the
      // visibility toggle is still present on-screen
      showVisibilityHandle
    >
      {sidebarContents === HouseholdOnboardingSidebarViews.Recents &&
      allowRecents ? (
        <Box sx={{ flexGrow: 1, flexShrink: 1, pl: 2, py: 3 }}>
          <RecentEntitiesSidebarContents />
        </Box>
      ) : (
        <HouseholdOnboardingSidebarInner
          householdId={householdId}
          household={household}
        />
      )}
      <ToggleViewFooter
        currentView={sidebarContents}
        allowRecents={allowRecents}
        onChangeView={setSidebarContents}
      />
    </SidebarContainer>
  );
}
