import { FlowChartLoader } from '../../slots/FlowChartLoader';
import { MarkerDefinitions } from '../../slots/MarkerDefinitions';
import { ExternalFlowChartProps } from '../../types';

export function useSlots({
  slots,
}: ExternalFlowChartProps): Required<
  NonNullable<ExternalFlowChartProps['slots']>
> {
  return { Loader: FlowChartLoader, MarkerDefinitions, ...slots };
}
