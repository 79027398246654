import { COLORS } from '@/styles/tokens/colors';

interface PoweredByLuminaryProps {
  textVariant?: 'light' | 'dark';
}

export function PoweredByLuminary({
  textVariant = 'light',
}: PoweredByLuminaryProps) {
  const textColor = textVariant === 'light' ? 'white' : COLORS.GRAY[500];
  return (
    <svg
      width="100%"
      // height="32"
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 138 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.2105 11.5781L14.4577 11.5441L23.4809 7.87534L23.1255 7.01919L14.1023 10.688L21.024 3.87276L20.363 3.21751L13.4444 10.0296L17.2077 1.10959L16.3441 0.751062L12.5807 9.67109L12.615 0.00309079L11.6797 0L11.6454 9.668L7.94442 0.723245L7.08076 1.0756L10.7817 10.0203L3.90673 3.15879L3.24574 3.81404L10.1176 10.6725L1.11933 6.94192L0.757651 7.79807L9.75592 11.5287L0.0031179 11.4947L0 12.4219L9.75281 12.4559L0.72959 16.1247L1.08503 16.9839L10.1051 13.3151L3.1865 20.1272L3.84749 20.7825L10.7661 13.9704L7.00281 22.8904L7.86647 23.2489L11.6298 14.3289L11.5955 23.9969L12.5309 24L12.5652 14.332L16.2661 23.2768L17.1329 22.9244L13.4288 13.9797L20.3038 20.8412L20.9679 20.186L14.0929 13.3275L23.0912 17.0581L23.4529 16.2019L14.4546 12.4713L24.2074 12.5053L24.2105 11.5781Z"
        fill={COLORS.ORANGE[400]}
      />
      <path
        d="M33.8947 22.2564V9.6H35.4105V20.9978H41.9357V22.2534H33.8979L33.8947 22.2564Z"
        fill={textColor}
      />
      <path
        d="M46.2785 21.1078C45.4043 20.2463 44.9672 18.9785 44.9672 17.3014V9.6H46.4641V17.4266C46.4641 18.6608 46.7754 19.5864 47.3981 20.2096C48.0207 20.8328 48.9358 21.1444 50.1434 21.1444C51.3509 21.1444 52.2629 20.8328 52.8793 20.2096C53.4956 19.5864 53.8038 18.6608 53.8038 17.4266V9.6H55.3196V17.3014C55.3196 18.9663 54.8793 20.231 53.9988 21.0986C53.1183 21.9662 51.8321 22.4 50.1465 22.4C48.461 22.4 47.1591 21.9693 46.2848 21.1078H46.2785Z"
        fill={textColor}
      />
      <path
        d="M71.15 9.6H72.389V22.2564H71.0022L71.0211 12.4533L66.7883 20.2463H65.7159L61.4643 12.5266L61.4832 22.2564H60.0775V9.6H61.3354L66.2694 18.7738L71.15 9.6Z"
        fill={textColor}
      />
      <path
        d="M77.2381 22.2564V9.6H78.7539V22.2564H77.2381Z"
        fill={textColor}
      />
      <path
        d="M94.0466 9.6V22.2564H92.883L85.0276 12.0775V22.2564H83.603V9.6H84.7666L92.622 19.7789V9.6H94.0466Z"
        fill={textColor}
      />
      <path
        d="M108.918 22.2564L107.478 19.006H100.676L99.2353 22.2564H97.6818L103.411 9.6H104.723L110.452 22.2564H108.918ZM101.21 17.7871H106.94L104.075 11.3077L101.21 17.7871Z"
        fill={textColor}
      />
      <path
        d="M124.352 22.2564H122.688L120.471 18.1995C120.201 17.7077 119.883 17.3716 119.519 17.1853C119.154 16.9989 118.66 16.9073 118.031 16.9073H115.591V22.2564H114.094V9.6H119.453C120.868 9.6 121.949 9.90549 122.695 10.5165C123.44 11.1274 123.814 12.0256 123.814 13.2109C123.814 14.1915 123.528 14.9858 122.956 15.5906C122.383 16.1955 121.566 16.5743 120.506 16.7301C120.839 16.8248 121.132 16.9867 121.383 17.2158C121.635 17.4419 121.868 17.7474 122.075 18.1323L124.349 22.2625L124.352 22.2564ZM119.324 15.6853C120.333 15.6853 121.088 15.4807 121.588 15.0744C122.088 14.6681 122.336 14.051 122.336 13.2262C122.336 12.4013 122.091 11.8087 121.597 11.4116C121.104 11.0175 120.346 10.8189 119.324 10.8189H115.591V15.6853H119.324Z"
        fill={textColor}
      />
      <path
        d="M138 9.6L132.88 16.278V22.2564H131.365V16.2597L126.245 9.6H128L132.138 15.0591L136.277 9.6H138Z"
        fill={textColor}
      />
      <path
        d="M33.8945 6.40046V1.80751H35.5383C35.8715 1.80751 36.1695 1.86469 36.4322 1.97904C36.6971 2.09126 36.9054 2.25749 37.0571 2.47772C37.2087 2.69794 37.2845 2.96899 37.2845 3.29085C37.2845 3.59366 37.2151 3.85306 37.0763 4.06905C36.9396 4.28504 36.7484 4.45021 36.5027 4.56455C36.2571 4.6789 35.9698 4.73607 35.6408 4.73607H34.686V6.40046H33.8945ZM34.6796 4.11669H35.6216C35.8929 4.11669 36.114 4.03623 36.2848 3.87529C36.4579 3.71436 36.5444 3.50472 36.5444 3.24638C36.5444 2.98381 36.4547 2.78052 36.2752 2.63653C36.0958 2.49042 35.8704 2.41737 35.5992 2.41737H34.6796V4.11669Z"
        fill={textColor}
      />
      <path
        d="M40.8505 6.46399C40.4233 6.46399 40.0538 6.37082 39.7419 6.18447C39.4322 5.99813 39.1918 5.7292 39.021 5.37769C38.8522 5.02618 38.7678 4.60479 38.7678 4.11352C38.7678 3.61801 38.8533 3.19345 39.0242 2.83982C39.1951 2.48619 39.4364 2.21514 39.7483 2.02668C40.0623 1.83822 40.4297 1.74399 40.8505 1.74399C41.2714 1.74399 41.6356 1.83822 41.9432 2.02668C42.2529 2.21514 42.4921 2.48619 42.6609 2.83982C42.8297 3.19345 42.914 3.61801 42.914 4.11352C42.914 4.60479 42.8307 5.02618 42.6641 5.37769C42.4975 5.72708 42.2593 5.99601 41.9496 6.18447C41.6398 6.37082 41.2735 6.46399 40.8505 6.46399ZM40.8505 5.81602C41.1026 5.81602 41.3194 5.75779 41.501 5.64132C41.6826 5.52274 41.8214 5.33957 41.9175 5.09182C42.0158 4.84195 42.0649 4.51903 42.0649 4.12305C42.0649 3.72071 42.0158 3.39249 41.9175 3.13839C41.8193 2.88217 41.6794 2.69371 41.4978 2.57301C41.3162 2.45231 41.1005 2.39196 40.8505 2.39196C40.5985 2.39196 40.3806 2.45336 40.1969 2.57618C40.0153 2.69688 39.8743 2.88534 39.7739 3.14157C39.6735 3.39567 39.6233 3.72283 39.6233 4.12305C39.6233 4.51903 39.6735 4.84195 39.7739 5.09182C39.8743 5.33957 40.0153 5.52274 40.1969 5.64132C40.3806 5.75779 40.5985 5.81602 40.8505 5.81602Z"
        fill={textColor}
      />
      <path
        d="M45.5295 6.40046L44.1773 1.80751H44.9463L45.8852 5.12358L46.8272 1.80751H47.4103L48.3588 5.12358L49.3008 1.80751H50.073L48.7112 6.40046H48.0544L47.1188 3.14474L46.1703 6.40046H45.5295Z"
        fill={textColor}
      />
      <path
        d="M51.729 6.40046V1.80751H54.7922L54.789 2.47136H52.514V3.75777H54.5231V4.41209H52.5076V5.72708L54.8371 5.73026V6.40046H51.729Z"
        fill={textColor}
      />
      <path
        d="M56.7518 6.40046V1.80751H58.6327C58.9873 1.80751 59.2788 1.86257 59.5074 1.97268C59.7381 2.08068 59.909 2.2342 60.0201 2.43325C60.1311 2.63018 60.1867 2.86099 60.1867 3.12568C60.1867 3.37132 60.1461 3.57778 60.0649 3.74506C59.9838 3.91023 59.878 4.04576 59.7477 4.15163C59.6195 4.25539 59.4818 4.34009 59.3344 4.40574L60.2187 6.40046H59.4049L58.607 4.53597H57.5304V6.40046H56.7518ZM57.5304 3.92294H58.4564C58.7512 3.92294 58.9808 3.85518 59.1453 3.71965C59.3119 3.58413 59.3953 3.39567 59.3953 3.15427C59.3953 2.91711 59.3184 2.73394 59.1646 2.60477C59.0108 2.4756 58.8078 2.41101 58.5558 2.41101H57.5304V3.92294Z"
        fill={textColor}
      />
      <path
        d="M62.1125 6.40046V1.80751H65.1757L65.1725 2.47136H62.8976V3.75777H64.9066V4.41209H62.8912V5.72708L65.2206 5.73026V6.40046H62.1125Z"
        fill={textColor}
      />
      <path
        d="M67.1353 6.40046V1.80751C67.1546 1.80751 67.2112 1.80751 67.3052 1.80751C67.3991 1.80751 67.5102 1.80751 67.6384 1.80751C67.7687 1.80751 67.9001 1.80857 68.0325 1.81069C68.1671 1.81069 68.2835 1.81175 68.3818 1.81387C68.9457 1.82445 69.4039 1.9208 69.7564 2.10291C70.1109 2.2829 70.3705 2.54018 70.535 2.87476C70.7016 3.20721 70.7849 3.60848 70.7849 4.07858C70.7849 4.56985 70.6962 4.98806 70.5189 5.33322C70.3438 5.67838 70.0746 5.94307 69.7115 6.1273C69.3505 6.30941 68.8912 6.40046 68.3337 6.40046H67.1353ZM67.9268 5.76202H68.3465C68.6648 5.76202 68.9457 5.70803 69.1892 5.60003C69.4327 5.48992 69.6228 5.31205 69.7596 5.06641C69.8984 4.82078 69.9678 4.49256 69.9678 4.08175C69.9678 3.68366 69.9037 3.36497 69.7756 3.12568C69.6474 2.8864 69.4637 2.71382 69.2245 2.60795C68.9852 2.49995 68.699 2.44595 68.3657 2.44595H67.9268V5.76202Z"
        fill={textColor}
      />
      <path
        d="M75.0907 6.40046V1.80751H76.6832C77.2706 1.80751 77.7096 1.91127 78.0001 2.11879C78.2927 2.32419 78.4391 2.62065 78.4391 3.00816C78.4391 3.24321 78.3782 3.44649 78.2564 3.61801C78.1347 3.78953 77.92 3.93458 77.6124 4.05317C77.8004 4.09764 77.9552 4.15904 78.077 4.23739C78.2009 4.31362 78.297 4.4015 78.3654 4.50103C78.4337 4.59843 78.4818 4.70325 78.5096 4.81548C78.5373 4.92771 78.5512 5.04312 78.5512 5.1617C78.5512 5.58097 78.4038 5.89225 78.109 6.09554C77.8164 6.29882 77.3625 6.40046 76.7473 6.40046H75.0907ZM75.8693 5.78426H76.7569C77.0858 5.78426 77.3347 5.72602 77.5034 5.60956C77.6743 5.49098 77.7598 5.32052 77.7598 5.09817C77.7598 4.93724 77.7181 4.80489 77.6348 4.70113C77.5515 4.59526 77.4383 4.51691 77.2952 4.46609C77.1521 4.41527 76.9918 4.38986 76.8145 4.38986H75.8693V5.78426ZM75.8693 3.76412H76.8145C76.9235 3.76412 77.0292 3.75248 77.1318 3.72918C77.2343 3.70589 77.3272 3.66883 77.4105 3.61801C77.4938 3.56719 77.56 3.49943 77.6092 3.41473C77.6604 3.33003 77.6861 3.22733 77.6861 3.10663C77.6861 2.87581 77.5974 2.70429 77.4201 2.59206C77.245 2.47772 77.0196 2.42054 76.744 2.42054H75.8693V3.76412Z"
        fill={textColor}
      />
      <path
        d="M81.2408 6.40046V4.45656L79.6451 1.80751H80.491L81.6349 3.78318L82.7595 1.80751H83.6118L82.0194 4.45974V6.40046H81.2408Z"
        fill={textColor}
      />
    </svg>
  );
}
