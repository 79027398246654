import { GridColDef } from '@mui/x-data-grid-pro';

import { BalanceSheetCategoryAssetClassesCell } from './components/BalanceSheetCategoryAssetClassesCell';
import { BalanceSheetCategoryNameCell } from './components/BalanceSheetCategoryNameCell';

export function useBalanceSheetCategoriesColumns(): GridColDef[] {
  return [
    {
      field: 'name',
      headerName: 'Balance sheet category',
      flex: 1,
      renderCell: (params) => <BalanceSheetCategoryNameCell {...params} />,
    },
    {
      field: 'assetClasses',
      headerName: 'Associated asset categories',
      flex: 2,
      renderCell: (params) => (
        <BalanceSheetCategoryAssetClassesCell {...params} />
      ),
    },
  ];
}
