import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { Card } from '@/components/layout/Card/Card';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { EstateTaxSummaryPanel_NodeFragment } from '@/modules/summaryPanels/EstateTaxSummaryPanel/graphql/EstateTaxSummaryPanel.generated';
import {
  getTaxableGiftsMade,
  getTotalGiftTaxDue,
} from '@/modules/summaryPanels/EstateTaxSummaryPanel/utils';
import { GiftTaxMiniTable } from '@/modules/taxes/components/GiftTaxMiniTable/GiftTaxMiniTable';

interface GiftTaxTotalsCardProps {
  nodes: EstateTaxSummaryPanel_NodeFragment[];
  lifetimeExemptionAppliedTowardsGiftTax?: Decimal | null;
}

export const GiftTaxTotalsCard = ({
  nodes,
  lifetimeExemptionAppliedTowardsGiftTax,
}: GiftTaxTotalsCardProps) => {
  const { federalEstateTaxPercent } = useIRSConstants();

  const giftTax = useMemo(() => {
    return getTotalGiftTaxDue(nodes);
  }, [nodes]);

  const taxableGiftsMade = useMemo(
    () =>
      getTaxableGiftsMade(
        giftTax,
        federalEstateTaxPercent,
        lifetimeExemptionAppliedTowardsGiftTax
      ),
    [federalEstateTaxPercent, giftTax, lifetimeExemptionAppliedTowardsGiftTax]
  );

  return (
    <Card variant="filled" sx={{ borderRadius: '6px', padding: 2 }}>
      <GiftTaxMiniTable
        taxableGiftsMade={taxableGiftsMade}
        lifetimeExemptionAppliedTowardGiftTax={
          lifetimeExemptionAppliedTowardsGiftTax ?? new Decimal(0)
        }
        giftTax={giftTax}
      />
    </Card>
  );
};
