import { HouseholdOnboardingStepKind } from '@/types/schema';

export enum HouseholdOnboardingGroupKey {
  EstateOverview = 'EstateOverview',
  EstateWaterfall = 'EstateWaterfall',
  Proposals = 'Proposals',
  Presentations = 'Presentations',
}

export const HOUSEHOLD_ONBOARDING_SIDEBAR_STEP_GROUPS = {
  [HouseholdOnboardingGroupKey.EstateOverview]: [
    HouseholdOnboardingStepKind.AddEntities,
    HouseholdOnboardingStepKind.CreateWealthProfile,
    HouseholdOnboardingStepKind.SummarizeDocument,
    HouseholdOnboardingStepKind.SpecifyBeneficiaries,
  ],
  [HouseholdOnboardingGroupKey.EstateWaterfall]: [
    HouseholdOnboardingStepKind.ReviewExemption,
    HouseholdOnboardingStepKind.SpecifyDispositiveProvisions,
    HouseholdOnboardingStepKind.CustomizeTaxCalc,
  ],
  [HouseholdOnboardingGroupKey.Proposals]: [
    HouseholdOnboardingStepKind.ModelStrategy,
    HouseholdOnboardingStepKind.CreateHypotheticalWaterfall,
  ],
  [HouseholdOnboardingGroupKey.Presentations]: [
    HouseholdOnboardingStepKind.GeneratePresentation,
  ],
};

export const HOUSEHOLD_ONBOARDING_SIDEBAR_GROUP_HEADERS: Record<
  HouseholdOnboardingGroupKey,
  string
> = {
  [HouseholdOnboardingGroupKey.EstateOverview]: 'Estate overview',
  [HouseholdOnboardingGroupKey.EstateWaterfall]: 'Estate waterfall',
  [HouseholdOnboardingGroupKey.Proposals]: 'Proposals',
  [HouseholdOnboardingGroupKey.Presentations]: 'Presentations',
};

export const HOUSEHOLD_ONBOARDING_SIDEBAR_STEP_COPY: Record<
  HouseholdOnboardingStepKind,
  string
> = {
  [HouseholdOnboardingStepKind.AddEntities]: 'Add entities',
  [HouseholdOnboardingStepKind.SummarizeDocument]: 'Summarize a document',
  [HouseholdOnboardingStepKind.CreateWealthProfile]: 'Create wealth profile',
  [HouseholdOnboardingStepKind.SpecifyBeneficiaries]:
    'Specify current beneficiaries',
  [HouseholdOnboardingStepKind.ReviewExemption]: 'Review exemption used',
  [HouseholdOnboardingStepKind.SpecifyDispositiveProvisions]:
    'Set dispositive provisions',
  [HouseholdOnboardingStepKind.CustomizeTaxCalc]: 'Customize tax settings',
  [HouseholdOnboardingStepKind.ModelStrategy]: 'Model a new strategy',
  [HouseholdOnboardingStepKind.CreateHypotheticalWaterfall]:
    'Create hypotheticals',
  [HouseholdOnboardingStepKind.GeneratePresentation]:
    'Auto-generate presentation',
};
