import { Box, Stack, Typography, useTheme } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { EyeOffIcon } from '@/components/icons/EyeOffIcon';
import { SwitchVertical01Icon } from '@/components/icons/SwitchVertical01Icon';
import { GRAY } from '@/styles/tokens/colors';

import { useGuardedClientPresentationDesignerContext } from '../contexts/clientPresentationDesigner.context';

export function SlideManagementMenu() {
  const theme = useTheme();
  const {
    showHiddenItems,
    setShowHiddenItems,
    isReorderMode,
    setIsReorderMode,
  } = useGuardedClientPresentationDesignerContext();

  const isEditing = showHiddenItems || isReorderMode;

  return (
    <Box
      sx={{
        backgroundColor: GRAY[100],
        p: 1.5,
        boxShadow: theme.palette.shadows.mdYInvert,
      }}
    >
      <Typography display="block" variant="h6" mb={1}>
        Slide management
      </Typography>
      {!isEditing && (
        <Stack direction="row" justifyContent="space-between" spacing={1.5}>
          <Button
            size="sm"
            sx={{ flex: 1 }}
            variant="secondary"
            startIcon={EyeOffIcon}
            onClick={() => setShowHiddenItems(true)}
          >
            View hidden
          </Button>
          <Button
            size="sm"
            sx={{ flex: 1 }}
            variant="secondary"
            startIcon={SwitchVertical01Icon}
            onClick={() => setIsReorderMode(true)}
          >
            Reorder
          </Button>
        </Stack>
      )}
      {isEditing && (
        <Button
          size="sm"
          variant="primary"
          fullWidth
          onClick={() => {
            setShowHiddenItems(false);
            setIsReorderMode(false);
          }}
        >
          Done
        </Button>
      )}
    </Box>
  );
}
