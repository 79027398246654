// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetIntegrationEntitiesQueryVariables = Types.Exact<{
  optionsLike?: Types.InputMaybe<Types.IntegrationEntityWhereInput>;
  selectedOptionsLike?: Types.InputMaybe<Types.IntegrationEntityWhereInput>;
  first: Types.Scalars['Int']['input'];
}>;


export type GetIntegrationEntitiesQuery = { __typename?: 'Query', queryData: { __typename?: 'IntegrationEntityConnection', totalCount: number, edges?: Array<{ __typename?: 'IntegrationEntityEdge', node?: { __typename?: 'IntegrationEntity', id: string, integrationID: string, name: string, externalKind?: Types.IntegrationEntityExternalKind | null, value?: CurrencyUSD | null, integrationClient?: { __typename?: 'IntegrationClient', id: string, name: string } | null } | null } | null> | null }, selectedData: { __typename?: 'IntegrationEntityConnection', totalCount: number, edges?: Array<{ __typename?: 'IntegrationEntityEdge', node?: { __typename?: 'IntegrationEntity', id: string, integrationID: string, name: string, integrationClient?: { __typename?: 'IntegrationClient', id: string, name: string } | null } | null } | null> | null } };


export const GetIntegrationEntitiesDocument = gql`
    query GetIntegrationEntities($optionsLike: IntegrationEntityWhereInput, $selectedOptionsLike: IntegrationEntityWhereInput, $first: Int!) {
  queryData: integrationEntities(where: $optionsLike, first: $first) {
    totalCount
    edges {
      node {
        id
        integrationID
        name
        externalKind
        value
        integrationClient {
          id
          name
        }
      }
    }
  }
  selectedData: integrationEntities(where: $selectedOptionsLike) {
    totalCount
    edges {
      node {
        id
        integrationID
        name
        integrationClient {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetIntegrationEntitiesQuery__
 *
 * To run a query within a React component, call `useGetIntegrationEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationEntitiesQuery({
 *   variables: {
 *      optionsLike: // value for 'optionsLike'
 *      selectedOptionsLike: // value for 'selectedOptionsLike'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetIntegrationEntitiesQuery(baseOptions: Apollo.QueryHookOptions<GetIntegrationEntitiesQuery, GetIntegrationEntitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntegrationEntitiesQuery, GetIntegrationEntitiesQueryVariables>(GetIntegrationEntitiesDocument, options);
      }
export function useGetIntegrationEntitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntegrationEntitiesQuery, GetIntegrationEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntegrationEntitiesQuery, GetIntegrationEntitiesQueryVariables>(GetIntegrationEntitiesDocument, options);
        }
export function useGetIntegrationEntitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIntegrationEntitiesQuery, GetIntegrationEntitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIntegrationEntitiesQuery, GetIntegrationEntitiesQueryVariables>(GetIntegrationEntitiesDocument, options);
        }
export type GetIntegrationEntitiesQueryHookResult = ReturnType<typeof useGetIntegrationEntitiesQuery>;
export type GetIntegrationEntitiesLazyQueryHookResult = ReturnType<typeof useGetIntegrationEntitiesLazyQuery>;
export type GetIntegrationEntitiesSuspenseQueryHookResult = ReturnType<typeof useGetIntegrationEntitiesSuspenseQuery>;
export type GetIntegrationEntitiesQueryResult = Apollo.QueryResult<GetIntegrationEntitiesQuery, GetIntegrationEntitiesQueryVariables>;
export const Operations = {
  Query: {
    GetIntegrationEntities: 'GetIntegrationEntities' as const
  }
}