import { Box, Skeleton, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import { isNull } from 'lodash';

import {
  TenantPresentationConfigurationContext,
  useUnguardedTenantPresentationConfigurationContext,
} from '../context/tenantPresentationConfiguration.context';

function getFooterDisclaimers(
  variant: DisclaimerFooterVariant,
  legalDisclaimers?: TenantPresentationConfigurationContext['legalDisclaimers']
) {
  // the disclaimer configuration is null if it hasn't been persisted into the context yet
  // (it will always ultimately exist in the context, but it might not be there yet)
  if (isNull(legalDisclaimers)) {
    return {
      heading: <Skeleton sx={{ display: 'inline-block' }} width={200} />,
      body: (
        <Skeleton sx={{ display: 'inline-block' }} width={400} height={25} />
      ),
    };
  }

  // something is defined; use what's defined and nothing else. it's probably okay for someone to just define
  // a header and not a body
  if (
    variant === 'standard' &&
    (legalDisclaimers?.presentationFooterDisclaimerBody ||
      legalDisclaimers?.presentationFooterDisclaimerTitle)
  ) {
    return {
      heading: legalDisclaimers?.presentationFooterDisclaimerTitle,
      body: legalDisclaimers?.presentationFooterDisclaimerBody,
    };
  }

  if (
    variant === 'cover' &&
    (legalDisclaimers?.presentationCoverDisclaimerBody ||
      legalDisclaimers?.presentationCoverDisclaimerTitle)
  ) {
    return {
      heading: legalDisclaimers?.presentationCoverDisclaimerTitle,
      body: legalDisclaimers?.presentationCoverDisclaimerBody,
    };
  }

  return {
    heading: 'For illustrative purposes only',
    body: 'This presentation is a hypothetical analysis used for illustrative purposes and should not be construed as legal or tax advice. Neither the information nor any opinion expressed constitutes a solicitation for the purchase or sale of any security or other instrument.',
  };
}

type DisclaimerFooterVariant = 'cover' | 'standard';

export interface DisclaimerFooterProps {
  contentSx?: SystemStyleObject<Theme>;
  // we allow users to define different copy for the cover slide
  variant: DisclaimerFooterVariant;
}

export function DisclaimerFooter({
  variant,
  contentSx = {},
}: DisclaimerFooterProps) {
  const { legalDisclaimers } =
    useUnguardedTenantPresentationConfigurationContext();

  const { heading, body } = getFooterDisclaimers(variant, legalDisclaimers);

  return (
    <Box>
      {heading && (
        <Typography
          component="div"
          variant="h6"
          sx={(theme) => ({
            fontWeight: theme.typography.fontWeightBold,
            textTransform: 'uppercase',
            fontSize: '10px',
            ...(contentSx || {}),
          })}
        >
          {heading}
        </Typography>
      )}
      {body && (
        <Typography
          component="div"
          variant="body1"
          sx={{ fontSize: '11px', lineHeight: 1.25, ...contentSx }}
        >
          {body}
        </Typography>
      )}
    </Box>
  );
}
