import { Box, Stack, SxProps, Typography } from '@mui/material';
import {
  GridToolbarQuickFilter,
  GridToolbarQuickFilterProps,
} from '@mui/x-data-grid-pro';

import { Card } from '@/components/layout/Card/Card';
import { COLORS } from '@/styles/tokens/colors';

export interface SearchSelectionToolbarProps {
  label: React.ReactNode;
  quickFilterProps?: Partial<GridToolbarQuickFilterProps>;
  // details adds additional information to the right of searchbar
  details?: React.ReactNode;
}

/**
 * @description When using the SearchSelectionToolbar component, make sure to pass in these styles
 * to the parent DataTable to normalize the styling to interact nicely with the SearchSelectionToolbar
 */
export const SEARCH_TOOLBAR_STYLES: SxProps = {
  // necessary so only the custom toolbar has the curved top corners
  '& .MuiDataGrid-columnHeaders': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
};

export function SearchSelectionToolbar({
  label,
  details,
  quickFilterProps = {},
}: SearchSelectionToolbarProps) {
  return (
    <Card
      variant="filled"
      sx={{
        borderBottom: `1px solid ${COLORS.PRIMITIVES.WHITE}`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        p: 3,
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="column">
          <Box maxWidth="350px">
            <Typography variant="label" sx={{ pb: 0.5 }}>
              {label}
            </Typography>
            <GridToolbarQuickFilter
              debounceMs={500}
              sx={{
                width: '100%',
                background: COLORS.PRIMITIVES.WHITE,
                padding: 0,
              }}
              variant="outlined"
              placeholder=""
              {...quickFilterProps}
            />
          </Box>
        </Stack>
        {details}
      </Stack>
    </Card>
  );
}
