import { EntityKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { EntityType } from '../types/EntityType';

export function getEntityTypeFromEntityKind(
  entityKind: EntityKind
): EntityType {
  switch (entityKind) {
    case EntityKind.GratTrust:
      return 'grat';
    case EntityKind.SlatTrust:
      return 'slat';
    case EntityKind.IlitTrust:
      return 'ilit';
    case EntityKind.IrrevocableTrust:
      return 'irrevocable-trust';
    case EntityKind.RevocableTrust:
      return 'revocable-trust';
    case EntityKind.QprtTrust:
      return 'qprt';
    case EntityKind.IndividualPersonalAccount:
      return 'individual-account';
    case EntityKind.JointPersonalAccount:
      return 'joint-account';
    case EntityKind.RetirementPersonalAccount:
      return 'retirement-account';
    case EntityKind.QualifiedTuitionPersonalAccount:
      return 'qualified-tuition-account';
    case EntityKind.CustodialPersonalAccount:
      return 'custodial-account';
    case EntityKind.CltTrust:
      return 'clt';
    case EntityKind.CrtTrust:
      return 'crt';
    case EntityKind.DonorAdvisedFund:
      return 'daf';
    case EntityKind.PrivateFoundation:
      return 'private-foundation';
    case EntityKind.SoleProprietorshipBusinessEntity:
      return 'sole-proprietorship';
    case EntityKind.CcorpBusinessEntity:
      return 'c-corp';
    case EntityKind.ScorpBusinessEntity:
      return 's-corp';
    case EntityKind.LlcBusinessEntity:
      return 'llc';
    case EntityKind.LpBusinessEntity:
      return 'lp';
    case EntityKind.GpBusinessEntity:
      return 'gp';
    case EntityKind.InsurancePersonalAccount:
      return 'insurance-account';
    default:
      throw new UnreachableError({
        case: entityKind,
        message: 'Unexpected entity kind',
      });
  }
}
