import { Skeleton } from '@mui/material';
import { noop } from 'lodash';

import { SidebarSlideElement } from '../SidebarSlideElement';
import { SidebarGroup } from './SidebarGroup';

export function SidebarGroupLoadingSkeleton() {
  return (
    <SidebarGroup isHidden={false} label={<Skeleton width={100} height={20} />}>
      <SidebarSlideElement
        slideId={'loading'}
        label={<Skeleton width={100} height={20} />}
        isActive={false}
        onSelectSlide={noop}
      />
      <SidebarSlideElement
        slideId={'loading'}
        label={<Skeleton width={100} height={20} />}
        isActive={false}
        onSelectSlide={noop}
      />
      <SidebarSlideElement
        slideId={'loading'}
        label={<Skeleton width={100} height={20} />}
        isActive={false}
        onSelectSlide={noop}
      />
    </SidebarGroup>
  );
}
