import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareTypeaheadSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';
import { Alert } from '@/components/notifications/Alert/Alert';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { getNodes } from '@/utils/graphqlUtils';

import {
  useArchetypeClientsQuery,
  useCopyArchetypeClientMutation,
} from './graphql/ClientImportExport.generated';

function useCopyArchetypeOptions() {
  const queryProps = useArchetypeClientsQuery();
  const { data } = queryProps;

  const options = getNodes(data?.archetypeHouseholds).map((household) => ({
    value: household.id,
    display: household.displayName,
  }));

  return {
    ...queryProps,
    options,
  };
}

interface CopyArchetypeFormShape {
  householdId: string;
}

export function ClientArchetypeCopy() {
  const [importedClientLink, setImportedClientLink] = useState<string | null>(
    null
  );
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { options, loading: dataLoading } = useCopyArchetypeOptions();

  const [copyHousehold] = useCopyArchetypeClientMutation({
    onError: (err) => {
      showFeedback('Failed to copy client');
      reportError('could not copy client', err);
    },
  });

  const formMethods = useForm<CopyArchetypeFormShape>();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  async function onClientCopy(formValues: CopyArchetypeFormShape) {
    return copyHousehold({
      variables: {
        id: formValues.householdId,
      },
      onCompleted: (data) => {
        const createdClientId = data.copyArchetypeHousehold.id;

        setImportedClientLink(
          getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW, {
            householdId: createdClientId,
          })
        );
      },
    });
  }

  return (
    <Box>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="end"
        noValidate
        component="form"
        onSubmit={handleSubmit(onClientCopy)}
      >
        <Box sx={{ flexGrow: 1 }}>
          <FormAwareTypeaheadSelectInput<CopyArchetypeFormShape>
            label="Select archetype client"
            fieldName={'householdId'}
            required
            options={options}
            disabled={isSubmitting || dataLoading}
            control={control}
          />
        </Box>
        <Box>
          <Button
            disabled={isSubmitting}
            variant="primary"
            size="sm"
            type="submit"
          >
            Copy client
          </Button>
        </Box>
      </Stack>
      {importedClientLink && (
        <Alert size="sm" severity="success">
          <NavLink to={importedClientLink}>
            <Typography variant="body1">
              Go to the newly-created client {'→'}
            </Typography>
          </NavLink>
        </Alert>
      )}
    </Box>
  );
}
