import { Box, Stack, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { useLiabilityPaymentCalculationQuery } from './graphql/LiabilityModal.generated';
import { LiabilityFormShape } from './LiabilityModal.constants';

export function CalculatedLiabilityPaymentDetails() {
  const { control } = useFormContext<LiabilityFormShape>();
  const [
    interestRate,
    paymentFrequency,
    paymentKind,
    outstandingBalance,
    paymentAmount,
    termStartDate,
    termEndDate,
  ] = useWatch({
    name: [
      'interestRate',
      'paymentFrequency',
      'paymentKind',
      'outstandingBalance',
      'paymentAmount',
      'termStartDate',
      'termEndDate',
    ],
    control,
  });

  const hasAllRequiredFields = [
    termStartDate,
    termEndDate,
    interestRate,
    paymentFrequency,
    paymentKind,
    outstandingBalance,
    paymentAmount,
  ].every(Boolean);

  const { data, error } = useLiabilityPaymentCalculationQuery({
    // no-cache because there's no ID and this is live-calculated
    fetchPolicy: 'no-cache',
    variables: {
      params: {
        interestRate: interestRate!,
        paymentFrequency: paymentFrequency,
        paymentKind: paymentKind,
        principalAmount: outstandingBalance!,
        termStart: termStartDate!,
        termEnd: termEndDate,
        paymentAmount,
      },
    },
    skip: !hasAllRequiredFields,
  });

  function getCalculatedPaymentsCopy() {
    if (!paymentFrequency || !data) {
      return EMPTY_CONTENT_HYPHEN;
    }

    if (error && !data) {
      return (
        <Box sx={{ color: COLORS.FUNCTIONAL.ERROR.DEFAULT }}>
          Error calculating payment amounts
        </Box>
      );
    }

    const { liabilityPaymentCalculation: calculatedData } = data;
    return formatCurrencyNoDecimals(calculatedData.finalPaymentAmount);
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      gap={8}
    >
      <Box flexShrink={0}>
        <Typography variant="h5" component="div">
          Calculated balloon payment
        </Typography>
      </Box>
      <Box flexGrow={1}>
        <Typography
          variant="h5"
          sx={{ fontWeight: FONT_WEIGHTS.medium, textAlign: 'right' }}
          component="div"
        >
          {getCalculatedPaymentsCopy()}
        </Typography>
      </Box>
    </Stack>
  );
}
