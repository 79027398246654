import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import {
  AccessParameterFrequency,
  BeneficiaryDistributionDistributionFrequency,
  BeneficiaryDistributionParameterKind,
  BeneficiaryLevel,
} from '@/types/schema';

/** DISTRIBUTION FREQUENCY */
export const BENEFICIARY_DISTRIBUTION_FREQUENCY_DISPLAY_NAMES: Record<
  BeneficiaryDistributionDistributionFrequency,
  string
> = {
  [BeneficiaryDistributionDistributionFrequency.Monthly]: 'Monthly',
  [BeneficiaryDistributionDistributionFrequency.Quarterly]: 'Quarterly',
  [BeneficiaryDistributionDistributionFrequency.Semiannually]: 'Semiannually',
  [BeneficiaryDistributionDistributionFrequency.Annually]: 'Annually',
};

export const BENEFICIARY_DISTRIBUTION_FREQUENCY_DISPLAY_NAMES_V2: Record<
  AccessParameterFrequency,
  string
> = {
  [AccessParameterFrequency.Annually]: 'Annually',
  [AccessParameterFrequency.Semiannually]: 'Semiannually',
  [AccessParameterFrequency.Quarterly]: 'Quarterly',
  [AccessParameterFrequency.Monthly]: 'Monthly',
};

export const BENEFICIARY_DISTRIBUTION_FREQUENCY_ITEMS: SelectInputOption<BeneficiaryDistributionDistributionFrequency>[] =
  [
    BeneficiaryDistributionDistributionFrequency.Monthly,
    BeneficiaryDistributionDistributionFrequency.Quarterly,
    BeneficiaryDistributionDistributionFrequency.Semiannually,
    BeneficiaryDistributionDistributionFrequency.Annually,
  ].map((value) => ({
    display: BENEFICIARY_DISTRIBUTION_FREQUENCY_DISPLAY_NAMES[value],
    value,
  }));

export const BENEFICIARY_DISTRIBUTION_FREQUENCY_ITEMS_V2: SelectInputOption<AccessParameterFrequency>[] =
  [
    AccessParameterFrequency.Annually,
    AccessParameterFrequency.Semiannually,
    AccessParameterFrequency.Quarterly,
    AccessParameterFrequency.Monthly,
  ].map((value) => ({
    display: BENEFICIARY_DISTRIBUTION_FREQUENCY_DISPLAY_NAMES[value],
    value,
  }));

/** DISTRIBUTION FREQUENCY */
export const BENEFICIARY_LEVEL_DISPLAY_NAMES: Record<BeneficiaryLevel, string> =
  {
    [BeneficiaryLevel.Primary]: 'First',
    [BeneficiaryLevel.Secondary]: 'Second',
    [BeneficiaryLevel.Tertiary]: 'Third',
    [BeneficiaryLevel.Other]: 'Other',
  };
export const BENEFICIARY_LEVEL_ITEMS: SelectInputOption<BeneficiaryLevel>[] = [
  BeneficiaryLevel.Primary,
  BeneficiaryLevel.Secondary,
  BeneficiaryLevel.Tertiary,
  BeneficiaryLevel.Other,
].map((value) => ({
  display: BENEFICIARY_LEVEL_DISPLAY_NAMES[value],
  value,
}));

/** DISTRIBUTION PARAMETERS */

export const BENEFICIARY_DISTRIBUTION_PARAMETER_DISPLAY_NAMES_V1: Record<
  BeneficiaryDistributionParameterKind,
  string
> = {
  [BeneficiaryDistributionParameterKind.HealthEducationMaintenanceSupport]:
    'Health education maintenance support',
  [BeneficiaryDistributionParameterKind.MandatoryDistribution]:
    'Mandatory distribution',
  [BeneficiaryDistributionParameterKind.UnderFullDiscretionOfTrustee]:
    'Under full discretion of trustee',
  [BeneficiaryDistributionParameterKind.UponReachingAge]: 'Upon reaching age',
  [BeneficiaryDistributionParameterKind.Other]: 'Other',
};

export const BENEFICIARY_DISTRIBUTION_PARAMETER_ITEMS_V1: SelectInputOption<BeneficiaryDistributionParameterKind>[] =
  [
    BeneficiaryDistributionParameterKind.HealthEducationMaintenanceSupport,
    BeneficiaryDistributionParameterKind.MandatoryDistribution,
    BeneficiaryDistributionParameterKind.UnderFullDiscretionOfTrustee,
    BeneficiaryDistributionParameterKind.UponReachingAge,
    BeneficiaryDistributionParameterKind.Other,
  ].map((value) => ({
    display: BENEFICIARY_DISTRIBUTION_PARAMETER_DISPLAY_NAMES_V1[value],
    value,
  }));
