// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { TrusteeFragmentDoc } from '../../../trustees/graphql/trustees.fragments.generated';
export type TrustTrusteesList_SuccessorTrusteeFragment = { __typename?: 'Trustee', id: string, level?: Types.TrusteeLevel | null, notes?: string | null, duties?: Array<{ __typename?: 'TrusteeDuty', id: string, kind: Types.TrusteeDutyKind }> | null, individual?: { __typename?: 'ClientProfile', displayName: string, id: string } | null, organization?: { __typename?: 'ClientOrganization', id: string, name: string } | null };

export type TrustTrusteesList_TrusteeFragment = { __typename?: 'Trustee', id: string, level?: Types.TrusteeLevel | null, notes?: string | null, duties?: Array<{ __typename?: 'TrusteeDuty', id: string, kind: Types.TrusteeDutyKind }> | null, individual?: { __typename?: 'ClientProfile', displayName: string, id: string } | null, organization?: { __typename?: 'ClientOrganization', id: string, name: string } | null };

export const TrustTrusteesList_SuccessorTrusteeFragmentDoc = gql`
    fragment TrustTrusteesList_SuccessorTrustee on Trustee {
  id
  level
  notes
  duties {
    id
    kind
  }
  ...Trustee
}
    ${TrusteeFragmentDoc}`;
export const TrustTrusteesList_TrusteeFragmentDoc = gql`
    fragment TrustTrusteesList_Trustee on Trustee {
  id
  level
  notes
  duties {
    id
    kind
  }
  ...Trustee
}
    ${TrusteeFragmentDoc}`;
export const Operations = {
  Fragment: {
    TrustTrusteesList_SuccessorTrustee: 'TrustTrusteesList_SuccessorTrustee' as const,
    TrustTrusteesList_Trustee: 'TrustTrusteesList_Trustee' as const
  }
}