import { Box } from '@mui/material';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

type ScalableSlideHeightProps = PropsWithChildren<{
  containerWidthPx: number;
  contentHeightPx: number;
  aspectRatio: number;
  scaleFactor?: number;
}>;

/**
 * @description This component will zoom the content to fit a page if the content is too tall.
 */
export function ScalableSlideHeight({
  children,
  containerWidthPx,
  contentHeightPx,
  aspectRatio,
  scaleFactor = 100,
}: ScalableSlideHeightProps) {
  const [zoomPercent, setZoomPercent] = useState<number | undefined>(undefined);

  const containerHeightPx = useMemo(() => {
    if (containerWidthPx) {
      return containerWidthPx * (1 / aspectRatio);
    }
    return;
  }, [aspectRatio, containerWidthPx]);

  useEffect(() => {
    if (containerHeightPx && contentHeightPx > containerHeightPx) {
      setZoomPercent(
        Math.floor((containerHeightPx / contentHeightPx) * scaleFactor)
      );
    }
  }, [containerHeightPx, contentHeightPx, scaleFactor]);

  return (
    <Box sx={zoomPercent ? { zoom: `${zoomPercent}%` } : undefined}>
      {children}
    </Box>
  );
}
