import { ControlButton } from '@/components/diagrams/FlowChart/components/Controls/ControlButton';
import { Settings03Icon } from '@/components/icons/Settings03Icon';
import { useModalState } from '@/hooks/useModalState';

import {
  EditGraphVizViewModal,
  EditGraphVizViewModalProps,
} from './EditGraphVizViewModal';

export type GraphVizViewUpdateButtonProps<TabValue extends string> = Omit<
  EditGraphVizViewModalProps<TabValue>,
  'isOpen' | 'onClose'
>;

export const GraphVizViewUpdateButton = <TabValue extends string>(
  props: GraphVizViewUpdateButtonProps<TabValue>
) => {
  const [{ isModalOpen }, { closeModal, openModal }] = useModalState();

  return (
    <>
      <ControlButton
        variant="secondary-filled"
        aria-label="update"
        startIcon={Settings03Icon}
        onClick={openModal}
      >
        Modify attributes
      </ControlButton>
      <EditGraphVizViewModal
        isOpen={isModalOpen}
        onClose={closeModal}
        {...props}
      />
    </>
  );
};
