// eslint-disable-next-line no-restricted-imports
import { useCallback } from 'react';
import { useNavigate as useReactRouterNavigate } from 'react-router-dom';

import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

export function useNavigateToRoute() {
  const navigateReactRouter = useReactRouterNavigate();
  const navigate = useCallback(
    (...args: Parameters<typeof getCompletePathFromRouteKey>) => {
      return navigateReactRouter(getCompletePathFromRouteKey(...args));
    },
    [navigateReactRouter]
  );

  return { navigate };
}
