import { useCallback } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';

import { CopyDispositionsResult } from '../CopyDispositiveProvisionsModal/CopyDispositiveProvisionsModal.types';
import { DispositiveProvisionsFormShape } from '../DispositiveProvisionsForm.types';

export function useHandleCopyDispositionsResult() {
  const { showFeedback } = useFeedback();
  const { setValue } = useFormContext<DispositiveProvisionsFormShape>();

  return useCallback(
    function handleCopyDispositionsResult(
      result: CopyDispositionsResult | null
    ) {
      if (!result) return;

      setValue('dispositiveProvisions.recipients', result.recipients);
      setValue('dispositiveProvisions.templateId', result.templateId);
      return showFeedback(`Dispositions copied`, { variant: 'success' });
    },
    [setValue, showFeedback]
  );
}
