import Decimal from 'decimal.js';
import React, { useMemo } from 'react';

import {
  DataTable_LEGACY,
  DataTable_LEGACYProps,
} from '@/components/tables/DataTable_LEGACY/DataTable_LEGACY';
import { Column } from '@/components/tables/DataTable_LEGACY/tableTypes';
import { AssetValuationAsOfModal } from '@/modules/assetValuation/AssetValuationAsOfModal/AssetValuationAsOfModal';
import { ValuationReasonLabel } from '@/modules/assetValuation/AssetValuationReasonLabel/AssetValuationReasonLabel';
import {
  AssetValuationDesignerAccountFragment,
  AssetValuationV2Fragment,
  useAssetValuationsQuery,
} from '@/modules/assetValuation/graphql/entityValuationQueries.generated';
import { ImpliedSurplus } from '@/modules/content/tooltipContent/ImpliedSurplus';
import { RemainderValue } from '@/modules/content/tooltipContent/RemainderValue';
import {
  GratAnnuityFragment,
  GratPerformanceFragment,
} from '@/modules/entities/gratTrusts/graphql/GratTrust.generated';
import {
  AssetValuationV2OrderField,
  AssetValuationV2ValuationReason,
  OrderDirection,
} from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { getNodes } from '@/utils/graphqlUtils';

function getColumns(isCompleted: boolean): Column[] {
  return [
    {
      field: 'updatedOn',
      headerName: 'Updated on',
      flex: 1,
      width: 150,
    },
    {
      field: 'current',
      headerName: 'GRAT value',
      width: 175,
      flex: 1,
      cellFormat: 'BoldText',
    },
    {
      field: 'remainingAnnuityPayments',
      headerName: 'Remaining annuity payments',
      width: 175,
      flex: 1,
    },
    {
      field: 'impliedSurplus',
      headerName: isCompleted ? 'Remainder value' : 'Implied surplus',
      width: 175,
      flex: 1,
      contextualHelp: isCompleted ? <RemainderValue /> : <ImpliedSurplus />,
    },
    {
      field: 'updatedBy',
      headerName: 'Updated by',
      width: 150,
    },
    {
      field: 'valuationReason',
      headerName: 'Valuation reason',
      flex: 1,
      width: 80,
    },
    {
      field: 'actions',
      headerName: '',
      align: 'center',
      width: 64,
      minWidth: 64,
      maxWidth: 64,
      cellFormat: 'ActionIndicator',
      sortable: false,
    },
  ];
}

function getAnnuityPaymentsSum(annuities: GratAnnuityFragment[]) {
  return annuities.reduce((acc, annuity) => {
    return acc.plus(annuity.computedPaymentAmount ?? new Decimal(0));
  }, new Decimal(0));
}

function remainingAnnuityPaymentsAtRevaluation(
  completedAnnuityPayments: GratAnnuityFragment[],
  annuities: GratAnnuityFragment[]
): Decimal {
  const allAnnuityPaymentsSum = getAnnuityPaymentsSum(annuities);
  const completedAnnuityPaymentsSum = getAnnuityPaymentsSum(
    completedAnnuityPayments
  );
  return allAnnuityPaymentsSum.minus(completedAnnuityPaymentsSum);
}

function mapDataToRows(
  account: AssetValuationDesignerAccountFragment | null,
  annuities: GratAnnuityFragment[],
  distributionAssetValuation: Decimal | undefined
) {
  if (!account?.valuations) {
    return [];
  }

  const res: Record<string, unknown>[] = [];
  const completedAnnuityPayments: GratAnnuityFragment[] = [];

  account.valuations?.edges?.forEach((edge) => {
    if (!edge?.node) {
      return;
    }

    const valuation = edge.node;
    const valuationReason = valuation.valuationReason;

    const relatedAnnuityPayment = annuities.find((annuity) => {
      return annuity.associatedAssetValuation?.id === valuation.id;
    });

    // it's possible (common, even) to have a valuation without a related annuity payment if the revaluation happened
    // not as part of an annuity
    if (relatedAnnuityPayment) {
      completedAnnuityPayments.push(relatedAnnuityPayment);
    }

    const currentValue = valuation.valuationValue ?? new Decimal(0);

    const remainingAnnuityPayments = remainingAnnuityPaymentsAtRevaluation(
      completedAnnuityPayments,
      annuities
    );

    const impliedSurplus = currentValue.minus(remainingAnnuityPayments);

    // our "distribution asset valuation" edge points to the value that was distributed, but we show the "Remainder distribution"
    // valuation reason label on the row in the performance table that shows the trust valuation going to zero.
    const displayImpliedSurplus = (() => {
      if (
        valuationReason ===
        AssetValuationV2ValuationReason.GratRemainderDistribution
      ) {
        if (!distributionAssetValuation) {
          diagnostics.error(
            'Invalid remainder distribution without defined distribution valuation',
            new Error('missing required defined distribution valuation')
          );
          return new Decimal(0);
        }
        return distributionAssetValuation;
      }

      return impliedSurplus.isNegative() ? new Decimal(0) : impliedSurplus;
    })();

    res.push({
      id: valuation.id,
      updatedOn: {
        lineOne: formatDateToMonDDYYYY(valuation.effectiveDate),
        value: valuation.effectiveDate,
      },
      current: formatCurrency(currentValue),
      remainingAnnuityPayments: formatCurrency(remainingAnnuityPayments),
      impliedSurplus: formatCurrency(displayImpliedSurplus),
      updatedBy: valuation.user.displayName,
      valuationReason: {
        lineOne: (
          <>
            {valuationReason && (
              <ValuationReasonLabel reason={valuationReason} />
            )}
          </>
        ),

        value: valuationReason,
      },
    });
  });
  return res.reverse();
}

interface Props {
  entityId: string;
  grat: GratPerformanceFragment | null;
  isCompleted: boolean;
}

export function GratPerformanceTable({
  grat,
  entityId,
  isCompleted = false,
}: Props) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedAssetValuationId, setSelectedAssetValuationId] =
    React.useState<string>('');

  const { data } = useAssetValuationsQuery({
    variables: {
      entityId,
      orderBy: {
        // we use asc here because we reverse the data in the mapDataToRows function
        // this is because the data is returned in the correct order sorted by
        // effective date and created at when we use asc
        direction: OrderDirection.Asc,
        field: AssetValuationV2OrderField.EffectiveDate,
      },
    },
  });

  const columns = useMemo(() => {
    return getColumns(isCompleted);
  }, [isCompleted]);

  if (!grat) {
    return null;
  }

  const onRowClick: DataTable_LEGACYProps['onRowClick'] = (params) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument -- linter refactor / legacy table stuff
    setSelectedAssetValuationId(params.row.id);
    setIsModalOpen(true);
  };

  if (data?.entity?.__typename !== 'Entity') {
    return null;
  }

  if (data.entity.subtype.__typename !== 'GRATTrust') {
    return null;
  }

  const designerAccount: AssetValuationDesignerAccountFragment | null =
    data.entity.subtype.designerAccount ?? null;

  const rows = grat.annuities
    ? mapDataToRows(
        designerAccount,
        grat.annuities,
        grat.distributionAssetValuation?.valuationValue ?? undefined
      )
    : [];

  const assetValuations: AssetValuationV2Fragment[] = getNodes(
    data.entity.subtype.designerAccount?.valuations
  );

  return (
    <>
      <AssetValuationAsOfModal
        isOpen={isModalOpen}
        entityId={entityId}
        onClose={() => setIsModalOpen(false)}
        currentAssetValuationId={selectedAssetValuationId}
        assetValuations={assetValuations}
      />
      <DataTable_LEGACY
        onRowClick={onRowClick}
        header=""
        rows={rows}
        columns={columns}
        variant="short"
      />
    </>
  );
}
