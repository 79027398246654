import { getCRTTrustSummaryProperties } from '@/modules/entities/details/trusts/CRTTrustDetails';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { TrustBeneficiariesSlide } from '../components/Trust.Beneficiaries';
import { CRTTrustPresentationSlideProps } from './CRTTrust.types';

export function CRTTrustBeneficiariesSlide({
  entity,
  subtype,
  ...registrationProps
}: CRTTrustPresentationSlideProps) {
  const summaryDetails = getCRTTrustSummaryProperties({
    entity,
    entityType: ENTITY_TYPES.ILIT,
  });

  return (
    <TrustBeneficiariesSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      displayName={subtype.displayName}
    />
  );
}
