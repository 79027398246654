import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { first } from 'lodash';
import { useCallback, useMemo } from 'react';

import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { DragAndDropList } from '@/components/lists/DragAndDropList/DragAndDropList';
import { TableCell, TableCellContext } from '@/components/lists/TableCell';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useDebouncedFn } from '@/hooks/useDebouncedFn';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { SortableListItem } from '@/modules/estateTaxScenarios/forms/PaymentMethodForm/useDraggablePayoutOrderItems';
import { EstateWaterfallDocument } from '@/modules/estateWaterfall/graphql/EstateWaterfall.generated';
import { ClientProfileSummaryPanel_ClientProfileFragment } from '@/modules/summaryPanels/ClientProfileSummaryPanel/graphql/ClientProfileSummaryPanel.generated';
import { COLORS } from '@/styles/tokens/colors';
import {
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';
import { getTransferDetails } from '@/utils/hypotheticalTransfers/transferUtils';

import { EntityDetail_EntityFragment } from '../graphql/EntityDetailPage.generated';
import { HypotheticalTransferModal } from '../HypotheticalTransferModal/HypotheticalTransferModal';
import { GetHypotheticalTransfers_HypotheticalTransferFragment } from './graphql/GetHypotheticalTransfers.generated';
import { useCreateOrUpdateHypotheticalTransfersMutation } from './hypotheticalTransfers/graphql/CreateOrUpdateHypotheticalTransfers.generated';
import { TransferDirection } from './hypotheticalTransfers/HypotheticalTransferForm';
import { getUpdateHypotheticalTransfersOrderInput } from './hypotheticalTransfers/hypotheticalTransfers.utils';
import {
  getDescriptionFromTransfer,
  getNetHypotheticalTransfers,
  getTransferDisplayName,
} from './HypotheticalTransfersCard.utils';

export enum TransferTab {
  Outbound = 'outbound',
  Inbound = 'inbound',
}

interface TransferItemsProps {
  transfers: {
    inboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
    outboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
  };
  activeTab: TransferTab;
  transferReferenceObj?:
    | EntityDetail_EntityFragment
    | ClientProfileSummaryPanel_ClientProfileFragment;
  waterfallId: string;
  householdId: string;
  entityId: string;
  growthRate: Decimal;
}

export function TransferItems({
  transfers: { inboundTransfers, outboundTransfers },
  activeTab,
  waterfallId,
  householdId,
  entityId,
  growthRate: wholePercentGrowthRate,
}: TransferItemsProps) {
  const { showFeedback, createSuccessFeedback } = useFeedback();
  const { reportError } = useReportError();

  const isOutbound = activeTab === TransferTab.Outbound;
  const transfers = isOutbound ? outboundTransfers : inboundTransfers;

  const [{ isModalOpen, data: transferId }, { openModal, closeModal }] =
    useModalState<string | null>();

  const items = useMemo(() => {
    return transfers
      .sort((a, b) => a.transferOrder - b.transferOrder)
      .map((transfer) => {
        const { amount: transferValue, date: subheading } =
          getTransferDetails(transfer);

        let heading = formatCurrencyNoDecimals(transferValue);

        if (isOutbound) {
          heading = formatCurrencyNoDecimalsAccounting(transferValue.times(-1));
        }

        let headingSx = undefined;

        if (activeTab === TransferTab.Outbound) {
          headingSx = { color: COLORS.MATH.NEGATIVE };
        }

        return {
          id: transfer.id,
          heading: getTransferDisplayName(transfer),
          description: getDescriptionFromTransfer(transfer, activeTab),
          context: {
            heading,
            headingSx,
            subheading,
            action: (
              <EditButton
                onClick={() => {
                  openModal(transfer.id);
                }}
              />
            ),
          },
        };
      });
  }, [activeTab, isOutbound, openModal, transfers]);

  const [updateEstateWaterfall] =
    useCreateOrUpdateHypotheticalTransfersMutation({
      refetchQueries: [EstateWaterfallDocument],
      onCompleted: createSuccessFeedback('Hypothetical transfers updated'),
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        reportError('Error updating hypothetical transfers', error);
      },
    });

  const debouncedUpdateEstateWaterfall = useDebouncedFn(
    updateEstateWaterfall,
    500,
    {
      leading: false,
      trailing: true,
    }
  );

  const handleDrop = useCallback(
    (sortedList: SortableListItem[]) => {
      if (!sortedList.length) {
        return;
      }

      void debouncedUpdateEstateWaterfall({
        variables: {
          input: getUpdateHypotheticalTransfersOrderInput({
            waterfallId,
            transfers: sortedList,
          }),
        },
      });
    },
    [debouncedUpdateEstateWaterfall, waterfallId]
  );

  const firstItem = first(items);

  const growthRate = wholePercentGrowthRate.dividedBy(100).plus(1);
  const { totalNormalizedTransfers: netTransferAmount } =
    getNetHypotheticalTransfers({
      // using zero here because we don't care about the net value of
      // the entity, only the net value of the transfers
      currentValue: new Decimal(0),
      inboundTransfers: isOutbound ? [] : transfers,
      outboundTransfers: isOutbound ? transfers : [],
      growthRate,
    });

  return (
    <>
      {isModalOpen && transferId && (
        <HypotheticalTransferModal
          transferId={transferId}
          waterfallId={waterfallId}
          householdId={householdId}
          isOpen={isModalOpen}
          onClose={closeModal}
          initiatingDirection={
            isOutbound ? TransferDirection.Outbound : TransferDirection.Inbound
          }
          initiatingId={entityId}
        />
      )}
      <Stack>
        <Stack p={1.5} direction="row" bgcolor={COLORS.GRAY[100]}>
          <Box>
            <Typography variant="h6" component="p" color={COLORS.GRAY[500]}>
              Transfers
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction="column"
          sx={{
            borderBottom: `1px solid ${COLORS.GRAY[200]}`,
            '&:last-child': {
              borderBottom: 'none',
            },
          }}
        >
          {items.length === 1 && firstItem ? (
            <TableCell
              RightContent={
                firstItem.context && <TableCellContext {...firstItem.context} />
              }
              {...firstItem}
            />
          ) : (
            <DragAndDropList<SortableListItem>
              items={items}
              respondToLengthChanges={false}
              ItemInnerComponent={({ item, Draghandle }) => (
                <TableCell
                  RightContent={
                    item.context && <TableCellContext {...item.context} />
                  }
                  {...item}
                  Draghandle={Draghandle}
                />
              )}
              droppableId={`${activeTab}-transfers`}
              onDropped={handleDrop}
            />
          )}
          <Stack
            sx={{
              borderTop: `2px solid ${COLORS.NAVY[600]}`,
              px: 1.5,
              py: 2.5,
            }}
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="h5" component="span">
              Transferred {isOutbound ? 'out' : 'in'}
            </Typography>
            <Typography
              variant="h5"
              component="span"
              sx={{
                color: isOutbound ? COLORS.MATH.NEGATIVE : undefined,
              }}
            >
              {formatCurrencyNoDecimalsAccounting(
                netTransferAmount.times(isOutbound ? -1 : 1)
              )}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
}
