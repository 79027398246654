import { Box, Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { includes } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import {
  validateAtMost100Percent,
  ValidatedInputRepeaterPercentSumRow,
  validateExactly100Percent,
} from '@/components/form/formAwareInputs/FormAwareInputRepeater/ValidatedInputRepeaterPercentSumRow';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { useFormContext } from '@/components/react-hook-form';
import { BUSINESS_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import {
  ContextPrimaryClient,
  useHouseholdDetailsContext,
} from '@/modules/household/contexts/householdDetails.context';
import { diagnostics } from '@/utils/diagnostics';

import { defaultValues as businessEntityFormDefaultValues } from '../../../BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import { EntityType } from '../../../types/EntityType';
import {
  EntityShortFormPaths,
  EntityShortFormShape,
} from '../CreateEntityShortForm.types';
import {
  getSelectedClientProfileIdsFromForm,
  principalPatternAllowsMultipleSelection,
} from '../formUtils/CreateEntityShortForm.common';
import {
  getInitialSelectedPrincipalsState,
  getOptionsFromContextClients,
  getPrincipalInputConfiguration,
  useDefaultJointAccountOwnership,
  useOwnershipSumValidation,
} from './Principals.utils';
import { PrincipalsLabel } from './PrincipalsLabel';

interface PrincipalsProps {
  principalOptions: SelectInputOption<string>[];
  entityType: EntityType;
}

const PERCENT_INPUT_WIDTH_PX = 150;

export function Principals({ principalOptions, entityType }: PrincipalsProps) {
  const { primaryClients } = useHouseholdDetailsContext();
  const {
    setValue,
    resetField,
    watch,
    control,
    formState: { isLoading },
  } = useFormContext<EntityShortFormShape>();

  const multiplePrincipalsValues = useWatch({
    control,
    name: `createEntityShortForm.principals` as const satisfies EntityShortFormPaths,
  });

  const singlePrincipalValue = useWatch({
    control,
    name: `createEntityShortForm.selectedSinglePrincipal` as const satisfies EntityShortFormPaths,
  });

  const memoizedSelectedPrincipals = useMemo(
    () => multiplePrincipalsValues,
    [multiplePrincipalsValues]
  );

  const principalInputConfiguration = useMemo(
    () =>
      getPrincipalInputConfiguration(
        entityType,
        getOptionsFromContextClients(primaryClients),
        principalOptions
      ),
    [entityType, primaryClients, principalOptions]
  );

  const { principalSelectionPattern, options, validateCompleteOwnership } =
    principalInputConfiguration;
  const allowMultiple = principalPatternAllowsMultipleSelection(
    principalSelectionPattern
  );

  const resetMultiPrincipalField = useCallback(
    (primaryClients: ContextPrimaryClient[]) => {
      const multiplePrincipalsField =
        `createEntityShortForm.principals` as const satisfies EntityShortFormPaths;
      const initialFormValues =
        getInitialSelectedPrincipalsState(primaryClients);
      resetField(multiplePrincipalsField, { defaultValue: initialFormValues });
    },
    [resetField]
  );

  useEffect(
    function initializeFormValuesFromContext() {
      if (!primaryClients) return;
      diagnostics.debug('Running initializeFormValuesFromContext');
      resetMultiPrincipalField(primaryClients);
    },
    [options, primaryClients, resetField, resetMultiPrincipalField]
  );

  useEffect(
    function autoSelectSingleOption() {
      const selectedIds = getSelectedClientProfileIdsFromForm(
        memoizedSelectedPrincipals,
        singlePrincipalValue,
        entityType
      );

      if (options.length === 1 && selectedIds.length < 1 && options[0]?.value) {
        diagnostics.debug('Running autoSelectSingleOption');
        // we need to set the value for both the multi-principal persistence model
        // and the single-principal persistence model
        setValue(
          `createEntityShortForm.principals.${options[0].value}.isIncluded`,
          true
        );
        setValue(
          `createEntityShortForm.selectedSinglePrincipal`,
          options[0].value
        );
      }
    },
    [
      entityType,
      memoizedSelectedPrincipals,
      options,
      setValue,
      singlePrincipalValue,
    ]
  );

  useEffect(
    function handleMultiToSingleInputConfigurationChange() {
      // if moving from multiple-principal type to a single-principal type, we need to clear one
      // of the selected grantors
      if (allowMultiple || !primaryClients || options.length === 1) return;
      const selectedIds = getSelectedClientProfileIdsFromForm(
        memoizedSelectedPrincipals,
        singlePrincipalValue,
        entityType
      );
      if (!allowMultiple && selectedIds.length < 2) return;
      diagnostics.debug('Running handleMultiToSingleInputConfigurationChange');

      const clientProfileToPersist = selectedIds[0]!;
      setValue(
        `createEntityShortForm.selectedSinglePrincipal`,
        clientProfileToPersist
      );
      resetMultiPrincipalField(primaryClients);
    },
    [
      principalSelectionPattern,
      options,
      primaryClients,
      memoizedSelectedPrincipals,
      allowMultiple,
      setValue,
      resetMultiPrincipalField,
      singlePrincipalValue,
      entityType,
    ]
  );

  useEffect(
    function handleSingleToMultiInputConfigurationChange() {
      if (!allowMultiple || !primaryClients) return;
      setValue(`createEntityShortForm.selectedSinglePrincipal`, '');
    },
    [allowMultiple, primaryClients, setValue]
  );

  useEffect(
    function handleNonBusinessEntityChange() {
      if (includes(BUSINESS_ENTITY_TYPES, entityType)) return;
      resetField(`businessEntityDetailsSubform`, {
        defaultValue: businessEntityFormDefaultValues,
      });
    },
    [entityType, resetField]
  );

  useOwnershipSumValidation();
  useDefaultJointAccountOwnership();

  return (
    <Stack spacing={1} data-testid="Principals">
      <PrincipalsLabel
        label={principalInputConfiguration.label}
        entityType={entityType}
      />
      {allowMultiple ? (
        <Stack component="ul" spacing={1} sx={{ m: 0, p: 0 }}>
          {options.map((option) => {
            const isOptionSelected =
              watch(
                `createEntityShortForm.principals.${option.value}.isIncluded` as const satisfies EntityShortFormPaths
              ) ?? false;
            return (
              <Stack
                key={option.value}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormAwareCheckbox
                  control={control}
                  fieldName={
                    `createEntityShortForm.principals.${option.value}.isIncluded` as const satisfies EntityShortFormPaths
                  }
                  id={`principal-${option.value}`}
                  required
                  disabled={options.length === 1 || isLoading}
                  label={option.display}
                />
                {principalInputConfiguration.collectOwnershipPercentage && (
                  <Box key={option.value} width={PERCENT_INPUT_WIDTH_PX}>
                    <FormAwarePercentInput<EntityShortFormShape>
                      disabled={!isOptionSelected}
                      control={control}
                      required={isOptionSelected}
                      isDecimalJSInput
                      fieldName={
                        `createEntityShortForm.principals.${option.value}.ownershipPercentage` as const satisfies EntityShortFormPaths
                      }
                      label="Ownership percentage"
                      hideLabel
                    />
                  </Box>
                )}
              </Stack>
            );
          })}
        </Stack>
      ) : (
        <FormAwareRadioGroup<EntityShortFormShape>
          control={control}
          label={principalInputConfiguration.label}
          hideLabel
          fieldName={
            `createEntityShortForm.selectedSinglePrincipal` as const satisfies EntityShortFormPaths
          }
          options={options.map((option) => ({
            label: option.display,
            value: option.value,
          }))}
          row={false}
          required
          disabled={isLoading}
        />
      )}

      {principalInputConfiguration.collectOwnershipPercentage && (
        <Stack alignItems="end">
          <Box width={PERCENT_INPUT_WIDTH_PX}>
            <ValidatedInputRepeaterPercentSumRow<EntityShortFormShape>
              validateOnChange
              decimalScale={5}
              validationField={
                `createEntityShortForm._ownershipSum` as const satisfies EntityShortFormPaths
              }
              validation={{
                totalValue: (value) => {
                  if (validateCompleteOwnership) {
                    return validateExactly100Percent(value as Decimal | null);
                  }

                  return validateAtMost100Percent(value as Decimal | null);
                },
              }}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
