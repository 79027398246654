import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Alert } from '@/components/notifications/Alert/Alert';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { TenantKind } from '@/types/schema';
import { getEnvironment } from '@/utils/environmentUtils';
import { getTenant } from '@/utils/tenantUtils';

import { usageBucketOptions } from '../AdminTenantsPage.constants';
import {
  useTenantSettingsQuery,
  useUpdateTenantMutation,
} from '../graphql/AdminTenantsPage.generated';
import {
  TenantUpdateFormField,
  TenantUpdateFormShape,
} from './TenantSettingsForm.types';

function useTenantSettingsData() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { data, ...queryProps } = useTenantSettingsQuery({
    onError: (err) => {
      showFeedback('Failed to fetch tenant settings');
      reportError('could not fetch tenant settings for internal page', err);
    },
  });

  const {
    id: tenantId,
    usageBucket,
    kind,
    name,
    contractStartDate,
    branding,
  } = data?.tenantInformation ?? {};

  return {
    data,
    tenantId,
    usageBucket,
    kind,
    name,
    branding,
    contractStartDate,
    ...queryProps,
  };
}

/**
 * @description TenantSettingsForm
 */
export function TenantSettingsForm() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { tenantId, usageBucket, kind, name, contractStartDate, branding } =
    useTenantSettingsData();
  const environment = getEnvironment();
  const tenantSubdomain = getTenant();

  const formMethods = useForm<TenantUpdateFormShape>({
    defaultValues: {
      displayName: '',
      usageBucket: usageBucketOptions[0]?.value,
      productionLevel: TenantKind.NonProduction,
      contractStartDate: null,
    },
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = formMethods;

  useEffect(() => {
    reset({
      displayName: name,
      usageBucket: usageBucket,
      productionLevel: kind,
      contractStartDate: contractStartDate,
    });
  }, [contractStartDate, kind, name, reset, usageBucket]);

  const [updateTenant] = useUpdateTenantMutation({
    onError: (err) => {
      showFeedback('Failed to update tenant');
      reportError('could not update tenant', err);
    },
    onCompleted: () => {
      showFeedback('Tenant updated successfully', { variant: 'success' });
    },
  });

  async function onSubmit(formData: TenantUpdateFormShape) {
    if (!branding?.id || !tenantId) {
      throw new Error('Missing data for this tenant');
    }

    return updateTenant({
      variables: {
        tenantInput: {
          id: tenantId,
          update: {
            usageBucket: formData.usageBucket,
            contractStartDate: formData.contractStartDate,
            kind: formData.productionLevel,
          },
        },
        brandingId: branding.id,
        brandingInput: {
          displayName: formData.displayName,
        },
      },
    });
  }

  return (
    <FormProvider {...formMethods}>
      <Stack
        spacing={2}
        noValidate
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Alert severity="info-high" size="lg">
          REMINDER: You are updating the settings for {tenantSubdomain} in the{' '}
          {environment.toUpperCase()} environment.
        </Alert>
        <FormAwareTextInput<TenantUpdateFormShape>
          label="Organization display name"
          helpText="To be used in places like proposals and invitation emails"
          fieldName={'displayName' as const satisfies TenantUpdateFormField}
          required
          disabled={isSubmitting}
          control={control}
        />
        <FormAwareSelectInput<TenantUpdateFormShape>
          label="Usage bucket"
          fieldName={'usageBucket' as const satisfies TenantUpdateFormField}
          required
          disabled={isSubmitting}
          control={control}
          options={usageBucketOptions}
        />
        <Box sx={{ maxWidth: 350 }}>
          <FormAwareButtonGroup
            control={control}
            label="Production level"
            helpText={
              "For security reasons, tenants can't be downgraded from production."
            }
            fieldName={
              'productionLevel' as const satisfies TenantUpdateFormField
            }
            disabled={kind === TenantKind.Production || isSubmitting}
            options={[
              {
                display: 'Non-production',
                value: TenantKind.NonProduction,
              },
              {
                display: 'Production',
                value: TenantKind.Production,
              },
            ]}
          />
        </Box>
        <FormAwareDatePickerInput<TenantUpdateFormShape>
          label="Contract start date"
          helpText="For billing purposes"
          fieldName={
            'contractStartDate' as const satisfies TenantUpdateFormField
          }
          disabled={isSubmitting}
          control={control}
        />
        <Button
          disabled={isSubmitting}
          variant="primary"
          size="md"
          type="submit"
        >
          Update tenant
        </Button>
      </Stack>
    </FormProvider>
  );
}
