import { ReactFlowState } from '@xyflow/react';
import { useCallback } from 'react';

import { useGetValue } from './internal/useGetValue';

const isInteractiveSelector = (s: ReactFlowState) =>
  s.nodesDraggable && s.nodesConnectable;

export function useIsInteractive() {
  const {
    store,
    value: isInteractive,
    get: getIsInteractive,
  } = useGetValue(isInteractiveSelector);

  const toggleIsInteractive = useCallback(() => {
    store.setState({
      nodesDraggable: !isInteractive,
      nodesConnectable: !isInteractive,
    });
  }, [isInteractive, store]);

  return { isInteractive, toggleIsInteractive, getIsInteractive };
}
