import { Button } from '@/components/form/baseInputs/Button';
import { SlashCircle01Icon } from '@/components/icons/SlashCircle01Icon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import { COLORS } from '@/styles/tokens/colors';

import { useDispositiveProvisionsContext } from '../../contexts/dispositiveProvisions.context';
import { useDispositiveProvisionsTemplateSplitScreenModalContext } from '../../DispositiveProvisionsTemplateSplitScreenModal/DispositiveProvisionsTemplateSplitScreenModal.context';
import { useOrderedDyingClients } from '../hooks/utilityHooks';

export interface DispositiveProvisionsRecipientsEmptyStateProps {
  onAddRecipients: () => void;
}

export function DispositiveProvisionsRecipientsEmptyState({
  onAddRecipients,
}: DispositiveProvisionsRecipientsEmptyStateProps) {
  const { isClientProfile } = useDispositiveProvisionsContext();
  const { isTemplateMode } =
    useDispositiveProvisionsTemplateSplitScreenModalContext();
  const [firstClientToDie, secondClientToDie] = useOrderedDyingClients();
  return (
    <ActionCard
      variant="noCard"
      heading="No dispositions upon death"
      description={getNoneDispositionDescription({
        firstClientToDie,
        secondClientToDie,
        isClientProfile,
        isTemplateMode,
      })}
      icon={<SlashCircle01Icon size={40} sx={{ color: COLORS.GRAY[200] }} />}
      actions={
        <Button
          variant="secondary"
          size="lg"
          fullWidth
          onClick={onAddRecipients}
        >
          Add a distribution
        </Button>
      }
    />
  );
}

interface GetNoneDispositionDescriptionOpts {
  isClientProfile: boolean;
  firstClientToDie: ContextPrimaryClient;
  secondClientToDie?: ContextPrimaryClient;
  isTemplateMode: boolean;
}

function getNoneDispositionDescription({
  firstClientToDie,
  secondClientToDie,
  isClientProfile,
  isTemplateMode,
}: GetNoneDispositionDescriptionOpts): string {
  if (isTemplateMode) {
    return 'No dispositions have been set';
  }

  const prefix = (() => {
    if (isClientProfile) {
      return 'There are no dispositions';
    }

    return 'This entity will remain as-is';
  })();

  if (!secondClientToDie) {
    return `${prefix} when ${firstClientToDie.firstName} passes`;
  }

  return `${prefix} if ${firstClientToDie.displayName} dies first and ${secondClientToDie.displayName} subsequently dies`;
}
