import { PropsWithChildren, useEffect } from 'react';

import { getNodes } from '@/utils/graphqlUtils';

import { NEW_PROPOSAL_ID } from '../proposal.constants';
import { useGetProposalDetailsQuery } from './graphql/GetProposalDetails.generated';
import { useProposalDetailsContext } from './proposalDetails.context';

export const ProposalDetailsAwareRoute = ({
  proposalId,
  children,
}: PropsWithChildren<{ proposalId: string | null }>) => {
  const { setProposalId, setDisplayName } = useProposalDetailsContext();
  const isNewProposal = proposalId === NEW_PROPOSAL_ID;

  const { data: proposalData } = useGetProposalDetailsQuery({
    variables: { where: { id: proposalId } },
    skip: isNewProposal,
  });

  const proposal = getNodes(proposalData?.proposals)[0];

  useEffect(() => {
    const displayName = isNewProposal ? 'New Proposal' : proposal?.displayName;
    setProposalId(proposal?.id ?? null);
    setDisplayName(displayName ?? '');

    return () => {
      setProposalId(null);
      setDisplayName(null);
    };
  }, [
    proposal?.displayName,
    proposal?.id,
    setProposalId,
    setDisplayName,
    isNewProposal,
  ]);

  return <>{children}</>;
};
