import { TooltipProps } from '@mui/material';

import { InfoCircleIcon } from '@/components/icons/InfoCircleIcon';
import { COLORS } from '@/styles/tokens/colors';

import { Tooltip } from './Tooltip';

export function InfoTooltip(props: Omit<TooltipProps, 'children'>) {
  return (
    <Tooltip {...props}>
      <InfoCircleIcon
        size={16}
        sx={{
          color: COLORS.GRAY[500],
          display: 'flex',
          cursor: 'pointer',
        }}
      />
    </Tooltip>
  );
}
