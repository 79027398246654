import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { NotFoundError } from '@/components/display/NotFoundError/NotFoundError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { GlobalSidebar } from '@/modules/globalSidebar/GlobalSidebar';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { HouseholdDetailsAwareRoute } from '@/modules/household/contexts/HouseholdDetailsAwareRoute';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { ClientDetailsSidebar } from '../Sidebar/ClientDetailsSidebar';
import { ClientDetailsAppBar } from './AppBar/ClientDetailsAppBar';
import { PrimaryAppBar } from './AppBar/PrimaryAppBar';
import { AppBarLayout } from './AppBarLayout/AppBarLayout';

function ClientDetailsLayoutInner() {
  const { householdId, householdNotFound } = useHouseholdDetailsContext();

  if (householdNotFound) {
    const notFoundReturnPath = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLDS_LIST,
      {}
    );
    return (
      <AppBarLayout
        hideSidebar
        sidebarLayout="primary"
        slots={{
          AppBar: {
            component: PrimaryAppBar,
            props: {},
          },
        }}
      >
        <Stack
          sx={{
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <NotFoundError
            title="Unable to load client"
            message="The client you are looking for could not be found, or you may not have the necessary access"
            buttonText="Return to clients"
            returnPath={notFoundReturnPath}
            sx={{ maxWidth: '350px' }}
          />
        </Stack>
      </AppBarLayout>
    );
  }

  return (
    <AppBarLayout
      // This id is used to indicate to posthog where surveys should show up; right now, we're targeting for them to show up
      // on the client details page and any subroutes
      id="posthog-survey-target"
      sidebarLayout="secondary"
      slots={{
        AppBar: {
          component: ClientDetailsAppBar,
          props: {},
        },
        LeftSidebar: {
          component: ClientDetailsSidebar,
          props: {
            householdId,
          },
        },
      }}
    >
      <AppBarLayout
        sidebarLayout="primary"
        hideAppBar
        slots={{
          RightSidebar: {
            component: GlobalSidebar,
            props: {},
          },
        }}
      >
        <Outlet />
      </AppBarLayout>
    </AppBarLayout>
  );
}

export function ClientDetailsLayout() {
  const householdId = useRequiredParam('householdId');

  return (
    <HouseholdDetailsAwareRoute householdId={householdId}>
      <ClientDetailsLayoutInner />
    </HouseholdDetailsAwareRoute>
  );
}
