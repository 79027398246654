import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useSubBrandOptions } from '@/modules/admin/branding/hooks/useSubBrandOptions';
import { HouseholdBillableKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import {
  ClientFormDataQuery,
  useClientFormDataQuery,
} from '../graphql/ClientFormData.generated';
import { UpdateHouseholdFormShape } from '../types';
import { getDefaultValues } from '../Update';
import { getPossibleRelationships } from './clientProfileFormUtils';

interface Props {
  householdId: string;
}

function getGuardedGrantorProfiles(
  data?: ClientFormDataQuery
): UpdateHouseholdFormShape['profiles'] {
  const householdEdges = data?.households?.edges;
  if (!householdEdges) return [];
  return householdEdges.flatMap((householdEdge) => {
    const clientProfiles = householdEdge?.node?.clientProfiles;
    const possiblePrimaryClients =
      householdEdge?.node?.possiblePrimaryClients ?? [];
    if (!clientProfiles) return [];
    return clientProfiles.flatMap((profile) => {
      if (!profile.isGrantor) return [];
      return {
        address: {
          id: profile.address?.id ?? null,
          cityName: profile.address?.cityName ?? '',
          stateCode: profile.address?.stateCode ?? '',
        },
        id: profile.id,
        email: profile.email ?? '',
        firstName: profile.firstName,
        middleName: profile.middleName || '',
        lastName: profile.lastName,
        suffix: profile.suffix || '',
        isBeneficiary: profile.isBeneficiary,
        isGrantor: profile.isGrantor,
        isTrustee: profile.isTrustee,
        dateOfBirth: profile.dateOfBirth || null,
        relationships: getPossibleRelationships(
          profile.id,
          profile.relationships ?? [],
          possiblePrimaryClients
        ),
      };
    });
  });
}

export function useExistingHouseholdFormData({ householdId }: Props): {
  formData: UpdateHouseholdFormShape | null;
  subBrandOptions: SelectInputOption<string>[];
} {
  const { data } = useClientFormDataQuery({
    variables: {
      where: {
        id: householdId,
      },
    },
  });

  const households = getNodes(data?.households);
  const household = households[0];

  const formData: UpdateHouseholdFormShape = useMemo(() => {
    const defaultValues = getDefaultValues();
    const existingGrantorProfiles = getGuardedGrantorProfiles(data);
    return {
      // if we have existing profiles, show those. otherwise,
      // populate with the default empty state.
      profiles:
        existingGrantorProfiles.length > 0
          ? existingGrantorProfiles
          : defaultValues.profiles,
      primaryRelationshipOwnerId: household?.primaryRelationshipOwner.id ?? '',
      subBrandId: household?.subBrand?.id ?? '',
      isProspect: household?.billableKind === HouseholdBillableKind.Prospect,
    };
  }, [
    data,
    household?.billableKind,
    household?.primaryRelationshipOwner.id,
    household?.subBrand?.id,
  ]);

  const subBrandNodes = useMemo(() => getNodes(data?.subBrands), [data]);
  const subBrandOptions = useSubBrandOptions(subBrandNodes);

  return {
    formData,
    subBrandOptions,
  };
}
