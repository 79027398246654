import { Box } from '@mui/material';
import { useMemo, useState } from 'react';
import { useFieldArray } from 'react-hook-form';

import { FormAwareEmptyPlaceholder } from '@/components/form/formAwareInputs/FormAwareEmptyPlaceholder';
import { BankIcon } from '@/components/icons/BankIcon';
import { useFormContext } from '@/components/react-hook-form';
import {
  EditableList,
  EditableListAddItemButton,
  EditableListItem,
} from '@/components/tables/EditableList/EditableList';
import { getAssetCategoryDisplay } from '@/modules/assets/assetCategoryUtils';
import {
  AssetDetailsModal,
  AssetDetailsModalProps,
} from '@/modules/assets/AssetDetailsModal/AssetDetailsModal';
import { NEW_ASSET_ID } from '@/modules/assets/types/asset';
import {
  getMarketValueForAsset,
  getSelectedAsset,
  getValidAssets,
} from '@/modules/assets/utils';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';

import { FormSectionProps, GRATStructuringForm } from '../constants';
import { ReturnProjectionCard } from './ReturnProjectionCard';

export function AssetForm({
  values,
}: FormSectionProps<GRATStructuringForm['assets']>) {
  const { assetClassesById } = useTenantDetailsContext();
  const { control, setValue } = useFormContext<GRATStructuringForm>();
  const [selectedAssetId, setSelectedAssetId] = useState<string | null>(null);
  const { append: appendToAssets, update: updateAsset } = useFieldArray<
    GRATStructuringForm,
    'assets.assets',
    'id'
  >({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'assets.assets', // unique name for your Field Array
    rules: {
      validate: {
        required: (value) => {
          return getValidAssets(value).length > 0
            ? undefined
            : 'You need to add at least one asset.';
        },
      },
    },
  });

  const handleAssetsModalSubmit: AssetDetailsModalProps['onSubmit'] = (
    value,
    { isNewAsset, isRemoveAsset }
  ) => {
    // remove becomes first because there's a case where we're removing a new asset
    if (isRemoveAsset) {
      const assetIndex = values.assets.findIndex(
        (asset) => asset.id === value.id
      );
      updateAsset(assetIndex, {
        ...value,
        doDelete: true,
      });
    } else if (isNewAsset) {
      appendToAssets(value);
    } else {
      const assetIndex = values.assets.findIndex(
        (asset) => asset.id === value.id
      );
      updateAsset(assetIndex, value);
    }

    setSelectedAssetId(null);
  };

  const handleAddNewAsset = () => {
    setSelectedAssetId(NEW_ASSET_ID);
  };

  const handleEditAsset = (assetId: string) => {
    setSelectedAssetId(assetId);
  };

  const undeletedAssets = useMemo(
    () => getValidAssets(values.assets),
    [values.assets]
  );
  return (
    <>
      {selectedAssetId && (
        <AssetDetailsModal
          onSubmit={handleAssetsModalSubmit}
          asset={getSelectedAsset(values.assets, selectedAssetId)}
          isOpen={!!selectedAssetId}
          isNewAsset={selectedAssetId === NEW_ASSET_ID}
          onClose={() => setSelectedAssetId(null)}
        />
      )}

      {undeletedAssets.length === 0 ? (
        <FormAwareEmptyPlaceholder<GRATStructuringForm>
          control={control}
          aria-label="Add an asset"
          fieldName="assets.assets"
          buttonText="Add an asset"
          onClick={handleAddNewAsset}
        >
          <Box textAlign="center" mb={3}>
            <BankIcon size={60} color={COLORS.GRAY[400]} />
          </Box>
          <Box>
            Input the asset(s) that will be used to fund this GRAT. The best way
            to fund GRATs is with asset(s) that have the highest likelihood of
            outperforming the GRAT hurdle rate.
          </Box>
        </FormAwareEmptyPlaceholder>
      ) : (
        <Box>
          <EditableList
            footerButton={
              <EditableListAddItemButton onClick={handleAddNewAsset}>
                Add an asset
              </EditableListAddItemButton>
            }
          >
            {undeletedAssets.map((asset) => {
              if (asset.doDelete) return null;
              return (
                <EditableListItem
                  key={asset.id}
                  title={asset.displayName}
                  lineTwo={getAssetCategoryDisplay(asset, assetClassesById)}
                  lineThree={formatCurrency(getMarketValueForAsset(asset))}
                  onEditClick={() => handleEditAsset(asset.id)}
                />
              );
            })}
          </EditableList>
          <Box mt={3}>
            <ReturnProjectionCard
              setValue={setValue}
              control={control}
              assets={undeletedAssets}
              projectedSharePrice={values.projectedSharePrice || null}
              projectedMarketValue={values.projectedMarketValue || null}
              returnProjectionType={values.returnProjectionType}
            />
          </Box>
        </Box>
      )}
    </>
  );
}
