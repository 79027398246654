import { Box, Typography, useTheme } from '@mui/material';

import { RibbonCalloutCard } from '@/components/layout/RibbonCalloutCard';
import { COLORS } from '@/styles/tokens/colors';

import { ContextualHelpTrigger } from '../../components/ContextualHelpTrigger';

export function GratContentTrigger() {
  const theme = useTheme();
  return (
    <Box width="280px" flexBasis="280px">
      <RibbonCalloutCard
        sx={{
          backgroundColor: COLORS.PRIMITIVES.WHITE,
          borderRadius: '6px 0px 0px 6px',
        }}
      >
        <Typography variant="label" color={theme.palette.text.primary}>
          What is a Grantor Retained Annuity Trust (GRAT)?
        </Typography>
        <Typography variant="subtitle2" color={theme.palette.text.primary}>
          GRATs are a method to transfer wealth to family members, and can be
          structured to require little to no taxable gift.
        </Typography>
        <Typography variant="subtitle2" color={theme.palette.text.primary}>
          Assets are placed in the trust by a grantor, and the trust makes
          annual annuity payments back to the grantor. Assets in excess of the
          annuity payments are transferred outside of the estate.
        </Typography>
        <Box>
          <ContextualHelpTrigger
            display="Learn more"
            contextualHelpId="clientFacingGRAT"
          />
        </Box>
      </RibbonCalloutCard>
    </Box>
  );
}
