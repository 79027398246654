import { useEffect, useRef, useState } from 'react';

/**
 * @description Ensures a minimum time before resolving a "loading" boolean,
 * like one you might see as a result as of an apollo request.
 * Useful for prevent flashing loading states for requests that resolve quickly.
 */
export function useMinimumLoading(loading: boolean, ms = 250) {
  const [minimumLoading, setMinimumLoading] = useState(loading);
  const timerId = useRef<number>();

  useEffect(() => {
    if (!loading) return;

    clearTimeout(timerId.current);

    setMinimumLoading(true);

    timerId.current = setTimeout(() => {
      setMinimumLoading(false);
      timerId.current = undefined;
    }, ms) as unknown as number;
  }, [loading, ms]);

  // Clear on unmount
  useEffect(() => {
    return () => {
      clearTimeout(timerId.current);
    };
  }, []);

  return loading || minimumLoading;
}
