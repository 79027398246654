import { compact, startCase, toLower } from 'lodash';

import { AIOnboardingModalOrganizationField } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import {
  AiOnboardingModal_AiSuggestionFragment,
  AiOnboardingModal_HouseholdFragment,
} from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import { AugmentedCreateClientOrganizationInput } from '@/types/schema';

///////////////////////////////////////////////////////////
// SUGGESTION DATA -> FORM DATA
///////////////////////////////////////////////////////////

/**
 * Maps the ONBOARDING_ORGANIZATION suggestion to form data.
 *
 * @param suggestions
 */
export function mapOrganizationsToFormData(
  suggestions: AiOnboardingModal_AiSuggestionFragment[]
): AIOnboardingModalOrganizationField[] {
  return compact(
    suggestions.map((s) => {
      if (!s.onboardingOrganization) return null;

      return {
        _suggestionID: s.id,
        _nodeName: s.onboardingOrganization?.nodeName,
        organizationName: startCase(
          toLower(s.onboardingOrganization?.organizationName || '')
        ),
        organizationKind: s.onboardingOrganization?.organizationKind || '',
      };
    })
  );
}

export function getEmptyOrganizationField(): AIOnboardingModalOrganizationField {
  return {
    _suggestionID: '',
    _nodeName: '',
    organizationName: '',
    organizationKind: '',
  };
}

///////////////////////////////////////////////////////////
// FORM DATA -> MUTATION INPUT
///////////////////////////////////////////////////////////

/**
 * Maps the form data to the mutation input.
 *
 * @param organizations
 * @param household
 */
export function mapOrganizationsToInput(
  organizations: AIOnboardingModalOrganizationField[],
  household: AiOnboardingModal_HouseholdFragment
): AugmentedCreateClientOrganizationInput[] {
  return organizations.map((o) => ({
    create: {
      householdID: household.id,
      name: o.organizationName,
      kind: o.organizationKind || undefined,
      isBeneficiary: true,
      isTrustee: true,
      suggestedClientOrganizationIDs: o._suggestionID
        ? [o._suggestionID]
        : undefined,
    },
  }));
}
