import { useApolloClient } from '@apollo/client';
import { Box, Card, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { useState } from 'react';

import { RibbonHorizontal } from '@/components/display/Ribbon/RibbonHorizontal';
import { LinkButton } from '@/components/form/baseInputs/Link';
import { ShieldDollarIcon } from '@/components/icons/ShieldDollarIcon';
import { ComparisonChart } from '@/modules/proposal/Comparison';
import { useProposalForScenario } from '@/modules/proposal/hooks/useProjectionForScenario';
import { COLORS } from '@/styles/tokens/colors';
import {
  EntityProposalAssetValuationProjectionType,
  ProposalScenarioProjectionParamsV2,
} from '@/types/schema';
import { maxDecimalJS } from '@/utils/decimalJSUtils';
import { formatCurrency } from '@/utils/formatting/currency';

import { ChartLegend } from '../ChartLegend';
import { ProposalScenarioFragment } from '../graphql/ProposalScenarioFragment.generated';
import { getBeneficiariesFromProposal } from '../utils';
import { BeneficiariesBreakdown } from './BeneficiariesBreakdown';
import { ScenarioComparisonTable } from './ScenarioComparisonTable';
import { ScenarioReturns } from './ScenarioReturns';

const COLUMN_HEIGHT_PX = 200 as const;
const COLUMN_WIDTH_PX = 73 as const;

function RowText({ number, text }: { number: Decimal; text: string }) {
  const theme = useTheme();

  return (
    <Box component="span">
      <Typography
        display="inline"
        color={theme.palette.primary.contrastText}
        variant="h5"
      >
        {formatCurrency(number, {
          notation: 'compact',
        })}{' '}
      </Typography>
      <Typography
        display="inline"
        color={theme.palette.primary.contrastText}
        fontWeight="medium"
        variant="h5"
      >
        {text}
      </Typography>
    </Box>
  );
}

interface Props {
  gratProposalScenario: NonNullable<
    ProposalScenarioFragment['gratProposalScenario']
  >;
  entityId: string;
  maxPositive?: Decimal;
  scenarioNumber: number;
  selectedYearOptionIndex: number;
  proposalYearProjectionOptions: {
    value: string;
    valueDecimal: Decimal;
    display: string;
  }[];
  taxDrag: Decimal;
  assetValuationProjectionType: EntityProposalAssetValuationProjectionType;
  beneficiaries: ReturnType<typeof getBeneficiariesFromProposal>;
}
export function ScenarioSummary({
  gratProposalScenario,
  entityId,
  maxPositive: maxPositiveProp,
  scenarioNumber,
  selectedYearOptionIndex,
  proposalYearProjectionOptions,
  taxDrag,
  assetValuationProjectionType,
  beneficiaries,
}: Props) {
  const client = useApolloClient();
  const theme = useTheme();
  const ribbonColor = theme.palette.primary.main;
  const [showBeneficiaryBreakdown, setShowBeneficiaryBreakdown] =
    useState<boolean>(false);

  const isTermEndProjectedValue =
    assetValuationProjectionType ===
    EntityProposalAssetValuationProjectionType.ProjectedValue;
  const projectionTimelineYearsValue =
    proposalYearProjectionOptions[selectedYearOptionIndex]?.value;

  const queryInputs = {
    afterTermAnnualRateOfReturn:
      gratProposalScenario.projectedAnnualReturnAfterTerm ?? new Decimal(0),
    inTermAnnualRateOfReturn: isTermEndProjectedValue
      ? undefined
      : gratProposalScenario.projectedAnnualReturnThroughTerm,
    projectionTimelineYears:
      (projectionTimelineYearsValue
        ? parseInt(projectionTimelineYearsValue)
        : undefined) ?? gratProposalScenario.projectionTimePeriodYears,
    entityID: entityId,
    taxDragEstimate: new Decimal(20),
    endTermProjectedValue: isTermEndProjectedValue
      ? gratProposalScenario.projectedMarketValueAtTerm
      : new Decimal(0),
  };

  const maxTimelineYears =
    proposalYearProjectionOptions[
      proposalYearProjectionOptions.length - 1
    ]?.valueDecimal.toNumber() ?? 0;

  const queryInputsMaxTerm: ProposalScenarioProjectionParamsV2 = {
    ...queryInputs,
    projectionTimelineYears:
      maxTimelineYears ?? gratProposalScenario.projectionTimePeriodYears,
  };

  const {
    noPlan: noPlanPreTax,
    withPlan: withPlanPreTax,
    maxProjectionValue,
  } = useProposalForScenario({
    currentTab: 'preTax',
    termEndAssetValuationType: assetValuationProjectionType,
    queryInputs,
    client,
    queryInputsMaxTerm,
  });

  const { noPlan: noPlanPostTax, withPlan: withPlanPostTax } =
    useProposalForScenario({
      currentTab: 'postTax',
      termEndAssetValuationType: assetValuationProjectionType,
      queryInputs,
      client,
      queryInputsMaxTerm,
    });

  const chartValues = {
    preTax: {
      noPlan: noPlanPreTax,
      withPlan: withPlanPreTax,
    },
    postTax: {
      noPlan: noPlanPostTax,
      withPlan: withPlanPostTax,
    },
  };

  let maxPositive = maxPositiveProp;

  if (!maxPositive) {
    const noPlanNoTax = chartValues.preTax.noPlan[0].plus(
      chartValues.preTax.noPlan[1]
    );
    const withPlanNoTax = chartValues.preTax.withPlan[0].plus(
      chartValues.preTax.withPlan[1]
    );
    const noPlanWithTax = chartValues.postTax.noPlan[0].plus(
      chartValues.postTax.noPlan[1]
    );
    const withPlanWithTax = chartValues.postTax.withPlan[0].plus(
      chartValues.postTax.withPlan[1]
    );

    maxPositive = maxDecimalJS(
      [noPlanNoTax, withPlanNoTax, noPlanWithTax, withPlanWithTax],
      new Decimal(0)
    );
  }

  const noPlanLessTax = chartValues.postTax.noPlan[0].plus(
    chartValues.postTax.noPlan[1]
  );
  const withPlanLessTax = chartValues.postTax.withPlan[0].plus(
    chartValues.postTax.withPlan[1]
  );

  const noPlanTax = chartValues.postTax.noPlan[2];
  const withPlanTax = chartValues.postTax.withPlan[2];

  return (
    <Stack>
      <Card
        sx={{
          overflow: 'visible',
        }}
      >
        <Stack flexDirection="row">
          <Stack minWidth="800px">
            <Stack position="relative">
              <RibbonHorizontal
                ribbonColor={ribbonColor}
                ribbonText={`Scenario ${scenarioNumber}`}
              />

              <Stack direction="row" mt={10} mb={2}>
                <Stack direction="column" width="100%" mx={4} spacing={1}>
                  <Box
                    sx={{ borderBottom: `solid ${ribbonColor} 2px` }}
                    pb="4px"
                  >
                    <Box width="50%">
                      <Typography color={ribbonColor} variant="label">
                        Before estate tax
                      </Typography>
                    </Box>
                  </Box>

                  <ComparisonChart
                    noPlan={[
                      chartValues.preTax.noPlan[0],
                      chartValues.preTax.noPlan[1],
                      chartValues.preTax.noPlan[2],
                    ]}
                    withPlan={[
                      chartValues.preTax.withPlan[0],
                      chartValues.preTax.withPlan[1],
                      chartValues.preTax.withPlan[2],
                    ]}
                    columnHeight={COLUMN_HEIGHT_PX}
                    columnWidth={COLUMN_WIDTH_PX}
                    scenario="pre-tax"
                    maxInGroup={maxPositive}
                    maxProjectionValue={maxProjectionValue}
                  />
                </Stack>
                <Stack direction="column" width="100%" mx={4} spacing={1}>
                  <Box
                    sx={{ borderBottom: `solid ${ribbonColor} 2px` }}
                    pb="4px"
                  >
                    <Box width="50%">
                      <Typography color={ribbonColor} variant="label">
                        After estate tax
                      </Typography>
                    </Box>
                  </Box>
                  <ComparisonChart
                    noPlan={[
                      chartValues.postTax.noPlan[0],
                      chartValues.postTax.noPlan[1],
                      chartValues.postTax.noPlan[2],
                    ]}
                    withPlan={[
                      chartValues.postTax.withPlan[0],
                      chartValues.postTax.withPlan[1],
                      chartValues.postTax.withPlan[2],
                    ]}
                    columnHeight={COLUMN_HEIGHT_PX}
                    columnWidth={COLUMN_WIDTH_PX}
                    scenario="post-tax"
                    maxInGroup={maxPositive}
                    maxProjectionValue={maxProjectionValue}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack width={{ md: '100%', lg: '408px' }} height="auto">
            <Stack
              height="100%"
              bgcolor={`${COLORS.GRAY[50]}`}
              sx={{
                borderTopRightRadius: '3px',
                borderLeft: `1px ${COLORS.GRAY[200]} solid}`,
              }}
            >
              <ScenarioReturns
                inTermRoR={gratProposalScenario?.inTermCAGRV2 ?? new Decimal(0)}
                afterTermTaxFreeInTrust={
                  gratProposalScenario?.projectedAnnualReturnAfterTerm ??
                  new Decimal(0)
                }
                afterTermTaxedInEstate={
                  (
                    gratProposalScenario?.projectedAnnualReturnAfterTerm ??
                    new Decimal(0)
                  ).times(
                    gratProposalScenario?.projectedAnnualReturnAfterTerm?.isNegative()
                      ? new Decimal(1).plus(taxDrag.dividedBy(100))
                      : new Decimal(1).minus(taxDrag.dividedBy(100))
                  ) ?? new Decimal(0)
                }
              />

              {!showBeneficiaryBreakdown && (
                <ScenarioComparisonTable chartValues={chartValues} />
              )}
              {showBeneficiaryBreakdown && (
                <BeneficiariesBreakdown
                  beneficiaries={beneficiaries}
                  entityId={entityId}
                  chartValues={chartValues}
                />
              )}
              <Stack direction="row" justifyContent="end" mx={2} mb={2}>
                <LinkButton
                  display={
                    showBeneficiaryBreakdown
                      ? 'Back to summary view'
                      : 'View by individual beneficiary'
                  }
                  onClick={() =>
                    setShowBeneficiaryBreakdown(!showBeneficiaryBreakdown)
                  }
                />
              </Stack>
            </Stack>
            <Stack
              flexDirection="row"
              padding={1.5}
              paddingBottom={3}
              bgcolor={theme.palette.primary.main}
              sx={{
                borderBottomRightRadius: '3px',
              }}
            >
              <Stack justifyContent="center" marginRight={1.5}>
                <ShieldDollarIcon
                  size={32}
                  sx={{
                    color: theme.palette.primary.contrastText,
                    opacity: 0.4,
                  }}
                />
              </Stack>
              <Stack>
                <Typography
                  sx={{ mb: 1 }}
                  color={theme.palette.primary.contrastText}
                  variant="h2"
                >
                  Benefits with plan
                </Typography>
                <RowText
                  number={withPlanLessTax.minus(noPlanLessTax)}
                  text="net increase to beneficiaries"
                />
                <RowText
                  number={withPlanTax.minus(noPlanTax)}
                  text="reduction in estate taxes"
                />
                <RowText
                  number={chartValues.postTax.withPlan[0]}
                  text="transferred out of estate"
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
      <Stack pt={3}>
        <ChartLegend justifyContent="start" variant="squares" />
      </Stack>
    </Stack>
  );
}
