import { useEffect, useState } from 'react';

interface EstimatedProgressOptions {
  startTime: number;
  estimatedDuration: number;
  isCompleted: boolean;
}

export function useEstimatedProgress({
  startTime,
  estimatedDuration,
  isCompleted,
}: EstimatedProgressOptions) {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Date.now();
      const elapsed = now - startTime;

      setElapsedTime(elapsed);

      if (isCompleted) {
        clearInterval(interval);
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [estimatedDuration, isCompleted, startTime]);

  // Calculate the progress percentage
  let progress = 0;
  const SLOWDOWN_CUTOFF_PERCENT = 0.7;

  if (isCompleted) {
    // If the process is completed, set progress to 100%
    progress = 100;
  } else if (elapsedTime <= SLOWDOWN_CUTOFF_PERCENT * estimatedDuration) {
    // For the first 80% of the estimated duration,
    // progress increases linearly from 0% toSLOWDOWN_CUTOFF_PERCENT
    progress = (elapsedTime / estimatedDuration) * 100;
  } else if (elapsedTime <= estimatedDuration) {
    // Between SLOWDOWN_CUTOFF_PERCENT and 100% of the estimated duration,
    // progress increases from SLOWDOWN_CUTOFF_PERCENT to 95% at a slower rate
    progress =
      SLOWDOWN_CUTOFF_PERCENT * 100 +
      ((elapsedTime - SLOWDOWN_CUTOFF_PERCENT * estimatedDuration) /
        ((1 - SLOWDOWN_CUTOFF_PERCENT) * estimatedDuration)) *
        15;
  } else {
    // If the elapsed time exceeds the estimated duration and the process isn't complete,
    // keep the progress at 95% to indicate nearing completion
    progress = 95;
  }

  return progress;
}
