// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteEntityTaskMutationVariables = Types.Exact<{
  taskID: Types.Scalars['ID']['input'];
}>;


export type CompleteEntityTaskMutation = { __typename?: 'Mutation', completeEntityTask: { __typename?: 'EntityTask', id: string, completionState: Types.EntityTaskCompletionState, completedAt?: Time | null } };


export const CompleteEntityTaskDocument = gql`
    mutation CompleteEntityTask($taskID: ID!) {
  completeEntityTask(id: $taskID) {
    id
    completionState
    completedAt
  }
}
    `;
export type CompleteEntityTaskMutationFn = Apollo.MutationFunction<CompleteEntityTaskMutation, CompleteEntityTaskMutationVariables>;

/**
 * __useCompleteEntityTaskMutation__
 *
 * To run a mutation, you first call `useCompleteEntityTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteEntityTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeEntityTaskMutation, { data, loading, error }] = useCompleteEntityTaskMutation({
 *   variables: {
 *      taskID: // value for 'taskID'
 *   },
 * });
 */
export function useCompleteEntityTaskMutation(baseOptions?: Apollo.MutationHookOptions<CompleteEntityTaskMutation, CompleteEntityTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteEntityTaskMutation, CompleteEntityTaskMutationVariables>(CompleteEntityTaskDocument, options);
      }
export type CompleteEntityTaskMutationHookResult = ReturnType<typeof useCompleteEntityTaskMutation>;
export type CompleteEntityTaskMutationResult = Apollo.MutationResult<CompleteEntityTaskMutation>;
export type CompleteEntityTaskMutationOptions = Apollo.BaseMutationOptions<CompleteEntityTaskMutation, CompleteEntityTaskMutationVariables>;
export const Operations = {
  Mutation: {
    CompleteEntityTask: 'CompleteEntityTask' as const
  }
}