import { Box, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Loader } from '@/components/progress/Loader/Loader';
import {
  getAddeparIntegrationLoginPath,
  getAddeparIntegrationLogoutPath,
} from '@/modules/assetProviderIntegrations/addepar/utils/addeparAuthUtils';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { getPublicImageUrl } from '@/utils/staticFiles';

import { AddeparConfigurationSummary } from './AddeparConfigurationSummary';
import {
  AddeparIntegrationForm,
  isAddeparConfigured,
} from './AddeparIntegrationForm';
import { AdminIntegrationsPage_IntegrationConfigurationFragment } from './graphql/AdminIntegrationsPage.generated';
import { ResyncTenantValuationsButton } from './ResyncTenantValuationsButton/ResyncTenantValuationsButton';

export interface AddeparIntegrationCardProps {
  // configuration will be null while loading
  configuration: AdminIntegrationsPage_IntegrationConfigurationFragment | null;
  refetch: () => void;
  isLoggedIn: boolean;
}

export function AddeparIntegrationCard({
  configuration,
  refetch,
  isLoggedIn,
}: AddeparIntegrationCardProps) {
  const [isEditingConfiguration, setIsEditingConfiguration] = useState(false);
  const addeparConfigured = isAddeparConfigured(configuration);

  if (!configuration) {
    return <Loader />;
  }

  return (
    <Stack spacing={1} position="relative">
      {isLoggedIn && (
        <Box position="absolute" right={-5} top={-5}>
          <ResyncTenantValuationsButton />
        </Box>
      )}
      <Box textAlign="center">
        <img
          alt="Addepar"
          src={getPublicImageUrl('/addeparLogo.svg')}
          height={30}
        />
      </Box>

      {addeparConfigured && !isEditingConfiguration ? (
        <AddeparIntegrationUserConnection
          onEditConnection={() => setIsEditingConfiguration(true)}
          configuration={configuration}
          isLoggedIn={isLoggedIn}
        />
      ) : (
        <AddeparIntegrationForm
          onEditComplete={() => {
            refetch();
            setIsEditingConfiguration(false);
          }}
          configuration={configuration}
        />
      )}
    </Stack>
  );
}

interface AddeparIntegrationUserConnectionProps {
  isLoggedIn: boolean;
  configuration: AdminIntegrationsPage_IntegrationConfigurationFragment;
  onEditConnection: () => void;
}

function AddeparIntegrationUserConnection({
  isLoggedIn,
  configuration,
  onEditConnection,
}: AddeparIntegrationUserConnectionProps) {
  const getAddeparButtonProps = useCallback(() => {
    const isIntegrationUserLoggedInToAddepar = isLoggedIn;

    const currentPageURL = getCompletePathFromRouteKey(
      'ADMIN_INTEGRATIONS',
      {}
    );

    const queryParams = {
      is_integration_user: 'true',
      redirect: currentPageURL,
    };

    if (isIntegrationUserLoggedInToAddepar) {
      return {
        children: 'Disconnect integration user',
        variant: 'secondary' as const,
        href: getAddeparIntegrationLogoutPath(queryParams),
        method: 'post' as const,
      };
    } else {
      return {
        children: 'Connect integration user',
        variant: 'primary' as const,
        href: getAddeparIntegrationLoginPath(queryParams),
      };
    }
  }, [isLoggedIn]);

  return (
    <Stack spacing={2}>
      <Typography variant="body1">
        Manage the integration user for your Addepar account. This user should
        have access to all clients and portfolio data to enable regular syncing
        of information between systems.
        <br />
        <br />
        Please work with your Luminary team to configure this user correctly.
      </Typography>

      {/* you can only set up the integration user once addepar is configured */}
      <Button
        size="lg"
        target="_self"
        {...getAddeparButtonProps()}
        fullWidth
        external
      />
      <Box textAlign="left">
        <AddeparConfigurationSummary
          onEditConnection={onEditConnection}
          configuration={configuration}
        />
      </Box>
    </Stack>
  );
}
