import { Outlet } from 'react-router-dom';

import { FeedbackProvider } from '@/components/notifications/Feedback/FeedbackProvider';
import { UserWatcher } from '@/modules/analytics/posthog/UserWatcher';
import { ContextualHelpDrawer } from '@/modules/content/components/ContextualHelpDrawer';
import { RouteWatcher } from '@/navigation/RouteWatcher';
import { UnloadPromptProvider } from '@/navigation/UnloadPrompt.provider';

export function BaseLayout() {
  return (
    <FeedbackProvider>
      <UnloadPromptProvider>
        <RouteWatcher>
          <Outlet />
          <ContextualHelpDrawer />
          <UserWatcher />
        </RouteWatcher>
      </UnloadPromptProvider>
    </FeedbackProvider>
  );
}
