import Decimal from 'decimal.js';

import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import { getNodes } from '@/utils/graphqlUtils';

export const getBeneficiariesFromProposal = (proposal: ProposalFragment) => {
  return (
    proposal.entityProposals?.reduce(
      (acc, entityProposal) => {
        const beneficiariesForEntity: {
          id: string;
          displayName: string;
          ownershipPercentageDecimal: Decimal;
          irrevocableTrustName: null | string;
        }[] = [];

        entityProposal.entity.gratTrust?.beneficiaries?.forEach(
          (beneficiary) => {
            const distributionPercentage =
              getNodes(beneficiary.accessParameters)[0]?.percentage ??
              new Decimal(0);
            if (beneficiary.individual) {
              beneficiariesForEntity.push({
                id: beneficiary.individual.id,
                displayName: beneficiary.individual.displayName,
                ownershipPercentageDecimal: distributionPercentage,
                irrevocableTrustName: null,
              });
            } else if (beneficiary.entity) {
              if (
                beneficiary.entity.subtype.__typename !== 'IrrevocableTrust'
              ) {
                // beneficiary is not an irrevocable trust, so we just model it as an individual
                // in the proposal because implementing all possible beneficiary structures on different entities
                // added too much complexity, so we truncate the tree here
                beneficiariesForEntity.push({
                  id: beneficiary.entity.id,
                  displayName: beneficiary.entity.subtype.displayName,
                  ownershipPercentageDecimal: distributionPercentage,
                  irrevocableTrustName: null,
                });

                return;
              }

              const trustBeneficiaries =
                beneficiary.entity.irrevocableTrust?.beneficiaries?.map((b) => {
                  const trustDistributionPercentage =
                    getNodes(b.accessParameters)[0]?.percentage ??
                    new Decimal(0);
                  return {
                    id: b.individual?.id ?? '',
                    displayName: b.individual?.displayName ?? '',
                    ownershipPercentageDecimal: trustDistributionPercentage,
                    irrevocableTrustName:
                      beneficiary.entity?.irrevocableTrust?.displayName ?? '',
                  };
                }) ?? [];

              beneficiariesForEntity.push(...trustBeneficiaries);
            }
          }
        );

        const entityId = entityProposal?.entity?.id ?? '';

        acc[entityId] = beneficiariesForEntity ?? [];

        return acc;
      },
      {} as Record<
        string,
        {
          id: string;
          displayName: string;
          ownershipPercentageDecimal: Decimal;
          irrevocableTrustName: null | string;
        }[]
      >
    ) ?? {}
  );
};
