import { compact } from 'lodash';

import { NormalizedUploadedFileData } from '@/modules/documents/documentUploaderUtils';
import { CreateDocumentInput, DocumentType } from '@/types/schema';

import { SUGGESTION_DOCUMENT_TYPES } from '../documents.constants';

// Interface for inputsWithPriority
export interface InputsWithPriority {
  input: CreateDocumentInput;
  index: number;
  group: number;
  subPriority: number;
}

// Helper function to assign priority based on document type
function getPriority(
  documentType: DocumentType,
  index: number
): {
  group: number;
  subPriority: number;
} {
  switch (documentType) {
    case DocumentType.SignedTrustDocument:
      return { group: 1, subPriority: 1 };
    case DocumentType.LlcAgreement:
      return { group: 1, subPriority: 2 };
    case DocumentType.OperatingAgreement:
      return { group: 1, subPriority: 3 };
    case DocumentType.PartnershipAgreement:
      return { group: 1, subPriority: 4 };
    case DocumentType.AssignmentOfInterest:
      return { group: 2, subPriority: 1 };
    default:
      return { group: 3, subPriority: index }; // Maintain original order for others
  }
}

/**
 * Converts the uploaded files into CreateDocumentInputs
 * @param uploadedFiles
 * @param fileIDTypeMap
 * @param defaultDocumentType
 * @param householdId
 * @param suggestionsEnabled
 */
export function convertUploadedFilesToDocumentInputs({
  uploadedFiles,
  fileIDTypeMap,
  defaultDocumentType,
  householdId,
  suggestionsEnabled,
  entityId,
}: {
  uploadedFiles: NormalizedUploadedFileData[];
  fileIDTypeMap: Record<string, DocumentType>;
  defaultDocumentType: DocumentType;
  householdId: string;
  suggestionsEnabled: boolean;
  entityId?: string;
}): CreateDocumentInput[] {
  // Assign index to maintain original order
  const uploadedFilesWithIndex = uploadedFiles.map((uploadedFile, index) => ({
    uploadedFile,
    index,
  }));

  // Map to inputs with index and priority
  const inputsWithPriority: InputsWithPriority[] = compact(
    uploadedFilesWithIndex.map(({ uploadedFile, index }) => {
      const { fileId, fileName } = uploadedFile;
      const documentType = fileIDTypeMap[fileId] || defaultDocumentType;

      const { group, subPriority } = getPriority(documentType, index);

      return {
        input: {
          fileID: fileId,
          householdID: householdId,
          enableAiSuggestions:
            suggestionsEnabled &&
            SUGGESTION_DOCUMENT_TYPES.includes(documentType),
          type: documentType,
          name: fileName,
          entityID: entityId,
        },
        index,
        group,
        subPriority,
      };
    })
  );

  // Sort the inputs based on group and subPriority
  inputsWithPriority.sort((a, b) => {
    if (a.group !== b.group) {
      return a.group - b.group; // Lower group number comes first
    } else if (a.subPriority !== b.subPriority) {
      return a.subPriority - b.subPriority; // Lower subPriority comes first
    } else {
      return a.index - b.index; // Maintain original order if needed
    }
  });

  // After sorting, set aiSuggestionOrder based on index in sorted list
  inputsWithPriority.forEach((item, index) => {
    item.input.aiSuggestionOrder = index;
  });

  // Extract the sorted inputs
  return inputsWithPriority.map(({ input }) => input);
}
