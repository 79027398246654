import { Box, BoxProps } from '@mui/material';

export function MainContentWrapper(props: BoxProps) {
  return (
    <Box
      component="main"
      {...props}
      sx={{
        width: '100%',
        height: '100vh',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        ...(props.sx || {}),
      }}
    />
  );
}
