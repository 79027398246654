import { Box, Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { Card } from '@/components/layout/Card/Card';
import { RibbonCard } from '@/components/layout/RibbonCard';
import { Footer } from '@/components/navigation/Footer';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { LoadingSkeletonOrValue } from '@/components/progress/LoadingSkeletonOrValue/LoadingSkeletonOrValue';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { entityHasAssetsIntegration } from '@/modules/assetProviderIntegrations/shared/utils';
import { mapQueryAssetsToAssets } from '@/modules/assets/utils';
import { AssetFullScreenModal } from '@/modules/assetValuation/AssetFullScreenModal/AssetFullScreenModal';
import { getAssetMutationUpdates } from '@/modules/assetValuation/AssetFullScreenModal/serializers';
import { AssetValuationTable } from '@/modules/assetValuation/AssetValuationTable/AssetValuationTable';
import { UpdateHoldingsButton } from '@/modules/assetValuation/UpdateHoldingsButton/UpdateHoldingsButton';
import { normalizeValuation } from '@/modules/assetValuation/useAssetValuation';
import { DocumentRepresentationList } from '@/modules/files/DocumentRepresentation/DocumentRepresentationList';
import {
  AssetValuationV2ValuationReason,
  DocumentType,
  EntityStage,
  UpdateEntityTaskInput,
} from '@/types/schema';
import { DocumentWhereInput } from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';
import { getNodes } from '@/utils/graphqlUtils';

import { FullScreenTaskLeftPaneLayout } from '../components/FullScreenTaskLeftPaneLayout/FullScreenTaskLeftPaneLayout';
import { FullScreenTaskLayout } from '../FullScreenTaskLayout';
import { useUpdateTaskMutation } from '../graphql/TaskQueries.generated';
import { UNASSIGNED_TASK_SENTINEL } from '../tasks.constants';
import { BeneficiaryDistributionSummary } from './BeneficiaryDistributionSummary';
import { DistributeTrustRemainderTaskSummary } from './DistributeTrustTaskSummary';
import {
  GetGratDistributeTrustRemainderTaskDetailsQuery,
  TrustRemainderTask_AnnuityPaidOnFragment,
  TrustRemainderTask_EntityFragment,
  TrustRemainderTask_EntityTaskFragment,
  TrustRemainderTask_GratTrustFragment,
  useGetGratDistributeTrustRemainderTaskDetailsQuery,
  useUpdateGratDistributeTrustRemainderDetailsMutation,
} from './graphql/GRATDistributeTrustRemainderTask.generated';

export interface GRATDistributeTrustRemainderTaskProps {
  taskId: string;
  onCompleteTask: () => void;
  onClose: () => void;
  entityId: string;
  householdId: string;
}

interface DistributeTrustRemainderTaskForm {
  dueDate: Date | null;
  assigneeId: string;
  distributionDate: Date | null;
}

function getGuardedTaskData(
  data?: GetGratDistributeTrustRemainderTaskDetailsQuery
) {
  if (data?.entity?.__typename !== 'Entity') return null;
  if (data.entity.subtype.__typename !== 'GRATTrust') return null;

  return {
    entity: data.entity as TrustRemainderTask_EntityFragment,
    subtype: data.entity.subtype as TrustRemainderTask_GratTrustFragment,
    task: data.task as TrustRemainderTask_EntityTaskFragment,
  };
}

function areAllAnnuitiesCompletedForStrategy(
  annuityDetails: TrustRemainderTask_AnnuityPaidOnFragment[]
) {
  return annuityDetails.every((annuity) => !!annuity.paidOn);
}

export function GRATDistributeTrustRemainderTask({
  onClose,
  taskId,
  entityId,
  householdId,
  onCompleteTask,
}: GRATDistributeTrustRemainderTaskProps) {
  const [isUpdateHoldingsModalOpen, setIsUpdateHoldingsModalOpen] =
    useState(false);
  const { createErrorFeedback } = useFeedback();
  const {
    data,
    loading: formContentLoading,
    error: loadFormContentError,
  } = useGetGratDistributeTrustRemainderTaskDetailsQuery({
    variables: {
      taskId,
      entityId,
    },
  });

  const onError = createErrorFeedback(
    'We failed to save the task updates. Please refresh the page and try again.'
  );
  const [
    completeTask,
    { loading: completeTaskLoading, error: completeTaskError },
  ] = useUpdateGratDistributeTrustRemainderDetailsMutation({ onError });
  const [updateTask, { loading: updateTaskLoading, error: updateTaskError }] =
    useUpdateTaskMutation({ onError });

  useReportError(
    'failed to complete the distribute trust remainder task',
    completeTaskError,
    {
      entityId,
      taskId,
    }
  );

  const relatedTrustDocuments: DocumentWhereInput = {
    type: DocumentType.SignedTrustDocument,
    hasEntityWith: [
      {
        id: entityId,
      },
    ],
  };

  useReportError(
    'failed to update the distrubute trust remainder task',
    updateTaskError,
    {
      entityId,
      taskId,
    }
  );

  const formMethods = useForm<DistributeTrustRemainderTaskForm>({
    defaultValues: {
      dueDate: null,
      distributionDate: null,
      assigneeId: '',
    },
  });

  const { control, handleSubmit, resetField } = formMethods;

  const taskDetails = useMemo(() => getGuardedTaskData(data), [data]);

  useEffect(
    function syncDataToForm() {
      if (!taskDetails) return;
      resetField('dueDate', {
        defaultValue: taskDetails.task.dueAt,
      });
      resetField('assigneeId', {
        defaultValue:
          taskDetails.task.assignedTo?.id ?? UNASSIGNED_TASK_SENTINEL,
      });
      resetField('distributionDate', {
        defaultValue: taskDetails?.subtype.distributionDate,
      });
    },
    [taskDetails, resetField, taskId]
  );

  // todo wire this up and prevent completion if all the annuity payments aren't done
  const isValidCompletionState = areAllAnnuitiesCompletedForStrategy(
    taskDetails?.subtype?.annuities ?? []
  );
  const submitInProgress = completeTaskLoading || updateTaskLoading;
  const taskUpdateDisabled =
    formContentLoading || !!loadFormContentError || submitInProgress;
  const taskCompletionDisabled = taskUpdateDisabled || !isValidCompletionState;
  const mostRecentValue =
    taskDetails?.subtype?.mostRecentValuation?.valuationValue ?? null;
  const summaryBeneficiaries = useMemo(() => {
    return (
      taskDetails?.subtype.beneficiaries?.map((beneficiary) => {
        if (!mostRecentValue) {
          throw new Error('No most recent valuation found');
        }
        const accessPercentage = getNodes(beneficiary.accessParameters)[0]
          ?.percentage;
        if (!accessPercentage) {
          throw new Error('Invalid GRAT beneficiary');
        }

        let remainderValue: Decimal | null = mostRecentValue.times(
          accessPercentage.dividedBy(100)
        );

        if (!isValidCompletionState) {
          remainderValue = null;
        }

        return {
          ...beneficiary.summary,
          remainderValue,
          id: beneficiary.summary.id,
        };
      }) ?? []
    );
  }, [taskDetails, mostRecentValue, isValidCompletionState]);

  async function submitForm(
    values: DistributeTrustRemainderTaskForm,
    opts = { saveChangesOnly: false }
  ) {
    if (!taskDetails) {
      throw new Error(
        'Attemping to edit task without strategy and task details'
      );
    }

    // if the task isn't ready to be completed, then we only want to update the task data (e.g. assignee
    // and due date), not actually update the distribution date and mark the task as completed
    const updateTaskInput: UpdateEntityTaskInput = {
      assignedToID:
        values.assigneeId === UNASSIGNED_TASK_SENTINEL
          ? null
          : values.assigneeId,
      clearAssignedTo: values.assigneeId === UNASSIGNED_TASK_SENTINEL,
      dueAt: values.dueDate,
    };

    if (opts.saveChangesOnly) {
      await updateTask({
        variables: {
          input: {
            id: taskDetails.task.id,
            update: updateTaskInput,
          },
        },
      });

      return onCompleteTask();
    } else {
      if (!taskDetails.subtype?.mostRecentValuation) {
        throw new Error('Missing required strategyInstance valuation');
      }

      if (!values.distributionDate) {
        throw new Error('Missing required value distributionDate');
      }

      // The things we want to accomplish when distributing a trust's remainder are:
      // 1) Associate the current valuation as the "distributed value" -- the amount that was given out to beneficiaries
      // 2) Set the distribution date
      // 3) Zero out the assets in the trust, since they've all been distributed
      // 4) Mark the strategy as completed.
      const normalizedValuation = normalizeValuation(
        taskDetails.subtype.mostRecentValuation
      );
      const zeroedOutAssetsValue = mapQueryAssetsToAssets(
        normalizedValuation.assetValues,
        {
          markAsConfirmed: true,
          setValuesToZero: true,
        }
      );
      const { newAssets, existingAssetsCreate } =
        getAssetMutationUpdates(zeroedOutAssetsValue);

      if (!taskDetails.subtype.designerAccount) {
        throw new Error('Missing required designer account');
      }

      await completeTask({
        variables: {
          taskId: taskId,
          entityUpdate: {
            id: entityId,
            update: {
              stage: EntityStage.Completed,
            },
            updateGratTrust: {
              id: taskDetails.subtype.id,
              update: {
                distributionDate: values.distributionDate,
                distributionAssetValuationID:
                  taskDetails.subtype.mostRecentValuation.id,
              },
              // TODO (LUM-1296) -- we'll likely need special handling for addepar here
              updateDesignerAccount: {
                id: taskDetails.subtype.designerAccount?.id,
                update: {},
                withValuations: [
                  {
                    create: {
                      effectiveDate: values.distributionDate,
                      valuationReason:
                        AssetValuationV2ValuationReason.GratRemainderDistribution,
                    },
                    withAssets: [...newAssets, ...existingAssetsCreate],
                  },
                ],
              },
            },
          },
          taskUpdate: {
            id: taskDetails.task.id,
            update: updateTaskInput,
          },
        },
      });

      onCompleteTask();
    }
  }

  const onSubmit = handleSubmit((values) => submitForm(values));
  const onSaveChanges = handleSubmit((values) =>
    submitForm(values, { saveChangesOnly: true })
  );

  return (
    <FormProvider {...formMethods}>
      {isUpdateHoldingsModalOpen && taskDetails && (
        <AssetFullScreenModal
          subtypeId={taskDetails.subtype.id}
          entityId={entityId}
          householdId={taskDetails.entity.household.id}
          assetsSubformType="updateHoldings"
          isOpen={isUpdateHoldingsModalOpen}
          onClose={() => setIsUpdateHoldingsModalOpen(false)}
        />
      )}
      <Stack height="100%">
        <FullScreenTaskLayout
          LeftPaneContent={
            <FullScreenTaskLeftPaneLayout
              householdId={householdId}
              heading="Distribute remainder"
              subheading="The remainder amount is the latest valuation for the GRAT."
              body={
                <Stack my={3} spacing={2}>
                  <Box mb={3}>
                    <DistributeTrustRemainderTaskSummary
                      gratTrust={taskDetails?.subtype ?? null}
                      loading={formContentLoading}
                    />
                  </Box>
                  <AssetValuationTable
                    entityId={entityId}
                    assetValuationId={
                      taskDetails?.subtype?.mostRecentValuation?.id ?? null
                    }
                    variant="compact"
                    hideValues={!isValidCompletionState}
                  />
                  {!entityHasAssetsIntegration(taskDetails?.entity) && (
                    <UpdateHoldingsButton
                      variant="secondary"
                      size="sm"
                      onClick={() => setIsUpdateHoldingsModalOpen(true)}
                    />
                  )}
                </Stack>
              }
              control={control}
              taskDescription="Specify an internal employee responsible for completing the remainder distribution"
            />
          }
          RightPaneContent={
            <RibbonCard heading="Confirm distribution to beneficiaries">
              <Stack spacing={3}>
                {!isValidCompletionState && (
                  <Callout severity="error">
                    This task cannot be completed until the final annuity
                    payment for this GRAT has been marked as paid.
                  </Callout>
                )}
                <Card variant="filled-callout" sx={{ p: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <OversizedMetricItem
                      title="Remainder distribution"
                      value={
                        <LoadingSkeletonOrValue
                          loading={formContentLoading}
                          value={
                            mostRecentValue && isValidCompletionState
                              ? formatCurrency(mostRecentValue)
                              : null
                          }
                        />
                      }
                    />
                    <FormAwareDatePickerInput
                      control={control}
                      required
                      label="Distribution date"
                      disabled={taskCompletionDisabled}
                      fieldName="distributionDate"
                      validation={{
                        required: (value) => {
                          if (taskCompletionDisabled) return undefined;
                          if (!value) return `Distribution date is required.`;
                          return undefined;
                        },
                      }}
                    />
                  </Stack>
                </Card>
                <BeneficiaryDistributionSummary
                  summaryBeneficiaries={summaryBeneficiaries}
                />
                <Stack alignItems="center">
                  <Box width="80%">
                    <DocumentRepresentationList
                      documentsLike={relatedTrustDocuments}
                    />
                  </Box>
                </Stack>
              </Stack>
            </RibbonCard>
          }
          Footer={
            <Footer
              leftAction={
                <Button
                  disabled={submitInProgress}
                  onClick={onClose}
                  variant="secondary"
                  size="sm"
                >
                  Exit without saving
                </Button>
              }
              rightAction={
                <Stack direction="row" spacing={3}>
                  <Button
                    disabled={taskUpdateDisabled}
                    onClick={onSaveChanges}
                    variant="transparent"
                    size="sm"
                  >
                    Save changes
                  </Button>
                  <Button
                    disabled={taskCompletionDisabled}
                    onClick={onSubmit}
                    variant="primary"
                    size="sm"
                  >
                    Mark as done & close GRAT
                  </Button>
                </Stack>
              }
            />
          }
        />
      </Stack>
    </FormProvider>
  );
}
