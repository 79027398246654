import { isUndefined } from 'lodash';

// splitting the type up like this so one of min or max length is always required
interface BaseType {
  fieldName?: string;
}

interface MinType extends BaseType {
  min: number;
  max?: number;
}

interface MaxType extends BaseType {
  min?: number;
  max: number;
}

export type ValidateStringLengthType = MinType | MaxType;

export function validateStringLength({
  fieldName = 'String',
  min,
  max,
}: ValidateStringLengthType) {
  return (value: unknown): string | undefined => {
    const length = value?.toString()?.length;
    if (isUndefined(length)) {
      return `${fieldName} is required`;
    }

    if (!isUndefined(min) && length < min) {
      return `${fieldName} must be at least ${min} characters`;
    }

    if (!isUndefined(max) && length > max) {
      return `${fieldName} must be no more than ${max} characters`;
    }

    return undefined;
  };
}
