import { Box, Stack } from '@mui/material';
import React, { useMemo, useState } from 'react';
import {
  FieldArrayWithId,
  FieldValues,
  UseFieldArrayReturn,
} from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { useFormFieldsDisabled } from '@/components/form/context/formFieldsDisabled.context';
import { AdditionalItemRendererProps } from '@/components/form/formAwareInputs/FormAwareInput.types';
import { FormAwareInputRepeaterProps } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import { XCircleIcon } from '@/components/icons/XCircleIcon';
import { ArrayFieldNameFromFormShape } from '@/types/react-hook-form';

import { iconSizeByButtonSize } from '../../baseInputs/Button/styles';

const LABEL_OFFSET_HEIGHT = 24;
const SINGLE_LINE_THRESHOLD_HEIGHT = 65;

export interface InputFieldItemProps<
  FormShape extends FieldValues,
  FieldArrayKeyName extends string,
> {
  i: number;
  value: FieldArrayWithId<
    FormShape,
    ArrayFieldNameFromFormShape<FormShape>,
    FieldArrayKeyName
  >;
  render: FormAwareInputRepeaterProps<FormShape, FieldArrayKeyName>['render'];
  fieldArrayProps: Pick<
    UseFieldArrayReturn<
      FormShape,
      ArrayFieldNameFromFormShape<FormShape>,
      FieldArrayKeyName
    >,
    'swap' | 'fields' | 'remove'
  >;
  fieldArrayKeyName: FieldArrayKeyName;
  showDeleteButton: boolean;
  additionalItemRendererProps?: AdditionalItemRendererProps;
  // If true, the delete button will be centered on the inputs
  isSingleLineRepeatedInput?: boolean;
  // An optional callback when the "delete" button is clicked. This is mainly
  // used to track which item was deleted from a DuplicatedInputFieldGroup.
  onDeleteItemClick?: () => void;
}

/**
 * A single input item of a FormAwareInputRepeater.
 */
export function InputFieldItem<
  FormShape extends FieldValues,
  FieldArrayKeyName extends string,
>({
  i,
  value,
  render,
  fieldArrayProps,
  fieldArrayKeyName,
  showDeleteButton,
  additionalItemRendererProps,
  isSingleLineRepeatedInput,
  onDeleteItemClick,
}: InputFieldItemProps<FormShape, FieldArrayKeyName>) {
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const [height, setHeight] = useState(0);

  const shouldOffsetForLabel = isSingleLineRepeatedInput && i === 0;

  const [offsetCloseIconForLocator, setOffsetCloseIconForLocator] =
    useState(false);

  const Item = useMemo(() => {
    return render(i, fieldArrayProps, value[fieldArrayKeyName], {
      ...additionalItemRendererProps,
      onRenderHasLocatorButton: setOffsetCloseIconForLocator,
    });
  }, [
    additionalItemRendererProps,
    fieldArrayKeyName,
    fieldArrayProps,
    i,
    render,
    value,
  ]);

  return (
    <Stack
      direction="row"
      alignItems="end"
      key={value[fieldArrayKeyName] ?? i}
      sx={{ mt: 1 }}
      data-testid="repeater-input-item"
    >
      <Box
        ref={(node: HTMLDivElement | undefined) => {
          setHeight(node?.getBoundingClientRect().height ?? 0);
        }}
        flexGrow={1}
      >
        {Item}
      </Box>
      {showDeleteButton && (
        // Simple rule to prevent noticable shift in the remove button when input is single line
        <Stack
          alignSelf={height > SINGLE_LINE_THRESHOLD_HEIGHT ? 'center' : 'start'}
          px={1}
        >
          {offsetCloseIconForLocator && <Box height="26px" />}
          {shouldOffsetForLabel && height <= SINGLE_LINE_THRESHOLD_HEIGHT && (
            <Box height={`${LABEL_OFFSET_HEIGHT}px`} />
          )}
          <Button
            variant="transparent"
            size="sm"
            onClick={() => {
              fieldArrayProps.remove(i);
              onDeleteItemClick?.();
            }}
            aria-label="Remove"
            disabled={disabledFromContext}
            data-testid="button-remove-repeater-input-item"
            square
          >
            <XCircleIcon size={iconSizeByButtonSize.sm} />
          </Button>
        </Stack>
      )}
    </Stack>
  );
}
