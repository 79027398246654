import { Callout } from '@/components/notifications/Callout/Callout';

export function RollingGRATScenarioExplanation() {
  return (
    <Callout severity="warning">
      Annuity payments detailed above represent the initial GRAT in this rolling
      GRAT series. Annuity payments are assumed to roll into new GRATs, such
      that the final GRAT annuity payment is made at the end of the last year of
      the rolling period. The analysis assumes a fixed 7520 rate during the
      rolling period and also assumes that any taxable gift entered is applied
      to the first GRAT and all subsequent GRATs are “zeroed-out” for gift tax
      purposes.
    </Callout>
  );
}
