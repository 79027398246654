import Decimal from 'decimal.js';

import { SimplePieChart } from '@/components/charts/PieChart/SimplePieChart';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

interface EstateValuePieChartProps {
  pieChartSeriesData: {
    value: number;
    label: string;
    color: string;
  }[];
  onePercentOfSum: Decimal;
  size?: number;
}

export function EstateValuePieChart({
  pieChartSeriesData,
  onePercentOfSum,
  size = 60,
}: EstateValuePieChartProps) {
  return (
    <SimplePieChart
      size={size}
      series={[
        {
          valueFormatter: (r) => {
            if (r.value === onePercentOfSum.toNumber()) {
              // We modify the series data when the slice is less than or equal to 1% so the displayed slice is equal
              // to the value of one percent of the sum of the three values. We lose the original value of the slice
              // so we display <1% in the tooltip.
              return '<1%';
            }

            return formatCurrencyNoDecimals(new Decimal(r.value));
          },
          data: pieChartSeriesData,
        },
      ]}
    />
  );
}
