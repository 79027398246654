import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';

import { SubtleLabelItem } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { CalloutListCard } from '@/components/layout/CalloutListCard/CalloutListCard';
import { LoadingSkeletonOrValue } from '@/components/progress/LoadingSkeletonOrValue/LoadingSkeletonOrValue';
import { CONTROLLING_PERSONS_LABEL } from '@/modules/entities/EntitySubforms/utils/shared/controllingParties.utils';
import {
  SummaryContainer,
  SummaryWrapper,
} from '@/modules/trusts/TrustDetails/TrustDetails.utils';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { NonTrustCharitableEntitySummaryData } from './NonTrustCharitableEntityDetails.types';

export type NonTrustCharitableEntitySummaryProps = Omit<
  NonTrustCharitableEntitySummaryData,
  'detailsItems'
>;

/**
 *
 * @description This component is responsible for rendering the properties that are shared
 * between all the different types of charitable entities, and so takes specific property names rather
 * than a generalized set of labels and values.
 *
 */
export function NonTrustCharitableEntitySummary({
  loading = false,
  taxStatus,
  legalName,
  entityType,
  donorNames,
  effectiveDate,
  controllingPartyNames,
  description,
  assetLocation,
}: NonTrustCharitableEntitySummaryProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Box>
        <Stack spacing={2}>
          <SubtleLabelItem
            label="Legal name"
            value={
              <LoadingSkeletonOrValue loading={loading} value={legalName} />
            }
          />
          <SubtleLabelItem
            label="Entity type"
            value={
              <LoadingSkeletonOrValue loading={loading} value={entityType} />
            }
          />
          <SubtleLabelItem
            label="Donor(s)"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={donorNames?.join(', ') ?? ''}
              />
            }
          />
          <SubtleLabelItem
            label="Effective Date"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={
                  effectiveDate
                    ? formatDateToMonDDYYYY(effectiveDate)
                    : undefined
                }
              />
            }
          />
          <SubtleLabelItem
            label={CONTROLLING_PERSONS_LABEL}
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={controllingPartyNames?.join(', ') ?? ''}
              />
            }
          />
          <SubtleLabelItem
            label="Tax status"
            value={
              <LoadingSkeletonOrValue
                loading={loading}
                value={taxStatus?.value ?? ''}
              />
            }
          />
          <SubtleLabelItem
            label="Asset location"
            value={
              <LoadingSkeletonOrValue loading={loading} value={assetLocation} />
            }
          />
        </Stack>
        {description && (
          <Box mt={3}>
            <Typography variant="body2">{description}</Typography>
          </Box>
        )}
      </Box>
    </Stack>
  );
}

export function NonTrustCharitableEntitySummaryWithWrapper(
  props: NonTrustCharitableEntitySummaryData
) {
  const { loading, detailsItems, onShowEditEntity, ...rest } = props;

  useEffect(() => {
    onShowEditEntity?.();
  }, [onShowEditEntity]);

  return (
    <SummaryWrapper>
      <SummaryContainer>
        <Box flexGrow={1} mt={2}>
          <NonTrustCharitableEntitySummary loading={!!loading} {...rest} />
        </Box>
        <Box>
          <CalloutListCard loading={!!loading} items={detailsItems} />
        </Box>
      </SummaryContainer>
    </SummaryWrapper>
  );
}
