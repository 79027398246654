import { AiOnboarding_KgRootFragment } from '@/modules/aiOnboarding/graphql/aiOnboarding.generated';
import {
  AsyncJobNotificationInfo,
  AsyncJobNotificationInfoType,
  AsyncJobNotificationStatus,
} from '@/modules/asyncJobs/AsyncJobNotifications/asyncJobNotifications.types';
import { KgRootStatus } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { pluralize } from '@/utils/formatting/strings';

/**
 * Maps information about an AIOnboarding job to an AsyncJobNotificationInfo
 * @param input
 */
export function aiOnboardingNotificationMapper(
  input: AiOnboarding_KgRootFragment
): AsyncJobNotificationInfo {
  if (!input.asyncJob) {
    throw new Error('KGRoot must have an asyncJob');
  }

  const numDocs = input.files?.length ?? 0;
  const docPlural = pluralize(numDocs, 'document');

  return {
    id: input.id,
    type: AsyncJobNotificationInfoType.AI_ONBOARDING,
    status: mapKGRootStatusToNotificationStatus(input.status),
    estimatedDurationMs: 2 * 60 * 1000, // estimate
    household: input.household,
    asyncJob: input.asyncJob,
    newNotificationMessage: `Processing ${numDocs} ${docPlural}`,
    completedNotificationMessage: `Completed processing of ${numDocs} ${docPlural}`,
    formatBannerMessage: (bannerNoun: string) =>
      `Luminary AI finished analyzing ${bannerNoun} from ${numDocs} ${docPlural}`,
  };
}

/**
 * Maps a KGRoot status to a NotificationStatus
 * @param status
 */
function mapKGRootStatusToNotificationStatus(
  status: KgRootStatus
): AsyncJobNotificationStatus {
  switch (status) {
    case KgRootStatus.InProgress:
      return AsyncJobNotificationStatus.IN_PROGRESS;
    case KgRootStatus.Complete:
      return AsyncJobNotificationStatus.COMPLETED;
    case KgRootStatus.SuggestionReviewComplete:
      return AsyncJobNotificationStatus.ACKNOWLEDGED;
    default:
      throw new UnreachableError({
        case: status,
        message: `Unknown KGRoot status for notifications: ${status}`,
      });
  }
}
