import { ComponentProps, ReactNode, useMemo } from 'react';

import { FlowChartWrapper } from '@/components/diagrams/FlowChart/components/FlowChartWrapper';

export interface EstateWaterfallFlowChartWrapperProps {
  height: number;
  children: ReactNode;
  presentationMode?: boolean;
}

export const EstateWaterfallFlowChartWrapper = ({
  height,
  children,
  presentationMode = false,
}: EstateWaterfallFlowChartWrapperProps) => {
  const variant: ComponentProps<typeof FlowChartWrapper>['variant'] =
    useMemo(() => {
      return presentationMode ? 'transparent' : 'inner-shadow';
    }, [presentationMode]);

  return (
    <FlowChartWrapper style={{ height }} variant={variant}>
      {children}
    </FlowChartWrapper>
  );
};
