import { $generateHtmlFromNodes } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot, createCommand, LexicalCommand } from 'lexical';
import { useCallback, useEffect } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { ClipboardAPIError } from '@/utils/errors';

import { removeNestedListItems } from './copyAllPlugin.utils';

const LowPriority = 1;

export const COPY_ALL_COMMAND: LexicalCommand<undefined> = createCommand();
export const COPY_ALL_EVENT = 'copy-all';

export function CopyAllPlugin() {
  const [editor] = useLexicalComposerContext();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const copyAll = useCallback(() => {
    try {
      if (!navigator.clipboard) {
        // If the clipboard API is not supported, throw an error
        throw new ClipboardAPIError();
      }

      editor.update(async () => {
        // Generate the HTML from of the target editor using the $generateHtmlFromNodes
        // from the @lexical/html package. This makes sure we inline all the styles
        // so the styling is preserved when pasted into Google Docs, Word, etc.
        const htmlString = $generateHtmlFromNodes(editor, null);

        const plainTextString = editor
          .getEditorState()
          .read(() => $getRoot().getTextContent());

        // Do some magic to set set multiple clipboard types that can
        // be pasted into different applications
        const clipboardData = {
          'text/plain': new Blob([plainTextString as BlobPart], {
            type: 'text/plain', // Makes for nice pasting into plain text editors
          }),
          'text/html': new Blob(
            [removeNestedListItems(htmlString) as BlobPart],
            {
              type: 'text/html', // Makes for nice pasting into Google Docs, Word, etc.
            }
          ),
        };

        // Write the clipboard item to the clipboard
        await navigator.clipboard.write([new ClipboardItem(clipboardData)]);
        showFeedback('Copied to clipboard', {
          variant: 'success',
        });
      });
    } catch (err) {
      if (err instanceof ClipboardAPIError) {
        showFeedback(
          'Failed to copy all content. Your browser is unsupported, use a supported browser such as Google Chrome.'
        );
        reportError('Failed to copy all content, unsupported browser', err);
        return;
      }

      showFeedback('Failed to copy all content');
      reportError('Failed to copy all content', err as Error);
    }
  }, [editor, reportError, showFeedback]);

  useEffect(() => {
    // Register a custom command that will copy all the content of the editor
    return editor.registerCommand(
      COPY_ALL_COMMAND,
      () => {
        copyAll();
        return true;
      },
      LowPriority
    );
  }, [copyAll, editor]);

  useEffect(() => {
    const copyListener = () => {
      // Dispatch the custom COPY_ALL_COMMAND command when the COPY_ALL_EVENT event is fired
      editor.dispatchCommand(COPY_ALL_COMMAND, undefined);
    };

    // Register a listener attached to the root ContentEditable element
    // of the editor that listens for the custom COPY_ALL_EVENT event.
    // This allows us to trigger the copyAll function from outside the editor's context.
    return editor.registerRootListener((rootElement, prevRootElement) => {
      rootElement?.addEventListener(COPY_ALL_EVENT, copyListener);
      prevRootElement?.removeEventListener(COPY_ALL_EVENT, copyListener);
    });
  }, [editor]);

  return null;
}
