import Decimal from 'decimal.js';
import { compact } from 'lodash';

import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { Copy07Icon } from '@/components/icons/Copy07Icon';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { ActionButtonRenderer } from '@/components/tables/DataTable/renderers/cell/ActionButtonRenderer';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { GrowthProfileGrowthType } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { formatPercent } from '@/utils/formatting/percent';

import { GrowthProfilesModalData } from './GrowthProfilesTable';
import { GrowthProfileDeleteMenuItem } from './GrowthProfilesTable.components';
import {
  GrowthProfilesTableColumns,
  RowData,
} from './GrowthProfilesTable.constants';

export interface GetGrowthProfilesTableColumnsArgs {
  openGrowthProfileModal: (arg: GrowthProfilesModalData) => void;
  openDeleteUsedGrowthProfileModal: (row: RowData) => void;
}

export function getGrowthProfilesTableColumns({
  openGrowthProfileModal,
  openDeleteUsedGrowthProfileModal,
}: GetGrowthProfilesTableColumnsArgs): Column<RowData>[] {
  return [
    {
      field: GrowthProfilesTableColumns.NAME,
      headerName: 'Name',
      width: 200,
      flex: 1,
      renderCell: TwoLineTextRenderer<RowData>({
        lineOne: ({ row }) => row.displayName,
        lineTwo: ({ row }) => {
          switch (row.growthType) {
            case GrowthProfileGrowthType.GlobalGrowth:
              return `Uses global growth rate of ${formatPercent(row.assetGrowthReturn || new Decimal(0), 2)}% for all entities`;
            case GrowthProfileGrowthType.CustomGrowth:
              return 'Uses custom asset category and entity growth rates';
            case GrowthProfileGrowthType.DefaultGrowth:
              return 'Uses asset category rates set at firm level';
            default: {
              throw new UnreachableError({
                case: row.growthType,
                message: 'Unknown growth type',
              });
            }
          }
        },
        rightContent: ({ row }) =>
          row.isDefault ? (
            <Badge variant={BadgeVariants.Primary} display="Default" />
          ) : undefined,
      }),
    },
    {
      field: GrowthProfilesTableColumns.EXEMPTION_GROWTH_RATE,
      headerName: 'Exemption growth rate',
      width: 150,
      flex: 1,
      renderCell: TwoLineTextRenderer<RowData>({
        lineOne: ({ row }) => `${formatPercent(row.exemptionGrowthRate, 2)}%`,
        lineTwo: ({ row }) =>
          row.willExemptionSunset ? 'Sunsets in 2025' : null,
      }),
    },
    {
      field: GrowthProfilesTableColumns.CREATED_BY,
      headerName: 'Created by',
      width: 150,
      flex: 1,
      renderCell: TextRenderer<RowData>({
        text: ({ row }) => {
          if (row.isDefault) {
            return EMPTY_CONTENT_HYPHEN;
          }
          return row.user.displayName;
        },
      }),
    },
    {
      field: 'id',
      width: 64,
      maxWidth: 64,
      minWidth: 64,
      sortable: false,
      renderCell: ActionButtonRenderer<RowData>({
        actions: compact([
          {
            MenuItem: ({ id: growthProfileId }) => (
              <MenuItem
                icon={<Copy07Icon />}
                key="menu-item-duplicate"
                label="Duplicate profile"
                onClick={() =>
                  openGrowthProfileModal({
                    isDuplicating: true,
                    growthProfileId,
                  })
                }
              />
            ),
          },
          {
            MenuItem: (row) => (
              <GrowthProfileDeleteMenuItem
                growthProfileId={row.id}
                isDefault={row.isDefault}
                estateWaterfalls={row.estateWaterfalls}
                openDeleteUsedGrowthProfileModal={() =>
                  openDeleteUsedGrowthProfileModal(row)
                }
              />
            ),
          },
        ]),
      }),
    },
    {
      field: 'action',
      width: 64,
      maxWidth: 64,
      minWidth: 64,
      sortable: false,
      renderCell: IconRenderer({
        icon: ChevronRightIcon,
      }),
    },
  ];
}
