import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface InputRepeaterSumRowProps {
  content: ReactNode;
}

/**
 * @description This component is used to display a passed-in ReactNode. It is a simple presentational component.
 */
export function InputRepeaterSumRow({ content }: InputRepeaterSumRowProps) {
  return (
    <Box
      sx={{
        pt: 0.5,
        pl: 2,
        borderTop: 'solid black 2px',
      }}
    >
      <Typography variant="h4" component="span">
        {content}
      </Typography>
    </Box>
  );
}
