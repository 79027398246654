import { Box } from '@mui/material';
import { useMemo } from 'react';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { TableEmptyState } from '@/components/tables/DataTable/TableEmptyState';
import { UnreachableError } from '@/utils/errors';

import { useManageDispositionsContext } from '../context/ManageDispositions.context';
import {
  mapEntitiesToRows,
  mapIndividualsToRows,
  useEntitiesTableColumns,
} from './ManageDispositionsEntitiesTable.utils';

type ManageDispositionsEntitiesTableVariant = 'in-estate' | 'out-of-estate';
interface ManageDispositionsEntitiesTableProps {
  variant: ManageDispositionsEntitiesTableVariant;
}

function EntitiesEmptyState({
  variant,
}: {
  variant: ManageDispositionsEntitiesTableVariant;
}) {
  return (
    <TableEmptyState
      text={
        variant === 'in-estate'
          ? 'No in-estate entities'
          : 'No out of estate entities'
      }
    />
  );
}

export function ManageDispositionsEntitiesTable({
  variant,
}: ManageDispositionsEntitiesTableProps) {
  const {
    activeClientDeathId,
    primaryClientRows: allPrimaryClientRows,
    isLoading,
  } = useManageDispositionsContext();
  const columns = useEntitiesTableColumns();
  const relevantEntities = useEntitiesForVariant(variant);
  const rows = useMemo(() => {
    const entityRows = mapEntitiesToRows(relevantEntities, activeClientDeathId);

    // only show the client rows for the in-estate table variant
    if (variant !== 'in-estate') return entityRows;
    const visiblePrimaryClientRows = mapIndividualsToRows(
      allPrimaryClientRows,
      activeClientDeathId
    );
    return [...entityRows, ...visiblePrimaryClientRows];
  }, [activeClientDeathId, allPrimaryClientRows, relevantEntities, variant]);

  return (
    <DataTable
      loading={isLoading}
      slots={{
        footer: Box,
        noRowsOverlay: () => <EntitiesEmptyState variant={variant} />,
      }}
      pageSize={100}
      columns={columns}
      rows={rows}
    />
  );
}

function useEntitiesForVariant(
  variant: ManageDispositionsEntitiesTableVariant
) {
  const { inEstateEntities, outOfEstateEntities } =
    useManageDispositionsContext();
  switch (variant) {
    case 'in-estate':
      return inEstateEntities;
    case 'out-of-estate':
      return outOfEstateEntities;
    default:
      throw new UnreachableError({
        message: `Unhandled variant ${variant}`,
        case: variant,
      });
  }
}
