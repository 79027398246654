import { Stack, Typography } from '@mui/material';

import { HeaderList } from '@/components/lists/HeaderList/HeaderList';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { BusinessEntitySummaryData } from '@/modules/entities/details/businessEntities/BusinessEntityDetailsCard/BusinessEntityDetails.types';
import { getFormattedKeyPerson } from '@/modules/entities/details/businessEntities/BusinessEntityDetailsCard/BusinessEntityDetails.utils';
import { useRegisterSlide } from '@/modules/presentation/clientPresentation/hooks/useRegisterSlide';

import { PresentationSlideSecondaryHeading } from '../../../components/PresentationSlideSecondaryHeading';
import { PRESENTATION_SPACING_UNITS } from '../../../presentation.constants';
import { PresentationSlide } from '../../../PresentationSlide';
import { PresentationSlideRegistrationDetails } from '../../entityPresentations.types';
import { EntityPresentation_EntityFragment } from '../../graphql/EntityPresentation.generated';

export interface BusinessEntityKeyPeopleSlideProps
  extends PresentationSlideRegistrationDetails {
  entity: EntityPresentation_EntityFragment;
  summary: BusinessEntitySummaryData;
  displayName: string;
}
const SLIDE_TITLE = 'Key people';
export function BusinessEntityKeyPeopleSlide({
  entity,
  summary,
  displayName,
  ...registrationProps
}: BusinessEntityKeyPeopleSlideProps) {
  useRegisterSlide({
    displayName: SLIDE_TITLE,
    bundleId: registrationProps.bundleId,
    id: registrationProps.slideId,
    index: registrationProps.bundleIndex,
  });
  return (
    <PresentationSlide.Slide
      id={registrationProps.slideId}
      leftHeaderContent={
        <PresentationSlide.MainHeading
          heading={SLIDE_TITLE}
          subheading={displayName}
        />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={entity.household.displayName}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <Stack spacing={3} p={PRESENTATION_SPACING_UNITS}>
        {summary.keyPeopleNotes && (
          <Typography variant="body1" component="p">
            {summary.keyPeopleNotes}
          </Typography>
        )}
        <HeaderList noItemsText="No key people specified">
          {summary.keyPeople?.map((kp) => (
            <RichListItem key={kp.id} {...getFormattedKeyPerson(kp)} />
          )) ?? []}
        </HeaderList>
      </Stack>
    </PresentationSlide.Slide>
  );
}
