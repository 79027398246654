import { SubForm } from '@/types/subform';

import {
  BASIC_INFORMATION_SUBFORM_NAMESPACE,
  BasicInformationSubformType,
  defaultValues,
  Fields,
  NamespaceType,
  Props,
  VariantType,
} from './BasicInformationSubform.types';
import { UI } from './BasicInformationSubform.UI';

export class BasicInformationSubform
  implements
    SubForm<
      BasicInformationSubformType,
      Fields,
      NamespaceType,
      VariantType,
      object
    >
{
  FormComponent: (props: Props) => JSX.Element;
  defaultValues: BasicInformationSubformType;
  namespace: NamespaceType;

  constructor() {
    this.FormComponent = UI;
    this.defaultValues = defaultValues;
    this.namespace = BASIC_INFORMATION_SUBFORM_NAMESPACE;
  }
}
