import { Stack } from '@mui/material';
import { useEffect } from 'react';

import { RadioGroupOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormLayoutItem } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';

import {
  AnnuallyRecurringValue,
  HypotheticalTransferFormModalShape,
} from '../HypotheticalTransferModal.types';

const TRANSFER_FREQUENCY_OPTIONS: RadioGroupOption<
  HypotheticalTransferFormModalShape['transferFrequency']
>[] = [
  {
    label: 'One-time',
    value: AnnuallyRecurringValue.false,
  },
  {
    label: 'Annual',
    value: AnnuallyRecurringValue.true,
  },
];

interface TransferFrequencyProps {
  disabled?: boolean;
}

export function TransferFrequency({ disabled }: TransferFrequencyProps) {
  const { control, resetField } =
    useFormContext<HypotheticalTransferFormModalShape>();

  useEffect(() => {
    if (disabled) {
      resetField('transferFrequency', {
        defaultValue: AnnuallyRecurringValue.false,
      });
    }
  }, [disabled, resetField]);

  return (
    <FormLayoutItem
      width={6}
      sx={{
        pt: 0.75, // padding necessary to align with the input next to it
      }}
    >
      <Stack justifyContent="center" height="100%" pt={3}>
        <FormAwareRadioGroup<HypotheticalTransferFormModalShape>
          fieldName="transferFrequency"
          control={control}
          options={TRANSFER_FREQUENCY_OPTIONS}
          row={true}
          stackingDirection="column"
          required
          disabled={disabled}
          label="Transfer frequency"
          hideLabel
        />
      </Stack>
    </FormLayoutItem>
  );
}
