import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { HeaderList } from '@/components/lists/HeaderList/HeaderList';
import { ButtonTab, Tabs } from '@/components/navigation/NavigationTabs';
import { TabsProvider } from '@/components/navigation/NavigationTabs/Tabs.provider';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useDispositiveProvisionsContext } from '@/modules/dispositiveProvisions/contexts/dispositiveProvisions.context';
import { SpecifyDispositiveProvisionsButton } from '@/modules/dispositiveProvisions/SpecifyDispositiveProvisionsButton';
import { getNodes } from '@/utils/graphqlUtils';

import {
  DispositionScheme,
  DispositiveProvisionsTotalLineVariants,
} from '../dispositiveProvisions.types';
import { mapProvisionToRecipient } from '../dispositiveProvisions.utils';
import { useGetTotalLineDetailsForEntity } from '../DispositiveProvisionsForm/DispositiveProvisionsTotalLine/hooks/useGetTotalLineDetailsForEntity';
import { useSimulateDispositions } from '../DispositiveProvisionsForm/hooks/useSimulateDispositions';
import { DispositiveProvisions_DispositionScenarioFragment } from '../graphql/DispositiveProvisions.fragments.generated';
import { DispositiveProvisionDirection } from './DispositiveProvisionsByDeath.types';
import { sortDispositiveProvisions } from './DispositiveProvisionsListView.utils';
import { DispositiveProvisionRow } from './DispositiveProvisionsRow';
import { useAssertIsOnTwoClientHousehold } from './hooks/useAssertIsOnTwoClientHousehold';
import { ActiveDispositionTab } from './hooks/useDispositiveProvisionsListViewTab';

type DispositiveProvisionsForTwoClientHouseholdProps = {
  dispositionScenarioForTab: DispositiveProvisions_DispositionScenarioFragment | null;
  currentNodeValue?: Decimal;
  firstDeathPrimaryClientId: string;
} & ActiveDispositionTab;

export function DispositiveProvisionsForTwoClientHousehold({
  dispositionScenarioForTab,
  firstDeathPrimaryClientId,
  currentNodeValue,
  setActivePrimaryClientIdTab,
  activePrimaryClientIdTab,
  defaultTab,
}: DispositiveProvisionsForTwoClientHouseholdProps) {
  const { totalMarketValue } = useDispositiveProvisionsContext();
  // Guarantee we are on a two client household
  const { primaryClients } = useAssertIsOnTwoClientHousehold();

  const TabButtons = useMemo(
    () =>
      primaryClients
        .sort(({ id }) => (id === firstDeathPrimaryClientId ? -1 : 1))
        .map((client) => {
          return (
            <ButtonTab
              key={client.id}
              display={`${client.displayName} dies first`}
              isActive={activePrimaryClientIdTab === client.id}
              onClick={() => setActivePrimaryClientIdTab(client.id)}
            />
          );
        }),
    [
      activePrimaryClientIdTab,
      firstDeathPrimaryClientId,
      primaryClients,
      setActivePrimaryClientIdTab,
    ]
  );

  const firstDeathDispositiveProvisions = useMemo(() => {
    if (dispositionScenarioForTab?.dispositiveProvisionsTemplate) {
      return getNodes(
        dispositionScenarioForTab.dispositiveProvisionsTemplate
          .dispositiveProvisions
      ).sort(sortDispositiveProvisions);
    }
    return getNodes(dispositionScenarioForTab?.dispositiveProvisions).sort(
      sortDispositiveProvisions
    );
  }, [
    dispositionScenarioForTab?.dispositiveProvisions,
    dispositionScenarioForTab?.dispositiveProvisionsTemplate,
  ]);

  const secondDeathDispositiveProvisions = useMemo(() => {
    if (dispositionScenarioForTab?.secondDeathDispositiveProvisionsTemplate) {
      return getNodes(
        dispositionScenarioForTab.secondDeathDispositiveProvisionsTemplate
          .dispositiveProvisions
      ).sort(sortDispositiveProvisions);
    }
    return getNodes(
      dispositionScenarioForTab?.secondDeathDispositiveProvisions
    ).sort(sortDispositiveProvisions);
  }, [
    dispositionScenarioForTab?.secondDeathDispositiveProvisions,
    dispositionScenarioForTab?.secondDeathDispositiveProvisionsTemplate,
  ]);

  const normalizedDispositionScenariosFirstDeath = useMemo(
    () => ({
      heading: 'Upon first death',
      noItemsText: 'No dispositions specified upon first death',
      items: firstDeathDispositiveProvisions,
    }),
    [firstDeathDispositiveProvisions]
  );

  const secondPrimaryClientDeathId = useMemo(() => {
    if (activePrimaryClientIdTab === primaryClients[0].id) {
      return primaryClients[1].id;
    } else {
      return primaryClients[0].id;
    }
  }, [primaryClients, activePrimaryClientIdTab]);

  const firstDeathRecipients = useMemo(() => {
    return firstDeathDispositiveProvisions.map(mapProvisionToRecipient);
  }, [firstDeathDispositiveProvisions]);

  const secondDeathRecipients = useMemo(() => {
    return secondDeathDispositiveProvisions.map(mapProvisionToRecipient);
  }, [secondDeathDispositiveProvisions]);

  const simulateDispositionsInput = useMemo(
    () => ({
      firstGrantorId: activePrimaryClientIdTab,
      secondGrantorId: secondPrimaryClientDeathId,
      entityTotalMarketValue: totalMarketValue,
      selectedDispositionScheme: DispositionScheme.UPON_FIRST_DEATH,
    }),
    [activePrimaryClientIdTab, secondPrimaryClientDeathId, totalMarketValue]
  );

  // Simulate the dispositions so we can know if the assets are depleted after the first death
  const { simulationResults: firstDeathSimulationResults } =
    useSimulateDispositions({
      ...simulateDispositionsInput,
      recipients: firstDeathRecipients,
    });
  const { simulationResults: secondDeathSimulationResults } =
    useSimulateDispositions({
      ...simulateDispositionsInput,
      recipients: secondDeathRecipients,
      selectedDispositionScheme: DispositionScheme.UPON_SECOND_DEATH,
    });

  const { variant: totalLineVariant } = useGetTotalLineDetailsForEntity({
    entityTotalMarketValue: totalMarketValue,
    simulationResults: firstDeathSimulationResults,
  });

  const noItemsText = useMemo(() => {
    if (!firstDeathSimulationResults) {
      return EMPTY_CONTENT_HYPHEN;
    }

    return totalLineVariant !==
      DispositiveProvisionsTotalLineVariants.LessThanAssets
      ? 'The current total market value will be distributed on first death'
      : 'No dispositions specified upon second death';
  }, [firstDeathSimulationResults, totalLineVariant]);

  const normalizedDispositionScenariosSecondDeath = useMemo(
    () => ({
      heading: 'Upon second death',
      noItemsText,
      items: secondDeathDispositiveProvisions,
    }),
    [noItemsText, secondDeathDispositiveProvisions]
  );

  return (
    <TabsProvider currentTab={activePrimaryClientIdTab}>
      <Tabs fullWidth>{TabButtons}</Tabs>
      <HeaderList
        heading={normalizedDispositionScenariosFirstDeath.heading}
        noItemsText={normalizedDispositionScenariosFirstDeath.noItemsText}
      >
        {normalizedDispositionScenariosFirstDeath.items.map((item, index) => (
          <DispositiveProvisionRow
            primaryClients={primaryClients}
            key={item.id}
            provision={item}
            dyingPrimaryClientId={firstDeathPrimaryClientId}
            calculatedProvisionAmount={
              firstDeathSimulationResults?.simulateDispositiveProvisions
                ?.firstDeath?.[index]?.transferAmount
            }
            direction={DispositiveProvisionDirection.Distributing}
          />
        ))}
      </HeaderList>
      <HeaderList
        heading={normalizedDispositionScenariosSecondDeath.heading}
        noItemsText={normalizedDispositionScenariosSecondDeath.noItemsText}
      >
        {normalizedDispositionScenariosSecondDeath.items.map((item, index) => (
          <DispositiveProvisionRow
            primaryClients={primaryClients}
            key={item.id}
            provision={item}
            dyingPrimaryClientId={secondPrimaryClientDeathId}
            calculatedProvisionAmount={
              secondDeathSimulationResults?.simulateDispositiveProvisions
                ?.secondDeath?.[index]?.transferAmount
            }
            direction={DispositiveProvisionDirection.Distributing}
          />
        ))}
      </HeaderList>
      <SpecifyDispositiveProvisionsButton
        buttonProps={{
          fullWidth: true,
          sx: {
            px: 3,
          },
        }}
        behavior="edit"
        currentNodeValue={currentNodeValue}
        setActivePrimaryClientIdTab={setActivePrimaryClientIdTab}
        activePrimaryClientIdTab={activePrimaryClientIdTab}
        defaultTab={defaultTab}
      />
    </TabsProvider>
  );
}
