import { ControlButtonProps } from '@xyflow/react';

import { useFullscreenContext } from '@/components/Fullscreen/fullscreen.context';
import { Maximize01Icon } from '@/components/icons/Maximize01Icon';
import { Minimize01Icon } from '@/components/icons/Minimize01Icon';

import { CONTROL_BUTTON_ICON_SIZE, ControlButton } from './ControlButton';

type FullscreenButtonProps = ControlButtonProps;

export function FullscreenButton({
  children: _,
  ...props
}: FullscreenButtonProps) {
  const { isFullscreen, handles } = useFullscreenContext();

  if (isFullscreen) {
    return (
      <ControlButton
        variant="primary"
        onClick={handles?.waterfallDiagram?.exit} // This must be called directly in the onClick to satisfy browser security requirements
        title="exit fullscreen"
        aria-label="exit fullscreen"
        {...props}
      >
        <Minimize01Icon size={CONTROL_BUTTON_ICON_SIZE} />
      </ControlButton>
    );
  }

  return (
    <ControlButton
      variant="secondary-filled"
      onClick={handles?.waterfallDiagram?.enter} // This must be called directly in the onClick to satisfy browser security requirements
      title="fullscreen"
      aria-label="fullscreen"
      {...props}
    >
      <Maximize01Icon size={CONTROL_BUTTON_ICON_SIZE} />
    </ControlButton>
  );
}
