import Decimal from 'decimal.js';

import { getNodes } from '@/utils/graphqlUtils';

import { AccountCurrentAssetValuationFragment } from './graphql/entityValuationQueries.generated';

/**
 * @description getDesignerAccountDetails extracts the most recent valuation details from a designer account.
 */
export function getDesignerAccountDetails(
  designerAccount?: AccountCurrentAssetValuationFragment | null
) {
  let ownAssetsValue = new Decimal(0);
  let assets = null;
  let dateOfValuation = null;
  let description = '';
  let accountId = undefined;

  if (designerAccount) {
    const mostRecentValuation = designerAccount.valuations?.edges?.[0]?.node;

    accountId = designerAccount.id;
    assets = getNodes(mostRecentValuation?.assets);
    ownAssetsValue = mostRecentValuation?.valuationValue ?? new Decimal(0);
    dateOfValuation = mostRecentValuation?.effectiveDate ?? null;
    description = mostRecentValuation?.description ?? '';
  }

  return {
    accountId,
    assets,
    ownAssetsValue,
    dateOfValuation,
    description,
  };
}
