import Decimal from 'decimal.js';

import { AssetV2IntegrationType } from '@/types/schema';
import { PathsOf } from '@/types/subform';

import { EntityType } from '../types/EntityType';

export const STRUCTURED_ASSETS_SUBFORM_NAMESPACE =
  'structuredAssetsSubform' as const;
export type NamespaceType = typeof STRUCTURED_ASSETS_SUBFORM_NAMESPACE;
const VARIANTS = ['default'] as const;
export type VariantType = (typeof VARIANTS)[number];

export type StructuredAssetsSubformDirtyState =
  | 'clean'
  | 'valuationFieldsDirty'
  | 'integrationOnlyDirty';

export interface StructuredAsset {
  categoryId: string;
  title: string;
  value: Decimal;
  integrationType: AssetV2IntegrationType | null;
}

export interface StructuredAssetsSubformProperties {
  accountId?: string;
  valuationId?: string;
  description: string;
  assets: StructuredAsset[];
  documentIds?: string[];
  // this is a computed value, not a field in the subform
  _assetsValueSum: Decimal;
}

// this is what the subform's values will be nested under in the parent form
export interface Fields {
  [STRUCTURED_ASSETS_SUBFORM_NAMESPACE]?: StructuredAssetsSubformProperties;
}

export type SubformField = PathsOf<Fields>;

// this is Props for the UI component
export interface Props {
  householdId?: string;
  entityType: EntityType;
  entityId?: string;
  disabled?: boolean;
  hideDescriptionInput?: boolean;
}

export const defaultValues: StructuredAssetsSubformProperties = {
  accountId: '',
  valuationId: '',
  description: '',
  assets: [],
  _assetsValueSum: new Decimal(0),
  documentIds: [],
};
