import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { PersonalAccountSummaryData } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountDetails.types';
import { getNodes } from '@/utils/graphqlUtils';

import { EntityInEstateStatusDisplayNames } from '../entityDetailPageUtils';
import { EntityDetailsData } from '../entityDetails.types';
import { getTotalDeathBenefitAmountLabelItem } from '../trusts/ILITTrustDetails';
import {
  getOwnerNameFromFragment,
  getSharedSummaryProperties,
} from './accountsCardUtils';

export function getInsuranceAccountSummaryProperties({
  entity,
}: EntityDetailsData): PersonalAccountSummaryData {
  if (entity.subtype.__typename !== 'InsurancePersonalAccount') {
    throw new Error('Invalid insurance personal account type');
  }

  const { inEstateStatus, owners, policies } = entity.subtype;
  const { _kind, description, entityKind, extendedDisplayKind } =
    getSharedSummaryProperties(entity);

  const summary: PersonalAccountSummaryData = {
    _kind,
    entityId: entity.id,
    description,
    entityKind,
    extendedDisplayKind,
    ownerNames: owners ? owners.map(getOwnerNameFromFragment) : [],
    insurancePolicies: entity.subtype.policies,
    accountDetailsItems: [
      // this seems kind of odd, but if we return nothing here then there's an empty card, which looks weird. so we
      // choose to fill it with something.
      getTotalDeathBenefitAmountLabelItem(policies) ?? {
        label: 'Total death benefit amount',
        value: EMPTY_CONTENT_HYPHEN,
      },
    ],
    hideControllingPerson: true,
    cashflows: getNodes(entity.cashflows) || [],
  };

  summary.locationDisplay = inEstateStatus
    ? EntityInEstateStatusDisplayNames[inEstateStatus]
    : undefined;
  summary.hideTaxStatus = true;

  return summary;
}
