import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { RefreshCcw05Icon } from '@/components/icons/RefreshCcw05Icon';
import { UserCheck01Icon } from '@/components/icons/UserCheck01Icon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { TaskRibbon } from '@/modules/tasks/components/TaskRibbon/TaskRibbon';
import { useGetTaskAssigneeOptions } from '@/modules/tasks/hooks/useGetTaskAssigneeOptions';
import { UNASSIGNED_TASK_SENTINEL } from '@/modules/tasks/tasks.constants';
import { COLORS } from '@/styles/tokens/colors';
import { EntityTaskWhereInput } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { GetImplementationTasksV2Document } from './graphql/ImplementationTasks.generated';
import {
  useModifyImplementationTasksMutation,
  useUpdateImplementationTaskMutation,
} from './graphql/UpdateImplementationTask.generated';
import { TaskListItemStatus } from './TaskList';
import { DenormalizedImplementationTask, tabConfigurations } from './taskUtils';

function TaskFooterStatus({ status }: { status: TaskListItemStatus }) {
  if (status === TaskListItemStatus.COMPLETED) {
    return (
      <Stack direction="row" alignItems="center">
        <CheckIcon color={COLORS.FUNCTIONAL.SUCCESS.DEFAULT} />
        <Typography ml={1} variant="h4" component="span">
          Task complete
        </Typography>
      </Stack>
    );
  }

  return (
    <Stack direction="row" alignItems="center">
      <RefreshCcw05Icon color={COLORS.GRAY[400]} />
      <Box ml={1}>
        <Typography variant="h4" component="span" sx={{ display: 'block' }}>
          In progress
        </Typography>
        <Typography
          variant="subtitle2"
          component="span"
          sx={{ display: 'block' }}
        >
          Taking action above will mark this task as complete
        </Typography>
      </Box>
    </Stack>
  );
}

interface TaskStatusDescriptionProps {
  dueAt: Date;
  dueDateSetBy: string | null;
  completedBy: string | null;
  completedAt?: Date | null;
}

function TaskStatusDescription({
  dueAt,
  // dueDateSetBy,
  completedAt,
}: TaskStatusDescriptionProps) {
  if (completedAt) {
    return (
      <Typography variant="subtitle2" component="span">
        Completed <b>{formatDateToMonDDYYYY(completedAt)}</b>
      </Typography>
    );
  }

  const isOverdue = new Date() > dueAt;
  const dueStatus = isOverdue ? 'Past due on' : 'Due on';
  return (
    <Box>
      <Typography variant="subtitle2" component="span">
        {dueStatus}
      </Typography>
      &nbsp;
      <Typography
        variant="subtitle2"
        component="span"
        color={isOverdue ? 'error' : undefined}
        fontWeight={700}
      >
        {formatDateToMonDDYYYY(dueAt)}
      </Typography>
      {/* TODO: Re-enable this with LUM-305 */}
      {/* {!dueDateSetBy && (
        <Badge variant="secondary" sx={{ ml: 1 }}>
          Auto
        </Badge>
      )} */}
    </Box>
  );
}

interface TaskDetailsProps {
  task: DenormalizedImplementationTask;
  onEditClick: () => void;
  onReopenTask: () => void;
}

function TaskDetails({ task, onEditClick, onReopenTask }: TaskDetailsProps) {
  const relevantPersonToShow = task.body.completedBy || task.body.assignedTo;
  const isCompleted = !!task.body.completedAt;
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      spacing={1}
      alignItems="center"
    >
      <Box textAlign="right">
        {/* TODO: Add persistence information around who the due date was set by */}
        <TaskStatusDescription
          dueAt={task.body.dueAt}
          completedAt={task.body.completedAt || null}
          completedBy={task.body.completedBy?.nameWithLastInitial ?? null}
          dueDateSetBy={null}
        />
        <Stack
          mt={0.5}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={0.5}
        >
          <UserCheck01Icon color={COLORS.GRAY[400]} size={16} />
          <Typography variant="subtitle2" component="div">
            {relevantPersonToShow?.nameWithLastInitial ?? 'Unassigned'}
          </Typography>
        </Stack>
      </Box>
      <Box>
        {isCompleted ? (
          <Box ml={1}>
            <Button onClick={onReopenTask} variant="secondary" size="sm">
              Reopen task
            </Button>
          </Box>
        ) : (
          <EditButton onClick={onEditClick} />
        )}
      </Box>
    </Stack>
  );
}

export interface TaskFooterProps {
  task: DenormalizedImplementationTask;
  otherTasks: DenormalizedImplementationTask[];
}

interface TaskDetailsForm {
  dueDate: Date;
  assigneeId: string;
  updateAllTasks: boolean;
}

function getReassignmentUpdatesForOpenTasks(
  currentTask: DenormalizedImplementationTask,
  otherTasks: DenormalizedImplementationTask[],
  opts: {
    dueDate: Date;
    assigneeId: string;
    reassignAllTasks: boolean;
  }
): {
  reassignedUserId: string;
  newDueDate: Date | undefined;
  whichTasks: EntityTaskWhereInput;
} {
  const tasksIdsToReassign = (() => {
    if (!opts.reassignAllTasks) {
      return [currentTask.id];
    }

    const otherTaskIdsToReassign = otherTasks.flatMap((task) => {
      if (task.status === TaskListItemStatus.COMPLETED) return [];
      if (task.tab !== currentTask.tab) return [];
      return task.id;
    });
    return [...otherTaskIdsToReassign, currentTask.id];
  })();

  return {
    reassignedUserId: opts.assigneeId,
    newDueDate: opts.dueDate,
    whichTasks: {
      idIn: tasksIdsToReassign,
    },
  };
}

const RIBBON_LEFT_PADDING_SX = 10;
const STANDARD_PADDING_SX = 3;

export function TaskFooter({ task, otherTasks }: TaskFooterProps) {
  const theme = useTheme();
  const { showFeedback, createErrorFeedback } = useFeedback();
  const [isOpen, setIsOpen] = React.useState(false);
  const [assigneeOptions, { loading: taskAssigneesLoading }] =
    useGetTaskAssigneeOptions({
      onError: createErrorFeedback('Failed to fetch task assignees'),
      variables: {
        householdId: task.householdId,
      },
    });
  const [updateTask, { loading: submittingUpdate }] =
    useUpdateImplementationTaskMutation();
  const [modifyTasks, { loading: submittingModifications }] =
    useModifyImplementationTasksMutation();
  const formMethods = useForm<TaskDetailsForm>({
    defaultValues: {
      dueDate: task.body.dueAt,
      assigneeId: task.body.assignedTo?.id ?? UNASSIGNED_TASK_SENTINEL,
      updateAllTasks: false,
    },
  });

  const { control, handleSubmit, reset } = formMethods;

  function handleCancel() {
    setIsOpen(false);
    reset();
  }

  function setDefaultErrorMessage() {
    showFeedback(`We weren't able to save your changes. Please try again.`);
  }

  const handleTaskUpdate = async (
    task: DenormalizedImplementationTask,
    opts: {
      successMessage: string;
      dueDate: Date;
      assigneeId: string;
      reassignAllTasks: boolean;
    }
  ) => {
    const { successMessage, ...restOpts } = opts;
    try {
      const { newDueDate, reassignedUserId, whichTasks } =
        getReassignmentUpdatesForOpenTasks(task, otherTasks, {
          ...restOpts,
        });

      await modifyTasks({
        variables: {
          reassignToUserId:
            reassignedUserId === UNASSIGNED_TASK_SENTINEL
              ? null
              : reassignedUserId,
          reassignTasksWhereInput: whichTasks,
          dueDateUpdate: newDueDate,
        },
        refetchQueries: [GetImplementationTasksV2Document],
        onCompleted: () => {
          showFeedback(successMessage, { variant: 'success' });
          setIsOpen(false);
        },
        onError: () => setDefaultErrorMessage(),
      });
    } catch (err) {
      setDefaultErrorMessage();
      diagnostics.error('Failed to update task in TaskFooter', err as Error, {
        taskId: task.body.id,
      });
    }
  };

  const onValidSubmit: SubmitHandler<TaskDetailsForm> = async (values) => {
    await handleTaskUpdate(task, {
      successMessage: 'Task saved successfully',
      dueDate: values.dueDate,
      assigneeId: values.assigneeId,
      reassignAllTasks: values.updateAllTasks,
    });
  };

  const handleReopenTask = async () => {
    await updateTask({
      variables: {
        input: {
          id: task.body.id,
          update: {
            clearCompletedAt: true,
            clearCompletedBy: true,
          },
        },
      },
    });
  };

  const onSubmit = handleSubmit(onValidSubmit);
  const tabDisplayName = useMemo(
    () =>
      tabConfigurations.find(
        (tabConfiguration) => tabConfiguration.value === task.tab
      )?.display ?? 'current', // it should never not be found, but better safe
    [task.tab]
  );

  const disableForm = submittingModifications || submittingUpdate;
  return (
    <FormProvider {...formMethods}>
      <Box bgcolor={COLORS.GRAY[200]} position="relative">
        <Box sx={{ position: 'absolute', top: -16, left: theme.spacing(3) }}>
          <TaskRibbon />
        </Box>
        {!isOpen && (
          <Stack
            p={STANDARD_PADDING_SX}
            pl={RIBBON_LEFT_PADDING_SX}
            direction="row"
            justifyContent="space-between"
          >
            <TaskFooterStatus status={task.status} />
            <TaskDetails
              onReopenTask={() => handleReopenTask()}
              onEditClick={() => setIsOpen(true)}
              task={task}
            />
          </Stack>
        )}
        {isOpen && (
          <Box>
            <Box
              p={STANDARD_PADDING_SX}
              pl={RIBBON_LEFT_PADDING_SX}
              onSubmit={onSubmit}
              component="form"
            >
              <FormLayoutRow>
                <FormLayoutItem width={4}>
                  <FormAwareDatePickerInput
                    control={control}
                    required
                    label="Due date"
                    fieldName="dueDate"
                  />
                </FormLayoutItem>
                <FormLayoutItem width={8}>
                  <FormAwareSelectInput
                    control={control}
                    required
                    label="Assigned to"
                    fieldName="assigneeId"
                    disabled={taskAssigneesLoading}
                    options={assigneeOptions}
                  />
                </FormLayoutItem>
              </FormLayoutRow>
            </Box>
            <Stack
              sx={{
                px: 3,
                py: 2,
                borderTop: `solid ${COLORS.GRAY[300]} 1px`,
              }}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormAwareSwitch
                control={control}
                labelPosition="right"
                fieldName="updateAllTasks"
                variant="dark"
                label={`Apply changes to all tasks in "${tabDisplayName}"`}
              />
              <Stack direction="row" spacing={1}>
                <Button
                  size="sm"
                  variant="secondary"
                  disabled={disableForm}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  variant="primary"
                  disabled={disableForm}
                  onClick={onSubmit}
                >
                  Save changes
                </Button>
              </Stack>
            </Stack>
          </Box>
        )}
      </Box>
    </FormProvider>
  );
}
