import { Grid, Stack, Typography } from '@mui/material';
import { getYear } from 'date-fns';
import Decimal from 'decimal.js';
import { useCallback, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Gift02Icon } from '@/components/icons/Gift02Icon';
import { Card } from '@/components/layout/Card/Card';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { AnnualExclusionCurrentYear } from '@/modules/content/tooltipContent/AnnualExclusionCurrentYear';
import { AnnualExclusionLifetime } from '@/modules/content/tooltipContent/AnnualExclusionLifetime';
import { ExemptionTrackingTable } from '@/modules/gifting/ExemptionTrackingTable/ExemptionTrackingTable';
import { ExemptionTrackingTable_LifetimeExclusionEventFragment } from '@/modules/gifting/ExemptionTrackingTable/graphql/ExemptionTrackingTable.generated';
import { GiftCard } from '@/modules/gifting/GiftCard';
import { GiftDataCard } from '@/modules/gifting/GiftDataCard';
import {
  ClientDetailsGiftingPage_ClientProfileFragment,
  ClientDetailsGiftingPage_HouseholdFragment,
} from '@/modules/gifting/graphql/ClientDetailsGiftingPage.generated';
import { useAnnualGiftExclusionAmount } from '@/modules/gifting/hooks/useAnnualGiftExclusionAmount';
import { useClientDetailsGiftingPageQuery } from '@/modules/gifting/hooks/useClientDetailsGiftingPageQuery';
import { useGiftingExemptionsForClient } from '@/modules/gifting/hooks/useGiftingExemptionsForClient';
import { LifetimeExemptionCard } from '@/modules/gifting/LifetimeExemptionCard/LifetimeExemptionCard';
import { LogNewGiftModal } from '@/modules/gifting/LogNewGiftModal/LogNewGiftModal';
import { RecordExemptionUsedModal } from '@/modules/gifting/RecordExemptionUsedModal/RecordExemptionUsedModal';
import { SPACING_CONSTANT } from '@/styles/themes/common';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { ReportingBreadcrumb } from '../ClientDetailsReportingPage/components/ReportingBreadcrumb';
import { DownloadGiftingCSVButton } from './DownloadGiftingCSVButton/DownloadGiftingCSVButton';
import { GiftingInfoCallout } from './GiftingInfoCallout';

function NoGiftsCard({ householdId }: { householdId: string }) {
  const [whichModalOpen, setWhichModalOpen] = useState<
    'gift' | 'exemption' | 'none'
  >('none');

  const handleClick = useCallback((kind: 'gift' | 'exemption') => {
    setWhichModalOpen(kind);
  }, []);

  return (
    <>
      <LogNewGiftModal
        isOpen={whichModalOpen === 'gift'}
        onClose={() => {
          setWhichModalOpen('none');
        }}
        householdId={householdId}
      />
      <RecordExemptionUsedModal
        isOpen={whichModalOpen === 'exemption'}
        onClose={() => {
          setWhichModalOpen('none');
        }}
        householdId={householdId}
      />
      <Card
        sx={{
          px: 2.5,
          py: 7,
        }}
        variant="outlined"
      >
        <Stack spacing={3} alignItems="center">
          <Stack spacing={1} alignItems="center">
            <Gift02Icon color={COLORS.GRAY[400]} size={SPACING_CONSTANT * 6} />
            <Typography variant="h2" component="h3">
              No gifts
            </Typography>
            <Typography variant="body2">
              Log an individual gift or record exemption used to get started.
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Button
              size="sm"
              variant="primary"
              onClick={() => handleClick('gift')}
            >
              Log gift
            </Button>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => handleClick('exemption')}
            >
              Record exemption used
            </Button>
          </Stack>
          <Typography variant="subtitle2">
            &quot;Record exemption used&quot; is to provide total lifetime or
            GST exemption amounts used to date. &quot;Add gift&quot; is to
            record individual gifts at a detailed level.
          </Typography>
        </Stack>
      </Card>
    </>
  );
}

function NoGiftExclusionUsedCard() {
  const currentGiftTaxExclusion = useAnnualGiftExclusionAmount();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={0.5}
      height="100%"
    >
      <Typography variant="h4">Annual exclusion gifting</Typography>
      {currentGiftTaxExclusion && (
        <Typography variant="subtitle2">
          {`${formatCurrencyNoDecimals(
            currentGiftTaxExclusion
          )} per recipient in ${getYear(new Date())}`}
        </Typography>
      )}
    </Stack>
  );
}

function GiftExclusionCard({
  grantors,
}: {
  grantors: ClientDetailsGiftingPage_ClientProfileFragment[];
}) {
  // if any grantor has made a gift, show the card
  const hasMadeGift = grantors.some((grantor) =>
    grantor.totalAnnualExclusionUsed?.greaterThan(0)
  );

  return (
    <GiftCard>
      {!hasMadeGift && <NoGiftExclusionUsedCard />}
      {hasMadeGift && (
        <Stack spacing={1.5}>
          <Typography variant="h4">Annual exclusion gift totals</Typography>
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center">
              <Typography variant="h6">{getYear(new Date())}</Typography>
              <ContextualHelpTooltip>
                <AnnualExclusionCurrentYear />
              </ContextualHelpTooltip>
            </Stack>
            <MiniTable
              rows={grantors.map((grantor) => ({
                label: grantor.displayName,
                value: formatCurrencyNoDecimals(
                  grantor.currentAnnualExclusionUsed ?? new Decimal(0)
                ),
              }))}
              variant="default"
            />
          </Stack>
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center">
              <Typography variant="h6">Cumulative</Typography>
              <ContextualHelpTooltip>
                <AnnualExclusionLifetime />
              </ContextualHelpTooltip>
            </Stack>
            <MiniTable
              rows={grantors.map((grantor) => ({
                label: grantor.displayName,
                value: formatCurrencyNoDecimals(
                  grantor.totalAnnualExclusionUsed ?? new Decimal(0)
                ),
              }))}
              variant="default"
            />
          </Stack>
        </Stack>
      )}
    </GiftCard>
  );
}

interface Props {
  advisorClient?: ClientDetailsGiftingPage_HouseholdFragment;
  hasExclusionEvents: boolean;
  lifetimeExclusionEvents: ExemptionTrackingTable_LifetimeExclusionEventFragment[];
  householdId: string;
  loading: boolean;
}

function ClientDetailsGiftingPageInner({
  advisorClient,
  hasExclusionEvents,
  lifetimeExclusionEvents,
  householdId,
  loading,
}: Props) {
  const { gstExemptionSections, availableGstExemption } =
    useGiftingExemptionsForClient({
      household: advisorClient,
      returnNegativeValues: false,
    });

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pb={3}
      >
        <ReportingBreadcrumb reportingPageCrumb="Gifting" />
        <Stack direction="row" alignItems="center" spacing={1}>
          {hasExclusionEvents && (
            <DownloadGiftingCSVButton householdId={householdId} />
          )}
        </Stack>
      </Stack>
      <Grid container spacing={3} columns={12} alignSelf="flex-end">
        <Grid item xl={4} lg={4} sm={12}>
          <LifetimeExemptionCard householdId={householdId} />
        </Grid>
        <Grid item xl={4} lg={4} sm={12}>
          {availableGstExemption && (
            <GiftDataCard
              sections={gstExemptionSections}
              label="GST exemption"
              displayValue={formatCurrencyNoDecimals(availableGstExemption)}
            />
          )}
        </Grid>
        <Grid item xl={4} lg={4} sm={12}>
          <GiftExclusionCard
            grantors={advisorClient?.possiblePrimaryClients ?? []}
          />
        </Grid>
        {!hasExclusionEvents && !loading && (
          <Grid item sm={12}>
            <NoGiftsCard householdId={householdId} />
          </Grid>
        )}
        {hasExclusionEvents && (
          <Grid item sm={12}>
            <GiftCard>
              <ExemptionTrackingTable
                lifetimeExclusionEvents={lifetimeExclusionEvents}
                householdId={householdId}
              />
            </GiftCard>
          </Grid>
        )}
        <Grid item sm={12}>
          <GiftingInfoCallout />
        </Grid>
      </Grid>
    </>
  );
}

export function ClientDetailsGiftingPage() {
  const householdId = useRequiredParam('householdId');

  const clientDetailsGiftingPageState =
    useClientDetailsGiftingPageQuery(householdId);

  const {
    household: advisorClient,
    hasExclusionEvents,
    lifetimeExclusionEvents,
    loading,
  } = clientDetailsGiftingPageState;

  return (
    <ClientDetailsGiftingPageInner
      advisorClient={advisorClient}
      hasExclusionEvents={hasExclusionEvents}
      lifetimeExclusionEvents={lifetimeExclusionEvents}
      householdId={householdId}
      loading={loading}
    />
  );
}
