import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useGetWaterfallSummaryQuery } from '@/modules/estateWaterfall/graphql/GetWaterfallSummary.generated';

export function useWaterfallSummaryData(waterfallId: string) {
  const { createErrorFeedback } = useFeedback();

  return useGetWaterfallSummaryQuery({
    variables: {
      where: {
        id: waterfallId,
      },
    },
    fetchPolicy: 'cache-and-network',
    onError: createErrorFeedback(
      'Failed to load waterfall data. Please refresh the page to try again.'
    ),
  });
}
