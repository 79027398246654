import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { uniq } from 'lodash';
import { useContext, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { Card } from '@/components/layout/Card/Card';
import { RibbonCard } from '@/components/layout/RibbonCard';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { DocumentUploaderWithList } from '@/modules/documents/DocumentUploaderWithList/DocumentUploaderWithList';
import {
  InsurancePolicyFrequencyCopyMap,
  InsurancePolicyTypeCopyMap,
} from '@/modules/entities/InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform.types';
import { DocumentRepresentationList } from '@/modules/files/DocumentRepresentation/DocumentRepresentationList';
import { DocumentType, DocumentWhereInput } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';
import { getMaskedString } from '@/utils/getMaskedString';

import { ILITTaskContext } from '../ILITTaskContext';
import { ILITTaskSidebar } from '../ILITTaskSidebar/ILITTaskSidebar';
import { GetIlitPremiumPaymentTaskDetails_IlitCrummeyLetterFragment } from './graphql/GetILITPremiumPaymentTaskDetails.generated';
import { ILITPremiumPaymentTaskForm } from './ILITPremiumPaymentTask.types';
import { getCrummeyLetterMessage } from './ILITPremiumPaymentTask.utils';

export function ILITPremiumPaymentTaskSidebar() {
  const { getValues } = useFormContext<ILITPremiumPaymentTaskForm>();
  const formData = getValues();

  const listDocumentsLike: DocumentWhereInput = {
    hasEntityWith: [
      { hasIlitTrustWith: [{ hasPoliciesWith: [{ id: formData.policyId }] }] },
    ],
  };

  let displayPolicyName = formData.carrierName;
  if (formData.policyNumber) {
    displayPolicyName = `${formData.carrierName} (${getMaskedString(
      formData.policyNumber
    )})`;
  }
  return (
    <ILITTaskSidebar
      heading="Pay insurance premium"
      subheading="Pay the insurance premium for the following policy by the specified due date."
    >
      <MiniTable
        variant="default"
        rows={[
          {
            label: 'Policy',
            value: displayPolicyName,
          },
          {
            label: 'Insurance type',
            value: formData.policyKind
              ? InsurancePolicyTypeCopyMap[formData.policyKind]
              : 'Unknown',
          },
          {
            label: 'Premium amount',
            value: formData.premiumAmount
              ? formatCurrency(formData.premiumAmount)
              : 'Unknown',
          },
          {
            label: 'Due date',
            value: formatDateToMonDDYYYY(formData.premiumDueDate || new Date()),
          },
          {
            label: 'Frequency',
            value: formData.premiumFrequency
              ? InsurancePolicyFrequencyCopyMap[formData.premiumFrequency]
              : 'Unknown',
          },
        ]}
      />
      <DocumentRepresentationList
        showDelete={false}
        documentsLike={listDocumentsLike}
        documentIds={formData.documentIds}
      />
    </ILITTaskSidebar>
  );
}

export function ILITPremiumPaymentTaskBody() {
  const { householdId, entityId } = useContext(ILITTaskContext);
  const { control, getValues, setValue } =
    useFormContext<ILITPremiumPaymentTaskForm>();
  const formDocumentIds = getValues('documentIds');
  const premiumAmount = useWatch({ name: 'premiumAmount', control });
  const associatedCrummeyLetter = useWatch<ILITPremiumPaymentTaskForm>({
    name: 'associatedCrummeyLetter',
    control,
  }) as GetIlitPremiumPaymentTaskDetails_IlitCrummeyLetterFragment;

  const {
    showCrummeyLetterMessage,
    crummeyLetterMessageHeader,
    crummeyLetterMessage,
  } = getCrummeyLetterMessage(associatedCrummeyLetter) || {
    showCrummeyLetterMessage: false,
  };

  const [documentIds, setDocumentIds] = useState<string[]>(formDocumentIds);

  const [showDocumentUploadError, setShowDocumentUploadError] =
    useState<boolean>(false);

  return (
    <RibbonCard variant="default" heading="Insurance payment details">
      <Box sx={{ mb: 3 }}>
        {showCrummeyLetterMessage && (
          <>
            <Callout severity="error" textVariant="label">
              {crummeyLetterMessageHeader}
            </Callout>
            <Callout severity="error" textVariant="subtitle2">
              {crummeyLetterMessage}
            </Callout>
          </>
        )}
      </Box>
      <Card variant="filled-callout" sx={{ py: 2, px: 3, mb: 6 }}>
        <Stack direction="row" justifyContent="space-between">
          <OversizedMetricItem
            title={<Typography variant="label">Payment due</Typography>}
            value={formatCurrency(premiumAmount || new Decimal(0))}
          />
          <FormAwareDatePickerInput<ILITPremiumPaymentTaskForm>
            control={control}
            fieldName="premiumPaymentDate"
            label="Payment date"
            required
          />
        </Stack>
      </Card>
      <Box
        sx={{
          px: 10,
        }}
      >
        <DocumentUploaderWithList
          uploaderLabel={''}
          documentIds={documentIds}
          householdId={householdId}
          getCreateDocumentInput={(file) => ({
            householdID: householdId,
            name: file.fileName,
            entityID: entityId,
            type: DocumentType.InsurancePremiumPayment,
            fileID: file.fileId,
          })}
          onDocumentUploadSuccess={({ documentId }) => {
            const uniqueDocumentIds = uniq([...documentIds, documentId]);
            setValue('documentIds', uniqueDocumentIds);
            setDocumentIds(uniqueDocumentIds);
            setShowDocumentUploadError(false);
          }}
          errorMessage={
            showDocumentUploadError
              ? 'Could not upload the document'
              : undefined
          }
          onDocumentUploadError={(error) => {
            diagnostics.error(
              'Caught error when uploading ILIT document',
              error
            );
            setShowDocumentUploadError(true);
          }}
        />
      </Box>
    </RibbonCard>
  );
}
