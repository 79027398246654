import Decimal from 'decimal.js';

import { SubForm } from '@/types/subform';

import {
  Fields,
  NAMESPACE,
  NamespaceType,
  SubformComponentProps,
  SubstitutionThresholdSubformType,
  VariantType,
} from './types';

export { NAMESPACE };
export type { Fields };

import { SubstitutionThresholdSubformComponent } from './ui/SubstitutionThresholdSubformComponent';

export class SubstitutionThresholdSubform
  implements
    SubForm<
      SubstitutionThresholdSubformType,
      Fields,
      NamespaceType,
      VariantType,
      object
    >
{
  FormComponent: (props: SubformComponentProps) => JSX.Element;
  defaultValues: SubstitutionThresholdSubformType;
  namespace: NamespaceType;

  constructor() {
    this.FormComponent = SubstitutionThresholdSubformComponent;
    this.defaultValues = {
      downsidePercent: new Decimal(0),
      upsidePercent: new Decimal(0),
      upsideValue: new Decimal(0),
      upsideAsTarget: false,
    };
    this.namespace = NAMESPACE;
  }
}
