import { createContext, PropsWithChildren } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export interface EstateWaterfallComparisonModalContextShape {
  waterfallId: string;
  householdId: string;
}

const EstateWaterfallComparisonModalContext =
  createContext<EstateWaterfallComparisonModalContextShape>({
    householdId: '',
    waterfallId: '',
  });

export type EstateWaterfallComparisonModalContextProviderProps =
  PropsWithChildren<{
    waterfallId?: string | null;
    householdId?: string | null;
  }>;

export function EstateWaterfallComparisonModalContextProvider({
  waterfallId,
  householdId,
  children,
}: EstateWaterfallComparisonModalContextProviderProps) {
  if (!waterfallId || !householdId) {
    return null;
  }
  return (
    <EstateWaterfallComparisonModalContext.Provider
      value={{ waterfallId, householdId }}
    >
      {children}
    </EstateWaterfallComparisonModalContext.Provider>
  );
}

export function useEstateWaterfallComparisonModalContext() {
  return useGuardedContext(
    EstateWaterfallComparisonModalContext,
    'EstateWaterfallComparisonModalContextProvider'
  );
}
