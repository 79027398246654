import { Box, Stack } from '@mui/material';
import { useMemo } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Footer } from '@/components/navigation/Footer';
import { useModalState } from '@/hooks/useModalState';

import { HypotheticalEntityDeleteModal } from './HypotheticalEntityDeleteModal';

interface EntitySubformsFooterProps {
  shouldHandleCancel: boolean;
  requestClose: () => void;
  loading: boolean;
  isEdit: boolean;
  entityId?: string;
  isHypotheticalEntity?: boolean;
}

export const EntitySubformsFooter = ({
  shouldHandleCancel,
  requestClose,
  loading,
  isEdit,
  entityId,
  isHypotheticalEntity = false,
}: EntitySubformsFooterProps) => {
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  const cancelButton = useMemo(
    () =>
      shouldHandleCancel && (
        <Button variant="secondary" size="sm" onClick={requestClose}>
          Cancel
        </Button>
      ),
    [shouldHandleCancel, requestClose]
  );

  return (
    <>
      <HypotheticalEntityDeleteModal
        entityId={entityId}
        isOpen={isModalOpen}
        requestClose={closeModal}
        onSuccess={requestClose}
      />
      <Box flexShrink={0}>
        <Footer
          leftAction={
            <Stack direction="row" spacing={2}>
              {isHypotheticalEntity && (
                <Button variant="destructive" size="sm" onClick={openModal}>
                  Delete
                </Button>
              )}
            </Stack>
          }
          rightAction={
            <Stack direction="row" spacing={2}>
              {cancelButton}
              <Button
                disabled={loading}
                variant="primary"
                size="sm"
                type="submit"
              >
                {isEdit ? 'Save changes' : 'Create entity'}
              </Button>
            </Stack>
          }
        />
      </Box>
    </>
  );
};
