import { compact } from 'lodash';

import { AISuggestionMatch } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import {
  TrustDetails_SuccessorTrustee,
  TrustDetails_TrustAdvisor,
  TrustDetails_Trustee,
  TrustDetailsSubformType,
} from '@/modules/entities/TrustDetailsSubform/TrustDetailsSubform.types';
import { AiSuggestionKind, TrusteeTrusteeCategory } from '@/types/schema';

export function suggestionsToTrusteesSubform(
  matches: AISuggestionMatch[]
): TrustDetailsSubformType['trustees'] {
  return compact(
    matches
      .filter((m) => m.clientProfileId || m.clientOrganizationId)
      .filter((m) => m.suggestion.kind === AiSuggestionKind.Trustee)
      .filter((m) => m.suggestion.trustee)
      .filter(
        (m) => m.suggestion.trustee?.category === TrusteeTrusteeCategory.Primary
      )
      .map(suggestionToTrustee)
  );
}

export function suggestionsToSuccessorTrusteesSubform(
  matches: AISuggestionMatch[]
): TrustDetailsSubformType['successorTrustees'] {
  return compact(
    matches
      .filter((m) => m.clientProfileId || m.clientOrganizationId)
      .filter((m) => m.suggestion.kind === AiSuggestionKind.Trustee)
      .filter((m) => m.suggestion.trustee)
      .filter(
        (m) =>
          m.suggestion.trustee?.category === TrusteeTrusteeCategory.Successor
      )
      .map(suggestionToSuccessorTrustee)
  );
}

export function suggestionsToTrustAdvisorsSubform(
  matches: AISuggestionMatch[]
): TrustDetailsSubformType['trustAdvisors'] {
  return compact(
    matches
      .filter((m) => m.clientProfileId || m.clientOrganizationId)
      .filter((m) => m.suggestion.kind === AiSuggestionKind.TrustAdvisor)
      .filter((m) => m.suggestion.trustAdvisor)
      .map(suggestionToTrustAdvisor)
  );
}

function suggestionToTrustee(
  suggestionMatch: AISuggestionMatch
): TrustDetails_Trustee | null {
  const clientProfileOrgId =
    suggestionMatch.clientProfileId || suggestionMatch.clientOrganizationId;
  if (!clientProfileOrgId) {
    // This shouldn't happen since we pre-filtered the matches. This is for type safety.
    return null;
  }

  const { trustee } = suggestionMatch.suggestion;

  return {
    trusteeId: clientProfileOrgId,
    duties: trustee?.duties || [],
    note: '',
    isSuggestion: true,
  };
}

function suggestionToSuccessorTrustee(
  suggestionMatch: AISuggestionMatch
): TrustDetails_SuccessorTrustee | null {
  const clientProfileOrgId =
    suggestionMatch.clientProfileId || suggestionMatch.clientOrganizationId;
  if (!clientProfileOrgId) {
    // This shouldn't happen since we pre-filtered the matches. This is for type safety.
    return null;
  }

  const { trustee } = suggestionMatch.suggestion;

  return {
    successorTrusteeId: clientProfileOrgId,
    level: trustee?.order || '',
    duties: trustee?.duties || [],
    note: '',
    isSuggestion: true,
  };
}

function suggestionToTrustAdvisor(
  suggestionMatch: AISuggestionMatch
): TrustDetails_TrustAdvisor | null {
  const clientProfileOrgId =
    suggestionMatch.clientProfileId || suggestionMatch.clientOrganizationId;
  if (!clientProfileOrgId) {
    // This shouldn't happen since we pre-filtered the matches. This is for type safety.
    return null;
  }

  const { trustAdvisor } = suggestionMatch.suggestion;

  return {
    trustAdvisorId: clientProfileOrgId,
    level: trustAdvisor?.order || '',
    role: trustAdvisor?.roles || [],
    note: '',
    isSuggestion: true,
  };
}
