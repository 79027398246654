import Decimal from 'decimal.js';

import { ComparisonChart } from '@/modules/proposal/Comparison';
import { VisualizationInput } from '@/modules/proposal/types';

const COLUMN_HEIGHT_PX = 225 as const;
const COLUMN_WIDTH_PX = 125 as const;

interface VisualizationProps {
  noPlan: VisualizationInput;
  withPlan: VisualizationInput;
  scenario: 'pre-tax' | 'post-tax';
  maxProjectionValue?: Decimal;
}

export function Visualization({
  noPlan,
  withPlan,
  scenario,
  maxProjectionValue,
}: VisualizationProps) {
  return (
    <ComparisonChart
      noPlan={noPlan}
      withPlan={withPlan}
      columnHeight={COLUMN_HEIGHT_PX}
      columnWidth={COLUMN_WIDTH_PX}
      scenario={scenario}
      maxProjectionValue={maxProjectionValue}
    />
  );
}
