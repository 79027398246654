import { useState } from 'react';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';

import { CreateCollaboratorModal } from './CollaboratorModal/CreateCollaboratorModal';

export function AddCollaboratorButton(props: Partial<ButtonProps>) {
  const [addModalOpen, setAddModalOpen] = useState(false);
  return (
    <>
      <CreateCollaboratorModal
        isOpen={addModalOpen}
        onClose={() => setAddModalOpen(false)}
      />
      <Button
        onClick={() => setAddModalOpen(true)}
        variant="primary"
        size="sm"
        startIcon={PlusIcon}
        {...props}
      >
        Add new collaborator
      </Button>
    </>
  );
}
