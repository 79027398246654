import { CharitableEntityDonors } from '@/modules/content/tooltipContent/CharitableEntityDonors';
import {
  EntityOwners,
  InsuranceAccountEntityOwners,
} from '@/modules/content/tooltipContent/EntityOwners';
import { WealthOwnerType } from '@/modules/entities/principals/NonGrantorPrincipalFields/NonGrantorPrincipalFields.types';
import { UnreachableError } from '@/utils/errors';

interface WealthOwnerTooltipProps {
  wealthOwnerType: WealthOwnerType;
  isInsuranceAccount: boolean;
}

export function WealthOwnerTooltip({
  isInsuranceAccount,
  wealthOwnerType,
}: WealthOwnerTooltipProps) {
  if (isInsuranceAccount) {
    return <InsuranceAccountEntityOwners />;
  }
  switch (wealthOwnerType) {
    case WealthOwnerType.OWNER:
      return <EntityOwners />;
    case WealthOwnerType.DONOR:
      return <CharitableEntityDonors />;
    default:
      throw new UnreachableError({
        case: wealthOwnerType,
        message: `Unknown wealth owner type ${wealthOwnerType}`,
      });
  }
}
