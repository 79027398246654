import { Box, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { compact, startsWith } from 'lodash';
import { useState } from 'react';
import { useWatch } from 'react-hook-form';

import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormAwareTypeaheadMultiSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadMultiSelectInput';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { useFormContext } from '@/components/react-hook-form';
import {
  CellContainer,
  PrimaryCellTypography,
} from '@/components/tables/DataTable/components/cells';
import { SHOW_ON_ROW_HOVER_CLASSNAME } from '@/components/tables/DataTable/components/ThemedDataGrid';
import { Column } from '@/components/tables/DataTable/types';
import { useTenantHasEnabledAssetIntegration } from '@/modules/assetProviderIntegrations/shared/hooks/useTenantHasEnabledAssetIntegration';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { AssetClassLiquidityStatus } from '@/types/schema';

import {
  AssetCategoriesForm,
  AssetCategoriesFormPaths,
  NEW_ASSET_CATEGORY_SENTINEL,
  RowData,
} from './AdminAssetCategoriesConfigurationPage.types';
import { AssetCategoryDeletionModal } from './components/AssetCategoryDeletionModal/AssetCategoryDeletionModal';
import { SystemClassIndicator } from './components/SystemClassIndicator';
import { useReorderableRows } from './context/ReorderableRows.context';
import { useAvailableIntegrationCategories } from './hooks/useAvailableIntegrationCategories';

export function useAssetCategoriesColumns() {
  const growthRatesEnabled = useFeatureFlag('custom_growth_rates');
  const integrationEnabled = useTenantHasEnabledAssetIntegration();
  const columns: Column<RowData>[] = compact([
    {
      field: 'name',
      headerName: 'Asset category',
      flex: 1,
      sortable: false,
      renderCell: AssetCategoryNameInputRenderer,
    },
    integrationEnabled && {
      field: 'integrationCategories',
      headerName: 'Associated integration categories',
      width: 450,
      sortable: false,
      renderCell: AssociatedIntegrationAssetClassesInputRenderer,
    },
    growthRatesEnabled && {
      field: 'growthRate',
      headerName: 'Growth rate',
      width: 125,
      sortable: false,
      renderCell: GrowthRateInputRenderer,
    },
    {
      field: 'liquidity',
      headerName: 'Liquid / Illiquid',
      width: 200,
      sortable: false,
      renderCell: LiquityStatusInputRenderer,
    },
  ]);

  return columns;
}

function AssociatedIntegrationAssetClassesInputRenderer({
  row,
  colDef,
}: GridRenderCellParams<RowData>) {
  const { options, loading } = useAvailableIntegrationCategories({
    currentRowId: row.id,
  });
  const [inputValue, setInputValue] = useState('');
  const { control } = useFormContext<AssetCategoriesForm>();
  const assetClassId = row.id;
  return (
    <CellContainer align={colDef.align}>
      <Box width="100%">
        <FormAwareTypeaheadMultiSelectInput
          fieldName={
            `assetCategoriesById.${assetClassId}.integrationCategoryIds` as const satisfies AssetCategoriesFormPaths
          }
          label="Associated asset classes"
          hideLabel
          loading={loading}
          control={control}
          inputValue={inputValue}
          onInputChange={setInputValue}
          options={options}
        />
      </Box>
    </CellContainer>
  );
}

function GrowthRateInputRenderer({
  row,
  colDef,
}: GridRenderCellParams<RowData>) {
  const { control } = useFormContext<AssetCategoriesForm>();
  const assetClassId = row.id;
  return (
    <CellContainer align={colDef.align}>
      <Box width="100%">
        <FormAwarePercentInput
          isDecimalJSInput
          fieldName={
            `assetCategoriesById.${assetClassId}.growthRate` as const satisfies AssetCategoriesFormPaths
          }
          label="Growth rate"
          hideLabel
          control={control}
        />
      </Box>
    </CellContainer>
  );
}

function LiquityStatusInputRenderer({
  row,
  colDef,
}: GridRenderCellParams<RowData>) {
  const { control } = useFormContext<AssetCategoriesForm>();
  const assetClassId = row.id;
  return (
    <CellContainer align={colDef.align}>
      <Box width="100%" pl={1.25}>
        <FormAwareRadioGroup
          fieldName={
            `assetCategoriesById.${assetClassId}.liquidityStatus` as const satisfies AssetCategoriesFormPaths
          }
          label="Liquidity status"
          hideLabel
          control={control}
          row
          options={[
            {
              label: '',
              disabled: row.isSystemClass,
              value: AssetClassLiquidityStatus.Liquid,
            },
            {
              label: '',
              disabled: row.isSystemClass,
              value: AssetClassLiquidityStatus.Illiquid,
            },
          ]}
        />
      </Box>
    </CellContainer>
  );
}

function AssetCategoryNameInputRenderer({
  row,
  colDef,
}: GridRenderCellParams<RowData>) {
  const [isDeletingAssetCategory, setIsDeletingAssetCategory] = useState(false);
  const { control, setValue } = useFormContext<AssetCategoriesForm>();
  const [name, liquidity, editingRowId] = useWatch({
    control,
    name: [
      `assetCategoriesById.${row.id}.name`,
      `assetCategoriesById.${row.id}.liquidityStatus`,
      `currentlyEditingRowId`,
    ],
  });
  const { reorderableRows, setReorderableRows } = useReorderableRows();
  const assetClassId = row.id;
  const isEditingRow = row.id === editingRowId;

  const setIsEditingRow = (isEditing: boolean) => {
    setValue('currentlyEditingRowId', isEditing ? assetClassId : null);
  };

  const handleDeleteAssetCategory = () => {
    // if the row hasn't yet been saved to the backend, we can just remove it from the table and move on
    // without worrying about any remapping or anything else
    if (startsWith(row.id, NEW_ASSET_CATEGORY_SENTINEL)) {
      setIsEditingRow(false);
      // Remove the row from reorderableRows
      setReorderableRows(reorderableRows.filter((r) => r.id !== row.id));
      return;
    }

    setIsDeletingAssetCategory(true);
  };

  return (
    <>
      {isDeletingAssetCategory && (
        <AssetCategoryDeletionModal
          onClose={() => {
            setIsDeletingAssetCategory(false);
            setIsEditingRow(false);
          }}
          deletedAssetCategoryId={row.id}
          isOpen={isDeletingAssetCategory}
        />
      )}
      <CellContainer align={colDef.align}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Stack
            direction="row"
            spacing={1}
            width="100%"
            display={isEditingRow ? 'inherit' : 'none'}
          >
            <Box flexGrow={1}>
              <FormAwareTextInput
                autoFocus
                fieldName={
                  `assetCategoriesById.${assetClassId}.name` as const satisfies AssetCategoriesFormPaths
                }
                label="Asset class name"
                hideLabel
                control={control}
                // we need to do this because the input is inside a grid cell, and there are a number
                // of event listeners on the grid cell that we don't want to trigger when the input is focused
                // see more: https://stackoverflow.com/questions/71055614/why-is-the-space-key-being-filtered-out-by-muis-text-field-component
                onKeyDown={(e) => {
                  e.stopPropagation();
                  // persist changes if the user presses enter
                  if (e.key === 'Enter') {
                    setIsEditingRow(false);
                  }
                }}
              />
            </Box>
            <Stack direction="row" spacing={1} ml={2}>
              <IconButton
                variant="primary"
                ariaLabel="Save edits"
                size="sm"
                icon={CheckIcon}
                onClick={() => setIsEditingRow(false)}
              />
              <IconButton
                variant="destructive-transparent"
                ariaLabel="Delete asset category"
                size="sm"
                icon={Trash01Icon}
                onClick={handleDeleteAssetCategory}
              />
            </Stack>
          </Stack>
          {!isEditingRow && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              spacing={1}
            >
              <PrimaryCellTypography>{name}</PrimaryCellTypography>
              <Stack direction="row" width={50} justifyContent="center">
                {!row.isSystemClass ? (
                  <Box className={SHOW_ON_ROW_HOVER_CLASSNAME}>
                    <IconButton
                      ariaLabel="Edit asset category"
                      icon={Edit02Icon}
                      variant="transparent"
                      size="xs"
                      onClick={() => setIsEditingRow(true)}
                    />
                  </Box>
                ) : (
                  <SystemClassIndicator liquidityStatus={liquidity} />
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      </CellContainer>
    </>
  );
}
