import { LoggedTransferPurpose } from '@/types/schema';

export const LoggedTransferPurposeCopyMap: Record<
  LoggedTransferPurpose | '',
  string
> = {
  [LoggedTransferPurpose.Grant]: 'Grant',
  [LoggedTransferPurpose.NonGiftAddition]: 'Non-gift addition',
  [LoggedTransferPurpose.CharitableGift]: 'Charitable gift',
  [LoggedTransferPurpose.BeneficiaryDistribution]: 'Beneficiary distribution',
  [LoggedTransferPurpose.GratRemainderDistribution]:
    'GRAT remainder distribution',
  [LoggedTransferPurpose.TaxableGift]: 'Taxable gift',
  [LoggedTransferPurpose.Other]: 'Other',
  '': '',
};
