import { useRequiredParam } from '@/hooks/useRequiredParam';
import { HouseholdDetailsAwareRoute } from '@/modules/household/contexts/HouseholdDetailsAwareRoute';
import { ClientPresentationDesigner } from '@/modules/presentation/clientPresentation/ClientPresentationDesigner/ClientPresentationDesigner';

export function ClientDetailsPresentationPage() {
  const householdId = useRequiredParam('householdId');
  const presentationId = useRequiredParam('presentationId');
  return (
    <HouseholdDetailsAwareRoute householdId={householdId}>
      <ClientPresentationDesigner
        householdId={householdId}
        presentationId={presentationId}
      />
    </HouseholdDetailsAwareRoute>
  );
}
