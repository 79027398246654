import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import { FormAwareMultiSelectInput } from '@/components/form/formAwareInputs/FormAwareMultiSelectInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { BENEFICIARY_LEVEL_ITEMS } from '@/modules/beneficiaries/beneficiaries.constants';
import { EntityMemberLevel } from '@/modules/content/tooltipContent/EntityMemberLevel';
import { TrustTrustAdvisors } from '@/modules/content/tooltipContent/TrustTrustAdvisors';
import { TrusteeDropdown } from '@/modules/entities/inputs/TrusteeDropdown/TrusteeDropdown';
import {
  emptyTrustAdvisorValue,
  Fields,
  SubformField,
  TRUST_ADVISOR_ROLE_KIND_ITEMS,
  TRUST_DETAILS_SUBFORM_NAMESPACE,
  TrustDetailsSubformType,
  TrustPersonDropdownFieldProps,
  TrustPersonMultiSelectFieldProps,
  TrustPersonRepeaterProps,
} from '@/modules/entities/TrustDetailsSubform/TrustDetailsSubform.types';
import { AiSuggestionKind } from '@/types/schema';

const TrustAdvisorDropdownField = ({
  index,
  hideLabel,
  trustPersonOptions,
  householdId,
  labelIconEnd,
  splitScreen,
  additionalItemRendererProps,
}: TrustPersonDropdownFieldProps) => {
  return (
    <TrusteeDropdown
      fieldName={
        `${TRUST_DETAILS_SUBFORM_NAMESPACE}.trustAdvisors.${index}.trustAdvisorId` as const satisfies SubformField
      }
      label={'Trust advisors'}
      hideLabel={hideLabel}
      options={trustPersonOptions}
      householdId={householdId}
      contextualHelp={<TrustTrustAdvisors />}
      labelIconEnd={labelIconEnd}
      suggestionKind={AiSuggestionKind.TrustAdvisor}
      blockNavigationAfterCreate={splitScreen}
      additionalItemRendererProps={additionalItemRendererProps}
    />
  );
};

const TrustAdvisorLevelsField = ({
  index,
  hideLabel,
}: TrustPersonMultiSelectFieldProps) => {
  const { control } = useFormContext<Fields>();

  const isFirst = index === 0;

  return (
    <FormAwareSelectInput<Fields>
      fieldName={
        `${TRUST_DETAILS_SUBFORM_NAMESPACE}.trustAdvisors.${index}.level` as const satisfies SubformField
      }
      label={'Ordering'}
      hideLabel={hideLabel}
      contextualHelp={isFirst ? <EntityMemberLevel /> : undefined}
      control={control}
      options={BENEFICIARY_LEVEL_ITEMS}
    />
  );
};

const TrustAdvisorRolesField = ({
  index,
  hideLabel,
}: TrustPersonMultiSelectFieldProps) => {
  const { control } = useFormContext<Fields>();

  return (
    <FormAwareMultiSelectInput<Fields>
      fieldName={
        `${TRUST_DETAILS_SUBFORM_NAMESPACE}.trustAdvisors.${index}.role` as const satisfies SubformField
      }
      label={'Role'}
      hideLabel={hideLabel}
      control={control}
      options={TRUST_ADVISOR_ROLE_KIND_ITEMS}
    />
  );
};

export const TrustAdvisorInputRepeater = ({
  trusteesWithoutDuties,
  trustPersonOptions,
  householdId,
  control,
  splitScreen,
  shouldGroupDuplicates,
}: TrustPersonRepeaterProps) => {
  type RepeatedFormType = TrustDetailsSubformType['trustAdvisors'][0];

  const name =
    `${TRUST_DETAILS_SUBFORM_NAMESPACE}.trustAdvisors` as const satisfies SubformField;

  return (
    <FormAwareInputRepeater<RepeatedFormType>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- linter refactor
      control={control as any}
      name={name}
      emptyValue={emptyTrustAdvisorValue}
      shouldInit
      addAnotherItemText="Add additional trust advisor"
      render={(i: number, _props, _id, additionalItemRendererProps) => {
        const hideLabel =
          additionalItemRendererProps?.hideLabelOverride ?? i > 0;
        return (
          <FormLayoutRow>
            <FormLayoutItem width={trusteesWithoutDuties ? 9 : 5}>
              <TrustAdvisorDropdownField
                index={i}
                hideLabel={hideLabel}
                trustPersonOptions={trustPersonOptions}
                householdId={householdId}
                splitScreen={splitScreen}
                additionalItemRendererProps={additionalItemRendererProps}
              />
            </FormLayoutItem>
            <FormLayoutItem width={3} sx={{ alignSelf: 'end' }}>
              <TrustAdvisorLevelsField index={i} hideLabel={hideLabel} />
            </FormLayoutItem>
            {!trusteesWithoutDuties && (
              <FormLayoutItem width={4} sx={{ alignSelf: 'end' }}>
                <TrustAdvisorRolesField index={i} hideLabel={hideLabel} />
              </FormLayoutItem>
            )}
          </FormLayoutRow>
        );
      }}
      idField="trustAdvisorId"
      shouldGroupDuplicates={shouldGroupDuplicates}
      duplicateGroupLabel="Duplicate trust advisor from AI"
      duplicateGroupCaption="Decide which trust advisor's details to retain"
    />
  );
};
