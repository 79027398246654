import { Box, useTheme } from '@mui/material';

import { getBackgroundColor } from '../chartAccessories';

export function BarChartBox({
  heightPercent,
  heightPx,
  colors,
  borderColor,
  columnWidthPx,
}: {
  colors: [string, string];
  heightPercent?: number;
  borderColor?: string;
  heightPx?: number;
  columnWidthPx: number;
}) {
  const theme = useTheme();
  const hasHeight = (heightPercent ?? 0) + (heightPx ?? 0) > 0;

  return (
    <Box
      sx={{
        minHeight: hasHeight ? theme.spacing(2) : 0,
        height: heightPx ? `${heightPx}px` : `${heightPercent}%`,
        background: getBackgroundColor(colors[0], colors[1]),
        border: borderColor ? `2px solid ${borderColor}` : 'none',
        width: '90%',
        maxWidth: columnWidthPx,
      }}
    ></Box>
  );
}
