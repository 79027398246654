import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { ClientProfileRelationshipType } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

export const GRANTOR_RELATIONSHIP_COPY_MAP: Partial<
  Record<ClientProfileRelationshipType, string>
> = {
  [ClientProfileRelationshipType.Child]: 'Child',
  [ClientProfileRelationshipType.StepChild]: 'Stepchild',
  [ClientProfileRelationshipType.DomesticPartner]: 'Domestic partner',
  [ClientProfileRelationshipType.ExDomesticPartner]: 'Ex-domestic partner',
  [ClientProfileRelationshipType.Friend]: 'Friend',
  [ClientProfileRelationshipType.Grandchild]: 'Grandchild',
  [ClientProfileRelationshipType.Parent]: 'Parent',
  [ClientProfileRelationshipType.StepParent]: 'Stepparent',
  [ClientProfileRelationshipType.Sibling]: 'Sibling',
  [ClientProfileRelationshipType.Spouse]: 'Spouse',
  [ClientProfileRelationshipType.ExSpouse]: 'Ex-spouse',
  [ClientProfileRelationshipType.OtherRelative]: 'Other relative',
  [ClientProfileRelationshipType.OtherNonRelative]: 'Other non-relative',
};

export const PRIMARY_CLIENT_RELATIONSHIP_COPY_MAP: Partial<
  Record<ClientProfileRelationshipType, string>
> = {
  ...GRANTOR_RELATIONSHIP_COPY_MAP,
  [ClientProfileRelationshipType.OtherNonRelative]: 'Other',
};

export const GRANTOR_RELATIONSHIP_ITEMS = [
  ClientProfileRelationshipType.Child,
  ClientProfileRelationshipType.StepChild,
  ClientProfileRelationshipType.DomesticPartner,
  ClientProfileRelationshipType.Friend,
  ClientProfileRelationshipType.Grandchild,
  ClientProfileRelationshipType.Parent,
  ClientProfileRelationshipType.Sibling,
  ClientProfileRelationshipType.Spouse,
  ClientProfileRelationshipType.ExSpouse,
  ClientProfileRelationshipType.OtherRelative,
  ClientProfileRelationshipType.OtherNonRelative,
];

// These are the relationships that primary clients can have with each other
export const PRIMARY_CLIENT_RELATIONSHIP_ITEMS = [
  ClientProfileRelationshipType.Spouse,
  ClientProfileRelationshipType.DomesticPartner,
  ClientProfileRelationshipType.ExSpouse,
  ClientProfileRelationshipType.OtherNonRelative,
];

function mapItemsToOptions(
  items: ClientProfileRelationshipType[],
  isPrimaryClientRelationship?: boolean
): SelectInputOption[] {
  // we don't currently support the full set of relationships that are defined for use in the
  // family tree
  const copyMap = isPrimaryClientRelationship
    ? PRIMARY_CLIENT_RELATIONSHIP_COPY_MAP
    : GRANTOR_RELATIONSHIP_COPY_MAP;
  return items.flatMap((v) => {
    const label = copyMap[v];
    if (!label) {
      diagnostics.info(`Missing copy map label for ${v}`);
      return [];
    }

    return {
      display: label,
      value: v,
    };
  });
}

export const GRANTOR_RELATIONSHIP_OPTIONS = mapItemsToOptions(
  GRANTOR_RELATIONSHIP_ITEMS
).slice(); // slice to make it non-readonly

export const PRIMARY_CLIENT_RELATIONSHIP_OPTIONS = mapItemsToOptions(
  PRIMARY_CLIENT_RELATIONSHIP_ITEMS,
  true
).slice(); // slice to make it non-readonly
