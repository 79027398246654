import { AugmentedCreateEntityInput } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { TAX_STATUS_SUBFORM } from '../TaxStatusSubform/TaxStatusSubform.constants';
import { defaultStateTaxSubformForm } from '../TaxStatusSubform/TaxStatusSubform.utils';
import { EntityType } from '../types/EntityType';
import { SubformsCombinedType } from './EntitySubforms.types';
import {
  getSharedCreateBusinessEntityTopLevelProperties,
  makeBusinessEntityInput,
} from './utils/entities/businessEntities.utils';
import { makeCharitableNonTrustEntityInput } from './utils/entities/charitableNonTrustEntities';
import {
  makeCreateCustodialAccountInput,
  makeCreateIndividualAccountInput,
  makeCreateInsuranceAccountInput,
  makeCreateJointAccountInput,
  makeCreateQualifiedTuitionAccountInput,
  makeCreateRetirementAccountInput,
} from './utils/entities/personalAccounts.utils';
import {
  makeCreateStateTaxesInput,
  makeCreateTrustInput,
} from './utils/entities/trusts.utils';
import { getCreateEntityInputCommonProps } from './utils/shared/common.utils';
import { validateCcorpCreateInput } from './validators/ccorp';
import { validateCltTrustCreateInput } from './validators/clt';
import { validateCrtTrustCreateInput } from './validators/crt';
import { validateCustodialAccountCreateInput } from './validators/custodialAccount';
import { validateDafCreateInput } from './validators/daf';
import { validateGPCreateInput } from './validators/gp';
import { validateIlitTrustCreateInput } from './validators/ilit';
import { validateIndividualAccountCreateInput } from './validators/individualAccount';
import { validateInsuranceAccountCreateInput } from './validators/insuranceAccount';
import { validateIrrevocableTrustCreateInput } from './validators/irrevocableTrust';
import { validateJointAccountCreateInput } from './validators/jointAccount';
import { validateLLCCreateInput } from './validators/llc';
import { validateLPCreateInput } from './validators/lp';
import { validatePrivateFoundationCreateInput } from './validators/privateFoundation';
import { validateQprtTrustCreateInput } from './validators/qprt';
import { validateQualifiedTuitionAccountCreateInput } from './validators/qualifiedTuitionAccount';
import { validateRetirementAccountCreateInput } from './validators/retirementAccount';
import { validateRevocableTrustCreateInput } from './validators/revocableTrust';
import { validateScorpCreateInput } from './validators/scorp';
import { validateSlatTrustCreateInput } from './validators/slat';
import { validateSoleProprietorshipCreateInput } from './validators/soleProprietorship';

function makeCreateRevocableTrustInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withRevocableTrust: makeCreateTrustInput({
      formInput,
      householdId,
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
  };

  return input;
}

function makeCreateIrrevocableTrustInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withIrrevocableTrust: makeCreateTrustInput({
      formInput,
      householdId,
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
  };

  return input;
}

function makeCreateIlitTrustInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withIlitTrust: makeCreateTrustInput({
      formInput,
      householdId,
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
  };

  return input;
}

function makeCreateSlatTrustInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withSlatTrust: makeCreateTrustInput({
      formInput,
      householdId,
      config: {
        entityType: 'slat',
      },
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
  };

  return input;
}

function makeCreateQprtTrustInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withQprtTrust: makeCreateTrustInput({
      formInput,
      householdId,
      config: {
        entityType: 'qprt',
      },
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
  };

  return input;
}

function makeCreateCltInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withCltTrust: makeCreateTrustInput({
      formInput,
      householdId,
      config: {
        entityType: 'clt',
      },
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
  };

  return input;
}

function makeCreateCrtInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withCrtTrust: makeCreateTrustInput({
      formInput,
      householdId,
      config: {
        entityType: 'crt',
      },
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
  };

  return input;
}

function makeCreateDafInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withDonorAdvisedFund: makeCharitableNonTrustEntityInput(
      formInput,
      {
        entityType: 'daf',
      },
      householdId
    ),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
  };

  return input;
}

function makeCreatePrivateFoundationInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withPrivateFoundation: makeCharitableNonTrustEntityInput(
      formInput,
      {
        entityType: 'private-foundation',
      },
      householdId
    ),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
  };

  return input;
}

function makeCreateSoleProprietorshipInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withSoleProprietorshipBusinessEntity: makeBusinessEntityInput(formInput, {
      entityType: 'sole-proprietorship',
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
    ...getSharedCreateBusinessEntityTopLevelProperties(formInput),
  };

  return input;
}

function makeCreateLLCInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withLlcBusinessEntity: makeBusinessEntityInput(formInput, {
      entityType: 'llc',
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
    ...getSharedCreateBusinessEntityTopLevelProperties(formInput),
  };

  return input;
}

function makeCreateCCorpInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withCcorpBusinessEntity: makeBusinessEntityInput(formInput, {
      entityType: 'c-corp',
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
    ...getSharedCreateBusinessEntityTopLevelProperties(formInput),
  };

  return input;
}

function makeCreateSCorpInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withScorpBusinessEntity: makeBusinessEntityInput(formInput, {
      entityType: 's-corp',
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
    ...getSharedCreateBusinessEntityTopLevelProperties(formInput),
  };

  return input;
}

function makeCreateGPInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withGpBusinessEntity: makeBusinessEntityInput(formInput, {
      entityType: 'gp',
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
    ...getSharedCreateBusinessEntityTopLevelProperties(formInput),
  };

  return input;
}

function makeCreateLPInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withLpBusinessEntity: makeBusinessEntityInput(formInput, {
      entityType: 'lp',
    }),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS_SUBFORM]?.stateTax ?? defaultStateTaxSubformForm
    ),
    ...getSharedCreateBusinessEntityTopLevelProperties(formInput),
  };

  return input;
}

/**
 * Creates an AugmentedCreateEntityInput to send to the backend,
 * from entity subform input.
 *
 * NOTE: This is currently only called from the EntityImportTable when
 * bulk-importing entities. Although there is a reference to this function
 * in the EntitySubforms component (LongForm Trowser), it is no longer invoked
 * because we only create entities from the CreateEntityShortFormModal now.
 *
 * @param input
 * @param entityType
 * @param householdId
 */
export function makeCreateEntityInput(
  input: SubformsCombinedType,
  entityType: EntityType,
  householdId: string
): AugmentedCreateEntityInput {
  switch (entityType) {
    case 'revocable-trust':
      return validateRevocableTrustCreateInput(
        makeCreateRevocableTrustInput(input, householdId)
      );
    case 'irrevocable-trust':
      return validateIrrevocableTrustCreateInput(
        makeCreateIrrevocableTrustInput(input, householdId)
      );

    case 'slat':
      return validateSlatTrustCreateInput(
        makeCreateSlatTrustInput(input, householdId)
      );

    case 'qprt':
      return validateQprtTrustCreateInput(
        makeCreateQprtTrustInput(input, householdId)
      );

    case 'ilit':
      return validateIlitTrustCreateInput(
        makeCreateIlitTrustInput(input, householdId)
      );

    case 'clt':
      return validateCltTrustCreateInput(
        makeCreateCltInput(input, householdId)
      );

    case 'crt':
      return validateCrtTrustCreateInput(
        makeCreateCrtInput(input, householdId)
      );

    case 'daf':
      return validateDafCreateInput(makeCreateDafInput(input, householdId));

    case 'private-foundation':
      return validatePrivateFoundationCreateInput(
        makeCreatePrivateFoundationInput(input, householdId)
      );

    case 'individual-account':
      return validateIndividualAccountCreateInput(
        makeCreateIndividualAccountInput(input, householdId)
      );

    case 'insurance-account':
      return validateInsuranceAccountCreateInput(
        makeCreateInsuranceAccountInput(input, householdId)
      );

    case 'joint-account':
      return validateJointAccountCreateInput(
        makeCreateJointAccountInput(input, householdId)
      );

    case 'retirement-account':
      return validateRetirementAccountCreateInput(
        makeCreateRetirementAccountInput(input, householdId)
      );

    case 'custodial-account':
      return validateCustodialAccountCreateInput(
        makeCreateCustodialAccountInput(input, householdId)
      );

    case 'qualified-tuition-account':
      return validateQualifiedTuitionAccountCreateInput(
        makeCreateQualifiedTuitionAccountInput(input, householdId)
      );

    // Business entities
    case 'sole-proprietorship':
      return validateSoleProprietorshipCreateInput(
        makeCreateSoleProprietorshipInput(input, householdId)
      );
    case 'c-corp':
      return validateCcorpCreateInput(makeCreateCCorpInput(input, householdId));
    case 's-corp':
      return validateScorpCreateInput(makeCreateSCorpInput(input, householdId));
    case 'llc':
      return validateLLCCreateInput(makeCreateLLCInput(input, householdId));
    case 'lp':
      return validateLPCreateInput(makeCreateLPInput(input, householdId));
    case 'gp':
      return validateGPCreateInput(makeCreateGPInput(input, householdId));

    default: {
      const err = Error(
        `Could not create entity input for unrecognized entity type: ${entityType}`
      );

      diagnostics.error(`Unrecognized entity type: ${entityType}`, err);
      throw err;
    }
  }
}
