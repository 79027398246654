import {
  CompleteInitializationAction,
  EstateWaterfallActionFn,
} from '../types';

export const completeInitializationAction: EstateWaterfallActionFn<
  CompleteInitializationAction
> = (state, _action, _queueEffect) => {
  return { ...state, isInitializing: false };
};
