import { cx } from '@emotion/css';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    padding: `4px 8px`,
  },
  emphasizedWrapper: {
    backgroundColor: COLORS.GRAY[200],
  },
  label: {
    fontSize: 12,
    color: COLORS.GRAY[500],
  },
  value: {
    fontSize: 12,
    fontWeight: 700,
    color: COLORS.NAVY[900],
  },
}));

export interface SumLineProps {
  label: string;
  amount: string;
  classes?: WithClasses<typeof useStyles>;
  emphasize?: boolean;
}

export function SumLine({
  label,
  amount,
  classes: externalClasses,
  emphasize = false,
}: SumLineProps) {
  const { classes } = useStyles(undefined, {
    props: { classes: externalClasses },
  });

  return (
    <div
      className={cx(classes.wrapper, emphasize && classes.emphasizedWrapper)}
    >
      <Typography className={classes.label}>{label}</Typography>
      <Typography className={classes.value}>{amount}</Typography>
    </div>
  );
}
