import { Box, Stack } from '@mui/material';
import { useCallback } from 'react';

import { Divider } from '@/components/Divider';
import { Switch } from '@/components/form/baseInputs/Switch';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';

import {
  useBalanceSheetTableContext,
  ViewConfiguration,
} from '../BalanceSheetTable/BalanceSheetTableContext';
import { useUpdateBalanceSheetViewConfigurationMutation } from './graphql/BalanceSheetConfigurationPopper.generated';

const SUB_ITEM_INDENT_UNITS = 6;
const VERTICAL_SPACING_UNITS = 1;

export function BalanceSheetConfigurationPopper() {
  const trackUserEvent = useTrackUserEvent();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const { viewConfiguration, viewConfigurationId, setViewConfiguration } =
    useBalanceSheetTableContext();

  const [updateConfiguration, { loading }] =
    useUpdateBalanceSheetViewConfigurationMutation({
      onError: (error) => {
        reportError('Failed to update configuration', error);
        showFeedback('Failed to update configuration');
      },
    });

  const updateViewConfiguration = useCallback(
    async (field: keyof ViewConfiguration, value: boolean) => {
      if (!viewConfiguration || !viewConfigurationId) return;

      const updatedConfig = { ...viewConfiguration, [field]: value };

      // Disable dependent fields if parent is unchecked
      if (field === 'showInEstate' && !value) {
        updatedConfig.separateInEstateDirectlyHeld = false;
        updatedConfig.separateInEstateTrusts = false;
      }
      if (field === 'showOutOfEstate' && !value) {
        updatedConfig.separateOutOfEstateTrusts = false;
      }

      setViewConfiguration(updatedConfig);

      await updateConfiguration({
        variables: {
          input: {
            id: viewConfigurationId,
            update: updatedConfig,
          },
        },
      });
      trackUserEvent('balance_sheet configuration updated', updatedConfig);
    },
    [
      setViewConfiguration,
      trackUserEvent,
      updateConfiguration,
      viewConfiguration,
      viewConfigurationId,
    ]
  );

  const handleSwitchChange =
    (field: keyof ViewConfiguration) =>
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.checked;
      await updateViewConfiguration(field, value);
    };

  if (!viewConfiguration) return null;
  return (
    <Stack spacing={VERTICAL_SPACING_UNITS}>
      <Switch
        label="Show in estate"
        labelPosition="right"
        name="showInEstate"
        value={viewConfiguration.showInEstate}
        onChange={handleSwitchChange('showInEstate')}
        disabled={loading}
      />
      {viewConfiguration.showInEstate && (
        <Stack
          sx={{ pl: SUB_ITEM_INDENT_UNITS }}
          spacing={VERTICAL_SPACING_UNITS}
        >
          <Switch
            label="Separate directly held"
            labelPosition="right"
            name="separateInEstateDirectlyHeld"
            value={viewConfiguration.separateInEstateDirectlyHeld}
            disabled={!viewConfiguration.showInEstate || loading}
            onChange={handleSwitchChange('separateInEstateDirectlyHeld')}
          />
          <Switch
            label="Separate trusts"
            labelPosition="right"
            name="separateInEstateTrusts"
            value={viewConfiguration.separateInEstateTrusts}
            onChange={handleSwitchChange('separateInEstateTrusts')}
            disabled={!viewConfiguration.showInEstate || loading}
          />
        </Stack>
      )}
      <Box py={VERTICAL_SPACING_UNITS}>
        <Divider />
      </Box>
      <Switch
        label="Show out of estate"
        labelPosition="right"
        name="showOutOfEstate"
        value={viewConfiguration.showOutOfEstate}
        onChange={handleSwitchChange('showOutOfEstate')}
        disabled={loading}
      />
      {viewConfiguration.showOutOfEstate && (
        <Stack
          sx={{ pl: SUB_ITEM_INDENT_UNITS }}
          spacing={VERTICAL_SPACING_UNITS}
        >
          <Switch
            label="Separate trusts"
            labelPosition="right"
            name="separateOutOfEstateTrusts"
            value={viewConfiguration.separateOutOfEstateTrusts}
            onChange={handleSwitchChange('separateOutOfEstateTrusts')}
            disabled={!viewConfiguration.showOutOfEstate || loading}
          />
        </Stack>
      )}
      <Box py={VERTICAL_SPACING_UNITS}>
        <Divider />
      </Box>
      <Switch
        label="Combine assets below $500"
        labelPosition="right"
        name="hideSmallValues"
        value={viewConfiguration.hideSmallValues}
        onChange={handleSwitchChange('hideSmallValues')}
        disabled={loading}
      />
    </Stack>
  );
}
