import { cx } from '@emotion/css';
import { Box, Stack } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { Card } from '@/components/layout/Card/Card';
import { PageHeader } from '@/components/navigation/PageHeader';
import { TableEmptyState } from '@/components/tables/DataTable/components/TableEmptyState';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { usePaginatedDataTableQuery } from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { COLLABORATOR_KIND_DISPLAY_NAMES } from '@/modules/collaborators/collaborators.constants';

import { AddCollaboratorButton } from './AddCollaboratorButton';
import { EditCollaboratorModal } from './CollaboratorModal/EditCollaboratorModal';
import { columns, RowData } from './CollaboratorPage.constants';
import {
  CollaboratorsPage_CollaboratorFragment,
  useGetCollaboratorsQuery,
} from './graphql/CollaboratorsPage.generated';

const useStyles = makeStyles()((_theme) => ({
  // this is required to give the virtual scroller enough height to not cut off the bottom of the table empty state
  virtualScroller: {
    minHeight: 170,
  },
}));

function mapDataToRows(
  collaborators: CollaboratorsPage_CollaboratorFragment[]
): RowData[] {
  return collaborators.map((collaborator) => ({
    id: collaborator.id,
    name: collaborator.displayName,
    email: collaborator.user?.email ?? '',
    clientAccessSummary:
      collaborator.households
        ?.map((household) => household.displayName)
        .join(', ') || EMPTY_CONTENT_HYPHEN,
    organizationName: collaborator.organizationName || EMPTY_CONTENT_HYPHEN,
    title: collaborator.title || EMPTY_CONTENT_HYPHEN,
    role: COLLABORATOR_KIND_DISPLAY_NAMES[collaborator.kind],
    action: null,
  }));
}

const slots = {
  noRowsOverlay: () => (
    <TableEmptyState text="No collaborators">
      <Box>
        <AddCollaboratorButton />
      </Box>
    </TableEmptyState>
  ),
};

export function CollaboratorsPage() {
  const [editingCollaboratorId, setEditingCollaboratorId] = useState<
    string | null
  >(null);
  const [paginatedTableProps, { data, refetch }] = usePaginatedDataTableQuery(
    useGetCollaboratorsQuery,
    {
      pageSize: PageSizes.Ten,
      variables: {
        where: {},
      },
    }
  );

  const rows = mapDataToRows(data);
  const styles = useStyles();
  const handleUpdate = () => {
    void refetch();
    setEditingCollaboratorId(null);
  };

  return (
    <>
      {editingCollaboratorId && (
        <EditCollaboratorModal
          isOpen={!!editingCollaboratorId}
          collaboratorId={editingCollaboratorId}
          onClose={handleUpdate}
        />
      )}
      <Stack>
        <PageHeader
          heading="Collaborators"
          action={<AddCollaboratorButton />}
        />
        <Stack spacing={1} p={3} pb={4}>
          <SubpageLayout heading="">
            <Card variant="outlined" sx={{ mt: 0, p: 3 }}>
              <DataTable
                {...paginatedTableProps}
                onRowClick={({ row }: GridRowParams<RowData>) =>
                  setEditingCollaboratorId(row.id)
                }
                classes={{
                  virtualScroller: cx(styles.classes.virtualScroller),
                }}
                slots={slots}
                columns={columns}
                rows={rows}
              />
            </Card>
          </SubpageLayout>
        </Stack>
      </Stack>
    </>
  );
}
