import { PersonalAccountSummaryData } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountDetails.types';

import { EntityDetailsData } from '../entityDetails.types';
import {
  getControllingPartyNameFromFragment,
  getOwnerNameFromFragment,
  getSharedSummaryProperties,
} from './accountsCardUtils';

export function getCustodialQualifiedTuitionPersonalAccountSummaryProperties({
  entity,
}: EntityDetailsData): PersonalAccountSummaryData {
  if (
    entity.subtype.__typename !== 'CustodialPersonalAccount' &&
    entity.subtype.__typename !== 'QualifiedTuitionPersonalAccount'
  ) {
    throw new Error('Invalid personal account type');
  }

  return {
    ...getSharedSummaryProperties(entity),
    ownerNames: entity.subtype.owner
      ? [getOwnerNameFromFragment(entity.subtype.owner)]
      : [],
    effectiveDate: entity.subtype.effectiveDate || undefined,
    controllingPartyNames:
      entity.subtype.controllingParties?.map(
        getControllingPartyNameFromFragment
      ) ?? [],
    beneficiaries: entity.subtype.beneficiaries || [],
    accountDetailsItems: [],
  };
}
