import {
  Box,
  FormControlLabel,
  RadioGroup as MUIRadioGroup,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import React, { CSSProperties, useMemo } from 'react';

import { FormControl } from '../FormControl';
import {
  BaseRadioGroupInputProps,
  FormControlRadioGroupInputProps,
  HelpTextVariant,
  RadioGroupStackingDirection,
} from '../inputTypes';
import { StyledRadioButton } from './StyledRadioButton';

export interface RadioGroupProps<V> extends FormControlRadioGroupInputProps<V> {
  label?: string;
  hideLabel?: boolean;
  required?: boolean;
  id?: string;
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
}

function BaseRadioGroupInput({
  row = false,
  stackingDirection = 'row',
  name,
  ...inputProps
}: BaseRadioGroupInputProps<string>) {
  // Strip error since radio group does not support errors
  // eslint-disable-next-line
  const { error, disabled, inputRef, options, labelSx, ...rest } = inputProps;

  const styleForStackingDirection: Record<
    RadioGroupStackingDirection,
    CSSProperties
  > = {
    row: {},
    column: {
      display: 'grid',
      gridTemplateColumns: `repeat(${options.length}, 1fr)`,
    },
  };

  const sxForFormControlLabel: SxProps<Theme> = useMemo(() => {
    const sxForStackingDirection = {
      row: {},
      column: {
        alignItems: 'center',
      },
    };

    const baseLabelSx = Object.assign({}, inputProps.sx ?? {});

    return {
      ...sxForStackingDirection[stackingDirection],
      ...Object.assign(baseLabelSx, labelSx),
    };
  }, [inputProps.sx, labelSx, stackingDirection]);

  return (
    <>
      <MUIRadioGroup
        {...rest}
        ref={inputRef}
        row={row}
        value={inputProps.value}
        onChange={inputProps.onChange}
        style={styleForStackingDirection[stackingDirection]}
      >
        <Stack
          spacing={row ? undefined : inputProps.columnSpacing}
          direction={row ? 'row' : 'column'}
        >
          {options.map((option, idx) => {
            const isSelected = option.value === inputProps.value;
            const identifier = ['radio', name, option.value, idx]
              .filter((n) => Boolean(n) || n === 0)
              .join('-');
            return (
              <Box key={identifier}>
                <FormControlLabel
                  sx={sxForFormControlLabel}
                  value={option.value}
                  control={<StyledRadioButton id={identifier} />}
                  htmlFor={identifier}
                  label={
                    // the entirety of this component is already wrapped in a `label` element via the use of FormControlLabel,
                    // so this typography element component should be a `span` even though the variant is `label`
                    <>
                      <Typography component="span" variant="label">
                        {option.label}
                      </Typography>
                      {option.helpText && (
                        <Typography variant="subtitle2">
                          {option.helpText}
                        </Typography>
                      )}
                    </>
                  }
                  disabled={disabled || option.disabled}
                />
                {isSelected && option.selectedOptionAdornment}
              </Box>
            );
          })}
        </Stack>
      </MUIRadioGroup>
    </>
  );
}

export function RadioGroup<V>({
  id,
  label,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant,
  hideLabel,
  required,
  ...inputProps
}: RadioGroupProps<V>) {
  return (
    <FormControl<BaseRadioGroupInputProps<V>>
      id={id}
      hideLabel={hideLabel}
      contextualHelp={contextualHelp}
      component={BaseRadioGroupInput}
      errorMessage={errorMessage}
      inputProps={inputProps}
      label={label}
      required={required}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
    />
  );
}
