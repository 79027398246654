import { DocumentNode } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { shouldRefetchQuery } from '@/graphql/client.utils';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';
import { GetDispositiveProvisionsDocument } from '@/modules/dispositiveProvisions/DispositiveProvisionsForm/graphql/GetDispositiveProvisions.generated';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { TestamentaryEntityKind } from '@/types/schema';

import { useDeleteTestamentaryEntityMutation } from './graphql/DeleteTestamentaryEntity.generated';
import { TestamentaryEntityForm } from './TestamentaryEntityForm';
import {
  DefaultEstateTaxStatus,
  TestamentaryEntityFormShape,
  TestamentaryEntityTab,
} from './TestamentaryEntityForm.types';
import { getDefaultValues } from './TestamentaryEntityForm.utils';

export interface TestamentaryEntityModalProps {
  isOpen: boolean;
  onClose: () => void;
  testamentaryEntityId?: string;
  dyingPrimaryClientId: string | null;
  deadClientIds: string[];
  isTwoClientHousehold: boolean;
  initialTab?: TestamentaryEntityTab;
  // after a mutation, the modal will trigger a refetch of the active queries.
  // pass any additional queries you want to ignore from the refetch here.
  ignoredQueryDocuments?: DocumentNode[];
  defaultEstateTaxStatus?: DefaultEstateTaxStatus;
  defaultName?: string;
  defaultKind?: TestamentaryEntityKind;
}

function TestamentaryEntityModalInner({
  isOpen,
  onClose,
  testamentaryEntityId,
  dyingPrimaryClientId,
  deadClientIds,
  isTwoClientHousehold,
  initialTab,
  ignoredQueryDocuments = [],
  defaultEstateTaxStatus,
  defaultName,
  defaultKind,
}: TestamentaryEntityModalProps) {
  const isEdit = !!testamentaryEntityId;

  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { reset, shouldBlockNavigation, formState } =
    useFormContext<TestamentaryEntityFormShape>();
  const { formRef, handleSave } = useFormSaveHandler();

  const [isLoading, setIsLoading] = useState(false);

  const [deleteTestamentaryEntity, { loading: isDeleting }] =
    useDeleteTestamentaryEntityMutation({
      onError: (error) => {
        showFeedback(
          "We weren't able to delete this testamentary entity. Please refresh the page and try again."
        );
        reportError('could not delete testamentary entity event', error);
      },
      onCompleted: () => {
        showFeedback('Testamentary entity deleted successfully', {
          variant: 'success',
        });
      },
      onQueryUpdated: (query) => {
        // Prevent showing an error when refetching the dispositive provisions for the deleted testamentary entity
        return shouldRefetchQuery(query.queryName, {
          ignoredQueryDocuments: [GetDispositiveProvisionsDocument],
          // Do not refetch dispotive provisions for the deleted testamentary entity
          ignoredWhen: () => {
            return query.options.variables?.id === testamentaryEntityId;
          },
        });
      },
      awaitRefetchQueries: true,
      refetchQueries: 'active',
    });

  useEffect(() => {
    setIsLoading(isDeleting);
  }, [isDeleting]);

  const heading = useMemo(() => {
    if (isEdit) {
      return 'Edit testamentary entity';
    }

    return 'Create testamentary entity';
  }, [isEdit]);

  const handleClose = useCallback(() => {
    reset(formState.defaultValues);
    onClose();
  }, [formState.defaultValues, reset, onClose]);

  const onDelete = useCallback(async () => {
    await deleteTestamentaryEntity({
      variables: {
        id: testamentaryEntityId ?? '',
      },
    });

    handleClose();
  }, [deleteTestamentaryEntity, handleClose, testamentaryEntityId]);

  const actions = useMemo(() => {
    return (
      <>
        {isEdit && (
          <FormModalActions.DeleteButton
            loading={isLoading}
            onConfirmDelete={onDelete}
          />
        )}
        <FormModalActions.CancelButton
          loading={isLoading}
          onClick={handleClose}
        />
        <FormModalActions.SaveButton
          loading={isLoading}
          onClick={handleSave}
          data-testid="TestamentaryEntityModal-save"
        >
          Save
        </FormModalActions.SaveButton>
      </>
    );
  }, [handleClose, handleSave, isEdit, isLoading, onDelete]);

  return (
    <FormModal
      isOpen={isOpen}
      heading={heading}
      onClose={handleClose}
      actions={actions}
      shouldBlockClose={shouldBlockNavigation}
    >
      <TestamentaryEntityForm
        testamentaryEntityId={testamentaryEntityId}
        setIsLoading={setIsLoading}
        handleClose={handleClose}
        dyingPrimaryClientId={dyingPrimaryClientId}
        deadPrimaryClientIds={deadClientIds}
        isTwoClientHousehold={isTwoClientHousehold}
        formRef={formRef}
        initialTab={initialTab}
        ignoredQueryDocuments={ignoredQueryDocuments}
        defaultEstateTaxStatus={defaultEstateTaxStatus}
        defaultName={defaultName}
        defaultKind={defaultKind}
      />
    </FormModal>
  );
}

export function TestamentaryEntityModal(props: TestamentaryEntityModalProps) {
  const {
    deadClientIds,
    dyingPrimaryClientId,
    isTwoClientHousehold,
    defaultEstateTaxStatus,
  } = props;
  const { householdId } = useHouseholdDetailsContext();

  const areAllClientsDead = useMemo(() => {
    if (isTwoClientHousehold && deadClientIds.length === 2) {
      return true;
    }

    return !isTwoClientHousehold;
  }, [deadClientIds, isTwoClientHousehold]);

  const defaultValues = useMemo(() => {
    return getDefaultValues({
      dyingPrimaryClientId,
      areAllClientsDead,
      householdId,
      estateInclusionStatus: defaultEstateTaxStatus?.estateInclusionStatus,
      survivingSpouseStateInEstateStatus:
        defaultEstateTaxStatus?.survivingSpouseStateInEstateStatus,
      gstStatus: defaultEstateTaxStatus?.gstStatus,
    });
  }, [
    areAllClientsDead,
    defaultEstateTaxStatus?.estateInclusionStatus,
    defaultEstateTaxStatus?.gstStatus,
    defaultEstateTaxStatus?.survivingSpouseStateInEstateStatus,
    dyingPrimaryClientId,
    householdId,
  ]);

  const formMethods = useForm<TestamentaryEntityFormShape>({
    defaultValues,
  });

  return (
    <FormProvider {...formMethods}>
      <TestamentaryEntityModalInner {...props} />
    </FormProvider>
  );
}
