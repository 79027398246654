import { BASIC_ASSETS_SUBFORM as BASIC_ASSETS } from '@/modules/entities/BasicAssetsSubform/BasicAssetsSubform.types';
import { BENEFICIARIES_SUBFORM } from '@/modules/entities/EntityBeneficiariesSubform/EntityBeneficiariesSubform.constants';
import { INSURANCE_POLICIES_DETAILS_SUBFORM } from '@/modules/entities/InsurancePolicyDetailsSubform/InsurancePolicyDetailsSubform.types';
import { hasNonGrantorPrincipalFields } from '@/modules/entities/principals/NonGrantorPrincipalFields/NonGrantorPrincipalFields.utils';
import { defaultStateTaxSubformForm } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  AugmentedCreateCustodialPersonalAccountInput,
  AugmentedCreateEntityInput,
  AugmentedCreateQualifiedTuitionPersonalAccountInput,
  AugmentedUpdateCustodialPersonalAccountInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateIndividualPersonalAccountInput,
  AugmentedUpdateInsurancePersonalAccountInput,
  AugmentedUpdateJointPersonalAccountInput,
  AugmentedUpdateQualifiedTuitionPersonalAccountInput,
  UpdateCustodialPersonalAccountInput,
} from '@/types/schema';
import { assertExact } from '@/utils/assertUtils';

import { BASIC_INFORMATION_SUBFORM_NAMESPACE as BASIC_INFORMATION } from '../../../BasicInformationSubform/BasicInformationSubform.types';
import { TAX_STATUS_SUBFORM as TAX_STATUS } from '../../../TaxStatusSubform/TaxStatusSubform.constants';
import {
  PartialSubformsCombinedType,
  SubformsCombinedType,
} from '../../EntitySubforms.types';
import {
  makeAccountInput,
  makeUpdateAccountInput,
} from '../shared/accounts.utils';
import { makeCreateBeneficiaryInputs } from '../shared/beneficiaries.utils';
import {
  getCreateEntityInputCommonProps,
  getDescriptionUpdate,
  getSharedAssetIntegrationUpdateProperties,
  getTypeOrUndefined,
} from '../shared/common.utils';
import { makeCreateControllingPartyInputs } from '../shared/controllingParties.utils';
import { makeUpdatePolicyInputs } from '../shared/policies.utils';
import { makeCreateOwnerInputsForAccountDetailsSubform } from '../shared/principals.utils';
import {
  getNonTrustEntityTaxStatusUpdates,
  sharedClearTaxStatusUpdate,
} from '../shared/taxStatus.utils';
import { makeCreateStateTaxesInput } from './trusts.utils';

function getUpdatePersonalAccountInputComponents({
  formInput,
  subtypeId,
  householdId,
}: {
  formInput: PartialSubformsCombinedType;
  subtypeId: string;
  householdId: string;
}) {
  if (
    !formInput.basicInformationSubform ||
    !hasNonGrantorPrincipalFields(formInput.basicInformationSubform)
  ) {
    throw new Error(
      `Account details subform is required to update a personal account`
    );
  }

  const ownerInputs = makeCreateOwnerInputsForAccountDetailsSubform(
    formInput.basicInformationSubform
  );

  const commonSubentityUpdate = {
    // non-clearable properties
    displayName: formInput[BASIC_INFORMATION]?.displayName,

    // TODO: make a typesafe utility function to handle this; there are a lot of bugs
    // related to this across these forms right now.
    // clearable properties
    ...(() => {
      const legalName = getTypeOrUndefined<string>(
        formInput[BASIC_INFORMATION]?.legalName
      );

      return legalName ? { legalName } : { clearLegalName: true };
    })(),
    ...(() => {
      const effectiveDate = getTypeOrUndefined<Date>(
        formInput[BASIC_INFORMATION]?.effectiveDate
      );

      // this declares with type -> return pattern is verbose here, but necessary to get the type
      // to correctly calculate that *either* effectiveDate or clearEffectiveDate will be returned,
      // but not both.
      const effectiveDateUpdate: Partial<UpdateCustodialPersonalAccountInput> =
        effectiveDate ? { effectiveDate } : { clearEffectiveDate: true };

      return effectiveDateUpdate;
    })(),
    clearControllingParties: true,
    clearBeneficiaries: true,
    ...getDescriptionUpdate(formInput[BASIC_INFORMATION]?.description),
  };

  const commonRelatedEntityUpdate: Omit<
    AugmentedUpdateCustodialPersonalAccountInput,
    'update'
  > = {
    id: subtypeId,
    withControllingParties: makeCreateControllingPartyInputs(
      formInput.basicInformationSubform
    ),
    withBeneficiaries: makeCreateBeneficiaryInputs(
      formInput[BENEFICIARIES_SUBFORM]?.beneficiaries ?? [],
      householdId
    ),
  };
  if (formInput[BASIC_ASSETS]) {
    commonRelatedEntityUpdate.updateDesignerAccount =
      makeUpdateAccountInput(formInput);
  }

  return {
    updateInput: commonSubentityUpdate,
    withSubentityPropertiesInputs: commonRelatedEntityUpdate,
    // we export ownerInputs separately from other withSubentityProperties because joint accounts
    // require two owners and others only require one, and we want to allow those types to do
    // their own validation
    ownerInputs,
  };
}

type TaxDataType =
  | Record<string, never>
  | (ReturnType<typeof getNonTrustEntityTaxStatusUpdates> &
      ReturnType<typeof sharedClearTaxStatusUpdate>);

export function makeUpdatePersonalAccountInputShared({
  formInput,
  subtypeId,
  entityType,
  householdId,
}: {
  formInput: PartialSubformsCombinedType;
  subtypeId: string;
  entityType: EntityType;
  householdId: string;
}):
  | AugmentedUpdateCustodialPersonalAccountInput
  | AugmentedUpdateQualifiedTuitionPersonalAccountInput {
  const {
    ownerInputs,
    updateInput: commonSubentityUpdate,
    withSubentityPropertiesInputs: commonRelatedEntityUpdate,
  } = getUpdatePersonalAccountInputComponents({
    formInput,
    subtypeId,
    householdId,
  });

  let taxData: TaxDataType = {};
  switch (entityType) {
    case 'individual-account':
    // falls through
    case 'joint-account':
    // falls through
    case 'custodial-account':
    // falls through to shared handling with qualified-tuition-account
    // @ts-expect-error no-fallthrough
    // eslint-disable-next-line no-fallthrough
    case 'qualified-tuition-account': {
      const taxStatusFormDetails = formInput[TAX_STATUS];

      taxData = {
        ...getNonTrustEntityTaxStatusUpdates(taxStatusFormDetails),
        ...sharedClearTaxStatusUpdate(formInput),
      };
    }
    // the four account types above all have variable tax implications; insurance accounts are always in-estate
    // eslint-disable-next-line no-fallthrough
    case 'insurance-account': {
      const update = {
        ...commonSubentityUpdate,
        ...taxData,
        clearOwner: true,
        clearBeneficiaries: true,
      };

      return assertExact<
        | AugmentedUpdateIndividualPersonalAccountInput
        | AugmentedUpdateJointPersonalAccountInput
        | AugmentedUpdateCustodialPersonalAccountInput
        | AugmentedUpdateQualifiedTuitionPersonalAccountInput
        | AugmentedUpdateInsurancePersonalAccountInput
      >()({
        ...commonRelatedEntityUpdate,
        withOwner: ownerInputs[0]!,
        update,
      });
    }

    default:
      throw new Error(`Unhandled entity type: ${entityType}`);
  }
}

function getCreatePersonalAccountInputComponents(
  formInput: SubformsCombinedType,
  householdId: string
) {
  const ownerInputs = makeCreateOwnerInputsForAccountDetailsSubform(
    formInput.basicInformationSubform
  );
  const controllingPartiesInputs = makeCreateControllingPartyInputs(
    formInput.basicInformationSubform
  );

  const commonCreateInput = {
    legalName: formInput[BASIC_INFORMATION].legalName || undefined,
    displayName: formInput[BASIC_INFORMATION].displayName,
    effectiveDate: getTypeOrUndefined<Date>(
      formInput[BASIC_INFORMATION].effectiveDate
    ),
    description: getTypeOrUndefined<string>(
      formInput[BASIC_INFORMATION].description
    ),
  };

  const commonRelatedInput = {
    withControllingParties: controllingPartiesInputs,
    withDesignerAccount: makeAccountInput(formInput),
    withBeneficiaries: makeCreateBeneficiaryInputs(
      formInput[BENEFICIARIES_SUBFORM]?.beneficiaries ?? [],
      householdId
    ),
  };

  return {
    createSubtypeInput: commonCreateInput,
    withSubentityPropertiesInputs: commonRelatedInput,
    // we export ownerInputs separately from other withSubentityProperties because joint accounts
    // require two owners and others only require one, and we want to allow those types to do
    // their own validation
    ownerInputs,
  };
}

/**
 * @description this is a shared function definition to generate the subentity creation input for most
 * personal account types. This doesn't include retirement and joint accounts because they have some special
 * fields that need to be handled separately.
 */
export function makeCreatePersonalAccountInputWithTaxStatus(
  formInput: SubformsCombinedType,
  householdId: string
):
  | AugmentedCreateCustodialPersonalAccountInput
  | AugmentedCreateQualifiedTuitionPersonalAccountInput {
  const {
    ownerInputs,
    createSubtypeInput: commonCreateInput,
    withSubentityPropertiesInputs: commonRelatedInput,
  } = getCreatePersonalAccountInputComponents(formInput, householdId);

  // for all other personal account types, there should be max one owner
  if (ownerInputs.length > 1) {
    throw new Error(
      'Must have one or fewer owners for non-joint personal accounts'
    );
  }
  return {
    create: {
      ...commonCreateInput,
      ...getNonTrustEntityTaxStatusUpdates(formInput[TAX_STATUS]),
    },
    ...commonRelatedInput,
    withOwner: ownerInputs[0],
    withBeneficiaries: makeCreateBeneficiaryInputs(
      formInput[BENEFICIARIES_SUBFORM]?.beneficiaries ?? [],
      householdId
    ),
  };
}

export function makeUpdateIndividualAccountInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const entityType: EntityType = 'individual-account';
  return {
    id: entityId,
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS]?.stateTax ?? defaultStateTaxSubformForm
    ),
    update: {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
      clearStateTaxes: true,
    },

    updateIndividualPersonalAccount: makeUpdatePersonalAccountInputShared({
      formInput,
      subtypeId,
      entityType,
      householdId,
    }),
  };
}

export function makeUpdateJointAccountInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const entityType: EntityType = 'joint-account';

  const jointAccountType = formInput[BASIC_INFORMATION]?.jointAccountType;

  const { ownerInputs } = getUpdatePersonalAccountInputComponents({
    formInput,
    subtypeId,
    householdId,
  });

  const commonIndividualAccountUpdate = makeUpdatePersonalAccountInputShared({
    formInput,
    subtypeId,
    entityType,
    householdId,
  });

  return {
    id: entityId,
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[TAX_STATUS]?.stateTax ?? defaultStateTaxSubformForm
    ),
    update: {
      ...getSharedAssetIntegrationUpdateProperties(formInput),
      clearStateTaxes: true,
    },

    updateJointPersonalAccount: {
      ...commonIndividualAccountUpdate,
      update: {
        ...commonIndividualAccountUpdate.update,

        ...(jointAccountType
          ? {
              type: jointAccountType,
            }
          : {
              clearType: true,
            }),
        clearOwners: true,
      },
      withOwners: ownerInputs,
    },
  };
}

export function makeUpdateInsuranceAccountInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const entityType: EntityType = 'insurance-account';

  const { ownerInputs, updateInput } = getUpdatePersonalAccountInputComponents({
    formInput,
    subtypeId,
    householdId,
  });

  const commonIndividualAccountUpdate = makeUpdatePersonalAccountInputShared({
    formInput,
    subtypeId,
    entityType,
    householdId,
  });

  const { updatePolicies, withPolicies, removePolicyIDs } =
    makeUpdatePolicyInputs(formInput[INSURANCE_POLICIES_DETAILS_SUBFORM]);

  return {
    id: entityId,
    update: getSharedAssetIntegrationUpdateProperties(formInput),
    updateInsurancePersonalAccount: {
      ...commonIndividualAccountUpdate,
      ...updateInput,
      update: {
        ...commonIndividualAccountUpdate.update,
        clearOwners: true,
        removePolicyIDs,
      },
      withOwners: ownerInputs,
      updatePolicies,
      withPolicies,
    },
  };
}

export function makeUpdateRetirementAccountInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const { ownerInputs, updateInput, withSubentityPropertiesInputs } =
    getUpdatePersonalAccountInputComponents({
      formInput,
      subtypeId,
      householdId,
    });

  return {
    id: entityId,
    update: getSharedAssetIntegrationUpdateProperties(formInput),
    updateRetirementPersonalAccount: {
      update: {
        ...updateInput,
        ...(formInput[BASIC_INFORMATION]?.retirementAccountType
          ? {
              type: formInput[BASIC_INFORMATION].retirementAccountType,
            }
          : {
              clearType: true,
            }),
        clearOwner: true,
      },
      withOwner: ownerInputs[0],
      ...withSubentityPropertiesInputs,
    },
  };
}

export function makeUpdateCustodialAccountInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const entityType: EntityType = 'custodial-account';
  return {
    id: entityId,
    update: getSharedAssetIntegrationUpdateProperties(formInput),
    updateCustodialPersonalAccount: makeUpdatePersonalAccountInputShared({
      formInput,
      subtypeId,
      entityType,
      householdId,
    }),
  };
}

export function makeUpdateQualifiedTuitionAccountInput(
  formInput: PartialSubformsCombinedType,
  entityId: string,
  subtypeId: string,
  householdId: string
): AugmentedUpdateEntityInput {
  const entityType: EntityType = 'qualified-tuition-account';
  return {
    id: entityId,
    update: getSharedAssetIntegrationUpdateProperties(formInput),
    updateQualifiedTuitionPersonalAccount: makeUpdatePersonalAccountInputShared(
      {
        formInput,
        subtypeId,
        entityType,
        householdId,
      }
    ),
  };
}

export function makeCreateIndividualAccountInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const { ownerInputs, createSubtypeInput, withSubentityPropertiesInputs } =
    getCreatePersonalAccountInputComponents(formInput, householdId);

  if (ownerInputs.length > 1) {
    throw new Error('Must have one or fewer owners for personal accounts');
  }

  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withIndividualPersonalAccount: {
      create: createSubtypeInput,
      ...withSubentityPropertiesInputs,
      withBeneficiaries: makeCreateBeneficiaryInputs(
        formInput[BENEFICIARIES_SUBFORM]?.beneficiaries ?? [],
        householdId
      ),
      withOwner: ownerInputs[0],
    },
  };

  return input;
}

export function makeCreateInsuranceAccountInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const { ownerInputs, createSubtypeInput, withSubentityPropertiesInputs } =
    getCreatePersonalAccountInputComponents(formInput, householdId);
  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withInsurancePersonalAccount: {
      create: createSubtypeInput,
      ...withSubentityPropertiesInputs,
      withOwners: ownerInputs,
    },
  };
  return input;
}

export function makeCreateJointAccountInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const { ownerInputs, createSubtypeInput, withSubentityPropertiesInputs } =
    getCreatePersonalAccountInputComponents(formInput, householdId);

  const jointAccountType = formInput[BASIC_INFORMATION].jointAccountType;

  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withJointPersonalAccount: {
      create: {
        ...createSubtypeInput,
        type: jointAccountType || null,
      },
      ...withSubentityPropertiesInputs,
      withOwners: ownerInputs,
    },
  };

  return input;
}

export function makeCreateRetirementAccountInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const { ownerInputs, createSubtypeInput, withSubentityPropertiesInputs } =
    getCreatePersonalAccountInputComponents(formInput, householdId);

  if (ownerInputs.length > 1) {
    throw new Error('Must have one or fewer owners for retirement accounts');
  }

  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withRetirementPersonalAccount: {
      create: {
        ...createSubtypeInput,
        type: formInput[BASIC_INFORMATION].retirementAccountType || undefined,
      },
      ...withSubentityPropertiesInputs,
      withBeneficiaries: makeCreateBeneficiaryInputs(
        formInput[BENEFICIARIES_SUBFORM]?.beneficiaries ?? [],
        householdId
      ),
      withOwner: ownerInputs[0],
    },
  };

  return input;
}

export function makeCreateCustodialAccountInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withCustodialPersonalAccount: makeCreatePersonalAccountInputWithTaxStatus(
      formInput,
      householdId
    ),
  };

  return input;
}

export function makeCreateQualifiedTuitionAccountInput(
  formInput: SubformsCombinedType,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withQualifiedTuitionPersonalAccount:
      makeCreatePersonalAccountInputWithTaxStatus(formInput, householdId),
  };

  return input;
}
