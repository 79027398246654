import { Box, Stack } from '@mui/material';

import { CheckIcon } from '@/components/icons/CheckIcon';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { COLORS } from '@/styles/tokens/colors';

export interface BackendTypeaheadOptionProps
  extends React.HTMLAttributes<HTMLLIElement> {
  isSelected: boolean;
  display: React.ReactNode;
  badgeText?: React.ReactNode;
}

export function BackendTypeaheadOption({
  isSelected,
  display,
  badgeText,
  ...props
}: BackendTypeaheadOptionProps) {
  return (
    <Stack
      sx={{
        backgroundColor: isSelected ? `${COLORS.NAVY[50]} !important` : '',
        ':hover': {
          backgroundColor: `${COLORS.FUNCTIONAL.HOVER} !important`,
        },
      }}
      alignItems="center"
      width="100%"
      direction="row"
      justifyContent="space-between"
      component="li"
      spacing={0.5}
      {...props}
    >
      <Box flex={1}>{display}</Box>
      {badgeText && <Badge variant={BadgeVariants.Gray} display={badgeText} />}
      {isSelected && (
        <CheckIcon color={COLORS.FUNCTIONAL.SUCCESS[500]} size={18} />
      )}
    </Stack>
  );
}
