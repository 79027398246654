import { Skeleton } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { FC } from 'react';

import { StyledTableCell } from '@/components/tables/DisplayTable/DisplayTable';

import { StyledTableRow } from '../DisplayTable/StyledTableRow';

const SKELETON_CELL_SX: SxProps<Theme> = {
  pb: 0,
  pt: 0,
};
const SKELETON_CELL_HEIGHT = 74;

const Cell: FC<{ height: number; sx: SxProps<Theme> }> = ({ height, sx }) => (
  <StyledTableCell sx={sx}>
    <Skeleton height={height} />
  </StyledTableCell>
);

export const TableRowSkeleton: FC<{
  columns: number;
  height?: number;
  sx?: SxProps<Theme>;
}> = ({
  columns: columnCount,
  height = SKELETON_CELL_HEIGHT,
  sx = SKELETON_CELL_SX,
}) => {
  const columns = new Array(columnCount).fill(<Cell height={height} sx={sx} />);
  return <StyledTableRow>{columns}</StyledTableRow>;
};
