import { Stack, Typography } from '@mui/material';
import { useEffect } from 'react';

import { HeaderList } from '@/components/lists/HeaderList/HeaderList';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { EmptyListEditEntityActionCard } from '@/modules/entities/components/EmptyListEditEntityActionCard';
import { EntitySubformCategory } from '@/modules/entities/EntitySubforms/entitySubformConfigs';

import { BusinessEntitySummaryData } from './BusinessEntityDetails.types';
import { getFormattedKeyPerson } from './BusinessEntityDetails.utils';

export function BusinessEntityKeyPeople(props: BusinessEntitySummaryData) {
  const isViewOnly = useViewOnly();
  const { keyPeople, keyPeopleNotes, onShowEditEntity } = props;

  useEffect(() => {
    if (!isViewOnly && (keyPeople || []).length > 0) {
      onShowEditEntity?.();
    }
  }, [isViewOnly, onShowEditEntity, keyPeople]);

  if (!isViewOnly && !keyPeople?.length) {
    return (
      <EmptyListEditEntityActionCard
        heading="Specify key people"
        buttonText="Add key people"
        description="No key people have been specified for this entity"
        editSubformCategory={EntitySubformCategory.BUSINESS_ENTITY_DETAILS}
      />
    );
  }

  return (
    <Stack spacing={3}>
      {keyPeopleNotes && (
        <Typography variant="body1" component="p">
          {keyPeopleNotes}
        </Typography>
      )}
      <HeaderList noItemsText="No key people specified">
        {keyPeople?.map((kp) => (
          <RichListItem key={kp.id} {...getFormattedKeyPerson(kp)} />
        )) ?? []}
      </HeaderList>
    </Stack>
  );
}
