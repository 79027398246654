import {
  DialogModal,
  DialogModalProps,
} from '@/components/modals/DialogModal/DialogModal';

import { DispositionScheme } from '../dispositiveProvisions.types';
import { DispositionSchemeSelector } from './DispositionSchemeSelector';

export interface DispositionScenarioKindSelectorModalProps
  extends Omit<DialogModalProps, 'onClose' | 'heading'> {
  onClose: (selection: DispositionScheme | null) => void;
}

export function DispositionSchemeSelectorModal({
  onClose,
  ...dialogProps
}: DispositionScenarioKindSelectorModalProps) {
  return (
    <DialogModal
      heading="When do dispositions occur?"
      onClose={() => onClose(null)}
      {...dialogProps}
    >
      <DispositionSchemeSelector
        // hideNoneOption is always true in the modal, because we only show this modal
        // when the user has already set up dispositions
        hideNoneOption
        onChange={onClose}
      />
    </DialogModal>
  );
}
