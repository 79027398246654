import { keyBy } from 'lodash';
import { PropsWithChildren, useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import {
  EstateTaxScenarios_HouseholdFragment,
  useEstateTaxScenariosQuery,
} from '../graphql/EstateTaxScenarios.generated';
import { EstateTaxScenariosContext } from './estateTaxScenarios.context';

function useContextValue({
  householdId,
  activeClientId,
}: EstateTaxScenariosProviderProps): EstateTaxScenariosContext {
  const { showFeedback } = useFeedback();
  const { primaryClients } = useHouseholdDetailsContext();

  const isTwoClientHousehold = primaryClients?.length === 2;
  const firstClient = primaryClients?.[0]?.id ?? '';
  const secondClient = primaryClients?.[1]?.id ?? '';

  const { loading: loadingScenarios, data } = useEstateTaxScenariosQuery({
    variables: {
      where: { id: householdId },
      isTwoGrantorClient: isTwoClientHousehold,
      firstGrantorFirstPayout: {
        firstGrantorDeath: firstClient,
        clientProfileDeathConsidered: firstClient,
      },
      firstGrantorSecondPayout: {
        firstGrantorDeath: firstClient,
        // Graphql schema requires an empty string, even though the @include directive is defined.
        // Passing undefined / null won't work here.
        // TBD on if this is something internal to ent
        clientProfileDeathConsidered: secondClient,
      },
      secondGrantorFirstPayout: {
        firstGrantorDeath: secondClient,
        clientProfileDeathConsidered: secondClient,
      },
      secondGrantorSecondPayout: {
        firstGrantorDeath: secondClient,
        clientProfileDeathConsidered: firstClient,
      },
    },
    onError: (error) => {
      showFeedback(
        `Could not load tax calculations. Please refresh the page and try again.`
      );
      diagnostics.error('failed to load tax calcs', error, { householdId });
    },
    skip: !householdId || !primaryClients,
  });

  const loading = loadingScenarios || !householdId || !primaryClients;

  const mappedData = useMemo(() => {
    const household = getNodes(data?.households)[0] as
      | EstateTaxScenarios_HouseholdFragment
      | undefined;

    const primaryClients = household?.possiblePrimaryClients ?? [];
    const primaryClientsById = keyBy(primaryClients, (g) => g.id);

    const scenarios = household?.estateTaxScenarios ?? [];
    const scenariosByPrimaryClientId = keyBy(
      scenarios,
      (s) => s.firstGrantorDeath.id
    );

    const entities = getNodes(household?.entities);
    const entitiesById = keyBy(entities, (e) => e.id);
    const testamentaryEntities = getNodes(household?.testamentaryEntities);

    const defaultPayoutOrderByPrimaryClientId: EstateTaxScenariosContext['defaultPayoutOrderByPrimaryClientId'] =
      {
        [firstClient]: {
          first: data?.firstGrantorFirstPayout ?? [],
          second: data?.firstGrantorSecondPayout ?? [],
        },
      };

    if (isTwoClientHousehold) {
      defaultPayoutOrderByPrimaryClientId[secondClient] = {
        first: data?.secondGrantorFirstPayout ?? [],
        second: data?.secondGrantorSecondPayout ?? [],
      };
    }

    return {
      scenarios,
      scenariosByPrimaryClientId,
      household: household!,
      primaryClients,
      primaryClientsById,
      entities,
      entitiesById,
      testamentaryEntities,
      isTwoClientHousehold,
      defaultPayoutOrderByPrimaryClientId,
    };
  }, [
    data?.households,
    data?.firstGrantorFirstPayout,
    data?.firstGrantorSecondPayout,
    data?.secondGrantorFirstPayout,
    data?.secondGrantorSecondPayout,
    firstClient,
    isTwoClientHousehold,
    secondClient,
  ]);

  return {
    loading,
    ...mappedData,
    activeClientId,
  };
}

type EstateTaxScenariosProviderProps = PropsWithChildren<{
  householdId: string;
  activeClientId: string;
}>;

export const EstateTaxScenariosProvider = ({
  children,
  householdId,
  activeClientId,
}: EstateTaxScenariosProviderProps) => {
  const value = useContextValue({ householdId, activeClientId });
  return (
    <EstateTaxScenariosContext.Provider value={value}>
      {children}
    </EstateTaxScenariosContext.Provider>
  );
};
