import { Stack, Typography } from '@mui/material';

interface DocumentAsyncJobStatusProps {
  inProgress: boolean;
  status: string;
  userAndTime: string;
}

export const DocumentAsyncJobStatus = ({
  inProgress,
  status,
  userAndTime,
}: DocumentAsyncJobStatusProps) => {
  return (
    <Stack sx={{ flexDirection: 'row', gap: 2, flex: 1 }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        {inProgress ? 'Current' : 'Last'} Run:
      </Typography>
      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <Typography variant="body1">{status}</Typography>
        <Typography variant="subtitle1">{userAndTime}</Typography>
      </Stack>
    </Stack>
  );
};
