// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
export type TrusteeSubformV2Fragment = { __typename?: 'Entity', id: string, gratTrust?: { __typename?: 'GRATTrust', id: string, trustees?: Array<{ __typename?: 'Trustee', id: string, individual?: { __typename?: 'ClientProfile', id: string } | null }> | null, successorTrustees?: Array<{ __typename?: 'Trustee', id: string, individual?: { __typename?: 'ClientProfile', id: string } | null }> | null } | null };

export type TrusteeFormDetailsFragment = { __typename?: 'Entity', id: string, gratTrust?: { __typename?: 'GRATTrust', id: string, displayName: string, legalName?: string | null, trustJurisdictionStateCode?: string | null, implementationNotesToAttorney?: string | null, grantor?: { __typename?: 'Principal', id: string, individual?: { __typename?: 'ClientProfile', id: string, address?: { __typename?: 'Address', id: string, stateCode: string } | null } | null } | null, trustees?: Array<{ __typename?: 'Trustee', id: string, individual?: { __typename?: 'ClientProfile', id: string } | null }> | null, successorTrustees?: Array<{ __typename?: 'Trustee', id: string, individual?: { __typename?: 'ClientProfile', id: string } | null }> | null } | null };

export const TrusteeSubformV2FragmentDoc = gql`
    fragment TrusteeSubformV2 on Entity {
  id
  gratTrust {
    id
    trustees {
      id
      individual {
        id
      }
    }
    successorTrustees {
      id
      individual {
        id
      }
    }
  }
}
    `;
export const TrusteeFormDetailsFragmentDoc = gql`
    fragment TrusteeFormDetails on Entity {
  id
  ...TrusteeSubformV2
  gratTrust {
    id
    displayName
    legalName
    trustJurisdictionStateCode
    implementationNotesToAttorney
    grantor {
      id
      individual {
        id
        address {
          id
          stateCode
        }
      }
    }
  }
}
    ${TrusteeSubformV2FragmentDoc}`;
export const Operations = {
  Fragment: {
    TrusteeSubformV2: 'TrusteeSubformV2' as const,
    TrusteeFormDetails: 'TrusteeFormDetails' as const
  }
}