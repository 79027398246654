import { Box, BoxProps } from '@mui/material';

/**
 * @description UnstyledButton should be used as a wrapper around a traditionally non-interactive component
 * to get the functional behavior of a button (tabbable, a11y-friendly, keyboard accessible, etc.) without
 * the default button styles (border, background, etc.)
 */
export function UnstyledButton({ sx = {}, ...props }: BoxProps<'button'>) {
  return (
    <Box
      sx={{
        border: 0,
        outline: 'none',
        background: 'transparent',
        cursor: 'pointer',
        fontFamily: 'inherit',
        m: 0,
        p: 0,
        ...sx,
      }}
      component="button"
      {...props}
    />
  );
}
