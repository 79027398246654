import { UnreachableError } from '@/utils/errors';

import { useDispositiveProvisionsContext } from '../../contexts/dispositiveProvisions.context';
import { DispositionScheme } from '../../dispositiveProvisions.types';
import {
  useActiveClientProfile,
  useDispositionScheme,
  useOrderedDyingClients,
} from './utilityHooks';

/**
 * Generates a descriptive string for the disposition scheme based on the current
 * context and client information. It handles various scenarios including
 * single/two-client households, client profiles, and different disposition schemes.
 */
export function useCopyForDispositionScheme(): string {
  const [firstClientToDie, secondClientToDie] = useOrderedDyingClients();
  const { client: activeClientProfile } = useActiveClientProfile();
  const scheme = useDispositionScheme();
  const { isTwoClientHousehold, isClientProfile } =
    useDispositiveProvisionsContext();

  if (!firstClientToDie) {
    throw new Error('Unhandled scenario: No first client do die available');
  }

  if (!isTwoClientHousehold) {
    return `Dispositions upon ${firstClientToDie.firstName}'s death`;
  }

  if (isClientProfile) {
    if (!activeClientProfile) {
      throw new Error(
        `Unhandled state: Editing a client profile without an active client`
      );
    }

    switch (scheme) {
      case DispositionScheme.NONE:
        return 'Dispositions';
      case DispositionScheme.UPON_FIRST_DEATH:
        return `Dispositions upon ${activeClientProfile.firstName}'s death if they die 1st`;
      case DispositionScheme.UPON_SECOND_DEATH:
        if (!secondClientToDie) {
          throw new Error(
            'Unhandled state: no secondClientToDie in UPON_SECOND_DEATH DispositionScheme'
          );
        }

        return `Dispositions upon ${activeClientProfile.firstName}'s death if ${firstClientToDie.firstName} dies 1st`;

      default:
        throw new UnreachableError({
          case: scheme,
          message: `Unhandled case: unknown scheme type ${scheme}`,
        });
    }
  }

  switch (scheme) {
    case DispositionScheme.NONE:
      return 'Dispositions';
    case DispositionScheme.UPON_FIRST_DEATH:
      return `Dispositions upon 1st death (${firstClientToDie.firstName})`;
    case DispositionScheme.UPON_SECOND_DEATH:
      if (!secondClientToDie) {
        throw new Error(
          'Unhandled state: no secondClientToDie in UPON_SECOND_DEATH DispositionScheme'
        );
      }

      return `Dispositions upon 2nd death (${secondClientToDie.firstName})`;

    default:
      throw new UnreachableError({
        case: scheme,
        message: `Unhandled case: unknown scheme type ${scheme}`,
      });
  }
}
