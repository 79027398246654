// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDocumentSharingTokenMutationVariables = Types.Exact<{
  documentId: Types.Scalars['ID']['input'];
}>;


export type CreateDocumentSharingTokenMutation = { __typename?: 'Mutation', createDocumentSharingToken: { __typename?: 'SharingToken', Token: string, URL: string } };


export const CreateDocumentSharingTokenDocument = gql`
    mutation CreateDocumentSharingToken($documentId: ID!) {
  createDocumentSharingToken(documentID: $documentId) {
    Token
    URL
  }
}
    `;
export type CreateDocumentSharingTokenMutationFn = Apollo.MutationFunction<CreateDocumentSharingTokenMutation, CreateDocumentSharingTokenMutationVariables>;

/**
 * __useCreateDocumentSharingTokenMutation__
 *
 * To run a mutation, you first call `useCreateDocumentSharingTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentSharingTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentSharingTokenMutation, { data, loading, error }] = useCreateDocumentSharingTokenMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useCreateDocumentSharingTokenMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentSharingTokenMutation, CreateDocumentSharingTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentSharingTokenMutation, CreateDocumentSharingTokenMutationVariables>(CreateDocumentSharingTokenDocument, options);
      }
export type CreateDocumentSharingTokenMutationHookResult = ReturnType<typeof useCreateDocumentSharingTokenMutation>;
export type CreateDocumentSharingTokenMutationResult = Apollo.MutationResult<CreateDocumentSharingTokenMutation>;
export type CreateDocumentSharingTokenMutationOptions = Apollo.BaseMutationOptions<CreateDocumentSharingTokenMutation, CreateDocumentSharingTokenMutationVariables>;
export const Operations = {
  Mutation: {
    CreateDocumentSharingToken: 'CreateDocumentSharingToken' as const
  }
}