import { ListItemText } from '@mui/material';
import { partition, sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';

import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { isHypotheticalWaterfall } from '@/modules/estateWaterfall/EstateWaterfall.utils';
import { useNavigateToWaterfall } from '@/modules/estateWaterfall/hooks';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { useWaterfallModalQuery } from '../../EstateWaterfallModal/hooks/useWaterfallModalQuery';
import { HypotheticalEstateWaterfallBadge } from '../../HypotheticalEstateWaterfallBadge';

interface EstateWaterfallWithoutClickHandler {
  component: JSX.Element;
  iconAfter?: JSX.Element | null;
}

interface EstateWaterfallWithClickHandler
  extends EstateWaterfallWithoutClickHandler {
  clickHandler: () => void;
  value: string;
}

export function hasClickHandler(
  estateWaterfall:
    | EstateWaterfallWithoutClickHandler
    | EstateWaterfallWithClickHandler
): estateWaterfall is EstateWaterfallWithClickHandler {
  if ('clickHandler' in estateWaterfall) {
    return true;
  }
  return false;
}

interface UseEstateWaterfallMenuItemsProps {
  householdId: string;
  waterfallId: string | null;
  onClick?: (waterfallId: string) => void;
}

export function useEstateWaterfallMenuItems({
  householdId,
  waterfallId,
  onClick,
}: UseEstateWaterfallMenuItemsProps) {
  const { householdId: householdIdInContext, primaryClients } =
    useHouseholdDetailsContext();
  const navigateToWaterfall = useNavigateToWaterfall();

  const {
    household,
    currentWaterfall: _cW,
    ...rest
  } = useWaterfallModalQuery({
    householdId,
  });

  const clientWaterfalls = useMemo(
    () => sortBy(household?.estateWaterfalls, (wf) => wf.displayName),
    [household?.estateWaterfalls]
  );

  const currentWaterfall = useMemo(() => {
    if (!waterfallId) {
      return clientWaterfalls?.[0];
    }

    return clientWaterfalls?.find((wf) => wf.id === waterfallId);
  }, [clientWaterfalls, waterfallId]);

  const handleClick = useCallback(
    (waterfallId: string) => {
      if (onClick) {
        onClick(waterfallId);
        return;
      }

      navigateToWaterfall({
        householdId,
        waterfallId,
      });
    },
    [householdId, navigateToWaterfall, onClick]
  );

  const dropdownItems = useMemo(() => {
    // If the dependencies are not loaded, return null
    if (!household || !primaryClients || householdId !== householdIdInContext) {
      return null;
    }

    const primaryClientIds = primaryClients.map((client) => client.id) ?? [];

    const waterfallsByPrimaryClient: Record<string, typeof clientWaterfalls> =
      clientWaterfalls.reduce(
        (acc, wf) => {
          const primaryClientId = wf.firstGrantorDeath.id;
          if (primaryClientIds.includes(primaryClientId)) {
            acc[primaryClientId]?.push(wf);
          }
          return acc;
        },
        Object.fromEntries(
          primaryClientIds.map((id) => [id, [] as typeof clientWaterfalls])
        )
      );

    return Object.entries(waterfallsByPrimaryClient).flatMap(
      ([primaryClientId, waterfallsForDeathOrder]) => {
        if (!waterfallsForDeathOrder.length) {
          return [];
        }

        const [hypotheticalWaterfalls, sourceWaterfalls] = partition(
          waterfallsForDeathOrder,
          (ew) => isHypotheticalWaterfall(ew)
        );

        const waterfallItems =
          sourceWaterfalls.map<EstateWaterfallWithClickHandler>((wf) => ({
            component: (
              <ListItemText key={wf.id}>{wf.displayName}</ListItemText>
            ),
            clickHandler: () => handleClick(wf.id),
            iconAfter: null,
            value: wf.id,
          }));

        const hypotheticalItems = hypotheticalWaterfalls.map((child) => ({
          component: (
            <ListItemText key={child.id}>{child.displayName}</ListItemText>
          ),
          clickHandler: () => handleClick(child.id),
          iconAfter: <HypotheticalEstateWaterfallBadge />,
          value: child.id,
        }));

        const firstDyingClientName =
          primaryClients.find((client) => client.id === primaryClientId)
            ?.firstName ?? 'client';

        return [
          {
            component: (
              <SelectItemGroupLabel
                label={`${firstDyingClientName} dies first`}
              />
            ),
          },
          ...waterfallItems,
          ...hypotheticalItems,
        ];
      }
    );
  }, [
    household,
    primaryClients,
    householdId,
    householdIdInContext,
    clientWaterfalls,
    handleClick,
  ]);

  return {
    currentWaterfall,
    dropdownItems,
    clientWaterfalls,
    ...rest,
  };
}
