import { darken } from '@mui/material';

export function getStripedBackgroundCSS(baseColor: string, gapWidthPx = 12) {
  const stripeWidthPx = gapWidthPx / 2;
  return `repeating-linear-gradient(
            45deg,
            ${baseColor}, /* Color of the stripe */
            ${baseColor} ${stripeWidthPx}px, /* Width of the stripe */
            ${darken(baseColor, 0.25)} ${stripeWidthPx}px, /* Color of the gap */
            ${darken(baseColor, 0.2)} ${gapWidthPx}px /* Width of the gap */
        )`;
}
