import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { ModalFormLayout } from '@/components/form/layout/ModalFormLayout';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';

import { SubBrandFormShape } from './SubBrandModal.constants';

export function SubBrandForm() {
  const { control } = useFormContext<SubBrandFormShape>();

  return (
    <ModalFormLayout>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput<SubBrandFormShape>
            control={control}
            fieldName="displayName"
            label="Name"
            required
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </ModalFormLayout>
  );
}
