import { Stack } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { Settings03Icon } from '@/components/icons/Settings03Icon';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { FIRST_DEATH_CLIENT_ID_SEARCH_PARAM } from '@/modules/estateTaxScenarios/EstateTaxScenarios.constants';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';

export function CustomizeTaxSettingsButton() {
  const { navigate } = useNavigateToRoute();
  const { primaryClients, householdId, isTwoClientHousehold } =
    useHouseholdDetailsContext();

  function handleMenuClick(clientId: string) {
    // just for typesafety
    if (!householdId) {
      throw new Error('missing require householdId');
    }

    navigate(
      ROUTE_KEYS.HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS_ESTATE_TAX_CALCULATION_TROWSER,
      {
        householdId,
      },
      {
        [FIRST_DEATH_CLIENT_ID_SEARCH_PARAM]: clientId,
      }
    );
  }

  if (!isTwoClientHousehold) {
    const client = primaryClients?.[0];
    return (
      <Button
        variant="secondary"
        size="sm"
        startIcon={Settings03Icon}
        onClick={() => client && handleMenuClick(client.id)}
        disabled={!client}
      >
        Customize tax settings
      </Button>
    );
  }

  return (
    <ButtonWithPopover
      popperVariant="menuBelow"
      label={
        <Stack alignItems="center" direction="row" spacing={1}>
          <Settings03Icon size={18} />
          <span>Customize tax settings</span>
        </Stack>
      }
      data-testid="customize-tax-settings"
      variant="secondary"
      size="sm"
    >
      {primaryClients?.map((client) => (
        <MenuItem
          key={client.id}
          label={`${client.firstName} dies first`}
          onClick={() => handleMenuClick(client.id)}
        />
      ))}
    </ButtonWithPopover>
  );
}
