// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { FileFragmentDoc } from '../../graphql/FileFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateFileMutationVariables = Types.Exact<{
  input: Types.CreateFileInput;
}>;


export type CreateFileMutation = { __typename?: 'Mutation', uploadFile: { __typename?: 'FileUpload', uploadURL: string, uploadMethod: string, uploadURLExpiresInSeconds: number, file: { __typename?: 'File', id: string, createdAt: Time, user: { __typename?: 'User', displayName: string, id: string }, download: { __typename?: 'FileDownload', downloadURL: string } } } };

export type CreateCsvFileMutationVariables = Types.Exact<{
  input: Types.CreateFileInput;
}>;


export type CreateCsvFileMutation = { __typename?: 'Mutation', uploadFile: { __typename?: 'FileUpload', uploadURL: string, uploadMethod: string, uploadURLExpiresInSeconds: number, file: { __typename?: 'File', id: string, createdAt: Time, user: { __typename?: 'User', displayName: string, id: string }, download: { __typename?: 'FileDownload', downloadURL: string } } } };


export const CreateFileDocument = gql`
    mutation CreateFile($input: CreateFileInput!) {
  uploadFile(input: $input) {
    file {
      ...File
    }
    uploadURL
    uploadMethod
    uploadURLExpiresInSeconds
  }
}
    ${FileFragmentDoc}`;
export type CreateFileMutationFn = Apollo.MutationFunction<CreateFileMutation, CreateFileMutationVariables>;

/**
 * __useCreateFileMutation__
 *
 * To run a mutation, you first call `useCreateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileMutation, { data, loading, error }] = useCreateFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateFileMutation, CreateFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFileMutation, CreateFileMutationVariables>(CreateFileDocument, options);
      }
export type CreateFileMutationHookResult = ReturnType<typeof useCreateFileMutation>;
export type CreateFileMutationResult = Apollo.MutationResult<CreateFileMutation>;
export type CreateFileMutationOptions = Apollo.BaseMutationOptions<CreateFileMutation, CreateFileMutationVariables>;
export const CreateCsvFileDocument = gql`
    mutation CreateCSVFile($input: CreateFileInput!) {
  uploadFile: uploadCSVImportFIle(input: $input) {
    file {
      ...File
    }
    uploadURL
    uploadMethod
    uploadURLExpiresInSeconds
  }
}
    ${FileFragmentDoc}`;
export type CreateCsvFileMutationFn = Apollo.MutationFunction<CreateCsvFileMutation, CreateCsvFileMutationVariables>;

/**
 * __useCreateCsvFileMutation__
 *
 * To run a mutation, you first call `useCreateCsvFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCsvFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCsvFileMutation, { data, loading, error }] = useCreateCsvFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCsvFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateCsvFileMutation, CreateCsvFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCsvFileMutation, CreateCsvFileMutationVariables>(CreateCsvFileDocument, options);
      }
export type CreateCsvFileMutationHookResult = ReturnType<typeof useCreateCsvFileMutation>;
export type CreateCsvFileMutationResult = Apollo.MutationResult<CreateCsvFileMutation>;
export type CreateCsvFileMutationOptions = Apollo.BaseMutationOptions<CreateCsvFileMutation, CreateCsvFileMutationVariables>;
export const Operations = {
  Mutation: {
    CreateFile: 'CreateFile' as const,
    CreateCSVFile: 'CreateCSVFile' as const
  }
}