import { useMostRecentlyViewedEntityMapQuery as useMostRecentlyViewedEntityMapQueryExternal } from '../graphql/MostRecentlyViewedEntityMap.generated';
import { useMostRecentlyViewedWaterfallQuery as useMostRecentlyViewedWaterfallQueryExternal } from '../graphql/MostRecentlyViewedWaterfall.generated';

export function useMostRecentlyViewedWaterfall(householdId: string) {
  // This is a sync query that only gets data from the cache
  const queryProps = useMostRecentlyViewedWaterfallQueryExternal({
    variables: {
      lastViewedWaterfallWhereInput: {
        householdId,
      },
    },
  });

  const mostRecentlyViewedWaterfallId =
    queryProps.data?.currentUser?.localMostRecentlyViewedWaterfall?.id || null;

  return { mostRecentlyViewedWaterfallId, ...queryProps };
}

export function useMostRecentlyViewedEntityMap(householdId: string) {
  const queryProps = useMostRecentlyViewedEntityMapQueryExternal({
    variables: { mostRecentlyViewedEntityMapWhereInput: { householdId } },
  });

  const mostRecentlyEntityMapId =
    queryProps.data?.currentUser?.localMostRecentlyViewedEntityMap?.id ?? null;

  return { mostRecentlyEntityMapId, ...queryProps };
}
