import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import {
  DocumentDetails_DocumentFragment,
  useDocumentDetailsQuery as useDocumentDetailsQueryExternal,
} from '@/modules/documents/graphql/DocumentDetails.generated';
import { getNodes } from '@/utils/graphqlUtils';

interface UseDocumentDetailsQueryResult
  extends ReturnType<typeof useDocumentDetailsQueryExternal> {
  document?: DocumentDetails_DocumentFragment;
  entityId?: string;
}

export function useDocumentDetailsQuery(
  documentId: string
): UseDocumentDetailsQueryResult {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const queryProps = useDocumentDetailsQueryExternal({
    variables: {
      where: {
        id: documentId,
      },
    },
    onError: (error) => {
      reportError(`failed to get document`, error);
      showFeedback(FeedbackMessages.queryError);
    },
  });

  const document = getNodes(queryProps.data?.documents)[0];

  return {
    ...queryProps,
    document,
    entityId: document?.entity?.id,
  };
}
