import { Stack, styled, Typography } from '@mui/material';
import { ComponentProps, Fragment, PropsWithChildren, ReactNode } from 'react';
import { Link, To } from 'react-router-dom';

import { COLORS } from '@/styles/tokens/colors';
import { makeUnprintableStyle } from '@/utils/makeUnprintableStyle';

import { ChevronRightIcon } from '../icons/ChevronRightIcon';

const CrumbLink = styled(Link)(() => ({
  textDecoration: 'inherit',
}));

interface Crumb {
  label: ReactNode;
  to?: To;
}

interface BigBreadcrumbsProps {
  crumbs: Crumb[];
  rowProps?: ComponentProps<typeof Stack>;
}

function CrumbLinkWrapper({ to, children }: PropsWithChildren<{ to?: To }>) {
  if (!to) {
    return <>{children}</>;
  }
  return <CrumbLink to={to}>{children}</CrumbLink>;
}

export function BigBreadcrumbs({ crumbs, rowProps }: BigBreadcrumbsProps) {
  return (
    <Stack
      direction="row"
      spacing={0.5}
      alignItems="center"
      minHeight={40}
      {...rowProps}
    >
      {crumbs.map((crumb, idx) => {
        // if this is the last crumb, it gets a different style
        if (idx === crumbs.length - 1) {
          return (
            <Typography
              variant="h1"
              component="h2"
              key={`bigBreadcrumb-${idx}`}
            >
              {crumb.label}
            </Typography>
          );
        }
        return (
          <Fragment key={`bigBreadcrumb-${idx}`}>
            <CrumbLinkWrapper to={crumb.to}>
              <Typography
                variant="h1"
                component="h2"
                color={COLORS.GRAY[400]}
                sx={makeUnprintableStyle({
                  '&:hover': { color: COLORS.GRAY[500] },
                })}
              >
                {crumb.label}
              </Typography>
            </CrumbLinkWrapper>
            <ChevronRightIcon
              size={20}
              color={COLORS.ORANGE[600]}
              sx={{
                ...{ display: 'flex' },
                ...makeUnprintableStyle(),
              }}
            />
          </Fragment>
        );
      })}
    </Stack>
  );
}
