import { compact, first, sortBy } from 'lodash';
import { useMemo } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { getNodes } from '@/utils/graphqlUtils';

import { useAvailableGrowthProfiles } from '../../hooks/useAvailableGrowthProfiles';
import {
  EMPTY_ESTATE_WATERFALL_COMPARISON,
  EstateWaterfallComparisonShape,
  EstateWaterfallComparisonWaterfall,
} from '../EstateWaterfallComparison.type';
import { EstateWaterfallModal_EstateWaterfallFragment } from '../EstateWaterfallModal/graphql/EstateWaterfallModal.generated';
import { useWaterfallModalQuery } from '../EstateWaterfallModal/hooks/useWaterfallModalQuery';
import { useWaterfallWithParentQuery } from './graphql/WaterfallWithParent.generated';

export function useDefaultEstateWaterfallComparisonFormState(
  comparisonStateExternal: EstateWaterfallComparisonShape | null | undefined,
  waterfallId: string | undefined
): {
  form: EstateWaterfallComparisonShape;
  loading: boolean;
} {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');

  const { data, loading } = useWaterfallWithParentQuery({
    variables: {
      where: {
        id: waterfallId,
      },
    },
    onError: (error) => {
      reportError('failed to fetch waterfalls for comparison', error);
      showFeedback(
        'Could not fetch waterfalls for comparison, please try again later.'
      );
    },
    skip:
      !isGrowthProfilesEnabled ||
      !waterfallId ||
      Boolean(comparisonStateExternal),
  });

  if (comparisonStateExternal) {
    return {
      loading: false,
      form: comparisonStateExternal,
    };
  }

  if (loading || !data) {
    return {
      loading,
      form: EMPTY_ESTATE_WATERFALL_COMPARISON,
    };
  }

  const waterfall = first(compact(getNodes(data?.waterfallsWithParent)));
  if (!waterfall || !isGrowthProfilesEnabled || !waterfallId) {
    return {
      form: EMPTY_ESTATE_WATERFALL_COMPARISON,
      loading: false,
    };
  }

  const currentWaterfall: EstateWaterfallComparisonWaterfall = {
    waterfallId: waterfallId,
    displayName: waterfall.displayName,
    growthProfileId: waterfall.growthProfile?.id ?? '',
    firstDeathYear: waterfall.firstGrantorDeathYear ?? new Date().getFullYear(),
    secondDeathYear:
      waterfall.secondGrantorDeathYear ?? new Date().getFullYear(),
  };

  /*
   * This is special handling for hypothetical waterfalls.
   *
   * - If a waterfall isn't a hypothetical (has no parent), return just that waterfall as the initial form state.
   * - If a waterfall has a parent:
   *   - Return the parent waterfall in the top slot
   *   - Return the current hypothetical waterfall in the second slot
   */
  if (!waterfall.parent) {
    return {
      loading: false,
      form: {
        comparisonName: '',
        waterfalls: [currentWaterfall],
      },
    };
  }

  const parentWaterfall: EstateWaterfallComparisonWaterfall = {
    waterfallId: waterfall.parent?.id ?? '',
    displayName: waterfall.parent?.displayName ?? '',
    growthProfileId: waterfall.growthProfile?.id ?? '',
    firstDeathYear: waterfall.firstGrantorDeathYear ?? new Date().getFullYear(),
    secondDeathYear: waterfall.secondGrantorDeathYear ?? undefined,
  };

  return {
    form: {
      comparisonName: '',
      waterfalls: [parentWaterfall, currentWaterfall],
    },
    loading: false,
  };
}

export interface UseWaterfallComparisonWaterfallsAndGrowthProfilesResult
  extends Omit<ReturnType<typeof useWaterfallModalQuery>, 'household'> {
  growthProfileOptions: SelectInputOption[];
  waterfallOptions: SelectInputOption[];
  waterfalls: EstateWaterfallModal_EstateWaterfallFragment[];
  loadingHouseholds: boolean;
  loadingGrowthProfiles: boolean;
  loading: boolean;
}

export function useWaterfallComparisonWaterfallsAndGrowthProfiles(): UseWaterfallComparisonWaterfallsAndGrowthProfilesResult {
  const { householdId } = useHouseholdDetailsContext();
  const {
    household,
    loading: loadingWaterfalls,
    ...rest
  } = useWaterfallModalQuery({
    householdId: householdId ?? '',
  });

  const { growthProfiles, loading: loadingGrowthProfiles } =
    useAvailableGrowthProfiles(householdId);

  const waterfalls: EstateWaterfallModal_EstateWaterfallFragment[] = useMemo(
    () => compact(household?.estateWaterfalls),
    [household]
  );

  const waterfallOptions = useMemo<SelectInputOption[]>(
    () =>
      sortBy(
        compact(waterfalls),
        (wf) => wf.displayName
      ).map<SelectInputOption>((wf) => ({
        value: wf.id,
        display: wf.displayName,
      })),
    [waterfalls]
  );

  const growthProfileOptions = useMemo<SelectInputOption[]>(
    () =>
      sortBy(
        compact(growthProfiles),
        (gp) => gp.displayName
      ).map<SelectInputOption>((gp) => ({
        value: gp.id,
        display: gp.displayName,
        endAdornment: gp.isDefault ? (
          <Badge variant={BadgeVariants.Primary} display="Default" />
        ) : undefined,
      })),
    [growthProfiles]
  );

  return {
    ...rest,
    waterfallOptions,
    growthProfileOptions,
    waterfalls,
    loadingHouseholds: loadingWaterfalls,
    loadingGrowthProfiles,
    loading: loadingWaterfalls || loadingGrowthProfiles,
  };
}
