import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { compact } from 'lodash';

import { EstateWaterfallAssumptionFields } from '@/modules/estateWaterfall/components/EstateWaterfallAssumptions/types';
import { EstateWaterfallComparisonTrowserComponents } from '@/modules/estateWaterfall/components/EstateWaterfallComparisionTrowser/EstateWaterfallComparisonTrowser.components';
import { EstateWaterfallComparisonWaterfallWithAssumptions } from '@/modules/estateWaterfall/components/EstateWaterfallComparisionTrowser/EstateWaterfallComparisonTrowser.type';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { PresentationSlideSecondaryHeading } from '@/modules/presentation/components/PresentationSlideSecondaryHeading';
import { PresentationSlide } from '@/modules/presentation/PresentationSlide';
import { AfterDeath } from '@/types/schema';
import { formatPercent } from '@/utils/formatting/percent';

interface PrintOnlyWaterfallSlideProps {
  afterDeath: AfterDeath;
  waterfalls: EstateWaterfallComparisonWaterfallWithAssumptions[];
  assumptions: EstateWaterfallAssumptionFields;
}

const getHeaderCopy = (
  afterDeath: AfterDeath,
  assumptions: EstateWaterfallAssumptionFields,
  isGrowthProfilesEnabled: boolean
): string => {
  if (afterDeath === AfterDeath.None) {
    return compact([
      'Before 1st death',
      isGrowthProfilesEnabled ? null : `(${assumptions.firstGrantorDeathYear})`,
    ]).join(' ');
  }

  if (afterDeath === AfterDeath.First) {
    return compact([
      'After 1st death',
      isGrowthProfilesEnabled ? null : `(${assumptions.firstGrantorDeathYear})`,
    ]).join(' ');
  }

  if (afterDeath === AfterDeath.Second) {
    return compact([
      'After 2nd death',
      isGrowthProfilesEnabled
        ? null
        : `(${assumptions.secondGrantorDeathYear})`,
    ]).join(' ');
  }

  return '';
};

export function PrintOnlyWaterfallSlide({
  afterDeath,
  waterfalls,
  assumptions,
}: PrintOnlyWaterfallSlideProps) {
  const isGrowthProfilesEnabled = useFeatureFlag('growth_profiles');
  const assetGrowthReturnString = formatPercent(
    assumptions.assetGrowthReturn ?? new Decimal(0),
    0
  );

  return (
    <PresentationSlide.Slide
      id="waterfall-multi-comparison"
      leftHeaderContent={
        <PresentationSlide.MainHeading heading="Estate waterfall comparison" />
      }
      rightHeaderContent={
        <PresentationSlideSecondaryHeading
          clientDisplayName={waterfalls[0]?.householdDisplayName || ''}
        />
      }
      footer={<PresentationSlide.Footer />}
    >
      <Stack px={3} pt={3} pb={1} height="100%">
        <Stack spacing={3} height="100%">
          <Stack direction="row" alignItems="center">
            <Typography variant="h1">
              {getHeaderCopy(afterDeath, assumptions, isGrowthProfilesEnabled)}
            </Typography>
            <Stack ml="auto">
              <Typography variant="h6">
                Assumes annual asset growth of {assetGrowthReturnString}%
              </Typography>
            </Stack>
          </Stack>
          <EstateWaterfallComparisonTrowserComponents.ParentComparisonSummary
            waterfalls={waterfalls}
          />
          <EstateWaterfallComparisonTrowserComponents.BarGraph
            waterfalls={waterfalls}
            afterDeath={afterDeath}
          />
          <EstateWaterfallComparisonTrowserComponents.SummaryTable
            waterfalls={waterfalls}
          />
        </Stack>
      </Stack>
    </PresentationSlide.Slide>
  );
}
