import { isEqual } from 'date-fns';
import { includes, isEmpty, map, maxBy } from 'lodash';
import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useMinimumLoading } from '@/hooks/useMinimumLoading';
import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { useMostRecentlyViewedWaterfall } from '@/modules/user/hooks/useMostRecentlyViewedWaterfall';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { ClientDetailsEstateWaterfallPlaceholderCard } from '@/pages/ClientDetailsPage/ClientDetailsEstateWaterfallBasePage/ClientDetailsEstateWaterfallPlaceholderCard';
import { useClientDetailsEstateWaterfallQuery } from '@/pages/ClientDetailsPage/ClientDetailsEstateWaterfallBasePage/graphql/ClientDetailsEstateWaterfall.generated';
import { useWaterfallRoutingContext } from '@/pages/ClientPages/ClientWaterfallPage/WaterfallRouting.context';
import { getNodes } from '@/utils/graphqlUtils';

function ClientDetailsEstateWaterfallBasePagePlaceholder() {
  const householdId = useRequiredParam('householdId');
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const navigate = useNavigate();
  const { setIsLoadingWaterfall } = useWaterfallRoutingContext();

  const { mostRecentlyViewedWaterfallId } =
    useMostRecentlyViewedWaterfall(householdId);

  const { loading } = useClientDetailsEstateWaterfallQuery({
    fetchPolicy: 'cache-and-network', // we need to refetch entity/waterfall counts when we delete from another page
    variables: {
      householdId,
    },
    onCompleted: (data) => {
      setIsLoadingWaterfall(false);
      const household = getNodes(data?.households)?.[0];
      if (!household) {
        showFeedback(
          'Sorry, we could not load the waterfalls page. Please refresh the page and try again'
        );
        return;
      }

      const getWaterfallIdToShow = () => {
        const hasMostRecentlyViewedWaterfall = includes(
          map(household.estateWaterfalls, (ew) => ew.id),
          mostRecentlyViewedWaterfallId
        );

        // If the most recently viewed waterfall is still in the list, show it
        if (hasMostRecentlyViewedWaterfall) {
          return mostRecentlyViewedWaterfallId;
        }

        let hasUpdatedWaterfalls = false;
        for (const ew of household.estateWaterfalls ?? []) {
          if (!isEqual(ew.updatedAt, ew.createdAt)) {
            hasUpdatedWaterfalls = true;
            break;
          }
        }

        // If there are no updated waterfalls, show the first one
        if (!hasUpdatedWaterfalls) {
          return household.estateWaterfalls?.[0]?.id ?? null;
        }

        const mostRecentlyUpdated =
          maxBy(household.estateWaterfalls, (ew) => ew.updatedAt)?.id ?? null;

        // If there are updated waterfalls, show the most recently updated one
        return mostRecentlyUpdated;
      };

      // Show the "Default" waterfall: most recently viewed, or most recently updated
      const waterfallId = getWaterfallIdToShow();

      if (isEmpty(household.estateWaterfalls) || !waterfallId) {
        // No waterfalls, show Waterfalls placeholder card.
        return;
      }

      // Otherwise, we have a waterfall! Navigate to waterfall page.
      const path = getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY,
        { householdId, waterfallId }
      );

      navigate(path, {
        replace: true,
      });

      return;
    },
    onError: (error) => {
      showFeedback(
        `Sorry, we could not load the waterfalls page. Please refresh the page and try again.`
      );
      reportError(
        `error fetching waterfalls data for householdId=${householdId}`,
        error
      );
    },
  });

  const showLoading = useMinimumLoading(loading);

  if (showLoading) {
    // Do not show the placeholder card when loading
    return null;
  }

  return <ClientDetailsEstateWaterfallPlaceholderCard loading={loading} />;
}

export function ClientDetailsEstateWaterfallBasePage() {
  const { waterfallId } = useParams();

  if (waterfallId) {
    return <Outlet />;
  }

  return <ClientDetailsEstateWaterfallBasePagePlaceholder />;
}
