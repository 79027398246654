import { ApolloError } from '@apollo/client';
import { Stack } from '@mui/material';
import { ReactNode, useCallback, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { FullScreenTaskLeftPaneLayout } from '../components/FullScreenTaskLeftPaneLayout/FullScreenTaskLeftPaneLayout';
import { ILITTaskContext } from '../ILITTaskContext';
import { useSaveTaskDetailsMutationMutation } from './graphql/ILITTaskSidebarQuery.generated';
import { ILITTaskSidebarForm } from './ILITTaskSidebar.types';
import {
  getILITTaskMutationInput,
  useTaskDetailsData,
} from './ILITTaskSidebar.utils';

export interface ILITTaskSidebarProps {
  heading: string;
  subheading: string;
  children?: ReactNode;
}

export function ILITTaskSidebar({
  heading,
  subheading,
  children,
}: ILITTaskSidebarProps) {
  const currentUser = useCurrentUser();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const navigate = useNavigate();

  const { householdId, entityId, taskId } = useContext(ILITTaskContext);

  const formMethods = useForm<ILITTaskSidebarForm>({
    defaultValues: {
      dueDate: null,
      assigneeId: '',
      initialAssigneeId: '',
    },
    values: useTaskDetailsData(taskId),
  });

  const { control, formState, getValues, handleSubmit, reset } = formMethods;

  const navigateToILIT = useCallback(() => {
    const targetPath = getCompletePathFromRouteKey(
      ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
      {
        householdId,
        entityId,
      }
    );
    navigate(targetPath);
  }, [householdId, entityId, navigate]);

  const [saveTaskAssignmentDetails, { loading: isSaving }] =
    useSaveTaskDetailsMutationMutation({
      onCompleted: () => {
        showFeedback('Task assignment changes updated.', {
          variant: 'success',
        });
      },
      onError: (error: ApolloError) => {
        showFeedback('Error encountered while updating task assignment.', {
          variant: 'error',
        });
        reportError('Error encountered while saving task details', error);
      },
    });

  const onSave = handleSubmit(async () => {
    await saveTaskAssignmentDetails({
      variables: {
        input: getILITTaskMutationInput(getValues(), taskId, currentUser?.id),
      },
    });
    reset({}, { keepValues: true }); // reset to hide the button, but don't change the fields
  });

  return (
    <FormProvider {...formMethods}>
      <FullScreenTaskLeftPaneLayout
        heading={heading}
        householdId={householdId}
        control={control}
        subheading={subheading}
        showSave={formState.isDirty}
        onSave={onSave}
        taskControlsDisabled={isSaving}
        body={
          <Stack direction="column" spacing={2}>
            {children}
            <Button
              variant="secondary"
              size="sm"
              fullWidth
              onClick={navigateToILIT}
            >
              View ILIT details
            </Button>
          </Stack>
        }
        taskDescription="Specify an internal employee responsible for completing this task"
      />
    </FormProvider>
  );
}
