import { COLORS } from '@/styles/tokens/colors';

import { PieChart } from './PieChart';

export interface ProgressPieChartProps {
  progressPercent: number;
  size?: number;
  emptyColor?: string;
  progressColor?: string;
}

export function ProgressPieChart({
  progressPercent,
  size = 50,
  emptyColor = COLORS.NAVY[600],
  progressColor = COLORS.FUNCTIONAL.SUCCESS.DEFAULT,
}: ProgressPieChartProps) {
  return (
    <PieChart
      tooltip={{
        trigger: 'none',
      }}
      slotProps={{
        legend: { hidden: true },
      }}
      height={size}
      width={size}
      sx={{
        '.MuiPieArc-root': {
          strokeWidth: 0,
        },
      }}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      series={[
        {
          data: [
            { value: progressPercent, color: progressColor },
            { value: 100 - progressPercent, color: emptyColor },
          ],
        },
      ]}
      skipAnimation
    />
  );
}
