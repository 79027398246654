import { useMemo } from 'react';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { EntityKind } from '@/types/schema';

import { RowData } from './ExpandableBeneficiaryTable.types';
import { getColumns, getRows } from './ExpandableBeneficiaryTable.utils';
import { TrustBeneficiariesList_BeneficiaryFragment } from './graphql/TrustBeneficiariesList.fragments.generated';

interface ExpandableBeneficiaryTableProps {
  heading?: string;
  beneficiaries: TrustBeneficiariesList_BeneficiaryFragment[];
  entityKind?: EntityKind;
}

export function ExpandableBeneficiaryTable({
  heading,
  beneficiaries,
  entityKind,
}: ExpandableBeneficiaryTableProps) {
  const columns = useMemo(() => {
    return getColumns({ heading });
  }, [heading]);

  const rows = useMemo(() => {
    return getRows(beneficiaries, entityKind);
  }, [beneficiaries, entityKind]);

  return (
    <DataTable
      columns={columns}
      rows={rows}
      treeData
      disableChildrenSorting
      useTreeStyling
      hideFooterPagination
      pagination={false}
      defaultGroupingExpansionDepth={0}
      getTreeDataPath={(row: RowData) => row.path}
    />
  );
}
