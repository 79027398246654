import {
  array,
  assign,
  Describe,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateCCorpBusinessEntityInput,
  AugmentedCreateEntityInput,
  AugmentedUpdateCCorpBusinessEntityInput,
  AugmentedUpdateEntityInput,
} from '@/types/schema';

import {
  commonCreateBusinessEntityProperties,
  commonCreateBusinessEntityTopLevelProperties,
  commonUpdateBusinessEntityEntityUpdateProperties,
  commonUpdateBusinessEntityProperties,
  commonUpdateBusinessEntityTopLevelProperties,
  keyPersonSchema,
} from './businessEntityTypes';
import {
  createInsurancePolicyInput,
  updateInsurancePolicyInput,
} from './insuranceTypes';
import {
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  principalWithOwnershipSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const createCCorpSchema: Describe<AugmentedCreateCCorpBusinessEntityInput> =
  object({
    create: commonCreateBusinessEntityProperties,
    withOwners: optional(nullable(array(principalWithOwnershipSchema))),
    withKeyPeople: optional(nullable(array(keyPersonSchema))),
    withDesignerAccount: optional(nullable(createAccountSchema)),
  });

const updateCCorpSchema: Describe<AugmentedUpdateCCorpBusinessEntityInput> =
  object({
    id: string(),
    update: commonUpdateBusinessEntityProperties,
    withOwners: optional(nullable(array(principalWithOwnershipSchema))),
    withKeyPeople: optional(nullable(array(keyPersonSchema))),
    updateDesignerAccount: optional(nullable(updateAccountSchema)),
    withPolicies: optional(nullable(array(createInsurancePolicyInput))),
    updatePolicies: optional(nullable(array(updateInsurancePolicyInput))),
  });

const createSchema: Describe<AugmentedCreateEntityInput> = assign(
  object({
    create: createEntityInput,
    withCcorpBusinessEntity: optional(nullable(createCCorpSchema)),
    withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
  }),
  commonCreateBusinessEntityTopLevelProperties
);

const updateSchema: Describe<AugmentedUpdateEntityInput> = assign(
  object({
    id: string(),
    update: commonUpdateBusinessEntityEntityUpdateProperties,
    updateCcorpBusinessEntity: optional(nullable(updateCCorpSchema)),
    withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
  }),
  commonUpdateBusinessEntityTopLevelProperties
);

export function validateCcorpCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'Ccorp create');
}

export function validateCcorpUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'Ccorp update');
}
