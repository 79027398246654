import { Box, BoxProps, Typography } from '@mui/material';

import { RefreshCcw05Icon } from '@/components/icons/RefreshCcw05Icon';
import { useAssetProviderDisplayName } from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';

export interface IntegrationContextBannerProps extends BoxProps {
  variant: 'linked' | 'linked-with-manual';
}

export function IntegrationContextBanner({
  variant,
  ...boxProps
}: IntegrationContextBannerProps) {
  const integrationProviderName = useAssetProviderDisplayName();
  return (
    <Box textAlign="center" {...boxProps}>
      <RefreshCcw05Icon color={COLORS.GRAY[200]} size={48} />
      <Typography variant="h2" gutterBottom={variant === 'linked'}>
        Linked to {integrationProviderName}
      </Typography>
      {variant === 'linked-with-manual' && (
        <Typography fontWeight={FONT_WEIGHTS.medium} variant="h4" gutterBottom>
          with additional manual tracking
        </Typography>
      )}
      <Typography variant="body2" sx={{ maxWidth: '80%', mx: 'auto' }}>
        {variant === 'linked'
          ? 'Valuation changes will be captured automatically moving forward'
          : 'Valuation changes will be captured automatically and tallied with manually entered assets'}
      </Typography>
    </Box>
  );
}
