import { ApolloError } from '@apollo/client';
import { Stack, Typography } from '@mui/material';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useWatch } from 'react-hook-form';

import { SpinningIcon } from '@/components/animation/SpinningIcon/SpinningIcon';
import { SectionHeader } from '@/components/display/SectionHeader/SectionHeader';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { Link } from '@/components/form/baseInputs/Link/Link';
import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Card, CardProps } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { PopperContent } from '@/components/poppers/PopperContent';
import { LoadingSkeletonOrValue } from '@/components/progress/LoadingSkeletonOrValue/LoadingSkeletonOrValue';
import { useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import {
  RATE_7520_DECIMAL_SCALE,
  Rate7520Input,
} from '@/modules/trusts/Rate7520Input/Rate7520Input';
import {
  GetCharitableProjectionQuery,
  useGetCharitableProjectionLazyQuery,
} from '@/pages/client-proposal/graphql/GetCharitableProjection.generated';
import {
  CharitableTrustCalculationMethod,
  CharitableTrustPayoutKind,
  CharitableTrustTermKind,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import { formatDateToMonYYYY } from '@/utils/formatting/dates';
import { formatPercent } from '@/utils/formatting/percent';
import { validateDateMustBeInPast } from '@/utils/validators/validateDateMustBeInPast';
import { validateNumberRange } from '@/utils/validators/validateNumberRange';
import { validateStringLength } from '@/utils/validators/validateStringLength';
import { validateYearAfter } from '@/utils/validators/validateYearAfter';

import { useIsCRT } from '../CharitableTrustDesignerContext';
import {
  CharitableTrustDesignerAnalysisForm,
  CharitableTrustDesignerAnalysisFormPaths,
  NAMESPACE,
} from './CharitableTrustDesignerAnalysis.types';
import {
  CALCULATION_METHOD_OPTIONS,
  CLT_OPTIMIZATION_TARGET_OPTIONS,
  CRT_OPTIMIZATION_TARGET_OPTIONS,
  PAYOUT_FREQUENCY_OPTIONS,
  PAYOUT_KIND_OPTIONS,
  TERM_KIND_OPTIONS,
  UNITRUST_KIND_OPTIONS,
} from './CharitableTrustDesignerAnalysisTrustStructure.constants';
import {
  getValidateMaximumCLATPayout,
  getValidateMaximumCLUTPayout,
  getValidateMaximumCRATPayout,
  getValidateMaximumCRUTPayout,
  getValidateMinimumCLATPayout,
  getValidateMinimumCLUTPayout,
  getValidateMinimumCRATPayout,
  getValidateMinimumCRUTPayout,
} from './CharitableTrustDesignerAnalysisTrustStructure.validations';
import { CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment } from './graphql/CharitableTrustDesignerIllustrationData.generated';
import { useGetCharitableDesignerOptimizationLazyQuery } from './graphql/GetCharitatbleDesignerOptimization.generated';
import { useCharitableOptimization } from './hooks/useCharitableOptimization';
import {
  BaseCharitableProjectionVariables,
  useCharitableProjection,
} from './hooks/useCharitableProjection';
import { CHARITABLE_DESIGNER_LIFETIME_MAX_AGE } from './hooks/useDonorsYearOfBirth';

const filledCardProps: CardProps = {
  variant: 'filled',
  sx: {
    p: 3,
  },
};

function TrustStructureCard({
  header,
  rightHeaderContent,
  children,
}: {
  header: string;
  rightHeaderContent?: ReactNode;
  children: ReactNode;
}) {
  return (
    <Card variant="outlined" sx={{ p: 3, pb: 4 }}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3" sx={{ pb: 2 }}>
          {header}
        </Typography>
        {rightHeaderContent}
      </Stack>
      {children}
    </Card>
  );
}

export interface CharitableTrustDesignerAnalysisTrustStructureProps {
  onSubmit: () => void;
}

export function CharitableTrustDesignerAnalysisTrustStructure() {
  return (
    <Stack direction="column" spacing={3}>
      <SectionHeader title="Trust structure" />
      <AssetsCard />
      <TermCard />
      <Payouts />
    </Stack>
  );
}

const PATH_TERM_KIND =
  `${NAMESPACE}.term.kind` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_TERM_LENGTH =
  `${NAMESPACE}.term.length` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_ASSET_VALUE =
  `${NAMESPACE}.assets.value` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_PAYOUT_KIND =
  `${NAMESPACE}.payouts.kind` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_CALCULATION_METHOD =
  `${NAMESPACE}.payouts.calculationMethod` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_OPTIMIZATION_TARGET =
  `${NAMESPACE}.payouts.optimizationTarget` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_DONORS =
  `${NAMESPACE}.term.donors` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_RATE_7520 =
  `${NAMESPACE}.term.rate7520` as const satisfies CharitableTrustDesignerAnalysisFormPaths;
const PATH_YEAR_OF_ANALYSIS =
  `${NAMESPACE}.analysis.yearOfAnalysis` as const satisfies CharitableTrustDesignerAnalysisFormPaths;

function AssetsCard() {
  const { control } = useFormContext<CharitableTrustDesignerAnalysisForm>();

  const isCRT = useIsCRT();
  return (
    <TrustStructureCard header="Assets">
      <FormLayoutRow columns={1}>
        <FormLayoutItem width={1}>
          <FormAwareCurrencyInput
            isDecimalJSInput
            decimalScale={2}
            label="Funding value"
            required
            control={control}
            fieldName={PATH_ASSET_VALUE}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {isCRT && (
        <FormLayoutRow columns={1}>
          <FormLayoutItem width={1}>
            <FormAwareCurrencyInput
              isDecimalJSInput
              control={control}
              fieldName={`${NAMESPACE}.assets.costBasis`}
              label="Cost basis"
              contextualHelp={
                <PopperContent body="Enter the cost of basis of the assets used to fund the trust." />
              }
              required
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      <FormLayoutRow columns={1}>
        <FormLayoutItem width={1}>
          <FormAwareTextInput
            control={control}
            fieldName={`${NAMESPACE}.assets.notes`}
            label="Notes"
            maxRows={3}
            multiline
            validation={{
              length: validateStringLength({
                fieldName: 'Notes',
                max: 240,
              }),
            }}
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </TrustStructureCard>
  );
}

function TermCard() {
  const { control, trigger } =
    useFormContext<CharitableTrustDesignerAnalysisForm>();
  const [term, donors, termLength] = useWatch({
    control,
    name: [PATH_TERM_KIND, PATH_DONORS, PATH_TERM_LENGTH],
  });

  const year7520 = new Date();
  // will always re-run the hooks as currentDate will change on each render
  const { rate7520 } = useIRSConstants({ at: year7520 });

  useEffect(() => {
    void trigger(PATH_YEAR_OF_ANALYSIS);
  }, [termLength, trigger]);

  return (
    <TrustStructureCard header="Term">
      <Stack spacing={2}>
        <Card {...filledCardProps}>
          <FormLayoutRow>
            <FormLayoutItem>
              <FormAwareRadioGroup
                control={control}
                fieldName={PATH_TERM_KIND}
                options={TERM_KIND_OPTIONS}
                row
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </Card>
        {term === CharitableTrustTermKind.Lifetime ? (
          <>
            {donors.map((donor, index) => (
              <FormLayoutRow key={index} columns={1}>
                <FormLayoutItem width={1}>
                  <FormAwareDatePickerInput
                    control={control}
                    fieldName={`${PATH_DONORS}.${index}.dateOfBirth`}
                    label={`${donor.displayName} date of birth`}
                    required
                    validateOnChange
                    validation={{
                      min: validateDateMustBeInPast('Date of birth'),
                      max: validateYearAfter(
                        'Date of birth',
                        CHARITABLE_DESIGNER_LIFETIME_MAX_AGE,
                        `Lifetime option is only available for individuals of a maximum age of ${CHARITABLE_DESIGNER_LIFETIME_MAX_AGE} years`
                      ),
                    }}
                  />
                </FormLayoutItem>
              </FormLayoutRow>
            ))}
            <FormLayoutRow columns={1}>
              <FormLayoutItem width={1}>
                <FormAwareFormattedNumberInput
                  control={control}
                  fieldName={PATH_TERM_LENGTH}
                  label="Length of analysis"
                  validateOnChange
                  validation={{
                    minMax: validateNumberRange({
                      min: 2,
                      max: 50,
                      fieldName: 'Analysis',
                      unit: 'years',
                    }),
                  }}
                  contextualHelp={
                    <PopperContent body="Enter a maximum of 50 years. Please note that charitable deductions will be calculated using the donor's current age and IRS actuarial tables." />
                  }
                />
              </FormLayoutItem>
            </FormLayoutRow>
          </>
        ) : (
          <FormLayoutRow columns={1}>
            <FormLayoutItem width={1}>
              <FormAwareFormattedNumberInput
                control={control}
                fieldName={PATH_TERM_LENGTH}
                label="Length of term (years)"
                helpText="Minimum 2 years; maximum 20 years"
                validateOnChange
                validation={{
                  minMax: validateNumberRange({
                    min: 2,
                    max: 20,
                    fieldName: 'Analysis',
                    unit: 'years',
                  }),
                }}
              />
            </FormLayoutItem>
          </FormLayoutRow>
        )}
        <FormLayoutRow columns={2}>
          <FormLayoutItem width={1}>
            <Rate7520Input
              control={control}
              fieldName={PATH_RATE_7520}
              contextualHelp={
                <PopperContent body="The IRC section 7520 rate is used to calculate the charitable deduction from funding charitable trusts, and is set monthly based on the mid-term Applicable Federal Rate (AFR)." />
              }
              isDecimalJSInput
            />
          </FormLayoutItem>
          <FormLayoutItem width={1}>
            <Callout severity="info-low" sx={{ height: '100%' }}>
              <Typography variant="subtitle2">
                Rate of{' '}
                <LoadingSkeletonOrValue
                  emptyValue=""
                  value={
                    rate7520
                      ? `${formatPercent(rate7520, RATE_7520_DECIMAL_SCALE)}`
                      : null
                  }
                  loading={!rate7520}
                />
                % as of {formatDateToMonYYYY(year7520)}.
              </Typography>
              <Link
                display="Learn more"
                href="https://www.irs.gov/businesses/small-businesses-self-employed/section-7520-interest-rates"
                external
              />
            </Callout>
          </FormLayoutItem>
        </FormLayoutRow>
      </Stack>
    </TrustStructureCard>
  );
}

function Payouts() {
  const isCRT = useIsCRT();
  const { control, setValue } =
    useFormContext<CharitableTrustDesignerAnalysisForm>();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [crtProjection, setCrtProjection] = useState<
    | CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment
    | undefined
  >(undefined);

  const [getCharitableDesignerOptimizationLazy, { loading }] =
    useGetCharitableDesignerOptimizationLazyQuery({
      fetchPolicy: 'network-only',
      onError: (err: ApolloError) => {
        showFeedback('Could not get updated payout calculation');
        reportError(
          'Encountered exception when fetching calculated payouts',
          err
        );
      },
    });

  useCharitableOptimization(getCharitableDesignerOptimizationLazy);

  const onQueryError = useCallback(
    (err: ApolloError) => {
      showFeedback('Could not fetch projection');
      reportError('Could not fetch projection', err);
    },
    [reportError, showFeedback]
  );

  const [charitableProjectionLazyQuery] = useGetCharitableProjectionLazyQuery({
    onError: onQueryError,
  });

  const handleSetProjections = useCallback(
    ({ crtProposalProjection }: GetCharitableProjectionQuery) => {
      setCrtProjection(crtProposalProjection);
    },
    []
  );

  const getVariables = useCallback(
    (vars: BaseCharitableProjectionVariables) => vars,
    []
  );

  useCharitableProjection(
    charitableProjectionLazyQuery,
    handleSetProjections,
    getVariables
  );

  const [payoutKind, calculationMethod, initialFundingAmount] = useWatch({
    control,
    name: [PATH_PAYOUT_KIND, PATH_CALCULATION_METHOD, PATH_ASSET_VALUE],
  });

  const minimumValidator = useMemo(() => {
    if (isCRT) {
      if (payoutKind === CharitableTrustPayoutKind.Annuity) {
        return getValidateMinimumCRATPayout(
          initialFundingAmount,
          crtProjection
        );
      } else if (isCRT && payoutKind === CharitableTrustPayoutKind.Unitrust) {
        return getValidateMinimumCRUTPayout(crtProjection);
      }
    } else {
      if (payoutKind === CharitableTrustPayoutKind.Annuity) {
        return getValidateMinimumCLATPayout();
      } else if (payoutKind === CharitableTrustPayoutKind.Unitrust) {
        return getValidateMinimumCLUTPayout();
      }
    }
    throw new UnreachableError({
      case: `${isCRT} - ${payoutKind}` as never,
      message: 'Invalid payout configuration',
    });
  }, [crtProjection, initialFundingAmount, isCRT, payoutKind]);

  const maximumValidator = useMemo(() => {
    if (isCRT) {
      if (payoutKind === CharitableTrustPayoutKind.Annuity) {
        return getValidateMaximumCRATPayout(crtProjection);
      } else if (payoutKind === CharitableTrustPayoutKind.Unitrust) {
        return getValidateMaximumCRUTPayout(crtProjection);
      }
    } else {
      if (payoutKind === CharitableTrustPayoutKind.Annuity) {
        return getValidateMaximumCLATPayout(initialFundingAmount);
      } else if (payoutKind === CharitableTrustPayoutKind.Unitrust) {
        return getValidateMaximumCLUTPayout();
      }
    }
    throw new UnreachableError({
      case: `${isCRT} - ${payoutKind}` as never,
      message: 'Invalid payout configuration',
    });
  }, [crtProjection, initialFundingAmount, isCRT, payoutKind]);

  const isCalculated =
    calculationMethod === CharitableTrustCalculationMethod.Calculated;

  return (
    <TrustStructureCard
      header="Payouts"
      rightHeaderContent={loading ? <SpinningIcon /> : undefined}
    >
      <FormLayoutRow columns={1}>
        <FormLayoutItem width={1}>
          <Card {...filledCardProps}>
            <Stack spacing={2}>
              <FormAwareRadioGroup
                control={control}
                fieldName={PATH_PAYOUT_KIND}
                required
                options={PAYOUT_KIND_OPTIONS}
                row
              />
              <Typography variant="subtitle2">
                {payoutKind === CharitableTrustPayoutKind.Annuity
                  ? 'Annuity trusts distribute a fixed dollar amount each year.'
                  : 'Unitrusts distribute a fixed percentage of trust assets each year.'}
              </Typography>
              {isCRT && payoutKind === CharitableTrustPayoutKind.Unitrust && (
                <FormAwareSelectInput
                  control={control}
                  required
                  fieldName={`${NAMESPACE}.payouts.unitrustKind`}
                  options={UNITRUST_KIND_OPTIONS}
                  label="Type"
                />
              )}
            </Stack>
          </Card>
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow columns={1}>
        <FormLayoutItem width={1}>
          <ButtonGroup
            variant="dark"
            label="Calculation method"
            hideLabel
            options={CALCULATION_METHOD_OPTIONS}
            value={calculationMethod}
            onChange={(_, value) => {
              setValue(PATH_CALCULATION_METHOD, value);
            }}
          />
        </FormLayoutItem>
      </FormLayoutRow>
      {isCalculated ? (
        <>
          <FormLayoutRow columns={1}>
            <FormLayoutItem width={1}>
              <Typography variant="body1">
                Optimize payouts to maximize...
              </Typography>
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem>
              <FormAwareRadioGroup
                control={control}
                fieldName={PATH_OPTIMIZATION_TARGET}
                row
                options={
                  isCRT
                    ? CRT_OPTIMIZATION_TARGET_OPTIONS
                    : CLT_OPTIMIZATION_TARGET_OPTIONS
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </>
      ) : null}
      <FormLayoutRow columns={2}>
        <FormLayoutItem width={1}>
          {payoutKind === CharitableTrustPayoutKind.Annuity ? (
            <FormAwareCurrencyInput
              isDecimalJSInput
              control={control}
              fieldName={`${NAMESPACE}.payouts.annuityPaymentAmount`}
              label="Amount"
              disabled={isCalculated}
              required
              validateOnChange
              validation={{
                min: minimumValidator,
                max: maximumValidator,
              }}
            />
          ) : (
            <FormAwarePercentInput
              isDecimalJSInput
              decimalScale={5}
              control={control}
              fieldName={`${NAMESPACE}.payouts.unitrustPayoutPercent`}
              label="Yearly amount"
              disabled={isCalculated}
              required
              validateOnChange
              validation={{
                min: minimumValidator,
                max: maximumValidator,
              }}
            />
          )}
        </FormLayoutItem>
        <FormLayoutItem width={1}>
          <FormAwareSelectInput
            control={control}
            fieldName={`${NAMESPACE}.payouts.frequency`}
            label="Distribution frequency"
            required
            options={PAYOUT_FREQUENCY_OPTIONS}
            showEmptyValue={false}
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </TrustStructureCard>
  );
}
