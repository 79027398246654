import Decimal from 'decimal.js';

import { BalanceSheetRowType } from '@/types/schema';

export interface RowValue {
  value: Decimal | null;
  description?: string;
}

export interface RowData {
  id: string;
  name: {
    lineOne: string;
    lineTwo: string | undefined;
  };
  // this is used for "tree data", which is the MUI data grid row collapsing feature
  path: string[];
  // map of columnId to value
  values: Record<string, RowValue>;
  variant: BalanceSheetRowType;
  // we use this to determine if a row can be expanded/collapsed
  hasChildren: boolean;

  // a row will either have entityIds or liabilityId, but not both
  entityIds?: string[];
  liabilityId?: string;
}

export const BALANCE_SHEET_TABLE_CLASS_NAME = 'BalanceSheetTable';
