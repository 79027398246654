import { createContext, ReactNode } from 'react';

import { FormAwareSelectInputProps } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { ClientProfessionalTeamForm } from '@/modules/professionalTeam/ClientProfessionalTeam.types';

export interface ClientProfessionalTeamContextType {
  teamMemberOptions: FormAwareSelectInputProps<ClientProfessionalTeamForm>['options'];
  principalClientDisplayInfo: Record<string, string>;
  householdId: string;
}

export const ClientProfessionalTeamContext =
  createContext<ClientProfessionalTeamContextType>({
    teamMemberOptions: [],
    principalClientDisplayInfo: {},
    householdId: '',
  });

export function ClientProfessionalTeamContextProvider({
  children,
  ...props
}: ClientProfessionalTeamContextType & { children: ReactNode }) {
  return (
    <ClientProfessionalTeamContext.Provider value={props}>
      {children}
    </ClientProfessionalTeamContext.Provider>
  );
}
