// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAssetClassMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateAssetClassInput;
}>;


export type CreateAssetClassMutation = { __typename?: 'Mutation', createAssetClass: { __typename?: 'AssetClass', id: string, displayName: string, isSystemClass: boolean, sortOrder: number } };


export const CreateAssetClassDocument = gql`
    mutation CreateAssetClass($input: AugmentedCreateAssetClassInput!) {
  createAssetClass(input: $input) {
    id
    displayName
    isSystemClass
    sortOrder
  }
}
    `;
export type CreateAssetClassMutationFn = Apollo.MutationFunction<CreateAssetClassMutation, CreateAssetClassMutationVariables>;

/**
 * __useCreateAssetClassMutation__
 *
 * To run a mutation, you first call `useCreateAssetClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetClassMutation, { data, loading, error }] = useCreateAssetClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetClassMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetClassMutation, CreateAssetClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetClassMutation, CreateAssetClassMutationVariables>(CreateAssetClassDocument, options);
      }
export type CreateAssetClassMutationHookResult = ReturnType<typeof useCreateAssetClassMutation>;
export type CreateAssetClassMutationResult = Apollo.MutationResult<CreateAssetClassMutation>;
export type CreateAssetClassMutationOptions = Apollo.BaseMutationOptions<CreateAssetClassMutation, CreateAssetClassMutationVariables>;
export const Operations = {
  Mutation: {
    CreateAssetClass: 'CreateAssetClass' as const
  }
}