import { FlowChart, FlowChartProps } from '@/components/diagrams/FlowChart';
import { Background } from '@/components/diagrams/FlowChart/components/Background';
import { Controls } from '@/components/diagrams/FlowChart/components/Controls';
import { EditButton } from '@/components/diagrams/FlowChart/components/Controls/EditButton';
import { FitViewButton } from '@/components/diagrams/FlowChart/components/Controls/FitViewButton';
import { ZoomInButton } from '@/components/diagrams/FlowChart/components/Controls/ZoomInButton';
import { ZoomOutButton } from '@/components/diagrams/FlowChart/components/Controls/ZoomOutButton';

import { useFlowChartProps } from './hooks/useFlowChartProps.hook';

export type FamilyTreeFlowChartProps = Partial<FlowChartProps>;

export const FamilyTreeFlowChart = (props: FamilyTreeFlowChartProps) => {
  const flowChartProps = useFlowChartProps();

  return (
    <FlowChart {...flowChartProps} {...props}>
      <Background />
      <Controls>
        <>
          <EditButton />
          <FitViewButton />
          <ZoomOutButton />
          <ZoomInButton />
        </>
      </Controls>
    </FlowChart>
  );
};
