// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
export type ClientProfileFragment = { __typename?: 'ClientProfile', id: string, firstName: string, lastName: string, email?: string | null, dateOfBirth?: Time | null, isGrantor: boolean, isTrustee: boolean, isPrimary: boolean, isBeneficiary: boolean, displayName: string, legalName: string, middleName?: string | null, suffix?: string | null, address?: { __typename?: 'Address', id: string, cityName?: string | null, stateCode: string } | null };

export type ClientProfileMinimalGrantorRelationshipFragment = { __typename?: 'ClientProfileRelationship', id: string, type: Types.ClientProfileRelationshipType, fromClientProfile: { __typename?: 'ClientProfile', id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }, toClientProfile: { __typename?: 'ClientProfile', id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string } };

export type ClientProfileBasicFragment = { __typename?: 'ClientProfile', id: string, displayName: string, legalName: string, firstName: string };

export type RelationshipFragment = { __typename?: 'ClientProfileRelationship', id: string, type: Types.ClientProfileRelationshipType, toClientProfile: { __typename?: 'ClientProfile', id: string } };

export type ClientProfileWithRelationshipsFragment = { __typename?: 'ClientProfile', id: string, firstName: string, lastName: string, email?: string | null, dateOfBirth?: Time | null, isGrantor: boolean, isTrustee: boolean, isPrimary: boolean, isBeneficiary: boolean, displayName: string, legalName: string, middleName?: string | null, suffix?: string | null, relationships?: Array<{ __typename?: 'ClientProfileRelationship', id: string, type: Types.ClientProfileRelationshipType, toClientProfile: { __typename?: 'ClientProfile', id: string } }> | null, address?: { __typename?: 'Address', id: string, cityName?: string | null, stateCode: string } | null };

export const ClientProfileMinimalGrantorRelationshipFragmentDoc = gql`
    fragment ClientProfileMinimalGrantorRelationship on ClientProfileRelationship {
  id
  type
  fromClientProfile {
    id
    isGrantor
    isPrimary
    firstName
    lastName
  }
  toClientProfile {
    id
    isGrantor
    isPrimary
    firstName
    lastName
  }
}
    `;
export const ClientProfileBasicFragmentDoc = gql`
    fragment ClientProfileBasic on ClientProfile {
  id
  displayName
  legalName
  firstName
}
    `;
export const ClientProfileFragmentDoc = gql`
    fragment ClientProfile on ClientProfile {
  id
  firstName
  lastName
  email
  dateOfBirth
  isGrantor
  isTrustee
  isPrimary
  isBeneficiary
  displayName
  legalName
  middleName
  suffix
  address {
    id
    cityName
    stateCode
  }
}
    `;
export const RelationshipFragmentDoc = gql`
    fragment Relationship on ClientProfileRelationship {
  id
  type
  toClientProfile {
    id
  }
}
    `;
export const ClientProfileWithRelationshipsFragmentDoc = gql`
    fragment ClientProfileWithRelationships on ClientProfile {
  ...ClientProfile
  relationships {
    ...Relationship
  }
}
    ${ClientProfileFragmentDoc}
${RelationshipFragmentDoc}`;
export const Operations = {
  Fragment: {
    ClientProfile: 'ClientProfile' as const,
    ClientProfileMinimalGrantorRelationship: 'ClientProfileMinimalGrantorRelationship' as const,
    ClientProfileBasic: 'ClientProfileBasic' as const,
    Relationship: 'Relationship' as const,
    ClientProfileWithRelationships: 'ClientProfileWithRelationships' as const
  }
}