import { Position } from '@xyflow/react';
import Decimal from 'decimal.js';

import { EdgeLabelVariant } from '@/components/diagrams/components/EdgeLabel';
import { ArrowEdgeVariant } from '@/components/diagrams/FlowChart/edges/ArrowEdge';
import { EstateWaterfallEdgeKind } from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { diagnostics } from '@/utils/diagnostics';
import { formatCurrency } from '@/utils/formatting/currency';
import { FlowChartGraph } from '@/utils/graphology/FlowChartGraph';

import {
  EstateWaterfall_EdgeFragment,
  EstateWaterfallFragment,
} from '../graphql/EstateWaterfall.generated';
import {
  EstateWaterfallGraphAttributes,
  EstateWaterfallGraphEdgeAttributes,
  EstateWaterfallGraphNodeAttributes,
  GraphNode,
  GraphNodeData,
} from '../types';
import { getNodeId } from './utils';
import { createEdge } from './waterfallGraph';

// Utility functions for edge operations
function getLabelFromEdgeKind(kind: EstateWaterfallEdgeKind) {
  switch (kind) {
    case EstateWaterfallEdgeKind.Transfer:
      return 'Transfer';
    case EstateWaterfallEdgeKind.Disposition:
      return '';
    default:
      diagnostics.warn(
        'Unknown edge kind, label will be labeled as Disposition'
      );
      return '';
  }
}

function getArrowEdgeVariantFromEdgeKind(
  kind: EstateWaterfallEdgeKind
): ArrowEdgeVariant {
  switch (kind) {
    case EstateWaterfallEdgeKind.Transfer:
      return 'secondary';
    case EstateWaterfallEdgeKind.Disposition:
      return 'primary';
    default:
      diagnostics.warn('Unknown edge kind, variant will be primary');
      return 'primary';
  }
}

function getEdgeLabelVariantFromEdgeKind(
  kind: EstateWaterfallEdgeKind
): EdgeLabelVariant {
  switch (kind) {
    case EstateWaterfallEdgeKind.Transfer:
      return 'secondary';
    case EstateWaterfallEdgeKind.Disposition:
      return 'primary';
    default:
      diagnostics.warn('Unknown edge kind, variant will be primary');
      return 'primary';
  }
}

function getTargetHandleFromEdgeKind(
  kind: EstateWaterfallEdgeKind
): Position | undefined {
  switch (kind) {
    case EstateWaterfallEdgeKind.Transfer:
      // We want transfers to appear as horizontal lines from left to right
      return Position.Left;
    default:
      return Position.Top;
  }
}

function getSourceHandleFromEdgeKind(
  kind: EstateWaterfallEdgeKind
): Position | undefined {
  switch (kind) {
    case EstateWaterfallEdgeKind.Transfer:
      return Position.Right;
    default:
      return Position.Bottom;
  }
}

const getEdgeSource = (edge: EstateWaterfall_EdgeFragment) =>
  getNodeId({
    id: edge.from.group?.id || edge.from.id,
    afterDeath: edge.from.afterDeath,
  });

const getEdgeTarget = (edge: EstateWaterfall_EdgeFragment) =>
  getNodeId({
    id: edge.to.group?.id || edge.to.id,
    afterDeath: edge.to.afterDeath,
  });

interface DrawEdgesInput {
  viz: EstateWaterfallFragment['visualizationWithProjections'];
  graph: FlowChartGraph<
    EstateWaterfallGraphNodeAttributes<GraphNode, GraphNodeData>,
    EstateWaterfallGraphEdgeAttributes,
    EstateWaterfallGraphAttributes
  >;
}

enum EdgeLinkType {
  NodeToGroup = 'node-to-group',
  GroupToGroup = 'group-to-group',
  GroupToNode = 'group-to-node',
}

export function drawEdges({ viz, graph }: DrawEdgesInput): void {
  const createAndAddEdge = (
    source: string,
    target: string,
    kind: EstateWaterfallEdgeKind,
    value: Decimal
  ) => {
    const edge = createEdge({
      source,
      target,
      targetHandle: getTargetHandleFromEdgeKind(kind),
      sourceHandle: getSourceHandleFromEdgeKind(kind),
      data: {
        variant: getArrowEdgeVariantFromEdgeKind(kind),
        edgeLabel: {
          label: getLabelFromEdgeKind(kind),
          value: formatCurrency(value, { notation: 'compact' }),
          variant: getEdgeLabelVariantFromEdgeKind(kind),
        },
      },
    });

    if (!graph.hasNode(source) || !graph.hasNode(target)) {
      return;
    }

    graph.addEdgeSafe(source, target, { type: 'default', edge });
  };

  // Add edges between ungrouped nodes
  viz.edges.forEach(({ to, from, value, kind, ...rest }) => {
    const source = getEdgeSource({ to, from, value, kind, ...rest });
    const target = getEdgeTarget({ to, from, value, kind, ...rest });

    const edgeValue = to.group?.id || from.group?.id ? null : value;

    if (edgeValue) {
      createAndAddEdge(source, target, kind, edgeValue);
    }
  });

  const reduceEdges = (
    edges: typeof groupToGroupEdges,
    linkType: EdgeLinkType
  ) =>
    edges.reduce<Record<string, (typeof nodeToGroupEdges)[0]>>((acc, edge) => {
      const source = getNodeId({
        id:
          linkType === EdgeLinkType.NodeToGroup
            ? edge.from.id
            : edge.from.group!.id,
        afterDeath: edge.from.afterDeath,
      });

      const target = getNodeId({
        id:
          linkType === EdgeLinkType.GroupToNode
            ? edge.to.id
            : edge.to.group!.id,
        afterDeath: edge.to.afterDeath,
      });

      const key = `${source}|${target}`;
      const existingValue = acc[key]?.value ?? new Decimal(0);
      const newValue = sumDecimalJS([existingValue, edge.value]);

      acc[key] = { ...edge, value: newValue };

      return acc;
    }, {});

  // Edges that require reducing the underlying edge values before drawing
  const groupToGroupEdges = viz.edges.filter(
    ({ from, to }) => from.group && to.group
  );
  const nodeToGroupEdges = viz.edges.filter(
    ({ from, to }) => !from.group && to.group
  );
  const groupToNodeEdges = viz.edges.filter(
    ({ from, to }) => from.group && !to.group
  );

  const groupToGroupEdgesReduced = reduceEdges(
    groupToGroupEdges,
    EdgeLinkType.GroupToGroup
  );
  const nodeToGroupEdgesReduced = reduceEdges(
    nodeToGroupEdges,
    EdgeLinkType.NodeToGroup
  );
  const groupToNodeEdgesReduced = reduceEdges(
    groupToNodeEdges,
    EdgeLinkType.GroupToNode
  );

  const addReducedEdges = (reducedEdges: typeof groupToGroupEdgesReduced) => {
    Object.entries(reducedEdges).forEach(([key, { kind, value }]) => {
      const [source, target] = key.split('|');
      if (source && target) {
        createAndAddEdge(source, target, kind, value);
      }
    });
  };

  // Add reduced edges to the graph
  addReducedEdges(groupToGroupEdgesReduced);
  addReducedEdges(nodeToGroupEdgesReduced);
  addReducedEdges(groupToNodeEdgesReduced);
}
