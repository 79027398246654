import { Stack, Typography } from '@mui/material';
import { useCallback } from 'react';

import { ConfirmationModal } from '@/components/modals/ConfirmationModal/ConfirmationModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { COLORS } from '@/styles/tokens/colors';
import { diagnostics } from '@/utils/diagnostics';

import { useAutoGroupEstateWaterfallMutation } from '../graphql/AutoGroupEstateWaterfall.generated';

export interface AutoGroupConfirmationModalProps {
  isOpen: boolean;
  onClose: () => void;
  loading: boolean;
  onConfirm: () => void;
}

export function AutoGroupConfirmationModal({
  isOpen,
  onClose,
  loading,
  onConfirm,
}: AutoGroupConfirmationModalProps) {
  return (
    <ConfirmationModal
      heading="Automatically group tiles"
      onClose={onClose}
      isOpen={isOpen}
      confirmText="Group tiles"
      confirmButtonProps={{
        loading,
        onClick: onConfirm,
      }}
      dialogContentProps={{
        sx: {
          p: 1,
          pb: 1,
        },
      }}
    >
      <Stack spacing={2}>
        <Typography variant="subtitle2" component="p">
          Tiles will automatically be grouped into the following categories: In
          estate (grantor 1-owned), In estate (grantor 2-owned), In estate
          (jointly-owned), Out of estate (family), and Out of estate (charity).
        </Typography>

        <Typography variant="h6" component="p" color={COLORS.ORANGE[600]}>
          Existing groups and the layout of this waterfall may be modified.
        </Typography>
      </Stack>
    </ConfirmationModal>
  );
}

export function useAutoGroup(waterfallId: string) {
  const { showFeedback } = useFeedback();

  const [autoGroup, mutationProps] = useAutoGroupEstateWaterfallMutation({
    onError: (error) => {
      showFeedback(
        "We weren't able to automatically group this waterfall. Please refresh the page and try again."
      );

      diagnostics.error('Failed to auto group estate waterfall', error);
    },
  });

  const handleAutoGroup = useCallback(() => {
    return autoGroup({
      variables: {
        autoGroupEstateWaterfallId: waterfallId,
      },
    });
  }, [autoGroup, waterfallId]);

  return {
    handleAutoGroup,
    ...mutationProps,
  };
}
