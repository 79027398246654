import { useMemo } from 'react';

import { AssetClassLiquidityStatus } from '@/types/schema';

import { useTenantDetailsContext } from '../TenantDetailsContext';

export function useDefaultAssetClassId(): string {
  const { assetClassesById } = useTenantDetailsContext();
  const defaultAssetClass = useMemo(() => {
    return Object.values(assetClassesById).find((assetClass) => {
      return (
        assetClass.isSystemClass &&
        assetClass.liquidityStatus === AssetClassLiquidityStatus.Liquid
      );
    });
  }, [assetClassesById]);

  if (!defaultAssetClass) {
    throw new Error('missing default asset class');
  }

  return defaultAssetClass.id;
}
