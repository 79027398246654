import { first } from 'lodash';
import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';

import { diagnostics } from '@/utils/diagnostics';

import {
  useAssetProviderDisplayName,
  useIsAssetProviderIntegrationEnabled,
} from './hooks/useEnabledAssetsIntegrations';
import {
  IntegrationClientsTypeahead,
  IntegrationClientsTypeaheadProps,
} from './IntegrationClientTypeahead/IntegrationClientsTypeahead';
import { IntegrationInputCard } from './IntegrationInputCard';

export type IntegrationClientsTypeaheadCardProps<
  FormShape extends FieldValues,
> = Omit<IntegrationClientsTypeaheadProps<FormShape>, 'provider' | 'label'>;

export function IntegrationClientsTypeaheadCard<FormShape extends FieldValues>(
  props: IntegrationClientsTypeaheadCardProps<FormShape>
) {
  const assetProviderName = useAssetProviderDisplayName();
  const { canConnectToIntegrations, integrationsConnected } =
    useIsAssetProviderIntegrationEnabled();
  const enabledIntegration = useMemo(() => {
    if (canConnectToIntegrations.length > 1) {
      diagnostics.warn(
        'Multiple enabled integrations found. This should not happen.'
      );
      return first(canConnectToIntegrations);
    }

    if (canConnectToIntegrations.length === 1) {
      return canConnectToIntegrations[0];
    }

    return null;
  }, [canConnectToIntegrations]);

  if (!enabledIntegration || !assetProviderName) {
    return null;
  }

  const isLoggedIntoIntegration =
    enabledIntegration && integrationsConnected.includes(enabledIntegration);

  return (
    <IntegrationInputCard providerName={assetProviderName}>
      <IntegrationClientsTypeahead
        {...props}
        notLoggedIn={!isLoggedIntoIntegration}
        provider={enabledIntegration}
        label={`${assetProviderName} client`}
        hideLabel
      />
    </IntegrationInputCard>
  );
}
