import { Stack } from '@mui/material';
import { compact } from 'lodash';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { SmallLinkButton } from '@/components/form/baseInputs/Link/SmallLinkButton';
import { LinkTabProps } from '@/components/navigation/NavigationTabs';
import { PageHeader } from '@/components/navigation/PageHeader';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { useCanAccessPage } from '@/navigation/usePageAccessLookup';
import { useRouteKeyMatch } from '@/navigation/useRouteKeyMatch';

import { ClientWealthPageAddButton } from './ClientWealthPageAddButton';

export function ClientWealthPage() {
  const { navigate } = useNavigateToRoute();
  const householdId = useRequiredParam('householdId');
  const growthProfilesEnabled = useFeatureFlag('growth_profiles');

  const tabs: LinkTabProps[] = useMemo(() => {
    return compact([
      {
        display: 'Balance sheet',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_BALANCE_SHEET,
          {
            householdId,
          }
        ),
      },
      {
        display: 'Liabilities',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_MANAGE_LIABILITIES,
          {
            householdId,
          }
        ),
      },
      growthProfilesEnabled && {
        display: 'Growth profiles',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_GROWTH_PROFILES,
          {
            householdId,
          }
        ),
      },
      {
        display: 'Cash flows',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_MANAGE_INCOME_EXPENSES,
          {
            householdId,
          }
        ),
      },
      {
        display: 'Gifting',
        to: getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_DETAILS_GIFTING, {
          householdId,
        }),
      },
    ]);
  }, [growthProfilesEnabled, householdId]);

  const isOnBalanceSheetPage = useRouteKeyMatch(
    ROUTE_KEYS.HOUSEHOLD_DETAILS_BALANCE_SHEET
  );
  const canAccessCategoriesConfigurationPage = useCanAccessPage(
    ROUTE_KEYS.ADMIN_CATEGORIES
  );

  return (
    <Stack>
      <PageHeader
        heading="Wealth"
        tabs={tabs}
        action={<ClientWealthPageAddButton popperVariant="menuBelow" />}
        secondaryAction={
          canAccessCategoriesConfigurationPage &&
          isOnBalanceSheetPage && (
            <SmallLinkButton
              display="Manage categories"
              onClick={() => navigate(ROUTE_KEYS.ADMIN_CATEGORIES, {})}
            />
          )
        }
      />
      <Stack p={3} pb={4} flex={1}>
        <Outlet />
      </Stack>
    </Stack>
  );
}
