import { Box, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import React, { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareButtonGroup } from '@/components/form/formAwareInputs/FormAwareButtonGroup';
import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareHiddenInput } from '@/components/form/formAwareInputs/FormAwareHiddenInput';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Card } from '@/components/layout/Card/Card';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import {
  useCurrentAssetValuation,
  useInitialAssetValuation,
} from '@/modules/assetValuation/useAssetValuation';
import { DocumentUploaderWithList } from '@/modules/documents/DocumentUploaderWithList/DocumentUploaderWithList';
import { AssetValuationV2ValuationReason, DocumentType } from '@/types/schema';
import { getNextDay } from '@/utils/dateUtils';
import { formatCurrency } from '@/utils/formatting/currency';

import {
  AssetDetailsModal,
  AssetDetailsModalProps,
} from '../AssetDetailsModal/AssetDetailsModal';
import { useAssetsFieldArray } from '../AssetDetailsModal/useAssetsFieldArray';
import { AssetFormTable } from '../AssetFormTable/AssetFormTable';
import { NEW_ASSET_ID } from '../types/asset';
import {
  getCalculatedFundingValueFromAssets,
  getSelectedAsset,
  mapQueryAssetsToAssets,
} from '../utils';
import { FundingDateInput } from './FundingDateInput';
import {
  Fields,
  NAMESPACE,
  SubformComponentProps,
  SubformField,
} from './types';

export function AssetsSubformComponent({
  householdId,
  entityId,
  variant,
  disabled,
  forceConfirmValuation,
  dateCopy,
  assetSumLabelCopy,
}: SubformComponentProps) {
  const { control, resetField } = useFormContext<Fields>();
  const isVerifyInitialFunding = variant === 'verifyInitialFunding';
  const isInitialFundingRevaluation = variant === 'initialFundingRevaluation';
  const isEditDesignSummary = variant === 'editDesignSummary';
  const theme = useTheme();
  const assets = useWatch({
    name: `${NAMESPACE}.assets` as const satisfies SubformField,
    control,
  });

  const { removeByAsset, updateByAsset, handleAssetsModalSubmit } =
    useAssetsFieldArray<Fields>(
      `${NAMESPACE}.assets` as const satisfies SubformField,
      control,
      assets
    );

  const [assetModalOpen, setAssetModalOpen] = React.useState<boolean>(false);
  const [createDocumentErrorMessage, setCreateDocumentErrorMessage] =
    React.useState<string | null>(null);

  const onAssetModalSubmit: AssetDetailsModalProps['onSubmit'] = (
    value,
    opts
  ) => {
    handleAssetsModalSubmit(value, opts);
    setAssetModalOpen(false);
  };

  const currentAssetValuation = useCurrentAssetValuation(entityId!);
  const initialAssetValuation = useInitialAssetValuation(entityId!);

  const { formattedAssets, valuationId, accountId } = React.useMemo(() => {
    const mapQueryAssetsOpts = { markAsConfirmed: !forceConfirmValuation };
    if (
      variant === 'initialFundingRevaluation' ||
      variant === 'verifyInitialFunding'
    ) {
      const formattedAssets = mapQueryAssetsToAssets(
        initialAssetValuation?.assetValues ?? [],
        mapQueryAssetsOpts
      );
      return {
        formattedAssets,
        valuationId: initialAssetValuation?.id,
        accountId: initialAssetValuation?.accountId,
      };
    }

    const formattedAssets = mapQueryAssetsToAssets(
      currentAssetValuation?.assetValues ?? [],
      mapQueryAssetsOpts
    );
    return {
      formattedAssets,
      valuationId: currentAssetValuation?.id,
      accountId: currentAssetValuation?.accountId,
    };
  }, [
    currentAssetValuation,
    initialAssetValuation,
    forceConfirmValuation,
    variant,
  ]);

  useEffect(() => {
    if (!isInitialFundingRevaluation && !isVerifyInitialFunding) {
      resetField(`${NAMESPACE}.valuationDate` as const satisfies SubformField, {
        defaultValue: null,
      });
    } else if (isVerifyInitialFunding) {
      resetField(`${NAMESPACE}.fundingDate` as const satisfies SubformField, {
        defaultValue: null,
      });
    }
  }, [resetField, isVerifyInitialFunding, isInitialFundingRevaluation]);

  useEffect(() => {
    if (assets.length) {
      return;
    }

    resetField(`${NAMESPACE}.assets` as const, {
      defaultValue: formattedAssets,
    });
    resetField(`${NAMESPACE}.valuationId` as const, {
      defaultValue: valuationId,
    });
    resetField(`${NAMESPACE}.accountId` as const, {
      defaultValue: accountId,
    });
  }, [formattedAssets, valuationId, resetField, assets, accountId]);

  const entityEffectiveDate: Date = useMemo(() => {
    return (
      initialAssetValuation?.effectiveDate ?? new Date(new Date().toISOString())
    );
  }, [initialAssetValuation]);

  return (
    <>
      <AssetDetailsModal
        onSubmit={onAssetModalSubmit}
        asset={getSelectedAsset([], NEW_ASSET_ID)}
        isOpen={assetModalOpen}
        isNewAsset={true}
        onClose={() => setAssetModalOpen(false)}
      />
      <Stack spacing={3}>
        <Box display="flex">
          {isInitialFundingRevaluation && (
            <Callout severity="warning">
              Annuity payment schedules will automatically be recalculated based
              on the new initial funding amounts. The taxable gift from funding
              the GRAT has not been changed. In order to edit the taxable gift
              amount, please edit the GRAT details.
            </Callout>
          )}
        </Box>
        <Box>
          <AssetFormTable
            assetList={assets}
            removeByAsset={removeByAsset}
            updateByAsset={updateByAsset}
            loading={!initialAssetValuation && !currentAssetValuation}
            disabled={disabled}
            updateAssetButtonText={
              variant === 'verifyInitialFunding' ? 'Review' : undefined
            }
            isInitialValuation={
              variant === 'verifyInitialFunding' ||
              variant === 'initialFundingRevaluation'
            }
          />

          <Button
            fullWidth
            whiteSpace="nowrap"
            sx={{
              marginTop: 3,
            }}
            startIcon={PlusIcon}
            size="sm"
            variant="secondary"
            disabled={disabled}
            onClick={() => setAssetModalOpen(!assetModalOpen)}
          >
            Add additional asset
          </Button>
        </Box>
        <Card
          variant="filled"
          sx={{
            p: 3,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap={3}
          >
            {!isInitialFundingRevaluation &&
              !isVerifyInitialFunding &&
              !isEditDesignSummary && (
                <Stack direction="row" spacing={3}>
                  <Box width={320}>
                    <FormAwareButtonGroup<Fields>
                      control={control}
                      fieldName={
                        `${NAMESPACE}.valuationReason` as const satisfies SubformField
                      }
                      variant="light"
                      required
                      label="Valuation type"
                      options={[
                        {
                          display: 'Revaluation',
                          value:
                            AssetValuationV2ValuationReason.AssetRevaluation,
                        },
                        {
                          display: 'Asset substitution',
                          value:
                            AssetValuationV2ValuationReason.GratAssetSubstitution,
                        },
                      ]}
                    />
                  </Box>
                  <FundingDateInput
                    control={control}
                    label={dateCopy || 'Valuation date'}
                    disabled={disabled}
                    fieldName={
                      `${NAMESPACE}.valuationDate` as const satisfies SubformField
                    }
                    minDate={getNextDay(entityEffectiveDate)}
                  />
                </Stack>
              )}
            {isVerifyInitialFunding && (
              <Stack>
                <FormAwareDatePickerInput<Fields>
                  disabled={disabled}
                  control={control}
                  required
                  label={dateCopy || 'Initial funding date'}
                  fieldName={
                    `${NAMESPACE}.fundingDate` as const satisfies SubformField
                  }
                />
              </Stack>
            )}

            {isInitialFundingRevaluation && (
              <Stack>
                <Stack direction="row">
                  <FormAwareCheckbox<Fields>
                    control={control}
                    disabled={disabled}
                    required
                    fieldName={
                      `${NAMESPACE}.confirm` as const satisfies SubformField
                    }
                  />
                  <Typography variant="label" maxWidth={theme.spacing(20)}>
                    I confirm that I wish to revise the initial funding value
                  </Typography>
                </Stack>
              </Stack>
            )}
            {/* These hidden inputs are used to allow this form to communicate IDs required in updates to the consuming forms */}
            <FormAwareHiddenInput<Fields>
              control={control}
              fieldName={
                `${NAMESPACE}.valuationId` as const satisfies SubformField
              }
            />
            <FormAwareHiddenInput<Fields>
              control={control}
              fieldName={
                `${NAMESPACE}.accountId` as const satisfies SubformField
              }
            />
            <Stack>
              <Typography variant="subtitle2">{assetSumLabelCopy}</Typography>
              <Typography variant="h1" component="p">
                {formatCurrency(
                  getCalculatedFundingValueFromAssets(assets) ?? new Decimal(0)
                )}
              </Typography>
            </Stack>
          </Stack>
        </Card>
        {!isEditDesignSummary && (
          <Stack pt={2} alignItems="center">
            <Box width="80%">
              <DocumentUploaderWithList
                uploaderLabel="Upload supporting documentation (optional)"
                listDocumentsLike={{
                  hasEntityWith: [{ id: entityId! }],
                  type: DocumentType.AssetValuation,
                }}
                errorMessage={createDocumentErrorMessage || undefined}
                getCreateDocumentInput={(file) => ({
                  householdID: householdId,
                  name: file.fileName,
                  entityID: entityId,
                  type: DocumentType.AssetValuation,
                  fileID: file.fileId,
                })}
                onDocumentUploadSuccess={() =>
                  setCreateDocumentErrorMessage(null)
                }
                onDocumentUploadError={() =>
                  setCreateDocumentErrorMessage(
                    `We weren't able to complete your document upload process. Please try again.`
                  )
                }
                householdId={householdId}
              />
            </Box>
          </Stack>
        )}
      </Stack>
    </>
  );
}
