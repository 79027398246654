import Decimal from 'decimal.js';
import { compact } from 'lodash';

import {
  DispositiveProvisionDispositionKind,
  SimulateDispositiveProvisionInput,
  SimulateDispositiveProvisionsInput,
} from '@/types/schema';

import { DispositionScheme } from '../../dispositiveProvisions.types';
import { Recipient } from '../DispositiveProvisionsForm.types';
import { $getApiDispositionKind } from '../DispositiveProvisionsForm.utils';

function getSimulateDispositiveProvisionInputFromRecipient(
  r: Recipient
): SimulateDispositiveProvisionInput | null {
  if (!r.recipientId) {
    return null;
  }

  let kind: DispositiveProvisionDispositionKind;

  try {
    kind = $getApiDispositionKind(r);
  } catch (e) {
    return null;
  }

  if (
    kind === DispositiveProvisionDispositionKind.Amount &&
    !r.dispositionAmount
  ) {
    return null;
  }

  if (
    kind === DispositiveProvisionDispositionKind.Percentage &&
    !r.dispositionPercentage
  ) {
    return null;
  }

  return {
    dispositionAmount: r.dispositionAmount,
    dispositionKind: kind,
    dispositionPercentage: r.dispositionPercentage,
  };
}

interface GetSimulateDispositiveProvisionsInputInput {
  recipients: Recipient[];
  dispositionScheme: DispositionScheme;
  firstGrantorId?: string;
  secondGrantorId?: string;
  entityTotalMarketValue?: Decimal;
}

export function getSimulateDispositiveProvisionsInput({
  recipients,
  dispositionScheme,
  firstGrantorId,
  secondGrantorId,
  entityTotalMarketValue,
}: GetSimulateDispositiveProvisionsInputInput): SimulateDispositiveProvisionsInput | null {
  if (!firstGrantorId || !entityTotalMarketValue) {
    return null;
  }

  const firstDeathRecipients =
    dispositionScheme === DispositionScheme.UPON_FIRST_DEATH ? recipients : [];
  const secondDeathRecipients =
    dispositionScheme === DispositionScheme.UPON_SECOND_DEATH ? recipients : [];

  return {
    firstDeathClientProfile: firstGrantorId,
    firstDeathProvisions: compact(
      [...firstDeathRecipients].map((r) =>
        getSimulateDispositiveProvisionInputFromRecipient(r)
      ) ?? []
    ),
    secondDeathClientProfile: secondGrantorId || undefined,
    secondDeathProvisions: compact(
      [...secondDeathRecipients].map((r) =>
        getSimulateDispositiveProvisionInputFromRecipient(r)
      ) ?? []
    ),
    valueToDistribute: entityTotalMarketValue,
  };
}
