// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemapAndDeleteCategoryMutationVariables = Types.Exact<{
  categoryToDeleteID: Types.Scalars['ID']['input'];
  categoryToRemapID: Types.Scalars['ID']['input'];
}>;


export type RemapAndDeleteCategoryMutation = { __typename?: 'Mutation', updateAssetsToNewClass: number, deleteAssetClass: string };


export const RemapAndDeleteCategoryDocument = gql`
    mutation RemapAndDeleteCategory($categoryToDeleteID: ID!, $categoryToRemapID: ID!) {
  updateAssetsToNewClass(
    newCategoryID: $categoryToRemapID
    previousCategoryID: $categoryToDeleteID
  )
  deleteAssetClass(id: $categoryToDeleteID)
}
    `;
export type RemapAndDeleteCategoryMutationFn = Apollo.MutationFunction<RemapAndDeleteCategoryMutation, RemapAndDeleteCategoryMutationVariables>;

/**
 * __useRemapAndDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useRemapAndDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemapAndDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remapAndDeleteCategoryMutation, { data, loading, error }] = useRemapAndDeleteCategoryMutation({
 *   variables: {
 *      categoryToDeleteID: // value for 'categoryToDeleteID'
 *      categoryToRemapID: // value for 'categoryToRemapID'
 *   },
 * });
 */
export function useRemapAndDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemapAndDeleteCategoryMutation, RemapAndDeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemapAndDeleteCategoryMutation, RemapAndDeleteCategoryMutationVariables>(RemapAndDeleteCategoryDocument, options);
      }
export type RemapAndDeleteCategoryMutationHookResult = ReturnType<typeof useRemapAndDeleteCategoryMutation>;
export type RemapAndDeleteCategoryMutationResult = Apollo.MutationResult<RemapAndDeleteCategoryMutation>;
export type RemapAndDeleteCategoryMutationOptions = Apollo.BaseMutationOptions<RemapAndDeleteCategoryMutation, RemapAndDeleteCategoryMutationVariables>;
export const Operations = {
  Mutation: {
    RemapAndDeleteCategory: 'RemapAndDeleteCategory' as const
  }
}