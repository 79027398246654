// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BootstrapWaterfallMutationVariables = Types.Exact<{
  isComplex: Types.Scalars['Boolean']['input'];
  isSimple: Types.Scalars['Boolean']['input'];
}>;


export type BootstrapWaterfallMutation = { __typename?: 'Mutation', seedEstateWaterfallSimpleTrustWithBequests?: { __typename?: 'Household', id: string, estateWaterfalls?: Array<{ __typename?: 'EstateWaterfall', id: string }> | null }, seedEstateWaterfall?: { __typename?: 'Household', id: string, estateWaterfalls?: Array<{ __typename?: 'EstateWaterfall', id: string }> | null } };


export const BootstrapWaterfallDocument = gql`
    mutation BootstrapWaterfall($isComplex: Boolean!, $isSimple: Boolean!) {
  seedEstateWaterfallSimpleTrustWithBequests @include(if: $isComplex) {
    id
    estateWaterfalls {
      id
    }
  }
  seedEstateWaterfall @include(if: $isSimple) {
    id
    estateWaterfalls {
      id
    }
  }
}
    `;
export type BootstrapWaterfallMutationFn = Apollo.MutationFunction<BootstrapWaterfallMutation, BootstrapWaterfallMutationVariables>;

/**
 * __useBootstrapWaterfallMutation__
 *
 * To run a mutation, you first call `useBootstrapWaterfallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBootstrapWaterfallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bootstrapWaterfallMutation, { data, loading, error }] = useBootstrapWaterfallMutation({
 *   variables: {
 *      isComplex: // value for 'isComplex'
 *      isSimple: // value for 'isSimple'
 *   },
 * });
 */
export function useBootstrapWaterfallMutation(baseOptions?: Apollo.MutationHookOptions<BootstrapWaterfallMutation, BootstrapWaterfallMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BootstrapWaterfallMutation, BootstrapWaterfallMutationVariables>(BootstrapWaterfallDocument, options);
      }
export type BootstrapWaterfallMutationHookResult = ReturnType<typeof useBootstrapWaterfallMutation>;
export type BootstrapWaterfallMutationResult = Apollo.MutationResult<BootstrapWaterfallMutation>;
export type BootstrapWaterfallMutationOptions = Apollo.BaseMutationOptions<BootstrapWaterfallMutation, BootstrapWaterfallMutationVariables>;
export const Operations = {
  Mutation: {
    BootstrapWaterfall: 'BootstrapWaterfall' as const
  }
}