import Decimal from 'decimal.js';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { getComparableDecimalJS } from '@/utils/decimalJSUtils';

import {
  AnnuityFormGratTrustFragment,
  GetAnnuitiesDetailsQuery,
  GetAnnuitiesDetailsQueryVariables,
  useGetAnnuitiesDetailsLazyQuery,
  useGetNpvLazyQuery,
} from '../graphql/AnnuitiesDetails.generated';

export function deriveQueryVariables(
  gratTrust: AnnuityFormGratTrustFragment,
  termDurationYears?: number,
  taxableGift?: Decimal | null
): GetAnnuitiesDetailsQueryVariables {
  let gratTerm = gratTrust.termDurationYears ?? null;
  let targetGift = gratTrust.targetTaxableGift ?? null;
  if (termDurationYears) {
    gratTerm = termDurationYears;
  }
  if (taxableGift) {
    targetGift = taxableGift;
  }

  // if the target gift should not be larger than the initial funding value
  // so we will set the target gift to the initial funding value
  // and prevent the user from saving the GRAT
  if (
    targetGift?.greaterThan(
      gratTrust.initialFundingValue ?? new Decimal(Infinity)
    )
  ) {
    targetGift = gratTrust.initialFundingValue ?? new Decimal(0);
  }

  if (gratTerm === null) {
    throw new Error('Cannot derive GRAT term to query annuities details');
  }

  return {
    projectionParams: {
      initialFundingValue: gratTrust?.initialFundingValue ?? new Decimal(0),
      termDurationYears: gratTerm,
      officialInterestRate:
        gratTrust?.officialInterestRatePercent ?? new Decimal(0),
      annualAnnuityIncrease:
        gratTrust?.annuityAnnualIncreasePercent ?? new Decimal(0),
      projectedRateOfReturn:
        gratTrust?.officialInterestRatePercent ?? new Decimal(0),
      targetGift: targetGift ?? new Decimal(0),
    },
    annuitiesLike: {
      hasGratTrustWith: [{ id: gratTrust.id }],
      termDurationYears: gratTerm,
    },
  };
}

export function useQueryAnnuitiesDetails(
  gratTrust?: AnnuityFormGratTrustFragment | null,
  termDurationYears?: number,
  taxableGift?: Decimal | null
) {
  // using two separate states to explicitly track initial and reactive annuities data
  const [initialAnnuitiesData, setInitialAnnuitiesData] =
    useState<GetAnnuitiesDetailsQuery | null>(null);

  // updates on every term duration change
  // or other dependency changes
  const [reactiveAnnuitiesData, setReactiveAnnuitiesData] =
    useState<GetAnnuitiesDetailsQuery | null>(null);

  const [queryAnnuitiesDetails, { data, error, loading }] =
    useGetAnnuitiesDetailsLazyQuery();

  const comparableTaxableGift = getComparableDecimalJS(
    taxableGift ?? new Decimal(0)
  );

  // query variables
  const variables = useMemo(() => {
    if (!gratTrust) {
      return null;
    }

    return deriveQueryVariables(gratTrust, termDurationYears, taxableGift);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gratTrust, termDurationYears, comparableTaxableGift]);

  useEffect(
    function reactiveFetch() {
      // if the term duration has changed, we need to re-fetch the annuities data
      if (initialAnnuitiesData && variables !== null) {
        void queryAnnuitiesDetails({
          variables,
        });
      }
    },
    [initialAnnuitiesData, queryAnnuitiesDetails, variables]
  );

  useEffect(
    function initialFetch() {
      // if we don't have annuities data yet, we need to fetch it
      // and apply the saved annuities data or the projection data
      if (!initialAnnuitiesData && variables !== null) {
        void queryAnnuitiesDetails({
          variables,
        });
      }
    },
    [queryAnnuitiesDetails, initialAnnuitiesData, variables]
  );

  useEffect(
    function setData() {
      if (data) {
        if (initialAnnuitiesData) {
          // if we already have annuities data, we need to update the reactive annuities data
          setReactiveAnnuitiesData(data);
        } else {
          setInitialAnnuitiesData(data);
        }
      }
    },
    [
      data,
      initialAnnuitiesData,
      setInitialAnnuitiesData,
      setReactiveAnnuitiesData,
    ]
  );

  useReportError(
    'failed to fetch annuities details for annuity subform',
    error
  );

  return {
    annuitiesData: initialAnnuitiesData,
    reactiveAnnuitiesData,
    error,
    loading,
  };
}

export function useGetNPVDetails(
  interestRate: Decimal | null,
  annuitiesPaymentAmounts: Decimal[] | null
) {
  const { createErrorFeedback } = useFeedback();
  const [getNPVDetails, { error, loading }] = useGetNpvLazyQuery();
  const [npv, setNpv] = useState<Decimal | null>(null);

  // this is gross, but is required because react-hook-forms watch and decimal.js don't play
  // particularly well together, since they both rely on in-place modifications and useEffect's
  // dependency array is a shallow comparison. this is a workaround to ensure that the NPV call
  // only actually triggers when the underlying values changes. sorry!
  const [lastComparedDecimals, setLastComparedDecimals] = useState<
    string | null
  >(null);
  const comparableAnnuityPayments = JSON.stringify(annuitiesPaymentAmounts);

  useEffect(() => {
    async function fetchNPV() {
      if (!interestRate || !annuitiesPaymentAmounts) {
        return;
      }

      if (
        lastComparedDecimals === null &&
        _.isEmpty(comparableAnnuityPayments)
      ) {
        // pre-first loop; no data, so not ready to run the query yet
        return;
      } else if (lastComparedDecimals === null) {
        // first loop; this should set the comparator and run the query
        setLastComparedDecimals(comparableAnnuityPayments);
      } else {
        // subsequent loop; this should manually compare the decimals
        if (_.isEqual(lastComparedDecimals, comparableAnnuityPayments)) {
          // same as previous query; shouldn't rerun
          return;
        } else {
          // update the lastCompared cache and run the query
          setLastComparedDecimals(comparableAnnuityPayments);
        }
      }

      const { data } = await getNPVDetails({
        variables: {
          interestRate,
          cashFlows: annuitiesPaymentAmounts,
        },
        onError: createErrorFeedback(
          'Failed to compute the grantor-retained interest.'
        ),
      });

      setNpv(data?.npv ?? null);
    }

    void fetchNPV();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    lastComparedDecimals,
    comparableAnnuityPayments,
    getComparableDecimalJS(interestRate),
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useReportError('failed to fetch npv details for annuity subform', error);

  return {
    npv,
    error,
    loading,
  };
}
