import {
  array,
  boolean as booleanType,
  Describe,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreateInsurancePersonalAccountInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateInsurancePersonalAccountInput,
  UpdateInsurancePersonalAccountInput,
} from '@/types/schema';

import {
  createInsurancePolicyInput,
  updateInsurancePolicyInput,
} from './insuranceTypes';
import {
  commonCreatePersonalAccountProperties,
  commonUpdatePersonalAccountProperties,
} from './personalAccountTypes';
import {
  commonUpdateEntityProperties,
  createAccountSchema,
  createEntityInput,
  grantorSchema,
  requiredInEstateStatusSchema,
} from './types';
import { $getValidatedInput } from './utils';

export const createInsuranceAccountSchema: Describe<AugmentedCreateInsurancePersonalAccountInput> =
  object({
    create: object({
      ...commonCreatePersonalAccountProperties,
      inEstateStatus: optional(nullable(requiredInEstateStatusSchema)),
    }),
    withDesignerAccount: optional(nullable(createAccountSchema)),
    withOwners: optional(nullable(array(grantorSchema))),
  });

const updateInsurancePersonalAccountSchema: Describe<UpdateInsurancePersonalAccountInput> =
  object({
    ...commonUpdatePersonalAccountProperties,
    clearOwners: optional(nullable(booleanType())),
    removePolicyIDs: optional(nullable(array(string()))),
  });

const updateInsuranceAccountSchema: Describe<AugmentedUpdateInsurancePersonalAccountInput> =
  object({
    id: string(),
    update: updateInsurancePersonalAccountSchema,
    clearPolicies: optional(nullable(booleanType())),
    withPolicies: optional(nullable(array(createInsurancePolicyInput))),
    updatePolicies: optional(nullable(array(updateInsurancePolicyInput))),
    withOwners: optional(nullable(array(grantorSchema))),
  });

export const createSchema: Describe<AugmentedCreateEntityInput> = object({
  create: createEntityInput,
  withInsurancePersonalAccount: optional(
    nullable(createInsuranceAccountSchema)
  ),
});

export const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object(commonUpdateEntityProperties),
  updateInsurancePersonalAccount: optional(
    nullable(updateInsuranceAccountSchema)
  ),
});

export function validateInsuranceAccountCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'Insurance account create');
}

export function validateInsuranceAccountUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'Insurance account update');
}
