import { Box, Stack, styled, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { compact, isEmpty, keyBy, uniqBy } from 'lodash';
import * as React from 'react';
import { useMemo } from 'react';

import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { Loader } from '@/components/progress/Loader/Loader';
import { COLORS } from '@/styles/tokens/colors';
import { truncateToLength } from '@/utils/stringUtils';

import { Chip } from '../../Chip/Chip';
import { Checkbox } from '../Checkbox';
import { FormControl } from '../FormControl';
import {
  BaseTypeaheadMultiSelectInputProps,
  FormControlTypeaheadMultiSelectInputProps,
  HelpTextVariant,
  SelectInputOption,
  TypeaheadSelectInputOption,
} from '../inputTypes';
import { SelectItemGroupLabel } from '../SelectInput/SelectItemGroupLabel';

const GroupItems = styled('ul')({
  padding: 0,
});

const MAX_TAG_DISPLAY_LENGTH = 20;

function getTagDisplay(tagValues: TypeaheadSelectInputOption<unknown>[]) {
  const [firstTagValue, ...otherTagValues] = tagValues;
  const firstTagDisplay = truncateToLength(
    firstTagValue?.display ?? '',
    MAX_TAG_DISPLAY_LENGTH
  );

  const otherTagsSummary = otherTagValues.length
    ? `+${otherTagValues.length} more`
    : '';

  return compact([firstTagDisplay, otherTagsSummary]).join(' ');
}

const BaseTypeaheadMultiSelectInput = ({
  options,
  testId,
  required,
  inputRef,
  noOptionsText,
  inputValue,
  onInputChange,
  endAdornment,
  values,
  ...inputProps // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: BaseTypeaheadMultiSelectInputProps<any>) => {
  const { disabled } = inputProps;
  // explicitly splitting off error and autocomplete so we don't pass them to
  // the top-level Autocomplete element, which doesn't accept them
  const { autoComplete, error, ...otherInputProps } = inputProps;
  const disabledStyle = disabled ? { background: COLORS.GRAY[50] } : {};
  const mergedSx = Object.assign({ background: 'white', m: 0 }, disabledStyle);

  return (
    // this select is a non-optimal experience for mobile; consider using NativeSelect here for a mobile device
    <Autocomplete<TypeaheadSelectInputOption<unknown>, true, boolean>
      multiple
      autoHighlight
      openOnFocus
      disableClearable
      disableCloseOnSelect
      value={values ?? []}
      {...otherInputProps}
      onChange={otherInputProps.onChange}
      options={options}
      inputValue={inputValue}
      onInputChange={(_, value, reason) => onInputChange?.(value, reason)}
      onBlur={() => onInputChange?.('', 'clear')} // for some reason, clearOnBlur doesn't actually fire a clear event
      noOptionsText={noOptionsText ?? 'No options'}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderOption={(props, option, state) => {
        return (
          <li {...props}>
            <Checkbox
              value={state.selected}
              label={
                <Stack
                  alignItems="center"
                  width="100%"
                  direction="row"
                  flexGrow={1}
                  justifyContent="space-between"
                  spacing={0.5}
                >
                  <Box flex={1}>{option.display}</Box>
                  <Stack spacing={2} direction="row">
                    {option.endLabel && (
                      <Typography variant="caption">
                        {option.endLabel}
                      </Typography>
                    )}
                    {option.badgeText && (
                      <Badge
                        variant={BadgeVariants.Gray}
                        display={option.badgeText}
                      />
                    )}
                  </Stack>
                </Stack>
              }
            />
          </li>
        );
      }}
      renderGroup={(params) => (
        <li key={params.key}>
          {params.group && (
            <SelectItemGroupLabel sx={{ top: '-20px' }} label={params.group} />
          )}
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderTags={(tagValues, getTagProps) => {
        if (tagValues.length === 1) {
          const option = tagValues[0];
          if (!option) return null;

          const props = getTagProps({ index: 0 });
          return (
            <Chip
              label={option.display}
              {...props}
              disabled={option.disabled || disabled}
              onRemove={props.onDelete}
              data-testid="TypeaheadMultiSelect-tag"
            />
          );
        }

        const tagDisplayValue = getTagDisplay(tagValues);

        return (
          <Chip
            label={tagDisplayValue}
            {...getTagProps({ index: 0 })}
            disabled={tagValues.some((option) => option.disabled) || disabled}
            onRemove={(e) => otherInputProps.onChange?.(e, [], 'clear')}
          />
        );
      }}
      // this method name is misleading. it actually passes the whole option when the
      // selection popover is opened, and just the value when the popover is closed.
      // see: https://github.com/mui/material-ui/issues/31192
      getOptionLabel={(valueOrOption) => valueOrOption.display}
      getOptionDisabled={(option) => !!option.disabled}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputRef={inputRef}
            variant="outlined"
            error={error}
            placeholder={isEmpty(values) ? inputProps.placeholder : ''}
            InputProps={{
              ...params.InputProps,
              endAdornment,
              sx: mergedSx,
              inputProps: {
                ...params.inputProps,
                required,
                autoComplete,
                'data-testid':
                  testId ?? `select-input-${inputProps.name ?? ''}`,
              },
            }}
          />
        );
      }}
    />
  );
};

export interface TypeaheadMultiSelectInputProps
  extends FormControlTypeaheadMultiSelectInputProps<string> {
  label: string;
  // the presence of contextualHelp indicates that there's help text related to this input, which
  // will cause the "info" icon to show up next to the input label
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  hideLabel?: boolean;
  autoSelect?: boolean;
  loading?: boolean;
}

export function TypeaheadMultiSelectInput({
  id,
  label,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant,
  hideLabel,
  loading,
  options,
  ...inputProps
}: TypeaheadMultiSelectInputProps) {
  const { values: selectedValues } = inputProps;

  const finalOptions = useMemo(() => {
    const optionsByValue = keyBy(options, 'value');

    // group all the selected options at the top under the label "Selected"
    const selectedOptions =
      selectedValues?.flatMap((selectedValue) => {
        const option = optionsByValue[selectedValue.value];
        return option
          ? {
              ...option,
              groupName: 'Selected',
            }
          : [];
      }) ?? [];

    // we want to make sure to always include both the current query options *and* the option that corresponds
    // to the current value, but don't include the same option more than once
    // this will be a no-op if selectedOptions is empty
    return uniqBy<SelectInputOption<string>>(
      compact([
        // put the currently-selected options first in the list
        ...selectedOptions,
        ...options,
      ]),
      'value'
    );
  }, [options, selectedValues]);

  const inputValue = useMemo(() => {
    return inputProps.inputValue ?? '';
  }, [inputProps.inputValue]);

  return (
    <FormControl<BaseTypeaheadMultiSelectInputProps<string>>
      id={id}
      hideLabel={hideLabel}
      contextualHelp={contextualHelp}
      component={BaseTypeaheadMultiSelectInput}
      label={label}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
      required={inputProps.required}
      errorMessage={errorMessage}
      inputProps={{
        ...inputProps,
        values: selectedValues,
        groupBy: (option) => option.groupName ?? '',
        inputValue,
        options: finalOptions,
        noOptionsText: loading ? 'Loading...' : undefined,
        endAdornment: loading ? (
          <Loader
            boxProps={{
              alignItems: 'center',
              position: 'absolute',
              right: 10,
              top: 13,
            }}
            circularProgressProps={{ size: 15 }}
          />
        ) : undefined,
      }}
    />
  );
}
