import Decimal from 'decimal.js';

import { TileSummary } from '@/components/diagrams/components/Tile/TileSummary';
import { TileSummaryItem } from '@/components/diagrams/components/Tile/TileSummaryItem';
import { sumDecimalJS } from '@/utils/decimalJSUtils';

import { useWaterfallSummaryContext } from '../../contexts/waterfallSummary.context';
import { GetWaterfallSummary_EstateWaterfallFragment } from '../../graphql/GetWaterfallSummary.generated';
import { useShowSummaryTileDetails } from '../../hooks/useShowSummaryTileDetails.hook';
import { WaterfallBarChartSection } from '../EstateWaterfallSummaryBarCharts/hooks/useWaterfallSummaryBarChartSections';
import { TileSection } from './components/TileSection';
import {
  formatCompactValue,
  getSummaryTileNode,
} from './EstateWaterfallSummaryTiles.utils';
import { useShowMoreSplit } from './hooks/useShowMoreSplit.hook';

export interface CurrentSummarySectionProps {
  waterfall: GetWaterfallSummary_EstateWaterfallFragment;
  section: WaterfallBarChartSection;
  showDetails?: boolean;
}

export const CurrentSummarySection = ({
  waterfall,
  section,
}: CurrentSummarySectionProps) => {
  const householdId = waterfall.household?.id as unknown as string;
  const showSummaryTileDetails = useShowSummaryTileDetails(waterfall.id);
  const currentSectionValue = sumDecimalJS(
    section.waterfallSections.map((v) => {
      return new Decimal(v.value);
    })
  );

  const firstDeathGrantorName =
    waterfall.household?.possiblePrimaryClients.find(
      (c) => c.id === waterfall.firstGrantorDeath.id
    )?.firstName;

  const { visible, remainingLabel, remaining, remainingSum } = useShowMoreSplit(
    section.waterfallSections
  );

  const {
    summaryPanel: { openModal },
  } = useWaterfallSummaryContext();

  const details = (
    <>
      {visible.map(({ kind, value }) => {
        return (
          <TileSummaryItem
            key={kind}
            label={kind}
            value={formatCompactValue(value)}
          />
        );
      })}
      {!!remaining.length && (
        <TileSummaryItem
          label={remainingLabel}
          value={formatCompactValue(remainingSum)}
        />
      )}
    </>
  );

  return (
    <TileSection label={section.label}>
      <TileSummary
        lineOne="Projected net worth"
        lineThree={formatCompactValue(currentSectionValue)}
        onClick={() =>
          openModal({
            type: 'summaryTileDetails',
            nodes: section.waterfallSections.flatMap((section) => {
              return section.nodes.map(getSummaryTileNode);
            }),
            subtitle: `Combined value of all in and out of estate assets as of ${firstDeathGrantorName}'s death in ${waterfall.firstGrantorDeathYear}`,
            title: 'Projected net worth',
            total: currentSectionValue,
            householdId,
            firstPrimaryClientDeathId: waterfall.firstGrantorDeath.id,
            waterfallId: waterfall.id,
          })
        }
      >
        {showSummaryTileDetails ? details : null}
      </TileSummary>
    </TileSection>
  );
};
