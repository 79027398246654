import { Loader } from '@/components/progress/Loader/Loader';

/**
 * @description Pass LoadingAdornment as a startAdornment to a SelectInput
 * to display a loading spinner in the select input.
 */
export function LoadingAdornment() {
  return (
    <Loader
      boxProps={{
        alignItems: 'center',
        position: 'absolute',
        left: 8,
        top: 13,
      }}
      circularProgressProps={{ size: 15 }}
    />
  );
}
