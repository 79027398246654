import { Box, Stack, Typography } from '@mui/material';
import { difference } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { Dataflow01Icon } from '@/components/icons/Dataflow01Icon';
import { Card } from '@/components/layout/Card/Card';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES } from '@/modules/assetProviderIntegrations/shared/constants';
import { SharedIntegrationEntityTypeahead } from '@/modules/assetProviderIntegrations/shared/SharedIntegrationEntityTypeahead';
import { getIntegrationEntitiesFromEntity } from '@/modules/assetProviderIntegrations/shared/utils';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { UpdateEntityInput } from '@/types/schema';
import { PathsOf } from '@/types/subform';
import * as diagnostics from '@/utils/diagnostics';

import { GetImplementationTasksV2Document } from '../../graphql/ImplementationTasks.generated';
import { TaskHeaderCard } from '../../TaskHeaderCard';
import { TaskBodyProps } from '../../types';
import {
  CreateTrustAccountTaskBody_EntityFragment,
  useCompleteTaskAndUpdateEntityMutation,
  useGetEntityDetailsForTrustAccountQuery,
} from './graphql/CreateTrustAccountTaskBody.generated';

interface CreateTrustAccountFormShape {
  integrationEntityIds: string[];
  linkToAllAddeparEntities: boolean;
}

type CreateTrustAccountFormShapePaths = PathsOf<CreateTrustAccountFormShape>;

const formDefaultValues = {
  integrationEntityIds: [],
  linkToAllAddeparEntities: false,
};

export function CreateTrustAccountTaskBody({
  task,
  footer,
  onCompleteTask,
}: TaskBodyProps) {
  const { isHouseholdLinkedToAssetProvider, householdIntegrationLink } =
    useHouseholdDetailsContext();
  const { householdId } = useHouseholdDetailsContext();
  const { showFeedback } = useFeedback();
  const formMethods = useForm<CreateTrustAccountFormShape>({
    defaultValues: formDefaultValues,
  });

  const { handleSubmit, reset, control } = formMethods;

  // mutations
  const [completeTaskAndUpdateEntityMutation, { loading }] =
    useCompleteTaskAndUpdateEntityMutation();

  // queries
  const { data } = useGetEntityDetailsForTrustAccountQuery({
    variables: {
      nodeId: task.entityId,
    },
  });

  // sync the query data to the form
  useEffect(() => {
    if (data?.node?.__typename === 'Entity') {
      const integrationEntities = getIntegrationEntitiesFromEntity(data.node);
      const integrationEntityIds = integrationEntities.map(({ id }) => id);
      reset({
        integrationEntityIds,
        linkToAllAddeparEntities:
          data.node.addeparLinkedToNongrantorEntity ?? false,
      });
    }
  }, [data, reset]);

  // handlers
  const handleCompleteTask: SubmitHandler<CreateTrustAccountFormShape> = (
    formValues
  ) => {
    if (!data) {
      throw new Error('No entity data found');
    }

    const previousIntegrationEntityIds = getIntegrationEntitiesFromEntity(
      data.node as CreateTrustAccountTaskBody_EntityFragment
    ).map(({ id }) => id);
    const nextIntegrationEntityIds = formValues.integrationEntityIds;
    const addedIntegrationEntityIds = difference(
      nextIntegrationEntityIds,
      previousIntegrationEntityIds
    );
    const removedIntegrationEntityIds = difference(
      previousIntegrationEntityIds,
      nextIntegrationEntityIds
    );

    const update: UpdateEntityInput = formValues.integrationEntityIds
      ? {
          addIntegrationEntityIDs: addedIntegrationEntityIds,
          removeIntegrationEntityIDs: removedIntegrationEntityIds,
          addeparLinkedToNongrantorEntity: formValues.linkToAllAddeparEntities,
        }
      : {
          // we need to remove instead of clear to ensure that we're not accidentally clearing other integrationEntities,
          // like csv import connections
          removeIntegrationEntityIDs: removedIntegrationEntityIds,
          addeparLinkedToNongrantorEntity: false,
        };

    return completeTaskAndUpdateEntityMutation({
      variables: {
        taskID: task.id,
        entityInput: {
          id: task.entityId,
          update: update,
        },
      },
      refetchQueries: [GetImplementationTasksV2Document],
      onCompleted: () => onCompleteTask(),
      onError: (error) => {
        diagnostics.error('Failed to save CreateTrust changes', error);
        showFeedback('We failed to save your data. Please try again.');
      },
    });
  };

  if (!data || data.node?.__typename !== 'Entity') {
    return null;
  }

  return (
    <FormProvider {...formMethods}>
      <TaskHeaderCard task={task} footer={footer}>
        <Stack
          spacing={3}
          component="form"
          onSubmit={handleSubmit(handleCompleteTask)}
        >
          {isHouseholdLinkedToAssetProvider && (
            <SharedIntegrationEntityTypeahead
              filterToHouseholdId={householdId ?? ''}
              control={control}
              fieldName={
                `integrationEntityIds` as const satisfies CreateTrustAccountFormShapePaths
              }
              includeAllEntitiesFieldName={
                `linkToAllAddeparEntities` as const satisfies CreateTrustAccountFormShapePaths
              }
            />
          )}
          {!isHouseholdLinkedToAssetProvider && (
            <Card variant="outlined" sx={{ p: 2, display: 'flex', gap: 2 }}>
              <Box display="flex" alignSelf="center">
                <Dataflow01Icon size={24} />
              </Box>
              <Stack>
                <Typography variant="h5">
                  {data.node.gratTrust?.displayName ?? ''}
                </Typography>
                <Typography variant="subtitle2">
                  {data.node.gratTrust?.gratSummaryDisplay ?? ''}
                </Typography>
              </Stack>
            </Card>
          )}

          <Stack spacing={1}>
            <Button
              disabled={loading}
              variant="primary"
              fullWidth
              size="sm"
              type="button"
              onClick={handleSubmit(handleCompleteTask)}
            >
              {householdIntegrationLink
                ? `Set linked ${ASSET_INTEGRATION_PROVIDER_DISPLAY_NAMES[householdIntegrationLink]} entity`
                : 'I confirm this trust has been created'}
            </Button>
            {householdIntegrationLink && (
              <Button
                onClick={() => handleCompleteTask(formDefaultValues)}
                variant="transparent"
                fullWidth
                size="lg"
                type="button"
              >
                Continue without linking
              </Button>
            )}
          </Stack>
        </Stack>
      </TaskHeaderCard>
    </FormProvider>
  );
}
