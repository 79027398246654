import { SetNonNullable, SetOptional } from 'type-fest';

import { PrinterIcon } from '@/components/icons/PrinterIcon';

import { Button, ButtonProps } from './Button';

// size and variant should be optional now because we declare them internally;
// we want aria-label to be required because there's no visual text on the button
export type PrintButtonProps = SetOptional<
  SetNonNullable<ButtonProps, 'aria-label'>,
  'size' | 'variant'
>;

export function PrintButton({ onClick, ...rest }: PrintButtonProps) {
  return (
    <Button square size="xs" variant="secondary" onClick={onClick} {...rest}>
      <PrinterIcon size={20} />
    </Button>
  );
}
