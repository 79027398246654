import { AISuggestionsMatcher } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher';
import { useEntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { useAISuggestionsEnabled } from '@/modules/aiSuggestions/hooks/useAISuggestionsEnabled';
import { useEditEntitySplitScreenModalContext } from '@/modules/entities/EditEntitySplitScreen/contexts/editEntitySplitScreenModal.context';
import { getMatcherVariantForEditEntitySection } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.utils';

export function EditEntitySplitScreenFormSuggestionsMatcher() {
  const { currentSection, entityType } = useEditEntitySplitScreenModalContext();
  const aiSuggestionsEnabled = useAISuggestionsEnabled(entityType);

  const { getCurrentSectionSuggestions, acknowledgedSuggestions } =
    useEntitySuggestionsContext();

  const suggestionsMatcherVariant = getMatcherVariantForEditEntitySection(
    entityType,
    currentSection
  );

  if (!aiSuggestionsEnabled || !suggestionsMatcherVariant) {
    return null;
  }

  return (
    <AISuggestionsMatcher
      variant={suggestionsMatcherVariant}
      suggestions={getCurrentSectionSuggestions(currentSection).filter(
        (s) => !acknowledgedSuggestions[s.id]
      )}
    />
  );
}
