import Decimal from 'decimal.js';

import { TypeaheadSelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { formatCurrency } from '@/utils/formatting/currency';

import {
  EntityAssetOptionsQuery,
  IndividualAssetOptionsQuery,
  SaleLoanForm_OwnershipStakeFragment,
  SaleLoanForm_ValuationFragment,
} from './graphql/SaleLoanForm.generated';

function formatCompactCurrency(value: Decimal | null | undefined) {
  return formatCurrency(value ?? new Decimal(0), { notation: 'compact' });
}

function aggregateAssetsByClass(
  valuation: SaleLoanForm_ValuationFragment | null | undefined
) {
  const assetsByClass = new Map<
    string,
    { displayName: string; totalValue: Decimal }
  >();

  valuation?.assets.edges?.forEach((edge) => {
    const asset = edge?.node;
    if (asset) {
      const classId = asset.class.id;
      const existing = assetsByClass.get(classId);
      if (existing) {
        existing.totalValue = existing.totalValue.plus(
          asset.assetValue.ownedValue ?? new Decimal(0)
        );
      } else {
        assetsByClass.set(classId, {
          displayName: asset.class.displayName,
          totalValue: new Decimal(asset.assetValue.ownedValue ?? 0),
        });
      }
    }
  });

  return assetsByClass;
}

function getBusinessEntityOptions(
  ownedOwnershipStakes: SaleLoanForm_OwnershipStakeFragment[] | null | undefined
) {
  const options: TypeaheadSelectInputOption<string>[] = [];

  ownedOwnershipStakes?.forEach((stake) => {
    if (stake.ownedEntity) {
      options.push({
        value: stake.ownedEntity.id,
        display: `${stake.ownedEntity.subtype.displayName} (${formatCompactCurrency(
          stake.ownedValue
        )})`,
        groupName: 'Business entities',
      });
    }
  });

  return options;
}

/**
 * @description Generates options for the assets that are being sold or loaned. There are two possible sources of the assets:
 * 1. Assets/business entities owned if an entity is chosen as the seller
 * 2. Assets/business entities owned by the client profile if a client profile/individual is chosen as the seller
 */
export const mapAssetOptionsToSelectOptions = (
  entityAssetData: EntityAssetOptionsQuery | undefined,
  individualAssetData: IndividualAssetOptionsQuery | undefined
): TypeaheadSelectInputOption<string>[] => {
  const entity = entityAssetData?.node;
  const individual = individualAssetData?.node;
  const options: TypeaheadSelectInputOption<string>[] = [];

  if (entity && entity.__typename === 'Entity') {
    // Add directly owned assets from the designer account
    const latestValuation =
      entity.subtype?.designerAccount?.valuations.edges?.[0]?.node;
    const assetsByClass = aggregateAssetsByClass(latestValuation);

    assetsByClass.forEach((classData, classId) => {
      options.push({
        value: classId,
        display: `${classData.displayName} (${formatCompactCurrency(classData.totalValue)})`,
        groupName: 'Asset classes',
      });
    });

    options.push(...getBusinessEntityOptions(entity.ownedOwnershipStakes));
  } else if (individual && individual.__typename === 'ClientProfile') {
    options.push(...getBusinessEntityOptions(individual.ownedOwnershipStakes));
  }

  return options;
};
