import { Node } from '@/components/diagrams/FlowChart';

import { applyDerivedDataToState } from '../state/applyDerivedDataToState';
import { generateEntityGraphState } from '../state/generateDefaultState';
import { EntityMapActionFn, UpdateEntityMapAction } from '../types';

export const updateEntityMapAction: EntityMapActionFn<UpdateEntityMapAction> = (
  state,
  action,
  _queueEffect
) => {
  const stateProps = action;
  const { nodes, edges, entityGraph } = generateEntityGraphState(stateProps);

  const { derivedData } = applyDerivedDataToState(state);
  const { nodesById } = derivedData;

  const updatedNodes = nodes.map((n) => {
    const existingNode = nodesById[n.id];
    if (!existingNode) return n;
    // We only update the node data, but leave everything about position, height, width, etc. alone
    // since we've already computed it from when the chart first initialized
    return { ...existingNode, data: { ...n.data } } as Node;
  });

  return {
    ...state,
    ...stateProps,
    entityGraph,
    nodes: updatedNodes,
    edges,
  };
};
