// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
export type ProposalScenarioFragment = { __typename?: 'ProposalScenario', id: string, gratProposalScenario?: { __typename?: 'GRATProposalScenario', id: string, createdAt: Time, updatedAt: Time, projectedAnnualReturnThroughTerm?: Percent | null, projectedAnnualReturnAfterTerm?: Percent | null, projectionTimePeriodYears: number, projectedMarketValueAtTerm?: CurrencyUSD | null, inTermValuationType?: Types.GratProposalScenarioInTermValuationType | null, inTermCAGRV2: Percent } | null };

export const ProposalScenarioFragmentDoc = gql`
    fragment ProposalScenario on ProposalScenario {
  id
  gratProposalScenario {
    id
    createdAt
    updatedAt
    projectedAnnualReturnThroughTerm
    projectedAnnualReturnAfterTerm
    projectionTimePeriodYears
    projectedMarketValueAtTerm
    inTermValuationType
    inTermCAGRV2
  }
}
    `;
export const Operations = {
  Fragment: {
    ProposalScenario: 'ProposalScenario' as const
  }
}