import { useEffect } from 'react';

import { ClientPresentationSlide } from '../clientPresentation.types';
import { useUnguardedClientPresentationDesignerContext } from '../contexts/clientPresentationDesigner.context';

/**
 * @description This function is used to ensure that the slide is only registered once
 * with a given set of data. This is important because the registration is done inline
 * and, since rerendering causes a setState call in the ClientPresentationDesigner, we
 * want to make sure to not cause parallel setStates.
 */
export function useRegisterSlide(registrationProps: ClientPresentationSlide) {
  // slides can be used outside the context of the clientPresentationDesigner, so we want to make sure
  // that they continue to work in that case.
  const pctx = useUnguardedClientPresentationDesignerContext();
  const { registerSlide } = pctx ?? {};

  useEffect(() => {
    registerSlide?.(registrationProps);
  }, [registerSlide, registrationProps]);
}
