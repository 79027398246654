import { Divider, Stack } from '@mui/material';

import { BrandingLogoUploader, LogoType } from './BrandingLogoUploader';
import { AdminBrandingPage_BrandingFragment } from './graphql/AdminBrandingPage.generated';

interface AdminLogosFormProps {
  tenantBranding: AdminBrandingPage_BrandingFragment | null;
}

export function AdminLogosForm({ tenantBranding }: AdminLogosFormProps) {
  return (
    <Stack spacing={3} divider={<Divider />} maxWidth={600}>
      <BrandingLogoUploader
        logoKey={LogoType.PrimaryWide}
        tenantBranding={tenantBranding}
      />
      <BrandingLogoUploader
        logoKey={LogoType.SecondaryWide}
        tenantBranding={tenantBranding}
      />
      <BrandingLogoUploader
        logoKey={LogoType.SecondarySquare}
        tenantBranding={tenantBranding}
      />
    </Stack>
  );
}
