import { Box, Grid, Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { EntityDetailsCard } from '@/modules/entities/components/EntityDetailsCard';
import { useEntityPageDefinition } from '@/modules/entities/details/EntityDetailOverviewPage';

export function EntityDetailPage() {
  const entityId = useRequiredParam('entityId');
  const householdId = useRequiredParam('householdId');
  const { entityPageDefinition, error, loading } =
    useEntityPageDefinition(entityId);

  useReportError('failed to load trust on TrustDetailsPage', error);

  return (
    <Stack>
      <Grid container p={3} pb={4} spacing={3}>
        <Grid item sm={4}>
          <Box sx={{ height: '100%' }}>{entityPageDefinition.AssetsCard}</Box>
        </Grid>
        <Grid item sm={8}>
          <EntityDetailsCard
            tabs={entityPageDefinition.summary}
            loading={loading}
            entityId={entityId}
            householdId={householdId}
          />
        </Grid>
      </Grid>
      {entityPageDefinition.DistributionVisualization && (
        <Box p={3}>{entityPageDefinition.DistributionVisualization}</Box>
      )}
      <Outlet />
    </Stack>
  );
}
