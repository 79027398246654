import { PropsWithChildren } from 'react';
import { makeStyles } from 'tss-react/mui';
import { SetOptional } from 'type-fest';

import { Frame, FrameProps } from '@/components/notifications/Frame/Frame';
import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

const useStyles = makeStyles()((_theme) => ({
  root: {
    backgroundColor: COLORS.PRIMITIVES.WHITE,
  },
}));

export type FlowChartWrapperProps = SetOptional<FrameProps, 'variant'> &
  PropsWithChildren<{
    classes?: WithClasses<typeof useStyles>;
  }>;

/**
 * @description Card Wrapper around FlowChart
 */
export function FlowChartWrapper({
  children,
  variant = 'inner-shadow',
  ...props
}: FlowChartWrapperProps) {
  const { classes } = useStyles(undefined, { props });
  return (
    <Frame variant={variant} className={classes.root} {...props}>
      {children}
    </Frame>
  );
}
