import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

// when this is set, it indicates that we should open the entity edit
// modal immediately upon landing on this page.
export const IMMEDIATE_EDIT_SEARCH_PARAM = 'immediateEdit' as const;

interface UseImmediateOpenEditModalReturn {
  doForceEditing: boolean;
  clearImmediateEditSearchParam: () => void;
}
export function useImmediateOpenEditModal(): UseImmediateOpenEditModalReturn {
  const [searchParams, setSearchParams] = useSearchParams();

  /**
   * Once the edit entity modals have immediately opened, they will call this
   * to clear the search param, so it doesn't keep re-triggering.
   */
  const clearImmediateEditSearchParam = useCallback(() => {
    const nextSearchParams = new URLSearchParams(searchParams);
    nextSearchParams.delete(IMMEDIATE_EDIT_SEARCH_PARAM);
    setSearchParams(nextSearchParams, { replace: true });
  }, [searchParams, setSearchParams]);

  return {
    doForceEditing: searchParams.get(IMMEDIATE_EDIT_SEARCH_PARAM) === 'true',
    clearImmediateEditSearchParam,
  };
}
