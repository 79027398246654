// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IlitTaskSidebarQueryQueryVariables = Types.Exact<{
  taskID: Types.Scalars['ID']['input'];
}>;


export type IlitTaskSidebarQueryQuery = { __typename?: 'Query', entityTasks: { __typename?: 'EntityTaskConnection', edges?: Array<{ __typename?: 'EntityTaskEdge', node?: { __typename?: 'EntityTask', id: string, dueAt: Time, assignedTo?: { __typename?: 'User', id: string } | null } | null } | null> | null } };

export type SaveTaskDetailsMutationMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateEntityTaskInput;
}>;


export type SaveTaskDetailsMutationMutation = { __typename?: 'Mutation', updateEntityTask: { __typename?: 'EntityTask', id: string } };


export const IlitTaskSidebarQueryDocument = gql`
    query ILITTaskSidebarQuery($taskID: ID!) {
  entityTasks(where: {id: $taskID}) {
    edges {
      node {
        id
        assignedTo {
          id
        }
        dueAt
      }
    }
  }
}
    `;

/**
 * __useIlitTaskSidebarQueryQuery__
 *
 * To run a query within a React component, call `useIlitTaskSidebarQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIlitTaskSidebarQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIlitTaskSidebarQueryQuery({
 *   variables: {
 *      taskID: // value for 'taskID'
 *   },
 * });
 */
export function useIlitTaskSidebarQueryQuery(baseOptions: Apollo.QueryHookOptions<IlitTaskSidebarQueryQuery, IlitTaskSidebarQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IlitTaskSidebarQueryQuery, IlitTaskSidebarQueryQueryVariables>(IlitTaskSidebarQueryDocument, options);
      }
export function useIlitTaskSidebarQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IlitTaskSidebarQueryQuery, IlitTaskSidebarQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IlitTaskSidebarQueryQuery, IlitTaskSidebarQueryQueryVariables>(IlitTaskSidebarQueryDocument, options);
        }
export function useIlitTaskSidebarQuerySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IlitTaskSidebarQueryQuery, IlitTaskSidebarQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IlitTaskSidebarQueryQuery, IlitTaskSidebarQueryQueryVariables>(IlitTaskSidebarQueryDocument, options);
        }
export type IlitTaskSidebarQueryQueryHookResult = ReturnType<typeof useIlitTaskSidebarQueryQuery>;
export type IlitTaskSidebarQueryLazyQueryHookResult = ReturnType<typeof useIlitTaskSidebarQueryLazyQuery>;
export type IlitTaskSidebarQuerySuspenseQueryHookResult = ReturnType<typeof useIlitTaskSidebarQuerySuspenseQuery>;
export type IlitTaskSidebarQueryQueryResult = Apollo.QueryResult<IlitTaskSidebarQueryQuery, IlitTaskSidebarQueryQueryVariables>;
export const SaveTaskDetailsMutationDocument = gql`
    mutation SaveTaskDetailsMutation($input: AugmentedUpdateEntityTaskInput!) {
  updateEntityTask(input: $input) {
    id
  }
}
    `;
export type SaveTaskDetailsMutationMutationFn = Apollo.MutationFunction<SaveTaskDetailsMutationMutation, SaveTaskDetailsMutationMutationVariables>;

/**
 * __useSaveTaskDetailsMutationMutation__
 *
 * To run a mutation, you first call `useSaveTaskDetailsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTaskDetailsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTaskDetailsMutationMutation, { data, loading, error }] = useSaveTaskDetailsMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTaskDetailsMutationMutation(baseOptions?: Apollo.MutationHookOptions<SaveTaskDetailsMutationMutation, SaveTaskDetailsMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTaskDetailsMutationMutation, SaveTaskDetailsMutationMutationVariables>(SaveTaskDetailsMutationDocument, options);
      }
export type SaveTaskDetailsMutationMutationHookResult = ReturnType<typeof useSaveTaskDetailsMutationMutation>;
export type SaveTaskDetailsMutationMutationResult = Apollo.MutationResult<SaveTaskDetailsMutationMutation>;
export type SaveTaskDetailsMutationMutationOptions = Apollo.BaseMutationOptions<SaveTaskDetailsMutationMutation, SaveTaskDetailsMutationMutationVariables>;
export const Operations = {
  Query: {
    ILITTaskSidebarQuery: 'ILITTaskSidebarQuery' as const
  },
  Mutation: {
    SaveTaskDetailsMutation: 'SaveTaskDetailsMutation' as const
  }
}