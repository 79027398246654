import { SetOptional } from 'type-fest';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { useModalState } from '@/hooks/useModalState';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { CreateEstateWaterfallTrowser } from '../EstateWaterfallModal/CreateEstateWaterfallTrowser';
import { useEstateWaterfallMenuItems } from '../EstateWaterfallsDropdown/hooks/useEstateWaterfallMenuItems';

export interface CreateEstateWaterfallButtonProps
  extends SetOptional<ButtonProps, 'size' | 'variant'> {
  householdId: string;
  waterfallId: string | null;
}

export function CreateEstateWaterfallButton({
  householdId,
  waterfallId,
  ...buttonProps
}: CreateEstateWaterfallButtonProps) {
  const [
    { isModalOpen: isWaterfallModalOpen },
    { openModal: openWaterfallModal, closeModal: closeWaterfallModal },
  ] = useModalState();

  const { primaryClients: grantors } = useHouseholdDetailsContext();
  const { clientWaterfalls } = useEstateWaterfallMenuItems({
    householdId,
    waterfallId,
  });

  return (
    <>
      {/* RHF preserves form state by default, but we don't want this because the update should
      always reflect the current state of the map so we fully unmount on open / close */}
      {isWaterfallModalOpen && (
        <CreateEstateWaterfallTrowser
          isOpen={isWaterfallModalOpen}
          onClose={closeWaterfallModal}
          householdId={householdId}
          grantors={grantors ?? []}
          sourceEstateWaterfalls={clientWaterfalls}
        />
      )}
      <Button
        startIcon={PlusIcon}
        variant="primary"
        size="sm"
        onClick={openWaterfallModal}
        data-testid="CreateWaterfallButton"
        loading={!grantors?.length}
        sx={{
          whiteSpace: 'nowrap',
          ...buttonProps.sx,
        }}
        {...buttonProps}
      >
        Create waterfall
      </Button>
    </>
  );
}
