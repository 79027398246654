import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { DialogModal } from '@/components/modals/DialogModal/DialogModal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { AugmentedUpdateEstateWaterfallInput } from '@/types/schema';
import { PathsOf } from '@/types/subform';
import { getNodes } from '@/utils/graphqlUtils';

import { useDeleteGrowthProfileMutation } from '../graphql/DeleteGrowthProfile.generated';
import { GrowthProfilesTable_GrowthProfileFragment } from '../graphql/GrowthProfilesTable.generated';
import { useGrowthProfilesTableContext } from '../GrowthProfilesTable.context';

export interface DeleteUsedGrowthProfileModalProps {
  growthProfile: GrowthProfilesTable_GrowthProfileFragment;
  onClose: () => void;
}

export interface DeleteUsedGrowthProfileFormData {
  newGrowthProfileId: string;
}

export function DeleteUsedGrowthProfileModal({
  growthProfile,
  onClose,
}: DeleteUsedGrowthProfileModalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { growthProfiles } = useGrowthProfilesTableContext();

  const [deleteGrowthProfileMutation, { loading: loadingDeleteGrowthProfile }] =
    useDeleteGrowthProfileMutation({
      // refetch everything to purge any cached EW data that uses the newly-deleted growth profile
      refetchQueries: 'active',
      onCompleted: () => {
        showFeedback('Growth profile deleted', { variant: 'success' });
        onClose();
      },
      onError: (error) => {
        showFeedback('Failed to delete growth profile');
        reportError('Failed to delete growth profile', error);
      },
    });

  const { targetOptions, defaultProfileId } = useMemo<{
    targetOptions: SelectInputOption[];
    defaultProfileId: string | undefined;
  }>(() => {
    const defaultProfileId = growthProfiles.find(
      (profile) => profile.isDefault
    )?.id;

    const targetOptions = growthProfiles
      .filter(({ id }) => id !== growthProfile.id)
      .sort((a, b) => {
        // sort is necessary to bubble the default option to the top
        if (a.isDefault) {
          return -1;
        }

        if (b.isDefault) {
          return 1;
        }

        return a.displayName.localeCompare(b.displayName);
      })
      .map<SelectInputOption>((growthProfile) => ({
        value: growthProfile.id,
        display: growthProfile.displayName,
      }));

    return {
      targetOptions,
      defaultProfileId,
    };
  }, [growthProfile.id, growthProfiles]);

  const formMethods = useForm<DeleteUsedGrowthProfileFormData>({
    defaultValues: {
      newGrowthProfileId: defaultProfileId,
    },
  });

  const { control, handleSubmit } = formMethods;

  const onSubmit = handleSubmit((data) => {
    void deleteGrowthProfileMutation({
      variables: {
        growthProfileId: growthProfile.id,
        updatedEstateWaterfalls: getNodes(
          growthProfile.estateWaterfalls
        ).map<AugmentedUpdateEstateWaterfallInput>((estateWaterfall) => ({
          id: estateWaterfall.id,
          update: {
            growthProfileID: data.newGrowthProfileId,
          },
        })),
        migrateEstateWaterfalls: true,
      },
    });
  });

  return (
    <FormProvider {...formMethods}>
      <DialogModal
        isOpen
        onClose={onClose}
        heading="Associate a new growth profile for existing waterfalls"
        actions={
          <Stack direction="row" spacing={3}>
            <Button
              variant="transparent"
              size="sm"
              disabled={loadingDeleteGrowthProfile}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant="destructive-prominent"
              size="sm"
              loading={loadingDeleteGrowthProfile}
              onClick={onSubmit}
            >
              Delete profile & reassociate
            </Button>
          </Stack>
        }
      >
        <Stack spacing={3}>
          <Typography variant="body1">
            Waterfalls currently using this growth profile will instead use the
            following:
          </Typography>
          <FormAwareSelectInput<DeleteUsedGrowthProfileFormData>
            control={control}
            fieldName={
              'newGrowthProfileId' as const satisfies PathsOf<DeleteUsedGrowthProfileFormData>
            }
            label="New growth profile"
            hideLabel
            showEmptyValue={false}
            options={targetOptions}
          />
        </Stack>
      </DialogModal>
    </FormProvider>
  );
}
