import { Stack } from '@mui/system';
import React from 'react';

import { useGuardedClientPresentationDesignerContext } from '../contexts/clientPresentationDesigner.context';
import { ReorderSlideElementList } from './ReorderSlideElementList/ReorderSlideElementList';
import { SidebarGroupLoadingSkeleton } from './SidebarGroup/SidebarGroupLoadingSkeleton';
import { SlideElementList } from './SlideElementList';
import { SlideManagementMenu } from './SlideManagementMenu';

interface ClientPresentationSidebarProps {
  loading?: boolean;
}

/**
 * @description This component is responsible for the overall rendering of the sidebar in the client presentation designer, including a loading state
 * and branching between the reordering mode, standard mode, and visibility toggling mode.
 */

export function ClientPresentationSidebar({
  loading,
}: ClientPresentationSidebarProps) {
  const { isReorderMode } = useGuardedClientPresentationDesignerContext();

  if (loading) {
    return (
      <SidebarContainer>
        <SidebarGroupLoadingSkeleton />
        <SidebarGroupLoadingSkeleton />
      </SidebarContainer>
    );
  }

  return (
    <>
      <SidebarContainer>
        {!isReorderMode && <SlideElementList />}
        {isReorderMode && <ReorderSlideElementList />}
      </SidebarContainer>
      <SlideManagementMenu />
    </>
  );
}

function SidebarContainer({ children }: React.PropsWithChildren<unknown>) {
  return (
    <Stack overflow="auto" height="100%" p={1}>
      {children}
    </Stack>
  );
}
