import { useMemo } from 'react';

import { DispositiveProvisionsListView } from '@/modules/dispositiveProvisions/DispositiveProvisionsListView/DispositiveProvisionsListView';
import {
  BusinessEntityDetailsTabs,
  NonTrustCharitableEntityDetailsTabs,
  PersonalAccountDetailsTabs,
  TrustDetailsTabs,
} from '@/modules/entities/details/entityDetails.types';
import { NonTrustCharitableEntitySummaryData } from '@/modules/nonTrustCharitableEntities/NonTrustCharitableEntityDetails/NonTrustCharitableEntityDetails.types';
import { NonTrustCharitableEntitySummaryWithWrapper } from '@/modules/nonTrustCharitableEntities/NonTrustCharitableEntityDetails/NonTrustCharitableEntityDetailsSummary';
import { PersonalAccountSummaryData } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountDetails.types';
import { PersonalAccountDetailsSummary } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountDetails.utils';
import { TrustTrusteesListWithWrapper } from '@/modules/trusts/TrustAdvisorsList/TrustAdvisorsList';
import { TrustBeneficiariesList } from '@/modules/trusts/TrustBeneficiariesList/TrustBeneficiariesList';
import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';
import { TrustSummaryWithWrapper } from '@/modules/trusts/TrustDetails/TrustSummary';
import { TrustPoliciesTab } from '@/modules/trusts/TrustPoliciesTab/TrustPoliciesTab';
import { EntityKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { BusinessEntitySummaryData } from '../details/businessEntities/BusinessEntityDetailsCard/BusinessEntityDetails.types';
import { BusinessEntityKeyPeople } from '../details/businessEntities/BusinessEntityDetailsCard/BusinessEntityKeyPeople';
import { BusinessEntityOwnershipList } from '../details/businessEntities/BusinessEntityDetailsCard/BusinessEntityOwnershipList';
import { BusinessEntitySummaryWithWrapper } from '../details/businessEntities/BusinessEntityDetailsCard/BusinessEntitySummary';
import { GRATTrustDetailsProvider } from '../details/grat/GRATTrustDetails';

// Map of entity details views to their components
export const EntityTabComponentMap = {
  [TrustDetailsTabs.SUMMARY]: TrustSummaryWithWrapper,
  [TrustDetailsTabs.TRUSTEES]: TrustTrusteesListWithWrapper,
  [TrustDetailsTabs.BENEFICIARIES]: TrustBeneficiariesList,
  [TrustDetailsTabs.DISPOSITIONS]: DispositiveProvisionsListView,
  [TrustDetailsTabs.POLICIES]: TrustPoliciesTab,
  [NonTrustCharitableEntityDetailsTabs.SUMMARY]:
    NonTrustCharitableEntitySummaryWithWrapper,
  [NonTrustCharitableEntityDetailsTabs.BENEFICIARIES]: TrustBeneficiariesList,
  [NonTrustCharitableEntityDetailsTabs.DISPOSITIONS]:
    DispositiveProvisionsListView,
  [NonTrustCharitableEntityDetailsTabs.POLICIES]: TrustPoliciesTab,
  [PersonalAccountDetailsTabs.SUMMARY]: PersonalAccountDetailsSummary,
  [PersonalAccountDetailsTabs.BENEFICIARIES]: TrustBeneficiariesList,
  [PersonalAccountDetailsTabs.DISPOSITIONS]: DispositiveProvisionsListView,
  [PersonalAccountDetailsTabs.POLICIES]: TrustPoliciesTab,
  [BusinessEntityDetailsTabs.SUMMARY]: BusinessEntitySummaryWithWrapper,
  [BusinessEntityDetailsTabs.OWNERSHIP]: BusinessEntityOwnershipList,
  [BusinessEntityDetailsTabs.KEY_PEOPLE]: BusinessEntityKeyPeople,
  [BusinessEntityDetailsTabs.POLICIES]: TrustPoliciesTab,
} as const;

// Helper type for getting the keys of EntityTabComponentMap
export type EntityDetailsView = keyof typeof EntityTabComponentMap;

export enum EntityDetailPropsKind {
  TrustSummaryData = 'TrustSummaryData',
  NonTrustCharitableEntitySummaryData = 'NonTrustCharitableEntitySummaryData',
  PersonalAccountSummaryData = 'PersonalAccountSummaryData',
  BusinessEntitySummaryData = 'BusinessEntitySummaryData',
}

type EntityDetailsViewProps =
  | TrustSummaryData
  | NonTrustCharitableEntitySummaryData
  | PersonalAccountSummaryData
  | BusinessEntitySummaryData;

export interface EntityDetailTab {
  display: string;
  value: EntityDetailsView;
  props?: EntityDetailsViewProps;
}

interface EntityDetailsProps {
  tabs: EntityDetailTab[];
  activeEntityView: EntityDetailsView;
  onShowEditEntity?: () => void;
}

export function EntityDetails({
  tabs,
  activeEntityView,
  onShowEditEntity,
}: EntityDetailsProps) {
  const propsForTab: EntityDetailsViewProps | null = useMemo(() => {
    return tabs.find((tab) => tab.value === activeEntityView)?.props ?? null;
  }, [activeEntityView, tabs]);

  if (!propsForTab) {
    return null;
  }

  switch (propsForTab._kind) {
    case EntityDetailPropsKind.TrustSummaryData: {
      const ActiveTrustView =
        EntityTabComponentMap[activeEntityView as TrustDetailsTabs];
      if (propsForTab.entityKind === EntityKind.GratTrust) {
        return (
          <GRATTrustDetailsProvider {...propsForTab}>
            <ActiveTrustView
              {...propsForTab}
              onShowEditEntity={onShowEditEntity}
            />
          </GRATTrustDetailsProvider>
        );
      }

      return (
        <ActiveTrustView {...propsForTab} onShowEditEntity={onShowEditEntity} />
      );
    }
    case EntityDetailPropsKind.NonTrustCharitableEntitySummaryData: {
      const NonTrustCharitableEntityView =
        EntityTabComponentMap[
          activeEntityView as NonTrustCharitableEntityDetailsTabs
        ];

      return (
        <NonTrustCharitableEntityView
          {...propsForTab}
          beneficiariesGroups={[
            {
              heading: undefined,
              items: propsForTab.beneficiaries ?? [],
              noItemsText: 'No beneficiaries',
            },
          ]}
          onShowEditEntity={onShowEditEntity}
        />
      );
    }
    case EntityDetailPropsKind.PersonalAccountSummaryData: {
      const PersonalAccountEntityView =
        EntityTabComponentMap[activeEntityView as PersonalAccountDetailsTabs];

      return (
        <PersonalAccountEntityView
          {...propsForTab}
          beneficiariesGroups={[
            {
              heading: undefined,
              items: propsForTab.beneficiaries ?? [],
              noItemsText: 'No beneficiaries',
            },
          ]}
          onShowEditEntity={onShowEditEntity}
        />
      );
    }
    case EntityDetailPropsKind.BusinessEntitySummaryData: {
      const BusinessEntityView =
        EntityTabComponentMap[activeEntityView as BusinessEntityDetailsTabs];

      return (
        <BusinessEntityView
          {...propsForTab}
          onShowEditEntity={onShowEditEntity}
        />
      );
    }
    default:
      throw new UnreachableError({
        case: propsForTab,
        message: 'Unexpected propsForTab',
      });
  }
}
