import { useApolloClient } from '@apollo/client';
import { Box, Stack, useTheme } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import {
  AssetsSubform,
  AssetsSubformType,
} from '@/modules/assets/AssetsSubform/AssetsSubform';
import { serializers } from '@/modules/assetValuation/AssetFullScreenModal/serializers';
import { AugmentedUpdateAssetValuationV2Input } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import { TaskHeaderCard } from '../../TaskHeaderCard';
import { TaskBodyProps } from '../../types';
import { useUpdateEntityAndCompleteTaskMutation } from './graphql/UpdateEntityAndCompleteTask.generated';
import {
  GetVerifyInitialFundingDataV2Query,
  useGetVerifyInitialFundingDataV2Query,
} from './graphql/VerifyInitialFunding.generated';

function getGuardedEntity(data?: GetVerifyInitialFundingDataV2Query) {
  if (data?.entity?.__typename !== 'Entity') return null;
  return data.entity;
}

interface AssetsForm {
  assetsSubform: AssetsSubformType;
}

const {
  FormComponent: AssetsFormComponent,
  defaultValues: assetsFormDefaultValues,
} = new AssetsSubform();

export function VerifyInitialFundingTaskBody({
  task,
  footer,
  onCompleteTask,
}: TaskBodyProps) {
  const client = useApolloClient();
  const { showFeedback } = useFeedback();
  // hooks
  const theme = useTheme();
  const {
    data: initialFundingDateData,
    error: getInitialFundingDateError,
    loading: initialFundingLoading,
  } = useGetVerifyInitialFundingDataV2Query({
    variables: {
      entityID: task.entityId,
    },
  });

  // Store gratTrustId as a ref so it can be set when hydrating the form data
  // and used in the submit handler
  const gratTrustId = useRef('');

  const formProperties = useForm<AssetsForm>({
    defaultValues: {
      assetsSubform: assetsFormDefaultValues,
    },
  });

  const { resetField, handleSubmit } = formProperties;

  // mutations
  const [updateEntityAndCompleteTask, { loading: mutateLoading }] =
    useUpdateEntityAndCompleteTaskMutation();

  useEffect(
    function syncFundingDateDataToForm() {
      const entity = getGuardedEntity(initialFundingDateData);
      gratTrustId.current = entity?.gratTrust?.id ?? '';

      if (!entity) return;

      // when first landing on this task, there won't be an initial funding date
      resetField(`assetsSubform.fundingDate`, {
        defaultValue: entity?.gratTrust?.effectiveDate ?? null,
      });
    },
    [initialFundingDateData, resetField]
  );

  // handlers
  const onValidSubmission: SubmitHandler<AssetsForm> = async (values) => {
    const formValues = values.assetsSubform;

    if (formValues === null) {
      // @ts-ignore -- unsure what is happening here, older code
      throw new Error('Form values are null');
    }

    let allConfirmed = true;
    formValues.assets.forEach((asset) => {
      if (!asset.confirmed) {
        allConfirmed = false;
      }
    });

    if (!allConfirmed) {
      showFeedback('Please confirm all asset valuations.');
      return;
    }

    try {
      const serializer = serializers.verifyInitialFunding;
      const input = serializer(
        formValues
      ) as AugmentedUpdateAssetValuationV2Input;

      await updateEntityAndCompleteTask({
        variables: {
          input: {
            id: task.entityId,
            update: {},
            updateGratTrust: {
              id: gratTrustId.current,
              update: {
                effectiveDate: input.update.effectiveDate,
              },
              updateDesignerAccount: {
                id: formValues.accountId!,
                update: {},
                updateInitialValuation: input,
              },
            },
          },
          taskId: task.id,
        },
        onCompleted: async (data) => {
          showFeedback('Your valuation information was updated successfully.', {
            variant: 'success',
          });

          await client.refetchQueries({
            updateCache(cache) {
              cache.evict({ id: cache.identify(data.updateEntity) });

              cache.gc();
            },
          });
        },
        onError: (err) => {
          diagnostics.error('Failed to save VerifyInitialFunding changes', err);
          showFeedback('Sorry, we failed to save your data. Please try again.');
        },
      });

      onCompleteTask();
    } catch (err) {
      diagnostics.error(
        'Failed to save VerifyInitialFunding changes',
        err as Error
      );
      showFeedback('Sorry, we failed to save your data. Please try again.');
    }
  };
  const submitHandler = handleSubmit(onValidSubmission);

  return (
    <FormProvider {...formProperties}>
      <TaskHeaderCard task={task} footer={footer}>
        <Stack component="form" noValidate onSubmit={submitHandler}>
          <Box my={3}>
            <AssetsFormComponent
              householdId={task.householdId}
              entityId={task.entityId}
              forceConfirmValuation={false}
              dateCopy="Valuation date"
              variant={'verifyInitialFunding'}
            />
          </Box>
          {getInitialFundingDateError && (
            <Callout severity="error">
              We failed to fetch your initial funding date. Please refresh the
              page to try again.
            </Callout>
          )}

          <FormLayoutRow
            sx={{
              pb: theme.spacing(10),
            }}
          >
            <FormLayoutItem>
              <Button
                disabled={!!mutateLoading || !!initialFundingLoading}
                variant="primary"
                fullWidth
                size="sm"
                type="submit"
                startIcon={CheckIcon}
              >
                I confirm that these details are correct
              </Button>
            </FormLayoutItem>
          </FormLayoutRow>
        </Stack>
      </TaskHeaderCard>
    </FormProvider>
  );
}
