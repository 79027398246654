import { useMemo } from 'react';

import { DispositionScheme } from '../dispositiveProvisions.types';
import { useActiveClientProfile } from '../DispositiveProvisionsForm/hooks/utilityHooks';

/**
 * @description This hook is responsible for determining the disposition scheme for a two-grantor
 * client death selection.
 */
export function useTwoGrantorClientDispositionScheme(): DispositionScheme | null {
  const {
    client: activeClientProfile,
    isFirstToDie: activeClientProfileIsFirstToDie,
  } = useActiveClientProfile();

  return useMemo(() => {
    // Because this is a hook and is called in all render paths, it will be called even when we're
    // configuring dispositions for a non-client profile. In that case, this logic is irrelevant,
    // so we can return null.
    if (!activeClientProfile) return null;

    return activeClientProfileIsFirstToDie
      ? DispositionScheme.UPON_FIRST_DEATH
      : DispositionScheme.UPON_SECOND_DEATH;
  }, [activeClientProfile, activeClientProfileIsFirstToDie]);
}
