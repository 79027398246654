import Decimal from 'decimal.js';
import { last } from 'lodash';

import {
  CompoundedGrowthAtYear,
  compoundedGrowthOverYears,
} from '@/utils/compoundedGrowthOverYears';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import {
  getTransferDestinationName,
  getTransferSourceName,
} from '@/utils/hypotheticalTransfers/transferUtils';

import { GetHypotheticalTransfers_HypotheticalTransferFragment } from './graphql/GetHypotheticalTransfers.generated';
import { TransferTab } from './TransferItems';

/**
 * Get the display name for a transfer, including a fallback based on the start and end years
 */
export function getTransferDisplayName({
  name,
  startYear,
  endYear,
}: {
  name?: string | undefined | null;
  startYear?: number | undefined | null;
  endYear?: number | undefined | null;
}): string {
  if (name) return name;

  if (startYear && endYear && endYear !== startYear)
    return 'Recurring transfer';

  return 'One-time transfer';
}

export function getDescriptionFromTransfer(
  transfer: GetHypotheticalTransfers_HypotheticalTransferFragment,
  kind: TransferTab
) {
  if (kind === TransferTab.Inbound) {
    return `From "${getTransferSourceName(transfer)}"`;
  }

  return `To "${getTransferDestinationName(transfer)}"`;
}

/**
 * Normalize by:
 * - Separate out transfers into their per-year events (applying growth in the process)
 * - Make all outbound transfers negative
 * - Sort transfer events by their year (for later grouping), then by transfer value (outgoing first)
 */
function annualizeHypotheticalTransfers(
  inboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[],
  outboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[]
): CompoundedGrowthAtYear[] {
  const inboundCalculatedTransfers = inboundTransfers.flatMap(
    ({
      startYear,
      endYear,
      assetGrowthReturn = new Decimal(0),
      transferValue,
    }) => {
      const initialAmount = transferValue || new Decimal(0);
      const growthRate = (assetGrowthReturn || new Decimal(0))
        .dividedBy(100)
        .plus(1);

      const { log } = compoundedGrowthOverYears({
        initialAmount,
        growthRate,
        startYear: startYear ?? 0,
        endYear: endYear ?? 0,
      });

      return log;
    }
  );

  const outboundCalculatedTransfers = outboundTransfers.flatMap(
    ({
      startYear,
      endYear,
      assetGrowthReturn = new Decimal(0),
      transferValue,
    }) => {
      const initialAmount = transferValue?.times(-1) || new Decimal(0);
      const growthRate = (assetGrowthReturn || new Decimal(0))
        .dividedBy(100)
        .plus(1);

      const { log } = compoundedGrowthOverYears({
        initialAmount,
        growthRate,
        startYear: startYear ?? 0,
        endYear: endYear ?? 0,
      });

      return log;
    }
  );

  return [...inboundCalculatedTransfers, ...outboundCalculatedTransfers].sort(
    (a, b) => a.year - b.year
  );
}

interface GetNetHypotheticalTransfersArg {
  /**
   * Current value of the entity
   */
  currentValue: Decimal;
  /**
   * All inbound transfers
   */
  inboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
  /**
   * All outbound transfers
   */
  outboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
  /**
   * Growth rate of the underlying waterfall
   */
  growthRate: Decimal;
}

export function getNetHypotheticalTransfers({
  currentValue,
  inboundTransfers,
  outboundTransfers,
  growthRate,
}: GetNetHypotheticalTransfersArg): {
  totalNormalizedTransfers: Decimal;
  totalValue: Decimal;
  finalYear: number;
  annualizedTransfers: CompoundedGrowthAtYear[];
} {
  const annualizedTransfers = annualizeHypotheticalTransfers(
    inboundTransfers,
    outboundTransfers
  );

  const currentYear = new Date().getFullYear();
  let lastTransferYear = currentYear;

  if (annualizedTransfers.length) {
    lastTransferYear = last(annualizedTransfers)?.year || currentYear;
  }

  let totalValue = currentValue;

  for (let year = new Date().getFullYear(); year <= lastTransferYear; year++) {
    if (year !== currentYear) {
      totalValue = totalValue.times(growthRate);
    }

    annualizedTransfers
      .filter(({ year: transferYear }) => year === transferYear)
      .forEach(({ value }) => {
        totalValue = totalValue.plus(value);
      });
  }

  const totalNormalizedTransfers = sumDecimalJS(
    annualizedTransfers.map(({ value }) => value)
  );

  return {
    totalNormalizedTransfers,
    totalValue: totalValue,
    finalYear: lastTransferYear,
    annualizedTransfers,
  };
}
