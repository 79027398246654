import { PopperContent } from '@/components/poppers/PopperContent';

export function RelationshipOwner() {
  return (
    <PopperContent
      heading={'Relationship owner'}
      body={
        'Relationship owner is typically the primary relationship manager or point of contact'
      }
    />
  );
}
