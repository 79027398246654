import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { useTenantDetailsContext } from '@/modules/tenant/TenantDetailsContext/TenantDetailsContext';

import { useCurrentUser } from '../../../authentication/hooks/useCurrentUser';

/**
 * @returns true if the current user is logged into Orion
 */
export function useIsUserLoggedIntoOrion() {
  const canConnect = useCanUserConnectToOrion();
  const currentUser = useCurrentUser();
  const userCanInteractWithOrion = currentUser?.loggedInToOrion ?? false;
  return canConnect && userCanInteractWithOrion;
}

/**
 * @returns true if Orion is configured on the tenant.
 */
export function useCanUserConnectToOrion() {
  const isOrionFFEnabled = useFeatureFlag('orion_integration');
  const { integrationConfiguration } = useTenantDetailsContext();

  const userCanConnectToOrion = Boolean(integrationConfiguration?.orionEnabled);

  return userCanConnectToOrion && isOrionFFEnabled;
}
