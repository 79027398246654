import { PlusIcon } from '@/components/icons/PlusIcon';

import { useZoom } from '../../hooks/useZoom';
import {
  CONTROL_BUTTON_ICON_SIZE,
  ControlButton,
  ControlButtonProps,
} from './ControlButton';

export type ZoomInButtonProps = ControlButtonProps;

export const ZoomInButton = ({
  onClick,
  children,
  ...props
}: ZoomInButtonProps) => {
  const { zoomIn, canZoomIn } = useZoom();
  const handleZoomIn = () => zoomIn();

  return (
    <ControlButton
      variant="secondary-filled"
      onClick={onClick ?? handleZoomIn}
      title="zoom in"
      aria-label="zoom in"
      disabled={!canZoomIn}
      {...props}
    >
      {children ?? <PlusIcon size={CONTROL_BUTTON_ICON_SIZE} />}
    </ControlButton>
  );
};
