import { Box, Stack } from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

export interface FooterProps {
  leftAction?: React.ReactElement;
  rightAction?: React.ReactElement;
}

// we use this number elsewhere to create margin in areas where this footer is
// set to position: fixed
export const FOOTER_HEIGHT = 80;
export function Footer({ leftAction, rightAction }: FooterProps) {
  return (
    <Stack
      bgcolor="common.white"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={2}
      height={FOOTER_HEIGHT}
      minHeight={FOOTER_HEIGHT}
      borderTop={1}
      borderColor={COLORS.GRAY[200]}
    >
      {/* It's a little weird to not render these conditionally given that they're both optional, but
      because we're splitting them up horizontally with justifyContent: space-between, rendering
      *something* all the time is required to make it work correctly */}
      {<Box>{leftAction}</Box>}
      {<Box>{rightAction}</Box>}
    </Stack>
  );
}
