import { getIncomers, getOutgoers, Node as ReactFlowNode } from '@xyflow/react';

import { Edge, Node, TileNode, TileNodeGroup } from '../types';

interface NodeBoundingBox {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export function isTileNode(node?: Node | null): node is TileNode {
  return node?.type === 'tile';
}

export function isTileGroupNode(node?: Node | null): node is TileNodeGroup {
  return isTileNode(node) && 'group' in node.data;
}

export function isSectionLabelChildNode(node?: Node | null): node is TileNode {
  return isTileNode(node) && !!node.data.sectionLabelId;
}

export function getNodeMeasuredWidthHeight(node: Node) {
  const width = node.measured?.width ?? node.width ?? 0;
  const height = node.measured?.height ?? node.height ?? 0;
  return { width, height };
}

export function getNodeBoundingBox(node: Node): NodeBoundingBox {
  const {
    position: { x, y },
  } = node;

  const { width, height } = getNodeMeasuredWidthHeight(node);

  return {
    left: x,
    right: x + width,
    top: y,
    bottom: y + height,
  };
}

export function getBoundingBoxForNodes(nodes: Node[]): NodeBoundingBox {
  const boundingBoxes = nodes.map(getNodeBoundingBox);
  return {
    left: Math.min(...boundingBoxes.map((box) => box.left)),
    right: Math.max(...boundingBoxes.map((box) => box.right)),
    top: Math.min(...boundingBoxes.map((box) => box.top)),
    bottom: Math.max(...boundingBoxes.map((box) => box.bottom)),
  };
}

export function getFirstOrderConnections(
  node: Node,
  nodes: Node[],
  edges: Edge[]
) {
  const incomers = getIncomers(
    node as ReactFlowNode,
    nodes as ReactFlowNode[],
    edges
  );
  const outgoers = getOutgoers(
    node as ReactFlowNode,
    nodes as ReactFlowNode[],
    edges
  );
  const incomerIds = new Set(incomers.map((i) => i.id));
  const outgoerIds = new Set(outgoers.map((o) => o.id));

  const incomingEdges = edges.filter(
    (e) => incomerIds.has(e.source) && e.target === node.id
  );
  const outgoingEdges = edges.filter(
    (e) => e.source === node.id && outgoerIds.has(e.target)
  );

  const relatedNodesAndEdges = [
    ...Array.from(incomerIds),
    ...Array.from(outgoerIds),
    ...[...incomingEdges, ...outgoingEdges].map((e) => e.id),
  ];

  return new Set(relatedNodesAndEdges);
}
