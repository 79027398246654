import { DocumentNode } from '@apollo/client';
import { SetRequired } from 'type-fest';

import { FullScreenModalProps } from '@/components/modals/FullScreenModal/FullScreenModal';
import { AIOnboardingModalFormShapeKeys } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import {
  AiOnboardingModal_AiSuggestionFragment,
  AiOnboardingModal_HouseholdFragment,
} from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import { DocumentViewerTab } from '@/modules/documents/components/HouseholdDocumentsViewer/context/HouseholdDocumentsViewer.context';
import { DocumentSearchResult } from '@/modules/pdf/DocumentSearch/DocumentSearch.types';
import { RouteKey } from '@/navigation/navigationUtils';
import { AiSuggestionKind } from '@/types/schema';

export interface OpenAIOnboardingModalProps {
  householdId: string;
  kgRootId: string;
}

export type AIOnboardingModalProps = OpenAIOnboardingModalProps &
  SetRequired<
    Pick<FullScreenModalProps, 'isOpen' | 'onClose'>,
    'isOpen' | 'onClose'
  >;

export type AIOnboardingModalInnerProps = AIOnboardingModalProps & {
  loading: boolean;
  household: AiOnboardingModal_HouseholdFragment;
  /**
   * The unacknowledged onboarding suggestions for the user to review.
   * These are provided in the desired order to the AIOnboardingModalProvider.
   */
  suggestions: AiOnboardingModal_AiSuggestionFragment[];
  /**
   * The relevant document ids that the suggestions were generated from.
   * These are provided in the desired order to the AIOnboardingModalProvider.
   */
  documentIds: string[];
};

export interface AIOnboardingDocumentPanelProps {
  activeDocumentViewerTab: DocumentViewerTab;
  activeDocumentId: string | undefined;
  searchQuery: string;
  annotations: DocumentSearchResult[] | undefined;
  searchLoading: boolean;
}

export enum AIOnboardingModalStep {
  NO_SUGGESTIONS = 'NO_SUGGESTIONS',
  INDIVIDUALS_AND_ORGANIZATIONS = 'INDIVIDUALS_AND_ORGANIZATIONS',
  ENTITIES = 'ENTITIES',
}

export interface AIOnboardingModalStepConfig {
  step: AIOnboardingModalStep;
  index: number;
  /** This text is shown in the step tracker at the top of the modal */
  stepTrackerText: string;
  /** This text is shown in the confirmation modal when the user tries to end the workflow */
  previouslyCreatedText: string;
  /** This text is shown on the primary button to submit the form */
  primaryButtonText: string;
  formPaths: AIOnboardingModalFormShapeKeys[];
  refetchQueries: DocumentNode[] | undefined;
  nextStep: AIOnboardingModalStep | undefined;
  navigateIfLast: RouteKey | undefined;
}

export type AIOnboardingModalSuggestionKinds =
  | AiSuggestionKind.OnboardingIndividual
  | AiSuggestionKind.OnboardingOrganization
  | AiSuggestionKind.OnboardingEntity
  | AiSuggestionKind.OnboardingTestamentaryEntity;
