import { Maybe } from 'graphql/jsutils/Maybe';
import { useMemo } from 'react';

import { StateCode } from '@/types/schema';

import { useFederalEstateTaxFormatted } from '../../irs/useIRSConstants';

interface UseEstateWaterfallCalloutCopyInput {
  stateData: {
    stateCode?: Maybe<StateCode>;
    stateDisclosure?: Maybe<string>;
  }[];
}

export function useEstateWaterfallCalloutCopy({
  stateData,
}: UseEstateWaterfallCalloutCopyInput) {
  const rate = useFederalEstateTaxFormatted();

  return useMemo(() => {
    const stateCodeToDisclosure: Partial<Record<StateCode, string>> = {};

    const addToStateCodeToDisclosure = ({
      stateCode,
      stateDisclosure,
    }: {
      stateCode?: Maybe<StateCode>;
      stateDisclosure?: Maybe<string>;
    }) => {
      if (stateCode && stateDisclosure) {
        stateCodeToDisclosure[stateCode] = stateDisclosure;
      }
    };

    stateData.forEach(addToStateCodeToDisclosure);

    if (Object.keys(stateCodeToDisclosure).length > 0) {
      const stateDisclosures = Object.values(stateCodeToDisclosure);

      return {
        ILLUSTRATIVE_PURPOSES_ONLY: `${stateDisclosures.join(' ')}`,
      };
    }

    return {
      ILLUSTRATIVE_PURPOSES_ONLY: `For illustrative purposes only. Assumes that assets transferred to non-charitable beneficiaries, in excess of the client’s remaining lifetime exemption will be taxed at a federal estate tax rate of ${rate}%. Assumes assets held outside of the client’s estate are not subject to estate tax. This does not account for any potential state-specific transfer tax.`,
    };
  }, [rate, stateData]);
}
