export interface EntityFormCommonProps {
  // This is called on cancel, AND after successful a successful create/update.
  // An entityId will be passed to indicate that the create/update was successful.
  handleClose: (entityId?: string) => void;
  householdId: string;
  entityId?: string;
  header?: string;
  navigateAfterCreate?: boolean;
}

export enum EntityFormStage {
  // Note that the entity form is no longer used for the creation of entities. It could likely be resurrected to
  // do so, but this is not a supported scenario.
  GRAT_IMPLEMENTATION_EDIT = 'GRAT_IMPLEMENTATION_EDIT',
  GRAT_IMPLEMENTATION_CONFIRM = 'GRAT_IMPLEMENTATION_CONFIRM',
  EDIT = 'EDIT',
}
