import { compact, get } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { useDispositiveProvisionsContext } from '@/modules/dispositiveProvisions/contexts/dispositiveProvisions.context';
import { CHARITABLE_ENTITY_KINDS } from '@/modules/entities/entities.constants';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import {
  ClientOrganizationKind,
  ClientProfileRelationshipType,
  DispositiveProvisionTransferTaxKind,
  EntityInEstateStatus,
  TestamentaryEntityKind,
} from '@/types/schema';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import {
  DispositiveProvisionsFormPaths,
  DispositiveProvisionsFormShape,
  Recipient,
} from '../../DispositiveProvisionsForm.types';
import {
  SURVIVING_SPOUSE_SENTINEL,
  useRecipientOptions,
  useRecipientOptionsParams,
} from '../../hooks/useRecipientOptions';
import { useFirstClientDeathId } from '../../hooks/utilityHooks';
import {
  DispositiveProvisionsPossibleRecipients_ClientOrganizationFragment,
  DispositiveProvisionsPossibleRecipients_ClientProfileFragment,
  DispositiveProvisionsPossibleRecipients_EntityFragment,
  DispositiveProvisionsPossibleRecipients_TestamentaryEntityFragment,
} from '../graphql/DispositiveProvisionsFormRecipients.generated';

/**
 * @description This hook will set the default transfer tax kind for a recipient based on the recipient's type and relationship to the dying client.
 *
 * Individual, with the relationship marked as "Spouse" -> default type to Spouse (marital)
 * Individual, with the relationship marked as "Grandchild" -> default to G3
 * Individual, with the relationship unmarked, or marked as anything OTHER THAN grandchild or spouse -> default type to G2
 * Charitable organization, DAF, foundation, CRT, or CLT -> default type to Charitable
 * Entity where the surviving spouse is a grantor/owner -> default type to Spouse (marital)
 * Testamentary entity marked "in estate of Surviving spouse" -> default type to Spouse (marital)
 * Testamentary entity marked "out of estate of Surviving spouse" -> default type to G2/3
 */
export function useDefaultRecipientTransferTaxKind(
  recipient: Recipient | undefined,
  recipientIndex: number,
  hasStateTax = false
) {
  const { primaryClients } = useDispositiveProvisionsContext();
  const dyingClientId = useFirstClientDeathId();
  const {
    setValue,
    getFieldState,
    control,
    formState: { isLoading, defaultValues },
  } = useFormContext<DispositiveProvisionsFormShape>();

  const transferTaxKindFieldName =
    `dispositiveProvisions.recipients.${recipientIndex}.transferTaxKind` as const satisfies DispositiveProvisionsFormPaths;
  const fieldDefaultValue = get(defaultValues, transferTaxKindFieldName);

  const recipientTransferTaxKind = useWatch({
    control,
    name: transferTaxKindFieldName,
  });

  const recipientOptionsParams = useRecipientOptionsParams();
  const {
    clientProfileRecipients,
    entityRecipients,
    organizationRecipients,
    testamentaryEntityRecipients,
  } = useRecipientOptions(recipientOptionsParams);

  const defaultedKind: DispositiveProvisionTransferTaxKind | null =
    useMemo(() => {
      if (!dyingClientId) return null;
      if (!recipient) {
        return null;
      }

      return _getDefaultedKindForRecipient(recipient, {
        clientProfileRecipients,
        entityRecipients,
        organizationRecipients,
        primaryClients,
        testamentaryEntityRecipients,
        dyingClientId,
        hasStateTax,
      });
    }, [
      clientProfileRecipients,
      dyingClientId,
      entityRecipients,
      hasStateTax,
      organizationRecipients,
      primaryClients,
      recipient,
      testamentaryEntityRecipients,
    ]);

  useEffect(() => {
    const { isDirty } = getFieldState(transferTaxKindFieldName);

    // if the user has already set a value on the field, either manually as part of this editing flow,
    // or previously and it was pre-populated as a "default value"
    if (isDirty || fieldDefaultValue || isLoading) return;

    // if we have no better default, do nothing
    if (defaultedKind === null) return;

    // don't re-set the value if it's already set to the default
    if (recipientTransferTaxKind === defaultedKind) {
      return;
    }

    setValue(transferTaxKindFieldName, defaultedKind, {
      // shouldDirty: false allows us to continue programmatically
      // changing the value until the user manually makes a change
      shouldDirty: false,
    });
  }, [
    defaultedKind,
    fieldDefaultValue,
    getFieldState,
    isLoading,
    recipientTransferTaxKind,
    setValue,
    transferTaxKindFieldName,
  ]);
}

export interface GetDefaultedKindForRecipientParams {
  clientProfileRecipients: DispositiveProvisionsPossibleRecipients_ClientProfileFragment[];
  entityRecipients: DispositiveProvisionsPossibleRecipients_EntityFragment[];
  organizationRecipients: DispositiveProvisionsPossibleRecipients_ClientOrganizationFragment[];
  testamentaryEntityRecipients: DispositiveProvisionsPossibleRecipients_TestamentaryEntityFragment[];
  primaryClients: ContextPrimaryClient[];
  dyingClientId: string;
  hasStateTax?: boolean;
}
// note: only exported for testing purposes
export function _getDefaultedKindForRecipient(
  recipient: Recipient,
  params: GetDefaultedKindForRecipientParams
): DispositiveProvisionTransferTaxKind | null {
  const {
    clientProfileRecipients,
    entityRecipients,
    organizationRecipients,
    primaryClients,
    testamentaryEntityRecipients,
    dyingClientId,
  } = params;

  if (!recipient?.recipientId) {
    return null;
  }

  if (recipient.recipientId === SURVIVING_SPOUSE_SENTINEL) {
    return DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion;
  }

  const recipientId = recipient.recipientId;
  const pulidKind = getPulidKind(recipientId);
  const otherPrimaryClient =
    primaryClients.find((c) => c.id !== dyingClientId) ?? null;
  const otherPrimaryClientWithRelationships =
    clientProfileRecipients.find((c) => c.id === otherPrimaryClient?.id) ??
    null;
  const otherPrimaryClientIsSpouse =
    getRelationshipType(dyingClientId, otherPrimaryClientWithRelationships) ===
    ClientProfileRelationshipType.Spouse;

  switch (pulidKind) {
    case PulidKind.ClientProfile: {
      const recipientClientProfile =
        clientProfileRecipients.find((cp) => cp.id === recipientId) ?? null;

      return getDefaultedKindForClientProfile(
        dyingClientId,
        recipientClientProfile
      );
    }

    case PulidKind.Entity: {
      const recipientEntity =
        entityRecipients.find((e) => e.id === recipientId) ?? null;

      return getDefaultedKindForEntity(
        recipientEntity,
        otherPrimaryClientIsSpouse,
        otherPrimaryClient
      );
    }

    case PulidKind.TestamentaryEntity: {
      const recipientTestamentaryEntity =
        testamentaryEntityRecipients.find((e) => e.id === recipientId) ?? null;

      return getDefaultedKindForTestamentaryEntity({
        recipientTestamentaryEntity,
        hasStateTax: params.hasStateTax ?? false,
      });
    }

    case PulidKind.ClientOrganization: {
      const recipientOrganization =
        organizationRecipients.find((o) => o.id === recipientId) ?? null;
      return getDefaultedKindForClientOrganization(recipientOrganization);
    }

    default:
      return null;
  }
}

/**
 * @description given a relatedToClientId and a clientProfile with defined relationships, this will return the type of
 * relationship between the two clients, if any.
 */
function getRelationshipType(
  relatedToClientId: string,
  recipientClientProfile: DispositiveProvisionsPossibleRecipients_ClientProfileFragment | null
): ClientProfileRelationshipType | null {
  if (!recipientClientProfile) return null;

  return (
    recipientClientProfile.relationships?.find(
      (r) => r.toClientProfile.id === relatedToClientId
    )?.type ?? null
  );
}

function getDefaultedKindForClientProfile(
  dyingClientId: string,
  recipientClientProfile: DispositiveProvisionsPossibleRecipients_ClientProfileFragment | null
) {
  const recipientToDyingClientRelationship = getRelationshipType(
    dyingClientId,
    recipientClientProfile
  );

  switch (recipientToDyingClientRelationship) {
    case ClientProfileRelationshipType.Spouse:
      return DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion;
    case ClientProfileRelationshipType.Grandparent:
      return DispositiveProvisionTransferTaxKind.Generation_3;
    default:
      return DispositiveProvisionTransferTaxKind.Generation_2OrOtherIndividual;
  }
}

function getDefaultedKindForEntity(
  recipientEntity: DispositiveProvisionsPossibleRecipients_EntityFragment | null,
  otherPrimaryClientIsSpouse: boolean,
  otherPrimaryClient: ContextPrimaryClient | null
): DispositiveProvisionTransferTaxKind | null {
  if (!recipientEntity) return null;

  if (CHARITABLE_ENTITY_KINDS.includes(recipientEntity.kind)) {
    return DispositiveProvisionTransferTaxKind.Charitable;
  }

  // we decided to only support a few types of entities to avoid unexpected behavior
  // https://linear.app/luminary/issue/T2-1446/default-recipient-type-based-on-the-recipient-entered#comment-08c84ded
  const principalIdsForEntity = (() => {
    if ('grantors' in recipientEntity.subtype) {
      return compact(
        recipientEntity.subtype.grantors?.map((g) => g?.individual?.id) ?? []
      );
    } else if ('owner' in recipientEntity.subtype) {
      return compact([recipientEntity.subtype.owner?.individual?.id]);
    } else if ('owners' in recipientEntity.subtype) {
      return compact(
        recipientEntity.subtype.owners?.map((o) => o.individual?.id) ?? []
      );
    }

    return [];
  })();

  // if the recipient is an entity where the surviving spouse is a grantor/owner, default to Spouse (marital)
  if (
    otherPrimaryClientIsSpouse &&
    otherPrimaryClient &&
    principalIdsForEntity.includes(otherPrimaryClient.id)
  ) {
    return DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion;
  }

  return null;
}

interface GetDefaultedKindForTestamentaryEntityInput {
  recipientTestamentaryEntity: DispositiveProvisionsPossibleRecipients_TestamentaryEntityFragment | null;
  hasStateTax: boolean;
}

function getDefaultedKindForTestamentaryEntity({
  recipientTestamentaryEntity,
  hasStateTax,
}: GetDefaultedKindForTestamentaryEntityInput): DispositiveProvisionTransferTaxKind | null {
  if (!recipientTestamentaryEntity) return null;

  if (
    [
      TestamentaryEntityKind.CharitableEntity,
      TestamentaryEntityKind.CharitableTrust,
    ].includes(recipientTestamentaryEntity.kind)
  ) {
    return DispositiveProvisionTransferTaxKind.Charitable;
  }

  if (hasStateTax) {
    const federalEstateStatus = recipientTestamentaryEntity.inEstateStatus;
    const stateEstateStatus =
      recipientTestamentaryEntity.survivingSpouseStateInEstateStatus;

    switch (federalEstateStatus) {
      case EntityInEstateStatus.InEstate: {
        if (stateEstateStatus === EntityInEstateStatus.InEstate) {
          return DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion;
        }
        break;
      }
      case EntityInEstateStatus.OutOfEstate: {
        if (stateEstateStatus === EntityInEstateStatus.OutOfEstate) {
          return DispositiveProvisionTransferTaxKind.Generation_2OrOtherIndividual;
        }
        if (stateEstateStatus === EntityInEstateStatus.InEstate) {
          return DispositiveProvisionTransferTaxKind.SpouseFederalCreditShelterStateMaritalExclusion;
        }
        break;
      }
    }

    return null;
  }

  switch (recipientTestamentaryEntity.inEstateStatus) {
    case EntityInEstateStatus.InEstate:
      return DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion;
    case EntityInEstateStatus.OutOfEstate:
      return DispositiveProvisionTransferTaxKind.Generation_2Then_3;
    default:
      return null;
  }
}

function getDefaultedKindForClientOrganization(
  recipientOrganization: DispositiveProvisionsPossibleRecipients_ClientOrganizationFragment | null
): DispositiveProvisionTransferTaxKind | null {
  if (!recipientOrganization) return null;
  if (
    recipientOrganization.kind === ClientOrganizationKind.CharitableOrganization
  ) {
    return DispositiveProvisionTransferTaxKind.Charitable;
  }

  return null;
}
