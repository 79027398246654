import { cx } from '@emotion/css';
import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';

type ActionCardVariant = 'default' | 'reporting' | 'noCard';
export interface ActionCardProps {
  icon: React.ReactNode;
  heading?: string;
  description: React.ReactNode;
  actions?: React.ReactNode;
  variant?: ActionCardVariant;
  sx?: SxProps<Theme>;
  spacing?: number;
  // Grow the card to fill the available vertical space
  // using the space between the description and the actions
  grow?: boolean;
  size?: 'sm' | 'lg';
}

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    paddingBottom: theme.spacing(4),
    border: `solid ${theme.palette.grey[100]} 1px`,
    boxShadow: theme.palette.shadows.md,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
}));

const useDefaultVariantStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
  },
}));

const useReportingVariantStyles = makeStyles()((_theme) => ({
  root: {
    backgroundColor: COLORS.PRIMITIVES.WHITE,
  },
}));

const useNoCardVariantStyles = makeStyles()((theme) => ({
  root: {
    padding: theme.spacing(0),
    backgroundColor: COLORS.PRIMITIVES.WHITE,
    border: 'none',
    boxShadow: 'none',
  },
}));

const useActionCardTextVariantStyles = (size: 'sm' | 'lg') => {
  return useMemo(() => {
    const headingTextVariant =
      size === 'sm' ? ('h4' as const) : ('h1' as const);
    const descriptionTextVariant =
      size === 'sm' ? ('subtitle2' as const) : ('body2' as const);
    return { headingTextVariant, descriptionTextVariant };
  }, [size]);
};

// https://www.figma.com/file/FlleF4k1X9St9vv3EgiSGY/Design-System?node-id=5788%3A958&mode=dev
export function ActionCard({
  icon,
  heading,
  description,
  actions,
  variant = 'default',
  sx,
  spacing = 3,
  grow = false,
  size = 'lg',
}: ActionCardProps) {
  const { classes } = useStyles(undefined);
  const { classes: defaultClasses } = useDefaultVariantStyles();
  const { classes: reportingClasses } = useReportingVariantStyles();
  const { classes: noCardClasses } = useNoCardVariantStyles();

  const variantClasses: Record<ActionCardVariant, typeof classes> = {
    noCard: noCardClasses,
    reporting: reportingClasses,
    default: defaultClasses,
  };
  const variantStyles = variantClasses[variant];

  const { headingTextVariant, descriptionTextVariant } =
    useActionCardTextVariantStyles(size);

  return (
    <Stack
      className={cx(classes.root, variantStyles.root)}
      spacing={spacing}
      sx={sx}
    >
      <Stack spacing={1} alignItems="center">
        <Box>{icon}</Box>
        {heading && (
          <Typography variant={headingTextVariant} component="h3">
            {heading}
          </Typography>
        )}
        <Typography variant={descriptionTextVariant} component="span">
          {description}
        </Typography>
      </Stack>
      {grow && <Box flexGrow={1} />}
      {actions}
    </Stack>
  );
}
