import { DOCUMENT_TYPE_OPTIONS } from './documents.constants';

export function documentTypeIsRestricted(documentType: DocumentType | string) {
  const documentTypeOption = DOCUMENT_TYPE_OPTIONS.find((typeOption) => {
    return typeOption.value === documentType;
  });
  return documentTypeOption?.disabled ?? false;
}

export function normalizeWhitespace(str: string): string {
  return str.replace(/\s+/g, ' ').trim();
}

export function orderDocsAlphaByName<T extends { id: string; name: string }>(
  docs: T[]
): T[] {
  return docs.sort((a, b) => {
    const aName = a.name ?? '';
    const bName = b.name ?? '';
    return aName.localeCompare(bName);
  });
}
