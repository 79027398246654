import { Box, Stack, Typography } from '@mui/material';

import { GREY_CANDY_CANE } from '@/components/charts/constants';
import { StackedHorizontalBar } from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';

export function NoScenariosBarChart() {
  return (
    <Stack direction="row" alignItems="center" spacing={3}>
      <Box display="flex">
        <Typography
          variant="subtitle2"
          sx={{
            fontWeight: FONT_WEIGHTS.bold,
          }}
        >
          Scenario
        </Typography>
      </Box>
      <Box width="100%">
        <StackedHorizontalBar
          sections={[
            {
              backgroundCss: GREY_CANDY_CANE,
              color: COLORS.PRIMITIVES.WHITE,
              label: 'No scenarios',
              value: 1,
              hideValue: true,
            },
          ]}
        />
      </Box>
    </Stack>
  );
}
