import { Stack, SxProps, Theme } from '@mui/material';

import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { Card } from '@/components/layout/Card/Card';
import { FloatingMenuContainer } from '@/components/layout/FloatingMenuContainer';
import { PDFViewerLazy } from '@/modules/pdf/PDFViewer';
import { useAnnotatedPDFViewerContext } from '@/modules/pdf/PDFViewer/AnnotatedPDFViewer/context/AnnotatedPDFViewer.context';
import { AnnotatedPDFViewerProvider } from '@/modules/pdf/PDFViewer/AnnotatedPDFViewer/context/AnnotatedPDFViewer.provider';
import { COLORS } from '@/styles/tokens/colors';

import { DocumentSearchBar } from '../../pdf/DocumentSearch/DocumentSearchBar';
import { DOCUMENT_DETAILS_VIEW_BUTTON_OPTIONS } from '../documents.constants';

interface DocumentViewerWithSearchProps {
  documentId: string;
  documentSrc: string;
  activeTab?: string;
  setActiveTab?: (value: string) => void;
  sx?: SxProps<Theme>;
}

function DocumentViewerWithSearchInner({
  documentId,
  documentSrc,
  activeTab,
  setActiveTab,
  sx,
}: DocumentViewerWithSearchProps) {
  const { annotations, activeAnnotationIndex } = useAnnotatedPDFViewerContext();

  return (
    <Stack flex={1} height="100%" overflow="auto">
      <Card
        data-name="DocumentViewerWithSearch-container"
        variant="inner-shadow-light"
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          px: 2,
          overflowY: 'scroll',
          position: 'relative',
          backgroundColor: COLORS.GRAY[50],
          ...sx,
        }}
      >
        {activeTab && setActiveTab && (
          <FloatingMenuContainer
            offset={{ top: 24, left: 8 }}
            sx={{ height: 40, width: 258 }}
          >
            <ButtonGroup
              label=""
              variant="dark"
              value={activeTab}
              options={DOCUMENT_DETAILS_VIEW_BUTTON_OPTIONS}
              onChange={(_, value) => setActiveTab(value)}
            />
          </FloatingMenuContainer>
        )}
        <PDFViewerLazy
          documentId={documentId}
          documentUrl={documentSrc}
          activeAnnotationIdx={activeAnnotationIndex}
          annotations={annotations}
          slots={{
            DocumentFooter: DocumentSearchBar,
          }}
        />
      </Card>
    </Stack>
  );
}

export default function DocumentViewerWithSearch(
  props: DocumentViewerWithSearchProps
) {
  return (
    <AnnotatedPDFViewerProvider documentId={props.documentId}>
      <DocumentViewerWithSearchInner {...props} />
    </AnnotatedPDFViewerProvider>
  );
}
