import { useState } from 'react';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { iconSizeByButtonSize } from '@/components/form/baseInputs/Button/styles';
import { Gift02Icon } from '@/components/icons/Gift02Icon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';

import { LogNewGiftModal } from '../gifting/LogNewGiftModal/LogNewGiftModal';
import { LogNewTransferModal } from '../gifting/LogNewTransferModal/LogNewTransferModal';
import { TransferType } from '../gifting/LogNewTransferModal/LogNewTransferModal.types';
import { TransferOptionType } from '../gifting/TransferOption.type';

interface LogNewGiftOrDistributionMenuProps {
  householdId: string;
  beneficiaryId: string;
  beneficiaryKind: TransferOptionType;
}

export function LogNewGiftOrDistributionMenu({
  householdId,
  beneficiaryId,
  beneficiaryKind,
}: LogNewGiftOrDistributionMenuProps) {
  const [isLogNewGiftModalOpen, setIsLogNewGiftModalOpen] = useState(false);
  const [isLogNewTransferModalOpen, setIsLogNewTransferModalOpen] =
    useState(false);

  return (
    <>
      {isLogNewGiftModalOpen && (
        <LogNewGiftModal
          isOpen={isLogNewGiftModalOpen}
          onClose={() => setIsLogNewGiftModalOpen(false)}
          householdId={householdId}
          beneficiaryId={beneficiaryId}
        />
      )}
      {isLogNewTransferModalOpen && (
        <LogNewTransferModal
          isOpen={isLogNewTransferModalOpen}
          onClose={() => setIsLogNewTransferModalOpen(false)}
          initiatorId={beneficiaryId}
          initiatorKind={beneficiaryKind}
          initiatorEntityKind={null}
          householdId={householdId}
          transferType={TransferType.Contribution}
          isInboundDistribution
        />
      )}
      <ButtonWithPopover
        label="Log a gift or distribution"
        buttonSx={{
          whiteSpace: 'nowrap',
        }}
        size="sm"
        variant="secondary"
        popperVariant="menuBelow"
        startIcon={Gift02Icon}
        iconProps={{ size: iconSizeByButtonSize.sm }}
      >
        <MenuItem
          label="Log a new gift"
          onClick={() => setIsLogNewGiftModalOpen(true)}
        />
        <MenuItem
          label="Log a new distribution"
          onClick={() => setIsLogNewTransferModalOpen(true)}
        />
      </ButtonWithPopover>
    </>
  );
}
