import { Box, Stack, SxProps, Typography } from '@mui/material';
import React from 'react';
import { Control, FieldValues } from 'react-hook-form';

import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { COLORS } from '@/styles/tokens/colors';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

export interface FormAwareCheckboxListItemProps<FormShape extends FieldValues> {
  title: string;
  lineTwo?: string;
  lineThree?: string;
  control: Control<FormShape>;
  fieldName: FieldNameFromFormShape<FormShape>;
  inputElement?: JSX.Element;
  sx?: SxProps;
}

export function FormAwareCheckboxListItem<FormShape extends FieldValues>({
  title,
  lineTwo,
  lineThree,
  fieldName,
  control,
  inputElement,
  sx,
}: FormAwareCheckboxListItemProps<FormShape>) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- linter refactor
  const isChecked = control._getWatch(fieldName);
  return (
    <Stack
      direction="row"
      alignItems="center"
      component="li"
      gap={1}
      pb={1}
      pt={1}
      sx={{
        borderBottom: `1px ${COLORS.GRAY[200]} solid`,
        ...sx,
      }}
    >
      <FormAwareCheckbox
        control={control}
        required={false}
        fieldName={fieldName}
      />
      <Box>
        <Typography fontWeight="bold" variant="h5">
          {title}
        </Typography>
        {lineTwo && <Typography variant="subtitle2">{lineTwo}</Typography>}
        {lineThree && <Typography variant="body1">{lineThree}</Typography>}
      </Box>
      <Box flexGrow="1"></Box>
      <Box
        sx={{
          visibility: `${isChecked ? 'unset' : 'hidden'}`,
        }}
      >
        {inputElement}
      </Box>
    </Stack>
  );
}

export function CheckboxListWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box component="section">
      <Box sx={{ p: 0, m: 0 }} component="ul">
        {React.Children.map(children, (child) => child)}
      </Box>
    </Box>
  );
}
