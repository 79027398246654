import {
  DataTable_LEGACY,
  DataTable_LEGACYProps,
} from '@/components/tables/DataTable_LEGACY/DataTable_LEGACY';
import { Column } from '@/components/tables/DataTable_LEGACY/tableTypes';

export const SCENARIO_ILLUSTRATIONS_COLUMNS: Column[] = [
  {
    field: 'timeFrame',
    headerName: '',
    width: 120,
    sortable: false,
  },
  {
    field: 'beginningPrincipal',
    headerName: 'Beginning principal',
    width: 200,
    flex: 1,
    sortable: false,
    align: 'right',
  },
  {
    field: 'principalGrowth',
    headerName: 'Principal growth',
    width: 200,
    flex: 1,
    sortable: false,
    align: 'right',
  },
  {
    field: 'annuityPayment',
    headerName: 'Annuity payment',
    width: 200,
    flex: 1,
    sortable: false,
    cellFormat: 'Emphasized',
    align: 'right',
  },
  {
    field: 'remainderValue',
    headerName: 'Remainder value',
    width: 200,
    flex: 1,
    sortable: false,
    cellFormat: 'HighlightLastCell',
    align: 'right',
  },
];

export function ScenarioIllustrationTable({
  rows,
}: {
  rows: DataTable_LEGACYProps['rows'];
}) {
  return (
    <DataTable_LEGACY
      columns={SCENARIO_ILLUSTRATIONS_COLUMNS}
      rows={rows}
      variant="long"
      hideFooter_DANGEROUS_ONLY_GOES_TO_100_ITEMS
    />
  );
}
