import { Stack } from '@mui/material';
import { compact } from 'lodash';
import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { LinkTabProps } from '@/components/navigation/NavigationTabs';
import { PageHeader } from '@/components/navigation/PageHeader';
import { useMatchingRoutes } from '@/hooks/useMatchingRoutes';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { CreateNewEntityOrStrategy } from '@/modules/common/CreateNewEntityOrStrategy/CreateNewEntityOrStrategy';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

export function ClientEntitiesPage() {
  const householdId = useRequiredParam('householdId');
  const { isMatchingRoute: isOnDiagramPage } = useMatchingRoutes([
    ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITY_MAP,
    ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITY_MAP_DIAGRAM,
  ]);

  const tabs: LinkTabProps[] = useMemo(() => {
    return compact([
      {
        display: 'List',
        dataTestId: 'tab-HOUSEHOLD_DETAILS_ENTITIES_LIST',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST,
          {
            householdId,
          }
        ),
      },
      {
        display: 'Diagram',
        dataTestId: 'tab-HOUSEHOLD_DETAILS_ENTITY_MAP',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITY_MAP,
          {
            householdId,
          }
        ),
      },
      {
        display: 'Dispositions',
        dataTestId: 'tab-HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS_V2',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS,
          {
            householdId,
          }
        ),
      },
    ]);
  }, [householdId]);

  return (
    <Stack height={isOnDiagramPage ? '100%' : undefined}>
      <PageHeader
        heading="Entities"
        tabs={tabs}
        action={
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            <CreateNewEntityOrStrategy
              householdId={householdId}
              variant="entity_only"
            />
          </Stack>
        }
      />
      <Stack p={3} pb={4} flex={1}>
        <Outlet />
      </Stack>
    </Stack>
  );
}
