import {
  array,
  assign,
  Describe,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreateGpBusinessEntityInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateGpBusinessEntityInput,
} from '@/types/schema';

import {
  commonCreateBusinessEntityProperties,
  commonCreateBusinessEntityTopLevelProperties,
  commonUpdateBusinessEntityEntityUpdateProperties,
  commonUpdateBusinessEntityProperties,
  commonUpdateBusinessEntityTopLevelProperties,
  keyPersonSchema,
} from './businessEntityTypes';
import {
  createInsurancePolicyInput,
  updateInsurancePolicyInput,
} from './insuranceTypes';
import {
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  principalWithOwnershipSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const createGPSchema: Describe<AugmentedCreateGpBusinessEntityInput> = object({
  create: commonCreateBusinessEntityProperties,
  withOwners: optional(nullable(array(principalWithOwnershipSchema))),
  withKeyPeople: optional(nullable(array(keyPersonSchema))),
  withDesignerAccount: optional(nullable(createAccountSchema)),
});

const updateGPSchema: Describe<AugmentedUpdateGpBusinessEntityInput> = object({
  id: string(),
  update: commonUpdateBusinessEntityProperties,
  withOwners: optional(nullable(array(principalWithOwnershipSchema))),
  withKeyPeople: optional(nullable(array(keyPersonSchema))),
  updateDesignerAccount: optional(nullable(updateAccountSchema)),
  withPolicies: optional(nullable(array(createInsurancePolicyInput))),
  updatePolicies: optional(nullable(array(updateInsurancePolicyInput))),
});

export const createSchema: Describe<AugmentedCreateEntityInput> = assign(
  object({
    create: createEntityInput,
    withGpBusinessEntity: optional(nullable(createGPSchema)),
    withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
  }),
  commonCreateBusinessEntityTopLevelProperties
);

export const updateSchema: Describe<AugmentedUpdateEntityInput> = assign(
  object({
    id: string(),
    update: commonUpdateBusinessEntityEntityUpdateProperties,
    updateGpBusinessEntity: optional(nullable(updateGPSchema)),
    withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
  }),
  commonUpdateBusinessEntityTopLevelProperties
);

export function validateGPCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'GP create');
}

export function validateGPUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'GP update');
}
