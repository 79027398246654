import { usePostHog } from 'posthog-js/react';
import { useCallback } from 'react';

import { formPost } from '@/utils/formUtils';

export function useLogoutUser() {
  const posthog = usePostHog();
  posthog.reset();
  return useCallback(() => {
    formPost('/api/auth/logout', {});
  }, []);
}
