import { Control, FieldValues } from 'react-hook-form';

import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

interface FundingDateInputProps<FormShape extends FieldValues> {
  control: Control<FormShape>;
  label: string;
  disabled?: boolean;
  fieldName: FieldNameFromFormShape<FormShape>;
  minDate: Date;
}

export function FundingDateInput<FormShape extends FieldValues>({
  control,
  disabled,
  label,
  fieldName,
  minDate,
}: FundingDateInputProps<FormShape>) {
  return (
    <FormAwareDatePickerInput<FormShape>
      control={control}
      disabled={disabled}
      required
      label={label}
      validation={{
        required: (value) => {
          if (disabled) return undefined;
          if (!value) return `${label} is required.`;
          return undefined;
        },
        minDate: (value) => {
          if (disabled) return undefined;
          if (new Date(value) < minDate) {
            return `Date must be after funding date`;
          }

          return undefined;
        },
      }}
      // this minDate hints to the datepicker what date range to allow the user
      // to choose from in the UI
      minDate={minDate}
      fieldName={fieldName}
    />
  );
}
