import { cx } from '@emotion/css';
import { Box, Stack, Typography } from '@mui/material';
import {
  GridEventListener,
  UncapitalizedGridProSlotsComponent,
} from '@mui/x-data-grid-pro';
import Decimal from 'decimal.js';
import { partition, uniqueId } from 'lodash';
import React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { Button } from '@/components/form/baseInputs/Button';
import { CommonButtonProps } from '@/components/form/baseInputs/Button/types';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Draghandle } from '@/components/lists/DragAndDropList/Draghandle';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { CellContainer } from '@/components/tables/DataTable/components/cells';
import { DataTableCard } from '@/components/tables/DataTable/components/DataTableCard';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import {
  PaginatedTableProps,
  usePaginatedDataTableQuery,
} from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { useReportError } from '@/hooks/useReportError';
import { useTenantHasEnabledAssetIntegration } from '@/modules/assetProviderIntegrations/shared/hooks/useTenantHasEnabledAssetIntegration';
import { COLORS } from '@/styles/tokens/colors';
import { AssetClassLiquidityStatus } from '@/types/schema';

import { useAssetCategoriesColumns } from './AdminAssetCategoriesConfigurationPage.columns';
import {
  AssetCategoriesForm,
  NEW_ASSET_CATEGORY_SENTINEL,
  RowData,
} from './AdminAssetCategoriesConfigurationPage.types';
import {
  getDefaultValues,
  getNextRowOrder,
  mapFormValuesToInputs,
  useMapDataToRows,
} from './AdminAssetCategoriesConfigurationPage.utils';
import {
  ReorderableRowsProvider,
  useReorderableRows,
} from './context/ReorderableRows.context';
import {
  useAssetClassesQuery,
  useUpdateAssetClassesMutation,
} from './graphql/AdminAssetCategoriesConfigurationPage.generated';

const useStyles = makeStyles()((_theme) => ({
  // this is required to give the virtual scroller enough height to not cut off the bottom of the table empty state
  virtualScroller: {
    minHeight: 170,
  },
  pinnedRows: {
    // using this to make the divider between the pinned rows and normal rows more prominent. normally
    // i'd use a border but it doesn't work on these elements, soo i'm using a box shadow.
    boxShadow: `0px 2px 2px -2px ${COLORS.GRAY[700]}`,
  },
}));

const slots: Partial<UncapitalizedGridProSlotsComponent> = {
  rowReorderIcon: () => (
    <CellContainer>
      <Draghandle />
    </CellContainer>
  ),
  footer: Box,
};

interface AdminAssetCategoriesConfigurationPageInnerProps {
  pinnedRows: RowData[];
  paginatedTableProps: PaginatedTableProps;
  pageToggle: React.ReactNode;
}

function AdminAssetCategoriesConfigurationPageInner({
  paginatedTableProps,
  pinnedRows,
  pageToggle,
}: AdminAssetCategoriesConfigurationPageInnerProps) {
  const { reorderableRows, setReorderableRows } = useReorderableRows();
  const integrationEnabled = useTenantHasEnabledAssetIntegration();
  const { setValue } = useFormContext<AssetCategoriesForm>();
  const columns = useAssetCategoriesColumns();
  const styles = useStyles();

  const addNewAssetRow = useCallback(() => {
    const id = uniqueId(NEW_ASSET_CATEGORY_SENTINEL);

    // first, add the value to the form so when we add the row there are values to read from.
    setValue(`assetCategoriesById.${id}`, {
      name: '',
      growthRate: new Decimal(0),
      liquidityStatus: AssetClassLiquidityStatus.Liquid,
      integrationCategoryIds: [],
      _isSystemClass: false,
    });

    // then, add the row to the table so it's bisible
    setReorderableRows((prev) => [
      {
        __reorder__: 'New Asset Category',
        id,
        name: '',
        isSystemClass: false,

        growthRate: null,
        liquidity: null,
        integrationCategories: null,
        action: null,
      },
      ...prev,
    ]);

    // finally, mark it as the currently edited row so the user can start editing it right away
    setValue(`currentlyEditingRowId`, id);
  }, [setReorderableRows, setValue]);

  const handleRowOrderChange: GridEventListener<'rowOrderChange'> = useCallback(
    (params) => {
      const nextRowOrder = getNextRowOrder(reorderableRows, params);
      setReorderableRows(nextRowOrder);
    },
    [reorderableRows, setReorderableRows]
  );

  const getIntegrationDescription = useCallback((): string => {
    if (integrationEnabled) {
      return 'Manage asset categories for assets that are held within entities. Once entities are linked, asset categories from the integration provider will flow into Luminary with default associations for review.';
    }

    return 'Manage asset categories for assets that are held within entities. Map these asset categories to the balance sheet via the balance sheet toggle.';
  }, [integrationEnabled]);

  return (
    <DataTableCard>
      <Stack spacing={2}>
        <Stack
          spacing={2}
          direction="row"
          // TODO: Remove when the balance sheet ff is no longer a thing; this is making sure the add category
          // button is right-aligned even if the page toggle isn't present
          justifyContent={pageToggle ? 'space-between' : 'flex-end'}
          alignItems="center"
        >
          {pageToggle}
          <AddAssetCategoryButton onClick={addNewAssetRow} />
        </Stack>

        {/* TODO (T2-XXX): with growth rates, add  "Growth rates specified here will be used to project future entity values." */}
        <Typography variant="body1">{getIntegrationDescription()}</Typography>
        <DataTable
          {...paginatedTableProps}
          classes={{
            virtualScroller: cx(styles.classes.virtualScroller),
            pinnedRows: cx(styles.classes.pinnedRows),
          }}
          disableColumnReorder
          disableColumnResize
          pagination={false}
          slots={slots}
          columns={columns}
          rows={reorderableRows}
          pinnedRows={{
            top: pinnedRows,
          }}
          onRowOrderChange={handleRowOrderChange}
          rowReordering
          rowHoverVariant="transparent" // disable row hover effect
        />
      </Stack>
    </DataTableCard>
  );
}

interface AdminAssetCategoriesConfigurationPageProps {
  pageToggle: React.ReactNode;
}

export function AdminAssetCategoriesConfigurationPage(
  props: AdminAssetCategoriesConfigurationPageProps
) {
  const { createSuccessFeedback, showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [reorderableRows, setReorderableRows] = useState<RowData[]>([]);

  const [updateAssetClasses] = useUpdateAssetClassesMutation({
    onCompleted: createSuccessFeedback('Successfully updated asset categories'),
    onError: (err) => {
      reportError('failed to update asset classes', err);
      showFeedback(
        'Failed to update asset categories. Please refresh the page and try again.'
      );
    },
  });
  const [paginatedTableProps, { data }] = usePaginatedDataTableQuery(
    useAssetClassesQuery,
    {
      // we want to basically show all asset classes, but not if someone
      // creates an insane number
      pageSize: PageSizes.Fifty,
      variables: {
        where: {},
      },
      // without this, the old row order will briefly flash during save
      fetchPolicy: 'network-only',
    }
  );

  const rows = useMapDataToRows(data);
  const { reorderableRows: reorderableRowsFromQuery, pinnedRows } =
    useMemo(() => {
      const [pinnedRows, reorderableRows] = partition(
        rows,
        (row) => row.isSystemClass
      );
      return { pinnedRows, reorderableRows };
    }, [rows]);

  useEffect(() => {
    if (reorderableRowsFromQuery.length > 0) {
      setReorderableRows(reorderableRowsFromQuery);
    }
  }, [reorderableRowsFromQuery]);

  const formMethods = useForm<AssetCategoriesForm>({
    defaultValues: {
      assetCategoriesById: {},
    },
  });

  const {
    formState: { isSubmitting },
  } = formMethods;

  const { reset } = formMethods;
  useEffect(() => {
    if (!data) return;
    const defaultValues = getDefaultValues(data);
    reset(defaultValues);
  }, [data, reset]);

  const handleSubmit = formMethods.handleSubmit((formValues) => {
    const { creates, updates, clears } = mapFormValuesToInputs(formValues, {
      pinnedRows,
      reorderableRows,
    });
    return updateAssetClasses({
      variables: {
        createInputs: creates,
        updateInputs: updates,
        clearIntegrationAssetClassInputs: clears,
      },
    });
  });

  return (
    <ReorderableRowsProvider
      reorderableRows={reorderableRows}
      setReorderableRows={setReorderableRows}
    >
      <Box>
        <FormProvider {...formMethods}>
          {/* add bottom-margin to make scrolling room for the fixed footer */}
          <Box mb={`${FOOTER_HEIGHT + 10}px`}>
            <AdminAssetCategoriesConfigurationPageInner
              {...props}
              pinnedRows={pinnedRows}
              paginatedTableProps={paginatedTableProps}
            />
          </Box>
        </FormProvider>
        <Box position="fixed" sx={{ bottom: 0, left: 0, right: 0 }}>
          <Footer
            rightAction={
              <Button
                variant="primary"
                size="sm"
                onClick={handleSubmit}
                loading={isSubmitting}
              >
                Save changes
              </Button>
            }
          />
        </Box>
      </Box>
    </ReorderableRowsProvider>
  );
}

function AddAssetCategoryButton(props: Partial<CommonButtonProps>) {
  return (
    <Button variant="secondary" size="sm" startIcon={PlusIcon} {...props}>
      Add asset category
    </Button>
  );
}
