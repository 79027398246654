import { Typography, TypographyProps } from '@mui/material';

export type EmptyListItemProps = TypographyProps;

export function EmptyListItem({ children, ...rest }: EmptyListItemProps) {
  return (
    <Typography m={2} variant="subtitle1" {...rest}>
      {children}
    </Typography>
  );
}
