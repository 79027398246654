import { Box, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import React, { useCallback, useMemo, useState } from 'react';
import Tree, { RawNodeDatum } from 'react-d3-tree';

import { Loader } from '@/components/progress/Loader/Loader';
import { useDebouncedFn } from '@/hooks/useDebouncedFn';
import { useEventListener } from '@/hooks/useEventListener';
import { GratTrustFragment } from '@/modules/entities/gratTrusts/graphql/GratTrust.generated';
import { EntityStage } from '@/types/schema';

import { entityKindToDisplayName } from '../../EntityForm/utils';
import { BeneficiaryLeaf } from './BeneficiaryLeaf';
import { GrantorLeaf } from './GrantorLeaf';
import { GratLeaf as GratLeafComponent } from './GratLeaf';
import {
  BeneficiaryTree,
  EntityBeneficiaryLeaf,
  GratLeaf,
  IndividualLeaf,
  makeBeneficiaryTreeV2,
  NoBeneficiaryLeaf,
  NODE_HEIGHT,
  NODE_WIDTH,
  OrganizationLeaf,
  SEPARATION_SCALE,
  TrustLeaf,
  useCenteredTree,
} from './helpers';
import { NoBeneficiaryLeaf as NoBeneficiaryLeafComponent } from './NoBeneficiaryLeaf';
import { TrustLeaf as TrustLeafComponent } from './TrustLeaf';

interface Props {
  gratTrust: GratTrustFragment;
  stage: EntityStage;
}

export const NODE_HEIGHT_PX = `${NODE_HEIGHT}px`;
export const NODE_WIDTH_PX = `${NODE_WIDTH}px`;

function ForeignObjectLeafGrat({ nodeDatum }: { nodeDatum: GratLeaf }) {
  const [height, setHeight] = useState(340); // height of a standard GRAT leaf
  const theme = useTheme();

  const handleRect = useCallback((node: HTMLDivElement) => {
    // get the actual height of the GRAT leaf component
    const rect = node?.getBoundingClientRect();

    if (rect?.height) {
      setHeight(rect.height);
    }
  }, []);

  return (
    <g>
      <foreignObject
        height={height}
        width={theme.spacing(28)}
        x={theme.spacing(-28 / 2)}
        y={`-${height / 2}`}
        overflow="visible"
      >
        <Box ref={handleRect}>
          <GratLeafComponent
            displayName={nodeDatum.name}
            gratSummaryDisplay={nodeDatum.attributes.gratSummaryDisplay}
            initialFundingValue={
              nodeDatum.attributes.initialFundingValue ?? new Decimal(0)
            }
            grantorRetainedInterest={
              nodeDatum.attributes.grantorRetainedInterest ?? new Decimal(0)
            }
            calculatedTaxableGift={
              nodeDatum.attributes.calculatedTaxableGift ?? new Decimal(0)
            }
            rate7520={nodeDatum.attributes.rate7520 ?? new Decimal(0)}
          />
        </Box>
      </foreignObject>
    </g>
  );
}

function ForeignObjectLeaf({
  children,
  displayName,
}: React.PropsWithChildren<{
  displayName: string;
}>) {
  let height = NODE_HEIGHT_PX;
  if (displayName.length > 15) {
    // heuristic to make the node taller if the name is long
    // leaf is a constant width
    height = '100%';
  }

  return (
    <g>
      <foreignObject
        height={height}
        width={NODE_WIDTH_PX}
        x={`-${NODE_WIDTH / 2}px`}
        y={`-${NODE_HEIGHT / 2}px`}
      >
        {children}
      </foreignObject>
    </g>
  );
}

const renderForeignObjectNode = ({
  nodeDatum,
}: {
  nodeDatum:
    | BeneficiaryTree
    | GratLeaf
    | TrustLeaf
    | IndividualLeaf
    | NoBeneficiaryLeaf
    | OrganizationLeaf
    | EntityBeneficiaryLeaf;
}) => {
  if (nodeDatum.attributes.type === 'Grat') {
    return <ForeignObjectLeafGrat nodeDatum={nodeDatum as GratLeaf} />;
  }

  if (nodeDatum.attributes?.type === 'Individual') {
    return (
      <ForeignObjectLeaf displayName={nodeDatum.name}>
        <BeneficiaryLeaf
          displayName={nodeDatum.name}
          percentOwnership={nodeDatum.attributes.percentOwnership}
          distributedValue={nodeDatum.attributes.distributedValue}
          type={nodeDatum.attributes.type}
        />
      </ForeignObjectLeaf>
    );
  }

  if (nodeDatum.attributes?.type === 'NoBeneficiary') {
    return (
      <ForeignObjectLeaf displayName={nodeDatum.name}>
        <NoBeneficiaryLeafComponent />
      </ForeignObjectLeaf>
    );
  }

  if (nodeDatum.attributes?.type === 'Grantor') {
    return (
      <ForeignObjectLeaf displayName={nodeDatum.name}>
        <GrantorLeaf displayName={nodeDatum.name} />
      </ForeignObjectLeaf>
    );
  }

  if (nodeDatum.attributes?.type === 'Trust') {
    return (
      <ForeignObjectLeaf displayName={nodeDatum.name}>
        <TrustLeafComponent
          displayName={nodeDatum.name}
          percentOwnership={nodeDatum.attributes.percentOwnership}
          trustTaxableStatus={nodeDatum.attributes.trustType}
          distributedValue={nodeDatum.attributes.distributedValue}
        />
      </ForeignObjectLeaf>
    );
  }

  if (nodeDatum.attributes.type === 'Organization') {
    return (
      <ForeignObjectLeaf displayName={nodeDatum.name}>
        <BeneficiaryLeaf
          displayName={nodeDatum.name}
          percentOwnership={nodeDatum.attributes.percentOwnership}
          distributedValue={nodeDatum.attributes.distributedValue}
          type={nodeDatum.attributes.type}
        />
      </ForeignObjectLeaf>
    );
  }

  if (nodeDatum.attributes.type === 'Entity') {
    const trustDisplayType =
      nodeDatum.attributes.trustType &&
      entityKindToDisplayName(nodeDatum.attributes.trustType);

    return (
      <ForeignObjectLeaf displayName={nodeDatum.name}>
        <BeneficiaryLeaf
          displayName={nodeDatum.name}
          percentOwnership={nodeDatum.attributes.percentOwnership}
          distributedValue={nodeDatum.attributes.distributedValue}
          type={trustDisplayType ?? nodeDatum.attributes.type}
        />
      </ForeignObjectLeaf>
    );
  }

  return (
    <ForeignObjectLeaf displayName={nodeDatum.name}>
      <GrantorLeaf displayName={nodeDatum.name} />
    </ForeignObjectLeaf>
  );
};

function GratBeneficiaryTreeInner({
  gratTrust,
  stage,
  setRefresh,
}: Props & { setRefresh: React.Dispatch<React.SetStateAction<number>> }) {
  const [dimensions, translate, getDimensions] = useCenteredTree();

  const handleResize = useCallback(() => {
    setRefresh((prev) => prev + 1);
  }, [setRefresh]);

  const debouncedHandleResize = useDebouncedFn(handleResize, 500, {
    leading: false,
    trailing: true,
  });
  useEventListener('resize', debouncedHandleResize);

  const beneficiaryTree = useMemo(() => {
    return makeBeneficiaryTreeV2(gratTrust, stage);
  }, [gratTrust, stage]);

  const height = (dimensions?.height ?? 0) + 120;

  return (
    <Box
      sx={{
        width: '100%',
        height: `${height}px`,
      }}
    >
      <div
        style={{
          position: 'relative',
          height: `100%`,
          background: 'transparent',
          zIndex: 10,
          width: '100%',
          pointerEvents: 'none',
        }}
      >
        {!dimensions?.width && (
          <Loader
            boxProps={{
              sx: {
                textAlign: 'center',
                my: 3,
              },
            }}
          />
        )}
        <div
          style={{
            position: 'absolute',
            height: `100%`,
            top: 0,
            left: 0,
            zIndex: 1,
            width: '100%',
            // make sure the tree is centered before making visible
            visibility: dimensions?.width ? 'unset' : 'hidden',
          }}
          ref={(ref) => {
            if (ref) {
              // get visibility of the container
              const hidden = ref.style.visibility === 'hidden';
              // center the container ref
              if (hidden) {
                getDimensions(ref);
              }
            }
          }}
        >
          <Tree
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- linter refactor
            data={beneficiaryTree as unknown as RawNodeDatum}
            nodeSize={{ x: NODE_WIDTH, y: NODE_HEIGHT }}
            depthFactor={250}
            separation={{
              nonSiblings: SEPARATION_SCALE,
              siblings: SEPARATION_SCALE,
            }}
            translate={translate}
            pathFunc={'step'}
            pathClassFunc={() => 'custom-path'}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- linter refactor
            renderCustomNodeElement={(rd3tProps: any) =>
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- untyped lib / legacy linter refactor
              renderForeignObjectNode({
                ...rd3tProps,
              })
            }
            zoomable={false}
            collapsible={false}
            hasInteractiveNodes={false}
          />
        </div>
      </div>
    </Box>
  );
}

// this component is used to force a mount of the tree when the window is resized
export function GratBeneficiaryTree(props: Props) {
  const [refresh, setRefresh] = useState<number>(0);

  return (
    <GratBeneficiaryTreeInner
      {...props}
      setRefresh={setRefresh}
      key={`grat-beneficiary-tree-${refresh}`}
    />
  );
}
