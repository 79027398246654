// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PreTaxReturnLimitModal_ProposalFragment = { __typename?: 'Proposal', id: string, crtProposal?: { __typename?: 'CRTProposal', id: string, preTaxReturnPercentageHigh?: Percent | null, preTaxReturnPercentageMedium?: Percent | null, preTaxReturnPercentageLow?: Percent | null, taxDragPercentageHigh?: Percent | null, taxDragPercentageMedium?: Percent | null, taxDragPercentageLow?: Percent | null } | null, cltProposal?: { __typename?: 'CLTProposal', id: string, preTaxReturnPercentageHigh?: Percent | null, preTaxReturnPercentageMedium?: Percent | null, preTaxReturnPercentageLow?: Percent | null, taxDragPercentageHigh?: Percent | null, taxDragPercentageMedium?: Percent | null, taxDragPercentageLow?: Percent | null } | null };

export type PreTaxReturnLimitModalQueryVariables = Types.Exact<{
  proposalId: Types.Scalars['ID']['input'];
}>;


export type PreTaxReturnLimitModalQuery = { __typename?: 'Query', proposals: { __typename?: 'ProposalConnection', edges?: Array<{ __typename?: 'ProposalEdge', node?: { __typename?: 'Proposal', id: string, crtProposal?: { __typename?: 'CRTProposal', id: string, preTaxReturnPercentageHigh?: Percent | null, preTaxReturnPercentageMedium?: Percent | null, preTaxReturnPercentageLow?: Percent | null, taxDragPercentageHigh?: Percent | null, taxDragPercentageMedium?: Percent | null, taxDragPercentageLow?: Percent | null } | null, cltProposal?: { __typename?: 'CLTProposal', id: string, preTaxReturnPercentageHigh?: Percent | null, preTaxReturnPercentageMedium?: Percent | null, preTaxReturnPercentageLow?: Percent | null, taxDragPercentageHigh?: Percent | null, taxDragPercentageMedium?: Percent | null, taxDragPercentageLow?: Percent | null } | null } | null } | null> | null } };

export const PreTaxReturnLimitModal_ProposalFragmentDoc = gql`
    fragment PreTaxReturnLimitModal_Proposal on Proposal {
  id
  crtProposal {
    id
    preTaxReturnPercentageHigh
    preTaxReturnPercentageMedium
    preTaxReturnPercentageLow
    taxDragPercentageHigh
    taxDragPercentageMedium
    taxDragPercentageLow
  }
  cltProposal {
    id
    preTaxReturnPercentageHigh
    preTaxReturnPercentageMedium
    preTaxReturnPercentageLow
    taxDragPercentageHigh
    taxDragPercentageMedium
    taxDragPercentageLow
  }
}
    `;
export const PreTaxReturnLimitModalDocument = gql`
    query PreTaxReturnLimitModal($proposalId: ID!) {
  proposals(where: {id: $proposalId}) {
    edges {
      node {
        id
        ...PreTaxReturnLimitModal_Proposal
      }
    }
  }
}
    ${PreTaxReturnLimitModal_ProposalFragmentDoc}`;

/**
 * __usePreTaxReturnLimitModalQuery__
 *
 * To run a query within a React component, call `usePreTaxReturnLimitModalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreTaxReturnLimitModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreTaxReturnLimitModalQuery({
 *   variables: {
 *      proposalId: // value for 'proposalId'
 *   },
 * });
 */
export function usePreTaxReturnLimitModalQuery(baseOptions: Apollo.QueryHookOptions<PreTaxReturnLimitModalQuery, PreTaxReturnLimitModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreTaxReturnLimitModalQuery, PreTaxReturnLimitModalQueryVariables>(PreTaxReturnLimitModalDocument, options);
      }
export function usePreTaxReturnLimitModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreTaxReturnLimitModalQuery, PreTaxReturnLimitModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreTaxReturnLimitModalQuery, PreTaxReturnLimitModalQueryVariables>(PreTaxReturnLimitModalDocument, options);
        }
export function usePreTaxReturnLimitModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PreTaxReturnLimitModalQuery, PreTaxReturnLimitModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreTaxReturnLimitModalQuery, PreTaxReturnLimitModalQueryVariables>(PreTaxReturnLimitModalDocument, options);
        }
export type PreTaxReturnLimitModalQueryHookResult = ReturnType<typeof usePreTaxReturnLimitModalQuery>;
export type PreTaxReturnLimitModalLazyQueryHookResult = ReturnType<typeof usePreTaxReturnLimitModalLazyQuery>;
export type PreTaxReturnLimitModalSuspenseQueryHookResult = ReturnType<typeof usePreTaxReturnLimitModalSuspenseQuery>;
export type PreTaxReturnLimitModalQueryResult = Apollo.QueryResult<PreTaxReturnLimitModalQuery, PreTaxReturnLimitModalQueryVariables>;
export const Operations = {
  Query: {
    PreTaxReturnLimitModal: 'PreTaxReturnLimitModal' as const
  },
  Fragment: {
    PreTaxReturnLimitModal_Proposal: 'PreTaxReturnLimitModal_Proposal' as const
  }
}