// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateDocumentSummaryMutationVariables = Types.Exact<{
  documentID: Types.Scalars['ID']['input'];
  input: Types.CreateDocumentSummaryInput;
}>;


export type CreateDocumentSummaryMutation = { __typename?: 'Mutation', createDocumentSummary: { __typename?: 'DocumentSummary', id: string } };


export const CreateDocumentSummaryDocument = gql`
    mutation CreateDocumentSummary($documentID: ID!, $input: CreateDocumentSummaryInput!) {
  createDocumentSummary(documentID: $documentID, input: $input) {
    id
  }
}
    `;
export type CreateDocumentSummaryMutationFn = Apollo.MutationFunction<CreateDocumentSummaryMutation, CreateDocumentSummaryMutationVariables>;

/**
 * __useCreateDocumentSummaryMutation__
 *
 * To run a mutation, you first call `useCreateDocumentSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentSummaryMutation, { data, loading, error }] = useCreateDocumentSummaryMutation({
 *   variables: {
 *      documentID: // value for 'documentID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDocumentSummaryMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentSummaryMutation, CreateDocumentSummaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentSummaryMutation, CreateDocumentSummaryMutationVariables>(CreateDocumentSummaryDocument, options);
      }
export type CreateDocumentSummaryMutationHookResult = ReturnType<typeof useCreateDocumentSummaryMutation>;
export type CreateDocumentSummaryMutationResult = Apollo.MutationResult<CreateDocumentSummaryMutation>;
export type CreateDocumentSummaryMutationOptions = Apollo.BaseMutationOptions<CreateDocumentSummaryMutation, CreateDocumentSummaryMutationVariables>;
export const Operations = {
  Mutation: {
    CreateDocumentSummary: 'CreateDocumentSummary' as const
  }
}