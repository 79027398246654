import { find } from 'lodash';
import { ComponentType, useState } from 'react';

import { EmptyListActionCard } from '@/components/lists/EmptyListActionCard';
import { useMatchingRoutes } from '@/hooks/useMatchingRoutes';
import { useReportError } from '@/hooks/useReportError';
import { useUnguardedDispositiveProvisionsContext } from '@/modules/dispositiveProvisions/contexts/dispositiveProvisions.context';
import { ENTITY_SUBFORM_TO_SECTION_MAP } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.constants';
import { EditEntitySplitScreenOpenModalButton } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenOpenModalButton';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';

import { IconProps } from '../../../components/icons/ChevronDownIcon';
import { useEntityDetailsContext } from '../contexts/entityDetails/entityDetails.context';
import { EntityFormTrowser } from '../EntityForm/EntityFormTrowser';
import { EntityFormStage } from '../EntityForm/types';
import { useEntitySubformConfigs } from '../EntityForm/useEntitySubformConfigs';
import { EntitySubformCategory } from '../EntitySubforms/entitySubformConfigs';
import { TestamentaryEntityTab } from '../testamentaryEntities/TestamentaryEntityForm/TestamentaryEntityForm.types';
import { TestamentaryEntityModal } from '../testamentaryEntities/TestamentaryEntityForm/TestamentaryEntityModal';

export interface EmptyListEditEntityActionCardProps {
  icon?: ComponentType<IconProps>;
  heading: string;
  description: string;
  buttonText: React.ReactNode;
  editSubformCategory: EntitySubformCategory;
}

function getTestamentaryEntityInitialTabFromFormCategory(
  category: EntitySubformCategory
): TestamentaryEntityTab {
  switch (category) {
    case EntitySubformCategory.BENEFICIARIES:
      return 'beneficiaries';
    default:
      return 'trustees';
  }
}

export function EmptyListEditEntityActionCard({
  icon: Icon,
  buttonText,
  editSubformCategory,
  ...cardProps
}: EmptyListEditEntityActionCardProps) {
  const { reportError } = useReportError();

  const [editEntityModalOpen, setEditEntityModalOpen] = useState(false);
  const [editTestamentaryEntityModalOpen, setEditTestamentaryEntityModalOpen] =
    useState(false);
  const { householdId } = useHouseholdDetailsContext();
  const { entityType, entityId } = useEntityDetailsContext();
  const {
    isTestamentaryEntity,
    clientProfileOrEntityOrTestamentaryEntityId,
    isTwoClientHousehold,
    dyingPrimaryClientId,
  } = useUnguardedDispositiveProvisionsContext();

  const entitySubformConfigs = useEntitySubformConfigs(
    entityType,
    householdId,
    entityId ?? undefined,
    EntityFormStage.EDIT
  );
  const firstSubformConfigForCategory = find(
    entitySubformConfigs,
    (config) => config.category === editSubformCategory
  );

  const editEntitySplitScreenSection =
    firstSubformConfigForCategory &&
    ENTITY_SUBFORM_TO_SECTION_MAP[firstSubformConfigForCategory.type];

  // This empty ActionCard is used on the EntityDetailsPage, and the WaterfallPage SummaryPanel.
  // If we're on the waterfall page and using the new EditEntitySplitScreen modal,
  // we don't want to navigate after saving an entity section.
  const { isMatchingRoute: isOnEntityDetailsPage } = useMatchingRoutes([
    ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
  ]);

  // this pattern is a bit brittle, because it relies on context presence runtime checks.
  // as a hedge against that, we add notifications in case we create a scenario in the future
  // where we don't have required information
  function handleEmptyListClick() {
    if (isTestamentaryEntity) {
      if (
        !clientProfileOrEntityOrTestamentaryEntityId ||
        !dyingPrimaryClientId
      ) {
        reportError(
          'Missing required context data for testamentary entity',
          new Error('Missing required context data for testamentary entity'),
          {
            clientProfileOrEntityOrTestamentaryEntityId,
            dyingPrimaryClientId,
            householdId,
          }
        );
      }

      return setEditTestamentaryEntityModalOpen(true);
    }

    if (!firstSubformConfigForCategory) {
      reportError(
        `No subform config found for category ${editSubformCategory}`,
        new Error('Missing subform config for category'),
        {
          entityType,
          entityId,
          category: editSubformCategory,
        }
      );
      return;
    }

    setEditEntityModalOpen(true);
  }

  // this is just for typesafety; by the time the user is able to click the button to open the modal, we can guarantee
  // that the entity details are loaded
  const hasRequiredDetails = entityType && entityId && householdId;

  return (
    <>
      {editEntityModalOpen &&
        hasRequiredDetails &&
        firstSubformConfigForCategory && (
          <EntityFormTrowser
            stage={EntityFormStage.EDIT}
            isOpen={editEntityModalOpen}
            type={entityType}
            handleClose={() => setEditEntityModalOpen(false)}
            householdId={householdId}
            entityId={entityId}
            isHypotheticalEntity={false}
            initialFocusedSubformType={firstSubformConfigForCategory.type}
          />
        )}
      {editTestamentaryEntityModalOpen && dyingPrimaryClientId && (
        <TestamentaryEntityModal
          isOpen={editTestamentaryEntityModalOpen}
          onClose={() => setEditTestamentaryEntityModalOpen(false)}
          testamentaryEntityId={clientProfileOrEntityOrTestamentaryEntityId}
          deadClientIds={[]}
          dyingPrimaryClientId={dyingPrimaryClientId}
          isTwoClientHousehold={isTwoClientHousehold}
          initialTab={getTestamentaryEntityInitialTabFromFormCategory(
            editSubformCategory
          )}
        />
      )}
      {editEntitySplitScreenSection ? (
        <EmptyListActionCard
          icon={Icon}
          {...cardProps}
          action={
            <EditEntitySplitScreenOpenModalButton
              initialSection={editEntitySplitScreenSection}
              startIcon={Icon}
              buttonText={buttonText}
              size="sm"
              variant="primary"
              navigateAfterSave={isOnEntityDetailsPage}
            />
          }
        />
      ) : (
        <EmptyListActionCard
          icon={Icon}
          {...cardProps}
          buttonText={buttonText}
          buttonOnClick={() => handleEmptyListClick()}
        />
      )}
    </>
  );
}
