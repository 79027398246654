import {
  PieChart as MUIPieChart,
  PieChartProps as MUIPieChartProps,
} from '@mui/x-charts';

export type PieChartProps = MUIPieChartProps;

export function PieChart(props: PieChartProps) {
  return <MUIPieChart {...props} />;
}
