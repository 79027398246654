import { compact } from 'lodash';

import { AISuggestionMatch } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import {
  BasicInformation_Grantor,
  BasicInformationSubformType_WithGrantors,
} from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import { AiSuggestionKind } from '@/types/schema';

export function suggestionsToGrantorsSubform(
  matches: AISuggestionMatch[]
): BasicInformationSubformType_WithGrantors['grantors'] {
  return compact(
    matches
      .filter((m) => m.clientProfileId)
      .filter((m) => m.suggestion.kind === AiSuggestionKind.Principal)
      .filter((m) => m.suggestion.principal)
      .map(suggestionToGrantor)
  );
}

function suggestionToGrantor(
  suggestionMatch: AISuggestionMatch
): BasicInformation_Grantor | null {
  if (!suggestionMatch.clientProfileId) {
    // This shouldn't happen since we pre-filtered the matches. This is for type safety.
    return null;
  }

  return {
    clientProfileId: suggestionMatch.clientProfileId,
    isSuggestion: true,
  };
}
