import { compact, first, orderBy, uniq } from 'lodash';

import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { getRoleKindDisplayName } from '@/modules/professionalTeam/ClientProfessionalTeam.copy';
import { formatEnumCase } from '@/utils/formatting/strings';
import { getNodes } from '@/utils/graphqlUtils';

import {
  ClientPeopleListViewTable_ClientOrganizationFragment,
  ClientPeopleListViewTable_ClientProfileFragment,
  ClientPeopleListViewTableQuery,
} from './graphql/ClientPeopleListViewTable.generated';

export const ROW_TYPE_INDIVIDUAL = 'INDIVIDUAL' as const;
export const ROW_TYPE_ORGANIZATION = 'ORGANIZATION' as const;

export interface RowType {
  lineOne: string;
  lineTwo: string;
  id: string;
  kind: typeof ROW_TYPE_INDIVIDUAL | typeof ROW_TYPE_ORGANIZATION;
}

function mapIndividualToRowType(
  individual: ClientPeopleListViewTable_ClientProfileFragment
): RowType {
  let lineTwo = '';
  if (individual.isPrimary) {
    lineTwo = 'Client';
  }

  const professionalTeamRoles = getNodes(individual.professionalTeamRoles);
  if (professionalTeamRoles.length) {
    const professionalTeamRolesCopy = professionalTeamRoles.map((role) =>
      getRoleKindDisplayName({
        kind: role.kind,
        otherRoleName: role.otherRoleName || '',
        powerOfAttorneyKind: role.powerOfAttorneyKind,
      })
    );
    // only show each professional team role once, enforce with `_.uniq`
    lineTwo = uniq(compact([lineTwo, ...professionalTeamRolesCopy])).join(', ');
  }

  return {
    lineOne: individual.legalName,
    lineTwo,
    id: individual.id,
    kind: ROW_TYPE_INDIVIDUAL,
  };
}

function mapOrganizationToRowType(
  organization: ClientPeopleListViewTable_ClientOrganizationFragment
): RowType {
  return {
    lineOne: organization.name,
    lineTwo: formatEnumCase(organization.kind) || '',
    id: organization.id,
    kind: ROW_TYPE_ORGANIZATION,
  };
}

export function mapDataToTableRows(
  data: ClientPeopleListViewTableQuery | undefined
): RowType[] {
  const advisorClient = first(getNodes(data?.households));
  if (!advisorClient) {
    return [];
  }

  const individuals = compact(advisorClient.clientProfiles || []).map(
    mapIndividualToRowType
  );
  const organizations = compact(advisorClient.clientOrganizations || []).map(
    mapOrganizationToRowType
  );

  return orderBy([...individuals, ...organizations], 'lineOne');
}

export const TABLE_COLUMNS: Column<RowType>[] = [
  {
    headerName: 'Name',
    renderCell: TwoLineTextRenderer({
      lineOne: ({ row }) => row.lineOne,
      lineTwo: ({ row }) => row.lineTwo,
    }),
    flex: 1,
    field: 'lineOne',
  },
  {
    headerName: '',
    field: 'id',
    renderCell: IconRenderer({
      icon: Edit02Icon,
    }),
    align: 'center',
    width: 64,
    minWidth: 64,
    maxWidth: 64,
    sortable: false,
  },
];
