import Decimal from 'decimal.js';
import { compact, first, isEmpty } from 'lodash';

import { IntegrationEntityExternalKind } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import {
  EntityImportEntityMap,
  EntityImportHouseholdMap,
  EntityImportTableEntity,
  EntityImportTableForm,
  NAMESPACE,
} from '../EntityImportTable.types';
import {
  AddeparAllHouseholdsEntityImportTableQuery,
  AddeparEntityImportTable_IntegrationEntityFragment,
} from './graphql/AddeparEntityImportTable.generated';

export function mapAddeparEntityQueryResponseToTable(
  data: AddeparAllHouseholdsEntityImportTableQuery
): EntityImportTableForm {
  if (!data) {
    return {
      [NAMESPACE]: { householdMap: {} },
    };
  }

  const unlinkedEntities = getNodes(data.integrationEntities);
  const householdMap: EntityImportHouseholdMap = compact(
    getNodes(data.households)
  ).reduce<EntityImportTableForm[typeof NAMESPACE]['householdMap']>(
    (householdAcc, household) => {
      const householdIntegrationClientIds = compact(
        household.integrationClients?.map((c) => c.id)
      );

      // if there's only one client profile, defualt to that
      const defaultOwnerID: string =
        household.possiblePrimaryClients.length === 1
          ? first(household.possiblePrimaryClients)?.id || ''
          : '';

      const entityMap: EntityImportEntityMap = unlinkedEntities
        .filter(
          (entity) =>
            entity.integrationClient &&
            householdIntegrationClientIds.includes(entity.integrationClient.id)
        )
        .sort((a, b) =>
          a.value?.greaterThan(b.value ?? new Decimal(0)) ? -1 : 1
        )
        .reduce<Record<string, EntityImportTableEntity>>(
          (entityAcc, entity) => {
            const additionalEntities = getAllDescendentEntities(entity, {
              defaultOwnerID,
            });

            return {
              ...entityAcc,
              ...additionalEntities,
            };
          },
          {}
        );

      // don't include clients without entities
      if (Object.keys(entityMap).length) {
        householdAcc[household.id] = {
          luminaryClientID: household.id,
          entityMap,
          displayName: household.displayName,
          grantors: household.possiblePrimaryClients.map((client) => ({
            displayName: client.displayName,
            id: client.id,
          })),
        };
      }
      return householdAcc;
    },
    {}
  );
  return {
    [NAMESPACE]: {
      householdMap,
    },
  };
}

interface GetAllDescendentEntitiesOptions {
  defaultOwnerID: string;
}

function getAllDescendentEntities(
  entity: AddeparEntityImportTable_IntegrationEntityFragment,
  opts: GetAllDescendentEntitiesOptions
): Record<string, EntityImportTableEntity> {
  return getAllDescendentEntitiesRecursive(entity, null, {}, 0, 0, opts);
}

function getAllDescendentEntitiesRecursive(
  entity: AddeparEntityImportTable_IntegrationEntityFragment,
  // parentEntityId will be null for the top-level entity, and a string otherwise.
  parentEntityId: string | null,
  acc: Record<string, EntityImportTableEntity>,
  level: number,
  levelIndex: number,
  opts: GetAllDescendentEntitiesOptions
): Record<string, EntityImportTableEntity> {
  const hasChildren = !isEmpty(entity.childEntities);
  acc[entity.id] = {
    shouldImport: false,
    integrationEntityId: entity.id,
    remoteName: entity.name,
    displayName: entity.name,
    entityValue: entity.value ?? null,
    externalKind:
      entity.externalKind ??
      IntegrationEntityExternalKind.AddeparHoldingAccount,
    entityKind: '',
    commaSeparatedOwnerIDs: opts.defaultOwnerID,
    parentEntityId,
    level,
    levelIndex,
    hasChildren,
  };

  if (hasChildren) {
    const sortedChildren = [...(entity.childEntities ?? [])].sort((a, b) => {
      const valueA = a.value?.toNumber() ?? 0;
      const valueB = b.value?.toNumber() ?? 0;
      return valueB - valueA; // Sort descending
    });

    sortedChildren.forEach((childEntity, i) => {
      getAllDescendentEntitiesRecursive(
        childEntity,
        parentEntityId || entity.id,
        acc,
        level + 1,
        i,
        opts
      );
    });
  }

  return acc;
}
