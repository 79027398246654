import { Stack, Typography } from '@mui/material';

export interface SummaryTableRow {
  label: React.ReactNode;
  value: React.ReactNode;
}

export interface SummaryTableProps {
  rows: SummaryTableRow[];
}

export function SummaryTable({ rows }: SummaryTableProps) {
  return (
    <Stack spacing={1}>
      {rows.map((row, i) => {
        return (
          <Stack
            key={i}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle2" component="label">
              {row.label}
            </Typography>
            <Typography variant="h6" component="span">
              {row.value}
            </Typography>
          </Stack>
        );
      })}
    </Stack>
  );
}
