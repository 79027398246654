import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { NavLink } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { Alert } from '@/components/notifications/Alert/Alert';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { useCreateHouseholdMutation } from '@/modules/household/graphql/Households.generated';

import {
  ClientBuilderFormShape,
  getInputsForFormValues,
} from './clientBuilderUtils';

export function ClientBuilderCard() {
  const { showFeedback } = useFeedback();
  const currentUser = useCurrentUser();
  const { reportError } = useReportError();
  const [createdHouseholdLink, setCreatedHouseholdLink] = useState<
    string | null
  >(null);
  const [createHousehold, { loading: submittingForm }] =
    useCreateHouseholdMutation({
      onError: (err) => {
        showFeedback('Failed to create client');
        reportError('could not create client', err);
      },
      refetchQueries: ['UserHasCreatedHouseholdsQuery'],
    });

  const formMethods = useForm<ClientBuilderFormShape>();
  const { control, handleSubmit } = formMethods;

  async function onSubmit(formValues: ClientBuilderFormShape) {
    setCreatedHouseholdLink(null);
    // sync form state to local storage so it persists across page refreshes
    const createInput = getInputsForFormValues(formValues, currentUser?.id);
    const res = await createHousehold({
      variables: { input: createInput },
    });

    if (res.data?.createHousehold) {
      const clientLink = `/households/${res.data.createHousehold.id}`;
      setCreatedHouseholdLink(clientLink);
    }
  }

  return (
    <FormProvider {...formMethods}>
      <HeaderCard heading="Client builder">
        <Stack
          spacing={2}
          noValidate
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormAwareTextInput<ClientBuilderFormShape>
            label="Last name"
            fieldName={'lastName'}
            required
            disabled={submittingForm}
            control={control}
          />
          <FormAwareCheckbox<ClientBuilderFormShape>
            label="Single grantor?"
            fieldName={'singleGrantor'}
            required
            disabled={submittingForm}
            control={control}
          />
          <Button
            disabled={submittingForm}
            variant="primary"
            size="md"
            type="submit"
          >
            Create client
          </Button>
          {createdHouseholdLink && (
            <Alert size="sm" severity="success">
              <NavLink to={createdHouseholdLink}>
                <Typography variant="body1">
                  Go to the newly-created client {'→'}
                </Typography>
              </NavLink>
            </Alert>
          )}
        </Stack>
      </HeaderCard>
    </FormProvider>
  );
}
