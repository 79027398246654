import { getYear } from 'date-fns';
import { compact, uniqBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import {
  SelectInputComponentOption,
  SelectInputOption,
} from '@/components/form/baseInputs/inputTypes';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { useFormContext } from '@/components/react-hook-form';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';

import { useHypotheticalTransferModalContext } from '../../../HypotheticalTransferModal/HypotheticalTransferModal.context';
import {
  AnnuallyRecurringValue,
  HypotheticalTransferFormModalShape,
} from '../../../HypotheticalTransferModal/HypotheticalTransferModal.types';

export type PolicyOption =
  | SelectInputComponentOption<undefined>
  | SelectInputOption<string>;

interface UsePolicyOptionsInput {
  entityId: string;
}

export function usePolicyOptions({ entityId }: UsePolicyOptionsInput): {
  policyOptions: PolicyOption[];
  hasPolicyOptions: boolean;
} {
  const {
    derivedData: { policiesByEntityId },
  } = useHypotheticalTransferModalContext();

  const { control, setValue } =
    useFormContext<HypotheticalTransferFormModalShape>();

  const selectedSourceInsurancePolicyId = useWatch({
    control,
    name: 'sourceInsurancePolicyId',
  });

  const { policyOptions, hasPolicyOptions } = useMemo(() => {
    const policiesForEntity = policiesByEntityId[entityId] || [];

    const hasPolicyOptions = !!policiesForEntity.length;

    const policiesForEntityOptions = uniqBy(
      policiesForEntity
        .map((policy) => ({
          display: policy.display,
          value: policy.sourceInsurancePolicyId,
        }))
        .sort((a, b) => a.display.localeCompare(b.display)),
      'value'
    );

    const policyOptions = compact([
      {
        display: EMPTY_CONTENT_HYPHEN,
        value: '',
      },
      (() =>
        policiesForEntity.length
          ? {
              component: <SelectItemGroupLabel label="Insurance Policy" />,
              type: 'component' as const,
            }
          : null)(),

      ...policiesForEntityOptions,
    ]);

    return { policyOptions, hasPolicyOptions };
  }, [entityId, policiesByEntityId]);

  useEffect(
    function setYearOptions() {
      if (selectedSourceInsurancePolicyId) {
        setValue('transferFrequency', AnnuallyRecurringValue.false);
        setValue('transferStartYear', getYear(new Date()));
        setValue('transferEndYear', getYear(new Date()));
        setValue('_yearOptionsDisabled', true);
      } else {
        setValue('_yearOptionsDisabled', false);
      }
    },
    [selectedSourceInsurancePolicyId, setValue]
  );

  return {
    policyOptions,
    hasPolicyOptions,
  };
}
