/* eslint-disable react/display-name */
import {
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import { SetOptional } from 'type-fest';

import {
  Button as ButtonExternal,
  ButtonProps as ButtonPropsExternal,
} from '@/components/form/baseInputs/Button';

import { CellContainer } from '../../components/cells';
import { CellRenderProp } from '../../types';
import { getCellRenderProp } from '../../utils/getRenderProp';

interface ButtonRendererProps<
  R extends GridValidRowModel,
  V,
  F,
  N extends GridTreeNodeWithRender,
> {
  buttonProps: CellRenderProp<R, V, F, N, ButtonProps<R>>;
}

type RelevantButtonProps = SetOptional<
  Omit<ButtonPropsExternal, 'onClick' | 'variant'>,
  'size'
>;

interface ButtonProps<R extends GridValidRowModel> extends RelevantButtonProps {
  handleClick: (row: R) => void;
  label: (row: R) => React.ReactNode;
  variant: (row: R) => ButtonPropsExternal['variant'];
}

function Button<R extends GridValidRowModel = GridValidRowModel>({
  props,
  row,
}: {
  props?: ButtonProps<R>;
  row: R;
}) {
  const { handleClick, label, variant, ...otherProps } = props ?? {};
  return (
    <ButtonExternal
      size="sm"
      variant={variant?.(row) ?? 'primary'}
      onClick={() => handleClick?.(row)}
      {...otherProps}
    >
      {label?.(row)}
    </ButtonExternal>
  );
}

export const ButtonRenderer = <
  R extends GridValidRowModel = GridValidRowModel,
  V extends string = string,
  F = V,
  N extends GridTreeNodeWithRender = GridTreeNodeWithRender,
>(
  factoryProps?: ButtonRendererProps<R, V, F, N>
) => {
  return (props: GridRenderCellParams<R, V, F, N>) => {
    const buttonProps = getCellRenderProp({
      props,
      prop: factoryProps?.buttonProps,
      defaultValue: undefined,
    });

    return (
      <CellContainer align={props.colDef.align}>
        <Button props={buttonProps} row={props.row} />
      </CellContainer>
    );
  };
};
