import { Box, Stack, Typography } from '@mui/material';

import { Accordion } from '@/components/Accordion/Accordion';
import { Button } from '@/components/form/baseInputs/Button';
import { Download01Icon } from '@/components/icons/Download01Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { $downloadFileFromURL } from '@/modules/files/utils/fileUtils';

import { ClientEntityCSVUpload } from './ClientEntityCSVUpload';
import { useGetCurrentHouseholdAccountsMappingMutation } from './graphql/ClientEntityCSVUploadDownload.generated';

interface ClientEntityCSVUploadDownloadProps {
  onUploadComplete: () => void;
}

export function ClientEntityCSVUploadDownload({
  onUploadComplete,
}: ClientEntityCSVUploadDownloadProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [downloadMapping, { loading: downloadingFile }] =
    useGetCurrentHouseholdAccountsMappingMutation({
      onError: (err) => {
        showFeedback(
          `Failed to download mapping. Please refresh the page and try again.`
        );
        reportError(
          'Failed to download household/account mapping for csv upload',
          err
        );
      },
      onCompleted: async (res) => {
        try {
          await $downloadFileFromURL(
            res.exportHouseholdAccounts?.download.downloadURL,
            'luminary_household_account_mapping.csv'
          );
        } catch (err) {
          showFeedback('Failed to download CSV. Please try again.');
        }
      },
    });

  return (
    <Accordion
      variant="default"
      title="Optional: Use a spreadsheet to upload IDs"
      subtitle="If you have a large number of accounts to connect, you can link IDs and Luminary entities using a CSV."
    >
      <Stack spacing={3} direction="row">
        <Box width="40%">
          <Typography variant="h4" mb={0.5}>
            First, download the list of entities:
          </Typography>
          <Button
            loading={downloadingFile}
            onClick={() => downloadMapping()}
            variant="primary"
            startIcon={Download01Icon}
            size="md"
          >
            Download
          </Button>
        </Box>
        <Box width="60%">
          <Typography variant="h4" mb={0.5}>
            Then, edit the CSV file and re-upload it:
          </Typography>
          <ClientEntityCSVUpload onUploadComplete={onUploadComplete} />
        </Box>
      </Stack>
    </Accordion>
  );
}
