import Decimal from 'decimal.js';
import { useMemo } from 'react';

import { useChartColorDefinitions } from '@/components/charts/constants';
import { ClientDetailsGiftingPage_HouseholdFragment } from '@/modules/gifting/graphql/ClientDetailsGiftingPage.generated';

import {
  createClampNegativeValuesToZero,
  getGSTExemptionSections,
  getLifetimeExemptionSections,
  ZERO_DECIMAL,
} from './useGiftingExemptionsForClient.utils';
import { useGSTExemptionAmount } from './useGSTExemptionAmount';
import { useLifetimeExemptionAmount } from './useLifetimeExemptionAmount';

export function useGiftingExemptionsForClient({
  household,
  returnNegativeValues = true,
  groupByGrantor = true,
}: {
  household?: ClientDetailsGiftingPage_HouseholdFragment;
  returnNegativeValues?: boolean;
  groupByGrantor?: boolean;
}) {
  const colors = useChartColorDefinitions();
  const numGrantors = household?.possiblePrimaryClients.length ?? 0;
  const possiblePrimaryClients = useMemo(() => {
    return household?.possiblePrimaryClients ?? [];
  }, [household?.possiblePrimaryClients]);
  const clampNegativeValuesToZero = useMemo(() => {
    return createClampNegativeValuesToZero(returnNegativeValues);
  }, [returnNegativeValues]);

  const grantorIdToExemptionsMap: Record<
    string,
    {
      lifetimeExemptionAmount: Decimal;
      gstExemptionAmount: Decimal;
    }
  > = useMemo(() => ({}), []);

  const {
    forAllGrantors: lifetimeExemptionAmountForAllGrantors,
    perGrantor: lifetimeExemptionAmountPerGrantor,
  } = useLifetimeExemptionAmount({ numGrantors });

  const {
    forAllGrantors: gstExemptionAmountForAllGrantors,
    perGrantor: gstExemptionAmountPerGrantor,
  } = useGSTExemptionAmount({ numGrantors });

  // get the total of used lifetime exemption for all grantors
  const lifetimeExclusionUsedTotal = useMemo(() => {
    return possiblePrimaryClients.reduce((acc, grantor) => {
      return acc.plus(grantor.lifetimeExclusionUsed ?? 0);
    }, ZERO_DECIMAL);
  }, [possiblePrimaryClients]);

  // get the total of used GST exemption for all grantors
  const gstExclusionUsedTotal = useMemo(() => {
    return possiblePrimaryClients.reduce((acc, grantor) => {
      return acc.plus(grantor.gstExclusionUsed ?? 0);
    }, ZERO_DECIMAL);
  }, [possiblePrimaryClients]);

  // get the sections for the lifetime exemption chart
  const lifetimeExemptionSections = useMemo(() => {
    return getLifetimeExemptionSections({
      colors,
      clampNegativeValuesToZero,
      lifetimeExemptionAmountForAllGrantors,
      possiblePrimaryClients,
      lifetimeExclusionUsedTotal,
      groupByGrantor,
      lifetimeExemptionAmountPerGrantor,
      grantorIdToExemptionsMap,
    });
  }, [
    clampNegativeValuesToZero,
    colors,
    grantorIdToExemptionsMap,
    groupByGrantor,
    lifetimeExclusionUsedTotal,
    lifetimeExemptionAmountForAllGrantors,
    lifetimeExemptionAmountPerGrantor,
    possiblePrimaryClients,
  ]);

  // get the sections for the GST exemption chart
  const gstExemptionSections = useMemo(() => {
    return getGSTExemptionSections({
      colors,
      clampNegativeValuesToZero,
      gstExemptionAmountForAllGrantors,
      possiblePrimaryClients,
      gstExclusionUsedTotal,
      groupByGrantor,
      gstExemptionAmountPerGrantor,
      grantorIdToExemptionsMap,
    });
  }, [
    clampNegativeValuesToZero,
    colors,
    grantorIdToExemptionsMap,
    groupByGrantor,
    gstExclusionUsedTotal,
    gstExemptionAmountForAllGrantors,
    gstExemptionAmountPerGrantor,
    possiblePrimaryClients,
  ]);

  const availableLifetimeExemption = clampNegativeValuesToZero(
    lifetimeExemptionAmountForAllGrantors?.minus(lifetimeExclusionUsedTotal)
  );
  const availableGstExemption = clampNegativeValuesToZero(
    gstExemptionAmountForAllGrantors?.minus(gstExclusionUsedTotal)
  );

  return {
    lifetimeExemptionSections,
    gstExemptionSections,
    availableLifetimeExemption,
    availableGstExemption,
    grantorIdToExemptionsMap,
  };
}
