import { Divider, Stack, Typography } from '@mui/material';
import { chunk, isEmpty } from 'lodash';
import { Fragment, useMemo } from 'react';

import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { NO_GIFTING_SENTINEL } from '@/modules/gifting/proposal/designer/form';
import { ProposalFragment } from '@/modules/proposal/graphql/ProposalFragment.generated';
import {
  GiftingProposal_GiftingScenarioFragment,
  GiftProposalFragment,
} from '@/pages/proposal/graphql/GiftProposalFragment.generated';
import { COLORS } from '@/styles/tokens/colors';
import { GiftingProposalCashFlowCashFlowType } from '@/types/schema';
import { formatCurrency } from '@/utils/formatting/currency';

import {
  DataCell,
  DataItem,
  ScenarioCard,
  ScenarioTitle,
  ScenarioTitleRow,
} from './GiftOverview.components';
import {
  dateRangeOrStartYear,
  formatPercentGrowthOrDiscount,
  getGiftDetailLabel,
  getGiftLabelProps,
} from './GiftOverview.utils';

interface ScenarioProps {
  scenario: GiftingProposal_GiftingScenarioFragment;
  idx: number;
}

function Scenario({ scenario, idx }: ScenarioProps) {
  const hasScenarioGifts = !isEmpty(scenario.scenarioGifts);
  const hasScenarioCashFlows = !isEmpty(scenario.scenarioCashFlows);
  const doesExemptionSunset = !!scenario.exemptionSunsets;

  return (
    <ScenarioCard>
      <ScenarioTitleRow pb={hasScenarioGifts || hasScenarioCashFlows ? 3 : 0}>
        <Stack spacing={0.5}>
          <ScenarioTitle>
            {scenario.displayName === NO_GIFTING_SENTINEL
              ? 'Baseline'
              : scenario.displayName}
          </ScenarioTitle>
          {doesExemptionSunset && (
            <Badge
              variant={BadgeVariants.Primary}
              display="Exemption sunsets"
            />
          )}
        </Stack>
        <Typography variant="subtitle2">{`Scenario ${idx + 1}`}</Typography>
      </ScenarioTitleRow>
      {hasScenarioGifts && (
        <Divider
          sx={{
            borderColor: COLORS.GRAY[300],
          }}
        />
      )}
      {hasScenarioGifts && (
        <DataCell label="Gifts">
          <Stack spacing={1}>
            {scenario.scenarioGifts!.map((gift, giftIdx) => (
              <DataItem
                testId={`GiftOverviewScenarios-Scenario-${idx}-${giftIdx}`}
                key={gift.id}
                label={gift.displayName}
                {...getGiftLabelProps({
                  amount: gift.amount,
                  discountPercentage: gift.discount
                    ? gift.discountPercentage
                    : null,
                })}
                subItemLeft1={dateRangeOrStartYear({
                  startYear: gift.startYear,
                  endYear: gift.endYear,
                })}
                subItemLeft2={getGiftDetailLabel({
                  nonTaxableGiftType: gift.nonTaxableGiftType,
                  isTaxable: gift.isTaxable,
                  gifters:
                    gift.senders?.map((s) => s.grantor.displayName) ?? [],
                })}
                subItemRight1={formatPercentGrowthOrDiscount({
                  percent: gift.growthPercentage,
                  isGrowth: true,
                })}
                subItemRight2={formatPercentGrowthOrDiscount({
                  percent: gift.discount ? gift.discountPercentage : null,
                  isDiscount: true,
                })}
              />
            ))}
          </Stack>
        </DataCell>
      )}
      {scenario.displayName !== NO_GIFTING_SENTINEL && hasScenarioCashFlows && (
        <Divider
          sx={{
            borderColor: COLORS.GRAY[300],
          }}
        />
      )}
      {hasScenarioCashFlows && (
        <DataCell label="Income and expenses">
          <Stack spacing={1}>
            {scenario.scenarioCashFlows!.map((cf) => (
              <DataItem
                key={cf.id}
                label={cf.displayName}
                primaryValue={formatCurrency(
                  cf.amount.times(
                    cf.cashFlowType ===
                      GiftingProposalCashFlowCashFlowType.Expense
                      ? -1
                      : 1
                  ),
                  {
                    currencySign: 'accounting',
                  }
                )}
                subItemLeft1={dateRangeOrStartYear({
                  startYear: cf.startYear,
                  endYear: cf.endYear,
                })}
                subItemLeft2={
                  cf.portfolio?.displayName ?? 'In-estate portfolio'
                }
              />
            ))}
          </Stack>
        </DataCell>
      )}
    </ScenarioCard>
  );
}

interface GiftOverviewScenariosProps {
  proposal: ProposalFragment;
}

export function GiftOverviewScenarios({
  proposal,
}: GiftOverviewScenariosProps) {
  const giftingProposal = proposal.giftingProposal;
  const viewOnly = useViewOnly();

  const scenarios = useMemo(() => {
    if (viewOnly) {
      return chunk(giftingProposal?.giftingScenarios ?? [], 2);
    }

    return giftingProposal?.giftingScenarios ?? [];
  }, [giftingProposal?.giftingScenarios, viewOnly]);

  const isChunked = Array.isArray(scenarios?.[0]);

  return (
    <>
      <Stack direction="row" pb={3}>
        <Typography variant="h2">Scenarios</Typography>
      </Stack>
      <Stack spacing={3}>
        {isChunked
          ? (scenarios as GiftProposalFragment['giftingScenarios'][])?.map(
              (scenariosChunk, idx) => {
                if (!scenariosChunk) {
                  return null;
                }

                const scenariosChunkLength = scenariosChunk.length;
                const isLast = idx === scenariosChunkLength - 1;

                return (
                  <Fragment key={`chunk-${idx}`}>
                    {scenariosChunk.map((scenario, idx) => (
                      <Scenario
                        scenario={scenario}
                        key={scenario.id}
                        idx={idx}
                      />
                    ))}
                    {!isLast && <div className="pagebreak" />}
                  </Fragment>
                );
              }
            )
          : (scenarios as GiftProposalFragment['giftingScenarios'])?.map(
              (scenario, idx) => (
                <Scenario scenario={scenario} key={scenario.id} idx={idx} />
              )
            )}
      </Stack>
    </>
  );
}
