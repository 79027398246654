import {
  array,
  assign,
  Describe,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreateSoleProprietorshipBusinessEntityInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateSoleProprietorshipBusinessEntityInput,
} from '@/types/schema';

import {
  commonCreateBusinessEntityProperties,
  commonCreateBusinessEntityTopLevelProperties,
  commonUpdateBusinessEntityEntityUpdateProperties,
  commonUpdateBusinessEntityProperties,
  commonUpdateBusinessEntityTopLevelProperties,
  keyPersonSchema,
} from './businessEntityTypes';
import {
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  principalWithOwnershipSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const createSoleProprietorshipSchema: Describe<AugmentedCreateSoleProprietorshipBusinessEntityInput> =
  object({
    create: commonCreateBusinessEntityProperties,
    withOwners: optional(nullable(array(principalWithOwnershipSchema))),
    withKeyPeople: optional(nullable(array(keyPersonSchema))),
    withDesignerAccount: optional(nullable(createAccountSchema)),
  });

const updateSoleProprietorshipSchema: Describe<AugmentedUpdateSoleProprietorshipBusinessEntityInput> =
  object({
    id: string(),
    update: commonUpdateBusinessEntityProperties,
    withOwners: optional(nullable(array(principalWithOwnershipSchema))),
    withKeyPeople: optional(nullable(array(keyPersonSchema))),
    updateDesignerAccount: optional(nullable(updateAccountSchema)),
  });

const createSchema: Describe<AugmentedCreateEntityInput> = assign(
  object({
    create: createEntityInput,
    withSoleProprietorshipBusinessEntity: optional(
      nullable(createSoleProprietorshipSchema)
    ),
    withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
  }),
  commonCreateBusinessEntityTopLevelProperties
);

const updateSchema: Describe<AugmentedUpdateEntityInput> = assign(
  object({
    id: string(),
    update: commonUpdateBusinessEntityEntityUpdateProperties,
    updateSoleProprietorshipBusinessEntity: optional(
      nullable(updateSoleProprietorshipSchema)
    ),
    withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
  }),
  commonUpdateBusinessEntityTopLevelProperties
);

export function validateSoleProprietorshipCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'SoleProprietorship create');
}

export function validateSoleProprietorshipUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'SoleProprietorship update');
}
