// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from '../../../../graphql/fragments/pageInfo.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminSubBrandsPage_SubBrandFragment = { __typename?: 'SubBrand', id: string, displayName: string, households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, displayName: string } | null } | null> | null } };

export type GetSubBrandsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.SubBrandWhereInput>;
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['Cursor']['input']>;
}>;


export type GetSubBrandsQuery = { __typename?: 'Query', paginatedData: { __typename?: 'SubBrandConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: any | null, endCursor?: any | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'SubBrandEdge', node?: { __typename?: 'SubBrand', id: string, displayName: string, households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, displayName: string } | null } | null> | null } } | null } | null> | null } };

export const AdminSubBrandsPage_SubBrandFragmentDoc = gql`
    fragment AdminSubBrandsPage_SubBrand on SubBrand {
  id
  displayName
  households(first: 6, orderBy: {direction: ASC, field: created_at}) {
    edges {
      node {
        id
        displayName
      }
    }
  }
}
    `;
export const GetSubBrandsDocument = gql`
    query GetSubBrands($where: SubBrandWhereInput, $first: Int!, $after: Cursor) {
  paginatedData: subBrands(where: $where, first: $first, after: $after) {
    totalCount
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        id
        ...AdminSubBrandsPage_SubBrand
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${AdminSubBrandsPage_SubBrandFragmentDoc}`;

/**
 * __useGetSubBrandsQuery__
 *
 * To run a query within a React component, call `useGetSubBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubBrandsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetSubBrandsQuery(baseOptions: Apollo.QueryHookOptions<GetSubBrandsQuery, GetSubBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubBrandsQuery, GetSubBrandsQueryVariables>(GetSubBrandsDocument, options);
      }
export function useGetSubBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubBrandsQuery, GetSubBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubBrandsQuery, GetSubBrandsQueryVariables>(GetSubBrandsDocument, options);
        }
export function useGetSubBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSubBrandsQuery, GetSubBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSubBrandsQuery, GetSubBrandsQueryVariables>(GetSubBrandsDocument, options);
        }
export type GetSubBrandsQueryHookResult = ReturnType<typeof useGetSubBrandsQuery>;
export type GetSubBrandsLazyQueryHookResult = ReturnType<typeof useGetSubBrandsLazyQuery>;
export type GetSubBrandsSuspenseQueryHookResult = ReturnType<typeof useGetSubBrandsSuspenseQuery>;
export type GetSubBrandsQueryResult = Apollo.QueryResult<GetSubBrandsQuery, GetSubBrandsQueryVariables>;
export const Operations = {
  Query: {
    GetSubBrands: 'GetSubBrands' as const
  },
  Fragment: {
    AdminSubBrandsPage_SubBrand: 'AdminSubBrandsPage_SubBrand' as const
  }
}