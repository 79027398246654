// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetGiftDesignerBasicInformationFormData_ProposalFragment = { __typename?: 'Proposal', id: string, displayName: string, giftingProposal?: { __typename?: 'GiftingProposal', id: string, status: Types.GiftingProposalStatus, lengthOfAnalysis: number, inEstatePortfolioValue: CurrencyUSD, exemptionGrowthRate?: Percent | null, outOfEstatePortfolios?: Array<{ __typename?: 'GiftingProposalPortfolio', id: string, displayName: string, portfolioType: Types.GiftingProposalPortfolioPortfolioType, startingAssetValue: CurrencyUSD }> | null, baseCashFlows?: Array<{ __typename?: 'GiftingProposalCashFlow', id: string, annuallyRecurring?: boolean | null, amount: CurrencyUSD, cashFlowType: Types.GiftingProposalCashFlowCashFlowType, displayName: string, endYear?: number | null, growthPercentage?: Percent | null, order: number, startYear: number }> | null } | null };

export type GetGiftDesignerBasicInformationFormDataQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ProposalWhereInput>;
}>;


export type GetGiftDesignerBasicInformationFormDataQuery = { __typename?: 'Query', proposals: { __typename?: 'ProposalConnection', edges?: Array<{ __typename?: 'ProposalEdge', node?: { __typename?: 'Proposal', id: string, displayName: string, giftingProposal?: { __typename?: 'GiftingProposal', id: string, status: Types.GiftingProposalStatus, lengthOfAnalysis: number, inEstatePortfolioValue: CurrencyUSD, exemptionGrowthRate?: Percent | null, outOfEstatePortfolios?: Array<{ __typename?: 'GiftingProposalPortfolio', id: string, displayName: string, portfolioType: Types.GiftingProposalPortfolioPortfolioType, startingAssetValue: CurrencyUSD }> | null, baseCashFlows?: Array<{ __typename?: 'GiftingProposalCashFlow', id: string, annuallyRecurring?: boolean | null, amount: CurrencyUSD, cashFlowType: Types.GiftingProposalCashFlowCashFlowType, displayName: string, endYear?: number | null, growthPercentage?: Percent | null, order: number, startYear: number }> | null } | null } | null } | null> | null } };

export const GetGiftDesignerBasicInformationFormData_ProposalFragmentDoc = gql`
    fragment GetGiftDesignerBasicInformationFormData_Proposal on Proposal {
  id
  displayName
  giftingProposal {
    id
    status
    lengthOfAnalysis
    inEstatePortfolioValue
    exemptionGrowthRate
    outOfEstatePortfolios {
      id
      displayName
      portfolioType
      startingAssetValue
    }
    baseCashFlows {
      id
      annuallyRecurring
      amount
      cashFlowType
      displayName
      endYear
      growthPercentage
      order
      startYear
    }
  }
}
    `;
export const GetGiftDesignerBasicInformationFormDataDocument = gql`
    query GetGiftDesignerBasicInformationFormData($where: ProposalWhereInput) {
  proposals(where: $where) {
    edges {
      node {
        id
        ...GetGiftDesignerBasicInformationFormData_Proposal
      }
    }
  }
}
    ${GetGiftDesignerBasicInformationFormData_ProposalFragmentDoc}`;

/**
 * __useGetGiftDesignerBasicInformationFormDataQuery__
 *
 * To run a query within a React component, call `useGetGiftDesignerBasicInformationFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftDesignerBasicInformationFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftDesignerBasicInformationFormDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetGiftDesignerBasicInformationFormDataQuery(baseOptions?: Apollo.QueryHookOptions<GetGiftDesignerBasicInformationFormDataQuery, GetGiftDesignerBasicInformationFormDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiftDesignerBasicInformationFormDataQuery, GetGiftDesignerBasicInformationFormDataQueryVariables>(GetGiftDesignerBasicInformationFormDataDocument, options);
      }
export function useGetGiftDesignerBasicInformationFormDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiftDesignerBasicInformationFormDataQuery, GetGiftDesignerBasicInformationFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiftDesignerBasicInformationFormDataQuery, GetGiftDesignerBasicInformationFormDataQueryVariables>(GetGiftDesignerBasicInformationFormDataDocument, options);
        }
export function useGetGiftDesignerBasicInformationFormDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGiftDesignerBasicInformationFormDataQuery, GetGiftDesignerBasicInformationFormDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGiftDesignerBasicInformationFormDataQuery, GetGiftDesignerBasicInformationFormDataQueryVariables>(GetGiftDesignerBasicInformationFormDataDocument, options);
        }
export type GetGiftDesignerBasicInformationFormDataQueryHookResult = ReturnType<typeof useGetGiftDesignerBasicInformationFormDataQuery>;
export type GetGiftDesignerBasicInformationFormDataLazyQueryHookResult = ReturnType<typeof useGetGiftDesignerBasicInformationFormDataLazyQuery>;
export type GetGiftDesignerBasicInformationFormDataSuspenseQueryHookResult = ReturnType<typeof useGetGiftDesignerBasicInformationFormDataSuspenseQuery>;
export type GetGiftDesignerBasicInformationFormDataQueryResult = Apollo.QueryResult<GetGiftDesignerBasicInformationFormDataQuery, GetGiftDesignerBasicInformationFormDataQueryVariables>;
export const Operations = {
  Query: {
    GetGiftDesignerBasicInformationFormData: 'GetGiftDesignerBasicInformationFormData' as const
  },
  Fragment: {
    GetGiftDesignerBasicInformationFormData_Proposal: 'GetGiftDesignerBasicInformationFormData_Proposal' as const
  }
}