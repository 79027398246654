import { getGRATTrustSummaryProperties } from '@/modules/entities/details/grat/GRATTrustDetails';
import { StageAwareGRATAssetsCardViewOnly } from '@/modules/entities/details/grat/StageAwareGRATAssetsCard';
import { ENTITY_TYPES } from '@/modules/entities/entities.constants';

import { TrustOverviewSlide } from '../components/Trust.OverviewSlide';
import { GRATTrustPresentationSlideProps } from './GRATTrust.types';

export function GRATTrustOverviewSlide({
  entity,
  subtype,
  ...registrationProps
}: GRATTrustPresentationSlideProps) {
  const summaryDetails = getGRATTrustSummaryProperties(
    {
      entity,
      entityType: ENTITY_TYPES.GRAT,
    },
    { variant: 'print' }
  );

  return (
    <TrustOverviewSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      AssetCard={
        <StageAwareGRATAssetsCardViewOnly
          entity={entity}
          entityType={ENTITY_TYPES.GRAT}
        />
      }
      displayName={subtype.displayName}
    />
  );
}
