import { useMemo } from 'react';

import { useTextEditorContext } from '../contexts/textEditor.context';
import { useTextEditorClasses } from './useTextEditorClasses';

export function useTextEditorTheme() {
  const { theme: textEditorTheme } = useTextEditorContext();
  const { classes } = useTextEditorClasses({ textEditorTheme });

  const theme = useMemo(() => {
    return {
      heading: {
        h1: classes.h1,
        h2: classes.h2,
        h3: classes.h3,
        h4: classes.h4,
        h5: classes.h5,
        h6: classes.h6,
      },
      list: {
        listitem: classes.li,
        nested: {
          listitem: classes.nestedListItem,
        },
        olDepth: [
          classes.ol1,
          classes.ol2,
          classes.ol3,
          classes.ol4,
          classes.ol5,
        ],
        ul: classes.ul,
      },
      paragraph: classes.p,
      quote: classes.blockquote,
      text: {
        bold: classes.bold,
        code: classes.code,
        italic: classes.italic,
        strikethrough: classes.strikethrough,
        subscript: classes.subscript,
        superscript: classes.superscript,
        underline: classes.underline,
        underlineStrikethrough: classes.underlineStrikethrough,
      },
    };
  }, [
    classes.blockquote,
    classes.bold,
    classes.code,
    classes.h1,
    classes.h2,
    classes.h3,
    classes.h4,
    classes.h5,
    classes.h6,
    classes.italic,
    classes.li,
    classes.nestedListItem,
    classes.ol1,
    classes.ol2,
    classes.ol3,
    classes.ol4,
    classes.ol5,
    classes.p,
    classes.strikethrough,
    classes.subscript,
    classes.superscript,
    classes.ul,
    classes.underline,
    classes.underlineStrikethrough,
  ]);

  return theme;
}
