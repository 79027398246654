import { Box, BoxProps, Stack, Typography, useTheme } from '@mui/material';
import { ComponentType } from 'react';

import { COLORS } from '@/styles/tokens/colors';

import { PresentationSlideSecondaryHeading } from '../../components/PresentationSlideSecondaryHeading';
import { useGuardedTenantPresentationConfigurationContext } from '../../context/tenantPresentationConfiguration.context';
import { PRESENTATION_SPACING_UNITS } from '../../presentation.constants';
import { PresentationSlide } from '../../PresentationSlide';
import { Footer } from '../../PresentationSlide/accessoryComponents';
import { ClientPresentationStandaloneSlideType } from '../clientPresentation.types';
import { ClientPresentationDesigner_HouseholdFragment } from '../ClientPresentationDesigner/graphql/ClientPresentationDesigner.generated';
import { useGuardedClientPresentationDesignerContext } from '../contexts/clientPresentationDesigner.context';
import { useRegisterSlide } from '../hooks/useRegisterSlide';
import { useDefaultDisclaimerSlideTitle } from './useDefaultDisclaimerSlideTitle';

export interface DisclaimerSlideProps {
  client: ClientPresentationDesigner_HouseholdFragment;
  SlideWrapper?: ComponentType<BoxProps>;
}

/**
 * @description This slide exposes customers' configured disclaimers in a two-column layout
 */
export function CustomDisclaimerSlide({
  client,
  SlideWrapper = Box,
}: DisclaimerSlideProps) {
  const { legalDisclaimers } =
    useGuardedTenantPresentationConfigurationContext();
  const { shouldShowItem } = useGuardedClientPresentationDesignerContext();
  const theme = useTheme();
  const slideId = 'slide_custom_ending_disclaimers';
  const defaultDisclaimerSlideTitle = useDefaultDisclaimerSlideTitle();
  const slideTitle =
    legalDisclaimers?.presentationStandaloneDisclaimerTitle ??
    defaultDisclaimerSlideTitle;

  useRegisterSlide({
    displayName: slideTitle,
    bundleId: '',
    id: slideId,
    identifier: ClientPresentationStandaloneSlideType.DISCLAIMER,
    index: 0,
  });

  if (!shouldShowItem(ClientPresentationStandaloneSlideType.DISCLAIMER)) {
    return null;
  }

  return (
    <SlideWrapper>
      <PresentationSlide.Slide
        id={slideId}
        leftHeaderContent={
          <PresentationSlide.MainHeading heading={slideTitle} />
        }
        rightHeaderContent={
          <PresentationSlideSecondaryHeading
            clientDisplayName={client.displayName}
          />
        }
        footer={<Footer hideDisclaimer />}
      >
        <Stack p={PRESENTATION_SPACING_UNITS} height="100%">
          <Box
            sx={{
              columnCount: 2,
              columnGap: theme.spacing(6),
              columnRule: `1px solid ${COLORS.NEUTRAL_GRAY[900]}`,
              height: 800, // height of a slide's body, necessary for the column content to wrap correctly,
            }}
          >
            <Typography
              variant="body1"
              // white-space: pre-wrap is necessary to preserve line breaks
              sx={{ breakInside: 'avoid', whiteSpace: 'pre-wrap' }}
            >
              {legalDisclaimers?.presentationStandaloneDisclaimerBody ?? ''}
            </Typography>
          </Box>
        </Stack>
      </PresentationSlide.Slide>
    </SlideWrapper>
  );
}
