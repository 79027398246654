import { StepTracker } from '@/components/navigation/StepTracker/StepTracker';
import { AI_ONBOARDING_MODAL_STEP_CONFIGS } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.constants';
import { AIOnboardingModalStep } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.types';
import { useAIOnboardingModalContext } from '@/modules/aiOnboarding/AIOnboardingModal/context/AIOnboardingModal.context';

export function AIOnboardingModalStepTracker() {
  const { activeStep } = useAIOnboardingModalContext();

  if (!activeStep || activeStep === AIOnboardingModalStep.NO_SUGGESTIONS) {
    return null;
  }

  const { index } = AI_ONBOARDING_MODAL_STEP_CONFIGS[activeStep];

  return (
    <StepTracker
      segments={[
        {
          display:
            AI_ONBOARDING_MODAL_STEP_CONFIGS[
              AIOnboardingModalStep.INDIVIDUALS_AND_ORGANIZATIONS
            ].stepTrackerText,
          isComplete:
            index ===
            AI_ONBOARDING_MODAL_STEP_CONFIGS[
              AIOnboardingModalStep.INDIVIDUALS_AND_ORGANIZATIONS
            ].index,
        },
        {
          display:
            AI_ONBOARDING_MODAL_STEP_CONFIGS[AIOnboardingModalStep.ENTITIES]
              .stepTrackerText,
          isComplete:
            index ===
            AI_ONBOARDING_MODAL_STEP_CONFIGS[AIOnboardingModalStep.ENTITIES]
              .index,
        },
      ]}
      activeSegmentIndex={index}
    />
  );
}
