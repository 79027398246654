import { Box, Grid, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { appbarHeight } from '@/components/architecture/Layout/AppBar/AppBar';
import { Button } from '@/components/form/baseInputs/Button';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { PageHeader } from '@/components/navigation/PageHeader';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getNodes } from '@/utils/graphqlUtils';

import { ClientEntityCSVConnectionTable } from '../ClientEntityCSVConnectionTable/ClientEntityCSVConnectionTable';
import { ClientEntityCSVUploadDownload } from '../ClientEntityCSVUploadDownload/ClientEntityCSVUploadDownload';
import { CSVUploadConfirmationModal } from '../CSVUploadConfirmationModal/CSVUploadConfirmationModal';
import { CSVUploadForm } from '../CSVUploadForm/CSVUploadForm';
import { CSVUploadValidationModal } from '../CSVUploadValidationModal/CSVUploadValidationModal';
import { UploadAttributionForm } from '../UploadAttributionForm/UploadAttributionForm';
import {
  ImportCsvValuationsPage_EntityValuationErrorMessageFragment,
  useImportCsvValuationPageQuery,
  useUpdateEntitiesAndSyncValuationsMutation,
} from './graphql/ImportCSVValuationsPage.generated';
import { CSVUploadFormShape } from './ImportCSVValuationsPage.types';
import {
  getInitialAccountIdsByHousehold,
  getUpdatesFromFormData,
} from './ImportCSVValuationsPage.utils';
import { defaultFormValues } from './ImportCSVVvaluationsPage.constants';

function usePageDataQuery() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const queryProps = useImportCsvValuationPageQuery({
    onError: (err) => {
      showFeedback(
        `We weren't able to load required data for the CSV import page`
      );
      reportError('failed to load csv import page data', err);
    },
  });

  const { data } = queryProps;

  const integrationEntities = useMemo(
    () => getNodes(data?.csvIntegrationEntities),
    [data?.csvIntegrationEntities]
  );

  const integrationConfigProps = useMemo(() => {
    return {
      integrationConfigId: data?.integrationConfiguration?.id || null,
      attributionName:
        data?.integrationConfiguration?.csvValuationImportName || '',
    };
  }, [
    data?.integrationConfiguration?.csvValuationImportName,
    data?.integrationConfiguration?.id,
  ]);

  return {
    integrationEntities,
    ...integrationConfigProps,
    ...queryProps,
  };
}

interface ValuationUpdateConfirmationDetails {
  numValuationsUpdated: number;
  valuationUpdateErrorMessages: ImportCsvValuationsPage_EntityValuationErrorMessageFragment[];
}

function ImportCSVValuationsPageInner() {
  const { navigate } = useNavigateToRoute();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const [valuationConfirmationDetails, setValuationConfirmationDetails] =
    useState<ValuationUpdateConfirmationDetails | null>(null);

  const { handleSubmit, reset, setError } =
    useFormContext<CSVUploadFormShape>();
  const {
    integrationConfigId,
    attributionName,
    integrationEntities,
    // we use this refetch so that once the initial CSV upload is done, we can refetch and get the integration entities that were
    // created by uploading the CSV file and match them with the accountIds entered by the users in the table
    refetch: refetchIntegrationEntities,
  } = usePageDataQuery();

  const resetFormValues = useCallback(() => {
    const initialValue = getInitialAccountIdsByHousehold(integrationEntities);
    reset({
      ...defaultFormValues,
      attributionName,
      accountIdsByHousehold: initialValue,
    });
  }, [attributionName, integrationEntities, reset]);

  useEffect(() => {
    if (isEmpty(integrationEntities)) return;
    resetFormValues();
  }, [integrationEntities, reset, resetFormValues]);

  const [updateEntities, { data, loading }] =
    useUpdateEntitiesAndSyncValuationsMutation({
      // skipping the cache because the validation responses here are dynamic/"unkeyed" but reference entities below them
      fetchPolicy: 'no-cache',
      onError: (err) => {
        reportError('error submitting entity update valuation', err);
        showFeedback(
          'Failed to update valuations and entity data. Please try again.'
        );
      },
    });

  const onSubmit = handleSubmit((formData) => {
    if (!formData.fileId) {
      setError('fileId', { message: 'Please upload a valuations file' });
      return;
    }

    const updates = getUpdatesFromFormData(formData, {
      integrationConfigId,
      integrationEntities,
    });

    return updateEntities({
      variables: updates,
    });
  });

  const handleSubmitSuccess = useCallback(() => {
    // we show feedback via the mutation onError, so we just should return here
    if (!data) return;

    if (valuationConfirmationDetails) return;
    setValuationConfirmationDetails({
      numValuationsUpdated:
        data.ingestValuationsFromCSV?.numValuationsUpdated || 0,
      valuationUpdateErrorMessages:
        data.ingestValuationsFromCSV?.valuationUpdateErrorMessages ?? [],
    });
  }, [data, valuationConfirmationDetails]);

  useSubmitSuccessHandler(() => {
    handleSubmitSuccess();
  });

  async function handleConfirmationModalClose(doRetry: boolean) {
    if (!doRetry) {
      navigate(ROUTE_KEYS.HOUSEHOLDS_LIST, {});
    }

    await refetchIntegrationEntities();
    resetFormValues();
    setValuationConfirmationDetails(null);
  }

  return (
    <>
      {valuationConfirmationDetails && (
        <ValuationConfirmationModal
          {...valuationConfirmationDetails}
          onClose={handleConfirmationModalClose}
        />
      )}
      <Stack
        sx={{
          height: `calc(100vh - ${appbarHeight} - ${FOOTER_HEIGHT}px)`,
          overflow: 'auto',
        }}
      >
        <PageHeader heading="Update valuations via CSV import" />
        <Grid container spacing={2} p={3} component="form" onSubmit={onSubmit}>
          <Grid item sm={4}>
            <Stack spacing={2}>
              <HeaderCard heading="Step 1: Upload valuation data">
                <Stack spacing={3}>
                  <CSVUploadForm
                    onUploadComplete={refetchIntegrationEntities}
                  />
                  <UploadAttributionForm />
                </Stack>
              </HeaderCard>
            </Stack>
          </Grid>
          <Grid item sm={8}>
            <HeaderCard heading="Step 2: Associate IDs to Luminary entities">
              <Stack spacing={2}>
                <ClientEntityCSVUploadDownload
                  onUploadComplete={refetchIntegrationEntities}
                />
                <ClientEntityCSVConnectionTable />
              </Stack>
            </HeaderCard>
          </Grid>
        </Grid>
      </Stack>
      <Box>
        <Footer
          leftAction={
            <Button
              variant="secondary"
              size="sm"
              onClick={() => navigate(ROUTE_KEYS.HOUSEHOLDS_LIST, {})}
            >
              Exit import
            </Button>
          }
          rightAction={
            <Button
              variant="primary"
              loading={loading}
              onClick={onSubmit}
              type="submit"
              size="sm"
            >
              Update valuations
            </Button>
          }
        />
      </Box>
    </>
  );
}

export function ImportCSVValuationsPage() {
  const formMethods = useForm<CSVUploadFormShape>({
    defaultValues: defaultFormValues,
  });

  return (
    <FormProvider {...formMethods}>
      <ImportCSVValuationsPageInner />
    </FormProvider>
  );
}

interface ValuationConfirmationModalProps
  extends ValuationUpdateConfirmationDetails {
  onClose: (doRetry: boolean) => void;
}

function ValuationConfirmationModal({
  numValuationsUpdated,
  onClose,
  valuationUpdateErrorMessages,
}: ValuationConfirmationModalProps) {
  if (!isEmpty(valuationUpdateErrorMessages)) {
    return (
      <CSVUploadValidationModal
        updatedValuations={numValuationsUpdated}
        validationResults={valuationUpdateErrorMessages}
        isOpen={true}
        onClose={onClose}
      />
    );
  }

  return (
    <CSVUploadConfirmationModal
      updatedValuations={numValuationsUpdated}
      isOpen={true}
      onClose={() => onClose(false)}
    />
  );
}
