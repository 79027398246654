import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useCallback, useMemo } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';

import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { Card } from '@/components/layout/Card/Card';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { Callout } from '@/components/notifications/Callout/Callout';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { GiftPreTaxGrowthRate } from '@/modules/content/tooltipContent/GiftPreTaxGrowthRate';
import { GiftTaxDrag } from '@/modules/content/tooltipContent/GiftTaxDrag';
import { formatPercent } from '@/utils/formatting/percent';

import {
  GiftDesignerModelScenariosFormShape,
  TaxReturnLimits,
} from '../../GiftDesignerModelScenariosForm.types';

interface TaxReturnLimitModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmitSuccess: (formData: TaxReturnLimits) => void;
}

interface AfterTaxCalloutProps {
  taxReturnField: 'lowPreTaxReturn' | 'mediumPreTaxReturn' | 'highPreTaxReturn';
  taxDragField: 'lowTaxDrag' | 'mediumTaxDrag' | 'highTaxDrag';
}

function AfterTaxCallout({
  taxReturnField,
  taxDragField,
}: AfterTaxCalloutProps) {
  const { control } = useFormContext<TaxReturnLimits>();

  const taxReturn = useWatch({
    control,
    name: taxReturnField,
  });

  const taxDrag = useWatch({
    control,
    name: taxDragField,
  });

  // afterTaxReturn = taxReturn - (taxDrag * abs(taxReturn))
  const afterTaxReturn =
    taxReturn?.minus(
      (taxDrag ?? new Decimal(0))
        .times(taxReturn.abs() ?? new Decimal(0))
        .div(100)
    ) ?? new Decimal(0);

  return (
    <Callout severity="info-high" textVariant="subtitle1">
      Calculated after-tax return of{' '}
      <span data-testid={`TaxReturnLimitModal-afterTaxRate-${taxReturnField}`}>
        {formatPercent(afterTaxReturn, 2)}%
      </span>
    </Callout>
  );
}

function TaxReturnLimitModalInner({
  isOpen,
  onClose,
  onSubmitSuccess,
}: TaxReturnLimitModalProps) {
  const { formRef, handleSave } = useFormSaveHandler();

  const { reset, handleSubmit, control, formState } =
    useFormContext<TaxReturnLimits>();

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  const actions = useMemo(() => {
    return (
      <FormModalActions.Provider<TaxReturnLimits> formState={formState}>
        <FormModalActions.CancelButton onClick={handleClose} />
        <FormModalActions.SaveButton onClick={handleSave}>
          Save limits
        </FormModalActions.SaveButton>
      </FormModalActions.Provider>
    );
  }, [formState, handleClose, handleSave]);

  const onSubmit = handleSubmit((formData: TaxReturnLimits) => {
    return onSubmitSuccess(formData);
  });

  useSubmitSuccessHandler(() => {
    handleClose();
  });

  return (
    <FormModal
      isOpen={isOpen}
      heading="Pre-tax return limits"
      onClose={handleClose}
      actions={actions}
    >
      <Stack
        component="form"
        ref={formRef}
        onSubmit={onSubmit}
        noValidate
        spacing={2.5}
      >
        <Typography variant="body2">
          Adjust the range of available returns. These values are used when
          generating a client proposal.
        </Typography>
        {(
          [
            {
              taxReturnField: 'lowPreTaxReturn',
              taxDragField: 'lowTaxDrag',
              label: 'Low',
            },
            {
              taxReturnField: 'mediumPreTaxReturn',
              taxDragField: 'mediumTaxDrag',
              label: 'Medium',
            },
            {
              taxReturnField: 'highPreTaxReturn',
              taxDragField: 'highTaxDrag',
              label: 'High',
            },
          ] as const
        ).map(({ taxReturnField, taxDragField, label }) => {
          return (
            <Card
              key={label}
              variant="filled"
              sx={{
                p: 3,
              }}
            >
              <Stack spacing={2}>
                <Stack direction="row" spacing={3}>
                  <Stack flex={1}>
                    <FormAwarePercentInput
                      fieldName={taxReturnField}
                      label={`${label} pre-tax return`}
                      control={control}
                      isDecimalJSInput
                      required
                      contextualHelp={<GiftPreTaxGrowthRate />}
                    />
                  </Stack>

                  <Stack flex={1}>
                    <FormAwarePercentInput
                      fieldName={taxDragField}
                      label="Associated tax drag"
                      control={control}
                      isDecimalJSInput
                      required
                      contextualHelp={<GiftTaxDrag />}
                    />
                  </Stack>
                </Stack>
                <AfterTaxCallout
                  taxReturnField={taxReturnField}
                  taxDragField={taxDragField}
                />
              </Stack>
            </Card>
          );
        })}
      </Stack>
    </FormModal>
  );
}

export function TaxReturnLimitModal(props: TaxReturnLimitModalProps) {
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();

  const taxReturnLimits = useWatch({
    control,
    name: 'taxReturnLimits',
  });

  const formMethods = useForm<TaxReturnLimits>({
    defaultValues: taxReturnLimits,
    values: taxReturnLimits,
  });

  return (
    <FormProvider {...formMethods}>
      <TaxReturnLimitModalInner {...props} />
    </FormProvider>
  );
}
