import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';

import { useDispositiveProvisionsContext } from '../../contexts/dispositiveProvisions.context';

export function useAssertIsOnTwoClientHousehold() {
  const { primaryClients } = useDispositiveProvisionsContext();

  if (primaryClients.length !== 2) {
    throw new Error('Expected two primary clients');
  }

  return {
    primaryClients: primaryClients as [
      ContextPrimaryClient,
      ContextPrimaryClient,
    ],
  };
}
