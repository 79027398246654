// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEntityAndCompleteTaskMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateEntityInput;
  taskId: Types.Scalars['ID']['input'];
}>;


export type UpdateEntityAndCompleteTaskMutation = { __typename?: 'Mutation', updateEntity: { __typename?: 'Entity', id: string }, completeEntityTask: { __typename?: 'EntityTask', id: string } };


export const UpdateEntityAndCompleteTaskDocument = gql`
    mutation UpdateEntityAndCompleteTask($input: AugmentedUpdateEntityInput!, $taskId: ID!) {
  updateEntity(input: $input) {
    id
  }
  completeEntityTask(id: $taskId) {
    id
  }
}
    `;
export type UpdateEntityAndCompleteTaskMutationFn = Apollo.MutationFunction<UpdateEntityAndCompleteTaskMutation, UpdateEntityAndCompleteTaskMutationVariables>;

/**
 * __useUpdateEntityAndCompleteTaskMutation__
 *
 * To run a mutation, you first call `useUpdateEntityAndCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityAndCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityAndCompleteTaskMutation, { data, loading, error }] = useUpdateEntityAndCompleteTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUpdateEntityAndCompleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntityAndCompleteTaskMutation, UpdateEntityAndCompleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntityAndCompleteTaskMutation, UpdateEntityAndCompleteTaskMutationVariables>(UpdateEntityAndCompleteTaskDocument, options);
      }
export type UpdateEntityAndCompleteTaskMutationHookResult = ReturnType<typeof useUpdateEntityAndCompleteTaskMutation>;
export type UpdateEntityAndCompleteTaskMutationResult = Apollo.MutationResult<UpdateEntityAndCompleteTaskMutation>;
export type UpdateEntityAndCompleteTaskMutationOptions = Apollo.BaseMutationOptions<UpdateEntityAndCompleteTaskMutation, UpdateEntityAndCompleteTaskMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateEntityAndCompleteTask: 'UpdateEntityAndCompleteTask' as const
  }
}