import { Stack } from '@mui/material';
import { useWatch } from 'react-hook-form';

import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { GRATQPRTTaxableGift } from '@/modules/content/tooltipContent/GRATQPRTTaxableGift';
import {
  BASIC_INFORMATION_SUBFORM_NAMESPACE,
  Fields,
  Props,
  SubformField,
} from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import { GRAT_TERM_SELECT_OPTIONS } from '@/modules/entities/gratTrusts/TermsSubform/ui/utils';
import { Rate7520Input } from '@/modules/trusts/Rate7520Input/Rate7520Input';

interface TrustPropertiesProps {
  variant: Props['variant'];
  householdId: string;
  entityId?: string;
}

const SMALL_INPUT_WIDTH_UNITS = 4;

/**
 * Various fields that are common to all Trusts for the Basic Information Subform.
 */
export function TrustProperties({
  variant,
  householdId,
}: TrustPropertiesProps) {
  const isGRATImplementation =
    variant === 'GRATImplementationConfirm' ||
    variant === 'GRATImplementationEdit';
  const isActiveGRATEdit = variant === 'GRATActive';
  const isQPRT = variant === 'QPRT';
  const isCLT = variant === 'CLT';
  const isCRT = variant === 'CRT';
  const isCharitableTrust = isCLT || isCRT;

  const { control } = useFormContext<Fields>();

  const isLifetime = useWatch({
    name: `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.isLifetime` as const satisfies SubformField,
    control,
  });
  const taxableGiftAmount = useWatch({
    name: `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.taxableGift` as const satisfies SubformField,
    control,
  });

  return (
    // <Card variant="filled-callout" sx={{ p: 2 }}>
    <Stack>
      {isQPRT && (
        <>
          <FormLayoutRow>
            <FormLayoutItem width={SMALL_INPUT_WIDTH_UNITS}>
              <FormAwareDatePickerInput<Fields>
                control={control}
                label="Term start date"
                fieldName={`${BASIC_INFORMATION_SUBFORM_NAMESPACE}.effectiveDate`}
                // this is because we use taxable gift and term start date in conjunction
                // to automatically persist the gift related to this, and we want to enforce
                // that we have both properties
                required={Boolean(taxableGiftAmount)}
              />
            </FormLayoutItem>
            <FormLayoutItem width={SMALL_INPUT_WIDTH_UNITS}>
              <FormAwareFormattedNumberInput<Fields>
                control={control}
                label="Term length (years)"
                fieldName={`${BASIC_INFORMATION_SUBFORM_NAMESPACE}.termLengthNumber`}
                decimalScale={0}
              />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem width={SMALL_INPUT_WIDTH_UNITS}>
              <FormAwareCurrencyInput<Fields>
                control={control}
                isDecimalJSInput
                label="Taxable gift"
                fieldName={`${BASIC_INFORMATION_SUBFORM_NAMESPACE}.taxableGift`}
                contextualHelp={
                  <GRATQPRTTaxableGift householdId={householdId} />
                }
              />
            </FormLayoutItem>
            <FormLayoutItem width={SMALL_INPUT_WIDTH_UNITS}>
              <Rate7520Input<Fields>
                control={control}
                isDecimalJSInput
                fieldName={`${BASIC_INFORMATION_SUBFORM_NAMESPACE}.rate7520`}
              />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              <FormAwareSwitch<Fields>
                control={control}
                label="This QPRT has terminated"
                labelPosition="right"
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.isCompleted` as const satisfies SubformField
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </>
      )}
      {isCharitableTrust && (
        <>
          <FormLayoutRow>
            <FormLayoutItem width={SMALL_INPUT_WIDTH_UNITS}>
              <FormAwareDatePickerInput<Fields>
                control={control}
                label="Term start date"
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.effectiveDate` as const satisfies SubformField
                }
              />
            </FormLayoutItem>
            <FormLayoutItem width={SMALL_INPUT_WIDTH_UNITS}>
              <FormAwareFormattedNumberInput<Fields>
                control={control}
                label="Term length (years)"
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.termLengthNumber` as const satisfies SubformField
                }
                decimalScale={0}
                disabled={isLifetime}
              />
            </FormLayoutItem>
            <FormLayoutItem
              width={SMALL_INPUT_WIDTH_UNITS}
              sx={{
                paddingTop: 4,
              }}
            >
              <FormAwareSwitch<Fields>
                control={control}
                label="Lifetime"
                labelPosition="right"
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.isLifetime` as const satisfies SubformField
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem width={SMALL_INPUT_WIDTH_UNITS}>
              <FormAwareCurrencyInput<Fields>
                control={control}
                isDecimalJSInput
                label="Charitable deduction"
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.charitableDeduction` as const satisfies SubformField
                }
              />
            </FormLayoutItem>
            <FormLayoutItem width={SMALL_INPUT_WIDTH_UNITS}>
              <Rate7520Input<Fields>
                control={control}
                isDecimalJSInput
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.rate7520` as const satisfies SubformField
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
          <FormLayoutRow>
            <FormLayoutItem width={12}>
              <FormAwareSwitch<Fields>
                control={control}
                labelPosition="right"
                label={`This ${
                  (isCLT && 'CLT') || (isCRT && 'CRT') || 'trust'
                } has terminated`}
                fieldName={
                  `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.isCompleted` as const satisfies SubformField
                }
              />
            </FormLayoutItem>
          </FormLayoutRow>
        </>
      )}
      {isGRATImplementation && (
        <FormLayoutRow>
          <FormLayoutItem width={SMALL_INPUT_WIDTH_UNITS}>
            <FormAwareSelectInput<Fields>
              control={control}
              label="Term length (years)"
              fieldName={
                `${BASIC_INFORMATION_SUBFORM_NAMESPACE}.termLength` as const satisfies SubformField
              }
              disabled={isActiveGRATEdit}
              options={GRAT_TERM_SELECT_OPTIONS}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
    </Stack>
    // </Card>
  );
}
