import {
  DeleteDocumentMenuItem,
  DeleteDocumentMenuItemProps,
} from '../../menuItems';
import { DocumentsTableRowData } from '../DocumentsTable.types';

interface DeleteDocumentActionItemProps
  extends Omit<DeleteDocumentMenuItemProps, 'documentId'> {
  row: DocumentsTableRowData;
}

/**
 * @description This an action item component for use by the table ActionButtonRenderer
 */
export function DeleteDocumentActionItem({
  row,
  ...props
}: DeleteDocumentActionItemProps) {
  return <DeleteDocumentMenuItem documentId={row.id} {...props} />;
}
