import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { HypotheticalSaleLoanFormShape } from '../../EstateWaterfallHypotheticalSaleLoanModal.types';
import {
  useEntityAssetOptionsQuery,
  useIndividualAssetOptionsQuery,
} from '../graphql/SaleLoanForm.generated';
import { mapAssetOptionsToSelectOptions } from '../SaleLoanAssetsForm.utils';

export function useAssetOptions() {
  const { showFeedback } = useFeedback();
  const { control } = useFormContext<HypotheticalSaleLoanFormShape>();

  const sellerId = useWatch({
    control,
    name: 'sellerId',
  });

  const sellerIdPulidKind = sellerId ? getPulidKind(sellerId) : null;

  const { data: entityAssetData, loading: loadingEntityAssetOptions } =
    useEntityAssetOptionsQuery({
      variables: { entityId: sellerId },
      skip: sellerIdPulidKind !== PulidKind.Entity,
      onError: () => {
        showFeedback('Error fetching asset options for selling entity');
      },
    });

  const { data: individualAssetData, loading: loadingIndividualAssetOptions } =
    useIndividualAssetOptionsQuery({
      variables: { clientProfileId: sellerId },
      skip: sellerIdPulidKind !== PulidKind.ClientProfile,
      onError: () => {
        showFeedback('Error fetching asset options for selling individual');
      },
    });

  const assetOptions = useMemo(
    () => mapAssetOptionsToSelectOptions(entityAssetData, individualAssetData),
    [entityAssetData, individualAssetData]
  );

  return {
    sellerId,
    assetOptions,
    loading: loadingEntityAssetOptions || loadingIndividualAssetOptions,
  };
}
