import { PersonalAccountSummaryData } from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountDetails.types';

import { EntityDetailsData } from '../entityDetails.types';
import {
  getControllingPartyNameFromFragment,
  getOwnerNameFromFragment,
  getSharedSummaryProperties,
} from './accountsCardUtils';

export function getRetirementPersonalAccountSummaryProperties({
  entity,
}: EntityDetailsData): PersonalAccountSummaryData {
  if (entity.subtype.__typename !== 'RetirementPersonalAccount') {
    throw new Error('Invalid personal account type');
  }

  return {
    ...getSharedSummaryProperties(entity),
    ownerNames: entity.subtype.owner
      ? [getOwnerNameFromFragment(entity.subtype.owner)]
      : [],
    controllingPartyNames:
      entity.subtype.controllingParties?.map(
        getControllingPartyNameFromFragment
      ) ?? [],
    beneficiaries: entity.subtype.beneficiaries || [],
    accountDetailsItems: [],
  };
}
