import { ApolloError } from '@apollo/client';
import { GraphQLFormattedError } from 'graphql';
import { useMemo } from 'react';

import { ErrorCodes } from '@/types/schema';

interface PublicGraphQLError {
  message: string;
  code: ErrorCodes;
}

type PublicGraphQLErrors = PublicGraphQLError[];

const PUBLIC_ERROR_PROPERTY = 'luminary.code' as const;

interface PublicGraphQLErrorResponse {
  display: string;
  statusCode: number;
}

export function useGraphQLErrors(
  error: ApolloError | undefined
): PublicGraphQLErrors {
  return useMemo(() => {
    if (!error) return [];

    const errors: PublicGraphQLErrors = [];

    error.graphQLErrors.forEach((graphQLError) => {
      const errorResponse = graphQLError.extensions?.[
        PUBLIC_ERROR_PROPERTY
      ] as PublicGraphQLErrorResponse;
      const publicDisplayCode = errorResponse?.display;
      if (!publicDisplayCode) return;

      // if this error code isn't in the ErrorCodes GraphQL shared type, do nothing
      if (
        Object.values<string>(ErrorCodes).includes(publicDisplayCode) === false
      ) {
        return;
      }

      errors.push({
        message: graphQLError.message,
        code: publicDisplayCode as ErrorCodes,
      });
    });

    return errors;
  }, [error]);
}

export function useSpecificGraphQLError(
  errors: PublicGraphQLErrors,
  errorToFind: ErrorCodes
): PublicGraphQLError | undefined {
  return useMemo(() => {
    if (!errors) return undefined;
    if (errors?.length === 0) return undefined;

    return errors.find((error) => {
      return error.code === errorToFind;
    });
  }, [errors, errorToFind]);
}

/**
 * @description Given an ApolloError, returns a list of user-facing error messages
 * sent from the backend
 */
export function getUserFacingErrorMessages(error: ApolloError): string[] {
  const userFacingErrors = getGraphQLErrorsByStatusCode(
    error,
    GraphQLStatusCodes.BAD_INPUT
  );
  return userFacingErrors.map((e) => {
    return e.message;
  });
}

export enum GraphQLStatusCodes {
  BAD_INPUT = 400,
  NOT_FOUND = 404,
}

/**
 * @description Given an ApolloError, returns a list of GraphQLFormattedError objects
 * that are associated with the BAD_INPUT error code
 */
export function getGraphQLErrorsByStatusCode(
  error: ApolloError,
  statusCode: GraphQLStatusCodes
): GraphQLFormattedError[] {
  return Array.from(error.graphQLErrors).filter((graphQLError) => {
    const code = (
      graphQLError.extensions?.['luminary.code'] as
        | { display: string; status_code: number }
        | undefined
    )?.status_code;

    return code === statusCode;
  });
}
