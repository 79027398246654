import Decimal from 'decimal.js';
import { PropsWithChildren, useEffect } from 'react';

import { useTestamentaryEntityDetailsContext } from './testamentaryEntityDetails.context';

export const TestamentaryEntityDetailsAwareRoute = ({
  entityId,
  children,
  totalMarketValue,
}: PropsWithChildren<{
  entityId: string | null;
  totalMarketValue: Decimal;
}>) => {
  const { setEntityId, setTotalMarketValue } =
    useTestamentaryEntityDetailsContext();

  useEffect(() => {
    setEntityId(entityId ?? null);

    return () => {
      setEntityId(null);
    };
  }, [setEntityId, entityId]);

  useEffect(() => {
    setTotalMarketValue(totalMarketValue);

    return () => {
      setTotalMarketValue(new Decimal(0));
    };
  }, [setTotalMarketValue, totalMarketValue]);

  return <>{children}</>;
};
