import { compact } from 'lodash';

import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { useCanAccessPage } from '@/navigation/usePageAccessLookup';

export function useAuthorizedSettingsPages() {
  const currentUser = useCurrentUser();
  const canAccessIntegrationsPage = useCanAccessPage(
    ROUTE_KEYS.ADMIN_INTEGRATIONS
  );
  const canAccessCategoriesConfigurationPage = useCanAccessPage(
    ROUTE_KEYS.ADMIN_CATEGORIES
  );
  const canAccessUserAdminPage = useCanAccessPage(ROUTE_KEYS.ADMIN_USERS);
  const canAccessBrandingPage = useCanAccessPage(ROUTE_KEYS.ADMIN_BRANDING);
  const canAccessSubBrandsPage = useCanAccessPage(ROUTE_KEYS.ADMIN_SUB_BRANDS);
  const canAccessLegalDisclaimersPage = useCanAccessPage(
    ROUTE_KEYS.ADMIN_LEGAL_DISCLAIMERS
  );
  const canAccessInternalToolsViaPermissions = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_ACCESS_INTERNAL_TOOLS
  );
  const canAccessInternalTools =
    canAccessInternalToolsViaPermissions || currentUser?.isInternalUser;

  return compact([
    canAccessUserAdminPage && {
      display: 'Users',
      to: getCompletePathFromRouteKey(ROUTE_KEYS.ADMIN_USERS, {}),
    },

    canAccessBrandingPage && {
      display: 'Branding',
      to: getCompletePathFromRouteKey(ROUTE_KEYS.ADMIN_BRANDING, {}),
    },
    canAccessSubBrandsPage && {
      display: 'Sub-brands',
      to: getCompletePathFromRouteKey(ROUTE_KEYS.ADMIN_SUB_BRANDS, {}),
    },

    canAccessIntegrationsPage && {
      display: 'Integrations',
      to: getCompletePathFromRouteKey(ROUTE_KEYS.ADMIN_INTEGRATIONS, {}),
      showWarningBadge: currentUser?.apiUserIntegrationLoginExpired ?? false,
    },

    canAccessLegalDisclaimersPage && {
      display: 'Disclaimers',
      to: getCompletePathFromRouteKey(ROUTE_KEYS.ADMIN_LEGAL_DISCLAIMERS, {}),
    },

    canAccessCategoriesConfigurationPage && {
      display: 'Categories',
      to: getCompletePathFromRouteKey(ROUTE_KEYS.ADMIN_CATEGORIES, {}),
    },

    // tools that are only available to internal luminary users
    canAccessInternalTools && {
      display: 'Feature flags',
      to: getCompletePathFromRouteKey(ROUTE_KEYS.ADMIN_FEATURE_FLAGS, {}),
    },

    canAccessInternalTools && {
      display: 'Tenants',
      to: getCompletePathFromRouteKey(ROUTE_KEYS.ADMIN_TENANTS, {}),
    },
  ]);
}
