function stringify(params: AddeparQueryParams): string {
  return new URLSearchParams(params).toString();
}

export type AddeparQueryParams = {
  redirect: string;
} & Record<string, string>;

export const getAddeparIntegrationLoginPath = (
  queryParams: AddeparQueryParams
): string => `/api/v1/addepar/auth/initiate?${stringify(queryParams)}`;

export const getAddeparIntegrationLogoutPath = (
  queryParams: AddeparQueryParams
): string => `/api/v1/addepar/auth/logout?${stringify(queryParams)}`;
