import { Stack, Typography } from '@mui/material';

import { IconButton } from '@/components/form/baseInputs/Button/IconButton';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { XIcon } from '@/components/icons/XIcon';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import {
  SuggestableSuggestion,
  SuggestedValue,
} from '@/modules/aiSuggestions/AISuggestedValue/AISuggestedValue.types';
import { getSuggestedValue } from '@/modules/aiSuggestions/AISuggestedValue/AISuggestedValue.utils';
import { COLORS } from '@/styles/tokens/colors';

interface AISuggestedValueProps {
  suggestion: SuggestableSuggestion;
  onAcceptSuggestion: (id: string, value: SuggestedValue['value']) => void;
  onRejectSuggestion: (id: string) => void;
}

export function AISuggestedValue({
  suggestion,
  onAcceptSuggestion,
  onRejectSuggestion,
}: AISuggestedValueProps) {
  const { value, display } = getSuggestedValue(suggestion);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p={1}
      spacing={1}
      bgcolor={COLORS.TEAL[50]}
      sx={(theme) => ({
        borderBottomLeftRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
      })}
      data-testid={`suggestion-${suggestion.kind}`}
    >
      <Typography
        variant="caption"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
        sx={{ cursor: 'default' }}
      >
        <Tooltip title={display} placement="top-start">
          <span>{display}</span>
        </Tooltip>
      </Typography>
      <Stack direction="row" spacing={1}>
        <IconButton
          icon={CheckIcon}
          iconProps={{
            sx: {
              color: COLORS.FUNCTIONAL.SUCCESS['600'],
            },
            fill: 'transparent',
          }}
          ariaLabel="Accept suggestion"
          size="xs"
          variant="secondary"
          onClick={() => onAcceptSuggestion(suggestion.id, value)}
          data-testid={`button-acceptSuggestion-${suggestion.kind}`}
        />
        <IconButton
          icon={XIcon}
          iconProps={{
            sx: {
              color: COLORS.FUNCTIONAL.ERROR['700'],
            },
            fill: 'transparent',
          }}
          ariaLabel="Reject suggestion"
          size="xs"
          variant="secondary"
          onClick={() => onRejectSuggestion(suggestion.id)}
          data-testid={`button-rejectSuggestion-${suggestion.kind}`}
        />
      </Stack>
    </Stack>
  );
}
