import { ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import { useReportError } from '@/hooks/useReportError';

import {
  SubstitutionThresholdDetailsv2Fragment,
  useGetGratTrustThresholdsQuery,
} from '../graphql/SubstitutionThresholdSubform.generated';

export function useQuerySubstitutionThresholdDetails(
  entityId: string,
  isModalOpen: boolean
): {
  error?: ApolloError;
  loading: boolean;
  thresholdsData: SubstitutionThresholdDetailsv2Fragment | null;
} {
  const { data, error, loading } = useGetGratTrustThresholdsQuery({
    variables: {
      nodeId: entityId,
    },
  });

  const thresholdsData = useMemo(() => {
    if (!data || !isModalOpen) return null;
    if (
      data.node?.__typename !== 'Entity' ||
      data.node.subtype?.__typename !== 'GRATTrust'
    ) {
      throw new Error('Invalid data returned from query');
    }

    return data.node;
  }, [data, isModalOpen]);

  useReportError(
    'failed to fetch thresholds details for substitution thresholds subform',
    error
  );

  return { thresholdsData, error, loading };
}
