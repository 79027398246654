import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { isUndefined } from 'lodash';
import { ReactNode, useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { Card } from '@/components/layout/Card/Card';
import { useFormContext } from '@/components/react-hook-form';
import {
  DispositiveProvisionDispositionKind,
  DispositiveProvisionTemplateKind,
  DispositiveProvisionTransferTaxKind,
  TestamentaryEntityKind,
} from '@/types/schema';

import { DISPOSITIVE_PROVISIONS_FORM_NAMESPACE } from '../../DispositiveProvisionsForm/DispositiveProvisionsForm.constants';
import { Recipient } from '../../DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import { DraggableListItemRecipient } from '../../DispositiveProvisionsForm/DispositiveProvisionsFormRecipients/DraggableListItemRecipient';
import {
  DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE,
  DispositiveProvisionsTemplateShape,
} from '../DispositiveProvisionsTemplateSplitScreenModal.types';
import {
  BLANK_BYPASS_TRUST,
  BypassTrustRecipient,
  MaritalTrustRecipient,
  TrustRecipientProps,
} from './DispositiveProvisionsTemplateSplitScreenModal.components';

function SurvivorTrustRecipient({ index, templateType }: TrustRecipientProps) {
  return (
    <DraggableListItemRecipient
      index={index}
      Draghandle={null}
      simulatedValue={null}
      onRemove={null}
      defaultExpanded
      templateType={
        templateType ||
        DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse
      }
      emptyRecipientOptionDisplay="Select survivor’s trust"
      recipientConfiguration={{
        testamentaryEntityTaxSettings: {
          estateInclusionStatus: 'in-estate',
          survivingSpouseStateInEstateStatus: 'in-estate',
        },
        defaultNewTestamentaryEntityName: "Survivor's trust",
        defaultNewTestamentaryEntityKind:
          TestamentaryEntityKind.TestamentaryTrust,
      }}
    />
  );
}

export function DispositiveProvisionsTemplateABTrustPreset() {
  const { control, setValue } =
    useFormContext<DispositiveProvisionsTemplateShape>();
  const [includeSurvivorsTrust, recipients] = useWatch({
    control,
    name: [
      `${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.includeSurvivorsTrust`,
      `${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients`,
    ],
  });
  const includeSurvivorsTrustPrevious = usePrevious(includeSurvivorsTrust);

  useEffect(() => {
    if (
      isUndefined(includeSurvivorsTrustPrevious) ||
      includeSurvivorsTrustPrevious === includeSurvivorsTrust
    )
      return;

    if (includeSurvivorsTrust) {
      setValue(`${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients`, [
        BLANK_SURVIVOR_TRUST,
        ...recipients.slice(),
      ]);
    } else {
      setValue(`${DISPOSITIVE_PROVISIONS_FORM_NAMESPACE}.recipients`, [
        ...recipients.slice(1),
      ]);
    }
  }, [
    includeSurvivorsTrust,
    includeSurvivorsTrustPrevious,
    recipients,
    setValue,
  ]);

  let body: ReactNode;

  if (includeSurvivorsTrust && recipients.length > 3) {
    body = (
      <>
        <SurvivorTrustRecipient
          index={0}
          templateType={DispositiveProvisionTemplateKind.AbTrust}
        />
        <BypassTrustRecipient
          templateType={DispositiveProvisionTemplateKind.AbTrust}
          index={1}
          recipientConfiguration={{
            defaultNewTestamentaryEntityName: 'Bypass trust',
            defaultNewTestamentaryEntityKind:
              TestamentaryEntityKind.TestamentaryTrust,
            testamentaryEntityTaxSettings: {
              estateInclusionStatus: 'out-of-estate',
              survivingSpouseStateInEstateStatus: 'out-of-estate',
            },
          }}
        />
        <MaritalTrustRecipient
          index={2}
          templateType={DispositiveProvisionTemplateKind.AbTrust}
          emptyRecipientOptionDisplay="Select GST exempt marital trust"
          isOptional
          recipientConfiguration={{
            defaultNewTestamentaryEntityName: 'Marital trust (GST exempt)',
            defaultNewTestamentaryEntityKind:
              TestamentaryEntityKind.TestamentaryTrust,
            testamentaryEntityTaxSettings: {
              estateInclusionStatus: 'in-estate',
              survivingSpouseStateInEstateStatus: 'in-estate',
              gstStatus: 'gst-exempt',
            },
          }}
        />
        <MaritalTrustRecipient
          index={3}
          templateType={DispositiveProvisionTemplateKind.AbTrust}
          emptyRecipientOptionDisplay="Select GST non-exempt marital trust"
          isOptional
          recipientConfiguration={{
            defaultNewTestamentaryEntityName: 'Marital trust (GST non-exempt)',
            defaultNewTestamentaryEntityKind:
              TestamentaryEntityKind.TestamentaryTrust,
            testamentaryEntityTaxSettings: {
              estateInclusionStatus: 'in-estate',
              survivingSpouseStateInEstateStatus: 'in-estate',
              gstStatus: 'gst-non-exempt',
            },
          }}
        />
      </>
    );
  } else {
    body = (
      <>
        <BypassTrustRecipient
          index={0}
          templateType={DispositiveProvisionTemplateKind.AbTrust}
          recipientConfiguration={{
            defaultNewTestamentaryEntityName: 'Bypass trust',
            defaultNewTestamentaryEntityKind:
              TestamentaryEntityKind.TestamentaryTrust,
            testamentaryEntityTaxSettings: {
              estateInclusionStatus: 'out-of-estate',
              survivingSpouseStateInEstateStatus: 'out-of-estate',
            },
          }}
        />
        <MaritalTrustRecipient
          index={1}
          templateType={DispositiveProvisionTemplateKind.AbTrust}
          emptyRecipientOptionDisplay="Select GST exempt marital trust"
          recipientConfiguration={{
            defaultNewTestamentaryEntityName: 'Marital trust (GST exempt)',
            defaultNewTestamentaryEntityKind:
              TestamentaryEntityKind.TestamentaryTrust,
            testamentaryEntityTaxSettings: {
              estateInclusionStatus: 'in-estate',
              survivingSpouseStateInEstateStatus: 'in-estate',
              gstStatus: 'gst-exempt',
            },
          }}
          isOptional
        />
        <MaritalTrustRecipient
          index={2}
          templateType={DispositiveProvisionTemplateKind.AbTrust}
          emptyRecipientOptionDisplay="Select GST non-exempt marital trust"
          isOptional
          recipientConfiguration={{
            defaultNewTestamentaryEntityName: 'Marital trust (GST non-exempt)',
            defaultNewTestamentaryEntityKind:
              TestamentaryEntityKind.TestamentaryTrust,
            testamentaryEntityTaxSettings: {
              estateInclusionStatus: 'in-estate',
              survivingSpouseStateInEstateStatus: 'in-estate',
              gstStatus: 'gst-non-exempt',
            },
          }}
        />
      </>
    );
  }

  return (
    <Stack direction="column" spacing={3}>
      <Card variant="filled-callout" sx={{ padding: 3 }}>
        <FormAwareSwitch<DispositiveProvisionsTemplateShape>
          control={control}
          label="Include survivor's trust"
          fieldName={`${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.includeSurvivorsTrust`}
          labelPosition="right"
        />
      </Card>
      {body}
    </Stack>
  );
}

const BLANK_SURVIVOR_TRUST: Recipient = {
  _dispositiveProvisionId: null,
  recipientId: '',
  recipientKind: null,
  dispositionKind_ButtonGroup: DispositiveProvisionDispositionKind.Percentage,
  dispositionKind_Select: '',
  dispositionAmount: null,
  dispositionPercentage: new Decimal(50),
  notes: '',
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion,
};

export const BLANK_AB_GST_EXEMPT_MARITAL_TRUST: Recipient = {
  _dispositiveProvisionId: null,
  recipientId: '',
  recipientKind: null,
  dispositionKind_ButtonGroup: 'other',
  dispositionKind_Select:
    DispositiveProvisionDispositionKind.RemainingGstExclusionOfGrantorInExcessOfLifetimeExclusion,
  dispositionAmount: null,
  dispositionPercentage: new Decimal(100),
  notes: '',
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion,
};

export const BLANK_AB_GST_NON_EXEMPT_MARITAL_TRUST: Recipient = {
  _dispositiveProvisionId: null,
  recipientId: '',
  recipientKind: null,
  dispositionKind_ButtonGroup: 'other',
  dispositionKind_Select: DispositiveProvisionDispositionKind.AnythingLeftOver,
  dispositionAmount: null,
  dispositionPercentage: new Decimal(100),
  notes: '',
  transferTaxKind: DispositiveProvisionTransferTaxKind.SpouseMaritalExclusion,
};
export const DEFAULT_AB_TRUST_RECIPIENTS: Recipient[] = [
  BLANK_BYPASS_TRUST,
  BLANK_AB_GST_EXEMPT_MARITAL_TRUST,
  BLANK_AB_GST_NON_EXEMPT_MARITAL_TRUST,
];
