import { createContext, PropsWithChildren } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

import { GrowthProfileModal_AssetClassFragment } from './graphql/GrowthProfileModal.generated';

export interface GrowthProfileModalContextShape {
  growthProfileId: string | null;
  isDefault: boolean;
  isDuplicating: boolean;
  assetClasses: GrowthProfileModal_AssetClassFragment[];
  householdId: string;
}

export const GrowthProfileModalContext =
  createContext<GrowthProfileModalContextShape>({
    growthProfileId: null,
    isDefault: false,
    isDuplicating: false,
    assetClasses: [],
    householdId: '',
  });

export function GrowthProfileModalContextProvider({
  growthProfileId,
  isDefault,
  isDuplicating,
  children,
  assetClasses,
  householdId,
}: PropsWithChildren<GrowthProfileModalContextShape>) {
  return (
    <GrowthProfileModalContext.Provider
      value={{
        growthProfileId,
        isDefault,
        isDuplicating,
        assetClasses,
        householdId,
      }}
    >
      {children}
    </GrowthProfileModalContext.Provider>
  );
}

export function useGrowthProfileModalContext() {
  return useGuardedContext(
    GrowthProfileModalContext,
    'GrowthProfileModalContextProvider'
  );
}
