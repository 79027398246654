// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLoggedTransferMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateLoggedTransferInput;
}>;


export type UpdateLoggedTransferMutation = { __typename?: 'Mutation', updateLoggedTransfer: { __typename?: 'LoggedTransfer', id: string } };


export const UpdateLoggedTransferDocument = gql`
    mutation UpdateLoggedTransfer($input: AugmentedUpdateLoggedTransferInput!) {
  updateLoggedTransfer(input: $input) {
    id
  }
}
    `;
export type UpdateLoggedTransferMutationFn = Apollo.MutationFunction<UpdateLoggedTransferMutation, UpdateLoggedTransferMutationVariables>;

/**
 * __useUpdateLoggedTransferMutation__
 *
 * To run a mutation, you first call `useUpdateLoggedTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLoggedTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLoggedTransferMutation, { data, loading, error }] = useUpdateLoggedTransferMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLoggedTransferMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLoggedTransferMutation, UpdateLoggedTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLoggedTransferMutation, UpdateLoggedTransferMutationVariables>(UpdateLoggedTransferDocument, options);
      }
export type UpdateLoggedTransferMutationHookResult = ReturnType<typeof useUpdateLoggedTransferMutation>;
export type UpdateLoggedTransferMutationResult = Apollo.MutationResult<UpdateLoggedTransferMutation>;
export type UpdateLoggedTransferMutationOptions = Apollo.BaseMutationOptions<UpdateLoggedTransferMutation, UpdateLoggedTransferMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateLoggedTransfer: 'UpdateLoggedTransfer' as const
  }
}