import { Box, BoxProps, Skeleton, Stack } from '@mui/material';

import { ScalableSlide } from '../ScalableSlide/ScalableSlide';
import {
  US_LETTER_ASPECT_RATIO,
  US_LETTER_SCALE_WIDTH_PX,
} from './PresentationSlide.constants';

interface PresentationSlideLoadingSkeletonProps {
  SlideWrapper?: React.ComponentType<BoxProps>;
}

export function PresentationSlideLoadingSkeleton({
  SlideWrapper = Box,
}: PresentationSlideLoadingSkeletonProps) {
  return (
    <SlideWrapper>
      <ScalableSlide
        aspectRatio={US_LETTER_ASPECT_RATIO}
        scaleWidthPx={US_LETTER_SCALE_WIDTH_PX}
      >
        <Stack spacing={2} justifyContent="space-between" height="100%" p={6}>
          <Box flexGrow={1}>
            <Skeleton width="100%" height={80} variant="text" />
            <Skeleton variant="text" height={35} width={500} />
          </Box>
          <Box flexGrow={1}>
            <Stack direction="row" justifyContent="space-between">
              <Box width="40%">
                <Skeleton width="100%" height={30} variant="text" />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="80%" />
              </Box>
              <Skeleton variant="rounded" height={600} width={300} />
            </Stack>
          </Box>
          <Box>
            <Skeleton width="30%" height={50} variant="rounded" />
          </Box>
        </Stack>
      </ScalableSlide>
    </SlideWrapper>
  );
}
