import { Box, Grid, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Control, useWatch } from 'react-hook-form';

import { FormAwareRadioGroup } from '@/components/form/formAwareInputs/FormAwareRadioGroup';
import {
  FormAwareSelectInput,
  FormAwareSelectInputProps,
} from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFormContext } from '@/components/react-hook-form';
import { SuggestableFormAwareInput } from '@/modules/aiSuggestions/components/forms/SuggestableFormAwareInput';
import { BASIC_INFORMATION_SUBFORM_NAMESPACE as BASIC_INFORMATION_SUBFORM_NAMESPACE } from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import { GrantorsFieldSubformsFieldsType } from '@/modules/entities/principals/GrantorsField/GrantorsField.types';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AiSuggestionKind } from '@/types/schema';
import { PathsOf } from '@/types/subform';

import { useDefaultTaxStatusValues } from './hooks/useDefaultTaxStatusValues';
import { useTaxStatusAssetLocationOptions } from './hooks/useTaxStatusAssetLocationOptions';
import {
  GST_STATUS_ITEMS,
  PERSONAL_ACCOUNT_TAX_STATUS_ITEMS,
  TAX_STATUS_SUBFORM,
  TRUST_TAX_STATUS_ITEMS,
} from './TaxStatusSubform.constants';
import {
  Fields,
  SubformField,
  TaxStatusSubformProps,
} from './TaxStatusSubform.types';

export function UI({
  variant,
  selectedGrantorClientProfileIds,
}: TaxStatusSubformProps) {
  const {
    control,
    setValue,
    formState: { isDirty },
    setShouldBlockNavigation,
  } = useFormContext<Fields>();
  const { displayName: householdDisplayName } = useHouseholdDetailsContext();

  const estateInclusionStatus = useWatch({
    name: `${TAX_STATUS_SUBFORM}.estateInclusionStatus` as const satisfies SubformField,
    control,
  });

  const { assetLocationOptions, stateTaxOptions } =
    useTaxStatusAssetLocationOptions({
      fieldNamePrefix: `${TAX_STATUS_SUBFORM}`,
      selectedGrantorClientProfileIds: selectedGrantorClientProfileIds ?? [],
    });

  useDefaultTaxStatusValues({
    stateTaxOptions,
  });

  const trustHasNonClientGrantor = useWatch({
    name: `${BASIC_INFORMATION_SUBFORM_NAMESPACE}._hasNonClientGrantor` as const satisfies PathsOf<GrantorsFieldSubformsFieldsType>,
    control: control as unknown as Control<GrantorsFieldSubformsFieldsType>,
  });

  const isOutOfEstate = estateInclusionStatus === 'out-of-estate';

  const taxStatusFieldName =
    `${TAX_STATUS_SUBFORM}.taxStatus` as const satisfies SubformField;

  const gstStatusFieldName =
    `${TAX_STATUS_SUBFORM}.gstStatus` as const satisfies SubformField;

  // Respond to changes in the estate inclusion status to reset the GST status
  // when the user switches from out of estate to in estate
  useEffect(() => {
    if (!isOutOfEstate) {
      setValue(`${TAX_STATUS_SUBFORM}.gstStatus` as const, '');
    }
  }, [isOutOfEstate, setValue]);

  const isNonTrustEntityVariant = [
    'nonTrustEntity',
    'nonTrustEntityDefaultInEstate',
  ].includes(variant);

  const federalInEstateStatusValue = useWatch({
    control,
    name: `${TAX_STATUS_SUBFORM}.estateInclusionStatus`,
  });

  useEffect(() => {
    // Blocks navigation if the user manually toggles the in-estate status
    // radio buttons. This might be a little redundant, but otherwise,
    // the reset() in the useTaxStatusAssetLocationOptions hooks causes the
    // form to not block.
    if (isDirty) {
      setShouldBlockNavigation(true);
    }
  }, [federalInEstateStatusValue, isDirty, setShouldBlockNavigation]);

  return (
    <Grid container spacing={3} columns={12}>
      <Grid item sm={12}>
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            {variant == 'default' && (
              <FormAwareSelectInput<Fields>
                fieldName={taxStatusFieldName}
                label="Tax status"
                options={TRUST_TAX_STATUS_ITEMS}
                control={control}
              />
            )}
            {isNonTrustEntityVariant && (
              <FormAwareSelectInput<Fields>
                control={control}
                fieldName={
                  `${TAX_STATUS_SUBFORM}.nonTrustEntityTaxStatus` as const satisfies SubformField
                }
                label="Tax status"
                options={PERSONAL_ACCOUNT_TAX_STATUS_ITEMS}
              />
            )}
          </FormLayoutItem>
        </FormLayoutRow>
        <Card
          variant="filled"
          sx={{
            p: 3,
            pb: 4,
          }}
        >
          <Stack spacing={2}>
            <Typography variant="h4">Asset location</Typography>
            <Stack direction="row" width="100%">
              <Stack spacing={1}>
                <Stack direction="row" spacing={3} alignItems="center">
                  <Box width="60px" />
                  <Typography width="60px" variant="label">
                    In estate
                  </Typography>
                  <Typography width="86px" variant="label">
                    Out of estate
                  </Typography>
                </Stack>
                {assetLocationOptions.map(({ label, fieldName, options }) => (
                  <Stack
                    direction="row"
                    spacing={3}
                    alignItems="center"
                    key={label}
                  >
                    <Typography width="60px" variant="label">
                      {label}
                    </Typography>
                    <FormAwareRadioGroup<Fields>
                      sx={{
                        flexFlow: 'nowrap',
                      }}
                      labelSx={{
                        width: '84px', // 60px width + 24px padding
                      }}
                      row={true}
                      label={label}
                      hideLabel={true}
                      options={options}
                      fieldName={fieldName}
                      control={control}
                    />
                  </Stack>
                ))}
              </Stack>
              <Box display="flex" flexGrow={1} maxWidth="48px" minWidth="8px" />
              {isOutOfEstate && (
                <SuggestableFormAwareInput<
                  Fields,
                  FormAwareSelectInputProps<Fields>
                >
                  FormAwareElement={FormAwareSelectInput}
                  control={control}
                  fieldName={gstStatusFieldName}
                  label="GST status"
                  options={GST_STATUS_ITEMS}
                  suggestionKind={AiSuggestionKind.GstStatus}
                />
              )}
            </Stack>
          </Stack>
        </Card>
        <FormLayoutRow
          sx={{
            alignItems: 'center',
            // use a fixed height to prevent the row from jumping when GST status is hidden
            height: 65,
          }}
        />
        {trustHasNonClientGrantor && (
          <FormLayoutRow>
            <FormLayoutItem>
              <Callout severity="info-high">
                This entity is{' '}
                {isOutOfEstate ? 'out of the estate of' : 'in the estate of'}{' '}
                {householdDisplayName}.
              </Callout>
            </FormLayoutItem>
          </FormLayoutRow>
        )}
      </Grid>
    </Grid>
  );
}
