// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { ClientProfileFragmentDoc, ClientProfileMinimalGrantorRelationshipFragmentDoc, RelationshipFragmentDoc, ClientProfileBasicFragmentDoc } from '../../graphql/ClientProfile.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateClientProfileModalMutationVariables = Types.Exact<{
  clientProfileId: Types.Scalars['ID']['input'];
  householdId: Types.Scalars['ID']['input'];
  clientProfile: Types.UpdateClientProfileInput;
  clientRelationships?: Types.InputMaybe<Array<Types.UpdateClientProfileRelationship> | Types.UpdateClientProfileRelationship>;
  hasRelationships: Types.Scalars['Boolean']['input'];
}>;


export type UpdateClientProfileModalMutation = { __typename?: 'Mutation', updateClientProfile: { __typename?: 'ClientProfile', id: string, firstName: string, lastName: string, email?: string | null, dateOfBirth?: Time | null, isGrantor: boolean, isTrustee: boolean, isPrimary: boolean, isBeneficiary: boolean, displayName: string, legalName: string, middleName?: string | null, suffix?: string | null, address?: { __typename?: 'Address', id: string, cityName?: string | null, stateCode: string } | null }, updateClientProfileRelationships?: Array<{ __typename?: 'ClientProfileRelationship', id: string, type: Types.ClientProfileRelationshipType, fromClientProfile: { __typename?: 'ClientProfile', id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string }, toClientProfile: { __typename?: 'ClientProfile', id: string, isGrantor: boolean, isPrimary: boolean, firstName: string, lastName: string } } | null> };


export const UpdateClientProfileModalDocument = gql`
    mutation UpdateClientProfileModal($clientProfileId: ID!, $householdId: ID!, $clientProfile: UpdateClientProfileInput!, $clientRelationships: [UpdateClientProfileRelationship!], $hasRelationships: Boolean!) {
  updateClientProfile(id: $clientProfileId, input: $clientProfile) {
    ...ClientProfile
  }
  updateClientProfileRelationships(
    householdId: $householdId
    updateRelationships: $clientRelationships
  ) @include(if: $hasRelationships) {
    ...ClientProfileMinimalGrantorRelationship
  }
}
    ${ClientProfileFragmentDoc}
${ClientProfileMinimalGrantorRelationshipFragmentDoc}`;
export type UpdateClientProfileModalMutationFn = Apollo.MutationFunction<UpdateClientProfileModalMutation, UpdateClientProfileModalMutationVariables>;

/**
 * __useUpdateClientProfileModalMutation__
 *
 * To run a mutation, you first call `useUpdateClientProfileModalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientProfileModalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientProfileModalMutation, { data, loading, error }] = useUpdateClientProfileModalMutation({
 *   variables: {
 *      clientProfileId: // value for 'clientProfileId'
 *      householdId: // value for 'householdId'
 *      clientProfile: // value for 'clientProfile'
 *      clientRelationships: // value for 'clientRelationships'
 *      hasRelationships: // value for 'hasRelationships'
 *   },
 * });
 */
export function useUpdateClientProfileModalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientProfileModalMutation, UpdateClientProfileModalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientProfileModalMutation, UpdateClientProfileModalMutationVariables>(UpdateClientProfileModalDocument, options);
      }
export type UpdateClientProfileModalMutationHookResult = ReturnType<typeof useUpdateClientProfileModalMutation>;
export type UpdateClientProfileModalMutationResult = Apollo.MutationResult<UpdateClientProfileModalMutation>;
export type UpdateClientProfileModalMutationOptions = Apollo.BaseMutationOptions<UpdateClientProfileModalMutation, UpdateClientProfileModalMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateClientProfileModal: 'UpdateClientProfileModal' as const
  }
}