import {
  ClientProfileFormShape,
  ClientProfileRelationshipFormShape,
} from '@/modules/clientProfiles/ClientProfile.types';
import { PathsOf } from '@/types/subform';

export interface UpdateHouseholdFormShape {
  primaryRelationshipOwnerId: string;
  subBrandId: string;
  isProspect: boolean;
  profiles: ClientProfileFormShape[];
}

export type UpdateHouseholdFormPaths = PathsOf<UpdateHouseholdFormShape>;

interface GetEmptyClientProfileArgs {
  relationshipOptions: ClientProfileRelationshipFormShape[];
}

export function getEmptyClientProfileValue({
  relationshipOptions,
}: GetEmptyClientProfileArgs): ClientProfileFormShape {
  return {
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    email: '',
    address: {
      id: null,
      cityName: '',
      stateCode: '',
    },
    dateOfBirth: null,
    isBeneficiary: false,
    isGrantor: true,
    isTrustee: true,
    relationships: relationshipOptions,
  };
}
