import { Stack, Typography, useTheme } from '@mui/material';

import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { ArrowRightChunkyIcon } from '@/components/icons/ArrowRightChunkyIcon';
import { MinusIcon } from '@/components/icons/MinusIcon';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { SPACING_CONSTANT } from '@/styles/themes/common';
import { COLORS } from '@/styles/tokens/colors';

import {
  Fields,
  NAMESPACE,
  SubformComponentProps,
  SubformField,
} from '../types';

// because the width of the target surplus field is variable (based on whether we're asking for a
// percent or a dollar value), the width is variable. but if the width of its container varies based
// on the size of the input, the whole container moves around. so, we set a minimum width and apply
// it to both the downside and upside threshold sides to make them balanced and ensure that the form
// doesn't shift.
const MINIMUM_FIELD_CONTAINER_WIDTH_PX = 190;
export function SubstitutionThresholdSubformComponent({
  subformValues,
}: SubformComponentProps) {
  const { control } = useFormContext<Fields>();
  const theme = useTheme();
  return (
    <Stack gap={3}>
      <Stack direction="row" gap={2}>
        <Stack
          justifyContent="space-between"
          bgcolor={COLORS.ORANGE[50]}
          direction="row"
          borderRadius={1}
          gap={2}
        >
          <ArrowRightChunkyIcon
            color="white"
            sx={{
              transform: 'rotate(90deg)',
            }}
            size={56}
          />
          <Stack
            gap={2}
            paddingY={2}
            paddingRight={3}
            alignItems="flex-end"
            minWidth={MINIMUM_FIELD_CONTAINER_WIDTH_PX}
          >
            <Typography color={COLORS.ORANGE[700]} variant="label">
              Downside threshold
            </Typography>
            <Stack
              direction="row"
              width={SPACING_CONSTANT * 14}
              gap={1}
              alignItems="center"
              justifyContent="flex-end"
              color={COLORS.ORANGE[700]}
            >
              <MinusIcon size={20} />
              <FormAwarePercentInput<Fields>
                fieldName={
                  `${NAMESPACE}.downsidePercent` as const satisfies SubformField
                }
                label=""
                isDecimalJSInput
                decimalScale={0}
                control={control}
              />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          justifyContent="space-between"
          bgcolor={COLORS.TEAL[100]}
          direction="row"
          borderRadius={1}
          gap={2}
        >
          <ArrowRightChunkyIcon
            color="white"
            sx={{
              alignSelf: 'end',
              transform: 'rotate(-90deg)',
              ml: 1,
            }}
            size={56}
          />
          <Stack
            minWidth={MINIMUM_FIELD_CONTAINER_WIDTH_PX}
            gap={2}
            paddingY={2}
            paddingRight={3}
            alignItems="flex-end"
          >
            {subformValues.upsideAsTarget ? (
              <Stack direction="row">
                <Typography color={COLORS.TEAL[700]} variant="label">
                  Target surplus
                </Typography>
                <ContextualHelpTooltip>
                  <PopperContent
                    sx={{
                      maxWidth: theme.spacing(30),
                    }}
                    body={
                      'Surplus represents the value of assets held in the GRAT in excess of the cumulative remaining annuity payments.'
                    }
                  />
                </ContextualHelpTooltip>
              </Stack>
            ) : (
              <Typography color={COLORS.TEAL[700]} variant="label">
                Upside threshold
              </Typography>
            )}

            <Stack
              direction="row"
              width={
                subformValues.upsideAsTarget
                  ? theme.spacing(20)
                  : theme.spacing(14)
              }
              gap={1}
              alignItems="center"
              color={COLORS.TEAL[700]}
            >
              <PlusIcon size={20} />
              {subformValues.upsideAsTarget ? (
                <FormAwareCurrencyInput<Fields>
                  control={control}
                  fieldName={
                    `${NAMESPACE}.upsideValue` as const satisfies SubformField
                  }
                  isDecimalJSInput
                  label=""
                />
              ) : (
                <FormAwarePercentInput<Fields>
                  fieldName={
                    `${NAMESPACE}.upsidePercent` as const satisfies SubformField
                  }
                  label=""
                  isDecimalJSInput
                  control={control}
                  maxValue={10000}
                  decimalScale={0}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" gap={3} paddingBottom={6} justifyContent="end">
        <FormAwareSwitch<Fields>
          disabled={false}
          control={control}
          label="Define upside as target surplus"
          fieldName={
            `${NAMESPACE}.upsideAsTarget` as const satisfies SubformField
          }
          labelPosition="right"
        />
      </Stack>
    </Stack>
  );
}
