import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Divider } from '@/components/Divider';
import { Button } from '@/components/form/baseInputs/Button';
import { Card } from '@/components/layout/Card/Card';
import { Markdown } from '@/components/Markdown/Markdown';
import { useActiveTab } from '@/components/navigation/NavigationTabs/useActiveTab';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { Loader } from '@/components/progress/Loader/Loader';
import { TextEditorProvider } from '@/components/TextEditor/contexts/TextEditor.provider';
import { useModalState } from '@/hooks/useModalState';
import { useReportError } from '@/hooks/useReportError';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import DocumentViewerWithSearch from '@/modules/documents/components/DocumentViewerWithSearch';
import { DocumentDetailsForm } from '@/modules/documents/DocumentDetailsForm/DocumentDetailsForm';
import { EditSummaryTrowser } from '@/modules/documents/EditSummaryTrowser';
import { useWatchDocumentStatus } from '@/modules/documents/hooks/useWatchDocumentStatus';
import { useRegenAiSummary } from '@/modules/documents/RegenAiSummaryMenuItem/hooks/useRegenAiSummary';
import { useAICapabilitiesEnabled } from '@/modules/tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import { DocumentMetadata } from '@/modules/trusts/TrustDetails/DocumentMetadata';

import { DocumentDetailsDocumentViewer } from './component/DocumentDetailsDocumentViewer';
import {
  AboutLoadingState,
  DocumentDetailsPageVariant,
  SummaryLoadingState,
} from './DocumentDetailsPage.types';
import { DocumentDetailsPageContextMenu } from './DocumentDetailsPageContextMenu';
import { DocumentSummaryHeader } from './DocumentSummaryHeader';
import { useDocumentDetailsPageLoadingState } from './hooks/useDocumentDetailsPageLoadingState';
import { useDocumentDetailsQuery } from './hooks/useDocumentDetailsQuery';
import { useLayoutDefinition } from './hooks/useLayoutDefinition';
import { RateSummary } from './RateSummary';

const DOC_VIEWER_MIN_HEIGHT = 700;

interface DocumentDetailsPageProps {
  variant: DocumentDetailsPageVariant;
}

export function DocumentDetailsPage({ variant }: DocumentDetailsPageProps) {
  const theme = useTheme();
  const aiEnabled = useAICapabilitiesEnabled();

  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const documentId = useRequiredParam('documentId');

  const { activeTab, setActiveTab } = useActiveTab<string>({
    defaultTab: 'summary',
    filterSearchParam: 'documentView',
  });

  const {
    isDocumentBeingAsyncSummarized: isDocumentBeingProcessed,
    startPolling: startPollingDocumentSummary,
  } = useWatchDocumentStatus(documentId);
  const { gridTemplateColumns, gridTemplateAreas } = useLayoutDefinition();

  const {
    document,

    loading: queryLoading,
    refetch: refetchQuery,
  } = useDocumentDetailsQuery(documentId);

  const { asyncRegen } = useRegenAiSummary(
    documentId,
    document?.documentSummary?.id ?? ''
  );

  const {
    state: {
      error,
      about: { loading: aboutLoadingState },
      summary: { loading: summaryLoadingState },
    },
    dispatch,
  } = useDocumentDetailsPageLoadingState();

  const combinedState = useMemo(
    () => ({
      doneLoading: !queryLoading,
      doneProcessingDocument: !isDocumentBeingProcessed,
      hasDocument: Boolean(document),
      hasSummary: Boolean(document?.documentSummary?.summary),
    }),
    [document, isDocumentBeingProcessed, queryLoading]
  );

  // Effect for updating the loading state
  useEffect(() => {
    return dispatch({ type: 'stateUpdate', input: combinedState });
  }, [dispatch, combinedState]);

  // Error handling for when the document is not found
  useEffect(() => {
    if (error) {
      reportError('Document not found', undefined, {
        context: 'DocumentDetailsPage',
        documentId,
      });
      showFeedback(FeedbackMessages.queryError);
    }
  }, [documentId, error, reportError, showFeedback]);

  // Refetch the document query when the document has finished processing and
  // we previous had a summary
  useEffect(() => {
    if (combinedState.hasSummary && combinedState.doneProcessingDocument) {
      void refetchQuery();
    }
  }, [
    combinedState.doneProcessingDocument,
    combinedState.hasSummary,
    refetchQuery,
    showFeedback,
  ]);

  // Refetch the document query when the document is done processing and we
  // do not have a summary
  useEffect(() => {
    if (!combinedState.hasSummary && combinedState.doneProcessingDocument) {
      void refetchQuery();
    }
  }, [
    combinedState.doneProcessingDocument,
    combinedState.hasSummary,
    refetchQuery,
  ]);

  // This logic is used to determine the height of the document viewer, so that
  // it fills the remaining space on the screen. We use the top offset of the
  // document viewer and the bottom padding of the page to calculate the height.
  const documentViewerRef = useRef<HTMLDivElement>(null);
  const [documentViewerHeight, setDocumentViewerHeight] = useState('100vh');

  useLayoutEffect(() => {
    const updateHeight = () => {
      if (aiEnabled && activeTab !== 'document') {
        setDocumentViewerHeight('0px');
        return;
      }

      if (documentViewerRef.current) {
        const rect = documentViewerRef.current.getBoundingClientRect();
        const topOffset = rect.top;
        const bottomPaddingOffset = theme.spacing(3);
        const newHeight = `calc(100vh - ${topOffset}px - ${bottomPaddingOffset})`;
        setDocumentViewerHeight(newHeight);
      }
    };

    // Update height on mount and on window resize
    updateHeight();
    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, [theme, activeTab, aiEnabled]);

  const [
    { isModalOpen: isEditSummaryTrowserOpen },
    { openModal: openEditSummaryTrowser, closeModal: closeEditSummaryTrowser },
  ] = useModalState();

  const hasAdditionalEntities =
    (document?.additionalEntities || []).length +
      (document?.additionalTestamentaryEntities || []).length >
    0;

  const handleEditSummary = useCallback(() => {
    openEditSummaryTrowser();
  }, [openEditSummaryTrowser]);

  return (
    <>
      <Stack
        spacing={3}
        // Variant dependent styling
        px={variant === DocumentDetailsPageVariant.Entity ? 3 : 0}
        py={variant === DocumentDetailsPageVariant.Entity ? 3 : 0}
        pb={variant === DocumentDetailsPageVariant.Entity ? 4 : 0}
        height="100%"
      >
        <Stack height="100%">
          <Grid
            display="grid"
            gap={3}
            container
            gridTemplateColumns={gridTemplateColumns}
            gridTemplateAreas={gridTemplateAreas}
          >
            <Grid item gridArea="about">
              <Card
                variant="outlined"
                sx={{
                  p: 3,
                  pb: 4,
                }}
              >
                <Stack spacing={3}>
                  <Typography variant="h2">About this document</Typography>
                  {aboutLoadingState === AboutLoadingState.Loading && (
                    <Loader
                      boxProps={{
                        sx: { textAlign: 'center', my: 3, width: '100%' },
                      }}
                    />
                  )}
                  {aboutLoadingState === AboutLoadingState.Done &&
                    document && ( // TS
                      // Only show entity in the Household variant
                      <DocumentDetailsForm
                        document={document}
                        showEntity={
                          variant === DocumentDetailsPageVariant.Household
                        }
                      />
                    )}
                </Stack>
              </Card>
            </Grid>
            <Grid item gridArea="summary">
              {/* We need this element to be present on initial render so that
                we can use it to calculate the height it should be. */}
              <Box
                ref={documentViewerRef}
                height={documentViewerHeight}
                minHeight={
                  // If AI is disabled or if on the document tab, we show a doc viewer we need resizable
                  !aiEnabled || activeTab === 'document'
                    ? DOC_VIEWER_MIN_HEIGHT
                    : 0
                }
                overflow="hidden"
              >
                {!aiEnabled && document && (
                  <DocumentDetailsDocumentViewer
                    documentId={documentId}
                    documentUrl={document.file.download.downloadURL}
                  />
                )}
                {aiEnabled && (
                  <>
                    {document && activeTab === 'document' && (
                      <DocumentViewerWithSearch
                        documentId={documentId}
                        documentSrc={document.file.download.downloadURL}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                      />
                    )}
                  </>
                )}
              </Box>
              {aiEnabled && activeTab === 'summary' && (
                <Card
                  variant="outlined"
                  sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TextEditorProvider theme={'userGeneratedContent'}>
                    <Stack spacing={3} pb={4} pt={3} px={3} flexGrow={1}>
                      {summaryLoadingState === SummaryLoadingState.Loading && (
                        <Loader
                          boxProps={{
                            sx: { textAlign: 'center', my: 3, width: '100%' },
                          }}
                        />
                      )}
                      {summaryLoadingState ===
                        SummaryLoadingState.Processing && (
                        <Stack
                          spacing={1}
                          justifyContent="center"
                          alignItems="center"
                          maxWidth="240px"
                          alignSelf="center"
                          height="100%"
                        >
                          <Loader
                            circularProgressProps={{
                              size: 50,
                            }}
                            boxProps={{
                              sx: { textAlign: 'center', my: 3, width: '100%' },
                            }}
                          />
                          <Typography textAlign="center" variant="h4">
                            Summary in progress
                          </Typography>
                          <Typography textAlign="center" variant="subtitle2">
                            Summaries can take a few minutes to be generated.
                            You can exit this screen.
                          </Typography>
                        </Stack>
                      )}
                      {summaryLoadingState ===
                        SummaryLoadingState.NoSummary && (
                        <>
                          <Typography variant="h2">Summary</Typography>
                          <Divider />
                          <Stack
                            maxWidth="380px"
                            height="100%"
                            alignSelf="center"
                            justifyContent="center"
                            alignItems="center"
                            spacing={3}
                          >
                            <Stack spacing={1} alignItems="center">
                              <Typography variant="h4">No summary</Typography>
                              <Typography
                                textAlign="center"
                                variant="subtitle2"
                              >
                                You can automatically generate a summary of this
                                document powered by Luminary AI.
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={2}>
                              <Button
                                variant="primary"
                                size="md"
                                onClick={handleEditSummary}
                              >
                                Write a summary
                              </Button>
                              <Button
                                variant="secondary"
                                size="md"
                                onClick={() => {
                                  void asyncRegen();
                                  // Shorter poll interval than the default 5 seconds
                                  // because just summarizing the document should be faster
                                  startPollingDocumentSummary(1000);
                                }}
                              >
                                Generate AI summary
                              </Button>
                            </Stack>
                          </Stack>
                        </>
                      )}
                      {summaryLoadingState ===
                        SummaryLoadingState.WithSummary &&
                        document?.documentSummary?.summary && ( // TS
                          <Stack>
                            <Box mb={2}>
                              <DocumentSummaryHeader<
                                ComponentProps<
                                  typeof DocumentDetailsPageContextMenu
                                >
                              >
                                documentId={documentId}
                                documentSrc={document.file.download.downloadURL}
                                documentSummary={document.documentSummary}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                hasAdditionalEntities={hasAdditionalEntities}
                                slots={{
                                  ContextMenu: DocumentDetailsPageContextMenu,
                                  ContextMenuProps: {
                                    startPollingDocumentSummary,
                                    documentId,
                                    documentSummaryId:
                                      document.documentSummary.id,
                                  },
                                }}
                              />
                            </Box>
                            <Divider />
                            <Stack mt={0.5}>
                              <DocumentMetadata<
                                ComponentProps<typeof RateSummary>
                              >
                                stackSx={{ p: 0 }}
                                documentSummary={document.documentSummary}
                                slots={{
                                  RateSummary,
                                  RateSummaryProps: {
                                    documentSummaryId:
                                      document.documentSummary.id,
                                    documentSummaryEntryMode:
                                      document.documentSummary.entryMode,
                                    allowFeedback:
                                      !document.documentSummary
                                        .aiSummaryFeedback,
                                    existingRating:
                                      document.documentSummary
                                        .aiSummaryRating ?? null,
                                  },
                                }}
                              />
                            </Stack>
                            <Markdown
                              markdown={document.documentSummary.summary}
                            />
                          </Stack>
                        )}
                    </Stack>
                  </TextEditorProvider>
                </Card>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      {/* We need to reset the state of the form in the trowser upon open because the editor and form
      both maintain their own state and if they differ the trowser content and page content might differ */}
      {document && isEditSummaryTrowserOpen && (
        <EditSummaryTrowser
          isOpen={isEditSummaryTrowserOpen}
          onClose={closeEditSummaryTrowser}
          documentId={documentId}
          documentSrc={document.file.download.downloadURL}
          hasAdditionalEntities={hasAdditionalEntities}
        />
      )}
    </>
  );
}
