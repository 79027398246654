import { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import { Node } from '@/components/diagrams/FlowChart';
import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useForm, useSubmitSuccessHandler } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { diagnostics } from '@/utils/diagnostics';

import {
  GraphViz_ViewFragment,
  useCreateGraphVizViewMutation,
} from '../graphql/GraphVizView.generated';
import {
  mapFormDataToInput,
  mapInputToFormData,
} from './CreateGraphVizViewModal.utils';
import { GraphVizViewForm, GraphVizViewFormProps } from './GraphVizViewForm';
import { GraphVizViewFormData } from './types';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'> & {
  onSuccess?: (view: GraphViz_ViewFragment) => void;
};

interface CreateGraphVizViewInnerModalProps<TabValue extends string>
  extends FormModalOptionalProps,
    GraphVizViewFormProps<TabValue> {}

export interface CreateGraphVizViewModalProps<TabValue extends string>
  extends CreateGraphVizViewInnerModalProps<TabValue> {
  householdId: string;
  nodes: Node[];
}

function CreateGraphVizView({
  onClose,
  heading = 'Create custom map',
  onSuccess,
  getTabForField,
  tabs,
  ...formModalProps
}: CreateGraphVizViewInnerModalProps<string>) {
  const { showFeedback } = useFeedback();
  const { formRef, handleSave } = useFormSaveHandler();
  const { formState, handleSubmit, reset, shouldBlockNavigation } =
    useFormContext<GraphVizViewFormData>();

  const [createMutation, { data }] = useCreateGraphVizViewMutation({
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      diagnostics.error(`Could not create custom map`, error);
    },
  });

  const onSubmit = handleSubmit((formData) => {
    return createMutation({
      variables: { input: { create: mapFormDataToInput(formData) } },
    });
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  useSubmitSuccessHandler(() => {
    if (data?.createEntityGraphView && onSuccess) {
      onSuccess(data.createEntityGraphView);
    }
    closeModal();
  });

  return (
    <FormModal
      {...formModalProps}
      onClose={closeModal}
      heading={heading}
      fullHeight
      shouldBlockClose={shouldBlockNavigation}
      maxWidth="md"
      actions={
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any -- TODO (LUM-1552): Causes infinite type recursion with latest TS, need to upgrade to RHF v8
        <FormModalActions.Provider formState={formState as any}>
          <FormModalActions.CancelButton onClick={closeModal} />
          <FormModalActions.SaveButton onClick={handleSave}>
            Create new map
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <GraphVizViewForm getTabForField={getTabForField} tabs={tabs} />
      </form>
    </FormModal>
  );
}

export const CreateGraphVizViewModal = <TabValue extends string>({
  householdId,
  onSuccess,
  nodes,
  ...props
}: CreateGraphVizViewModalProps<TabValue>) => {
  const defaultValues = useMemo(
    () => mapInputToFormData({ householdId, nodes }),
    [householdId, nodes]
  );

  // another okay fix here would be to drastically simplify the form data type by storing only the ids of the nodes
  // and then mapping the true node configurations back to the form data on submit.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any -- TODO (LUM-1552): Causes infinite type recursion with latest TS, need to upgrade to RHF v8
  const formMethods = useForm<any>({ defaultValues });
  const { reset } = formMethods;

  useEffect(() => {
    reset(mapInputToFormData({ householdId, nodes }));
  }, [nodes, householdId, reset]);

  return (
    <FormProvider {...formMethods}>
      <CreateGraphVizView onSuccess={onSuccess} {...props} />
    </FormProvider>
  );
};
