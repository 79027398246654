import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import React, { ReactNode } from 'react';

import { GREY_CANDY_CANE } from '@/components/charts/constants';
import { StackedHorizontalBarTooltip } from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import {
  StackedHorizontalBarGroupSection,
  StackedHorizontalBarsWithLabels,
} from '@/components/charts/StackedHorizontalBarGroup/StackedHorizontalBarGroup';
import { PopperContent } from '@/components/poppers/PopperContent';
import { DisplayTableColumn } from '@/components/tables/DisplayTable/DisplayTable';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import {
  CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment,
  CharitableTrustDesignerIllustrationData_CltProposalYearlyProjectionFragment,
  CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment,
  CharitableTrustDesignerIllustrationData_CrtProposalYearlyProjectionFragment,
  CharitableTrustDesignerIllustrationData_NoPlanProjectionFragment,
  CharitableTrustDesignerIllustrationData_NoPlanProjectionYearlyFragment,
} from '../graphql/CharitableTrustDesignerIllustrationData.generated';

const CRT_NON_CHARITABLE_COLOR = COLORS.NAVY[300];
const CRT_CHARITABLE_COLOR = COLORS.BLUE[300];
const CLT_NON_CHARITABLE_COLOR = COLORS.BLUE[300];
const CLT_CHARITABLE_COLOR = COLORS.TEAL[300];

function getYearProjection(
  crtProjection:
    | CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment
    | undefined,
  yearOfAnalysis: number | undefined
):
  | Record<string, never>
  | CharitableTrustDesignerIllustrationData_CrtProposalYearlyProjectionFragment;
function getYearProjection(
  cltProjection:
    | CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment
    | undefined,
  yearOfAnalysis: number | undefined
):
  | Record<string, never>
  | CharitableTrustDesignerIllustrationData_CltProposalYearlyProjectionFragment;
function getYearProjection(
  noPlanProjection:
    | CharitableTrustDesignerIllustrationData_NoPlanProjectionFragment
    | undefined,
  yearOfAnalysis: number | undefined
): CharitableTrustDesignerIllustrationData_NoPlanProjectionYearlyFragment;
function getYearProjection(
  projection:
    | CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment
    | CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment
    | CharitableTrustDesignerIllustrationData_NoPlanProjectionFragment
    | undefined,
  yearOfAnalysis: number | undefined
) {
  // there are some cases where this is getting passed in as a string at runtime
  const normalizedYear = parseInt((yearOfAnalysis || 0).toString());
  return (
    projection?.yearly.find(
      (yearlyProjection) => yearlyProjection.year === normalizedYear
    ) || {}
  );
}

export interface DataForCharitableProjection {
  isCRT: boolean;
  crtProjection?: CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment;
  cltProjection?: CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment;
  noPlanProjection?: CharitableTrustDesignerIllustrationData_NoPlanProjectionFragment;
  yearOfAnalysis?: number;
  loading?: boolean;
}

const getBaseEmtpySection = (
  {
    groupName,
    label,
    color,
  }: {
    groupName: string;
    label: string | JSX.Element;
    color: string;
  },
  value = 1 // defaulting to 1 so in cases where everything is empty the rows fill the available area
): StackedHorizontalBarGroupSection => {
  return {
    value,
    color,
    backgroundCss: GREY_CANDY_CANE,
    hideValue: true,
    groupName,
    label,
  };
};

interface ProjectionResult {
  leading: Decimal;
  remainder: Decimal;
}

function getCRTProjection(
  crtProjection:
    | CharitableTrustDesignerIllustrationData_CrtProposalProjectionFragment
    | undefined,
  yearOfAnalysis: number | undefined
): ProjectionResult {
  const yearlyProjection = getYearProjection(crtProjection, yearOfAnalysis);
  const nonCharitable = yearlyProjection.cumulativeIncome;
  const charitable = yearlyProjection.remainderValue;

  return {
    remainder: charitable || new Decimal(0),
    leading: nonCharitable || new Decimal(0),
  };
}

function getCLTProjection(
  cltProjection:
    | CharitableTrustDesignerIllustrationData_CltProposalProjectionFragment
    | undefined,
  yearOfAnalysis: number | undefined
): ProjectionResult {
  const yearlyProjection = getYearProjection(cltProjection, yearOfAnalysis);
  const charitable = yearlyProjection.cumulativeCharity;
  const nonCharitable = yearlyProjection.remainderValue;
  return {
    leading: charitable || new Decimal(0),
    remainder: nonCharitable || new Decimal(0),
  };
}

export function getBarGraphsForProjection({
  isCRT,
  crtProjection,
  cltProjection,
  noPlanProjection,
  yearOfAnalysis,
  loading,
}: DataForCharitableProjection): StackedHorizontalBarsWithLabels[] {
  const { endOfYear: noPlanProjectedValue = new Decimal(0) } =
    getYearProjection(noPlanProjection, yearOfAnalysis) || {};

  const {
    noPlanLabel,
    noPlanColor,
    noPlanTextColor,
    leadingLabel,
    leadingColor,
    leadingPlanTextColor,
    remainderColor,
    remainderLabel,
    withPlanLabel,
    emptySectionLabel,
    projection: { leading, remainder },
  } = isCRT
    ? {
        noPlanLabel: 'Income beneficiary',
        noPlanColor: CRT_NON_CHARITABLE_COLOR,
        noPlanTextColor: undefined,
        leadingLabel: 'Income beneficiary',
        leadingColor: CRT_NON_CHARITABLE_COLOR,
        leadingPlanTextColor: undefined,
        remainderLabel: 'Charitable remainder',
        remainderColor: CRT_CHARITABLE_COLOR,
        withPlanLabel: (
          <Stack direction="row">
            With CRT
            <ContextualHelpTooltip>
              <PopperContent
                body="Assumes assets are sold in the CRT, and capital gains taxes are
              only paid as payments are made to the income beneficiary."
              />
            </ContextualHelpTooltip>
          </Stack>
        ),
        emptySectionLabel: 'Charitable remainder',
        projection: getCRTProjection(crtProjection, yearOfAnalysis),
      }
    : {
        noPlanLabel: 'Charitable beneficiary',
        noPlanColor: CLT_CHARITABLE_COLOR,
        noPlanTextColor: COLORS.NAVY[900],
        leadingLabel: 'Charitable beneficiary',
        leadingColor: CLT_CHARITABLE_COLOR,
        leadingPlanTextColor: COLORS.NAVY[900],
        remainderLabel: 'Charitable lead trust (non-charitable remainder)',
        remainderColor: CLT_NON_CHARITABLE_COLOR,
        withPlanLabel: (
          <Stack direction="row">
            With CLT
            <ContextualHelpTooltip>
              <PopperContent
                body="Assumes the funding assets are donated to the CLT in the
                beginning of the analysis, and distributions are made to the
                charitable beneficiary over time."
              />
            </ContextualHelpTooltip>
          </Stack>
        ),
        emptySectionLabel: 'Charitable beneficiary',
        projection: getCLTProjection(cltProjection, yearOfAnalysis),
      };

  const noPlanSectionBase = {
    label: noPlanLabel,
    groupName: noPlanLabel,
    color: noPlanColor,
    sectionTextColor: isCRT ? undefined : noPlanTextColor,
  };

  // plans with $0 initial funding should be treated as if the value is missing, not as if it's $0 relative to other amounts
  const noPlanNumber = noPlanProjectedValue.toNumber() || undefined;
  const noPlanSections: StackedHorizontalBarGroupSection[] = [
    noPlanNumber
      ? {
          ...noPlanSectionBase,
          value: noPlanNumber,
          tooltip: (
            <StackedHorizontalBarTooltip
              value={noPlanProjectedValue}
              label={noPlanLabel}
            />
          ),
        }
      : getBaseEmtpySection(noPlanSectionBase),
  ];

  let withPlanSections: StackedHorizontalBarGroupSection[] = [
    getBaseEmtpySection(
      {
        label: emptySectionLabel,
        groupName: emptySectionLabel,
        color: isCRT ? CRT_CHARITABLE_COLOR : CLT_CHARITABLE_COLOR,
      },
      noPlanNumber
    ),
  ];

  if ((leading.greaterThan(0) || remainder.greaterThan(0)) && !loading) {
    withPlanSections = [
      {
        label: leadingLabel,
        groupName: leadingLabel,
        color: leadingColor,
        value: leading.toNumber(),
        sectionTextColor: leadingPlanTextColor,
        tooltip: (
          <StackedHorizontalBarTooltip value={leading} label={leadingLabel} />
        ),
      },
      {
        label: remainderLabel,
        groupName: remainderLabel,
        color: remainderColor,
        value: remainder.toNumber(),
        tooltip: (
          <StackedHorizontalBarTooltip
            value={remainder}
            label={remainderLabel}
          />
        ),
      },
    ];
  }

  const noPlanRowLabel: ReactNode = (
    <Stack direction="row">
      No plan
      <ContextualHelpTooltip>
        <PopperContent
          body={
            isCRT
              ? 'Assumes assets are sold in the personal portfolio and capital gains taxes are paid at the beginning of the analysis.'
              : 'Assumes the funding assets are donated directly to the charitable beneficiary in the beginning of the analysis.'
          }
        />
      </ContextualHelpTooltip>
    </Stack>
  );
  return [
    { label: noPlanRowLabel, sections: noPlanSections },
    { label: withPlanLabel, sections: withPlanSections },
  ];
}

export interface CharitableTrustDesignerIllustrationAssetsTableRowType {
  color: string;
  label: string;
  noPlanValue: string;
  withPlanValue: string;
}

export interface CharitableTrustDesignerIllustrationAssetsTableType {
  columns: DisplayTableColumn[];
  rows: CharitableTrustDesignerIllustrationAssetsTableRowType[];
  footerRow: CharitableTrustDesignerIllustrationAssetsTableRowType;
}

export function getTableDataForProjection({
  isCRT,
  cltProjection,
  crtProjection,
  noPlanProjection,
  yearOfAnalysis,
}: DataForCharitableProjection): CharitableTrustDesignerIllustrationAssetsTableType {
  const { endOfYear: noPlanProjectedValue = new Decimal(0) } =
    getYearProjection(noPlanProjection, yearOfAnalysis) || {};

  const columnHeaderName = isCRT ? 'With CRT' : 'With CLT';

  const columns: DisplayTableColumn[] = [
    {
      width: 36,
      headerName: '',
    },
    {
      flex: 2,
      headerName: '',
    },
    {
      flex: 1,
      headerName: 'No plan',
      align: 'right',
    },
    {
      flex: 1,
      headerName: columnHeaderName,
      align: 'right',
    },
  ];

  const {
    leadingLabel,
    leadingColor,
    remainderLabel,
    remainderColor,
    projection: { leading, remainder },
  } = isCRT
    ? {
        leadingLabel: 'Income beneficiary assets',
        leadingColor: CRT_NON_CHARITABLE_COLOR,
        remainderLabel: 'Projected charitable remainder',
        remainderColor: CRT_CHARITABLE_COLOR,
        projection: getCRTProjection(crtProjection, yearOfAnalysis),
      }
    : {
        leadingLabel: 'Charitable beneficiary assets',
        leadingColor: CLT_CHARITABLE_COLOR,
        remainderLabel: 'Projected non-charitable remainder',
        remainderColor: CLT_NON_CHARITABLE_COLOR,
        projection: getCLTProjection(cltProjection, yearOfAnalysis),
      };

  let leadingPlanValue: string = EMPTY_CONTENT_HYPHEN;
  let remainderPlanValue: string = EMPTY_CONTENT_HYPHEN;
  let withPlanTotal: string = EMPTY_CONTENT_HYPHEN;

  if (leading.greaterThan(0) || remainder.greaterThan(0)) {
    leadingPlanValue = formatCurrencyNoDecimals(leading);
    remainderPlanValue = formatCurrencyNoDecimals(remainder);
    withPlanTotal = formatCurrencyNoDecimals(leading.plus(remainder));
  }

  const noPlanProjectedValueDisplay = noPlanProjectedValue.greaterThan(0)
    ? formatCurrencyNoDecimals(noPlanProjectedValue)
    : EMPTY_CONTENT_HYPHEN;

  const rows: CharitableTrustDesignerIllustrationAssetsTableRowType[] = [
    {
      color: leadingColor,
      label: leadingLabel,
      noPlanValue: noPlanProjectedValueDisplay,
      withPlanValue: leadingPlanValue,
    },
    {
      color: remainderColor,
      label: remainderLabel,
      noPlanValue: EMPTY_CONTENT_HYPHEN,
      withPlanValue: remainderPlanValue,
    },
  ];

  return {
    columns,
    rows,
    footerRow: {
      color: '',
      label: 'Total assets',
      noPlanValue: noPlanProjectedValueDisplay,
      withPlanValue: withPlanTotal,
    },
  };
}
