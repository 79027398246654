import { useMemo } from 'react';

import { SELECT_END_ADORNMENT_SIZE } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { Stars01Icon } from '@/components/icons/Stars01Icon';
import { useEntitySuggestionsContext } from '@/modules/aiSuggestions/context/EntitySuggestions.context';
import { EditEntitySection } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { getSectionHasUnacknowledgedSuggestions } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.utils';
import { COLORS } from '@/styles/tokens/colors';

interface AISuggestionsSparkleIconProps {
  section: EditEntitySection;
}

/**
 * Determines if the section has any unacknowledged suggestions and displays a
 * sparkle icon if so.
 *
 * @param section
 */
export function AISuggestionsSparkleIcon({
  section,
}: AISuggestionsSparkleIconProps) {
  const { suggestionsByKind } = useEntitySuggestionsContext();

  const sectionHasUnacknowledgedSuggestions = useMemo(
    () => getSectionHasUnacknowledgedSuggestions(section, suggestionsByKind),
    [section, suggestionsByKind]
  );

  return sectionHasUnacknowledgedSuggestions ? (
    <Stars01Icon size={SELECT_END_ADORNMENT_SIZE} color={COLORS.TEAL[600]} />
  ) : null;
}
