import { Box, Stack } from '@mui/material';
import { compact } from 'lodash';
import { Outlet } from 'react-router-dom';

import { PageHeader } from '@/components/navigation/PageHeader';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { DeleteHouseholdButton } from './DeleteHouseholdButton/DeleteHouseholdButton';

export function ClientAccessPage() {
  const canDeleteClients = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_DELETE_CLIENTS
  );
  const { navigate } = useNavigateToRoute();
  const householdId = useRequiredParam('householdId');
  const currentUser = useCurrentUser();

  const headerAction = (
    <Stack direction="row" spacing={1}>
      {canDeleteClients && (
        <DeleteHouseholdButton
          onDelete={() => navigate(ROUTE_KEYS.HOUSEHOLDS_LIST, {})}
          householdId={householdId}
        />
      )}
    </Stack>
  );

  const tabs = compact([
    {
      display: 'Client access',
      to: getCompletePathFromRouteKey(ROUTE_KEYS.MANAGE_HOUSEHOLD_DETAILS, {
        householdId,
      }),
    },
    // TODO: move this to a feature flag
    currentUser?.isInternalUser && {
      display: 'Activity history (internal)',
      to: getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_ACTIVITY_HISTORY, {
        householdId,
      }),
    },
    currentUser?.isInternalUser && {
      display: 'Admin (internal)',
      to: getCompletePathFromRouteKey(ROUTE_KEYS.MANAGE_HOUSEHOLD_ADMIN, {
        householdId,
      }),
    },
  ]);

  return (
    <div>
      <PageHeader heading="Access" tabs={tabs} action={headerAction} />
      <Box>
        <Outlet />
      </Box>
    </div>
  );
}
