import { Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { compact } from 'lodash';

import { SubtleLabelItem } from '@/components/display/SubtleLabelItem/SubtleLabelItem';
import { Card } from '@/components/layout/Card/Card';
import { SidePanel } from '@/components/modals/SidePanel';
import { DispositiveProvisionsListView } from '@/modules/dispositiveProvisions/DispositiveProvisionsListView/DispositiveProvisionsListView';

import { getSummaryFields } from './OrganizationSummaryPanel.utils';

export enum ClientOrganizationTabs {
  Summary = 'SUMMARY',
  Disposition = 'DISPOSITION',
}

interface UseClientOrganziationPanelsInput {
  summaryFields: ReturnType<typeof getSummaryFields>;
  currentNodeValue?: Decimal;
}

export function useClientOrganizationPanels({
  summaryFields,
  currentNodeValue,
}: UseClientOrganziationPanelsInput) {
  return compact([
    {
      name: 'Summary',
      value: ClientOrganizationTabs.Summary,
      component: (
        <>
          <SubtleLabelItem
            label="Type of organization"
            value={summaryFields.kind}
          />
          <SubtleLabelItem label="Location" value={summaryFields.location} />
          <Card variant="filled" sx={{ p: 2 }}>
            <SidePanel.Section>
              <Typography variant="h4" component="h3" mb={1}>
                Point of contact
              </Typography>
              <SubtleLabelItem label="Name" value={summaryFields.pocName} />
              <SubtleLabelItem label="Email" value={summaryFields.pocPhone} />
              <SubtleLabelItem label="Phone" value={summaryFields.pocEmail} />
            </SidePanel.Section>
          </Card>
        </>
      ),
    },
    {
      name: 'Dispositions',
      value: ClientOrganizationTabs.Disposition,
      component: (
        <DispositiveProvisionsListView currentNodeValue={currentNodeValue} />
      ),
    },
  ]);
}
