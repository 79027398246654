// https://stackoverflow.com/questions/6139107/programmatically-select-text-in-a-contenteditable-html-element
export function selectElementContents(el: HTMLElement) {
  const range = document.createRange();
  range.selectNodeContents(el);
  const sel = window.getSelection();
  if (sel) {
    sel.removeAllRanges();
    sel.addRange(range);
  }
  el.focus();
}
