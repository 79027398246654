import { Skeleton, Stack, Typography } from '@mui/material';
import { noop } from 'lodash';
import { ReactNode, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { Divider } from '@/components/Divider';
import { Button } from '@/components/form/baseInputs/Button';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { TextInput } from '@/components/form/baseInputs/TextInput';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { ConfirmationModal } from '@/components/modals/ConfirmationModal/ConfirmationModal';
import { Badge, BadgeVariants } from '@/components/notifications/Badge/Badge';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useFormContext } from '@/components/react-hook-form';
import { useModalState } from '@/hooks/useModalState';
import { COLORS } from '@/styles/tokens/colors';
import { DispositiveProvisionTemplateKind } from '@/types/schema';

import { DispositiveProvisionsModalDetails } from '../DispositiveProvisionsForm/DispositiveProvisionsForm.types';
import { DispositiveProvisionsRecipients } from '../DispositiveProvisionsForm/DispositiveProvisionsFormRecipients/DispositiveProvisionsRecipients';
import {
  DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE,
  DispositiveProvisionsTemplateShape,
} from './DispositiveProvisionsTemplateSplitScreenModal.types';
import {
  mapClientProfileScenariosToLinkedTable,
  mapEntityDispositionScenariosToLinkedTable,
} from './DispositiveProvisionsTemplateSplitScreenModal.utils';
import { useLinkedEntities } from './hooks/useLinkedEntities';
import { DispositiveProvisionsTemplateABCTrustPreset } from './presets/DispositiveProvisionsTemplateSplitScreenModal.abcTrust';
import { DispositiveProvisionsTemplateABTrustPreset } from './presets/DispositiveProvisionsTemplateSplitScreenModal.abTrust';
import { DispositiveProvisionsTemplateMaritalTrustPreset } from './presets/DispositiveProvisionsTemplateSplitScreenModal.maritalTrust';
import { DispositiveProvisionsTemplateOutrightToSurvivingSpousePreset } from './presets/DispositiveProvisionsTemplateSplitScreenModal.outrightToSurvivingSpouse';
import { DispositiveProvisionsTemplatePouroverWillPreset } from './presets/DispositiveProvisionsTemplateSplitScreenModal.pourover';

export interface DispositiveProvisionsTemplateHeaderProps {
  onDelete: () => void;
  isExistingTemplate: boolean;
}

export function DispositiveProvisionsTemplateHeader({
  onDelete,
  isExistingTemplate,
}: DispositiveProvisionsTemplateHeaderProps) {
  const [isEditing, setEditing] = useState(false);
  const { control, setValue } =
    useFormContext<DispositiveProvisionsTemplateShape>();

  const [displayName] = useWatch({
    control,
    name: [`${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.displayName`],
  });

  return isEditing ? (
    <TextInput
      value={displayName}
      autoFocus
      onChange={(e) => {
        setValue(
          `${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.displayName`,
          e.target.value
        );
      }}
      onBlur={() => setEditing(false)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') setEditing(false);
      }}
      label="Template name"
      hideLabel
      placeholder="Template name"
      required
    />
  ) : (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h2">
          {displayName || 'Untitled template'}
        </Typography>
        <Button
          variant="transparent"
          size="sm"
          onClick={() => setEditing(true)}
          startIcon={Edit02Icon}
        />
      </Stack>
      {isExistingTemplate && (
        <Stack direction="row" spacing={2} alignItems="center">
          <TemplateUseCountBadge />
          <ContextMenu onDelete={onDelete} />
        </Stack>
      )}
    </Stack>
  );
}

interface ContextMenuProps {
  onDelete: () => void;
}
function ContextMenu({ onDelete }: ContextMenuProps) {
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();
  return (
    <>
      <ContextMenuButton>
        <MenuItem
          label={
            <Typography
              sx={{ color: COLORS.FUNCTIONAL.ERROR.DEFAULT }}
              variant="body1"
            >
              Delete template
            </Typography>
          }
          onClick={openModal}
          icon={<Trash01Icon color={COLORS.FUNCTIONAL.ERROR.DEFAULT} />}
        />
      </ContextMenuButton>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={(reason) => {
          if (reason === 'confirm') {
            onDelete();
          }
          closeModal();
        }}
        confirmText="Delete"
        confirmButtonProps={{
          variant: 'destructive-prominent',
        }}
        cancelText="Cancel"
        heading="Are you sure you wish to delete this template?"
      >
        Entities currently using this template will be reset and will require
        new dispositions to be added
      </ConfirmationModal>
    </>
  );
}

function TemplateUseCountBadge() {
  const { entities, individuals, loading } = useLinkedEntities();
  const templateUseCount = entities.length + individuals.length;

  if (loading) return <Skeleton variant="text" width={40} />;
  if (templateUseCount === 0) return null;

  return (
    <Badge
      display={`Linked to ${templateUseCount} ${templateUseCount === 1 ? 'entity' : 'entities'}`}
      variant={BadgeVariants.Yellow}
    />
  );
}

export interface DispositiveProvisionsTemplateLinkedEntitiesProps {
  isFromEditingDispositions: boolean;
  setDispositiveProvisionsModalDetails?: (
    details: DispositiveProvisionsModalDetails | null
  ) => void;
}

export function DispositiveProvisionsTemplateLinkedEntities({
  isFromEditingDispositions,
  setDispositiveProvisionsModalDetails,
}: DispositiveProvisionsTemplateLinkedEntitiesProps) {
  const { control } = useFormContext<DispositiveProvisionsTemplateShape>();

  const [templateId] = useWatch({
    control,
    name: [`${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.id`],
  });

  const { entities, individuals } = useLinkedEntities();

  const entityRows = mapEntityDispositionScenariosToLinkedTable(
    entities,
    templateId,
    setDispositiveProvisionsModalDetails
  );

  const individualRows = mapClientProfileScenariosToLinkedTable(
    individuals,
    templateId,
    setDispositiveProvisionsModalDetails
  );

  const rows = [...entityRows, ...individualRows];
  return (
    <>
      <Stack divider={<Divider sx={{ borderColor: COLORS.GRAY[200] }} />}>
        {rows.length === 0 && (
          <Typography variant="body1">
            This template is not used on any entity
          </Typography>
        )}
        {rows.map(({ lineOne, lineTwo, id, onClick }) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            key={id}
            sx={{
              p: 1.5,
              cursor: isFromEditingDispositions ? 'cursor' : 'pointer',
            }}
            role={isFromEditingDispositions ? undefined : 'button'}
            tabIndex={0}
            onClick={isFromEditingDispositions ? noop : onClick}
          >
            <RichListItem heading={lineOne} description={lineTwo} />
            {isFromEditingDispositions ? null : (
              <ChevronRightIcon sx={{ color: COLORS.GRAY[500] }} />
            )}
          </Stack>
        ))}
      </Stack>
    </>
  );
}

export function DispositiveProvisionsTemplateEditorTab() {
  const { control } = useFormContext<DispositiveProvisionsTemplateShape>();
  const [templateType] = useWatch({
    control,
    name: [`${DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE}.templateType`],
  });

  let editorContent: ReactNode = null;

  switch (templateType) {
    case DispositiveProvisionTemplateKind.PouroverWill:
      editorContent = <DispositiveProvisionsTemplatePouroverWillPreset />;
      break;
    case DispositiveProvisionTemplateKind.OutrightToSurvivingSpouse:
      editorContent = (
        <DispositiveProvisionsTemplateOutrightToSurvivingSpousePreset />
      );
      break;
    case DispositiveProvisionTemplateKind.MaritalTrust:
      editorContent = <DispositiveProvisionsTemplateMaritalTrustPreset />;
      break;
    case DispositiveProvisionTemplateKind.AbTrust:
      editorContent = <DispositiveProvisionsTemplateABTrustPreset />;
      break;
    case DispositiveProvisionTemplateKind.AbcTrust:
      editorContent = <DispositiveProvisionsTemplateABCTrustPreset />;
      break;
    case DispositiveProvisionTemplateKind.Default:
    default:
      editorContent = (
        <DispositiveProvisionsRecipients
          // because templates are death-order agnostic, there's no death order
          // for a disposition scheme to apply to; instead, the template will
          // be attached to an existing disposition scheme
          onOpenSchemeSelectionModal={null}
          simulationResults={undefined}
          defaultExpandAll
        />
      );
  }

  return editorContent;
}
