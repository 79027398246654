import { Box } from '@mui/material';
import { compact, includes } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SetRequired } from 'type-fest';

import { Button } from '@/components/form/baseInputs/Button';
import { DropdownButton } from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { LinkExternal01Icon } from '@/components/icons/LinkExternal01Icon';
import { SidePanel } from '@/components/modals/SidePanel';
import {
  EntityDetails,
  EntityDetailTab,
  EntityTabComponentMap,
} from '@/modules/entities/components/EntityDetails';
import { EntityDetailsAwareRoute } from '@/modules/entities/contexts/entityDetails/EntityDetailsAwareRoute';
import { useEntityPageDefinition } from '@/modules/entities/details/EntityDetailOverviewPage';
import {
  BusinessEntityDetailsTabs,
  NonTrustCharitableEntityDetailsTabs,
  PersonalAccountDetailsTabs,
  TrustDetailsTabs,
} from '@/modules/entities/details/entityDetails.types';
import { getSectionForEntityDetailsActiveTab } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.utils';
import { EditEntitySplitScreenOpenModalButton } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreenOpenModalButton';
import { EntityFormEditButton } from '@/modules/entities/EntityForm/EntityFormEditButton';
import { EntitySubformNamespaceType } from '@/modules/entities/EntitySubforms/entitySubformConfigs';
import { getStageAwareLinkForEntity } from '@/modules/entities/utils/entitiesNavigationUtils';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';

import { SummaryPanelLoader } from '../components/SummaryPanelLoader';

export interface EntitySummaryPanelProps {
  entityId: string;
  householdId: string;
  onClose: () => void;
  isOnHypotheticalWaterfall: boolean;
  isHypotheticalEntity: boolean;
  isSummaryView?: boolean;
  isOnSourceWaterfall?: boolean;
}

const AssetsTab = 'Assets';
const TransfersTab = 'Transfers';
const CashFlowsTab = 'CashFlows';

export const sidePanelSummaryTabs = [
  TrustDetailsTabs.SUMMARY,
  NonTrustCharitableEntityDetailsTabs.SUMMARY,
  PersonalAccountDetailsTabs.SUMMARY,
  BusinessEntityDetailsTabs.SUMMARY,
];

// Show the footer for tabs associated with
// the entity details input form
const footerVisibleTabs = [
  ...sidePanelSummaryTabs,
  TrustDetailsTabs.BENEFICIARIES,
  TrustDetailsTabs.POLICIES,
  TrustDetailsTabs.TRUSTEES,
  NonTrustCharitableEntityDetailsTabs.BENEFICIARIES,
  PersonalAccountDetailsTabs.BENEFICIARIES,
  BusinessEntityDetailsTabs.OWNERSHIP,
  BusinessEntityDetailsTabs.KEY_PEOPLE,
];

type CurrentView =
  | EntityDetailTab
  | { display: string; value: typeof AssetsTab }
  | { display: string; value: typeof TransfersTab }
  | { display: string; value: typeof CashFlowsTab };

const CURRENT_VIEW_TYPE_TO_ENTITY_SUBFORM_MAP: Partial<
  Record<CurrentView['value'], EntitySubformNamespaceType>
> = {
  trustees: 'trustDetailsSubform',
  beneficiaries: 'beneficiariesSubform',
  summary: 'basicInformationSubform',
  policies: 'insurancePolicyDetailsSubform',
};

export const EntitySummaryPanel = ({
  entityId,
  onClose,
  isOnHypotheticalWaterfall = false,
  isHypotheticalEntity = false,
  isSummaryView = false,
  isOnSourceWaterfall = false,
}: EntitySummaryPanelProps) => {
  const [currentView, setCurrentView] = useState<CurrentView>();
  const {
    entity,
    entityPageDefinition: {
      summary: summaryTabs,
      AssetsCard,
      TransfersCard,
      CashFlowsCard,
    },
    loading,
  } = useEntityPageDefinition(entityId, { isPanelView: true });

  const summaryTabsWithAssetsAndTransfers: CurrentView[] | undefined =
    useMemo(() => {
      if (loading) {
        return;
      }

      return [
        ...summaryTabs,
        {
          display: 'Holdings',
          value: AssetsTab,
          props: {},
        },
        {
          display: TransfersTab,
          value: TransfersTab,
          props: {},
        },
        {
          display: 'Cash flows',
          value: CashFlowsTab,
          props: {},
        },
      ];
    }, [loading, summaryTabs]);

  const items = useMemo(() => {
    if (!summaryTabsWithAssetsAndTransfers) {
      return [];
    }

    return compact(
      summaryTabsWithAssetsAndTransfers.flatMap(({ display, value }) => {
        // Hide transfers tab when not on a hypothetical waterfall or hypothetical waterfall
        // summary view
        if (!isOnHypotheticalWaterfall && value === TransfersTab) {
          return [];
        }

        // Hide assets tab for draft entities since
        // we don't want to confuse users. Draft entity
        // assets are not accounted for when calculating hypothetical
        // waterfall values, so this asset value could differ from
        // the value shown in the waterfall.
        if (isHypotheticalEntity && value === AssetsTab) {
          return [];
        }

        return {
          name: display,
          clickHandler: () => setCurrentView({ display, value }),
        };
      })
    );
  }, [
    isHypotheticalEntity,
    isOnHypotheticalWaterfall,
    summaryTabsWithAssetsAndTransfers,
  ]);

  const handleSetCurrentView = useCallback(
    (tabs: CurrentView[]) => {
      if (isOnHypotheticalWaterfall) {
        // Set the initial tab to the transfers tab
        // when on a hypothetical waterfall
        setCurrentView(tabs.find((t) => t.value === TransfersTab) ?? tabs[0]);
      } else if (isOnSourceWaterfall) {
        // Set the inital tab to the dispositions tab
        // when on a source waterfall
        setCurrentView(
          tabs.find(
            (t) =>
              t.value === TrustDetailsTabs.DISPOSITIONS ||
              t.value === PersonalAccountDetailsTabs.DISPOSITIONS ||
              t.value === NonTrustCharitableEntityDetailsTabs.DISPOSITIONS
          ) ?? tabs[0]
        );
      } else {
        setCurrentView(tabs[0]);
      }
    },
    [isOnHypotheticalWaterfall, isOnSourceWaterfall]
  );

  useEffect(
    function handleSetCurrentViewOnLoad() {
      // Once our available tabs are loaded, set the current view
      // using the tab reset handler.
      if (summaryTabsWithAssetsAndTransfers && !currentView) {
        handleSetCurrentView(summaryTabsWithAssetsAndTransfers);
      }
    },
    [
      currentView,
      isSummaryView,
      handleSetCurrentView,
      summaryTabsWithAssetsAndTransfers,
    ]
  );

  useEffect(
    function handleResetCurrentViewOnTabsChange() {
      // If the current view is not in the available tabs
      // call the reset handler to set the default current view.
      if (currentView && summaryTabsWithAssetsAndTransfers) {
        const activeTab =
          summaryTabsWithAssetsAndTransfers.find(
            (tab) => tab.value === currentView.value
          ) ?? null;

        if (!activeTab) {
          handleSetCurrentView(summaryTabsWithAssetsAndTransfers);
        }
      }
    },
    [currentView, handleSetCurrentView, summaryTabsWithAssetsAndTransfers]
  );

  const initialFocusedSubformType = currentView?.value
    ? CURRENT_VIEW_TYPE_TO_ENTITY_SUBFORM_MAP[currentView.value]
    : undefined;

  const initialSectionOptions = useMemo(() => {
    if (!currentView?.value || !entity?.kind) {
      return undefined;
    }

    function isEntityDetailTab(view: CurrentView): view is EntityDetailTab {
      return Object.keys(EntityTabComponentMap).includes(view.value);
    }

    if (isEntityDetailTab(currentView)) {
      return getSectionForEntityDetailsActiveTab(
        currentView.value,
        getEntityTypeFromEntityKind(entity.kind)
      );
    }

    return undefined;
  }, [currentView, entity?.kind]);

  return (
    <EntityDetailsAwareRoute entityId={entityId}>
      <SidePanel.Panel data-testid="EntitySummaryPanel">
        <SidePanel.Header
          title={entity?.subtype?.displayName}
          onClose={onClose}
        >
          <DropdownButton
            showArrow
            name="Dropdown-selectView"
            variant="secondary"
            size="sm"
            buttonContent={currentView?.display || ''}
            items={items}
            contentBoxSx={{ width: '100%', justifyContent: 'space-between' }}
          />
        </SidePanel.Header>
        <SidePanel.Content>
          <SidePanel.Section>
            {loading && <SummaryPanelLoader />}
            <Box display={loading ? 'none' : 'block'}>
              {currentView &&
                currentView.value !== AssetsTab &&
                currentView.value !== TransfersTab &&
                currentView.value !== CashFlowsTab && (
                  <EntityDetails
                    tabs={summaryTabs}
                    activeEntityView={currentView.value}
                  />
                )}
              {currentView?.value === AssetsTab && AssetsCard}
              {currentView?.value === TransfersTab && TransfersCard}
              {currentView?.value === CashFlowsTab && CashFlowsCard}
            </Box>
          </SidePanel.Section>
        </SidePanel.Content>
        {includes(footerVisibleTabs, currentView?.value) && (
          <SidePanel.Footer>
            <Button
              size="sm"
              variant="primary"
              target="_blank"
              href={
                entity
                  ? getStageAwareLinkForEntity(
                      // TODO: for some reason our graphql codegen is not marking __typename as defined when it should be
                      entity as Omit<NonNullable<typeof entity>, 'subtype'> & {
                        subtype: SetRequired<
                          NonNullable<typeof entity>['subtype'],
                          '__typename'
                        >;
                      },
                      isHypotheticalEntity
                    )
                  : ''
              }
              disabled={loading}
            >
              <Box display="flex" alignItems="center" gap={1}>
                Go to entity
                <LinkExternal01Icon />
              </Box>
            </Button>
            {initialSectionOptions ? (
              <EditEntitySplitScreenOpenModalButton
                initialSection={
                  initialSectionOptions.length === 1
                    ? initialSectionOptions[0]
                    : undefined
                }
                initialSectionOptions={
                  initialSectionOptions.length === 1
                    ? undefined
                    : initialSectionOptions
                }
              />
            ) : (
              <EntityFormEditButton
                householdId={entity?.household.id ?? ''}
                entityId={entity?.id ?? ''}
                entityType={
                  entity?.kind && getEntityTypeFromEntityKind(entity.kind)
                }
                disabled={loading}
                isHypotheticalEntity={isHypotheticalEntity}
                initialFocusedSubformType={initialFocusedSubformType}
              />
            )}
          </SidePanel.Footer>
        )}
      </SidePanel.Panel>
    </EntityDetailsAwareRoute>
  );
};
