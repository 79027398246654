import { Box, Stack, styled, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { map } from 'lodash';
import { useEffect, useState } from 'react';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { Card, CardProps } from '@/components/layout/Card/Card';
import { Loader } from '@/components/progress/Loader/Loader';
import { AssetIntegrationProviders } from '@/modules/assetProviderIntegrations/shared/constants';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { COLORS } from '@/styles/tokens/colors';
import { AsyncJobStatus } from '@/types/schema';

import { EntityDetail_IntegrationEntityFragment } from '../../graphql/EntityDetailPage.generated';
import { InitialIntegrationSyncError } from '../../shared/InitialIntegrationSyncError';
import { EntitySyncBadge } from './EntitySyncBadge';
import { EntityValuationBreakdown } from './EntityValuationBreakdown';
import { EntityValuationItemProps } from './EntityValuationItem';
import { EntityValuationSumLine } from './EntityValuationSumLine';

interface AssetPropertyGroup {
  /**
   * If two different groups names are passed, the asset property
   * items will be broken up into two two different groups below a buttonGroup
   */
  groupName: string | null;
  items: EntityValuationItemProps[];
}

export interface IntegrationDetails {
  integrationEntities: EntityDetail_IntegrationEntityFragment[];
  ingestJobStatus: AsyncJobStatus;
  lastSyncedAt: Date | null;
  provider: AssetIntegrationProviders;
}

/**
 * EntityValuationVariant describes the styling pattern of the EntityValuationCard.
 * card - renders with a border and padding
 * plain - renders with no border or external padding
 */
export type EntityValuationVariant = 'card' | 'plain';

export interface EntityValuationCardProps {
  headlineValueLabel: React.ReactNode;
  headlineValue: React.ReactNode;
  headlineTooltip?: React.ReactNode;
  notes?: React.ReactNode;
  integrationDetails: IntegrationDetails | null;
  /** If present, sumLineValue will render as a tally line below the breakdown of the assets */
  sumLineValue?: Decimal;
  hasValidValuation: boolean;
  assetPropertyGroups: AssetPropertyGroup[];
  variant: EntityValuationVariant;
  actions: React.ReactNode;
  dateOfValuation?: Date;
}

export const HeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  // @container selections are not native to MUI yet, but will be soon.
  // See: https://github.com/mui/material-ui/issues/25189#issuecomment-1321236185
  [theme.breakpoints.down(280).replace('@media', '@container')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

const HORIZONTAL_PADDING = 1.5;

const cardPropsForVariant: Record<EntityValuationVariant, CardProps> = {
  card: { variant: 'outlined', sx: { p: 3 } },
  plain: { variant: 'transparent', sx: {} },
};

/**
 * @description EntityValuationCard is a presentational component that displays a breakdown of the
 * entity's valuation and exposes a set of possible actions to take to update the valuation
 */
export function EntityValuationCard({
  headlineValue,
  headlineValueLabel,
  headlineTooltip,
  actions,
  variant,
  ...valuationContentProps
}: EntityValuationCardProps) {
  const { integrationDetails } = valuationContentProps;
  const { sx, ...cardProps } = cardPropsForVariant[variant];

  return (
    <Card {...cardProps} sx={{ height: '100%', ...sx }}>
      <Stack spacing={3} sx={{ containerType: 'inline-size' }}>
        <HeaderWrapper data-name="HeaderWrapper">
          <OversizedMetricItem
            titleVariant="h4"
            valueVariant="h1"
            valueColor={COLORS.NAVY[600]}
            tooltip={
              headlineTooltip && (
                // need inline-block here to make sure that the tooltip is rendered
                // inline with the headline
                <ContextualHelpTooltip sx={{ display: 'inline-block' }}>
                  {headlineTooltip}
                </ContextualHelpTooltip>
              )
            }
            title={headlineValueLabel}
            value={headlineValue}
          />
          {integrationDetails && (
            <EntitySyncBadge
              sx={{ flexShrink: 0 }}
              lastJobStatus={integrationDetails.ingestJobStatus}
              lastSyncedAt={integrationDetails.lastSyncedAt}
            />
          )}
        </HeaderWrapper>
        <ValuationOrIntegrationContent {...valuationContentProps} />
        <Box mt={3}>{actions}</Box>
      </Stack>
    </Card>
  );
}

interface ValuationOrIntegrationContentProps extends ValuationContentProps {
  integrationDetails: IntegrationDetails | null;
  hasValidValuation: boolean;
}

function ValuationOrIntegrationContent({
  integrationDetails,
  hasValidValuation,
  ...props
}: ValuationOrIntegrationContentProps) {
  // if there's a sync job in progress, always show the loader
  if (integrationDetails?.ingestJobStatus == AsyncJobStatus.InProgress) {
    return (
      <Loader
        boxProps={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
        }}
        circularProgressProps={{
          size: 80,
        }}
      />
    );
  }

  // we only want to show the error state in this way if the initial sync job is
  // failed and we have nothing to fall back on, otherwise we'd want to show the normal valuation content
  // and rely on the integration badge to show a more subtle badge to indicate that just the most recent
  // sync failed
  if (
    integrationDetails?.ingestJobStatus === AsyncJobStatus.Failed &&
    !hasValidValuation
  ) {
    return (
      <InitialIntegrationSyncError provider={integrationDetails.provider} />
    );
  }

  return <ValuationContent {...props} />;
}

interface ValuationContentProps {
  assetPropertyGroups: AssetPropertyGroup[];
  sumLineValue?: Decimal;
  notes?: React.ReactNode;
}

function ValuationContent({
  assetPropertyGroups,
  sumLineValue,
  notes,
}: ValuationContentProps) {
  const [entityBreakdownOption, setEntityBreakdownOption] = useState<
    string | null
  >(null);

  useEffect(
    function autoSelectFirstBreakdownOption() {
      // make sure that if we already have a selected group, if assetPropertyGroups changes and that
      // group is still present, we keep it selected. this resolves a bug where when assetPropertyGroups
      // recomputed because of a new valuation, it would always reset the selected group to the first one
      const currentSelectedGroup = assetPropertyGroups.find(
        (g) => g.groupName === entityBreakdownOption
      );
      const selectedGroup =
        currentSelectedGroup?.groupName ??
        assetPropertyGroups[0]?.groupName ??
        null;
      setEntityBreakdownOption(selectedGroup);
    },
    [assetPropertyGroups, entityBreakdownOption]
  );

  const shouldFilterPropertyGroups = assetPropertyGroups.length > 1;
  return (
    <>
      {shouldFilterPropertyGroups && (
        <ButtonGroup
          options={assetPropertyGroups.map(({ groupName }) => ({
            display: groupName ?? 'Unknown',
            value: groupName ?? 'Unknown',
          }))}
          value={entityBreakdownOption}
          variant="dark"
          label="Entity breakdown options"
          hideLabel
          onChange={(_e, v) => setEntityBreakdownOption(v)}
        />
      )}
      {map(assetPropertyGroups, ({ items, groupName }) => {
        if (shouldFilterPropertyGroups && entityBreakdownOption !== groupName) {
          return null;
        }

        return (
          <Box key={groupName}>
            <EntityValuationBreakdown valuationItems={items} />
          </Box>
        );
      })}
      {sumLineValue && (
        <EntityValuationSumLine
          value={sumLineValue}
          padding={HORIZONTAL_PADDING}
        />
      )}
      <Typography variant="body1">{notes}</Typography>
    </>
  );
}
