import { assertNonNil } from '@/utils/assertUtils';
import { UnreachableError } from '@/utils/errors';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';
import { FlowChartGraph } from '@/utils/graphology/FlowChartGraph';

import {
  EntityGraphAttributes,
  EntityGraphBuilderFn,
  EntityGraphEdgeAttributes,
  EntityGraphNodeAttributes,
} from '../types';
import { createEdge } from '../utils/edges';
import { normalizeById } from '../utils/normalize';
import { createBeneficiaryNodeFromClientProfile } from './beneficiaries';
import { entitySubtypeToNodeCategorization } from './constants';
import {
  addGrantors,
  createGrantorNodeFromClientProfile,
  getGrantorOrIndividualNodeId,
} from './grantors';
import { entitySubtypeToTile } from './tiles';

export const createOwnershipEntityGraph: EntityGraphBuilderFn = (props) => {
  const { entities, possibleBeneficiaries, possibleGrantors, orientation } =
    props;
  const byId = normalizeById(props);
  const graph = new FlowChartGraph<
    EntityGraphNodeAttributes,
    EntityGraphEdgeAttributes,
    EntityGraphAttributes
  >();

  graph.setAttribute('byId', byId);
  graph.setAttribute('orientation', orientation);

  possibleBeneficiaries.clients.forEach((cp) => {
    if (!cp.ownedOwnershipStakes?.length) return;
    const node = createBeneficiaryNodeFromClientProfile(graph, cp);
    graph.addNodeSafe(node.node.id, node);
  });

  possibleGrantors.forEach((cp) => {
    if (!cp.ownedOwnershipStakes?.length) return;
    const node = createGrantorNodeFromClientProfile(graph, cp)!;
    graph.addNodeSafe(node.node.id, node);
  });

  // Ensure we add all non business entities to the graph first,
  // so we can utilize looking them when building the business entities
  const sortedEntities = entities
    .slice()
    .sort((e) => (e.subtype.__typename?.includes('BusinessEntity') ? 1 : -1));

  sortedEntities.forEach(function addEntityToGraph(entity) {
    const supportedEntityTypename = entity.subtype.__typename!;
    const entityTile = entitySubtypeToTile[supportedEntityTypename](entity);
    graph.addNodeSafe(entityTile.id, {
      node: entityTile,
      data: entity,
      categorizationType:
        entitySubtypeToNodeCategorization[supportedEntityTypename],
    });

    switch (entity.subtype.__typename) {
      case 'GRATTrust':
      case 'SLATTrust': {
        const { grantor } = entity.subtype;
        addGrantors(graph, entity.id, [grantor]);
        return;
      }

      case 'RetirementPersonalAccount':
      case 'QualifiedTuitionPersonalAccount':
      case 'CustodialPersonalAccount':
      case 'IndividualPersonalAccount': {
        const { owner } = entity.subtype;
        addGrantors(graph, entity.id, [owner]);
        return;
      }

      case 'JointPersonalAccount': {
        const { owners } = entity.subtype;
        addGrantors(graph, entity.id, owners);
        return;
      }

      case 'InsurancePersonalAccount': {
        const { owners } = entity.subtype;
        addGrantors(graph, entity.id, owners);
        return;
      }

      case 'DonorAdvisedFund':
      case 'PrivateFoundation': {
        const { donors } = entity.subtype;
        addGrantors(graph, entity.id, donors);
        return;
      }

      case 'ILITTrust':
      case 'IrrevocableTrust':
      case 'RevocableTrust':
      case 'QPRTTrust':
      case 'CLTTrust':
      case 'CRTTrust': {
        const { grantors } = entity.subtype;
        addGrantors(graph, entity.id, grantors);
        return;
      }

      case 'SoleProprietorshipBusinessEntity':
      case 'CCorpBusinessEntity':
      case 'LLCBusinessEntity':
      case 'LPBusinessEntity':
      case 'SCorpBusinessEntity':
      case 'GPBusinessEntity': {
        const { ownedByStakes } = entity;
        ownedByStakes?.forEach((stake) => {
          const target = entity.id;
          let source: string | null = null;

          if (stake.owningEntity?.id) {
            source = stake.owningEntity.id;
          } else if (stake.owningIndividual?.id) {
            source = getGrantorOrIndividualNodeId(
              graph,
              stake.owningIndividual.id
            );
          }

          if (!source) {
            throw new Error(
              `Could not find source for ${supportedEntityTypename} ${entity.id}`
            );
          }

          if (stake.owningEntity && !graph.hasNode(source)) {
            const entityToAdd = assertNonNil(
              byId.entitiesById[source],
              `No entity for ${source}`
            );
            addEntityToGraph(entityToAdd);
          }

          const percent = stake.ownershipPercentage
            ? `${formatPercent(stake.ownershipPercentage, 2)}%`
            : '';

          const value = `(${formatCurrency(stake.ownedValue, {
            notation: 'compact',
          })})`;

          const edge = createEdge(graph, {
            source,
            target,
            data: {
              edgeLabel: { label: '', value: [percent, value].join(' ') },
            },
          });

          graph.addEdgeSafe(source, target, {
            type: 'ownership-percentage',
            edge,
          });
        });
        return;
      }

      default:
        throw new UnreachableError({
          // Type fix for undefined __typename
          case: entity.subtype.__typename as NonNullable<
            typeof entity.subtype.__typename
          >,
          message: `Unhandled entity subtype of ${entity.subtype.__typename}`,
        });
    }
  });

  return graph;
};
