// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssociatedEntitiesModalQueryVariables = Types.Exact<{
  entityIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type AssociatedEntitiesModalQuery = { __typename?: 'Query', entities: { __typename?: 'EntityConnection', edges?: Array<{ __typename?: 'EntityEdge', node?: { __typename?: 'Entity', id: string, subtype: { __typename?: 'CCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'CLTTrust', id: string, displayName: string } | { __typename?: 'CRTTrust', id: string, displayName: string } | { __typename?: 'CustodialPersonalAccount', id: string, displayName: string } | { __typename?: 'DonorAdvisedFund', id: string, displayName: string } | { __typename?: 'GPBusinessEntity', id: string, displayName: string } | { __typename?: 'GRATTrust', id: string, displayName: string } | { __typename?: 'ILITTrust', id: string, displayName: string } | { __typename?: 'IndividualPersonalAccount', id: string, displayName: string } | { __typename?: 'InsurancePersonalAccount', id: string, displayName: string } | { __typename?: 'IrrevocableTrust', id: string, displayName: string } | { __typename?: 'JointPersonalAccount', id: string, displayName: string } | { __typename?: 'LLCBusinessEntity', id: string, displayName: string } | { __typename?: 'LPBusinessEntity', id: string, displayName: string } | { __typename?: 'PrivateFoundation', id: string, displayName: string } | { __typename?: 'QPRTTrust', id: string, displayName: string } | { __typename?: 'QualifiedTuitionPersonalAccount', id: string, displayName: string } | { __typename?: 'RetirementPersonalAccount', id: string, displayName: string } | { __typename?: 'RevocableTrust', id: string, displayName: string } | { __typename?: 'SCorpBusinessEntity', id: string, displayName: string } | { __typename?: 'SLATTrust', id: string, displayName: string } | { __typename?: 'SoleProprietorshipBusinessEntity', id: string, displayName: string } } | null } | null> | null } };


export const AssociatedEntitiesModalDocument = gql`
    query AssociatedEntitiesModal($entityIds: [ID!]!) {
  entities(where: {idIn: $entityIds}) {
    edges {
      node {
        id
        subtype {
          id
          displayName
        }
      }
    }
  }
}
    `;

/**
 * __useAssociatedEntitiesModalQuery__
 *
 * To run a query within a React component, call `useAssociatedEntitiesModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssociatedEntitiesModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssociatedEntitiesModalQuery({
 *   variables: {
 *      entityIds: // value for 'entityIds'
 *   },
 * });
 */
export function useAssociatedEntitiesModalQuery(baseOptions: Apollo.QueryHookOptions<AssociatedEntitiesModalQuery, AssociatedEntitiesModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssociatedEntitiesModalQuery, AssociatedEntitiesModalQueryVariables>(AssociatedEntitiesModalDocument, options);
      }
export function useAssociatedEntitiesModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssociatedEntitiesModalQuery, AssociatedEntitiesModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssociatedEntitiesModalQuery, AssociatedEntitiesModalQueryVariables>(AssociatedEntitiesModalDocument, options);
        }
export function useAssociatedEntitiesModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AssociatedEntitiesModalQuery, AssociatedEntitiesModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AssociatedEntitiesModalQuery, AssociatedEntitiesModalQueryVariables>(AssociatedEntitiesModalDocument, options);
        }
export type AssociatedEntitiesModalQueryHookResult = ReturnType<typeof useAssociatedEntitiesModalQuery>;
export type AssociatedEntitiesModalLazyQueryHookResult = ReturnType<typeof useAssociatedEntitiesModalLazyQuery>;
export type AssociatedEntitiesModalSuspenseQueryHookResult = ReturnType<typeof useAssociatedEntitiesModalSuspenseQuery>;
export type AssociatedEntitiesModalQueryResult = Apollo.QueryResult<AssociatedEntitiesModalQuery, AssociatedEntitiesModalQueryVariables>;
export const Operations = {
  Query: {
    AssociatedEntitiesModal: 'AssociatedEntitiesModal' as const
  }
}