import { Row } from '@/pages/ClientDetailsPage/ClientDetailsPerformancePage/ClientValuePerformanceDashboard.utils';

export interface Page {
  rows: Row[];
}

const FIRST_PAGE_ROW_COUNT = 6;
const PAGE_ROW_COUNT = 8;

function isLastRowHeaderRow(lastRowIndex: number, allRows: Row[]): boolean {
  const lastRow = allRows[lastRowIndex];
  const nextRow = allRows[lastRowIndex + 1];
  if (!lastRow) {
    throw new Error(
      `could not get last row ${lastRowIndex} out of ${allRows.length}`
    );
  }

  if (!nextRow) {
    return false;
  }

  return nextRow.hierarchy.includes(lastRow.id);
}

export function splitRowsForPrint(rows: Row[]): Page[] {
  const output: Page[] = [];
  // 0: if there aren't enough rows to fill the first page, skip all the logic
  if (rows.length <= FIRST_PAGE_ROW_COUNT) {
    return [{ rows }];
  }

  let currentIndex = 0;
  do {
    // determine the intended page size -- the first page should be shorter because it has the headers, while
    // the last page can be full length because the footer will break naturally
    const pageRowOffset =
      currentIndex === 0 ? FIRST_PAGE_ROW_COUNT : PAGE_ROW_COUNT;

    // determine where the page should end, if full.  if too long, shorten to the list of rows
    let lastRowIndex = currentIndex + pageRowOffset;
    if (lastRowIndex >= rows.length) {
      lastRowIndex = rows.length;
    }

    // get the intended set of rows
    const currentPageRows: Row[] = rows.slice(currentIndex, lastRowIndex);

    const firstRow = rows[currentIndex];

    if (!firstRow) {
      throw new Error('Could not get first row'); // necessary for type safety
    }

    // if the row is in a hierarchy, repeat the rows of that hierarchy above,
    // or they'll be rendered as blank rows
    if (firstRow.hierarchy.length > 1) {
      // iterate through the reversed hierarchy (since elements will be getting pushed on the front of the array)
      [...firstRow.hierarchy].reverse().forEach((label) => {
        // find the original iteration of the id
        const targetRow = rows.find((row) => row.id === label);

        // if found, and its not itself (don't duplicate), push it on the front of the array
        if (targetRow && targetRow.id !== firstRow.id) {
          currentPageRows.unshift(targetRow);

          // if pushing this onto the page made the page too big, remove the last element from the end
          // of the array, and decrement the lastRowIndex so it'll get picked up on the next page
          if (currentPageRows.length === pageRowOffset) {
            currentPageRows.pop();
            lastRowIndex--;
          }
        }
      });
    }

    // don't end on header row(s), since they'll be duplicated on the next page
    while (isLastRowHeaderRow(lastRowIndex - 1, rows)) {
      currentPageRows.pop();
      lastRowIndex--;
    }

    // add the page to the output
    output.push({
      rows: currentPageRows,
    });

    // prep for the next page
    currentIndex = lastRowIndex;
  } while (currentIndex < rows.length); // iterate through as long as there are more rows to show

  return output;
}
