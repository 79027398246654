import { CharitableBeneficiaryFormBodyV2 } from './CharitableBeneficiaryFormBodyV2';
import { EntityBeneficiariesFormBodyProps } from './EntityBeneficiariesSubform.types';

interface CharitableBeneficaryFormBodyProps {
  control: EntityBeneficiariesFormBodyProps['control'];
  beneficiaryOptions: EntityBeneficiariesFormBodyProps['beneficiaryOptions'];
  householdId: EntityBeneficiariesFormBodyProps['householdId'];
  namespace:
    | 'leadBeneficiarySubform.leadBeneficiary'
    | 'incomeBeneficiarySubform.incomeBeneficiary';
  splitScreen: boolean;
}

export function CharitableBeneficiaryFormBody(
  props: CharitableBeneficaryFormBodyProps
) {
  return <CharitableBeneficiaryFormBodyV2 {...props} />;
}
