import { Stack } from '@mui/material';

import { Legend } from '@/components/charts/Legend/Legend';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { EstateWaterfallSummaryBarCharts } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/EstateWaterfallSummaryBarCharts';
import { useEstateWaterfallSummaryLegendSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useEstateWaterfallSummaryLegend.hook';
import { useWaterfallSummaryBarChartSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useWaterfallSummaryBarChartSections';
import {
  EstateWaterfallSummaryBarChartsSections,
  WaterfallSection,
} from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/types';
import { EstateWaterfallSummaryTiles } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryTiles/EstateWaterfallSummaryTiles';
import { GetWaterfallSummary_EstateWaterfallFragment } from '@/modules/estateWaterfall/graphql/GetWaterfallSummary.generated';
import { ROUTE_KEYS } from '@/navigation/constants';
import { COLORS } from '@/styles/tokens/colors';

export interface WaterfallLoadedContentProps {
  waterfall: GetWaterfallSummary_EstateWaterfallFragment;
}
export const WaterfallLoadedContent = ({
  waterfall,
}: WaterfallLoadedContentProps) => {
  const { navigate } = useNavigateToRoute();

  const waterfallSections = useWaterfallSummaryBarChartSections({
    waterfall,
    onOpenSectionSummaryPanel: (section: WaterfallSection) => {
      if (
        section.kind !==
        EstateWaterfallSummaryBarChartsSections.GiftAndEstateTax
      ) {
        return;
      }

      navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_TAX_OVERVIEW, {
        waterfallId: waterfall.id,
        householdId: waterfall.household?.id ?? '',
      });
    },
  });

  const legendSections =
    useEstateWaterfallSummaryLegendSections(waterfallSections);

  return (
    <>
      <Stack sx={{ p: 2.5 }}>
        <EstateWaterfallSummaryBarCharts sections={waterfallSections} />
      </Stack>
      <Stack sx={{ paddingBottom: 2.5 }}>
        <EstateWaterfallSummaryTiles waterfall={waterfall} />
      </Stack>
      <Stack
        width="fit-content"
        sx={{ backgroundColor: COLORS.GRAY[100] }}
        pb={1}
        paddingLeft={1}
        paddingRight={1}
      >
        <Legend sections={legendSections} />
      </Stack>
    </>
  );
};
