import { createValueToDisplayLookup } from '@/types/definitionsUtils';
import { AssetValueV2OwnershipType } from '@/types/schema';

export const ASSET_OWNERSHIP_TYPES_ITEMS = [
  { display: 'By market value', value: AssetValueV2OwnershipType.ValueBased },
  { display: 'By share', value: AssetValueV2OwnershipType.ShareBased },
  { display: 'By ownership %', value: AssetValueV2OwnershipType.PercentBased },
] as const;
export { AssetValueV2OwnershipType };
export const VALUATION_METHOD_OPTIONS = ASSET_OWNERSHIP_TYPES_ITEMS.slice(); // slice to make it non-readonly
export const VALUATION_METHOD_DISPLAY_BY_VALUE = createValueToDisplayLookup(
  VALUATION_METHOD_OPTIONS
);
