import { cx } from '@emotion/css';
import {
  GridGroupingColDefOverride,
  GridRowClassNameParams,
} from '@mui/x-data-grid-pro';

import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { UnreachableError } from '@/utils/errors';

import {
  EntityRow,
  GroupKind,
  GroupRow,
  OwnershipStakeRow,
  Row,
} from './EntityMultiSelectTable.types';

export function isGroupKind(value: string): value is GroupKind {
  return value in GroupKind;
}

export function isEntityRow(row: Row): row is EntityRow {
  return 'entityId' in row;
}

export function isIndividualRow(row: Row): row is OwnershipStakeRow {
  return 'individualId' in row;
}

export function isGroupRow(row: Row): row is GroupRow {
  return 'group' in row;
}

export const groupingColDef: GridGroupingColDefOverride<Row> = {
  hideDescendantCount: true,
  headerName: '',
  flex: 2,
  disableColumnMenu: true,
  disableReorder: true,
  // use valueGetter instead of renderCell to preserve the hierarchical display and expand/collapse arrows
  valueGetter: (params) => <Renderer {...params} />,
};

export function getRowClassName({ row }: GridRowClassNameParams<Row>): string {
  const isGroup = isGroupRow(row);

  const shouldShowCursorIcon = isEntityRow(row);
  const shouldShowInEstate =
    isGroup &&
    (row.group === GroupKind.IN_ESTATE_JOINT ||
      row.group === GroupKind.IN_ESTATE_GRANTOR_A ||
      row.group === GroupKind.IN_ESTATE_GRANTOR_B);
  const shouldShowFamilyGiving =
    isGroup && row.group === GroupKind.OUT_OF_ESTATE_FAMILY_GIVING;
  const shouldShowCharitableGiving =
    isGroup && row.group === GroupKind.OUT_OF_ESTATE_CHARITY;

  return cx(
    isGroup && 'shouldShowBold',
    shouldShowCursorIcon && 'shouldShowCursorIcon',
    shouldShowInEstate && 'shouldShowInEstate',
    shouldShowFamilyGiving && 'shouldShowFamilyGiving',
    shouldShowCharitableGiving && 'shouldShowCharitableGiving'
  );
}

const Renderer = TextRenderer<Row>({
  text: ({ row }) => row.name,
  textProps: ({ row }) => ({
    sx: {
      fontWeight: isGroupRow(row) ? 'bold' : 'auto',
      '&:has(.textRendererWrapper)': {
        border: 'none !important',
      },
    },
  }),
});

export const getTreeDataPath = (row: Row) => row.path;

export function getGroupNameForGroupKind(
  group: GroupKind,
  grantors: {
    grantorA?: string;
    grantorB?: string;
  }
) {
  switch (group) {
    case GroupKind.IN_ESTATE_JOINT:
      return 'In estate (Jointly held assets)';
    case GroupKind.OUT_OF_ESTATE_FAMILY_GIVING:
      return 'Out of estate (Family)';
    case GroupKind.OUT_OF_ESTATE_CHARITY:
      return 'Out of estate (Charity)';
    case GroupKind.IN_ESTATE_GRANTOR_A:
      return `In estate (${grantors.grantorA}’s assets)`;
    case GroupKind.IN_ESTATE_GRANTOR_B:
      return `In estate (${grantors.grantorB}’s assets)`;
    default:
      throw new UnreachableError({
        case: group,
        message: `Unrecognized GroupKind ${group}`,
      });
  }
}
