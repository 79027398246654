// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserProfileSettingsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUserProfileSettingsQuery = { __typename?: 'Query', isUserLoggedInToAddepar: { __typename?: 'IsLoggedInToAddepar', isLoggedIn: boolean }, isUserLoggedInToBlackDiamond: { __typename?: 'IsLoggedIn', isLoggedIn: boolean }, isUserLoggedInToOrion: { __typename?: 'IsLoggedIn', isLoggedIn: boolean }, integrationConfiguration?: { __typename?: 'IntegrationConfiguration', id: string, addeparFirmID?: string | null, blackDiamondEnabled: boolean, orionEnabled: boolean } | null };


export const GetUserProfileSettingsDocument = gql`
    query GetUserProfileSettings {
  isUserLoggedInToAddepar {
    isLoggedIn
  }
  isUserLoggedInToBlackDiamond {
    isLoggedIn
  }
  isUserLoggedInToOrion {
    isLoggedIn
  }
  integrationConfiguration {
    id
    addeparFirmID
    blackDiamondEnabled
    orionEnabled
  }
}
    `;

/**
 * __useGetUserProfileSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserProfileSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileSettingsQuery, GetUserProfileSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileSettingsQuery, GetUserProfileSettingsQueryVariables>(GetUserProfileSettingsDocument, options);
      }
export function useGetUserProfileSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileSettingsQuery, GetUserProfileSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileSettingsQuery, GetUserProfileSettingsQueryVariables>(GetUserProfileSettingsDocument, options);
        }
export function useGetUserProfileSettingsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserProfileSettingsQuery, GetUserProfileSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserProfileSettingsQuery, GetUserProfileSettingsQueryVariables>(GetUserProfileSettingsDocument, options);
        }
export type GetUserProfileSettingsQueryHookResult = ReturnType<typeof useGetUserProfileSettingsQuery>;
export type GetUserProfileSettingsLazyQueryHookResult = ReturnType<typeof useGetUserProfileSettingsLazyQuery>;
export type GetUserProfileSettingsSuspenseQueryHookResult = ReturnType<typeof useGetUserProfileSettingsSuspenseQuery>;
export type GetUserProfileSettingsQueryResult = Apollo.QueryResult<GetUserProfileSettingsQuery, GetUserProfileSettingsQueryVariables>;
export const Operations = {
  Query: {
    GetUserProfileSettings: 'GetUserProfileSettings' as const
  }
}