import { useApolloClient } from '@apollo/client';
import { Stack } from '@mui/material';
import { useCallback } from 'react';
import { flushSync } from 'react-dom';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useDebouncedFormValidationTrigger } from '@/components/form/formAwareInputs/hooks/useDebouncedFormValidationTrigger';
import { FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm, useFormContext } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { EntityFormCommonProps } from '@/modules/entities/EntityForm/types';
import { SUBMIT_ERROR_MESSAGE } from '@/modules/entities/EntitySubforms/EntitySubforms.constants';
import { EntitySubformsContainer } from '@/modules/entities/EntitySubforms/EntitySubformsContainer';
import { EntitySubformsFooter } from '@/modules/entities/EntitySubforms/EntitySubformsFooter';
import { EntitySubformsForms } from '@/modules/entities/EntitySubforms/EntitySubformsForms';
import { EntitySubformsSidebarNav } from '@/modules/entities/EntitySubforms/EntitySubformsSidebarNav';
import { EstateWaterfallDocument } from '@/modules/estateWaterfall/graphql/EstateWaterfall.generated';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { CONFIRMATION_MESSAGE } from '@/navigation/UnloadPrompt.provider';
import {
  AugmentedCreateEntityInput,
  AugmentedUpdateEntityInput,
} from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { ValidationError } from '@/utils/errors';

import { useUpdateEntityMutation } from '../graphql/UpdateEntity.generated';
import { EntityType } from '../types/EntityType';
import { makeCreateEntityInput } from './createUtils';
import { SubformConfig } from './entitySubformConfigs';
import { SubformsCombinedType } from './EntitySubforms.types';
import { EntitySubformsSidebar } from './EntitySubformsSidebar';
import { useCreateEntityMutation } from './graphql/CreateEntity.generated';
import { makeUpdateEntityInput } from './updateUtils';
import { useSubforms } from './useSubforms';

interface EntitySubformsProps extends EntityFormCommonProps {
  subformConfig: SubformConfig[];
  data: Partial<SubformsCombinedType>;
  isTrowser?: boolean;
  isEdit: boolean;
  subtypeId?: string;
  isHypotheticalEntity?: boolean;
}

function EntitySubformsInner({
  subformConfig,
  handleClose,
  householdId,
  header,
  isTrowser,
  navigateAfterCreate,
  isEdit,
  entityId,
  subtypeId,
  isHypotheticalEntity = false,
}: EntitySubformsProps) {
  const { showFeedback, createErrorFeedback } = useFeedback();
  const navigate = useNavigate();
  const client = useApolloClient();

  const {
    handleSubmit,
    watch,
    trigger,
    shouldBlockNavigation,
    setShouldBlockNavigation,
  } = useFormContext<SubformsCombinedType>();

  useDebouncedFormValidationTrigger(trigger, watch);

  const [createEntityMutation, { loading: createLoading, error: createError }] =
    useCreateEntityMutation();
  const [updateEntityMutation, { loading: updateLoading, error: updateError }] =
    useUpdateEntityMutation();

  const loading = createLoading || updateLoading;
  const error = createError || updateError;

  useReportError('failed to submit entity form', error);

  const subforms = useSubforms(subformConfig);

  // NOTE: This is actually never called via this path anymore, since entities
  // are always created via the CreateEntityShortFormModal, and not from this
  // LongForm Trowser.
  const createEntity = async (
    values: SubformsCombinedType,
    entityType: EntityType
  ) => {
    // make the AugmentedCreateEntityInput here for trust of type EntityType
    let createEntityInput: AugmentedCreateEntityInput;

    try {
      createEntityInput = makeCreateEntityInput(
        values,
        entityType,
        householdId
      );
    } catch (err) {
      diagnostics.error('Could not make create entity input', err as Error, {
        entityType,
      });

      showFeedback(SUBMIT_ERROR_MESSAGE);

      return;
    }

    // submit the form
    await createEntityMutation({
      variables: {
        input: createEntityInput,
      },
      onError: createErrorFeedback(SUBMIT_ERROR_MESSAGE),
      onCompleted: (data) => {
        const entityId = data.createEntity.id;
        showFeedback('Entity created successfully', { variant: 'success' });

        // note that we only want to call handleCancel if we are in a trowser and have not been
        // instructed to navigate to the newly-created entity, because in a trowser we want to
        // return the user to whatever they're in the middle of working on
        if (isTrowser && !navigateAfterCreate) {
          // Close the trowser and indicate create succeeded (instead of the user closing/cancelling the trowser).
          handleClose?.(entityId);
          return;
        }

        const entityDetailsPage = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
          {
            householdId,
            entityId: entityId,
          }
        );

        navigate(entityDetailsPage);
      },
    });

    // invalidate the cache for entities
    await client.refetchQueries({
      updateCache(cache) {
        cache.evict({ fieldName: 'entities' });
        cache.gc();
      },
    });
  };

  const updateEntity = async (
    values: SubformsCombinedType,
    entityType: EntityType
  ) => {
    // make the AugmentedUpdateEntityInput here for trust of type EntityType
    let updateEntityInput: AugmentedUpdateEntityInput;

    if (!entityId || !subtypeId) {
      throw new Error('No entity id or subtype id found');
    }

    try {
      updateEntityInput = makeUpdateEntityInput(values, {
        entityType,
        entityId,
        subtypeId,
        householdId,
      });
    } catch (err) {
      if (err instanceof ValidationError) {
        showFeedback(err.message);
        return;
      }

      diagnostics.error('Could not make update entity input', err as Error, {
        entityId,
      });

      showFeedback(SUBMIT_ERROR_MESSAGE);

      return;
    }

    // submit the form
    await updateEntityMutation({
      variables: {
        input: updateEntityInput,
      },
      onError: createErrorFeedback(SUBMIT_ERROR_MESSAGE),
      onCompleted: async (data) => {
        showFeedback('Entity updated successfully', { variant: 'success' });

        await client.refetchQueries({
          include: [EstateWaterfallDocument],
          updateCache(cache) {
            cache.evict({ id: cache.identify(data.updateEntity) });
            cache.evict({ fieldName: 'entities' });
            cache.gc();
          },
        });

        if (isTrowser) {
          // Close the trowser and indicate update succeeded (instead of the user closing/cancelling the trowser).
          handleClose?.(entityId);
          return;
        }
      },
    });
  };

  const entityType = subformConfig[0]?.entityType;

  const onValidSubmission: SubmitHandler<SubformsCombinedType> = async (
    values
  ) => {
    if (!entityType) {
      diagnostics.error(
        'No entity type found in subform config',
        new Error('No entity type found in subform config')
      );

      showFeedback(SUBMIT_ERROR_MESSAGE);

      return;
    }

    if (isEdit) {
      await updateEntity(values, entityType);
    } else {
      await createEntity(values, entityType);
    }
  };

  const handleRequestClose = useCallback(() => {
    // Manually show the prompt if there are pending changes
    // and the user is not on the create page since the
    // create page navigates away on submit
    if (shouldBlockNavigation) {
      const isConfirmed = window.confirm?.(CONFIRMATION_MESSAGE);

      if (!isConfirmed) {
        return;
      }
    }

    handleClose?.();
  }, [handleClose, shouldBlockNavigation]);

  const submitHandler = () => {
    flushSync(() => {
      setShouldBlockNavigation(false);
    });

    void handleSubmit(onValidSubmission)();
  };

  // This is used to sync the scrolling between the sidebar nav and subforms
  const scrollingContainerId = 'entity-subforms-container';

  return (
    <Stack
      component="form"
      height="100%"
      noValidate
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        submitHandler();
      }}
      pb={`${FOOTER_HEIGHT}px`}
    >
      <Stack direction="row" width="100%" height="100%">
        <EntitySubformsSidebar>
          <EntitySubformsSidebarNav
            subformConfigs={subformConfig}
            entityType={entityType}
            heading={header}
            contentContainerId={scrollingContainerId}
            isHypotheticalEntity={isHypotheticalEntity}
          />
        </EntitySubformsSidebar>
        <EntitySubformsContainer contentContainerId={scrollingContainerId}>
          <EntitySubformsForms
            subforms={subforms}
            subformConfig={subformConfig}
          />
        </EntitySubformsContainer>
      </Stack>
      <EntitySubformsFooter
        shouldHandleCancel={!!handleClose}
        requestClose={handleRequestClose}
        isEdit={isEdit}
        loading={loading}
        entityId={entityId}
        isHypotheticalEntity={isHypotheticalEntity}
      />
    </Stack>
  );
}

export function EntitySubforms(props: EntitySubformsProps) {
  const { data } = props;

  const formMethods = useForm<SubformsCombinedType>({
    defaultValues: data,
  });

  return (
    <FormProvider {...formMethods}>
      <EntitySubformsInner {...props} />
    </FormProvider>
  );
}
