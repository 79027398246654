import { Box, Skeleton, Stack } from '@mui/material';
import { compact, isEmpty } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { SmallLinkButton } from '@/components/form/baseInputs/Link/SmallLinkButton';
import { LinkTabProps } from '@/components/navigation/NavigationTabs';
import { PageHeader } from '@/components/navigation/PageHeader';
import { useMatchingRoutes } from '@/hooks/useMatchingRoutes';
import { useMinimumLoading } from '@/hooks/useMinimumLoading';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { WaterfallRouteProvider } from '@/modules/entities/contexts/waterfallRoute/WaterfallRoute.provider';
import { EstateWaterfallBreadcrumbNavigation } from '@/modules/estateWaterfall/components/EstateWaterfallBreadcrumbNavigation/EstateWaterfallBreadcrumbNavigation';
import { EstateWaterfallsHeadingDropdown } from '@/modules/estateWaterfall/components/EstateWaterfallsDropdown/EstateWaterfallsHeadingDropdown';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { WaterfallRouting } from './WaterfallRouting.context';

export function ClientWaterfallPage() {
  const navigate = useNavigate();
  const householdId = useRequiredParam('householdId');
  const params = useParams();
  const waterfallId = params.waterfallId ?? null;
  const [isLoadingWaterfall, setIsLoadingWaterfall] = useState(!waterfallId);
  const showLoadingWatefall = useMinimumLoading(isLoadingWaterfall);

  const { isMatchingRoute: isOnDiagramPage } = useMatchingRoutes([
    ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM,
    ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM_ESTATE_TAX_CALCULATION_TROWSER,
  ]);

  const { isMatchingRoute: isOnBeneficiaryReportingPage } = useMatchingRoutes([
    ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING,
    ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING_OVERVIEW,
    ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING_BENEFICIARY_DETAILS,
  ]);

  const tabs: LinkTabProps[] | undefined = useMemo(() => {
    if (!waterfallId) return undefined;

    return compact([
      {
        display: 'Summary',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY,
          {
            householdId,
            waterfallId,
          }
        ),
      },
      {
        display: 'Diagram',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM,
          {
            householdId,
            waterfallId,
          }
        ),
      },
      {
        display: 'Beneficiaries',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING,
          {
            householdId,
            waterfallId,
          }
        ),
      },
      {
        display: 'Entities',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_LIST,
          {
            householdId,
            waterfallId,
          }
        ),
      },
      {
        display: 'Tax overview',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_TAX_OVERVIEW,
          {
            householdId,
            waterfallId,
          }
        ),
      },
    ]);
  }, [householdId, waterfallId]);

  const heading = useMemo(() => {
    if (!waterfallId) return 'Waterfall';
    return (
      <Box pr={2}>
        <EstateWaterfallsHeadingDropdown
          waterfallId={waterfallId}
          householdId={householdId}
        />
      </Box>
    );
  }, [householdId, waterfallId]);

  const action = useMemo(() => {
    if (!waterfallId) return null;
    return (
      <WaterfallRouteProvider waterfallId={waterfallId}>
        <EstateWaterfallBreadcrumbNavigation
          householdId={householdId}
          waterfallId={waterfallId}
        />
      </WaterfallRouteProvider>
    );
  }, [householdId, waterfallId]);

  return (
    <WaterfallRouting.Provider
      value={{ isLoadingWaterfall, setIsLoadingWaterfall }}
    >
      <Stack
        height={
          isOnDiagramPage || isOnBeneficiaryReportingPage ? '100%' : undefined
        }
      >
        <PageHeader
          heading={
            showLoadingWatefall ? <Skeleton width={80} height={40} /> : heading
          }
          action={action}
          secondaryAction={
            isEmpty(tabs) ? null : (
              <SmallLinkButton
                data-testid="ManageDispositionsButton"
                display="Manage dispositions"
                onClick={() => {
                  navigate(
                    getCompletePathFromRouteKey(
                      ROUTE_KEYS.HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS,
                      {
                        householdId,
                      }
                    )
                  );
                }}
              />
            )
          }
          tabs={tabs}
        />
        <Stack p={3} pb={4} flex={1}>
          <Outlet />
        </Stack>
      </Stack>
    </WaterfallRouting.Provider>
  );
}
