import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { NEW_ENTITY_SENTINEL } from '@/modules/entities/entities.constants';
import { ClientProfile } from '@/types/schema';

export function isNewEntity(entityId: string) {
  return entityId === NEW_ENTITY_SENTINEL;
}

export function getClientProfileOptions(
  clientProfiles: Pick<ClientProfile, 'legalName' | 'id'>[]
): SelectInputOption<string>[] {
  return clientProfiles.map((profile) => ({
    display: profile.legalName,
    value: profile.id,
  }));
}
