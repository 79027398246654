import { FormProvider } from 'react-hook-form';
import { SetOptional } from 'type-fest';

import {
  FormModal,
  FormModalProps,
} from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions/FormModalActions';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useForm, useSubmitSuccessHandler } from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { mapFormDataToInput } from './CreateLiabilityModal.utils';
import { useCreateLiabilityMutation } from './graphql/LiabilityModal.generated';
import { LiabilityForm } from './LiabilityForm';
import { defaultValues, LiabilityFormShape } from './LiabilityModal.constants';

type FormModalOptionalProps = SetOptional<FormModalProps, 'heading'>;
export type CreateLiabilityModalProps = FormModalOptionalProps & {
  entityId?: string;
};

function CreateLiability({
  onClose,
  heading = 'Add liability',
  entityId,
  ...formModalProps
}: CreateLiabilityModalProps) {
  const { householdId } = useHouseholdDetailsContext();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { formRef, handleSave } = useFormSaveHandler();
  const trackUserEvent = useTrackUserEvent();
  const { handleSubmit, reset, shouldBlockNavigation, formState } =
    useFormContext<LiabilityFormShape>();

  const [createMutation] = useCreateLiabilityMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      reportError(`Could not create liability`, error);
    },
  });

  const onSubmit = handleSubmit((formData) => {
    if (!householdId) return; // ts
    trackUserEvent('liability created', {
      includesPaymentData: Boolean(formData.paymentKind),
    });
    return createMutation({
      variables: {
        input: mapFormDataToInput(formData, householdId),
      },
    });
  });

  const closeModal = () => {
    reset();
    onClose();
  };

  useSubmitSuccessHandler(() => {
    showFeedback('Successfully created liability', { variant: 'success' });
    closeModal();
  });

  return (
    <FormModal
      {...formModalProps}
      onClose={onClose}
      heading={heading}
      shouldBlockClose={shouldBlockNavigation}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.CancelButton onClick={closeModal} />
          <FormModalActions.SaveButton onClick={handleSave}>
            Add liability
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <LiabilityForm entityId={entityId} />
      </form>
    </FormModal>
  );
}

export const CreateLiabilityModal = ({
  ...props
}: CreateLiabilityModalProps) => {
  const formMethods = useForm<LiabilityFormShape>({ defaultValues });

  return (
    <FormProvider {...formMethods}>
      <CreateLiability {...props} />
    </FormProvider>
  );
};
