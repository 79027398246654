import { PropsWithChildren, useEffect } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  getGraphQLErrorsByStatusCode,
  GraphQLStatusCodes,
} from '@/graphql/errors';
import { deletedHouseholdIdsVar } from '@/graphql/reactiveVars';
import { getHouseholdIntegration } from '@/modules/household/householdIntegrations';
import { useMostRecentlyViewedHouseholds } from '@/modules/user/hooks/useMostRecentlyViewedHouseholds';
import { StateCode } from '@/types/schema';

import {
  GetClientDetails_HouseholdFragment,
  useGetClientDetailsQuery,
} from './graphql/GetClientDetails.generated';
import { useHouseholdDetailsContext } from './householdDetails.context';

export const HouseholdDetailsAwareRoute = ({
  householdId,
  children,
}: PropsWithChildren<{ householdId: string | null }>) => {
  const { showFeedback } = useFeedback();
  const { addMostRecentlyViewedHousehold } = useMostRecentlyViewedHouseholds();

  const {
    setHouseholdId,
    setDisplayName,
    setPrimaryClients,
    setPossibleGrantors,
    setPossibleTrustees,
    setPossibleBeneficiaries,
    setIsTwoClientHousehold,
    setHouseholdIntegrationLink,
    setHouseholdOnboardingCompletedAt,
    setRecentClients,
    setHouseholdNotFound,
  } = useHouseholdDetailsContext();

  const { data: clientData, error } = useGetClientDetailsQuery({
    variables: { householdId: householdId! },
    skip: !householdId,
  });

  const household =
    clientData?.household as GetClientDetails_HouseholdFragment | null;
  const recentClients = clientData?.recentClients;

  useEffect(() => {
    const householdId = household?.id;
    const displayName = household?.displayName;

    if (householdId && displayName) {
      void addMostRecentlyViewedHousehold(householdId, displayName);
    }
  }, [addMostRecentlyViewedHousehold, household?.displayName, household?.id]);

  useEffect(() => {
    // if we've already run through this effect once and marked the household as deleted,
    // read the variable directly. this resolves a bug where if we're just reliant on the error
    // being present, if a user hits the back button to get to a household-aware page for a deleted household,
    // we wouldn't detect that the household was deleted and would incorrectly attempt to render the page normally
    const isHouseholdIdMarkedAsDeleted = deletedHouseholdIdsVar().includes(
      householdId ?? ''
    );
    if (isHouseholdIdMarkedAsDeleted) {
      return setHouseholdNotFound(true);
    }

    // if we're seeing an error when loading the client, check to see if it's a not found error
    // and mark the household as deleted if so
    if (error) {
      const householdNotFound = Boolean(
        getGraphQLErrorsByStatusCode(error, GraphQLStatusCodes.NOT_FOUND)
      );

      setHouseholdNotFound(householdNotFound);
      if (householdNotFound && householdId) {
        const currentDeletedHouseholdIds = deletedHouseholdIdsVar();
        void deletedHouseholdIdsVar([
          ...currentDeletedHouseholdIds,
          householdId,
        ]);
      } else {
        showFeedback(
          'Failed to load details for this client. Please refresh the page and try again.'
        );
      }
    }
  }, [
    error,
    setHouseholdNotFound,
    clientData?.household,
    householdId,
    showFeedback,
  ]);

  useEffect(() => {
    setHouseholdId(household?.id ?? null);
    setDisplayName(household?.displayName ?? null);
    setPrimaryClients(
      household?.possiblePrimaryClients.map((c) => ({
        id: c.id,
        displayName: c.displayName,
        firstName: c.firstName,
        lastName: c.lastName,
        stateCode: c.address?.stateCode as StateCode | undefined,
      })) ?? null
    );
    setPossibleGrantors(household?.possibleGrantors ?? null);
    setPossibleTrustees(household?.possibleTrusteesV2 ?? null);
    setPossibleBeneficiaries(household?.possibleBeneficiariesV2 ?? null);
    setIsTwoClientHousehold(
      household?.possiblePrimaryClients.length === 2 ?? false
    );

    const integration = getHouseholdIntegration(household);
    setHouseholdIntegrationLink(integration);

    setHouseholdOnboardingCompletedAt(
      household?.onboarding?.completedAt || null
    );

    setRecentClients(recentClients ?? []);

    return () => {
      setHouseholdId(null);
      setDisplayName(null);
      setPrimaryClients(null);
      setPossibleGrantors(null);
      setIsTwoClientHousehold(false);
      setHouseholdIntegrationLink(null);
      setHouseholdOnboardingCompletedAt(null);
      setRecentClients([]);
      setHouseholdNotFound(false);
    };
  }, [
    household,
    recentClients,
    setDisplayName,
    setHouseholdId,
    setHouseholdIntegrationLink,
    setHouseholdNotFound,
    setHouseholdOnboardingCompletedAt,
    setIsTwoClientHousehold,
    setPossibleBeneficiaries,
    setPossibleGrantors,
    setPossibleTrustees,
    setPrimaryClients,
    setRecentClients,
  ]);

  return <>{children}</>;
};
