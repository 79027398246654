import { Stack } from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid-pro';
import { useMemo, useState } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { SEARCH_TOOLBAR_STYLES } from '@/components/tables/DataTable/components/SearchSelectionToolbar';
import { DefaultTableEmptyState } from '@/components/tables/DataTable/components/TableEmptyState';
import { PageSizes } from '@/components/tables/DataTable/constants';
import { DataTable } from '@/components/tables/DataTable/DataTable';
import { usePaginatedDataTableQuery } from '@/components/tables/DataTable/hooks/usePaginatedDataTableQuery';
import { useReportError } from '@/hooks/useReportError';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';
import { EntityStage } from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { ClientEntityRow } from './ClientEntityCSVConnectionTable.constants';
import { ClientEntityCSVConnectionTableEmptyState } from './ClientEntityCSVConnectionTableEmptyState';
import { ClientSearchSelectionToolbar } from './ClientSearchSelectionToolbar';
import {
  CsvImportIntegrationClients_HouseholdFragment,
  useCsvImportIntegrationClientsQuery,
} from './graphql/ClientEntityCSVConnectionTable.generated';
import { useCSVTableColumns } from './hooks/useCSVTableColumns';

const getTreeDataPath = (row: ClientEntityRow) => row.path;

export function ClientEntityCSVConnectionTable() {
  const [searchString, setSearchString] = useState('');
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const columns = useCSVTableColumns();
  const onFilterModelChange = ({ quickFilterValues }: GridFilterModel) => {
    const searchString: string = quickFilterValues?.[0];
    setSearchString(searchString ?? '');
  };

  const [paginatedTableProps, { data, loading }] = usePaginatedDataTableQuery(
    useCsvImportIntegrationClientsQuery,
    {
      variables: {
        query: searchString,
        where: {
          hasEntitiesWith: [
            {
              stage: EntityStage.Active,
            },
          ],
        },
      },
      pageSize: PageSizes.Ten,
      onError: (error) => {
        reportError(
          `Failed to fetch client list for csv connection table`,
          error
        );
        showFeedback(
          'Failed to load importable clients. Please refresh the page to try again.'
        );
      },
    }
  );

  const rows = useMemo(() => mapDataToRows(data ?? []), [data]);

  return (
    <Stack spacing={3}>
      {rows.length || loading || searchString.length ? (
        <>
          <DataTable
            {...paginatedTableProps}
            treeData
            getTreeDataPath={getTreeDataPath}
            disableColumnReorder
            disableColumnResize
            disableRowSelectionOnClick
            rows={rows}
            columns={columns}
            sx={SEARCH_TOOLBAR_STYLES}
            filterMode="server"
            slots={{
              toolbar: ClientSearchSelectionToolbar,
              noRowsOverlay: DefaultTableEmptyState,
            }}
            onFilterModelChange={onFilterModelChange}
          />
        </>
      ) : (
        <ClientEntityCSVConnectionTableEmptyState />
      )}
    </Stack>
  );
}

function mapDataToRows(
  households: CsvImportIntegrationClients_HouseholdFragment[]
): ClientEntityRow[] {
  return households.flatMap((h) => {
    const entities = getNodes(h.entities);

    // if a household has no entities, we don't want to show it in the table
    if (!entities.length) {
      return [];
    }

    const householdRow: ClientEntityRow = {
      id: h.id,
      name: h.displayName,
      linkedAccountIds: '',
      entityType: null,
      path: [h.id],
    };

    const entitiesRows = entities.map((e) => {
      return {
        id: e.id,
        name: e.subtype.displayName,
        entityType: getEntityTypeFromEntityKind(e.kind),
        linkedAccountIds: '',
        path: [h.id, e.id],
      };
    });

    return [householdRow, ...entitiesRows];
  });
}
