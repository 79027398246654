import Decimal from 'decimal.js';
import { first, isEmpty } from 'lodash';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useClientProfileDetailsContext } from '@/modules/entities/contexts/clientProfileDetails/clientProfileDetails.context';
import {
  EntityDetailsContext,
  useEntityDetailsContext,
} from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { useTestamentaryEntityDetailsContext } from '@/modules/entities/contexts/testamentaryEntityDetails/testamentaryEntityDetails.context';
import { EstateWaterfallContext } from '@/modules/estateWaterfall/contexts/estateWaterfall.context';
import { isHypotheticalWaterfall } from '@/modules/estateWaterfall/EstateWaterfall.utils';
import {
  HouseholdDetailsContext,
  useHouseholdDetailsContext,
} from '@/modules/household/contexts/householdDetails.context';
import {
  EntityInEstateStatus,
  NonTrustEntityTaxStatus,
  TrustTaxStatus,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import {
  DispositiveProvisionsCalculateValues,
  DispositiveProvisionsContext,
} from '../contexts/dispositiveProvisions.context';
import {
  GetDispositiveProvisions_ClientOrganziationFragment,
  GetDispositiveProvisions_ClientProfileFragment,
  GetDispositiveProvisions_EntityFragment,
  GetDispositiveProvisions_EstateWaterfallFragment,
  GetDispositiveProvisions_TestamentaryEntityFragment,
  useGetDispositiveProvisionsQuery,
} from '../DispositiveProvisionsForm/graphql/GetDispositiveProvisions.generated';
import { DispositiveProvisions_DispositionScenarioFragment } from '../graphql/DispositiveProvisions.fragments.generated';

type DispositiveProvisionsContextDataOrLoading =
  | (Omit<DispositiveProvisionsContext, 'formVariant'> & {
      loading: false;
    })
  | {
      loading: true;
    };

// TODO add hypothetical disposition scenarios to the common entity subtype interface
interface HDS {
  hypotheticalDispositionScenarios: {
    edges: {
      node: DispositiveProvisions_DispositionScenarioFragment;
    }[];
  };
}

function entityContext(
  { primaryClients, householdId }: HouseholdDetailsContext,
  { displayName }: EntityDetailsContext,
  entity: GetDispositiveProvisions_EntityFragment,
  [
    showCalculatedDispositions,
    setShowCalculatedDispositions,
  ]: DispositiveProvisionsCalculateValues,
  waterfall?: GetDispositiveProvisions_EstateWaterfallFragment
): DispositiveProvisionsContextDataOrLoading {
  let taxStatus: TrustTaxStatus | NonTrustEntityTaxStatus | null = null;
  let estateStatus: EntityInEstateStatus | null = null;
  let gstStatus = null;
  const isOnHypotheticalWaterfall = isHypotheticalWaterfall(waterfall);

  const subtype = entity?.subtype;

  if ('trustTaxStatus' in subtype) {
    taxStatus = subtype.trustTaxStatus ?? null;
  }

  if ('nonTrustTaxStatus' in subtype) {
    taxStatus = subtype.nonTrustTaxStatus ?? null;
  }

  if ('nonTrustTaxStatusRequired' in subtype) {
    taxStatus = subtype.nonTrustTaxStatusRequired ?? null;
  }

  if ('inEstateStatus' in subtype) {
    estateStatus = subtype.inEstateStatus ?? null;
  }

  if ('gstStatus' in subtype) {
    gstStatus = subtype.gstStatus ?? null;
  }

  if (!entity || !primaryClients || !householdId || !displayName) {
    return {
      loading: true,
    };
  }

  const dispositionScenarios =
    (isOnHypotheticalWaterfall
      ? getNodes((entity.subtype as HDS).hypotheticalDispositionScenarios)
      : entity.subtype.dispositionScenarios) ?? [];

  if (isOnHypotheticalWaterfall && dispositionScenarios.length === 0) {
    // If we're on a hypothetical waterfall, but there are no hypothetical disposition scenarios,
    // we use the real disposition scenarios instead.
    dispositionScenarios.push(...(entity.subtype.dispositionScenarios ?? []));
  }

  return {
    loading: false,
    id: entity.id,
    firstGrantorDeathId: waterfall?.firstGrantorDeath?.id ?? null,
    hasTestamentaryEntityBeenReviewed: false, // not relevant for a non-testamentary entity
    dispositionScenarios,
    totalMarketValue: entity.subtype.currentValue,
    primaryClients: primaryClients,
    isTwoClientHousehold: primaryClients.length === 2,
    dyingPrimaryClientId: null,
    householdId,
    estateStatus,
    taxStatus,
    gstStatus,
    entityDisplayName: displayName,
    isTestamentaryEntity: false,
    isClientProfile: false,
    clientProfileOrEntityOrTestamentaryEntityId: entity.id,
    allClientIds: primaryClients.map((g) => g.id),
    waterfall,
    isOnHypotheticalWaterfall,
    showCalculatedDispositions,
    setShowCalculatedDispositions,
  };
}

function testamentaryEntityContext(
  { primaryClients, householdId }: HouseholdDetailsContext,
  entity: GetDispositiveProvisions_TestamentaryEntityFragment,
  entityValue: Decimal,
  [
    showCalculatedDispositions,
    setShowCalculatedDispositions,
  ]: DispositiveProvisionsCalculateValues,
  waterfall?: GetDispositiveProvisions_EstateWaterfallFragment
): DispositiveProvisionsContextDataOrLoading {
  if (!entity || !primaryClients || !householdId) {
    return { loading: true };
  }
  const isOnHypotheticalWaterfall = isHypotheticalWaterfall(waterfall);

  let dispositionScenarios = entity.dispositionScenarios ?? [];
  const hypotheticalDispositionScenarios = getNodes(
    (entity as HDS).hypotheticalDispositionScenarios
  );

  if (isOnHypotheticalWaterfall && !isEmpty(hypotheticalDispositionScenarios)) {
    dispositionScenarios = hypotheticalDispositionScenarios;
  }

  return {
    loading: false,
    id: entity.id,
    firstGrantorDeathId: waterfall?.firstGrantorDeath?.id ?? null,
    hasTestamentaryEntityBeenReviewed: Boolean(entity.reviewedAt),
    dispositionScenarios,
    totalMarketValue: entityValue,
    primaryClients: primaryClients,
    isTwoClientHousehold: primaryClients.length === 2,
    householdId,
    dyingPrimaryClientId: entity.grantorDeath?.id ?? null,
    estateStatus: entity.inEstateStatus ?? null,
    taxStatus: entity.taxStatus ?? null,
    gstStatus: entity.gstStatus ?? null,
    entityDisplayName: entity.displayName,
    isTestamentaryEntity: true,
    isClientProfile: false,
    clientProfileOrEntityOrTestamentaryEntityId: entity.id,
    allClientIds: primaryClients.map((g) => g.id),
    waterfall,
    isOnHypotheticalWaterfall,
    showCalculatedDispositions,
    setShowCalculatedDispositions,
  };
}

function clientProfileContext(
  { primaryClients, householdId }: HouseholdDetailsContext,
  cp: GetDispositiveProvisions_ClientProfileFragment,
  currentNodeValue: Decimal = new Decimal(0),
  [
    showCalculatedDispositions,
    setShowCalculatedDispositions,
  ]: DispositiveProvisionsCalculateValues,
  waterfall?: GetDispositiveProvisions_EstateWaterfallFragment
): DispositiveProvisionsContextDataOrLoading {
  if (!cp || !primaryClients || !householdId) {
    return { loading: true };
  }

  const isOnHypotheticalWaterfall = isHypotheticalWaterfall(waterfall);

  const dispositionScenarios =
    (isOnHypotheticalWaterfall
      ? getNodes(cp.hypotheticalDispositionScenarios)
      : cp.dispositionScenarios) ?? [];

  if (isOnHypotheticalWaterfall && dispositionScenarios.length === 0) {
    // If we're on a hypothetical waterfall, but there are no hypothetical disposition scenarios,
    // we use the real disposition scenarios instead.
    dispositionScenarios.push(...(cp.dispositionScenarios ?? []));
  }

  return {
    loading: false,
    id: cp.id,
    firstGrantorDeathId: waterfall?.firstGrantorDeath?.id ?? null,
    dispositionScenarios,
    hasTestamentaryEntityBeenReviewed: false, // not relevant for a non-testamentary entity
    totalMarketValue: currentNodeValue,
    primaryClients: primaryClients,
    isTwoClientHousehold: primaryClients.length === 2,
    dyingPrimaryClientId: null,
    householdId,
    estateStatus: null,
    taxStatus: null,
    gstStatus: null,
    entityDisplayName: cp.displayName,
    isTestamentaryEntity: false,
    isClientProfile: true,
    clientProfileOrEntityOrTestamentaryEntityId: cp.id,
    allClientIds: primaryClients.map((g) => g.id),
    waterfall,
    isOnHypotheticalWaterfall,
    showCalculatedDispositions,
    setShowCalculatedDispositions,
  };
}

function clientOrganizationContext(
  { primaryClients, householdId }: HouseholdDetailsContext,
  node: GetDispositiveProvisions_ClientOrganziationFragment,
  currentNodeValue: Decimal = new Decimal(0),
  [
    showCalculatedDispositions,
    setShowCalculatedDispositions,
  ]: DispositiveProvisionsCalculateValues,
  waterfall?: GetDispositiveProvisions_EstateWaterfallFragment
): DispositiveProvisionsContextDataOrLoading {
  if (!primaryClients || !householdId || !node) {
    return { loading: true };
  }

  const isOnHypotheticalWaterfall = isHypotheticalWaterfall(waterfall);

  return {
    loading: false,
    id: node.id,
    primaryClients: primaryClients,
    isTwoClientHousehold: primaryClients.length === 2,
    firstGrantorDeathId: waterfall?.firstGrantorDeath?.id ?? null,
    hasTestamentaryEntityBeenReviewed: false, // not relevant for a non-testamentary entity
    totalMarketValue: currentNodeValue,
    dyingPrimaryClientId: null,
    householdId,
    estateStatus: null,
    taxStatus: null,
    gstStatus: null,
    entityDisplayName: node.name,
    isTestamentaryEntity: false,
    isClientProfile: false,
    clientProfileOrEntityOrTestamentaryEntityId: node.id,
    allClientIds: primaryClients.map((g) => g.id),
    waterfall,
    isOnHypotheticalWaterfall,
    showCalculatedDispositions,
    setShowCalculatedDispositions,
  };
}

export function useDispositiveProvisionsContextData(
  currentNodeValue?: Decimal
): DispositiveProvisionsContextDataOrLoading {
  const { createErrorFeedback } = useFeedback();
  const showCalculatedDispositionsState = useState<boolean>(false);
  const clientDetails = useHouseholdDetailsContext();
  const entityDetails = useEntityDetailsContext();
  const testamentaryEntityDetails = useTestamentaryEntityDetailsContext();
  const clientProfileDetails = useClientProfileDetailsContext();
  const params = useParams();
  const waterfallId = params.waterfallId ?? null;

  const ewContext = useContext(EstateWaterfallContext);

  const id =
    ewContext?.state?.derivedData?.summaryNode?.data?.id ??
    testamentaryEntityDetails.entityId ??
    entityDetails?.entityId ??
    clientProfileDetails?.clientProfileId ??
    null;

  const {
    data: dispositiveProvisionsData,
    loading,
    error,
  } = useGetDispositiveProvisionsQuery({
    variables: {
      id: id!, // Guaranteed to be defined by the skip condition below
      waterfallWhere: {
        id: waterfallId,
      },
      isOnWaterfall: !!waterfallId,
    },
    onError: createErrorFeedback(
      'Failed to load entity data. Please refresh the page to try again.'
    ),
    skip: !id,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  if (loading) {
    return { loading: true };
  }

  if (error) {
    // because errorPolicy is 'all', data should still be present, so don't throw here
    diagnostics.error(
      'Caught error in useDispositiveProvisionsContextData',
      error
    );
  }

  if (!dispositiveProvisionsData) {
    diagnostics.warn(
      'dispositiveProvisionsData not present, but the load completed and there was no error'
    );
  }

  const node = dispositiveProvisionsData?.node;

  const waterfall = first(
    getNodes(dispositiveProvisionsData?.dispositiveProvisionWaterfalls)
  );

  if (!node?.__typename) return { loading: true };

  if (node.__typename === 'Entity') {
    return entityContext(
      clientDetails,
      entityDetails,
      node,
      showCalculatedDispositionsState,
      waterfall
    );
  }

  if (node.__typename === 'TestamentaryEntity') {
    return testamentaryEntityContext(
      clientDetails,
      node,
      testamentaryEntityDetails.totalMarketValue,
      showCalculatedDispositionsState,
      waterfall
    );
  }

  if (node.__typename === 'ClientProfile') {
    return clientProfileContext(
      clientDetails,
      node,
      currentNodeValue,
      showCalculatedDispositionsState,
      waterfall
    );
  }

  if (node.__typename === 'ClientOrganization') {
    return clientOrganizationContext(
      clientDetails,
      node,
      currentNodeValue,
      showCalculatedDispositionsState,
      waterfall
    );
  }

  throw new Error(`Unhandled entity data ${node.__typename}`);
}
