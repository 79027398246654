import Decimal from 'decimal.js';
import React, { useMemo } from 'react';

import { Accordion } from '@/components/Accordion/Accordion';
import { useFormContext } from '@/components/react-hook-form';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import { DispositiveProvisionTemplateKind } from '@/types/schema';

import { DispositionScheme } from '../../dispositiveProvisions.types';
import { DispositiveProvisionsFormShape } from '../DispositiveProvisionsForm.types';
import {
  useDeathOrderAwareClient,
  useDispositionScheme,
} from '../hooks/utilityHooks';
import { DispositiveProvisionsRecipient } from './DispositiveProvisionsRecipient';
import { DispositiveProvisionsRecipientTemplateRecipientConfiguration } from './DispositiveProvisionsRecipient.types';
import { getTemplateRulesForTemplateType } from './DispositiveProvisionsRecipient.utils';
import { useRecipientAccordionProps } from './DraggableListItemRecipient.utils';

interface DraggableListItemRecipientProps {
  index: number;
  Draghandle: React.ReactNode;
  defaultExpanded?: boolean;
  simulatedValue: Decimal | null;
  onRemove?: (() => void) | null;
  templateType?: DispositiveProvisionTemplateKind;
  recipientConfiguration?: Partial<DispositiveProvisionsRecipientTemplateRecipientConfiguration>;
  emptyRecipientOptionDisplay?: string;
  isOptional?: boolean;
}

export function DraggableListItemRecipient({
  index,
  Draghandle,
  onRemove,
  defaultExpanded,
  templateType,
  recipientConfiguration: externalRecipientConfiguration,
  emptyRecipientOptionDisplay,
  isOptional = false,
  simulatedValue,
}: DraggableListItemRecipientProps) {
  const {
    formState: { errors },
  } = useFormContext<DispositiveProvisionsFormShape>();
  const dispositionScheme = useDispositionScheme();
  const firstDeadGrantor = useDeathOrderAwareClient();
  const { availableStateEstateTaxes } = useIRSConstants();
  const { recipientConfiguration } = getTemplateRulesForTemplateType(
    templateType,
    externalRecipientConfiguration
  );

  const hasStateTax = useMemo(() => {
    if (!firstDeadGrantor) return false;

    return Boolean(
      availableStateEstateTaxes?.includes(firstDeadGrantor.stateCode!)
    );
  }, [availableStateEstateTaxes, firstDeadGrantor]);

  const accordionProps = useRecipientAccordionProps(
    index,
    simulatedValue,
    hasStateTax,
    recipientConfiguration,
    isOptional
  );

  const forceExpansion = useMemo(() => {
    if (defaultExpanded) {
      return true;
    }

    const recipientHasFieldError = Boolean(
      errors.dispositiveProvisions?.recipients?.[index]
    );

    // return undefined if there's no error because we want to the default internal handling of the accordion,
    // rather than passing false and forcing it to be closed
    return recipientHasFieldError ? true : undefined;
  }, [defaultExpanded, errors.dispositiveProvisions?.recipients, index]);

  return (
    <Accordion
      leftAdornment={Draghandle}
      // set a minHeight to make sure that in most cases the accordion tops are the same height, even if we're not yet rendering
      // all of the properties for the recipient
      labelContainerProps={{
        spacing: 0,
        minHeight: 60,
        justifyContent: 'center',
      }}
      variant="filled"
      isDefaultExpanded={forceExpansion}
      data-testid={`DraggableListItemRecipient-${index}`}
      {...accordionProps}
    >
      <DispositiveProvisionsRecipient
        idx={index}
        deadPrimaryClientId={firstDeadGrantor.id}
        isSecondDeath={
          dispositionScheme === DispositionScheme.UPON_SECOND_DEATH
        }
        onRemove={onRemove}
        templateType={templateType}
        recipientConfiguration={externalRecipientConfiguration}
        emptyRecipientOptionDisplay={emptyRecipientOptionDisplay}
        isOptional={isOptional}
      />
    </Accordion>
  );
}
