import { Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { useCallback, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Footer } from '@/components/navigation/Footer';
import { useFormContext } from '@/components/react-hook-form';
import { useAiSuggestionsMatcherContext } from '@/modules/aiSuggestions/AISuggestionsMatcher/context/aiSuggestionsMatcher.context';
import { useEditEntitySplitScreenModalContext } from '@/modules/entities/EditEntitySplitScreen/contexts/editEntitySplitScreenModal.context';
import {
  ConfirmAISuggestionsModal,
  ConfirmDiscardChangesModal,
} from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.components';
import { CombinedSupportedSubformFieldTypes } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';

export function EditEntitySplitScreenFooter() {
  const { formActionsPortalRef } = useEditEntitySplitScreenModalContext();

  return (
    <Footer
      // The close/save buttons will get portaled here, as children of the stack.
      rightAction={
        <Stack
          ref={formActionsPortalRef}
          spacing={2}
          alignItems="center"
          justifyContent="flex-end"
          direction="row"
        />
      }
    />
  );
}

interface SubmitEditEntityFormButtonProps {
  onSubmit: () => void;
}

export function SubmitEditEntityFormButton({
  onSubmit,
}: SubmitEditEntityFormButtonProps) {
  const {
    formState: { isSubmitting },
  } = useFormContext<CombinedSupportedSubformFieldTypes>();

  const { matchedSuggestions, commitMatchedSuggestions } =
    useAiSuggestionsMatcherContext();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleRequestSubmitAndClose = useCallback(() => {
    if (isEmpty(matchedSuggestions)) {
      // We don't have any uncommitted matched suggestions, all good to submit!
      onSubmit();
      return;
    }
    setShowConfirmationModal(true);
  }, [matchedSuggestions, onSubmit]);

  const handleCloseConfirmationModal = useCallback(
    (reason: 'confirm' | 'cancel') => {
      setShowConfirmationModal(false);
      if (reason === 'confirm') {
        commitMatchedSuggestions();
        return;
      }
      // Just submit the form normally without acknowledging the suggestions.
      onSubmit();
    },
    [commitMatchedSuggestions, onSubmit]
  );

  return (
    <>
      <Button
        variant="primary"
        size="sm"
        type="submit"
        onClick={handleRequestSubmitAndClose}
        loading={isSubmitting}
      >
        Save & close
      </Button>
      <ConfirmAISuggestionsModal
        isOpen={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
      />
    </>
  );
}

interface CloseEditEntityFormButtonProps {
  onClose: () => void;
}

export function CloseEditEntityFormButton({
  onClose,
}: CloseEditEntityFormButtonProps) {
  const {
    shouldBlockNavigation,
    formState: { isSubmitting },
  } = useFormContext<CombinedSupportedSubformFieldTypes>();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleRequestDiscardAndClose = useCallback(() => {
    if (shouldBlockNavigation) {
      setShowConfirmationModal(true);
      return;
    }
    onClose();
  }, [onClose, shouldBlockNavigation]);

  const handleCloseConfirmDiscardAndCloseModal = useCallback(
    (reason: 'confirm' | 'cancel') => {
      setShowConfirmationModal(false);
      if (reason === 'confirm') {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <>
      <Button
        variant="secondary"
        size="sm"
        onClick={handleRequestDiscardAndClose}
        disabled={isSubmitting}
      >
        Cancel
      </Button>
      <ConfirmDiscardChangesModal
        isOpen={showConfirmationModal}
        onClose={handleCloseConfirmDiscardAndCloseModal}
      />
    </>
  );
}
