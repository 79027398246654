import { EdgeMouseEnterAction, EntityMapActionFn } from '../types';

export const edgeMouseEnterAction: EntityMapActionFn<EdgeMouseEnterAction> = (
  state,
  { edge },
  _queueEffect
) => {
  return {
    ...state,
    // Edges are only immediate connections, and the edge itself
    highlightedIds: new Set([edge.id, edge.source, edge.target]),
  };
};
