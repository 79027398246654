import { Box, Stack } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { ArrowNarrowRightIcon } from '@/components/icons/ArrowNarrowRightIcon';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { EntityStage, EntityTaskType } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import { TaskHeaderCard } from '../../TaskHeaderCard';
import { TaskListItemStatus } from '../../TaskList';
import { DenormalizedImplementationTask } from '../../taskUtils';
import { ExtendedTaskBodyProps } from '../../types';
import { useFinalizeImplementationMutation } from './graphql/FinalizeImplementation.generated';

interface TaskValidationError {
  title: string;
  description: string;
  task: DenormalizedImplementationTask;
}

const VALIDATION_ERROR_MESSAGES: Partial<Record<EntityTaskType, string>> = {
  [EntityTaskType.GratConfirmAnnuities]:
    'Please confirm the annuity payment amounts in the ‘Confirm annuity payment details’ task in order to finalize implementation of this GRAT.',
};

function getValidationErrors(allTasks: DenormalizedImplementationTask[]) {
  const validationErrors: TaskValidationError[] = [];

  allTasks.forEach((task) => {
    if (task.status === TaskListItemStatus.INVALID) {
      // not all tasks have associated validation error; in fact, most don't. if there's a task that's marked as invalid that doens't have an
      // associated validation error message, log it and don't block the user from continuing
      const relevantErrorMessage = VALIDATION_ERROR_MESSAGES[task.type];
      if (!relevantErrorMessage) {
        return diagnostics.error(
          'unexpected task with validation error',
          new Error('unexpected valiation error at finalize implementation'),
          {
            taskId: task.id,
          }
        );
      }

      validationErrors.push({
        title: `Confirm task details: ${task.shortHeading}`,
        description: relevantErrorMessage,
        task: task,
      });
    }
  });

  return validationErrors;
}

interface ValidationErrorProps {
  title: string;
  description: string;
  onClickResolve: () => void;
}

function ValidationError({
  title,
  description,
  onClickResolve,
}: ValidationErrorProps) {
  return (
    <Stack
      direction="row"
      spacing={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Callout severity="warning">
        <Box mb={0.5}>
          <strong>{title}</strong>
        </Box>
        <Box>{description}</Box>
      </Callout>
      <Button
        variant="primary"
        size="md"
        onClick={onClickResolve}
        endIcon={ArrowNarrowRightIcon}
        iconProps={{ fill: 'white' }}
      >
        Resolve
      </Button>
    </Stack>
  );
}

export function FinalizeImplementationTaskBody({
  task,
  footer,
  getFirstOpenTask,
  otherTasks,
  onTaskChange,
}: ExtendedTaskBodyProps) {
  const { showFeedback } = useFeedback();

  // mutations
  const [finalizeImplementationMutation, { loading }] =
    useFinalizeImplementationMutation();

  // handlers
  const handleCompleteTask = async () => {
    // first, check to see if all of the other tasks are complete. if they're not, switch to the
    // first incomplete task, regardless of tab
    const firstOpenTask = getFirstOpenTask();
    if (firstOpenTask && firstOpenTask.id !== task.id) {
      diagnostics.debug(
        'Attempting to finalize implementation, but there are still >0 open tasks',
        {
          entityId: task.entityId,
          openTaskId: firstOpenTask.id,
        }
      );
      return onTaskChange(firstOpenTask);
    }

    // if everything is done, we can attempt to mark this task as complete and the GRAT
    // as active.
    await finalizeImplementationMutation({
      variables: {
        taskID: task.id,
        input: {
          id: task.entityId,
          update: {
            stage: EntityStage.Active,
          },
          updateGratTrust: {
            id: task.entitySubtypeId,
            update: {},
          },
        },
      },
      onCompleted: () => {
        const entityDetailsPage = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
          {
            householdId: task.householdId,
            entityId: task.entityId,
          }
        );

        // hard navigate to the household details page
        // to refetch all data after finalizing implementation
        window.location.href = entityDetailsPage;
      },
      onError: (error) => {
        diagnostics.error(
          'Failed to save FinalizeImplementationTask changes',
          error
        );
        showFeedback(
          'Failed to implement this GRAT. Please refresh the page and try again.'
        );
      },
    });
  };

  const validationErrors = getValidationErrors(otherTasks);

  return (
    <TaskHeaderCard task={task} footer={footer}>
      {validationErrors.length > 0 && (
        <Stack component="ul" spacing={2} sx={{ m: 0, p: 0, mb: 3 }}>
          {validationErrors.map((validationError, i) => (
            <ValidationError
              key={i}
              title={validationError.title}
              description={validationError.description}
              onClickResolve={() => onTaskChange(validationError.task)}
            />
          ))}
        </Stack>
      )}

      <Button
        onClick={handleCompleteTask}
        disabled={loading || validationErrors.length > 0}
        variant="primary"
        fullWidth
        size="sm"
        type="submit"
        startIcon={CheckIcon}
      >
        Finalize implementation
      </Button>
    </TaskHeaderCard>
  );
}
