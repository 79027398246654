import { Skeleton, Stack } from '@mui/material';
import { compact } from 'lodash';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { LinkTabProps } from '@/components/navigation/NavigationTabs';
import { PageHeader } from '@/components/navigation/PageHeader';
import { useModalState } from '@/hooks/useModalState';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import {
  CONTINUE_ONBOARDING_FLOW_PARAM,
  CreateHouseholdFlow,
} from '@/modules/createHousehold/CreateHouseholdFlow/CreateHouseholdFlow';
import { getCreateHouseholdFlowStep } from '@/modules/createHousehold/CreateHouseholdFlow/CreateHouseholdFlow.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { ClientDetailsPageActionItems } from '@/pages/ClientDetailsPage/ClientDetailsPageActionItems';
import { useGetClientQuery } from '@/pages/ClientDetailsPage/graphql/GetClient.generated';

export function ClientOverviewPage() {
  const location = useLocation();
  const householdId = useRequiredParam('householdId');

  // handle onboarding flow
  const searchParams = new URLSearchParams(window.location.search);
  const continueOnboardingStep = getCreateHouseholdFlowStep(
    searchParams.get(CONTINUE_ONBOARDING_FLOW_PARAM)
  );
  const [{ isModalOpen }, { closeModal }] = useModalState(
    !!continueOnboardingStep
  );

  const { data } = useGetClientQuery({
    variables: { householdId },
  });

  const client = data?.node?.__typename === 'Household' ? data.node : null;

  const clientTabs: LinkTabProps[] = React.useMemo(() => {
    return compact([
      {
        display: 'Overview',
        to: getCompletePathFromRouteKey(ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW, {
          householdId,
        }),
      },
      {
        display: 'Value creation',
        to: getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_PERFORMANCE,
          {
            householdId,
          }
        ),
      },
    ]);
  }, [householdId]);

  return (
    <>
      <Stack>
        <PageHeader
          heading={client ? client.displayName : <Skeleton width={50} />}
          tabs={clientTabs}
          action={
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={1}
            >
              <ClientDetailsPageActionItems
                householdId={householdId}
                key={location.pathname}
              />
            </Stack>
          }
        />
        <Stack p={3} pb={4} flex={1}>
          <Outlet />
        </Stack>
      </Stack>
      <CreateHouseholdFlow
        isOpen={isModalOpen}
        onClose={closeModal}
        continueFrom={continueOnboardingStep}
        continueFromHouseholdId={householdId}
      />
    </>
  );
}
