import { compact, includes, isEmpty } from 'lodash';

import { RichListItemProps } from '@/components/lists/RichListItem/RichListItem';
import { STATE_CODE_TO_NAME } from '@/constants/states';
import { EntityDetailPropsKind } from '@/modules/entities/components/EntityDetails';
import { INSURANCE_POLICY_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { getEntityTypeFromEntityKind } from '@/modules/entities/utils/getEntityTypeFromEntityKind';
import { FeatureFlagMap } from '@/modules/featureFlags/featureFlags.types';
import { getNodes } from '@/utils/graphqlUtils';

import {
  BusinessEntityDetailsTabs,
  EntityDetailsData,
} from '../../entityDetails.types';
import { BusinessEntityDetailsCard_KeyPersonFragment } from '../../graphql/EntityDetailPage.generated';
import {
  businessTaxStatusDisplayNames,
  keyPersonRoleDisplayNames,
} from '../businessEntities.constants';
import { BusinessEntitySummaryData } from './BusinessEntityDetails.types';

export function getBusinessEntitySummaryProperties({
  entity: businessEntity,
}: EntityDetailsData): BusinessEntitySummaryData {
  // using this as a heuristic typeguard rather than listing all of the various
  // business entity subtypes
  if (!('doingBusinessAsName' in businessEntity.subtype)) {
    throw new Error('Invalid business entity');
  }

  const { subtype } = businessEntity;

  return {
    _kind: EntityDetailPropsKind.BusinessEntitySummaryData as const,
    entityId: businessEntity.id,
    entityKind: businessEntity.kind,
    legalName: subtype.legalName || undefined,
    description: subtype.description || undefined,
    entityType: businessEntity.extendedDisplayKind,
    dbaName: subtype.doingBusinessAsName || undefined,
    taxStatus: subtype.businessEntityTaxStatus
      ? businessTaxStatusDisplayNames[subtype.businessEntityTaxStatus]
      : undefined,
    taxId: subtype.taxID || undefined,
    effectiveDate: subtype.effectiveDate || undefined,
    keyPeople: subtype.keyPeople || undefined,
    ownedByStakes: businessEntity.ownedByStakes || undefined,
    ownerOfStakes: businessEntity.ownedOwnershipStakes || undefined,
    keyPeopleNotes: subtype.keyPeopleNotes || undefined,
    ownershipAsOfDate: subtype.ownershipAsOfDate || undefined,
    currentValue: subtype.currentValue || undefined,
    formationJurisdiction: subtype.formationJurisdictionStateCode
      ? STATE_CODE_TO_NAME[subtype.formationJurisdictionStateCode]
      : undefined,
    detailsItems: [],
    cashflows: getNodes(businessEntity.cashflows) || [],
    requiresCtaReporting: subtype.requiresCtaReporting || undefined,
    finCenID: subtype.finCenID || undefined,
    insurancePolicies: 'policies' in subtype ? subtype.policies : undefined,
  };
}

export function getFormattedKeyPerson(
  keyPerson: BusinessEntityDetailsCard_KeyPersonFragment
): RichListItemProps & { id: string } {
  return {
    id: keyPerson.id,
    heading:
      keyPerson.entity?.subtype?.displayName ??
      keyPerson?.individual?.displayName ??
      keyPerson?.organization?.name ??
      '',
    description: compact(
      keyPerson.roles?.map((role) => {
        if (!role.kind) return null;
        return keyPersonRoleDisplayNames[role.kind];
      })
    ).join(', '),
  };
}

export function getBusinessEntityDetailsTabs(
  summaryData: BusinessEntitySummaryData,
  _featureFlags: FeatureFlagMap
) {
  const Summary = {
    display: 'Summary',
    value: BusinessEntityDetailsTabs.SUMMARY,
    props: summaryData,
  };

  const Ownership = {
    display: 'Ownership',
    value: BusinessEntityDetailsTabs.OWNERSHIP,
    props: summaryData,
  };

  const KeyPeople = {
    display: 'Key people',
    value: BusinessEntityDetailsTabs.KEY_PEOPLE,
    props: summaryData,
  };

  const InsurancePolicies = {
    display: 'Policies',
    value: BusinessEntityDetailsTabs.POLICIES,
    props: summaryData,
  };

  const showPolicies = (() => {
    const hasPolicies = !isEmpty(summaryData.insurancePolicies);
    const entityType = getEntityTypeFromEntityKind(summaryData.entityKind);
    return includes(INSURANCE_POLICY_ENTITY_TYPES, entityType) && hasPolicies;
  })();

  return compact([
    Summary,
    summaryData.ownedByStakes && Ownership,
    summaryData.keyPeople && KeyPeople,
    showPolicies && InsurancePolicies,
  ]);
}
