import { ApolloError, useApolloClient } from '@apollo/client';
import { Stack, Typography } from '@mui/material';
import { useCallback, useRef } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { DeleteButton } from '@/components/form/baseInputs/Button/DeleteButton';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Modal } from '@/components/modals/Modal/Modal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useDeleteAssetValuationMutation } from '@/modules/assetValuation/graphql/mutations.generated';
import { DocumentRepresentationList } from '@/modules/files/DocumentRepresentation/DocumentRepresentationList';
import { diagnostics } from '@/utils/diagnostics';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { useEntityViewValuationModalQuery } from './graphql/EntityViewValuationModal.generated';

export interface EntityViewValuationModalProps {
  entityId: string;
  isOpen: boolean;
  valuationId?: string | null;
  onClose: () => void;
}

function EntityViewValuationModalInner({
  entityId,
  onClose,
  valuationId,
  isOpen,
}: EntityViewValuationModalProps & { valuationId: string }) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const hasBeenSuccessfullyDeleted = useRef<boolean>(false); // using a ref here since changing this shouldn't trigger a re-render

  const client = useApolloClient();

  const { data, loading: dataLoading } = useEntityViewValuationModalQuery({
    variables: {
      valuationId,
      entityId,
      shouldSkipFetch: hasBeenSuccessfullyDeleted.current,
    },
    onError: (error: ApolloError) => {
      showFeedback(
        'Unable to load the valuation.  Please refresh and try again.'
      );
      reportError('could not load valuation', error, {
        entityId,
        valuationId,
      });
    },
  });

  const onDeleteCompletedCallback = useCallback(async () => {
    try {
      await client.refetchQueries({
        updateCache(cache) {
          cache.evict({ fieldName: 'assetValuationV2s' });
          cache.evict({
            id: `Entity:{"id":"${entityId}"}`,
          });
          cache.gc();
        },
      });
    } catch (err) {
      // this is somewhat expected, as apollo will still sometimes try to refetch on the
      // now-deleted valuation, so don't report every single error.
      diagnostics.warn(
        `Caught error when cache purging and garbage collecting after deleting ${valuationId}`,
        err as Error
      );
    }
    showFeedback('Successfully deleted valuation.', {
      variant: 'success',
    });
    onClose();
  }, [client, entityId, onClose, showFeedback, valuationId]);

  const [deleteEntityValuation, { loading: deleteLoading }] =
    useDeleteAssetValuationMutation({
      onError: (err: ApolloError) => {
        showFeedback(
          'Could not delete the valuation.  Please refresh and try again.'
        );
        reportError(`Caught error when deleting ${valuationId}`, err, {
          entityId,
          valuationId,
        });
      },
      onCompleted: async () => {
        hasBeenSuccessfullyDeleted.current = true;
        await onDeleteCompletedCallback();
      },
    });

  const deleteValuation = useCallback(async () => {
    if (!valuationId) {
      return;
    }

    await deleteEntityValuation({
      variables: {
        assetValuationId: valuationId,
      },
    });
  }, [valuationId, deleteEntityValuation]);

  const loading = deleteLoading || dataLoading;

  const valuation = data?.node;

  // typeguard
  if (valuation?.__typename !== 'AssetValuationV2') {
    return null;
  }

  const documentIds = (valuation?.documents || [])
    .map((doc) => doc.id)
    .filter((item) => !!item);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      heading={
        loading
          ? '' // show blank instead of flashing
          : `Valuation on ${formatDateToMonDDYYYY(valuation?.effectiveDate)}`
      }
      actions={
        <Stack direction="row" justifyContent="space-between" width="100%">
          <DeleteButton
            onConfirmDelete={deleteValuation}
            loading={deleteLoading}
            disabled={loading}
          />
          <Button
            variant="secondary"
            size="md"
            disabled={loading || deleteLoading}
            onClick={onClose}
          >
            Close
          </Button>
        </Stack>
      }
    >
      <Stack>
        <FormLayoutRow>
          <FormLayoutItem>
            {valuation.valuationValue ? (
              <>
                <Typography variant="h1" component="h2">
                  {formatCurrencyNoDecimals(valuation.valuationValue)}
                </Typography>
                <Typography variant="caption">
                  Updated by {valuation.user.displayName}
                </Typography>
              </>
            ) : (
              'Could not load a valuation'
            )}
          </FormLayoutItem>
        </FormLayoutRow>

        {valuation.description && (
          <FormLayoutRow>
            <FormLayoutItem>
              <Typography>{valuation.description}</Typography>
            </FormLayoutItem>
          </FormLayoutRow>
        )}
        <FormLayoutRow>
          <FormLayoutItem>
            <DocumentRepresentationList
              showDelete={false}
              documentIds={documentIds}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      </Stack>
    </Modal>
  );
}

export function EntityViewValuationModal(props: EntityViewValuationModalProps) {
  const { showFeedback } = useFeedback();
  const { isOpen, valuationId } = props;
  if (!isOpen) {
    return null;
  }

  if (!valuationId) {
    showFeedback('Could not look up valuation history');
    return null;
  }

  return <EntityViewValuationModalInner {...props} valuationId={valuationId} />;
}
