// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLifetimeExclusionEventMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateLifetimeExclusionEventInput;
}>;


export type CreateLifetimeExclusionEventMutation = { __typename?: 'Mutation', createLifetimeExclusionEvent: { __typename?: 'LifetimeExclusionEvent', id: string } };

export type UpdateLifetimeExclusionEventMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateLifetimeExclusionEventInput;
}>;


export type UpdateLifetimeExclusionEventMutation = { __typename?: 'Mutation', updateLifetimeExclusionEvent: { __typename?: 'LifetimeExclusionEvent', id: string } };

export type DeleteLifetimeExclusionEventMutationVariables = Types.Exact<{
  deleteLifetimeExclusionEventId: Types.Scalars['ID']['input'];
}>;


export type DeleteLifetimeExclusionEventMutation = { __typename?: 'Mutation', deleteLifetimeExclusionEvent: string };

export type LifetimeExclusionEventsWithLoggedTransfersQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.LifetimeExclusionEventWhereInput>;
}>;


export type LifetimeExclusionEventsWithLoggedTransfersQuery = { __typename?: 'Query', lifetimeExclusionEvents: { __typename?: 'LifetimeExclusionEventConnection', edges?: Array<{ __typename?: 'LifetimeExclusionEventEdge', node?: { __typename?: 'LifetimeExclusionEvent', id: string, loggedTransfer?: { __typename?: 'LoggedTransfer', transactionDate: Time, amount: CurrencyUSD, createdBy: string, purpose?: Types.LoggedTransferPurpose | null, id: string, receivingEntity?: { __typename?: 'Entity', id: string } | null, receivingGrantor?: { __typename?: 'ClientProfile', id: string } | null, receivingOrganization?: { __typename?: 'ClientOrganization', id: string } | null, sourceGrantor?: { __typename?: 'ClientProfile', id: string } | null, documents?: Array<{ __typename?: 'Document', id: string }> | null, lifetimeExclusionEvents?: Array<{ __typename?: 'LifetimeExclusionEvent', id: string, annualExclusionAmount?: CurrencyUSD | null, discountAmount?: CurrencyUSD | null, giftCategory?: Types.LifetimeExclusionEventGiftCategory | null, gstExclusionAmount: CurrencyUSD, kind: Types.LifetimeExclusionEventKind, lifetimeExclusionAmount: CurrencyUSD, notes?: string | null }> | null } | null } | null } | null> | null } };


export const CreateLifetimeExclusionEventDocument = gql`
    mutation CreateLifetimeExclusionEvent($input: AugmentedCreateLifetimeExclusionEventInput!) {
  createLifetimeExclusionEvent(input: $input) {
    id
  }
}
    `;
export type CreateLifetimeExclusionEventMutationFn = Apollo.MutationFunction<CreateLifetimeExclusionEventMutation, CreateLifetimeExclusionEventMutationVariables>;

/**
 * __useCreateLifetimeExclusionEventMutation__
 *
 * To run a mutation, you first call `useCreateLifetimeExclusionEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLifetimeExclusionEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLifetimeExclusionEventMutation, { data, loading, error }] = useCreateLifetimeExclusionEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLifetimeExclusionEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateLifetimeExclusionEventMutation, CreateLifetimeExclusionEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLifetimeExclusionEventMutation, CreateLifetimeExclusionEventMutationVariables>(CreateLifetimeExclusionEventDocument, options);
      }
export type CreateLifetimeExclusionEventMutationHookResult = ReturnType<typeof useCreateLifetimeExclusionEventMutation>;
export type CreateLifetimeExclusionEventMutationResult = Apollo.MutationResult<CreateLifetimeExclusionEventMutation>;
export type CreateLifetimeExclusionEventMutationOptions = Apollo.BaseMutationOptions<CreateLifetimeExclusionEventMutation, CreateLifetimeExclusionEventMutationVariables>;
export const UpdateLifetimeExclusionEventDocument = gql`
    mutation UpdateLifetimeExclusionEvent($input: AugmentedUpdateLifetimeExclusionEventInput!) {
  updateLifetimeExclusionEvent(input: $input) {
    id
  }
}
    `;
export type UpdateLifetimeExclusionEventMutationFn = Apollo.MutationFunction<UpdateLifetimeExclusionEventMutation, UpdateLifetimeExclusionEventMutationVariables>;

/**
 * __useUpdateLifetimeExclusionEventMutation__
 *
 * To run a mutation, you first call `useUpdateLifetimeExclusionEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLifetimeExclusionEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLifetimeExclusionEventMutation, { data, loading, error }] = useUpdateLifetimeExclusionEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLifetimeExclusionEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLifetimeExclusionEventMutation, UpdateLifetimeExclusionEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLifetimeExclusionEventMutation, UpdateLifetimeExclusionEventMutationVariables>(UpdateLifetimeExclusionEventDocument, options);
      }
export type UpdateLifetimeExclusionEventMutationHookResult = ReturnType<typeof useUpdateLifetimeExclusionEventMutation>;
export type UpdateLifetimeExclusionEventMutationResult = Apollo.MutationResult<UpdateLifetimeExclusionEventMutation>;
export type UpdateLifetimeExclusionEventMutationOptions = Apollo.BaseMutationOptions<UpdateLifetimeExclusionEventMutation, UpdateLifetimeExclusionEventMutationVariables>;
export const DeleteLifetimeExclusionEventDocument = gql`
    mutation DeleteLifetimeExclusionEvent($deleteLifetimeExclusionEventId: ID!) {
  deleteLifetimeExclusionEvent(id: $deleteLifetimeExclusionEventId)
}
    `;
export type DeleteLifetimeExclusionEventMutationFn = Apollo.MutationFunction<DeleteLifetimeExclusionEventMutation, DeleteLifetimeExclusionEventMutationVariables>;

/**
 * __useDeleteLifetimeExclusionEventMutation__
 *
 * To run a mutation, you first call `useDeleteLifetimeExclusionEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLifetimeExclusionEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLifetimeExclusionEventMutation, { data, loading, error }] = useDeleteLifetimeExclusionEventMutation({
 *   variables: {
 *      deleteLifetimeExclusionEventId: // value for 'deleteLifetimeExclusionEventId'
 *   },
 * });
 */
export function useDeleteLifetimeExclusionEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLifetimeExclusionEventMutation, DeleteLifetimeExclusionEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLifetimeExclusionEventMutation, DeleteLifetimeExclusionEventMutationVariables>(DeleteLifetimeExclusionEventDocument, options);
      }
export type DeleteLifetimeExclusionEventMutationHookResult = ReturnType<typeof useDeleteLifetimeExclusionEventMutation>;
export type DeleteLifetimeExclusionEventMutationResult = Apollo.MutationResult<DeleteLifetimeExclusionEventMutation>;
export type DeleteLifetimeExclusionEventMutationOptions = Apollo.BaseMutationOptions<DeleteLifetimeExclusionEventMutation, DeleteLifetimeExclusionEventMutationVariables>;
export const LifetimeExclusionEventsWithLoggedTransfersDocument = gql`
    query LifetimeExclusionEventsWithLoggedTransfers($where: LifetimeExclusionEventWhereInput) {
  lifetimeExclusionEvents(where: $where) {
    edges {
      node {
        id
        loggedTransfer {
          transactionDate
          amount
          createdBy
          receivingEntity {
            id
          }
          receivingGrantor {
            id
          }
          receivingOrganization {
            id
          }
          sourceGrantor {
            id
          }
          documents {
            id
          }
          purpose
          id
          lifetimeExclusionEvents {
            id
            annualExclusionAmount
            discountAmount
            giftCategory
            gstExclusionAmount
            kind
            lifetimeExclusionAmount
            notes
          }
        }
      }
    }
  }
}
    `;

/**
 * __useLifetimeExclusionEventsWithLoggedTransfersQuery__
 *
 * To run a query within a React component, call `useLifetimeExclusionEventsWithLoggedTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLifetimeExclusionEventsWithLoggedTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLifetimeExclusionEventsWithLoggedTransfersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLifetimeExclusionEventsWithLoggedTransfersQuery(baseOptions?: Apollo.QueryHookOptions<LifetimeExclusionEventsWithLoggedTransfersQuery, LifetimeExclusionEventsWithLoggedTransfersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LifetimeExclusionEventsWithLoggedTransfersQuery, LifetimeExclusionEventsWithLoggedTransfersQueryVariables>(LifetimeExclusionEventsWithLoggedTransfersDocument, options);
      }
export function useLifetimeExclusionEventsWithLoggedTransfersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LifetimeExclusionEventsWithLoggedTransfersQuery, LifetimeExclusionEventsWithLoggedTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LifetimeExclusionEventsWithLoggedTransfersQuery, LifetimeExclusionEventsWithLoggedTransfersQueryVariables>(LifetimeExclusionEventsWithLoggedTransfersDocument, options);
        }
export function useLifetimeExclusionEventsWithLoggedTransfersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LifetimeExclusionEventsWithLoggedTransfersQuery, LifetimeExclusionEventsWithLoggedTransfersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LifetimeExclusionEventsWithLoggedTransfersQuery, LifetimeExclusionEventsWithLoggedTransfersQueryVariables>(LifetimeExclusionEventsWithLoggedTransfersDocument, options);
        }
export type LifetimeExclusionEventsWithLoggedTransfersQueryHookResult = ReturnType<typeof useLifetimeExclusionEventsWithLoggedTransfersQuery>;
export type LifetimeExclusionEventsWithLoggedTransfersLazyQueryHookResult = ReturnType<typeof useLifetimeExclusionEventsWithLoggedTransfersLazyQuery>;
export type LifetimeExclusionEventsWithLoggedTransfersSuspenseQueryHookResult = ReturnType<typeof useLifetimeExclusionEventsWithLoggedTransfersSuspenseQuery>;
export type LifetimeExclusionEventsWithLoggedTransfersQueryResult = Apollo.QueryResult<LifetimeExclusionEventsWithLoggedTransfersQuery, LifetimeExclusionEventsWithLoggedTransfersQueryVariables>;
export const Operations = {
  Query: {
    LifetimeExclusionEventsWithLoggedTransfers: 'LifetimeExclusionEventsWithLoggedTransfers' as const
  },
  Mutation: {
    CreateLifetimeExclusionEvent: 'CreateLifetimeExclusionEvent' as const,
    UpdateLifetimeExclusionEvent: 'UpdateLifetimeExclusionEvent' as const,
    DeleteLifetimeExclusionEvent: 'DeleteLifetimeExclusionEvent' as const
  }
}