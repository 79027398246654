import { useEffect, useState } from 'react';

import { InteractionParadigmContext } from '@/contexts/InteractionParadigm.context';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { useGuardedTenantPresentationConfigurationContext } from '@/modules/presentation/context/tenantPresentationConfiguration.context';
import { TenantPresentationConfigurationProvider } from '@/modules/presentation/context/TenantPresentationConfiguration.provider';
import { useEntityPresentationDetails } from '@/modules/presentation/entities/hooks/useEntityPresentationDetails';
import { SingleEntityPresentationBundle } from '@/modules/presentation/entities/SingleEntityPresentationBundle/SingleEntityPresentationBundle';
import { ALL_ENTITY_SLIDE_TYPES } from '@/modules/presentation/entities/SingleEntityPresentationBundle/SingleEntityPresentationBundle.constants';
import { useSubBrand } from '@/modules/tenant/TenantDetailsContext/useSubBrand';

import { PrintStatusSentinel } from '../components/PrintStatusSentinel';
import { PrintStatuses } from '../printOnly.constants';

function PrintOnlySingleEntityInner() {
  const [printStatus, setPrintStatus] = useState<PrintStatuses>(
    PrintStatuses.LOADING
  );
  const entityId = useRequiredParam('entityId');
  const {
    entityDetails,
    subtypeDetails,
    dispositionScenariosDetails,
    legalDisclaimers,
    loading,
    error,
  } = useEntityPresentationDetails({
    variables: { entityId },
  });

  const { setLegalDisclaimers } =
    useGuardedTenantPresentationConfigurationContext();
  useEffect(() => {
    setLegalDisclaimers(legalDisclaimers);
  }, [legalDisclaimers, setLegalDisclaimers]);

  useSubBrand(entityDetails?.household.subBrand?.id ?? null);

  // we wrap this in a useEffect instead of doing straight computation of the status because
  // the MUI table component needs an extra pass to render properly
  useEffect(() => {
    if (loading || !entityDetails) {
      setPrintStatus(PrintStatuses.LOADING);
      return;
    }
    if (error) {
      setPrintStatus(PrintStatuses.ERROR);
    }

    setTimeout(() => {
      setPrintStatus(PrintStatuses.READY);
    }, 250);
  }, [entityDetails, error, loading]);

  return (
    <InteractionParadigmContext.Provider value={{ viewOnly: true }}>
      <style type="text/css" media="print">
        {
          // print-color-adjust indicates to the printing mechanism that the printer
          // should faithfully include background colors and images, rather than excluding
          // them to quaintly save ink
          '@page {size: landscape; margin: 0 0 0 0; print-color-adjust: exact;}'
        }
        {'@media print { body { zoom: 75% } }'}
      </style>
      {/* this is used to indicate the status of the document to the PDF printing service, so it knows to delay until everything is ready */}
      <PrintStatusSentinel status={printStatus} />
      <SingleEntityPresentationBundle
        slideTypes={ALL_ENTITY_SLIDE_TYPES}
        entity={entityDetails}
        subtype={subtypeDetails}
        dispositionScenarios={dispositionScenariosDetails}
        entityId={entityId}
      />
    </InteractionParadigmContext.Provider>
  );
}

export function PrintOnlySingleEntity() {
  return (
    <TenantPresentationConfigurationProvider>
      <PrintOnlySingleEntityInner />
    </TenantPresentationConfigurationProvider>
  );
}
