import { PossibleHouseholdBeneficiaryFragment } from '@/modules/household/contexts/graphql/GetClientDetails.generated';
import { ContextPrimaryClient } from '@/modules/household/contexts/householdDetails.context';
import { AfterDeath } from '@/types/schema';

import { BeneficiaryReport } from '../beneficiaryReporting.types';
import { getAccessValueForDeathEvent } from '../beneficiaryReporting.utils';

export interface GetBenefitsOverviewMetricsInput {
  beneficiaryDataByAfterDeath: [
    AfterDeath,
    Record<string, BeneficiaryReport>,
  ][];
  secondGrantor?: ContextPrimaryClient;
  possibleBeneficiaries: PossibleHouseholdBeneficiaryFragment;
}

export function getBenefitsOverviewMetrics({
  beneficiaryDataByAfterDeath,
  secondGrantor,
  possibleBeneficiaries,
}: GetBenefitsOverviewMetricsInput) {
  const clientIds = possibleBeneficiaries?.clients.map((c) => c.id) ?? [];
  const orgIds = possibleBeneficiaries?.organizations.map((o) => o.id) ?? [];

  const beneficiaryReportsForSecondGrantor: {
    beneficiaryReport: BeneficiaryReport;
    afterDeath: AfterDeath;
  }[] = beneficiaryDataByAfterDeath
    .flatMap(([afterDeath, beneficiaryReport]) => {
      if (!secondGrantor?.id) {
        return [];
      }

      if (!beneficiaryReport[secondGrantor.id]) {
        return [];
      }

      return {
        beneficiaryReport: beneficiaryReport[secondGrantor.id]!,
        afterDeath,
      };
    })
    .filter(({ beneficiaryReport }) => beneficiaryReport !== undefined);

  const beneficiaryReportsForIndividuals: {
    beneficiaryReport: BeneficiaryReport;
    afterDeath: AfterDeath;
  }[] = beneficiaryDataByAfterDeath
    .flatMap(([afterDeath, beneficiaryReport]) => {
      // remove all org beneficiaries
      return Object.entries(beneficiaryReport).flatMap(([key, value]) => {
        if (clientIds.includes(key)) {
          return [{ beneficiaryReport: value, afterDeath }];
        }
        return [];
      });
    })
    .filter(({ beneficiaryReport }) => beneficiaryReport !== undefined);

  const beneficiaryReportsForOrganizations: {
    beneficiaryReport: BeneficiaryReport;
    afterDeath: AfterDeath;
  }[] = beneficiaryDataByAfterDeath
    .flatMap(([afterDeath, beneficiaryReport]) => {
      // remove all client beneficiaries
      return Object.entries(beneficiaryReport).flatMap(([key, value]) => {
        if (orgIds.includes(key)) {
          return [{ beneficiaryReport: value, afterDeath }];
        }
        return [];
      });
    })
    .filter(({ beneficiaryReport }) => beneficiaryReport !== undefined);

  const allAccessValueForSecondGrantor = getAccessValueForDeathEvent(
    beneficiaryReportsForSecondGrantor
  );

  const allAccessValueForIndividuals = getAccessValueForDeathEvent(
    beneficiaryReportsForIndividuals
  );

  const allAccessValueForOrganizations = getAccessValueForDeathEvent(
    beneficiaryReportsForOrganizations
  );

  return {
    allAccessValueForSecondGrantor,
    allAccessValueForIndividuals,
    allAccessValueForOrganizations,
  };
}
