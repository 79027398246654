import { merge } from 'lodash';

import { SUPPORTED_HYPOTHETICAL_ENTITY_KINDS } from '@/modules/entities/draftEntities/draftEntities.constants';
import {
  ALLOW_MULTIPLE_PRINCIPALS_KINDS,
  DONOR_ENTITY_KINDS,
  GRANTOR_ENTITY_KINDS,
  OWNER_ENTITY_KINDS,
} from '@/modules/entities/entities.constants';
import {
  entityGstStatusToApiTypeMap,
  estateInclusionStatusToApiTypeMap,
  nonTrustEntityTaxStatusToApiTypeMap,
  trustTaxStatusToApiTypeMap,
} from '@/modules/entities/EntitySubforms/utils/shared/taxStatus.utils';
import { getTaxStatusValuesFromEntityKind } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.utils';
import {
  AugmentedCreateCltTrustInput,
  AugmentedCreateCrtTrustInput,
  AugmentedCreateDonorAdvisedFundInput,
  AugmentedCreateEntityInput,
  AugmentedCreateGratTrustInput,
  AugmentedCreateIlitTrustInput,
  AugmentedCreateIndividualPersonalAccountInput,
  AugmentedCreateIrrevocableTrustInput,
  AugmentedCreateJointPersonalAccountInput,
  AugmentedCreatePrincipalInput,
  AugmentedCreatePrivateFoundationInput,
  AugmentedCreateQprtTrustInput,
  AugmentedCreateQualifiedTuitionPersonalAccountInput,
  AugmentedCreateRetirementPersonalAccountInput,
  AugmentedCreateRevocableTrustInput,
  AugmentedCreateSlatTrustInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateEstateWaterfallInput,
  EntityKind,
  EntityStage,
  EstateWaterfallHypotheticalTransferDestinationKind,
  EstateWaterfallHypotheticalTransferSourceKind,
  EstateWaterfallHypotheticalTransferTransferTaxKind,
  Exact,
} from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { GetHypotheticalTransfers_HypotheticalTransferFragment } from '../graphql/GetHypotheticalTransfers.generated';
import {
  HypotheticalTransferFormShape,
  TransferDirection,
} from './HypotheticalTransferForm';
import { TransferReferenceObj } from './HypotheticalTransferForm.types';

export const EXTERNAL_ENTITY_SENTINEL = 'external';

interface Destination {
  destinationKind: EstateWaterfallHypotheticalTransferDestinationKind;
  destinationEntityID?: string;
  destinationOrganizationID?: string;
  destinationIndividualID?: string;
}

interface Source {
  sourceKind: EstateWaterfallHypotheticalTransferSourceKind;
  sourceEntityID?: string;
  sourceOrganizationID?: string;
  sourceIndividualID?: string;
}

function getDestinationAndSourceFromFormData(
  formData: HypotheticalTransferFormShape,
  transferReferenceObj: TransferReferenceObj
): { destination: Destination; source: Source } {
  const transferDirection = formData.transferDirection;

  const transferReferenceObjId = (() => {
    if (transferReferenceObj === EXTERNAL_ENTITY_SENTINEL) {
      return EXTERNAL_ENTITY_SENTINEL;
    }
    return transferReferenceObj.id;
  })();

  let sourceEntityID: undefined | string = undefined;
  if (
    typeof transferReferenceObj !== 'string' &&
    transferReferenceObj.__typename === 'Entity'
  ) {
    sourceEntityID = transferReferenceObj.id;
  }
  let sourceIndividualID: undefined | string = undefined;
  let inboundDestinationKind =
    EstateWaterfallHypotheticalTransferDestinationKind.Entity;
  if (
    typeof transferReferenceObj !== 'string' &&
    transferReferenceObj.__typename === 'ClientProfile'
  ) {
    sourceIndividualID = transferReferenceObj.id;
    inboundDestinationKind =
      EstateWaterfallHypotheticalTransferDestinationKind.Individual;
  }

  if (!formData.destinationKind) {
    throw new Error(
      'Cannot create a hypothetical transfer without a destination kind'
    );
  }

  if (!formData.sourceKind) {
    throw new Error(
      'Cannot create a hypothetical transfer without a source kind'
    );
  }

  // If the transfer direction has been switched to inbound, then the destination entity is the
  // current entity and the source entity is the entity selected in the form.
  const destination: {
    destinationKind: EstateWaterfallHypotheticalTransferDestinationKind;
    destinationEntityID?: string;
    destinationOrganizationID?: string;
    destinationIndividualID?: string;
  } = {
    destinationKind: formData.destinationKind,
    destinationEntityID: formData.destinationEntityID ?? undefined,
    destinationIndividualID: formData.destinationIndividualID ?? undefined,
    destinationOrganizationID: formData.destinationOrganizationID ?? undefined,
  };
  const source: {
    // If the transfer direction has been switched to inbound, then the source entity is the
    // current entity and the destination entity is the entity selected in the form.
    sourceKind: EstateWaterfallHypotheticalTransferSourceKind;

    sourceEntityID?: string;
    sourceOrganizationID?: string;
    sourceIndividualID?: string;
  } = {
    sourceKind: formData.sourceKind,
    sourceEntityID,
    sourceIndividualID,
  };

  if (transferDirection === TransferDirection.Inbound) {
    destination.destinationKind = inboundDestinationKind;
    destination.destinationEntityID =
      inboundDestinationKind ===
      EstateWaterfallHypotheticalTransferDestinationKind.Entity
        ? transferReferenceObjId
        : undefined;
    destination.destinationIndividualID =
      inboundDestinationKind ===
      EstateWaterfallHypotheticalTransferDestinationKind.Individual
        ? transferReferenceObjId
        : undefined;
    // Inbound transfers are always from a supported source kind
    source.sourceKind =
      formData.destinationKind as unknown as EstateWaterfallHypotheticalTransferSourceKind;
    source.sourceEntityID = formData.destinationEntityID ?? undefined;
    source.sourceIndividualID = formData.destinationIndividualID ?? undefined;
    source.sourceOrganizationID =
      formData.destinationOrganizationID ?? undefined;
  }

  return {
    destination,
    source,
  };
}

interface GetCreateHypotheticalTransfersInputProps {
  waterfallId: string;
  transferReferenceObj: TransferReferenceObj;
  formData: HypotheticalTransferFormShape;
  transfers: {
    inboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
    outboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
  };
  isShouldTransferToNewHypotheticalEntitySwitchEnabled: boolean;
  householdID: string;
}

export function getCreateHypotheticalTransfersInput({
  waterfallId,
  transferReferenceObj,
  formData,
  transfers: { outboundTransfers, inboundTransfers },
  isShouldTransferToNewHypotheticalEntitySwitchEnabled,
  householdID,
}: GetCreateHypotheticalTransfersInputProps): AugmentedUpdateEstateWaterfallInput {
  const transferDirection = formData.transferDirection;

  const { destination, source } = getDestinationAndSourceFromFormData(
    formData,
    transferReferenceObj
  );

  const transfers =
    transferDirection === TransferDirection.Inbound
      ? inboundTransfers
      : outboundTransfers;

  const commonInputProps: AugmentedUpdateEstateWaterfallInput = {
    id: waterfallId,
    update: {},
    withHypotheticalTransfers: [
      {
        create: {
          ...destination,
          ...source,
          giftingGrantorIDs: formData.giftingGrantorIDs,
          isGstGift: !!formData.isGstGift,
          transferOrder: transfers.length,
          transferTaxKind:
            formData.transferTaxKind ??
            EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift,
          transferValue: formData.transferValue,
        },
      },
    ],
  };

  if (isShouldTransferToNewHypotheticalEntitySwitchEnabled) {
    if (transferReferenceObj === EXTERNAL_ENTITY_SENTINEL) {
      throw new Error('Cannot create a draft entity from an external source');
    }

    const possibleClients =
      transferReferenceObj.household?.possiblePrimaryClients ?? [];

    const entityInput = getCreateHypotheticalEntityInput(
      formData,
      possibleClients
    );

    // Merge to avoid overwriting the common input props
    // and merge nested objects
    const input = merge({}, commonInputProps, {
      withHypotheticalTransfers: [
        {
          withDestinationEntity: {
            create: {
              stage: EntityStage.Draft,
              householdID,
            },
            ...entityInput,
          },
        },
      ],
    });

    return input;
  }

  return {
    ...commonInputProps,
  };
}

export interface GetUpdateHypotheticalTransfersInputProps {
  waterfallId: string;
  transferReferenceObj: TransferReferenceObj;
  formData: HypotheticalTransferFormShape;
  transferId: string;
  transfers: {
    inboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
    outboundTransfers: GetHypotheticalTransfers_HypotheticalTransferFragment[];
  };
  isShouldTransferToNewHypotheticalEntitySwitchEnabled: boolean;
  householdID: string;
}

export function getUpdateHypotheticalTransfersInput({
  waterfallId,
  transferReferenceObj,
  formData,
  transferId,
  transfers: { outboundTransfers, inboundTransfers },
  isShouldTransferToNewHypotheticalEntitySwitchEnabled,
}: GetUpdateHypotheticalTransfersInputProps): AugmentedUpdateEstateWaterfallInput {
  const transferDirection = formData.transferDirection;

  const { destination, source } = getDestinationAndSourceFromFormData(
    formData,
    transferReferenceObj
  );

  const transfers =
    transferDirection === TransferDirection.Inbound
      ? inboundTransfers
      : outboundTransfers;

  let currentSortIdx: number | null = transfers
    .sort((a, b) => a.transferOrder - b.transferOrder)
    .findIndex((t) => t.id === transferId);

  if (currentSortIdx === -1) {
    currentSortIdx = null;
  }

  const commonInputProps: AugmentedUpdateEstateWaterfallInput = {
    id: waterfallId,

    update: {},
    updateHypotheticalTransfers: [
      {
        id: transferId,
        update: {
          clearGiftingGrantors: true,
          ...destination,
          ...source,
          destinationKind:
            formData.destinationKind ??
            EstateWaterfallHypotheticalTransferDestinationKind.Entity,
          addGiftingGrantorIDs: formData.giftingGrantorIDs,
          isGstGift: !!formData.isGstGift,
          transferOrder: currentSortIdx ?? transfers.length,
          transferTaxKind:
            formData.transferTaxKind ??
            EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift,
          transferValue: formData.transferValue,
        },
      },
    ],
  };

  if (isShouldTransferToNewHypotheticalEntitySwitchEnabled) {
    if (transferReferenceObj === EXTERNAL_ENTITY_SENTINEL) {
      throw new Error('Cannot create a draft entity from an external source');
    }

    const possibleClients =
      transferReferenceObj.household?.possiblePrimaryClients ?? [];
    const entityInput = getUpdateHypotheticalEntityInput(
      formData,
      possibleClients
    );

    // Merge to avoid overwriting the common input props
    // and merge nested objects
    const input = merge({}, commonInputProps, {
      updateHypotheticalTransfers: [
        {
          updateDestinationEntity: entityInput,
        },
      ],
    });

    return input;
  }

  return commonInputProps;
}

export function getUpdateHypotheticalTransfersOrderInput({
  waterfallId,
  transfers,
}: {
  waterfallId: string;
  transfers: { id: string }[];
}): AugmentedUpdateEstateWaterfallInput {
  const updateHypotheticalTransfers = transfers.map((t, idx) => {
    return {
      id: t.id,
      update: {
        transferOrder: idx,
      },
    };
  });

  return {
    id: waterfallId,
    update: {},
    updateHypotheticalTransfers,
  };
}

export function getDeleteHypotheticalTransfersInput({
  waterfallId,
  transferId,
}: {
  waterfallId: string;
  transferId: string;
}): AugmentedUpdateEstateWaterfallInput {
  return {
    id: waterfallId,

    update: {
      removeHypotheticalTransferIDs: [transferId],
    },
  };
}

const DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT = {
  create: {
    displayName: 'Default account',
  },
  withInitialValuation: null,
  withValuations: [],
};

type CreateHypotheticalEntityInput = Omit<AugmentedCreateEntityInput, 'create'>;
function getCreateHypotheticalEntityInput(
  formData: HypotheticalTransferFormShape,
  possiblePrimaryClients: {
    id: string;
    displayName: string;
  }[]
): CreateHypotheticalEntityInput {
  if (!formData.hypotheticalEntityKind) {
    throw new Error('Cannot create a draft entity without a draft entity kind');
  }

  const entityKind = formData.hypotheticalEntityKind;

  if (!SUPPORTED_HYPOTHETICAL_ENTITY_KINDS.includes(entityKind)) {
    throw new Error(
      `Unsupported draft entity kind: ${entityKind}. Supported kinds are: ${SUPPORTED_HYPOTHETICAL_ENTITY_KINDS.join(
        ', '
      )}`
    );
  }

  const hypotheticalGrantorOrDonorOrOwnerIDs: string[] = (() => {
    if ((formData.hypotheticalEntityGrantor ?? '') === 'both') {
      return possiblePrimaryClients.map((c) => c.id);
    }
    return [formData.hypotheticalEntityGrantor];
  })();

  const createPrincipalInput: AugmentedCreatePrincipalInput[] =
    hypotheticalGrantorOrDonorOrOwnerIDs.map((id) => ({
      create: {
        individualID: id,
      },
    }));

  const taxStatusValues = getTaxStatusValuesFromEntityKind(entityKind);

  const taxStatus = taxStatusValues.taxStatus
    ? trustTaxStatusToApiTypeMap[taxStatusValues.taxStatus]
    : undefined;
  const nonTrustEntityTaxStatus = taxStatusValues.nonTrustEntityTaxStatus
    ? nonTrustEntityTaxStatusToApiTypeMap[
        taxStatusValues.nonTrustEntityTaxStatus
      ]
    : undefined;
  const inEstateStatus =
    estateInclusionStatusToApiTypeMap[taxStatusValues.estateInclusionStatus];
  const gstStatus = taxStatusValues.gstStatus
    ? entityGstStatusToApiTypeMap[taxStatusValues.gstStatus]
    : undefined;

  const commonInputTrust: Exact<
    | AugmentedCreateIrrevocableTrustInput
    | AugmentedCreateRevocableTrustInput
    | AugmentedCreateSlatTrustInput
    | AugmentedCreateGratTrustInput
    | AugmentedCreateIlitTrustInput
    | AugmentedCreateQprtTrustInput
    | AugmentedCreateCrtTrustInput
    | AugmentedCreateCltTrustInput
  > = {
    create: {
      displayName: formData.hypotheticalEntityName,
      taxStatus: taxStatus ?? undefined,
      inEstateStatus,
      gstStatus,
    },
  };

  const commonInputNonTrust: Exact<
    | AugmentedCreateIndividualPersonalAccountInput
    | AugmentedCreateJointPersonalAccountInput
    | AugmentedCreateRetirementPersonalAccountInput
    | AugmentedCreateQualifiedTuitionPersonalAccountInput
    | AugmentedCreateDonorAdvisedFundInput
    | AugmentedCreatePrivateFoundationInput
  > = {
    create: {
      displayName: formData.hypotheticalEntityName,
      taxStatus: nonTrustEntityTaxStatus ?? undefined,
      inEstateStatus,
      gstStatus,
    },
  };

  switch (entityKind) {
    case EntityKind.IrrevocableTrust:
      return {
        withIrrevocableTrust: {
          create: {
            ...commonInputTrust.create,
          },
          withGrantors: createPrincipalInput,
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.RevocableTrust:
      return {
        withRevocableTrust: {
          create: {
            ...commonInputTrust.create,
          },
          withGrantors: createPrincipalInput,
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.SlatTrust:
      return {
        withSlatTrust: {
          create: {
            ...commonInputTrust.create,
          },
          withGrantor: createPrincipalInput[0],
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.GratTrust:
      return {
        withGratTrust: {
          create: {
            ...commonInputTrust.create,
          },
          withGrantor: createPrincipalInput[0],
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.IlitTrust:
      return {
        withIlitTrust: {
          create: {
            ...commonInputTrust.create,
          },
          withGrantors: createPrincipalInput,
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.QprtTrust:
      return {
        withQprtTrust: {
          create: {
            ...commonInputTrust.create,
          },
          withGrantors: createPrincipalInput,
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.IndividualPersonalAccount:
      return {
        withIndividualPersonalAccount: {
          create: {
            ...commonInputNonTrust.create,
          },
          withOwner: createPrincipalInput[0],
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.JointPersonalAccount:
      return {
        withJointPersonalAccount: {
          create: {
            ...(commonInputNonTrust.create as AugmentedCreateJointPersonalAccountInput['create']),
          },
          withOwners: createPrincipalInput,
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.RetirementPersonalAccount:
      return {
        withRetirementPersonalAccount: {
          create: {
            ...(commonInputNonTrust.create as AugmentedCreateRetirementPersonalAccountInput['create']),
          },
          withOwner: createPrincipalInput[0],
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.QualifiedTuitionPersonalAccount:
      return {
        withQualifiedTuitionPersonalAccount: {
          create: {
            ...commonInputNonTrust.create,
          },
          withOwner: createPrincipalInput[0],
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.CrtTrust:
      return {
        withCrtTrust: {
          create: {
            ...commonInputTrust.create,
          },
          withGrantors: createPrincipalInput,
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.CltTrust:
      return {
        withCltTrust: {
          create: {
            ...commonInputTrust.create,
          },
          withGrantors: createPrincipalInput,
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.DonorAdvisedFund:
      return {
        withDonorAdvisedFund: {
          create: {
            ...commonInputNonTrust.create,
          },
          withDonors: createPrincipalInput,
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };
    case EntityKind.PrivateFoundation:
      return {
        withPrivateFoundation: {
          create: {
            ...commonInputNonTrust.create,
          },
          withDonors: createPrincipalInput,
          withDesignerAccount: DEFAULT_HYPOTHETICAL_EMPTY_ACCOUNT,
        },
      };

    default:
      throw new UnreachableError({
        case: entityKind as never,
        message: `Unrecognized entity type ${entityKind}`,
      });
  }
}

type UpdateHypotheticalEntityInput = AugmentedUpdateEntityInput;
function getUpdateHypotheticalEntityInput(
  formData: HypotheticalTransferFormShape,
  possiblePrimaryClients: {
    id: string;
    displayName: string;
  }[]
): UpdateHypotheticalEntityInput {
  const subtypeId = formData._hypotheticalDestinationEntity?.subtype.id;

  if (!formData.destinationEntityID || !subtypeId) {
    throw new Error('Cannot update a draft entity without a draft entity ID');
  }

  if (!formData.hypotheticalEntityKind) {
    throw new Error('Cannot update a draft entity without a draft entity kind');
  }

  const entityKind = formData.hypotheticalEntityKind;

  const createInput = getCreateHypotheticalEntityInput(
    formData,
    possiblePrimaryClients
  );

  const displayName = formData.hypotheticalEntityName;

  const commonInput = {
    id: formData.destinationEntityID,
    update: {},
  };

  switch (entityKind) {
    case EntityKind.IrrevocableTrust:
      return {
        ...commonInput,
        updateIrrevocableTrust: {
          id: subtypeId,
          update: {
            displayName,
            clearGrantors: true,
          },
          withGrantors: createInput.withIrrevocableTrust?.withGrantors,
        },
      };
    case EntityKind.RevocableTrust:
      return {
        ...commonInput,
        updateRevocableTrust: {
          id: subtypeId,
          update: {
            displayName,
            clearGrantors: true,
          },
          withGrantors: createInput.withRevocableTrust?.withGrantors,
        },
      };
    case EntityKind.SlatTrust:
      return {
        ...commonInput,
        updateSlatTrust: {
          id: subtypeId,
          update: {
            displayName,
            clearGrantor: true,
          },
          withGrantor: createInput.withSlatTrust?.withGrantor,
        },
      };
    case EntityKind.GratTrust:
      return {
        ...commonInput,
        updateGratTrust: {
          id: subtypeId,
          update: {
            displayName,
            clearGrantor: true,
          },
          withGrantor: createInput.withGratTrust?.withGrantor,
        },
      };
    case EntityKind.IlitTrust:
      return {
        ...commonInput,
        updateIlitTrust: {
          id: subtypeId,
          update: {
            displayName,
            clearGrantors: true,
          },
          withGrantors: createInput.withIlitTrust?.withGrantors,
        },
      };
    case EntityKind.QprtTrust:
      return {
        ...commonInput,
        updateQprtTrust: {
          id: subtypeId,
          update: {
            displayName,
            clearGrantors: true,
          },
          withGrantors: createInput.withQprtTrust?.withGrantors,
        },
      };
    case EntityKind.IndividualPersonalAccount:
      return {
        ...commonInput,
        updateIndividualPersonalAccount: {
          id: subtypeId,
          update: {
            displayName,
            clearOwner: true,
          },
          withOwner: createInput.withIndividualPersonalAccount?.withOwner,
        },
      };
    case EntityKind.JointPersonalAccount:
      return {
        ...commonInput,
        updateJointPersonalAccount: {
          id: subtypeId,
          update: {
            displayName,
            clearOwners: true,
          },
          withOwners: createInput.withJointPersonalAccount?.withOwners,
        },
      };
    case EntityKind.RetirementPersonalAccount:
      return {
        ...commonInput,
        updateRetirementPersonalAccount: {
          id: subtypeId,
          update: {
            displayName,
            clearOwner: true,
          },
          withOwner: createInput.withRetirementPersonalAccount?.withOwner,
        },
      };
    case EntityKind.QualifiedTuitionPersonalAccount:
      return {
        ...commonInput,
        updateQualifiedTuitionPersonalAccount: {
          id: subtypeId,
          update: {
            displayName,
            clearOwner: true,
          },
          withOwner: createInput.withQualifiedTuitionPersonalAccount?.withOwner,
        },
      };
    case EntityKind.CrtTrust:
      return {
        ...commonInput,
        updateCrtTrust: {
          id: subtypeId,
          update: {
            displayName,
            clearGrantors: true,
          },
          withGrantors: createInput.withCrtTrust?.withGrantors,
        },
      };
    case EntityKind.CltTrust:
      return {
        ...commonInput,
        updateCltTrust: {
          id: subtypeId,
          update: {
            displayName,
            clearGrantors: true,
          },
          withGrantors: createInput.withCltTrust?.withGrantors,
        },
      };
    case EntityKind.DonorAdvisedFund:
      return {
        ...commonInput,
        updateDonorAdvisedFund: {
          id: subtypeId,
          update: {
            displayName,
            clearDonors: true,
          },
          withDonors: createInput.withDonorAdvisedFund?.withDonors,
        },
      };
    case EntityKind.PrivateFoundation:
      return {
        ...commonInput,
        updatePrivateFoundation: {
          id: subtypeId,
          update: {
            displayName,
            clearDonors: true,
          },
          withDonors: createInput.withPrivateFoundation?.withDonors,
        },
      };

    default:
      throw new UnreachableError({
        case: entityKind as never,
        message: `Unrecognized entity type ${entityKind}`,
      });
  }
}

const EntityProperties: EntityProperties = {
  isGrantor: false,
  isOwner: false,
  isDonor: false,
  allowOnlyOne: false,
  allowMultiple: false,
};

interface EntityProperties {
  isGrantor: boolean;
  isOwner: boolean;
  isDonor: boolean;
  allowOnlyOne: boolean;
  allowMultiple: boolean;
}
export function getEntityPropertiesFromEntityKind(
  entityKind?: EntityKind | null | ''
): EntityProperties {
  if (!entityKind) {
    return {
      isGrantor: true,
      isOwner: false,
      isDonor: false,
      allowOnlyOne: true,
      allowMultiple: false,
    };
  }

  const isGrantor = GRANTOR_ENTITY_KINDS.includes(entityKind);
  const isOwner = OWNER_ENTITY_KINDS.includes(entityKind);
  const isDonor = DONOR_ENTITY_KINDS.includes(entityKind);
  const allowOnlyOne = !ALLOW_MULTIPLE_PRINCIPALS_KINDS.includes(entityKind);
  const allowMultiple = ALLOW_MULTIPLE_PRINCIPALS_KINDS.includes(entityKind);

  return {
    isGrantor,
    isOwner,
    isDonor,
    allowOnlyOne,
    allowMultiple,
  };
}

export function getExternalTransferCopy(transferDirection: TransferDirection) {
  if (transferDirection === TransferDirection.Inbound) {
    return 'External source';
  }

  return 'External destination';
}
