// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IntegrationAssetClassesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type IntegrationAssetClassesQuery = { __typename?: 'Query', integrationAssetClasses: { __typename?: 'IntegrationAssetClassConnection', edges?: Array<{ __typename?: 'IntegrationAssetClassEdge', node?: { __typename?: 'IntegrationAssetClass', id: string, integrationName: string, provider: Types.IntegrationAssetClassProvider, assetClass?: { __typename?: 'AssetClass', id: string } | null } | null } | null> | null } };


export const IntegrationAssetClassesDocument = gql`
    query IntegrationAssetClasses {
  integrationAssetClasses {
    edges {
      node {
        id
        integrationName
        provider
        assetClass {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useIntegrationAssetClassesQuery__
 *
 * To run a query within a React component, call `useIntegrationAssetClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIntegrationAssetClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIntegrationAssetClassesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIntegrationAssetClassesQuery(baseOptions?: Apollo.QueryHookOptions<IntegrationAssetClassesQuery, IntegrationAssetClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IntegrationAssetClassesQuery, IntegrationAssetClassesQueryVariables>(IntegrationAssetClassesDocument, options);
      }
export function useIntegrationAssetClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IntegrationAssetClassesQuery, IntegrationAssetClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IntegrationAssetClassesQuery, IntegrationAssetClassesQueryVariables>(IntegrationAssetClassesDocument, options);
        }
export function useIntegrationAssetClassesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<IntegrationAssetClassesQuery, IntegrationAssetClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IntegrationAssetClassesQuery, IntegrationAssetClassesQueryVariables>(IntegrationAssetClassesDocument, options);
        }
export type IntegrationAssetClassesQueryHookResult = ReturnType<typeof useIntegrationAssetClassesQuery>;
export type IntegrationAssetClassesLazyQueryHookResult = ReturnType<typeof useIntegrationAssetClassesLazyQuery>;
export type IntegrationAssetClassesSuspenseQueryHookResult = ReturnType<typeof useIntegrationAssetClassesSuspenseQuery>;
export type IntegrationAssetClassesQueryResult = Apollo.QueryResult<IntegrationAssetClassesQuery, IntegrationAssetClassesQueryVariables>;
export const Operations = {
  Query: {
    IntegrationAssetClasses: 'IntegrationAssetClasses' as const
  }
}