import { Box, Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { useMemo } from 'react';

import { TableCell, TableCellContext } from '@/components/lists/TableCell';
import { InfoTooltip } from '@/components/poppers/Tooltip/InfoTooltip';
import { entityKindToDisplayName } from '@/modules/entities/EntityForm/utils';
import { testamentaryEntityKindToDisplayName } from '@/modules/entities/testamentaryEntities/testamentaryEntities.utils';
import { useIRSConstants } from '@/modules/irs/useIRSConstants';
import {
  DispositiveProvisionDispositionKind,
  DispositiveProvisionRecipientKind,
  StateCode,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';
import {
  formatCurrency,
  formatCurrencyNoDecimals,
} from '@/utils/formatting/currency';
import {
  formatPercent,
  formatPercentNoDecimals,
} from '@/utils/formatting/percent';

import { useUnguardedDispositiveProvisionsContext } from '../contexts/dispositiveProvisions.context';
import { getTransferTaxKindOptions } from '../DispositiveProvisionsForm/DispositiveProvisionsForm.constants';
import { useRemainingExemptionAmountForClient } from '../DispositiveProvisionsForm/hooks/useRemainingExemptionAmountForClient';
import { DispositiveProvisions_DispositiveProvisionFragment } from '../graphql/DispositiveProvisions.fragments.generated';
import { DispositiveProvisionDirection } from './DispositiveProvisionsByDeath.types';

export interface SimulatedProvision {
  transferAmount?: Decimal;
  transferAmountWasClipped?: boolean;
}

interface ProvisionDetails {
  id: string;
  heading: string;
  subheading?: string;
  tooltip?: string;
}

interface DispositiveProvisionRow {
  heading: string;
  description: string;
  additionalItems?: string[];
  badgeText?: string;
  badgeTextCallout?: string;
  provisions: ProvisionDetails[];
}

export function getDistributionDetails(
  provision: DispositiveProvisions_DispositiveProvisionFragment,
  calculatedProvisionAmount: Decimal | null,
  opts: { compact?: boolean } = {}
) {
  const currencyFormatter = opts.compact
    ? formatCurrencyNoDecimals
    : formatCurrency;

  const percentageFormatter = opts.compact
    ? formatPercentNoDecimals
    : formatPercent;

  if (calculatedProvisionAmount) {
    return [
      {
        id: provision.id,
        heading: currencyFormatter(calculatedProvisionAmount),
      },
    ];
  }

  switch (provision.dispositionKind) {
    case DispositiveProvisionDispositionKind.Percentage:
      return [
        {
          id: provision.id,
          heading: `${percentageFormatter(
            provision.dispositionPercentage ?? new Decimal(0),
            2
          )}%`,
        },
      ];
    case DispositiveProvisionDispositionKind.Amount:
      return [
        {
          id: provision.id,
          heading: currencyFormatter(
            provision.dispositionAmount ?? new Decimal(0)
          ),
        },
      ];
    case DispositiveProvisionDispositionKind.AnythingLeftOver:
      return [
        {
          id: provision.id,
          heading: 'Anything left over',
        },
      ];
    case DispositiveProvisionDispositionKind.RemainingGstExclusionOfGrantorInExcessOfLifetimeExclusion:
      return [
        {
          id: provision.id,
          heading: 'Remaining GST exemption',
        },
      ];
    case DispositiveProvisionDispositionKind.RemainingLifetimeExclusionOfGrantor:
      return [
        {
          id: provision.id,
          heading: 'Remaining lifetime exemption',
        },
      ];
    case DispositiveProvisionDispositionKind.RemainingFederalLifetimeExemptionInExcessOfStateExemption:
      return [
        {
          id: provision.id,
          heading:
            'Remaining federal lifetime exemption (in excess of state exemption)',
        },
      ];
    case DispositiveProvisionDispositionKind.RemainingStateExemption:
      return [
        {
          id: provision.id,
          heading: 'Remaining state exemption',
        },
      ];

    default:
      throw new UnreachableError(provision.dispositionKind);
  }
}

function createDispositiveProvisionRow({
  provision,
  nameOfDeadClient,
  nameOfSurvivingClient,
  hasStateTax,
  calculatedProvisionAmount,
  direction,
}: {
  provision: DispositiveProvisions_DispositiveProvisionFragment;
  nameOfDeadClient: string;
  nameOfSurvivingClient: string;
  hasStateTax: boolean;
  calculatedProvisionAmount: Decimal | null;
  direction: DispositiveProvisionDirection;
}): DispositiveProvisionRow {
  const transferTaxKind = getTransferTaxKindOptions({ hasStateTax }).find(
    (option) => option.value === provision.transferTaxKind
  )?.display;

  const getNotesTooltip = (
    provision: DispositiveProvisions_DispositiveProvisionFragment
  ) => {
    if (provision.notes) {
      return (
        <InfoTooltip
          title={<Box sx={{ whiteSpace: 'pre-wrap' }}>{provision.notes}</Box>}
          placement="top-start"
        />
      );
    }
    return null;
  };

  const sharedProperties = {
    provisions: getDistributionDetails(provision, calculatedProvisionAmount),
    additionalItems: compact([transferTaxKind]),
    Tooltip: getNotesTooltip(provision),
  };

  if (
    provision.recipientKind ===
      DispositiveProvisionRecipientKind.SurvivingSpouse &&
    direction === DispositiveProvisionDirection.Distributing
  ) {
    return {
      heading: nameOfSurvivingClient,
      description: 'Individual',
      ...sharedProperties,
    };
  } else if (provision.individual) {
    return {
      heading: provision.individual.displayName,
      description: 'Individual',
      ...sharedProperties,
    };
  } else if (provision.entity) {
    return {
      heading: provision.entity.subtype.displayName,
      description: entityKindToDisplayName(provision.entity.kind),
      ...sharedProperties,
    };
  } else if (provision.testamentaryEntity) {
    return {
      heading: provision.testamentaryEntity.displayName,
      description: testamentaryEntityKindToDisplayName(
        provision.testamentaryEntity.kind,
        nameOfDeadClient
      ),
      ...sharedProperties,
    };
  } else if (provision.organization) {
    return {
      heading: provision.organization.name,
      description: 'Organization',
      ...sharedProperties,
    };
  }

  diagnostics.warn(
    `unknown dispositve provision type for provision ${provision.id}`,
    provision
  );

  return {
    heading: '',
    description: '',
    ...sharedProperties,
  };
}

interface DispositiveProvisionRowPrimaryClient {
  displayName: string;
  id: string;
}

export interface DispositiveProvisionRowProps {
  provision: DispositiveProvisions_DispositiveProvisionFragment;
  dyingPrimaryClientId: string | null;
  primaryClients: DispositiveProvisionRowPrimaryClient[] | null;
  calculatedProvisionAmount: Decimal | null | undefined;
  direction: DispositiveProvisionDirection;
}

export function DispositiveProvisionRow({
  provision,
  dyingPrimaryClientId,
  primaryClients,
  calculatedProvisionAmount,
  direction,
}: DispositiveProvisionRowProps) {
  const { availableStateEstateTaxes } = useIRSConstants();

  const { data: clientExemptionData } = useRemainingExemptionAmountForClient({
    // clientProfileId of who died
    clientId: dyingPrimaryClientId,
  });
  const showCalculatedDispositions =
    useUnguardedDispositiveProvisionsContext()?.showCalculatedDispositions;

  const nameOfDeadClient: string = useMemo(() => {
    return (
      primaryClients?.find((g) => g.id === dyingPrimaryClientId)?.displayName ??
      ''
    );
  }, [dyingPrimaryClientId, primaryClients]);
  const nameOfSurvivingClient: string = useMemo(() => {
    return (
      primaryClients?.find((g) => g.id !== dyingPrimaryClientId)?.displayName ??
      ''
    );
  }, [dyingPrimaryClientId, primaryClients]);

  const hasStateTax = useMemo(() => {
    if (!clientExemptionData) return false;

    return Boolean(
      availableStateEstateTaxes?.includes(
        clientExemptionData.stateCode as StateCode
      )
    );
  }, [availableStateEstateTaxes, clientExemptionData]);

  const { provisions, ...richListItemProps } = createDispositiveProvisionRow({
    provision,
    nameOfDeadClient,
    nameOfSurvivingClient,
    hasStateTax,
    calculatedProvisionAmount:
      (showCalculatedDispositions && calculatedProvisionAmount) || null,
    direction,
  });

  return (
    <TableCell
      RightContent={
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack spacing={1}>
            {provisions.map((provision) => (
              <Box key={provision.id} mb={1}>
                <TableCellContext {...provision} />
              </Box>
            ))}
          </Stack>
        </Stack>
      }
      {...richListItemProps}
    />
  );
}
