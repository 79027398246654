import { first } from 'lodash';
import { PropsWithChildren, useEffect } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  getGraphQLErrorsByStatusCode,
  GraphQLStatusCodes,
} from '@/graphql/errors';
import { deletedEntityIdsVar } from '@/graphql/reactiveVars';
import { getNodes } from '@/utils/graphqlUtils';

import { getEntityTypeFromSubtype } from '../../utils/getEntityTypeFromSubtype';
import { useEntityDetailsContext } from './entityDetails.context';
import { useGetEntityDetailsQuery } from './graphql/GetEntityDetails.generated';

export const EntityDetailsAwareRoute = ({
  entityId,
  children,
}: PropsWithChildren<{ entityId: string | null }>) => {
  const { showFeedback } = useFeedback();
  const {
    setEntityId,
    setDisplayName,
    setEntitySubtypeId,
    setEntityType,
    setEntityNotFound,
  } = useEntityDetailsContext();

  const { data, error } = useGetEntityDetailsQuery({
    variables: { entityId: entityId! },
    skip: !entityId,
  });

  const entity = (() => {
    if (!data) return null;
    const entities = getNodes(data.entities);
    if (entities.length !== 1) return null;
    return first(entities);
  })();

  useEffect(() => {
    // if we've already run through this effect once and marked the household as deleted,
    // read the variable directly. this resolves a bug where if we're just reliant on the error
    // being present, if a user hits the back button to get to a household-aware page for a deleted household,
    // we wouldn't detect that the household was deleted and would incorrectly attempt to render the page normally
    const isEntityIdMarkedAsDeleted = deletedEntityIdsVar().includes(
      entityId ?? ''
    );
    if (isEntityIdMarkedAsDeleted) {
      return setEntityNotFound(true);
    }

    // if we're seeing an error when loading the client, check to see if it's a not found error
    // and mark the household as deleted if so
    if (error) {
      const entityNotFound = Boolean(
        getGraphQLErrorsByStatusCode(error, GraphQLStatusCodes.NOT_FOUND)
      );

      setEntityNotFound(entityNotFound);
      if (entityNotFound && entityId) {
        const currentDeletedEntityIds = deletedEntityIdsVar();
        void deletedEntityIdsVar([...currentDeletedEntityIds, entityId]);
      } else {
        showFeedback(
          'Failed to load details for this client. Please refresh the page and try again.'
        );
      }
    }
  }, [error, showFeedback, entityId, setEntityNotFound]);

  useEffect(() => {
    if (!data) return;
    setEntityNotFound(!entity);
    setEntityId(entity?.id ?? null);
    setDisplayName(entity?.subtype.displayName ?? null);
    setEntitySubtypeId(entity?.subtype.id ?? null);
    setEntityType(
      entity?.subtype.__typename
        ? getEntityTypeFromSubtype(entity.subtype.__typename)
        : null
    );

    return () => {
      setEntityId(null);
      setDisplayName(null);
      setEntitySubtypeId(null);
      setEntityType(null);
      setEntityNotFound(false);
    };
  }, [
    entity?.subtype.displayName,
    entity?.id,
    setDisplayName,
    setEntityId,
    entity?.subtype.id,
    setEntitySubtypeId,
    entity?.subtype.__typename,
    setEntityType,
    setEntityNotFound,
    data,
    entity,
  ]);

  return <>{children}</>;
};
