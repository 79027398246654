import Decimal from 'decimal.js';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { TrustTaxStatus } from '@/types/schema';
import { PathsOf } from '@/types/subform';

export const NAMESPACE = 'CHARITABLE_TRUST_DESIGNER_BASIC_INFORMATION' as const;

export interface CharitableTrustDesignerBasicInformationDonor {
  id: string;
}

export interface CharitableTrustDesignerBasicInformationBeneficiary {
  id: string;
  beneficiaryNodeId?: string | null;
  percentageOwnership: Decimal | null;
  kind: 'individual' | 'organization' | 'entity' | null;
}
export interface CharitableTrustDesignerBasicInformationForm {
  [NAMESPACE]: {
    id: string;
    proposalId?: string;
    name: string;
    donors: CharitableTrustDesignerBasicInformationDonor[];
    taxStatus: TrustTaxStatus | '';
    charitableBeneficiaries: CharitableTrustDesignerBasicInformationBeneficiary[];
    nonCharitableBeneficiaries: CharitableTrustDesignerBasicInformationBeneficiary[];
    _validationFields: {
      charitableBeneficiaries: Decimal | null;
      nonCharitableBeneficiaries: Decimal | null;
    };
  };
}

export type CharitableTrustDesignerBasicInformationFormPaths =
  PathsOf<CharitableTrustDesignerBasicInformationForm>;

export type CharitableTrustDesignerBasicInformationBeneficiaryOption =
  | (SelectInputOption<string> & {
      kind: CharitableTrustDesignerBasicInformationBeneficiary['kind'];
    })
  | { type: 'component'; component: JSX.Element };
