// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetIlitCrummeyLetterTaskDetails_IlitCrummeyLetterFragment = { __typename?: 'ILITCrummeyLetter', id: string, crummeyWithdrawalPeriodDays: number, sentOn?: Time | null, associatedTask?: { __typename?: 'EntityTask', id: string } | null, documents?: Array<{ __typename?: 'Document', id: string }> | null, recipient?: { __typename?: 'ClientProfile', id: string, displayName: string } | null };

export type GetIlitCrummeyLetterTaskDetailsQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input'];
}>;


export type GetIlitCrummeyLetterTaskDetailsQuery = { __typename?: 'Query', entityTasks: { __typename?: 'EntityTaskConnection', edges?: Array<{ __typename?: 'EntityTaskEdge', node?: { __typename?: 'EntityTask', id: string, dueAt: Time, title: string, assignedTo?: { __typename?: 'User', displayName: string, id: string } | null, ilitCrummeyLetter?: { __typename?: 'ILITCrummeyLetter', id: string, crummeyWithdrawalPeriodDays: number, sentOn?: Time | null, associatedTask?: { __typename?: 'EntityTask', id: string } | null, documents?: Array<{ __typename?: 'Document', id: string }> | null, recipient?: { __typename?: 'ClientProfile', id: string, displayName: string } | null } | null, entity: { __typename?: 'Entity', id: string, ilitTrust?: { __typename?: 'ILITTrust', id: string } | null }, taskDependencies?: Array<{ __typename?: 'EntityTask', id: string, type: Types.EntityTaskType, loggedTransfers?: Array<{ __typename?: 'LoggedTransfer', id: string, amount: CurrencyUSD, lifetimeExclusionEvents?: Array<{ __typename?: 'LifetimeExclusionEvent', id: string, eventDate: Time, giftAmount?: CurrencyUSD | null, benefitOf?: { __typename?: 'ClientProfile', id: string } | null, grantor: { __typename?: 'ClientProfile', id: string, displayName: string } }> | null }> | null }> | null } | null } | null> | null } };

export const GetIlitCrummeyLetterTaskDetails_IlitCrummeyLetterFragmentDoc = gql`
    fragment GetILITCrummeyLetterTaskDetails_ILITCrummeyLetter on ILITCrummeyLetter {
  id
  associatedTask {
    id
  }
  crummeyWithdrawalPeriodDays
  documents {
    id
  }
  sentOn
  recipient {
    id
    displayName
  }
}
    `;
export const GetIlitCrummeyLetterTaskDetailsDocument = gql`
    query GetILITCrummeyLetterTaskDetails($taskId: ID!) {
  entityTasks(where: {id: $taskId}) {
    edges {
      node {
        id
        assignedTo {
          displayName
          id
        }
        dueAt
        title
        ilitCrummeyLetter {
          id
          ...GetILITCrummeyLetterTaskDetails_ILITCrummeyLetter
        }
        entity {
          id
          ilitTrust {
            id
          }
        }
        taskDependencies {
          id
          type
          loggedTransfers {
            id
            amount
            lifetimeExclusionEvents {
              id
              benefitOf {
                id
              }
              eventDate
              giftAmount
              grantor {
                id
                displayName
              }
            }
          }
        }
      }
    }
  }
}
    ${GetIlitCrummeyLetterTaskDetails_IlitCrummeyLetterFragmentDoc}`;

/**
 * __useGetIlitCrummeyLetterTaskDetailsQuery__
 *
 * To run a query within a React component, call `useGetIlitCrummeyLetterTaskDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIlitCrummeyLetterTaskDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIlitCrummeyLetterTaskDetailsQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetIlitCrummeyLetterTaskDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetIlitCrummeyLetterTaskDetailsQuery, GetIlitCrummeyLetterTaskDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIlitCrummeyLetterTaskDetailsQuery, GetIlitCrummeyLetterTaskDetailsQueryVariables>(GetIlitCrummeyLetterTaskDetailsDocument, options);
      }
export function useGetIlitCrummeyLetterTaskDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIlitCrummeyLetterTaskDetailsQuery, GetIlitCrummeyLetterTaskDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIlitCrummeyLetterTaskDetailsQuery, GetIlitCrummeyLetterTaskDetailsQueryVariables>(GetIlitCrummeyLetterTaskDetailsDocument, options);
        }
export function useGetIlitCrummeyLetterTaskDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetIlitCrummeyLetterTaskDetailsQuery, GetIlitCrummeyLetterTaskDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetIlitCrummeyLetterTaskDetailsQuery, GetIlitCrummeyLetterTaskDetailsQueryVariables>(GetIlitCrummeyLetterTaskDetailsDocument, options);
        }
export type GetIlitCrummeyLetterTaskDetailsQueryHookResult = ReturnType<typeof useGetIlitCrummeyLetterTaskDetailsQuery>;
export type GetIlitCrummeyLetterTaskDetailsLazyQueryHookResult = ReturnType<typeof useGetIlitCrummeyLetterTaskDetailsLazyQuery>;
export type GetIlitCrummeyLetterTaskDetailsSuspenseQueryHookResult = ReturnType<typeof useGetIlitCrummeyLetterTaskDetailsSuspenseQuery>;
export type GetIlitCrummeyLetterTaskDetailsQueryResult = Apollo.QueryResult<GetIlitCrummeyLetterTaskDetailsQuery, GetIlitCrummeyLetterTaskDetailsQueryVariables>;
export const Operations = {
  Query: {
    GetILITCrummeyLetterTaskDetails: 'GetILITCrummeyLetterTaskDetails' as const
  },
  Fragment: {
    GetILITCrummeyLetterTaskDetails_ILITCrummeyLetter: 'GetILITCrummeyLetterTaskDetails_ILITCrummeyLetter' as const
  }
}