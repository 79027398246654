import {
  Control,
  Controller,
  FieldValues,
  RegisterOptions,
} from 'react-hook-form';

import {
  getFieldErrorValue,
  getValidations,
} from '@/components/utils/inputUtils';
import { FieldNameFromFormShape } from '@/types/react-hook-form';

import {
  BackendTypeaheadSelectInput,
  BackendTypeaheadSelectProps,
  ValidTypeaheadValue,
} from '../baseInputs/BackendTypeaheadSelectInput/BackendTypeaheadSelectInput';
import { useFormFieldsDisabled } from '../context/formFieldsDisabled.context';

type RelevantExtendedProps<V extends ValidTypeaheadValue> = Omit<
  BackendTypeaheadSelectProps<V>,
  'value' | 'onChange'
>;

export interface FormAwareBackendTypeaheadSelectInputProps<
  FormShape extends FieldValues,
  V extends ValidTypeaheadValue,
> extends RelevantExtendedProps<V> {
  fieldName: FieldNameFromFormShape<FormShape>;
  label: string;
  control: Control<FormShape>;
  validation?: RegisterOptions<FormShape>['validate'];
}

export function FormAwareBackendTypeaheadSelectInput<
  FormShape extends FieldValues,
  V extends ValidTypeaheadValue,
>({
  fieldName,
  control,
  validation,
  disabled,
  ...props
}: FormAwareBackendTypeaheadSelectInputProps<FormShape, V>) {
  const { label, required } = props;
  const { disabled: disabledFromContext } = useFormFieldsDisabled();
  const validations = getValidations(label, !!required, validation);
  return (
    <Controller
      name={fieldName}
      control={control}
      rules={{ validate: validations }}
      render={({ field, fieldState, formState }) => {
        return (
          <BackendTypeaheadSelectInput<V>
            errorMessage={getFieldErrorValue(fieldState, formState.isSubmitted)}
            onBlur={field.onBlur}
            value={field.value}
            name={field.name}
            inputRef={field.ref}
            disabled={disabledFromContext ?? disabled}
            {...props}
            onChange={(_e, value) => {
              // the underlying event doesn't have the appropriate value,
              // so we explicitly pass it through here.
              field.onChange({
                target: {
                  value,
                },
              });
            }}
          />
        );
      }}
    />
  );
}
