import { RadioGroupOption } from '@/components/form/baseInputs/inputTypes';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { DisplayTableColumn } from '@/components/tables/DisplayTable/DisplayTable';
import { GrowthProfileGrowthType } from '@/types/schema';

import { GrowthProfileCustomGrowthOverrideRowData } from './GrowthProfileModal.types';

export const growthProfileTypeOptions: RadioGroupOption<GrowthProfileGrowthType>[] =
  [
    {
      label: 'Default growth',
      value: GrowthProfileGrowthType.DefaultGrowth,
      helpText:
        'Assumes asset category growth rates set at the firm level is applied to the estate waterfall',
    },
    {
      label: 'Customize growth',
      value: GrowthProfileGrowthType.CustomGrowth,
      helpText:
        'Enables override of specific asset category and/or entity-level growth rates from the defaults set at the firm level',
    },
    {
      label: 'Global growth',
      value: GrowthProfileGrowthType.GlobalGrowth,
      helpText:
        'Assumes asset category growth rates set at the firm level is applied to the estate waterfall',
    },
  ];

export const DEFAULT_GROWTH_TABLE_COLUMNS: DisplayTableColumn[] = [
  {
    headerName: 'Asset category',
    flex: 1,
  },
  {
    headerName: 'Default growth rate',
    align: 'right',
    width: 200,
  },
];

export const CUSTOM_GROWTH_TABLE_COLUMNS: Column<
  GrowthProfileCustomGrowthOverrideRowData & {
    action?: string;
  }
>[] = [
  {
    field: 'displayName',
    headerName: 'Name',
    flex: 1,
    renderCell: TwoLineTextRenderer({
      lineOne: ({ row }) => row.displayName,
      lineTwo: ({ row }) => row.displayType,
    }),
  },
  {
    field: 'futureValuationValue',
    headerName: 'Future valuation',
    flex: 1,
    align: 'right',
    renderCell: TwoLineTextRenderer({
      lineOne: ({ row }) => row.futureValuationValue,
      lineTwo: ({ row }) => row.futureValuationYear,
    }),
  },
  {
    field: 'growthRateValue',
    headerName: 'Custom growth rate',
    align: 'right',
    flex: 1,
    renderCell: TwoLineTextRenderer({
      lineOne: ({ row }) => row.growthRateValue,
      lineTwo: ({ row }) => row.growthRateSubtitle,
    }),
  },
  {
    field: 'action',
    width: 64,
    maxWidth: 64,
    minWidth: 64,
    sortable: false,
    renderCell: IconRenderer({
      icon: Edit02Icon,
    }),
  },
];
