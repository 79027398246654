import { Loader } from '@/components/progress/Loader/Loader';

export const SummaryPanelLoader = () => {
  return (
    <Loader
      boxProps={{
        display: 'flex',
        margin: 'auto',
      }}
      circularProgressProps={{ size: 40 }}
    />
  );
};
