import { Stack } from '@mui/material';
import { useWatch } from 'react-hook-form';

import { FormAwareCheckbox } from '@/components/form/formAwareInputs/FormAwareCheckbox';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';
import { ContextualHelpTrigger } from '@/modules/content/components/ContextualHelpTrigger';
import { EstateWaterfallHypotheticalTransferTransferTaxKind } from '@/types/schema';

import { useHypotheticalTransferType } from '../hooks/useHypotheticalTransferType';
import { useHypotheticalTransferTypeHelpText } from '../hooks/useHypotheticalTransferTypeHelpText';
import { HypotheticalTransferFormModalShape } from '../HypotheticalTransferModal.types';
import { deserializeTransferType } from '../HypotheticalTransferModal.utils';

export function TransferType() {
  const { control } = useFormContext<HypotheticalTransferFormModalShape>();
  const transferTypes = useHypotheticalTransferType();
  const transferType = useWatch({ control, name: 'transferType' });

  const { transferTaxKind } = deserializeTransferType(transferType);
  const isTaxableGift =
    transferTaxKind ===
    EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift;

  const { helpText } = useHypotheticalTransferTypeHelpText();

  return (
    <FormLayoutRow>
      <FormLayoutItem width={isTaxableGift ? 8 : 12}>
        <FormAwareSelectInput<HypotheticalTransferFormModalShape>
          control={control}
          fieldName="transferType"
          label="Type of transfer (gift tax purposes)"
          required
          options={transferTypes}
          helpText={helpText}
          contextualHelp={
            <PopperContent
              body="Learn more about transfer types and associated estate tax treatments"
              actions={
                <ContextualHelpTrigger
                  contextualHelpId="transferTypes"
                  display="Learn more"
                />
              }
              actionsSx={{ marginBottom: 0, marginRight: 0 }}
            />
          }
        />
      </FormLayoutItem>
      {isTaxableGift && (
        <FormLayoutItem width={4}>
          <Stack justifyContent="center" height="100%" pt={3}>
            <FormAwareCheckbox<HypotheticalTransferFormModalShape>
              control={control}
              fieldName="transferGstGift"
              label="GST Gift"
            />
          </Stack>
        </FormLayoutItem>
      )}
    </FormLayoutRow>
  );
}
