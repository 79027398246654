import { Box } from '@mui/material';
import { DataGridProProps, GridFilterModel } from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';

import { DataTable } from '../DataTable/DataTable';
import { TableEmptyState } from '../DataTable/TableEmptyState';
import { Column } from '../DataTable/types';
import { FilterableTable, FilterableTableProps } from './FilterableTable';

interface Props extends Omit<FilterableTableProps, 'filter' | 'children'> {
  rows: DataGridProProps['rows'];
  columns: Column<DataGridProProps['rows'][0]>[];
  filterField: string;
  emptyStateText: string;
  emptyStateContent?: React.ReactNode;
  loading: boolean;
  onRowClick?: DataGridProProps['onRowClick'];
  onSelectedTabChange?: (selectedTab: string) => void;
  pageSize?: number;
}

export const ALL_TAB_VALUE = 'all';

export function ControlledFilterableTable({
  rows,
  columns,
  filterField,
  title,
  tabs,
  emptyStateText,
  emptyStateContent,
  loading,
  onRowClick,
  actions,
  belowHeaderContent,
  belowTabContentRenderer,
  onSelectedTabChange,
  pageSize,
}: Props) {
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [
      {
        id: 1,
        field: filterField,
        operator: 'equals',
        value: tabs[0]?.value ?? '-',
      },
    ],
  });

  const [hideColumns, setHideColumns] = useState<string[]>(
    tabs[0]?.hideColumns ?? []
  );

  const filterCallback = useCallback(
    function filterByTab({ tab }: { tab: string }) {
      const currentTab = tabs.find((t) => t.value === tab);

      if (currentTab?.hideColumns) {
        setHideColumns(currentTab.hideColumns);
      } else {
        setHideColumns([]);
      }

      onSelectedTabChange?.(tab);

      if (tab === ALL_TAB_VALUE) {
        setFilterModel({
          items: [],
        });
        return;
      }

      setFilterModel({
        // Filter items by the selected tab
        // can add other filters here if needed
        // https://mui.com/x/react-data-grid/filtering/#pass-filters-to-the-data-grid
        items: [
          {
            id: 1,
            field: filterField,
            operator: 'equals',
            value: tab,
          },
        ],
      });
    },
    [filterField, onSelectedTabChange, tabs]
  );

  const hideColumnsMapping = useMemo(() => {
    return (
      hideColumns?.reduce((acc, field) => {
        return {
          ...acc,
          [field]: false,
        };
      }, {}) ?? {}
    );
  }, [hideColumns]);

  return (
    <FilterableTable
      title={title}
      filter={{
        filterCallback,
      }}
      tabs={tabs}
      actions={actions}
      belowHeaderContent={belowHeaderContent}
      belowTabContentRenderer={belowTabContentRenderer}
    >
      <DataTable
        columns={columns}
        onRowClick={onRowClick}
        filterModel={filterModel}
        loading={loading}
        pageSize={pageSize}
        initialState={{
          columns: {
            // Hide the filter field column i.e., the selected tab
            // MUI DataGrid needs the filterable field to exist in the table
            // but we don't want to show it necessarily
            columnVisibilityModel: {
              [filterField]: false,
            },
          },
        }}
        columnVisibilityModel={{
          [filterField]: false,
          ...hideColumnsMapping,
        }}
        rows={rows}
        slots={{
          noRowsOverlay: () => (
            <TableEmptyState text={emptyStateText}>
              <Box>{emptyStateContent && emptyStateContent}</Box>
            </TableEmptyState>
          ),
        }}
      />
    </FilterableTable>
  );
}
