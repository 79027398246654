import { useMemo } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';

import { RouteKey } from '@/navigation/constants';
import { computedRoutes } from '@/navigation/routes';

/**
 * @description Computes if a list of route keys matches the current location
 */
export function useMatchingRoutes(routeKeys: RouteKey[]) {
  const location = useLocation();
  return useMemo(() => {
    const allRoutes = routeKeys.map((key) => ({ path: computedRoutes[key] }));
    const matches = matchRoutes(allRoutes, location);
    return {
      isMatchingRoute: Array.isArray(matches) && matches.length > 0,
      matches,
    };
  }, [location, routeKeys]);
}
