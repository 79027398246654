import { useApolloClient } from '@apollo/client';
import { Box, Card, Stack, Typography } from '@mui/material';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { DeepPartial, FormProvider, SubmitErrorHandler } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { FormConfigurationProvider } from '@/components/form/context/FormConfigurationContext';
import { EyeIcon } from '@/components/icons/EyeIcon';
import { Settings04Icon } from '@/components/icons/Settings04Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { ChartLegend } from '@/modules/proposal/ChartLegend';
import { ProposalDetailsAwareRoute } from '@/modules/proposal/contexts/ProposalDetailsAwareRoute';
import { NEW_PROPOSAL_ID } from '@/modules/proposal/proposal.constants';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import {
  EntityProposalAssetValuationProjectionType,
  EntityStage,
} from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import {
  DEFAULT_TAX_DRAG,
  defaultValues,
  entitiesAndAssumptionsFromDisplayProposal,
  FORM_NAME,
  FormShape,
} from '../form/constants';
import {
  makeCreateInput,
  makeUpdateInput,
} from '../form/ProposalFormTranslators';
import { useCreateProposalMutation } from '../graphql/CreateProposal.generated';
import { useProposalBuilderPageQueryQuery } from '../graphql/ProposalBuilderPageQuery.generated';
import { useUpdateProposalMutation } from '../graphql/UpdateProposal.generated';
import { useClientEntities } from '../hooks/useClientEntities';
import { useProposalScenarios } from '../hooks/useProposalScenarios';
import { EditAssumptionsForm } from '../modals/EditAssumptionsModal/constants';
import { EditAssumptionsModal } from '../modals/EditAssumptionsModal/EditAssumptionsModal';
import { Scenarios } from '../Scenarios/Scenarios';
import { FinishProposalModal } from './FinishProposalModal';
import { LeftPaneContent } from './LeftPaneContent';
import { ProposalLayout } from './ProposalLayout';

const createOrUpdateErrorMsg =
  'Error saving this proposal. Please refresh the page and try again.';

// These stages are not ready for the proposal builder
const EXCLUDE_ENTITY_STAGES = [
  EntityStage.AiCreating,
  EntityStage.AiNeedsReview,
  EntityStage.Draft,
  EntityStage.Archived,
];

export function ProposalBuilderPage() {
  const { proposalId, householdId } = useParams<{
    initialEntityId: string;
    proposalId: string;
    householdId: string;
  }>();
  const [searchParams] = useSearchParams();
  const { showFeedback } = useFeedback();
  const initialEntityIdFromParams = searchParams.get('initialEntityId');
  const [isFinishProposalModalOpen, setIsFinishProposalModalOpen] =
    useState(false);

  if (!proposalId) {
    throw new Error(`No proposalId provided.`);
  }

  if (!householdId) {
    throw new Error(`No householdId provided.`);
  }
  const client = useApolloClient();
  const navigate = useNavigate();

  const { data, loading: queryLoading } = useProposalBuilderPageQueryQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      entityWhereInput: {
        stageNotIn: EXCLUDE_ENTITY_STAGES,
        hasHouseholdWith: [
          {
            id: householdId,
          },
        ],
      },
      proposalWhereInput: {
        id: proposalId,
      },
    },
    onError: (error) => {
      showFeedback(error.message);
    },
  });

  const { displayEntities } = useClientEntities(data);
  const { proposal, entityIdsInProposal: entityIdsInProposalFromQuery } =
    useProposalScenarios(data?.proposals?.edges);

  const initialEntityId =
    initialEntityIdFromParams ?? entityIdsInProposalFromQuery[0] ?? '';

  const [createProposal, { loading: createLoading }] =
    useCreateProposalMutation({
      onError: () => {
        showFeedback(createOrUpdateErrorMsg);
      },
    });
  const [updateProposal, { loading: updateLoading }] =
    useUpdateProposalMutation({
      onError: () => {
        showFeedback(createOrUpdateErrorMsg);
      },
    });
  const loading = createLoading || updateLoading || queryLoading;

  // form
  const formMethods = useForm<FormShape>({
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty, isSubmitting },
    setShouldBlockNavigation,
  } = formMethods;

  // implicitly watch the form for changes
  // and debounce the watch function to prevent
  // excessive re-renders
  // see https://link.excalidraw.com/l/4OiHqQnYGyt/1kJXRnx3DX4
  // to see why we watch at this level
  const [_update, setUpdate] = useState(0);
  useEffect(() => {
    const watchFn = debounce(
      (_value: DeepPartial<FormShape>) => {
        setUpdate((update) => update + 1);
      },
      500,
      { leading: true, trailing: true }
    );

    const subscription = watch(watchFn);
    return () => subscription.unsubscribe();
  }, [watch]);

  const originalForm = useRef<FormShape>(defaultValues);

  // state
  const [entityIdsInProposal, setEntityIdsInProposal] = useState([
    initialEntityId,
  ]);
  const [selectedEntityId, setSelectedEntityId] =
    useState<string>(initialEntityId);
  const [assumptions, setAssumptions] = useState<EditAssumptionsForm>({});
  const [isEditAssumptionsModalOpen, setIsEditAssumptionsModalOpen] =
    useState<boolean>(false);

  // effects

  useEffect(() => {
    if (
      entityIdsInProposalFromQuery.length &&
      selectedEntityId === '' &&
      entityIdsInProposalFromQuery[0]
    ) {
      setEntityIdsInProposal(entityIdsInProposalFromQuery);
      setSelectedEntityId(entityIdsInProposalFromQuery[0]);
    }
  }, [entityIdsInProposalFromQuery, selectedEntityId]);

  useEffect(() => {
    if (proposal) {
      const { entities, assumptions } =
        entitiesAndAssumptionsFromDisplayProposal(proposal);

      originalForm.current = {
        [FORM_NAME]: {
          name: proposal.displayName,
          description: proposal.clientNotes,
          includeCumulativeView: proposal.includeCumulativeView,
          syncPostTermReturns: false,
          entities,
        },
      };

      setAssumptions(assumptions);

      reset(originalForm.current);
    }
  }, [proposal, reset, setAssumptions]);

  useEffect(() => {
    if (!proposal) {
      const assumptions: EditAssumptionsForm = {};

      displayEntities?.forEach((entity) => {
        if (entity?.id) {
          assumptions[entity?.id] = {
            termEndAssetValuationType: entity.projectedMarketValueAtTerm.equals(
              0
            )
              ? EntityProposalAssetValuationProjectionType.ProjectedRateOfReturn
              : EntityProposalAssetValuationProjectionType.ProjectedValue,
            taxDragPercent: DEFAULT_TAX_DRAG,
          };
        }
      });

      setAssumptions(assumptions);
    }
  }, [displayEntities, proposal]);

  useEffect(() => {
    if (
      !entityIdsInProposal.includes(selectedEntityId) &&
      entityIdsInProposal[0]
    ) {
      setSelectedEntityId(entityIdsInProposal[0]);
    }
  }, [entityIdsInProposal, selectedEntityId]);

  // submit
  const onValidSubmit = async (
    values: FormShape,
    type: 'finish' | 'exit' | 'preview'
  ) => {
    const showSuccessFeedback = () => {
      showFeedback('Proposal saved', { variant: 'success' });
    };

    if (proposalId === NEW_PROPOSAL_ID) {
      const input = makeCreateInput({
        values,
        householdId,
        assumptions,
        entityIdsInProposal,
      });
      const { data, errors } = await createProposal({
        variables: {
          input,
        },
      });

      if (!data || errors) {
        throw new Error(`Failed to submit form: ${FORM_NAME}`);
      }

      await client.refetchQueries({
        include: 'active',
      });
      const proposalId = data.createProposalV2.id;

      if (type === 'finish') {
        // first, navigate from the new route to the edit route so that if the user navigates and away and clicks
        // "back", they get back to the proposal they just created rather than to the prompt to create another
        // new proposal
        const route = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_PROPOSAL_EDIT,
          {
            householdId,
            proposalId,
          }
        );
        flushSync(() => {
          setShouldBlockNavigation(false);
        });

        navigate(route, { replace: true });
        showSuccessFeedback();
        setIsFinishProposalModalOpen(true);
      } else if (type === 'preview') {
        const route = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_PROPOSAL_PRESENTATION,
          {
            householdId,
            proposalId,
          },
          {
            preview: true,
            householdId,
          }
        );

        flushSync(() => {
          setShouldBlockNavigation(false);
        });

        navigate(route);
      } else if (type === 'exit') {
        const key = ROUTE_KEYS.HOUSEHOLD_SIDEBAR_PRESENTATIONS;

        const route = getCompletePathFromRouteKey(key, {
          householdId,
        });
        flushSync(() => {
          setShouldBlockNavigation(false);
        });
        navigate(route);
      }
    } else {
      const entityProposalIds: string[] = [];
      Object.values(values[FORM_NAME].entities).forEach((scenario) => {
        scenario.scenarios.forEach((scenario) => {
          entityProposalIds.push(scenario.entityProposalId ?? '');
        });
      });

      const input = makeUpdateInput({
        values,
        householdId,
        assumptions,
        proposalId,
        entityProposalIds,
      });
      const { data, errors } = await updateProposal({
        variables: {
          input,
        },
      });
      if (!data || errors) {
        throw new Error(`Failed to submit form: ${FORM_NAME}`);
      }
      await client.refetchQueries({
        include: 'active',
      });

      if (type === 'finish') {
        showSuccessFeedback();
        setIsFinishProposalModalOpen(true);
      } else if (type === 'preview') {
        const route = getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_PROPOSAL_PRESENTATION,
          {
            householdId,
            proposalId,
          },
          {
            preview: true,
            householdId,
          }
        );

        flushSync(() => {
          setShouldBlockNavigation(false);
        });

        navigate(route);
      } else if (type === 'exit') {
        const key = ROUTE_KEYS.HOUSEHOLD_SIDEBAR_PRESENTATIONS;

        const route = getCompletePathFromRouteKey(key, {
          householdId,
        });
        flushSync(() => {
          setShouldBlockNavigation(false);
        });
        navigate(route);
      }
    }
  };

  const onInvalidSubmit: SubmitErrorHandler<FormShape> = (errors) => {
    let isListError = false;

    entityIdsInProposal.forEach((entityId) => {
      if (
        errors[FORM_NAME]?.entities &&
        errors[FORM_NAME]?.entities[entityId]?.scenarios?.root?.type ===
          'maxLength'
      ) {
        isListError = true;
      }
    });

    if (isListError) {
      showFeedback(
        'Error saving this proposal. Please make sure there are three or fewer scenarios.'
      );
    }

    diagnostics.debug(errors);
  };

  const onSubmit = (type: 'exit' | 'finish' | 'preview') =>
    handleSubmit((data) => onValidSubmit(data, type), onInvalidSubmit);

  const selectedEntity = displayEntities?.find(
    (entity) => entity?.id === selectedEntityId
  );

  useEffect(() => {
    if (
      displayEntities.length &&
      !selectedEntity &&
      !loading &&
      selectedEntityId
    ) {
      // this shows if the user has an entity selected that is no longer in the proposal
      showFeedback('Could not find entity for this proposal.');
    }
  }, [
    selectedEntity,
    showFeedback,
    displayEntities.length,
    loading,
    selectedEntityId,
  ]);

  if (!displayEntities) {
    return null;
  }

  const entitiesInProposal = displayEntities.filter((entity) => {
    return entityIdsInProposal.includes(entity?.id ?? '');
  });

  if (!selectedEntity) {
    return null;
  }

  return (
    <ProposalDetailsAwareRoute proposalId={proposalId}>
      <FormProvider {...formMethods}>
        <FinishProposalModal
          householdId={householdId}
          proposalId={proposalId}
          onClose={() => setIsFinishProposalModalOpen(false)}
          isOpen={isFinishProposalModalOpen}
        />
        <FormConfigurationProvider
          value={{ optionalDisplayType: 'optional-label' }}
        >
          <ProposalLayout
            householdId={householdId}
            proposalId={proposalId}
            onFormSubmit={onSubmit('finish')}
            heading={'Proposal builder'}
            LeftPaneContent={
              <LeftPaneContent
                control={control}
                // this functionality allows the GRAT modal to update the URL
                // (really to just add a query param) without being blocked by the
                // functionality that stops users with dirty forms from
                // navigating away
                allowExternalNavigationCallback={(allowNavigation) => {
                  flushSync(() => {
                    setShouldBlockNavigation(!allowNavigation);
                  });
                }}
                setEntityIdsInProposal={setEntityIdsInProposal}
                displayEntities={displayEntities}
                householdId={householdId}
                entitiesInProposal={entitiesInProposal}
                entityIdsInProposal={entityIdsInProposal}
                selectedEntityId={selectedEntityId}
                setSelectedEntityId={setSelectedEntityId}
                proposalId={proposalId}
              />
            }
            RightPaneContent={
              <>
                <EditAssumptionsModal
                  onSubmit={(formValues) => {
                    setIsEditAssumptionsModalOpen(false);
                    const newAssumptions: EditAssumptionsForm = {
                      ...assumptions,
                      [selectedEntityId]: formValues,
                    };

                    setAssumptions(newAssumptions);
                  }}
                  onClose={() => setIsEditAssumptionsModalOpen(false)}
                  isOpen={isEditAssumptionsModalOpen}
                  assumptions={assumptions}
                  entityId={selectedEntity.id}
                />

                <Card sx={{ pt: 3, px: 3, pb: 4 }}>
                  <Stack spacing={3}>
                    <Stack
                      component="header"
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={3}
                    >
                      <Typography variant="h1">
                        {selectedEntity.displayName ?? ''}
                      </Typography>

                      <Button
                        variant="secondary"
                        size="sm"
                        startIcon={Settings04Icon}
                        onClick={() => setIsEditAssumptionsModalOpen(true)}
                      >
                        Edit assumptions
                      </Button>
                    </Stack>
                    <ChartLegend justifyContent="end" variant="circles" />

                    {entityIdsInProposal.map((id) => {
                      const hidden = id !== selectedEntityId;

                      const entity = displayEntities.find(
                        (entity) => entity?.id === id
                      );

                      if (!entity) {
                        return null;
                      }

                      return (
                        <Box
                          key={id}
                          sx={{
                            display: hidden ? `none` : `unset`,
                          }}
                        >
                          <Scenarios
                            assumptions={assumptions}
                            selectedEntity={entity}
                            setIsEditAssumptionsModalOpen={
                              setIsEditAssumptionsModalOpen
                            }
                          />
                        </Box>
                      );
                    })}
                  </Stack>
                </Card>
              </>
            }
            FooterActionLeftContent={
              <Button variant="secondary" size="sm" onClick={onSubmit('exit')}>
                {isDirty ? 'Save & close' : 'Close'}
              </Button>
            }
            FooterActionRightContent={
              <Stack direction="row" spacing={2}>
                <Button
                  disabled={false}
                  data-testid="PreviewButton"
                  variant="secondary"
                  size="sm"
                  startIcon={EyeIcon}
                  onClick={onSubmit('preview')}
                >
                  {isDirty ? 'Save and preview' : 'Preview'}
                </Button>
                <Stack direction="row">
                  <Button
                    size="sm"
                    data-testid="FinishButton"
                    variant="primary"
                    disabled={loading || isSubmitting}
                    onClick={onSubmit('finish')}
                  >
                    {isDirty ? 'Save & finish proposal' : 'Finish proposal'}
                  </Button>
                </Stack>
              </Stack>
            }
          />
        </FormConfigurationProvider>
      </FormProvider>
    </ProposalDetailsAwareRoute>
  );
}
