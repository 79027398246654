import { compact } from 'lodash';

import { SubformValues as BasicInformationSubformValuesType } from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import {
  GrantorsFieldTypes_MultipleGrantor,
  GrantorsFieldTypes_SingleGrantor,
} from '@/modules/entities/principals/GrantorsField/GrantorsField.types';
import { AugmentedCreatePrincipalInput } from '@/types/schema';

import { SubformsCombinedType } from '../../EntitySubforms.types';

export function makeCreatePrincipalInputs(
  trustDetails: BasicInformationSubformValuesType
): AugmentedCreatePrincipalInput[] | AugmentedCreatePrincipalInput | undefined {
  const grantor = getGrantorOrGrantors(trustDetails);

  if (!grantor) {
    return [];
  }

  if (!Array.isArray(grantor)) {
    // if the grantor is an empty string, then we want to unset the grantor input
    if (grantor.clientProfileId === '') {
      return undefined;
    }

    return {
      create: {
        individualID: grantor.clientProfileId,
      },
    };
  }

  return (
    grantor
      .map<AugmentedCreatePrincipalInput | null>((grantor) => {
        if (!grantor.clientProfileId) {
          return null;
        }

        const create = {
          individualID: grantor.clientProfileId,
          // this is only used for the MultipleGrantorWithPercent variant
          ownershipPercentage: grantor.percentage,
        };

        // create.ownershipPercentage is Decimal | null | undefined; an empty value will be falsey,
        // but a zero value will not
        if (!create.ownershipPercentage) {
          delete create.ownershipPercentage;
        }

        return {
          create,
        };
      })
      .filter((g): g is AugmentedCreatePrincipalInput => g !== null) ?? []
  );
}

export function makeCreateOwnerInputsForAccountDetailsSubform(
  accountDetails: SubformsCombinedType['basicInformationSubform']
): AugmentedCreatePrincipalInput[] {
  if ('owner' in accountDetails) {
    if (!accountDetails.owner || !accountDetails.owner.ownerId) {
      return [];
    }

    return [
      {
        create: {
          individualID: accountDetails.owner.ownerId,
        },
      },
    ];
  }

  if ('owners' in accountDetails) {
    return compact(
      (accountDetails?.owners || []).map((owner) => {
        if (!owner?.ownerId) {
          return null;
        }

        return {
          create: {
            individualID: owner.ownerId,
          },
        };
      })
    );
  }

  throw new Error('Invalid accountDetailsSubform input');
}

function getGrantorOrGrantors(
  basicInformationSubformValues: BasicInformationSubformValuesType
):
  | GrantorsFieldTypes_SingleGrantor['grantor']
  | GrantorsFieldTypes_MultipleGrantor['grantors'] {
  if (!Object.hasOwn(basicInformationSubformValues, 'grantors')) {
    return (basicInformationSubformValues as GrantorsFieldTypes_SingleGrantor)
      .grantor;
  } else {
    return (basicInformationSubformValues as GrantorsFieldTypes_MultipleGrantor)
      .grantors;
  }
}
