import { Stack } from '@mui/material';
import { useMemo } from 'react';

import { CloseButton } from '@/components/form/baseInputs/Button/CloseButton';
import { Card } from '@/components/layout/Card/Card';
import { Modal } from '@/components/modals/Modal/Modal';
import { Callout } from '@/components/notifications/Callout/Callout';
import { GiftProposalProjection } from '@/modules/gifting/proposal/giftProposal.types';
import { FederalTaxMiniTable } from '@/modules/taxes/components/FederalTaxMiniTable/FederalTaxMiniTable';
import { GiftTaxMiniTable } from '@/modules/taxes/components/GiftTaxMiniTable/GiftTaxMiniTable';
import { StateTaxMiniTable } from '@/modules/taxes/components/StateTaxMiniTable/StateTaxMiniTable';

type GiftScenarioTaxCalculationModalVariant =
  | 'gift-tax'
  | 'gift-and-estate-tax';

interface GiftScenarioTaxCalculationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  projection: GiftProposalProjection;
  hasGiftAndEstateTaxSection: boolean;
}

export function GiftScenarioTaxCalculationModal({
  isOpen,
  handleClose,
  projection,
  hasGiftAndEstateTaxSection,
}: GiftScenarioTaxCalculationModalProps) {
  const {
    taxableGift: taxableGiftsMade,
    lifetimeExemptionAppliedTowardGiftTax,
    giftTax,
    estateTaxSummary: { federalTax, stateTax },
  } = projection;

  const variant: GiftScenarioTaxCalculationModalVariant = useMemo(() => {
    if (hasGiftAndEstateTaxSection) {
      return 'gift-and-estate-tax' as const;
    }

    return 'gift-tax' as const;
  }, [hasGiftAndEstateTaxSection]);

  const hasGiftTax = giftTax.greaterThan(0);

  const modalHeading =
    variant === 'gift-and-estate-tax'
      ? 'Gift & estate tax due'
      : 'Gift tax due';

  return (
    <Modal
      heading={modalHeading}
      isOpen={isOpen}
      onClose={handleClose}
      rightHeaderContent={<CloseButton onClick={handleClose} />}
    >
      <Stack spacing={3}>
        {variant === 'gift-and-estate-tax' && (
          <Card variant="filled" sx={{ borderRadius: '6px', padding: 2 }}>
            <Stack spacing={4}>
              {stateTax && stateTax.length > 0 && (
                <>
                  {stateTax.map((stateTax) => (
                    <StateTaxMiniTable
                      key={stateTax._cacheKey}
                      stateTax={stateTax}
                    />
                  ))}
                </>
              )}
              {federalTax?.tax.isPos() ? (
                <FederalTaxMiniTable federalTax={federalTax} />
              ) : (
                <Callout severity="info-high" textVariant="subtitle2">
                  No estate tax due
                </Callout>
              )}
            </Stack>
          </Card>
        )}

        <Card variant="filled" sx={{ borderRadius: '6px', padding: 2 }}>
          {hasGiftTax ? (
            <GiftTaxMiniTable
              taxableGiftsMade={taxableGiftsMade}
              lifetimeExemptionAppliedTowardGiftTax={
                lifetimeExemptionAppliedTowardGiftTax
              }
              giftTax={giftTax}
            />
          ) : (
            <Callout severity="info-high" textVariant="subtitle2">
              No gift tax due
            </Callout>
          )}
        </Card>
      </Stack>
    </Modal>
  );
}
