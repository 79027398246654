import { Grid, Stack } from '@mui/material';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { SeedAIOnboardingSuggestionsButton } from '@/pages/internal/DevToolsPage/SeedAIOnboardingSuggestionsButton/SeedAIOnboardingSuggestionsButton';
import { SeedAISuggestionsButton } from '@/pages/internal/DevToolsPage/SeedAISuggestionsButton/SeedAISuggestionsButton';

import { AuthorizationEmulation } from './AuthorizationEmulation/AuthorizationEmulationCard';
import { BootstrapWaterfallButton } from './BootstrapWaterfallButton/BootstrapWaterfallButton';
import { ClearLocalStorageValues } from './ClearLocalStorageValues/ClearLocalStorageValues';
import { ClientBuilderCard } from './ClientBuilder/ClientBuilderCard';
import { ClientImportExport } from './ClientImportExport/ClientImportExport';

export function DevToolsPage() {
  return (
    <SubpageLayout heading="Dev tools">
      <Grid container spacing={3} pb={4}>
        <Grid item lg={6} sm={12}>
          <Stack spacing={3}>
            <ClientBuilderCard />
            <ClientImportExport />
          </Stack>
        </Grid>
        <Grid item lg={6} sm={12}>
          <AuthorizationEmulation />
        </Grid>
        <Grid item lg={6} sm={12}>
          <BootstrapWaterfallButton />
        </Grid>
        <Grid item lg={6} sm={12}>
          <SeedAISuggestionsButton />
        </Grid>
        <Grid item lg={6} sm={12}>
          <SeedAIOnboardingSuggestionsButton />
        </Grid>
        <Grid item lg={6} sm={12}>
          <ClearLocalStorageValues />
        </Grid>
      </Grid>
    </SubpageLayout>
  );
}
