import { InputAdornment, Stack } from '@mui/material';
import { Control } from 'react-hook-form';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { FormAwareMultiSelectInput } from '@/components/form/formAwareInputs/FormAwareMultiSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { Mail01Icon } from '@/components/icons/Mail01Icon';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';

import { AssignedClientList } from './AssignedClientList';
import { EmployeeFormShape } from './EmployeeModal';

export interface EmployeeFormProps {
  control: Control<EmployeeFormShape>;
  isCreatingUser: boolean;
  roleOptions: SelectInputOption<string>[];
  assignedClients: {
    id: string;
    displayName: string;
  }[];
}

export function EmployeeForm({
  control,
  assignedClients,
  roleOptions,
  isCreatingUser = false,
}: EmployeeFormProps) {
  return (
    <Stack component="form" noValidate>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareMultiSelectInput<EmployeeFormShape>
            control={control}
            label="Role(s)"
            fieldName="roleIds"
            options={roleOptions}
            required
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput<EmployeeFormShape>
            control={control}
            label="First name"
            required={true}
            fieldName="firstName"
          />
        </FormLayoutItem>
        <FormLayoutItem>
          <FormAwareTextInput<EmployeeFormShape>
            control={control}
            label="Last name"
            required={true}
            fieldName="lastName"
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <FormAwareTextInput<EmployeeFormShape>
            control={control}
            label="Email"
            type="email"
            fieldName="email"
            disabled={!isCreatingUser}
            helpText={
              isCreatingUser
                ? ''
                : 'Contact support to change this email address.'
            }
            startAdornment={
              <InputAdornment position="start">
                <Mail01Icon size={20} />
              </InputAdornment>
            }
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow>
        <FormLayoutItem>
          <AssignedClientList clients={assignedClients} />
        </FormLayoutItem>
      </FormLayoutRow>
    </Stack>
  );
}
