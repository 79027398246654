import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { useFormContext } from '@/components/react-hook-form';

import {
  CSVUploadFormPaths,
  CSVUploadFormShape,
} from '../ImportCSVValuationsPage/ImportCSVValuationsPage.types';
import { DEFAULT_VALUATION_ATTRIBUTION_NAME } from '../ImportCSVValuationsPage/ImportCSVVvaluationsPage.constants';

export function UploadAttributionForm() {
  const { control } = useFormContext<CSVUploadFormShape>();
  return (
    <FormAwareTextInput
      control={control}
      fieldName={'attributionName' as const satisfies CSVUploadFormPaths}
      label="Label data"
      placeholder={DEFAULT_VALUATION_ATTRIBUTION_NAME}
      helpText={
        'Enter a name for the data source to track these valuations. It will appear in entity valuation history.'
      }
    />
  );
}
