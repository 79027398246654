import { noop } from 'lodash';
import { useCallback } from 'react';

import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useTriggerAIOnboardingWorkflow } from '@/modules/aiOnboarding/hooks/useTriggerAIOnboardingWorkflow';
import { AssetIntegrationProviders } from '@/modules/assetProviderIntegrations/shared/constants';
import { useIsAssetProviderIntegrationEnabled } from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import {
  BULK_IMPORT_FLOW_ADDEPAR,
  BulkImportFlowType,
} from '@/modules/import/BulkImportFlowContext';
import { ROUTE_KEYS } from '@/navigation/constants';
import { IntegrationClientKind } from '@/types/schema';

import { CONTINUE_ONBOARDING_FLOW_PARAM } from './CreateHouseholdFlow';
import {
  CreateHouseholdFlowStep,
  useCreateHouseholdFlowContext,
} from './CreateHouseholdFlow.context';
import { useUserHasCreatedHousehold } from './useUserHasCreatedHousehold';

interface PostClientIntakeOpts {
  isProspectHousehold: boolean;
}

export type PostClientIntakeActionType = (
  householdId: string,
  householdIntegrationClient: IntegrationClientKind | null,
  opts: PostClientIntakeOpts
) => void;

export function useGetPostClientIntakeAction(
  currentStep: CreateHouseholdFlowStep
): PostClientIntakeActionType {
  const { integrationsConnected } = useIsAssetProviderIntegrationEnabled();
  const { hasCreatedHousehold } = useUserHasCreatedHousehold();
  const { onClose } = useCreateHouseholdFlowContext();
  const { navigate } = useNavigateToRoute();
  const { triggerWorkflow } = useTriggerAIOnboardingWorkflow();

  const onPostClientIntake = useCallback(
    async (
      householdId: string,
      householdIntegrationClient: IntegrationClientKind | null,
      { isProspectHousehold }: PostClientIntakeOpts
    ) => {
      // if on client data intake with AI onboarding, trigger the AI onboarding workflow and navigate to the documents page.
      // NOTE: this also allows prospect households to create entities via the AI onboarding workflow.
      if (
        currentStep === CreateHouseholdFlowStep.ClientDataIntakeWithAIOnboarding
      ) {
        await triggerWorkflow(householdId);
        navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_DOCUMENTS, {
          householdId,
        });
        return;
      }

      // if the household is a prospect household, just navigate directly to the household page and don't do anything else
      if (isProspectHousehold) {
        navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW, {
          householdId,
        });
        return;
      }

      // if on client data intake and first household, show interstitial modal
      if (
        currentStep === CreateHouseholdFlowStep.ClientDataIntake &&
        !hasCreatedHousehold
      ) {
        navigate(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
          {
            householdId,
          },
          {
            [CONTINUE_ONBOARDING_FLOW_PARAM]:
              CreateHouseholdFlowStep.PostHouseholdCreation,
          }
        );
        return;
      }

      let bulkImportFlowType: BulkImportFlowType | null = null;
      if (
        integrationsConnected.includes(AssetIntegrationProviders.ADDEPAR) &&
        householdIntegrationClient === IntegrationClientKind.Addepar
      ) {
        bulkImportFlowType = BULK_IMPORT_FLOW_ADDEPAR;
      } else if (
        integrationsConnected.includes(
          AssetIntegrationProviders.BLACK_DIAMOND
        ) &&
        householdIntegrationClient === IntegrationClientKind.BlackDiamond
      ) {
        noop(); // WIP -- waiting on black diamond integration
      }

      // if a bulk import flow is available, navigate to the bulk import page and close the modal
      if (bulkImportFlowType) {
        navigate(ROUTE_KEYS.HOUSEHOLD_ENTITY_IMPORT, {
          householdId,
          flowType: bulkImportFlowType,
        });
        onClose();
        return;
      }

      // if a bulk import flow is _not_ available, navigate to the household details page & open the modal to the entity intake step
      navigate(
        ROUTE_KEYS.HOUSEHOLD_DETAILS_OVERVIEW,
        {
          householdId,
        },
        {
          [CONTINUE_ONBOARDING_FLOW_PARAM]:
            CreateHouseholdFlowStep.EntityIntake,
        }
      );
    },
    [
      currentStep,
      hasCreatedHousehold,
      integrationsConnected,
      navigate,
      onClose,
      triggerWorkflow,
    ]
  );
  return onPostClientIntake;
}
