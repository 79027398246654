import { FetchResult, MutationResult } from '@apollo/client';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';

import {
  DuplicateProposalMutation,
  useDuplicateProposalMutation,
} from '../../../pages/ClientDetailsPage/ClientDetailsProposalsPage/graphql/DuplicateProposal.generated';

export function useDuplicateProposal(
  proposalId: string
): [
  duplicateProposal: (
    afterDuplication: (createdProposalId: string) => void | undefined
  ) => Promise<FetchResult<DuplicateProposalMutation>>,
  mutationResult: MutationResult<DuplicateProposalMutation>,
] {
  const [duplicateProposal, mutationResult] = useDuplicateProposalMutation();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const onDuplicateProposal = async (
    afterDuplication: (createdProposalId: string) => void | undefined
  ) =>
    await duplicateProposal({
      variables: {
        duplicateProposalId: proposalId,
      },
      onError: (err) => {
        showFeedback('Failed to duplicate proposal');
        reportError(
          `Caught error while duplicating proposal [${proposalId}]`,
          err
        );
      },
      onCompleted: (data) => {
        const newProposalId = data.duplicateProposal.id;
        if (!afterDuplication) {
          return;
        }
        if (!newProposalId) {
          throw new Error('Duplicated proposal does not have an ID');
        }

        afterDuplication(newProposalId);
      },
    });

  return [onDuplicateProposal, mutationResult];
}
