import { Box } from '@mui/material';
import { chunk } from 'lodash';

import { getBusinessEntitySummaryProperties } from '@/modules/entities/details/businessEntities/BusinessEntityDetailsCard/BusinessEntityDetails.utils';
import {
  EntityDetail_BusinessEntity_CCorpBusinessEntity_Fragment,
  EntityDetail_BusinessEntity_GpBusinessEntity_Fragment,
  EntityDetail_BusinessEntity_LlcBusinessEntity_Fragment,
  EntityDetail_BusinessEntity_LpBusinessEntity_Fragment,
  EntityDetail_BusinessEntity_SCorpBusinessEntity_Fragment,
  EntityDetail_BusinessEntity_SoleProprietorshipBusinessEntity_Fragment,
} from '@/modules/entities/details/graphql/EntityDetailPage.generated';
import { INSURANCE_POLICY_ENTITY_TYPES } from '@/modules/entities/entities.constants';
import { EntityType } from '@/modules/entities/types/EntityType';

import { ClientPresentationBundleTypes } from '../../clientPresentation/clientPresentation.types';
import { useUnguardedClientPresentationDesignerContext } from '../../clientPresentation/contexts/clientPresentationDesigner.context';
import { useRegisterBundle } from '../../clientPresentation/hooks/useRegisterBundle';
import { DispositionScenarioSlide } from '../components/DispositionScenarioSlide/DispositionScenarioSlide';
import { getFilteredDispositionSlideProps } from '../components/DispositionScenarioSlide/DispositionScenarioSlide.utils';
import { InsurancePoliciesSlide } from '../components/InsurancePoliciesSlide';
import {
  EntityPresentationBundleProps,
  EntityPresentationSlideType,
  EntitySlideMap,
} from '../entityPresentations.types';
import {
  generateEntityBundleId,
  getPresentationEntitySlideProps,
} from '../entityPresentations.utils';
import { useOrderedEntitySlides } from '../hooks/useFilteredEntitySlides';
import { BusinessEntityKeyPeopleSlide } from './components/BusinessEntity.KeyPeople';
import { BusinessEntityOverviewSlide } from './components/BusinessEntity.OverviewSlide';
import { BusinessEntityOwnershipSlide } from './components/BusinessEntity.Ownership';

type BusinessEntityFragment =
  | EntityDetail_BusinessEntity_LlcBusinessEntity_Fragment
  | EntityDetail_BusinessEntity_CCorpBusinessEntity_Fragment
  | EntityDetail_BusinessEntity_SCorpBusinessEntity_Fragment
  | EntityDetail_BusinessEntity_SoleProprietorshipBusinessEntity_Fragment
  | EntityDetail_BusinessEntity_GpBusinessEntity_Fragment
  | EntityDetail_BusinessEntity_LpBusinessEntity_Fragment;

type BusinessEntityPresentationBundleProps =
  EntityPresentationBundleProps<BusinessEntityFragment> & {
    entityType: EntityType;
  };

export function BusinessEntityPresentationBundle({
  slideTypes,
  SlideWrapper = Box,
  entity,
  subtype,
  dispositionScenarios,
  entityType,
  entityId,
}: BusinessEntityPresentationBundleProps) {
  const displayName = subtype.displayName;
  const summary = getBusinessEntitySummaryProperties({
    entity,
    entityType,
  });
  const grantors =
    entity.household.possiblePrimaryClients?.map((grantor) => ({
      displayName: grantor.displayName,
      id: grantor.id,
    })) ?? [];
  const bundleId = generateEntityBundleId(entityId);
  useRegisterBundle({
    type: ClientPresentationBundleTypes.ENTITY,
    id: bundleId,
    displayName: displayName,
    identifier: entityId,
  });

  const SLIDE_MAP: EntitySlideMap = {
    [EntityPresentationSlideType.OVERVIEW]: (
      <BusinessEntityOverviewSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.OVERVIEW,
          slideTypes
        )}
        entity={entity}
        summary={summary}
        displayName={displayName}
      />
    ),
    [EntityPresentationSlideType.BUSINESS_OWNERSHIP]: (
      <BusinessEntityOwnershipSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.BUSINESS_OWNERSHIP,
          slideTypes
        )}
        displayName={displayName}
        entity={entity}
        summary={summary}
      />
    ),
    [EntityPresentationSlideType.BUSINESS_KEY_PEOPLE]: (
      <BusinessEntityKeyPeopleSlide
        {...getPresentationEntitySlideProps(
          entityId,
          EntityPresentationSlideType.BUSINESS_KEY_PEOPLE,
          slideTypes
        )}
        displayName={displayName}
        entity={entity}
        summary={summary}
      />
    ),
    [EntityPresentationSlideType.DISPOSITIVE_PROVISIONS]:
      getFilteredDispositionSlideProps({
        dispositionScenarios,
        entityId,
        slideTypes,
      }).map((slideProps, i) => (
        <DispositionScenarioSlide
          {...slideProps}
          key={i}
          displayName={displayName}
          entity={entity}
          grantors={grantors}
        />
      )),
  };

  // Add insurance policies slide only for specific business entity types
  if (INSURANCE_POLICY_ENTITY_TYPES.includes(entityType)) {
    const policyPages = chunk(summary.insurancePolicies, 2);
    SLIDE_MAP[EntityPresentationSlideType.INSURANCE_POLICIES] = policyPages.map(
      (pagePolicies, i) => (
        <InsurancePoliciesSlide
          key={i}
          entity={entity}
          pagePolicies={pagePolicies}
          slideIndex={i}
          numberOfPolicySlides={policyPages.length}
          displayName={displayName}
          {...getPresentationEntitySlideProps(
            entityId,
            EntityPresentationSlideType.INSURANCE_POLICIES,
            slideTypes,
            {
              localSlideTypeIndex: i,
              slideTypeId: pagePolicies?.[0]?.id ?? `slide-${i}`,
            }
          )}
        />
      )
    );
  }

  const slides = useOrderedEntitySlides(slideTypes, SLIDE_MAP);
  const { shouldShowItem } = useUnguardedClientPresentationDesignerContext();
  if (!shouldShowItem(entityId)) {
    return null;
  }

  return (
    <>
      {slides.map((slide, index) => {
        return <SlideWrapper key={index}>{slide}</SlideWrapper>;
      })}
    </>
  );
}
