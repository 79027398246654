import { Box } from '@mui/material';
import { ComponentProps } from 'react';
import { SetOptional } from 'type-fest';

import { DotsVerticalIcon } from '@/components/icons/DotsVerticalIcon';
import { IconProps } from '@/components/icons/Edit02Icon';

import { ButtonWithPopover, ButtonWithPopoverProps } from './ButtonWithPopover';
import { iconSizeByButtonSize } from './styles';

export type ContextMenuButtonProps = SetOptional<
  ButtonWithPopoverProps,
  'variant' | 'size' | 'label'
> & {
  sx?: ComponentProps<typeof ButtonWithPopover>['sx'];
  iconProps?: IconProps;
};

export function ContextMenuButton({
  variant = 'secondary',
  size = 'sm',
  iconProps,
  children,
  sx = {},
  ...props
}: ContextMenuButtonProps) {
  const iconSize = iconSizeByButtonSize[size];

  return (
    <ButtonWithPopover
      popperVariant="menuBelow"
      label={<DotsVerticalIcon size={iconSize} {...iconProps} />}
      hideChevron
      variant={variant}
      size={size}
      square
      buttonSx={sx}
      highlightWhenOpen={variant === 'transparent'}
      {...props}
    >
      <Box sx={{ minWidth: 250 }}>{children}</Box>
    </ButtonWithPopover>
  );
}
