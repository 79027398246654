import Decimal from 'decimal.js';

import { EntityStage } from '@/types/schema';

import { ESTATE_TAX_RATE } from '../../entities.constants';
import { GratPerformanceFragment } from '../graphql/GratTrust.generated';
import { getAnnuitiesStatistics } from './getAnnuitiesStatistics';

export type GratPerformance = GratPerformanceFragment & {
  stage: EntityStage;
};

export function getPerformanceForGrat(grat: GratPerformance) {
  const { remainingPayments } = getAnnuitiesStatistics(grat.annuities ?? []);

  const gratValue = (() => {
    // if the GRAT has already been distributed it is complete, use that value
    if (grat.distributionAssetValuation) {
      return grat.distributionAssetValuation.valuationValue ?? new Decimal(0);
    }

    // otherwise, use the current value
    return grat?.currentValue ?? new Decimal(0);
  })();
  const impliedSurplus = gratValue.minus(remainingPayments ?? new Decimal(0));

  // if the tax-free transfer number is negative, then we should show 0, because
  // there's no way to transfer a negative amount of money
  const taxFreeTransfer = (() => {
    const potentialTaxFreeTransfer = impliedSurplus.minus(
      grat?.taxableGift ?? new Decimal(0)
    );
    if (potentialTaxFreeTransfer.lessThan(new Decimal(0)))
      return new Decimal(0);
    return potentialTaxFreeTransfer;
  })();

  const impliedEstateTaxSavings = taxFreeTransfer.times(ESTATE_TAX_RATE);

  return {
    remainingPayments,
    gratValue,
    impliedSurplus,
    taxFreeTransfer,
    impliedEstateTaxSavings,
    taxableGift: grat?.taxableGift ?? new Decimal(0),
    remainderValueOfCompletedGrat:
      grat.stage === EntityStage.Completed ? gratValue : new Decimal(0),
    estateTaxSavingsOfCompletedGrat:
      grat.stage === EntityStage.Completed
        ? impliedEstateTaxSavings
        : new Decimal(0),
    impliedSurplusOfActiveGrat:
      grat.stage === EntityStage.Active ? impliedSurplus : new Decimal(0),
  };
}

// this function is used to provide a summary of the performance of a group of GRATs
// but discounts negatively performing GRATs to communicate net value added
export function getAddedValuePerformance(grats: GratPerformance[]) {
  const performance = {
    remainingPayments: new Decimal(0),
    gratValue: new Decimal(0),
    impliedSurplus: new Decimal(0),
    displayImpliedSurplus: new Decimal(0),
    taxFreeTransfer: new Decimal(0),
    displayTaxFreeTransfer: new Decimal(0),
    impliedEstateTaxSavings: new Decimal(0),
    displayImpliedEstateTaxSavings: new Decimal(0),
    taxableGift: new Decimal(0),
    totalDeficit: new Decimal(0),
    dueBackToGrantor: new Decimal(0), // this is the amount that the grantor will receive if a GRAT is in shortfall
    remainderValueOfCompletedGrats: new Decimal(0),
    estateTaxSavingsOfCompletedGrats: new Decimal(0),
    impliedSurplusOfActiveGrats: new Decimal(0),
  };

  grats.forEach((grat) => {
    const {
      remainingPayments,
      gratValue,
      impliedSurplus,
      impliedEstateTaxSavings,
      taxableGift,
      remainderValueOfCompletedGrat,
      estateTaxSavingsOfCompletedGrat,
      impliedSurplusOfActiveGrat,
    } = getPerformanceForGrat(grat);

    performance.remainingPayments =
      performance.remainingPayments.plus(remainingPayments);
    performance.gratValue = performance.gratValue.plus(gratValue);
    performance.impliedSurplus = impliedSurplus.isNegative()
      ? performance.impliedSurplus
      : performance.impliedSurplus.plus(impliedSurplus);
    performance.impliedEstateTaxSavings = impliedEstateTaxSavings.isNegative()
      ? performance.impliedEstateTaxSavings
      : performance.impliedEstateTaxSavings.plus(impliedEstateTaxSavings);

    performance.taxableGift = performance.taxableGift.plus(taxableGift);
    performance.totalDeficit = impliedSurplus.isNegative()
      ? performance.totalDeficit.plus(impliedSurplus.abs())
      : performance.totalDeficit;
    performance.dueBackToGrantor = impliedSurplus.isNegative()
      ? performance.dueBackToGrantor.plus(gratValue)
      : performance.dueBackToGrantor.plus(remainingPayments);
    performance.remainderValueOfCompletedGrats =
      performance.remainderValueOfCompletedGrats.plus(
        remainderValueOfCompletedGrat.isNegative()
          ? new Decimal(0)
          : remainderValueOfCompletedGrat
      );
    performance.estateTaxSavingsOfCompletedGrats =
      performance.estateTaxSavingsOfCompletedGrats.plus(
        estateTaxSavingsOfCompletedGrat
      );
    performance.impliedSurplusOfActiveGrats =
      performance.impliedSurplusOfActiveGrats.plus(
        impliedSurplusOfActiveGrat.isNegative()
          ? new Decimal(0)
          : impliedSurplusOfActiveGrat
      );
  });

  performance.displayImpliedSurplus = performance.impliedSurplus;
  performance.taxFreeTransfer = performance.impliedSurplus.minus(
    performance.taxableGift
  );
  performance.displayTaxFreeTransfer = performance.taxFreeTransfer.isNegative()
    ? new Decimal(0)
    : performance.taxFreeTransfer;
  performance.displayImpliedEstateTaxSavings =
    performance.impliedEstateTaxSavings;

  return performance;
}

// this function is used to provide a summary of the performance of a group of GRATs
// and incorporates negative performance
export function getCumulativeGratPerformance(grats: GratPerformance[]) {
  const performance = {
    remainingPayments: new Decimal(0),
    gratValue: new Decimal(0),
    impliedSurplus: new Decimal(0),
    displayImpliedSurplus: new Decimal(0),
    taxFreeTransfer: new Decimal(0),
    displayTaxFreeTransfer: new Decimal(0),
    impliedEstateTaxSavings: new Decimal(0),
    displayImpliedEstateTaxSavings: new Decimal(0),
    taxableGift: new Decimal(0),
  };

  grats.forEach((grat) => {
    const {
      remainingPayments,
      gratValue,
      impliedSurplus,
      taxFreeTransfer,
      impliedEstateTaxSavings,
      taxableGift,
    } = getPerformanceForGrat(grat);

    performance.remainingPayments =
      performance.remainingPayments.plus(remainingPayments);
    performance.gratValue = performance.gratValue.plus(gratValue);
    performance.impliedSurplus =
      performance.impliedSurplus.plus(impliedSurplus);
    performance.taxFreeTransfer =
      performance.taxFreeTransfer.plus(taxFreeTransfer);
    performance.impliedEstateTaxSavings =
      performance.impliedEstateTaxSavings.plus(impliedEstateTaxSavings);

    performance.taxableGift = performance.taxableGift.plus(taxableGift);
  });

  performance.displayImpliedSurplus = performance.impliedSurplus.isNegative()
    ? new Decimal(0)
    : performance.impliedSurplus;
  performance.displayTaxFreeTransfer = performance.taxFreeTransfer.isNegative()
    ? new Decimal(0)
    : performance.taxFreeTransfer;
  performance.displayImpliedEstateTaxSavings =
    performance.impliedEstateTaxSavings.isNegative()
      ? new Decimal(0)
      : performance.impliedEstateTaxSavings;

  return performance;
}
