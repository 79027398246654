import {
  getOptionsFromData,
  getTrusteeOptionsWithSelectedDisabled,
} from '../../inputs/TrusteeDropdown/TrusteeDropdown';
import { TrustDetailsSubformType } from '../TrustDetailsSubform.types';
import {
  hasSuccessorTrustees,
  hasTrustAdvisors,
  hasTrustees,
} from '../TrustDetailsSubform.utils';
import { TrustDetailsSubformType_TrusteesAndSuccessorTrustees } from './../TrustDetailsSubform.types';

export function useOptionsForTrustDetailsForm({
  subformValues,
  possibleTrusteeOptions,
}: {
  subformValues?:
    | TrustDetailsSubformType
    | TrustDetailsSubformType_TrusteesAndSuccessorTrustees;
  possibleTrusteeOptions: ReturnType<typeof getOptionsFromData>;
}) {
  const selectedTrusteeIds = (() => {
    if (hasTrustees(subformValues)) {
      return subformValues.trustees?.map((t) => t.trusteeId) ?? [];
    }
    return [];
  })();

  const selectedTrustAdvisorIds = (() => {
    if (hasTrustAdvisors(subformValues)) {
      return subformValues.trustAdvisors.map((t) => t.trustAdvisorId) ?? [];
    }
    return [];
  })();

  const selectedSuccessorTrusteeIds = (() => {
    if (hasSuccessorTrustees(subformValues)) {
      return (
        subformValues?.successorTrustees.map((t) => t.successorTrusteeId) ?? []
      );
    }
    return [];
  })();

  const trusteeOptions = getTrusteeOptionsWithSelectedDisabled({
    possibleOptions: possibleTrusteeOptions,
    selectedIds: selectedTrusteeIds,
  });

  const trustAdvisorOptions = getTrusteeOptionsWithSelectedDisabled({
    possibleOptions: possibleTrusteeOptions,
    selectedIds: selectedTrustAdvisorIds,
  });

  const successorTrusteeOptions = getTrusteeOptionsWithSelectedDisabled({
    possibleOptions: possibleTrusteeOptions,
    selectedIds: selectedSuccessorTrusteeIds,
  });

  return { trusteeOptions, trustAdvisorOptions, successorTrusteeOptions };
}
