import { identity } from 'lodash';

import { compose } from '@/utils/reducerUtils';

import { applyAutoLayoutToState, hasFullyInitializedNodes } from '../state';
import { EntityMapActionFn, InitializeNodePositionsAction } from '../types';

export const initializeNodePositionsAction: EntityMapActionFn<
  InitializeNodePositionsAction
> = (state, _action, queueEffect) => {
  queueEffect({ type: 'fitViewEffect' });
  queueEffect({ type: 'completeInitializationEffect' });

  // Only run auto layout when we don't already have a layout to begin with
  const autoLayoutFn = hasFullyInitializedNodes(state)
    ? identity
    : applyAutoLayoutToState;

  return compose(autoLayoutFn)(state);
};
