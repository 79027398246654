import { Stack } from '@mui/material';
import { ReactNode } from 'react';

import { SectionLabelCentered } from './SectionLabelCentered';

export interface TileSectionProps {
  label?: string;
  children: ReactNode;
}

export const TileSection = ({ children, label }: TileSectionProps) => {
  return (
    <Stack
      gap={3}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {label && <SectionLabelCentered label={label} />}
      <Stack
        gap={2}
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
      >
        {children}
      </Stack>
    </Stack>
  );
};
