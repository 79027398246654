// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { PrimaryClientDropdown_HouseholdWithPrimaryClientsFragmentDoc } from '../../../entities/inputs/PrimaryClientDropdown/graphql/PrimaryClientDropdown.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecordExemptionUsedModalFormOptionsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.HouseholdWhereInput>;
}>;


export type RecordExemptionUsedModalFormOptionsQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, legalName: string, firstName: string }> } | null } | null> | null } };

export type LifetimeExclusionEventsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.LifetimeExclusionEventWhereInput>;
}>;


export type LifetimeExclusionEventsQuery = { __typename?: 'Query', lifetimeExclusionEvents: { __typename?: 'LifetimeExclusionEventConnection', edges?: Array<{ __typename?: 'LifetimeExclusionEventEdge', node?: { __typename?: 'LifetimeExclusionEvent', id: string, giftCategory?: Types.LifetimeExclusionEventGiftCategory | null, eventDate: Time, annualExclusionAmount?: CurrencyUSD | null, discountAmount?: CurrencyUSD | null, gstExclusionAmount: CurrencyUSD, kind: Types.LifetimeExclusionEventKind, lifetimeExclusionAmount: CurrencyUSD, notes?: string | null, grantor: { __typename?: 'ClientProfile', id: string }, documents?: Array<{ __typename?: 'Document', id: string }> | null } | null } | null> | null } };


export const RecordExemptionUsedModalFormOptionsDocument = gql`
    query RecordExemptionUsedModalFormOptions($where: HouseholdWhereInput) {
  households(where: $where) {
    edges {
      node {
        ...PrimaryClientDropdown_HouseholdWithPrimaryClients
      }
    }
  }
}
    ${PrimaryClientDropdown_HouseholdWithPrimaryClientsFragmentDoc}`;

/**
 * __useRecordExemptionUsedModalFormOptionsQuery__
 *
 * To run a query within a React component, call `useRecordExemptionUsedModalFormOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordExemptionUsedModalFormOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordExemptionUsedModalFormOptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useRecordExemptionUsedModalFormOptionsQuery(baseOptions?: Apollo.QueryHookOptions<RecordExemptionUsedModalFormOptionsQuery, RecordExemptionUsedModalFormOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecordExemptionUsedModalFormOptionsQuery, RecordExemptionUsedModalFormOptionsQueryVariables>(RecordExemptionUsedModalFormOptionsDocument, options);
      }
export function useRecordExemptionUsedModalFormOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecordExemptionUsedModalFormOptionsQuery, RecordExemptionUsedModalFormOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecordExemptionUsedModalFormOptionsQuery, RecordExemptionUsedModalFormOptionsQueryVariables>(RecordExemptionUsedModalFormOptionsDocument, options);
        }
export function useRecordExemptionUsedModalFormOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<RecordExemptionUsedModalFormOptionsQuery, RecordExemptionUsedModalFormOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecordExemptionUsedModalFormOptionsQuery, RecordExemptionUsedModalFormOptionsQueryVariables>(RecordExemptionUsedModalFormOptionsDocument, options);
        }
export type RecordExemptionUsedModalFormOptionsQueryHookResult = ReturnType<typeof useRecordExemptionUsedModalFormOptionsQuery>;
export type RecordExemptionUsedModalFormOptionsLazyQueryHookResult = ReturnType<typeof useRecordExemptionUsedModalFormOptionsLazyQuery>;
export type RecordExemptionUsedModalFormOptionsSuspenseQueryHookResult = ReturnType<typeof useRecordExemptionUsedModalFormOptionsSuspenseQuery>;
export type RecordExemptionUsedModalFormOptionsQueryResult = Apollo.QueryResult<RecordExemptionUsedModalFormOptionsQuery, RecordExemptionUsedModalFormOptionsQueryVariables>;
export const LifetimeExclusionEventsDocument = gql`
    query LifetimeExclusionEvents($where: LifetimeExclusionEventWhereInput) {
  lifetimeExclusionEvents(where: $where) {
    edges {
      node {
        id
        grantor {
          id
        }
        documents {
          id
        }
        giftCategory
        eventDate
        annualExclusionAmount
        discountAmount
        gstExclusionAmount
        kind
        lifetimeExclusionAmount
        notes
      }
    }
  }
}
    `;

/**
 * __useLifetimeExclusionEventsQuery__
 *
 * To run a query within a React component, call `useLifetimeExclusionEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLifetimeExclusionEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLifetimeExclusionEventsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useLifetimeExclusionEventsQuery(baseOptions?: Apollo.QueryHookOptions<LifetimeExclusionEventsQuery, LifetimeExclusionEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LifetimeExclusionEventsQuery, LifetimeExclusionEventsQueryVariables>(LifetimeExclusionEventsDocument, options);
      }
export function useLifetimeExclusionEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LifetimeExclusionEventsQuery, LifetimeExclusionEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LifetimeExclusionEventsQuery, LifetimeExclusionEventsQueryVariables>(LifetimeExclusionEventsDocument, options);
        }
export function useLifetimeExclusionEventsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<LifetimeExclusionEventsQuery, LifetimeExclusionEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LifetimeExclusionEventsQuery, LifetimeExclusionEventsQueryVariables>(LifetimeExclusionEventsDocument, options);
        }
export type LifetimeExclusionEventsQueryHookResult = ReturnType<typeof useLifetimeExclusionEventsQuery>;
export type LifetimeExclusionEventsLazyQueryHookResult = ReturnType<typeof useLifetimeExclusionEventsLazyQuery>;
export type LifetimeExclusionEventsSuspenseQueryHookResult = ReturnType<typeof useLifetimeExclusionEventsSuspenseQuery>;
export type LifetimeExclusionEventsQueryResult = Apollo.QueryResult<LifetimeExclusionEventsQuery, LifetimeExclusionEventsQueryVariables>;
export const Operations = {
  Query: {
    RecordExemptionUsedModalFormOptions: 'RecordExemptionUsedModalFormOptions' as const,
    LifetimeExclusionEvents: 'LifetimeExclusionEvents' as const
  }
}