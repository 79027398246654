import { FetchResult } from '@apollo/client';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { diagnostics } from '@/utils/diagnostics';

import {
  CreateEstateWaterfallVisualizationFilterMutation,
  DeleteEstateWaterfallFilterMutation,
  UpdateEstateWaterfallVisualizationFilterMutation,
  useCreateEstateWaterfallVisualizationFilterMutation,
  useDeleteEstateWaterfallFilterMutation,
  useUpdateEstateWaterfallMutation,
  useUpdateEstateWaterfallVisualizationFilterMutation,
} from '../graphql/EstateWaterfallModal.generated';
import { EstateWaterfallFormData } from '../types';

interface UseCreateEstateWaterfallInput {
  waterfallId: string;
}

export function useEditEstateWaterfall({
  waterfallId,
}: UseCreateEstateWaterfallInput) {
  const { showFeedback } = useFeedback();
  const { setError } = useFormContext<EstateWaterfallFormData>();

  const [updateMutation] = useUpdateEstateWaterfallMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      diagnostics.error(`Could not update estate waterfall`, error, {
        id: waterfallId,
      });
    },
  });

  const [updateVizFilter] = useUpdateEstateWaterfallVisualizationFilterMutation(
    {
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        diagnostics.error(
          `Could not update estate waterfall viz fiilter`,
          error
        );
      },
    }
  );

  const [createVizFilter] = useCreateEstateWaterfallVisualizationFilterMutation(
    {
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onError: (error) => {
        showFeedback(FeedbackMessages.formSaveError);
        diagnostics.error(
          `Could not create estate waterfall viz filter`,
          error
        );
      },
    }
  );

  const [deleteVizFilter] = useDeleteEstateWaterfallFilterMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      diagnostics.error(`Could not delete estate waterfall viz filter`, error);
    },
  });

  const submitFailure = (message: string) => {
    diagnostics.error(message);
    // Sets isSubmitSuccessful to false
    setError('root', {
      type: 'manual',
      message,
    });
    // Sets isSubmitting to false
    return Promise.resolve();
  };

  const editWaterfall: (
    formData: EstateWaterfallFormData
  ) => Promise<
    | void
    | FetchResult<UpdateEstateWaterfallVisualizationFilterMutation>
    | FetchResult<CreateEstateWaterfallVisualizationFilterMutation>
    | FetchResult<DeleteEstateWaterfallFilterMutation>
  > = async (formData) => {
    try {
      const shouldRemoveFilter = !formData.waterfallFilter;

      const { data } = await updateMutation({
        variables: {
          input: {
            id: waterfallId,
            update: {
              displayName: formData.displayName,
            },
          },
          refetchWaterfall: true,
        },
      });

      const waterfallID = data?.updateEstateWaterfall.id;
      const filterId = data?.updateEstateWaterfall.filter?.id;

      if (!waterfallID) {
        return submitFailure('Failed to update waterfall, no waterfallID');
      }

      const { includedClientIDs, includedEntityIDs } =
        formData.waterfallFilter ?? {};

      if (shouldRemoveFilter) {
        if (filterId) {
          return deleteVizFilter({
            variables: {
              id: filterId,
            },
          });
        }

        return Promise.resolve();
      }

      if (!filterId) {
        // We could be editing a default waterfall that doesn't have a filter yet
        return createVizFilter({
          variables: {
            input: {
              create: {
                includedClientIDs,
                includedEntityIDs,
                waterfallID,
              },
            },
          },
        });
      }

      return updateVizFilter({
        variables: {
          input: {
            id: filterId,
            update: {
              clearIncludedClients: true,
              clearIncludedEntities: true,
              addIncludedClientIDs: includedClientIDs,
              addIncludedEntityIDs: includedEntityIDs,
            },
          },
        },
      });
    } catch (e) {
      return submitFailure('Failed to update waterfall');
    }
  };

  return {
    editWaterfall,
  };
}
