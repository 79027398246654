import { useMemo } from 'react';

import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.constants';
import { AIOnboardingModalFormShape } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { ClientOrganizationKind } from '@/types/schema';
import { PathsOf } from '@/types/subform';
import { createOptionsFromEnum } from '@/utils/createOptionsFromEnum';

interface OrganizationFieldsRowProps {
  index: number;
}

export function OrganizationFieldsRow({ index }: OrganizationFieldsRowProps) {
  const { control } = useFormContext<AIOnboardingModalFormShape>();

  const organizationKindOptions = useMemo(
    () => createOptionsFromEnum(ClientOrganizationKind),
    []
  );

  const { styles, columns } =
    AI_ONBOARDING_MODAL_FORM_TABLE_STYLE_CONFIGS.organizations;

  return (
    <>
      <FormLayoutRow sx={{ gap: styles.columnPaddingHorizontal }}>
        <FormLayoutItem width={columns.organizationKind.columnWidth}>
          <FormAwareSelectInput<AIOnboardingModalFormShape>
            control={control}
            fieldName={
              `organizations.${index}.organizationKind` as const satisfies PathsOf<AIOnboardingModalFormShape>
            }
            label={columns.organizationKind.columnHeader}
            options={organizationKindOptions}
            hideLabel
            required
          />
        </FormLayoutItem>
        <FormLayoutItem width={columns.organizationName.columnWidth}>
          <FormAwareTextInput<AIOnboardingModalFormShape>
            control={control}
            fieldName={
              `organizations.${index}.organizationName` as const satisfies PathsOf<AIOnboardingModalFormShape>
            }
            label={columns.organizationName.columnHeader}
            hideLabel
            required
          />
        </FormLayoutItem>
      </FormLayoutRow>
    </>
  );
}
