import { PopperContent } from '@/components/poppers/PopperContent';

export function ImpliedSurplus() {
  return (
    <PopperContent
      heading="Implied surplus"
      body={
        'Represents the value of assets held in the GRAT in excess of the remaining annuity payments as of the last valuation date.'
      }
    />
  );
}
