import { Stack } from '@mui/material';
import { isNumber } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwareCurrencyInput } from '@/components/form/formAwareInputs/FormAwareCurrencyInput';
import { FormAwareFormattedNumberInput } from '@/components/form/formAwareInputs/FormAwareFormattedNumberInput';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';
import { EstateWaterfallHypotheticalTransferTransferTaxKind } from '@/types/schema';

import { useHypotheticalTransferAmountHelpText } from '../hooks/useHypotheticalTransferAmountHelpText';
import {
  AnnuallyRecurringValue,
  HypotheticalTransferFormModalShape,
} from '../HypotheticalTransferModal.types';
import { deserializeTransferType } from '../HypotheticalTransferModal.utils';
import { TransferFrequency } from './TransferFrequency';

export function TransferDetails() {
  const {
    control,
    setValue,
    resetField,
    formState: { defaultValues },
  } = useFormContext<HypotheticalTransferFormModalShape>();
  const [
    transferFrequency,
    transferAmount,
    taxableValue,
    sourceId,
    sourceOwnedEntityId,
    sourceInsurancePolicyId,
    startYear,
    _yearOptionsDisabled,
    _policyOrAsset,
    transferType,
  ] = useWatch({
    control,
    name: [
      'transferFrequency',
      'transferAmount',
      'taxableValue',
      'sourceId',
      'sourceOwnedEntityId',
      'sourceInsurancePolicyId',
      'transferStartYear',
      '_yearOptionsDisabled',
      '_policyOrAsset',
      'transferType',
    ],
  });

  const transferAmountHelpText = useHypotheticalTransferAmountHelpText({
    transferAmount,
    sourceId,
    sourceOwnedEntityId,
    sourceInsurancePolicyId,
  });

  const startYearValidator = useCallback(
    (endYear: unknown) => {
      let endYearNumber: number | undefined = undefined;
      // end year doesn't need to be present so failing to parse it is fine...
      try {
        endYearNumber = parseInt((endYear as string) || '');
      } catch (err) {
        return undefined;
      }

      // ...as is the case where it's not a number
      if (!isNumber(endYearNumber)) {
        return undefined;
      }

      // ...and if it's a one-time transfer, the value can be ignored (since it'll be overwritten on save)
      if (transferFrequency === AnnuallyRecurringValue.false) {
        return undefined;
      }

      // but if it's present and a number, on a recurring transfer, it needs to be greater than the start year
      if (startYear > endYearNumber) {
        return 'End year must be no earlier than the start year';
      }

      return undefined;
    },
    [startYear, transferFrequency]
  );

  const isGiftFromPolicy = useMemo(() => {
    if (!sourceInsurancePolicyId) return false;
    return true;
  }, [sourceInsurancePolicyId]);

  const isTaxableGiftFromPolicy = useMemo(() => {
    if (!isGiftFromPolicy) return false;

    const { transferTaxKind } = deserializeTransferType(transferType);

    return (
      transferTaxKind ===
      EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift
    );
  }, [isGiftFromPolicy, transferType]);

  useEffect(
    function clearTaxableValueOrAmount() {
      if (!sourceInsurancePolicyId) return;

      if (isTaxableGiftFromPolicy && transferAmount) {
        setValue('transferAmount', null);
      } else if (!isTaxableGiftFromPolicy && taxableValue) {
        setValue('taxableValue', null);
      }
    },
    [
      isTaxableGiftFromPolicy,
      setValue,
      sourceInsurancePolicyId,
      taxableValue,
      transferAmount,
    ]
  );

  useEffect(
    function defaultTaxableGiftFromPolicy() {
      if (!isTaxableGiftFromPolicy) return;
      if (defaultValues?.taxableValue) return;

      resetField('taxableValue', {
        defaultValue: transferAmountHelpText.value ?? null,
      });
    },
    [
      defaultValues,
      isTaxableGiftFromPolicy,
      resetField,
      transferAmountHelpText.value,
    ]
  );

  return (
    <Stack>
      <FormLayoutRow columns={12}>
        <FormLayoutItem width={12}>
          <FormAwareTextInput<HypotheticalTransferFormModalShape>
            control={control}
            fieldName="transferName"
            label="Transfer name"
          />
        </FormLayoutItem>
      </FormLayoutRow>
      <FormLayoutRow columns={12} sx={{ alignItems: 'flex-start' }}>
        <FormLayoutItem width={6}>
          {!isTaxableGiftFromPolicy && (
            <FormAwareCurrencyInput<HypotheticalTransferFormModalShape>
              control={control}
              fieldName="transferAmount"
              label="Amount to transfer"
              required
              isDecimalJSInput
              {...transferAmountHelpText}
            />
          )}
          {isTaxableGiftFromPolicy && (
            <FormAwareCurrencyInput<HypotheticalTransferFormModalShape>
              control={control}
              fieldName="taxableValue"
              label="Taxable gift amount"
              required
              isDecimalJSInput
              {...transferAmountHelpText}
            />
          )}
        </FormLayoutItem>
        {!isGiftFromPolicy && (
          <TransferFrequency disabled={_yearOptionsDisabled} />
        )}
      </FormLayoutRow>
      {transferFrequency === AnnuallyRecurringValue.true ? (
        <FormLayoutRow>
          <FormLayoutItem width={4}>
            <FormAwareFormattedNumberInput<HypotheticalTransferFormModalShape>
              control={control}
              fieldName="transferStartYear"
              label="Start year"
              required
            />
          </FormLayoutItem>
          <FormLayoutItem width={4}>
            <FormAwareFormattedNumberInput<HypotheticalTransferFormModalShape>
              control={control}
              fieldName="transferEndYear"
              label="End year"
              validation={{
                startYear: startYearValidator,
              }}
            />
          </FormLayoutItem>
          <FormLayoutItem width={4}>
            <FormAwarePercentInput<HypotheticalTransferFormModalShape>
              control={control}
              fieldName="transferGrowthRate"
              label="Growth rate"
              required
              isDecimalJSInput
              allowNegative
              contextualHelp={
                <PopperContent
                  sx={{
                    maxWidth: '200px',
                  }}
                  body="If this is a recurring transfer, enter the rate (%) the transfer amount will increase or decrease each year."
                />
              }
            />
          </FormLayoutItem>
        </FormLayoutRow>
      ) : (
        <FormLayoutRow>
          <FormLayoutItem width={12}>
            <FormAwareFormattedNumberInput<HypotheticalTransferFormModalShape>
              control={control}
              fieldName="transferStartYear"
              label="Year of transfer"
              required
              disabled={_yearOptionsDisabled}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
    </Stack>
  );
}
