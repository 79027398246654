import { ApolloError } from '@apollo/client';
import { useCallback, useState } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';

import { $downloadFileFromURL } from '../utils/fileUtils';
import { useGetDocumentDownloadDetailsLazyQuery } from './graphql/UseFileDownload.generated';

export function useDocumentDownload(): [
  (documentId: string) => Promise<void>,
  { loading: boolean; error: ApolloError | undefined },
] {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [documentDownloading, setDocumentDownloading] = useState(false);
  const trackUserEvent = useTrackUserEvent();
  const [getDownloadDetails, { error, loading: generatingDownloadToken }] =
    useGetDocumentDownloadDetailsLazyQuery({
      nextFetchPolicy: 'no-cache',
    });

  const downloadFile = useCallback(
    async (documentId: string) => {
      try {
        setDocumentDownloading(true);
        const result = await getDownloadDetails({
          variables: {
            documentId,
          },
        });

        const downloadDetails = result.data;
        if (
          !downloadDetails ||
          downloadDetails.node?.__typename !== 'Document'
        ) {
          // this shouldn't happen
          showFeedback('There was an error downloading the document.');
          reportError(
            'invalid download details received',
            new Error('non-document downloaded')
          );
          return;
        }

        const { downloadURL } = downloadDetails.node.file.download;
        await $downloadFileFromURL(downloadURL, downloadDetails.node.name);

        trackUserEvent('document downloaded', {
          documentId: downloadDetails.node.id,
        });
      } catch (err) {
        reportError('failed to download document from s3', err as Error);
        showFeedback(
          'There was an error downloading the document. Please try again.'
        );
        throw err; // Re-throw to maintain promise rejection
      } finally {
        setDocumentDownloading(false);
      }
    },
    [getDownloadDetails, reportError, showFeedback, trackUserEvent]
  );

  const loading = generatingDownloadToken || documentDownloading;
  return [downloadFile, { loading, error }];
}
