import { Typography } from '@mui/material';
import { useState } from 'react';

import { Download01Icon } from '@/components/icons/Download01Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { downloadBlob } from '@/modules/files/utils/fileUtils';
import {
  GetClientDetails_HouseholdFragment,
  useGetClientDetailsQuery,
} from '@/modules/household/contexts/graphql/GetClientDetails.generated';
import { diagnostics } from '@/utils/diagnostics';

import { useIsInteractive } from '../../hooks/useIsInteractive';
import { ControlButton, ControlButtonProps } from './ControlButton';
import {
  defaultDownloadOptions,
  DownloadOptions,
  getFlowChartDownloadSizeOptions,
} from './FlowChartDownload.utils';

export type PrintDiagramButtonProps = ControlButtonProps & {
  downloadOptions?: DownloadOptions;
  hideWhenInteractive?: boolean;
  viewDisplayName: string | null;
  householdId: string;
  onBeforeDownload?: () => void | Promise<void>;
  onAfterDownload?: () => void;
  tooltipText?: string;
};

export const DownloadButton = ({
  variant = 'secondary-filled',
  downloadOptions = defaultDownloadOptions,
  hideWhenInteractive = true,
  onClick,
  householdId,
  viewDisplayName,
  children,
  onBeforeDownload,
  onAfterDownload,
  tooltipText,
  ...props
}: PrintDiagramButtonProps) => {
  const { createErrorFeedback } = useFeedback();
  const { isInteractive } = useIsInteractive();
  const [loading, setLoading] = useState(false);
  const {
    fileName = defaultDownloadOptions.fileName,
    elementSelector = defaultDownloadOptions.elementSelector,
    resolution = defaultDownloadOptions.resolution,
    cacheBust = defaultDownloadOptions.cacheBust,
    filter = defaultDownloadOptions.filter,
    ...opts
  } = downloadOptions;

  const showDownloadError = createErrorFeedback(
    `Sorry, we couldn't download this image. Please refresh the page and try again.`
  );
  const { data } = useGetClientDetailsQuery({
    variables: { householdId },
  });

  const household =
    data?.household as GetClientDetails_HouseholdFragment | null;

  async function defaultOnClick() {
    try {
      setLoading(true);
      await onBeforeDownload?.();

      const { toCanvas } = await import('html-to-image');
      const element = document.querySelector(elementSelector);

      if (!element) {
        showDownloadError();
        diagnostics.error(`Could not find element ${elementSelector}`);
        return;
      }

      const { canvasHeight, canvasWidth } = getFlowChartDownloadSizeOptions(
        element,
        resolution
      );

      const canvas = await toCanvas(element as HTMLElement, {
        canvasHeight,
        canvasWidth,
        filter,
        cacheBust,
        ...opts,
      });

      const blob = await new Promise<Blob | null>((r) => canvas.toBlob(r));

      if (!blob) {
        showDownloadError();
        diagnostics.error(`Could not generate blob for ${elementSelector}`);
        return;
      }

      downloadBlob(
        blob,
        `${household?.displayName} - ${viewDisplayName || 'All entities'}.png`
      );
    } catch (err) {
      showDownloadError();
      const msg = `failed to download image for selector: ${elementSelector} and fileName: ${fileName}`;
      diagnostics.error(msg, err as Error);
    } finally {
      setLoading(false);
      onAfterDownload?.();
    }
  }

  if (hideWhenInteractive && isInteractive) return null;

  return (
    <Tooltip
      placement="top"
      title={
        <Typography variant="subtitle2">{tooltipText ?? 'Download'}</Typography>
      }
    >
      <ControlButton
        variant={variant}
        onClick={onClick ?? defaultOnClick}
        square={!children}
        aria-label={tooltipText ?? 'Download'}
        loading={loading}
        {...props}
      >
        {children ?? <Download01Icon size={20} />}
      </ControlButton>
    </Tooltip>
  );
};
