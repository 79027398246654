// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { GrowthProfileModal_AssetClassFragmentDoc } from '../../GrowthProfileModal/graphql/GrowthProfileModal.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomAssetCategoryGrowthModal_AssetClassFragment = { __typename?: 'AssetClass', id: string, displayName: string, growthRate?: Percent | null };

export type CustomAssetCategoryGrowthModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CustomAssetCategoryGrowthModalQuery = { __typename?: 'Query', assetClasses: { __typename?: 'AssetClassConnection', edges?: Array<{ __typename?: 'AssetClassEdge', node?: { __typename?: 'AssetClass', id: string, displayName: string, growthRate?: Percent | null } | null } | null> | null } };

export const CustomAssetCategoryGrowthModal_AssetClassFragmentDoc = gql`
    fragment CustomAssetCategoryGrowthModal_AssetClass on AssetClass {
  ...GrowthProfileModal_AssetClass
}
    ${GrowthProfileModal_AssetClassFragmentDoc}`;
export const CustomAssetCategoryGrowthModalDocument = gql`
    query CustomAssetCategoryGrowthModal {
  assetClasses {
    edges {
      node {
        ...CustomAssetCategoryGrowthModal_AssetClass
      }
    }
  }
}
    ${CustomAssetCategoryGrowthModal_AssetClassFragmentDoc}`;

/**
 * __useCustomAssetCategoryGrowthModalQuery__
 *
 * To run a query within a React component, call `useCustomAssetCategoryGrowthModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomAssetCategoryGrowthModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomAssetCategoryGrowthModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomAssetCategoryGrowthModalQuery(baseOptions?: Apollo.QueryHookOptions<CustomAssetCategoryGrowthModalQuery, CustomAssetCategoryGrowthModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomAssetCategoryGrowthModalQuery, CustomAssetCategoryGrowthModalQueryVariables>(CustomAssetCategoryGrowthModalDocument, options);
      }
export function useCustomAssetCategoryGrowthModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomAssetCategoryGrowthModalQuery, CustomAssetCategoryGrowthModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomAssetCategoryGrowthModalQuery, CustomAssetCategoryGrowthModalQueryVariables>(CustomAssetCategoryGrowthModalDocument, options);
        }
export function useCustomAssetCategoryGrowthModalSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomAssetCategoryGrowthModalQuery, CustomAssetCategoryGrowthModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomAssetCategoryGrowthModalQuery, CustomAssetCategoryGrowthModalQueryVariables>(CustomAssetCategoryGrowthModalDocument, options);
        }
export type CustomAssetCategoryGrowthModalQueryHookResult = ReturnType<typeof useCustomAssetCategoryGrowthModalQuery>;
export type CustomAssetCategoryGrowthModalLazyQueryHookResult = ReturnType<typeof useCustomAssetCategoryGrowthModalLazyQuery>;
export type CustomAssetCategoryGrowthModalSuspenseQueryHookResult = ReturnType<typeof useCustomAssetCategoryGrowthModalSuspenseQuery>;
export type CustomAssetCategoryGrowthModalQueryResult = Apollo.QueryResult<CustomAssetCategoryGrowthModalQuery, CustomAssetCategoryGrowthModalQueryVariables>;
export const Operations = {
  Query: {
    CustomAssetCategoryGrowthModal: 'CustomAssetCategoryGrowthModal' as const
  },
  Fragment: {
    CustomAssetCategoryGrowthModal_AssetClass: 'CustomAssetCategoryGrowthModal_AssetClass' as const
  }
}