import { useParams } from 'react-router-dom';

import * as diagnostics from '@/utils/diagnostics';

import { ERROR_CONTENT, ErrorCodes, ErrorContent } from './constants';
import { ErrorOverlay } from './ErrorOverlay';

function getValidatedErrorCode(errorCode?: string): ErrorCodes {
  const errorCodeValues = Object.values(ErrorCodes) as string[];
  if (!errorCode || !errorCodeValues.includes(errorCode)) {
    diagnostics.error(
      'Failed error code lookup',
      new Error(
        `No error code mapping found for ${errorCode}; defaulting to ${ErrorCodes.UNKNOWN}`
      )
    );
    return ErrorCodes.UNKNOWN;
  }

  return errorCode as ErrorCodes;
}

function getContentFromErrorCode(errorCode?: string): ErrorContent {
  const validatedErrorCode = getValidatedErrorCode(errorCode);
  return ERROR_CONTENT[validatedErrorCode];
}

export function PublicErrorPage() {
  const { errorCode } = useParams();
  const { heading, body } = getContentFromErrorCode(errorCode);

  return <ErrorOverlay heading={heading} body={body} />;
}
