import { Control } from 'react-hook-form';

import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { TrusteeDutyKind } from '@/types/schema';

import { NotesContainer } from './NotesContainer';
import {
  Fields,
  SubformField,
  TRUST_DETAILS_SUBFORM_NAMESPACE,
  TrustDetailsSubformType,
  TrustDetailsSubformType_TrusteesAndSuccessorTrustees,
} from './TrustDetailsSubform.types';

interface Props {
  control: Control<Fields>;
  subformValues:
    | TrustDetailsSubformType
    | TrustDetailsSubformType_TrusteesAndSuccessorTrustees;
  trusteeOptions: SelectInputProps['options'];
}

export function TrusteeNotes({
  control,
  subformValues,
  trusteeOptions,
}: Props) {
  return (
    <NotesContainer>
      <>
        {subformValues?.trustees.map((trustee, i) => {
          const fieldName =
            `${TRUST_DETAILS_SUBFORM_NAMESPACE}.trustees.${i}.note` as const satisfies SubformField;
          const isSpecialDuty =
            trustee.duties?.includes(TrusteeDutyKind.Special) ?? false;

          const name = (
            trusteeOptions.find((option) => {
              if ('value' in option) {
                return option.value === trustee.trusteeId;
              }
              return false;
            }) as { display: string; value: string } | undefined
          )?.display;

          const shouldShowNotes = isSpecialDuty && !!name;

          const label = `Duties for ${name}`;

          if (shouldShowNotes) {
            return (
              <FormAwareTextInput<Fields>
                key={fieldName}
                control={control}
                fieldName={fieldName}
                label={label}
                multiline
                rows={2}
              />
            );
          }

          return null;
        })}
      </>
    </NotesContainer>
  );
}
