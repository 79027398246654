import { useState } from 'react';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { Upload01Icon } from '@/components/icons/Upload01Icon';

import { RichDocumentUploader } from '../../RichDocumentUploader/RichDocumentUploader';

interface DocumentUploadButtonProps extends Partial<ButtonProps> {
  householdId: string;
  entityId?: string;
  onModalClose?: (documentId?: string) => void;
  additionalUploadDetails?: React.ReactNode;
}

export function DocumentUploadButton({
  onModalClose,
  householdId,
  entityId,
  additionalUploadDetails,
  ...props
}: DocumentUploadButtonProps) {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  function handleModalClose(documentId?: string) {
    setUploadModalOpen(false);
    onModalClose?.(documentId);
  }

  return (
    <>
      <RichDocumentUploader
        variant="default"
        documentId={null}
        householdId={householdId}
        entityId={entityId}
        isOpen={uploadModalOpen}
        onClose={handleModalClose}
        additionalDetails={additionalUploadDetails}
      />
      <Button
        onClick={() => setUploadModalOpen(true)}
        variant="primary"
        size="sm"
        startIcon={Upload01Icon}
        {...props}
      >
        Upload document
      </Button>
    </>
  );
}
