import { cx } from '@emotion/css';
import { Box } from '@mui/material';
import { Node, NodeProps, Position } from '@xyflow/react';
import { useMemo } from 'react';

import { Omit } from '@/components/charts/Legend/Legend.stories';
import { EditButton } from '@/components/form/baseInputs/Button/EditButton';
import { useModalState } from '@/hooks/useModalState';
import { ManageGroupModal } from '@/modules/estateWaterfall/waterfallGraph/ManageGroupModal';
import { AfterDeath } from '@/types/schema';

import { Handle, HandleProps } from '../../components/Handle';
import { Tile, TileProps } from '../../components/Tile';
import { useFlowChartContext } from '../context/flowChart.context';
import { useCursorStyles } from '../hooks/useCursorStyles';
import { useDraggingNode } from '../hooks/useDraggingNode';
import { useIsInteractive } from '../hooks/useIsInteractive';
import {
  NodeRankAttrs,
  SectionLabelNodeChildAttrs,
  TileNodeGroupAttrs,
} from '../types';

type TileNodeGroupData = Record<string, unknown> &
  /**
   * isSelected is omitted because we compute it based on internal react flow props in this component
   */
  Omit<TileProps, 'isSelected'> &
  NodeRankAttrs &
  SectionLabelNodeChildAttrs &
  TileNodeGroupAttrs;

export type TileNodeGroupProps = NodeProps<Node<TileNodeGroupData>>;

const AllPositions = Object.values(Position);

export function TileNodeGroup({
  id,
  data: { classes: tileClasses = {}, ...props },
  selected,
  isConnectable,
  dragging,
}: TileNodeGroupProps) {
  const { root, ...classes } = tileClasses;
  const { group, groupedTiles } = props;
  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();
  const { isInteractive } = useIsInteractive();
  const { dropTargetNode } = useFlowChartContext();
  const { draggingNode } = useDraggingNode();
  const { cursorClassName } = useCursorStyles(id);
  const commonHandleProps: Partial<HandleProps> = {
    isConnectable,
    hidden: true,
  };
  const isGrouped = id === dropTargetNode?.id && !!draggingNode;

  const GroupEditButton = useMemo(() => {
    return function GroupEditButton() {
      return (
        // Use visibility to preserve the spacing taken up by the button when it is hidden as to not impact the layout
        <Box visibility={isInteractive ? 'visible' : 'hidden'}>
          <EditButton size="sm" onClick={openModal} variant="transparent" />
        </Box>
      );
    };
  }, [isInteractive, openModal]);

  return (
    <>
      {isInteractive && isModalOpen && (
        <ManageGroupModal
          isOpen={isModalOpen}
          groupName={group.displayName}
          groupDescription={group.description}
          onClose={closeModal}
          namespace={group.namespace as AfterDeath}
          groupedTiles={groupedTiles}
          groupId={group.id}
        />
      )}
      <Tile
        classes={{
          root: cx(cursorClassName, root),
          ...classes,
        }}
        dragging={dragging}
        isSelected={dragging || selected}
        slots={{
          EditButton: <GroupEditButton />,
        }}
        isGrouped={isGrouped}
        {...props}
      />
      {/* Build source/target handles for each four corners of the tile square */}
      {AllPositions.map((position) => (
        <Handle
          key={`target-${position}`}
          type="target"
          {...commonHandleProps}
          id={position}
          position={position}
        />
      ))}
      {/* Build source/target handles for each four corners of the tile square */}
      {AllPositions.map((position) => (
        <Handle
          key={`source-${position}`}
          type="source"
          {...commonHandleProps}
          id={position}
          position={position}
        />
      ))}
    </>
  );
}
