import { Box, BoxProps, SxProps, Theme, Typography } from '@mui/material';
import { PropsWithChildren, useMemo } from 'react';

import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';

export interface SidebarTabProps extends BoxProps {
  onClick: () => void;
  isActive: boolean;
}

/**
 * @description This is a presentational component that represents a large, horizontal tab in the sidebar.
 */
export function SidebarTab({
  isActive,
  onClick,
  children,
  ...boxProps
}: PropsWithChildren<SidebarTabProps>) {
  const containerSx = useMemo(() => getSidebarTabStyles(isActive), [isActive]);

  return (
    <Box sx={containerSx}>
      {/* this element should be clickable and tabbable, but have no button styling or anything else */}
      <Box role="button" tabIndex={0} onClick={onClick} {...boxProps}>
        <Typography
          sx={{
            py: 2,
            px: 2,
            color: COLORS.NAVY[900],
            fontWeight: FONT_WEIGHTS.bold,
          }}
        >
          {children}
        </Typography>
      </Box>
    </Box>
  );
}
function getSidebarTabStyles(isActive: boolean): SxProps<Theme> {
  const baseStyle = {
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: COLORS.GRAY[200],
    borderRight: '8px solid',
    borderRightColor: 'transparent',
    cursor: 'pointer',
    '&:last-child': { borderBottom: 'none' },
    '&:hover': {
      backgroundColor: COLORS.FUNCTIONAL.HOVER,
    },
  };

  if (!isActive) return baseStyle;
  return Object.assign(baseStyle, {
    borderRightColor: COLORS.NAVY[600],
    background: COLORS.FUNCTIONAL.HOVER,
  });
}
