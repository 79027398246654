import { Box, Stack, Typography } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';
import { COLORS } from '@/styles/tokens/colors';

interface DesignSummarySectionProps {
  heading: string;
  onEditClick?: () => void;
}

export function DesignSummarySection({
  heading,
  onEditClick,
  children,
}: React.PropsWithChildren<DesignSummarySectionProps>) {
  return (
    <Box component="section">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        px={1.5}
        py={0.5}
        mb={2}
        component="header"
        borderBottom={`solid ${COLORS.ORANGE[500]} 1px`}
      >
        <Typography component="h2" variant="h2">
          {heading}
        </Typography>
        {onEditClick && (
          <Button
            variant="transparent"
            size="sm"
            startIcon={Edit02Icon}
            onClick={onEditClick}
          >
            Edit
          </Button>
        )}
      </Stack>
      <Box px={2}>{children}</Box>
    </Box>
  );
}

interface AnnuityPaymentsTableProps {
  heading: string;
  rows: {
    label: string;
    value: string;
  }[];
}

export function AnnuityPaymentsTable({
  heading,
  rows,
}: AnnuityPaymentsTableProps) {
  return (
    <Box component="section">
      <Box
        component="header"
        py={1.5}
        px={1}
        sx={{ borderBottom: `solid ${COLORS.GRAY[600]} 1px` }}
      >
        <Typography variant="h6">{heading}</Typography>
      </Box>
      {rows.map((row, i) => {
        const isLastItem = i === rows.length - 1;
        return (
          <Stack
            key={i}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            px={1.5}
            py={1}
            sx={{
              borderBottom: isLastItem
                ? undefined
                : `solid ${COLORS.GRAY[200]} 1px`,
            }}
          >
            <Typography variant="h5" component="span">
              {row.label}
            </Typography>
            <Typography variant="body1" component="span">
              {row.value}
            </Typography>
          </Stack>
        );
      })}
    </Box>
  );
}

interface EntityItemRowProps {
  heading: string;
  description: string;
  additionalItems: string[];
  value: string;
  valueContext?: string;
  badgeText?: string;
}

export function EntityItemRow({
  heading,
  description,
  additionalItems,
  value,
  valueContext,
  badgeText,
}: EntityItemRowProps) {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <RichListItem
        heading={heading}
        description={description}
        additionalItems={additionalItems}
        badgeText={badgeText}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        minWidth={200}
      >
        <Box textAlign="right">
          <Typography variant="body1" component="div">
            {value}
          </Typography>
          {valueContext && (
            <Typography variant="subtitle2" component="div">
              {valueContext}
            </Typography>
          )}
        </Box>
      </Stack>
    </Stack>
  );
}
