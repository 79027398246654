import { Describe, mask, validate } from 'superstruct';

import { diagnostics } from '@/utils/diagnostics';

export function maskInput<T>(input: T, schema: Describe<T>): T {
  const [err, _validatedInput] = validate(input, schema);

  if (err) {
    diagnostics.warn('Form input does not match expected schema', {
      errors: err.failures(),
    });
  }

  return mask(input, schema);
}

export function $getValidatedInput<T>(
  input: T,
  schema: Describe<T>,
  typeDescriptor: string
): T {
  try {
    // first, mask the input so we remove any fields that are not specified in the schema
    const maskedInput = maskInput(input, schema);

    // validate against schema
    const [err, validatedInput] = schema.validate(maskedInput);

    if (err) {
      // log all validation failures
      err.failures().forEach((failure) => {
        diagnostics.debug(
          `${typeDescriptor} trust input validation failure at`,
          failure
        );
      });

      throw err;
    }

    return validatedInput;
  } catch (err) {
    diagnostics.error(
      `Failed to make ${typeDescriptor} trust input`,
      err as Error
    );
    throw new Error(`Could not validate input for ${typeDescriptor} trust`);
  }
}
