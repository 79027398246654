import { Stack } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { FormModal } from '@/components/modals/FormModal/FormModal';

import { MultiDocumentUploaderWithList } from './MultiDocumentUploaderWithList';

export interface MultiDocumentUploaderWithListModalProps {
  onClose: () => void;
  onSubmit: () => Promise<void>;
  isOpen: boolean;
  householdId: string;
  uploaderTextHeader: string;
  uploaderTextSubheader: string;
  loading?: boolean;
}

export function MultiDocumentUploaderWithListModal({
  onClose,
  onSubmit,
  isOpen,
  householdId,
  uploaderTextHeader,
  uploaderTextSubheader,
  loading,
}: MultiDocumentUploaderWithListModalProps) {
  return (
    <FormModal
      onClose={onClose}
      isOpen={isOpen}
      heading="Upload documents"
      actions={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            variant="secondary"
            onClick={onClose}
            size={'sm'}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={onSubmit}
            size={'sm'}
            loading={loading}
          >
            Save documents
          </Button>
        </Stack>
      }
    >
      <MultiDocumentUploaderWithList
        householdId={householdId}
        uploaderTextHeader={uploaderTextHeader}
        uploaderTextSubheader={uploaderTextSubheader}
        hideAIUploaderFrame
      />
    </FormModal>
  );
}
