import { Box, Stack, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

import {
  Section,
  StackedHorizontalBar,
} from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';
import { GiftCard } from '@/modules/gifting/GiftCard';
import { COLORS } from '@/styles/tokens/colors';

export interface DataCardProps {
  sections: Section[];
  label: string;
  displayValue?: string;
  cardSx?: SxProps<Theme>;
  tooltip?: ReactNode;
  menuItems?: JSX.Element[];
  isMenuOpen?: boolean;
  updating?: boolean;
}

export function GiftDataCard({
  sections,
  label,
  displayValue,
  cardSx,
  tooltip,
  menuItems,
  isMenuOpen,
  updating,
}: DataCardProps) {
  return (
    <GiftCard sx={cardSx}>
      {menuItems?.length && (
        <Stack direction="row" flexDirection="row-reverse" position="relative">
          <Box position="absolute" zIndex={2}>
            <ContextMenuButton variant="transparent" isOpen={isMenuOpen}>
              {menuItems}
            </ContextMenuButton>
          </Box>
        </Stack>
      )}
      <Box flex={1} sx={{ position: 'relative' }}>
        <LoadingOverlay
          open={!!updating}
          updatingText="Updating exemption..."
        />
        <Stack spacing={2}>
          <OversizedMetricItem
            title={label}
            value={displayValue}
            tooltip={tooltip}
            titleVariant="h4"
            valueVariant="h1"
            valueColor={COLORS.NAVY[600]}
            valueLabel="available"
          />
          <StackedHorizontalBar sections={sections} variant="legend" />
        </Stack>
      </Box>
    </GiftCard>
  );
}
