import { DesktopDatePicker } from '@mui/x-date-pickers';
import { isValid } from 'date-fns';
import { useEffect } from 'react';

import { COLORS } from '@/styles/tokens/colors';
import { noonifyDate } from '@/utils/dateUtils';

import { FormControl } from '../FormControl';
import {
  BaseDatePickerInputProps,
  FormControlDatePickerInputProps,
  HelpTextVariant,
} from '../inputTypes';
import { BaseTextInput } from '../TextInput/TextInput';

interface DatePickerInputProps extends FormControlDatePickerInputProps {
  label: string;
  hideLabel?: boolean;
  id?: string;
  // the presence of contextualHelp indicates that there's help text related to this input, which
  // will cause the "info" icon to show up next to the input label
  contextualHelp?: JSX.Element;
  errorMessage?: string;
  helpText?: string;
  helpTextVariant?: HelpTextVariant;
  labelIconEnd?: React.ReactNode;
}

export function BaseDatePickerInput({
  ...inputProps
}: BaseDatePickerInputProps) {
  const { disabled, onChange, value } = inputProps;
  const disabledStyle = disabled ? { background: COLORS.GRAY[50] } : {};
  const mergedSx = Object.assign({ background: 'white' }, disabledStyle);

  // *** IMPORTANT ***
  // This effect updates the date's time to noon at UTC.
  // This allows date picker dates to be consistent across timezones.

  // Without this, the date picker will default to the user's local time, which
  // means date behavior is inconsistent across timezones, and could result in
  // two US timezones showing different dates for the same thing. For example,
  // if a user selected a date when it's 12:01am in the US Eastern timezone, the date
  // whould be saved as 5:01am UTC. If a user in the US Pacific timezone
  // were to view that date, it would show as the previous day, because it's
  // still 9:01pm the previous day in the Pacific timezone.

  // This effect ensures that the date is always saved as noon UTC, which means
  // we support MOST timezones, and the date will be consistent across timezones.
  // Timezone support is documented in the test here src/utils/dateUtils.test.ts
  useEffect(
    function noonifyDateValue() {
      if (!value) {
        return;
      }

      // if value is not noon, set the value to noon
      if (value.getUTCHours() !== 12 && isValid(value)) {
        const noonDate = noonifyDate(value);
        if (!isValid(noonDate) || noonDate.getTime() === value.getTime()) {
          return;
        }

        setTimeout(() => {
          onChange(noonDate, { shouldDirty: false });
        }, 0);
      }
    },
    [onChange, value]
  );

  return (
    <DesktopDatePicker
      sx={mergedSx}
      inputFormat="MM/dd/yyyy"
      {...inputProps}
      onChange={(date) => {
        onChange(date, { shouldDirty: true });
      }}
      InputProps={{
        sx: mergedSx,
      }}
      disableHighlightToday={true}
      renderInput={(params) => {
        // destructuring these params off because they aren't required and
        // the types don't match up
        const {
          onKeyDown: _onKeyDown,
          onKeyUp: _onKeyUp,
          rows: _rows,
          maxRows: _maxRows,
          ...inputParams
        } = params;
        return (
          <BaseTextInput
            id={inputProps.id}
            placeholder="MM/DD/YYYY"
            {...inputParams}
            // for some reason, inputParams comes through with `error: false` even when `error: true`
            // is passed to the parent input.
            error={inputProps.error}
            testId={`date-input-${inputProps.name ?? ''}`}
          />
        );
      }}
    />
  );
}

export function DatePickerInput({
  label,
  hideLabel,
  contextualHelp,
  errorMessage,
  helpText,
  helpTextVariant,
  labelIconEnd,
  ...inputProps
}: DatePickerInputProps) {
  return (
    <FormControl<BaseDatePickerInputProps>
      component={BaseDatePickerInput}
      inputProps={inputProps}
      errorMessage={errorMessage}
      contextualHelp={contextualHelp}
      required={inputProps.required}
      helpText={helpText}
      helpTextVariant={helpTextVariant}
      id={inputProps.id}
      label={label}
      hideLabel={hideLabel}
      labelIconEnd={labelIconEnd}
    />
  );
}
