import { Maybe } from 'graphql/jsutils/Maybe';

interface Node<T> {
  node?: Maybe<T>;
}

export type EdgeOf<T> = Maybe<Maybe<Node<T>>[]>;

export function getGuardedNodeType<
  QueryType extends { __typename?: unknown } | undefined | null,
  ReturnValueType extends { __typename?: string },
>(
  value: QueryType | undefined | null,
  typeName: ReturnValueType['__typename']
) {
  if (!value || value.__typename !== typeName) return null;
  return value as unknown as ReturnValueType;
}
