import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { $createHeadingNode } from '@lexical/rich-text';
import { $setBlocksType } from '@lexical/selection';
import { Box, Stack, Typography } from '@mui/material';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from 'lexical';
import { ComponentProps, ComponentType, useCallback, useMemo } from 'react';
import { usePreviousDistinct } from 'react-use';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { IconProps } from '@/components/icons/Loading02Icon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';

import {
  Block,
  blockTypeToBlockIcon,
  blockTypeToBlockName,
  iconProps,
} from './ToolbarPlugin.utils';

interface DropdownItemProps {
  icon: ComponentType<IconProps>;
  label: string;
  typographyProps: ComponentProps<typeof Typography>;
}

function DropdownItem({
  icon: Icon,
  label,
  typographyProps,
}: DropdownItemProps) {
  return (
    <Stack direction="row" spacing={1} flex={1} width="100%">
      <Box display="flex">
        <Icon {...iconProps} />
      </Box>
      <Typography variant="body1" {...typographyProps}>
        {label}
      </Typography>
    </Stack>
  );
}

interface BlockOptionsDropdownListProps {
  editor: LexicalEditor;
  blockType: Block;
}

export function BlockOptionsDropdown({
  editor,
  blockType,
}: BlockOptionsDropdownListProps) {
  const formatParagraph = useCallback(() => {
    if (blockType !== 'paragraph') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createParagraphNode());
        }
      });
    }
  }, [blockType, editor]);

  const formatLargeHeading = useCallback(() => {
    if (blockType !== 'h1') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode('h1'));
        }
      });
    }
  }, [blockType, editor]);

  const formatSmallHeading = useCallback(() => {
    if (blockType !== 'h2') {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode('h2'));
        }
      });
    }
  }, [blockType, editor]);

  const formatBulletList = useCallback(() => {
    if (blockType !== 'ul') {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }, [blockType, editor]);

  const formatNumberedList = useCallback(() => {
    if (blockType !== 'ol') {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
    }
  }, [blockType, editor]);

  const getTypographyProps = useCallback(
    (kind: string) => {
      return {
        sx: {
          fontWeight: kind === blockType ? 700 : 400,
        },
      };
    },
    [blockType]
  );

  const prevBlockType = usePreviousDistinct(blockType);

  const label = useMemo(() => {
    return (
      blockTypeToBlockName[blockType] ??
      (prevBlockType ? blockTypeToBlockName[prevBlockType] : 'Body')
    );
  }, [blockType, prevBlockType]);

  const IconComponent = useMemo(() => {
    if (!(blockType in blockTypeToBlockIcon)) {
      return blockTypeToBlockIcon.paragraph;
    }

    return (
      blockTypeToBlockIcon[blockType] ??
      (prevBlockType
        ? blockTypeToBlockIcon[prevBlockType]
        : blockTypeToBlockIcon.paragraph)
    );
  }, [blockType, prevBlockType]);

  const items = useMemo(() => {
    return [
      {
        label: blockTypeToBlockName.h1,
        component: (
          <DropdownItem
            icon={blockTypeToBlockIcon.h1}
            label={blockTypeToBlockName.h1}
            typographyProps={getTypographyProps('h1')}
          />
        ),
        clickHandler: formatLargeHeading,
      },
      {
        label: blockTypeToBlockName.h2,
        component: (
          <DropdownItem
            icon={blockTypeToBlockIcon.h2}
            label={blockTypeToBlockName.h2}
            typographyProps={getTypographyProps('h2')}
          />
        ),
        clickHandler: formatSmallHeading,
      },
      {
        label: blockTypeToBlockName.paragraph,
        component: (
          <DropdownItem
            icon={blockTypeToBlockIcon.paragraph}
            label={blockTypeToBlockName.paragraph}
            typographyProps={getTypographyProps('paragraph')}
          />
        ),
        clickHandler: formatParagraph,
      },
      {
        label: blockTypeToBlockName.ul,
        component: (
          <DropdownItem
            icon={blockTypeToBlockIcon.ul}
            label={blockTypeToBlockName.ul}
            typographyProps={getTypographyProps('ul')}
          />
        ),
        clickHandler: formatBulletList,
      },
      {
        label: blockTypeToBlockName.ol,
        component: (
          <DropdownItem
            icon={blockTypeToBlockIcon.ol}
            label={blockTypeToBlockName.ol}
            typographyProps={getTypographyProps('ol')}
          />
        ),
        clickHandler: formatNumberedList,
      },
    ];
  }, [
    formatBulletList,
    formatLargeHeading,
    formatNumberedList,
    formatParagraph,
    formatSmallHeading,
    getTypographyProps,
  ]);

  return (
    <ButtonWithPopover
      sx={{
        width: '240px',
      }}
      contentBoxSx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }}
      size="sm"
      variant="secondary-filled"
      popperVariant="menuBelow"
      startIcon={IconComponent || undefined}
      label={
        <Typography
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {label}
        </Typography>
      }
    >
      {items.map((o) => (
        <MenuItem
          key={o.label}
          justifyContent="space-between"
          label={o.component}
          onClick={o.clickHandler}
          sx={{ width: '240px' }}
        />
      ))}
    </ButtonWithPopover>
  );
}
