import { Box, Stack } from '@mui/material';
import { lowerFirst } from 'lodash';
import { useCallback, useContext, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import { RepeaterInputShape } from '@/components/form/formAwareInputs/formAwareRepeater/FormAwareRepeater.types';
import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { CheckIcon } from '@/components/icons/CheckIcon';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { XIcon } from '@/components/icons/XIcon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { ClientProfileModal } from '@/modules/clientProfiles/ClientProfileForm/ClientProfileModal';
import { getRoleKindLabel } from '@/modules/professionalTeam/ClientProfessionalTeam.copy';
import {
  ClientProfessionalTeamForm,
  ClientProfessionalTeamMemberRoleKey,
  ClientProfessionalTeamRole,
  getEmptyRoleForKind,
} from '@/modules/professionalTeam/ClientProfessionalTeam.types';
import { ProfessionalTeamRoleKind } from '@/types/schema';
import { PathsOf } from '@/types/subform';

import { POWER_OF_ATTORNEY_OPTIONS } from './ClientProfessionalTeam.constants';
import { ClientProfessionalTeamContext } from './ClientProfessionalTeam.context';

interface CreateCustomRoleProps {
  fieldName: PathsOf<ClientProfessionalTeamForm>;
  roleKey: ClientProfessionalTeamMemberRoleKey;
  path: 'advisorClientRoles' | `principalClientIdTeamMemberMap.${string}`;
}

function CreateCustomRole({ fieldName, roleKey, path }: CreateCustomRoleProps) {
  const { control, setValue, getValues } =
    useFormContext<ClientProfessionalTeamForm>();
  const { teamMemberOptions } = useContext(ClientProfessionalTeamContext);

  const onCancel = useCallback(() => {
    const currentValues = getValues(path);
    if (currentValues[roleKey]) {
      delete currentValues[roleKey];
      setValue(path, currentValues);
    }
  }, [getValues, path, roleKey, setValue]);

  const onSubmit = useCallback(() => {
    setValue(`${path}.${roleKey}.isJustCreated`, false);
  }, [path, roleKey, setValue]);

  return (
    <Card variant="filled" sx={{ p: 3 }}>
      <Stack direction="row" spacing={2}>
        <Box flex={'1 1 50%'}>
          <FormAwareTextInput<ClientProfessionalTeamForm>
            fieldName={`${fieldName}.otherRoleName`}
            control={control}
            label="Role name"
          />
        </Box>
        <Box flex={'1 1 50%'}>
          <FormAwareSelectInput<ClientProfessionalTeamForm>
            control={control}
            fieldName={`${fieldName}.members.0.memberId`}
            options={teamMemberOptions}
            label="Person"
          />
        </Box>
        <Box sx={{ alignSelf: 'end', justifySelf: 'end' }}>
          <Stack direction="row" alignContent="flex-end" spacing={0.5}>
            <Button
              variant="primary"
              size="sm"
              onClick={onSubmit}
              sx={{ width: 40, height: 40 }}
            >
              <CheckIcon />
            </Button>
            <Button
              variant="secondary"
              size="sm"
              onClick={onCancel}
              sx={{ width: 40, height: 40 }}
            >
              <XIcon />
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
}

export interface ClientProfessionalTeamRoleCardProps {
  role: ClientProfessionalTeamRole;
  roleKey: ClientProfessionalTeamMemberRoleKey;
  pathToRoleList:
    | 'advisorClientRoles'
    | `principalClientIdTeamMemberMap.${string}`;
}

export function ClientProfessionalTeamRoleCard({
  role,
  roleKey,
  pathToRoleList,
}: ClientProfessionalTeamRoleCardProps) {
  const { control } = useFormContext<ClientProfessionalTeamForm>();
  const { teamMemberOptions, householdId } = useContext(
    ClientProfessionalTeamContext
  );
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const displayName = getRoleKindLabel(role);
  const roleFieldName =
    `${pathToRoleList}.${roleKey}` as const satisfies PathsOf<ClientProfessionalTeamForm>;
  const isOtherRole = role.kind === ProfessionalTeamRoleKind.Other;

  const currentMembers = useWatch({
    name: `${roleFieldName}.members`,
    control,
  });

  if (role.isJustCreated) {
    return (
      <CreateCustomRole
        fieldName={roleFieldName}
        roleKey={roleKey}
        path={pathToRoleList}
      />
    );
  } else if (!currentMembers.length && isOtherRole) {
    return null;
  }
  return (
    <>
      <FormAwareInputRepeater<ClientProfessionalTeamForm>
        control={control}
        name={`${roleFieldName}.members`}
        addAnotherItemText={`Add additional ${lowerFirst(displayName)}`}
        shouldInit
        allowEmpty={isOtherRole}
        emptyValue={
          { memberId: '' } as RepeaterInputShape<ClientProfessionalTeamForm>
        }
        render={(i: number) => {
          const isFirstInput = i === 0;
          const isPowerOfAttorney =
            role.kind === ProfessionalTeamRoleKind.PowerOfAttorney;

          return (
            <FormLayoutRow>
              <FormLayoutItem width={isPowerOfAttorney ? 9 : 12}>
                <FormAwareSelectInput<ClientProfessionalTeamForm>
                  disableAutomaticNewOptionSelection
                  control={control}
                  options={teamMemberOptions}
                  label={displayName}
                  fieldName={`${roleFieldName}.members.${i}.memberId`}
                  hideLabel={!isFirstInput}
                  emptyValueDisplay="None"
                  addNewOption={{
                    onClick: () => setModalOpen(true),
                    icon: <PlusIcon />,
                    text: 'Add new individual',
                  }}
                />
              </FormLayoutItem>
              {isPowerOfAttorney && (
                <FormLayoutItem width={3}>
                  <FormAwareSelectInput<ClientProfessionalTeamForm>
                    disableAutomaticNewOptionSelection
                    control={control}
                    options={POWER_OF_ATTORNEY_OPTIONS}
                    label="Category"
                    fieldName={`${roleFieldName}.members.${i}.powerOfAttorneyKind`}
                    hideLabel={!isFirstInput}
                  />
                </FormLayoutItem>
              )}
            </FormLayoutRow>
          );
        }}
      />
      <ClientProfileModal
        isOpen={isModalOpen}
        setIsOpen={setModalOpen}
        householdId={householdId}
        clientProfileId={null}
        clientProfileTypeDisplay={'individual'}
        forceBeneficiaryIfNoClientProfileId={false}
      />
    </>
  );
}

export interface ClientProfessionalTeamAddCustomRoleButtonProps {
  path: 'advisorClientRoles' | `principalClientIdTeamMemberMap.${string}`;
}
export function ClientProfessionalTeamAddCustomRoleButton({
  path,
}: ClientProfessionalTeamAddCustomRoleButtonProps) {
  const { getValues, setValue } = useFormContext<ClientProfessionalTeamForm>();

  const onClick = useCallback(() => {
    const newRoleKey: ClientProfessionalTeamMemberRoleKey = `OTHER-${
      Object.keys(getValues(path)).length
    }`;
    setValue(
      `${path}.${newRoleKey}`,
      getEmptyRoleForKind(ProfessionalTeamRoleKind.Other, {
        isJustCreated: true,
      })
    );
  }, [getValues, path, setValue]);
  return (
    <Button
      variant="secondary"
      size="sm"
      startIcon={PlusIcon}
      onClick={onClick}
    >
      Add a custom role
    </Button>
  );
}
