import { Box, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { To } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { LinkExternal01Icon } from '@/components/icons/LinkExternal01Icon';
import { XCircleIcon } from '@/components/icons/XCircleIcon';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';

import { Badge, BadgeVariants } from '../../notifications/Badge/Badge';

export interface RichListItemProps {
  heading: string;
  description?: string;
  additionalItems?: ReactNode[];
  badge?: ReactNode;
  badgeText?: string;
  badgeTextCallout?: string;
  Tooltip?: JSX.Element;
  remove?: () => void;
  link?: To;
}

/**
 * @description RichListItem is a standardized way to display some set of context, and isn't specific
 * to the platform-level concept of an Entity.
 */
export function RichListItem({
  heading,
  description,
  additionalItems,
  badge,
  badgeText,
  badgeTextCallout,
  Tooltip,
  link,
  remove,
}: RichListItemProps) {
  const isViewOnly = useViewOnly();
  return (
    <Stack direction="row" spacing={1}>
      {/* breakInside used to avoid content inside here from splitting up between pages and columns */}
      <Box sx={{ breakInside: 'avoid' }}>
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Typography
            variant="h5"
            component="div"
            sx={{ overflowWrap: 'anywhere' }}
          >
            {heading}
          </Typography>
          {(!!badgeText || !!badgeTextCallout || !!badge) && (
            <Stack alignItems="center" spacing={0.5} direction="row" ml={3}>
              {badgeText && (
                <Badge
                  variant={BadgeVariants.Gray}
                  display={badgeText}
                  textTransform="uppercase"
                />
              )}
              {badgeTextCallout && (
                <Badge
                  variant={BadgeVariants.Yellow}
                  display={badgeTextCallout}
                  textTransform="uppercase"
                />
              )}
              {badge}
            </Stack>
          )}
          {Tooltip && <>{Tooltip}</>}
          <Box flexGrow={1} />
          {remove && (
            <Box display="flex">
              <Button
                variant="transparent"
                size="sm"
                aria-label="Remove"
                onClick={() => remove()}
                sx={{ padding: 0 }}
              >
                <XCircleIcon size={20} />
              </Button>
            </Box>
          )}
        </Stack>
        {description && (
          <Typography variant="subtitle2" component="div">
            {description}
          </Typography>
        )}
        {additionalItems?.map((item, i) => (
          <Typography key={i} variant="body1" component="div">
            {item}
          </Typography>
        ))}
      </Box>
      {link && !isViewOnly && (
        <Box display="flex">
          <Button href={link} variant="transparent" size="xs">
            <LinkExternal01Icon size={18} />
          </Button>
        </Box>
      )}
    </Stack>
  );
}
