// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SeedAiSuggestionsMutationVariables = Types.Exact<{
  entityID: Types.Scalars['ID']['input'];
}>;


export type SeedAiSuggestionsMutation = { __typename?: 'Mutation', seedEntityWithAISuggestions: { __typename?: 'Entity', id: string, household: { __typename?: 'Household', id: string } } };


export const SeedAiSuggestionsDocument = gql`
    mutation SeedAISuggestions($entityID: ID!) {
  seedEntityWithAISuggestions(input: {entityID: $entityID}) {
    id
    household {
      id
    }
  }
}
    `;
export type SeedAiSuggestionsMutationFn = Apollo.MutationFunction<SeedAiSuggestionsMutation, SeedAiSuggestionsMutationVariables>;

/**
 * __useSeedAiSuggestionsMutation__
 *
 * To run a mutation, you first call `useSeedAiSuggestionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeedAiSuggestionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seedAiSuggestionsMutation, { data, loading, error }] = useSeedAiSuggestionsMutation({
 *   variables: {
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useSeedAiSuggestionsMutation(baseOptions?: Apollo.MutationHookOptions<SeedAiSuggestionsMutation, SeedAiSuggestionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SeedAiSuggestionsMutation, SeedAiSuggestionsMutationVariables>(SeedAiSuggestionsDocument, options);
      }
export type SeedAiSuggestionsMutationHookResult = ReturnType<typeof useSeedAiSuggestionsMutation>;
export type SeedAiSuggestionsMutationResult = Apollo.MutationResult<SeedAiSuggestionsMutation>;
export type SeedAiSuggestionsMutationOptions = Apollo.BaseMutationOptions<SeedAiSuggestionsMutation, SeedAiSuggestionsMutationVariables>;
export const Operations = {
  Mutation: {
    SeedAISuggestions: 'SeedAISuggestions' as const
  }
}