import { Grid as MUIGrid, GridProps as MUIGridProps } from '@mui/material';

export type GridProps = MUIGridProps;

export function Grid({
  children,
  ...otherProps
}: React.PropsWithChildren<GridProps>) {
  return <MUIGrid {...otherProps}>{children}</MUIGrid>;
}
