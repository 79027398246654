import { invert } from 'lodash';

import { ClientPresentationSection } from '@/types/schema';

export enum ReorderableSlideTypes {
  // top-level items
  STANDALONE_SLIDE_PROFESSIONAL_TEAM = 'STANDALONE_SLIDE_PROFESSIONAL_TEAM',
  STANDALONE_SLIDE_ALL_ENTITIES = 'STANDALONE_SLIDE_ALL_ENTITIES',
  ESTATE_WATERFALL_BUNDLE = 'ESTATE_WATERFALL_BUNDLE',
  ENTITIES_BUNDLE = 'ENTITIES_BUNDLE',
  BALANCE_SHEET_SLIDE = 'BALANCE_SHEET_SLIDE',

  // members of the entities sub-list
  SINGLE_ENTITY = 'SINGLE_ENTITY',

  // members of the estate waterfalls sub-list
  SINGLE_WATERFALL = 'SINGLE_WATERFALL',
}

export const PRESENTATION_SECTION_TO_REORDERABLE_SLIDE_TYPE: Record<
  ClientPresentationSection,
  ReorderableSlideTypes
> = {
  BALANCE_SHEET: ReorderableSlideTypes.BALANCE_SHEET_SLIDE,
  ALL_ENTITIES_SLIDE: ReorderableSlideTypes.STANDALONE_SLIDE_ALL_ENTITIES,
  PROFESSIONAL_TEAM: ReorderableSlideTypes.STANDALONE_SLIDE_PROFESSIONAL_TEAM,
  ENTITIES_GROUP: ReorderableSlideTypes.ENTITIES_BUNDLE,
  ESTATE_WATERFALLS_GROUP: ReorderableSlideTypes.ESTATE_WATERFALL_BUNDLE,
};

export const REORDERABLE_SLIDE_TYPE_TO_PRESENTATION_SECTION = invert(
  PRESENTATION_SECTION_TO_REORDERABLE_SLIDE_TYPE
);

export interface ReorderableSlideItem {
  id: string;
  // slideId is present to allow us to scroll to specific slides when clicked, but
  // some ReorderableSlideItems (bundles) won't have a slideId
  slideId: string | null;
  slideType: ReorderableSlideTypes;
  // SINGLE_ENTITY and SINGLE_WATERFALL slide types will have an itemPulid
  // directly associated with them
  itemPulid?: string;
  display: string;
  index: number;
  children?: ReorderableSlideItem[];
}
