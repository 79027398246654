// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { TrusteeDropdown_HouseholdFragmentDoc } from '../../../inputs/TrusteeDropdown/graphql/TrusteeDropdown.generated';
import { PrimaryClientDropdown_HouseholdWithGrantorsFragmentDoc } from '../../../inputs/PrimaryClientDropdown/graphql/PrimaryClientDropdown.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NonGrantorPrincipalFieldsFormOptionsQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.HouseholdWhereInput>;
}>;


export type NonGrantorPrincipalFieldsFormOptionsQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, possibleTrusteesV2: { __typename?: 'PossibleTrusteesV2', clients: Array<{ __typename?: 'ClientProfile', id: string, displayName: string, legalName: string, firstName: string }>, organizations: Array<{ __typename?: 'ClientOrganization', id: string, name: string }> }, possibleGrantors: Array<{ __typename?: 'ClientProfile', isPrimary: boolean, id: string, displayName: string, legalName: string, firstName: string }> } | null } | null> | null } };


export const NonGrantorPrincipalFieldsFormOptionsDocument = gql`
    query NonGrantorPrincipalFieldsFormOptions($where: HouseholdWhereInput) {
  households(where: $where) {
    edges {
      node {
        ...TrusteeDropdown_Household
        ...PrimaryClientDropdown_HouseholdWithGrantors
      }
    }
  }
}
    ${TrusteeDropdown_HouseholdFragmentDoc}
${PrimaryClientDropdown_HouseholdWithGrantorsFragmentDoc}`;

/**
 * __useNonGrantorPrincipalFieldsFormOptionsQuery__
 *
 * To run a query within a React component, call `useNonGrantorPrincipalFieldsFormOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNonGrantorPrincipalFieldsFormOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNonGrantorPrincipalFieldsFormOptionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useNonGrantorPrincipalFieldsFormOptionsQuery(baseOptions?: Apollo.QueryHookOptions<NonGrantorPrincipalFieldsFormOptionsQuery, NonGrantorPrincipalFieldsFormOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NonGrantorPrincipalFieldsFormOptionsQuery, NonGrantorPrincipalFieldsFormOptionsQueryVariables>(NonGrantorPrincipalFieldsFormOptionsDocument, options);
      }
export function useNonGrantorPrincipalFieldsFormOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NonGrantorPrincipalFieldsFormOptionsQuery, NonGrantorPrincipalFieldsFormOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NonGrantorPrincipalFieldsFormOptionsQuery, NonGrantorPrincipalFieldsFormOptionsQueryVariables>(NonGrantorPrincipalFieldsFormOptionsDocument, options);
        }
export function useNonGrantorPrincipalFieldsFormOptionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NonGrantorPrincipalFieldsFormOptionsQuery, NonGrantorPrincipalFieldsFormOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NonGrantorPrincipalFieldsFormOptionsQuery, NonGrantorPrincipalFieldsFormOptionsQueryVariables>(NonGrantorPrincipalFieldsFormOptionsDocument, options);
        }
export type NonGrantorPrincipalFieldsFormOptionsQueryHookResult = ReturnType<typeof useNonGrantorPrincipalFieldsFormOptionsQuery>;
export type NonGrantorPrincipalFieldsFormOptionsLazyQueryHookResult = ReturnType<typeof useNonGrantorPrincipalFieldsFormOptionsLazyQuery>;
export type NonGrantorPrincipalFieldsFormOptionsSuspenseQueryHookResult = ReturnType<typeof useNonGrantorPrincipalFieldsFormOptionsSuspenseQuery>;
export type NonGrantorPrincipalFieldsFormOptionsQueryResult = Apollo.QueryResult<NonGrantorPrincipalFieldsFormOptionsQuery, NonGrantorPrincipalFieldsFormOptionsQueryVariables>;
export const Operations = {
  Query: {
    NonGrantorPrincipalFieldsFormOptions: 'NonGrantorPrincipalFieldsFormOptions' as const
  }
}