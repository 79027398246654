import { noop } from 'lodash';
import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import { DocumentSearchResult } from '@/modules/pdf/DocumentSearch/DocumentSearch.types';

export interface AnnotatedPDFViewerContext {
  documentId: string | null;
  annotations: DocumentSearchResult[] | undefined;
  setAnnotations: (annotations: DocumentSearchResult[] | undefined) => void;
  activeAnnotationIndex: number;
  setActiveAnnotationIndex: (index: number) => void;
  overrideSearchQuery: string | undefined;
  onUpdateSearchQuery?: () => void;
  overrideSearchLoading: boolean | undefined;
}

export const AnnotatedPDFViewerContext =
  createContext<AnnotatedPDFViewerContext>({
    documentId: null,
    annotations: undefined,
    setAnnotations: () => noop,
    activeAnnotationIndex: 0,
    setActiveAnnotationIndex: () => noop,
    overrideSearchQuery: undefined,
    onUpdateSearchQuery: () => noop,
    overrideSearchLoading: undefined,
  });

export const useAnnotatedPDFViewerContext = () => {
  return useGuardedContext(
    AnnotatedPDFViewerContext,
    'AnnotatedPDFViewerProvider'
  );
};
