import { useApolloClient } from '@apollo/client';
import Decimal from 'decimal.js';
import { findIndex, isEmpty } from 'lodash';
import dynamic from 'next/dynamic';
import { useCallback, useMemo } from 'react';

import { Button, ButtonProps } from '@/components/form/baseInputs/Button';
import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useModalState } from '@/hooks/useModalState';
import { diagnostics } from '@/utils/diagnostics';

import { useEntityDetailsContext } from '../entities/contexts/entityDetails/entityDetails.context';
import { EditEntitySection } from '../entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { useDispositiveProvisionsContext } from './contexts/dispositiveProvisions.context';
import { ActiveDispositionTab } from './DispositiveProvisionsListView/hooks/useDispositiveProvisionsListViewTab';
import { DispositiveProvisionsSplitScreenModal } from './DispositiveProvisionsSplitScreenModal/DispositiveProvisionsSplitScreenModal';

// this is an approximation of the props that can be shared between a normal button and a ButtonWithPopover
type SharedPopoverButtonProps = Omit<
  ButtonProps,
  'variant' | 'size' | 'onMouseLeave'
>;

const EditEntitySplitScreenModal = dynamic(
  () =>
    import('../entities/EditEntitySplitScreen/EditEntitySplitScreenModal').then(
      (module) => module.EditEntitySplitScreenModal
    ),
  { ssr: false }
);

type SpecifyDispositiveProvisionsButtonProps = {
  buttonProps?: Partial<SharedPopoverButtonProps>;
  behavior?: 'edit' | 'create';
  currentNodeValue?: Decimal;
  /* firstDeathPrimaryClientId is only used to order the "x dies first" options in the dropdown, such as in a waterfall scenario */
  firstDeathPrimaryClientId?: string | null;
} & ActiveDispositionTab;

export const SpecifyDispositiveProvisionsButton = ({
  buttonProps,
  behavior = 'create',
  //
  firstDeathPrimaryClientId,
  activePrimaryClientIdTab,
  setActivePrimaryClientIdTab,
  currentNodeValue,
}: SpecifyDispositiveProvisionsButtonProps) => {
  const [{ isModalOpen }, { closeModal, openModal }] = useModalState();
  const {
    primaryClients,
    isTwoClientHousehold,
    isTestamentaryEntity,
    isClientProfile,
  } = useDispositiveProvisionsContext();
  const { entityType } = useEntityDetailsContext();

  const initialSection = useMemo(() => {
    const grantorIndex = findIndex(
      primaryClients,
      (c) => c.id === activePrimaryClientIdTab
    );

    if (grantorIndex === -1) {
      // do this defensively so that if the activePrimaryClientIdTab is not found in primaryClients
      // for whatever reason, we default to showing a grantor
      const msg =
        'did not find grantors when computing initial DP edit section';
      diagnostics.error(msg, new Error(msg), {
        activePrimaryClientIdTab,
        numPrimaryClients: primaryClients.length,
      });

      return EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1;
    }

    return grantorIndex === 1
      ? EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_2
      : EditEntitySection.DISPOSITIVE_PROVISIONS_GRANTOR_1;
  }, [activePrimaryClientIdTab, primaryClients]);

  const handleClick = useCallback(
    (activeClientId?: string) => {
      if (activeClientId) {
        setActivePrimaryClientIdTab(activeClientId);
      }

      openModal();
    },
    [openModal, setActivePrimaryClientIdTab]
  );

  const CreateOrEditDispositiveProvisionsButton = useMemo(() => {
    // in the edit scenario, we always expose both possible death orders, via the two tabs
    // so when you hit edit you're already doing it in the context of a specific death order.
    if (behavior === 'edit') {
      return (
        <Button
          startIcon={Edit02Icon}
          size="lg"
          variant="secondary"
          {...buttonProps}
          onClick={() => handleClick()}
        >
          Edit dispositive provisions
        </Button>
      );
    }

    if (isTwoClientHousehold) {
      return (
        <ButtonWithPopover
          size="lg"
          variant="primary"
          label="Specify dispositive provisions"
          popperVariant="menuBelow"
          {...buttonProps}
        >
          {isTwoClientHousehold &&
            primaryClients
              .sort((a) => (a.id === firstDeathPrimaryClientId ? -1 : 1))
              .map((client) => (
                <MenuItem
                  key={client.id}
                  label={`${client.displayName} dies first`}
                  onClick={() => handleClick(client.id)}
                />
              ))}
        </ButtonWithPopover>
      );
    }

    return (
      <Button
        size="sm"
        variant="primary"
        onClick={() => handleClick()}
        {...buttonProps}
      >
        Specify dispositive provisions
      </Button>
    );
  }, [
    behavior,
    isTwoClientHousehold,
    buttonProps,
    handleClick,
    primaryClients,
    firstDeathPrimaryClientId,
  ]);
  // we don't support editing testamentary entities through the EditEntitySplitScreenModal,
  // so those will always use the DispositiveProvisionsSplitScreenModal
  const shouldUseEntityModal = !isTestamentaryEntity && !isClientProfile;

  const client = useApolloClient();

  const onClose = useCallback(() => {
    closeModal();
    // since this can be invoked from outside an EW context, we need to refetch the dispositive provisions manually
    void client.refetchQueries({
      include: ['GetDispositiveProvisions'],
    });
  }, [client, closeModal]);

  const hasPrimaryClients = !isEmpty(primaryClients);
  const shouldDisplayEntityModal: boolean =
    Boolean(entityType) && shouldUseEntityModal && hasPrimaryClients;

  return (
    <>
      {isModalOpen && (
        <>
          {entityType && (
            <EditEntitySplitScreenModal
              initialSection={initialSection}
              entityValueOverride={currentNodeValue}
              entityType={entityType}
              navigateAfterSave={false}
              isOpen={isModalOpen && shouldDisplayEntityModal}
              onClose={onClose}
            />
          )}
          <DispositiveProvisionsSplitScreenModal
            firstClientDeathId={activePrimaryClientIdTab}
            currentNodeValue={currentNodeValue}
            isOpen={
              isModalOpen &&
              Boolean(activePrimaryClientIdTab) &&
              hasPrimaryClients &&
              !shouldDisplayEntityModal
            }
            onClose={onClose}
          />
        </>
      )}
      {CreateOrEditDispositiveProvisionsButton}
    </>
  );
};
