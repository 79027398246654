import { useEffect } from 'react';

import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { InPageNavigationContextProvider } from '@/components/navigation/InPageNavigation/InPageNavigationBox';
import { useImmediateOpenEditModal } from '@/modules/entities/hooks/useImmediateOpenEditModal';
import { COLORS } from '@/styles/tokens/colors';

import { EntitySubformNamespaceType } from '../EntitySubforms/entitySubformConfigs';
import { EntityType } from '../types/EntityType';
import { EntityForm } from './EntityForm';
import { EntityFormCommonProps, EntityFormStage } from './types';
import { stringToDisplayType } from './utils';

interface EntityFormTrowserProps extends EntityFormCommonProps {
  isOpen: boolean;
  type: EntityType;
  stage: EntityFormStage;
  estateWaterfallId?: string;
  isHypotheticalEntity?: boolean;
  initialFocusedSubformType?: EntitySubformNamespaceType;
}

export function EntityFormTrowser({
  isOpen,
  type,
  handleClose,
  householdId,
  entityId,
  stage,
  header,
  navigateAfterCreate,
  estateWaterfallId,
  initialFocusedSubformType,
  isHypotheticalEntity = false,
}: EntityFormTrowserProps) {
  const displayType = stringToDisplayType(type);
  const heading =
    header ||
    (entityId ? `Update ${displayType}` : `Add an existing ${displayType}`);

  const { clearImmediateEditSearchParam } = useImmediateOpenEditModal();

  useEffect(() => {
    // This will clear out the immediate edit search param if it exists,
    // so that we don't keep re-triggering the modal to open.
    // The immediate edit search param gets set by the CreateEntityShortFormModal -> Add additional details button.
    clearImmediateEditSearchParam();
  }, [clearImmediateEditSearchParam]);

  return (
    <InPageNavigationContextProvider
      initialRowIdSubstring={initialFocusedSubformType}
    >
      <FullScreenModal
        isOpen={isOpen}
        backdropSx={{ bgcolor: COLORS.GRAY[50] }}
      >
        {isOpen && (
          <EntityForm
            stage={stage}
            type={type}
            handleClose={handleClose}
            householdId={householdId}
            entityId={entityId}
            header={heading}
            isTrowser={true}
            navigateAfterCreate={navigateAfterCreate}
            estateWaterfallId={estateWaterfallId}
            isHypotheticalEntity={isHypotheticalEntity}
          />
        )}
      </FullScreenModal>
    </InPageNavigationContextProvider>
  );
}
