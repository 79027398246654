import Decimal from 'decimal.js';
import { compact, forEach, includes, map } from 'lodash';
import { useEffect } from 'react';

import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { useFormContext } from '@/components/react-hook-form';
import { BUSINESS_OWNERSHIP_PERCENTAGE_DECIMAL_SCALE } from '@/modules/entities/BusinessEntityDetailsSubform/BusinessEntityDetailsSubform.types';
import {
  ACCOUNT_ENTITY_TYPES,
  ALLOW_MULTIPLE_PRINCIPALS_TYPES,
  BUSINESS_ENTITY_TYPES,
  DONOR_ENTITY_TYPES,
  ENTITY_TYPES,
  GRANTOR_ENTITY_TYPES,
  SINGLE_GRANTOR_TRUST_TYPES,
} from '@/modules/entities/entities.constants';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  ContextPrimaryClient,
  useHouseholdDetailsContext,
} from '@/modules/household/contexts/householdDetails.context';
import { getComparableDecimalJS, sumDecimalJS } from '@/utils/decimalJSUtils';
import { diagnostics } from '@/utils/diagnostics';

import { OWNERSHIP_PERCENTAGE_ENTITY_TYPES } from '../CreateEntityShortForm.constants';
import {
  EntityShortFormShape,
  PrincipalsOwnershipMap,
} from '../CreateEntityShortForm.types';
import {
  getPrincipalSelectionPattern,
  getSelectedClientProfileIdsFromForm,
  PrincipalSelectionPattern,
} from '../formUtils/CreateEntityShortForm.common';

interface PrincipalInputConfiguration {
  principalSelectionPattern: PrincipalSelectionPattern;
  collectOwnershipPercentage: boolean;
  label: string;
  options: SelectInputOption<string>[];
  // validateCompleteOwnership indicates whether to validate that the sum of ownership percentages is
  // exactly 100%
  validateCompleteOwnership: boolean;
}

function getOwnershipPercentageAwareLabel(
  labelRoot: string,
  includeOwnershipPercentage: boolean
) {
  if (includeOwnershipPercentage) {
    return `${labelRoot} & percent ownership`;
  }
  return labelRoot;
}

export function getPrincipalInputConfiguration(
  entityType: EntityType,
  primaryClientOptions: SelectInputOption<string>[],
  principalOptions: SelectInputOption<string>[]
): PrincipalInputConfiguration {
  const principalSelectionPattern = getPrincipalSelectionPattern(entityType);
  const validateCompleteOwnership = includes(
    [ENTITY_TYPES.QPRT, ENTITY_TYPES.JOINT_ACCOUNT],
    entityType
  );

  const configProps = {
    validateCompleteOwnership,
    collectOwnershipPercentage: includes(
      OWNERSHIP_PERCENTAGE_ENTITY_TYPES,
      entityType
    ),
  };

  if (includes(GRANTOR_ENTITY_TYPES, entityType)) {
    if (includes(SINGLE_GRANTOR_TRUST_TYPES, entityType)) {
      return {
        label: 'Grantor',
        principalSelectionPattern,
        options: primaryClientOptions,
        ...configProps,
      };
    }

    return {
      label: getOwnershipPercentageAwareLabel(
        'Grantors',
        configProps.collectOwnershipPercentage
      ),
      principalSelectionPattern,
      options: principalOptions,
      ...configProps,
    };
  }

  if (includes(DONOR_ENTITY_TYPES, entityType)) {
    return {
      label: 'Donors',
      principalSelectionPattern,
      options: principalOptions,
      ...configProps,
    };
  }

  if (
    includes(ACCOUNT_ENTITY_TYPES, entityType) &&
    !includes(ALLOW_MULTIPLE_PRINCIPALS_TYPES, entityType)
  ) {
    return {
      label: getOwnershipPercentageAwareLabel(
        'Owner',
        configProps.collectOwnershipPercentage
      ),
      principalSelectionPattern,
      options: principalOptions,
      ...configProps,
    };
  }

  if (
    includes([...ACCOUNT_ENTITY_TYPES, ...BUSINESS_ENTITY_TYPES], entityType)
  ) {
    return {
      label: getOwnershipPercentageAwareLabel(
        'Owners',
        configProps.collectOwnershipPercentage
      ),
      principalSelectionPattern,
      options: principalOptions,
      ...configProps,
    };
  }

  if (entityType === 'insurance-account') {
    return {
      label: 'Owners',
      principalSelectionPattern,
      options: principalOptions,
      ...configProps,
    };
  }

  throw new Error(`Unhandled entity type ${entityType}`);
}

export function getInitialSelectedPrincipalsState(
  clients: ContextPrimaryClient[]
): PrincipalsOwnershipMap {
  const res: PrincipalsOwnershipMap = {};
  clients.forEach((client) => {
    res[client.id] = {
      isIncluded: false,
      ownershipPercentage: null,
    };
  });
  return res;
}

export function getOptionsFromContextClients(
  clients: ContextPrimaryClient[] | null
): SelectInputOption<string>[] {
  if (!clients) return [];

  return clients
    .sort((a, b) => a.displayName.localeCompare(b.displayName))
    .map((client) => ({
      display: client.displayName,
      value: client.id,
    }));
}

export function useOwnershipSumValidation() {
  const { watch, setValue } = useFormContext<EntityShortFormShape>();
  const principals = watch('createEntityShortForm.principals');
  const ownershipSum = sumDecimalJS(
    compact(
      map(principals, (p) => {
        if (!p.isIncluded) return null;
        return p.ownershipPercentage;
      })
    )
  );

  useEffect(() => {
    setValue(`createEntityShortForm._ownershipSum` as const, ownershipSum);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // getComparableDecimalJS is the only way to avoid infinite setValues when the value
    // is a Decimal.js instance
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getComparableDecimalJS(
      ownershipSum,
      BUSINESS_OWNERSHIP_PERCENTAGE_DECIMAL_SCALE
    ),
    setValue,
  ]);
}

export function validateMoreThanOnePrincipalSelected(
  multiplePrincipalsValue:
    | EntityShortFormShape['createEntityShortForm']['principals']
    | undefined,
  singlePrincipalsValue:
    | EntityShortFormShape['createEntityShortForm']['selectedSinglePrincipal']
    | undefined,
  entityType: EntityType
): string | undefined {
  const selectedIds = getSelectedClientProfileIdsFromForm(
    multiplePrincipalsValue,
    singlePrincipalsValue,
    entityType
  );

  if (selectedIds.length < 1) {
    return 'At least one principal must be selected';
  }

  return undefined;
}

export function useDefaultJointAccountOwnership() {
  const { primaryClients } = useHouseholdDetailsContext();
  const { watch, setValue } = useFormContext<EntityShortFormShape>();
  const entityType = watch('createEntityShortForm.entityType');

  useEffect(() => {
    if (entityType !== ENTITY_TYPES.JOINT_ACCOUNT || !primaryClients) return;
    diagnostics.debug('Running useDefaultJointAccountOwnership');
    const initialState = getInitialSelectedPrincipalsState(primaryClients);

    // We want to divide ownership equally among all primary clients
    const primaryClientOwnershipShare = new Decimal(
      100 / primaryClients.length
    );

    // need to set each field individually instead of the object for... unknown reasons
    forEach(initialState, (_, id) => {
      const isPrimaryClient = primaryClients.some((client) => client.id === id);
      setValue(
        `createEntityShortForm.principals.${id}.isIncluded` as const,
        isPrimaryClient
      );
      setValue(
        `createEntityShortForm.principals.${id}.ownershipPercentage` as const,
        isPrimaryClient ? primaryClientOwnershipShare : null
      );
    });
  }, [entityType, primaryClients, setValue]);
}
