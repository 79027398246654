import { useAsyncFn } from 'react-use';

import { Edit02Icon } from '@/components/icons/Edit02Icon';

import { useFlowChartContext } from '../../context/flowChart.context';
import { useIsInteractive } from '../../hooks/useIsInteractive';
import { ControlButton, ControlButtonProps } from './ControlButton';

export type EditButtonProps = ControlButtonProps & {
  onSave?: () => Promise<void>;
};

export const EditButton = ({
  onClick,
  onSave,
  children,
  ...props
}: EditButtonProps) => {
  const [{ loading }, saveFn] = useAsyncFn(async () => onSave?.(), [onSave]);
  const { isDirty } = useFlowChartContext();
  const { isInteractive, toggleIsInteractive } = useIsInteractive();

  const defaultOnClick: ControlButtonProps['onClick'] = async () => {
    // If it's currently interactive, we are "exiting" AKA moving to a "saved" state
    if (onSave && isDirty) {
      await saveFn();
    }

    toggleIsInteractive();
  };

  return (
    <ControlButton
      variant={isInteractive ? 'primary' : 'secondary-filled'}
      onClick={onClick ?? defaultOnClick}
      title="toggle edit"
      aria-label="toggle edit"
      loading={loading}
      startIcon={Edit02Icon}
      {...props}
    >
      {children ?? <>{isInteractive ? 'Exit edit mode' : 'Edit'}</>}
    </ControlButton>
  );
};
