import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { TrustSummaryData } from '@/modules/trusts/TrustDetails/TrustDetails.types';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import {
  getPayoutInformation,
  getPossibleLifetimeDurationLabelItem,
} from '../entityDetailPageUtils';
import { EntityDetailsData } from '../entityDetails.types';
import {
  getGrantorNameFromFragment,
  getSharedSummaryProperties,
  getTermLabelItem,
} from './trustCardUtils';

export function getCRTTrustSummaryProperties({
  entity,
}: EntityDetailsData): TrustSummaryData {
  if (entity.subtype.__typename !== 'CRTTrust') {
    throw new Error('Invalid trust type');
  }

  const { payoutType, payoutAmount } = getPayoutInformation({ entity });

  const summary: TrustSummaryData = {
    ...getSharedSummaryProperties(entity),
    beneficiariesGroups: [
      {
        heading: 'Income beneficiary',
        items: entity.subtype.lifetimeBeneficiaries || [],
        noItemsText: 'No income beneficiaries',
      },
      {
        heading: 'Remainder beneficiaries',
        items: entity.subtype.remainderBeneficiaries || [],
        noItemsText: 'No remainder beneficiaries',
      },
    ],
    grantorNames: entity.subtype.grantors
      ? entity.subtype.grantors.map((grantor) =>
          getGrantorNameFromFragment(grantor)
        )
      : [],
    trustDetailsItems: [
      getTermLabelItem({
        effectiveDate: entity.subtype.effectiveDate,
        termEndDate: entity.subtype.termEndDate,
        isLifetime: entity.subtype.termDurationLifetime,
        label: 'Effective date',
      }),
      getPossibleLifetimeDurationLabelItem({
        termDurationLifetime: entity.subtype.termDurationLifetime,
        termDurationYears: entity.subtype.termDurationLifetime
          ? null
          : entity.subtype.termDurationYears ?? null,
      }),
      {
        label: 'Charitable deduction',
        value: entity.subtype.charitableDeduction
          ? formatCurrency(entity.subtype.charitableDeduction)
          : EMPTY_CONTENT_HYPHEN,
      },
      {
        label: 'Payout type',
        value: payoutType,
      },
      {
        label: 'Payout amount',
        value: payoutAmount,
      },
      {
        label: '7520 rate',
        value: entity.subtype.officialInterestRatePercent
          ? `${formatPercent(entity.subtype.officialInterestRatePercent, 1)}%`
          : EMPTY_CONTENT_HYPHEN,
      },
    ],
  };

  return summary;
}
