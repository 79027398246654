import { Box } from '@mui/material';

import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { useAICapabilitiesEnabled } from '@/modules/tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import { zIndexes } from '@/styles/zIndexes';

import { DOCUMENT_DETAILS_VIEW_BUTTON_OPTIONS } from '../../documents.constants';
import { useHouseholdDocumentsViewerContext } from './context/HouseholdDocumentsViewer.context';
import {
  DOCUMENT_CONTROLS_TOP_OFFSET,
  DOCUMENT_CONTROLS_X_OFFSET,
} from './HouseholdDocumentsViewer.constants';

export function DocumentSummarySwitch() {
  const aiEnabled = useAICapabilitiesEnabled();

  const { documentViewerActiveTab, updateDocumentViewerActiveTab } =
    useHouseholdDocumentsViewerContext();

  // Never show the summary option if tenant has AI disabled
  if (!aiEnabled) return null;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: DOCUMENT_CONTROLS_TOP_OFFSET,
        left: DOCUMENT_CONTROLS_X_OFFSET,
        zIndex: zIndexes.MENU,
      }}
    >
      <ButtonGroup
        label="Choose view type"
        hideLabel
        variant="dark"
        value={documentViewerActiveTab}
        options={DOCUMENT_DETAILS_VIEW_BUTTON_OPTIONS}
        onChange={(_, value) => updateDocumentViewerActiveTab(value)}
      />
    </Box>
  );
}
