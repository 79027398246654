import { keyBy } from 'lodash';
import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { getNodes } from '@/utils/graphqlUtils';

import { useGetAdvisorClientOptionsQuery } from './graphql/CollaboratorModal.generated';

export function useAdvisorClientOptions() {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();

  const { data, loading, ...queryProps } = useGetAdvisorClientOptionsQuery({
    fetchPolicy: 'cache-and-network',
    onError: (err) => {
      showFeedback(
        `We weren't able to get client options. Please refresh the page and try again.`
      );
      reportError('could not get client options', err);
    },
  });

  const options = useMemo(() => {
    if (loading) {
      return [{ heading: 'Loading...', id: '' }];
    }
    return getNodes(data?.households).map((client) => ({
      heading: client.displayName,
      id: client.id,
    }));
  }, [data, loading]);

  const optionsById = useMemo(() => {
    return keyBy(options, 'value');
  }, [options]);

  return { options, optionsById, loading, ...queryProps };
}
