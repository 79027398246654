import { getFirstOrderConnections } from '@/components/diagrams/FlowChart/utils/nodes';

import { EstateWaterfallActionFn, NodeMouseEnterAction } from '../types';

export const nodeMouseEnterAction: EstateWaterfallActionFn<
  NodeMouseEnterAction
> = (state, { node }, _queueEffect) => {
  const highlightedIds = getFirstOrderConnections(
    node,
    state.nodes,
    state.edges
  );

  return { ...state, highlightedIds };
};
