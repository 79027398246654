import { Edit01Icon } from '@/components/icons/Edit01Icon';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { Column } from '@/components/tables/DataTable/types';

export interface RowData {
  id: string;
  name: string;
  householdSummary: string;
  action: unknown;
}

export const columns: Column<RowData>[] = [
  {
    field: 'name',
    headerName: 'Sub-brand',
    flex: 1,
    renderCell: TextRenderer({
      text: ({ row: { name } }) => name,
    }),
  },
  {
    field: 'householdSummary',
    headerName: 'Associated clients',
    flex: 1,
  },
  {
    field: 'action',
    headerName: '',
    align: 'center',
    renderCell: IconRenderer({
      icon: Edit01Icon,
    }),
    sortable: false,
  },
];
