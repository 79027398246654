import { noop } from 'lodash';
import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';
import {
  AcknowledgedSuggestion,
  MatchableKind,
  SuggestableKind,
} from '@/modules/aiSuggestions/aiSuggestions.types';
import { CommonAiSuggestionFragment } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { EditEntitySection } from '@/modules/entities/EditEntitySplitScreen/EditEntitySplitScreen.types';
import { AiSuggestionKind } from '@/types/schema';

/**
 * Use this context to provide the form with suggested data. This should be
 * used within a form context provider.
 */
export interface EntitySuggestionsContext {
  /**
   * This includes suggestions that have been acknowledged and unacknowledged.
   */
  suggestionsByKind: Record<AiSuggestionKind, CommonAiSuggestionFragment[]>;

  /**
   * The available suggestions for the given entity section being edited.
   */
  getCurrentSectionSuggestions: (
    currentSection: EditEntitySection
  ) => CommonAiSuggestionFragment[];

  /**
   * Returns the suggestable suggestion of a specific kind.
   */
  getSuggestableSuggestion: (
    kind: SuggestableKind
  ) => CommonAiSuggestionFragment | undefined;

  /**
   * Returns the suggestion of a specific kind that got matched with the given id.
   */
  getMatchableSuggestion: (
    kind: MatchableKind,
    matchedId: string
  ) => CommonAiSuggestionFragment | undefined;

  /**
   * The suggestions that have been acknowledged by the user during an edit session.
   * This is used to hide suggestion while the user is editing, before they have
   * saved the form. This gets reset when the user navigates to a different section,
   * or closes the form.
   */
  acknowledgedSuggestions: Record<string, AcknowledgedSuggestion>;

  /**
   * Acknowledge a suggestion, either accepting or rejecting it. This will
   * update the acknowledgedSuggestions state, for the current edit session.
   */
  acknowledgeSuggestion: (suggestion: AcknowledgedSuggestion) => void;

  /**
   * The previously acknowledged suggestions. This is used to determine if a
   * suggestion was acknowledged during a previous edit session.
   */
  previouslyAcknowledgedSuggestions: Record<string, AcknowledgedSuggestion>;

  /**
   * Resets the acknowledged suggestions for the current edit session. This
   * should be called when we close the form, or navigate to a different section.
   */
  resetAcknowledgedSuggestions: () => void;

  /**
   * Refetch the suggestions for the current entity, or the given entityID, and
   * updates the state variables.
   */
  refetchSuggestions: (variables?: { entityID: string }) => void;
}

export const getDefaultValues = (): EntitySuggestionsContext => ({
  suggestionsByKind: {} as Record<
    AiSuggestionKind,
    CommonAiSuggestionFragment[]
  >,
  getCurrentSectionSuggestions: () => [],
  getSuggestableSuggestion: () => undefined,
  getMatchableSuggestion: () => undefined,
  acknowledgedSuggestions: {},
  acknowledgeSuggestion: noop,
  previouslyAcknowledgedSuggestions: {},
  resetAcknowledgedSuggestions: noop,
  refetchSuggestions: noop,
});

export const EntitySuggestionsContext =
  createContext<EntitySuggestionsContext>(getDefaultValues());

export const useEntitySuggestionsContext = () => {
  return useGuardedContext(
    EntitySuggestionsContext,
    'EntitySuggestionsProvider'
  );
};
