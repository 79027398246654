import { PropsWithChildren, useState } from 'react';

import { IntegrationClientKind } from '@/types/schema';

import { HouseholdDetailsContext } from './householdDetails.context';

function useHouseholdDetailsContextValue(): HouseholdDetailsContext {
  const [householdId, setHouseholdId] =
    useState<HouseholdDetailsContext['householdId']>(null);

  const [displayName, setDisplayName] =
    useState<HouseholdDetailsContext['displayName']>(null);

  const [primaryClients, setPrimaryClients] =
    useState<HouseholdDetailsContext['primaryClients']>(null);

  const [possibleGrantors, setPossibleGrantors] =
    useState<HouseholdDetailsContext['possibleGrantors']>(null);

  const [possibleTrustees, setPossibleTrustees] =
    useState<HouseholdDetailsContext['possibleTrustees']>(null);

  const [possibleBeneficiaries, setPossibleBeneficiaries] =
    useState<HouseholdDetailsContext['possibleBeneficiaries']>(null);

  const [isTwoClientHousehold, setIsTwoClientHousehold] =
    useState<HouseholdDetailsContext['isTwoClientHousehold']>(false);

  const [householdIntegrationLink, setHouseholdIntegrationLink] =
    useState<HouseholdDetailsContext['householdIntegrationLink']>(null);

  const [householdOnboardingCompletedAt, setHouseholdOnboardingCompletedAt] =
    useState<HouseholdDetailsContext['householdOnboardingCompletedAt']>(null);

  const [recentClients, setRecentClients] = useState<
    HouseholdDetailsContext['recentClients']
  >([]);

  const [householdNotFound, setHouseholdNotFound] =
    useState<HouseholdDetailsContext['householdNotFound']>(false);

  const isHouseholdLinkedToAddepar =
    householdIntegrationLink === IntegrationClientKind.Addepar;
  const isHouseholdLinkedToBlackDiamond =
    householdIntegrationLink === IntegrationClientKind.BlackDiamond;
  const isHouseholdLinkedToAssetProvider = Boolean(householdIntegrationLink);

  return {
    householdId,
    setHouseholdId,

    displayName,
    setDisplayName,

    primaryClients,
    setPrimaryClients,

    possibleGrantors,
    setPossibleGrantors,

    possibleTrustees,
    setPossibleTrustees,

    possibleBeneficiaries,
    setPossibleBeneficiaries,

    isTwoClientHousehold,
    setIsTwoClientHousehold,

    isHouseholdLinkedToBlackDiamond,
    isHouseholdLinkedToAddepar,
    isHouseholdLinkedToAssetProvider,
    householdIntegrationLink,
    setHouseholdIntegrationLink,

    householdOnboardingCompletedAt,
    setHouseholdOnboardingCompletedAt,

    recentClients,
    setRecentClients,

    householdNotFound,
    setHouseholdNotFound,
  };
}

export const HouseholdDetailsProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const value = useHouseholdDetailsContextValue();
  return (
    <HouseholdDetailsContext.Provider value={value}>
      {children}
    </HouseholdDetailsContext.Provider>
  );
};
