import { IntegrationEntityExternalKind } from '@/types/schema';

export const EXTERNAL_KIND_DISPLAY_NAME_MAP: Record<
  IntegrationEntityExternalKind,
  string
> = {
  ADDEPAR_HOLDING_ACCOUNT: 'Addepar Holding Account',
  ADDEPAR_LEGAL_ENTITY: 'Addepar Legal Entity',
  BLACK_DIAMOND_ACCOUNT: 'Black Diamond Account',
  BLACK_DIAMOND_PORTFOLIO: 'Black Diamond Portfolio',
  ORION_ACCOUNT: 'Orion Account',
  ORION_PORTFOLIO: 'Orion Portfolio',
};
