import { MenuItem } from '@mui/material';
import { useParams } from 'react-router-dom';

import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useMatchingRoutes } from '@/hooks/useMatchingRoutes';
import { CreateNewEntityOrStrategy } from '@/modules/common/CreateNewEntityOrStrategy/CreateNewEntityOrStrategy';
import { EstateWaterfallActionButtons } from '@/modules/estateWaterfall/components/EstateWaterfallActionButtons/EstateWaterfallActionButtons';
import { ROUTE_KEYS } from '@/navigation/constants';

interface DefaultClientDetailsPageActionItemsProps {
  householdId: string;
}

function SecondaryAction({
  householdId,
}: DefaultClientDetailsPageActionItemsProps) {
  const { navigate } = useNavigateToRoute();
  const { isMatchingRoute: isEntitiesRoute } =
    useMatchingRoutes(ENTITIES_ROUTES);

  if (!isEntitiesRoute) return null;

  return (
    <ButtonWithPopover
      popperVariant="menuBelow"
      label="Manage cash flows & liabilities"
      size="sm"
      variant="secondary"
    >
      <MenuItem
        onClick={() =>
          navigate(
            ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_MANAGE_INCOME_EXPENSES,
            {
              householdId,
            }
          )
        }
      >
        Manage cash flows
      </MenuItem>
      <MenuItem
        onClick={() =>
          navigate(ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_MANAGE_LIABILITIES, {
            householdId,
          })
        }
      >
        Manage liabilities
      </MenuItem>
    </ButtonWithPopover>
  );
}

interface ClientDetailsPageActionItemsProps {
  householdId: string;
}

const WATERFALL_ROUTES = [
  ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB,
  ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_DIAGRAM,
  ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY,
  ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_LIST,
  ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY_ESTATE_TAX_CALCULATION_TROWSER,
];

const ENTITIES_ROUTES = [
  ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST,
  ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_MANAGE_LIABILITIES,
  ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_MANAGE_INCOME_EXPENSES,
  ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITY_MAP,
  ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITY_MAP_DIAGRAM,
];

const DISPOSITION_ROUTES = [
  ROUTE_KEYS.HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS,
  ROUTE_KEYS.HOUSEHOLD_DETAILS_MANAGE_DISPOSITIONS_ESTATE_TAX_CALCULATION_TROWSER,
];
// This component is used to render the action items on the client details page.
// The spacing between them is set on the parent component.
export function ClientDetailsPageActionItems({
  householdId,
}: ClientDetailsPageActionItemsProps) {
  const { isMatchingRoute: isWaterfallRoute } =
    useMatchingRoutes(WATERFALL_ROUTES);

  const { isMatchingRoute: isDispositionsRoute } =
    useMatchingRoutes(DISPOSITION_ROUTES);

  const waterfallId = useParams().waterfallId ?? null;

  if (isDispositionsRoute) {
    return null;
  }

  if (isWaterfallRoute) {
    return (
      <EstateWaterfallActionButtons
        householdId={householdId}
        waterfallId={waterfallId}
      />
    );
  }

  return (
    <>
      <SecondaryAction householdId={householdId} />
      <CreateNewEntityOrStrategy size="sm" householdId={householdId} />
    </>
  );
}
