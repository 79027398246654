import { compact, startCase, toLower } from 'lodash';

import { AIOnboardingModalTestamentaryEntityField } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { getAssociatedDocumentsForSuggestionID } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/fields/common.utils';
import {
  AiOnboardingModal_AiSuggestionFragment,
  AiOnboardingModal_HouseholdFragment,
} from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import { AugmentedCreateTestamentaryEntityInput } from '@/types/schema';

///////////////////////////////////////////////////////////
// SUGGESTION DATA -> FORM DATA
///////////////////////////////////////////////////////////

/**
 * Maps the ONBOARDING_TESTAMENTARY_ENTITY suggestion to form data.
 *
 * @param suggestions
 */
export function mapTestamentaryEntitiesToFormData(
  suggestions: AiOnboardingModal_AiSuggestionFragment[]
): AIOnboardingModalTestamentaryEntityField[] {
  return compact(
    suggestions.map((s) => {
      if (!s.onboardingTestamentaryEntity) return null;

      return {
        _suggestionID: s.id,
        _nodeName: s.onboardingTestamentaryEntity?.nodeName,
        testamentaryEntityName: startCase(
          toLower(s.onboardingTestamentaryEntity?.testamentaryEntityName || '')
        ),
        testamentaryEntityKind:
          s.onboardingTestamentaryEntity?.testamentaryEntityKind || '',
      };
    })
  );
}

export function getEmptyTestamentaryEntityField(): AIOnboardingModalTestamentaryEntityField {
  return {
    _suggestionID: '',
    _nodeName: '',
    testamentaryEntityName: '',
    testamentaryEntityKind: '',
  };
}

///////////////////////////////////////////////////////////
// FORM DATA -> MUTATION INPUT
///////////////////////////////////////////////////////////

export function mapTestamentaryEntitiesToInput(
  testamentaryEntities: AIOnboardingModalTestamentaryEntityField[],
  household: AiOnboardingModal_HouseholdFragment,
  suggestionsByID: Record<string, AiOnboardingModal_AiSuggestionFragment>
): AugmentedCreateTestamentaryEntityInput[] {
  return testamentaryEntities.map((t) => {
    if (!t.testamentaryEntityKind) {
      throw new Error('Testamentary entity kind is required');
    }

    return {
      create: {
        householdID: household.id,
        displayName: t.testamentaryEntityName,
        kind: t.testamentaryEntityKind,
        onboardingTestamentaryEntitySuggestionIDs: t._suggestionID
          ? [t._suggestionID]
          : undefined,
        additionalDocumentIDs: getAssociatedDocumentsForSuggestionID(
          t._suggestionID,
          suggestionsByID
        ).map((d) => d.docID),
      },
    };
  });
}
