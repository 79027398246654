import { Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { Modal } from '@/components/modals/Modal/Modal';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';

import { usePreTaxReturnLimitModalQuery } from './graphql/PreTaxReturnLimitModalQuery.generated';
import { DEFAULT_PRE_TAX_RETURN_LIMIT_MODAL_FORM } from './PreTaxReturnLimitModal.constants';
import { PreTaxReturnLimitModalForm } from './PreTaxReturnLimitModal.types';
import { PreTaxReturnLimitModalBody } from './PreTaxReturnLimitModal.UI';
import { mapPreTaxReturnLimitQueryToForm } from './PreTaxReturnLimitModal.utils';

export interface PreTaxReturnLimitModalProps {
  proposalId: string;
  onClose: () => void;
  onSave: (limits: PreTaxReturnLimitModalForm) => void;
  isOpen: boolean;
}

export function PreTaxReturnLimitModal({
  isOpen,
  onClose: onCloseExternal,
  onSave: onSaveExternal,
  proposalId,
}: PreTaxReturnLimitModalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const formMethods = useForm<PreTaxReturnLimitModalForm>({
    defaultValues: DEFAULT_PRE_TAX_RETURN_LIMIT_MODAL_FORM,
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
    reset,
  } = formMethods;

  const { loading } = usePreTaxReturnLimitModalQuery({
    variables: { proposalId },
    onError: (error) => {
      showFeedback(
        'Could not fetch pre-tax return limits.  Please refresh the page and try again'
      );
      reportError('Encountered error fetching pre-tax return limits', error);
    },
    onCompleted: (data) => {
      reset(mapPreTaxReturnLimitQueryToForm(data), {
        keepDefaultValues: false,
      });
    },
  });

  const onSave = handleSubmit((formData) => {
    onSaveExternal(formData);
    onCloseExternal();
    // because the limits aren't saved into the apollo cache, it's necessary to reset the default
    // state to the new values in case the user returns to the modal, makes changes, and then closes the
    // modal without saving -- this way, the modal is reset to the most-recently saved values
    reset(formData, { keepDefaultValues: false });
  });

  const onClose = useCallback(() => {
    reset();
    onCloseExternal();
  }, [onCloseExternal, reset]);

  const actions = useMemo(() => {
    return (
      <Stack direction="row">
        <Button variant="secondary" onClick={onClose} size="md">
          Cancel
        </Button>
        <Button variant="primary" onClick={onSave} size="md">
          Save limits
        </Button>
      </Stack>
    );
  }, [onClose, onSave]);

  return (
    <Modal
      heading="Pre-tax return limits"
      isOpen={isOpen}
      onClose={onClose}
      actions={actions}
    >
      <FormFieldsDisabledProvider isDisabled={isSubmitting || loading}>
        <FormProvider {...formMethods}>
          <PreTaxReturnLimitModalBody />
        </FormProvider>
      </FormFieldsDisabledProvider>
    </Modal>
  );
}
