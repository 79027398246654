import { Stack } from '@mui/material';
import { useCallback } from 'react';

import { Legend } from '@/components/charts/Legend/Legend';
import { useEstateWaterfallSummaryLegendSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useEstateWaterfallSummaryLegend.hook';
import { useWaterfallSummaryBarChartSections } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/hooks/useWaterfallSummaryBarChartSections';
import { WaterfallSection } from '@/modules/estateWaterfall/components/EstateWaterfallSummaryBarCharts/types';
import { useWaterfallSummaryContext } from '@/modules/estateWaterfall/contexts/waterfallSummary.context';
import { GetWaterfallSummary_EstateWaterfallFragment } from '@/modules/estateWaterfall/graphql/GetWaterfallSummary.generated';
import { AfterDeath } from '@/types/schema';

import { WaterfallSummaryTable } from './WaterfallSummaryTable/WaterfallSummaryTable';

export interface WaterfallLoadedContentProps {
  waterfall: GetWaterfallSummary_EstateWaterfallFragment;
}
export const WaterfallLoadedContent = ({
  waterfall,
}: WaterfallLoadedContentProps) => {
  const {
    summaryPanel: { openModal },
  } = useWaterfallSummaryContext();

  const openEstateTaxSummaryPanel = useCallback(
    (afterDeath: AfterDeath) => {
      openModal({ type: 'estateTax', afterDeath });
    },
    [openModal]
  );

  const onOpenSectionSummaryPanel = useCallback(
    ({ afterDeath }: WaterfallSection) => {
      openModal({ type: 'estateTax', afterDeath });
    },
    [openModal]
  );

  const waterfallSections = useWaterfallSummaryBarChartSections({
    waterfall,
    onOpenSectionSummaryPanel,
  });

  const legendSections =
    useEstateWaterfallSummaryLegendSections(waterfallSections);

  return (
    <>
      <WaterfallSummaryTable
        onOpenSectionSummaryPanel={openEstateTaxSummaryPanel}
      />
      <Stack direction="row" alignItems="center">
        <Legend sections={legendSections} />
      </Stack>
    </>
  );
};
