import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useNavigate } from 'react-router-dom';

import { Divider } from '@/components/Divider';
import { TransparentButton } from '@/components/form/baseInputs/Button/TransparentButton';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { TableCell } from '@/components/lists/TableCell';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatPercent } from '@/utils/formatting/percent';

import { EntityDetail_OwnerOfStakeFragment } from '../graphql/EntityDetailPage.generated';

function getFormattedOwnedBusinessDescription(
  stake: EntityDetail_OwnerOfStakeFragment
) {
  const formattedOwnershipPercentage =
    formatPercent(stake.ownershipPercentage ?? new Decimal(0)) + '% ownership';
  const formattedOwnershipValue = formatCurrency(stake.ownedValue, {
    notation: 'compact',
  });
  return `${formattedOwnershipPercentage} (${formattedOwnershipValue})`;
}

function getDescriptiveCopy(ownerKind: 'individual' | 'entity') {
  return `This ${
    ownerKind === 'entity' ? 'entity' : 'person'
  } holds ownership of the following business entities:`;
}

export interface OwnedBusinessEntitiesListProps {
  ownedStakes?: EntityDetail_OwnerOfStakeFragment[];
  ownerKind?: 'individual' | 'entity';
}

export function OwnedBusinessEntitiesList({
  ownedStakes,
  ownerKind = 'entity',
}: OwnedBusinessEntitiesListProps) {
  const navigate = useNavigate();
  const { householdId } = useHouseholdDetailsContext();
  if (!ownedStakes || ownedStakes.length === 0) return null;
  if (!householdId) {
    // this sometimes happens on the first render pass, but then once the householdId is loaded in the context,
    // it works out
    return null;
  }
  return (
    <Stack spacing={1} sx={{ backgroundColor: COLORS.NAVY[50], p: 3 }}>
      <Typography variant="subtitle2">
        {getDescriptiveCopy(ownerKind)}
      </Typography>
      <Stack
        divider={
          // i have no idea why, but this divider is not rendering unless you explicitly set
          // the height to 2px.
          <Divider light sx={{ height: '2px' }} />
        }
      >
        {ownedStakes.map((stake) => {
          return (
            <TransparentButton
              key={stake.id}
              onClick={() => {
                const entityPath = getCompletePathFromRouteKey(
                  ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS,
                  { entityId: stake.ownedEntity?.id ?? '', householdId }
                );
                navigate(entityPath);
              }}
            >
              <TableCell
                heading={stake.ownedEntity?.subtype?.displayName ?? ''}
                description={getFormattedOwnedBusinessDescription(stake)}
                RightContent={
                  <ChevronRightIcon size={20} color={COLORS.GRAY[400]} />
                }
              />
            </TransparentButton>
          );
        })}
      </Stack>
    </Stack>
  );
}

export function ViewOnlyOwnedBusinessEntitiesList({
  ownedStakes,
  ownerKind = 'entity',
}: OwnedBusinessEntitiesListProps) {
  if (!ownedStakes || ownedStakes.length === 0) return null;
  return (
    <Stack spacing={1} sx={{ backgroundColor: COLORS.NEUTRAL_GRAY[300], p: 3 }}>
      <Typography variant="subtitle2">
        {getDescriptiveCopy(ownerKind)}
      </Typography>
      <Stack
        divider={
          // i have no idea why, but this divider is not rendering unless you explicitly set
          // the height to 2px.
          <Divider light sx={{ height: '2px' }} />
        }
      >
        {ownedStakes.map((stake) => {
          return (
            <TableCell
              key={stake.id}
              heading={stake.ownedEntity?.subtype?.displayName ?? ''}
              description={getFormattedOwnedBusinessDescription(stake)}
            />
          );
        })}
      </Stack>
    </Stack>
  );
}
