import {
  array,
  boolean as booleanType,
  date,
  Describe,
  nullable,
  number,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedUpdateEntityInput,
  AugmentedUpdateGratTrustInput,
  UpdateGratTrustInput,
} from '@/types/schema';

import {
  beneficiarySchema,
  commonUpdateEntityProperties,
  createAnnuitySchema,
  createEntityStateTaxSchema,
  decimal,
  grantorSchema,
  gstStatusSchema,
  inEstateStatusSchema,
  taxStatusSchema,
  trustAdvisorSchema,
  trusteeSchema,
  updateAccountSchema,
  updateAnnuitySchema,
} from './types';
import { $getValidatedInput } from './utils';

// we're not yet supporting creating GRATs through this form, just updating them,
// hence the lack of `create` schemas

const updateGratTrustInput: Describe<UpdateGratTrustInput> = object({
  description: optional(nullable(string())),
  displayName: optional(nullable(string())),
  effectiveDate: optional(nullable(date())),
  gstStatus: gstStatusSchema,
  inEstateStatus: inEstateStatusSchema,
  legalName: optional(nullable(string())),
  grantorID: optional(nullable(string())),
  taxStatus: taxStatusSchema,
  trustJurisdictionStateCode: optional(nullable(string())),
  clearEffectiveDate: optional(nullable(booleanType())),
  clearLegalName: optional(nullable(booleanType())),
  clearTrustJurisdictionStateCode: optional(nullable(booleanType())),
  clearBeneficiaries: optional(nullable(booleanType())),
  clearGrantor: optional(nullable(booleanType())),
  clearSuccessorTrustees: optional(nullable(booleanType())),
  clearTrustAdvisors: optional(nullable(booleanType())),
  clearTrustees: optional(nullable(booleanType())),
  clearDescription: optional(nullable(booleanType())),
  officialInterestRatePercent: optional(nullable(decimal)),
  taxableGift: optional(nullable(decimal)),
  termDurationYears: optional(nullable(number())),
  clearGstStatus: optional(nullable(booleanType())),
  clearTaxStatus: optional(nullable(booleanType())),
  clearAnnuities: optional(nullable(booleanType())),
});

const updateGratTrustSchema: Describe<AugmentedUpdateGratTrustInput> = object({
  id: string(),
  update: updateGratTrustInput,
  withBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withTrustees: optional(nullable(array(trusteeSchema))),
  withSuccessorTrustees: optional(nullable(array(trusteeSchema))),
  withTrustAdvisors: optional(nullable(array(trustAdvisorSchema))),
  withGrantor: optional(nullable(grantorSchema)),
  updateDesignerAccount: optional(nullable(updateAccountSchema)),
  withAnnuities: optional(nullable(array(createAnnuitySchema))),
  updateAnnuities: optional(nullable(array(updateAnnuitySchema))),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object(commonUpdateEntityProperties),
  updateGratTrust: optional(nullable(updateGratTrustSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export function validateGratTrustUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'GRAT create');
}
