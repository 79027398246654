import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { useEffect } from 'react';

import {
  GroupedHeaderList,
  GroupedHeaderListProps,
} from '@/components/lists/GroupedHeaderList/GroupedHeaderList';
import { TableCell } from '@/components/lists/TableCell';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { EmptyListEditEntityActionCard } from '@/modules/entities/components/EmptyListEditEntityActionCard';
import { EntitySubformCategory } from '@/modules/entities/EntitySubforms/entitySubformConfigs';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { EntityDetail_OwnedByStakeFragment } from '../../graphql/EntityDetailPage.generated';
import { getFormattedOwnedByStake } from './BusinessEntityOwnershipList.utils';

type HeaderListItem = ReturnType<typeof getFormattedOwnedByStake>;

export interface BusinessEntityOwnershipListProps
  extends Partial<GroupedHeaderListProps<HeaderListItem>> {
  ownershipAsOfDate?: Date;
  ownedByStakes?: EntityDetail_OwnedByStakeFragment[];
  currentValue?: Decimal;
  onShowEditEntity?: () => void;
}

export function BusinessEntityOwnershipList({
  ownedByStakes,
  ownershipAsOfDate,
  currentValue,
  onShowEditEntity,
  ...listProps
}: BusinessEntityOwnershipListProps) {
  const isViewOnly = useViewOnly();

  useEffect(() => {
    if (!isViewOnly && (ownedByStakes || []).length > 0) {
      onShowEditEntity?.();
    }
  }, [isViewOnly, onShowEditEntity, ownedByStakes]);

  if (!isViewOnly && !ownedByStakes?.length) {
    return (
      <EmptyListEditEntityActionCard
        heading="Specify ownership details"
        buttonText="Add ownership details"
        description="No ownership details have been specified for this entity"
        editSubformCategory={EntitySubformCategory.BUSINESS_ENTITY_DETAILS}
      />
    );
  }

  return (
    <GroupedHeaderList<HeaderListItem>
      rowGroups={[
        {
          heading: compact([
            `Current ownership`,
            ownershipAsOfDate &&
              `(as of ${formatDateToMonDDYYYY(ownershipAsOfDate)})`,
          ]).join(' '),
          noItemsText: 'No ownership information',
          items:
            ownedByStakes?.map((stake) =>
              getFormattedOwnedByStake(stake, currentValue ?? new Decimal(0))
            ) ?? [],
        },
      ]}
      withDividers
      ItemComponent={({ item }) => <TableCell {...item} />}
      {...listProps}
    />
  );
}
