import { ListItemText, Menu, MenuItem } from '@mui/material';
import { isEmpty } from 'lodash';
import { MouseEvent, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import {
  Button,
  ButtonProps,
} from '@/components/form/baseInputs/Button/Button';
import { ChevronDownIcon } from '@/components/icons/ChevronDownIcon';
import { ChevronUpIcon } from '@/components/icons/ChevronUpIcon';
import { generateUniqueId } from '@/components/utils/inputUtils';

export interface DropdownButtonProps extends ButtonProps {
  buttonContent: string | JSX.Element;
  ariaLabel?: string;
  items: {
    name?: string;
    component?: JSX.Element;
    clickHandler?: () => void;
    isLastInGroup?: boolean;
  }[];
  name: string;
  showArrow: boolean;
  showDivider?: boolean;
}
const useStyles = makeStyles()((theme) => ({
  list: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

function shouldShowDivider(
  idx: number,
  length: number,
  isLastInGroup?: boolean
): boolean {
  const isLast = idx === length - 1;

  // Do not show the divider when the item is the last
  // item in the dropdown or if the item is explicitly
  // not the last in its group
  if (isLast || isLastInGroup === false) {
    return false;
  }

  // Show the divider for all other items
  return true;
}

export const DropdownButton = ({
  buttonContent,
  ariaLabel,
  items,
  name,
  showArrow,
  showDivider,
  ...rest
}: DropdownButtonProps) => {
  const menuId = generateUniqueId(`${name}DropdownMenu_`);
  const buttonId = generateUniqueId(`${name}DropdownButton_`);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { classes } = useStyles();
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const hasItems = !isEmpty(items);

  return (
    <>
      <Button
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        aria-label={ariaLabel ?? ''}
        disabled={!hasItems}
        endIcon={
          showArrow ? (open ? ChevronUpIcon : ChevronDownIcon) : undefined
        }
        {...rest}
      >
        {hasItems ? buttonContent : 'No items'}
      </Button>

      {hasItems && (
        <Menu
          id={menuId}
          MenuListProps={{
            'aria-labelledby': buttonId,
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          classes={{
            list: classes.list,
          }}
        >
          {items.map((item, idx) => {
            return (
              <MenuItem
                key={`menu-item-${idx}`}
                divider={
                  shouldShowDivider(idx, items.length, item.isLastInGroup)
                    ? showDivider
                    : false
                }
                onClick={() => {
                  item.clickHandler?.();
                  handleClose();
                }}
              >
                {item.name ? <ListItemText>{item.name}</ListItemText> : null}
                {item.component ? item.component : null}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};
