import Decimal from 'decimal.js';

import {
  BasicInformation_Grantor,
  Fields as BasicInformationSubformFieldsType,
  NamespaceType as BasicInformationSubformNamespaceType,
  SubformValues as BasicInformationSubformValuesType,
} from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import { BasicInformation_GrantorFragment } from '@/modules/entities/BasicInformationSubform/graphql/GetBasicInformation.generated';

// Union of the subform namespace types that could have the grantors field.
export type GrantorsFieldSubformsNamespaceType =
  BasicInformationSubformNamespaceType;

// Union of the subform values types that could have the grantors field.
export type GrantorsFieldSubformValuesType = BasicInformationSubformValuesType;

// Union of the subform fields types that could have the grantors field.
// This is basically a wrapper around { [NAMESPACE_TYPE] : GrantorsFieldSubformValuesType }
export type GrantorsFieldSubformsFieldsType = BasicInformationSubformFieldsType;

// This is a proper subset of the VariantType from TrustDetailsSubform.types.ts
// which only includes the variants that are relevant to the grantors field.
export const GRANTORS_FIELD_VARIANTS = [
  'default',
  'MultipleGrantorWithPercent',
  'SingleGrantor',
] as const;
export type GrantorsFieldVariantType = (typeof GRANTORS_FIELD_VARIANTS)[number];

export type GrantorsFieldType =
  | GrantorsFieldTypes_MultipleGrantor
  | GrantorsFieldTypes_SingleGrantor;

// The subform using the grantors field will need to extend this type.
// This is for the multiple grantors variant.
export interface GrantorsFieldTypes_MultipleGrantor {
  grantors: BasicInformation_Grantor[];
  _grantorAttributionSum?: Decimal | null;
  _hasNonClientGrantor: boolean;
}

// The subform using the grantors field will need to extend this type.
// This is for the single grantor variant.
export interface GrantorsFieldTypes_SingleGrantor {
  grantor: BasicInformation_Grantor | '';
  // we use this synthetic property to communicate to the tax status form that it should show an indicator
  // about how we are handling the attribution of this trust's tax status
  _hasNonClientGrantor: boolean;
}

// The subform using the grantors field will need to include these fields in
// their default values. This is for the multiple grantors variant.
export const grantorsFieldDefaultValue_MultipleGrantor: GrantorsFieldTypes_MultipleGrantor =
  {
    grantors: [],
    _grantorAttributionSum: new Decimal(0),
    _hasNonClientGrantor: false,
  };

// The subform using the grantors field will need to include these fields in
// their default values. This is for the single grantor variant.
export const grantorsFieldDefaultValue_SingleGrantor: GrantorsFieldTypes_SingleGrantor =
  {
    grantor: {
      clientProfileId: '',
    },
    _hasNonClientGrantor: false,
  };

// This is the union type of the minimum shape of the data that we need to fetch
// for a Trust entity, in order to set the grantors field existing data.
export interface GrantorsFieldTrustData {
  id: string;
  // gratGrantor field will be set if the trust is a GRAT
  gratGrantor?: BasicInformation_GrantorFragment | null;
  // slatGrantor field will be set if the trust is a SLAT
  slatGrantor?: BasicInformation_GrantorFragment | null;
  // grantors field will be set if the trust is any other type
  grantors?: BasicInformation_GrantorFragment[] | null;
}
