// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import { GiftingProposalProjectionFragmentDoc } from '../../../../modules/gifting/proposal/graphql/GiftingProposalProjection.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateGiftProposalMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateProposalInput;
}>;


export type CreateGiftProposalMutation = { __typename?: 'Mutation', createProposalV2: { __typename?: 'Proposal', id: string, giftingProposal?: { __typename?: 'GiftingProposal', id: string, giftingScenarios?: Array<{ __typename?: 'GiftingProposalScenario', id: string, projections: { __typename?: 'GiftingProposalProjections', inEstateValueAfterTax: CurrencyUSD, inEstateValueBeforeTax: CurrencyUSD, outOfEstateCharitableValue: CurrencyUSD, outOfEstateFamilyValue: CurrencyUSD, outOfEstateTotalValue: CurrencyUSD, taxableGift: CurrencyUSD, totalValue: CurrencyUSD, remainingLifetimeExemption: CurrencyUSD, lifetimeExemptionAppliedTowardGiftTax: CurrencyUSD, giftTax: CurrencyUSD, scenarioId: string, yearInEstatePortfolioDepleted?: number | null, scenario: { __typename?: 'GiftingProposalScenario', order: number, displayName: string, isDefault: boolean, isBaseline: boolean, id: string }, estateTaxSummary: { __typename?: 'TaxSummary', _cacheKey: string, federalTax?: { __typename?: 'TaxDetail', _cacheKey: string, deductions: CurrencyUSD, exemptionAvailable: CurrencyUSD, exemptionCliff?: CurrencyUSD | null, exemptionUsed: CurrencyUSD, portableExemption?: CurrencyUSD | null, stateCode?: Types.StateCode | null, stateDisclosure?: string | null, tax: CurrencyUSD, taxableAmount: CurrencyUSD, taxableAmountAfterExemption: CurrencyUSD, taxableTransfers: CurrencyUSD, totalExemption: CurrencyUSD, taxableAmountOverCliff?: boolean | null } | null, stateTax?: Array<{ __typename?: 'TaxDetail', _cacheKey: string, deductions: CurrencyUSD, exemptionAvailable: CurrencyUSD, exemptionCliff?: CurrencyUSD | null, exemptionUsed: CurrencyUSD, portableExemption?: CurrencyUSD | null, stateCode?: Types.StateCode | null, stateDisclosure?: string | null, tax: CurrencyUSD, taxableAmount: CurrencyUSD, taxableAmountAfterExemption: CurrencyUSD, taxableTransfers: CurrencyUSD, totalExemption: CurrencyUSD, taxableAmountOverCliff?: boolean | null }> | null } } }> | null } | null } };


export const CreateGiftProposalDocument = gql`
    mutation CreateGiftProposal($input: AugmentedCreateProposalInput!) {
  createProposalV2(input: $input) {
    id
    giftingProposal {
      id
      giftingScenarios {
        id
        projections {
          ...GiftingProposalProjection
        }
      }
    }
  }
}
    ${GiftingProposalProjectionFragmentDoc}`;
export type CreateGiftProposalMutationFn = Apollo.MutationFunction<CreateGiftProposalMutation, CreateGiftProposalMutationVariables>;

/**
 * __useCreateGiftProposalMutation__
 *
 * To run a mutation, you first call `useCreateGiftProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGiftProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGiftProposalMutation, { data, loading, error }] = useCreateGiftProposalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGiftProposalMutation(baseOptions?: Apollo.MutationHookOptions<CreateGiftProposalMutation, CreateGiftProposalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGiftProposalMutation, CreateGiftProposalMutationVariables>(CreateGiftProposalDocument, options);
      }
export type CreateGiftProposalMutationHookResult = ReturnType<typeof useCreateGiftProposalMutation>;
export type CreateGiftProposalMutationResult = Apollo.MutationResult<CreateGiftProposalMutation>;
export type CreateGiftProposalMutationOptions = Apollo.BaseMutationOptions<CreateGiftProposalMutation, CreateGiftProposalMutationVariables>;
export const Operations = {
  Mutation: {
    CreateGiftProposal: 'CreateGiftProposal' as const
  }
}