import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useChartColorDefinitions } from '@/components/charts/constants';
import { Legend } from '@/components/charts/Legend/Legend';
import { useFormContext } from '@/components/react-hook-form';
import { GiftProposalProjection } from '@/modules/gifting/proposal/giftProposal.types';
import { getTaxMetricsForProjection } from '@/modules/gifting/proposal/giftProposal.utils';
import { maxDecimalJS, sumDecimalJS } from '@/utils/decimalJSUtils';

import { useProjectionsForProposal } from '../../../../hooks/useProjectionsForProposal';
import { GiftDesignerModelScenariosFormShape } from '../../GiftDesignerModelScenariosForm.types';
import {
  GiftScenarioBarChart,
  GiftScenarioSectionKind,
  GiftScenarioSectionKindCopy,
} from './GiftScenarioBarChart';
import { NoScenariosBarChart } from './NoScenariosBarChart';

interface GiftScenarioBarChartsProps {
  proposalId: string;
  projections?: GiftProposalProjection[];
}

const useSectionColors = (): Record<GiftScenarioSectionKind, string> => {
  const dataVisColors = useChartColorDefinitions();

  return {
    [GiftScenarioSectionKind.InEstateAfterTax]:
      dataVisColors.PRIMARY.backgroundColor,
    [GiftScenarioSectionKind.InEstatePreTax]:
      dataVisColors.PRIMARY.backgroundColor,
    [GiftScenarioSectionKind.OutOfEstateFamily]:
      dataVisColors.SECONDARY.backgroundColor,
    [GiftScenarioSectionKind.OutOfEstateCharity]:
      dataVisColors.TERTIARY.backgroundColor,
    [GiftScenarioSectionKind.GiftAndEstateTax]:
      dataVisColors.NEGATIVE.backgroundColor,
    [GiftScenarioSectionKind.GiftTax]: dataVisColors.NEGATIVE.backgroundColor,
  };
};

export function GiftScenarioBarCharts({
  proposalId,
  projections: externalProjections,
}: GiftScenarioBarChartsProps) {
  const { projections } = useProjectionsForProposal(
    proposalId,
    externalProjections
  );
  const { control } = useFormContext<GiftDesignerModelScenariosFormShape>();
  const shouldShowAfterEstateTax = useWatch({
    control,
    name: 'showAfterEstateTax',
  });

  const sectionColors = useSectionColors();

  const legendSections = useMemo(() => {
    let sections = [
      GiftScenarioSectionKind.OutOfEstateFamily,
      GiftScenarioSectionKind.OutOfEstateCharity,
    ].map((label) => ({
      color: sectionColors[label],
      label: GiftScenarioSectionKindCopy[label],
    }));

    if (shouldShowAfterEstateTax) {
      sections = [
        {
          color: sectionColors[GiftScenarioSectionKind.InEstateAfterTax],
          label:
            GiftScenarioSectionKindCopy[
              GiftScenarioSectionKind.InEstateAfterTax
            ],
        },
        ...sections,
      ];
    } else {
      sections = [
        {
          color: sectionColors[GiftScenarioSectionKind.InEstatePreTax],
          label:
            GiftScenarioSectionKindCopy[GiftScenarioSectionKind.InEstatePreTax],
        },
        ...sections,
      ];
    }

    if (shouldShowAfterEstateTax) {
      sections.push({
        color: sectionColors[GiftScenarioSectionKind.GiftAndEstateTax],
        label:
          GiftScenarioSectionKindCopy[GiftScenarioSectionKind.GiftAndEstateTax],
      });
    } else {
      sections.push({
        color: sectionColors[GiftScenarioSectionKind.GiftTax],
        label: GiftScenarioSectionKindCopy[GiftScenarioSectionKind.GiftTax],
      });
    }

    return sections;
  }, [sectionColors, shouldShowAfterEstateTax]);

  const maxScenarioBarMagnitude = useMemo(() => {
    const sums =
      projections?.map((p) => {
        const { federalEstateTax, giftTax, stateEstateTax } =
          getTaxMetricsForProjection(p);

        const sum = sumDecimalJS([
          p.inEstateValueAfterTax,
          p.outOfEstateCharitableValue,
          p.outOfEstateFamilyValue,
          federalEstateTax,
          stateEstateTax,
          giftTax,
        ]);

        return sum;
      }) ?? [];

    return maxDecimalJS(sums, new Decimal(0));
  }, [projections]);

  if (!projections?.length) {
    return <NoScenariosBarChart />;
  }

  return (
    <Stack>
      <Stack spacing={1}>
        {projections.map((p, idx) => (
          <GiftScenarioBarChart
            projection={p}
            idx={idx}
            key={`projection-${idx}`}
            shouldShowAfterEstateTax={shouldShowAfterEstateTax}
            maxScenarioBarMagnitude={maxScenarioBarMagnitude}
          />
        ))}
      </Stack>
      <Stack direction="row" alignItems="center" py={2}>
        <Legend sections={legendSections} />
      </Stack>
    </Stack>
  );
}
