import { Maybe } from 'graphql/jsutils/Maybe';

import { getNodes } from '@/utils/graphqlUtils';

import {
  EntityMap_BeneficiaryConnectionFragment,
  EntityMap_ClientProfileFragment,
} from '../graphql/EntityMap.generated';
import {
  EntityGraph,
  EntityGraphEdgeAttributes,
  EntityGraphNodeAttributes,
} from '../types';
import { getBeneficiaryEdgeLabelValue } from '../utils/beneficiaries';
import { createEdge, EdgeInput } from '../utils/edges';
import { $getSupportedEntityNodeTypename } from '../utils/nodes';
import { entitySubtypeToNodeCategorization } from './constants';
import {
  entitySubtypeToTile,
  individualBeneficiaryTile,
  organizationBeneficiaryTile,
} from './tiles';

export function createBeneficiaryEdge(
  graph: EntityGraph,
  b: EntityMap_BeneficiaryConnectionFragment,
  edge: EdgeInput
) {
  const accessParameters = getNodes(b.accessParameters) ?? [];
  return createEdge(graph, {
    ...edge,
    data: {
      ...edge.data,
      edgeLabel: {
        variant: 'primary',
        label:
          accessParameters.length > 1 ? 'Multiple distributions' : undefined,
        // value will evaluate to empty string if there are none, or 2+ distributions
        value: getBeneficiaryEdgeLabelValue(b),
        ...(typeof edge.data?.edgeLabel === 'object'
          ? edge.data?.edgeLabel
          : {}),
      },
    },
  });
}

export function createBeneficiaryNodeFromClientProfile(
  _graph: EntityGraph,
  individual: EntityMap_ClientProfileFragment
): EntityGraphNodeAttributes {
  const node = individualBeneficiaryTile(individual);
  return { node, data: individual, categorizationType: 'INDIVIDUAL' };
}

export function createBeneficiaryNode(
  graph: EntityGraph,
  b?: EntityMap_BeneficiaryConnectionFragment | null
): EntityGraphNodeAttributes | null {
  if (!b) return null;

  const {
    individualBeneficiariesById,
    organizationBeneficiariesById,
    entitiesById,
  } = graph.getAttribute('byId');

  const individual = individualBeneficiariesById[b.individual?.id ?? ''];
  if (individual) {
    return createBeneficiaryNodeFromClientProfile(graph, individual);
  }

  const org = organizationBeneficiariesById[b.organization?.id ?? ''];
  if (org) {
    return {
      node: organizationBeneficiaryTile(org),
      data: org,
      categorizationType: 'INDIVIDUAL',
    };
  }

  const entity = entitiesById[b.entity?.id ?? ''];
  if (entity) {
    const supporteEntityTypename = $getSupportedEntityNodeTypename(
      entity.subtype.__typename!
    );
    const node = entitySubtypeToTile[supporteEntityTypename](entity);
    return {
      node,
      data: entity,
      categorizationType:
        entitySubtypeToNodeCategorization[supporteEntityTypename],
    };
  }

  return null;
}

export function addBeneficiaries(
  graph: EntityGraph,
  source: string,
  beneficiaries: Maybe<Maybe<EntityMap_BeneficiaryConnectionFragment>[]> = [],
  edgeInput: Omit<EdgeInput, 'source' | 'target'> = {},
  edgeAttributes: Partial<EntityGraphEdgeAttributes> = {}
) {
  beneficiaries?.forEach((b) => {
    const node = createBeneficiaryNode(graph, b);
    if (!node || !b) return;

    graph.addNodeSafe(node.node.id, node);
    const target = node.node.id;
    const edge = createBeneficiaryEdge(graph, b, {
      source,
      target,
      ...edgeInput,
    });
    graph.addEdgeSafe(source, target, {
      edge,
      type: 'beneficiary',
      ...edgeAttributes,
    });
  });
}
