import { useSearchParams } from 'react-router-dom';

import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { useRouteModal } from '@/components/modals/RouteModal/useRouteModal';
import { Loader } from '@/components/progress/Loader/Loader';
import { useRequiredParam } from '@/hooks/useRequiredParam';
import { NavigationKey } from '@/navigation/navigationUtils';
import { COLORS } from '@/styles/tokens/colors';

import { useEstateTaxScenariosContext } from './contexts/estateTaxScenarios.context';
import { EstateTaxScenariosProvider } from './contexts/estateTaxScenarios.provider';
import { EstateTaxScenarios } from './EstateTaxScenarios';
import { FIRST_DEATH_CLIENT_ID_SEARCH_PARAM } from './EstateTaxScenarios.constants';

export interface EstateTaxScenariosTrowserProps {
  returnRouteKey?: NavigationKey;
}

function EstateTaxScenariosTrowserInner({
  returnRouteKey,
}: EstateTaxScenariosTrowserProps) {
  const { onRequestClose, isOpen } = useRouteModal(returnRouteKey);
  const { loading } = useEstateTaxScenariosContext();

  return (
    <FullScreenModal isOpen={isOpen} backdropSx={{ bgcolor: COLORS.GRAY[50] }}>
      {loading ? (
        <Loader
          boxProps={{
            sx: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            },
          }}
        />
      ) : (
        <EstateTaxScenarios onCancel={onRequestClose} />
      )}
    </FullScreenModal>
  );
}

/**
 * @description EstateTaxScenariosTrowserPage is a component that appears to be a trowser, but is actually a
 * standalone page and derives its state from URL params.
 */
export function EstateTaxScenariosTrowserPage(
  props: EstateTaxScenariosTrowserProps
) {
  const householdId = useRequiredParam('householdId');
  const [searchParams] = useSearchParams();
  const activeClientId = searchParams.get(FIRST_DEATH_CLIENT_ID_SEARCH_PARAM);
  if (!activeClientId) {
    throw new Error(
      `Missing required search param: ${FIRST_DEATH_CLIENT_ID_SEARCH_PARAM}`
    );
  }

  return (
    <EstateTaxScenariosProvider
      activeClientId={activeClientId}
      householdId={householdId}
    >
      <EstateTaxScenariosTrowserInner {...props} />
    </EstateTaxScenariosProvider>
  );
}
