import { ComponentType } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { IconProps } from '@/components/icons/ChevronDownIcon';
import { ActionCard } from '@/components/layout/ActionCard/ActionCard';
import { COLORS } from '@/styles/tokens/colors';

export interface AddNewAnythingActionCardProps {
  description: React.ReactNode;
  Icon: ComponentType<IconProps>;
  onClick: () => void;
}

export function AddNewAnythingActionCard({
  description,
  Icon,
  onClick,
}: AddNewAnythingActionCardProps) {
  return (
    <ActionCard
      sx={{ flex: 1 }}
      description={description}
      icon={<Icon size={70} color={COLORS.GRAY[400]} />}
      actions={
        <Button onClick={onClick} variant="primary" size="lg" fullWidth>
          Add new
        </Button>
      }
    />
  );
}
