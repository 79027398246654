import {
  DropdownButton,
  DropdownButtonProps,
} from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { useModalState } from '@/hooks/useModalState';
import { LogNewGiftModal } from '@/modules/gifting/LogNewGiftModal/LogNewGiftModal';
import { RecordExemptionUsedModal } from '@/modules/gifting/RecordExemptionUsedModal/RecordExemptionUsedModal';

export interface UpdateGiftingButton extends Partial<DropdownButtonProps> {
  householdId: string;
}

export const UpdateGiftingButton = ({
  householdId,
  ...props
}: UpdateGiftingButton) => {
  const [
    { isModalOpen: isNewGiftModalOpen },
    { closeModal: closeNewGiftModal, openModal: openNewGiftModal },
  ] = useModalState();
  const [
    { isModalOpen: isRecordExemptionUsedModalOpen },
    {
      closeModal: closeRecordExemptionUsedModal,
      openModal: openRecordExemptionUsedModal,
    },
  ] = useModalState();

  return (
    <>
      <DropdownButton
        name="UpdateGiftingButton"
        variant="secondary"
        showArrow
        size="sm"
        buttonContent="Update exemptions"
        items={[
          {
            name: 'Add gift',
            clickHandler: openNewGiftModal,
          },
          {
            name: 'Record exemption used',
            clickHandler: openRecordExemptionUsedModal,
          },
        ]}
        {...props}
      />
      <LogNewGiftModal
        isOpen={isNewGiftModalOpen}
        onClose={closeNewGiftModal}
        householdId={householdId}
      />
      <RecordExemptionUsedModal
        isOpen={isRecordExemptionUsedModalOpen}
        onClose={closeRecordExemptionUsedModal}
        householdId={householdId}
      />
    </>
  );
};
