import { isEmpty } from 'lodash';
import { useMemo } from 'react';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useMinimumLoading } from '@/hooks/useMinimumLoading';
import { EntityStage } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { BusinessEntityKinds, TrustEntityKinds } from '../constants';
import {
  EntityMapQueryVariables,
  useEntityMapQuery as useEntityMapQueryInternal,
} from '../graphql/EntityMap.generated';

export interface UseEntityMapQueryProps {
  householdId: string;
  entityGraphViewId: string;
}

export function getEntityMapQueryVariables({
  entityGraphViewId,
  householdId,
}: UseEntityMapQueryProps): EntityMapQueryVariables {
  return {
    entityGraphViewWhere: { id: entityGraphViewId },
    allEntityGraphViewsWhere: { hasHouseholdWith: [{ id: householdId }] },
    householdWhere: { id: householdId },
    entitiesWhere: {
      stage: EntityStage.Active,
      kindIn: [...TrustEntityKinds, ...BusinessEntityKinds],
    },
  };
}

export function useEntityMapQuery(props: UseEntityMapQueryProps) {
  const { householdId } = props;
  const { showFeedback } = useFeedback();
  const {
    data,
    loading: isFetchingFromNetwork,
    ...rest
  } = useEntityMapQueryInternal({
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: getEntityMapQueryVariables(props),
    onError: (error) => {
      showFeedback(
        `Sorry, we failed to load the entity map. Please refresh the page and try again.`
      );
      diagnostics.error('entity map load failure', error, {
        householdId,
      });
    },
  });

  const household = useMemo(() => {
    return getNodes(data?.households)[0] ?? null;
  }, [data?.households]);

  const allEntityGraphViews = useMemo(() => {
    return getNodes(data?.allEntityGraphViews);
  }, [data?.allEntityGraphViews]);

  const entityGraphView = useMemo(() => {
    return getNodes(data?.entityGraphViews)[0];
  }, [data?.entityGraphViews]);

  const hasData = !!household && !!entityGraphView;
  const isRefetching = isFetchingFromNetwork && hasData;

  const isEmptyMap = isEmpty(getNodes(household?.entities));

  const isInitialLoading = !hasData;
  const isUpdating = useMinimumLoading(isRefetching);

  return {
    isInitialLoading,
    isUpdating,
    isEmptyMap,
    household,
    entityGraphView,
    allEntityGraphViews,
    data,
    loading: isFetchingFromNetwork,
    ...rest,
  };
}
