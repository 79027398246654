import { useModalState } from '@/hooks/useModalState';

export function useGiftDesignerModelScenariosPageModals() {
  const [
    { isModalOpen: isTaxReturnLimitModalOpen },
    {
      openModal: openTaxReturnLimitModal,
      closeModal: closeTaxReturnLimitModal,
    },
  ] = useModalState();

  return {
    isTaxReturnLimitModalOpen,
    openTaxReturnLimitModal,
    closeTaxReturnLimitModal,
  };
}
