import Decimal from 'decimal.js';
import { compact, merge, noop } from 'lodash';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useGuardedContext } from '@/hooks/useGuardedContext';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import {
  GetTransferDestinationOptionsV2_InsurancePolicyFragment,
  GetTransferDestinationOptionsV2_OwnershipStakeFragment,
} from '../HypotheticalTransfersCard/hypotheticalTransfers/graphql/GetTransferDestinationOptions.generated';
import { TransferDirection } from '../HypotheticalTransfersCard/hypotheticalTransfers/HypotheticalTransferForm';

export interface OwnershipStakeForTransferModal {
  sourceOwnedEntityId: string;
  ownedValue: Decimal;
  display: string;
}

export interface PolicyForTransferModal {
  sourceInsurancePolicyId: string;
  display: string;
  cashValue: Decimal;
}

export interface HypotheticalTransferModalContextShape {
  householdId: string;
  waterfallId: string;
  transferId: string | undefined;
  initiatingDirection: TransferDirection | undefined;
  initiatingId: string | undefined;
  derivedData: {
    ownershipStakesByOwnerId: Record<string, OwnershipStakeForTransferModal[]>;
    policiesByEntityId: Record<string, PolicyForTransferModal[]>;
    discountRateByEntityId: Record<string, Decimal>;
    ilitValueByEntityId: Record<string, Decimal>;
  };
  setDerivedData: (
    derivedData: Partial<HypotheticalTransferModalContextShape['derivedData']>
  ) => void;
}

const HypotheticalTransferModalContext =
  createContext<HypotheticalTransferModalContextShape>({
    householdId: '',
    waterfallId: '',
    transferId: undefined,
    initiatingDirection: undefined,
    initiatingId: undefined,
    derivedData: {
      ownershipStakesByOwnerId: {},
      policiesByEntityId: {},
      discountRateByEntityId: {},
      ilitValueByEntityId: {},
    },
    setDerivedData: noop,
  });

export function apiOwnershipStakeToContextStake(
  stake: GetTransferDestinationOptionsV2_OwnershipStakeFragment
): OwnershipStakeForTransferModal {
  return {
    sourceOwnedEntityId: stake.ownedEntity?.id ?? '',
    ownedValue: stake.ownedValue,
    display: stake.ownedEntity?.subtype.displayName ?? EMPTY_CONTENT_HYPHEN,
  };
}

export function apiPolicyToContextPolicy(
  policy: GetTransferDestinationOptionsV2_InsurancePolicyFragment
): PolicyForTransferModal | [] {
  return {
    sourceInsurancePolicyId: policy.id,
    display: compact([
      policy.carrierName,
      formatCurrencyNoDecimals(policy.deathBenefitAmount),
    ]).join(', '),
    cashValue: policy.cashValue ?? new Decimal(0),
  };
}

function useHypotheticalTransferModalDerivedState() {
  const [derivedData, _setDerivedData] = useState<
    HypotheticalTransferModalContextShape['derivedData']
  >({
    ownershipStakesByOwnerId: {},
    policiesByEntityId: {},
    discountRateByEntityId: {},
    ilitValueByEntityId: {},
  });

  const setDerivedData = useCallback(
    (
      updateDerivedData: Partial<
        HypotheticalTransferModalContextShape['derivedData']
      >
    ) => {
      _setDerivedData((prevData) => {
        return merge({}, prevData, updateDerivedData);
      });
    },
    []
  );

  return useMemo(
    () => ({ derivedData, setDerivedData }),
    [derivedData, setDerivedData]
  );
}

export function HypotheticalTransferModalContextProvider({
  children,
  ...contextFields
}: PropsWithChildren<
  Omit<HypotheticalTransferModalContextShape, 'derivedData' | 'setDerivedData'>
>) {
  const { derivedData, setDerivedData } =
    useHypotheticalTransferModalDerivedState();

  const value = useMemo(() => {
    return {
      ...contextFields,
      derivedData,
      setDerivedData,
    };
  }, [contextFields, derivedData, setDerivedData]);

  return (
    <HypotheticalTransferModalContext.Provider value={value}>
      {children}
    </HypotheticalTransferModalContext.Provider>
  );
}

export function useHypotheticalTransferModalContext() {
  return useGuardedContext(
    HypotheticalTransferModalContext,
    'HypotheticalTransferModalContextProvider'
  );
}
