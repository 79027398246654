import { Stack, SxProps, Theme, Typography } from '@mui/material';

import { AlertCircleIcon } from '@/components/icons/AlertCircleIcon';
import { Link03Icon } from '@/components/icons/Link03Icon';
import { Card } from '@/components/layout/Card/Card';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { COLORS } from '@/styles/tokens/colors';
import { AsyncJobStatus } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';
import {
  formatDateToMMDDYY,
  formatDateToMonDDYYYYWithTime,
} from '@/utils/formatting/dates';

function getSyncStatusLabel(lastJobStatus: AsyncJobStatus) {
  switch (lastJobStatus) {
    case AsyncJobStatus.Queued:
    case AsyncJobStatus.InProgress:
      return 'Syncing';
    case AsyncJobStatus.Failed:
    case AsyncJobStatus.Cancelled:
      return 'Sync';
    case AsyncJobStatus.Completed:
      return 'Synced';
    default:
      throw new UnreachableError({
        case: lastJobStatus,
        message: `Unknown job status: ${lastJobStatus}`,
      });
  }
}

export interface EntitySyncBadgeProps {
  lastSyncedAt: Date | null;
  lastJobStatus: AsyncJobStatus;
  sx?: SxProps<Theme>;
}

export function EntitySyncBadge({
  lastJobStatus,
  lastSyncedAt,
  sx = {},
}: EntitySyncBadgeProps) {
  const syncStatusLabel = getSyncStatusLabel(lastJobStatus);

  const cardContent = (
    <Card variant="filled-callout" sx={{ p: 1, px: 1.5, ...sx }}>
      <Stack direction="row" alignItems="center" spacing={0.75}>
        <EntitySyncIndicator
          lastSyncFailed={lastJobStatus === AsyncJobStatus.Failed}
        />
        <Stack>
          <Typography variant="h5" component="div">
            {syncStatusLabel}
          </Typography>
          <Typography variant="subtitle2" component="div">
            {lastSyncedAt
              ? formatDateToMMDDYY(lastSyncedAt)
              : EMPTY_CONTENT_HYPHEN}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );

  return lastSyncedAt ? (
    <Tooltip
      title={`Last synced at ${formatDateToMonDDYYYYWithTime(lastSyncedAt)}`}
    >
      {cardContent}
    </Tooltip>
  ) : (
    cardContent
  );
}

interface EntitySyncFailedIndicatorProps {
  lastSyncFailed: boolean;
}

const ENTITY_SYNC_INDICATOR_DIMENSIONS = 20;
function EntitySyncIndicator({
  lastSyncFailed,
}: EntitySyncFailedIndicatorProps) {
  if (lastSyncFailed) {
    return (
      <Tooltip title="The most recent attempted sync failed. Please contact your administrator or reach out to support@withluminary.com.">
        <AlertCircleIcon
          size={ENTITY_SYNC_INDICATOR_DIMENSIONS}
          sx={{ color: COLORS.FUNCTIONAL.ERROR[700] }}
        />
      </Tooltip>
    );
  }

  return (
    <Link03Icon
      size={ENTITY_SYNC_INDICATOR_DIMENSIONS}
      sx={{ color: COLORS.NAVY[600] }}
    />
  );
}
