import type { ReactFlowState } from '@xyflow/react';

import { useGetValue } from './internal/useGetValue';

const selector = (s: ReactFlowState) =>
  Array.from(s.nodeLookup.values()).find((node) => node.dragging);

export function useDraggingNode() {
  const { value: draggingNode, get: getDraggingNode } = useGetValue(selector);

  return { draggingNode, getDraggingNode };
}
