import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useCallback, useMemo } from 'react';

import {
  ChartColorDefinitions,
  useChartColorDefinitions,
} from '@/components/charts/constants';
import {
  Section,
  StackedHorizontalBar,
} from '@/components/charts/StackedHorizontalBar/StackedHorizontalBar';
import { LinkButton } from '@/components/form/baseInputs/Link';
import { useModalState } from '@/hooks/useModalState';
import { GiftProposalProjection } from '@/modules/gifting/proposal/giftProposal.types';
import { getTaxMetricsForProjection } from '@/modules/gifting/proposal/giftProposal.utils';
import { COLORS } from '@/styles/tokens/colors';
import { FONT_WEIGHTS } from '@/styles/tokens/fonts';

import { GiftScenarioTaxCalculationModal } from '../GiftScenarioTaxCalculationModal/GiftScenarioTaxCalculationModal';
import { GiftAndEstateTaxSectionTooltip } from './GiftAndEstateTaxSectionTooltip';
import { SectionTooltip } from './SectionTooltip';

interface GiftScenarioBarChartProps {
  projection: GiftProposalProjection;
  idx: number;
  shouldShowAfterEstateTax: boolean;
  maxScenarioBarMagnitude?: Decimal;
}

export enum GiftScenarioSectionKind {
  InEstateAfterTax = 'InEstateAfterTax',
  InEstatePreTax = 'InEstatePreTax',
  OutOfEstateFamily = 'OutOfEstateFamily',
  OutOfEstateCharity = 'OutOfEstateCharity',
  GiftAndEstateTax = 'GiftAndEstateTax',
  GiftTax = 'GiftTax',
}

export const GiftScenarioSectionKindCopy = {
  [GiftScenarioSectionKind.InEstateAfterTax]: 'In-estate (after tax)',
  [GiftScenarioSectionKind.InEstatePreTax]: 'In-estate (before tax)',
  [GiftScenarioSectionKind.OutOfEstateFamily]: 'Out-of-estate (family)',
  [GiftScenarioSectionKind.OutOfEstateCharity]: 'Out-of-estate (charity)',
  [GiftScenarioSectionKind.GiftAndEstateTax]: 'Gift & estate tax',
  [GiftScenarioSectionKind.GiftTax]: 'Gift tax', // Show just gift tax when hiding estate tax
} as const satisfies Record<GiftScenarioSectionKind, string>;

type SectionWithKind = Section & {
  kind: GiftScenarioSectionKind;
};

type GiftScenarioBarChartSections = [
  SectionWithKind & {
    kind:
      | GiftScenarioSectionKind.InEstateAfterTax
      | GiftScenarioSectionKind.InEstatePreTax;
  },
  SectionWithKind & {
    kind: GiftScenarioSectionKind.OutOfEstateFamily;
  },
  SectionWithKind & {
    kind: GiftScenarioSectionKind.OutOfEstateCharity;
  },
  SectionWithKind & {
    kind: GiftScenarioSectionKind.GiftAndEstateTax;
  },
  SectionWithKind & {
    kind: GiftScenarioSectionKind.GiftTax;
  },
];

interface GetSectionsInput {
  dataVisColors: ChartColorDefinitions;
  projection: GiftProposalProjection;
  shouldShowAfterEstateTax: boolean;
  onShowCalculations: () => void;
}

const getSections = ({
  dataVisColors,
  projection,
  shouldShowAfterEstateTax,
  onShowCalculations,
}: GetSectionsInput): GiftScenarioBarChartSections => {
  const {
    inEstateValueAfterTax,
    inEstateValueBeforeTax,
    outOfEstateCharitableValue,
    outOfEstateFamilyValue,
    giftTax,
  } = projection;

  const { federalEstateTax, stateEstateTax, combinedGiftAndEstateTax } =
    getTaxMetricsForProjection(projection);

  const inEstateSectionValue = shouldShowAfterEstateTax
    ? inEstateValueAfterTax
    : inEstateValueBeforeTax;

  return [
    // In estate
    {
      value: inEstateSectionValue.toNumber(),
      color: dataVisColors.PRIMARY.backgroundColor,
      sectionTextColor: dataVisColors.PRIMARY.contrastText,
      kind: shouldShowAfterEstateTax
        ? GiftScenarioSectionKind.InEstateAfterTax
        : GiftScenarioSectionKind.InEstatePreTax,
      tooltip: (
        <SectionTooltip
          value={inEstateSectionValue}
          sectionKindCopy={
            GiftScenarioSectionKindCopy[
              shouldShowAfterEstateTax
                ? GiftScenarioSectionKind.InEstateAfterTax
                : GiftScenarioSectionKind.InEstatePreTax
            ]
          }
        />
      ),
    },
    // Out of estate (family)
    {
      value: outOfEstateFamilyValue.toNumber(),
      color: dataVisColors.SECONDARY.backgroundColor,
      sectionTextColor: dataVisColors.SECONDARY.contrastText,
      kind: GiftScenarioSectionKind.OutOfEstateFamily,
      tooltip: (
        <SectionTooltip
          value={outOfEstateFamilyValue}
          sectionKindCopy={
            GiftScenarioSectionKindCopy[
              GiftScenarioSectionKind.OutOfEstateFamily
            ]
          }
        />
      ),
    },
    // Out of estate (charity)
    {
      value: outOfEstateCharitableValue.toNumber(),
      color: dataVisColors.TERTIARY.backgroundColor,
      sectionTextColor: dataVisColors.TERTIARY.contrastText,
      kind: GiftScenarioSectionKind.OutOfEstateCharity,
      tooltip: (
        <SectionTooltip
          value={outOfEstateCharitableValue}
          sectionKindCopy={
            GiftScenarioSectionKindCopy[
              GiftScenarioSectionKind.OutOfEstateCharity
            ]
          }
        />
      ),
    },
    // All taxes (gift, state, and federal) combined (show when "Show after estate tax" is checked)
    {
      value: shouldShowAfterEstateTax ? combinedGiftAndEstateTax.toNumber() : 0,
      color: dataVisColors.NEGATIVE.backgroundColor,
      sectionTextColor: dataVisColors.NEGATIVE.contrastText,
      kind: GiftScenarioSectionKind.GiftAndEstateTax,
      tooltip: (
        <GiftAndEstateTaxSectionTooltip
          federalEstateTaxValue={federalEstateTax}
          stateEstateTaxValue={stateEstateTax}
          giftTaxValue={giftTax}
          actions={
            <LinkButton
              display="Show calculations"
              onClick={onShowCalculations}
            />
          }
        />
      ),
    },
    // Just gift tax (show when "Show after estate tax" is unchecked)
    {
      value: shouldShowAfterEstateTax ? 0 : giftTax.toNumber(),
      color: dataVisColors.NEGATIVE.backgroundColor,
      sectionTextColor: dataVisColors.NEGATIVE.contrastText,
      kind: GiftScenarioSectionKind.GiftTax,
      tooltip: (
        <GiftAndEstateTaxSectionTooltip
          federalEstateTaxValue={federalEstateTax}
          stateEstateTaxValue={stateEstateTax}
          giftTaxValue={giftTax}
          actions={
            <LinkButton
              display="Show calculations"
              onClick={onShowCalculations}
            />
          }
          isOnlyGiftTax
        />
      ),
    },
  ];
};

export function GiftScenarioBarChart({
  projection,
  idx,
  shouldShowAfterEstateTax,
  maxScenarioBarMagnitude,
}: GiftScenarioBarChartProps) {
  const dataVisColors = useChartColorDefinitions();

  const [{ isModalOpen }, { openModal, closeModal }] = useModalState();

  const handleShowCalculations = useCallback(() => {
    openModal();
  }, [openModal]);

  const sections = getSections({
    dataVisColors,
    projection,
    shouldShowAfterEstateTax,
    onShowCalculations: handleShowCalculations,
  });

  const validSections = useMemo(() => {
    const isDepleted = !!projection.yearInEstatePortfolioDepleted;
    const sectionsGtZero = sections.filter((s) => s.value > 0);

    if (!isDepleted) {
      return sectionsGtZero;
    }

    return sectionsGtZero.filter(
      (s) => s.kind !== GiftScenarioSectionKind.InEstateAfterTax
    );
  }, [projection.yearInEstatePortfolioDepleted, sections]);

  const hasGiftAndEstateTaxSection = useMemo(() => {
    return validSections.some(
      (s) => s.kind === GiftScenarioSectionKind.GiftAndEstateTax
    );
  }, [validSections]);

  return (
    <>
      <GiftScenarioTaxCalculationModal
        isOpen={isModalOpen}
        handleClose={closeModal}
        projection={projection}
        hasGiftAndEstateTaxSection={hasGiftAndEstateTaxSection}
      />
      <Stack
        direction="row"
        alignItems="center"
        spacing={3}
        data-testid={`GiftScenarioBarChart-${projection.scenario?.displayName}`}
      >
        <Box display="flex">
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: FONT_WEIGHTS.bold,
              width: 80,
            }}
          >
            Scenario {idx + 1}
            {projection.scenario?.isBaseline ? ' (Baseline)' : ''}
          </Typography>
        </Box>
        <Box width="100%">
          {validSections.length > 0 && (
            <StackedHorizontalBar
              sections={validSections}
              scaleDenominator={
                maxScenarioBarMagnitude?.toNumber() ?? undefined
              }
              endAdornment={
                projection.yearInEstatePortfolioDepleted ? (
                  <Stack height={48} justifyContent="center" px={1.5}>
                    <Typography
                      variant="h6"
                      component="p"
                      color={COLORS.GRAY[500]}
                    >
                      {`In-estate portfolio is fully depleted in ${projection.yearInEstatePortfolioDepleted}`}
                    </Typography>
                  </Stack>
                ) : null
              }
            />
          )}
          {validSections.length === 0 && (
            <Stack
              height={46}
              justifyContent="center"
              px={1.5}
              borderRadius="4px"
              border={`1px dashed ${COLORS.GRAY[300]}`}
            >
              <Typography variant="h6" component="p" color={COLORS.GRAY[500]}>
                {projection.yearInEstatePortfolioDepleted
                  ? `In-estate portfolio is fully depleted in ${projection.yearInEstatePortfolioDepleted}`
                  : 'Invalid wealth projection'}
              </Typography>
            </Stack>
          )}
        </Box>
      </Stack>
    </>
  );
}
