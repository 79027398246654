import {
  array,
  boolean as booleanType,
  date,
  Describe,
  nullable,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreateRevocableTrustInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateRevocableTrustInput,
} from '@/types/schema';

import {
  createInsurancePolicyInput,
  updateInsurancePolicyInput,
} from './insuranceTypes';
import {
  beneficiarySchema,
  commonUpdateEntityProperties,
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  grantorSchema,
  gstStatusSchema,
  inEstateStatusSchema,
  taxStatusSchema,
  trustAdvisorSchema,
  trusteeSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

const createRevocableTrustSchema: Describe<AugmentedCreateRevocableTrustInput> =
  object({
    create: object({
      trustJurisdictionStateCode: optional(nullable(string())),
      taxStatus: taxStatusSchema,
      legalName: optional(nullable(string())),
      inEstateStatus: inEstateStatusSchema,
      gstStatus: gstStatusSchema,
      effectiveDate: optional(nullable(date())),
      displayName: string(),
      description: optional(nullable(string())),
    }),
    withBeneficiaries: optional(nullable(array(beneficiarySchema))),
    withTrustees: optional(nullable(array(trusteeSchema))),
    withSuccessorTrustees: optional(nullable(array(trusteeSchema))),
    withTrustAdvisors: optional(nullable(array(trustAdvisorSchema))),
    withGrantors: optional(nullable(array(grantorSchema))),
    withDesignerAccount: optional(nullable(createAccountSchema)),
  });

const updateRevocableTrustSchema: Describe<AugmentedUpdateRevocableTrustInput> =
  object({
    id: string(),
    update: object({
      description: optional(nullable(string())),
      displayName: optional(nullable(string())),
      effectiveDate: optional(nullable(date())),
      clearEffectiveDate: optional(nullable(booleanType())),
      clearLegalName: optional(nullable(booleanType())),
      clearTrustJurisdictionStateCode: optional(nullable(booleanType())),
      gstStatus: gstStatusSchema,
      inEstateStatus: inEstateStatusSchema,
      legalName: optional(nullable(string())),
      taxStatus: taxStatusSchema,
      trustJurisdictionStateCode: optional(nullable(string())),
      clearBeneficiaries: optional(nullable(booleanType())),
      clearGrantors: optional(nullable(booleanType())),
      clearSuccessorTrustees: optional(nullable(booleanType())),
      clearTrustAdvisors: optional(nullable(booleanType())),
      clearDescription: optional(nullable(booleanType())),
      clearTrustees: optional(nullable(booleanType())),
      clearGstStatus: optional(nullable(booleanType())),
      clearTaxStatus: optional(nullable(booleanType())),
      removePolicyIDs: optional(nullable(array(string()))),
    }),
    withBeneficiaries: optional(nullable(array(beneficiarySchema))),
    withTrustees: optional(nullable(array(trusteeSchema))),
    withSuccessorTrustees: optional(nullable(array(trusteeSchema))),
    withTrustAdvisors: optional(nullable(array(trustAdvisorSchema))),
    withGrantors: optional(nullable(array(grantorSchema))),
    updateDesignerAccount: optional(nullable(updateAccountSchema)),
    withPolicies: optional(nullable(array(createInsurancePolicyInput))),
    updatePolicies: optional(nullable(array(updateInsurancePolicyInput))),
  });

export const createSchema: Describe<AugmentedCreateEntityInput> = object({
  create: createEntityInput,
  withRevocableTrust: optional(nullable(createRevocableTrustSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object(commonUpdateEntityProperties),
  updateRevocableTrust: optional(nullable(updateRevocableTrustSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export function validateRevocableTrustCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'revocable trust create');
}

export function validateRevocableTrustUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'revocable trust update');
}
