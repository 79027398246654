/* eslint-disable luminary-rules/no-page-imports */
import { useNavigate } from 'react-router-dom';

import { useNavigateToRoute } from '@/components/navigation/useNavigateToRoute';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  MenuItem,
  MenuItemProps,
} from '@/components/poppers/MenuPopper/MenuItem';
import { DEFAULT_FILTER_SEARCH_PARAM } from '@/components/tables/FilterableTable/FilterableTable';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { ListClientEntitiesDocument } from '@/pages/ClientDetailsPage/ClientDetailsEntitiesPage/ClientDetailsEntitiesListPage/graphql/ListClientEntities.generated';

import { FilterOptions } from '../entities.constants';
import { useDuplicateEntityMutation } from '../graphql/Entities.generated';
import { useDuplicateTestamentaryEntityMutation } from '../testamentaryEntities/TestamentaryEntityForm/graphql/CreateTestamentaryEntity.generated';

export interface DuplicateEntityMenuItemProps extends Partial<MenuItemProps> {
  label: string;
  householdId: string;
  entityId: string;
}

export interface DuplicateTestamentaryEntityMenuItemProps
  extends Partial<MenuItemProps> {
  label: string;
  householdId: string;
  testamentaryEntityId: string;
}

export function DuplicateEntityMenuItem({
  householdId,
  entityId,
  ...buttonProps
}: DuplicateEntityMenuItemProps) {
  const trackUserEvent = useTrackUserEvent();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { navigate } = useNavigateToRoute();
  const [duplicateEntity, { loading }] = useDuplicateEntityMutation({
    variables: { id: entityId },
    onError: (error) => {
      reportError(`failed to duplicate entity`, error);
      showFeedback(
        'Failed to duplicate this entity. Please refresh the page and try again.'
      );
    },
    onCompleted: (data) => {
      trackUserEvent('entity duplicated');
      navigate(ROUTE_KEYS.HOUSEHOLD_ENTITY_DETAILS, {
        householdId,
        entityId: data.duplicateEntity.id,
      });
    },
  });

  return (
    <MenuItem
      loading={loading}
      onClick={() => duplicateEntity()}
      {...buttonProps}
    />
  );
}

export function DuplicateTestamentaryEntityMenuItem({
  householdId,
  testamentaryEntityId,
  ...buttonProps
}: DuplicateTestamentaryEntityMenuItemProps) {
  const trackUserEvent = useTrackUserEvent();
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const navigate = useNavigate();
  const [duplicateEntity, { loading }] = useDuplicateTestamentaryEntityMutation(
    {
      variables: { id: testamentaryEntityId },
      onError: (error) => {
        reportError(`failed to duplicate testamentary entity`, error);
        showFeedback(
          'Failed to duplicate this entity. Please refresh the page and try again.'
        );
      },
      onCompleted: () => {
        trackUserEvent('testamentary_entity duplicated');
        const path = `${getCompletePathFromRouteKey(
          ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST,
          { householdId }
        )}?${DEFAULT_FILTER_SEARCH_PARAM}=${FilterOptions.TESTAMENTARY}`;
        navigate(path);
      },
      refetchQueries: [ListClientEntitiesDocument],
    }
  );

  return (
    <MenuItem
      loading={loading}
      onClick={() => duplicateEntity()}
      {...buttonProps}
    />
  );
}
