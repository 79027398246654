import { PropsWithChildren } from 'react';

import { RibbonCard, RibbonCardProps } from '@/components/layout/RibbonCard';

type EntitySubformOptionalRibbonCardProps = PropsWithChildren<
  {
    hideRibbon?: boolean;
  } & RibbonCardProps
>;

/**
 * This is a (temporary) wrapper to conditionally render a subform form within a
 * RibbonCard, or standalone.
 *
 * Currently, this is used when we render a subform form in the
 * EditEntitySplitScreenModal, where we don't need the ribbon/header around the
 * subform form, because we're only rendering one subform form at a time.
 */
export function EntitySubformOptionalRibbonCard({
  hideRibbon,
  children,
  ...ribbonProps
}: EntitySubformOptionalRibbonCardProps) {
  if (hideRibbon) {
    return <>{children}</>;
  }

  return <RibbonCard {...ribbonProps}>{children}</RibbonCard>;
}
