import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import Decimal from 'decimal.js';
import { first } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { SelectInput } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { FormAwareInputRepeater } from '@/components/form/formAwareInputs/FormAwareInputRepeater/FormAwareInputRepeater';
import {
  ValidatedInputRepeaterPercentSumRow,
  validateExactly100Percent,
} from '@/components/form/formAwareInputs/FormAwareInputRepeater/ValidatedInputRepeaterPercentSumRow';
import { FormAwarePercentInput } from '@/components/form/formAwareInputs/FormAwarePercentInput';
import { UserPlus01Icon } from '@/components/icons/UserPlus01Icon';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';

import { useIsCRT } from '../CharitableTrustDesignerContext';
import {
  CharitableTrustDesignerBasicInformationBeneficiaryOption,
  CharitableTrustDesignerBasicInformationForm,
  CharitableTrustDesignerBasicInformationFormPaths,
  NAMESPACE,
} from './CharitableTrustDesignerBasicInformation.types';

interface BeneficiaryCardProps {
  options: CharitableTrustDesignerBasicInformationBeneficiaryOption[];
  onClickAddNew?: () => void;
}

interface BeneficiaryRepeaterCardProps extends BeneficiaryCardProps {
  fieldName: 'charitableBeneficiaries' | 'nonCharitableBeneficiaries';
}

function BeneficiaryRepeaterCard({
  fieldName,
  options,
  onClickAddNew,
}: BeneficiaryRepeaterCardProps) {
  const { control, getValues, setValue } =
    useFormContext<CharitableTrustDesignerBasicInformationForm>();

  const owners = useWatch({
    name: `${NAMESPACE}.${fieldName}`,
    control,
  });

  const ownershipSum = owners.reduce<Decimal>(
    (acc, { percentageOwnership }) => {
      acc = acc.add(percentageOwnership || new Decimal(0));
      return acc;
    },
    new Decimal(0)
  );

  const validationField =
    `${NAMESPACE}._validationFields.${fieldName}` as const satisfies CharitableTrustDesignerBasicInformationFormPaths;

  useEffect(() => {
    setValue(validationField, ownershipSum);
  }, [setValue, ownershipSum, validationField]);

  const finalOptions = useMemo(
    () => [
      {
        type: 'component' as const,
        component: (
          <MenuItem onClick={onClickAddNew}>
            <ListItemIcon>
              <UserPlus01Icon size={16} />
            </ListItemIcon>
            <ListItemText>Add new</ListItemText>
          </MenuItem>
        ),
      },
      ...options,
    ],
    [onClickAddNew, options]
  );
  return (
    <Card variant="filled" sx={{ p: 3 }}>
      <FormAwareInputRepeater<CharitableTrustDesignerBasicInformationForm>
        control={control}
        name={
          `${NAMESPACE}.${fieldName}` as const satisfies CharitableTrustDesignerBasicInformationFormPaths
        }
        emptyValue={{
          id: '',
          percentageOwnership: null,
        }}
        addAnotherItemText="Add beneficiary"
        rightFooterContent={
          <ValidatedInputRepeaterPercentSumRow<CharitableTrustDesignerBasicInformationForm>
            decimalScale={0}
            validationField={validationField}
            validation={{
              totalValue: useCallback(
                (value) => {
                  // beneficiaries are optional, so only validate if there's an owner with an ID present
                  const hasValidOwner = owners.find((owner) => !!owner.id);
                  if (!hasValidOwner) {
                    return undefined;
                  }
                  return validateExactly100Percent(value as Decimal | null);
                },
                [owners]
              ),
            }}
          />
        }
        render={(index) => {
          const baseFieldName = `${NAMESPACE}.${fieldName}.${index}` as const;
          const value = getValues(`${baseFieldName}.id`);
          return (
            <FormLayoutRow>
              <FormLayoutItem width={8}>
                <SelectInput<string>
                  value={value}
                  onChange={(event) => {
                    const targetId = event.target.value;
                    if (!targetId) {
                      setValue(
                        `${baseFieldName}.id` as const satisfies CharitableTrustDesignerBasicInformationFormPaths,
                        ''
                      );
                      setValue(
                        `${baseFieldName}.kind` as const satisfies CharitableTrustDesignerBasicInformationFormPaths,
                        null
                      );
                      setValue(
                        `${baseFieldName}.beneficiaryNodeId` as const satisfies CharitableTrustDesignerBasicInformationFormPaths,
                        null
                      );
                      setValue(
                        `${baseFieldName}.percentageOwnership` as const satisfies CharitableTrustDesignerBasicInformationFormPaths,
                        null
                      );
                    } else {
                      const matchingOption = options.find(
                        (option) =>
                          option.type !== 'component' &&
                          option.value === targetId
                      );
                      if (
                        !matchingOption ||
                        matchingOption.type === 'component' // necessary to satisfy lookups below
                      ) {
                        return;
                      }

                      setValue(
                        `${baseFieldName}.id` as const satisfies CharitableTrustDesignerBasicInformationFormPaths,
                        matchingOption.value
                      );
                      setValue(
                        `${baseFieldName}.kind` as const satisfies CharitableTrustDesignerBasicInformationFormPaths,
                        matchingOption.kind
                      );
                      setValue(
                        `${baseFieldName}.beneficiaryNodeId` as const satisfies CharitableTrustDesignerBasicInformationFormPaths,
                        null
                      );

                      // when setting the first owner, default the percentage ownership to 100%
                      if (
                        owners.length === 1 &&
                        first(owners)?.percentageOwnership === null
                      ) {
                        setValue(
                          `${baseFieldName}.percentageOwnership` as const satisfies CharitableTrustDesignerBasicInformationFormPaths,
                          new Decimal(100)
                        );
                      }
                    }
                  }}
                  label="Beneficiary"
                  options={finalOptions}
                  hideLabel={index >= 1}
                />
              </FormLayoutItem>
              <FormLayoutItem width={4}>
                <FormAwarePercentInput
                  control={control}
                  fieldName={
                    `${baseFieldName}.percentageOwnership` as const satisfies CharitableTrustDesignerBasicInformationFormPaths
                  }
                  label="Percentage"
                  hideLabel={index >= 1}
                  isDecimalJSInput
                  fixedDecimalScale
                  decimalScale={0}
                />
              </FormLayoutItem>
            </FormLayoutRow>
          );
        }}
      />
    </Card>
  );
}

export function CharitableBeneficiariesCard({
  options,
  onClickAddNew,
}: BeneficiaryCardProps) {
  const isCRT = useIsCRT();

  return (
    <Card variant="outlined" sx={{ p: 3, pb: 4 }}>
      <Stack direction="column">
        <Typography variant="h3" sx={{ pb: 0.5 }}>
          Charitable {isCRT ? 'remainder' : 'income'} beneficiaries
        </Typography>
        <Typography variant="body1" sx={{ pb: 2 }}>
          {isCRT ? (
            <>
              Select the charitable beneficiaries that will receive the
              trust&apos;s remainder at the end of the trust&apos;s term.
            </>
          ) : (
            <>
              Select the charitable beneficiaries that will receive the income
              payments from the trust.
            </>
          )}
        </Typography>
        <BeneficiaryRepeaterCard
          onClickAddNew={onClickAddNew}
          options={options}
          fieldName={'charitableBeneficiaries'}
        />
      </Stack>
    </Card>
  );
}

export function NonCharitableBeneficiariesCard({
  options,
  onClickAddNew,
}: BeneficiaryCardProps) {
  const isCRT = useIsCRT();
  return (
    <Card variant="outlined" sx={{ p: 3, pb: 4 }}>
      <Stack direction="column">
        <Typography variant="h3" sx={{ pb: 0.5 }}>
          {isCRT ? 'Income' : 'Remainder'} beneficiaries
        </Typography>
        <Typography variant="body1" sx={{ pb: 2 }}>
          {isCRT ? (
            <>
              Select the beneficiaries that will receive the income payments
              from the trust.
            </>
          ) : (
            <>
              Add remainder beneficiaries that will receive the trust&apos;s
              remainder at the end of the trust&apos;s term. Remainder
              beneficiaries are often trusts for the benefit of family members.
            </>
          )}
        </Typography>
        <BeneficiaryRepeaterCard
          onClickAddNew={onClickAddNew}
          options={options}
          fieldName={'nonCharitableBeneficiaries'}
        />
      </Stack>
    </Card>
  );
}
