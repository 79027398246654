import { Control, Controller, FieldValues } from 'react-hook-form';

import { FieldNameFromFormShape } from '@/types/react-hook-form';

export interface FormAwareTextInputProps<FormShape extends FieldValues> {
  fieldName: FieldNameFromFormShape<FormShape>;
  control: Control<FormShape>;
  testId?: string;
}

export function FormAwareHiddenInput<FormShape extends FieldValues>({
  fieldName,
  control,
  testId,
}: FormAwareTextInputProps<FormShape>) {
  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field }) => {
        return <input type="hidden" data-testid={testId} {...field} />;
      }}
    />
  );
}
