import {
  IconButton,
  IconButtonProps,
} from '@/components/form/baseInputs/Button/IconButton';
import { RefreshCw05Icon } from '@/components/icons/RefreshCw05Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { getUserFacingErrorMessages } from '@/graphql/errors';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';

import { useResyncClientEntitiesMutation } from './graphql/ResyncClientsEntitiesButton.generated';

interface ResyncClientsEntitiesButtonProps extends Partial<IconButtonProps> {
  disabled?: boolean;
}

export function ResyncClientsEntitiesButton(
  buttonProps: ResyncClientsEntitiesButtonProps
) {
  const { showFeedback } = useFeedback();
  const trackUserEvent = useTrackUserEvent();
  const [resyncClientEntities, { loading }] = useResyncClientEntitiesMutation({
    onCompleted: () => {
      showFeedback(
        'Initiated resync of clients and entities. This may take a few minutes depending on the number of clients and entities.',
        {
          variant: 'success',
        }
      );
    },
    onError: (err) => {
      const errorMessages = getUserFacingErrorMessages(err);
      showFeedback(
        `Error syncing clients and entities: ${errorMessages.join(' ')}`
      );
    },
  });

  const handleClick = () => {
    trackUserEvent('user resynced clients and entities');
    void resyncClientEntities();
  };

  return (
    <Tooltip title="Resync client and entity data from the integration to capture recent updates">
      <IconButton
        icon={RefreshCw05Icon}
        ariaLabel="Resync clients and entities"
        size="xs"
        onClick={handleClick}
        loading={loading}
        {...buttonProps}
      />
    </Tooltip>
  );
}
