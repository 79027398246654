import { Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { useMemo, useState } from 'react';

import { HeaderList } from '@/components/lists/HeaderList/HeaderList';
import { ButtonTab, Tabs } from '@/components/navigation/NavigationTabs';
import { useEntityDetailsContext } from '@/modules/entities/contexts/entityDetails/entityDetails.context';
import { useTestamentaryEntityDetailsContext } from '@/modules/entities/contexts/testamentaryEntityDetails/testamentaryEntityDetails.context';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { useDispositiveProvisionsContext } from '../contexts/dispositiveProvisions.context';
import { GetDispositiveProvisions_EstateWaterfallFragment } from '../DispositiveProvisionsForm/graphql/GetDispositiveProvisions.generated';
import { SpecifyDispositiveProvisionsButton } from '../SpecifyDispositiveProvisionsButton';
import {
  DispositiveProvisionDirection,
  DispositiveProvisionWithEdges,
  UponDeath,
} from './DispositiveProvisionsByDeath.types';
import {
  getCaptionText,
  getDispositiveProvisionsWithEdgesByDeath,
  getProvisionSourceFromAssociatedVizNode,
} from './DispositiveProvisionsByDeath.utils';
import { DispositiveProvisionRow } from './DispositiveProvisionsRow';
import { ActiveDispositionTab } from './hooks/useDispositiveProvisionsListViewTab';

export interface DispositiveProvisionsByDeathProps
  extends ActiveDispositionTab {
  currentNodeValue?: Decimal;
  waterfall: GetDispositiveProvisions_EstateWaterfallFragment;
}

export function DispositiveProvisionsByDeath({
  waterfall,
  ...props
}: DispositiveProvisionsByDeathProps) {
  const { id, isTwoClientHousehold, allClientIds, dispositionScenarios } =
    useDispositiveProvisionsContext();
  const { entityKind } = useEntityDetailsContext();
  const { primaryClients } = useHouseholdDetailsContext();
  const { entityId: testamentaryEntityId } =
    useTestamentaryEntityDetailsContext();

  const dispositionScenarioForDeathOrder = dispositionScenarios?.find(
    (scenario) =>
      scenario.firstGrantorDeath?.id === waterfall.firstGrantorDeath?.id
  );

  const canEditProvisions = useMemo(() => {
    if (testamentaryEntityId && isTwoClientHousehold) return true;
    if (allClientIds.includes(id)) return true;
    if (entityKind) return true;

    return false;
  }, [
    allClientIds,
    entityKind,
    id,
    isTwoClientHousehold,
    testamentaryEntityId,
  ]);

  const [direction, setDirection] = useState<DispositiveProvisionDirection>(
    canEditProvisions
      ? DispositiveProvisionDirection.Distributing
      : DispositiveProvisionDirection.Receiving
  );

  const waterfallEdges = useMemo(
    () => waterfall.visualizationWithProjections.edges,
    [waterfall]
  );
  const dispositiveProvisionsByDeath = useMemo<
    Record<UponDeath, DispositiveProvisionWithEdges[]>
  >(() => {
    return getDispositiveProvisionsWithEdgesByDeath(
      waterfallEdges,
      id,
      direction,
      dispositionScenarioForDeathOrder
    );
  }, [waterfallEdges, id, direction, dispositionScenarioForDeathOrder]);

  const hasAnyProvisionsInView =
    dispositiveProvisionsByDeath.uponFirstDeath.length > 0 ||
    dispositiveProvisionsByDeath.uponSecondDeath.length > 0;
  const caption = getCaptionText(direction, hasAnyProvisionsInView);

  return (
    <Stack spacing={3}>
      {canEditProvisions && (
        <Tabs fullWidth>
          <ButtonTab
            display="Distributing"
            isActive={direction === DispositiveProvisionDirection.Distributing}
            onClick={() =>
              setDirection(DispositiveProvisionDirection.Distributing)
            }
          />
          <ButtonTab
            display="Receiving"
            isActive={direction === DispositiveProvisionDirection.Receiving}
            onClick={() =>
              setDirection(DispositiveProvisionDirection.Receiving)
            }
          />
        </Tabs>
      )}
      <Typography variant="subtitle2">{caption}</Typography>
      <HeaderList
        heading={
          isTwoClientHousehold
            ? `Upon first death (${waterfall?.firstGrantorDeathYear})`
            : `Upon death (${waterfall.firstGrantorDeathYear})`
        }
        noItemsText="No dispositions specified"
      >
        {dispositiveProvisionsByDeath.uponFirstDeath.map(
          ({ dispositiveProvision, value, from }) => (
            <DispositiveProvisionRow
              key={`upon-first-death-${dispositiveProvision.id}`}
              direction={direction}
              provision={
                direction === DispositiveProvisionDirection.Distributing
                  ? dispositiveProvision
                  : getProvisionSourceFromAssociatedVizNode(
                      dispositiveProvision,
                      from
                    )
              }
              dyingPrimaryClientId={waterfall.firstGrantorDeath.id}
              primaryClients={primaryClients}
              calculatedProvisionAmount={value}
            />
          )
        )}
      </HeaderList>
      {isTwoClientHousehold && (
        <HeaderList
          heading={`Upon second death (${waterfall?.secondGrantorDeathYear})`}
          noItemsText="No dispositions specified"
        >
          {dispositiveProvisionsByDeath.uponSecondDeath.map(
            ({ dispositiveProvision, value, from }) => (
              <DispositiveProvisionRow
                key={`upon-second-death-${dispositiveProvision.id}`}
                direction={direction}
                provision={
                  direction === DispositiveProvisionDirection.Distributing
                    ? dispositiveProvision
                    : getProvisionSourceFromAssociatedVizNode(
                        dispositiveProvision,
                        from
                      )
                }
                dyingPrimaryClientId={
                  primaryClients?.find(
                    (c) => c.id !== waterfall.firstGrantorDeath.id
                  )?.id ?? null
                }
                primaryClients={primaryClients}
                calculatedProvisionAmount={value}
              />
            )
          )}
        </HeaderList>
      )}
      {canEditProvisions && (
        <SpecifyDispositiveProvisionsButton
          buttonProps={{
            fullWidth: true,
            sx: {
              px: 3,
            },
          }}
          behavior="edit"
          {...props}
        />
      )}
    </Stack>
  );
}
