import { Stack } from '@mui/material';

import { Avatar } from '@/components/Avatar';
import { Button } from '@/components/form/baseInputs/Button';
import { LogOut01Icon } from '@/components/icons/LogOut01Icon';
import { Settings01Icon } from '@/components/icons/Settings01Icon';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useLogoutUser } from '@/modules/authentication/authenticationUtils';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import {
  BehaviorAuthorizationType,
  useHasBehaviorAuthorization,
} from '@/modules/authentication/hooks/useHasBehaviorAuthorization';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

function UserSettingsButtonLink() {
  return (
    <Button
      startIcon={Settings01Icon}
      size="sm"
      variant="secondary"
      href={getCompletePathFromRouteKey(ROUTE_KEYS.USER_SETTINGS, {})}
    >
      Manage profile
    </Button>
  );
}

function LogOutButtonLink() {
  const logoutUser = useLogoutUser();
  return (
    <Button
      endIcon={LogOut01Icon}
      onClick={logoutUser}
      size="sm"
      variant="destructive-prominent"
      fullWidth
    >
      Log out
    </Button>
  );
}

export function UserSettingsTooltip() {
  const currentUser = useCurrentUser();

  const canAccessSettingsPage = useHasBehaviorAuthorization(
    BehaviorAuthorizationType.CAN_ACCESS_USER_SETTINGS_PAGE
  );
  if (!currentUser) return null;
  return (
    <Stack spacing={2} p={2}>
      <Avatar
        initials={currentUser.initials}
        fullName={currentUser.displayName}
        email={currentUser.email}
        size="md"
      />
      {currentUser.integrationLoginExpired && (
        <Callout severity="warning">
          Your integration access has expired. Please unlink and relink your
          integration account below via manage profile.
        </Callout>
      )}
      {canAccessSettingsPage && <UserSettingsButtonLink />}
      <LogOutButtonLink />
    </Stack>
  );
}
