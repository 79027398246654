import styled from '@emotion/styled';
import {
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

const AppNotificationPopupInner = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: COLORS.PRIMITIVES.WHITE,
    border: `1px solid ${COLORS.FUNCTIONAL.WARNING[400]}`,
    width: 240,
    padding: 8,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: COLORS.FUNCTIONAL.WARNING[400],
  },
}));

interface AppNotificationPopupProps extends Omit<TooltipProps, 'title'> {
  /** Emphasized title */
  title?: string;
  /** Body text */
  message: string;
  /** Component to be wrapped with coachmark */
  children: JSX.Element;
}

export function AppNotificationPopup({
  title = 'You have a new notification',
  message,
  children,
  placement = 'bottom',
  open,
  ...tooltipProps
}: AppNotificationPopupProps) {
  return (
    <AppNotificationPopupInner
      open={open}
      placement={placement}
      arrow
      disableHoverListener
      TransitionProps={{ timeout: 400 }}
      title={
        <Stack>
          <Typography
            variant="h6"
            sx={{ color: COLORS.FUNCTIONAL.WARNING[600] }}
          >
            {title}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: COLORS.NAVY[900] }}
            noWrap
          >
            {message}
          </Typography>
        </Stack>
      }
      {...tooltipProps}
    >
      {children}
    </AppNotificationPopupInner>
  );
}
