import {
  array,
  boolean as booleanType,
  date,
  Describe,
  nullable,
  number,
  object,
  optional,
  string,
} from 'superstruct';

import {
  AugmentedCreateEntityInput,
  AugmentedCreatePrincipalInput,
  AugmentedCreateQprtTrustInput,
  AugmentedUpdateEntityInput,
  AugmentedUpdateQprtTrustInput,
  CreateQprtTrustInput,
  UpdateQprtTrustInput,
} from '@/types/schema';

import {
  beneficiarySchema,
  commonUpdateEntityProperties,
  createAccountSchema,
  createEntityInput,
  createEntityStateTaxSchema,
  decimal,
  gstStatusSchema,
  inEstateStatusSchema,
  stageSchema,
  taxStatusSchema,
  trustAdvisorSchema,
  trusteeSchema,
  updateAccountSchema,
} from './types';
import { $getValidatedInput } from './utils';

export const qprtGrantorSchema: Describe<AugmentedCreatePrincipalInput> =
  object({
    create: object({
      individualID: optional(nullable(string())),
      ownershipPercentage: optional(nullable(decimal)),
    }),
  });

const qprtCreateUpdateProperties = {
  // share trust properties
  legalName: optional(nullable(string())),
  trustJurisdictionStateCode: optional(nullable(string())),
  effectiveDate: optional(nullable(date())),
  description: optional(nullable(string())),
  taxStatus: taxStatusSchema,
  inEstateStatus: inEstateStatusSchema,
  gstStatus: gstStatusSchema,

  // QPRT-specific properties
  termDurationYears: optional(nullable(number())),
  officialInterestRatePercent: optional(nullable(decimal)),
  taxableGift: optional(nullable(decimal)),
};

const createQprtTrustInput: Describe<CreateQprtTrustInput> = object({
  displayName: string(),
  ...qprtCreateUpdateProperties,
});

const createQprtTrustSchema: Describe<AugmentedCreateQprtTrustInput> = object({
  create: createQprtTrustInput,
  withBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withTrustees: optional(nullable(array(trusteeSchema))),
  withSuccessorTrustees: optional(nullable(array(trusteeSchema))),
  withTrustAdvisors: optional(nullable(array(trustAdvisorSchema))),
  withGrantors: optional(nullable(array(qprtGrantorSchema))),
  withDesignerAccount: optional(nullable(createAccountSchema)),
});

const updateQprtTrustInput: Describe<UpdateQprtTrustInput> = object({
  displayName: optional(nullable(string())),
  ...qprtCreateUpdateProperties,
  clearEffectiveDate: optional(nullable(booleanType())),
  clearLegalName: optional(nullable(booleanType())),
  clearTrustJurisdictionStateCode: optional(nullable(booleanType())),
  clearBeneficiaries: optional(nullable(booleanType())),
  clearGrantors: optional(nullable(booleanType())),
  clearSuccessorTrustees: optional(nullable(booleanType())),
  clearTrustAdvisors: optional(nullable(booleanType())),
  clearDescription: optional(nullable(booleanType())),
  clearTrustees: optional(nullable(booleanType())),
  clearGstStatus: optional(nullable(booleanType())),
  clearTaxStatus: optional(nullable(booleanType())),
});

const updateQprtTrustSchema: Describe<AugmentedUpdateQprtTrustInput> = object({
  id: string(),
  update: updateQprtTrustInput,
  withBeneficiaries: optional(nullable(array(beneficiarySchema))),
  withTrustees: optional(nullable(array(trusteeSchema))),
  withSuccessorTrustees: optional(nullable(array(trusteeSchema))),
  withTrustAdvisors: optional(nullable(array(trustAdvisorSchema))),
  withGrantors: optional(nullable(array(qprtGrantorSchema))),
  updateDesignerAccount: optional(nullable(updateAccountSchema)),
});

export const createSchema: Describe<AugmentedCreateEntityInput> = object({
  create: createEntityInput,
  withQprtTrust: optional(nullable(createQprtTrustSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export const updateSchema: Describe<AugmentedUpdateEntityInput> = object({
  id: string(),
  update: object({
    ...commonUpdateEntityProperties,
    stage: optional(nullable(stageSchema)),
  }),
  updateQprtTrust: optional(nullable(updateQprtTrustSchema)),
  withStateTaxes: optional(nullable(array(createEntityStateTaxSchema))),
});

export function validateQprtTrustCreateInput(
  input: AugmentedCreateEntityInput
): AugmentedCreateEntityInput {
  return $getValidatedInput(input, createSchema, 'QPRT create');
}

export function validateQprtTrustUpdateInput(
  input: AugmentedUpdateEntityInput
): AugmentedUpdateEntityInput {
  return $getValidatedInput(input, updateSchema, 'QPRT update');
}
