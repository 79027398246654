import { Typography } from '@mui/material';
import Decimal from 'decimal.js';
import { merge } from 'lodash';
import { PartialDeep } from 'type-fest';

import { TileVariant } from '@/components/diagrams/components/Tile/types';
import { TileNode } from '@/components/diagrams/FlowChart';
import { getGrantorRelationshipsFriendlyString } from '@/modules/clientProfiles/clientProfileUtils';
import { entityKindToDisplayName } from '@/modules/entities/EntityForm/utils';
import {
  ClientOrganizationKind,
  EntityInEstateStatus,
  Maybe,
} from '@/types/schema';
import { sumDecimalJS } from '@/utils/decimalJSUtils';
import { formatCurrency } from '@/utils/formatting/currency';
import { formatEnumCase } from '@/utils/formatting/strings';

import {
  EntityMap_BusinessEntityFragment,
  EntityMap_ClientProfileFragment,
  EntityMap_CltTrustFragment,
  EntityMap_CrtTrustFragment,
  EntityMap_CustodialPersonalAccountFragment,
  EntityMap_DonorAdvisedFundFragment,
  EntityMap_GrantorClientProfileFragment,
  EntityMap_GratTrustFragment,
  EntityMap_IlitTrustFragment,
  EntityMap_IndividualPersonalAccountFragment,
  EntityMap_InsurancePersonalAccountFragment,
  EntityMap_IrrevocableTrustFragment,
  EntityMap_JointPersonalAccountFragment,
  EntityMap_NodeFragment,
  EntityMap_OrganizationFragment,
  EntityMap_PrivateFoundationFragment,
  EntityMap_QprtTrustFragment,
  EntityMap_QualifiedTuitionPersonalAccountFragment,
  EntityMap_RetirementPersonalAccountFragment,
  EntityMap_RevocableTrustFragment,
  EntityMap_SlatTrustFragment,
} from '../graphql/EntityMap.generated';
import { SupportedSubtypeTypename } from '../types';
import { getGrantorBusinessEntitiesNodeId } from '../utils/normalize';

type TileFromEntity<T> = Omit<EntityMap_NodeFragment, '__typename'> & {
  subtype: Omit<EntityMap_NodeFragment['subtype'], '__typename'> &
    Omit<T, '__typename'>;
};

type EntityTileFn<T> = (entity: TileFromEntity<T>) => TileNode;

const getTileVariant = (
  inEstateStatus: Maybe<EntityInEstateStatus> | undefined | null,
  defaultVariant?: TileVariant
): TileVariant | undefined =>
  inEstateStatus === EntityInEstateStatus.InEstate
    ? TileVariant.Primary
    : defaultVariant;

const defaultTileNodeProps: Pick<TileNode, 'position' | 'type'> = {
  position: { x: 0, y: 0 },
  type: 'tile',
};

const defaultFormatCurrencyOptions: Intl.NumberFormatOptions = {
  notation: 'compact',
  currencySign: 'accounting',
};

export function individualBeneficiaryTile(
  { id, displayName, relationships }: EntityMap_ClientProfileFragment,
  opts: PartialDeep<TileNode> = {}
): TileNode {
  const lineTwo = getGrantorRelationshipsFriendlyString(relationships);

  return merge<TileNode, Partial<TileNode>>(
    {
      ...defaultTileNodeProps,
      id,
      data: {
        lineOne: displayName,
        lineTwo,
      },
    },
    opts as Partial<TileNode>
  );
}

export function individualGrantorTile({
  id,
  displayName,
  isPrimary,
}: EntityMap_ClientProfileFragment): TileNode {
  return {
    ...defaultTileNodeProps,
    id,
    data: {
      lineOne: displayName,
      lineTwo: isPrimary ? 'Client' : 'Non-client grantor',
    },
  };
}

export const businessEntityTile: EntityTileFn<
  EntityMap_BusinessEntityFragment
> = ({ id, subtype, kind }) => {
  return {
    ...defaultTileNodeProps,
    id: id,
    data: {
      lineOne: subtype.displayName,
      lineTwo: entityKindToDisplayName(kind),
      lineThree: formatCurrency(
        subtype.currentValue,
        defaultFormatCurrencyOptions
      ),
    },
  };
};

export const grantorBusinessEntitiesSummaryTile = ({
  id,
  displayName,
  ownedOwnershipStakes,
}: EntityMap_GrantorClientProfileFragment): TileNode => {
  const ownershipStakesValueSum =
    ownedOwnershipStakes?.reduce(
      (sum, { ownedValue }) => sum.plus(ownedValue),
      new Decimal(0)
    ) ?? new Decimal(0);

  return {
    ...defaultTileNodeProps,
    id: getGrantorBusinessEntitiesNodeId(id),
    data: {
      lineOne: 'Business ownership',
      lineTwo: `Directly held by ${displayName}`,
      lineThree: formatCurrency(
        ownershipStakesValueSum,
        defaultFormatCurrencyOptions
      ),
      variant: TileVariant.Primary,
    },
  };
};

export function organizationBeneficiaryTile({
  id,
  kind,
  name,
}: EntityMap_OrganizationFragment): TileNode {
  return {
    ...defaultTileNodeProps,
    id,
    data: {
      lineOne: name,
      lineTwo: formatEnumCase(kind),
      variant:
        kind === ClientOrganizationKind.CharitableOrganization
          ? TileVariant.Tertiary
          : undefined,
    },
  };
}

const TrustTile: EntityTileFn<
  Pick<EntityMap_GratTrustFragment, 'inEstateStatus' | 'currentValue'>
> = ({ id, subtype: { displayName, inEstateStatus, currentValue } }) => {
  return {
    ...defaultTileNodeProps,
    id,
    data: {
      lineOne: displayName,
      lineTwo: '',
      lineThree: formatCurrency(currentValue, defaultFormatCurrencyOptions),
      variant: getTileVariant(inEstateStatus, TileVariant.Secondary),
    },
  };
};

const CharitableTrustTile: typeof TrustTile = (...args) => {
  const tile = TrustTile(...args);
  tile.data.variant = TileVariant.Tertiary;
  return tile;
};

const PersonalAccountEntityTile: EntityTileFn<
  Pick<
    EntityMap_JointPersonalAccountFragment,
    'inEstateStatus' | 'currentValue'
  >
> = ({ id, subtype: { displayName, inEstateStatus, currentValue } }) => {
  return {
    ...defaultTileNodeProps,
    id,
    data: {
      lineOne: displayName,
      lineTwo: '',
      lineThree: formatCurrency(currentValue, defaultFormatCurrencyOptions),
      variant: getTileVariant(inEstateStatus, TileVariant.Light),
    },
  };
};

const InsurancePersonalAccountEntityTile: EntityTileFn<
  Pick<EntityMap_InsurancePersonalAccountFragment, 'currentValue' | 'policies'>
> = ({ id, subtype: { displayName, currentValue, policies } }) => {
  const totalDeathBenefit = sumDecimalJS(
    (policies || [])?.map(({ deathBenefitAmount }) => deathBenefitAmount)
  );
  return {
    ...defaultTileNodeProps,
    id,
    data: {
      lineOne: displayName,
      lineTwo: '',
      lineThree: formatCurrency(currentValue, defaultFormatCurrencyOptions),
      variant: TileVariant.Primary,
      children: (
        <Typography variant="subtitle2">
          Death benefit{' '}
          {formatCurrency(totalDeathBenefit, { notation: 'compact' })}
        </Typography>
      ),
    },
  };
};

const NonTrustCharitableEntityTile: EntityTileFn<
  Pick<EntityMap_PrivateFoundationFragment, 'inEstateStatus' | 'currentValue'>
> = ({ id, subtype: { displayName, inEstateStatus, currentValue } }) => {
  return {
    ...defaultTileNodeProps,
    id,
    data: {
      lineOne: displayName,
      lineTwo: '',
      lineThree: formatCurrency(currentValue, defaultFormatCurrencyOptions),
      variant: getTileVariant(inEstateStatus, TileVariant.Tertiary),
    },
  };
};

const GRATTrust: EntityTileFn<
  Pick<EntityMap_GratTrustFragment, 'inEstateStatus'>
> = (entity) => {
  const { data, ...rest } = TrustTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'GRAT' },
  };
};

const ILITTrust: EntityTileFn<EntityMap_IlitTrustFragment> = (entity) => {
  const { data, ...rest } = TrustTile(entity);
  const totalDeathBenefit = sumDecimalJS(
    entity.subtype.policies?.map(
      ({ deathBenefitAmount }) => deathBenefitAmount
    ) || []
  );
  return {
    ...rest,
    data: {
      ...data,
      lineTwo: 'ILIT',
      children: (
        <Typography variant="subtitle2">
          Death benefit{' '}
          {formatCurrency(totalDeathBenefit, { notation: 'compact' })}
        </Typography>
      ),
    },
  };
};

const SLATTrust: EntityTileFn<EntityMap_SlatTrustFragment> = (entity) => {
  const { data, ...rest } = TrustTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'SLAT' },
  };
};

const RevocableTrust: EntityTileFn<EntityMap_RevocableTrustFragment> = (
  entity
) => {
  const { data, ...rest } = TrustTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'Revocable Trust' },
  };
};

const IrrevocableTrust: EntityTileFn<EntityMap_IrrevocableTrustFragment> = (
  entity
) => {
  const { data, ...rest } = TrustTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'Irrevocable Trust' },
  };
};

const QPRTTrust: EntityTileFn<EntityMap_QprtTrustFragment> = (entity) => {
  const { data, ...rest } = TrustTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'QPRT' },
  };
};

const IndividualPersonalAccount: EntityTileFn<
  EntityMap_IndividualPersonalAccountFragment
> = (entity) => {
  const { data, ...rest } = PersonalAccountEntityTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'Personal account' },
  };
};

const InsurancePersonalAccount: EntityTileFn<
  EntityMap_InsurancePersonalAccountFragment
> = (entity) => {
  const { data, ...rest } = InsurancePersonalAccountEntityTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'Directly held life insurance' },
  };
};

const JointPersonalAccount: EntityTileFn<
  EntityMap_JointPersonalAccountFragment
> = (entity) => {
  const { data, ...rest } = PersonalAccountEntityTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'Joint account' },
  };
};

const RetirementPersonalAccount: EntityTileFn<
  EntityMap_RetirementPersonalAccountFragment
> = (entity) => {
  const { data, ...rest } = PersonalAccountEntityTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'Retirement account' },
  };
};

const CustodialPersonalAccount: EntityTileFn<
  EntityMap_CustodialPersonalAccountFragment
> = (entity) => {
  const { data, ...rest } = PersonalAccountEntityTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'Custodial account' },
  };
};

const QualifiedTuitionPersonalAccount: EntityTileFn<
  EntityMap_QualifiedTuitionPersonalAccountFragment
> = (entity) => {
  const { data, ...rest } = PersonalAccountEntityTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: '529 account' },
  };
};

const CLTTrust: EntityTileFn<EntityMap_CltTrustFragment> = (entity) => {
  const { data, ...rest } = CharitableTrustTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'CLT' },
  };
};

const CRTTrust: EntityTileFn<EntityMap_CrtTrustFragment> = (entity) => {
  const { data, ...rest } = CharitableTrustTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'CRT' },
  };
};

const DonorAdvisedFund: EntityTileFn<EntityMap_DonorAdvisedFundFragment> = (
  entity
) => {
  const { data, ...rest } = NonTrustCharitableEntityTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'DAF' },
  };
};

const PrivateFoundation: EntityTileFn<EntityMap_PrivateFoundationFragment> = (
  entity
) => {
  const { data, ...rest } = NonTrustCharitableEntityTile(entity);
  return {
    ...rest,
    data: { ...data, lineTwo: 'Private foundation' },
  };
};

export const entitySubtypeToTile: Record<
  SupportedSubtypeTypename,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we want to match against any EntityFileFn type
  EntityTileFn<any>
> = {
  GRATTrust,
  ILITTrust,
  SLATTrust,
  RevocableTrust,
  IrrevocableTrust,
  QPRTTrust,
  IndividualPersonalAccount,
  InsurancePersonalAccount, // TODO: Implement tile
  JointPersonalAccount,
  RetirementPersonalAccount,
  CustodialPersonalAccount,
  QualifiedTuitionPersonalAccount,
  CLTTrust,
  CRTTrust,
  DonorAdvisedFund,
  PrivateFoundation,
  CCorpBusinessEntity: businessEntityTile,
  SoleProprietorshipBusinessEntity: businessEntityTile,
  SCorpBusinessEntity: businessEntityTile,
  LPBusinessEntity: businessEntityTile,
  LLCBusinessEntity: businessEntityTile,
  GPBusinessEntity: businessEntityTile,
};
