import { Box } from '@mui/material';
import { FC } from 'react';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { SubtotalCard } from '@/components/display/SubtotalCard/SubtotalCard';
import { LineChartUp02Icon } from '@/components/icons/LineChartUp02Icon';
import {
  MiniTable,
  MiniTableRow,
} from '@/components/tables/MiniTable/MiniTable';
import { COLORS } from '@/styles/tokens/colors';
import {
  formatCurrency,
  formatCurrencyNoDecimals,
} from '@/utils/formatting/currency';

import { entityHasBusinessOwnershipStakes } from './EntityValuationHistoryPage.utils';
import { EntityValuationHistoryPage_EntityFragment } from './graphql/GetEntityValuationHistory.generated';

export interface GrowthOutOfEstateProps {
  entity: EntityValuationHistoryPage_EntityFragment | undefined | null;
}

export const GrowthOutOfEstate: FC<GrowthOutOfEstateProps> = ({ entity }) => {
  if (
    entity?.subtype?.__typename !== 'IrrevocableTrust' &&
    entity?.subtype?.__typename !== 'SLATTrust'
  ) {
    return null;
  }
  const hasBusinessOwnershipStakes = entityHasBusinessOwnershipStakes(entity);
  const { performanceReport, currentValue } = entity?.subtype || {};
  const { outOfEstateGrowth, distributions, taxableGifts, transfers } =
    performanceReport;

  const rows: MiniTableRow[] = [
    {
      label: 'Current entity value',
      labelTooltip: hasBusinessOwnershipStakes
        ? 'This value represents the sum of ownership in any business entities plus the value of other assets held by this entity'
        : undefined,
      value: formatCurrencyNoDecimals(currentValue),
    },
    {
      label: 'Taxable gifts',
      labelTooltip:
        'Consists of non-charitable gifts that have been contributed to the trust upon initial funding or over time',
      value: formatCurrency(taxableGifts.mul(-1), {
        currencySign: 'accounting',
        maximumFractionDigits: 0,
      }),
    },
    {
      label: 'Transfers',
      labelTooltip:
        'Consists of transfers from entities such as GRATs or other trust remainders that are considered non-gift contributions',
      value: formatCurrency(transfers.mul(-1), {
        currencySign: 'accounting',
        maximumFractionDigits: 0,
      }),
    },
    {
      label: 'Distributions',
      value: formatCurrencyNoDecimals(distributions),
    },
    {
      label: 'Growth out of estate',
      value: formatCurrencyNoDecimals(outOfEstateGrowth),
    },
  ];

  return (
    <SubtotalCard
      wrapperSx={{ flex: '1 1 50%' }}
      caption={
        <OversizedMetricItem
          title="Growth out of estate"
          value={formatCurrencyNoDecimals(outOfEstateGrowth)}
        />
      }
      icon={<LineChartUp02Icon />}
      iconColor={COLORS.CATEGORIES.FAMILY_GIVING[500]}
    >
      <Box sx={{ flex: '1 1 50%' }}>
        <MiniTable rows={rows} variant="tally" />
      </Box>
    </SubtotalCard>
  );
};
