import { Stack } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { countBy, every, first, isEmpty } from 'lodash';
import { useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Checkbox } from '@/components/form/baseInputs/Checkbox';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { TERMINAL_ASYNC_JOB_STATUSES } from '@/modules/asyncJobs/graphql/asyncJobs.constants';
import { DocumentAsyncJobStatus } from '@/pages/internal/DocumentInferenceSandbox/DocumentAsyncJobStatus';
import { POLLING_INTERVAL } from '@/pages/internal/DocumentInferenceSandbox/DocumentInferenceSandbox.constants';
import {
  BackfillOcrAsyncJobFragment,
  BackfillOcrUserFragment,
  useBackfillOcrFetchMostRecentJobsQuery,
  useBackfillOcrMutation,
} from '@/pages/internal/DocumentInferenceSandbox/toolsTab/backfillOCR/graphql/BackfillOCR.generated';

export const BackfillOCRCard = () => {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const [isJobInProgress, setIsJobInProgress] = useState(false);
  const [asyncJobs, setAsyncJobs] = useState<BackfillOcrAsyncJobFragment[]>([]);
  const [skipExistingOCR, setSkipExistingOCR] = useState(false);

  const { startPolling, stopPolling } = useBackfillOcrFetchMostRecentJobsQuery({
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true, // notifyOnNetworkStatusChange is required to rerun onComplete for each poll
    onCompleted: (data) => {
      const newAsyncJobs = data.backfillOCRAsyncJobs;

      if (isEmpty(newAsyncJobs)) {
        setIsJobInProgress(false);
        setAsyncJobs([]);
        stopPolling();
        return;
      }

      setAsyncJobs(newAsyncJobs);

      const allDone = every(newAsyncJobs, (job) =>
        TERMINAL_ASYNC_JOB_STATUSES.includes(job.status)
      );

      if (allDone) {
        setIsJobInProgress(false);
        stopPolling();
      } else {
        setIsJobInProgress(true);
        startPolling(POLLING_INTERVAL);
      }
    },
    onError: (error) => {
      setIsJobInProgress(false);
      stopPolling();
      showFeedback('Failed to fetch most recent backfill OCR async job');
      reportError('Failed to fetch most recent backfill OCR async job', error);
    },
  });

  const [backfillMutation, { loading: loadingMutation }] =
    useBackfillOcrMutation({
      onCompleted: (data) => {
        setIsJobInProgress(true);
        setAsyncJobs(data.asyncDocumentOCRBackfill);
        startPolling(POLLING_INTERVAL);
      },
      onError: (error) => {
        setIsJobInProgress(false);
        showFeedback('Failed to start backfill OCR');
        reportError('Failed to start backfill OCR', error);
      },
    });

  const [status, userAndTime] = aggregatedAsyncJobDetails(asyncJobs);

  return (
    <HeaderCard
      heading="Backfill OCR"
      subheading="Run OCR on documents in this tenant that have not been OCR'd yet."
    >
      <Stack sx={{ gap: 3 }}>
        <Button
          size="md"
          variant="primary"
          loading={isJobInProgress || loadingMutation}
          onClick={() =>
            backfillMutation({
              variables: {
                skipExistingOCR,
              },
            })
          }
        >
          Backfill OCR
        </Button>
        <Checkbox
          label="Force OCR on docs that have already been OCRd (can be expensive, use with caution)"
          value={skipExistingOCR}
          onChange={(e) => setSkipExistingOCR(e.target.checked)}
        />
        <DocumentAsyncJobStatus
          inProgress={isJobInProgress}
          status={status}
          userAndTime={userAndTime}
        />
      </Stack>
    </HeaderCard>
  );
};

const aggregatedAsyncJobDetails = (
  asyncJobs: BackfillOcrAsyncJobFragment[]
): [string, string] => {
  if (isEmpty(asyncJobs)) return ['None', ''];

  const agg = countBy(asyncJobs, 'status');

  const statuses = Object.entries(agg)
    .map(([status, count]) => `${status}: ${count}`)
    .join(', ');

  const mostRecent = first(asyncJobs);

  return [statuses, formatUserAndTime(mostRecent!.user, mostRecent!.updatedAt)];
};

const formatUserAndTime = (user: BackfillOcrUserFragment, date: Date) =>
  `${user?.displayName || 'Unknown'}, ${formatDistanceToNow(date, {
    addSuffix: true,
  })}`;
