import { Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { DragAndDropList } from '@/components/lists/DragAndDropList/DragAndDropList';
import { Modal } from '@/components/modals/Modal/Modal';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { FetchEntityAiSuggestionsDocument } from '@/modules/aiSuggestions/graphql/aiSuggestions.generated';
import { useHouseholdDocumentsViewerContext } from '@/modules/documents/components/HouseholdDocumentsViewer/context/HouseholdDocumentsViewer.context';
import {
  EntityDocumentViewer_DocumentFragment,
  EntityDocumentViewerDocument,
} from '@/modules/documents/components/HouseholdDocumentsViewer/graphql/EntityDocumentViewer.generated';
import { useBulkUpdateGenericDocumentMutation } from '@/modules/files/hooks/graphql/UseUpdateDocument.generated';
import { AugmentedUpdateDocumentInput } from '@/types/schema';

interface DocumentPrecedenceModalProps {
  isOpen: boolean;
  onClose: () => void;
  entityId: string | undefined | null;
}

export function DocumentPrecedenceModal({
  isOpen,
  onClose,
  entityId,
}: DocumentPrecedenceModalProps) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();

  const { documents } = useHouseholdDocumentsViewerContext();

  const [updateDocumentsMutation, { loading }] =
    useBulkUpdateGenericDocumentMutation({
      refetchQueries: () =>
        entityId
          ? [
              EntityDocumentViewerDocument,
              {
                query: FetchEntityAiSuggestionsDocument,
                variables: { entityID: entityId },
              },
            ]
          : [],
      onCompleted: () => {
        onClose();
        showFeedback(FeedbackMessages.formSaveSuccess, {
          variant: 'success',
        });
      },
      onError: (error) => {
        reportError(`failed to update document precedence order`, error);
        showFeedback(FeedbackMessages.formSaveError);
      },
    });

  const draggableDocs = useMemo(
    () => (documents || []).filter((d) => d.enableAiSuggestions),
    [documents]
  );

  const [reorderedDocs, setReorderedDocs] = useState<
    EntityDocumentViewer_DocumentFragment[]
  >([]);

  const handleSaveChanges = useCallback(async () => {
    if (isEmpty(reorderedDocs)) {
      onClose();
      return;
    }

    const updatedDocs: AugmentedUpdateDocumentInput[] = reorderedDocs.map(
      (doc, index) => ({
        id: doc.id,
        update: {
          aiSuggestionOrder: index,
        },
      })
    );

    await updateDocumentsMutation({
      variables: {
        input: updatedDocs,
      },
    });
  }, [onClose, reorderedDocs, updateDocumentsMutation]);

  const footerActions = useMemo(
    () => (
      <Stack direction="row" gap={2}>
        <Button
          size="sm"
          variant="secondary"
          onClick={onClose}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          size="sm"
          variant="primary"
          onClick={handleSaveChanges}
          loading={loading}
        >
          Save changes
        </Button>
      </Stack>
    ),
    [handleSaveChanges, loading, onClose]
  );

  return (
    <Modal
      heading="Specify document precedence"
      isOpen={isOpen}
      onClose={onClose}
      actions={footerActions}
    >
      <Stack direction="column" gap={3}>
        <Stack direction="column" gap={1}>
          <Typography>
            Luminary AI has been enabled for suggesting entity details out of
            the following documents.{' '}
            <Typography fontWeight="bold" display="inline">
              Re-order to specify which document should take precedence when
              suggesting details for the forms.
            </Typography>
          </Typography>
          <Typography>
            If details are not found in the primary document, they will be
            searched for in the second document and so on.
          </Typography>
        </Stack>
        <DragAndDropList<EntityDocumentViewer_DocumentFragment>
          items={draggableDocs}
          respondToLengthChanges={false}
          droppableId={`document-precedence`}
          ItemInnerComponent={({ item, Draghandle }) => (
            <Stack direction="row">
              {Draghandle}
              <Stack direction="column" p={2}>
                <Typography variant="h5">{item.name}</Typography>
                <Typography variant="subtitle2">{item.displayType}</Typography>
              </Stack>
            </Stack>
          )}
          onDropped={(sortedList) => setReorderedDocs(sortedList)}
        />
      </Stack>
    </Modal>
  );
}
