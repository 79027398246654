import {
  AIOnboardingModalStep,
  AIOnboardingModalStepConfig,
  AIOnboardingModalSuggestionKinds,
} from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.types';
import { AIOnboardingModalFormShape } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { AiOnboardingModal_HouseholdFragmentDoc } from '@/modules/aiOnboarding/AIOnboardingModal/graphql/aiOnboardingModal.generated';
import { ROUTE_KEYS } from '@/navigation/constants';
// eslint-disable-next-line luminary-rules/no-page-imports
import { EntityListFragmentDoc } from '@/pages/ClientDetailsPage/ClientDetailsEntitiesPage/ClientDetailsEntitiesListPage/graphql/ListClientEntities.generated';
import { AiSuggestionKind } from '@/types/schema';

export const AI_ONBOARDING_MODAL_STEP_CONFIGS: Record<
  AIOnboardingModalStep,
  AIOnboardingModalStepConfig
> = {
  [AIOnboardingModalStep.NO_SUGGESTIONS]: {
    step: AIOnboardingModalStep.NO_SUGGESTIONS,
    index: 0,
    stepTrackerText: '', // No text for this step, since we hide the step tracker when there are no suggestions
    previouslyCreatedText: '', // No text for this step, since we can't create anything when on this step
    primaryButtonText: 'Process additional documents',
    formPaths: [],
    nextStep: undefined,
    refetchQueries: undefined,
    navigateIfLast: undefined,
  },
  [AIOnboardingModalStep.INDIVIDUALS_AND_ORGANIZATIONS]: {
    step: AIOnboardingModalStep.INDIVIDUALS_AND_ORGANIZATIONS,
    index: 1,
    stepTrackerText: 'Individuals and Organizations',
    previouslyCreatedText: 'individuals & organizations',
    primaryButtonText: 'Create individuals & organizations',
    formPaths: ['individuals', 'organizations'],
    nextStep: AIOnboardingModalStep.ENTITIES,
    refetchQueries: [AiOnboardingModal_HouseholdFragmentDoc],
    navigateIfLast: ROUTE_KEYS.HOUSEHOLD_DETAILS_PEOPLE_ORGANIZATIONS,
  },
  [AIOnboardingModalStep.ENTITIES]: {
    step: AIOnboardingModalStep.ENTITIES,
    index: 2,
    stepTrackerText: 'Entities',
    previouslyCreatedText: 'entities',
    primaryButtonText: 'Create entities',
    formPaths: ['entities', 'testamentaryEntities'],
    nextStep: undefined,
    refetchQueries: [EntityListFragmentDoc],
    navigateIfLast: ROUTE_KEYS.HOUSEHOLD_DETAILS_ENTITIES_LIST,
  },
};

export const AI_ONBOARDING_MODAL_SUGGESTION_FORM_KEY_MAP: Record<
  AIOnboardingModalSuggestionKinds,
  keyof AIOnboardingModalFormShape
> = {
  [AiSuggestionKind.OnboardingIndividual]: 'individuals',
  [AiSuggestionKind.OnboardingOrganization]: 'organizations',
  [AiSuggestionKind.OnboardingEntity]: 'entities',
  [AiSuggestionKind.OnboardingTestamentaryEntity]: 'testamentaryEntities',
};
