import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { Card } from '@/components/layout/Card/Card';
import { Grid } from '@/components/layout/Grid';
import { Callout } from '@/components/notifications/Callout/Callout';
import { Loader } from '@/components/progress/Loader/Loader';
import { useReportError } from '@/hooks/useReportError';
import { useAddeparIntegrationErrorSearchParam } from '@/modules/assetProviderIntegrations/addepar/hooks/useAddeparIntegrationErrorSearchParam';
import { useCurrentUser } from '@/modules/authentication/hooks/useCurrentUser';
import { useFeatureFlag } from '@/modules/featureFlags/useFeatureFlag';
import { OpenAIIntegrationCard } from '@/pages/admin/AdminIntegrationsPage/OpenAIIntegrationCard';

import { AddeparIntegrationCard } from './AddeparIntegrationCard';
import { BlackDiamondIntegrationCard } from './BlackDiamondIntegrationCard';
import { useGetIntegrationConfigurationQuery } from './graphql/AdminIntegrationsPage.generated';
import { OrionIntegrationCard } from './OrionIntegrationCard';

export function AdminIntegrationsPage() {
  const isOrionFFEnabled = useFeatureFlag('orion_integration');
  const currentUser = useCurrentUser();
  const { reportError } = useReportError();
  const {
    data: integrationData,
    loading: configLoading,
    refetch,
  } = useGetIntegrationConfigurationQuery({
    onError: (err) => {
      reportError('failed to get integrations data', err);
    },
  });

  useAddeparIntegrationErrorSearchParam();

  const isAddeparConnected =
    integrationData?.isIntegrationUserLoggedInToAddepar?.isLoggedIn ?? false;

  const isBlackDiamondConnected =
    integrationData?.isIntegrationUserLoggedInToBlackDiamond?.isLoggedIn ??
    false;

  const isOrionConnected =
    integrationData?.isIntegrationUserLoggedInToOrion?.isLoggedIn ?? false;

  return (
    <SubpageLayout heading="Data integrations">
      {configLoading && <Loader />}
      {!configLoading && (
        <Grid container spacing={2}>
          {currentUser?.apiUserIntegrationLoginExpired && (
            <Grid item sm={12}>
              <Callout severity="warning">
                Your login to your integration provider is expired. Disconnect
                and relink your integration account to enable valuation updates.
              </Callout>
            </Grid>
          )}
          <Grid item sm={6} lg={4}>
            <Card variant="outlined" sx={{ p: 3 }}>
              <AddeparIntegrationCard
                refetch={refetch}
                configuration={
                  integrationData?.integrationConfiguration ?? null
                }
                isLoggedIn={isAddeparConnected}
              />
            </Card>
          </Grid>
          <Grid item sm={6} lg={4}>
            <Card variant="outlined" sx={{ p: 3 }}>
              <BlackDiamondIntegrationCard
                configuration={
                  integrationData?.integrationConfiguration ?? null
                }
                isLoggedIn={isBlackDiamondConnected}
              />
            </Card>
          </Grid>
          {isOrionFFEnabled && (
            <Grid item sm={6} lg={4}>
              <Card variant="outlined" sx={{ p: 3 }}>
                <OrionIntegrationCard
                  configuration={
                    integrationData?.integrationConfiguration ?? null
                  }
                  isLoggedIn={isOrionConnected}
                />
              </Card>
            </Grid>
          )}
          {integrationData?.integrationConfiguration && (
            <Grid item sm={6} lg={4}>
              <Card variant="outlined" sx={{ p: 3 }}>
                <OpenAIIntegrationCard
                  configuration={integrationData.integrationConfiguration}
                />
              </Card>
            </Grid>
          )}
        </Grid>
      )}
    </SubpageLayout>
  );
}
