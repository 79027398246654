import Decimal from 'decimal.js';
import { compact, first, values } from 'lodash';

import {
  EntityKind,
  EstateWaterfallHypotheticalTransferDestinationKind,
  EstateWaterfallHypotheticalTransferSourceKind,
  EstateWaterfallHypotheticalTransferTransferTaxKind,
} from '@/types/schema';
import { getNodes } from '@/utils/graphqlUtils';

import { EXTERNAL_ENTITY_SENTINEL } from '../HypotheticalTransfersCard/hypotheticalTransfers/hypotheticalTransfers.utils';
import {
  HypotheticalTransferModal_EstateWaterfallHypotheticalTransferFragment,
  HypotheticalTransferModalQuery,
} from './graphql/HypotheticalTransferModal.generated';
import { DEFAULT_VALUES } from './HypotheticalTransferModal.constants';
import {
  AnnuallyRecurringValue,
  HypotheticalTransferFormModalShape,
} from './HypotheticalTransferModal.types';

function getSourceId({
  sourceEntity,
  sourceIndividual,
  sourceKind,
}: HypotheticalTransferModal_EstateWaterfallHypotheticalTransferFragment): string {
  if (
    sourceKind === EstateWaterfallHypotheticalTransferSourceKind.Entity &&
    sourceEntity?.id
  ) {
    return sourceEntity.id;
  } else if (
    sourceKind === EstateWaterfallHypotheticalTransferSourceKind.Individual &&
    sourceIndividual?.id
  ) {
    return sourceIndividual.id;
  } else if (
    sourceKind === EstateWaterfallHypotheticalTransferSourceKind.External
  ) {
    return EXTERNAL_ENTITY_SENTINEL;
  }
  return '';
}

function getTargetId({
  destinationKind,
  destinationEntity,
  destinationIndividual,
  destinationOrganization,
}: HypotheticalTransferModal_EstateWaterfallHypotheticalTransferFragment): string {
  if (
    destinationKind ===
      EstateWaterfallHypotheticalTransferDestinationKind.Entity &&
    destinationEntity?.id
  ) {
    return destinationEntity.id;
  } else if (
    destinationKind ===
      EstateWaterfallHypotheticalTransferDestinationKind.Individual &&
    destinationIndividual?.id
  ) {
    return destinationIndividual.id;
  } else if (
    destinationKind ===
      EstateWaterfallHypotheticalTransferDestinationKind.Organization &&
    destinationOrganization?.id
  ) {
    return destinationOrganization.id;
  } else if (
    destinationKind ===
    EstateWaterfallHypotheticalTransferDestinationKind.External
  ) {
    return EXTERNAL_ENTITY_SENTINEL;
  }

  return '';
}

export function getDraftEntityGiftingGrantorIds({
  destinationEntity,
}: HypotheticalTransferModal_EstateWaterfallHypotheticalTransferFragment):
  | string
  | undefined {
  if (!destinationEntity?.subtype) {
    return;
  }
  const { subtype } = destinationEntity;

  switch (subtype.__typename) {
    case 'IrrevocableTrust':
    case 'RevocableTrust':
    case 'ILITTrust':
    case 'QPRTTrust':
    case 'CRTTrust':
    case 'CLTTrust':
      return compact(
        subtype.grantors?.sort().map(({ individual }) => individual?.id)
      ).join(',');

    case 'SLATTrust':
    case 'GRATTrust':
      return subtype.grantor?.individual?.id;

    case 'IndividualPersonalAccount':
    case 'RetirementPersonalAccount':
    case 'QualifiedTuitionPersonalAccount':
      return subtype.owner?.individual?.id;

    case 'JointPersonalAccount':
      return compact(
        subtype.owners?.sort().map(({ individual }) => individual?.id)
      ).join(',');

    case 'DonorAdvisedFund':
    case 'PrivateFoundation':
      return compact(
        subtype.donors?.sort().map(({ individual }) => individual?.id)
      ).join('');

    default:
      return;
  }
}

const TRANSFER_TAX_KIND_SEPARATOR = '__transfer_tax_kind__';
const TRANSFER_GIFTING_GRANTOR_SEPARATOR = '__gifting_grantor__';

export function deserializeTransferType(
  transferType: HypotheticalTransferFormModalShape['transferType']
): {
  transferTaxKind: EstateWaterfallHypotheticalTransferTransferTaxKind;
  giftingGrantorIDs: string[];
} {
  const [transferTaxKind = '', giftingGrantorIDsString = ''] =
    transferType.split(TRANSFER_TAX_KIND_SEPARATOR);
  const giftingGrantorIDs =
    giftingGrantorIDsString?.split(TRANSFER_GIFTING_GRANTOR_SEPARATOR) || [];

  return {
    transferTaxKind:
      transferTaxKind as EstateWaterfallHypotheticalTransferTransferTaxKind,
    giftingGrantorIDs,
  };
}

export function serializeTransferType(
  transferTaxKind: EstateWaterfallHypotheticalTransferTransferTaxKind,
  giftingGrantorIDs: string[]
): string {
  if (
    transferTaxKind ===
    EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift
  ) {
    return `${transferTaxKind}${TRANSFER_TAX_KIND_SEPARATOR}${giftingGrantorIDs
      .sort()
      .join(TRANSFER_GIFTING_GRANTOR_SEPARATOR)}`;
  }
  return transferTaxKind;
}

export function mapDataToHypotheticalTransferModalForm(
  data: HypotheticalTransferModalQuery,
  initiatingId: string | undefined
): HypotheticalTransferFormModalShape {
  const formData: HypotheticalTransferFormModalShape = { ...DEFAULT_VALUES };

  if (initiatingId) {
    formData.sourceId = initiatingId;
  }

  const waterfall = first(getNodes(data.estateWaterfalls));

  if (!waterfall) {
    return formData;
  }
  const activeTransfer = first(getNodes(waterfall.hypotheticalTransfers));

  if (!activeTransfer) {
    return formData;
  }

  formData.sourceId = getSourceId(activeTransfer);
  formData.sourceOwnedEntityId = activeTransfer.sourceOwnedEntity?.id || '';
  formData.sourceInsurancePolicyId =
    activeTransfer.sourceInsurancePolicy?.id || '';
  formData.targetId = getTargetId(activeTransfer);
  formData.taxDiscountRate = activeTransfer.taxDiscount ?? null;
  formData._discountRateToggle = !!activeTransfer.taxDiscount;
  formData._policyOrAsset = null;
  // ILIT rules for the policy or asset toggle
  if (activeTransfer.sourceEntity?.kind === EntityKind.IlitTrust) {
    // For ILITs, we default the toggle to asset
    formData._policyOrAsset = 'asset';

    if (activeTransfer?.sourceInsurancePolicy) {
      formData._policyOrAsset = 'policy';
    }
  }

  formData._activeTransfer = activeTransfer;

  formData.transferName = activeTransfer.name || '';
  formData.transferAmount = activeTransfer.transferValue || new Decimal(0);
  formData.taxableValue = activeTransfer.taxableValue || new Decimal(0);
  if (activeTransfer.startYear) {
    formData.transferStartYear = activeTransfer.startYear;
  }

  if (
    activeTransfer.endYear &&
    activeTransfer.endYear !== activeTransfer.startYear
  ) {
    formData.transferEndYear = activeTransfer.endYear;
    formData.transferFrequency = AnnuallyRecurringValue.true;
  }

  if (activeTransfer.assetGrowthReturn) {
    formData.transferGrowthRate = activeTransfer.assetGrowthReturn;
  }

  if (activeTransfer.transferTaxKind) {
    formData.transferType = serializeTransferType(
      activeTransfer.transferTaxKind,
      activeTransfer.giftingGrantors?.map(({ id }) => id) || []
    );
  }

  if (activeTransfer.isGstGift) {
    formData.transferGstGift = true;
  }

  return formData;
}

interface HypotheticalTransferDestination {
  destinationKind: EstateWaterfallHypotheticalTransferDestinationKind;
  destinationEntityID?: string;
  destinationIndividualID?: string;
  destinationOrganizationID?: string;
}

export function getHypotheticalTransferDestination({
  targetId,
  transferToNewDraft,
}: HypotheticalTransferFormModalShape): HypotheticalTransferDestination | null {
  if (transferToNewDraft) {
    return {
      destinationKind:
        EstateWaterfallHypotheticalTransferDestinationKind.Entity,
    };
  }
  if (targetId === EXTERNAL_ENTITY_SENTINEL) {
    return {
      destinationKind:
        EstateWaterfallHypotheticalTransferDestinationKind.External,
    };
  } else if (targetId.startsWith('entity_')) {
    return {
      destinationKind:
        EstateWaterfallHypotheticalTransferDestinationKind.Entity,
      destinationEntityID: targetId,
    };
  } else if (targetId.startsWith('CP_')) {
    return {
      destinationKind:
        EstateWaterfallHypotheticalTransferDestinationKind.Individual,
      destinationIndividualID: targetId,
    };
  } else if (targetId.startsWith('org_')) {
    return {
      destinationKind:
        EstateWaterfallHypotheticalTransferDestinationKind.Organization,
      destinationOrganizationID: targetId,
    };
  }
  return null;
}

interface HypotheticalTransferSource {
  sourceKind: EstateWaterfallHypotheticalTransferSourceKind;
  sourceEntityID?: string;
  sourceIndividualID?: string;
  sourceOwnedEntityID?: string;
  sourceInsurancePolicyID?: string;
}

export function getHypotheticalTransferSource({
  sourceId,
  sourceOwnedEntityId,
  sourceInsurancePolicyId,
}: HypotheticalTransferFormModalShape): HypotheticalTransferSource | null {
  if (sourceId === EXTERNAL_ENTITY_SENTINEL) {
    return {
      sourceKind: EstateWaterfallHypotheticalTransferSourceKind.External,
    };
  } else if (sourceId.startsWith('entity_')) {
    return {
      sourceKind: EstateWaterfallHypotheticalTransferSourceKind.Entity,
      sourceEntityID: sourceId,
      sourceOwnedEntityID: sourceOwnedEntityId || undefined,
      sourceInsurancePolicyID: sourceInsurancePolicyId || undefined,
    };
  } else if (sourceId.startsWith('CP_')) {
    return {
      sourceKind: EstateWaterfallHypotheticalTransferSourceKind.Individual,
      sourceIndividualID: sourceId,
      sourceOwnedEntityID: sourceOwnedEntityId || undefined,
    };
  }

  return null;
}

export function getHypotheticalTransferTaxDetails(
  formData: HypotheticalTransferFormModalShape
): {
  transferTaxKind?: EstateWaterfallHypotheticalTransferTransferTaxKind;
  giftingGrantorIDs?: string[];
} {
  if (!formData.transferType) {
    return {};
  }
  const { transferTaxKind, giftingGrantorIDs } = deserializeTransferType(
    formData.transferType
  );

  if (
    transferTaxKind ===
    EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift
  ) {
    return {
      transferTaxKind:
        EstateWaterfallHypotheticalTransferTransferTaxKind.GrantorTaxableGift,
      giftingGrantorIDs: giftingGrantorIDs.length
        ? giftingGrantorIDs
        : undefined,
    };
  }

  if (
    values(EstateWaterfallHypotheticalTransferTransferTaxKind).includes(
      transferTaxKind
    )
  ) {
    return {
      transferTaxKind,
    };
  }

  return {};
}
