import Decimal from 'decimal.js';

import { AugmentedUpdateGratTrustInput } from '@/types/schema';

import { Fields, NAMESPACE } from '../types';

export function generateThresholdDetailsUpdates(
  formValues: Fields | null, // formValues will only be null when opts.clearThresholds is true
  opts = { clearThresholds: false }
): AugmentedUpdateGratTrustInput['update'] {
  if (opts.clearThresholds) {
    return {
      clearDownsideThresholdPercent: true,
      clearUpsideThresholdValue: true,
      clearUpsideThresholdPercent: true,
    };
  }

  if (!formValues) {
    throw new Error(
      'formValues must be provided when clearThresholds is false.'
    );
  }

  const subformValues = formValues[NAMESPACE];

  const downsideThresholdProps = (() => {
    if (subformValues.downsidePercent?.equals(new Decimal(0)) ?? true) {
      return {
        clearDownsideThresholdPercent: true,
      };
    }

    return {
      downsideThresholdPercent: subformValues.downsidePercent,
    };
  })();

  const upsideThresholdProps = (() => {
    if (subformValues.upsidePercent?.equals(new Decimal(0)) ?? true) {
      return {
        clearUpsideThresholdPercent: true,
      };
    }

    return {
      upsideThresholdPercent: subformValues.upsidePercent,
      clearUpsideThresholdValue: true,
    };
  })();

  const upsideValueProps = (() => {
    if (subformValues.upsideValue?.equals(new Decimal(0)) ?? true) {
      return {
        clearUpsideThresholdValue: true,
      };
    }

    return {
      upsideThresholdValue: subformValues.upsideValue,
      clearUpsideThresholdPercent: true,
    };
  })();

  const relevantUpsideProps = subformValues.upsideAsTarget
    ? upsideValueProps
    : upsideThresholdProps;

  return {
    ...downsideThresholdProps,
    ...relevantUpsideProps,
    upsideAsTargetSurplus: subformValues.upsideAsTarget,
  };
}
