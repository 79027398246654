import { ApolloClient } from '@apollo/client';
import Decimal from 'decimal.js';
import { Maybe } from 'graphql/jsutils/Maybe';
import _ from 'lodash';

import { getIntegrationEntitiesFromEntity } from '@/modules/assetProviderIntegrations/shared/utils';
import { CreateAssetValuationV2Input } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';
import { UnreachableError } from '@/utils/errors';

import { EntityType } from '../types/EntityType';
import {
  BasicAssetsSubformProperties,
  BasicAssetsSubformType,
  NamespaceType,
  VariantType,
} from './BasicAssetsSubform.types';
import {
  BasicAssets_EntityFragment,
  BasicAssetsSubform_ValuationFragment,
  GetBasicAssetsDocument,
  GetBasicAssetsQuery,
} from './graphql/BasicAssets.generated';

function getFormValuesFromValuation(
  accountId: string,
  valuation: Maybe<BasicAssetsSubform_ValuationFragment>,
  defaultAssetClassId: string
): BasicAssetsSubformProperties {
  let dateOfValuation = valuation?.effectiveDate ?? null;
  let currentMarketValue: Decimal | null =
    valuation?.fixedValuationAmount ?? null;
  // If a valuation is present, but the date is the epoch, then the valuation
  // is a placeholder and should be treated as if it doesn't exist in the form.
  if (
    dateOfValuation?.getTime() === new Date(new Date(0).toISOString()).getTime()
  ) {
    // TODO LUM-1283
    dateOfValuation = null;
    currentMarketValue = null;
  }

  // TODO (T2-3278) - remove this loading logic
  return {
    accountId,
    currentMarketValue,
    dateOfValuation,
    assetCategoryId: defaultAssetClassId,
    description: valuation?.description ?? '',
    valuationId: valuation?.id,
    dirtyState: 'clean',
    integrationEntityIds: [],
    _previousIntegrationEntityIds: [],
    linkToAllAddeparEntities: false,
    documentIds: [],
  };
}

function getBasicAssetsForEntity(
  namespace: NamespaceType,
  entity: BasicAssets_EntityFragment,
  defaultAssetClassId: string
): BasicAssetsSubformType {
  const entitySubtype = entity.subtype;
  if (
    _.includes(
      [
        'IrrevocableTrust',
        'RevocableTrust',
        'SLATTrust',
        'GRATTrust',
        'QPRTTrust',
        'ILITTrust',
        'IndividualPersonalAccount',
        'JointPersonalAccount',
        'QualifiedTuitionPersonalAccount',
        'RetirementPersonalAccount',
        'CustodialPersonalAccount',
        'CLTTrust',
        'CRTTrust',
        'DonorAdvisedFund',
        'PrivateFoundation',
        'LLCBusinessEntity',
        'GPBusinessEntity',
        'LPBusinessEntity',
        'SoleProprietorshipBusinessEntity',
        'SCorpBusinessEntity',
        'CCorpBusinessEntity',
        'InsurancePersonalAccount',
      ],
      entity.subtype.__typename
    )
  ) {
    const emptyFormData = {
      accountId: undefined,
      valuationId: undefined,
      assetCategoryId: '',
      currentMarketValue: null,
      dateOfValuation: null,
      description: '',
      dirtyState: 'clean' as const,
      integrationEntityIds: [],
      _previousIntegrationEntityIds: [],
      linkToAllAddeparEntities: false,
      documentIds: [],
    };

    const designerAccount = entitySubtype.designerAccount;

    if (namespace === 'basicAssetsSubform') {
      if (!designerAccount) {
        return {
          basicAssets: emptyFormData,
        };
      }

      const mostRecentValuation = designerAccount.valuations.edges?.[0]?.node;
      const basicAssetsFormValues = getFormValuesFromValuation(
        designerAccount.id,
        mostRecentValuation,
        defaultAssetClassId
      );
      const currentIntegrationEntityIds = getIntegrationEntitiesFromEntity(
        entity
      ).map((entity) => entity.id);
      return {
        basicAssets: {
          ...basicAssetsFormValues,
          // persist this to both properties so we can remove the linkage to the old one
          integrationEntityIds: currentIntegrationEntityIds,
          _previousIntegrationEntityIds: currentIntegrationEntityIds,
          linkToAllAddeparEntities:
            entity.addeparLinkedToNongrantorEntity ?? false,
        },
      };
    } else if (namespace === 'basicAssetsInitialFundingSubform') {
      if (!designerAccount) {
        return {
          basicAssetsInitialFunding: emptyFormData,
        };
      }
      const initialValuation = designerAccount.initialValuation;
      const basicAssetsInitialFundingFormValues = getFormValuesFromValuation(
        designerAccount.id,
        initialValuation,
        defaultAssetClassId
      );
      return {
        // we don't need to worry about the integrationEntity here because it's
        // not exposed in the initial funding subform
        basicAssetsInitialFunding: basicAssetsInitialFundingFormValues,
      };
    }

    throw new UnreachableError({
      case: namespace,
      message: `Unexpected namespace ${namespace}`,
    });
  }

  throw new UnreachableError({
    case: entitySubtype.__typename as never,
    message: `Unexpected entity subtype ${entitySubtype.__typename}`,
  });
}

function dataToForm(
  namespace: NamespaceType,
  data: GetBasicAssetsQuery,
  defaultAssetClassId: string
): BasicAssetsSubformType {
  if (data.node?.__typename !== 'Entity') {
    throw new Error('Unexpected node type');
  }

  return getBasicAssetsForEntity(namespace, data.node, defaultAssetClassId);
}

export async function basicAssetsDataFetcher(
  namespace: NamespaceType,
  apolloClient: ApolloClient<object>,
  nodeId: string,
  defaultAssetClassId: string
): Promise<BasicAssetsSubformType> {
  const { data, error } = await apolloClient.query<GetBasicAssetsQuery>({
    query: GetBasicAssetsDocument,
    variables: {
      nodeId: nodeId,
    },
  });

  if (error) {
    diagnostics.error('Could not fetch data for basic assets subform', error);

    throw error;
  }

  return dataToForm(namespace, data, defaultAssetClassId);
}

export function getVariantFromEntityType(entityType: EntityType): VariantType {
  switch (entityType) {
    case 'grat':
      return 'assetIntegrationOnly';
    case 'insurance-account':
      return 'insuranceAccountPolicies';
    case 'ilit':
      return 'ilitPolicySummary';
    default:
      return 'default';
  }
}

export function makeCreateValuationInput(
  values: BasicAssetsSubformProperties
): CreateAssetValuationV2Input {
  return {
    description: values.description,
    effectiveDate: values.dateOfValuation ?? new Date(),
    fixedValuationAmount: values.currentMarketValue,
    documentIDs: values.documentIds,
  };
}
