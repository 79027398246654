import styled from '@emotion/styled';
import { Radio, RadioProps } from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

const StyledIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 20,
  height: 20,
  boxShadow: `inset 0 0 0 1px ${COLORS.GRAY[300]}`,
  backgroundColor: COLORS.PRIMITIVES.WHITE,

  'input:hover ~ &': {
    backgroundColor: COLORS.PRIMITIVES.WHITE,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: COLORS.GRAY[300],
  },
}));

const StyledCheckedIcon = styled(StyledIcon)({
  backgroundColor: COLORS.NAVY[600],
  boxShadow: 'none',
  '&:before': {
    display: 'block',
    width: 20,
    height: 20,
    backgroundImage: `radial-gradient(${COLORS.PRIMITIVES.WHITE},${COLORS.PRIMITIVES.WHITE} 24%,transparent 32%)`,
    content: '""',
  },
  '.Mui-disabled &:before': {
    backgroundImage: `radial-gradient(${COLORS.GRAY[400]},${COLORS.GRAY[400]} 24%,transparent 32%)`,
  },
  'input:hover ~ &': {
    backgroundColor: COLORS.NAVY['600'],
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: COLORS.GRAY[300],
  },
});

export function StyledRadioButton(props: RadioProps) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
        py: 0.5,
      }}
      disableRipple
      color="default"
      checkedIcon={<StyledCheckedIcon />}
      icon={<StyledIcon />}
      inputProps={
        {
          'data-testid': props.id,
        } as React.InputHTMLAttributes<HTMLInputElement>
      }
      {...props}
    />
  );
}
