import { InputAdornment } from '@mui/material';
import { useCallback } from 'react';
import { FieldValues, useWatch } from 'react-hook-form';

import {
  ExtendedTypeaheadQueryOpts,
  useAsyncTypeaheadProps,
} from '@/components/form/baseInputs/BackendTypeaheadSelectInput/hooks/useAsyncTypeaheadProps';
import {
  FormAwareBackendTypeaheadSelectInput,
  FormAwareBackendTypeaheadSelectInputProps,
} from '@/components/form/formAwareInputs/FormAwareBackendTypeaheadSelectInput2';
import { User01Icon } from '@/components/icons/User01Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { RelationshipOwner } from '@/modules/content/tooltipContent/RelationshipOwner';

import {
  GetPossibleRelationshipOwnersQuery,
  GetPossibleRelationshipOwnersQueryVariables,
  useGetPossibleRelationshipOwnersQuery,
} from './graphql/PossibleRelationshipOwnersTypeahead.generated';

export type PossibleRelationshipOwnersTypeahead<FormShape extends FieldValues> =
  Omit<
    FormAwareBackendTypeaheadSelectInputProps<FormShape, string>,
    'options' | 'inputValue' | 'onInputChange'
  >;

const EMPTY_OPTION = {
  value: '',
  groupName: '',
  display: EMPTY_CONTENT_HYPHEN,
};

export function PossibleRelationshipOwnersTypeahead<
  FormShape extends FieldValues,
>(props: PossibleRelationshipOwnersTypeahead<FormShape>) {
  const { createErrorFeedback } = useFeedback();
  const { control } = useFormContext<FormShape>();
  const selectedRelationshipOwnerId = useWatch({
    control,
    name: props.fieldName,
  });

  const searchTermToVariables = useCallback(
    (searchTerm: string) => ({
      optionsLike: {
        hasUserWith: [
          {
            or: [
              {
                searchNameContainsFold: searchTerm,
              },
              {
                emailContainsFold: searchTerm,
              },
            ],
          },
        ],
      },
    }),
    []
  );

  const getSelectedOptionVariables = useCallback(() => {
    return {
      selectedOptionsLike: {
        id: selectedRelationshipOwnerId,
      },
    };
  }, [selectedRelationshipOwnerId]);

  const toOptions: ExtendedTypeaheadQueryOpts<
    string,
    GetPossibleRelationshipOwnersQuery,
    GetPossibleRelationshipOwnersQueryVariables
  >['toOptions'] = useCallback((data) => {
    return data.map((possibleRelationshipOwner) => ({
      // even though we do this lookup through hte possibleRelationshipOwners edges, which returns employees,
      // we save the userId of the possibleRelationshipOwner
      value: possibleRelationshipOwner.user.id,
      display: possibleRelationshipOwner.displayName,
    }));
  }, []);

  const [asyncTypeaheadProps] = useAsyncTypeaheadProps(
    useGetPossibleRelationshipOwnersQuery,
    {
      emptyOption: EMPTY_OPTION,
      pageSize: 15,
      searchTermToVariables,
      getSelectedOptionVariables,
      toOptions,
      onError: createErrorFeedback(
        'Error fetching clients. Please refresh the page to try again.'
      ),
    }
  );

  return (
    <FormAwareBackendTypeaheadSelectInput<FormShape, string>
      {...asyncTypeaheadProps}
      placeholder="Choose a relationship owner"
      contextualHelp={<RelationshipOwner />}
      startAdornment={
        <InputAdornment position="start">
          <User01Icon size={20} />
        </InputAdornment>
      }
      {...props}
    />
  );
}
