import { compact } from 'lodash';

import { BasicInformationSubformType } from '@/modules/entities/BasicInformationSubform/BasicInformationSubform.types';
import {
  BasicInformation_ControllingPartyFragment,
  GetBasicInformationQuery,
} from '@/modules/entities/BasicInformationSubform/graphql/GetBasicInformation.generated';
import { EntityType } from '@/modules/entities/types/EntityType';

import {
  NonGrantorPrincipalFieldsType,
  VariantType,
} from './NonGrantorPrincipalFields.types';

export function getNonGrantorPrincipalFieldsDataToForm(
  data: GetBasicInformationQuery
): NonGrantorPrincipalFieldsType {
  if (data.node?.__typename !== 'Entity') {
    throw new Error('Unexpected node type');
  }

  const { subtype } = data.node;

  function getControllingPartyIds(
    cps: BasicInformation_ControllingPartyFragment[]
  ): { controllingPartyId: string }[] {
    const ids = compact(
      cps.map((cp) => cp.individual?.id || cp.organization?.id)
    );
    return ids.map((id) => ({ controllingPartyId: id }));
  }

  switch (subtype.__typename) {
    case 'RetirementPersonalAccount':
    case 'CustodialPersonalAccount':
    case 'QualifiedTuitionPersonalAccount':
    case 'IndividualPersonalAccount':
      return {
        controllingParties: getControllingPartyIds(
          subtype.controllingParties ?? []
        ),
        owner: subtype.owner
          ? {
              ownerId: subtype.owner.individual?.id ?? '',
            }
          : '',
      };
    case 'JointPersonalAccount':
      return {
        controllingParties: getControllingPartyIds(
          subtype.controllingParties ?? []
        ),
        owners:
          subtype.owners?.map((owner) => ({
            ownerId: owner.individual?.id ?? '',
          })) ?? [],
      };
    case 'DonorAdvisedFund':
    case 'PrivateFoundation':
      return {
        controllingParties: getControllingPartyIds(
          subtype.controllingParties ?? []
        ),
        owners:
          subtype.donors?.map((owner) => ({
            ownerId: owner.individual?.id ?? '',
          })) ?? [],
      };
    case 'InsurancePersonalAccount':
      return {
        controllingParties: [],
        owners:
          subtype.owners?.map((owner) => ({
            ownerId: owner.individual?.id ?? '',
          })) ?? [],
      };
    default:
      throw new Error(`Unexpected subtype: ${subtype.__typename}`);
  }
}

export function getNonGrantorPrincipalFieldVariant(
  entityType: EntityType
): VariantType | undefined {
  switch (entityType) {
    case 'daf':
    case 'private-foundation':
    case 'joint-account':
      return 'multipleOwnerNoPercent';
    case 'individual-account':
    case 'retirement-account':
    case 'custodial-account':
    case 'qualified-tuition-account':
      return 'default';
    case 'insurance-account':
      return 'multipleOwnerNoPercentNoControllingParty';
  }
  return undefined;
}

export function hasNonGrantorPrincipalFields(
  basicInfoSubform: BasicInformationSubformType
) {
  return (
    basicInfoSubform.controllingParties &&
    (basicInfoSubform.owner || basicInfoSubform.owners)
  );
}
