import { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';

import { FormConfigurationProvider } from '@/components/form/context/FormConfigurationContext';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import {
  useForm,
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { useFormSaveHandler } from '@/hooks/useFormSaveHandler';
import { AfterDeath, CreateGraphVisualizationGroupInput } from '@/types/schema';

import { GraphVizGroupBasicDetailsForm } from '../../graphViz/graphVizGroup/GraphVizGroupBasicDetailsForm';
import { EstateWaterfallSectionMultiSelectTable } from '../components/EstateWaterfallSectionMulitSelectTable/EstateWaterfallSectionMultiSelectTable';
import { OnSelectionChange } from '../components/EstateWaterfallSectionMulitSelectTable/EstateWaterfallSectionMultiSelectTable.utils';
import { useEstateWaterfallContext } from '../contexts/estateWaterfall.context';
import { useCreateNodeGroup } from './useCreateNodeGroup';

export interface CreateNewGroupFormShape
  extends CreateGraphVisualizationGroupInput {
  applyToAllSections: boolean;
}

interface CreateNewGroupModalInnerProps {
  isOpen: boolean;
  onClose: () => void;
  nodeIds: string[];
  namespace: AfterDeath;
}

function CreateNewGroupModalInner({
  isOpen,
  onClose,
  nodeIds,
  namespace,
}: CreateNewGroupModalInnerProps) {
  const { formRef, handleSave } = useFormSaveHandler();
  const { formState, handleSubmit, setValue } =
    useFormContext<CreateNewGroupFormShape>();
  const { createNodeGroup } = useCreateNodeGroup();
  const { dispatch } = useEstateWaterfallContext();

  const onSubmit = handleSubmit((formData) => {
    return createNodeGroup(formData);
  });

  const handleClose = () => {
    // If the user cancels, set the waterfall to the prescribed previous waterfall
    setTimeout(() => {
      // Use setTimeout to ensure the dispatch is processed after the modal is closed
      // otherwise the state changes in undoNodeGroupAction will be overridden by the
      // previous state
      dispatch({ type: 'UNDO_NODE_GROUP' });
    });
    onClose();
  };

  const onSuccess = () => {
    onClose();
  };

  useSubmitSuccessHandler(onSuccess);

  const onSelectionChange = useCallback<OnSelectionChange>(
    ({ selectedRowIds }) => {
      setValue('nodeIds', selectedRowIds);
    },
    [setValue]
  );

  return (
    <FormModal
      isOpen={isOpen}
      onClose={handleClose}
      heading="Create new group"
      rightHeaderContent={null}
      actions={
        <FormModalActions.Provider formState={formState}>
          <FormModalActions.CancelButton onClick={handleClose} />
          <FormModalActions.SaveButton onClick={handleSave}>
            Create group
          </FormModalActions.SaveButton>
        </FormModalActions.Provider>
      }
    >
      <form ref={formRef} onSubmit={onSubmit} noValidate>
        <GraphVizGroupBasicDetailsForm<CreateNewGroupFormShape>
          variant="create"
          slots={{
            SelectTable: {
              component: EstateWaterfallSectionMultiSelectTable,
              props: {
                namespace,
                defaultSelectedRowIds: nodeIds,
                onSelectionChange,
              },
            },
          }}
        />
      </form>
    </FormModal>
  );
}

interface CreateNewGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  nodeIds: string[];
  namespace: AfterDeath;
}

export function CreateNewGroupModal(props: CreateNewGroupModalProps) {
  const { isOpen, onClose, nodeIds, namespace } = props;

  const formMethods = useForm<CreateNewGroupFormShape>({
    defaultValues: {
      displayName: '',
      description: '',
      nodeIds,
      namespace,
      applyToAllSections: false,
    },
  });

  return (
    <FormProvider {...formMethods}>
      <FormConfigurationProvider
        value={{ optionalDisplayType: 'required-asterisk' }}
      >
        <CreateNewGroupModalInner
          isOpen={isOpen}
          onClose={onClose}
          nodeIds={nodeIds}
          namespace={namespace}
        />
      </FormConfigurationProvider>
    </FormProvider>
  );
}
