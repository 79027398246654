// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DuplicateHouseholdMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DuplicateHouseholdMutation = { __typename?: 'Mutation', copyHousehold: { __typename?: 'Household', id: string, displayName: string } };


export const DuplicateHouseholdDocument = gql`
    mutation DuplicateHousehold($id: ID!) {
  copyHousehold(id: $id) {
    id
    displayName
  }
}
    `;
export type DuplicateHouseholdMutationFn = Apollo.MutationFunction<DuplicateHouseholdMutation, DuplicateHouseholdMutationVariables>;

/**
 * __useDuplicateHouseholdMutation__
 *
 * To run a mutation, you first call `useDuplicateHouseholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateHouseholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateHouseholdMutation, { data, loading, error }] = useDuplicateHouseholdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDuplicateHouseholdMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateHouseholdMutation, DuplicateHouseholdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateHouseholdMutation, DuplicateHouseholdMutationVariables>(DuplicateHouseholdDocument, options);
      }
export type DuplicateHouseholdMutationHookResult = ReturnType<typeof useDuplicateHouseholdMutation>;
export type DuplicateHouseholdMutationResult = Apollo.MutationResult<DuplicateHouseholdMutation>;
export type DuplicateHouseholdMutationOptions = Apollo.BaseMutationOptions<DuplicateHouseholdMutation, DuplicateHouseholdMutationVariables>;
export const Operations = {
  Mutation: {
    DuplicateHousehold: 'DuplicateHousehold' as const
  }
}