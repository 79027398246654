import { OnNodeDrag, useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';

import {
  isGiftTaxNode,
  isTaxNode,
} from '@/modules/estateWaterfall/waterfallGraph/utils';

import { useFlowChartContext } from '../../context/flowChart.context';
import { InternalFlowChartProps, Node } from '../../types';
import { isTileGroupNode, isTileNode } from '../../utils/nodes';

export function isValidDropTarget(targetNode: Node, draggingNode?: Node) {
  if (!isTileNode(draggingNode)) return false;

  // Cannot drop onto oneself
  if (draggingNode.id === targetNode.id) return false;

  // Only tile nodes can be a target for grouping (group or normal) currently
  if (!isTileNode(targetNode)) return false;

  // Do nothing when dropping onto a tax node or when dropping a tax node
  if (isTaxNode(targetNode.id) || isTaxNode(draggingNode.id)) return false;
  if (isGiftTaxNode(targetNode.id) || isGiftTaxNode(draggingNode.id))
    return false;

  // Don't allow grouping nodes that are in different parent node groups
  if (targetNode.data.sectionLabelId !== draggingNode.data.sectionLabelId) {
    return false;
  }

  // Only non group tiles can be a dragging node
  return isTileNode(draggingNode) && !isTileGroupNode(draggingNode);
}

interface FilterIntersectionsInput {
  draggingNode?: Node;
  intersections: Node[];
}

const filterIntersections = ({
  draggingNode,
  intersections,
}: FilterIntersectionsInput): Node[] =>
  intersections.filter((n) => isValidDropTarget(n, draggingNode));

/**
 * @description Wrapper around nodes drag callback to keep track of drop targets
 * Note: this does make assumptions about what a "group node", eventually that may need to be configurable
 * and decoupled from the internal Node definition.
 */
export function useInternalOnNodeDrag({
  onNodeDrag: onNodeDragProp,
}: InternalFlowChartProps) {
  /**
   * Intersection logic:
   * slack context: https://withluminary.slack.com/archives/C05PFLRCCT1/p1699571503236679
   */
  const { getIntersectingNodes } = useReactFlow();
  const {
    dropTargetNode,
    __internal: { setDropTargetNode },
  } = useFlowChartContext();

  const onNodeDrag = useCallback<OnNodeDrag<Node>>(
    (...args) => {
      const [_event, draggingNode] = args;

      const nodeIntersections = filterIntersections({
        draggingNode: draggingNode as Node | undefined,
        intersections: draggingNode
          ? (getIntersectingNodes(draggingNode) as Node[])
          : [],
      });

      const intersections = nodeIntersections;

      const intersectionTarget = intersections[0] ?? null;

      // Only update when there is a change to the underlying node id
      if (intersectionTarget?.id !== dropTargetNode?.id) {
        setDropTargetNode(intersectionTarget);
      }

      onNodeDragProp?.(...args);
    },
    [
      dropTargetNode?.id,
      getIntersectingNodes,
      onNodeDragProp,
      setDropTargetNode,
    ]
  );

  return onNodeDrag;
}
