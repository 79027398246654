import {
  IconButton,
  IconButtonProps,
} from '@/components/form/baseInputs/Button/IconButton';
import { RefreshCw05Icon } from '@/components/icons/RefreshCw05Icon';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { Tooltip } from '@/components/poppers/Tooltip/Tooltip';
import { getUserFacingErrorMessages } from '@/graphql/errors';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';

import { useResyncTenantValuationsMutation } from './graphql/ResyncTenantValuationsButton.generated';

interface ResyncTenantValuationsButtonProps extends Partial<IconButtonProps> {
  disabled?: boolean;
}

export function ResyncTenantValuationsButton(
  buttonProps: ResyncTenantValuationsButtonProps
) {
  const { showFeedback } = useFeedback();
  const trackUserEvent = useTrackUserEvent();
  const [resyncTenantValuations, { loading }] =
    useResyncTenantValuationsMutation({
      onCompleted: () => {
        showFeedback(
          'Successfully initiated resync of entity valuations. This may take a few minutes depending on the number of entities.',
          { variant: 'success' }
        );
      },
      onError: (err) => {
        const errorMessages = getUserFacingErrorMessages(err);
        showFeedback(`Error syncing valuations: ${errorMessages.join(' ')}`);
      },
    });

  const handleClick = () => {
    trackUserEvent('user resynced tenant valuations');
    void resyncTenantValuations();
  };

  return (
    <Tooltip title="Resync valuations for all integrated entities">
      <IconButton
        icon={RefreshCw05Icon}
        ariaLabel="Resync valuations"
        size="xs"
        onClick={handleClick}
        loading={loading}
        {...buttonProps}
      />
    </Tooltip>
  );
}
