import { noop } from 'lodash';
import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

import { CommonAiSuggestionFragment } from '../graphql/aiSuggestions.generated';

export interface SelectedSuggestionContext {
  /**
   * Locate the evidence for a suggestion in the doc viewer.
   * @param suggestion
   */
  locateSuggestion: (suggestion: CommonAiSuggestionFragment) => void;
}

export const getDefaultValues = (): SelectedSuggestionContext => ({
  locateSuggestion: noop,
});

export const SelectedSuggestionContext =
  createContext<SelectedSuggestionContext>(getDefaultValues());

export const useSelectedSuggestionContext = () => {
  return useGuardedContext(
    SelectedSuggestionContext,
    'SelectedSuggestionProvider'
  );
};
