import { useTheme } from '@mui/material';

import { PopperContent } from '@/components/poppers/PopperContent';
import { useFederalEstateTaxFormatted } from '@/modules/irs/useIRSConstants';

export function ImpliedEstateTaxSavings() {
  const theme = useTheme();
  const rate = useFederalEstateTaxFormatted();
  return (
    <PopperContent
      sx={{
        maxWidth: theme.spacing(30),
      }}
      heading="Implied estate tax savings"
      body={`Assumes the implied surplus would otherwise be taxable at a federal estate tax rate of ${rate}%. Does not account for any remaining gift tax exemption, or state inheritance or estate taxes.`}
    />
  );
}
