import Decimal from 'decimal.js';

import { EntityType } from '@/modules/entities/types/EntityType';
import {
  DispositiveProvisionDispositionKind,
  DispositiveProvisionTransferTaxKind,
} from '@/types/schema';
import { PathsOf } from '@/types/subform';

import { DispositionScheme } from '../dispositiveProvisions.types';
import { DISPOSITIVE_PROVISIONS_FORM_NAMESPACE } from './DispositiveProvisionsForm.constants';

export enum RecipientKind {
  Individual = 'individual',
  Entity = 'entity',
  Organization = 'organization',
  TestamentaryEntity = 'testamentaryEntity',
  SurvivingSpouse = 'survivingSpouse',
}

export interface Recipient {
  recipientId: string | null;
  recipientKind: RecipientKind | null;
  // The disposition kind can be set from a button group or from a select input
  // if the user selects "f(x)" from the button group
  dispositionKind_ButtonGroup:
    | DispositiveProvisionDispositionKind.Percentage
    | DispositiveProvisionDispositionKind.Amount
    | 'other';
  dispositionKind_Select:
    | DispositiveProvisionDispositionKind.AnythingLeftOver
    | DispositiveProvisionDispositionKind.RemainingGstExclusionOfGrantorInExcessOfLifetimeExclusion
    | DispositiveProvisionDispositionKind.RemainingLifetimeExclusionOfGrantor
    | DispositiveProvisionDispositionKind.RemainingStateExemption
    | DispositiveProvisionDispositionKind.RemainingFederalLifetimeExemptionInExcessOfStateExemption
    | '';
  dispositionAmount: Decimal | null;
  dispositionPercentage: Decimal | null;
  notes: string;
  transferTaxKind: DispositiveProvisionTransferTaxKind | '';
  // _dispositiveProvisionId is used to link the recipient to a dispositive provision
  // for deletion scenarios; it's never set for new recipients or interacted with via the form
  _dispositiveProvisionId: string | null;
}

export interface DraggableRecipient extends Recipient {
  id: string;
  index: number;
}

// Indicates that the recipient includes a selected recipient from the dropdown
export interface RecipientWithId extends Recipient {
  recipientId: string;
}

export interface DispositiveProvisionsFormShape {
  [DISPOSITIVE_PROVISIONS_FORM_NAMESPACE]: {
    // dispositionScenarioId will be present if there's already an existing disposition scenario
    // that should be updated and null if it needs to be created or it's on a testamentary entity,
    // which has dispositive provisions directly and not disposition scenarios
    dispositionScenarioId: string | null;
    _dispositionScenarioAssociatedWaterfallId: string | null;
    selectedFirstPrimaryClientDeathId: string | null;
    _hasBeenReviewed: boolean;
    dispositionScheme: DispositionScheme;
    recipients: Recipient[];
    templateId?: string | null;
  };
}

export type DispositiveProvisionsFormPaths =
  PathsOf<DispositiveProvisionsFormShape>;

export enum DispositiveProvisionsModalDetailsType {
  ENTITY = 'entity',
  CLIENT_PROFILE = 'clientProfile',
  TESTAMENTARY_ENTITY = 'testamentaryEntity',
}

export type DispositiveProvisionsModalDetails =
  | DispositiveProvisionsModalDetails_Entity
  | DispositiveProvisionsModalDetails_ClientProfile
  | DispositiveProvisionsModalDetails_TestamentaryEntity;

interface DispositiveProvisionsModalDetails_Entity {
  type: DispositiveProvisionsModalDetailsType.ENTITY;
  isFromTemplateEditor?: boolean;
  firstDeathClientId: string;
  entityDisplayName: string;
  entityType: EntityType;
  entityId: string;
  entitySubtypeId: string;
}

interface DispositiveProvisionsModalDetails_ClientProfile {
  type: DispositiveProvisionsModalDetailsType.CLIENT_PROFILE;
  isFromTemplateEditor?: boolean;
  displayName: string;
  clientProfileId: string;
  firstDeathClientId: string;
  /**
   * We specifically need to pass valueToDistribute here because it's computed based on
   * a waterfall, and includes a combination of business assets and received assets.
   */
  valueToDistribute: Decimal;
}

interface DispositiveProvisionsModalDetails_TestamentaryEntity {
  type: DispositiveProvisionsModalDetailsType.TESTAMENTARY_ENTITY;
  isFromTemplateEditor?: boolean;
  entityDisplayName: string;
  testamentaryEntityId: string;
  firstDeathClientId: string;
}
