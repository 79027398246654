import { MarkTaskSkippedMutationVariables } from './graphql/MarkTaskSkipped.generated';
import { ILITSkipTaskModalForm } from './ILITTaskFooter.UI';

export function mapFormToSkipMutation(
  formData: ILITSkipTaskModalForm,
  taskId: string
): MarkTaskSkippedMutationVariables {
  return {
    cancelEntityTaskId: taskId,
    cancelledReason: formData.skipReason,
  };
}
