import { Box, Stack, Typography } from '@mui/material';
import Decimal from 'decimal.js';

import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { ImpliedEstateTaxSavingsCompleted } from '@/modules/content/tooltipContent/ImpliedEstateTaxSavingsCompleted';
import { useFederalEstateTaxFormatted } from '@/modules/irs/useIRSConstants';
import { COLORS } from '@/styles/tokens/colors';
import { formatCurrency } from '@/utils/formatting/currency';

export function SumTable({
  displayImpliedSurplus,
  displayTaxFreeTransfer,
  displayImpliedEstateTaxSavings,
  taxableGift,
  variant = 'narrow',
  noGrats,
}: {
  displayImpliedSurplus: Decimal;
  displayTaxFreeTransfer: Decimal;
  displayImpliedEstateTaxSavings: Decimal;
  taxableGift: Decimal;
  variant: 'narrow' | 'wide';
  noGrats: boolean;
}) {
  const rate = useFederalEstateTaxFormatted();
  const isWide = variant === 'wide';

  const greyColor = COLORS.GRAY[500];
  const navyColor = isWide ? COLORS.NAVY[600] : COLORS.NAVY[900];

  return (
    <Stack
      width={isWide ? '100%' : 'auto'}
      p={2}
      bgcolor={COLORS.GRAY[100]}
      borderRadius={1}
      gap={1}
    >
      <Stack
        justifyContent="space-between"
        direction="row"
        paddingLeft={1}
        paddingRight={1}
      >
        <Typography
          color={isWide ? greyColor : COLORS.NAVY[900]}
          variant="subtitle2"
        >
          {isWide ? 'Implied wealth transferred' : 'Implied surplus'}
        </Typography>
        <Typography color={navyColor} variant="h5">
          {noGrats
            ? EMPTY_CONTENT_HYPHEN
            : formatCurrency(displayImpliedSurplus)}
        </Typography>
      </Stack>
      <Stack
        justifyContent="space-between"
        direction="row"
        paddingLeft={isWide ? 1 : 3}
        paddingRight={1}
      >
        <Typography variant="subtitle2" color={COLORS.GRAY[500]}>
          {isWide ? 'Less taxable gifts' : 'Taxable gift'}
        </Typography>
        <Typography color={isWide ? navyColor : greyColor} variant="h5">
          {noGrats
            ? EMPTY_CONTENT_HYPHEN
            : formatCurrency(taxableGift.times(-1), {
                currencySign: 'accounting',
              })}
        </Typography>
      </Stack>
      <Box borderTop={`1px ${greyColor} solid`} />
      <Stack
        justifyContent="space-between"
        direction="row"
        paddingLeft={1}
        paddingRight={1}
      >
        <Typography color={isWide ? greyColor : navyColor} variant="subtitle2">
          Tax-free transfer
        </Typography>
        <Typography color={navyColor} variant="h5">
          {noGrats
            ? EMPTY_CONTENT_HYPHEN
            : formatCurrency(displayTaxFreeTransfer)}
        </Typography>
      </Stack>
      <Stack mt={2} pt={1} px={isWide ? 1 : 0} spacing={1}>
        {isWide && (
          <Stack justifyContent="flex-end" direction="row">
            <Typography color={navyColor} variant="h5">
              x{rate}%
            </Typography>
          </Stack>
        )}
        <Stack
          justifyContent="space-between"
          direction="row"
          bgcolor={isWide ? 'unset' : COLORS.GRAY[200]}
          py={isWide ? 0 : 1.5}
          px={isWide ? 0 : 1}
          alignItems="center"
          gap={3}
        >
          <Stack direction="row" alignSelf="center">
            <Typography
              color={isWide ? greyColor : navyColor}
              variant="subtitle2"
            >
              Implied estate tax savings
            </Typography>
            <ContextualHelpTooltip>
              <ImpliedEstateTaxSavingsCompleted />
            </ContextualHelpTooltip>
          </Stack>
          <Typography color={navyColor} variant="h5">
            {noGrats
              ? EMPTY_CONTENT_HYPHEN
              : formatCurrency(displayImpliedEstateTaxSavings)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
