import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { CharitableTrustPayoutKind } from '@/types/schema';

import { useIsCRT } from '../../CharitableTrustDesignerContext';
import {
  CharitableTrustDesignerAnalysisForm,
  NAMESPACE,
} from '../CharitableTrustDesignerAnalysis.types';

export const MINIMUM_CLUT_PAYOUT = new Decimal('0.2');
export const MINIMUM_CRUT_PAYOUT = new Decimal('5');
export const MAXIMUM_UNITRUST_PAYOUT = new Decimal('20');

export function useValidPayout() {
  const { control } = useFormContext<CharitableTrustDesignerAnalysisForm>();
  const isCRT = useIsCRT();
  const [
    payoutKind,
    payoutFrequency,
    annuityPayoutAmount,
    unitrustPayoutPercent,
  ] = useWatch({
    control,
    name: [
      `${NAMESPACE}.payouts.kind`,
      `${NAMESPACE}.payouts.frequency`,
      `${NAMESPACE}.payouts.annuityPaymentAmount`,
      `${NAMESPACE}.payouts.unitrustPayoutPercent`,
    ],
  });

  const potentialOutput = useMemo(() => {
    if (
      !unitrustPayoutPercent ||
      unitrustPayoutPercent.isZero() ||
      unitrustPayoutPercent.isNaN()
    ) {
      return null;
    }
    if (payoutKind === CharitableTrustPayoutKind.Unitrust) {
      const min = isCRT ? MINIMUM_CRUT_PAYOUT : MINIMUM_CLUT_PAYOUT;
      const below: boolean = unitrustPayoutPercent?.lessThan(min);
      const above: boolean = unitrustPayoutPercent?.greaterThan(
        MAXIMUM_UNITRUST_PAYOUT
      );
      if (above || below) {
        return null;
      }
    }

    return {
      payoutKind,
      payoutFrequency,
      annuityPayoutAmount: annuityPayoutAmount || new Decimal(0),
      unitrustPayoutPercent: unitrustPayoutPercent || new Decimal(0),
    };
  }, [
    annuityPayoutAmount,
    isCRT,
    payoutFrequency,
    payoutKind,
    unitrustPayoutPercent,
  ]);

  return potentialOutput;
}
