import { Skeleton } from '@mui/material';
import { first, noop } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { useReportError } from '@/hooks/useReportError';
import { EntityDetailsCardProps } from '@/modules/entities/components/EntityDetailsCard';
import { TrustDetailsTabs } from '@/modules/entities/details/entityDetails.types';
import { FeatureFlagMap } from '@/modules/featureFlags/featureFlags.types';
import {
  getNonTrustCharitableEntityDetailsTabs,
  getNonTrustCharitableEntitySummaryProperties,
} from '@/modules/nonTrustCharitableEntities/NonTrustCharitableEntityDetails/NonTrustCharitableEntityDetails.utils';
import {
  getInsuranceAccountDetailsTabs,
  getPersonalAccountDetailsTabs,
} from '@/modules/personalAccounts/PersonalAccountDetails/PersonalAccountDetails.utils';
import { getTrustDetailsTabs } from '@/modules/trusts/TrustDetails/TrustDetails.utils';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { useEntityDetailsContext } from '../contexts/entityDetails/entityDetails.context';
import { EntityType } from '../types/EntityType';
import { getCustodialQualifiedTuitionPersonalAccountSummaryProperties } from './accounts/CustodialQualifiedTuitionPersonalAccountDetails.utils';
import { getIndividualPersonalAccountSummaryProperties } from './accounts/IndividualPersonalAccountDetails.utils';
import { getInsuranceAccountSummaryProperties } from './accounts/InsuranceAccountDetails.utils';
import { getJointPersonalAccountSummaryProperties } from './accounts/JointPersonalAccountDetails.utils';
import { getRetirementPersonalAccountSummaryProperties } from './accounts/RetirementPersonalAccountDetails.utils';
import {
  getBusinessEntityDetailsTabs,
  getBusinessEntitySummaryProperties,
} from './businessEntities/BusinessEntityDetailsCard/BusinessEntityDetails.utils';
import { CashFlowsCard } from './CashFlowsCard/CashFlowsCard';
import {
  EntityCardProps,
  getSharedTopLevelProperties,
} from './entityDetailPageUtils';
import { EntityValuationCard } from './EntityValuation/components/EntityValuationCard';
import {
  EntityDetail_EntityFragment,
  useEntityDetailsQuery,
} from './graphql/EntityDetailPage.generated';
import { GRATDistributionCard } from './grat/GRATDistributionCard/GRATDistributionCard';
import { getGRATTrustDetailsTabs } from './grat/GRATTrustDetails';
import { StageAwareGRATAssetsCard } from './grat/StageAwareGRATAssetsCard';
import { HypotheticalTransfersCard } from './HypotheticalTransfersCard/HypotheticalTransfersCard';
import { GenericEntityAssetsCard } from './shared/GenericEntityAssetsCard';
import { getCLTTrustSummaryProperties } from './trusts/CLTTrustDetails';
import { getCRTTrustSummaryProperties } from './trusts/CRTTrustDetails';
import { getILITTrustSummaryProperties } from './trusts/ILITTrustDetails';
import { getQPRTTrustSummaryProperties } from './trusts/QPRTTrustDetails';
import { getRevocableIrrevocableTrustSummaryProperties } from './trusts/RevocableIrrevocableTrustDetails';
import { getSLATTrustSummaryProperties } from './trusts/SLATTrustDetails';

interface EntityTypeDetails {
  entityType: EntityType;
  subtypeId: string | null;
  summary: EntityDetailsCardProps['tabs'];
  AssetsCard: React.ReactNode;
  DistributionVisualization?: React.ReactNode;
  TransfersCard: React.ReactNode;
  CashFlowsCard: React.ReactNode;
}

interface GetEntityPageConfigurationParams
  extends UseEntityPageDefinitionParams {
  refetchData: () => void;
  featureFlags: FeatureFlagMap;
}

function getEntityPageConfiguration(
  entity: EntityDetail_EntityFragment,
  opts: GetEntityPageConfigurationParams = {
    isPanelView: false,
    refetchData: noop,
    featureFlags: {},
  }
): EntityTypeDetails {
  const topLevelProperties = getSharedTopLevelProperties(entity);
  const cardProps: EntityCardProps = {
    entity,
    entityType: topLevelProperties.entityType,
    onUpdate,
    hasValuation: !!entity.subtype.mostRecentValuationDate,
    isPanelView: opts.isPanelView,
  };

  const summaryProps = {
    entity,
    entityType: cardProps.entityType,
  };

  function onUpdate() {
    opts.refetchData();
  }

  switch (entity.subtype.__typename) {
    case 'IrrevocableTrust':
    // falls through to identical handling of revocable trusts
    case 'RevocableTrust': {
      const summaryData =
        getRevocableIrrevocableTrustSummaryProperties(summaryProps);

      return {
        ...topLevelProperties,
        summary: getTrustDetailsTabs(summaryData, opts.featureFlags),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }
    case 'SLATTrust': {
      const summaryData = getSLATTrustSummaryProperties(summaryProps);
      return {
        ...topLevelProperties,
        summary: getTrustDetailsTabs(summaryData, opts.featureFlags),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }
    case 'QPRTTrust': {
      const summaryData = getQPRTTrustSummaryProperties(summaryProps);
      return {
        ...topLevelProperties,
        summary: getTrustDetailsTabs(summaryData, opts.featureFlags),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }
    case 'ILITTrust': {
      const summaryData = getILITTrustSummaryProperties(summaryProps);
      return {
        ...topLevelProperties,
        summary: getTrustDetailsTabs(summaryData, opts.featureFlags),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }
    case 'GRATTrust': {
      return {
        ...topLevelProperties,
        summary: getGRATTrustDetailsTabs(cardProps, opts.featureFlags),
        AssetsCard: <StageAwareGRATAssetsCard {...cardProps} />,
        DistributionVisualization: <GRATDistributionCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }
    case 'CLTTrust': {
      const summaryData = getCLTTrustSummaryProperties(summaryProps);
      return {
        ...topLevelProperties,
        summary: getTrustDetailsTabs(summaryData, opts.featureFlags),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }
    case 'CRTTrust': {
      const summaryData = getCRTTrustSummaryProperties(summaryProps);
      return {
        ...topLevelProperties,
        summary: getTrustDetailsTabs(summaryData, opts.featureFlags),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }

    case 'InsurancePersonalAccount': {
      const summaryData = getInsuranceAccountSummaryProperties(summaryProps);

      return {
        ...topLevelProperties,
        summary: getInsuranceAccountDetailsTabs(summaryData, opts.featureFlags),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard />,
      };
    }

    case 'JointPersonalAccount': {
      const summaryData =
        getJointPersonalAccountSummaryProperties(summaryProps);
      return {
        ...topLevelProperties,
        summary: getPersonalAccountDetailsTabs(summaryData, opts.featureFlags),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard />,
      };
    }
    case 'IndividualPersonalAccount': {
      const summaryData =
        getIndividualPersonalAccountSummaryProperties(summaryProps);
      return {
        ...topLevelProperties,
        summary: getPersonalAccountDetailsTabs(summaryData, opts.featureFlags),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }
    case 'RetirementPersonalAccount': {
      const summaryData =
        getRetirementPersonalAccountSummaryProperties(summaryProps);
      return {
        ...topLevelProperties,
        summary: getPersonalAccountDetailsTabs(summaryData, opts.featureFlags),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }
    case 'CustodialPersonalAccount':
    // falls through to shared handling with 529 plans
    case 'QualifiedTuitionPersonalAccount': {
      const summaryData =
        getCustodialQualifiedTuitionPersonalAccountSummaryProperties(
          summaryProps
        );

      return {
        ...topLevelProperties,
        summary: getPersonalAccountDetailsTabs(summaryData, opts.featureFlags),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }

    case 'DonorAdvisedFund':
    case 'PrivateFoundation': {
      const summaryData =
        getNonTrustCharitableEntitySummaryProperties(summaryProps);
      return {
        ...topLevelProperties,
        summary: getNonTrustCharitableEntityDetailsTabs(
          summaryData,
          opts.featureFlags
        ),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }

    case 'LLCBusinessEntity':
    case 'GPBusinessEntity':
    case 'LPBusinessEntity':
    case 'SoleProprietorshipBusinessEntity':
    case 'CCorpBusinessEntity':
    case 'SCorpBusinessEntity': {
      const summaryData = getBusinessEntitySummaryProperties(summaryProps);
      return {
        ...topLevelProperties,
        summary: getBusinessEntityDetailsTabs(summaryData, opts.featureFlags),
        AssetsCard: <GenericEntityAssetsCard {...cardProps} />,
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }

    default: {
      const message = `attempting to render unknown trust type ${entity.subtype.__typename}`;
      diagnostics.error(message, new Error(message));
      return {
        subtypeId: null,
        entityType: 'unknown' as never,
        summary: [
          {
            display: 'Details',
            value: TrustDetailsTabs.SUMMARY,
          },
        ],
        AssetsCard: (
          <EntityValuationCard
            headlineValue={EMPTY_CONTENT_HYPHEN}
            headlineValueLabel=""
            actions={null}
            variant="card"
            assetPropertyGroups={[]}
            hasValidValuation={false}
            integrationDetails={null}
          />
        ),
        TransfersCard: <HypotheticalTransfersCard {...cardProps} />,
        CashFlowsCard: <CashFlowsCard {...cardProps} />,
      };
    }
  }
}

interface UseEntityPageDefinitionState {
  entity: EntityDetail_EntityFragment | undefined;
  entityPageDefinition: EntityTypeDetails;
}

interface UseEntityPageDefinitionParams {
  isPanelView: boolean;
}

export function useEntityPageDefinition(
  entityId: string,
  opts?: UseEntityPageDefinitionParams
) {
  const { entityNotFound } = useEntityDetailsContext();
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const [useEntityPageDefinitionState, setUseEntityPageDefinitionState] =
    useState<UseEntityPageDefinitionState>({
      entity: undefined,
      entityPageDefinition: {
        entityType: 'unknown' as never,
        subtypeId: null,
        DistributionVisualization: undefined,
        summary: [],
        AssetsCard: (
          <EntityValuationCard
            actions={null}
            assetPropertyGroups={[]}
            hasValidValuation={false}
            headlineValue={<Skeleton width={150} height={40} />}
            headlineValueLabel={<Skeleton width={120} height={20} />}
            integrationDetails={null}
            variant="card"
          />
        ),
        TransfersCard: <HypotheticalTransfersCard />,
        CashFlowsCard: <CashFlowsCard />,
      },
    });

  const queryProps = useEntityDetailsQuery({
    variables: {
      entityId,
    },
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
    onError: (error) => {
      // we don't care about alerting  if the entity isn't found
      if (entityNotFound) return;
      reportError('failed to load entity details on overview page', error);
      showFeedback(FeedbackMessages.queryError);
    },
  });

  const entity = first(getNodes(queryProps.data?.entities));

  useEffect(() => {
    if (!entity) return;

    setUseEntityPageDefinitionState({
      entity: entity as EntityDetail_EntityFragment,
      entityPageDefinition: getEntityPageConfiguration(
        entity as EntityDetail_EntityFragment,
        {
          refetchData: () => {
            return queryProps.refetch();
          },
          isPanelView: opts?.isPanelView ?? false,
          featureFlags: {},
        }
      ),
    });
  }, [entity, opts?.isPanelView, queryProps]);

  const numDocsWithSummaries = useMemo(() => {
    if (!entity || entity.__typename !== 'Entity') return 0;

    return entity.numDocumentsWithSummary.totalCount;
  }, [entity]);

  return {
    ...useEntityPageDefinitionState,
    ...queryProps,
    numDocsWithSummaries, // Override the loading property from queryProps with
    // the presence of the entity because the query's loading
    // property will return as true before the setUseEntityPageDefinitionState
    // is complete.
    loading: !useEntityPageDefinitionState.entity,
  };
}
