import { createContext } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export const BULK_IMPORT_FLOW_ADDEPAR = 'addepar' as const;

export type BulkImportFlowType = typeof BULK_IMPORT_FLOW_ADDEPAR;

export interface BulkImportFlowContextType {
  flowType: BulkImportFlowType;
}

export const BulkImportFlowContext = createContext<BulkImportFlowContextType>({
  flowType: BULK_IMPORT_FLOW_ADDEPAR,
});

export const BulkImportFlowContextProvider = BulkImportFlowContext.Provider;

export const VALID_BULK_IMPORT_FLOW_TPYES = [BULK_IMPORT_FLOW_ADDEPAR];

export const BulkImportFlowTitleMap: Record<BulkImportFlowType, string> = {
  [BULK_IMPORT_FLOW_ADDEPAR]: 'Addepar',
};

export function getBulkImportFlow(flowType: string): BulkImportFlowType {
  if (!VALID_BULK_IMPORT_FLOW_TPYES.includes(flowType as BulkImportFlowType)) {
    throw new Error('Invalid flow type');
  }
  return flowType as BulkImportFlowType;
}

export function useBulkImportFlowContext(): BulkImportFlowContextType {
  return useGuardedContext<BulkImportFlowContextType>(
    BulkImportFlowContext,
    'BulkImportFlowContextProivder'
  );
}
