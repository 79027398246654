import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { DropdownButton } from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { SidePanel } from '@/components/modals/SidePanel';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { DispositiveProvisionsContext } from '@/modules/dispositiveProvisions/contexts/dispositiveProvisions.context';
import { useDispositiveProvisionsContextData } from '@/modules/dispositiveProvisions/hooks/useDispositiveProvisionsContextData';
import { TestamentaryEntityDetailsAwareRoute } from '@/modules/entities/contexts/testamentaryEntityDetails/TestamentaryEntityDetailsAwareRoute';
import { TestamentaryEntityModal } from '@/modules/entities/testamentaryEntities/TestamentaryEntityForm/TestamentaryEntityModal';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { EditButton } from '../components/EditButton';
import { SummaryPanelLoader } from '../components/SummaryPanelLoader';
import {
  TestamentaryEntitySummaryPanel_TestamentaryEntityFragment,
  useTestamentaryEntitySummaryPanelQuery,
} from './graphql/TestamentaryEntitySummaryPanel.generated';
import {
  TestamentaryEntityTabs,
  useTestamentaryEntityPanels,
} from './useTestamentaryEntityPanels';
import { useTestamentaryEntitySummaryData } from './useTestamentaryEntitySummaryData';

export interface TestamentaryEntitySummaryPanelProps {
  entityId: string;
  householdId: string;
  onClose: () => void;
  firstPrimaryClientDeathId: string | null;
  entityValue: Decimal;
  isOnWaterfall?: boolean;
}

interface CurrentView {
  display: string;
  value: string;
}

interface TestamentaryEntitySummaryPanelInnerProps {
  entityId: string;
  onClose: () => void;
  testamentaryEntity: TestamentaryEntitySummaryPanel_TestamentaryEntityFragment;
  dispositiveProvisionData: DispositiveProvisionsContext;
  firstDeathPrimaryClientId: string;
  isOnWaterfall?: boolean;
}

function TestamentaryEntitySummaryPanelInner({
  entityId,
  onClose,
  testamentaryEntity,
  dispositiveProvisionData,
  firstDeathPrimaryClientId,
  isOnWaterfall,
}: TestamentaryEntitySummaryPanelInnerProps) {
  const {
    inEstateStatus,
    gstStatus,
    grantorDeath: primaryClientDeath,
    displayName,
  } = testamentaryEntity;
  const { primaryClients } = dispositiveProvisionData;

  const [isEditingTestamentaryEntity, setIsEditingTestamentaryEntity] =
    useState(false);
  const [currentView, setCurrentView] = useState<CurrentView>();

  const { hasAliveSpouse, deadPrimaryClientIds, taxStatus, assetLocation } =
    useTestamentaryEntitySummaryData({
      primaryClients,
      primaryClientDeathId: primaryClientDeath?.id ?? '',
      firstPrimaryClientDeathId: firstDeathPrimaryClientId,
      inEstateStatus,
      gstStatus: gstStatus ?? null,
    });

  const panels = useTestamentaryEntityPanels({
    testamentaryEntity,
    hasAliveSpouse,
    taxStatus,
    firstDeathPrimaryClientId,
    assetLocation,
  });

  const selectItems = useMemo(() => {
    return compact(
      panels.map(({ display, value }) => {
        return {
          name: display,
          clickHandler: () => setCurrentView({ display, value }),
        };
      })
    );
  }, [panels]);

  useEffect(
    function updateCurrentView() {
      if (!currentView) {
        if (isOnWaterfall) {
          // Set the inital tab to the dispositions tab
          // when on a waterfall
          setCurrentView(
            panels.find(
              (t) => t.value === TestamentaryEntityTabs.Disposition
            ) ?? panels[0]
          );
        } else {
          setCurrentView(panels[0]);
        }
      }
    },
    [currentView, isOnWaterfall, panels]
  );

  const CurrentPanel = useMemo(
    () =>
      panels.find(({ value }) => value === currentView?.value)?.component ??
      null,
    [currentView, panels]
  );

  return (
    <DispositiveProvisionsContext.Provider value={dispositiveProvisionData}>
      <TestamentaryEntityModal
        isOpen={isEditingTestamentaryEntity}
        onClose={() => setIsEditingTestamentaryEntity(false)}
        testamentaryEntityId={entityId}
        dyingPrimaryClientId={primaryClientDeath?.id ?? ''}
        deadClientIds={deadPrimaryClientIds}
        isTwoClientHousehold={primaryClients.length === 2}
      />
      <SidePanel.Panel>
        <SidePanel.Header title={displayName} onClose={onClose}>
          <DropdownButton
            showArrow
            name="Dropdown-selectView"
            variant="secondary"
            size="sm"
            buttonContent={currentView?.display || ''}
            items={selectItems}
            contentBoxSx={{ width: '100%', justifyContent: 'space-between' }}
          />
        </SidePanel.Header>
        <SidePanel.Content>
          <SidePanel.Section>
            {CurrentPanel && <CurrentPanel />}
          </SidePanel.Section>
        </SidePanel.Content>
        <SidePanel.Footer>
          <EditButton onClick={() => setIsEditingTestamentaryEntity(true)}>
            Edit details
          </EditButton>
        </SidePanel.Footer>
      </SidePanel.Panel>
    </DispositiveProvisionsContext.Provider>
  );
}

function TestamentaryEntitySummaryDataFetcher(
  props: TestamentaryEntitySummaryPanelProps
) {
  const { entityId, householdId, firstPrimaryClientDeathId } = props;

  const { showFeedback } = useFeedback();

  const { data, loading } = useTestamentaryEntitySummaryPanelQuery({
    variables: { where: { id: entityId } },
    onError: (error) => {
      showFeedback(
        `Couldn't get information. Please refresh the page and try again.`
      );
      diagnostics.error(`failed to fetch testamentary entity`, error, {
        entityId,
        householdId,
      });
    },
  });

  const dispositiveProvisionData = useDispositiveProvisionsContextData();

  const te = useMemo(() => getNodes(data?.testamentaryEntities)[0], [data]);

  if (
    loading ||
    !te ||
    dispositiveProvisionData.loading ||
    !firstPrimaryClientDeathId
  ) {
    return <SummaryPanelLoader />;
  }

  return (
    <TestamentaryEntitySummaryPanelInner
      testamentaryEntity={te}
      dispositiveProvisionData={{
        ...dispositiveProvisionData,
        formVariant: 'manageDispositions',
      }}
      firstDeathPrimaryClientId={firstPrimaryClientDeathId}
      {...props}
    />
  );
}

export const TestamentaryEntitySummaryPanel = (
  props: TestamentaryEntitySummaryPanelProps
) => {
  const { entityId, entityValue } = props;
  return (
    <TestamentaryEntityDetailsAwareRoute
      entityId={entityId}
      totalMarketValue={entityValue}
    >
      <TestamentaryEntitySummaryDataFetcher {...props} />
    </TestamentaryEntityDetailsAwareRoute>
  );
};
