import { first } from 'lodash';
import { useCallback, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import { FormFieldsDisabledProvider } from '@/components/form/context/formFieldsDisabled.provider';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { FormModalActions } from '@/components/modals/FormModal/FormModalActions';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import {
  AugmentedCreateCashflowInput,
  AugmentedUpdateCashflowInput,
} from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';
import { getNodes } from '@/utils/graphqlUtils';

import { CashflowModalBody } from './CashflowModal.components';
import {
  CashflowModalDefaultValues,
  CashflowModalFormShape,
} from './CashflowModal.types';
import {
  mapDataToCashflowModal,
  mapFormDataToCreatePayload,
  mapFormDataToUpdatePayload,
} from './CashflowModal.utils';
import {
  useCashflowQuery,
  useCreateCashflowMutation,
  useDeleteCashflowMutation,
  useUpdateCashflowMutation,
} from './graphql/Cashflows.generated';

export interface CashflowModalProps {
  isOpen: boolean;
  onClose: () => void;
  cashflowId: string | null;
  recipient?: string;
}

export function CashflowModal({
  cashflowId,
  isOpen,
  onClose,
  recipient,
}: CashflowModalProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const { householdId } = useHouseholdDetailsContext();
  const trackUserEvent = useTrackUserEvent();
  const formMethods = useForm<CashflowModalFormShape>({
    defaultValues: {
      ...CashflowModalDefaultValues,
      targetId: recipient || CashflowModalDefaultValues.targetId,
    },
  });

  const { handleSubmit, formState, shouldBlockNavigation, reset } = formMethods;
  const { data, loading } = useCashflowQuery({
    variables: {
      where: {
        id: cashflowId,
      },
    },
    skip: !cashflowId,
  });

  const cashflow = first(getNodes(data?.cashflows));
  useEffect(() => {
    if (cashflow) {
      reset(mapDataToCashflowModal(cashflow));
    }
  }, [cashflow, reset]);

  const [createCashflow, { loading: isCreatingCashflow }] =
    useCreateCashflowMutation({
      onError: (error) => {
        showFeedback(
          'Could not create the cash flow.  Please try again later.'
        );
        reportError('Caught error while creating cashflow', error);
      },
      awaitRefetchQueries: true,
      refetchQueries: 'active',
    });

  const [updateCashflow, { loading: isUpdatingCashflow }] =
    useUpdateCashflowMutation({
      onError: (error) => {
        showFeedback(
          'Could not update the cash flow.  Please try again later.'
        );
        reportError(
          `Caught error while updating cashflow ${cashflowId}`,
          error
        );
      },
      awaitRefetchQueries: true,
      refetchQueries: 'active',
    });

  const [deleteCashflow, { loading: isDeletingCashflow }] =
    useDeleteCashflowMutation({
      onError: (error) => {
        showFeedback(
          'Could not delete the cash flow.  Please try again later.'
        );
        reportError(
          `Caught error while deleting cashflow ${cashflowId}`,
          error
        );
      },
      awaitRefetchQueries: true,
      refetchQueries: 'active',
    });

  const onSubmit = handleSubmit(async (formData): Promise<void> => {
    if (cashflowId) {
      let payload: AugmentedUpdateCashflowInput;
      try {
        payload = mapFormDataToUpdatePayload(formData, cashflowId);
      } catch (error) {
        showFeedback(
          'Could not update the cash flow.  Please try again later.'
        );
        reportError(
          `Caught error while generating update cashflow ${cashflowId} payload`,
          error as Error
        );
        return;
      }

      const { data } = await updateCashflow({ variables: { input: payload } });

      if (!data?.updateCashflow.id) {
        // warn when the call succeeds but no data is returned
        diagnostics.warn(
          `No data returned for update cash flow ID [${cashflowId}]`,
          { payload }
        );
      }

      showFeedback('Cash flow updated successfully.', { variant: 'success' });
      reset({ ...CashflowModalDefaultValues });
      onClose();
    } else {
      let payload: AugmentedCreateCashflowInput;

      try {
        payload = mapFormDataToCreatePayload(formData, householdId);
      } catch (error) {
        showFeedback(
          'Could not create the cash flow.  Please try again later.'
        );
        reportError(
          'Caught error while generating create cashflow payload',
          error as Error
        );
        return;
      }
      const { data } = await createCashflow({ variables: { input: payload } });

      trackUserEvent('cash_flow created');

      if (!data?.createCashflow.id) {
        // warn when the call succeeds but no data is returned
        diagnostics.warn('No data returned for create cash flow', {
          payload,
        });
      }

      showFeedback('Cash flow created successfully.', { variant: 'success' });
      reset({ ...CashflowModalDefaultValues });
      onClose();
    }
  });

  const onCancel = useCallback(() => {
    onClose();
    reset({ ...CashflowModalDefaultValues });
  }, [onClose, reset]);

  const onDelete = useCallback(async () => {
    if (!cashflowId) {
      return;
    }
    await deleteCashflow({
      variables: { cashflowId },
      update: (cache) => {
        cache.evict({ id: `Cashflow:${cashflowId}` });
      },
    });
    reset({ ...CashflowModalDefaultValues });
    onClose();
  }, [cashflowId, deleteCashflow, onClose, reset]);

  const shouldBlock =
    isCreatingCashflow || isUpdatingCashflow || isDeletingCashflow;

  const actions = (
    <FormModalActions.Provider formState={formState} disabled={shouldBlock}>
      {cashflowId && (
        <FormModalActions.DeleteButton onConfirmDelete={onDelete} />
      )}
      <FormModalActions.CancelButton onClick={onCancel} />
      <FormModalActions.SaveButton onClick={onSubmit}>
        {!!cashflowId ? 'Save changes' : 'Add cash flow'}
      </FormModalActions.SaveButton>
    </FormModalActions.Provider>
  );

  const shouldBlockClose = shouldBlock || shouldBlockNavigation;

  return (
    <FormProvider {...formMethods}>
      <FormFieldsDisabledProvider
        isDisabled={formState.isSubmitting || loading}
      >
        <FormModal
          isOpen={isOpen}
          onClose={onClose}
          heading={`${cashflowId ? 'Edit' : 'Add'} cash flow`}
          actions={actions}
          shouldBlockClose={shouldBlockClose}
        >
          <CashflowModalBody householdId={householdId} recipient={recipient} />
        </FormModal>
      </FormFieldsDisabledProvider>
    </FormProvider>
  );
}
