import { useApolloClient } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useWatch,
} from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { DeleteButton } from '@/components/form/baseInputs/Button/DeleteButton';
import { FormConfigurationProvider } from '@/components/form/context/FormConfigurationContext';
import { Card, CardVariant } from '@/components/layout/Card/Card';
import { FormModal } from '@/components/modals/FormModal/FormModal';
import { Callout } from '@/components/notifications/Callout/Callout';
import { Loader } from '@/components/progress/Loader/Loader';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useDeleteDocumentMutation } from '@/modules/files/DocumentRepresentation/graphql/DocumentRepresentation.generated';
import { FileRepresentation } from '@/modules/files/FileRepresentation/FileRepresentation';
import {
  FileUploader,
  NewFileUpload,
} from '@/modules/files/FileUploader/FileUploader';
import { CreateFileMutation } from '@/modules/files/FileUploader/graphql/CreateFile.generated';
import { useGenerateSignedFileUploadURL } from '@/modules/files/FileUploader/useGenerateSignedFileUploadURL';
import { UploadedFileData } from '@/modules/files/FileUploader/useUploadFile';
import { useCreateDocument } from '@/modules/files/hooks/useCreateDocument';
import { useDocumentData } from '@/modules/files/hooks/useDocumentData';
import { useUpdateDocument } from '@/modules/files/hooks/useUpdateDocument';
import { DocumentType } from '@/types/schema';
import * as diagnostics from '@/utils/diagnostics';

import { DocumentUploadForm } from '../DocumentUpload.form';
import { getNewFileUploadFromUploadResult } from '../documentUploaderUtils';
import { useGetAssignableEntities } from '../hooks/useGetAssignableEntities';

interface DocumentModalForm {
  fileName: string;
  documentType: DocumentType | '';
  entityId: string;
  associatedEntityIds: string[];
  // Only show for entity types with new forms UX
  enableAiSuggestions: boolean;
  isDefaultForEntity: boolean;
}

const defaultFormValues: DocumentModalForm = {
  fileName: '',
  documentType: '' as const,
  entityId: '',
  associatedEntityIds: [],

  // Only show for entity types with new forms UX
  enableAiSuggestions: false,
  isDefaultForEntity: false,
};

function useFormWithValues(
  documentId: string | null,
  entityId: string | undefined
) {
  const { document, loading, error } = useDocumentData(documentId);
  const formMethods = useForm<DocumentModalForm>({
    defaultValues: {
      ...defaultFormValues,
      entityId: entityId || '',
    },
  });

  const { reset } = formMethods;

  useEffect(() => {
    if (document) {
      reset({
        fileName: document.name,
        documentType: document.type,
        entityId: document.entityId,
        enableAiSuggestions: document.enableAiSuggestions,
        isDefaultForEntity: document.isDefaultForEntity,
      });
    }
  }, [reset, document]);

  return {
    loading,
    error,
    formMethods,
  };
}

export interface RichDocumentUploaderProps {
  isOpen: boolean;
  householdId: string;
  // documentId of null indicates this is a creation modal; passing a valid documentId
  // indicates this is an edit modal.
  documentId: string | null;
  // if entityId is passed, we limit this modal to only uploading documents associated with this specific entity
  entityId?: string;
  // isCreateEntityScenario hides the entity association picker, becuase it will be associated with a new entity
  // that's currently being created
  hideEntityPicker?: boolean;
  onClose: (documentId?: string) => void;
  variant?: 'default' | 'card';
  cardVariant?: CardVariant;
  // additionalDetails is rendered above the form to provide more context if useful
  additionalDetails?: React.ReactNode;
}

export function RichDocumentUploader({
  isOpen,
  variant = 'default',
  cardVariant = 'inner-shadow',
  documentId,
  householdId,
  hideEntityPicker,
  entityId: exclusiveEntityId,
  additionalDetails,
  onClose,
}: RichDocumentUploaderProps) {
  const client = useApolloClient();
  const { generateUploadURL } = useGenerateSignedFileUploadURL(householdId);
  const isCreateScenario = documentId === null;
  const [documentUploadError, setDocumentUploadError] = useState('');
  const [pendingFile, setPendingFile] = useState<null | NewFileUpload>(null);
  const {
    entityOptions,
    entitiesByID,
    loading: loadingAssignableEntities,
    error: getAssignableEntitiesError,
  } = useGetAssignableEntities(householdId, {
    includeTestamentaryEntities: true,
  });

  const {
    loading: documentDataLoading,
    error: documentDataError,
    formMethods,
  } = useFormWithValues(documentId, exclusiveEntityId);

  const { handleSubmit, setValue, shouldBlockNavigation, control } =
    formMethods;

  const [
    updateDocument,
    { loading: submittingDocumentUpdate, error: documentUpdateError },
  ] = useUpdateDocument();
  const [
    createDocument,
    { loading: submittingDocumentCreation, error: documentCreationError },
  ] = useCreateDocument();
  const [
    deleteDocument,
    { loading: submittingDocumentDeletion, error: documentDeletionError },
  ] = useDeleteDocumentMutation();

  useReportError('failed to delete document', documentDeletionError);

  function handlePendingFiles(
    uploaded: UploadedFileData<CreateFileMutation>[]
  ) {
    if (isEmpty(uploaded)) {
      return;
    }

    // We're only handling one file at a time for now.
    const { file, result } = uploaded[0]!;

    setDocumentUploadError('');
    const pendingFile = getNewFileUploadFromUploadResult(file, result?.data);
    if (pendingFile === null) {
      setPendingFile(null);
      setValue('fileName', '');
    } else {
      setPendingFile({ ...pendingFile, householdId });

      // we only want to overwrite the filename if we're creating a new document, not if
      // we're replacing an existing document
      if (isCreateScenario) {
        setValue('fileName', pendingFile.fileName);
      }
    }
  }

  const handleClose = useCallback(
    (documentId?: string) => {
      onClose(documentId);
    },
    [onClose]
  );

  const handleInvalidSubmit: SubmitErrorHandler<DocumentModalForm> = () => {
    // the inputs show error state automatically, but we need to handle the file input
    // requiredness manually here
    if (isCreateScenario && pendingFile === null) {
      setDocumentUploadError('You must upload a document');
    }
  };

  const handleDeletion = useCallback(async () => {
    if (!documentId) {
      throw new Error("can't delete a document in a creation scenario");
    }
    try {
      await deleteDocument({
        variables: {
          documentId,
        },
        onCompleted: async () => {
          await client.refetchQueries({
            updateCache(cache) {
              cache.evict({ fieldName: 'documents' });
              cache.gc();
            },
          });

          onClose();
        },
      });
    } catch (err) {
      diagnostics.error('failed to delete document', err as Error, {
        documentId,
      });
    }
  }, [client, deleteDocument, documentId, onClose]);

  const handleValidSubmit: SubmitHandler<DocumentModalForm> = async (
    values: DocumentModalForm
  ) => {
    if (isCreateScenario) {
      if (pendingFile === null) {
        setDocumentUploadError('You must upload a document');
        return;
      }

      // NOTE: this hook has it's own onComplete hook
      const documentId = await createDocument({
        householdId,
        fileId: pendingFile.fileId,
        fileName: values.fileName,
        // asserting here because we're inside handleValidSubmit, so we know this is a valid DocumentType
        documentType: values.documentType as DocumentType,
        entityId: values.entityId ? values.entityId : undefined,
        enableAiSuggestions: values.enableAiSuggestions,
        isDefaultForEntity: values.isDefaultForEntity,
        associatedEntityIds: values.associatedEntityIds,
      });

      handleClose(documentId);
    } else {
      await updateDocument({
        documentId,
        fileName: values.fileName,
        documentType: values.documentType as DocumentType,
        fileId: pendingFile?.fileId ?? undefined,
        entityId: values.entityId ? values.entityId : undefined,
        enableAiSuggestions: values.enableAiSuggestions,
        isDefaultForEntity: values.isDefaultForEntity,
        associatedEntityIds: values.associatedEntityIds,
      });

      handleClose();
    }
  };

  const onSubmit = handleSubmit(handleValidSubmit, handleInvalidSubmit);
  const headerText = isCreateScenario ? 'Upload document' : 'Edit document';
  const disableInputsForLoading =
    submittingDocumentUpdate ||
    submittingDocumentCreation ||
    submittingDocumentDeletion;
  const documentType = useWatch({ control, name: 'documentType' });

  const fileUploaderElement = pendingFile ? (
    <FileRepresentation
      {...pendingFile}
      uploadedBy={pendingFile.uploadedByName}
      documentType={documentType || undefined}
      actions={
        <Button
          size="sm"
          variant="destructive"
          disabled={disableInputsForLoading}
          onClick={() => handlePendingFiles([{ file: null, result: null }])}
        >
          Remove
        </Button>
      }
    />
  ) : (
    <FileUploader
      generateSignedUploadURL={generateUploadURL}
      onUploadComplete={handlePendingFiles}
      errorMessage={documentUploadError}
    />
  );

  const onSubmitErrorMessage = (() => {
    if (documentUpdateError)
      return 'There was an error updating the document. Please try again.';
    if (documentCreationError)
      return 'There was an error creating the document. Please try again.';
    if (documentDeletionError)
      return 'There was an error deleting the document. Please try again.';
    return null;
  })();

  const formBody = (() => {
    if (documentDataLoading) {
      return (
        <Loader
          boxProps={{
            sx: {
              textAlign: 'center',
              my: 3,
            },
          }}
        />
      );
    }
    if (documentDataError) {
      return (
        <Callout severity="error">
          We failed to get the data to edit this document. Please close this
          modal and try again.
        </Callout>
      );
    }

    return (
      <DocumentUploadForm
        isCreateScenario={isCreateScenario}
        hideEntityPicker={hideEntityPicker}
        entities={entityOptions}
        fileUploaderElement={fileUploaderElement}
        getAssignableEntitiesError={getAssignableEntitiesError}
        entityId={exclusiveEntityId}
        loadingAssignableEntities={loadingAssignableEntities}
        onSubmitErrorMessage={onSubmitErrorMessage}
        entitiesByID={entitiesByID}
      />
    );
  })();

  const actions = useMemo(() => {
    return (
      <Stack
        px={1.5}
        direction="row"
        justifyContent="space-between"
        width="100%"
      >
        <Box>
          {!isCreateScenario && (
            <DeleteButton onConfirmDelete={handleDeletion} />
          )}
        </Box>
        <Stack direction="row" gap={1}>
          <Button
            size="sm"
            variant="secondary"
            disabled={disableInputsForLoading}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            loading={disableInputsForLoading}
            size="sm"
            variant="primary"
            onClick={onSubmit}
          >
            Save document
          </Button>
        </Stack>
      </Stack>
    );
  }, [
    disableInputsForLoading,
    handleClose,
    handleDeletion,
    isCreateScenario,
    onSubmit,
  ]);

  const body = useMemo(() => {
    return (
      <Stack
        spacing={2}
        component="form"
        minHeight={variant === 'default' ? 500 : 'unset'}
        onSubmit={onSubmit}
      >
        <FormProvider {...formMethods}>{formBody}</FormProvider>
      </Stack>
    );
  }, [formMethods, formBody, onSubmit, variant]);

  return (
    <FormConfigurationProvider
      value={{ optionalDisplayType: 'required-asterisk' }}
    >
      {variant === 'default' && (
        <FormModal
          onClose={() => {
            onClose();
          }}
          heading={headerText}
          isOpen={isOpen}
          actions={actions}
          shouldBlockClose={shouldBlockNavigation}
        >
          {additionalDetails && <Box mb={2}>{additionalDetails}</Box>}
          {body}
        </FormModal>
      )}
      {variant === 'card' && (
        <Card
          variant={cardVariant}
          sx={{
            p: 3,
            mb: 3,
          }}
        >
          <Stack spacing={2}>
            {body}
            {actions}
          </Stack>
        </Card>
      )}
    </FormConfigurationProvider>
  );
}
