import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { GratDesignerStages } from '@/navigation/constants';

export interface DesignerStagePickerProps {
  currentStage: GratDesignerStages;
  disabled?: boolean;
  onChangeStage: (newStage: GratDesignerStages) => void;
}

const DESIGNER_STAGE_OPTIONS: Record<GratDesignerStages, string> = {
  [GratDesignerStages.BASIC_INFORMATION]: 'Basic information',
  [GratDesignerStages.STRUCTURING]: 'GRAT structuring',
} as const;

export function DesignerStagePicker({
  currentStage,
  onChangeStage,
  disabled,
}: DesignerStagePickerProps) {
  return (
    <ButtonWithPopover
      variant="secondary"
      label={DESIGNER_STAGE_OPTIONS[currentStage]}
      size="sm"
      disabled={disabled}
    >
      {[
        GratDesignerStages.BASIC_INFORMATION,
        GratDesignerStages.STRUCTURING,
      ].map((stage) => {
        return (
          <MenuItem
            label={DESIGNER_STAGE_OPTIONS[stage]}
            key={stage}
            onClick={() => onChangeStage(stage)}
          />
        );
      })}
    </ButtonWithPopover>
  );
}
