import { useNavigate } from 'react-router-dom';

import { Divider } from '@/components/Divider';
import { ContextMenuButton } from '@/components/form/baseInputs/Button/ContextMenuButton';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { DeleteMenuItemWithConfirmationModal } from '@/components/poppers/MenuPopper/DeleteMenuItemWithConfirmationModal';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { $downloadFileFromURL } from '@/modules/files/utils/fileUtils';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { useDownloadClientPresentation } from '../../hooks/useDownloadClientPresentation';
import { useDeleteClientPresentationMutation } from '../graphql/ClientPresentationDesigner.generated';

interface ClientPresentationDesignerActionsProps {
  householdId: string;
  presentationId: string;
}

export function ClientPresentationDesignerActions({
  householdId,
  presentationId,
}: ClientPresentationDesignerActionsProps) {
  const trackUserEvent = useTrackUserEvent();
  const { showFeedback } = useFeedback();
  const navigate = useNavigate();
  const [printDocument, { loading }] = useDownloadClientPresentation(
    householdId,
    presentationId
  );
  const [deletePresentation, { loading: deleteLoading }] =
    useDeleteClientPresentationMutation({
      variables: {
        householdId,
        presentationId,
      },
      onCompleted: () => {
        showFeedback('Presentation deleted successfully', {
          variant: 'success',
        });
        navigate(
          getCompletePathFromRouteKey(
            ROUTE_KEYS.HOUSEHOLD_DETAILS_PRESENTATIONS,
            {
              householdId,
            }
          )
        );
      },
      onError: () => {
        showFeedback('Failed to delete presentation');
      },
    });

  const handlePrintAndDownloadDocument = () => {
    void printDocument({
      onCompleted: (data) => {
        const downloadUrl = data.print.download.downloadURL;
        try {
          void $downloadFileFromURL(downloadUrl, 'Presentation');
          trackUserEvent('presentation exported', {
            presentationId,
            householdId,
            source: 'presentation_designer',
          });
          showFeedback('PDF generated successfully', { variant: 'success' });
        } catch (err) {
          showFeedback('Failed to download PDF. Please try again.');
        }
      },
    });
  };

  return (
    <ContextMenuButton size="md">
      <MenuItem
        label="Download PDF"
        loading={loading}
        onClick={handlePrintAndDownloadDocument}
      />
      <Divider noMargin />
      <DeleteMenuItemWithConfirmationModal
        label="Delete presentation"
        menuItemProps={{ loading: deleteLoading }}
        modalProps={{
          heading: 'Are you sure you want to delete this presentation?',
        }}
        onConfirmDelete={() => deletePresentation()}
      />
    </ContextMenuButton>
  );
}
