import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { Box, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

import { COLORS } from '@/styles/tokens/colors';
import { diagnostics } from '@/utils/diagnostics';

import { TextEditorContext } from './contexts/textEditor.context';

const BORDER_RADIUS = '12px';

export function TextEditorWrapper({ children }: PropsWithChildren) {
  return (
    <Stack
      color={COLORS.PRIMITIVES.BLACK}
      width="100%"
      lineHeight={1.5}
      textAlign="left"
      fontWeight={400}
      height="100%"
      overflow="hidden"
      border={`1px solid ${COLORS.GRAY[200]}`}
      borderRadius="6px"
    >
      {children}
    </Stack>
  );
}

export function EditorInner({ children }: PropsWithChildren) {
  return (
    <Box
      sx={{
        background: COLORS.PRIMITIVES.WHITE,
        borderRadius: BORDER_RADIUS,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {children}
    </Box>
  );
}

interface TextEditorContentEditableProps {
  themeVariant: TextEditorContext['theme'];
}

export const CONTENT_EDITABLE_ID = 'text-editor-content-editable';

export function getEditorElement() {
  const maybeEditors = document.querySelectorAll(`#${CONTENT_EDITABLE_ID}`);

  if (maybeEditors.length === 0) {
    diagnostics.error('Could not find editor element');
  }

  if (maybeEditors.length > 1) {
    diagnostics.error('Found multiple editor elements');
  }

  return maybeEditors[0];
}

export function TextEditorContentEditable({
  themeVariant,
}: TextEditorContentEditableProps) {
  if (themeVariant === 'contextContent') {
    return (
      <ContentEditable
        style={{
          resize: 'none',
          fontSize: '15px',
          position: 'relative',
          tabSize: 1,
          outline: 0,
          padding: 0,
        }}
      />
    );
  }

  return (
    <ContentEditable
      id={CONTENT_EDITABLE_ID}
      style={{
        minHeight: '150px',
        resize: 'none',
        fontSize: '15px',
        position: 'relative',
        tabSize: 1,
        outline: 0,
        padding: '15px 10px',
      }}
    />
  );
}

export const EMPTY_EDITOR_JSON = JSON.stringify({
  root: {
    children: [
      {
        children: [],
        direction: null,
        format: '',
        indent: 0,
        type: 'paragraph',
        version: 1,
      },
    ],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  },
});
