function stringify(params: OrionQueryParams): string {
  return new URLSearchParams(params).toString();
}

export type OrionQueryParams = {
  redirect: string;
} & Record<string, string>;

export const getOrionIntegrationLoginPath = (
  queryParams: OrionQueryParams
): string => `/api/v1/orion/auth/initiate?${stringify(queryParams)}`;

export const getOrionIntegrationLogoutPath = (
  queryParams: OrionQueryParams
): string => `/api/v1/orion/auth/logout?${stringify(queryParams)}`;
