import { Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Button } from '@/components/form/baseInputs/Button';
import { HeaderCard } from '@/components/layout/HeaderCard';
import { Alert } from '@/components/notifications/Alert/Alert';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';

import { useBootstrapWaterfallMutation } from './graphql/BootstrapWaterfall.generated';

export function BootstrapWaterfallButton() {
  const [loadingWaterfallType, setLoadingWaterfallType] = useState<
    'simple' | 'complex' | null
  >(null);
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [waterfallLink, setWaterfallLink] = useState<string | null>(null);

  const [generateWaterfall, { loading }] = useBootstrapWaterfallMutation({
    onError: (err) => {
      showFeedback('Failed to bootstrap waterfall');
      reportError('could not bootstrap waterfall', err);
    },
    onCompleted: (data) => {
      setLoadingWaterfallType(null);
      const { householdId, waterfallId } = (() => {
        if (data.seedEstateWaterfallSimpleTrustWithBequests) {
          const householdId =
            data.seedEstateWaterfallSimpleTrustWithBequests.id;
          const waterfallId =
            data.seedEstateWaterfallSimpleTrustWithBequests
              .estateWaterfalls?.[0]?.id;

          return { householdId, waterfallId };
        }

        if (data.seedEstateWaterfall) {
          const householdId = data.seedEstateWaterfall.id;
          const waterfallId =
            data.seedEstateWaterfall.estateWaterfalls?.[0]?.id;
          return { householdId, waterfallId };
        }

        throw new Error('Invalid waterfall data');
      })();

      if (!waterfallId) {
        showFeedback('Failed to bootstrap waterfall');
        reportError('could not bootstrap waterfall');
        return;
      }

      const link = getCompletePathFromRouteKey(
        ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_TAB_SUMMARY,
        {
          householdId,
          waterfallId,
        }
      );
      setWaterfallLink(link);
    },
  });

  const handleClick = useCallback(
    async (type: 'simple' | 'complex') => {
      setLoadingWaterfallType(type);
      switch (type) {
        case 'simple':
          return generateWaterfall({
            variables: {
              isComplex: false,
              isSimple: true,
            },
          });
        case 'complex':
          return generateWaterfall({
            variables: {
              isComplex: true,
              isSimple: false,
            },
          });
      }
    },
    [generateWaterfall]
  );

  return (
    <HeaderCard heading="Generate waterfall">
      <Stack p={3} spacing={2}>
        <Button
          size="md"
          variant="primary"
          onClick={() => handleClick('complex')}
          loading={loading && loadingWaterfallType === 'complex'}
        >
          Generate complex waterfall
        </Button>
        <Button
          size="md"
          variant="primary"
          onClick={() => handleClick('simple')}
          loading={loading && loadingWaterfallType === 'simple'}
        >
          Generate simple waterfall
        </Button>
        {waterfallLink && (
          <Alert size="sm" severity="success">
            <NavLink to={waterfallLink}>
              <Typography variant="body1">
                Go to the newly-created waterfall {'→'}
              </Typography>
            </NavLink>
          </Alert>
        )}
      </Stack>
    </HeaderCard>
  );
}
