import { useParams } from 'react-router-dom';

import { EntityDetailsAwareRoute } from '@/modules/entities/contexts/entityDetails/EntityDetailsAwareRoute';
import { NEW_ENTITY_ID } from '@/modules/entities/entities.constants';
import { GratDesignerStages } from '@/navigation/constants';

import { GRATDesigner } from './GRATDesigner/GRATDesigner';
import { TAB_NAMES } from './GRATDesigner/StructuringDesigner/constants';

function isValidDesignerStage(
  possibleDesignerStage?: string
): possibleDesignerStage is GratDesignerStages {
  // this is a hack, but we have to cast possibleStrategyType as StrategyType
  // for the lookup on the StrategyTypes object to function the way that we want.
  // ultimately, this function is responsible for guaranteeing that a string is, in fact,
  // a valid strategy type
  if (!possibleDesignerStage) return false;
  return Object.values(GratDesignerStages).includes(
    possibleDesignerStage as GratDesignerStages
  );
}

export function DesignerPageV2() {
  const {
    entityType: _possibleEntityType,
    entityId,
    householdId,
    designerStage: possibleDesignerStage,
  } = useParams<{
    entityType: string;
    entityId: string;
    householdId: string;
    designerStage: string;
  }>();

  if (!isValidDesignerStage(possibleDesignerStage)) {
    throw new Error(`Unknown design stage: ${possibleDesignerStage}`);
  }
  // reassigning because we're now confident that it's a design stage
  const designerStage = possibleDesignerStage;

  // TODO: this should be either a UUID or the string "new" -- nothing else is valid.
  if (!entityId) {
    throw new Error(
      `Unrecognized entityId is not a uuid or "${NEW_ENTITY_ID}".`
    );
  }

  if (!householdId) {
    throw new Error(`No householdId provided.`);
  }

  return (
    <EntityDetailsAwareRoute entityId={entityId}>
      <GRATDesigner
        initialStructuringTab={TAB_NAMES.ASSETS}
        designerStage={designerStage}
        entityId={entityId}
        householdId={householdId}
      />
    </EntityDetailsAwareRoute>
  );
}
