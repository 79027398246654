import { useEffect, useMemo, useState } from 'react';

import { TextEditor } from '../TextEditor/TextEditor';

interface LexicalRendererProps {
  lexicalJson?: string;
  lexicalHtml?: string;
}

// Helper component to render lexical JSON or HTML in a read-only text editor
export function LexicalRenderer({
  lexicalJson,
  lexicalHtml,
}: LexicalRendererProps) {
  // get unique key when content changes
  const [key, setKey] = useState(0);

  // update key when lexicalJson changes
  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [lexicalJson, lexicalHtml]);

  const { initialValue, initialValueKind } = useMemo(() => {
    if (lexicalJson) {
      return { initialValue: lexicalJson, initialValueKind: 'json' as const };
    }

    if (lexicalHtml) {
      return { initialValue: lexicalHtml, initialValueKind: 'html' as const };
    }

    throw new Error('Invalid lexical content');
  }, [lexicalHtml, lexicalJson]);

  return (
    <TextEditor
      key={key}
      initialValue={initialValue}
      initialValueKind={initialValueKind}
      readOnly
    />
  );
}
