import { ApolloClient } from '@apollo/client';

import { grantorsFieldTrustDataToForm } from '@/modules/entities/principals/GrantorsField/GrantorsField.utils';
import { getEntityTypeFromSubtype } from '@/modules/entities/utils/getEntityTypeFromSubtype';
import * as diagnostics from '@/utils/diagnostics';

import {
  TrustDetailsDocument,
  TrustDetailsQuery,
} from './graphql/TrustDetails.generated';
import {
  TrustDetailsSubformProps,
  TrustDetailsSubformType,
  TrustDetailsSubformType_TrusteesAndSuccessorTrustees,
} from './TrustDetailsSubform.types';

function dataToForm(data: TrustDetailsQuery): TrustDetailsSubformType {
  if (data.node?.__typename !== 'Entity') {
    throw new Error('Unexpected node type');
  }

  // This form only supports trust entities, where the subtype has trustees field.
  if (!('trustees' in data.node.subtype)) {
    throw new Error(
      `Unexpected entity type without trustees ${data.node.subtype.__typename}`
    );
  }

  if (!data.node.subtype.__typename) {
    throw new Error(`Unknown entity subtype`);
  }

  const entityType = getEntityTypeFromSubtype(data.node.subtype.__typename);

  const trustees =
    data.node.subtype.trustees?.map((trustee) => {
      return {
        trusteeId: (trustee?.individual?.id || trustee?.organization?.id) ?? '',
        duties: trustee?.duties?.map((d) => d.kind) ?? [],
        note: trustee?.notes ?? '',
      };
    }) ?? [];

  const successorTrustees =
    data.node.subtype.successorTrustees?.map((trustee) => {
      return {
        successorTrusteeId:
          (trustee?.individual?.id || trustee?.organization?.id) ?? '',
        duties: trustee?.duties?.map((d) => d.kind) ?? [],
        level: trustee?.level ?? ('' as const),
        note: trustee?.notes ?? '',
      };
    }) ?? [];

  const trustAdvisors =
    data.node.subtype.trustAdvisors?.map((advisor) => {
      return {
        trustAdvisorId:
          (advisor?.individual?.id || advisor?.organization?.id) ?? '',
        level: advisor.level ?? ('' as const),
        role: advisor.roles?.map((r) => r.kind) ?? [],
        note: advisor.note ?? '',
      };
    }) ?? [];

  const grantorsFieldFormValues = grantorsFieldTrustDataToForm(
    entityType,
    data.node.subtype
  );

  return {
    trustees,
    successorTrustees,
    trustAdvisors,
    ...grantorsFieldFormValues,
  };
}

export async function trustDetailsDataFetcher(
  apolloClient: ApolloClient<object>,
  nodeId: string
): Promise<undefined | TrustDetailsSubformType> {
  const { data, error } = await apolloClient.query<TrustDetailsQuery | null>({
    query: TrustDetailsDocument,
    variables: {
      nodeId: nodeId,
    },
  });

  if (error) {
    diagnostics.error('Could not fetch data for trust details form', error);

    throw error;
  }

  if (!data) {
    const message = 'No data for trust details form';
    const err = new Error(message);
    diagnostics.error(message, err, {
      nodeId,
    });
    return;
  }

  return dataToForm(data);
}

export function hasTrustAdvisors(
  subformValues: TrustDetailsSubformProps['subformValues']
): subformValues is TrustDetailsSubformType {
  return (
    (subformValues &&
      'trustAdvisors' in subformValues &&
      Array.isArray(subformValues.trustAdvisors)) ??
    false
  );
}

export function hasTrustees(
  subformValues: TrustDetailsSubformProps['subformValues']
): subformValues is
  | TrustDetailsSubformType
  | TrustDetailsSubformType_TrusteesAndSuccessorTrustees {
  return (
    (subformValues &&
      'trustees' in subformValues &&
      Array.isArray(subformValues.trustees)) ??
    false
  );
}

export function hasSuccessorTrustees(
  subformValues: TrustDetailsSubformProps['subformValues']
): subformValues is
  | TrustDetailsSubformType
  | TrustDetailsSubformType_TrusteesAndSuccessorTrustees {
  return (
    (subformValues &&
      'successorTrustees' in subformValues &&
      Array.isArray(subformValues.successorTrustees)) ??
    false
  );
}
