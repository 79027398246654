import { Branding_BrandedFragment } from '@/modules/admin/branding/graphql/Branding.generated';
import { AugmentedCreateSubBrandInput } from '@/types/schema';

import { SubBrandFormShape } from './SubBrandModal.constants';

export function mapFormDataToInput(
  formValues: SubBrandFormShape,
  branding: Branding_BrandedFragment
): AugmentedCreateSubBrandInput {
  // this all uses the "create" paradigm, but internally to the endpoint
  // we will skip user creation if an email of an existing user already matches
  const {
    lightWideLogoURL: logoURL,
    id: _id,
    ...otherBrandingProps
  } = branding;
  return {
    create: {
      ...otherBrandingProps,
      logoURL,
      displayName: formValues.displayName,
    },
  };
}
