import { Box, Stack, Typography } from '@mui/material';
import { uniq } from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { OversizedMetricItem } from '@/components/display/OversizedMetricItem/OversizedMetricItem';
import { FormAwareDatePickerInput } from '@/components/form/formAwareInputs/FormAwareDatePickerInput';
import { Card } from '@/components/layout/Card/Card';
import { RibbonCard } from '@/components/layout/RibbonCard';
import { useFormContext } from '@/components/react-hook-form';
import { DocumentUploaderWithList } from '@/modules/documents/DocumentUploaderWithList/DocumentUploaderWithList';
import { COLORS } from '@/styles/tokens/colors';
import { DocumentType } from '@/types/schema';
import { diagnostics } from '@/utils/diagnostics';

import { ILITSendCrummeyLetterTaskContext } from './ILITSendCrummeyLetterTask';
import { ILITSendCrummeyLetterTaskForm } from './ILITSendCrummeyLetterTask.types';

export function ILITSendCrummeyLetterTaskBody() {
  const { householdId, entityId } = useContext(
    ILITSendCrummeyLetterTaskContext
  );
  const { control, getValues, setValue } =
    useFormContext<ILITSendCrummeyLetterTaskForm>();

  const formDocumentIds = getValues('documentIds');
  const withdrawalPeriodDays = getValues('withdrawalPeriodDays');

  const [documentIds, setDocumentIds] = useState<string[]>(formDocumentIds);
  const [showDocumentUploadError, setShowDocumentUploadError] =
    useState<boolean>(false);

  useEffect(() => {
    if (!formDocumentIds) return;
    setDocumentIds(formDocumentIds);
  }, [formDocumentIds]);

  return (
    <RibbonCard variant="default" heading="Upload copy of Crummey letter">
      <Stack direction="column" spacing={6}>
        <Card variant="filled-callout" sx={{ p: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            <OversizedMetricItem
              title={<Typography variant="label">Withdrawal period</Typography>}
              value={`${withdrawalPeriodDays} days`}
              valueColor={COLORS.NAVY[600]}
            />
            <FormAwareDatePickerInput<ILITSendCrummeyLetterTaskForm>
              control={control}
              fieldName={'letterDate'}
              label={'Date letter was sent'}
              required
            />
          </Stack>
        </Card>
        <Box
          sx={{
            px: 10,
          }}
        >
          <DocumentUploaderWithList
            uploaderLabel={''}
            documentIds={documentIds}
            householdId={householdId}
            getCreateDocumentInput={(file) => ({
              householdID: householdId,
              name: file.fileName,
              entityID: entityId,
              type: DocumentType.CrummeyLetter,
              fileID: file.fileId,
            })}
            onDocumentUploadSuccess={({ documentId }) => {
              const uniqueDocumentIds = uniq([...documentIds, documentId]);
              setValue('documentIds', uniqueDocumentIds);
              setDocumentIds(uniqueDocumentIds);
              setShowDocumentUploadError(false);
            }}
            errorMessage={
              showDocumentUploadError
                ? 'Could not upload the document'
                : undefined
            }
            onDocumentUploadError={(error) => {
              diagnostics.error(
                'Caught error when uploading ILIT document',
                error
              );
              setShowDocumentUploadError(true);
            }}
          />
        </Box>
      </Stack>
    </RibbonCard>
  );
}
