import { map } from 'lodash';

import { STATE_CODE_TO_NAME } from '@/constants/states';

const STATE_CODE_OPTIONS = map(STATE_CODE_TO_NAME, (stateName, stateCode) => ({
  value: stateCode,
  display: `${stateCode} - ${stateName}`,
}));

export const stateOptions = STATE_CODE_OPTIONS;
