import { AugmentedRoute, RouteKey } from './constants';

export function computeRoutes(routes: AugmentedRoute[]) {
  const res = {};
  computeChildRoutes(res, routes, '');
  return res as Record<RouteKey, string>;
}

function computeChildRoutes(
  acc: Record<string, string>,
  routes: AugmentedRoute[],
  currentPath: string
): void {
  routes.forEach((route) => {
    // certain routes don't have path definitions on them because they're just layout or logical
    // containers for their child routes. we don't care about exposing those on the computed route
    // object because people won't be navigating to them explicitly.
    let routePath = currentPath;
    if (typeof route.path !== 'undefined') {
      routePath = `${currentPath}/${route.path}`;
      acc[route.key] = routePath;
    }

    if (route.children) {
      computeChildRoutes(acc, route.children, routePath);
    }
  });
}
