import { Box, Typography } from '@mui/material';
import { isUndefined } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { FieldValues, useWatch } from 'react-hook-form';
import { usePrevious } from 'react-use';

import { FormAwareMultiSelectInput } from '@/components/form/formAwareInputs/FormAwareMultiSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormAwareTextInput } from '@/components/form/formAwareInputs/FormAwareTextInput';
import { FormAwareTypeaheadSelectInput } from '@/components/form/formAwareInputs/FormAwareTypeaheadSelectInput';
import { Dataflow01Icon } from '@/components/icons/Dataflow01Icon';
import { File06Icon } from '@/components/icons/File06Icon';
import { Callout } from '@/components/notifications/Callout/Callout';
import { PopperContent } from '@/components/poppers/PopperContent';
import { useFormContext } from '@/components/react-hook-form';
import { useShowAISuggestionsToggle } from '@/modules/documents/hooks/useShowAISuggestionsToggle';
import { COLORS } from '@/styles/tokens/colors';
import { DocumentType } from '@/types/schema';
import { PathsOf } from '@/types/subform';
import { getNodes } from '@/utils/graphqlUtils';

import { useGetEntityDetailsQuery } from '../entities/contexts/entityDetails/graphql/GetEntityDetails.generated';
import { useAICapabilitiesEnabled } from '../tenant/TenantDetailsContext/hooks/useAICapabilitiesEnabled';
import {
  ADDITIONAL_DOCUMENTS_POPPER_CONTENT,
  AI_ENABLED_FOR_DOCUMENT_HELP_TEXT,
  DOCUMENT_TYPE_OPTIONS,
} from './documents.constants';
import { SUGGESTION_DOCUMENT_TYPES } from './documents.constants';
import { documentTypeIsRestricted } from './documents.utils';
import { useGetAssignableEntities } from './hooks/useGetAssignableEntities';

interface Props {
  isCreateScenario: boolean;
  hideEntityPicker?: boolean;
  entities?: ReturnType<typeof useGetAssignableEntities>['entityOptions'];
  fileUploaderElement?: JSX.Element;
  getAssignableEntitiesError?: ReturnType<
    typeof useGetAssignableEntities
  >['error'];
  entityId?: string;
  loadingAssignableEntities?: ReturnType<
    typeof useGetAssignableEntities
  >['loading'];
  onSubmitErrorMessage: string | null;
  entitiesByID?: ReturnType<typeof useGetAssignableEntities>['entitiesByID'];
}

interface LocalFormShape extends FieldValues {
  fileName: string;
  documentType: DocumentType;
  entityId: string;
  associatedEntityIds: string[];

  // Only show for entity types with new forms UX
  enableAiSuggestions: boolean;
  isDefaultForEntity: boolean;
}

type LocalFormShapePaths = PathsOf<LocalFormShape>;

export function DocumentUploadForm({
  isCreateScenario,
  hideEntityPicker,
  entities,
  fileUploaderElement,
  getAssignableEntitiesError,
  entityId: exclusiveEntityId,
  loadingAssignableEntities,
  onSubmitErrorMessage,
  entitiesByID = {},
}: Props) {
  const aiCapabilitiesEnabled = useAICapabilitiesEnabled();
  const { control, watch, formState, setValue } =
    useFormContext<LocalFormShape>();

  const [initialIsDefault, setInitialIsDefault] = useState(
    formState.defaultValues?.isDefaultForEntity || false
  );

  const [selectedEntityId, documentType] = useWatch({
    control,
    name: ['entityId', 'documentType'],
  });
  const previousSelectedEntityId = usePrevious(selectedEntityId);
  const previousDocumentType = usePrevious(documentType);

  // Set the initial state of the default switch to true if it has previously been set or
  // if this is the first document uploaded for the entity
  useGetEntityDetailsQuery({
    variables: {
      entityId: selectedEntityId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const entities = getNodes(data.entities);
      if (entities.length !== 1) return;
      const entity = entities[0];
      const documents = getNodes(entity!.documents);
      setInitialIsDefault(
        formState.defaultValues?.isDefaultForEntity || documents.length === 0
      );
    },
  });

  const showAISuggestionsToggle = useShowAISuggestionsToggle(
    selectedEntityId,
    entitiesByID
  );

  useEffect(() => {
    setValue('isDefaultForEntity', initialIsDefault);
  }, [selectedEntityId, initialIsDefault, setValue]);

  const enableAiSuggestionsDirty = useMemo(
    () => formState.dirtyFields.enableAiSuggestions,
    [formState]
  );

  useEffect(() => {
    // if AI turned off for tenant, don't update the switch
    if (!aiCapabilitiesEnabled) return;

    if (enableAiSuggestionsDirty) return;

    // if first render, don't update the switch
    if (
      isUndefined(previousSelectedEntityId) ||
      isUndefined(previousDocumentType)
    ) {
      return;
    }

    // if there's no primary entity selected, don't update the switch
    if (!selectedEntityId) return;

    // if the entity hasn't changed, and it's not exclusive to an entity, don't update the switch
    if (previousSelectedEntityId === selectedEntityId && !exclusiveEntityId)
      return;

    // if the entity changes or document type changes and the new doc type is in the AI-enabled list, turn on the switch automatically
    if (
      previousSelectedEntityId !== selectedEntityId ||
      previousDocumentType !== documentType ||
      SUGGESTION_DOCUMENT_TYPES.includes(documentType)
    ) {
      setValue('enableAiSuggestions', true, { shouldDirty: false });
    }
  }, [
    aiCapabilitiesEnabled,
    documentType,
    enableAiSuggestionsDirty,
    exclusiveEntityId,
    initialIsDefault,
    previousDocumentType,
    previousSelectedEntityId,
    selectedEntityId,
    setValue,
  ]);

  const associatedEntitiesOptions = useMemo(
    () => entities?.filter((entity) => entity.value !== selectedEntityId) || [],
    [entities, selectedEntityId]
  );

  return (
    <>
      {isCreateScenario && fileUploaderElement}
      <FormAwareTextInput<LocalFormShape>
        control={control}
        label="File name"
        required
        fieldName={'fileName' as const satisfies LocalFormShapePaths}
      />

      <FormAwareTypeaheadSelectInput<LocalFormShape>
        startAdornment={<File06Icon size={16} color={COLORS.GRAY[400]} />}
        options={DOCUMENT_TYPE_OPTIONS}
        disabled={documentTypeIsRestricted(watch('documentType'))}
        control={control}
        placeholder="Search document types..."
        label="Type"
        required
        fieldName={'documentType' as const satisfies LocalFormShapePaths}
      />
      {!isCreateScenario && (
        <Box>
          <Typography variant="h4">Replace existing file</Typography>
          {fileUploaderElement}
        </Box>
      )}
      {!hideEntityPicker && entities && (
        <>
          <FormAwareTypeaheadSelectInput<LocalFormShape>
            startAdornment={
              <Dataflow01Icon size={16} color={COLORS.GRAY[400]} />
            }
            contextualHelp={
              aiCapabilitiesEnabled ? (
                <PopperContent body="Entity details will be suggested by AI for the entity specified" />
              ) : undefined
            }
            options={entities}
            placeholder="Search entities..."
            helpText={
              getAssignableEntitiesError &&
              `We weren't able to fetch entities. Please close the modal and try again.`
            }
            disabled={
              !!exclusiveEntityId ||
              !!getAssignableEntitiesError ||
              loadingAssignableEntities ||
              documentTypeIsRestricted(watch('documentType'))
            }
            control={control}
            label={
              aiCapabilitiesEnabled
                ? `Parent entity (for AI suggestions)`
                : 'Parent entity'
            }
            fieldName={'entityId' as const satisfies LocalFormShapePaths}
          />
          <Box sx={{ maxWidth: '100%' }}>
            <FormAwareMultiSelectInput<LocalFormShape>
              startAdornment={
                <Dataflow01Icon size={16} color={COLORS.GRAY[400]} />
              }
              contextualHelp={
                <PopperContent body={ADDITIONAL_DOCUMENTS_POPPER_CONTENT} />
              }
              options={associatedEntitiesOptions}
              helpText={
                getAssignableEntitiesError &&
                `We weren't able to fetch entities. Please close the modal and try again.`
              }
              disabled={
                !!getAssignableEntitiesError ||
                loadingAssignableEntities ||
                documentTypeIsRestricted(watch('documentType'))
              }
              control={control}
              label="Other entities that share this document"
              fieldName={
                'associatedEntityIds' as const satisfies LocalFormShapePaths
              }
            />
          </Box>
        </>
      )}
      {onSubmitErrorMessage && (
        <Callout severity="error">{onSubmitErrorMessage}</Callout>
      )}

      {selectedEntityId && (
        <FormAwareSwitch<LocalFormShape>
          control={control}
          label="Display as default when editing entity"
          fieldName={
            'isDefaultForEntity' as const satisfies LocalFormShapePaths
          }
          labelPosition="right"
        />
      )}

      {showAISuggestionsToggle && (
        <FormAwareSwitch<LocalFormShape>
          control={control}
          label="Enable Luminary AI suggestions"
          fieldName={
            'enableAiSuggestions' as const satisfies LocalFormShapePaths
          }
          contextualHelp={
            <PopperContent body={AI_ENABLED_FOR_DOCUMENT_HELP_TEXT} />
          }
          labelPosition="right"
        />
      )}
    </>
  );
}
