import { Skeleton } from '@mui/material';

import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { PresentationSlide } from '../PresentationSlide';

export interface PresentationSlideSecondaryHeadingProps {
  clientDisplayName: string | null;
}

export function PresentationSlideSecondaryHeading({
  clientDisplayName,
}: PresentationSlideSecondaryHeadingProps) {
  return (
    <PresentationSlide.SecondaryHeading
      lineOne={clientDisplayName ?? <Skeleton width={50} />}
      lineTwo={`As of ${formatDateToMonDDYYYY(new Date())}`}
    />
  );
}
