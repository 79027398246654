// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTestamentaryEntityMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateTestamentaryEntityInput;
}>;


export type UpdateTestamentaryEntityMutation = { __typename?: 'Mutation', updateTestamentaryEntity: { __typename?: 'TestamentaryEntity', id: string } };


export const UpdateTestamentaryEntityDocument = gql`
    mutation UpdateTestamentaryEntity($input: AugmentedUpdateTestamentaryEntityInput!) {
  updateTestamentaryEntity(input: $input) {
    id
  }
}
    `;
export type UpdateTestamentaryEntityMutationFn = Apollo.MutationFunction<UpdateTestamentaryEntityMutation, UpdateTestamentaryEntityMutationVariables>;

/**
 * __useUpdateTestamentaryEntityMutation__
 *
 * To run a mutation, you first call `useUpdateTestamentaryEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTestamentaryEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTestamentaryEntityMutation, { data, loading, error }] = useUpdateTestamentaryEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTestamentaryEntityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTestamentaryEntityMutation, UpdateTestamentaryEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTestamentaryEntityMutation, UpdateTestamentaryEntityMutationVariables>(UpdateTestamentaryEntityDocument, options);
      }
export type UpdateTestamentaryEntityMutationHookResult = ReturnType<typeof useUpdateTestamentaryEntityMutation>;
export type UpdateTestamentaryEntityMutationResult = Apollo.MutationResult<UpdateTestamentaryEntityMutation>;
export type UpdateTestamentaryEntityMutationOptions = Apollo.BaseMutationOptions<UpdateTestamentaryEntityMutation, UpdateTestamentaryEntityMutationVariables>;
export const Operations = {
  Mutation: {
    UpdateTestamentaryEntity: 'UpdateTestamentaryEntity' as const
  }
}