import {
  LiabilityPaymentFrequency,
  LiabilityPaymentKind,
} from '@/types/schema';

export const paymentKindOptions = [
  {
    label: 'Amortizing',
    value: LiabilityPaymentKind.Amortized,
  },
  {
    label: 'Interest-only with balloon',
    value: LiabilityPaymentKind.InterestOnly,
  },
];

export const paymentFrequencyOptions = [
  {
    display: 'Monthly',
    value: LiabilityPaymentFrequency.Monthly,
  },
  {
    display: 'Annually',
    value: LiabilityPaymentFrequency.Annually,
  },
  {
    display: 'Quarterly',
    value: LiabilityPaymentFrequency.Quarterly,
  },
  {
    display: 'Semiannually',
    value: LiabilityPaymentFrequency.Semiannually,
  },
];
