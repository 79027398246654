import { Control } from 'react-hook-form';

import { SelectInputProps } from '@/components/form/baseInputs/SelectInput/SelectInput';
import { AdditionalItemRendererProps } from '@/components/form/formAwareInputs/FormAwareInput.types';
import { MatchableField } from '@/modules/aiSuggestions/AISuggestionsMatcher/AISuggestionsMatcher.types';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  TrustAdvisorLevel,
  TrustAdvisorRoleKind,
  TrusteeDutyKind,
  TrusteeLevel,
} from '@/types/schema';
import { PathsOf } from '@/types/subform';

export const TRUST_DETAILS_SUBFORM_NAMESPACE = 'trustDetailsSubform' as const;
export type NamespaceType = typeof TRUST_DETAILS_SUBFORM_NAMESPACE;
const VARIANTS = [
  'default',
  'TrusteesAndSuccessorTrustees',
  'TrusteesSuccessorTrusteesAndTrustAdvisors',
] as const;
export type VariantType = (typeof VARIANTS)[number];

export interface TrustDetails_Trustee extends MatchableField {
  trusteeId: string;
  duties?: TrusteeDutyKind[];
  note?: string;
}

export interface TrustDetails_SuccessorTrustee extends MatchableField {
  successorTrusteeId: string;
  level: TrusteeLevel | '';
  duties?: TrusteeDutyKind[];
  note?: string;
}

export interface TrustDetails_TrustAdvisor extends MatchableField {
  trustAdvisorId: string;
  level: TrustAdvisorLevel | '';
  role?: TrustAdvisorRoleKind[];
  note?: string;
}

// this is the type of the subform's values
export interface TrustDetailsSubformType {
  trustees: TrustDetails_Trustee[];
  successorTrustees: TrustDetails_SuccessorTrustee[];
  trustAdvisors: TrustDetails_TrustAdvisor[];
}

// this is the type of the subform's values for the TrusteesAndSuccessorTrustees variant
export interface TrustDetailsSubformType_TrusteesAndSuccessorTrustees {
  trustees: TrustDetails_Trustee[];
  successorTrustees: TrustDetails_SuccessorTrustee[];
}

export interface TrustDetailsSubformType_TrusteesSuccessorTrusteesAndTrustAdvisors
  extends TrustDetailsSubformType_TrusteesAndSuccessorTrustees {
  trustAdvisors: TrustDetails_TrustAdvisor[];
}

// this is what the subform's values will be nested under in the parent form
export interface Fields {
  [TRUST_DETAILS_SUBFORM_NAMESPACE]:
    | TrustDetailsSubformType
    | TrustDetailsSubformType_TrusteesAndSuccessorTrustees
    | TrustDetailsSubformType_TrusteesSuccessorTrusteesAndTrustAdvisors;
}

export type SubformField = PathsOf<Fields>;

export type SubformValues =
  | TrustDetailsSubformType
  | TrustDetailsSubformType_TrusteesAndSuccessorTrustees
  | TrustDetailsSubformType_TrusteesSuccessorTrusteesAndTrustAdvisors;

// this is Props for the UI component
export interface TrustDetailsSubformProps {
  variant: VariantType;
  householdId: string;
  subformValues?: SubformValues;
  entityType?: EntityType;
  // Filter out trustees that can't be selected due to circumstances
  // defined in the parent component, e.g., if the trustee is already
  // dead
  filterClientProfileIds?: string[];
}

export const emptyTrusteeValue: TrustDetails_Trustee = {
  trusteeId: '',
  duties: [],
  note: '',
};

export const emptySuccessorTrusteeValue: TrustDetails_SuccessorTrustee = {
  successorTrusteeId: '',
  level: '',
  duties: [],
  note: '',
};

export const emptyTrustAdvisorValue: TrustDetails_TrustAdvisor = {
  trustAdvisorId: '',
  level: '',
  role: [],
  note: '',
};

export const defaultValues: TrustDetailsSubformType = {
  trustees: [emptyTrusteeValue],
  successorTrustees: [emptySuccessorTrusteeValue],
  trustAdvisors: [emptyTrustAdvisorValue],
};

export const defaultValues_TrusteesAndSuccessorTrustees: TrustDetailsSubformType_TrusteesAndSuccessorTrustees =
  {
    trustees: [emptyTrusteeValue],
    successorTrustees: [emptySuccessorTrusteeValue],
  };

export const defaultValues_TrusteesSuccessorTrusteesAndTrustAdvisors: TrustDetailsSubformType_TrusteesSuccessorTrusteesAndTrustAdvisors =
  {
    ...defaultValues_TrusteesAndSuccessorTrustees,
    trustAdvisors: [emptyTrustAdvisorValue],
  };

export const TRUST_ADVISOR_ROLE_KIND_ITEMS: {
  display: string;
  value: TrustAdvisorRoleKind;
  abbreviation?: string;
}[] = [
  {
    display: 'Investment advisor',
    value: TrustAdvisorRoleKind.InvestmentAdvisor,
    abbreviation: 'Inv. advisor',
  },
  {
    display: 'Distribution advisor',
    value: TrustAdvisorRoleKind.DistributionAdvisor,
    abbreviation: 'Dist. advisor',
  },
  {
    display: 'Trust protector',
    value: TrustAdvisorRoleKind.TrustProtector,
  },
  { display: 'Other', value: TrustAdvisorRoleKind.Other },
];

// This function returns the default values for the TrustDetailsSubform for
// the given variant. Includes the logic to set the default values for the
// grantors field (whether or not this subform is responsible for it).
export function getTrustDetailsDefaultValues(
  variant: VariantType
): SubformValues {
  if (variant === 'TrusteesAndSuccessorTrustees') {
    return defaultValues_TrusteesAndSuccessorTrustees;
  }

  if (variant === 'TrusteesSuccessorTrusteesAndTrustAdvisors') {
    return defaultValues_TrusteesSuccessorTrusteesAndTrustAdvisors;
  }

  return {
    ...defaultValues,
  };
}

export interface TrustPersonDropdownFieldProps {
  index: number;
  hideLabel?: boolean;
  trustPersonOptions: SelectInputProps['options'];
  householdId: string;
  labelIconEnd?: React.ReactNode;
  splitScreen: boolean;
  additionalItemRendererProps?: AdditionalItemRendererProps;
}

export interface TrustPersonMultiSelectFieldProps {
  index: number;
  hideLabel?: boolean;
}

export interface TrustPersonRepeaterProps {
  control: Control<Fields>;
  householdId: string;
  trusteesWithoutDuties: boolean;
  trustPersonOptions: SelectInputProps['options'];
  splitScreen: boolean;
  shouldGroupDuplicates: boolean;
}

export interface TrustPersonCardProps {
  index: number;
  trusteesWithoutDuties: boolean;
  trustPersonOptions: SelectInputProps['options'];
  householdId: string;
}
