import { groupBy, keyBy } from 'lodash';

import { EntityMapDerivedData, EntityMapState } from '../types';

export function applyDerivedDataToState(state: EntityMapState): EntityMapState {
  const nodesById = keyBy(state.nodes, (n) => n.id);
  const edgesById = keyBy(state.edges, (n) => n.id);

  const nodesByType = groupBy(
    state.nodes,
    (n) => n.type
  ) as EntityMapDerivedData['nodesByType'];

  const nodeConfigurationsByNodeId = keyBy(
    state.entityGraphView.nodeConfigurations,
    (n) => n.nodeID
  ) as EntityMapDerivedData['nodeConfigurationsByNodeId'];

  return {
    ...state,
    derivedData: {
      isSummaryPanelOpen: !!state.summaryNodeId,
      allPossibleNodes: state.entityGraph.getNodes(),
      nodeConfigurationsByNodeId,
      nodesById,
      edgesById,
      nodesByType,
      summaryNode: state.summaryNodeId
        ? state.entityGraph.getNodesById()[state.summaryNodeId] || null
        : null,
    },
  };
}
