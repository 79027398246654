import { useCallback } from 'react';

import { useFlowChartContext } from '@/components/diagrams/FlowChart';

import { EntityMapEffectFn, SetIsDirtyEffect } from '../types';

export function useSetIsDirtyEffect(): EntityMapEffectFn<SetIsDirtyEffect> {
  const {
    __internal: { setIsDirty },
  } = useFlowChartContext();

  return useCallback<EntityMapEffectFn<SetIsDirtyEffect>>(
    (_state, { isDirty }, _dispatch) => {
      setIsDirty(isDirty);
    },
    [setIsDirty]
  );
}
