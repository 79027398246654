import { intersection } from 'lodash';
import { useCallback } from 'react';
import { Path } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';

import { OnSelectionChange } from '../EntityMultiSelectTable/EntityMultiSelectTable.types';
import {
  isEntityRow,
  isIndividualRow,
} from '../EntityMultiSelectTable/EntityMultiSelectTable.utils';
import { EstateWaterfallFormData } from '../types';

export function useUpdateFilterModel(): OnSelectionChange {
  const { setValue } = useFormContext<EstateWaterfallFormData>();

  const updateFilterModel: OnSelectionChange = useCallback(
    ({ selectedRowIds, rows }) => {
      // If all rows are selected, do not create a filter
      if (selectedRowIds.length === rows.length) {
        setValue(
          'waterfallFilter' as const satisfies Path<EstateWaterfallFormData>,
          null
        );
        return;
      }

      const individualIds = rows
        .filter(isIndividualRow)
        .map((r) => r.individualId);
      const entityIds = rows.filter(isEntityRow).map((r) => r.entityId);
      const selectedClientIds = intersection(individualIds, selectedRowIds);
      const selectedEntityIds = intersection(entityIds, selectedRowIds);

      setValue(
        'waterfallFilter.includedClientIDs' as const satisfies Path<EstateWaterfallFormData>,
        selectedClientIds
      );

      setValue(
        'waterfallFilter.includedEntityIDs' as const satisfies Path<EstateWaterfallFormData>,
        selectedEntityIds
      );
    },
    [setValue]
  );

  return updateFilterModel;
}
