import { isEmpty } from 'lodash';

import {
  getIntegrationEntitiesFromEntity,
  getIntegrationEntityProvider,
} from '@/modules/assetProviderIntegrations/shared/utils';
import { BusinessEntityValuationExplanation } from '@/modules/content/tooltipContent/BusinessEntityValuationExplanation';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';
import { AsyncJobStatus } from '@/types/schema';

import { BUSINESS_ENTITY_KINDS } from '../../entities.constants';
import { EntityDetail_EntityFragment } from '../graphql/EntityDetailPage.generated';
import { useHandlePendingIngestJobs } from '../trusts/integratedAssetsUtils';
import {
  EntityValuationCard,
  EntityValuationVariant,
  IntegrationDetails,
} from './components/EntityValuationCard';
import {
  getEntityValuationActions,
  getValuationDetailsFromEntity,
} from './EntityValuation.utils';
import { EntityValuationActions } from './EntityValuationActions';

export function getIntegrationDetails(
  entity: EntityDetail_EntityFragment,
  jobStatus: AsyncJobStatus | null
): IntegrationDetails | null {
  const integrationEntities = getIntegrationEntitiesFromEntity(entity);
  const provider = getIntegrationEntityProvider(integrationEntities);
  if (isEmpty(integrationEntities) || !jobStatus || !provider) return null;

  // determining the last synced date is actually kind of tricky. in theory,
  // it should be the most recent valuation date, but it's possible to have a manual
  // valuation that's more recent than the most recent provider-synced valuation. but,
  // that's kind of an edge case and we're going to ignore it for now.
  const lastSyncedAt = (() => {
    if (jobStatus !== AsyncJobStatus.Completed) return null;
    const { mostRecentValuationDate } = entity.subtype;
    return mostRecentValuationDate ?? null;
  })();

  return {
    integrationEntities,
    ingestJobStatus: jobStatus,
    lastSyncedAt,
    provider,
  };
}

export interface EntityValuationProps {
  entity: EntityDetail_EntityFragment;
  /** onAfterUpdate should be invoked if an update occurs and we want to refetch data */
  onAfterUpdate: () => void;
  variant: EntityValuationVariant;
}

export function EntityValuation({
  entity,
  variant,
  onAfterUpdate,
}: EntityValuationProps) {
  const clientDetailsContext = useHouseholdDetailsContext();
  const grantorDisplayNames =
    clientDetailsContext?.primaryClients?.map((g) => g.displayName) ?? [];
  const isBusinessEntity = BUSINESS_ENTITY_KINDS.includes(entity.kind);
  const integrationEntities = getIntegrationEntitiesFromEntity(entity);
  const { mostRecentJobStatus } = useHandlePendingIngestJobs(entity, {
    onJobComplete: () => {
      // there's a brief delay between the job complete and the valuation being written,
      // so we delay here
      setTimeout(onAfterUpdate, 100);
    },
    skip: isEmpty(integrationEntities),
  });

  const valuationDetails = getValuationDetailsFromEntity(entity, {
    isViewOnly: false,
  });
  const integrationDetails = getIntegrationDetails(entity, mostRecentJobStatus);
  return (
    <EntityValuationCard
      variant={variant}
      integrationDetails={integrationDetails}
      {...valuationDetails}
      headlineTooltip={
        isBusinessEntity ? (
          <BusinessEntityValuationExplanation
            clientDisplayNames={grantorDisplayNames}
          />
        ) : undefined
      }
      actions={
        <EntityValuationActions
          onAfterUpdate={onAfterUpdate}
          entity={entity}
          actions={getEntityValuationActions(entity)}
        />
      }
    />
  );
}

export function ViewOnlyEntityValuation({
  entity,
}: Omit<EntityValuationProps, 'onAfterUpdate'>) {
  const valuationDetails = getValuationDetailsFromEntity(entity, {
    isViewOnly: true,
  });
  const integrationDetails = getIntegrationDetails(entity, null);
  return (
    <EntityValuationCard
      variant="card"
      integrationDetails={integrationDetails}
      {...valuationDetails}
      actions={null}
    />
  );
}
