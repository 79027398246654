import { useCallback } from 'react';

import { CompleteInitializationEffect, EntityMapEffectFn } from '../types';

export function useCompleteInitialiationEffect(): EntityMapEffectFn<CompleteInitializationEffect> {
  return useCallback<EntityMapEffectFn<CompleteInitializationEffect>>(
    (_state, _effect, dispatch) => {
      dispatch({ type: 'COMPLETE_INITIALIZATION' });
    },
    []
  );
}
