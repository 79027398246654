import { AugmentedCreateEntityInput } from '@/types/schema';

import { EntityShortFormShape } from './CreateEntityShortForm/CreateEntityShortForm.types';
import { makeCreateEntityInput } from './CreateEntityShortForm/formUtils/CreateEntityShortForm.entityUtils';

export function mapFormDataToInput(
  formData: EntityShortFormShape,
  householdId: string
): AugmentedCreateEntityInput {
  const input = makeCreateEntityInput(formData, householdId);
  return input;
}
