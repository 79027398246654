import { Stack } from '@mui/material';
import { isEmpty, pick } from 'lodash';
import { useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { Link } from '@/components/form/baseInputs/Link';
import { FormAwareCard } from '@/components/form/formAwareInputs/FormAwareCard';
import { Trash01Icon } from '@/components/icons/Trash01Icon';
import { Callout } from '@/components/notifications/Callout/Callout';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  useFormContext,
  useSubmitSuccessHandler,
} from '@/components/react-hook-form';
import { getNewFileUploadFromUploadResult } from '@/modules/documents/documentUploaderUtils';
import { FileRepresentation } from '@/modules/files/FileRepresentation/FileRepresentation';
import {
  FileUploader,
  NewFileUpload,
} from '@/modules/files/FileUploader/FileUploader';
import { acceptedFileTypes } from '@/modules/files/FileUploader/fileUploaderUtils';
import { CreateFileMutation } from '@/modules/files/FileUploader/graphql/CreateFile.generated';
import { useGenerateSignedCSVFileUploadURL } from '@/modules/files/FileUploader/useGenerateSignedCSVFileUploadURL';
import { UploadedFileData } from '@/modules/files/FileUploader/useUploadFile';
import { COLORS } from '@/styles/tokens/colors';

import {
  CSVUploadFormPaths,
  CSVUploadFormShape,
} from '../ImportCSVValuationsPage/ImportCSVValuationsPage.types';
import { downloadSampleFile } from './CSVUploadForm.utils';
import { useCreateIntegrationEntitiesFromFileMutation } from './graphql/CSVUploadForm.generated';

const csvUploadFileTypes = pick(acceptedFileTypes, ['application/csv']);

interface CSVUploadFormProps {
  onUploadComplete: () => void;
}

export function CSVUploadForm({ onUploadComplete }: CSVUploadFormProps) {
  const { setValue, control } = useFormContext<CSVUploadFormShape>();
  const { showFeedback } = useFeedback();
  const [pendingFile, setPendingFile] = useState<null | Omit<
    NewFileUpload,
    'householdId'
  >>(null);

  function clearFileState() {
    setPendingFile(null);
    setValue('fileId', null);
  }

  useSubmitSuccessHandler(() => {
    clearFileState();
  });

  const { generateUploadURL } = useGenerateSignedCSVFileUploadURL();
  const [createIntegrationEntities] =
    useCreateIntegrationEntitiesFromFileMutation({
      onCompleted: () => {
        showFeedback('File successfully uploaded', {
          variant: 'success',
        });
        onUploadComplete();
      },
      onError: (error) => {
        clearFileState();
        showFeedback(`File upload failed. ${error.message}`);
      },
    });

  function handlePendingFile(uploaded: UploadedFileData<CreateFileMutation>[]) {
    if (isEmpty(uploaded)) {
      return;
    }

    // We're only handling one file at a time for now.
    const { file, result } = uploaded[0]!;

    const pendingFile = getNewFileUploadFromUploadResult(file, result?.data);
    if (pendingFile === null) {
      clearFileState();
      return;
    }

    setPendingFile({ ...pendingFile });
    setValue('fileId', pendingFile.fileId);
    void createIntegrationEntities({
      variables: {
        fileId: pendingFile.fileId,
      },
    });
  }

  return (
    <Stack spacing={2}>
      {pendingFile ? (
        <FileRepresentation
          fileName={pendingFile.fileName}
          uploadedAt={pendingFile.uploadedAt}
          uploadedBy={pendingFile.uploadedByName}
          actions={
            <Button
              size="xs"
              variant="transparent"
              onClick={() => setPendingFile(null)}
            >
              <Trash01Icon size={20} color={COLORS.FUNCTIONAL.ERROR.DEFAULT} />
            </Button>
          }
        />
      ) : (
        <FormAwareCard
          variant="transparent"
          control={control}
          validation={{
            required: (value) => {
              if (!value) {
                return 'Please upload a file';
              }

              return undefined;
            },
          }}
          fieldName={`fileId` as const satisfies CSVUploadFormPaths}
        >
          <FileUploader
            onUploadComplete={handlePendingFile}
            generateSignedUploadURL={generateUploadURL}
            acceptTypes={csvUploadFileTypes}
          />
        </FormAwareCard>
      )}

      <Callout severity="info-high">
        <Stack spacing={0.5}>
          <b>How should I format my CSV file?</b>
          <div>
            To update valuations, your file will need to be structured to match
            the template below.
          </div>
          <div>
            <Link onClick={downloadSampleFile} display="Download template" />
          </div>
        </Stack>
      </Callout>
    </Stack>
  );
}
