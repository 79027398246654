import { Stack, StackProps, Typography } from '@mui/material';

import { getPublicImageUrl } from '@/utils/staticFiles';

export interface WaterfallEmptyStateProps extends StackProps {
  heading: React.ReactNode;
  description: React.ReactNode;
  action: React.ReactNode;
}

export function WaterfallEmptyState({
  heading,
  description,
  action,
  ...stackProps
}: WaterfallEmptyStateProps) {
  return (
    <Stack
      gap={3}
      direction="column"
      justifyContent="center"
      alignItems="center"
      {...stackProps}
    >
      <img
        src={getPublicImageUrl('/waterfallPlaceholder.png')}
        alt=""
        height="200px"
        width="350px"
      />
      <Stack
        gap={1}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h1">{heading}</Typography>
        <Typography variant="body1">{description}</Typography>
      </Stack>
      {action}
    </Stack>
  );
}
