import { Stack } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { HeaderCard } from '@/components/layout/HeaderCard';

import { ClientArchetypeCopy } from './ClientArchetypeCopy';
import { ClientExport } from './ClientExport';
import { ClientImport } from './ClientImport';

export function ClientImportExport() {
  return (
    <HeaderCard heading="Client import/export">
      <Stack spacing={2}>
        <Card variant="filled-light" sx={{ p: 2 }}>
          <ClientArchetypeCopy />
        </Card>
        <Card variant="filled-light" sx={{ p: 2 }}>
          <ClientExport />
        </Card>
        <Card variant="filled-light" sx={{ p: 2 }}>
          <ClientImport />
        </Card>
      </Stack>
    </HeaderCard>
  );
}
