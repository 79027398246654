import { compact } from 'lodash';

import { Edit02Icon } from '@/components/icons/Edit02Icon';
import { CellContainer } from '@/components/tables/DataTable/components/cells';
import { IconRenderer } from '@/components/tables/DataTable/renderers/cell/IconRenderer';
import { LegendColorRenderer } from '@/components/tables/DataTable/renderers/cell/LegendColorRenderer';
import { TextRenderer } from '@/components/tables/DataTable/renderers/cell/TextRenderer';
import { TwoLineTextRenderer } from '@/components/tables/DataTable/renderers/cell/TwoLineTextRenderer';
import { Column } from '@/components/tables/DataTable/types';
import { FilterOptions } from '@/modules/entities/entities.constants';
import {
  formatCurrencyNoDecimals,
  formatCurrencyNoDecimalsAccounting,
} from '@/utils/formatting/currency';
import { getFormattedDate } from '@/utils/formatting/getFormattedDate';

import { ClientDetailsEntityListRow } from './rowUtils';

export const getColumns = (): Column<ClientDetailsEntityListRow>[] =>
  compact([
    {
      field: 'swatch',
      headerName: '',
      renderCell: LegendColorRenderer({
        backgroundColor: ({ row }) => row.swatch,
      }),
      align: 'center',
      sortable: false,
      width: 55,
      minWidth: 55,
      maxWidth: 55,
    },
    {
      field: 'entityName',
      headerName: 'Entity',
      width: 400,
      flex: 1,
      valueGetter: ({ row }) => {
        return row.entityName.lineOne;
      },
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => {
          return row.entityName.lineOne;
        },
        lineOneProps: {
          variant: 'h5',
        },
        lineTwo: ({ row }) => {
          return row.entityName.lineTwo;
        },
      }),
    },
    {
      // Note: this is not the same as the "status" column, which is used for filtering rows by tab.
      field: 'draftTabEntityLabel',
      headerName: 'Status',
      width: 150,
    },
    {
      field: 'createdAtTheDeathOf',
      headerName: 'Created at the death of',
      width: 200,
    },
    {
      field: 'effectiveDate',
      headerName: 'Effective date',
      align: 'right',
      width: 150,
      valueGetter: ({ row }) => {
        return row.effectiveDate;
      },
      renderCell: TextRenderer({
        text: ({ row }) => {
          return getFormattedDate(row.effectiveDate);
        },
      }),
    },
    {
      field: 'endDate',
      headerName: 'Termination date',
      align: 'right',
      width: 180,
      valueGetter: ({ row }) => {
        return row.endDate;
      },
      renderCell: TextRenderer({
        text: ({ row }) => {
          return getFormattedDate(row.endDate);
        },
      }),
    },
    {
      field: 'beneficiaries',
      headerName: 'Beneficiaries',
      width: 400,
      flex: 1,
    },
    {
      field: 'assets',
      headerName: 'Assets',
      width: 200,
      flex: 1,
    },
    {
      field: 'assetLocation',
      headerName: 'Asset location',
      width: 240,
      valueGetter: ({ row }) => {
        return row.assetLocation.lineOne ?? '';
      },
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => {
          return row.assetLocation.lineOne ?? '';
        },
        lineTwo: ({ row }) => {
          return row.assetLocation.lineTwo ?? '';
        },
      }),
    },
    {
      field: 'federalAssetLocation',
      headerName: 'Federal asset location',
      width: 120,
      valueGetter: ({ row }) => {
        return row.federalAssetLocation?.lineOne ?? '';
      },
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => {
          return row.federalAssetLocation?.lineOne ?? '';
        },
        lineTwo: ({ row }) => {
          return row.federalAssetLocation?.lineTwo ?? '';
        },
      }),
    },
    {
      field: 'stateAssetLocation',
      headerName: 'State asset location',
      width: 120,
      valueGetter: ({ row }) => {
        return row.stateAssetLocation?.lineOne ?? '';
      },
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => {
          return row.stateAssetLocation?.lineOne ?? '';
        },
        lineTwo: ({ row }) => {
          return row.stateAssetLocation?.lineTwo ?? '';
        },
      }),
    },
    {
      field: 'currentValue',
      headerName: 'Net value',
      width: 175,
      align: 'right',
      valueGetter: ({ row }) => {
        if (!('currentValue' in row)) {
          return '';
        }
        return row.currentValue.lineOne;
      },
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => {
          if (!('currentValue' in row)) {
            return '';
          }

          return formatCurrencyNoDecimalsAccounting(row.currentValue.lineOne);
        },
        lineTwo: ({ row }) => {
          if (!('currentValue' in row) || !row.currentValue.lineTwo) {
            return '';
          }

          return `as of ${getFormattedDate(row.currentValue.lineTwo)}`;
        },
      }),
    },
    {
      field: 'distributedValue',
      headerName: 'Distributed value',
      width: 175,
      align: 'right',
      valueGetter: ({ row }) => {
        if (!('distributedValue' in row)) {
          return '';
        }
        return row.distributedValue.lineOne.toNumber();
      },
      renderCell: TwoLineTextRenderer({
        lineOne: ({ row }) => {
          if (!('distributedValue' in row)) {
            return '';
          }

          return formatCurrencyNoDecimals(row.currentValue.lineOne);
        },
        lineTwo: ({ row }) => {
          if (!('distributedValue' in row) || !row.distributedValue.lineTwo) {
            return '';
          }

          return `as of ${getFormattedDate(row.distributedValue.lineTwo)}`;
        },
      }),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
    },
    {
      field: 'actions',
      headerName: '',
      align: 'center',
      width: 64,
      minWidth: 64,
      maxWidth: 64,
      sortable: false,
      renderCell: ({ row, colDef }) => {
        return (
          <CellContainer align={colDef.align}>{row.actions}</CellContainer>
        );
      },
    },
    {
      field: 'chevron',
      headerName: '',
      align: 'center',
      width: 64,
      minWidth: 64,
      maxWidth: 64,
      renderCell: IconRenderer({
        icon: Edit02Icon,
      }),
      sortable: false,
    },
  ]);

export const TESTAMENTARY_ENTITY_TABS = compact([
  {
    display: 'Active',
    value: FilterOptions.ACTIVE,
    hideColumns: [
      'distributedValue',
      'endDate',
      'draftTabEntityLabel',
      'createdAtTheDeathOf',
      'actions',
      'beneficiaries',
      'effectiveDate',
      'assetLocation',
    ],
  },
  {
    display: 'Completed',
    value: FilterOptions.COMPLETED,
    hideColumns: [
      'swatch',
      'assets',
      'assetLocation',
      'federalAssetLocation',
      'stateAssetLocation',
      'draftTabEntityLabel',
      'createdAtTheDeathOf',
      'actions',
      'beneficiaries',
    ],
  },
  {
    display: 'Testamentary',
    value: FilterOptions.TESTAMENTARY,
    hideColumns: [
      'swatch',
      'effectiveDate',
      'endDate',
      'distributedValue',
      'assets',
      'currentValue',
      'draftTabEntityLabel',
      'assetLocation',
      'createdAtTheDeathOf',
    ],
  },
  {
    display: 'Drafts',
    value: FilterOptions.DRAFTS,
    hideColumns: [
      'swatch',
      'effectiveDate',
      'distributedValue',
      'endDate',
      'createdAtTheDeathOf',
      'assets',
      'currentValue',
      'beneficiaries',
      'assetLocation',
    ],
  },
]);
