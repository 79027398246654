import { omit } from 'lodash';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

import { BalanceSheetConfigurationPopper_BalanceSheetViewConfigurationFragment } from '../BalanceSheetConfigurationPopper/graphql/BalanceSheetConfigurationPopper.generated';
import { RowData } from './BalanceSheetTable.constants';

interface SelectedEntitiesRow {
  entityIds: string[];
  row: RowData;
}

interface SelectedLiabilityRow {
  liabilityId: string;
  row: RowData;
}

export type ViewConfiguration = Omit<
  BalanceSheetConfigurationPopper_BalanceSheetViewConfigurationFragment,
  'id'
>;

interface BalanceSheetTableContextType {
  viewConfigurationId: string | null;
  selectedEntitiesRow: SelectedEntitiesRow | null;
  setSelectedEntitiesRow: (row: SelectedEntitiesRow | null) => void;
  selectedLiabilityRow: SelectedLiabilityRow | null;
  setSelectedLiabilityRow: (row: SelectedLiabilityRow | null) => void;
  viewConfiguration: ViewConfiguration | null;
  setViewConfiguration: (viewConfig: ViewConfiguration) => void;
  loading: boolean;
  isEditingColumnOrder: boolean;
  setIsEditingColumnOrder: (isEditing: boolean) => void;
  renderWhiteLabeled: boolean;
  setRenderWhiteLabeled: (render: boolean) => void;
}

const BalanceSheetTableContext = createContext<
  BalanceSheetTableContextType | undefined
>(undefined);

interface BalanceSheetTableProviderProps {
  children: ReactNode;
  viewConfiguration: BalanceSheetConfigurationPopper_BalanceSheetViewConfigurationFragment | null;
  loading: boolean;
}

export function BalanceSheetTableProvider({
  children,
  viewConfiguration: initialViewConfiguration,
  loading,
}: BalanceSheetTableProviderProps) {
  // split off the id because the viewConfig is interacted with directly from a form, and the
  // id is needed for updates
  const { id: viewConfigurationId, viewConfig } = useMemo(() => {
    return {
      id: initialViewConfiguration?.id ?? null,
      viewConfig: omit(initialViewConfiguration, 'id') ?? null,
    };
  }, [initialViewConfiguration]);

  const [selectedRow, setSelectedRow] = useState<SelectedEntitiesRow | null>(
    null
  );
  const [selectedLiabilityRow, setSelectedLiabilityRow] =
    useState<SelectedLiabilityRow | null>(null);
  const [isEditingColumnOrder, setIsEditingColumnOrder] = useState(false);
  const [viewConfiguration, setViewConfiguration] =
    useState<ViewConfiguration | null>(viewConfig);
  const [renderWhiteLabeled, setRenderWhiteLabeled] = useState(false);

  useEffect(() => {
    setViewConfiguration(viewConfig);
  }, [viewConfig]);

  return (
    <BalanceSheetTableContext.Provider
      value={{
        viewConfigurationId: viewConfigurationId ?? null,
        selectedEntitiesRow: selectedRow,
        setSelectedEntitiesRow: setSelectedRow,
        selectedLiabilityRow,
        setSelectedLiabilityRow,
        isEditingColumnOrder,
        setIsEditingColumnOrder,
        setViewConfiguration,
        viewConfiguration,
        renderWhiteLabeled,
        setRenderWhiteLabeled,
        loading,
      }}
    >
      {children}
    </BalanceSheetTableContext.Provider>
  );
}

export function useBalanceSheetTableContext() {
  return useGuardedContext(
    BalanceSheetTableContext,
    'BalanceSheetTableProvider'
  );
}

export function useUnguardedBalanceSheetTableContext() {
  return useContext(BalanceSheetTableContext);
}
