// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PossibleRelationshipOwnerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PossibleRelationshipOwnerQuery = { __typename?: 'Query', possibleRelationshipOwners: Array<{ __typename?: 'Employee', id: string, displayName: string, user: { __typename?: 'User', id: string, displayName: string } } | null> };


export const PossibleRelationshipOwnerDocument = gql`
    query PossibleRelationshipOwner {
  possibleRelationshipOwners {
    id
    displayName
    user {
      id
      displayName
    }
  }
}
    `;

/**
 * __usePossibleRelationshipOwnerQuery__
 *
 * To run a query within a React component, call `usePossibleRelationshipOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePossibleRelationshipOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePossibleRelationshipOwnerQuery({
 *   variables: {
 *   },
 * });
 */
export function usePossibleRelationshipOwnerQuery(baseOptions?: Apollo.QueryHookOptions<PossibleRelationshipOwnerQuery, PossibleRelationshipOwnerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PossibleRelationshipOwnerQuery, PossibleRelationshipOwnerQueryVariables>(PossibleRelationshipOwnerDocument, options);
      }
export function usePossibleRelationshipOwnerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PossibleRelationshipOwnerQuery, PossibleRelationshipOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PossibleRelationshipOwnerQuery, PossibleRelationshipOwnerQueryVariables>(PossibleRelationshipOwnerDocument, options);
        }
export function usePossibleRelationshipOwnerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PossibleRelationshipOwnerQuery, PossibleRelationshipOwnerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PossibleRelationshipOwnerQuery, PossibleRelationshipOwnerQueryVariables>(PossibleRelationshipOwnerDocument, options);
        }
export type PossibleRelationshipOwnerQueryHookResult = ReturnType<typeof usePossibleRelationshipOwnerQuery>;
export type PossibleRelationshipOwnerLazyQueryHookResult = ReturnType<typeof usePossibleRelationshipOwnerLazyQuery>;
export type PossibleRelationshipOwnerSuspenseQueryHookResult = ReturnType<typeof usePossibleRelationshipOwnerSuspenseQuery>;
export type PossibleRelationshipOwnerQueryResult = Apollo.QueryResult<PossibleRelationshipOwnerQuery, PossibleRelationshipOwnerQueryVariables>;
export const Operations = {
  Query: {
    PossibleRelationshipOwner: 'PossibleRelationshipOwner' as const
  }
}