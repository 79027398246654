import { Box, Stack } from '@mui/material';
import React from 'react';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { ButtonGroup } from '@/components/form/baseInputs/ButtonGroup';
import { useActiveTab } from '@/components/navigation/NavigationTabs/useActiveTab';

import { AdminAssetCategoriesConfigurationPage } from '../AdminAssetCategoriesConfigurationPage/AdminAssetCategoriesConfigurationPage';
import { AdminBalanceSheetConfigurationPage } from '../AdminBalanceSheetConfigurationPage/AdminBalanceSheetConfigurationPage';

enum CategoryTypes {
  ASSET = 'asset',
  BALANCE_SHEET = 'balance-sheet',
}

export function AdminCategoriesConfigurationPage() {
  const { activeTab: activeCategory, setActiveTab: setActiveCategory } =
    useActiveTab({
      defaultTab: CategoryTypes.ASSET,
      filterSearchParam: 'page',
    });

  const tabButtonSelector = (
    <Box width={300}>
      <ButtonGroup
        value={activeCategory}
        label="Category"
        hideLabel
        onChange={(_e, value) => setActiveCategory(value)}
        options={[
          {
            value: CategoryTypes.ASSET,
            display: 'Asset',
          },
          {
            value: CategoryTypes.BALANCE_SHEET,
            display: 'Balance sheet',
          },
        ]}
      />
    </Box>
  );

  return (
    <SubpageLayout heading="Categories">
      <Stack spacing={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        ></Box>
        {activeCategory === CategoryTypes.ASSET ? (
          <AdminAssetCategoriesConfigurationPage
            pageToggle={tabButtonSelector}
          />
        ) : (
          <AdminBalanceSheetConfigurationPage pageToggle={tabButtonSelector} />
        )}
      </Stack>
    </SubpageLayout>
  );
}
