// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetLifetimeExclusionAmounts_ClientProfileFragment = { __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, remainingStateExemption: CurrencyUSD, address?: { __typename?: 'Address', id: string, stateCode: string } | null };

export type GetLifetimeExclusionAmounts_HouseholdFragment = { __typename?: 'Household', possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, remainingStateExemption: CurrencyUSD, address?: { __typename?: 'Address', id: string, stateCode: string } | null }> };

export type GetLifetimeExclusionAmountsQueryVariables = Types.Exact<{
  householdWhere?: Types.InputMaybe<Types.HouseholdWhereInput>;
}>;


export type GetLifetimeExclusionAmountsQuery = { __typename?: 'Query', households: { __typename?: 'HouseholdConnection', edges?: Array<{ __typename?: 'HouseholdEdge', node?: { __typename?: 'Household', id: string, possiblePrimaryClients: Array<{ __typename?: 'ClientProfile', id: string, gstExclusionUsed: CurrencyUSD, displayName: string, lifetimeExclusionUsed: CurrencyUSD, remainingStateExemption: CurrencyUSD, address?: { __typename?: 'Address', id: string, stateCode: string } | null }> } | null } | null> | null } };

export const GetLifetimeExclusionAmounts_ClientProfileFragmentDoc = gql`
    fragment GetLifetimeExclusionAmounts_ClientProfile on ClientProfile {
  id
  gstExclusionUsed
  displayName
  lifetimeExclusionUsed
  remainingStateExemption
  address {
    id
    stateCode
  }
}
    `;
export const GetLifetimeExclusionAmounts_HouseholdFragmentDoc = gql`
    fragment GetLifetimeExclusionAmounts_Household on Household {
  possiblePrimaryClients {
    ...GetLifetimeExclusionAmounts_ClientProfile
  }
}
    ${GetLifetimeExclusionAmounts_ClientProfileFragmentDoc}`;
export const GetLifetimeExclusionAmountsDocument = gql`
    query GetLifetimeExclusionAmounts($householdWhere: HouseholdWhereInput) {
  households(where: $householdWhere) {
    edges {
      node {
        id
        ...GetLifetimeExclusionAmounts_Household
      }
    }
  }
}
    ${GetLifetimeExclusionAmounts_HouseholdFragmentDoc}`;

/**
 * __useGetLifetimeExclusionAmountsQuery__
 *
 * To run a query within a React component, call `useGetLifetimeExclusionAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLifetimeExclusionAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLifetimeExclusionAmountsQuery({
 *   variables: {
 *      householdWhere: // value for 'householdWhere'
 *   },
 * });
 */
export function useGetLifetimeExclusionAmountsQuery(baseOptions?: Apollo.QueryHookOptions<GetLifetimeExclusionAmountsQuery, GetLifetimeExclusionAmountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLifetimeExclusionAmountsQuery, GetLifetimeExclusionAmountsQueryVariables>(GetLifetimeExclusionAmountsDocument, options);
      }
export function useGetLifetimeExclusionAmountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLifetimeExclusionAmountsQuery, GetLifetimeExclusionAmountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLifetimeExclusionAmountsQuery, GetLifetimeExclusionAmountsQueryVariables>(GetLifetimeExclusionAmountsDocument, options);
        }
export function useGetLifetimeExclusionAmountsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetLifetimeExclusionAmountsQuery, GetLifetimeExclusionAmountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetLifetimeExclusionAmountsQuery, GetLifetimeExclusionAmountsQueryVariables>(GetLifetimeExclusionAmountsDocument, options);
        }
export type GetLifetimeExclusionAmountsQueryHookResult = ReturnType<typeof useGetLifetimeExclusionAmountsQuery>;
export type GetLifetimeExclusionAmountsLazyQueryHookResult = ReturnType<typeof useGetLifetimeExclusionAmountsLazyQuery>;
export type GetLifetimeExclusionAmountsSuspenseQueryHookResult = ReturnType<typeof useGetLifetimeExclusionAmountsSuspenseQuery>;
export type GetLifetimeExclusionAmountsQueryResult = Apollo.QueryResult<GetLifetimeExclusionAmountsQuery, GetLifetimeExclusionAmountsQueryVariables>;
export const Operations = {
  Query: {
    GetLifetimeExclusionAmounts: 'GetLifetimeExclusionAmounts' as const
  },
  Fragment: {
    GetLifetimeExclusionAmounts_ClientProfile: 'GetLifetimeExclusionAmounts_ClientProfile' as const,
    GetLifetimeExclusionAmounts_Household: 'GetLifetimeExclusionAmounts_Household' as const
  }
}