import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';
import { INTEGRATION_EXCLUDED_ENTITY_TYPES } from '@/modules/assetProviderIntegrations/shared/constants';
import {
  useAssetProviderDisplayName,
  useIsAssetProviderIntegrationEnabled,
} from '@/modules/assetProviderIntegrations/shared/hooks/useEnabledAssetsIntegrations';
import { SharedIntegrationEntityTypeahead } from '@/modules/assetProviderIntegrations/shared/SharedIntegrationEntityTypeahead';
import { EntityType } from '@/modules/entities/types/EntityType';
import { useHouseholdDetailsContext } from '@/modules/household/contexts/householdDetails.context';

import { ConnectHouseholdToAssetProviderCard } from './ConnectHouseholdToAssetProvider/ConnectHouseholdToAssetProviderCard';
import {
  FullScreenStructuredAssetsModalForm,
  FullScreenStructuredAssetsModalFormPath,
} from './FullScreenStructuredAssetsModal.types';
import { useValuationIntegrationDetails } from './hooks/useValuationLinkDetails';

export interface AssetIntegrationFieldProps {
  entityType: EntityType;
  householdId: string;
}

export function AssetIntegrationField({
  entityType,
  householdId,
}: AssetIntegrationFieldProps) {
  const { hasConnectedIntegration } = useIsAssetProviderIntegrationEnabled();
  const { isIntegratedScenario } = useValuationIntegrationDetails();
  const { control, setValue } =
    useFormContext<FullScreenStructuredAssetsModalForm>();
  const assetProviderDisplayName = useAssetProviderDisplayName();
  const isIntegrationSupportedEntityType =
    !INTEGRATION_EXCLUDED_ENTITY_TYPES.includes(entityType);

  const formAssetsSum = useWatch({
    control,
    name: 'structuredAssetsSubform._assetsValueSum',
  });

  const { isHouseholdLinkedToAddepar, isHouseholdLinkedToBlackDiamond } =
    useHouseholdDetailsContext();

  const isHouseholdIntegrated =
    isHouseholdLinkedToAddepar || isHouseholdLinkedToBlackDiamond;

  const shouldShowIntegrationLink =
    isIntegrationSupportedEntityType && isHouseholdIntegrated;

  const shouldShowConnectHouseholdPrompt =
    isIntegrationSupportedEntityType && // the entity type can be connected to
    !isHouseholdIntegrated && // the household is not already connected to an integration
    hasConnectedIntegration; // the user is logged into an integration

  // when enabling the integration scenario, if there are already structured assets,
  // we want to automatically toggle on the allow additional manual assets switch
  useEffect(() => {
    if (isIntegratedScenario && formAssetsSum.greaterThan(new Decimal(0))) {
      setValue(`allowAdditionalManualAssets`, true);
    }
    // no exhaustive deps because we only want to rerun this if the isIntegratedScenario prop changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIntegratedScenario, setValue]);

  if (shouldShowConnectHouseholdPrompt) {
    return <ConnectHouseholdToAssetProviderCard />;
  }

  return (
    <Stack>
      {shouldShowIntegrationLink && (
        <FormLayoutRow>
          <FormLayoutItem>
            <SharedIntegrationEntityTypeahead
              filterToHouseholdId={householdId}
              control={control}
              fieldName={
                `integrationEntityIds` as const satisfies FullScreenStructuredAssetsModalFormPath
              }
              includeAllEntitiesFieldName={
                `linkToAllAddeparEntities` as const satisfies FullScreenStructuredAssetsModalFormPath
              }
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
      {isIntegratedScenario && (
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareSwitch
              labelPosition="right"
              control={control}
              fieldName={
                `allowAdditionalManualAssets` as const satisfies FullScreenStructuredAssetsModalFormPath
              }
              label="Manually track additional assets"
              helpText={`Enable this to track assets this entity holds that do not exist in ${assetProviderDisplayName}`}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      )}
    </Stack>
  );
}
