import { ReactFlowProvider, ReactFlowState, useStore } from '@xyflow/react';
import { PropsWithChildren, useState } from 'react';

import { useMeasureDOMElement } from '@/hooks/useMeasureDOMElement';

import { FlowChartContext } from './flowChart.context';

const domElSelector = (s: ReactFlowState) => s.domNode;

function useFlowChartContextValue(): FlowChartContext {
  const [isDirty, setIsDirty] = useState(false);
  const [markers, setMarkers] = useState({});
  const [dropTargetNode, setDropTargetNode] =
    useState<FlowChartContext['dropTargetNode']>(null);
  const domEl = useStore(domElSelector);
  const flowBounds = useMeasureDOMElement(domEl);

  return {
    isDirty,
    dropTargetNode,
    flowBounds,
    __internal: {
      setDropTargetNode,
      setIsDirty,
      markers,
      setMarkers,
    },
  };
}

export const FlowChartContextProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const value = useFlowChartContextValue();
  return (
    <FlowChartContext.Provider value={value}>
      {children}
    </FlowChartContext.Provider>
  );
};

export const FlowChartProvider: typeof ReactFlowProvider = ({
  children,
  ...props
}) => {
  return (
    <ReactFlowProvider {...props}>
      <FlowChartContextProvider>{children}</FlowChartContextProvider>
    </ReactFlowProvider>
  );
};
