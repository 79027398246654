import { compact } from 'lodash';
import { useState } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { DeleteButton } from '@/components/form/baseInputs/Button/DeleteButton';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { useReportError } from '@/hooks/useReportError';
import { useUpdateHouseholdMutation } from '@/modules/household/graphql/Households.generated';
import { TaskReassignmentModal } from '@/modules/tasks/TaskReassignmentModal/TaskReassignmentModal';
import { TaskReassignmentModalCloseReason } from '@/modules/tasks/TaskReassignmentModal/TaskReassignmentModal.types';
import { UNASSIGNED_TASK_SENTINEL } from '@/modules/tasks/tasks.constants';

import { useUpdateClientAndReassignTasksMutation } from './graphql/ManageHouseholdAccessForm.generated';

interface RemoveAssociatedUserButtonProps {
  userId: string;
  employeeId?: string;
  householdId: string;
  hasTasksToReassign: boolean;
  handleRemove: () => void;
  disabled?: boolean;
  onAfterRemove: () => void;
}

/**
 * @description This component is used to remove a user from a client. If the user has tasks assigned to them,
 * the person interacting with the button will be prompted to reassign those tasks to another user. Otherwise,
 * they'll just be asked to confirm the removal.
 */
export function RemoveAssociatedUserButton({
  householdId,
  userId,
  employeeId,
  hasTasksToReassign,
  disabled,
  handleRemove,
  onAfterRemove,
}: RemoveAssociatedUserButtonProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [updateClientAndReassignTasks, { loading: updatingClient }] =
    useUpdateClientAndReassignTasksMutation();
  const [updateHousehold] = useUpdateHouseholdMutation();
  const [isTaskReassignmentModalOpen, setIsTaskReassignmentModalOpen] =
    useState(false);

  function handleLocalConfirmDelete() {
    handleRemove();
    onAfterRemove();
  }

  function handleConfirmDelete() {
    return updateHousehold({
      variables: {
        id: householdId,
        input: {
          removeAssignedEmployeeIDs: compact([employeeId]),
          removeCollaboratorIDs: [userId],
        },
      },
      onCompleted: () => {
        setIsTaskReassignmentModalOpen(false);
        showFeedback('Removed user access.', {
          variant: 'success',
        });
        handleLocalConfirmDelete();
      },
      onError: (err) => {
        showFeedback(
          'Could not remove user access. Please refresh the page and try again.'
        );
        reportError('failed to remove user access', err);
      },
    });
  }

  const handleTaskReassignmentModalClosed: React.ComponentProps<
    typeof TaskReassignmentModal
  >['onClose'] = (reason, reassignToUserId) => {
    if (
      reason === TaskReassignmentModalCloseReason.CLOSE ||
      !reassignToUserId
    ) {
      setIsTaskReassignmentModalOpen(false);
      return;
    }

    return updateClientAndReassignTasks({
      variables: {
        householdId: householdId,
        updateClientInput: {
          removeAssignedEmployeeIDs: compact([employeeId]),
          removeCollaboratorIDs: [userId],
        },
        reassignTasksToId:
          reassignToUserId === UNASSIGNED_TASK_SENTINEL
            ? null
            : reassignToUserId,
        reassignTasksLike: {
          hasAssignedToWith: [
            {
              id: userId,
            },
          ],
          hasEntityWith: [
            {
              hasHouseholdWith: [
                {
                  id: householdId,
                },
              ],
            },
          ],
        },
      },
      onCompleted: () => {
        setIsTaskReassignmentModalOpen(false);
        showFeedback('Reassigned tasks and removed user access.', {
          variant: 'success',
        });
        handleLocalConfirmDelete();
      },
      onError: (err) => {
        showFeedback(
          'Could not remove user access or reassign tasks. Please refresh the page and try again.'
        );
        reportError('failed to remove user access or reassign tasks', err);
      },
    });
  };

  if (!hasTasksToReassign) {
    return (
      <DeleteButton
        loading={updatingClient}
        disabled={disabled}
        onConfirmDelete={handleConfirmDelete}
        promptDeleteText="Remove"
        confirmDeleteText="Confirm remove"
      />
    );
  }

  return (
    <>
      <TaskReassignmentModal
        householdId={householdId}
        userId={userId}
        isOpen={isTaskReassignmentModalOpen}
        onClose={handleTaskReassignmentModalClosed}
      />
      <Button
        variant="destructive"
        size="sm"
        loading={updatingClient}
        onClick={() => setIsTaskReassignmentModalOpen(true)}
        disabled={disabled}
      >
        Remove
      </Button>
    </>
  );
}
