import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { Path, useWatch } from 'react-hook-form';

import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { FormAwareSwitch } from '@/components/form/formAwareInputs/FormAwareSwitch';
import { useFormContext } from '@/components/react-hook-form';
import { AccessParameterFrequency } from '@/types/schema';

import { ACCESS_PARAMETER_FREQUENCY_ITEMS } from './EntityBeneficiariesSubform.constants';
import { BeneficiaryFormAccessParameter } from './EntityBeneficiariesSubform.types';

type AccessParameterFrequencyIntervalFormPaths =
  Path<BeneficiaryFormAccessParameter>;

interface AccessParameterFrequencyIntervalFormProps {
  fieldName: `accessParametersPartial.${number}`;
}

export function AccessParameterFrequencyIntervalForm({
  fieldName,
}: AccessParameterFrequencyIntervalFormProps) {
  const { control, setValue } =
    useFormContext<BeneficiaryFormAccessParameter>();

  const hasFrequencyToggled = useWatch({
    name: `${fieldName}._hasFrequency` as '_hasFrequency' satisfies AccessParameterFrequencyIntervalFormPaths,
    control,
  });

  const selectedFrequency = useWatch({
    name: `${fieldName}.frequency` as 'frequency' satisfies AccessParameterFrequencyIntervalFormPaths,
    control,
  });

  useEffect(
    function initFrequency() {
      if (hasFrequencyToggled && selectedFrequency === undefined) {
        setValue(
          `${fieldName}.frequency` as 'frequency' satisfies AccessParameterFrequencyIntervalFormPaths,
          AccessParameterFrequency.Annually
        );
      }
    },
    [fieldName, hasFrequencyToggled, selectedFrequency, setValue]
  );

  return (
    <Stack spacing={1}>
      <FormAwareSwitch<BeneficiaryFormAccessParameter>
        label="Frequency interval"
        labelPosition="right"
        fieldName={
          `${fieldName}._hasFrequency` as '_hasFrequency' satisfies AccessParameterFrequencyIntervalFormPaths
        }
        control={control}
      />
      {hasFrequencyToggled && (
        <Stack pl={7} spacing={1}>
          <Box maxWidth={250}>
            <FormAwareSelectInput<BeneficiaryFormAccessParameter>
              label="Frequency"
              hideLabel
              fieldName={
                `${fieldName}.frequency` as 'frequency' satisfies AccessParameterFrequencyIntervalFormPaths
              }
              control={control}
              options={ACCESS_PARAMETER_FREQUENCY_ITEMS}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
}
