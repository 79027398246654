import { Stack } from '@mui/material';
import Decimal from 'decimal.js';
import { compact } from 'lodash';
import { useMemo } from 'react';

import { Button } from '@/components/form/baseInputs/Button';
import { PlusIcon } from '@/components/icons/PlusIcon';
import { Share06Icon } from '@/components/icons/Share06Icon';
import { EmptyListActionCard } from '@/components/lists/EmptyListActionCard';
import {
  ButtonTab,
  TabContent,
  Tabs,
} from '@/components/navigation/NavigationTabs';
import { TabsProvider } from '@/components/navigation/NavigationTabs/Tabs.provider';
import { useActiveTab } from '@/components/navigation/NavigationTabs/useActiveTab';
import { Loader } from '@/components/progress/Loader/Loader';
import { useModalState } from '@/hooks/useModalState';
import { isFeatureFlagEnabled } from '@/modules/featureFlags/isFeatureFlagEnabled';
import { ClientProfileSummaryPanel_ClientProfileFragment } from '@/modules/summaryPanels/ClientProfileSummaryPanel/graphql/ClientProfileSummaryPanel.generated';
import { CreateHypotheticalEventButton } from '@/modules/summaryPanels/HypotheticalTransfersSummaryPanel/CreateHypotheticalEventButton';
import { sumDecimalJS } from '@/utils/decimalJSUtils';

import { useWaterfallRouteContext } from '../../contexts/waterfallRoute/waterfallRoute.context';
import { EntityDetail_EntityFragment } from '../graphql/EntityDetailPage.generated';
import { HypotheticalTransferModal } from '../HypotheticalTransferModal/HypotheticalTransferModal';
import { useGetHypotheticalTransfers } from './hooks/useGetHypotheticalTransfers';
import { TransferDirection } from './hypotheticalTransfers/HypotheticalTransferForm';
import { HypotheticalTransfersSummaryTable } from './HypotheticalTransfersSummaryTable';
import { TransferItems, TransferTab } from './TransferItems';

interface HypotheticalTransfersCardInnerViewState {
  showNoTransfersMessage: boolean;
  showLoader: boolean;
  showTransfers: boolean;
}

interface HypotheticalTransfersCardInnerProps {
  transferReferenceObj:
    | EntityDetail_EntityFragment
    | ClientProfileSummaryPanel_ClientProfileFragment;
  waterfallId: string;
  householdId: string;
  entityId: string;
}
function HypotheticalTransfersCardInner({
  transferReferenceObj,
  waterfallId,
  householdId,
  entityId,
}: HypotheticalTransfersCardInnerProps) {
  const isHypotheticalSaleLoanEnabled = isFeatureFlagEnabled(
    'hypothetical_sale_loan'
  );
  const [{ isModalOpen }, { closeModal, openModal }] = useModalState();
  const { activeTab, setActiveTab } = useActiveTab<TransferTab>({
    defaultTab: TransferTab.Outbound,
    filterSearchParam: 'transfer-direction',
  });

  const isEntity = transferReferenceObj.__typename === 'Entity';
  const isIndividual = transferReferenceObj.__typename === 'ClientProfile';

  let currentValue: Decimal | undefined = undefined;

  if (isEntity) {
    currentValue = transferReferenceObj.subtype.currentValue;
  } else if (isIndividual) {
    currentValue = sumDecimalJS(
      compact(transferReferenceObj.ownedOwnershipStakes)?.map(
        (stake) => stake.ownedValue
      )
    );
  }

  const { transfers: outboundTransfers, growthRate } =
    useGetHypotheticalTransfers({
      transferReferenceObjId: transferReferenceObj.id,
      waterfallId,
      sourceTransferReferenceObjId: transferReferenceObj.id,
      isEntity,
      isIndividual,
    });

  const { transfers: inboundTransfers } = useGetHypotheticalTransfers({
    transferReferenceObjId: transferReferenceObj.id,
    waterfallId,
    destinationTransferReferenceObjId: transferReferenceObj.id,
    isEntity,
    isIndividual,
  });

  const hasLoadedAllTransfers = !!(outboundTransfers && inboundTransfers);

  const transfers = {
    inboundTransfers: inboundTransfers ?? [],
    outboundTransfers: outboundTransfers ?? [],
  };

  const hasTransfers =
    transfers.inboundTransfers.length > 0 ||
    transfers.outboundTransfers.length > 0;

  const viewState: HypotheticalTransfersCardInnerViewState = {
    showNoTransfersMessage: !hasTransfers && hasLoadedAllTransfers,
    showLoader: !hasLoadedAllTransfers,
    showTransfers: hasTransfers && hasLoadedAllTransfers,
  };

  const AddATransferButton = useMemo(() => {
    if (isHypotheticalSaleLoanEnabled) {
      return (
        <CreateHypotheticalEventButton
          waterfallId={waterfallId}
          householdId={householdId}
          variant={viewState.showTransfers ? 'secondary' : 'primary'}
          disabled={!transferReferenceObj}
          size="lg"
          fullWidth
        />
      );
    }
    return (
      <Button
        fullWidth
        variant={viewState.showTransfers ? 'secondary' : 'primary'}
        size="lg"
        onClick={openModal}
        disabled={!transferReferenceObj}
        startIcon={PlusIcon}
      >
        Add hypothetical transfer
      </Button>
    );
  }, [
    householdId,
    isHypotheticalSaleLoanEnabled,
    openModal,
    transferReferenceObj,
    viewState.showTransfers,
    waterfallId,
  ]);

  return (
    <>
      {isModalOpen && transfers && (
        <HypotheticalTransferModal
          waterfallId={waterfallId}
          householdId={householdId}
          isOpen={isModalOpen}
          onClose={closeModal}
          initiatingId={entityId}
          initiatingDirection={
            activeTab === TransferTab.Outbound ||
            viewState.showNoTransfersMessage
              ? TransferDirection.Outbound
              : TransferDirection.Inbound
          }
        />
      )}
      <Loader
        boxProps={{
          sx: {
            textAlign: 'center',
            my: 3,
            display: viewState.showLoader ? undefined : 'none',
          },
        }}
      />
      <Stack
        p={viewState.showTransfers ? 0 : 3}
        py={0}
        spacing={viewState.showTransfers ? 3 : 6}
        display={viewState.showLoader ? 'none' : undefined}
      >
        {viewState.showNoTransfersMessage && (
          <EmptyListActionCard
            icon={Share06Icon}
            heading={`Specify transfers`}
            description={`No transfers have been created for this entity`}
            action={AddATransferButton}
          />
        )}
        <Stack display={viewState.showTransfers ? undefined : 'none'}>
          <TabsProvider currentTab={activeTab}>
            <Stack spacing={3}>
              <Tabs fullWidth>
                <ButtonTab
                  display="Outbound"
                  isActive={activeTab === TransferTab.Outbound}
                  onClick={() => setActiveTab(TransferTab.Outbound)}
                  badge={outboundTransfers?.length.toString()}
                />
                <ButtonTab
                  display="Inbound"
                  isActive={activeTab === TransferTab.Inbound}
                  onClick={() => setActiveTab(TransferTab.Inbound)}
                  badge={inboundTransfers?.length.toString()}
                />
              </Tabs>
              <TabContent tabKey={TransferTab.Outbound}>
                <TransferItems
                  transfers={transfers}
                  activeTab={TransferTab.Outbound}
                  transferReferenceObj={transferReferenceObj}
                  waterfallId={waterfallId}
                  householdId={householdId}
                  entityId={entityId}
                  growthRate={growthRate}
                />
              </TabContent>
              <TabContent tabKey={TransferTab.Inbound}>
                <TransferItems
                  transfers={transfers}
                  activeTab={TransferTab.Inbound}
                  transferReferenceObj={transferReferenceObj}
                  waterfallId={waterfallId}
                  householdId={householdId}
                  entityId={entityId}
                  growthRate={growthRate}
                />
              </TabContent>
            </Stack>
          </TabsProvider>
        </Stack>
        <HypotheticalTransfersSummaryTable
          currentValue={currentValue}
          transfers={transfers}
          isIndividual={isIndividual}
          isEntity={isEntity}
          growthRate={growthRate}
        />
        {!viewState.showNoTransfersMessage && AddATransferButton}
      </Stack>
    </>
  );
}

interface HypotheticalTransfersCardProps {
  entity?: EntityDetail_EntityFragment;
  individual?: ClientProfileSummaryPanel_ClientProfileFragment;
}

export function HypotheticalTransfersCard({
  entity,
  individual,
}: HypotheticalTransfersCardProps) {
  const { waterfallId } = useWaterfallRouteContext();

  const transferReferenceObj = entity ?? individual;

  if (!waterfallId || !transferReferenceObj) {
    return null;
  }

  return (
    <HypotheticalTransfersCardInner
      key={entity?.id ?? individual?.id ?? ''}
      transferReferenceObj={transferReferenceObj}
      waterfallId={waterfallId}
      householdId={transferReferenceObj?.household?.id ?? ''}
      entityId={entity?.id ?? individual?.id ?? ''}
    />
  );
}
