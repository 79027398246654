import { makeCreateStateTaxesInput } from '@/modules/entities/EntitySubforms/utils/entities/trusts.utils';
import { trustTaxStatusToApiTypeMap } from '@/modules/entities/EntitySubforms/utils/shared/taxStatus.utils';
import { getTaxStatusValuesFromEntityType } from '@/modules/entities/TaxStatusSubform/TaxStatusSubform.utils';
import { EntityType } from '@/modules/entities/types/EntityType';
import {
  AugmentedCreateCltTrustInput,
  AugmentedCreateCrtTrustInput,
  AugmentedCreateEntityInput,
  AugmentedCreateIlitTrustInput,
  AugmentedCreateIrrevocableTrustInput,
  AugmentedCreateQprtTrustInput,
  AugmentedCreateRevocableTrustInput,
  AugmentedCreateSlatTrustInput,
} from '@/types/schema';

import {
  CREATE_ENTITY_SHORT_FORM_NAMESPACE,
  EntityShortFormShape,
} from '../CreateEntityShortForm.types';
import {
  getCreateEntityInputCommonProps,
  getCreateSubtypeInputCommonProps,
  getSinglePrincipalInput,
  makeCreateAccountInput,
  makeCreateMultiplePrincipalInputs,
} from './CreateEntityShortForm.common';

export function makeCreateRevocableTrustInput(
  formInput: EntityShortFormShape,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withRevocableTrust: makeCreateTrustInput(formInput),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].stateTax
    ),
  };

  return input;
}

export function makeCreateIrrevocableTrustInput(
  formInput: EntityShortFormShape,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withIrrevocableTrust: makeCreateTrustInput(formInput),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].stateTax
    ),
  };

  return input;
}

export function makeCreateIlitTrustInput(
  formInput: EntityShortFormShape,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withIlitTrust: makeCreateTrustInput(formInput),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].stateTax
    ),
  };

  return input;
}

export function makeCreateSlatTrustInput(
  formInput: EntityShortFormShape,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: getCreateEntityInputCommonProps(formInput, householdId),
    withSlatTrust: makeCreateTrustInput(formInput),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].stateTax
    ),
  };

  return input;
}

export function makeCreateQprtTrustInput(
  formInput: EntityShortFormShape,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withQprtTrust: {
      ...makeCreateTrustInput(formInput),
    },
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].stateTax
    ),
  };

  return input;
}

export function makeCreateCltInput(
  formInput: EntityShortFormShape,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withCltTrust: makeCreateTrustInput(formInput),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].stateTax
    ),
  };

  return input;
}

export function makeCreateCrtInput(
  formInput: EntityShortFormShape,
  householdId: string
): AugmentedCreateEntityInput {
  const input: AugmentedCreateEntityInput = {
    create: {
      ...getCreateEntityInputCommonProps(formInput, householdId),
    },
    withCrtTrust: makeCreateTrustInput(formInput),
    withStateTaxes: makeCreateStateTaxesInput(
      formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].stateTax
    ),
  };

  return input;
}

type AugmentedCreateTrustCombinedType =
  | AugmentedCreateRevocableTrustInput
  | AugmentedCreateIrrevocableTrustInput
  | AugmentedCreateIlitTrustInput
  | AugmentedCreateSlatTrustInput
  | AugmentedCreateQprtTrustInput
  | AugmentedCreateCrtTrustInput
  | AugmentedCreateCltTrustInput;

function makeCreateTrustInput(
  formInput: EntityShortFormShape
): AugmentedCreateTrustCombinedType {
  const grantorProperties: Partial<AugmentedCreateTrustCombinedType> = (() => {
    if (formInput.createEntityShortForm.entityType === 'slat') {
      return {
        withGrantor: getSinglePrincipalInput(formInput),
      };
    }

    return {
      withGrantors: makeCreateMultiplePrincipalInputs(formInput),
    };
  })();

  const taxStatusFormDefaultValues = getTaxStatusValuesFromEntityType(
    formInput[CREATE_ENTITY_SHORT_FORM_NAMESPACE].entityType as EntityType
  );
  const input: AugmentedCreateTrustCombinedType = {
    create: {
      ...getCreateSubtypeInputCommonProps(formInput),
      taxStatus: taxStatusFormDefaultValues.taxStatus
        ? trustTaxStatusToApiTypeMap[taxStatusFormDefaultValues.taxStatus]
        : null,
    },
    ...grantorProperties,
    withDesignerAccount: makeCreateAccountInput(formInput),
  };

  return input;
}
