// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeleteLoggedTransferMutationVariables = Types.Exact<{
  deleteLoggedTransferId: Types.Scalars['ID']['input'];
}>;


export type DeleteLoggedTransferMutation = { __typename?: 'Mutation', deleteLoggedTransfer: string };


export const DeleteLoggedTransferDocument = gql`
    mutation DeleteLoggedTransfer($deleteLoggedTransferId: ID!) {
  deleteLoggedTransfer(id: $deleteLoggedTransferId)
}
    `;
export type DeleteLoggedTransferMutationFn = Apollo.MutationFunction<DeleteLoggedTransferMutation, DeleteLoggedTransferMutationVariables>;

/**
 * __useDeleteLoggedTransferMutation__
 *
 * To run a mutation, you first call `useDeleteLoggedTransferMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLoggedTransferMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLoggedTransferMutation, { data, loading, error }] = useDeleteLoggedTransferMutation({
 *   variables: {
 *      deleteLoggedTransferId: // value for 'deleteLoggedTransferId'
 *   },
 * });
 */
export function useDeleteLoggedTransferMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLoggedTransferMutation, DeleteLoggedTransferMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLoggedTransferMutation, DeleteLoggedTransferMutationVariables>(DeleteLoggedTransferDocument, options);
      }
export type DeleteLoggedTransferMutationHookResult = ReturnType<typeof useDeleteLoggedTransferMutation>;
export type DeleteLoggedTransferMutationResult = Apollo.MutationResult<DeleteLoggedTransferMutation>;
export type DeleteLoggedTransferMutationOptions = Apollo.BaseMutationOptions<DeleteLoggedTransferMutation, DeleteLoggedTransferMutationVariables>;
export const Operations = {
  Mutation: {
    DeleteLoggedTransfer: 'DeleteLoggedTransfer' as const
  }
}