import { compact, includes, map } from 'lodash';
import { useEffect } from 'react';

import { useFormContext } from '@/components/react-hook-form';

import {
  BasicAssetsSubformDirtyState,
  BasicAssetsSubformProperties,
  BasicAssetsSubformType,
  Fields,
  SubformField,
} from './BasicAssetsSubform.types';

/**
 This hook is responsible for syncing the dirty status of the local subform form to the state.

  We use formState.dirtyFields instead of formState.isDirty because formState.isDirty is global to the whole form,
  which could include other subforms. We only want `isDirty` to be true if the user has made changes to the assets subform.

  formState.dirtyFields will look like this:
    {
        basicAssetsSubform: { // first namespace
          basicAssets: { // second namespace
            fieldName: true,
            fieldName2: false,
          }
        }
    }
  */
type FormFieldsDirtinessMap = Record<
  keyof BasicAssetsSubformProperties,
  boolean
>;

export function useSyncDirtyStateToForm({
  namespace,
  secondNamespace,
  subformValues,
}: {
  namespace: keyof Fields;
  secondNamespace: keyof BasicAssetsSubformType;
  subformValues: BasicAssetsSubformProperties | undefined;
}) {
  const { formState, setValue } = useFormContext<Fields>();
  const namespacePath =
    `${namespace}.${secondNamespace}` as const satisfies SubformField;

  useEffect(
    function syncDirtyStatusToState() {
      const formFieldsDirtyState =
        formState.dirtyFields[namespace]?.[secondNamespace] ?? {};

      const dirtyFieldNames = compact(
        map(formFieldsDirtyState, (value, key) => {
          if (!value) return null;
          return key as keyof FormFieldsDirtinessMap;
        })
      );

      const currentDirtyState: BasicAssetsSubformDirtyState = (() => {
        if (dirtyFieldNames.length === 0) {
          return 'clean';
        }

        // description gets a special case because if it's a description-only update, then we want to
        // only update the existing valuation, not create a new one
        if (
          dirtyFieldNames.length === 1 &&
          dirtyFieldNames[0] === 'description'
        ) {
          return 'integrationOnlyDirty';
        }

        // if it's an integrated valuation, then we don't want
        // to update the valuation at all through this form
        const integrationDirtyFieldNames: typeof dirtyFieldNames = [
          `integrationEntityIds` as const,
        ];

        if (
          dirtyFieldNames.length === 1 &&
          includes(integrationDirtyFieldNames, dirtyFieldNames[0])
        ) {
          return 'descriptionOnlyDirty';
        }

        // finally, we know that relevant valuation fields have been updated
        return 'valuationFieldsDirty';
      })();

      // we don't want to infinitely be setting state, so here's the base case check
      if (subformValues?.dirtyState === currentDirtyState) return;
      setValue(
        `${namespacePath}.dirtyState` as const satisfies SubformField,
        currentDirtyState,
        { shouldDirty: false } // `dirtyState` is a special case where we don't want to dirty the form
      );
    },
    [
      formState,
      setValue,
      namespace,
      secondNamespace,
      namespacePath,
      subformValues?.dirtyState,
    ]
  );
}
