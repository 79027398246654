import { GridRowModel } from '@mui/x-data-grid-pro';
import { useMemo, useState } from 'react';

import { DataTable } from '@/components/tables/DataTable/DataTable';
import { LifetimeExclusionEventKind } from '@/types/schema';
import { UnreachableError } from '@/utils/errors';

import { LogNewGiftModal } from '../LogNewGiftModal/LogNewGiftModal';
import { RecordExemptionUsedModal } from '../RecordExemptionUsedModal/RecordExemptionUsedModal';
import {
  mapDataToRows,
  RowData,
  useColumns,
} from './ExemptionTrackingTable.utils';
import { ExemptionTrackingTable_LifetimeExclusionEventFragment } from './graphql/ExemptionTrackingTable.generated';

interface Props {
  lifetimeExclusionEvents: ExemptionTrackingTable_LifetimeExclusionEventFragment[];
  householdId: string;
}

const DEFAULT_MODAL_STATE = {
  kind: 'none',
} as const;

export function ExemptionTrackingTable({
  lifetimeExclusionEvents,
  householdId,
}: Props) {
  const [modalState, setModalState] = useState<{
    kind: 'gift' | 'exemption' | 'none';
    actionKind?: 'edit' | 'duplicate';
    lifetimeExclusionEventId?: string;
  }>(DEFAULT_MODAL_STATE);

  const handleEdit = (row: GridRowModel<RowData>) => {
    const kind = row.kind;
    const lifetimeExclusionEventId = row.id;

    switch (kind) {
      case LifetimeExclusionEventKind.Gift:
        setModalState({
          kind: 'gift',
          actionKind: 'edit',
          lifetimeExclusionEventId,
        });
        break;
      case LifetimeExclusionEventKind.Adjustment:
        setModalState({
          kind: 'exemption',
          actionKind: 'edit',
          lifetimeExclusionEventId,
        });
        break;
      default:
        throw new UnreachableError({
          case: kind,
          message: `Unrecognized exclusion event kind ${kind}`,
        });
    }
  };

  const handleDuplicate = (row: GridRowModel<RowData>) => {
    const kind = row.kind;
    const lifetimeExclusionEventId = row.id;

    switch (kind) {
      case LifetimeExclusionEventKind.Gift:
        setModalState({
          kind: 'gift',
          actionKind: 'duplicate',
          lifetimeExclusionEventId,
        });
        break;
      case LifetimeExclusionEventKind.Adjustment:
        setModalState({
          kind: 'exemption',
          actionKind: 'duplicate',
          lifetimeExclusionEventId,
        });
        break;
      default:
        throw new UnreachableError({
          case: kind,
          message: `Unrecognized exclusion event kind ${kind}`,
        });
    }
  };

  const rows = mapDataToRows(lifetimeExclusionEvents, { householdId });
  const { columns } = useColumns({ handleEdit, handleDuplicate });

  const actionModals = useMemo(() => {
    if (!modalState.lifetimeExclusionEventId) {
      // We want to wait to render the modal until we have the lifetimeExclusionEventId
      // because we need it to fetch the data for the modal
      return null;
    }

    return (
      <>
        <LogNewGiftModal
          isOpen={modalState.kind === 'gift'}
          onClose={() => {
            setModalState(DEFAULT_MODAL_STATE);
          }}
          householdId={householdId}
          lifetimeExclusionEventId={modalState.lifetimeExclusionEventId}
          isDuplicate={modalState.actionKind === 'duplicate'}
          isEdit={modalState.actionKind === 'edit'}
        />
        <RecordExemptionUsedModal
          isOpen={modalState.kind === 'exemption'}
          onClose={() => {
            setModalState(DEFAULT_MODAL_STATE);
          }}
          householdId={householdId}
          lifetimeExclusionEventId={modalState.lifetimeExclusionEventId}
          isDuplicate={modalState.actionKind === 'duplicate'}
          isEdit={modalState.actionKind === 'edit'}
        />
      </>
    );
  }, [householdId, modalState]);

  return (
    <>
      {actionModals}
      <DataTable
        columnVisibilityModel={{
          // Hide the 'kind' column because it's only used to determine which modal to use
          kind: false,
        }}
        columns={columns}
        rows={rows}
      />
    </>
  );
}
