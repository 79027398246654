import { HTMLAttributes } from 'react';
import { makeStyles } from 'tss-react/mui';

import { COLORS } from '@/styles/tokens/colors';
import { WithClasses } from '@/styles/types';

import { ROW_HEIGHT_PX, ROW_PADDING_PX } from './TileGroupItem';

// Show first three and a half items
const VIEW_ITEMS = 3.5;

const useStyles = makeStyles()((_theme) => ({
  root: {
    backgroundColor: COLORS.PRIMITIVES.WHITE,
    borderRadius: '6px',
  },
  // The combination of the border radius with the way the
  // browser renders the scrollbar causes text fuzziness
  // unless separated into two elements
  scroll: {
    maxHeight: `${(ROW_HEIGHT_PX + ROW_PADDING_PX * 2) * VIEW_ITEMS}px`,
    overflow: 'hidden',
    overflowY: 'auto',
  },
}));

export interface TileGroupProps extends HTMLAttributes<HTMLDivElement> {
  classes?: WithClasses<typeof useStyles>;
}

export const TileGroup = ({
  classes: externalClasses,
  ...props
}: TileGroupProps): JSX.Element => {
  const { classes } = useStyles(undefined, {
    props: { classes: externalClasses },
  });

  return (
    <div className={classes?.root}>
      <div className={classes?.scroll} {...props} />
    </div>
  );
};
