import { PaletteColor, Stack } from '@mui/material';
import { memo, useMemo } from 'react';

import { LegendLEGACY } from '@/components/charts/chartAccessories';
import { useColorChoices } from '@/components/charts/constants';

interface Props {
  justifyContent: 'start' | 'end';
  variant: 'squares' | 'circles';
}
function ChartLegendInner({ justifyContent, variant = 'circles' }: Props) {
  const colorChoices = useColorChoices();
  const legendValues = useMemo<[string, PaletteColor][]>(
    () => [
      ['In-estate assets', colorChoices.PRIMARY],
      ['Out-of-estate assets', colorChoices.SECONDARY],
      ['Estate tax', colorChoices.NEGATIVE],
    ],
    [colorChoices.PRIMARY, colorChoices.SECONDARY, colorChoices.NEGATIVE]
  );

  return (
    <Stack direction="row" justifyContent={justifyContent}>
      <LegendLEGACY legendValues={legendValues} variant={variant} />
    </Stack>
  );
}

export const ChartLegend = memo(ChartLegendInner);
