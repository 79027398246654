import { Grid, Typography } from '@mui/material';
import { useMemo, useState } from 'react';

import { SubpageLayout } from '@/components/architecture/Layout/SubpageLayout';
import { DropdownButton } from '@/components/form/baseInputs/DropdownButton/DropdownButton';
import { Markdown } from '@/components/Markdown/Markdown';
import { LexicalRenderer } from '@/components/TextEditor/LexicalRenderer';
import { TextEditor } from '@/components/TextEditor/TextEditor';
import { COLORS } from '@/styles/tokens/colors';

import { html, json, markdownString } from './TextEditorSandobx.utils';

interface HeaderProps {
  display: string;
}

function Header({ display }: HeaderProps) {
  return (
    <Typography
      height="74px"
      bgcolor={COLORS.GRAY[100]}
      justifyContent="center"
      display="flex"
      alignItems="center"
      variant="h3"
      borderRadius="4px"
    >
      {display}
    </Typography>
  );
}

export function TextEditorSandbox() {
  const [markdownState, setMarkdownState] = useState(markdownString);
  const [jsonState, setJsonState] = useState(json);
  const [htmlState, setHtmlState] = useState(html);
  const [format, setFormat] = useState<'markdown' | 'html' | 'json'>(
    'markdown'
  );

  const items = useMemo(() => {
    return [
      {
        name: 'Markdown',
        clickHandler: () => setFormat('markdown'),
      },
      {
        name: 'HTML',
        clickHandler: () => setFormat('html'),
      },
      {
        name: 'JSON',
        clickHandler: () => setFormat('json'),
      },
    ];
  }, []);

  const initialValue = useMemo(() => {
    switch (format) {
      case 'markdown':
        return markdownString;
      case 'html':
        return htmlState;
      case 'json':
        return json;
    }
  }, [format, htmlState]);

  return (
    <SubpageLayout
      heading="Text editor sandbox"
      actions={
        <DropdownButton
          name="Format"
          showArrow
          size="md"
          variant="secondary-filled"
          buttonContent={<Typography variant="body1">{format}</Typography>}
          items={items}
        />
      }
    >
      <Grid container spacing={3}>
        <Grid item sm={6}>
          <TextEditor
            key={format}
            initialValue={initialValue}
            initialValueKind={format}
            onUpdate={(str) => {
              switch (format) {
                case 'markdown':
                  setMarkdownState(str);
                  break;
                case 'html':
                  setHtmlState(str);
                  break;
                case 'json':
                  setJsonState(str);
                  break;
              }
            }}
          />
        </Grid>
        {format === 'markdown' && (
          <Grid item sm={6}>
            <Header display="Markdown preview" />
            <Markdown markdown={markdownState} />
          </Grid>
        )}
        {format === 'html' && (
          <Grid item sm={6}>
            <Header display="HTML preview" />
            <LexicalRenderer lexicalHtml={htmlState} />
          </Grid>
        )}
        {format === 'json' && (
          <Grid item sm={6}>
            <Header display="JSON preview" />
            <LexicalRenderer lexicalJson={jsonState} />
          </Grid>
        )}
      </Grid>
    </SubpageLayout>
  );
}
