import { QueryHookOptions } from '@apollo/client';
import { includes } from 'lodash';
import { useEffect, useState } from 'react';

import { useReportError } from '@/hooks/useReportError';
import { diagnostics } from '@/utils/diagnostics';

import { PENDING_ASYNC_JOB_STATUSES } from '../graphql/asyncJobs.constants';
import {
  AsyncJobStatus_AsyncJobFragment,
  AsyncJobStatusQuery,
  AsyncJobStatusQueryVariables,
  useAsyncJobStatusQuery,
} from '../graphql/AsyncJobs.generated';

export const DEFAULT_POLL_INTERVAL = 1000;

interface UsePollForJobCompletionOpts
  extends QueryHookOptions<AsyncJobStatusQuery, AsyncJobStatusQueryVariables> {
  onJobComplete?: (job: AsyncJobStatus_AsyncJobFragment) => void;
}

/**
 * @description usePollForJobCompletion is a hook that will poll for the completion of an async job
 * and call onJobComplete with the completed job when it's done.
 *
 * It also returns the initial job status (the status of the first non-null asyncJobDetails passed in) and
 * the most recently-polled job status, whether or not the job as been completed.
 */
export function usePollForJobCompletion(
  asyncJobDetails: AsyncJobStatus_AsyncJobFragment | null | undefined,
  {
    onJobComplete,
    onError,
    // the caller can pass skip if we know for certain we don't need to poll
    skip: skipAlways,
    ...otherQueryOpts
  }: UsePollForJobCompletionOpts
) {
  const { reportError } = useReportError();
  const [initialJobStatus, setInitialJobStatus] = useState(
    asyncJobDetails?.status ?? null
  );

  // if asyncJobDetails comes in as null initially, we need to sync that into state so we can use it
  // once it's present
  useEffect(() => {
    if (initialJobStatus === null && asyncJobDetails) {
      setInitialJobStatus(asyncJobDetails.status);
    }
  }, [asyncJobDetails, initialJobStatus]);

  const hasPendingIngest =
    includes(PENDING_ASYNC_JOB_STATUSES, initialJobStatus) ||
    // handle the case where a new in-progress asyncJobDetails is passed in after a job has been completed
    includes(PENDING_ASYNC_JOB_STATUSES, asyncJobDetails?.status);

  const currentJobId = asyncJobDetails?.id;

  const skipPolling = skipAlways || !hasPendingIngest || !currentJobId;
  const { startPolling, stopPolling, data } = useAsyncJobStatusQuery({
    variables: {
      // okay to assert here because we skip if this is falsy
      id: currentJobId!,
    },
    pollInterval: DEFAULT_POLL_INTERVAL,
    skip: skipPolling,
    // notifyOnNetworkStatusChange is required to rerun onComplete for each poll
    notifyOnNetworkStatusChange: true,
    onError: (err) => {
      reportError('failed to query for async job status', err);
      onError?.(err);
    },
    onCompleted: (data) => {
      if (data?.job?.__typename !== 'AsyncJob') return;
      diagnostics.debug('Polling for async job status', { job: data?.job });
      if (!includes(PENDING_ASYNC_JOB_STATUSES, data?.job?.status)) {
        diagnostics.debug('Async job completed', { job: data?.job });
        stopPolling();
        onJobComplete?.(data?.job);
      }
    },
    ...otherQueryOpts,
  });

  // this is handling both the initial call, where there's no data, and the silly typescript scenario
  // where it doesn't know the type of the node
  if (data?.job?.__typename !== 'AsyncJob') {
    return {
      initialJobStatus,
      mostRecentJobStatus: initialJobStatus,
    };
  }

  const activeJobStatus = data.job.status;
  return {
    initialJobStatus,
    mostRecentJobStatus: activeJobStatus ?? initialJobStatus,
    startPolling,
  };
}
