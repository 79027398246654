// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../types/schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateClientPresentationMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateClientPresentationInput;
}>;


export type CreateClientPresentationMutation = { __typename?: 'Mutation', createClientPresentation: { __typename?: 'ClientPresentation', id: string } };


export const CreateClientPresentationDocument = gql`
    mutation CreateClientPresentation($input: AugmentedCreateClientPresentationInput!) {
  createClientPresentation(input: $input) {
    id
  }
}
    `;
export type CreateClientPresentationMutationFn = Apollo.MutationFunction<CreateClientPresentationMutation, CreateClientPresentationMutationVariables>;

/**
 * __useCreateClientPresentationMutation__
 *
 * To run a mutation, you first call `useCreateClientPresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientPresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientPresentationMutation, { data, loading, error }] = useCreateClientPresentationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClientPresentationMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientPresentationMutation, CreateClientPresentationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientPresentationMutation, CreateClientPresentationMutationVariables>(CreateClientPresentationDocument, options);
      }
export type CreateClientPresentationMutationHookResult = ReturnType<typeof useCreateClientPresentationMutation>;
export type CreateClientPresentationMutationResult = Apollo.MutationResult<CreateClientPresentationMutation>;
export type CreateClientPresentationMutationOptions = Apollo.BaseMutationOptions<CreateClientPresentationMutation, CreateClientPresentationMutationVariables>;
export const Operations = {
  Mutation: {
    CreateClientPresentation: 'CreateClientPresentation' as const
  }
}