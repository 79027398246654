import { useEffect } from 'react';

import { FormAwareSelectInput } from '@/components/form/formAwareInputs/FormAwareSelectInput';
import { Card } from '@/components/layout/Card/Card';
import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { useFormContext } from '@/components/react-hook-form';

import { PolicyOption } from '../../HypotheticalTransfersCard/hypotheticalTransfers/hooks/usePolicyOptions';
import { useHypotheticalTransferModalContext } from '../HypotheticalTransferModal.context';
import { HypotheticalTransferFormModalShape } from '../HypotheticalTransferModal.types';

interface PolicyOptionsProps {
  options: PolicyOption[];
  disabled: boolean;
  entityId: string;
  hide: boolean;
}

export function PolicyOptions({
  options,
  disabled,
  entityId,
  hide,
}: PolicyOptionsProps) {
  const {
    transferId,
    derivedData: { policiesByEntityId },
  } = useHypotheticalTransferModalContext();
  const { control, resetField } =
    useFormContext<HypotheticalTransferFormModalShape>();

  useEffect(
    function setDefaultPolicy() {
      if (transferId) return;

      const policiesForEntity = policiesByEntityId[entityId] || [];
      const defaultInsurancePolicyId =
        policiesForEntity[0]?.sourceInsurancePolicyId;

      if (policiesForEntity.length === 1 && defaultInsurancePolicyId && !hide) {
        resetField('sourceInsurancePolicyId', {
          defaultValue: defaultInsurancePolicyId,
        });
      }
    },
    [entityId, hide, policiesByEntityId, resetField, transferId]
  );

  return (
    <FormLayoutRow
      sx={{
        display: hide ? 'none' : undefined,
      }}
    >
      <Card variant="filled-dark" sx={{ p: 2, gridColumn: '1 / -1' }}>
        <FormLayoutRow>
          <FormLayoutItem>
            <FormAwareSelectInput<HypotheticalTransferFormModalShape>
              control={control}
              fieldName={
                'sourceInsurancePolicyId' as const satisfies keyof HypotheticalTransferFormModalShape
              }
              label="Policy"
              options={options}
              showEmptyValue={false}
              disabled={disabled}
              required={!hide}
            />
          </FormLayoutItem>
        </FormLayoutRow>
      </Card>
    </FormLayoutRow>
  );
}
