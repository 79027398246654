import Decimal from 'decimal.js';
import { createContext, Dispatch, SetStateAction } from 'react';

import { useGuardedContext } from '@/hooks/useGuardedContext';

export interface TestamentaryEntityDetailsContext {
  entityId: string | null;
  setEntityId: Dispatch<
    SetStateAction<TestamentaryEntityDetailsContext['entityId']>
  >;
  totalMarketValue: Decimal;
  setTotalMarketValue: Dispatch<
    SetStateAction<TestamentaryEntityDetailsContext['totalMarketValue']>
  >;
}

export const TestamentaryEntityDetailsContext =
  createContext<TestamentaryEntityDetailsContext>({
    entityId: null,
    setEntityId: () => null,
    totalMarketValue: new Decimal(0),
    setTotalMarketValue: () => null,
  });

export const useTestamentaryEntityDetailsContext = () => {
  return useGuardedContext(
    TestamentaryEntityDetailsContext,
    'TestamentaryEntityDetailsProvider'
  );
};
