import { Stack, Typography } from '@mui/material';
import { compact } from 'lodash';
import { useMemo } from 'react';

import { Loader } from '@/components/progress/Loader/Loader';
import { AIOnboardingModalStep } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModal.types';
import { AIOnboardingModalFormShape } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalForm.types';
import { AIOnboardingModalFormTable } from '@/modules/aiOnboarding/AIOnboardingModal/AIOnboardingModalForm/AIOnboardingModalFormTable';
import { useAIOnboardingModalContext } from '@/modules/aiOnboarding/AIOnboardingModal/context/AIOnboardingModal.context';
import { ClientMultiDocumentUploader } from '@/modules/aiOnboarding/components/ClientMultiDocumentUploader';
import { PathsOf } from '@/types/subform';
import { UnreachableError } from '@/utils/errors';

export function AIOnboardingModalRightContent() {
  const {
    loading,
    activeStep,
    suggestionsByFormPath: suggestions,
    householdId,
  } = useAIOnboardingModalContext();

  const { title, subtitle, tableConfigs } = useMemo(() => {
    if (!activeStep) {
      // This is the default/empty/loading state
      return {
        title: '',
        subtitle: '',
        tableConfigs: [],
      };
    }

    switch (activeStep) {
      case AIOnboardingModalStep.NO_SUGGESTIONS:
        return {
          title: 'Nothing could be identified from documents',
          subtitle:
            'Upload additional documents and Luminary AI will help build out the client profile',
          tableConfigs: [],
        };
      case AIOnboardingModalStep.INDIVIDUALS_AND_ORGANIZATIONS:
        return {
          title: getTitle([
            {
              count: suggestions.individuals.length,
              nounSingular: 'individual',
              nounPlural: 'individuals',
            },
            {
              count: suggestions.organizations.length,
              nounSingular: 'organization',
              nounPlural: 'organizations',
            },
          ]),
          subtitle:
            'Verify the accuracy of the identified individuals and/or organizations below',
          tableConfigs: compact([
            suggestions.individuals.length && {
              header: 'Individuals',
              formPath:
                'individuals' as const satisfies PathsOf<AIOnboardingModalFormShape>,
            },
            suggestions.organizations.length && {
              header: 'Organizations',
              formPath:
                'organizations' as const satisfies PathsOf<AIOnboardingModalFormShape>,
            },
          ]),
        };
      case AIOnboardingModalStep.ENTITIES:
        return {
          title: getTitle([
            {
              count: suggestions.entities.length,
              nounSingular: 'entity',
              nounPlural: 'entities',
            },
            {
              count: suggestions.testamentaryEntities.length,
              nounSingular: 'testamentary entity',
              nounPlural: 'testamentary entities',
            },
          ]),
          subtitle: 'Verify the accuracy of the identified entities below',
          tableConfigs: compact([
            suggestions.entities.length && {
              header: 'Entities',
              formPath:
                'entities' as const satisfies PathsOf<AIOnboardingModalFormShape>,
            },
            suggestions.testamentaryEntities.length && {
              header: 'Testamentary Entities',
              formPath:
                'testamentaryEntities' as const satisfies PathsOf<AIOnboardingModalFormShape>,
            },
          ]),
        };
      default:
        throw new UnreachableError({
          case: activeStep,
          message: 'unknown activeStep in AIOnboardingModalRightContent',
        });
    }
  }, [activeStep, suggestions]);

  if (loading || !activeStep) {
    return (
      <Stack
        p={3}
        pb={4}
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Stack>
    );
  }

  return (
    <Stack p={3} pb={4} gap={3} height="100%" sx={{ overflowY: 'scroll' }}>
      <Stack gap={1}>
        <Typography variant="h1">{title}</Typography>
        <Typography>{subtitle}</Typography>
      </Stack>
      {activeStep === AIOnboardingModalStep.NO_SUGGESTIONS ? (
        <ClientMultiDocumentUploader householdId={householdId} hideLabel />
      ) : (
        tableConfigs.map((configs) => {
          return (
            <AIOnboardingModalFormTable key={configs.formPath} {...configs} />
          );
        })
      )}
    </Stack>
  );
}

function getTitle(
  parts: { count: number; nounSingular: string; nounPlural: string }[]
): string {
  const things = parts
    .filter((part) => part.count > 0)
    .map((part) => {
      return `${part.count} ${part.count > 1 ? part.nounPlural : part.nounSingular}`;
    })
    .join(' & ');

  return `${things} identified`;
}
