// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
export type AssetV2Fragment = { __typename?: 'AssetV2', id: string, displayName: string, qsbsEligibility: Types.AssetV2QsbsEligibility, class: { __typename?: 'AssetClass', id: string, displayName: string }, assetValue: { __typename?: 'AssetValueV2', id: string, value: CurrencyUSD, ownershipType: Types.AssetValueV2OwnershipType, ownedValue?: CurrencyUSD | null, totalValue?: CurrencyUSD | null, ownedPercent?: Percent | null, shareCount?: GraphQLDecimal | null, shareValue?: CurrencyUSD | null } };

export const AssetV2FragmentDoc = gql`
    fragment AssetV2 on AssetV2 {
  id
  displayName
  qsbsEligibility
  class {
    id
    displayName
  }
  assetValue {
    id
    value
    ownershipType
    ownedValue
    totalValue
    ownedPercent
    shareCount
    shareValue
  }
}
    `;
export const Operations = {
  Fragment: {
    AssetV2: 'AssetV2' as const
  }
}