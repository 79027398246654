import { Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { Fragment, useCallback, useMemo } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { InPageNavigationBox } from '@/components/navigation/InPageNavigation/InPageNavigationBox';
import {
  FeedbackMessages,
  useFeedback,
} from '@/components/notifications/Feedback/useFeedback';
import { useFormContext } from '@/components/react-hook-form';
import { useForm, useSubmitSuccessHandler } from '@/components/react-hook-form';
import { diagnostics } from '@/utils/diagnostics';
import { formatEnumCase } from '@/utils/formatting/strings';

import { useEstateTaxScenariosContext } from './contexts/estateTaxScenarios.context';
import {
  mapFormDataToInput,
  mapInputToFormData,
} from './EstateTaxScenarios.utils';
import { PaymentMethodForm } from './forms/PaymentMethodForm/PaymentMethodForm';
import { RemainingExemptionsForm } from './forms/RemainingExemptionsForm/RemainingExemptionsForm';
import { useUpdateEstateTaxScenariosMutation } from './graphql/EstateTaxScenarios.generated';
import { EstateTaxScenarioFormData } from './types';

const contentContainerId = 'estate-tax-scenarios-container';
enum FORM_SECTIONS {
  REMAINING_EXEMPTIONS = 'REMAINING_EXEMPTIONS',
  ESTATE_TAX_PAYMENT_METHOD = 'ESTATE_TAX_PAYMENT_METHOD',
}

interface FormSectionComponentMap {
  section: FORM_SECTIONS;
  component: typeof PaymentMethodForm;
}

const FORM_SECTION_COMPONENTS: FormSectionComponentMap[] = [
  {
    section: FORM_SECTIONS.REMAINING_EXEMPTIONS,
    component: RemainingExemptionsForm,
  },
  {
    section: FORM_SECTIONS.ESTATE_TAX_PAYMENT_METHOD,
    component: PaymentMethodForm,
  },
];

export interface EstateTaxScenariosProps {
  onCancel?: () => void;
}

export const EstateTaxScenariosInner = ({
  onCancel,
}: EstateTaxScenariosProps) => {
  const theme = useTheme();
  const estateTaxContext = useEstateTaxScenariosContext();
  const { showFeedback, createSuccessFeedback } = useFeedback();
  const { household, activeClientId, primaryClientsById } = estateTaxContext;
  const activePrimaryClient = primaryClientsById[activeClientId];
  const { control, handleSubmit } = useFormContext<EstateTaxScenarioFormData>();
  const { fields } = useFieldArray({ control, name: 'scenarios' });

  const rows = useMemo(() => {
    return fields.flatMap((field) => {
      if (field.firstGrantorDeathID !== activeClientId) return [];
      return Object.values(FORM_SECTIONS).map((s) => ({
        id: `${s}-${field.id}`,
        label: formatEnumCase(s),
      }));
    });
  }, [activeClientId, fields]);

  const [updateMutation, { loading }] = useUpdateEstateTaxScenariosMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
    onError: (error) => {
      showFeedback(FeedbackMessages.formSaveError);
      diagnostics.error(
        `could not save estate tax scenarios: ${household.id}`,
        error
      );
    },
    onCompleted: createSuccessFeedback(
      `Calculation method saved successfully!`
    ),
  });

  const onSubmit = handleSubmit((formData) => {
    return updateMutation({
      variables: {
        inputs: [mapFormDataToInput({ formData, context: estateTaxContext })],
      },
    });
  });

  const handleClickCloseButton = useCallback(() => {
    onCancel?.();
  }, [onCancel]);

  useSubmitSuccessHandler(() => {
    onCancel?.();
  });

  return (
    <Stack
      component="form"
      height="100%"
      noValidate
      onSubmit={onSubmit}
      pb={`${FOOTER_HEIGHT}px`}
    >
      <Stack direction="row" width="100%" height="100%" minHeight="100%">
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            p: 6,
            flexBasis: '500px',
            flexGrow: 0,
            boxShadow: theme.palette.shadows.innerShadowLeftward,
          }}
        >
          <Stack>
            <Box sx={{ mb: 3 }}>
              <Typography variant="h1">Customize tax settings</Typography>
              <Typography variant="body1">
                If{' '}
                {activePrimaryClient?.displayName ?? <Skeleton width={150} />}{' '}
                dies first
              </Typography>
            </Box>
            <InPageNavigationBox
              sx={{ pb: 6, mr: -6 }}
              rows={rows}
              observerOptions={{
                rootMargin: `0px 0px ${FOOTER_HEIGHT}px 0px`,
              }}
              scrollOptions={{ block: 'start' }}
              contentContainerId={contentContainerId}
            />
          </Stack>
        </Box>
        <Box
          p={3}
          display="flex"
          justifyContent="center"
          flexGrow={1}
          overflow="auto"
        >
          <Stack
            id={contentContainerId}
            spacing={5}
            sx={{
              // adds padding to the last child element inside this to create space between it and the footer
              '>:last-child': {
                pb: 3,
              },
            }}
          >
            {fields.map((field, i) => {
              const identifier = `scenarios.${i}` as const;
              if (field.firstGrantorDeathID !== activeClientId) return null;

              return (
                <Fragment key={field.id}>
                  {FORM_SECTION_COMPONENTS.map(
                    ({ section, component: SubForm }) => {
                      const id = `${section}-${field.id}`;
                      return (
                        <div key={id} id={id}>
                          <SubForm field={field} identifier={identifier} />
                        </div>
                      );
                    }
                  )}
                </Fragment>
              );
            })}
          </Stack>
        </Box>
      </Stack>
      <Box flexShrink={0}>
        <Footer
          rightAction={
            <Stack direction="row" spacing={2}>
              <Button
                variant="secondary"
                size="lg"
                disabled={loading}
                onClick={handleClickCloseButton}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                size="lg"
                loading={loading}
                type="submit"
              >
                Save calculation method
              </Button>
            </Stack>
          }
        />
      </Box>
    </Stack>
  );
};

export const EstateTaxScenarios = (props: EstateTaxScenariosProps) => {
  const context = useEstateTaxScenariosContext();
  const formMethods = useForm<EstateTaxScenarioFormData>({
    defaultValues: mapInputToFormData({ context }),
  });

  return (
    <FormProvider {...formMethods}>
      <EstateTaxScenariosInner {...props} />
    </FormProvider>
  );
};
