import { Stack, Typography, useTheme } from '@mui/material';

import { Button } from '@/components/form/baseInputs/Button';
import { FlipBackwardIcon } from '@/components/icons/FlipBackwardIcon';
import { COLORS } from '@/styles/tokens/colors';

interface AdvisorPreviewIndicatorProps {
  onClick: () => void;
  disabled?: boolean;
}

export const ADVISOR_PREVIEW_INDICATOR_HEIGHT = 7.5;

export function AdvisorPreviewIndicator({
  onClick,
  disabled,
}: AdvisorPreviewIndicatorProps) {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      px={3}
      textAlign="right"
      height={theme.spacing(ADVISOR_PREVIEW_INDICATOR_HEIGHT)}
      flexShrink={0}
      bgcolor="white"
      borderLeft={`solid ${COLORS.ORANGE[400]} 4px`}
      boxShadow={theme.palette.shadows.mdInsetInvert}
    >
      <Typography variant="h5">
        This is how your proposal will appear to your client
      </Typography>
      <Button
        startIcon={FlipBackwardIcon}
        onClick={onClick}
        disabled={disabled}
        size="sm"
        variant="primary"
      >
        Return to builder view
      </Button>
    </Stack>
  );
}
