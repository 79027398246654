import { Stack, Typography } from '@mui/material';

import { Callout } from '@/components/notifications/Callout/Callout';

export function EntityImportTableHeader() {
  return (
    <Stack direction="column" spacing={3} sx={{ pb: 3 }}>
      <Typography variant="body1">
        The following entities exist in Addepar but have not been linked to a
        Luminary entity. If the entity already exists in Luminary, you can link
        the entity to Addepar from the entity overview.
      </Typography>
      <Callout severity="info-high">
        Select each item you wish to import as a new entity in Luminary.
        Selecting items nested below a top level row will create separate
        entities for each.
      </Callout>
    </Stack>
  );
}
