import { compact } from 'lodash';

import { LoadingSkeletonOrValue } from '@/components/progress/LoadingSkeletonOrValue/LoadingSkeletonOrValue';
import { SummaryTable } from '@/components/tables/SummaryTable';
import { getTrusteeDisplayNames } from '@/modules/trustees/trusteeUtils';
import { formatDateToMonDDYYYY } from '@/utils/formatting/dates';

import { TrustRemainderTask_GratTrustFragment } from './graphql/GRATDistributeTrustRemainderTask.generated';

interface DistributeTrustRemainderTaskSummaryProps {
  gratTrust: TrustRemainderTask_GratTrustFragment | null;
  loading: boolean;
}

export function DistributeTrustRemainderTaskSummary({
  gratTrust,
  loading,
}: DistributeTrustRemainderTaskSummaryProps) {
  return (
    <SummaryTable
      rows={compact([
        {
          label: 'GRAT name',
          value: (
            <LoadingSkeletonOrValue
              loading={loading}
              value={gratTrust?.displayName}
            />
          ),
        },
        {
          label: 'Grantor',
          value: (
            <LoadingSkeletonOrValue
              loading={loading}
              value={gratTrust?.grantor?.individual?.displayName}
            />
          ),
        },
        {
          label: 'GRAT trustee',
          value: (
            <LoadingSkeletonOrValue
              loading={loading}
              value={getTrusteeDisplayNames(gratTrust?.trustees).join(', ')}
            />
          ),
        },
        {
          label: 'Latest valuation date',
          value: (
            <LoadingSkeletonOrValue
              loading={loading}
              value={(() => {
                const effectiveDate = gratTrust?.mostRecentValuationDate;
                return effectiveDate
                  ? formatDateToMonDDYYYY(effectiveDate)
                  : undefined;
              })()}
            />
          ),
        },
      ])}
    />
  );
}
