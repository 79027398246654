function stringify(params: BlackDiamondQueryParams): string {
  return new URLSearchParams(params).toString();
}

export type BlackDiamondQueryParams = {
  redirect: string;
} & Record<string, string>;

export const getBlackDiamondIntegrationLoginPath = (
  queryParams: BlackDiamondQueryParams
): string => `/api/v1/blackdiamond/auth/initiate?${stringify(queryParams)}`;

export const getBlackDiamondIntegrationLogoutPath = (
  queryParams: BlackDiamondQueryParams
): string => `/api/v1/blackdiamond/auth/logout?${stringify(queryParams)}`;
