import { ComposeStateFn } from '@/utils/reducerUtils';

import { createFamilyTreeGraph } from '../familyTreeGraph';
import { FamilyTreeState } from '../types';

export const rebuildFamilyTreeGraph =
  (): ComposeStateFn<FamilyTreeState> => (state) => {
    const graph = createFamilyTreeGraph(state);
    const nodes = graph.getNodes();
    const edges = graph.getEdges();

    return { ...state, graph, nodes, edges };
  };
