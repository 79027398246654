import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';

import { RowData } from '../AdminBalanceSheetConfigurationPage.types';

interface ReorderableRowsContextType {
  reorderableRows: RowData[];
  setReorderableRows: Dispatch<SetStateAction<RowData[]>>;
}

const ReorderableRowsContext = createContext<
  ReorderableRowsContextType | undefined
>(undefined);

export const useReorderableRows = () => {
  const context = useContext(ReorderableRowsContext);
  if (!context) {
    throw new Error(
      'useReorderableRows must be used within a ReorderableRowsProvider'
    );
  }
  return context;
};

interface ReorderableRowsProviderProps {
  children: React.ReactNode;
  reorderableRows: RowData[];
  setReorderableRows: Dispatch<SetStateAction<RowData[]>>;
}

export const ReorderableRowsProvider: React.FC<
  ReorderableRowsProviderProps
> = ({ children, reorderableRows, setReorderableRows }) => {
  return (
    <ReorderableRowsContext.Provider
      value={{ reorderableRows, setReorderableRows }}
    >
      {children}
    </ReorderableRowsContext.Provider>
  );
};
