import { DispositiveProvisionTemplateKind } from '@/types/schema';

import {
  DispositiveProvisionsFormShape,
  Recipient,
} from '../DispositiveProvisionsForm/DispositiveProvisionsForm.types';

export const NEW_TEMPLATE_SENTINEL = '__NEW_TEMPLATE__';

export const DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE =
  'dispositiveProvisionsTemplate';

interface DispositiveProvisionExistingTemplateDetails {
  templateId: string;
  templateType?: never;
  recipients?: Recipient[];
}

interface DispositiveProvisionNewTemplateDetails {
  templateId: typeof NEW_TEMPLATE_SENTINEL;
  templateType: DispositiveProvisionTemplateKind;
  recipients?: Recipient[];
}

export type DispositiveProvisionTemplateDetails =
  | DispositiveProvisionNewTemplateDetails
  | DispositiveProvisionExistingTemplateDetails;

export enum MaritalTrustType {
  None = 'none',
  DisclaimerTrust = 'disclaimer',
  ClaytonQTIPTrust = 'claytonQtipTrust',
}

export interface DispositiveProvisionsTemplateShape
  extends DispositiveProvisionsFormShape {
  [DISPOSITIVE_PROVISIONS_TEMPLATE_FORM_NAMESPACE]: {
    id: string;
    displayName: string;
    documentId?: string;
    /**
     * For new templates, this can be any type.  For existing templates, this should be limited
     * to `DispositiveProvisionsTemplateType.POUROVER_WILL` or `DispositiveProvisionsTemplateType.NO_TEMPLATE`,
     * as editing non-pour-over templates will show the user the full set of options.
     */
    templateType: DispositiveProvisionTemplateKind;
    includeDisclaimerTrust: boolean;
    includeSurvivorsTrust: boolean;
    maritalTrustType: MaritalTrustType;
  };
}

export interface DispositiveProvisionsTemplateLinkedEntryRow {
  lineOne: string;
  lineTwo: string;
  id: string;
  onClick: () => void;
}
