import { SelectInputOption } from '@/components/form/baseInputs/inputTypes';
import { ClientOrganizationKind } from '@/types/schema';

export interface ClientOrganizationFormShape {
  name: string;
  kind: ClientOrganizationKind | '';
  isTrustee: boolean;
  isBeneficiary: boolean;
  address: {
    cityName: string;
    stateCode: string;
  };
  pointOfContactEmail: string;
  pointOfContactPhone: string;
  pointOfContactName: string;
  pointOfContactLastName: string;
}

export const defaultValues: ClientOrganizationFormShape = {
  name: '',
  kind: '',
  isTrustee: false,
  isBeneficiary: false,
  address: {
    cityName: '',
    stateCode: '',
  },
  pointOfContactEmail: '',
  pointOfContactPhone: '',
  pointOfContactName: '',
  pointOfContactLastName: '',
};

export const CLIENT_ORGANIZATION_KIND_OPTIONS: SelectInputOption[] = [
  {
    display: 'Charitable organization',
    value: ClientOrganizationKind.CharitableOrganization,
  },
  {
    display: 'Corporate trustee',
    value: ClientOrganizationKind.CorporateTrustee,
  },
  {
    display: 'Other',
    value: ClientOrganizationKind.Other,
  },
];
