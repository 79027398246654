import { Stack, useTheme } from '@mui/material';
import { useEffect, useMemo } from 'react';

import { ActionTile } from '@/components/layout/ActionTile/ActionTile';
import { diagnostics } from '@/utils/diagnostics';

import { useDispositiveProvisionsContext } from '../contexts/dispositiveProvisions.context';
import { DispositionScheme } from '../dispositiveProvisions.types';
import { useTwoGrantorClientDispositionScheme } from '../hooks/useTwoGrantorClientDispositionScheme';
import SingleGrantorNoneIcon from './icons/SingleGrantorNoneIcon.svg';
import SingleGrantorUponDeathIcon from './icons/SingleGrantorUponDeathIcon.svg';
import TwoGrantorFirstDeathIcon from './icons/TwoGrantorFirstDeathIcon.svg';
import TwoGrantorNoneIcon from './icons/TwoGrantorNoneIcon.svg';
import TwoGrantorSecondDeathIcon from './icons/TwoGrantorSecondDeathIcon.svg';

export interface DispositionSchemeSelectorProps {
  onChange: (value: DispositionScheme) => void;
  // hideNoneOption is relevant when asking the user to select a disposition scheme
  // after already setting up dispositions. in that case, the "none" option is better represented by the user
  // removing all of the existing dispositions by hand
  hideNoneOption?: boolean;
}

export function DispositionSchemeSelector({
  onChange,
  hideNoneOption,
}: DispositionSchemeSelectorProps) {
  const twoGrantorClientDispositionScheme =
    useTwoGrantorClientDispositionScheme();
  const { isTwoClientHousehold, isClientProfile, householdId } =
    useDispositiveProvisionsContext();
  const theme = useTheme();
  const actionTileSharedProps = useMemo(
    () => ({
      variant: 'small' as const,
      containerSx: {
        px: `${theme.spacing(6)} !important`,
        minWidth: 200,
        flexGrow: 1,
      },
    }),
    [theme]
  );

  useEffect(() => {
    // make sure we're aware of any situations where this component is rendering in an invalid state
    if (!isTwoClientHousehold && hideNoneOption) {
      diagnostics.error(
        'invalid state: rendering single-item DispositionSchemeSelector',
        new Error('Invalid state'),
        {
          householdId,
        }
      );
    }
  }, [hideNoneOption, householdId, isTwoClientHousehold]);

  const selectorRenderMode = (() => {
    if (isTwoClientHousehold && !isClientProfile) return 'ALL_OPTIONS';
    if (!isTwoClientHousehold) return 'SINGLE_GRANTOR';
    return 'TWO_CLIENT_HOUSEHOLD_GRANTOR_DEATH';
  })();

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      {selectorRenderMode === 'ALL_OPTIONS' && (
        <>
          <ActionTile
            heading="1st death only"
            icon={<TwoGrantorFirstDeathIcon />}
            onClick={() => onChange(DispositionScheme.UPON_FIRST_DEATH)}
            {...actionTileSharedProps}
          />
          <ActionTile
            heading="2nd death only"
            icon={<TwoGrantorSecondDeathIcon />}
            onClick={() => onChange(DispositionScheme.UPON_SECOND_DEATH)}
            {...actionTileSharedProps}
          />
          {!hideNoneOption && (
            <ActionTile
              heading="No dispositions"
              icon={<TwoGrantorNoneIcon />}
              onClick={() => onChange(DispositionScheme.NONE)}
              {...actionTileSharedProps}
            />
          )}
        </>
      )}
      {selectorRenderMode === 'SINGLE_GRANTOR' && (
        <>
          <ActionTile
            heading="Upon death"
            icon={<SingleGrantorUponDeathIcon />}
            onClick={() => onChange(DispositionScheme.UPON_FIRST_DEATH)}
            {...actionTileSharedProps}
          />
          {!hideNoneOption && (
            <ActionTile
              heading="No dispositions"
              icon={<SingleGrantorNoneIcon />}
              onClick={() => onChange(DispositionScheme.NONE)}
              {...actionTileSharedProps}
            />
          )}
        </>
      )}
      {selectorRenderMode === 'TWO_CLIENT_HOUSEHOLD_GRANTOR_DEATH' && (
        <>
          <ActionTile
            heading="Upon death"
            icon={<SingleGrantorUponDeathIcon />}
            onClick={() =>
              onChange(
                // the nullish handling should never happen here
                twoGrantorClientDispositionScheme ??
                  DispositionScheme.UPON_FIRST_DEATH
              )
            }
            {...actionTileSharedProps}
          />
          {!hideNoneOption && (
            <ActionTile
              heading="No dispositions"
              icon={<SingleGrantorNoneIcon />}
              onClick={() => onChange(DispositionScheme.NONE)}
              {...actionTileSharedProps}
            />
          )}
        </>
      )}
    </Stack>
  );
}
