import { invert } from 'lodash';

import { ClientPresentationSection } from '@/types/schema';

export enum ClientPresentationStandaloneSlideType {
  ENTITIES_LIST = 'ENTITIES_LIST',
  PROFESSIONAL_TEAM = 'PROFESSIONAL_TEAM',
  BALANCE_SHEET = 'BALANCE_SHEET',

  COVER = 'COVER',
  DISCLAIMER = 'DISCLAIMER',
}

export const STANDALONE_SLIDE_TYPE_TO_PRESENTATION_SECTION = {
  [ClientPresentationStandaloneSlideType.ENTITIES_LIST]:
    ClientPresentationSection.AllEntitiesSlide,
  [ClientPresentationStandaloneSlideType.PROFESSIONAL_TEAM]:
    ClientPresentationSection.ProfessionalTeam,
  [ClientPresentationStandaloneSlideType.BALANCE_SHEET]:
    ClientPresentationSection.BalanceSheet,
};

export const PRESENTATION_SECTION_TO_STANDALONE_SLIDE_TYPE: Partial<
  Record<ClientPresentationSection, ClientPresentationStandaloneSlideType>
> = invert(STANDALONE_SLIDE_TYPE_TO_PRESENTATION_SECTION);

export type StringArrayOrNull = string[] | null;

export interface SetInitialItemVisibilityParams {
  shouldShowCoverSlide: boolean;
  visibleEntityPulids: StringArrayOrNull;
  visibleWaterfallPulids: StringArrayOrNull;
  sectionOrder: ClientPresentationSection[];
}

// the ClientPresentationIdentifier is either going to be a PULID (in the case of estate waterfalls and entities)
// or a ClientPresentationStandaloneSlideType (in the case of standalone slides)
export type ClientPresentationIdentifier =
  | string
  | ClientPresentationStandaloneSlideType;

type SectionSlideType =
  keyof typeof STANDALONE_SLIDE_TYPE_TO_PRESENTATION_SECTION;

export function isClientPresentationSlideType(
  identifier: string
): identifier is SectionSlideType {
  return Object.keys(STANDALONE_SLIDE_TYPE_TO_PRESENTATION_SECTION).includes(
    identifier
  );
}

type StandaloneSlideSection =
  keyof typeof PRESENTATION_SECTION_TO_STANDALONE_SLIDE_TYPE;
export function isStandaloneClientPresentationSection(
  identifier: ClientPresentationSection
): identifier is StandaloneSlideSection {
  return Object.keys(PRESENTATION_SECTION_TO_STANDALONE_SLIDE_TYPE).includes(
    identifier
  );
}

export interface ClientPresentationSlide {
  id: string;
  displayName: string;
  index: number;
  bundleId: string;
  // the presence of the `identifier` property indicates that the slide
  // is "hideable" from the presentation
  identifier?: ClientPresentationIdentifier;
}

export enum ClientPresentationBundleTypes {
  ENTITY = 'entity',
  ESTATE_WATERFALL = 'estatewaterfall',
}

export interface ClientPresentationBundle {
  id: string;
  displayName: string;
  // the presence of the `identifier` property indicates that the bundle
  // is "hideable" from the presentation
  identifier?: ClientPresentationIdentifier;
  type: ClientPresentationBundleTypes;
}

export interface ClientPresentationBundleWithSlides
  extends ClientPresentationBundle {
  slides: ClientPresentationSlide[];
}
