export interface GroupRow {
  id: string;
  group: GroupKind;
  path: string[];
  name: string;
}

export interface EntityRow {
  id: string;
  entityId: string;
  path: string[];
  name: string;
}

export interface OwnershipStakeRow {
  id: string;
  individualId: string;
  path: string[];
  name: string;
}

export type Row = GroupRow | EntityRow | OwnershipStakeRow;

export enum GroupKind {
  IN_ESTATE_JOINT = 'IN_ESTATE_JOINT',
  IN_ESTATE_GRANTOR_A = 'IN_ESTATE_GRANTOR_A',
  IN_ESTATE_GRANTOR_B = 'IN_ESTATE_GRANTOR_B',
  OUT_OF_ESTATE_FAMILY_GIVING = 'OUT_OF_ESTATE_FAMILY_GIVING',
  OUT_OF_ESTATE_CHARITY = 'OUT_OF_ESTATE_CHARITY',
}

export type OnSelectionChange = ({
  selectedRowIds,
  rows,
}: {
  selectedRowIds: string[];
  rows: Row[];
}) => void;
