import { hasTrustAdvisors } from '@/modules/entities/TrustDetailsSubform/TrustDetailsSubform.utils';
import {
  AugmentedCreateTrustAdvisorInput,
  AugmentedCreateTrusteeInput,
  TrustAdvisorLevel,
  TrusteeLevel,
} from '@/types/schema';
import { getPulidKind, PulidKind } from '@/utils/pulid';

import { SubformsCombinedType } from '../../EntitySubforms.types';
import { getTypeOrUndefined } from './common.utils';

export function makeCreateTrusteeInputs(
  trustees: SubformsCombinedType['trustDetailsSubform']
): AugmentedCreateTrusteeInput[] {
  return (
    trustees.trustees
      .map((trustee) => {
        if (!trustee.trusteeId) {
          return null;
        }
        const kind = getPulidKind(trustee.trusteeId);

        let input: AugmentedCreateTrusteeInput | null = null;

        if (kind === PulidKind.ClientProfile) {
          input = {
            create: {
              individualID: trustee.trusteeId,
              notes: trustee.note,
            },
            withDuties:
              trustee.duties?.map((duty) => ({
                create: {
                  kind: duty,
                },
              })) ?? [],
          };
        }

        if (kind === PulidKind.ClientOrganization) {
          input = {
            create: {
              organizationID: trustee.trusteeId,
              notes: trustee.note,
            },
            withDuties:
              trustee.duties?.map((duty) => ({
                create: {
                  kind: duty,
                },
              })) ?? [],
          };
        }

        return input;
      })
      .filter((b): b is AugmentedCreateTrusteeInput => b !== null) ?? []
  );
}

export function makeCreateSuccessorTrusteeInputs(
  trustees: SubformsCombinedType['trustDetailsSubform']
): AugmentedCreateTrusteeInput[] {
  return (
    trustees.successorTrustees
      .map((trustee) => {
        if (!trustee.successorTrusteeId) {
          return null;
        }
        const createId: Partial<AugmentedCreateTrusteeInput['create']> = {};

        const pulidKind = getPulidKind(trustee.successorTrusteeId);

        if (!pulidKind) {
          throw new Error('Could not get pulid kind');
        }

        if (pulidKind === PulidKind.ClientProfile) {
          createId.individualID = trustee.successorTrusteeId;
        } else if (pulidKind === PulidKind.ClientOrganization) {
          createId.organizationID = trustee.successorTrusteeId;
        } else {
          throw new Error(`Unexpected pulid kind ${pulidKind}`);
        }

        const input: AugmentedCreateTrusteeInput = {
          create: {
            ...createId,
            level: getTypeOrUndefined<TrusteeLevel>(trustee.level),
            notes: trustee.note,
          },
          withDuties:
            trustee.duties?.map((duty) => ({
              create: {
                kind: duty,
              },
            })) ?? [],
        };

        return input;
      })
      .filter((b): b is AugmentedCreateTrusteeInput => b !== null) ?? []
  );
}

export function makeCreateTrustAdvisorInputs(
  trustForm: SubformsCombinedType['trustDetailsSubform']
): AugmentedCreateTrustAdvisorInput[] {
  if (!hasTrustAdvisors(trustForm)) {
    return [];
  }

  return (
    trustForm.trustAdvisors
      .map((advisor): AugmentedCreateTrustAdvisorInput | null => {
        if (!advisor.trustAdvisorId) {
          return null;
        }
        const kind = getPulidKind(advisor.trustAdvisorId);

        if (kind === PulidKind.ClientProfile) {
          return {
            create: {
              individualID: advisor.trustAdvisorId,
              level:
                getTypeOrUndefined<TrustAdvisorLevel>(advisor.level) ??
                TrustAdvisorLevel.Primary,
              note: advisor.note,
            },
            withRoles: advisor.role?.map((role) => ({
              create: {
                kind: role,
              },
            })),
          };
        }

        if (kind === PulidKind.ClientOrganization) {
          return {
            create: {
              organizationID: advisor.trustAdvisorId,
              level:
                getTypeOrUndefined<TrustAdvisorLevel>(advisor.level) ??
                TrustAdvisorLevel.Primary,
              note: advisor.note,
            },
            withRoles: advisor.role?.map((role) => ({
              create: {
                kind: role,
              },
            })),
          };
        }

        throw new Error(`Unrecognized pulid kind ${kind}`);
      })
      .filter((a): a is AugmentedCreateTrustAdvisorInput => a !== null) ?? []
  );
}
