import { MutationHookOptions } from '@apollo/client';
import { Box, Stack } from '@mui/material';
import { first } from 'lodash';
import { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from '@/components/form/baseInputs/Button';
import { FullScreenModal } from '@/components/modals/FullScreenModal/FullScreenModal';
import { Footer, FOOTER_HEIGHT } from '@/components/navigation/Footer';
import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import { LoadingOverlay } from '@/components/progress/LoadingOverlay/LoadingOverlay';
import { useForm } from '@/components/react-hook-form';
import { useReportError } from '@/hooks/useReportError';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { COLORS } from '@/styles/tokens/colors';
import { getNodes } from '@/utils/graphqlUtils';

import { useGrowthProfileModalQuery } from './graphql/GrowthProfileModal.generated';
import {
  useCreateGrowthProfileMutation,
  useUpdateGrowthProfileMutation,
} from './graphql/GrowthProfileModalMutations.generated';
import { GrowthProfileModalBody } from './GrowthProfileModal.components';
import { GrowthProfileModalContextProvider } from './GrowthProfileModal.context';
import {
  defaultGrowthProfileModalFormShape,
  GrowthProfileModalFormShape,
} from './GrowthProfileModal.types';
import {
  mapFormDataToCreateGrowthProfileInput,
  mapFormDataToUpdateGrowthProfileInput,
  mapIncomingDataToForm,
} from './GrowthProfileModal.utils';

export interface GrowthProfileModalProps {
  householdId: string;
  growthProfileId?: string | null | undefined;
  onClose: () => void;
  isDuplicating?: boolean | null | undefined;
  refetchQueries?: MutationHookOptions['refetchQueries'];
}

export function GrowthProfileModal({
  householdId,
  growthProfileId,
  onClose,
  isDuplicating,
  refetchQueries,
}: GrowthProfileModalProps) {
  const { reportError } = useReportError();
  const { showFeedback } = useFeedback();
  const trackUserEvent = useTrackUserEvent();

  const primaryButtonLabel = useMemo(() => {
    if (isDuplicating) return 'Duplicate profile';
    if (growthProfileId) return 'Save profile';
    return 'Create profile';
  }, [growthProfileId, isDuplicating]);

  const { data, loading: loadingDataFetch } = useGrowthProfileModalQuery({
    variables: {
      growthProfileId: growthProfileId ?? '',
      hasExistingGrowthProfile: !!growthProfileId,
    },
    fetchPolicy: 'network-only', // always get the latest data
  });

  const [createGrowthProfile, { loading: loadingCreateGrowthProfile }] =
    useCreateGrowthProfileMutation({
      refetchQueries,
      awaitRefetchQueries: true,
      onCompleted() {
        showFeedback('Growth profile created successfully', {
          variant: 'success',
        });
        trackUserEvent('growth_profile created');
      },
    });

  const [updateGrowthProfile, { loading: loadingUpdateGrowthProfile }] =
    useUpdateGrowthProfileMutation({
      refetchQueries: 'active',
      awaitRefetchQueries: true,
      onCompleted() {
        showFeedback('Growth profile saved successfully', {
          variant: 'success',
        });
        trackUserEvent('growth_profile updated');
      },
    });

  const loading =
    loadingDataFetch ||
    loadingCreateGrowthProfile ||
    loadingUpdateGrowthProfile;

  const formMethods = useForm<GrowthProfileModalFormShape>({
    defaultValues: defaultGrowthProfileModalFormShape,
  });

  const { reset, handleSubmit } = formMethods;

  const existingGrowthProfile = useMemo(
    () => first(getNodes(data?.growthProfiles)),
    [data?.growthProfiles]
  );

  useEffect(() => {
    if (loadingDataFetch) return;
    if (existingGrowthProfile) {
      reset(mapIncomingDataToForm(existingGrowthProfile));
    }
  }, [existingGrowthProfile, reset, loadingDataFetch]);

  const growthProfile = useMemo(
    () => first(getNodes(data?.growthProfiles)),
    [data?.growthProfiles]
  );

  const isDefault = growthProfile?.isDefault ?? false;

  const onSubmit = handleSubmit(async (data: GrowthProfileModalFormShape) => {
    try {
      if (growthProfileId && !isDuplicating) {
        await updateGrowthProfile({
          variables: mapFormDataToUpdateGrowthProfileInput(
            data,
            householdId,
            growthProfileId
          ),
        });
      } else {
        await createGrowthProfile({
          variables: mapFormDataToCreateGrowthProfileInput(data, householdId),
        });
      }
      onClose();
    } catch (e) {
      reportError('Error submitting growth profile', e as Error);
      let prefix = 'Error creating growth profile.';
      if (isDuplicating) {
        prefix = 'Error duplicating growth profile.';
      } else if (growthProfileId) {
        prefix = 'Error updating growth profile.';
      }

      showFeedback(`${prefix}  Please try again.`, { variant: 'error' });
    }
  });

  return (
    <GrowthProfileModalContextProvider
      growthProfileId={growthProfileId ?? null}
      isDefault={isDefault}
      isDuplicating={isDuplicating ?? false}
      assetClasses={getNodes(data?.assetClasses)}
      householdId={householdId}
    >
      <FormProvider {...formMethods}>
        <FullScreenModal isOpen onClose={onClose}>
          <Stack
            height="100%"
            pb={`${FOOTER_HEIGHT}px`}
            sx={{ overflowY: 'auto', backgroundColor: COLORS.GRAY[50] }}
            width="100%"
            alignItems="center"
          >
            <LoadingOverlay open={loading} />
            <Box maxWidth="800px">
              <GrowthProfileModalBody />
            </Box>
          </Stack>
          <Footer
            rightAction={
              <Stack direction="row" spacing={2}>
                <Button
                  key="cancel"
                  variant="secondary"
                  size="sm"
                  onClick={onClose}
                  disabled={
                    loadingCreateGrowthProfile || loadingUpdateGrowthProfile
                  }
                  loading={loading}
                >
                  Cancel
                </Button>
                <Button
                  key="submit"
                  variant="primary"
                  size="sm"
                  onClick={onSubmit}
                  loading={loading}
                  disabled={loading}
                >
                  {primaryButtonLabel}
                </Button>
              </Stack>
            }
          />
        </FullScreenModal>
      </FormProvider>
    </GrowthProfileModalContextProvider>
  );
}
