import Decimal from 'decimal.js';

import { Card } from '@/components/layout/Card/Card';
import { EstateWaterfallCumulativeTaxTable } from '@/modules/estateWaterfall/components/EstateWaterfallCumulativeTaxTable/EstateWaterfallCumulativeTaxTable';
import { AfterDeath } from '@/types/schema';

interface CumulativeTaxTotalsCardProps {
  taxBeforeFirstDeath?: Decimal;
  taxAfterFirstDeath?: Decimal;
  taxAfterSecondDeath?: Decimal;
  afterDeath: AfterDeath;
}

export const CumulativeTaxTotalsCard = (
  props: CumulativeTaxTotalsCardProps
) => {
  return (
    <Card variant="filled" sx={{ borderRadius: '6px', padding: 2 }}>
      <EstateWaterfallCumulativeTaxTable includeHeader {...props} />
    </Card>
  );
};
