import { getYear } from 'date-fns';
import { compact, uniqBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import {
  SelectInputComponentOption,
  SelectInputOption,
} from '@/components/form/baseInputs/inputTypes';
import { SelectItemGroupLabel } from '@/components/form/baseInputs/SelectInput/SelectItemGroupLabel';
import { useFormContext } from '@/components/react-hook-form';
import { EMPTY_CONTENT_HYPHEN } from '@/components/typography/placeholders';
import { formatCurrencyNoDecimals } from '@/utils/formatting/currency';

import { useHypotheticalTransferModalContext } from '../../../HypotheticalTransferModal/HypotheticalTransferModal.context';
import {
  AnnuallyRecurringValue,
  HypotheticalTransferFormModalShape,
} from '../../../HypotheticalTransferModal/HypotheticalTransferModal.types';

export type AssetOption =
  | SelectInputComponentOption<undefined>
  | SelectInputOption<string>;

interface UseAssetOptionsInput {
  ownerId: string;
}

export function useAssetOptions({ ownerId }: UseAssetOptionsInput): {
  assetOptions: AssetOption[];
  hasAssetOptions: boolean;
} {
  const { setValue, control } =
    useFormContext<HypotheticalTransferFormModalShape>();

  const selectedSourceOwnedEntityId = useWatch({
    control,
    name: 'sourceOwnedEntityId',
  });

  const {
    derivedData: { ownershipStakesByOwnerId, ilitValueByEntityId },
  } = useHypotheticalTransferModalContext();

  const { assetOptions, hasAssetOptions } = useMemo(() => {
    const ownershipStakesForOwner = ownershipStakesByOwnerId[ownerId] || [];
    const hasAssetOptions = !!ownershipStakesForOwner.length;

    // The options are the owned business entities for the selected owner
    // derived from the ownership stakes for the selected owner
    const ownershipStakesForOwnerOptions = uniqBy(
      ownershipStakesForOwner
        .map((stake) => ({
          display: stake.display,
          value: stake.sourceOwnedEntityId,
        }))
        .sort((a, b) => a.display.localeCompare(b.display)),
      'value'
    );

    const ilitNonInsuranceValue = ilitValueByEntityId[ownerId];

    const assetOptions = compact([
      ilitNonInsuranceValue
        ? {
            display: `Non-insurance assets (${formatCurrencyNoDecimals(ilitNonInsuranceValue)})`,
            value: '',
          }
        : {
            display: EMPTY_CONTENT_HYPHEN,
            value: '',
          },
      ownershipStakesForOwner.length
        ? {
            component: (
              <SelectItemGroupLabel label="Direct business ownership" />
            ),
            type: 'component' as const,
          }
        : null,
      ...ownershipStakesForOwnerOptions,
    ]);

    return { assetOptions, hasAssetOptions };
  }, [ilitValueByEntityId, ownerId, ownershipStakesByOwnerId]);

  useEffect(
    function setYearOptions() {
      if (selectedSourceOwnedEntityId) {
        setValue('transferFrequency', AnnuallyRecurringValue.false);
        setValue('transferStartYear', getYear(new Date()));
        setValue('transferEndYear', getYear(new Date()));
        setValue('_yearOptionsDisabled', true);
      } else {
        setValue('_yearOptionsDisabled', false);
      }
    },
    [selectedSourceOwnedEntityId, setValue]
  );

  return {
    assetOptions,
    hasAssetOptions,
  };
}
