import { Box, BoxProps, Stack, Typography, useTheme } from '@mui/material';
import Decimal from 'decimal.js';
import { CSSProperties, forwardRef, PropsWithChildren, ReactNode } from 'react';
import { useMeasure } from 'react-use';

import { PopperContent } from '@/components/poppers/PopperContent';
import { MiniTable } from '@/components/tables/MiniTable/MiniTable';
import { ContextualHelpTooltip } from '@/modules/content/components/ContextualHelpTooltip';
import { SPACING_CONSTANT } from '@/styles/themes/common';
import { zIndexes } from '@/styles/zIndexes';
import {
  formatCurrency,
  formatCurrencyNoDecimals,
} from '@/utils/formatting/currency';

import { getPreferredBackground } from '../chartAccessories';
import { Legend } from '../Legend/Legend';

export interface Subsection {
  value: number;
  color: string;
  backgroundCss?: CSSProperties['background'];
}

export interface Section {
  value: number;
  color: string;
  /** subsections allows you to break up the section into differently-formatted pieces */
  subsections?: Subsection[];
  label?: string | JSX.Element;
  backgroundCss?: CSSProperties['background'];
  tooltip?: JSX.Element;
  sectionTextColor?: string;
  onClick?: () => void;
  hideValue?: boolean;
  legendPrimaryText?: string; // If provided, this will be used instead of the value for the legend item
}

export interface StackedHorizontalBarProps {
  sections: Section[];
  variant?: 'legend' | 'bars-only';
  hideLegend?: boolean;
  scaleDenominator?: number; // If provided, the bar chart's width will be scaled by the ratio of the total value of the sections to this value
  endAdornment?: ReactNode;
}

export function StackedHorizontalBar({
  sections,
  variant = 'bars-only',
  scaleDenominator,
  hideLegend = false, // If true, the legend itself will not be displayed
  endAdornment,
}: StackedHorizontalBarProps) {
  const theme = useTheme();
  // use Math.abs to make sure each bar section can be scaled properly
  const barTotal = sections.reduce(
    (acc, { value }) => acc + Math.abs(value),
    0
  );

  const filteredSections =
    barTotal > 0 ? sections.filter(({ value }) => value > 0) : sections;

  return (
    <Stack gap={1}>
      <Stack direction="row">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: theme.spacing(6),
            width: scaleDenominator
              ? `${(barTotal / scaleDenominator) * 100}%`
              : '100%',
          }}
        >
          {filteredSections.map((section, idx) => (
            <BarChartSection
              section={section}
              isFirst={idx === 0}
              isLast={idx === filteredSections.length - 1}
              key={`horizontal-bar-${idx}`}
              data-testid={`StackedHorizontalBar-${idx}`}
              total={barTotal}
              variant={variant}
            />
          ))}
        </Box>
        {endAdornment}
      </Stack>
      {variant === 'legend' && !hideLegend && <Legend sections={sections} />}
    </Stack>
  );
}

interface BarChartSectionProps extends Omit<BoxProps, 'ref' | 'onClick'> {
  isFirst: boolean;
  section: Section;
  isLast: boolean;
  total: number;
  variant: StackedHorizontalBarProps['variant'];
}

/**
 * @description This component is used to render a single bar chart section
 * @param props.section The section to render
 * @param props.isFirst Boolean to determine if this is the first section in the bar chart, changes the border radius
 * @param props.isLast Boolean to determine if this is the last section in the bar chart, changes the border radius
 * @param props.total Total used to calculate the width of the bar
 * @param props.variant If variant is legend, then we will display a legend under the bar chart, otherwise we will only display the bars
 */
function BarChartSection({
  section,
  isFirst,
  isLast,
  total,
  variant,
  ...boxProps
}: BarChartSectionProps) {
  const {
    tooltip,
    value,
    color,
    backgroundCss,
    sectionTextColor,
    subsections,
  } = section;
  const theme = useTheme();

  const [innerLabelRef, { width: innerLabelWidth }] =
    useMeasure<HTMLDivElement>();
  const [barRef, { width: barWidth }] = useMeasure<HTMLDivElement>();

  // We should only display the inner label if there is enough space,
  //but account for the padding on both sides
  const shouldDisplayBarChildren =
    barWidth > innerLabelWidth + SPACING_CONSTANT * 2;

  const backgroundForBar = getPreferredBackground({
    backgroundCss,
    color,
  });

  return (
    <BarChartBoxHorizontal
      ref={barRef}
      widthPercent={(Math.abs(section.value) / total) * 100}
      isFirst={isFirst}
      isLast={isLast}
      tooltip={tooltip}
      background={backgroundForBar}
      onClick={section.onClick}
      {...boxProps}
    >
      <>
        {variant === 'bars-only' && !section.hideValue ? (
          <Stack
            direction="row"
            alignItems="center"
            height="100%"
            sx={{
              p: 1,
              pl: theme.spacing(1.5),
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              zIndex: zIndexes.CARD,
            }}
          >
            <Typography
              variant="h6"
              component="p"
              ref={innerLabelRef}
              color={sectionTextColor ?? theme.palette.common.white}
              visibility={shouldDisplayBarChildren ? 'visible' : 'hidden'}
            >
              {formatCurrency(new Decimal(value), {
                notation: 'compact',
              })}
            </Typography>
          </Stack>
        ) : undefined}

        {subsections && (
          <Box
            // todo: this assumes that the subsections are always on the right side of the bar; we may
            // want to make this configurable
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              height: '100%',
              position: 'relative',
              zIndex: zIndexes.CARD + 1,
            }}
          >
            {subsections.map((subsection, idx) => (
              <Subsection
                key={`subsection-${idx}`}
                subsection={subsection}
                total={value}
              />
            ))}
          </Box>
        )}
      </>
    </BarChartBoxHorizontal>
  );
}

type BarChartBoxHorizontalProps = PropsWithChildren<{
  background: CSSProperties['background'];
  widthPercent: number;
  isFirst: boolean;
  isLast: boolean;
  tooltip?: JSX.Element;
  onClick?: () => void;
}>;

const BarChartBoxHorizontal = forwardRef<
  HTMLDivElement,
  BarChartBoxHorizontalProps
>(function BarChartBoxHorizontal(
  { widthPercent, background, isFirst, isLast, tooltip, children, onClick },
  ref
) {
  const theme = useTheme();

  return (
    <ContextualHelpTooltip
      sx={{
        width: `${widthPercent}%`,
        minWidth: theme.spacing(1),
        // Override the default behavior of the tooltip to hide on print
        '@media print': {
          display: 'flex',
        },
      }}
      wrappedComponent={
        <Box
          ref={ref}
          onClick={onClick}
          sx={{
            background,
            position: 'relative',
            height: theme.spacing(6),
            borderTopLeftRadius: isFirst ? theme.spacing(0.5) : '0px',
            borderTopRightRadius: isLast ? theme.spacing(0.5) : '0px',
            borderBottomLeftRadius: isFirst ? theme.spacing(0.5) : '0px',
            borderBottomRightRadius: isLast ? theme.spacing(0.5) : '0px',
            overflow: 'hidden', // make sure that any subsections are also affected by the border radius
            cursor: onClick ? 'pointer' : undefined,
          }}
        >
          {children}
        </Box>
      }
      center
    >
      {tooltip ? tooltip : null}
    </ContextualHelpTooltip>
  );
});

interface SubsectionProps {
  subsection: Subsection;
  total: number;
}

function Subsection({ subsection, total }: SubsectionProps) {
  const { value, color, backgroundCss } = subsection;
  const widthPercent = (value / total) * 100;

  const backgroundForSubsection = getPreferredBackground({
    backgroundCss,
    color,
  });

  return (
    <Box
      sx={{
        width: `${widthPercent}%`,
        background: backgroundForSubsection,
        height: '100%',
      }}
    />
  );
}

export interface StackedHorizontalBarTooltipProps {
  value: Decimal;
  label: string;
}
export function StackedHorizontalBarTooltip({
  value,
  label,
}: StackedHorizontalBarTooltipProps) {
  return (
    <PopperContent
      sx={{
        width: 'auto',
      }}
      body={
        <MiniTable
          variant="default"
          rows={[
            {
              label,
              value: formatCurrencyNoDecimals(new Decimal(value)),
            },
          ]}
        />
      }
    />
  );
}
