import { useMemo } from 'react';

import {
  GroupedHeaderList,
  GroupedHeaderListProps,
} from '@/components/lists/GroupedHeaderList/GroupedHeaderList';
import { RichListItem } from '@/components/lists/RichListItem/RichListItem';

import {
  TrustTrusteesList_SuccessorTrusteeFragment,
  TrustTrusteesList_TrusteeFragment,
} from './graphql/TrustTrusteesList.fragments.generated';
import {
  getFormattedTrustee,
  getGroupedSuccessorTrusteeRows,
} from './TrustTrusteesList.utils';

type RowType = ReturnType<typeof getFormattedTrustee> & { id: string };

interface TrustTrusteesProps extends Partial<GroupedHeaderListProps<RowType>> {
  trustees: TrustTrusteesList_TrusteeFragment[];
  successorTrustees: TrustTrusteesList_SuccessorTrusteeFragment[];
}

export function TrustTrusteesList({
  trustees,
  successorTrustees,
  ...props
}: TrustTrusteesProps) {
  const successorTrusteeRows = useMemo(
    () => getGroupedSuccessorTrusteeRows(successorTrustees),
    [successorTrustees]
  );
  const trusteeRows = [
    {
      heading: 'Primary trustees',
      items: trustees.map((t) => ({
        ...getFormattedTrustee(t),
        id: t.id,
      })),
    },
    ...successorTrusteeRows,
  ];

  return (
    <GroupedHeaderList<RowType>
      rowGroups={trusteeRows}
      ItemComponent={({ item }) => <RichListItem {...item} />}
      {...props}
    />
  );
}
