import { noop } from 'lodash';
import { useEffect, useState } from 'react';

import { FlowChart } from '@/components/diagrams/FlowChart';
import { Background } from '@/components/diagrams/FlowChart/components/Background';
import { ControlButtonGroup } from '@/components/diagrams/FlowChart/components/Controls/ControlButtonGroup';
import { Controls } from '@/components/diagrams/FlowChart/components/Controls/Controls';
import { DownloadButton } from '@/components/diagrams/FlowChart/components/Controls/DownloadButton';
import { EditButton } from '@/components/diagrams/FlowChart/components/Controls/EditButton';
import { FitViewButton } from '@/components/diagrams/FlowChart/components/Controls/FitViewButton';
import { FullscreenButton } from '@/components/diagrams/FlowChart/components/Controls/FullscreenButton';
import { ResetButtonMenuItem } from '@/components/diagrams/FlowChart/components/Controls/ResetButton';
import { ZoomInButton } from '@/components/diagrams/FlowChart/components/Controls/ZoomInButton';
import { ZoomOutButton } from '@/components/diagrams/FlowChart/components/Controls/ZoomOutButton';
import { HelperLines } from '@/components/diagrams/FlowChart/components/HelperLines';
import { useIsInteractive } from '@/components/diagrams/FlowChart/hooks/useIsInteractive';
import { Button } from '@/components/form/baseInputs/Button';
import { ButtonWithPopover } from '@/components/form/baseInputs/Button/ButtonWithPopover';
import { MagicWand01Icon } from '@/components/icons/MagicWand01Icon';
import { MenuItem } from '@/components/poppers/MenuPopper/MenuItem';
import { useOnBeforeUnloadPrompt } from '@/hooks/useOnBeforeUnloadPrompt';
import { useTrackUserEvent } from '@/hooks/useTrackUserEvent';
import { COLORS } from '@/styles/tokens/colors';

import {
  AutoGroupConfirmationModal,
  useAutoGroup,
} from './components/AutoGroupConfirmationModal';
import { EstateWaterfallLegend } from './components/EstateWaterfallLegend/EstateWaterfallLegend';
import { EstateWaterfallVizConfigSettings } from './components/EstateWaterfallVizConfigSettings/EstateWaterfallVizConfigSettings';
import { useEstateWaterfallContext } from './contexts/estateWaterfall.context';
import { useFitViewOptions, useQueryWaterfall } from './hooks';
import { useFlowChartProps } from './hooks/useFlowChartProps';
import { useNodeSelection } from './hooks/useNodeSelection';

export interface EstateWaterfallFlowChartProps {
  waterfallId: string;
  householdId: string;
  waterfall: NonNullable<ReturnType<typeof useQueryWaterfall>['waterfall']>;
  // presentationMode is used to disable interactivity with the graph
  presentationMode?: boolean;
}

export const EstateWaterfallFlowChart = ({
  waterfallId,
  householdId,
  waterfall,
  presentationMode,
}: EstateWaterfallFlowChartProps) => {
  const trackUserEvent = useTrackUserEvent();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { flowChartProps, helperLineHorizontal, helperLineVertical } =
    useFlowChartProps();
  const { getFitViewOptions } = useFitViewOptions();
  const { isInteractive, toggleIsInteractive } = useIsInteractive();
  const { hasMultipleSelectedNodesForGrouping, groupSelection } =
    useNodeSelection();
  const { dispatch, state } = useEstateWaterfallContext();

  function onAutoPosition() {
    dispatch({ type: 'AUTO_POSITION' });
    return Promise.resolve();
  }

  function onEditButtonSave() {
    dispatch({ type: 'EDIT_SAVE' });
    toggleIsInteractive();
  }

  useOnBeforeUnloadPrompt(isInteractive);

  const { handleAutoGroup, loading: isAutoGrouping } =
    useAutoGroup(waterfallId);

  // Close the summary panel when the edit button is toggled
  useEffect(() => {
    if (isInteractive && state.derivedData.isSummaryPanelOpen) {
      dispatch({ type: 'CLOSE_SUMMARY_PANEL' });
    }
  }, [dispatch, isInteractive, state.derivedData.isSummaryPanelOpen]);

  if (presentationMode) {
    // Disable panning and zooming in presentation mode
    flowChartProps.panOnDrag = false;
    flowChartProps.panOnDrag = false;
    flowChartProps.zoomOnScroll = false;
    flowChartProps.zoomOnDoubleClick = false;
    flowChartProps.zoomOnPinch = false;
    flowChartProps.zoomActivationKeyCode = null;
    flowChartProps.onNodeClick = noop;
    flowChartProps.elementsSelectable = false;
  }

  return (
    <>
      {isConfirmationModalOpen && (
        <AutoGroupConfirmationModal
          isOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          loading={isAutoGrouping}
          onConfirm={async () => {
            const { data } = await handleAutoGroup();

            if (data?.autoGroupEstateWaterfall.id) {
              dispatch({ type: 'AUTO_POSITION' });
              setIsConfirmationModalOpen(false);
            }
          }}
        />
      )}
      <FlowChart {...flowChartProps} presentationMode={presentationMode}>
        <Background />
        <HelperLines
          vertical={helperLineVertical}
          horizontal={helperLineHorizontal}
        />
        {!presentationMode && (
          <>
            <Controls position="top-left">
              {isInteractive && (
                <>
                  {hasMultipleSelectedNodesForGrouping && (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={groupSelection}
                    >
                      Create group
                    </Button>
                  )}
                  <ButtonWithPopover
                    label="Auto tiles"
                    variant="secondary"
                    buttonSx={{
                      backgroundColor: COLORS.PRIMITIVES.WHITE,
                    }}
                    size="sm"
                    popperProps={{
                      anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
                      transformOrigin: { horizontal: 'left', vertical: 0 },
                    }}
                    startIcon={MagicWand01Icon}
                  >
                    <ResetButtonMenuItem onReset={onAutoPosition} />
                    <MenuItem
                      label="Automatically group tiles..."
                      onClick={() => setIsConfirmationModalOpen(true)}
                    />
                  </ButtonWithPopover>
                  <EstateWaterfallVizConfigSettings />
                </>
              )}
            </Controls>
            <Controls>
              <EditButton onClick={onEditButtonSave} />
              <DownloadButton
                viewDisplayName={waterfall.displayName}
                householdId={householdId}
                onBeforeDownload={async () => {
                  dispatch({
                    type: 'START_EXPORT',
                  });
                  // Wait for the waterfall to update before exporting the image
                  await new Promise((res) => setTimeout(res, 100));
                }}
                onAfterDownload={() => {
                  dispatch({ type: 'END_EXPORT' });
                  trackUserEvent('waterfall exported', {
                    householdId,
                    waterfallId,
                  });
                }}
                tooltipText="Download waterfall as PNG"
              />
              <ControlButtonGroup>
                <FullscreenButton />
                <FitViewButton fitViewOptions={getFitViewOptions} />
                <ZoomOutButton />
                <ZoomInButton />
              </ControlButtonGroup>
            </Controls>
            <Controls position="bottom-left" style={{ margin: 0 }}>
              <EstateWaterfallLegend />
            </Controls>
          </>
        )}
      </FlowChart>
    </>
  );
};
