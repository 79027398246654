import { Stack } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { useRequiredParam } from '@/hooks/useRequiredParam';

import { EntitiesBreadcrumb } from '../ClientDetailsEntitiesPage/components/EntitiesBreadcrumb';
import { GrowthProfilesTable } from './GrowthProfilesTable/GrowthProfilesTable';

interface ClientDetailsGrowthProfilesPageInnerProps {
  householdId: string;
}

function ClientDetailsGrowthProfilesPageInner({
  householdId,
}: ClientDetailsGrowthProfilesPageInnerProps) {
  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        pb={3}
      >
        <EntitiesBreadcrumb
          pageCrumb="Growth profiles"
          householdId={householdId}
        />
      </Stack>
      <Card variant="outlined" sx={{ p: 3 }}>
        <GrowthProfilesTable householdId={householdId} />
      </Card>
    </>
  );
}

export function ClientDetailsGrowthProfilesPage() {
  const householdId = useRequiredParam('householdId');

  return <ClientDetailsGrowthProfilesPageInner householdId={householdId} />;
}
