// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../../../types/schema';

import { gql } from '@apollo/client';
import { PageInfoFragmentDoc } from '../../../../../graphql/fragments/pageInfo.fragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ClientPresentationsTable_ClientPresentationFragment = { __typename?: 'ClientPresentation', id: string, updatedAt: Time, name: string, createdBy: { __typename?: 'User', id: string, initials: string, displayName: string }, household?: { __typename?: 'Household', id: string } | null };

export type ClientPresentationsTableQueryVariables = Types.Exact<{
  where?: Types.InputMaybe<Types.ClientPresentationWhereInput>;
  orderBy?: Types.InputMaybe<Array<Types.ClientPresentationOrder> | Types.ClientPresentationOrder>;
  first: Types.Scalars['Int']['input'];
  after?: Types.InputMaybe<Types.Scalars['Cursor']['input']>;
}>;


export type ClientPresentationsTableQuery = { __typename?: 'Query', paginatedData: { __typename?: 'ClientPresentationConnection', totalCount: number, pageInfo: { __typename?: 'PageInfo', startCursor?: any | null, endCursor?: any | null, hasNextPage: boolean, hasPreviousPage: boolean }, edges?: Array<{ __typename?: 'ClientPresentationEdge', node?: { __typename?: 'ClientPresentation', id: string, updatedAt: Time, name: string, createdBy: { __typename?: 'User', id: string, initials: string, displayName: string }, household?: { __typename?: 'Household', id: string } | null } | null } | null> | null } };

export const ClientPresentationsTable_ClientPresentationFragmentDoc = gql`
    fragment ClientPresentationsTable_ClientPresentation on ClientPresentation {
  id
  createdBy: user {
    id
    initials
    displayName
  }
  household {
    id
  }
  updatedAt
  name
}
    `;
export const ClientPresentationsTableDocument = gql`
    query ClientPresentationsTable($where: ClientPresentationWhereInput, $orderBy: [ClientPresentationOrder!], $first: Int!, $after: Cursor) {
  paginatedData: clientPresentations(
    where: $where
    orderBy: $orderBy
    first: $first
    after: $after
  ) {
    totalCount
    pageInfo {
      ...PageInfo
    }
    edges {
      node {
        id
        ...ClientPresentationsTable_ClientPresentation
      }
    }
  }
}
    ${PageInfoFragmentDoc}
${ClientPresentationsTable_ClientPresentationFragmentDoc}`;

/**
 * __useClientPresentationsTableQuery__
 *
 * To run a query within a React component, call `useClientPresentationsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientPresentationsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientPresentationsTableQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useClientPresentationsTableQuery(baseOptions: Apollo.QueryHookOptions<ClientPresentationsTableQuery, ClientPresentationsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientPresentationsTableQuery, ClientPresentationsTableQueryVariables>(ClientPresentationsTableDocument, options);
      }
export function useClientPresentationsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientPresentationsTableQuery, ClientPresentationsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientPresentationsTableQuery, ClientPresentationsTableQueryVariables>(ClientPresentationsTableDocument, options);
        }
export function useClientPresentationsTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ClientPresentationsTableQuery, ClientPresentationsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ClientPresentationsTableQuery, ClientPresentationsTableQueryVariables>(ClientPresentationsTableDocument, options);
        }
export type ClientPresentationsTableQueryHookResult = ReturnType<typeof useClientPresentationsTableQuery>;
export type ClientPresentationsTableLazyQueryHookResult = ReturnType<typeof useClientPresentationsTableLazyQuery>;
export type ClientPresentationsTableSuspenseQueryHookResult = ReturnType<typeof useClientPresentationsTableSuspenseQuery>;
export type ClientPresentationsTableQueryResult = Apollo.QueryResult<ClientPresentationsTableQuery, ClientPresentationsTableQueryVariables>;
export const Operations = {
  Query: {
    ClientPresentationsTable: 'ClientPresentationsTable' as const
  },
  Fragment: {
    ClientPresentationsTable_ClientPresentation: 'ClientPresentationsTable_ClientPresentation' as const
  }
}