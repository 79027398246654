import { Box, Stack } from '@mui/material';

import { Card } from '@/components/layout/Card/Card';
import { PDFViewerLazy } from '@/modules/pdf/PDFViewer';
import { DocumentChromeProps } from '@/modules/pdf/PDFViewer/DocumentChrome';
import { COLORS } from '@/styles/tokens/colors';

type DocumentViewerDocumentChromeProps = DocumentChromeProps;

export function DocumentViewerDocumentChrome({
  children,
  shouldCenterContent,
  sx = {},
}: DocumentViewerDocumentChromeProps) {
  return (
    <Card
      data-name="DocumentViewerDocumentChrome"
      variant="inner-shadow-light"
      sx={{
        height: '100%',
        width: '100%',
        backgroundColor: COLORS.PRIMITIVES.WHITE,
        overflowY: 'auto',
        p: 2,
        borderRadius: 0,
        ...sx,
      }}
    >
      <Box
        sx={{
          border: `1px solid ${COLORS.GRAY[200]}`,
          position: 'relative',
          width: '100%',
          height: shouldCenterContent ? '100%' : 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: shouldCenterContent ? 'center' : 'start',
        }}
      >
        {children}
      </Box>
    </Card>
  );
}

interface DocumentDetailsDocumentViewerProps {
  documentId: string;
  documentUrl: string;
}

export function DocumentDetailsDocumentViewer({
  documentId,
  documentUrl,
}: DocumentDetailsDocumentViewerProps) {
  return (
    <Stack flex={1} height="100%" overflow="auto">
      <Card
        data-name="DocumentViewer-container"
        variant="transparent"
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        <PDFViewerLazy
          documentId={documentId}
          documentUrl={documentUrl}
          slots={{ DocumentChrome: DocumentViewerDocumentChrome }}
        />
      </Card>
    </Stack>
  );
}
