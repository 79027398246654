import { useEffect, useState } from 'react';

import {
  FormFieldsDisabledContext,
  FormFieldsDisabledContextType,
} from './formFieldsDisabled.context';

export const FormFieldsDisabledProvider = ({
  children,
  isDisabled,
}: React.PropsWithChildren<{ isDisabled: boolean }>) => {
  const [disabled, setDisabled] =
    useState<FormFieldsDisabledContextType['disabled']>(undefined);

  useEffect(() => {
    // we translate from boolean -> (true | undefined) this way because we only want this context
    // to be able to explicitly mark all inputs as disabled; we don't want this component to explicitly *enable*
    // all inputs because we want to delegate the control of that to the form itself, and allowing this
    // component to do that would be a major footgun.
    setDisabled(isDisabled || undefined);
  }, [isDisabled, setDisabled]);

  return (
    <FormFieldsDisabledContext.Provider value={{ disabled }}>
      {children}
    </FormFieldsDisabledContext.Provider>
  );
};
