import { Stack, Typography } from '@mui/material';

import { MultiDocumentUploaderWithList } from '@/modules/documents/MultiDocumentUploader/MultiDocumentUploaderWithList';

interface ClientMultiDocumentUploaderProps {
  householdId: string;
  hideLabel?: boolean;
}

export function ClientMultiDocumentUploader({
  householdId,
  hideLabel,
}: ClientMultiDocumentUploaderProps) {
  return (
    <Stack gap={3} data-testid="client-multi-document-uploader">
      {!hideLabel && (
        <Typography>
          Upload client documents and Luminary AI will help build out the client
          profile.
        </Typography>
      )}
      <MultiDocumentUploaderWithList
        householdId={householdId}
        uploaderTextHeader="Upload all documents for this client to get started"
        uploaderTextSubheader="Verify document types are correct for each uploaded file. You’ll review & confirm extracted details after documents are processed"
      />
    </Stack>
  );
}
