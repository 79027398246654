import { useFeedback } from '@/components/notifications/Feedback/useFeedback';
import {
  DeleteMenuItemWithConfirmationModal,
  DeleteMenuItemWithConfirmationModalProps,
} from '@/components/poppers/MenuPopper/DeleteMenuItemWithConfirmationModal';
import { useReportError } from '@/hooks/useReportError';

import { useDeleteEntityMutation } from '../graphql/Entities.generated';

interface DeleteEntityMenuItemProps
  extends Partial<DeleteMenuItemWithConfirmationModalProps> {
  entityId: string;
  onDelete: () => void;
}

export function DeleteEntityMenuItem({
  entityId,
  onDelete,
  modalProps = {},
  menuItemProps = {},
  ...deleteProps
}: DeleteEntityMenuItemProps) {
  const { showFeedback } = useFeedback();
  const { reportError } = useReportError();
  const [deleteEntity, { loading: deleting }] = useDeleteEntityMutation({
    variables: { id: entityId },
    onError: (error) => {
      reportError(`failed to delete entity`, error);
      showFeedback(
        'Failed to delete this entity. Please refresh the page and try again.'
      );
    },
    onCompleted: () => {
      showFeedback('Entity deleted successfully', { variant: 'success' });
      onDelete();
    },
  });

  return (
    <DeleteMenuItemWithConfirmationModal
      label="Delete entity"
      onConfirmDelete={() => deleteEntity()}
      {...deleteProps}
      menuItemProps={{ loading: deleting, ...menuItemProps }}
      modalProps={{
        heading: 'Are you sure you want to delete this entity?',
        ...modalProps,
      }}
    />
  );
}
