import { Divider, Stack, StackProps } from '@mui/material';

import { COLORS } from '@/styles/tokens/colors';

interface Props extends StackProps {
  component?: React.ElementType;
  withDividers?: boolean;
}

export function UnorderedList(props: Props) {
  const { children, sx, component, withDividers, ...restProps } = props;

  return (
    <Stack
      {...restProps}
      divider={
        withDividers ? (
          <Divider
            sx={{
              borderColor: COLORS.GRAY[200],
            }}
          />
        ) : undefined
      }
      sx={{ listStyle: 'none', p: 0, m: 0, ...sx }}
      spacing={1}
      component={component ?? 'ul'}
    >
      {children}
    </Stack>
  );
}
