// @ts-nocheck
import {Percent, CurrencyUSD, Time, GraphQLDecimal} from '@/graphql/scalars'

import * as Types from '../../../types/schema';

import { gql } from '@apollo/client';
import { AssetValuationV2FragmentDoc } from './entityValuationQueries.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateAssetValuationMutationVariables = Types.Exact<{
  input: Types.AugmentedCreateAssetValuationV2Input;
}>;


export type CreateAssetValuationMutation = { __typename?: 'Mutation', createAssetValuationV2: { __typename?: 'AssetValuationV2', id: string, createdAt: Time, description?: string | null, effectiveDate: Time, valuationValue?: CurrencyUSD | null, valuationReason?: Types.AssetValuationV2ValuationReason | null, account?: { __typename?: 'Account', id: string, displayName: string, initialValuation?: { __typename?: 'AssetValuationV2', id: string, valuationValue?: CurrencyUSD | null } | null } | null, previousValuation?: { __typename?: 'AssetValuationV2', id: string, valuationValue?: CurrencyUSD | null, effectiveDate: Time, assets: { __typename?: 'AssetV2Connection', edges?: Array<{ __typename?: 'AssetV2Edge', node?: { __typename?: 'AssetV2', id: string, displayName: string, qsbsEligibility: Types.AssetV2QsbsEligibility, class: { __typename?: 'AssetClass', id: string, displayName: string }, assetValue: { __typename?: 'AssetValueV2', id: string, value: CurrencyUSD, ownershipType: Types.AssetValueV2OwnershipType, ownedValue?: CurrencyUSD | null, totalValue?: CurrencyUSD | null, ownedPercent?: Percent | null, shareCount?: GraphQLDecimal | null, shareValue?: CurrencyUSD | null } } | null } | null> | null } } | null, assets: { __typename?: 'AssetV2Connection', totalCount: number, edges?: Array<{ __typename?: 'AssetV2Edge', node?: { __typename?: 'AssetV2', id: string, displayName: string, qsbsEligibility: Types.AssetV2QsbsEligibility, class: { __typename?: 'AssetClass', id: string, displayName: string }, assetValue: { __typename?: 'AssetValueV2', id: string, value: CurrencyUSD, ownershipType: Types.AssetValueV2OwnershipType, ownedValue?: CurrencyUSD | null, totalValue?: CurrencyUSD | null, ownedPercent?: Percent | null, shareCount?: GraphQLDecimal | null, shareValue?: CurrencyUSD | null } } | null } | null> | null } } };

export type UpdateAssetValuationMutationVariables = Types.Exact<{
  input: Types.AugmentedUpdateAssetValuationV2Input;
}>;


export type UpdateAssetValuationMutation = { __typename?: 'Mutation', updateAssetValuationV2: { __typename?: 'AssetValuationV2', id: string, createdAt: Time, description?: string | null, effectiveDate: Time, valuationValue?: CurrencyUSD | null, valuationReason?: Types.AssetValuationV2ValuationReason | null, account?: { __typename?: 'Account', id: string, displayName: string, initialValuation?: { __typename?: 'AssetValuationV2', id: string, valuationValue?: CurrencyUSD | null } | null } | null, previousValuation?: { __typename?: 'AssetValuationV2', id: string, valuationValue?: CurrencyUSD | null, effectiveDate: Time, assets: { __typename?: 'AssetV2Connection', edges?: Array<{ __typename?: 'AssetV2Edge', node?: { __typename?: 'AssetV2', id: string, displayName: string, qsbsEligibility: Types.AssetV2QsbsEligibility, class: { __typename?: 'AssetClass', id: string, displayName: string }, assetValue: { __typename?: 'AssetValueV2', id: string, value: CurrencyUSD, ownershipType: Types.AssetValueV2OwnershipType, ownedValue?: CurrencyUSD | null, totalValue?: CurrencyUSD | null, ownedPercent?: Percent | null, shareCount?: GraphQLDecimal | null, shareValue?: CurrencyUSD | null } } | null } | null> | null } } | null, assets: { __typename?: 'AssetV2Connection', totalCount: number, edges?: Array<{ __typename?: 'AssetV2Edge', node?: { __typename?: 'AssetV2', id: string, displayName: string, qsbsEligibility: Types.AssetV2QsbsEligibility, class: { __typename?: 'AssetClass', id: string, displayName: string }, assetValue: { __typename?: 'AssetValueV2', id: string, value: CurrencyUSD, ownershipType: Types.AssetValueV2OwnershipType, ownedValue?: CurrencyUSD | null, totalValue?: CurrencyUSD | null, ownedPercent?: Percent | null, shareCount?: GraphQLDecimal | null, shareValue?: CurrencyUSD | null } } | null } | null> | null } } };

export type DeleteAssetValuationMutationVariables = Types.Exact<{
  assetValuationId: Types.Scalars['ID']['input'];
}>;


export type DeleteAssetValuationMutation = { __typename?: 'Mutation', deleteAssetValuationv2: string };


export const CreateAssetValuationDocument = gql`
    mutation CreateAssetValuation($input: AugmentedCreateAssetValuationV2Input!) {
  createAssetValuationV2(input: $input) {
    ...AssetValuationV2
  }
}
    ${AssetValuationV2FragmentDoc}`;
export type CreateAssetValuationMutationFn = Apollo.MutationFunction<CreateAssetValuationMutation, CreateAssetValuationMutationVariables>;

/**
 * __useCreateAssetValuationMutation__
 *
 * To run a mutation, you first call `useCreateAssetValuationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetValuationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetValuationMutation, { data, loading, error }] = useCreateAssetValuationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetValuationMutation(baseOptions?: Apollo.MutationHookOptions<CreateAssetValuationMutation, CreateAssetValuationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAssetValuationMutation, CreateAssetValuationMutationVariables>(CreateAssetValuationDocument, options);
      }
export type CreateAssetValuationMutationHookResult = ReturnType<typeof useCreateAssetValuationMutation>;
export type CreateAssetValuationMutationResult = Apollo.MutationResult<CreateAssetValuationMutation>;
export type CreateAssetValuationMutationOptions = Apollo.BaseMutationOptions<CreateAssetValuationMutation, CreateAssetValuationMutationVariables>;
export const UpdateAssetValuationDocument = gql`
    mutation UpdateAssetValuation($input: AugmentedUpdateAssetValuationV2Input!) {
  updateAssetValuationV2(input: $input) {
    ...AssetValuationV2
  }
}
    ${AssetValuationV2FragmentDoc}`;
export type UpdateAssetValuationMutationFn = Apollo.MutationFunction<UpdateAssetValuationMutation, UpdateAssetValuationMutationVariables>;

/**
 * __useUpdateAssetValuationMutation__
 *
 * To run a mutation, you first call `useUpdateAssetValuationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssetValuationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssetValuationMutation, { data, loading, error }] = useUpdateAssetValuationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssetValuationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssetValuationMutation, UpdateAssetValuationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssetValuationMutation, UpdateAssetValuationMutationVariables>(UpdateAssetValuationDocument, options);
      }
export type UpdateAssetValuationMutationHookResult = ReturnType<typeof useUpdateAssetValuationMutation>;
export type UpdateAssetValuationMutationResult = Apollo.MutationResult<UpdateAssetValuationMutation>;
export type UpdateAssetValuationMutationOptions = Apollo.BaseMutationOptions<UpdateAssetValuationMutation, UpdateAssetValuationMutationVariables>;
export const DeleteAssetValuationDocument = gql`
    mutation DeleteAssetValuation($assetValuationId: ID!) {
  deleteAssetValuationv2(id: $assetValuationId)
}
    `;
export type DeleteAssetValuationMutationFn = Apollo.MutationFunction<DeleteAssetValuationMutation, DeleteAssetValuationMutationVariables>;

/**
 * __useDeleteAssetValuationMutation__
 *
 * To run a mutation, you first call `useDeleteAssetValuationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetValuationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetValuationMutation, { data, loading, error }] = useDeleteAssetValuationMutation({
 *   variables: {
 *      assetValuationId: // value for 'assetValuationId'
 *   },
 * });
 */
export function useDeleteAssetValuationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAssetValuationMutation, DeleteAssetValuationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAssetValuationMutation, DeleteAssetValuationMutationVariables>(DeleteAssetValuationDocument, options);
      }
export type DeleteAssetValuationMutationHookResult = ReturnType<typeof useDeleteAssetValuationMutation>;
export type DeleteAssetValuationMutationResult = Apollo.MutationResult<DeleteAssetValuationMutation>;
export type DeleteAssetValuationMutationOptions = Apollo.BaseMutationOptions<DeleteAssetValuationMutation, DeleteAssetValuationMutationVariables>;
export const Operations = {
  Mutation: {
    CreateAssetValuation: 'CreateAssetValuation' as const,
    UpdateAssetValuation: 'UpdateAssetValuation' as const,
    DeleteAssetValuation: 'DeleteAssetValuation' as const
  }
}