import { Box, Stack, Typography } from '@mui/material';
import { getYear } from 'date-fns';
import { chunk } from 'lodash';
import { PropsWithChildren, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMeasure } from 'react-use';

import { useChartColorDefinitions } from '@/components/charts/constants';
import { LegendItem } from '@/components/charts/Legend/Legend';
import { StackedVerticalBar } from '@/components/charts/StackedVerticalBar/StackedVerticalBar';
import { LinkButton } from '@/components/form/baseInputs/Link';
import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon';
import { UnstyledNavLink } from '@/components/navigation/UnstyledNavLink';
import { useViewOnly } from '@/contexts/InteractionParadigm.context';
import { useWaterfallRouteContext } from '@/modules/entities/contexts/waterfallRoute/waterfallRoute.context';
import {
  ContextPrimaryClient,
  useHouseholdDetailsContext,
} from '@/modules/household/contexts/householdDetails.context';
import { ROUTE_KEYS } from '@/navigation/constants';
import { getCompletePathFromRouteKey } from '@/navigation/navigationUtils';
import { SPACING_CONSTANT } from '@/styles/themes/common';
import { formatCurrency } from '@/utils/formatting/currency';

import { usePrimaryBeneficiaryBars } from './hooks/usePrimaryBeneficiaryBars';

// Vertical bar height in pixels
const MAX_BAR_HEIGHT = 600;
const MIN_BAR_WIDTH = 156;
const MAX_BAR_WIDTH = 240;
const BAR_SPACING = 2.5;
export const MAX_NUMBER_OF_BARS = 6;
const scrimGradient =
  'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 32%, rgba(255, 255, 255, 0.48) 100%)';

function BarLink({
  beneficiaryDetailsPath,
  beneficiaryName,
  children,
}: PropsWithChildren<{
  beneficiaryDetailsPath?: string;
  beneficiaryName?: string;
}>) {
  if (beneficiaryDetailsPath && beneficiaryName) {
    return (
      <UnstyledNavLink
        to={beneficiaryDetailsPath}
        aria-label={`View beneficiary details for ${beneficiaryName}`}
      >
        {children}
      </UnstyledNavLink>
    );
  }

  return <>{children}</>;
}

interface BenefitsOverviewBarChartsProps {
  firstGrantor?: ContextPrimaryClient;
  secondGrantor?: ContextPrimaryClient;
  firstGrantorDeathYear?: number;
  secondGrantorDeathYear?: number;
  page?: number;
}

export function BenefitsOverviewBarCharts({
  firstGrantor,
  secondGrantor,
  firstGrantorDeathYear,
  secondGrantorDeathYear,
  page,
}: BenefitsOverviewBarChartsProps) {
  const navigate = useNavigate();
  const viewOnly = useViewOnly();
  const chartColorDefinitions = useChartColorDefinitions();
  const { householdId } = useHouseholdDetailsContext();
  const { waterfallId } = useWaterfallRouteContext();
  const allPrimaryBeneficiaryBars = usePrimaryBeneficiaryBars();

  const primaryBeneficiaryBars = useMemo(() => {
    if (page === undefined) {
      return allPrimaryBeneficiaryBars;
    }

    return chunk(allPrimaryBeneficiaryBars, MAX_NUMBER_OF_BARS)[page] ?? [];
  }, [allPrimaryBeneficiaryBars, page]);

  const [barChartContainerRef, { width: barChartContainerWidth }] =
    useMeasure<HTMLDivElement>();

  const scaleDenominator = useMemo(() => {
    return Math.max(...primaryBeneficiaryBars.map((b) => b.total.toNumber()));
  }, [primaryBeneficiaryBars]);

  const barWidth = useMemo(() => {
    const numberOfBars = Math.min(
      primaryBeneficiaryBars.length,
      MAX_NUMBER_OF_BARS
    );

    const spacing = Math.max(
      BAR_SPACING * SPACING_CONSTANT * (numberOfBars - 1),
      0
    );

    if (barChartContainerWidth) {
      const barWidth = (barChartContainerWidth - spacing) / numberOfBars;

      return Math.max(Math.min(barWidth, MAX_BAR_WIDTH), MIN_BAR_WIDTH);
    }

    return MIN_BAR_WIDTH;
  }, [barChartContainerWidth, primaryBeneficiaryBars.length]);

  const isOverflowing = useMemo(() => {
    return (
      barWidth === MIN_BAR_WIDTH ||
      primaryBeneficiaryBars.length > MAX_NUMBER_OF_BARS
    );
  }, [barWidth, primaryBeneficiaryBars.length]);

  if (!primaryBeneficiaryBars.length) {
    return <Typography variant="h6">No primary beneficiaries</Typography>;
  }

  return (
    <Stack spacing={3} position="relative">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">Primary beneficiaries</Typography>
      </Stack>
      <Stack
        ref={barChartContainerRef}
        direction="row"
        spacing={BAR_SPACING}
        sx={{
          overflowX: isOverflowing ? 'auto' : 'hidden',
          position: 'relative',
        }}
      >
        {primaryBeneficiaryBars.map((bar) => {
          const heightPx = Math.max(
            (bar.total.toNumber() / scaleDenominator) * MAX_BAR_HEIGHT,
            bar.sections.filter((s) => s.value > 0).length *
              SPACING_CONSTANT *
              3
          );

          const beneficiaryDetailsPath =
            householdId && waterfallId
              ? getCompletePathFromRouteKey(
                  ROUTE_KEYS.HOUSEHOLD_DETAILS_ESTATE_WATERFALL_BENEFICIARY_REPORTING_BENEFICIARY_DETAILS,
                  {
                    householdId,
                    waterfallId,
                    clientOrOrgId: bar.id,
                  }
                )
              : undefined;

          return (
            <Stack
              key={bar.id}
              spacing={2}
              alignItems="center"
              justifyContent="end"
            >
              <Stack direction="row" justifyContent="center">
                <Typography variant="h4" component="p">
                  {formatCurrency(bar.total, {
                    notation: 'compact',
                  })}
                </Typography>
              </Stack>
              <BarLink
                beneficiaryDetailsPath={beneficiaryDetailsPath}
                beneficiaryName={bar.label}
              >
                <StackedVerticalBar
                  width={`${barWidth}px`}
                  height={`${heightPx}px`}
                  sections={bar.sections}
                />
              </BarLink>
              <LinkButton
                underline={false}
                display={bar.label}
                sx={{
                  maxWidth: barWidth,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                }}
                onClick={() => {
                  if (!beneficiaryDetailsPath) {
                    return;
                  }

                  navigate(beneficiaryDetailsPath);
                }}
                endIcon={viewOnly ? undefined : <ChevronRightIcon size={14} />}
              />
            </Stack>
          );
        })}
      </Stack>
      {isOverflowing && (
        <Box
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          width="48px"
          sx={{
            background: scrimGradient,
          }}
        />
      )}
      <Stack direction="row" spacing={3} alignItems="center">
        <LegendItem
          primaryText={`Before ${firstGrantor?.firstName}’s death ${firstGrantorDeathYear ? `(${getYear(new Date())})` : ''}`.trim()}
          legendItemColor={chartColorDefinitions.PRIMARY.backgroundColor}
        />
        {firstGrantor && (
          <LegendItem
            primaryText={`After ${firstGrantor?.firstName}’s death ${firstGrantorDeathYear ? `(${firstGrantorDeathYear})` : ''}`.trim()}
            legendItemColor={chartColorDefinitions.SECONDARY.backgroundColor}
          />
        )}
        {secondGrantor && (
          <LegendItem
            primaryText={`After ${secondGrantor?.firstName}’s death ${secondGrantorDeathYear ? `(${secondGrantorDeathYear})` : ''}`.trim()}
            legendItemColor={chartColorDefinitions.TERTIARY.backgroundColor}
          />
        )}
      </Stack>
    </Stack>
  );
}
