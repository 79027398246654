import { Outlet } from 'react-router-dom';

import { useRequiredParam } from '@/hooks/useRequiredParam';
import { WaterfallRouteProvider } from '@/modules/entities/contexts/waterfallRoute/WaterfallRoute.provider';

export function ClientWaterfallContexts() {
  const waterfallId = useRequiredParam('waterfallId');

  return (
    <WaterfallRouteProvider waterfallId={waterfallId}>
      <Outlet />
    </WaterfallRouteProvider>
  );
}
