import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import Decimal from 'decimal.js';
import { useCallback, useEffect, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import { useFormContext } from '@/components/react-hook-form';
import { useDebouncedFn } from '@/hooks/useDebouncedFn';
import {
  CltProposalProjectionInput,
  CrtProposalProjectionInput,
  NoPlanProjectionInput,
} from '@/types/schema';

import { useIsCRT } from '../../CharitableTrustDesignerContext';
import { NAMESPACE as TAX_RETURN_LIMIT_MODAL_NAMESPACE } from '../../PreTaxReturnLimitModal/PreTaxReturnLimitModal.types';
import {
  CharitableTrustDesignerAnalysisForm,
  NAMESPACE,
} from '../CharitableTrustDesignerAnalysis.types';
import { useDonorsYearOfBirth } from './useDonorsYearOfBirth';
import { useValidPayout } from './useValidPayout';
import { useValidTerm } from './useValidTerm';

export interface CharitableLookupVariables {
  modelParams: (CltProposalProjectionInput & CrtProposalProjectionInput) | null;
  noPlanProjectionInput: NoPlanProjectionInput | null;
}

export interface BaseCharitableProjectionVariables extends OperationVariables {
  crtProjectionInput: CrtProposalProjectionInput;
  cltProjectionInput: CltProposalProjectionInput;
  noPlanProjectionInput: NoPlanProjectionInput;
  isCRT: boolean;
}

type GetVariablesType<TVariables extends OperationVariables> = (
  arg: BaseCharitableProjectionVariables
) => TVariables;

export function useCharitableProjection<
  TData,
  TVariables extends BaseCharitableProjectionVariables,
>(
  lazyQueryFunction: LazyQueryExecFunction<TData, TVariables>,
  callback: (arg: TData) => void,
  getVariables: GetVariablesType<TVariables>
) {
  const { control } = useFormContext<CharitableTrustDesignerAnalysisForm>();
  const isCRT = useIsCRT();
  const [
    preTaxReturnModel,
    preTaxReturnPercentageLow,
    preTaxReturnPercentageMedium,
    preTaxReturnPercentageHigh,
    taxDragPercentageLow,
    taxDragPercentageMedium,
    taxDragPercentageHigh,
    costBasis,
    fundingAmount,
  ] = useWatch({
    control,
    name: [
      `${NAMESPACE}.analysis.preTaxReturnModel`,
      `${TAX_RETURN_LIMIT_MODAL_NAMESPACE}.preTaxReturnPercentageLow`,
      `${TAX_RETURN_LIMIT_MODAL_NAMESPACE}.preTaxReturnPercentageMedium`,
      `${TAX_RETURN_LIMIT_MODAL_NAMESPACE}.preTaxReturnPercentageHigh`,
      `${TAX_RETURN_LIMIT_MODAL_NAMESPACE}.taxDragPercentageLow`,
      `${TAX_RETURN_LIMIT_MODAL_NAMESPACE}.taxDragPercentageMedium`,
      `${TAX_RETURN_LIMIT_MODAL_NAMESPACE}.taxDragPercentageHigh`,
      `${NAMESPACE}.assets.costBasis`,
      `${NAMESPACE}.assets.value`,
    ],
  });

  let preTaxReturnPercentage: Decimal | null = null;
  let taxDrag: Decimal | null = null;

  switch (preTaxReturnModel) {
    case 'low': {
      preTaxReturnPercentage = preTaxReturnPercentageLow;
      taxDrag = taxDragPercentageLow;
      break;
    }
    case 'medium':
      preTaxReturnPercentage = preTaxReturnPercentageMedium;
      taxDrag = taxDragPercentageMedium;
      break;
    case 'high':
      preTaxReturnPercentage = preTaxReturnPercentageHigh;
      taxDrag = taxDragPercentageHigh;
      break;
  }

  const donorsYearOfBirth = useDonorsYearOfBirth();
  const term = useValidTerm();
  const payoutData = useValidPayout();

  const { modelParams: charitableParamsInput, noPlanProjectionInput } =
    useMemo<CharitableLookupVariables>(() => {
      if (
        costBasis &&
        fundingAmount &&
        preTaxReturnPercentage &&
        taxDrag &&
        term &&
        donorsYearOfBirth &&
        payoutData
      ) {
        return {
          modelParams: {
            costBasis,
            fundingAmount,
            preTaxReturnPercentage,
            taxDrag,
            donorsYearOfBirth,
            ...payoutData,
            ...term,
          },
          noPlanProjectionInput: {
            assetValue: fundingAmount,
            costBasis,
            growthRate: preTaxReturnPercentage,
            ignoreCapitalGainsTax: isCRT ? undefined : true,
            taxDrag: isCRT ? taxDrag : new Decimal(0),
            termYears: term.termYears,
          },
        };
      }
      return {
        modelParams: null,
        noPlanProjectionInput: null,
      };
    }, [
      costBasis,
      donorsYearOfBirth,
      fundingAmount,
      term,
      isCRT,
      payoutData,
      preTaxReturnPercentage,
      taxDrag,
    ]);

  const runQuery = useCallback(
    async (
      charitableParamsInput:
        | (CltProposalProjectionInput & CrtProposalProjectionInput)
        | null,
      noPlanProjectionInput: NoPlanProjectionInput | null,
      isCRT: boolean
    ) => {
      if (!charitableParamsInput || !noPlanProjectionInput) {
        return;
      }

      const result = await lazyQueryFunction({
        fetchPolicy: 'no-cache', // because the underlying projections lack IDs, disable caching
        variables: getVariables({
          crtProjectionInput: charitableParamsInput,
          cltProjectionInput: charitableParamsInput,
          noPlanProjectionInput,
          isCRT,
        }),
      });

      const { data } = result || {};

      if (data) {
        callback(data);
      }
    },
    [callback, getVariables, lazyQueryFunction]
  );

  const debouncedQueryCall = useDebouncedFn(runQuery, 250, {
    leading: false,
    trailing: true,
  });

  useEffect(() => {
    if (
      charitableParamsInput?.fundingAmount.lessThanOrEqualTo(new Decimal(0)) ||
      noPlanProjectionInput?.assetValue.lessThanOrEqualTo(new Decimal(0))
    ) {
      return;
    }

    void debouncedQueryCall(
      charitableParamsInput,
      noPlanProjectionInput,
      isCRT
    );
  }, [isCRT, charitableParamsInput, noPlanProjectionInput, debouncedQueryCall]);
}
