import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useFeedback } from '@/components/notifications/Feedback/useFeedback';

const ADDEPAR_FAILED_CODE = 'ADDEPAR_AUTHENTICATION_FAILED';

interface AddeparIntegrationErrorParams {
  onError?: () => void;
}

export function useAddeparIntegrationErrorSearchParam(
  params?: AddeparIntegrationErrorParams
) {
  const { showFeedback } = useFeedback();
  const [searchParams] = useSearchParams();

  useEffect(
    function maybeShowErrorFromCode() {
      const maybeErrorCode = searchParams.get('code');
      if (maybeErrorCode === ADDEPAR_FAILED_CODE) {
        showFeedback('Failed to authenticate with Addepar. Please try again.');
        // onError here allows calling components to perform actions when they know there's an error being communicated from the backend
        params?.onError?.();
      }
    },
    [params, searchParams, showFeedback]
  );
}
