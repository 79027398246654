import { Box } from '@mui/material';

import { FormLayoutItem, FormLayoutRow } from '@/components/layout/FormLayout';
import { Callout } from '@/components/notifications/Callout/Callout';
import { EntityType } from '@/modules/entities/types/EntityType';

export interface InfoCalloutProps {
  entityType: EntityType;
}

export function InfoCallout({ entityType }: InfoCalloutProps) {
  switch (entityType) {
    case 'grat':
      return (
        <FormLayoutRow>
          <FormLayoutItem>
            <Callout severity="info-high">
              <Box mb={0.5}>
                <strong>Setting up GRATs in Luminary</strong>
              </Box>
              <Box>
                Creating a GRAT will require completing the full design and
                implementation workflow.
              </Box>
            </Callout>
          </FormLayoutItem>
        </FormLayoutRow>
      );
    case 'insurance-account':
      return (
        <FormLayoutRow>
          <FormLayoutItem>
            <Callout severity="info-high">
              <Box mb={0.5}>
                <strong>Add policy information</strong>
              </Box>
              <Box>
                Once the entity is created, you can edit entity details to add
                information for each insurance policy held by the client.
              </Box>
            </Callout>
          </FormLayoutItem>
        </FormLayoutRow>
      );
    default:
      return null;
  }
}
