import { getRevocableIrrevocableTrustSummaryProperties } from '@/modules/entities/details/trusts/RevocableIrrevocableTrustDetails';
import { getEntityTypeFromSubtype } from '@/modules/entities/utils/getEntityTypeFromSubtype';

import { TrustFiduciariesSlide } from '../components/Trust.Fiduciaries';
import { RevocableIrrevocableTrustPresentationSlideProps } from './RevocableIrrevocableTrust.types';

export function RevocableIrrevocableTrustFiduciariesSlide({
  entity,
  subtype,
  ...registrationProps
}: RevocableIrrevocableTrustPresentationSlideProps) {
  const summaryDetails = getRevocableIrrevocableTrustSummaryProperties({
    entity,
    entityType: getEntityTypeFromSubtype(subtype.__typename!),
  });

  return (
    <TrustFiduciariesSlide
      {...registrationProps}
      entity={entity}
      summary={summaryDetails}
      displayName={subtype.displayName}
    />
  );
}
